import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "components/notifications/notifications.module.scss";
import Conditional from "components/Conditional";
import { useOptionalClassName } from "utilities/hooks";
import notificationsApi from "clientApi/notificationsApi";
import { ClarityNotification, INotificationAction } from "utilities/types";

export default function Notifications() {
  const notifications = useRef<{ [id: string]: ClarityNotification }>({});
  const [notificationIds, setNotificationIds] = useState<string[]>([]);
  const notificationsIdsRef = useRef<string[]>([]);

  useEffect(() => {
    notificationsIdsRef.current = notificationIds;
  }, [notificationIds]);

  const subscriptionListener = useCallback((newNotification) => {
    if (newNotification !== null) {
      if (!newNotification.removed) {
        notifications.current = {
          ...notifications.current,
          [newNotification.id]: {
            ...notifications.current[newNotification.id],
            ...newNotification,
          },
        };
        const newNotifIds = Array.from(
          new Set([...notificationsIdsRef.current, newNotification.id])
        );
        setNotificationIds(newNotifIds);
      } else {
        const { [newNotification.id]: value, ...withoutNotification } =
          notifications.current;

        notifications.current = withoutNotification;

        setNotificationIds(
          notificationsIdsRef.current.filter((id) => id !== newNotification.id)
        );
      }
    }
  }, []);

  useLayoutEffect(() => {
    const subscription =
      notificationsApi.notificationsSubject.subscribe(subscriptionListener);
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  return (
    <div className={styles.container}>
      {notificationIds.map((id) => (
        <Notification key={id} {...notifications.current[id]} />
      ))}
    </div>
  );
}

const Notification: React.FC<ClarityNotification> = ({
  id,
  duration,
  actions,
  autodismissable,
  title,
  body,
  footer,
  icon,
  dismissable,
  leaving,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const close: React.MouseEventHandler = (e) => {
    setIsExiting(true);
    setTimeout(() => {
      notificationsApi.removeNotification(id);
    }, 500);
  };

  useEffect(() => {
    if (leaving) {
      setIsExiting(true);
    }
  }, [leaving]);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 50);
  }, []);

  const className = useOptionalClassName({
    baseStyle: styles.notification,
    pairs: [
      {
        extraStyle: styles.notificationReady,
        withExtra: isReady,
      },
      {
        extraStyle: styles.notificationExiting,
        withExtra: isExiting,
      },
    ],
  });

  return (
    <div className={className}>
      <div className={styles.notificationHead}>
        <Conditional on={icon}>
          <span className={styles.notificationIcon}>{icon}</span>
        </Conditional>
        <div className={styles.notificationContent}>
          <Conditional on={title}>
            <span className={styles.notificationTitle}>
              {title}
              {body && ":"}{" "}
            </span>
          </Conditional>
          <span className={styles.notificationBody}>{body}</span>
        </div>
      </div>
      <Conditional on={dismissable || (actions && actions.length > 0)}>
        <NotificationTail
          dismissable={dismissable}
          actions={actions}
          close={close}
        />
      </Conditional>
    </div>
  );
};

const NotificationTail: React.FC<{
  dismissable?: boolean;
  actions?: INotificationAction[];
  close: React.MouseEventHandler<Element>;
}> = ({ dismissable, actions, close }) => {
  const closeAction = useMemo(
    () => ({
      action: close,
      content: "Dismiss",
    }),
    []
  );

  return (
    <div className={styles.notificationTail}>
      {actions?.map((action) => (
        <TailAction key={action.content} action={action} />
      ))}
      <Conditional on={dismissable}>
        <TailAction action={closeAction} />
      </Conditional>
    </div>
  );
};

const TailAction: React.FC<{ action: INotificationAction }> = ({ action }) => {
  return (
    <div className={styles.notificationTailAction} onClick={action.action}>
      {action.content}
    </div>
  );
};
