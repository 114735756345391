import {
  ADD_ONLINE_MEMBER,
  CHANGED_BASE_MEMBER,
  REMOVE_ONLINE_MEMBER,
  SET_ACTIVE_WORKSPACE,
  SET_BASE_MEMBERS,
  SET_USER_IN_DICT,
} from "../actions";
import { IPendingCollaborator, IUserObj } from "utilities/types";
import _ from "lodash";
import { prevState } from "store/storeExporter";

interface ISetBaseMembersActionParameters {
  type: string;
  members: IUserObj[];
  pendingMembers: IPendingCollaborator[];
  onlineMembers?: string[];
  pendingGuests?: string[];
  newOnlineMember?: string;
  offlineMember?: string;
  changeType?: string;
  changedMember?: IPendingCollaborator;
  invitedMembers?: IPendingCollaborator[];
  addedMember?: IUserObj;
}

interface IUpdateBaseMembersDictParameters {
  type: string;
  param: {
    user: IUserObj;
  };
}

export interface MemberEntry {
  [key: string]: IUserObj;
}

export interface PendingEntry {
  [key: string]: IPendingCollaborator;
}

export interface IInitialMembersState {
  dict: MemberEntry;
  ids: string[];
  invitedDict: PendingEntry;
  invitedIds: string[];
  onlineMembers: string[];
  pendingGuests: string[];
}

const initialState: IInitialMembersState = {
  dict: {} as MemberEntry,
  ids: [],
  invitedDict: {} as PendingEntry,
  invitedIds: [],
  onlineMembers: [],
  pendingGuests: [],
};

const memberReducer = (
  state: IInitialMembersState = initialState,
  action: ISetBaseMembersActionParameters & IUpdateBaseMembersDictParameters
) => {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      return initialState;
    }
    case SET_BASE_MEMBERS: {
      const newState = {
        ...state,
        dict: {} as MemberEntry,
        ids: [] as string[],
        invitedDict: {} as PendingEntry,
        invitedIds: [] as string[],
        onlineMembers: [] as string[],
        pendingGuests: [] as string[],
      };

      if (action.members && Array.isArray(action.members)) {
        const ids = action.members.map((m) => m.id);
        newState.ids = _.uniq([...state.ids, ...ids]);
        const userId = prevState.value.user?.id;
        if (userId) {
          const index = newState.ids.indexOf(userId);
          if (index > -1) {
            newState.ids.splice(index, 1);
            newState.ids = [userId, ...newState.ids];
          }
        }
        action.members.forEach((member: IUserObj) => {
          if (state.dict?.[member.id]) {
            return (newState.dict[member.id] = {
              ...state.dict[member.id],
              ...member,
            });
          }
          return (newState.dict[member.id] = member);
        });
      }
      if (action.pendingMembers && Array.isArray(action.pendingMembers)) {
        const ids = action.pendingMembers.map((m) => m.id);
        newState.invitedIds = _.uniq([...state.invitedIds, ...ids]);
        action.pendingMembers.forEach((member: IPendingCollaborator) => {
          if (state.invitedDict?.[member.id]) {
            return (newState.invitedDict[member.id] = {
              ...state.invitedDict[member.id],
              ...member,
            });
          }
          return (newState.invitedDict[member.id] = member);
        });
      }
      if (action.pendingGuests && Array.isArray(action.pendingGuests)) {
        newState.pendingGuests = _.uniq([
          ...state.pendingGuests,
          ...action.pendingGuests,
        ]);
      }
      if (action.onlineMembers && Array.isArray(action.onlineMembers)) {
        newState.onlineMembers = [...action.onlineMembers];
      }

      return newState;
    }

    case CHANGED_BASE_MEMBER:
      if (action.changeType === "ACCEPTED_INVITE") {
        const newState = {
          ...state,
        };
        if (
          action.changedMember &&
          newState.invitedDict[action.changedMember?.id]
        ) {
          const { [action.changedMember?.id]: value, ...removedInvitedMember } =
            newState.invitedDict;
          newState.invitedDict = removedInvitedMember;
          const idIndex = newState.invitedIds.indexOf(action.changedMember?.id);
          const newIds = newState.invitedIds.slice(idIndex, idIndex);
          newState.invitedIds = newIds;

          newState.dict[action.changedMember?.invited?.id] = {
            ...action.changedMember.invited,
          };
          newState.ids = [...newState.ids, action.changedMember?.invited?.id];
          return newState;
        } else {
          return newState;
        }
      }
      if (action.changeType === "ADDED_USER") {
        const newState = {
          ...state,
        };
        if (action.addedMember) {
          newState.dict[action.addedMember?.id] = {
            ...action.addedMember,
          };
          newState.ids = [...newState.ids, action.addedMember?.id];
        }
        return newState;
      } else if (action.changeType === "INVITED_USER") {
        const newState = {
          ...state,
        };
        if (action.pendingGuests && Array.isArray(action.pendingGuests)) {
          newState.pendingGuests = [
            ...newState.pendingGuests,
            ...action.pendingGuests,
          ];
        }
        if (action.invitedMembers && Array.isArray(action.invitedMembers)) {
          action.invitedMembers.forEach((invitedMember) => {
            newState.invitedDict = {
              [invitedMember.id]: invitedMember,
              ...newState.invitedDict,
            };
          });

          newState.invitedIds = [
            ...newState.invitedIds,
            ...action.invitedMembers?.map((invited) => invited.id),
          ];
          return newState;
        } else {
          return newState;
        }
      } else if (action.changeType === "REMOVE_INVITE") {
        const newState = { ...state };
        if (action.changedMember) {
          const { [action.changedMember?.id]: value, ...removedInvitedMember } =
            newState.invitedDict;
          newState.invitedDict = removedInvitedMember;
          const newIds = newState.invitedIds.filter(
            (id) => id !== action.changedMember?.id
          );
          newState.invitedIds = newIds;
          return newState;
        }
        return newState;
      } else if (action.changeType === "REMOVED_USER") {
        const newState = { ...state };
        if (action.changedMember) {
          const { [action.changedMember?.id]: value, ...removedInvitedMember } =
            newState.dict;
          newState.dict = removedInvitedMember;
          const newIds = newState.ids.filter(
            (id) => id !== action.changedMember?.id
          );
          newState.ids = newIds;
          return newState;
        }
        return newState;
      } else if (action.changeType === "UPDATED_USER") {
        const newState = { ...state };
        if (action.changedMember) {
          newState.dict[action.changedMember.id] = {
            ...newState.dict[action.changedMember.id],
            ...action.changedMember,
          };
        }
        return newState;
      } else {
        return state;
      }

    case SET_USER_IN_DICT: {
      const newState = { ...state };
      newState.dict = { ...newState.dict };
      const user = action.param?.user;
      if (!user) return state;
      newState.dict[user.id] = user;
      return newState;
    }

    case REMOVE_ONLINE_MEMBER: {
      const newState = {
        ...state,
        onlineMembers: [...state.onlineMembers],
      };

      if (action.offlineMember) {
        const newOnlineMembers = newState.onlineMembers.filter(
          (i) => i !== action.offlineMember
        );

        newState.onlineMembers = newOnlineMembers;
      }

      return newState;
    }

    case ADD_ONLINE_MEMBER: {
      const newState = {
        ...state,
        onlineMembers: [...state.onlineMembers],
      };

      if (action.newOnlineMember) {
        const newOnlineMembers = _.uniq([
          ...newState.onlineMembers,
          action.newOnlineMember,
        ]);

        newState.onlineMembers = newOnlineMembers;
      }

      return newState;
    }
    default:
      return state;
  }
};

export default memberReducer;
