import { groupApi } from "clientApi/groupsApi";
import _ from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  ADD_GROUP,
  CHANGE_USER_GROUP,
  DELETE_GROUP,
  SET_BASE_GROUPS,
  SET_USER_GROUPS,
  UPDATE_GROUP,
  UNARCHIVE_GROUP,
} from "store/actions";
import { ClarityStore } from "store/storeExporter";
import {
  updateSection,
  updateSectionPin,
  updateSectionPinsOrder,
} from "utilities/homeUtilities";
import { useShallowSelector } from "utilities/hooks";
import {
  HomeScreenObj,
  HomeSectionDict,
  HomeSectionObj,
  HomeSectionRow,
  IGroup,
} from "utilities/types";

export interface GroupEntry {
  [key: string]: IGroup;
}

export interface IInitialState {
  dict: GroupEntry;
  slugDict: GroupEntry;
  ids: string[];
  archivedGroupids: string[];
  userGroups: string[];
  activeGroupids: string[];
}

const initialState: IInitialState = {
  dict: {} as GroupEntry,
  slugDict: {} as GroupEntry,
  ids: [],
  userGroups: [],
  archivedGroupids: [],
  activeGroupids: [],
};

interface ISetBaseGroupActionParameters {
  type: string;
  groups: IGroup[];
}

interface IAddNewGroup {
  newGroup: IGroup;
}

interface IUpdateGroup {
  updatedGroup: IGroup;
}
interface IDeleteGroup {
  deletedGroupId: string;
}

interface IArchiveGroup {
  archiveGroupId: string;
}

interface ISetUserGroups {
  userGroups: string[];
}

interface IChangeUserGroup {
  changeType: "joined" | "left";
  groupId: string;
  group: IGroup;
}

export default function groupReducer(
  state: IInitialState = initialState,
  action: ISetBaseGroupActionParameters &
    IAddNewGroup &
    IUpdateGroup &
    ISetUserGroups &
    IChangeUserGroup &
    IDeleteGroup &
    IArchiveGroup
) {
  switch (action.type) {
    case SET_BASE_GROUPS: {
      const newState: IInitialState = {
        ...initialState,
        userGroups: [...state.userGroups],
        dict: { ...state.dict },
      };

      if (action.groups && Array.isArray(action.groups)) {
        const ids = action.groups.map((group) => group.id);

        newState.ids = _.uniq(ids);

        newState.activeGroupids = [];
        newState.archivedGroupids = [];
        for (const group of action.groups) {
          if (!group.deleted) {
            newState.activeGroupids.push(group.id);
          }
          if (group.deleted) {
            newState.archivedGroupids.push(group.id);
          }
          if (newState.dict[group.id]) {
            newState.dict[group.id] = {
              ...newState.dict[group.id],
              ...group,
            };
            newState.slugDict[group.slug] = {
              ...newState.slugDict[group.slug],
              ...group,
            };
          } else {
            newState.dict[group.id] = {
              ...group,
            };
            newState.slugDict[group.slug] = {
              ...group,
            };
          }
        }

        const sortedUserGroups = newState.userGroups;
        sortedUserGroups.sort((a, b) =>
          newState.dict[a].rank.localeCompare(newState.dict[b].rank, "en")
        );
        newState.userGroups = sortedUserGroups;
        return newState;
      }
      return newState;
    }
    case ADD_GROUP: {
      const newState: IInitialState = {
        ...state,
        dict: { ...state.dict },
        slugDict: { ...state.slugDict },
      };

      if (action.newGroup) {
        newState.ids = _.uniq([...newState.ids, action.newGroup.id]);
        if (newState.activeGroupids.indexOf(action.newGroup.id) === -1) {
          newState.activeGroupids.push(action.newGroup.id);
        }
        newState.dict[action.newGroup.id] = {
          ...action.newGroup,
        };
        newState.slugDict[action.newGroup.slug] = {
          ...action.newGroup,
        };
      }

      return newState;
    }
    case UNARCHIVE_GROUP: {
      const newState: IInitialState = {
        ...state,
        dict: { ...state.dict },
        archivedGroupids: [...state.archivedGroupids],
        activeGroupids: [...state.activeGroupids],
      };
      if (action.archiveGroupId) {
        let index = newState.archivedGroupids.indexOf(action.archiveGroupId);
        if (index > -1) {
          newState.archivedGroupids.splice(index, 1);
          newState.activeGroupids.push(action.archiveGroupId);
        }
      }
      return newState;
    }
    case UPDATE_GROUP: {
      const newState: IInitialState = {
        ...state,
        dict: { ...state.dict },
        slugDict: { ...state.slugDict },
        ids: [...state.ids],
      };

      if (action.updatedGroup && newState.dict[action.updatedGroup.id]) {
        newState.dict[action.updatedGroup.id] = {
          ...newState.dict[action.updatedGroup.id],
          ...action.updatedGroup,
        };
        newState.slugDict[action.updatedGroup.slug] = {
          ...newState.slugDict[action.updatedGroup.slug],
          ...action.updatedGroup,
        };
        const groups = Object.values(newState.dict);

        newState.ids = groups
          .sort((a, b) => a.rank.localeCompare(b.rank, "en"))
          .map((group) => group.id);
      }
      return newState;
    }

    case DELETE_GROUP: {
      const newState: IInitialState = {
        ...state,
        dict: { ...state.dict },
        slugDict: { ...state.slugDict },
        userGroups: [...state.userGroups],
      };
      if (action.deletedGroupId && newState.dict[action.deletedGroupId]) {
        newState.ids = newState.ids.filter(
          (id) => id !== action.deletedGroupId
        );
        let index = newState.activeGroupids.indexOf(action.deletedGroupId);
        newState.activeGroupids.splice(index, 1);
        newState.archivedGroupids.push(action.deletedGroupId);
        newState.dict[action.deletedGroupId] = {
          ...newState.dict[action.deletedGroupId],
          members: [],
          deleted: true,
        };
        newState.slugDict[newState.dict[action.deletedGroupId].slug] = {
          ...newState.dict[action.deletedGroupId],
          members: [],
          deleted: true,
        };
        newState.userGroups = newState.userGroups.filter(
          (groupId) => groupId !== action.deletedGroupId
        );
      }

      return newState;
    }
    case SET_USER_GROUPS: {
      const newState: IInitialState = {
        ...initialState,
      };
      if (action.userGroups && Array.isArray(action.userGroups)) {
        newState.userGroups = [...action.userGroups];
      }
      return newState;
    }

    case CHANGE_USER_GROUP: {
      const newState: IInitialState = {
        ...state,
        dict: {
          ...state.dict,
        },
        userGroups: [...state.userGroups],
        slugDict: {
          ...state.slugDict,
        },
      };

      if (action.changeType === "joined") {
        if (!newState.userGroups.includes(action.groupId)) {
          newState.userGroups = [...newState.userGroups, action.groupId];
        }
      } else if (action.changeType === "left") {
        newState.userGroups = newState.userGroups.filter(
          (groupId) => groupId !== action.groupId
        );
      }
      return newState;
    }
    default:
      return state;
  }
}

export function useGroups() {
  return useSelector(
    (state: ClarityStore) => ({
      groupDict: state.groups.dict,
      groupIds: state.groups.ids,
    }),
    shallowEqual
  );
}

export function useGroupHomeData(groupId: string) {
  return useShallowSelector((state) => {
    const group: IGroup = state.groups.dict[groupId];
    const { home } = group;
    if (!home) {
      return {
        tagline: "",
        sectionIds: [] as string[],
        sections: [],
        sectionsDict: {} as HomeSectionDict,
        home: {
          sectionIds: [] as string[],
          sectionsDict: {} as HomeSectionDict,
        } as HomeScreenObj,
      };
    }
    const { sectionsDict } = home;
    const tagline = group.description ?? "";
    const sectionIds = home.sectionIds ?? [];
    const sections = sectionIds.map((id) => sectionsDict[id]);
    return {
      tagline,
      sections,
      home,
      sectionIds,
      sectionsDict,
      group,
    };
  });
}

export function useGroupHomeUpdater() {
  const dispatch = useDispatch();

  return (home: any, groupId?: string) => {
    dispatch({
      type: UPDATE_GROUP,
      updatedGroup: {
        home: home,
        id: groupId,
      },
    });
  };
}

export function useDescriptionUpdater() {
  const dispatch = useDispatch();

  return (tagline: string) => {
    dispatch({
      type: UPDATE_GROUP,
      updatedGroup: {
        description: tagline,
      },
    });
  };
}

export function usePatchGroupProfile(groupId: string) {
  const group = useShallowSelector((store) => store.groups.dict[groupId]);

  return async (home: HomeScreenObj = group.home) => {
    if (JSON.stringify(home) === JSON.stringify(group.home)) return;
    home = {
      ...home,
    };

    await groupApi.updateGroupHome(home, groupId);
  };
}

export function useAddOrEditGroupPin(groupId: string) {
  const homeUpdates = usePatchGroupProfile(groupId);
  const group = useShallowSelector((store) => store.groups.dict[groupId]);
  if (!groupId) {
    return () => {};
  }
  return async (item: HomeSectionRow, sectionId: string) => {
    const updatedHome = updateSectionPin(group.home, item, sectionId);

    homeUpdates(updatedHome);
  };
}

export function useReorderGroupPins(groupId: string) {
  const homeUpdates = usePatchGroupProfile(groupId);
  const group = useShallowSelector((store) => store.groups.dict[groupId]);
  if (!groupId) {
    return () => {};
  }
  return async (items: string[], sectionId: string) => {
    const updatedHome = updateSectionPinsOrder(group.home, items, sectionId);

    homeUpdates(updatedHome);
  };
}

export function useAddOrEditGroupHomeSection(groupId: string) {
  const homeUpdates = usePatchGroupProfile(groupId);
  const group = useShallowSelector((store) => store.groups.dict[groupId]);

  return async (item: HomeSectionObj) => {
    const updatedHome = updateSection(group.home, item);
    homeUpdates(updatedHome);
  };
}

export function useReorderGroupHomeSection(groupId: string) {
  const homeUpdates = usePatchGroupProfile(groupId);
  const group = useShallowSelector((store) => store.groups.dict[groupId]);

  return async (items: string[]) => {
    const home = {
      ...group.home,
    };

    home.sectionIds = items;
    homeUpdates(home);
  };
}

export function useDeleteGroupProfileSection(groupId: string) {
  const homeUpdates = usePatchGroupProfile(groupId);
  const group = useShallowSelector((store) => store.groups.dict[groupId]);

  return async (id: string) => {
    const home = {
      ...group.home,
    };
    if (!home.sectionIds.includes(id) || !home.sectionsDict[id]) {
      return;
    }
    const newSectionIds = group.home.sectionIds.filter(
      (itemId) => itemId !== id
    );

    home.sectionIds = newSectionIds;
    delete home.sectionsDict[id];

    homeUpdates({ ...home, sectionIds: newSectionIds });
  };
}

export function useRemoveGroupPin(groupId: string) {
  const homeUpdates = usePatchGroupProfile(groupId);
  const group = useShallowSelector((store) => store.groups.dict[groupId]);
  if (!groupId) {
    return () => {};
  }
  return async (pinId: string, sectionId: string) => {
    const home = {
      ...group.home,
      sectionsDict: {
        ...group.home.sectionsDict,
      },
    };
    const section = {
      ...home.sectionsDict[sectionId],
    };

    const newPins = section.resourcesIds.filter((id) => id !== pinId);
    const updatedSection = { ...home.sectionsDict[sectionId] };
    updatedSection.resourcesIds = newPins;
    updatedSection.resourcesDict = { ...updatedSection.resourcesDict };
    delete updatedSection.resourcesDict[pinId];
    home.sectionsDict[section.id] = updatedSection;
    homeUpdates(home);
  };
}
