import React, { useMemo } from "react";
import MentionsAndRepliesInbox from "./MentionsAndRepliesInbox";

const InboxHoc: React.FC<any> = (props) => {
  return useMemo(
    () => <MentionsAndRepliesInbox mode={props.inboxMode} />,
    [props.inboxMode]
  );
};

export default InboxHoc;
