import { flashBlockSubject } from "App";
import {
  mouseDown,
  preventClick,
  selectionStarted,
  setPreventClick,
} from "components/Overlay";
import { executeTextSaveTimeout } from "editor/utils/blockActions";
import { blockApi } from "editor/utils/blockActionsApi";
import { unfoldAllAncestors } from "editor/utils/specificActions/collapseActions";
import { blockModeDelete } from "editor/utils/specificActions/deleteBlockActions";
import { checkAccordingBlock } from "editor/utils/specificActions/positionActions";
import React, { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { batch, RootStateOrAny, shallowEqual, useSelector } from "react-redux";
import { executeCopy } from "screens/Base";
import { FocusObject, FocusType } from "store/reducers/blockReducer";
import {
  getBlockById,
  getCurrentContext,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $documentMode, $focusOn } from "store/storeExporter";
import {
  moveCaretAtLineEnd,
  moveCaretToPreviousPosition,
} from "utilities/caretMovement";
import { ContainerTypes, DocumentModes, IBlockContext } from "utilities/types";

interface Props {
  context: IBlockContext;
  containerRef: React.MutableRefObject<HTMLDivElement | Element | null>;
}

export const BlockContextPass = React.createContext<IBlockContext>(
  {} as IBlockContext
);

export const BlockContextUpdater = React.createContext<
  (newBlockContext: IBlockContext) => void
>(() => {});

export interface ContextBlocks {
  [id: string]: React.MutableRefObject<HTMLDivElement | null>;
}

export const BlockContextRef = React.createContext<
  MutableRefObject<ContextBlocks>
>({ current: {} });

const BlockContext: React.FC<Props> = (props) => {
  const contextState = useSelector((state: RootStateOrAny) => {
    return {
      focusOn: state.blocks.focusOn,
      context: state.blocks.contexts[props.context.id],
    };
  }, shallowEqual);

  const contextBlockRefs = useRef<ContextBlocks>({});

  useEffect(() => {
    if (
      contextState.focusOn.focusPane === props.context.paneId &&
      contextState.focusOn.focusContext?.id === props.context.id &&
      contextState.focusOn.focusContext?.type === props.context.type
    ) {
      const focusObject: FocusObject = contextState.focusOn;
      const currentId = focusObject.focusBlockId;
      const selection = document.getSelection();
      if (selection && selection.toString().length > 0) return;
      if (props.containerRef?.current && currentId) {
        const focusedBlock: any = contextBlockRefs.current[currentId]?.current;
        if (focusedBlock) {
          (focusedBlock as HTMLDivElement).focus({ preventScroll: true });
          if ($documentMode.value === DocumentModes.BLOCK) {
            const selection = document.getSelection();
            selection?.removeAllRanges();
            return;
          }
          if (focusObject.type === FocusType.withDiff) {
            moveCaretAtLineEnd(
              focusedBlock,
              focusObject.diff ? focusObject.diff : 0
            );
            if (focusedBlock.innerText.length === 0) {
              const textNode = document.createTextNode(" ");
              focusedBlock.appendChild(textNode);
              const newRange = new Range();
              newRange.selectNode(textNode);

              const selection = document.getSelection();
              selection?.removeAllRanges();
              selection?.addRange(newRange);
              textNode.remove();
            }
          } else {
            moveCaretToPreviousPosition(
              focusedBlock,
              focusObject.caretPosition ? focusObject.caretPosition : 0
            );
          }
        }
      }
    }
  }, [contextState.focusOn.focusBlockId, contextState.focusOn.refocusToggle]);

  useEffect(() => {
    let wrapper: Element | null | undefined = null;
    let handleClick = (e: any) => {};
    if (props.context.type === "container" && props.context.canEdit) {
      handleClick = (e: any) => {
        const target = e.target;
        if (!preventClick || mouseDown) {
          batch(() => {
            if (
              target.className &&
              target.className.includes &&
              !target.closest(".Block") &&
              !target.closest(".discussion-handle") &&
              !target.closest(".mentions") &&
              !target.className.includes("citation") &&
              !target.className.includes("clarity-draggable") &&
              !target.className.includes("ant-dropdown-trigger") &&
              !target.closest(".ignoreBubbleUp") &&
              !selectionStarted
            ) {
              e.preventDefault();
              const target = e.currentTarget;
              const width = target.offsetWidth;
              const atEnd = e.pageX / width > 0.5 ? true : false;
              checkAccordingBlock(
                contextBlockRefs,
                props.context.id,
                e.pageY,
                atEnd
              );
            }
          });
        } else {
          setPreventClick(false);
          e.preventDefault();
        }
      };

      wrapper = props.containerRef.current?.closest(".document-wrapper");
      if (wrapper) {
        wrapper.addEventListener(
          "click",
          (e) => {
            handleClick(e);
          },
          { capture: true }
        );
        wrapper.addEventListener("cut", (event: any) => {
          if (
            $focusOn.value.focusBlockId &&
            $documentMode.value === DocumentModes.BLOCK
          ) {
            executeCopy(event);
            blockModeDelete({
              blockId: $focusOn.value.focusBlockId,
              context: props.context,
            });
          }
        });
      }
    }
    return () => {
      if (props.context.type === "container" && wrapper) {
        wrapper.removeEventListener(
          "click",
          (e) => {
            handleClick(e);
          },
          { capture: true }
        );

        wrapper.removeEventListener("cut", (event: any) => {
          if (
            $focusOn.value.focusBlockId &&
            $documentMode.value === DocumentModes.BLOCK
          ) {
            executeCopy(event);
            blockModeDelete({
              blockId: $focusOn.value.focusBlockId,
              context: props.context,
            });
          }
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!contextBlockRefs) return;
    if (
      props.context.type === "container" &&
      props.context.canEdit &&
      props.context.container.id !== "newComment"
    ) {
      const newState = store.getState().blocks;
      const context = getCurrentContext(newState, props.context.id);
      const firstBlockIndex =
        context.containerType === ContainerTypes.NOTE ? 1 : 0;
      const firstBlockId = context.state.rootBlocksIds[firstBlockIndex];
      const blockRef = contextBlockRefs.current[firstBlockId]?.current;
      $documentMode.next(DocumentModes.INSERT);
      if (blockRef) blockRef.focus();
    }
    return () => {
      if (props.context.type === "container") executeTextSaveTimeout();
    };
  }, []);

  useEffect(() => {
    if (props.context.type !== "container") return;
    const sub = flashBlockSubject.subscribe((flashObj: any) => {
      if (flashObj.id) {
        let id = flashObj.id;
        const stateObj = store.getState().blocks;
        if (
          flashObj.isDiscussion &&
          props.context.container.id === flashObj.containerId
        ) {
          for (const key of Object.keys(contextBlockRefs.current)) {
            const innerBlock = getBlockById(stateObj.dict, key);
            if (innerBlock.relatedEntities?.discussion?.id === flashObj.id) {
              id = key;
              flashBlockSubject.next({});
              break;
            }
          }
        }

        const block = getBlockById(stateObj.dict, id);

        if (block && block.containerId === props.context.container.id) {
          const prevSelected = document.getElementsByClassName("flash");
          Array.from(prevSelected).forEach((element) => {
            element.classList.remove("flash");
          });
          unfoldAllAncestors({ blockId: block.id, context: props.context });
          setTimeout(() => {
            if (
              contextBlockRefs.current[block.id] &&
              contextBlockRefs.current[block.id].current
            ) {
              const blockstr = contextBlockRefs.current[block.id].current;
              if (!blockstr) return;
              blockstr.focus();
              moveCaretToPreviousPosition(blockstr, 0);
              blockstr.classList.add("flash");
              if (flashObj.openComments) {
                blockApi.blockAction.commentOnBlock(id);
              }
            }
          }, 0);
        }
      }
    });
    return () => {
      if (sub && sub.unsubscribe) sub.unsubscribe();
    };
  }, []);

  return useMemo(() => {
    return (
      <BlockContextRef.Provider value={contextBlockRefs}>
        {props.children}
      </BlockContextRef.Provider>
    );
  }, []);
};

export default BlockContext;
