import { FlagOutlined } from "@ant-design/icons";
import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import Conditional from "components/Conditional";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import { useShallowSelector } from "utilities/hooks";
import {
  CommandPaletteContext,
  ContainerTypes,
  WorkTypes,
} from "utilities/types";
import styles from "../../containerDetailMetadata/containerDetailMetadata.module.scss";

const MilestoneRow: React.FC<{
  canEditEntity: boolean;
  milestoneId?: string | null;
  workType: WorkTypes;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ canEditEntity, workType, milestoneId, containerId, containerType }) => {
  const showMilestones = useShallowSelector(
    (store) => store.workspace.showMilestones
  );
  const milestoneName = useShallowSelector((store) =>
    milestoneId ? store.work.milestoneDict[milestoneId]?.name : null
  );
  const handleMilestoneClick = () =>
    openCommandPalette(CommandPaletteContext.MILESTONE, {
      selectedItemIds: [containerId],
      slectedItemsType: containerType,
    });

  return (
    <Conditional
      on={
        [WorkTypes.PROJECT, WorkTypes.INITIATIVE].includes(workType) &&
        showMilestones
      }
    >
      <MetadataBlock
        title="Milestone"
        disabled={!canEditEntity}
        infoText={milestoneName ?? <span className="disabled"> None </span>}
        onClick={handleMilestoneClick}
        prefix={
          <span className={milestoneName ? "" : "disabled"}>
            <MilestoneDisplay />
          </span>
        }
      />
    </Conditional>
  );
};

function MilestoneDisplay() {
  return (
    <div className={styles.cycleDisplay}>
      <FlagOutlined className={styles.icon} />
    </div>
  );
}

export default MilestoneRow;
