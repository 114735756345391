import React, { useEffect, useState } from "react";
import PortalPath from "clarity-ui/portalContainer/PortalPath";
import { Line, LineType, LineStatus } from "utilities/lineUtilities";
import ReadOnlyBlocksContainer from "./ReadOnlyBlocksContainer";
import store from "store/storeExporter";
import { ContainerTypes } from "utilities/types";
import { Checkbox } from "antd";
import WorkItem from "screens/base/main/workTwo/workSection/WorkItem";
import FoldButton from "shannon/FoldButton";
import { BlockObject } from "store/reducers/blockReducer";
import { getBlockTypeHandlersPosition } from "editor/blockContainer/Block";
import { getHtml } from "editor/utils/blockValueHelpers";
import { getClassNameFromType } from "screens/base/main/detailView/documentContainer/document/lineComponent/lineVariants/ReadOnlyBlock";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";
import { ChunkDestination } from "utilities/stateTypes";

interface Props {
  id: string;
  block: Line;
  blocksObject: BlockObject;
  parentListType: string;
  parentId: string;
  indentLevel: number;
  linkedEntity?: any;
  allowToggle?: boolean;
  isFirst?: boolean;
  fromNote?: boolean;
  toggleMode?: string;
  ignoreType?: boolean;
  unlinked?: boolean;
  linkButton?: any;
  paneId: ChunkDestination;
}

const checkIfRelatedPages = (props: Line) => {
  if (
    props.lineType === LineType.Title &&
    props.containerType === ContainerTypes.NOTE
  ) {
  }
  return "";
};

export const getSubLinesListTypeReadOnly = (props: Props) => {
  if (props.parentListType === "bullet-1") {
    if (props.block.subLinesList === LineType.numberedList) return "numbered-1";
    else return "bullet-2";
  }
  if (props.parentListType === "bullet-2") {
    if (props.block.subLinesList === LineType.numberedList) return "numbered-1";
    else return "bullet-3";
  }
  if (props.parentListType === "bullet-3") {
    if (props.block.subLinesList === LineType.numberedList) return "numbered-1";
    else return "bullet-1";
  }
  if (props.parentListType === "numbered-1") {
    if (props.block.subLinesList === LineType.numberedList) return "numbered-2";
    else return "bullet-1";
  }
  if (props.parentListType === "numbered-2") {
    if (props.block.subLinesList === LineType.numberedList) return "numbered-1";
    else return "bullet-1";
  }
  if (props.block.subLinesList === LineType.numberedList) return "numbered-1";
  if (props.block.subLinesList === LineType.bulletedList) return "bullet-1";
  return "bullet-1";
};

const checkDefaultToggle = (props: Props) => {
  if (props.allowToggle) {
    if (props.toggleMode && props.toggleMode === "default")
      return props.block.isFolded;
    if (props.indentLevel > 0 || props.fromNote) return true;
  }
  return false;
};

const getBlockTypeHandlersPositionMiddle = (lineData: any, props: Props) => {
  return getBlockTypeHandlersPosition(lineData, true);
};

const ReadOnlyBlock: React.FC<Props> = (props) => {
  useEffect(() => {
    if (props.toggleMode) {
      setisFolded(checkDefaultToggle(props));
    }
  }, [props.block.isFolded]);

  const [isFolded, setisFolded] = useState(checkDefaultToggle(props));

  // if (props.unlinked) return defaultUnlinkedMention(props.block, props);

  if (props.block.ancestors && props.block.lineType === LineType.work) {
    return (
      <>
        {props.block.ancestors && (
          <PortalPath
            ancestors={props.block.ancestors}
            linkedEntity={props.linkedEntity}
            linkTagIcon={<CheckCircleTwoTone />}
          />
        )}
      </>
    );
  }
  return (
    <>
      {props.block.ancestors && props.block.ancestors?.length > 0 && (
        <PortalPath
          ancestors={props.block.ancestors}
          linkedEntity={props.linkedEntity}
        />
      )}
      {/* {props.block.containerType === ContainerTypes.NOTE &&
        props.block.lineType === LineType.Title && (
          <div style={{ marginTop: "-26px" }}></div>
        )} */}
      <div
        style={{
          position: "relative",
          marginLeft: props.block.indentLevel === 0 ? "-8px" : "-16px",
        }}
        className={"single-row row" + checkIfRelatedPages(props.block)}
      >
        <div className="section-big">
          {props.block.lineType !== LineType.tagsBlock ? (
            <div
              className="Block"
              style={getBlockTypeHandlersPositionMiddle(props.block, props)}
            >
              <div
                className="foldButton-container"
                style={{
                  marginLeft: "1px",
                  width: "20px",
                  minWidth: "20px",
                  paddingTop: "5px",
                }}
              >
                {props.allowToggle && props.block.children?.length > 0 && (
                  <FoldButton
                    isFolded={isFolded}
                    onClick={() => setisFolded(!isFolded)}
                  />
                )}
              </div>
              {!(
                props.block.lineType === LineType.Title &&
                props.block.containerType === ContainerTypes.NOTE
              ) && (
                <div
                  className="Block__numeralContainer"
                  style={{
                    marginRight: "3px",
                    marginTop: "1px",
                    position: "relative",
                  }}
                >
                  <span className="Block__numeralContainer__numeral"></span>
                </div>
              )}
            </div>
          ) : (
            <span style={{ width: "8px" }}></span>
          )}
          <div className="flex-section">
            {props.block.lineType === LineType.checkbox && (
              <Checkbox
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.preventDefault();
                }}
                style={{
                  paddingRight: "3px",
                  zIndex: 81,
                }}
                checked={props.block.checkboxStatus === LineStatus.done}
                disabled={false}
                indeterminate={
                  props.block.checkboxStatus === LineStatus.inProgress
                }
                onChange={(e) => {
                  return;
                }}
              ></Checkbox>
            )}
            {checkBlockTypeCases(props.block, props)}
            {props.block.children &&
              props.block.children.length > 0 &&
              !isFolded && (
                <ol
                  className={"list-type-" + getSubLinesListTypeReadOnly(props)}
                  style={{
                    marginTop: "-2px",
                    marginBottom: "2px",
                    marginLeft: "-12px",
                    paddingLeft: "4px",
                  }}
                >
                  <ReadOnlyBlocksContainer
                    blocksObject={props.blocksObject}
                    parentListType={getSubLinesListTypeReadOnly(props)}
                    allowToggle={props.allowToggle}
                    indentLevel={props.indentLevel + 1}
                    blockIds={props.block.children}
                    parentId={props.id}
                    paneId={props.paneId}
                  />
                </ol>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const checkBlockTypeCases = (block: Line, props: Props) => {
  if (props.ignoreType || props.unlinked)
    return (
      <div
        className="unlinked-mention"
        style={{ flexGrow: 1, display: "flex" }}
        id={"unlinked-" + block.id}
      >
        {defaultInnerBlock(block, props)}
      </div>
    );

  switch (block.lineType) {
    // eslint-disable-next-line no-fallthrough
    case LineType.work: {
      const storeData = store.getState();
      if (
        block.referencingContainerId &&
        storeData.work.dict[block.referencingContainerId]
      )
        return (
          <React.Fragment key={block.id}>
            <WorkItem
              key={block.id}
              index={0}
              isWorkBlock={true}
              item={storeData.work.dict[block.referencingContainerId]}
              handleTaskClick={null}
              reorder={false}
              type={"link"}
            />
          </React.Fragment>
        );
      else return <></>;
    }

    case LineType.Title: {
      const storeData = store.getState();
      if (
        block.containerType !== ContainerTypes.NOTE &&
        block.referencingContainerId &&
        storeData.work.dict[block.referencingContainerId]
      )
        return (
          <React.Fragment key={block.id}>
            <WorkItem
              key={block.id}
              index={0}
              item={storeData.work.dict[block.referencingContainerId]}
              handleTaskClick={null}
              reorder={false}
              type={"entity"}
            />
          </React.Fragment>
        );
      else return <>{defaultInnerBlock(block, props)}</>;
    }
    // eslint-disable-next-line no-fallthrough
    case LineType.image: {
      const storeData = store.getState();
      if (block.referencingContainerId)
        return (
          <img
            alt=""
            style={{
              maxHeight: "600px",
              maxWidth: "100%",
              verticalAlign: "center",
            }}
            src={
              storeData.workspace.resources?.[block.referencingContainerId]?.url
            }
          />
        );
      return <>{defaultInnerBlock(block, props)}</>;
    }
    // eslint-disable-next-line no-fallthrough
    default: {
      return <>{defaultInnerBlock(block, props)}</>;
    }
  }
};

const defaultInnerBlock = (block: Line, props: Props) => {
  return (
    <div
      // id={props.id}
      contentEditable={false}
      tabIndex={0}
      className={`content-section ${getClassNameFromType(block.lineType)} ${
        block.lineType === LineType.checkbox && block.checkboxStatus === "Done"
          ? "clarity-checkbox-done"
          : ""
      }`}
      onMouseDown={(e: any) => {
        if (e.target?.closest("mention")) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onClick={(e: any) => {
        if (!e.target?.closest("mention")) {
          e.stopPropagation();
          // e.preventDefault();
        }
        if (e.target.closest("a")) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      dangerouslySetInnerHTML={{ __html: getHtml(props.block.value, {}) }}
      style={{
        paddingLeft:
          props.block.lineType === LineType.checkbox
            ? 8 + "px"
            : props.block.indentLevel > 0
            ? 3 + "px"
            : 0,
        caretColor: "transparent",
        maxWidth:
          props.block.lineType === LineType.checkbox
            ? `calc(100% - 18px)`
            : `calc(100% - 38px)`,
        width:
          props.block.lineType === LineType.checkbox
            ? `calc(100% - 24px)`
            : `100%`,
      }}
      // onBeforeInput={(e) => {
      //   e.stopPropagation();
      //   e.preventDefault();
      // }}
      // onInput={(e) => e.preventDefault()}
    ></div>
  );
};

export default ReadOnlyBlock;
