import * as actionTypes from "../actions";
import { batch } from "react-redux";
import {
  ContainerTypes,
  IDocumentCreationMethods,
  INotesObj,
  IPageObj,
} from "utilities/types";
import { prepareStateForFrontend } from "store/reducers/workReducer";
import store from "store/storeExporter";
import { handleNewDocumentCreation } from "modules/documentService";
import { axiosInstance } from "index";
import { ILineValue, LineValueType } from "utilities/lineUtilities";

const valueUpdateChecker =
  (store: any) => (next: any) => async (action: any) => {
    if (
      action.type === actionTypes.LOAD_BASE_NOTES ||
      action.type === actionTypes.LOAD_NEXT_NOTE_BATCH
    ) {
      handleNoteBlocksLoad(action);
    }
    if (action.type === actionTypes.ADD_NEW_NOTE) {
      prepareSingleNoteBlocks(action.note);
    }
    if (action.type === actionTypes.CHECK_TITLE_UPDATE) {
      await checkTagsForDocRename(store, action, next);
      return;
    }

    const result = next(action);
    return result;
  };

const checkTagsForDocRename = async (store: any, action: any, next: any) => {
  const state = store.getState();
  const tagsToLookThrough = [...action.param.tags].reverse();
  const docsToAdd: any = [];
  const path =
    action.param.containerType === ContainerTypes.PROJECT
      ? "project"
      : "document";
  for (const tag of tagsToLookThrough) {
    if (tag.text === "") continue;
    const found = checkIfNewTag(state, tag.text);
    if (!found) {
      await handleNewDocumentCreation(
        state.workspace.id,
        IDocumentCreationMethods.TAG,
        tag.text.trim()
      ).then(async (resp) => {
        await axiosInstance({
          method: "post",
          url: `/api/document-mentions/create_mention`,
          data: {
            lineId: action.param.titleBlockId,
            referencedDocument: resp.data.id,
          },
        }).then(async (rs) => {
          tag.el.linkTo = resp.data.id;
          const newTitleValue: ILineValue[] = [
            {
              type: LineValueType.title,
              value: tag.el.value,
              children: [...tag.el.children],
            },
          ];
          await axiosInstance
            .patch("/api/document/rename", {
              id: resp.data.id,
              name: tag.text.trim(),
              nameValue: newTitleValue,
              ignoreDateModified: true,
            })
            .then(({ data }) => {
              docsToAdd.push({ document: data, type: "add" });
            });
        });
      });
    } else {
      if (true) {
        tag.el.linkTo = found.id;
        tag.text = tag.text.trim();
        const newTitleValue: ILineValue[] = [
          {
            type: LineValueType.title,
            value: tag.el.value,
            children: [...tag.el.children],
          },
        ];
        await axiosInstance({
          method: "post",
          url: `/api/document-mentions/create_mention`,
          data: {
            lineId: action.param.titleBlockId,
            referencedDocument: found.id,
          },
        }).then(async (ress) => {
          tag.el.linkTo = found.id;
          await axiosInstance
            .patch("/api/document/rename", {
              id: found.id,
              name: tag.text.trim(),
              nameValue: newTitleValue,
            })
            .then(({ data }) => {
              tag.el.linkTo = found.id;
              docsToAdd.push({ document: data, type: "update" });
            });
        });
      }
    }
  }
  batch(() => {
    for (const doc of docsToAdd) {
      next({
        type:
          doc.type === "add"
            ? actionTypes.ADD_NEW_DOCUMENT
            : actionTypes.UPDATE_DOCUMENT_NAME,
        document: doc.document,
      });
    }
  });
  const id = action.param.document
    ? action.param.document.id
    : action.param.project.id;
  await axiosInstance
    .patch(`/api/${path}/rename`, {
      id,
      name: action.param.text,
      nameValue: action.param.value,
      refocus: true,
    })
    .then(({ data }) => {
      if (action.param.containerType === ContainerTypes.DOCUMENT) {
        next({
          type: actionTypes.UPDATE_DOCUMENT_NAME,
          document: data,
        });
      }
      if (action.param.containerType === ContainerTypes.PROJECT) {
        next({
          type: actionTypes.UPDATE_WORK_ITEM,
          workItem: data.payload,
        });
      }
    });

  const block = action.param.titleRef;
  const nowTags = block?.querySelectorAll("mention");

  nowTags?.forEach((tt: any) => {
    for (const tag of tagsToLookThrough) {
      let tagText;
      if (tag.el.options && tag.el.options.hashtag)
        tagText = tt.textContent?.substr(1);
      else tagText = tt.textContent;
      if (tagText?.trim() === tag.text.trim()) {
        tt.setAttribute("href", tag.el.linkTo);
        tt.classList.remove("not-active");
        break;
      }
    }
  });
  return;
};

const checkIfNewTag = (state: any, tag: string): any | undefined => {
  const documents: any = state.pages.dict;
  const documentArray: IPageObj[] = Object.values(documents);
  for (const document of documentArray) {
    if (tag.trim().toLowerCase() === document.name) return document;
  }
  return false;
};

const prepareSingleNoteBlocks = (note: INotesObj, isFullyLoaded?: boolean) => {
  note.blocksState = prepareStateForFrontend(note.linesArray);
  store.dispatch({
    type: actionTypes.SET_BLOCKS_FROM_CONTAINER,
    param: {
      container: {
        containerId: note.id,
        containerType: ContainerTypes.NOTE,
        outlineMode: note.outlineMode,
      },
      blocks: note.blocksState.lineObject.entities.lines,
      rootLinesId: note.blocksState.lineObject.rootLinesId,
      notFullyLoad: isFullyLoaded ? false : true,
    },
  });
};

export const handleNoteBlocksLoad = (action: any) => {
  if (action.params.notes && Array.isArray(action.params.notes)) {
    batch(() => {
      action.params.notes.map((note: INotesObj) => {
        prepareSingleNoteBlocks(note, false);
        return note;
      });
    });
  }
};

export default valueUpdateChecker;
