import notificationsApi from "clientApi/notificationsApi";
import { templatesApi } from "clientApi/templateApi";
import workApi from "clientApi/workApi";
import { moveToContainerEnd } from "editor/utils/specificActions/moveActions";
import { flatMap } from "lodash";
import { Moment } from "moment";
import { batch } from "react-redux";
import {
  HIDE_COMMAND_PALETTE,
  REFOCUS,
  SET_COMMAND_PALETTE_CONTEXT,
} from "store/actions";
import store, { $focusOn } from "store/storeExporter";
import {
  CommandPaletteContext,
  ContainerTypes,
  IProjectObj,
  IWorkStatus,
  WorkTypes,
} from "utilities/types";

export const setCommandPaletteContext = (context: CommandPaletteContext) =>
  store.dispatch({ type: SET_COMMAND_PALETTE_CONTEXT, context });

export const hideCommandPalette = () =>
  store.dispatch({ type: HIDE_COMMAND_PALETTE });

export const generalBlockRefocus = () => {
  setTimeout(() => {
    store.dispatch({
      type: REFOCUS,
      param: {
        newFocus: { ...$focusOn.value },
      },
    });
  }, 0);
};

export const renderPlaceholder = (contextType: CommandPaletteContext) => {
  switch (contextType) {
    case CommandPaletteContext.OPEN: {
      const baseName = store.getState().workspace.name;
      return `Search tags & work in ${baseName}`;
    }
    case CommandPaletteContext.STATUS:
      return "Select status...";
    case CommandPaletteContext.PRIORITY:
      return "Select priority...";
    case CommandPaletteContext.FILTER_ASSIGNEE:
      return "Select assignees to include...";
    case CommandPaletteContext.FILTER_ASSIGNEE_NOT:
      return "Select assignees to exclude...";
    case CommandPaletteContext.FILTER_LABEL:
      return "Select tags to include...";
    case CommandPaletteContext.FILTER_LABEL_NOT:
      return "Select tags to exclude...";
    case CommandPaletteContext.FILTER_PRIORITY:
      return "Select priorities to include...";
    case CommandPaletteContext.FILTER_PRIORITY_NOT:
      return "Select priorities to exclude...";
    case CommandPaletteContext.FILTER_CYCLE:
      return "Select sprints to include...";
    case CommandPaletteContext.FILTER_CYCLE_NOT:
      return "Select sprints to exclude...";
    case CommandPaletteContext.FILTER_STATUS:
      return "Select statuses to include...";
    case CommandPaletteContext.FILTER_STATUS_NOT:
      return "Select statuses to exclude...";
    case CommandPaletteContext.FILTER_PARENT:
      return "Select parents to include...";
    case CommandPaletteContext.FILTER_PARENT_NOT:
      return "Select parents to exclude...";
    case CommandPaletteContext.FILTER_GROUP:
      return "Select groups to include...";
    case CommandPaletteContext.FILTER_GROUP_NOT:
      return "Select groups to exclude...";
    default:
      return "Select a command...";
  }
};

const checkIsCreatingNewTask = () => {
  const newTaskContext = store.getState().client.newTaskContext;
  const creatingNewTask = newTaskContext?.presetData?.id ? true : false;
  return creatingNewTask;
};

const checkIsCreatingNewTemplate = () => {
  const newTemplateContext = store.getState().client.newTemplateContext;
  const creatingNewTemplate = newTemplateContext?.presetData?.id ? true : false;
  return creatingNewTemplate;
};

export const paletteGetSelectedEntities = () => {
  const commandPaletteParams = store.getState().commandPalette.params;
  const selectedEntities = store.getState().client.selectedEntities;

  if (commandPaletteParams.selectedItemIds) {
    return {
      ids: [...commandPaletteParams.selectedItemIds],
      type: commandPaletteParams.slectedItemsType,
    };
  } else {
    return {
      ids: [...selectedEntities.ids],
      type: selectedEntities.type,
    };
  }
};

export const handleSelectContributor = (contributorId: string | null) => {
  const { ids, type } = paletteGetSelectedEntities();

  if (type === ContainerTypes.WORK) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      batch(() => {
        ids.forEach((workId) => {
          const workStore = store.getState().work;
          const workItem = { ...workStore.dict[workId] };
          workItem.contributorIds = workItem.contributorIds
            ? [...workItem.contributorIds]
            : [];

          let currentContributorIndex = -1;

          workItem?.contributorIds?.forEach((currentContributorId, idx) => {
            if (currentContributorId === contributorId) {
              currentContributorIndex = idx;
            }
          });
          if (currentContributorIndex === -1) {
            if (contributorId !== null)
              workItem?.contributorIds?.push(contributorId);
          } else {
            workItem?.contributorIds?.splice(currentContributorIndex, 1);
          }

          workApi.update({ contributorIds: workItem?.contributorIds }, [
            workItem.id,
          ]);
        });
      });
    } else {
      const workStore = store.getState().work;
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      const updatedWorkItem = workStore.dict[newWorkItemId];
      let currentContributorIndex = -1;

      updatedWorkItem?.contributorIds?.forEach((currentContributorId, idx) => {
        if (currentContributorId === contributorId) {
          currentContributorIndex = idx;
        }
      });
      if (currentContributorIndex === -1) {
        if (contributorId !== null)
          updatedWorkItem?.contributorIds?.push(contributorId);
      } else {
        updatedWorkItem?.contributorIds?.splice(currentContributorIndex, 1);
      }
      workApi.update({ contributorIds: updatedWorkItem?.contributorIds }, [
        updatedWorkItem.id,
      ]);
    }
  }

  if (type === ContainerTypes.TEMPLATE) {
    const creatingNewTemplate = checkIsCreatingNewTemplate();
    if (!creatingNewTemplate) {
      batch(() => {
        ids.forEach((workId) => {
          const templatesDict = store.getState().templates.dict;
          const workItem = { ...templatesDict[workId] };
          workItem.contributorIds = workItem.contributorIds
            ? [...workItem.contributorIds]
            : [];
          let currentContributorIndex = -1;

          workItem?.contributorIds?.forEach((currentContributorId, idx) => {
            if (currentContributorId === contributorId) {
              currentContributorIndex = idx;
            }
          });
          if (currentContributorIndex === -1) {
            if (contributorId !== null)
              workItem?.contributorIds?.push(contributorId);
          } else {
            workItem?.contributorIds?.splice(currentContributorIndex, 1);
          }

          templatesApi.update({ contributorIds: workItem?.contributorIds }, [
            workItem.id,
          ]);
        });
      });
    } else {
      const templatesDict = store.getState().templates.dict;
      const newTemplateContext = store.getState().client.newTemplateContext;
      if (!newTemplateContext?.presetData?.id) return;
      const updatedTemplateItem =
        templatesDict[newTemplateContext.presetData.id];

      let currentContributorIndex = -1;

      updatedTemplateItem?.contributorIds?.forEach(
        (currentContributorId, idx) => {
          if (currentContributorId === contributorId) {
            currentContributorIndex = idx;
          }
        }
      );
      if (currentContributorIndex === -1) {
        if (contributorId !== null)
          updatedTemplateItem?.contributorIds?.push(contributorId);
      } else {
        updatedTemplateItem?.contributorIds?.splice(currentContributorIndex, 1);
      }
      workApi.update({ contributorIds: updatedTemplateItem?.contributorIds }, [
        updatedTemplateItem.id,
      ]);
    }
  }
};

export const handleSelectAssignee = (assigneeId: string | null) => {
  const { ids, type } = paletteGetSelectedEntities();

  if (type === ContainerTypes.WORK) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      workApi.update({ assigneeId }, ids);
    } else {
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      workApi.update({ assigneeId }, [newWorkItemId]);
    }
  }

  if (type === ContainerTypes.TEMPLATE) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      templatesApi.update({ assigneeId }, ids);
    } else {
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      templatesApi.update({ assigneeId }, [newWorkItemId]);
    }
  }
};

export const handleSelectGroup = (groupId: string) => {
  const { ids, type } = paletteGetSelectedEntities();
  if (type !== ContainerTypes.WORK) return;

  const creatingNewTask = checkIsCreatingNewTask();
  if (!creatingNewTask) {
    batch(() => {
      ids.forEach((workId) => {
        const workStore = store.getState().work;
        const workItem = { ...workStore.dict[workId] };
        let currentGroupIndex = -1;

        const currentChildren = workItem.childrenAggregate;
        let canRemoveGroup = true;

        for (const child of currentChildren) {
          const workChild = workStore.dict[child.id];

          if (
            (workItem?.groupIds?.includes(workChild.groupId) &&
              workChild.groupId === groupId) ||
            (workItem?.groupIds?.includes(groupId) &&
              workChild.groupIds &&
              workChild.groupIds.includes(groupId))
          ) {
            canRemoveGroup = false;
            break;
          }
        }
        if (!canRemoveGroup) {
          notificationsApi.displayError({
            body: "Cannot remove group: Subtasks of this project belong to this group",
          });
          return;
        }
        workItem?.groupIds?.forEach((currentGroupId, idx) => {
          if (currentGroupId === groupId) {
            currentGroupIndex = idx;
          }
        });
        if (currentGroupIndex === -1) {
          if (groupId !== null) workItem.groupIds?.push(groupId);
        } else {
          if (workItem.groupIds?.length === 1) {
            notificationsApi.displayError({
              body: "Cannot remove group: The project must have at least 1 group",
            });
            return;
          }
          workItem.groupIds?.splice(currentGroupIndex, 1);
        }
        if (workItem?.groupIds && workItem?.groupIds?.length < 1) {
          notificationsApi.displayError({
            body: "Cannot remove group: The project must have at least 1 group",
          });
          return;
        }
        workApi.update({ groupIds: workItem.groupIds }, [workItem.id]);
      });
    });
  } else {
    const workStore = store.getState().work;
    const newTaskContext = store.getState().client.newTaskContext;
    const newWorkItemId = newTaskContext?.presetData?.id;
    if (!newWorkItemId) return;
    const updatedWorkItem = workStore.dict[newWorkItemId];
    let currentGroupIndex = -1;

    if (updatedWorkItem.groupIds?.length === 1) {
      return;
    }

    updatedWorkItem?.groupIds?.forEach((currentGroupId, idx) => {
      if (currentGroupId === groupId) {
        currentGroupIndex = idx;
      }
    });
    if (currentGroupIndex === -1) {
      if (groupId !== null) updatedWorkItem.groupIds?.push(groupId);
    } else {
      updatedWorkItem.groupIds?.splice(currentGroupIndex, 1);
    }
    workApi.update({ groupIds: updatedWorkItem.groupIds }, [
      updatedWorkItem.id,
    ]);
  }
};

export const handleSelectSponsorGroup = (groupId: string) => {
  const creatingNewTask = checkIsCreatingNewTask();
  const { ids } = paletteGetSelectedEntities();
  if (!creatingNewTask) {
    const updatedWorkItems: IProjectObj[] = [];
    batch(() => {
      ids.forEach((workId) => {
        const workStore = store.getState().work;
        const workItem = { ...workStore.dict[workId] };
        if (workItem.reward) {
          workItem.reward.sponsorGroupId = groupId;
        }
        updatedWorkItems.push(workItem);
      });
    });
    workApi.dispatchMultipleUpdate(updatedWorkItems);
  }
};

export const handleSetDueDate = (value: Moment | null) => {
  const { ids, type } = paletteGetSelectedEntities();

  value?.set({ hour: 12, minutes: 0 });

  if (type === ContainerTypes.WORK) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      batch(() => {
        workApi.updateWorkItems(
          ids.map((workId) => {
            return { id: workId, dueDate: value?.toDate() };
          })
        );
      });
    } else {
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      const updatedWorkItem = {
        ...store.getState().work.dict[newWorkItemId],
      };
      updatedWorkItem.dueDate = value?.toDate();
      workApi.update({ dueDate: updatedWorkItem.dueDate }, [
        updatedWorkItem.id,
      ]);
    }
  }

  if (type === ContainerTypes.TEMPLATE) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      batch(() => {
        templatesApi.update(
          { dueDate: value ? value.toDate() : undefined },
          ids
        );
      });
    } else {
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      const updatedWorkItem = {
        ...store.getState().work.dict[newWorkItemId],
      };
      updatedWorkItem.dueDate = value?.toDate();
      templatesApi.update({ dueDate: updatedWorkItem.dueDate }, [
        updatedWorkItem.id,
      ]);
    }
  }

  hideCommandPalette();
};

export const handleSelectSection = (sectionId: string | null) => {
  const { ids, type } = paletteGetSelectedEntities();
  if (type !== ContainerTypes.WORK) return;

  const creatingNewTask = checkIsCreatingNewTask();
  if (!creatingNewTask) {
    batch(() => {
      workApi.update({ workSectionId: sectionId }, ids);
    });
  } else {
    const newTaskContext = store.getState().client.newTaskContext;
    const newWorkItemId = newTaskContext?.presetData?.id;
    if (!newWorkItemId) return;
    const updatedWorkItem = {
      ...store.getState().work.dict[newWorkItemId],
    };
    workApi.update({ workSectionId: sectionId }, [updatedWorkItem.id]);
  }
};

export const handleSelectMilestone = (milestoneId: string | null) => {
  const { ids, type } = paletteGetSelectedEntities();
  if (type === ContainerTypes.WORK) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      batch(() => {
        workApi.update({ milestoneId }, ids);
      });
    } else {
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      const updatedWorkItem = {
        ...store.getState().work.dict[newWorkItemId],
      };
      workApi.update({ milestoneId }, [updatedWorkItem.id]);
    }
  }

  if (type === ContainerTypes.TEMPLATE) {
    const creatingNewTemplate = checkIsCreatingNewTemplate();
    if (!creatingNewTemplate) {
      batch(() => {
        templatesApi.update({ milestoneId }, ids);
      });
    } else {
      const newTemplateContext = store.getState().client.newTemplateContext;
      const templateItemId = newTemplateContext?.presetData?.id;
      if (!templateItemId) return;
      templatesApi.update({ milestoneId }, [templateItemId]);
    }
  }
};

export const handleSelectReviewer = (reviewerId: string | null) => {
  const { ids, type } = paletteGetSelectedEntities();
  if (type === ContainerTypes.WORK) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      batch(() => {
        workApi.update({ reviewerId }, ids);
      });
    } else {
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      const updatedWorkItem = {
        ...store.getState().work.dict[newWorkItemId],
      };
      workApi.update({ reviewerId }, [updatedWorkItem.id]);
    }
  }

  if (type === ContainerTypes.TEMPLATE) {
    const creatingNewTemplate = checkIsCreatingNewTemplate();
    if (!creatingNewTemplate) {
      batch(() => {
        templatesApi.update({ reviewerId }, ids);
      });
    } else {
      const newTemplateContext = store.getState().client.newTemplateContext;
      const templateItemId = newTemplateContext?.presetData?.id;
      if (!templateItemId) return;
      templatesApi.update({ reviewerId }, [templateItemId]);
    }
  }
};

export const handleStatusChange = (newStatus: IWorkStatus) => {
  const { ids, type } = paletteGetSelectedEntities();
  if (type === ContainerTypes.WORK) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      batch(() => {
        workApi.update({ statusId: newStatus.id }, ids);
      });
    } else {
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      const updatedWorkItem = {
        ...store.getState().work.dict[newWorkItemId],
      };
      workApi.update({ statusId: newStatus.id }, [updatedWorkItem.id]);
    }
  }

  if (type === ContainerTypes.TEMPLATE) {
    const creatingNewTemplate = checkIsCreatingNewTemplate();
    if (!creatingNewTemplate) {
      batch(() => {
        templatesApi.update({ statusId: newStatus.id }, ids);
      });
    } else {
      const newTemplateContext = store.getState().client.newTemplateContext;
      const templateItemId = newTemplateContext?.presetData?.id;
      if (!templateItemId) return;
      templatesApi.update({ statusId: newStatus.id }, [templateItemId]);
    }
  }
};

export const handlePriorityChange = (newPriority: number) => {
  const { ids, type } = paletteGetSelectedEntities();
  if (type === ContainerTypes.WORK) {
    const creatingNewTask = checkIsCreatingNewTask();
    if (!creatingNewTask) {
      batch(() => {
        workApi.update({ priority: newPriority }, ids);
      });
    } else {
      const newTaskContext = store.getState().client.newTaskContext;
      const newWorkItemId = newTaskContext?.presetData?.id;
      if (!newWorkItemId) return;
      const updatedWorkItem = {
        ...store.getState().work.dict[newWorkItemId],
      };
      workApi.update({ priority: newPriority }, [updatedWorkItem.id]);
    }
  }

  if (type === ContainerTypes.TEMPLATE) {
    const creatingNewTemplate = checkIsCreatingNewTemplate();
    if (!creatingNewTemplate) {
      batch(() => {
        templatesApi.update({ priority: newPriority }, ids);
      });
    } else {
      const newTemplateContext = store.getState().client.newTemplateContext;
      const templateItemId = newTemplateContext?.presetData?.id;
      if (!templateItemId) return;
      templatesApi.update({ priority: newPriority }, [templateItemId]);
    }
  }
};

export const changeContainer = (targetContainer: {
  containerId: string;
  containerType: ContainerTypes;
}) => {
  const params = store.getState().commandPalette.params;
  if (params.blockData) {
    moveToContainerEnd({
      blockId: params.blockData?.id,
      context: params.context,
      targetContainer,
    });
  }
};

export const canChangeCycles = () => {
  const { ids } = paletteGetSelectedEntities();
  const state = store.getState();
  const groupIds = flatMap(ids, (workId: string) => {
    const workItem = state.work.dict[workId];
    if (!workItem) {
      return [];
    }
    if (workItem.workType === WorkTypes.TASK) {
      return [workItem.groupId];
    } else {
      return workItem.groupIds;
    }
  });
  const canChangeCycle = groupIds?.some((groupId: string | undefined) => {
    if (!groupId) {
      return false;
    }
    const group = state.groups.dict[groupId];
    return group.showCycles;
  });
  return canChangeCycle;
};
