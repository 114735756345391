import React, { useEffect, useRef, useCallback, useState } from "react";
import styles from "./home/groupOverview/groupOverview.module.scss";
import GroupPinsHoc from "./home/groupOverview/GroupPinsHoc";
import { ChunkDestination } from "utilities/stateTypes";
import {
  SectionNumber,
  useContainerWidth,
  useShallowSelector,
} from "utilities/hooks";
import GroupDetails from "./home/groupOverview/GroupDetails";
import { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities } from "utilities/types";
import {
  getActionTrigger,
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
  useBtnComponentsSetter,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import AddNewFabBtn from "components/AddNewFabBtn";
import { batch } from "react-redux";
import RightFloatBar from "./home/RightFloatBar";
import { useTranslucentBackground } from "./home/hooks";
import { SplitViewRef } from "../SplitView";
import { MainViewRef } from "../Main";
import GroupWidgets from "./home/groupOverview/GroupWidgets";
import { useActiveGoals } from "./home/widgets/ActiveGoals";
import { useActiveProjects } from "./home/widgets/ActiveProjects";

enum SectionWidthModes {
  small = "small",
  medium = "medium",
  large = "large",
}

const GroupOverview: React.FC<{
  paneId: ChunkDestination;
  groupSlug: string;
}> = ({ paneId, groupSlug }) => {
  const group = useShallowSelector((store) => store.groups.slugDict[groupSlug]);

  const elRef = useRef(null);
  const width = useContainerWidth(elRef);
  const ref = useRef<HTMLDivElement | null>(null);
  const [sectionWidthMode, setsectionWidthMode] = useState<SectionWidthModes>(
    SectionWidthModes.large
  );

  const { image } = useTranslucentBackground(
    paneId === ChunkDestination.secondary ? SplitViewRef : MainViewRef,
    group?.coverPhotoUrl
  );

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: group.id,
  });

  usePageDataSetter(paneId, {
    title: group?.name ? group?.name : "",
  });

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.focus();
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width > 1000)
          return setsectionWidthMode(SectionWidthModes.large);
        if (600 < entry.contentRect.width && entry.contentRect.width <= 1000)
          return setsectionWidthMode(SectionWidthModes.medium);
        if (entry.contentRect.width <= 600) {
          return setsectionWidthMode(SectionWidthModes.small);
        }
      }
    });
    if (ref.current) resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const getClassName = useCallback(() => {
    switch (sectionWidthMode) {
      case SectionWidthModes.large:
        return styles.large;
      case SectionWidthModes.medium:
        return styles.medium;
      case SectionWidthModes.small:
        return styles.small;
    }
  }, [sectionWidthMode]);

  useEffect(() => {
    batch(() => {
      setPaneSubroutes({
        paneId,
        subRoutesParams: {
          type: "group",
          groupId: group?.id,
          activeKey: "home",
        },
      });
      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.group,
      });
    });

    return () => {
      batch(() => {
        setPaneSubroutes({
          paneId,
        });
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, []);

  useBtnComponentsSetter(
    paneId,
    {
      btnComponents: [
        getActionTrigger({
          key: "create",
          standAloneCom: true,
          contents: () => (
            <AddNewFabBtn
              slim={true}
              hideWhenOpen={false}
              buttonType={ButtonTypes.PRIMARY}
              menuYDir="down"
              menuClass={styles.menu}
              menuXDir="left"
              containerClass={styles.addNewBtn}
              group={group}
              disabled={!canEditEntity}
            />
          ),
          onClick: (e: any) => {},
        }),
      ],
    },
    [group?.id]
  );

  const isGoalLength = useActiveGoals(group.id);
  const isProjectLength = useActiveProjects(group.id);
  const isGoalProjectCount =
    isGoalLength.length === 0 && isProjectLength.length === 0;

  return (
    <>
      <div
        className={`${
          styles.homePage
        } flex-fill page__mxWidth1355 ${getClassName()}`}
        ref={elRef}
      >
        <div ref={ref} className={`${styles.container} flex-fill`}>
          <div
            className={`${styles.content} content`}
            style={
              SectionNumber[width] <= 4
                ? {
                    gap: "16px",
                    flexDirection: "column-reverse",
                    paddingLeft: "48px",
                    paddingRight: "48px",
                  }
                : undefined
            }
          >
            <RightFloatBar
              card={false}
              fitContainer={SectionNumber[width] <= 4}
            >
              <GroupDetails paneId={paneId} groupId={group.id} />
            </RightFloatBar>
            <div className={styles.twoColContent}>
              <GroupWidgets
                paneId={paneId}
                groupId={group.id}
                fitContainer={SectionNumber[width] <= 2}
              />

              <RightFloatBar
                customStyle={
                  isGoalProjectCount ? styles.contentFull : styles.contentRight
                }
                card={false}
                fitContainer={SectionNumber[width] <= 2}
              >
                <GroupPinsHoc paneId={paneId} groupId={group.id} />
              </RightFloatBar>
            </div>
          </div>
        </div>
        {image && <TranslucentBackground image={image} />}
      </div>
    </>
  );
};

const TranslucentBackground: React.FC<{ image: string }> = ({ image }) => {
  return (
    <div
      data-background={image}
      style={{
        // "--bg-image": `url(${image})`,
        background: `url(${image})`,
      }}
      className="translucentBackground"
    ></div>
  );
};

export default GroupOverview;
