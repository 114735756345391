import { locationSubject } from "components/LocationListener";
import { axiosInstance } from "index";
import { Subject } from "rxjs";
import { db } from "./dbService";
export const subject = new Subject();

export enum ActionHttpType {
  POST = "post",
  GET = "get",
}

export interface IAction {
  url: string;
  params: any;
  type: ActionHttpType;
  timestamp?: number;
  retries: number;
}

class Queue {
  public _offlineMode: boolean = false;

  async enqueue(item: IAction) {
    const tryInput = async () => {
      const date = Date.now();
      await db.byTimeStamp.add({ ...item, timestamp: date, retries: 0 }, date);
    };

    try {
      await tryInput();
    } catch {
      await tryInput();
    }
  }

  async updateItem(item: IAction) {
    const tryUpdate = async () => {
      await db.byTimeStamp.put({ ...item }, item.timestamp);
    };

    try {
      await tryUpdate();
    } catch (e) {
      await tryUpdate();
    }
  }

  async clearDb() {
    await db.byTimeStamp.clear();
  }

  async dequeue() {
    const item = await db.table("byTimeStamp").orderBy("timestamp").first();

    if (item) {
      await db.byTimeStamp.delete(item.timestamp);
    }
    return item;
  }

  async executeItem() {
    const item = await db.table("byTimeStamp").orderBy("timestamp").first();
    return item;
  }
}

class ExecutionQueue extends Queue {
  private _working: boolean;
  constructor() {
    super();
    this._working = false;
  }

  async offlineMode(offline: boolean) {
    if (offline !== this._offlineMode) {
      this._offlineMode = offline;
      if (!this._offlineMode) {
        await this.dequeueItem();
      }
    }
  }

  async enqueueAction(action: IAction) {
    await super.enqueue(action);
    if (!this._offlineMode && !this._working) {
      await this.dequeueItem();
    }
  }

  async dequeueItem() {
    if (this._working) return false;
    if (this._offlineMode) return false;
    this._working = true;
    const item = await super.executeItem();

    if (!item) {
      this._working = false;
      return false;
    }

    if (item && item.retries >= 20) {
      this._working = false;
      await super.clearDb();
      locationSubject.next(`/error`);
      return false;
    }

    try {
      switch (item.type) {
        case ActionHttpType.POST:
          await axiosInstance.post(item.url, {
            ...item.params,
          });
      }
      await super.dequeue();
      this._working = false;
      await this.dequeueItem();
    } catch (e) {
      console.log("error", e);
      item.retries = item.retries + 1;
      await super.updateItem(item);
      this._working = false;
      this.dequeueItem();
    }
  }
}

export const executionQueue = new ExecutionQueue();
