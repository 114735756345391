import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { socket } from "App";
interface Props {
  containerId?: string;
  user: any;
  connected: boolean;
}

const usePrevious = <T extends Props>(value: T): any => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const setTimer = (props: Props, prevProps: any) => {
  socket.emit("changeBlockInDocument", {
    containerId: props.containerId,
    blockId: null,
    user: {
      id: props.user.id,
      email: props.user.email,
      name: props.user.name,
      username: props.user.username,
      avatar: props.user.avatar,
    },
  });
};

// const resetTimer = () => {
//   clearTimeout(timer);
// };

const DocumentChangesListener: React.FC<Props> = (props) => {
  const prevProps = usePrevious(props);
  const readableProps = useRef(props);
  useEffect(() => {
    readableProps.current = props;
  }, [props]);

  useEffect(() => {
    // resetTimer();
    setTimer(props, prevProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on("newUserInRoom", () => {
      if (prevProps?.currentBlockId) {
        socket.emit("changeBlockInDocument", {
          containerId: props.containerId,
          prevBlockId: prevProps?.currentBlockId,
          user: {
            id: props.user.id,
            email: props.user.email,
            name: props.user.name,
            username: props.user.username,
            avatar: props.user.avatar,
          },
        });
      }
    });
    if (prevProps?.currentBlockId) {
      socket.emit("changeBlockInDocument", {
        containerId: props.containerId,
        prevBlockId: prevProps?.currentBlockId,
        user: {
          id: props.user.id,
          email: props.user.email,
          name: props.user.name,
          username: props.user.username,
          avatar: props.user.avatar,
        },
      });
    }

    return () => {
      socket.removeListener("newUserInRoom");
      socket.emit("changeBlockInDocument", {
        containerId: props.containerId,
        blockId: null,
        user: {
          id: props.user.id,
          email: props.user.email,
          name: props.user.name,
          username: props.user.username,
          avatar: props.user.avatar,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.connected]);

  return <></>;
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    connected: state.network.connected,
  };
};

export default connect(mapStateToProps)(DocumentChangesListener);
