import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Abilities,
  ContainerTypes,
  UserRole,
  ViewNames,
} from "utilities/types";
import {
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import EmptyState from "clarity-ui/EmptyState";

import { SectionWidthModes, useShallowSelector } from "utilities/hooks";
import { batch } from "react-redux";
import { ChunkDestination } from "utilities/stateTypes";
import Conditional from "components/Conditional";
import style from "./notes/notes.module.scss";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import { AutoSizer } from "react-virtualized";
import { Virtuoso } from "react-virtuoso";
import {
  getCreatedString,
  getNameFromContainer,
} from "modules/containerHelpers";
import navigationApi from "clientApi/navigationApi";
import Button, { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import { PlusOutlined } from "@ant-design/icons";
import { snippetsApi } from "clientApi/snippetsApi";

const SnippetsList: React.FC<{ paneId: ChunkDestination }> = memo(
  ({ paneId }) => {
    usePageDataSetter(paneId, {
      title: "Snippets",
    });

    useEffect(() => {
      batch(() => {
        setPaneSubroutes({
          paneId,
          subRoutesParams: {
            type: "templates",
            activeKey: "snippets",
          },
        });
        setPaneTopNavViewType({
          paneId,
          navType: TopNavbarType.base,
        });
      });

      return () => {
        batch(() => {
          setPaneSubroutes({
            paneId,
          });
          setPaneTopNavViewType({
            paneId,
          });
        });
      };
    }, []);

    const snippetIds = useShallowSelector((store) => store.snippets.ids);
    const userRole = useShallowSelector((store) => store.client.roleType);

    const rowRenderer = useCallback(
      (index: number) => {
        const id = snippetIds[index];
        return <SnippetRow id={id} paneId={paneId} />;
      },
      [snippetIds, paneId]
    );

    const listRef = useRef<HTMLDivElement | null>(null);

    const widthMode = usePageWidth(listRef);

    const getClassName = useCallback(() => {
      switch (widthMode) {
        case SectionWidthModes.large:
          return style.large;
        case SectionWidthModes.medium:
          return style.medium;
        case SectionWidthModes.small:
          return style.small;
      }
    }, [widthMode]);

    return (
      <>
        <div
          ref={listRef}
          style={{ height: "100%", width: "100%" }}
          className={`${style.page} ${getClassName()}`}
        >
          <Conditional on={Boolean(userRole !== UserRole.GUEST)}>
            <TopNavFiltersBar paneId={paneId}>
              <AddSnippet />
            </TopNavFiltersBar>
          </Conditional>
          <div className={style.content}>
            {snippetIds.length === 0 ? (
              <EmptyState
                heading={""}
                caption={"There are no snippets created in this base"}
              />
            ) : (
              <>
                <Titles />
                <AutoSizer>
                  {({ width, height }) => {
                    return (
                      <>
                        <Virtuoso
                          className="scrollContentY"
                          totalCount={snippetIds.length}
                          width={width}
                          style={{
                            height: height + "px",
                            width: `${width + 8}px`,
                            marginLeft: "-8px",
                          }}
                          overscan={5}
                          height={height}
                          itemContent={(index) => rowRenderer(index)}
                        />
                      </>
                    );
                  }}
                </AutoSizer>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);

const SnippetRow: React.FC<{ id: string; paneId: ChunkDestination }> = ({
  id,
  paneId,
}) => {
  const snippetObj = useShallowSelector((store) => store.snippets.dict[id]);

  const navigate = useCallback(
    (e: React.MouseEvent) => {
      navigationApi.contextBasedNavigate({
        currentPane: paneId,
        navigationChunk: {
          viewName: ViewNames.Detail,
          entity: {
            containerId: id,
            containerType: ContainerTypes.SNIPPET,
          },
        },
      });

      e.stopPropagation();
      e.preventDefault();
    },
    [id, paneId]
  );

  if (!snippetObj) return <></>;
  return (
    <div className={style.NoteItem}>
      <div className={style.itemHead}>
        <span
          onClick={navigate}
          className={`body regular exerpt ${style.title}`}
        >
          {getNameFromContainer(snippetObj, ContainerTypes.SNIPPET)}
        </span>
      </div>
      <div className={style.itemTail}>
        <span
          className={`${style.field} ${style.column} ${style.date} caption regular secondary exerpt`}
        >
          {getCreatedString(new Date(snippetObj.dateCreated))}
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date} caption regular secondary exerpt`}
        >
          {getCreatedString(
            new Date(snippetObj.dateModified ?? snippetObj.dateCreated)
          )}
        </span>
      </div>
    </div>
  );
};

const Titles: React.FC = () => {
  return (
    <div className={`${style.NoteItem} ${style.titleRow}`}>
      <div className={`${style.itemHead} label disabled medium`}>Name</div>
      <div className={style.itemTail}>
        <span
          className={`${style.field} ${style.column} ${style.date} label  medium disabled`}
        >
          Created at
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date} label  medium disabled`}
        >
          Updated at
        </span>
      </div>
    </div>
  );
};

const AddSnippet: React.FC = () => {
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });
  return (
    <Button
      disabled={!canEditEntity}
      buttonType={ButtonTypes.PRIMARY}
      onClick={(e: any) => {
        if (!canEditEntity) return;
        e.stopPropagation();
        snippetsApi.createAndOpenSnippet();
      }}
      icon={<PlusOutlined />}
    >
      Snippet
    </Button>
  );
};

const usePageWidth = (
  templatesRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const [sectionWidthMode, setsectionWidthMode] = useState<SectionWidthModes>(
    SectionWidthModes.large
  );
  useEffect(() => {
    if (!templatesRef.current) return;
    templatesRef.current?.focus();
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width > 960)
          return setsectionWidthMode(SectionWidthModes.large);
        if (600 < entry.contentRect.width && entry.contentRect.width <= 960)
          return setsectionWidthMode(SectionWidthModes.medium);
        if (entry.contentRect.width <= 600) {
          return setsectionWidthMode(SectionWidthModes.small);
        }
      }
    });
    if (templatesRef.current) resizeObserver.observe(templatesRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return sectionWidthMode;
};

export default SnippetsList;
