import React, { memo, useEffect, useMemo, useState } from "react";
import { batch, shallowEqual, useSelector } from "react-redux";
import {
  getActionTrigger,
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
  useBtnComponentsSetter,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import store, { ClarityStore } from "store/storeExporter";
import groupGalleryStyle from "./groupGallery/groupGallery.module.scss";
import Button, { ButtonTypes } from "components/Button";
import { groupApi } from "clientApi/groupsApi";
import notificationsApi from "clientApi/notificationsApi";
import { SET_MODAL_JOIN_GROUP } from "store/actions";
import { locationSubject } from "components/LocationListener";
import { ReactComponent as GroupsIcon } from "../../../icons/menu/Groups.svg";
import Icon, { PlusOutlined } from "@ant-design/icons";
import UsersStack from "clarity-ui/UsersStack";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities, GroupGeneralViews, UserRole } from "utilities/types";
import CreateGroup from "./settings/CreateGroup";
import navigationApi from "clientApi/navigationApi";
import { useIsBaseMember, useRoleType } from "store/reducers/clientReducer";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import { ChunkDestination } from "utilities/stateTypes";
import Conditional from "components/Conditional";

const GroupGallery: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  usePageDataSetter(paneId, {
    title: "Groups",
  });
  const [createGroupEnabled, setCreateGroupEnabled] = useState<boolean>(false);

  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });

  const createGroup = () => {
    setCreateGroupEnabled(true);
  };

  useBtnComponentsSetter(
    paneId,
    {
      btnComponents: [
        getActionTrigger({
          key: "link",
          buttonType: ButtonTypes.PRIMARY,
          disabled: !Boolean(canEdit),
          condition: () => !createGroupEnabled,
          onClick: () => {
            createGroup();
          },
          contents: () => "Group",
          icon: () => <PlusOutlined />,
        }),
        getActionTrigger({
          key: "link",
          buttonType: ButtonTypes.DEFAULT,
          disabled: !Boolean(canEdit),
          condition: () => createGroupEnabled,
          onClick: () => {
            setCreateGroupEnabled(false);
          },
          contents: () => "Cancel",
        }),
      ],
    },
    [canEdit, createGroupEnabled]
  );

  useEffect(() => {
    batch(() => {
      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.base,
      });
      setPaneSubroutes({
        paneId,
        subRoutesParams: {
          type: "groups&roles",
          activeKey: "groups",
        },
      });
    });

    return () => {
      batch(() => {
        setPaneSubroutes({
          paneId,
        });
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, [paneId]);

  return (
    <>
      <TopNavFiltersBar paneId={paneId}>
        <Conditional on={!createGroupEnabled}>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            icon={<PlusOutlined />}
            disabled={!Boolean(canEdit)}
            onClick={() => createGroup()}
          >
            Group
          </Button>
        </Conditional>

        <Conditional on={createGroupEnabled}>
          <Button
            buttonType={ButtonTypes.LINK}
            disabled={!Boolean(canEdit)}
            onClick={() => setCreateGroupEnabled(false)}
          >
            Cancel
          </Button>
        </Conditional>
      </TopNavFiltersBar>
      <div className={groupGalleryStyle.groupGallerySectionHolder}>
        {createGroupEnabled ? (
          <div className={groupGalleryStyle.createGroupContainer}>
            <CreateGroup onGroupSucess={() => setCreateGroupEnabled(false)} />
          </div>
        ) : (
          <GroupGalleryTable canEdit={canEdit} />
        )}
      </div>
    </>
  );
};

const GroupGalleryTable: React.FC<{
  canEdit: boolean;
}> = memo(({ canEdit }) => {
  const activeGroupIds = useSelector(
    (state: ClarityStore) => state.groups.activeGroupids,
    shallowEqual
  );
  const archiveGroupIds = useSelector(
    (state: ClarityStore) => state.groups.archivedGroupids,
    shallowEqual
  );

  const userGroups = useSelector(
    (state: ClarityStore) => state.groups.userGroups,
    shallowEqual
  );

  const baseSlug = useSelector(
    (state: ClarityStore) => state.workspace.slug,
    shallowEqual
  );

  const isBaseMember = useIsBaseMember();
  const roleName = useRoleType();

  const disableJoin = !isBaseMember || roleName === UserRole.GUEST;

  return (
    <>
      <div className={groupGalleryStyle.groupGalleryRowHeadings}>
        <div
          className={`label medium disabled ${groupGalleryStyle.groupGalleryRowHeadingGroup}`}
        >
          Groups
        </div>
        <div className="label medium disabled">Members</div>
        <div className="label bold secondary"></div>
      </div>
      <div className={groupGalleryStyle.groupGallerySections}>
        {useMemo(
          () =>
            activeGroupIds.map((groupId) => (
              <GroupCard
                id={groupId}
                disableJoin={disableJoin}
                userGroups={userGroups}
                key={groupId}
                baseSlug={baseSlug}
                canEdit={canEdit}
              />
            )),
          [activeGroupIds, userGroups]
        )}
        {useMemo(
          () =>
            archiveGroupIds.map((groupId) => (
              <GroupCard
                id={groupId}
                userGroups={userGroups}
                key={groupId}
                canEdit={canEdit}
                baseSlug={baseSlug}
              />
            )),
          [archiveGroupIds, userGroups]
        )}
      </div>
    </>
  );
});

const GroupCard: React.FC<{
  id: string;
  userGroups: string[];
  baseSlug: string;
  canEdit: boolean;
  disableJoin?: boolean;
}> = memo(({ id, userGroups, baseSlug, canEdit, disableJoin }) => {
  const group = useSelector(
    (store: ClarityStore) => store.groups.dict[id],
    shallowEqual
  );
  const partOfGroup = userGroups.includes(id);
  const [isHovered, setisHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  const joinGroup = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (disableJoin) return;
    setLoading(true);
    if (
      group.requiredRoles &&
      group.requiredRoles.length > 0 &&
      !groupApi.canJoinGroup(group.id)
    ) {
      store.dispatch({
        type: SET_MODAL_JOIN_GROUP,
        isOpen: true,
        roleIds: group.requiredRoles,
      });
      setLoading(false);
    } else {
      groupApi
        .joinGroup(id)
        .then(() => {
          notificationsApi.displayConfirmation({
            title: <span>Joined group</span>,
            body: <span>You joined "{group.name}" successfully</span>,
            duration: 3,
          });
          setLoading(false);
        })
        .catch((e) => {
          notificationsApi.displayError({
            title: <span>Error joining group</span>,
            body: <span>{e.response?.data?.message}</span>,
            duration: 3,
          });
          setLoading(false);
        });
    }
  };

  const gotoGroup = (e: any) => {
    // if (group.deleted) {
    //   return;
    // }
    if (e.shiftKey) {
      navigationApi.openSplitView({
        viewName: GroupGeneralViews.GroupProfile,
        groupSlug: group.slug,
      });
    } else {
      locationSubject.next(`/${baseSlug}/group/${group?.slug}`);
    }
  };

  const unarchiveGroup = () => {
    setLoading(true);

    groupApi.unarchiveGroup(id).then(() => {
      setLoading(false);

      notificationsApi.displayConfirmation({
        title: <span>Group unarchived</span>,
        body: <span>"{group.name}" was unarchived</span>,
        duration: 3,
      });
    });
  };
  const leaveGroup = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    groupApi
      .leaveGroup(id)
      .then(() => {
        setLoading(false);

        notificationsApi.displayConfirmation({
          title: <span>Left group</span>,
          body: <span>You left "{group.name}"</span>,
          duration: 3,
        });
      })
      .catch((e) => {
        setLoading(false);

        notificationsApi.displayError({
          title: <span>Error leaving group</span>,
          body: <span>{e.response?.data?.message}</span>,
          duration: 3,
        });
      });
  };
  return (
    <div
      className={`${groupGalleryStyle.row} ${
        group.deleted ? groupGalleryStyle.rowDeleted : ""
      }`}
      onClick={(e: any) => gotoGroup(e)}
      onMouseEnter={() => setisHovered(true)}
      onMouseLeave={() => setisHovered(false)}
    >
      <div className={groupGalleryStyle.rowHeading}>
        <div className={groupGalleryStyle.rowIcon}>
          <Icon component={GroupsIcon} />
        </div>
        <div>
          <div
            className={`body2 bold ellipsis-nowrap ${groupGalleryStyle.underlineOnParentHover}`}
          >
            {group.name}
          </div>
          <div className="small secondary">{group.description}</div>
        </div>
      </div>
      <div className={groupGalleryStyle.rowUserStack}>
        <div>
          {!group.members && (
            <span className="small medium disabled">No members</span>
          )}
          {group.members && <GroupMembers memberIds={group.members || []} />}
        </div>
      </div>
      <div style={{ minWidth: "90px", textAlign: "right" }}>
        {!partOfGroup && !group.deleted && isHovered && (
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={joinGroup}
            style={{ justifyContent: "center" }}
            disabled={loading || disableJoin}
          >
            Join
          </Button>
        )}
        {partOfGroup && !group.deleted && isHovered && (
          <Button
            onClick={leaveGroup}
            disabled={loading}
            style={{ justifyContent: "center" }}
          >
            Leave
          </Button>
        )}
        {group.deleted && isHovered && canEdit && (
          <Button
            onClick={unarchiveGroup}
            disabled={!canEdit || loading}
            style={{ justifyContent: "center" }}
          >
            Unarchive
          </Button>
        )}
      </div>
    </div>
  );
});

const GroupMembers: React.FC<{ memberIds: string[] }> = ({ memberIds }) => {
  const firstThreeMembers = memberIds ? memberIds.slice(0, 3) : [];
  if (memberIds.length === 0) {
    return <span className="small medium disabled">No members</span>;
  }
  return (
    <div
      style={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
      }}
    >
      {<UsersStack userIds={firstThreeMembers || []} />}{" "}
      <span className="small medium disabled">{memberIds.length}</span>
    </div>
  );
};

export default GroupGallery;
