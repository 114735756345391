import { BlockPropsWithRef } from "editor/blockContainer/BlockSplitter";
import { BehaviorSubject } from "rxjs";
import { $focusOn } from "store/storeExporter";
import { BlockMenuTypes, BlockStateMenu } from "utilities/types";
import { checkCaretPosition } from "../caretUtils";

export const resetBlockMenu = (
  menuStateValue: React.MutableRefObject<BehaviorSubject<BlockStateMenu>>,
  opts: Partial<BlockStateMenu> = {}
) => {
  const menuState: BlockStateMenu = {
    isOpened: false,
    executeSelection: false,
    filterMenuBy: "",
    hoveredItem: 0,
    isAtBegining: true,
    manualReset: false,
    preOpenedCaretPosition: 0,
    type: menuStateValue.current.value.type,
    options: undefined,
  };

  menuStateValue.current.next({ ...menuState, ...opts });
};

export const checkIfOpenedBlockMenu = (
  menuStateValue: React.MutableRefObject<BehaviorSubject<BlockStateMenu>>,
  blockRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const menuState = { ...menuStateValue.current.value };
  if (menuState.isOpened) {
    const classToCheck = getPlaceholderClass(menuState.type);
    const placeholder = blockRef.current?.querySelectorAll(classToCheck);
    if (placeholder && placeholder[0]) {
      if (
        menuState.type !== BlockMenuTypes.tagsMenu ||
        menuState.options?.hashtag
      ) {
        menuState.filterMenuBy = placeholder[0].textContent
          ? placeholder[0].textContent.substring(1)
          : "";
        const caretPosition = checkCaretPosition(blockRef.current);
        $focusOn.next({ ...$focusOn.value, caretPosition });
      } else {
        menuState.filterMenuBy = placeholder[0].textContent
          ? placeholder[0].textContent
          : "";
      }

      menuStateValue.current.next(menuState);
      const brs = placeholder[0].getElementsByTagName("br");

      if (brs) {
        for (const item of Array.from(brs)) {
          const itemCopy: any = item;
          itemCopy.replaceWith("");
        }
      }

      if (placeholder[0].textContent?.length === 0) {
        placeholder[0].remove();
        resetBlockMenu(menuStateValue);
      }
    } else resetBlockMenu(menuStateValue);
  }
  const el: HTMLElement | null = blockRef.current;
  const brs = el?.getElementsByTagName("br");
  if (brs) {
    for (const item of Array.from(brs)) {
      item.replaceWith("");
    }
  }
};

const getPlaceholderClass = (type: BlockMenuTypes) => {
  switch (type) {
    case BlockMenuTypes.tagsMenu:
      return ".mention-placeholder";
    case BlockMenuTypes.userMentions:
      return ".user-mention-placeholder";
    case BlockMenuTypes.linkEntityMenu:
      return ".link-entity-placeholder";
    case BlockMenuTypes.snippetMenu:
      return ".snippet-placeholder";
    default:
      return ".slash-placeholder";
  }
};

export const getPopupCoordinates = (props: BlockPropsWithRef) => {
  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  if (selection && selection.rangeCount > 0 && props.blockRef.current) {
    const range: Range = selection.getRangeAt(0);
    let rangeX = range.getBoundingClientRect().x;
    let rangeR = range.getBoundingClientRect().width;
    let rangeY = range.getBoundingClientRect().top;
    let componentX = props.blockRef.current
      .closest(".section-big")
      ?.getBoundingClientRect().x;
    let componentY = props.blockRef.current.getBoundingClientRect().y;
    const computed = window
      .getComputedStyle(props.blockRef.current)
      .getPropertyValue("margin-top");
    const marginString = computed.substring(0, computed.length - 2);
    const margin = Number(marginString);

    x = rangeX === 0 ? 10 : rangeX - (componentX ? componentX : 0) + rangeR / 2;
    y = rangeY === 0 ? 10 : rangeY + margin - 6 - componentY;
    return { x, y };
  }
};
