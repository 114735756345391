import EmptyState from "clarity-ui/EmptyState";
import Modal from "clarity-ui/Modal";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { batch } from "react-redux";
import { AutoSizer, List, ListRowProps } from "react-virtualized";
import {
  SET_PAGE_DATA,
  SET_SHOW_SUBROUTES,
  SET_TOPNAV_VIEW_TYPE,
} from "store/actions";
import store, { ClarityStore } from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { IWorkViewConfig } from "../defaultWorkView/types";
import CycleRow from "./allCycles/CycleRow";
import CyclesEditor from "./allCycles/CyclesEditor";
import styles from "./allCycles/cyclesView.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import { Dropdown, Menu } from "antd";
import Button, {
  ButtonShapes,
  ButtonTypes,
  IconSides,
} from "components/Button";
import { locationSubject } from "components/LocationListener";
import navigationApi from "clientApi/navigationApi";
import { Abilities, GroupGeneralViews, TasksViewModes } from "utilities/types";
import ChevronDown from "icons/Components/ChevronDown";
import { TopNavbarType } from "store/reducers/topNavReducer";
import { useAbilityChecker } from "editor/utils/customHooks";
import { ControlOutlined } from "@ant-design/icons";
import GroupWorkSubroutes from "../home/subroutes/GroupWorkSubroutes";

enum SectionWidthModes {
  small = "small",
  medium = "medium",
  large = "large",
}

const AllCycles: React.FC<{
  mode: "active" | "closed";
  viewConfig: IWorkViewConfig;
  paneId: ChunkDestination;
}> = ({ mode, viewConfig, paneId }) => {
  const groupContext = useShallowSelector(
    (store: ClarityStore) => store.client.paneGroupContext[paneId]?.groupId
  );

  const cycleIds = useShallowSelector((state) =>
    mode === "active"
      ? state.work.groupCycles[groupContext]
        ? state.work.groupCycles[groupContext].openTimeframeIds
        : []
      : state.work.groupCycles[groupContext]
      ? state.work.groupCycles[groupContext].closedTimeframeIds
      : []
  );
  const [editCycles, seteditCycles] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const [sectionWidthMode, setsectionWidthMode] = useState(
    SectionWidthModes.large
  );

  const canManageCycles = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_CYCLES,
  });

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.focus();
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width > 1200)
          return setsectionWidthMode(SectionWidthModes.large);
        if (500 < entry.contentRect.width && entry.contentRect.width <= 1200)
          return setsectionWidthMode(SectionWidthModes.medium);
        if (entry.contentRect.width <= 550) {
          return setsectionWidthMode(SectionWidthModes.small);
        }
      }
    });
    if (ref.current) resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    batch(() => {
      const groupName = store.getState().groups.dict[groupContext]?.name;
      store.dispatch({
        type: SET_PAGE_DATA,
        paneId,
        title: groupName,
      });
      store.dispatch({
        type: SET_SHOW_SUBROUTES,
        paneId,
        subRoutesParams: {
          type: "group",
          groupId: groupContext,
          activeKey: "sprints",
        },
      });
      store.dispatch({
        type: SET_TOPNAV_VIEW_TYPE,
        paneId,
        navType: TopNavbarType.group,
      });
    });

    return () => {
      batch(() => {
        store.dispatch({
          type: SET_SHOW_SUBROUTES,
          paneId,
        });
        store.dispatch({
          type: SET_TOPNAV_VIEW_TYPE,
          paneId,
        });
      });
    };
  }, []);

  const handleNewCycleClick = async () => {
    seteditCycles(true);
  };

  const getAction = (e: React.MouseEvent, archive?: boolean) => {
    let groupSlug: string | undefined =
      store.getState().client.paneGroupContext[paneId]?.groupSlug;
    const navigatePrimary = () => {
      const baseSlug = store.getState().workspace.slug;
      if (!baseSlug) return;

      if (archive) {
        locationSubject.next(`/${baseSlug}/group/${groupSlug}/sprints`);
      } else
        locationSubject.next(`/${baseSlug}/group/${groupSlug}/sprints/closed`);
    };

    const navigateSplit = () => {
      navigationApi.openSplitView({
        viewName: archive
          ? GroupGeneralViews.CyclesActive
          : GroupGeneralViews.CyclesClosed,
        groupSlug,
      });
    };

    if (paneId === ChunkDestination.primary) {
      if (e.shiftKey) navigateSplit();
      else navigatePrimary();
    } else {
      if (e.shiftKey) navigatePrimary();
      else navigateSplit();
    }
  };

  return (
    <>
      <TopNavFiltersBar paneId={paneId}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <GroupWorkSubroutes paneId={paneId} groupId={groupContext} />
          <div
            style={{
              display: "flex",
              gap: "4px",
            }}
          >
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item
                    key={"activity"}
                    onClick={(e: any) => getAction(e, true)}
                  >
                    Active
                  </Menu.Item>
                  <Menu.Item key={"profile"} onClick={(e: any) => getAction(e)}>
                    Closed
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                buttonType={ButtonTypes.DEFAULT}
                shape={ButtonShapes.OVAL}
                icon={<ChevronDown />}
                iconSide={IconSides.RIGHT}
              >
                {mode === "active" ? "Active" : "Closed"}
              </Button>
            </Dropdown>
            <Button
              icon={<ControlOutlined />}
              disabled={!canManageCycles}
              onClick={() => {
                if (!canManageCycles) return;
                seteditCycles(true);
              }}
            >
              Edit sprints
            </Button>
          </div>
        </div>
      </TopNavFiltersBar>
      <div className={styles.scrollContainer}>
        <div className={styles.container} ref={ref}>
          {cycleIds?.length === 0 ? (
            <EmptyState
              buttonFn={handleNewCycleClick}
              buttonIcon={<PlusOutlined />}
              buttonLabel="Sprint"
              heading={viewConfig?.description ?? ""}
              caption={viewConfig?.emptyState ?? ""}
              hideButton={viewConfig?.viewMode === TasksViewModes.ClosedCycles}
            />
          ) : (
            <>
              <div className={styles.labelContainer}>
                <div className={styles.head}>
                  <span className="label medium disabled  ellipsis-nowrap">
                    Name
                  </span>
                </div>
                <div className={styles.tail}>
                  <span className="label medium disabled  ellipsis-nowrap">
                    Progress
                  </span>
                </div>
              </div>
              <CyclesDisplay
                cycleIds={cycleIds}
                paneId={paneId}
                sectionWidthMode={sectionWidthMode}
              />
            </>
          )}
          {editCycles &&
            ReactDOM.createPortal(
              <Modal size="medium" hideModal={() => seteditCycles(false)}>
                <CyclesEditor mode={mode} groupId={groupContext} />
              </Modal>,
              document.body
            )}
        </div>
      </div>
    </>
  );
};

const CyclesDisplay: React.FC<{
  cycleIds: string[];
  paneId: ChunkDestination;
  sectionWidthMode: SectionWidthModes;
}> = ({ cycleIds, paneId, sectionWidthMode }) => {
  const rowRenderer = useCallback(
    ({ key, index, style }: ListRowProps) => {
      const cycleId = cycleIds[index];
      return (
        <div style={style} key={key}>
          <div>
            <CycleRow key={cycleId} id={cycleId} paneId={paneId} />
          </div>
        </div>
      );
    },
    [cycleIds]
  );

  return useMemo(() => {
    return (
      <AutoSizer>
        {({ width, height }) => {
          return (
            <>
              <List
                width={width}
                autoContainerWidth={true}
                height={height}
                rowCount={cycleIds ? cycleIds.length : 0}
                rowHeight={
                  sectionWidthMode === SectionWidthModes.small ? 49 : 49
                }
                rowRenderer={rowRenderer}
                overscanRowCount={5}
              />
            </>
          );
        }}
      </AutoSizer>
    );
  }, [cycleIds, sectionWidthMode]);
};

export default AllCycles;
