import { BlockActionTypes } from "editor/utils/blockActions";
import { getBlockFromExtender } from "editor/utils/socketActionsListener";
import { cloneBlockBranch } from "editor/utils/specificActions/cloneBlockActions";
import { setUpdateObjectToStore } from "editor/utils/specificActions/persistActions";
import { ActionObject } from "editor/utils/specificActions/undoUtils";
import { axiosInstance } from "index";
import { batch } from "react-redux";
import {
  REMOVE_TEMPLATE_SIDEEFFECT,
  SET_BASE_TEMPLATES,
  SET_BLOCKS_ARRAY_FROM_CONTAINER,
} from "store/actions";
import { Block } from "store/reducers/blockReducer";
import {
  INewTaskCreationModes,
  INewTemplateCreationModes,
  openNewTaskModal,
  openNewTemplateModal,
} from "store/reducers/clientReducer";
import {
  multipleUpdateTemplateItemsAction,
  updateTemplateItemAction,
} from "store/reducers/templateReducer";
import store from "store/storeExporter";
import { ILineValue, LineValueType } from "utilities/lineUtilities";
import {
  ContainerTypes,
  IBlockContext,
  IContributon,
  IProjectObj,
  ITemplateObj,
  SplitType,
  WorkTypes,
} from "utilities/types";
import notificationsApi from "./notificationsApi";

const { v4: uuidv4 } = require("uuid");

class TemplatesApi {
  url = "/api/template";

  getBaseTemplates(baseId: string) {
    return axiosInstance
      .get(`${this.url}/all`, {
        params: {
          baseId,
        },
      })
      .then((res: any) => {
        store.dispatch({
          type: SET_BASE_TEMPLATES,
          param: {
            templates: res.data,
          },
        });
      });
  }

  getState() {
    return store.getState().templates;
  }

  dispatchUpdate(workItem: ITemplateObj) {
    updateTemplateItemAction({
      delta: workItem,
      id: workItem.id,
      skipInsertInList: true,
      type: "update",
    });
  }

  dispatchMultipleUpdate(templates: ITemplateObj[]) {
    multipleUpdateTemplateItemsAction({
      templates,
    });
  }

  rebuildContributors(
    templateItem: ITemplateObj,
    prevContributors: IContributon[]
  ) {
    const contributors = [];
    const otherContributors = templateItem.contributorIds
      ? templateItem.contributorIds.filter(
          (id) => id !== templateItem.assigneeId
        )
      : [];

    const getFullReward = () => {
      if (templateItem.reward?.splitType === SplitType.currency)
        return templateItem.reward.amount;
      return 100;
    };

    const checkIfContributionExists = (id: string) => {
      for (const contribution of prevContributors) {
        if (contribution.userId === id) return contribution;
      }
    };

    if (templateItem.assigneeId) {
      const existingContribution = checkIfContributionExists(
        templateItem.assigneeId
      );
      const contribution: IContributon = {
        userId: templateItem.assigneeId,
        amount: otherContributors?.length > 0 ? 0 : getFullReward(),
        reason: "assignee",
      };
      if (existingContribution && otherContributors?.length > 0) {
        contribution.amount = existingContribution.amount;
      }
      contributors.push(contribution);
    }

    otherContributors?.forEach((id) => {
      const existingContribution = checkIfContributionExists(id);
      const contribution: IContributon = {
        userId: id,
        amount: 0,
        reason: "contributor",
      };
      if (existingContribution) {
        contribution.amount = existingContribution.amount;
      }
      contributors.push(contribution);
    });

    return contributors;
  }

  checkSideEffects(templateItem: ITemplateObj, delta: Partial<ITemplateObj>) {
    const addAssigneAsContributor = () => {
      if (templateItem.reward) {
        const updatedWorkItem = { ...templateItem, ...delta };
        const contributors = this.rebuildContributors(
          updatedWorkItem,
          templateItem.reward.contributors
        );
        const reward = { ...templateItem.reward };
        reward.contributors = contributors;

        delta.reward = reward;
      }
    };

    if ("reward" in delta) {
      if (!delta.reward) delta.reward = null;
    }

    if ("assigneeId" in delta) {
      if (templateItem.reward) {
        addAssigneAsContributor();
      }
    }

    if ("workType" in delta) {
      if (delta.workType === WorkTypes.TASK) delta.contributorIds = [];
    }

    if ("contributorIds" in delta) {
      if (templateItem.reward) {
        const updatedWorkItem = { ...templateItem, ...delta };
        const contributors = this.rebuildContributors(
          updatedWorkItem,
          templateItem.reward.contributors
        );

        const reward = { ...templateItem.reward };
        reward.contributors = contributors;
        delta.reward = reward;
      }
    }
  }

  async updateTemplateItems(deltas: Partial<ITemplateObj>[]) {
    const baseId = store.getState().workspace.id;
    const state = this.getState();
    const dict = state.dict;
    const updatedWorkItems = [];
    for (const delta of deltas) {
      if (!delta.id) {
        continue;
      }
      const templateItem = dict[delta.id];
      this.checkSideEffects(templateItem, delta);
      const updatedTemplateItem = { ...templateItem, ...delta };
      updatedWorkItems.push(updatedTemplateItem);
    }

    if (!this.checkIsCreatingNewTemplate()) {
      axiosInstance.patch(`${this.url}/updateAll`, {
        deltas,
        baseId,
      });
    }
    this.dispatchMultipleUpdate(updatedWorkItems);
  }

  async updateTemplateItem(workId: string, delta: Partial<ITemplateObj>) {
    const state = this.getState();
    const dict = state.dict;
    const workItem = dict[workId];
    const itemDelta = { ...delta };
    this.checkSideEffects(workItem, itemDelta);
    const creatingNewTask = this.checkIsCreatingNewTemplate();
    if (!creatingNewTask) {
      axiosInstance.patch(`${this.url}/update`, {
        delta: { ...itemDelta, id: workItem.id },
      });
    }

    const updatedWorkItem = { ...workItem, ...itemDelta };
    this.dispatchUpdate(updatedWorkItem);
    return updatedWorkItem;
  }

  saveUpdateTemplateItem(workId: string, delta: Partial<ITemplateObj>) {
    axiosInstance.patch(`${this.url}/update`, {
      delta: {
        ...delta,
        id: workId,
      },
    });
  }

  saveUpdateTemplateItems(deltas: Partial<ITemplateObj>[]) {
    const baseId = store.getState().workspace.id;

    axiosInstance.patch(`${this.url}/updateAll`, {
      deltas,
      baseId,
    });
  }

  checkIsCreatingNewTemplate() {
    return Boolean(store.getState().client.newTemplateContext?.presetData?.id);
  }

  update(delta: Partial<ITemplateObj>, items: string[]) {
    batch(() => {
      this.updateTemplateItems(
        items.map((workId) => {
          return { id: workId, ...delta };
        })
      );
    });
  }

  openCreateWorkItemFromTemplate(id: string) {
    const storeData = store.getState();
    const blockContext =
      storeData.blocks.contexts[ContainerTypes.TEMPLATE + id];
    const blockIds = blockContext.state.rootBlocksIds;
    const newBlocks: Block[] = [];
    const newContainerId = uuidv4();

    cloneBlockBranch(
      blockIds,
      "",
      0,
      newContainerId,
      ContainerTypes.PROJECT,
      newBlocks
    );
    const templateItem = storeData.templates.dict[id];

    const saveActions: ActionObject[] = [];
    const context = {
      autosave: false,
      id: ContainerTypes.PROJECT + newContainerId,
      type: "container",
      container: {
        id: newContainerId,
        type: ContainerTypes.PROJECT,
      },
    } as IBlockContext;

    newBlocks.forEach((block) => {
      const saveAction: ActionObject = {
        id: block.id,
        delta: getBlockFromExtender(block),
        type: BlockActionTypes.insert,
        options: {
          newBlock: true,
        },
      };
      saveActions.push(saveAction);
    });
    setUpdateObjectToStore(saveActions, context);

    batch(() => {
      store.dispatch({
        type: SET_BLOCKS_ARRAY_FROM_CONTAINER,
        param: {
          blocks: newBlocks,
          container: {
            containerId: newContainerId,
            containerType: ContainerTypes.PROJECT,
          },
        },
      });

      openNewTaskModal({
        type: INewTaskCreationModes.fromTemplate,
        presetData: {
          id: newContainerId,
          assigneeId: templateItem.assigneeId,
          name: templateItem.name,
          nameValue: templateItem.nameValue,
          isClosed: templateItem.isClosed,
          isDone: templateItem.isDone,
          contributorIds: templateItem.contributorIds,
          reviewerId: templateItem.reviewerId,
          statusId: templateItem.statusId,
          outlineMode: templateItem.outlineMode,
          workType: templateItem.workType,
          dateClosed: templateItem.isClosed ? new Date() : undefined,
          workspaceId: templateItem.workspaceId,
          dueDate: templateItem.dueDate,
          priority: templateItem.priority,
          groupId: templateItem.groupId,
          groupIds: templateItem.groupIds ? templateItem.groupIds : [],
          childrenAggregate: templateItem.childrenAggregate,
          reward: templateItem.reward,
        },
        generatedFromId: id,
      });
    });
  }

  openDuplicateTemplatetModal(id: string) {
    const storeData = store.getState();
    const blockContext =
      storeData.blocks.contexts[ContainerTypes.TEMPLATE + id];
    const blockIds = blockContext.state.rootBlocksIds;
    const newBlocks: Block[] = [];
    const newContainerId = uuidv4();

    cloneBlockBranch(
      blockIds,
      "",
      0,
      newContainerId,
      ContainerTypes.TEMPLATE,
      newBlocks
    );
    const templateItem = storeData.templates.dict[id];

    const newName = templateItem.name + " (copy)";
    const newNameValue: ILineValue[] = [
      { type: LineValueType.text, value: newName, children: [] },
    ];

    const saveActions: ActionObject[] = [];
    const context = {
      autosave: false,
      id: ContainerTypes.TEMPLATE + newContainerId,
      type: "container",
      container: {
        id: newContainerId,
        type: ContainerTypes.TEMPLATE,
      },
    } as IBlockContext;

    newBlocks.forEach((block) => {
      const saveAction: ActionObject = {
        id: block.id,
        delta: getBlockFromExtender(block),
        type: BlockActionTypes.insert,
        options: {
          newBlock: true,
        },
      };
      saveActions.push(saveAction);
    });
    setUpdateObjectToStore(saveActions, context);

    batch(() => {
      store.dispatch({
        type: SET_BLOCKS_ARRAY_FROM_CONTAINER,
        param: {
          blocks: newBlocks,
          container: {
            containerId: newContainerId,
            containerType: ContainerTypes.TEMPLATE,
          },
        },
      });

      openNewTemplateModal({
        type: INewTemplateCreationModes.duplicate,
        presetData: {
          id: newContainerId,
          assigneeId: templateItem.assigneeId,
          name: newName,
          nameValue: newNameValue,
          isClosed: templateItem.isClosed,
          isDone: templateItem.isDone,
          contributorIds: templateItem.contributorIds,
          reviewerId: templateItem.reviewerId,
          statusId: templateItem.statusId,
          outlineMode: templateItem.outlineMode,
          workType: templateItem.workType,
          dateClosed: templateItem.dateClosed,
          workspaceId: templateItem.workspaceId,
          dueDate: templateItem.dueDate,
          priority: templateItem.priority,
          groupIds: templateItem.groupIds ? templateItem.groupIds : [],
          childrenAggregate: templateItem.childrenAggregate,
          reward: templateItem.reward,
          isRoot: templateItem.isRoot,
          parentId: templateItem.parentId,
        },
        generatedFromId: id,
      });
    });
  }

  createNewTemplate(
    presets: Partial<ITemplateObj>,
    name: string,
    groupId: string,
    thenAction?: (templateItem: ITemplateObj) => void
  ) {
    return axiosInstance
      .post(`${this.url}`, {
        presets,
        name,
        groupId,
      })
      .then((res: any) => {
        const templateItem = res.data.payload;
        updateTemplateItemAction({
          delta: templateItem,
          id: templateItem.id,
          skipInsertInList: false,
          type: "add",
        });

        if (thenAction) {
          return thenAction(templateItem);
        }
        notificationsApi.displayConfirmation({
          title: "Success",
          body: templateItem.name + " was created",
        });
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Something went wrong",
        });
      });
  }

  deleteTemplate(id: string) {
    return axiosInstance
      .delete(`${this.url}`, { data: { id } })
      .then((res) => {
        const baseId = store.getState().workspace?.id;
        store.dispatch({
          type: REMOVE_TEMPLATE_SIDEEFFECT,
          containerId: id,
          baseId,
        });
        notificationsApi.displayConfirmation({
          title: "Template was deleted",
        });
        return res.data.payload;
      })
      .catch(() => {
        notificationsApi.displayError({
          title: "Something went wrong",
          body: "Please try again",
        });
        return undefined;
      });
  }

  duplicateTemplate(
    id: string,
    presets?: Partial<ITemplateObj>,
    includeChildren?: boolean,
    thenAction?: (templateItem: ITemplateObj) => void
  ) {
    axiosInstance
      .post(`${this.url}/duplicate`, {
        id,
        presets,
        includeChildren,
      })
      .then((res: any) => {
        if (thenAction) {
          return thenAction(res.data);
        }
        notificationsApi.displayConfirmation({
          title: "Success",
        });
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Something went wrong",
          body: "Please try again",
        });
      });
  }

  createWorkItemFromTemplate(
    templateId: string,
    presets?: Partial<IProjectObj>,
    includeChildren?: boolean,
    thenAction?: (templateItem: IProjectObj) => void
  ) {
    return axiosInstance
      .post(`${this.url}/createWorkItemFromTemplate`, {
        templateId,
        presets,
        includeChildren,
      })
      .then((res: any) => {
        if (thenAction) {
          return thenAction(res.data);
        }
        notificationsApi.displayConfirmation({
          title: "Success",
        });
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Something went wrong",
          body: "Please try again",
        });
      });
  }
}

export const templatesApi = new TemplatesApi();
