import React from "react";
import { connect } from "react-redux";
import styles from "./getStartedInvite/getStartedInvite.module.scss";
import Members from "./base/main/settings/Members";
import { IWorkspaceObj, IUserObj } from "utilities/types";
import * as actionTypes from "store/actions";

interface IMapStateToProps {
  base: IWorkspaceObj;
  user: IUserObj;
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

interface IMapDispatchToProps {}

function GetStartedInvite(props: IMapDispatchToProps & IMapStateToProps) {
  return (
    <div className={styles.getStartedInvite}>
      <div className={styles.inner}>
        <div style={{ textAlign: "center", marginBottom: "94px" }}>
          <h2 style={{ marginBottom: "24px" }}>Invite collaborators</h2>
          <h4 style={{ fontWeight: 400 }}>
            Invite team members to work with you in {props.base.name}
          </h4>
        </div>
        <Members
          base={props.base}
          user={props.user}
          setAuthenticatedUser={props.setAuthenticatedUser}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  base: state.workspace,
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAuthenticatedUser: (user: IUserObj) =>
    dispatch({ type: actionTypes.SET_AUTHENTICATED_USER, user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStartedInvite);
