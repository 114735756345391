import workApi from "clientApi/workApi";
import Button, { ButtonTypes } from "components/Button";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import {
  PlusOutlined,
  NumberOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import Modal from "./Modal";
import ReactDOM from "react-dom";
import {
  Abilities,
  CommandPaletteContext,
  ContainerTypes,
  exerptNumber,
  IContributon,
  IProjectObj,
  IReward,
  SplitType,
  TokenTypes,
  WorkTypes,
} from "utilities/types";
import ClarityInput from "components/ClarityInput";
import UserDisplay from "./UserDisplay";
import styles from "./workReward/workReward.module.scss";
import subworkstyles from "components/subTasks/subTasks.module.scss";
import { useFoldButton } from "components/SubTasks";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import { TriangleIcon } from "screens/base/Main";
import { Checkbox } from "antd";
import { tokenApi } from "clientApi/tokenApi";
import notificationsApi from "clientApi/notificationsApi";
import Conditional from "components/Conditional";
import { useAbilityChecker } from "editor/utils/customHooks";
import { numberWithCommas } from "utilities/regexUtilities";
import NetworkSelect from "components/NetworkSelect";
import { getNetworkDefaultToken } from "utilities/web3/connectors";
import TokenSelector, { ITokenConfirmation } from "components/TokenSelector";
import { web3Api } from "clientApi/web3Api";
import { NETWORK_TOKEN_CONTRACT } from "utilities/web3/tokens";
import SponsorGroupDisplay from "components/SponsorProjectDisplay";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";

const { v4: uuidv4 } = require("uuid");

const WorkReward: React.FC<{ workId: string }> = ({ workId }) => {
  const reward = useSelector(
    (state: ClarityStore) => state.work.dict[workId]?.reward,
    shallowEqual
  );

  const { open, toggle } = useFoldButton();
  const [rewardModal, setRewardModal] = useState(false);

  const openRewardModal = () => {
    setRewardModal(true);
  };

  const closeRewardModal = () => {
    setRewardModal(false);
  };

  return (
    <>
      {!reward?.id ? (
        <Button
          className={subworkstyles.emptyStateAddSubtaskBtn}
          icon={<PlusOutlined />}
          buttonType={ButtonTypes.LINK}
          onClick={openRewardModal}
          style={{ marginLeft: "-8px" }}
        >
          Add reward
        </Button>
      ) : (
        <div className={subworkstyles.container}>
          <div className={subworkstyles.subtasksTable}>
            <FoldButton open={open} onClick={toggle} />
            <div className={subworkstyles.headerRow}>
              {!open && (
                <span className={subworkstyles.title}>
                  Reward: {reward.amount} {reward.symbol}
                </span>
              )}
            </div>
            {open && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div
                  className="body medium secondary"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <span>Reward: </span>
                  <span>
                    {reward.amount} {reward.symbol}
                  </span>
                </div>
                <div
                  className="body medium secondary"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <span>Reviewer: </span>{" "}
                  <UserDisplay
                    id={reward.reviewerId}
                    avatarSize={36}
                    customStyles={{ marginRight: "14px" }}
                  />
                </div>
                <div>
                  <Button
                    className={subworkstyles.emptyStateAddSubtaskBtn}
                    icon={<PlusOutlined />}
                    buttonType={ButtonTypes.LINK}
                    onClick={openRewardModal}
                    style={{ marginLeft: "-8px" }}
                  >
                    Edit Reward
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {rewardModal &&
        ReactDOM.createPortal(
          <Modal size="medium" hideModal={() => setRewardModal(false)}>
            <RewardCard
              key={workId}
              workId={workId}
              editing={reward?.id ? true : false}
              closeRewardModal={closeRewardModal}
            />
          </Modal>,
          document.body
        )}
    </>
  );
};

export const RewardCard: React.FC<{
  workId: string;
  editing: boolean;
  closeRewardModal: () => void;
}> = ({ workId, editing, closeRewardModal }) => {
  const workItem = useSelector(
    (state: ClarityStore) => state.work.dict[workId],
    shallowEqual
  );

  const baseId = useSelector(
    (state: ClarityStore) => state.workspace.id,
    shallowEqual
  );

  const canManageReward = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_REWARDS,
  });

  const defaultTokenData = getNetworkDefaultToken(web3Api.networkId);
  const isFirstLoad = useRef(true);

  const lastUsedToken = useShallowSelector(
    (state) => state.client.lastUsedToken
  );

  const generateNewReward = () => ({
    amount: 0,
    id: uuidv4(),
    isPaid: false,
    isAllocationConfirmed: false,
    type: TokenTypes.NATIVE_CURRENCY,
    tokenName: defaultTokenData.name,
    isPublic: false,
    splitType: SplitType.percentage,
    tokenId: "1",
    contributors: [],
    contractAddress: lastUsedToken.contractAddress ?? NETWORK_TOKEN_CONTRACT,
    networkId: lastUsedToken.networkId ?? web3Api.networkId,
    symbol: defaultTokenData.symbol,
    datePaid: null,
    baseId: baseId,
    sponsorGroupId:
      workItem.workType === WorkTypes.TASK ? workItem.groupId : undefined,
  });

  const [reward, setReward] = useState<IReward>(
    workItem.reward ?? generateNewReward()
  );
  const [rewardOverAllocated, setrewardOverAllocated] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const getRewardPrice = async () => {
    setisLoading(true);
    return tokenApi
      .getTokenPricesForExport([reward])
      .then(() => {
        setisLoading(false);
        return;
      })
      .catch(() => {
        notificationsApi.displayError({
          body: "Something went worng, please try again",
        });
      });
  };

  const handleConfirm = () => {
    if (editing) {
      if (!reward.sponsorGroupId) {
        return;
      }
      if (reward.isPaid) {
        getRewardPrice().then(() => {
          workApi.updateWorkItems([
            {
              reward,
              id: workId,
            },
          ]);
          closeRewardModal();
        });
        return;
      }
      workApi.updateWorkItems([
        {
          reward,
          id: workId,
        },
      ]);
      closeRewardModal();
      return;
    }
    if (reward.isPaid) {
      getRewardPrice().then(() => {
        workApi.saveReward(reward, workId);
        closeRewardModal();
      });
      return;
    }
    workApi.saveReward(reward, workId);
    closeRewardModal();
  };

  const removeReward = () => {
    workApi.deleteReward(reward.id, workId);
    closeRewardModal();
  };

  useEffect(() => {
    const checkExistingAllocation = (userId: string) => {
      for (const contributor of reward.contributors) {
        if (contributor.userId === userId) return contributor.amount;
      }
      return 0;
    };

    if (workItem.reward) {
      if (isFirstLoad.current) {
        return setReward(workItem.reward);
      }
      const existingReward = { ...workItem.reward };
      const contributors = existingReward.contributors.map((contributor) => {
        const updatedContributor = { ...contributor };
        updatedContributor.amount = checkExistingAllocation(
          updatedContributor.userId
        );
        return updatedContributor;
      });
      setReward({ ...reward, contributors });
    } else {
      const contributors: IContributon[] = [];
      const otherContributors = workItem.contributorIds.filter(
        (id) => id !== workItem.assigneeId
      );
      const checkFullReward = () => {
        if (reward.splitType === SplitType.percentage) return 100;
        else return reward.amount;
      };
      if (workItem.assigneeId) {
        const contribution: IContributon = {
          userId: workItem.assigneeId,
          amount: otherContributors.length > 0 ? 0 : checkFullReward(),
          reason: "assignee",
        };
        contributors.push(contribution);
      }
      otherContributors.forEach((id) => {
        const contribution: IContributon = {
          userId: id,
          amount: checkExistingAllocation(id),
          reason: "contributor",
        };
        contributors.push(contribution);
      });

      setReward({ ...reward, contributors });
    }
  }, [workItem.reward, workItem.contributorIds, workItem.assigneeId]);

  useEffect(() => {
    if (workItem.workType === WorkTypes.TASK) {
      setReward({
        ...reward,
        sponsorGroupId: workItem.groupId,
      });
    }
  }, [workItem.groupId]);

  useEffect(() => {
    isFirstLoad.current = false;
  }, []);

  const onTokenSelection = useCallback(
    (contractAddress: string) => {
      const updatedReward = { ...reward, contractAddress };
      if (updatedReward.contractAddress === NETWORK_TOKEN_CONTRACT) {
        updatedReward.type = TokenTypes.NATIVE_CURRENCY;
      } else updatedReward.type = TokenTypes.ERC20;
      setReward(updatedReward);
    },
    [reward]
  );

  const onConfirmedToken = useCallback(
    (data: ITokenConfirmation) => {
      const updateClause: Partial<IReward> = {};
      if (data.name) {
        updateClause.tokenName = data.name;
      }
      if (data.symbol) {
        updateClause.symbol = data.symbol;
      }

      if (data.contractAddress) {
        updateClause.contractAddress = data.contractAddress;
      }

      const updatedReward = { ...reward, ...updateClause };

      if (updatedReward.contractAddress === NETWORK_TOKEN_CONTRACT) {
        updatedReward.type = TokenTypes.NATIVE_CURRENCY;
      } else updatedReward.type = TokenTypes.ERC20;
      setReward(updatedReward);
    },
    [reward]
  );

  const updateNetwork = (newNetwork: number) => {
    const defaultTokenData = getNetworkDefaultToken(newNetwork);

    setReward({
      ...reward,
      networkId: newNetwork,
      contractAddress: NETWORK_TOKEN_CONTRACT,
      symbol: defaultTokenData.symbol,
      tokenName: defaultTokenData.name,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <h4 className={styles.header}>
          {editing ? "Edit reward" : "Add reward"}
        </h4>
        <div className={styles.buttons}>
          {editing && (
            <Button
              buttonType={ButtonTypes.DEFAULT}
              disabled={!canManageReward}
              className={styles.deleteBtn}
              onClick={removeReward}
            >
              Delete
            </Button>
          )}
          <Button
            className={styles.saveBtn}
            buttonType={ButtonTypes.PRIMARY}
            isLoading={isLoading}
            disabled={
              !canManageReward ||
              reward.amount === 0 ||
              !reward.symbol ||
              rewardOverAllocated ||
              isLoading ||
              !reward.sponsorGroupId ||
              shallowEqual(reward, workItem?.reward)
            }
            onClick={canManageReward ? handleConfirm : undefined}
          >
            {editing ? "Save changes" : "Save reward"}
          </Button>
        </div>
      </div>
      <div className={styles.inputsContainer}>
        <div className={styles.inputRow}>
          <div className={styles.singleInputContainer}>
            <NetworkSelect
              selectedNetworkId={Number(reward.networkId)}
              disabled={reward.isAllocationConfirmed || !canManageReward}
              onSelectionChanged={updateNetwork}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.tokenSelectContainer}>
            <label className={styles.label}>Token</label>
            <TokenSelector
              networkId={Number(reward.networkId ?? web3Api.networkId)}
              tokenType={TokenTypes.ERC20}
              selectedItem={reward.contractAddress}
              onSelect={onTokenSelection}
              onConfirmedToken={onConfirmedToken}
              disabled={reward.isAllocationConfirmed || !canManageReward}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.singleInputContainer}>
            <ClarityInput
              label="Amount"
              min={0}
              value={reward.amount ?? 0}
              type="number"
              maxLength={15}
              placeholder={"Amount"}
              disabled={reward.isAllocationConfirmed || !canManageReward}
              onChange={(e) => {
                if (e.target.value.length > 20) {
                  return;
                }
                const value = Number(e.target.valueAsNumber);
                if (value) {
                  e.target.value = String(value);
                } else {
                  if (e.target.value !== "0" && e.target.value !== "") {
                    e.preventDefault();
                    return;
                  }
                }

                const getValue = () => {
                  if (e.target.valueAsNumber && !isNaN(e.target.valueAsNumber))
                    return e.target.valueAsNumber;
                  if (e.target.value) return reward.amount;
                  return 0;
                };

                setReward({
                  ...reward,
                  amount: Number(getValue()),
                });
              }}
            />
          </div>
        </div>
      </div>

      <Conditional on={workItem.workType !== WorkTypes.TASK}>
        <div className={styles.statusDivider} />
        <div className={styles.inputRow}>
          <div className={styles.singleInputContainer}>
            <SponsorGroupDisplay
              reward={reward}
              setSponsorGroupId={(groupId) => {
                setReward({
                  ...reward,
                  sponsorGroupId: groupId,
                });
              }}
              disabled={
                workItem.workType === WorkTypes.TASK ||
                reward.isAllocationConfirmed ||
                !canManageReward
              }
            />
          </div>
        </div>
        <div className={styles.splitDivider} />
        <div className={styles.splitContainer}>
          <div className={styles.topRow}>
            <span className={styles.header}>Split</span>
            <div className={styles.toggleContainer}>
              <ModeButton
                setReward={setReward}
                reward={reward}
                splitType={SplitType.currency}
                canManageReward={canManageReward}
              />
              <ModeButton
                setReward={setReward}
                reward={reward}
                canManageReward={canManageReward}
                splitType={SplitType.percentage}
              />
            </div>
          </div>

          <RewardSplit
            workItem={workItem}
            canManageReward={canManageReward}
            setrewardOverAllocated={setrewardOverAllocated}
            reward={reward}
            setReward={setReward}
          />
        </div>
      </Conditional>
      <div className={styles.statusDivider} />

      <div className={styles.statusContainer}>
        <div className={styles.topRow}>
          <span className={styles.header}>Status</span>
        </div>
        <div className={styles.statusInputsContainer}>
          <div className={styles.singleInputContainer}>
            <Checkbox
              className={`${styles.statusCheckbox} passwordRequirementBox`}
              checked={reward.isAllocationConfirmed}
              disabled={reward.isPaid || !canManageReward}
              onChange={() =>
                setReward({
                  ...reward,
                  isAllocationConfirmed: !reward.isAllocationConfirmed,
                })
              }
            >
              <span
                className={`caption regular secondary ${
                  reward.isPaid || !canManageReward ? "disabled" : ""
                }`}
              >
                Ready for payment
              </span>
            </Checkbox>
          </div>
          <div className={styles.singleInputContainer}>
            <Checkbox
              disabled={!reward.isAllocationConfirmed || !canManageReward}
              className={`${styles.statusCheckbox} passwordRequirementBox`}
              checked={reward.isPaid}
              onChange={() => {
                const newPaidState = !reward.isPaid;
                let newPaidDate: Date | null = new Date();
                if (!newPaidState) {
                  newPaidDate = null;
                }
                setReward({
                  ...reward,
                  isPaid: newPaidState,
                  datePaid: newPaidDate,
                });
              }}
            >
              <span
                className={`caption regular secondary ${
                  !reward.isAllocationConfirmed || !canManageReward
                    ? "disabled"
                    : ""
                }`}
              >
                Payment sent
              </span>
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

const RewardSplit: React.FC<{
  workItem: IProjectObj;
  reward: IReward;
  canManageReward: boolean;
  setReward: React.Dispatch<React.SetStateAction<IReward>>;
  setrewardOverAllocated: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  workItem,
  reward,
  setReward,
  setrewardOverAllocated,
  canManageReward,
}) => {
  const [unallocatedReward, setUnallocatedReward] = useState(reward.amount);

  useLayoutEffect(() => {
    const rewardAmount = reward.amount;
    let rewardLeft = rewardAmount;
    reward.contributors.forEach((contribution) => {
      let contributionValue = 0;
      if (reward.splitType === SplitType.currency)
        contributionValue = contribution.amount;
      if (reward.splitType === SplitType.percentage)
        contributionValue = exerptNumber(
          (contribution.amount / 100) * rewardAmount
        );
      rewardLeft = rewardLeft - contributionValue;
    });
    setUnallocatedReward(rewardLeft);
  }, [reward.amount, reward.contributors, reward.splitType]);

  useEffect(() => {
    const number = exerptNumber(unallocatedReward);
    setrewardOverAllocated(number < 0);
  }, [unallocatedReward]);

  const updateContribution = (
    key: "amount",
    index: number,
    newValue: string | number
  ) => {
    const updatedReward = { ...reward };
    updatedReward.contributors = [...updatedReward.contributors];
    const contribution = { ...updatedReward.contributors[index] };

    contribution.amount =
      Number(newValue) && Number(newValue) > 0 ? Number(newValue) : 0;
    contribution.amount = Number(exerptNumber(contribution.amount));

    updatedReward.contributors[index] = contribution;
    setReward(updatedReward);
  };

  return (
    <div className={styles.rewardSplit}>
      <div className={styles.splitContributors}>
        {reward.contributors.map((contributor, index) => (
          <div
            key={contributor.userId}
            className={styles.splitContributorEntry}
          >
            <UserDisplay
              avatarSize={24}
              key={contributor.userId}
              id={contributor.userId}
              labelClassname={`caption medium secondary ${styles.label}`}
              customStyles={{
                width: "100%",
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
              }}
              caption={contributor.reason === "assignee" ? "(Lead)" : ""}
            />
            <div className={styles.splitInputContainer}>
              <ClarityInput
                min={0}
                value={contributor.amount ?? 0}
                disabled={reward.isAllocationConfirmed || !canManageReward}
                onChange={(e) => {
                  const value = Number(e.target.valueAsNumber);
                  if (value) {
                    e.target.value = String(value);
                  } else {
                    if (e.target.value !== "0" && e.target.value !== "") {
                      e.preventDefault();
                      return;
                    }
                  }
                  const getValue = () => {
                    if (
                      e.target.valueAsNumber &&
                      !isNaN(e.target.valueAsNumber)
                    )
                      return e.target.valueAsNumber;
                    if (e.target.value) return contributor.amount;
                    return 0;
                  };

                  updateContribution("amount", index, Number(getValue()));
                }}
                type="number"
              />
            </div>
            <span className={`${styles.metricSymbol} caption medium secondary`}>
              {reward.splitType === SplitType.percentage ? (
                <PercentageOutlined />
              ) : (
                <NumberOutlined />
              )}
            </span>
          </div>
        ))}
        <div className={styles.bottomRow}>
          <div className={styles.addContributorBtnContainer}>
            <AddContributorBtn
              workItem={workItem}
              disabled={reward.isAllocationConfirmed || !canManageReward}
            />
          </div>
          <div className={styles.unallocatedContainer}>
            <span
              className={`caption medium secondary`}
              style={{ marginRight: "4px" }}
            >
              Unallocated:
            </span>{" "}
            <span className={`caption regular secondary`}>
              {unallocatedReward
                ? numberWithCommas(exerptNumber(unallocatedReward).toString())
                : 0}{" "}
              {reward.symbol} (
              {reward.amount
                ? exerptNumber((unallocatedReward / reward.amount) * 100)
                : 0}
              %)
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddContributorBtn: React.FC<{
  workItem: IProjectObj;
  disabled: boolean;
}> = ({ workItem, disabled }) => {
  const PaletteContexts = CommandPaletteContext;
  const handleContributorsClick = () => {
    openCommandPalette(PaletteContexts.CONTRIBUTORS, {
      selectedItemIds: [workItem.id],
      slectedItemsType: ContainerTypes.WORK,
    });
  };

  return (
    <Button
      disabled={disabled}
      icon={<PlusOutlined />}
      buttonType={ButtonTypes.DEFAULT}
      onClick={handleContributorsClick}
      className={styles.addContributorBtn}
    >
      Contributor
    </Button>
  );
};

const ModeButton: React.FC<{
  reward: IReward;
  setReward: React.Dispatch<React.SetStateAction<IReward>>;
  splitType: SplitType;
  canManageReward: boolean;
}> = ({ reward, splitType, setReward, canManageReward }) => {
  const clickAction = () => {
    if (reward.splitType === splitType) return;
    const updatedReward = { ...reward };
    const convertAmount = (amount: number) => {
      if (splitType === SplitType.percentage) {
        return Number(exerptNumber((amount / reward.amount) * 100));
      }
      if (splitType === SplitType.currency) {
        return Number(exerptNumber((amount * reward.amount) / 100));
      }
      return 0;
    };
    updatedReward.contributors = updatedReward.contributors.map(
      (contributor) => {
        const updatdContributor = { ...contributor };
        updatdContributor.amount = convertAmount(updatdContributor.amount);
        return updatdContributor;
      }
    );
    setReward({ ...updatedReward, splitType });
  };

  return (
    <Button
      onClick={clickAction}
      disabled={
        reward.isPaid || reward.isAllocationConfirmed || !canManageReward
      }
      iconStyle={reward.splitType === splitType ? { color: "#fff" } : undefined}
      style={
        reward.splitType === splitType ? { background: "#826274" } : undefined
      }
      buttonType={ButtonTypes.LINK}
      icon={
        splitType === SplitType.percentage ? (
          <PercentageOutlined />
        ) : (
          <NumberOutlined />
        )
      }
    />
  );
};

function FoldButton({ open, onClick }: any): JSX.Element {
  const className = useOptionalClassName({
    baseStyle: subworkstyles.foldButton,
    pairs: [
      {
        extraStyle: subworkstyles.foldButtonClosed,
        withExtra: !open,
      },
    ],
  });

  return (
    <Button
      onClick={onClick}
      className={className}
      icon={<img src={TriangleIcon} alt="Fold & unfold button" />}
      buttonType={ButtonTypes.LINK}
      style={{
        width: "20px",
        minWidth: "20px",
        minHeight: "20px",
        height: "20px",
        marginTop: "2px",
        paddingTop: "7px",
        position: "absolute",
        left: "-20px",
      }}
    />
  );
}

export default WorkReward;
