import {
  ADD_NEW_UNIQUE_USER,
  LOAD_BASE_SUBSCRIPTION,
  REMOVE_UNIQUE_USER,
  SET_ACTIVE_WORKSPACE,
  UPDATE_CURRENT_MONTHLY_COUNT,
  UPDATE_SUBSCRIPTION,
} from "store/actions";
import { ISubscriptionObj } from "utilities/types";

interface ISubscriptionActionParameters {
  type: string;
  subscription: ISubscriptionObj;
  numberOfInvites?: number;
}

interface ISubscriptionUpdateAction {
  type: string;
  param: {
    subdelta: Partial<ISubscriptionObj>;
  };
}

interface IBlockCountChange {
  type: string;
  param: {
    delta?: number;
    currentCount?: {
      workCount: number;
      blockCount: number;
    };
  };
}

export const MAX_FREE_BLOCK_COUNT = 550;
export const MAX_FREE_OPEN_WORK = 55;
export const LIMIT_FREE_BLOCK_COUNT = 500;
export const LIMIT_FREE_WORK = 50;

const initialState: ISubscriptionObj = {
  customerId: "",
  subscriptionId: "",
  productId: "",
  activeSubscription: "",
  subscriptionState: "",
  currentUniqueUsers: 1,
  allowedUniqueUsers: 1,
  isFree: false,
  recurring: "",
  paidCustomer: true,
  freeTrialProgress: true,
  trialEnd: "",
  cancel_at_period_end: false,
  monthlyCount: {
    blockCount: 0,
    workCount: 0,
  },
};
export default function subscriptionReducer(
  state = initialState,
  action: ISubscriptionActionParameters &
    IBlockCountChange &
    ISubscriptionUpdateAction
) {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE:
      return initialState;

    case LOAD_BASE_SUBSCRIPTION:
      if (action.subscription) {
        const newState = {
          ...state,
          ...action.subscription,
        };
        return newState;
      } else {
        return initialState;
      }

    case UPDATE_CURRENT_MONTHLY_COUNT: {
      if (action.param) {
        if (
          action.param.currentCount ||
          action.param.currentCount === 0 ||
          action.param.delta
        ) {
          const newState = {
            ...state,
            ...action.subscription,
          };
          if (action.param.currentCount || action.param.currentCount === 0) {
            newState.monthlyCount = action.param.currentCount;
            return newState;
          }
          if (action.param.delta) {
            newState.monthlyCount = { ...newState.monthlyCount };
            newState.monthlyCount.blockCount =
              newState.monthlyCount.blockCount + action.param.delta;
            if (newState.monthlyCount.blockCount < 0)
              newState.monthlyCount.blockCount = 0;
            return newState;
          }
        }
      }
      return state;
    }

    case UPDATE_SUBSCRIPTION: {
      if (action.param?.subdelta) {
        const newState = { ...state, ...action.param.subdelta };
        return newState;
      }
      return state;
    }
    case ADD_NEW_UNIQUE_USER:
      const newState = {
        ...state,
      };
      if (action.numberOfInvites) {
        newState.currentUniqueUsers =
          newState.currentUniqueUsers + action.numberOfInvites;
      } else {
        newState.currentUniqueUsers = newState.currentUniqueUsers + 1;
      }
      return newState;
    case REMOVE_UNIQUE_USER:
      const newState2 = {
        ...state,
        currentUniqueUsers: state.currentUniqueUsers - 1,
      };
      return newState2;
    default:
      return state;
  }
}
