import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import NavigationContext from "screens/base/NavigationContext";
import { GroupGeneralViews, TasksViewModes } from "utilities/types";

const GroupRouter: React.FC = () => {
  const params: any = useParams();

  return (
    <Switch>
      <Redirect
        path="/:baseSlug/group/:groupSlug/notes"
        to="/:baseSlug/group/:groupSlug/docs"
      />
      <Route
        path={["/:baseSlug/group/:groupSlug/docs"]}
        render={(props) => {
          return (
            <NavigationContext
              viewName={GroupGeneralViews.Notes}
              groupSlug={params.groupSlug}
            />
          );
        }}
      />

      <Route
        path={["/:baseSlug/group/:groupSlug/projects/archive"]}
        render={(props) => {
          return (
            <NavigationContext
              viewName={GroupGeneralViews.ProjectsArchive}
              groupSlug={params.groupSlug}
            />
          );
        }}
      />

      <Route
        path={["/:baseSlug/group/:groupSlug/projects"]}
        render={(props) => {
          return (
            <NavigationContext
              viewName={GroupGeneralViews.Projects}
              groupSlug={params.groupSlug}
            />
          );
        }}
      />

      <Route
        path="/:baseSlug/group/:groupSlug/tasks"
        render={(props) => {
          return (
            <NavigationContext
              viewName={GroupGeneralViews.Tasks}
              groupSlug={params.groupSlug}
              taskViewMode={TasksViewModes.Tasks}
            />
          );
        }}
      />

      <Redirect
        from="/:baseSlug/group/:groupSlug/cycles/closed"
        to="/:baseSlug/group/:groupSlug/sprints/closed"
      />

      <Route
        path="/:baseSlug/group/:groupSlug/sprints/closed"
        render={(props) => {
          return (
            <NavigationContext
              viewName={GroupGeneralViews.CyclesClosed}
              groupSlug={params.groupSlug}
              taskViewMode={TasksViewModes.ClosedCycles}
            />
          );
        }}
      />

      <Redirect
        from="/:baseSlug/group/:groupSlug/cycles"
        to="/:baseSlug/group/:groupSlug/sprints"
      />

      <Route
        path="/:baseSlug/group/:groupSlug/sprints"
        render={(props) => {
          return (
            <NavigationContext
              viewName={GroupGeneralViews.Cycles}
              groupSlug={params.groupSlug}
              taskViewMode={TasksViewModes.OpenCycles}
            />
          );
        }}
      />

      <Route
        path={["/:baseSlug/group/:groupSlug", "/:baseSlug/group/:groupSlug/"]}
        render={(props) => {
          return (
            <NavigationContext
              viewName={GroupGeneralViews.GroupProfile}
              groupSlug={params.groupSlug}
            />
          );
        }}
      />
    </Switch>
  );
};

export default GroupRouter;
