import {
  RESET_ROADMAP_VIEW,
  TOGGLE_CLOSED_PROJECT,
  UPDATE_ROADMAP_MILESTONE_MODE,
} from "store/actions";

export enum RoadmapMilestonesMode {
  all = "All",
  completed = "Completed",
  upcoming = "Upcoming",
}

export const DEFAULT_EMPTY_MILESTONE_MODE = RoadmapMilestonesMode.upcoming;

export interface IRoadmapFilterStateObj {
  [baseId: string]: {
    [viewId: string]: {
      showClosedProjects?: boolean;
      roadmapMilestonesMode?: RoadmapMilestonesMode;
    };
  };
}

const initialState = {};

export default function roadmapFilterReducer(
  state: IRoadmapFilterStateObj = initialState,
  action: any
) {
  switch (action.type) {
    case TOGGLE_CLOSED_PROJECT: {
      const newState = { ...state };
      if (newState[action.baseId]) {
        newState[action.baseId] = {
          ...newState[action.baseId],
        };
        if (newState[action.baseId][action.viewId]) {
          newState[action.baseId][action.viewId] = {
            ...newState[action.baseId][action.viewId],
            showClosedProjects: action.value,
          };
        } else {
          newState[action.baseId][action.viewId] = {
            showClosedProjects: action.value,
          };
        }
      } else {
        newState[action.baseId] = {
          [action.viewId]: {
            showClosedProjects: action.value,
          },
        };
      }
      return newState;
    }

    case UPDATE_ROADMAP_MILESTONE_MODE: {
      const newState = { ...state };
      if (newState[action.baseId]) {
        newState[action.baseId] = {
          ...newState[action.baseId],
        };
        if (newState[action.baseId][action.viewId]) {
          newState[action.baseId][action.viewId] = {
            ...newState[action.baseId][action.viewId],
            roadmapMilestonesMode: action.value,
          };
        } else {
          newState[action.baseId][action.viewId] = {
            roadmapMilestonesMode: action.value,
          };
        }
      } else {
        newState[action.baseId] = {
          [action.viewId]: {
            roadmapMilestonesMode: action.value,
          },
        };
      }
      return newState;
    }

    case RESET_ROADMAP_VIEW: {
      if (action.viewId && action.baseId) {
        const newState = { ...state };
        delete newState[action.baseId][action.viewId];
        return newState;
      }
      return state;
    }

    default:
      return state;
  }
}
