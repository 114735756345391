import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import store, { prevState } from "store/storeExporter";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { containerSearchFuse } from "utilities/fuseHelpers";
import {
  ILineValue,
  KeyCodes,
  LineMenuState,
  LineType,
  LineValueType,
} from "utilities/lineUtilities";
import styles from "editor/blockMenus/blockMenuVariants/tagMenu.module.scss";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ContainerTypes, IProjectObj, ITag, ViewNames } from "utilities/types";
import { UPDATE_WORK_ITEM } from "store/actions";
import { stripHtml } from "utilities/stringUtilities";
import { BlockPropsWithRef } from "editor/blockContainer/BlockSplitter";
import { updateBlockText } from "editor/utils/blockActions";
import { addNewBlockKeepValue } from "editor/utils/specificActions/addBlockActions";
import { checkCaretPosition } from "editor/utils/caretUtils";
import { getHtml } from "editor/utils/blockValueHelpers";
import { getCorrectLink } from "utilities/linkUtilities";
import navigationApi from "clientApi/navigationApi";

const getValue = (tag: ILineValue) => {
  let textValue = "";
  textValue = textValue + tag.value;
  if (tag.children && tag.children.length > 0) {
    tag.children.forEach((tagPart) => {
      textValue = textValue + getValue(tagPart);
    });
  }
  return textValue;
};

const RelatedPagesInner: React.FC<
  BlockPropsWithRef & {
    toggleFocusToInput: boolean;
    setToggleFocusToInput: (toggle: boolean) => any;
    workItemId?: string;
    workItem?: IProjectObj;
    shouldPersist: boolean;
    isFocused?: boolean;
    setisFocused?: any;
    isInWorkItem?: boolean;
    noteId?: string;
    destination?: string;
  }
> = (props) => {
  const [tagsArray, settagsArray] = useState<ILineValue[]>([]);
  const [selectedIndex, setselectedIndex] = useState<number | null>(null);
  const firstLoad = useRef(true);
  const editorRef = useRef<any>(null);
  const newRef = useRef<any>(null);
  const tagIds = useRef<string[]>([]);

  const getValueTags = useCallback(
    (value: ILineValue[], arrayOfTags: ILineValue[]) => {
      value.forEach((valueChunk) => {
        if (valueChunk.type === LineValueType.mention) {
          arrayOfTags.push(valueChunk);
        }
        if (valueChunk.children && valueChunk.children?.length > 0) {
          getValueTags(valueChunk.children, arrayOfTags);
        }
      });
    },
    []
  );

  const updateValue = useCallback(
    (tags: ILineValue[]) => {
      const tagsWithSpaces: ILineValue[] = [];
      const workItemSaveMode: ITag[] = [];
      tags.forEach((tag) => {
        const workItemEntry: ITag = {
          containerType: tag.options?.workTag
            ? ContainerTypes.WORK
            : ContainerTypes.DOCUMENT,
          containerId: tag.linkTo ? tag.linkTo : "",
        };
        workItemSaveMode.push(workItemEntry);
        tagsWithSpaces.push(tag);
        tagsWithSpaces.push({
          type: LineValueType.text,
          value: " ",
        });
      });
      newRef.current.innerHTML = getHtml(tagsWithSpaces);
      const eventCopy = {
        target: newRef.current,
        currentTarget: newRef.current,
      };
      updateBlockText(props.blockData, eventCopy, props.context, true);

      if (props.workItemId) {
        const workItem = { ...store.getState().work.dict[props.workItemId] };
        workItem.tags = workItemSaveMode;
        store.dispatch({
          type: UPDATE_WORK_ITEM,
          workItem,
        });
      }
    },
    [props.workItem, props.shouldPersist]
  );

  const checkOption = useCallback(
    (option, textOption?) => {
      const dummyTags = [...tagsArray];
      if (!option.root) {
        const newTag: ILineValue = {
          type: LineValueType.mention,
          children: [
            {
              type: LineValueType.text,
              value: option.projectId
                ? option.projectId + " " + stripHtml(getHtml(option.nameValue))
                : stripHtml(getHtml(option.nameValue)),
            },
          ],
          value: "",
          options: option.projectId
            ? {
                hashtag: true,
                workNr: String(option.projectId),
                workTag: true,
              }
            : {
                hashtag: true,
              },
          linkTo: option.id,
        };
        dummyTags.push(newTag);
      } else {
        if (textOption) {
          const newTag: ILineValue = {
            type: LineValueType.mention,
            children: [
              {
                type: LineValueType.text,
                value: textOption,
              },
            ],
            value: "",
            options: {
              hashtag: true,
            },
          };
          dummyTags.push(newTag);
        }
      }
      updateValue(dummyTags);
    },
    [tagsArray]
  );

  useEffect(() => {
    const arrayOfTags: ILineValue[] = [];
    getValueTags(props.blockData.value, arrayOfTags);
    settagsArray(arrayOfTags);
    tagIds.current = arrayOfTags.map((tag) => (tag.linkTo ? tag.linkTo : ""));
    firstLoad.current = true;
  }, [props.blockData.value]);

  useEffect(() => {
    firstLoad.current = true;
  }, []);
  let note;
  if (props.context.container.type === ContainerTypes.NOTE) {
    note =
      store.getState().notes.dict[props.context.container.id] ??
      store.getState().notes.weeklyNotesDict[props.context.container.id];
  }
  return (
    <>
      <TagArray
        fieldIsFocused={props.isFocused}
        tagsArray={note?.isWeekly ? tagsArray.slice(1) : tagsArray}
        selectedIndex={selectedIndex}
        destination={props.destination}
        setselectedIndex={setselectedIndex}
        updateValue={updateValue}
        staticTagArray={note?.isWeekly ? tagsArray.slice(0, 1) : []}
        note={note}
      />
      {props.isFocused && (
        <>
          <div
            style={{ display: "none" }}
            id={props.blockData.id}
            ref={newRef}
            dangerouslySetInnerHTML={{
              __html: getHtml(props.blockData.value),
            }}
          ></div>
          <EditorSection
            editorRef={editorRef}
            newRef={newRef}
            isFocused={props.isFocused}
            context={props.context}
            workItemId={props.workItemId}
            lineProps={props.blockData}
            blockRef={props.blockRef}
            setisFocused={props.setisFocused}
            tagIds={tagIds.current}
            checkOption={checkOption}
            toggleFocusToInput={props.toggleFocusToInput}
            value={props.blockData.value}
            tagsArray={tagsArray}
            isSelected={selectedIndex === null}
            setselectedIndex={setselectedIndex}
          />
        </>
      )}
    </>
  );
};

const TagArray: React.FC<any> = ({
  tagsArray,
  selectedIndex,
  setselectedIndex,
  updateValue,
  destination,
  fieldIsFocused,
  staticTagArray,
  note,
}) => {
  const selectedIndexRef = useRef(selectedIndex);
  useEffect(() => {
    selectedIndexRef.current = selectedIndex;
  }, [selectedIndex]);

  const moveSelection = useCallback(
    (type: "next" | "previous") => {
      const selectedIndex = selectedIndexRef.current;
      if (selectedIndex <= staticTagArray.length - 1 && note.isWeekly) {
        return;
      }
      if (type === "next") {
        setselectedIndex(
          selectedIndex === tagsArray.length - 1 ? null : selectedIndex + 1
        );
      } else {
        if (selectedIndex > 0) setselectedIndex(selectedIndex - 1);
      }
    },
    [tagsArray]
  );

  const deleteTag = useCallback(
    (type: "focusNext" | "focusPrevious", index?: number) => {
      const selectedIndex = index ? index : selectedIndexRef.current;
      if (selectedIndex <= staticTagArray.length - 1 && note?.isWeekly) {
        return;
      }
      const newTagArray = [...staticTagArray, ...tagsArray];
      newTagArray.splice(selectedIndex, 1);
      if (type === "focusPrevious") {
        if (newTagArray[selectedIndex - 1]) setselectedIndex(selectedIndex - 1);
        else if (!newTagArray[selectedIndex]) setselectedIndex(null);
      } else {
        if (!newTagArray[selectedIndex]) setselectedIndex(null);
      }
      updateValue([...newTagArray]);
    },
    [tagsArray]
  );

  return useMemo(() => {
    return (
      <>
        {[...staticTagArray, ...tagsArray].map(
          (tag: ILineValue, index: number) => (
            <TagEntry
              key={tag.linkTo ? tag.linkTo + index : index}
              tagValue={getValue(tag)}
              tag={tag}
              deleteTag={deleteTag}
              moveSelection={moveSelection}
              setselectedIndex={setselectedIndex}
              index={index}
              isSelected={index === selectedIndex}
              destination={destination}
              fieldIsFocused={
                staticTagArray.includes(tag) ? false : fieldIsFocused
              }
            />
          )
        )}
      </>
    );
  }, [tagsArray, selectedIndex, fieldIsFocused, staticTagArray]);
};

const TagEntry: React.FC<any> = (props) => {
  const [isFocused, setisFocused] = useState(false);
  const tagRef = useRef<HTMLSpanElement | null>(null);
  const history = useHistory();
  useEffect(() => {
    setisFocused(props.isSelected);
    if (props.isSelected) {
      tagRef.current?.focus();
    }
  }, [props.isSelected]);

  let styleObject = {
    margin: "2px 3px",
    position: "relative" as any,
  };
  if (props.isSelected) {
    styleObject = { ...styleObject, ...{ background: "#dedcdd" } };
  }
  if (props.index === 0) {
    styleObject = { ...styleObject, ...{ marginLeft: "0" } };
  }

  const handleNavigation = (e: any) => {
    const workTagCheck = props.tag.options?.workTag;
    const entityId = props.tag.linkTo;
    const base = prevState.value.workspace;
    const containerType =
      workTagCheck === true ? ContainerTypes.PROJECT : ContainerTypes.DOCUMENT;
    if (props.destination !== "secondary") {
      if (e.shiftKey) {
        navigationApi.openSplitView({
          viewName: ViewNames.Detail,
          containerId: entityId,
          containerType: containerType,
        });

        e.preventDefault();
      } else {
        if (containerType === ContainerTypes.DOCUMENT)
          history.push(`/${base.slug}/tags/${entityId}`);
        else {
          const entity = store.getState().work.dict[entityId];
          history.push(getCorrectLink(base, entity));
        }
        e.preventDefault();
        e.stopPropagation();
      }
    } else {
      if (!e.shiftKey) {
        navigationApi.openSplitView({
          viewName: ViewNames.Detail,
          containerId: entityId,
          containerType: containerType,
        });
        e.preventDefault();
      } else {
        if (containerType === ContainerTypes.DOCUMENT)
          history.push(`/${base.slug}/tags/${entityId}`);
        else {
          const entity = store.getState().work.dict[entityId];
          history.push(getCorrectLink(base, entity));
          // history.push(`/${base.slug}/work/${Number(entityNr)}`);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  return (
    <>
      <span
        className="mention hashtag confirmed"
        ref={tagRef}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleNavigation(e);
        }}
        style={styleObject}
        onMouseOver={() => setisFocused(true)}
        onMouseLeave={() => setisFocused(false)}
        tabIndex={0}
        onKeyDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          switch (e.keyCode) {
            case KeyCodes.arrow_right: {
              props.moveSelection("next");
              break;
            }
            case KeyCodes.arrow_left: {
              props.moveSelection("previous");
              break;
            }
            case KeyCodes.backspace: {
              props.deleteTag("focusPrevious");
              break;
            }
            case KeyCodes.delete: {
              props.deleteTag("focusNext");
              break;
            }
            case KeyCodes.enter: {
              e.preventDefault();
              e.stopPropagation();
              handleNavigation(e);
              break;
            }
            case KeyCodes.shift: {
              e.preventDefault();
              e.stopPropagation();
              break;
            }
            default: {
              props.setselectedIndex(null);
            }
          }
        }}
      >
        {"#"}
        {props.tagValue}
        {isFocused && props.fieldIsFocused && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.deleteTag("focusPrevious", props.index);
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            style={{
              position: "absolute",
              right: "-7px",
              top: "-8px",
              height: "100%",
            }}
          >
            <CloseCircleOutlined />
          </span>
        )}
      </span>
    </>
  );
};

const EditorSection: React.FC<any> = (props) => {
  const [isFocused, setisFocused] = useState(false);
  const [menuState, setmenuState] = useState<LineMenuState>({
    executeSelection: false,
    filterMenuBy: "",
    hoveredItem: 0,
    isAtBegining: true,
    isOpened: false,
    options: [],
    preOpenedCaretPosition: 0,
    type: "Hashtag",
  });
  const menuRef = useRef<any>(null);
  const checkerRef = useRef(false);
  const caretPositionRef = useRef(0);

  useEffect(() => {
    setmenuState({
      ...menuState,
      ...{ isOpened: isFocused && props.isSelected },
    });
    checkerRef.current = false;
  }, [isFocused, props.isSelected]);

  const updateMenuState = useCallback(
    (menuPatch: Partial<LineMenuState>) => {
      setmenuState({ ...menuState, ...menuPatch });
    },
    [menuState]
  );

  const resetMenuState = useCallback(() => {
    setmenuState({ ...menuState });
  }, []);

  useEffect(() => {
    props.editorRef.current.innerText = "";
    moveCaretToPreviousPosition(props.editorRef.current, 0);
    updateMenuState({ filterMenuBy: "", hoveredItem: 0, isOpened: false });
  }, [props.lineProps.value]);

  useEffect(() => {
    if (props.isSelected) {
      props.editorRef.current.innerHTML = "<br>";
      props.editorRef.current.focus();
      moveCaretToPreviousPosition(props.editorRef.current, 0);
    }
  }, [props.isSelected]);

  useEffect(() => {
    if (!menuState.isOpened && isFocused && props.isSelected) {
      updateMenuState({ isOpened: true });
    }
  }, [menuState.isOpened, isFocused, props.isSelected]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!document.activeElement?.contains(props.editorRef.current)) {
      props.editorRef.current.focus();
      moveCaretToPreviousPosition(props.editorRef.current, 0);
    }
  }, [props.toggleFocusToInput]);

  useEffect(() => {
    if (menuState.filterMenuBy === "")
      props.editorRef.current.innerHTML = "<br>";
  }, [menuState]);

  const handleClickOutside = useCallback((event: any) => {
    if (
      checkerRef.current === true &&
      props.isSelected &&
      props.editorRef.current &&
      !props.editorRef.current.contains(event.target) &&
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      props.blockRef?.current &&
      !props.blockRef?.current.contains(event.target)
    ) {
      setisFocused(false);
      props.setisFocused(false);
      resetMenuState();
    } else if (checkerRef.current !== true) {
      checkerRef.current = true;
    }
  }, []);

  return (
    <>
      {menuState.isOpened && (
        <span placeholder={"#"} style={{ opacity: "0.5" }}>
          #
        </span>
      )}

      <span
        ref={props.editorRef}
        contentEditable="true"
        className="no-outfocus"
        tabIndex={0}
        onSelect={() => {
          const caretPosition = checkCaretPosition(props.editorRef.current);
          caretPositionRef.current = caretPosition;
        }}
        onFocus={() => {
          batch(() => {
            setisFocused(true);
            updateMenuState({
              filterMenuBy: props.editorRef.current.innerText,
            });
            props.setselectedIndex(null);
          });
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          if (checkerRef.current === false) {
            checkerRef.current = true;
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ minHeight: "100%", minWidth: "2px", whiteSpace: "pre-wrap" }}
        onPaste={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const addedText = e.clipboardData.getData("text/plain");
          const textNode = document.createTextNode(addedText);
          const selection = document.getSelection();
          if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const newRange = range.cloneRange();
            range.insertNode(textNode);
            selection.removeAllRanges();
            newRange.setStartAfter(textNode);
            selection.addRange(newRange);
            updateMenuState({ filterMenuBy: e.currentTarget.innerText });
          }
        }}
        onInput={(e) => {
          const caretPosition = checkCaretPosition(props.editorRef.current);
          props.editorRef.current.innerText = e.currentTarget.textContent;
          if (e.currentTarget.innerHTML === "")
            props.editorRef.current.innerHTML = "<br>";
          updateMenuState({ filterMenuBy: e.currentTarget.innerText });
          moveCaretToPreviousPosition(props.editorRef.current, caretPosition);
        }}
        onKeyDown={(e) => {
          switch (e.keyCode) {
            case KeyCodes.tab: {
              e.preventDefault();
              e.stopPropagation();
              if (e.shiftKey) {
                if (menuState.hoveredItem > 0)
                  updateMenuState({ hoveredItem: menuState.hoveredItem - 1 });
              } else {
                if (
                  props.editorRef.current.innerText.trim().length === 0 &&
                  !props.workItemId
                ) {
                  setisFocused(false);
                  props.setisFocused(false);
                  resetMenuState();
                  // return moveUpDown(
                  //   props.lineProps.id,
                  //   null,
                  //   e,
                  //   TypeOfLineMovement.previousCursorState,
                  //   "fromUp",
                  //   0,
                  //   props.lineProps
                  // );
                }
                updateMenuState({ hoveredItem: menuState.hoveredItem + 1 });
              }
              break;
            }
            case KeyCodes.arrow_up: {
              if (menuState.hoveredItem > 0) {
                e.preventDefault();
                e.stopPropagation();
                updateMenuState({ hoveredItem: menuState.hoveredItem - 1 });
              }
              break;
            }
            case KeyCodes.arrow_down: {
              e.preventDefault();
              if (
                props.editorRef.current.innerText.trim().length === 0 &&
                !props.workItemId
              ) {
                setisFocused(false);
                props.setisFocused(false);
                resetMenuState();
                return;
              }
              e.stopPropagation();
              updateMenuState({ hoveredItem: menuState.hoveredItem + 1 });
              break;
            }
            case KeyCodes.arrow_left: {
              e.stopPropagation();
              const caretPosition = checkCaretPosition(props.editorRef.current);
              if (
                caretPosition === 0 &&
                props.tagsArray[props.tagsArray.length - 1]
              ) {
                e.preventDefault();
                e.stopPropagation();
                props.setselectedIndex(props.tagsArray.length - 1);
                break;
              }
              break;
            }
            case KeyCodes.backspace: {
              e.stopPropagation();
              const selection = document.getSelection();
              if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                if (range.collapsed) {
                  const caretPosition = checkCaretPosition(
                    props.editorRef.current
                  );
                  if (caretPosition === 0) {
                    if (props.tagsArray.length > 0) {
                      props.setselectedIndex(props.tagsArray.length - 1);
                      e.preventDefault();
                    }
                  }
                }
              }
              break;
            }
            case KeyCodes.enter: {
              e.preventDefault();
              e.stopPropagation();
              if (props.editorRef.current.innerText.trim().length === 0) {
                if (
                  props.context &&
                  props.context.container.type === ContainerTypes.NOTE
                ) {
                  setisFocused(false);
                  props.setisFocused(false);
                  resetMenuState();
                  addNewBlockKeepValue(props.lineProps, props.context, {
                    lineType: LineType.text,
                  });
                }
              } else {
                updateMenuState({ executeSelection: true });
              }
              break;
            }
            default: {
              e.stopPropagation();
            }
          }
        }}
      />
      {menuState.isOpened && props.editorRef.current && (
        <Dropdown
          placement="bottomRight"
          overlay={
            <TagsMenu
              parentProps={props.lineProps}
              menuRef={menuRef}
              menuState={menuState}
              editorRef={props.editorRef.current}
              setMenuState={setmenuState}
              updateMenuState={updateMenuState}
              checkOption={props.checkOption}
              tagIds={props.tagIds}
              checkerRef={checkerRef}
              caretPositionRef={caretPositionRef}
            />
          }
          visible={menuState.isOpened}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          ></div>
        </Dropdown>
      )}
    </>
  );
};

const getCorrectValue = (option: any) => {
  if (option.projectId)
    return "#" + option.projectId + " " + getHtml(option.nameValue);
  return getHtml(option.nameValue);
};

const TagsMenu: React.FC<any> = (props) => {
  const optionsToFilterRef = useRef<any[]>([]);

  useEffect(() => {
    const documents = Object.values(prevState.value.pages.dict);
    let workItems: any[] = [];
    workItems = Object.values(prevState.value.work.dict);
    optionsToFilterRef.current = [...documents, ...workItems];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let hoveredOption = props.menuState.hoveredItem;
  const firstEl = [
    {
      name: 'Create "' + props.menuState.filterMenuBy + '"',
      id: "idString",
      root: true,
    },
  ];

  let optionsToDisplay: any[] = [];
  if (props.menuState.filterMenuBy !== "") {
    optionsToDisplay = containerSearchFuse(
      optionsToFilterRef.current,
      props.menuState.filterMenuBy.trim()
    ).slice(0, 14);

    if (
      optionsToDisplay[0]?.name.toLowerCase().trim() !==
      props.menuState.filterMenuBy.toLowerCase().trim()
    )
      optionsToDisplay = [...firstEl, ...optionsToDisplay];
  } else {
    optionsToDisplay = [optionsToDisplay[0]];
  }

  useEffect(() => {
    if (
      optionsToDisplay.length > 0 &&
      props.menuState.hoveredItem >= optionsToDisplay.length
    ) {
      hoveredOption = optionsToDisplay.length - 1;
      if (hoveredOption < 0) hoveredOption = 0;
      const menuStateCopy = { ...props.menuState };
      menuStateCopy.hoveredItem = hoveredOption;
      props.setMenuState(menuStateCopy);
    }
  }, [props]);

  useEffect(() => {
    const el = document.getElementById(
      "menuItem" + props.menuState.hoveredItem
    );
    el?.scrollIntoView({ block: "nearest" });
  }, [props.menuState.hoveredItem]);

  useEffect(() => {
    if (props.menuState.executeSelection === true) {
      const option = optionsToDisplay[props.menuState.hoveredItem];
      if (!props.tagIds.includes(option.id))
        props.checkOption(option, props.menuState.filterMenuBy);
      const menuStateCopy = { ...props.menuState };
      menuStateCopy.executeSelection = false;
      props.setMenuState(menuStateCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuState.executeSelection]);

  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  let styleObject: any = {
    left: `${x}px`,
    top: `${y}px`,
  };
  if (selection && selection.rangeCount > 0) {
    const range: Range = selection.getRangeAt(0);
    const boundingRect = range.getBoundingClientRect();
    let rangeX = boundingRect.x;
    let rangeY = boundingRect.bottom;
    if (rangeX === 0) {
      const placeholder = props.editorRef;
      if (placeholder) {
        const placeholderSizes = placeholder.getBoundingClientRect();
        rangeX = placeholderSizes.x;
        rangeY = placeholderSizes.bottom;
      }
    }
    // let componentX = props.editorRef
    //   .closest(".section-big")
    //   .getBoundingClientRect().x;
    // let componentY = props.editorRef
    //   .closest(".section-big")
    //   .getBoundingClientRect().top;

    let componentX = props.editorRef
      .closest(".flex-section")
      .getBoundingClientRect().x;
    let componentY = props.editorRef
      .closest(".flex-section")
      .getBoundingClientRect().top;

    x = rangeX === 0 ? 10 : rangeX - componentX;
    y = rangeY === 0 ? 10 : rangeY - componentY;

    if (componentX + x + 350 > window.outerWidth) {
      styleObject = {
        right: `${-x}px`,
        top: `${y}px`,
      };
    } else {
      styleObject = {
        left: `${x}px`,
        top: `${y}px`,
      };
    }
  }

  return (
    <>
      <div
        className={styles.tagCompleteMenuContainer}
        ref={props.menuRef}
        style={styleObject}
      >
        <Menu
          className={styles.tagCompleteMenu + " no-outfocus"}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            e.domEvent.preventDefault();
          }}
          selectable
        >
          {props.menuState.filterMenuBy.trim().length === 0 ||
          props.menuState.filterMenuBy.trim() === "" ? (
            <Menu.Item
              style={{ opacity: "0.5" }}
              key={"0"}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onClick={(e) => {
                e.domEvent.stopPropagation();
                e.domEvent.preventDefault();
              }}
              className={`${styles.tagCompleteMenu__menuItem} ${styles.tagCompleteMenu__menuItem__disabled} ${styles.tagCompleteMenu__menuItem__placeholder}`}
            >
              Create a new tag or insert an existing one
            </Menu.Item>
          ) : (
            optionsToDisplay.map((option, index) => {
              const inserted = props.tagIds.includes(option.id);
              let customStyle = {};
              if (inserted)
                customStyle = { ...customStyle, ...{ color: "#95dbc4" } };

              return (
                <Menu.Item
                  key={index}
                  id={"menuItem" + index}
                  role="button"
                  onClick={(e) => {
                    e.domEvent.stopPropagation();
                    e.domEvent.preventDefault();
                    if (!props.tagIds.includes(option.id))
                      props.checkOption(option, props.menuState.filterMenuBy);
                    moveCaretToPreviousPosition(
                      props.editorRef,
                      props.caretPositionRef.current
                    );
                  }}
                  className={
                    hoveredOption === index
                      ? `${styles.tagCompleteMenu__menuItem} ${styles.nice}`
                      : styles.tagCompleteMenu__menuItem
                  }
                >
                  {option.root ? (
                    <span>
                      <PlusOutlined
                        className={styles.tagCompleteMenu__menuItem__icon}
                      />
                    </span>
                  ) : option.projectId ? (
                    <span style={customStyle}>
                      <CheckCircleOutlined
                        className={styles.tagCompleteMenu__menuItem__icon}
                      />
                    </span>
                  ) : (
                    <span style={customStyle}>
                      <TagOutlined
                        className={styles.tagCompleteMenu__menuItem__icon}
                      />
                    </span>
                  )}
                  {option.nameValue ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getCorrectValue(option),
                      }}
                      className={
                        styles.tagCompleteMenu__menuItem__labelContainer
                      }
                    ></span>
                  ) : (
                    <span
                      className={
                        styles.tagCompleteMenu__menuItem__labelContainer
                      }
                    >
                      {option.name}
                    </span>
                  )}
                  {inserted && (
                    <span
                      className={
                        styles.tagCompleteMenu__menuItem__CheckCircleOutlined
                      }
                    >
                      <CheckCircleOutlined />
                    </span>
                  )}
                </Menu.Item>
              );
            })
          )}
        </Menu>
      </div>
    </>
  );
};

export default RelatedPagesInner;
