import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { batch, useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  CommandPaletteContext,
  DocumentModes,
  UserRole,
} from "utilities/types";
import * as actionTypes from "../store/actions";
import FluidLoader from "icons/fluid-loader.svg";
import FluidLoaderDark from "icons/fluid-loader-dark.svg";
import styles from "./base/base.module.scss";
import { KeyCodes } from "utilities/lineUtilities";
import store, {
  $documentMode,
  $focusOn,
  ClarityStore,
} from "store/storeExporter";
import ModalCitationSyncCompare from "components/ModalCitationSyncCompare";
import ModalNewTask from "components/ModalNewTask";
import ImageModal from "./base/main/ImageModal";
import Helmet from "react-helmet";
import { setTimeout } from "timers";
import ConfirmationModal from "components/ConfirmationModal";
import { ChunkDestination } from "utilities/stateTypes";
import {
  getAllBlockTree,
  getSortedSelectedBlocks,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import ModalSelectItem from "clarity-ui/ModalSelectItem";
import ModalInvitations from "screens/base/main/settings/members/ModalInvitations";
import InviteGroupMemberModal from "screens/base/main/settings/members/InviteGroupMemberModal";
import { useCollaborators } from "screens/base/main/settings/Members";
import FiltersModal from "components/FiltersModal";
import DefaultModalView from "clarity-ui/DefaultModalView";
import MonetizationCard from "clarity-ui/MonetizationCard";
import _ from "lodash";
import SharingAndPermsModal from "components/SharingAndPermsModal";
import ContainerInviteModal from "screens/base/main/detailView/documentContainer/document/ContainerInviteModal";
import {
  useCheckBaseSettingsHasChanges,
  useContainerInviteModalState,
  useFullSearchActivator,
  useQuickSearchActivator,
  useSettingsOpened,
} from "store/reducers/clientReducer";
import BaseView from "./base/BaseView";

import { sidebarApi } from "clientApi/sidebarApi";
import { SidebarModes } from "store/reducers/sidebarReducer";
import StayInLoop from "clarity-ui/StayInLoop";
import GroupSelector from "components/GroupSelector";
import { getContainerHtml } from "editor/utils/blockValueHelpers";
import { useBaseLoader } from "./base/baseHelpers/baseLoadersUtils";
import { useShallowSelector } from "utilities/hooks";
import BaseNotFound from "./BaseNotFound";
import ActionInterceptor from "components/ActionInterceptor";
import { Modal } from "antd";
import Settings from "./base/main/Settings";
import { baseNavigationSubject } from "./base/main/settings/FooterForSave";
import DiscordConnection from "./base/main/DiscordConnection";
import NewTaskTemplate from "components/NewTaskTemplate";
import BasePalette from "components/commandPalette/BasePalette";
import NewSnippetModal from "components/NewSnippetModal";
import CloseSquareTwoTone from "icons/Components/CloseSquareTwoTone";
import JoinClosedGroupModal from "components/JoinClosedGroupModal";
import TemplateItemPreviewModal from "./base/main/TemplateItemPreviewModal";
import PeekViewListener from "./base/PeekViewListener";

interface Props {
  baseSlug: string;
}

// TODO: Clean up these handlers
function useGlobalKeyboardShortcuts() {
  const dispatch = useDispatch();
  const activateQuickSearch = useQuickSearchActivator();
  const activateFullSearch = useFullSearchActivator();

  interface GlobalKeyboardShortcut {
    eventChecker: (e: KeyboardEvent, keyCode: number) => boolean;
    dispatcher: () => void;
  }

  useEffect(() => {
    const shortcuts: GlobalKeyboardShortcut[] = [
      {
        eventChecker: (event, keyCode) =>
          (event.ctrlKey || event.metaKey) && keyCode === KeyCodes.p,
        dispatcher: activateQuickSearch,
      },
      {
        eventChecker: (event, keyCode) =>
          (event.ctrlKey || event.metaKey) && keyCode === KeyCodes.k,
        dispatcher: () =>
          dispatch({
            type: actionTypes.SHOW_COMMAND_PALETTE,
            context: CommandPaletteContext.GENERAL,
          }),
      },
      {
        eventChecker: (event, keyCode) =>
          (event.ctrlKey || event.metaKey) &&
          [KeyCodes.num0, KeyCodes.numpPad0].includes(keyCode) &&
          event.shiftKey,
        dispatcher: () => dispatch({ type: actionTypes.TOGGLE_SECONDARY_VIEW }),
      },
      {
        eventChecker: (event, keyCode) =>
          (event.ctrlKey || event.metaKey) &&
          [KeyCodes.num0, KeyCodes.numpPad0].includes(keyCode) &&
          !event.shiftKey,
        dispatcher: () => sidebarApi.toggleSidebarMode(),
      },
    ];
    const priorityShortcuts: GlobalKeyboardShortcut[] = [
      {
        eventChecker: (event, keyCode) =>
          (event.ctrlKey || event.metaKey) && keyCode === KeyCodes.p,
        dispatcher: activateQuickSearch,
      },
      {
        eventChecker: (event, keyCode) =>
          (event.ctrlKey || event.metaKey) &&
          event.shiftKey &&
          keyCode === KeyCodes.f,
        dispatcher: () => {
          const role = store.getState().client.roleType;
          if (role === UserRole.GUEST) return;
          sidebarApi.setSidebarMode({
            newMode: SidebarModes.search,
            isOpen: true,
          });
          activateFullSearch();
        },
      },
    ];

    document.addEventListener(
      "keydown",
      (event) =>
        priorityShortcuts.forEach((shortcut) => {
          if (shortcut.eventChecker(event, event.keyCode)) {
            event.preventDefault();
            shortcut.dispatcher();
          }
        }),
      { capture: true }
    );
    document.addEventListener("keydown", (event) =>
      shortcuts.forEach((shortcut) => {
        if (shortcut.eventChecker(event, event.keyCode)) {
          event.preventDefault();
          shortcut.dispatcher();
        }
      })
    );

    return () => {
      document.removeEventListener(
        "keydown",
        (event) =>
          priorityShortcuts.forEach((shortcut) => {
            if (shortcut.eventChecker(event, event.keyCode)) {
              event.preventDefault();
              shortcut.dispatcher();
            }
          }),
        { capture: true }
      );
      document.removeEventListener("keydown", (event) =>
        shortcuts.forEach((shortcut) => {
          if (shortcut.eventChecker(event, event.keyCode)) {
            event.preventDefault();
            shortcut.dispatcher();
          }
        })
      );
    };
  }, []);
}

window.addEventListener("dragover", (event) => {
  event.preventDefault();
});

window.addEventListener("drop", (event) => {
  event.preventDefault();
});

const sanitizeCopy = (el: HTMLDivElement) => {
  if (el.getElementsByClassName("single-row").length > 0) {
    let newFragment = document.createDocumentFragment();
    let newText = { current: "" };
    let lastElement = null;
    recursiveSanitizer(el, 0, newFragment, newText, lastElement);
    return {
      newFragment,
      newText: newText.current,
    };
  } else return null;
};

const recursiveSanitizer = (
  element: Element,
  indentLevel: number,
  parent: DocumentFragment | HTMLElement,
  newText: { current: string },
  lastElement: Element | null
) => {
  Array.from(element.children).forEach((child, index) => {
    if (child.classList.contains("content-section")) {
      const newDiv = document.createElement(indentLevel === 0 ? "p" : "li");
      newDiv.innerHTML = child.innerHTML;
      parent.appendChild(newDiv);
      lastElement = newDiv;
      let spacing = "";
      for (let i = 0; i < indentLevel - 1; i++) {
        spacing = spacing + "\t";
      }
      if (indentLevel > 0) spacing = spacing + "- ";

      newText.current = newText.current + spacing + child.textContent + "\n";
    } else if (child.nodeName === "OL") {
      const classListText = child.getAttribute("class");
      if (classListText?.includes("number")) {
        const newList = document.createElement("ol");
        parent.appendChild(newList);
        recursiveSanitizer(child, indentLevel + 1, newList, newText, null);
      } else {
        const newList = document.createElement("ul");
        parent.appendChild(newList);
        recursiveSanitizer(child, indentLevel + 1, newList, newText, null);
      }
    } else recursiveSanitizer(child, indentLevel, parent, newText, lastElement);
  });
};

export const executeCopy = (event: ClipboardEvent) => {
  const selection = document.getSelection();
  const storeState = store.getState();
  const copyOptions = {
    sourceBase: storeState.workspace.id,
  };

  event.clipboardData?.setData("clarity/internal", JSON.stringify(copyOptions));
  event.preventDefault();
  if (
    selection &&
    selection.toString().length > 0 &&
    selection.toString() !== "clarity-citation-copy-&-"
  ) {
    const range = selection.getRangeAt(0);

    const el = document.createElement("div");
    el.appendChild(range.cloneContents());

    const data = sanitizeCopy(el);

    if (data) {
      const innerCopy = document.createElement("div");
      innerCopy.appendChild(document.createElement("p"));
      innerCopy.appendChild(data.newFragment);
      event.clipboardData?.setData("text/html", innerCopy.innerHTML);
      event.clipboardData?.setData("text/plain", data.newText);
    } else if (el.innerText.length > 0) {
      event.clipboardData?.setData("text/html", el.innerHTML);
      event.clipboardData?.setData("text/plain", el.innerText);
    } else {
      event.clipboardData?.setData("text/plain", selection.toString());
    }

    return;
  }

  event.clipboardData?.setData("clarity/internal", JSON.stringify(copyOptions));

  let focusId = null;
  focusId = $focusOn.value.focusBlockId;

  let selectedBlocks: string[] = [];

  if (
    storeState.blocks.selectedBlocks.length > 0 &&
    $documentMode.value === DocumentModes.BLOCK
  )
    selectedBlocks = getSortedSelectedBlocks(storeState.blocks.selectedBlocks);
  else if (focusId) selectedBlocks.push(focusId);

  const selectedAndFocused = focusId
    ? _.uniq([...selectedBlocks, focusId])
    : selectedBlocks;
  const blockEntities = storeState.blocks.dict;
  const fullTreeBlocks = _.uniq(
    getSortedSelectedBlocks(
      [...getAllBlockTree(selectedAndFocused, blockEntities, [])],
      true
    )
  );
  if (!fullTreeBlocks) return;
  if (!focusId) return;
  if (fullTreeBlocks.length === 0) fullTreeBlocks.push(focusId);

  event.clipboardData?.setData(
    "clarity/blocks",
    JSON.stringify({
      clonedBlocks: fullTreeBlocks,
    })
  );
  const res = getContainerHtml(selectedBlocks, blockEntities);
  const fragment = res.fragment;
  const text = res.text;
  const el = document.createElement("div");
  el.appendChild(document.createElement("p"));
  el.appendChild(fragment);

  event.clipboardData?.setData("text/html", el.innerHTML);
  event.clipboardData?.setData("text/plain", text.current);
};

document.addEventListener("copy", (event) => {
  executeCopy(event);
});

function Base(props: Props) {
  const baseId = useShallowSelector((store) => store.workspace.id);
  const { baseSlug } = props;

  useGlobalKeyboardShortcuts();

  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (isLoading) setShowSpinner(true);
    }, 800);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setShowSpinner(false);
    setTimeout(() => {
      setShowSpinner(true);
    }, 800);
  }, [baseSlug]);

  useEffect(() => {
    batch(() => {
      store.dispatch({
        type: "RESET_WORK_ACTIVITY",
        destination: ChunkDestination.taskComments,
      });
      store.dispatch({
        type: "CLEAR_FILTER",
      });
    });
  }, []);

  useBaseLoader(baseSlug, setIsLoading, setShowSpinner, setIsNotFound);
  const isCurrentTheme = localStorage.getItem("dark");
  return (
    <div className={styles.baseContainer}>
      <BaseHeader />
      <BaseListeners baseId={baseId} />
      {useMemo(() => {
        if (isNotFound) {
          return (
            <div style={{ width: "100%" }}>
              <BaseNotFound />
            </div>
          );
        }
        if (isLoading && !isNotFound) {
          return (
            <>
              {showSpinner && (
                <div
                  style={{
                    width: "164px",
                    height: "275px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={
                      isCurrentTheme === "dark-mode"
                        ? FluidLoaderDark
                        : FluidLoader
                    }
                    alt="Loading Animation"
                    style={{
                      margin: "auto",
                      borderRadius: "6px",
                      marginBottom: "28px",
                      display: "block",
                    }}
                  />
                  <span className="caption disabled secondary">
                    be water my friend
                  </span>
                </div>
              )}
            </>
          );
        }
        return <BaseView id={baseId} />;
      }, [baseId, isNotFound, isLoading, showSpinner])}
    </div>
  );
}

const BaseHeader: React.FC = () => {
  const baseName = useSelector(
    (store: ClarityStore) => store.workspace?.name,
    shallowEqual
  );
  return (
    <Helmet>
      <title>{`${baseName} • Clarity`}</title>
    </Helmet>
  );
};

const BaseListeners: React.FC<{ baseId: string }> = ({ baseId }) => {
  const containerInviteModal = useContainerInviteModalState();

  const listenersState = useSelector((state: ClarityStore) => {
    return {
      allDiscussionsModal: state.client.allDiscussionsModal,
      isCommandPaletteVisible: state.commandPalette.display,
      isConfirmationModalVisible:
        state.confirmationModal.confirmationModal.display,
      isSelectGroupModalVisible:
        state.confirmationModal.selectGroupModal.display,
      isModalNewTaskVisible: state.client.isModalNewTaskVisible,
      isModalNewSnippetVisible: state.client.isModalNewSnippetVisible,
      isNewTemplateVisible: state.client.isNewTemplateVisible,
      isShareAndPermissionModalOpen: state.client.sharingAndPermsModal,
      showLeaveBaseConfirmation: state.client.showLeaveBaseConfirmation,
      // taskModalData: state.client.taskModalData,
      templateModalData: state.client.templateModalData,
      imageModal: state.client.imageModal,
      invitationsModal: state.client.invitationsModal,
      groupInvitationsModal: state.client.groupInvitationsModal,
      filtersModal: state.client.filtersModal,
      needsEmailConfirmation:
        state.user?.gettingStartedProgress === -1 &&
        !state.user.email &&
        state.user.workspaceIds.length > 0,
      discordIntegrationModal: state.client.discordIntegrationModal,
      joinClosedGroupModal: state.client.modalJoinClosedGroup,
    };
  }, shallowEqual);

  const templateItem = useShallowSelector((store) =>
    listenersState.templateModalData?.id
      ? store.templates.dict[listenersState.templateModalData.id]
      : undefined
  );

  return (
    <>
      {listenersState.isCommandPaletteVisible && <BasePalette />}
      {listenersState.isConfirmationModalVisible && <ConfirmationModal />}
      {listenersState.isSelectGroupModalVisible && <GroupSelector />}
      <ModalSelectItem />
      <DefaultModalView />
      <SharingAndPermsModal />
      <ModalCitationSyncCompare />
      <MonetizationCard />
      <SettingsModalListener />
      <PeekViewListener />
      {listenersState.needsEmailConfirmation && <StayInLoop />}

      {listenersState.templateModalData &&
        listenersState.templateModalData.isOpen &&
        listenersState.templateModalData.id &&
        templateItem && (
          <TemplateItemPreviewModal
            hideModal={() => {
              store.dispatch({
                type: actionTypes.TOGGLE_TEMPLATE_MODAL,
                isOpen: false,
                id: null,
              });
            }}
            template={templateItem}
          />
        )}
      {listenersState.isModalNewTaskVisible && <ModalNewTask />}
      {listenersState.isModalNewSnippetVisible && <NewSnippetModal />}
      {listenersState.isNewTemplateVisible && <NewTaskTemplate />}
      {listenersState.joinClosedGroupModal?.isOpen && <JoinClosedGroupModal />}
      {listenersState.imageModal && listenersState.imageModal.isOpen && (
        <ImageModal />
      )}
      {listenersState.groupInvitationsModal &&
        listenersState.groupInvitationsModal.isOpen && (
          <GroupModalInvitationsHoc
            baseId={baseId}
            groupId={listenersState.groupInvitationsModal.groupId}
          />
        )}
      {listenersState.invitationsModal &&
        listenersState.invitationsModal.isOpen && (
          <ModalInvitationsHoc baseId={baseId} />
        )}
      {listenersState.filtersModal && listenersState.filtersModal.isOpen && (
        <FiltersModal />
      )}
      <ActionInterceptor />
      {containerInviteModal && containerInviteModal.isOpen ? (
        <ContainerInviteModal />
      ) : (
        <></>
      )}
      {listenersState.discordIntegrationModal &&
      listenersState.discordIntegrationModal.isOpen ? (
        <DiscordConnection />
      ) : (
        <></>
      )}
    </>
  );
};

const ModalInvitationsHoc: React.FC<{ baseId: string; groupId?: string }> = ({
  baseId,
}) => {
  const { pendingCollaborators, refetchCollaborators } = useCollaborators({
    getStarted: false,
    baseId: baseId,
  });

  return (
    <ModalInvitations
      refetchCollaborators={refetchCollaborators}
      currentNumberOfInvites={pendingCollaborators.length}
    />
  );
};

const GroupModalInvitationsHoc: React.FC<{
  baseId: string;
  groupId?: string;
}> = ({ baseId, groupId }) => {
  const { pendingCollaborators, refetchCollaborators } = useCollaborators({
    getStarted: false,
    baseId: baseId,
  });

  return (
    <InviteGroupMemberModal
      refetchCollaborators={refetchCollaborators}
      currentNumberOfInvites={pendingCollaborators.length}
      groupId={groupId}
    />
  );
};

const SettingsModalListener: React.FC = () => {
  const checkIfSettingsHaveChanges = useCheckBaseSettingsHasChanges();

  const isOpenedDict = useSettingsOpened();

  const onClose = () => {
    if (checkIfSettingsHaveChanges) {
      baseNavigationSubject.next(true);
      return;
    }
    store.dispatch({
      type: actionTypes.OPEN_BASE_SETTING_SET_INTIAL,
      param: {
        openSettings: {},
      },
    });
  };

  if (!isOpenedDict?.isOpen) return <></>;

  return (
    <Modal
      style={{
        top: 0,
        bottom: 0,
        padding: 0,
        margin: 0,
        height: "100vh",
      }}
      visible={isOpenedDict.isOpen}
      closeIcon={<CloseSquareTwoTone />}
      width={"100vw"}
      className="base-seeting-modal"
      onCancel={() => onClose()}
      footer={null}
      transitionName=""
    >
      <Settings />
    </Modal>
  );
};

export default memo(Base);
