import _ from "lodash";
import { READ_ALL, SET_CHANGELOGS } from "store/actions";
import { IChangelog } from "utilities/types";

interface ChangelogEntry {
  [key: string]: IChangelog;
}
interface IInitialState {
  changelogDict: ChangelogEntry;
  ids: string[];
  readAll: boolean;
}

const initialState: IInitialState = {
  changelogDict: {} as ChangelogEntry,
  ids: [],
  readAll: true,
};

interface ISetChangelogs {
  type: string;
  changelogs: IChangelog[];
}

export default function changelogReducer(
  state: IInitialState = initialState,
  action: ISetChangelogs
) {
  switch (action.type) {
    case SET_CHANGELOGS: {
      const newState: IInitialState = {
        ...initialState,
        changelogDict: { ...state.changelogDict },
        ids: [...state.ids],
      };

      if (action.changelogs && Array.isArray(action.changelogs)) {
        const ids = action.changelogs.map((changelog) => changelog.id);

        newState.ids = _.uniq(ids);
        let readAll = true;
        for (const changelog of action.changelogs) {
          if (!changelog.read) {
            readAll = false;
          }

          newState.changelogDict[changelog.id] = {
            ...changelog,
          };
        }
        newState.readAll = readAll;
      }
      return newState;
    }
    case READ_ALL: {
      const newState: IInitialState = {
        ...state,
        changelogDict: { ...state.changelogDict },
        ids: [...state.ids],
      };

      newState.readAll = true;

      const changelogs = Object.values(newState.changelogDict);
      for (const changelog of changelogs) {
        newState.changelogDict[changelog.id] = {
          ...newState.changelogDict[changelog.id],
          read: true,
        };
      }
      return newState;
    }
    default:
      return state;
  }
}
