// Vendor Libraries
import { useState, useRef, useEffect } from "react";

import {
  LoadingOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styles from "./photoUpload/photoUpload.module.scss";
import short from "short-uuid";
import Button, { ButtonTypes } from "components/Button";
import { axiosInstance } from "index";
// interface onUploadSucess { (myArgument: string): string }
type onUploadSucess = (name: any) => any;
interface PhotoUploadProps {
  onUploadSucess: onUploadSucess;
  bucketName: string;
  fallBackComponent?: any;
  exitingUrl?: string;
  disabled?: boolean;
}

export default function PhotoUpload(props: PhotoUploadProps) {
  const {
    inputAvatarUpload,
    handleUploadImageChange,
    handleUploadImageClick,
    removeImage,
    avatarUrl,
    isAvatarUploadWaiting,
  } = usePhotoUpload(
    props.bucketName,
    props.onUploadSucess,
    props.exitingUrl,
    props.disabled
  );

  return (
    <div className={styles.container}>
      <div
        className={`${styles.profileImage} ${
          isAvatarUploadWaiting ? styles.profileUploading : ""
        } `}
      >
        {avatarUrl ? (
          <div
            className={styles.baseAvatarImage}
            style={{ backgroundImage: `url(${avatarUrl})` }}
          />
        ) : (
          <>
            {props.fallBackComponent && (
              <div onClick={() => handleUploadImageClick()}>
                {props.fallBackComponent}
              </div>
            )}
          </>
        )}
        {avatarUrl && (
          <div
            className={styles.overlay}
            onClick={() => handleUploadImageClick()}
          >
            {isAvatarUploadWaiting ? (
              <LoadingOutlined />
            ) : (
              <>
                <Button
                  className={styles.deleteIcon}
                  type={ButtonTypes.LINK}
                  icon={<DeleteOutlined />}
                  disabled={props.disabled}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    removeImage();
                  }}
                ></Button>
                <CloudUploadOutlined color="#fff" />
              </>
            )}
          </div>
        )}
      </div>
      <input
        onChange={() => handleUploadImageChange()}
        type="file"
        accept=".jpg, .jpeg, .png"
        style={{ display: "none" }}
        ref={inputAvatarUpload as any}
      />
    </div>
  );
}

function usePhotoUpload(
  bucketName: string,
  onUploadSucess: onUploadSucess,
  exitingUrl: string | undefined,
  disabeld?: boolean
) {
  const inputAvatarUpload = useRef<HTMLInputElement>();
  const [avatarUrl, setAvatarUrl] = useState(exitingUrl ? exitingUrl : "");

  useEffect(() => {
    setAvatarUrl(exitingUrl ? exitingUrl : "");
  }, [exitingUrl]);
  const [isAvatarUploadWaiting, setIsAvatarUploadWaiting] =
    useState<boolean>(false);

  const handleUploadImageChange = () => {
    if (disabeld) return;
    const input: any = inputAvatarUpload.current;
    if (input && input.files[0]) {
      const image = input.files[0];
      const imageUrl = URL.createObjectURL(image);
      const photoKey = generatePhotoKey({ image, bucketName });
      if (photoKey) {
        setAvatarUrl(imageUrl);
        setIsAvatarUploadWaiting(true);
        persistAvatar({ photoKey, image }).then((img) => {
          setIsAvatarUploadWaiting(false);
          onUploadSucess(img);
        });
      }
    }
  };

  const handleUploadImageClick = () => {
    if (disabeld) return;
    if (inputAvatarUpload.current) {
      inputAvatarUpload.current.value = "";
      inputAvatarUpload.current.click();
    }
  };

  const removeImage = () => {
    if (disabeld) return;
    if (inputAvatarUpload.current) {
      inputAvatarUpload.current.value = "";
      onUploadSucess("");
    }
  };
  // exitingUrl
  return {
    inputAvatarUpload,
    handleUploadImageChange,
    handleUploadImageClick,
    removeImage,
    avatarUrl,
    isAvatarUploadWaiting,
  };
}

async function persistAvatar({
  photoKey,
  image,
}: {
  photoKey?: string;
  image: File;
}) {
  const hostedUrl: string = await axiosInstance
    .post(`/api/auth/getSignedUrl`, { key: photoKey })
    .then((res) => {
      const signedUrl = res.data.uploadUrl;
      const authorizationHeader =
        axiosInstance.defaults.headers.common["Authorization"];
      delete axiosInstance.defaults.headers.common["Authorization"];
      const hostedUrl = axiosInstance
        .put(signedUrl, image, {
          headers: {
            "content-type": "image/*",
          },
        })
        .then((res) => {
          const hostedUrl = signedUrl.split("?")[0];
          axiosInstance.defaults.headers.common.Authorization =
            authorizationHeader;
          return hostedUrl;
        });
      return hostedUrl;
    });
  return hostedUrl;
}

function generatePhotoKey({
  image,
  bucketName,
}: {
  image: File;
  bucketName: string;
}) {
  if (image.size / 1024 / 1024 >= 5) {
    alert("Please upload the image less than 5MB");
    return;
  }
  const splitFilename = image.name.split(".");
  const fileExtension = splitFilename[splitFilename.length - 1];

  return `${bucketName}/${short.generate()}.${fileExtension}`;
}
