import { usernameSplitter } from "clarity-ui/UserDisplay";
import { userApi } from "clientApi/userApi";
import { getLabelBasedOnContainer } from "modules/containersService";
import { getWorkAssigneeLabelByType } from "modules/taskService";
import moment from "moment";
import { useCallback } from "react";
import store from "store/storeExporter";
import {
  ContainerTypes,
  DeltaType,
  getPriorityName,
  IProjectObj,
  WorkActivityDelta,
  WorkActivityTypes,
  WorkTypes,
} from "utilities/types";
import { IWorkActivityProps } from "../types";

export const MetadataConditionalText: React.FC<IWorkActivityProps> = (
  props
) => {
  const getHtmlForMetadataChange = useCallback((delta: WorkActivityDelta) => {
    let text = "";

    if (props.workActivity.type === WorkActivityTypes.REVIEW_REQUEST) {
      const userName = userApi.getUsernameForUser(
        props.workActivity.delta?.metadata?.nextValue?.value
      );

      if (userName) {
        text = `Requested a review from <b>${userName}</b>`;
      } else {
        text = "Requested a review";
      }

      return text;
    }

    if (props.workActivity.type === WorkActivityTypes.REVIEW_REQUEST_ACCEPTED) {
      text = `Completed the review`;

      return text;
    }

    if (props.workActivity.type === WorkActivityTypes.REVIEW_REMINDER) {
      const userName = userApi.getUsernameForUser(
        props.workActivity.delta?.metadata?.nextValue?.value
      );

      if (userName) {
        text = `Nudged <b>${userName}</b> to review their work`;
      } else {
        text = "Nudged the reviewer to review their work";
      }
      return text;
    }

    switch (delta.type) {
      case DeltaType.RENAME:
        text = `Changed the title from <b>'${delta.metadata?.previousValue}'</b> to <b>'${delta.metadata?.nextValue}'</b>`;
        break;
      case DeltaType.STATUS:
        const status =
          store.getState().work.statuses.dict[delta.metadata?.nextValue];
        text = `Changed status to <b>${status?.name}</b>`;
        break;
      case DeltaType.PRIORITY:
        if (delta.metadata?.previousValue > delta.metadata?.nextValue) {
          text = `Lowered priority to <b>${getPriorityName(
            delta.metadata?.nextValue
          )}</b>`;
        }
        if (delta.metadata?.previousValue < delta.metadata?.nextValue) {
          text = `Raised priority to  to <b>${getPriorityName(
            delta.metadata?.nextValue
          )}</b>`;
        }
        if (delta.metadata?.previousValue === 1) {
          text = `Set priority to <b>${getPriorityName(
            delta.metadata?.nextValue
          )}</b>`;
        }
        if (delta.metadata?.nextValue === 1) {
          text = `Removed priority`;
        }
        break;
      case DeltaType.ASSIGNE:
        const members = store.getState().members;
        if (delta.metadata?.previousValue === null) {
          text = `Assigned to <b>${usernameSplitter(
            members.dict[delta.metadata?.nextValue]?.name
              ? members.dict[delta.metadata?.nextValue]?.name
              : "@" + members.dict[delta.metadata?.nextValue]?.username
          )}</b>`;
        } else {
          if (delta.metadata?.nextValue === null) {
            text = `Removed the ${getWorkAssigneeLabelByType(
              props.workItem?.workType
            )}`;
          } else {
            text = `Changed the ${getWorkAssigneeLabelByType(
              props.workItem?.workType
            )} to <b>${usernameSplitter(
              members.dict[delta.metadata?.nextValue]?.name
                ? members.dict[delta.metadata?.nextValue]?.name
                : "@" + members.dict[delta.metadata?.nextValue]?.username
            )}</b>`;
          }
        }
        break;
      case DeltaType.DUE_DATE:
        if (delta.metadata?.previousValue === null) {
          text = `Set due date to <b>${moment
            .utc(delta.metadata?.nextValue)
            .format("MMMM D YYYY")}</b>`;
        } else {
          if (delta.metadata?.nextValue) {
            text = `Changed due date to <b>${moment
              .utc(delta.metadata.nextValue)
              .format("MMMM D YYYY")}</b>`;
          } else {
            text = "Removed the due date";
          }
        }
        break;
      case DeltaType.CYCLE:
        const sections = store.getState().work.sections;
        if (delta.metadata?.previousValue === null) {
          text = `Set sprint to <b>${
            sections[delta.metadata.nextValue]
              ? sections[delta.metadata.nextValue].name
              : "None"
          }</b>`;
        } else {
          if (delta.metadata?.nextValue) {
            text = `Changed sprint to <b>${
              sections[delta.metadata.nextValue]?.name
            }</b>`;
          } else {
            text = "Removed the sprint";
          }
        }
        break;
      case DeltaType.TAGS:
        const checkIfWorkTag = (projectNr?: number) => {
          if (projectNr) {
            return ` work-tag = "true" work-nr = "${Number(
              projectNr ? projectNr : 0
            )}"`;
          }
          return "";
        };
        if (delta.action === "Add") {
          const tagLabel = getLabelBasedOnContainer(
            delta.metadata?.nextValue.containerType,
            delta.metadata?.nextValue.containerId
          );
          if (!tagLabel || !tagLabel.entity) {
            text = `Added tag`;
          } else {
            text =
              `Added tag <mention class="mention hashtag confirmed" href="${tagLabel.entity.id}" ` +
              `${checkIfWorkTag((tagLabel.entity as IProjectObj).projectId)}` +
              `>${tagLabel.label}</mention>`;
          }
        } else if (delta.action === "Remove") {
          const tagLabel = getLabelBasedOnContainer(
            delta.metadata?.nextValue.containerType,
            delta.metadata?.nextValue.containerId
          );
          if (!tagLabel || !tagLabel.entity) {
            text = "Removed tag";
          } else {
            text =
              `Removed tag <mention class="mention hashtag confirmed" href="${tagLabel.entity.id}" ` +
              `${checkIfWorkTag((tagLabel.entity as IProjectObj).projectId)}` +
              `>${tagLabel.label}</mention>`;
          }
        }
        break;
      case DeltaType.PROJECT:
        if (!delta.metadata?.previousValue) {
          const tagLabel = getLabelBasedOnContainer(
            ContainerTypes.PROJECT,
            delta.metadata?.nextValue
          );
          text = `Added parent <b>${tagLabel.label}</b>`;
        } else {
          if (delta.metadata.nextValue) {
            const tagLabel = getLabelBasedOnContainer(
              ContainerTypes.PROJECT,
              delta.metadata.nextValue
            );
            text = `Changed parent <b>${tagLabel.label}</b>`;
          } else {
            text = `Removed parent`;
          }
        }
        break;
      case DeltaType.CONTRIBUTORS:
        if (delta.metadata?.nextValue.type === "add") {
          const member =
            store.getState().members.dict[delta.metadata.nextValue.id];
          if (member) {
            text = `Added @${member.username} as a contributor`;
          }
        } else if (delta.metadata?.nextValue.type === "remove") {
          const member =
            store.getState().members.dict[delta.metadata.nextValue.id];
          if (member) {
            text = `Removed @${member.username} as a contributor`;
          }
        }
        break;
      case DeltaType.REWARD:
        if (delta.metadata?.nextValue) {
          text = "Added reward";
        } else if (
          delta.metadata?.nextValue === null &&
          delta.metadata?.previousValue
        ) {
          text = "Removed reward";
        } else {
          text = "Updated reward";
        }

        if (delta.isPaid) {
          text = "Marked as paid";
        } else if (delta.isPaid !== undefined && !delta.isPaid) {
          text = "Marked as not paid";
        }
        if (delta.ready) {
          text = "Marked as ready to be paid";
        } else if (delta.ready !== undefined && !delta.ready) {
          text = "Marked as not ready to be paid";
        }
        break;
      case DeltaType.REVIEWER:
        const members1 = store.getState().members;
        if (delta.metadata?.previousValue === null) {
          text = `Set reviewer to <b>${usernameSplitter(
            members1.dict[delta.metadata.nextValue]?.name
              ? members1.dict[delta.metadata.nextValue]?.name
              : "@" + members1.dict[delta.metadata.nextValue]?.username
          )}</b>`;
        } else {
          if (delta.metadata?.nextValue === null) {
            text = `Removed the reviewer`;
          } else {
            text = `Changed the reviewer to <b>${usernameSplitter(
              members1.dict[delta.metadata?.nextValue]?.name
                ? members1.dict[delta.metadata?.nextValue]?.name
                : "@" + members1.dict[delta.metadata?.nextValue]?.username
            )}</b>`;
          }
        }
        break;
      case DeltaType.CREATED:
        if (delta.metadata?.workType) {
          text = ` Created this ${
            delta.metadata?.workType === WorkTypes.INITIATIVE
              ? "goal"
              : delta.metadata?.workType.toLowerCase()
          }`;
        }
        break;
      default:
        text = JSON.stringify(delta);
    }
    return text;
  }, []);

  if (props.type === WorkActivityTypes.REVIEW_REQUEST) {
  }

  return (
    <span
      style={{ marginRight: "12px" }}
      dangerouslySetInnerHTML={{
        __html: getHtmlForMetadataChange(props.workActivity.delta),
      }}
    ></span>
  );
};
