import {
  HIDE_COMMAND_PALETTE,
  HIDE_MODAL_NEW_PROJECT,
  HIDE_MODAL_NEW_TEMPLATE,
  SHOW_MODAL_NEW_PROJECT,
  SHOW_MODAL_NEW_SNIPPET,
  SHOW_MODAL_NEW_TEMPLATE,
} from "store/actions";
import { handleNewSnippetItemCreationCases } from "./entityCreateHelpers/newSnippetItemCheck";
import { handleNewTemplateItemCreationCases } from "./entityCreateHelpers/newTemplateItemCheck";
import { handleNewWorkItemCreationCases } from "./entityCreateHelpers/newWorkItemCheck";

const entityCreate = (store: any) => (next: any) => (action: any) => {
  if (action.type === SHOW_MODAL_NEW_PROJECT) {
    handleNewWorkItemCreationCases(action, next);
  }

  if (action.type === SHOW_MODAL_NEW_TEMPLATE) {
    handleNewTemplateItemCreationCases(action, next);
  }

  if (action.type === SHOW_MODAL_NEW_SNIPPET) {
    handleNewSnippetItemCreationCases(action, next);
  }

  if (
    action.type === HIDE_MODAL_NEW_PROJECT ||
    action.type === HIDE_MODAL_NEW_TEMPLATE
  ) {
    const state = store.getState().client;
    const actionOnClose = state.onNewTaskClose;
    if (actionOnClose) actionOnClose();
  }

  if (action.type === HIDE_COMMAND_PALETTE) {
    const state = store.getState().commandPalette;
    const actionOnClose = state.afterClose;
    if (actionOnClose) actionOnClose();
  }

  let result = next(action);
  return result;
};

export default entityCreate;
