import { Menu } from "antd";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { containerSearchFuse } from "utilities/fuseHelpers";
import { MenuProps } from "../BlockMenu";
import styles from "./tagMenu.module.scss";
import {
  CheckCircleOutlined,
  PlusOutlined,
  TagOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { batch, shallowEqual, useSelector } from "react-redux";
import { removePlaceholderPlaceCaret } from "editor/utils/specificActions/textUpdateUtils";
import {
  createHashTag,
  createTag,
} from "editor/utils/specificActions/textUpdateActions";
import { updateImmediateText } from "editor/utils/blockActions";
import { resetBlockMenu } from "editor/utils/blockMenusActions/menuActions";
import { stripHtml } from "utilities/stringUtilities";
import { $focusOn, ClarityStore } from "store/storeExporter";
import { useOnClickOutside } from "editor/utils/customHooks";
import { getHtml } from "editor/utils/blockValueHelpers";
import { checkCaretPosition } from "editor/utils/caretUtils";
import { getNameFromContainer } from "modules/containerHelpers";
import { ContainerTypes, WorkTypes } from "utilities/types";

const getCorrectValue = (option: any, containerType: ContainerTypes) => {
  return getNameFromContainer(option, containerType);
};

const TagMenu: React.FC<MenuProps> = (props) => {
  const prevPosition = useRef({
    left: `0px`,
    top: `0px`,
  });
  const optionsToFilterRef = useRef<any[]>([]);
  const storeState = useSelector((state: ClarityStore) => {
    return {
      documents: state.pages.dict,
      workDict: state.work.dict,
      workspaceId: state.workspace.id,
    };
  }, shallowEqual);

  const menuRef = useRef(null);
  const closeMenu = useCallback((event) => {
    if (event) {
      resetBlockMenu(props.menuStateValue);
    }
  }, []);

  useOnClickOutside(menuRef, closeMenu);

  const firstEl = [
    {
      name: 'Create "' + props.menuState.filterMenuBy + '"',
      id: "idString",
      root: true,
    },
  ];

  const [optionsToDisplay, setoptionsToDisplay] = useState<any[]>([...firstEl]);

  const activateCheckedOption = (index: number) => {
    batch(() => {
      removePlaceholderPlaceCaret(props.blockRef.current);
      const selectedOption = optionsToDisplay[index];
      const text = selectedOption.root
        ? props.menuState.filterMenuBy
        : stripHtml(getHtml(selectedOption.nameValue));

      if (props.menuState.options && props.menuState.options.hashtag) {
        createHashTag(text, selectedOption);
      } else createTag(text);

      const caretPosition = checkCaretPosition(props.blockRef.current);
      $focusOn.next({
        ...$focusOn.value,
        caretPosition,
      });
      updateImmediateText(props.blockData, props.blockRef, props.context);
      resetBlockMenu(props.menuStateValue);
    });
  };

  useEffect(() => {
    const documents = Object.values(storeState.documents);
    let workItems: any[] = [];
    if (props.menuState.options?.hashtag) {
      workItems = Object.values(storeState.workDict);
    }
    optionsToFilterRef.current = [...documents, ...workItems];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let hoveredOption = props.menuState.hoveredItem;

  useLayoutEffect(() => {
    if (props.menuState.isOpened) {
      let optionsToDisplayTest: any[] = [];
      if (props.menuState.filterMenuBy !== "") {
        // let optionsToFilter: any = Object.values(props.documents);
        optionsToDisplayTest = containerSearchFuse(
          optionsToFilterRef.current,
          props.menuState.filterMenuBy.trim()
        ).slice(0, 14);
        if (
          optionsToDisplayTest[0]?.name.toLowerCase().trim() !==
          props.menuState.filterMenuBy.toLowerCase().trim()
        )
          optionsToDisplayTest = [...firstEl, ...optionsToDisplayTest];
      } else {
        optionsToDisplayTest = [...firstEl];
      }
      setoptionsToDisplay(optionsToDisplayTest);
    }
  }, [props.menuState.filterMenuBy, props.menuState.isOpened]);

  useEffect(() => {
    if (
      optionsToDisplay.length > 0 &&
      props.menuState.hoveredItem >= optionsToDisplay.length
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hoveredOption = optionsToDisplay.length - 1;
      if (hoveredOption < 0) hoveredOption = 0;
      const menuStateCopy = { ...props.menuState };
      menuStateCopy.hoveredItem = hoveredOption;
      props.menuStateValue.current.next(menuStateCopy);
    }
  }, [props]);

  useEffect(() => {
    const el = document.getElementById(
      "menuItem" + props.menuState.hoveredItem
    );
    el?.scrollIntoView({ block: "nearest" });
  }, [props.menuState.hoveredItem]);

  useEffect(() => {
    if (props.menuState.executeSelection === true)
      activateCheckedOption(props.menuState.hoveredItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuState.executeSelection]);

  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  let styleObject: any = {
    left: `${x}px`,
    top: `${y}px`,
  };
  if (selection && selection.rangeCount > 0) {
    const range: Range = selection.getRangeAt(0);
    const boundingRect = range.getBoundingClientRect();
    let rangeX = boundingRect.x;
    let rangeY = boundingRect.bottom;
    if (true) {
      const placeholder = props.blockRef.current.querySelectorAll(
        ".mention-placeholder"
      );
      if (placeholder[0]) {
        const placeholderSizes = placeholder[0].getBoundingClientRect();
        rangeX = placeholderSizes.x + placeholderSizes.width;
        rangeY = placeholderSizes.bottom;
        // const closestEl = props.blockRef.current.closest(".section-big");
        const closestEl = props.blockRef.current.closest(".flex-section");
        let componentX = closestEl ? closestEl.getBoundingClientRect().x : 0;
        let componentY = props.blockRef.current.getBoundingClientRect().y;
        const computed = window
          .getComputedStyle(props.blockRef.current)
          .getPropertyValue("margin-top");
        const marginString = computed.substring(0, computed.length - 2);
        const margin = Number(marginString);

        x = rangeX === 0 ? 10 : rangeX - componentX;
        y = rangeY === 0 ? 10 : rangeY + margin - componentY;

        if (componentX + x + 350 > window.outerWidth) {
          styleObject = {
            right: `${-x}px`,
            top: `${y}px`,
          };
        } else {
          styleObject = {
            left: `${x}px`,
            top: `${y}px`,
          };
        }
        prevPosition.current = styleObject;
      } else {
        styleObject = prevPosition.current;
      }
    }
  }

  return (
    <>
      <div
        className={styles.tagCompleteMenuContainer}
        style={styleObject}
        ref={menuRef}
      >
        <Menu className={styles.tagCompleteMenu} selectable>
          {props.menuState.filterMenuBy.trim().length === 0 ||
          props.menuState.filterMenuBy.trim() === "" ? (
            <Menu.Item
              disabled={true}
              key="0"
              className={`${styles.tagCompleteMenu__menuItem} ${styles.tagCompleteMenu__menuItem__placeholder}`}
            >
              Create a new tag or insert an existing one
            </Menu.Item>
          ) : (
            optionsToDisplay.map((option, index) => (
              <Menu.Item
                key={index}
                id={"menuItem" + index}
                role="button"
                onClick={(e) => {
                  e.domEvent.preventDefault();
                  e.domEvent.stopPropagation();
                  activateCheckedOption(index);
                }}
                className={
                  hoveredOption === index
                    ? `${styles.tagCompleteMenu__menuItem} ${styles.nice}`
                    : styles.tagCompleteMenu__menuItem
                }
              >
                {option.root ? (
                  <span>
                    <PlusOutlined
                      className={styles.tagCompleteMenu__menuItem__icon}
                    />
                  </span>
                ) : option.projectId ? (
                  <span>
                    {option.workType === WorkTypes.TASK ? (
                      <CheckCircleOutlined
                        className={styles.tagCompleteMenu__menuItem__icon}
                      />
                    ) : (
                      <FileDoneOutlined
                        className={styles.tagCompleteMenu__menuItem__icon}
                      />
                    )}
                  </span>
                ) : (
                  <span>
                    <TagOutlined
                      className={styles.tagCompleteMenu__menuItem__icon}
                    />
                  </span>
                )}
                {option.nameValue ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getCorrectValue(
                        option,
                        option.workType
                          ? ContainerTypes.PROJECT
                          : ContainerTypes.DOCUMENT
                      ),
                    }}
                    className={styles.tagCompleteMenu__menuItem__labelContainer}
                  ></span>
                ) : (
                  <span
                    className={styles.tagCompleteMenu__menuItem__labelContainer}
                  >
                    {option.name}
                  </span>
                )}
              </Menu.Item>
            ))
          )}
        </Menu>
      </div>
    </>
  );
};

export default TagMenu;
