import Button, { ButtonTypes } from "components/Button";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { WorkViewStoreContext } from "../WorkView";
import KanabanCard from "./KanbanCard";
import styles from "./kanban.module.scss";
import {
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowProps,
} from "react-virtualized";
import { PlusOutlined } from "@ant-design/icons";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import StatusDisplay from "components/StatusDisplay";
import {
  INewTaskCreationModes,
  openNewTaskModal,
} from "store/reducers/clientReducer";
import { usePaneId } from "store/reducers/filterReducer";
import navigationApi from "clientApi/navigationApi";
import { ContainerTypes, ViewNames } from "utilities/types";

const Track: React.FC<{ id: string; canEditEntity: boolean }> = memo(
  ({ id, canEditEntity }) => {
    const workViewStore = useContext(WorkViewStoreContext);
    const [groupItems, setgroupItems] = useState<string[]>([]);
    const ref = useRef(groupItems);

    const handleTaskClick = (id: string) => {
      navigationApi.openPeekView({
        viewName: ViewNames.Detail,
        entity: {
          containerId: id,
          containerType: ContainerTypes.WORK,
        },
      });
    };
    const paneId = usePaneId();
    const workViewState = useSelector(
      (store: ClarityStore) => ({
        viewConfig: store.filter.viewConfigDict[paneId],
      }),
      shallowEqual
    );

    useEffect(() => {
      ref.current = groupItems;
    }, [groupItems]);

    useEffect(() => {
      const groupsListener = workViewStore.groups.subscribe((groups) => {
        const _groupItems = groups[id];
        if (JSON.stringify(_groupItems) !== JSON.stringify(ref.current)) {
          setgroupItems(_groupItems ?? []);
        }
      });

      return () => {
        groupsListener.unsubscribe();
      };
    }, []);

    return useMemo(() => {
      return (
        <Droppable
          droppableId={id}
          isDropDisabled={!canEditEntity}
          mode="virtual"
          renderClone={(
            provided: DraggableProvided,
            snapshot: DraggableStateSnapshot,
            rubric: DraggableRubric
          ) => (
            <KanabanCard
              isTargetTrackCard={false}
              handleTaskClick={handleTaskClick}
              provided={provided}
              snapshot={snapshot}
              canEditEntity={canEditEntity}
              id={groupItems[rubric.source.index]}
            />
          )}
        >
          {(provided, snapshot) => (
            <div
              className={[
                styles.track,
                styles.trackWithScroll,
                !snapshot.isDraggingOver ? "" : styles.trackIsDraggedOver,
              ].join(" ")}
              ref={provided.innerRef}
              style={{ backgroundColor: snapshot.isDraggingOver ? "" : "" }}
              {...provided.droppableProps}
            >
              <div className={styles.trackHeader}>
                <div className={styles.trackHeader_left}>
                  <div className={styles.trackHeaderPrefix}>
                    <StatusDisplay
                      showName={true}
                      captionClass={styles.trackHeaderTitle}
                      statusId={id}
                      size={"large"}
                    />
                  </div>
                  <span className={styles.trackHeaderCount}>
                    {groupItems.length}
                  </span>
                </div>
                <Button
                  className={styles.trackFooterBtn}
                  onClick={() => {
                    if (!canEditEntity) return;
                    const groupId =
                      workViewStore.groupContext.value ?? undefined;
                    openNewTaskModal({
                      type: INewTaskCreationModes.new,
                      presetData: {
                        ...workViewStore.groupsHeaders.value[id]
                          .presetTaskMetadata,
                        groupId,
                      },
                      disableSelectGroup:
                        workViewState.viewConfig.id === "MyTasks"
                          ? false
                          : true,
                    });
                  }}
                  disabled={!canEditEntity}
                  buttonType={ButtonTypes.DEFAULT}
                  icon={<PlusOutlined />}
                />
              </div>
              <TrackBody
                groupItems={groupItems}
                canEditEntity={canEditEntity}
                snapshot={snapshot}
              />
            </div>
          )}
        </Droppable>
      );
    }, [groupItems, canEditEntity]);
  }
);

const TrackBody: React.FC<{
  groupItems: string[];
  snapshot: DroppableStateSnapshot;
  canEditEntity: boolean;
}> = ({ groupItems, snapshot, canEditEntity }) => {
  const isFirstLoad = useRef(true);
  const cacheRef = useGetCellMeasurerCache();
  const listRef = useRef<List | undefined>();
  const handleTaskClick = (id: string) => {
    navigationApi.openPeekView({
      viewName: ViewNames.Detail,
      entity: {
        containerId: id,
        containerType: ContainerTypes.WORK,
      },
    });
  };

  const listRowRendererGetter = useGetListRowRendererGetter({
    groupItems,
    listRef,
    measurerCache: cacheRef.current,
    canEditEntity,
    handleTaskClick,
  });

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }
    cacheRef.current.clearAll();
  }, [groupItems]);

  return (
    <div className={styles.trackBody}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              className={styles.trackDroppable}
              style={{ transition: "background-color 0.2s ease" }}
              containerStyle={{
                marginBottom: "0",
              }}
              deferredMeasurementCache={cacheRef.current}
              height={height}
              rowCount={groupItems.length}
              rowHeight={cacheRef.current.rowHeight}
              rowRenderer={listRowRendererGetter({
                isTargetTrackCard: snapshot.isDraggingOver,
              })}
              width={width}
              ref={listRef as any}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
};

const VirtualRow: React.FC<{
  style: React.CSSProperties;
  measure: () => void;
  measurerCache: CellMeasurerCache;
  id: string;
  listRef: React.MutableRefObject<List | undefined>;
  index: number;
  isTargetTrackCard: boolean;
  canEditEntity: boolean;
  handleTaskClick: (id: string) => void;
}> = ({
  style,
  measure,
  measurerCache,
  id,
  index,
  isTargetTrackCard,
  listRef,
  canEditEntity,
  handleTaskClick,
}) => {
  const workItem = useSelector(
    (state: ClarityStore) => state.work.dict[id],
    shallowEqual
  );
  const isFirstLoad = useRef(true);
  const isMounted = useRef(false);

  useLayoutEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      isMounted.current = true;
      if (!measurerCache.has(index, 0)) {
        return;
      }
    }

    if (isMounted.current) {
      requestAnimationFrame(() => {
        measurerCache.clear(index, 0);
        try {
          measure();
        } catch {}
      });
    }
  }, [workItem, index]);

  return (
    <div style={style}>
      <div
        style={{
          paddingBottom: "8px",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        <Draggable draggableId={id} index={index} key={id}>
          {(provided, snapshot) => (
            <KanabanCard
              id={id}
              canEditEntity={canEditEntity}
              handleTaskClick={handleTaskClick}
              provided={provided}
              snapshot={snapshot}
              isTargetTrackCard={isTargetTrackCard}
            />
          )}
        </Draggable>
      </div>
    </div>
  );
};

function useGetListRowRendererGetter({
  groupItems,
  listRef,
  measurerCache,
  canEditEntity,
  handleTaskClick,
}: {
  groupItems: string[];
  listRef: React.MutableRefObject<List | undefined>;
  measurerCache: CellMeasurerCache;
  canEditEntity: boolean;
  handleTaskClick: (id: string) => void;
}) {
  return useCallback(
    ({ isTargetTrackCard }: { isTargetTrackCard: boolean }) => {
      return (props: ListRowProps): React.ReactNode => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { index, isScrolling, isVisible, key, parent, style } = props;
        const id = groupItems[index];

        return (
          <CellMeasurer
            cache={measurerCache}
            columnIndex={0}
            rowIndex={index}
            parent={parent}
            key={id}
          >
            {({ registerChild, measure }) => (
              <VirtualRow
                id={id}
                listRef={listRef}
                handleTaskClick={handleTaskClick}
                measurerCache={measurerCache}
                measure={measure}
                style={style}
                canEditEntity={canEditEntity}
                index={index}
                isTargetTrackCard={isTargetTrackCard}
              />
            )}
          </CellMeasurer>
        );
      };
    },
    [canEditEntity, groupItems]
  );
}

function useGetCellMeasurerCache() {
  const cacheRef = useRef(
    new CellMeasurerCache({
      defaultHeight: 76,
      fixedWidth: true,
    })
  );
  return cacheRef;
}

export default Track;
