import { axiosInstance } from "index";
import { useEffect, useState } from "react";
import { ADD_RESOURCE } from "store/actions";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";

export const useGetResourceById = (id?: string) => {
  const [isLoading, setIsLoading] = useState(false);

  const resource = useShallowSelector((state) =>
    id ? state.workspace.resources[id] : undefined
  );

  useEffect(() => {
    if (!resource && id) {
      setIsLoading(true);
      axiosInstance
        .get("/api/resource", {
          params: {
            id,
          },
        })
        .then((res) => {
          store.dispatch({
            type: ADD_RESOURCE,
            resource: res.data,
          });
          setIsLoading(false);
        });
    }
  }, []);

  return {
    isLoading,
    resource,
  };
};
