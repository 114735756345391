import React from "react";

import styles from "./userInfo/userInfo.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  avatar: string;
  username: string;
  role?: string;
  name: string;
}

const UserInfo = ({ avatar, username, role, name, ...restProps }: Props) => {
  return (
    <div className={styles.user_info} {...restProps}>
      <img src={avatar} alt="Avatar" />
      <div className={styles.user_info_texts}>
        <p>{name}</p>
        <p>{`${username} ${role ? `• ${role}` : ""}`}</p>
      </div>
    </div>
  );
};

export default UserInfo;
