import React, { useEffect, useRef } from "react";
import { Avatar, Menu } from "antd";
import { connect } from "react-redux";
import { LineType } from "utilities/lineUtilities";
import { UPDATE_LINE_TEXT } from "store/actions";
import styles from "./userMentionsMenu.module.scss";
import store, { prevState } from "store/storeExporter";
import { checkCaretPosition } from "editor/utils/caretUtils";

export const optionsToNotKeepType: LineType[] = [
  LineType.heading1,
  LineType.heading2,
  LineType.heading3,
  LineType.work,
];

const checkOption = async (option: any, filterString: string, props: any) => {
  const newContainer = document.createDocumentFragment();
  const newElement = document.createElement("usermention");
  newElement.setAttribute("user-id", option.id);
  const newSpace = document.createTextNode(" ");
  newContainer.appendChild(newElement);
  newContainer.appendChild(newSpace);
  newElement.contentEditable = "false";
  const placeholder = document.getElementsByClassName(
    "user-mention-placeholder"
  );

  newElement.innerHTML = option.name
    ? "@" + option.name
    : "@" + option.username;
  if (placeholder[0]) {
    placeholder[0].replaceWith(newContainer);
  }
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection?.getRangeAt(0);
    const newRange = range?.cloneRange();
    newRange?.setEndAfter(newSpace);
    newRange?.setStartAfter(newSpace);
    if (newRange) {
      selection?.removeAllRanges();
      selection?.addRange(newRange);
    }
  }
  // props.element.replace(placeholder, newElement);
  props.resetMenuState(props.menuState, props.setMenuState);
  setTimeout(() => {
    if (props.destination) {
      const caretPosition = checkCaretPosition(props.element);
      store.dispatch({
        type: UPDATE_LINE_TEXT,
        param: {
          event: {
            target: props.element,
            currentTarget: props.element,
          },
          id: props.id,
          caretPosition,
          destination: props.destination,
        },
        destination: props.destination,
      });
    }
  }, 0);
};

const UserMentionsMenu: React.FC<any> = (props) => {
  const optionsToFilterRef = useRef<any[]>([]);

  useEffect(() => {
    const storeData = prevState.value;
    const memberDict = storeData.members.dict;
    const membersAndGuestList = [...storeData.members.ids];

    const members = membersAndGuestList.map((id) => memberDict[id]);
    optionsToFilterRef.current = [...members];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let hoveredOption = props.menuState.hoveredItem;
  const firstEl = [
    {
      name: 'No results for "' + props.menuState.filterMenuBy + '"',
      id: "idString",
      root: true,
    },
  ];

  let optionsToDisplay: any[] = [];

  const checkFilter = (filterableName: any) => {
    if (!filterableName) return false;
    return filterableName
      .toLowerCase()
      .trim()
      .includes(props.menuState.filterMenuBy.toLowerCase().trim());
  };

  if (props.menuState.filterMenuBy !== "") {
    optionsToDisplay = optionsToFilterRef.current.filter((el) => {
      return checkFilter(el.name) || checkFilter(el.username);
    });

    if (optionsToDisplay.length === 0) optionsToDisplay = [...firstEl];
    else {
      optionsToFilterRef.current.forEach((el) => {
        if (
          el.name?.toLowerCase().trim() ===
            props.menuState.filterMenuBy.toLowerCase().trim() ||
          el.username?.toLowerCase().trim() ===
            props.menuState.filterMenuBy.toLowerCase().trim()
        ) {
          optionsToDisplay = [el];
        }
      });
    }
  } else {
    optionsToDisplay = optionsToFilterRef.current;
  }

  useEffect(() => {
    props.setOptionsArray(optionsToDisplay);
    if (
      optionsToDisplay.length > 0 &&
      props.menuState.hoveredItem >= optionsToDisplay.length
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hoveredOption = optionsToDisplay.length - 1;
      if (hoveredOption < 0) hoveredOption = 0;
      const menuStateCopy = { ...props.menuState };
      menuStateCopy.hoveredItem = hoveredOption;
      props.setMenuState(menuStateCopy);
    }
  }, [props.menuState.hoveredItem]);

  useEffect(() => {
    const el = document.getElementById(
      "menuItem" + props.menuState.hoveredItem
    );
    el?.scrollIntoView({ block: "nearest" });
  }, [props.menuState.hoveredItem]);

  useEffect(() => {
    if (props.menuState.executeSelection === true)
      checkOption(
        optionsToDisplay[props.menuState.hoveredItem],
        props.menuState.filterMenuBy,
        props
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuState.executeSelection]);

  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  let styleObject: any = {
    left: `${x}px`,
    top: `${y}px`,
  };
  if (selection && selection.rangeCount > 0) {
    const range: Range = selection.getRangeAt(0);
    const boundingRect = range.getBoundingClientRect();

    let rangeX = boundingRect.x;
    let rangeY = boundingRect.bottom;
    let element: any = range.startContainer.parentElement;
    const placeholder = document.querySelectorAll(".user-mention-placeholder");
    if (placeholder[0]) {
      const placeholderSizes = placeholder[0].getBoundingClientRect();
      rangeX = placeholderSizes.x + placeholderSizes.width;
      rangeY = placeholderSizes.bottom;
      element = placeholder[0];
    }
    let componentX = element.closest(".section-big").getBoundingClientRect().x;
    let componentY = props.element.getBoundingClientRect().y;

    const computed = window
      .getComputedStyle(props.element)
      .getPropertyValue("margin-top");
    const marginString = computed.substring(0, computed.length - 2);
    const margin = Number(marginString);

    const computed2 = window
      .getComputedStyle(props.element)
      .getPropertyValue("padding-top");

    const paddingString = computed2.substring(0, computed2.length - 2);
    const padding = Number(paddingString);

    const computed3 = window
      .getComputedStyle(props.element)
      .getPropertyValue("padding-left");

    const paddingLString = computed3.substring(0, computed3.length - 2);
    const paddingL = Number(paddingLString);

    x = rangeX === 0 ? 10 : rangeX + paddingL - componentX;
    y = rangeY === 0 ? 10 : rangeY + margin + padding + 14 - componentY;

    if (componentX + x + 350 > window.outerWidth) {
      styleObject = {
        right: `${-x}px`,
        top: `${y}px`,
      };
    } else {
      styleObject = {
        left: `${x}px`,
        top: `${y}px`,
      };
    }
  }

  return (
    <>
      <div
        className={styles.slashCommandContainer}
        style={styleObject}
        // ref={menuRef}
      >
        <Menu className={styles.atMentionsMenu} selectable>
          {optionsToDisplay.map((option, index) => (
            <Menu.Item
              key={index}
              id={"menuItem" + index}
              disabled={option.id === "idString"}
              role="button"
              style={{
                padding: "0 4px 0 7px",
              }}
              onMouseDown={() => {
                if (option.id === "idString") {
                  return;
                }
                checkOption(option, props.menuState.filterMenuBy, props);
              }}
              className={
                hoveredOption === index
                  ? `${styles.menuItem} ${styles.nice}`
                  : styles.menuItem
              }
            >
              {option.root ? (
                <></>
              ) : (
                <>
                  <Avatar src={option.avatar} shape="circle" size={21} />
                </>
              )}
              <span className={styles.labelContainer}>
                {option.name || "@" + option.username}
              </span>
              {option.name &&
                option.username &&
                option.name !== option.username && (
                  <span className={styles.suffixContainer}>
                    {`@${option.username}`}
                  </span>
                )}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    members: state.members,
  };
};

export default connect(mapStateToProps)(React.memo(UserMentionsMenu));
