import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CommandPaletteOption, CommandPaletteState } from "../helpers/types";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { AutoSizer } from "react-virtualized";
import styles from "../commandPalette.module.scss";
import OptionRow from "./OptionRow";
import Fuse from "fuse.js";
import { batch } from "react-redux";
import { useShallowSelector } from "utilities/hooks";

const OptionLister: React.FC<{ options: CommandPaletteOption[] }> = ({
  options,
}) => {
  const listRef = useRef<VirtuosoHandle>(null);
  const listContainer = useRef<HTMLDivElement | null>(null);
  const filteredOptions = useOptionsFilter(options, listRef);
  const paletteStateContext = useContext(CommandPaletteState);

  useEffect(() => {
    batch(() => {
      paletteStateContext.filteredOptions.next(filteredOptions);
      paletteStateContext.selectedItemIndex.next(0);
      const firstItem = filteredOptions[0];
      paletteStateContext.selectedItemName.next(
        firstItem ? firstItem.name : ""
      );
    });
  }, [filteredOptions]);

  const paletteContext = useShallowSelector(
    (store) => store.commandPalette.context
  );

  const rowRenderer = useCallback(
    (index: number) => {
      const option = filteredOptions[index];
      return (
        <OptionRow
          option={option}
          index={index}
          paletteContext={paletteContext}
        />
      );
    },
    [filteredOptions, paletteContext]
  );

  return (
    <div className={styles.listContainer} ref={listContainer}>
      {filteredOptions.length === 0 ? (
        <div className={styles.noResultsContainer}>
          <div className={styles.noResultsContainer__message}>
            No results to show
          </div>
        </div>
      ) : (
        <AutoSizer>
          {({ width, height }) => {
            return (
              <Virtuoso
                totalCount={filteredOptions.length}
                className={styles.list}
                wmode={"true"}
                ref={listRef}
                width={width}
                style={{ height: height + "px", width: `${width}px` }}
                overscan={4}
                height={height}
                itemContent={(index) => rowRenderer(index)}
              />
            );
          }}
        </AutoSizer>
      )}
    </div>
  );
};

const exeFuseSearch = (
  options: CommandPaletteOption[]
): ((value: string) => CommandPaletteOption[]) => {
  const fuse = new Fuse(options, {
    minMatchCharLength: 2,
    threshold: 0.4,
    keys: ["name"],
  });

  return (value: string) => fuse.search(value).map((result) => result.item);
};

const useOptionsFilter = (
  options: CommandPaletteOption[],
  listRef: React.RefObject<VirtuosoHandle>
) => {
  const [filteredOptions, setfilteredOptions] = useState(options);
  const paletteStateContext = useContext(CommandPaletteState);
  const [filterString, setfilterString] = useState("");

  useEffect(() => {
    const sub = paletteStateContext.selectedItemIndex.subscribe(
      (activeIndex) => {
        listRef.current?.scrollIntoView({
          index: activeIndex,
        });
      }
    );
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const sub = paletteStateContext.searchText.subscribe((query) =>
      setfilterString(query)
    );
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (filterString) {
      const fitleredItems = exeFuseSearch(options)(filterString);
      setfilteredOptions(fitleredItems);
    } else {
      setfilteredOptions(options);
    }
  }, [options, filterString]);

  return filteredOptions;
};

export default OptionLister;
