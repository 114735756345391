import { ADD_BLOCK_TYPES } from "editor/utils/specificActions/addBlockActions";
import { Block, BlockReducerState, ContainerContext } from "../blockReducer";
import {
  getCurrentContext,
  getBlockById,
  updateCurrentBlock,
  getBlockIndex,
  updateCurrentContext,
  UPDATE_TYPES,
} from "./generalBlockHelpers";

export const addBlock = (newState: BlockReducerState, param: any) => {
  const currentContext = getCurrentContext(newState, param.context.id);

  const currentId: string = param.referencePoint;

  if (param.type === ADD_BLOCK_TYPES.addBlockAfter) {
    addBlockAsSibling(
      newState,
      currentId,
      currentContext,
      param.block,
      "afterIndex"
    );
  }
  if (param.type === ADD_BLOCK_TYPES.addBlockBefore) {
    addBlockAsSibling(
      newState,
      currentId,
      currentContext,
      param.block,
      "beforeIndex"
    );
  }
  if (param.type === ADD_BLOCK_TYPES.addChildBlock)
    addBlockAsChild(newState, currentId, param.block);
};

const addBlockAsChild = (
  newState: BlockReducerState,
  currentBlockId: string,
  newBlock: Block
) => {
  const currentBlock = getBlockById(newState.dict, currentBlockId);
  const fullBlock: Block = {
    ...newBlock,
  };
  if (currentBlock.ancestors)
    fullBlock.ancestors = [...currentBlock.ancestors, currentBlockId];
  if (!currentBlock.children) currentBlock.children = [];
  let newChildrenArray = [...currentBlock.children];
  newChildrenArray.splice(0, 0, fullBlock.id);
  currentBlock.children = newChildrenArray;
  updateCurrentBlock(newState, currentBlock, UPDATE_TYPES.update);
  updateCurrentBlock(newState, fullBlock, UPDATE_TYPES.insert);
};

const addBlockAsSibling = (
  newState: BlockReducerState,
  currentBlockId: string,
  currentContext: ContainerContext,
  newBlock: Block,
  type: "beforeIndex" | "afterIndex"
) => {
  const currentBlock = getBlockById(newState.dict, currentBlockId);

  const fullBlock: Block = {
    ...newBlock,
  };

  const index = getBlockIndex(newState, currentBlock, currentContext);
  const insertionPoint = type === "beforeIndex" ? index : index + 1;

  if (currentBlock.parentId === "") {
    currentContext.state = { ...currentContext.state };
    currentContext.state.rootBlocksIds = [
      ...currentContext.state.rootBlocksIds,
    ];
    currentContext.state.rootBlocksIds.splice(insertionPoint, 0, newBlock.id);
    updateCurrentContext(newState, currentContext);
  } else {
    fullBlock.parentId = currentBlock.parentId;
    fullBlock.indentLevel = currentBlock.indentLevel;
    fullBlock.ancestors = currentBlock.ancestors;
    const parentBlock = getBlockById(newState.dict, currentBlock.parentId);
    if (!parentBlock.children) parentBlock.children = [];
    parentBlock.children = [...parentBlock.children];
    parentBlock.children.splice(insertionPoint, 0, newBlock.id);
    updateCurrentBlock(newState, parentBlock, UPDATE_TYPES.update, {
      children: parentBlock.children,
    });
    updateCurrentContext(newState, currentContext);
  }
  updateCurrentBlock(newState, fullBlock, UPDATE_TYPES.insert);
};
