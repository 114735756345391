import React from "react";
import {
  useAddOrEditGroupHomeSection,
  useAddOrEditGroupPin,
  useDeleteGroupProfileSection,
  useRemoveGroupPin,
  useReorderGroupHomeSection,
  useReorderGroupPins,
} from "store/reducers/groupReducer";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import PinsBoard from "../PinsBoard";

const GroupPinsHoc: React.FC<{ paneId: ChunkDestination; groupId: string }> = ({
  paneId,
  groupId,
}) => {
  const sections = useShallowSelector(
    (store) => store.groups.dict[groupId]?.home
  );

  const addPin = useAddOrEditGroupPin(groupId);
  const updateOrAddCollection = useAddOrEditGroupHomeSection(groupId);
  const reorderPins = useReorderGroupPins(groupId);
  const reorderCollection = useReorderGroupHomeSection(groupId);
  const deleteCollection = useDeleteGroupProfileSection(groupId);
  const deletePin = useRemoveGroupPin(groupId);

  return (
    <>
      <PinsBoard
        paneId={paneId}
        mode="group"
        sections={sections}
        addPin={addPin}
        updateOrAddCollection={updateOrAddCollection}
        reorderPins={reorderPins}
        reorderCollection={reorderCollection}
        deleteCollection={deleteCollection}
        deletePin={deletePin}
      />
    </>
  );
};

export default GroupPinsHoc;
