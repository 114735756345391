import { Block, FocusObject } from "store/reducers/blockReducer";
import store, { $focusOn } from "store/storeExporter";
import { DocumentModes } from "utilities/types";
import { BlockActionTypes } from "../blockActions";

export interface ActionWrapperObject {
  actions: ActionObject[];
  context: any;
  focusOn: FocusObject;
  selectedBlocks: string[];
  documentMode: DocumentModes;
}

export interface ActionObject {
  type: BlockActionTypes;
  options?: any;
  delta: Partial<Block>;
  id: string;
}

export const createActionWrapperObject = (context: any) => {
  const actionWrapper: ActionWrapperObject = {
    actions: [],
    context: context,
    focusOn: { ...$focusOn.value },
    selectedBlocks: store.getState().blocks.selectedBlocks,
    documentMode: store.getState().blocks.documentMode,
  };
  return actionWrapper;
};
