import React, { Dispatch, SetStateAction } from "react";
import { connect } from "react-redux";
import styles from "./listItemMenuHandle/listItemMenuHandle.module.scss";
import { Dropdown } from "antd";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
interface IMapStateToProps {}

interface IMapDispatchToProps {}

interface ISixDotHandleProps {
  menuFn: any;
  menuPlacement: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
  triggerArray: ("click" | "hover" | "contextMenu")[];
  className?: string;
  draggable?: boolean;
  projectHandleMode?: boolean;
  parentProps?: any;
  setdragged?: Dispatch<SetStateAction<boolean>>;
}

function ListItemMenuHandle(
  props: ISixDotHandleProps & IMapDispatchToProps & IMapStateToProps
) {
  const { className, draggable, triggerArray, menuFn, menuPlacement } = props;

  return (
    <Dropdown trigger={triggerArray} overlay={menuFn} placement={menuPlacement}>
      <div
        className={
          draggable
            ? styles.sixDotHandle +
              ` ${className}` +
              " " +
              styles.sixDotHandle_draggable
            : styles.sixDotHandle + ` ${className}`
        }
      >
        <MoreHorizontalDots
          style={{ opacity: 0.65 }}
          alt="Menu"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
          }}
        />
        {/* <img src={ThreeDotImg} alt="Menu" /> */}
      </div>
    </Dropdown>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListItemMenuHandle);
