// Vendor Libraries
import React from "react";

// Internal Modules
import { logUserOut } from "../utilities/authTokens";

export default function Logout() {
  logUserOut("/");
  return <></>;
}
