// Vendor Libraries
import React, { useState, useRef } from "react";
import { LoadingOutlined, CloudUploadOutlined } from "@ant-design/icons";
import styles from "./baseAvatarUpload/baseAvatarUpload.module.scss";
import { BucketAlbums } from "utilities/types";
import short from "short-uuid";
import SuperSidebarItem from "../screens/base/sidebar/superSidebar/SuperSidebarItem";
import { useBase } from "store/reducers/workspaceReducer";
import store from "store/storeExporter";
import { SET_AUTHENTICATED_USER, UPDATE_WORKSPACE } from "store/actions";
import { axiosInstance } from "index";

interface BaseAvatarUploadProps {
  disabled?: boolean;
}

export default function BaseAvatarUpload(props: BaseAvatarUploadProps) {
  const {
    inputAvatarUpload,
    handleUploadImageChange,
    handleUploadImageClick,
    avatarUrl,
    isAvatarUploadWaiting,
    base,
  } = useBaseAvatarUpload(props.disabled);

  return (
    <div className={styles.container}>
      <div className={styles.profileImage}>
        {avatarUrl ? (
          <div
            className={styles.baseAvatarImage}
            style={{ backgroundImage: `url(${avatarUrl})` }}
          />
        ) : (
          <SuperSidebarItem
            baseId={base.id}
            isActive={false}
            isAvatar={true}
            isHoverable={false}
            noTooltip={true}
            noClick={true}
            containerClass={styles.baseAvatarItemContainer}
            itemClass={styles.baseAvatarItem}
          />
        )}
        <div
          className={styles.overlay}
          onClick={() => handleUploadImageClick()}
        >
          {isAvatarUploadWaiting ? (
            <LoadingOutlined />
          ) : (
            <CloudUploadOutlined color="#fff" />
          )}
        </div>
      </div>
      <input
        onChange={() => handleUploadImageChange()}
        type="file"
        accept=".jpg, .jpeg, .png"
        style={{ display: "none" }}
        ref={inputAvatarUpload as any}
      />
    </div>
  );
}

function useBaseAvatarUpload(disabled?: boolean) {
  const inputAvatarUpload = useRef<HTMLInputElement>();
  const { base } = useBase();
  const [avatarUrl, setAvatarUrl] = useState(
    base && base.avatar ? base.avatar : ""
  );
  const [isAvatarUploadWaiting, setIsAvatarUploadWaiting] =
    useState<boolean>(false);

  const handleUploadImageChange = () => {
    if (disabled) return;
    const input: any = inputAvatarUpload.current;
    if (input && input.files[0]) {
      const image = input.files[0];
      const imageUrl = URL.createObjectURL(image);
      const photoKey = generatePhotoKey({ image });
      if (photoKey) {
        setAvatarUrl(imageUrl);
        setIsAvatarUploadWaiting(true);
        persistAvatar({ photoKey, image, baseId: base.id }).then(() =>
          setIsAvatarUploadWaiting(false)
        );
      }
    }
  };

  const handleUploadImageClick = () => {
    if (disabled) return;
    if (inputAvatarUpload.current) {
      inputAvatarUpload.current.value = "";
      inputAvatarUpload.current.click();
    }
  };

  return {
    inputAvatarUpload,
    handleUploadImageChange,
    handleUploadImageClick,
    avatarUrl,
    isAvatarUploadWaiting,
    base,
  };
}

async function persistAvatar({
  photoKey,
  image,
  baseId,
}: {
  photoKey?: string;
  image: File;
  baseId: string;
}) {
  const hostedUrl = await axiosInstance
    .post(`/api/auth/getSignedUrl`, { key: photoKey })
    .then((res) => {
      const signedUrl = res.data.uploadUrl;
      const authorizationHeader =
        axiosInstance.defaults.headers.common["Authorization"];
      delete axiosInstance.defaults.headers.common["Authorization"];
      const hostedUrl = axiosInstance
        .put(signedUrl, image, {
          headers: {
            "content-type": "image/*",
          },
        })
        .then((res) => {
          const hostedUrl = signedUrl.split("?")[0];
          axiosInstance.defaults.headers.common.Authorization =
            authorizationHeader;
          return hostedUrl;
        });
      return hostedUrl;
    });

  return patchBaseAvatar({ baseId, avatarValue: hostedUrl });
}

async function patchBaseAvatar({
  baseId,
  avatarValue,
}: {
  baseId: string;
  avatarValue: string;
}) {
  await axiosInstance.patch(`/api/workspace/profile`, {
    baseId: baseId,
    patch: [
      {
        op: "replace",
        path: "/avatar",
        value: avatarValue,
      },
    ],
  });

  store.dispatch({
    type: UPDATE_WORKSPACE,
    params: {
      delta: { avatar: avatarValue },
    },
  });

  const data = await axiosInstance.get("/api/user");

  store.dispatch({
    type: SET_AUTHENTICATED_USER,
    user: { ...data.data.payload },
    forceUpdate: true,
  });
}

function generatePhotoKey({ image }: { image: File }) {
  if (image.size / 1024 / 1024 >= 5) {
    alert("Please upload the image less than 5MB");
    return;
  }
  const splitFilename = image.name.split(".");
  const fileExtension = splitFilename[splitFilename.length - 1];

  return `${BucketAlbums.BASE_AVATAR}/${short.generate()}.${fileExtension}`;
}
