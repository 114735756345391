import Button, { ButtonTypes, IconSides } from "components/Button";
import { locationSubject } from "components/LocationListener";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Email from "./userSettingsV2/sections/Email";
import PasswordChange from "./userSettingsV2/sections/PasswordChange";
import Username from "./userSettingsV2/sections/Username";
import WalletConnection from "./userSettingsV2/sections/WalletConnection";
import UserSettingsSection from "./userSettingsV2/UserSettingsSection";
import { LeftOutlined } from "@ant-design/icons";
import store from "store/storeExporter";
import Profile from "./userSettingsV2/sections/Profile";
import { CLEAR_WORKSPACE_DATA } from "store/actions";
import { useLocation } from "react-router";
import DeleteUser from "components/DeleteUser";
import DangerZone from "./base/main/settings/DangerZone";
import DiscordSection from "./userSettingsV2/sections/DiscordSection";
import styles from "./userSettings/userSettings.module.scss";

const UserSettings: React.FC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useLayoutEffect(() => {
    store.dispatch({ type: CLEAR_WORKSPACE_DATA });
  }, []);

  // switch theme code
  let theme = localStorage.getItem("dark");
  const isCheckTheme = () => (theme === "dark-mode" ? true : false);
  const checkTheme = isCheckTheme();
  const [themeSwitch, setThemeSwitch] = useState(checkTheme);

  const toggleTheme = () => {
    if (!checkTheme) {
      localStorage.setItem("dark", "dark-mode");
      document.body.classList.add("dark-mode");
      document.body.classList.remove("light-mode");
      setThemeSwitch(!themeSwitch);
    } else {
      setThemeSwitch(!themeSwitch);
      document.body.classList.remove("dark-mode");
      localStorage.setItem("dark", "light-mode");
    }
  };

  useEffect(() => {
    const focusId = query.get("focusid");
    if (focusId) {
      const el = document.getElementById(focusId);
      if (el) {
        console.log(el);
        el?.scrollIntoView();
      }
    }
  }, []);

  // styles are used for development purposes
  return (
    <>
      <div className={styles.gradientContainer}>
        <div
          className="white-wrapper"
          style={{
            flexGrow: "1",
            height: "100%",
            overflow: "auto",
          }}
        >
          <Button
            buttonType={ButtonTypes.LINK}
            icon={<LeftOutlined />}
            iconSide={IconSides.LEFT}
            style={{ margin: "16px 15px" }}
            onClick={() => {
              locationSubject.next(`/profile`);
            }}
          >
            Return to profile
          </Button>
          <div
            style={{
              maxWidth: "677px",
              margin: "auto",
              width: "90%",
              padding: "54px 0",
              paddingBottom: "164px",
              display: "flex",
              flexDirection: "column",
              gap: "104px",
            }}
          >
            <UserSettingsSection title="Profile">
              <Profile />
            </UserSettingsSection>

            <UserSettingsSection
              title="Discord Connection"
              description="Connect your Discord account to your Clarity account so that Discord can @-mention you in threads for tasks and projects that you are working on."
            >
              <DiscordSection />
            </UserSettingsSection>

            <UserSettingsSection
              title="Wallet Connection"
              description="Use your Ethereum wallet to sign in, and to access token-gated documents"
            >
              <WalletConnection />
            </UserSettingsSection>
            <UserSettingsSection
              title="Theme"
              description="Switch between light and dark appearance"
            >
              <div className={styles.darkThemeContainer}>
                <Button onClick={() => toggleTheme()}>
                  Switch to {`${themeSwitch ? "light" : "dark"}`} mode
                </Button>
                {/* <Switch
                    className={themeSwitch ? styles.checked  : styles.ischeck}
                  defaultChecked
                    onChange={(e) => toggleTheme()}
                    checked={themeSwitch}
                /> */}
              </div>
            </UserSettingsSection>
            <UserSettingsSection
              title="Username"
              description="Your unique identifier in the Clarity network"
            >
              <Username />
            </UserSettingsSection>

            <UserSettingsSection
              title="Email"
              description="Use email to login and to receive app notifications"
            >
              <Email />
            </UserSettingsSection>

            <UserSettingsSection
              title="Password"
              description="Passwords are optional. You don’t need one to login."
              id="password-section"
            >
              <PasswordChange />
            </UserSettingsSection>
            <div>
              <DangerZone
                DangerSections={[
                  {
                    heading: "Delete Account",
                    description:
                      " This will delete your account. Once you do this, there is no going back.",
                    component: <DeleteUser />,
                  },
                ]}
              ></DangerZone>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettings;
