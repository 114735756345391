// Vendor Libraries
import { connect, batch } from "react-redux";
import Helmet from "react-helmet";
import React, {
  useState,
  memo,
  useRef,
  useLayoutEffect,
  useMemo,
  useEffect,
} from "react";
import { RouteComponentProps } from "react-router-dom";
import styles from "./note/note.module.scss";

// Internal Modules
import * as actionTypes from "store/actions";
import {
  DocumentModes,
  IUserObj,
  ContainerTypes,
  IBlockContext,
  ISnippetObj,
} from "utilities/types";
import { getRelatedEntities } from "modules/documentService";
import { ChunkDestination } from "utilities/stateTypes";
import { loadContainerData } from "editor/utils/containerActions/loadContainerActions";
import { Block } from "store/reducers/blockReducer";
import { $focusOn, ClarityStore } from "store/storeExporter";
import { clearBlockSelection } from "editor/utils/blockActions";
import EditorContainer from "editor/EditorContainer";
import { useContainerContextSetter } from "editor/utils/customHooks";
import SnippetTitle from "../../templates/snippets/SnippetTitle";

interface IProjectComponentProps {
  checkForAccordingLine: (param: any) => any;
  baseName: string;
  containerId: string;
  changeDocumentMode: (param: any) => any;
  connected: boolean;
  paneId: ChunkDestination;
  outlineMode: string;
}

interface IMapStateToProps {
  isBaseMember: any;
  assignee: IUserObj;
  projectComments: [];
  newDiscussionDetail: any;
  snippetDict: { [id: string]: ISnippetObj };
}

function Snippet(
  props: RouteComponentProps<any> & IProjectComponentProps & IMapStateToProps
) {
  const documentRef = useRef<HTMLDivElement | null>(null);
  const prevId = useRef("");
  const activeId = useRef(props.containerId);
  activeId.current = props.containerId;
  const titleBlock = useRef<Block | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [templateContext, setTemplateContext] = useState<IBlockContext>({
    id: ContainerTypes.SNIPPET + props.containerId,
    type: "container",
    container: {
      id: props.containerId,
      type: ContainerTypes.SNIPPET,
      outlineMode: props.outlineMode ? props.outlineMode : "noOutline",
    },
    ref: documentRef,
    paneId: props.paneId,
    persistToggle: props.connected && props.isBaseMember,
    autosave: props.connected && props.isBaseMember,
    online: true,
    canComment: props.connected && props.isBaseMember,
    canEdit: props.connected && props.isBaseMember,
  });

  useContainerContextSetter(templateContext, setTemplateContext);

  useLayoutEffect(() => {
    if (
      (activeId && activeId.current !== prevId.current && props.snippetDict) ||
      props.connected
    ) {
      if (activeId.current === "new") return;
      let snippet = props.snippetDict
        ? props.snippetDict[activeId.current]
        : null;
      if (snippet && snippet.id && snippet.id !== "") {
        props.changeDocumentMode(DocumentModes.INSERT);
        loadContainerData({
          containerId: snippet.id,
          containerType: ContainerTypes.SNIPPET,
          titleBlockId: "",
          tagsBlockId: undefined,
          titleBlock,
          outlineMode: snippet.outlineMode,
          isPublicAccess: false,
        }).then(() => {
          batch(() => {
            if (!props.isBaseMember && snippet) {
              if (snippet?.id) {
                getRelatedEntities(snippet.id, ContainerTypes.SNIPPET).then(
                  () => {
                    // if (props.workDict) setisLoading(false);
                  }
                );
              }
            }
          });
        });
      }
    }
    prevId.current = activeId.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerId, props.connected]);

  useEffect(() => {
    return () => {
      if (
        $focusOn.value.focusContext?.id === templateContext.id &&
        $focusOn.value.focusPane === templateContext.paneId
      ) {
        $focusOn.next({ ...$focusOn.value, focusBlockId: undefined });
        clearBlockSelection();
      }
    };
  }, []);

  return useMemo(() => {
    if (
      !props.snippetDict ||
      !activeId.current ||
      !props.snippetDict[props.containerId]?.id
    ) {
      return <></>;
    } else {
      return (
        <>
          <div className="document-wrapper">
            <div className="document-container">
              {props.snippetDict &&
              props.paneId === ChunkDestination.primary ? (
                <Helmet>
                  <title>{`${props.snippetDict[props.containerId]?.name} • ${
                    props.baseName
                  }`}</title>
                </Helmet>
              ) : (
                <></>
              )}
              <div
                className={`document select-area ${styles.NoteDetail__body} ${styles.NoteDetail__body__outlined}`}
                ref={documentRef}
              >
                <div className="container-860">
                  <div className="titleBlockContainer workDetail">
                    <SnippetTitle
                      customClassName=""
                      snippetId={props.containerId}
                    />
                  </div>
                  <EditorContainer
                    context={templateContext}
                    isBaseMember={props.isBaseMember}
                    outlineMode={props.outlineMode}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.containerId,
    props.outlineMode,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.snippetDict?.[props.containerId],
    templateContext,
  ]);
}

const mapStateToProps = (state: ClarityStore, ownProps: any) => {
  let docState = {
    baseName: state.workspace.name,
    snippetDict: state.snippets.dict,
    connected: state.network.connected,
    isBaseMember: state.client.isBaseMember,
    outlineMode:
      state.blocks.contexts[ContainerTypes.SNIPPET + ownProps.containerId]
        ?.outlineMode,
  };
  return docState;
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    changeDocumentMode: (param: any) =>
      dispatch({
        type: actionTypes.CHANGE_DOCUMENT_MODE,
        param,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Snippet));
