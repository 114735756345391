import Icon from "@ant-design/icons";
import React from "react";

export default function ThunderboltTwoTone(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        opacity="0.1"
        d="M16.2983 3.8465H11.0342L6.59045 11.5199H10.2795L8.86624 17.1684L16.3615 10.0176H11.4162L16.2983 3.8465Z"
        fill="currentColor"
      />
      <path
        d="M19.8772 8.42109H14.7139L19.3568 2.55469C19.4529 2.43047 19.3662 2.25 19.2092 2.25H10.2209C10.1553 2.25 10.092 2.28516 10.0592 2.34375L3.98653 12.832C3.91387 12.9563 4.00293 13.1133 4.14824 13.1133H8.23574L6.14043 21.4945C6.0959 21.6773 6.31621 21.8062 6.45215 21.675L20.0061 8.74219C20.1279 8.62734 20.0459 8.42109 19.8772 8.42109ZM8.86621 17.168L10.2795 11.5195H6.59043L11.0342 3.84609H16.2982L11.4162 10.0172H16.3615L8.86621 17.168Z"
        fill="currentColor"
      />
    </svg>
  );
}
