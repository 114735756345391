import {
  ILineValue,
  LineValueType,
  LineMenuState,
  LineType,
} from "utilities/lineUtilities";
import store, { prevState } from "store/storeExporter";
import { stripHtml } from "utilities/stringUtilities";
import { Block } from "store/reducers/blockReducer";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { checkCaretPosition, checkIfInsideCode } from "./caretUtils";
import { axiosInstance } from "index";
import { ContainerTypes } from "utilities/types";
import { getNameFromContainerForEmail } from "modules/containerHelpers";

export const getHtml = (
  objValue: ILineValue[],
  documents?: any,
  inner?: boolean,
  endText?: string
): string => {
  let textToReply: string = "";
  if (!objValue) return textToReply;
  objValue.forEach((val: ILineValue, valIndex) => {
    if (val.type === LineValueType.text)
      textToReply = textToReply + replaceVaules(val.value);
    if (val.type === LineValueType.b)
      textToReply = textToReply + `<b>` + getValue(val, documents) + `</b>`;
    if (val.type === LineValueType.i)
      textToReply = textToReply + `<i>` + getValue(val, documents) + `</i>`;
    if (val.type === LineValueType.strike)
      textToReply =
        textToReply + `<strike>` + getValue(val, documents) + `</strike>`;
    if (val.type === LineValueType.highlight)
      textToReply =
        textToReply + `<highlight>` + getValue(val, documents) + `</highlight>`;
    if (val.type === LineValueType.citation)
      textToReply =
        textToReply +
        `<citation href="${val.linkTo}" data-citation-id="${
          val.options?.citationId
        }" data-source-id="${val.options?.sourceId}">${getValue(
          val,
          documents
        )}</citation>`;
    if (val.type === LineValueType.citationPlaceholder)
      textToReply =
        textToReply +
        `<citationPlaceholder data-source-id="${
          val.options?.sourceId
        }" data-iteration-stop="${val.options?.iterationStop}">${getValue(
          val
        )}</citationPlaceholder>`;
    if (val.type === LineValueType.comment)
      textToReply =
        textToReply +
        `<comment href="${val.linkTo}" class="${
          val.options?.active ? "active" : ""
        }" data-comment-id="${val.options?.commentId}">${getValue(
          val,
          documents
        )}</comment>`;
    if (val.type === LineValueType.a)
      textToReply =
        textToReply +
        '<a class="decorated" href="' +
        (val.linkTo && val.linkTo !== null ? val.linkTo : "/") +
        '"' +
        ">" +
        getValue(val, documents) +
        "</a>";
    if (val.type === LineValueType.code) {
      const value = getValue(val, documents);
      if (value !== "")
        textToReply =
          textToReply +
          '<code class="code-style">' +
          getValue(val, documents) +
          "</code>";
    }
    if (val.type === LineValueType.mention) {
      if (val.linkTo) {
        if (val.options && val.options.hashtag) {
          textToReply =
            textToReply +
            '<mention href="' +
            val.linkTo +
            '" class="' +
            getMentionClassList(val) +
            ' confirmed" ' +
            checkIfWorkTag(val) +
            ' contentEditable="false">' +
            checkHashtag(val) +
            getValue(val, documents) +
            // getHtml(documents[val.linkTo].nameValue, documents, true) +
            "</mention>";
        } else {
          textToReply =
            textToReply +
            '<mention href="' +
            val.linkTo +
            '" class="' +
            getMentionClassList(val) +
            ' confirmed">' +
            checkHashtag(val) +
            getValue(val, documents) +
            // getHtml(documents[val.linkTo].nameValue, documents, true) +
            "</mention>";
        }
      } else {
        textToReply =
          textToReply +
          '<mention class="' +
          getMentionClassList(val, inner) +
          '">' +
          checkHashtag(val, inner) +
          getValue(val, documents) +
          "</mention>";
      }
    }
    if (val.type === LineValueType.title) {
      textToReply = textToReply + getValue(val, documents);
    }
    if (val.type === LineValueType.break)
      textToReply = textToReply + getValue(val, documents);
    if (val.type === LineValueType.childrenText)
      textToReply = textToReply + getValue(val, documents);
    if (val.type === LineValueType.div)
      textToReply = textToReply + `<div>${getValue(val)}</div>`;
    if (val.type === LineValueType.usermention)
      textToReply =
        textToReply +
        `<usermention  user-id="${
          val.options.userId
        }" contentEditable="false">${getValue(val)}</usermention>`;
  });

  if (endText) {
    textToReply += `<end>${endText}</end>`;
  }

  return textToReply;
};

export const getMarkdown = (objValue: ILineValue[]): string => {
  let textToReply: string = "";
  if (!objValue) return textToReply;
  objValue.forEach((val: ILineValue, valIndex) => {
    if (val.type === LineValueType.text)
      textToReply = textToReply + replaceVaules(val.value);
    if (val.type === LineValueType.b)
      textToReply = textToReply + `*` + getMarkdownValue(val) + `*`;
    if (val.type === LineValueType.i)
      textToReply = textToReply + `_` + getMarkdownValue(val) + `_`;
    if (val.type === LineValueType.strike)
      textToReply = textToReply + `~` + getMarkdownValue(val) + `~`;
    if (val.type === LineValueType.highlight)
      textToReply = textToReply + `^` + getMarkdownValue(val) + `^`;
    if (val.type === LineValueType.citation)
      textToReply = textToReply + getMarkdownValue(val);
    if (val.type === LineValueType.citationPlaceholder)
      textToReply = textToReply + getMarkdownValue(val);
    if (val.type === LineValueType.comment)
      textToReply = textToReply + getMarkdownValue(val);
    if (val.type === LineValueType.a)
      textToReply =
        textToReply +
        `[Link](${val.linkTo && val.linkTo !== null ? val.linkTo : "/"})` +
        getMarkdownValue(val);
    if (val.type === LineValueType.code) {
      const value = getMarkdownValue(val);
      if (value !== "")
        textToReply = textToReply + "`" + getMarkdownValue(val) + "`";
    }
    if (val.type === LineValueType.mention) {
      if (val.options && val.options.hashtag) {
        textToReply = textToReply + "#" + getMarkdownValue(val);
      } else {
        textToReply = textToReply + "[[" + getMarkdownValue(val) + "]]";
      }
    }
    if (val.type === LineValueType.title) {
      textToReply = textToReply + getMarkdownValue(val);
    }
    if (val.type === LineValueType.break)
      textToReply = textToReply + getMarkdownValue(val);
    if (val.type === LineValueType.childrenText)
      textToReply = textToReply + getMarkdownValue(val);
    if (val.type === LineValueType.div)
      textToReply = textToReply + getMarkdownValue(val) + "/n";
    if (val.type === LineValueType.usermention)
      textToReply = textToReply + getMarkdownValue(val);
  });

  return textToReply;
};

export const getDiscordMarkdown = (objValue: ILineValue[]): string => {
  let textToReply: string = "";
  if (!objValue) return textToReply;
  objValue.forEach((val: ILineValue, valIndex) => {
    if (val.type === LineValueType.text)
      textToReply = textToReply + replaceVaules(val.value);
    if (val.type === LineValueType.b)
      textToReply = textToReply + `**` + getDiscordMarkdownValue(val) + `**`;
    if (val.type === LineValueType.i)
      textToReply = textToReply + `_` + getDiscordMarkdownValue(val) + `_`;
    if (val.type === LineValueType.strike)
      textToReply = textToReply + `~~` + getDiscordMarkdownValue(val) + `~~`;
    if (val.type === LineValueType.highlight)
      textToReply = textToReply + "`" + getDiscordMarkdownValue(val) + "`";
    if (val.type === LineValueType.citation)
      textToReply = textToReply + getDiscordMarkdownValue(val);
    if (val.type === LineValueType.citationPlaceholder)
      textToReply = textToReply + getDiscordMarkdownValue(val);
    if (val.type === LineValueType.comment)
      textToReply = textToReply + getDiscordMarkdownValue(val);
    if (val.type === LineValueType.a)
      textToReply = textToReply + getDiscordMarkdownValue(val);
    if (val.type === LineValueType.code) {
      const value = getDiscordMarkdownValue(val);
      if (value !== "")
        textToReply = textToReply + "`" + getDiscordMarkdownValue(val) + "`";
    }
    if (val.type === LineValueType.mention) {
      if (val.options && val.options.hashtag) {
        textToReply = textToReply + "#" + getDiscordMarkdownValue(val);
      } else {
        textToReply = textToReply + "[[" + getDiscordMarkdownValue(val) + "]]";
      }
    }
    if (val.type === LineValueType.title) {
      textToReply = textToReply + getDiscordMarkdownValue(val);
    }
    if (val.type === LineValueType.break)
      textToReply = textToReply + getDiscordMarkdownValue(val);
    if (val.type === LineValueType.childrenText)
      textToReply = textToReply + getDiscordMarkdownValue(val);
    if (val.type === LineValueType.div)
      textToReply = textToReply + getDiscordMarkdownValue(val) + "/n";
    if (val.type === LineValueType.usermention)
      textToReply = textToReply + getDiscordMarkdownValue(val);
  });

  return textToReply;
};

const checkIfWorkTag = (val: ILineValue) => {
  if (val.options && val.options.workTag)
    return `work-tag = "true" work-nr = "${Number(val.options.workNr)}"`;
  return "";
};

export const getValue = (node: ILineValue, documents?: any) => {
  if (node.type === LineValueType.usermention) {
    let member = prevState.value.members.dict[node.options.userId];
    if (member) {
      let innerText = "@";
      if (member.name && member.name !== "") {
        innerText = "@" + member.name;
      } else if (member.username) {
        innerText = "@" + member.username;
      }
      return innerText;
    }
  }
  if (node.children && node.children.length > 0) {
    const innerText = getHtml(
      node.children,
      documents,
      node.type === LineValueType.mention ? true : false
    );
    return innerText;
  } else {
    let innerText = node.value;
    return innerText;
  }
};

const getDiscordMarkdownValue = (node: ILineValue, documents?: any) => {
  if (node.type === LineValueType.usermention) {
    let member = prevState.value.members.dict[node.options.userId];
    if (member) {
      let innerText = "@";
      if (member.name && member.name !== "") {
        innerText = "@" + member.name;
      } else if (member.username) {
        innerText = "@" + member.username;
      }
      return "<&" + innerText + "|" + member.id + "&>";
    }
  }
  if (node.children && node.children.length > 0) {
    const innerText = getDiscordMarkdown(node.children);
    return innerText;
  } else {
    let innerText = node.value;
    return innerText;
  }
};

const getMarkdownValue = (node: ILineValue, documents?: any) => {
  if (node.type === LineValueType.usermention) {
    let member = prevState.value.members.dict[node.options.userId];
    if (member) {
      let innerText = "@";
      if (member.name && member.name !== "") {
        innerText = "@" + member.name;
      } else if (member.username) {
        innerText = "@" + member.username;
      }
      return innerText;
    }
  }
  if (node.children && node.children.length > 0) {
    const innerText = getMarkdown(node.children);
    return innerText;
  } else {
    let innerText = node.value;
    return innerText;
  }
};

const getMentionClassList = (val: ILineValue, inner?: boolean): string => {
  if (val.options && val.options.hashtag && !inner) return "mention hashtag";
  else return "mention";
};

const checkHashtag = (val: ILineValue, inner?: boolean): string => {
  if (val.options && val.options.hashtag && !inner) return "#";
  else return "";
};

export const replaceVaules = (value: string) => {
  if (value.includes("&")) value = value.replace(/&/g, "&amp;");
  if (value.includes("<")) value = value.replace(/</g, "&lt;");
  if (value.includes(">")) value = value.replace(/>/g, "&gt;");
  return value;
};

export const iterateThroughValue = (
  valuePart: ILineValue[],
  arrayOfMentions: any[],
  parentMentionText?: string,
  parentIsHashtag?: boolean
): string[] => {
  valuePart.forEach((val: ILineValue) => {
    if (
      val.type === LineValueType.mention &&
      (!val.options.hashtag || !val.linkTo)
    ) {
      let value = val.value;
      if (val.options?.hashtag) {
        parentIsHashtag = true;
      }
      parentMentionText = parentMentionText ? parentMentionText + value : value;
      if (val.children)
        parentMentionText = iterateThroughMentionChildren(
          val.children,
          parentMentionText,
          parentIsHashtag
        );
      arrayOfMentions.push({ el: val, text: parentMentionText });
    }
    if (val.children) {
      parentMentionText = "";
      iterateThroughValue(
        val.children,
        arrayOfMentions,
        parentMentionText,
        parentIsHashtag
      );
    }
  });
  return arrayOfMentions;
};

const iterateThroughMentionChildren = (
  childArray: ILineValue[],
  parentMentionText: string,
  parentIsHashtag?: boolean
): string => {
  childArray.forEach((val: ILineValue) => {
    let value = val.value;
    if (parentIsHashtag) {
      if (value[0] === "#") value = value.substr(1);
    }
    parentMentionText = parentMentionText ? parentMentionText + value : value;
    if (val.children) {
      parentMentionText = iterateThroughMentionChildren(
        val.children,
        parentMentionText,
        parentIsHashtag
      );
    }
  });
  return parentMentionText;
};

let citationUpdateObject: any = {};

const setCitationUpdateObject = (citationId: string, type: string) => {
  if (citationUpdateObject[citationId] && type === "update") {
    if (
      !(
        citationUpdateObject[citationId].type === "add" ||
        citationUpdateObject[citationId].type === "remove"
      )
    )
      citationUpdateObject[citationId] = {
        id: citationId,
        type,
      };
  } else {
    citationUpdateObject[citationId] = {
      id: citationId,
      type,
    };
  }
};

let citationChangesTimer: any;
let doneWithChangesInterval = 1000;

const addNewCitationChange = async () => {
  clearTimeout(citationChangesTimer);
  citationChangesTimer = setTimeout(async () => {
    axiosInstance
      .post("/api/citation/transaction", {
        citationUpdateObject,
      })
      .then((res) => {})
      .catch((err) => console.log(err));
    citationUpdateObject = {};
  }, doneWithChangesInterval);
};

export const addToListOfCitationChanges = (params: {
  citationId: string;
  citation: HTMLElement;
  type: string;
}) => {
  params.citation.setAttribute("data-active", "false");
  setCitationUpdateObject(params.citationId, params.type);
  addNewCitationChange();
};

export const checkMenuStateOptions = (menuState: LineMenuState) => {
  switch (menuState.type) {
    case "Mentions": {
      const selection = document.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range: Range = selection.getRangeAt(0);
        const newNode = document.createElement("span");
        newNode.classList.add("mention-placeholder");
        let lastNode = null;
        if (menuState.options?.hashtag) {
          lastNode = document.createTextNode("#");
        } else lastNode = document.createTextNode("\uFEFF");
        if (lastNode) newNode.appendChild(lastNode);
        range.insertNode(newNode);
        range.collapse();
        const newRange = new Range();
        selection.removeAllRanges();
        newRange.setStartAfter(lastNode);
        selection.addRange(newRange);
      }
      break;
    }
    case "UserMentions": {
      const selection = document.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range: Range = selection.getRangeAt(0);
        const newNode = document.createElement("span");
        newNode.classList.add("user-mention-placeholder");
        let lastNode = null;
        lastNode = document.createTextNode("@");
        if (lastNode) newNode.appendChild(lastNode);
        range.insertNode(newNode);
        range.collapse();
        const newRange = new Range();
        selection.removeAllRanges();
        newRange.setStartAfter(lastNode);
        selection.addRange(newRange);
      }
      break;
    }
    case "Link": {
      const newPlaceholder = document.createElement("span");
      newPlaceholder.classList.add("link-entity-placeholder");
      const selection = document.getSelection();
      newPlaceholder.innerHTML = "\uFEFF";
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.insertNode(newPlaceholder);
        const newRange = new Range();
        newRange.setStart(newPlaceholder, 1);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
      break;
    }
    default: {
      const selection = document.getSelection();
      if (selection && selection.rangeCount > 0) {
        const placeholder = document.createElement("span");
        placeholder.classList.add("slash-placeholder");
        placeholder.innerHTML = "/";
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          range.insertNode(placeholder);
          const newRange = new Range();
          newRange.setStart(placeholder, 1);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
    }
  }
};

export const getContainerHtml = (
  selectedBlocks: string[],
  blocksEntities: any
) => {
  const fragment = new DocumentFragment();
  let text = { current: "" };
  let indentLevel = 0;
  selectedBlocks.forEach((blockId: string) => {
    const block = blocksEntities[blockId];
    let elementName = "p";
    switch (block.lineType) {
      case LineType.heading1: {
        elementName = "h1";
        break;
      }
      case LineType.heading2: {
        elementName = "h2";
        break;
      }
      case LineType.heading3: {
        elementName = "h3";
        break;
      }
      case LineType.checkbox: {
        if (block.checkboxStatus === "Done") {
          elementName = "donecheckbox";
        } else {
          elementName = "checkbox";
        }
        break;
      }
      case LineType.image: {
        elementName = "img";
        break;
      }
      default: {
        elementName = "p";
        break;
      }
    }
    const el = document.createElement(elementName);

    // block.ref.current.childNodes.forEach((node: ChildNode) => {
    //   const copy = node.cloneNode(true);
    //   el.appendChild(copy);
    // });
    if (elementName === "img") {
      const resources = store.getState().workspace.resources;
      const referencingResource = resources[block.referencingContainerId];
      el.setAttribute("src", referencingResource.url);
      const el2 = document.createElement("span");
      el2.appendChild(el);
      fragment.appendChild(el2);
    } else if (block.lineType === LineType.work) {
      const work = store.getState().work.dict[block.referencingContainerId];
      if (work) {
        const workName = getNameFromContainerForEmail(
          work,
          ContainerTypes.WORK
        );
        const textElement = document.createTextNode(workName);
        el.appendChild(textElement);
        fragment.appendChild(el);
      }
    } else {
      fragment.appendChild(el);
      el.innerHTML = getHtml(block.value);
    }

    text.current = text.current + stripHtml(el.innerHTML) + "\n";

    if (
      block.children &&
      block.children.length > 0
      // && !block.isFolded
    ) {
      // el.setAttribute("collapsed", "true");
      // el.appendChild(document.createElement("br"));
      const listElementType =
        block.subLinesList === LineType.numberedList ? "ol" : "ul";
      const listElement = document.createElement(listElementType);
      addChildrenBlocks(
        block.children,
        listElement,
        blocksEntities,
        text,
        indentLevel + 1
      );
      fragment.appendChild(listElement);
    } else el.appendChild(document.createElement("br"));
  });
  return {
    fragment,
    text,
  };
};

export const addChildrenBlocks = (
  childArray: string[],
  listElement: HTMLElement,
  blockEntities: any,
  text: { current: string },
  indentLevel: number
) => {
  childArray.forEach((blockId: string) => {
    const block = blockEntities[blockId];
    const listEl = document.createElement("li");
    listEl.innerHTML = getHtml(block.value);
    const childText = stripHtml(listEl.innerHTML);

    let spacing = "";
    for (let i = 0; i < indentLevel - 1; i++) {
      spacing = spacing + "\t";
    }
    if (indentLevel > 0) spacing = spacing + "- ";

    text.current = text.current + spacing + childText + "\n";
    // block.ref.current.childNodes.forEach((node: ChildNode) => {
    //   const copy = node.cloneNode(true);
    //   listEl.appendChild(copy);
    // });
    listElement.appendChild(listEl);
    if (
      block.children &&
      block.children.length > 0
      // && !block.isFolded
    ) {
      const listElementType =
        block.subLinesList === LineType.numberedList ? "ol" : "ul";
      const listElementInner = document.createElement(listElementType);
      addChildrenBlocks(
        block.children,
        listElementInner,
        blockEntities,
        text,
        indentLevel + 1
      );
      listElement.appendChild(listElementInner);
    }
  });
};

export const getContainerMarkdown = (
  selectedBlocks: string[],
  blocksEntities: { [id: string]: Block }
) => {
  let text = { current: "" };
  let indentLevel = 0;
  selectedBlocks.forEach((blockId: string) => {
    const block = blocksEntities[blockId];
    switch (block.lineType) {
      case LineType.heading1: {
        text.current = "**" + text.current + "**";
        break;
      }
      case LineType.heading2: {
        text.current = "**" + text.current + "**";
        break;
      }
      case LineType.heading3: {
        text.current = "**" + text.current + "**";
        break;
      }
      case LineType.image: {
        const resources = store.getState().workspace.resources;
        const referencingResource = block?.referencingContainerId
          ? resources[block?.referencingContainerId]
          : undefined;
        text.current = text.current + referencingResource?.url;
        break;
      }
      default: {
        break;
      }
    }

    text.current = text.current + getDiscordMarkdown(block.value);

    if (block.children && block.children.length > 0) {
      const listElementType =
        block.subLinesList === LineType.numberedList ? "ol" : "ul";

      addChildrenToMarkdown(
        block.children,
        listElementType,
        blocksEntities,
        text,
        indentLevel + 1
      );
    }
  });
  return {
    text,
  };
};

export const addChildrenToMarkdown = (
  childArray: string[],
  listElement: "ul" | "ol",
  blockEntities: any,
  text: { current: string },
  indentLevel: number
) => {
  childArray.forEach((blockId: string, index: number) => {
    const block = blockEntities[blockId];

    let spacing = "";
    for (let i = 0; i < indentLevel - 1; i++) {
      spacing = spacing + ".";
    }

    if (indentLevel > 0)
      spacing = spacing + listElement === "ul" ? "• " : `${index + 1}- `;

    text.current =
      text.current + spacing + getDiscordMarkdown(block.value) + "\n";

    if (block.children && block.children.length > 0 && !block.isFolded) {
      const listElementType =
        block.subLinesList === LineType.numberedList ? "ol" : "ul";
      addChildrenToMarkdown(
        block.children,
        listElementType,
        blockEntities,
        text,
        indentLevel + 1
      );
    }
  });
};

export const findType = (child: ChildNode | any): LineValueType => {
  const name: string = child.nodeName;
  for (const key of keys(LineValueType)) {
    if (LineValueType[key] === name) {
      if (LineValueType[key] === LineValueType.a) {
        if (child.attributes) {
          for (const attr of child.attributes) {
            if (attr.name === "class" && attr.value === "mention")
              return LineValueType.mention;
          }
          return LineValueType[key];
        }
      } else return LineValueType[key];
    }
  }
  return LineValueType.text;
};

export function keys<O extends object>(obj: O): Array<keyof O> {
  return Object.keys(obj) as Array<keyof O>;
}

export const breakDownHtml = (
  child: any,
  parentIsHashtag?: boolean
): ILineValue => {
  let nodeType = findType(child);
  let linkTo;
  let options: any = {};

  if (nodeType === LineValueType.a || nodeType === LineValueType.mention) {
    const refArray = child.attributes;
    if (
      nodeType === LineValueType.mention &&
      child.classList.contains("hashtag")
    ) {
      options.hashtag = true;
      parentIsHashtag = true;
      const hasAttribute = child.getAttribute("work-tag");
      if (hasAttribute === "true") options.workTag = true;
      const workNumber = child.getAttribute("work-nr");
      if (hasAttribute) options.workNr = workNumber;
    }

    for (const attribute of refArray) {
      if (attribute.nodeName === "href") {
        linkTo = attribute.textContent;
        break;
      }
    }
  }
  if (nodeType === LineValueType.citation) {
    const refArray = child.attributes;
    options = {};
    for (const attribute of refArray) {
      if (attribute.nodeName === "href") {
        linkTo = attribute.textContent;
      }
      if (attribute.nodeName === "data-citation-id") {
        options = { ...options, citationId: attribute.textContent };
      }
      if (attribute.nodeName === "data-source-id") {
        options = { ...options, sourceId: attribute.textContent };
      }
    }
  }
  if (nodeType === LineValueType.citationPlaceholder) {
    const refArray = child.attributes;
    options = {};
    for (const attribute of refArray) {
      if (attribute.nodeName === "href") {
        linkTo = attribute.textContent;
      }
      if (attribute.nodeName === "data-source-id") {
        options = { ...options, sourceId: attribute.textContent };
      }
      if (attribute.nodeName === "data-iteration-stop") {
        const value = attribute.textContent === "true" ? "true" : "false";
        options = { ...options, iterationStop: value };
      }
    }
  }
  if (nodeType === LineValueType.usermention) {
    const refArray = child.attributes;
    for (const attribute of refArray) {
      if (attribute.nodeName === "user-id") {
        options = {
          userId: attribute.textContent,
        };
      }
    }
  }
  if (nodeType === LineValueType.comment) {
    const refArray = child.attributes;
    for (const attribute of refArray) {
      if (attribute.nodeName === "href") {
        linkTo = attribute.textContent;
      }
      if (attribute.nodeName === "data-comment-id") {
        const active = child.classList.contains("active");
        options = {
          commentId: attribute.textContent,
          active: active ? active : false,
        };
      }
    }
  }
  if (nodeType === LineValueType.s) nodeType = LineValueType.strike;
  if (nodeType === LineValueType.del) nodeType = LineValueType.strike;
  if (nodeType === LineValueType.strong) nodeType = LineValueType.b;
  if (nodeType === LineValueType.em) nodeType = LineValueType.i;
  let arrayfromChildren: any = [];
  if (nodeType !== LineValueType.text) {
    if (nodeType === LineValueType.span) nodeType = LineValueType.childrenText;
    for (const subchild of Array.from(child.childNodes)) {
      const check: any = subchild;
      if (
        !check.classList ||
        (check.classList && !check.classList.contains("ignore"))
      )
        arrayfromChildren.push(breakDownHtml(subchild, parentIsHashtag));
    }
  }

  const val: ILineValue = {
    type: nodeType,
    value:
      nodeType === LineValueType.text && child.textContent !== null
        ? getNodeText(child.textContent, parentIsHashtag)
        : "",
    children: arrayfromChildren,
    linkTo,
    options: { ...options },
  };
  return val;
};

const getNodeText = (text: string, parentIsHashtag?: boolean): string => {
  if (!parentIsHashtag) return text;
  else if (text[0] && text[0] === "#") return text.substr(1);
  return text;
};

export const removeBlancks = (el: HTMLElement) => {
  const braks = el.querySelectorAll("br");
  braks.forEach((br) => br.remove());
};

export const checkIfNeedsCombination = (event: any, props: any) => {
  const caretPosition = checkCaretPosition(event.currentTarget);
  const selection: any = document.getSelection();
  checkEmptyChars(props, caretPosition, event);
  moveCaretToPreviousPosition(event.currentTarget, caretPosition);
  if (selection) {
    event.currentTarget.normalize();
    const result = checkIfInsideCode(selection.focusNode);
    if (result.includes(LineValueType.code)) {
      const value = [];
      for (const child of event.currentTarget.childNodes) {
        value.push(breakDownHtml(child));
      }
      const defValue = combineSameTags(value);
      event.currentTarget.innerHTML = getHtml(defValue);
      moveCaretToPreviousPosition(event.currentTarget, caretPosition);
      return;
    }
  }
};

export const checkEmptyChars = (
  props: any,
  caretPosition: number,
  event: any
) => {
  const selection: any = window.getSelection();
  if (selection) {
    if (selection.focusNode) {
      if (
        selection.focusNode.nodeValue &&
        selection.focusNode.nodeValue.length > 1
      ) {
        const matches = selection.focusNode.nodeValue.match(/\uFEFF/g);
        const nrOfEmpty = matches ? matches.length : 0;
        if (nrOfEmpty > 0) {
          selection.focusNode.nodeValue = selection.focusNode.nodeValue.replace(
            /\uFEFF/g,
            ``
          );
          moveCaretToPreviousPosition(
            event.currentTarget,
            caretPosition - nrOfEmpty
          );

          if (props.changeLineText)
            return props.changeLineText({
              event,
              id: props.id,
              caretPosition: caretPosition - nrOfEmpty,
              updateLine: true,
            });
        }
      }
    }
  }
};

export const combineSameTags = (
  val: ILineValue[],
  fromDelete?: boolean
): ILineValue[] => {
  const nrOfElements = val.length;
  const newVal: ILineValue[] = [];
  const indexesToPut = [];
  for (let i = nrOfElements - 1; i >= 0; i--) {
    if (i > 0) {
      if (
        val[i].type === val[i - 1].type &&
        ![LineValueType.a, LineValueType.mention].includes(val[i].type)
      ) {
        val[i - 1].value = val[i - 1].value + val[i].value;
        let firstChildren = val[i - 1].children ? val[i - 1].children : [];
        const secondChild = val[i].children ? val[i].children : [];
        if (secondChild) {
          secondChild.forEach((child) => {
            if (firstChildren) firstChildren.push(child);
          });
        }
        val[i - 1].children = combineSameTags(
          firstChildren ? firstChildren : [],
          fromDelete
        );
      } else {
        indexesToPut.push(i);
      }
    } else indexesToPut.push(i);
  }
  indexesToPut.forEach((index) => {
    newVal.unshift(val[index]);
  });
  return newVal;
};
