import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import store from "store/storeExporter";
import style from "./notes/notes.module.scss";
import {
  Abilities,
  BaseType,
  ContainerTypes,
  ITemplateObj,
  UserRole,
  ViewNames,
  WorkTypes,
} from "utilities/types";
import Button, { ButtonTypes } from "components/Button";
import {
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import EmptyState from "clarity-ui/EmptyState";
import { SectionWidthModes, useShallowSelector } from "utilities/hooks";
import { useAbilityChecker } from "editor/utils/customHooks";
import { batch } from "react-redux";
import Conditional from "components/Conditional";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import { ChunkDestination } from "utilities/stateTypes";
import {
  ActionIntercept,
  INewTemplateCreationModes,
  openNewTemplateModal,
  setActionInterceptor,
} from "store/reducers/clientReducer";
import { PlusOutlined } from "@ant-design/icons";
import navigationApi from "clientApi/navigationApi";
import roleApi from "clientApi/rolesApi";
import { Virtuoso } from "react-virtuoso";
import { AutoSizer } from "react-virtualized";
import {
  getCreatedString,
  getNameFromContainer,
} from "modules/containerHelpers";
import { getNameFromWorkType } from "utilities/workUtils";
import { templatesApi } from "clientApi/templateApi";
import UserDisplay from "clarity-ui/UserDisplay";

const TemplatesList: React.FC<{ paneId: ChunkDestination }> = memo(
  ({ paneId }) => {
    usePageDataSetter(paneId, {
      title: "Templates",
    });

    useEffect(() => {
      batch(() => {
        setPaneSubroutes({
          paneId,
          subRoutesParams: {
            type: "templates",
            activeKey: "templates",
          },
        });
        setPaneTopNavViewType({
          paneId,
          navType: TopNavbarType.base,
        });
      });

      return () => {
        batch(() => {
          setPaneSubroutes({
            paneId,
          });
          setPaneTopNavViewType({
            paneId,
          });
        });
      };
    }, []);

    const templateIds = useShallowSelector(
      (store) => store.templates.rootTemplateIds
    );

    const userRole = useShallowSelector((store) => store.client.roleType);

    const canAddTask = useAbilityChecker({
      abilityName: Abilities.CAN_EDIT_ENTITY,
    });

    const rowRenderer = useCallback(
      (index: number) => {
        const id = templateIds[index];
        return <TemplateRow id={id} paneId={paneId} canAddTask={canAddTask} />;
      },
      [templateIds, paneId, canAddTask]
    );

    const listRef = useRef<HTMLDivElement | null>(null);

    const widthMode = usePageWidth(listRef);

    const getClassName = useCallback(() => {
      switch (widthMode) {
        case SectionWidthModes.large:
          return style.large;
        case SectionWidthModes.medium:
          return style.medium;
        case SectionWidthModes.small:
          return style.small;
      }
    }, [widthMode]);

    return (
      <>
        <div
          ref={listRef}
          style={{ height: "100%", width: "100%" }}
          className={`${style.page} ${getClassName()}`}
        >
          <Conditional on={Boolean(userRole !== UserRole.GUEST)}>
            <TopNavFiltersBar paneId={paneId}>
              <AddTemplate />
            </TopNavFiltersBar>
          </Conditional>
          <div className={style.content}>
            {templateIds.length === 0 ? (
              <EmptyState
                heading={""}
                caption={"There are no templates created in this base"}
              />
            ) : (
              <>
                <Titles />
                <AutoSizer>
                  {({ width, height }) => {
                    return (
                      <>
                        <Virtuoso
                          className="scrollContentY"
                          totalCount={templateIds.length}
                          width={width}
                          style={{
                            height: height + "px",
                            width: `${width + 8}px`,
                            marginLeft: "-8px",
                          }}
                          overscan={5}
                          height={height}
                          itemContent={(index) => rowRenderer(index)}
                        />
                      </>
                    );
                  }}
                </AutoSizer>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);

const Titles: React.FC = () => {
  return (
    <div className={`${style.NoteItem} ${style.titleRow}`}>
      <div className={`${style.itemHead} label disabled medium`}>Name</div>
      <div className={style.itemTail}>
        <span
          className={`${style.field} ${style.column} ${style.tags} label  medium disabled`}
        >
          Type
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date} label  medium disabled`}
        >
          Created By
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date} label  medium  disabled`}
        >
          Updated at
        </span>
        <span
          className={`${style.field} ${style.column} ${style.useTemplateButtonCol} label secondary medium`}
        ></span>
      </div>
    </div>
  );
};

const TemplateRow: React.FC<{
  id: string;
  canAddTask: boolean;
  paneId: ChunkDestination;
}> = ({ id, paneId, canAddTask }) => {
  const templateObj = useShallowSelector((store) => store.templates.dict[id]);
  const navigate = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    navigationApi.contextBasedNavigate({
      currentPane: paneId,
      shiftKey: e.shiftKey,
      navigationChunk: {
        viewName: ViewNames.Detail,
        entity: {
          containerId: id,
          containerType: ContainerTypes.TEMPLATE,
        },
      },
    });
  }, []);

  if (!templateObj) return <></>;
  return (
    <div className={style.NoteItem}>
      <div className={style.itemHead}>
        {/* {getWorkTypeIcons(templateObj.workType)} */}
        <span
          onClick={navigate}
          className={`body regular exerpt  ${style.title}`}
        >
          {getNameFromContainer(templateObj, ContainerTypes.TEMPLATE)}
        </span>
      </div>
      <div className={style.itemTail}>
        <span
          className={`${style.field} ${style.column} ${style.tags} caption regular secondary exerpt`}
        >
          {getNameFromWorkType(templateObj.workType, true)}
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date} caption regular  exerpt`}
        >
          {/* {getCreatedString(new Date(templateObj.dateCreated))} */}
          <UserDisplay hideAvatar={true} id={templateObj.authorId} />
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date} caption regular secondary exerpt`}
        >
          {getCreatedString(
            new Date(templateObj.dateModified ?? templateObj.dateCreated)
          )}
        </span>
        <span
          className={`${style.field} ${style.column}  ${style.useTemplateButtonCol} caption   exerpt`}
        >
          <Button
            className={style.useTemplateButtonCol__button}
            buttonType={ButtonTypes.PRIMARY}
            disabled={!canAddTask}
            onClick={(e: any) => {
              if (!canAddTask) return;
              templatesApi.openCreateWorkItemFromTemplate(id);
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Use template
          </Button>
        </span>
      </div>
    </div>
  );
};

const AddTemplate: React.FC = () => {
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });
  // const [ismenuOpened, setIsmenuOpened] = useState(false);
  const storeData = useShallowSelector((store) => ({
    baseType: store.workspace.type,
    isBaseMember: store.client?.isBaseMember,
    isGuest: store.client.roleType === UserRole.GUEST,
    userId: store.user?.id,
  }));

  const checkIfIsDisabeld = () => {
    if (storeData.baseType === BaseType.Public) {
      if (!storeData.isBaseMember || storeData.isGuest) return false;
    }
    return !canEditEntity;
  };

  const checkOpenModal = (workType: WorkTypes) => {
    createTemplate(workType);
  };

  const isDisabled = checkIfIsDisabeld();

  return (
    <Button
      disabled={isDisabled}
      buttonType={ButtonTypes.PRIMARY}
      onClick={(e: any) => {
        e.stopPropagation();
        checkOpenModal(WorkTypes.TASK);
      }}
      icon={<PlusOutlined />}
    >
      Template
    </Button>
  );

  // return (
  //   <Dropdown
  //     onVisibleChange={handleVisibleChange}
  //     visible={ismenuOpened}
  //     overlay={
  //       <Menu
  //         close={() => handleVisibleChange(false)}
  //         slim={true}
  //         xDir="left"
  //         yDir="down"
  //       >
  //         <MenuItem
  //           title="Task Template"
  //           disabled={isDisabled}
  //           icon={<CheckCircleOutlined />}
  //           onClick={() => checkOpenModal(WorkTypes.TASK)}
  //         />
  //         <MenuItem
  //           title="Project Template"
  //           disabled={isDisabled}
  //           icon={<FileDoneOutlined />}
  //           onClick={() => checkOpenModal(WorkTypes.PROJECT)}
  //         />
  //         <MenuItem
  //           title="Goal Template"
  //           disabled={isDisabled}
  //           icon={<AimOutlined />}
  //           onClick={() => checkOpenModal(WorkTypes.INITIATIVE)}
  //         />
  //       </Menu>
  //     }
  //   >
  //     <Button
  //       disabled={!canEditEntity}
  //       buttonType={ButtonTypes.PRIMARY}
  //       onClick={(e: any) => {
  //         e.stopPropagation();
  //         setIsmenuOpened(!ismenuOpened);
  //       }}
  //       icon={<PlusOutlined />}
  //     >
  //       Template
  //     </Button>
  //   </Dropdown>
  // );
};

const checkShowAffordance = () => {
  const storeData = store.getState();
  const storeDataObj = {
    baseType: storeData.workspace.type,
    isBaseMember: storeData.client?.isBaseMember,
    isGuest: storeData.client.roleType === UserRole.GUEST,
    userId: storeData.user?.id,
  };
  const canEditEntity = roleApi.checkAbility({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  let visitMode: ActionIntercept | undefined = ActionIntercept.default;
  const getActionIntercept = () => {
    if (!canEditEntity) {
      if (storeDataObj.baseType === BaseType.Public) {
        if (!storeDataObj.isBaseMember || storeDataObj.isGuest) {
          if (!storeDataObj.userId)
            return ActionIntercept.notAuthUserInPublicBase;
          if (!storeDataObj.isBaseMember)
            return ActionIntercept.authedUserInPublicBase;
          if (storeDataObj.isGuest) return ActionIntercept.guestInPublicBase;
        }
      }
    }
    return;
  };

  visitMode = getActionIntercept();

  if (visitMode) setActionInterceptor(visitMode);
};

const usePageWidth = (
  templatesRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const [sectionWidthMode, setsectionWidthMode] = useState<SectionWidthModes>(
    SectionWidthModes.large
  );
  useEffect(() => {
    if (!templatesRef.current) return;
    templatesRef.current?.focus();
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width > 960)
          return setsectionWidthMode(SectionWidthModes.large);
        if (600 < entry.contentRect.width && entry.contentRect.width <= 960)
          return setsectionWidthMode(SectionWidthModes.medium);
        if (entry.contentRect.width <= 600) {
          return setsectionWidthMode(SectionWidthModes.small);
        }
      }
    });
    if (templatesRef.current) resizeObserver.observe(templatesRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return sectionWidthMode;
};

export const createTemplate = (
  workType: WorkTypes,
  presets?: Partial<ITemplateObj>
) => {
  checkShowAffordance();
  const canEditEntity = roleApi.checkAbility({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });
  if (!canEditEntity) return;
  openNewTemplateModal({
    type: INewTemplateCreationModes.new,
    presetData: {
      workType,
      ...(presets ?? {}),
    },
  });
  return true;
};

export default TemplatesList;
