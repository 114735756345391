import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import Conditional from "components/Conditional";
import { useAbilityChecker } from "editor/utils/customHooks";
import ThunderboltTwoTone from "icons/Components/ThunderboltTwoTone";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import { useShallowSelector } from "utilities/hooks";
import {
  Abilities,
  CommandPaletteContext,
  ContainerTypes,
  WorkTypes,
} from "utilities/types";
import styles from "../../containerDetailMetadata/containerDetailMetadata.module.scss";

const CycleRow: React.FC<{
  itemId: string;
  workType: WorkTypes;
  cycleId: string | null | undefined;
  groupId: string;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ workType, cycleId, groupId, itemId }) => {
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });
  const group = useShallowSelector((store) =>
    groupId ? store.groups.dict[groupId] : undefined
  );
  const cycle = useShallowSelector((store) =>
    cycleId ? store.work.sections[cycleId] : undefined
  );

  const handleCycleClick = () =>
    openCommandPalette(CommandPaletteContext.WORK_SECTION, {
      selectedItemIds: [itemId],
      slectedItemsType: ContainerTypes.WORK,
    });

  const cycleName = cycle?.name;

  return (
    <Conditional on={group?.showCycles && workType === WorkTypes.TASK}>
      <MetadataBlock
        title="Sprint"
        disabled={!canEditEntity}
        infoText={
          cycleId ? cycleName : <span className={"disabled"}>No sprint</span>
        }
        onClick={handleCycleClick}
        prefix={
          <span className={cycleId ? "" : "disabled"}>
            <CycleDisplay />
          </span>
        }
      />
    </Conditional>
  );
};

function CycleDisplay() {
  return (
    <div className={styles.cycleDisplay}>
      <ThunderboltTwoTone className={styles.icon} />
    </div>
  );
}

export default CycleRow;
