import { socket } from "App";
import { axiosInstance } from "index";
import { SET_BASE_TOKENS, UPDATE_TOKEN } from "store/actions";
import store from "store/storeExporter";
import { IReward, IToken, TokenTypes } from "utilities/types";
import { ChainNames, Chains } from "utilities/web3/connectors";
import notificationsApi from "./notificationsApi";

const { v4: uuidv4 } = require("uuid");

const networkIdToProviderIdentifier: { [id in Chains]: string } = {
  [Chains.ETHEREUM]: "ethereum",
  [Chains.POLYGON]: "polygon-pos",
  [Chains.BSC]: "binance-smart-chain",
  [Chains.AVALANCHE]: "avalanche",
  [Chains.XDAI]: "xdai",
  [Chains.FANTOM]: "fantom",
  [Chains.ARBITRUM]: "arbitrum-one",
  [Chains.CELO]: "celo",
  [Chains.HARMONY]: "harmony-shard-0",
  [Chains.OPTIMISM]: "optimistic-ethereum",
  [Chains.MOONRIVER]: "moonriver",
  [Chains.GOERLI]: "",
  [Chains.RINKEBY]: "",
  [Chains.ROPSTEN]: "",
};

const networkIdToNativeCurrencyFinder: { [id in Chains]: string } = {
  [Chains.ETHEREUM]: "ethereum",
  [Chains.POLYGON]: "matic-network",
  [Chains.BSC]: "binance-smart-chain",
  [Chains.AVALANCHE]: "avalanche",
  [Chains.XDAI]: "xdai",
  [Chains.FANTOM]: "fantom",
  [Chains.ARBITRUM]: "arbitrum-one",
  [Chains.CELO]: "celo",
  [Chains.HARMONY]: "harmony-shard-0",
  [Chains.OPTIMISM]: "optimistic-ethereum",
  [Chains.MOONRIVER]: "moonriver",
  [Chains.GOERLI]: ChainNames.GOERLI,
  [Chains.RINKEBY]: ChainNames.RINKEBY,
  [Chains.ROPSTEN]: ChainNames.ROPSTEN,
};
interface ITokenPriceCache {
  toUsd: number;
  updateTime: Date;
  expires: number | null;
}
class TokenApi {
  presetTokens: string[] = ["2", "3", "4", "5", "6"];
  presetTokenDict: { [id: string]: IToken } = {
    "1": {
      id: "1",
      contractAddress: "",
      networkId: 1,
      name: "Ethereum",
      symbol: "ETH",
      type: TokenTypes.NATIVE_CURRENCY,
      icon: "https://assets.coingecko.com/coins/images/279/thumb/ethereum.png?1595348880",
      default: false,
      baseId: "",
    },
    "2": {
      id: "2",
      contractAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      networkId: 1,
      name: "Dai Stablecoin",
      symbol: "DAI",
      type: TokenTypes.ERC20,
      icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
      default: false,
      baseId: "",
    },
    "3": {
      id: "3",
      contractAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      networkId: 1,
      name: "USDCoin",
      symbol: "USDC",
      type: TokenTypes.ERC20,
      icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
      default: false,
      baseId: "",
    },
    "4": {
      id: "4",
      contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      networkId: 1,
      name: "Tether USD",
      symbol: "USDT",
      type: TokenTypes.ERC20,
      icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
      default: false,
      baseId: "",
    },
    "5": {
      id: "5",
      contractAddress: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      networkId: 1,
      name: "Wrapped BTC",
      symbol: "WBTC",
      type: TokenTypes.ERC20,
      icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
      default: false,
      baseId: "",
    },
    "6": {
      id: "6",
      contractAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      networkId: 1,
      name: "Wrapped Ether",
      symbol: "WETH",
      type: TokenTypes.ERC20,
      icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
      default: false,
      baseId: "",
    },
  };

  defaultExpiration = 900;

  nativeCurrencyPriceCache: { [id: string]: ITokenPriceCache } = {
    [Chains.GOERLI]: {
      toUsd: 0,
      updateTime: new Date(),
      expires: null,
    },
    [Chains.RINKEBY]: {
      toUsd: 0,
      updateTime: new Date(),
      expires: null,
    },
    [Chains.ROPSTEN]: {
      toUsd: 0,
      updateTime: new Date(),
      expires: null,
    },
  };

  coinPriceCache: { [id: string]: ITokenPriceCache } = {
    ["0x6B175474E89094C44Da98b954EedeAC495271d0F".toLowerCase()]: {
      toUsd: 1,
      updateTime: new Date(),
      expires: null,
    },
    ["0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48".toLowerCase()]: {
      toUsd: 1,
      updateTime: new Date(),
      expires: null,
    },
    ["0xdAC17F958D2ee523a2206206994597C13D831ec7".toLowerCase()]: {
      toUsd: 1,
      updateTime: new Date(),
      expires: null,
    },
  };

  getTokensForBase(baseId: string) {
    return axiosInstance
      .get("/api/token/allBaseTokens", {
        params: {
          baseId,
        },
      })
      .then((res) => {
        store.dispatch({
          type: SET_BASE_TOKENS,
          param: {
            tokens: res.data,
          },
        });
      });
  }

  generateToken(data: {
    name: string;
    symbol: string;
    icon?: string;
    tokenType: TokenTypes;
    contractAddress: string;
    networkId: number;
  }) {
    const newToken: IToken = {
      baseId: store.getState().workspace.id,
      contractAddress: data.contractAddress,
      default: false,
      id: uuidv4(),
      name: data.name,
      networkId: data.networkId,
      symbol: data.symbol,
      type: data.tokenType,
      icon: data.icon,
    };
    this.save(newToken);
  }

  save(token: IToken) {
    const tokens = store.getState().tokens;
    for (const id of tokens.ids) {
      const existingtoken = tokens.dict[id];
      if (existingtoken.contractAddress === token.contractAddress) {
        notificationsApi.displayError({
          title: "Cannot add token",
          body: "Token address already exists",
        });
        return;
      }
    }
    axiosInstance.post("/api/token", {
      token,
      clientId: socket.id,
    });
    this.updateState(token.id, token, "add");
  }

  update(id: string, delta: Partial<IToken>) {
    const tokens = store.getState().tokens;
    const currentToken = tokens.dict[id];
    axiosInstance.patch("/api/token", {
      delta,
      id,
      clientId: socket.id,
    });
    if (delta.default && delta.default !== currentToken.default) {
      let currentDefault = null;
      tokens.ids.forEach((tokenId) => {
        if (tokens.dict[tokenId].default) currentDefault = tokenId;
      });
      if (currentDefault) this.update(currentDefault, { default: false });
    }

    this.updateState(id, delta, "update");
  }

  updateState(
    id: string,
    delta: Partial<IToken> | IToken,
    type: "add" | "update" | "delete"
  ) {
    store.dispatch({
      type: UPDATE_TOKEN,
      param: {
        id,
        delta: delta,
        type,
      },
    });
  }

  delete(id: string) {
    axiosInstance
      .delete("/api/token", {
        data: {
          id,
          clientId: socket.id,
        },
      })
      .then(() => {
        this.updateState(id, {}, "delete");
      })
      .catch(() => {
        notificationsApi.displayError({
          title: "Cannot delete token",
          body: "This token cannot be deleted because it is being used in a reward or token gate",
        });
      });
  }

  getRewardPrice(reward: IReward) {
    if (reward.type === TokenTypes.NATIVE_CURRENCY) {
      if (!reward.networkId) return;
      const networkId = Number(reward.networkId) as Chains;
      if (!(networkId in Chains)) return;
      const networkName = networkIdToNativeCurrencyFinder[networkId];
      return this.nativeCurrencyPriceCache[networkName]?.toUsd ?? 0;
    }
    if (reward.type === TokenTypes.ERC20 && reward.contractAddress) {
      const id = reward.contractAddress.toLowerCase();
      return this.coinPriceCache[id]?.toUsd ?? 0;
    }
    return 0;
  }

  async getNativeCurrencyPrice(networkIds: number[]) {
    let networks = "";
    networkIds.forEach((networkId: Chains, index) => {
      if (networkIdToNativeCurrencyFinder[networkId]) {
        networks = networks + networkIdToNativeCurrencyFinder[networkId];
      }
      if (index < networkIds.length - 1) networks = networks + ",";
    });

    return axiosInstance
      .get("https://api.coingecko.com/api/v3/simple/price", {
        params: {
          ids: networks,
          vs_currencies: "usd",
        },
      })
      .then((res) => {
        const tokens = res.data;
        Object.entries(tokens).forEach(([key, value]) => {
          this.nativeCurrencyPriceCache[(key as string).toLowerCase()] = {
            toUsd: (value as any).usd as number,
            updateTime: new Date(),
            expires: this.defaultExpiration,
          };
        });
      });
  }

  async getTokensPrice(contractAdresses: string, networkId: number) {
    const networkName = networkIdToProviderIdentifier[networkId as Chains];
    if (!networkName) return;
    return axiosInstance
      .get(
        `https://api.coingecko.com/api/v3/simple/token_price/${networkName}`,
        {
          params: {
            contract_addresses: contractAdresses,
            vs_currencies: "usd",
          },
        }
      )
      .then((res) => {
        const tokens = res.data;
        Object.entries(tokens).forEach(([key, value]) => {
          this.coinPriceCache[(key as string).toLowerCase()] = {
            toUsd: (value as any).usd as number,
            updateTime: new Date(),
            expires: this.defaultExpiration,
          };
        });
      });
  }

  getTokensToFetchPrice(tokens: IReward[]) {
    let fetchTokens: { [id: number]: string[] } = {};

    let fetchNativeCurrency: number[] = [];
    const addToTokensToFetch = (networkId: number, contractAdress: string) => {
      if (!fetchTokens[networkId]) fetchTokens[networkId] = [];
      fetchTokens[networkId].push(contractAdress);
    };

    tokens.forEach((token) => {
      if (token.type === TokenTypes.ERC20 && token.contractAddress) {
        if (
          this.coinPriceCache[token.contractAddress.toLowerCase()] &&
          this.coinPriceCache[token.contractAddress.toLowerCase()].expires
        ) {
          const tokenCacheData =
            this.coinPriceCache[token.contractAddress.toLowerCase()];
          if (
            tokenCacheData.updateTime.getSeconds() +
              (tokenCacheData.expires ?? 0) >
            new Date().getSeconds()
          ) {
            addToTokensToFetch(
              Number(token.networkId ?? 1),
              token.contractAddress
            );
          }
        } else {
          addToTokensToFetch(
            Number(token.networkId ?? 1),
            token.contractAddress
          );
        }
      }
      if (token.type === TokenTypes.NATIVE_CURRENCY && token.networkId) {
        if (
          this.nativeCurrencyPriceCache[Number(token.networkId)] &&
          !this.nativeCurrencyPriceCache[Number(token.networkId)].expires
        ) {
          return;
        }
        if (
          this.nativeCurrencyPriceCache[Number(token.networkId)] &&
          this.nativeCurrencyPriceCache[Number(token.networkId)].toUsd
        ) {
          if (
            this.nativeCurrencyPriceCache[
              Number(token.networkId)
            ].updateTime.getSeconds() +
              (this.nativeCurrencyPriceCache[Number(token.networkId)].expires ??
                0) >
            new Date().getSeconds()
          ) {
            fetchNativeCurrency.push(Number(token.networkId));
          }
        } else fetchNativeCurrency.push(Number(token.networkId));
      }
    });

    Object.entries(fetchTokens).forEach(([key, value]) => {
      fetchTokens[Number(key)] = [...Array.from(new Set(value))];
    });

    fetchNativeCurrency = [...Array.from(new Set(fetchNativeCurrency))];
    return {
      fetchNativeCurrency,
      fetchTokens,
    };
  }

  async getTokenPricesForExport(tokens: IReward[]) {
    const { fetchNativeCurrency, fetchTokens } =
      this.getTokensToFetchPrice(tokens);

    const promises = [];
    if (fetchNativeCurrency.length > 0) {
      promises.push(this.getNativeCurrencyPrice(fetchNativeCurrency));
    }
    Object.entries(fetchTokens).forEach(([key, value]) => {
      let tokenString = "";
      const length = value.length;
      if (length > 0) {
        value.forEach((address, index) => {
          if (index < length - 1) tokenString = tokenString + address;
          else tokenString = tokenString + address + ",";
        });
        promises.push(this.getTokensPrice(tokenString, Number(key)));
      }
      fetchTokens[Number(key)] = [...Array.from(new Set(value))];
    });

    return Promise.all(promises);
  }

  checkIfAddressAlreadyAdded(contractAdress: string, networkId: number) {
    const baseTokens = store.getState().tokens;
    let found = false;
    baseTokens.ids.forEach((id) => {
      if (
        baseTokens.dict[id].networkId === networkId &&
        baseTokens.dict[id].contractAddress === contractAdress
      ) {
        found = true;
      }
    });
    return found;
  }
}

export const tokenApi = new TokenApi();
