import React from "react";
import Logo from "images/clarity-logo-color-small.svg";

const PrivatePageInfo: React.FC<{ presetClassName?: string }> = ({
  children,
  presetClassName,
}): JSX.Element => {
  return (
    <div
      className={presetClassName ? presetClassName : ""}
      style={{ textAlign: "center", paddingTop: "107px", overflowY: "auto" }}
    >
      <img
        src={Logo}
        style={{ width: "125px", marginBottom: "56px" }}
        alt="Clarity Logo"
      />
      <h2 style={{ marginBottom: "16px" }}>This page is private</h2>
      {children}
    </div>
  );
};

export default PrivatePageInfo;
