import ContainerDetailMetadata from "clarity-ui/ContainerDetailMetadata";
import WorkItemFooter from "clarity-ui/WorkItemsFooter";
import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import {
  ContainerTypes,
  IBlockContext,
  IUserObj,
  UserRole,
  WorkTypes,
} from "utilities/types";
import styles from "../screens/base/main/detailView/documentContainer/project/project.module.scss";
import SubTasks from "components/SubTasks";

interface TaskEditorProps {
  id: string;
  context: IBlockContext;
  userRole: UserRole;
  sharedView: boolean;
  noHead?: boolean;
  outlineMode?: string;
}

const TaskEditor: React.FC<TaskEditorProps> = ({
  children,
  id,
  context,
  noHead = false,
  outlineMode,
}) => {
  const workItem = useSelector(
    (store: ClarityStore) => store.work.dict?.[id],
    shallowEqual
  );
  const user: IUserObj | undefined = useSelector((store: ClarityStore) => {
    return store.user ? (store.user as any) : undefined;
  }, shallowEqual);

  const childrenData = useMemo(() => {
    return children;
  }, [children]);

  return useMemo(() => {
    return (
      <>
        {childrenData}
        <div className="taskActionStraightbar">
          <ContainerDetailMetadata
            key={id}
            containerId={id}
            containerType={ContainerTypes.PROJECT}
            paneId={context.paneId}
            rowMode={true}
            noPersist={!context.canEdit}
          />
        </div>
        <div className={styles.subTasksContainer}>
          <SubTasks
            parentWorkId={workItem.id}
            workType={workItem.workType}
            containerType={ContainerTypes.WORK}
            groupIds={
              workItem.workType === WorkTypes.TASK
                ? [workItem.groupId]
                : workItem.groupIds
            }
          />
        </div>
        <>
          <WorkItemFooter workItem={workItem} />
        </>
      </>
    );
  }, [workItem, user, context, noHead, outlineMode, childrenData]);
};

export default TaskEditor;
