import { Tag } from "antd";
import React, { useLayoutEffect, useState } from "react";
import { stripHtml } from "utilities/stringUtilities";
import { ContainerTypes, IProjectObj, ITag } from "utilities/types";
import { truthy } from "utilities/fns";
import { useWork } from "store/reducers/workReducer";
import styles from "./workLabels/workLabels.module.scss";
import { usePages } from "store/reducers/pagesReducer";
import { useOptionalClassName } from "utilities/hooks";
import { getHtml } from "editor/utils/blockValueHelpers";

const WorkLabels: React.FC<{
  workItem: IProjectObj;
  noContainer?: boolean;
  containerClass?: string;
  tagClass?: string;
}> = ({ workItem, noContainer, containerClass, tagClass }) => {
  const displayTags = useWorkItemDisplayTags({ item: workItem });
  return (
    <WorkLabelsDisplay
      displayTags={displayTags}
      noContainer={noContainer}
      containerClass={containerClass}
      tagClass={tagClass}
    />
  );
};

export interface DisplayTag {
  value: string;
  label: string;
}

interface IWorkLabelsDisplayProps {
  displayTags: DisplayTag[];
  noContainer?: boolean;
  containerClass?: string;
  tagClass?: string;
}

export function WorkLabelsDisplay({
  displayTags,
  noContainer,
  containerClass = "",
  tagClass = "",
}: IWorkLabelsDisplayProps) {
  const containerClassName = useOptionalClassName({
    baseStyle: styles.container,
    pairs: [
      {
        extraStyle: containerClass,
        withExtra: Boolean(containerClass),
      },
    ],
  });

  const tagClassName = useOptionalClassName({
    baseStyle: styles.tag,
    pairs: [
      {
        extraStyle: "truncateTag",
        withExtra: true,
      },
      {
        extraStyle: tagClass,
        withExtra: Boolean(tagClass),
      },
    ],
  });

  const Wrapper = ({ children }: React.PropsWithChildren<any>) =>
    noContainer ? (
      <React.Fragment>{children}</React.Fragment>
    ) : (
      <div className={containerClassName}>{children}</div>
    );

  return (
    <Wrapper>
      {displayTags.map((value: any) => (
        <Tag className={tagClassName} key={value.value}>
          {value.label}
        </Tag>
      ))}
    </Wrapper>
  );
}

export function useWorkItemDisplayTags({ item }: { item: IProjectObj }) {
  const { workDict } = useWork();
  const { documentsObj } = usePages();
  const [displayTags, setDisplayTags] = useState<DisplayTag[]>(
    getItemDisplayTags({ item, workDict, documentsObj })
  );

  useLayoutEffect(() => {
    if (item.tags?.length) {
      setDisplayTags(getItemDisplayTags({ item, workDict, documentsObj }));
    }
  }, [item.tags, workDict, documentsObj]);

  return displayTags;
}

function getItemDisplayTags({
  item,
  workDict,
  documentsObj,
}: {
  item: IProjectObj;
  workDict: ReturnType<typeof useWork>["workDict"];
  documentsObj: ReturnType<typeof usePages>["documentsObj"];
}) {
  return (item.tags ?? [])
    .map((tag: ITag) => {
      return tag.containerType === ContainerTypes.WORK
        ? getWorkTagDisplayTag({
            workTag: tag as ITag<ContainerTypes.WORK>,
            workDict,
          })
        : getDocumentTagDisplayTag({
            docTag: tag as ITag<ContainerTypes.DOCUMENT>,
            documentsObj,
          });
    })
    .filter(truthy);
}

function getWorkTagDisplayTag({
  workTag,
  workDict,
}: {
  workTag: ITag<ContainerTypes.WORK>;
  workDict: ReturnType<typeof useWork>["workDict"];
}): DisplayTag | undefined {
  const item = workDict[workTag.containerId];
  return !item
    ? undefined
    : {
        value: item.id,
        label: `#${item.projectId} ${stripHtml(getHtml(item.nameValue))}`,
      };
}

function getDocumentTagDisplayTag({
  docTag,
  documentsObj,
}: {
  docTag: ITag<ContainerTypes.DOCUMENT>;
  documentsObj: ReturnType<typeof usePages>["documentsObj"];
}): DisplayTag | undefined {
  const item = documentsObj[docTag.containerId];
  return !item
    ? undefined
    : {
        value: item.id,
        label: `#${stripHtml(getHtml(item.nameValue))}`,
      };
}

export default WorkLabels;
