import React, { useContext, useLayoutEffect, useState } from "react";
import { useShallowSelector } from "utilities/hooks";
import { CommandPaletteContext } from "utilities/types";
import OptionLister from "../components/OptionLister";
import {
  CommandPaletteOption,
  CommandPaletteState,
  contextMatch,
} from "../helpers/types";

const TurnIntoMode: React.FC = () => {
  const paletteContext = useShallowSelector(
    (store) => store.commandPalette.params
  );

  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const paletteStateContext = useContext(CommandPaletteState);

  useLayoutEffect(() => {
    const contextProps = contextMatch[CommandPaletteContext.TURN_INTO];
    const options = contextProps.getOptions();
    setOptions(options);
    paletteStateContext.topBarText.next(contextProps.topBarText);
  }, [paletteContext]);

  return <OptionLister options={options} />;
};

export default TurnIntoMode;
