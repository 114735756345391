import { web3Api } from "clientApi/web3Api";
import React, { useState } from "react";
import { batch } from "react-redux";
import { setAccessToken } from "utilities/authTokens";
import styles from "./connectWalletButton/styles.module.scss";
import Coinbase from "images/coinbase.svg";
import Web3Modal from "web3modal";
import Torus from "@toruslabs/torus-embed";
import Portis from "@portis/web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { WalletLink } from "walletlink";
import Logo from "images/siwe-ethereum-logo.svg";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import Button, { ButtonTypes } from "./Button";

const providerOptions = {
  /* See Provider Options Section */
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "283b0f72c9944c19a1c2a619fe69233e",
    },
  },
  portis: {
    package: Portis, // required
    options: {
      id: "4744a353-2725-44b8-bd8a-27aee6e9cdb2", // required
    },
  },
  torus: {
    package: Torus,
  },
  "custom-coinbase": {
    display: {
      logo: Coinbase,
      name: "Coinbase",
      description: "Scan with WalletLink to connect",
    },
    options: {
      appName: "Clarity", // Your app name
      networkUrl: `https://mainnet.infura.io/v3/283b0f72c9944c19a1c2a619fe69233e`,
      chainId: "1",
    },
    package: WalletLink,
    connector: async (
      _: any,
      options: { appName: any; networkUrl: any; chainId: any }
    ) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

export const ConnectWalletButton: React.FC<{
  message?: string;
  afterEffect?: () => void;
  email?: string;
  loadingState?: (loading: boolean) => void;
  beforeSettingUser?: (userId: string) => Promise<any>;
  roleId?: string;
  inviteToken?: string;
  userId?: string;
}> = ({
  message,
  afterEffect,
  email,
  inviteToken,
  loadingState,
  beforeSettingUser,
  roleId,
  userId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loginWithMetaMask = (web3Modal: Web3Modal) => {
    return web3Api
      .singupWithWallet({ email, inviteToken, roleId, userId })
      .then(async (data) => {
        if (beforeSettingUser) {
          await beforeSettingUser(data.userId);
        }
        batch(() => {
          setAccessToken(data.accessToken);
        });
        setIsLoading(false);
        if (loadingState) loadingState(false);
        if (afterEffect) {
          afterEffect();
        }
      })
      .catch(async (err) => {
        await web3Modal.clearCachedProvider();
        let message = err.message;
        if (message.includes("code 500")) {
          message = "Oops, something went wrong!";
        }
        setErrorMessage(message);
        setIsLoading(false);
        if (loadingState) loadingState(false);
      });
  };

  const handleLoginWithMetamask = async () => {
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });
    const callback = async (provider: any) => {
      web3Api.recreateConnection(provider).then(async () => {
        return loginWithMetaMask(web3Modal);
      });
    };
    let account = null;
    try {
      account = await web3Api.getAccount();
    } catch (err) {}

    if (account) {
      setIsLoading(true);
      if (loadingState) loadingState(true);
      try {
        loginWithMetaMask(web3Modal);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const elements = document.getElementsByClassName(
          "web3modal-modal-hitbox"
        );
        for (const element of Array.from(elements)) {
          const innercallback = (e: any) => {
            if (!e.target.closest(".web3modal-modal-card")) {
              web3Modal.toggleModal();
              element.removeEventListener("click", innercallback);
            }
          };
          element.addEventListener("click", innercallback);
        }
        const provider = await web3Modal.connect();
        callback(provider);
        setIsLoading(true);
        if (loadingState) loadingState(true);
      } catch {
        await web3Modal.clearCachedProvider();
      }
    }
  };

  const getMessage = () => {
    if (isLoading) {
      return "Waiting for signin approval...";
    } else {
      return message ?? "Connect wallet";
    }
  };

  return (
    <div>
      <Button
        buttonType={ButtonTypes.LARGE_PRIMARY}
        onClick={handleLoginWithMetamask}
        disabled={isLoading}
        style={{ width: "154px" }}
      >
        {!isLoading && (
          <img
            src={Logo}
            alt="Etherum Logo"
            className={styles.buttonIcon}
            // style={{
            //   marginLeft: "28px",
            //   marginRight: "17px",
            //   width: "19px",
            //   height: "31px",
            // }}
          />
        )}
        <span className={styles.textButtonLabel}>{getMessage()}</span>
      </Button>

      {errorMessage !== "" && (
        <ClarityLabel
          message={errorMessage}
          type={MessageLabelTypes.error}
          customStyles={{ marginTop: "12px", marginLeft: "16px" }}
        />
      )}
    </div>
  );
};
