export function swapItemFromIndexToIndex(
  collection: string[],
  currentIdx: number,
  newIdx: number
) {
  const coll = [...collection];
  const item = coll.splice(currentIdx, 1)[0];
  coll.splice(newIdx, 0, item);
  return [...coll];
}
