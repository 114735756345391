import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { Prompt } from "react-router-dom";
import taskStyles from "../task/task.module.scss";
import styles from "./activity/styles/taskWorkItems.module.scss";
import { Skeleton } from "antd";
import Button, { ButtonTypes } from "components/Button";
import DocumentContainer from "../../DocumentContainer";
import { ChunkDestination } from "utilities/stateTypes";
import {
  Abilities,
  ContainerTypes,
  DeltaType,
  IBlockContext,
  IWorkActivity,
  UserRole,
  ViewNames,
  WorkActivityTypes,
} from "utilities/types";
import { useSelector, shallowEqual, useStore, batch } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import {
  createWorkActivity,
  clearWorkActivity,
  updateWorkActivity,
} from "modules/taskService";

import {
  LOAD_TASK_ACTIVITIES,
  SET_BLOCKS_FROM_CONTAINER,
  SET_MENTION_OBJECTS,
  SET_NOTE_OBJECTS,
} from "store/actions";
import { getLabelBasedOnContainer } from "modules/containersService";
import { KeyCodes, LineValueType } from "utilities/lineUtilities";

import { handleNoteBlocksLoad } from "store/middlewares/valueUpdateChecker";
import {
  getBlockById,
  getCurrentContext,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import { setLocalSnapshot } from "editor/utils/specificActions/persistActions";
import { Block } from "store/reducers/blockReducer";
import { checkIfCannotAddBlocks } from "modules/appService";
import { useAbilityChecker } from "editor/utils/customHooks";
import { useShallowSelector } from "utilities/hooks";
import {
  getGroupedActivityFromWorkItemActivities,
  IGroupedActivity,
} from "./activity/activityHelpers";
import AuthorDisplay from "components/AuthorDisplay";
import ActivityContent from "./activity/components/ActivityContent";
import UserDisplay from "clarity-ui/UserDisplay";
import { axiosInstance } from "index";
import navigationApi from "clientApi/navigationApi";
import WorkItemActions from "./activity/components/WorkItemActions";
const { v4: uuidv4 } = require("uuid");

const getButtonClasses = (props: any) => {
  const reactiveStyling =
    !props.isEditingComment || props.disableSave
      ? taskStyles.commentCompose__footer__disabled
      : "";
  return taskStyles.commentCompose__footer__primary + " " + reactiveStyling;
};

const WorkActivityItems: React.FC<any> = (props) => {
  const state = useSelector((state: any) => {
    return {
      activeEditingTask: state.work.editingTask,
    };
  }, shallowEqual);

  const workItem = useSelector(
    (state: ClarityStore) =>
      props.workItem ? state.work.dict[props.workItem.id] : null,
    shallowEqual
  );

  const [canStartEdit, setcanStartEdit] = useState(true);
  const isOpened = useRef(true);
  const [isEditingComment, setisEditingComment] = useState(false);
  const [isCreatingNewComment, setisCreatingNewComment] = useState(false);
  const [groupedActivity, setgroupedActivity] = useState<IGroupedActivity[]>(
    []
  );

  useEffect(() => {
    if (!props.presave) {
      axiosInstance
        .get(`/api/workActivity/taskActivity/${props.workItem.id}`)
        .then((res) => {
          batch(() => {
            store.dispatch({
              type: LOAD_TASK_ACTIVITIES,
              workActivities: res.data,
              id: props.workItem.id,
            });

            res.data.forEach((item: any) => {
              if (item.blocksState) {
                store.dispatch({
                  type: SET_BLOCKS_FROM_CONTAINER,
                  param: {
                    rootLinesId: item.blocksState?.lineObject?.rootLinesId,
                    blocks: item.blocksState?.lineObject?.entities?.lines,
                    container: {
                      containerId: item.id,
                      containerType: ContainerTypes.WORK_ACTIVITY,
                    },
                  },
                });
              }
            });
          });
          return axiosInstance
            .get(`/api/project/mentionsToTask/${props.workItem.id}`)
            .then((resp) => {
              batch(() => {
                store.dispatch({
                  type: SET_MENTION_OBJECTS,
                  param: {
                    containers: resp.data.fullResponse,
                    containerId: props.workItem.id,
                    blocks: resp.data.fullResponse.blocks,
                  },
                });
                if (resp.data.notes && resp.data.notes.length > 0) {
                  handleNoteBlocksLoad({
                    type: SET_NOTE_OBJECTS,
                    params: {
                      notes: resp.data.notes,
                    },
                  });

                  store.dispatch({
                    type: SET_NOTE_OBJECTS,
                    params: {
                      notes: resp.data.notes,
                    },
                  });
                }
              });
            });
        });
    }
  }, []);

  useEffect(() => {
    isOpened.current = true;
    return () => {
      isOpened.current = false;
      clearWorkActivity();
    };
  }, []);

  useLayoutEffect(() => {
    if (
      (isEditingComment || isCreatingNewComment) &&
      !state.activeEditingTask
    ) {
      store.dispatch({
        type: "SET_ACTIVE_EDITING_TASK",
        id: props.workItem.id,
      });
    } else {
      if (!isEditingComment && !isCreatingNewComment) {
        store.dispatch({
          type: "SET_ACTIVE_EDITING_TASK",
          id: null,
        });
      }
    }
    return () => {
      if ((isEditingComment || isCreatingNewComment) && !isOpened.current) {
        store.dispatch({
          type: "SET_ACTIVE_EDITING_TASK",
          id: null,
        });
      }
    };
  }, [isEditingComment, isCreatingNewComment]);

  useEffect(() => {
    if (!state.activeEditingTask || state.activeEditingTask === workItem?.id) {
      setcanStartEdit(true);
    } else {
      setcanStartEdit(false);
    }
  }, [state.activeEditingTask]);

  useEffect(() => {
    const onUnload = (e: BeforeUnloadEvent) => {
      if (isEditingComment || isCreatingNewComment) {
        e.preventDefault();
        e.returnValue = "show warning";
      }
    };
    window.addEventListener("beforeunload", onUnload);
    return function remove() {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [isEditingComment, isCreatingNewComment]);

  useLayoutEffect(() => {
    if (workItem) {
      const groupedItems = getGroupedActivityFromWorkItemActivities(workItem);
      if (JSON.stringify(groupedItems) === JSON.stringify(groupedActivity))
        return;
      setgroupedActivity(groupedItems);
    }
  }, [workItem?.workActivities]);

  const user = useStore().getState().user;

  if (!workItem) return <></>;

  return (
    <>
      <Prompt
        when={isEditingComment || isCreatingNewComment}
        message={(location) =>
          `There is an unsaved comment, do you want to leave?`
        }
      />

      {groupedActivity?.map((group) => (
        <div className={styles.commentRow} key={group.activityIds[0]}>
          <AuthorDisplay
            authorId={group.authorId}
            creationDate={new Date(group.dateCreated)}
            editDate={
              group.dateModified ? new Date(group.dateModified) : undefined
            }
            shape="circle"
          />
          <div className={styles.activityGroup}>
            {group.activityIds?.map(
              (itemId: string, index: number, array: any[]) => (
                <SingleWorkActivity
                  key={itemId}
                  user={user}
                  paneId={props.paneId}
                  workItem={workItem}
                  canStartEdit={canStartEdit}
                  workActivityId={itemId}
                  isEditingComment={isEditingComment}
                  isCreatingNewComment={isCreatingNewComment}
                  setisEditingComment={setisEditingComment}
                  setisCreatingNewComment={setisCreatingNewComment}
                  isCreated={itemId === "created" ? true : false}
                  isFirstActiveEntry={false}
                  isLastActivityEntry={index === array.length - 1}
                />
              )
            )}
          </div>
        </div>
      ))}
      <WorkItemActions workItemId={props.workItem.id} />
      {(!isEditingComment || isCreatingNewComment) && canStartEdit && (
        <EditingWorkActivity
          isCreatingNewComment={isCreatingNewComment}
          user={user}
          paneId={props.paneId}
          presave={props.presave}
          autoFocus={props.isInModal}
          containerId={"newComment"}
          workItem={workItem}
          isEditingComment={isEditingComment}
          setisEditingComment={setisEditingComment}
          setisCreatingNewComment={setisCreatingNewComment}
        />
      )}
    </>
  );
};

const SingleWorkActivity: React.FC<any> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [workActivity, setWorkActivity] = useState<IWorkActivity>();
  const state = useSelector((state: any) => {
    return {
      workActivity: state.work.workActivities[props.workActivityId],
    };
  }, shallowEqual);

  useEffect(() => {
    if (props.isCreated) {
      const id = uuidv4();

      const author = store.getState().members.dict[props.workItem.authorId];

      const createdWorkActivity: IWorkActivity = {
        id,
        type: WorkActivityTypes.WORK_ITEM_CREATED,
        authorId: props.workItem?.authorId,
        dateCreated: props.workItem?.dateCreated,
        dateModified: props.workItem.dateCreated,
        taskId: props.workItem.id,
        delta: {
          type: DeltaType.CREATED,
          metadata: {
            authorId: props.workItem.authorId,
            workType: props.workItem.workType,
          },
        },
        blocksState: [],
        isDeleted: false,
        author,
      };

      setWorkActivity(createdWorkActivity);
    } else {
      if (state.workActivity) {
        setWorkActivity(state.workActivity);
      }
    }
  }, [state.workActivity, props.isCreated]);

  const [commentIsBeingEdited, setcommentIsBeingEdited] = useState(false);

  return useMemo(() => {
    if (workActivity && workActivity.author) {
      if (workActivity.type === "Mention")
        return (
          <div ref={ref} className={`${styles.activityContainer}`}>
            <MentionWorkActivity
              workActivity={workActivity}
              workId={props.workItem.id}
              workItem={props.workItem}
              user={props.user}
              isFirstActiveEntry={props.isFirstActiveEntry}
              isLastActivityEntry={props.isLastActivityEntry}
            />
          </div>
        );

      return (
        <>
          <div
            className={`${styles.activityContainer}`}
            ref={ref}
            onClick={(e: any) => {
              if (
                e.target.nodeName === LineValueType.mention &&
                e.target.classList.contains("hashtag")
              ) {
                const workTagCheck = e.target.getAttribute("work-tag");
                const entityId = e.target.getAttribute("href");

                const containerType =
                  workTagCheck === "true"
                    ? ContainerTypes.PROJECT
                    : ContainerTypes.DOCUMENT;

                navigationApi.contextBasedNavigate({
                  currentPane: props.paneId,
                  shiftKey: e.shiftKey,
                  navigationChunk: {
                    viewName: ViewNames.Detail,
                    entity: {
                      containerId: entityId,
                      containerType: containerType,
                    },
                  },
                });
              }
              if (e.target.nodeName === LineValueType.a) {
                e.stopPropagation();
              }
            }}
          >
            <ActivityContent
              type={WorkActivityTypes.COMMENT}
              workActivity={workActivity}
              user={props.user}
              showBody={true}
              workItem={props.workItem}
              setcommentIsBeingEdited={setcommentIsBeingEdited}
              setisEditingComment={props.setisEditingComment}
              isEditingComment={props.isEditingComment}
              isCreatingNewComment={props.isCreatingNewComment}
              canStartEdit={props.canStartEdit}
              isLastActivityEntry={props.isLastActivityEntry}
              isFirstActiveEntry={props.isFirstActiveEntry}
            >
              {commentIsBeingEdited &&
              !(
                workActivity.type === WorkActivityTypes.METADATA_CHANGE ||
                workActivity.type === WorkActivityTypes.WORK_ITEM_CREATED
              ) ? (
                <EditingWorkActivity
                  isCreatingNewComment={props.isCreatingNewComment}
                  containerId={state.workActivity.id}
                  workActivity={state.workActivity}
                  workItem={props.workItem}
                  setisCreatingNewComment={props.setisCreatingNewComment}
                  user={props.user}
                  isEditingComment={props.isEditingComment}
                  setisEditingComment={props.setisEditingComment}
                  commentIsBeingEdited={commentIsBeingEdited}
                  setcommentIsBeingEdited={setcommentIsBeingEdited}
                />
              ) : (
                <ReadOnlyWorkActivity
                  containerId={workActivity.id}
                  workActivity={workActivity}
                  user={props.user}
                  workItem={props.workItem}
                  blocksState={workActivity.blocksState}
                />
              )}
            </ActivityContent>
          </div>
        </>
      );
    }
    return <></>;
  }, [
    commentIsBeingEdited,
    props.isEditingComment,
    props.canStartEdit,
    props.isFirstActiveEntry,
    props.isLastActivityEntry,
    props.paneId,
    workActivity,
  ]);
};

const MentionWorkActivity: React.FC<any> = (props) => {
  const state = useShallowSelector((state) => {
    const containerId =
      state.mentions.mentionIdToContainerIndex[props.workActivity.mentionId];
    return {
      mentions: state.mentions.dict[props.workId],
      mention: state.mentions.dict[props.workId]?.containers?.[containerId],
    };
  });

  const block = useShallowSelector((store) =>
    state.mention?.blocks[0]
      ? store.blocks.dict[state.mention.blocks[0]]
      : undefined
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showBody, setshowBody] = useState(false);

  const [linkingContainer, setlinkingContainer] = useState({
    containerType: state.mention?.containerType,
    containerId: state.mention?.containerId,
  });

  const [containerLinks, setcontainerLinks] = useState<any>({});

  useEffect(() => {
    setlinkingContainer({
      containerType: state.mention?.containerType,
      containerId: state.mention?.containerId,
    });
  }, [state.mention]);

  useEffect(() => {
    setcontainerLinks(
      getLabelBasedOnContainer(
        linkingContainer.containerType,
        linkingContainer.containerId
      )
    );
  }, [linkingContainer]);

  useEffect(() => {
    if (!block) return;

    if (state.mention.containerType === ContainerTypes.WORK_ACTIVITY) {
      axiosInstance
        .get(`/api/workActivity/singleActivity/${state.mention.containerId}`)
        .then((res) => {
          setlinkingContainer({
            ...linkingContainer,
            containerId: res.data.taskId,
            containerType: ContainerTypes.PROJECT,
          });
        });
    }
  }, [state.mention]);

  if (!state.mentions && state.mention)
    return (
      <ActivityContent
        type={WorkActivityTypes.MENTION}
        workActivity={props.workActivity}
        showBody={showBody}
        user={props.user}
        workItem={props.workItem}
        isLastActivityEntry={props.isLastActivityEntry}
      >
        <Skeleton
          active={true}
          title={false}
          paragraph={{ rows: 1 }}
          loading={true}
        />
      </ActivityContent>
    );

  if (!state.mention)
    return (
      <ActivityContent
        type={WorkActivityTypes.MENTION}
        workActivity={props.workActivity}
        user={props.user}
        showBody={showBody}
        workItem={props.workItem}
        mentionDeleted
        isLastActivityEntry={props.isLastActivityEntry}
      >
        <div
          style={{ height: "10px", width: "100%", background: "transparent" }}
        ></div>
      </ActivityContent>
    );

  return (
    <>
      <ActivityContent
        type={WorkActivityTypes.MENTION}
        workActivity={props.workActivity}
        user={props.user}
        workItem={props.workItem}
        showBody={showBody}
        mentionContainerLabel={containerLinks.label}
        linkingContainer={linkingContainer}
        isLastActivityEntry={props.isLastActivityEntry}
      >
        <></>
      </ActivityContent>
    </>
  );
};

const ReadOnlyWorkActivity: React.FC<any> = (props) => {
  const isSameUser = props.workActivity?.author?.id === props.user.id;
  const userRole = store.getState().client.roleType;
  const isOwner = userRole === UserRole.OWNER;

  const contextPresets: Partial<IBlockContext> = {
    autosave: false,
    canComment: false,
    canEdit: false,
    canChangeCheckbox: isSameUser || isOwner,
  };
  return (
    <div
      className={styles.TaskWorkItems__taskCommentBody + " commentContainer"}
    >
      <div className="document-view ">
        <DocumentContainer
          contextPresets={contextPresets}
          paneId={ChunkDestination.taskComments}
          key={props.containerId}
          containerType={ContainerTypes.WORK_ACTIVITY}
          containerId={props.containerId}
          workItem={props.workItem}
        />
      </div>
    </div>
  );
};

const EditingWorkActivity: React.FC<any> = (props) => {
  const [disableSave, setdisableSave] = useState(false);
  const ref = useRef<any>(null);
  const docViewRef = useRef<any>(null);

  const canAddComment = useAbilityChecker({
    abilityName: Abilities.CAN_ADD_TASK_COMMENT,
  });

  useEffect(() => {
    // return;
    if (!ref.current) return;
    // Select the node that will be observed for mutations
    const targetNode = ref.current;
    // Options for the observer (which mutations to observe)
    const config = {
      subtree: true,
      characterData: true,
      childList: true,
    };

    // Callback function to execute when mutations are observed
    const callback = (mutationsList: MutationRecord[], observer: any) => {
      batch(() => {
        const target = checkIfElementHasTextOrImage(targetNode);
        if (props.containerId === "newComment") {
          props.setisEditingComment(target.editing);
          props.setisCreatingNewComment(target.editing);
        }
        setdisableSave(!target.canSave);
      });
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    if (targetNode) observer.observe(targetNode, config);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    if (props.containerId !== "newComment") {
      const storeData = store.getState().blocks;
      const contextId = ContainerTypes.WORK_ACTIVITY + props.containerId;
      const context = getCurrentContext(storeData, contextId);
      const rootBlocksIds = context.state.rootBlocksIds;
      const blockDict: { [id: string]: Block } = {};

      const getBlockDict = (block: Block) => {
        if (block.children && block.children.length > 0) {
          block.children.forEach((childId) => {
            const child = getBlockById(storeData.dict, childId);
            if (child.id) blockDict[child.id] = block;
            getBlockDict(child);
          });
        }
      };

      rootBlocksIds.forEach((blockId) => {
        const block = getBlockById(storeData.dict, blockId);
        if (block.id) blockDict[block.id] = block;
        getBlockDict(block);
      });

      setLocalSnapshot({
        contextId,
        rootBlocksIds,
        innerDict: blockDict,
      });
    }
  }, []);

  return (
    <div
      className={`${taskStyles.commentComposeContainer} compose`}
      style={
        (checkIfCannotAddBlocks({ skipModal: true }) || !canAddComment) &&
        props.containerId === "newComment"
          ? { opacity: 0.5 }
          : {}
      }
    >
      {props.containerId === "newComment" && (
        <div className={taskStyles.commentComposeContainer__pre}>
          <UserDisplay avatarSize={40} hideName={true} id={props.user.id} />
        </div>
      )}
      <div
        className={`${taskStyles.TaskCommentCompose} ${
          !props.isEditingComment &&
          props.containerId === "newComment" &&
          taskStyles.TaskCommentCompose__empty
        }`}
      >
        <div
          className={taskStyles.TaskCommentCompose__body + " commentContainer"}
          ref={ref}
        >
          <div
            className="document-view"
            ref={docViewRef}
            onKeyDown={(e) => {
              if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                if (disableSave) return;
                if (props.presave) return;
                if (props.containerId === "newComment") {
                  createWorkActivity(
                    { type: WorkActivityTypes.COMMENT },
                    props.workItem,
                    false
                  );
                } else {
                  updateWorkActivity(
                    {
                      type: WorkActivityTypes.COMMENT,
                      workActivity: props.workActivity,
                    },
                    props.workItem
                  );
                }
                setTimeout(() => {
                  batch(() => {
                    props.setisCreatingNewComment(false);
                    props.setisEditingComment(false);
                    if (props.containerId !== "newComment")
                      props.setcommentIsBeingEdited(false);
                  });
                }, 0);
                return;
              }
              if (e.keyCode === KeyCodes.esc) {
                if (props.isEditingComment) e.stopPropagation();
              }
            }}
          >
            <DocumentContainer
              paneId={ChunkDestination.taskComments}
              key={props.containerId}
              containerType={ContainerTypes.WORK_ACTIVITY}
              containerId={props.containerId}
              workItem={props.workItem}
            />
          </div>
        </div>
        {props.isEditingComment && !props.presave && (
          <div className={taskStyles.TaskCommentCompose__footer}>
            {props.containerId === "newComment" ? (
              <NewCommentFooter
                {...props}
                refEdit={ref}
                disableSave={disableSave}
              />
            ) : (
              <EditingCommentFooter
                {...props}
                refEdit={ref}
                disableSave={disableSave}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const NewCommentFooter: React.FC<any> = (props) => {
  return (
    <>
      <Button
        buttonType={ButtonTypes.LINK}
        onClick={() => {
          clearWorkActivity();
          setTimeout(() => {
            batch(() => {
              props.setisCreatingNewComment(false);
              props.setisEditingComment(false);
            });
          }, 0);
        }}
      >
        Discard
      </Button>
      <Button
        style={{ marginLeft: "8px" }}
        buttonType={ButtonTypes.PRIMARY}
        disabled={!props.isCreatingNewComment || props.disableSave}
        onClick={() => {
          if (!props.isCreatingNewComment || props.disableSave) return;
          createWorkActivity(
            { type: WorkActivityTypes.COMMENT },
            props.workItem,
            false
          );

          setTimeout(() => {
            batch(() => {
              props.setisCreatingNewComment(false);
              props.setisEditingComment(false);
            });
          }, 0);
        }}
        className={getButtonClasses(props)}
      >
        Comment
      </Button>
    </>
  );
};

const EditingCommentFooter: React.FC<any> = (props) => {
  return (
    <>
      {props.isEditingComment && (
        <Button
          buttonType={ButtonTypes.LINK}
          onClick={() => {
            clearWorkActivity(props.workActivity);
            batch(() => {
              props.setisCreatingNewComment(false);
              props.setisEditingComment(false);
              props.setcommentIsBeingEdited(false);
            });
          }}
        >
          Cancel
        </Button>
      )}
      <Button
        style={{ marginLeft: "8px" }}
        buttonType={ButtonTypes.PRIMARY}
        disabled={props.disableSave}
        onClick={() => {
          if (props.disableSave) return;
          updateWorkActivity(
            {
              type: WorkActivityTypes.COMMENT,
              workActivity: props.workActivity,
            },
            props.workItem
          );
          batch(() => {
            props.setisCreatingNewComment(false);
            props.setisEditingComment(false);
            props.setcommentIsBeingEdited(false);
          });
        }}
        className={getButtonClasses(props)}
      >
        Save
      </Button>
    </>
  );
};

const checkIfElementHasTextOrImage = (
  element: HTMLDivElement
): { editing: boolean; canSave: boolean } => {
  if (!element)
    return {
      editing: false,
      canSave: false,
    };
  if (element.innerText.trim().length === 0) {
    let multipleBlocks = false;
    let canSave = false;
    const blocks = element.getElementsByClassName("Block");
    if (blocks.length > 1) multipleBlocks = true;

    const images = element.getElementsByClassName(
      "blockComponent_imageBlock__z9WK7"
    );
    if (images.length > 0) canSave = true;
    return {
      editing: canSave || multipleBlocks,
      canSave,
    };
  } else {
    return {
      editing: true,
      canSave: true,
    };
  }
};

export default WorkActivityItems;
