import {
  START_DISCUSSION_FOR_SELECTION,
  CONFIRM_DISCUSSION_STARTED,
} from "../actions";
import { ContainerTypes } from "../../utilities/types";

const initialState = {} as any;

interface ISelectionActionParameters {
  type: string;
  discussionType: any;
  discussionData: {
    containerId: string;
    containerType: ContainerTypes;
    blockId: string;
    documentRank: string;
  };
}

export default function selectionReducer(
  state = initialState,
  action: ISelectionActionParameters
) {
  switch (action.type) {
    case START_DISCUSSION_FOR_SELECTION: {
      const newState = { ...state };
      newState.startDiscussion = true;
      newState.discussionType = action.discussionType;
      newState.discussionData = action.discussionData;
      return newState;
    }
    case CONFIRM_DISCUSSION_STARTED: {
      const newState = {};
      return newState;
    }
    default:
      return state;
  }
}
