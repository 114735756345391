import React, { useRef, useLayoutEffect } from "react";
import styles from "./inboxItem/inboxItem.module.scss";
import Button, { ButtonTypes } from "components/Button";
import { CheckOutlined } from "@ant-design/icons";
import {
  markThreadAsDone,
  markThreadAsNotDone,
  readUserThreads,
} from "modules/notificationsService";
import { locationSubject } from "components/LocationListener";
import { prevState } from "store/storeExporter";
import { ContainerTypes, IThreadView, ViewNames } from "utilities/types";
import { getContainerLink } from "modules/containersService";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import { getFullTime } from "components/TimePassed";
import { flashBlock, flashBlockSubject } from "App";
import navigationApi from "clientApi/navigationApi";

export interface IInboxItem {
  id: string;
  mode: "inbox" | "done";
}

export default function InboxItem(props: IInboxItem) {
  const { id, mode } = props;

  const previewRef = useRef<HTMLSpanElement | undefined>();

  const inboxItem: IThreadView = useSelector(
    (state: any) => state.inAppNotifications.dict[id],
    shallowEqual
  );

  const preview = inboxItem.threadValue;

  useElidedPreview(previewRef, preview);

  if (!inboxItem) return <></>;

  const title = getTitle(
    inboxItem.participantIds ? inboxItem.participantIds : [],
    inboxItem.reason
  );

  const isDate = new Date(inboxItem.threadName)?.getTime();

  let subtitle =
    inboxItem.containerType === ContainerTypes.NOTE && isDate ? (
      <>Doc from {getFullTime(moment(inboxItem.threadName))}</>
    ) : (
      inboxItem.threadName
    );

  const onCheckClick = (ev: any) => {
    ev.stopPropagation();
    if (mode === "inbox") {
      markThreadAsDone(inboxItem.id);
    } else if (mode === "done") {
      markThreadAsNotDone(inboxItem.id);
    }
  };

  const onItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // after using IThreadView for the whole element
    if (e.shiftKey) {
      navigationApi.openSplitView({
        viewName: ViewNames.Detail,
        containerId: inboxItem.containerId,
        containerType:
          inboxItem.containerType !== "Task"
            ? inboxItem.containerType
            : ContainerTypes.WORK,
      });
    } else {
      const correctLink = getContainerLink(
        inboxItem.containerId,
        inboxItem.containerType
      );
      if (correctLink) locationSubject.next(correctLink);
      if (inboxItem.referenceId?.includes("COMMENT")) {
        let blockId = inboxItem.referenceId;
        blockId = blockId.replace("COMMENT", "");
        flashBlockSubject.next({
          id: blockId,
          openComments: true,
          isDiscussion: true,
          containerId: inboxItem.containerId,
        });
      }
      if (inboxItem.referenceId?.includes("Block")) {
        let blockId = inboxItem.referenceId;
        blockId = blockId.replace("Block", "");
        flashBlock.id = blockId;
        flashBlockSubject.next({
          id: blockId,
          containerId: inboxItem.containerId,
        });
      }
    }
    readUserThreads([inboxItem.id]);
    e.preventDefault();
    e.stopPropagation();
  };

  if (!inboxItem.id) return <></>;

  return (
    <div
      className={`${styles.item} ${inboxItem.done ? styles.done : ""}`}
      onClick={onItemClick}
    >
      <div className={styles.item__textBox}>
        <span className={styles.item__title}>{title}</span>
        <span className={styles.item__subtitle}>{subtitle}</span>
        <span ref={previewRef as any} className={styles.item__preview}></span>
      </div>
      <div className={styles.item__topRight}>
        {!inboxItem.read && inboxItem.mentionCount === 0 && (
          <div className={styles.badge} />
        )}
        {!inboxItem.read && inboxItem.mentionCount > 0 && (
          <div className={styles.mentionsBadge}>
            <span>{inboxItem.mentionCount}</span>
          </div>
        )}
        <Button
          onClick={onCheckClick}
          className={styles.doneButton}
          buttonType={ButtonTypes.LINK}
          icon={<CheckOutlined />}
        />
      </div>
    </div>
  );
}

function getTitle(participants: string[], reason: string) {
  const getName = (id: string) => {
    const user = prevState.value.members.dict[id];
    return user ? user.name || `@${user.username}` : "";
  };

  const filteredParetcipants = participants.filter(
    (userId) => userId !== prevState.value.user?.id
  );

  if (filteredParetcipants.length === 0) {
    return getName(prevState.value.user ? prevState.value.user.id : "");
  }
  if (filteredParetcipants.length === 1) {
    return `${getName(filteredParetcipants[0])}`;
    // return `Mentioned by ${getName(filteredParetcipants[0])}`;
  } else if (filteredParetcipants.length === 2) {
    const first = getName(filteredParetcipants[0]);
    const second = getName(filteredParetcipants[1]);
    return `${first} & ${second}`;
  } else {
    const names = filteredParetcipants.map(getName);
    return `${names.slice(0, names.length - 1).join(", ")} & ${
      names[names.length - 1]
    }`;
  }
}

function useElidedPreview(
  previewRef: React.MutableRefObject<HTMLSpanElement | undefined>,
  preview: string
) {
  useLayoutEffect(() => {
    if (previewRef?.current) {
      const temp = document.createElement("span");
      temp.innerHTML = preview;
      previewRef.current.innerHTML = "";
      (previewRef.current as any).appendChild(
        temp.childNodes && temp.childNodes[0] ? temp.childNodes[0] : temp
      );
    }
  }, [previewRef, preview]);
}
