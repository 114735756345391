import DueDateDisplay from "clarity-ui/DueDateDisplay";
import UserDisplay from "clarity-ui/UserDisplay";
import WorkProgress from "clarity-ui/WorkProgress";
import React from "react";
import { useShallowSelector } from "utilities/hooks";
import ItemDetails from "./projectCard/ItemDetails";
import ItemStatus from "./projectCard/ItemStatus";
import styles from "./projectCard/projectCard.module.scss";

const ProjectCard: React.FC<{
  itemId: string;
  large?: boolean;
  handleClick: (id: string) => void;
}> = ({ itemId, large, handleClick }) => {
  const project = useShallowSelector((store) => store.work.dict[itemId]);

  if (!project) return <></>;

  return (
    <div
      className={`${styles.card} ${large ? styles.large : ""}`}
      onClick={() => handleClick(project.id)}
    >
      <div className={styles.head}>
        <ItemDetails workItem={project} large={large} />
        <UserDisplay
          disabled={project.isClosed}
          hideName={true}
          avatarSize={large ? 26 : 21}
          id={project.assigneeId}
        />
      </div>
      <div className={styles.body}>
        <ItemStatus
          workItem={project}
          customStyles={{
            background: "$surface-3",
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: "1px",
            paddingBottom: 0,
          }}
          canEditEntity={true}
        />
        {project.dueDate && (
          <DueDateDisplay
            dueDate={project.dueDate}
            isClosed={project.isClosed}
            editId={project.id}
          />
        )}
      </div>
      <div className={styles.footer}>
        <WorkProgress
          childrenAggregate={project.childrenAggregate}
          type={"minimal"}
          completed={project.isClosed}
        />
      </div>
    </div>
  );
};

export default ProjectCard;
