import Conditional from "components/Conditional";
import { breakDownHtml, getHtml } from "editor/utils/blockValueHelpers";
import { primitiveUpdateBlockText } from "editor/utils/primitiveActions/primitiveActions";
import { setUpdateObjectToStore } from "editor/utils/specificActions/persistActions";
import {
  ActionObject,
  createActionWrapperObject,
} from "editor/utils/specificActions/undoUtils";
import {
  VALUE_B,
  VALUE_BACK_SPACE,
  VALUE_CANCEL,
  VALUE_DELETE,
  VALUE_DOWN,
  VALUE_E,
  VALUE_ENTER,
  VALUE_ESCAPE,
  VALUE_I,
  VALUE_K,
  VALUE_LEFT,
  VALUE_RIGHT,
  VALUE_TAB,
  VALUE_UP,
  VALUE_X,
  VALUE_Y,
  VALUE_Z,
} from "keycode-js";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SET_UNDO } from "store/actions";
import store, { $documentMode, $focusOn } from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ILineValue, LineValueType } from "utilities/lineUtilities";
import { DocumentModes, IBlockContext } from "utilities/types";
import {
  ICellCache,
  tableDndObserver,
  tableListeners,
  tableResizeObserver,
} from "../TableBlock";
import { ReactComponent as SixDotHandle } from "icons/six-dot-handle-autofill.svg";
import {
  checkCaretPosition,
  getSelectionTextInfo,
} from "editor/utils/caretUtils";
import { FocusType } from "store/reducers/blockReducer";
import { DebouncedFunc, throttle } from "lodash";
import {
  addColumn,
  addRow,
  clearContent,
  dragAndDropStartHandler,
  moveColumn,
  moveRow,
  pasteHandler,
  removeColumn,
  removeRow,
  resizeHandler,
  toggleHeader,
} from "./action";
import { Dropdown, Switch } from "antd";
import { Menu, MenuItem } from "components/FabBtn";
import { redoAction, undoAction } from "editor/utils/blockActions";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { handleSoftReturn } from "editor/utils/specificActions/addBlockActions";
import {
  handleCreateLink,
  handleInlineCodeStyling,
} from "editor/utils/specificActions/textUpdateActions";
import { handleBlockNativeStylingInnerAction } from "editor/utils/specificActions/textUpdateUtils";
import { batch } from "react-redux";
import styles from "../styles/TableRow.module.scss";

let focusFromHandle = false;

const consumeFocusFromHandle = () => {
  if (focusFromHandle) {
    focusFromHandle = false;
    return true;
  }
  return false;
};

const TableRow: React.FC<{
  blockId: string;
  isFirstRow: boolean;
  context: IBlockContext;
  tableId: string;
  rowIndex: number;
  cellCache: React.MutableRefObject<ICellCache>;
  tableColumnData?: any;
  moveInCells: React.MutableRefObject<
    (params: {
      currentRow: string;
      currentColumn: string;
      moveType: "up" | "down" | "right" | "left";
      e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent;
    }) => void
  >;
}> = ({
  blockId,
  isFirstRow,
  tableColumnData,
  context,
  tableId,
  cellCache,
  moveInCells,
  rowIndex,
}) => {
  const block = useShallowSelector((store) => store.blocks.dict[blockId]);

  const indexDict = useRef<{ [id: string]: number }>({});
  const firstRender = useRef(true);
  const getCells = () => {
    const newCells: ILineValue[] = [];
    indexDict.current = {};
    tableColumnData?.order?.forEach((id: string, index: number) => {
      indexDict.current[id] = index;
      newCells.push({
        type: LineValueType.text,
        value: "",
        children: [],
        nodeId: id,
      });
    });

    block.value.forEach((chunk: ILineValue) => {
      if (chunk?.nodeId) {
        const index = indexDict.current[chunk.nodeId];
        newCells[index] = chunk;
      }
    });
    return newCells;
  };

  const [cells, setcells] = useState<ILineValue[]>(getCells());
  const cellsRef = useRef(cells);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const newCells = getCells();
      setcells(newCells);
    }
  }, [block.value, tableColumnData]);

  useEffect(() => {
    cellsRef.current = cells;
  }, [cells]);

  const handleUpdateInTableCell = useRef(
    throttle(
      (
        index: number,
        ref: React.MutableRefObject<HTMLDivElement | null>,
        nodeId?: string
      ): void => {
        if (!ref.current || !context.canEdit) return;
        const caretPosition = checkCaretPosition(ref.current);
        let newValue: ILineValue;
        const contentValue: ILineValue[] = [];
        for (const child of Array.from(ref.current.childNodes)) {
          contentValue.push(breakDownHtml(child));
        }

        if (contentValue.length === 0 && indexDict.current[index]) {
          contentValue[0].nodeId = nodeId;
          newValue = contentValue[0];
        } else {
          newValue = {
            type: LineValueType.childrenText,
            value: "",
            children: contentValue,
            nodeId,
          };
        }
        const updatedCells = [...cellsRef.current];
        updatedCells[index] = newValue;
        const fullBlockValue = updatedCells;
        const saveActions: ActionObject[] = [];

        const undoObject = createActionWrapperObject(context);
        primitiveUpdateBlockText({
          blockId,
          newBlockText: fullBlockValue,
          context,
          saveActions,
          undoObject,
        });
        store.dispatch({
          type: SET_UNDO,
          param: {
            undoObject,
            contextId: context.id,
          },
        });
        setUpdateObjectToStore(saveActions, context);
        if (
          $focusOn.value.focusTableId === tableId &&
          $focusOn.value.focusNodeId === nodeId &&
          $focusOn.value.focusBlockId === blockId
        ) {
          $focusOn.next({
            ...$focusOn.value,
            caretPosition,
            focusBlockId: block.id,
            focusContext: context,
            focusPane: context.paneId,
            type: FocusType.prevPosition,
            refocusToggle: false,
            focusBlockRef: ref,
            focusTableId: tableId,
            focusNodeId: nodeId,
          });
        }
      },
      600,
      { trailing: true, leading: false }
    )
  );

  return (
    <tr className="tableRow">
      {cells.map((cell, index) => (
        <TableCell
          key={cell.nodeId ?? index}
          cell={cell}
          manualSize={
            cell.nodeId
              ? tableColumnData?.dict?.[cell.nodeId]?.manualSize
              : undefined
          }
          hasHeaderBackground={
            (tableColumnData?.hasHeaderColumn && index === 0) ||
            (tableColumnData?.hasHeaderRow && rowIndex === 0)
          }
          columnId={cell.nodeId ? cell.nodeId : ""}
          isFirstRow={isFirstRow}
          tableId={tableId}
          cellCache={cellCache}
          handleUpdateInTableCell={handleUpdateInTableCell}
          moveInCells={moveInCells}
          columnIndex={index}
          rowIndex={rowIndex}
          rowId={blockId}
          context={context}
        />
      ))}
    </tr>
  );
};

const TableCell: React.FC<{
  cell: ILineValue;
  rowId: string;
  columnId: string;
  columnIndex: number;
  rowIndex: number;
  tableId: string;
  cellCache: React.MutableRefObject<ICellCache>;
  manualSize?: number;
  isFirstRow: boolean;
  context: IBlockContext;
  hasHeaderBackground: boolean;
  handleUpdateInTableCell: React.MutableRefObject<
    DebouncedFunc<
      (
        index: number,
        ref: React.MutableRefObject<HTMLDivElement | null>,
        nodeId?: string
      ) => void
    >
  >;
  moveInCells: React.MutableRefObject<
    (params: {
      currentRow: string;
      currentColumn: string;
      moveType: "up" | "down" | "right" | "left";
      e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent;
    }) => void
  >;
}> = ({
  cell,
  rowId,
  columnId,
  handleUpdateInTableCell,
  columnIndex,
  rowIndex,
  tableId,
  cellCache,
  context,
  moveInCells,
  manualSize,
  hasHeaderBackground,
}) => {
  const ref = useRef<HTMLTableCellElement | null>(null);
  const editbaleRef = useRef<HTMLDivElement | null>(null);
  const [isDragged, setisDragged] = useState(false);

  useEffect(() => {
    if (ref.current) cellCache.current[rowId + columnId] = ref.current;
    return () => {
      delete cellCache.current[rowId + columnId];
    };
  }, []);

  useEffect(() => {
    const sub = tableDndObserver.subscribe((tableData) => {
      if (tableData.tableId === tableId) {
        if (
          tableData.dragColumnId === columnId ||
          tableData.dragRowId === rowId
        )
          return setisDragged(true);
      }
      setisDragged(false);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [columnId, rowId]);

  return (
    <td
      ref={ref}
      className={`tableCell ${hasHeaderBackground ? "headerBackground" : ""}`}
      style={
        manualSize
          ? {
              minWidth: `${manualSize}px`,
              maxWidth: `${manualSize}px`,
              opacity: isDragged ? 0 : 1,
            }
          : { opacity: isDragged ? 0 : 1 }
      }
      tabIndex={-1}
      onMouseEnter={() =>
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: columnId,
          hoverRowId: rowId,
          hoverTableId: tableId,
        })
      }
      onMouseLeave={() =>
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: "",
          hoverRowId: "",
          hoverTableId: "",
        })
      }
      onFocus={(e) => {
        const hasConsumable = consumeFocusFromHandle();
        if (hasConsumable) return;
        batch(() => {
          tableListeners.next({
            ...tableListeners.value,
            focusColumnId: columnId,
            focusRowId: rowId,
            focusTableId: tableId,
          });
          if (tableListeners.value.cellMode === "insert") {
            $focusOn.next({
              caretPosition: 0,
              focusBlockId: rowId,
              focusContext: context,
              focusPane: context.paneId,
              type: FocusType.atBlockEnd,
              refocusToggle: false,
              focusBlockRef: ref,
              focusTableId: tableId,
              focusNodeId: columnId,
            });
            return;
          }
        });
      }}
      onKeyDown={(e) => {
        switch (e.key) {
          case VALUE_TAB: {
            e.preventDefault();
            e.stopPropagation();
            if (e.shiftKey)
              return moveInCells.current({
                currentColumn: columnId,
                currentRow: rowId,
                moveType: "left",
                e,
              });
            return moveInCells.current({
              currentColumn: columnId,
              currentRow: rowId,
              moveType: "right",
              e,
            });
          }
          case VALUE_UP: {
            e.preventDefault();
            e.stopPropagation();
            return moveInCells.current({
              currentColumn: columnId,
              currentRow: rowId,
              moveType: "up",
              e,
            });
          }
          case VALUE_DOWN: {
            e.preventDefault();
            e.stopPropagation();
            return moveInCells.current({
              currentColumn: columnId,
              currentRow: rowId,
              moveType: "down",
              e,
            });
          }
          case VALUE_LEFT: {
            e.preventDefault();
            e.stopPropagation();
            return moveInCells.current({
              currentColumn: columnId,
              currentRow: rowId,
              moveType: "left",
              e,
            });
          }
          case VALUE_RIGHT: {
            e.preventDefault();
            e.stopPropagation();
            return moveInCells.current({
              currentColumn: columnId,
              currentRow: rowId,
              moveType: "right",
              e,
            });
          }
          case VALUE_ENTER: {
            e.preventDefault();
            e.stopPropagation();
            if (tableListeners.value.cellMode === "block") {
              tableListeners.next({
                ...tableListeners.value,
                cellMode: "insert",
                focusColumnId: columnId,
                focusRowId: rowId,
                focusTableId: tableId,
              });
              $focusOn.next({
                ...$focusOn.value,
                type: FocusType.atBlockEnd,
              });
              return;
            }
            if (e.shiftKey) {
              return moveInCells.current({
                currentColumn: columnId,
                currentRow: rowId,
                moveType: "up",
                e,
              });
            }
            return moveInCells.current({
              currentColumn: columnId,
              currentRow: rowId,
              moveType: "down",
              e,
            });
          }
          case VALUE_Z:
          case VALUE_Z.toUpperCase(): {
            if (!context.canEdit) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }
            if (e.metaKey || e.ctrlKey) {
              e.preventDefault();
              e.stopPropagation();
              if (e.shiftKey) {
                redoAction(context);
                break;
              }
              undoAction(context);
            }
            break;
          }
          case VALUE_CANCEL:
          case VALUE_ESCAPE: {
            break;
          }
          case VALUE_BACK_SPACE:
          case VALUE_DELETE: {
            if (tableListeners.value.cellMode === "block") {
              if (editbaleRef.current && editbaleRef.current.innerHTML !== "") {
                editbaleRef.current.innerHTML = "";
                handleUpdateInTableCell.current(
                  columnIndex,
                  editbaleRef,
                  columnId
                );
                handleUpdateInTableCell.current.flush();
              }
            }
            break;
          }
        }
      }}
    >
      <Conditional on={context.canEdit}>
        <Conditional on={rowIndex === 0}>
          <ColumnCTA
            tableId={tableId}
            columnId={columnId}
            columnIndex={columnIndex}
            context={context}
          />
        </Conditional>
        <Conditional on={columnIndex === 0}>
          <RowCTA
            tableId={tableId}
            rowId={rowId}
            rowIndex={rowIndex}
            context={context}
          />
        </Conditional>
      </Conditional>
      <EditableEntry
        cell={cell}
        cellRef={ref}
        columnId={columnId}
        columnIndex={columnIndex}
        context={context}
        editableRef={editbaleRef}
        handleUpdateInTableCell={handleUpdateInTableCell}
        isDragged={isDragged}
        rowId={rowId}
        tableId={tableId}
      />
      <Conditional on={context.canEdit}>
        <ColumnResizer
          columnId={columnId}
          tableId={tableId}
          context={context}
          cellRef={ref}
        />
        <Conditional on={!isDragged}>
          <ColumnDropZone
            columnId={columnId}
            context={context}
            tableId={tableId}
            columnIndex={columnIndex}
          />
          <RowDropZone
            rowId={rowId}
            context={context}
            rowIndex={rowIndex}
            tableId={tableId}
          />
        </Conditional>
      </Conditional>
    </td>
  );
};

const EditableEntry: React.FC<{
  editableRef: React.MutableRefObject<HTMLDivElement | null>;
  cellRef: React.MutableRefObject<HTMLTableCellElement | null>;
  cell: ILineValue;
  tableId: string;
  columnId: string;
  rowId: string;
  context: IBlockContext;
  isDragged: boolean;
  columnIndex: number;
  handleUpdateInTableCell: React.MutableRefObject<
    DebouncedFunc<
      (
        index: number,
        ref: React.MutableRefObject<HTMLDivElement | null>,
        nodeId?: string
      ) => void
    >
  >;
}> = ({
  editableRef,
  cellRef,
  context,
  rowId,
  columnId,
  tableId,
  isDragged,
  handleUpdateInTableCell,
  columnIndex,
  cell,
}) => {
  const inputAction = (e: React.FormEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!context.canEdit) {
      e.preventDefault();
      return;
    }
    const selection = document.getSelection();
    if (selection && selection.toString().length > 0) {
      if (
        tableListeners.value.focusColumnId ||
        tableListeners.value.focusRowId
      ) {
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: "",
          hoverRowId: "",
          hoverTableId: "",
          cellMode: "insert",
        });
      }
    }

    handleUpdateInTableCell.current(columnIndex, editableRef, columnId);
  };

  const inputActionRef = useRef(inputAction);

  useEffect(() => {
    inputActionRef.current = inputAction;
  }, [inputAction]);

  useEffect(() => {
    if (editableRef.current) {
      editableRef.current.innerHTML = getHtml([cell]);
      if (
        $focusOn.value.focusBlockId === rowId &&
        $focusOn.value.focusTableId === tableId &&
        $focusOn.value.focusNodeId === columnId
      ) {
        let moveToPosition = 0;
        if ($focusOn.value.caretPosition || $focusOn.value.caretPosition === 0)
          moveToPosition = $focusOn.value.caretPosition;
        else {
          const textContent = editableRef.current.innerText ?? "";
          moveToPosition = textContent.length;
        }
        moveCaretToPreviousPosition(editableRef.current, moveToPosition);
      }
    }
  }, [cell]);

  return (
    <>
      {useMemo(
        () => (
          <div
            tabIndex={-1}
            contentEditable={true}
            ref={editableRef}
            className={`"Block caption"  ${styles.contentEditable}`}
            onSelect={(e) => {
              e.stopPropagation();
              const selection = document.getSelection();
              if (selection && selection.toString().length > 0) return;

              const caretPosition = checkCaretPosition(e.currentTarget);
              if (caretPosition !== $focusOn.value.caretPosition) {
                $focusOn.next({
                  caretPosition,
                  focusBlockId: rowId,
                  focusContext: context,
                  focusPane: context.paneId,
                  type: FocusType.prevPosition,
                  refocusToggle: false,
                  focusBlockRef: cellRef,
                  focusTableId: tableId,
                  focusNodeId: columnId,
                });
              }
            }}
            data-content-editable-leaf={true}
            style={{
              caretColor: context.canEdit ? "unset" : "rgba(0, 0, 0, 0)",
            }}
            onBeforeInput={(e) => {
              if (!context.canEdit) {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              if (!context.canEdit) {
                e.stopPropagation();
                e.preventDefault();
                return;
              }
              pasteHandler(e);
              handleUpdateInTableCell.current(
                columnIndex,
                editableRef,
                columnId
              );
            }}
            onInput={(e) => inputActionRef.current(e)}
            onFocus={(e) => {
              e.stopPropagation();
              tableListeners.next({
                ...tableListeners.value,
                focusColumnId: columnId,
                focusRowId: rowId,
                focusTableId: tableId,
                cellMode: "insert",
              });
              const selection = document.getSelection();
              if (selection && selection.toString().length > 0) return;

              const caretPosition = checkCaretPosition(e.currentTarget);
              if (caretPosition !== $focusOn.value.caretPosition) {
                $focusOn.next({
                  caretPosition,
                  focusBlockId: rowId,
                  focusContext: context,
                  focusPane: context.paneId,
                  type: FocusType.prevPosition,
                  refocusToggle: false,
                  focusBlockRef: cellRef,
                  focusTableId: tableId,
                  focusNodeId: columnId,
                });
              }
            }}
            onMouseDown={(e) => {
              if ($documentMode.value === DocumentModes.INSERT) {
                e.stopPropagation();
              }
            }}
            onBlur={() => {
              handleUpdateInTableCell.current.flush();
            }}
            onKeyDown={(e) => {
              if (!context.canEdit) {
                return;
              }
              switch (e.key) {
                case VALUE_CANCEL:
                case VALUE_ESCAPE: {
                  tableListeners.next({
                    ...tableListeners.value,
                    focusColumnId: columnId,
                    focusRowId: rowId,
                    focusTableId: tableId,
                    cellMode: "block",
                  });

                  $focusOn.next({
                    caretPosition: 0,
                    focusBlockId: rowId,
                    focusContext: context,
                    focusPane: context.paneId,
                    type: FocusType.prevPosition,
                    refocusToggle: false,
                    focusBlockRef: cellRef,
                    focusTableId: tableId,
                    focusNodeId: columnId,
                  });
                  e.stopPropagation();
                  e.preventDefault();
                  break;
                }
                case VALUE_LEFT: {
                  const caretPosition = checkCaretPosition(e.currentTarget);
                  if (caretPosition !== 0) {
                    e.stopPropagation();
                  }
                  break;
                }
                case VALUE_RIGHT: {
                  const caretPosition = checkCaretPosition(e.currentTarget);
                  if (caretPosition !== e.currentTarget.innerText.length) {
                    e.stopPropagation();
                  }
                  break;
                }
                case VALUE_UP: {
                  const isOverEl = getSelectionTextInfo(e.target, e);
                  if (!isOverEl.atStart && !isOverEl.singleLine) {
                    e.stopPropagation();
                    return;
                  }
                  break;
                }
                case VALUE_DOWN: {
                  const isOverEl = getSelectionTextInfo(e.target, e);
                  if (!isOverEl.atEnd && !isOverEl.singleLine) {
                    e.stopPropagation();
                    return;
                  }
                  break;
                }
                case VALUE_B:
                case VALUE_B.toUpperCase(): {
                  if (!context.canEdit) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  if (e.ctrlKey || e.metaKey) {
                    handleBlockNativeStylingInnerAction(e, "bold");
                    inputActionRef.current(e);
                  }
                  break;
                }

                case VALUE_X:
                case VALUE_X.toUpperCase(): {
                  if (!context.canEdit) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
                    e.stopPropagation();
                    e.preventDefault();
                    handleBlockNativeStylingInnerAction(e, "strikethrough");
                    inputActionRef.current(e);
                  }
                  break;
                }

                case VALUE_I:
                case VALUE_I.toUpperCase(): {
                  if (!context.canEdit) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  if (e.ctrlKey || e.metaKey) {
                    handleBlockNativeStylingInnerAction(e, "italic");
                    inputActionRef.current(e);
                  }
                  break;
                }

                case VALUE_E:
                case VALUE_E.toUpperCase(): {
                  if (!context.canEdit) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  if (e.ctrlKey || e.metaKey) {
                    e.stopPropagation();
                    handleInlineCodeStyling(
                      e,
                      e.currentTarget,
                      LineValueType.code
                    );
                    inputActionRef.current(e);
                    return;
                  }
                  break;
                }

                case VALUE_K:
                case VALUE_K.toUpperCase(): {
                  if (!context.canEdit) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  if (e.metaKey || e.ctrlKey) {
                    const selection = document.getSelection();
                    if (selection && selection?.toString().length > 0) {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCreateLink(e);
                      inputActionRef.current(e);
                    }
                  } else {
                    e.stopPropagation();
                  }
                  break;
                }

                case VALUE_ENTER: {
                  if (e.altKey && context.canEdit) {
                    handleSoftReturn(e);
                    inputActionRef.current(e);
                    e.stopPropagation();
                    return;
                  }
                  break;
                }
                case VALUE_Y:
                case VALUE_Y.toUpperCase(): {
                  if (!context.canEdit) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  if (e.metaKey || e.ctrlKey) {
                    if (e.shiftKey) {
                      e.preventDefault();
                      e.stopPropagation();
                      handleInlineCodeStyling(
                        e,
                        e.currentTarget,
                        LineValueType.highlight
                      );
                      inputActionRef.current(e);
                      return;
                    }
                  }
                  break;
                }
              }
            }}
            dangerouslySetInnerHTML={{
              __html: getHtml([cell]),
            }}
          />
        ),
        [isDragged, context]
      )}
    </>
  );
};

const ColumnResizer: React.FC<{
  columnId: string;
  tableId: string;
  context: IBlockContext;
  cellRef: React.MutableRefObject<HTMLTableCellElement | null>;
}> = ({ columnId, tableId, context, cellRef }) => {
  const [focused, setfocused] = useState(false);
  const [show, setshow] = useState(false);

  useLayoutEffect(() => {
    const sub = tableResizeObserver.subscribe((resizePosition) => {
      if (
        (resizePosition.hoveredTableId === tableId ||
          resizePosition.pressedTableId === tableId) &&
        (resizePosition.hoveredResizerColumnId === columnId ||
          resizePosition.pressedResizerColumnId === columnId)
      ) {
        setfocused(true);
        return;
      }
      setfocused(false);
    });
    const sub2 = tableDndObserver.subscribe((tableData) => {
      if (tableData.dragColumnId) {
        setshow(false);
        return;
      }
      setshow(true);
    });
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
  }, [tableId, columnId]);

  const onStopAction = () => {
    tableResizeObserver.next({
      ...tableResizeObserver.value,
      pressedResizerColumnId: "",
      pressedTableId: "",
    });
  };

  if (!show) return <></>;

  return (
    <div className={styles.tableResizeObserver}>
      <div
        className={
          focused
            ? `${styles.isFocused} ${styles.focusedContaier}`
            : `${styles.notFocused} ${styles.focusedContaier}`
        }
        onMouseEnter={() => {
          tableResizeObserver.next({
            ...tableResizeObserver.value,
            hoveredResizerColumnId: columnId,
            hoveredTableId: tableId,
          });
        }}
        onMouseDown={(e) => {
          tableResizeObserver.next({
            ...tableResizeObserver.value,
            pressedResizerColumnId: columnId,
            pressedTableId: tableId,
          });
          resizeHandler(e, {
            tableId,
            columnId,
            context,
            ref: cellRef,
            onStopAction,
          });
          e.stopPropagation();
        }}
        onMouseLeave={() => {
          tableResizeObserver.next({
            ...tableResizeObserver.value,
            hoveredResizerColumnId: "",
            hoveredTableId: "",
          });
        }}
      ></div>
    </div>
  );
};

const ColumnDropZone: React.FC<{
  tableId: string;
  columnId: string;
  context: IBlockContext;
  columnIndex: number;
}> = ({ tableId, columnId, columnIndex, context }) => {
  const [mode, setmode] = useState<"before" | "after">("after");

  const [focused, setfocused] = useState(false);
  const [show, setshow] = useState(false);

  useEffect(() => {
    const sub = tableDndObserver.subscribe((tableData) => {
      if (
        tableData.tableId === tableId &&
        tableData.dragColumnId &&
        tableData.dragColumnId !== columnId
      ) {
        setshow(true);
        if (tableData.hoveredDropZoneColumnId === columnId) {
          if (columnIndex > (tableData.dragColumnIndex ?? 0)) {
            setmode("after");
          } else setmode("before");
          setfocused(true);
        } else setfocused(false);

        return;
      }
      setshow(false);
      setfocused(false);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [tableId, columnId, columnIndex]);

  if (!show) return <></>;

  return (
    <>
      <div
        className={styles.hoveredDropZoneColumnId}
        onMouseEnter={() => {
          tableDndObserver.next({
            ...tableDndObserver.value,
            hoveredDropZoneColumnId: columnId,
            dropZoneMode: mode,
          });
        }}
        onMouseUp={(e) => {
          const draggedColumn = tableDndObserver.value.dragColumnId;
          moveColumn({
            context,
            mode,
            movingColumnId: draggedColumn,
            refColumnId: columnId,
            tableId,
          });
        }}
        onMouseLeave={() => {
          tableDndObserver.next({
            ...tableDndObserver.value,
            hoveredDropZoneColumnId: "",
          });
        }}
      ></div>
      <div
        className={
          focused
            ? `${styles.isFocused} ${styles.modeContainer}`
            : `${styles.notFocused} ${styles.modeContainer}`
        }
        style={{
          right: mode === "after" ? "0px" : undefined,
          left: mode === "before" ? "0px" : undefined,
        }}
      ></div>
    </>
  );
};

const RowDropZone: React.FC<{
  tableId: string;
  rowId: string;
  context: IBlockContext;
  rowIndex: number;
}> = ({ tableId, rowId, rowIndex, context }) => {
  const [mode, setmode] = useState<"before" | "after">("after");
  const [focused, setfocused] = useState(false);
  const [show, setshow] = useState(false);

  useEffect(() => {
    const sub = tableDndObserver.subscribe((tableData) => {
      if (
        tableData.tableId === tableId &&
        tableData.dragRowId &&
        tableData.dragRowId !== rowId
      ) {
        setshow(true);
        if (tableData.hoveredDropZoneRowId === rowId) {
          if (rowIndex > (tableData.dragRowIndex ?? 0)) {
            setmode("after");
          } else setmode("before");
          setfocused(true);
        } else setfocused(false);

        return;
      }
      setshow(false);
      setfocused(false);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [tableId, rowId, rowIndex]);

  if (!show) return <></>;

  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          flexGrow: 0,
          zIndex: 1,
        }}
        onMouseEnter={() => {
          tableDndObserver.next({
            ...tableDndObserver.value,
            hoveredDropZoneRowId: rowId,
            dropZoneMode: mode,
          });
        }}
        onMouseUp={(e) => {
          const draggedRow = tableDndObserver.value.dragRowId;
          moveRow({
            context,
            mode,
            movingRowId: draggedRow,
            refRowId: rowId,
            tableId,
          });
        }}
        onMouseLeave={() => {
          tableDndObserver.next({
            ...tableDndObserver.value,
            hoveredDropZoneRowId: "",
            dropZoneMode: "",
          });
        }}
      ></div>
      <div
        className={
          focused
            ? `${styles.isFocused} ${styles.modeContainerSecond}`
            : `${styles.notFocused} ${styles.modeContainerSecond}`
        }
        style={{
          bottom: mode === "after" ? "0px" : undefined,
          top: mode === "before" ? "0px" : undefined,
        }}
      ></div>
    </>
  );
};

const ColumnCTA: React.FC<{
  tableId: string;
  columnId: string;
  columnIndex: number;
  context: IBlockContext;
}> = ({ tableId, columnId, context, columnIndex }) => {
  const [isVisible, setisVisible] = useState(false);
  const [isPressed, setisPressed] = useState(false);
  const [isFocused, setisFocused] = useState(false);

  useEffect(() => {
    const sub = tableListeners.subscribe((tableFocusData) => {
      if (
        tableFocusData.focusTableId === tableId &&
        tableFocusData.focusColumnId === columnId &&
        !tableFocusData.focusRowId
      ) {
        setisFocused(true);
        setisVisible(true);
        return;
      }
      if (
        tableFocusData.hoverTableId === tableId &&
        tableFocusData.hoverColumnId === columnId
      ) {
        setisFocused(false);
        return setisVisible(true);
      }

      setisFocused(false);
      setisVisible(false);
    });
    return () => sub?.unsubscribe();
  }, [tableId, columnId]);

  return (
    <Dropdown
      destroyPopupOnHide={true}
      overlay={
        <ColumnDropdown
          tableId={tableId}
          columnId={columnId}
          context={context}
          columnIndex={columnIndex}
          setisPressed={setisPressed}
        />
      }
      visible={isPressed}
    >
      <div
        className={
          isFocused
            ? `${styles.isFocusedDrop}  ${styles.dragAndDropStartHandler}`
            : `${styles.notFocusedDrop}  ${styles.dragAndDropStartHandler}`
        }
        style={{
          opacity: isVisible || isPressed ? "1" : "0",
          pointerEvents: isVisible || isPressed ? "all" : "none",
        }}
        onClick={(e) => {
          focusFromHandle = true;
          tableListeners.next({
            ...tableListeners.value,
            focusTableId: tableId,
            focusColumnId: columnId,
            focusRowId: "",
          });
          setisPressed(true);
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          focusFromHandle = true;
          tableListeners.next({
            ...tableListeners.value,
            focusTableId: tableId,
            focusColumnId: columnId,
            focusRowId: "",
          });
          dragAndDropStartHandler(e, {
            tableId,
            columnId,
            context,
            columnIndex,
          });
        }}
      >
        <SixDotHandle style={{ transform: "rotate(90deg)" }} />
      </div>
    </Dropdown>
  );
};

const RowCTA: React.FC<{
  tableId: string;
  rowId: string;
  rowIndex: number;
  context: IBlockContext;
}> = ({ tableId, rowId, context, rowIndex }) => {
  const [isVisible, setisVisible] = useState(false);
  const [isPressed, setisPressed] = useState(false);
  const [isFocused, setisFocused] = useState(false);

  useEffect(() => {
    const sub = tableListeners.subscribe((tableFocusData) => {
      if (
        tableFocusData.focusTableId === tableId &&
        tableFocusData.focusRowId === rowId &&
        !tableFocusData.focusColumnId
      ) {
        setisFocused(true);
        setisVisible(true);
        return;
      }
      if (tableFocusData.hoverTableId && tableFocusData.hoverRowId === rowId) {
        setisFocused(false);
        return setisVisible(true);
      }

      setisFocused(false);
      setisVisible(false);
    });
    return () => sub?.unsubscribe();
  }, [tableId, rowId]);

  return (
    <Dropdown
      overlay={
        <RowDropdown
          setisPressed={setisPressed}
          tableId={tableId}
          rowIndex={rowIndex}
          rowId={rowId}
          context={context}
        />
      }
      visible={isPressed}
    >
      <div
        className={
          isFocused
            ? `${styles.isFocusedDrop}  ${styles.dragAndDropStartHandler}`
            : `${styles.notFocusedDrop}  ${styles.dragAndDropStartHandler}`
        }
        style={{
          opacity: isVisible || isPressed ? "1" : "0",
          pointerEvents: isVisible || isPressed ? "all" : "none",
          height: "26px",
          width: "16px",
          padding: "4px 2px",
          left: "-8px",
          top: "4px",
        }}
        data-beacon={true}
        tabIndex={-1}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          tableListeners.next({
            ...tableListeners.value,
            focusTableId: tableId,
            focusRowId: rowId,
            focusColumnId: "",
          });
          setisPressed(true);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          focusFromHandle = true;
          tableListeners.next({
            ...tableListeners.value,
            focusTableId: tableId,
            focusRowId: rowId,
            focusColumnId: "",
          });
          dragAndDropStartHandler(e, { tableId, rowId, context, rowIndex });
        }}
      >
        <SixDotHandle />
      </div>
    </Dropdown>
  );
};

const ColumnDropdown: React.FC<{
  tableId: string;
  columnId: string;
  context: IBlockContext;
  columnIndex: number;
  setisPressed: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ tableId, columnId, context, columnIndex, setisPressed }) => {
  const closeRef = useRef(() => {
    setisPressed(false);
  });
  return (
    <div
      className="allowClick"
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Menu
        close={() => {
          closeRef.current();
        }}
        slim={true}
        yDir="down"
      >
        <Conditional on={columnIndex === 0}>
          <ColumnHeaderCheck tableId={tableId} context={context} />
        </Conditional>
        <MenuItem
          title="Add column before"
          onClick={() => {
            setisPressed(false);
            addColumn({
              tableId,
              refColumnId: columnId,
              context,
              count: 1,
              insert: "before",
            });
          }}
        />
        <MenuItem
          title="Add column after"
          onClick={() => {
            setisPressed(false);
            addColumn({
              tableId,
              refColumnId: columnId,
              context,
              count: 1,
              insert: "after",
            });
          }}
        />
        <MenuItem
          title="Clear contents"
          onClick={() => {
            setisPressed(false);
            clearContent({
              context,
              id: columnId,
              tableId,
              type: "column",
            });
          }}
        />
        <MenuItem
          title="Delete column"
          onClick={() => {
            setisPressed(false);
            removeColumn({
              tableId,
              context,
              count: 1,
              includeThis: true,
              refColumnId: columnId,
              remove: "after",
              stopOnNonEmpty: false,
            });
          }}
        />
      </Menu>
    </div>
  );
};

const RowDropdown: React.FC<{
  tableId: string;
  rowId: string;
  context: IBlockContext;
  rowIndex: number;
  setisPressed: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ tableId, rowId, context, rowIndex, setisPressed }) => {
  const closeRef = useRef(() => {
    setisPressed(false);
  });
  return (
    <div
      className="allowClick"
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Menu close={closeRef.current} xDir="right" yDir="down" slim={true}>
        <Conditional on={rowIndex === 0}>
          <RowHeaderCheck tableId={tableId} context={context} />
        </Conditional>
        <MenuItem
          title="Add row before"
          onClick={() => {
            setisPressed(false);
            addRow({
              tableId,
              refRowId: rowId,
              context,
              count: 1,
              insert: "before",
            });
          }}
        />
        <MenuItem
          title="Add row after"
          onClick={() => {
            setisPressed(false);
            addRow({
              tableId,
              refRowId: rowId,
              context,
              count: 1,
              insert: "after",
            });
          }}
        />
        <MenuItem
          title="Clear contents"
          onClick={() => {
            setisPressed(false);
            clearContent({
              context,
              id: rowId,
              tableId,
              type: "row",
            });
          }}
        />
        <MenuItem
          title="Delete row"
          onClick={() => {
            setisPressed(false);
            removeRow({
              tableId,
              context,
              includeThis: true,
              refRowId: rowId,
              stopOnNonEmpty: false,
            });
          }}
        />
      </Menu>
    </div>
  );
};

const RowHeaderCheck: React.FC<{
  tableId: string;
  context: IBlockContext;
}> = ({ tableId, context }) => {
  const tableBlock = useShallowSelector((store) => store.blocks.dict[tableId]);
  const hasTableRowHeader =
    tableBlock.value[0].tableBlockColumnData?.hasHeaderRow;

  return (
    <MenuItem
      title="Header row"
      onClick={() =>
        toggleHeader({
          context,
          tableId,
          type: "row",
        })
      }
    >
      <Switch checked={hasTableRowHeader ?? false} />
    </MenuItem>
  );
};

const ColumnHeaderCheck: React.FC<{
  tableId: string;
  context: IBlockContext;
}> = ({ tableId, context }) => {
  const tableBlock = useShallowSelector((store) => store.blocks.dict[tableId]);
  const hasTableRowHeader =
    tableBlock.value[0].tableBlockColumnData?.hasHeaderColumn;

  return (
    <MenuItem
      title="Header column"
      onClick={() =>
        toggleHeader({
          context,
          tableId,
          type: "column",
        })
      }
    >
      <Switch checked={hasTableRowHeader ?? false} />
    </MenuItem>
  );
};

export default TableRow;
