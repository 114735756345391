import React, { useCallback, useEffect, useRef, useState } from "react";
import Member from "./userList/Member";
import { Virtuoso } from "react-virtuoso";
import { AutoSizer } from "react-virtualized";
import ClarityInput from "components/ClarityInput";
import Fuse from "fuse.js";
import store from "store/storeExporter";
import Conditional from "components/Conditional";
import EmptyState from "./EmptyState";
import styles from "./userList/userList.module.scss";

const UserList: React.FC<{
  userIds: string[];
  userAction?: (id: string) => void;
  searchPlaceholder?: string;
  showSearch?: boolean;
  memberAction?: (id: string) => JSX.Element;
}> = ({ userIds, showSearch, searchPlaceholder, memberAction }) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const [filteredUsers, setfilteredUsers] = useState(userIds);

  const rowRenderer = useCallback(
    (index: number) => {
      const id = filteredUsers[index];
      return (
        <Member key={id} userId={id} disabled={false}>
          {memberAction ? memberAction(id) : undefined}
        </Member>
      );
    },
    [filteredUsers]
  );

  return (
    <div
      className="flex-fill"
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {showSearch && (
        <SearchAction
          setfilteredUsers={setfilteredUsers}
          userIds={userIds}
          searchPlaceholder={searchPlaceholder}
        />
      )}
      <div ref={listRef} className="flex-fill">
        <Conditional on={filteredUsers.length === 0}>
          <EmptyState
            heading="Nothing to show here"
            caption={
              userIds.length === 0
                ? "No user in this list"
                : "No user matches your search"
            }
          />
        </Conditional>
        <Conditional on={filteredUsers.length > 0}>
          <AutoSizer>
            {({ width, height }) => {
              return (
                <>
                  <Virtuoso
                    className={styles.scrollElement}
                    totalCount={filteredUsers.length}
                    height={height}
                    fixedItemHeight={60}
                    style={{ height: height + "px", width: `${width}px` }}
                    overscan={10}
                    width={width}
                    itemContent={(index) => rowRenderer(index)}
                  />
                </>
              );
            }}
          </AutoSizer>
        </Conditional>
      </div>
    </div>
  );
};

const SearchAction: React.FC<{
  setfilteredUsers: React.Dispatch<React.SetStateAction<string[]>>;
  userIds: string[];
  searchPlaceholder?: string;
}> = ({ setfilteredUsers, userIds, searchPlaceholder }) => {
  const [inputText, setInputText] = useState("");

  const searchForUsers = (inputText: string, userIds: string[]) => {
    const membersDict = store.getState().members.dict;

    const members = userIds.map((id) => {
      const member = membersDict[id];
      return member ?? {};
    });

    const fuse = new Fuse(members, {
      minMatchCharLength: 2,
      threshold: 0.4,
      keys: ["name", "username"],
    });

    return fuse.search(inputText).map((result) => result.item.id);
  };

  useEffect(() => {
    if (!inputText) setfilteredUsers(userIds);
    else {
      const filteredItems = searchForUsers(inputText, userIds);
      setfilteredUsers(filteredItems);
    }
  }, [inputText, userIds]);

  return (
    <ClarityInput
      placeholder={searchPlaceholder ?? "Search usernames or names"}
      autoFocus={true}
      onChange={(e) => setInputText(e.target.value)}
    />
  );
};

export default UserList;
