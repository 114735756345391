import React, { useMemo } from "react";
import { ContainerTypes } from "utilities/types";
import DetailView from "../DetailView";

const DetailViewWrapper: React.FC<{
  containerType: ContainerTypes;
  containerId: string;
}> = ({ containerId, containerType }) => {
  return useMemo(
    () => (
      <DetailView containerId={containerId} containerType={containerType} />
    ),
    [containerId, containerType]
  );
};

export default DetailViewWrapper;
