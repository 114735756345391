import React, { useContext, useState } from "react";
import { prevState } from "store/storeExporter";
import { flashBlock, flashBlockSubject } from "App";
import { IBlockContext, ViewNames } from "utilities/types";
import ChevronRight from "icons/chevron-right.svg";
import styles from "clarity-ui/portalContainer/portalPath/portalPath.module.scss";
import { IsCitationPortal } from "screens/base/main/detailView/documentContainer/document/lineComponent/SourceCitations";
import {
  getNameFromContainer,
  useGetContainerFromType,
} from "modules/containerHelpers";
import { VariableContainerIcon } from "clarity-ui/VariableContainerIcon";
import { getHtml } from "editor/utils/blockValueHelpers";
import navigationApi from "clientApi/navigationApi";
import { ChunkDestination } from "utilities/stateTypes";

interface IPortalPathProps {
  ancestors: any[];
  linkedEntity: any;
  linkTagIcon?: any;
  blockContext?: IBlockContext;
  setContext?: any;
  blockId?: string | null;
}

export default function PortalPath(props: IPortalPathProps) {
  const { ancestors, linkedEntity, linkTagIcon, blockContext, setContext } =
    props;
  const isCitationPortal = useContext(IsCitationPortal);

  const ancestorsCount = ancestors ? ancestors.length : 0;
  return (
    <div className={styles.PortalPath}>
      {isCitationPortal && props.blockId && (
        <AncestorContainer
          blockId={props.blockId}
          noArrow={ancestors.length === 0}
          blockContext={blockContext}
        />
      )}
      {ancestors.map((ancestor, index) => (
        <React.Fragment key={ancestor}>
          <SingleAncestor
            ancestor={ancestor}
            linkedEntity={linkedEntity}
            blockContext={blockContext}
            setContext={setContext}
          />
          {ancestorsCount > 0 &&
            (ancestorsCount > index + 1 || linkTagIcon) && (
              <img
                src={ChevronRight}
                className={styles.PortalPath__chevron}
                alt=""
              />
            )}
        </React.Fragment>
      ))}
      {linkTagIcon && <span>{linkTagIcon}</span>}
    </div>
  );
}

const SingleAncestor: React.FC<any> = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blockData, setBlockData] = useState(
    prevState.value.blocks.dict[props.ancestor]
  );

  if (!blockData?.value) return <></>;

  return (
    <>
      <span
        className={styles.SingleAncestor}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          const paneId = props.blockContext?.paneId;

          if (props.ancestor) {
            setTimeout(() => {
              flashBlock.id = props.ancestor;
              flashBlockSubject.next(flashBlock);
            }, 1);
          }

          navigationApi.contextBasedNavigate({
            navigationChunk: {
              viewName: ViewNames.Detail,
              entity: {
                containerId: blockData.containerId,
                containerType: blockData.containerType,
              },
            },
            currentPane: paneId,
            shiftKey: e.shiftKey,
            forceToPane: ChunkDestination.peek,
          });
        }}
        dangerouslySetInnerHTML={{ __html: getHtml(blockData.value) }}
      ></span>
    </>
  );
};

const AncestorContainer: React.FC<{
  blockId: string;
  noArrow: boolean;
  blockContext: IBlockContext | undefined;
}> = ({ blockId, noArrow, blockContext }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blockData, setBlockData] = useState(
    prevState.value.blocks.dict[blockId]
  );

  const container = useGetContainerFromType(
    blockData.containerId,
    blockData.containerType
  );

  if (!container) return <></>;

  return (
    <>
      <span
        className={styles.SingleAncestor}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const paneId = blockContext
            ? blockContext.paneId
            : ChunkDestination.primary;

          navigationApi.contextBasedNavigate({
            navigationChunk: {
              viewName: ViewNames.Detail,
              entity: {
                containerId: blockData.containerId,
                containerType: blockData.containerType,
              },
            },
            currentPane: paneId,
            shiftKey: e.shiftKey,
            forceToPane: ChunkDestination.peek,
          });
        }}
      >
        <span style={{ marginRight: "5px" }}>
          <VariableContainerIcon containerType={blockData.containerType} />
        </span>
        <span
          dangerouslySetInnerHTML={{
            __html: getNameFromContainer(container, blockData.containerType),
          }}
        />
      </span>
      {!noArrow && (
        <img src={ChevronRight} className={styles.PortalPath__chevron} alt="" />
      )}
    </>
  );
};
