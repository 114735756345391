import store, { prevState } from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import {
  Abilities,
  CommandPaletteContext,
  CommandPaletteDateFilterContextTypes,
  ContainerTypes,
  GeneralViewsNames,
  GroupGeneralViews,
  IBlockContext,
  IFilterKey,
  IGroup,
  IProjectObj,
  priorities,
  TasksViewModes,
  UserRole,
  ViewNames,
  WorkTypes,
} from "utilities/types";
import {
  canChangeCycles,
  changeContainer,
  generalBlockRefocus,
  handlePriorityChange,
  handleSelectAssignee,
  handleSelectContributor,
  handleSelectGroup,
  handleSelectMilestone,
  handleSelectReviewer,
  handleSelectSection,
  handleSelectSponsorGroup,
  handleStatusChange,
  paletteGetSelectedEntities,
  setCommandPaletteContext,
} from "./actions";
import { CommandPaletteOption } from "./types";
import { ReactComponent as UnassignedIcon } from "icons/avatar-empty.svg";
import { ReactComponent as GroupsIcon } from "icons/menu/Groups.svg";
import Icon, {
  FlagOutlined,
  FontSizeOutlined,
  CheckSquareOutlined,
  CheckCircleOutlined,
  PictureOutlined,
  CodeOutlined,
  FilePdfOutlined,
  PicCenterOutlined,
  FileDoneOutlined,
  AimOutlined,
  FormOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import UserDisplay, { usernameSplitter } from "clarity-ui/UserDisplay";
import {
  getDateFilterPropertyNames,
  updateFilterAction,
} from "store/reducers/filterReducer";
import { remove } from "lodash";
import { uniqBy } from "lodash";
import { getHtml } from "editor/utils/blockValueHelpers";
import { stripHtml } from "utilities/stringUtilities";
import rolesApi from "clientApi/rolesApi";
import workApi from "clientApi/workApi";
import {
  getContainerByType,
  getNameFromContainer,
} from "modules/containerHelpers";
import {
  getIconFromContainerType,
  getIconFromNavigationChunk,
} from "utilities/iconHelpers";
import StatusDisplay from "components/StatusDisplay";
import PriorityDisplay from "components/PriorityDisplay";
import { Moment } from "moment";
import { Cycle } from "store/reducers/workReducer";
import { checkCheckboxCases } from "editor/utils/blockModeEvents";
import { changeBlockOrSelectionType } from "editor/utils/specificActions/blockTypesActions";
import { batch } from "react-redux";
import {
  createAndSetNewPage,
  createAndSetNewProject,
  createAndSetNewInitiative,
} from "utilities/createNewContainers";
import { LineType } from "utilities/lineUtilities";
import { Block } from "store/reducers/blockReducer";
import { locationSubject } from "components/LocationListener";
import navigationApi from "clientApi/navigationApi";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";
import ControlTwoTone from "icons/Components/ControlTwoTone";
import FileTextTwoTone from "icons/Components/FileTextTwoTone";
import StarTwoTone from "icons/Components/StarTwoTone";
import { getCorrectLink } from "utilities/linkUtilities";
import roleApi from "clientApi/rolesApi";
import { createTemplate } from "screens/base/main/TemplatesList";
import { snippetsApi } from "clientApi/snippetsApi";
import moment from "moment";
import {
  INewTaskCreationModes,
  openNewTaskModal,
} from "store/reducers/clientReducer";
import ThunderboltTwoTone from "icons/Components/ThunderboltTwoTone";

function ManualSvgIcon({ pathD }: { pathD: string }) {
  return (
    <svg viewBox="0 -64 896 896" width="1em" height="1em">
      <path d={pathD} fill="currentColor" />
    </svg>
  );
}

const getReciprocalColl = <T extends number | string = string>(
  property: IFilterKey,
  paneId: ChunkDestination
) => {
  const filters = store.getState().filter.currentDict?.[paneId]?.filters;
  return Array.from(filters ? (filters[property] as T[]) : []);
};

const createFilterHandler =
  <T extends number | string = string>(property: IFilterKey) =>
  (itemId: T, paneId: ChunkDestination) => {
    const filters = store.getState().filter.currentDict?.[paneId]?.filters;
    const filterColl = Array.from(filters ? (filters[property] as T[]) : []);

    if (filterColl.includes(itemId)) {
      remove(filterColl, (id: T) => id === itemId);
    } else {
      filterColl.push(itemId);
    }

    updateFilterAction({
      paneId,
      delta: { [property]: filterColl },
    });
  };

const getMemberToAssigneeOption =
  ({ excluded, paneId }: { excluded: boolean; paneId: ChunkDestination }) =>
  (memberId: string): CommandPaletteOption => {
    const member = prevState.value.members.dict[memberId];

    return {
      id: memberId,
      name: usernameSplitter(member.name || `@${member.username}`),
      action: () =>
        createFilterHandler(excluded ? "excludedAssignees" : "assignees")(
          member.id,
          paneId
        ),
      entity: member,
      closeAfter: false,
      optionType: "command",
      icon: <UserDisplay avatarSize={27} id={memberId} hideName={true} />,
      multiselect: true,
      checked() {
        const filters = store.getState().filter.currentDict[paneId].filters;
        if (excluded) return filters.excludedAssignees.includes(memberId);
        return filters.assignees.includes(memberId);
      },
    };
  };

export const getFilterOptions = () => {
  const options: CommandPaletteOption[] = [
    {
      id: "status",
      name: "Status",
      action: () =>
        setCommandPaletteContext(CommandPaletteContext.FILTER_STATUS),
      optionType: "command",
    },
    {
      id: "assignee",
      name: "Assignee",
      action: () =>
        setCommandPaletteContext(CommandPaletteContext.FILTER_ASSIGNEE),
      optionType: "command",
    },
    {
      id: "priority",
      name: "Priority",
      action: () => setCommandPaletteContext(CommandPaletteContext.PRIORITY),
      closeAfter: false,
      optionType: "command",
    },
    {
      id: "parent",
      name: "Parent",
      action: () => setCommandPaletteContext(CommandPaletteContext.PARENT),
      closeAfter: false,
      optionType: "command",
    },
    {
      id: "tags",
      name: "Tags",
      action: () =>
        setCommandPaletteContext(CommandPaletteContext.FILTER_LABEL),
      closeAfter: false,
      optionType: "command",
    },
    {
      id: "dueDateAfter",
      name: "Due date after",
      action: (event: any) =>
        setCommandPaletteContext(CommandPaletteContext.FILTER_DUE_AFTER_DATE),
      closeAfter: false,
      optionType: "command",
    },
    {
      id: "dueDateBefore",
      name: "Due date before",
      action: (event: any) =>
        setCommandPaletteContext(CommandPaletteContext.FILTER_DUE_BEFORE_DATE),
      closeAfter: false,
      optionType: "command",
    },
  ];
  return options;
};

export const getFilterAssigneeOptions = (
  paneId: ChunkDestination
): CommandPaletteOption[] => {
  const reciprocalColl = getReciprocalColl("excludedAssignees", paneId);
  const options = [...store.getState().members.ids]
    .filter((id: string) => !reciprocalColl.includes(id))
    .map(getMemberToAssigneeOption({ excluded: false, paneId }));

  const anyoneOption: CommandPaletteOption = {
    id: "*",
    name: "Anyone",
    action: () => {
      createFilterHandler("assignees")("*", paneId);
    },
    icon: <Icon component={UnassignedIcon} />,
    optionType: "user",
    multiselect: true,
    checked() {
      const filters = store.getState().filter.currentDict[paneId].filters;
      return filters.assignees.includes("*");
    },
  };

  options.unshift(anyoneOption);

  return options;
};

export const getFilterAssigneeNotOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("assignees", paneId);
  return [...store.getState().members.ids]
    .filter((id: string) => !reciprocalColl.includes(id))
    .map(getMemberToAssigneeOption({ excluded: true, paneId }));
};

export const getAssigneeOptions = (): CommandPaletteOption[] => {
  const storeData = store.getState();
  const memberDict = storeData.members.dict;
  const membersAndGuestList = [...storeData.members.ids];
  const options: CommandPaletteOption[] = membersAndGuestList.map(
    (collaboratorId) => {
      const collaborator = memberDict[collaboratorId];
      return {
        id: collaboratorId,
        name: usernameSplitter(
          collaborator.name || `@${collaborator.username}`
        ),
        action: () => handleSelectAssignee(collaborator.id),
        entity: collaborator,
        closeAfter: true,
        optionType: "user",
        icon: (
          <UserDisplay id={collaboratorId} avatarSize={27} hideName={true} />
        ),
      };
    }
  );
  const noneOption: CommandPaletteOption = {
    id: "unassigned",
    name: "Unassigned",
    action: () => handleSelectAssignee(null),
    optionType: "user",
    closeAfter: true,
    icon: <Icon component={UnassignedIcon} style={{ fontSize: "27px" }} />,
  };

  return [noneOption, ...options];
};

export const getContributorOptions = (): CommandPaletteOption[] => {
  const storeData = store.getState();
  const memberDict = storeData.members.dict;
  const membersAndGuestList = [...storeData.members.ids];
  const itemId = storeData.commandPalette.params?.selectedItemIds?.[0];
  const workItem = itemId ? storeData.work.dict[itemId] : undefined;

  const options: CommandPaletteOption[] = membersAndGuestList.map(
    (collaboratorId) => {
      const collaborator = memberDict[collaboratorId];
      return {
        id: collaboratorId,
        name: usernameSplitter(
          collaborator.name || `@${collaborator.username}`
        ),
        action: () => handleSelectContributor(collaborator.id),
        entity: collaborator,
        closeAfter: false,
        multiselect: true,
        checked: () =>
          !!(workItem && workItem.contributorIds.includes(collaboratorId)),
        icon: (
          <UserDisplay id={collaboratorId} hideName={true} avatarSize={27} />
        ),
        optionType: "user",
      };
    }
  );

  return [...options];
};

export const getGroupOptions = (): CommandPaletteOption[] => {
  const storeData = store.getState();
  const groupState = storeData.groups;
  const userGroups = groupState.userGroups;
  const itemId = storeData.commandPalette.params?.selectedItemIds?.[0];
  const workItem = itemId ? storeData.work.dict[itemId] : undefined;
  const options: CommandPaletteOption[] = userGroups.map((groupId) => {
    const group = groupState.dict[groupId];

    return {
      id: groupId,
      name: group.name,
      entity: group,
      action: () => handleSelectGroup(groupId),
      multiselect: true,
      checked: () =>
          !!(workItem && workItem.groupIds?.includes(groupId)),
      closeAfter: false,
      optionType: "command",
      icon: <Icon component={GroupsIcon} />,
    };
  });

  return options;
};

export const getSponsorGroupOptions = (): CommandPaletteOption[] => {
  const groupState = store.getState().groups;
  const userGroups = groupState.userGroups;

  const options: CommandPaletteOption[] = userGroups.map((groupId) => {
    const group = groupState.dict[groupId];

    return {
      id: groupId,
      name: group.name,
      entity: group,
      action: () => handleSelectSponsorGroup(groupId),
      closeAfter: true,
      optionType: "command",
      icon: <Icon component={GroupsIcon} />,
    };
  });

  return options;
};

export const getReviewerOptions = () => {
  const storeData = store.getState();
  const memberDict = storeData.members.dict;
  const membersAndGuestList = [...storeData.members.ids];

  const options: CommandPaletteOption[] = membersAndGuestList
    .filter((collaboratorId) => {
      const collaborator = memberDict[collaboratorId];
      if (collaborator.roleIds) {
        const aggregatedRoles = rolesApi.aggregateAbilities(
          collaborator.roleIds
        );
        return aggregatedRoles[Abilities.CAN_BE_REVIEWER];
      }
      return true;
    })
    .map((collaboratorId) => {
      const collaborator = memberDict[collaboratorId];
      return {
        id: collaboratorId,
        name: usernameSplitter(
          collaborator.name || `@${collaborator.username}`
        ),
        action: () => handleSelectReviewer(collaborator.id),
        entity: collaborator,
        optionType: "user",
        icon: (
          <UserDisplay id={collaboratorId} hideName={true} avatarSize={27} />
        ),
        closeAfter: true,
      };
    });

  const noReiviewerOption: CommandPaletteOption = {
    id: "noReviewer",
    name: "No reviewer",
    action: () => handleSelectReviewer(null),
    closeAfter: true,
    optionType: "user",
    icon: <Icon style={{ fontSize: "27px" }} component={UnassignedIcon} />,
  };

  return [noReiviewerOption, ...options];
};

export const getParentOptions = (): CommandPaletteOption[] => {
  let projectsArray: IProjectObj[] = [];
  const selectedEntities = paletteGetSelectedEntities();
  if (selectedEntities.ids.length === 0) return [];

  const selectedEntityIds = selectedEntities.ids;

  projectsArray = workApi.getPossibleParents(selectedEntityIds);

  const noParent: CommandPaletteOption = {
    id: "noParent",
    name: "No parent",
    action: () => workApi.update({ parentId: null }, selectedEntityIds),
    entity: null,
    closeAfter: true,
    optionType: "command",
  };

  let options: CommandPaletteOption[] = projectsArray
    .filter((project) => !(project.isClosed || project.isDeleted))
    .map((project: IProjectObj) => ({
      id: project.id,
      name: getNameFromContainer(project, ContainerTypes.PROJECT),
      action: () => {
        workApi.update({ parentId: project.id }, selectedEntityIds);
      },
      entity: project,
      closeAfter: true,
      optionType: "project",
      icon: getIconFromContainerType(ContainerTypes.WORK, project.workType),
    }));

  options = [noParent, ...options];

  return options;
};

export const getWorkSectionOptions = (): CommandPaletteOption[] => {
  const selectedEntities = paletteGetSelectedEntities();
  if (selectedEntities.ids.length === 0) return [];

  const selectedEntityIds: string[] = selectedEntities.ids;
  const work = store.getState().work;

  const options: CommandPaletteOption[] = [];
  const unique = uniqBy(selectedEntityIds, (id) => {
    const item = work.dict[id];

    return item.groupId;
  });

  unique.forEach((entityId) => {
    const item = work.dict[entityId];

    if (work.groupCycles[item.groupId]) {
      const sections: CommandPaletteOption[] = work.groupCycles[
        item.groupId
      ]?.openTimeframeIds?.map((id: string) => {
        const section = work.sections[id];
        return {
          id,
          name: section.name,
          icon: <ThunderboltTwoTone />,
          action: () => handleSelectSection(section.id),
          entity: section,
          closeAfter: true,
          optionType: "command",
        };
      });
      options.push(...sections);
    }
  });

  const noCycleOption: CommandPaletteOption = {
    id: "none",
    name: "None",
    action: () => handleSelectSection(null),
    closeAfter: true,
    icon: <ThunderboltTwoTone />,
    optionType: "command",
  };

  return [noCycleOption, ...options];
};

export const getMilestoneOptions = () => {
  const work = store.getState().work;
  const options: CommandPaletteOption[] = work.milestoneIds.map(
    (id: string) => {
      const milestone = work.milestoneDict[id];
      return {
        id,
        name: milestone.name,
        icon: <FlagOutlined />,
        action: () => handleSelectMilestone(id),
        entity: milestone,
        closeAfter: true,
        optionType: "command",
      };
    }
  );

  const noMilestoneOption: CommandPaletteOption = {
    id: "none",
    name: "None",
    action: () => handleSelectMilestone(null),
    closeAfter: true,
    icon: <FlagOutlined />,
    optionType: "command",
  };

  return [noMilestoneOption, ...options];
};

export const getStatusOptions = () => {
  const work = store.getState().work;

  const options: CommandPaletteOption[] = work.statuses.statusArray.map(
    (statusId: string) => {
      const status = work.statuses.dict[statusId];
      return {
        id: statusId,
        icon: <StatusDisplay statusId={statusId} showName={false} />,
        name: status.name,
        action: () => {
          handleStatusChange(status);
        },
        entity: status,
        closeAfter: true,
        optionType: "command",
      };
    }
  );

  return options;
};

export const getPriorityOptions = () => {
  const options: CommandPaletteOption[] = priorities.map(
    (priority: string, index: number) => {
      return {
        id: String(index + 1),
        icon: <PriorityDisplay priorityLevel={index + 1} showName={false} />,
        name: priority,
        action: () => {
          handlePriorityChange(index + 1);
        },
        closeAfter: true,
        optionType: "command",
      };
    }
  );
  return options;
};

export const getFilterLabelOptions = (
  paneId: ChunkDestination
): CommandPaletteOption[] => {
  const reciprocalColl = getReciprocalColl("excludedLabels", paneId);
  const storeData = store.getState();
  const docsArray = storeData.pages.ids;
  const work = storeData.work;
  const allWorkIds = [
    ...work.allTaskIds,
    ...work.allProjectIds,
    ...work.allInitiativeIds,
  ];

  const documents = docsArray
    .filter(
      (id: string) => !reciprocalColl.includes(ContainerTypes.DOCUMENT + id)
    )
    .map(getDocumentIdToLabelOption({ excluded: false, paneId }));

  const projects = allWorkIds
    .filter(
      (id: string) =>
        !reciprocalColl.includes(ContainerTypes.WORK + id) &&
        !work.dict[id].isClosed
    )
    .map(getProjectIdToLabelOption({ excluded: false, paneId }));

  return [...documents, ...projects];
};

export const handleFilterDueDateChange = (
  value: Moment | null,
  paneId: ChunkDestination
) => {
  const commandPaletteData = store.getState().commandPalette;
  const context = commandPaletteData.context;

  const isDateFilterContext =
    context.includes("Filter") && context.includes("Date");

  if (isDateFilterContext) {
    const { dateFilterName, typeName } = getDateFilterPropertyNames(
      context as CommandPaletteDateFilterContextTypes
    );

    const filters = store.getState().filter.currentDict?.[paneId]?.filters;

    const date = filters
      ? filters[dateFilterName]
      : {
          after: null,
          before: null,
          exactly: null,
          not: null,
          isWeekView: null,
        };

    updateFilterAction({
      paneId,
      delta: {
        [dateFilterName]: {
          ...date,
          [typeName]: value?.toString() ?? null,
        },
      },
    });
  }
};

const getDocumentIdToLabelOption =
  ({ excluded, paneId }: { excluded: boolean; paneId: ChunkDestination }) =>
  (id: string): CommandPaletteOption => {
    const page = store.getState().pages.dict[id];
    return {
      id,
      name: stripHtml(getHtml(page.nameValue)),
      action: () =>
        createFilterHandler(excluded ? "excludedLabels" : "labels")(
          ContainerTypes.DOCUMENT + id,
          paneId
        ),
      entity: page,
      closeAfter: false,
      optionType: "document",
      icon: getIconFromContainerType(ContainerTypes.DOCUMENT),
    };
  };

const getProjectIdToLabelOption =
  ({ excluded, paneId }: { excluded: boolean; paneId: ChunkDestination }) =>
  (id: string): CommandPaletteOption => {
    const workItem = store.getState().work.dict[id];
    return {
      id,
      name: `#${workItem.projectId} • ${stripHtml(
        getHtml(workItem.nameValue)
      )}`,
      action: () =>
        createFilterHandler(excluded ? "excludedLabels" : "labels")(
          ContainerTypes.WORK + id,
          paneId
        ),
      entity: workItem,
      optionType: "project",
      icon: getIconFromContainerType(ContainerTypes.WORK, workItem.workType),
      closeAfter: false,
    };
  };

export const getFilterLabelNotOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("labels", paneId);
  const storeData = store.getState();
  const docsArray = storeData.pages.ids;
  const work = storeData.work;
  const allWorkIds = [
    ...work.allTaskIds,
    ...work.allProjectIds,
    ...work.allInitiativeIds,
  ];

  const documents = docsArray
    .filter(
      (id: string) => !reciprocalColl.includes(ContainerTypes.DOCUMENT + id)
    )
    .map(getDocumentIdToLabelOption({ excluded: true, paneId }));

  const projects = allWorkIds
    .filter(
      (id: string) =>
        !reciprocalColl.includes(ContainerTypes.WORK + id) &&
        !work.dict[id].isClosed
    )
    .map(getProjectIdToLabelOption({ excluded: true, paneId }));

  return [...documents, ...projects];
};

const getStatusIdToStatusOption =
  ({ excluded, paneId }: { excluded: boolean; paneId: ChunkDestination }) =>
  (statusId: string): CommandPaletteOption => {
    const status = store.getState().work.statuses.dict[statusId];
    return {
      id: statusId,
      icon: <StatusDisplay showName={false} statusId={statusId} />,
      name: status.name,
      action: () =>
        createFilterHandler(excluded ? "excludedStatuses" : "statuses")(
          status.id,
          paneId
        ),
      entity: status,
      optionType: "command",
      closeAfter: false,
      multiselect: true,
      checked() {
        const filters = store.getState().filter.currentDict[paneId].filters;
        if (excluded) return filters.excludedStatuses.includes(statusId);
        return filters.statuses.includes(statusId);
      },
    };
  };

export const getFilterStatusOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("excludedStatuses", paneId);
  return store
    .getState()
    .work.statuses.statusArray.filter(
      (id: string) => !reciprocalColl.includes(id)
    )
    .map(getStatusIdToStatusOption({ excluded: false, paneId }));
};

export const getFilterStatusNotOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("statuses", paneId);
  return store
    .getState()
    .work.statuses.statusArray.filter(
      (id: string) => !reciprocalColl.includes(id)
    )
    .map(getStatusIdToStatusOption({ excluded: true, paneId }));
};

const getPriorityToPriorityOption =
  ({ excluded, paneId }: { excluded: boolean; paneId: ChunkDestination }) =>
  (priority: string, index: number): CommandPaletteOption => ({
    id: String(index + 1),
    icon: <PriorityDisplay priorityLevel={index + 1} showName={false} />,
    name: priority,
    optionType: "command",
    action: () =>
      createFilterHandler<number>(
        excluded ? "excludedPriorities" : "priorities"
      )(index + 1, paneId),
    closeAfter: false,
    multiselect: true,
    checked() {
      const filters = store.getState().filter.currentDict[paneId].filters;
      if (excluded) return filters.excludedPriorities.includes(index + 1);
      return filters.priorities.includes(index + 1);
    },
  });

export const getFilterPriorityOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl<string>(
    "excludedPriorities",
    paneId
  );
  return priorities
    .map(getPriorityToPriorityOption({ excluded: false, paneId }))
    .filter((opt) => !reciprocalColl.includes(opt.id));
};

export const getFilterPriorityNotOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl<string>("priorities", paneId);
  return priorities
    .map(getPriorityToPriorityOption({ excluded: true, paneId }))
    .filter((opt) => !reciprocalColl.includes(opt.id));
};

const getCycleToCycleOption =
  ({ excluded, paneId }: { excluded: boolean; paneId: ChunkDestination }) =>
  (cycle: Cycle): CommandPaletteOption => ({
    id: cycle.id,
    name: cycle.name,
    optionType: "command",
    icon: <ThunderboltTwoTone />,
    action: () =>
      createFilterHandler(excluded ? "excludedCycles" : "cycles")(
        cycle.id,
        paneId
      ),
    closeAfter: false,
    multiselect: true,
    checked() {
      const filters = store.getState().filter.currentDict[paneId].filters;
      if (excluded) return filters.excludedCycles.includes(cycle.id);
      return filters.cycles.includes(cycle.id);
    },
  });

export const getFilterCycleOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("excludedCycles", paneId);

  return Object.values(store.getState().work.sections)
    .map(getCycleToCycleOption({ excluded: false, paneId }))
    .filter((opt) => !reciprocalColl.includes(opt.id));
};

export const getFilterCycleNotOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("cycles", paneId);

  return Object.values(store.getState().work.sections)
    .map(getCycleToCycleOption({ excluded: true, paneId }))
    .filter((opt) => !reciprocalColl.includes(opt.id));
};

const getParentIdToLabelOption =
  ({ excluded, paneId }: { excluded: boolean; paneId: ChunkDestination }) =>
  (id: string): CommandPaletteOption => {
    const workItem = store.getState().work.dict[id];

    return {
      name: getNameFromContainer(workItem, ContainerTypes.WORK),
      action: () =>
        createFilterHandler(excluded ? "excludedParents" : "parents")(
          id,
          paneId
        ),
      entity: workItem,
      optionType: "project",
      id: id,
      closeAfter: false,
      icon: getIconFromContainerType(ContainerTypes.WORK, workItem.workType),
      multiselect: true,
      checked() {
        const filters = store.getState().filter.currentDict[paneId].filters;
        if (excluded) return filters.excludedParents.includes(id);
        return filters.parents.includes(id);
      },
    };
  };

export const getFilterParentOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("excludedParents", paneId);

  const workState = store.getState().work;
  return [
    ...Object.values(workState.allProjectIds),
    ...Object.values(workState.allInitiativeIds),
    ...Object.values(workState.allTaskIds),
  ]
    .map(getParentIdToLabelOption({ excluded: false, paneId }))
    .filter((opt) => !reciprocalColl.includes(opt.id));
};

export const getFilterParentNotOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("parents", paneId);
  const workState = store.getState().work;

  return [
    ...Object.values(workState.allProjectIds),
    ...Object.values(workState.allInitiativeIds),
    ...Object.values(workState.allTaskIds),
  ]
    .map(getParentIdToLabelOption({ excluded: true, paneId }))
    .filter((opt) => !reciprocalColl.includes(opt.id));
};

const getGroupToGroupOptions =
  ({ excluded, paneId }: { excluded: boolean; paneId: ChunkDestination }) =>
  (group: IGroup): CommandPaletteOption => ({
    id: group.id,
    optionType: "command",
    icon: <Icon component={GroupsIcon} />,
    name: group.name,
    action: () =>
      createFilterHandler(excluded ? "excludedGroups" : "groups")(
        group.id,
        paneId
      ),
    closeAfter: false,
    multiselect: true,
    checked() {
      const filters = store.getState().filter.currentDict[paneId].filters;
      if (excluded) return filters.excludedGroups.includes(group.id);
      return filters.groups.includes(group.id);
    },
  });

export const getFilterGroupOptions = (
  paneId: ChunkDestination
): CommandPaletteOption[] => {
  const reciprocalColl = getReciprocalColl("excludedGroups", paneId);
  const storeData = store.getState();
  return storeData.groups.userGroups
    .map((id: string) => {
      const group = storeData.groups.dict[id];
      return getGroupToGroupOptions({ excluded: false, paneId })(group);
    })
    .filter((opt) => !reciprocalColl.includes(opt.id));
};

export const getFilterGroupNotOptions = (paneId: ChunkDestination) => {
  const reciprocalColl = getReciprocalColl("groups", paneId);
  const storeData = store.getState();
  return storeData.groups.userGroups
    .map((id) => {
      const group = storeData.groups.dict[id];
      return getGroupToGroupOptions({ excluded: true, paneId })(group);
    })
    .filter((opt) => !reciprocalColl.includes(opt.id));
};

export const getTurnIntoOptions = () => {
  const paletteContext = store.getState().commandPalette.params;

  if (
    !paletteContext ||
    !paletteContext.blockData ||
    !paletteContext.blockData?.id ||
    !paletteContext.blockRef ||
    !paletteContext.context
  )
    return [] as CommandPaletteOption[];

  const blockData = paletteContext.blockData as Block;
  const context = paletteContext.context as IBlockContext;
  const blockRef =
    paletteContext.blockRef as React.MutableRefObject<HTMLDivElement | null>;

  const options: CommandPaletteOption[] = [
    {
      id: "basicText",
      name: "Basic text",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.text,
          context
        );
      },
      closeAfter: true,
      icon: <FontSizeOutlined />,
      optionType: "command",
    },
    {
      id: "task",
      name: "Task",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.work,
          context,
          {
            workType: WorkTypes.TASK,
          }
        );
      },
      disable: () => {
        if (!context.autosave) return true;

        return false;
      },
      icon: <CheckCircleOutlined />,
      closeAfter: true,
      optionType: "command",
    },
    {
      id: "heading1",
      name: "Heading 1",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.heading1,
          context
        );
      },
      closeAfter: true,
      optionType: "command",
      icon: (
        <ManualSvgIcon pathD="M593.72 103.28l0-41.5c0-11.32-8.49-20.75-18.87-20.28l-191.46 0c-10.37 0-19.33 9.43-19.33 20.28l0 41.5c0 11.32 8.49 20.75 19.33 20.75l44.8 0 0 165.05-243.33 0 0-165.05 45.27 0c10.37 0 19.33-9.43 18.86-20.75l0-41.5c0-11.32-8.49-20.75-18.86-20.28l-191.93 0c-10.37 0-19.33 9.43-18.87 20.28l0 41.5c0 11.32 8.49 20.75 18.87 20.75l44.8 0 0 412.16-44.8 0c-10.37 0-19.33 9.43-18.87 20.74l0 41.5c0 11.32 8.49 20.75 18.87 20.28l191.93 0c10.37 0 19.33-9.43 18.86-20.28l0-41.5c0-11.32-8.49-20.75-18.86-20.74l-45.27 0 0-165.06 243.33 0 0 165.06-45.27 0c-10.37 0-19.33 9.43-18.86 20.74l0 41.5c0 11.32 8.49 20.75 18.86 20.28l191.93 0c10.37 0 19.33-9.43 18.87-20.28l0-41.5c0-11.32-8.49-20.75-18.87-20.74l-44.8 0 0-412.64 44.8 0c10.37 0 19.33-9.43 18.87-20.27z M854.97 613.05l0-377.26-74.98 0-93.37 58.95 0 70.73 86.3-53.76 2.36 0 0 301.34z" />
      ),
    },
    {
      id: "heading2",
      name: "Heading 2",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.heading2,
          context
        );
      },
      closeAfter: true,
      optionType: "command",
      icon: (
        <ManualSvgIcon pathD="M564.03 98.11l0-39.42c0-10.75-8.06-19.71-17.92-19.27l-181.89 0c-9.86 0-18.37 8.96-18.36 19.27l0 39.42c0 10.75 8.06 19.71 18.36 19.71l42.56 0 0 156.8-231.16 0 0-156.8 43 0c9.86 0 18.37-8.96 17.92-19.71l0-39.42c0-10.75-8.06-19.71-17.92-19.27l-182.33 0c-9.86 0-18.37 8.96-17.92 19.27l0 39.42c0 10.75 8.06 19.71 17.92 19.71l42.56 0 0 391.56-42.56 0c-9.86 0-18.37 8.96-17.92 19.71l0 39.42c0 10.75 8.06 19.71 17.92 19.27l182.33 0c9.86 0 18.37-8.96 17.92-19.27l0-39.42c0-10.75-8.06-19.71-17.92-19.71l-43 0 0-156.8 231.16 0 0 156.8-43 0c-9.86 0-18.37 8.96-17.92 19.71l0 39.42c0 10.75 8.06 19.71 17.92 19.27l182.33 0c9.86 0 18.37-8.96 17.92-19.27l0-39.42c0-10.75-8.06-19.71-17.92-19.71l-42.56 0 0-392 42.56 0c9.86 0 18.37-8.96 17.92-19.27z M866.88 582.4l0-61.82-150.53 0 0-2.69 52.42-51.07c24.64-22.4 43.9-41.66 57.34-57.8 13.44-16.13 22.85-30.46 28.23-43.9 5.38-12.99 8.06-26.43 8.06-39.87 0-20.61-5.38-38.98-16.13-55.11-10.75-16.13-25.54-28.67-44.35-37.63-19.26-8.96-41.66-13.44-67.2-13.44-25.09 0-47.04 4.93-66.3 14.34-19.26 9.41-34.05 22.85-44.36 39.87-10.75 17.47-16.13 37.63-16.12 60.93l0 0 72.12 0c0-11.65 2.24-21.5 6.28-29.57 4.48-8.06 10.75-14.78 19.26-18.82 8.06-4.48 17.92-6.72 28.67-6.72 10.3 0 19.71 2.24 28.23 6.28 8.06 4.03 14.78 9.86 19.26 17.47 4.93 7.62 7.17 16.58 7.17 27.33 0 9.41-1.79 18.37-5.83 26.43-4.03 8.06-9.41 16.58-16.57 24.64-7.62 8.51-16.58 17.92-27.33 28.22l0 0-127.68 118.27 0 54.66 255.36 0z" />
      ),
    },
    {
      id: "heading3",
      name: "Heading 3",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.heading3,
          context
        );
      },
      closeAfter: true,
      optionType: "command",
      icon: (
        <ManualSvgIcon pathD="M564.03 98.11l0-39.42c0-10.75-8.06-19.71-17.92-19.27l-181.89 0c-9.86 0-18.37 8.96-18.36 19.27l0 39.42c0 10.75 8.06 19.71 18.36 19.71l42.56 0 0 156.8-231.16 0 0-156.8 43 0c9.86 0 18.37-8.96 17.92-19.71l0-39.42c0-10.75-8.06-19.71-17.92-19.27l-182.33 0c-9.86 0-18.37 8.96-17.92 19.27l0 39.42c0 10.75 8.06 19.71 17.92 19.71l42.56 0 0 391.56-42.56 0c-9.86 0-18.37 8.96-17.92 19.71l0 39.42c0 10.75 8.06 19.71 17.92 19.27l182.33 0c9.86 0 18.37-8.96 17.92-19.27l0-39.42c0-10.75-8.06-19.71-17.92-19.71l-43 0 0-156.8 231.16 0 0 156.8-43 0c-9.86 0-18.37 8.96-17.92 19.71l0 39.42c0 10.75 8.06 19.71 17.92 19.27l182.33 0c9.86 0 18.37-8.96 17.92-19.27l0-39.42c0-10.75-8.06-19.71-17.92-19.71l-42.56 0 0-392 42.56 0c9.86 0 18.37-8.96 17.92-19.27z M744.13 587.33c26.88 0 50.62-4.48 71.23-13.89 20.61-8.96 37.18-21.5 48.83-37.18 11.65-15.68 17.92-34.05 17.47-54.66 0-22.4-6.72-40.77-20.6-55.55-13.89-14.78-34.05-24.19-60.04-27.33l0 0 0-2.69c20.16-3.58 36.29-12.1 48.84-25.53 12.54-13.44 18.82-30.02 18.81-50.63 0-18.82-4.93-35.84-15.68-51.07-10.3-15.23-24.64-27.33-43.01-36.29-18.37-8.96-40.32-13.44-64.96-13.44-24.64 0-46.59 4.48-65.85 13.44-19.26 8.96-34.94 21.06-46.15 37.19-11.65 15.68-17.47 34.05-17.92 54.2l0 0 72.58 0c0.45-8.96 3.14-17.02 8.51-23.74 4.93-6.72 12.1-12.1 20.16-15.68 8.51-3.58 17.92-5.38 28.23-5.38 10.3 0 19.26 1.79 26.88 5.83 7.62 4.03 13.44 9.41 17.92 16.13 4.48 6.72 6.27 14.78 6.27 23.74 0 9.41-2.24 17.92-7.62 24.64-4.93 7.17-11.65 12.54-20.61 16.58-8.96 4.03-18.82 5.82-30.01 5.82l0 0-33.6 0 0 55.55 33.6 0c13.44 0 25.09 2.24 34.94 6.27 9.86 4.03 17.47 9.86 22.85 17.48 5.38 7.62 8.06 15.68 8.06 25.53 0 9.41-2.69 17.92-7.61 25.09-4.93 7.17-12.1 12.99-21.06 17.02-8.96 4.03-19.26 6.27-30.46 5.83-11.2 0-21.06-1.79-30.02-5.38-8.96-3.58-16.13-8.51-21.05-15.23-5.38-6.72-8.06-14.34-8.96-22.85l0 0-76.16 0c0.45 21.06 6.27 39.42 17.92 55.11 11.65 16.13 27.78 28.22 48.38 37.63 20.16 8.96 43.46 13.44 69.89 13.44z" />
      ),
    },
    {
      id: "checkboxes",
      name: "Checkboxes",
      action: () => {
        batch(() => {
          checkCheckboxCases(blockData.id, context);
          generalBlockRefocus();
        });
      },
      closeAfter: true,
      icon: <CheckSquareOutlined />,
      optionType: "command",
    },
    {
      id: "quote",
      name: "Quote",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.quote,
          context
        );
        generalBlockRefocus();
      },
      closeAfter: true,
      optionType: "command",
      icon: <PictureOutlined />,
    },
    {
      id: "callout",
      name: "Callout",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.callout,
          context
        );
        generalBlockRefocus();
      },
      closeAfter: true,
      optionType: "command",
      icon: <PicCenterOutlined />,
    },
    {
      id: "pdf",
      name: "PDF",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.pdf,
          context
        );
        generalBlockRefocus();
      },
      disable: () => {
        if (!context.autosave) return true;

        return false;
      },
      closeAfter: true,
      optionType: "command",
      icon: <FilePdfOutlined />,
    },
    {
      id: "code",
      name: "Code",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.code,
          context
        );
        generalBlockRefocus();
      },
      closeAfter: true,
      optionType: "command",
      icon: <CodeOutlined />,
    },
    {
      id: "image",
      name: "Image",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.image,
          context
        );
      },
      closeAfter: true,
      optionType: "command",
      icon: <PictureOutlined />,
    },
    {
      id: "project",
      name: "Project",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.work,
          context,
          {
            workType: WorkTypes.PROJECT,
          }
        );
      },

      disable: () => {
        if (!context.autosave) return true;
        if (context.container.type === ContainerTypes.PROJECT) {
          const id: string = context.container.id;
          const workItem = prevState.value.work.dict[id];
          if (workItem.workType !== WorkTypes.INITIATIVE) return true;
        }

        return false;
      },
      closeAfter: true,
      optionType: "command",
      icon: <FileDoneOutlined />,
    },
    {
      id: "goal",
      name: "Goal",
      action: () => {
        changeBlockOrSelectionType(
          blockData.id,
          blockRef,
          LineType.work,
          context,
          {
            workType: WorkTypes.INITIATIVE,
          }
        );
      },
      disable: () => {
        if (
          !context.autosave ||
          context.container.type === ContainerTypes.PROJECT
        )
          return true;

        return false;
      },
      closeAfter: true,
      optionType: "command",
      icon: <AimOutlined />,
    },
  ];

  return options.filter((option) => !option.disable || !option.disable());
};

export const getbaseSelectedBlocks = (): CommandPaletteOption[] => {
  const canEditEntity = roleApi.checkAbility({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });
  const context = store.getState().commandPalette.params.context;

  const options: CommandPaletteOption[] = [
    {
      id: "turnInto",
      name: "Turn Into...",
      disable: () => !canEditEntity,
      action: () => setCommandPaletteContext(CommandPaletteContext.TURN_INTO),
      optionType: "command",
    },
    {
      id: "moveTo",
      name: "Move to...",
      action: () => setCommandPaletteContext(CommandPaletteContext.MOVE_TO),
      disable: () => {
        if (!canEditEntity) {
          return true;
        }
        if (context) {
          if (!context.autosave) return true;
        }
        return false;
      },
      optionType: "command",
    },
    {
      id: "createSnippet",
      name: "Create snippet from blocks...",
      action: () => snippetsApi.copyBlockAndOpenSnippet(),
      closeAfter: true,
      disable: () => {
        if (!canEditEntity) {
          return true;
        }
        if (context) {
          if (!context.autosave) return true;
        }
        return false;
      },
      optionType: "command",
    },
  ];
  return options;
};

export const getMultipleProjectsSelectedOptions = () => {
  const baseSelectedBlocksOptions = getbaseSelectedBlocks();
  const workSelectedOptions = getProjectDetailOptions();
  return [...baseSelectedBlocksOptions, ...workSelectedOptions];
};

export const getWorkViewOptions = () => {
  const selectedIds = store.getState().commandPalette.params?.selectedItemIds;
  if (selectedIds && selectedIds.length > 0) {
    return [...getProjectDetailOptions()];
  } else {
    return [...getGeneralModeOptions()];
  }
};

export const getMultipleBlocksSelectedInDocumentOptions = () => {
  const baseSelectedBlocksOptions = getbaseSelectedBlocks();
  const generalOptions = getGeneralModeOptions();
  return [...baseSelectedBlocksOptions, ...generalOptions];
};

export const getMultipleBlocksSelectedInProjectOptions = () => {
  const baseSelectedBlocksOptions = getbaseSelectedBlocks();
  return baseSelectedBlocksOptions;
};

export const getGeneralModeOptions = () => {
  const baseId = store.getState().workspace.id;
  const canEditEntity = roleApi.checkAbility({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  const options: CommandPaletteOption[] = [
    {
      id: "open",
      name: "Open...",
      action: () => setCommandPaletteContext(CommandPaletteContext.OPEN),
      optionType: "command",
    },
    {
      id: "newPage",
      name: "New tag",
      disable: () => !canEditEntity,
      action: (event: any) => createAndSetNewPage(event, baseId),
      optionType: "command",
      closeAfter: true,
    },
    {
      id: "newTask",
      name: "New task...",
      disable: () => !canEditEntity,
      action: () => openNewTaskModal({ type: INewTaskCreationModes.new }),
      optionType: "command",
      closeAfter: true,
    },
    {
      id: "newProject",
      name: "New project",
      disable: () => !canEditEntity,
      optionType: "command",
      action: (event: any) =>
        createAndSetNewProject(event, baseId, ChunkDestination.primary),
      closeAfter: true,
    },
    {
      id: "newGoal",
      name: "New goal",
      action: (event: any) =>
        createAndSetNewInitiative(event, baseId, ChunkDestination.primary),
      disable: () => !canEditEntity,
      optionType: "command",
      closeAfter: true,
    },
    {
      id: "newTaskTemplate",
      name: "New task template...",
      action: () => createTemplate(WorkTypes.TASK),
      disable: () => !canEditEntity,
      optionType: "command",
      closeAfter: true,
    },
    // {
    //   id: "newProjectTemplate",
    //   name: "New project template...",
    //   action: () => createTemplate(WorkTypes.PROJECT),
    //   disable: () => !canEditEntity,
    //   optionType: "command",
    //   closeAfter: true,
    // },
    // {
    //   id: "newGoalTemplate",
    //   name: "New goal template...",
    //   action: () => createTemplate(WorkTypes.INITIATIVE),
    //   disable: () => !canEditEntity,
    //   optionType: "command",
    //   closeAfter: true,
    // },
    {
      id: "newSnippet",
      name: "New snippet",
      action: () => snippetsApi.createAndOpenSnippet(),
      disable: () => !canEditEntity,
      optionType: "command",
      closeAfter: true,
    },
    // {
    //   id: "filter",
    //   name: "Filter...",
    //   action: (event: any) =>
    //     setCommandPaletteContext(CommandPaletteContext.FILTERS_GENERAL),
    //   closeAfter: false,
    //   optionType: "command",
    // },
  ];
  return options;
};

export const getOpenModeOptions = (afterAction?: () => void) => {
  const storeData = store.getState();

  const workDict = storeData.work.dict;
  const groupDict = storeData.groups.dict;
  const groupIds = storeData.groups.userGroups;

  const projectIds = storeData.work.allProjectIds;
  const taskIds = storeData.work.allTaskIds;
  const initiativeIds = storeData.work.allInitiativeIds;

  const documentsArray = storeData.pages.ids;
  const documentsObj = storeData.pages.dict;

  const base = storeData.workspace;

  const customWorkViews = storeData.customWork.dict;
  const userRole = store.getState().client.roleType;

  const getNavToBaseView =
    (slug: string, onShift: () => void) => (event: any) => {
      if (event.shiftKey) {
        event.preventDefault();
        onShift();
      } else {
        locationSubject.next(`/${(base as any).slug}/${slug}`);
      }
    };

  return () => {
    const builtInViews =
      userRole !== UserRole.GUEST
        ? [
            ...(!base.showWeekly
              ? []
              : [
                  {
                    name: "Weekly",
                    action: getNavToBaseView(`weekly`, () =>
                      navigationApi.openSplitView({
                        viewName: GeneralViewsNames.TheWeekly,
                      })
                    ),
                    link: `weekly`,
                    icon: <StarTwoTone />,
                  },
                ]),
            ...groupIds.map((groupId) => {
              const group = groupDict[groupId];

              return {
                name: `${group.name} Docs`,
                action: getNavToBaseView(`group/${group.slug}/docs/`, () =>
                  navigationApi.openSplitView({
                    viewName: GroupGeneralViews.Notes,
                    groupSlug: group.slug,
                  })
                ),
                link: `group/${group.slug}/docs/`,
                icon: <FormOutlined />,
              };
            }),
            {
              name: "My Work",
              action: getNavToBaseView(`my-work`, () =>
                navigationApi.openSplitView({
                  viewName: GeneralViewsNames.MyTasks,
                  taskViewMode: TasksViewModes.MyTasks,
                })
              ),
              link: `my-work`,
              icon: <AuditOutlined />,
            },
            {
              name: "Tags",
              action: getNavToBaseView(`tags`, () =>
                navigationApi.openSplitView({
                  viewName: GeneralViewsNames.Wiki,
                })
              ),
              link: "tags",
              icon: <FileTextTwoTone />,
            },
            {
              name: "Roadmap",
              action: getNavToBaseView(`roadmap`, () =>
                navigationApi.openSplitView({
                  viewName: GeneralViewsNames.Roadmap,
                })
              ),
              link: "roadmap",
              icon: <FileDoneOutlined />,
            },
            {
              name: "Views",
              action: getNavToBaseView(`views`, () =>
                navigationApi.openSplitView({
                  viewName: GeneralViewsNames.Views,
                })
              ),
              link: "views",
              icon: <ControlTwoTone />,
            },
          ].map((view: Partial<CommandPaletteOption>) => ({
            ...view,
            closeAfter: true,
            optionType: "built-in",
          }))
        : [
            {
              name: "My Work",
              action: getNavToBaseView(`assigned-to-me`, () =>
                navigationApi.openSplitView({
                  viewName: GeneralViewsNames.MyTasks,
                  taskViewMode: TasksViewModes.MyTasks,
                })
              ),
              link: `my-work`,
              icon: <CheckCircleTwoTone />,
            },
            {
              name: "Roadmap",
              action: getNavToBaseView(`roadmap`, () =>
                navigationApi.openSplitView({
                  viewName: GeneralViewsNames.Roadmap,
                })
              ),
              link: "roadmap",
              icon: <FileDoneOutlined />,
            },
            {
              name: "Tasks",
              action: getNavToBaseView(`tasks`, () =>
                navigationApi.openSplitView({
                  viewName: GeneralViewsNames.Tasks,
                  taskViewMode: TasksViewModes.Tasks,
                })
              ),
              link: "tasks",
              icon: <CheckCircleTwoTone />,
            },
          ].map((view: Partial<CommandPaletteOption>) => ({
            ...view,
            closeAfter: true,
            optionType: "built-in",
          }));

    const userViews: CommandPaletteOption[] = Object.values(
      customWorkViews
    ).map((view) => ({
      id: view.id,
      name: view.name,
      action: getNavToBaseView(`view/${view.id}`, () =>
        navigationApi.openSplitView({
          viewName: GeneralViewsNames.Tasks,
          taskViewMode: TasksViewModes.Tasks,
        })
      ),
      icon: <ControlTwoTone />,
      closeAfter: true,
      optionType: "user-view",
      entity: view,
    }));

    const documents = documentsArray.map((id: string) => ({
      name: stripHtml(getHtml(documentsObj[id].nameValue)),
      action: (event: any) => {
        if (event.shiftKey) {
          navigationApi.openSplitView({
            viewName: ViewNames.Detail,
            containerId: id,
            containerType: ContainerTypes.DOCUMENT,
          });
        } else {
          locationSubject.next(`/${(base as any).slug}/tags/${id}`);
        }
        if (afterAction) afterAction();
      },
      entity: documentsObj[id],
      icon: <FileTextTwoTone />,
      closeAfter: true,
      optionType: "document",
    }));

    const projects = [...projectIds, ...taskIds, ...initiativeIds].map((id) => {
      const project = workDict[id];
      return {
        name: getNameFromContainer(project, ContainerTypes.PROJECT),
        action: (event: any) => {
          if (event.shiftKey) {
            navigationApi.openSplitView({
              viewName: ViewNames.Detail,
              containerId: id,
              containerType: ContainerTypes.WORK,
            });
          } else {
            locationSubject.next(getCorrectLink(base, project));
          }

          if (afterAction) afterAction();
        },
        entity: workDict[id],
        closeAfter: true,
        optionType: "project",
        icon:
          project.workType === WorkTypes.TASK ? (
            <CheckCircleTwoTone />
          ) : (
            <FileDoneOutlined />
          ),
      };
    });
    return [
      ...builtInViews,
      ...userViews,
      ...documents,
      ...projects,
    ] as CommandPaletteOption[];
  };
};

export const getRecentsAsViewOptions = (): CommandPaletteOption[] => {
  const state = store.getState();
  const baseId = state.workspace.id;
  if (!baseId) return [];

  const hasRecentlyOpened =
    state.recentlyOpened.dict && state.recentlyOpened.dict[baseId];
  const recentlyOpened = hasRecentlyOpened
    ? state.recentlyOpened.dict[baseId]
    : [];

  const viewOptions: CommandPaletteOption[] = recentlyOpened.map((item) => {
    let container: any;
    let containerType = ContainerTypes.DOCUMENT;

    if (item.navigationChunk.entity) {
      containerType =
        item.navigationChunk.entity.containerType ?? ContainerTypes.DOCUMENT;

      container = getContainerByType({
        containerId: item.navigationChunk.entity.containerId,
        containerType,
      });
    }

    const title = () => {
      const title = getNameFromContainer(
        container,
        containerType ?? ContainerTypes.DOCUMENT,
        false
      );
      if (!title || title === "") {
        if (item.name) {
          return item.name;
        }
      }

      return title;
    };

    return {
      id: item.navigationChunk.entity?.containerId ?? "",
      action: (event: any) => {
        if (event.shiftKey) {
          navigationApi.openSplitViewNavigationChunk(item.navigationChunk);
        } else {
          navigationApi.navigateMain(item.navigationChunk);
        }
      },
      name: title(),
      optionType: "document",
      entity: container,
      icon: getIconFromNavigationChunk(item.navigationChunk),
      closeAfter: true,
    };
  });

  return viewOptions;
};

export const getMoveToOptions = (
  canEditEntity: boolean
): CommandPaletteOption[] => {
  const pagesReducerState = store.getState().pages;
  const dict = pagesReducerState.dict;
  const documentsArray = pagesReducerState.ids;

  const workReducerState = store.getState().work;
  const workDict = workReducerState.dict;
  const allWorkIds = [
    ...workReducerState.allInitiativeIds,
    ...workReducerState.allProjectIds,
    ...workReducerState.allTaskIds,
  ];

  const documents: CommandPaletteOption[] = documentsArray.map((id) => ({
    id,
    name: stripHtml(getHtml(dict[id].nameValue)),
    icon: getIconFromContainerType(ContainerTypes.DOCUMENT),
    action: (event: any) => {
      changeContainer({
        containerId: id,
        containerType: ContainerTypes.DOCUMENT,
      });
    },
    entity: dict[id],
    optionType: "document",
    closeAfter: true,
    disabled: !canEditEntity,
  }));

  let projects: CommandPaletteOption[] = allWorkIds.map((id) => {
    const entity = workDict[id];
    return {
      id,
      icon: getIconFromContainerType(ContainerTypes.WORK),
      name: getNameFromContainer(entity, ContainerTypes.WORK),
      action: (event: any) => {
        changeContainer({
          containerId: id,
          containerType: ContainerTypes.PROJECT,
        });
      },
      optionType: "project",
      entity,
      closeAfter: true,
      disabled: !canEditEntity,
    };
  });

  projects = projects.filter((project) => !project.entity.isClosed);

  return [...documents, ...projects];
};

export const getProjectDetailOptions = () => {
  const canEditEntity = roleApi.checkAbility({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  const options: CommandPaletteOption[] = [
    {
      id: "changeStatus",
      name: "Change status...",
      disable: () => !canEditEntity,
      action: () => setCommandPaletteContext(CommandPaletteContext.STATUS),
      optionType: "command",
    },
    {
      id: "changepriority",
      name: "Change priority...",
      disable: () => !canEditEntity,
      action: () => setCommandPaletteContext(CommandPaletteContext.PRIORITY),
      optionType: "command",
    },
    {
      id: "changeAssign",
      name: "Assign to...",
      disable: () => !canEditEntity,
      action: () => setCommandPaletteContext(CommandPaletteContext.ASSIGNEE),
      optionType: "command",
    },
    {
      id: "changeDueDate",
      name: "Change due date...",
      disable: () => !canEditEntity,
      action: () => setCommandPaletteContext(CommandPaletteContext.DUE_DATE),
      optionType: "command",
    },
    {
      id: "changeParent",
      name: "Change parent...",
      disable: () => !canEditEntity,
      action: () => setCommandPaletteContext(CommandPaletteContext.PARENT),
      optionType: "command",
    },
    {
      id: "changeCycle",
      name: "Change sprint...",
      disable: () => !canEditEntity || !canChangeCycles(),
      action: () =>
        setCommandPaletteContext(CommandPaletteContext.WORK_SECTION),
      optionType: "command",
    },
  ];

  return [...options, ...getGeneralModeOptions()];
};

export const getDateFilterAction = (
  context: CommandPaletteDateFilterContextTypes,
  paneId: ChunkDestination
) => {
  const filters = store.getState().filter.currentDict?.[paneId]?.filters;

  const getFilterName = (
    ctx: CommandPaletteContext
  ): "createdDate" | "dueDate" | "closedDate" => {
    if (ctx.includes("Created")) {
      return "createdDate";
    } else if (ctx.includes("Closed")) {
      return "closedDate";
    } else {
      return "dueDate";
    }
  };

  const filterName = getFilterName(context);

  const isBefore = context.includes("Before");
  const isAfter = context.includes("After");
  const isExactly = context.includes("Exactly");
  const isFiltered = context.includes("Filter");

  const isUnfilteredDueDate = filterName === "dueDate" && !isFiltered;
  const defaultValueProperty =
    isBefore || isUnfilteredDueDate
      ? "before"
      : isAfter
      ? "after"
      : isExactly
      ? "exactly"
      : "not";

  const defaultValue =
    filters && filters[filterName] && filters[filterName][defaultValueProperty]
      ? moment(filters[filterName][defaultValueProperty])
      : undefined;

  return {
    defaultValue,
    onChange: handleFilterDueDateChange,
  };
};
