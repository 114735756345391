import React, { useMemo, useRef } from "react";
import { batch, useStore } from "react-redux";
import styles from "./workItem/workItem.module.scss";
import {
  WorkTypes,
  ContainerTypes,
  CommandPaletteContext,
  Abilities,
  ViewNames,
} from "utilities/types";
import { useHistory } from "react-router-dom";
import WorkLabels from "components/WorkLabels";
import { ChunkDestination } from "utilities/stateTypes";
import StatusDisplay from "components/StatusDisplay";
import PriorityDisplay from "components/PriorityDisplay";
import * as actionTypes from "store/actions";
import { Checkbox } from "antd";
import ParentItemTitle from "./workItem/ParentItemTitle";
import CycleTitle from "./workItem/CycleTitle";
import DueDateDisplay from "./DueDateDisplay";
import { pick, partial } from "lodash";
import store from "store/storeExporter";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import { useAbilityChecker } from "editor/utils/customHooks";
import { BehaviorSubject } from "rxjs";
import { getCorrectLink } from "utilities/linkUtilities";
import {
  getCorrectPrefixForWorkItem,
  getGroupsFromEntity,
} from "modules/containerHelpers";
import navigationApi from "clientApi/navigationApi";
import UserDisplay from "./UserDisplay";
import {
  openCommandPalette,
  useCommandPaletteShower,
} from "store/reducers/commandPaletteReducer";

interface IWorkItemProps {
  itemId: any;
  index: number;
  actions: {
    handleTaskClick: (id: string) => void;
    selectWork: (data: { taskId: string; index: number }) => void;
    selectRange: (end: { index: number; id: string }) => void;
  };
  type?: string;
  disableNavigation?: boolean;
  isWorkBlock?: boolean;
  destination?: string;
  canSelect?: boolean;
  selectedTasksSubject?: BehaviorSubject<string[]>;
  isSelected?: boolean;
}

const selectOneEntity = (id: string, entityType: ContainerTypes) =>
  store.dispatch({
    type: actionTypes.SELECT_ONE_ENTITY,
    entityType,
    ids: [id],
  });

export const LinkIcon: React.FC<any> = () => {
  return (
    <div style={{ width: "16px", display: "contents" }}>
      <svg
        style={{
          height: "25px",
          width: "25px",
          paddingTop: "4px",
          opacity: ".3",
        }}
      >
        <path d="M13.646 10.879V3.084c0-.473-.298-.788-.78-.788l-7.794.016c-.465 0-.764.34-.764.73 0 .39.34.723.73.723h2.466l3.951-.15-1.502 1.329-7.413 7.429a.733.733 0 00-.232.506c0 .39.348.764.755.764.19 0 .365-.075.515-.224l7.42-7.43 1.337-1.502-.158 3.777v2.648c0 .382.332.73.739.73.39 0 .73-.323.73-.763z"></path>
      </svg>
    </div>
  );
};

function WorkItem(props: IWorkItemProps): JSX.Element {
  const { isWorkBlock } = props;
  const itemRef = useRef<HTMLDivElement | null>(null);
  const item = useShallowSelector((store) => store.work.dict[props.itemId]);

  const workItemClickHandler = useWorkItemClickHandler(
    pick(props, [
      "selectedTasksSubject",
      "actions",
      "index",
      "disableNavigation",
      "destination",
    ])
  );

  const handleWorkItemClick = partial(workItemClickHandler, item);

  if (!item) return <React.Fragment></React.Fragment>;

  return (
    <div
      ref={itemRef}
      className={`${styles.workItem} ${
        item.isClosed ? styles.workItem_closed : ""
      } ${isWorkBlock && styles.workItem__workBlock}
      ${props.isSelected && styles.workItem_selected}
      `}
      onClick={(e) => handleWorkItemClick(e)}
    >
      <div className={styles.workItemCheckboxContainer}>
        {props.canSelect && (
          <Checkbox
            className={styles.workItemCheckbox}
            onClick={(e) => e.stopPropagation()}
            checked={props.isSelected}
            onChange={(e) => {
              if (
                e.nativeEvent.shiftKey &&
                props.selectedTasksSubject &&
                props.selectedTasksSubject.value.length > 0
              ) {
                props.actions.selectRange({
                  index: props.index,
                  id: props.itemId,
                });
              } else {
                props.actions.selectWork({
                  taskId: props.itemId,
                  index: props.index,
                });
              }
            }}
          />
        )}
      </div>
      <ItemContent {...props} />
    </div>
  );
}

export function useWorkItemClickHandler({
  selectedTasksSubject,
  actions,
  index,
  disableNavigation,
  destination,
}: Pick<
  IWorkItemProps,
  "selectedTasksSubject" | "actions" | "disableNavigation" | "destination"
> &
  Partial<Pick<IWorkItemProps, "index">>) {
  const store = useStore();
  const history = useHistory();

  const { handleTaskClick } = actions;

  return (item: any, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const base = store.getState().workspace;
    if (
      selectedTasksSubject &&
      selectedTasksSubject.value.length > 0 &&
      index !== undefined
    ) {
      if (e.shiftKey) {
        actions.selectRange({
          index: index,
          id: item.id,
        });
      } else {
        actions.selectWork({
          taskId: item.id,
          index: index,
        });
      }
      return;
    }

    if (disableNavigation) return;

    if (destination) {
      if (destination === "primary") {
        if (e.shiftKey) {
          return navigationApi.openSplitView({
            viewName: ViewNames.Detail,
            containerId: item.id,
            containerType: ContainerTypes.WORK,
          });
        }

        if (handleTaskClick) return handleTaskClick(item.id);
        else {
          return history.push(getCorrectLink(base, item));
        }
      }

      if (destination === ChunkDestination.secondary) {
        if (e.shiftKey) {
          return history.push(getCorrectLink(base, item));
        }
        if (handleTaskClick) return handleTaskClick(item.id);
        else {
          return navigationApi.openSplitView({
            viewName: ViewNames.Detail,
            containerId: item.id,
            containerType: ContainerTypes.WORK,
          });
        }
      }
    }

    if (e.shiftKey) {
      return navigationApi.openSplitView({
        viewName: ViewNames.Detail,
        containerId: item.id,
        containerType: ContainerTypes.WORK,
      });
    }

    if (handleTaskClick) {
      return handleTaskClick(item.id);
    } else {
      return history.push(getCorrectLink(base, item));
    }
  };
}

const ItemContent: React.FC<IWorkItemProps> = (props) => {
  const item = useShallowSelector((store) => store.work.dict[props.itemId]);
  const showCycles = useShallowSelector((store) => store.workspace.showCycles);
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: getGroupsFromEntity(item, ContainerTypes.PROJECT),
  });

  const getSelectedIds = () => {
    return props.selectedTasksSubject?.value &&
      props.selectedTasksSubject?.value.length > 0
      ? props.selectedTasksSubject.value
      : [props.itemId];
  };

  return useMemo(() => {
    return (
      <>
        <div className={styles.workItem_head}>
          <div
            onClick={(event) => {
              event.stopPropagation();
              if (!canEditEntity) {
                event.preventDefault();
                event.stopPropagation();
                return;
              }
              batch(() => {
                openCommandPalette(CommandPaletteContext.PRIORITY, {
                  selectedItemIds: getSelectedIds(),
                  slectedItemsType: ContainerTypes.WORK,
                });
                selectOneEntity(item.id, ContainerTypes.PROJECT);
              });
            }}
            className={styles.workItem_headPriorityContainer}
          >
            <PriorityDisplay
              showName={false}
              priorityLevel={item.priority}
              size={"large"}
            />
          </div>
          <span className={styles.workItem_headWorkNrLink}>
            {getCorrectPrefixForWorkItem(item)}
          </span>
          <div
            onClick={(event) => {
              event.stopPropagation();
              if (!canEditEntity) {
                event.preventDefault();
                event.stopPropagation();
                return;
              }
              batch(() => {
                openCommandPalette(CommandPaletteContext.STATUS, {
                  selectedItemIds: getSelectedIds(),
                  slectedItemsType: ContainerTypes.WORK,
                });
                selectOneEntity(item.id, ContainerTypes.PROJECT);
              });
            }}
            className={styles.workItem_headStatusContainer}
          >
            {item.statusId && (
              <StatusDisplay
                showName={false}
                statusId={item.statusId}
                workType={item.workType}
                size={"large"}
              />
            )}
          </div>
        </div>
        <div className={styles.workItem_body}>
          <span className={styles.workItemCaption}>{item.name}</span>
          {item.parentId &&
            (item.workType === WorkTypes.TASK ||
              (item.workType === WorkTypes.PROJECT &&
                props.type === "link")) && (
              <ParentItemTitle parentId={item.parentId} />
            )}
        </div>
        <div className={styles.workItem_tail}>
          {item.workSectionId && showCycles && (
            <div
              className={styles.workItem_tail_cycleContainer}
              onClick={(event) => {
                event.stopPropagation();
                if (!canEditEntity) {
                  event.preventDefault();
                  event.stopPropagation();
                  return;
                }
                batch(() => {
                  openCommandPalette(CommandPaletteContext.WORK_SECTION, {
                    selectedItemIds: getSelectedIds(),
                    slectedItemsType: ContainerTypes.WORK,
                  });
                });
                selectOneEntity(item.id, ContainerTypes.PROJECT);
              }}
            >
              <CycleTitle cycleId={item.workSectionId} />
            </div>
          )}
          <div className={styles.workItem_tail_labelContainer}>
            <WorkLabels workItem={item} />
          </div>
          {item.dueDate && (
            <div
              className={styles.workItem_tail_deadlineContainer}
              onClick={(event) => {
                event.stopPropagation();
                if (!canEditEntity) {
                  event.preventDefault();
                  event.stopPropagation();
                  return;
                }
                batch(() => {
                  openCommandPalette(CommandPaletteContext.DUE_DATE, {
                    selectedItemIds: getSelectedIds(),
                    slectedItemsType: ContainerTypes.WORK,
                  });
                  selectOneEntity(item.id, ContainerTypes.PROJECT);
                });
              }}
            >
              <DueDateDisplay dueDate={item.dueDate} isClosed={item.isClosed} />
            </div>
          )}
          <div
            className={styles.workItem_tail_assigneeContainer}
            onClick={(event) => {
              event.stopPropagation();
              if (!canEditEntity) {
                event.preventDefault();
                event.stopPropagation();
                return;
              }
              batch(() => {
                openCommandPalette(CommandPaletteContext.ASSIGNEE, {
                  selectedItemIds: getSelectedIds(),
                  slectedItemsType: ContainerTypes.WORK,
                });
                selectOneEntity(item.id, ContainerTypes.PROJECT);
              });
            }}
          >
            <WorkItemAvatar userId={item.assigneeId} />
          </div>
        </div>
      </>
    );
  }, [item, showCycles, canEditEntity]);
};

interface IWorkItemAvatarProps {
  userId?: string | null;
  editId?: string;
  containerClass?: string;
  containerType?: ContainerTypes;
}

export function WorkItemAvatar({
  userId,
  editId,
  containerClass = "",
  containerType,
}: IWorkItemAvatarProps) {
  const openPalette = useCommandPaletteShower();
  const edit: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openPalette(CommandPaletteContext.ASSIGNEE, {
      selectedItemIds: editId ? [editId] : [],
      slectedItemsType: containerType ?? ContainerTypes.WORK,
    });
  };
  const avatarClassName = useOptionalClassName({
    baseStyle: styles.avatar,
    pairs: [
      {
        extraStyle: containerClass,
        withExtra: Boolean(containerClass),
      },
    ],
  });

  return (
    <div className={avatarClassName} onClick={!editId ? undefined : edit}>
      <UserDisplay id={userId} hideName={true} avatarSize={24} />
    </div>
  );
}

export default WorkItem;
