import Button, { ButtonTypes } from "components/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import {
  PlusOutlined,
  NumberOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import Modal from "../Modal";
import ReactDOM from "react-dom";
import {
  Abilities,
  exerptNumber,
  IContributon,
  IReward,
  SplitType,
  TokenTypes,
  WorkTypes,
} from "utilities/types";
import ClarityInput from "components/ClarityInput";
import UserDisplay from "../UserDisplay";
import styles from "../workReward/workReward.module.scss";
import subworkstyles from "components/subTasks/subTasks.module.scss";
import { useFoldButton } from "components/SubTasks";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import { TriangleIcon } from "screens/base/Main";
import Conditional from "components/Conditional";
import { useAbilityChecker } from "editor/utils/customHooks";
import NetworkSelect from "components/NetworkSelect";
import { getNetworkDefaultToken } from "utilities/web3/connectors";
import TokenSelector, { ITokenConfirmation } from "components/TokenSelector";
import { web3Api } from "clientApi/web3Api";
import { NETWORK_TOKEN_CONTRACT } from "utilities/web3/tokens";
import SponsorGroupDisplay from "components/SponsorProjectDisplay";
import { templatesApi } from "clientApi/templateApi";

const { v4: uuidv4 } = require("uuid");

const TemplateReward: React.FC<{ templateItemId: string }> = ({
  templateItemId,
}) => {
  const reward = useSelector(
    (state: ClarityStore) => state.templates.dict[templateItemId]?.reward,
    shallowEqual
  );

  const { open, toggle } = useFoldButton();
  const [rewardModal, setRewardModal] = useState(false);

  const openRewardModal = () => {
    setRewardModal(true);
  };

  const closeRewardModal = () => {
    setRewardModal(false);
  };

  return (
    <>
      {!reward?.id ? (
        <Button
          className={subworkstyles.emptyStateAddSubtaskBtn}
          icon={<PlusOutlined />}
          buttonType={ButtonTypes.LINK}
          onClick={openRewardModal}
          style={{ marginLeft: "-8px" }}
        >
          Add reward
        </Button>
      ) : (
        <div className={subworkstyles.container}>
          <div className={subworkstyles.subtasksTable}>
            <FoldButton open={open} onClick={toggle} />
            <div className={subworkstyles.headerRow}>
              {!open && (
                <span className={subworkstyles.title}>
                  Reward: {reward.amount} {reward.symbol}
                </span>
              )}
            </div>
            {open && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div
                  className="body medium secondary"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <span>Reward: </span>
                  <span>
                    {reward.amount} {reward.symbol}
                  </span>
                </div>
                <div
                  className="body medium secondary"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <span>Reviewer: </span>{" "}
                  <UserDisplay
                    id={reward.reviewerId}
                    avatarSize={36}
                    customStyles={{ marginRight: "14px" }}
                  />
                </div>
                <div>
                  <Button
                    className={subworkstyles.emptyStateAddSubtaskBtn}
                    icon={<PlusOutlined />}
                    buttonType={ButtonTypes.LINK}
                    onClick={openRewardModal}
                    style={{ marginLeft: "-8px" }}
                  >
                    Edit Reward
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {rewardModal &&
        ReactDOM.createPortal(
          <Modal size="medium" hideModal={() => setRewardModal(false)}>
            <TemplateRewardCard
              key={templateItemId}
              templateItemId={templateItemId}
              editing={reward?.id ? true : false}
              closeRewardModal={closeRewardModal}
            />
          </Modal>,
          document.body
        )}
    </>
  );
};

export const TemplateRewardCard: React.FC<{
  templateItemId: string;
  editing: boolean;
  closeRewardModal: () => void;
}> = ({ templateItemId, editing, closeRewardModal }) => {
  const templateItem = useShallowSelector(
    (state: ClarityStore) => state.templates.dict[templateItemId]
  );

  const baseId = useSelector(
    (state: ClarityStore) => state.workspace.id,
    shallowEqual
  );

  const canManageReward = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  const defaultTokenData = getNetworkDefaultToken(web3Api.networkId);
  const isFirstLoad = useRef(true);

  const lastUsedToken = useShallowSelector(
    (state) => state.client.lastUsedToken
  );

  const generateNewReward = () => ({
    amount: 0,
    id: uuidv4(),
    isPaid: false,
    isAllocationConfirmed: false,
    type: TokenTypes.NATIVE_CURRENCY,
    tokenName: defaultTokenData.name,
    isPublic: false,
    splitType: SplitType.percentage,
    tokenId: "1",
    contributors: [],
    contractAddress: lastUsedToken.contractAddress ?? NETWORK_TOKEN_CONTRACT,
    networkId: lastUsedToken.networkId ?? web3Api.networkId,
    symbol: defaultTokenData.symbol,
    datePaid: null,
    baseId: baseId,
    sponsorGroupId:
      templateItem.workType === WorkTypes.TASK
        ? templateItem.groupId
        : undefined,
  });

  const [reward, setReward] = useState<IReward>(
    templateItem?.reward ?? generateNewReward()
  );

  const handleConfirm = () => {
    templatesApi.update({ reward }, [templateItemId]);
    closeRewardModal();
    return;
  };

  const removeReward = () => {
    templatesApi.update({ reward: undefined }, [templateItemId]);
    closeRewardModal();
  };

  useEffect(() => {
    const checkExistingAllocation = (userId: string) => {
      for (const contributor of reward.contributors) {
        if (contributor.userId === userId) return contributor.amount;
      }
      return 0;
    };

    if (templateItem.reward) {
      if (isFirstLoad.current) {
        return setReward(templateItem.reward);
      }
      const existingReward = { ...templateItem.reward };
      const contributors = existingReward.contributors.map((contributor) => {
        const updatedContributor = { ...contributor };
        updatedContributor.amount = checkExistingAllocation(
          updatedContributor.userId
        );
        return updatedContributor;
      });
      setReward({ ...reward, contributors });
    } else {
      const contributors: IContributon[] = [];
      const otherContributors = (templateItem.contributorIds ?? []).filter(
        (id) => id !== templateItem.assigneeId
      );
      const checkFullReward = () => {
        if (reward.splitType === SplitType.percentage) return 100;
        else return reward.amount;
      };
      if (templateItem.assigneeId) {
        const contribution: IContributon = {
          userId: templateItem.assigneeId,
          amount: otherContributors.length > 0 ? 0 : checkFullReward(),
          reason: "assignee",
        };
        contributors.push(contribution);
      }
      otherContributors.forEach((id) => {
        const contribution: IContributon = {
          userId: id,
          amount: checkExistingAllocation(id),
          reason: "contributor",
        };
        contributors.push(contribution);
      });

      setReward({ ...reward, contributors });
    }
  }, [
    templateItem.reward,
    templateItem.contributorIds,
    templateItem.assigneeId,
  ]);

  useEffect(() => {
    if (templateItem.workType === WorkTypes.TASK) {
      setReward({
        ...reward,
        sponsorGroupId: templateItem.groupId,
      });
    }
  }, [templateItem.groupId]);

  useEffect(() => {
    isFirstLoad.current = false;
  }, []);

  const onTokenSelection = useCallback(
    (contractAddress: string) => {
      const updatedReward = { ...reward, contractAddress };
      if (updatedReward.contractAddress === NETWORK_TOKEN_CONTRACT) {
        updatedReward.type = TokenTypes.NATIVE_CURRENCY;
      } else updatedReward.type = TokenTypes.ERC20;
      setReward(updatedReward);
    },
    [reward]
  );

  const onConfirmedToken = useCallback(
    (data: ITokenConfirmation) => {
      const updateClause: Partial<IReward> = {};
      if (data.name) {
        updateClause.tokenName = data.name;
      }
      if (data.symbol) {
        updateClause.symbol = data.symbol;
      }

      if (data.contractAddress) {
        updateClause.contractAddress = data.contractAddress;
      }

      const updatedReward = { ...reward, ...updateClause };

      if (updatedReward.contractAddress === NETWORK_TOKEN_CONTRACT) {
        updatedReward.type = TokenTypes.NATIVE_CURRENCY;
      } else updatedReward.type = TokenTypes.ERC20;
      setReward(updatedReward);
    },
    [reward]
  );

  const updateNetwork = (newNetwork: number) => {
    const defaultTokenData = getNetworkDefaultToken(newNetwork);

    setReward({
      ...reward,
      networkId: newNetwork,
      contractAddress: NETWORK_TOKEN_CONTRACT,
      symbol: defaultTokenData.symbol,
      tokenName: defaultTokenData.name,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <h4 className={styles.header}>
          {editing ? "Edit reward" : "Add reward"}
        </h4>
        <div className={styles.buttons}>
          {editing && (
            <Button
              buttonType={ButtonTypes.DEFAULT}
              disabled={!canManageReward}
              className={styles.deleteBtn}
              onClick={removeReward}
            >
              Delete
            </Button>
          )}
          <Button
            className={styles.saveBtn}
            buttonType={ButtonTypes.PRIMARY}
            disabled={
              !canManageReward ||
              reward.amount === 0 ||
              !reward.symbol ||
              shallowEqual(reward, templateItem?.reward)
            }
            onClick={canManageReward ? handleConfirm : undefined}
          >
            {editing ? "Save changes" : "Save reward"}
          </Button>
        </div>
      </div>
      <div className={styles.inputsContainer}>
        <div className={styles.inputRow}>
          <div className={styles.singleInputContainer}>
            <NetworkSelect
              selectedNetworkId={Number(reward.networkId)}
              disabled={reward.isAllocationConfirmed || !canManageReward}
              onSelectionChanged={updateNetwork}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.tokenSelectContainer}>
            <label className={styles.label}>Token</label>
            <TokenSelector
              networkId={Number(reward.networkId ?? web3Api.networkId)}
              tokenType={TokenTypes.ERC20}
              selectedItem={reward.contractAddress}
              onSelect={onTokenSelection}
              onConfirmedToken={onConfirmedToken}
              disabled={reward.isAllocationConfirmed || !canManageReward}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.singleInputContainer}>
            <ClarityInput
              label="Amount"
              min={0}
              value={reward.amount ?? 0}
              type="number"
              maxLength={15}
              placeholder={"Amount"}
              disabled={reward.isAllocationConfirmed || !canManageReward}
              onChange={(e) => {
                if (e.target.value.length > 20) {
                  return;
                }
                const value = Number(e.target.valueAsNumber);
                if (value) {
                  e.target.value = String(value);
                } else {
                  if (e.target.value !== "0" && e.target.value !== "") {
                    e.preventDefault();
                    return;
                  }
                }

                const getValue = () => {
                  if (e.target.valueAsNumber && !isNaN(e.target.valueAsNumber))
                    return e.target.valueAsNumber;
                  if (e.target.value) return reward.amount;
                  return 0;
                };

                setReward({
                  ...reward,
                  amount: Number(getValue()),
                });
              }}
            />
          </div>
        </div>
      </div>

      <Conditional on={templateItem.workType !== WorkTypes.TASK}>
        <div className={styles.statusDivider} />
        <div className={styles.inputRow}>
          <div className={styles.singleInputContainer}>
            <SponsorGroupDisplay
              reward={reward}
              setSponsorGroupId={(groupId) => {
                setReward({
                  ...reward,
                  sponsorGroupId: groupId,
                });
              }}
              disabled={
                templateItem.workType === WorkTypes.TASK ||
                reward.isAllocationConfirmed ||
                !canManageReward
              }
            />
          </div>
        </div>
        <div className={styles.splitDivider} />
        <div className={styles.splitContainer}>
          <div className={styles.topRow}>
            <span className={styles.header}>Split</span>
            <div className={styles.toggleContainer}>
              <ModeButton
                setReward={setReward}
                reward={reward}
                splitType={SplitType.currency}
                canManageReward={canManageReward}
              />
              <ModeButton
                setReward={setReward}
                reward={reward}
                canManageReward={canManageReward}
                splitType={SplitType.percentage}
              />
            </div>
          </div>
        </div>
      </Conditional>
    </div>
  );
};

const ModeButton: React.FC<{
  reward: IReward;
  setReward: React.Dispatch<React.SetStateAction<IReward>>;
  splitType: SplitType;
  canManageReward: boolean;
}> = ({ reward, splitType, setReward, canManageReward }) => {
  const clickAction = () => {
    if (reward.splitType === splitType) return;
    const updatedReward = { ...reward };
    const convertAmount = (amount: number) => {
      if (splitType === SplitType.percentage) {
        return Number(exerptNumber((amount / reward.amount) * 100));
      }
      if (splitType === SplitType.currency) {
        return Number(exerptNumber((amount * reward.amount) / 100));
      }
      return 0;
    };
    updatedReward.contributors = updatedReward.contributors.map(
      (contributor) => {
        const updatdContributor = { ...contributor };
        updatdContributor.amount = convertAmount(updatdContributor.amount);
        return updatdContributor;
      }
    );
    setReward({ ...updatedReward, splitType });
  };

  return (
    <Button
      onClick={clickAction}
      disabled={
        reward.isPaid || reward.isAllocationConfirmed || !canManageReward
      }
      iconStyle={reward.splitType === splitType ? { color: "#fff" } : undefined}
      style={
        reward.splitType === splitType ? { background: "#826274" } : undefined
      }
      buttonType={ButtonTypes.LINK}
      icon={
        splitType === SplitType.percentage ? (
          <PercentageOutlined />
        ) : (
          <NumberOutlined />
        )
      }
    />
  );
};

function FoldButton({ open, onClick }: any): JSX.Element {
  const className = useOptionalClassName({
    baseStyle: subworkstyles.foldButton,
    pairs: [
      {
        extraStyle: subworkstyles.foldButtonClosed,
        withExtra: !open,
      },
    ],
  });

  return (
    <Button
      onClick={onClick}
      className={className}
      icon={<img src={TriangleIcon} alt="Fold & unfold button" />}
      buttonType={ButtonTypes.LINK}
      style={{
        width: "20px",
        minWidth: "20px",
        minHeight: "20px",
        height: "20px",
        marginTop: "2px",
        paddingTop: "7px",
        position: "absolute",
        left: "-20px",
      }}
    />
  );
}

export default TemplateReward;
