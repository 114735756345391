import { Avatar } from "antd";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import { tokenGateApi } from "clientApi/tokenGatesApi";
import Button, { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import { axiosInstance } from "index";
import { createContext, useEffect, useState } from "react";
import {
  useContainerInviteModalSetter,
  useContainerInviteModalState,
  useSharingAndPermsModalState,
} from "store/reducers/clientReducer";
import { useBase, useBaseType } from "store/reducers/workspaceReducer";
import {
  Abilities,
  BaseType,
  ContainerTypes,
  ContainerVisibility,
  PermissionTypes,
  PrimitiveScopes,
} from "utilities/types";
import {
  usePermissionDeltaContext,
  usePermissionsDropdownState,
} from "./dropdownstate";
import { PermissionsDropdownComponent } from "./PermissionDropDown";
import styles from "./sharingAndPermsModal.module.scss";
import {
  DropDownType,
  loadingData,
  permToPermissionType,
  PlusOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  visibilityData,
} from "./util";
interface IGuestInviteSectionProps {
  baseId: string;
  containerId: string;
  containerType: ContainerTypes;
}

export function GuestInviteSection(props: IGuestInviteSectionProps) {
  const {
    acceptedGuestUsers,
    pendingGuestInvites,
    openContainerInviteModal,
    removeAcceptedGuest,
  } = useGuestInviteSectionState();

  const containerId = useContainerId();
  const { editable } = usePermissionDeltaContext();
  const canEditShareData = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MEMBERS,
  });

  return (
    <>
      <div>
        <div className={styles.headingWrapGuest}>
          <span className="body medium">Guest access</span>
          {editable && (
            <Button
              buttonType={ButtonTypes.PLAIN}
              disabled={!canEditShareData}
              onClick={openContainerInviteModal}
              icon={<PlusOutlined />}
            ></Button>
          )}
        </div>
        {acceptedGuestUsers.length === 0 && pendingGuestInvites.length === 0 && (
          <div className={styles.noSetup}>
            <div className={styles.icon}>
              <UsergroupAddOutlined />
            </div>
            <div>
              <div className="medium caption">No guests have been added</div>
              <div className="regular small secondary">
                Nobody has been granted guest access
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.userContainer}>
        {acceptedGuestUsers.map((acceptedGuest) => (
          <UserPermEntry
            key={acceptedGuest.id}
            title={usernameSplitter(
              acceptedGuest.name ? acceptedGuest.name : acceptedGuest.username
            )}
            description="Guest"
            icon={<Avatar src={acceptedGuest.avatar} size={32} />}
            baseId={props.baseId}
            userId={acceptedGuest.id}
            containerId={containerId}
            containerType={props.containerType}
            permissions={acceptedGuest.permissions}
            existingUser={true}
            removeAcceptedGuest={removeAcceptedGuest}
            editable={canEditShareData}
          />
        ))}
        {pendingGuestInvites.map((pendingGuest) => (
          <UserPermEntry
            key={pendingGuest.id}
            title={
              pendingGuest.invitedEmail ??
              usernameSplitter(pendingGuest.invited.username)
            }
            description="Pending"
            icon={<Avatar src={<UserOutlined />} size={32} />}
            existingUser={false}
            userId={pendingGuest.id}
            removeAcceptedGuest={removeAcceptedGuest}
            containerType={props.containerType}
            editable={canEditShareData}
            roleAssignmentId={pendingGuest.roleAssignmentId}
            permissions={pendingGuest.permissions}
          />
        ))}
      </div>
    </>
  );
}

export function useGuestInviteSectionState() {
  const [acceptedGuestUsers, setAcceptedGuestUsers] = useState<any[]>([]);
  const [pendingGuestInvites, setPendingGuestInvites] = useState<any[]>([]);
  const { base } = useBase();
  const baseType = useBaseType();

  const { containerId, containerType } = useSharingAndPermsModalState();
  const { isOpen: containerInviteModalIsOpen } = useContainerInviteModalState();

  const setContainerInviteModalState = useContainerInviteModalSetter();

  const openContainerInviteModal: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) =>
    setContainerInviteModalState({
      isOpen: true,
      containerId,
      containerType,
    });

  const removeAcceptedGuest = (acceptedGuestUserId: any) => {
    const newAcceptedGuestsUsers = acceptedGuestUsers.filter(
      (acceptedGuestUser) => acceptedGuestUser.id !== acceptedGuestUserId
    );
    setAcceptedGuestUsers(newAcceptedGuestsUsers);

    const newpendingGuestInvites = pendingGuestInvites.filter(
      (pendingGuestUser) => pendingGuestUser.id !== acceptedGuestUserId
    );
    setPendingGuestInvites(newpendingGuestInvites);
  };

  useEffect(() => {
    if (!containerInviteModalIsOpen) {
      loadGuests({
        containerId,
        containerType: containerType,
        baseId: base.id,
        handleData: ({
          acceptedGuestUsers,
          pendingGuestInvites,
          visibility,
        }) => {
          setAcceptedGuestUsers(acceptedGuestUsers);
          setPendingGuestInvites(pendingGuestInvites);
          if (baseType !== BaseType.Public) {
            visibilityData.next(visibility);
          }
        },
      });
    }
  }, [containerInviteModalIsOpen]);

  return {
    acceptedGuestUsers,
    pendingGuestInvites,
    openContainerInviteModal,
    removeAcceptedGuest,
  };
}

async function loadGuests({
  containerId,
  containerType,
  baseId,
  handleData,
}: {
  containerId: string;
  containerType: ContainerTypes;
  baseId: string;
  handleData: (params: {
    acceptedGuestUsers: any[];
    pendingGuestInvites: any[];
    visibility: ContainerVisibility;
  }) => void;
}) {
  loadingData.next(true);
  const result = await axiosInstance.get("/api/container", {
    params: {
      containerId,
      containerType: containerType,
      baseId,
    },
  });
  if (result.data.tokenGates) {
    const tokenGate = result.data.tokenGates[0];
    if (tokenGate) {
      const gateLevel = result.data.gateLevel
        ? result.data.gateLevel
        : PermissionTypes.SUGGEST;
      tokenGateApi.setAssignedGate(tokenGate, gateLevel);
    }
  }
  loadingData.next(false);

  handleData(result.data);
}

interface IGuestSearchContext {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  isEmail: boolean;
  setIsEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GuestSearchContext = createContext<IGuestSearchContext>({
  searchValue: "",
  setSearchValue: () => {},
  isEmail: false,
  setIsEmail: () => {},
});

interface IUserPermEntryProps {
  title: string;
  description: string;
  icon: React.ReactElement;
  noPermsMenu?: boolean;
  baseId?: string;
  userId?: string;
  roleAssignmentId?: string;
  containerId?: string;
  containerType?: ContainerTypes;
  permissions?: any;
  existingUser?: boolean;
  removeAcceptedGuest?: any;
  editable: boolean;
}

function UserPermEntry({
  title,
  description,
  icon,
  noPermsMenu,
  baseId,
  userId,
  containerId,
  containerType,
  permissions,
  existingUser,
  removeAcceptedGuest,
  editable,
  roleAssignmentId,
}: IUserPermEntryProps) {
  const { ...dropdownProps } = usePermissionsDropdownState({
    initialValue: "suggest",
    readOnly: noPermsMenu,
    onChange: (newPermission: string) => {
      if (newPermission === ContainerVisibility.remove) {
        removeEntry();
      }
    },
    permissions,
    userId,
    roleAssignmentId,
  });

  const { removeEntry } = useUserPermEntryActions(
    permToPermissionType[dropdownProps.permSelected],
    userId,
    existingUser,
    removeAcceptedGuest
  );

  return (
    <div className={styles.userPermEntryContainer}>
      <div className={styles.userPermEntryPrefix}>{icon}</div>
      <div className={styles.userPermEntryInfo}>
        <span className={styles.userPermEntryInfoTitle}>{title}</span>
        <span className={styles.userPermEntryInfoDescription}>
          {description}
        </span>
      </div>
      {noPermsMenu && permissions !== undefined ? (
        <></>
      ) : (
        <PermissionsDropdownComponent
          type={DropDownType.guest}
          permissions={permissions}
          userId={userId}
          {...dropdownProps}
          readOnly={!editable}
          editable={editable}
        />
      )}
      {/* {editable && (
        <Button
          buttonType={ButtonTypes.LINK}
          icon={<CloseOutlined />}
          onClick={removeEntry}
        />
      )} */}
    </div>
  );
}

function useUserPermEntryActions(
  permissionType: any,
  userId: any,
  existingUser?: boolean,
  removeAcceptedGuest?: any
) {
  const { base } = useBase();
  const containerId = useContainerId();
  const removeEntry = () => {
    if (existingUser) {
      axiosInstance.post("/api/role-assignment/role", {
        baseId: base.id,
        users: [userId],
        containerId,
        containerType: "Project",
        deltas: [
          {
            type: "remove",
            value: containerId,
            primitive: PrimitiveScopes.WORK,
            permissionType: permissionType,
            userId: userId,
            existingUser,
          },
        ],
      });
    } else {
      axiosInstance.delete(`/api/invitation/${userId}`);
    }
    if (removeAcceptedGuest) {
      removeAcceptedGuest(userId);
    }
  };

  return {
    removeEntry,
  };
}

function useContainerId() {
  const { containerId } = useSharingAndPermsModalState();
  return containerId;
}
