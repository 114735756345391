import eventApi from "clientApi/eventApi";
import { axiosInstance } from "index";
import {
  CHANGE_DOC_PUBLIC_ACCESS,
  PATCH_NOTE,
  UPDATE_WORK_ITEM,
} from "store/actions";
import { useSharingAndPermsModalState } from "store/reducers/clientReducer";
// import { usePrimaryActiveEntity } from "store/reducers/primaryActiveEntityReducer";
import { useBaseId } from "store/reducers/workspaceReducer";
import store from "store/storeExporter";
import {
  ContainerTypes,
  ContainerVisibility,
  UserEventTypes,
} from "utilities/types";
import { Permission } from "./util";

export function useEntityUpdater() {
  const { containerId, containerType } = useSharingAndPermsModalState();

  const baseId = useBaseId();
  return (containerVisibility: ContainerVisibility | Permission) => {
    axiosInstance
      .post("/api/container/visibility", {
        containerId,
        containerType,
        baseId: baseId,
        visibility: containerVisibility,
      })
      .then((res) => {
        eventApi.postEvent(UserEventTypes.ANONYMOUS_PERMISSIONS_CHANGED, {
          container_id: containerId,
          container_type: containerType,
        });
        handlePermissionChangeResponse(res, containerId, containerType);
      });
  };
}

const handlePermissionChangeResponse = (
  response: any,
  containerId: string,
  containerType: ContainerTypes
) => {
  const { isPublicAccess } = response.data;

  if (containerType === ContainerTypes.DOCUMENT) {
    store.dispatch({
      type: CHANGE_DOC_PUBLIC_ACCESS,
      docId: containerId,
      isPublicAccess,
      entityType: containerType,
    });
  } else if (containerType === ContainerTypes.NOTE) {
    store.dispatch({
      type: PATCH_NOTE,
      patchContainer: {
        container: {
          containerId,
          containerType,
        },
        patch: {
          isPublicAccess,
        },
      },
    });
  } else {
    store.dispatch({
      type: UPDATE_WORK_ITEM,
      workItem: {
        id: containerId,
        isPublicAccess,
      },
    });
  }
};
