import { getValue } from "editor/utils/blockValueHelpers";
import React, { useMemo, useRef } from "react";
import { useShallowSelector } from "utilities/hooks";
import { LineValueType } from "utilities/lineUtilities";

const NotePreviewTags: React.FC<{ blockId: string; className: string }> = ({
  blockId,
  className,
}) => {
  const block = useShallowSelector((store) => store.blocks.dict[blockId]);
  const tagsElement = useRef<HTMLDivElement | null>(null);

  const tags = useMemo(() => {
    let potential = block.value
      .map((value, index) => {
        if (value.type === LineValueType.mention) {
          return (
            <span className="truncateTag" key={index}>
              #{getValue(value)}
            </span>
          );
        }
        return null;
      })
      .filter((val) => Boolean(val));

    if (potential.length > 4) {
      const total = potential.length;
      potential = potential.slice(0, 3);
      potential.push(
        <span className="small secondary regular">+{total - 4} more</span>
      );
    }
    return potential;
  }, [block.value]);

  return (
    <div className={className} ref={tagsElement}>
      {tags}
    </div>
  );
};

export default NotePreviewTags;
