import { ILineValue } from "../utilities/lineUtilities";
import {
  IDocumentCreationMethods,
  ContainerTypes,
  IPageObj,
  WorkTypes,
} from "../utilities/types";
import store from "store/storeExporter";
import {
  REMOVE_PIN,
  LOAD_RELATED_WORK,
  REMOVE_NOTE,
  LOAD_RELATED_RESOURCES,
  REMOVE_NOTE_SIDEEFFECT,
  REMOVE_WORKITEM_SIDEEFFECT,
} from "store/actions";
import { axiosInstance } from "index";

const { v4: uuidv4 } = require("uuid");

export const handleNewDocumentCreation = async (
  workspaceId: string,
  method: IDocumentCreationMethods,
  docName?: string,
  docNameValue?: ILineValue[],
  id?: string
) => {
  return axiosInstance.post("/api/document/create", {
    workspaceId,
    docName: docName ? docName : null,
    nameValue: docNameValue ? docNameValue : null,
    method,
    id,
  });
};

export const handleNewPageLocal = (
  docName: string,
  docNameValue: ILineValue[]
) => {
  const date = new Date().toDateString();
  const newDocument: IPageObj = {
    id: uuidv4(),
    name: docName ? docName : "",
    nameValue: docNameValue ? docNameValue : [],
    dateCreated: date,
    dateModified: date,
    isPublicAccess: false,
    aliases: [],
    mentions: [],
    outlineMode: "noOutline",
  };
  return newDocument;
};

export const makeDocumentActive = async (documentId: string) => {
  return axiosInstance.post("/api/document/activate", {
    documentId,
  });
};

export const deleteDocument = async (id: string) => {
  const workspaceId = store.getState().workspace.id;
  store.dispatch({
    type: REMOVE_WORKITEM_SIDEEFFECT,
    containerId: id,
    baseId: workspaceId,
  });
  return await axiosInstance
    .delete(`/api/document/delete`, {
      data: {
        id,
      },
    })
    .then((res) => {
      if (res.data && res.data.success) {
        return res.data;
      }
    });
};

export const deleteNote = async (noteId: string) => {
  const note = store.getState().notes.dict[noteId];
  const group = note?.groupId
    ? store.getState().groups.dict[note.groupId]
    : undefined;
  store.dispatch({
    type: REMOVE_NOTE,
    noteId: noteId,
  });
  store.dispatch({
    type: REMOVE_NOTE_SIDEEFFECT,
    containerId: noteId,
    baseId: note.workspaceId,
  });
  return axiosInstance.delete(`/api/note/${noteId}`).then((res) => {
    if (res.data && res.data.status) {
      return {
        containerId: res.data.payload.deletedNote,
        groupSlug: group?.slug,
      };
    } else {
      return {
        containerId: undefined,
        groupSlug: undefined,
      };
    }
  });
};

export const deleteProject = async (id: string) => {
  const item = store.getState().work.dict[id];
  const workspaceId = store.getState().workspace.id;
  if (item.workType === WorkTypes.TASK) {
    const group = store.getState().groups.dict[item.groupId];
    store.dispatch({
      type: REMOVE_WORKITEM_SIDEEFFECT,
      containerId: `${group.slug}-${item.projectId}`,
      baseId: workspaceId,
    });
  } else {
    store.dispatch({
      type: REMOVE_WORKITEM_SIDEEFFECT,
      containerId: item.projectId,
      baseId: workspaceId,
    });
  }
  return await axiosInstance
    .delete(`/api/project/delete`, {
      data: {
        id,
      },
    })
    .then((res) => {
      if (res.data && res.data.status === 1) {
        store.dispatch({
          type: REMOVE_PIN,
          id: res.data.payload.deletedPin,
        });
        return res.data.payload;
      }
    });
};

export const getRelatedEntities = async (
  id: string,
  containerType: ContainerTypes
) => {
  return Promise.all([
    getRelatedWork(id, containerType),
    getRelatedResources(id, containerType),
  ]);
};

export const getRelatedWork = async (
  id: string,
  containerType: ContainerTypes
) => {
  return await axiosInstance
    .get("/api/project/relatedWork", {
      params: {
        id,
        containerType,
      },
    })
    .then((resp) => {
      store.dispatch({
        type: LOAD_RELATED_WORK,
        work: resp.data.payload,
      });
    });
};

export const getRelatedResources = async (
  id: string,
  containerType: ContainerTypes
) => {
  return await axiosInstance
    .get("/api/resource/relatedResources", {
      params: {
        id,
        containerType,
      },
    })
    .then((resp) => {
      store.dispatch({
        type: LOAD_RELATED_RESOURCES,
        resources: resp.data.payload,
      });
    });
};
