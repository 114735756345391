// Vendor Libraries
import Helmet from "react-helmet";
import { useLayoutEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import LoginForm from "./logIn/LogInForm";
import ClarityLogo from "clarity-ui/ClarityLogo";
import styles from "./logIn/logIn.module.scss";
import { useIsMobile } from "utilities/hooks";

export default function LogIn() {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  useRouteIntercept(location, history);
  const [reset, setReset] = useState(false);

  return (
    <div className={styles.scrollElement}>
      <div className={`${styles.login} ${isMobile ? styles.login_mobile : ""}`}>
        <Helmet>
          <title>Log in • Clarity</title>
        </Helmet>
        <div className={styles.login_body}>
          <span
            onClick={() => {
              setReset(!reset);
            }}
            style={{
              cursor: "pointer",
            }}
            id={"clarity-logo"}
          >
            <ClarityLogo
              containerStyle={({ isMobile }) => ({ marginBottom: "68px" })}
            />
          </span>
          <LoginForm reset={reset} />
        </div>
      </div>
    </div>
  );
}

const useRouteIntercept = (
  location: ReturnType<typeof useLocation>,
  history: ReturnType<typeof useHistory>
) => {
  useLayoutEffect(() => {
    if (location.pathname !== "/login") {
      const intendedRoute = location.pathname.slice(1);
      if (location.pathname !== "/logout" && location.pathname !== "/") {
        history.push(`/login?redirectTo=${intendedRoute}`);
      } else {
        history.push(`/login`);
      }
    }
  }, [location]);
};
