import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Modal from "clarity-ui/Modal";
import Button, { ButtonTypes, IButtonProps } from "components/Button";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as actionTypes from "store/actions";
import styles from "./imageModal/imageModal.module.scss";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import IconPeek from "icons/Components/IconPeek";

interface IImageModalProps {}

function ImageModal(props: IImageModalProps) {
  const bodyRef = useRef();
  const dispatch = useDispatch();
  const hideModal = () =>
    dispatch({
      type: actionTypes.TOGGLE_IMAGE_MODAL,
      isOpen: false,
    });

  const containerRef = useRef<HTMLDivElement | undefined>();

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.focus();
    }
  }, [containerRef]);

  const { imageUrl } = useSelector(
    (state: any) => state.client.imageModal,
    shallowEqual
  );

  const openInNewTab = () => openUrlInNewTab(imageUrl);
  const [showCopyBtn, setShowCopyBtn] = useState(true);
  const copyLink = () => {
    navigator.clipboard.writeText(imageUrl);
    setShowCopyBtn(false);
    setTimeout(() => setShowCopyBtn(true), 2000);
  };

  const imgRef = useRef<HTMLImageElement>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLandscape, setIsLandscape] = useState(false);

  const imgWrapperStyles = useResponsiveImgStyles(imgRef, bodyRef);

  const onKeyDown = useOnKeyDown(hideModal);

  return (
    <Modal size="full" hideModal={hideModal} padding={false}>
      <div
        className={styles.container}
        onKeyDown={onKeyDown}
        tabIndex={1}
        ref={containerRef as any}
      >
        <div className={styles.header}>
          <div className={styles.headerButtons}>
            {showCopyBtn && (
              <HeaderButton
                title="Copy link to file"
                icon={<CopyOutlined />}
                onClick={copyLink}
              />
            )}
            {!showCopyBtn && <Button>Copied</Button>}
            <HeaderButton
              title="Open in new tab"
              icon={
                <IconPeek style={{ transform: "rotate(0deg) scaleX(-1)" }} />
              }
              onClick={openInNewTab}
            />
            <HeaderButton
              title="Close"
              icon={<CloseOutlined />}
              onClick={hideModal}
            />
          </div>
        </div>
        <div className={styles.body} ref={bodyRef as any}>
          <div
            style={imgWrapperStyles}
            className={`${styles.imgWrapper} ${
              isLandscape ? styles.imgWrapper__landscape : ""
            }`}
          >
            <img
              ref={imgRef as any}
              alt="blockImage"
              draggable={false}
              className={styles.imageBlock}
              src={imageUrl}
              style={{ borderRadius: "3px" }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

function openUrlInNewTab(url: string) {
  window.open(url, "_blank");
}

// function download(url: string, filename: string) {
//   fetch(url).then((t) => {
//     return t.blob().then((b) => {
//       var a = document.createElement("a");
//       a.href = URL.createObjectURL(b);
//       a.setAttribute("download", filename);
//       a.click();
//     });
//   });
// }

interface IHeaderButton {
  icon: IButtonProps["icon"];
  onClick: IButtonProps["onClick"];
  title?: string;
}

function HeaderButton(props: IHeaderButton) {
  const { icon, onClick, title = "" } = props;
  return (
    <div className={styles.headerButtonContainer} title={title}>
      <Button buttonType={ButtonTypes.LINK} icon={icon} onClick={onClick} />
    </div>
  );
}

function useOnKeyDown(hideModal: () => void) {
  return (e: any) => {
    if (e.key === "Escape") {
      hideModal();
    }
  };
}
function useResponsiveImgStyles(
  imgRef: React.MutableRefObject<HTMLImageElement | undefined>,
  bodyRef: React.MutableRefObject<undefined>
) {
  const [imgWrapperStyles, setImgWrapperStyles] = useState({
    height: "100%",
    width: "100%",
  });

  useLayoutEffect(() => {
    if (bodyRef?.current && imgRef?.current) {
      const proportion = imgRef.current.width / imgRef.current.height;
      const isLandscape = proportion > 1;
      let height, width;
      if (isLandscape) {
        width = (bodyRef.current as any).clientWidth;
        height = width / proportion;
        if (height > (bodyRef.current as any).clientHeight) {
          height = (bodyRef.current as any).clientHeight - 50;
          width = height * proportion;
        }
      } else {
        height = (bodyRef.current as any).clientHeight - 50;
        width = height * proportion;
        if (width > (bodyRef.current as any).clientWidth) {
          width = (bodyRef.current as any).clientWidth;
          height = width / proportion;
        }
      }
      setImgWrapperStyles({
        width: `${width}px`,
        height: `${height}px`,
      });
    }
  }, [bodyRef, imgRef]);

  return imgWrapperStyles;
}

export default ImageModal;
