import React, { MutableRefObject } from "react";

import ModalScrimComponent from "components/ModalScrimComponent";
import {
  Abilities,
  ContainerTypes,
  ITemplateObj,
  UserRole,
} from "utilities/types";
import styles from "./workItemPreviewModal/workItemPreviewModal.module.scss";
import taskStyles from "./detailView/documentContainer/task/task.module.scss";
import { useIsMobile, useShallowSelector } from "utilities/hooks";
import DocumentContainer from "./detailView/DocumentContainer";
import { ChunkDestination } from "utilities/stateTypes";
import { Alert, Modal, Tooltip } from "antd";
import Button, { ButtonTypes } from "components/Button";
import { VALUE_ESCAPE } from "keycode-js";
import store from "store/storeExporter";
import { TOGGLE_TEMPLATE_MODAL } from "store/actions";
import Icon, {
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useAbilityChecker } from "editor/utils/customHooks";
import { ReactComponent as CloseIcon } from "icons/closeIcon.svg";
import { templatesApi } from "clientApi/templateApi";

const { confirm } = Modal;

interface Props {
  template: ITemplateObj;
  hideModal: () => void;
  selectedTasksRef?: MutableRefObject<string[]>;
}

const showDeleteTemplateConfirm = (containerId: string) => {
  const container = store.getState().templates.dict[containerId];
  const childrenCount = container.childrenAggregate.length;
  const contentText =
    childrenCount > 0 ? (
      <Alert
        message={`Deleting it will also delete ${childrenCount} subtask${
          childrenCount > 1 ? "s" : ""
        }`}
        type="error"
      />
    ) : (
      ""
    );

  return confirm({
    title: `Are you sure you want to delete this template?`,
    icon: <ExclamationCircleOutlined />,
    content: contentText,
    maskClosable: true,
    okText: "Delete",

    onOk() {
      templatesApi.deleteTemplate(containerId);
    },
    onCancel() {},
  });
};

const TemplateItemPreviewModal: React.FC<Props> = ({ template, hideModal }) => {
  const isMobile = useIsMobile();

  const userRole = useShallowSelector((state) => state.client.roleType);

  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  if (!template) return <></>;

  return (
    <div
      style={{
        display: "flex",
        zIndex: 99,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <div
        className={`${styles.item_preview_modal} document-view task-modal ${
          isMobile ? styles.item_preview_modal__mobile : ""
        }`}
      >
        <div
          tabIndex={0}
          className={"taskScreen"}
          onKeyDown={(e) => {
            if (e.key === VALUE_ESCAPE) {
              hideModal();
            }
          }}
        >
          <div className={taskStyles.taskBodyContainer + " commentContainer"}>
            <div className={taskStyles.taskBody__headerContainer}>
              <div className={taskStyles.taskBody__headerContainer__buttons}>
                {userRole !== UserRole.GUEST ? (
                  <Tooltip title={"Delete"}>
                    <Button
                      buttonType={ButtonTypes.LINK}
                      disabled={!canEdit}
                      onClick={() => {
                        if (!canEdit) return;
                        showDeleteTemplateConfirm(template.id);
                      }}
                      icon={<DeleteOutlined />}
                      className="mr-4"
                    />{" "}
                  </Tooltip>
                ) : (
                  <></>
                )}

                <Tooltip title={"Close"}>
                  <span>
                    <Button
                      buttonType={ButtonTypes.LINK}
                      icon={
                        <Icon
                          style={{ fontSize: "12px" }}
                          component={CloseIcon}
                        />
                      }
                      onClick={() => {
                        store.dispatch({
                          type: TOGGLE_TEMPLATE_MODAL,
                          isOpen: false,
                          id: undefined,
                        });
                      }}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
            <DocumentContainer
              paneId={ChunkDestination.peek}
              key={template.id}
              document={template}
              containerId={template.id}
              containerType={ContainerTypes.TEMPLATE}
            />
          </div>
        </div>
      </div>
      <ModalScrimComponent hideModal={hideModal} />
    </div>
  );
};

export default TemplateItemPreviewModal;
