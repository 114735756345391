import { DatePicker } from "antd";
import moment, { Moment } from "moment";

const PaletteDatePicker: React.FC<{
  onChange: (value: moment.Moment | null) => void;
  defaultValue?: Moment;
}> = ({ onChange, defaultValue }) => {
  const setdefaultValue = defaultValue ?? undefined;

  return (
    <div>
      <DatePicker
        showToday={true}
        autoFocus={true}
        defaultOpen={true}
        onChange={onChange}
        defaultValue={setdefaultValue}
      />
    </div>
  );
};

export default PaletteDatePicker;
