import Modal from "clarity-ui/Modal";
import PricingPlans from "components/PricingPlans";
import { shallowEqual, useSelector } from "react-redux";

interface IFreeTrialModalProps {
  hideModal: () => void;
}

function FreeTrialModal(props: IFreeTrialModalProps) {
  const { hideModal } = props;

  const innerState = useSelector((store: any) => {
    return {
      user: store.user,
      base: store.workspace,
    };
  }, shallowEqual);

  return (
    <Modal size={"extraLarge"} hideModal={hideModal}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* <span
          className="body2 medium secondary"
          style={{
            display: "block",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Upgrade your plan to add more teammates to this base
        </span> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PricingPlans base={innerState.base} user={innerState.user} />
        </div>
      </div>
    </Modal>
  );
}

export default FreeTrialModal;
