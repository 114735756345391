import { FileTextOutlined } from "@ant-design/icons";
import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import Conditional from "components/Conditional";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import { useShallowSelector } from "utilities/hooks";
import {
  CommandPaletteContext,
  ContainerTypes,
  IProjectObj,
  WorkTypes,
} from "utilities/types";

const ParentRow: React.FC<{
  id?: string | null;
  workType: WorkTypes;
  containerId: string;
  canEditEntity: boolean;
}> = ({ id, workType, canEditEntity, containerId }) => {
  const parent = useShallowSelector((state) =>
    id ? state.work.dict[id] ?? {} : ({} as IProjectObj)
  );
  const {
    name: parentName,
    projectId: parentWorkId,
    workType: parentWorkType,
  } = parent;
  const hasParent = parentName && parentWorkId;

  const handleProjectClick = () => {
    if (containerId) {
      openCommandPalette(CommandPaletteContext.PARENT, {
        selectedItemIds: [containerId],
        slectedItemsType: ContainerTypes.WORK,
      });
    }
  };

  const isParentWorkTypeTask = parentWorkType === WorkTypes.TASK;

  return (
    <Conditional on={[WorkTypes.PROJECT, WorkTypes.TASK].includes(workType)}>
      <MetadataBlock
        title={workType === WorkTypes.PROJECT ? "Goal" : "Parent"}
        disabled={!canEditEntity}
        infoText={
          <span className={hasParent ? "" : "disabled"}>
            {" "}
            {hasParent
              ? `${parentName} ${
                  isParentWorkTypeTask ? "#" + parentWorkId : ""
                }`
              : `No ${workType === WorkTypes.PROJECT ? "Goal" : "Parent"}`}{" "}
          </span>
        }
        prefix={<FileTextOutlined className={hasParent ? "" : "disabled"} />}
        onClick={handleProjectClick}
      />
    </Conditional>
  );
};

export default ParentRow;
