import React from "react";
import styles from "./defaultPageHoc/defaultPageHoc.module.scss";
import ClarityLogoGray from "icons/Components/ClarityLogoGray";

import { useOptionalClassName } from "utilities/hooks";

const DefaultPageHoc: React.FC<{
  showLogo?: boolean;
  cardBackground?: boolean;
  cardOutline?: boolean;
}> = ({ children, showLogo, cardBackground, cardOutline }) => {
  const cardClasses = useOptionalClassName({
    baseStyle: styles.card,
    pairs: [
      {
        extraStyle: styles.withBorder,
        withExtra: cardOutline,
      },
      {
        extraStyle: styles.withBackground,
        withExtra: cardBackground,
      },
    ],
  });

  return (
    <>
      <div className={styles.background}>
        <div className={styles.content}>
          {showLogo && (
            <div className={styles.iconHolder}>
              <ClarityLogoGray alt="Clarity Logo" style={{ width: "93px" }} />
            </div>
          )}
          <div className={cardClasses}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default DefaultPageHoc;
