import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "../blockMenuVariants/slashCommandMenu.module.scss";
import { resetBlockMenu } from "editor/utils/blockMenusActions/menuActions";
import { Menu } from "antd";
import { MenuProps } from "../BlockMenu";
import store from "store/storeExporter";
import { ContainerTypes, WorkTypes } from "utilities/types";
import { LineType } from "utilities/lineUtilities";
import { removePlaceholderPlaceCaret } from "editor/utils/specificActions/textUpdateUtils";
import { batch } from "react-redux";
import { batchBlockUpdate } from "editor/utils/specificActions/batchBlockUpdate";
import { useOnClickOutside } from "editor/utils/customHooks";

const getEntityList = (props: MenuProps) => {
  switch (props.menuState.options?.entityType) {
    case "DOCUMENT": {
      const documents = store.getState().pages.dict;
      return Object.values(documents);
    }
    case "PROJECT": {
      const workDict = store.getState().work.dict;
      const projects = Object.values(workDict);
      // .filter(
      //   (workItem) => workItem.workType === WorkTypes.PROJECT
      // );
      return projects;
    }
    case "TASK": {
      const workDict = store.getState().work.dict;
      const tasks = Object.values(workDict).filter(
        (workItem: any) => workItem.workType === WorkTypes.TASK
      );
      return tasks;
    }
    default: {
      const documents = store.getState().pages.dict;
      return Object.values(documents);
    }
  }
};

const getEntityName = (props: MenuProps) => {
  switch (props.menuState.options?.entityType) {
    case "DOCUMENT": {
      return LineType.document;
    }
    case "PROJECT": {
      return LineType.work;
    }
    case "TASK": {
      return LineType.work;
    }
    default:
      return LineType.work;
  }
};

const getOptionValue = (option: any, props: MenuProps) => {
  return (
    <>
      <span style={{ marginRight: "10px" }}>#{option.projectId}</span>
      <span className={styles.slashCommandMenu__menuItem__labelContainer}>
        {option.name}
      </span>
    </>
  );
};

const LinkEntityMenu: React.FC<MenuProps> = (props) => {
  const prevPosition = useRef({
    left: `0px`,
    top: `0px`,
  });
  const menuOptions: any = getEntityList(props);

  const menuRef = useRef(null);
  const closeMenu = useCallback((event) => {
    if (event) {
      resetBlockMenu(props.menuStateValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useOnClickOutside(menuRef, closeMenu);

  const [optionsToDisplay, setoptionsToDisplay] = useState<any[]>([
    ...menuOptions.slice(0, 15),
  ]);

  let hoveredOption = props.menuState.hoveredItem;

  const activateCheckedOption = (index: number) => {
    batch(() => {
      removePlaceholderPlaceCaret(props.blockRef.current);
      const option = { ...optionsToDisplay[index] };
      batchBlockUpdate({
        id: props.blockData.id,
        context: props.context,
        delta: {
          referencingContainerType: ContainerTypes.PROJECT,
          referencingContainerId: option.id,
          lineType: getEntityName(props),
        },
      });
      resetBlockMenu(props.menuStateValue);
    });
  };

  useLayoutEffect(() => {
    if (props.menuState.isOpened) {
      let optionsToDisplayTrigger: any[] = [];
      if (props.menuState.filterMenuBy !== "") {
        const optionsToFilter = [...menuOptions];
        optionsToDisplayTrigger = optionsToFilter
          .filter(
            (option: any) =>
              option.name
                .toLowerCase()
                .trim()
                .includes(props.menuState.filterMenuBy.toLowerCase().trim()) ||
              option.projectId.toString().includes(props.menuState.filterMenuBy)
          )
          .slice(0, 15);
      } else {
        optionsToDisplayTrigger = menuOptions.slice(0, 15);
      }
      setoptionsToDisplay(optionsToDisplayTrigger);
    }
  }, [props.menuState.filterMenuBy, props.menuState.isOpened]);

  useEffect(() => {
    if (!props.menuState.isOpened) {
      return;
    }
    if (
      optionsToDisplay.length > 0 &&
      props.menuState.hoveredItem >= optionsToDisplay.length
    ) {
      hoveredOption = optionsToDisplay.length - 1;
      if (hoveredOption < 0) hoveredOption = 0;
      const menuStateCopy = { ...props.menuState };
      menuStateCopy.hoveredItem = hoveredOption;
      props.menuStateValue.current.next(menuStateCopy);
    }
  }, [props]);

  useEffect(() => {
    if (!props.menuState.isOpened) {
      return;
    }
  }, [props.menuState.filterMenuBy]);

  useEffect(() => {
    if (!props.menuState.isOpened) return;
    const el = document.getElementById(
      "menuItem" + props.menuState.hoveredItem
    );
    el?.scrollIntoView({ block: "nearest" });
  }, [props.menuState.hoveredItem]);

  useEffect(() => {
    if (!props.menuState.isOpened) return;
    if (props.menuState.executeSelection === true) {
      resetBlockMenu(props.menuStateValue);
      activateCheckedOption(props.menuState.hoveredItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuState.executeSelection]);

  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  let styleObject: any = {
    left: `${x}px`,
    top: `${y}px`,
  };
  if (selection && selection.rangeCount > 0) {
    const range: Range = selection.getRangeAt(0);
    const boundingRect = range.getBoundingClientRect();
    let rangeX = boundingRect.x;
    let rangeY = boundingRect.bottom;
    if (true) {
      const placeholder = props.blockRef.current.querySelectorAll(
        ".link-entity-placeholder"
      );

      if (placeholder[0]) {
        const placeholderSizes = placeholder[0].getBoundingClientRect();
        rangeX = placeholderSizes.x;
        rangeY = placeholderSizes.bottom;
        // const closestEl = props.blockRef.current.closest(".section-big");
        const closestEl = props.blockRef.current.closest(".flex-section");
        let componentX = closestEl ? closestEl.getBoundingClientRect().x : 0;
        let componentY = props.blockRef.current.getBoundingClientRect().y;
        const computed = window
          .getComputedStyle(props.blockRef.current)
          .getPropertyValue("margin-top");
        const marginString = computed.substring(0, computed.length - 2);
        const margin = Number(marginString);

        x = rangeX === 0 ? 10 : rangeX - componentX;
        y = rangeY === 0 ? 10 : rangeY + margin - componentY;

        if (componentX + x + 260 > window.outerWidth) {
          styleObject = {
            right: `${-x}px`,
            top: `${y}px`,
          };
        } else {
          styleObject = {
            left: `${x}px`,
            top: `${y}px`,
          };
        }
        prevPosition.current = styleObject;
      } else {
        styleObject = prevPosition.current;
      }
    }
  }
  return (
    <>
      <div
        className={styles.slashCommandContainer}
        style={styleObject}
        ref={menuRef}
      >
        <Menu className={styles.slashCommandMenu} selectable>
          <Menu.Item key={"start"} disabled={true}>
            {"Link to existing " + getEntityName(props)}
          </Menu.Item>
          {optionsToDisplay.length > 0 ? (
            optionsToDisplay.map((option, index) => (
              <Menu.Item
                key={"menuItem" + index}
                role="button"
                id={"menuItem" + index}
                onClick={(e) => {
                  e.domEvent.preventDefault();
                  e.domEvent.stopPropagation();
                  activateCheckedOption(index);
                }}
                className={
                  hoveredOption === index
                    ? `${styles.slashCommandMenu__menuItem} ${styles.nice}`
                    : styles.slashCommandMenu__menuItem
                }
              >
                {getOptionValue(option, props)}
              </Menu.Item>
            ))
          ) : (
            <Menu.Item key={"no-result"} disabled={true}>
              No results
            </Menu.Item>
          )}
        </Menu>
      </div>
    </>
  );
};

export default LinkEntityMenu;
