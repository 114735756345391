import ProjectCard from "components/ProjectCard";
import { applyFiltersToItems, sortByMetadata } from "modules/taskService";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { generateNewChunk } from "store/reducers/filterReducer";
import { useShallowSelector } from "utilities/hooks";
import {
  ContainerTypes,
  IFilterState,
  OrderTypes,
  ViewNames,
  WorkTypes,
} from "utilities/types";
import { getClosedStatuses } from "./types";
import styles from "./projectsSection/projectsSection.module.scss";
import { useUser } from "store/reducers/userReducer";
import navigationApi from "clientApi/navigationApi";

const WorkProjectsSection: React.FC = () => {
  const projectIds = useShallowSelector((store) => store.work.allProjectIds);
  const initiativeIds = useShallowSelector(
    (store) => store.work.allInitiativeIds
  );
  const dict = useShallowSelector((store) => store.work.dict);
  const [activeProjects, setactiveProjects] = useState<string[]>([]);

  const user = useUser();
  useLayoutEffect(() => {
    const closedStatusIds = getClosedStatuses();
    const filters: IFilterState = {
      ...generateNewChunk(),
      excludedStatuses: closedStatusIds,
      hasActiveFilters: true,
      type: [WorkTypes.PROJECT, WorkTypes.INITIATIVE],
      reviewers: [user.id],
    };
    const orderByClause = OrderTypes.status;
    const _filteredItems = applyFiltersToItems(
      [...projectIds, ...initiativeIds],
      dict,
      filters
    );
    sortByMetadata(orderByClause, _filteredItems);
    if (JSON.stringify(_filteredItems) !== JSON.stringify(activeProjects))
      setactiveProjects(_filteredItems);
  }, [projectIds, dict, initiativeIds]);

  const handleClick = (id: string) => {
    navigationApi.openPeekView({
      viewName: ViewNames.Detail,
      entity: {
        containerId: id,
        containerType: ContainerTypes.WORK,
      },
    });
  };

  return useMemo(() => {
    return (
      <>
        <div className={styles.lane}>
          <span
            className="label secondary extra-bold"
            style={{ paddingLeft: "10px" }}
          >
            Projects & Goals
          </span>
          {activeProjects.length === 0 ? (
            <span
              style={{ paddingLeft: "10px" }}
              className={`caption disabled`}
            >
              No projects to show
            </span>
          ) : (
            <>
              {" "}
              <div className={styles.projectsList}>
                {activeProjects.map((id) => (
                  <ProjectCard handleClick={handleClick} itemId={id} key={id} />
                ))}
              </div>
              {/* <div className={styles.navigator}>
                <div className={styles.navigatorBtn}>
                  <Button
                    onClick={() => {
                      const base = store.getState().workspace;
                      locationSubject.next(`/${base.slug}/roadmap`);
                    }}
                    buttonType={ButtonTypes.LINK}
                    icon={<RightOutlined />}
                  />
                </div>
              </div> */}
            </>
          )}
        </div>
        <div className={styles.divider}></div>
      </>
    );
  }, [activeProjects]);
};

export default WorkProjectsSection;
