import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import UserDisplay from "clarity-ui/UserDisplay";
import { userApi } from "clientApi/userApi";
import { useAbilityChecker } from "editor/utils/customHooks";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import {
  Abilities,
  CommandPaletteContext,
  ContainerTypes,
} from "utilities/types";

const ReviewerRow: React.FC<{
  reviewerId?: string | null;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ reviewerId, containerId, containerType }) => {
  const handleReviewerClick = () =>
    openCommandPalette(CommandPaletteContext.REVIEWER, {
      selectedItemIds: [containerId],
      slectedItemsType: containerType,
    });
  const canEditReviewer = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_REVIEWER,
  });

  return (
    <MetadataBlock
      title="Reviewer"
      disabled={!canEditReviewer}
      infoText={
        userApi.getUsernameForUser(reviewerId) ?? (
          <span className="disabled"> No reviewer </span>
        )
      }
      prefix={
        <UserDisplay
          id={reviewerId}
          borderColor="white"
          avatarSize={18}
          hideName={true}
        />
      }
      onClick={handleReviewerClick}
    />
  );
};

export default ReviewerRow;
