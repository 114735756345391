import { useAbilityChecker } from "editor/utils/customHooks";
import { useState, useLayoutEffect } from "react";
import { useShallowSelector } from "utilities/hooks";
import { Abilities } from "utilities/types";
import OptionLister from "../components/OptionLister";
import { getMultipleBlocksSelectedInDocumentOptions } from "../helpers/contextOptionGetters";
import { CommandPaletteOption } from "../helpers/types";

const BlocksInDocumentMode: React.FC = () => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  const paletteContext = useShallowSelector(
    (store) => store.commandPalette.params
  );

  useLayoutEffect(() => {
    const turnIntoOptions = getMultipleBlocksSelectedInDocumentOptions();
    setOptions([...turnIntoOptions]);
  }, [canEditEntity, paletteContext]);

  return <OptionLister options={options} />;
};

export default BlocksInDocumentMode;
