import { axiosInstance } from "index";
import {
  ADD_GROUP,
  UNARCHIVE_GROUP,
  CHANGE_USER_GROUP,
  DELETE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP_WORK,
} from "store/actions";
import store from "store/storeExporter";
import { HomeScreenObj, IGroup } from "utilities/types";
import { baseApi } from "./baseApi";
import notificationsApi from "./notificationsApi";
const { v4: uuidv4 } = require("uuid");

class GroupApi {
  createNewEmptyGroup() {
    const baseId = store.getState().workspace.id;

    const id = uuidv4();
    const newGroup: IGroup = {
      id: id,

      name: "New Group",
      description: "",
      slug: "",
      workspaceId: baseId,
      requiredRoles: [],
      home: {
        sectionIds: [] as string[],
        sectionsDict: {},
      },
      rank: "0z",
      showCycles: false,
    };

    return newGroup;
  }

  async addGroup(group: IGroup) {
    store.dispatch({
      type: ADD_GROUP,
      newGroup: group,
    });
    return axiosInstance.post("/api/group/", {
      group: group,
    });
  }

  deleteGroupDispatch(group: IGroup) {
    store.dispatch({
      type: DELETE_GROUP,
      deletedGroupId: group.id,
    });
  }

  async joinGroup(groupId: string) {
    const res = axiosInstance
      .post("/api/group/join", {
        groupId: groupId,
      })
      .then((res) => {
        this.joinedGroup({ changeType: "joined", groupId });
        return res;
      });

    this.loadGroupWork(groupId);
    return res;
  }

  async loadGroupWork(groupId: string) {
    const baseId = store.getState().workspace.id;
    Promise.all([
      baseApi.fetchAndSetBaseCycles(baseId, [groupId]),
      baseApi.fetchAndSetBaseTasks(baseId, [groupId]),
      baseApi.fetchAndSetBaseProjects(baseId, [groupId]),
    ]);
  }

  async deleteGroupWork(groupId: string) {
    store.dispatch({
      type: DELETE_GROUP_WORK,
      groupId,
    });
  }

  checkIfGroupMember(groupIds: string[]) {
    const userGroups = store.getState().groups.userGroups;

    const isPartOfOneGroup = userGroups.some((groupId) =>
      groupIds.includes(groupId)
    );

    return isPartOfOneGroup;
  }

  async leaveGroup(groupId: string) {
    return axiosInstance
      .post("/api/group/leave", {
        groupId: groupId,
      })
      .then((res) => {
        this.joinedGroup({ changeType: "left", groupId });
        return res;
      });
  }

  async kickFromGroup(groupId: string, userId: string) {
    return axiosInstance.post("/api/group/kick", {
      groupId: groupId,
      userId: userId,
    });
  }

  async joinedGroup(change: {
    changeType: "joined" | "left";
    groupId: string;
  }) {
    store.dispatch({
      type: CHANGE_USER_GROUP,
      changeType: change.changeType,
      groupId: change.groupId,
    });
  }

  async updateGroup(delta: Partial<IGroup>, id?: string) {
    if (!id) {
      return;
    }
    const currentGroup = store.getState().groups.dict[id];

    store.dispatch({
      type: UPDATE_GROUP,
      updatedGroup: {
        ...currentGroup,
        ...delta,
      },
    });

    return axiosInstance
      .post(`/api/group/update`, {
        delta,
        id,
      })
      .catch(() => {
        notificationsApi.displayError({
          title: "Oops something went wrong",
          body: "Sorry, we could not save your changes. Please try again later",
        });
      });
  }

  async updateGroupHome(home: HomeScreenObj, groupId?: string) {
    return this.updateGroup(
      {
        home: home,
      },
      groupId
    );
  }

  async updateGroupFromSocket(data: any) {
    if (data.type === "add") {
      store.dispatch({
        type: ADD_GROUP,
        newGroup: data.group,
      });
    } else if (data.type === "update") {
      store.dispatch({
        type: UPDATE_GROUP,
        updatedGroup: data.group,
      });
    } else if (data.type === "delete") {
      store.dispatch({
        type: DELETE_GROUP,
        deletedGroupId: data.group.id,
      });
    } else if (data.type === "unarchive") {
      store.dispatch({
        type: UNARCHIVE_GROUP,
        archiveGroupId: data.group.id,
      });
    }
  }

  async deleteGroup(groupId: string) {
    return axiosInstance.post(`/api/group/delete`, {
      groupId,
    });
  }

  async checkUniqueSlug(slug: string, baseId: string, id: string) {
    return axiosInstance.post(`/api/group/checkUniqueSlug`, {
      slug,
      baseId,
      id,
    });
  }

  async unarchiveGroup(groupId: string) {
    return axiosInstance
      .post(`/api/group/unarchive`, {
        groupId,
      })
      .then(() => {
        this.joinedGroup({ changeType: "joined", groupId });
        this.loadGroupWork(groupId);
      });
  }

  async getGroupChannelOptions() {
    const baseId = store.getState().workspace.id;

    return axiosInstance
      .get(`/api/group/discord/channels/${baseId}`)
      .then((res) => {
        if (res.data) {
          return res.data;
        }
        return [];
      });
  }

  async connectGroupToChannel(groupId?: string, channelId?: string) {
    if (!groupId) {
      return;
    }
    return axiosInstance.post(`/api/group/discord/connect`, {
      groupId,
      channelId,
    });
  }

  async removeGroupChannel(groupId: string) {
    return axiosInstance.post(`/api/group/discord/disconnect`, {
      groupId,
    });
  }

  isGroupMember(groupId: string | string[] | undefined) {
    if (!groupId) {
      return true;
    }
    const state = store.getState();
    const userId = state.user?.id;
    const userGroups = state.groups?.userGroups;

    if (!userId) {
      return false;
    }

    if (!userGroups || userGroups.length === 0) {
      return false;
    }

    if (!groupId || groupId.length === 0) {
      return true;
    }

    if (!Array.isArray(groupId)) {
      const group = state.groups.dict[groupId];

      if (!group) {
        return false;
      }
      if (group.members?.includes(userId)) {
        return true;
      } else {
        return false;
      }
    } else {
      let atLeastOneGroup = false;

      for (const id of groupId) {
        const group = state.groups.dict[id];

        if (group && group.members?.includes(userId)) {
          atLeastOneGroup = true;
          break;
        }
      }
      return atLeastOneGroup;
    }
  }

  canJoinGroup(groupId: string) {
    const state = store.getState();

    const group = state.groups.dict[groupId];

    const userRoles = state.client.roleIds;

    const userPermissions = state.client.permissions;

    if (group.requiredRoles.length === 0) {
      return true;
    }

    if (userPermissions.admin) {
      return true;
    }
    for (const requiredRoleId of group.requiredRoles) {
      if (userRoles?.includes(requiredRoleId)) {
        return true;
      }
    }
    return false;
  }
}

export const groupApi = new GroupApi();
