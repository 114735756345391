import { useEffect, useState } from "react";
import Button, { ButtonTypes } from "../Button";
import { batch, connect } from "react-redux";
import Logo from "images/clarity-logo-color-small.svg";
import styles from "./styles.module.scss";
import { IUserObj } from "utilities/types";
import { useHistory, useLocation } from "react-router-dom";
import { SET_AUTHENTICATED_USER } from "store/actions";
import { VerificationTypes } from "utilities/types";
import LogInForm from "screens/logIn/LogInForm";
import store from "store/storeExporter";
import { axiosInstance } from "index";

interface Props {
  user: IUserObj;
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

const VerificationPage = ({ user, setAuthenticatedUser }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const useQuery = () => new URLSearchParams(location.search);
  const [isSent, setIsSent] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isVerified, setIsVerified] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [verificationType, setVerificationType] = useState<
    "Change Email" | null
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showUserSettings, setShowUserSettings] = useState<boolean>(false);

  const query = useQuery();
  const verifyToken = query.get("xvt") || "";

  useEffect(() => {
    if (!user || !user.id) {
      return;
    }
    if (verifyToken) {
      axiosInstance
        .post("/api/user/verify", { verifyToken })
        .then(({ data }) => {
          if (data.status === 1) {
            setIsVerified(true);
            if (data.payload && data.payload.type === "Change Email") {
              setVerificationType(data.payload.type);
              axiosInstance
                .get("/api/user")
                .then((res) => {
                  const { payload: user } = res.data;
                  console.clear();
                  batch(() => {
                    store.dispatch({ type: SET_AUTHENTICATED_USER, user });
                  });
                  history.push(`/profile/settings`);
                })
                .catch((err) => {});
            }
          } else {
            history.push("/");
          }
        });
    } else {
      user.verified && history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleResendClick = () => {
    axiosInstance
      .post("/api/user/resendVerify", { type: VerificationTypes.NEW_USER })
      .then(({ data }) => {
        if (data.status === 1) {
          setIsSent(true);
        }
      });
  };

  return (
    <div className={styles.verification}>
      {showUserSettings && <>Show user settings</>}
      <img
        src={Logo}
        style={{ width: "125px", marginBottom: "56px" }}
        alt="Clarity Logo"
      />
      <h2>Verify your email address</h2>
      <div className={styles.verification__desc}>
        <p>
          Before you can get started on Clarity, you’ll need to verify your
          email address
        </p>
        <p>
          Click the link in the email we just sent to{" "}
          <strong>{user.email}</strong> This link will remain valid for 24
          hours.
        </p>
      </div>
      {(!user || !user.id) && (
        <LogInForm redirectPath={location.pathname + location.search} />
      )}
      <div className={styles.verification__btns}>
        {isSent ? (
          <p>Email Sent</p>
        ) : (
          <Button buttonType={ButtonTypes.PRIMARY} onClick={handleResendClick}>
            Resend verification email
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAuthenticatedUser: (user: IUserObj) =>
    dispatch({ type: SET_AUTHENTICATED_USER, user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationPage);
