import store, { $focusOn, contextWithNoBlocks } from "store/storeExporter";
import { LineType } from "utilities/lineUtilities";
import { ContainerTypes } from "utilities/types";
import {
  BlockReducerState,
  Block,
  BlockObject,
  ContainerContext,
  FocusObject,
} from "../blockReducer";

export const transactionObject: {
  [id: string]: { type: UPDATE_TYPES; id: string; data: Partial<Block> };
} = {};

export const setToTransaction = (
  block: Block,
  updateType: UPDATE_TYPES,
  delta: Partial<Block>
) => {
  transactionObject[block.id] = {
    type: updateType,
    id: block.id,
    data: delta,
  };
};
export let updatedBlocks: BlockObject = {};
export const resetUpdateBlocks = () => {
  updatedBlocks = {};
};
export let containerCleanupDict: { [id: string]: any[] } = {};
export const clearContainerCleanupId = (id: string) => {
  if (id && containerCleanupDict[id]) {
    delete containerCleanupDict[id];
  }
};

export enum UPDATE_TYPES {
  update = "update",
  delete = "delete",
  insert = "insert",
}

export const getBlockAncestors = (dict: BlockObject, block: Block) => {
  const ancestors: string[] = [];
  startAncestorSearch(dict, block, ancestors);
  return ancestors.reverse();
};

const startAncestorSearch = (
  dict: BlockObject,
  block: Block,
  ancestors: string[]
) => {
  if (block.parentId && block.parentId !== "") {
    ancestors.push(block.parentId);
    const parentBlock = getBlockById(dict, block.parentId);
    startAncestorSearch(dict, parentBlock, ancestors);
  }
};

export const updateCurrentBlock = (
  newState: BlockReducerState,
  newBlockData: Block,
  type: UPDATE_TYPES,
  delta?: Partial<Block>
) => {
  newState.dict = { ...newState.dict };
  // checkDeltaCases(newState, newBlockData, delta);
  const newBlockValue = delta ? { ...newBlockData, ...delta } : newBlockData;
  newBlockValue.ancestors = getBlockAncestors(newState.dict, newBlockValue);
  if (newBlockValue.containerType === ContainerTypes.PROJECT) {
    if ([LineType.Title, LineType.work].includes(newBlockValue.lineType)) {
      if (newBlockValue.containerId !== newBlockValue.referencingContainerId) {
        const contextId =
          newBlockValue.containerType + newBlockValue.containerId;
        const currentContext = getCurrentContext(newState, contextId);
        if (
          currentContext.state &&
          !currentContext.state.workItems.includes(newBlockValue.id)
        ) {
          currentContext.state = { ...currentContext.state };
          currentContext.state.workItems = [...currentContext.state.workItems];
          currentContext.state.workItems.push(newBlockValue.id);
          updateCurrentContext(newState, currentContext);
        } else {
          if (currentContext.state) {
            currentContext.state = { ...currentContext.state };
            currentContext.state.workItems = [
              ...currentContext.state.workItems,
            ];
            updateCurrentContext(newState, currentContext);
          }
        }
      }
    }
  }
  newState.dict[newBlockValue.id] = newBlockValue;
  updatedBlocks[newBlockValue.id] = newBlockValue;
};

export const updateFocus = (
  newState: BlockReducerState,
  focusDelta: Partial<FocusObject>
) => {
  const newFocus = { ...newState.focusOn, ...focusDelta };
  newState.focusOn = newFocus;
  $focusOn.next(newState.focusOn);
  return newFocus;
};

// const insertInCorrectDocRank = (
//   dict: BlockObject,
//   updatedBlock: Block,
//   iterateArray: string[]
// ) => {
//   let index = 0;
//   let found = false;
//   for (const lineId of iterateArray) {
//     const block: Block = dict[lineId];
//     if (
//       block.documentRank &&
//       updatedBlock.documentRank &&
//       block.documentRank.localeCompare(updatedBlock.documentRank) >= 0
//     ) {
//       iterateArray.splice(index, 0, updatedBlock.id);
//       found = true;
//       break;
//     }
//     index++;
//   }
//   if (!found) {
//     iterateArray.push(updatedBlock.id);
//   }
// };

export const updateCurrentContext = (
  newState: BlockReducerState,
  currentContext: ContainerContext
) => {
  newState.contexts[currentContext.id] = currentContext;
  if (
    currentContext.isFullContainerLoaded &&
    (currentContext.state.rootBlocksIds.length === 0 ||
      (currentContext.containerType === ContainerTypes.NOTE &&
        currentContext.state.rootBlocksIds.length === 1))
  ) {
    contextWithNoBlocks[currentContext.id] = currentContext;
  }
};

export const getCurrentContext = (
  newState: BlockReducerState,
  contextId: string
) => {
  newState.contexts = { ...newState.contexts };
  newState.contexts[contextId] = {
    ...newState.contexts[contextId],
  };
  const currentContext = newState.contexts[contextId];
  return currentContext;
};

export const getBlockById = (dict: BlockObject, id: string): Block => {
  return { ...dict[id] };
};

export const getCompoundRank = (blockId: string) => {
  const blockState = store.getState().blocks;
  let compoundRank = "";

  const addRankToCompound = (blockId: string): string => {
    const block = getBlockById(blockState.dict, blockId);
    compoundRank = block.documentRank + compoundRank;
    if (block.parentId && block.parentId !== "") {
      return addRankToCompound(block.parentId);
    }
    return compoundRank;
  };

  compoundRank = addRankToCompound(blockId);

  return compoundRank;
};

export const getSortedSelectedBlocks = (
  selectedIds: string[],
  skipFilter?: boolean
) => {
  const slectedArray = [...selectedIds];
  const blockDict = store.getState().blocks.dict;
  return slectedArray
    .filter((id) => {
      const block = getBlockById(blockDict, id);
      return (
        skipFilter || !block.parentId || !slectedArray.includes(block.parentId)
      );
    })
    .sort((a, b) => {
      const rankA = getCompoundRank(a);
      const rankB = getCompoundRank(b);
      if (rankA && rankB) {
        return rankA.localeCompare(rankB, "en");
      }
      return 0;
    });
};

export const getBlockIndex = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
) => {
  let index = -1;
  if (block.parentId === "" || !block.parentId) {
    index = currentContext.state.rootBlocksIds?.indexOf(block.id);
  } else {
    const parentBlock = getBlockById(newState.dict, block.parentId);
    index = parentBlock.children?.indexOf(block.id);
  }
  return index;
};

export const getContainerArray = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
) => {
  if (block.parentId === "") return currentContext.state.rootBlocksIds;
  else {
    const parentBlock = getBlockById(newState.dict, block.parentId);
    return parentBlock.children;
  }
};

export const removeBlockFromContainerArray = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
) => {
  const index = getBlockIndex(newState, block, currentContext);
  if (index < 0) return;
  if (block.parentId === "") {
    currentContext.state.rootBlocksIds = [
      ...currentContext.state.rootBlocksIds,
    ];
    currentContext.state.rootBlocksIds.splice(index, 1);
    updateCurrentContext(newState, currentContext);
  } else {
    const parentBlock = getBlockById(newState.dict, block.parentId);
    parentBlock.children = [...parentBlock.children];
    parentBlock.children.splice(index, 1);
    updateCurrentBlock(newState, parentBlock, UPDATE_TYPES.update);
  }
};

export const getNextBlockId = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
): string | null => {
  const index = getBlockIndex(newState, block, currentContext);

  if (
    block.children &&
    block.children.length > 0 &&
    !block.isFolded &&
    block.lineType !== LineType.table
  ) {
    const nextId = block.children[0];
    if (nextId) return nextId;
    else return null;
  } else {
    const toCompare =
      block.parentId === ""
        ? currentContext.state.rootBlocksIds
        : getBlockById(newState.dict, block.parentId).children;
    if (index === toCompare.length - 1 && block.parentId !== "") {
      if (block.indentLevel === 0) {
        return null;
      } else {
        const parentLine = getBlockById(newState.dict, block.parentId);
        return findFirstAncestorSibling(newState, parentLine, currentContext);
      }
    } else {
      const nextLineId =
        block.parentId === ""
          ? currentContext.state.rootBlocksIds[index + 1]
          : getBlockById(newState.dict, block.parentId).children[index + 1];
      return nextLineId;
    }
  }
};

export const findFirstAncestorSibling = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
): string | null => {
  let parentIndex: number;
  if (block.parentId === "") {
    parentIndex = getBlockIndex(newState, block, currentContext);
    const parentSiblingId = currentContext.state.rootBlocksIds[parentIndex + 1];
    if (parentSiblingId) return parentSiblingId;
    else return null;
  } else {
    const grandParentBlock = getBlockById(newState.dict, block.parentId);
    parentIndex = grandParentBlock.children.indexOf(block.id);
    if (grandParentBlock.children.length > parentIndex + 1) {
      const parentSiblingLineId = grandParentBlock.children[parentIndex + 1];
      return parentSiblingLineId;
    } else
      return findFirstAncestorSibling(
        newState,
        grandParentBlock,
        currentContext
      );
  }
};

export const getPreviousBlock = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
): string | null => {
  let index = getBlockIndex(newState, block, currentContext);
  if (index < 0) return null;
  if (index === 0 && (block.parentId !== "" || !block.parentId)) {
    if (block.parentId) return block.parentId;
    else return null;
  } else {
    const prevBlockId =
      block.parentId === ""
        ? currentContext.state.rootBlocksIds[index - 1]
        : getBlockById(newState.dict, block.parentId)?.children
        ? getBlockById(newState.dict, block.parentId).children[index - 1]
        : null;
    if (!prevBlockId) return null;
    const prevBlock = getBlockById(newState.dict, prevBlockId);
    if (!prevBlock) return block.id;
    if (
      prevBlock.children &&
      prevBlock.children.length > 0 &&
      !prevBlock.isFolded
    ) {
      const res = findLastDescendent(newState, prevBlock.children);
      return res;
    } else return prevBlock.id;
  }
};

export const findLastDescendent = (
  newState: BlockReducerState,
  childArray: string[]
): string | null => {
  const id = childArray[childArray.length - 1];
  const child = getBlockById(newState.dict, id);
  if (
    childArray[childArray.length - 1] &&
    child.children &&
    child.children.length > 0 &&
    !child.isFolded
  )
    return findLastDescendent(newState, child.children);
  else return child.id;
};

export const getPreviousSibling = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
) => {
  let index = getBlockIndex(newState, block, currentContext);
  if (!block.parentId || block.parentId === "") {
    return currentContext.state.rootBlocksIds[index - 1];
  } else {
    const parentBlock = getBlockById(newState.dict, block.parentId);
    return parentBlock.children[index - 1];
  }
};

export const getPreviousSiblingOrParent = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
) => {
  let index = getBlockIndex(newState, block, currentContext);
  if (!block.parentId || block.parentId === "") {
    return currentContext.state.rootBlocksIds[index - 1];
  } else {
    const parentBlock = getBlockById(newState.dict, block.parentId);
    return parentBlock.children[index - 1] ?? parentBlock.id;
  }
};

export const getNextSibling = (
  newState: BlockReducerState,
  block: Block,
  currentContext: ContainerContext
) => {
  let index = getBlockIndex(newState, block, currentContext);
  if (block.parentId === "") {
    return currentContext.state.rootBlocksIds[index + 1];
  } else {
    const parentBlock = getBlockById(newState.dict, block.parentId);
    return parentBlock.children[index + 1];
  }
};

export const getNextBlockOfLastChild = (
  newState: BlockReducerState,
  id: string,
  currentContext: ContainerContext
) => {
  const block = getBlockById(newState.dict, id);
  if (block.children?.length === 0)
    return getNextBlockId(newState, block, currentContext);
  const lastChildId = findLastDescendent(newState, block.children);
  if (lastChildId) {
    const lastChildBlock = getBlockById(newState.dict, lastChildId);
    const nextId = getNextBlockId(newState, lastChildBlock, currentContext);
    return nextId;
  }
};

export const checkIfAncestorBlockSelected = (
  newState: BlockReducerState,
  parentId: string
): any => {
  if (parentId && parentId !== "") {
    const parentBlock = getBlockById(newState.dict, parentId);
    if (parentBlock.selected) return true;
    if (parentBlock.parentId && parentBlock.parentId !== "")
      return checkIfAncestorBlockSelected(newState, parentBlock.parentId);
  }
  return false;
};

export const checkIfBlockIsTitle = (
  newState: BlockReducerState,
  id: string
) => {
  const block = getBlockById(newState.dict, id);
  if (
    block.lineType === LineType.Title &&
    block.referencingContainerType === ContainerTypes.NOTE
  )
    return true;
  return false;
};

export const checkPreviousBlockIsTitle = (
  newState: BlockReducerState,
  id: string
) => {
  const block = getBlockById(newState.dict, id);
  const currentContext = getCurrentContext(
    newState,
    block.containerType + block.containerId
  );
  const previousBlockId = getPreviousSibling(newState, block, currentContext);
  if (previousBlockId) {
    return checkIfBlockIsTitle(newState, previousBlockId);
  } else {
    if (block.indentLevel === 0) return true;
  }
};

export const getAllBlockTree = (
  blockIds: string[],
  blockDict: any,
  allIds: string[]
): string[] => {
  let newAllIds = [...allIds];
  return blockIds.flatMap((blockId) => {
    const block = blockDict[blockId];
    if (block.children.length > 0) {
      newAllIds = [block.id, ...block.children, ...allIds];
      return [
        ...allIds,
        ...getAllBlockTree(block.children, blockDict, newAllIds),
      ];
    } else {
      return [block.id, ...newAllIds];
    }
  });
};
