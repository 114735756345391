import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStore } from "react-redux";
import styles from "./searchResults.module.scss";
import { ContainerTypes, ViewNames } from "utilities/types";
import { LineType } from "utilities/lineUtilities";
import moment from "moment";
import { flashBlock, flashBlockSubject } from "App";
import { useSearch } from "store/reducers/clientReducer";
import { useOptionalClassName } from "utilities/hooks";
import {
  useActiveMenuItemContext,
  useScrollIntoViewOnActive,
} from "components/FabBtn";
import { getCorrectLink } from "utilities/linkUtilities";

import {
  LoadingOutlined,
  FormOutlined,
  FileDoneOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { getNameFromContainer } from "modules/containerHelpers";
import { getHtml } from "editor/utils/blockValueHelpers";
import navigationApi from "clientApi/navigationApi";
import StarTwoTone from "icons/Components/StarTwoTone";

const SearchResults: React.FC<any> = () => {
  const { activeItemIndex, setActiveItemIndex } = useActiveMenuItemContext();

  const { resultArray, isSearching, queryString } = useSearchResultsState();

  useEffect(() => {
    setActiveItemIndex(0);
  }, [resultArray]);

  const showPlainRecentlyOpened =
    !isSearching && !queryString && resultArray.length === 0;

  const showRecentlyOpenedWithNoResults =
    !isSearching && !showPlainRecentlyOpened && resultArray.length === 0;

  return (
    <div className={styles.container}>
      {isSearching ? (
        <span style={{ opacity: "0.4" }}>
          <LoadingOutlined /> Searching...
        </span>
      ) : showRecentlyOpenedWithNoResults ? (
        <>
          <div className={styles.containerEntry}>
            <span>No results</span>
          </div>
        </>
      ) : (
        <>
          {resultArray.map((resContainer: any, index) => (
            <ContainerSearchResult
              key={resContainer.id}
              container={resContainer}
              isActive={index === activeItemIndex}
            />
          ))}
        </>
      )}
    </div>
  );
};

function useSearchResultsState() {
  const state = useSearch();

  return {
    ...state,
  };
}

interface IContainerSearchResultProps {
  container: any;
  isActive: boolean;
}

const ContainerSearchResult: React.FC<IContainerSearchResultProps> = ({
  container,
  isActive,
}) => {
  const { containerEntryClassName, itemRef } = useContainerSearchResultState({
    isActive,
  });
  const base = useStore().getState().workspace;
  const prefix = getCorrectPrefix(base, container);

  const test = getHtml(container.nameValue);
  const Icon = prefix.icon;
  return (
    <Link
      to={getCorrectLink(base, container)}
      className={styles.entry}
      onClick={(e) => {
        if (e.shiftKey) {
          e.preventDefault();
          navigationApi.openSplitView({
            viewName: ViewNames.Detail,
            containerId: container.id,
            containerType: container.type,
          });
        }
      }}
    >
      <div className={containerEntryClassName} ref={itemRef}>
        <span className={styles.title}>
          <span className={styles.ml}>
            <Icon />
          </span>
          <span className={styles.preTitle}>{prefix.preTitle}</span>
          {container.type === ContainerTypes.NOTE ? (
            !container.isWeekly ? (
              <span>
                {getNameFromContainer(container, ContainerTypes.NOTE)}
              </span>
            ) : (
              <span>
                Week of {moment(container.dateCreated).format("MMMM D, YYYY")}
              </span>
            )
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: test,
              }}
            ></span>
          )}
        </span>
        {container.blocks.map((block: any) => {
          if (block.lineType === LineType.Title)
            return <React.Fragment key={block.id}></React.Fragment>;
          return (
            <div
              className={styles.block}
              key={block.id}
              onClick={() => {
                flashBlock.id = block.id;
                flashBlockSubject.next(flashBlock);
              }}
              dangerouslySetInnerHTML={{ __html: getHtml(block.value) }}
            ></div>
          );
        })}
      </div>
    </Link>
  );
};

function useContainerSearchResultState({
  isActive,
}: Pick<IContainerSearchResultProps, "isActive">) {
  const itemRef = useScrollIntoViewOnActive({ isActive });

  const containerEntryClassName = useOptionalClassName({
    baseStyle: styles.containerEntry,
    pairs: [
      {
        extraStyle: styles.containerEntryActive,
        withExtra: isActive,
      },
    ],
  });

  return {
    containerEntryClassName,
    itemRef,
  };
}

const getCorrectPrefix = (base: any, container: any) => {
  let icon = FileDoneOutlined;
  let preTitle = "";
  if (container.type === ContainerTypes.NOTE) icon = FormOutlined;
  if (container.workType) {
    icon = FileDoneOutlined;
    if (container.workType === ContainerTypes.TASK) icon = CheckCircleOutlined;
    preTitle = `#${container.projectId}`;
  }
  if (container.isWeekly) icon = StarTwoTone as any;
  return {
    icon,
    preTitle,
  };
};

export default SearchResults;
