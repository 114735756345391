import React, { useEffect } from "react";
import { batch } from "react-redux";
import {
  setPaneTopNavViewType,
  TopNavbarType,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import { ChunkDestination } from "utilities/stateTypes";
import { GeneralViewsNames } from "utilities/types";
import Roadmap from "../../Roadmap";

const BaseRoadmap: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  usePageDataSetter(paneId, {
    title: "Roadmap",
  });

  useEffect(() => {
    batch(() => {
      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.base,
      });
    });

    return () => {
      batch(() => {
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, []);

  return (
    <div
      className={`flex-fill page__mxWidth1355`}
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      <Roadmap paneId={paneId} viewId={`${GeneralViewsNames.Roadmap}`} />
    </div>
  );
};

export default BaseRoadmap;
