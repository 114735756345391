export const isElementNotVisible = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom - 200 < 0 ||
    rect.top + 200 >
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */
  );
};

export const elementIsAtTop = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return rect.top - 250 < 0;
};

export const elementIsAtBottom = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.bottom - 250 <
    (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const moveCaretToPreviousPosition = (
  element: HTMLElement | Element,
  caretPosition: number
) => {
  if (!element) return;
  if (element.classList.contains("code")) {
    element = element.querySelector(".cm-content") as HTMLDivElement;
    if (!element) return;
  }

  for (let node of Array.from(element.childNodes)) {
    if (node.nodeType === 3) {
      if (node.nodeValue && node.nodeValue.length >= caretPosition) {
        setRange(node, caretPosition);
        return -1;
      } else if (
        Array.from(element.childNodes).indexOf(node) ===
        element.childNodes.length - 1
      ) {
        if (node.childNodes.length > 0) {
          caretPosition = moveCaretToPrevPositionChildIteration(
            node,
            caretPosition,
            true
          );
          if (caretPosition === -1) {
            return -1;
          }
        } else {
          if (node.nodeValue) setRange(node, node.nodeValue.length);
          return -1;
        }
      } else {
        if (node.nodeValue) caretPosition -= node.nodeValue.length;
      }
    } else {
      if (
        !node.nodeValue &&
        (node.nodeName === "BR" || node.nodeName === "DIV")
      ) {
        if (caretPosition === 0) {
          setRange(node, caretPosition);
          return -1;
        }
      }
      let isLastChild = false;
      if (
        Array.from(element.childNodes).indexOf(node) ===
        element.childNodes.length - 1
      ) {
        isLastChild = true;
      }
      caretPosition = moveCaretToPrevPositionChildIteration(
        node,
        caretPosition,
        isLastChild
      );
      if (caretPosition === -1) {
        return -1;
      }
    }
  }
  if (caretPosition) {
    const lastEl = getLastDescendent(element);
    setRange(lastEl, lastEl.nodeValue ? lastEl.nodeValue.length : 0);
  }
  return caretPosition;
};

const getLastDescendent = (
  element: HTMLDivElement | HTMLElement | Element | ChildNode
): HTMLDivElement | HTMLElement | Element | ChildNode => {
  if (element.childNodes.length === 0) return element;
  const lastEl = element.childNodes[element.childNodes.length - 1];
  return getLastDescendent(lastEl);
};

export const moveCaretToPrevPositionChildIteration = (
  element: ChildNode,
  caretPosition: number,
  isLastDesc?: boolean
) => {
  for (let node of Array.from(element.childNodes)) {
    if (node.nodeType === 3) {
      if (node.nodeValue && node.nodeValue.length >= caretPosition) {
        setRange(node, caretPosition);
        return -1;
      } else {
        if (!node.nodeValue && node.nodeName === "BR") {
          if (caretPosition === 0) {
            setRange(node, caretPosition);
            return -1;
          }
        }
        if (node.nodeValue)
          caretPosition =
            caretPosition - (node.nodeValue.length ? node.nodeValue.length : 0);
      }
    } else {
      let isLasDesc = false;
      if (
        Array.from(element.childNodes).indexOf(node) ===
        element.childNodes.length - 1
      )
        isLasDesc = true;
      caretPosition = moveCaretToPrevPositionChildIteration(
        node,
        caretPosition,
        isLasDesc && isLastDesc
      );
      if (caretPosition === -1) {
        return -1;
      }
    }
  }
  // if (isLastDesc) {
  //   if (
  //     (element.nodeValue && element.nodeValue.length >= caretPosition) ||
  //     !element.nodeValue
  //   ) {
  //     try {
  //       const checkBrEL = checkIfLastElementIsBr(element);
  //       if (checkBrEL) return -1;
  //       setRange(element, caretPosition);
  //     } catch {
  //       try {
  //         setRange(element, 0);
  //       } catch {}
  //     }
  //     return -1;
  //   } else {
  //     caretPosition =
  //       caretPosition - (element.textContent ? element.textContent.length : 0);
  //   }
  // }
  return caretPosition;
};

export const checkIfLastElementIsBr = (element: ChildNode) => {
  if (element.nodeName === "BR") {
    try {
      const brEl = element;
      const parent = element.parentElement;
      if (parent) {
        const newEl = document.createTextNode("0");
        parent.appendChild(newEl);
        parent.removeChild(brEl);
        setRange(parent, 0);
        parent.appendChild(brEl);
        parent.removeChild(newEl);
      }
      return true;
    } catch {}
  }
  return false;
};

const setRange = (node: any, position: number) => {
  if (position < 0) position = 0;
  const range = document.createRange(),
    sel = window.getSelection();
  range.setStart(node, position);
  range.setEnd(node, position);
  if (sel) {
    sel.removeAllRanges();
    sel.addRange(range);
    range.collapse(false);
  }
};

export const getLastChild = (element: any): any => {
  if (!element.childNodes || element.childNodes.length === 0) {
    return element;
  } else return getLastChild(element.childNodes[element.childNodes.length - 1]);
};

export const focus = (refOfElement: any, numberOfElementsAdded: number) => {
  // number of elements added is used to reposition the cursor in between the lines existing content and the one added by the delete
  if (refOfElement && refOfElement.current) {
    const element = refOfElement.current;
    if (numberOfElementsAdded || numberOfElementsAdded === 0) {
    }
    element.focus();
  }
};

export const moveCaretAtLineEnd = (
  element: HTMLAnchorElement | HTMLDivElement,
  numberOfElementsAdded: number
) => {
  if (element.classList.contains("code")) {
    element = element.querySelector(".cm-content") as HTMLDivElement;
    if (!element) return;
  }

  const text = element.innerText;
  if (!text) return;

  const caretPosition = text.length - numberOfElementsAdded;
  moveCaretToPreviousPosition(element, caretPosition);
};

export const getInnerCaretPosition = (
  target: (EventTarget & Element) | null,
  node: any,
  caretPosition: number
) => {
  let innerCaretPosition = caretPosition;
  if (target) {
    for (const child of Array.from(target.childNodes)) {
      if (child.childNodes) {
        const resp = checkSubChildren(child, node, innerCaretPosition);
        if (resp) return resp;
      }
      if (child === node) {
        return innerCaretPosition;
      } else {
        innerCaretPosition = child.textContent
          ? innerCaretPosition - child.textContent.length
          : innerCaretPosition;
      }
    }
  }
  return innerCaretPosition;
};

const checkSubChildren = (
  child: any,
  node: any,
  innerCaretPosition: number
) => {
  for (const subChild of child.childNodes) {
    if (subChild.childNodes) {
      const resp: any = checkSubChildren(subChild, node, innerCaretPosition);
      if (resp) return resp;
    }
    if (subChild === node) {
      return innerCaretPosition;
    } else {
      innerCaretPosition = subChild.textContent
        ? innerCaretPosition - subChild.textContent.length
        : innerCaretPosition;
    }
  }
};

export const createRange = (
  element: Node | Element,
  start?: number,
  end?: number
): Range => {
  const newRange = new Range();
  if (!start || start < 0) start = 0;
  if (element.childNodes.length === 0) {
    newRange.setStart(element, 0);
    newRange.setEnd(element, 0);
    return newRange;
  }
  const startData = nodeIterator(element, { current: start });
  newRange.setStart(startData.element, startData.innerPosition);

  if (end && element.textContent && end <= element.textContent.length) {
    const endData = nodeIterator(element, { current: end });
    newRange.setEnd(endData.element, endData.innerPosition);
  } else {
    newRange.setEnd(startData.element, startData.innerPosition);
  }
  return newRange;
};

const nodeIterator = (
  element: Node | Element,
  positionRef: { current: number }
): any => {
  let finishedObject: any = {
    finished: false,
  };
  for (const child of Array.from(element.childNodes)) {
    if (child.nodeType === 3) {
      const textContent = child.textContent ? child.textContent : "";
      if (textContent.length >= positionRef.current) {
        finishedObject = {
          finished: true,
          element: child,
          innerPosition: positionRef.current,
        };
        return finishedObject;
      } else {
        positionRef.current = positionRef.current - textContent.length;
      }
    } else {
      const resp = nodeIterator(child, positionRef);
      finishedObject = resp;
      if (finishedObject.finished) return finishedObject;
    }
  }

  return finishedObject;
};
