import ReadOnlyBlocksContainer from "components/readOnlyBlocks/ReadOnlyBlocksContainer";
import React, { useRef } from "react";
import { prevState } from "store/storeExporter";
import styles from "./blockCitations/blockCitations.module.scss";

const BlockCitations: React.FC<any> = (props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  if (!props.blockMentions) return <></>;
  return (
    <div
      className={styles.BlockCitations}
      ref={containerRef}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ReadOnlyBlocksContainer
        unlinked={false}
        linkButton={null}
        blocksObject={prevState.value.blocks.dict}
        blockIds={props.blockMentions}
        indentLevel={0}
        allowToggle={true}
        parentId={""}
        containerRef={containerRef}
        rootCustomClass={""}
        showDefaultRootBullets={true}
        parentListType={"bullet-1"}
        paneId={props.paneId}
      />
    </div>
  );
};

export default BlockCitations;
