import React, { useContext, useLayoutEffect, useState } from "react";
import { useShallowSelector } from "utilities/hooks";
import { CommandPaletteContext } from "utilities/types";
import OptionLister from "../components/OptionLister";
import {
  CommandPaletteOption,
  CommandPaletteState,
  contextMatch,
} from "../helpers/types";

const WorkView: React.FC = () => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const paletteStateContext = useContext(CommandPaletteState);
  const paletteParams = useShallowSelector(
    (store) => store.commandPalette.params
  );

  useLayoutEffect(() => {
    const contextConfig = contextMatch[CommandPaletteContext.WORK_VIEW];
    setOptions(contextConfig.getOptions());
    paletteStateContext.topBarText.next(contextConfig.topBarText);
  }, [paletteParams]);

  return <OptionLister options={options} />;
};

export default WorkView;
