import { WorkActivityTypes } from "utilities/types";
import styles from "../styles/activity.module.scss";
import {
  FileDoneOutlined,
  CalendarTwoTone,
  UserOutlined,
  TeamOutlined,
  DollarTwoTone,
  ClockCircleTwoTone,
  AuditOutlined,
} from "@ant-design/icons";
import StatusDisplay from "components/StatusDisplay";
import EditTwoTone from "icons/Components/EditTwoTone";
import PriorityDisplay from "components/PriorityDisplay";
import { IWorkActivityProps } from "../types";
import TagTwoTone from "icons/Components/TagTwoTone";
import ThunderboltTwoTone from "icons/Components/ThunderboltTwoTone";

export const MetadataTypeIndicator: React.FC<IWorkActivityProps> = (props) => {
  switch (props.workActivity.type) {
    case WorkActivityTypes.COMMENT: {
      return <></>;
    }
    case WorkActivityTypes.MENTION: {
      return (
        <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
          <TagTwoTone
            style={{
              fontSize: "16px",
              color: "#b295a5",
            }}
          />
        </IconWrapper>
      );
    }
    case WorkActivityTypes.METADATA_CHANGE: {
      switch (props.workActivity.metadataType) {
        case "Status": {
          const action = props.workActivity.delta;
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <StatusDisplay
                size="large"
                showName={false}
                statusId={action.metadata.nextValue}
                customStyle={{ margin: 0 }}
                noSpace={true}
              />
            </IconWrapper>
          );
        }

        case "Rename":
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <EditTwoTone />
            </IconWrapper>
          );
        case "Priority": {
          const action = props.workActivity.delta;
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <PriorityDisplay
                showName={false}
                priorityLevel={action.metadata.nextValue ?? 1}
              />
            </IconWrapper>
          );
        }

        case "Project":
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <FileDoneOutlined
                style={{
                  fontSize: "16px",
                  color: "#b295a5",
                }}
              />
            </IconWrapper>
          );
        case "Tags":
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <TagTwoTone
                style={{
                  fontSize: "16px",
                  color: "#b295a5",
                }}
              />
            </IconWrapper>
          );
        case "Assigne":
        case "Reviewer":
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <UserOutlined
                style={{
                  fontSize: "16px",
                  color: "#b295a5",
                }}
              />
            </IconWrapper>
          );
        case "Contributors":
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <TeamOutlined
                style={{
                  fontSize: "16px",
                  color: "#b295a5",
                }}
              />
            </IconWrapper>
          );
        case "Reward":
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <DollarTwoTone
                style={{
                  fontSize: "16px",
                  color: "#b295a5",
                }}
              />
            </IconWrapper>
          );
        case "Due Date":
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <CalendarTwoTone
                style={{
                  fontSize: "16px",
                  color: "#b295a5",
                }}
              />
            </IconWrapper>
          );
        case "Cycle":
          return (
            <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
              <ThunderboltTwoTone
                style={{
                  fontSize: "16px",
                  color: "#b295a5",
                }}
              />
            </IconWrapper>
          );
      }
      return <></>;
    }

    case WorkActivityTypes.REVIEW_REQUEST: {
      return (
        <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
          <AuditOutlined
            style={{
              fontSize: "16px",
              color: "#b295a5",
            }}
          />
        </IconWrapper>
      );
    }
    case WorkActivityTypes.REVIEW_REQUEST_ACCEPTED: {
      return (
        <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
          <AuditOutlined
            style={{
              fontSize: "16px",
              color: "#b295a5",
            }}
          />
        </IconWrapper>
      );
    }
    case WorkActivityTypes.WORK_ITEM_CREATED: {
      return (
        <IconWrapper isFirst={props.isFirstActiveEntry ?? false}>
          <ClockCircleTwoTone
            style={{
              fontSize: "16px",
              color: "#b295a5",
            }}
          />
        </IconWrapper>
      );
    }
    default:
      return <></>;
  }
};

const IconWrapper: React.FC<{ isFirst: boolean }> = ({ children, isFirst }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "37px",
        margin: 0,
        gap: 0,
      }}
    >
      {isFirst && (
        <div
          style={{
            position: "absolute",
            top: 0,
            height: "20px",
            width: "12px",
            background: "#fff",
          }}
        ></div>
      )}
      <div className={styles.TaskComment__pre__iconHolder}>{children}</div>
    </div>
  );
};
