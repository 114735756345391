import React from "react";

const RightFloatBar: React.FC<{
  fitContainer?: boolean;
  card?: boolean;
  customStyle?: string;
}> = ({ children, fitContainer, card, customStyle }) => {
  return (
    <div
      className={`right-floatbar ${fitContainer ? "fitContainer" : ""} ${
        card ? "card" : ""
      } ${customStyle}`}
    >
      {children}
    </div>
  );
};

export default RightFloatBar;
