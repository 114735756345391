import { every, omit } from "lodash";
import moment from "moment";
import { Moment } from "moment";
import { getMonday } from "utilities/dateTime";
import {
  IFilterState,
  IProjectObj,
  RewardFilter,
  WeeklyWidgetContext,
  WorkTypes,
} from "utilities/types";

export const checkAssignee = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.assignees || toFilter?.assignees.length === 0) return true;
  return Boolean(
    (workItem.assigneeId &&
      toFilter?.assignees.includes(workItem.assigneeId)) ||
      (toFilter?.assignees.includes("*") && workItem.assigneeId)
  );
};

export const checkReviewer = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.reviewers || toFilter?.reviewers.length === 0) return true;
  return Boolean(
    (workItem.reviewerId &&
      toFilter?.reviewers.includes(workItem.reviewerId)) ||
      (toFilter?.reviewers.includes("*") && workItem.reviewerId)
  );
};

export const checkExcludedAssignee = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.excludedAssignees || toFilter?.excludedAssignees.length === 0)
    return true;
  return (
    (workItem.assigneeId &&
      !toFilter?.excludedAssignees?.includes(workItem.assigneeId) &&
      !toFilter?.excludedAssignees.includes("*")) ||
    (!workItem.assigneeId && toFilter?.excludedAssignees.includes("*"))
  );
};

export const checkLabels = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.labels || toFilter?.labels.length === 0) return true;
  return Boolean(
    workItem.tags &&
      workItem.tags.filter((tag) => {
        if (!tag) return false;
        const complexString = tag.containerType + tag.containerId;
        return toFilter?.labels.includes(complexString);
      }).length === toFilter?.labels?.length
  );
};

export const checkExcludedLabels = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.excludedLabels || toFilter.excludedLabels.length === 0)
    return true;
  return Boolean(
    workItem.tags &&
      every(workItem.tags, (tag) => {
        const complexString = tag.containerType + tag.containerId;
        return !toFilter?.excludedLabels?.includes(complexString);
      })
  );
};

export const checkParents = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.parents || toFilter?.parents.length === 0) return true;
  return Boolean(
    workItem.parentId && toFilter?.parents.includes(workItem.parentId)
  );
};

export const checkExcludedParents = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.excludedParents || toFilter?.excludedParents?.length === 0)
    return true;
  return (
    !workItem.parentId || !toFilter?.excludedParents.includes(workItem.parentId)
  );
};

export const checkStatuses = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.statuses || toFilter?.statuses.length === 0) return true;
  return Boolean(
    workItem.statusId && toFilter?.statuses.includes(workItem.statusId)
  );
};

export const checkPriority = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.priorities || toFilter?.priorities.length === 0) return true;
  return Boolean(
    workItem.priority && toFilter?.priorities.includes(workItem.priority)
  );
};

export const checkExcludedPriority = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (
    !toFilter?.excludedPriorities ||
    toFilter?.excludedPriorities?.length === 0
  )
    return true;
  return Boolean(
    workItem.priority &&
      !toFilter?.excludedPriorities.includes(workItem.priority)
  );
};

export const checkCycle = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.cycles || toFilter?.cycles?.length === 0) return true;
  return Boolean(
    workItem.workSectionId && toFilter.cycles.includes(workItem.workSectionId)
  );
};

export const checkExcludedCycle = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.excludedCycles || toFilter?.excludedCycles?.length === 0)
    return true;
  return Boolean(
    !workItem.workSectionId ||
      (workItem.workSectionId &&
        !toFilter.excludedCycles.includes(workItem.workSectionId))
  );
};

export const checkGroup = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.groups || toFilter?.groups?.length === 0) return true;
  if (workItem.workType === WorkTypes.TASK) {
    return Boolean(
      workItem.groupId && toFilter.groups.includes(workItem.groupId)
    );
  }

  let atLeastOneProjectGroup = false;
  if (workItem.groupIds) {
    for (const groupId of toFilter.groups) {
      if (workItem.groupIds && workItem.groupIds.includes(groupId)) {
        atLeastOneProjectGroup = true;
        break;
      }
    }
  }

  return Boolean(atLeastOneProjectGroup);
};

export const checkSponsorGroup = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (workItem.workType === WorkTypes.TASK)
    return Boolean(workItem.groupId === toFilter.sponsorGroup);

  if (!toFilter?.sponsorGroup) return true;
  return Boolean(
    workItem.reward?.sponsorGroupId &&
      toFilter.sponsorGroup === workItem.reward?.sponsorGroupId
  );
};

export const checkExcludedGroup = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.excludedGroups || toFilter?.excludedGroups?.length === 0)
    return true;

  if (workItem.workType === WorkTypes.TASK) {
    return Boolean(
      !workItem.groupId ||
        (workItem.groupId &&
          !toFilter.excludedGroups.includes(workItem.groupId))
    );
  }
  let atLeastOneProjectGroup = false;
  if (workItem.groupIds) {
    for (const groupId of toFilter.groups) {
      if (workItem.groupIds && workItem.groupIds.includes(groupId)) {
        atLeastOneProjectGroup = true;
        break;
      }
    }
  }

  return Boolean(
    !workItem.groupId || (workItem.groupId && Boolean(!atLeastOneProjectGroup))
  );
};

export const checkExcludedStatuses = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.excludedStatuses || toFilter?.excludedStatuses?.length === 0)
    return true;
  return Boolean(
    workItem.statusId && !toFilter?.excludedStatuses.includes(workItem.statusId)
  );
};

const rewardCheckerDict = {
  [RewardFilter.HAS_REWARD]: (workItem: IProjectObj) => workItem.reward,
  [RewardFilter.HAS_NOT_REWARD]: (workItem: IProjectObj) => !workItem.reward,
  [RewardFilter.IS_APPROVED]: (workItem: IProjectObj) =>
    workItem.reward &&
    workItem.reward.isAllocationConfirmed &&
    !workItem.reward.isPaid,
  [RewardFilter.IS_NOT_APPROVED]: (workItem: IProjectObj) =>
    workItem.reward &&
    !workItem.reward.isAllocationConfirmed &&
    !workItem.reward.isPaid,
  [RewardFilter.IS_PAID]: (workItem: IProjectObj) =>
    workItem.reward && workItem.reward.isPaid,
  [RewardFilter.IS_NOT_PAID]: (workItem: IProjectObj) =>
    workItem.reward && !workItem.reward.isPaid,
};

export const checkReward = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (toFilter?.hasReward === undefined) return true;
  if (
    rewardCheckerDict[toFilter.hasReward] &&
    rewardCheckerDict[toFilter.hasReward](workItem)
  )
    return true;
  return false;
};

export const checkIsDone = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (toFilter?.isDone === undefined || toFilter?.isDone === null) return true;
  return workItem.isDone === toFilter.isDone;
};

export const checkIsClosed = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (toFilter?.isClosed === undefined || toFilter?.isClosed === null)
    return true;
  return workItem.isClosed === toFilter.isClosed;
};

export const checkDate = ({
  date,
  before,
  after,
  exactly,
  not,
}: {
  date: Moment;
  before?: string | null;
  after?: string | null;
  exactly?: string | null;
  not?: string | null;
}) => {
  return (
    (!after || moment(after).isSameOrBefore(date)) &&
    (!before || moment(before).isSameOrAfter(date)) &&
    (!exactly || moment(exactly).isSame(date, "day")) &&
    (!not || !moment(not).isSame(date, "day"))
  );
};

export const checkDueDate = ({
  toFilter,
  workItem,
  weeklyContext,
  showContext = true,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
  weeklyContext?: WeeklyWidgetContext | null;
  showContext: boolean;
}) => {
  if (
    !toFilter?.dueDate ||
    !(
      toFilter.dueDate.after ||
      toFilter.dueDate.before ||
      toFilter.dueDate.exactly ||
      toFilter.dueDate.isOverdue ||
      toFilter.dueDate.isWeekView ||
      toFilter.dueDate.not
    )
  )
    return true;
  const isWeekly = toFilter?.dueDate.isWeekView;
  const isOverdue = toFilter?.dueDate.isOverdue;
  const filterAfter = toFilter?.dueDate.after;
  let filterBefore = toFilter?.dueDate.before;
  let filterExactly = toFilter?.dueDate.exactly;
  let filterNot = toFilter?.dueDate.not;

  let passesWeeklyCheck = false;
  let passesOverdueCheck = false;

  if (isWeekly) {
    const monday =
      weeklyContext?.mondayDate && showContext
        ? new Date(weeklyContext.mondayDate)
        : getMonday(new Date());
    monday.setHours(0, 0, 0, 0);
    const after = null;
    // const after = monday.toUTCString();
    const newDate = new Date(monday);
    const lastday = new Date(
      newDate.setDate(newDate.getDate() - newDate.getDay() + 8)
    );
    lastday.setHours(0, 0, 0, 0);
    const before = lastday.toUTCString();
    passesWeeklyCheck = checkDate({
      date: moment(workItem.dueDate),
      before,
      after,
      exactly: null,
      not: null,
    });
  }

  if (isOverdue) {
    const monday =
      weeklyContext?.mondayDate && showContext
        ? new Date(weeklyContext.mondayDate)
        : getMonday(new Date());
    monday.setHours(0, 0, 0, 0);
    const after = null;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    passesOverdueCheck = checkDate({
      date: moment(workItem.dueDate),
      before: today.toUTCString(),
      after,
      exactly: null,
      not: null,
    });
  }

  return (
    (!isWeekly || passesWeeklyCheck) &&
    (!isOverdue || passesOverdueCheck) &&
    checkDate({
      date: moment(workItem.dueDate),
      before: filterBefore,
      after: filterAfter,
      exactly: filterExactly,
      not: filterNot,
    })
  );
};

export const checkClosedDate = ({
  toFilter,
  workItem,
  weeklyContext,
  showContext = true,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
  weeklyContext?: WeeklyWidgetContext | null;
  showContext: boolean;
}) => {
  if (
    !toFilter?.closedDate ||
    !(
      toFilter.closedDate.after ||
      toFilter.closedDate.before ||
      toFilter.closedDate.exactly ||
      toFilter.closedDate.isWeekView ||
      toFilter.closedDate.not
    )
  )
    return true;
  const isWeekly = toFilter?.closedDate.isWeekView;
  const filterAfter = toFilter?.closedDate.after;
  let filterBefore = toFilter?.closedDate.before;
  let filterExactly = toFilter?.closedDate.exactly;
  let filterNot = toFilter?.closedDate.not;

  if (isWeekly) {
    const monday =
      weeklyContext?.mondayDate && showContext
        ? new Date(weeklyContext.mondayDate)
        : getMonday(new Date());
    monday.setHours(0, 0, 0, 0);
    const after = monday.toUTCString();
    const newDate = new Date(monday);
    const lastday = new Date(
      newDate.setDate(newDate.getDate() - newDate.getDay() + 8)
    );
    lastday.setHours(0, 0, 0, 0);
    const before = lastday.toUTCString();
    return (
      checkDate({
        date: moment(workItem.dateClosed),
        before,
        after,
        exactly: null,
        not: null,
      }) &&
      checkDate({
        date: moment(workItem.dateClosed),
        before: filterBefore,
        after: filterAfter,
        exactly: filterExactly,
        not: filterNot,
      })
    );
  }

  return checkDate({
    date: moment(workItem.dateClosed),
    before: filterBefore,
    after: filterAfter,
    exactly: filterExactly,
    not: filterNot,
  });
};

export const checkCreatedDate = ({
  toFilter,
  workItem,
  weeklyContext,
  showContext = true,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
  weeklyContext?: WeeklyWidgetContext | null;
  showContext: boolean;
}) => {
  if (
    !toFilter?.createdDate ||
    !(
      toFilter.createdDate.after ||
      toFilter.createdDate.before ||
      toFilter.createdDate.exactly ||
      toFilter.createdDate.isWeekView ||
      toFilter.createdDate.not
    )
  )
    return true;
  const isWeekly = toFilter?.createdDate.isWeekView;
  const filterAfter = toFilter?.createdDate.after;
  let filterBefore = toFilter?.createdDate.before;
  let filterExactly = toFilter?.createdDate.exactly;
  let filterNot = toFilter?.createdDate.not;

  if (isWeekly) {
    const monday =
      weeklyContext?.mondayDate && showContext
        ? new Date(weeklyContext.mondayDate)
        : getMonday(new Date());
    monday.setHours(0, 0, 0, 0);
    const after = monday.toUTCString();
    const newDate = new Date(monday);
    const lastday = new Date(
      newDate.setDate(newDate.getDate() - newDate.getDay() + 8)
    );
    lastday.setHours(0, 0, 0, 0);
    const before = lastday.toUTCString();
    const c1 = checkDate({
      date: moment(workItem.dateCreated),
      before,
      after,
      exactly: null,
      not: null,
    });
    const c2 = checkDate({
      date: moment(workItem.dateCreated),
      before: filterBefore,
      after: filterAfter,
      exactly: filterExactly,
      not: filterNot,
    });
    return c1 && c2;
  }

  return checkDate({
    date: moment(workItem.dateCreated),
    before: filterBefore,
    after: filterAfter,
    exactly: filterExactly,
    not: filterNot,
  });
};

export const checkWorkType = ({
  toFilter,
  workItem,
}: {
  toFilter: IFilterState;
  workItem: IProjectObj;
}) => {
  if (!toFilter?.type || toFilter.type.length === 0) return true;
  return Boolean(
    !toFilter || !toFilter.type || toFilter?.type?.includes(workItem.workType)
  );
};

enum filterkeys {
  assignees = "assignees",
  excludedAssignees = "excludedAssignees",
  labels = "labels",
  excludedLabels = "excludedLabels",
  dueDate = "dueDate",
  closedDate = "closedDate",
  createdDate = "createdDate",
  statuses = "statuses",
  excludedStatuses = "excludedStatuses",
  priorities = "priorities",
  excludedPriorities = "excludedPriorities",
  cycles = "cycles",
  excludedCycles = "excludedCycles",
  parents = "parents",
  excludedParents = "excludedParents",
  type = "type",
  hasReward = "hasReward",
  groups = "groups",
  excludedGroups = "excludedGroups",
  sponsorGroup = "sponsorGroup",
  isDone = "isDone",
  reviewers = "reviewers",
  isClosed = "isClosed",
}

const filterCheckObj = {
  [filterkeys.assignees]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.reviewers]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.excludedAssignees]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.labels]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.excludedLabels]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.dueDate]: (value: {
    before: null | string;
    after: null | string;
    exactly: null | string;
    not: null | string;
    isWeekView: null | boolean;
    isOverdue: null | boolean;
  }) =>
    !value ||
    !(
      value.after ||
      value.before ||
      value.exactly ||
      value.isOverdue ||
      value.isWeekView ||
      value.not
    ),

  [filterkeys.closedDate]: (value: {
    before: null | string;
    after: null | string;
    exactly: null | string;
    not: null | string;
    isWeekView: null | boolean;
  }) =>
    !value ||
    !(
      value.after ||
      value.before ||
      value.exactly ||
      value.isWeekView ||
      value.not
    ),

  [filterkeys.createdDate]: (value: {
    before: null | string;
    after: null | string;
    exactly: null | string;
    not: null | string;
    isWeekView: null | boolean;
  }) =>
    !value ||
    !(
      value.after ||
      value.before ||
      value.exactly ||
      value.isWeekView ||
      value.not
    ),

  [filterkeys.statuses]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.excludedStatuses]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.priorities]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.excludedPriorities]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.cycles]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.excludedCycles]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.parents]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.excludedParents]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.groups]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.excludedGroups]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.type]: (value: string[] | null | undefined) =>
    !value || value.length === 0,

  [filterkeys.hasReward]: (value: RewardFilter | undefined) => !value,

  [filterkeys.sponsorGroup]: (value: string | null | undefined) => !value,

  [filterkeys.isDone]: (value: any | null | undefined) =>
    value === undefined || value === null,

  [filterkeys.isClosed]: (value: any | null | undefined) =>
    value === undefined || value === null,
};

export const getActiveFilters = ({
  toFilter,
  weeklyContext,
  showContext = true,
}: {
  toFilter: IFilterState;
  weeklyContext?: WeeklyWidgetContext | null;
  showContext: boolean;
}) => {
  const filters = omit(toFilter, "hasActiveFilters");

  const actionGenerators = {
    [filterkeys.assignees]: (workItem: IProjectObj) =>
      checkAssignee({ toFilter, workItem }),

    [filterkeys.excludedAssignees]: (workItem: IProjectObj) =>
      checkExcludedAssignee({ toFilter, workItem }),

    [filterkeys.labels]: (workItem: IProjectObj) =>
      checkLabels({ toFilter, workItem }),

    [filterkeys.excludedLabels]: (workItem: IProjectObj) =>
      checkExcludedLabels({ toFilter, workItem }),

    [filterkeys.dueDate]: (workItem: IProjectObj) =>
      checkDueDate({ toFilter, workItem, showContext, weeklyContext }),

    [filterkeys.closedDate]: (workItem: IProjectObj) =>
      checkClosedDate({ toFilter, workItem, showContext, weeklyContext }),

    [filterkeys.createdDate]: (workItem: IProjectObj) =>
      checkCreatedDate({ toFilter, workItem, showContext, weeklyContext }),

    [filterkeys.statuses]: (workItem: IProjectObj) =>
      checkStatuses({ toFilter, workItem }),

    [filterkeys.excludedStatuses]: (workItem: IProjectObj) =>
      checkExcludedStatuses({ toFilter, workItem }),

    [filterkeys.priorities]: (workItem: IProjectObj) =>
      checkPriority({ toFilter, workItem }),

    [filterkeys.excludedPriorities]: (workItem: IProjectObj) =>
      checkExcludedPriority({ toFilter, workItem }),

    [filterkeys.cycles]: (workItem: IProjectObj) =>
      checkCycle({ toFilter, workItem }),

    [filterkeys.excludedCycles]: (workItem: IProjectObj) =>
      checkExcludedCycle({ toFilter, workItem }),

    [filterkeys.groups]: (workItem: IProjectObj) =>
      checkGroup({ toFilter, workItem }),

    [filterkeys.excludedGroups]: (workItem: IProjectObj) =>
      checkExcludedGroup({ toFilter, workItem }),

    [filterkeys.parents]: (workItem: IProjectObj) =>
      checkParents({ toFilter, workItem }),

    [filterkeys.excludedParents]: (workItem: IProjectObj) =>
      checkExcludedParents({ toFilter, workItem }),

    [filterkeys.type]: (workItem: IProjectObj) =>
      checkWorkType({ toFilter, workItem }),

    [filterkeys.hasReward]: (workItem: IProjectObj) =>
      checkReward({ toFilter, workItem }),

    [filterkeys.sponsorGroup]: (workItem: IProjectObj) =>
      checkSponsorGroup({ toFilter, workItem }),

    [filterkeys.isDone]: (workItem: IProjectObj) =>
      checkIsDone({ toFilter, workItem }),

    [filterkeys.reviewers]: (workItem: IProjectObj) =>
      checkReviewer({ toFilter, workItem }),

    [filterkeys.isClosed]: (workItem: IProjectObj) =>
      checkIsClosed({ toFilter, workItem }),
  };

  const filtersRow: ((workItem: IProjectObj) => boolean)[] = [];

  Object.entries(filters).forEach(([key, value]) => {
    if (filterCheckObj[key as filterkeys]) {
      const data = filterCheckObj[key as filterkeys](value as any);
      if (!data) filtersRow.push(actionGenerators[key as filterkeys]);
    }
  });

  return filtersRow;
};
