import { socket } from "App";
import { batch } from "react-redux";
import { TRIGGER_BLOCK_UPDATE } from "./actions";
import store from "./storeExporter";

interface IBlockPresence {
  [blockId: string]: IUserInfo[];
}

export interface IUserInfo {
  id: string;
  name: string;
  avatar: string;
  email: string;
  clientId: string;
}

enum DeltaType {
  add = "add",
  remove = "remove",
}

export interface PresenceDelta {
  type: DeltaType;
  blockId: string;
  user: IUserInfo;
}

export let BlockPresence: IBlockPresence = {};

export const deltaUpdateBlockPresence = (deltaArray: PresenceDelta[]) => {
  batch(() => {
    deltaArray.forEach((delta) => {
      if (!delta) return;
      if (delta.type === DeltaType.add && delta.user.clientId !== socket.id) {
        if (!BlockPresence[delta.blockId]) BlockPresence[delta.blockId] = [];
        let index = -1;
        let iteration = 0;
        for (const presence of BlockPresence[delta.blockId]) {
          if (presence.clientId === delta.user.clientId) {
            index = iteration;
            break;
          }
          iteration++;
        }
        if (index === -1) BlockPresence[delta.blockId].push(delta.user);
      }
      if (delta.type === DeltaType.remove) {
        if (BlockPresence[delta.blockId]) {
          let index = -1;
          let iteration = 0;
          for (const presence of BlockPresence[delta.blockId]) {
            if (presence.clientId === delta.user.clientId) {
              index = iteration;
              break;
            }
            iteration++;
          }
          if (index > -1) {
            BlockPresence[delta.blockId].splice(index, 1);
          }
        }
      }
      store.dispatch({
        type: TRIGGER_BLOCK_UPDATE,
        param: {
          id: delta.blockId,
        },
      });
    });
  });
};

export const resetLocalPresence = () => {
  BlockPresence = {};
};
