import ItemDescription from "clarity-ui/ItemDescription";
import Button, { ButtonTypes } from "components/Button";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import styles from "../roles/roles.module.scss";
import { EditOutlined, IdcardOutlined } from "@ant-design/icons";
import AddOrEditRole from "./AddOrEditRole";

const RoleRow: React.FC<{
  id: string;
  canEdit?: boolean;
  canViewDetails?: boolean;
}> = ({ id, canEdit, canViewDetails }) => {
  const role = useSelector(
    (clarity: ClarityStore) => clarity.roles.dict[id],
    shallowEqual
  );

  const [isHovered, setisHovered] = useState(false);
  const [isEditing, setisEditing] = useState(false);

  useEffect(() => {
    if (!isEditing) setisHovered(false);
  }, [isEditing]);
  if (!role) {
    return <></>;
  }
  return (
    <div
      onMouseEnter={() => setisHovered(true)}
      onMouseLeave={() => setisHovered(false)}
      className={styles.roleRow}
    >
      <ItemDescription
        noPadding={true}
        title={role.roleName}
        description={role.description}
        icon={<IdcardOutlined />}
      />
      {isHovered && canViewDetails && (
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Button
            className={styles.tokenGateRowEditBtn}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              setisEditing(true);
            }}
            buttonType={ButtonTypes.LINK}
            icon={<EditOutlined />}
          />
        </div>
      )}
      {isEditing && (
        <AddOrEditRole
          id={role.id}
          disabled={!canEdit}
          setdetailsOpen={setisEditing}
        />
      )}
    </div>
  );
};

export default RoleRow;
