import React, { useCallback, useRef } from "react";
import styles from "./splitViewTopNavBar/splitViewTopNavBar.module.scss";
import {
  ShareBtn,
  ExternalBtns,
  getMenuItem,
  useTotalActionBtns,
  IsWorkViewListener,
} from "components/TopNavBar";
import { SwapOutlined, CloseOutlined } from "@ant-design/icons";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
import { Dropdown, Menu } from "antd";
import Button, { ButtonTypes } from "components/Button";
import ActiveUsersListener from "screens/base/main/detailView/documentContainer/document/ActiveUsersListener";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionTypes from "store/actions";
import {
  useBtnComponents,
  isActionMenu,
  isMenuItem,
  isMenuSubMenu,
  isActionTrigger,
  ActionBtn,
} from "store/reducers/topNavReducer";
import { useWidthStyles, useOptionalClassName } from "utilities/hooks";
import { some } from "lodash";
import store, { ClarityStore } from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import { PageTitle } from "./topNavBar/PageTitle";
import navigationApi from "clientApi/navigationApi";

export default function SplitViewTopNavBar() {
  const actionsRef = useRef();
  const totalActionBtns = useTotalActionBtns({
    paneId: ChunkDestination.secondary,
  });
  const actionsClassName = useWidthContainer({
    containerRef: actionsRef,
    baseStyle: styles.actions,
    groups: [[620, [styles.sixTwentyOrLess]]],
    exceptions: [totalActionBtns < 2],
  });

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <PageTitle paneId={ChunkDestination.secondary} />
      </div>
      <div className={actionsClassName} ref={actionsRef as any}>
        <ActiveUsersListener
          containerClass={styles.actionsUsersPresence}
          paneId={ChunkDestination.secondary}
        />
        <ExternalBtns
          paneId={ChunkDestination.secondary}
          className={styles.actionsExternalBtns}
        />
        <IsWorkViewListener paneId={ChunkDestination.secondary} />
        <ViewSwapBtn />
        <SplitViewCloseBtn />
      </div>
    </div>
  );
}

function ViewSwapBtn() {
  const secondaryView = useSelector(
    (store: ClarityStore) => store.navigation.splitView.displaySecondaryView,
    shallowEqual
  );
  const secondaryChunk = useSelector(
    (store: ClarityStore) =>
      store.navigation.navigation[ChunkDestination.secondary],
    shallowEqual
  );
  const handleViewsSwap = useCallback(() => {
    store.dispatch({ type: actionTypes.SWAP_PANES });
    const url = navigationApi.getLinkFromNavigationChunk(secondaryChunk);
    window.history.replaceState(null, "", url);
  }, [secondaryView, secondaryChunk]);
  if (!secondaryView) return <></>;
  return (
    <Button
      buttonType={ButtonTypes.LINK}
      onClick={handleViewsSwap}
      icon={<SwapOutlined />}
    />
  );
}

function SplitViewCloseBtn() {
  const { hideSplitView } = useSplitViewCloseBtnState();

  return (
    <Button
      className={styles.actionsCloseBtn}
      onClick={hideSplitView}
      buttonType={ButtonTypes.LINK}
      icon={<CloseOutlined />}
    />
  );
}

function useSplitViewCloseBtnState() {
  const dispatch = useDispatch();
  const hideSplitView = () =>
    dispatch({ type: actionTypes.HIDE_SECONDARY_VIEW, removeContainer: true });

  return {
    hideSplitView,
  };
}

function getMenuActionButton(btn: ActionBtn) {
  if (isActionMenu(btn) && (!btn.condition || btn.condition())) {
    return (
      <Menu.SubMenu title={<btn.title />} key={btn.key}>
        {btn.menuItems.map((menuItem, index) => {
          if (isMenuItem(menuItem)) {
            return getMenuItem(menuItem);
          } else if (isMenuSubMenu(menuItem)) {
            return (
              <Menu.SubMenu title={menuItem.title} key={menuItem.key}>
                {menuItem.children.map((menuItem) => getMenuItem(menuItem))}
              </Menu.SubMenu>
            );
          } else return <React.Fragment key={index} />;
        })}
      </Menu.SubMenu>
    );
  } else if (isActionTrigger(btn) && (!btn.condition || btn.condition())) {
    return (
      <Menu.Item onClick={btn.onClick} key={btn.key} disabled={btn.disabled}>
        <btn.contents />
      </Menu.Item>
    );
  } else {
    return null;
  }
}

export function useWidthContainer({
  containerRef,
  baseStyle,
  groups,
  exceptions = [],
}: {
  containerRef: React.MutableRefObject<undefined>;
  baseStyle: string;
  groups: Parameters<typeof useWidthStyles>[1];
  exceptions?: boolean[];
}) {
  const widthStyles = useWidthStyles(containerRef, groups);

  const containerClassName = useOptionalClassName({
    baseStyle,
    pairs: [
      {
        extraStyle: widthStyles.join(" "),
        withExtra: Boolean(widthStyles.length) && !some(exceptions),
      },
    ],
  });

  return containerClassName;
}

interface IActionBtnsMenuProps {
  paneId: ChunkDestination;
  triggerClass?: string;
}

export function ActionBtnsMenu({
  paneId,
  triggerClass = "",
}: IActionBtnsMenuProps) {
  const btnComponents = useBtnComponents(paneId);
  const triggerClassName = useOptionalClassName({
    baseStyle: styles.actionsMenuTrigger,
    pairs: [
      {
        extraStyle: triggerClass,
        withExtra: Boolean(triggerClass),
      },
    ],
  });

  const hideTrigger = btnComponents.length < 1;

  return hideTrigger ? (
    <></>
  ) : (
    <Dropdown
      overlay={
        <Menu>
          {btnComponents.map((btn) => getMenuActionButton(btn))}
          <ShareBtn
            paneId={paneId}
            asMenuItems
            btnClass={styles.actionsShareBtn}
          />
        </Menu>
      }
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button
        buttonType={ButtonTypes.LINK}
        icon={<MoreHorizontalDots />}
        className={triggerClassName}
      />
    </Dropdown>
  );
}
