import React from "react";
import styles from "./foldButton/foldButton.module.scss";
import { TriangleIcon } from "screens/base/Main";
import { useOptionalClassName } from "utilities/hooks";

interface IFoldButtonProps {
  onClick: (e?: any) => void;
  isFolded: boolean;
  size?: "medium" | "micro" | null;
  style?: any;
  containerClass?: string;
}

const FoldButton: React.FC<IFoldButtonProps> = (props) => {
  const { containerClassName } = useFoldButtonState(props);

  return (
    <div
      tabIndex={0}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.onClick) props.onClick();
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
      className={containerClassName}
      style={props.style}
    >
      <img
        src={TriangleIcon}
        alt="Fold & unfold button"
        onMouseOver={(e) => e.preventDefault()}
      />
    </div>
  );
};

function useFoldButtonState({
  containerClass = "",
  size,
  isFolded,
}: Pick<IFoldButtonProps, "containerClass" | "size" | "isFolded">) {
  const containerClassName = useOptionalClassName({
    baseStyle: styles.foldButton,
    pairs: [
      {
        extraStyle: containerClass,
        withExtra: Boolean(containerClass),
      },
      {
        extraStyle: styles.unfolded,
        withExtra: !isFolded,
      },
      {
        extraStyle: styles.foldButton__medium,
        withExtra: size === "medium",
      },
      {
        extraStyle: styles.foldButton__micro,
        withExtra: size === "micro",
      },
      {
        extraStyle: styles.foldButton__small,
        withExtra: !size,
      },
    ],
  });

  return {
    containerClassName,
  };
}

export default FoldButton;
