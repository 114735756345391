import { SubroutesListener } from "components/TopNavBar";
import {
  getNameFromContainer,
  useGetContainerFromType,
} from "modules/containerHelpers";
import React, { useMemo } from "react";
import { TopNavbarType, usePageData } from "store/reducers/topNavReducer";
import { SectionWidthModes, useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { ContainerTypes } from "utilities/types";
import BaseTopnavRow from "./typeSwitcher/BaseTopnavRow";

const RowTypeSwitcher: React.FC<{
  paneId: ChunkDestination;
  sectionWidthMode?: SectionWidthModes;
}> = ({ paneId, sectionWidthMode }) => {
  const viewType = useShallowSelector((store) => store.topNav[paneId]?.navType);

  return useMemo(() => {
    switch (viewType) {
      case TopNavbarType.baseOverview:
        return (
          <BaseTopnavRow paneId={paneId} sectionWidthMode={sectionWidthMode} />
        );
      case TopNavbarType.detail:
        return <ContainerName paneId={paneId} />;

      default:
        return <></>;
    }
  }, [viewType, paneId]);
};

const ContainerName: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  const pageData = usePageData(paneId);
  const paneContainer = useShallowSelector(
    (store) => store.navigation.navigation[paneId]
  );

  const container = useGetContainerFromType(
    paneContainer.entity?.containerId,
    paneContainer.entity?.containerType
  );

  const title = paneContainer.entity
    ? getNameFromContainer(
        container,
        paneContainer.entity.containerType as ContainerTypes
      )
    : pageData?.title;

  if (!pageData) return <></>;

  return (
    <div style={{ display: "flex", gap: "14px", alignItems: "center" }}>
      <span className={`body primary exerpt`} style={{ maxWidth: "240px" }}>
        {title}
        {pageData?.subtitle && (
          <span style={{ marginLeft: "4px" }}>{pageData.subtitle}</span>
        )}
      </span>
      <SubroutesListener paneId={paneId} />
    </div>
  );
};

export default RowTypeSwitcher;
