import Icon from "@ant-design/icons";
import React from "react";

export default function PriorityNoneIcon(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 13 8"
      width="13"
      height="13"
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Work-v4"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <g
          id="Desktop-HD-Copy-3"
          transform="translate(-275.000000, -381.000000)"
          fill="currentColor"
        >
          <g id="Group-4" transform="translate(240.000000, 362.000000)">
            <g id="Group-21" transform="translate(35.000000, 19.000000)">
              <rect
                id="Rectangle-Copy-7"
                x="0"
                y="0"
                width="3"
                height="8"
                rx="1.5"
              />
              <rect
                id="Rectangle-Copy-19"
                x="5"
                y="0"
                width="3"
                height="8"
                rx="1.5"
              />
              <rect
                id="Rectangle-Copy-20"
                x="10"
                y="0"
                width="3"
                height="8"
                rx="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
