import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { message } from "antd";

interface Props {
  error?: {
    nr: number;
    message: string;
  };
}

const openNotificationWithIcon = (messageText: string) => {
  message.error(messageText, 3);
};

const ErrorListener: React.FC<Props> = (props) => {
  useEffect(() => {
    if (props.error && props.error.message)
      openNotificationWithIcon(props.error?.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error?.nr]);

  return useMemo(() => {
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error?.nr]);
};

const mapStateToProps = (state: any) => {
  return {
    error: state.network.error,
  };
};

export default connect(mapStateToProps, null)(ErrorListener);
