import {
  changeBlockOrSelectionType,
  changeParentListType,
  changeSingleBlockType,
  checkForPrefix,
  turnIntoTableBlock,
} from "editor/utils/specificActions/blockTypesActions";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Block } from "store/reducers/blockReducer";
import { LineType } from "utilities/lineUtilities";
import {
  BlockMenuTypes,
  ContainerTypes,
  IBlockContext,
  UserRole,
  WorkTypes,
} from "utilities/types";
import styles from "./slashCommandMenu.module.scss";
import { resetBlockMenu } from "editor/utils/blockMenusActions/menuActions";
import { Menu } from "antd";
import { MenuProps } from "../BlockMenu";
import { batch } from "react-redux";
import { removePlaceholderPlaceCaret } from "editor/utils/specificActions/textUpdateUtils";
import { addNewBlockKeepValue } from "editor/utils/specificActions/addBlockActions";
import { useOnClickOutside } from "editor/utils/customHooks";
import store, { $focusOn, prevState } from "store/storeExporter";
import {
  FontSizeOutlined,
  TagOutlined,
  NumberOutlined,
  UserOutlined,
  CheckSquareOutlined,
  CheckCircleOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  DashboardOutlined,
  LineOutlined,
  FileImageOutlined,
  ExpandOutlined,
  CodeOutlined,
  FilePdfOutlined,
  LinkOutlined,
  ScissorOutlined,
} from "@ant-design/icons";
import { blockApi } from "editor/utils/blockActionsApi";
import { useCustomWorkViews } from "store/reducers/customWorkViewsReducer";
import { checkCaretPosition } from "editor/utils/caretUtils";

export const slashMenuOptions: any[] = [
  {
    name: "Basic text",
    caption: "Simple text with formatting",
    value: LineType.text,
    action: (props: MenuProps) =>
      changeSingleBlockType(
        props.blockData.id,
        LineType.text,
        props.context,
        props.blockRef
      ),
    prefix: "",
    aliases: [],
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: <FontSizeOutlined className={styles.svgContainer} />,
  },
  {
    name: "Heading 1",
    caption: "Large section",
    value: LineType.heading1,
    action: (props: MenuProps) =>
      changeSingleBlockType(
        props.blockData.id,
        LineType.heading1,
        props.context,
        props.blockRef
      ),
    prefix: "#",
    aliases: ["h1"],
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: (
      <ManualSvgIcon pathD="M593.72 103.28l0-41.5c0-11.32-8.49-20.75-18.87-20.28l-191.46 0c-10.37 0-19.33 9.43-19.33 20.28l0 41.5c0 11.32 8.49 20.75 19.33 20.75l44.8 0 0 165.05-243.33 0 0-165.05 45.27 0c10.37 0 19.33-9.43 18.86-20.75l0-41.5c0-11.32-8.49-20.75-18.86-20.28l-191.93 0c-10.37 0-19.33 9.43-18.87 20.28l0 41.5c0 11.32 8.49 20.75 18.87 20.75l44.8 0 0 412.16-44.8 0c-10.37 0-19.33 9.43-18.87 20.74l0 41.5c0 11.32 8.49 20.75 18.87 20.28l191.93 0c10.37 0 19.33-9.43 18.86-20.28l0-41.5c0-11.32-8.49-20.75-18.86-20.74l-45.27 0 0-165.06 243.33 0 0 165.06-45.27 0c-10.37 0-19.33 9.43-18.86 20.74l0 41.5c0 11.32 8.49 20.75 18.86 20.28l191.93 0c10.37 0 19.33-9.43 18.87-20.28l0-41.5c0-11.32-8.49-20.75-18.87-20.74l-44.8 0 0-412.64 44.8 0c10.37 0 19.33-9.43 18.87-20.27z M854.97 613.05l0-377.26-74.98 0-93.37 58.95 0 70.73 86.3-53.76 2.36 0 0 301.34z" />
    ),
  },
  {
    name: "Heading 2",
    caption: "Medium section",
    value: LineType.heading2,
    action: (props: MenuProps) =>
      changeSingleBlockType(
        props.blockData.id,
        LineType.heading2,
        props.context,
        props.blockRef
      ),
    prefix: "##",
    aliases: ["h2"],
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: (
      <ManualSvgIcon pathD="M564.03 98.11l0-39.42c0-10.75-8.06-19.71-17.92-19.27l-181.89 0c-9.86 0-18.37 8.96-18.36 19.27l0 39.42c0 10.75 8.06 19.71 18.36 19.71l42.56 0 0 156.8-231.16 0 0-156.8 43 0c9.86 0 18.37-8.96 17.92-19.71l0-39.42c0-10.75-8.06-19.71-17.92-19.27l-182.33 0c-9.86 0-18.37 8.96-17.92 19.27l0 39.42c0 10.75 8.06 19.71 17.92 19.71l42.56 0 0 391.56-42.56 0c-9.86 0-18.37 8.96-17.92 19.71l0 39.42c0 10.75 8.06 19.71 17.92 19.27l182.33 0c9.86 0 18.37-8.96 17.92-19.27l0-39.42c0-10.75-8.06-19.71-17.92-19.71l-43 0 0-156.8 231.16 0 0 156.8-43 0c-9.86 0-18.37 8.96-17.92 19.71l0 39.42c0 10.75 8.06 19.71 17.92 19.27l182.33 0c9.86 0 18.37-8.96 17.92-19.27l0-39.42c0-10.75-8.06-19.71-17.92-19.71l-42.56 0 0-392 42.56 0c9.86 0 18.37-8.96 17.92-19.27z M866.88 582.4l0-61.82-150.53 0 0-2.69 52.42-51.07c24.64-22.4 43.9-41.66 57.34-57.8 13.44-16.13 22.85-30.46 28.23-43.9 5.38-12.99 8.06-26.43 8.06-39.87 0-20.61-5.38-38.98-16.13-55.11-10.75-16.13-25.54-28.67-44.35-37.63-19.26-8.96-41.66-13.44-67.2-13.44-25.09 0-47.04 4.93-66.3 14.34-19.26 9.41-34.05 22.85-44.36 39.87-10.75 17.47-16.13 37.63-16.12 60.93l0 0 72.12 0c0-11.65 2.24-21.5 6.28-29.57 4.48-8.06 10.75-14.78 19.26-18.82 8.06-4.48 17.92-6.72 28.67-6.72 10.3 0 19.71 2.24 28.23 6.28 8.06 4.03 14.78 9.86 19.26 17.47 4.93 7.62 7.17 16.58 7.17 27.33 0 9.41-1.79 18.37-5.83 26.43-4.03 8.06-9.41 16.58-16.57 24.64-7.62 8.51-16.58 17.92-27.33 28.22l0 0-127.68 118.27 0 54.66 255.36 0z" />
    ),
  },
  {
    name: "Heading 3",
    caption: "Small section",
    value: LineType.heading3,
    prefix: "###",
    aliases: ["h3"],
    action: (props: MenuProps) =>
      changeSingleBlockType(
        props.blockData.id,
        LineType.heading3,
        props.context,
        props.blockRef
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: (
      <ManualSvgIcon pathD="M564.03 98.11l0-39.42c0-10.75-8.06-19.71-17.92-19.27l-181.89 0c-9.86 0-18.37 8.96-18.36 19.27l0 39.42c0 10.75 8.06 19.71 18.36 19.71l42.56 0 0 156.8-231.16 0 0-156.8 43 0c9.86 0 18.37-8.96 17.92-19.71l0-39.42c0-10.75-8.06-19.71-17.92-19.27l-182.33 0c-9.86 0-18.37 8.96-17.92 19.27l0 39.42c0 10.75 8.06 19.71 17.92 19.71l42.56 0 0 391.56-42.56 0c-9.86 0-18.37 8.96-17.92 19.71l0 39.42c0 10.75 8.06 19.71 17.92 19.27l182.33 0c9.86 0 18.37-8.96 17.92-19.27l0-39.42c0-10.75-8.06-19.71-17.92-19.71l-43 0 0-156.8 231.16 0 0 156.8-43 0c-9.86 0-18.37 8.96-17.92 19.71l0 39.42c0 10.75 8.06 19.71 17.92 19.27l182.33 0c9.86 0 18.37-8.96 17.92-19.27l0-39.42c0-10.75-8.06-19.71-17.92-19.71l-42.56 0 0-392 42.56 0c9.86 0 18.37-8.96 17.92-19.27z M744.13 587.33c26.88 0 50.62-4.48 71.23-13.89 20.61-8.96 37.18-21.5 48.83-37.18 11.65-15.68 17.92-34.05 17.47-54.66 0-22.4-6.72-40.77-20.6-55.55-13.89-14.78-34.05-24.19-60.04-27.33l0 0 0-2.69c20.16-3.58 36.29-12.1 48.84-25.53 12.54-13.44 18.82-30.02 18.81-50.63 0-18.82-4.93-35.84-15.68-51.07-10.3-15.23-24.64-27.33-43.01-36.29-18.37-8.96-40.32-13.44-64.96-13.44-24.64 0-46.59 4.48-65.85 13.44-19.26 8.96-34.94 21.06-46.15 37.19-11.65 15.68-17.47 34.05-17.92 54.2l0 0 72.58 0c0.45-8.96 3.14-17.02 8.51-23.74 4.93-6.72 12.1-12.1 20.16-15.68 8.51-3.58 17.92-5.38 28.23-5.38 10.3 0 19.26 1.79 26.88 5.83 7.62 4.03 13.44 9.41 17.92 16.13 4.48 6.72 6.27 14.78 6.27 23.74 0 9.41-2.24 17.92-7.62 24.64-4.93 7.17-11.65 12.54-20.61 16.58-8.96 4.03-18.82 5.82-30.01 5.82l0 0-33.6 0 0 55.55 33.6 0c13.44 0 25.09 2.24 34.94 6.27 9.86 4.03 17.47 9.86 22.85 17.48 5.38 7.62 8.06 15.68 8.06 25.53 0 9.41-2.69 17.92-7.61 25.09-4.93 7.17-12.1 12.99-21.06 17.02-8.96 4.03-19.26 6.27-30.46 5.83-11.2 0-21.06-1.79-30.02-5.38-8.96-3.58-16.13-8.51-21.05-15.23-5.38-6.72-8.06-14.34-8.96-22.85l0 0-76.16 0c0.45 21.06 6.27 39.42 17.92 55.11 11.65 16.13 27.78 28.22 48.38 37.63 20.16 8.96 43.46 13.44 69.89 13.44z" />
    ),
  },
  {
    name: "Tag",
    caption: "Link to a page in your base",
    value: "keywordTag",
    prefix: "",
    aliases: ["kt"],
    action: (props: MenuProps) => {
      setTimeout(() => {
        const newMenu = { ...props.menuStateValue.current.value };
        newMenu.type = BlockMenuTypes.tagsMenu;
        newMenu.executeSelection = false;
        newMenu.isOpened = true;
        newMenu.hoveredItem = 0;
        newMenu.isAtBegining = true;
        props.menuStateValue.current.next(newMenu);
      }, 1);
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      const userRole = store.getState().client.roleType;
      return userRole !== UserRole.GUEST;
    },
    icon: <TagOutlined className={styles.svgContainer} />,
  },
  {
    name: "Hashtag",
    caption: "Link to page, task, or project",
    value: "hashTag",
    prefix: "",
    aliases: ["ht"],
    action: (props: MenuProps) => {
      setTimeout(() => {
        const newMenu = { ...props.menuStateValue.current.value };
        newMenu.type = BlockMenuTypes.tagsMenu;
        newMenu.executeSelection = false;
        newMenu.isOpened = true;
        newMenu.hoveredItem = 0;
        newMenu.isAtBegining = true;
        newMenu.options = {
          hashtag: true,
        };
        props.menuStateValue.current.next(newMenu);
      }, 1);
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      const userRole = store.getState().client.roleType;
      return userRole !== UserRole.GUEST;
    },
    icon: <NumberOutlined className={styles.svgContainer} />,
  },
  {
    name: "Mention a person",
    caption: "Bring this to a teammate's attention",
    value: "userMention",
    prefix: "",
    aliases: ["@"],
    action: (props: MenuProps) => {
      setTimeout(() => {
        const newMenu = { ...props.menuStateValue.current.value };
        newMenu.type = BlockMenuTypes.userMentions;
        newMenu.executeSelection = false;
        newMenu.isOpened = true;
        newMenu.hoveredItem = 0;
        newMenu.isAtBegining = true;
        newMenu.options = {
          hashtag: true,
        };
        props.menuStateValue.current.next(newMenu);
      }, 1);
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      const userRole = store.getState().client.roleType;
      return userRole !== UserRole.GUEST;
    },
    icon: <UserOutlined className={styles.svgContainer} />,
  },
  {
    name: "Checklist",
    caption: "Simple checkbox",
    value: LineType.checkbox,
    prefix: "+",
    aliases: ["[]"],
    action: (props: MenuProps) =>
      changeSingleBlockType(
        props.blockData.id,
        LineType.checkbox,
        props.context,
        props.blockRef
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: <CheckSquareOutlined className={styles.svgContainer} />,
  },
  {
    name: "New task",
    caption: "Create a new task and insert it here",
    value: WorkTypes.PROJECT,
    prefix: "++",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.work,
        props.context,
        { workType: WorkTypes.TASK },
        true
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      const userRole = store.getState().client.roleType;
      if (!context.autosave) {
        return false;
      }
      return userRole !== UserRole.GUEST;
    },
    icon: <CheckCircleOutlined className={styles.svgContainer} />,
  },
  {
    name: "New project",
    caption: "Create and open a new project",
    value: WorkTypes.PROJECT,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.work,
        props.context,
        { workType: WorkTypes.PROJECT }
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      if (context.container.type === ContainerTypes.PROJECT) {
        const workItem = prevState.value.work.dict[context.container.id];
        if (workItem.workType !== WorkTypes.INITIATIVE) return false;
      }

      const userRole = store.getState().client.roleType;
      if (!context.autosave) {
        return false;
      }
      return userRole !== UserRole.GUEST;
    },
    icon: <CheckCircleOutlined className={styles.svgContainer} />,
  },
  {
    name: "New goal",
    caption: "Create and open a new goal",
    value: WorkTypes.PROJECT,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.work,
        props.context,
        { workType: WorkTypes.INITIATIVE }
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      if (context.container.type === ContainerTypes.PROJECT) return false;
      const userRole = store.getState().client.roleType;
      if (!context.autosave) {
        return false;
      }
      return userRole !== UserRole.GUEST;
    },
    icon: <CheckCircleOutlined className={styles.svgContainer} />,
  },
  {
    name: "Link existing task, project, or goal",
    caption: "Insert a reference to an existing work item",
    value: "Link to task",
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) => {
      const newMenu = { ...props.menuStateValue.current.value };
      newMenu.type = BlockMenuTypes.linkEntityMenu;
      newMenu.isOpened = true;
      newMenu.hoveredItem = 0;
      newMenu.isAtBegining = true;
      newMenu.filterMenuBy = "";
      newMenu.options = {
        entityType: "PROJECT",
      };
      if (props.blockRef.current.innerText.length === 0) {
        setTimeout(() => {
          props.menuStateValue.current.next(newMenu);
        }, 1);
      } else {
        setTimeout(() => {
          addNewBlockKeepValue(props.blockData, props.context, {
            options: { presetMenu: newMenu },
          });
        }, 1);
      }
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      const fullBlock = { ...block, ...block.blockSubject.value };
      const userRole = store.getState().client.roleType;
      if (fullBlock.children.length > 0 || userRole === UserRole.GUEST)
        return false;
      return true;
    },
    icon: <LinkIcon />,
  },
  {
    name: "Widget",
    caption: "Add a widget",
    icon: <DashboardOutlined className={styles.svgContainer} />,
    value: LineType.widget,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) => {
      const newMenu = { ...props.menuStateValue.current.value };
      newMenu.type = BlockMenuTypes.widget;
      newMenu.isOpened = true;
      newMenu.hoveredItem = 0;
      newMenu.isAtBegining = true;
      newMenu.executeSelection = false;
      newMenu.filterMenuBy = "";

      if (props.blockRef.current.innerText.length === 0) {
        setTimeout(() => {
          props.menuStateValue.current.next(newMenu);
        }, 1);
      } else {
        setTimeout(() => {
          addNewBlockKeepValue(props.blockData, props.context, {
            options: { presetMenu: newMenu },
          });
        }, 1);
      }
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return false;
      }
      const fullBlock = { ...block, ...block.blockSubject.value };
      if (fullBlock.children.length > 0) return false;
      return true;
    },
  },
  {
    name: "Snippet",
    caption: "Insert a snippet",
    icon: <ScissorOutlined className={styles.svgContainer} />,
    value: "insertSnippet",
    prefix: "",
    aliases: [";"],
    notRootline: true,
    action: (props: MenuProps) => {
      const newMenu = { ...props.menuStateValue.current.value };
      newMenu.type = BlockMenuTypes.snippetMenu;
      newMenu.isOpened = true;
      newMenu.hoveredItem = 0;
      newMenu.isAtBegining = true;
      newMenu.executeSelection = false;
      newMenu.filterMenuBy = "";

      if (props.blockRef.current.innerText.length === 0) {
        setTimeout(() => {
          props.menuStateValue.current.next(newMenu);
        }, 1);
      } else {
        setTimeout(() => {
          addNewBlockKeepValue(props.blockData, props.context, {
            options: { presetMenu: newMenu },
          });
        }, 1);
      }
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return false;
      }
      const fullBlock = { ...block, ...block.blockSubject.value };
      if (fullBlock.children.length > 0) return false;
      return true;
    },
  },
  {
    name: "Image",
    caption: "Upload your own or embed from a URL",
    value: LineType.image,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.image,
        props.context
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: <PictureOutlined className={styles.svgContainer} />,
  },
  {
    name: "Video",
    caption: "Insert video from url",
    icon: <VideoCameraOutlined className={styles.svgContainer} />,
    value: LineType.video,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.video,
        props.context
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
  },
  {
    name: "Divider",
    caption: "Insert divider",
    icon: <LineOutlined className={styles.svgContainer} />,
    value: LineType.divider,
    prefix: "---",
    notRootline: true,
    action: (props: MenuProps) => {
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.divider,
        props.context
      );
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
  },
  {
    name: "Loom",
    caption: "Insert a loom",
    icon: <ExpandOutlined className={styles.svgContainer} />,
    value: LineType.loom,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) => {
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.loom,
        props.context
      );
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
  },
  {
    name: "Figma",
    caption: "Insert a live public Figma file",
    icon: <FileImageOutlined className={styles.svgContainer} />,
    value: LineType.figma,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) => {
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.figma,
        props.context
      );
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
  },
  {
    name: "Code",
    caption: "Insert a code block",
    icon: <CodeOutlined className={styles.svgContainer} />,
    value: LineType.code,
    prefix: "```",
    notRootline: true,
    action: (props: MenuProps) => {
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.code,
        props.context
      );
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
  },
  {
    name: "PDF",
    caption: "Upload your own or embed from a URL",
    value: LineType.pdf,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.pdf,
        props.context
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      if (!context.autosave) {
        return false;
      }
      return true;
    },
    icon: <FilePdfOutlined className={styles.svgContainer} />,
  },
  {
    name: "Web Link",
    caption: "Web link",
    value: LineType.webLink,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.webLink,
        props.context
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: <LinkOutlined className={styles.svgContainer} />,
  },
  {
    name: "Quote",
    caption: "Quote",
    value: LineType.quote,
    prefix: ">",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.quote,
        props.context
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: <PictureOutlined className={styles.svgContainer} />,
  },
  {
    name: "Callout",
    caption: "Callout",
    value: LineType.callout,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) =>
      changeBlockOrSelectionType(
        props.blockData.id,
        props.blockRef,
        LineType.callout,
        props.context
      ),
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: <PictureOutlined className={styles.svgContainer} />,
  },
  {
    name: "Table",
    caption: "A simple table",
    value: LineType.table,
    prefix: "",
    notRootline: true,
    action: (props: MenuProps) =>
      turnIntoTableBlock({
        blockId: props.blockData.id,
        context: props.context,
      }),
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
    icon: <PictureOutlined className={styles.svgContainer} />,
  },
];

function useWidgetMenuOptions({
  blockContext,
}: {
  blockContext: IBlockContext;
}) {
  const customWork = useCustomWorkViews();
  const [widgetMenuOptions, setWidgetMenuOptions] = useState<any[]>([]);

  const updateWidgetMenuOptions = () => {
    setWidgetMenuOptions(
      Object.values(customWork).map((customView) => ({
        name: customView.name,
        caption: customView.description,
        action: (props: MenuProps) =>
          changeBlockOrSelectionType(
            props.blockData.id,
            props.blockRef,
            LineType.widget,
            props.context,
            {
              referencingContainerId: customView.id,
            }
          ),
        filterTrigger: (block: Block, context: IBlockContext) => true,
      }))
    );
  };

  useEffect(updateWidgetMenuOptions, [customWork]);

  return widgetMenuOptions;
}

const filterForSlashMenuItems = (
  optionsToFilter: any[],
  filterString: string,
  blockData: Block,
  context: IBlockContext
) => {
  return optionsToFilter.filter(
    (option) =>
      option.filterTrigger(blockData, context) &&
      (option.name
        .toLowerCase()
        .trim()
        .includes(filterString.toLowerCase().trim()) ||
        option.prefix.includes(filterString) ||
        option.aliases?.includes(filterString))
  );
};

const filterForWidgetItems = (
  optionsToFilter: any[],
  filterString: string,
  blockData: Block
) => {
  return optionsToFilter.filter(
    (option) =>
      option.filterTrigger(blockData, {}) &&
      (option.name
        .toLowerCase()
        .trim()
        .includes(filterString.toLowerCase().trim()) ||
        option.caption.includes(filterString))
  );
};

export const addedSlashMenuOptions = [
  {
    name: "Bulleted List",
    caption: "Create an outline with bullets",
    value: LineType.bulletedList,
    notRootline: true,
    postValue: LineType.bulletedList,
    outlineMode: LineType.bulletedList,
    prefix: "*",
    action: (props: MenuProps) => {
      if (props.isFirst && props.blockData.indentLevel === 0) {
        changeParentListType(
          props.blockData.id,
          LineType.bulletedList,
          props.context
        );
        return;
      }
      checkForPrefix(
        props.blockData.id,
        props.blockRef,
        LineType.bulletedList,
        props.context
      );
      blockApi.blockAction.refocus();
    },
    aliases: ["•", "-"],
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
  },
  {
    name: "Numbered List",
    caption: "Create an outline with numbers",
    value: LineType.numberedList,
    postValue: LineType.numberedList,
    notRootline: true,
    outlineMode: LineType.numberedList,
    prefix: "1.",
    aliases: ["1)"],
    action: (props: MenuProps) => {
      if (props.isFirst && props.blockData.indentLevel === 0) {
        changeParentListType(
          props.blockData.id,
          LineType.numberedList,
          props.context
        );
        return;
      }
      checkForPrefix(
        props.blockData.id,
        props.blockRef,
        LineType.numberedList,
        props.context
      );
      blockApi.blockAction.refocus();
    },
    filterTrigger: (block: Block, context: IBlockContext) => {
      return true;
    },
  },
];

const endSpace = /\s$/;

const SlashCommandMenu: React.FC<MenuProps> = (props) => {
  const prevPosition = useRef({
    left: `0px`,
    top: `0px`,
  });

  const [optionsToDisplay, setOptionsToDisplay] = useState<any[]>([]);

  const { getMenuContextOptions, setMenuContext } = useMenuContext({
    setOptionsToDisplay,
    blockContext: props.context,
    blockData: props.blockData,
  });

  const menuRef = useRef(null);
  const closeMenu = useCallback((event) => {
    if (event) {
      resetBlockMenu(props.menuStateValue);
    }
  }, []);

  useOnClickOutside(menuRef, closeMenu);

  let hoveredOption = props.menuState.hoveredItem;
  const text = props.menuState.filterMenuBy;

  const activateCheckedOption = (index: number) => {
    batch(() => {
      const option = optionsToDisplay[index];
      if (option) {
        removePlaceholderPlaceCaret(props.blockRef.current);
        const caretPosition = checkCaretPosition(props.blockRef.current);
        $focusOn.next({ ...$focusOn.value, caretPosition });
        option.action(props);
      }
      resetBlockMenu(props.menuStateValue);
    });
  };

  useLayoutEffect(() => {
    if (props.menuState.type === BlockMenuTypes.widget) {
      setMenuContext("widget");
    }
  }, [props.menuState.type]);

  useLayoutEffect(() => {
    if (props.menuState.isOpened) {
      let optionsToDisplayTrigger = [];
      if (props.menuState.filterMenuBy !== "") {
        const optionsToFilter = [...getMenuContextOptions()];
        if (props.menuStateValue.current.value.type === BlockMenuTypes.widget) {
          optionsToDisplayTrigger = filterForWidgetItems(
            optionsToFilter,
            props.menuStateValue.current.value.filterMenuBy,
            props.blockData
          );
        }
        if (
          props.menuStateValue.current.value.type === BlockMenuTypes.slashMenu
        )
          optionsToDisplayTrigger = filterForSlashMenuItems(
            optionsToFilter,
            props.menuStateValue.current.value.filterMenuBy,
            props.blockData,
            props.context
          );
      } else {
        optionsToDisplayTrigger = [...getMenuContextOptions()];
        optionsToDisplayTrigger = optionsToDisplayTrigger.filter((option) =>
          option.filterTrigger(props.blockData, props.context)
        );
      }
      setOptionsToDisplay(optionsToDisplayTrigger);
    }
  }, [props.menuState.filterMenuBy, props.menuState.isOpened]);

  useEffect(() => {
    if (!props.menuState.isOpened) {
      return;
    }
    if (
      optionsToDisplay.length > 0 &&
      props.menuState.hoveredItem >= optionsToDisplay.length
    ) {
      hoveredOption = optionsToDisplay.length - 1;
      if (hoveredOption < 0) hoveredOption = 0;
      const menuStateCopy = { ...props.menuState };
      menuStateCopy.hoveredItem = hoveredOption;
      props.menuStateValue.current.next(menuStateCopy);
    }
  }, [props]);

  useEffect(() => {
    if (!props.menuState.isOpened) {
      return;
    }
    if (endSpace.test(text) && optionsToDisplay.length === 0) {
      resetBlockMenu(props.menuStateValue);
    }
  }, [props.menuState.filterMenuBy]);

  useEffect(() => {
    if (!props.menuState.isOpened) return;
    const el = document.getElementById(
      "menuItem" + props.menuState.hoveredItem
    );
    el?.scrollIntoView({ block: "nearest" });
  }, [props.menuState.hoveredItem]);

  useEffect(() => {
    if (!props.menuState.isOpened) return;
    if (props.menuState.executeSelection === true) {
      resetBlockMenu(props.menuStateValue);
      activateCheckedOption(props.menuState.hoveredItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuState.executeSelection]);

  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  let styleObject: any = {
    left: `${x}px`,
    top: `${y}px`,
  };
  if (selection && selection.rangeCount > 0) {
    const range: Range = selection.getRangeAt(0);
    const boundingRect = range.getBoundingClientRect();
    let rangeX = boundingRect.x;
    let rangeY = boundingRect.bottom;
    if (true) {
      const placeholder =
        props.blockRef.current.querySelectorAll(".slash-placeholder");

      if (placeholder[0]) {
        const placeholderSizes = placeholder[0].getBoundingClientRect();
        rangeX = placeholderSizes.x + placeholderSizes.width;
        rangeY = placeholderSizes.bottom;
        // const closestEl = props.blockRef.current.closest(".flex-section");
        const closestEl = props.blockRef.current.closest(".flex-section");
        let componentX = closestEl ? closestEl.getBoundingClientRect().x : 0;
        let componentY = props.blockRef.current.getBoundingClientRect().y;
        const computed = window
          .getComputedStyle(props.blockRef.current)
          .getPropertyValue("margin-top");
        const marginString = computed.substring(0, computed.length - 2);
        const margin = Number(marginString);

        x = rangeX === 0 ? 10 : rangeX - componentX;
        y = rangeY === 0 ? 10 : rangeY + margin - componentY;

        if (componentX + x + 260 > window.outerWidth) {
          styleObject = {
            right: `${-x}px`,
            top: `${y}px`,
          };
        } else {
          styleObject = {
            left: `${x}px`,
            top: `${y}px`,
          };
        }
        prevPosition.current = styleObject;
      } else {
        styleObject = prevPosition.current;
      }
    }
  }

  return (
    <>
      <div
        className={styles.slashCommandContainer}
        style={styleObject}
        ref={menuRef}
      >
        <Menu className={styles.slashCommandMenu} selectable>
          {optionsToDisplay.length === 0 && (
            <Menu.Item key="0" disabled={true}>
              No results...
            </Menu.Item>
          )}
          {optionsToDisplay.map((option, index) => (
            <Menu.Item
              key={option.name}
              id={"menuItem" + index}
              role="button"
              onClick={(e) => {
                e.domEvent.preventDefault();
                e.domEvent.stopPropagation();
                activateCheckedOption(index);
              }}
              className={
                hoveredOption === index
                  ? `${styles.slashCommandMenu__menuItem} ${styles.nice}`
                  : styles.slashCommandMenu__menuItem
              }
            >
              <div style={{ display: "flex" }}>
                {option.icon ? (
                  <div className={styles.iconContainer}>{option.icon}</div>
                ) : (
                  <></>
                )}
                <div className={styles.labelContainer}>
                  <span className={styles.labelContainer__label}>
                    {option.name}
                  </span>
                  <span
                    className={`${styles.labelContainer__caption} ${
                      !option.caption ? styles.labelContainer__emphasized : ""
                    }`}
                  >
                    {option.caption || "No caption"}
                  </span>
                </div>
                <div className={styles.prefixContainer}>
                  <span>{option.prefix}</span>
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </>
  );
};

function ManualSvgIcon({ pathD }: { pathD: string }) {
  return (
    <span className={styles.svgContainer}>
      <svg viewBox="0 -64 896 896" width="1em" height="1em">
        <path d={pathD} fill="currentColor" />
      </svg>
    </span>
  );
}

function LinkIcon() {
  return (
    <span className={styles.svgContainer}>
      <svg viewBox="-1 -1 18 18" width="1em" height="1em" fill="currentColor">
        <path d="M13.646 10.879V3.084c0-.473-.298-.788-.78-.788l-7.794.016c-.465 0-.764.34-.764.73 0 .39.34.723.73.723h2.466l3.951-.15-1.502 1.329-7.413 7.429a.733.733 0 00-.232.506c0 .39.348.764.755.764.19 0 .365-.075.515-.224l7.42-7.43 1.337-1.502-.158 3.777v2.648c0 .382.332.73.739.73.39 0 .73-.323.73-.763z" />
      </svg>
    </span>
  );
}

function useMenuContext({
  setOptionsToDisplay,
  blockContext,
  blockData,
}: {
  setOptionsToDisplay: React.Dispatch<React.SetStateAction<any[]>>;
  blockContext: IBlockContext;
  blockData: Block;
}) {
  const [menuContext, setMenuContext] = useState<"default" | "widget">(
    "default"
  );

  const widgetMenuOptions = useWidgetMenuOptions({
    blockContext,
  });

  const getMenuContextOptions = () =>
    menuContext === "widget"
      ? widgetMenuOptions.filter((option) =>
          option.filterTrigger(blockData, blockContext)
        )
      : slashMenuOptions.filter((option) =>
          option.filterTrigger(blockData, blockContext)
        );

  useEffect(() => {
    setOptionsToDisplay(getMenuContextOptions());
  }, [menuContext]);

  return {
    menuContext,
    getMenuContextOptions,
    setMenuContext,
  };
}

export default SlashCommandMenu;
