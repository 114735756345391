import React, { useEffect, useRef } from "react";
import {
  SectionNumber,
  useContainerWidth,
  useShallowSelector,
} from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import styles from "./home/baseOverview/baseProfile.module.scss";
import {
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
} from "store/reducers/topNavReducer";
import { batch } from "react-redux";
import TopNavBar from "components/TopNavBar";
import { SplitViewRef } from "../SplitView";
import { MainViewRef } from "../Main";
import { useTranslucentBackground } from "./home/hooks";
import "./home/home.scss";
import BaseProfile from "./home/baseOverview/BaseProfile";
import RightFloatBar from "./home/RightFloatBar";

const BaseOverview: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  const base = useShallowSelector((store) => store.workspace);
  const elRef = useRef(null);
  const width = useContainerWidth(elRef);

  const { image } = useTranslucentBackground(
    paneId === ChunkDestination.secondary ? SplitViewRef : MainViewRef,
    base.avatar
  );

  useEffect(() => {
    batch(() => {
      setPaneSubroutes({
        paneId,
        subRoutesParams: {
          type: "base",
          activeKey: "overview",
        },
      });

      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.baseOverview,
      });
    });

    return () => {
      batch(() => {
        setPaneSubroutes({
          paneId,
        });
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.homePage} flex-fill page__mxWidth1355`}
        ref={elRef}
      >
        <div className={`${styles.container} flex-fill`}>
          <TopNavBar
            paneId={paneId}
            designatedType={TopNavbarType.baseOverview}
            sectionWidthMode={width}
            intersectionWith={elRef}
          />
          <div
            className={`${styles.content} content`}
            style={
              SectionNumber[width] <= 2
                ? {
                    gap: "16px",
                    flexDirection: "column-reverse",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }
                : undefined
            }
          >
            <RightFloatBar
              customStyle={styles.newHome}
              card={false}
              fitContainer={SectionNumber[width] <= 4}
            >
              <BaseProfile paneId={paneId} />
            </RightFloatBar>
          </div>
        </div>
        {image && <TranslucentBackground image={image} />}
      </div>
    </>
  );
};

const TranslucentBackground: React.FC<{ image: string }> = ({ image }) => {
  return (
    <div
      data-background={image}
      style={{
        background: `url(${image})`,
      }}
      className="translucentBackground"
    ></div>
  );
};

export default BaseOverview;
