import {
  ADD_CITATION,
  CONNECT_CITATION,
  DISCONNECT_CITATION,
  LOAD_CITATIONS,
} from "store/actions";
import { CitationData } from "utilities/types";

interface CitationDataObj {
  [id: string]: CitationData;
}

interface BlockCitationsObj {
  [id: string]: BlockCitations;
}

interface BlockCitations {
  sourceCitations: any;
  referenceCitations: any;
}

interface ICitationsState {
  dict: CitationDataObj;
  blockCitations: BlockCitationsObj;
}

const initialState = {
  dict: {} as CitationDataObj,
  blockCitations: {} as any,
};

const handleCitationInsert = (
  newState: ICitationsState,
  citation: CitationData
) => {
  const citationId = citation.citationId;
  if (!citationId) return;
  newState.dict[citationId] = citation;
  const sourceBlockId = citation.sourceBlockId;
  const referenceBlockId = citation.referencingBlockId;
  if (!newState.blockCitations[sourceBlockId])
    newState.blockCitations[sourceBlockId] = {
      referenceCitations: {},
      sourceCitations: {},
    };
  if (newState.blockCitations[sourceBlockId]) {
    newState.blockCitations[sourceBlockId] = {
      ...newState.blockCitations[sourceBlockId],
    };
    newState.blockCitations[sourceBlockId].sourceCitations = {
      ...newState.blockCitations[sourceBlockId].sourceCitations,
    };
    newState.blockCitations[sourceBlockId].sourceCitations[
      citation.citationId
    ] = { citationId: citation.citationId };
  }

  if (!newState.blockCitations[referenceBlockId])
    newState.blockCitations[referenceBlockId] = {
      referenceCitations: {},
      sourceCitations: {},
    };
  if (newState.blockCitations[referenceBlockId]) {
    newState.blockCitations[referenceBlockId] = {
      ...newState.blockCitations[referenceBlockId],
    };
    newState.blockCitations[referenceBlockId].referenceCitations = {
      ...newState.blockCitations[referenceBlockId].referenceCitations,
    };
    newState.blockCitations[referenceBlockId].referenceCitations[
      citation.citationId
    ] = { citationId: citation.citationId };
  }
};

const citationsReducer = (
  state: ICitationsState = initialState,
  action: any
) => {
  switch (action.type) {
    case LOAD_CITATIONS: {
      const newState = { ...state, dict: { ...state.dict } };
      newState.blockCitations = { ...state.blockCitations };
      action.param.citations.forEach((citation: CitationData) => {
        handleCitationInsert(newState, citation);
      });
      return newState;
    }

    case ADD_CITATION: {
      const newCitation = action.param.citation;
      const newState = { ...state, dict: { ...state.dict } };
      newState.blockCitations = { ...state.blockCitations };
      handleCitationInsert(newState, newCitation);
      return newState;
    }

    case CONNECT_CITATION: {
      const newState = { ...state };
      const param = action.param;
      newState.blockCitations = { ...newState.blockCitations };
      if (param.blockId) {
        newState.blockCitations[param.blockId] = {
          ...newState.blockCitations[param.blockId],
        };
        newState.blockCitations[param.blockId].referenceCitations = {
          ...newState.blockCitations[param.blockId].referenceCitations,
        };
        newState.blockCitations[param.blockId].referenceCitations[
          param.citationId
        ] = param.citationId;

        newState.dict = { ...newState.dict };
        newState.dict[param.citationId] = {
          ...newState.dict[param.citationId],
        };
        newState.dict[param.citationId].referencingBlockId = param.blockId;
        newState.dict[param.citationId].isDeleted = false;
        const citationSource = newState.dict[param.citationId].sourceBlockId;
        newState.blockCitations[citationSource] = {
          ...newState.blockCitations[citationSource],
        };
        newState.blockCitations[citationSource].sourceCitations = {
          ...newState.blockCitations[citationSource].sourceCitations,
        };
      }
      return newState;
    }

    case DISCONNECT_CITATION: {
      const newState = { ...state };
      const param = action.param;
      newState.blockCitations = { ...newState.blockCitations };
      if (param.blockId) {
        newState.blockCitations[param.blockId] = {
          ...newState.blockCitations[param.blockId],
        };
        newState.blockCitations[param.blockId].referenceCitations = {
          ...newState.blockCitations[param.blockId].referenceCitations,
        };
        delete newState.blockCitations[param.blockId].referenceCitations[
          param.citationId
        ];
        newState.dict = { ...newState.dict };
        newState.dict[param.citationId] = {
          ...newState.dict[param.citationId],
        };
        newState.dict[param.citationId].isDeleted = true;
        const citationSource = newState.dict[param.citationId].sourceBlockId;
        newState.blockCitations[citationSource] = {
          ...newState.blockCitations[citationSource],
        };
        newState.blockCitations[citationSource].sourceCitations = {
          ...newState.blockCitations[citationSource].sourceCitations,
        };
      }
      return newState;
    }

    default:
      return state;
  }
};

export default citationsReducer;
