import { useShallowSelector } from "utilities/hooks";
import Button, { ButtonTypes } from "./Button";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { axiosInstance } from "index";
import styles from "./baseExport/baseExport.module.scss";

const BaseExport: React.FC = () => {
  const baseId = useShallowSelector((store) => store.workspace.id);
  const [isExporting, setIsExporting] = useState(false);
  const baseName = useSelector(
    (store: ClarityStore) => store.workspace?.name,
    shallowEqual
  );

  const exportBaseProjects = (baseId: string) => {
    setIsExporting(true);
    axiosInstance
      .post(
        "/api/workspace/exportBase",
        {
          baseId: baseId,
        },
        {
          responseType: "blob", // Set the response type to blob
        }
      )
      .then((response) => {
        // Create a blob from the response data
        const blob = new Blob([response.data], { type: "application/zip" });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `${baseName || "base"}_export.zip`;
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        setIsExporting(false);
      })
      .catch((error) => {
        setIsExporting(false);
        console.error(error);
      });

    console.log(`exporting base ${baseId} with name ${baseName}`);
  };

  // const userId = useShallowSelector((store) => store.user?.id);

  // if (
  //   !userId ||
  //   ![
  //     "c919d4b9-8315-47f2-90f9-9f68dff68fa3",
  //     "c32a8c18-4688-4159-9be0-4b315d8ca8d0",
  //     "ee426f85-1052-445a-9dc4-2cbf4c2a843b",
  //     "475ba15f-5add-4b2b-8047-e6f49c2ec106",
  //     "bc862088-32df-480e-b2ef-8c0e4f3717fa",
  //   ].includes(userId)
  // )
  //   return <></>;

  return (
    <div className={styles.baseExportRow}>
      {!isExporting && (
        <span className="caption regular secondary">
          Clarity is shutting down on Dec 15, 2024.{" "}
          <a
            href="https://foragexyz.notion.site/Clarity-is-Sunsetting-on-Dec-15-2024-121e2482c40480098901c83f3e4c9731?pvs=4"
            style={{ textDecoration: "underline" }}
            target="_blank"
            rel="noreferrer"
          >
            Read the full announcement
          </a>
        </span>
      )}
      {isExporting && (
        <span className="caption regular secondary">
          Preparing your download. This may take a few minutes. Do not close
          this tab.
        </span>
      )}

      <Button
        isLoading={isExporting}
        disabled={isExporting}
        buttonType={ButtonTypes.PRIMARY}
        onClick={() => {
          exportBaseProjects(baseId);
        }}
      >
        {isExporting ? "Preparing..." : "Download your data"}
      </Button>
    </div>
  );
};

export default BaseExport;
