import Button, { ButtonTypes } from "components/Button";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { Link, useLocation } from "react-router-dom";
import DefaultPageHoc from "./templates/DefaultPageHoc";
import { WarningTwoTone } from "@ant-design/icons";
import SignUpLoginSwitcher from "components/SignUpLoginSwitcher";
import styles from "./templates/defaultPageHoc/defaultPageHoc.module.scss";
import { usernameSplitter } from "clarity-ui/UserDisplay";

const BaseNotFound: React.FC = () => {
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);
  const location = useLocation();

  return (
    <DefaultPageHoc cardOutline showLogo>
      <div className={styles.errorIcon}>
        <WarningTwoTone
          twoToneColor={["var(--icon-color)", "var(--icon-color)"]}
        />
      </div>
      <h1 className={styles.h1}>Base Not Found</h1>
      <p
        className="caption regular secondary"
        style={{
          marginTop: "4px",
          textAlign: "center",
        }}
      >
        The base you are trying to access cannot be joined or does not exist at
        this address.
      </p>
      <div className={styles.divider} />

      {!user || !user?.id || user?.id === "" ? (
        <>
          <p
            className="caption regular secondary"
            style={{
              marginTop: "4px",
              textAlign: "center",
              marginBottom: "36px",
            }}
          >
            Does this sound like a mistake? You may need to login.
          </p>
          <SignUpLoginSwitcher />
          <p
            className="caption regular disabled"
            style={{
              textAlign: "center",
            }}
          >
            If there is a base at this address you must be a member of the base
            in order to see it.
          </p>
        </>
      ) : (
        <>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={() => window.location.replace(`/`)}
          >
            Return to my content
          </Button>
          <p
            className="caption regular disabled"
            style={{
              textAlign: "center",
              marginTop: "36px",
            }}
          >
            You are logged in as{" "}
            <b>{usernameSplitter(user.name || "@" + user.username)}</b>.{" "}
            <Link
              to={`/logout?redirect=${location.pathname}`}
              style={{ textDecoration: "underline", color: "#1b1b1b" }}
            >
              Not you?
            </Link>
            <br />
            If you believe this is a mistake, check your email for an invitation
            or contact the base admin to confirm that you’ve been invited.
          </p>
        </>
      )}
    </DefaultPageHoc>
  );
};

export default BaseNotFound;
