import React, { useCallback, useEffect, useState } from "react";
import styles from "./button/button.module.scss";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import { LoadingOutlined } from "@ant-design/icons";
import { BaseType, UserRole } from "utilities/types";
import {
  ActionIntercept,
  setActionInterceptor,
} from "store/reducers/clientReducer";

export enum IconSides {
  LEFT = "Left",
  RIGHT = "Right",
}

export enum ButtonShapes {
  CIRCLE = "circle",
  SQUARE = "square",
  OVAL = "oval",
}

export enum ButtonTypes {
  DEFAULT = "Default",
  PRIMARY = "Primary",
  LINK = "Link",
  LARGE_PRIMARY = "Large Primray",
  LARGE_SECONDARY = "Large Secondary",
  LARGE_LINK = "Large Link",
  DANGER = "Danger",
  PLAIN = "Plain",
  MEDIUM_PLAIN = "Medium Plain",
  MEDIUM_PRIMARY = "Medium Primary",
  LARGE_PLAIN = "Large Plain",
}

export interface IButtonProps {
  children?: any;
  icon?: any;
  isLoading?: boolean;
  iconSide?: IconSides;
  buttonType?: ButtonTypes;
  onClick?: any;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  className?: string;
  labelClass?: string;
  disabled?: boolean;
  onMouseDown?: any;
  type?: any;
  disclosure?: boolean;
  additionalIconClass?: string;
  shape?: ButtonShapes;
  size?: string;
  tabIndex?: number;
}

const Button: React.FC<IButtonProps> = (props) => {
  const {
    children,
    icon,
    isLoading,
    iconSide,
    buttonType = ButtonTypes.DEFAULT,
    className,
    labelClass,
    iconStyle,
    onClick,
    style,
    labelStyle = {},
    disabled,
    onMouseDown,
    type,
    additionalIconClass,
    shape,
    tabIndex,
  } = props;

  const visitMode = useCheckPublicBase(disabled);

  const disabledState = disabled && visitMode === ActionIntercept.default;

  const {
    newStyle,
    buttonClassName,
    withLeftIcon,
    withRightIcon,
    iconOnly,
    labelClassName,
    isLink,
    additionalIconClassName,
  } = useButtonState({
    style,
    disabled: disabledState,
    className,
    buttonType,
    icon,
    iconSide,
    children,
    labelClass,
    additionalIconClass,
    shape,
  });

  const onClickAction = useCallback(
    (e) => {
      if (visitMode !== ActionIntercept.default) {
        e.stopPropagation();
        setActionInterceptor(visitMode);
        return;
      }
      if (disabled) return;
      if (onClick) onClick(e);
    },
    [onClick, visitMode, disabled]
  );

  return (
    <button
      onMouseDown={onMouseDown}
      tabIndex={tabIndex}
      onClick={onClickAction}
      style={newStyle}
      disabled={disabledState}
      className={buttonClassName}
      type={type}
    >
      {!withLeftIcon ? (
        <></>
      ) : (
        <span
          className={additionalIconClassName}
          style={{ marginLeft: -2, marginRight: isLink ? 2 : 3 }}
        >
          {icon}
        </span>
      )}
      {!iconOnly ? (
        <></>
      ) : (
        <span
          className={`${styles.icon} ${additionalIconClassName}`}
          style={iconStyle}
        >
          {icon}
        </span>
      )}
      {!children ? (
        <></>
      ) : (
        <span className={labelClassName} style={labelStyle}>
          {children}
        </span>
      )}
      {!withRightIcon ? (
        <></>
      ) : (
        <span
          className={additionalIconClassName}
          style={{ marginLeft: isLink ? 2 : 3, marginRight: 1 }}
        >
          {icon}
        </span>
      )}
      {isLoading ? <LoadingOutlined spin /> : ""}
    </button>
  );
};

function useButtonState({
  style,
  disabled,
  className,
  buttonType,
  icon,
  iconSide,
  children,
  labelClass = "",
  additionalIconClass = "",
  shape,
}: Pick<
  IButtonProps,
  | "style"
  | "disabled"
  | "className"
  | "buttonType"
  | "icon"
  | "iconSide"
  | "children"
  | "labelClass"
  | "additionalIconClass"
  | "shape"
>) {
  let newStyle = { ...style };
  if (disabled) {
    newStyle = {
      cursor: "not-allowed",
      pointerEvents: "none",
      opacity: 0.45,
      ...style,
    };
  }

  const withLeftIcon =
    (children && icon && !iconSide) || iconSide === IconSides.LEFT;
  const withRightIcon = children && icon && iconSide === IconSides.RIGHT;
  const iconOnly = icon && !children;
  const isLink = buttonType === ButtonTypes.LINK;

  const buttonClassName = useOptionalClassName({
    baseStyle: styles.button,
    pairs: [
      {
        extraStyle: styles.buttonLink,
        withExtra: buttonType === ButtonTypes.LINK,
      },
      {
        extraStyle: styles.buttonLinkWithIcon,
        withExtra: buttonType === ButtonTypes.LINK && Boolean(icon),
      },
      {
        extraStyle: styles.buttonSecondary,
        withExtra: buttonType === ButtonTypes.DEFAULT,
      },
      {
        extraStyle: styles.buttonSecondaryWithIcon,
        withExtra: buttonType === ButtonTypes.DEFAULT && Boolean(icon),
      },
      {
        extraStyle: styles.buttonPrimary,
        withExtra: buttonType === ButtonTypes.PRIMARY,
      },
      {
        extraStyle: styles.buttonMediumPrimary,
        withExtra: buttonType === ButtonTypes.MEDIUM_PRIMARY,
      },
      {
        extraStyle: styles.buttonPrimaryWithIcon,
        withExtra: buttonType === ButtonTypes.PRIMARY && Boolean(icon),
      },
      {
        extraStyle: styles.buttonLargePrimary,
        withExtra: buttonType === ButtonTypes.LARGE_PRIMARY,
      },
      {
        extraStyle: styles.buttonLargeSecondary,
        withExtra: buttonType === ButtonTypes.LARGE_SECONDARY,
      },
      {
        extraStyle: styles.buttonLargeLink,
        withExtra: buttonType === ButtonTypes.LARGE_LINK,
      },
      {
        extraStyle: styles.buttonDanger,
        withExtra: buttonType === ButtonTypes.DANGER,
      },
      {
        extraStyle: styles.buttonLeftIcon,
        withExtra: withLeftIcon,
      },
      {
        extraStyle: styles.buttonRightIcon,
        withExtra: withRightIcon,
      },
      {
        extraStyle: styles.buttonLarge,
        withExtra:
          buttonType &&
          [
            ButtonTypes.LARGE_PRIMARY,
            ButtonTypes.LARGE_LINK,
            ButtonTypes.LARGE_SECONDARY,
          ].includes(buttonType),
      },
      {
        extraStyle: styles.buttonLargePrimaryWithIcon,
        withExtra: buttonType === ButtonTypes.LARGE_PRIMARY && Boolean(icon),
      },
      {
        extraStyle: styles.buttonIcon,
        withExtra: iconOnly,
      },
      {
        extraStyle: className ?? "",
        withExtra: Boolean(className),
      },
      {
        extraStyle: styles.buttonCircle,
        withExtra: shape === ButtonShapes.CIRCLE,
      },
      {
        extraStyle: styles.buttonOval,
        withExtra: shape === ButtonShapes.OVAL,
      },
      {
        extraStyle: styles.buttonSquare,
        withExtra: shape === ButtonShapes.SQUARE,
      },
      {
        extraStyle: styles.buttonPlain,
        withExtra: buttonType === ButtonTypes.PLAIN,
      },
      {
        extraStyle: styles.buttonMedium,
        withExtra: buttonType === ButtonTypes.MEDIUM_PLAIN,
      },
      {
        extraStyle: styles.buttonMediumPlainWithIcon,
        withExtra: buttonType === ButtonTypes.MEDIUM_PLAIN && Boolean(icon),
      },
      {
        extraStyle: styles.buttonMediumPlain,
        withExtra: buttonType === ButtonTypes.MEDIUM_PLAIN,
      },
      {
        extraStyle: styles.buttonLargePlain,
        withExtra: buttonType === ButtonTypes.LARGE_PLAIN,
      },
    ],
  });

  const labelClassName = useOptionalClassName({
    baseStyle: styles.textButtonLabel,
    pairs: [
      {
        extraStyle: styles.textButtonLabelWithIcon,
        withExtra: Boolean(icon),
      },
      {
        extraStyle: labelClass,
        withExtra: Boolean(labelClass),
      },
    ],
  });

  const additionalIconClassName = useOptionalClassName({
    baseStyle: styles.additionalIcon,
    pairs: [
      {
        extraStyle: additionalIconClass,
        withExtra: Boolean(additionalIconClass),
      },
    ],
  });

  return {
    newStyle,
    buttonClassName,
    withLeftIcon,
    withRightIcon,
    iconOnly,
    labelClassName,
    isLink,
    additionalIconClassName,
  };
}

const useCheckPublicBase = (disabled?: boolean) => {
  const [mode, setmode] = useState(ActionIntercept.default);
  const storeData = useShallowSelector((store) => ({
    baseVisibility: store.workspace.type,
    userId: store.user?.id,
    userRole: store.client.roleType,
  }));

  useEffect(() => {
    if (!disabled) return setmode(ActionIntercept.default);
    if (storeData.baseVisibility === BaseType.Public) {
      if (!storeData.userId) {
        setmode(ActionIntercept.notAuthUserInPublicBase);
        return;
      }
      if (storeData.userRole === UserRole.GUEST) {
        setmode(ActionIntercept.guestInPublicBase);
        return;
      }
      if (!storeData.userRole) {
        setmode(ActionIntercept.authedUserInPublicBase);
        return;
      }
    }
    setmode(ActionIntercept.default);
  }, [storeData, disabled, storeData.userRole]);

  return mode;
};

export default Button;
