import React, { useState, useEffect, useRef, useCallback } from "react";
import ModalScrimComponent from "./ModalScrimComponent";
import styles from "./modalNewTask/modalNewTask.module.scss";
import workItemModalStyles from "screens/base/main/workItemPreviewModal/workItemPreviewModal.module.scss";
import taskPreviewStyles from "screens/base/main/detailView/documentContainer/task/task.module.scss";
import * as actionTypes from "store/actions";
import Button, { ButtonTypes } from "./Button";
import {
  ContainerTypes,
  IBlockContext,
  WorkTypes,
  ITemplateObj,
  ViewNames,
} from "utilities/types";
import { KeyCodes, LineValueType } from "utilities/lineUtilities";
import ContainerDetailMetadata from "clarity-ui/ContainerDetailMetadata";
import store from "store/storeExporter";
import { Switch, Tooltip } from "antd";
import {
  executeTransaction,
  localChangesTransaction,
} from "editor/utils/specificActions/persistActions";
import { useIsMobile, useShallowSelector } from "utilities/hooks";
import { executeTextSaveTimeout } from "editor/utils/blockActions";
import DocumentContainer from "screens/base/main/detailView/DocumentContainer";
import { templatesApi } from "clientApi/templateApi";
import { setMultipleBlocks } from "editor/utils/specificActions/textUpdateActions";
import { getBlockFromExtender } from "editor/utils/socketActionsListener";
import {
  INewTemplateCreationModes,
  openNewTemplateModal,
} from "store/reducers/clientReducer";
import workApi from "clientApi/workApi";
import Conditional from "./Conditional";
import CloseSquareTwoTone from "icons/Components/CloseSquareTwoTone";
import { updateTemplateItemAction } from "store/reducers/templateReducer";
import { wordTruncate } from "utilities/ellipsisText";
import notificationsApi from "clientApi/notificationsApi";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";
import navigationApi from "clientApi/navigationApi";
import { ChunkDestination } from "utilities/stateTypes";

function NewTaskTemplate() {
  const storeData = useShallowSelector((store) => ({
    baseId: store.workspace.id,
    newTemplateContext: store.client.newTemplateContext,
    templateObj: store.client.newTemplateContext?.presetData?.id
      ? store.templates.dict[store.client.newTemplateContext.presetData.id]
      : undefined,
  }));

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const [includeChidlren, setincludeChildren] = useState(true);

  const presaveTemplate = {
    ...storeData.templateObj,
  };

  const taskName = useRef(presaveTemplate?.name ?? "");
  const groupId = useRef(presaveTemplate?.groupId ?? "");

  useEffect(() => {
    taskName.current = presaveTemplate.name ?? "";
    if (taskName.current === "") setIsSaveDisabled(true);
    else setIsSaveDisabled(false);
  }, []);

  const reopenNewTask = () => {
    const presetData = storeData.newTemplateContext?.presetData
      ? { ...storeData.newTemplateContext.presetData }
      : {};
    delete presetData.id;

    setTimeout(() => {
      openNewTemplateModal({
        type: INewTemplateCreationModes.new,
        presetData,
      });
    }, 0);
  };

  const handleCancel = () => {
    taskName.current = "";
    groupId.current = "";
    setIsSaveDisabled(true);
    setIsSaving(false);
    hideModal();
    if (presaveTemplate) {
      store.dispatch({
        type: actionTypes.UPDATE_TEMPLATE,
        param: {
          id: presaveTemplate.id,
          delta: {},
          type: "delete",
        },
      });
    }
  };

  const handleItemClick = (template: ITemplateObj) => {
    if (!template.isRoot) {
      store.dispatch({
        type: actionTypes.TOGGLE_TEMPLATE_MODAL,
        isOpen: true,
        id: template.id,
      });
      return;
    }
    navigationApi.navigateMain({
      viewName: ViewNames.Detail,
      entity: {
        containerId: template.id,
        containerType: ContainerTypes.TEMPLATE,
      },
    });
  };

  const handleSave = (taskName: string, reopen: boolean) => {
    const newTemplateContext = storeData.newTemplateContext;
    if (taskName.length < 1) return;
    if (!newTemplateContext) return;

    let isExecuted = false;

    setIsSaving(true);
    executeTextSaveTimeout();

    const handleAfterCreation = (newTemplateItem: ITemplateObj) => {
      updateTemplateItemAction({
        type: "add",
        delta: newTemplateItem,
        id: newTemplateItem.id,
        skipInsertInList: false,
      });

      const name = wordTruncate(newTemplateItem.name, 14);
      notificationsApi.displayConfirmation({
        title: <span>Template created</span>,
        duration: 4,
        actions: [
          { action: () => handleItemClick(newTemplateItem), content: "View" },
        ],
        dismissable: true,
        body: (
          <span className={styles.notification__description}>
            <span>{name}</span>
          </span>
        ),
        icon: <CheckCircleTwoTone />,
      });
    };

    const localChangesCopy = { ...localChangesTransaction };
    executeTransaction(
      localChangesCopy,
      undefined,
      ContainerTypes.TEMPLATE + presaveTemplate.id
    );

    if (presaveTemplate.tagsBlockId) {
      const block = store.getState().blocks.dict[presaveTemplate.tagsBlockId];
      if (block) {
        setMultipleBlocks(
          [{ id: block.id, blockData: getBlockFromExtender(block) }],
          {
            autosave: true,
          } as any
        );

        const workItemSaveMode: any[] = [];
        block.value.forEach((tag: any) => {
          if (tag.linkTo && tag.linkTo !== "") {
            const workItemEntry = {
              containerType: tag.options?.workTag
                ? ContainerTypes.WORK
                : ContainerTypes.DOCUMENT,
              containerId: tag.linkTo ? tag.linkTo : "",
            };
            workItemSaveMode.push(workItemEntry);
          }
        });
        presaveTemplate.tags = workItemSaveMode;
      }
    }

    const presets: Partial<ITemplateObj> = {
      workspaceId: presaveTemplate.workspaceId,
      name: taskName,
      workType: presaveTemplate.workType,
      dueDate: presaveTemplate.dueDate,
      assigneeId: presaveTemplate.assigneeId,
      statusId: presaveTemplate.statusId,
      priority: presaveTemplate.priority,
      id: presaveTemplate.id,
      parentId: presaveTemplate.parentId,
      isClosed: presaveTemplate.isClosed,
      isDone: presaveTemplate.isDone,
      titleBlockId: presaveTemplate.titleBlockId,
      tags: presaveTemplate.tags,
      tagsBlockId: presaveTemplate.tagsBlockId,
      contributorIds: presaveTemplate.contributorIds,
      reviewerId: presaveTemplate.reviewerId,
      groupId: presaveTemplate.groupId,
      groupIds: presaveTemplate.groupIds,
      milestoneId: presaveTemplate.milestoneId,
      reward: presaveTemplate.reward,
    };

    if (
      newTemplateContext.type === INewTemplateCreationModes.duplicate &&
      newTemplateContext.generatedFromId
    ) {
      templatesApi.duplicateTemplate(
        newTemplateContext.generatedFromId,
        presets,
        includeChidlren,
        handleAfterCreation
      );

      isExecuted = true;
    }

    if (
      newTemplateContext.type === INewTemplateCreationModes.fromTask &&
      newTemplateContext.generatedFromId
    ) {
      workApi.createTemplateFromWorkItem(
        newTemplateContext.generatedFromId,
        presets,
        includeChidlren,
        handleAfterCreation
      );

      isExecuted = true;
    }

    if (!isExecuted) {
      templatesApi.createNewTemplate(
        {
          ...presets,
          name: taskName,
          nameValue: [{ type: LineValueType.text, value: taskName }],
        },
        taskName,
        presaveTemplate.groupId ?? "",
        handleAfterCreation
      );
    }

    setIsSaving(false);

    hideModal();
    if (reopen) {
      reopenNewTask();
    }
  };

  const handleKeydown = (event: any, taskName: string) => {
    const { keyCode } = event;
    if ((event.ctrlKey || event.metaKey) && keyCode === KeyCodes.enter) {
      if (event.shiftKey) {
        handleSave(taskName, true);
      } else handleSave(taskName, false);
    } else if (keyCode === KeyCodes.esc) {
      if (taskName === "") handleCancel();
    }
  };

  const isMobile = useIsMobile();

  if (!presaveTemplate.id) return <></>;

  return (
    <>
      <div
        style={{
          display: "flex",
          zIndex: 99,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <div
          style={{
            maxHeight: "616px",
          }}
          className={`${workItemModalStyles.item_preview_modal} task-modal ${
            workItemModalStyles.item_preview_modal__new
          }  ${isMobile ? workItemModalStyles.item_preview_modal__mobile : ""}`}
        >
          <div
            style={{
              boxShadow: "none",
              height: "100%",
            }}
            onKeyDown={(event) => handleKeydown(event, taskName.current)}
          >
            <div className={taskPreviewStyles.taskBodyContainer_new}>
              <div className={taskPreviewStyles.taskBodyContainer_new_content}>
                <div
                  className={styles.modalNewTask__body__name}
                  style={{ paddingTop: "32px" }}
                >
                  <NameHolder
                    setIsSaveDisabled={setIsSaveDisabled}
                    taskName={taskName}
                    workType={presaveTemplate.workType ?? WorkTypes.TASK}
                  />
                </div>
                <div className={taskPreviewStyles.taskBody__metadata_1}>
                  <div
                    className={
                      taskPreviewStyles.taskBody__metadata_1__workIdContainer
                    }
                  ></div>
                </div>
                <div>
                  <DocumentContainer
                    containerId={presaveTemplate.id}
                    containerType={ContainerTypes.TEMPLATE}
                    paneId={ChunkDestination.peek}
                    contextPresets={
                      {
                        autosave: false,
                        id: ContainerTypes.TEMPLATE + presaveTemplate.id,
                        container: {
                          id: presaveTemplate.id,
                          type: ContainerTypes.TEMPLATE,
                        },
                      } as IBlockContext
                    }
                    showDefault={true}
                  />
                </div>
                <div className="new-task-mobile-sidebar">
                  <ContainerDetailMetadata
                    key={presaveTemplate.id}
                    noPersist={true}
                    containerId={presaveTemplate.id}
                    containerType={ContainerTypes.TEMPLATE}
                    paneId={ChunkDestination.peek}
                    presave={true}
                    rowMode={true}
                  />
                </div>
              </div>
              <div
                className={`${taskPreviewStyles.taskBodyContainer_new_sidebar} new-task-sidebar`}
              >
                <div
                  className={
                    taskPreviewStyles.taskBodyContainer_new_sidebar_heading
                  }
                >
                  {/* <div className="body2 medium disabled">Properties</div> */}
                  <Button buttonType={ButtonTypes.LINK} onClick={handleCancel}>
                    {<CloseSquareTwoTone />}
                  </Button>
                </div>
                {presaveTemplate && (
                  <ContainerDetailMetadata
                    key={presaveTemplate.id}
                    noPersist={true}
                    containerId={presaveTemplate.id}
                    containerType={ContainerTypes.TEMPLATE}
                    paneId={ChunkDestination.peek}
                    presave={true}
                    rowMode={false}
                  />
                )}
              </div>
            </div>
            <div
              className={taskPreviewStyles.taskBodyContainer_new_sidebar_footer}
            >
              <p
                className={`small regular disabled hint-bar ${taskPreviewStyles.taskBodyContainer_new_sidebar_hint}`}
              >
                Type “/” to add images and other content to the description
              </p>
              <Button
                className="footer-close"
                buttonType={ButtonTypes.DEFAULT}
                onClick={handleCancel}
              >
                Close
              </Button>
              <div
                className={
                  taskPreviewStyles.taskBodyContainer_new_sidebar_footer_action
                }
              >
                <div>
                  <Conditional
                    on={
                      storeData.newTemplateContext?.type ===
                      INewTemplateCreationModes.new
                    }
                  >
                    <Switch onChange={setCreateAnother} />
                    <span className="small regular secondary">
                      {" "}
                      Create another
                    </span>
                  </Conditional>
                  <Conditional
                    on={
                      storeData.newTemplateContext?.type ===
                        INewTemplateCreationModes.duplicate ||
                      storeData.newTemplateContext?.type ===
                        INewTemplateCreationModes.fromTask
                    }
                  >
                    <Switch
                      onChange={setincludeChildren}
                      checked={includeChidlren}
                    />
                    <span className="small regular secondary">
                      {" "}
                      Include subtasks
                    </span>
                  </Conditional>
                </div>

                <Tooltip title="Cmd+Enter" trigger={"hover"}>
                  <span>
                    <Button
                      buttonType={ButtonTypes.PRIMARY}
                      onClick={() =>
                        handleSave(taskName.current, createAnother)
                      }
                      style={{ marginLeft: "8px", minWidth: "137px" }} // need to fix this
                      className={
                        isSaveDisabled || isSaving ? styles.disabledButton : ""
                      }
                      disabled={isSaveDisabled || isSaving}
                    >
                      {isSaving ? "Saving..." : "Save"}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <ModalScrimComponent hideModal={() => {}} />
      </div>
    </>
  );
}

const NameHolder: React.FC<{
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  taskName: React.MutableRefObject<string>;
  workType: WorkTypes;
}> = ({ setIsSaveDisabled, taskName, workType }) => {
  const handleTaskNameChange = (event: any) => {
    const { value } = event.target;

    if (value === "") setIsSaveDisabled(true);
    else setIsSaveDisabled(false);
    taskName.current = value;
    setName(value);
  };

  const [name, setName] = useState(taskName.current);
  const InputTaskName = useRef<HTMLInputElement | null>(null);

  const setFocus = useCallback((el: HTMLInputElement | null) => {
    if (el) {
      InputTaskName.current = el;
      setTimeout(() => {
        el.focus();
      }, 1);
    }
  }, []);

  return (
    <input
      style={{ width: "100%", paddingLeft: 0 }}
      type="text"
      value={name}
      onChange={handleTaskNameChange}
      placeholder={`Untitled ${getNameFromWorkType(workType)} template`}
      ref={(el) => setFocus(el)}
    />
  );
};

const getNameFromWorkType = (workType: WorkTypes) => {
  switch (workType) {
    case WorkTypes.TASK:
      return "task";
    case WorkTypes.PROJECT:
      return "project";
    case WorkTypes.INITIATIVE:
      return "goal";
  }
};

const hideModal = () =>
  store.dispatch({ type: actionTypes.HIDE_MODAL_NEW_TEMPLATE });

export default NewTaskTemplate;
