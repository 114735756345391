import DefaultPageHoc from "./templates/DefaultPageHoc";
import styles from "./templates/defaultPageHoc/defaultPageHoc.module.scss";

const NotFound = () => {
  return (
    <DefaultPageHoc>
      <h3 className={styles.h3}>Page Not Found</h3>
      <div
        className="caption regular secondary"
        style={{ marginBottom: "32px" }}
      >
        Nothing to see here. This URL does not match a page in this base.
      </div>
    </DefaultPageHoc>
  );
};

export default NotFound;
