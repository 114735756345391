import React from "react";
import styles from "./documentCellSecondary/documentCellSecondary.module.scss";

interface IDocumentCellSecondaryProps {
  children: any;
}

export default function DocumentCellSecondary(
  props: IDocumentCellSecondaryProps
) {
  return (
    <div className={styles.documentList__cell_secondary}>{props.children}</div>
  );
}
