import React, { useEffect } from "react";
import { batch } from "react-redux";
import {
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
} from "store/reducers/topNavReducer";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { GroupGeneralViews } from "utilities/types";
import Roadmap from "../../Roadmap";

const GroupRoadmap: React.FC<{
  paneId: ChunkDestination;
  groupSlug: string;
}> = ({ groupSlug, paneId }) => {
  const group = useShallowSelector((store) => store.groups.slugDict[groupSlug]);
  useEffect(() => {
    if (!group) return;
    batch(() => {
      setPaneSubroutes({
        paneId,
        subRoutesParams: {
          type: "group",
          activeKey: "roadmap",
          groupId: group.id,
        },
      });

      if (group.id) {
        setPaneTopNavViewType({
          paneId,
          navType: TopNavbarType.group,
        });
      }
    });

    return () => {
      batch(() => {
        setPaneSubroutes({
          paneId,
        });
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, [group?.id]);

  return (
    <div
      className={`flex-fill page__mxWidth1355`}
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      <Roadmap
        paneId={paneId}
        viewId={`${GroupGeneralViews.Projects}${groupSlug}`}
      />
    </div>
  );
};

export default GroupRoadmap;
