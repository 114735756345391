import React from "react";
import { create } from "@simonwep/selection-js";
import { batch } from "react-redux";
import store, { $documentMode, $focusOn } from "store/storeExporter";
import {
  REFOCUS,
  SELECT_BLOCK,
  UNSELECT_BLOCK,
  UNSELECT_BLOCKS,
} from "store/actions";
import { DocumentModes } from "utilities/types";

export let mouseDown = false;
export let origin: string | null = null;
export let selectionStarted = false;
export const setSelectionStarted = (isSelectionStarted: boolean) => {
  selectionStarted = isSelectionStarted;
};

export let insideContent = false;
export let preventClick = false;
export const setPreventClick = (preventClickBool: boolean) => {
  preventClick = preventClickBool;
};

// export let selection: any | null = null;
let destination = "primary";
let element: any;

export const checkManualTrigger = (e: any, insideContent: boolean) => {
  try {
    if (insideContent) {
      selection?.option("class", "no-color");
    } else {
      selection?.option("class", "selection-area");
    }
    selection?.enable();
    // document.getSelection()?.removeAllRanges();
    preventClick = true;
    selectionStarted = true;
    selection?.clearSelection();
    selection?.trigger(e);
    setPreventClick(true);
  } catch {}
};

export const checkManualSelect = (element: HTMLElement | null) => {
  if (element) {
    selection?.select(element);
  }
};

export let setMouseDown = (mode: boolean) => {
  mouseDown = mode;
};

export const setInisdeContent = (isInsideContent: boolean) => {
  insideContent = isInsideContent;
};

export const selectionDisable = () => {
  selection.cancel();
  selection?.disable();
};

export const selection = create({
  // Class for the selection-area
  class: "selection-area",

  // All elements in this container can be selected
  selectables: [".select-area .clarity-selectable"],

  // The container is also the boundary in this case
  boundaries: [".document-view"],
  // startareas: [".document-view"],
  scrollSpeedDivider: 20,
  manualScrollSpeed: 250,
})
  .on("beforestart", ({ inst, selected, oe }) => {
    if (mouseDown) return true;
    return false;
  })
  .on("start", ({ inst, selected, oe }) => {
    const target: any = oe.target;
    element = target.closest(".document-view");
    if (element) {
      const otherElements = document.getElementsByClassName("document-view");
      Array.from(otherElements).forEach((el) => {
        el.classList.remove("noSelect");
      });
      element.classList.add("noSelect");
    }

    inst.option("selectables", [".noSelect .clarity-selectable"]);
    selection.resolveSelectables();

    inst.clearSelection();
    // oe.preventDefault();

    $documentMode.next(DocumentModes.BLOCK);
    if ($documentMode.value === DocumentModes.BLOCK) {
      store.dispatch({
        type: UNSELECT_BLOCKS,
        param: {
          event: oe,
        },
      });
    }
    if (insideContent) {
      const selections = document.querySelectorAll(".selection-area");
      selections[0]?.parentElement?.style.setProperty("z-index", "99");
      selections[0]?.classList.add("no-color");
    } else {
      const selections = document.querySelectorAll(".selection-area");
      selections[0]?.parentElement?.style.setProperty("z-index", "99");
      selections[0]?.classList.remove("no-color");
    }
  })
  .on("move", ({ changed: { removed, added }, selected, oe, area, inst }) => {
    batch(() => {
      for (const el of removed) {
        const id = el.getAttribute("data-block-id");
        if (id) {
          store.dispatch({
            type: UNSELECT_BLOCK,
            param: {
              id,
              mouseSelection: true,
            },
          });
        }
      }
      for (const el of added) {
        const id = el.getAttribute("data-block-id");
        if (id)
          store.dispatch({
            type: SELECT_BLOCK,
            param: {
              id: id,
              mouseSelection: true,
            },
          });
      }
    });
  })
  .on("stop", ({ inst, selected, oe }) => {
    mouseDown = false;
    setMouseDown(false);
    if (element) element.classList.remove("noSelect");
    batch(() => {
      store.dispatch({
        type: UNSELECT_BLOCKS,
        param: {
          event: oe,
        },
        destination,
      });
      for (const el of selected) {
        const id = el.getAttribute("data-block-id");
        if (id)
          store.dispatch({
            type: SELECT_BLOCK,
            param: {
              id: id,
              mouseSelection: false,
              destination,
            },
            destination,
          });
      }

      store.dispatch({ type: REFOCUS, param: { newFocus: $focusOn.value } });
      if (selected.length > 0) {
        $documentMode.next(DocumentModes.BLOCK);
      }
    });
    inst.keepSelection();
    inst.option("selectables", [".select-area .clarity-selectable"]);
  });

const Overlay: React.FC<any> = (props) => {
  return <div className="clarity-overlay"></div>;
};

export default Overlay;
