import React, { useEffect } from "react";
import styles from "./mentionsAndRepliesInbox/mentionsAndRepliesInbox.module.scss";
import ChevronDown from "icons/Components/ChevronDown";
import { getUserThreads } from "modules/notificationsService";
import { shallowEqual, useSelector } from "react-redux";
import { UserEventTypes } from "utilities/types";
import { Dropdown, Menu, Spin } from "antd";
import InboxItem from "./mentionsAndRepliesInbox/InboxItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { prevState } from "store/storeExporter";
import { mixpanel } from "App";
import Button, {
  ButtonShapes,
  ButtonTypes,
  IconSides,
} from "components/Button";

const MentionsAndRepliesInbox: React.FC<{ mode: "inbox" | "done" }> = ({
  mode,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.list} id="list">
        {mode === "inbox" ? <InboxView /> : <DoneView />}
      </div>
    </div>
  );
};

export default MentionsAndRepliesInbox;

const InboxView: React.FC<any> = () => {
  useEffect(() => {
    mixpanel.track(UserEventTypes.NOTIFICATION_INBOX_VIEWED, {
      distinct_id: prevState.value?.user?.id,
    });
  }, []);

  const items = useSelector(
    (state: any) => ({
      list: state.inAppNotifications.inboxNotifications,
      count: state.inAppNotifications.position,
    }),
    shallowEqual
  );

  const fetchMoreData = () => {
    getUserThreads(
      prevState.value.user ? prevState.value.user.id : "",
      prevState.value.workspace.id,
      items.count.page
    );
  };

  return (
    <>
      <InfiniteScroll
        dataLength={items.list.length}
        scrollableTarget="list"
        next={fetchMoreData}
        hasMore={!items.count.allLoaded}
        loader={
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
        }
      >
        {items.list.map((item: string) => (
          <div key={item}>
            <InboxItem key={item} id={item} mode={"inbox"} />
          </div>
        ))}
      </InfiniteScroll>
      {items.list.length === 0 && (
        <div className={styles.inboxZeroMessage}>
          <span className="caption bold secondary">That's all for now</span>
          <br />
          <span className="caption secondary">You’ve reached Inbox Zero.</span>
          <span></span>
        </div>
      )}
    </>
  );
};

const DoneView: React.FC<any> = () => {
  useEffect(() => {
    mixpanel.track(UserEventTypes.NOTIFICATION_DONE_VIEWED, {
      distinct_id: prevState.value?.user?.id,
    });
  }, []);

  const items = useSelector(
    (state: any) => ({
      list: state.inAppNotifications.doneNotifications,
      count: state.inAppNotifications.donePosition,
    }),
    shallowEqual
  );

  const fetchMoreData = () => {
    getUserThreads(
      prevState.value.user ? prevState.value.user.id : "",
      prevState.value.workspace.id,
      items.count.page,
      true
    );
  };

  return (
    <>
      <InfiniteScroll
        dataLength={items.list.length}
        // scrollableTarget="notes"
        next={fetchMoreData}
        hasMore={!items.count.allLoaded}
        loader={
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
        }
      >
        {items.list.map((item: string) => (
          <div key={item}>
            <InboxItem key={item} id={item} mode={"done"} />
          </div>
        ))}
      </InfiniteScroll>
    </>
  );
};

export const InboxHeader: React.FC<{
  setMode: any;
  mode: "inbox" | "done";
}> = ({ setMode, mode }) => {
  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              onClick={() => setMode(mode === "inbox" ? "done" : "inbox")}
              key="first"
            >
              Switch to {mode === "inbox" ? "Done" : "Inbox"}
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
        placement="bottomRight"
      >
        <Button
          buttonType={ButtonTypes.DEFAULT}
          shape={ButtonShapes.OVAL}
          iconSide={IconSides.RIGHT}
          icon={<ChevronDown />}
          style={{ marginLeft: "-6px" }}
        >
          {mode === "inbox" ? "Inbox" : "Done"}
        </Button>
      </Dropdown>
    </>
  );
};
