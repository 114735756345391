import { batch } from "react-redux";
import { BehaviorSubject } from "rxjs";
import { Block, BlockData } from "store/reducers/blockReducer";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store from "store/storeExporter";
import { BlockActionTypes } from "./blockActions";
import { handleUpdateAction } from "./primitiveActions/primitiveActions";
import { checkBlockRecover } from "./specificActions/addBlockActions";
import { deleteBlockMiddleware } from "./specificActions/deleteBlockActions";
import { ActionObject } from "./specificActions/undoUtils";

export const getBlockFromExtender = (fullBlock: Block) => {
  const data: BlockData = {
    id: fullBlock.id,
    baseId: fullBlock.baseId,
    value: fullBlock.value,
    containerId: fullBlock.containerId,
    containerType: fullBlock.containerType,
    createdBy: fullBlock.createdBy,
    checkboxStatus: fullBlock.checkboxStatus,
    dateCreated: fullBlock.dateCreated,
    dateModified: fullBlock.dateModified,
    indentLevel: fullBlock.indentLevel,
    isFolded: fullBlock.isFolded,
    lineType: fullBlock.lineType,
    modifiedBy: fullBlock.modifiedBy,
    parentId: fullBlock.parentId,
    subLinesList: fullBlock.subLinesList,
    textDateModified: fullBlock.textDateModified,
    textModifiedBy: fullBlock.textModifiedBy,
    documentRank: fullBlock.documentRank,
    referencingContainerId: fullBlock.referencingContainerId,
    referencingContainerType: fullBlock.referencingContainerType,
    relatedEntities: fullBlock.relatedEntities,
  };
  return data;
};

export const getFullBlockFromData = (block: BlockData) => {
  const subject = new BehaviorSubject<Partial<Block>>(block);
  const fullBlock: Block = { ...block, children: [], blockSubject: subject };
  return fullBlock;
};

export const checkSocketChanges = (actions: ActionObject[]) => {
  const newState = store.getState().blocks;
  batch(() => {
    actions.forEach((action) => {
      if (action.type === BlockActionTypes.update) {
        handleUpdateAction(action, false, undefined, { socketUpdate: true });
      }
      if (action.type === BlockActionTypes.insert) {
        const prevBlock = getBlockById(newState.dict, action.id);
        const fullBlock = getFullBlockFromData({
          ...prevBlock,
          ...action.delta,
        });
        if (fullBlock.id) {
          checkBlockRecover(fullBlock, {
            socketUpdate: true,
          });
        }
      }
      if (action.type === BlockActionTypes.delete) {
        deleteBlockMiddleware(action.id, {
          socketUpdate: true,
        });
      }
    });
  });
};
