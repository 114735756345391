import { useShallowSelector } from "utilities/hooks";
import PeekViewModal from "./peekView/PeekViewModal";

const PeekViewListener: React.FC = () => {
  const navigationChunk = useShallowSelector(
    (store) => store.navigation.navigation.peek
  );

  if (!navigationChunk || !navigationChunk.viewName) return <></>;

  return <PeekViewModal navigationChunk={navigationChunk} />;
};

export default PeekViewListener;
