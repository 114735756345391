// Vendor Libraries
import { Form } from "antd";
import Button, { ButtonTypes } from "components/Button";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useState } from "react";

// Internal Modules
import * as actionTypes from "../store/actions";
import { IWorkspaceObj, IUserObj } from "../utilities/types";
import { useHistory } from "react-router-dom";
import ClarityLogo from "clarity-ui/ClarityLogo";
import ClarityInput from "components/ClarityInput";

import styles from "./launchBase/styles.module.scss";
import { userApi } from "clientApi/userApi";
import { axiosInstance } from "index";

interface IMapStateToProps {
  user: IUserObj;
}

interface IMapDispatchToProps {
  setActiveWorkspace: (workspace: IWorkspaceObj) => {
    type: string;
    workspace: IWorkspaceObj;
  };
  addNewWorkspace: (workspace: IWorkspaceObj) => {
    type: string;
    workspace: IWorkspaceObj;
  };
}

function SignupPartTwo(props: IMapStateToProps & IMapDispatchToProps) {
  const history = useHistory();

  const [name, setName] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorStatus, setErrorStatus] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState("");

  const isBaseNameValid =
    name && name.trim().length > 0 && name.trim().length < 40;
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case "your-name":
        setName(value);
        break;
      default:
        console.log("unanticipated form field");
        break;
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    axiosInstance
      .patch("/api/user", {
        patch: [{ op: "replace", path: "/name", value: name }],
      })
      .then((res) => {
        userApi.updateUser({ name });
        axiosInstance
          .post("/api/workspace", {
            name: `${res.data.payload.name}'s Base`,
          })
          .then((res) => {
            const { payload: base } = res.data;
            setErrorStatus(0);
            setErrorMessage("");
            props.addNewWorkspace(base);
            history.push(`/${base.slug}`);
          })
          .catch((err) => {
            if (err.response) {
              const { message, status } = err.response.data;
              setErrorStatus(status);
              setErrorMessage(message);
            }
            console.log("Error: ", err);
          });
      });
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Getting Started • Clarity</title>
      </Helmet>
      <ClarityLogo containerStyle={({ isMobile }) => ({ marginBottom: 64 })} />
      <h5 className={styles.info}>Welcome. We're so glad you're here.</h5>
      <Form onSubmitCapture={handleSubmit} className={styles.form}>
        <div className={styles.inputRow}>
          <Form.Item className={styles.inputRowInputItem}>
            <ClarityInput
              className={styles.inputRowInput}
              label="What's your name (or alias)?"
              style={{ width: "100%" }}
              onChange={handleChange}
              placeholder="Your name"
              value={name}
              name="your-name"
            />
          </Form.Item>
        </div>
        <div className={styles.inputRow}>
          <Form.Item>
            <Button
              buttonType={ButtonTypes.LARGE_PRIMARY}
              disabled={!isBaseNameValid}
              type="submit"
            >
              <span className={styles.continueBtnText}>Continue</span>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setActiveWorkspace: (workspace: IWorkspaceObj) =>
    dispatch({
      type: actionTypes.SET_ACTIVE_WORKSPACE,
      workspace,
    }),
  addNewWorkspace: (workspace: IWorkspaceObj) =>
    dispatch({
      type: actionTypes.ADD_NEW_WORKSPACE,
      workspace,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupPartTwo);
