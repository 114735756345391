import moment from "moment";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  LIMIT_FREE_BLOCK_COUNT,
  LIMIT_FREE_WORK,
} from "store/reducers/subscriptionReducer";
import { ClarityStore } from "store/storeExporter";

const MonthlyCount: React.FC<{}> = (props) => {
  const stateData = useSelector(
    (state: ClarityStore) => state.subscription.monthlyCount,
    shallowEqual
  );
  const nextMonth = moment(new Date()).add(1, "month").format("MMMM");

  return (
    <div>
      You’ve used {stateData.blockCount}/{LIMIT_FREE_BLOCK_COUNT} blocks and{" "}
      {stateData.workCount}/{LIMIT_FREE_WORK} tasks and projects this month.{" "}
      <br /> This count will reset on {nextMonth} 1, {new Date().getFullYear()}
    </div>
  );
};

export default MonthlyCount;
