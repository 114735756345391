import React, { ReactElement, useMemo } from "react";
import styles from "./itemDescription/itemDescription.module.scss";
import { useOptionalClassName } from "utilities/hooks";

const ItemDescription: React.FC<{
  icon?: any;
  title: string;
  description?: string;
  titleCustomClassname?: string;
  tail?: ReactElement<any, any>;
  customStyle?: React.CSSProperties;
  customIconStyles?: React.CSSProperties;
  noPadding?: boolean;
}> = ({
  icon,
  title,
  description,
  tail,
  customStyle,
  customIconStyles,
  titleCustomClassname,
  noPadding = false,
}) => {
  const style = useMemo(() => {
    return customStyle ? customStyle : {};
  }, [customStyle]);

  const containerClassName = useOptionalClassName({
    baseStyle: styles.optionGroupContainer,
    pairs: [
      {
        extraStyle: styles.optionGroupContainerNoPadding,
        withExtra: noPadding,
      },
    ],
  });

  const titleClassName = useOptionalClassName({
    baseStyle: styles.optionGroupInfoTitle,
    pairs: [
      {
        extraStyle: titleCustomClassname ?? "",
        withExtra: Boolean(titleCustomClassname),
      },
    ],
  });

  return (
    <div className={containerClassName} style={style}>
      {icon && (
        <DescriptionIcon icon={icon} customIconStyles={customIconStyles} />
      )}
      <div className={styles.optionGroupInfo}>
        <span className={titleClassName}>{title}</span>
        {description && (
          <span className={styles.optionGroupInfoDescription}>
            {description}
          </span>
        )}
      </div>
      {tail && tail}
    </div>
  );
};

export const DescriptionIcon: React.FC<{
  icon?: any;
  customIconStyles?: React.CSSProperties;
}> = ({ customIconStyles, icon }) => (
  <div className={styles.optionGroupPrefix} style={customIconStyles}>
    {icon}
  </div>
);

export default ItemDescription;
