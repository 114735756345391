import React from "react";
import ChevronDown from "icons/chevron-down.svg";
import ItemDescription from "./ItemDescription";

const OptionGroup: React.FC<{
  icon?: any;
  title: string;
  description?: string;
  value: string;
  readOnly?: boolean;
  hideValue?: boolean;
}> = ({ icon, title, description, value, readOnly, hideValue }) => {
  return (
    <ItemDescription
      icon={icon}
      title={title}
      description={description}
      tail={
        !hideValue ? (
          <OptionDropdown value={value} readOnly={readOnly} />
        ) : undefined
      }
    />
  );
};

const OptionDropdown: React.FC<{ value: string; readOnly?: boolean }> = ({
  value,
  readOnly,
}) => {
  return (
    <div>
      <span className={"caption secondary"}>{value}</span>
      {readOnly ? <></> : <img src={ChevronDown} alt="chevron pointing down" />}
    </div>
  );
};

export default OptionGroup;
