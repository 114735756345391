import React, { useState, useRef, useCallback, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { connect, shallowEqual, useSelector, useStore } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ChevronRight from "icons/chevron-right.svg";
import {
  PushpinOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  FileDoneOutlined,
  CheckCircleOutlined,
  ScissorOutlined,
} from "@ant-design/icons";

import { NumberSize, Resizable } from "re-resizable";
import {
  SidebarModeTypes,
  useSearchSetter,
} from "store/reducers/clientReducer";
import { ClarityStore } from "store/storeExporter";

import {
  getPreviousRank,
  getNextRank,
  getRankBetween,
} from "utilities/containerRankHelpers";
import {
  IPinObj,
  TasksViewModes,
  ContainerTypes,
  IFavorite,
  WorkTypes,
  IProjectObj,
  GeneralViewsNames,
  ViewNames,
  RecentlyOpened,
  NavigationChunk,
} from "utilities/types";
import store from "store/storeExporter";
import { unpinItemFromSidebar } from "utilities/pinService";
import Close from "icons/close.svg";
import styles from "./sidebar/sidebar.module.scss";
import * as actionTypes from "../../store/actions";
import { Direction } from "re-resizable/lib/resizer";
import { KeyCodes } from "utilities/lineUtilities";
import { deviceType, socket } from "App";
import FoldButton from "shannon/FoldButton";
import GettingStartedProgress from "components/GettingStartedProgress";
import { useOptionalClassName, useIsMobile } from "utilities/hooks";
import { DeviceType } from "editor/utils/customHooks";
import { favoriteApi } from "clientApi/favoriteApi";
import { Dropdown } from "antd";
import {
  getContainerByType,
  getLinkFromContainer,
  getNameFromContainer,
} from "modules/containerHelpers";

import Button, { ButtonTypes } from "components/Button";
import { MenuItem } from "components/FabBtn";
import { axiosInstance } from "index";
import { ChunkDestination } from "utilities/stateTypes";
import navigationApi from "clientApi/navigationApi";
import { getIconFromNavigationChunk } from "utilities/iconHelpers";
import FileTextTwoTone from "icons/Components/FileTextTwoTone";
import ControlTwoTone from "icons/Components/ControlTwoTone";
import TagTwoTone from "icons/Components/TagTwoTone";
import SnippetsTwoTone from "icons/Components/SnippetsTwoTone";

interface ISidebarProps {
  withHeader?: boolean;
  children: any;
  sideBarWidth: number;
  setSidebarWidth: (width: number) => void;
  setSplitViewWidth: (width: number) => void;
  mentionsAndRepliesInbox: {
    isOpen: boolean;
  };
  setMentionsAndRepliesInbox: (isOpen: boolean) => void;
  hideFooter?: boolean;
  noBorder?: boolean;
  isSidebarHidden: boolean;
  isSidebarHovered: boolean;
  lockScroll?: boolean;
}

const SidebarWrapper: React.FC<ISidebarProps> = (props) => {
  const { isSidebarHidden } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customStyles, setcustomStyles] = useState<any>({
    zIndex: 91,
    width: "100%",
  });

  const mode = useSelector<any, SidebarModeTypes>(
    (state: any) => state.client.sidebarMode,
    shallowEqual
  );

  const isMobile = useIsMobile();

  return useMemo(() => {
    return (
      <>
        <div
          style={{
            position: "relative",
            width: "100%",
          }}
        >
          <Sidebar {...props} isMobile={isMobile} customStyles={customStyles} />
        </div>
      </>
    );
  }, [mode, isSidebarHidden, customStyles, props]);
};

function Sidebar(
  props: ISidebarProps & { customStyles: any; isMobile: boolean }
) {
  let headerComponent;
  let children = Array.isArray(props.children)
    ? [...props.children]
    : [props.children];

  if (props.withHeader) {
    headerComponent = children.shift();
  }

  return (
    <>
      <div
        className={`${styles.sidebar} ${
          props.noBorder ? styles.sidebar__white : ""
        }`}
        id="clarity-sidebar"
      >
        {props.withHeader ? <>{headerComponent}</> : null}
        <>{children}</>
      </div>
    </>
  );
}

export const ResizebleSidebar: React.FC<{}> = ({ children }) => {
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const sideBarWidth = useSelector(
    (state: ClarityStore) => state.navigation.dimension.sideBarWidth,
    shallowEqual
  );
  const defaultSidebarWidth = sideBarWidth || 265;

  const maxWidth =
    deviceType.value !== DeviceType.mobile
      ? window.innerWidth / 3
      : `${Math.floor(window.innerWidth * (2 / 3))}px`;

  const minWidth =
    deviceType.value !== DeviceType.mobile
      ? "229px"
      : `${Math.floor(window.innerWidth / 3)}px`;

  const resRef = useRef<any>(null);

  const throttledSetIsResizingRef = useRef(() => setIsResizing(true));

  const handleResizeStop = useCallback(
    (
      event: MouseEvent | TouchEvent,
      direction: Direction,
      elementRef: HTMLElement,
      delta: NumberSize
    ) => {
      handleResize(event, direction, elementRef, delta);
      setIsResizing(false);
    },
    []
  );

  const handleResize = useCallback(
    (
      event: MouseEvent | TouchEvent,
      direction: Direction,
      elementRef: HTMLElement,
      delta: NumberSize
    ) => {
      const baseElement = document.getElementById("primary");
      store.dispatch({
        type: actionTypes.SET_SIDEBAR_WIDTH,
        width: elementRef.offsetWidth,
      });
      if (baseElement && baseElement.offsetWidth <= 440)
        store.dispatch({
          type: actionTypes.SET_SPLITVIEW_WIDTH,
          width: window.innerWidth - sideBarWidth - 440,
        });
    },
    []
  );

  return (
    <>
      <Resizable
        minWidth={minWidth}
        minHeight="100%"
        maxWidth={maxWidth}
        size={{ width: `${defaultSidebarWidth}px`, height: "100%" }}
        onResizeStop={handleResizeStop}
        onResizeStart={throttledSetIsResizingRef.current}
        ref={resRef}
        handleStyles={{
          right: {
            backgroundColor: isResizing ? "#EBE1E5" : "transparent",
            width: "3px",
            right: "0px",
            zIndex: 20,
          },
        }}
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
      >
        {children}
      </Resizable>
    </>
  );
};

const sidebarMapStateToProps = (state: ClarityStore) => ({
  sideBarWidth:
    state.navigation.dimension && state.navigation.dimension.sideBarWidth,
  isSidebarHidden: state.client.isSidebarHidden,
  isSidebarHovered: state.client.isSidebarHovered,
  mentionsAndRepliesInbox: state.client.mentionsAndRepliesInbox,
});

const sidebarMapDispatchToProps = (dispatch: any) => ({
  setSidebarWidth: (width: number) =>
    dispatch({
      type: actionTypes.SET_SIDEBAR_WIDTH,
      width,
    }),
  setSplitViewWidth: (width: number) =>
    dispatch({
      type: actionTypes.SET_SPLITVIEW_WIDTH,
      width,
    }),
  setMentionsAndRepliesInbox: (isOpen: boolean) =>
    dispatch({
      type: actionTypes.SET_MENTIONS_AND_REPLIES_INBOX,
      isOpen,
    }),
});

export default connect(
  sidebarMapStateToProps,
  sidebarMapDispatchToProps
)(SidebarWrapper);

interface IGettingStartedWidgetProps {
  onClick: () => void;
}

export function GettingStartedWidget(props: IGettingStartedWidgetProps) {
  return (
    <div className={styles.sidebar__getting_started} onClick={props.onClick}>
      <div className={styles.sidebar__getting_started__topRow}>
        <span>Getting Started</span>
        <img
          src={ChevronRight}
          alt="chevron pointing right"
          style={{ marginLeft: "6px" }}
        />
      </div>
      <div className={styles.sidebar__getting_started__bottomRow}>
        <GettingStartedProgress showInfo={false} />
      </div>
    </div>
  );
}

interface ISidebarSectionProps {
  title: string;
  children: any;
  noToggle?: boolean;
}

export function SidebarSection(props: ISidebarSectionProps) {
  const { noToggle = false } = props;
  const [isFolded, setIsFolded] = useState(false);

  return (
    <div className={styles.sidebar__section}>
      <div
        className={styles.sidebar__section__title}
        onClick={() => {
          setIsFolded(!isFolded);
        }}
      >
        <span
          className={`${styles.sidebar__section__title__text} ${
            isFolded ? styles.sidebar__section__title__text__folded : ""
          }`}
        >
          {props.title}
        </span>
        {noToggle ? (
          <div className={styles.sidebar__section__foldButton} />
        ) : (
          <FoldButton
            isFolded={isFolded}
            containerClass={styles.sidebar__section__foldButton}
            size="micro"
            onClick={() => setIsFolded(!isFolded)}
          />
        )}
      </div>
      {isFolded ? null : (
        <div className={styles.sidebar__section__body}>{props.children}</div>
      )}
    </div>
  );
}

interface ISidebarGroupSectionProps {
  title: string;
  children: any;
  icon?: any;
  to: string;
  groupSlug?: string;
  secondaryViewTitle?: string;
  isActive?: boolean;
  menu?: any;
  ItemIcon?: any;
}

export function SidebarGroupSection(props: ISidebarGroupSectionProps) {
  const {
    icon,
    to,
    title,
    groupSlug,
    secondaryViewTitle,
    isActive,
    menu,
    ItemIcon,
  } = props;
  const [isFolded, setIsFolded] = useState(false); // need in future when we have expand and collapase for group
  const history = useHistory();
  const activeProps =
    isActive === undefined
      ? {}
      : {
          isActive: () => isActive,
        };

  return (
    <div className={styles.sidebar__section}>
      <Dropdown overlay={menu} trigger={["contextMenu"]}>
        <div className={styles.navItemContainer}>
          <NavLink
            to={to}
            className={styles.navItem}
            activeClassName={styles.navItem__active}
            {...activeProps}
            exact
            isActive={(match, location) => {
              if (!match) {
                return false;
              }
              return match.isExact;
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (e.shiftKey) {
                store.dispatch({
                  type: actionTypes.SET_NAVIGATION_CHUNK,
                  navigationChunk: {
                    viewName: secondaryViewTitle,
                    groupSlug: groupSlug,
                  },
                  paneId: ChunkDestination.secondary,
                });
              } else {
                history.push(to);
              }
            }}
          >
            <div className={styles.navItem__icon}>
              {ItemIcon ? <ItemIcon /> : icon ? icon : null}
            </div>
            <div className={styles.navItem__labelContainer}>{title}</div>
            {menu && <div className={styles.navItem__moreMenu}>{menu}</div>}
            <FoldButton
              isFolded={isFolded}
              containerClass={styles.sidebar__section__foldButton}
              size="micro"
              onClick={() => setIsFolded(!isFolded)}
            />
          </NavLink>
        </div>
      </Dropdown>
      {isFolded ? null : (
        <div
          className={
            styles.sidebar__section__body +
            " " +
            styles.sidebar__section__body__group
          }
        >
          {props.children}
        </div>
      )}
    </div>
  );
}

interface ISidebarGroupProps {
  title?: string;
  children: any;
  withTopPad?: boolean;
  withSidePad?: boolean;
}

export function SidebarGroup(props: ISidebarGroupProps) {
  const { withTopPad = false, withSidePad = false } = props;
  const { groupClassName } = useSidebarGroupState({ withTopPad, withSidePad });

  return (
    <div className={groupClassName}>
      {props.title ? (
        <div className={styles.sidebar__group__title}>{props.title}</div>
      ) : null}{" "}
      {props.children}
    </div>
  );
}

function useSidebarGroupState({
  withTopPad,
  withSidePad,
}: {
  withTopPad: boolean;
  withSidePad: boolean;
}) {
  const groupClassName = useOptionalClassName({
    baseStyle: styles.sidebar__group,
    pairs: [
      {
        extraStyle: styles.sidebar__group__topPad,
        withExtra: withTopPad,
      },
      {
        extraStyle: styles.sidebar__group__sidePad,
        withExtra: withSidePad,
      },
    ],
  });

  return {
    groupClassName,
  };
}

interface ISidebarItemProps {
  to?: string;
  label: string;
  viewName?: GeneralViewsNames | string;
  offset?: boolean;
  icon?: any;
  actionIcon?: any;
  actionFn?: any;
  style?: any;
  tasksViewMode?: TasksViewModes;
  badge?: any;
  onClick?: React.MouseEventHandler;
  isActive?: boolean;
  groupSlug?: string;
  showAdd?: boolean;
  ItemIcon?: any;
  menu?: any;
  addMenu?: any;
  parentClass?: boolean;
}

export const SidebarItem: React.FC<ISidebarItemProps> = (props) => {
  const {
    to,
    icon,
    label,
    actionIcon,
    actionFn,
    style,
    tasksViewMode,
    badge,
    onClick,
    isActive,
    groupSlug,
    ItemIcon,
    viewName,
    menu,
    addMenu,
    parentClass = true,
    children,
  } = props;

  const activeProps =
    isActive === undefined
      ? {}
      : {
          isActive: () => isActive,
        };

  const defaultOnClick: React.MouseEventHandler = (e) => {
    if (e.shiftKey) {
      e.preventDefault();
      if (label === "Weekly") {
        navigationApi.openSplitView({
          viewName: GeneralViewsNames.TheWeekly,
        });
      } else {
        if (viewName)
          navigationApi.openSplitView({
            viewName,
            groupSlug,
            taskViewMode: tasksViewMode,
          });
      }
    }
  };

  return (
    <div className={parentClass ? styles.navItemContainer : ""}>
      <NavLink
        to={to ? to : ""}
        className={styles.navItem}
        activeClassName={styles.navItem__active}
        {...activeProps}
        exact={false}
        style={{
          marginTop: icon ? 0 : "-3px",
          ...style,
        }}
        isActive={(match, location) => {
          if (
            viewName === GeneralViewsNames.Roles ||
            viewName === GeneralViewsNames.Groups
          ) {
            let locationName = location.pathname;
            if (locationName.endsWith("/")) {
              locationName.slice(0, -1);
            }
            if (
              locationName.endsWith("/roles") ||
              locationName.endsWith("/groups")
            )
              return true;
          }

          if (
            viewName === GeneralViewsNames.Templates ||
            viewName === GeneralViewsNames.Snippets
          ) {
            let locationName = location.pathname;

            if (locationName.endsWith("/")) {
              locationName.slice(0, -1);
            }
            if (
              locationName.endsWith("/templates") ||
              locationName.endsWith("/snippets")
            )
              return true;
          }

          if (!match) {
            return false;
          }

          if (viewName === GeneralViewsNames.Home) {
            if (match.isExact) return true;
            // let locationName = location.pathname;
            // if (locationName.endsWith("/")) {
            //   locationName.slice(0, -1);
            // }
            // if (
            //    locationName.endsWith("roles") ||
            //   locationName.endsWith("groups") ||
            //   locationName.endsWith("roadmap") ||
            //   locationName.endsWith("pages")
            // )
            // return true;

            return false;
          }
          return true;
        }}
        onClick={onClick ?? defaultOnClick}
      >
        <div className={styles.navItem__icon}>
          {ItemIcon ? <ItemIcon /> : icon ? icon : null}
        </div>
        <div className={styles.navItem__labelContainer}>{label}</div>
        {menu && <div className={styles.navItem__moreMenu}>{menu}</div>}
        {addMenu && <div className={styles.navItem__moreMenu}>{addMenu}</div>}
        {badge ? <div className={styles.navItem__emptyBadge}></div> : null}
        {actionIcon ? (
          <Button
            className={styles.navItem__action}
            type={ButtonTypes.DEFAULT}
            onClick={actionFn}
            icon={actionIcon}
          ></Button>
        ) : null}
        {children}
      </NavLink>
    </div>
  );
};

interface ISidebarItemActionProps {
  onClick: React.MouseEventHandler;
  label: string;
  icon?: any;
  style?: any;
  badgeValue?: boolean;
  hint?: string;
  isActive?: boolean;
  parentClass?: boolean;
  disable?: boolean;
}

export function SidebarItemAction(props: ISidebarItemActionProps) {
  const {
    onClick,
    icon,
    label,
    style,
    badgeValue,
    hint,
    isActive,
    parentClass = true,
    disable = false,
  } = props;
  return (
    <div className={parentClass ? styles.navItemContainer : ""}>
      <div
        className={`${styles.navItem} ${isActive ? styles.navItem__active : ""} 
          ${disable ? styles.navItem__disable : ""}`}
        style={style}
        onClick={onClick}
      >
        <div className={styles.navItem__icon}>
          {icon ? icon : <div style={{ width: "17px", height: "17px" }}></div>}
        </div>
        <div className={styles.navItem__labelContainer}>{label}</div>
        {badgeValue && <SidebarInboxBadge />}
        {hint && (
          <div
            className={
              styles.navItem__action + " " + styles.sidebarSearch__hint
            }
          >
            {hint}
          </div>
        )}
      </div>
    </div>
  );
}

const SidebarInboxBadge: React.FC<any> = () => {
  const badgeCount = useSelector(
    (state: any) => ({
      allUnreadCount: state.inAppNotifications.allUnreadCount,
      allMentionsCount: state.inAppNotifications.allMentionsCount,
    }),
    shallowEqual
  );

  if (badgeCount.allUnreadCount > 0) {
    if (badgeCount.allMentionsCount > 0)
      return (
        <>
          {" "}
          <div className={styles.navItem__badge}>
            <span>{badgeCount.allMentionsCount}</span>
          </div>
        </>
      );
    else return <div className={styles.navItem__emptyBadge}></div>;
  }
  return <></>;
};

interface ISidebarSearchProps {
  actionFn: any;
}

export function SidebarSearch(props: ISidebarSearchProps) {
  const setSearch = useSearchSetter();
  const { isSearching } = useSearchResultsState();
  const searchRef = useRef<any>(null);
  const [hasText, setHasText] = useState(false);

  const startSearch = () => {
    const queryString = searchRef.current.innerText.trim();
    if (queryString.length === 0) {
      setSearch({
        isOpen: false,
        isSearching: false,
      });
      return;
    }
    setSearch({
      isOpen: true,
      isSearching: true,
    });
    const baseId = store.getState().workspace.id;
    axiosInstance
      .get("/api/lines/searchResults", {
        params: {
          queryString,
          baseId,
        },
      })
      .then((res) => {
        setSearch({
          isSearching: false,
          resultArray: Object.values(res.data.res),
        });
      });
  };

  return (
    <>
      <div
        className={styles.navItem + "" + styles.navItem_extra}
        onClick={(e) => {
          searchRef.current.focus();
        }}
      >
        <div
          className={styles.navItem__icon}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            startSearch();
          }}
        >
          <SearchOutlined />
        </div>
        <div className={styles.navItem__labelContainer}>
          <div
            className={styles.searchContentEditable}
            ref={searchRef}
            onInput={(e) => {
              if (e.currentTarget.innerText.length > 0) setHasText(true);
              else setHasText(false);
            }}
            onCut={(e) => {
              if (e.currentTarget.innerText.length > 0) setHasText(true);
              else setHasText(false);
            }}
            onPaste={(e) => {
              e.preventDefault();
              searchRef.current.innerText =
                e.clipboardData.getData("text/plain");
              if (e.currentTarget.innerText.length > 0) setHasText(true);
              else setHasText(false);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === KeyCodes.esc) {
                e.preventDefault();
                searchRef.current.innerHTML = "";
                setSearch({
                  isOpen: false,
                  isSearching: false,
                });
                setHasText(false);
              }
              if (e.keyCode === KeyCodes.enter) {
                e.preventDefault();
                startSearch();
              }
            }}
            contentEditable="true"
            placeholder="Search"
          ></div>
        </div>
        {hasText && (
          <span
            className={styles.hasTextSidebar}
            onMouseOver={(e) => {
              e.stopPropagation();
            }}
            onClick={(e) => {
              if (isSearching) return;
              e.stopPropagation();
              searchRef.current.innerHTML = "";
              setHasText(false);
              setSearch({
                isOpen: false,
                isSearching: false,
              });
              searchRef.current.focus();
            }}
          >
            {isSearching ? <LoadingOutlined /> : <CloseCircleOutlined />}
          </span>
        )}
      </div>
    </>
  );
}

function useSearchResultsState() {
  const storeState = useStore().getState().client.search;

  return {
    ...storeState,
  };
}

const onDragEnd = (result: any, pinIds: string[], pins: any) => {
  //synchronously update the state to reflect the dnd result
  const { destination, source } = result;

  if (!destination) return;

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const newPinIds: string[] = [...pinIds];
  let pinIdToMove: string = newPinIds.splice(source.index, 1)[0];

  let newRank = "";

  if (destination.index === 0) {
    // get prev rank to first element
    const currentFirstRank = pins[newPinIds[0]].rank;
    if (currentFirstRank) {
      newRank = getPreviousRank(currentFirstRank);
    }
  } else if (destination.index === newPinIds.length) {
    // get next rank to last element
    // (don't need +1 here bc the moved project is the last element now)
    const currentLastRank = pins[newPinIds[newPinIds.length - 1]].rank;
    if (currentLastRank) {
      newRank = getNextRank(currentLastRank);
    }
  } else {
    // get rank between two elements
    const rankBefore = pins[newPinIds[destination.index - 1]].rank;
    const rankAfter = pins[newPinIds[destination.index]].rank;
    if (rankBefore && rankAfter) {
      newRank = getRankBetween(rankBefore, rankAfter);
    }
  }

  pins[pinIdToMove].rank = newRank;
  newPinIds.splice(destination.index, 0, pinIdToMove);
  store.dispatch({
    type: actionTypes.UPDATE_PIN,
    newPinsArray: newPinIds,
  });

  axiosInstance
    .patch(`/api/pin/`, {
      id: pinIdToMove,
      newRank,
      clientId: socket.id,
    })
    .then((res) => {
      // const { payload } = res.data;
    });
};

interface ISidebarPinnedGroup {
  pins: { [key: string]: IPinObj };
  pinIds: string[];
  workspace: any;
  pageDict: any;
  workDict: any;
  noteDict: any;
  weeklyNotesDict: any;
  customWorkDict: any;
  canEditPins: boolean;
}

export function SidebarPinnedGroup(props: ISidebarPinnedGroup) {
  const {
    pinIds,
    workspace,
    pins,
    workDict,
    pageDict,
    noteDict,
    weeklyNotesDict,
    customWorkDict,
    canEditPins,
  } = props;

  return (
    <>
      <DragDropContext
        onDragEnd={(result: any) => onDragEnd(result, pinIds, pins)}
      >
        <Droppable droppableId="pinnedList">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {!pinIds || pinIds.length < 1 ? (
                <div style={{ paddingLeft: "25px" }}>
                  <span className="small disabled">
                    Pinned items will show here
                  </span>
                </div>
              ) : (
                pinIds.map((pinId, index) => {
                  const pin = pins[pinId];
                  return (
                    <Draggable
                      draggableId={pin.id}
                      isDragDisabled={!canEditPins}
                      index={index}
                      key={pin.id}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <SidebarPinnedItem
                            entityId={pin.entityId}
                            entityType={pin.entityType}
                            key={pin.id}
                            id={pin.id}
                            canEditPins={canEditPins}
                            pageDict={pageDict}
                            noteDict={noteDict}
                            weeklyNotesDict={weeklyNotesDict}
                            workDict={workDict}
                            workspace={workspace}
                            customWorkDict={customWorkDict}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

interface ISidebarPinnedItemProps {
  id: string;
  entityId: string;
  entityType: ContainerTypes;
  workspace: any;
  workDict: any;
  pageDict: any;
  noteDict: any;
  weeklyNotesDict: any;
  customWorkDict: any;
  canEditPins: boolean;
  offset?: boolean;
}

export function SidebarPinnedItem(props: ISidebarPinnedItemProps) {
  const { id, entityId, canEditPins } = props;

  let pinnedEntity;
  let entityType = props.entityType;

  pinnedEntity = {
    ...getContainerByType({
      containerType: entityType,
      containerId: entityId,
    }),
  };

  const destination = getLinkFromContainer(pinnedEntity as any, entityType);

  if (pinnedEntity && pinnedEntity)
    return (
      <div className={styles.navItemContainer}>
        <NavLink
          to={destination ? destination : "/"}
          onClick={(e) => {
            if (e.shiftKey) {
              if (entityType === "CustomView") {
                //TODO
                navigationApi.openSplitView({
                  viewName: ViewNames.Detail,
                  groupId: entityId,
                  groupSlug: ContainerTypes.WORK,
                });
              } else {
                const containerType =
                  entityType !== ContainerTypes.PROJECT
                    ? entityType
                    : ContainerTypes.WORK;
                navigationApi.openSplitView({
                  viewName: ViewNames.Detail,
                  groupId: entityId,
                  groupSlug: containerType,
                });
              }

              e.preventDefault();
              e.stopPropagation();
            }
          }}
          activeClassName={styles.navItem__active}
          className={styles.navItem}
        >
          <div
            className={styles.navItem__icon + " " + styles.navItem__icon_small}
          >
            {entityType === ContainerTypes.DOCUMENT && <TagTwoTone />}
            {[ContainerTypes.PROJECT, ContainerTypes.WORK].includes(
              entityType
            ) &&
              (pinnedEntity as IProjectObj).workType !== WorkTypes.TASK && (
                <FileDoneOutlined />
              )}
            {[ContainerTypes.PROJECT, ContainerTypes.TASK].includes(
              entityType
            ) &&
              (pinnedEntity as IProjectObj).workType === WorkTypes.TASK && (
                <CheckCircleOutlined />
              )}

            {entityType === ContainerTypes.NOTE && <FileTextTwoTone />}
            {entityType === ContainerTypes.CUSTOM_VIEW && <ControlTwoTone />}
          </div>
          <div className={styles.navItem__labelContainer}>
            <>{getNameFromContainer(pinnedEntity as any, entityType)}</>
          </div>
          {canEditPins && (
            <div
              className={
                styles.navItem__action + " " + styles.navItem__action_pin
              }
              onClick={(event) => {
                if (!canEditPins) return;
                event.preventDefault();
                unpinItemFromSidebar(id);
              }}
            >
              <PushpinOutlined className="pin" style={{ marginTop: "-3px" }} />
              <img src={Close} className="close" alt="close-icon" />
            </div>
          )}
        </NavLink>
      </div>
    );
  else return <></>;
}

interface ISidebarFavoritesGroup {
  favorites: { [key: string]: IFavorite };
  favoriteIds: string[];
}

const onDragEndFavorites = (
  result: any,
  favoriteIds: string[],
  favorites: any
) => {
  //synchronously update the state to reflect the dnd result
  const { destination, source } = result;

  if (!destination) return;

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const newFavoriteIds: string[] = [...favoriteIds];
  let favoriteIdToMove: string = newFavoriteIds.splice(source.index, 1)[0];

  let newRank = "";

  if (destination.index === 0) {
    const currentFirstRank = favorites[newFavoriteIds[0]].rank;
    if (currentFirstRank) {
      newRank = getPreviousRank(currentFirstRank);
    }
  } else if (destination.index === newFavoriteIds.length) {
    const currentLastRank =
      favorites[newFavoriteIds[newFavoriteIds.length - 1]].rank;
    if (currentLastRank) {
      newRank = getNextRank(currentLastRank);
    }
  } else {
    const rankBefore = favorites[newFavoriteIds[destination.index - 1]].rank;
    const rankAfter = favorites[newFavoriteIds[destination.index]].rank;
    if (rankBefore && rankAfter) {
      newRank = getRankBetween(rankBefore, rankAfter);
    }
  }

  favorites[favoriteIdToMove].rank = newRank;
  newFavoriteIds.splice(destination.index, 0, favoriteIdToMove);

  favoriteApi.updateFavoriteRank({
    favoriteId: favoriteIdToMove,
    newRank: newRank,
    newFavoriteArray: newFavoriteIds,
  });
};

export function SidebarFavoriteGroup(props: ISidebarFavoritesGroup) {
  const { favoriteIds, favorites } = props;

  return (
    <SidebarGroup>
      <SidebarSection title="My Favorites" noToggle={true}>
        <DragDropContext
          onDragEnd={(result: any) =>
            onDragEndFavorites(result, favoriteIds, favorites)
          }
        >
          <Droppable droppableId="favoriteList" mode="standard">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {!favoriteIds || favoriteIds.length < 1 ? (
                  <div style={{ paddingLeft: "16px" }}>
                    <span className="small disabled">
                      Your favorite documents will show here
                    </span>
                  </div>
                ) : (
                  favoriteIds.map((favoriteId, index) => {
                    const favorite = favorites[favoriteId];
                    return (
                      <Draggable
                        draggableId={favorite.id}
                        index={index}
                        key={favorite.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SidebarFavoriteItem
                              entityId={favorite.entityId}
                              entityType={favorite.entityType}
                              key={favorite.id}
                              id={favorite.id}
                              name={favorite.name}
                              navigationChunk={favorite.navigationChunk}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </SidebarSection>
    </SidebarGroup>
  );
}

export const RecentItem: React.FC<{
  recentlyOpened: RecentlyOpened & { onClick: any };
}> = ({ recentlyOpened }) => {
  const containerType = recentlyOpened.navigationChunk?.entity?.containerType;

  const container: any = {
    ...getContainerByType({
      containerType: containerType ?? ContainerTypes.DOCUMENT,
      containerId: recentlyOpened.navigationChunk?.entity?.containerId ?? "",
    }),
    blocks: [],
    type: containerType,
  };

  const title = () => {
    const title = getNameFromContainer(
      container,
      containerType ?? ContainerTypes.DOCUMENT,
      false,
      true
    );
    if (containerType === ContainerTypes.NOTE) {
      if (recentlyOpened.name && recentlyOpened.name !== "")
        return recentlyOpened.name;
    }

    if (!title || title === "") {
      if (recentlyOpened.name) {
        return recentlyOpened.name;
      }
    }
    return title;
  };

  return (
    <MenuItem
      title={title()}
      icon={getIconFromNavigationChunk(recentlyOpened.navigationChunk)}
      onClick={recentlyOpened.onClick}
    />
  );
};

interface ISidebarPinnedItemProps {
  id: string;
  entityId: string;
  entityType: ContainerTypes;
  workspace: any;
  workDict: any;
  pageDict: any;
  noteDict: any;
  weeklyNotesDict: any;
  customWorkDict: any;
  canEditPins: boolean;
  offset?: boolean;
}

interface ISidebarFavoriteItemProps {
  id: string;
  entityId: string;
  entityType: ContainerTypes;
  name: string;
  navigationChunk: NavigationChunk;
}

export function SidebarFavoriteItem(props: ISidebarFavoriteItemProps) {
  const { id, entityId } = props;

  let pinnedEntity: any;
  let entityType = props.entityType;

  pinnedEntity = {
    ...getContainerByType({
      containerType: entityType,
      containerId: entityId,
    }),
  };

  const destination = navigationApi.getLinkFromNavigationChunk(
    props.navigationChunk
  );

  if (pinnedEntity && pinnedEntity)
    return (
      <div className={styles.navItemContainer}>
        <NavLink
          to={destination ? destination : "/"}
          onClick={(e) => {
            if (e.shiftKey) {
              if (entityType === "CustomView") {
                navigationApi.openSplitViewNavigationChunk(
                  props.navigationChunk
                );
              } else {
                navigationApi.openSplitViewNavigationChunk(
                  props.navigationChunk
                );
              }
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          activeClassName={styles.navItem__active}
          className={styles.navItem}
        >
          <div
            className={styles.navItem__icon + " " + styles.navItem__icon_small}
          >
            {entityType === ContainerTypes.DOCUMENT && <TagTwoTone />}
            {[ContainerTypes.PROJECT].includes(entityType) &&
              (pinnedEntity as IProjectObj).workType !== WorkTypes.TASK && (
                <FileDoneOutlined />
              )}
            {[ContainerTypes.PROJECT].includes(entityType) &&
              (pinnedEntity as IProjectObj).workType === WorkTypes.TASK && (
                <CheckCircleOutlined />
              )}
            {entityType === ContainerTypes.NOTE && <FileTextTwoTone />}
            {entityType === ContainerTypes.TEMPLATE && <SnippetsTwoTone />}
            {entityType === ContainerTypes.SNIPPET && <ScissorOutlined />}
            {entityType === ContainerTypes.CUSTOM_VIEW && <ControlTwoTone />}
          </div>
          <div className={styles.navItem__labelContainer}>
            {pinnedEntity.id
              ? getNameFromContainer(pinnedEntity as any, entityType)
              : props.name}
          </div>

          <div
            className={
              styles.navItem__action + " " + styles.navItem__action_pin
            }
            onClick={(event) => {
              event.preventDefault();
              favoriteApi.deleteFavorite({ favoriteId: id });
            }}
          >
            <img src={Close} className="close" alt="close-icon" />
          </div>
        </NavLink>
      </div>
    );
  else return <></>;
}
