import React, { useRef, useEffect, useState } from "react";
import { batch } from "react-redux";
import { Dropdown } from "antd";
import Button, { ButtonTypes } from "components/Button";
import axios from "axios";

import {
  ILineValue,
  KeyCodes,
  LineMenuState,
  ReplyType,
} from "utilities/lineUtilities";
import store from "store/storeExporter";
import discussionStyles from "./discussion.module.scss";
import { CONFIRM_DISCUSSION_STARTED } from "store/actions";
import {
  moveCaretAtLineEnd,
  moveCaretToPreviousPosition,
} from "utilities/caretMovement";
import SharedTooltip from "components/SharedTooltip";
import { socket } from "App";
import linkifyHtml from "linkifyjs/html";
import { handleCreateLink } from "editor/utils/specificActions/textUpdateActions";
import { replacePlacehoders } from "editor/utils/specificActions/textUpdateUtils";
import { Block } from "store/reducers/blockReducer";
import { Abilities, Reply } from "utilities/types";
import { blockApi } from "editor/utils/blockActionsApi";
import { useAbilityChecker } from "editor/utils/customHooks";
import {
  breakDownHtml,
  checkMenuStateOptions,
} from "editor/utils/blockValueHelpers";
import { checkCaretPosition } from "editor/utils/caretUtils";
import UserMentions from "./UserMentions";
const { v4: uuidv4 } = require("uuid");

const linkifyTest = require("linkifyjs");

interface Props {
  editing: boolean;
  relatedBlock: Block;
  discussion: any;
  setisEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  reply?: any;
  createDiscussion?: any;
  innerValue?: any;
  quotedText?: string;
  sethasUnconfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  replyEditingState: React.MutableRefObject<{
    [id: string]: boolean;
  }>;
}

export const openSlashMenu = async (
  caretPosition: number,
  menuState: LineMenuState,
  setMenuState: any,
  type?: string,
  options?: any
) => {
  let isAtBegining = caretPosition === 0 ? true : false;
  const menuStateCopy = { ...menuState };
  menuStateCopy.isOpened = true;
  menuStateCopy.isAtBegining = isAtBegining;
  menuStateCopy.hoveredItem = 0;
  menuStateCopy.filterMenuBy = "";
  menuStateCopy.preOpenedCaretPosition = caretPosition;
  menuStateCopy.options = options;
  if (type) menuStateCopy.type = type;

  await setMenuState({ ...menuStateCopy });
};

const resetMenuState = (menuState: LineMenuState, setMenuState: any) => {
  menuState.isOpened = false;
  menuState.type = "";
  menuState.executeSelection = false;
  menuState.preOpenedCaretPosition = 0;
  setMenuState({ ...menuState });
};

const getPlaceholderClass = (type: string) => {
  switch (type) {
    case "Mentions":
      return ".mention-placeholder";
    case "UserMentions":
      return ".user-mention-placeholder";
    case "Link":
      return ".link-entity-placeholder";
    default:
      return ".slash-placeholder";
  }
};

const replyCheckOpenedMenu = (
  menuState: any,
  setMenuState: any,
  element: any
) => {
  if (menuState.isOpened) {
    const classToCheck = getPlaceholderClass(menuState.type);
    const placeholder = element.querySelectorAll(classToCheck);
    if (placeholder[0]) {
      if (menuState.type !== "Mentions" || menuState.options?.hashtag)
        menuState.filterMenuBy = placeholder[0].innerText.substring(1);
      else menuState.filterMenuBy = placeholder[0].innerText;
      setMenuState({ ...menuState });
      const brs = placeholder[0].getElementsByTagName("br");

      if (brs) {
        for (const item of Array.from(brs)) {
          const itemCopy: any = item;
          itemCopy.replaceWith("");
        }
      }

      if (placeholder[0].innerText.length === 0) {
        placeholder[0].remove();
        resetMenuState(menuState, setMenuState);
      }
    } else resetMenuState(menuState, setMenuState);
  }

  const el: HTMLElement = element;
  const brs = el?.getElementsByTagName("br");
  if (brs) {
    for (const item of Array.from(brs)) {
      item.replaceWith("");
    }
  }
};

const ReplyEditor: React.FC<Props> = (props) => {
  const editorRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optionsArray, setOptionsArray] = useState([]);
  const [menuState, setMenuState] = useState({
    isOpened: false,
    isAtBegining: true,
    hoveredItem: 0,
    filterMenuBy: "",
    type: "",
    executeSelection: false,
    preOpenedCaretPosition: 0,
    options: {
      hashtag: false,
      entityType: null,
    },
  });
  const [showTooltip, setShowToolTip] = useState(false);
  const [showReply, setShowReply] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [replyButtonEnable, setReplyButtonEnable] = useState(false);

  const checkHasEditing = (overwriteEditing?: boolean) => {
    const replyId = props.reply ? props.reply.id : "new";
    props.replyEditingState.current[replyId] =
      replyButtonEnable || (props.editing && !overwriteEditing);
    let isActive = false;
    Object.values(props.replyEditingState.current).forEach((stateToggle) => {
      if (stateToggle) isActive = true;
    });
    props.sethasUnconfirmed(isActive);
  };

  const canComment = useAbilityChecker({
    abilityName: Abilities.CAN_COMMENT_ENTITY,
    entityId: props.relatedBlock.containerId,
    entityType: props.relatedBlock.containerType,
  });

  useEffect(() => {
    checkHasEditing();
  }, [props.editing, replyButtonEnable]);

  useEffect(() => {
    // if (!props.discussion) {
    if (props.relatedBlock.options) editorRef.current.focus();
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relatedBlock.options]);

  useEffect(() => {
    return () => {
      setReplyButtonEnable(false);
      checkHasEditing(true);
    };
  }, []);

  useEffect(() => {
    if (props.editing) {
      editorRef.current.focus();
      moveCaretAtLineEnd(editorRef.current, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editing]);

  useEffect(() => {
    setTimeout(() => {
      if (menuState) {
        const caretPosition = checkCaretPosition(editorRef?.current);
        if (menuState.isOpened) {
          checkMenuStateOptions(menuState);
          setMenuState({ ...menuState });
          moveCaretToPreviousPosition(editorRef?.current, caretPosition + 1);
          const container = document.getElementById("overlay");
          container?.classList.add("noscroll");
        }
        if (!menuState.isOpened) {
          const container = document.getElementById("overlay");
          container?.classList.remove("noscroll");
          replacePlacehoders(editorRef?.current);
          editorRef?.current?.normalize();
          if (
            editorRef?.current &&
            editorRef?.current.textContent?.trim().length === 0
          ) {
            setReplyButtonEnable(false);
            // props.setIsBlocking(false);
          } else {
            setReplyButtonEnable(true);
            // props.setIsBlocking(true);
          }
        }
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState.isOpened]);

  const createDiscussion = async () => {
    const newId = uuidv4();
    return await axios.post("/api/discussion", {
      presetId: newId,
      containerId: props.relatedBlock.containerId,
      containerType: props.relatedBlock.containerType,
      blockId: props.relatedBlock.id,
      documentRank: props.relatedBlock.documentRank, // for socket
      clientId: socket.id,
      inlineDiscussion: true,
    });
  };

  const sendReply = async () => {
    setReplyButtonEnable(false);
    // props.setIsBlocking(false);
    if (editorRef.current) {
      let value: ILineValue[] = [];
      for (const child of editorRef.current.childNodes) {
        value.push(breakDownHtml(child));
      }
      if (!props.discussion) {
        createDiscussion().then(async (res: any) => {
          const discussion = res.data;
          const user = store.getState().user;
          if (user) {
            const dateNow = new Date();
            const newId = uuidv4();
            const presetReply: Reply = {
              id: newId,
              value,
              discussionId: discussion.id,
              isRootOfDiscussion: true,
              quotedText: props.quotedText,
              authorId: user.id,
              type: ReplyType.TEXT,
              dateCreated: dateNow,
              dateModified: dateNow,
              orderInDiscussion: 0,
            };
            discussion.replies = [];
            batch(() => {
              blockApi.blockAction.confirmComment(props.relatedBlock.id);
              store.dispatch({
                type: "ADD_DISCUSSION",
                discussion,
              });
              store.dispatch({
                type: CONFIRM_DISCUSSION_STARTED,
              });
              store.dispatch({
                type: "ADD_REPLY_TO_DISCUSSION",
                discussionId: discussion.id,
                discussionType: discussion.type,
                containerType: discussion.containerType,
                id: discussion.containerId,
                reply: presetReply,
              });
            });

            editorRef.current.innerHTML = "";

            return await axios
              .post("/api/reply", {
                presetId: newId,
                value,
                discussionId: discussion.id,
                authorId: user.id,
                isRootOfDiscussion: true,
                quotedText: props.quotedText,
                container: {
                  id: discussion.containerId,
                  type: discussion.containerType,
                },
                clientId: socket.id,
              })
              .then((res) => {});
          }
        });
      }
      if (props.discussion) {
        const user = store.getState().user;
        if (user) {
          const repliesNr = props.discussion.replies.length;
          const newId = uuidv4();
          const dateNow = new Date();
          const presetReply: Reply = {
            id: newId,
            value,
            discussionId: props.discussion.id,
            authorId: user.id,
            orderInDiscussion: repliesNr,
            isRootOfDiscussion: repliesNr === 0 ? true : false,
            dateCreated: dateNow,
            quotedText: props.quotedText,
            dateModified: dateNow,
            type: props.discussion?.isResolved
              ? ReplyType.REOPEN
              : ReplyType.TEXT,
          };
          if (user.id === "") {
            const localStorageItem: string | null =
              localStorage.getItem("anonymousReplies");
            let replies = [];
            if (localStorageItem) replies = JSON.parse(localStorageItem);
            replies.push(presetReply.id);
            // if (props.anonymousReplies)
            //   props.anonymousReplies.current = replies;
            localStorage.setItem("anonymousReplies", JSON.stringify(replies));
          }
          batch(() => {
            // if (props.toggleResolveDiscussion) {
            //   props.toggleResolveDiscussion(props.discussion.id, false);
            // }
            store.dispatch({
              type: "ADD_REPLY_TO_DISCUSSION",
              discussionId: props.discussion.id,
              discussionType: props.discussion.type,
              containerType: props.discussion.containerType,
              id: props.discussion.containerId,
              reply: presetReply,
            });
          });

          editorRef.current.innerHTML = "";

          return await axios
            .post("/api/reply", {
              presetId: newId,
              value,
              discussionId: props.discussion.id,
              authorId: user.id,
              isRootOfDiscussion: false,
              type: props.discussion?.isResolved ? ReplyType.REOPEN : null,
              clientId: socket.id,
              quotedText: props.quotedText,
              container: {
                id: props.discussion.containerId,
                type: props.discussion.containerType,
              },
            })
            .then((res) => {});
        }
      }
    }
  };

  const updateReply = async () => {
    const updatedReply = { ...props.reply };
    let value: ILineValue[] = [];
    for (const child of editorRef.current.childNodes) {
      value.push(breakDownHtml(child));
    }
    updatedReply.value = value;
    updatedReply.dateModified = new Date();
    // props.setIsBlocking(false);
    store.dispatch({
      type: "UPDATE_REPLY_IN_DISCUSSION",
      discussionId: props.discussion.id,
      discussionType: props.discussion.type,
      containerType: props.discussion.containerType,
      discussion: props.discussion,
      id: props.discussion.containerId,
      reply: updatedReply,
    });
    const replyEntityObject = updatedReply;

    delete replyEntityObject.author;
    delete replyEntityObject.notConfirmed;
    setReplyButtonEnable(false);
    return await axios
      .patch("/api/reply", {
        reply: replyEntityObject,
        clientId: socket.id,
      })
      .then((res) => {
        if (props.setisEditing) props.setisEditing(false);
      })
      .catch();
  };

  const getPlaceholder = () => {
    if (props.createDiscussion) return "Add a new comment";
    if (props.discussion) {
      if (props.discussion?.isResolved) return "Reply & re-open discussion";
      return "Type your reply";
    }
  };

  const handleNativeStylingSave = (event: any, type: string) => {
    document.execCommand(type, false);
  };
  const getDiscussionSaveButtonStyling = (replyButtonEnable: boolean) => {
    if (replyButtonEnable) return " ";
    else return discussionStyles.disabled;
  };

  const eventHandler = (e: React.KeyboardEvent<any>) => {
    if (e.keyCode === KeyCodes.enter) {
      if (menuState.isOpened) {
        e.preventDefault();
        const menuStateCopy = { ...menuState };
        menuStateCopy.executeSelection = true;
        return setMenuState(menuStateCopy);
      }
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        if (editorRef.current?.textContent !== "") {
          if (props.editing) updateReply();
          else sendReply();
        }
      } else {
        editorRef.current.normalize();
        const selection = document.getSelection();
        const caretPosition = checkCaretPosition(editorRef.current);
        const textLength = editorRef.current.textContent.length;
        const range = selection?.getRangeAt(0);
        const text = document.createTextNode("\n");
        range?.insertNode(text);
        range?.setStartAfter(text);
        const newRange = range?.cloneRange();
        selection?.removeAllRanges();
        if (newRange) {
          selection?.addRange(newRange);
        }

        if (caretPosition === textLength) {
          newRange?.insertNode(document.createElement("br"));
          newRange?.collapse(true);
          if (newRange) {
            selection?.removeAllRanges();
            selection?.addRange(newRange);
          }
        }
        e.stopPropagation();
        e.preventDefault();
      }
    }
    if (e.keyCode === KeyCodes.num2) {
      if (e.shiftKey && !(e.ctrlKey || e.metaKey)) {
        if (menuState.isOpened) return;
        const caretPosition = checkCaretPosition(editorRef.current);
        e.preventDefault();
        return openSlashMenu(
          caretPosition,
          menuState,
          setMenuState,
          "UserMentions",
          {
            hashtag: true,
          }
        );
      }
    }
    if (e.keyCode === KeyCodes.esc) {
      if (menuState.isOpened) {
        resetMenuState(menuState, setMenuState);
      }
    }
    if (e.keyCode === KeyCodes.arrow_up) {
      if (menuState.isOpened) {
        e.preventDefault();
        if (menuState.hoveredItem > 0) {
          const newPosition = menuState.hoveredItem - 1;
          menuState.hoveredItem = newPosition;
          setMenuState({ ...menuState });
        }
      }
    }
    if (e.keyCode === KeyCodes.arrow_down) {
      if (menuState.isOpened) {
        e.preventDefault();
        const newPosition = menuState.hoveredItem + 1;
        menuState.hoveredItem = newPosition;
        setMenuState({ ...menuState });
      }
    }
    if (e.keyCode === KeyCodes.b) {
      if (e.ctrlKey || e.metaKey) {
        handleNativeStylingSave(e, "bold");
        e.preventDefault();
      }
    }
    if (e.keyCode === KeyCodes.i) {
      if (e.ctrlKey || e.metaKey) {
        handleNativeStylingSave(e, "italic");
        e.preventDefault();
      }
    }
    if (e.keyCode === KeyCodes.x) {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
        handleNativeStylingSave(e, "strikethrough");
        e.preventDefault();
      }
    }
    if (e.keyCode === KeyCodes.u) {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
        handleCreateLink(e);
        e.preventDefault();
      }
    }
  };

  const pasteHandler = (e: React.ClipboardEvent<HTMLElement>) => {
    e.preventDefault();
    const addedText = e.clipboardData.getData("text/plain");
    const selection = document.getSelection();
    if (selection && selection.rangeCount > 0) {
      if (selection && selection.rangeCount > 0) {
        const caret = selection.getRangeAt(0);
        if (caret.toString().length > 0) {
          const test = linkifyTest.find(addedText);
          if (test.length === 1) {
            const caretPosition = checkCaretPosition(editorRef.current);
            const entry = test[0];
            const contents = caret.cloneContents();
            contents.childNodes.forEach((child) => {
              if (child.nodeType === 3) {
                const newChild = document.createElement("a");
                newChild.classList.add("decorated");
                newChild.href = entry.href;
                newChild.textContent = child.textContent;
                contents.replaceChild(newChild, child);
              }
            });

            caret.deleteContents();
            caret.insertNode(contents);
            moveCaretToPreviousPosition(editorRef.current, caretPosition);

            return;
          }
        }
        caret.deleteContents();
        const textNode1 = document.createTextNode(" ");
        caret.insertNode(textNode1);
        caret.setStartAfter(textNode1);
        caret.setEndAfter(textNode1);
        selection.removeAllRanges();
        const range = new Range();

        const lastEl = document.createTextNode(addedText);
        caret.insertNode(lastEl);

        range.setStartAfter(lastEl);
        range.setEndAfter(lastEl);
        const textNode = document.createTextNode(" ");
        range.collapse();
        range.insertNode(textNode);
        range.setStartAfter(textNode);
        range.setEndAfter(textNode);
        selection.addRange(range);

        const caretPosition = checkCaretPosition(
          e.currentTarget as HTMLDivElement
        );

        setTimeout(() => {
          const element = editorRef.current;
          element.innerHTML = linkifyHtml(element.innerHTML, {
            defaultProtocol: "https",
            validate: true,
          });
          moveCaretToPreviousPosition(element, caretPosition);
        }, 0);
      }
    }
    if (e.currentTarget.textContent?.trim().length === 0) {
      setReplyButtonEnable(false);
      // props.setIsBlocking(false);
    } else {
      setReplyButtonEnable(true);
      // props.setIsBlocking(true);
    }
  };

  if (!props.editing) {
    return (
      <>
        <div
          style={!canComment ? { opacity: "0.45" } : {}}
          className={
            discussionStyles.reply_box + " " + discussionStyles.new_reply
          }
        >
          {props.quotedText && (
            <span className={discussionStyles.reply_quote}>
              {props.quotedText}
            </span>
          )}
          <div className={"section-big Block"}>
            <div
              ref={editorRef}
              placeholder={getPlaceholder()}
              className={discussionStyles.reply_editor}
              contentEditable={canComment ? "true" : "false"}
              onFocus={(e) => {
                setShowReply(true);
              }}
              onCopy={(e) => e.stopPropagation()}
              onBlur={(e) => {
                setShowToolTip(false);
                if (e.currentTarget.textContent?.trim().length === 0)
                  setReplyButtonEnable(false);
                setMenuState({
                  isOpened: false,
                  isAtBegining: true,
                  hoveredItem: 0,
                  filterMenuBy: "",
                  type: "",
                  executeSelection: false,
                  preOpenedCaretPosition: 0,
                  options: {
                    hashtag: false,
                    entityType: null,
                  },
                });
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                eventHandler(e);
              }}
              onInput={(e) => {
                e.stopPropagation();
                replyCheckOpenedMenu(
                  menuState,
                  setMenuState,
                  editorRef.current
                );
                if (e.currentTarget.textContent?.trim().length === 0) {
                  setReplyButtonEnable(false);
                  // props.setIsBlocking(false);
                } else {
                  setReplyButtonEnable(true);
                  // props.setIsBlocking(true);
                }
              }}
              onPaste={(e) => {
                e.stopPropagation();
                pasteHandler(e);
              }}
              onSelect={(e) => {
                const selection = document.getSelection();
                e.stopPropagation();
                if (selection && selection.toString().length > 0) {
                  setShowToolTip(true);
                } else setShowToolTip(false);
              }}
              onClick={(e: any) => {
                if (e.target.nodeName === "A") {
                  const wind = window.open(e.target.href, "_blank");
                  if (!(e.metaKey || e.ctrlKey)) {
                    if (wind) wind.focus();
                  }
                }
                // e.stopPropagation();
                e.preventDefault();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            ></div>
            {showTooltip && <SharedTooltip parentEl={editorRef.current} />}
            {menuState.isOpened ? (
              <Dropdown
                mouseLeaveDelay={200}
                placement="bottomRight"
                forceRender={true}
                overlay={
                  <UserMentions
                    menuState={menuState}
                    setMenuState={setMenuState}
                    resetMenuState={resetMenuState}
                    setOptionsArray={setOptionsArray}
                    element={editorRef.current}
                    destination={null}
                  />
                }
                visible={menuState.isOpened}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></div>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
          {showReply && (
            <div className={discussionStyles.reply_btns}>
              <Button
                buttonType={ButtonTypes.DEFAULT}
                onClick={() => {
                  setShowReply(false);
                  setReplyButtonEnable(false);
                  editorRef.current.innerHTML = "";
                  blockApi.blockAction.confirmComment(props.relatedBlock.id);
                  props.sethasUnconfirmed(false);
                  // if (props.createDiscussion && props.nonCreateDiscussion) {
                  //   props.nonCreateDiscussion();
                  // }
                }}
              >
                Cancel
              </Button>
              <Button
                buttonType={ButtonTypes.PRIMARY}
                disabled={!replyButtonEnable}
                className={getDiscussionSaveButtonStyling(replyButtonEnable)}
                onClick={() => {
                  blockApi.blockAction.confirmComment(props.relatedBlock.id);
                  setTimeout(() => {
                    sendReply();
                  }, 1);
                }}
              >
                {props.createDiscussion
                  ? "Comment"
                  : props.discussion?.isResolved
                  ? "Re-open"
                  : "Reply"}
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
  if (props.editing)
    return (
      <>
        <div className={discussionStyles.reply_box}>
          <div className="section-big Block">
            <div
              ref={editorRef}
              placeholder={"Edit discussion"}
              className={discussionStyles.reply_editor}
              contentEditable="true"
              data-discussion-id={props.discussion ? props.discussion.id : ""}
              dangerouslySetInnerHTML={{
                __html: props.innerValue ? props.innerValue : "",
              }}
              onInput={(e) => {
                e.stopPropagation();
                if (e.currentTarget.textContent?.trim().length === 0) {
                  setReplyButtonEnable(false);
                  // props.setIsBlocking(false);
                } else {
                  setReplyButtonEnable(true);
                  // props.setIsBlocking(true);
                }
              }}
              onBlur={(e) => {
                setShowToolTip(false);
                if (e.currentTarget.textContent?.trim().length === 0)
                  setReplyButtonEnable(false);
                setMenuState({
                  isOpened: false,
                  isAtBegining: true,
                  hoveredItem: 0,
                  filterMenuBy: "",
                  type: "",
                  executeSelection: false,
                  preOpenedCaretPosition: 0,
                  options: {
                    hashtag: false,
                    entityType: null,
                  },
                });
              }}
              onSelect={(e) => {
                const selection = document.getSelection();
                e.stopPropagation();
                if (selection && selection.toString().length > 0) {
                  setShowToolTip(true);
                } else setShowToolTip(false);
              }}
              onPaste={(e) => {
                pasteHandler(e);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                eventHandler(e);
              }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e: any) => {
                if (e.target.nodeName === "A") {
                  const wind = window.open(e.target.href, "_blank");
                  if (!(e.metaKey || e.ctrlKey)) {
                    if (wind) wind.focus();
                  }
                }
                // e.stopPropagation();
                e.preventDefault();
              }}
            ></div>
            {showTooltip && <SharedTooltip parentEl={editorRef.current} />}
            {menuState.isOpened ? (
              <Dropdown
                mouseLeaveDelay={200}
                placement="bottomRight"
                forceRender={true}
                overlay={
                  <UserMentions
                    menuState={menuState}
                    setMenuState={setMenuState}
                    resetMenuState={resetMenuState}
                    setOptionsArray={setOptionsArray}
                    element={editorRef.current}
                    destination={null}
                  />
                }
                visible={menuState.isOpened}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></div>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
          <div className={discussionStyles.reply_btns}>
            <Button
              buttonType={ButtonTypes.DEFAULT}
              className={discussionStyles.link_btn}
              onClick={() => {
                setReplyButtonEnable(false);
                props.sethasUnconfirmed(false);
                if (props.setisEditing) props.setisEditing(false);
              }}
            >
              Cancel
            </Button>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              disabled={!replyButtonEnable}
              className={getDiscussionSaveButtonStyling(replyButtonEnable)}
              onClick={() => {
                updateReply();
                if (props.setisEditing) props.setisEditing(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </>
    );
  return <></>;
};

export default ReplyEditor;
