import {
  CLEAR_FILTER,
  UPDATE_FILTERS,
  SET_PRESETS,
  SET_VIEW_AS,
  SET_INITIAL_FILTERS,
  SET_GROUP_BY,
  SET_ORDER_BY,
  SET_SHOW_ACTIVE_PROJECTS,
  RESET_VIEW,
  SET_CURRENT_FILTERS,
} from "store/actions";
import store from "store/storeExporter";
import {
  GroupTypes,
  IFilterState,
  OrderTypes,
  ViewAsTypes,
  CommandPaletteDateFilterContextTypes,
} from "utilities/types";
import { ChunkDestination } from "utilities/stateTypes";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { IWorkViewConfig } from "screens/base/main/defaultWorkView/types";
import { useContext } from "react";
import { PaneContext } from "App";

export interface ViewSettings {
  filters: IFilterState;
  groupBy: GroupTypes;
  orderBy: OrderTypes;
  viewAs: ViewAsTypes;
  showActiveProjects?: boolean;
}
export interface IFilterStateObj {
  presetsDict: { [id: string]: ViewSettings };
  currentDict: { [id: string]: ViewSettings };
  viewConfigDict: { [id: string]: IWorkViewConfig };
}

export const generateNewChunk = (): IFilterState => ({
  assignees: [],
  excludedAssignees: [],
  labels: [],
  excludedLabels: [],
  dueDate: {
    before: null,
    after: null,
    exactly: null,
    not: null,
    isWeekView: null,
    isOverdue: null,
  },
  closedDate: {
    before: null,
    after: null,
    exactly: null,
    not: null,
    isWeekView: null,
  },
  createdDate: {
    before: null,
    after: null,
    exactly: null,
    not: null,
    isWeekView: null,
  },
  statuses: [],
  excludedStatuses: [],
  priorities: [],
  excludedPriorities: [],
  cycles: [],
  excludedCycles: [],
  parents: [],
  groups: [],
  excludedGroups: [],
  sponsorGroup: null,
  excludedParents: [],
  hasActiveFilters: false,
  type: undefined,
  hasReward: undefined,
  isDone: undefined,
  reviewers: [],
  isClosed: undefined,
});

const initialState: IFilterStateObj = {
  presetsDict: {},
  currentDict: {},
  viewConfigDict: {},
};

const excludedKeys = ["hasActiveFilters"];

const checkIfActiveFilters = (newState: IFilterState) => {
  const hasActiveFilters = () => {
    newState.hasActiveFilters = true;
    return newState;
  };
  const doesNotHaveActiveFilters = () => {
    newState.hasActiveFilters = false;
    return newState;
  };

  for (const [key, value] of Object.entries(newState)) {
    if (!excludedKeys.includes(key)) {
      if (key === "dueDate") {
        if (value.before || value.after || value.isWeekView)
          return hasActiveFilters();
      } else if (key === "closedDate") {
        if (value.before || value.after || value.isWeekView)
          return hasActiveFilters();
      } else if (key === "createdDate") {
        if (value.before || value.after || value.isWeekView)
          return hasActiveFilters();
      } else if (key === "type") {
        if (value) return hasActiveFilters();
      } else if (key === "hasReward") {
        if (value !== undefined) return hasActiveFilters();
      } else if (Array.isArray(value)) {
        if (value.length > 0) return hasActiveFilters();
      }
    }
  }

  return doesNotHaveActiveFilters();
};

export const getDateFilterPropertyNames = (
  ctx: CommandPaletteDateFilterContextTypes
): {
  dateFilterName: "dueDate" | "createdDate" | "closedDate";
  typeName: "before" | "after" | "exactly" | "not";
} => {
  const dateFilterName = ctx.includes("Created")
    ? "createdDate"
    : ctx.includes("Closed")
    ? "closedDate"
    : "dueDate";

  const typeName = ctx.includes("Before")
    ? "before"
    : ctx.includes("After")
    ? "after"
    : ctx.includes("Exactly")
    ? "exactly"
    : "not";

  return {
    dateFilterName,
    typeName,
  };
};

const handleUpdate = (
  newState: IFilterStateObj,
  action: {
    type: string;
    paneId: string;
    delta: Partial<IFilterState>;
    creatingNewView?: boolean;
  }
) => {
  if (!newState.currentDict[action.paneId]?.filters) {
    newState.currentDict[action.paneId].filters = generateNewChunk();
  }

  newState.currentDict = { ...newState.currentDict };
  newState.currentDict[action.paneId] = {
    ...newState.currentDict[action.paneId],
  };

  newState.currentDict[action.paneId].filters = {
    ...newState.currentDict[action.paneId].filters,
    ...action.delta,
  };

  checkIfActiveFilters(newState.currentDict[action.paneId].filters);
  return newState;
};

export const updateFilterAction = (actionData: {
  paneId: string;
  delta: Partial<IFilterState>;
  creatingNewView?: boolean;
}) => {
  store.dispatch({
    type: UPDATE_FILTERS,
    paneId: actionData.paneId,
    delta: actionData.delta,
  });
};

export default function filterReducer(
  state: IFilterStateObj = initialState,
  action: any
) {
  switch (action.type) {
    case UPDATE_FILTERS: {
      let newState = { ...state };
      newState = handleUpdate(newState, action);
      return newState;
    }

    case SET_INITIAL_FILTERS: {
      let newState = { ...state };
      newState.currentDict = { ...newState.currentDict };
      newState.currentDict[action.param.paneId] = {
        filters: action.param.filters,
        groupBy: action.param.groupBy,
        orderBy: action.param.orderBy,
        viewAs: action.param.viewAs,
        showActiveProjects: action.param.showActiveProjects,
      };
      newState.presetsDict = { ...newState.presetsDict };
      newState.presetsDict[action.param.paneId] = {
        filters: action.param.filters,
        groupBy: action.param.groupBy,
        orderBy: action.param.orderBy,
        viewAs: action.param.viewAs,
        showActiveProjects: action.param.showActiveProjects,
      };
      newState.viewConfigDict = { ...newState.viewConfigDict };
      newState.viewConfigDict[action.param.paneId] = action.param.viewConfig;
      return newState;
    }

    case CLEAR_FILTER: {
      if (action.paneId) {
        const newState = { ...state };
        newState.currentDict = { ...newState.currentDict };
        newState.currentDict[action.paneId] = {
          ...newState.currentDict[action.paneId],
          filters: {
            ...generateNewChunk(),
            ...action.delta,
          },
        };
        return newState;
      } else {
        const newState = initialState;
        return newState;
      }
    }

    case SET_PRESETS: {
      const newState = { ...state };
      newState.presetsDict[
        action.paneId as ChunkDestination.primary | ChunkDestination.secondary
      ] = {
        ...action.param,
      };
      return newState;
    }

    case SET_VIEW_AS: {
      const newState = { ...state };
      newState.currentDict = { ...newState.currentDict };
      newState.currentDict[action.paneId] = {
        ...newState.currentDict[action.paneId],
      };
      newState.currentDict[action.paneId].viewAs = action.viewAs;
      return newState;
    }

    case SET_GROUP_BY: {
      const newState = { ...state };
      newState.currentDict = { ...newState.currentDict };
      newState.currentDict[action.paneId] = {
        ...newState.currentDict[action.paneId],
        groupBy: action.groupBy,
      };
      return newState;
    }

    case SET_ORDER_BY: {
      const newState = { ...state };
      newState.currentDict = { ...newState.currentDict };
      newState.currentDict[action.paneId] = {
        ...newState.currentDict[action.paneId],
        orderBy: action.orderBy,
      };
      return newState;
    }

    case SET_SHOW_ACTIVE_PROJECTS: {
      const newState = { ...state };
      newState.currentDict = { ...newState.currentDict };
      newState.currentDict[action.paneId] = {
        ...newState.currentDict[action.paneId],
        showActiveProjects: action.showActiveProjects,
      };
      return newState;
    }

    case SET_CURRENT_FILTERS: {
      if (!action.paneId) return state;
      const newState = { ...state };
      newState.currentDict = { ...newState.currentDict };
      const currentFilters = action.currentFilters as Partial<ViewSettings>;
      newState.currentDict[action.paneId] = {
        ...newState.currentDict[action.paneId],
        ...currentFilters,
        filters: {
          ...newState.currentDict[action.paneId].filters,
          ...(currentFilters.filters ? currentFilters.filters : {}),
        },
      };

      return newState;
    }

    case RESET_VIEW: {
      const newState = { ...state };
      newState.currentDict[action.paneId] = {
        ...newState.presetsDict[action.paneId],
      };
      return newState;
    }

    default:
      return state;
  }
}

export function usePresets(
  paneId: ChunkDestination.primary | ChunkDestination.secondary
) {
  const dispatch = useDispatch();
  const presets = useSelector(
    (state: ClarityStore) => state.filter.presetsDict[paneId],
    shallowEqual
  );

  const setPresets = (param: {
    filters: IFilterState;
    groupBy?: GroupTypes;
    orderBy?: OrderTypes;
    viewAs?: ViewAsTypes;
  }) =>
    dispatch({
      type: SET_PRESETS,
      paneId,
      param,
    });

  return {
    presets,
    setPresets,
  };
}

export function useViewAs() {
  const dispatch = useDispatch();
  const paneId = usePaneId();
  const viewAs = useSelector(
    (state: ClarityStore) => state.filter.currentDict[paneId].viewAs,
    shallowEqual
  );

  const setViewAs = (viewAs: ViewAsTypes) => {
    dispatch({
      type: SET_VIEW_AS,
      paneId,
      viewAs,
    });
  };

  return {
    viewAs,
    setViewAs,
  };
}

export function usePaneId() {
  const paneId = useContext(PaneContext);
  return paneId;
}
