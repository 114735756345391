import React, { useMemo } from "react";
import { TopNavbarType } from "store/reducers/topNavReducer";
import { SectionWidthModes, useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import Breadcrumbs, { PayoutCrumbNavigator } from "./breadcrumbs/Breadcrumbs";
import { PageTitle } from "./PageTitle";

const BreadcrumbContainer: React.FC<{
  paneId: ChunkDestination;
  sectionWidthMode?: SectionWidthModes;
}> = ({ paneId }) => {
  const viewType = useShallowSelector((store) => store.topNav[paneId]?.navType);

  return useMemo(() => {
    if (!viewType) return <></>;

    switch (viewType) {
      case TopNavbarType.baseOverview:
        return <Breadcrumbs viewType={viewType} paneId={paneId} />;
      case TopNavbarType.payoutScreen:
        return (
          <Breadcrumbs viewType={viewType} paneId={paneId}>
            <PayoutCrumbNavigator paneId={paneId} />
            <PageTitle paneId={paneId} />
          </Breadcrumbs>
        );

      default:
        return (
          <Breadcrumbs viewType={viewType} paneId={paneId}>
            <PageTitle paneId={paneId} />
          </Breadcrumbs>
        );
    }
  }, [viewType, paneId]);
};

export default BreadcrumbContainer;
