import { ChunkDestination } from "./stateTypes";

export const navigateToUrl = (
  e: any,
  paneId: ChunkDestination,
  openInMain: () => void,
  openInSplit: () => void
) => {
  if (paneId === ChunkDestination.primary) {
    if (e.shiftKey) {
      return openInSplit();
    }
    return openInMain();
  } else {
    if (e.shiftKey) {
      return openInMain();
    }
    return openInSplit();
  }
};
