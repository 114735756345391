import { checkCaretPosition } from "editor/utils/caretUtils";
import { createContextInState } from "editor/utils/contextActions/primitiveContextActions";
import { VALUE_DOWN, VALUE_LEFT, VALUE_RIGHT, VALUE_UP } from "keycode-js";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SET_FOCUS } from "store/actions";
import { FocusType } from "store/reducers/blockReducer";
import { updateFocus } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, {
  $documentMode,
  $focusOn,
  ClarityStore,
} from "store/storeExporter";
import {
  moveCaretAtLineEnd,
  moveCaretToPreviousPosition,
} from "utilities/caretMovement";
import { LineType } from "utilities/lineUtilities";
import { TypeOfLineMovement } from "utilities/movementTypes";
import { ContainerTypes, DocumentModes } from "utilities/types";
import BlockContext, { BlockContextPass } from "./BlockContext";
import BlockWrapper from "./BlockWrapper";

const BlockContainers: React.FC<any> = (props) => {
  const context = useContext(BlockContextPass);
  return useMemo(() => {
    if (props.childIds) {
      if (props.isRoot) {
        return (
          <ContextWrapper context={context}>
            <BlockChildren
              parentSelected={props.parentSelected}
              parentListType={props.parentListType}
              mapIds={props.childIds}
              parentSetIsHovered={props.parentSetIsHovered}
              changeBlock={props.changeBlock}
            />
          </ContextWrapper>
        );
      }
      return (
        <BlockChildren
          parentSelected={props.parentSelected}
          parentListType={props.parentListType}
          mapIds={props.childIds}
          parentSetIsHovered={props.parentSetIsHovered}
          changeBlock={props.changeBlock}
        />
      );
    } else {
      return (
        <ContextWrapper context={context}>
          <ContextChildren {...props} />
        </ContextWrapper>
      );
    }
  }, [props.childIds, props.parentSelected, context, props.parentListType]);
};

const BlockChildren: React.FC<any> = (props) => {
  const context = useContext(BlockContextPass);
  return useMemo(() => {
    return (
      <>
        <div
          className="siblingGroupContainer"
          style={{ marginLeft: "-4px", lineHeight: 1.4, marginBottom: "2px" }}
        >
          {props.mapIds.map((blockId: string, index: number) => {
            return (
              <BlockWrapper
                key={blockId}
                isFirst={index === 0}
                parentSelected={props.parentSelected}
                changeBlock={props.changeBlock}
                blockId={blockId}
                context={context}
                parentListType={
                  props.parentListType ? props.parentListType : "noOutline"
                }
                parentSetIsHovered={props.parentSetIsHovered}
              />
            );
          })}
        </div>
      </>
    );
  }, [props.mapIds, props.parentSelected, context, props.parentListType]);
};

const ContextChildren: React.FC<any> = (props) => {
  const context = useContext(BlockContextPass);
  const startedContextPopulation = useRef(false);
  const mapIds: string[] = useSelector((state: ClarityStore) => {
    if (
      !state.blocks.contexts[context.id] &&
      context.container.type !== ContainerTypes.WORK_ACTIVITY
    ) {
      if (!startedContextPopulation.current) {
        startedContextPopulation.current = true;
        createContextInState(context);
      }
      return [];
    }
    return state.blocks.contexts[context.id]?.state?.rootBlocksIds ?? [];
  }, shallowEqual);

  useEffect(() => {
    if (props.setIsFinishedLoading) props.setIsFinishedLoading(true);
  }, []);

  return useMemo(() => {
    return (
      <>
        <div>
          {mapIds?.map((id, index) => {
            return (
              <React.Fragment key={id}>
                <BlockWrapper
                  key={id}
                  isFirst={
                    (index === 0 &&
                      context.container.type !== ContainerTypes.NOTE) ||
                    (index === 1 &&
                      context.container.type === ContainerTypes.NOTE)
                  }
                  changeBlock={props.changeBlock}
                  parentSelected={props.parentSelected}
                  blockId={id}
                  context={context}
                  parentListType={props.parentListType}
                  parentSetIsHovered={props.parentSetIsHovered}
                />
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  }, [mapIds, context, props.parentListType]);
};

const ContextWrapper: React.FC<any> = (props) => {
  const moveToAnotherLine = (
    id: string,
    nextRef: any,
    event: any,
    typeOfLineMovement: TypeOfLineMovement,
    caretPosition: number,
    documentMode: DocumentModes,
    nextBlockId: string
  ) => {
    const key = event.key;
    const selection = document.getSelection();
    caretPosition = checkCaretPosition(event.currentTarget);
    const isTable = checkIfNextFocusIsTable(nextBlockId, nextRef, key);
    if (isTable) return;
    let range;
    let textNode;
    let nextFocusBlock;
    let newId;
    if (
      selection &&
      documentMode === DocumentModes.INSERT &&
      selection.rangeCount > 0 &&
      !["ArrowRight", "ArrowLeft"].includes(key)
    ) {
      const rangeX = document.getSelection()?.getRangeAt(0);
      let coordinateX = rangeX?.getBoundingClientRect().x;
      nextFocusBlock = nextRef;
      if (!nextFocusBlock) return;
      newId = nextFocusBlock.getAttribute("data-block-id");
      if (nextFocusBlock) nextFocusBlock.scrollIntoView({ block: "nearest" });

      const coordinates = nextFocusBlock?.getBoundingClientRect();
      let yCoordinate = 0;
      if (coordinates) {
        if (key === "ArrowUp") {
          const computedStyles = window.getComputedStyle(nextFocusBlock);

          const paddingBottomString = computedStyles.paddingBottom ?? "0px";
          const paddingBottom: number =
            Number(
              paddingBottomString.substring(0, paddingBottomString.length - 2)
            ) ?? 0;
          if (nextFocusBlock.classList.contains("code")) {
            const editor = nextFocusBlock.querySelector(
              ".cm-content"
            ) as HTMLDivElement;
            const coordinates = editor?.getBoundingClientRect();
            coordinateX = coordinateX ?? 0;
            if (coordinateX < coordinates.x) coordinateX = coordinates.x + 10;
            yCoordinate = coordinates.bottom - 12;
          } else yCoordinate = coordinates.bottom - paddingBottom - 12;
        } else {
          const computedStyles = window.getComputedStyle(nextFocusBlock);

          const paddingTopString = computedStyles.paddingTop ?? "0px";
          const paddingTop: number =
            Number(
              paddingTopString.substring(0, paddingTopString.length - 2)
            ) ?? 0;

          if (nextFocusBlock.classList.contains("code")) {
            const editor = nextFocusBlock.querySelector(
              ".cm-content"
            ) as HTMLDivElement;
            const coordinates = editor?.getBoundingClientRect();
            coordinateX = coordinateX ?? 0;
            if (coordinateX < coordinates.x) coordinateX = coordinates.x + 10;
            yCoordinate = coordinates.top + 12;
          } else yCoordinate = coordinates.top + paddingTop + 12;
        }
      }
      const nextY = yCoordinate ? yCoordinate : 0;
      let offset;
      if ((document as unknown as any).caretPositionFromPoint) {
        range = (document as unknown as any).caretPositionFromPoint(
          coordinateX ? coordinateX : 0,
          nextY
        );
        if (range) {
          textNode = range.offsetNode;
          offset = range.offset;
          const newRange = new Range();
          selection?.removeAllRanges();
          newRange.setStart(textNode, offset);
          selection?.addRange(newRange);
        } else nextFocusBlock?.focus();
        caretPosition = checkCaretPosition(nextFocusBlock);
      } else if (document.caretRangeFromPoint) {
        range = document.caretRangeFromPoint(
          coordinateX ? coordinateX : 0,
          nextY
        );
        if (range) {
          textNode = range.startContainer;
          offset = range.startOffset;
          const newRange = new Range();
          selection?.removeAllRanges();
          newRange.setStart(textNode, offset);
          selection?.addRange(newRange);
          caretPosition = checkCaretPosition(nextFocusBlock);
        } else nextFocusBlock?.focus();
      }
    }

    if (!range || !textNode || !nextFocusBlock?.contains(textNode)) {
      if (nextRef) {
        const el = nextRef;
        newId = el.getAttribute("data-block-id");
        if (typeOfLineMovement === TypeOfLineMovement.endOfLine) {
          el.focus();
          moveCaretAtLineEnd(el, 0);
          caretPosition = checkCaretPosition(el);
        }

        if (typeOfLineMovement === TypeOfLineMovement.startOfLine) {
          caretPosition = 0;
          el.focus();
          moveCaretToPreviousPosition(el, caretPosition);
        }

        if (typeOfLineMovement === TypeOfLineMovement.previousCursorState) {
          moveCaretToPreviousPosition(el, caretPosition);
          el.focus();
        }
      }
    }

    if (newId) {
      const newState = store.getState().blocks;
      const newFocus = updateFocus(
        { ...newState },
        {
          focusBlockId: newId,
          caretPosition,
          type: FocusType.prevPosition,
          refocusToggle: false,
        }
      );
      store.dispatch({
        type: SET_FOCUS,
        param: {
          newFocus,
        },
      });
    }
  };

  const callbackMoveToAnotherBlock = useCallback(
    (
      id: string,
      nextRef: any,
      event: any,
      typeOfLineMovement: TypeOfLineMovement,
      caretPosition: number,
      documentMode: DocumentModes,
      nextBlockId: string
    ) => {
      moveToAnotherLine(
        id,
        nextRef,
        event,
        typeOfLineMovement,
        caretPosition,
        documentMode,
        nextBlockId
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <BlockContext containerRef={props.context.ref} context={props.context}>
        {React.Children.map(props.children, (child) => {
          return React.cloneElement(child, {
            ...props,
            changeBlock: callbackMoveToAnotherBlock,
          });
        })}
      </BlockContext>
    </>
  );
};

const checkIfNextFocusIsTable = (id: string, nextRef: any, key: string) => {
  let searchId;
  if (!id) return false;

  if (id && !nextRef) {
    const block = store.getState().blocks.dict[id];
    if (block.parentId) {
      const parentBlock = store.getState().blocks.dict[block.parentId];
      if (parentBlock.lineType === LineType.table) searchId = block.parentId;
    }
  } else searchId = id;

  if (!searchId) return false;

  const block = store.getState().blocks.dict[searchId];
  if (block) {
    if (block.lineType === LineType.table) {
      if ($documentMode.value === DocumentModes.BLOCK) {
        const newState = store.getState().blocks;
        const newFocus = updateFocus(
          { ...newState },
          {
            focusBlockId: block.id,
            caretPosition: 0,
            type: FocusType.prevPosition,
            refocusToggle: false,
          }
        );
        store.dispatch({
          type: SET_FOCUS,
          param: {
            newFocus,
          },
        });
        return true;
      }
      if (key === VALUE_UP || key === VALUE_LEFT) {
        const rows = block.children;
        const columns = block.value[0].tableBlockColumnData?.order ?? [];

        $focusOn.next({
          ...$focusOn.value,
          caretPosition: 0,
          type: FocusType.atBlockEnd,
          focusBlockId: rows[rows.length - 1],
          focusTableId: searchId,
          // focusNodeId: columns[columns.length - 1],
          focusNodeId: columns[0],
        });
      }
      if (key === VALUE_DOWN || key === VALUE_RIGHT) {
        const rows = block.children;
        const columns = block.value[0].tableBlockColumnData?.order ?? [];

        $focusOn.next({
          ...$focusOn.value,
          caretPosition: 0,
          type: FocusType.prevPosition,
          focusBlockId: rows[0],
          focusTableId: searchId,
          focusNodeId: columns[0],
        });
      }
      return true;
    }
  }
  return false;
};

export default BlockContainers;
