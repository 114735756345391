import { ActionTabSet } from "components/TopNavBar";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import WorkActivityItems from "screens/base/main/detailView/documentContainer/workDetails/WorkActivityItems";
import { usePaneId } from "store/reducers/filterReducer";
import { ActionBtnKind } from "store/reducers/topNavReducer";
import { ContainerTypes, IProjectObj } from "utilities/types";
import MentionsSection from "./MentionsSection";
import styles from "./workItemFooter/workItemFooter.module.scss";

interface WorkFooterProps {
  workItem: IProjectObj;
}

enum WorkFooterMode {
  activity = "Activity",
  mentions = "Mentions",
}

const WorkItemFooter: React.FC<WorkFooterProps> = (props) => {
  const [mode, setmode] = useState<WorkFooterMode>(WorkFooterMode.activity);
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback(() => ref.current, []);
  const paneId = usePaneId();

  const tabs = useMemo(() => {
    return [WorkFooterMode.activity, WorkFooterMode.mentions].map((inmode) => {
      return {
        key: inmode,
        onClick: () => setmode(inmode),
        contents: () => <span>{inmode}</span>,
        active: () => inmode === mode,
      };
    });
  }, [mode]);

  useEffect(() => {
    ref.current?.focus({ preventScroll: true });
  }, [mode]);

  return (
    <>
      <div className={`ignoreBubbleUp ${styles.details}`}>
        <div
          tabIndex={0}
          onClick={(e) => e.stopPropagation()}
          className={styles.detailsHeader}
        >
          <ActionTabSet
            key="tab-set"
            kind={ActionBtnKind.TabSet}
            tabs={tabs}
            noFirstDivider={true}
          />
        </div>
        <div ref={setRef}>
          {mode === WorkFooterMode.activity ? (
            <WorkActivityItems
              workItem={props.workItem}
              paneId={paneId}
              isInModal={false}
            />
          ) : (
            <MentionsSection
              paneId={paneId}
              containerId={props.workItem.id}
              containerType={ContainerTypes.PROJECT}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WorkItemFooter;
