import { BlockPropsWithRef } from "editor/blockContainer/BlockSplitter";
import { $focusOn } from "store/storeExporter";
import { updateBlockText } from "../blockActions";

export const checkIfBlockHasATag = (element: HTMLElement | Element) => {
  const tags = element?.querySelectorAll(".mention");
  return tags && tags.length > 0;
};

export const handleBlockNativeStylingSave = (
  event: any,
  type: string,
  props: BlockPropsWithRef
) => {
  const newEvent = handleBlockNativeStylingInnerAction(event, type);
  if (newEvent) updateBlockText(props.blockData, newEvent, props.context);
};

export const handleBlockNativeStylingInnerAction = (
  event: any,
  type: string
) => {
  event.preventDefault();
  event.stopPropagation();
  let newEvent;
  const selection: any = document.getSelection();
  if (selection) {
    document.execCommand(type, false);
    newEvent = {
      currentTarget: event.currentTarget,
    };
  }
  return newEvent;
};

export const removeFromPosition = (positionToDeleteFrom: number) => {
  const selection = document.getSelection();
  if (selection) {
    const range = selection.getRangeAt(0);
    const shadowRange = range.cloneRange();
    shadowRange.setStart(range.startContainer, 0);
    shadowRange.setEnd(range.endContainer, positionToDeleteFrom);
    shadowRange.deleteContents();
  }
};

export const checkBlockEmptyChars = () => {
  const selection: Selection | null = document.getSelection();
  if (selection) {
    if (selection.focusNode) {
      if (
        selection.focusNode.nodeValue &&
        selection.focusNode.nodeValue.length > 1
      ) {
        const matches = selection.focusNode.nodeValue.match(/\uFEFF/g);
        const nrOfEmpty = matches ? matches.length : 0;
        if (nrOfEmpty > 0) {
          const range: Range = new Range();
          range.selectNode(selection.focusNode);
          const newNode = selection.focusNode.cloneNode(true);
          if (newNode)
            selection.focusNode.nodeValue =
              selection.focusNode.nodeValue?.replace(/\uFEFF/g, ``);
          newNode.nodeValue = selection.focusNode.nodeValue;
          range.deleteContents();

          range.insertNode(newNode);
          range.setStartAfter(newNode);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  }
};

export const replacePlacehoders = (element: HTMLElement) => {
  const classesToCheck: string[] = [
    ".mention-placeholder",
    ".slash-placeholder",
    ".link-entity-placeholder",
    ".user-mention-placeholder",
    ".snippet-placeholder",
  ];
  if (!element) return;
  classesToCheck.forEach((classToCheck) => {
    const placeholders = element.querySelectorAll(classToCheck);
    for (const placeholder of Array.from(placeholders)) {
      const prevTextSize = placeholder.textContent?.length;
      const newEl = document.createDocumentFragment();
      placeholder.childNodes.forEach((childNode) => {
        const clone = childNode.cloneNode(true);
        newEl.appendChild(clone);
      });
      const newTextSize = newEl.textContent?.length;
      if (prevTextSize !== newTextSize && prevTextSize && newTextSize) {
        $focusOn.next({
          ...$focusOn.value,
          caretEndPosition: $focusOn.value.caretEndPosition
            ? $focusOn.value.caretEndPosition - (prevTextSize - newTextSize)
            : 0,
        });
      }
      placeholder.replaceWith(newEl);
    }
  });
};

export const removePlaceholderPlaceCaret = (element: HTMLElement) => {
  const classesToCheck: string[] = [
    ".mention-placeholder",
    ".slash-placeholder",
    ".link-entity-placeholder",
    ".user-mention-placeholder",
    ".snippet-placeholder",
  ];
  if (!element) return;
  classesToCheck.forEach((classToCheck) => {
    const placeholders = element.querySelectorAll(classToCheck);
    for (const placeholder of Array.from(placeholders)) {
      if (placeholder) {
        const selection = document.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const newRange = range.cloneRange();
          newRange.setStartBefore(placeholder);
          newRange.setEndBefore(placeholder);
          placeholder.remove();
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
    }
  });
};

export const removeEmptyNodes = (
  element: DocumentFragment | HTMLDivElement
) => {
  const children = element.childNodes;
  const reverse: ChildNode[] = [];
  children.forEach((child) => reverse.push(child));
  reverse.forEach((child) => {
    if (child.textContent?.length === 0) child.remove();
  });
};

export const replaceHashtagPlaceholder = (element: HTMLElement) => {
  const placeholders = element.querySelectorAll(".mention-placeholder");
  for (const placeholder of Array.from(placeholders)) {
    placeholder.replaceWith(document.createTextNode("#"));
  }
};
