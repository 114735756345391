import { appVersion, socket } from "App";
import { axiosInstance } from "index";
import { READ_ALL, SET_CHANGELOGS } from "store/actions";
import store from "store/storeExporter";

class ChangelogApi {
  async getUserChangelogs() {
    const userId = store.getState().user?.id;
    if (userId)
      axiosInstance
        .post("/api/app-version", {
          version: appVersion,
          clientId: socket.id,
        })
        .then((res) => {
          store.dispatch({
            type: SET_CHANGELOGS,
            changelogs: res.data,
          });
        });
  }

  async readChangelogs() {
    const dict = store.getState().changelogs.changelogDict;
    const changelogIds = store.getState().changelogs.ids;

    const unreadChangelogs = changelogIds.filter((id) => {
      const changelog = dict[id];
      return !changelog.read;
    });

    axiosInstance
      .post("/api/app-version/read", {
        ids: unreadChangelogs,
      })
      .then(() => {
        store.dispatch({
          type: READ_ALL,
        });
      });
  }
}

export const changelogApi = new ChangelogApi();
