import React, {
  useState,
  useRef,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import styles from "./fabBtn/fabBtn.module.scss";
import { useOptionalClassName } from "utilities/hooks";
import { useOnClickOutside } from "editor/utils/customHooks";
import Button, { ButtonTypes } from "components/Button";

export interface IFabBtnProps {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  icon: JSX.Element;
  children: JSX.Element[] | JSX.Element;
  btnClass?: string;
  containerClass?: string;
  menuClass?: string;
  shadow?: boolean;
  menuYDir?: "up" | "down";
  menuXDir?: "left" | "right";
  hideWhenOpen?: boolean;
  buttonType?: ButtonTypes;
  label?: string;
  disabled?: boolean;
}

export default function FabBtn({
  children,
  isOpen,
  open,
  close,
  icon,
  btnClass = "",
  containerClass = "",
  menuClass = "",
  shadow,
  menuYDir,
  menuXDir,
  hideWhenOpen = true,
  buttonType = ButtonTypes.DEFAULT,
  label = "",
  disabled = false,
}: IFabBtnProps) {
  const { containerClassName } = useFabBtnState({
    shadow,
    containerClass,
    btnClass,
  });

  return (
    <div className={containerClassName}>
      {hideWhenOpen && isOpen ? (
        <></>
      ) : (
        <Button disabled={disabled} buttonType={buttonType} onClick={open}>
          {label} {icon}
        </Button>
      )}
      {isOpen && (
        <Menu
          close={close}
          className={menuClass}
          yDir={menuYDir}
          xDir={menuXDir}
        >
          {children}
        </Menu>
      )}
    </div>
  );
}

function useFabBtnState({
  shadow,
  containerClass,
  btnClass,
}: {
  shadow?: boolean;
  containerClass: string;
  btnClass: string;
}) {
  const containerClassName = useOptionalClassName({
    baseStyle: styles.container,
    pairs: [
      {
        extraStyle: containerClass,
        withExtra: Boolean(containerClass),
      },
    ],
  });

  const btnClassName = useOptionalClassName({
    baseStyle: styles.fabBtn,
    pairs: [
      {
        extraStyle: styles.fabBtnShadow,
        withExtra: shadow,
      },
      {
        extraStyle: btnClass,
        withExtra: Boolean(btnClass),
      },
    ],
  });

  return {
    containerClassName,
    btnClassName,
  };
}

interface IMenuProps {
  close: (e?: any) => void;
  children?: JSX.Element[] | JSX.Element;
  className?: string;
  yDir?: "up" | "down";
  xDir?: "left" | "right";
  noFocus?: boolean;
  slim?: boolean;
  skipFirst?: boolean;
  allowClickOn?: string[];
  clickRef?: React.RefObject<HTMLDivElement>;
}

export function Menu({
  close,
  children = [],
  yDir = "up",
  xDir = "right",
  className = "",
  noFocus = false,
  slim = false,
  skipFirst = false,
  allowClickOn = [],
  clickRef,
}: IMenuProps) {
  const { menuRef, classNames, focusProps } = useMenuState({
    className,
    yDir,
    xDir,
    noFocus,
    close,
    slim,
  });

  useOnClickOutside(clickRef ?? menuRef, close, {
    skipFirst,
    allowClickOn,
  });

  return (
    <div ref={menuRef as any} className={classNames} {...focusProps}>
      {children}
    </div>
  );
}

function useMenuState({
  className,
  yDir,
  xDir,
  noFocus,
  close,
  slim,
}: Pick<IMenuProps, "close"> &
  Required<
    Pick<IMenuProps, "className" | "xDir" | "yDir" | "noFocus" | "slim">
  >) {
  const menuRef = useMenuRef();

  const focusProps = noFocus ? {} : { tabIndex: 1 };

  const classNames = useOptionalClassName({
    baseStyle: styles.menu,
    pairs: [
      ...(!className
        ? []
        : [
            {
              extraStyle: className,
              withExtra: true,
            },
          ]),
      {
        extraStyle: yDir === "up" ? styles.menuUp : styles.menuDown,
        withExtra: true,
      },
      {
        extraStyle: xDir === "left" ? styles.menuLeft : styles.menuRight,
        withExtra: true,
      },
      {
        extraStyle: styles.menuSlim,
        withExtra: slim,
      },
    ],
  });

  return {
    menuRef,
    classNames,
    focusProps,
  };
}

interface IActiveMenuItemContext {
  activeItemIndex: number;
  setActiveItemIndex: React.Dispatch<React.SetStateAction<number>>;
  itemsCollection: any[];
  setItemsCollection: React.Dispatch<React.SetStateAction<any[]>>;
}

export const ActiveMenuItemContext = createContext<IActiveMenuItemContext>({
  activeItemIndex: 0,
  setActiveItemIndex: () => {},
  itemsCollection: [],
  setItemsCollection: () => {},
});

export function useActiveMenuItemContextValue(): IActiveMenuItemContext {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [itemsCollection, setItemsCollection] = useState<any[]>([]);

  const contextHandlers = useMemo(() => {
    return {
      activeItemIndex,
      setActiveItemIndex,
      itemsCollection,
      setItemsCollection,
    };
  }, [activeItemIndex, itemsCollection]);

  return contextHandlers;
}

export function useActiveMenuItemContext() {
  return useContext(ActiveMenuItemContext);
}

interface IMenuItemProps {
  title: string;
  description?: string;
  icon?: JSX.Element;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isActive?: boolean;
  disabled?: boolean;
  badge?: boolean;
  slim?: boolean;
}

export const MenuItem: React.FC<IMenuItemProps> = ({
  title,
  description = "",
  disabled,
  icon,
  onClick,
  isActive,
  badge,
  slim = false,
  children,
}) => {
  const { menuItemRef, menuItemClassName } = useMenuItemState({
    isActive,
    disabled,
  });

  return (
    <div
      className={`${slim ? styles.slim : ""} ${menuItemClassName}`}
      onClick={(e) => {
        if (disabled) return;
        onClick(e);
      }}
      ref={menuItemRef}
    >
      {icon && <div className={styles.menuItemPrefix}>{icon}</div>}
      <div className={styles.menuItemBody}>
        <p className={styles.menuItemTitle}>{title}</p>
        {description && (
          <p className={styles.menuItemDescription}>{description}</p>
        )}
      </div>
      {children && <>{children}</>}
      {badge ? <div className={styles.unreadBadge}></div> : null}
    </div>
  );
};

function useMenuItemState({
  isActive = false,
  disabled = false,
}: Pick<IMenuItemProps, "isActive"> & Pick<IMenuItemProps, "disabled">) {
  const menuItemRef = useScrollIntoViewOnActive({ isActive });

  const menuItemClassName = useOptionalClassName({
    baseStyle: styles.menuItem,
    pairs: [
      {
        extraStyle: styles.menuItemActive,
        withExtra: isActive,
      },
      {
        extraStyle: styles.disabled + " no-permissions",
        withExtra: disabled,
      },
    ],
  });

  return {
    menuItemRef,
    menuItemClassName,
  };
}

export function useScrollIntoViewOnActive({ isActive }: { isActive: boolean }) {
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive) {
      itemRef.current?.scrollIntoView({ block: "nearest", inline: "start" });
    }
  }, [isActive]);

  return itemRef;
}

export function useMenuControllerState() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const openMenu = () => setMenuIsOpen(true);
  const closeMenu = () => setMenuIsOpen(false);

  return {
    menuIsOpen,
    setMenuIsOpen,
    openMenu,
    closeMenu,
  };
}

function useMenuRef() {
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.focus();
    }
  }, []);
  return menuRef;
}
