import React from "react";
import { connect } from "react-redux";
import * as actionTypes from "../store/actions";
import { Modal } from "antd";
import { ILineValue, Line } from "../utilities/lineUtilities";
import Button, { ButtonTypes } from "components/Button";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $focusOn } from "store/storeExporter";
import { updateBlockText } from "editor/utils/blockActions";
import { IBlockContext } from "utilities/types";
import { getHtml } from "editor/utils/blockValueHelpers";
import { axiosInstance } from "index";

interface IModalCitationSyncProps {
  modalCitationsSync: {
    display: boolean;
    blockId: string | null;
    blockData: Line | null;
    citationData: any;
    destination: string | null;
  };
  closeModal: () => null;
  changeBlockValue: (
    newValue: ILineValue[],
    blockId: string,
    destination: string
  ) => null;
}

const ModalCitationSyncCompare: React.FC<IModalCitationSyncProps> = (props) => {
  if (!props.modalCitationsSync || !props.modalCitationsSync.citationData)
    return <></>;
  let citationInHtml: any = null;
  if ($focusOn.value.focusBlockId === props.modalCitationsSync.blockId) {
    const citationsInHtml =
      $focusOn.value.focusBlockRef?.current?.querySelectorAll("citation");
    citationsInHtml?.forEach((el) => {
      if (
        el.getAttribute("data-citation-id") ===
        props.modalCitationsSync.citationData.citationId
      ) {
        citationInHtml = el;
      }
    });
  }

  let citationLatestText = "";

  if (props.modalCitationsSync.citationData.sourceBlockId) {
    const sourceId = props.modalCitationsSync.citationData.sourceBlockId;
    const block = getBlockById(store.getState().blocks.dict, sourceId);
    if (block && block.id) {
      citationLatestText = getHtml(block.value);
    }
  }

  if (citationInHtml)
    return (
      <Modal
        visible={props.modalCitationsSync.display}
        onCancel={props.closeModal}
        footer={false}
      >
        <div onMouseDown={(e) => e.preventDefault()}>
          <h4 style={{ marginBottom: "34px" }}>Compare to latest version</h4>
          <div>
            <div
              className="caption bold"
              style={{ paddingLeft: "8px", marginBottom: "6px" }}
            >
              This version
            </div>
            <div
              className="version-box body"
              style={{
                background: "var(--surface-3)",
                border: "1px solid #EBEBED",
                borderRadius: "4px",
                padding: "12px 16px",
                marginBottom: "34px",
              }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: citationInHtml.innerHTML }}
              ></span>
            </div>
          </div>
          <div>
            <div
              className="caption bold"
              style={{ paddingLeft: "8px", marginBottom: "6px" }}
            >
              Latest version
            </div>
            <div
              className="version-box body"
              style={{
                background: "var(--surface-3)",
                border: "1px solid #EBEBED",
                borderRadius: "4px",
                padding: "12px 16px",
                marginBottom: "52px",
              }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: citationLatestText }}
              ></span>
            </div>
          </div>
          <div
            className="custom-buttons"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              style={{ marginRight: "16px" }}
              onClick={(e: any) => {
                e.preventDefault();
                props.closeModal();
              }}
            >
              Keep this version
            </Button>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              onClick={(e: any) => {
                citationInHtml.innerHTML = citationLatestText;
                if (props.modalCitationsSync.blockData) {
                  if (props.modalCitationsSync.blockId) {
                    const blockId = props.modalCitationsSync.blockId;
                    if (blockId === $focusOn.value.focusBlockId) {
                      const blockRef = $focusOn.value.focusBlockRef?.current;
                      if (blockRef) {
                        const newState = store.getState().blocks;
                        const block = getBlockById(
                          newState.dict,
                          props.modalCitationsSync.blockId
                        );
                        const pseudoevent = {
                          target: blockRef,
                          currentTarget: blockRef,
                        };
                        const pseudoContext = {
                          id: block.containerType + block.containerId,
                          canEdit: true,
                          canComment: true,
                          autosave: true,
                        } as IBlockContext;

                        updateBlockText(
                          block,
                          pseudoevent,
                          pseudoContext,
                          true
                        );
                      }
                    }
                  }
                }
                axiosInstance.patch("/api/citation/update", {
                  id: props.modalCitationsSync.citationData.citationId,
                  updates: {
                    dateAccessed: new Date(),
                    dateModified: new Date(),
                    synchronize: true,
                  },
                });
                e.preventDefault();
                props.closeModal();
              }}
            >
              Sync with latest
            </Button>
          </div>
        </div>
      </Modal>
    );
  return <></>;
};

const mapStateToProps = (state: any) => ({
  modalCitationsSync: state.client.modalCitationsSync,
});

const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () =>
    dispatch({
      type: actionTypes.SET_MODAL_CITATION_SYNC,
      param: { display: false },
    }),
  changeBlockValue: (
    newValue: ILineValue[],
    blockId: string,
    destination: string
  ) =>
    dispatch({
      type: actionTypes.UPDATE_SINLGE_LINE_TEXT,
      newValue,
      lineId: blockId,
      destination,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCitationSyncCompare);
