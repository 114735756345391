import {
  PrimitiveScopes,
  ContainerTypes,
  ContainerTypeToPrimitive,
} from "utilities/types";
import { useMenuControllerState } from "components/FabBtn";
import { createContext, useContext, useEffect, useState } from "react";
import { useBase } from "store/reducers/workspaceReducer";
import { useSharingAndPermsModalState } from "store/reducers/clientReducer";
import { axiosInstance } from "index";
import { Permission, permToPermissionType } from "./util";
import { useOptionalClassName } from "utilities/hooks";
import styles from "components/sharingAndPermsModal/sharingAndPermsModal.module.scss";

export function usePermissionsDropdownState({
  initialValue,
  readOnly,
  onChange,
  permissions,
  userId,
  roleAssignmentId,
  initialPermOptions,
  anonymousGuests,
  permissionContainerType = PrimitiveScopes.WORK,
}: {
  initialValue?: Permission;
  readOnly?: boolean;
  onChange?: (perm: Permission) => void;
  permissions?: any;
  userId?: string;
  roleAssignmentId?: string;
  initialPermOptions?: {
    edit: boolean;
    suggest: boolean;
    view: boolean;
    priv: boolean;
    remove?: boolean;
  };
  anonymousGuests?: boolean;
  permissionContainerType?: PrimitiveScopes;
}) {
  const permOptions = initialPermOptions
    ? getPermOptions(initialPermOptions)
    : getPermOptions();
  const { menuIsOpen, openMenu, closeMenu } = useMenuControllerState();
  const [permSelected, setPermSelected] = useState<Permission>(
    initialValue ?? "edit"
  );
  const { base } = useBase();

  const { containerId, containerType, gateLevel } =
    useSharingAndPermsModalState();

  const primitiveType: any = ContainerTypeToPrimitive[containerType];

  useEffect(() => {
    if (gateLevel && !anonymousGuests) {
      setPermSelected(gateLevel);
      return;
    }
    if (permissions?.permissions[primitiveType]?.edit?.includes(containerId)) {
      setPermSelected("edit");
    } else if (
      permissions?.permissions[primitiveType]?.suggest?.includes(containerId)
    ) {
      setPermSelected("suggest");
    } else if (
      permissions?.permissions[primitiveType]?.read?.includes(containerId)
    ) {
      setPermSelected("read");
    } else {
      if (initialValue) {
        setPermSelected(initialValue);
      }
    }
  }, [permissions, gateLevel]);

  const permToDisplayText: Record<Permission, string> = {
    edit: "Editor",
    suggest: "Commenter",

    read: "Viewer",
    locked: "Cannot view",
  };

  const getPrimitiveFromContainerType = () => {
    if (containerType === ContainerTypes.DOCUMENT) return PrimitiveScopes.PAGES;
    if (containerType === ContainerTypes.NOTE) return PrimitiveScopes.NOTES;
    return PrimitiveScopes.WORK;
  };

  const getPermUpdater = (perm: Permission) => () => {
    if (!readOnly) {
      if (perm !== permSelected) {
        if ((perm as any) !== "remove") setPermSelected(perm);
        if (!anonymousGuests) {
          axiosInstance.post("/api/role-assignment/role", {
            baseId: base.id,
            users: [userId],
            containerId,
            containerType: containerType,
            deltas: [
              {
                type: "change",
                value: containerId,
                primitive: getPrimitiveFromContainerType(),
                permissionType: permToPermissionType[permSelected],
                newPermissionType: permToPermissionType[perm],
                userId: userId,
                existingUser: true,
                roleAssignmentId,
              },
            ],
          });
        }
        if (onChange) {
          onChange(perm);
        }
      }
    }
  };

  const triggerClassName = useOptionalClassName({
    baseStyle: styles.permissionsDropdownTrigger,
    pairs: [
      {
        extraStyle: styles.permissionsDropdownTriggerActive,
        withExtra: menuIsOpen,
      },
    ],
  });
  const { editable } = usePermissionDeltaContext();

  return {
    triggerClassName,
    menuIsOpen,
    openMenu: readOnly ? () => {} : openMenu,
    closeMenu,
    getPermUpdater,
    permSelected,
    permToDisplayText,
    ...permOptions,
    editable,
  };
}

function getPermOptions(
  {
    edit,
    suggest,
    view,
    priv,
    remove,
  }: {
    edit: boolean;
    suggest: boolean;
    view: boolean;
    priv: boolean;
    remove?: boolean;
  } = { edit: true, suggest: true, view: false, priv: false, remove: false }
) {
  return {
    editEnabled: edit,
    suggestEnabled: suggest,
    viewEnabled: view,
    privateEnabled: priv,
    removeEnabled: remove,
  };
}

export function usePermissionDeltaContext() {
  return useContext(SavePermissionDeltaContext);
}

interface ISavePermissionDeltaContext {
  deltas: any[];
  setDeltas: React.Dispatch<React.SetStateAction<any[]>>;
  editable: boolean;
}

export const SavePermissionDeltaContext =
  createContext<ISavePermissionDeltaContext>({
    deltas: [],
    setDeltas: () => {},
    editable: false,
  });
