import { DRAG_TOGGLE } from "../actions";

const initialState = {
  dragStart: false,
  notInRoot: false,
  disableRootDropZones: false,
};

export default function containerReducer(state = initialState, action: any) {
  switch (action.type) {
    case DRAG_TOGGLE: {
      const newState = { ...state };
      newState.dragStart = action.dragState;
      if (newState.dragStart) {
        newState.notInRoot = action.notInRoot ? action.notInRoot : false;
        newState.disableRootDropZones = !action.notRootProject ? true : false;
      } else newState.notInRoot = false;
      return newState;
    }
    default:
      return state;
  }
}
