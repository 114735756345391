import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "clarity-ui/Modal";
import { useBase } from "store/reducers/workspaceReducer";
import { useUser } from "store/reducers/userReducer";
import {
  useDiscordIntegrationModalOpener,
  useDiscordIntegrationModalState,
} from "store/reducers/clientReducer";
import Button, { ButtonTypes } from "components/Button";
import { groupApi } from "clientApi/groupsApi";
import ClaritySelect from "components/ClaritySelect";
import { Abilities, IGroup, WorkTypes } from "utilities/types";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import workApi from "clientApi/workApi";
import { ReloadOutlined } from "@ant-design/icons";
import styles from "./discordConnection/discordConnection.module.scss";
import notificationsApi from "clientApi/notificationsApi";
import { useAbilityChecker } from "editor/utils/customHooks";

export enum DiscordIntegrationSteps {
  BaseConnection = "base",
  UserConnection = "user",
  GroupConnection = "group",
  ProjectConnection = "project",
}

const groupSteps = [
  DiscordIntegrationSteps.BaseConnection,
  DiscordIntegrationSteps.UserConnection,
  DiscordIntegrationSteps.GroupConnection,
];

const projectSteps = [
  DiscordIntegrationSteps.BaseConnection,
  DiscordIntegrationSteps.UserConnection,
  DiscordIntegrationSteps.GroupConnection,
  DiscordIntegrationSteps.ProjectConnection,
];

const steps: any = {
  [DiscordIntegrationSteps.GroupConnection]: groupSteps,
  [DiscordIntegrationSteps.ProjectConnection]: projectSteps,
};
const DiscordConnection: React.FC = () => {
  const discordIntegrationOpener = useDiscordIntegrationModalOpener(false);

  return (
    <Modal
      size="medium"
      hideModal={() => discordIntegrationOpener()}
      zIndex={8000}
    >
      <GeneralView />
    </Modal>
  );
};

const GeneralView: React.FC = () => {
  const [activeMode, setActiveMode] = useState<DiscordIntegrationSteps>();
  const discordConnection = useDiscordIntegrationModalState();
  const discordIntegrationOpener = useDiscordIntegrationModalOpener(false);
  const [disabled, setDisabled] = useState(false);

  const base = useBase();
  const user = useUser();
  const group = useShallowSelector((state) =>
    discordConnection.groupId
      ? state.groups.dict[discordConnection.groupId]
      : undefined
  );

  const canManageBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });

  const handleStartThread = (step: DiscordIntegrationSteps) => {
    const project = store.getState().work.dict[discordConnection.workId];
    setDisabled(true);
    if (project.workType === WorkTypes.TASK) {
      workApi
        .connectToDiscord(project.id)
        .then(() => {
          setDisabled(false);

          discordIntegrationOpener();
        })
        .catch((e) => {
          notificationsApi.displayError({
            title: "Oops something went wrong",
            body: "Sorry, we could not create the thread. Please try again later",
          });
        });
    } else {
      setActiveMode(step);
    }
  };

  useEffect(() => {
    const stepsOrder = steps[discordConnection.intendedStep];
    for (const step of stepsOrder) {
      if (step === DiscordIntegrationSteps.BaseConnection) {
        if (!base.base.integrations?.discord?.activated) {
          setActiveMode(DiscordIntegrationSteps.BaseConnection);
          break;
        }
      }
      if (step === DiscordIntegrationSteps.UserConnection) {
        if (!user.discordUsername) {
          setActiveMode(DiscordIntegrationSteps.UserConnection);
          break;
        }
      }

      if (step === DiscordIntegrationSteps.GroupConnection) {
        if (discordConnection.groupId && !group?.channelName) {
          setActiveMode(DiscordIntegrationSteps.GroupConnection);
          break;
        }
      }

      if (step === DiscordIntegrationSteps.ProjectConnection) {
        handleStartThread(step);
      }

      if (discordConnection.intendedStep === step) {
        break;
      }
    }
  }, [
    base.base.integrations?.discord,
    user.discordUsername,
    group?.channelName,
    discordConnection,
  ]);
  const containerRef = useRef(null);

  return (
    <div ref={containerRef}>
      {activeMode === DiscordIntegrationSteps.BaseConnection && (
        <ConnectBaseToDiscord
          baseId={base.base.id}
          disabled={!canManageBaseSettings}
        />
      )}
      {activeMode === DiscordIntegrationSteps.UserConnection && (
        <UserConnection />
      )}
      {activeMode === DiscordIntegrationSteps.GroupConnection && (
        <GroupConnection
          containerRef={containerRef}
          disabled={disabled || !canManageBaseSettings}
        />
      )}
      {activeMode === DiscordIntegrationSteps.ProjectConnection && (
        <ProjectConnection
          workId={discordConnection.workId}
          containerRef={containerRef}
          disabled={!canManageBaseSettings}
        />
      )}
    </div>
  );
};

const ConnectBaseToDiscord: React.FC<{
  baseId: string;
  disabled: boolean;
}> = ({ baseId, disabled }) => {
  return (
    <div className={styles.container}>
      <div className={styles.informationContainer}>
        <h4>Connect to Discord</h4>
        <span className="body2 secondary">
          Get project & task updates directly in Discord. Clarity will create
          threads, invite relevant contributors, and clean-up threads
          automatically. Keeping your server (and conversations) organized.
        </span>
      </div>
      <Button
        buttonType={ButtonTypes.PRIMARY}
        style={{
          width: "140px",
        }}
        disabled={disabled}
        onClick={() => {
          window.open(
            `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_DISCORD_APP_CLIENT_ID}&permissions=${process.env.REACT_APP_DISCORD_PERMISSIONS}&scope=applications.commands%20bot&state=${baseId}&redirect_uri=${process.env.REACT_APP_DISCORD_CALLBACK_URL}`,
            "_blank"
          );
        }}
      >
        Connect to Discord
      </Button>
    </div>
  );
};

const UserConnection: React.FC<{}> = () => {
  const user = useUser();

  return (
    <div className={styles.container}>
      <div className={styles.informationContainer}>
        <h4>Connect to your Discord account</h4>
        <span className="body2 secondary">
          In order to use the Discord integration, Clarity needs to know who you
          are in Discord. Continue to link your Clarity account with your
          Discord account.
        </span>
      </div>
      {!user.discordUsername && (
        <Button
          isLoading={false}
          disabled={false}
          buttonType={ButtonTypes.PRIMARY}
          style={{
            width: "156px",
          }}
          onClick={() => {
            window.open(
              `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DISCORD_CALLBACK_URL}&response_type=code&scope=identify`,
              "_blank"
            );
          }}
        >
          Connect to Discord
        </Button>
      )}
    </div>
  );
};

const ProjectConnection: React.FC<{
  workId: string;
  containerRef: any;
  disabled: boolean;
}> = ({ workId, containerRef, disabled }) => {
  const project = useShallowSelector((state) => state.work.dict[workId]);
  useDiscordIntegrationModalState();
  const discordIntegrationOpener = useDiscordIntegrationModalOpener(false);

  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState<string>();

  useEffect(() => {
    if (project.workType !== WorkTypes.TASK) {
      loadChannels();
    }
  }, []);

  const loadChannels = useCallback(() => {
    groupApi.getGroupChannelOptions().then((channels) => {
      setOptions(
        channels.map((value: any) => ({
          label: value.name,
          value: value.id,
        }))
      );
    });
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.informationContainer}>
        <h4>Connect to thread</h4>
        <span className="body2 secondary">
          This is the channel where Clarity will create the thread for this
          project.
        </span>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <ClaritySelect
            options={options}
            placeholder="Select a channel..."
            container={
              containerRef && containerRef.current
                ? containerRef.current
                : document.body
            }
            onChange={(value: string) => {
              setSelectedOption(value);
            }}
          />
          <Button
            buttonType={ButtonTypes.LINK}
            icon={<ReloadOutlined />}
            onClick={() => loadChannels()}
          />
        </div>
      </div>
      <Button
        buttonType={ButtonTypes.PRIMARY}
        disabled={!selectedOption || loading || disabled}
        style={{
          width: "72px",
        }}
        onClick={() => {
          setLoading(true);
          workApi.connectToDiscord(project.id, selectedOption).then(() => {
            discordIntegrationOpener();
            setLoading(false);
          });
        }}
      >
        Save
      </Button>
    </div>
  );
};

const GroupConnection: React.FC<{ containerRef: any; disabled: boolean }> = ({
  containerRef,
  disabled,
}) => {
  const discordConnection = useDiscordIntegrationModalState();
  const discordIntegrationOpener = useDiscordIntegrationModalOpener(false);

  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  const [group, setGroup] = useState<IGroup>();
  const [selectedOption, setSelectedOption] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const group = store.getState().groups.dict[discordConnection.groupId];

    if (group) {
      setGroup(group);
    }
  }, [discordConnection.groupId]);
  const loadChannels = useCallback(() => {
    groupApi.getGroupChannelOptions().then((channels) => {
      setOptions(
        channels.map((value: any) => ({
          label: value.name,
          value: value.id,
        }))
      );
    });
  }, []);
  useEffect(() => {
    loadChannels();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.informationContainer}>
        <h4>Select a channel for this group</h4>
        <span className="body2 secondary">
          This is the default channel where Clarity will create threads for
          projects & tasks that belong to this group. You will have the option
          to select other channels for each new thread. You can change this
          default channel later.
        </span>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <ClaritySelect
            options={options}
            container={
              containerRef && containerRef.current
                ? containerRef.current
                : document.body
            }
            placeholder="Select a channel..."
            onChange={(value: string) => {
              setSelectedOption(value);
            }}
          />
          <Button
            buttonType={ButtonTypes.LINK}
            icon={<ReloadOutlined />}
            onClick={() => loadChannels()}
          />
        </div>
      </div>
      {options.length === 0 && (
        <span>
          The discord bot doesn't have the necessary permissions to fetch the
          channels!
        </span>
      )}
      {!group?.channelName && (
        <>
          <Button
            isLoading={false}
            disabled={!selectedOption || loading || disabled}
            buttonType={ButtonTypes.PRIMARY}
            style={{
              width: "72px",
            }}
            onClick={() => {
              setLoading(true);
              groupApi
                .connectGroupToChannel(
                  discordConnection.groupId,
                  selectedOption
                )
                .then(() => {
                  if (
                    discordConnection.intendedStep ===
                    DiscordIntegrationSteps.GroupConnection
                  )
                    discordIntegrationOpener();

                  setLoading(false);
                });
            }}
          >
            Save
          </Button>
        </>
      )}
      {group?.channelName && <span>{group.channelName}</span>}
    </div>
  );
};

export default DiscordConnection;
