import Button, { ButtonTypes } from "components/Button";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import React, { useEffect } from "react";
import { batch } from "react-redux";
import {
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import styles from "./home/groupMembers/groupMembers.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import { useImmediateGroupInvitationsModalSetter } from "./settings/members/InviteGroupMemberModal";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities } from "utilities/types";
import UserList from "clarity-ui/UserList";

const GroupMembers: React.FC<{
  paneId: ChunkDestination;
  groupSlug: string;
}> = ({ paneId, groupSlug }) => {
  const group = useShallowSelector((store) => store.groups.slugDict[groupSlug]);

  usePageDataSetter(paneId, {
    title: group?.name ? group?.name : "",
  });
  const canManageMembers = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });
  const { showInvitationsModal } = useImmediateGroupInvitationsModalSetter(
    group.id
  );

  useEffect(() => {
    batch(() => {
      setPaneSubroutes({
        paneId,
        subRoutesParams: {
          type: "group",
          groupId: group?.id,
          activeKey: "members",
        },
      });
      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.group,
      });
    });

    return () => {
      batch(() => {
        setPaneSubroutes({
          paneId,
        });
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, []);

  return (
    <>
      <TopNavFiltersBar paneId={paneId}>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          icon={<PlusOutlined />}
          onClick={showInvitationsModal}
          disabled={!canManageMembers}
        >
          Add Member
        </Button>
      </TopNavFiltersBar>
      <div className={styles.memberPage}>
        <div className={`${styles.container} flex-fill`}>
          <div className={`${styles.content} content layoutPadding1 flex-fill`}>
            <UserList userIds={group.members ?? []} />
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupMembers;
