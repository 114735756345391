import {
  SET_ACTIVE_WORKSPACE,
  SET_CLARITY_SIDEBAR_MODE,
  LOAD_BASE_FAVORITES,
  ADD_NEW_FAVORITE,
  REMOVE_FAVORITE,
  UPDATE_FAVORITE,
} from "../actions";
import { IFavorite, IPinObj } from "../../utilities/types";
import { ISetActiveWorkspaceActionParameters } from "./workspaceReducer";
import { useShallowSelector } from "utilities/hooks";

interface ILoadBasePinsActionParameters {
  type: string;
  pins: IPinObj[];
}

interface ILoadBaseFavoritesActionParameters {
  type: string;
  favorites: IFavorite[];
}

interface IAddNewFavoriteActionParameters {
  type: string;
  favorite: any;
}

interface IUpdateFavoriteActionParameters {
  type: string;
  favoriteId: string;
  newRank: string;
  newFavoriteArray: string[];
}

interface IAddNewPinActionParameters {
  type: string;
  pin: any;
}

interface IRemovePinActionParameters {
  type: string;
  id: string;
}

interface IUpdatePinActionParameters {
  type: string;
  newPinsArray: string[];
}

interface IUpdateSidebarMode {
  type: string;
  newMode?: SidebarModes;
  isTemporary?: boolean;
  isOpen?: boolean;
}

export interface IPinEntry {
  [id: string]: IPinObj;
}

interface IInitialState {
  pins: { [key: string]: IPinObj };
  pinIds: string[];
  sidebarMode: SidebarModes;
  isOpen: boolean;
  isTemporary: boolean;
  favorites: { [key: string]: IFavorite };
  favoriteIds: string[];
}

export enum SidebarModes {
  closed = "closed",
  home = "home",
  search = "search",
  inbox = "inbox",
}

const initialState: IInitialState = {
  pins: {},
  pinIds: [],
  sidebarMode: SidebarModes.home,
  isOpen: false,
  isTemporary: false,
  favoriteIds: [],
  favorites: {},
};

export default function sidebarReducer(
  state = initialState,
  action: ILoadBasePinsActionParameters &
    IAddNewPinActionParameters &
    IRemovePinActionParameters &
    ISetActiveWorkspaceActionParameters &
    IUpdatePinActionParameters &
    IUpdateSidebarMode &
    ILoadBaseFavoritesActionParameters &
    IAddNewFavoriteActionParameters &
    IUpdateFavoriteActionParameters
) {
  switch (action.type) {
    case SET_CLARITY_SIDEBAR_MODE: {
      const newState = { ...state };
      if ("newMode" in action && action.newMode) {
        newState.sidebarMode = action.newMode;
        if (newState.sidebarMode === SidebarModes.closed)
          newState.isTemporary = true;
      }
      if ("isTemporary" in action) {
        newState.isTemporary = Boolean(action.isTemporary);
      }
      if ("isOpen" in action) {
        newState.isOpen = Boolean(action.isOpen);
      }
      return newState;
    }

    case SET_ACTIVE_WORKSPACE: {
      if (action.workspace && action.workspace.id) {
        return {
          ...initialState,
          isOpen: state.isOpen,
          isTemporary: state.isTemporary,
        };
      }
      return state;
    }

    case LOAD_BASE_FAVORITES: {
      const newState: IInitialState = {
        ...state,
        favorites: {},
        favoriteIds: [] as string[],
      };
      if (action.favorites) {
        if (action.favorites && Array.isArray(action.favorites)) {
          const favoritesIds = action.favorites.map((favorite: IFavorite) => {
            newState.favorites[favorite.id] = favorite;
            return favorite.id;
          });
          newState.favoriteIds = [...favoritesIds];
        }
        return newState;
      }
      return newState;
    }

    case ADD_NEW_FAVORITE: {
      if (action.favorite) {
        const newState = {
          ...state,
          favorites: { ...state.favorites },
          favoriteIds: [...state.favoriteIds],
        };
        newState.favorites[action.favorite.id] = action.favorite;
        if (!newState.favoriteIds.includes(action.favorite.id)) {
          newState.favoriteIds = [...newState.favoriteIds, action.favorite.id];
        }

        return newState;
      }
      return state;
    }

    case REMOVE_FAVORITE: {
      if (action.id) {
        const newState = { ...state, favorites: { ...state.favorites } };
        delete newState.favorites[action.id];
        newState.favoriteIds = [
          ...state.favoriteIds.filter((id) => id !== action.id),
        ];
        return newState;
      }
      return state;
    }

    case UPDATE_FAVORITE: {
      const newState = {
        ...state,
        favorites: { ...state.favorites },
        favoriteIds: state.favoriteIds,
      };

      if (action.newRank && action.favoriteId) {
        newState.favorites[action.favoriteId] = {
          ...newState.favorites[action.favoriteId],
          rank: action.newRank,
        };
        if (action.newFavoriteArray) {
          newState.favoriteIds = action.newFavoriteArray;
        }
      }
      return newState;
    }

    default:
      return state;
  }
}

export function usePins() {
  return useShallowSelector((state) => ({
    pinIds: state.sidebar.pinIds,
    pins: state.sidebar.pins,
  }));
}

export function useFavorites() {
  return useShallowSelector((state) => ({
    favoriteIds: state.sidebar.favoriteIds,
    favorites: state.sidebar.favorites,
  }));
}
