import { Input, InputProps } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./clarityInput/clarityInput.scss";
import { omit } from "lodash";
import { useOptionalClassName } from "utilities/hooks";
import Conditional from "components/Conditional";
import styles from "components/clarityInput/clarityInput.module.scss";

export enum ClarityInputMode {
  normal = "normal",
  borderless = "borderless",
}

type ClarityInputType = InputProps & {
  componentRef?: React.MutableRefObject<HTMLInputElement | null>;
  label?: string;
  inputType?: ClarityInputMode;
};

const ClarityInput: React.FC<ClarityInputType> = (props) => {
  const { label, inputType = ClarityInputMode.normal } = props;
  const inputProps = omit(props, [
    "componentRef",
    "label",
    "inputType",
    "textArea",
    "children",
  ]);

  const classNames = useOptionalClassName(
    {
      baseStyle: `clarityInput`,
      pairs: [
        {
          withExtra: inputType === ClarityInputMode.borderless,
          extraStyle: "borderless",
        },
        {
          extraStyle: props.className ?? "",
          withExtra: Boolean(props.className),
        },
      ],
    },
    [props.className]
  );

  const setInputRef = useCallback((e: Input | null) => {
    if (props.componentRef && e) {
      props.componentRef.current = e.input;
    }
    return e;
  }, []);

  return (
    <div className={styles.container}>
      <Conditional on={label}>
        <InputLabel label={label} />
      </Conditional>
      <div className={styles.inputContainer}>
        <Input
          {...inputProps}
          prefixCls={undefined}
          ref={setInputRef}
          className={classNames}
        />
        {props.children}
      </div>
    </div>
  );
};

export const InputLabel: React.FC<{ label?: string }> = ({ label }) => {
  return <label className={styles.label}>{label ?? ""}</label>;
};

export function useClarityInput({ initialValue }: { initialValue: string }) {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<Input>();
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const focus = () =>
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 10);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    onChange,
    inputRef,
    focus,
  };
}

export default ClarityInput;
