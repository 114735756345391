// Vendor Libraries
import { connect } from "react-redux";
import { History, LocationState } from "history";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

// Internal Modules
import {
  Abilities,
  ContainerTypes,
  IBlockContext,
  IUserObj,
} from "../../../../../utilities/types";
import { Line } from "../../../../../utilities/lineUtilities";
import { getOutlineListType } from "./Document";
import { WorkActivityEntry } from "store/reducers/workReducer";
import BlocksContainer from "editor/blockContainer/BlocksContainer";
import { BlockContextPass } from "editor/blockContainer/BlockContext";
import ContainerWrapper from "editor/blockContainer/ContainerWrapper";
import { $focusOn } from "store/storeExporter";
import { clearBlockSelection } from "editor/utils/blockActions";
import { checkIfCannotAddBlocks } from "modules/appService";
import { useAbilityChecker } from "editor/utils/customHooks";
const { v4: uuidv4 } = require("uuid");

interface IDocumentComponentProps {
  history: History<LocationState>;
  lines?: Line[];
  workspace: any;
  workItem: any;
  containerId: string;
  loading: boolean;
  isBaseMember: boolean;

  outlineMode: string;
  sharedView: boolean;
  connected: boolean;
  destination?: string;
  documentComments: any;
  contextPresets?: Partial<IBlockContext>;
}

interface IMapStateToProps {
  displayModalDocumentShare: boolean;
  workActivities: WorkActivityEntry;
  user: IUserObj;
  newDiscussionDetail: any;
}

function WorkActivity(
  props: RouteComponentProps<any> & IDocumentComponentProps & IMapStateToProps
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setisLoading] = useState(false);
  const activeId = useRef(props.containerId);
  activeId.current = props.containerId;
  const documentRef = useRef<HTMLDivElement | null>(null);
  const canAddTaskComment = useAbilityChecker({
    abilityName: Abilities.CAN_ADD_TASK_COMMENT,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const blocksLoaded = useRef(false);

  const checkIfCanEdit = () => {
    if (props.containerId === "newComment") {
      if (checkIfCannotAddBlocks({ skipModal: true })) return false;
    }
    if (!canAddTaskComment) return false;
    return true;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activityContext, setActivityContext] = useState<IBlockContext>({
    ...{
      id: ContainerTypes.WORK_ACTIVITY + props.containerId,
      container: {
        id: props.containerId,
        type: ContainerTypes.WORK_ACTIVITY,
        outlineMode: props.outlineMode,
        referenceTaskId: props.workItem?.id,
      },
      type: "container",
      ref: documentRef,
      paneId: uuidv4(),
      autosave: false,
      persistToggle: false,
      online: true,
      canEdit: checkIfCanEdit(),
      canComment: false,
    },
    ...(props.contextPresets ? props.contextPresets : {}),
  });

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (
        $focusOn.value.focusContext?.id === activityContext.id &&
        $focusOn.value.focusPane === activityContext.paneId
      ) {
        $focusOn.next({ ...$focusOn.value, focusBlockId: undefined });
        clearBlockSelection();
      }
    };
  }, []);

  return useMemo(() => {
    if (isLoading || !props.workActivities || !props.containerId) {
      return <></>;
    } else {
      return (
        <>
          <div className="document-wrapper">
            <div className="document-container-wrapper">
              <div className="document-container">
                <div
                  className="document select-area"
                  style={{ paddingTop: "5px", paddingBottom: "0px" }}
                  ref={documentRef}
                  // onInput={(e) => handleCheckCitationUpdate(e)}
                >
                  <ol
                    className={
                      "list-type-" + getOutlineListType(props.outlineMode)
                    }
                  >
                    <ContainerWrapper
                      context={activityContext}
                      isBaseMember={props.isBaseMember}
                    >
                      <BlockContextPass.Provider value={activityContext}>
                        <BlocksContainer
                          parentListType={getOutlineListType(props.outlineMode)}
                        />
                      </BlockContextPass.Provider>
                    </ContainerWrapper>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.containerId,
    isLoading,
    props.outlineMode,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.workActivities?.[props.containerId],
  ]);
}

const mapStateToProps = (state: any, ownProps: any) => {
  let docState = {
    workspace: state.workspace,
    workActivities: state.work.workActivities,
    displayModalDocumentShare: state.client.displayModalDocumentShare,
    user: state.user,
    isBaseMember: state.client.isBaseMember,
    connected: state.network.connected,
    outlineMode:
      state.blocks.contexts[ContainerTypes.WORK_ACTIVITY + ownProps.containerId]
        ?.outlineMode,
  };
  return docState;
};

export default connect(mapStateToProps, null)(WorkActivity);
