import React, { useEffect, useState } from "react";

import {
  abilityToFriendlyName,
  IRole,
  IUserPermissions,
  managableAbilities,
} from "utilities/types";
import { Checkbox, Switch } from "antd";
import { CheckOutlined } from "@ant-design/icons";

const RoleAbilities: React.FC<{
  role: IRole;
  showOwnPermissions: boolean;
  showCheckPermission: boolean;
  showName?: boolean;
  toggleType?: "switch" | "checkbox";
  cellClass?: string;
  activeClass?: string;
  onChangeAbilities?: (
    roleId: string,
    delta: Partial<IUserPermissions>
  ) => void;
}> = ({
  role,
  showCheckPermission,
  showOwnPermissions,
  showName = true,
  onChangeAbilities,
  toggleType = "switch",
  cellClass,
  activeClass,
}) => {
  const [abilitiesToShow, setAbilitiesToShow] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<Partial<IUserPermissions>>({});

  useEffect(() => {
    const newPermissions = {
      ...role.permissions,
    };
    setPermissions(newPermissions);
  }, [role.permissions]);

  useEffect(() => {
    const roleAbilities = Object.keys(role.permissions);

    const toShow = showOwnPermissions
      ? managableAbilities.filter((abilityName) =>
          roleAbilities.includes(abilityName)
        )
      : managableAbilities;

    setAbilitiesToShow(toShow);
  }, [showCheckPermission, showOwnPermissions]);

  const onChange = (ability: string) => {
    const newPermissions = {
      ...permissions,
    };
    if (ability in permissions) {
      delete newPermissions[ability as keyof IUserPermissions];
    } else {
      newPermissions[ability as keyof IUserPermissions] = true;
    }
    setPermissions(newPermissions);
    if (onChangeAbilities) {
      onChangeAbilities(role.id, newPermissions);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {abilitiesToShow.map((ability: string) => {
        return (
          <div
            key={ability}
            className={`${cellClass} ${
              ability in permissions ? activeClass : ""
            }`}
            style={{
              alignItems: "center",
            }}
            onClick={(e) => {
              if (!onChangeAbilities) return;
              if (showCheckPermission && toggleType !== "switch") {
                e.stopPropagation();
                onChange(ability);
              }
            }}
          >
            {showCheckPermission && toggleType === "switch" && (
              <Switch
                onChange={(e) => {
                  onChange(ability);
                }}
                checkedChildren={<CheckOutlined />}
                disabled={!onChangeAbilities}
                style={{ marginRight: "12px" }}
                checked={showOwnPermissions ? true : ability in permissions}
              />
            )}
            {showName && (
              <span className="body2 medium secondary">
                {abilityToFriendlyName[ability]}
              </span>
            )}
            {showCheckPermission && toggleType === "checkbox" && (
              <Checkbox
                onChange={(e) => {
                  e.preventDefault();
                  // e.stopPropagation();
                  // e.preventDefault();
                  // onChange(ability);
                }}
                style={{ marginTop: "-5px" }}
                disabled={!onChangeAbilities}
                checked={showOwnPermissions ? true : ability in permissions}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RoleAbilities;
