import { ContainerTypes, IBlockContext, UserEventTypes } from "utilities/types";
import store, { prevState } from "store/storeExporter";
import React from "react";
import { Block } from "store/reducers/blockReducer";
import * as actionTypes from "store/actions";
import { batch } from "react-redux";
import { handleNoteBlocksLoad } from "store/middlewares/valueUpdateChecker";
import { ActionObject } from "../specificActions/undoUtils";
import { BlockActionTypes } from "../blockActions";
import { setUpdateObjectToStore } from "../specificActions/persistActions";
import { mixpanel } from "App";
import { axiosInstance } from "index";
import { permissionsApi } from "clientApi/permissionsApi";

export const loadContainerData = async (container: {
  containerId: string;
  containerType: ContainerTypes;
  titleBlockId: string;
  tagsBlockId?: string;
  titleBlock: React.MutableRefObject<Block | null>;
  isPublicAccess: boolean | undefined;
  outlineMode?: string;
}) => {
  const params: any = {
    containerId: container.containerId,
    containerType: container.containerType,
    baseId: store.getState().workspace.id,
    isPublicAccess: container.isPublicAccess,
  };

  if (container.tagsBlockId) params["tagsBlockId"] = container.tagsBlockId;
  await new Promise(async (resolve, reject) => {
    const promises = [];
    const title = axiosInstance
      .get("/api/lines/containerTitle", {
        params,
      })
      .then((res) => {
        if (res.data.titleBlock) {
          store.dispatch({
            type: actionTypes.SET_BLOCK_IN_DICT,
            block: res.data.titleBlock,
            globalBlockAction: true,
          });
        }
        if (res.data.tagsBlock) {
          store.dispatch({
            type: actionTypes.SET_BLOCK_IN_DICT,
            block: res.data.tagsBlock,
            globalBlockAction: true,
          });
        }
        container.titleBlock.current = res.data.titleBlock;
        return true;
      })
      .catch((err) => {
        console.log(err);
      });

    promises.push(title);

    const blocksAndMentions = axiosInstance
      .get("/api/lines/getContainerBlocks", {
        params: {
          id: container.containerId,
          titleBlockId: container.titleBlockId,
          containerType: container.containerType.toLocaleLowerCase(),
        },
      })
      .then(async (res) => {
        const linesArray = res.data;
        const fullResponse = {
          lineObject: {
            entities: {
              lines: {},
            },
            rootLinesId: [],
          },
        };
        prepareStateForFrontend(linesArray, fullResponse);
        store.dispatch({
          type: actionTypes.SET_BLOCKS_FROM_CONTAINER,
          param: {
            blocks: fullResponse.lineObject.entities.lines,
            rootLinesId: fullResponse.lineObject.rootLinesId,
            container,
          },
        });
      });
    promises.push(blocksAndMentions);

    if (container.isPublicAccess) {
      const containerAccess = axiosInstance
        .get("/api/container/visibility", {
          params: {
            containerId: container.containerId,
            containerType: container.containerType,
          },
        })
        .then((res) => {
          if (res.data) {
            permissionsApi.addPermission({
              containerId: container.containerId,
              containerType: container.containerType,
              accessLevel: res.data.visibility,
            });
          }
        });
      promises.push(containerAccess);
    }
    resolve(Promise.all(promises));
  });

  if (
    [ContainerTypes.DOCUMENT, ContainerTypes.PROJECT].includes(
      container.containerType
    )
  ) {
    const path =
      container.containerType === ContainerTypes.DOCUMENT
        ? `/api/document/mentionsToDoc`
        : `/api/project/mentionsToProject/${container.containerId}`;

    axiosInstance
      .get(path, {
        params: {
          id: container.containerId,
        },
      })
      .then((resp) => {
        batch(() => {
          store.dispatch({
            type: actionTypes.SET_MENTION_OBJECTS,
            param: {
              containers: resp.data.fullResponse,
              containerId: container.containerId,
              blocks: resp.data.fullResponse.blocks,
            },
          });
          if (resp.data.notes && resp.data.notes.length > 0) {
            handleNoteBlocksLoad({
              type: actionTypes.SET_NOTE_OBJECTS,
              params: {
                notes: resp.data.notes,
              },
            });

            store.dispatch({
              type: actionTypes.SET_NOTE_OBJECTS,
              params: {
                notes: resp.data.notes,
              },
            });
          }
        });
      });
  }

  axiosInstance
    .get("/api/citation/containerCitations", {
      params: {
        containerId: container.containerId,
        containerType: container.containerType,
      },
    })
    .then((res) => {
      store.dispatch({
        type: actionTypes.LOAD_CITATIONS,
        param: {
          citations: res.data,
        },
      });
      axiosInstance
        .get("/api/citation/sourceContainerCitations", {
          params: {
            containerId: container.containerId,
            containerType: container.containerType,
          },
        })
        .then((res) => {
          store.dispatch({
            type: actionTypes.LOAD_CITATIONS,
            param: {
              citations: res.data,
            },
          });
        });
    });

  axiosInstance
    .get("/api/discussion/containerDiscussions", {
      params: {
        containerId: container.containerId,
        containerType: container.containerType,
      },
    })
    .then((res) => {
      const comments = res.data.sort(function (a: any, b: any) {
        return a.documentRank.localeCompare(b.documentRank, "en");
      });
      store.dispatch({
        type: "ADD_ENTITY_CONNECTIONS_OBJECT",
        id: container.containerId,
        containerType: container.containerType,
        comments,
      });
    });
};

const prepareStateForFrontend = (lineArray: Block[], fullResponse: any) => {
  const lines = fullResponse.lineObject.entities.lines;
  const rootLinesId = fullResponse.lineObject.rootLinesId;
  let needsFix = false;
  lineArray.forEach((el) => {
    if (!el.parentId || el.parentId === null) el.parentId = "";
    el.ref = React.createRef();
    if (!el.children) el.children = [];
    if (el.parentId === "") rootLinesId.push(el.id);
    else {
      if (lines) {
        if (!lines[el.parentId]) {
          lines[el.parentId] = { children: [] };
          needsFix = true;
        } else {
          if (
            lines[el.parentId].indentLevel !== undefined &&
            el.indentLevel !== lines[el.parentId].indentLevel + 1
          )
            needsFix = true;
        }
        if (!lines[el.parentId].children) lines[el.parentId].children = [];
        lines[el.parentId].children.push(el.id);
      }
    }
    if (lines[el.id]) lines[el.id] = { ...el, ...lines[el.id] };
    else lines[el.id] = el;
  });
  if (needsFix) {
    startAutoFix(lines);
  }
};

const startAutoFix = (lines: { [id: string]: Block }) => {
  const saveActions: ActionObject[] = [];
  let containerId = null;
  Object.values(lines).forEach((line) => {
    containerId = line.containerId;
    if (line.children && line.children.length > 0) {
      line.children.forEach((childId) => {
        if (lines[childId]) {
          if (lines[childId].indentLevel !== line.indentLevel + 1) {
            lines[childId].indentLevel = line.indentLevel + 1;
            const action: ActionObject = {
              id: childId,
              delta: {
                indentLevel: line.indentLevel + 1,
              },
              type: BlockActionTypes.update,
            };
            saveActions.push(action);
          }
        }
      });
    }
  });
  if (saveActions.length > 0) {
    setUpdateObjectToStore(saveActions, {
      autosave: true,
      canEdit: true,
    } as IBlockContext);

    mixpanel.track(UserEventTypes.BLOCK_AUTOFIX_EXECUTED, {
      distinct_id: prevState.value?.user?.id,
      containerId,
    });
    console.log("Auto cleanup executed");
  }
};
