import { useEffect, useState } from "react";
import { Alert, Form, Switch } from "antd";
import { WarningOutlined, CheckOutlined } from "@ant-design/icons";

import {
  Abilities,
  IUserObj,
  IWorkspaceObj,
  UserBaseSettings,
} from "utilities/types";
import TabLayout from "./TabLayout";
import FooterForSave from "./FooterForSave";
import styles from "./options/options.module.scss";
import store from "store/storeExporter";
import { useAbilityChecker } from "editor/utils/customHooks";
import { baseApi } from "clientApi/baseApi";
import { UPDATE_WORKSPACE } from "store/actions";
import notificationsApi from "clientApi/notificationsApi";
import { useSetBaseSettingHasChanges } from "store/reducers/clientReducer";
import { axiosInstance } from "index";

interface Props {
  base: IWorkspaceObj;
  user: IUserObj;
  userSettings: UserBaseSettings;
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

interface Status {
  message: string;
  type: "error" | "success";
}

const OptionsWeekly = ({ base, userSettings, setAuthenticatedUser }: Props) => {
  const [baseSettings, setBaseSettings] = useState<IWorkspaceObj>(base);
  const [baseStatus, setBaseStatus] = useState<Status>({
    message: "",
    type: "error",
  });

  const [showWeekly, setshowWeekly] = useState<boolean>(base.showWeekly);

  const canManageBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      "email-notification": userSettings?.settings?.emailNotifications,
    });
  }, [userSettings]);

  useEffect(() => {
    setBaseSettings(base);
  }, [base]);

  const handleSaveBaseChanges = async () => {
    try {
      const { data } = await axiosInstance.patch(`/api/workspace/profile`, {
        baseId: baseSettings.id,
        patch: [
          {
            op: "replace",
            path: "/showWeekly",
            value: showWeekly,
          },
        ],
      });
      if (data.status === 1) {
        // history.push(`/${base.ownerName}/${baseSettings.slug}/settings`);
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Details updated successfully",
        });

        const base = store.getState().workspace;

        if (showWeekly) {
          const promises = [
            baseApi.createWeeklyNote(base.id, false),
            baseApi.fetchAndSetBaseWeeklNotes(base.id),
          ];
          await Promise.all(promises);
        }

        store.dispatch({
          type: UPDATE_WORKSPACE,
          params: {
            delta: { showWeekly },
          },
        });
        // setActiveBase(baseSettings);
        const { data } = await axiosInstance.get("/api/user");
        setAuthenticatedUser(data.payload);
      } else {
        setBaseStatus({
          type: "error",
          message:
            "Sorry, we could not save your changes. Please try again later",
        });
      }
    } catch (e) {
      setBaseStatus({
        type: "error",
        message:
          "Sorry, we could not save your changes. Please try again later",
      });
    }
  };
  const handleReset = () => {
    setshowWeekly(!showWeekly);
  };
  const hasChanges = !(
    baseSettings.showWeekly === showWeekly || !canManageBaseSettings
  );

  useSetBaseSettingHasChanges(hasChanges, [
    baseSettings.showWeekly,
    showWeekly,
    canManageBaseSettings,
  ]);

  return (
    <>
      <TabLayout
        title="Weekly"
        description="A new note every week, for your whole team"
        actionBtn={
          <>
            <Switch
              checkedChildren={<CheckOutlined />}
              defaultChecked={showWeekly}
              checked={showWeekly}
              disabled={!canManageBaseSettings}
              onChange={() => {
                setshowWeekly(!showWeekly);
              }}
            />
          </>
        }
      ></TabLayout>
      <Form>
        <div className={styles.options}>
          {baseStatus.type === "error" && !!baseStatus.message && (
            <Alert
              type="error"
              message={
                <div>
                  <WarningOutlined /> {baseStatus.message}
                </div>
              }
              style={{
                marginBottom: 28,
                backgroundColor: " #fcdbdc",
                borderRadius: "4px",
                border: "solid 1px #dbabac",
                color: "#723b3e",
              }}
            />
          )}
          {baseStatus.type === "success" && !!baseStatus.message && (
            <Alert
              type="success"
              message={baseStatus.message}
              style={{ marginBottom: 28 }}
            />
          )}
          {!(
            baseSettings.showWeekly === showWeekly || !canManageBaseSettings
          ) && (
            <FooterForSave
              onSave={handleSaveBaseChanges}
              onCancel={handleReset}
            />
          )}
        </div>
      </Form>
    </>
  );
};

export default OptionsWeekly;
