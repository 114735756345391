import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect, useSelector, shallowEqual, batch } from "react-redux";
import { IWorkspaceObj, ContainerTypes, Abilities } from "utilities/types";
import styles from "clarity-ui/mentionsSection/mentionsSection.module.scss";
import MentionSubSection from "clarity-ui/mentionsSection/MentionSubSection";
import ContainerGroup from "clarity-ui/mentionsSection/mentionSubSection/ContainerGroup";
import {
  MentionDataObj,
  ContainerMentions,
} from "store/reducers/mentionsReducer";
import Mark from "mark.js";
import { updateMultipleBlocksText } from "editor/utils/specificActions/textUpdateActions";
import Button, { ButtonTypes } from "components/Button";
import { TagOutlined, LoadingOutlined } from "@ant-design/icons";
import store from "store/storeExporter";
import { LOAD_UNLINKED_MENTIONS } from "store/actions";
import { stripHtml } from "utilities/stringUtilities";
import { useAbilityChecker } from "editor/utils/customHooks";
import { breakDownHtml, getHtml } from "editor/utils/blockValueHelpers";
import { axiosInstance } from "index";
import { getGroupsFromEntity } from "modules/containerHelpers";
import { ChunkDestination } from "utilities/stateTypes";

interface MentionsSectionProps {
  containerId: string;
  containerType: ContainerTypes;
  paneId: ChunkDestination;
}

interface IMapStateToProps {
  base: IWorkspaceObj;
  containerType: ContainerTypes;
}

interface IMapDispatchToProps {}

function MentionsSection(
  props: MentionsSectionProps & IMapDispatchToProps & IMapStateToProps
) {
  const { base, containerType, paneId } = props;

  const containerMentions: MentionDataObj = useSelector((state: any) => {
    return state.mentions.dict[props.containerId];
  }, shallowEqual);

  const entity = useSelector((state: any) => {
    if (props.containerType === ContainerTypes.DOCUMENT && state.pages.dict)
      return state.pages.dict[props.containerId];
    return state.work.dict[props.containerId];
  }, shallowEqual);

  const [filters, setFilters] = useState<string[]>([]);

  return (
    <div
      className={`${styles.MentionsSection} mentions ${
        props.containerType === ContainerTypes.PROJECT
          ? styles.projectMentions
          : ""
      }`}
      onClick={(e) => e.nativeEvent.stopPropagation()}
    >
      {containerMentions &&
      Object.keys(containerMentions.containers).length > 0 ? (
        <MentionSubSection
          title={`Mentions of "${stripHtml(getHtml(entity.nameValue))}" in ${
            base.name
          }`}
          setFilters={setFilters}
          filters={filters}
        >
          <LinkedMentions
            paneId={paneId}
            filters={filters}
            containerMentions={containerMentions}
          />
        </MentionSubSection>
      ) : (
        <>
          {props.containerType === ContainerTypes.PROJECT ? (
            <div style={{ display: "flex" }} className={styles.emptyMentions}>
              <span>Nothing to show</span>
            </div>
          ) : null}
        </>
      )}
      {containerType === ContainerTypes.DOCUMENT && (
        <UnlinkedMentions
          entity={entity}
          paneId={props.paneId}
          containerId={props.containerId}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  base: state.workspace,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MentionsSection);

const sortContainersByUpdateDate = (arrayOfContainers: any[]) => {
  const storeData = store.getState();

  const getContainerByType = (container: {
    containerType: ContainerTypes;
    containerId: string;
  }) => {
    switch (container.containerType) {
      case ContainerTypes.DOCUMENT:
        return storeData.pages.dict[container.containerId];
      case ContainerTypes.PROJECT:
      case ContainerTypes.TASK:
      case ContainerTypes.WORK:
        return storeData.work.dict[container.containerId];
      case ContainerTypes.NOTE:
        return (
          storeData.notes.dict[container.containerId] ??
          storeData.notes.weeklyNotesDict[container.containerId]
        );
    }
  };
  arrayOfContainers.sort((containerA: any, containerB) => {
    const containerDataA = getContainerByType(containerA);
    const containerDataB = getContainerByType(containerB);
    const dateA = new Date(containerDataA ? containerDataA.dateCreated : "");
    const dateB = new Date(containerDataB ? containerDataB.dateCreated : "");
    return dateB.getTime() - dateA.getTime();
  });
  return arrayOfContainers;
};

const LinkedMentions: React.FC<{
  containerMentions: MentionDataObj;
  paneId: ChunkDestination;
  filters: string[];
}> = ({ containerMentions, paneId, filters }) => {
  let arrayOfContainers =
    filters.length === 0
      ? Object.values(containerMentions.containers)
      : Object.values(containerMentions.containers).filter((container) => {
          if (container.containerType === ContainerTypes.PROJECT) {
            const item = store.getState().work.dict[container.containerId];
            return filters.includes(item.workType);
          }
          return filters.includes(container.containerType);
        });
  arrayOfContainers = sortContainersByUpdateDate(arrayOfContainers);

  if (filters.length > 0 && arrayOfContainers.length === 0)
    return (
      <div style={{ display: "flex" }} className={styles.emptyMentions}>
        <span>Nothing to show</span>
      </div>
    );

  return (
    <>
      {arrayOfContainers.map((container: any) => {
        if (container.blocks.length === 0)
          return <React.Fragment key={container.containerId}></React.Fragment>;
        return (
          <ContainerGroup
            key={container.containerId}
            container={container}
            paneId={paneId}
          />
        );
      })}
    </>
  );
};

const UnlinkedMentions: React.FC<any> = (props) => {
  const containerMentions: ContainerMentions = useSelector((state: any) => {
    return state.mentions.unlinkedDict[props.containerId];
  }, shallowEqual);

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: getGroupsFromEntity(props.entity, props.containerType),
  });

  const [unlinkedMentions, setunlinkedMentions] = useState<{
    blockCount: number;
    fullResponse: any;
    rootBlocks: any[];
  }>({
    blockCount: 0,
    fullResponse: { documents: {} },
    rootBlocks: [],
  });
  const [searchingUnlinkedMentions, setsearchUnlinkedMentions] =
    useState(false);
  const [folded, setfolded] = useState(true);
  const [keyWord, setkeyWord] = useState(props.entity?.name);

  const actionRef = useRef({
    unlinkedMentions,
    setunlinkedMentions,
  });

  useEffect(() => {
    if (!folded) {
      searchForUnlinkedMentions();
    }
  }, [folded]);

  useEffect(() => {
    if (keyWord !== props.entity?.name) setkeyWord(props.entity?.name);
  }, [props.entity?.name]);

  useEffect(() => {
    actionRef.current = {
      setunlinkedMentions,
      unlinkedMentions,
    };
  }, [unlinkedMentions]);

  const searchForUnlinkedMentions = () => {
    const baseId = store.getState().workspace.id;
    setsearchUnlinkedMentions(true);
    axiosInstance
      .get("/api/lines/unlinkedMentions", {
        params: {
          queryString: props.entity.name,
          baseId,
          titleBlockId: props.entity.titleBlockId,
        },
      })
      .then((res) => {
        batch(() => {
          setsearchUnlinkedMentions(false);
          store.dispatch({
            type: LOAD_UNLINKED_MENTIONS,
            param: {
              containers: res.data.fullResponse,
              containerId: props.containerId,
              blockCount: res.data.blockCount,
              blocks: res.data.fullResponse.blocks,
            },
          });
          setunlinkedMentions(res.data);
        });
      });
  };

  const linkTagsForBlock = useCallback(
    (
      mention: any,
      options?: {
        currentLinesContext: any;
      }
    ) => {
      // const searchIn = "#unlinked-" + mention.id;
      const context: any = document.querySelectorAll(
        `[data-block-id="${mention.id}"]`
      )[0];
      // document.querySelector(searchIn);
      const confirmedHref = '[href*="' + props.containerId + '"]';
      if (context) {
        var instance = new Mark(context);
        instance.mark(keyWord, {
          separateWordSearch: false,
          caseSensitive: false,
          className: "mention highlightet",
          element: "mention",
          exclude: [".highlightet", confirmedHref],
          acrossElements: true,
        });
        const els = context.querySelectorAll("mention.mention.highlightet");
        let i = 0;
        const length = els.length;
        for (i; i <= length - 1; i++) {
          if (els[i].textContent && els[i].textContent !== keyWord) {
            if (els[i + 1] && els[i].textContent && els[i + 1].textContent) {
              const text1 = els[i].textContent;
              const text2 = els[i + 1].textContent;
              if (text1 && text2) {
                const compText = text1 + text2;
                if (compText === keyWord) {
                  els[i].textContent = keyWord;
                  els[i + 1].remove();
                }
              }
            }
          }
          els[i].setAttribute("href", props.containerId);
        }
        const newValue = [];
        const blockRef = context;
        // context.getElementsByClassName("content-section")[0];

        if (blockRef) {
          for (const child of Array.from(blockRef?.childNodes)) {
            newValue.push(breakDownHtml(child));
          }
        }
        const id = mention.id;
        const value = newValue;
        updateMultipleBlocksText([{ id, value }], { autosave: true } as any);
      }
    },
    []
  );

  const getContainerId = (block: any) => {
    return block.containerId;
  };

  const linkButton = (block: any) => {
    const action = actionRef;
    return (
      <Button
        disabled={!canEditEntity}
        icon={
          <TagOutlined
            style={{
              fontSize: "1.125rem",
              margin: "1px auto 0",
              color: "inherit",
            }}
          />
        }
        buttonType={ButtonTypes.LINK}
        onClick={(e: any) => {
          if (!canEditEntity) return;
          e.stopPropagation();

          const newUnlinkedMentions = { ...action.current.unlinkedMentions };
          const containerId = getContainerId(block);

          let index = -1;
          for (let i = 0; i < unlinkedMentions.rootBlocks.length; i++) {
            if (block.id === unlinkedMentions.rootBlocks[i].id) {
              index = i;
              break;
            }
          }

          if (index > -1) {
            newUnlinkedMentions.rootBlocks.splice(index, 1);
            newUnlinkedMentions.blockCount =
              newUnlinkedMentions.rootBlocks.length;
          }

          const index2 = newUnlinkedMentions.fullResponse.documents[
            containerId
          ]?.rootLinesId.indexOf(block.id);
          if (index2 > -1) {
            newUnlinkedMentions.fullResponse.documents[
              containerId
            ].rootLinesId.splice(index2, 1);

            linkTagsForBlock(block, {
              currentLinesContext:
                newUnlinkedMentions.fullResponse.documents[containerId].lines,
            });

            if (
              newUnlinkedMentions.fullResponse.documents[containerId]
                .rootLinesId.length === 0
            ) {
              delete newUnlinkedMentions.fullResponse.documents[containerId];
            }
          }
          // action.current.setunlinkedMentions(newUnlinkedMentions);

          // updateBlocksGlobally([block]);
          // updateMultipleBlocksText([mention.id, newValue]);
        }}
      ></Button>
    );
  };

  const linkAll = (e: any) => {
    if (!canEditEntity) return;
    e.stopPropagation();
    batch(() => {
      unlinkedMentions.rootBlocks.forEach((mention) => {
        const containerId = getContainerId(mention);
        linkTagsForBlock(mention, {
          currentLinesContext:
            unlinkedMentions.fullResponse.documents[containerId].lines,
        });
      });
    });
    // setunlinkedMentions({
    //   blockCount: 0,
    //   fullResponse: { documents: {} },
    //   rootBlocks: [],
    // });
    // updateBlocksGlobally(unlinkedMentions.rootBlocks);
  };

  if (!canEditEntity) {
    return <></>;
  }

  return (
    <div className={styles.mentionGroup + " boundry"}>
      <MentionSubSection
        title={`Find unlinked mentions`}
        unlinked={true}
        setIsUnlinkedFolded={setfolded}
        unlinkedMentionsCount={
          containerMentions && containerMentions.blockCount
            ? containerMentions.blockCount
            : 0
        }
        linkAllUnlinked={linkAll}
      >
        <>
          <div className={styles.mentionBlockContainer}>
            {searchingUnlinkedMentions ? (
              <div style={{ display: "flex" }} className={styles.mentionBlock}>
                <span style={{ opacity: "0.4" }}>
                  <LoadingOutlined /> Loading mentions
                </span>
              </div>
            ) : (
              <>
                <div>
                  {!containerMentions || !containerMentions.blockCount ? (
                    <div
                      style={{ display: "flex" }}
                      className={styles.emptyMentions}
                    >
                      <span>Nothing to show</span>
                    </div>
                  ) : (
                    <>
                      {containerMentions &&
                        Object.values(containerMentions.containers).map(
                          (container: any) => {
                            return container.blocks.length === 0 ? (
                              <></>
                            ) : (
                              <ContainerGroup
                                linkButton={linkButton}
                                unlinked={true}
                                key={container.containerId}
                                container={container}
                                paneId={props.paneId}
                              />
                            );
                          }
                        )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      </MentionSubSection>
    </div>
  );
};
