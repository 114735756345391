import React from "react";
import { useBase } from "store/reducers/workspaceReducer";
import store from "store/storeExporter";
import Button, { ButtonTypes } from "./Button";
import { CloseOutlined } from "@ant-design/icons";
import { SET_SHOW_LEAVE_BASE_MODAL } from "store/actions";

export enum LeaveBaseTypes {
  Button = "Button",
  Icon = "Icon",
  Span = "span",
}

const LeaveBase: React.FC<{
  leaveBaseType?: LeaveBaseTypes;
  disabled?: boolean;
  baseId?: string;
}> = ({ leaveBaseType = LeaveBaseTypes.Button, disabled = false, baseId }) => {
  const base = useBase();

  return (
    <>
      {leaveBaseType === LeaveBaseTypes.Button && (
        <Button
          buttonType={ButtonTypes.DANGER}
          onClick={(e: any) => {
            store.dispatch({
              type: SET_SHOW_LEAVE_BASE_MODAL,
              param: {
                showLeaveBaseConfirmation: true,
                leaveBaseId: baseId ?? base.base.id,
              },
            });
          }}
          disabled={disabled}
        >
          {"Leave base"}
        </Button>
      )}
      {leaveBaseType === LeaveBaseTypes.Icon && (
        <Button
          buttonType={ButtonTypes.LINK}
          onClick={(e: any) => {
            store.dispatch({
              type: SET_SHOW_LEAVE_BASE_MODAL,
              param: {
                showLeaveBaseConfirmation: true,
                leaveBaseId: baseId ?? base.base.id,
              },
            });
          }}
          style={{ borderRadius: 3 }}
          disabled={disabled}
        >
          <CloseOutlined style={{ fontSize: 14 }} />
        </Button>
      )}

      {leaveBaseType === LeaveBaseTypes.Span && (
        <span
          onClick={(e) => {
            if (disabled) {
              return;
            }
            store.dispatch({
              type: SET_SHOW_LEAVE_BASE_MODAL,
              param: {
                showLeaveBaseConfirmation: true,
                leaveBaseId: baseId ?? base.base.id,
              },
            });
          }}
          style={{ borderRadius: 3 }}
        >
          {"Leave base"}
        </span>
      )}
    </>
  );
};

export default LeaveBase;
