import ConfirmModal from "clarity-ui/ConfirmModal";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useUser } from "store/reducers/userReducer";
import { useBase } from "store/reducers/workspaceReducer";
import store, { ClarityStore } from "store/storeExporter";
import { UserRole } from "utilities/types";
import { SET_SHOW_LEAVE_BASE_MODAL } from "store/actions";
import ReactDOM from "react-dom";
import { axiosInstance } from "index";

const LeaveBaseConfirmationModal: React.FC = () => {
  const baseId = useSelector(
    (store: ClarityStore) => store.client.leaveBaseId,
    shallowEqual
  );

  const showLeaveBaseConfirmation = useSelector(
    (store: ClarityStore) => store.client.showLeaveBaseConfirmation,
    shallowEqual
  );

  const userRole = useSelector(
    (store: ClarityStore) => store.client.roleType,
    shallowEqual
  );
  const isOwner = userRole === UserRole.OWNER;

  const user = useUser();
  const base = useBase();
  const handleLeaveBase = async () => {
    if (!isOwner) {
      await axiosInstance
        .post(`/api/workspace/revokeAccess`, {
          userId: user.id,
          baseId: baseId ?? base.base.id,
        })
        .then(() => {
          store.dispatch({
            type: SET_SHOW_LEAVE_BASE_MODAL,
            param: {
              showLeaveBaseConfirmation: false,
            },
          });
        });
    }
  };

  return (
    <>
      {showLeaveBaseConfirmation &&
        ReactDOM.createPortal(
          <ConfirmModal
            onConfirm={handleLeaveBase}
            title="Leave base"
            close={() => {
              store.dispatch({
                type: SET_SHOW_LEAVE_BASE_MODAL,
                param: {
                  showLeaveBaseConfirmation: false,
                },
              });
            }}
          >
            <div
              className="body2 regular"
              style={{
                marginTop: "24px",
                marginBottom: "40px",
              }}
            >
              Are you sure you want to leave the base?
            </div>
          </ConfirmModal>,
          document.body
        )}
    </>
  );
};

export default LeaveBaseConfirmationModal;
