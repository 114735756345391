import { locationSubject } from "components/LocationListener";
import { BlockActionTypes } from "editor/utils/blockActions";
import { loadContainerData } from "editor/utils/containerActions/loadContainerActions";
import { getBlockFromExtender } from "editor/utils/socketActionsListener";
import { cloneBlockBranch } from "editor/utils/specificActions/cloneBlockActions";
import { startBlockInsert } from "editor/utils/specificActions/pasteActions";
import {
  executeTransaction,
  localChangesTransaction,
  setUpdateObjectToStore,
} from "editor/utils/specificActions/persistActions";
import { ActionObject } from "editor/utils/specificActions/undoUtils";
import { axiosInstance } from "index";
import { createNewBlock } from "modules/entityService";
import { batch } from "react-redux";
import {
  REMOVE_SNIPPET_SIDEEFFECT,
  SET_BASE_SNIPPET,
  SHOW_MODAL_NEW_SNIPPET,
  UPDATE_SNIPPET,
} from "store/actions";
import { Block } from "store/reducers/blockReducer";
import { getSortedSelectedBlocks } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import { updateSnippetItemAction } from "store/reducers/snippetReducer";
import store, { $focusOn, ClarityStore } from "store/storeExporter";
import { LineType } from "utilities/lineUtilities";
import {
  ContainerTypes,
  IBlockContext,
  ISnippetObj,
  NavigationChunk,
  ViewNames,
} from "utilities/types";
import navigationApi from "./navigationApi";
import notificationsApi from "./notificationsApi";
const { v4: uuidv4 } = require("uuid");

class SnippetsApi {
  url = "/api/snippets";
  getState() {
    return store.getState().snippets;
  }

  getBaseSnippets(baseId: string) {
    return axiosInstance
      .get(`${this.url}/all`, {
        params: {
          baseId,
        },
      })
      .then((res: any) => {
        store.dispatch({
          type: SET_BASE_SNIPPET,
          param: {
            snippets: res.data,
          },
        });
      });
  }

  createSnippet(presets: Partial<ISnippetObj>, showConfirm?: boolean) {
    const baseId = store.getState().workspace.id;
    presets.workspaceId = baseId;

    return axiosInstance
      .post(`${this.url}`, {
        name: presets.name,
        presets,
      })
      .then((res: any) => {
        const snippet = res.data.payload;

        const newAction = {
          type: UPDATE_SNIPPET,
          param: {
            delta: snippet,
            type: "add",
            id: snippet.id,
          },
        };
        store.dispatch(newAction);
        if (showConfirm) {
          notificationsApi.displayConfirmation({
            title: "Success",
            body: "Snippet was created",
          });
        }

        return snippet;
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Something went wrong",
        });
      });
  }

  copyBlockAndOpenSnippet() {
    const newId = uuidv4();
    const newState = store.getState().blocks;
    const selectedBlocks = [...newState.selectedBlocks];
    let focusId = $focusOn.value.focusBlockId;

    if (selectedBlocks.length === 0 && focusId) {
      selectedBlocks.push(focusId);
    }
    const sortedSelection = getSortedSelectedBlocks(selectedBlocks);
    const newBlocksArray: Block[] = [];
    cloneBlockBranch(
      sortedSelection,
      "",
      0,
      newId,
      ContainerTypes.SNIPPET,
      newBlocksArray
    );
    const saveActions: ActionObject[] = [];
    const context = {
      autosave: false,
      id: ContainerTypes.SNIPPET + newId,
      type: "container",
      container: {
        id: newId,
        type: ContainerTypes.SNIPPET,
      },
    } as IBlockContext;

    newBlocksArray.forEach((block) => {
      const saveAction: ActionObject = {
        id: block.id,
        delta: getBlockFromExtender(block),
        type: BlockActionTypes.insert,
        options: {
          newBlock: true,
        },
      };
      saveActions.push(saveAction);
    });
    setUpdateObjectToStore(saveActions, context);

    this.createSnippet({
      id: newId,
      name: "Untitled snippet",
    }).then(() => {
      const localChangesCopy = { ...localChangesTransaction };
      executeTransaction(
        localChangesCopy,
        () => this.navigateToSnippet(newId),
        ContainerTypes.SNIPPET + newId
      );
    });
  }

  createAndOpenSnippet() {
    const newId = uuidv4();
    this.createSnippet({
      id: newId,
      name: "Untitled snippet",
    }).then(() => {
      const baseId = store.getState().workspace.id;
      let newBlock = createNewBlock(
        {
          lineType: LineType.text,
          indentLevel: 0,
          documentRank: "0a",
        },
        {
          containerId: newId,
          containerType: ContainerTypes.SNIPPET,
          baseId,
        }
      );

      const saveActions: ActionObject[] = [];
      const context = {
        autosave: false,
        id: ContainerTypes.SNIPPET + newId,
        type: "container",
        container: {
          id: newId,
          type: ContainerTypes.SNIPPET,
        },
      } as IBlockContext;

      const saveAction: ActionObject = {
        id: newBlock.id,
        delta: newBlock,
        type: BlockActionTypes.insert,
        options: {
          newBlock: true,
        },
      };
      saveActions.push(saveAction);
      setUpdateObjectToStore(saveActions, context);

      const localChangesCopy = { ...localChangesTransaction };

      executeTransaction(
        localChangesCopy,
        () => this.navigateToSnippet(newId),
        ContainerTypes.SNIPPET + newId
      );
    });
  }

  openNewSnippetModal(presets?: Partial<ISnippetObj>) {
    const now = new Date();

    const defaultPresets: Partial<ISnippetObj> = {
      dateCreated: now,
      isShared: true,
      isDeleted: false,
    };

    const userPresets = presets ? presets : {};

    store.dispatch({
      type: SHOW_MODAL_NEW_SNIPPET,
      newSnippetContext: {
        ...defaultPresets,
        ...userPresets,
      } as Partial<ISnippetObj>,
    });
  }

  navigateToSnippet(id: string, shiftKey?: boolean) {
    const viewParams: NavigationChunk = {
      viewName: ViewNames.Detail,
      entity: {
        containerId: id,
        containerType: ContainerTypes.SNIPPET,
      },
    };

    if (shiftKey) {
      navigationApi.openSplitView(viewParams);
      return;
    }

    locationSubject.next(navigationApi.getLinkFromNavigationChunk(viewParams));
  }

  updateSnippetItems(deltas: Partial<ISnippetObj>[]) {
    const state = this.getState();
    const dict = state.dict;
    const updatedWorkItems = [];
    for (const delta of deltas) {
      if (!delta.id) {
        continue;
      }
      const snippetItem = dict[delta.id];

      const updatedSnippetItem = { ...snippetItem, ...delta };
      updatedWorkItems.push(updatedSnippetItem);
    }
    for (const delta of deltas) {
      axiosInstance.patch(`${this.url}/update`, {
        delta,
      });
    }

    this.dispatchMultipleUpdate(updatedWorkItems);
  }

  dispatchMultipleUpdate(snippets: ISnippetObj[]) {
    batch(() => {
      snippets.forEach((snippet) => {
        updateSnippetItemAction({
          type: "update",
          delta: snippet,
          id: snippet.id,
          skipInsertInList: false,
        });
      });
    });
  }

  update(delta: Partial<ISnippetObj>, items: string[]) {
    batch(() => {
      this.updateSnippetItems(
        items.map((snippetId) => {
          return { id: snippetId, ...delta };
        })
      );
    });
  }

  async insertSnippetInBlock(
    snippetId: string,
    blockId: string,
    containerContext: IBlockContext
  ) {
    const storeData = store.getState();
    let context = storeData.blocks.contexts[ContainerTypes.SNIPPET + snippetId];
    const startContextCopy = (storeData: ClarityStore) => {
      const snippetBlocksContext =
        storeData.blocks.contexts[ContainerTypes.SNIPPET + snippetId];
      const sortedSelection = snippetBlocksContext.state.rootBlocksIds;
      const newBlocksArray: Block[] = [];

      const block = storeData.blocks.dict[blockId];

      cloneBlockBranch(
        sortedSelection,
        "",
        0,
        block.containerId,
        block.containerType,
        newBlocksArray
      );

      startBlockInsert(
        "startInBlock",
        blockId,
        newBlocksArray,
        containerContext
      );
    };

    if (!context || !context.isFullContainerLoaded) {
      return await loadContainerData({
        containerId: snippetId,
        containerType: ContainerTypes.SNIPPET,
        titleBlockId: "",
        tagsBlockId: undefined,
        titleBlock: { current: null },
        outlineMode: "noOutline",
        isPublicAccess: false,
      }).then((res) => {
        const storeData = store.getState();
        startContextCopy(storeData);
      });
    }

    return startContextCopy(storeData);
  }

  deleteSnippet(id: string) {
    return axiosInstance
      .delete(this.url, { data: { id } })
      .then((res) => {
        const baseId = store.getState().workspace?.id;
        store.dispatch({
          type: REMOVE_SNIPPET_SIDEEFFECT,
          containerId: id,
          baseId,
        });
        notificationsApi.displayConfirmation({
          title: "Snippet deleted",
        });
        return res.data.payload;
      })
      .catch((err) => {
        notificationsApi.displayError({
          title: "Something went wrong",
          body: "Please try again",
        });
      });
  }
}

export const snippetsApi = new SnippetsApi();
