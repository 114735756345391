import { HomeScreenObj, HomeSectionObj, HomeSectionRow } from "./types";

export const updateSection = (homeObj: HomeScreenObj, item: HomeSectionObj) => {
  const home = {
    ...homeObj,
  };

  home.sectionsDict = { ...home.sectionsDict };
  const section = home.sectionsDict[item.id];

  if (!section) {
    home.sectionsDict[item.id] = {
      ...item,
    };
    home.sectionIds = [...home.sectionIds, item.id];
  } else {
    home.sectionsDict[item.id] = {
      ...item,
    };
  }
  return home;
};

export const updateSectionPin = (
  homeObj: HomeScreenObj,
  item: HomeSectionRow,
  sectionId: string
) => {
  const home = {
    ...homeObj,
  };

  home.sectionsDict = { ...home.sectionsDict };
  const section = home.sectionsDict[sectionId];
  if (!section) {
    return;
  }

  const updatedSection = { ...section };

  const rowIndex = section.resourcesIds.findIndex((i) => item.id === i);
  if (rowIndex !== -1) {
    updatedSection.resourcesIds = [...section.resourcesIds];
    updatedSection.resourcesIds.splice(rowIndex, 1, item.id);
    updatedSection.resourcesDict = { ...updatedSection.resourcesDict };
  } else {
    updatedSection.resourcesIds = [...updatedSection.resourcesIds, item.id];
    updatedSection.resourcesDict = { ...updatedSection.resourcesDict };
  }

  updatedSection.resourcesDict[item.id] = item;

  home.sectionsDict[sectionId] = updatedSection;
  return home;
};

export const updateSectionPinsOrder = (
  homeInput: HomeScreenObj,
  items: string[],
  sectionId: string
) => {
  const home = {
    ...homeInput,
  };

  const section = home.sectionsDict[sectionId];
  if (!section) {
    return;
  }

  const updatedSection = { ...section };
  home.sectionsDict = { ...home.sectionsDict };
  updatedSection.resourcesIds = items;
  home.sectionsDict[sectionId] = updatedSection;
  return home;
};
