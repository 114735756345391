import { useEffect, useState } from "react";
import { Alert, Form, Switch } from "antd";
import { WarningOutlined, CheckOutlined } from "@ant-design/icons";
import TabLayout from "./TabLayout";

import {
  Abilities,
  IUserObj,
  IWorkspaceObj,
  UserBaseSettings,
} from "utilities/types";
import styles from "./options/options.module.scss";
import store from "store/storeExporter";
import { useAbilityChecker } from "editor/utils/customHooks";
import { UPDATE_WORKSPACE } from "store/actions";
import notificationsApi from "clientApi/notificationsApi";
import { useSetBaseSettingHasChanges } from "store/reducers/clientReducer";
import FooterForSave from "./FooterForSave";
import { axiosInstance } from "index";

interface Props {
  base: IWorkspaceObj;
  user: IUserObj;
  userSettings: UserBaseSettings;
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

interface Status {
  message: string;
  type: "error" | "success";
}

const OptionsCycles = ({ base, userSettings, setAuthenticatedUser }: Props) => {
  const [baseSettings, setBaseSettings] = useState<IWorkspaceObj>(base);
  const [baseStatus, setBaseStatus] = useState<Status>({
    message: "",
    type: "error",
  });

  const [showCycles, setShowCycles] = useState<boolean>(base.showCycles);

  const canManageBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      "email-notification": userSettings?.settings?.emailNotifications,
    });
  }, [userSettings]);

  useEffect(() => {
    setBaseSettings(base);
  }, [base]);

  const handleSaveBaseChanges = async () => {
    try {
      const { data } = await axiosInstance.patch(`/api/workspace/profile`, {
        baseId: baseSettings.id,
        patch: [
          {
            op: "replace",
            path: "/showCycles",
            value: showCycles,
          },
        ],
      });
      if (data.status === 1) {
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Details updated successfully",
        });

        store.dispatch({
          type: UPDATE_WORKSPACE,
          params: {
            delta: { showCycles },
          },
        });
        // setActiveBase(baseSettings);
        const { data } = await axiosInstance.get("/api/user");
        setAuthenticatedUser(data.payload);
      } else {
        setBaseStatus({
          type: "error",
          message:
            "Sorry, we could not save your changes. Please try again later",
        });
      }
    } catch (e) {
      setBaseStatus({
        type: "error",
        message:
          "Sorry, we could not save your changes. Please try again later",
      });
    }
  };
  const handleReset = () => {
    setShowCycles(!showCycles);
  };

  const hasChanges = !(
    baseSettings.showCycles === showCycles || !canManageBaseSettings
  );

  useSetBaseSettingHasChanges(hasChanges, [
    baseSettings.showCycles,
    showCycles,
    canManageBaseSettings,
  ]);

  return (
    <>
      <TabLayout
        title="Sprints"
        description="Use Sprints for running tasks across multiple projects"
        actionBtn={
          <>
            <Switch
              defaultChecked={showCycles}
              checkedChildren={<CheckOutlined />}
              disabled={!canManageBaseSettings}
              checked={showCycles}
              onChange={() => {
                setShowCycles(!showCycles);
              }}
            />
          </>
        }
      ></TabLayout>
      <Form>
        <div className={styles.options}>
          {/* <Form.Item>
            <div className={styles.options_expandSwitch}>
              <Switch
                defaultChecked={baseSettings.showCycles}
                onChange={() => {
                  setShowCycles(!showCycles);
                }}
              />
            </div>
          </Form.Item> */}
          {baseStatus.type === "error" && !!baseStatus.message && (
            <Alert
              type="error"
              message={
                <div>
                  <WarningOutlined /> {baseStatus.message}
                </div>
              }
              style={{
                marginBottom: 28,
                backgroundColor: " #fcdbdc",
                borderRadius: "4px",
                border: "solid 1px #dbabac",
                color: "#723b3e",
              }}
            />
          )}
          {baseStatus.type === "success" && !!baseStatus.message && (
            <Alert
              type="success"
              message={baseStatus.message}
              style={{ marginBottom: 28 }}
            />
          )}
          {hasChanges && (
            <FooterForSave
              onSave={handleSaveBaseChanges}
              onCancel={handleReset}
            />
          )}
        </div>
      </Form>
    </>
  );
};

export default OptionsCycles;
