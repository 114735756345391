import Modal from "clarity-ui/Modal";
import TokenGateContainer from "clarity-ui/TokenGateContainer";
import { useAbilityChecker } from "editor/utils/customHooks";
import React from "react";
import { Abilities, ContainerTypes } from "utilities/types";
import TokenGateRow from "./TokenGateRow";
import styles from "./tokenGateSelector/tokenGateSelector.module.scss";

const TokenGateSelector: React.FC<{
  adding: boolean;
  setadding: React.Dispatch<React.SetStateAction<boolean>>;
  gateId?: string;
  container?: {
    containerId: string;
    containerType: ContainerTypes;
  };
  linkId?: string;
  noUpdate?: boolean;
  onSelect: (id: any) => void;
  editable?: boolean;
  onRemove?: () => void;
}> = ({
  adding,
  setadding,
  gateId,
  container,
  linkId,
  onSelect,
  noUpdate,
  editable = true,
  onRemove,
}) => {
  const canEditTokenGate = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_TOKEN_GATES,
  });

  return (
    <>
      {adding && editable && (
        <AddTokenGateModal
          onSelect={onSelect}
          setadding={setadding}
          onRemove={onRemove}
        />
      )}
      {gateId && (
        <div className={styles.gateContainer}>
          <TokenGateRow
            gateId={gateId}
            canEditTokenGate={canEditTokenGate}
            noPadding={true}
            noUpdate={noUpdate}
            showDetails={true}
            overwriteEdit={() => setadding(true)}
            container={container}
            linkId={linkId}
            editable={editable}
          />
        </div>
      )}
    </>
  );
};

export const AddTokenGateModal: React.FC<{
  onSelect: any;
  setadding: React.Dispatch<React.SetStateAction<boolean>>;
  onRemove?: () => void;
}> = ({ onSelect, setadding, onRemove }) => {
  return (
    <Modal size="large" hideModal={() => setadding(false)}>
      <TokenGateContainer onSelect={onSelect} onRemove={onRemove} />
    </Modal>
  );
};

export default TokenGateSelector;
