import styles from "./forgotPassword/forgotPassword.module.scss";
import Helmet from "react-helmet";
import ClarityLogo from "clarity-ui/ClarityLogo";
import Otp, { OtpMode } from "components/Otp";

interface Props {}

const ForgotPassword = (props: Props) => {
  return (
    <div className={styles.forgotPassworkContainer}>
      <div className={styles.forgot_password}>
        <Helmet>
          <title>{`Forgot Password • Clarity`}</title>
        </Helmet>
        <div className={styles.reset_password}>
          <Helmet>
            <title>{`Change your password • Clarity`}</title>
          </Helmet>
          <ClarityLogo
            containerStyle={({ isMobile }) => ({ marginBottom: "68px" })}
          />
          <Otp mode={OtpMode.FORGOT_PASSWORD} fullWidth={true} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
