import Icon from "@ant-design/icons";
import React from "react";

export default function TriangleIconSvg(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg viewBox="0 0 6 11" width="1em" height="1em" fill="currentColor">
      <defs>
        <path
          d="M8.12848091,5.56710937 C6.14150643,5.5633642 4.08301885,5.29841903 4.07780963,5.29841903 C3.83007019,5.2994178 3.58244639,5.38866158 3.38983375,5.56710937 C3.19722528,5.74515137 3.08635733,5.99015098 3.08186223,6.24767485 C3.07736713,6.50519872 3.17961718,6.75391832 3.36589474,6.9385738 L7.38236172,10.7365074 C7.76504751,11.1114148 8.38778901,11.1222848 8.78332576,10.7609613 L12.7747279,6.9675496 C12.9673363,6.7895076 13.0782043,6.54450799 13.0826994,6.28698412 C13.0871945,6.02946025 12.9849444,5.78074065 12.7986669,5.59608517 C12.616174,5.42027374 12.3705416,5.31909079 12.1129127,5.31356486 C10.7812745,5.48509131 9.45313056,5.56960615 8.12848091,5.56710937 Z"
          id="path-1"
        />
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icon/Disclosure-Triangle/Closed"
          transform="translate(-5.000000, -3.000000)"
        >
          <mask id="mask-2" fill="currentColor">
            <use href="#path-1" />
          </mask>
          <use
            id="Mask"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(8.082281, 8.161699) rotate(-90.000000) translate(-8.082281, -8.161699) "
            href="#path-1"
          />
        </g>
      </g>
    </svg>
  );
}
