import { socket } from "App";
import _ from "lodash";
import { RESET_CONTEXT_SNAPSHOT } from "store/actions";
import { Block } from "store/reducers/blockReducer";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store from "store/storeExporter";
import { LineType } from "utilities/lineUtilities";
import { IBlockContext, UserEventTypes } from "utilities/types";
import { BlockActionTypes } from "../blockActions";
import { getBlockFromExtender } from "../socketActionsListener";
import { ActionObject } from "./undoUtils";
import { ActionHttpType, executionQueue } from "../../../modules/queueService";
import { axiosInstance } from "index";

interface LocalTransaction {
  [id: string]: { [id: string]: ActionObject };
}

export interface SnapshotData {
  contextId: string;
  rootBlocksIds: string[];
  innerDict: { [id: string]: Block };
}

const { v4: uuidv4 } = require("uuid");

let changesPingTimer: any = null;

export const setChangesPingTimer = () => {
  changesPingTimer = setTimeout(() => {
    const storeData = store.getState();
    axiosInstance.post("/api/event", {
      eventType: UserEventTypes.BLOCK_OPERATION,
      userId: storeData.user?.id || "Anonymous",
      baseId: storeData.workspace.id,
    });
    changesPingTimer = null;
  }, 60000);
};

export let localChangesTransaction: LocalTransaction = {};
export let localChangesSnapshot: SnapshotData | null = null;
export let localChangesUUID: string = uuidv4();
export const setLocalChangesUUID = (id: string) => {
  localChangesUUID = id;
};
export const generateLocalId = () => {
  const id = uuidv4();
  setLocalChangesUUID(id);
};
export const resetLocalChanges = () => {
  localChangesTransaction = {};
  localChangesSnapshot = null;
  generateLocalId();
};
export const setLocalSnapshot = (snapshot: SnapshotData | null) => {
  localChangesSnapshot = snapshot;
};

const chunkSize = 50;
export const setUpdateObjectToStore = async (
  actions: ActionObject[],
  context: IBlockContext,
  options?: {
    saveFullBlock?: boolean;
    waitForActions?: boolean;
  }
) => {
  if (options && options.saveFullBlock) {
    const newState = store.getState().blocks;
    actions.forEach((action) => {
      const block = getBlockById(newState.dict, action.id);
      action.delta = { ...getBlockFromExtender(block), ...action.delta };
      action.type = BlockActionTypes.insert;
      action.options = { newBlock: true };
      if (localChangesTransaction[action.id]) {
        delete localChangesTransaction[action.id];
      }
    });
  }

  if (options && options.waitForActions) {
    if (!changesPingTimer) setChangesPingTimer();
    const arrays = _.chunk(actions, chunkSize);
    arrays.forEach((arrayOfUpdates, index) => {
      arrayOfUpdates.forEach((entry) => {
        if (entry.delta.blockSubject) delete entry.delta.blockSubject;
      });
      executionQueue.enqueueAction({
        url: "/api/lines/updateTransaction",
        params: {
          actions: arrayOfUpdates,
          clientId: socket.id,
        },
        type: ActionHttpType.POST,
        retries: 0,
      });
    });
    return;
  }

  if (context.autosave) {
    if (!changesPingTimer) setChangesPingTimer();
    const arrays = _.chunk(actions, chunkSize);
    arrays.forEach((arrayOfUpdates, index) => {
      arrayOfUpdates.forEach((entry) => {
        if (entry.delta.blockSubject) delete entry.delta.blockSubject;
      });
      executionQueue.enqueueAction({
        url: "/api/lines/updateTransaction",
        params: {
          actions: arrayOfUpdates,
          clientId: socket.id,
        },
        type: ActionHttpType.POST,
        retries: 0,
      });
    });
  } else {
    setLocalUpdateObject(actions, context);
  }
};

export const setLocalUpdateObject = (
  actions: ActionObject[],
  context: IBlockContext
) => {
  actions.forEach((action) => {
    if (!localChangesTransaction[context.id])
      localChangesTransaction[context.id] = {};
    if (action.type === BlockActionTypes.insert) {
      localChangesTransaction[context.id][action.id] = {
        id: action.id,
        type: action.type,
        delta: action.delta,
        options: { newBlock: true },
      };
    }
    if (action.type === BlockActionTypes.delete) {
      if (
        context.container.id === "newComment" ||
        (localChangesTransaction[context.id][action.id] &&
          localChangesTransaction[context.id][action.id].options?.newBlock)
      ) {
        delete localChangesTransaction[context.id][action.id];
      } else {
        if (localChangesTransaction[context.id][action.id])
          localChangesTransaction[context.id][action.id].type =
            BlockActionTypes.delete;
        else {
          localChangesTransaction[context.id][action.id] = {
            id: action.id,
            type: action.type,
            delta: action.delta,
          };
        }
      }
    }
    if (action.type === BlockActionTypes.update) {
      if (localChangesTransaction[context.id][action.id])
        localChangesTransaction[context.id][action.id] = {
          ...localChangesTransaction[context.id][action.id],
          delta: {
            ...localChangesTransaction[context.id][action.id].delta,
            ...action.delta,
          },
        };
      else {
        localChangesTransaction[context.id][action.id] = {
          id: action.id,
          type: action.type,
          delta: action.delta,
        };
      }
    }
  });
};

export const executeTransaction = async (
  changesTransationCopy?: LocalTransaction,
  executeAfterConfirm?: any,
  chunkId?: string
) => {
  const copyOfTransaction = changesTransationCopy
    ? changesTransationCopy
    : localChangesTransaction;

  const chunk = chunkId ? chunkId : "Work_ActivitynewComment";

  const arrayOfAction = [];
  if (!copyOfTransaction[chunk]) {
    return;
  }
  for (const item of Object.values(copyOfTransaction[chunk])) {
    if (item.delta.parentId === "") item.delta.parentId = undefined;
    if (item.delta.lineType === LineType.tagsBlock) continue;
    arrayOfAction.push(item);
  }

  try {
    const arrays = _.chunk(arrayOfAction, chunkSize);
    for (const arrayOfUpdates of arrays) {
      arrayOfUpdates.forEach((entry) => {
        if (entry.delta.blockSubject) delete entry.delta.blockSubject;
      });
      await axiosInstance.post("/api/lines/updateTransaction", {
        actions: arrayOfUpdates,
        clientId: socket.id,
      });
    }
    if (executeAfterConfirm) {
      executeAfterConfirm();
    }
  } catch (e) {
    console.log(e);
  }
};

export const clearLocalTransactionAndResetState = (workActivity: any) => {
  if (workActivity && localChangesSnapshot) {
    store.dispatch({
      type: RESET_CONTEXT_SNAPSHOT,
      param: localChangesSnapshot,
    });
  }
};
