import workApi from "clientApi/workApi";
import Button, { ButtonTypes } from "components/Button";
import { useShallowSelector } from "utilities/hooks";
import { UserAddOutlined } from "@ant-design/icons";
import { WorkTypes } from "utilities/types";
import { getNameFromWorkType } from "utilities/workUtils";
import ActionCTAContent from "./ActionCTAContent";

const RequestClaimCTA: React.FC<{ workItemId: string }> = ({ workItemId }) => {
  useShallowSelector((store) => store.work.dict[workItemId]?.requests);

  const workItemType = useShallowSelector(
    (store) => store.work.dict[workItemId]?.workType
  );

  if (!workItemType) return <></>;

  const title =
    workItemType === WorkTypes.TASK
      ? "Claim this task"
      : "Request to contribute";

  const description =
    workItemType === WorkTypes.TASK
      ? "Submit a request to become the assignee for this task"
      : ` Submit a request to become a contributor for this ${getNameFromWorkType(
          workItemType,
          false
        )}`;

  const buttonAction = (e: any) => {
    workApi.requestClaim(workItemId);
  };
  const buttonDisabled = !workApi.canRequestClaim(workItemId);

  if (buttonDisabled) return <></>;

  return (
    <>
      <ActionCTAContent
        title={title}
        description={description}
        icon={<UserAddOutlined />}
      >
        <Button
          disabled={buttonDisabled}
          onClick={buttonAction}
          style={{
            minWidth: "126px",
          }}
          buttonType={ButtonTypes.PRIMARY}
        >
          {workItemType === WorkTypes.TASK ? "Request claim" : "Submit request"}
        </Button>
      </ActionCTAContent>
    </>
  );
};

export default RequestClaimCTA;
