import { axiosInstance } from "index";
import { UPDATE_NOTE } from "store/actions";
import store from "store/storeExporter";
import { INotesObj } from "utilities/types";

class NoteApi {
  updateNoteTitle(noteId: string, title: string) {
    const note = { ...store.getState().notes.dict[noteId] };
    note.title = title;
    note.lowerCaseTitle = title.toLowerCase();
    this.updateNote(note);
    return axiosInstance.post("/api/note/title", {
      noteId: noteId,
      title: title,
    });
  }

  updateNote(note: INotesObj) {
    store.dispatch({
      type: UPDATE_NOTE,
      note: note,
    });
  }
}

export const noteApi = new NoteApi();
