// Vendor Libraries
import { connect, batch } from "react-redux";
import Helmet from "react-helmet";
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from "react";

// Internal Modules
import * as actionTypes from "../../../../../store/actions";
import {
  DocumentModes,
  IUserObj,
  ContainerTypes,
  UserEventTypes,
  IBlockContext,
} from "../../../../../utilities/types";
import { getRelatedEntities } from "modules/documentService";
import { ChunkDestination } from "utilities/stateTypes";
import { Block } from "store/reducers/blockReducer";
import { loadContainerData } from "editor/utils/containerActions/loadContainerActions";
import styles from "./note/note.module.scss";
import { $focusOn, ClarityStore } from "store/storeExporter";
import { clearBlockSelection } from "editor/utils/blockActions";
import WeeklyNoteHeaderInfo from "../../notes/WeeklyNoteHeaderInfo";
import { mixpanel } from "App";
import moment from "moment";
import _ from "lodash";
import EditorContainer from "editor/EditorContainer";
import { useContainerContextSetter } from "editor/utils/customHooks";
import { INotesDict } from "store/reducers/notesReducer";
import NoteTitle from "../../notes/NoteTitle";

interface IDocumentComponentProps {
  workspace: any;
  containerId: string;
  loading: boolean;
  isBaseMember: boolean;
  notesDict: INotesDict;
  weeklyNotesDict: INotesDict;
  weeklyNotesArray: string[];
  noteIds: string[];
  weeklyNoteId: string;
  setDocumentMentions: (document: any, mentions: any) => any;
  changeDocumentMode: (param: any) => any;
  outlineMode: string;
  sharedView: boolean;
  connected: boolean;
  paneId: ChunkDestination;
  documentComments: any;
}

interface IMapStateToProps {
  user: IUserObj | undefined;
}

function Note(props: IDocumentComponentProps & IMapStateToProps) {
  const prevId = useRef("");
  const activeId = useRef(props.containerId);
  activeId.current = props.containerId;
  const documentRef = useRef<HTMLDivElement | null>(null);
  const titleBlock = useRef<Block | null>(null);
  const componentIsMounted = useRef(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [noteContext, setNoteContext] = useState<IBlockContext>({
    id: ContainerTypes.NOTE + props.containerId,
    type: "container",
    container: {
      id: props.containerId,
      type: ContainerTypes.NOTE,
      outlineMode: props.outlineMode ? props.outlineMode : "noOutline",
    },
    ref: documentRef,
    paneId: props.paneId,
    persistToggle: props.connected && props.isBaseMember,
    autosave: props.connected && props.isBaseMember,
    online: true,
    canComment: props.connected && props.isBaseMember,
    canEdit: props.connected && props.isBaseMember,
  });

  useContainerContextSetter(noteContext, setNoteContext);

  useEffect(() => {
    return () => {
      if (
        $focusOn.value.focusContext?.id === noteContext.id &&
        $focusOn.value.focusPane === noteContext.paneId
      ) {
        $focusOn.next({ ...$focusOn.value, focusBlockId: undefined });
        clearBlockSelection();
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useLayoutEffect(() => {
    if (
      (activeId && activeId.current !== prevId.current && props.notesDict) ||
      props.connected
    ) {
      let note =
        props.notesDict?.[activeId.current] ??
        props.weeklyNotesDict?.[activeId.current];

      if (note && note.id && note.id !== "") {
        props.changeDocumentMode(DocumentModes.INSERT);
        loadContainerData({
          containerId: note.id,
          containerType: ContainerTypes.NOTE,
          titleBlockId: note.titleBlockId ? note.titleBlockId : "",
          titleBlock,
          outlineMode: note.outlineMode,
          isPublicAccess: note.isPublicAccess,
        }).then(() => {
          batch(() => {
            if (!props.isBaseMember) {
              getRelatedEntities(note.id, ContainerTypes.NOTE).then(() => {});
            }
          });
        });
        mixpanel.track(UserEventTypes.NOTE_VIEWED, {
          distinct_id: props.user?.id,
        });
      }
    }
    prevId.current = activeId.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerId, props.connected]);

  return useMemo(() => {
    if (!props.notesDict || !props.weeklyNotesDict || !props.containerId) {
      return <></>;
    } else {
      return (
        <>
          <div className="document-wrapper container-860">
            <div className="document-container-wrapper">
              <div
                className="document-container"
                style={{ flexDirection: "column" }}
              >
                <Helmet>
                  <title>{` Doc • ${props.workspace.name}`}</title>
                </Helmet>

                {props.weeklyNoteId &&
                  props.weeklyNotesDict &&
                  props.weeklyNotesDict?.[props.containerId] && (
                    <div className={styles.NoteDetail__header}>
                      <div className={styles.NoteDetail__header__weeklyHeader}>
                        <WeeklyNoteHeaderInfo
                          note={props.weeklyNotesDict?.[props.containerId]}
                          weeklyNotes={_.uniqBy(
                            [
                              moment
                                .utc(
                                  props.weeklyNotesDict[props.containerId]
                                    .dateCreated
                                )
                                .isSameOrAfter(
                                  moment.utc(
                                    props.weeklyNotesDict?.[props.weeklyNoteId]
                                      .dateCreated
                                  )
                                )
                                ? props.weeklyNotesDict?.[props.containerId]
                                : undefined,
                              ...props.weeklyNotesArray
                                .map((id: string) => props.weeklyNotesDict[id])
                                .filter((note: any) => {
                                  return moment
                                    .utc(note.dateCreated)
                                    .isSameOrBefore(
                                      moment.utc(
                                        props.weeklyNotesDict[
                                          props.weeklyNoteId
                                        ].dateCreated
                                      )
                                    );
                                }),
                            ].filter((note) => note),
                            "id"
                          )}
                          splitView={
                            props.paneId === ChunkDestination.secondary
                          }
                          baseId={props.workspace.id}
                        />
                      </div>
                    </div>
                  )}
                <div
                  className={`document select-area ${styles.NoteDetail__body}`}
                  ref={documentRef}
                >
                  <NoteTitle
                    noteId={props.containerId}
                    customClassName="titleBlockContainer"
                  />
                  <EditorContainer
                    context={noteContext}
                    isBaseMember={props.isBaseMember}
                    outlineMode={props.outlineMode}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.containerId,
    // isLoading,
    props.outlineMode,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.notesDict?.[props.containerId],
    props.weeklyNotesDict?.[props.containerId],
    props.workspace.weeklyNotesArray,
    noteContext,
  ]);
}

const mapStateToProps = (state: ClarityStore, ownProps: any) => {
  let docState = {
    workspace: state.workspace,
    weeklyNotesArray: state.notes.weeklyNoteIds,
    noteIds: state.notes.ids,
    weeklyNoteId: state.notes.weeklyNoteId,
    notesDict: state.notes.dict,
    weeklyNotesDict: state.notes.weeklyNotesDict,
    user: state.user,
    isBaseMember: state.client.isBaseMember,
    connected: state.network.connected,
    outlineMode:
      state.blocks.contexts[ContainerTypes.NOTE + ownProps.containerId]
        ?.outlineMode,
  };
  return docState;
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    changeDocumentMode: (param: any) =>
      dispatch({
        type: actionTypes.CHANGE_DOCUMENT_MODE,
        param,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Note);
