import { Menu, MenuItem } from "components/FabBtn";
import styles from "./sharingAndPermsModal.module.scss";
import { CommentOutlined, UserOutlined, LockFilled } from "@ant-design/icons";
import ChevronDown from "icons/chevron-down.svg";
import { useEffect, useState } from "react";
import { IMenuItem, visibilityData } from "./util";

import { useEntityUpdater } from "./useEntityUpdater";
import { ContainerVisibility } from "utilities/types";

const ACESSDISPLAYNAMES: any = {
  [ContainerVisibility.canComment]: "Commenter",
  [ContainerVisibility.canView]: "Viewer",
  [ContainerVisibility.canEdit]: "Editor",
};

const PUBLICACCESS = [
  {
    title: ACESSDISPLAYNAMES[ContainerVisibility.canEdit],
    description: "Can edit",
    icon: <UserOutlined />,
    key: ContainerVisibility.canEdit,
  },
  {
    title: ACESSDISPLAYNAMES[ContainerVisibility.canComment],
    description: "Create & reply to comments",
    icon: <CommentOutlined />,
    key: ContainerVisibility.canComment,
  },
  {
    title: ACESSDISPLAYNAMES[ContainerVisibility.canView],
    description: "Read and click only",
    icon: <UserOutlined />,
    key: ContainerVisibility.canView,
  },
];

export function AnonymousDropDownComponent({ readOnly, editable }: any) {
  const updateWorkItem = useEntityUpdater();
  const [intialValue, setIntialValue] = useState<ContainerVisibility>(
    ContainerVisibility.canView
  );
  const getPermUpdater = (key: ContainerVisibility) => {
    updateWorkItem(key);
    visibilityData.next(key);
    closeMenu();
  };

  useEffect(() => {
    const visibilitySub = visibilityData.subscribe((data) => {
      setIntialValue(data);
    });
    return () => visibilitySub?.unsubscribe();
  }, []);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  return (
    <div className={styles.permissionsDropdownContainer}>
      <div
        className={styles.permissionsDropdownTrigger}
        onClick={() => (readOnly || !editable ? null : setMenuIsOpen(true))}
      >
        <span className="small primary"> {ACESSDISPLAYNAMES[intialValue]}</span>
        {readOnly || !editable ? (
          <LockFilled />
        ) : (
          <img src={ChevronDown} alt="chevron pointing down" />
        )}
      </div>
      {menuIsOpen && (
        <Menu
          close={closeMenu}
          yDir="down"
          xDir="left"
          className={styles.permissionsDropdownMenu}
        >
          {PUBLICACCESS?.map((item: IMenuItem) => (
            <MenuItem
              key={item.key}
              title={item.title}
              description={item.description}
              icon={item.icon}
              onClick={() => getPermUpdater(item.key)}
            />
          ))}
        </Menu>
      )}
    </div>
  );
}
