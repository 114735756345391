import React, { useContext, useEffect, useRef, useState } from "react";
import { CommandPaletteContext } from "utilities/types";
import styles from "../commandPalette.module.scss";
import { renderPlaceholder } from "../helpers/actions";
import { CommandPaletteState } from "../helpers/types";

const PaletteTextSearch: React.FC<{ context: CommandPaletteContext }> = ({
  context,
}) => {
  const paletteStateContext = useContext(CommandPaletteState);
  const queryInput = useRef<HTMLInputElement | null>(null);
  const [query, setQuery] = useState("");

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
  };

  useEffect(() => {
    setQuery("");
    queryInput.current?.focus();
  }, [context]);

  useEffect(() => {
    paletteStateContext.searchText.next(query);
  }, [query]);

  return (
    <div className={styles.queryContainer}>
      <input
        type="text"
        value={query}
        onChange={handleQueryChange}
        placeholder={renderPlaceholder(context)}
        ref={queryInput}
      />
    </div>
  );
};

export default PaletteTextSearch;
