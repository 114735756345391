import { appClipboardData, clearappClipboardData, socket } from "App";
import { BlockPropsWithRef } from "editor/blockContainer/BlockSplitter";
import { createNewBlock } from "modules/entityService";
import { batch } from "react-redux";
import { BehaviorSubject } from "rxjs";
import { Block, FocusObject, FocusType } from "store/reducers/blockReducer";
import {
  getBlockById,
  getCurrentContext,
  getNextSibling,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $documentMode, $focusOn, prevState } from "store/storeExporter";
import { getRankBetween } from "utilities/containerRankHelpers";
import {
  entityKeepTypes,
  LineType,
  LineValueType,
} from "utilities/lineUtilities";
import { htmlParser, pasteParser } from "utilities/pasteUtil";
import {
  CitationData,
  ContainerTypes,
  DocumentModes,
  IBlockContext,
} from "utilities/types";
import { addBlock, ADD_BLOCK_TYPES } from "./addBlockActions";
import { batchActions } from "redux-batched-actions";
import {
  ADD_BLOCK,
  ADD_CITATION,
  ADD_RESOURCE,
  PASS_RESOURCE_TO_UPLOAD,
  REFOCUS,
  SAVE_BLOCK_DATA,
  SET_UNDO,
  UPDATE_CURRENT_MONTHLY_COUNT,
} from "store/actions";
import { createCitation } from "utilities/createNewEntity";
import {
  BlockActionTypes,
  getAntiAction,
  updateBlockText,
} from "../blockActions";
import {
  ActionObject,
  ActionWrapperObject,
  createActionWrapperObject,
} from "./undoUtils";
import { cloneBlock } from "./cloneBlockActions";
import { getBlockFromExtender } from "../socketActionsListener";
import linkifyHtml from "linkifyjs/html";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { pasteNodeCreator } from "utilities/pasteNodeCreator";
import { BlockProps } from "editor/blockContainer/Block";
import { changeSingleBlockType } from "./blockTypesActions";
import React from "react";
import { setUpdateObjectToStore } from "./persistActions";
import { handleUpdateAction } from "../primitiveActions/primitiveActions";
import { removeEmptyNodes } from "./textUpdateUtils";
import { checkIfCannotAddBlocks } from "modules/appService";
import { checkCaretPosition, setNewBlockValue } from "../caretUtils";
import { breakDownHtml } from "../blockValueHelpers";
import { getCorrectLink } from "utilities/linkUtilities";
import { axiosInstance } from "index";
const linkifyTest = require("linkifyjs");
const { v4: uuidv4 } = require("uuid");

export const handleBlockPaste = (
  e: React.ClipboardEvent<HTMLDivElement>,
  props: BlockPropsWithRef
) => {
  if (!props.context.canEdit) {
    e.preventDefault();
    e.stopPropagation();
    return;
  }
  if (
    e.clipboardData.files.length === 1 &&
    JSON.stringify(e.clipboardData.types) !==
      JSON.stringify(["text/plain", "text/html", "text/rtf", "Files"])
  ) {
    return;
  }
  e.preventDefault();
  e.stopPropagation();
  const textData = e.clipboardData.getData("text/plain");
  const isClarityCitation = e.clipboardData.getData("clarity-citation-copy");

  if (
    !isClarityCitation ||
    !appClipboardData ||
    !appClipboardData.sourceContainer
  ) {
    clearappClipboardData();
  } else {
    const innerHTML = e.clipboardData.getData("text/html");

    const multiBlock = appClipboardData.selectedBlocks?.length > 0;
    if (multiBlock) {
      let type: "startAfterBlock" | "startInBlock" = "startAfterBlock";
      if (
        props.blockRef.current?.innerText === "" &&
        !entityKeepTypes.includes(props.blockData.lineType) &&
        $documentMode.value === DocumentModes.INSERT
      ) {
        type = "startInBlock";
      }
      handleMultiBlockCitationPaste(
        props,
        appClipboardData.selectedBlocks,
        type
      );
      return;
    }
    const isNotion = e.clipboardData.types.includes(
      "text/_notion-blocks-v2-production"
    );
    const isWord =
      JSON.stringify(e.clipboardData.types) ===
        JSON.stringify(["text/plain", "text/html", "text/rtf", "Files"]) ||
      JSON.stringify(e.clipboardData.types) ===
        JSON.stringify(["text/plain", "text/html", "text/rtf"]);
    const parsedHtml = htmlParser(innerHTML, isNotion, isWord);
    handleCitationPaste(props, parsedHtml.innerHTML);
    return;
  }

  e.stopPropagation();

  let newBlocks: any[] = [];
  newBlocks = pasteParser(e.clipboardData, props.blockData.lineType) ?? [];

  e.preventDefault();
  e.stopPropagation();

  if (
    newBlocks.length > 1 ||
    $documentMode.value === DocumentModes.BLOCK ||
    e.clipboardData.types.includes("clarity/blocks")
  ) {
    const preventBlockAdd = checkIfCannotAddBlocks();
    if (preventBlockAdd) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    batch(() => {
      let type: "startAfterBlock" | "startInBlock" = "startAfterBlock";
      if (
        props.blockRef.current?.innerText === "" &&
        !entityKeepTypes.includes(props.blockData.lineType) &&
        $documentMode.value === DocumentModes.INSERT
      ) {
        type = "startInBlock";
      }
      pasteBlocks(type, newBlocks, props.blockData.id, props.context);
    });
    return;
  } else {
    handleNormalPaste(props, textData, newBlocks);
  }
};

const handleNormalPaste = (
  props: BlockPropsWithRef,
  textData: string,
  newBlocks: any[]
) => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const caret = selection.getRangeAt(0);
    if (caret.toString().length > 0) {
      const test = linkifyTest.find(textData);
      if (test.length === 1) {
        const entry = test[0];
        const contents = caret.cloneContents();
        contents.childNodes.forEach((child) => {
          if (child.nodeType === 3) {
            const newChild = document.createElement("a");
            newChild.classList.add("decorated");
            newChild.href = entry.href;
            newChild.textContent = child.textContent;
            contents.replaceChild(newChild, child);
          }
        });
        caret.deleteContents();
        caret.insertNode(contents);
        const eventCopy = {
          target: props.blockRef.current,
          currentTarget: props.blockRef.current,
        };
        updateBlockText(props.blockData, eventCopy, props.context);
        return;
      }
    }
    if (!newBlocks[0]) return;
    caret.deleteContents();
    let previousElementIsSpace = false;
    const checkCaret = checkCaretPosition(props.blockRef.current);
    if (checkCaret === 0) previousElementIsSpace = true;
    else {
      const lastChar = props.blockRef.current?.innerText[checkCaret - 1];
      if (lastChar === " ") previousElementIsSpace = true;
    }
    const textNode1 = document.createTextNode(" ");
    caret.insertNode(textNode1);
    caret.setStartAfter(textNode1);
    caret.setEndAfter(textNode1);
    selection.removeAllRanges();
    const range = new Range();
    let lastEl: any = null;

    newBlocks[0]?.lineValues.forEach(
      (
        lineValue: {
          name: any;
          value: any;
          lineValues: any[];
          link?: string;
          htmlElement?: HTMLElement;
          children: any[];
        },
        index: number
      ) => {
        if (lineValue.name === "#text") {
          const textNode = document.createTextNode(lineValue.value);
          caret.insertNode(textNode);
          caret.setEndAfter(textNode);
          if (index === 0) lastEl = textNode;
        } else if (lineValue.name === "A") {
          const node = document.createElement(lineValue.name);
          node.setAttribute("href", lineValue.link);
          if (lineValue.value !== null) {
            const textNode = document.createTextNode(lineValue.value);
            node.appendChild(textNode);
          }
          pasteNodeCreator(node, lineValue.children, document);
          caret.insertNode(node);
          caret.setEndAfter(node);
          if (index === 0) lastEl = node;
        } else if (lineValue.name === "MENTION") {
          if (lineValue.htmlElement) {
            caret.insertNode(lineValue.htmlElement);
            caret.setEndAfter(lineValue.htmlElement);
            if (index === 0) lastEl = lineValue.htmlElement;
          }
        } else {
          const node = document.createElement(lineValue.name);
          if (lineValue.value !== null) {
            const textNode = document.createTextNode(lineValue.value);
            node.appendChild(textNode);
          }
          pasteNodeCreator(node, lineValue.children, document);
          caret.insertNode(node);
          caret.setEndAfter(node);
          if (index === 0) lastEl = node;
        }
      }
    );
    let hasLinks = false;
    if (lastEl !== null) {
      range.setStartAfter(lastEl);
      range.setEndAfter(lastEl);
      hasLinks = linkifyTest.find(lastEl.textContent).length > 0;
    }

    if (!hasLinks || previousElementIsSpace) textNode1.remove();
    const textNode = document.createTextNode(" ");
    range.collapse();
    range.insertNode(textNode);
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);
    selection.addRange(range);
    if (!hasLinks) textNode.remove();
    const caretPosition = checkCaretPosition(props.blockRef.current);
    const eventCopy = {
      target: props.blockRef.current,
      currentTarget: props.blockRef.current,
    };
    updateBlockText(props.blockData, eventCopy, props.context);
    setTimeout(() => {
      const element = props.blockRef.current;
      if (!element) return;
      element.innerHTML = linkifyHtml(element.innerHTML, {
        defaultProtocol: "https",
        validate: true,
      });
      moveCaretToPreviousPosition(element, caretPosition);
      setNewBlockValue(props.blockRef.current);
      updateBlockText(props.blockData, eventCopy, props.context);
      if (props.blockRef.current)
        moveCaretToPreviousPosition(props.blockRef.current, caretPosition);
    }, 1);
  }
};

const handleMultiBlockCitationPaste = (
  props: BlockPropsWithRef,
  selectedBlocks: string[],
  type: "startInBlock" | "startAfterBlock"
) => {
  const newBlocksArray: Block[] = [];
  cloneBlockBranchAsCitation(
    selectedBlocks,
    props.blockData.parentId,
    props.blockData.indentLevel,
    props.blockData.containerId,
    props.blockData.containerType,
    newBlocksArray
  );
  startBlockInsert(type, props.blockData.id, newBlocksArray, props.context);
};

const cloneBlockBranchAsCitation = (
  idsList: string[],
  parentId: string,
  indentLevel: number,
  containerId: string,
  containerType: ContainerTypes,
  newBlocksArray: Block[]
) => {
  const newState = store.getState().blocks;
  idsList.forEach((blockId: string) => {
    const block = getBlockById(newState.dict, blockId);
    if (entityKeepTypes.includes(block.lineType)) return;
    const newBlock = cloneBlock(block, {
      indentLevel,
      parentId,
      containerId: containerId,
      containerType: containerType,
    });
    const citation = createCitation(
      {
        referencingBlockId: newBlock.id,
        sourceBlockId: block.id,
        dateAccessed: appClipboardData.copyDate,
        dateModified: appClipboardData.copyDate,
        referencingContainerId: newBlock.containerId,
        sourceBlockBaseId: appClipboardData.sourceBlockBaseId,
        sourceContainerId: appClipboardData.sourceContainer.containerId,
        sourceContanierType: appClipboardData.sourceContainer.containerType,
        referencingContainerType: block.containerType,
        synchronize: appClipboardData.sync,
        dateCreated: appClipboardData.copyDate,
      },
      newBlock.value
    );
    store.dispatch({
      type: ADD_CITATION,
      param: {
        citation,
      },
    });
    newBlock.value = [
      {
        type: LineValueType.citation,
        children: [...newBlock.value],
        options: {
          sourceId: citation.sourceBlockId,
          citationId: citation.citationId,
        },
        value: "",
      },
    ];
    newBlocksArray.push(newBlock);
    if (block.children && block.children.length > 0) {
      cloneBlockBranchAsCitation(
        block.children,
        newBlock.id,
        indentLevel + 1,
        containerId,
        containerType,
        newBlocksArray
      );
    }
  });
};

const pasteBlocks = (
  type: "startInBlock" | "startAfterBlock",
  newBlocks: any,
  refBlockId: string,
  context: any
) => {
  const newState = store.getState().blocks;
  const refBlock = getBlockById(newState.dict, refBlockId);

  const containerData = {
    containerId: refBlock.containerId,
    containerType: refBlock.containerType,
    baseId: refBlock.baseId,
  };

  const createdDate = new Date();

  const presetData: Partial<Block> = {
    createdBy: prevState.value.user?.id,
    dateCreated: createdDate,
    modifiedBy: prevState.value.user?.id,
    dateModified: createdDate,
    textDateModified: createdDate,
    textModifiedBy: prevState.value.user?.id,
  };

  const newBlocksArray: Block[] = [];

  batch(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [idx, pastedBlock] of newBlocks.entries()) {
      const lineValuesTest = pastedBlock.lineValues
        .reverse()
        .flatMap((lineValue: any) => {
          if (lineValue.htmlElement !== undefined) {
            return breakDownHtml(lineValue.htmlElement);
          } else {
            return [lineValue];
          }
        });

      let specificBlockPresets: Partial<Block> = {
        id: pastedBlock.uuid,
        indentLevel: refBlock.indentLevel + pastedBlock.indentLevel,
        parentId:
          idx === 0
            ? refBlock.parentId
            : pastedBlock.parentId
            ? pastedBlock.parentId
            : refBlock.parentId,
        lineType: pastedBlock.lineType,
        value: lineValuesTest,
        subLinesList: pastedBlock.subLinesList,
        options: {
          embedData: {
            src: pastedBlock.src,
          },
        },
        referencingContainerId: pastedBlock.referencingContainerId,
        referencingContainerType: pastedBlock.referencingContainerType,
        checkboxStatus: pastedBlock.checkboxStatus,
        documentRank: pastedBlock.documentRank,
        ...presetData,
      };
      const newBlock = createNewBlock(specificBlockPresets, containerData);
      const fullBlock: Block = {
        ...newBlock,
        blockSubject: new BehaviorSubject<Partial<Block>>({}),
        children: [],
      };
      newBlocksArray.push(fullBlock);
    }
  });
  startBlockInsert(type, refBlockId, newBlocksArray, context);
};

const handleCitationPaste = (props: BlockPropsWithRef, innerHTML: string) => {
  if (true) {
    const element = document.createElement("div");
    element.innerHTML = innerHTML;
    removeEmptyNodes(element);
    innerHTML = element.innerHTML;

    const newCitation = createCitation(
      {
        referencingBlockId: props.blockData.id,
        sourceBlockId: appClipboardData.sourceBlockId,
        dateAccessed: appClipboardData.copyDate,
        dateModified: appClipboardData.copyDate,
        referencingContainerId: props.blockData.containerId,
        sourceBlockBaseId: appClipboardData.sourceBlockBaseId,
        sourceContainerId: appClipboardData.sourceContainer.containerId,
        sourceContanierType: appClipboardData.sourceContainer.containerType,
        referencingContainerType: props.blockData.containerType,
        synchronize: appClipboardData.sync,
        dateCreated: appClipboardData.copyDate,
      },
      appClipboardData.value
    );

    store.dispatch({
      type: ADD_CITATION,
      param: {
        citation: newCitation,
      },
    });

    const citationNode = createCitationNode(newCitation, innerHTML);
    const textNode = document.createTextNode(" ");
    const selection = document.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.insertNode(textNode);
      range.insertNode(citationNode);
      const newRange = range.cloneRange();
      newRange.setStartAfter(textNode);
      newRange.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(newRange);
      const newEvent = {
        currentTarget: props.blockRef.current,
      };
      updateBlockText(props.blockData, newEvent, props.context);
    }
  }
};

const createCitationNode = (
  newCitation: CitationData,
  innerHTML: string
): HTMLElement => {
  const citationData: CitationData = newCitation;

  const citation = document.createElement("citation");
  let generatedLink = citationData.containerId;
  if (citationData.containerType === ContainerTypes.DOCUMENT) {
    generatedLink = `/${citationData.sourceBlockBaseId}/tags/${citationData.containerId}`;
  }
  if (citationData.containerType === ContainerTypes.PROJECT) {
    generatedLink = getCorrectLink(
      { slug: citationData.sourceBlockBaseId },
      {
        type: appClipboardData.sourceContainer.type,
        projectId: appClipboardData.sourceContainer.projectId,
      }
    );
  }
  if (citationData.containerType === ContainerTypes.NOTE) {
    generatedLink = `/${citationData.sourceBlockBaseId}/docs/${appClipboardData.sourceContainer.projectId}`;
  }
  citation.setAttribute("href", generatedLink);
  citation.setAttribute("data-citation-id", citationData.citationId);
  citation.setAttribute("data-source-id", citationData.sourceBlockId);
  citation.innerHTML = innerHTML;

  searchForInnerCitationPlaceholder(citation, newCitation);

  return citation;
};

const searchForInnerCitationPlaceholder = (
  citation: Element,
  newCitation: CitationData
) => {
  Array.from(citation.children).forEach((child: Element) => {
    if (child.nodeName === LineValueType.citationPlaceholder) {
      const sourceId = child.getAttribute("data-source-id");
      if (sourceId === newCitation.referencingBlockId) {
        child.setAttribute("data-iteration-stop", "true");
        child.innerHTML = "Ø";
      }
    }
    searchForInnerCitationPlaceholder(child, newCitation);
  });
};

export const startBlockInsert = (
  type: "startInBlock" | "startAfterBlock",
  refBlockId: string,
  newBlocks: any,
  context: IBlockContext
) => {
  const newState = store.getState().blocks;
  const refBlock = getBlockById(newState.dict, refBlockId);
  const currentContext = getCurrentContext(
    newState,
    refBlock.containerType + refBlock.containerId
  );
  let nextRank: string | undefined = undefined;
  let prevRank = refBlock.documentRank;

  const nextBlockId = getNextSibling(newState, refBlock, currentContext);
  if (nextBlockId) {
    const nextBlock = getBlockById(newState.dict, nextBlockId);
    nextRank = nextBlock.documentRank;
  }

  let lastBlock = refBlock;
  let focusId = refBlock.id;
  let insertedBlocks: any = {
    "": [],
  };

  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(context);
  let blockAddDelta = 0;

  const newValue: FocusObject = {
    ...$focusOn.value,
    focusBlockId: lastBlock.id,
    type: FocusType.withDiff,
    diff: 0,
  };

  let skippedId: string | null = null;

  if (refBlock.parentId) insertedBlocks[refBlock.parentId] = [refBlock.id];

  batch(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [idx, block] of newBlocks.entries()) {
      // block.containerId = refBlock.containerId;
      // block.containerType = refBlock.containerType;
      block.baseId = refBlock.baseId;
      block.children = [];
      if (
        idx === 0 &&
        type === "startInBlock"
        // block.lineType === refBlock.lineType
      ) {
        skippedId = block.id;
        block.id = refBlock.id;

        const delta = {
          lineType: block.lineType,
          checkboxStatus: block.checkboxStatus,
          options: block.options,
          referencingContainerId: block.referencingContainerId,
          referencingContainerType: block.referencingContainerType,
          value: block.value,
          isFolded: block.isFolded,
          subLinesList: block.subLinesList,
        };

        const action: ActionObject = {
          id: refBlock.id,
          delta: {
            ...delta,
          },
          type: BlockActionTypes.update,
        };
        reduxActions.push({
          type: SAVE_BLOCK_DATA,
          param: {
            id: refBlock.id,
            blockData: refBlock,
            delta: {
              value: block.value,
            },
          },
        });
        if (!insertedBlocks[block.parentId])
          insertedBlocks[block.parentId] = [];
        insertedBlocks[block.parentId].push(block.id);
        const antiAction = getAntiAction(action);
        const saveAction = { ...action };
        saveAction.delta = { ...action.delta };
        delete saveAction.delta.options;
        saveActions.push(saveAction);
        undoObj.actions.push(antiAction);
        handleUpdateAction(action);
        // TOdo if video or loom create resource
        if (["Video", "Loom"].includes(block.lineType)) {
          const uuid = uuidv4();
          const resource = {
            name: "",
            type: block.lineType === "Video" ? "Video" : "Loom",
            url: block.options.embedData.src,
            caption: "",
            id: uuid,
          };
          store.dispatch({
            type: ADD_RESOURCE,
            resource: resource,
          });
          const action = {
            id: refBlock.id,
            delta: {
              referencingContainerType: ContainerTypes.RESOURCE,
              referencingContainerId: uuid,
            },
            type: BlockActionTypes.update,
          };
          handleUpdateAction({
            id: refBlock.id,
            delta: {
              referencingContainerType: ContainerTypes.RESOURCE,
              referencingContainerId: uuid,
            },
            type: BlockActionTypes.update,
          });
          updateResource(resource);
          saveActions.push(action);
        }
        continue;
      }

      if (block.parentId === skippedId) {
        block.parentId = refBlock.id;
        block.indentLevel = refBlock.indentLevel + 1;
      } else {
        if (block.parentId) {
          const parentBlock = insertedBlocks[block.parentId];
          if (parentBlock?.indentLevel)
            block.indentLevel = refBlock.indentLevel + 1;
        } else block.indentLevel = 0;
      }

      block.documentRank = getRankBetween(prevRank, nextRank);
      if (!insertedBlocks[block.parentId]) insertedBlocks[block.parentId] = [];
      const insertionArray = insertedBlocks[block.parentId];
      let lastId = insertionArray[insertionArray.length - 1];

      if (lastId) {
        blockAddDelta++;
        reduxActions.push({
          type: ADD_BLOCK,
          param: {
            type: ADD_BLOCK_TYPES.addBlockAfter,
            referencePoint: lastId,
            block: block,
            context: currentContext,
          },
        });
      } else {
        if (block.parentId === "") {
          blockAddDelta++;
          reduxActions.push({
            type: ADD_BLOCK,
            param: {
              type: ADD_BLOCK_TYPES.addBlockAfter,
              referencePoint: lastBlock.id,
              block: block,
              context: currentContext,
            },
          });
        } else {
          blockAddDelta++;
          reduxActions.push({
            type: ADD_BLOCK,
            param: {
              type: ADD_BLOCK_TYPES.addChildBlock,
              referencePoint: block.parentId,
              block: block,
              context: currentContext,
            },
          });
        }
      } // TOdo if video or loom create resource

      if (["Video", "Loom"].includes(block.lineType)) {
        const uuid = uuidv4();
        const resource = {
          name: "",
          type: block.lineType === "Video" ? "Video" : "Loom",
          url: block.options.embedData.src,
          caption: "",
          id: uuid,
        };
        store.dispatch({
          type: ADD_RESOURCE,
          resource: resource,
        });

        block.referencingContainerType = ContainerTypes.RESOURCE;
        block.referencingContainerId = uuid;
        updateResource(resource);
        // handleUpdateAction(action);
      }
      const action = {
        id: block.id,
        delta: {
          ...getBlockFromExtender(block),
        },
        type: BlockActionTypes.insert,
        options: {
          newBlock: true,
        },
      };
      const antiAction = getAntiAction(action);
      saveActions.push(action);
      undoObj.actions.push(antiAction);
      insertedBlocks[block.parentId].push(block.id);
      focusId = block.id;
      prevRank = block.documentRank;
    }

    newValue.focusBlockId = focusId;

    reduxActions.push({
      type: REFOCUS,
      param: {
        newFocus: newValue,
      },
    });
    reduxActions.push({
      type: UPDATE_CURRENT_MONTHLY_COUNT,
      param: {
        delta: blockAddDelta,
      },
    });
    store.dispatch({
      type: SET_UNDO,
      param: {
        context: context,
        contextId: context.id,
        undoObject: undoObj,
      },
    });

    setUpdateObjectToStore(saveActions, context);
    store.dispatch(batchActions(reduxActions));
  });
};

const updateResource = (resource: any) => {
  axiosInstance
    .post(`/api/resource/`, {
      ...resource,
      baseId: store.getState().workspace.id,
      clientId: socket.id,
    })
    .then((res) => {
      batch(() => {
        store.dispatch({
          type: ADD_RESOURCE,
          resource: res.data.payload,
        });
      });
    });
};

export const checkImageDrop = (
  event: any,
  blockRef: React.MutableRefObject<HTMLDivElement | null>,
  dataObject: DataTransfer,
  props: BlockProps,
  sameBlock?: boolean
) => {
  const fileData = dataObject.getData("Files");
  const files = Array.from(dataObject.files);
  const htmlData = dataObject.getData("text/html");
  const element = document.createElement("span");
  element.innerHTML = dataObject.getData("text/html");
  let imgUrl: any;
  Array.from(element.children).forEach((child) => {
    if (child.nodeName === "IMG") imgUrl = child.getAttribute("src");
  });

  let newId = "";

  if (files.length > 0) {
    event.stopPropagation();
    event.preventDefault();
  }

  batch(() => {
    if (!sameBlock) {
      newId = uuidv4();
      addBlock({
        context: props.context,
        currentBlock: props.blockData,
        focus: "newBlock",
        newBlockValue: [],
        type: ADD_BLOCK_TYPES.addBlockAfter,
        presetData: {
          id: newId,
          lineType: LineType.image,
          options: {
            clipboardData: dataObject,
            imgUrl,
            fileData,
            htmlData,
            files,
          },
        },
      });
    } else {
      changeSingleBlockType(
        props.blockData.id,
        LineType.image,
        props.context,
        blockRef
      );
    }
    store.dispatch({
      type: PASS_RESOURCE_TO_UPLOAD,
      param: {
        blockId: sameBlock ? props.blockData.id : newId,
        clipboardData: dataObject,
        imgUrl,
        fileData,
        htmlData,
        files,
      },
    });
  });
};
