import React, { memo, useState, useEffect } from "react";
import { useStore } from "react-redux";
import PopupCard from "./PopupCard";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import { getHtml } from "editor/utils/blockValueHelpers";
import { axiosInstance } from "index";

const TagPopup: React.FC<any> = (props: any) => {
  const state = useStore();
  const [citationContainerData, setcitationContainerData] = useState<any>(null);
  let blockData;
  if (props.blockId) {
    const newState = state.getState().blocks;
    blockData = getBlockById(newState.dict, props.blockId);
  }

  useEffect(() => {
    if (props.documentId) {
      axiosInstance
        .get(`/api/document/getDocumentAndFirstBlock`, {
          params: {
            id: props.documentId,
          },
        })
        .then((res) => {
          setcitationContainerData(res.data);
        });
    }

    return () => {
      setcitationContainerData(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.citationId]);

  if (
    !citationContainerData ||
    (!blockData && !props.blockId?.includes("title"))
  )
    return <PopupCard loading={true}></PopupCard>;
  return (
    <div>
      <PopupCard
        blockData={blockData}
        documentId={props.documentId}
        citationData={null}
        focusElement={props.focusElement}
        cardData={{
          title: getTitle(citationContainerData),
          firstBlock: getHtml(
            citationContainerData?.block
              ? citationContainerData?.block.value
              : []
          ),
          link: getLink(citationContainerData),
          citationData: null,
        }}
        destination={props.destination}
      ></PopupCard>
    </div>
  );
};

const getTitle = (citationContainerData: any) => {
  return getHtml(citationContainerData?.container.nameValue);
};

const getLink = (citationContainerData: any) => {
  return `/${citationContainerData?.container.workspace.slug}/tags/${citationContainerData?.container.id}`;
};

const equalComp = (prevProps: any, nextProps: any) => {
  return prevProps.citationId === nextProps.citationId;
};

export default memo(TagPopup, equalComp);
