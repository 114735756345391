import Modal from "clarity-ui/Modal";
import UserList from "clarity-ui/UserList";
import Button, { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { Abilities } from "utilities/types";
import { useInvitationsModalSetter } from "../../settings/members/ModalInvitations";
import styles from "../groupMembers/groupMembers.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import { VALUE_ESCAPE } from "keycode-js";

const BaseMembersModal: React.FC<{
  isVisible: boolean;
  setisVisible: (isVisible: boolean) => void;
}> = ({ isVisible, setisVisible }) => {
  if (!isVisible) return <></>;
  else return <BaseMembersModalContent setisVisible={setisVisible} />;
};

const BaseMembersModalContent: React.FC<{
  setisVisible: (isVisible: boolean) => void;
}> = ({ setisVisible }) => {
  const stateCollaboratorsIds = useShallowSelector(
    (store) => store.members.ids
  );
  const membersDict = useShallowSelector((store) => store.members.dict);
  const [members, setmembers] = useState<string[]>([]);
  const canManageMembers = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MEMBERS,
  });

  const { showInvitationsModal } = useInvitationsModalSetter();

  useLayoutEffect(() => {
    const guestRole = Object.values(store.getState().roles.dict).find(
      (role) => role.roleName === "Guest"
    );
    const collaborators = stateCollaboratorsIds.filter((id) => {
      const user = membersDict[id];
      if (guestRole) return !user?.roleIds?.includes(guestRole?.id);
      return true;
    });

    collaborators.sort((a: any, b: any) => {
      const userA = membersDict[a];
      const userB = membersDict[b];

      if (userA.username < userB.username) return -1;
      if (userA.username > userB.username) return 1;
      return 0;
    });
    setmembers(collaborators);
  }, [stateCollaboratorsIds, membersDict]);

  const baseName = useShallowSelector((store) => store.workspace.name);

  const emptyList = useRef([]);

  return ReactDOM.createPortal(
    <Modal
      size="medium"
      className={styles.modalClass}
      hideModal={() => setisVisible(false)}
      onKeyDown={(e) => {
        if (e.key === VALUE_ESCAPE) setisVisible(false);
      }}
    >
      <div className={styles.modalTitle}>
        <h3 className="exerpt">Members of {baseName}</h3>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          icon={<PlusOutlined />}
          onClick={() => {
            if (!canManageMembers) return;
            showInvitationsModal();
          }}
          disabled={!canManageMembers}
          className={styles.button}
        >
          Add Member
        </Button>
      </div>
      <UserList userIds={members ?? emptyList.current} showSearch={true} />
      <div>
        <Button onClick={() => setisVisible(false)}>Close</Button>
      </div>
    </Modal>,
    document.body
  );
};

export default BaseMembersModal;
