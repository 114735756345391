import { ContainerTypes, InlineDiscussion } from "utilities/types";

interface ContainerConnections {
  comments?: any;
  mentions?: any;
}

interface ContainerConnectionsObject {
  [id: string]: ContainerConnections;
}

interface ContainerConnectionsState {
  documents: ContainerConnectionsObject;
  projects: ContainerConnectionsObject;
  notes: ContainerConnectionsObject;
}

const initialState: ContainerConnectionsState = {
  documents: {} as ContainerConnectionsObject,
  projects: {} as ContainerConnectionsObject,
  notes: {} as ContainerConnectionsObject,
};

interface IEntityConnectionActionParameters {
  type: string;
  id: string;
  containerType: ContainerTypes;
  comments?: any;
  mentions?: any;
  discussion?: any;
  reply?: any;
  discussionType?: "COMMENT" | "SUGGESTION";
  discussionId?: string;
}

export default function entityConnectionReducer(
  state: ContainerConnectionsState = initialState,
  action: IEntityConnectionActionParameters
) {
  switch (action.type) {
    case "ADD_ENTITY_CONNECTIONS_OBJECT": {
      const newState = { ...state };
      let entityTypekey: "documents" | "projects" | "notes" = "documents";
      switch (action.containerType) {
        case ContainerTypes.PROJECT:
          entityTypekey = "projects";
          break;
        case ContainerTypes.NOTE:
          entityTypekey = "notes";
          break;
        default:
          entityTypekey = "documents";
      }
      newState[entityTypekey] = { ...newState[entityTypekey] };
      if (newState[entityTypekey][action.id]) {
        newState[entityTypekey][action.id] = {
          ...newState[entityTypekey][action.id],
        };
      } else newState[entityTypekey][action.id] = {};
      if (action.comments)
        newState[entityTypekey][action.id].comments = action.comments.filter(
          (comment: InlineDiscussion) => !comment.inlineDiscussion
        );
      if (action.mentions)
        newState[entityTypekey][action.id].mentions = action.mentions;
      return newState;
    }

    case "ADD_REPLY_TO_DISCUSSION": {
      const newState = { ...state };
      let entityTypekey: "documents" | "projects" | "notes" = "documents";
      switch (action.containerType) {
        case ContainerTypes.PROJECT:
          entityTypekey = "projects";
          break;
        case ContainerTypes.NOTE:
          entityTypekey = "notes";
          break;
        default:
          entityTypekey = "documents";
      }
      newState[entityTypekey] = { ...newState[entityTypekey] };
      if (newState[entityTypekey][action.id]) {
        newState[entityTypekey][action.id] = {
          ...newState[entityTypekey][action.id],
        };
      } else newState[entityTypekey][action.id] = {};

      switch (action.discussionType) {
        case "COMMENT": {
          if (newState[entityTypekey][action.id]) return state;
          newState[entityTypekey][action.id].comments = [
            ...newState[entityTypekey][action.id].comments,
          ];
          newState[entityTypekey][action.id].comments = newState[entityTypekey][
            action.id
          ].comments.map((commentDiscussion: any) => {
            if (commentDiscussion.id === action.discussionId) {
              commentDiscussion.replies = [...commentDiscussion.replies];
              commentDiscussion.replies.push(action.reply);
            }
            return commentDiscussion;
          });
        }
      }
      return newState;
    }

    case "ADD_DISCUSSION": {
      const newState = { ...state };
      const discussion = action.discussion;
      if (discussion.inlineDiscussion) return state;
      discussion.replies = [];
      const containerType = discussion.containerType;
      action.id = discussion.containerId;
      let entityTypekey: "documents" | "projects" | "notes" = "documents";
      switch (containerType) {
        case ContainerTypes.PROJECT:
          entityTypekey = "projects";
          break;
        case ContainerTypes.NOTE:
          entityTypekey = "notes";
          break;
        default:
          entityTypekey = "documents";
      }
      newState[entityTypekey] = { ...newState[entityTypekey] };
      if (newState[entityTypekey][action.id]) {
        newState[entityTypekey][action.id] = {
          ...newState[entityTypekey][action.id],
        };
      } else newState[entityTypekey][action.id] = {};

      switch (discussion.type) {
        case "COMMENT": {
          if (newState[entityTypekey][action.id].comments)
            newState[entityTypekey][action.id].comments = [
              ...newState[entityTypekey][action.id].comments,
            ];
          else newState[entityTypekey][action.id].comments = [];
          let insertionIndex = 0;
          let index = 0;
          let found = false;
          for (const disc of newState[entityTypekey][action.id].comments) {
            if (discussion.documentRank.localeCompare(disc.documentRank) < 0) {
              insertionIndex = index;
              found = true;
              break;
            }
            index++;
          }

          if (!found) insertionIndex = index;

          newState[entityTypekey][action.id].comments.splice(
            insertionIndex,
            0,
            discussion
          );
        }
      }

      return newState;
    }

    case "UPDATE_DISCUSSION": {
      const newState = { ...state };
      const discussion = action.discussion;
      const containerType = discussion.containerType;
      action.id = discussion.containerId;
      let entityTypekey: "documents" | "projects" | "notes" = "documents";
      switch (containerType) {
        case ContainerTypes.PROJECT:
          entityTypekey = "projects";
          break;
        case ContainerTypes.NOTE:
          entityTypekey = "notes";
          break;
        default:
          entityTypekey = "documents";
      }
      newState[entityTypekey] = { ...newState[entityTypekey] };
      if (newState[entityTypekey][action.id]) {
        newState[entityTypekey][action.id] = {
          ...newState[entityTypekey][action.id],
        };
      } else newState[entityTypekey][action.id] = {};

      switch (discussion.type) {
        case "COMMENT": {
          if (newState[entityTypekey][action.id].comments)
            newState[entityTypekey][action.id].comments = [
              ...newState[entityTypekey][action.id].comments,
            ];
          else newState[entityTypekey][action.id].comments = [];
          let index = -1;
          let i = 0;
          for (const disc of newState[entityTypekey][action.id].comments) {
            if (disc.id === discussion.id) {
              index = i;
            }
            i++;
          }
          if (index > -1) {
            newState[entityTypekey][action.id].comments[index] = {
              ...newState[entityTypekey][action.id].comments[index],
              ...discussion,
            };
          }
        }
      }
      return newState;
    }

    case "UPDATE_REPLY_IN_DISCUSSION": {
      const newState = { ...state };
      const discussion = action.discussion;
      const containerType = discussion.containerType;
      action.id = discussion.containerId;
      let entityTypekey: "documents" | "projects" | "notes" = "documents";
      switch (containerType) {
        case ContainerTypes.PROJECT:
          entityTypekey = "projects";
          break;
        case ContainerTypes.NOTE:
          entityTypekey = "notes";
          break;
        default:
          entityTypekey = "documents";
      }
      newState[entityTypekey] = { ...newState[entityTypekey] };
      if (newState[entityTypekey][action.id]) {
        newState[entityTypekey][action.id] = {
          ...newState[entityTypekey][action.id],
        };
      } else newState[entityTypekey][action.id] = {};

      switch (discussion.type) {
        case "COMMENT": {
          if (newState[entityTypekey][action.id].comments)
            newState[entityTypekey][action.id].comments = [
              ...newState[entityTypekey][action.id].comments,
            ];
          else newState[entityTypekey][action.id].comments = [];
          let index = -1;
          let i = 0;
          for (const disc of newState[entityTypekey][action.id].comments) {
            if (disc.id === discussion.id) {
              index = i;
            }
            i++;
          }
          if (index > -1) {
            newState[entityTypekey][action.id].comments[index] = {
              ...newState[entityTypekey][action.id].comments[index],
            };
            newState[entityTypekey][action.id].comments[index].replies = [
              ...newState[entityTypekey][action.id].comments[index].replies,
            ];
            newState[entityTypekey][action.id].comments[index].replies.forEach(
              (replyInDiscussion: any, replyIndex: number) => {
                if (replyInDiscussion.id === action.reply.id) {
                  newState[entityTypekey][action.id].comments[index].replies[
                    replyIndex
                  ] = { ...action.reply };
                }
              }
            );
          }
        }
      }
      return newState;
    }

    case "REMOVE_REPLY_FROM_DISCUSSION": {
      const newState = { ...state };
      const discussion = action.discussion;
      const containerType = discussion.containerType;
      action.id = discussion.containerId;
      let entityTypekey: "documents" | "projects" | "notes" = "documents";
      switch (containerType) {
        case ContainerTypes.PROJECT:
          entityTypekey = "projects";
          break;
        case ContainerTypes.NOTE:
          entityTypekey = "notes";
          break;
        default:
          entityTypekey = "documents";
      }
      newState[entityTypekey] = { ...newState[entityTypekey] };
      if (newState[entityTypekey][action.id]) {
        newState[entityTypekey][action.id] = {
          ...newState[entityTypekey][action.id],
        };
      } else newState[entityTypekey][action.id] = {};

      switch (discussion.type) {
        case "COMMENT": {
          if (newState[entityTypekey][action.id].comments)
            newState[entityTypekey][action.id].comments = [
              ...newState[entityTypekey][action.id].comments,
            ];
          else newState[entityTypekey][action.id].comments = [];
          let index = -1;
          let i = 0;
          for (const disc of newState[entityTypekey][action.id].comments) {
            if (disc.id === discussion.id) {
              index = i;
            }
            i++;
          }
          if (index > -1) {
            newState[entityTypekey][action.id].comments[index] = {
              ...newState[entityTypekey][action.id].comments[index],
            };
            newState[entityTypekey][action.id].comments[index].replies = [
              ...newState[entityTypekey][action.id].comments[index].replies,
            ];
            let deleteIndex = -1;
            newState[entityTypekey][action.id].comments[index].replies.forEach(
              (replyInDiscussion: any, replyIndex: number) => {
                if (replyInDiscussion.id === action.reply.id) {
                  deleteIndex = replyIndex;
                }
              }
            );
            if (index > -1)
              newState[entityTypekey][action.id].comments[index].replies.splice(
                deleteIndex,
                1
              );
          }
        }
      }
      return newState;
    }

    case "REMOVE_DISCUSSION": {
      const newState = { ...state };
      const discussion = action.discussion;
      const containerType = discussion.containerType;
      action.id = discussion.containerId;
      let entityTypekey: "documents" | "projects" | "notes" = "documents";
      switch (containerType) {
        case ContainerTypes.PROJECT:
          entityTypekey = "projects";
          break;
        case ContainerTypes.NOTE:
          entityTypekey = "notes";
          break;
        default:
          entityTypekey = "documents";
      }
      newState[entityTypekey] = { ...newState[entityTypekey] };
      if (newState[entityTypekey][action.id]) {
        newState[entityTypekey][action.id] = {
          ...newState[entityTypekey][action.id],
        };
      } else newState[entityTypekey][action.id] = {};

      switch (discussion.type) {
        case "COMMENT": {
          if (newState[entityTypekey][action.id].comments)
            newState[entityTypekey][action.id].comments = [
              ...newState[entityTypekey][action.id].comments,
            ];
          else newState[entityTypekey][action.id].comments = [];
          let index = -1;
          let i = 0;
          for (const disc of newState[entityTypekey][action.id].comments) {
            if (disc.id === discussion.id) {
              index = i;
            }
            i++;
          }
          if (index > -1) {
            newState[entityTypekey][action.id].comments.splice(index, 1);
          }
        }
      }
      return newState;
    }
    default:
      return state;
  }
}
