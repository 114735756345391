import { getClassNameFromBlockType } from "editor/utils/blockActions";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LineType } from "utilities/lineUtilities";
import { BlockPropsWithRef } from "../BlockSplitter";
import styles from "./styles/blockComponent.module.scss";
import WorkBlockItem from "./workBlock/WorkBlockItem";
import { ContainerTypes } from "utilities/types";

const checkIfLinkedOrActual = (props: BlockPropsWithRef) => {
  if (props.blockData.lineType !== LineType.Title) return "linked ";
  else return "ownWorkItem ";
};

const WorkBlock: React.FC<BlockPropsWithRef> = (props) => {
  const workBlockState = useSelector((state: any) => {
    return {
      workItem:
        props.blockData.referencingContainerId && state.work.dict
          ? state.work.dict[props.blockData.referencingContainerId]
          : null,
    };
  }, shallowEqual);

  if (!workBlockState.workItem) return <></>;
  return (
    <div
      data-block-id={props.blockData.id}
      tabIndex={0}
      ref={props.blockRef}
      contentEditable={false}
      className={
        "content-section clarity-selectable idle-project " +
        getClassNameFromBlockType(props.blockData.lineType) +
        checkIfLinkedOrActual(props) +
        styles.documentBlock
      }
      style={{
        paddingLeft: props.blockData.indentLevel > 0 ? 3 + "px" : 0,
        marginLeft: "-5px",
        marginTop: "-1px",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <WorkBlockItem
          workItemId={workBlockState.workItem.id}
          paneId={props.context.paneId}
          canEdit={props.context.canEdit}
          disableNavigation={
            (props.context.canEdit &&
              props.blockData.containerType === ContainerTypes.WORK_ACTIVITY) ||
            !props.context.canEdit
          }
        />
      </div>
    </div>
  );
};

export default WorkBlock;
