import { useState, useRef, useEffect } from "react";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import classNames from "classnames";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getPreviousRank,
  getNextRank,
  getRankBetween,
} from "utilities/containerRankHelpers";

import { IUserObj } from "utilities/types";
import * as actionTypes from "store/actions";
import styles from "./switchBaseMenu/switchBaseMenu.module.scss";
import BaseMenuItem from "./switchBaseMenu/BaseMenuItem";
import SixDotImg from "icons/six-dot-handle.svg";
import SixDotImgHover from "icons/six-dot-handle-hover.svg";
import store from "store/storeExporter";
import Button, { ButtonTypes } from "./Button";
import { locationSubject } from "./LocationListener";
import { axiosInstance } from "index";

interface Props {
  user: IUserObj;
  activeWorkspaceId: string;
  isVisible: boolean;
  setActiveWorkspace: (workspace: any) => void;
  setSwitchBaseMenuVisibility: (visible: boolean) => void;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  background: isDragging ? "white" : "",
  borderRadius: "4px",
  border: isDragging ? "1px solid var(--border-color1)" : "none",
  ...draggableStyle,
});

const SwitchBaseMenu = (props: Props) => {
  const {
    user,
    activeWorkspaceId,
    isVisible,
    setActiveWorkspace,
    setSwitchBaseMenuVisibility,
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [itemHovered, setItemHovered] = useState<boolean>(false);

  useEffect(() => {
    if (isVisible)
      document.addEventListener("click", handleClickOutside, false);
    return () => {
      if (isVisible)
        document.removeEventListener("click", handleClickOutside, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSwitchBaseMenuVisibility(false);
    }
  };

  const handleDragEnd = (
    result: any,
    workspaceIds: string[],
    workspaceEntries: any
  ) => {
    // synchronously update the state to reflect the dnd result
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // immutably clone workspaces
    const newWorkspaceIds: string[] = [...workspaceIds];

    // pull out the workspace we are moving
    let workspaceIdToMove: string = newWorkspaceIds.splice(source.index, 1)[0];

    let newRank = "";

    if (destination.index === 0) {
      // if destination is first in the list, get prev rank to first element
      const currentFirstRank = workspaceEntries[newWorkspaceIds[0]].rank;
      if (currentFirstRank) {
        newRank = getPreviousRank(currentFirstRank);
      }
    } else if (destination.index === newWorkspaceIds.length) {
      // if destination is last in the list, get next rank to last element
      // (don't need +1 here bc the moved project is the last element now)
      const currentLastRank =
        workspaceEntries[newWorkspaceIds[newWorkspaceIds.length - 1]].rank;
      if (currentLastRank) {
        newRank = getNextRank(currentLastRank);
      }
    } else {
      // get rank between two elements
      const rankBefore =
        workspaceEntries[newWorkspaceIds[destination.index - 1]].rank;
      const rankAfter =
        workspaceEntries[newWorkspaceIds[destination.index]].rank;
      if (rankBefore && rankAfter) {
        newRank = getRankBetween(rankBefore, rankAfter);
      }
    }

    workspaceEntries[workspaceIdToMove].rank = newRank;
    newWorkspaceIds.splice(destination.index, 0, workspaceIdToMove);

    // dispatch the changes to the workspace order
    store.dispatch({
      type: actionTypes.UPDATE_WORKSPACE_RANK,
      id: workspaceIdToMove,
      newRank,
    });

    // send request to update on server
    axiosInstance
      .patch("/api/workspace/updateRank", {
        id: workspaceIdToMove,
        newRank,
      })
      .then((res) => {
        // no response expected
      });
  };

  const handleMenuItemMouseEnter = () => {
    setItemHovered(true);
  };

  const handleMenuItemMouseLeave = () => {
    setItemHovered(false);
  };

  const renderWorkspaces = () => {
    if (user.workspaceIds && user.workspaceIds.length > 0) {
      return user.workspaceIds.map((id: string, index) => (
        <Draggable
          key={id}
          draggableId={id}
          index={index}
          isDragDisabled={!itemHovered}
        >
          {(provided, snapshot) => (
            <div
              className={styles.menuItemWrapper}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <BaseMenuItem
                workspace={user.workspaceEntries[id]}
                setActiveWorkspace={setActiveWorkspace}
                setSwitchBaseMenuVisibility={setSwitchBaseMenuVisibility}
                user={user}
              />
              <div
                className={classNames(
                  styles.grabHandleImg,
                  snapshot.isDragging && styles.grabHandleImg_active
                )}
                onMouseEnter={handleMenuItemMouseEnter}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                {id === activeWorkspaceId && (
                  <CheckOutlined
                    style={{ fontSize: "18px", color: "#7a74d4" }}
                    className={styles.activeCheck}
                  />
                )}
                <img
                  src={SixDotImg}
                  alt="Grab Handle"
                  className={styles.grabHandleIcon}
                />
                <img
                  src={SixDotImgHover}
                  alt="Grab Handle"
                  className={styles.grabHandleIconHover}
                />
              </div>
            </div>
          )}
        </Draggable>
      ));
    }
  };

  if (!isVisible) return <></>;

  return (
    isVisible && (
      <div className={styles.switchBaseMenu_overflowContainer}>
        <div className={styles.switchBaseMenu} ref={wrapperRef}>
          <div className={styles.switchBaseMenu_main}>
            <span
              className={`${styles.switchBaseMenu_main_title} label bold primary`}
            >
              BASES
            </span>
            <DragDropContext
              onDragEnd={(result: any) =>
                handleDragEnd(result, user.workspaceIds, user.workspaceEntries)
              }
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {renderWorkspaces()}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Button
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                  marginLeft: "10px",
                }}
                buttonType={ButtonTypes.LINK}
                className={styles.addBaseButton}
                icon={<PlusOutlined />}
                onClick={() => {
                  setSwitchBaseMenuVisibility(false);
                  locationSubject.next("/launch-base");
                }}
              >
                New base
              </Button>
            </DragDropContext>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
  activeWorkspaceId: state.workspace.id,
  isVisible: state.client.isSwitchBaseMenuVisible,
});

const mapDispatchToProps = (dispatch: any) => ({
  setActiveWorkspace: (workspace: any) =>
    dispatch({ type: actionTypes.SET_ACTIVE_WORKSPACE, workspace }),
  setSwitchBaseMenuVisibility: (visible: boolean) =>
    dispatch({ type: actionTypes.SET_SWITCH_BASE_MENU_VISIBILITY, visible }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitchBaseMenu);
