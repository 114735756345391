import Button, { ButtonTypes } from "components/Button";
import { useState } from "react";
import { socket } from "App";
import TabLayout from "./TabLayout";
import styles from "./options/options.module.scss";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities } from "utilities/types";
import { axiosInstance } from "index";

const Integrations = (props: any) => {
  const [loading, setLoading] = useState<boolean>();
  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MEMBERS,
  });
  return (
    <TabLayout title="Slack Activity Bot">
      <div className={styles.options} style={{ paddingLeft: "8px" }}>
        <p className="body2 secondary" style={{ marginBottom: "24px" }}>
          Add Clarity to a channel to get a stream of events happening in your
          base. This is useful for getting a high-level view of what's
          happening. We recommend putting it in a muted channel and checking it
          periodically, so that it's not distracting. More granular controls and
          additional integrations with Slack coming soon.
        </p>
        <Button
          isLoading={loading}
          disabled={
            (props.base.integrations ? props.base.integrations.slack : false) ||
            !canEditSettings
          }
          buttonType={ButtonTypes.PRIMARY}
          onClick={() => {
            setLoading(true);
            axiosInstance
              .post(`/slack-app-installer`, {
                baseId: props.base.id,
                clientId: socket.id,
              })
              .then((res) => {
                setLoading(false);
                window.open(res.data, "_blank");
              })
              .catch((e) => {
                setLoading(false);
              });
          }}
        >
          Add to Slack
        </Button>

        <p className="body2 secondary" style={{ marginTop: "24px" }}>
          This feature will no longer be supported.
        </p>
      </div>
    </TabLayout>
  );
};

export default Integrations;
