import {
  Abilities,
  ContainerTypes,
  IProjectObj,
  NavigationChunk,
  UserRole,
  ViewNames,
} from "utilities/types";
import { Alert, message, Modal as AntModal, Tooltip } from "antd";
import store from "store/storeExporter";
import {
  DELETE_WORK_ITEM,
  REMOVE_DOCUMENT,
  REMOVE_NOTE,
  REMOVE_PIN,
  UPDATE_DOCUMENT,
} from "store/actions";
import Icon, {
  ExpandOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CheckOutlined,
  LinkOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  deleteDocument,
  deleteProject,
  deleteNote,
} from "modules/documentService";
import { batch } from "react-redux";
import { showDeleteViewConfirm } from "../main/workViews/WorkViewList";
import { getContainerName } from "clarity-ui/paneHeader/DetailViewMoreMenu";
import navigationApi from "clientApi/navigationApi";
import {
  getContainerByType,
  getGroupsFromEntity,
  getNameFromContainer,
  useGetContainerFromType,
} from "modules/containerHelpers";
import { snippetsApi } from "clientApi/snippetsApi";
import { updateSnippetItemAction } from "store/reducers/snippetReducer";
import { templatesApi } from "clientApi/templateApi";
import { updateTemplateItemAction } from "store/reducers/templateReducer";
import Button, { ButtonTypes } from "components/Button";
import { copyTextToClipboard } from "utilities/copyUtils";
import notificationsApi from "clientApi/notificationsApi";
import { useAbilityChecker } from "editor/utils/customHooks";
import { useShallowSelector } from "utilities/hooks";
import { useEffect, useState } from "react";
import workApi from "clientApi/workApi";
import { Link } from "react-router-dom";
import { locationSubject } from "components/LocationListener";
import { ReactComponent as CloseIcon } from "icons/closeIcon.svg";
import styles from "./peekView.module.scss";
import { ChunkDestination } from "utilities/stateTypes";

const { confirm } = AntModal;

const showDeleteDocumentConfirm = (
  containerType: ContainerTypes,
  containerId: string
) => {
  const container = getContainerByType({ containerId, containerType });

  switch (containerType) {
    case ContainerTypes.DOCUMENT:
      return confirm({
        title: "Are you sure you want to delete this tag?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        maskClosable: true,
        okText: "Delete",

        onOk() {
          deleteDocument(containerId).then((resp: any) => {
            if (resp) {
              batch(() => {
                Object.values(resp.updatedDocuments).forEach((document) => {
                  store.dispatch({ type: UPDATE_DOCUMENT, document });
                });
                store.dispatch({
                  type: REMOVE_PIN,
                  id: resp.deletedPin,
                });
              });
            }
            const name = getNameFromContainer(container, containerType);
            store.dispatch({ type: REMOVE_DOCUMENT, documentId: containerId });
            documentDeletedMessage(name);
            navigationApi.closePeekView();
          });
        },
        onCancel() {},
      });
    case ContainerTypes.CUSTOM_VIEW: {
      const base = store.getState().workspace;
      return showDeleteViewConfirm(
        containerId,
        base.id,
        base.slug,
        ChunkDestination.peek
      );
    }

    case ContainerTypes.NOTE: {
      return confirm({
        title: "Are you sure you want to delete this doc?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        maskClosable: true,
        okText: "Delete",

        onOk() {
          deleteNote(containerId).then(({ containerId }) => {
            store.dispatch({ type: REMOVE_NOTE, noteId: containerId });
            const name = getNameFromContainer(container, containerType);
            documentDeletedMessage(name);
            navigationApi.closePeekView();
          });
        },
        onCancel() {},
      });
    }

    case ContainerTypes.TASK:
    case ContainerTypes.WORK:
    case ContainerTypes.PROJECT: {
      const name = getNameFromContainer(container, containerType);
      const childrenCount = (container as IProjectObj).childrenAggregate.length;
      const contentText =
        childrenCount > 0 ? (
          <Alert
            message={`Deleting it will also delete ${childrenCount} subtask${
              childrenCount > 1 ? "s" : ""
            }`}
            type="error"
          />
        ) : (
          ""
        );
      return confirm({
        title: `Are you sure you want to delete this ${
          container
            ? getContainerName(containerType, container).trim()
            : containerType
        }?`,
        icon: <ExclamationCircleOutlined />,
        content: contentText,
        maskClosable: true,
        okText: "Delete",

        onOk() {
          deleteProject(containerId).then((resp: any) => {
            store.dispatch({
              type: DELETE_WORK_ITEM,
              id: containerId,
            });
            documentDeletedMessage(name);
            navigationApi.closePeekView();
          });
        },
        onCancel() {},
      });
    }
    case ContainerTypes.SNIPPET:
      return confirm({
        title: "Are you sure you want to delete this snippet?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        maskClosable: true,
        okText: "Delete",

        onOk() {
          snippetsApi.deleteSnippet(containerId).then((res) => {
            if (!res) return;
            if (res.snippetId) {
              updateSnippetItemAction({
                delta: {},
                id: res.snippetId,
                skipInsertInList: false,
                type: "delete",
              });
              navigationApi.closePeekView();
            }
          });
        },
        onCancel() {},
      });
    case ContainerTypes.TEMPLATE:
      return confirm({
        title: "Are you sure you want to delete this template?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        maskClosable: true,
        okText: "Delete",

        onOk() {
          templatesApi.deleteTemplate(containerId).then((res) => {
            if (!res) return;
            if (res.templateId) {
              updateTemplateItemAction({
                delta: {},
                id: res.templateId,
                skipInsertInList: false,
                type: "delete",
              });
            }
            navigationApi.closePeekView();
          });
        },
        onCancel() {},
      });
  }
};

export const documentDeletedMessage = (docName: string) => {
  message.success(`Deleted successfully`, 2);
};

const PeekViewHeader: React.FC<{ navigationChunk: NavigationChunk }> = ({
  navigationChunk,
}) => {
  const entity = navigationChunk.entity;
  const container = useGetContainerFromType(
    entity?.containerId,
    entity?.containerType
  );

  const copyLinkStateToText = {
    default: "Copy link",
    success: "Success",
  };

  const [copyLinkText, setCopyLinkText] = useState(copyLinkStateToText.default);

  const userRole = useShallowSelector((state) => state.client.roleType);

  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember:
      entity?.containerType === ContainerTypes.PROJECT ||
      entity?.containerType === ContainerTypes.NOTE
        ? getGroupsFromEntity(container as any, entity?.containerType)
        : undefined,
  });

  const copyUrl = () => {
    if (!container) return;
    const internalUrl =
      navigationApi.getLinkFromNavigationChunk(navigationChunk);
    const origin = window.location.origin;
    const url: string = `${origin}${internalUrl}`;
    copyTextToClipboard(url);
    setCopyLinkText(copyLinkStateToText.success);
    notificationsApi.displayConfirmation({
      title: "Success",
      duration: 4,
      body: "Link Copied",
    });
    setTimeout(() => setCopyLinkText(copyLinkStateToText.default), 2000);
  };

  if (!entity || !entity.containerType) return <></>;

  return (
    <div className={styles.taskBody__headerContainer}>
      <ListNavigation itemId={entity.containerId} />
      <div className={styles.taskBody__headerContainer__buttons}>
        {userRole !== UserRole.GUEST ? (
          <Tooltip title={"Delete"}>
            <Button
              buttonType={ButtonTypes.LINK}
              disabled={!canEdit}
              onClick={() => {
                if (!canEdit || !entity.containerType) return;
                showDeleteDocumentConfirm(
                  entity.containerType,
                  entity.containerId
                );
              }}
              icon={<DeleteOutlined />}
              className="mr-4"
            />{" "}
          </Tooltip>
        ) : (
          <></>
        )}

        {/* make a copy button */}
        {(entity?.containerType === ContainerTypes.WORK ||
          entity.containerType === ContainerTypes.PROJECT) && (
          <Tooltip title={"Make a Copy"}>
            <span>
              <Button
                icon={<CopyOutlined />}
                className="mr-4"
                disabled={!canEdit}
                buttonType={ButtonTypes.LINK}
                onClick={() => workApi.openDuplicatModal(entity.containerId)}
              />
            </span>
          </Tooltip>
        )}

        <Tooltip title={"Copy Link"}>
          <span>
            <Button
              icon={
                copyLinkText === copyLinkStateToText.success ? (
                  <CheckOutlined />
                ) : (
                  <LinkOutlined />
                )
              }
              className="mr-4"
              buttonType={ButtonTypes.LINK}
              onClick={() => copyUrl()}
            ></Button>
          </span>
        </Tooltip>

        <Tooltip title={"FullScreen"}>
          <Link
            className="mr-4"
            to={navigationApi.getLinkFromNavigationChunk(navigationChunk)}
            onClick={(e) => {
              e.preventDefault();
              if (e.shiftKey) {
                navigationApi.openSplitViewNavigationChunk(navigationChunk);
              } else {
                locationSubject.next(
                  navigationApi.getLinkFromNavigationChunk(navigationChunk)
                );
              }
              navigationApi.closePeekView();
            }}
          >
            <Button
              buttonType={ButtonTypes.LINK}
              icon={<ExpandOutlined />}
            ></Button>
          </Link>
        </Tooltip>

        <Tooltip title={"Close"}>
          <span>
            <Button
              buttonType={ButtonTypes.LINK}
              icon={<Icon style={{ fontSize: "12px" }} component={CloseIcon} />}
              onClick={() => {
                navigationApi.closePeekView();
              }}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const ListNavigation: React.FC<{ itemId: string }> = ({ itemId }) => {
  const currentListItems = useShallowSelector(
    (state) => state.work.currentListNavigationItems
  );
  const toggleTask = (id: string) => {
    navigationApi.openPeekView({
      viewName: ViewNames.Detail,
      entity: {
        containerId: id,
        containerType: ContainerTypes.WORK,
      },
    });
  };
  const [index, setindex] = useState(0);

  useEffect(() => {
    setindex(currentListItems ? currentListItems.indexOf(itemId) : 0);
  }, [currentListItems, itemId]);

  return (
    <div style={{ display: "flex" }}>
      {currentListItems && (
        <>
          <Tooltip title={"Up"}>
            <span>
              <Button
                className={styles.customButton}
                buttonType={ButtonTypes.MEDIUM_PLAIN}
                style={{ marginLeft: "5px", marginRight: "5px" }}
                icon={<ArrowUpOutlined />}
                disabled={index === 0}
                onClick={() => {
                  if (index > 0) {
                    toggleTask(
                      store.getState().work.dict[currentListItems[index - 1]].id
                    );
                  }
                }}
              />
            </span>
          </Tooltip>
          <Tooltip title={"Down"}>
            <span>
              <Button
                className={styles.customButton}
                icon={<ArrowDownOutlined />}
                buttonType={ButtonTypes.MEDIUM_PLAIN}
                onClick={() => {
                  if (index < currentListItems.length) {
                    toggleTask(
                      store.getState().work.dict[currentListItems[index + 1]].id
                    );
                  }
                }}
                disabled={index === currentListItems.length - 1}
              />
            </span>
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default PeekViewHeader;
