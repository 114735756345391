import { socket } from "App";
import { addToListOfCitationChanges } from "editor/utils/blockValueHelpers";
import React, { useEffect } from "react";
import { containerCleanupDict } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store from "store/storeExporter";
import { LineValueType } from "utilities/lineUtilities";
import { IBlockContext } from "utilities/types";

interface Props {
  context: IBlockContext;
  isBaseMember: boolean;
}

export const handleCheckCitationUpdate = (
  e: React.FormEvent<HTMLDivElement>
) => {
  const selection = document.getSelection()?.focusNode;
  const parentElOfText = selection?.parentElement;
  if (parentElOfText?.nodeName === LineValueType.citation) {
    const citationId = parentElOfText?.getAttribute("data-citation-id");

    if (citationId) {
      addToListOfCitationChanges({
        citationId,
        citation: parentElOfText,
        type: "update",
      });
    }
  }
};

const ContainerWrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    return () => {
      if (containerCleanupDict[props.context.container.id]) {
        containerCleanupDict[props.context.container.id].forEach((action) =>
          action()
        );
      }
    };
  }, []);

  useEffect(() => {
    const baseId = store.getState().workspace.id;
    if (!props.isBaseMember) {
      if (baseId) socket.emit("connectToRoom", { roomId: baseId });
    }
    return () => {
      if (!props.isBaseMember) {
        if (baseId)
          socket.emit("disconnectFromRoom", {
            roomId: baseId,
          });
      }
    };
  }, [props.isBaseMember]);

  return <>{props.children}</>;
};

export default ContainerWrapper;
