import React from "react";
import styles from "./priorityDisplay/priorityDisplay.module.scss";
import { getPriorityName } from "utilities/types";
import { useCommandPaletteShower } from "store/reducers/commandPaletteReducer";
import { CommandPaletteContext } from "utilities/types";
import PriorityHighIcon from "icons/Components/PriorityHighIcon";
import PriorityLowIcon from "icons/Components/PriorityLowIcon";
import PriorityMediumIcon from "icons/Components/PriorityMediumIcon";
import PriorityNoneIcon from "icons/Components/PriorityNoneIcon";
import PriorityUrgentIcon from "icons/Components/PriorityUrgentIcon";

interface PriorityProps {
  priorityLevel: number;
  size?: "small" | "medium" | "large";
  showName: boolean;
  customLabelClass?: string;
  editId?: string;
}

export const getPriorityIcon = (priorityLevel: number) => {
  switch (priorityLevel) {
    case 1:
      return <PriorityNoneIcon />;
    case 2:
      return <PriorityLowIcon />;
    case 3:
      return <PriorityMediumIcon />;
    case 4:
      return <PriorityHighIcon />;
    case 5:
      return <PriorityUrgentIcon />;
    default:
      return null;
  }
};

const PriorityDisplay: React.FC<PriorityProps> = (props) => {
  const icon = getPriorityIcon(props.priorityLevel);
  const openPalette = useCommandPaletteShower();
  const edit: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openPalette(CommandPaletteContext.PRIORITY, {
      selectedItemIds: props?.editId ? [props?.editId] : [],
    });
  };

  const style = {
    ...(props.editId ? { cursor: "pointer" } : {}),
  };

  return (
    <div
      className={styles.container}
      onClick={!props.editId ? undefined : edit}
      style={style}
    >
      {icon ? (
        <div
          className={
            props.priorityLevel === 1
              ? `${styles.iconContainerDisabled}`
              : `${styles.iconContainer}`
          }
        >
          {icon}
        </div>
      ) : (
        <></>
      )}
      {!props.showName ? (
        <></>
      ) : (
        <span className={`${styles.caption} caption ${props.customLabelClass}`}>
          {props.showName && getPriorityName(props.priorityLevel)}
        </span>
      )}
    </div>
  );
};

export default PriorityDisplay;
