import { connect } from "react-redux";
import { IPageObj } from "utilities/types";
import { useTable, useSortBy } from "react-table";
import {
  PlusOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import styles from "./pagesList/pagesList.module.scss";
import DocumentListHeader from "components/DocumentListHeader";
import documentListHeaderStyles from "components/documentListHeader/documentListHeader.module.scss";
import EmptyState from "clarity-ui/EmptyState";
import { createAndSetNewPage } from "utilities/createNewContainers";

interface IDocumentListProps {
  documents: IPageObj[];
  columns: any[];
}

interface IMapStateToProps {
  baseId: string;
}

interface IMapDispatchToProps {}

function DocumentList(
  props: IDocumentListProps & IMapDispatchToProps & IMapStateToProps
) {
  const { columns, documents, baseId } = props;

  const { headers, getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable(
      {
        columns,
        data: documents,
        initialState: {
          // @ts-ignore
          sortBy: [{ id: "name", desc: false }] as any,
        },
      },
      useSortBy
    );

  return (
    <>
      {documents.length < 1 ? (
        <EmptyState
          buttonFn={(event: any) => createAndSetNewPage(event, baseId)}
          buttonIcon={<PlusOutlined />}
          buttonLabel="New Tag"
          heading={"Your Community Library"}
          caption={
            <>
              Tags are your long-term knowledge storage.
              <br />
              <br />
              Create tags for important topics in your community. Tagged content
              is added to the page of that tag.
            </>
          }
        />
      ) : (
        <div className={styles.listViewContainer} {...getTableProps()}>
          <div className={styles.header}>
            {headers.map((column) => {
              return (
                // @ts-ignore
                <div
                  className={styles.headerCol}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <DocumentListHeader>
                    <span className={documentListHeaderStyles.headerText}>
                      {column.render("Header")}
                    </span>
                    <span className={documentListHeaderStyles.sortCaret}>
                      {/* @ts-ignore */}
                      {column.isSorted ? (
                        <>
                          {/* @ts-ignore */}
                          {column.isSortedDesc ? (
                            <CaretDownOutlined />
                          ) : (
                            <CaretUpOutlined />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </DocumentListHeader>
                </div>
              );
            })}
          </div>

          <div className={styles.list} {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <div className={styles.col} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <div
                        className={
                          cell.column.id === "menu"
                            ? styles.threeDots
                            : styles.title
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  baseId: state.workspace.id,
});

export default connect(mapStateToProps)(DocumentList);
