import { EmailNotifications, UserBaseSettings } from "../../utilities/types";
import { SET_USER_BASE_SETTINGS } from "../actions";

const initialState: UserBaseSettings = {
  id: "",
  settings: {
    emailNotifications: EmailNotifications.ALL,
    lastUnreadNote: {},
  },
  workspaceId: "",
  myTasksState: {},
};

interface IUserBaseSettingsParameters {
  type: string;
  userBaseSettings: UserBaseSettings;
}

export default function userBaseSettingsReducer(
  state = initialState,
  action: IUserBaseSettingsParameters
) {
  switch (action.type) {
    case SET_USER_BASE_SETTINGS:
      if (action.userBaseSettings) {
        const newState = {
          ...state,
          ...action.userBaseSettings,
          settings: { ...action.userBaseSettings.settings },
          myTasksState: action.userBaseSettings.myTasksState,
        };
        return newState;
      }
      return state;
    default:
      return state;
  }
}
