import _ from "lodash";
import {
  ADD_ROLES,
  CHANGED_BASE_MEMBER,
  DELETE_ROLES,
  SET_BASE_ROLES,
  UPDATE_ROLE,
  UPDATE_ROLES,
  UPDATE_USER_ROLES,
} from "store/actions";
import store from "store/storeExporter";
import { getNextRank } from "utilities/containerRankHelpers";
import {
  abilities,
  Abilities,
  ContainerTypes,
  ContainerTypeToPrimitive,
  IRole,
  IUserPermissions,
  PermissionType,
  RoleTypes,
} from "utilities/types";
import { axiosInstance } from "index";
import { groupApi } from "./groupsApi";

const { v4: uuidv4 } = require("uuid");

export const emptyPermissions = {
  notes: {
    read: [],
    comment: [],
    suggest: [],
    edit: [],
    create: [],
    noEdit: [],
    delete: [],
    noDelete: [],
    noCreate: [],
    noRead: [],
  },
  pages: {
    read: [],
    comment: [],
    suggest: [],
    edit: [],
    create: [],
    noEdit: [],
    delete: [],
    noDelete: [],
    noCreate: [],
    noRead: [],
  },
  work: {
    read: [],
    comment: [],
    suggest: [],
    edit: [],
    create: [],
    noEdit: [],
    delete: [],
    noDelete: [],
    noCreate: [],
    noRead: [],
  },
};

class RoleApi {
  getRolesForBase(baseId: string) {
    return axiosInstance.get(`/api/role/all/${baseId}`).then((res: any) => {
      store.dispatch({
        type: SET_BASE_ROLES,
        roles: res.data,
      });
    });
  }

  checkAbility(param: {
    abilityName: Abilities;
    entityId?: string;
    entityType?: ContainerTypes;
    isGroupMember?: string | string[];
  }) {
    const permissions: IUserPermissions = store.getState().client.permissions;

    if (permissions?.admin) {
      return true;
    }
    if (
      param.abilityName === Abilities.CAN_EDIT_ENTITY ||
      param.abilityName === Abilities.CAN_COMMENT_ENTITY
    ) {
      if (param.entityType && param.entityId) {
        const primitive = ContainerTypeToPrimitive[
          param.entityType
        ] as keyof PermissionType;

        if (
          permissions?.permissions &&
          permissions?.permissions[primitive as keyof PermissionType] &&
          permissions?.permissions[
            primitive as keyof PermissionType
          ]?.edit.includes(param.entityId)
        ) {
          return true;
        } else if (
          permissions?.permissions &&
          permissions?.permissions[primitive as keyof PermissionType] &&
          permissions?.permissions[
            primitive as keyof PermissionType
          ]?.suggest.includes(param.entityId)
        ) {
          if (param.abilityName === Abilities.CAN_COMMENT_ENTITY) {
            return true;
          }
        } else {
          if (param.abilityName === Abilities.CAN_COMMENT_ENTITY) {
            return true;
          }
        }
      }
    }
    if (!permissions) return false;
    if (permissions[param.abilityName]) {
      if (param.isGroupMember) {
        return groupApi.isGroupMember(param.isGroupMember);
      }
      return true;
    } else {
      return false;
    }
  }

  changeUserRole(param: {
    userId: string | undefined;
    baseId: string;
    removedRoles: string[];
    newRoles: string[];
  }) {
    if (param.userId) {
      const member = store.getState().members.dict[param.userId];

      let newRoles: string[] = this.calculateNewRoles(
        member.roleIds,
        param.newRoles,
        param.removedRoles
      );

      store.dispatch({
        type: CHANGED_BASE_MEMBER,
        changeType: "UPDATED_USER",
        changedMember: {
          id: member.id,
          roleIds: newRoles,
        },
      });

      axiosInstance
        .post("/api/role-assignment/changeRole", {
          ...param,
        })
        .catch((e) => {
          let newRoles: string[] = this.calculateNewRoles(
            member.roleIds,
            param.removedRoles,
            param.newRoles
          );

          store.dispatch({
            type: CHANGED_BASE_MEMBER,
            changeType: "UPDATED_USER",
            changedMember: {
              id: member.id,
              roleIds: newRoles,
            },
          });
        });
    }
  }

  calculateNewRoles(
    roleIds: string[] | undefined,
    newRoleIds: string[],
    removedRoles: string[]
  ) {
    let newRoles: string[] = [];

    if (newRoleIds.length > 0) {
      newRoles = roleIds
        ? _.uniq([...roleIds, ...newRoleIds])
        : [...newRoleIds];
    }

    if (removedRoles.length > 0) {
      newRoles = roleIds
        ? roleIds.filter((roleId) => !removedRoles.includes(roleId))
        : [];
    }
    return newRoles;
  }

  aggregateAbilities(roleIds: string[]) {
    const roles = roleIds.map((roleId) => store.getState().roles.dict[roleId]);
    roles.sort((a, b) => {
      if (a.rank < b.rank) return -1;
      if (a.rank > b.rank) return 1;
      return 0;
    });

    const aggregatedAbilities = roles.reduce(
      (prevReducedValue, currentValue: any) => {
        const aggregatedCurrentAbilitites = { ...prevReducedValue };
        if (!currentValue?.permissions) {
          return aggregatedCurrentAbilitites;
        }
        abilities.forEach((ability: string) => {
          if (
            ability in currentValue?.permissions &&
            currentValue?.permissions[ability]
          ) {
            aggregatedCurrentAbilitites[ability] =
              currentValue?.permissions[ability];
          }
        });
        return aggregatedCurrentAbilitites;
      },
      {} as any
    );

    return aggregatedAbilities;
  }

  aggregateNewUserRoles(roleIds: string[], workspaceId?: string) {
    const currentWorkspace = store.getState().workspace.id;

    if (workspaceId && currentWorkspace !== workspaceId) {
      return;
    }
    const roles = roleIds.map((roleId) => store.getState().roles.dict[roleId]);
    roles.sort((a, b) => {
      if (a.rank < b.rank) return -1;
      if (a.rank > b.rank) return 1;
      return 0;
    });

    const aggregatedAbilities = roles.reduce(
      (prevReducedValue, currentValue: any) => {
        const aggregatedCurrentAbilitites = { ...prevReducedValue };
        abilities.forEach((ability: string) => {
          if (
            ability in currentValue.permissions &&
            currentValue.permissions[ability]
          ) {
            aggregatedCurrentAbilitites[ability] =
              currentValue.permissions[ability];
          }
        });
        return aggregatedCurrentAbilitites;
      },
      {} as any
    );
    const newPermissions = {
      ...aggregatedAbilities,
    };

    store.dispatch({
      type: UPDATE_USER_ROLES,
      params: {
        roleIds: roleIds,
        permissions: newPermissions,
      },
    });
  }

  addRoles(roles: IRole[]) {
    store.dispatch({
      type: ADD_ROLES,
      newRoles: roles,
    });
    return axiosInstance.post(`/api/role/new`, {
      roles,
    });
  }

  deleteRoles(roleIds: string[]) {
    store.dispatch({
      type: DELETE_ROLES,
      deletedRoleIds: roleIds,
    });
    axiosInstance.post("/api/role/delete", {
      roles: roleIds,
    });
  }

  updateRole(delta: Partial<IRole>, id: string) {
    const currentRole = store.getState().roles.dict[id];

    store.dispatch({
      type: UPDATE_ROLE,
      id,
      delta: { ...currentRole, ...delta },
    });
    return axiosInstance.post(`/api/role/update`, {
      delta,
      id,
    });
  }

  updateRoles(deltas: Partial<IRole>[]) {
    store.dispatch({
      type: UPDATE_ROLES,
      deltas: deltas,
    });
    return axiosInstance.post(`/api/role/updateMultiple`, {
      deltas,
    });
  }

  updateRoleAndPermissions(deltas: Partial<IRole>[], type: string) {
    if (type && type === "add") {
      store.dispatch({
        type: ADD_ROLES,
        newRoles: deltas,
      });
      return;
    }
    store.dispatch({
      type: UPDATE_ROLES,
      deltas,
    });

    const currentRoleIds = store.getState().client.roleIds;
    let mustUpdateRole = false;
    for (const delta of deltas) {
      if (delta?.id && currentRoleIds.includes(delta?.id)) {
        mustUpdateRole = true;
      }
    }

    if (mustUpdateRole) {
      this.aggregateNewUserRoles(currentRoleIds);
    }
  }

  equipRole(roleId: string) {
    const state = store.getState();
    const userId = state.user?.id;
    const baseId = state.workspace.id;
    const userRoleIds = state.client.roleIds;
    let newRoles: string[] = this.calculateNewRoles(userRoleIds, [roleId], []);

    this.aggregateNewUserRoles(newRoles);

    axiosInstance.post("/api/role-assignment/changeRole", {
      userId,
      baseId,
      newRoles: [roleId],
      removedRoles: [],
    });
  }

  removeRole(roleId: string) {
    const state = store.getState();
    const userId = state.user?.id;
    const baseId = state.workspace.id;
    const userRoleIds = state.client.roleIds;
    let newRoles: string[] = this.calculateNewRoles(userRoleIds, [], [roleId]);

    this.aggregateNewUserRoles(newRoles);

    axiosInstance.post("/api/role-assignment/changeRole", {
      userId,
      baseId,
      newRoles: [],
      removedRoles: [roleId],
    });
  }

  createNewEmptyRole() {
    const baseId = store.getState().workspace.id;
    const roles = Object.values(store.getState().roles.dict);
    const filteresRoles = roles.filter((role) => role.roleType === "custom");
    filteresRoles.sort((a, b) => {
      return a.rank?.localeCompare(b.rank, "en");
    });

    const lastRole = filteresRoles[filteresRoles.length - 1];
    const id = uuidv4();
    const newRole: IRole = {
      id: id,
      roleName: "New role",
      description: "",
      permissions: {
        permissions: { ...emptyPermissions },
      },
      roleType: RoleTypes.CUSTOM,
      baseId: baseId,
      rank: getNextRank(lastRole.rank),
      selfAssignable: false,
    };

    return newRole;
  }
}

const roleApi = new RoleApi();

export default roleApi;
