import {
  SET_ACTIVE_WORKSPACE,
  SET_BASE_SNIPPET,
  UPDATE_SNIPPET,
} from "store/actions";
import store from "store/storeExporter";
import { ISnippetObj } from "utilities/types";

interface ISnippetReducerState {
  dict: SnippetDict;
  ids: string[];
}

export interface SnippetDict {
  [id: string]: ISnippetObj;
}

const initialState: ISnippetReducerState = {
  dict: {},
  ids: [],
};

interface IUpdateSnippetParam {
  id: string;
  type: "add" | "delete" | "update";
  delta: Partial<ISnippetObj>;
  skipInsertInList: boolean;
}

interface UpdateSnippetAction {
  type: string;
  param: IUpdateSnippetParam;
}

interface SetBaseSnippetsAction {
  type: string;
  param: {
    snippets: ISnippetObj[];
  };
}

export default function snippetReducer(
  state: ISnippetReducerState = initialState,
  action: UpdateSnippetAction & SetBaseSnippetsAction
) {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      return initialState;
    }

    case SET_BASE_SNIPPET: {
      if (action.param.snippets) {
        const newState: ISnippetReducerState = {
          ids: [],
          dict: {},
        };

        action.param.snippets.forEach((snippet) => {
          newState.ids.push(snippet.id);
          newState.dict[snippet.id] = snippet;
        });

        return newState;
      }
      return state;
    }

    case UPDATE_SNIPPET: {
      const newState = { ...state };
      newState.dict = { ...newState.dict };
      if (action.param.type === "add") {
        if (
          !action.param.skipInsertInList &&
          !newState.ids.includes(action.param.id)
        ) {
          newState.ids = [...newState.ids];
          newState.ids.splice(0, 0, action.param.id);
        }

        newState.dict[action.param.id] = action.param.delta as ISnippetObj;
        return newState;
      }
      if (action.param.type === "delete") {
        const snippet = newState.dict[action.param.id];
        if (!snippet) return state;
        newState.ids = [...newState.ids];
        const id = action.param.id;
        const index = newState.ids.indexOf(id);
        if (index > -1) {
          newState.ids.splice(index, 1);
        }

        delete newState.dict[action.param.id];
        return newState;
      }
      if (action.param.type === "update") {
        if (!newState.dict[action.param.id]) return state;
        newState.dict[action.param.id] = {
          ...newState.dict[action.param.id],
          ...action.param.delta,
        };
        return newState;
      }
      return initialState;
    }
    default:
      return state;
  }
}

export const updateSnippetItemAction = (param: IUpdateSnippetParam) => {
  store.dispatch({
    type: UPDATE_SNIPPET,
    param,
  });
};
