export const getNextRank = (rank: string): string => {
  return getNewDocRankUtility(rank, undefined);
};

export const getPreviousRank = (rank: string): string => {
  return getNewDocRankUtility(undefined, rank);
};

export const getRankBetween = (
  prevRank: string | undefined,
  nextRank: string | undefined
): string => {
  return getNewDocRankUtility(prevRank, nextRank);
};

export const getNewDocRankUtility = (
  prevRank: string | undefined,
  nextRank: string | undefined
): string => {
  if (prevRank) {
    const prevRankLength = prevRank.length;
    const positionToCompare = prevRankLength - 1;

    if (nextRank) {
      const nextRankLength = nextRank.length;

      const lastCharOfPrevCode = prevRank.charCodeAt(positionToCompare);
      const lastCharOfNextCode = nextRank.charCodeAt(positionToCompare);
      const diffInLastOfPrev = lastCharOfNextCode - lastCharOfPrevCode;

      if ([-1, 1].includes(diffInLastOfPrev)) {
        if (prevRankLength === nextRankLength) {
          return prevRank + "a";
        } else {
          return (
            nextRank.substring(0, nextRankLength - 1) +
            prevLetter(nextRank[nextRankLength - 1])
          );
        }
      }

      if ([0].includes(diffInLastOfPrev)) {
        return (
          nextRank.substring(0, nextRankLength - 1) +
          prevLetter(nextRank[nextRankLength - 1])
        );
      }
      if (checkIfAtLastCharIsZ(prevRank) === true) return prevRank + "a";
      else
        return (
          prevRank.substring(0, prevRankLength - 1) +
          nextLetter(prevRank[prevRankLength - 1])
        );
    } else {
      if (checkIfAtLastCharIsZ(prevRank) === true) return prevRank + "a";
      else
        return (
          prevRank.substring(0, prevRankLength - 1) +
          nextLetter(prevRank[prevRankLength - 1])
        );
    }
  } else {
    if (nextRank) {
      return "0" + nextRank;
    }
    return "0a";
  }
};

const prevLetter = (string: string) => {
  return string.replace(/([a-zA-Z])[^a-zA-Z]*$/, (a) => {
    let c = a.charCodeAt(0);
    switch (a) {
      case "0":
        return "00";
      case "a":
        return "0a";
      default:
        return String.fromCharCode(--c);
    }
  });
};

const nextLetter = (string: string) => {
  return string.replace(/([a-zA-Z])[^a-zA-Z]*$/, (a) => {
    let c = a.charCodeAt(0);
    switch (c) {
      case 90:
        return "A";
      case 122:
        return "a";
      default:
        return String.fromCharCode(++c);
    }
  });
};

const checkIfAtLastCharIsZ = (string: string): boolean => {
  const lastChar = string[string.length - 1];
  if (lastChar === "z" || lastChar === "Z") return true;
  else return false;
};
