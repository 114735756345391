import navigationApi from "clientApi/navigationApi";
import { locationSubject } from "components/LocationListener";
import { useEntityLoader } from "editor/utils/entityLoader/entityLoader";
import React, { useLayoutEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { SET_NAVIGATION_CHUNK } from "store/actions";
import { usePaneId } from "store/reducers/filterReducer";
import store from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import {
  ContainerTypes,
  GeneralViewsNames,
  GroupGeneralViews,
  NavigationChunk,
  TasksViewModes,
  ViewNameType,
  WorkTypes,
} from "utilities/types";
import Navigator from "./Navigator";

const NavigationContext: React.FC<{
  viewName: ViewNameType;
  groupSlug?: string;
  containerId?: string;
  containerType?: ContainerTypes;
  taskViewMode?: TasksViewModes;
  workType?: WorkTypes;
}> = ({
  viewName,
  groupSlug,
  containerId,
  containerType,
  taskViewMode,
  workType,
}) => {
  const dispatch = useDispatch();
  const paneId = usePaneId();

  const workItem = useEntityLoader(containerId, containerType);

  useLayoutEffect(() => {
    if (
      containerType &&
      (containerType === ContainerTypes.WORK ||
        containerType === ContainerTypes.PROJECT ||
        containerType === ContainerTypes.TASK) &&
      workItem
    ) {
      const navigationChunk = buildNavigationChunk({
        viewName,
        groupSlug,
        groupId:
          workItem.workType === WorkTypes.TASK ? workItem.groupId : undefined,
        containerId: workItem.id,
        containerType,
        taskViewMode,
        workType: workItem.workType,
      });

      dispatch({
        type: SET_NAVIGATION_CHUNK,
        navigationChunk,
        paneId: paneId,
      });
    } else {
      const navigationChunk = buildNavigationChunk({
        viewName,
        groupSlug,
        containerId,
        containerType,
        taskViewMode,
        workType,
      });

      dispatch({
        type: SET_NAVIGATION_CHUNK,
        navigationChunk,
        paneId: paneId,
      });
    }
  }, [
    viewName,
    groupSlug,
    containerId,
    containerType,
    taskViewMode,
    paneId,
    workItem,
  ]);

  return useMemo(() => <Navigator />, []);
};

export const buildNavigationChunk = (param: {
  viewName: ViewNameType;
  groupSlug?: string;
  containerId?: string;
  containerType?: ContainerTypes;
  taskViewMode?: TasksViewModes;
  workType?: WorkTypes;
  groupId?: string;
}): NavigationChunk => {
  const navigationChunk: NavigationChunk = {
    groupSlug: param.groupSlug,
    groupId: param.groupId,
    viewName: param.viewName,
    taskViewMode: param.taskViewMode,
    entity: param.containerId
      ? {
          containerId: param.containerId,
          containerType: param.containerType,
          workType: param.workType,
        }
      : undefined,
  };
  return navigationChunk;
};

export const navigateToGroup = (
  groupId: string,
  paneId: ChunkDestination,
  shiftKey?: boolean
) => {
  const group = store.getState().groups.dict[groupId];
  if (!group) return;

  const baseData = store.getState().workspace;

  const lastVisitedPage =
    store.getState().recentlyOpened?.lastVisitedGroupLocation &&
    store.getState().recentlyOpened?.lastVisitedGroupLocation[group.workspaceId]
      ? store.getState().recentlyOpened.lastVisitedGroupLocation[
          group.workspaceId
        ][group.slug]
      : {
          viewName: GroupGeneralViews.GroupProfile,
          groupSlug: group.slug,
          groupId: group.id,
        };

  const openInPrimary = () => {
    let to = `/${baseData.slug}/group/${group?.slug}`;
    if (lastVisitedPage) {
      if (lastVisitedPage.viewName !== GroupGeneralViews.GroupProfile) {
        to = `/${baseData.slug}/group/${group?.slug}/${lastVisitedPage.viewName}`;
      } else {
        to = `/${baseData.slug}/group/${group?.slug}`;
      }
    }

    const current = window.location.pathname;
    if (to !== current) {
      locationSubject.next(to);
    } else {
      locationSubject.next(`/${baseData.slug}/group/${group?.slug}`);
    }
  };

  const openInSplit = () => {
    if (lastVisitedPage) {
      return navigationApi.openSplitView(lastVisitedPage);
    } else {
      return navigationApi.openSplitView({
        groupSlug: group.slug,
        groupId: group.id,
        viewName: GroupGeneralViews.GroupProfile,
      });
    }
  };

  if (paneId === ChunkDestination.secondary) {
    if (shiftKey) return openInPrimary();
    return openInSplit();
  } else {
    if (shiftKey) return openInSplit();
    return openInPrimary();
  }
};

export const navigateToBase = (
  paneId: ChunkDestination,
  shiftKey?: boolean
) => {
  const baseData = store.getState().workspace;

  // const lastVisitedPage = store.getState().recentlyOpened
  //   .lastVisitedHomeLocation
  //   ? store.getState().recentlyOpened.lastVisitedHomeLocation[baseData.id]
  //   : {
  //       viewName: GeneralViewsNames.Home,
  //     };

  const lastVisitedPage = {
    viewName: GeneralViewsNames.Home,
  };

  const navigateToMain = () => {
    let to = `/${baseData.slug}`;
    if (lastVisitedPage) {
      if (lastVisitedPage.viewName !== GeneralViewsNames.Home) {
        to = `/${baseData.slug}/${lastVisitedPage.viewName}`;
      }
    }
    const current = window.location.pathname;
    if (to !== current) {
      locationSubject.next(to);
    } else {
      locationSubject.next(`/${baseData.slug}`);
    }
  };

  const navigateToSplit = () => {
    return navigationApi.openSplitView(lastVisitedPage);
  };

  if (paneId === ChunkDestination.secondary) {
    if (shiftKey) return navigateToMain();
    else return navigateToSplit();
  } else {
    if (shiftKey) return navigateToSplit();
    else return navigateToMain();
  }
};

export default NavigationContext;
