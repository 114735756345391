import WidgetEntry from "clarity-ui/WidgetEntry";
import { getClassNameFromBlockType } from "editor/utils/blockActions";
import { BlockPropsWithRef } from "../BlockSplitter";
import styles from "./styles/blockComponent.module.scss";

const WidgetBlock = (props: BlockPropsWithRef) => {
  return (
    <div
      data-block-id={props.blockData.id}
      tabIndex={0}
      ref={props.blockRef}
      contentEditable={false}
      className={
        "content-section clarity-selectable idle-project " +
        getClassNameFromBlockType(props.blockData.lineType) +
        styles.documentBlock
      }
      style={{
        paddingLeft: props.blockData.indentLevel > 0 ? 3 + "px" : 0,
        marginLeft: "-5px",
        marginTop: "-1px",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <WidgetEntry
          customViewId={
            props.blockData.referencingContainerId
              ? props.blockData.referencingContainerId
              : ""
          }
          listed={false}
          blockContext={props.context}
        />
      </div>
    </div>
  );
};

export default WidgetBlock;
