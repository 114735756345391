import UserDisplay from "clarity-ui/UserDisplay";

interface ICollaboratorAvatarProps {
  user: any;
  index: number;
}

export default function CollaboratorAvatar(props: ICollaboratorAvatarProps) {
  const { user, index } = props;

  if (!user.avatar) return <></>;
  return (
    <div
      style={{
        position: "absolute",
        left: String(-16 - 10 * index) + "px",
      }}
    >
      <UserDisplay id={user.id} hideName={true} />
    </div>
  );
}
