import { Switch } from "antd";
import Modal from "clarity-ui/Modal";
import OptionGroup from "clarity-ui/OptionGroup";
import TokenGateSelector from "clarity-ui/tokenGateContainer/TokenGateSelector";
import notificationsApi from "clientApi/notificationsApi";
import roleApi from "clientApi/rolesApi";
import Button, { ButtonTypes } from "components/Button";
import ClarityInput from "components/ClarityInput";
import { ActionTabSet } from "components/TopNavBar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ActionBtnKind } from "store/reducers/topNavReducer";
import { ClarityStore } from "store/storeExporter";
import { IRole, IUserPermissions } from "utilities/types";
import RoleAbilities from "../../roleGallery/RoleAbilities";
import styles from "../roles/roles.module.scss";
import { CheckOutlined } from "@ant-design/icons";
enum EditorMode {
  details = "Details",
  permissions = "Permissions",
}

const AddOrEditRole: React.FC<{
  id?: string;
  setdetailsOpen: any;
  disabled?: boolean;
  noRole?: boolean;
}> = ({ id, setdetailsOpen, disabled = false, noRole }) => {
  return (
    <Modal size="medium" hideModal={() => null}>
      <DetailView
        id={id}
        setdetailsOpen={setdetailsOpen}
        disabled={disabled}
        noRole={noRole}
      />
    </Modal>
  );
};

const DetailView: React.FC<{
  id?: string;
  setdetailsOpen: any;
  disabled: boolean;
  noRole?: boolean;
}> = ({ id, setdetailsOpen, disabled, noRole }) => {
  const existingRole = useSelector(
    (state: ClarityStore) => (id ? state.roles.dict[id] : null),
    shallowEqual
  );

  const [newRole, setnewRole] = useState<IRole>(
    existingRole ?? roleApi.createNewEmptyRole()
  );

  const [mode, setmode] = useState(
    noRole ? EditorMode.permissions : EditorMode.details
  );

  useEffect(() => {
    if (existingRole) {
      setnewRole({ ...existingRole });
    }
  }, [existingRole]);

  const deleteRole = () => {
    if (disabled) return;
    if (existingRole) roleApi.deleteRoles([existingRole?.id]);
    setdetailsOpen(false);
    return;
  };

  const addRole = () => {
    if (disabled) return;
    if (existingRole) {
      roleApi.updateRole(newRole, newRole.id).then(() => {
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Role updated",
        });
      });
      setdetailsOpen(false);
      return;
    } else {
      roleApi.addRoles([newRole]).then(() => {
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Role added",
        });
      });
      setdetailsOpen(false);
      return;
    }
  };

  const changeAbilities = (
    roleId: string,
    delta: Partial<IUserPermissions>
  ) => {
    setnewRole({
      ...newRole,
      permissions: { ...delta },
    });
  };

  const tabs = useMemo(() => {
    const tabsArray = noRole
      ? [EditorMode.permissions]
      : [EditorMode.details, EditorMode.permissions];
    return tabsArray.map((inmode) => {
      return {
        key: inmode,
        onClick: () => setmode(inmode),
        contents: () => <span>{inmode}</span>,
        active: () => inmode === mode,
      };
    });
  }, [mode, noRole]);

  const getHeaderText = () => {
    if (id) return "Modify role";
    return "Add new role";
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "12px" }}>
        <h4>{getHeaderText()}</h4>
        <div style={{ flexGrow: 1 }}></div>
        {id && !noRole && (
          <>
            <Button
              disabled={disabled}
              buttonType={ButtonTypes.LINK}
              onClick={deleteRole}
            >
              Delete
            </Button>
          </>
        )}
        <Button
          buttonType={ButtonTypes.DEFAULT}
          onClick={() => setdetailsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          onClick={addRole}
          disabled={
            disabled ||
            newRole.roleName.length === 0 ||
            shallowEqual(newRole, existingRole)
          }
        >
          {id ? "Save & Apply" : "Save"}
        </Button>
      </div>
      <div className={styles.modeChecker}>
        <ActionTabSet
          key="tab-set"
          kind={ActionBtnKind.TabSet}
          tabs={tabs}
          noFirstDivider={true}
        />
      </div>
      {mode === EditorMode.details && (
        <RoleDetails
          role={newRole}
          setnewRole={setnewRole}
          disabled={disabled}
        />
      )}
      {mode === EditorMode.permissions && (
        <RoleAbilities
          role={newRole}
          onChangeAbilities={!disabled ? changeAbilities : undefined}
          toggleType="switch"
          showOwnPermissions={false}
          showCheckPermission={true}
          showName={true}
        />
      )}
    </div>
  );
};

const RoleDetails: React.FC<{
  role: IRole;
  setnewRole: React.Dispatch<React.SetStateAction<IRole>>;
  disabled: boolean;
}> = ({ role, setnewRole, disabled }) => {
  const updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setnewRole({ ...role, roleName: e.target.value });
  };

  const updateDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setnewRole({ ...role, description: e.target.value });
  };

  const updateSelfAssignable = (isSelfAssignable: boolean) => {
    setnewRole({ ...role, selfAssignable: isSelfAssignable });
  };

  const updateTokenGate = (id: string) => {
    setnewRole({ ...role, tokenGateId: id });
  };

  const removeTokenGate = () => {
    setnewRole({ ...role, tokenGateId: null });
  };

  return (
    <div>
      <div className={styles.editItemRow}>
        <OptionGroup
          title="Role name"
          description="An identifier for this role"
          value=""
          hideValue={true}
        />
        <ClarityInput
          disabled={disabled}
          value={role.roleName}
          onChange={updateName}
        />
      </div>

      <div className={styles.editItemRow}>
        <OptionGroup
          title="Role description"
          description="This will show in the Role Gallery for all members to see"
          value=""
          hideValue={true}
        />
        <ClarityInput
          disabled={disabled}
          placeholder="What does this role do"
          value={role.description}
          onChange={updateDescription}
        />
      </div>

      <div className={styles.editItemRow}>
        <OptionGroup
          title="Self assignable"
          description="Any member can give this role to themself"
          value=""
          hideValue={true}
        />
        <Switch
          checkedChildren={<CheckOutlined />}
          disabled={disabled}
          checked={role.selfAssignable}
          onChange={updateSelfAssignable}
        />
      </div>

      <div
        className={`${styles.editItemRow} ${
          role.tokenGateId ? styles.columnDirection : ""
        }`}
      >
        <OptionGroup
          title="Token Requirements"
          description="The member must have these tokens to assign themself the role"
          value=""
          hideValue={true}
        />
        <RoleTokenGate
          onSelect={updateTokenGate}
          onRemove={removeTokenGate}
          noUpdate={disabled || !role.selfAssignable}
          gateId={role.tokenGateId ? role.tokenGateId : undefined}
        />
      </div>
    </div>
  );
};

const RoleTokenGate: React.FC<{
  roleId?: string;
  onSelect?: any;
  gateId?: string;
  noUpdate?: boolean;
  onRemove?: () => void;
}> = ({ roleId, onSelect, gateId, noUpdate, onRemove }) => {
  const [adding, setadding] = useState<boolean>(false);
  const fullOnselect = useCallback(
    (id?: string) => {
      if (onSelect) {
        onSelect(id);
        setadding(false);
      }
    },
    [onSelect]
  );

  return (
    <>
      <TokenGateSelector
        gateId={gateId}
        onSelect={fullOnselect}
        adding={adding}
        noUpdate={noUpdate}
        setadding={setadding}
        onRemove={onRemove}
      />
      {!gateId && !noUpdate && (
        <Button buttonType={ButtonTypes.LINK} onClick={() => setadding(true)}>
          Add new
        </Button>
      )}
    </>
  );
};

export default AddOrEditRole;
