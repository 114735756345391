import { mixpanel } from "App";
import Button, { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { ADD_NEW_NOTE, LOAD_NEXT_NOTE_BATCH } from "store/actions";
import {
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import store, { ClarityStore } from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import {
  Abilities,
  BaseType,
  ContainerTypes,
  UserEventTypes,
  UserRole,
  ViewNames,
} from "utilities/types";
import NotePreview, { NotePreviewTitles } from "./notes/NotePreview";
import { LoadingOutlined } from "@ant-design/icons";
import {
  AutoSizer,
  InfiniteLoader,
  List,
  ListRowProps,
} from "react-virtualized";
import { NOTE_PAGE_SIZE } from "store/reducers/notesReducer";
import styles from "./notes/notes.module.scss";
import { axiosInstance } from "index";
import navigationApi from "clientApi/navigationApi";
import scrollbarSize from "dom-helpers/scrollbarSize";
import { useBaseType } from "store/reducers/workspaceReducer";
import { batch } from "react-redux";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import { ChunkDestination } from "utilities/stateTypes";
import Conditional from "components/Conditional";
import { PlusOutlined } from "@ant-design/icons";

enum SectionWidthModes {
  small = "small",
  medium = "medium",
  large = "large",
}

const Notes: React.FC<{ groupSlug: string; paneId: ChunkDestination }> = ({
  groupSlug,
  paneId,
}) => {
  const [loading, setLoading] = useState(false);
  const [tableWidth, setTableWidth] = useState(0);
  const notesRef = useRef<HTMLDivElement | null>(null);

  const group = useShallowSelector((state: ClarityStore) =>
    groupSlug ? state.groups.slugDict[groupSlug] : undefined
  );

  const notesArray = useShallowSelector((state: ClarityStore) =>
    group ? state.notes.groupNotes[group.id]?.noteIds ?? [] : state.notes.ids
  );

  const baseId = useShallowSelector((state) => state.workspace.id);

  const baseType = useBaseType();
  const notesLoadStatus = useShallowSelector((state: ClarityStore) =>
    group
      ? state.notes.groupNotes[group.id]?.cursorPosition
      : state.notes.notesLoadStatus
  );

  const canEditEntities = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: group?.id,
  });

  const userRole = useShallowSelector((state) => state.client.roleType);
  const user = useShallowSelector((state) => state.user);

  const handleNewNoteClick = async () => {
    const base = store.getState().workspace;
    axiosInstance
      .post("/api/note", {
        baseId: base.id,
        groupId: group?.id,
      })
      .then(({ data }) => {
        store.dispatch({
          type: ADD_NEW_NOTE,
          note: data,
        });
        navigationApi.contextBasedNavigate({
          currentPane: paneId,
          navigationChunk: {
            viewName: ViewNames.Detail,
            entity: {
              containerId: data.id,
              containerType: ContainerTypes.NOTE,
            },
          },
        });
      });
  };

  usePageDataSetter(paneId, {
    title: group?.name,
  });

  useEffect(() => {
    batch(() => {
      setPaneSubroutes({
        paneId,
        subRoutesParams: {
          type: "group",
          groupId: group?.id,
          activeKey: "notes",
        },
      });
      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.group,
      });
    });

    return () => {
      batch(() => {
        setPaneSubroutes({
          paneId,
        });
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, []);

  const fetchMoreData = async () => {
    if (loading || notesLoadStatus?.allLoaded) return;
    setLoading(true);
    if (userRole === UserRole.GUEST && baseType !== BaseType.Public) {
      const stateClient = store.getState().client;
      const permissions = (stateClient as any).permissions.permissions;
      return axiosInstance
        .post("/api/note/all/guest", {
          workspaceId: baseId,
          startFrom: 0,
          pageSize: NOTE_PAGE_SIZE,
          noteIds: permissions?.notes?.read,
        })
        .then((res: any) => {
          setLoading(false);

          store.dispatch({
            type: LOAD_NEXT_NOTE_BATCH,
            params: res.data,
          });
        });
    } else {
      return await axiosInstance
        .get("/api/note/page", {
          params: {
            workspaceId: baseId,
            startFrom: notesLoadStatus?.startFrom,
            pageSize: NOTE_PAGE_SIZE,
            groupId: group?.id,
          },
        })
        .then((res) => {
          setLoading(false);

          store.dispatch({
            type: LOAD_NEXT_NOTE_BATCH,
            params: res.data,
          });
        });
    }
  };

  useEffect(() => {
    mixpanel.track(UserEventTypes.ALL_NOTES_VIEWED, {
      distinct_id: user?.id,
    });
  }, []);

  const isRowLoaded = useCallback(
    ({ index }: { index: number }) => {
      return Boolean(notesArray[index]);
    },
    [notesArray]
  );

  const getRowHeight = useCallback(
    ({ index }: { index: number }) => {
      if (!isRowLoaded({ index })) {
        if (notesLoadStatus?.allLoaded) return 79;
        else return 100;
      }
      return 50;
    },
    [notesArray]
  );

  const rowRenderer = useCallback(
    ({ key, index, style }: ListRowProps) => {
      if (!isRowLoaded({ index })) {
        if (notesLoadStatus?.allLoaded) {
          return (
            <div style={style} key={key}>
              <div className={`${styles.emptyRow} caption secondary disabled`}>
                <Conditional on={index >= 2}>
                  <> No more to show </>
                </Conditional>
                <Conditional on={index === 0}>
                  <> There are no docs to show </>
                </Conditional>
              </div>
            </div>
          );
        }
        return (
          <div style={style} key={key}>
            <div
              className={styles.emptyRow}
              style={{
                fontSize: "26px",
                justifyContent: "center",
                marginLeft: "8px",
              }}
            >
              <LoadingOutlined size={50} />
            </div>
          </div>
        );
      } else {
        const noteId = notesArray[index];
        return (
          <div style={style} key={key}>
            <NotePreview key={noteId} noteId={noteId} />
          </div>
        );
      }
    },
    [notesArray, isRowLoaded, notesLoadStatus]
  );

  const widthMode = useNotesWidth(notesRef);

  const getClassName = useCallback(() => {
    switch (widthMode) {
      case SectionWidthModes.large:
        return styles.large;
      case SectionWidthModes.medium:
        return styles.medium;
      case SectionWidthModes.small:
        return styles.small;
    }
  }, [widthMode]);

  return (
    <div
      ref={notesRef}
      style={{ height: "100%", width: "100%" }}
      className={getClassName()}
    >
      <Conditional on={Boolean(userRole !== UserRole.GUEST)}>
        <TopNavFiltersBar paneId={paneId}>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            icon={<PlusOutlined />}
            disabled={!canEditEntities}
            onClick={() => {
              if (!canEditEntities) return;
              handleNewNoteClick();
            }}
          >
            Doc
          </Button>
        </TopNavFiltersBar>
      </Conditional>
      <div className={styles.noteContainer}>
        <NotePreviewTitles width={tableWidth - scrollbarSize()} />
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={fetchMoreData}
          threshold={5}
          rowCount={notesArray.length + 1}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => {
                setTableWidth(width + 8);

                return (
                  <>
                    <List
                      className="scrollContentY"
                      onRowsRendered={onRowsRendered}
                      ref={registerChild}
                      width={width + 8}
                      style={{
                        marginLeft: "-8px",
                      }}
                      autoContainerWidth={true}
                      height={height}
                      rowCount={notesArray.length + 1}
                      rowHeight={getRowHeight}
                      rowRenderer={rowRenderer}
                      overscanRowCount={5}
                    />
                  </>
                );
              }}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    </div>
  );
};

const useNotesWidth = (
  notesRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const [sectionWidthMode, setsectionWidthMode] = useState<SectionWidthModes>(
    SectionWidthModes.large
  );
  useEffect(() => {
    if (!notesRef.current) return;
    notesRef.current?.focus();
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width > 960)
          return setsectionWidthMode(SectionWidthModes.large);
        if (600 < entry.contentRect.width && entry.contentRect.width <= 960)
          return setsectionWidthMode(SectionWidthModes.medium);
        if (entry.contentRect.width <= 600) {
          return setsectionWidthMode(SectionWidthModes.small);
        }
      }
    });
    if (notesRef.current) resizeObserver.observe(notesRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return sectionWidthMode;
};

export default Notes;
