import { CloseCircleOutlined } from "@ant-design/icons";
import CalendarTwoTone from "icons/Components/CalendarTwoTone";
import { MetadataBlock, useIsGuest } from "clarity-ui/ContainerDetailMetadata";
import Button, { ButtonTypes } from "components/Button";
import Conditional from "components/Conditional";
import moment from "moment";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import { CommandPaletteContext, ContainerTypes } from "utilities/types";
import { templatesApi } from "clientApi/templateApi";
import workApi from "clientApi/workApi";

const TargetDateRow: React.FC<{
  canEditEntity: boolean;
  dueDate?: Date | string | null;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ dueDate, canEditEntity, containerId, containerType }) => {
  const handleDueDateClick = () =>
    openCommandPalette(CommandPaletteContext.DUE_DATE, {
      selectedItemIds: [containerId],
      slectedItemsType: containerType,
    });

  const resetDueDate = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!canEditEntity) return;

    if (containerType === ContainerTypes.TEMPLATE) {
      templatesApi.update({ dueDate: null }, [containerId]);
      return;
    }

    if (containerType === ContainerTypes.WORK) {
      workApi.update({ dueDate: null }, [containerId]);
      return;
    }
  };

  const hasDueDate = Boolean(dueDate);
  const dueDateText = hasDueDate ? (
    moment.utc(dueDate).format("MMM D, YYYY")
  ) : (
    <span className="disabled">No target</span>
  );

  return (
    <MetadataBlock
      title="Target"
      infoText={dueDateText}
      disabled={!canEditEntity}
      onClick={handleDueDateClick}
      prefix={<CalendarTwoTone className={hasDueDate ? "" : "disabled"} />}
      suffix={
        <Conditional on={hasDueDate}>
          <CancelBtn onClick={resetDueDate} />
        </Conditional>
      }
    />
  );
};

interface ICancelBtnProps {
  onClick: React.MouseEventHandler;
}

function CancelBtn({ onClick }: ICancelBtnProps) {
  const isGuest = useIsGuest();

  return (
    <Conditional on={!isGuest}>
      <Button
        buttonType={ButtonTypes.LINK}
        icon={<CloseCircleOutlined />}
        style={{
          width: "20px",
          height: "20px",
          minWidth: "20px",
          minHeight: "20px",
        }}
        onClick={onClick}
      />
    </Conditional>
  );
}

export default TargetDateRow;
