import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Abilities,
  BaseType,
  CommandPaletteContext,
  ContainerTypes,
  IProjectObj,
  OrderTypes,
  UserRole,
  ViewNames,
  WorkTypes,
} from "utilities/types";
import roadmapStyles from "./roadmap/roadmap.module.scss";
import {
  useInitiativeIds,
  useProjectIds,
  useWork,
} from "store/reducers/workReducer";
import { shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore, prevState } from "store/storeExporter";
import { locationSubject } from "components/LocationListener";
import WorkProgress from "clarity-ui/WorkProgress";
import UserDisplay from "clarity-ui/UserDisplay";
import { ADD_NEW_PROJECT } from "store/actions";
import { sortByMetadata } from "modules/taskService";
import { usePageDataSetter } from "store/reducers/topNavReducer";
import Button, { ButtonTypes } from "components/Button";
import { PlusOutlined, FileDoneOutlined, AimOutlined } from "@ant-design/icons";
import DueDateDisplay from "clarity-ui/DueDateDisplay";
import { checkIfCannotCreateWork } from "modules/appService";
import { Dropdown, Menu } from "antd";
import { deviceType } from "App";
import { DeviceType, useAbilityChecker } from "editor/utils/customHooks";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
import ItemStatus from "components/projectCard/ItemStatus";
import ItemDetails from "components/projectCard/ItemDetails";
import { useShallowSelector } from "utilities/hooks";
import { getCorrectLink } from "utilities/linkUtilities";
import { getCorrectGroupId } from "utilities/groupUtilities";
import { ChunkDestination } from "utilities/stateTypes";
import { axiosInstance } from "index";
import navigationApi from "clientApi/navigationApi";
import Conditional from "components/Conditional";
import {
  ActionIntercept,
  setActionInterceptor,
} from "store/reducers/clientReducer";
import { useBaseId } from "store/reducers/workspaceReducer";
import { useGetMilestonesToShow } from "./roadmap/hooks";
import { RoadmapMilestonesMode } from "store/reducers/roadmapFilterReducer";
import RoadmapTopNavFilters from "./roadmap/RoadmapTopNavFilters";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";

const PaletteContexts = CommandPaletteContext;
export interface RoadmapScreenProps {
  paneId: ChunkDestination;
  archive?: boolean;
  assigneeId?: string;
  viewId?: string;
  fullHeight?: boolean;
}

enum SectionWidthModes {
  small = "small",
  medium = "medium",
  large = "large",
}

const Roadmap: React.FC<RoadmapScreenProps> = ({
  paneId,
  archive,
  assigneeId,
  fullHeight,
  viewId,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const baseId = useBaseId();

  const options = useShallowSelector((store) => ({
    showMilestones: store.workspace.showMilestones,
    groupId: store.client.paneGroupContext[paneId]?.groupId ?? "",
    groupSlug: store.client.paneGroupContext[paneId]?.groupSlug ?? "",
  }));

  const [sectionWidthMode, setsectionWidthMode] = useState<SectionWidthModes>(
    SectionWidthModes.large
  );
  const showClosedProjects = useShallowSelector((store) =>
    Boolean(
      viewId &&
        store.roadmapFilters &&
        (store.roadmapFilters[baseId]?.[viewId]?.showClosedProjects ||
          store.roadmapFilters[baseId]?.[viewId]?.roadmapMilestonesMode ===
            RoadmapMilestonesMode.completed)
    )
  );

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: options.groupId,
  });

  usePageDataSetter(paneId, {
    title: getViewNameAsSubroute(options.groupId, assigneeId),
  });

  useEffect(() => {
    if (!ref.current) return;
    ref.current?.focus();
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width > 1000)
          return setsectionWidthMode(SectionWidthModes.large);
        if (600 < entry.contentRect.width && entry.contentRect.width <= 1000)
          return setsectionWidthMode(SectionWidthModes.medium);
        if (entry.contentRect.width <= 600) {
          return setsectionWidthMode(SectionWidthModes.small);
        }
      }
    });
    if (ref.current) resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const getClassName = useCallback(() => {
    switch (sectionWidthMode) {
      case SectionWidthModes.large:
        return roadmapStyles.large;
      case SectionWidthModes.medium:
        return roadmapStyles.medium;
      case SectionWidthModes.small:
        return roadmapStyles.small;
    }
  }, [sectionWidthMode]);

  return (
    <>
      {useMemo(() => {
        return (
          <>
            <Conditional on={options.showMilestones}>
              <RoadmapTopNavFilters
                viewId={viewId}
                baseId={baseId}
                paneId={paneId}
                groupId={options.groupId}
              />
            </Conditional>

            <div
              style={!fullHeight ? { overflow: "auto" } : undefined}
              className={`${roadmapStyles.roadmapSectionsHolder} page__mxWidth1355`}
            >
              <div
                style={!fullHeight ? { overflow: "auto" } : undefined}
                ref={ref}
                className={`${
                  roadmapStyles.sections
                } content ${getClassName()}`}
              >
                <Conditional on={options.showMilestones}>
                  <MilestonesSection
                    paneId={paneId}
                    canEditEntity={canEditEntity}
                    archive={archive}
                    groupId={options.groupId}
                    assigneeId={assigneeId}
                    showClosedProjects={showClosedProjects}
                    viewId={viewId}
                  />
                </Conditional>
                <Conditional on={false || !options.showMilestones}>
                  <ProjectsSection
                    paneId={paneId}
                    canEditEntity
                    showClosedProjects={showClosedProjects}
                    archive={archive}
                    groupId={options.groupId}
                    assigneeId={assigneeId}
                    viewId={viewId}
                  />
                </Conditional>
              </div>
            </div>
          </>
        );
      }, [
        sectionWidthMode,
        archive,
        canEditEntity,
        options.showMilestones,
        options.groupId,
        showClosedProjects,
      ])}
    </>
  );
};

const MilestonesSection: React.FC<{
  paneId: ChunkDestination;
  archive?: boolean;
  canEditEntity: boolean;
  groupId?: string;
  assigneeId?: string;
  showClosedProjects: boolean;
  viewId?: string;
}> = ({
  paneId,
  archive = false,
  canEditEntity,
  groupId,
  assigneeId,
  showClosedProjects,
  viewId,
}) => {
  const baseId = useBaseId();
  const refItems = useRef({
    baseId,
    groupId,
    assigneeId,
    viewId,
  });

  const { showMilestoneIds } = useGetMilestonesToShow(refItems.current);

  return (
    <>
      {showMilestoneIds.map((milestoneId, index) => (
        <MilestoneSection
          paneId={paneId}
          index={index}
          canEditEntity={canEditEntity}
          key={milestoneId}
          id={milestoneId}
          groupId={groupId}
          assigneeId={assigneeId}
          showClosedProjects={showClosedProjects}
        />
      ))}
      <MilestoneSection
        paneId={paneId}
        archive={archive}
        index={9999}
        canEditEntity={canEditEntity}
        groupId={groupId}
        assigneeId={assigneeId}
        showClosedProjects={showClosedProjects}
      />
    </>
  );
};

const ProjectsSection: React.FC<{
  paneId: ChunkDestination;
  archive?: boolean;
  canEditEntity: boolean;
  groupId?: string;
  assigneeId?: string;
  showClosedProjects: boolean;
  viewId?: string;
}> = ({
  paneId,
  archive,
  canEditEntity,
  groupId,
  assigneeId,
  showClosedProjects,
  viewId,
}) => {
  const [currentSpaceProjectIds, setcurrentSpaceProjectIds] = useState<
    string[]
  >([]);

  const options = useShallowSelector((store) => ({
    groupId: store.client.paneGroupContext[paneId]?.groupId ?? "",
  }));

  const projectIds = useProjectIds(options.groupId);
  const work = useWork();
  const presets = useRef<Partial<IProjectObj>>({});

  useLayoutEffect(() => {
    const spaceIds = projectIds.filter((itemId) => {
      const item = work.workDict[itemId];
      if (!item || !item.projectId) return false;

      const assigneeFilter = assigneeId
        ? item.assigneeId === assigneeId ||
          item.contributorIds.includes(assigneeId)
        : true;

      const shouldBeClosed = archive ? true : false;
      return item.isClosed === shouldBeClosed && assigneeFilter;
    });

    const orderedIds = sortByMetadata(OrderTypes.dueDate, spaceIds);
    if (JSON.stringify(orderedIds) !== JSON.stringify(currentSpaceProjectIds))
      setcurrentSpaceProjectIds(orderedIds);
  }, [
    work.workDict,
    currentSpaceProjectIds,
    archive,
    options.groupId,
    showClosedProjects,
  ]);

  return (
    <SectionWrapper
      isFirst={true}
      sectionName={options.groupId === "" ? "Projects" : ""}
      presets={presets}
      canEditEntity={canEditEntity}
      workType={WorkTypes.PROJECT}
      paneId={paneId}
      groupId={options.groupId}
    >
      <SectionInner
        paneId={paneId}
        canEditEntity={canEditEntity}
        index={0}
        showClosedProjects={showClosedProjects}
        archive={archive}
        groupId={groupId}
        all={true}
      />
    </SectionWrapper>
  );
};

const MilestoneSection: React.FC<{
  id?: string;
  paneId: ChunkDestination;
  archive?: boolean;
  canEditEntity: boolean;
  index: number;
  groupId?: string;
  assigneeId?: string;
  showClosedProjects: boolean;
}> = ({
  id,
  paneId,
  archive,
  canEditEntity,
  index,
  groupId,
  assigneeId,
  showClosedProjects,
}) => {
  const milestone = useSelector(
    (store: ClarityStore) => (id ? store.work.milestoneDict[id] : undefined),
    shallowEqual
  );

  const presets = useRef<Partial<IProjectObj>>({ milestoneId: id, assigneeId });

  useEffect(() => {
    if (groupId) presets.current.groupIds = [groupId];
  }, []);

  return (
    <SectionWrapper
      isFirst={index === 0}
      sectionName={milestone?.name ?? "No milestone"}
      presets={presets}
      canEditEntity={canEditEntity}
      workType={WorkTypes.PROJECT}
      paneId={paneId}
      isClosed={milestone?.isClosed}
    >
      <SectionInner
        id={id}
        canEditEntity={canEditEntity}
        paneId={paneId}
        index={index}
        archive={archive}
        groupId={groupId}
        assigneeId={assigneeId}
        showClosedProjects={showClosedProjects}
      />
    </SectionWrapper>
  );
};

const SectionInner: React.FC<{
  id?: string;
  paneId: ChunkDestination;
  archive?: boolean;
  canEditEntity: boolean;
  index: number;
  groupId?: string;
  all?: boolean;
  assigneeId?: string;
  showClosedProjects: boolean;
}> = ({
  id,
  paneId,
  archive,
  canEditEntity,
  index,
  groupId,
  all,
  assigneeId,
  showClosedProjects,
}) => {
  const milestone = useSelector(
    (store: ClarityStore) => (id ? store.work.milestoneDict[id] : undefined),
    shallowEqual
  );

  const [currentSpaceProjectIds, setcurrentSpaceProjectIds] = useState<
    string[]
  >([]);

  const [currentSpaceInitiativetIds, setcurrentSpaceInitiativeIds] = useState<
    string[]
  >([]);

  const projectIds = useProjectIds(groupId ?? "");
  const initiativeIds = useInitiativeIds(groupId ?? "");
  const atLeastOneProject = useRef(false);

  const work = useWork();

  useLayoutEffect(() => {
    atLeastOneProject.current = false;

    const fitlerItem = (itemId: string) => {
      if (all) return true;
      const item = work.workDict[itemId];

      if (!item || !item.projectId) return false;

      const assigneeFilter = assigneeId
        ? item.assigneeId === assigneeId ||
          item.contributorIds.includes(assigneeId)
        : true;

      if (id === item.milestoneId || (!id && !item.milestoneId)) {
        if (!assigneeFilter) return false;
      } else return false && assigneeFilter;

      if (item.isClosed && !showClosedProjects) {
        atLeastOneProject.current = true;
        return false;
      }
      return true;
    };
    const spaceProjectIds = projectIds.filter((itemId) => fitlerItem(itemId));

    const spaceInitiativeIds = initiativeIds.filter((itemId) =>
      fitlerItem(itemId)
    );

    const orderedSpaceInitiativeIds = sortByMetadata(
      OrderTypes.dueDate,
      spaceInitiativeIds
    );
    if (
      JSON.stringify(orderedSpaceInitiativeIds) !==
      JSON.stringify(currentSpaceInitiativetIds)
    )
      setcurrentSpaceInitiativeIds(orderedSpaceInitiativeIds);

    const orderedSpaceProjectIds = sortByMetadata(
      OrderTypes.dueDate,
      spaceProjectIds
    );
    if (
      JSON.stringify(orderedSpaceProjectIds) !==
      JSON.stringify(currentSpaceProjectIds)
    )
      setcurrentSpaceProjectIds(orderedSpaceProjectIds);
  }, [
    work.workDict,
    projectIds,
    initiativeIds,
    id,
    archive,
    showClosedProjects,
  ]);

  if (!milestone && id) return <></>;

  return (
    <>
      <Conditional
        on={
          currentSpaceInitiativetIds.length === 0 &&
          currentSpaceProjectIds.length === 0
        }
      >
        <span
          className={`caption disabled ${roadmapStyles.projectItem} ${roadmapStyles.noTopBorder} ${roadmapStyles.noHover}`}
        >
          <Conditional on={atLeastOneProject.current === false}>
            <span style={{ marginLeft: "-8px" }}>
              There are no projects or goals inside this milestone
            </span>
          </Conditional>

          <Conditional on={atLeastOneProject.current === true}>
            <>All projects and goals in this milestone are closed</>
          </Conditional>
        </span>
      </Conditional>
      <Conditional on={currentSpaceInitiativetIds.length > 0}>
        <div className={roadmapStyles.initiativeSectionContainer}>
          <h4 className="label medium disabled" style={{ marginBottom: "8px" }}>
            Goals
          </h4>
          <div className={roadmapStyles.initiativeSection}>
            {currentSpaceInitiativetIds.map((id) => (
              <RoadmapInitiativeItem
                id={id}
                key={id}
                isFirst={index === 0}
                isLast={index === currentSpaceInitiativetIds.length - 1}
                paneId={paneId}
                canEditEntity={canEditEntity}
              />
            ))}
          </div>
        </div>
      </Conditional>
      <Conditional on={currentSpaceProjectIds.length > 0}>
        <div className={roadmapStyles.projectsContainer}>
          <ProjectHeader />
          {currentSpaceProjectIds.map((id, index) => (
            <RoadmapProjectItem
              id={id}
              key={id}
              canEditEntity={canEditEntity}
              isFirst={index === 0}
              isLast={index === currentSpaceProjectIds.length - 1}
              paneId={paneId}
            />
          ))}
        </div>
      </Conditional>
    </>
  );
};

const SectionWrapper: React.FC<{
  isFirst: boolean;
  sectionName: string;
  workType: WorkTypes;
  presets: React.MutableRefObject<Partial<IProjectObj>>;
  canEditEntity: boolean;
  paneId: ChunkDestination;
  groupId?: string;
  isClosed?: boolean;
}> = ({
  isFirst,
  sectionName,
  children,
  workType,
  presets,
  paneId,
  canEditEntity,
  groupId,
  isClosed,
}) => {
  return (
    <div
      className={
        roadmapStyles.roadmapSection +
        // (isClosed ? " disabled" : "") +
        (isFirst ? ` ${roadmapStyles.noTopBorder}` : "") +
        (workType === WorkTypes.PROJECT
          ? ` ${roadmapStyles.milestoneSection}`
          : "")
      }
      style={
        workType === WorkTypes.INITIATIVE ? { marginBottom: "66px" } : undefined
      }
    >
      <div className={roadmapStyles.sectionContent}>
        {sectionName && (
          <h3 className={`${roadmapStyles.sectionHeader}`}>
            <span
              className={`${roadmapStyles.overlay}`}
              style={{
                paddingLeft: "6px",
                paddingRight: "6px",
                marginLeft: "-6px",
                marginRight: "-6px",
              }}
            >
              <span className={`${roadmapStyles.sectiontext}`}>
                {sectionName}
              </span>
            </span>
            <span className={roadmapStyles.overlay}>
              <MilestoneMoreMenu
                canEditEntity={canEditEntity}
                presets={presets.current}
                groupId={groupId}
                paneId={paneId}
              ></MilestoneMoreMenu>
            </span>
          </h3>
        )}
        {children}
      </div>
    </div>
  );
};

const MilestoneMoreMenu: React.FC<{
  presets: Partial<IProjectObj>;
  paneId: ChunkDestination;
  groupId?: string;
  canEditEntity: boolean;
}> = ({ presets, paneId, groupId, canEditEntity }) => {
  const [ismenuOpened, setIsmenuOpened] = useState(false);
  const storeData = useShallowSelector((store) => ({
    baseType: store.workspace.type,
    isBaseMember: store.client?.isBaseMember,
    isGuest: store.client.roleType === UserRole.GUEST,
    userId: store.user?.id,
  }));

  const checkIfIsDisabeld = () => {
    if (storeData.baseType === BaseType.Public) {
      if (!storeData.isBaseMember || storeData.isGuest) return false;
    }
    return !canEditEntity;
  };

  const checkShowAffordance = () => {
    let visitMode: ActionIntercept | undefined = ActionIntercept.default;
    const getActionIntercept = () => {
      if (!canEditEntity) {
        if (storeData.baseType === BaseType.Public) {
          if (!storeData.isBaseMember || storeData.isGuest) {
            if (!storeData.userId)
              return ActionIntercept.notAuthUserInPublicBase;
            if (!storeData.isBaseMember)
              return ActionIntercept.authedUserInPublicBase;
            if (storeData.isGuest) return ActionIntercept.guestInPublicBase;
          }
        }
      }
      return;
    };

    visitMode = getActionIntercept();

    if (visitMode) setActionInterceptor(visitMode);
  };

  const createProject = (e: any) => {
    checkShowAffordance();
    if (!canEditEntity) return;
    createAndSetNewItem(e, WorkTypes.PROJECT, presets, paneId, groupId);
    setIsmenuOpened(false);
  };

  const createGoal = (e: any) => {
    checkShowAffordance();
    if (!canEditEntity) return;
    createAndSetNewItem(e, WorkTypes.INITIATIVE, presets, paneId, groupId);
    setIsmenuOpened(false);
  };

  const handleVisibleChange = (flag: boolean) => {
    setIsmenuOpened(flag);
  };

  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={ismenuOpened}
      overlay={
        <Menu>
          <Menu.Item
            key="project"
            disabled={checkIfIsDisabeld()}
            onClick={(e: any) => createProject(e)}
          >
            <span className={roadmapStyles.menuItemTitle}>
              <span className={roadmapStyles.menuItemIcon}>
                <FileDoneOutlined />
              </span>
              <span className={roadmapStyles.menuItemTitle}> Project</span>
            </span>
          </Menu.Item>
          <Menu.Item
            key="goal"
            disabled={checkIfIsDisabeld()}
            onClick={(e: any) => createGoal(e)}
          >
            <span className={roadmapStyles.menuItemTitle}>
              <span className={roadmapStyles.menuItemIcon}>
                <AimOutlined />
              </span>
              <span className={roadmapStyles.menuItemTitle}> Goal</span>
            </span>
          </Menu.Item>
        </Menu>
      }
      trigger={["click"]}
    >
      <Button
        disabled={!canEditEntity}
        buttonType={ButtonTypes.MEDIUM_PLAIN}
        onClick={(e: any) => {
          e.stopPropagation();
          setIsmenuOpened(false);
        }}
        icon={<PlusOutlined />}
      ></Button>
    </Dropdown>
  );
};

const RoadmapInitiativeItem: React.FC<{
  id: string;
  isFirst: boolean;
  isLast: boolean;
  paneId: ChunkDestination;
  canEditEntity: boolean;
}> = ({ id, isFirst, isLast, paneId, canEditEntity }) => {
  const workItem = useSelector(
    (state: ClarityStore) => state.work.dict[id],
    shallowEqual
  );

  const getclassNames = useCallback(() => {
    return (
      roadmapStyles.initiativeItem +
      (isFirst ? ` ${roadmapStyles.noTopBorder}` : "") +
      (isLast ? ` ${roadmapStyles.lastItem}` : "")
    );
  }, [isFirst, isLast]);

  const navigate = useCallback(
    (e: React.MouseEvent) => {
      if (!workItem) return () => {};
      navigationApi.contextBasedNavigate({
        currentPane: paneId,
        forceToPane: ChunkDestination.peek,
        shiftKey: e.shiftKey,
        navigationChunk: {
          viewName: ViewNames.Detail,
          entity: {
            containerId: id,
            containerType: ContainerTypes.WORK,
          },
        },
      });
      e.stopPropagation();
      e.preventDefault();
    },
    [workItem?.projectId, paneId]
  );

  const handleAssigneeClick = () => {
    if (!workItem?.id) return;
    openCommandPalette(CommandPaletteContext.ASSIGNEE, {
      selectedItemIds: [workItem.id],
      slectedItemsType: ContainerTypes.WORK,
    });
  };

  return useMemo(() => {
    if (!workItem) return <></>;
    return (
      <div className={getclassNames()} onClick={navigate}>
        <div className={roadmapStyles.initiativeHeader}>
          <h4 className={roadmapStyles.itemCaption}>{workItem.name}</h4>
        </div>
        <div className={roadmapStyles.initiativeFooter}>
          <span
            className={roadmapStyles.initiativeLead}
            onClick={handleAssigneeClick}
          >
            <UserDisplay
              id={workItem.assigneeId}
              hideName={true}
              avatarSize={28}
            />
          </span>
          <Conditional on={workItem.dueDate}>
            <span className={roadmapStyles.initiativeDueDate}>
              <DueDateDisplay
                dueDate={workItem.dueDate}
                isClosed={workItem.isClosed}
                editId={canEditEntity ? workItem.id : undefined}
                extraClass={roadmapStyles.dueDate}
              />
            </span>
          </Conditional>

          <div className={roadmapStyles.progressHolder}>
            <WorkProgress
              childrenAggregate={workItem.childrenAggregate}
              item={workItem}
              completed={workItem.isClosed}
              type="minimal"
            />
          </div>
        </div>
      </div>
    );
  }, [workItem, isFirst, isLast]);
};

const RoadmapProjectItem: React.FC<{
  id: string;
  isFirst: boolean;
  isLast: boolean;
  paneId: ChunkDestination;
  canEditEntity: boolean;
}> = ({ id, isFirst, isLast, paneId, canEditEntity }) => {
  const workItem = useSelector(
    (state: ClarityStore) => state.work.dict[id],
    shallowEqual
  );

  const getclassNames = useCallback(() => {
    return (
      roadmapStyles.projectItem + (isLast ? ` ${roadmapStyles.lastItem}` : "")
    );
  }, [isFirst, isLast]);

  const navigate = useCallback(
    (e: React.MouseEvent) => {
      if (!workItem) return;
      navigationApi.contextBasedNavigate({
        currentPane: paneId,
        shiftKey: e.shiftKey,
        forceToPane: ChunkDestination.peek,
        navigationChunk: {
          viewName: ViewNames.Detail,
          entity: {
            containerId: id,
            containerType: ContainerTypes.WORK,
            workType: workItem.workType,
          },
        },
      });

      e.stopPropagation();
      e.preventDefault();
    },
    [workItem?.projectId, paneId]
  );

  const handleAssigneeClick = () => {
    if (!workItem?.id) return;
    openCommandPalette(CommandPaletteContext.ASSIGNEE, {
      selectedItemIds: [workItem.id],
      slectedItemsType: ContainerTypes.WORK,
    });
  };

  const handleDueDateClick = () => {
    if (!workItem?.id) return;
    openCommandPalette(PaletteContexts.DUE_DATE, {
      selectedItemIds: [workItem.id],
      slectedItemsType: ContainerTypes.WORK,
    });
  };

  return useMemo(() => {
    if (!workItem) return <></>;
    const statusId = workItem.statusId ?? 0;
    const status = store.getState().work.statuses?.dict?.[statusId];
    return (
      <div className={`${getclassNames()} ${roadmapStyles.extraCss}`}>
        <div className={roadmapStyles.itemHead}>
          <ItemDetails
            workItem={workItem}
            showChevron={false}
            onClickHandler={navigate}
          />
        </div>
        <div className={roadmapStyles.itemTail}>
          <span
            className={`${roadmapStyles.status} ${roadmapStyles.field} ${
              roadmapStyles.column
            } ${status?.categoryId === "4" ? roadmapStyles.projectDone : ""}`}
          >
            <ItemStatus workItem={workItem} canEditEntity={canEditEntity} />
          </span>
          <span
            className={`${roadmapStyles.lead} ${roadmapStyles.field} ${
              roadmapStyles.column
            } ${workItem.isClosed ? roadmapStyles.projectDone : ""}`}
            onClick={canEditEntity ? handleAssigneeClick : undefined}
          >
            <UserDisplay
              id={workItem.assigneeId}
              hideName={!workItem.assigneeId}
              customStyles={{ width: "100%" }}
              labelClassname="exerpt"
              avatarSize={22}
              hideTooltip={true}
            />
          </span>
          <span
            className={`${roadmapStyles.due} ${roadmapStyles.field} ${roadmapStyles.column}`}
          >
            {workItem.dueDate ? (
              <DueDateDisplay
                dueDate={workItem.dueDate}
                isClosed={workItem.isClosed}
                editId={canEditEntity ? workItem.id : undefined}
              />
            ) : (
              <span
                className={`${roadmapStyles.dueNone}`}
                onClick={canEditEntity ? handleDueDateClick : () => {}}
              >
                None
              </span>
            )}
          </span>

          <span
            className={`${roadmapStyles.progress} ${roadmapStyles.field} ${roadmapStyles.column}`}
          >
            <WorkProgress
              childrenAggregate={workItem.childrenAggregate}
              item={workItem}
              completed={status?.categoryId === "4"}
              type="minimal"
            />
          </span>
          <span
            className={`${roadmapStyles.menu} ${roadmapStyles.field} ${roadmapStyles.column}`}
          >
            <ProjectOptions
              workItem={workItem}
              customClass={roadmapStyles.visibility}
            />
          </span>
        </div>
      </div>
    );
  }, [workItem, isFirst, isLast]);
};

const ProjectHeader: React.FC = () => {
  return (
    <div
      className={`${roadmapStyles.projectItem} ${roadmapStyles.noTopBorder} ${roadmapStyles.noPadding} ${roadmapStyles.noHover}`}
      style={{
        cursor: "unset",
        textTransform: "uppercase",
        paddingBottom: "4px",
      }}
    >
      <div className={`${roadmapStyles.itemHead} label medium disabled`}>
        <span
          className={`${roadmapStyles.column} ${roadmapStyles.titleColumn} ${roadmapStyles.headings}`}
        >
          Project title
        </span>
      </div>
      <div className={`${roadmapStyles.itemTail} label medium disabled`}>
        <span
          className={`${roadmapStyles.status} ${roadmapStyles.field} ${roadmapStyles.column} ${roadmapStyles.headings}`}
        >
          Status
        </span>
        <span
          className={`${roadmapStyles.lead} ${roadmapStyles.field} ${roadmapStyles.column} ${roadmapStyles.headings}`}
        >
          Lead
        </span>
        <span
          className={`${roadmapStyles.due} ${roadmapStyles.field} ${roadmapStyles.column} ${roadmapStyles.headings}`}
        >
          Target
        </span>

        <span
          className={`${roadmapStyles.progress} ${roadmapStyles.field} ${roadmapStyles.column} ${roadmapStyles.headings}`}
        >
          Progress
        </span>
        <span
          className={`${roadmapStyles.menu} ${roadmapStyles.field} ${roadmapStyles.column} ${roadmapStyles.headings}`}
        ></span>
      </div>
    </div>
  );
};

const ProjectOptions: React.FC<{
  workItem: IProjectObj;
  customClass: string;
}> = ({ workItem, customClass }) => {
  const base = prevState.value.workspace;
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item
            key="0"
            onClick={({ domEvent: e }) => {
              e.stopPropagation();
              locationSubject.next(getCorrectLink(base, workItem));
            }}
          >
            Open
          </Menu.Item>
          {deviceType.value !== DeviceType.mobile && (
            <Menu.Item
              key="1"
              onClick={({ domEvent: e }) => {
                e.stopPropagation();
                navigationApi.openSplitView({
                  viewName: ViewNames.Detail,
                  containerId: workItem.id,
                  containerType: ContainerTypes.WORK,
                });
              }}
            >
              Open in split
            </Menu.Item>
          )}
        </Menu>
      }
      trigger={["hover"]}
      placement="bottomRight"
    >
      <MoreHorizontalDots
        className={customClass}
        alt="more options menu"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
        }}
      />
    </Dropdown>
  );
};

const createAndSetNewItem = (
  event: React.MouseEvent,
  workType: WorkTypes,
  presets: Partial<IProjectObj>,
  paneId: ChunkDestination,
  groupId?: string
) => {
  const baseId = store.getState().workspace.id;
  const defaultGroupId = getCorrectGroupId(paneId, groupId);
  const cannotCreateWork = checkIfCannotCreateWork();
  if (cannotCreateWork) return;
  const shiftKey = event.shiftKey;
  return axiosInstance
    .post("/api/project/unnamed", {
      baseId,
      workType,
      presets: presets,
      groupId: defaultGroupId,
    })
    .then(({ data }) => {
      store.dispatch({
        type: ADD_NEW_PROJECT,
        project: data.payload,
      });
      navigationApi.contextBasedNavigate({
        currentPane: paneId,
        shiftKey,
        navigationChunk: {
          viewName: ViewNames.Detail,
          entity: {
            containerId: data.payload.id,
            containerType: ContainerTypes.WORK,
          },
        },
      });
    });
};

const getViewNameAsSubroute = (groupId?: string, assigneeId?: string) => {
  if (assigneeId) return "My Work";
  if (!groupId) return "Roadmap";
  else return store.getState().groups.dict[groupId].name ?? "";
};

export default Roadmap;
