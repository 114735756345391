import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import {
  setPaneTopNavViewType,
  TopNavbarType,
  useBtnComponentsSetter,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import { CloudDownloadOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  Abilities,
  IFilterState,
  IReward,
  TasksViewModes,
} from "utilities/types";
import store from "store/storeExporter";
import { batch } from "react-redux";
import styles from "./contributions/contributions.module.scss";
import {
  useContributionViewCount,
  useGetContributorViewItems,
} from "store/reducers/customWorkViewsReducer";
import Button, { ButtonTypes } from "components/Button";
import {
  preparePaidViewExport,
  prepareNotPaidViewExport,
} from "modules/taskService";
import moment from "moment";
import workApi from "clientApi/workApi";
import { tokenApi } from "clientApi/tokenApi";
import notificationsApi from "clientApi/notificationsApi";
import { useAbilityChecker } from "editor/utils/customHooks";
import navigationApi from "clientApi/navigationApi";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { defaultworkViewsDict } from "./defaultWorkView/types";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import PayoutsGroupSelector from "./contributions/PayoutsGroupSelector";
import ConfirmModal from "clarity-ui/ConfirmModal";

export const contributionViews = [
  TasksViewModes.UnclaimedContributions,
  TasksViewModes.UnstartedContributions,
  TasksViewModes.InProgressContributions,
  TasksViewModes.AwitingRewardApprovalContributions,
  TasksViewModes.ApprovedForPaymentContributions,
  TasksViewModes.PaidContributions,
];

const Payouts: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  usePageDataSetter(paneId, {
    title: "Payouts",
  });

  useEffect(() => {
    batch(() => {
      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.base,
      });
    });

    return () => {
      batch(() => {
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, []);

  useBtnComponentsSetter(paneId, { btnComponents: [] });

  return (
    <>
      <TopNavFiltersBar paneId={paneId}>
        <div style={{ flexGrow: "1" }}>
          <PayoutsGroupSelector />
        </div>
      </TopNavFiltersBar>

      <div className={styles.scrollContainer}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.item} style={{ paddingBottom: 0 }}>
              <span className="caption regular secondary">
                Discover, manage, and pay out contributions that have rewards
              </span>
            </div>
          </div>
          {contributionViews.map((viewId) => (
            <ContibutionView key={viewId} viewId={viewId} paneId={paneId} />
          ))}
        </div>
      </div>
    </>
  );
};

const ContibutionView: React.FC<{
  viewId: TasksViewModes;
  paneId: ChunkDestination;
}> = ({ viewId, paneId }) => {
  const contributionView = defaultworkViewsDict[viewId];

  const groupContextId = useShallowSelector((state) => {
    return state.client.paneGroupContext[paneId]?.groupId;
  });

  const handleOpen = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    navigationApi.contextBasedNavigate({
      currentPane: paneId,
      shiftKey: e.shiftKey,
      navigationChunk: {
        viewName: contributionView.path as any,
        taskViewMode: viewId as TasksViewModes,
      },
    });
  };

  return (
    <div className={`${styles.row} ${styles.itemRow}`} onClick={handleOpen}>
      <div className={styles.item}>
        <div className={styles.top}>
          <div className={styles.head}>
            <h4 className="h4 medium">{contributionView.title}</h4>
            <span className="caption regular secondary">
              {contributionView.description}
            </span>
          </div>
          <div className={styles.tail}>
            <ViewCounter
              paneId={paneId}
              groupId={groupContextId}
              getFilters={contributionView.getFilters}
            />
          </div>
        </div>
        {[
          TasksViewModes.ApprovedForPaymentContributions,
          TasksViewModes.PaidContributions,
        ].includes(viewId) && (
          <div className={styles.bottom}>
            <RowBottom
              viewMode={contributionView.viewMode}
              getFilters={contributionView.getFilters}
              groupId={groupContextId}
              paneId={paneId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ViewCounter: React.FC<{
  getFilters: (paneId?: ChunkDestination) => IFilterState;
  paneId: ChunkDestination;
  groupId?: string;
}> = memo(({ getFilters, paneId, groupId }) => {
  const [filters, setfilters] = useState(getFilters(paneId));

  useLayoutEffect(() => {
    setfilters(getFilters(paneId));
  }, [paneId, groupId, getFilters]);

  const counter = useContributionViewCount(filters);
  return <h2 className="h2 bold disabled">{counter}</h2>;
});

const RowBottom: React.FC<{
  viewMode: TasksViewModes;
  getFilters: (paneId?: ChunkDestination) => IFilterState;
  paneId: ChunkDestination;
  groupId?: string;
}> = ({ getFilters, viewMode, groupId, paneId }) => {
  const [filters, setfilters] = useState(getFilters(paneId));
  const userGroups = useShallowSelector((store) => store.groups.userGroups);
  const groupDict = useShallowSelector((store) => store.groups.dict);

  useLayoutEffect(() => {
    setfilters(getFilters(paneId));
  }, [paneId, groupId, getFilters]);

  const items = useGetContributorViewItems(filters);

  const prepareData = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (viewMode === TasksViewModes.ApprovedForPaymentContributions) {
      let name = "";
      if (groupId) {
        const group = groupDict[groupId];
        if (group) {
          name = `Approved for Payment for ${group.name} — `;
        }
      } else {
        let groupNames: string[] = [];
        userGroups.forEach((groupId) => {
          const group = groupDict[groupId];
          if (group) {
            groupNames.push(group.name);
          }
        });
        if (userGroups.length > 1) groupNames = ["Multiple Groups"];
        name = `Approved for Payment for ${groupNames.join(", ")} — `;
      }

      const zone = new Date()
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2];
      name = name + moment().format("MMM D, YYYY @ hh-mm-ss A") + " " + zone;

      prepareNotPaidViewExport(items, name);
    }

    if (viewMode === TasksViewModes.PaidContributions) {
      let name = "";
      if (groupId) {
        const group = groupDict[groupId];
        if (group) {
          name = `Paid Contributions for ${group.name} — `;
        }
      } else {
        let groupNames: string[] = [];
        if (userGroups.length > 1) groupNames = ["Multiple Groups"];
        name = `Paid Contributions for ${groupNames.join(", ")} — `;
      }

      const zone = new Date()
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2];
      name = name + moment().format("MMM D, YYYY @ hh-mm-ss A") + " " + zone;
      preparePaidViewExport(items, name);
    }
  };

  const canManageReward = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_PAYMENTS,
  });

  const { isLoading, markAllAsPaid } = useTokenPriceExporterState(items);
  const [isConfirmationModalVisible, setisConfirmationModalVisible] =
    useState(false);

  return (
    <>
      <Button
        disabled={items.length === 0 || !canManageReward}
        onClick={canManageReward ? prepareData : undefined}
        icon={<CloudDownloadOutlined />}
      >
        Export to CSV
      </Button>
      {viewMode === TasksViewModes.ApprovedForPaymentContributions && (
        <Button
          disabled={items.length === 0 || isLoading || !canManageReward}
          onClick={(e: any) => {
            e.stopPropagation();
            setisConfirmationModalVisible(true);
          }}
          icon={<CheckCircleOutlined />}
          isLoading={isLoading}
          buttonType={ButtonTypes.PRIMARY}
        >
          Mark all as paid
        </Button>
      )}
      {isConfirmationModalVisible && (
        <ConfirmModal
          onConfirm={canManageReward ? (markAllAsPaid as () => void) : () => {}}
          confirmationText="Mark all as paid"
          title="Mark as paid"
          confirmDisabled={!canManageReward}
          close={() => setisConfirmationModalVisible(false)}
        >
          <ConfirmationModalContent groupId={groupId} />
        </ConfirmModal>
      )}
    </>
  );
};

const ConfirmationModalContent: React.FC<{ groupId?: string }> = ({
  groupId,
}) => {
  const userGroups = useShallowSelector((store) => store.groups.userGroups);
  const groupDict = useShallowSelector((store) => store.groups.dict);

  const groups = groupId ? [groupId] : userGroups;

  return (
    <div className={"body2 regular secondary"}>
      <p>
        You are about to mark all unpaid rewards as paid for the following
        groups:{" "}
      </p>
      <ul>
        {groups.map((id) => (
          <li>{groupDict[id]?.name}</li>
        ))}
      </ul>
    </div>
  );
};

const useTokenPriceExporterState = (items: string[]) => {
  const [isLoading, setisLoading] = useState(false);

  const markAllAsPaid = async (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setisLoading(true);
    const workDict = store.getState().work.dict;

    const rewards = items.map((itemId) => {
      const workItem = workDict[itemId];
      return workItem.reward ?? ({} as IReward);
    });

    tokenApi
      .getTokenPricesForExport(rewards)
      .then(() => {
        batch(() => {
          const length = items.length;
          const deltas: any = items
            .map((itemId) => {
              const workItem = workDict[itemId];
              return workItem;
            })
            .filter((item) => {
              return item.reward !== undefined;
            })
            .map((item) => {
              return {
                id: item.id,
                reward: {
                  ...item.reward,
                  isPaid: true,
                  datePaid: new Date(),
                },
              };
            });
          workApi.updateWorkItems(deltas);
          setisLoading(false);
          notificationsApi.displayConfirmation({
            title: "Success!",
            body: `You've marked ${length} contribution${
              length > 1 ? "s" : ""
            } as paid`,
          });
        });
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
        notificationsApi.displayError({
          title: "Could not mark as paid",
          body: "Something went worng, please try again",
        });
      });
  };

  return { isLoading, markAllAsPaid };
};

export default Payouts;
