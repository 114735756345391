import Conditional from "components/Conditional";
import { partial } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useCommandPaletteShower } from "store/reducers/commandPaletteReducer";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import {
  CommandPaletteContext,
  ContainerTypes,
  IProjectObj,
  WorkTypes,
} from "utilities/types";
import { ReactComponent as ChevronRight } from "icons/chevron-right.svg";
import styles from "../boardView/kanban.module.scss";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import DueDateDisplay from "clarity-ui/DueDateDisplay";
import PriorityDisplay from "components/PriorityDisplay";
import CycleTitle from "clarity-ui/workItem/CycleTitle";
import WorkLabels from "components/WorkLabels";
import UserDisplay from "clarity-ui/UserDisplay";
import { getCorrectPrefixForWorkItem } from "modules/containerHelpers";
import Icon from "@ant-design/icons";

function getStyle(style: any, snapshot: DraggableStateSnapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.005s`,
  };
}

const KanabanCard: React.FC<{
  id: string;
  canEditEntity: boolean;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  isTargetTrackCard: boolean;
  handleTaskClick: (id: string) => void;
}> = ({
  id,
  canEditEntity,
  provided,
  snapshot,
  isTargetTrackCard,
  handleTaskClick,
}) => {
  const workItem = useShallowSelector((state) => state.work.dict[id]);
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (cardRef.current && provided) {
      provided.innerRef(cardRef.current);
    }
  }, []);

  return useMemo(() => {
    if (!workItem || !workItem.id) return <></>;

    return (
      <div
        onClick={() => handleTaskClick(workItem.id)}
        ref={cardRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={[
          styles.card,
          ...(!snapshot.draggingOver && isTargetTrackCard
            ? [styles.cardTargetTrack]
            : []),
        ].join(" ")}
        style={getStyle(provided.draggableProps.style, snapshot)}
      >
        <CardHeader item={workItem} canEditEntity={canEditEntity} />
        <div className={styles.cardBody}>
          <div className={styles.cardBodyTitle}>{workItem.name}</div>
        </div>
        <CardFooter item={workItem} canEditEntity={canEditEntity} />
      </div>
    );
  }, [workItem, canEditEntity, provided]);
};

const CardHeader: React.FC<{
  item: IProjectObj;
  canEditEntity: boolean;
}> = ({ item, canEditEntity }) => {
  const paletteClickHandler = usePaletteClickHandlerMaker(canEditEntity);
  const ctxTypes = CommandPaletteContext;
  const parent = useShallowSelector((state) =>
    item.parentId ? state.work.dict[item.parentId] : undefined
  );
  const avatarClassName = useOptionalClassName({
    baseStyle: styles.avatarContainer,
    pairs: [
      {
        extraStyle: styles.footerChild,
        withExtra: true,
      },
    ],
  });

  return (
    <div className={styles.cardHeader}>
      <div className={styles.workId}>
        <span>{getCorrectPrefixForWorkItem(item)}</span>
        <Conditional on={parent}>
          <Icon className={styles.chevron} component={ChevronRight} />
          <span className={styles.parentName}>{parent?.name}</span>
        </Conditional>
      </div>
      <div
        className={avatarClassName}
        onClick={
          canEditEntity
            ? partial(paletteClickHandler(ctxTypes.ASSIGNEE), item)
            : () => {}
        }
      >
        <UserDisplay hideName={true} avatarSize={21} id={item.assigneeId} />
      </div>
    </div>
  );
};

const CardFooter: React.FC<{
  item: IProjectObj;
  canEditEntity: boolean;
}> = ({ item, canEditEntity }) => {
  const group = useShallowSelector((store) => store.groups.dict[item.groupId]);

  const paletteClickHandler = usePaletteClickHandlerMaker(canEditEntity);
  const ctxTypes = CommandPaletteContext;
  return (
    <>
      <div className={styles.cardFooter}>
        <Conditional on={item.dueDate}>
          <div
            onClick={partial(paletteClickHandler(ctxTypes.DUE_DATE), item)}
            className={styles.footerChild}
          >
            <DueDateDisplay dueDate={item.dueDate} isClosed={item.isClosed} />
          </div>
        </Conditional>
        <Conditional on={item.priority > 1}>
          <div
            onClick={
              canEditEntity
                ? partial(paletteClickHandler(ctxTypes.PRIORITY), item)
                : () => {}
            }
            className={styles.footerChild}
          >
            <PriorityDisplay
              showName={false}
              priorityLevel={item.priority}
              size={"large"}
            />
          </div>
        </Conditional>
        <Conditional
          on={
            item.workSectionId &&
            item.workType === WorkTypes.TASK &&
            group.showCycles
          }
        >
          <div
            onClick={partial(paletteClickHandler(ctxTypes.WORK_SECTION), item)}
            className={styles.footerChild}
          >
            <CycleTitle cycleId={item.workSectionId as unknown as string} />
          </div>
        </Conditional>
      </div>
      <Conditional on={item && item.tags?.length}>
        <div className={styles.cardFooterTags}>
          <WorkLabels
            workItem={item}
            containerClass={styles.workLabelsContainer}
            tagClass={styles.workLabel}
            noContainer
          />
        </div>
      </Conditional>
    </>
  );
};

function usePaletteClickHandlerMaker(canEditEntity: boolean) {
  const showCommandPalette = useCommandPaletteShower();
  return (ctxType: CommandPaletteContext) => (item: IProjectObj, event: any) => {
    event.stopPropagation();
    if (!canEditEntity) {
      event.preventDefault();
      return;
    }

    showCommandPalette(ctxType, {
      selectedItemIds: [item.id],
      slectedItemsType: ContainerTypes.WORK,
    });
  };
}

export default KanabanCard;
