import React from "react";
import styles from "./unexpectedError/styles.module.scss";
import Logo from "images/clarity-logo-black.svg";
import Button, { ButtonTypes } from "components/Button";

const UnexpectedError: React.FC<{}> = () => {
  return (
    <div className={styles.notFoundContainer}>
      <img
        src={Logo}
        style={{ width: "105px", marginBottom: "48px", opacity: "0.8" }}
        alt="Clarity Logo"
      />
      <h3>The connection was interrupted</h3>
      <div className="body primary" style={{ marginBottom: "32px" }}>
        This could be due to an issue on our server, or because your device lost
        connection to the Internet
      </div>
      {
        <>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            onClick={() => {
              window.location.replace(`/`);
            }}
          >
            Return to my content
          </Button>
        </>
      }
    </div>
  );
};

export default UnexpectedError;
