import {
  ADD_MENTION,
  LOAD_UNLINKED_MENTIONS,
  REMOVE_MENTION,
  SET_MENTION_OBJECTS,
} from "store/actions";
import { ContainerTypes } from "utilities/types";

export interface ContainerMentions {
  containers: { [id: string]: ISingleMention };
  blockCount?: number;
}

export interface MentionDataObj {
  [id: string]: ContainerMentions;
}

export interface ISingleMention {
  containerId: string;
  containerType: ContainerTypes;
  projectId?: number;
  blocks: string[];
}

interface IMentionsState {
  dict: MentionDataObj;
  mentionIdToContainerIndex: { [id: string]: string };
  unlinkedDict: MentionDataObj;
}

const initialState = {
  dict: {} as MentionDataObj,
  unlinkedDict: {} as MentionDataObj,
  mentionIdToContainerIndex: {},
};

const getCorrectDict = (
  type: "linked" | "unlinked"
): "dict" | "unlinkedDict" => {
  return type === "linked" ? "dict" : "unlinkedDict";
};

const handleAddMention = (
  mentionData: {
    blockId: string;
    containerId: string;
    containerType: ContainerTypes;
    referencingContainerId: string;
  },
  newState: IMentionsState,
  type: "linked" | "unlinked"
) => {
  const dict = getCorrectDict(type);
  newState[dict] = { ...newState[dict] };
  if (!newState[dict][mentionData.referencingContainerId]) {
    newState[dict][mentionData.referencingContainerId] = { containers: {} };
  } else
    newState[dict][mentionData.referencingContainerId] = {
      ...newState[dict][mentionData.referencingContainerId],
      containers: {
        ...newState[dict][mentionData.referencingContainerId].containers,
      },
    };

  const refDictEntry = newState[dict][mentionData.referencingContainerId];

  if (!refDictEntry.containers[mentionData.containerId])
    refDictEntry.containers[mentionData.containerId] = {
      containerId: mentionData.containerId,
      containerType: mentionData.containerType,
      blocks: [],
    };
  else
    refDictEntry.containers[mentionData.containerId] = {
      ...refDictEntry.containers[mentionData.containerId],
    };
  const containerMentionEntry = {
    ...refDictEntry.containers[mentionData.containerId],
  };
  containerMentionEntry.blocks = [...containerMentionEntry.blocks];
  if (containerMentionEntry.blocks.includes(mentionData.blockId)) return;
  containerMentionEntry.blocks.push(mentionData.blockId);

  refDictEntry.containers[mentionData.containerId] = containerMentionEntry;
  newState[dict][mentionData.referencingContainerId] = refDictEntry;
  delete newState[dict][mentionData.referencingContainerId].containers[
    mentionData.referencingContainerId
  ];
};

const handleRemoveMention = (
  mentionData: {
    blockId: string;
    containerId: string;
    containerType: ContainerTypes;
    referencingContainerId: string;
  },
  newState: IMentionsState,
  type: "linked" | "unlinked"
) => {
  const dict = getCorrectDict(type);
  newState[dict] = { ...newState[dict] };
  // newState[dict].containers = { ...newState[dict].containers };

  if (!newState[dict][mentionData.referencingContainerId]) {
    return;
  } else
    newState[dict][mentionData.referencingContainerId] = {
      ...newState[dict][mentionData.referencingContainerId],
      containers: {
        ...newState[dict][mentionData.referencingContainerId].containers,
      },
    };

  const refDictEntry = {
    ...newState[dict][mentionData.referencingContainerId],
  };

  if (!refDictEntry.containers[mentionData.containerId]) return;
  else
    refDictEntry.containers[mentionData.containerId] = {
      ...refDictEntry.containers[mentionData.containerId],
    };
  const containerMentionEntry =
    refDictEntry.containers[mentionData.containerId];
  containerMentionEntry.blocks = [...containerMentionEntry.blocks];
  const index = containerMentionEntry.blocks.indexOf(mentionData.blockId);

  containerMentionEntry.blocks.splice(index, 1);

  let blockCount =
    newState[dict][mentionData.referencingContainerId].blockCount;
  blockCount = blockCount ? blockCount - 1 : 0;
  refDictEntry.containers[mentionData.containerId] = containerMentionEntry;

  newState[dict][mentionData.referencingContainerId] = refDictEntry;
  newState[dict][mentionData.referencingContainerId].blockCount =
    blockCount === 0 ? undefined : blockCount;
  if (newState[dict][mentionData.referencingContainerId].blockCount === 0) {
    delete newState[dict][mentionData.referencingContainerId].containers[
      mentionData.containerId
    ];
  }
  // if (containerMentionEntry.blocks.length === 0) delete refDictEntry[mentionData.containerId];
};

const mentionsReducer = (state: IMentionsState = initialState, action: any) => {
  switch (action.type) {
    case SET_MENTION_OBJECTS: {
      const newState: IMentionsState = {
        ...state,
        dict: { ...state.dict },
        mentionIdToContainerIndex: { ...state.mentionIdToContainerIndex },
      };
      const refContainerId = action.param.containerId;
      const mentionContainers = action.param.containers.documents;
      const mentions = action.param.containers.mentions;
      const keys = Object.keys(mentionContainers);

      Object.values(mentionContainers).forEach((entry: any, index) => {
        const containerId = keys[index];
        const blockIdsWithMention = entry.rootLinesId;
        if (!newState.dict[refContainerId]) {
          newState.dict[refContainerId] = {
            containers: {
              [containerId]: {
                containerId: entry.containerId,
                containerType: entry.containerType,
                blocks: blockIdsWithMention,
              },
            },
          };
        } else {
          newState.dict[refContainerId] = {
            ...newState.dict[refContainerId],
            containers: {
              ...newState.dict[refContainerId].containers,
              [containerId]: {
                containerId: entry.containerId,
                containerType: entry.containerType,
                blocks: blockIdsWithMention,
              },
            },
          };
        }
      });

      if (mentions) {
        Object.values(mentions).forEach((entry: any, index) => {
          const mentionId = entry.mentionId;
          const containerId = entry.containerId;
          newState.mentionIdToContainerIndex[mentionId] = containerId;
        });
      }

      return newState;
    }

    case LOAD_UNLINKED_MENTIONS: {
      const newState = { ...state, unlinkedDict: { ...state.unlinkedDict } };
      const refContainerId = action.param.containerId;
      const mentionContainers = action.param.containers.documents;
      const keys = Object.keys(mentionContainers);

      newState.unlinkedDict[refContainerId] = {
        containers: {},
        blockCount: action.param.blockCount,
      };

      Object.values(mentionContainers).forEach((entry: any, index) => {
        const containerId = keys[index];
        const blockIdsWithMention = entry.rootLinesId;
        newState.unlinkedDict[refContainerId].containers[containerId] = {
          containerId: entry.containerId,
          containerType: entry.containerType,
          blocks: blockIdsWithMention,
        };
      });
      return newState;
    }

    case ADD_MENTION: {
      const newState = { ...state };
      const blockParam = {
        blockId: action.param.blockId,
        containerId: action.param.containerId,
        containerType: action.param.containerType,
        referencingContainerId: action.param.referencingContainerId,
      };
      if (blockParam.containerId === "newComment") return state;

      handleAddMention(blockParam, newState, "linked");
      handleRemoveMention(blockParam, newState, "unlinked");
      return newState;
    }

    case REMOVE_MENTION: {
      const newState = { ...state };
      const blockParam = {
        blockId: action.param.blockId,
        containerId: action.param.containerId,
        containerType: action.param.containerType,
        referencingContainerId: action.param.referencingContainerId,
      };
      handleRemoveMention(blockParam, newState, "linked");
      handleAddMention(blockParam, newState, "unlinked");
      return newState;
    }

    default:
      return state;
  }
};

export default mentionsReducer;
