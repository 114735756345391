import {
  QuestionOutlined,
  CommentOutlined,
  AuditOutlined,
  CalendarOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import FabBtn, {
  IFabBtnProps,
  MenuItem,
  useMenuControllerState,
} from "components/FabBtn";
import styles from "./helpFabBtn/helpFabBtn.module.scss";
import { useOptionalClassName } from "utilities/hooks";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { useEffect } from "react";
import { changelogApi } from "clientApi/changelogApi";

interface IHelpFabBtnProps {
  standalone?: boolean;
  menuYDir: IFabBtnProps["menuYDir"];
  menuXDir: IFabBtnProps["menuXDir"];
  btnClass?: string;
  containerClass?: string;
}

export default function HelpFabBtn({
  standalone,
  menuYDir,
  menuXDir,
  btnClass = "",
  containerClass = "",
}: IHelpFabBtnProps) {
  const { menuIsOpen, openMenu, closeMenu } = useMenuControllerState();
  const { btnClassName } = useHelpFabBtnState({ standalone, btnClass });

  return (
    <FabBtn
      open={openMenu}
      close={closeMenu}
      isOpen={menuIsOpen}
      icon={<QuestionOutlined />}
      containerClass={containerClass}
      btnClass={btnClassName}
      menuClass={styles.menu}
      menuYDir={menuYDir}
      menuXDir={menuXDir}
    >
      {/* <MenuItems close={closeMenu} /> */}
    </FabBtn>
  );
}

function useHelpFabBtnState({
  standalone,
  btnClass,
}: {
  standalone?: boolean;
  btnClass: string;
}) {
  const btnClassName = useOptionalClassName({
    baseStyle: btnClass,
    pairs: [
      {
        extraStyle: styles.standalone,
        withExtra: standalone,
      },
    ],
  });

  return {
    btnClassName,
  };
}

function useMenuState({ close }: { close: () => void }) {
  const openInNewTab = (href: string) => window.open(href, "_blank");

  const openManual = () =>
    openInNewTab(
      "https://app.clarity.so/wiki/tags/e1bdb0ed-065b-458d-a666-ba1672e2fcdb"
    );

  const openKeyboardShortcuts = () =>
    openInNewTab(
      "https://app.clarity.so/wiki/tags/b58cbc45-5acb-42cc-ab99-c50d95914b2a"
    );

  const openDiscordJoin = () => openInNewTab("https://discord.gg/hcgRk7Gth9");

  const openOfficeHours = () =>
    openInNewTab("https://calendly.com/richiebonilla/clarity-office-hours");

  const openSupportContact = () => {
    // showIntercom();
    openInNewTab("mailto:help@clarity.so");
    close();
  };

  return {
    openManual,
    openKeyboardShortcuts,
    openDiscordJoin,
    openOfficeHours,
    openSupportContact,
  };
}

interface IMenuItemsProps {
  close: () => void;
  readAllChangelog: boolean;
  showChangelog: () => void;
  disableChangelogs: boolean;
}

export function MenuItems({
  close,
  readAllChangelog,
  showChangelog,
  disableChangelogs,
}: IMenuItemsProps) {
  const { openManual, openDiscordJoin, openOfficeHours, openSupportContact } =
    useMenuState({ close });

  return (
    <>
      <MenuItem
        title="What’s new"
        description="See the latest product updates"
        icon={<GiftOutlined />}
        disabled={disableChangelogs}
        onClick={() => {
          if (showChangelog) {
            showChangelog();
          }
        }}
        badge={!readAllChangelog}
      ></MenuItem>
      <MenuItem
        title="Clarity User Manual"
        description="Documentation, tips, and other resources"
        icon={<AuditOutlined />}
        onClick={openManual}
      ></MenuItem>
      {/* <MenuItem
        title="Keyboard Shortcuts"
        description="Move at the speed of thought"
        icon={<MacCommandOutlined />}
        onClick={openKeyboardShortcuts}
        ></MenuItem> */}
      <MenuItem
        title="Join Discord Community"
        description="Ask questions, get involved, and follow along"
        icon={<CommentOutlined />}
        onClick={openDiscordJoin}
      ></MenuItem>
      <MenuItem
        title="Office Hours"
        description="Book a quick call with a member of our team"
        icon={<CalendarOutlined />}
        onClick={openOfficeHours}
      ></MenuItem>
      <MenuItem
        title="Contact Support"
        description="Email us at help@clarity.so"
        icon={<QuestionOutlined />}
        onClick={openSupportContact}
      ></MenuItem>
    </>
  );
}

export const ChangelogMenu: React.FC = () => {
  const changelogs = useSelector(
    (state: ClarityStore) => state.changelogs.ids,
    shallowEqual
  );

  const changelogDict = useSelector(
    (state: ClarityStore) => state.changelogs.changelogDict,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      changelogApi.readChangelogs();
    };
  }, []);

  return (
    <>
      {changelogs.map((id) => {
        const changelog = changelogDict[id];
        return (
          <MenuItem
            key={id}
            title={changelog.name}
            description={changelog.description}
            // icon={<QuestionOutlined />}
            onClick={() => {
              window.open(changelog.url, "_blank")?.focus();
            }}
            badge={!changelog.read}
          ></MenuItem>
        );
      })}
    </>
  );
};
