import { useEffect, useRef, useState } from "react";
import store from "store/storeExporter";
import { tableDndObserver, tableStoreData } from "../TableBlock";

export const TableColumnDragAndDropListener: React.FC<{ tableId: string }> = ({
  tableId,
}) => {
  const [position, setPosition] = useState(0);
  const [draggedColumn, setdraggedColumn] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const sub = tableDndObserver.subscribe((tableFocusData) => {
      if (tableFocusData.tableId === tableId) {
        if (tableFocusData.dragColumnId) {
          setPosition(tableFocusData.dragColumnPosition);
          setdraggedColumn(tableFocusData.dragColumnId);
          return;
        }
      }
      setPosition(0);
      setdraggedColumn(null);
    });
    return () => sub?.unsubscribe();
  }, []);

  useEffect(() => {
    if (draggedColumn) {
      const block = store.getState().blocks.dict[tableId];
      const rows = block.children;
      const newEl = document.createElement("table");
      const newBody = document.createElement("tbody");
      rows.forEach((row) => {
        const currentEl =
          tableStoreData.value[tableId].cellCache.current[row + draggedColumn];
        if (currentEl) {
          const row = document.createElement("tr");
          row.classList.add("tableRow");
          const copyEl = currentEl.cloneNode(true);
          const sizes = currentEl.getBoundingClientRect();
          const height = sizes.height;
          const width = sizes.width;
          (copyEl as HTMLElement).style.height = `${height}px`;
          (copyEl as HTMLElement).style.opacity = "1";
          newEl.style.minWidth = `${width}px`;
          newEl.style.maxWidth = `${width}px`;
          row.appendChild(copyEl);
          newBody.appendChild(row);
        }
      });
      if (ref.current) {
        ref.current.innerHTML = "";
        newEl.appendChild(newBody);
        ref.current.appendChild(newEl);
      }
    } else {
      if (ref.current) {
        ref.current.innerHTML = "";
      }
    }
  }, [draggedColumn]);

  if (!draggedColumn) return <></>;

  return (
    <div
      style={{
        left: position,
        top: "6px",
        position: "absolute",
        zIndex: 10,
        pointerEvents: "none",
        boxShadow:
          "0 2px 32px 0 rgb(0 0 0 / 10%), 0 5px 10px 0 rgb(0 0 0 / 8%), 0 1px 5px 0 rgb(0 0 0 / 5%)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
          position: "relative",
          background: "rgba(255, 255, 255, 1)",
          opacity: 0.9,
          border: "2px solid rgba(181, 101, 146, 0.3)",
        }}
        ref={ref}
      />
    </div>
  );
};

export const TableRowDragAndDropListener: React.FC<{ tableId: string }> = ({
  tableId,
}) => {
  const [position, setPosition] = useState(0);
  const [draggedRow, setdraggedRow] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const sub = tableDndObserver.subscribe((tableFocusData) => {
      if (tableFocusData.tableId === tableId) {
        if (tableFocusData.dragRowId) {
          setPosition(tableFocusData.dragRowPosition);
          setdraggedRow(tableFocusData.dragRowId);
          return;
        }
      }
      setPosition(0);
      setdraggedRow(null);
    });
    return () => sub?.unsubscribe();
  }, []);

  useEffect(() => {
    if (draggedRow) {
      const block = store.getState().blocks.dict[tableId];
      const columns = block.value[0].tableBlockColumnData?.order ?? [];

      const newEl = document.createElement("table");
      const newBody = document.createElement("tbody");
      const newRow = document.createElement("tr");
      newRow.classList.add("tableRow");

      columns.forEach((column) => {
        const currentEl =
          tableStoreData.value[tableId].cellCache.current[draggedRow + column];
        if (currentEl) {
          const copyEl = currentEl.cloneNode(true);
          const sizes = currentEl.getBoundingClientRect();
          const width = sizes.width;

          (copyEl as HTMLElement).style.width = `${width}px`;
          (copyEl as HTMLElement).style.minWidth = `${width}px`;
          (copyEl as HTMLElement).style.opacity = "1";
          newRow.appendChild(copyEl);
        }
      });

      if (ref.current) {
        ref.current.innerHTML = "";
        newBody.appendChild(newRow);
        newEl.appendChild(newBody);
        ref.current.appendChild(newEl);
      }
    } else {
      if (ref.current) {
        ref.current.innerHTML = "";
      }
    }
  }, [draggedRow]);

  if (!draggedRow) return <></>;

  return (
    <div
      style={{
        left: "-2px",
        top: position,
        position: "absolute",
        zIndex: 10,
        pointerEvents: "none",
        boxShadow:
          "0 2px 32px 0 rgb(0 0 0 / 10%), 0 5px 10px 0 rgb(0 0 0 / 8%), 0 1px 5px 0 rgb(0 0 0 / 5%)",
      }}
    >
      <div
        style={{
          flexShrink: 0,
          display: "flex",
          flexDirection: "row",
          position: "relative",
          background: "rgba(255, 255, 255, 1)",
          border: "2px solid rgba(181, 101, 146, 0.3)",
          opacity: 0.9,
        }}
        ref={ref}
      />
    </div>
  );
};
