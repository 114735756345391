import Icon from "@ant-design/icons";
import React from "react";

export default function HomeTwoTone(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        opacity="0.1"
        d="M12.0022 4.04572L3.3303 12.7106H5.5803V20.3442H9.18733V15.0004C9.18733 14.4824 9.60686 14.0629 10.1248 14.0629H13.8748C14.3928 14.0629 14.8123 14.4824 14.8123 15.0004V20.3442H18.4194V12.7106H20.6717L12.5436 4.58712L12.0022 4.04572ZM22.1858 13.9574C22.0451 14.0981 21.8787 14.2106 21.6983 14.2832C21.8787 14.2082 22.0451 14.0981 22.1858 13.9574ZM1.3967 13.1442C1.51389 13.8543 2.13264 14.3981 2.87561 14.3981H2.8967C2.14905 14.3981 1.51624 13.859 1.3967 13.1442ZM1.37561 12.8981V12.8535V12.8981ZM1.37795 12.8371C1.3803 12.7645 1.38967 12.6942 1.40139 12.6238C1.38733 12.6918 1.3803 12.7645 1.37795 12.8371Z"
        fill="currentColor"
      />
      <path
        d="M22.2892 11.9526C22.2892 11.9502 22.2869 11.9502 22.2869 11.9479L22.2447 11.8987C22.2424 11.8963 22.24 11.8916 22.2353 11.8893C22.2189 11.8705 22.2002 11.8518 22.1838 11.833L13.1275 2.78382L12.5205 2.17679C12.3822 2.03939 12.1951 1.96228 12.0002 1.96228C11.8052 1.96228 11.6182 2.03939 11.4799 2.17679L1.81658 11.8354C1.642 12.009 1.51317 12.2232 1.44158 12.4588L1.42751 12.508L1.42048 12.5338L1.41345 12.5619C1.40876 12.5784 1.40642 12.5948 1.40408 12.6112C1.40408 12.6135 1.40408 12.6182 1.40173 12.6205C1.38767 12.6909 1.38064 12.7612 1.3783 12.8338V12.9112C1.3783 12.9229 1.3783 12.9346 1.38064 12.9463C1.38064 12.958 1.38064 12.9674 1.38298 12.9791C1.38298 12.9909 1.38533 13.0026 1.38533 13.0143C1.38533 13.0284 1.38767 13.0424 1.39001 13.0565C1.39001 13.0635 1.39236 13.0705 1.39236 13.0776L1.39939 13.1362V13.1385C1.51892 13.8534 2.15408 14.3924 2.89939 14.3924H3.89548V22.0307H20.1072V14.3971H21.1244C21.326 14.3971 21.5205 14.3573 21.6986 14.2823C21.8767 14.2073 22.0431 14.0971 22.1861 13.9565C22.3257 13.8173 22.4364 13.6518 22.5116 13.4695C22.5869 13.2873 22.6252 13.0919 22.6244 12.8948C22.6244 12.5502 22.5072 12.2198 22.2892 11.9526ZM13.3127 20.3432H10.6877V15.5619H13.3127V20.3432ZM18.4197 12.7096V20.3432H14.8127V14.9994C14.8127 14.4815 14.3931 14.0619 13.8752 14.0619H10.1252C9.6072 14.0619 9.18767 14.4815 9.18767 14.9994V20.3432H5.58064V12.7096H3.33064L12.0025 4.04476L12.5439 4.58617L20.672 12.7096H18.4197Z"
        fill="currentColor"
      />
    </svg>
  );
}
