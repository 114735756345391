import React, { useLayoutEffect, useRef, useState } from "react";
import styles from "./styles/blockComponent.module.scss";
import { VideoCameraOutlined } from "@ant-design/icons";
import { getClassNameFromBlockType } from "editor/utils/blockActions";
import { VALUE_ESCAPE } from "keycode-js";
import Button, { ButtonTypes } from "components/Button";
import { batch, connect, useDispatch } from "react-redux";
import * as actionTypes from "store/actions";
import { socket } from "App";
import { ContainerTypes } from "utilities/types";
import { batchBlockUpdate } from "editor/utils/specificActions/batchBlockUpdate";
import ReactPlayer from "react-player";
import { BlockPropsWithRef } from "../BlockSplitter";
import { selectionStarted } from "components/Overlay";
import { axiosInstance } from "index";
import { useGetResourceById } from "editor/utils/resourceGetterHooks/resourceGetter";

interface IMapStateToProps {
  workspaceId: string;
  passedEntity: any;
}

let videoFocusedTimmer: any = null;

const clearFocusTimeout = (action: any) => {
  clearTimeout(videoFocusedTimmer);
  action(false);
};

const startUpdateTimeout = (action?: any) => {
  clearTimeout(videoFocusedTimmer);
  videoFocusedTimmer = setTimeout(() => {
    if (!selectionStarted) {
      action(true);
    }
  }, 300);
};

const VideoBlock: React.FC<BlockPropsWithRef & IMapStateToProps> = (props) => {
  const { workspaceId } = props;
  const blockImageData = props.blockData.value[0];
  let initialUrlValue = null;
  const { resource } = useGetResourceById(
    props.blockData.referencingContainerId
  );
  if (resource) {
    initialUrlValue = resource.url;
  } else {
    if (blockImageData?.options?.src)
      initialUrlValue = blockImageData.options.src;
  }
  const [videoUrl, setVideoUrl] = useState<string>(initialUrlValue);
  const [embedInput, setEmbedInput] = useState(false);
  const [embedInputValue, setEmbedInputValue] = useState("");
  const [allowedUrl, setAllowedUrl] = useState(false);
  const playerRef = useRef<any>(null);
  const [readyToPlay, setreadyToPlay] = useState(false);
  const dispatcher = useDispatch();

  const handleEmbedInputChange = (event: any) => {
    const { value } = event.target;
    setEmbedInputValue(value);
    if (ReactPlayer.canPlay(value)) {
      setAllowedUrl(true);
    } else {
      setAllowedUrl(false);
    }
  };

  const handleVideoEmbed = (videoUrl: string) => {
    setVideoUrl(videoUrl);
    handleEntityCreation(videoUrl);
  };

  const handleEntityCreation = (imgUrl: string) => {
    axiosInstance
      .post(`/api/resource/`, {
        name: "",
        url: imgUrl,
        caption: "",
        type: "Video",
        baseId: workspaceId,
        clientId: socket.id,
      })
      .then((res) => {
        batch(() => {
          //   setuploadError(false);
          //   setIsImageUploadWaiting(false);
          dispatcher({
            type: actionTypes.ADD_RESOURCE,
            resource: res.data.payload,
          });

          const resourceId: string = res.data.payload.id;

          batchBlockUpdate({
            id: props.blockData.id,
            context: props.context,
            delta: {
              referencingContainerType: ContainerTypes.RESOURCE,
              referencingContainerId: resourceId,
            },
          });

          dispatcher({
            type: actionTypes.CLEAR_RESOURCE_TO_UPLOAD,
            param: {
              blockId: props.blockData.id,
            },
          });
        });
      })
      .catch((err) => {
        // setuploadError(true);
        console.log(err);
      });
  };

  useLayoutEffect(() => {
    let initialUrlValue = null;
    if (resource) {
      initialUrlValue = resource.url;
    } else {
      if (blockImageData?.options?.src)
        initialUrlValue = blockImageData.options.src;
    }
    setVideoUrl(initialUrlValue);
  }, [props, resource]);

  const EmbedStep = () => {
    return (
      <div
        className={styles.imageBlockInput}
        style={{
          margin: props.blockData.indentLevel === 0 ? "-16px 0" : "0",
        }}
      >
        <div className={styles.imageBlockInput__icon}>
          <VideoCameraOutlined />
        </div>
        <div className={styles.imageBlockInput__embedInput}>
          <input
            autoFocus={true}
            className={"embed-input"}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onPaste={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation();
              // e.preventDefault();
              // props.setisFocused(false);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === VALUE_ESCAPE) {
                setEmbedInput(false);
              }
            }}
            onChange={handleEmbedInputChange}
            value={embedInputValue}
            placeholder="Paste link here to embed video"
          />
        </div>
        <div>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={(event: any) => {
              event.stopPropagation();
              handleVideoEmbed(embedInputValue);
            }}
            disabled={!allowedUrl}
          >
            Embed
          </Button>
        </div>
      </div>
    );
  };

  const EmptyBlock = () => {
    return (
      <div
        className={styles.imageBlockInput}
        style={{
          margin: props.blockData.indentLevel === 0 ? "-16px 0" : "0",
        }}
      >
        <div className={styles.imageBlockInput__icon}>
          <VideoCameraOutlined />
        </div>
        {/* {isImageUploadWaiting && <LoadingSpinner />} */}
        <>
          <span
            className={styles.imageBlockInput__button}
            onClick={(event: any) => {
              setEmbedInput(true);
              //   props.setisFocused(false);
              event.stopPropagation();
            }}
          >
            Embed a video from Youtube, Facebook, Twitch, Vimeo, etc.
          </span>
        </>
      </div>
    );
  };

  return (
    <div
      data-block-id={props.blockData.id}
      tabIndex={0}
      data-root={true}
      ref={props.blockRef}
      contentEditable={false}
      style={{ alignItems: "center" }}
      className={
        "content-section clarity-selectable idle-project " +
        getClassNameFromBlockType(props.blockData.lineType) +
        styles.documentBlock
      }
    >
      <div
        onClick={(e) => {}}
        onMouseEnter={() => startUpdateTimeout(setreadyToPlay)}
        onMouseLeave={() => clearFocusTimeout(setreadyToPlay)}
        style={{
          textAlign: props.blockData.indentLevel > 0 ? "left" : "center",
          position: "relative",
          padding: props.blockData.indentLevel === 0 ? "16px 0" : "1px",
        }}
      >
        {!videoUrl ? (
          embedInput ? (
            EmbedStep()
          ) : (
            EmptyBlock()
          )
        ) : (
          <div
            style={{
              borderRadius: "3px",
              overflow: "hidden",
              position: "relative",
              zIndex: 9,
              pointerEvents: readyToPlay ? "auto" : "none",
            }}
          >
            <ReactPlayer
              ref={playerRef}
              // style={{ pointerEvents: "none", cursor: "pointer" }}
              pip={true}
              width="100%"
              url={videoUrl}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: BlockPropsWithRef) => {
  return {
    workspaceId: state.workspace.id,
    passedEntity: state.blocks?.resourceToUpload?.[ownProps.blockData.id],
  };
};
export default connect(mapStateToProps)(VideoBlock);
