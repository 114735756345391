import { Dropdown } from "antd";
import DetailViewMoreMenu from "clarity-ui/paneHeader/DetailViewMoreMenu";
import Conditional from "components/Conditional";
import ChevronDown from "icons/Components/ChevronDown";
import {
  getNameFromContainer,
  useGetContainerFromType,
} from "modules/containerHelpers";
import React, { useEffect, useState } from "react";
import { useIsBaseMember, useRoleType } from "store/reducers/clientReducer";
import { usePins } from "store/reducers/sidebarReducer";
import { usePageData, useShareData } from "store/reducers/topNavReducer";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { ContainerTypes, UserRole } from "utilities/types";
import styles from "./topNavBar.module.scss";

interface IPageTitleProps {
  paneId: ChunkDestination;
}

export const PageTitle: React.FC<IPageTitleProps> = ({ paneId }) => {
  const pageData = usePageData(paneId);
  const paneContent = useShallowSelector(
    (store) => store.navigation.navigation[paneId]
  );
  const isBaseMember = useIsBaseMember();
  const userRole = useRoleType();
  const pinId = usePinId({ paneId });
  const isMenu =
    paneContent?.entity?.containerId &&
    isBaseMember &&
    userRole !== UserRole.GUEST;

  const containerClassName = `${styles.pageTitleContainer} ${styles.pageTitleContainerTitleOnly}`;
  const container = useGetContainerFromType(
    paneContent?.entity?.containerId,
    paneContent?.entity?.containerType
  );

  const name =
    paneContent?.entity && paneContent?.entity.containerType && container?.id
      ? getNameFromContainer(container, paneContent?.entity.containerType)
      : pageData?.title;

  const title =
    (name?.length ?? 0) > 31 ? `${name?.slice(0, 30)}...` : name ?? "";

  if (!paneContent) return <></>;

  return !pageData?.title ? (
    <></>
  ) : isMenu ? (
    pageData?.subtitle ? (
      <div className={containerClassName}>
        <div className={styles.pageTitleRight}>
          <Conditional on={container}>
            <Dropdown
              destroyPopupOnHide={true}
              overlayClassName={"cl-popup"}
              overlay={
                <DetailViewMoreMenu
                  activeEntity={container}
                  containerType={
                    paneContent?.entity?.containerType as ContainerTypes
                  }
                  containerId={paneContent?.entity?.containerId as string}
                  pinId={pinId}
                  paneId={paneId}
                  title={
                    pageData?.subtitle
                      ? `${pageData?.subtitle} ${title}`
                      : title
                  }
                />
              }
              trigger={["click"]}
              placement="bottomLeft"
            >
              <div
                className={`${styles.pageTitleBtnContainer}`}
                style={{ cursor: "pointer" }}
              >
                <span
                  className={`body regular primary white exerpt`}
                  style={{ maxWidth: "140px" }}
                >
                  {title}
                  {pageData?.subtitle && (
                    <span style={{ marginLeft: "4px" }}>
                      {pageData.subtitle}
                    </span>
                  )}
                </span>
                <span className={`body regular primary secondary`}>
                  <ChevronDown className={styles.chevronDown} />
                </span>
              </div>
            </Dropdown>
          </Conditional>
        </div>
      </div>
    ) : (
      <div className={containerClassName}>
        <Conditional on={container}>
          <Dropdown
            destroyPopupOnHide={true}
            overlayClassName={"cl-popup"}
            overlay={
              <DetailViewMoreMenu
                activeEntity={container}
                containerType={
                  paneContent?.entity?.containerType as ContainerTypes
                }
                containerId={paneContent?.entity?.containerId as string}
                pinId={pinId}
                paneId={paneId}
                title={
                  pageData?.subtitle ? `${pageData?.subtitle} ${title}` : title
                }
              />
            }
            trigger={["click"]}
            placement="bottomLeft"
          >
            <div
              className={styles.pageTitleBtnContainer}
              style={{ cursor: "pointer" }}
            >
              <span
                className={`body regular white primary exerpt`}
                style={{ maxWidth: "140px" }}
              >
                {title}
              </span>

              <ChevronDown className={styles.chevronDown} />
            </div>
          </Dropdown>
        </Conditional>
      </div>
    )
  ) : (
    <div className={containerClassName} style={{ maxWidth: "140px" }}>
      <span className={`body regular white primary exerpt`}>
        {title}{" "}
        {pageData.subtitle && (
          <span className={styles.pageTitleSubTitle}>{pageData.subtitle}</span>
        )}
      </span>
    </div>
  );
};

function usePinId({ paneId }: { paneId: ChunkDestination }) {
  const [pinId, setPinId] = useState<string | null>(null);
  const { pinIds, pins } = usePins();
  const primaryView = useShallowSelector(
    (store) => store.navigation.navigation.primary
  );
  const secondaryView = useShallowSelector((state) =>
    state.navigation.navigation
      ? state.navigation.navigation[ChunkDestination.secondary]
      : undefined
  );
  const shareData = useShareData(paneId);

  useEffect(() => {
    if (pins && pinIds) {
      const matchingPin = pinIds.filter((id) =>
        paneId === ChunkDestination.secondary
          ? pins[id].entityId === secondaryView?.entity?.containerId ||
            pins[id].entityId === shareData?.activeEntity?.id
          : pins[id].entityId === primaryView?.entity?.containerId ||
            pins[id].entityId === shareData?.activeEntity?.id
      )[0];
      setPinId(matchingPin || null);
    }
  }, [
    pins,
    pinIds,
    primaryView?.entity?.containerId,
    secondaryView?.entity?.containerId,
    shareData?.activeEntity?.id,
  ]);

  return pinId;
}
