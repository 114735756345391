import Icon from "@ant-design/icons";
import React from "react";

export default function TrophyTwoTone(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        opacity="0.1"
        d="M7.5 11.25C7.5 12.4008 7.94766 13.4836 8.76328 14.2992C9.57656 15.1148 10.6617 15.5625 11.8125 15.5625H12.1875C13.3383 15.5625 14.4211 15.1148 15.2367 14.2992C16.0523 13.4859 16.5 12.4008 16.5 11.25V4.3125H7.5V11.25ZM4.3125 8.25C4.3125 9.21094 4.94297 10.0266 5.8125 10.3031C4.94297 10.0242 4.3125 9.20859 4.3125 8.25ZM12.8438 17.2148C15.5859 16.9172 17.775 14.7703 18.1359 12.0469C17.7727 14.7703 15.5859 16.9148 12.8438 17.2148ZM5.86406 12.0469C6.225 14.7703 8.41406 16.9172 11.1562 17.2148C8.41406 16.9148 6.22734 14.7703 5.86406 12.0469Z"
        fill="currentColor"
      />
      <path
        d="M20.3438 3.75H18.1875V2.8125C18.1875 2.70937 18.1031 2.625 18 2.625H6C5.89687 2.625 5.8125 2.70937 5.8125 2.8125V3.75H3.65625C3.38275 3.75 3.12044 3.85865 2.92705 4.05205C2.73365 4.24544 2.625 4.50775 2.625 4.78125V8.25C2.625 10.1648 4.03125 11.7563 5.86406 12.0469C6.22734 14.7703 8.41406 16.9148 11.1562 17.2148V19.6805H6.5625C6.14766 19.6805 5.8125 20.0156 5.8125 20.4305V21.1875C5.8125 21.2906 5.89687 21.375 6 21.375H18C18.1031 21.375 18.1875 21.2906 18.1875 21.1875V20.4305C18.1875 20.0156 17.8523 19.6805 17.4375 19.6805H12.8438V17.2148C15.5859 16.9148 17.7727 14.7703 18.1359 12.0469C19.9688 11.7563 21.375 10.1648 21.375 8.25V4.78125C21.375 4.50775 21.2663 4.24544 21.073 4.05205C20.8796 3.85865 20.6173 3.75 20.3438 3.75ZM5.8125 10.3031C5.37714 10.1641 4.99723 9.89025 4.72762 9.52122C4.45801 9.15219 4.31264 8.70703 4.3125 8.25V5.4375H5.8125V10.3031ZM16.5 11.25C16.5 12.4008 16.0523 13.4859 15.2367 14.2992C14.4211 15.1148 13.3383 15.5625 12.1875 15.5625H11.8125C10.6617 15.5625 9.57656 15.1148 8.76328 14.2992C7.94766 13.4836 7.5 12.4008 7.5 11.25V4.3125H16.5V11.25ZM19.6875 8.25C19.6875 9.21094 19.057 10.0266 18.1875 10.3031V5.4375H19.6875V8.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
