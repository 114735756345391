import React from "react";
import {
  useAddOrEditBasePin,
  useAddOrEditHomeSection,
  useDeleteHomeSection,
  useRemoveHomePin,
  useReorderHomePins,
  useReorderHomeSection,
} from "store/reducers/workspaceReducer";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import PinsBoard from "./PinsBoard";

const BasePinsHoc: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  const sections = useShallowSelector((store) => store.workspace.home);

  const addPin = useAddOrEditBasePin();
  const updateOrAddCollection = useAddOrEditHomeSection();
  const reorderPins = useReorderHomePins();
  const reorderCollection = useReorderHomeSection();
  const deleteCollection = useDeleteHomeSection();
  const deletePin = useRemoveHomePin();

  return (
    <>
      <PinsBoard
        paneId={paneId}
        mode="base"
        sections={sections}
        addPin={addPin}
        updateOrAddCollection={updateOrAddCollection}
        reorderPins={reorderPins}
        reorderCollection={reorderCollection}
        deleteCollection={deleteCollection}
        deletePin={deletePin}
      />
    </>
  );
};

export default BasePinsHoc;
