// Vendor Libraries
import { connect, batch } from "react-redux";
import Helmet from "react-helmet";
import React, {
  useState,
  memo,
  useRef,
  useLayoutEffect,
  useMemo,
  useEffect,
} from "react";
import { RouteComponentProps } from "react-router-dom";

// Internal Modules
import * as actionTypes from "store/actions";
import {
  DocumentModes,
  IUserObj,
  IProjectObj,
  ContainerTypes,
  IBlockContext,
  ITemplateObj,
} from "utilities/types";
import { stripHtml } from "utilities/stringUtilities";
import { getRelatedEntities } from "modules/documentService";
import { ChunkDestination } from "utilities/stateTypes";
import { loadContainerData } from "editor/utils/containerActions/loadContainerActions";
import { Block } from "store/reducers/blockReducer";
import { $focusOn, ClarityStore } from "store/storeExporter";
import { clearBlockSelection } from "editor/utils/blockActions";
import EditorContainer from "editor/EditorContainer";
import { useContainerContextSetter } from "editor/utils/customHooks";
import { getHtml } from "editor/utils/blockValueHelpers";
import ContainerDetailMetadata from "clarity-ui/ContainerDetailMetadata";
import { groupApi } from "clientApi/groupsApi";
import TemlpateTitle from "../../templates/TemplateTitle";
import SubTasks from "components/SubTasks";

interface IProjectComponentProps {
  checkForAccordingLine: (param: any) => any;
  baseName: string;
  containerId: string;
  changeDocumentMode: (param: any) => any;
  sharedView: boolean;
  connected: boolean;
  paneId: ChunkDestination;
  outlineMode: string;
  updateProjects: (projects: IProjectObj[]) => {
    type: string;
    projects: IProjectObj[];
  };
}

interface IMapStateToProps {
  isBaseMember: any;
  assignee: IUserObj;
  projectComments: [];
  newDiscussionDetail: any;
  templateDict: { [id: string]: ITemplateObj };
}

function Template(
  props: RouteComponentProps<any> & IProjectComponentProps & IMapStateToProps
) {
  const documentRef = useRef<HTMLDivElement | null>(null);
  const prevId = useRef("");
  const activeId = useRef(props.containerId);
  activeId.current = props.containerId;
  const titleBlock = useRef<Block | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [templateContext, setTemplateContext] = useState<IBlockContext>({
    id: ContainerTypes.TEMPLATE + props.containerId,
    type: "container",
    container: {
      id: props.containerId,
      type: ContainerTypes.TEMPLATE,
      outlineMode: props.outlineMode ? props.outlineMode : "noOutline",
    },
    ref: documentRef,
    paneId: props.paneId,
    persistToggle: props.connected && props.isBaseMember,
    autosave: props.connected && props.isBaseMember,
    online: true,
    canComment: props.connected && props.isBaseMember,
    canEdit: props.connected && props.isBaseMember,
  });

  useContainerContextSetter(templateContext, setTemplateContext);

  useLayoutEffect(() => {
    if (
      (activeId && activeId.current !== prevId.current && props.templateDict) ||
      props.connected
    ) {
      if (activeId.current === "new") return;
      let template = props.templateDict
        ? props.templateDict[activeId.current]
        : null;
      if (template && template.id && template.id !== "") {
        props.changeDocumentMode(DocumentModes.INSERT);
        loadContainerData({
          containerId: template.id,
          containerType: ContainerTypes.TEMPLATE,
          titleBlockId: "",
          tagsBlockId: template.tagsBlockId ? template.tagsBlockId : undefined,
          titleBlock,
          outlineMode: template.outlineMode,
          isPublicAccess: template.isPublicAccess,
        }).then(() => {
          batch(() => {
            if (
              (!props.isBaseMember && template) ||
              (template &&
                !groupApi.checkIfGroupMember(
                  template?.groupIds ?? [template?.groupId]
                ))
            ) {
              if (template?.id) {
                getRelatedEntities(template.id, ContainerTypes.TEMPLATE).then(
                  () => {
                    // if (props.workDict) setisLoading(false);
                  }
                );
              }
            }
          });
        });
      }
    }
    prevId.current = activeId.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerId, props.connected]);

  useEffect(() => {
    return () => {
      if (
        $focusOn.value.focusContext?.id === templateContext.id &&
        $focusOn.value.focusPane === templateContext.paneId
      ) {
        $focusOn.next({ ...$focusOn.value, focusBlockId: undefined });
        clearBlockSelection();
      }
    };
  }, []);

  return useMemo(() => {
    if (
      !props.templateDict ||
      !activeId.current ||
      !props.templateDict[props.containerId]?.id
    ) {
      return <></>;
    } else {
      return (
        <>
          <div className="document-wrapper">
            <div className="document-container">
              {props.templateDict &&
              props.paneId === ChunkDestination.primary ? (
                <Helmet>
                  <title>{`${stripHtml(
                    getHtml(props.templateDict[props.containerId]?.nameValue)
                  )} • ${props.baseName}`}</title>
                </Helmet>
              ) : (
                <></>
              )}
              <div
                className="document projectDetail select-area"
                ref={documentRef}
              >
                <div className="container-860">
                  <div className="titleBlockContainer workDetail">
                    <TemlpateTitle
                      templateId={props.containerId}
                      customClassName=""
                    />
                  </div>
                  <EditorContainer
                    context={templateContext}
                    isBaseMember={props.isBaseMember}
                    outlineMode={props.outlineMode}
                  />
                  <div
                    style={{
                      marginTop: "48px",
                    }}
                    // className={styles.subTasksContainer}
                  >
                    <SubTasks
                      parentWorkId={props.containerId}
                      workType={props.templateDict[props.containerId].workType}
                      containerType={ContainerTypes.TEMPLATE}
                    />
                  </div>
                </div>
              </div>
              <div className="taskSidebar">
                {/* <div className="body2 medium disabled mb-14 ">Properties</div> */}
                <ContainerDetailMetadata
                  key={props.containerId}
                  containerId={props.containerId}
                  containerType={ContainerTypes.TEMPLATE}
                  paneId={props.paneId}
                  rowMode={false}
                  noPersist={!templateContext.canEdit}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.containerId,
    props.outlineMode,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.templateDict?.[props.containerId],
    templateContext,
  ]);
}

const mapStateToProps = (state: ClarityStore, ownProps: any) => {
  let docState = {
    baseName: state.workspace.name,
    templateDict: state.templates.dict,
    connected: state.network.connected,
    isBaseMember: state.client.isBaseMember,
    outlineMode:
      state.blocks.contexts[ContainerTypes.TEMPLATE + ownProps.containerId]
        ?.outlineMode,
  };
  return docState;
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    changeDocumentMode: (param: any) =>
      dispatch({
        type: actionTypes.CHANGE_DOCUMENT_MODE,
        param,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Template));
