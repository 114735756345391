import ClarityLogo from "clarity-ui/ClarityLogo";
import Conditional from "components/Conditional";
import { axiosInstance } from "index";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DiscordIntegrationRedirect: React.FC = () => {
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const baseId = params.get("state");
    const serverId = params.get("guild_id");
    const code = params.get("code");
    const accessToken = params.get("access_token");
    const tokenType = params.get("token_type");

    axiosInstance
      .post(`/api/workspace/discord`, {
        baseId: baseId,
        serverId,
        code,
        accessToken,
        tokenType,
      })
      .then((res) => {
        window.opener = null;
        window.open("", "_self");
        window.close();
      })
      .catch((e) => {
        setError("The Discord Server is already connected to a Clarity Base");
      });
  }, []);

  console.log(error);

  return (
    <div
      className="h-100 w-100"
      style={{
        background: "#F1F1F3",
        justifyContent: "center",
        paddingTop: "103px",
      }}
    >
      <div
        style={{
          maxWidth: "620px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <ClarityLogo
          imgStyle={({ isMobile }) => ({ marginBottom: 68, width: "139px" })}
        />
        <Conditional on={!error}>
          <div style={{ textAlign: "center" }}>
            <div>We’re waiting for a response from Discord...</div>
            <div>This tab will close automatically when we hear back.</div>
          </div>
        </Conditional>
        <Conditional on={error}>
          <div
            style={{
              background: "#FFE3E3",
              borderRadius: "8px",
              padding: "17px 24px",
            }}
          >
            <span className="body2 regular urgent" style={{ color: "#993636" }}>
              <span className="bold">Oops: </span>The Discord server you
              selected is already connected to another Clarity base
            </span>
          </div>
        </Conditional>
      </div>
    </div>
  );
};

export default DiscordIntegrationRedirect;
