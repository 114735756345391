import React, { useCallback, useState } from "react";
import { LineType } from "utilities/lineUtilities";
import {
  Abilities,
  ContainerTypes,
  IBlockContext,
  ViewNames,
} from "utilities/types";
import { ChunkDestination } from "utilities/stateTypes";
import BlocksContainer from "editor/blockContainer/BlocksContainer";
import {
  BlockContextPass,
  BlockContextUpdater,
} from "editor/blockContainer/BlockContext";
import PortalContainer from "clarity-ui/PortalContainer";

import { flashBlock, flashBlockSubject } from "App";
import IconPeek from "icons/Components/IconPeek";
import Button, { ButtonTypes } from "components/Button";
import { shallowEqual, useSelector } from "react-redux";
import { useContainerContextSetter } from "editor/utils/customHooks";
import { BlockObject } from "store/reducers/blockReducer";
import roleApi from "clientApi/rolesApi";
import navigationApi from "clientApi/navigationApi";

interface Props {
  blocksObject: BlockObject;
  blockIds: string[];
  parentListType: string;
  allowToggle?: boolean;
  showDefaultRootBullets?: boolean;
  parentId: string;
  indentLevel: number;
  rootCustomClass?: string;
  linkedEntity?: any;
  fromNote?: boolean;
  toggleMode?: string;
  unlinked?: boolean;
  linkButton?: any;
  destination?: string;
  containerRef?: any;
  editable?: boolean;
  contextPresets?: Partial<IBlockContext>;
  paneId: ChunkDestination;
}

const ReadOnlyBlocksContainer: React.FC<Props> = (props) => {
  if (!props.blockIds) return <></>;
  return (
    <>
      {props.blockIds.map((id: string, index: number) => {
        const block = props.blocksObject[id];
        if (!block) return <React.Fragment key={id}></React.Fragment>;
        return (
          <PortalContainer
            key={id}
            styles={{ marginBottom: "8px" }}
            containerRef={props.containerRef}
          >
            <React.Fragment key={id}>
              {props.rootCustomClass ? (
                <div className={props.rootCustomClass}>
                  {props.indentLevel === 0 &&
                  props.parentListType === "bullet-1" &&
                  props.showDefaultRootBullets ? (
                    <ol className={"list-type-bullet-1"}>
                      <ShowInner
                        id={id}
                        props={props}
                        block={block}
                        index={index}
                      />
                    </ol>
                  ) : (
                    <>
                      <ShowInner
                        id={id}
                        props={props}
                        block={block}
                        index={index}
                      />
                    </>
                  )}
                  {props.linkedEntity && (
                    <GroupLinks
                      entityId={props.linkedEntity.entityId}
                      entityType={props.linkedEntity.entityType}
                      projectId={props.linkedEntity.projectId}
                      specificBlockId={id}
                      block={props.blocksObject[id]}
                      linkButton={props.linkButton}
                      paneId={props.paneId}
                    />
                  )}
                </div>
              ) : (
                <>
                  {props.indentLevel === 0 &&
                  props.parentListType === "bullet-1" &&
                  props.showDefaultRootBullets ? (
                    <ol className={"list-type-bullet-1"}>
                      <ShowInner
                        id={id}
                        props={props}
                        block={block}
                        index={index}
                      />
                    </ol>
                  ) : (
                    <ShowInner
                      id={id}
                      props={props}
                      block={block}
                      index={index}
                    />
                  )}
                </>
              )}
            </React.Fragment>
          </PortalContainer>
        );
      })}
    </>
  );
};

const ShowInner: React.FC<any> = ({ id, props, block, index }) => {
  const isContainer = useCallback((block) => {
    return (
      block.lineType === LineType.Title &&
      block.containerId === block.referencingContainerId &&
      block.containerType === ContainerTypes.NOTE
    );
  }, []);

  const componentState = useSelector((state: any) => {
    return {
      isBaseMember: state.client.isBaseMember,
      connected: state.network.connected,
    };
  }, shallowEqual);

  const isEditable = useCallback(() => {
    const hasEditAbility = roleApi.checkAbility({
      abilityName: Abilities.CAN_EDIT_ENTITY,
    });
    if (!hasEditAbility) return false;
    return (
      block.containerType !== ContainerTypes.WORK_ACTIVITY &&
      componentState.connected &&
      componentState.isBaseMember
    );
  }, []);

  const [context, setContex] = useState<IBlockContext>({
    ...{
      id: block.containerType + block.containerId,
      type: "mention",
      container: {
        id: block.containerId,
        type: block.containerType,
        outlineMode: props.outlineMode ? props.outlineMode : "noOutline",
        zoomedFullNote: false,
      },
      ref: props.containerRef,
      zoomedBlockId: block.id,
      paneId: props.destination ? props.destination : ChunkDestination.primary,
      autosave: isEditable() ? true : false,
      persistToggle: isEditable() ? true : false,
      online: componentState.connected,
      canComment: roleApi.checkAbility({
        abilityName: Abilities.CAN_COMMENT_ENTITY,
        entityId: block.containerId,
        entityType: block.containerType,
      }),
      canEdit: isEditable() ? true : false,
    },
    ...(props.contextPresets ? props.contextPresets : {}),
  });

  useContainerContextSetter(context, setContex);

  if (isContainer(block)) {
    return (
      <BlockContextUpdater.Provider value={setContex}>
        <BlockContextPass.Provider value={context}>
          <BlocksContainer />
        </BlockContextPass.Provider>
      </BlockContextUpdater.Provider>
    );
  }

  return (
    <BlockContextUpdater.Provider value={setContex}>
      <BlockContextPass.Provider value={context}>
        <BlocksContainer isRoot={true} childIds={[block.id]} />
      </BlockContextPass.Provider>
    </BlockContextUpdater.Provider>
  );
};

interface IGroupLinkProps {
  entityId: string;
  entityType: ContainerTypes;
  projectId?: number;
  specificBlockId?: string;
  linkButton?: any;
  block?: any;
  workType?: any;
  paneId: ChunkDestination;
}

export const GroupLinks: React.FC<IGroupLinkProps> = (props) => {
  return (
    <span
      style={{
        display: "flex",
        position: "absolute",
        top: "6px",
        right: "6px",
      }}
    >
      {props.linkButton && props.block ? props.linkButton(props.block) : <></>}
      <Button
        icon={<IconPeek alt="Open in primary view" />}
        buttonType={ButtonTypes.LINK}
        onClick={(e: any) => {
          if (props.specificBlockId) {
            flashBlock.id = props.specificBlockId;
            flashBlockSubject.next(flashBlock);
          }
          navigationApi.contextBasedNavigate({
            currentPane: props.paneId,
            forceToPane: ChunkDestination.peek,
            shiftKey: e.shiftKey,
            navigationChunk: {
              viewName: ViewNames.Detail,
              entity: {
                containerId: `${props.entityId}`,
                containerType: props.entityType,
                workType: props.workType,
              },
            },
          });
          e.stopPropagation();
        }}
      />
    </span>
  );
};

export default ReadOnlyBlocksContainer;
