import React from "react";
import { ContainerTypes } from "utilities/types";
import { CheckCircleOutlined, FormOutlined } from "@ant-design/icons";
import FileTextTwoTone from "icons/Components/FileTextTwoTone";

export const VariableContainerIcon: React.FC<{
  containerType: ContainerTypes;
}> = React.memo(({ containerType }) => {
  switch (containerType) {
    case ContainerTypes.DOCUMENT:
      return <FileTextTwoTone />;
    case ContainerTypes.PROJECT:
    case ContainerTypes.WORK_ACTIVITY:
      return <CheckCircleOutlined />;
    case ContainerTypes.NOTE:
      return <FormOutlined />;
    default:
      return <FileTextTwoTone />;
  }
});
