import { useMemo } from "react";
import { useShallowSelector } from "utilities/hooks";
import { GeneralViewsNames } from "utilities/types";
import TagsTwoTone from "icons/Components/TagsTwoTone";
import ScheduleTwoTone from "icons/Components/ScheduleTwoTone";
import ProfileTwoTone from "icons/Components/ProfileTwoTone";
import SettingTwoTone from "icons/Components/SettingTwoTone";
import store from "store/storeExporter";
import { OPEN_BASE_SETTING_SET_INTIAL } from "store/actions";

enum Options {
  overview = "overview",
  roadmap = "roadmap",
  tags = "tags",
  settings = "settings",
}

export const useBaseNavigationOptions = () => {
  const base = useShallowSelector((store) => store.workspace);

  const options = useMemo(() => {
    const possibleOptions: Options[] = [];
    orderedOptions.forEach((option) => {
      possibleOptions.push(option);
    });
    return possibleOptions.map((option) => optionsData[option]);
  }, [base.id, base.showWeekly, base.showMilestones]);

  return options;
};

const optionsData = {
  [Options.overview]: {
    key: Options.overview,
    name: () => "Overview",
    icon: <ProfileTwoTone />,
    url: () => "",
    secondaryViewConfig: () => {
      return {
        viewName: GeneralViewsNames.Home,
      };
    },
  },
  [Options.roadmap]: {
    key: Options.roadmap,
    name: () => {
      return "Roadmap";
    },
    icon: <ScheduleTwoTone />,
    url: () => "roadmap",
    secondaryViewConfig: () => ({
      viewName: GeneralViewsNames.Roadmap,
    }),
  },
  [Options.tags]: {
    key: Options.tags,
    name: () => "Tags",
    icon: <TagsTwoTone />,
    url: () => "tags",
    secondaryViewConfig: () => ({
      viewName: GeneralViewsNames.Wiki,
    }),
  },
  [Options.settings]: {
    key: Options.settings,
    name: () => "Settings",
    icon: <SettingTwoTone />,
    url: () => "",
    action: () => {
      store.dispatch({
        type: OPEN_BASE_SETTING_SET_INTIAL,
        param: {
          openSettings: {
            isOpen: true,
          },
        },
      });
    },
    secondaryViewConfig: () => {},
  },
};

const orderedOptions = [
  Options.overview,
  Options.roadmap,
  Options.tags,
  Options.settings,
];
