import React, { useEffect, useMemo, useRef, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { useShallowSelector } from "utilities/hooks";
import { DocumentModes, IBlockContext } from "utilities/types";
import { BlockPropsWithRef } from "../BlockSplitter";
import "./tableBlock/tableBlock.scss";
import TableRow from "./tableBlock/TableRow";
import { $documentMode, $focusOn } from "store/storeExporter";
import {
  moveCaretAtLineEnd,
  moveCaretToPreviousPosition,
} from "utilities/caretMovement";
import { FocusType } from "store/reducers/blockReducer";
import { toggleDocumentMode } from "editor/utils/blockActions";
import { VALUE_ESCAPE } from "keycode-js";
import {
  TableBottomEdge,
  TableBottomRightEdge,
  TableLeftEdge,
  TableRightEdge,
} from "./tableBlock/TableEdges";
import {
  TableColumnDragAndDropListener,
  TableRowDragAndDropListener,
} from "./tableBlock/TableDndListeners";
import {
  handleMoveToNextBlock,
  handleMoveToPreviousBlock,
} from "./tableBlock/action";
import ReactDOM from "react-dom";
import Conditional from "components/Conditional";

interface ITableSignals {
  hoverTableId: string;
  hoverRowId: string;
  hoverColumnId: string;
  focusTableId: string;
  focusRowId: string;
  focusColumnId: string;
  cellMode: "insert" | "block";
}

export interface ICellCache {
  [cellPositionId: string]: HTMLTableCellElement;
}

export const tableListeners = new BehaviorSubject<ITableSignals>({
  hoverColumnId: "",
  hoverRowId: "",
  hoverTableId: "",
  focusColumnId: "",
  focusRowId: "",
  focusTableId: "",
  cellMode: "insert",
});

export const tableResizeObserver = new BehaviorSubject({
  hoveredResizerColumnId: "",
  pressedResizerColumnId: "",
  hoveredTableId: "",
  pressedTableId: "",
});

export const tableDndObserver = new BehaviorSubject<{
  tableId: string;
  dragRowId: string;
  dragColumnId: string;
  dragColumnPosition: number;
  dragRowPosition: number;
  dragColumnIndex?: number;
  dragRowIndex?: number;
  hoveredDropZoneColumnId: string;
  hoveredDropZoneRowId: string;
  dropZoneMode: string;
}>({
  tableId: "",
  dragRowId: "",
  dragColumnId: "",
  dragColumnPosition: 0,
  dragRowPosition: 0,
  hoveredDropZoneColumnId: "",
  hoveredDropZoneRowId: "",
  dropZoneMode: "",
});

export const tableStoreData = new BehaviorSubject(
  {} as {
    [id: string]: {
      tableRef: React.MutableRefObject<HTMLTableElement | null>;
      cellCache: React.MutableRefObject<ICellCache>;
    };
  }
);

const TableBlock: React.FC<BlockPropsWithRef> = (props) => {
  const cellCache = useRef<ICellCache>({});
  const tableRef = useRef<HTMLTableElement | null>(null);

  const block = useShallowSelector(
    (store) => store.blocks.dict[props.blockData.id]
  );
  useEffect(() => {
    tableStoreData.next({
      ...tableStoreData.value,
      [props.blockData.id]: { cellCache, tableRef },
    });
    const sub = $focusOn.subscribe((focusOn) => {
      if (
        focusOn.focusContext?.paneId === props.context.paneId &&
        focusOn.focusTableId === props.blockData.id &&
        focusOn.focusBlockId &&
        focusOn.focusNodeId
      ) {
        const nodeAddress = focusOn.focusBlockId + focusOn.focusNodeId;
        if (cellCache.current[nodeAddress]) {
          if (tableListeners.value.cellMode === "block") {
            cellCache.current[nodeAddress].focus({ preventScroll: true });
            return;
          }
          const beaconElements = cellCache.current[
            nodeAddress
          ]?.querySelectorAll(`[data-content-editable-leaf = ${true}]`);

          const editable = Array.from(beaconElements)[0];
          if (!editable) return;
          (editable as HTMLDivElement).focus({ preventScroll: true });

          if (
            focusOn.type === FocusType.withDiff ||
            focusOn.type === FocusType.atBlockEnd
          ) {
            moveCaretAtLineEnd(
              editable as HTMLDivElement,
              focusOn.diff ? focusOn.diff : 0
            );
            if ((editable as HTMLDivElement).innerText.length === 0) {
              const textNode = document.createTextNode(" ");
              (editable as HTMLDivElement).appendChild(textNode);
              const newRange = new Range();
              newRange.selectNode(textNode);

              const selection = document.getSelection();
              selection?.removeAllRanges();
              selection?.addRange(newRange);
              textNode.remove();
            }
          } else {
            moveCaretToPreviousPosition(
              editable as HTMLDivElement,
              focusOn.caretPosition ? focusOn.caretPosition : 0
            );
          }
        }
      }
    });

    return () => {
      const currentValue = { ...tableStoreData.value };
      delete currentValue[props.blockData.id];
      tableStoreData.next(currentValue);
      sub.unsubscribe();
    };
  }, []);

  return useMemo(() => {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            paddingLeft: props.blockData.indentLevel > 0 ? 3 + "px" : 0,
          }}
          data-block-id={props.blockData.id}
          className={"clarity-selectable"}
          tabIndex={-1}
          data-root={true}
          ref={props.blockRef}
          contentEditable={false}
          onKeyDown={(e) => {
            if ($documentMode.value === DocumentModes.INSERT) {
              if (e.key === VALUE_ESCAPE) {
                e.preventDefault();
                e.stopPropagation();
                if ($documentMode.value === DocumentModes.INSERT)
                  props.blockRef.current?.focus({ preventScroll: true });
                toggleDocumentMode(props.blockData.id);
              }
              e.stopPropagation();
            }
          }}
          onKeyDownCapture={(e) => {
            if ($documentMode.value === DocumentModes.BLOCK) {
              e.preventDefault();
              return;
            }
          }}
        >
          <TableContent
            cellCache={cellCache}
            tableRef={tableRef}
            context={props.context}
            rows={block.children}
            tableBlockColumnData={
              block.value[0]?.tableBlockColumnData ?? ({} as any)
            }
            tableId={props.blockData.id}
            indentLevel={props.blockData.indentLevel}
            changeBlock={props.changeBlock}
          />
          <TableSelectionOverlay
            cellCache={cellCache}
            tableRef={tableRef}
            tableId={props.blockData.id}
            tableBlockColumnData={
              block.value[0]?.tableBlockColumnData ?? ({} as any)
            }
          />
          <TableLeftEdge tableId={props.blockData.id} />
          <TableRightEdge
            tableId={props.blockData.id}
            columns={block.value[0]?.tableBlockColumnData?.order ?? []}
            rows={block.children}
            context={props.context}
            tableRef={tableRef}
          />

          <Conditional on={props.context.canEdit}>
            <TableBottomEdge
              tableId={props.blockData.id}
              columns={block.value[0]?.tableBlockColumnData?.order ?? []}
              rows={block.children}
              context={props.context}
              tableRef={tableRef}
            />
            <TableBottomRightEdge
              tableId={props.blockData.id}
              columns={block.value[0]?.tableBlockColumnData?.order ?? []}
              rows={block.children}
              context={props.context}
              tableRef={tableRef}
            />
          </Conditional>
          <TableScrollShadowEdges
            blockRef={props.blockRef}
            context={props.context}
          />
        </div>
        <div
          className={
            props.blockData.indentLevel > 0
              ? "selection-fill list-style-fill"
              : " selection-fill"
          }
        ></div>
      </div>
    );
  }, [block.children, block.value, props.context, props.blockData.indentLevel]);
};

const TableScrollShadowEdges: React.FC<{
  blockRef: React.MutableRefObject<HTMLDivElement | null>;
  context: IBlockContext;
}> = ({ blockRef, context }) => {
  const scrollbarRef = useRef(null);
  const [showLeftShadow, setshowLeftShadow] = useState(false);
  const [showRightShadow, setshowRightShadow] = useState(false);

  useEffect(() => {
    const closest: any = blockRef.current?.closest(".styledScrollbarContainer");
    if (closest) scrollbarRef.current = closest;

    let rightShowing = false;
    let leftShowing = false;

    let options = {
      root: closest,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const callback: IntersectionObserverCallback = (entries, observer) => {
      let newLeftIsShowing = leftShowing;
      let newRightIsShowing = rightShowing;

      entries.forEach((entry) => {
        let side: string | null = null;
        side = entry.target.getAttribute("data-table-edge");
        if (side === "left") newLeftIsShowing = entry.isIntersecting;
        if (side === "right") newRightIsShowing = entry.isIntersecting;
      });

      if (
        newRightIsShowing !== rightShowing ||
        leftShowing !== newLeftIsShowing
      ) {
        setshowRightShadow(!newRightIsShowing);
        setshowLeftShadow(!newLeftIsShowing);
      }

      rightShowing = newRightIsShowing;
      leftShowing = newLeftIsShowing;
    };

    let observer = new IntersectionObserver(callback, options);

    const edges = blockRef.current
      ? blockRef.current.getElementsByClassName("edge")
      : [];

    Array.from(edges).forEach((edge) => {
      observer.observe(edge);
    });

    return () => {
      observer.disconnect();
    };
  }, [context]);

  return (
    <>
      {ReactDOM.createPortal(
        <>
          <Conditional on={showLeftShadow}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "30px",
                borderRadius: "4px",
                boxShadow: "inset 4px 0px 8px -8px rgba(0, 0, 0, 0.25)",
                pointerEvents: "none",
              }}
            ></div>
          </Conditional>
          <Conditional on={showRightShadow}>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "30px",
                borderRadius: "4px",
                boxShadow: "inset -4px 0px 8px -8px rgba(0, 0, 0, 0.25)",
                pointerEvents: "none",
              }}
            ></div>
          </Conditional>
        </>,
        scrollbarRef.current ?? document.body
      )}
    </>
  );
};

const TableContent: React.FC<{
  rows: string[];
  context: IBlockContext;
  tableId: string;
  cellCache: React.MutableRefObject<ICellCache>;
  indentLevel: number;
  tableRef: React.MutableRefObject<HTMLTableElement | null>;
  changeBlock: any;
  tableBlockColumnData: {
    order: string[];
    dict: {
      [id: string]: {
        id: string;
        manualSize?: number | undefined;
      };
    };
  };
}> = ({
  rows,
  tableBlockColumnData,
  context,
  tableId,
  cellCache,
  indentLevel,
  tableRef,
  changeBlock,
}) => {
  const moveFunc = (params: {
    currentRow: string;
    currentColumn: string;
    moveType: "up" | "down" | "right" | "left";
    e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent;
  }) => {
    const moveUp = () => {
      const index = rows.indexOf(params.currentRow);
      if (index === 0) {
        handleMoveToPreviousBlock(params.e, tableId, context, changeBlock);
        return;
      }
      const prevRow = rows[index - 1];

      $focusOn.next({
        caretPosition: 0,
        focusBlockId: prevRow,
        focusContext: context,
        focusPane: context.paneId,
        type: FocusType.atBlockEnd,
        refocusToggle: false,
        focusTableId: tableId,
        focusNodeId: params.currentColumn,
      });
    };
    const moveDown = () => {
      const index = rows.indexOf(params.currentRow);
      if (index === rows.length - 1) {
        handleMoveToNextBlock(params.e, tableId, context, changeBlock);
        return;
      }
      const nextRow = rows[index + 1];

      $focusOn.next({
        caretPosition: 0,
        focusBlockId: nextRow,
        focusContext: context,
        focusPane: context.paneId,
        type: FocusType.atBlockEnd,
        refocusToggle: false,
        focusTableId: tableId,
        focusNodeId: params.currentColumn,
      });
    };
    const moveLeft = () => {
      const columns = tableBlockColumnData.order;
      const index = columns.indexOf(params.currentColumn);
      if (index === 0) {
        const currentRowIndex = rows.indexOf(params.currentRow);
        if (currentRowIndex === 0) {
          handleMoveToPreviousBlock(params.e, tableId, context, changeBlock);
          return;
        }
        const prevRowIndex = rows[currentRowIndex - 1];
        const lastColumn = columns[columns.length - 1];

        $focusOn.next({
          caretPosition: 0,
          focusBlockId: prevRowIndex,
          focusContext: context,
          focusPane: context.paneId,
          type: FocusType.atBlockEnd,
          refocusToggle: false,
          focusTableId: tableId,
          focusNodeId: lastColumn,
        });

        return;
      }
      const prevColumn = columns[index - 1];

      $focusOn.next({
        caretPosition: 0,
        focusBlockId: params.currentRow,
        focusContext: context,
        focusPane: context.paneId,
        type: FocusType.atBlockEnd,
        refocusToggle: false,
        focusTableId: tableId,
        focusNodeId: prevColumn,
      });
    };

    const moveRight = () => {
      const columns = tableBlockColumnData.order;
      const index = columns.indexOf(params.currentColumn);
      if (index === columns.length - 1) {
        const currentRowIndex = rows.indexOf(params.currentRow);
        if (currentRowIndex === rows.length - 1) {
          handleMoveToNextBlock(params.e, tableId, context, changeBlock);
          return;
        }
        const nextRowIndex = rows[currentRowIndex + 1];
        const firstColumn = columns[0];

        $focusOn.next({
          caretPosition: 0,
          focusBlockId: nextRowIndex,
          focusContext: context,
          focusPane: context.paneId,
          type: FocusType.atBlockEnd,
          refocusToggle: false,
          focusTableId: tableId,
          focusNodeId: firstColumn,
        });

        return;
      }

      const nextColumn = columns[index + 1];

      $focusOn.next({
        caretPosition: 0,
        focusBlockId: params.currentRow,
        focusContext: context,
        focusPane: context.paneId,
        type: FocusType.prevPosition,
        refocusToggle: false,
        focusTableId: tableId,
        focusNodeId: nextColumn,
      });
    };
    const moveDict = {
      up: moveUp,
      down: moveDown,
      left: moveLeft,
      right: moveRight,
    };
    return moveDict[params.moveType]();
  };

  const moveInCells = useRef(moveFunc);

  useEffect(() => {
    moveInCells.current = moveFunc;
  }, [moveFunc]);

  return (
    <table ref={tableRef} style={{ margin: "8px 18px 18px 0px" }}>
      <div style={{ position: "relative" }}>
        <tbody
          onBlur={() =>
            tableListeners.next({
              ...tableListeners.value,
              focusTableId: "",
            })
          }
          onFocus={() =>
            tableListeners.next({
              ...tableListeners.value,
              focusTableId: tableId,
            })
          }
        >
          {rows.map((childId, index) => (
            <TableRow
              key={childId}
              moveInCells={moveInCells}
              cellCache={cellCache}
              blockId={childId}
              tableId={tableId}
              isFirstRow={index === 0}
              rowIndex={index}
              context={context}
              tableColumnData={tableBlockColumnData}
            />
          ))}
        </tbody>
        <div
          style={{ width: "100%" }}
          className={
            indentLevel > 0
              ? "selection-fill list-style-fill"
              : " selection-fill"
          }
        ></div>
      </div>
    </table>
  );
};

const TableSelectionOverlay: React.FC<{
  tableId: string;
  cellCache: React.MutableRefObject<ICellCache>;
  tableBlockColumnData: any;
  tableRef: React.MutableRefObject<HTMLTableElement | null>;
}> = ({ tableId, cellCache, tableBlockColumnData, tableRef }) => {
  const [position, setPosition] = useState<{
    width: number;
    top: number;
    height: number;
    left: number;
  } | null>(null);
  const [showOverlay, setshowOverlay] = useState(true);
  const tableBlock = useShallowSelector((store) => store.blocks.dict[tableId]);

  useEffect(() => {
    const calculateFocus = (tableFocusData: ITableSignals) => {
      if (tableFocusData.focusTableId === tableId) {
        if (tableFocusData.focusRowId && tableFocusData.focusColumnId) {
          const leaf =
            cellCache.current[
              tableFocusData.focusRowId + tableFocusData.focusColumnId
            ];
          if (!leaf) return;
          const width = leaf.offsetWidth + 1;
          const height = leaf.offsetHeight + 1;
          setPosition({
            width,
            height,
            left: leaf.offsetLeft - 1,
            top: leaf.offsetTop + 8 - 1,
          });
          return;
        }
        if (tableFocusData.focusRowId && !tableFocusData.focusColumnId) {
          const columns: string[] = tableBlockColumnData.order ?? [];
          const firstCellInFocusedRow =
            cellCache.current[tableFocusData.focusRowId + columns[0]];
          const lastCellInFocusedRow =
            cellCache.current[
              tableFocusData.focusRowId + columns[columns.length - 1]
            ];

          const width =
            lastCellInFocusedRow.offsetLeft -
            firstCellInFocusedRow.offsetLeft +
            lastCellInFocusedRow.offsetWidth +
            2;

          const height = firstCellInFocusedRow.offsetHeight + 1;

          setPosition({
            width,
            height,
            left: 0,
            top: firstCellInFocusedRow.offsetTop + 8 - 1,
          });
          return;
        }
        if (tableFocusData.focusColumnId && !tableFocusData.focusRowId) {
          const rows = tableBlock.children;
          const firstCellInFocusedColumn =
            cellCache.current[rows[0] + tableFocusData.focusColumnId];
          const lastCellInFocusedColumn =
            cellCache.current[
              rows[rows.length - 1] + tableFocusData.focusColumnId
            ];
          const width = firstCellInFocusedColumn.offsetWidth + 1;

          const height =
            lastCellInFocusedColumn.offsetTop -
            firstCellInFocusedColumn.offsetTop +
            lastCellInFocusedColumn.offsetHeight +
            1;

          setPosition({
            width,
            height,
            top: 8,
            left: firstCellInFocusedColumn.offsetLeft - 1,
          });
          return;
        }
      }
      setPosition(null);
    };
    const sub = tableListeners.subscribe((tableFocusData) => {
      calculateFocus(tableFocusData);
    });
    const sub2 = tableDndObserver.subscribe((dndData) => {
      if (dndData.tableId && dndData.tableId === tableId) {
        setshowOverlay(false);
        return;
      }
      setshowOverlay((current) => {
        if (current === false) {
          const tableFocusData = tableListeners.value;
          calculateFocus(tableFocusData);
        }
        return true;
      });
    });
    const resizeObserver = new ResizeObserver(() => {
      const tableFocusData = tableListeners.value;
      calculateFocus(tableFocusData);
    });
    if (tableRef.current) resizeObserver.observe(tableRef.current);
    return () => {
      sub?.unsubscribe();
      sub2.unsubscribe();
      resizeObserver.disconnect();
    };
  }, [tableBlock.children, tableBlockColumnData]);

  if (!position) return <></>;

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          pointerEvents: "none",
        }}
      >
        <TableColumnDragAndDropListener tableId={tableId} />
        <TableRowDragAndDropListener tableId={tableId} />
        <div
          style={{
            visibility: showOverlay ? "visible" : "hidden",
            position: "absolute",
            ...position,
            border: "2px solid rgba(181, 101, 146, 0.3)",
            borderRadius: "2px",
            zIndex: 3,
          }}
        ></div>
      </div>
    </>
  );
};

export default TableBlock;
