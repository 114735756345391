import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { batch, shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import roleGalleryStyle from "./roleGallery/roleGallery.module.scss";
import notificationsApi from "clientApi/notificationsApi";
import { ITokenVerification, web3Api } from "clientApi/web3Api";
import rolesApi from "clientApi/rolesApi";
import { TokenDetailContainer } from "clarity-ui/tokenGateContainer/TokenGateRow";
import { Switch } from "antd";
import { IRole, ITokenRequirement, UserRole } from "utilities/types";
import { CheckOutlined } from "@ant-design/icons";
import LockTwoTone from "icons/Components/LockTwoTone";
import {
  getTokenAddress,
  getTokenDescription,
} from "clarity-ui/tokenGateContainer/TokenDetailRow";
import ArrowRightUp from "icons/icon-right-45deg.svg";
import Modal from "clarity-ui/Modal";
import ReactDOM from "react-dom";
import Button from "components/Button";
import {
  setPaneSubroutes,
  setPaneTopNavViewType,
  TopNavbarType,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import { useBase } from "store/reducers/workspaceReducer";
import EmptyState from "clarity-ui/EmptyState";
import { useIsBaseMember, useRoleType } from "store/reducers/clientReducer";
import { ChunkDestination } from "utilities/stateTypes";

const RoleGallery: React.FC<{ paneId: ChunkDestination }> = memo(
  ({ paneId }) => {
    usePageDataSetter(paneId, {
      title: "Roles",
    });

    useEffect(() => {
      batch(() => {
        setPaneTopNavViewType({
          paneId,
          navType: TopNavbarType.base,
        });
        setPaneSubroutes({
          paneId,
          subRoutesParams: {
            type: "groups&roles",
            activeKey: "roles",
          },
        });
      });

      return () => {
        batch(() => {
          setPaneTopNavViewType({
            paneId,
          });
          setPaneSubroutes({
            paneId,
          });
        });
      };
    }, [paneId]);

    const roleIds = useRolesToShow();

    return (
      <>
        <div className={roleGalleryStyle.roleGallerySectionHolder}>
          <div
            className={`caption regular secondary ${roleGalleryStyle.featureExplainer}`}
          >
            <ExplainerText />
          </div>
          <RoleGalleryContent roleIds={roleIds} />
        </div>
      </>
    );
  }
);

const ExplainerText: React.FC<{}> = () => {
  const base = useBase();
  return (
    <>
      {`This is the Roles Gallery. You can manage your own abilities in ${base.base.name} by turning roles on & off. Some roles may have token requirements. If a role says “Cannot self-assign”, then an admin will need to manage that role for you in Base Settings > Members. `}
      <a
        href="https://clarity.so/post/introducing-token-based-roles-permissions"
        target="_blank"
        className={`${roleGalleryStyle.link}`}
        rel="noreferrer"
      >
        Learn more
      </a>
    </>
  );
};

export const RoleGalleryContent: React.FC<{ roleIds: string[] }> = ({
  roleIds,
}) => {
  const [errorModalRes, seterrorModalRes] = useState<
    null | ITokenVerification[]
  >(null);
  return (
    <>
      <div className={roleGalleryStyle.roleGallerySections}>
        {useMemo(
          () =>
            roleIds.map((roleId) => (
              <RoleCard
                key={roleId}
                id={roleId}
                seterrorModalRes={seterrorModalRes}
              />
            )),
          [roleIds]
        )}
        {roleIds.length === 0 && (
          <EmptyState
            heading={""}
            caption={"There are no roles created in this base"}
          />
        )}
      </div>
      {errorModalRes && (
        <ReqErrorModal
          res={errorModalRes}
          seterrorModalRes={seterrorModalRes}
        />
      )}
    </>
  );
};
const RoleCard: React.FC<{
  id: string;
  seterrorModalRes: React.Dispatch<
    React.SetStateAction<ITokenVerification[] | null>
  >;
}> = memo(({ id, seterrorModalRes }) => {
  const role: IRole = useSelector(
    (store: ClarityStore) => store.roles.dict[id],
    shallowEqual
  );

  const tokenGate = useSelector(
    (state: ClarityStore) =>
      role?.tokenGateId ? state.tokenGates.dict[role?.tokenGateId] : undefined,
    shallowEqual
  );

  const isEquiped = useIsEquiped(id);
  const [isWaiting, setisWaiting] = useState(false);
  const isMember = useIsBaseMember();
  const userRole = useRoleType();
  const addRole = useCallback(async () => {
    if (!role.selfAssignable) return;
    if (isEquiped) {
      rolesApi.removeRole(role.id);
      return;
    }
    if (!role.tokenGateId) {
      rolesApi.equipRole(role.id);
      return;
    }

    const user = store.getState().user;
    if (!user?.publicEthAdress) {
      notificationsApi.displayError({
        title: <span>Error equiping role</span>,
        body: (
          <span>
            You must connect a wallet to your account to equip this role
          </span>
        ),
        duration: 3,
      });
      return;
    }

    if (!tokenGate) {
      notificationsApi.displayError({
        title: <span>Error equiping role</span>,
        body: <span>Oops, something went wrong!</span>,
        duration: 3,
      });
    } else {
      setisWaiting(true);
      const res = await web3Api.checkHasTokens(tokenGate.tokenRequirements);
      const hasAll = res.length === 0 || res.every((value) => value.success);

      if (hasAll) {
        rolesApi.equipRole(role.id);
      } else {
        seterrorModalRes(res);
      }
      setisWaiting(false);
    }
  }, [role, tokenGate, isEquiped]);

  return useMemo(() => {
    if (!role) return <></>;
    return (
      <div className={roleGalleryStyle.card}>
        <div className={roleGalleryStyle.header}>
          <h3
            className={`${roleGalleryStyle.h3} ${
              !isEquiped ? roleGalleryStyle.disabled : ""
            }`}
          >
            {role.roleName}
          </h3>
          <Switch
            onChange={addRole}
            loading={isWaiting}
            checked={isEquiped}
            checkedChildren={<CheckOutlined />}
            disabled={
              !role.selfAssignable || !isMember || userRole === UserRole.GUEST
            }
          />
        </div>
        <div className={`${roleGalleryStyle.body} body2 secondary regular`}>
          {role.description}
        </div>
        <div className={roleGalleryStyle.footer}>
          {tokenGate?.tokenRequirements && (
            <RoleTokenRequirements
              tokenRequirements={tokenGate.tokenRequirements}
            />
          )}
          {!role.selfAssignable && (
            <span className={roleGalleryStyle.tagItem}>
              <LockTwoTone />
              Cannot self-assign
            </span>
          )}
        </div>
      </div>
    );
  }, [role, isEquiped, tokenGate, isWaiting]);
});

const ReqErrorModal: React.FC<{
  res: ITokenVerification[];
  seterrorModalRes: React.Dispatch<
    React.SetStateAction<ITokenVerification[] | null>
  >;
}> = ({ res, seterrorModalRes }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Modal
          size="medium"
          padding={false}
          className={roleGalleryStyle.modal}
          hideModal={() => seterrorModalRes(null)}
        >
          <h4 className="h4" style={{ fontSize: "18px", lineHeight: "20px" }}>
            Missing Required Tokens
          </h4>
          <p
            className="caption secondary regular"
            style={{ marginTop: "10px", marginBottom: "30px" }}
          >
            The connected wallet does not meet the token requirements for this
            role
          </p>
          <TokenDetailContainer tokenRequirements={res} showStatus={true} />
          <div style={{ marginTop: "38px" }}>
            <Button
              style={{ marginLeft: "auto" }}
              onClick={() => seterrorModalRes(null)}
            >
              Okay
            </Button>
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
};

const useRolesToShow = () => {
  const userRoleIds = useSelector(
    (state: ClarityStore) => state.roles.ids,
    shallowEqual
  );
  const roleDict = useSelector(
    (store: ClarityStore) => store.roles.dict,
    shallowEqual
  );
  const [rolesToShow, setRolesToShow] = useState<string[]>([]);

  useEffect(() => {
    const displayRoles = userRoleIds.filter((roleId) => {
      const role = roleDict[roleId];
      if (role.roleName === "Guest") {
        return false;
      }

      if (role.roleName === "Owner") {
        return false;
      }

      if (role.roleName === "No Role") {
        return false;
      }

      if (role.roleType === "personal") {
        return false;
      }

      return true;
    });
    displayRoles.sort((a, b) => {
      const aRole = roleDict[a];
      const bRole = roleDict[b];
      return bRole.rank?.localeCompare(aRole?.rank, "en");
    });
    if (JSON.stringify(displayRoles) !== JSON.stringify(rolesToShow))
      setRolesToShow(displayRoles);
  }, [userRoleIds, roleDict]);

  return rolesToShow;
};

const useIsEquiped = (roleId: string) => {
  const equipedRoles = useSelector(
    (store: ClarityStore) => store.client.roleIds,
    shallowEqual
  );
  const [isEquiped, setisEquiped] = useState(equipedRoles?.includes(roleId));

  useEffect(() => {
    setisEquiped(equipedRoles?.includes(roleId));
  }, [equipedRoles]);

  return isEquiped;
};

const RoleTokenRequirements: React.FC<{
  tokenRequirements: ITokenRequirement[];
}> = ({ tokenRequirements }) => {
  return (
    <>
      {tokenRequirements.map((token, index) => (
        <a
          key={token.symbol + index}
          className={`${roleGalleryStyle.tagItem}`}
          target="_blank"
          href={getTokenAddress(token)}
          rel="noreferrer"
        >
          {token.ammount} {getTokenDescription(token)}
          <img src={ArrowRightUp} alt="Open in primary view" />
        </a>
      ))}
    </>
  );
};

export default RoleGallery;
