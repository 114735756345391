import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getOutlineListType } from "screens/base/main/detailView/documentContainer/Document";
import { ClarityStore } from "store/storeExporter";
import { IBlockContext } from "utilities/types";
import { BlockContextPass } from "./blockContainer/BlockContext";
import BlocksContainer from "./blockContainer/BlocksContainer";
import ContainerWrapper from "./blockContainer/ContainerWrapper";

interface EditorProps {
  context: IBlockContext;
  outlineMode: string;
  isBaseMember: boolean;
}
const EditorContainer: React.FC<EditorProps> = ({
  context,
  outlineMode,
  isBaseMember,
}) => {
  const contextData = useSelector(
    (state: ClarityStore) =>
      state.blocks.contexts[context.id]?.isFullContainerLoaded,
    shallowEqual
  );

  return useMemo(() => {
    if (!contextData) return <></>;

    return (
      <ol
        className={"list-type-" + getOutlineListType(outlineMode) + " content"}
      >
        <ContainerWrapper context={context} isBaseMember={isBaseMember}>
          <BlockContextPass.Provider value={context}>
            <BlocksContainer parentListType={getOutlineListType(outlineMode)} />
          </BlockContextPass.Provider>
        </ContainerWrapper>
      </ol>
    );
  }, [contextData, context, outlineMode, isBaseMember]);
};

export default EditorContainer;
