import Icon from "@ant-design/icons";
import React from "react";

export default function ProjectTwoTone(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        d="M20.625 2.625H3.375C2.96016 2.625 2.625 2.96016 2.625 3.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V3.375C21.375 2.96016 21.0398 2.625 20.625 2.625ZM19.6875 19.6875H4.3125V4.3125H19.6875V19.6875Z"
        fill="currentColor"
      />
      <path
        opacity="0.1"
        d="M4.3125 19.6875H19.6875V4.3125H4.3125V19.6875ZM15.375 6.5625C15.375 6.45937 15.4594 6.375 15.5625 6.375H17.4375C17.5406 6.375 17.625 6.45937 17.625 6.5625V12.5625C17.625 12.6656 17.5406 12.75 17.4375 12.75H15.5625C15.4594 12.75 15.375 12.6656 15.375 12.5625V6.5625ZM10.875 6.5625C10.875 6.45937 10.9594 6.375 11.0625 6.375H12.9375C13.0406 6.375 13.125 6.45937 13.125 6.5625V10.875C13.125 10.9781 13.0406 11.0625 12.9375 11.0625H11.0625C10.9594 11.0625 10.875 10.9781 10.875 10.875V6.5625ZM6.375 6.5625C6.375 6.45937 6.45937 6.375 6.5625 6.375H8.4375C8.54063 6.375 8.625 6.45937 8.625 6.5625V17.4375C8.625 17.5406 8.54063 17.625 8.4375 17.625H6.5625C6.45937 17.625 6.375 17.5406 6.375 17.4375V6.5625Z"
        fill="currentColor"
      />
      <path
        d="M6.5625 17.625H8.4375C8.54063 17.625 8.625 17.5406 8.625 17.4375V6.5625C8.625 6.45937 8.54063 6.375 8.4375 6.375H6.5625C6.45937 6.375 6.375 6.45937 6.375 6.5625V17.4375C6.375 17.5406 6.45937 17.625 6.5625 17.625ZM11.0625 11.0625H12.9375C13.0406 11.0625 13.125 10.9781 13.125 10.875V6.5625C13.125 6.45937 13.0406 6.375 12.9375 6.375H11.0625C10.9594 6.375 10.875 6.45937 10.875 6.5625V10.875C10.875 10.9781 10.9594 11.0625 11.0625 11.0625ZM15.5625 12.75H17.4375C17.5406 12.75 17.625 12.6656 17.625 12.5625V6.5625C17.625 6.45937 17.5406 6.375 17.4375 6.375H15.5625C15.4594 6.375 15.375 6.45937 15.375 6.5625V12.5625C15.375 12.6656 15.4594 12.75 15.5625 12.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
