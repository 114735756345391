import Icon from "@ant-design/icons";
import React from "react";

export default function ControlTwoTone(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        d="M20.625 2.625H3.375C2.96016 2.625 2.625 2.96016 2.625 3.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V3.375C21.375 2.96016 21.0398 2.625 20.625 2.625ZM19.6875 19.6875H4.3125V4.3125H19.6875V19.6875Z"
        fill="currentColor"
      />
      <path
        opacity="0.1"
        d="M4.3125 19.6875H19.6875V4.3125H4.3125V19.6875ZM7.35938 11.0672H8.45859C8.69766 11.0672 8.925 11.182 9.06562 11.3789L10.7344 13.6945L14.4188 8.58516C14.5594 8.39062 14.7844 8.27344 15.0258 8.27344H16.125C16.2773 8.27344 16.3664 8.44688 16.2773 8.57109L11.3414 15.4148C11.2724 15.5111 11.1815 15.5896 11.0761 15.6437C10.9707 15.6978 10.854 15.7261 10.7355 15.7261C10.6171 15.7261 10.5003 15.6978 10.395 15.6437C10.2896 15.5896 10.1987 15.5111 10.1297 15.4148L7.20703 11.3648C7.11797 11.2406 7.20703 11.0672 7.35938 11.0672Z"
        fill="currentColor"
      />
      <path
        d="M10.1297 15.4148C10.1987 15.5111 10.2897 15.5896 10.395 15.6437C10.5004 15.6978 10.6171 15.7261 10.7356 15.7261C10.8541 15.7261 10.9708 15.6978 11.0762 15.6437C11.1815 15.5896 11.2725 15.5111 11.3415 15.4148L16.2774 8.57109C16.3665 8.44688 16.2774 8.27344 16.1251 8.27344H15.0258C14.7844 8.27344 14.5594 8.39062 14.4188 8.58516L10.7344 13.6945L9.06568 11.3789C8.92505 11.182 8.69771 11.0672 8.45865 11.0672H7.35943C7.20708 11.0672 7.11802 11.2406 7.20708 11.3648L10.1297 15.4148Z"
        fill="currentColor"
      />
    </svg>
  );
}
