export const UPDATE_LINES_GO_TO_PREV_POS = "UPDATE_LINES_GO_TO_PREV_POS";
export const UPDATE_LINES = "UPDATE_LINES_S";
export const BATCH_BLOCK_UPDATE = "BATCH_BLOCK_UPDATE";
export const DELETE_LINE = "DELETE_LINE";
export const ADD_LINE = "ADD_LINE";
export const APPEND_TO_PREVIOUS_LINE = "APPEND_TO_PREVIOUS_LINE";
export const OUTDENT_LINE = "OUTDENT_LINE";
export const CHANGE_LINE_TYPE = "CHANGE_LINE_TYPE";
export const CHANGE_LIST_TYPE = "CHANGE_LIST_TYPE";
export const ADD_BLOCK_AT_CONTAINER_END = "ADD_BLOCK_AT_CONTAINER_END";
export const REFOCUS = "REFOCUS";
export const BLOCK_UPDATE = "BLOCK_UPDATE";
export const SAVE_BLOCK_DATA = "SAVE_BLOCK_DATA";

export const SET_LINE_NUMBER = "SET_LINE_NUMBER";
export const RESET_LINES_NUMBERS = "RESET_LINES_NUMBERS";
export const RESET_DOCUMENT_RANKS = "RESET_DOCUMENT_RANKS";
export const RESET_STATE = "RESET_STATE";
export const CREATE_FIRST_BLOCK = "CREATE_FIRST_BLOCK";
export const DELETE_FIRST_BLOCK = "DELETE_FIRST_BLOCK";

export const UPDATE_LINE_STATUS = "UPDATE_LINE_STATUS";
export const UPDATE_LINE_TEXT = "UPDATE_LINE_TEXT";
export const CHANGE_BLOCK_IS_FOLDED = "CHANGE_BLOCK_IS_FOLDED";
export const UPDATE_SINLGE_LINE_TEXT = "UPDATE_SINLGE_LINE_TEXT";
export const UPDATE_SINLGE_LINE_TEXT_NEW = "UPDATE_SINLGE_LINE_TEXT_NEW";
export const UNDOABLE_TEXT_UPDATE = "UNDOABLE_TEXT_UPDATE";
export const UNFOLD_ANCESTORS = "UNFOLD_ANCESTORS";

export const SET_AUTHENTICATED_USER = "SET_AUTHENTICATED_USER";
export const SET_USER_IN_DICT = "SET_USER_IN_DICT";
export const REMOVE_AUTHENTICATED_USER = "REMOVE_AUTHENTICATED_USER";
export const SET_ACTIVE_WORKSPACE = "SET_ACTIVE_WORKSPACE";
export const ADD_NEW_WORKSPACE = "ADD_NEW_WORKSPACE";
export const UPDATE_WORKSPACE_RANK = "UPDATE_WORKSPACE_RANK";
export const UPDATE_WORKSPACE_USER_SETTINGS = "UPDATE_WORKSPACE_USER_SETTINGS";
export const LOAD_WORKSPACE_DOCUMENTS = "LOAD_WORKSPACE_DOCUMENTS";
export const SET_BASE_LABELS = "SET_BASE_LABELS";
export const ADD_BASE_LABEL = "ADD_BASE_LABEL";
export const SET_BASE_MEMBERS = "SET_BASE_MEMBERS";
export const CHANGED_BASE_MEMBER = "CHANGED_BASE_MEMBER";
export const ADD_ONLINE_MEMBER = "ADD_ONLINE_MEMBER";
export const REMOVE_ONLINE_MEMBER = "REMOVE_ONLINE_MEMBER";
export const ADDED_BASE_INVITATION = "ADDED_BASE_INVITATION";
export const ADD_NEW_DOCUMENT = "ADD_NEW_DOCUMENT";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const SET_PRIMARY_ACTIVE_ENTITY = "SET_PRIMARY_ACTIVE_ENTITY";
export const CLEAR_ACTIVE_DOCUMENT = "CLEAR_ACTIVE_DOCUMENT";
export const UPDATE_PRIMARY_ACTIVE_DOCUMENT_NAME =
  "UPDATE_PRIMARY_ACTIVE_DOCUMENT_NAME";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_PUBLIC_VIEW = "SET_PUBLIC_VIEW";
export const CLEAR_JUST_CLOSED_WORK_ITEMS = "CLEAR_JUST_CLOSED_WORK_ITEMS";
export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const UPDATE_HOME_OBJ = "UPDATE_HOME_OBJ";

export const CHANGE_DOCUMENT_MODE = "CHANGE_DOCUMENT_MODE";
export const MOVE_LINE = "MOVE_LINE";
export const SELECT_LINE = "SELECT_LINE";
export const UNSELECT_LINE = "UNSELECT_LINE";
export const UNSELECT_LINES = "UNSELECT_LINES";
export const SELECT_ALL = "SELECT_ALL";
export const MOVE_TO_NEW_SELECTED_LINE = "MOVE_TO_NEW_SELECTED_LINE";

export const CHECK_ACCORDING_LINE = "CHECK_ACCORDING_LINE";
export const CHANGE_CURRENT_ID = "CHANGE_CURRENT_ID";
export const CHANGE_CURRENT_ID_TO_FIRST_BLOCK =
  "CHANGE_CURRENT_ID_TO_FIRST_BLOCK";

export const COPY_LINES = "COPY_LINES";
export const PASTE_BLOCKS = "PASTE_BLOCKS";
export const CHANGE_MOUSE_DOWN = "CHANGE_MOUSE_DOWN";

export const SET_DOCUMENT_CITATIONS = "SET_DOCUMENT_CITATIONS";
export const ADD_DOCUMENT_CITATION = "ADD_DOCUMENT_CITATION";
export const ADD_SINGLE_MENTION = "ADD_SINGLE_MENTION";
export const SET_DOCUMENT_SOURCE_CITATIONS = "SET_DOCUMENT_SOURCE_CITATIONS";
export const UPDATE_DOCUMENT_NAME = "UPDATE_DOCUMENT_NAME";
export const SET_NOTE_CITATIONS = "SET_NOTE_CITATIONS";

export const CHECK_FOR_NEW_TAGS = "CHECK_FOR_NEW_TAGS";
export const CHECK_TITLE_UPDATE = "CHECK_TITLE_UPDATE";
export const CHECK_TAG_BLOCK_CHANGE = "CHECK_TAG_BLOCK_CHANGE";

export const SET_DOCUMENT_NAME_TOP_NAV = "SET_DOCUMENT_NAME_TOP_NAV";

export const CHANGE_CARET_POSITION = "CHANGE_CARET_POSITION";
export const REMOVE_ACCESS_TOKEN = "REMOVE_ACCESS_TOKEN";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export const SHOW_MODAL_DOCUMENT_SHARE = "SHOW_MODAL_DOCUMENT_SHARE";
export const HIDE_MODAL_DOCUMENT_SHARE = "HIDE_MODAL_DOCUMENT_SHARE";
export const HIDE_MODAL_BASE_SETTINGS = "HIDE_MODAL_BASE_SETTINGS";
export const SHOW_MODAL_BASE_SETTINGS = "SHOW_MODAL_BASE_SETTINGS";

export const SET_PUBLIC_ACCESS_FOR_PRIMARY_ACTIVE_DOC =
  "SET_PUBLIC_ACCESS_FOR_PRIMARY_ACTIVE_DOC";
export const CHANGE_DOC_PUBLIC_ACCESS = "CHANGE_DOC_PUBLIC_ACCESS";

export const SET_REFRESH_REDIRECT_URL = "SET_REFRESH_REDIRECT_URL";
export const CLEAR_WORKSPACE_DATA = "CLEAR_WORKSPACE_DATA";

export const SET_DOCUMENT_PERMISSION = "SET_DOCUMENT_PERMISSION";

export const FREEZE_BLOCK = "FREEZE_BLOCK";
export const UNFREEZE_BLOCK = "UNFREEZE_BLOCK";
export const UPDATE_USER = "UPDATE_USER";
export const BATCH_UPDATE = "BATCH_UPDATE";

export const CLIENT_OFFLINE = "CLIENT_OFFLINE";
export const CLIENT_ONLINE = "CLIENT_ONLINE";
export const ADD_ERROR_MESSAGE = "ADD_ERROR_MESSAGE";

export const TOGGLE_SIDEBAR_VISIBILITY = "TOGGLE_SIDEBAR_VISIBILITY";
export const SET_SIDEBAR_MODE = "SET_SIDEBAR_MODE";
export const SET_CLARITY_SIDEBAR_MODE = "SET_CLARITY_SIDEBAR_MODE";

export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const LOAD_BASE_PROJECTS = "LOAD_BASE_PROJECTS";

export const CHANGE_PROJECT_IS_CLOSED = "CHANGE_PROJECT_IS_CLOSED";
export const REMOVE_PROJECT_FROM_TREE = "REMOVE_PROJECT_FROM_TREE";
export const UPDATE_PROJECTS = "UPDATE_PROJECTS";
export const UPDATE_PROJECT_NAME = "UPDATE_PROJECT_NAME";
export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_PROJECT_RANK = "UPDATE_PROJECT_RANK";
export const SET_PRIMARY_ACTIVE_PROJECT = "SET_PRIMARY_ACTIVE_PROJECT";
export const CLEAR_ACTIVE_PROJECT = "CLEAR_ACTIVE_PROJECT";
export const UPDATE_PRIMARY_ACTIVE_PROJECT_NAME =
  "UPDATE_PRIMARY_ACTIVE_PROJECT_NAME";
export const SET_PROJECT_TREE_VIEW = "SET_PROJECT_TREE_VIEW";
export const SELECT_SINGLE_PROJECT = "SELECT_SINGLE_PROJECT";
export const UPDATE_CONTAINER = "UPDATE_CONTAINER";
export const PATCH_DOCUMENT = "PATCH_DOCUMENT";
export const PATCH_PROJECT = "PATCH_PROJECT";
export const PATCH_NOTE = "PATCH_NOTE";
export const SET_NOTE_IN_DICT = "SET_NOTE_IN_DICT";
export const ADD_PROJECT_IN_TREE = "ADD_PROJECT_IN_TREE";

export const SHOW_MODAL_NEW_PROJECT = "SHOW_MODAL_NEW_PROJECT";
export const SET_TEMP_WORK_ITEM = "SET_TEMP_WORK_ITEM";
export const HIDE_MODAL_NEW_PROJECT = "HIDE_MODAL_NEW_PROJECT";
export const SHOW_MODAL_NEW_TEMPLATE = "SHOW_MODAL_NEW_TEMPLATE";
export const HIDE_MODAL_NEW_TEMPLATE = "HIDE_MODAL_NEW_TEMPLATE";
export const SHOW_MODAL_NEW_SNIPPET = "SHOW_MODAL_NEW_SNIPPET";
export const HIDE_MODAL_NEW_SNIPPET = "HIDE_MODAL_NEW_SNIPPET";

export const SHOW_SECONDARY_VIEW = "SHOW_SECONDARY_VIEW";
export const HIDE_SECONDARY_VIEW = "HIDE_SECONDARY_VIEW";
export const SET_SECONDARY_VIEW_TEMPORARY_VIEW =
  "SET_SECONDARY_VIEW_TEMPORARY_VIEW";
export const REMOVE_SECONDARY_VIEW_TEMPORARY_VIEW =
  "REMOVE_SECONDARY_VIEW_TEMPORARY_VIEW";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const LINK_TO_ENTITY = "LINK_TO_ENTITY";
export const REMOVE_MENU_STATE = "REMOVE_MENU_STATE";

export const LOAD_BASE_PINS = "LOAD_BASE_PINS";
export const ADD_NEW_PIN = "ADD_NEW_PIN";
export const REMOVE_PIN = "REMOVE_PIN";
export const UPDATE_PIN = "UPDATE_PIN";

export const LOAD_BASE_RESOURCES = "LOAD_BASE_RESOURCES";
export const LOAD_INVITATION_LINKS = "LOAD_INVITATION_LINKS";
export const PATCH_INVITATION_LINK = "PATCH_INVITATION_LINK";
export const ADD_RESOURCE = "ADD_RESOURCE";
export const LOAD_BASE_NOTES = "LOAD_BASE_NOTES";
export const LOAD_BASE_WEEKLY_NOTES = "LOAD_BASE_WEEKLY_NOTES";
export const RESET_NOTES = "RESET_NOTES";
export const LOAD_NOTE_WITHOUT_BLOCKS = "LOAD_NOTE_WITHOUT_BLOCKS";
export const LOAD_NEW_WEEKLY_WITHOUT_BLOCKS = "LOAD_NEW_WEEKLY_WITHOUT_BLOCKS";
export const ADD_NEW_NOTE = "ADD_NEW_NOTE";
export const LOAD_NEXT_NOTE_BATCH = "LOAD_NEXT_NOTE_BATCH";
export const LOAD_TASK_ACTIVITIES = "LOAD_TASK_ACTIVITIES";
export const SET_NOTE_OBJECTS = "SET_NOTE_OBJECTS";
export const RELOAD_BASE = "RELOAD_BASE";

export const SET_SECONDARY_VIEW = "SET_SECONDARY_VIEW";
export const TOGGLE_SECONDARY_VIEW = "TOGGLE_SECONDARY_VIEW";
export const SET_MODAL_IMAGE_SELECT = "SET_MODAL_IMAGE_SELECT";
export const SET_MODAL_CITATION_SYNC = "SET_MODAL_CITATION_SYNC";

export const HIDE_MODAL_USER_SETTINGS = "HIDE_MODAL_USER_SETTINGS";
export const SHOW_MODAL_USER_SETTINGS = "SHOW_MODAL_USER_SETTINGS";

export const SET_SWITCH_BASE_MENU_VISIBILITY =
  "SET_SWITCH_BASE_MENU_VISIBILITY";

export const CONNECT_ENTITY_TO_BLOCK = "CONNECT_ENTITY_TO_BLOCK";
export const PASS_RESOURCE_TO_UPLOAD = "PASS_RESOURCE_TO_UPLOAD";
export const CLEAR_RESOURCE_TO_UPLOAD = "CLEAR_RESOURCE_TO_UPLOAD";

export const SET_UNDO_STEP = "SET_UNDO_STEP";
export const HIDE_COMMAND_PALETTE = "HIDE_COMMAND_PALETTE";
export const SHOW_COMMAND_PALETTE = "SHOW_COMMAND_PALETTE";
export const SET_COMMAND_PALETTE_CONTEXT = "SET_COMMAND_PALETTE_CONTEXT";
export const CLOSE_CONFIRMATION_MODAL = "CLOSE_CONFIRMATION_MODAL";
export const OPEN_CONFIRMATION_MODAL = "OPEN_CONFIRMATION_MODAL";
export const OPEN_SELECT_GROUP_MODAL = "OPEN_SELECT_GROUP_MODAL";
export const CLOSE_SELECT_GROUP_MODAL = "CLOSE_SELECT_GROUP_MODAL";
export const TOGGLE_TEMPLATE_MODAL = "TOGGLE_TEMPLATE_MODAL";
export const TOGGLE_IMAGE_MODAL = "TOGGLE_IMAGE_MODAL";
export const SET_INVITATIONS_MODAL = "SET_INVITATIONS_MODAL";
export const SET_GROUP_INVITATIONS_MODAL = "SET_GROUP_INVITATIONS_MODAL";
export const SET_SHARING_AND_PERMS_MODAL = "SET_SHARING_AND_PERMS_MODAL";
export const SET_CONTAINER_INVITE_MODAL = "SET_CONTAINER_INVITE_MODAL";
export const SET_MENTIONS_AND_REPLIES_INBOX = "SET_MENTIONS_AND_REPLIES_INBOX";
export const SET_FILTERS_MODAL = "SET_FILTERS_MODAL";

export const ADD_SELECTED_ENTITIES = "ADD_SELECTED_ENTITIES";
export const REMOVE_SELECTED_ENTITIES = "REMOVE_SELECTED_ENTITIES";
export const CLEAR_SELECTED_ENTITIES = "CLEAR_SELECTED_ENTITIES";
export const SELECT_ONE_ENTITY = "SELECT_ONE_ENTITY";
export const UPDATE_MULTIPLE_BLOCKS_TYPE = "UPDATE_MULTIPLE_BLOCKS_TYPE";
export const UPDATE_MULTIPLE_BLOCKS_TYPE_SKIP_MIDDLE =
  "UPDATE_MULTIPLE_BLOCKS_TYPE_SKIP_MIDDLE";

export const MOVE_BLOCKS_TO_CONTAINER_FRONTEND =
  "MOVE_BLOCKS_TO_CONTAINER_FRONTEND";
export const SET_SEARCH = "SET_SEARCH";

export const DRAG_TOGGLE = "DRAG_TOGGLE";
export const TOGGLE_OUTLINE_MODE = "TOGGLE_OUTLINE_MODE";
export const CYCLE_OUTLINE_MODE = "CYCLE_OUTLINE_MODE";
export const CHANGE_OUTLINE_TYPE = "CHANGE_OUTLINE_TYPE";

export const START_DISCUSSION_FOR_SELECTION = "START_DISCUSSION_FOR_SELECTION";
export const CONFIRM_DISCUSSION_STARTED = "CONFIRM_DISCUSSION_STARTED";

export const SET_CURRENT_DISCUSSION_ID = "SET_CURRENT_DISCUSSION_ID";
export const SET_NEW_DISCUSSION_DETAIL = "SET_NEW_DISCUSSION_DETAIL";
export const SET_CURRENT_PREDISCUSSION_ID = "SET_CURRENT_PREDISCUSSION_ID";
export const REMOVE_PREDISCUSSION_ID = "REMOVE_PREDISCUSSION_ID";
export const SET_EDIT_VIEW_MODAL = "SET_EDIT_VIEW_MODAL";

export const SET_ALL_DISCUSSIONS_MODAL_VISIBILITY =
  "SET_ALL_DISCUSSIONS_MODAL_VISIBILITY";

export const SET_IS_BASE_MEMBER = "SET_IS_BASE_MEMBER";
export const SET_COMMAND_PALETTE_VISIBILITY = "SET_COMMAND_PALETTE_VISIBILITY";
export const ADD_NEW_WORK_ITEM = "ADD_NEW_WORK_ITEM";
export const UPDATE_WORK_ITEM = "UPDATE_WORK_ITEM";
export const UPDATE_WORK_ITEMS = "UPDATE_WORK_ITEMS";
export const REORDER_WORK_ITEM = "REORDER_WORK_ITEM";
export const UPDATE_WORK_ACTIVITY = "UPDATE_WORK_ACTIVITY";
export const DELETE_WORK_ACTIVITY = "DELETE_WORK_ACTIVITY";
export const UPDATE_WORK_STATUS = "UPDATE_WORK_STATUS";
export const TOGGLE_CREATING_CYCLE = "TOGGLE_CREATING_CYCLE";

export const SET_SIDEBAR_WIDTH = "SET_SIDEBAR_WIDTH";
export const SET_SPLITVIEW_WIDTH = "SET_SPLITVIEW_WIDTH";

export const CLEAR_FILTER = "CLEAR_FILTER";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const SET_INITIAL_FILTERS = "SET_INITIAL_FILTERS";

export const REMOVE_NOTE = "REMOVE_NOTE";
export const LOAD_BASE_WORK = "LOAD_BASE_WORK";
export const LOAD_RELATED_WORK = "LOAD_RELATED_WORK";
export const LOAD_RELATED_RESOURCES = "LOAD_RELATED_RESOURCES";
export const PATCH_SECTION = "PATCH_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const ADD_SECTION = "ADD_SECTION";
export const DELETE_WORK_ITEM = "DELETE_WORK_ITEM";
export const SET_WORK_MENTIONS = "SET_WORK_MENTIONS";
export const SET_SECTION_FOLDED = "SET_SECTION_FOLDED";
export const SET_LIST_ITEMS = "SET_LIST_ITEMS";
export const ADD_CONTRIBUTORS = "ADD_CONTRIBUTORS";
export const UPDATE_CONTRIBUTOR = "UPDATE_CONTRIBUTOR";
export const LOAD_BASE_CYCLES = "LOAD_BASE_CYCLES";

// renamed ations
export const ADD_BLOCK = "ADD_BLOCK";
export const INDENT_BLOCK = "INDENT_BLOCK";
export const OUTDENT_BLOCK = "OUTDENT_BLOCK";
export const DELETE_BLOCK = "DELETE_BLOCK";
export const SET_FOCUS = "SET_FOCUS";
export const MOVE_BLOCK = "MOVE_BLOCK";
export const SET_MENTION_OBJECTS = "SET_MENTION_OBJECTS";
export const SET_BLOCK_OBJ = "SET_BLOCK_OBJ";
export const LOAD_UNLINKED_MENTIONS = "LOAD_UNLINKED_MENTIONS";
export const SET_BLOCK_IN_DICT = "SET_BLOCK_IN_DICT";
export const SET_UNDO = "SET_UNDO";
export const BLOCK_UNDO = "BLOCK_UNDO";
export const SAVE_BLOCK_DATA_WITH_SIDE_EFFECTS =
  "SAVE_BLOCK_DATA_WITH_SIDE_EFFECTS";
export const FIX_RANK_ORDER = "FIX_RANK_ORDER";
export const SELECT_BLOCK = "SELECT_BLOCK";
export const UNSELECT_BLOCK = "UNSELECT_BLOCK";
export const SELECT_NEXT_BLOCK = "SELECT_NEXT_BLOCK";
export const UNSELECT_BLOCKS = "UNSELECT_BLOCKS";
export const CLEAR_BLOCKS_SELECTION = "CLEAR_BLOCKS_SELECTION";
export const BATCH_ACTIONS = "BATCH_ACTIONS";
export const SET_NEW_CONTEXT = "SET_NEW_CONTEXT";
export const SET_BLOCKS = "SET_BLOCKS";
export const SET_BLOCKS_FROM_CONTAINER = "SET_BLOCKS_FROM_CONTAINER";
export const SET_BLOCKS_ARRAY_FROM_CONTAINER =
  "SET_BLOCKS_ARRAY_FROM_CONTAINER";
export const SET_SINGLE_BLOCK = "SET_SINGLE_BLOCK";
export const SIDE_EFFECT_ADD_FIRST_BLOCK = "SIDE_EFFECT_ADD_FIRST_BLOCK";
export const SELECT_ALL_BLOCKS = "SELECT_ALL_BLOCKS";
export const TRIGGER_BLOCK_UPDATE = "TRIGGER_BLOCK_UPDATE";

// new citation handlers
export const LOAD_CITATIONS = "LOAD_CITATIONS";
export const ADD_CITATION = "ADD_CITATION";
export const CONNECT_CITATION = "CONNECT_CITATION";
export const DISCONNECT_CITATION = "DISCONNECT_CITATION";

// new mention handlers
export const LOAD_MENTIONS = "LOAD_MENTIONS";
export const ADD_MENTION = "ADD_MENTION";
export const REMOVE_MENTION = "REMOVE_MENTION";

export const UPDATE_CONTEXT = "UPDATE_CONTEXT";
export const RESET_CONTEXT_SNAPSHOT = "RESET_CONTEXT_SNAPSHOT";

// project tree actions
export const REBUILD_PROJECT_BRANCH = "REBUILD_PROJECT_BRANCH";

// notification
export const SET_NEW_NOTIFICATIONS = "SET_NEW_NOTIFICATIONS";
export const SET_DONE_NOTIFICATIONS = "SET_DONE_NOTIFICATIONS";
export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_THREAD = "UPDATE_THREAD";
export const SET_MOUSE_POS = "SET_MOUSE_POS";
export const UPDATE_CUSTOM_VIEW = "UPDATE_CUSTOM_VIEW";
export const SET_CUSTOM_VIEW_PRESETS = "SET_CUSTOM_VIEW_PRESETS";
export const SET_PRESETS = "SET_PRESETS";
export const SET_VIEW_AS = "SET_VIEW_AS";
export const SET_GROUP_BY = "SET_GROUP_BY";
export const SET_ORDER_BY = "SET_ORDER_BY";
export const SET_SHOW_ACTIVE_PROJECTS = "SET_SHOW_ACTIVE_PROJECTS";
export const SET_CURRENT_FILTERS = "SET_CURRENT_FILTERS";
export const RESET_VIEW = "RESET_VIEW";
export const SET_WORK_VIEWS = "SET_WORK_VIEWS";
export const SET_MODAL_ITEM_SELECT = "SET_MODAL_ITEM_SELECT";
export const SET_DEFAULT_MODAL = "SET_DEFAULT_MODAL";
export const SET_BASE_SETTINGS_HAS_CHANGES = "SET_BASE_SETTINGS_HAS_CHANGES";
export const OPEN_BASE_SETTING_SET_INTIAL = "OPEN_BASE_SETTING_SET_INTIAL";

// subscription
export const ADD_NEW_UNIQUE_USER = "ADD_NEW_UNIQUE_USER";
export const REMOVE_UNIQUE_USER = "REMOVE_UNIQUE_USER";
export const LOAD_BASE_SUBSCRIPTION = "LOAD_BASE_SUBSCRIPTION";
export const UPDATE_CURRENT_MONTHLY_COUNT = "UPDATE_CURRENT_MONTHLY_COUNT";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";

// user base settings
export const SET_USER_BASE_SETTINGS = "SET_USER_BASE_SETTINGS";

// permissions
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const UPDATE_USER_ROLES = "UPDATE_USER_ROLES";

// topnav
export const SET_PAGE_DATA = "SET_PAGE_DATA";
export const SET_SHARE_DATA = "SET_SHARE_DATA";
export const SET_TOP_NAV_BTN_COMPONENTS = "SET_TOP_NAV_BTN_COMPONENTS";
export const REMOVE_TOP_NAV_BTN_COMPONENTS = "REMOVE_TOP_NAV_BTN_COMPONENTS";
export const SET_IS_WORK_VIEW = "SET_IS_WORK_VIEW";
export const SET_SHOW_SUBROUTES = "SET_SHOW_SUBROUTES";
export const SET_TOPNAV_VIEW_TYPE = "SET_TOPNAV_VIEW_TYPE";
export const SET_SHOW_BASE_AS_BREADCRUMB = "SET_SHOW_BASE_AS_BREADCRUMB";
export const SET_TASK_COUNT_DATA = "SET_TASK_COUNT_DATA";
export const TOGGLE_CLOSED_PROJECT = "TOGGLE_CLOSED_PROJECT";
export const UPDATE_ROADMAP_MILESTONE_MODE = "UPDATE_ROADMAP_MILESTONE_MODE";
export const RESET_ROADMAP_VIEW = "RESET_ROADMAP_VIEW";

// last visited page
export const SET_LAST_VISITED_PAGE = "SET_LAST_VISITED_PAGE";
export const SET_RECENTLY_VISITED_PAGES = "SET_RECENTLY_VISITED_PAGES";
export const SET_LAST_VISITED_BASE = "SET_LAST_VISITED_BASE";
export const SET_LAST_VISITED_LOCATION = "SET_LAST_VISITED_LOCATION";
export const SET_OPEN_BASE_VIEW = "SET_OPEN_BASE_VIEW";
export const SET_SHOW_LEAVE_BASE_MODAL = "SET_SHOW_LEAVE_BASE_MODAL";

export const UPDATE_TOKEN_GATES = "UPDATE_TOKEN_GATES";
export const SET_BASE_TOKEN_GATES = "SET_BASE_TOKEN_GATES";
export const SET_BASE_TOKENS = "SET_BASE_TOKENS";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const SET_FOLLOWING_PAGES = "SET_FOLLOWING_PAGES";
export const SET_PAGES_IN_DICT = "LOAD_PAGES_IN_DICT";
export const SET_FOLLOWING_NOTES = "SET_FOLLOWING_NOTES";
export const SET_FOLLOWING_WORK = "SET_FOLLOWING_WORK";
export const UPDATE_LOCAL_PERMISSIONS = "UPDATE_LOCAL_PERMISSIONS";

export const UPDATE_MILESTONE = "UPDATE_MILESTONE";

//roles
export const SET_BASE_ROLES = "SET_BASE_ROLES";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLES = "UPDATE_ROLES";
export const ADD_ROLES = "ADD_ROLES";
export const DELETE_ROLES = "DELETE_ROLES";

//groups
export const SET_BASE_GROUPS = "SET_BASE_GROUPS";
export const ADD_GROUP = "ADD_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const UNARCHIVE_GROUP = "UNARCHIVE_GROUP";
export const SET_USER_GROUPS = "SET_USER_GROUPS";
export const CHANGE_USER_GROUP = "CHANGE_USER_GROUP";

export const SET_GROUP_CONTEXT = "SET_GROUP_CONTEXT";
export const REMOVE_GROUP_CONTEXT = "REMOVE_GROUP_CONTEXT";
export const SET_MODAL_JOIN_GROUP = "SET_MODAL_JOIN_GROUP";

//favorites
export const LOAD_BASE_FAVORITES = "LOAD_BASE_FAVORITES";
export const ADD_NEW_FAVORITE = "ADD_NEW_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const UPDATE_FAVORITE = "UPDATE_FAVORITE";

export const UPDATE_NOTE = "UPDATE_NOTE";

//changelogs
export const SET_CHANGELOGS = "SET_CHANGELOGS";
export const READ_ALL = "READ_ALL";

// group work
export const LOAD_TASKS = "LOAD_TASKS";
export const LOAD_PROJECTS = "LOAD_PROJECTS";
export const LOAD_STATUSES = "LOAD_STATUSES";
export const SET_WORK_IN_DICT = "SET_WORK_IN_DICT";
export const DELETE_GROUP_WORK = "DELETE_GROUP_WORK";

// navigation
export const SET_NAVIGATION_CHUNK = "SET_NAVIGATION_CHUNK";
export const CLOSE_SPLIT_VIEW = "CLOSE_SPLIT_VIEW";
export const SWAP_PANES = "SWAP_PANES";
export const CLEAR_NAVIGATION_CHUNK = "CLEAR_NAVIGATION_CHUNK";

//recently Opened
export const SET_RECENTLY_OPENED = "SET_RECENTLY_OPENED";
export const REMOVE_NOTE_SIDEEFFECT = "REMOVE_NOTE_SIDEEFFECT";
export const REMOVE_WORKITEM_SIDEEFFECT = "REMOVE_WORKITEM_SIDEEFFECT";
export const REMOVE_VIEW_SIDEEFFECT = "REMOVE_VIEW_SIDEEFFECT";
export const REMOVE_DOCUMENT_SIDEEFFECT = "REMOVE_DOCUMENT_SIDEEFFECT";
export const REMOVE_TEMPLATE_SIDEEFFECT = "REMOVE_TEMPLATE_SIDEEFFECT";
export const REMOVE_SNIPPET_SIDEEFFECT = "REMOVE_SNIPPET_SIDEEFFECT";
export const SET_LAST_VISITED_GROUP_LOCATION =
  "SET_LAST_VISITED_GROUP_LOCATION";
export const SET_LAST_VISITED_HOME_LOCATION = "SET_LAST_VISITED_HOME_LOCATION";

export const SET_ACTION_INTERCEPTOR = "SET_ACTION_INTERCEPTOR";
export const OPEN_DISCORD_INTEGRATION_MODAL = "OPEN_DISCORD_INTEGRATION_MODAL";

export const SET_NUDGE_SENT_WORK_ITEM = "SET_NUDGE_SENT_WORK_ITEM";
export const SET_LAST_USED_TOKEN = "SET_LAST_USED_TOKEN";

export const SET_BASE_TEMPLATES = "SET_BASE_TEMPLATES";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const UPDATE_MULTIPLE_TEMPLATE_ITEMS = "UPDATE_MULTIPLE_TEMPLATE_ITEMS";
export const SET_BASE_SNIPPET = "SET_BASE_SNIPPET";
export const UPDATE_SNIPPET = "UPDATE_SNIPPET";
