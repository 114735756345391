import { SnippetsOutlined } from "@ant-design/icons";
import { templatesApi } from "clientApi/templateApi";
import Conditional from "components/Conditional";
import { checkCaretPosition } from "editor/utils/caretUtils";
import { useAbilityChecker } from "editor/utils/customHooks";
import { VALUE_DOWN, VALUE_ENTER } from "keycode-js";
import { throttle } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { useShallowSelector } from "utilities/hooks";
import { LineValueType } from "utilities/lineUtilities";
import { Abilities, ITemplateObj } from "utilities/types";
import styles from "../notes/noteTitle/noteTitle.module.scss";

const TemlpateTitle: React.FC<{
  templateId: string;
  customClassName: string;
}> = ({ templateId, customClassName }) => {
  const template = useShallowSelector(
    (store) => store.templates.dict[templateId]
  );
  if (!template) return <></>;

  return (
    <div className={customClassName} style={{ marginBottom: "10px" }}>
      <Conditional on={template.parentId}>
        <TemplateParentDisplay parentId={template.parentId} />
      </Conditional>
      <div className={styles.titleContainer__row1}>
        <TemplateTitleInput template={template} />
      </div>
    </div>
  );
};

const TemplateTitleInput: React.FC<{ template: ITemplateObj }> = ({
  template,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const canEditName = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  const onInput = (ev: React.FormEvent<HTMLDivElement>) => {
    if (ev.currentTarget.childElementCount > 1) {
      if (ref.current) {
        const caretPosition = checkCaretPosition(ref.current);
        moveCaretToPreviousPosition(ref.current, caretPosition);
        ev.currentTarget.innerHTML = ev.currentTarget.textContent as string;
      }
    }
    changeTitle(ev.currentTarget?.textContent ?? "");
  };

  const updateNoteName = async (noteName: string) => {
    templatesApi.update(
      {
        name: noteName,
        nameValue: [{ type: LineValueType.text, value: noteName }],
      },
      [template.id]
    );
    if (ref.current && document.activeElement === ref.current) {
      const caretPosition = checkCaretPosition(ref.current);
      moveCaretToPreviousPosition(ref.current, caretPosition);
    }
  };

  const changeTitle = useCallback(
    throttle(updateNoteName, 500, { trailing: true, leading: false }),
    []
  );

  const navigateToFirstBlock = (e: React.KeyboardEvent) => {
    const nextBlock = document.getElementsByClassName("Block")?.[1];
    if (nextBlock) {
      const content = nextBlock.getElementsByClassName("content-section")[0];
      if (content) {
        e.preventDefault();
        e.stopPropagation();
        (content as HTMLDivElement).focus();
      }
    }
  };

  const handleKeyActions = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case VALUE_DOWN:
      case VALUE_ENTER: {
        navigateToFirstBlock(e);
        break;
      }
    }
  };

  return useMemo(() => {
    return (
      <div
        contentEditable={canEditName ? true : false}
        ref={ref}
        onInput={onInput}
        placeholder={"Untitled snippet"}
        onKeyDown={handleKeyActions}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        className={`${styles.title} Block noteTitle`}
        dangerouslySetInnerHTML={{ __html: template?.name ?? "" }}
      ></div>
    );
  }, [template.id]);
};

const TemplateParentDisplay: React.FC<{ parentId?: string }> = ({
  parentId,
}) => {
  const parent = useShallowSelector((store) =>
    parentId ? store.templates.dict[parentId] : undefined
  );

  const handleParentClick = () => {
    console.log("okkk");
  };

  if (!parent) return <></>;

  return (
    <div style={{ display: "flex" }}>
      <div className={`${styles.parentContainer}`} onClick={handleParentClick}>
        <Conditional on={parent.name}>
          <div className={styles.workTypeContainer}>
            <span className={`${styles.workType}`}>
              <SnippetsOutlined />
            </span>
          </div>
          <span className={styles.parent}>{parent.name}</span>
        </Conditional>
      </div>
    </div>
  );
};

export default TemlpateTitle;
