import rolesApi from "clientApi/rolesApi";
import Button, { ButtonTypes } from "components/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import {
  Abilities,
  abilityToFriendlyName,
  abilityCaptionsToFriendlyName,
  IRole,
  IUserPermissions,
  managableAbilities,
} from "utilities/types";
import RoleAbilities from "../roleGallery/RoleAbilities";
import RoleSection from "./roles/RoleSection";
import TabLayout from "./TabLayout";
import AddOrEditRole from "./roles/AddOrEditRole";
import styles from "./roles/roles.module.scss";
import { useAbilityChecker } from "editor/utils/customHooks";
import notificationsApi from "clientApi/notificationsApi";
import DefaultPermissionSection from "./roles/DefaultPermissionsSection";
import { Switch } from "antd";
import FooterForSave from "./FooterForSave";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";
import { useSetBaseSettingHasChanges } from "store/reducers/clientReducer";

const Roles: React.FC = () => {
  const [addingRole, setaddingRole] = useState(false);
  const [useTableLayout, setuseTableLayout] = useState(false);
  const canManageRoles = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_ROLES,
  });

  return (
    <div
      style={{
        paddingBottom: "5vh",
      }}
    >
      <div className="base-settings--fullwidth-container">
        <TabLayout
          title="Roles"
          description="Manage the roles & permissions that are available to members"
          secondaryActionBtn={
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  height: "28px",
                }}
              >
                <span className="caption regular secondary">
                  Abilities view
                </span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  checked={useTableLayout}
                  onChange={(value) => {
                    setuseTableLayout(value);
                  }}
                />
              </div>
            </>
          }
          actionBtn={
            <Button
              buttonType={ButtonTypes.PRIMARY}
              disabled={!canManageRoles}
              onClick={() => {
                if (!canManageRoles) return;
                setaddingRole(true);
              }}
            >
              <PlusOutlined /> Role
            </Button>
          }
        >
          {!useTableLayout && (
            <div className="base-settings--inner-container">
              <DefaultPermissionSection />
              <RoleSection />
            </div>
          )}
        </TabLayout>
      </div>

      {addingRole && (
        <AddOrEditRole setdetailsOpen={() => setaddingRole(false)} />
      )}

      {useTableLayout && <RoleTable setuseTableLayout={setuseTableLayout} />}
    </div>
  );
};

const RoleTable: React.FC<{
  setuseTableLayout: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setuseTableLayout }) => {
  const roleIds = useSelector(
    (store: ClarityStore) => store.roles.ids,
    shallowEqual
  );
  const roleDict = useSelector(
    (store: ClarityStore) => store.roles.dict,
    shallowEqual
  );

  const [update, setupdate] = useState(false);
  const rolesRef = useRef<{ [key: string]: Partial<IRole> }>({});
  const [newRoles, setNewRoles] = useState<string[]>([]);
  const newRolesDict = useRef<{ [key: string]: IRole }>({});
  const [toShow, setToShow] = useState<IRole[]>([]);
  const canManageRoles = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_ROLES,
  });
  const updateListener = useRef(update);

  useEffect(() => {
    const noShow = ["Guest", "Owner"];
    const selectableRoles = roleIds.map((id) => {
      if (newRoles.includes(id)) {
        const removedId = [...newRoles];
        const index = removedId.indexOf(id);
        removedId.splice(index, 1);
        delete newRolesDict.current[id];

        setNewRoles(removedId);
      }
      return roleDict[id];
    });
    const options = selectableRoles
      .filter((role) => !noShow.includes(role.roleName))
      .filter((role) => role.roleType !== "personal");
    setToShow(options);
  }, [roleIds]);

  const triggerUpdate = () => {
    setupdate(!updateListener.current);
  };

  useEffect(() => {
    updateListener.current = update;
  }, [update]);

  const setUpdate = useCallback(
    (roleId: string, delta: Partial<IUserPermissions>) => {
      if (!canManageRoles) return;
      const roleDict = store.getState().roles.dict;
      const role = roleDict[roleId];
      if (!role) return;
      if (!shallowEqual(delta, role.permissions)) {
        rolesRef.current[roleId] = {
          ...role,
          permissions: delta,
        };
      } else {
        delete rolesRef.current[roleId];
      }
      triggerUpdate();
    },
    []
  );

  const hasChanges =
    Object.values(rolesRef.current).length !== 0 && canManageRoles;

  useSetBaseSettingHasChanges(hasChanges, [
    rolesRef.current,
    update,
    canManageRoles,
  ]);

  const onCancel = () => {
    setuseTableLayout(false);
  };

  return (
    <div className={styles.tableContainer}>
      {hasChanges && (
        <FooterForSave
          onSave={() => {
            if (!canManageRoles) return;
            const updates = Object.values(rolesRef.current);
            if (updates.length > 0) {
              rolesApi.updateRoles(Object.values(rolesRef.current)).then(() => {
                notificationsApi.displayConfirmation({
                  title: "Success",
                  duration: 4,
                  body: "Role updated",
                });
                rolesRef.current = {};
                setupdate(!update);
              });
            }
          }}
          onCancel={onCancel}
        />
      )}
      <div className={styles.tableWrapper}>
        <div className={styles.fixedColumn}>
          <h4
            className={`label bold secondary upper-case ellipsis-nowrap ${styles.headerPaddingBottom}`}
            title="Abilities"
          >
            Abilities
          </h4>
          {managableAbilities.map((ability) => (
            <span
              key={ability}
              className={`${styles.abilityColumn} ${styles.cell}`}
            >
              <span className={`body2 medium secondary`}>
                {abilityToFriendlyName[ability]}
              </span>
              <span className={`small regular secondary`}>
                {abilityCaptionsToFriendlyName[ability]}
              </span>
            </span>
          ))}
        </div>
        {toShow.map((role, i) => {
          return (
            <RoleColumn
              key={i}
              role={role}
              disabled={!canManageRoles}
              setUpdate={setUpdate}
            />
          );
        })}
      </div>
    </div>
  );
};

const RoleColumn: React.FC<{
  role: IRole;
  adding?: boolean;
  disabled?: boolean;
  setUpdate: (
    roleId: string,
    delta: Partial<IUserPermissions>,
    name?: string
  ) => void;
}> = ({ role, setUpdate, adding, disabled }) => {
  return (
    <div>
      <h4
        className={`label bold secondary upper-case ellipsis-nowrap ${styles.headerStyle} ${styles.headerPaddingBottom}`}
        title={role.roleName !== "No Role" ? role.roleName : "Everyone"}
        style={{ textAlign: "center" }}
      >
        {role.roleName !== "No Role" ? role.roleName : "Everyone"}
      </h4>
      <RoleAbilities
        role={role}
        showOwnPermissions={false}
        showName={false}
        cellClass={`${styles.cell}`}
        activeClass={`${styles.active}`}
        toggleType="checkbox"
        showCheckPermission
        onChangeAbilities={disabled ? undefined : setUpdate}
      />
    </div>
  );
};

export default Roles;
