import { Input } from "antd";
import React, { LegacyRef } from "react";
import "./clarityInput/clarityInput.scss";
import { useOptionalClassName } from "utilities/hooks";
import Conditional from "components/Conditional";
import styles from "components/clarityInput/clarityInput.module.scss";

type ClarityTextAreaType = {
  componentRef?: LegacyRef<Input> | undefined;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement> | undefined;
  value?: string | number | readonly string[] | undefined;
  placeholder?: string | undefined;
  rows?: number | undefined;
  className: string | undefined;
  maxLength: number | undefined;
  disabled?: boolean;
};

const ClarityTextArea: React.FC<ClarityTextAreaType> = (props) => {
  const { label } = props;

  const classNames = useOptionalClassName(
    {
      baseStyle: `clarityInput ${"styles.input"} ant-input`,
      pairs: [
        {
          extraStyle: props.className ?? "",
          withExtra: Boolean(props.className),
        },
      ],
    },
    [props.className]
  );

  return (
    <div className={styles.container}>
      <Conditional on={label}>
        <label className={styles.label}>{label}</label>
      </Conditional>
      <Input.TextArea
        maxLength={props.maxLength}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        value={props.value}
        className={classNames}
        disabled={props.disabled}
        rows={props.rows}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default ClarityTextArea;
