import Button, { ButtonTypes } from "components/Button";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SET_DEFAULT_MODAL } from "store/actions";
import {
  LIMIT_FREE_BLOCK_COUNT,
  LIMIT_FREE_WORK,
} from "store/reducers/subscriptionReducer";
import store, { ClarityStore } from "store/storeExporter";
import styles from "./monetizationCard/monetizationCard.module.scss";

const MonetizationCard: React.FC<{}> = () => {
  const [isVisible, setisVisible] = useState(false);
  const [manualHide, setmanualHide] = useState(false);
  const [nameToDisplay, setnameToDisplay] = useState("");
  const subscriptionData = useSelector((state: ClarityStore) => {
    return {
      monthlyCount: state.subscription.monthlyCount,
      activeSub: state.subscription.activeSubscription,
      isFreeBase: state.subscription.isFree,
    };
  }, shallowEqual);

  const userData = useSelector(
    (state: ClarityStore) => ({
      user: state.user,
      base: state.workspace,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (userData.user?.name) {
      const index = userData.user.name.indexOf(" ");
      if (index > 0) {
        const firstName = userData.user.name.substr(0, index);
        setnameToDisplay(firstName);
        return;
      }
      setnameToDisplay(userData.user.name);
      return;
    }
    if (userData.user?.username) setnameToDisplay(`@${userData.user.username}`);
  }, [userData.user]);

  useEffect(() => {
    if (
      subscriptionData.isFreeBase ||
      manualHide ||
      subscriptionData.activeSub
    ) {
      setisVisible(false);
      return;
    }
    if (
      subscriptionData.monthlyCount.blockCount > LIMIT_FREE_BLOCK_COUNT ||
      subscriptionData.monthlyCount.workCount > LIMIT_FREE_WORK
    ) {
      setisVisible(true);
      return;
    }
    setisVisible(false);
  }, [subscriptionData, manualHide]);

  useEffect(() => {
    setmanualHide(false);
  }, [userData.base.id]);

  if (!isVisible) return <></>;
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.header}>Hey {nameToDisplay}! 👋</div>
        <div className={styles.header}>
          {userData.base.name} is over the free limit for this month.
        </div>
        <div className={styles.body}>
          We added a little extra room so you can finish up what you’re doing.
        </div>
        <div className={styles.body}>
          {subscriptionData.monthlyCount.blockCount}/{LIMIT_FREE_BLOCK_COUNT}{" "}
          blocks <br /> {subscriptionData.monthlyCount.workCount}/
          {LIMIT_FREE_WORK} tasks & projects
        </div>
        <div className={styles.footer}>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            style={{
              width: "50%",
              marginRight: "4px",
              justifyContent: "center",
            }}
            onClick={() => {
              store.dispatch({
                type: SET_DEFAULT_MODAL,
                param: {
                  isOpen: true,
                  type: "plansModal",
                },
              });
            }}
          >
            See Plans
          </Button>
          <Button
            buttonType={ButtonTypes.DEFAULT}
            style={{ width: "50%", justifyContent: "center" }}
            onClick={() => setmanualHide(true)}
          >
            Dismiss
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MonetizationCard;
