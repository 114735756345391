import { useEffect, useState } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import Icon, {
  TeamOutlined,
  ScheduleOutlined,
  LinkOutlined,
  KeyOutlined,
  SlackOutlined,
  FileDoneOutlined,
  AlertOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

import {
  IUserObj,
  IWorkspaceObj,
  UserBaseSettings,
  SettingsSidebar,
  SubItem,
} from "utilities/types";
import * as actionTypes from "store/actions";
import Options from "./settings/Options";
import CreateGroup from "./settings/CreateGroup";
import EditGroup from "./settings/EditGroup";
import OptionsCycles from "./settings/OptionsCycles";
import OptionsWeekly from "./settings/OptionsWeekly";
import Notifications from "./settings/Notifications";
import Members from "./settings/Members";
import "./settings/settings.scss";
import Integrations from "./settings/Integrations";
import WorkSettings from "./settings/WorkSettings";
import PricingPlans from "components/PricingPlans";
import MonthlyCount from "clarity-ui/MonthlyCount";
import { ClarityStore } from "store/storeExporter";
import Roles from "screens/base/main/settings/Roles";
import TabLayout from "./settings/TabLayout";
import Tokens from "./settings/Tokens";
import SidebarWrapper, { SidebarGroup, SidebarItemAction } from "../Sidebar";
import {
  useCheckBaseSettingsHasChanges,
  useSettingsOpened,
} from "store/reducers/clientReducer";
import { baseNavigationSubject } from "./settings/FooterForSave";
import OptionsMilestones from "./settings/OptionsMilestones";
import OptionsRewards from "./settings/OptionsRewards";
import Groups from "./settings/Groups";
import { ReactComponent as GroupsIcon } from "../../../icons/menu/Groups.svg";
import TrophyTwoTone from "icons/Components/TrophyTwoTone";
import GoldTwoTone from "icons/Components/GoldTwoTone";
import InfoCircleTwoTone from "icons/Components/InfoCircleTwoTone";
import { ReactComponent as IdcardIcon } from "../../../icons/menu/Idcard.svg";
import { ReactComponent as HomeIcon } from "../../../icons/menu/Home.svg";
import StarTwoTone from "icons/Components/StarTwoTone";
import DiscordIntegration from "./settings/DiscordIntegration";
import { ReactComponent as DiscordIcon } from "icons/discord-icon.svg";
import SettingTwoTone from "icons/Components/SettingTwoTone";
import TagsTwoTone from "icons/Components/IdcardTwoTone";

interface IProps {
  base: IWorkspaceObj;
  user: IUserObj | undefined;
  workspaces: IWorkspaceObj[];
  userBaseSettings: UserBaseSettings;
  setActiveBase: (base: IWorkspaceObj) => {
    type: string;
    workspace: IWorkspaceObj;
  };
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

const Profile = (props: IProps) => {
  const {
    base,
    user,
    workspaces,
    userBaseSettings,
    setActiveBase,
    setAuthenticatedUser,
  } = props;
  const query = useQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeKey, setActiveKey] = useState<undefined | string>(
    getActiveKey(query)
  );
  const [activeSelection, setActiveSelection] = useState("details");
  const [selectedGroup, setSelectedGroup] = useState("");
  const checkIfSettingsHaveChanges = useCheckBaseSettingsHasChanges();
  const activeGroups = useSelector(
    (state: ClarityStore) => state.groups.activeGroupids,
    shallowEqual
  );
  const groupDict = useSelector(
    (state: ClarityStore) => state.groups.dict,
    shallowEqual
  );
  const sideBarItems: SettingsSidebar = {
    preferences: {
      title: "My Preferences",
      subItems: [
        {
          key: "notifications",
          title: "Email notifications",
          icon: <AlertOutlined />,
          enable: true,
        },
      ],
    },
    baseSettings: {
      title: "Base settings",
      subItems: [
        {
          key: "details",
          title: "Details",
          icon: <SettingTwoTone />,
          enable: true,
        },
        {
          key: "members",
          title: "Members",
          icon: <TeamOutlined />,
          enable: true,
        },
        {
          key: "roles",
          title: "Roles",
          icon: <TagsTwoTone />,
          enable: true,
        },
        {
          key: "invite-links",
          title: "Invite Links",
          icon: <LinkOutlined />,
          enable: true,
        },
        {
          key: "tokens",
          title: "Tokens",
          icon: <GoldTwoTone />,
          enable: true,
        },
        {
          key: "token-gates",
          title: "Token Gates",
          icon: <KeyOutlined />,
          enable: true,
        },
        {
          key: "work-statuses",
          title: "Work Statuses",
          icon: <ScheduleOutlined />,
          enable: true,
        },
        {
          key: "plans-billing",
          title: "Plans & Billing",
          icon: <InfoCircleTwoTone />,
          enable: true,
        },
      ],
    },
    corePlugIns: {
      title: "Core plug-ins",
      subItems: [
        {
          key: "home-screen",
          title: "Home Screen",
          icon: <Icon component={HomeIcon}></Icon>,
          enable: false,
        },
        // {
        //   key: "initiatives",
        //   title: "Goals",
        //   icon: <FileDoneOutlined />,
        //   enable: true,
        // },
        // {
        //   key: "join-page",
        //   title: "Join Page",
        //   icon: <ImportOutlined />,
        //   enable: true,
        // },
        {
          key: "rewards",
          title: "Rewards",
          icon: <TrophyTwoTone />,
          enable: true,
        },
        {
          key: "milestones",
          title: "Milestones",
          icon: <FileDoneOutlined />,
          enable: true,
        },
        {
          key: "role-gallery",
          title: "Role Gallery",
          icon: <Icon component={IdcardIcon}></Icon>,
          enable: false,
        },
        {
          key: "weekly",
          title: "Weekly",
          icon: <StarTwoTone />,
          enable: true,
        },
      ],
    },
    integrations: {
      title: "Integrations",
      subItems: [
        {
          key: "slack",
          title: "Slack",
          icon: <SlackOutlined />,
          enable: base.integrations?.slack,
        },

        {
          key: "discord",
          title: "Discord",
          icon: <Icon component={DiscordIcon}></Icon>,
          enable: true,
        },
      ],
    },
    groups: {
      title: "Groups",
      subItems: [
        {
          key: "createGroup",
          title: "Create Group",
          icon: <PlusOutlined />,
          enable: true,
        },
      ],
    },
  };

  const changeSection = (key: string) => {
    if (checkIfSettingsHaveChanges) {
      baseNavigationSubject.next(true);
      return;
    }
    setActiveSelection(key);
  };

  const selectGroup = (key: string) => {
    if (checkIfSettingsHaveChanges) {
      baseNavigationSubject.next(true);
      return;
    }
    setSelectedGroup(key);
    setActiveSelection("editGroup");
  };

  const getNaviItem = (data: any) => {
    return data.subItems.map((item: SubItem, i: number) => {
      return (
        item.enable && (
          <SidebarItemAction
            key={i}
            onClick={() => changeSection(item.key)}
            icon={item.icon}
            isActive={item.key === activeSelection}
            label={item.title}
          />
        )
      );
    });
  };

  const getDynamicGroups = () => {
    return activeGroups.map((item: string, i: number) => {
      return (
        <SidebarItemAction
          key={i}
          onClick={() => selectGroup(item)}
          icon={<Icon component={GroupsIcon}></Icon>}
          isActive={item === selectedGroup}
          label={groupDict[item].name}
        />
      );
    });
  };

  useEffect(() => {
    if (activeSelection !== "editGroup") {
      setSelectedGroup("");
    }
  }, [activeSelection]);
  const onArchive = () => {
    setActiveSelection("details");
  };
  const settingsObj = useSettingsOpened();

  useEffect(() => {
    if (settingsObj?.isOpen) {
      setSelectedGroup(settingsObj?.selectedGroup);
      setActiveSelection(settingsObj?.selectedSection);
    }
  }, [settingsObj]);

  if (!user) return <></>;

  const getWrapper = () => {
    switch (activeSelection) {
      case "details":
        return (
          <div className="base-settings--inner-container">
            <Options
              base={base}
              user={user}
              workspaces={workspaces}
              setActiveBase={setActiveBase}
              setAuthenticatedUser={setAuthenticatedUser}
              userSettings={userBaseSettings}
            />
          </div>
        );
      case "cycles":
        return (
          <div className="base-settings--inner-container">
            <OptionsCycles
              base={base}
              user={user}
              setAuthenticatedUser={setAuthenticatedUser}
              userSettings={userBaseSettings}
            />
          </div>
        );
      case "weekly":
        return (
          <div className="base-settings--inner-container">
            <OptionsWeekly
              base={base}
              user={user}
              setAuthenticatedUser={setAuthenticatedUser}
              userSettings={userBaseSettings}
            />
          </div>
        );
      case "home-screen":
        return (
          <div className="base-settings--inner-container">
            {/* <OptionsHome
              base={base}
              user={user}
              setAuthenticatedUser={setAuthenticatedUser}
              userSettings={userBaseSettings}
            /> */}
          </div>
        );
      case "role-gallery":
        return (
          <div className="base-settings--inner-container">
            <OptionsMilestones
              base={base}
              user={user}
              setAuthenticatedUser={setAuthenticatedUser}
              userSettings={userBaseSettings}
            />
          </div>
        );
      case "milestones":
        return (
          <div className="base-settings--inner-container">
            <OptionsMilestones
              base={base}
              user={user}
              setAuthenticatedUser={setAuthenticatedUser}
              userSettings={userBaseSettings}
            />
          </div>
        );
      // case "initiatives":
      //   return (
      //     <div className="base-settings--inner-container">
      //       <OptionsInitiatives
      //         base={base}
      //         user={user}
      //         setAuthenticatedUser={setAuthenticatedUser}
      //         userSettings={userBaseSettings}
      //       />
      //     </div>
      //   );
      case "rewards":
        return (
          <div className="base-settings--inner-container">
            <OptionsRewards
              base={base}
              user={user}
              setAuthenticatedUser={setAuthenticatedUser}
              userSettings={userBaseSettings}
            />
          </div>
        );
      // case "join-page":
      //   return (
      //     <div className="base-settings--inner-container">
      //       <JoinableBase />
      //     </div>
      //   );
      case "tokens":
        return (
          <div className="base-settings--inner-container">
            <Tokens />
          </div>
        );
      case "notifications":
        return (
          <div className="base-settings--inner-container">
            <Notifications base={base} userSettings={userBaseSettings} />
          </div>
        );
      case "roles":
        return <Roles />;
      case "groups":
        return <Groups />;
      case "invite-links":
      case "token-gates":
      case "members":
        return (
          <Members
            base={base}
            user={user}
            active={activeSelection}
            setAuthenticatedUser={setAuthenticatedUser}
          />
        );
      case "work-statuses":
        return (
          <div className="base-settings--inner-container">
            <WorkSettings base={base} />
          </div>
        );
      case "slack":
        return (
          <div className="base-settings--inner-container">
            {" "}
            <Integrations base={base} />{" "}
          </div>
        );
      case "discord":
        return (
          <div className="base-settings--inner-container">
            <DiscordIntegration base={base} />{" "}
          </div>
        );
      case "plans-billing":
        return (
          <div className="base-settings--fullwidth-container">
            <TabLayout
              title="Plans & Billing"
              description="Manage your team’s plan and billing details"
            >
              <MonthlyCount />
            </TabLayout>
            <PricingPlans base={base} user={user} activeKey={activeKey} />
          </div>
        );
      case "createGroup":
        return (
          <div className="base-settings--inner-container">
            <CreateGroup />
          </div>
        );
      case "editGroup":
        return (
          <div className="base-settings--inner-container">
            <EditGroup group={groupDict[selectedGroup]} onArchive={onArchive} />
          </div>
        );
      default:
        return (
          <div className="base-settings--inner-container">
            <Options
              base={base}
              user={user}
              workspaces={workspaces}
              setActiveBase={setActiveBase}
              setAuthenticatedUser={setAuthenticatedUser}
              userSettings={userBaseSettings}
            />
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Settings & Members • ${base.name}`}</title>
      </Helmet>

      <div className="profile base-settings">
        <div className="base-settings--wrapper">
          <div className="base-settings--sidebar">
            <SidebarWrapper withHeader>
              <SidebarGroup>
                <div className="base-settings--sidebar--group-title">
                  {sideBarItems.preferences.title}
                </div>
                {getNaviItem(sideBarItems.preferences)}
              </SidebarGroup>
              <SidebarGroup>
                <div className="base-settings--sidebar--group-title">
                  {sideBarItems.baseSettings.title}
                </div>
                {getNaviItem(sideBarItems.baseSettings)}
              </SidebarGroup>

              <SidebarGroup>
                <div className="base-settings--sidebar--group-title">
                  {sideBarItems.corePlugIns.title}
                </div>
                {getNaviItem(sideBarItems.corePlugIns)}
              </SidebarGroup>

              <SidebarGroup>
                <div className="base-settings--sidebar--group-title">
                  {sideBarItems.integrations.title}
                </div>
                {getNaviItem(sideBarItems.integrations)}
              </SidebarGroup>

              <SidebarGroup>
                <div className="base-settings--sidebar--group-title">
                  {sideBarItems.groups.title}
                </div>
                {getDynamicGroups()}
                {getNaviItem(sideBarItems.groups)}
              </SidebarGroup>
            </SidebarWrapper>
          </div>
          <div className="base-settings--container">{getWrapper()}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ClarityStore) => {
  return {
    base: state.workspace,
    user: state.user,
    workspaces: state.user ? state.user.workspaces : [],
    userBaseSettings: state.userBaseSettings,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setActiveBase: (base: IWorkspaceObj) =>
    dispatch({ type: actionTypes.SET_ACTIVE_WORKSPACE, workspace: base }),
  setAuthenticatedUser: (user: IUserObj) =>
    dispatch({ type: actionTypes.SET_AUTHENTICATED_USER, user }),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getActiveKey(query: URLSearchParams): string {
  const tabNameToKey = {
    options: "1",
    members: "2",
    integrations: "3",
    workStatuses: "4",
    plans: "5",
  };
  type TNTKKey = keyof typeof tabNameToKey;
  const tab = query.get("tab");
  return tabNameToKey[tab as unknown as TNTKKey] ?? "1";
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
