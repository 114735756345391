import React, { useEffect, useState } from "react";
import { useShallowSelector } from "utilities/hooks";
import { IReward } from "utilities/types";
import ClaritySelect, { IOptions } from "./ClaritySelect";

const SponsorGroupDisplay: React.FC<{
  reward: IReward;
  setSponsorGroupId: (groupId: string) => void;
  disabled: boolean;
}> = ({ reward, setSponsorGroupId, disabled }) => {
  const groups = useShallowSelector((store) => store.groups.dict);
  const userGroups = useShallowSelector((store) => store.groups.userGroups);
  const [options, setOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    if (userGroups) {
      const toSet: IOptions[] = userGroups.map((groupId) => ({
        value: groups[groupId].id,
        label: groups[groupId].name,
      }));
      setOptions(toSet);
    }
  }, [userGroups]);

  return (
    <ClaritySelect
      options={options}
      label={"Sponsor"}
      placeholder="Select the group which will sponsor this reward"
      defaultValue={reward?.sponsorGroupId}
      onChange={setSponsorGroupId}
      value={reward?.sponsorGroupId}
      disabled={disabled}
    />
  );
};

export default SponsorGroupDisplay;
