import StatusDisplay from "components/StatusDisplay";
import React, { useEffect, useMemo, useRef, useState } from "react";
import sectionStyles from "./workSection.module.scss";
import styles from "./workItem/workItem.module.scss";
import Button, { ButtonTypes } from "components/Button";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { ReactComponent as SimpleCheckWhite } from "icons/check-simple-white.svg";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
import {
  CLEAR_JUST_CLOSED_WORK_ITEMS,
  DELETE_SECTION,
  PATCH_SECTION,
} from "store/actions";
import store, { ClarityStore } from "store/storeExporter";
import UserDisplay from "clarity-ui/UserDisplay";
import CyclePresence from "clarity-ui/CyclePresence";
import PriorityDisplay from "components/PriorityDisplay";
import ProjectDisplay from "clarity-ui/ProjectDisplay";
import {
  Abilities,
  GroupTypes,
  TasksViewModes,
  UserRole,
} from "utilities/types";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { socket } from "App";
import Modal from "antd/lib/modal";
import { Dropdown, Menu } from "antd";
import {
  getNextRank,
  getPreviousRank,
  getRankBetween,
} from "utilities/containerRankHelpers";
import { useOptionalClassName } from "utilities/hooks";
import { useAbilityChecker } from "editor/utils/customHooks";
import { IGroupEmptyEntry, IGroupHeader } from "../../defaultWorkView/WorkView";
import { axiosInstance } from "index";
import {
  INewTaskCreationModes,
  openNewTaskModal,
} from "store/reducers/clientReducer";

const { confirm } = Modal;

const GroupHeader: React.FC<IGroupHeader & { mode?: TasksViewModes }> = (
  props
) => {
  const { containerClassName } = useGroupHeaderState(props);

  const prefix = useMemo(() => {
    switch (props.type) {
      case GroupTypes.status:
        return (
          <StatusDisplay
            showName={true}
            statusId={props.groupId}
            size={"large"}
            heavyCaption={true}
          />
        );
      case GroupTypes.assignee: {
        if (!props.groupId || props.groupId === "null")
          return (
            <>
              <UserOutlined
                size={24}
                style={{ marginRight: "5px", color: "rgba(0,0,0,0.2)" }}
              />
              <span style={{ marginLeft: "12px" }}>No assignee</span>
            </>
          );
        return <UserDisplay id={props.groupId} />;
      }
      case GroupTypes.cycle: {
        if (!props.groupId || props.groupId === "null") return <>No Sprint</>;
        return <CycleHead cycleId={props.groupId} mode={props.mode} />;
      }
      case GroupTypes.priority: {
        return (
          <PriorityDisplay
            priorityLevel={Number(props.groupId)}
            showName={true}
            size="small"
          />
        );
      }
      case GroupTypes.project: {
        if (!props.groupId || props.groupId === "null") return <>No Project</>;
        return <ProjectDisplay id={props.groupId} />;
      }
      case GroupTypes.weekClosed:
      case GroupTypes.weekCreated:
      case GroupTypes.weekDue: {
        if (props.groupId === "Invalid date") return "None";
        return `Week of ${moment(props.groupId).format("MMMM D, YYYY")}`;
      }
      default:
        return <></>;
    }
  }, [props.type, props.groupId]);

  const userRole = useSelector(
    (state: ClarityStore) => state.client.roleType,
    shallowEqual
  );

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember:
      props.mode !== TasksViewModes.CustomView &&
      props.mode !== TasksViewModes.NewCustomView
        ? props.baseGroupId
        : undefined,
  });

  return (
    <div className={containerClassName}>
      <div className={styles.groupHeaderPrefixContainer}>{prefix}</div>
      <span className={styles.groupHeaderCount}>{props.count}</span>
      <div
        className={
          styles.groupHeader_tail +
          " " +
          (props.type === GroupTypes.cycle && sectionStyles.tail)
        }
      >
        {props.type === GroupTypes.cycle &&
          props.groupId &&
          props.groupId !== "null" && (
            <CycleTail cycleId={props.groupId} mode={props.mode} />
          )}
        {props.presetTaskMetadata && (
          <Button
            icon={<PlusOutlined />}
            key={"New"}
            disabled={userRole === UserRole.GUEST || !canEditEntity}
            buttonType={ButtonTypes.LINK}
            onClick={() => {
              if (!canEditEntity) return;
              openNewTaskModal({
                type: INewTaskCreationModes.new,
                presetData: props.presetTaskMetadata ?? undefined,
              });
            }}
            style={{ marginLeft: "8px" }}
          />
        )}
      </div>
    </div>
  );
};

function useGroupHeaderState({ type }: Pick<IGroupHeader, "type">) {
  const containerClassName = useOptionalClassName({
    baseStyle: styles.groupHeaderContainer,
    pairs: [
      {
        extraStyle: sectionStyles.header,
        withExtra: type === GroupTypes.cycle,
      },
    ],
  });

  return {
    containerClassName,
  };
}

export const EmptyGroupElement: React.FC<IGroupEmptyEntry> = (props) => {
  return (
    <div className={styles.groupHeader + " " + styles.emptyRow}>
      <>Nothing to show</>
    </div>
  );
};

const CycleHead: React.FC<{ cycleId: string; mode?: TasksViewModes }> = ({
  cycleId,
  mode,
}) => {
  const stateData = useSelector((state: ClarityStore) => {
    const cycle = state.work.sections[cycleId];
    return {
      openCycles: cycle
        ? state.work.groupCycles[cycle.id]?.openTimeframeIds
        : [],
      closedCycles: cycle
        ? state.work.groupCycles[cycle.id]?.closedTimeframeIds
        : [],
      workDict: state.work.dict,
      cycle,
    };
  }, shallowEqual);

  const InputRenameSection = useRef<any>();
  const [renameSectionName, setRenameSectionName] = useState<string>(
    (stateData.cycle && stateData.cycle.name) || ""
  );

  useEffect(() => {
    if (stateData.cycle?.isBeingRenamed) {
      InputRenameSection.current.focus();
    }
  }, [stateData.cycle?.isBeingRenamed]);

  if (cycleId === "null" || !stateData.cycle) return <></>;

  const handleRenameSectionNameChange = (event: any) => {
    const { value } = event.target;
    setRenameSectionName(value);
  };

  const handleRenameSectionSubmit = (event: any) => {
    event.preventDefault();
    const name = renameSectionName;

    if (name.trim().length < 1) return;

    axiosInstance
      .patch("/api/workSection", {
        sectionId: cycleId,
        patch: [{ op: "replace", path: "/name", value: name }],
        clientId: socket.id,
      })
      .then(() => {});

    store.dispatch({
      type: PATCH_SECTION,
      sectionId: cycleId,
      patchSection: {
        id: cycleId,
        patch: { isBeingRenamed: false, name },
        clientId: socket.id,
      },
    });
  };

  return (
    <>
      {stateData.cycle.isBeingRenamed ? (
        <form onSubmit={(event: any) => handleRenameSectionSubmit(event)}>
          <div className={sectionStyles.section_header_head}>
            <input
              type="text"
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="Enter a section name"
              className={`body bold ${sectionStyles.placeholderInput}`}
              value={renameSectionName}
              onChange={(event: any) => handleRenameSectionNameChange(event)}
              ref={InputRenameSection}
            />
          </div>
          <div className={sectionStyles.section_header_tail}>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              type="submit"
              disabled={renameSectionName.trim().length < 1}
            >
              Save
            </Button>
          </div>
        </form>
      ) : (
        <b
          className={
            stateData.cycle.isClosed && mode === TasksViewModes.OpenCycles
              ? sectionStyles.closed_name
              : ""
          }
        >
          <CyclePresence id={cycleId} showName={true} />
        </b>
      )}

      {stateData.openCycles && stateData.openCycles[0] === cycleId && (
        <span className="caption secondary" style={{ marginLeft: "9px" }}>
          (Active)
        </span>
      )}
      {stateData.openCycles && stateData.openCycles[1] === cycleId && (
        <span className="caption secondary" style={{ marginLeft: "9px" }}>
          (Next)
        </span>
      )}
    </>
  );
};

const CycleTail: React.FC<{ cycleId: string; mode?: TasksViewModes }> = ({
  cycleId,
  mode,
}) => {
  const stateData = useSelector((state: ClarityStore) => {
    const cycle = state.work.sections[cycleId];
    return {
      openCycles: cycle
        ? state.work.groupCycles[cycle.id]?.openTimeframeIds
        : [],
      closedCycles: cycle
        ? state.work.groupCycles[cycle.id]?.closedTimeframeIds
        : [],
      workDict: state.work.dict,
      cyclesObjects: state.work.sections,
      cycle,
    };
  }, shallowEqual);

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_CYCLES,
  });

  if (cycleId === "null" || !stateData.cycle) return <></>;

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "rename":
        store.dispatch({
          type: PATCH_SECTION,
          sectionId: cycleId,
          patchSection: {
            id: cycleId,
            patch: { isBeingRenamed: true },
            clientId: socket.id,
          },
        });
        break;
      case "delete":
        axiosInstance
          .delete(`/api/workSection`, {
            data: {
              sectionId: cycleId,
              clientId: socket.id,
            },
          })
          .then(() => {
            store.dispatch({ type: DELETE_SECTION, sectionId: cycleId });
          });
        break;
      case "up":
        const index = stateData.openCycles.findIndex(
          (cycle) => cycle === cycleId
        );

        const destination: any = {
          index: index - 1,
        };
        let newRank;
        if (destination.index === 0) {
          // if destination is first in the list, get prev rank to first element
          const currentFirstRank =
            stateData.cyclesObjects[stateData.openCycles[0]].rank;
          if (currentFirstRank) {
            newRank = getPreviousRank(currentFirstRank);
          }
        } else {
          // get rank between two elements
          const rankBefore =
            stateData.cyclesObjects[stateData.openCycles[destination.index - 1]]
              .rank;
          const rankAfter =
            stateData.cyclesObjects[stateData.openCycles[destination.index]]
              .rank;
          if (rankBefore && rankAfter) {
            newRank = getRankBetween(rankBefore, rankAfter);
          }
        }

        store.dispatch({
          type: PATCH_SECTION,
          patchSection: {
            id: cycleId,
            patch: { rank: newRank },
            preset: true,
          },
        });

        // send request to update on server
        axiosInstance
          .patch("/api/workSection", {
            sectionId: cycleId,
            patch: [{ op: "replace", path: "/rank", value: newRank }],
            clientId: socket.id,
          })
          .then((res) => {
            // no response expected
          });
        break;
      case "down":
        const indexDown = stateData.openCycles.findIndex(
          (cycle) => cycle === cycleId
        );
        const destinationDown: any = {
          index: indexDown + 1,
        };
        let newRank2;
        if (destinationDown.index === stateData.openCycles.length - 1) {
          // if destination is last in the list, get next rank to last element
          // (don't need +1 here bc the moved project is the last element now)
          const currentLastRank =
            stateData.cyclesObjects[
              stateData.openCycles[stateData.openCycles.length - 1]
            ].rank;
          if (currentLastRank) {
            newRank2 = getNextRank(currentLastRank);
          }
        } else {
          // get rank between two elements
          const rankBefore =
            stateData.cyclesObjects[
              stateData.openCycles[destinationDown.index + 1]
            ].rank;
          const rankAfter =
            stateData.cyclesObjects[stateData.openCycles[destinationDown.index]]
              .rank;
          if (rankBefore && rankAfter) {
            newRank2 = getRankBetween(rankAfter, rankBefore);
          }
        }

        store.dispatch({
          type: PATCH_SECTION,
          patchSection: {
            id: cycleId,
            patch: { rank: newRank2 },
            preset: true,
          },
        });

        // send request to update on server
        axiosInstance
          .patch("/api/workSection", {
            sectionId: cycleId,
            patch: [{ op: "replace", path: "/rank", value: newRank2 }],
            clientId: socket.id,
          })
          .then((res) => {
            // no response expected
          });
        break;
    }
  };

  const cannotCloseTimeframeAlert = () => {
    return confirm({
      title: "This cycle has open items",
      icon: <></>,
      content: "Please close or remove them before closing the cycle",
      maskClosable: true,
    });
  };

  const handleIsClosedChange = () => {
    const openItems = stateData.cycle.workIds.filter(
      (id: string) => !stateData.workDict[id].isClosed
    );

    if (openItems.length > 0 && !stateData.cycle.isClosed)
      return cannotCloseTimeframeAlert();

    axiosInstance
      .patch("/api/workSection", {
        sectionId: cycleId,
        patch: [
          {
            op: "replace",
            path: "/isClosed",
            value: !stateData.cycle.isClosed,
          },
        ],
        clientId: socket.id,
      })
      .then(() => {});

    store.dispatch({
      type: PATCH_SECTION,
      patchSection: {
        id: cycleId,
        patch: { isClosed: !stateData.cycle.isClosed },
        clientId: socket.id,
      },
    });
    if (mode === TasksViewModes.ClosedCycles) {
      store.dispatch({ type: CLEAR_JUST_CLOSED_WORK_ITEMS });
    }
  };

  const handleReopen = () => {
    axiosInstance
      .patch("/api/workSection", {
        sectionId: cycleId,
        patch: [{ op: "replace", path: "/isClosed", value: false }],
        clientId: socket.id,
      })
      .then(() => {});
    store.dispatch({
      type: PATCH_SECTION,
      patchSection: {
        id: cycleId,
        patch: { isClosed: false, isReopen: true },
        clientId: socket.id,
      },
    });
    if (mode === TasksViewModes.ClosedCycles) {
      store.dispatch({ type: CLEAR_JUST_CLOSED_WORK_ITEMS });
    }
  };

  return (
    <>
      <Dropdown
        overlay={
          <Menu
            disabled={!canEditEntity}
            onClick={canEditEntity ? handleMenuClick : () => {}}
          >
            <Menu.Item key="rename" disabled={stateData.cycle?.isBeingRenamed}>
              Rename...
            </Menu.Item>
            <Menu.Item
              key="delete"
              disabled={stateData.cycle?.workIds.length > 0}
            >
              Delete...
            </Menu.Item>
            {mode === TasksViewModes.OpenCycles && (
              <Menu.Item
                key="up"
                disabled={
                  stateData.openCycles.length > 0 &&
                  stateData.openCycles[0] === cycleId
                }
              >
                Move up
              </Menu.Item>
            )}
            {mode === TasksViewModes.OpenCycles && (
              <Menu.Item
                key="down"
                disabled={
                  stateData.openCycles.length > 0 &&
                  stateData.openCycles[stateData.openCycles.length - 1] ===
                    cycleId
                }
              >
                Move down
              </Menu.Item>
            )}
          </Menu>
        }
        trigger={["click"]}
        forceRender={true}
      >
        <Button
          buttonType={ButtonTypes.LINK}
          icon={<MoreHorizontalDots alt="more options menu" />}
          disabled={!canEditEntity}
          className={sectionStyles.showOnHover}
        />
      </Dropdown>
      {mode === TasksViewModes.OpenCycles && (
        <>
          <Button
            buttonType={
              stateData.cycle.isClosed ? ButtonTypes.PRIMARY : ButtonTypes.LINK
            }
            className={
              !stateData.cycle.isClosed
                ? sectionStyles.showOnHover
                : sectionStyles.completedButton
            }
            disabled={!canEditEntity}
            onClick={canEditEntity ? handleIsClosedChange : () => {}}
            style={{ marginLeft: "8px" }}
          >
            {stateData.cycle.isClosed ? (
              <>
                <SimpleCheckWhite
                  style={{ marginLeft: "-5px", marginRight: "4px" }}
                />{" "}
                Closed
              </>
            ) : (
              "Mark complete"
            )}
          </Button>
        </>
      )}
      {mode === TasksViewModes.ClosedCycles && (
        <>
          <Button
            buttonType={ButtonTypes.LINK}
            className={sectionStyles.showOnHover}
            disabled={!canEditEntity}
            onClick={canEditEntity ? handleReopen : () => {}}
            style={{ marginLeft: "8px" }}
          >
            Re-open
          </Button>
        </>
      )}
    </>
  );
};

export default GroupHeader;
