import React, { useEffect, useState } from "react";
import { Alert, Form } from "antd";
import { WarningOutlined, InfoCircleOutlined } from "@ant-design/icons";

import {
  Abilities,
  BaseType,
  IUserObj,
  IWorkspaceObj,
  UserBaseSettings,
  UserRole,
} from "utilities/types";
import TabLayout from "./TabLayout";
import styles from "./options/options.module.scss";
import store from "store/storeExporter";
import { UPDATE_WORKSPACE } from "store/actions";

import BaseAvatarUpload from "../../../../components/BaseAvatarUpload";
import ClarityInput from "components/ClarityInput";
import ClarityTextArea from "components/ClarityTextArea";
import { useAbilityChecker } from "editor/utils/customHooks";
import notificationsApi from "clientApi/notificationsApi";
import DangerZone, { DangerSections } from "./DangerZone";
import FooterForSave from "./FooterForSave";
import { useSetBaseSettingHasChanges } from "store/reducers/clientReducer";
import LeaveBase from "components/LeaveBase";
import ChangeBaseDomain from "components/ChangeBaseDomain";
import DeleteBase from "components/DeleteBase";
import { axiosInstance } from "index";
import BaseVisibilitySelector from "./options/BaseVisibilitySelector";
import ConfirmModal from "clarity-ui/ConfirmModal";
import Conditional from "components/Conditional";

interface Props {
  base: IWorkspaceObj;
  user: IUserObj;
  workspaces: IWorkspaceObj[];
  userSettings: UserBaseSettings;
  setActiveBase: (base: IWorkspaceObj) => {
    type: string;
    workspace: IWorkspaceObj;
  };
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

interface Status {
  message: string;
  type: "error" | "success";
}

const Options = ({
  base,
  workspaces,
  userSettings,
  setActiveBase,
  setAuthenticatedUser,
}: Props) => {
  const [baseSettings, setBaseSettings] = useState<IWorkspaceObj>({ ...base });
  const [status, setStatus] = useState<Status>({ message: "", type: "error" });
  const [saveChnagesEnabled, setSaveChnagesEnabled] = useState(false);
  const [dangerzoneArray, setDangerzoneArray] = useState<DangerSections>([]);
  const [showConfirmation, setshowConfirmation] = useState(false);
  useSetBaseSettingHasChanges(saveChnagesEnabled, [saveChnagesEnabled]);
  const canRenameBase = useAbilityChecker({
    abilityName: Abilities.CAN_RENAME_BASE,
  });

  const canDeleteBase = useAbilityChecker({
    abilityName: Abilities.CAN_DELETE_BASE,
  });

  const canManageBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });

  const userRole = store.getState().client.roleType;
  const isOwner = userRole === UserRole.OWNER;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      "email-notification": userSettings?.settings?.emailNotifications,
    });
  }, [userSettings]);

  useEffect(() => {
    setBaseSettings({ ...base });
    form.setFieldsValue({
      name: base.name,
    });
    dangerZoneSetup();
  }, [base]);

  const dangerZoneSetup = () => {
    const DangerzoneArray: DangerSections = [];
    if (!isOwner) {
      const obj = {
        heading: "Leave base",
        description: (
          <>
            <span className="secondary">
              If you leave the base you need to be invited again to join it
            </span>
          </>
        ),
        component: <LeaveBase />,
      };
      DangerzoneArray.push(obj);
    }
    if (canDeleteBase) {
      const obj = [
        {
          heading: "Change base domain",
          description: (
            <>
              <span className="secondary">
                The domain of this base is currently
              </span>
              <span className="bold"> app.clarity.so/{base.slug}</span>
            </>
          ),
          component: <ChangeBaseDomain />,
        },
        {
          heading: "Delete this base",
          description: (
            <>
              <span className="secondary">
                This will delete all the documents and information in this base
                for everyone. Once you do this, there is no going back.
              </span>
            </>
          ),
          component: <DeleteBase />,
        },
      ];
      DangerzoneArray.push(...obj);
    }
    setDangerzoneArray(DangerzoneArray);
  };

  const isBaseNameBlank = baseSettings.name.length < 1;
  const isBaseNameLong = baseSettings.name.length > 39;
  let isTaglineValid = true;
  if (baseSettings.tagline) isTaglineValid = baseSettings.tagline.length < 201;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value, name } = e.currentTarget;
    setStatus({ message: "", type: "error" });
    setBaseSettings({ ...baseSettings, [name]: value.trim() });
    enableDisFooter();
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setStatus({ message: "", type: "error" });
    const updatedBaseSettings = { ...baseSettings, tagline: value };
    setBaseSettings(updatedBaseSettings);
    enableDisFooter(updatedBaseSettings);
  };

  const handleVisibilityChange = (type: BaseType) => {
    const updatedBaseSettings = { ...baseSettings, type };
    setBaseSettings(updatedBaseSettings);
    enableDisFooter(updatedBaseSettings);
  };

  const enableDisFooter = (updatedBaseSettings = baseSettings) => {
    if (!canManageBaseSettings) return setSaveChnagesEnabled(false);
    if (
      updatedBaseSettings.name !== base.name ||
      updatedBaseSettings.tagline !== base.tagline ||
      updatedBaseSettings.type !== base.type ||
      updatedBaseSettings.tokenGateId !== base.tokenGateId
    ) {
      setSaveChnagesEnabled(true);
    } else {
      setSaveChnagesEnabled(false);
    }
  };

  const handleReset = () => {
    setSaveChnagesEnabled(false);
    setBaseSettings(base);
    form.setFieldsValue({
      name: base.name,
    });
  };

  const checkForConfirmationAndSave = () => {
    if (baseSettings.type !== base.type) {
      setshowConfirmation(true);
    } else handleSaveChanges();
  };

  const handleSaveChanges = async () => {
    if (isBaseNameBlank) {
      notificationsApi.displayError({
        title: "Error",
        body: "Name cannot be blank",
      });
      return;
    }
    try {
      const { data } = await axiosInstance.patch(`/api/workspace/profile`, {
        baseId: baseSettings.id,
        patch: [
          {
            op: "replace",
            path: "/name",
            value: baseSettings.name,
          },
          {
            op: "replace",
            path: "/tagline",
            value: baseSettings.tagline || "",
          },
          {
            op: "replace",
            path: "/type",
            value: baseSettings.type,
          },
          {
            op: "replace",
            path: "/tokenGateId",
            value:
              baseSettings.type === BaseType.Private
                ? baseSettings.tokenGateId
                : null,
          },
        ],
      });
      if (data.status === 1) {
        setSaveChnagesEnabled(false);
        notificationsApi.displayConfirmation({
          title: "Success",
          body: "Details updated successfully",
        });
        store.dispatch({
          type: UPDATE_WORKSPACE,
          params: {
            delta: baseSettings,
          },
        });
        const { data } = await axiosInstance.get("/api/user");
        setAuthenticatedUser(data.payload);
      } else {
        setStatus({
          type: "error",
          message:
            "Sorry, we could not save your changes. Please try again later",
        });
      }
    } catch (e: any) {
      let message =
        "Sorry, we could not save your changes. Please try again later";
      if (e?.response?.data?.message.includes("Slug")) {
        message =
          "Sorry, we could not save your changes. The domain name is not unique";
      }
      setStatus({
        type: "error",
        message: message,
      });
    }
  };

  const onGateSelect = (id: string) => {
    const updatedBaseSettings = { ...baseSettings, tokenGateId: id };
    setBaseSettings(updatedBaseSettings);
    enableDisFooter(updatedBaseSettings);
  };

  const onGateRemove = () => {
    const updatedBaseSettings = { ...baseSettings, tokenGateId: undefined };
    setBaseSettings(updatedBaseSettings);
    enableDisFooter(updatedBaseSettings);
  };

  return (
    <>
      {showConfirmation && (
        <ChangeVisibilityConfirmation
          handleSaveChanges={handleSaveChanges}
          setshowConfirmation={setshowConfirmation}
          prevVisibility={base.type}
          nextVisibility={baseSettings.type}
        />
      )}
      <TabLayout title="Details" description="General details about your base">
        <Form form={form}>
          <div className={styles.options}>
            <div className={styles.options_avatar}>
              <BaseAvatarUpload disabled={!canManageBaseSettings} />
            </div>
            {/* <div className={styles.options_avatar} style={{ marginBottom: 32 }}>
              <BaseCoverPhotoUpload />
            </div> */}
            <div className={styles.options_inputs}>
              <Form.Item name="name" style={{ marginBottom: 32 }}>
                <ClarityInput
                  name="name"
                  onChange={handleInputChange}
                  label="Name"
                  disabled={!canRenameBase}
                  required
                />
              </Form.Item>
              {isBaseNameLong && (
                <Alert
                  type="error"
                  message="Name cannot be longer than 39 characters"
                  style={{ marginBottom: 16, marginTop: -16 }}
                />
              )}
            </div>
            <div>
              <Form.Item
                name="tagline"
                initialValue={baseSettings.tagline || ""}
              >
                <ClarityTextArea
                  value={baseSettings.tagline || ""}
                  className=""
                  label="Tagline"
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={handleTextAreaChange}
                  maxLength={200}
                  disabled={!canRenameBase}
                  rows={2}
                />
                <div>
                  <span className={`caption secondary ${styles.options_hint}`}>
                    <InfoCircleOutlined /> This tagline will be displayed on the
                    Home Screen and Join Page of this base
                  </span>
                </div>
              </Form.Item>
              {!isTaglineValid && (
                <Alert
                  type="error"
                  message="Tagline cannot be longer than 200 characters"
                  style={{ marginBottom: 16, marginTop: -16 }}
                />
              )}
            </div>
            {status.type === "error" && !!status.message && (
              <Alert
                type="error"
                message={
                  <div>
                    <WarningOutlined /> {status.message}
                  </div>
                }
                style={{
                  marginBottom: 28,
                  backgroundColor: " #fcdbdc",
                  borderRadius: "4px",
                  border: "solid 1px #dbabac",
                  color: "#723b3e",
                }}
              />
            )}
            {status.type === "success" && !!status.message && (
              <Alert
                type="success"
                message={status.message}
                style={{ marginBottom: 28 }}
              />
            )}
            {saveChnagesEnabled && !!canManageBaseSettings && (
              <FooterForSave
                onSave={checkForConfirmationAndSave}
                onCancel={handleReset}
              />
            )}
          </div>
        </Form>
        <div style={{ height: "48px" }}></div>
        <DangerZone DangerSections={dangerzoneArray}>
          <BaseVisibilitySelector
            currentVisibility={baseSettings.type}
            handleVisibilityChange={handleVisibilityChange}
            onGateSelect={onGateSelect}
            onGateRemove={onGateRemove}
            tokenGateId={baseSettings.tokenGateId}
          />
        </DangerZone>
        <div style={{ height: "50px" }}></div>
      </TabLayout>
    </>
  );
};

const visibilityChangeConfirmText = "CHANGE VISIBILITY";

const typesOfChangeCopy = {
  fromPublic: {
    warning: "this is a potentially destructive action.",
    description: `By making this change the following screens will no longer be
    visible to non-members:`,
    containerVisibility: `If you want specific documents to be visible to non-members, you will need to turn on Public Access for each of those documents.`,
  },
  toPublic: {
    warning: "this action will make your entire base visible to the public.",
    description: `By making this change the following screens will be visible to
    anyone who views your base:`,
    containerVisibility: `Public Access will be turned on for every document in this base. Including all new documents created while the base is public.`,
  },
  withinPrivate: {
    warning: "",
    description: ``,
    containerVisibility: "",
  },
};

const ChangeVisibilityConfirmation: React.FC<{
  setshowConfirmation: (visibility: boolean) => void;
  handleSaveChanges: () => void;
  prevVisibility: BaseType;
  nextVisibility: BaseType;
}> = ({
  setshowConfirmation,
  handleSaveChanges,
  prevVisibility,
  nextVisibility,
}) => {
  const [text, settext] = useState("");
  const typeChange: "toPublic" | "fromPublic" | "withinPrivate" =
    prevVisibility === BaseType.Public
      ? "fromPublic"
      : nextVisibility === BaseType.Public
      ? "toPublic"
      : "withinPrivate";

  return (
    <ConfirmModal
      close={() => setshowConfirmation(false)}
      onConfirm={handleSaveChanges}
      confirmDisabled={
        typeChange !== "withinPrivate" && text !== visibilityChangeConfirmText
      }
      title="Are you sure you want to change the base visibility?"
      confirmationText="I understand, change base visibility"
    >
      <Conditional on={typeChange !== "withinPrivate"}>
        <div className="mb-8">
          <span className="bold">Warning: </span>
          <span>{typesOfChangeCopy[typeChange].warning}</span>
        </div>

        <div className="mb-8">
          <span>{typesOfChangeCopy[typeChange].description}</span>
          <ul>
            <li>Home</li>
            <li>Roadmap</li>
            <li>Tags</li>
            <li>Groups</li>
            <li>Roles</li>
            <li>Views</li>
            <li>Payouts</li>
            <li>Group Activity</li>
            <li>Group Tasks</li>
            <li>Group Projects</li>
            <li>Group Docs</li>
          </ul>
        </div>

        <div className="mb-8">
          <span>{typesOfChangeCopy[typeChange].containerVisibility}</span>
        </div>

        <div>
          <span>Please type CHANGE VISIBILITY to confirm</span>
          <ClarityInput
            placeholder="CHANGE VISIBILITY"
            onChange={(e) => settext(e.target.value)}
          />
        </div>
      </Conditional>
    </ConfirmModal>
  );
};

export default Options;
