import { Moment } from "moment";
import React from "react";
import { BehaviorSubject } from "rxjs";
import { ChunkDestination } from "utilities/stateTypes";
import {
  CommandPaletteContext,
  CommandPaletteContextSelectionsTypes,
  CommandPalettePropertiesContextTypes,
} from "utilities/types";
import {
  getAssigneeOptions,
  getContributorOptions,
  getFilterAssigneeNotOptions,
  getFilterAssigneeOptions,
  getFilterCycleNotOptions,
  getFilterCycleOptions,
  getFilterGroupNotOptions,
  getFilterGroupOptions,
  getFilterLabelNotOptions,
  getFilterLabelOptions,
  getFilterParentNotOptions,
  getFilterParentOptions,
  getFilterPriorityNotOptions,
  getFilterPriorityOptions,
  getFilterStatusNotOptions,
  getFilterStatusOptions,
  getDateFilterAction,
  getGeneralModeOptions,
  getGroupOptions,
  getMilestoneOptions,
  getMoveToOptions,
  getMultipleBlocksSelectedInDocumentOptions,
  getMultipleBlocksSelectedInProjectOptions,
  getMultipleProjectsSelectedOptions,
  getOpenModeOptions,
  getParentOptions,
  getPriorityOptions,
  getProjectDetailOptions,
  getReviewerOptions,
  getSponsorGroupOptions,
  getStatusOptions,
  getTurnIntoOptions,
  getWorkSectionOptions,
  getWorkViewOptions,
} from "./contextOptionGetters";

export type CommandPaletteOption = {
  id: string;
  name: string;
  disable?: () => boolean;
  action: (event: any) => void;
  closeAfter?: boolean;
  entity?: any;
  optionType:
    | "user"
    | "document"
    | "project"
    | "built-in"
    | "user-view"
    | "command"
    | "title";
  icon?: JSX.Element;
  link?: string;
  multiselect?: boolean;
  checked?: () => boolean;
};

export interface IPaletteState {
  options: BehaviorSubject<CommandPaletteOption[]>;
  filteredOptions: BehaviorSubject<CommandPaletteOption[]>;
  selectedItemIndex: BehaviorSubject<number>;
  selectedItemName: BehaviorSubject<string>;
  topBarText: BehaviorSubject<string>;
  searchText: BehaviorSubject<string>;
  showCount: BehaviorSubject<boolean>;
}

export const CommandPaletteState = React.createContext<IPaletteState>({
  options: new BehaviorSubject<CommandPaletteOption[]>([]),
  filteredOptions: new BehaviorSubject<CommandPaletteOption[]>([]),
  selectedItemIndex: new BehaviorSubject<number>(0),
  selectedItemName: new BehaviorSubject(""),
  topBarText: new BehaviorSubject(""),
  searchText: new BehaviorSubject(""),
  showCount: new BehaviorSubject<boolean>(false),
});

export const contextMatch = {
  [CommandPaletteContext.OPEN]: {
    topBarText: "Open",
    getOptions: getOpenModeOptions,
  },
  [CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_DOCUMENT]: {
    topBarText: "",
    getOptions: getMultipleBlocksSelectedInDocumentOptions,
  },
  [CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_PROJECT]: {
    topBarText: "",
    getOptions: getMultipleBlocksSelectedInProjectOptions,
  },
  [CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_COMMENT]: {
    topBarText: "",
    getOptions: getGeneralModeOptions,
  },
  [CommandPaletteContext.TURN_INTO]: {
    topBarText: "Turn Into",
    getOptions: getTurnIntoOptions,
  },
  [CommandPaletteContext.MOVE_TO]: {
    topBarText: "Move to",
    getOptions: getMoveToOptions,
  },
  [CommandPaletteContext.PROJECT_DETAIL]: {
    topBarText: "Modify project:",
    getOptions: getProjectDetailOptions,
  },
  [CommandPaletteContext.GENERAL]: {
    topBarText: "",
    getOptions: getGeneralModeOptions,
  },
  [CommandPaletteContext.WORK_SELECTED]: {
    topBarText: "",
    getOptions: () => getMultipleProjectsSelectedOptions,
  },
  [CommandPaletteContext.WORK_VIEW]: {
    topBarText: "",
    getOptions: () => getWorkViewOptions,
  },
  [CommandPaletteContext.FILTERS_GENERAL]: {
    topBarText: "Select filter by ",
    getOptions: () => [],
  },
};

export type CommandPaletteDateFilterContextTypes =
  | CommandPaletteContext.FILTER_DUE_BEFORE_DATE
  | CommandPaletteContext.FILTER_DUE_AFTER_DATE
  | CommandPaletteContext.FILTER_DUE_EXACTLY_DATE
  | CommandPaletteContext.FILTER_DUE_NOT_DATE
  | CommandPaletteContext.FILTER_CREATED_BEFORE_DATE
  | CommandPaletteContext.FILTER_CREATED_AFTER_DATE
  | CommandPaletteContext.FILTER_CREATED_EXACTLY_DATE
  | CommandPaletteContext.FILTER_CREATED_NOT_DATE
  | CommandPaletteContext.FILTER_CLOSED_BEFORE_DATE
  | CommandPaletteContext.FILTER_CLOSED_AFTER_DATE
  | CommandPaletteContext.FILTER_CLOSED_EXACTLY_DATE
  | CommandPaletteContext.FILTER_CLOSED_NOT_DATE;

type DateFilterCommandPaletteContextsGetter = {
  [key in CommandPaletteDateFilterContextTypes]: {
    topBarText: string;
    getValueAndSetter: (
      context: CommandPaletteDateFilterContextTypes,
      paneId: ChunkDestination
    ) => {
      defaultValue: Moment | undefined;
      onChange: (value: Moment | null, paneId: ChunkDestination) => void;
    };
  };
};

export const filtersDateContextMatch: DateFilterCommandPaletteContextsGetter = {
  [CommandPaletteContext.FILTER_DUE_AFTER_DATE]: {
    topBarText: "Due date after",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_DUE_BEFORE_DATE]: {
    topBarText: "Due date before",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_DUE_EXACTLY_DATE]: {
    topBarText: "Due date exactly",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_DUE_NOT_DATE]: {
    topBarText: "Due date not",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_CLOSED_AFTER_DATE]: {
    topBarText: "Closed date after",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_CLOSED_BEFORE_DATE]: {
    topBarText: "Closed date before",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_CLOSED_EXACTLY_DATE]: {
    topBarText: "Closed date exactly",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_CLOSED_NOT_DATE]: {
    topBarText: "Closed date not",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_CREATED_AFTER_DATE]: {
    topBarText: "Created date after",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_CREATED_BEFORE_DATE]: {
    topBarText: "Created date before",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_CREATED_EXACTLY_DATE]: {
    topBarText: "Created date exactly",
    getValueAndSetter: getDateFilterAction,
  },
  [CommandPaletteContext.FILTER_CREATED_NOT_DATE]: {
    topBarText: "Created date not",
    getValueAndSetter: getDateFilterAction,
  },
};

type FilterCommandPaletteContexts = {
  [key in CommandPaletteContextSelectionsTypes]: {
    topBarText: string;
    getOptions: (paneId: ChunkDestination) => CommandPaletteOption[];
  };
};

export const filterscontextMatch: FilterCommandPaletteContexts = {
  [CommandPaletteContext.FILTER_ASSIGNEE]: {
    topBarText: "Filter assignees",
    getOptions: getFilterAssigneeOptions,
  },
  [CommandPaletteContext.FILTER_ASSIGNEE_NOT]: {
    topBarText: "Filter assignees",
    getOptions: getFilterAssigneeNotOptions,
  },
  [CommandPaletteContext.FILTER_LABEL]: {
    topBarText: "Filter tags",
    getOptions: getFilterLabelOptions,
  },
  [CommandPaletteContext.FILTER_LABEL_NOT]: {
    topBarText: "Filter tags",
    getOptions: getFilterLabelNotOptions,
  },
  [CommandPaletteContext.FILTER_STATUS]: {
    topBarText: "Filter status",
    getOptions: getFilterStatusOptions,
  },
  [CommandPaletteContext.FILTER_STATUS_NOT]: {
    topBarText: "Filter status",
    getOptions: getFilterStatusNotOptions,
  },
  [CommandPaletteContext.FILTER_PRIORITY]: {
    topBarText: "Filter Priority",
    getOptions: getFilterPriorityOptions,
  },
  [CommandPaletteContext.FILTER_PRIORITY_NOT]: {
    topBarText: "Filter Priority",
    getOptions: getFilterPriorityNotOptions,
  },
  [CommandPaletteContext.FILTER_CYCLE]: {
    topBarText: "Filter Sprint",
    getOptions: getFilterCycleOptions,
  },
  [CommandPaletteContext.FILTER_CYCLE_NOT]: {
    topBarText: "Filter Sprint",
    getOptions: getFilterCycleNotOptions,
  },
  [CommandPaletteContext.FILTER_PARENT]: {
    topBarText: "Filter Parent",
    getOptions: getFilterParentOptions,
  },
  [CommandPaletteContext.FILTER_PARENT_NOT]: {
    topBarText: "Filter Parent",
    getOptions: getFilterParentNotOptions,
  },
  [CommandPaletteContext.FILTER_GROUP]: {
    topBarText: "Filter Group",
    getOptions: getFilterGroupOptions,
  },
  [CommandPaletteContext.FILTER_GROUP_NOT]: {
    topBarText: "Filter Group",
    getOptions: getFilterGroupNotOptions,
  },
  [CommandPaletteContext.HAS_REWARD]: {
    topBarText: "",
    getOptions: () => [],
  },
};

type PropertiesCommandPaletteContext = {
  [key in CommandPalettePropertiesContextTypes]: {
    topBarText: string;
    getOptions: () => CommandPaletteOption[];
    showCount?: boolean;
  };
};

export const propertiesContextMatch: PropertiesCommandPaletteContext = {
  [CommandPaletteContext.REVIEWER]: {
    topBarText: "Change reviewer to",
    getOptions: getReviewerOptions,
    showCount: true,
  },
  [CommandPaletteContext.ASSIGNEE]: {
    topBarText: "Assign to ",
    getOptions: getAssigneeOptions,
    showCount: true,
  },
  [CommandPaletteContext.PARENT]: {
    topBarText: "Change parent to ",
    getOptions: getParentOptions,
    showCount: true,
  },
  [CommandPaletteContext.WORK_SECTION]: {
    topBarText: "Change sprint to ",
    getOptions: getWorkSectionOptions,
    showCount: true,
  },
  [CommandPaletteContext.MILESTONE]: {
    topBarText: "Change milestone to ",
    getOptions: getMilestoneOptions,
    showCount: true,
  },
  [CommandPaletteContext.STATUS]: {
    topBarText: "Change Status ",
    getOptions: getStatusOptions,
    showCount: true,
  },
  [CommandPaletteContext.PRIORITY]: {
    topBarText: "Change Priority ",
    getOptions: getPriorityOptions,
    showCount: true,
  },
  [CommandPaletteContext.DUE_DATE]: {
    topBarText: "Set due date ",
    getOptions: () => [],
    showCount: true,
  },
  [CommandPaletteContext.SELECT_GROUP]: {
    topBarText: "Select task group ",
    getOptions: getGroupOptions,
    showCount: true,
  },
  [CommandPaletteContext.SELECT_MULTIPLE_GROUPS]: {
    topBarText: "Select project groups ",
    getOptions: getGroupOptions,
    showCount: true,
  },
  [CommandPaletteContext.SELECT_SPONSOR_GROUP]: {
    topBarText: "Select sponsor group ",
    getOptions: getSponsorGroupOptions,
    showCount: true,
  },
  [CommandPaletteContext.CONTRIBUTORS]: {
    topBarText: "Add contributor ",
    getOptions: getContributorOptions,
    showCount: true,
  },
};
