import { axiosInstance } from "index";
import { batch } from "react-redux";
import { SET_OPEN_BASE_VIEW } from "store/actions";
import store from "store/storeExporter";
import { ContainerTypes } from "utilities/types";
import { userApi } from "./userApi";

class ContainerApi {
  followContainer(params: {
    baseId: string;
    containerId: string;
    containerType: ContainerTypes;
  }) {
    axiosInstance
      .post("/api/container/follow", {
        baseId: params.baseId,
        containerId: params.containerId,
        containerType: params.containerType,
      })
      .then(() => {
        batch(() => {
          userApi.resetUserWithBaseSelected();
          store.dispatch({
            type: SET_OPEN_BASE_VIEW,
            param: {
              openBaseView: true,
            },
          });
        });
      });
  }
}

export const containerApi = new ContainerApi();
