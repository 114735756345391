import { CheckOutlined } from "@ant-design/icons";
import workApi from "clientApi/workApi";
import Button from "components/Button";
import { useEffect, useState } from "react";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { WorkActivityTypes } from "utilities/types";
import ActionCTAContent from "./ActionCTAContent";

const ReviewWorkItemCTA: React.FC<{ workItemId: string }> = ({
  workItemId,
}) => {
  const userId = useShallowSelector((store) => store.user?.id);
  const workItem = useShallowSelector((store) => store.work.dict[workItemId]);

  if (
    (userId && userId !== workItem.reviewerId) ||
    workItem.isClosed ||
    workItem.requests?.activeReviewRequest ||
    workItem.requests?.reviewRequestCount === 0
  )
    return <></>;

  return <ReviewTypeSwitch workItemId={workItemId} />;
};

const ReviewTypeSwitch: React.FC<{ workItemId: string }> = ({ workItemId }) => {
  const storeData = useShallowSelector((store) => {
    const workItem = store.work.dict[workItemId];
    return {
      requests: workItem?.requests,
      workActivities: workItem?.workActivities,
    };
  });

  const [activeWorkActivity, setactiveWorkActivity] = useState<string | null>(
    null
  );

  const workActivity = useShallowSelector((store) =>
    activeWorkActivity
      ? store.work.workActivities[activeWorkActivity]
      : undefined
  );

  useEffect(() => {
    const activitiesDict = store.getState().work.workActivities;
    const workActivities = storeData.workActivities;
    if (!workActivities || storeData.requests?.activeReviewRequest)
      return setactiveWorkActivity(null);

    for (var i = workActivities.length - 1; i >= 0; i--) {
      const el = workActivities[i];
      const activityobj = activitiesDict[el];
      if (activityobj.type === WorkActivityTypes.REVIEW_REQUEST) {
        setactiveWorkActivity(el);
        break;
      }
    }
  }, [storeData.workActivities]);

  if (!workActivity) return <></>;

  return (
    <ActionCTAContent
      title={"Is this task complete?"}
      description={"Mark this task done if there is nothing left to do"}
      icon={<CheckOutlined />}
    >
      <Button
        onClick={() => {
          workApi.markWorkAsDone([workItemId]);
        }}
        style={{
          minWidth: "104px",
        }}
      >
        Mark done
      </Button>
    </ActionCTAContent>
  );
};

export default ReviewWorkItemCTA;
