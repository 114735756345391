import EtheriumLogo from "icons/networkLogos/ethereum.svg";
import BscLogo from "icons/networkLogos/bsc.svg";
import PolygonLogo from "icons/networkLogos/polygon.svg";
import AvalancheLogo from "icons/networkLogos/avalanche.svg";
import XdaiLogo from "icons/networkLogos/xdai.svg";
import FantomLogo from "icons/networkLogos/fantom.svg";
import ArbitrumLogo from "icons/networkLogos/arbitrum.svg";
import CeloLogo from "icons/networkLogos/celo.svg";
import HarmonyLogo from "icons/networkLogos/harmony.svg";
import OptimismLogo from "icons/networkLogos/optimism.svg";
import MoonriverLogo from "icons/networkLogos/moonriver.svg";

enum Chains {
  ETHEREUM = 1,
  BSC = 56,
  POLYGON = 137,
  AVALANCHE = 43114,
  XDAI = 100,
  FANTOM = 250,
  ARBITRUM = 42161,
  CELO = 42220,
  HARMONY = 1666600000,
  GOERLI = 5,
  OPTIMISM = 10,
  MOONRIVER = 1285,
  ROPSTEN = 3,
  RINKEBY = 4,
}

enum ChainNames {
  ETHEREUM = "ETHEREUM",
  BSC = "BSC",
  POLYGON = "POLYGON",
  AVALANCHE = "AVALANCHE",
  XDAI = "XDAI",
  FANTOM = "FANTOM",
  ARBITRUM = "ARBITRUM",
  CELO = "CELO",
  HARMONY = "HARMONY",
  GOERLI = "GOERLI",
  OPTIMISM = "OPTIMISM",
  MOONRIVER = "MOONRIVER",
  ROPSTEN = "ROPSTEN",
  RINKEBY = "RINKEBY",
}

const networkIdToNetowrkNameIdentifier = {
  [Chains.ETHEREUM]: ChainNames.ETHEREUM,
  [Chains.BSC]: ChainNames.BSC,
  [Chains.POLYGON]: ChainNames.POLYGON,
  [Chains.AVALANCHE]: ChainNames.AVALANCHE,
  [Chains.XDAI]: ChainNames.XDAI,
  [Chains.FANTOM]: ChainNames.FANTOM,
  [Chains.ARBITRUM]: ChainNames.ARBITRUM,
  [Chains.CELO]: ChainNames.CELO,
  [Chains.HARMONY]: ChainNames.HARMONY,
  [Chains.GOERLI]: ChainNames.GOERLI,
  [Chains.OPTIMISM]: ChainNames.OPTIMISM,
  [Chains.MOONRIVER]: ChainNames.MOONRIVER,
  [Chains.RINKEBY]: ChainNames.RINKEBY,
  [Chains.ROPSTEN]: ChainNames.ROPSTEN,
};

const getNetworkNameFromId = (networkId: Chains) => {
  const chainNameIdentifier = networkIdToNetowrkNameIdentifier[networkId];
  const name = RPC[chainNameIdentifier].chainName;
  return name;
};
const getNetworkIdentifierFromId = (networkId: Chains) => {
  return networkIdToNetowrkNameIdentifier[networkId];
};

const getNetworkDefaultTokenSymbol = (networkId: Chains) => {
  const chainNameIdentifier = networkIdToNetowrkNameIdentifier[networkId];
  const symbol = RPC[chainNameIdentifier].nativeCurrency.symbol;
  return symbol;
};

const getNetworkDefaultToken = (networkId: Chains) => {
  const chainNameIdentifier = networkIdToNetowrkNameIdentifier[networkId];
  const nativeCurrency = RPC[chainNameIdentifier].nativeCurrency;
  return nativeCurrency;
};

const getNetworkScanner = (networkId: Chains) => {
  const chainNameIdentifier = networkIdToNetowrkNameIdentifier[networkId];
  const blockExplorer = RPC[chainNameIdentifier].blockExplorerUrls[0];
  return blockExplorer;
};

interface INativeCurrency {
  name: string;
  symbol: string;
  decimals: number;
  address: string;
  logoURI: string;
}
interface IChainData {
  chainId: number;
  chainName: string;
  nativeCurrency: INativeCurrency;
  blockExplorerUrls: string[];
  iconUrl: string;
  rpcUrls: string[];
}

const RPC: { [id in ChainNames]: IChainData } = {
  ETHEREUM: {
    chainId: 1,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000", // needed for proper form handling in the TokenFormCard component
      logoURI:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    blockExplorerUrls: ["https://etherscan.io"],
    iconUrl: EtheriumLogo,
    rpcUrls: ["https://main-light.eth.linkpool.io"],
  },
  BSC: {
    chainId: 56,
    chainName: "BSC",
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
      logoURI:
        "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615",
    },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
    iconUrl: BscLogo,
  },
  POLYGON: {
    chainId: 137,
    chainName: "Polygon (MATIC)",
    nativeCurrency: {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
      logoURI:
        "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
    },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
    iconUrl: PolygonLogo,
  },
  AVALANCHE: {
    chainId: 43114,
    chainName: "Avalanche (AVAX)",
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
      logoURI:
        "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io"],
    iconUrl: AvalancheLogo,
  },
  XDAI: {
    chainId: 100,
    chainName: "Gnosis Chain (xDAI)",
    nativeCurrency: {
      name: "xDAI",
      symbol: "XDAI",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
      logoURI:
        "https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492",
    },
    rpcUrls: ["https://rpc.xdaichain.com"],
    blockExplorerUrls: ["https://blockscout.com/poa/xdai"],
    iconUrl: XdaiLogo,
  },
  FANTOM: {
    chainId: 250,
    chainName: "Fantom Opera",
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
      logoURI:
        "https://assets.coingecko.com/coins/images/4001/small/Fantom.png?1558015016",
    },
    rpcUrls: ["https://rpc.ftm.tools"],
    blockExplorerUrls: ["https://ftmscan.com"],
    iconUrl: FantomLogo,
  },
  ARBITRUM: {
    chainId: 42161,
    chainName: "Arbitrum One",
    nativeCurrency: {
      name: "Ether",
      symbol: "AETH",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
      logoURI:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://arbiscan.io"],
    iconUrl: ArbitrumLogo,
  },
  CELO: {
    chainId: 42220,
    chainName: "Celo Mainnet",
    nativeCurrency: {
      name: "Celo",
      symbol: "CELO",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
      logoURI:
        "https://assets.coingecko.com/coins/images/11090/small/icon-celo-CELO-color-500.png?1592293590",
    },
    rpcUrls: ["https://forno.celo.org"],
    blockExplorerUrls: ["https://explorer.celo.org"],
    iconUrl: CeloLogo,
  },
  HARMONY: {
    chainId: 1666600000,
    chainName: "Harmony",
    nativeCurrency: {
      name: "Harmony",
      symbol: "ONE",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
      logoURI:
        "https://assets.coingecko.com/coins/images/4344/small/Y88JAze.png?1565065793",
    },
    rpcUrls: ["https://api.harmony.one"],
    blockExplorerUrls: ["https://explorer.harmony.one"],
    iconUrl: HarmonyLogo,
  },
  GOERLI: {
    chainId: 5,
    chainName: "Goerli Test Network",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000", // needed for proper form handling in the TokenFormCard component
      logoURI:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    rpcUrls: ["https://goerli-light.eth.linkpool.io/"],
    blockExplorerUrls: ["https://goerli.etherscan.io/"],
    iconUrl: EtheriumLogo,
  },
  ROPSTEN: {
    chainId: 3,
    chainName: "Ropsten Test Network",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000", // needed for proper form handling in the TokenFormCard component
      logoURI:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    rpcUrls: ["https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://ropsten.etherscan.io"],
    iconUrl: EtheriumLogo,
  },
  RINKEBY: {
    chainId: 4,
    chainName: "Rinkeby Test Network",
    nativeCurrency: {
      name: "Ether",
      symbol: "ROP",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000", // needed for proper form handling in the TokenFormCard component
      logoURI:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    rpcUrls: ["https://rinkey.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161	"],
    blockExplorerUrls: ["https://rinkey.etherscan.io"],
    iconUrl: EtheriumLogo,
  },
  OPTIMISM: {
    chainId: 10,
    chainName: "Optimism",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000", // needed for proper form handling in the TokenFormCard component
      logoURI:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
    iconUrl: OptimismLogo,
    rpcUrls: ["https://mainnet.optimism.io"],
  },
  MOONRIVER: {
    chainId: 1285,
    chainName: "Moonriver",
    nativeCurrency: {
      name: "Moonriver",
      symbol: "MOVR",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000", // needed for proper form handling in the TokenFormCard component
      logoURI:
        "https://assets.coingecko.com/coins/images/17984/small/9285.png?1630028620",
    },
    blockExplorerUrls: ["https://moonriver.moonscan.io"],
    iconUrl: MoonriverLogo,
    rpcUrls: ["https://rpc.api.moonriver.moonbeam.network"],
  },
};

const supportedChains: ChainNames[] = [
  ChainNames.ETHEREUM,
  ChainNames.POLYGON,
  ChainNames.XDAI,
  ChainNames.ARBITRUM,
  ChainNames.AVALANCHE,
  ChainNames.OPTIMISM,
  ChainNames.ROPSTEN,
  ChainNames.RINKEBY,
  ChainNames.GOERLI,
  // ChainNames.FANTOM,
  // ChainNames.BSC,
  // ChainNames.CELO,
  // ChainNames.HARMONY,
  // ChainNames.MOONRIVER,
];

export {
  Chains,
  ChainNames,
  RPC,
  supportedChains,
  getNetworkNameFromId,
  getNetworkDefaultTokenSymbol,
  getNetworkScanner,
  getNetworkDefaultToken,
  getNetworkIdentifierFromId,
};
