import {
  getNameFromContainer,
  useGetContainerFromType,
} from "modules/containerHelpers";
import { useEffect, useState } from "react";
import { ContainerTypes } from "utilities/types";

interface HeadingProps {
  containerId: string;
  containerType: ContainerTypes;
}
export const HeadingSection: React.FC<HeadingProps> = ({
  containerId,
  containerType,
}) => {
  const [name, setName] = useState("");
  const container = useGetContainerFromType(containerId, containerType);
  useEffect(() => {
    const name = getNameFromContainer(container, containerType);
    setName(name);
  }, [container.id]);

  return (
    <div>
      <h4 className="bold mb-8"> Share “{name}”</h4>
      <p className="small regular secondary">
        {" "}
        All members of this base can view this document. Each person’s roles
        will determine if they can comment and edit.
      </p>
    </div>
  );
};
