import React, { useEffect } from "react";
import { Line } from "utilities/lineUtilities";
import { ContainerTypes } from "utilities/types";
import BlockCitations from "editor/mentionsSection/BlockCitations";
import { SET_BLOCKS } from "store/actions";
import store from "store/storeExporter";
import { axiosInstance } from "index";

const BlockCitationsView: React.FC<any> = (props: any) => {
  useEffect(() => {
    const citationIds = props.citations.map((id: any) => id);
    axiosInstance
      .post("/api/citation/citationMentions", {
        citationArray: citationIds,
      })
      .then((res) => {
        const blocks = res.data.blocks;
        const workActivities = res.data.workActivities;

        const mentions = { documents: {} as any, projects: {} as any };
        const rootBlocks: string[] = [];
        const blocksData: any = {};
        blocks.forEach((block: Line) => {
          let container;
          if (block.containerType === ContainerTypes.WORK_ACTIVITY) {
            const containerTypeValue = "documents";
            const containerId = workActivities[block.containerId].taskId;
            block.containerId = containerId;
            block.containerType = ContainerTypes.TASK;
            if (!mentions[containerTypeValue][containerId])
              mentions[containerTypeValue][containerId] = {
                rootLinesId: [],
                lines: {} as any,
              };
            container = mentions[containerTypeValue][containerId];
          } else if (block.containerType === ContainerTypes.NOTE) {
            if (!mentions.documents["00-note"]) {
              mentions.documents["00-note"] = {
                rootLinesId: [],
                lines: {} as any,
              };
            }
            container = mentions.documents["00-note"];
          } else {
            const containerTypeValue =
              block.containerType === ContainerTypes.WORK
                ? "projects"
                : "documents";
            if (!mentions[containerTypeValue][block.containerId])
              mentions[containerTypeValue][block.containerId] = {
                rootLinesId: [],
                lines: {} as any,
              };
            container = mentions[containerTypeValue][block.containerId];
          }
          const parentBlock = container.lines[block.parentId];
          if (parentBlock) {
            if (!parentBlock.children) parentBlock.children = [];
            parentBlock.children.push(block.id);
          } else {
            container.rootLinesId.push(block.id);
            rootBlocks.push(block.id);
          }
          container.lines[block.id] = block;
        });
        // setblockMentions(rootBlocks);
        store.dispatch({
          type: SET_BLOCKS,
          param: { blocks: blocksData },
        });
      });
  }, [props.citations]);

  return (
    <BlockCitations paneId={props.paneId} blockMentions={props.citations} />
  );
};

export default BlockCitationsView;
