import {
  SET_ACTIVE_WORKSPACE,
  SET_WORK_VIEWS,
  UPDATE_CUSTOM_VIEW,
} from "store/actions";
import {
  CustomWorkView,
  WeeklyWidgetContext,
  IBlockContext,
  INotesObj,
  IFilterState,
} from "utilities/types";
import { ClarityStore } from "store/storeExporter";
import { useSelector, shallowEqual } from "react-redux";
import {
  useWork,
  useProjectIds,
  useTaskIds,
  useInitiativeIds,
} from "store/reducers/workReducer";
import { applyFiltersToItems } from "modules/taskService";
import { useEffect, useState } from "react";

interface ICustomWorkViewDict {
  [id: string]: CustomWorkView;
}

export interface IinitialState {
  dict: ICustomWorkViewDict;
}

const initialState: IinitialState = {
  dict: {} as ICustomWorkViewDict,
};

interface ICustomWorkViewParams {
  type: string;
  param: {
    type: string;
    delta: Partial<CustomWorkView>;
    id: string;
  };
}

interface ISetCustomWorkViewParams {
  type: string;
  param: {
    workViews: CustomWorkView[];
  };
}

export default function customWorkViewsReducer(
  state = initialState,
  action: ICustomWorkViewParams & ISetCustomWorkViewParams
) {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      return initialState;
    }

    case UPDATE_CUSTOM_VIEW: {
      const newState = { ...state, dict: { ...state.dict } };
      if (action.param.type === "add") {
        newState.dict[action.param.id] = action.param.delta as CustomWorkView;
        return newState;
      }
      if (action.param.type === "update") {
        newState.dict[action.param.id] = {
          ...newState.dict[action.param.id],
          ...action.param.delta,
        };
        return newState;
      }
      if (action.param.type === "delete") {
        delete newState.dict[action.param.id];
        return newState;
      }
      return state;
    }

    case SET_WORK_VIEWS: {
      const newState = { ...state, dict: { ...state.dict } };
      action.param.workViews.forEach(
        (workView) => (newState.dict[workView.id] = workView)
      );
      return newState;
    }

    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const orderByCreateTime = (dict: ICustomWorkViewDict, list: string[]) => {
  list.sort((a: string, b: string) => {
    const threadA = dict[a];
    const threadB = dict[b];
    return (
      new Date(threadB.dateCreated).getTime() -
      new Date(threadA.dateCreated).getTime()
    );
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const orderByName = (dict: ICustomWorkViewDict, list: string[]) => {
  list.sort((a: string, b: string) => {
    const threadA = dict[a];
    const threadB = dict[b];
    return threadB.name.localeCompare(threadA.name);
  });
};

export function useCustomWorkViews() {
  return useSelector((state: ClarityStore) => {
    return state.customWork.dict;
  }, shallowEqual);
}

export function useCustomViewCountGetter() {
  const { workDict } = useWork();
  const allProjectIds = useProjectIds();
  const allTaskIds = useTaskIds();
  const notes = useSelector((store: ClarityStore) => {
    return {
      notesDict: store.notes.dict,
      weeklyNotesDict: store.notes.weeklyNotesDict,
    };
  }, shallowEqual);

  const getter = ({
    customView,
    container,
  }: {
    customView: CustomWorkView;
    container?: IBlockContext["container"];
  }) => {
    const containerNote: any =
      container?.type === "Note" &&
      ((notes.notesDict[container.id] ??
        notes.weeklyNotesDict[container.id]) as INotesObj);

    const weeklyContext: WeeklyWidgetContext | null = !containerNote?.isWeekly
      ? null
      : {
          mondayDate: containerNote.dateCreated,
        };

    if (!customView) return 0;
    const items = [...allProjectIds, ...allTaskIds];
    const filteredItems = applyFiltersToItems(
      items,
      workDict,
      customView.filters,
      weeklyContext
    );

    return filteredItems.length;
  };

  getter.deps = [workDict, allProjectIds, allTaskIds, notes];

  return getter;
}

export function useCustomViewCount({
  customView,
  container,
}: {
  customView: CustomWorkView;
  container?: IBlockContext["container"];
}) {
  const [count, setCount] = useState(0);
  const getCount = useCustomViewCountGetter();

  useEffect(() => {
    setCount(getCount({ customView, container }));
  }, [customView, container, ...getCount.deps]);

  return count;
}

export function useGetContributorViewItems(filters: IFilterState) {
  const { workDict } = useWork();
  const allProjectIds = useProjectIds();
  const allTaskIds = useTaskIds();
  const allInitiativesIds = useInitiativeIds();
  const [filteredItems, setfilteredItems] = useState<string[]>([]);

  useEffect(() => {
    const items = [...allProjectIds, ...allTaskIds, ...allInitiativesIds];
    const filteredItems = applyFiltersToItems(items, workDict, filters);
    setfilteredItems(filteredItems);
  }, [workDict, allProjectIds, allTaskIds, allInitiativesIds, filters]);

  return filteredItems;
}

export function useContributionViewCount(filters: IFilterState) {
  const [count, setCount] = useState(0);
  const items = useGetContributorViewItems(filters);

  useEffect(() => {
    setCount(items.length);
  }, [items]);

  return count;
}
