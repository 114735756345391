import Button, { ButtonTypes } from "components/Button";
import { VALUE_ENTER, VALUE_ESCAPE } from "keycode-js";
import React, { useEffect, useRef } from "react";
import Modal from "./Modal";
import styles from "./modal/modal.module.scss";

const ConfirmModal: React.FC<{
  onCancel?: () => void;
  onConfirm: () => void;
  confirmDisabled?: boolean;
  confirmationText?: string;
  cancelText?: string;
  primarylabelStyle?: React.CSSProperties;
  close: () => void;
  preventDefaultCloseOnConfirm?: boolean;
  title: string;
}> = ({
  children,
  onCancel,
  onConfirm,
  title,
  close,
  confirmDisabled,
  confirmationText,
  preventDefaultCloseOnConfirm,
  primarylabelStyle,
  cancelText,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    modalRef.current?.focus();
  }, []);
  const confirm = () => {
    onConfirm();
    if (!preventDefaultCloseOnConfirm) close();
  };
  const cancel = () => {
    if (onCancel) onCancel();
    close();
  };

  return (
    <>
      <Modal
        hideModal={close}
        size="medium"
        zIndex={9999}
        className={styles.confirmModal}
      >
        <div
          tabIndex={0}
          ref={modalRef}
          onKeyDown={(e) => {
            switch (e.key) {
              case VALUE_ENTER: {
                confirm();
                break;
              }
              case VALUE_ESCAPE: {
                cancel();
                break;
              }
            }
          }}
        >
          <>
            <h4 className={styles.title}>{title}</h4>
            <div
              className="body2 regular"
              style={{
                marginTop: "24px",
                marginBottom: "40px",
              }}
            >
              {children}
            </div>
            <div
              style={{
                display: "flex",
                gap: "12px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                buttonType={ButtonTypes.DEFAULT}
                onClick={cancel}
                labelStyle={{ paddingLeft: "12.5px", paddingRight: "12.5px" }}
              >
                {cancelText ? cancelText : "Cancel"}
              </Button>
              <Button
                buttonType={ButtonTypes.PRIMARY}
                disabled={confirmDisabled}
                onClick={confirm}
                labelStyle={
                  primarylabelStyle
                    ? primarylabelStyle
                    : { paddingLeft: "20px", paddingRight: "20px" }
                }
              >
                {confirmationText ? confirmationText : "Proceed"}
              </Button>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
