import { Radio, Space } from "antd";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import ConfirmModal from "clarity-ui/ConfirmModal";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import notificationsApi from "clientApi/notificationsApi";
import { userApi } from "clientApi/userApi";
import { web3Api } from "clientApi/web3Api";
import Button, { ButtonTypes } from "components/Button";
import ClarityInput from "components/ClarityInput";
import { throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { validateUsername } from "utilities/regexUtilities";
import { IMesssageInterface } from "utilities/types";
import settingsStyles from "../userSettings.module.scss";
import { Skeleton } from "antd";

const Username: React.FC = () => {
  const user = useSelector((store: ClarityStore) => store.user, shallowEqual);
  const [option, setoption] = useState<number | null>(checkOption());
  const [confimationOption, setConfirmationOption] = useState<number | null>(
    null
  );
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isUsingEns =
    user && user.publicEthAdress && user?.username.includes(".eth")
      ? true
      : false;

  function checkOption() {
    if (!user) return null;
    if (!user.username) return null;
    if (!user.publicEthAdress) return 2;

    if (
      user.username.includes(".eth") ||
      user.username === user.publicEthAdress
    )
      return 1;
    else return 2;
  }

  const checkIfShouldUpdateUsername = async () => {
    if (option === 2) {
      setLoading(true);
      const walletData = await web3Api.getEnsData();
      if (user?.publicEthAdress)
        userApi
          .changeUsername(
            walletData.name ? walletData.name : user?.publicEthAdress
          )
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
    }
  };

  if (!user) return <></>;
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      {user.publicEthAdress && (
        <>
          <OptionSelector
            option={option}
            setoption={(value) => {
              if (value === 1) {
                if (
                  (user &&
                    user.publicEthAdress &&
                    user?.username.includes(".eth")) ||
                  user.publicEthAdress === user.username
                ) {
                  setoption(1);
                } else {
                  setConfirmationOption(value);
                  setConfirmationModal(true);
                }
              } else setoption(2);
            }}
          />
          {option === 1 &&
            (isUsingEns || user.username === user.publicEthAdress) && (
              <EthUsername
                username={user?.username}
                isUsingEns={isUsingEns}
                loading={loading}
              />
            )}
        </>
      )}
      {option === 2 && (
        <CustomUsername
          username={user.username}
          withMargin={user.publicEthAdress ? true : false}
          dontShowUsername={
            (user && user.publicEthAdress && user?.username.includes(".eth")) ||
            user.publicEthAdress === user.username
          }
        />
      )}
      {confirmationModal && (
        <ConfirmModal
          close={() => setConfirmationModal(false)}
          onConfirm={() => {
            setoption(confimationOption);
            checkIfShouldUpdateUsername();
          }}
          title={"Change your username?"}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <span>
              When you switch to an ENS name or wallet address, your custom
              username will become available for someone else to use.
            </span>
            <span>
              If you do not have a Primary ENS name set on your wallet, we
              recommend setting a display name on your profile. This way, your
              fellow collaborators know who you are and can easily search you up
              to invite you to bases and documents.
            </span>
          </div>
        </ConfirmModal>
      )}
    </div>
  );
};

const OptionSelector: React.FC<{
  option: number | null;
  setoption: React.Dispatch<React.SetStateAction<number | null>>;
}> = ({ option, setoption }) => {
  return (
    <Radio.Group
      prefixCls="cl"
      onChange={(e) => {
        setoption(e.target.value);
      }}
      value={option}
    >
      <Space direction="vertical">
        <Radio value={1}>
          <span className={option === 1 ? "body2" : "body2 secondary"}>
            Use my Primary ENS name (or public key) as my Clarity username
          </span>
        </Radio>
        <Radio value={2}>
          <span className={option === 2 ? "body2" : "body2 secondary"}>
            Set a custom username
          </span>
        </Radio>
      </Space>
    </Radio.Group>
  );
};

const EthUsername: React.FC<{
  username: string;
  isUsingEns: boolean;
  loading: boolean;
}> = ({ username, isUsingEns, loading }) => {
  if (loading) {
    return (
      <Skeleton
        loading={true}
        paragraph={{ rows: 1 }}
        title={false}
        active={true}
      />
    );
  }
  if (isUsingEns)
    return (
      <p className="body2 secondary">
        Your username is set to <b>{username}</b> which is your Primary ENS
        name.
      </p>
    );
  return (
    <p className="body2 secondary">
      There isn’t a Primary ENS name set on this wallet, so your username is set
      to <b>@{usernameSplitter(username)}</b> which is your public key. You can
      register an ENS domain (yourname.eth) on the{" "}
      <a
        className={`body2 ${settingsStyles.link}`}
        href="https://ens.domains/"
        target="_blank"
        rel="noreferrer"
      >
        ENS website
      </a>
      .
    </p>
  );
};

const CustomUsername: React.FC<{
  username: string;
  withMargin: boolean;
  dontShowUsername: boolean;
}> = ({ username, withMargin, dontShowUsername }) => {
  const [innerUsername, setinnerUsername] = useState<string>(
    dontShowUsername ? "" : username
  );
  const [loading, setloading] = useState(false);
  const [messasge, setmessage] = useState<IMesssageInterface | null>(null);
  const [isUsernameValidState, setisUsernameValidState] = useState(false);
  const wasEditing = useRef(false);

  const validateUsernameUnique = async (usernameData: string) => {
    const isClientUsernameFormatValid =
      usernameData.length < 40 &&
      usernameData.length > 2 &&
      validateUsername(usernameData) &&
      usernameData !== username;

    if (!isClientUsernameFormatValid) {
      setisUsernameValidState(false);
      wasEditing.current = false;
      return;
    }
    const check = await userApi.checkUsernameUnique(usernameData);
    setmessage(check);

    if (check.status && isClientUsernameFormatValid) {
      setisUsernameValidState(true);
    } else setisUsernameValidState(false);

    wasEditing.current = false;
  };

  const validateUsernameUniqueThrottled = useRef(
    throttle(validateUsernameUnique, 300)
  );

  const handleChangeUsername = async () => {
    if (wasEditing.current) return;
    setloading(true);
    await userApi
      .changeUsername(innerUsername)
      .then((res) => {
        notificationsApi.displayConfirmation({
          title: <span>Username updated</span>,
        });
      })
      .catch();

    setisUsernameValidState(false);
    setmessage(null);
    setloading(false);
  };

  useEffect(() => {
    wasEditing.current = true;
    if (username !== innerUsername) {
      validateUsernameUniqueThrottled.current(innerUsername);
    }
  }, [innerUsername]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "50%",
        marginLeft: withMargin ? "24px" : 0,
      }}
    >
      <div>
        <ClarityInput
          value={innerUsername}
          onChange={(e) => setinnerUsername(e.target.value)}
        />
        {!isUsernameValidState && innerUsername !== username && (
          <>
            {innerUsername.length > 39 && (
              <ClarityLabel
                message={"Usernames cannot be longer than 39 characters"}
                type={MessageLabelTypes.error}
              />
            )}
            {!validateUsername(innerUsername) && (
              <ClarityLabel
                message={
                  "Usernames cannot contain spaces, periods, or most punctuation. Try again?"
                }
                type={MessageLabelTypes.error}
              />
            )}
            {messasge?.status === 0 && messasge?.info && (
              <ClarityLabel
                message={messasge.info}
                type={MessageLabelTypes.error}
              />
            )}
          </>
        )}
        {isUsernameValidState && (
          <ClarityLabel
            message={messasge ? messasge.info : ""}
            type={MessageLabelTypes.confirm}
          />
        )}
      </div>

      <Button
        buttonType={ButtonTypes.LARGE_PRIMARY}
        disabled={!isUsernameValidState || loading}
        onClick={() => {
          handleChangeUsername();
        }}
        style={{ width: "154px" }}
      >
        Change username
      </Button>
    </div>
  );
};

export default Username;
