import Icon from "@ant-design/icons";
import React from "react";

export default function CloseSquareTwoTone(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.307064 11.5863C0.459738 11.744 0.667395 11.8327 0.884137 11.8327C1.10088 11.8327 1.30856 11.744 1.46121 11.5863L5.76086 7.17256L10.0605 11.5863C10.2132 11.744 10.4208 11.8327 10.6376 11.8327C10.8543 11.8327 11.062 11.744 11.2147 11.5863C11.3686 11.4299 11.4551 11.2172 11.4551 10.9951C11.4551 10.773 11.3686 10.5603 11.2147 10.4039L6.90665 5.99864L11.2147 1.59342C11.4208 1.38221 11.5014 1.07424 11.4258 0.785795C11.3505 0.497209 11.1304 0.271788 10.8488 0.194555C10.5672 0.117179 10.2667 0.199713 10.0605 0.410925L5.76085 4.82471L1.46119 0.410925C1.25504 0.199712 0.95445 0.117179 0.672916 0.194555C0.391246 0.271791 0.171226 0.497224 0.0958435 0.785795C0.0203218 1.07424 0.100878 1.38221 0.307029 1.59342L4.61504 5.99864L0.307029 10.4039C0.153129 10.5603 0.0665894 10.773 0.0665894 10.9951C0.0665894 11.2172 0.153133 11.4299 0.307029 11.5863H0.307064Z"
        fill="currentColor"
        opacity="0.65"
      />
    </svg>
  );
}
