import { Block } from "store/reducers/blockReducer";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store from "store/storeExporter";

export const isAnyChildUnfolded = (block: Block) => {
  let childUnfolded = false;
  const newState = store.getState().blocks;
  if (!newState) return childUnfolded;
  for (const childId of block.children) {
    const childBlock = getBlockById(newState.dict, childId);
    if (
      childBlock &&
      childBlock.children?.length > 0 &&
      childBlock.isFolded === false
    ) {
      childUnfolded = true;
      break;
    }
  }
  return childUnfolded;
};
