import UsersStack from "clarity-ui/UsersStack";
import React, { useMemo, useState } from "react";
import { useShallowSelector } from "utilities/hooks";
import BaseMembersModal from "../baseMembers/BaseMembersModal";
import styles from "./baseProfile.module.scss";

const BaseMembers: React.FC = () => {
  const members = useShallowSelector((store) => store.members.ids);
  const membersDict = useShallowSelector((store) => store.members.dict);
  const onlineMembers = useShallowSelector(
    (store) => store.members.onlineMembers
  );

  const first3 = useMemo(() => {
    const toReturn: string[] = [];
    members.forEach((memberId) => {
      // if (toReturn.length === 10) return;
      if (toReturn.length === 3) return;
      const memberObj = membersDict[memberId];
      if (memberObj && memberObj.role && memberObj.role !== "Guest")
        toReturn.push(memberId);
    });
    return toReturn;
  }, [members]);

  const [membersModalVisible, setmembersModalVisible] = useState(false);

  return (
    <div className={styles.membersRow}>
      <UsersStack userIds={first3} hideTooltip={true} />
      <span
        className="caption medium secondary underlined-hover"
        onClick={() => setmembersModalVisible(true)}
      >
        {members.length} {members.length < 2 ? "member" : "members"} (
        {onlineMembers.length} online)
      </span>

      <BaseMembersModal
        isVisible={membersModalVisible}
        setisVisible={setmembersModalVisible}
      />
    </div>
  );
};

export default BaseMembers;
