import Conditional from "components/Conditional";
import React from "react";
import { ChunkDestination } from "utilities/stateTypes";
import ActiveGoals, { useActiveGoals } from "../widgets/ActiveGoals";
import ActiveProjects, { useActiveProjects } from "../widgets/ActiveProjects";

const GroupWidgets: React.FC<{
  paneId: ChunkDestination;
  groupId: string;
  fitContainer?: boolean;
}> = ({ paneId, groupId, fitContainer }) => {
  const isGoalLength = useActiveGoals(groupId);
  const isProjectLength = useActiveProjects(groupId);
  const isGoalProjectCount =
    isGoalLength.length === 0 && isProjectLength.length === 0;
  return (
    <Conditional on={!isGoalProjectCount}>
      <div className={`widgetContainer ${fitContainer ? "fitContainer" : ""}`}>
        <Conditional on={isGoalLength.length > 0}>
          {" "}
          <ActiveGoals paneId={paneId} groupId={groupId} />
        </Conditional>
        <Conditional on={isProjectLength.length > 0}>
          <ActiveProjects paneId={paneId} groupId={groupId} />{" "}
        </Conditional>
      </div>
    </Conditional>
  );
};

export default GroupWidgets;
