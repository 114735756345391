import React, {
  SyntheticEvent,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import {
  IPageObj,
  IProjectObj,
  ContainerTypes,
  IBlockContext,
  Abilities,
} from "utilities/types";
import { KeyCodes, ILineValue } from "utilities/lineUtilities";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as actionTypes from "store/actions";
import { message } from "antd";
import styles from "./titleBlockComponent/titleBlockComponent.module.scss";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import store, { $focusOn, ClarityStore } from "store/storeExporter";
import { getCurrentContext } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import { createFirstBlock } from "editor/utils/specificActions/addBlockActions";
import { stripHtml } from "utilities/stringUtilities";
import { useOptionalClassName } from "utilities/hooks";
import { useAbilityChecker } from "editor/utils/customHooks";
import {
  breakDownHtml,
  getHtml,
  iterateThroughValue,
  removeBlancks,
} from "editor/utils/blockValueHelpers";
import {
  checkCaretPosition,
  checkIfAtEdgeOfTag,
} from "editor/utils/caretUtils";
import { titleTagCreation } from "editor/utils/specificActions/textUpdateActions";
import { axiosInstance } from "index";
import { getGroupsFromEntity } from "modules/containerHelpers";

export interface TitleProps {
  document?: IPageObj | null;
  project?: IProjectObj | null;
  noTag?: boolean;
  isBaseMember: boolean;
  changeLineText: (param: any) => any;
  checkForNewTags: (param: any) => any;
  updateDocName: (param: any) => any;
  addDocument: (document: IPageObj) => any;
  updateDocNameInFrontend: (document: IPageObj) => any;
  focusFirstBlock: (param: { containerId: string; containerType: any }) => any;
  changeFocusedBlockToFirstBlock: (caretPosition: number) => null;
  workspaceId: string;
  documents: IPageObj[];
  titleBlock: any;
  context: IBlockContext;
  slim?: boolean;
}

const openNotificationWithIcon = (messageText: string) => {
  message.error(messageText, 3);
};

const checkSavingData = async (
  event: SyntheticEvent,
  document: IPageObj | null,
  props: TitleProps,
  titleRef: any,
  containerType?: ContainerTypes
): Promise<boolean> => {
  if (
    document &&
    event.currentTarget.innerHTML !==
      getHtml(document.nameValue, props.documents) &&
    event.currentTarget.innerHTML !== ""
  ) {
    const { textContent } = event.currentTarget;
    const nameValue: ILineValue[] = [];

    for (const el of Array.from(event.currentTarget.childNodes)) {
      nameValue.push(breakDownHtml(el));
    }

    const tags: string[] = [];
    const name = event.currentTarget.textContent
      ? event.currentTarget.textContent
      : "";

    const resp = await checkIfDocumentNameExists(
      document.id,
      name,
      props.workspaceId
    );

    if (!resp) {
      iterateThroughValue(nameValue, tags);
      props.updateDocName({
        lineId: "title",
        titleBlockId: props.titleBlock.id,
        containerId: document.id,
        containerType: ContainerTypes.DOCUMENT,
        titleRef,
        tags,
        value: nameValue,
        document,
        text: textContent,
      });
      return true;
    } else {
      openNotificationWithIcon("A document with this name already exists");
      return false;
    }
  } else return false;
};

const checkProjectSavingData = async (
  event: SyntheticEvent,
  project: IProjectObj | null,
  props: TitleProps,
  titleRef: any
): Promise<boolean> => {
  const textContent = event.currentTarget.textContent
    ? event.currentTarget.textContent
    : "";
  if (
    project &&
    event.currentTarget.innerHTML !==
      getHtml(project.nameValue, props.documents) &&
    textContent.trim().length > 0
  ) {
    const { textContent } = event.currentTarget;
    const nameValue: ILineValue[] = [];

    for (const el of Array.from(event.currentTarget.childNodes)) {
      nameValue.push(breakDownHtml(el));
    }

    const tags: string[] = [];
    iterateThroughValue(nameValue, tags);
    props.updateDocName({
      lineId: "title",
      titleBlockId: props.titleBlock.id,
      containerId: project.id,
      containerType: ContainerTypes.PROJECT,
      titleRef,
      tags,
      value: nameValue,
      project,
      text: textContent,
    });
    return true;
  } else {
    if (project) {
      event.currentTarget.innerHTML = getHtml(
        project.nameValue,
        props.documents
      );
    }
    return false;
  }
};

const checkIfDocumentNameExists = async (
  docId: string,
  name: string,
  workspaceId: string
): Promise<boolean> => {
  return await axiosInstance({
    method: "get",
    url: `/api/document/checkNameExists`,
    params: {
      docId,
      name,
      workspaceId,
    },
  }).then(async (rs) => {
    let response: boolean = false;
    if (rs.data) response = rs.data;
    return response;
  });
};

const focusFirstBlock = (context: IBlockContext, caretPosition: number) => {
  const newState = store.getState().blocks;
  const currentContext = getCurrentContext(newState, context.id);
  const firstBlockId = currentContext.state.rootBlocksIds[0];
  store.dispatch({
    type: actionTypes.REFOCUS,
    param: {
      newFocus: {
        ...$focusOn.value,
        focusBlockId: firstBlockId,
        caretPosition,
      },
    },
  });
};

const TitleBlockComponent: React.FC<TitleProps> = (props) => {
  const [reset, setreset] = useState(true);
  const innerCaretPosition = useRef(0);
  const titleRef = useRef<HTMLDivElement>(null);
  const userRole = useSelector(
    (state: ClarityStore) => state.client.roleType,
    shallowEqual
  );

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: props.project
      ? getGroupsFromEntity(props.project, ContainerTypes.PROJECT)
      : undefined,
  });

  const containerClassName = useOptionalClassName({
    baseStyle: `${styles.docTitleBlockContainer} single-row row Block`,
    pairs: [
      {
        extraStyle: styles.docTitleBlockContainerSlim,
        withExtra: Boolean(props.slim),
      },
    ],
  });

  const blockClassName = useOptionalClassName({
    baseStyle: `row flex-section section-big w-100 title ${styles.docTitleBlock}`,
    pairs: [
      {
        extraStyle: styles.docTitleBlockSlim,
        withExtra: Boolean(props.slim),
      },
    ],
  });

  useEffect(() => {
    if (titleRef && props.document) {
      if (
        titleRef.current !== null &&
        stripHtml(titleRef.current.innerHTML) !==
          stripHtml(getHtml(props.document.nameValue, props.documents))
      ) {
        titleRef.current.innerHTML = getHtml(
          props.document.nameValue,
          props.documents
        );
        setreset(!reset);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (titleRef.current && titleRef.current === document.activeElement) {
      moveCaretToPreviousPosition(titleRef.current, innerCaretPosition.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.document?.nameValue, props.project?.name]);

  useEffect(() => {
    innerCaretPosition.current = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project?.id, props.document?.id]);

  return useMemo(() => {
    if (props.document && props.document.nameValue && props.titleBlock) {
      const isWeeklyPage =
        stripHtml(getHtml(props.titleBlock.value)).toLowerCase().trim() ===
        "weeklys";
      return (
        <>
          <h1 className={containerClassName}>
            <div
              className={blockClassName}
              contentEditable={
                userRole === "Guest" || !canEditEntity
                  ? false
                  : isWeeklyPage
                  ? false
                  : props.isBaseMember
              }
              data-block-id={props.titleBlock.id}
              ref={titleRef}
              id={"title"}
              dangerouslySetInnerHTML={{
                __html: getHtml(props.titleBlock.value, props.documents),
              }}
              onKeyDown={async (e) => {
                if (!e.key.includes("Arrow") && e.key !== "Backspace") {
                  checkIfAtEdgeOfTag(e);
                }
                if (e.keyCode === KeyCodes.z || e.keyCode === KeyCodes.y) {
                  e.stopPropagation();
                }
                if (e.keyCode === KeyCodes.enter) {
                  const caretPosition = checkCaretPosition(e.currentTarget);
                  let atEnd = false;
                  e.preventDefault();
                  if (titleRef.current?.innerText.length === 0) return;
                  if (props.document) {
                    e.persist();
                    const resp = await checkSavingData(
                      e,
                      props.document,
                      props,
                      titleRef.current
                    );
                    if (!resp) {
                      setreset(!reset);
                    }
                    if (titleRef.current) {
                      removeBlancks(titleRef.current);
                      if (caretPosition === titleRef.current.innerText.length)
                        atEnd = true;
                      if (!atEnd) {
                        moveCaretToPreviousPosition(
                          titleRef.current,
                          titleRef.current.innerText.length
                        );
                        innerCaretPosition.current =
                          titleRef.current.innerText.length;
                      }
                    }
                  }
                  if (atEnd) {
                    e.stopPropagation();
                    e.preventDefault();
                    createFirstBlock(props.context);
                    return;
                  }
                }

                if (e.keyCode === KeyCodes.arrow_down) {
                  e.stopPropagation();
                  e.preventDefault();
                  const caretPosition = checkCaretPosition(titleRef.current);
                  focusFirstBlock(props.context, caretPosition);
                }

                if (e.keyCode === KeyCodes.u) {
                  if (e.ctrlKey || e.metaKey) {
                    const selection: any = window.getSelection();
                    if (selection) {
                      const text = selection.toString();
                      if (selection.focusNode && text.length === 0) {
                        return;
                      } else {
                        e.preventDefault();
                        titleTagCreation(e, selection, titleRef);
                        if (props.document) {
                          checkSavingData(
                            e,
                            props.document,
                            props,
                            titleRef.current
                          );
                        }
                      }
                    }
                  }
                }
                if (e.metaKey || e.ctrlKey) {
                  if ([KeyCodes.b, KeyCodes.i].includes(e.keyCode))
                    e.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const text = e.clipboardData.getData("text/plain");
                const textNode = document.createTextNode(text);
                const selection = document.getSelection();
                if (selection) {
                  const caret = selection.getRangeAt(0);
                  caret.deleteContents();
                  caret.insertNode(textNode);
                  const caretCopy = caret.cloneRange();
                  caretCopy.setEndAfter(textNode);
                  caretCopy.setStartAfter(textNode);
                  selection.removeAllRanges();
                  selection.addRange(caretCopy);
                }
              }}
              onBlur={async (e) => {
                if (props.document) {
                  const resp = await checkSavingData(
                    e,
                    props.document,
                    props,
                    titleRef.current
                  );
                  if (!resp) setreset(!reset);
                }
              }}
            ></div>
          </h1>
        </>
      );
    } else if (props.project && props.titleBlock) {
      return (
        <>
          <h1 className={containerClassName}>
            <div
              className={blockClassName}
              contentEditable={
                userRole === "Guest" || !canEditEntity
                  ? false
                  : props.isBaseMember
              }
              ref={titleRef}
              id={
                props.context.paneId
                  ? "title_" + props.context.paneId
                  : "title_primary"
              }
              dangerouslySetInnerHTML={{
                __html: getHtml(props.titleBlock.value, props.documents),
              }}
              onKeyDown={async (e) => {
                if (!e.key.includes("Arrow") && e.key !== "Backspace") {
                  checkIfAtEdgeOfTag(e);
                }
                if (e.keyCode === KeyCodes.z || e.keyCode === KeyCodes.y) {
                  e.stopPropagation();
                }
                if (e.keyCode === KeyCodes.enter) {
                  const caretPosition = checkCaretPosition(e.currentTarget);
                  let atEnd = false;
                  e.preventDefault();
                  if (titleRef.current?.innerText.length === 0) return;
                  if (props.project) {
                    e.persist();
                    await checkProjectSavingData(
                      e,
                      props.project,
                      props,
                      titleRef.current
                    );
                    // if (resp.status !== 1) {
                    //   setreset(!reset);
                    // }
                    if (titleRef.current) {
                      removeBlancks(titleRef.current);
                      if (caretPosition === titleRef.current.innerText.length)
                        atEnd = true;
                      if (!atEnd)
                        moveCaretToPreviousPosition(
                          titleRef.current,
                          titleRef.current.innerText.length
                        );
                    }
                  }
                  if (props.noTag) return;
                  if (atEnd) {
                    e.stopPropagation();
                    e.preventDefault();
                    createFirstBlock(props.context);
                    return;
                  }
                }
                if (e.keyCode === KeyCodes.arrow_down) {
                  if (props.noTag) return;
                  const caretPosition = checkCaretPosition(titleRef.current);
                  focusFirstBlock(props.context, caretPosition);
                  e.stopPropagation();
                  e.preventDefault();
                }
                if (e.keyCode === KeyCodes.u) {
                  if (props.noTag) return;
                  const selection: any = window.getSelection();
                  if (selection) {
                    const text = selection.toString();
                    if (selection.focusNode && text.length === 0) {
                      return;
                    } else {
                      e.preventDefault();
                      titleTagCreation(e, selection, titleRef);
                      if (props.project) {
                        await checkProjectSavingData(
                          e,
                          props.project,
                          props,
                          titleRef.current
                        );
                      }
                    }
                  }
                }
                if (e.metaKey || e.ctrlKey) {
                  if ([KeyCodes.b, KeyCodes.i].includes(e.keyCode))
                    e.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const text = e.clipboardData.getData("text/plain");
                const textNode = document.createTextNode(text);
                const selection = document.getSelection();
                if (selection) {
                  const caret = selection.getRangeAt(0);
                  caret.deleteContents();
                  caret.insertNode(textNode);
                  const caretCopy = caret.cloneRange();
                  caretCopy.setEndAfter(textNode);
                  caretCopy.setStartAfter(textNode);
                  selection.removeAllRanges();
                  selection.addRange(caretCopy);
                }
              }}
              onBlur={async (e) => {
                if (props.project && titleRef.current) {
                  await checkProjectSavingData(
                    e,
                    props.project,
                    props,
                    titleRef.current
                  );
                  // if (resp.status !== 1) setreset(!reset);
                }
              }}
            ></div>
          </h1>
        </>
      );
    } else {
      return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.document, props.project, containerClassName, blockClassName]);
};

export const TaskStaticTitle: React.FC<{ value: ILineValue[] }> = ({
  value,
}) => {
  return (
    <h1 className={styles.docTitleBlockContainer + " single-row row Block"}>
      <div
        className={`content-section row flex-section section-big w-100 title ${styles.docTitleBlock}`}
        contentEditable={false}
        dangerouslySetInnerHTML={{
          __html: getHtml(value),
        }}
      ></div>
    </h1>
  );
};

export const PageStaticTitle: React.FC<{ value: ILineValue[] }> = ({
  value,
}) => {
  return (
    <h1 className={styles.docTitleBlockContainer + " single-row row Block"}>
      <div
        className={`content-section row flex-section section-big w-100 title ${styles.docTitleBlock}`}
        contentEditable={false}
        dangerouslySetInnerHTML={{
          __html: getHtml(value),
        }}
      ></div>
    </h1>
  );
};

const mapStateToProps = (state: any) => ({
  publicView: state.client.publicView,
  workspaceId: state.workspace.id,
  documents: state.pages.dict,
  isBaseMember: state.client.isBaseMember,
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    updatePrimaryActiveDocumentName: (param: any) =>
      dispatch({
        type: actionTypes.UPDATE_PRIMARY_ACTIVE_DOCUMENT_NAME,
        param,
      }),
    focusFirstBlock: (param: any) =>
      dispatch({
        type: actionTypes.CREATE_FIRST_BLOCK,
        param,
        destination: ownProps.destination ? ownProps.destination : "primary",
      }),
    changeFocusedBlockToFirstBlock: (caretPosition: number) =>
      dispatch({
        type: actionTypes.CHANGE_CURRENT_ID_TO_FIRST_BLOCK,
        param: {
          caretPosition,
        },
        destination: ownProps.destination ? ownProps.destination : "primary",
      }),
    changeLineText: (param: any) =>
      dispatch({
        type: actionTypes.UPDATE_LINE_TEXT,
        param,
      }),
    checkForNewTags: (param: any) =>
      dispatch({
        type: actionTypes.CHECK_FOR_NEW_TAGS,
        param,
      }),
    addDocument: (document: any) =>
      dispatch({
        type: actionTypes.ADD_NEW_DOCUMENT,
        document,
      }),
    updateDocNameInFrontend: (document: IPageObj) =>
      dispatch({
        type: actionTypes.UPDATE_DOCUMENT_NAME,
        document,
      }),
    updateDocName: (param: any) =>
      dispatch({
        type: actionTypes.CHECK_TITLE_UPDATE,
        param,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TitleBlockComponent);
