import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  ContainerTypes,
  GeneralViewsNames,
  IBlockContext,
  TasksViewModes,
} from "utilities/types";
import styles from "./widget/widgetItem.module.scss";
import { useCustomViewCount } from "store/reducers/customWorkViewsReducer";
import navigationApi from "clientApi/navigationApi";

const WidgetEntry: React.FC<{
  customViewId: string;
  listed: boolean;
  blockContext?: IBlockContext;
}> = (props) => {
  const customView = useSelector(
    (state: any) => state.customWork.dict[props.customViewId],
    shallowEqual
  );

  const count = useCustomViewCount({
    customView,
    container: props.blockContext?.container,
  });

  const handleOpen = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (props.listed) return;
    if (props.blockContext) {
      e.preventDefault();
      e.stopPropagation();
      navigationApi.contextBasedNavigate({
        currentPane: props.blockContext.paneId,
        navigationChunk: {
          viewName: GeneralViewsNames.ViewEntity,
          entity: {
            containerId: props.customViewId,
            containerType: ContainerTypes.CUSTOM_VIEW,
          },
          taskViewMode: TasksViewModes.CustomView,
        },
        shiftKey: e.shiftKey,
      });
    }
  };

  return useMemo(() => {
    if (!customView) return <></>;
    return (
      <div className={styles.widgetItem} onDoubleClick={(e) => handleOpen(e)}>
        <div className={styles.widgetItem_body}>
          <span className={styles.title}>
            <span
              style={{ cursor: props.listed ? "inherit" : "pointer" }}
              onClick={(e) => handleOpen(e)}
            >
              {customView.name}
            </span>
          </span>

          <span className={styles.subtitle}>{customView.description}</span>
        </div>
        <div className={styles.widgetItem_tail}>{count}</div>
      </div>
    );
  }, [customView, count]);
};

export function useViewCount() {}

export default WidgetEntry;
