import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  PlusOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Button, { ButtonTypes } from "components/Button";
import { locationSubject } from "components/LocationListener";
import EmptyState from "clarity-ui/EmptyState";
import { sortBy } from "lodash";
import {
  Abilities,
  CustomWorkView,
  GeneralViewsNames,
  UserRole,
} from "utilities/types";
import { useSortBy, useTable } from "react-table";
import DocumentListHeader from "components/DocumentListHeader";
import DocumentCellSecondary from "components/DocumentCellSecondary";
import { abbreviatedMonths } from "utilities/dateTime";
import ListItemMenuHandle from "components/ListItemMenuHandle";
import pagesListStyles from "../pages/pagesList/pagesList.module.scss";
import documentListHeaderStyles from "components/documentListHeader/documentListHeader.module.scss";
import { Menu, Modal } from "antd";
import WorkViewCellMain from "components/WorkViewCellMain";
import styles from "../pages/pages.module.scss";
import { deleteCustomView } from "modules/taskService";
import store, { ClarityStore } from "store/storeExporter";
import { REMOVE_VIEW_SIDEEFFECT, UPDATE_CUSTOM_VIEW } from "store/actions";
import {
  setPaneTopNavViewType,
  TopNavbarType,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import { useAbilityChecker, useGroupContext } from "editor/utils/customHooks";
import navigationApi from "clientApi/navigationApi";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import Conditional from "components/Conditional";
import { ChunkDestination } from "utilities/stateTypes";
import UserDisplay from "clarity-ui/UserDisplay";

const { confirm } = Modal;

interface Props {
  paneId: ChunkDestination;
}

export const showDeleteViewConfirm = (
  id: string,
  baseId: string,
  baseSlug: string,
  paneId: ChunkDestination
) => {
  return confirm({
    title: "Are you sure you want to delete this view?",
    icon: <ExclamationCircleOutlined />,
    content: "",
    maskClosable: true,
    okText: "Delete",

    onOk() {
      deleteCustomView(id).then((res) => {
        store.dispatch({
          type: UPDATE_CUSTOM_VIEW,
          param: {
            type: "delete",
            delta: {},
            id,
          },
        });
        store.dispatch({
          type: REMOVE_VIEW_SIDEEFFECT,
          containerId: id,
          baseId,
        });
        navigationApi.contextBasedNavigate({
          currentPane: paneId,
          navigationChunk: {
            viewName: GeneralViewsNames.Views,
          },
        });
      });
    },
    onCancel() {},
  });
};

const WorkViewList: React.FC<Props> = (props) => {
  const storeData: { [id: string]: CustomWorkView } = useSelector(
    (state: any) => {
      return state.customWork.dict;
    },
    shallowEqual
  );
  const userRole = useSelector(
    (store: ClarityStore) => store.client.roleType,
    shallowEqual
  );
  const [viewList, setviewList] = useState<any[]>([]);
  const base = useSelector((store: ClarityStore) => store.workspace);
  const groupContext = useGroupContext();
  const canEditContainer = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupContext,
  });

  usePageDataSetter(props.paneId, {
    title: "Views",
  });

  useEffect(() => {
    setPaneTopNavViewType({
      paneId: props.paneId,
      navType: TopNavbarType.base,
    });

    return () => {
      setPaneTopNavViewType({
        paneId: props.paneId,
      });
    };
  }, []);

  useEffect(() => {
    setviewList(sortBy(Object.values(storeData), "name"));
  }, [storeData]);

  const documentMenu = (id: string) => {
    return (
      <Menu>
        <Menu.Item key={id} disabled={!canEditContainer}>
          <div
            onClick={(event) =>
              canEditContainer
                ? showDeleteViewConfirm(id, base.id, base.slug, props.paneId)
                : () => {}
            }
          >
            Delete Custom View...
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (cell: any) => (
          <WorkViewCellMain cell={cell} paneId={props.paneId} />
        ),
      },
      {
        Header: "Updated At",
        accessor: "dateUpdated",
        Cell: (cell: any) => {
          if (cell.row.original.dateModified) {
            const date = new Date(cell.row.original.dateModified);
            return (
              <DocumentCellSecondary>
                <span>{`${
                  abbreviatedMonths[date.getMonth()]
                } ${date.getDate()}, ${date.getFullYear()}`}</span>
              </DocumentCellSecondary>
            );
          }
          return (
            <DocumentCellSecondary>
              <span>--</span>
            </DocumentCellSecondary>
          );
        },
      },
      {
        Header: "Created By",
        accessor: "dateCreated",
        width: 144,
        Cell: (cell: any) => {
          return (
            <span>
              <UserDisplay hideAvatar={true} id={cell.row.original.userId} />
            </span>
          );
        },
      },
      {
        Header: "",
        accessor: "menu",
        Cell: (cell: any) => {
          return (
            <div className={pagesListStyles.documentList__cell_handle}>
              <ListItemMenuHandle
                className={pagesListStyles.sixDotHandle}
                triggerArray={["hover"]}
                menuFn={() => documentMenu(cell.row.original.id)}
                menuPlacement={"bottomLeft"}
                draggable={false}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeData, canEditContainer]
  );

  const { headers, getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable(
      {
        columns,
        data: viewList,
        initialState: {
          // @ts-ignore
          sortBy: [{ id: "name", desc: false }],
        },
      },
      useSortBy
    );

  return (
    <>
      <Conditional on={Boolean(userRole !== UserRole.GUEST)}>
        <TopNavFiltersBar paneId={props.paneId}>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            icon={<PlusOutlined />}
            disabled={!canEditContainer}
            onClick={() => {
              const state = store.getState();
              locationSubject.next(`/${state.workspace.slug}/view/new`);
            }}
          >
            View
          </Button>
        </TopNavFiltersBar>
      </Conditional>

      {viewList.length === 0 ? (
        <EmptyState
          buttonFn={() => {
            const state = store.getState();
            locationSubject.next(`/${state.workspace.slug}/view/new`);
          }}
          buttonIcon={<PlusOutlined />}
          buttonLabel="View"
          heading={"Create a view with saved filters"}
          caption={
            "Views you create will automatically stay current as work is updated"
          }
        />
      ) : (
        <>
          <div className={styles.allDocuments}>
            <div className={styles.documentListContainer}>
              <div
                className={pagesListStyles.listViewContainer}
                // className={pagesListStyles.documentList}
                {...getTableProps()}
              >
                <div className={pagesListStyles.header}>
                  {headers.map((column) => {
                    return (
                      <div
                        className={pagesListStyles.headerCol}
                        {...column.getHeaderProps(
                          // @ts-ignore
                          column.getSortByToggleProps()
                        )}
                      >
                        <DocumentListHeader>
                          <span className={documentListHeaderStyles.headerText}>
                            {column.render("Header")}
                          </span>
                          <span className={documentListHeaderStyles.sortCaret}>
                            {column.isSorted ? (
                              <>
                                {column.isSortedDesc ? (
                                  <CaretDownOutlined />
                                ) : (
                                  <CaretUpOutlined />
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </DocumentListHeader>
                      </div>
                    );
                  })}
                </div>

                <div className={pagesListStyles.list} {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <div
                        className={pagesListStyles.col}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <div
                              className={
                                cell.column.id === "menu"
                                  ? pagesListStyles.threeDots
                                  : pagesListStyles.title
                              }
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WorkViewList;
