import * as actionTypes from "store/actions";
import { Block } from "store/reducers/blockReducer";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $focusOn } from "store/storeExporter";
import { ContainerTypes, DocumentModes } from "utilities/types";
import { toggleDocumentMode } from "./blockActions";
import { loadContainerData } from "./containerActions/loadContainerActions";

class BlockActions {
  commentOnBlock(blockId: string, quotedText?: string) {
    const block = getBlockById(store.getState().blocks.dict, blockId);
    let delta: Partial<Block> = {};
    if (block.relatedEntities?.discussion) {
      delta = {
        options: {
          editingDiscussion: true,
          quotedText,
          autofocus: true,
        },
      };
    } else {
      delta = {
        options: {
          creatingDiscussion: true,
          quotedText,
          autofocus: true,
        },
      };
    }
    toggleDocumentMode(block.id, DocumentModes.INSERT);
    this.setBlock(block.id, delta);
  }

  confirmComment(blockId: string) {
    let delta: Partial<Block> = { options: undefined };
    this.setBlock(blockId, delta);
  }

  refocus() {
    store.dispatch({
      type: actionTypes.REFOCUS,
      param: {
        newFocus: {
          ...$focusOn.value,
          caretPosition: $focusOn.value.caretPosition,
        },
      },
    });
  }

  rerenderBlock(blockId: string) {
    const block = getBlockById(store.getState().blocks.dict, blockId);
    block.blockSubject.next(block);
  }

  setBlock(blockId: string, blockDelta: Partial<Block>, noSave?: boolean) {
    store.dispatch({
      type: actionTypes.SAVE_BLOCK_DATA,
      param: {
        id: blockId,
        delta: blockDelta,
      },
    });
  }
}

class BlockTextAnylize {
  getCaretPosition() {
    // blockApi.blockAction.setBlock("setting caret position");
  }
}

class BlockLoading {
  blocksTree(blockIds: string[]) {}
  blocksContainers(
    containers: { containerId: string; containerType: ContainerTypes }[]
  ) {
    for (const container of containers) {
      loadContainerData({
        containerId: container.containerId,
        containerType: container.containerType,
        titleBlock: { current: null },
        titleBlockId: "",
        isPublicAccess: false,
      }).catch();
    }
  }
}

export const blockApi = {
  blockAction: new BlockActions(),
  textAnalyze: new BlockTextAnylize(),
  load: new BlockLoading(),
};
