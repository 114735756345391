import { ProgressBar, ProgressState } from "clarity-ui/WorkProgress";
import navigationApi from "clientApi/navigationApi";
import { locationSubject } from "components/LocationListener";
import React, { useEffect, useMemo, useState } from "react";
import store from "store/storeExporter";
import { getPaneGroupContext } from "utilities/groupUtilities";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import {
  ChildrenAggregate,
  GroupGeneralViews,
  TasksViewModes,
} from "utilities/types";
import styles from "./cyclesView.module.scss";

const CycleRow: React.FC<{ id: string; paneId: ChunkDestination }> = ({
  id,
  paneId,
}) => {
  const cycleObj = useShallowSelector((store) => store.work.sections[id]);
  const progressState = useCycleProgress(id);
  const classNames = useOptionalClassName({
    baseStyle: styles.cycleRow,
    pairs: [
      {
        extraStyle: styles.closed,
        withExtra: cycleObj?.isClosed,
      },
    ],
  });

  const navigate = (e: React.MouseEvent) => {
    const groupId = getPaneGroupContext(paneId) ?? "";

    const group = store.getState().groups.dict[groupId];
    const openInSplit = () => {
      const action = {
        viewName: GroupGeneralViews.CycleEntity,
        containerId: id,
        taskViewMode: TasksViewModes.Cycle,
        groupSlug: group.slug,
      };
      // const cycles =
      //   store.getState().work.groupCycles[groupId].openTimeframeIds;
      // const index = cycles.indexOf(id);
      // if (index === 0) {
      //   action.taskViewMode = TasksViewModes.ActiveCycle;
      // }
      // if (index === 1) {
      //   action.taskViewMode = TasksViewModes.NextCycle;
      // }
      navigationApi.openSplitView({
        ...action,
      });
    };

    const openInMain = (groupId: string) => {
      const baseSlug = store.getState().workspace.slug;
      // const cycles =
      //   store.getState().work.groupCycles[groupId].openTimeframeIds;
      // const index = cycles.indexOf(id);
      // if (index === 0) {
      //   locationSubject.next(`/${baseSlug}/group/${group.slug}/cycles/active`);
      //   return;
      // }
      // if (index === 1) {
      //   locationSubject.next(`/${baseSlug}/group/${group.slug}/cycles/next`);
      //   return;
      // }
      locationSubject.next(`/${baseSlug}/cycle/${id}`);
    };

    if (paneId === ChunkDestination.primary) {
      if (e.shiftKey) return openInSplit();
      return openInMain(groupId);
    } else {
      if (e.shiftKey) return openInMain(groupId);
      return openInSplit();
    }
  };

  return useMemo(() => {
    if (!cycleObj) return <></>;
    return (
      <div className={classNames}>
        <div className={styles.cycleContent}>
          <div className={styles.head}>
            <h4 onClick={navigate} className="body regular ellipsis-nowrap">
              {cycleObj.name}
            </h4>
          </div>
          {/* <h2 className="h2 bold disabled">{cycleObj.workIds.length}</h2> */}
          <div className={styles.tail}>
            <ProgressBar
              type="normal"
              progressState={progressState}
              showAsOwnProgress={true}
            />
          </div>
        </div>
      </div>
    );
  }, [cycleObj, progressState, classNames]);
};

const useCycleProgress = (cycleId: string) => {
  const workDict = useShallowSelector((store) => store.work.dict);
  const cycleObj = useShallowSelector((store) => store.work.sections[cycleId]);

  const [progressState, setprogressState] = useState<ProgressState>({
    done: 0,
    inProgress: 0,
    total: 0,
    unstarted: 0,
  });

  useEffect(() => {
    const progressState: ProgressState = {
      done: 0,
      inProgress: 0,
      total: 0,
      unstarted: 0,
    };
    const statusDict = store.getState().work.statuses.dict;
    const aggregate: ChildrenAggregate[] = [...cycleObj.workIds].map((id) => {
      const item = workDict[id];
      if (!item || !item.statusId) {
        return {
          id,
          open: false,
          statusCategoryId: "4",
          statusId: "",
        };
      }
      return {
        id,
        open: !item.isClosed,
        statusCategoryId: statusDict[item.statusId].categoryId,
        statusId: item.statusId,
      };
    });

    aggregate.forEach((childAggregate) => {
      if (["1", "2"].includes(childAggregate.statusCategoryId)) {
        progressState.total++;
        progressState.unstarted++;
      }
      if (childAggregate.statusCategoryId === "3") {
        progressState.total++;
        progressState.inProgress++;
      }
      if (["4", "5"].includes(childAggregate.statusCategoryId)) {
        progressState.total++;
        progressState.done++;
      }
    });

    setprogressState(progressState);
  }, [workDict, cycleObj.workIds]);

  return progressState;
};

export default CycleRow;
