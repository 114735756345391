import React, { useMemo } from "react";
import { GeneralViewsNames } from "utilities/types";
import SnippetsTwoTone from "icons/Components/SnippetsTwoTone";
import { ScissorOutlined } from "@ant-design/icons";

enum Options {
  templates = "templates",
  snippets = "snippets",
}

const TemplatesNavigationItems: React.FC = () => {
  return <></>;
};

export const useTemplatesNavigationOptions = () => {
  const options = useMemo(() => {
    const possibleOptions: Options[] = [];
    orderedOptions.forEach((option) => {
      possibleOptions.push(option);
    });
    return possibleOptions.map((option) => optionsData()[option]);
  }, []);

  return options;
};

const optionsData = () => {
  return {
    [Options.templates]: {
      key: Options.templates,
      name: () => "Templates",
      icon: <SnippetsTwoTone />,
      url: () => `templates`,
      secondaryViewConfig: () => ({
        viewName: GeneralViewsNames.Templates,
      }),
    },
    [Options.snippets]: {
      key: Options.snippets,
      name: () => "Snippets",
      icon: <ScissorOutlined />,
      url: () => `snippets`,
      secondaryViewConfig: () => ({
        viewName: GeneralViewsNames.Snippets,
      }),
    },
    url: () => `snippets`,
  };
};

const orderedOptions = [Options.templates, Options.snippets];

export default TemplatesNavigationItems;
