import React, { useState, useEffect } from "react";
import moment from "moment";
import { Tooltip } from "antd";

interface Props {
  startTime: Date;
  noTooltip?: boolean;
}

const getMomentDiff = (startTime: Date) => {
  const text = moment(startTime).fromNow();
  switch (text) {
    case "a few seconds ago":
      return "just now";
    default:
      return text;
  }
};

export const getFullTime = (momentDate: moment.Moment) => {
  const reference = moment();
  var today = reference.clone().startOf("day");
  var yesterday = reference.clone().subtract(1, "days").startOf("day");
  const isToday = (momentDate: moment.Moment) => {
    return momentDate.isSame(today, "d");
  };
  const isYesterday = (momentDate: moment.Moment) => {
    return momentDate.isSame(yesterday, "d");
  };

  if (isToday(momentDate)) {
    return "Today at " + momentDate.format("hh:mm A");
  }

  if (isYesterday(momentDate)) {
    return "Yesterday at " + momentDate.format("hh:mm A");
  }

  return momentDate.format("MMM Do") + " at " + momentDate.format("hh:mm A");
};

export const getFullTimeWithYear = (momentDate: moment.Moment) => {
  const reference = moment();
  var today = reference.clone().startOf("day");
  var yesterday = reference.clone().subtract(1, "days").startOf("day");
  const isToday = (momentDate: moment.Moment) => {
    return momentDate.isSame(today, "d");
  };
  const isYesterday = (momentDate: moment.Moment) => {
    return momentDate.isSame(yesterday, "d");
  };

  if (isToday(momentDate)) {
    return "Today at " + momentDate.format("hh:mm A");
  }

  if (isYesterday(momentDate)) {
    return "Yesterday at " + momentDate.format("hh:mm A");
  }

  return (
    momentDate.format("MMM D, YYYY") + " at " + momentDate.format("hh:mm A")
  );
};

const TimePassed: React.FC<Props> = (props) => {
  const [rerender, setrerender] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setrerender(!rerender);
    }, 60000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.noTooltip) {
    return (
      <>
        <span>{getMomentDiff(props.startTime)}</span>
      </>
    );
  }

  return (
    <>
      <Tooltip
        placement="top"
        // style={{ fontSize: "12px" }}
        openClassName={"custom-tooltip"}
        title={getFullTime(moment(props.startTime))}
      >
        <span>{getMomentDiff(props.startTime)}</span>
      </Tooltip>
    </>
  );
};

export default TimePassed;
