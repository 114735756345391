import { Input, Select } from "antd";
import Modal from "clarity-ui/Modal";
import OptionGroup from "clarity-ui/OptionGroup";
import { invitationsApi } from "clientApi/invitationsApi";
import Button, { ButtonTypes } from "components/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import store from "store/storeExporter";
import styles from "../inviteLinkContainer/inviteLinkContainer.module.scss";
import {
  ClockCircleOutlined,
  UserAddOutlined,
  FontColorsOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import "./inviteLinkContainer.scss";
import TokenGateSelector from "clarity-ui/tokenGateContainer/TokenGateSelector";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities } from "utilities/types";
import { PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "index";
const { Option } = Select;

const NewInviteLinkRow: React.FC<{ buttonType?: ButtonTypes }> = ({
  buttonType,
}) => {
  const [addingNewLink, setaddingNewLink] = useState(false);
  const canAddLink = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MEMBERS,
  });

  return (
    <div>
      <Button
        buttonType={buttonType ? buttonType : ButtonTypes.LINK}
        disabled={!canAddLink}
        onClick={() => setaddingNewLink(!addingNewLink)}
      >
        <PlusOutlined /> Link
      </Button>
      {addingNewLink && <NewInviteLink setaddingNewLink={setaddingNewLink} />}
    </div>
  );
};

const NewInviteLink: React.FC<{
  setaddingNewLink: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setaddingNewLink }) => {
  const [creatingNewLink, setCreatingNewLink] = useState(false);
  const linkRef = useRef({
    name: "",
    expire: "",
    numberOfUses: "",
    tokenGateId: undefined,
  });

  const saveLink = useCallback(() => {
    const newLink = linkRef.current;
    setCreatingNewLink(true);
    axiosInstance
      .post("/api/invite-link", {
        baseId: store.getState().workspace.id,
        reedemLimit: newLink.numberOfUses
          ? invitationsApi.useArray[Number(newLink.numberOfUses)]
          : null,
        name: newLink.name ? newLink.name : "",
        endDate: newLink.expire ? invitationsApi.getTime(newLink.expire) : null,
        previousEdit: linkRef.current,
        tokenGateId: newLink.tokenGateId,
      })
      .then((res) => {
        const link = res.data;
        invitationsApi.updateInvitationLink({
          delta: link,
          type: "add",
          id: link.id,
        });
        setCreatingNewLink(false);
        setaddingNewLink(false);
      });
  }, []);

  return (
    <>
      <Modal
        hideModal={() => {
          if (!creatingNewLink) setaddingNewLink(false);
        }}
      >
        <h4>Create new invite link</h4>
        <EditingLink linkRef={linkRef} />
        <Button
          buttonType={ButtonTypes.PRIMARY}
          style={{ float: "right" }}
          disabled={creatingNewLink}
          onClick={saveLink}
        >
          {creatingNewLink ? "Saving..." : "Save"}
        </Button>
      </Modal>
    </>
  );
};

export const EditingLink: React.FC<{
  linkRef: React.MutableRefObject<{
    name: string;
    expire: string;
    numberOfUses: string;
    tokenGateId?: string;
  }>;
}> = ({ linkRef }) => {
  const [newLink, setNewLink] = useState(linkRef.current);

  useEffect(() => {
    linkRef.current = newLink;
  }, [newLink]);

  const onGateSelect = useCallback(
    (gateId?) => {
      setNewLink({ ...newLink, tokenGateId: gateId });
    },
    [newLink]
  );

  const onGateRemove = useCallback(
    (gateId?) => {
      setNewLink({ ...newLink, tokenGateId: undefined });
    },
    [newLink]
  );

  return (
    <>
      <div className={styles.row + " inv-row"}>
        <OptionGroup
          title="Description"
          description="A way to identify this link"
          hideValue={true}
          icon={<FontColorsOutlined />}
          value={""}
        />
        <div className={styles.inputFlex}>
          <Input
            defaultValue={newLink.name}
            autoFocus={true}
            className={`${styles.input}`}
            onChange={(e) => {
              setNewLink({ ...newLink, name: e.target.value });
            }}
          />
        </div>
      </div>
      <div className={styles.row + " inv-row " + styles.flexRow}>
        <OptionGroup
          title="Expires After"
          description="The link cannot be redeemed after expiring"
          icon={<ClockCircleOutlined />}
          hideValue={true}
          value={""}
        />
        <div className={styles.inputFlex} style={{ flexGrow: "unset" }}>
          <Input.Group className="caption secondary">
            <Select
              defaultValue={newLink.expire}
              className={`w-100`}
              dropdownClassName="clr-menu"
              onChange={(e) => {
                setNewLink({ ...newLink, expire: e });
              }}
            >
              <Option value="">Never</Option>
              <Option value="1">30 minutes</Option>
              <Option value="2">1 hour</Option>
              <Option value="3">6 hours</Option>
              <Option value="4">12 hours</Option>
              <Option value="5">1 day</Option>
              <Option value="6">7 days</Option>
            </Select>
          </Input.Group>
        </div>
      </div>
      <div className={styles.row + " inv-row " + styles.flexRow}>
        <OptionGroup
          title="Max Number of Uses"
          description="How many users can redeem this link"
          hideValue={true}
          value={""}
          icon={<UserAddOutlined />}
        />
        <div className={styles.inputFlex} style={{ flexGrow: "unset" }}>
          <Input.Group className="caption secondary">
            <Select
              defaultValue={newLink.numberOfUses}
              dropdownClassName="clr-menu"
              onChange={(e) => {
                setNewLink({ ...newLink, numberOfUses: e });
              }}
            >
              <Option value="">No limit</Option>
              <Option value="1">1 use</Option>
              <Option value="2">5 uses</Option>
              <Option value="3">10 uses</Option>
              <Option value="4">25 uses</Option>
              <Option value="5">50 uses</Option>
              <Option value="6">100 uses</Option>
            </Select>
          </Input.Group>
        </div>
      </div>
      <div
        className={
          styles.row +
          " inv-row " +
          styles.flexRow +
          " " +
          (newLink.tokenGateId ? styles.wrapRow : "")
        }
      >
        <OptionGroup
          title="Token Gate"
          description="Link is shown to those that have these tokens"
          hideValue={true}
          value={""}
          icon={<KeyOutlined />}
        />
        <LinkTokenGate
          onSelect={onGateSelect}
          gateId={newLink.tokenGateId}
          onRemove={() => {
            onGateRemove();
          }}
        />
      </div>
    </>
  );
};

export const LinkTokenGate: React.FC<{
  linkId?: string;
  onSelect?: any;
  gateId?: string;
  noUpdate?: boolean;
  onRemove?: () => void;
}> = ({ linkId, onSelect, gateId, noUpdate, onRemove }) => {
  const [adding, setadding] = useState<boolean>(false);
  const fullOnselect = useCallback(
    (id?: string) => {
      if (onSelect) {
        onSelect(id);
        setadding(false);
      }
    },
    [onSelect]
  );

  return (
    <>
      <TokenGateSelector
        linkId={linkId}
        gateId={gateId}
        onSelect={fullOnselect}
        adding={adding}
        noUpdate={noUpdate}
        setadding={setadding}
        onRemove={onRemove}
      />
      {!gateId && !noUpdate && (
        <Button buttonType={ButtonTypes.LINK} onClick={() => setadding(true)}>
          Add new
        </Button>
      )}
    </>
  );
};

export default NewInviteLinkRow;
