import * as React from "react";
import Big from "big.js";
import { ILineValue } from "./lineUtilities";
import { ChunkDestination } from "./stateTypes";

export enum DocumentModes {
  BLOCK = "Block",
  INSERT = "Insert",
}

export enum WorkTypes {
  INITIATIVE = "Initiative",
  PROJECT = "Project",
  TASK = "Task",
}

export interface ChildrenAggregate {
  id: string;
  open: boolean;
  statusId: string;
  statusCategoryId: string;
}

export interface IServerUsernameStatus {
  info: string;
  status: number;
}

export interface IChangeEmailStatus {
  info: string;
  status: number;
}

export interface IPendingEmailInfo {
  id: string;
  email: string;
}

export interface IWorkSectionObj {
  id: string;
  type: string;
  name: string;
  rank: string;
  isClosed: boolean;
  workIds: string[];
  dateCreated: Date;
  groupId: string;
}

export interface IUserProfileObj {
  id: string;
  username: string;
  name: string;
  bio: string;
  avatar: string;
  dateCreated: string;
  bases: IWorkspaceObj[];
  email: string;
  publicEthAdress?: string;
}

export interface IUserObj {
  id: string;
  username: string;
  name: string;
  email: string;
  bio: string;
  avatar: string;
  dateCreated: string;
  lastLogin: string;
  workspaces: IWorkspaceObj[];
  workspaceIds: string[];
  workspaceEntries: IWorkspaceEntry;
  gettingStartedProgress: number | null;
  verified?: boolean;
  role?: UserRole;
  roleIds?: string[];
  publicEthAdress?: string;
  secondaryWalletAddress?: string | null;
  hasPassword?: boolean;
  showSlashModal?: boolean;
  discordUsername?: string;
}

export interface IWorkspaceEntry {
  [id: string]: IWorkspaceObj;
}

export interface ISubscriptionObj {
  cancel_at_period_end: boolean;
  customerId: string;
  subscriptionId: string;
  productId: string;
  activeSubscription: string;
  subscriptionState: string;
  currentUniqueUsers: number;
  allowedUniqueUsers: number;
  recurring: string;
  paidCustomer: boolean;
  freeTrialProgress: boolean;
  trialEnd: string;
  monthlyCount: {
    blockCount: number;
    workCount: number;
  };
  isFree: boolean;
}

interface Integrations {
  slack: boolean;
  discord: {
    activated: boolean;
    serverName: string;
  };
}

export enum BaseType {
  Public = "public",
  Private = "private",
  Secret = "secret",
}

export interface IWorkspaceObj {
  id: string;
  name: string;
  slug: string;
  tagline: string;
  dateCreated?: Date;
  rank: string;
  ownerAvatar?: string;
  memberCount?: number;
  ownerName?: string;
  isPublic: boolean;
  integrations: Integrations | null;
  showCycles: boolean;
  showWeekly: boolean;
  showContributions: boolean;
  showMilestones: boolean;
  ownerId?: string;
  roleType?: UserRole;
  permissions?: PermissionType;
  avatar?: string;
  home: HomeScreenObj;
  reloadNumber?: number;
  isJoinable?: boolean;
  tokenGateId?: string;
  roleIds?: string[];
  userBaseSettings?: UserBaseSettings;
  coverPhotoUrl?: string;
  type: BaseType;
  discordIntegration: boolean;
}

export enum ContainerVisibility {
  canEdit = "edit",
  canView = "read",
  canComment = "suggest",
  private = "private",
  remove = "remove",
}

export interface HomeScreenObj {
  sectionsDict: HomeSectionDict;
  sectionIds: string[];
}

export interface HomeSectionDict {
  [id: string]: HomeSectionObj;
}

export interface HomeSectionObj {
  id: string;
  title: string;
  resourcesDict: { [id: string]: HomeSectionRow };
  resourcesIds: string[];
}

export enum SectionResourceType {
  InternalLink = "InternalLink",
  OutsideLink = "OutsideLink",
}

export interface HomeSectionRow {
  id: string;
  name: string;
  link: string;
  navigationChunk?: NavigationChunk;
  type: SectionResourceType;
}

export interface IPageObj {
  outlineMode: string;
  id: string;
  name: string;
  nameValue: ILineValue[];
  dateCreated: string | Date;
  dateModified: string;
  mentions: any;
  aliases: any[];
  isPublicAccess: boolean;
  titleBlockId?: string;
}

export enum UserRole {
  OWNER = "Owner",
  MEMBER = "Member",
  GUEST = "Guest",
}
export interface IUserPermissions {
  permissions: Partial<PermissionType>;
  canManageMembers: boolean;
  canManageRoles: boolean;
  canManageRewards: boolean;
  canManagePayments: boolean;
  canManageTokenGates: boolean;
  canManageTokens: boolean;
  canManageCycles: boolean;
  canManageMilestones: boolean;
  canManageHome: boolean;
  canManageBaseSettings: boolean;
  canManageBilling: boolean;
  canManageRoadmap: boolean;
  canEditEntity: boolean;
  canManagePins: boolean;
  canManageInlineThreads: boolean;
  moderateComments: boolean;
  canCommentEntity: boolean;
  canAddTaskComment: boolean;
  canAddBlockCommentInTask: boolean;
  canBeReviewer: boolean;
  allEntityAccess: boolean;
  canRenameBase: boolean;
  canManageReviewers: boolean;
  admin: boolean;
  canDeleteBase: boolean;
  canUpdateBaseAvatar: boolean;
  canManageGroups: boolean;
}

export enum Abilities {
  CAN_MANAGE_MEMBERS = "canManageMembers",
  CAN_MANAGE_ROLES = "canManageRoles",
  CAN_MANAGE_REWARDS = "canManageRewards",
  CAN_MANAGE_PAYMENTS = "canManagePayments",
  CAN_MANAGE_TOKEN_GATES = "canManageTokenGates",
  CAN_MANAGE_TOKENS = "canManageTokens",
  CAN_MANAGE_CYCLES = "canManageCycles",
  CAN_MANAGE_MILESTONES = "canManageMilestones",
  CAN_MANAGE_HOME = "canManageHome",
  CAN_MANAGE_BASE_SETTINGS = "canManageBaseSettings",
  CAN_MANAGE_BILLING = "canManageBilling",
  CAN_MANAGE_ROADMAP = "canManageRoadmap",
  CAN_MANAGE_PINS = "canManagePins",
  CAN_MANAGE_INLINE_THREADS = "canManageInlineThreads",
  MODERATE_COMMENTS = "moderateComments",
  CAN_EDIT_ENTITY = "canEditEntity",
  CAN_COMMENT_ENTITY = "canCommentEntity",
  CAN_ADD_TASK_COMMENT = "canAddTaskComment",
  CAN_ADD_BLOCK_COMMENT_IN_TASK = "canAddBlockCommentInTask",
  CAN_BE_REVIEWER = "canBeReviewer",
  ALL_ENTITY_ACCESS = "allEntityAccess",
  CAN_RENAME_BASE = "canRenameBase",
  CAN_DELETE_BASE = "canDeleteBase",
  CAN_UPDATE_BASE_AVATAR = "canUpdateBaseAvatar",
  CAN_MANAGE_REVIEWER = "canManageReviewers",
  CAN_MANAGE_GROUPS = "canManageGroups",
  ADMIN = "admin",
}

export const abilityToFriendlyName: { [key: string]: string } = {
  canManageMembers: "Manage members",
  canManageRoles: "Manage roles",
  canManageRewards: "Manage rewards",
  canManageReviewers: "Manage reviewers",
  canManagePayments: "Manage payments",
  canManageTokenGates: "Manage token gates",
  canManageTokens: "Manage tokens",
  canManageCycles: "Manage sprints",
  canManageMilestones: "Manage milestones",
  canManageHome: "Manage Home",
  canManageBaseSettings: "Manage Base Settings",
  canManageBilling: "Manage billing",
  canEditEntity: "Modify documents",
  canManagePins: "Manage pins",
  canAddTaskComment: "Reply to task & project threads",
  moderateComments: "Can moderate comments",
  canCommentEntity: "Manage in-line threads",
  // canAddBlockCommentInTask: "Can add block comment in work items",
  canBeReviewer: "Review work",
  canManageGroups: "Manage groups",
  // allEntityAccess: "Can view all pages, notes, work items and custom views",
};

export const abilityCaptionsToFriendlyName: { [key: string]: string } = {
  canManageMembers: "Invite & kick members, manage Join Page & invite links",
  canManageRoles: "Create, modify, and delete roles",
  canManageRewards: "Create & edit rewards on tasks and projects",
  canManageReviewers: "Set the reviewer on tasks & projects. Review work.",
  canManagePayments: "Download CSVs from the Contributions workflow",
  canManageTokenGates: "Create, modify, and delete token gates",
  canManageTokens: "Create, modify and delete base tokens",
  canManageCycles: "Create, modify, and delete cycles",
  canManageMilestones: "Create, modify, and delete milestones",
  canManageHome: "Modify sections & links on the Home screen",
  canManageBaseSettings:
    "Modify base settings, work statuses, and integrations",
  canManageBilling: "Manage the pricing plan and billing details",
  canEditEntity: "Create, edit, and delete notes, pages, tasks, and projects",
  canManagePins: "Pin/unpin documents to the sidebar. Reorder pins.",
  canAddTaskComment: "Add comments to the bottom of tasks & projects",
  moderateComments: "Delete comments that were not created by you",
  canCommentEntity: "Add comments to blocks inside documents",
  // canAddBlockCommentInTask: "Can add block comment in work items",
  canBeReviewer: "Can be set as a reviewer of a task or project",
  canManageGroups: "Manage groups in this base",
  // allEntityAccess: "Can view all pages, notes, work items and custom views",
};

export const nonManagableAbilities = [
  "admin",
  "canRenameBase",
  "canDeleteBase",
  "canUpdateBaseAvatar",
];

export const managableAbilities = [
  "canCommentEntity",
  "canAddTaskComment",
  "canEditEntity",
  "canManagePins",
  "canManageTokenGates",
  "canManageHome",
  "canManageCycles",
  "canManageMilestones",
  "canBeReviewer",
  "canManageReviewers",
  "canManageRewards",
  "canManagePayments",
  "canManageTokens",
  "moderateComments",
  "canManageBaseSettings",
  "canManageBilling",
  "canManageRoles",
  "canManageMembers",
  "canManageGroups",
];

export enum RoleTypes {
  PERSONAL = "personal",
  TEAM = "team",
  GROUP = "group",
  DEFAULT = "default",
  CUSTOM = "custom",
}

export interface IRole {
  id: string;
  roleName: string;
  description: string;
  permissions: Partial<IUserPermissions>;
  baseId: string;
  roleType: RoleTypes;
  rank: string;
  selfAssignable: boolean;
  tokenGateId?: string | null;
}

export type PermissionType = {
  [primitive in PrimitiveScopes]: {
    [permissionType in PermissionTypes]: string[];
  };
};

export enum PermissionTypes {
  READ = "read",
  COMMENT = "comment",
  SUGGEST = "suggest",
  EDIT = "edit",
  CREATE = "create",
  NO_EDIT = "noEdit",
  DELETE = "delete",
  NO_DELETE = "noDelete",
  NO_CREATE = "noCreate",
  NO_READ = "noRead",
}

export enum PrimitiveScopes {
  PAGES = "pages",
  WORK = "work",
  NOTES = "notes",
}

export interface IContributon {
  userId: string;
  amount: number;
  reason: "assignee" | "contributor";
}

export enum SplitType {
  currency = "currency",
  percentage = "percentage",
}
export interface IReward {
  id: string;
  type: TokenTypes;
  contractAddress?: string;
  symbol?: string;
  tokenName: string;
  contributors: IContributon[];
  amount: number;
  tokenIds?: string;
  tokenId: string;
  networkId?: number;
  reviewerId?: string;
  isPublic: boolean;
  isPaid: boolean;
  isAllocationConfirmed: boolean;
  splitType: SplitType;
  tokenUsdPrice?: number | null;
  datePaid?: Date | null;
  baseId: string;
  sponsorGroupId?: string;
}

export interface IProjectObj {
  id: string;
  name: string;
  nameValue: ILineValue[];
  titleBlockId: string;
  tagsBlockId: string | null;
  statusId?: string;
  priority: number;
  folded?: boolean | null;
  dateCreated: Date;
  isDeleted: boolean;
  projectId: number;
  isClosed: boolean;
  isDone: boolean;
  dateClosed?: Date;
  dateModified?: Date;
  isPublicAccess?: boolean;
  mentions?: any;
  citations?: any;
  assignee?: IUserObj;
  assigneeId?: string | null;
  reviewerId?: string | null;
  authorId: string;
  dueDate?: Date | null;
  parentId: string | null;
  workType: WorkTypes;
  workActivities: string[];
  workSectionId?: string | null;
  milestoneId?: string | null;
  tags?: ITag[];
  outlineMode?: string;
  childrenAggregate: ChildrenAggregate[];
  reward?: IReward | null;
  contributorIds: string[];
  projectMetadata?: {
    contributorIds: string[];
    milestoneId?: string;
    groupIds: string[];
  };
  groupId: string;
  groupIds?: string[];
  discordConnected?: boolean;
  discordThreadLink?: boolean;
  requests?: RequestsStatus;
  workspaceId: string;
}

export interface IProjectCreate {
  name: string;
  noFirstBlock: boolean;
  presets: Partial<IProjectObj>;
  groupId: string;
  presetTitleBlock?: any;
}

export interface RequestsStatus {
  activeReviewRequest: boolean;
  reviewRequestCount: number;
  activeClaimRequests: string[];
}

export interface ITemplateObj {
  id: string;
  name: string;
  nameValue: ILineValue[];
  workType: WorkTypes;
  dateCreated: Date;
  statusId: string;
  priority: number;
  authorId: string;
  childrenAggregate: ChildrenAggregate[];
  dateModified: Date;
  groupId: string;
  isRoot?: boolean;
  tagsBlockId?: string;
  titleBlockId?: string;
  outlineMode?: string;
  isClosed?: boolean;
  isDone?: boolean;
  dateClosed?: Date;
  isDeleted?: boolean;
  deletedAt?: Date;
  isPublicAccess?: boolean;
  dueDate?: Date | null;
  assigneeId?: string | null;
  reviewerId?: string | null;
  parentId?: string;
  workspaceId: string;
  milestoneId?: string | null;
  tags?: ITag[];
  reward?: IReward | null;
  rewardId?: string;
  contributorIds?: string[];
  groupIds?: string[];
}

export interface ISnippetObj {
  id: string;
  name: string;
  description?: string;
  isShared: boolean;
  authorId?: string;
  workspaceId: string;
  outlineMode: string;
  dateCreated: Date;
  dateModified?: Date | null;
  isDeleted?: boolean;
  deletedAt?: Date;
  isPublicAccess?: boolean;
}

export interface ITag<CT = ContainerTypes.DOCUMENT | ContainerTypes.WORK> {
  containerId: string;
  containerType: CT;
}

export interface IWorkActivity {
  id: string;
  type: WorkActivityTypes;
  dateCreated: Date | null;
  dateModified: Date | null;
  isDeleted: boolean;
  authorId: string | null;
  author: IUserObj | null;
  taskId: string;
  blocksState: any;
  linesArray?: any;
  delta?: WorkActivityDelta | null;
}

export enum DeltaType {
  RENAME = "Rename",
  STATUS = "Status",
  PRIORITY = "Priority",
  ASSIGNE = "Assigne",
  DUE_DATE = "Due Date",
  CYCLE = "Cycle",
  PROJECT = "Project",
  TAGS = "Tags",
  CONTRIBUTORS = "Contributors",
  REWARD = "Reward",
  REVIEWER = "Reviewer",
  REVIEW = "Review",
  CREATED = "Created",
  CLAIM = "Claim",
}

export enum DeltaAction {
  CHANGED = "Changed",
  ADD = "Add",
  REMOVE = "Remove",
  PENDING = "Pending",
  ACCEPTED = "Accepted",
  DENIED = "Denied",
}

export interface WorkActivityDelta {
  type: DeltaType;
  action?: DeltaAction;
  isPaid?: boolean;
  ready?: boolean;
  metadata?: {
    authorId?: string | null;
    workType?: string | null;
    previousValue?: string | any;
    nextValue?: string | any;
  };
}

export interface IWorkStatus {
  composedRank: string;
  id: string;
  name: string;
  categoryId: string;
  rank: string;
  dateCreated: Date;
  baseId: string;
}

export interface Citation {
  id: string;
  sourceBlockId: string;
  sourceContainerId: string;
  sourceContanierType: ContainerTypes;
  referencingBlockId: string;
  referencingContainerId: string;
  referencingContainerType: ContainerTypes;
  sourceBlockBaseId: string;
  dateCreated: Date;
  dateAccessed: Date;
  dateModified: Date;
  synchronize: boolean;
  isDeleted?: boolean;
  deletedAt?: Date;
}

export interface CitationData {
  citationId: string;
  sourceBlockId: string;
  dateAccessed: Date;
  referencingBlockId: string;
  dateModified: Date;
  referencingContainerId: string;
  referencingContainerType: ContainerTypes;
  sourceBlockBaseId: string;
  value: ILineValue[];
  containerId: string;
  containerType: ContainerTypes;
  synchronize: boolean;
  isDeleted?: boolean;
}

export enum WorkActivityTypes {
  COMMENT = "Comment",
  MENTION = "Mention",
  METADATA_CHANGE = "Metadata Change",
  WORK_ITEM_CREATED = "Work Item created",
  REVIEW_REQUEST = "Review Request",
  CLAIM_REQUEST = "Claim Request",
  REVIEW_REMINDER = "Review Reminder",
  REVIEW_REQUEST_ACCEPTED = "Review Request Accepted",
}

export enum UserInvitationStatus {
  INVITED_MEMBER = "INVITED_MEMBER",
  ALREADY_MEMBER = "ALREADY_MEMBER",
  NON_MEMBER = "NON_MEMBER",
  GUEST = "GUEST",
}

export interface IPartialUser {
  id: string;
  name: string;
  username: string;
  avatar: string;
}

export enum ContainerTypes {
  DOCUMENT = "Document",
  PROJECT = "Project",
  TASK = "Task",
  RESOURCE = "Resource",
  NOTE = "Note",
  WORK = "Work",
  WORK_ACTIVITY = "Work_Activity",
  CUSTOM_VIEW = "CustomView",
  CYCLE = "Cycle",
  TEMPLATE = "Template",
  SNIPPET = "Snippet",
}

export const ContainerTypeToPrimitive: any = {
  [ContainerTypes.NOTE]: "notes",
  [ContainerTypes.DOCUMENT]: "pages",
  [ContainerTypes.PROJECT]: "work",
  [ContainerTypes.WORK]: "work",
  [ContainerTypes.TASK]: "work",
};

export interface IPinObj {
  id: string;
  rank: string;
  entityId: string;
  baseId: string;
  entityType: ContainerTypes;
}

export interface IResourceObj {
  id: string;
  name: string;
  type: ResourceType;
  url: string;
  caption: string;
  dateCreated: Date;
  dateModified: Date;
  isDeleted: boolean;
}

export interface INotesObj {
  id: string;
  dateCreated: string | Date;
  dateModified?: string | Date;
  titleBlockId: string;
  createdById: string;
  linesArray: any[];
  blocksState: any;
  workspaceId: string;
  outlineMode: string;
  isWeekly: boolean;
  isPublicAccess?: boolean;
  citations: any;
  groupId?: string;
  title?: string;
  lowerCaseTitle?: string;
}

export interface ILabelsObj {
  id: string;
  name: string;
  background: string | null;
  dateCreated: Date;
  isDeleted: boolean;
  deletedAt: Date | null;
  baseId: string;
  createdById: string;
}

enum DiscussionType {
  COMMENT = "COMMENT",
  SUGGESTION = "SUGGESTION",
}

export enum ReplyType {
  TEXT = "text",
  RESOLVE = "resolve",
  REOPEN = "reopen",
}

export interface Reply {
  id: string;
  value: ILineValue[];
  type: ReplyType;
  dateCreated: Date;
  dateModified: Date;
  discussionId: string;
  isRootOfDiscussion: boolean;
  orderInDiscussion: number;
  quotedText?: string;
  authorId: string;
  isDeleted?: boolean;
  deletedAt?: Date;
}
export interface InlineDiscussion {
  id: string;
  type: DiscussionType;
  dateCreated: Date;
  dateModified: Date;
  blockId: string;
  selectedText: string;
  containerId: string;
  containerType: ContainerTypes;
  replies: Reply[];
  isResolved: boolean;
  isDeleted?: boolean;
  inlineDiscussion?: boolean;
  deletedAt?: Date;
}

export interface ICollaborator {
  id: string;
  role: UserRole;
  roleIds: string[];
  user: IUserObj;
}

export interface IPendingCollaborator {
  id: string;
  dateCreated: string;
  invited: IUserObj;
  invitedEmail: string;
  sentBy: IUserObj;
}

export enum ResourceType {
  IMAGE = "Image",
  PDF = "PDF",
  PDF_LINK = "PDF-LINK",
  URL = "URL",
}

export enum IDocumentCreationMethods {
  SCRATCH = "SCRATCH",
  TAG = "TAG",
}

export enum BucketAlbums {
  AVATAR = "avatar",
  BASE_AVATAR = "baseAvatar",
  TOKEN = "token",
  GROUPCOVER = "groupCover",
  BASECOVER = "baseCover",
}

export interface ProjectMentionResponse {
  containerType: ContainerTypes;
  containerId: string;
}

export enum CommandPaletteContext {
  GENERAL = "General",
  OPEN = "Open",

  WORK_SELECTED = "Work_Selected",
  WORK_VIEW = "Work_View",
  MULTIPLE_BLOCKS_SELECTED_IN_PROJECT = "Multiple Blocks Selected in Project",
  MULTIPLE_BLOCKS_SELECTED_IN_DOCUMENT = "Multiple Blocks Selected in Document",
  MULTIPLE_BLOCKS_SELECTED_IN_COMMENT = "Multiple Blocks Selected in Comment",
  TURN_INTO = "Turn Into",
  MOVE_TO = "MOVE_TO",
  PROJECT_DETAIL = "Project Detail",
  FILTERS_GENERAL = "FiltersGeneral",

  //properties
  ASSIGNEE = "Assignee",
  REVIEWER = "Reviewer",
  STATUS = "Status",
  PRIORITY = "Priority",
  DUE_DATE = "Due Date",
  PARENT = "Parent",
  WORK_SECTION = "Work Section",
  CONTRIBUTORS = "Contributors",
  SELECT_GROUP = "SelectGroup",
  SELECT_MULTIPLE_GROUPS = "SelectMultipleGroups",
  SELECT_SPONSOR_GROUP = "SelectSponsorGroup",
  MILESTONE = "Milestone",

  // filters
  FILTER_ASSIGNEE = "FilterAssignee",
  FILTER_ASSIGNEE_NOT = "FilterAssigneeNot",
  FILTER_LABEL = "FilterLabel",
  FILTER_LABEL_NOT = "FilterLabelNot",
  FILTER_DUE_AFTER_DATE = "FilterAfterDate",
  FILTER_DUE_BEFORE_DATE = "FilterBeforeDate",
  FILTER_DUE_EXACTLY_DATE = "FilterExactlyDate",
  FILTER_DUE_NOT_DATE = "FilterNotDate",
  FILTER_CREATED_AFTER_DATE = "FilterCreatedAfterDate",
  FILTER_CREATED_BEFORE_DATE = "FilterCreatedBeforeDate",
  FILTER_CREATED_EXACTLY_DATE = "FilterCreatedExactlyDate",
  FILTER_CREATED_NOT_DATE = "FilterCreatedNotDate",
  FILTER_CLOSED_AFTER_DATE = "FilterClosedAfterDate",
  FILTER_CLOSED_BEFORE_DATE = "FilterClosedBeforeDate",
  FILTER_CLOSED_EXACTLY_DATE = "FilterClosedExactlyDate",
  FILTER_CLOSED_NOT_DATE = "FilterClosedNotDate",
  FILTER_PRIORITY = "FilterPriority",
  FILTER_PRIORITY_NOT = "FilterPriorityNot",
  FILTER_CYCLE = "FilterCycle",
  FILTER_CYCLE_NOT = "FilterCycleNot",
  FILTER_PARENT = "FilterParent",
  FILTER_PARENT_NOT = "FilterParentNot",
  FILTER_STATUS = "FilterStatus",
  FILTER_STATUS_NOT = "FilterStatusNot",
  FILTER_GROUP = "FilterGroup",
  FILTER_GROUP_NOT = "FilterGroupNot",
  HAS_REWARD = "HasReward",
}

export type CommandPaletteDateFilterContextTypes =
  | CommandPaletteContext.FILTER_DUE_BEFORE_DATE
  | CommandPaletteContext.FILTER_DUE_AFTER_DATE
  | CommandPaletteContext.FILTER_DUE_EXACTLY_DATE
  | CommandPaletteContext.FILTER_DUE_NOT_DATE
  | CommandPaletteContext.FILTER_CREATED_BEFORE_DATE
  | CommandPaletteContext.FILTER_CREATED_AFTER_DATE
  | CommandPaletteContext.FILTER_CREATED_EXACTLY_DATE
  | CommandPaletteContext.FILTER_CREATED_NOT_DATE
  | CommandPaletteContext.FILTER_CLOSED_BEFORE_DATE
  | CommandPaletteContext.FILTER_CLOSED_AFTER_DATE
  | CommandPaletteContext.FILTER_CLOSED_EXACTLY_DATE
  | CommandPaletteContext.FILTER_CLOSED_NOT_DATE;

export type CommandPaletteContextSelectionsTypes =
  | CommandPaletteContext.FILTER_ASSIGNEE
  | CommandPaletteContext.FILTER_ASSIGNEE_NOT
  | CommandPaletteContext.FILTER_LABEL
  | CommandPaletteContext.FILTER_LABEL_NOT
  | CommandPaletteContext.FILTER_GROUP
  | CommandPaletteContext.FILTER_GROUP_NOT
  | CommandPaletteContext.FILTER_PRIORITY
  | CommandPaletteContext.FILTER_PRIORITY_NOT
  | CommandPaletteContext.FILTER_CYCLE
  | CommandPaletteContext.FILTER_CYCLE_NOT
  | CommandPaletteContext.FILTER_STATUS
  | CommandPaletteContext.FILTER_STATUS_NOT
  | CommandPaletteContext.FILTER_PARENT
  | CommandPaletteContext.FILTER_PARENT_NOT
  | CommandPaletteContext.HAS_REWARD;

export type CommandPaletteFilterContextTypes =
  | CommandPaletteDateFilterContextTypes
  | CommandPaletteContextSelectionsTypes;

export type CommandPalettePropertiesContextTypes =
  | CommandPaletteContext.ASSIGNEE
  | CommandPaletteContext.CONTRIBUTORS
  | CommandPaletteContext.DUE_DATE
  | CommandPaletteContext.MILESTONE
  | CommandPaletteContext.PARENT
  | CommandPaletteContext.REVIEWER
  | CommandPaletteContext.PRIORITY
  | CommandPaletteContext.STATUS
  | CommandPaletteContext.WORK_SECTION
  | CommandPaletteContext.SELECT_GROUP
  | CommandPaletteContext.SELECT_MULTIPLE_GROUPS
  | CommandPaletteContext.SELECT_SPONSOR_GROUP;

export type CommandPaletteType =
  | CommandPalettePropertiesContextTypes
  | CommandPaletteFilterContextTypes;

export enum RewardFilter {
  HAS_REWARD = "hasReward",
  HAS_NOT_REWARD = "hasNotReward",
  IS_APPROVED = "isApproved",
  IS_NOT_APPROVED = "isNotApproved",
  IS_PAID = "isPaid",
  IS_NOT_PAID = "isNotPaid",
}

export enum EntityTypes {
  DOCUMENT = "Document",
  PROJECT = "Project",
  RESOURCE = "Resource",
  BLOCK = "Block",
  TASK = "Task",
}

export enum MainViewTypes {
  PAGE_DETAIL = "Page Detail",
  PAGES = "Pages",
  PROJECT_DETAIL = "Project Detail",
  TASK_DETAIL = "Task Detail",
  PROJECTS = "Projects",
  WORK = "Work",
  NOTE_DETAIL = "Note Detail",
  NOTES = "Notes",
  BASE_SETTINGS = "Base Settings",
  USER_PROFILE = "User Profile",
}

export interface IPatchObj {
  op: string;
  path: string;
  value: any;
}

export enum BlockMenuTypes {
  slashMenu = "slashMenu",
  tagsMenu = "tagsMenu",
  linkEntityMenu = "linkEntityMenu",
  userMentions = "userMentions",
  widget = "widget",
  snippetMenu = "snippetMenu",
}
export interface BlockStateMenu {
  isOpened: boolean;
  isAtBegining: boolean;
  hoveredItem: number;
  filterMenuBy: string;
  type: BlockMenuTypes;
  executeSelection: boolean;
  preOpenedCaretPosition: number;
  manualReset: boolean;
  options?: {
    hashtag?: boolean;
    entityType?: any;
  };
}

export enum UserEventTypes {
  ALL_CYCLES_VIEWED = "All Cycles Viewed",
  ALL_TASKS_VIEWED = "All Tasks Viewed",
  MY_TASKS_VIEWED = "My Tasks Viewed",
  PROJECT_ARCHIVE_VIEWED = "Project Archive Viewed",
  TASK_ARCHIVE_VIEWED = "Task Archive Viewed",
  CURRENT_TASKS_VIEWED = "Current Tasks Viewed",
  SOMEDAY_TASKS_VIEWED = "Someday Tasks Viewed",
  ACTIVE_CYCLE_VIEWED = "Active Cycle Viewed",
  NEXT_CYCLE_VIEWED = "Next Cycle Viewed",
  ALL_PROJECTS_VIEWED = "All Projects Viewed",
  ALL_NOTES_VIEWED = "All Notes Viewed",
  ALL_PAGES_VIEWED = "All Pages Viewed",
  PAGE_VIEWED = "Page Viewed",
  PAGE_MENTION_CREATED = "Page Mention Created",
  PAGE_SHARE_COPY_LINK_CLICKED = "Page Share Copy Link Clicked",
  PAGE_SHARE_OPTIONS_VIEWED = "Page Share Options Viewed",
  NOTE_SHARE_OPTIONS_VIEWED = "Note Share Options Viewed",
  NOTE_SHARE_COPY_LINK_CLICKED = "Note Share Copy Link Clicked",
  PROJECT_VIEWED = "Project Viewed",
  PROJECT_SHARE_COPY_LINK_CLICKED = "Project Share Copy Link Clicked",
  PROJECT_SHARE_OPTIONS_VIEWED = "Project Share Options Viewed",
  NOTE_VIEWED = "Note Viewed",
  TASK_VIEWED = "Task Viewed",
  TASK_SHARE_OPTIONS_VIEWED = "Task Share Options Viewed",
  TASK_SHARE_COPY_LINK_CLICKED = "Task Share Copy Link Clicked",
  WORK_MENTION_CREATED = "Work Mention Created",
  BLOCK_OPERATION = "Block Operation",
  COMMAND_PALETTE_FILTER_ASSIGNEE = "Command Palette Filter Assignee",
  COMMAND_PALETTE_FILTER_LABEL = "Command Palette Filter Label",
  COMMAND_PALETTE_FILTER_DUE_AFTER_DATE = "Command Palette Filter Due After Date",
  COMMAND_PALETTE_FILTER_DUE_BEFORE_DATE = "Command Palette Filter Due Before Date",
  COMMAND_PALETTE_FILTER_CLOSED_AFTER_DATE = "Command Palette Filter Closed After Date",
  COMMAND_PALETTE_FILTER_CLOSED_BEFORE_DATE = "Command Palette Filter Closed Before Date",
  COMMAND_PALETTE_FILTER_CREATED_AFTER_DATE = "Command Palette Filter Created After Date",
  COMMAND_PALETTE_FILTER_CREATED_BEFORE_DATE = "Command Palette Filter Created Before Date",
  COMMAND_PALETTE_CHANGE_PARENT = "Command Palette Change Parent",
  COMMAND_PALETTE_WORK_SECTION = "Command Palette Work Section",
  COMMAND_PALETTE_DUE_DATE = "Command Palette Due Date",
  COMMAND_PALETTE_OPEN = "Command Palette Open",
  COMMAND_PALETTE_MOVE_TO = "Command Palette Move To",
  COMMAND_PALETTE_TURN_INTO = "Command Palette Turn Into",
  COMMAND_PALETTE_GENERAL = "Command Palette General",
  COMMAND_PALETTE_ASSIGNEE = "Command Palette Assignee",
  NOTIFICATION_INBOX_VIEWED = "Notification Inbox Viewed",
  NOTIFICATION_DONE_VIEWED = "Notification Done Viewed",
  NOTIFICATION_MARKED_DONE = "Notification Marked Done",
  NOTIFICATION_MARKED_NOT_DONE = "Notification Marked Not Done",
  NOTIFICATION_READ = "Notification Read",
  BLOCK_AUTOFIX_EXECUTED = "Block autofix executed",
  WEEKLY_WORK_VIWEWED = "Weekly work viewed",
  TOKEN_GATE_ENCOUNTERED = "Token Gate Encountered",
  WHAT_IS_SIWE_VIEWED = "What is SIWE Viewed",
  TOKEN_GATE_WALLET_CONNECTED = "Token Gate Wallet Connected",
  TOKEN_GATE_PASSED = "Token Gate Passed",
  TOKEN_GATE_REJECTED = "Token Gate Rejected",
  SIGNED_UP_BY_WALLET_VIRAL = "Signed up by Wallet (Viral)",
  ANONYMOUS_PERMISSIONS_CHANGED = "Anonymous Permissions Changed",
  PUBLIC_SIGN_IN_PROMPTED = "Public Sign-in Prompted",
  SIGNED_UP_BY_PUBLIC_PROMPT_VIRAL = "Signed up by Public Prompt (Viral)",
}

interface WorkCategories {
  [id: string]: {
    id: string;
    name: string;
    default: string;
    rank: string;
  };
}

export interface ITasksViewDetail {
  pageData: any;
  mode: TasksViewModes;
  filters?: IFilterState;
  viewId?: string;
  context?: string;
  hidePaneHeader?: boolean;
  extraBtns?: any[];
  viewSettings?: {
    viewAs?: ViewAsTypes;
    orderBy?: OrderTypes;
    groupBy?: GroupTypes;
    parents?: string[];
  };
  container?: any;
  containerType?: ContainerTypes;
}

export enum TasksViewModes {
  Tasks = "Tasks",
  MyTasks = "MyTasks",
  MyProjects = "MyProjects",
  DueThisWeek = "DueThisWeek",
  CurrentTasks = "CurrentTasks",
  ActiveCycle = "ActiveCycle",
  NextCycle = "NextCycle",
  Cycle = "Cycle",
  Someday = "Someday",
  TasksArchive = "TasksArchive",
  NewCustomView = "NewCustomView",
  CustomView = "CustomView",
  ProjectWork = "ProjectWork",
  OpenCycles = "OpenCycles",
  ClosedCycles = "ClosedCycles",
  UnclaimedContributions = "UnclaimedContributions",
  UnstartedContributions = "UnstartedContributions",
  InProgressContributions = "InProgressContributions",
  AwitingRewardApprovalContributions = "AwitingRewardApprovalContributions",
  ApprovedForPaymentContributions = "ApprovedForPaymentContributions",
  PaidContributions = "PaidContributions",
  Reviewing = "Reviewing",
}

export interface IBlockContext {
  id: string;
  type: "container" | "mention";
  container: {
    id: string;
    type: ContainerTypes;
    outlineMode?: string;
    zoomedFullNote?: boolean;
    referenceTaskId?: string;
  };
  zoomedBlockId?: string | null;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  paneId: ChunkDestination;
  complexId?: string;
  online: boolean;
  autosave: boolean | undefined;
  persistToggle: boolean;
  canEdit: boolean;
  canComment: boolean;
  canChangeCheckbox?: boolean;
  showInterceptor?: boolean;
  portalDetails?: {
    isPortal: boolean;
    portalType: "mention" | "citation";
    zoomedBlockIcon?: any;
  };
}

export const workStatusCategories: WorkCategories = {
  "1": {
    id: "1",
    name: "Someday",
    default: "Someday",
    rank: "0ca",
  },
  "2": {
    id: "2",
    name: "Unstarted",
    default: "To do",
    rank: "0b",
  },
  "3": {
    id: "3",
    name: "In Progress",
    default: "In Progress",
    rank: "0a",
  },
  "4": {
    id: "4",
    name: "Completed",
    default: "Done",
    rank: "0d",
  },
  "5": {
    id: "5",
    name: "Canceled",
    default: "Canceled",
    rank: "0e",
  },
};
export const workStatusCategoriesOrder = ["3", "2", "1", "4", "5"];
export const priorities = ["No priority", "Low", "Medium", "High", "Urgent"];

export const getPriorityName = (priorityLevel: number) => {
  if (priorityLevel >= 1 && priorityLevel <= 5)
    return priorities[priorityLevel - 1];
  else return "None";
};

export enum VerificationTypes {
  NEW_USER = "New User",
  CHANGE_EMAIL = "Change Email",
  PASSWORD_RESET = "Password Reset",
}

export enum NotificationTypes {
  TASK_COMMENT_CREATED = "Task Comment Created",
  WORK_OPENED_AND_ASSIGNED = "Work Opened And Assigned",
  WORK_ASSIGNEE_CHANGED = "Work Assignee Changed",
  WORK_PRIORITY_CHANGED = "Work Priority Changed",
  WORK_STATUS_CHANGED = "Work Status Changed",
  WORK_CLOSED = "Work Closed",
  WORK_REOPENED = "Work Reopened",
  WORK_DEADLINE_CHANGED = "Work Deadline Changed",
  WORK_TIMEFRAME_CHANGED = "Work Timeframe Changed",
  WORK_LABELS_CHANGED = "Work Labels Changed",
  WORK_RENAMED = "Work Renamed",
  WORK_DELETED = "Work Deleted",
  BLOCK_DISCUSSION_CREATED = "Block Discussion Created",
  BLOCK_DISCUSSION_RESOLVED = "Block Discussion Resolved",
  BLOCK_DISCUSSION_REPLY_CREATED = "Block Discussion Reply Created",
  BLOCK_DISCUSSION_REOPENED = "Block Discussion Reopened",
  BLOCK_USER_MENTIONED = "Block User Mentioned",
}

export interface InAppNotification {
  id: string;
  type: NotificationTypes;
  value: string;
  dateCreated: Date;
  baseId: string;
  reciepentId: string;
  entityId: string;
  entityType: EntityTypes;
  projectId: string;
}

export enum GroupTypes {
  none = "none",
  assignee = "assigneeId",
  cycle = "workSectionId",
  priority = "priority",
  project = "parentId",
  status = "statusId",
  weekDue = "weekDue",
  weekCreated = "weekCreated",
  weekClosed = "weekClosed",
}

export enum OrderTypes {
  dueDate = "dueDate",
  closeDate = "closeDate",
  createDate = "createDate",
  priority = "priority",
  status = "statusId",
}

export enum ListedWorkItems {
  tasks = "Tasks",
  projects = "Projects",
  initiatives = "Initiatives",
  all = "All",
}

export enum ViewAsTypes {
  list = "list",
  board = "board",
}

export interface CustomWorkView {
  id: string;
  name: string;
  description: string;
  baseId: string;
  userId: string;
  groupBy: GroupTypes;
  orderBy: OrderTypes;
  viewAs: ViewAsTypes;
  filters: IFilterState;
  showActiveProjects: boolean;
  shared: boolean;
  dateCreated: Date;
  dateModified: Date;
  isDeleted: boolean;
  deletedAt?: string;
}

export interface IThreadView {
  id: string;
  baseId: string;
  containerId: string;
  containerType: ContainerTypes;
  threadLink: string;
  threadName: string;
  threadValue: string;
  dateUpdated: Date;
  dateCreated: Date;
  participantIds: string[];
  reason: string;
  done: boolean;
  read: boolean;
  mentionCount: number;
  referenceId: string;
}

export interface IFilterState {
  assignees: string[];
  excludedAssignees: string[];
  labels: string[];
  excludedLabels: string[];
  dueDate: {
    before: null | string;
    after: null | string;
    exactly: null | string;
    not: null | string;
    isWeekView: null | boolean;
    isOverdue: null | boolean;
  };
  closedDate: {
    before: null | string;
    after: null | string;
    exactly: null | string;
    not: null | string;
    isWeekView: null | boolean;
  };
  createdDate: {
    before: null | string;
    after: null | string;
    exactly: null | string;
    not: null | string;
    isWeekView: null | boolean;
  };
  statuses: string[];
  excludedStatuses: string[];
  groups: string[];
  excludedGroups: string[];
  sponsorGroup: string | null;
  priorities: number[];
  excludedPriorities: number[];
  cycles: string[];
  excludedCycles: string[];
  parents: string[];
  excludedParents: string[];
  hasActiveFilters: boolean;
  type?: WorkTypes[];
  hasReward?: RewardFilter;
  isDone?: boolean;
  reviewers: string[];
  isClosed?: boolean;
}

export type IFilterKey = keyof Pick<
  IFilterState,
  | "assignees"
  | "excludedAssignees"
  | "labels"
  | "excludedLabels"
  | "statuses"
  | "excludedStatuses"
  | "priorities"
  | "excludedPriorities"
  | "cycles"
  | "excludedCycles"
  | "parents"
  | "excludedParents"
  | "hasReward"
  | "groups"
  | "excludedGroups"
>;

export interface WeeklyWidgetContext {
  mondayDate: string | undefined;
}

export enum EmailNotifications {
  NONE = "None",
  MENTIONS = "Mentions",
  ALL = "All",
}

export interface UserSettings {
  emailNotifications: EmailNotifications;
  lastUnreadNote: { [groupId: string]: Date };
}

export interface UserBaseSettings {
  id: any;
  settings: UserSettings;
  workspaceId: string;
  myTasksState: any;
}

export interface IInviteLink {
  id: string;
  token: string;
  endDate: Date | null;
  currentCount: number;
  totalCount: number;
  reedemLimit: number | null;
  retired: boolean;
  name: string;
  default: boolean;
  createdBy: string;
  createdAt: Date;
  previousEdit: any;
  deleted?: boolean;
  tokenGateId?: string | null;
}

export enum TokenTypes {
  ERC20 = "erc-20",
  ERC1155 = "erc-1155",
  ERC721 = "erc-721",
  MANUAL = "manual",
  NATIVE_CURRENCY = "native-currency",
  MOLOCH = "moloch",
}
export interface ITokenRequirement {
  type: TokenTypes;
  contractAddress: string;
  symbol: string;
  name: string;
  ammount: number;
  id?: string;
  tokenIds?: string;
  networkId: number;
  icon?: string;
}

export interface IToken {
  type: TokenTypes;
  contractAddress: string;
  symbol: string;
  name: string;
  id: string;
  networkId: number;
  icon?: string;
  default: boolean;
  baseId: string;
}

export enum ITokenGateRule {
  AND = "AND",
  OR = "OR",
}
export interface ITokenGate {
  id: string;
  name: string;
  description: string;
  baseId: string;
  roleType: string;
  tokenRequirements: ITokenRequirement[];
  rule: ITokenGateRule;
}

export const MAX_NUMBER_OF_FREE_MEMBERS = 2;
export type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T;

export const mapContainerTypeToPrimitive: any = {
  [ContainerTypes.DOCUMENT]: [PrimitiveScopes.PAGES],
  [ContainerTypes.NOTE]: [PrimitiveScopes.NOTES],
  [ContainerTypes.PROJECT]: [PrimitiveScopes.WORK],
  [ContainerTypes.TASK]: [PrimitiveScopes.WORK],
};

export type ReactStateSetter<T extends unknown> = React.Dispatch<
  React.SetStateAction<T>
>;

export interface IMesssageInterface {
  info: string;
  status: number;
}

export interface INotificationAction {
  content: string;
  action: any;
}

export interface ClarityNotification {
  id: string;
  title?: React.ReactElement | string;
  icon?: React.ReactElement;
  body?: React.ReactElement | string;
  footer?: React.ReactElement;
  autodismissable?: boolean;
  dismissable?: boolean;
  actions?: INotificationAction[];
  duration: number;
  leaving?: boolean;
  removed?: boolean;
}

export function exerptNumber(number: number, precision: number = 8) {
  const val = Big(number);
  return val.round(precision).toNumber();
}

export const abilities = [
  "canManageMembers",
  "canManageRoles",
  "canManageRewards",
  "canManagePayments",
  "canManageTokenGates",
  "canManageTokens",
  "canManageCycles",
  "canManageMilestones",
  "canManageHome",
  "canManageBaseSettings",
  "canManageBilling",
  "canManageRoadmap",
  "canManagePins",
  "canManageInlineThreads",
  "moderateComments",
  "canEditEntity",
  "canCommentEntity",
  "canAddTaskComment",
  "canAddBlockCommentInTask",
  "canBeReviewer",
  "allEntityAccess",
  "canRenameBase",
  "canDeleteBase",
  "canUpdateBaseAvatar",
  "canManageReviewers",
  "admin",
];

export interface SubItem {
  key: string;
  title: string;
  icon?: any;
  enable?: boolean;
}

export interface MenuSection {
  title: string;
  subItems: SubItem[];
}

export interface SettingsSidebar {
  baseSettings: MenuSection;
  corePlugIns: MenuSection;
  integrations: MenuSection;
  preferences: MenuSection;
  groups: MenuSection;
}

export interface IGroup {
  id: string;
  name: string;
  slug: string;
  description?: string;
  workspaceId: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  requiredRoles: string[];
  members?: string[];
  rank: string;
  home: HomeScreenObj;
  coverPhotoUrl?: string;
  deleted?: boolean;
  showCycles: boolean;
  channelName?: string;
}

export interface IFavorite {
  id: string;
  rank: string;
  entityId: string;
  baseId: string;
  userId: string;
  entityType: ContainerTypes;
  navigationChunk: NavigationChunk;
  name: string;
}

export interface IChangelog {
  id: string;
  read: boolean;
  content: any;
  url: string;
  name: string;
  description: string;
  version: string;
  versionName: string;
}

export enum ViewNames {
  Detail = "details",
}

export type ViewNameType =
  | GeneralViewsNames
  | GroupGeneralViews
  | ViewNames
  | PayoutsViewsNames;

export interface NavigationChunk {
  groupSlug?: string;
  groupId?: string;
  viewName: ViewNameType;
  taskViewMode?: TasksViewModes;
  entity?: {
    containerId: string;
    containerType?: ContainerTypes;
    workType?: WorkTypes;
  };
}

export enum GeneralViewsNames {
  Home = "home",
  Roadmap = "roadmap",
  Roles = "roles",
  Groups = "groups",
  RoadmapArchive = "roadmap/archive",
  Views = "views",
  Tasks = "tasks",
  ViewEntity = "view",
  MyTasks = "my-work",
  MyProjects = "my-work/projects",
  MyProjectsArchive = "my-work/projects/archive",
  CurrentTasks = "tasks/current",
  SomedayTasks = "tasks/someday",
  TasksArchive = "tasks/archive",
  NewCustomView = "view/new",
  Wiki = "pages",
  TheWeekly = "weekly",
  Reviewing = "my-work/reviewing",
  ReviewingProjects = "ReviewingProjects",
  Templates = "templates",
  Snippets = "snippets",
}

export enum PayoutsViewsNames {
  Payouts = "payouts",
  UnclaimedContributions = "payouts/unclaimed",
  UnstartedContributions = "payouts/unstarted",
  InProgressContributions = "payouts/in-progress",
  AwitingRewardApprovalContributions = "payouts/awaiting-reward-approval",
  ApprovedForPaymentContributions = "payouts/approved-for-payment",
  PaidContributions = "payouts/paid",
}

export enum GroupGeneralViews {
  Notes = "notes",
  GroupProfile = "groupProfile",
  Projects = "projects",
  ProjectsArchive = "projects/archive",
  Tasks = "tasks",
  Cycles = "cycles",
  CyclesNext = "cycles/next",
  CyclesActive = "cycles/active",
  CyclesClosed = "cycles/closed",
  CycleEntity = "cycle",
  Members = "members",
}

export interface RecentlyOpened {
  navigationChunk: NavigationChunk;
  name: string;
}
