import Button, { ButtonTypes } from "components/Button";
import ClarityInput from "components/ClarityInput";
import { locationSubject } from "components/LocationListener";
import { axiosInstance } from "index";
import React, { useState } from "react";
import { UPDATE_CUSTOM_VIEW } from "store/actions";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { CustomWorkView } from "utilities/types";
const { v4: uuidv4 } = require("uuid");

const ViewCreator: React.FC<{ paneId: string; onCancel: () => void }> = ({
  paneId,
  onCancel,
}) => {
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const filterData = useShallowSelector(
    (store) => store.filter.currentDict[paneId]
  );

  const createCustomView = () => {
    if (!filterData) return;
    const storeData = store.getState();
    const nowDate = new Date();
    const newCustomView: CustomWorkView = {
      id: uuidv4(),
      baseId: storeData.workspace.id,
      dateCreated: nowDate,
      deletedAt: undefined,
      description,
      filters: { ...filterData.filters },
      groupBy: filterData.groupBy,
      name,
      orderBy: filterData.orderBy,
      viewAs: filterData.viewAs,
      shared: true,
      showActiveProjects: false,
      dateModified: nowDate,
      isDeleted: false,
      userId: storeData.user ? storeData.user?.id : "",
    };

    store.dispatch({
      type: UPDATE_CUSTOM_VIEW,
      param: {
        type: "add",
        delta: newCustomView,
        id: newCustomView.id,
      },
    });

    axiosInstance
      .post(`/api/customWorkView/`, {
        ...newCustomView,
      })
      .catch((err) => {
        console.log(err);
      });
    if (paneId === ChunkDestination.secondary) {
      //TODO
      // return store.dispatch({
      //   type: SET_SECONDARY_VIEW,
      //   secondaryViewType: "AllView",
      //   entityId: newCustomView.id,
      //   tasksViewMode: TasksViewModes.CustomView,
      // });
    } else {
      const state = store.getState();
      locationSubject.next(`/${state.workspace.slug}/view/${newCustomView.id}`);
    }
  };

  // const clearInputs = () => {
  //   setName("");
  //   setdescription("");
  // };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <h4 style={{ marginBottom: "10px" }}>Name your view</h4>
      <ClarityInput
        onKeyDown={(e) => e.stopPropagation()}
        required={true}
        placeholder="Untitled View"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <ClarityInput
        onKeyDown={(e) => e.stopPropagation()}
        placeholder="Subtitle (optional)"
        value={description}
        onChange={(e) => setdescription(e.target.value)}
      />
      <div style={{ display: "flex", marginTop: "20px" }}>
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <span style={{ marginRight: "10px" }}>
            <Button buttonType={ButtonTypes.LINK} onClick={onCancel}>
              Cancel
            </Button>
          </span>

          <Button
            buttonType={ButtonTypes.PRIMARY}
            disabled={name === ""}
            onClick={() => createCustomView()}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewCreator;
