import React from "react";
import UserDisplay from "./UserDisplay";

const UsersStack: React.FC<{
  userIds: string[];
  avatarSize?: number;
  hideTooltip?: boolean;
}> = ({ userIds, avatarSize, hideTooltip }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {userIds.map((id, index) => (
        <span key={id} style={{ marginLeft: index === 0 ? "0" : `${-9}px` }}>
          <UserDisplay
            borderColor={userIds.length > 1 ? "white" : undefined}
            avatarSize={avatarSize}
            hideTooltip={hideTooltip}
            id={id}
            hideName={true}
          />
        </span>
      ))}
    </div>
  );
};

export default UsersStack;
