import StatusDisplay from "components/StatusDisplay";
import React from "react";
import metadataStyles from "clarity-ui/containerDetailMetadata/containerDetailMetadata.module.scss";
import { IProjectObj } from "utilities/types";
import cardStyles from "./projectCard.module.scss";

const ItemStatus: React.FC<{
  workItem: IProjectObj;
  canEditEntity: boolean;
  customStyles?: React.CSSProperties;
}> = ({ workItem, canEditEntity, customStyles = {} }) => {
  if (!workItem.statusId) return <></>;
  return (
    <span
      // className={`${metadataStyles.metadataBlockBottom} ${metadataStyles.noBack}`}
      style={{
        minHeight: "unset",
        paddingTop: "1.5px",
        paddingBottom: "0.5px",
        paddingRight: "5px",
        width: "fit-content",
        maxWidth: "100%",
        ...customStyles,
      }}
    >
      <StatusDisplay
        workType={workItem.workType}
        statusId={workItem.statusId}
        size="large"
        captionClass={`${metadataStyles.infoText} ${cardStyles.infoExerpted}`}
        captionStyle={{ fontSize: "12px" }}
        containerStyle={{ gap: "4px" }}
        editId={workItem.id}
        showName={true}
        disabled={!canEditEntity}
      />
    </span>
  );
};

export default ItemStatus;
