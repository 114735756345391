import { batch } from "react-redux";
import store from "store/storeExporter";
import {
  ADD_SECTION,
  SET_BLOCKS_ARRAY_FROM_CONTAINER,
  SET_LAST_USED_TOKEN,
  UPDATE_MILESTONE,
  UPDATE_WORK_ITEM,
  UPDATE_WORK_ITEMS,
} from "store/actions";
import {
  IProjectObj,
  WorkTypes,
  IReward,
  IContributon,
  SplitType,
  ContainerTypes,
  IWorkActivity,
  WorkActivityTypes,
  DeltaAction,
  IBlockContext,
  IProjectCreate,
  ITemplateObj,
} from "utilities/types";
import { getNextRank } from "utilities/containerRankHelpers";
import { IMilestone } from "store/reducers/workReducer";
import { socket } from "App";
import notificationsApi from "./notificationsApi";
import { tokenApi } from "./tokenApi";
import _ from "lodash";
import { axiosInstance } from "index";
import {
  getNameFromContainerForDiscord,
  getNameFromContainerForEmail,
} from "modules/containerHelpers";
import { getCorrectLink } from "utilities/linkUtilities";
import { Block } from "store/reducers/blockReducer";
import { cloneBlockBranch } from "editor/utils/specificActions/cloneBlockActions";
import { setUpdateObjectToStore } from "editor/utils/specificActions/persistActions";
import { ActionObject } from "editor/utils/specificActions/undoUtils";
import { BlockActionTypes } from "editor/utils/blockActions";
import { getBlockFromExtender } from "editor/utils/socketActionsListener";
import { userApi } from "./userApi";
import { ILineValue, LineValueType } from "utilities/lineUtilities";
import {
  INewTaskCreationModes,
  INewTemplateCreationModes,
  openNewTaskModal,
  openNewTemplateModal,
} from "store/reducers/clientReducer";
const { v4: uuidv4 } = require("uuid");

class WorkActions {
  getState() {
    return store.getState().work;
  }

  dispatchUpdate(workItem: IProjectObj) {
    store.dispatch({ type: UPDATE_WORK_ITEM, workItem });
  }

  dispatchMultipleUpdate(workItems: IProjectObj[]) {
    store.dispatch({ type: UPDATE_WORK_ITEMS, workItems });
  }

  checkSideEffects(workItem: IProjectObj, delta: Partial<IProjectObj>) {
    const addAssigneAsContributor = () => {
      if (workItem.reward) {
        const updatedWorkItem = { ...workItem, ...delta };
        const contributors = this.rebuildContributors(
          updatedWorkItem,
          workItem.reward.contributors,
          workItem
        );
        const reward = { ...workItem.reward };
        reward.contributors = contributors;

        delta.reward = reward;
      }
    };

    if ("statusId" in delta) {
      const statuses = this.getState().statuses;
      const newStatusId = delta.statusId;
      if (!newStatusId) return;
      const newStatus = statuses.dict[newStatusId];
      if (!newStatus) return;

      const closedCategoryIds = ["4", "5"];
      const doneCategories = ["4"];
      const openCategoryIds = ["1", "2", "3"];
      const newWorkItem = { ...workItem };
      const prevStatus = statuses.dict[workItem.statusId || 0];

      newWorkItem.statusId = delta.statusId;
      newWorkItem.isClosed = closedCategoryIds.includes(newStatus.categoryId);
      newWorkItem.isDone = doneCategories.includes(newStatus.categoryId);

      const categoryIdIsOpen = (id: string) => openCategoryIds.includes(id);
      const categoryIdIsClosed = (id: string) => closedCategoryIds.includes(id);

      const prevCategoryWasOpen = categoryIdIsOpen(prevStatus.categoryId);
      const newCategoryIsClosed = categoryIdIsClosed(newStatus.categoryId);
      const prevCategoryWasClosed = categoryIdIsClosed(prevStatus.categoryId);
      const newCategoryIsOpen = categoryIdIsOpen(newStatus.categoryId);

      const closedChange =
        (prevCategoryWasOpen && newCategoryIsClosed) ||
        (prevCategoryWasClosed && newCategoryIsOpen);

      if (closedChange) {
        delta.isClosed = newWorkItem.isClosed;
      }

      if (
        (doneCategories.includes(newStatus.categoryId) &&
          !doneCategories.includes(prevStatus.categoryId)) ||
        (doneCategories.includes(prevStatus.categoryId) &&
          !doneCategories.includes(newStatus.categoryId))
      )
        delta.isDone = doneCategories.includes(newStatus.categoryId);
    }

    if ("assigneeId" in delta) {
      if (workItem.reward) {
        addAssigneAsContributor();
      }
    }

    if ("workType" in delta) {
      if (delta.workType === WorkTypes.TASK) delta.contributorIds = [];
    }

    if ("contributorIds" in delta) {
      if (workItem.reward) {
        const updatedWorkItem = { ...workItem, ...delta };
        const contributors = this.rebuildContributors(
          updatedWorkItem,
          workItem.reward.contributors,
          workItem
        );

        const reward = { ...workItem.reward };
        reward.contributors = contributors;
        delta.reward = reward;
      }
    }
    if ("groupId" in delta) {
      if (workItem.reward && workItem.workType === WorkTypes.TASK) {
        const reward = { ...workItem.reward };
        reward.sponsorGroupId = workItem.groupId;
      }
    }
  }

  checkIfCloseOperationAndContinue(ids: string[], delta: Partial<IProjectObj>) {
    if ("statusId" in delta) {
      const statusId = delta.statusId;
      if (!statusId) return true;
      const status = this.getState().statuses.dict[statusId];
      if (["4", "5"].includes(status.categoryId)) {
        const canClose = this.checkIfItemsCanBeClosed(ids);
        return canClose;
      }
    }

    return true;
  }

  async updateWorkItems(deltas: Partial<IProjectObj>[]) {
    const baseId = store.getState().workspace.id;
    const state = this.getState();
    const dict = state.dict;
    const updatedWorkItems = [];
    for (const delta of deltas) {
      if (!delta.id) {
        continue;
      }
      const canContinue = this.checkIfCloseOperationAndContinue(
        [delta.id],
        delta
      );

      if (!canContinue) return;
    }

    for (const delta of deltas) {
      if (!delta.id) {
        continue;
      }
      const workItem = dict[delta.id];
      this.checkSideEffects(workItem, delta);
      const updatedWorkItem = { ...workItem, ...delta };
      updatedWorkItems.push(updatedWorkItem);
    }
    if (!this.checkIsCreatingNewTask()) {
      axiosInstance.patch("/api/project/updateAll", {
        deltas,
        baseId,
      });
    }
    this.dispatchMultipleUpdate(updatedWorkItems);
  }

  async updateWorkItem(workId: string, delta: Partial<IProjectObj>) {
    const state = this.getState();
    const dict = state.dict;
    const workItem = dict[workId];
    const itemDelta = { ...delta };
    const canContinue = this.checkIfCloseOperationAndContinue([workId], delta);
    if (!canContinue) return;
    this.checkSideEffects(workItem, itemDelta);
    const creatingNewTask = this.checkIsCreatingNewTask();
    if (!creatingNewTask) {
      axiosInstance.patch("/api/project/update", {
        delta: { ...itemDelta, id: workItem.id },
      });
    }

    const updatedWorkItem = { ...workItem, ...itemDelta };
    this.dispatchUpdate(updatedWorkItem);
    return updatedWorkItem;
  }

  saveUpdateWorkItem(workId: string, delta: Partial<IProjectObj>) {
    axiosInstance.patch("/api/project/update", {
      delta: {
        ...delta,
        id: workId,
      },
    });
  }

  saveUpdateWorkItems(deltas: Partial<IProjectObj>[]) {
    const baseId = store.getState().workspace.id;

    axiosInstance.patch("/api/project/updateAll", {
      deltas,
      baseId,
    });
  }

  checkIsCreatingNewTask() {
    return Boolean(store.getState().client.newTaskContext?.presetData?.id);
  }

  update(delta: Partial<IProjectObj>, items: string[]) {
    if (delta.parentId) {
      const canChangeParent = this.checkIfCanChangeParent(
        items,
        delta.parentId
      );
      if (!canChangeParent) return;
    }
    batch(() => {
      this.updateWorkItems(
        items.map((workId) => {
          return { id: workId, ...delta };
        })
      );
    });
  }

  markWorkAsDone(ids: string[]) {
    let doneStatusId: string = "";
    const statuses = this.getState().statuses;
    for (const statusId of statuses.statusArray) {
      const status = statuses.dict[statusId];
      if (status.categoryId === "4") {
        doneStatusId = statusId;
        break;
      }
    }

    if (doneStatusId && doneStatusId !== "") {
      this.update({ statusId: doneStatusId }, ids);
    }
  }

  getPossibleParents(selectedItems: string[]): IProjectObj[] {
    const state = store.getState().work;
    const dict = state.dict;
    const allTaskIds = state.allTaskIds;
    const allProjectIds = state.allProjectIds;
    const allInitiativeIds = state.allInitiativeIds;

    const possibleParents: IProjectObj[] = [];
    let selectionHasInitiative = false;
    let selectionHasProject = false;
    const nonApplicableIds: string[] = [];

    const checkIssue = (itemId: string) => {
      const item = dict[itemId];
      if (item.workType === WorkTypes.INITIATIVE) {
        selectionHasInitiative = true;
      }
      if (item.workType === WorkTypes.PROJECT) {
        selectionHasProject = true;
      }

      nonApplicableIds.push(itemId);
      if (item?.childrenAggregate.length > 0) {
        item?.childrenAggregate.forEach((child: any) => checkIssue(child.id));
      }
    };

    selectedItems.forEach((itemId) => {
      checkIssue(itemId);
    });

    if (selectionHasInitiative) return possibleParents;

    [...allInitiativeIds, ...allProjectIds, ...allTaskIds].forEach((itemId) => {
      const item = dict[itemId];
      if (!item) return;
      if (item.workType === WorkTypes.INITIATIVE && selectionHasInitiative)
        return;
      if (
        (item.workType === WorkTypes.PROJECT ||
          item.workType === WorkTypes.TASK) &&
        selectionHasProject
      )
        return;
      if (nonApplicableIds.includes(item.id)) return;

      possibleParents.push(item);
    });

    return possibleParents;
  }

  checkIfItemsCanBeClosed(selectedItems: string[]) {
    let canCloseItems = true;
    const state = store.getState();
    const user = state.user;
    const dict = state.work.dict;
    selectedItems.forEach((itemId) => {
      if (!canCloseItems) return;
      const workItem = dict[itemId];
      if (workItem) {
        if (workItem.reviewerId && workItem.reviewerId !== user?.id) {
          notificationsApi.displayError({
            title: "This task requires a reviewer",
            body: "Only the reviewer can mark this task as done or canceled",
            duration: 3,
          });
          return (canCloseItems = false);
        }

        workItem.childrenAggregate.forEach((item) => {
          if (!canCloseItems) return;
          if (item.open) {
            notificationsApi.displayError({
              title: "Cannot close items",
              body: "You cannot close an item with incomplete subtasks",
              duration: 3,
            });
            return (canCloseItems = false);
          }
        });
      }
    });
    return canCloseItems;
  }

  checkChildrenWorkTypes(itemId: string) {
    const state = store.getState().work;
    const dict = state.dict;
    const workItem = dict[itemId];
    return workItem.childrenAggregate.map((item) => dict[item.id].workType);
  }

  checkAncestorsWorkTypes(itemId: string) {
    const state = store.getState().work;
    const dict = state.dict;
    const workItem = dict[itemId];

    const parentTypes: WorkTypes[] = [];

    const checkParent = (parentId: string) => {
      const parentItem = dict[parentId];
      parentTypes.push(parentItem.workType);
      if (parentItem.parentId) {
        checkParent(parentItem.parentId);
      }
    };

    if (workItem?.parentId) checkParent(workItem.parentId);
    return parentTypes;
  }

  checkIfCanChangeParent(selectedItems: string[], newParentId: string) {
    let canChangeParent = true;
    const state = store.getState().work;
    const dict = state.dict;
    let reason = "";
    const possibleParent = dict[newParentId];

    if (!possibleParent) {
      canChangeParent = false;
      notificationsApi.displayError({
        title: "Cannot change parent",
        body: "Somthing went wrong, please try again",
      });
      return canChangeParent;
    } else {
      if (possibleParent.workType === WorkTypes.TASK) {
        for (const itemId of selectedItems) {
          const item = dict[itemId];
          if (item.workType === WorkTypes.PROJECT) {
            const groupIds = item.groupIds ?? [];
            const hasOverlap = groupIds.includes(possibleParent.groupId);
            if (!hasOverlap) {
              canChangeParent = false;
              reason = "Cannot set parent from another group";
              break;
            }
          }
          if (item.workType === WorkTypes.TASK) {
            const hasOverlap = item.groupId === possibleParent.groupId;
            if (!hasOverlap) {
              canChangeParent = false;
              reason = "Cannot set parent from another group";
              break;
            }
          }
        }
      }
    }

    if (!canChangeParent)
      notificationsApi.displayError({
        title: "Cannot change parent",
        body: reason,
      });
    return canChangeParent;
  }

  checkIfMilestoneHasOpenItems(milestoneId: string) {
    const state = store.getState().work;
    const projects = state.allProjectIds;
    const dict = state.dict;
    for (const id of projects) {
      if (
        dict[id].milestoneId === milestoneId &&
        !dict[id].isClosed &&
        !dict[id].isDeleted
      )
        return true;
    }
    return false;
  }

  saveMilestone(name: string) {
    const state = store.getState();
    const milestoneIds = state.work.milestoneIds;
    let rank = "0a";
    if (milestoneIds.length > 0) {
      const lastId = milestoneIds[milestoneIds.length - 1];
      const milestone = state.work.milestoneDict[lastId];
      rank = getNextRank(milestone.rank);
    }

    axiosInstance
      .post("/api/milestones/", {
        name,
        rank,
        baseId: state.workspace.id,
        clientId: socket.id,
      })
      .then((res) => {
        const milestone = res.data;
        this.updateMilestoneState(milestone.id, "insert", milestone);
      });
  }

  updateMilestone(id: string, delta: Partial<IMilestone>) {
    return axiosInstance
      .patch("/api/milestones/", {
        id,
        delta,
        clientId: socket.id,
      })
      .then(() => {
        this.updateMilestoneState(id, "update", delta);
      });
  }

  deleteMilestone(id: string) {
    return axiosInstance
      .delete("/api/milestones/", {
        data: {
          id,
          clientId: socket.id,
        },
      })
      .then(() => {
        this.updateMilestoneState(id, "delete", {});
      });
  }

  saveCycle(name: string, groupId: string) {
    const baseId = store.getState().workspace.id;
    if (!baseId) return;

    axiosInstance
      .post("/api/workSection", { name, baseId, clientId: socket.id, groupId })
      .then((res) => {
        store.dispatch({
          type: ADD_SECTION,
          section: res.data.payload.section,
        });
      });
  }

  saveReward(reward: IReward, workItemId: string) {
    const creatingNewTask = this.checkIsCreatingNewTask();
    const fulldelta = this.updateWorkItemReward(workItemId, reward, "add");
    if (!creatingNewTask) {
      axiosInstance
        .post("/api/reward", {
          reward: fulldelta as IReward,
          workItemId,
          clientId: socket.id,
        })
        .then(() => {
          store.dispatch({
            type: SET_LAST_USED_TOKEN,
            params: {
              networkId: fulldelta?.networkId,
              contractAddress: fulldelta?.contractAddress,
            },
          });
        })
        .catch(() => {
          this.updateWorkItemReward(workItemId, undefined, "delete");
        });
    }
  }

  updateWorkItemReward(
    workItemId: string,
    delta: Partial<IReward> | IReward | undefined,
    type: "add" | "update" | "delete",
    noUpdateToWorkItem?: boolean
  ) {
    const workItem: IProjectObj = store.getState().work.dict[workItemId];
    let reward: IReward | undefined;

    const checkIfPaidStatusChange = () => {
      if (delta && "isPaid" in delta) {
        if (delta.isPaid && reward) {
          const price = tokenApi.getRewardPrice(reward);
          reward.tokenUsdPrice = price;
          delta.tokenUsdPrice = price;
        }
        if (!delta.isPaid && reward) {
          reward.tokenUsdPrice = null;
          delta.tokenUsdPrice = null;
        }
      }
      if (reward && delta) {
        const updatedWorkItem = { ...workItem, reward };
        const contributors = this.rebuildContributors(
          updatedWorkItem,
          reward.contributors ?? [],
          workItem
        );
        reward.contributors = contributors;
        delta.contributors = contributors;
      }
    };

    if (type === "add") {
      reward = { ...(delta as IReward) };
      checkIfPaidStatusChange();
      reward = { ...(delta as IReward) };
    } else if (type === "update") {
      if (!workItem.reward) workItem.reward = {} as IReward;
      reward = workItem.reward
        ? { ...workItem.reward, ...delta }
        : { ...(delta as IReward) };
      checkIfPaidStatusChange();
    } else {
      reward = undefined;
    }

    if (!noUpdateToWorkItem) {
      const contributorIds = reward?.contributors
        .filter((contributor) => contributor.reason !== "assignee")
        .map((contributor) => contributor.userId);

      if (!_.isEqual(workItem.contributorIds, contributorIds)) {
        this.updateWorkItem(workItemId, { reward, contributorIds });
      } else {
        this.updateWorkItem(workItemId, { reward });
      }
    }

    return delta;
  }

  rebuildContributors(
    workItem: IProjectObj,
    prevContributors: IContributon[],
    prevWorkItem: IProjectObj
  ) {
    const contributors = [];
    const otherContributors = workItem.contributorIds?.filter(
      (id) => id !== workItem.assigneeId
    );

    const getFullReward = () => {
      if (workItem.reward?.splitType === SplitType.currency)
        return workItem.reward.amount;
      return 100;
    };

    const checkIfContributionExists = (id: string) => {
      for (const contribution of prevContributors) {
        if (contribution.userId === id) return contribution;
      }
    };

    if (workItem.assigneeId) {
      const existingContribution = checkIfContributionExists(
        workItem.assigneeId
      );
      const contribution: IContributon = {
        userId: workItem.assigneeId,
        amount: otherContributors?.length > 0 ? 0 : getFullReward(),
        reason: "assignee",
      };
      if (existingContribution && otherContributors?.length > 0) {
        contribution.amount = existingContribution.amount;
      }
      contributors.push(contribution);
    }

    otherContributors?.forEach((id) => {
      const existingContribution = checkIfContributionExists(id);
      const contribution: IContributon = {
        userId: id,
        amount: 0,
        reason: "contributor",
      };
      if (existingContribution) {
        contribution.amount = existingContribution.amount;
      }
      contributors.push(contribution);
    });

    return contributors;
  }

  deleteReward(id: string, workItemId: string) {
    const creatingNewTask = this.checkIsCreatingNewTask();
    if (!creatingNewTask) {
      axiosInstance.delete("/api/reward/", {
        data: {
          id,
          workItemId,
          clientId: socket.id,
        },
      });
    }
    this.updateWorkItem(workItemId, { reward: undefined });
  }

  updateMilestoneState(
    id: string,
    type: "update" | "delete" | "insert",
    delta: Partial<IMilestone> | IMilestone
  ) {
    store.dispatch({
      type: UPDATE_MILESTONE,
      param: {
        id,
        type,
        delta,
      },
    });
  }

  connectToDiscord(workId: string, channelId?: string) {
    const workItem = store.getState().work.dict[workId];

    const base = store.getState().workspace;
    const contributors = workItem.contributorIds;
    const name =
      workItem.workType === WorkTypes.TASK
        ? getNameFromContainerForDiscord(workItem, ContainerTypes.PROJECT)
        : getNameFromContainerForEmail(workItem, ContainerTypes.PROJECT);
    const originUrl = window.location.origin;

    const url = `${originUrl}${getCorrectLink(base, workItem)}`;
    const params = {
      workId: workItem.id,
      groupId: workItem.groupId,
      workName: name,
      assigneeId: workItem.assignee?.id,
      contributorsIds: workItem.reviewerId
        ? [...contributors, workItem.reviewerId]
        : contributors,
      assigneeUsername: `@${workItem.assignee?.username}`,
      channelId,
      workType: workItem.workType,
      workUrl: url,
    };
    return axiosInstance.post("/api/project/discord/connect", {
      ...params,
    });
  }

  disconnectFromDiscord(workId: string) {
    return axiosInstance.post("/api/project/discord/disconnect", {
      workId,
    });
  }

  requestReview(workId: string) {
    const state = store.getState();
    if (state.user) {
      axiosInstance.post("/api/project/review", {
        workId: workId,
      });
    }
  }

  requestClaim(workId: string) {
    const state = store.getState();
    if (state.user) {
      axiosInstance.post("/api/project/claim", {
        workId: workId,
      });
    }
  }

  canRequestClaim(workItemId: string) {
    const state = store.getState();
    const workItem = state.work.dict[workItemId];
    const userId = state.user?.id;
    if (!workItem.workActivities) {
      return true;
    }

    if (workItem.assigneeId && workItem.assigneeId === userId) {
      return false;
    }

    if (
      workItem.contributorIds &&
      userId &&
      workItem.contributorIds.includes(userId)
    ) {
      return false;
    }

    if (workItem.workType === WorkTypes.TASK && workItem.assigneeId) {
      return false;
    }

    for (const workActivityId of workItem.workActivities) {
      const workActivity = state.work.workActivities[workActivityId];

      if (workActivity.type === WorkActivityTypes.CLAIM_REQUEST) {
        if (
          workActivity.authorId === userId &&
          workActivity.delta?.action === DeltaAction.PENDING
        ) {
          return false;
        }
      }
    }
    return true;
  }

  canRequestReview(workItemId: string) {
    const state = store.getState();
    const workItem = state.work.dict[workItemId];

    if (!workItem) {
      return false;
    }

    if (workItem.isClosed) {
      return false;
    }

    if (workItem.assigneeId !== state.user?.id) {
      return false;
    }

    if (!workItem.reviewerId) {
      return false;
    }

    if (!workItem.workActivities) {
      return false;
    }

    if (workItem.requests?.activeReviewRequest) {
      return false;
    }

    return true;
  }

  acceptReview(workActivity: IWorkActivity) {
    const state = store.getState();

    const userId = state.user?.id;
    const newDelta = {
      ...workActivity.delta,
      action: DeltaAction.ACCEPTED,
      metadata: {
        nextValue: userId,
      },
    };
    axiosInstance.post("/api/project/review/update", {
      workActivityId: workActivity.id,
      workId: workActivity.taskId,
      delta: newDelta,
    });
  }

  denyReview(workActivity: IWorkActivity) {
    const state = store.getState();

    const userId = state.user?.id;
    const newDelta = {
      ...workActivity.delta,
      action: DeltaAction.DENIED,
      metadata: {
        nextValue: userId,
      },
    };

    axiosInstance.post("/api/project/review/update", {
      workActivityId: workActivity.id,
      workId: workActivity.taskId,
      delta: newDelta,
    });
  }

  async remindReview(workActivityId: string, workItemId: string) {
    const state = store.getState();
    const workItem = state.work.dict[workItemId];

    if (!workItem.reviewerId) {
      return;
    }
    const username = userApi.getUsernameForUser(workItem.reviewerId);
    const text = `Nudged <b>${username}</b> to review their work`;

    return axiosInstance.post("/api/project/review/remind", {
      id: workActivityId,
      text,
      mentionedUsers: [workItem.reviewerId],
    });
  }

  acceptClaim(workActivity: IWorkActivity) {
    const state = store.getState();

    const userId = state.user?.id;
    const newDelta = {
      ...workActivity.delta,
      action: DeltaAction.ACCEPTED,
      metadata: {
        nextValue: userId,
      },
    };

    const workItem = state.work.dict[workActivity.taskId];

    axiosInstance.post("/api/project/claim/update", {
      workId: workItem.id,
      workActivityId: workActivity.id,
      delta: newDelta,
    });
  }

  denyClaim(workActivity: IWorkActivity) {
    const state = store.getState();

    const userId = state.user?.id;
    const newDelta = {
      ...workActivity.delta,
      action: DeltaAction.DENIED,
      metadata: {
        nextValue: userId,
      },
    };
    const workItem = state.work.dict[workActivity.taskId];

    axiosInstance.post("/api/project/claim/update", {
      workId: workItem.id,
      workActivityId: workActivity.id,
      delta: newDelta,
    });
  }

  openDuplicatModal(id: string) {
    const storeData = store.getState();
    const blockContext = storeData.blocks.contexts[ContainerTypes.PROJECT + id];
    const blockIds = blockContext.state.rootBlocksIds;
    const newBlocks: Block[] = [];
    const newContainerId = uuidv4();
    cloneBlockBranch(
      blockIds,
      "",
      0,
      newContainerId,
      ContainerTypes.PROJECT,
      newBlocks
    );
    const workItem = storeData.work.dict[id];

    const newName = workItem.name + " (copy)";
    const newNameValue: ILineValue[] = [
      { type: LineValueType.text, value: newName, children: [] },
    ];

    const saveActions: ActionObject[] = [];
    const context = {
      autosave: false,
      id: ContainerTypes.PROJECT + newContainerId,
      type: "container",
      container: {
        id: newContainerId,
        type: ContainerTypes.PROJECT,
      },
    } as IBlockContext;

    newBlocks.forEach((block) => {
      const saveAction: ActionObject = {
        id: block.id,
        delta: getBlockFromExtender(block),
        type: BlockActionTypes.insert,
        options: {
          newBlock: true,
        },
      };
      saveActions.push(saveAction);
    });
    setUpdateObjectToStore(saveActions, context);

    batch(() => {
      store.dispatch({
        type: SET_BLOCKS_ARRAY_FROM_CONTAINER,
        param: {
          blocks: newBlocks,
          container: {
            containerId: newContainerId,
            containerType: ContainerTypes.PROJECT,
          },
        },
      });

      openNewTaskModal({
        type: INewTaskCreationModes.duplicate,
        generatedFromId: id,
        presetData: {
          id: newContainerId,
          assigneeId: workItem.assigneeId,
          name: newName,
          nameValue: newNameValue,
          isClosed: workItem.isClosed,
          isDone: workItem.isDone,
          contributorIds: workItem.contributorIds,
          parentId: workItem.parentId,
          reviewerId: workItem.reviewerId,
          milestoneId: workItem.milestoneId,
          workSectionId: workItem.workSectionId,
          statusId: workItem.statusId,
          outlineMode: workItem.outlineMode,
          workType: workItem.workType,
          dateClosed: workItem.dateClosed,
          workspaceId: workItem.workspaceId,
          dueDate: workItem.dueDate,
          priority: workItem.priority,
          groupId: workItem.groupId,
          tags: workItem.tags,
          groupIds: workItem.groupIds,
          childrenAggregate: workItem.childrenAggregate,
          reward: workItem.reward,
          requests: undefined,
        },
      });
    });
  }

  openCreateTemplatetModal(id: string) {
    const storeData = store.getState();
    const blockContext = storeData.blocks.contexts[ContainerTypes.PROJECT + id];
    const blockIds = blockContext.state.rootBlocksIds;
    const newBlocks: Block[] = [];
    const newContainerId = uuidv4();
    cloneBlockBranch(
      blockIds,
      "",
      0,
      newContainerId,
      ContainerTypes.TEMPLATE,
      newBlocks
    );
    const workItem = storeData.work.dict[id];

    const saveActions: ActionObject[] = [];
    const context = {
      autosave: false,
      id: ContainerTypes.TEMPLATE + newContainerId,
      type: "container",
      container: {
        id: newContainerId,
        type: ContainerTypes.TEMPLATE,
      },
    } as IBlockContext;

    newBlocks.forEach((block) => {
      const saveAction: ActionObject = {
        id: block.id,
        delta: getBlockFromExtender(block),
        type: BlockActionTypes.insert,
        options: {
          newBlock: true,
        },
      };
      saveActions.push(saveAction);
    });
    setUpdateObjectToStore(saveActions, context);

    batch(() => {
      store.dispatch({
        type: SET_BLOCKS_ARRAY_FROM_CONTAINER,
        param: {
          blocks: newBlocks,
          container: {
            containerId: newContainerId,
            containerType: ContainerTypes.TEMPLATE,
          },
        },
      });

      openNewTemplateModal({
        type: INewTemplateCreationModes.fromTask,
        presetData: {
          id: newContainerId,
          assigneeId: workItem.assigneeId,
          name: workItem.name,
          nameValue: workItem.nameValue,
          isClosed: workItem.isClosed,
          isDone: workItem.isDone,
          contributorIds: workItem.contributorIds,
          reviewerId: workItem.reviewerId,
          statusId: workItem.statusId,
          outlineMode: workItem.outlineMode,
          workType: workItem.workType,
          dateClosed: workItem.isClosed ? new Date() : undefined,
          workspaceId: workItem.workspaceId,
          dueDate: workItem.dueDate,
          priority: workItem.priority,
          groupId: workItem.groupId,
          groupIds: workItem.groupIds,
          childrenAggregate: workItem.childrenAggregate,
          reward: workItem.reward,
          isRoot: true,
        },
        generatedFromId: id,
      });
    });
  }

  createWorkItems(workItemsChunks: IProjectCreate[]) {
    axiosInstance
      .post("/api/project/multiple", {
        workItemsChunks,
      })
      .then((res: any) => {
        notificationsApi.displayConfirmation({
          title: "Success",
        });
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Something went wrong",
        });
      });
  }

  duplicateWorkItem(
    id: string,
    presets?: Partial<IProjectObj>,
    includeChildren?: boolean,
    thenAction?: (workItem: IProjectObj) => void
  ) {
    axiosInstance
      .post("/api/project/duplicate", {
        id,
        presets,
        includeChildren,
      })
      .then((res: any) => {
        if (thenAction) {
          return thenAction(res.data);
        }
        notificationsApi.displayConfirmation({
          title: "Success",
        });
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Something went wrong",
        });
      });
  }

  createTemplateFromWorkItem(
    id: string,
    presets?: Partial<ITemplateObj>,
    includeChildren?: boolean,
    thenAction?: (templateItem: ITemplateObj) => void
  ) {
    console.log(presets);
    axiosInstance
      .post("/api/project/createTemplateFrom", {
        id,
        presets,
        includeChildren,
      })
      .then((res: any) => {
        console.log(res);
        if (thenAction) {
          return thenAction(res.data);
        }
        notificationsApi.displayConfirmation({
          title: "Success",
          body: "template created",
        });
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Something went wrong",
        });
      });
  }
}

const workApi = new WorkActions();

export default workApi;
