import { mixpanel } from "App";
import { BlockPropsWithRef } from "editor/blockContainer/BlockSplitter";
import { getCorrectPrefixForWorkItem } from "modules/containerHelpers";
import {
  handleNewDocumentCreation,
  handleNewPageLocal,
} from "modules/documentService";
import { SyntheticEvent } from "react";
import { batch } from "react-redux";
import {
  ADD_NEW_DOCUMENT,
  ADD_MENTION,
  CONNECT_CITATION,
  REMOVE_MENTION,
  DISCONNECT_CITATION,
} from "store/actions";
import { Block, BlockData } from "store/reducers/blockReducer";
import {
  containerCleanupDict,
  getBlockById,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { prevState } from "store/storeExporter";
import {
  getInnerCaretPosition,
  moveCaretToPreviousPosition,
} from "utilities/caretMovement";
import { ILineValue, LineValueType } from "utilities/lineUtilities";
import { stripHtml } from "utilities/stringUtilities";
import {
  IBlockContext,
  IDocumentCreationMethods,
  IPageObj,
  UserEventTypes,
} from "utilities/types";
import {
  BlockActionTypes,
  updateBlockText,
  updateImmediateText,
} from "../blockActions";
import { blockApi } from "../blockActionsApi";
import { breakDownHtml, getHtml } from "../blockValueHelpers";
import { checkCaretPosition } from "../caretUtils";
import { handleUpdateAction } from "../primitiveActions/primitiveActions";
import { setUpdateObjectToStore } from "./persistActions";
import {
  checkifBeginingOfTag,
  checkIfEmptyTagCreated,
  checkIfEndOfTag,
  checkIfInsideCode,
  checkIfInsideMention,
  checkIfPreviousEmptyAndHasACodeBlock,
  checkIfShouldNotCreateTag,
  getFirstChild,
  getLastChild,
  getSelectionHtml,
} from "./textCheckingUtils";
import { ActionObject } from "./undoUtils";

export type IdValue = { id: string; value: ILineValue[] };

export const handleLocalEntityConnect = (
  block: Block,
  options?: { immediate?: boolean; socketUpdate?: boolean }
) => {
  valueChecker([...block.value], [], block.id, options);
};

export const handleLocalEntityDisconnect = (
  block: Block,
  options?: { immediate?: boolean; socketUpdate?: boolean }
) => {
  valueChecker([], [...block.value], block.id, options);
};

export const valueChecker = (
  newValue: ILineValue[],
  prevValue: ILineValue[],
  blockId: string,
  options?: { immediate?: boolean; socketUpdate?: boolean }
) => {
  const newValueCitations: any[] = [];
  const newValueTags: any[] = [];
  iterateThroughValue(
    newValue,
    newValueTags,
    newValueCitations,
    undefined,
    undefined,
    options
  );

  const prevValueTags: any[] = [];
  const prevValueCitations: any[] = [];
  iterateThroughValue(
    prevValue,
    prevValueTags,
    prevValueCitations,
    undefined,
    undefined,
    options
  );

  if (newValueCitations.length > 0 || prevValueCitations.length > 0) {
    handleCitationDiff(newValueCitations, prevValueCitations, blockId);
  }

  if (newValueTags.length > 0 || prevValueTags.length > 0) {
    handleTagDiff(newValueTags, prevValueTags, blockId, options);
  }
};

const handleCitationDiff = (
  newValueCitations: any[],
  prevValueCitations: any[],
  blockId: string
) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, blockId);

  const newCitations: any[] = getCitationsDiff(
    newValueCitations,
    prevValueCitations
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removedCitations: any[] = getCitationsDiff(
    prevValueCitations,
    newValueCitations
  );

  newCitations.forEach((newCitation) => {
    store.dispatch({
      type: CONNECT_CITATION,
      param: {
        citationId: newCitation.id,
        blockId: block.id,
      },
    });
  });

  removedCitations.forEach((removedCitation) => {
    store.dispatch({
      type: DISCONNECT_CITATION,
      param: {
        citationId: removedCitation.id,
        blockId: block.id,
      },
    });
  });
};

const handleTagDiff = (
  newValueTags: any[],
  prevValueTags: any[],
  blockId: string,
  options?: { immediate?: boolean; socketUpdate?: boolean }
) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, blockId);

  const newTags: any[] = getTagDiff(newValueTags, prevValueTags);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removedTags: any[] = getTagDiff(prevValueTags, newValueTags);
  newTags.forEach((newTag) => {
    if (!options?.socketUpdate) checkCorrectLinking(newTag);
    store.dispatch({
      type: ADD_MENTION,
      param: {
        blockId: block.id,
        containerId: block.containerId,
        containerType: block.containerType,
        referencingContainerId: newTag.el.linkTo,
      },
    });
  });

  removedTags.forEach((removedTag) => {
    if (options?.immediate) {
      store.dispatch({
        type: REMOVE_MENTION,
        param: {
          blockId: block.id,
          containerId: block.containerId,
          containerType: block.containerType,
          referencingContainerId: removedTag.el.linkTo,
        },
      });
    } else {
      if (!containerCleanupDict[removedTag.el.linkTo])
        containerCleanupDict[removedTag.el.linkTo] = [];
      containerCleanupDict[removedTag.el.linkTo].push(() => {
        store.dispatch({
          type: REMOVE_MENTION,
          param: {
            blockId: block.id,
            containerId: block.containerId,
            containerType: block.containerType,
            referencingContainerId: removedTag.el.linkTo,
          },
        });
      });
    }
  });

  const blocksWithId = document.querySelectorAll(
    `[data-block-id="${blockId}"]`
  );

  blocksWithId.forEach((block) => {
    const nowTags = block?.querySelectorAll("mention");

    nowTags?.forEach((tt: any) => {
      for (const tag of newTags) {
        let tagText;
        if (tag.el.options && tag.el.options.hashtag) {
          tagText = tt.textContent?.substr(1);
          tt.contentEditable = "false";
        } else tagText = tt.textContent;
        if (tagText?.trim() === tag.text.trim()) {
          tt.setAttribute("href", tag.el.linkTo);
          tt.classList.remove("not-active");
          break;
        }
      }
    });
  });
};

const getCitationsDiff = (searchIn: any[], searchFor: any[]) => {
  const citationsDiff = [];
  for (const citation of searchIn) {
    let found = false;
    for (const nextCitation of searchFor) {
      if (citation.id === nextCitation.id) {
        found = true;
        break;
      }
    }
    if (!found) citationsDiff.push(citation);
  }
  return citationsDiff;
};

const getTagDiff = (searchIn: any[], searchFor: any[]) => {
  const tagDiff = [];
  for (const tag of searchIn) {
    let found = false;
    for (const nextTag of searchFor) {
      if (tag.text === nextTag.text) {
        found = true;
        break;
      }
    }
    if (!found) tagDiff.push(tag);
  }
  return tagDiff;
};

const checkCorrectLinking = (newTag: any) => {
  const tagToExistingPage = checkIfNewTag(newTag.text);
  if (tagToExistingPage.id) {
    newTag.el.linkTo = tagToExistingPage.id;
    newTag.el.children = tagToExistingPage.nameValue;
  } else {
    const newTitleValue: ILineValue[] = [
      {
        type: LineValueType.title,
        value: newTag.el.value,
        children: [...newTag.el.children],
      },
    ];
    const document = handleNewPageLocal(newTag.text.trim(), newTitleValue);
    newTag.el.linkTo = document.id;
    store.dispatch({
      type: ADD_NEW_DOCUMENT,
      document,
    });

    handleNewDocumentCreation(
      prevState.value.workspace.id,
      IDocumentCreationMethods.TAG,
      newTag.text.trim(),
      document.nameValue,
      document.id
    );
  }
};

const checkIfNewTag = (tag: string): any | undefined => {
  const documents: any = store.getState().pages.dict;
  const documentArray: IPageObj[] = Object.values(documents);
  for (const document of documentArray) {
    if (tag.trim().toLowerCase() === document.name) return document;
  }
  return false;
};

export const iterateThroughValue = (
  valuePart: ILineValue[],
  arrayOfMentions: any[],
  arrayOfCitations: any[],
  parentMentionText?: string,
  parentIsHashtag?: boolean,
  options?: {
    immediate?: boolean;
  }
): string[] => {
  valuePart.forEach((val: ILineValue) => {
    if (val.type === LineValueType.mention && !val.options?.workTag) {
      if (!val.options?.hashtag || !val.linkTo || options?.immediate) {
        let value = val.value;
        if (val.options?.hashtag) {
          parentIsHashtag = true;
        }
        parentMentionText = parentMentionText
          ? parentMentionText + value
          : value;
        if (val.children)
          parentMentionText = iterateThroughMentionChildren(
            val.children,
            parentMentionText,
            parentIsHashtag
          );
        arrayOfMentions.push({ el: val, text: parentMentionText });
      }
    }

    if (val.type === LineValueType.citation) {
      arrayOfCitations.push({
        id: val.options.citationId,
        type: "citation",
        el: val,
      });
    }

    if (val.children) {
      parentMentionText = "";
      iterateThroughValue(
        val.children,
        arrayOfMentions,
        arrayOfCitations,
        parentMentionText,
        parentIsHashtag
      );
    }
  });
  return arrayOfMentions;
};

const iterateThroughMentionChildren = (
  childArray: ILineValue[],
  parentMentionText: string,
  parentIsHashtag?: boolean
): string => {
  childArray.forEach((val: ILineValue) => {
    let value = val.value;
    if (parentIsHashtag) {
      if (value[0] === "#") value = value.substr(1);
    }
    parentMentionText = parentMentionText ? parentMentionText + value : value;
    if (val.children) {
      parentMentionText = iterateThroughMentionChildren(
        val.children,
        parentMentionText,
        parentIsHashtag
      );
    }
  });
  return parentMentionText;
};

export const handleInlineCodeStylingAndSave = (
  event: any,
  props: BlockPropsWithRef,
  target: HTMLDivElement | null,
  type?: LineValueType.highlight | LineValueType.code
) => {
  if (!target) return;
  const newEvent = handleInlineCodeStyling(event, target, type);
  if (!newEvent) return;
  updateBlockText(props.blockData, newEvent, props.context);
};

export const handleInlineCodeStyling = (
  event: any,
  target: HTMLDivElement,
  type?: LineValueType.highlight | LineValueType.code
) => {
  const selection = document.getSelection();
  let nodeName: string = "";
  let caretPosition = checkCaretPosition(target);
  let newEvent;

  if (!type) nodeName = "code";
  else {
    switch (type) {
      case LineValueType.highlight:
        nodeName = "highlight";
        break;
      default:
        nodeName = "code";
    }
  }
  if (selection) {
    event.preventDefault();
    const text = selection.toString();
    if (selection.focusNode) {
      const checkCode: LineValueType[] = checkIfInsideCode(selection.focusNode);
      if (checkCode.includes(type ? type : LineValueType.code)) {
        if (text.length > 0) {
          const typeNode =
            selection.focusNode?.parentElement?.closest(nodeName);
          if (typeNode) {
            const range = selection.getRangeAt(0);
            const startInType = typeNode.contains(range.startContainer);
            const endInType = typeNode.contains(range.endContainer);
            if (startInType && endInType) {
              if (typeNode) {
                const el = document.createElement("span");
                const fragment = range.cloneContents();
                let prevFragment;
                let nextFragment;
                el.append(range.cloneContents());
                range.deleteContents();
                range.insertNode(el);
                const provRange = new Range();
                if (el.previousSibling) {
                  provRange.selectNode(el.previousSibling);
                  const prov = provRange.cloneContents();
                  if (prov.textContent && prov.textContent.length > 0) {
                    prevFragment = document.createElement(nodeName);
                    prevFragment.appendChild(prov);
                    if (nodeName === "code")
                      prevFragment.classList.add("code-style");
                  }
                }
                if (el.nextSibling) {
                  provRange.selectNode(el.nextSibling);
                  const prov = provRange.cloneContents();
                  if (prov.textContent && prov.textContent.length > 0) {
                    nextFragment = document.createElement(nodeName);
                    nextFragment.appendChild(prov);
                    if (nodeName === "code")
                      nextFragment.classList.add("code-style");
                  }
                }
                provRange.selectNode(typeNode);
                provRange.deleteContents();
                const firstChild = getFirstChild(fragment);
                const lastChild = getLastChild(fragment);
                if (nextFragment) provRange.insertNode(nextFragment);
                if (fragment) provRange.insertNode(fragment);
                if (prevFragment) provRange.insertNode(prevFragment);
                const newRange = range.cloneRange();
                selection.removeAllRanges();
                selection.addRange(newRange);
                if (firstChild && lastChild) {
                  newRange.setStart(firstChild, 0);
                  newRange.setEnd(
                    lastChild,
                    lastChild.textContent ? lastChild.textContent.length : 0
                  );
                }
                selection.removeAllRanges();
                selection.addRange(newRange);
              }
            } else {
              if (selection.containsNode(typeNode)) {
              } else if (startInType) {
                const newRange = range.cloneRange();
                newRange.setStartAfter(typeNode);
                const fragment = newRange.cloneContents();
                newRange.deleteContents();
                typeNode.appendChild(fragment);
              } else if (endInType) {
                const newRange = range.cloneRange();
                newRange.setEndAfter(typeNode);
                const fragment = newRange.cloneContents();
                newRange.deleteContents();
                typeNode.prepend(fragment);
              }
            }
          }
        } else {
          const parentCode = selection.focusNode?.parentElement?.closest(
            type ? type : LineValueType.code
          );
          let innerCaretPosition = getInnerCaretPosition(
            target,
            parentCode,
            caretPosition
          );
          if (parentCode) {
            const range: Range = selection.getRangeAt(0);
            let firstHalf = parentCode.textContent
              ? parentCode.textContent.substr(0, innerCaretPosition)
              : "";
            let secondHalf = parentCode.textContent
              ? parentCode.textContent.substr(innerCaretPosition)
              : "";
            const newRangex = range.cloneRange();
            firstHalf = firstHalf.replace(/\uFEFF/g, ``);
            secondHalf = secondHalf.replace(/\uFEFF/g, ``);
            newRangex.setStartBefore(parentCode);
            newRangex.setEnd(range.startContainer, range.startOffset);
            let firstHalfContent = newRangex.cloneContents();
            newRangex.setStart(range.startContainer, range.startOffset);
            newRangex.setEndAfter(parentCode);
            let secondHalfContnet = newRangex.cloneContents();
            parentCode?.remove();
            let codeLine1: DocumentFragment = new DocumentFragment();
            if (firstHalf.length > 0) {
              codeLine1 = firstHalfContent;
            }

            let codeLine2: DocumentFragment = new DocumentFragment();
            if (secondHalf.length > 0) {
              codeLine2 = secondHalfContnet;
            }
            const textPart =
              firstHalf || secondHalf
                ? document.createTextNode("\uFEFF")
                : null;
            if (codeLine2) range.insertNode(codeLine2);
            if (textPart) range.insertNode(textPart);
            if (codeLine1) range.insertNode(codeLine1);

            const adding = firstHalf ? 1 : -1;
            caretPosition =
              caretPosition > 0 ? caretPosition + adding : caretPosition + 1;
            if (target) moveCaretToPreviousPosition(target, caretPosition);
          }
        }
      } else {
        const range: Range = selection.getRangeAt(0);
        const nrOfCharacters: number = text.length;
        const textToPut = nrOfCharacters > 0 ? text : "&#65279";
        let check;
        if (text.length === 0 && selection.focusNode.parentElement)
          check = checkIfPreviousEmptyAndHasACodeBlock(
            selection.focusNode.parentElement,
            target,
            caretPosition,
            range
          );
        if (!check) {
          const range: Range = selection.getRangeAt(0);
          const child = range.cloneContents();
          if (child.textContent?.length === 0)
            child.appendChild(document.createTextNode("\uFEFF"));
          const codeLine = document.createElement(nodeName);
          if (nodeName === "code") {
            codeLine.className = "code-style";
          }
          codeLine.appendChild(child);

          if (textToPut !== "&#65279") {
            range.deleteContents();
          } else caretPosition = caretPosition + 1;
          range.insertNode(codeLine);
          const innerCodes = codeLine.getElementsByTagName(nodeName);
          for (const innerCode of Array.from(innerCodes)) {
            innerCode.outerHTML = innerCode.innerHTML;
          }
          const newRange = range.cloneRange();
          selection.removeAllRanges();
          selection.addRange(newRange);
          newRange.setStart(getFirstChild(codeLine), 0);
          const lastChild: Node = getLastChild(codeLine);
          newRange.setEnd(
            lastChild,
            lastChild.textContent ? lastChild.textContent.length : 0
          );

          newEvent = {
            currentTarget: target,
          };
        }
      }
    }
    newEvent = {
      currentTarget: target,
    };
  }
  return newEvent;
};

export const handleCreateLink = (event: any) => {
  event.preventDefault();
  const selection = document.getSelection();
  if (selection) {
    if (selection.focusNode) {
      const parent = selection.focusNode.parentElement;
      if (parent && parent.nodeName === LineValueType.a) {
        document.execCommand("unlink", false);
      } else {
        let linkURL = prompt("Enter a URL:", "http://");
        if (selection && linkURL !== "" && linkURL !== null) {
          const range = selection.getRangeAt(0);

          const newOne = document.createElement("a");
          newOne.href = linkURL;
          newOne.innerHTML = selection.toString();
          newOne.target = "_blank";
          newOne.classList.add("decorated");
          range.deleteContents();
          range.insertNode(newOne);
        }
      }
    }
  }
};

export const handleStartComment = (props: any) => {
  const selection = document.getSelection();
  const selectionText = selection?.toString();
  blockApi.blockAction.commentOnBlock(props.blockData.id, selectionText);
};

export const createTag = (name: any) => {
  if (typeof name !== "string") name = getHtml(name);
  const selection = document.getSelection();
  if (selection) {
    const range: Range | null =
      selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (range) {
      const mention = document.createElement("mention");
      mention.classList.add("mention");
      mention.innerHTML = name;
      mention.innerHTML = mention.innerHTML.replace(/\uFEFF/g, ``);
      const textNode = document.createTextNode("");
      range.extractContents();
      const fragment = document.createDocumentFragment();
      fragment.appendChild(mention);
      fragment.appendChild(textNode);
      range.insertNode(fragment);
      const newRange = range.cloneRange();
      newRange.collapse();
      selection.removeAllRanges();
      selection.addRange(newRange);
      newRange.setStartAfter(textNode);
    }
  } else return;
  const user = store.getState().user;
  if (user && user.id) {
    mixpanel.track(UserEventTypes.PAGE_MENTION_CREATED, {
      distinct_id: user.id,
    });
  }
};

export const createHashTag = (name: any, option?: any) => {
  if (typeof name !== "string") name = stripHtml(getHtml(name));
  const selection = document.getSelection();
  if (selection) {
    const range: Range | null =
      selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (range) {
      const mention = document.createElement("mention");
      mention.classList.add("mention");
      mention.classList.add("hashtag");
      mention.innerHTML = `#${name}`;
      mention.contentEditable = "false";
      mention.innerHTML = mention.innerHTML.replace(/\uFEFF/g, ``);
      if (option && option.projectId) {
        mention.setAttribute("href", option.id);
        if (option.projectId) {
          mention.setAttribute("work-tag", "true");
          mention.setAttribute("work-nr", option.projectId);
          mention.innerHTML = getCorrectPrefixForWorkItem(option) + ` ${name}`;
          // if (option.workType === WorkTypes.TASK) {

          // } else mention.innerHTML = `${name}`;
        }
      }
      if (option && option.projectId) {
        const user = store.getState().user;
        if (user && user.id) {
          mixpanel.track(UserEventTypes.WORK_MENTION_CREATED, {
            distinct_id: user.id,
          });
        }
      } else {
        const user = store.getState().user;
        if (user && user.id) {
          mixpanel.track(UserEventTypes.PAGE_MENTION_CREATED, {
            distinct_id: user.id,
          });
        }
      }

      const textNode = document.createTextNode(" ");
      range.deleteContents();
      const newRange = range.cloneRange();
      newRange.insertNode(mention);
      newRange.collapse(false);
      newRange.insertNode(textNode);

      selection.removeAllRanges();
      selection.addRange(newRange);
      newRange.setStartAfter(textNode);
    }
  }
};

export const tagSelectionCreation = (
  event: SyntheticEvent<any>,
  props: BlockPropsWithRef
) => {
  const selection = document.getSelection();
  if (!selection) return;

  const range: Range = selection.getRangeAt(0);

  event.preventDefault();
  const prev = [];
  if (!props.blockRef.current) return;
  for (const child of Array.from(props.blockRef.current.childNodes)) {
    const entry = breakDownHtml(child);
    if (entry) {
      if (entry.value.length > 1 && entry.value.endsWith("#"))
        entry.value = entry.value.substring(0, entry.value.length - 1);
      if (entry.value !== "#") prev.push(entry);
    }
  }
  const newText = getSelectionHtml();
  const newPlainText = selection.toString();

  const shouldNotCreateTag = checkIfShouldNotCreateTag(range, newPlainText);
  if (shouldNotCreateTag) return;

  if (range.startContainer !== range.endContainer) {
    if (
      range.startContainer.parentElement &&
      range.endContainer.parentElement
    ) {
      if (
        (checkIfInsideMention(range.startContainer.parentElement) &&
          !checkifBeginingOfTag(range)) ||
        (checkIfInsideMention(range.endContainer.parentElement) &&
          !checkIfEndOfTag(range))
      ) {
        document.execCommand("insertHTML", false, newText);
      } else if (newText) {
        createTag(newText);
      }
    }
  } else {
    if (
      range.endContainer.parentElement &&
      checkIfInsideMention(range.endContainer.parentElement) &&
      range.startOffset === 0 &&
      range.endOffset === range.endContainer.textContent?.length
    ) {
      return;
    }
    if (newText) {
      createTag(newText);
    }
  }

  checkIfEmptyTagCreated(event.currentTarget);
  updateImmediateText(props.blockData, props.blockRef, props.context);
  return;
};

export const titleTagCreation = (
  event: any,
  selection: Selection,
  titleRef: any
) => {
  const range: Range = selection.getRangeAt(0);
  event.preventDefault();
  const prev = [];
  for (const child of titleRef.current.childNodes) {
    const entry = breakDownHtml(child);
    if (entry) {
      if (entry.value.length > 1 && entry.value.endsWith("#"))
        entry.value = entry.value.substring(0, entry.value.length - 1);
      if (entry.value !== "#") prev.push(entry);
    }
  }
  const newText = getSelectionHtml();
  const newPlainText = selection.toString();
  const oldText = titleRef.current.textContent;

  const shouldNotCreateTag = checkIfShouldNotCreateTag(range, newPlainText);
  if (shouldNotCreateTag) return;

  if (newPlainText === oldText) {
    range.collapse();
    return;
  }

  if (range.startContainer !== range.endContainer) {
    if (
      range.startContainer.parentElement &&
      range.endContainer.parentElement
    ) {
      if (
        (checkIfInsideMention(range.startContainer.parentElement) &&
          !checkifBeginingOfTag(range)) ||
        (checkIfInsideMention(range.endContainer.parentElement) &&
          !checkIfEndOfTag(range))
      ) {
        document.execCommand("insertHTML", false, newText);
      } else if (newText) {
        createTag(newText);
      }
    }
  } else {
    if (
      range.endContainer.parentElement &&
      checkIfInsideMention(range.endContainer.parentElement) &&
      range.startOffset === 0 &&
      range.endOffset === range.endContainer.textContent?.length
    ) {
      return;
    }
    if (newText) {
      createTag(newText);
    }
  }

  checkIfEmptyTagCreated(titleRef.current);
  return;
};

export const updateMultipleBlocksText = (
  updatedBlocks: IdValue[],
  context: IBlockContext
) => {
  const saveActions: ActionObject[] = [];
  batch(() => {
    updatedBlocks.forEach(({ id, value }) => {
      const delta = {
        value: value,
        dateModified: new Date(),
        modifiedBy: prevState.value.user?.id,
      };

      const action: ActionObject = {
        id: id,
        type: BlockActionTypes.update,
        delta,
      };
      saveActions.push(action);
      handleUpdateAction(action);
    });
  });
  setUpdateObjectToStore(saveActions, context);
};

export const setMultipleBlocks = (
  updatedBlocks: { id: string; blockData: BlockData }[],
  context: IBlockContext
) => {
  const saveActions: ActionObject[] = [];
  batch(() => {
    updatedBlocks.forEach(({ id, blockData }) => {
      const delta = blockData;
      const action: ActionObject = {
        id: id,
        type: BlockActionTypes.insert,
        delta,
        options: {
          newBlock: true,
        },
      };
      saveActions.push(action);
    });
  });
  setUpdateObjectToStore(saveActions, context);
};
