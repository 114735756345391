import { batch } from "react-redux";
import { REFOCUS, SET_UNDO } from "store/actions";
import { Block, BlockObject } from "store/reducers/blockReducer";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $focusOn, prevState } from "store/storeExporter";
import { IBlockContext } from "utilities/types";
import { BlockActionTypes, getAntiAction } from "../blockActions";
import {
  getAntiDelta,
  handleUpdateAction,
} from "../primitiveActions/primitiveActions";
import { isAnyChildUnfolded } from "./blockTreeCheckingUtils";
import { setUpdateObjectToStore } from "./persistActions";
import {
  ActionObject,
  ActionWrapperObject,
  createActionWrapperObject,
} from "./undoUtils";

export const toggleBlockCollapse = (toggleProps: {
  blockId: string;
  context: any;
  presetFoldMode?: boolean;
  shiftKey?: boolean;
}) => {
  const newState = store.getState().blocks;
  const selectedBlocks =
    newState.selectedBlocks.length > 0
      ? newState.selectedBlocks
      : [toggleProps.blockId];

  const saveActions: ActionObject[] = [];
  const undoObject: ActionWrapperObject = createActionWrapperObject(
    toggleProps.context
  );

  const delta: Partial<Block> = {
    dateModified: new Date(),
    modifiedBy: prevState.value.user?.id,
  };

  if (toggleProps.shiftKey) {
    checkShiftCollapse({
      selectedBlocks,
      dict: newState.dict,
      undoObject: undoObject,
      saveActions: saveActions,
    });
  } else {
    if (toggleProps.presetFoldMode || toggleProps.presetFoldMode === false) {
      selectedBlocks.forEach((id) => {
        const block = getBlockById(newState.dict, id);
        if (block.isFolded === toggleProps.presetFoldMode) return;
        delta.isFolded = toggleProps.presetFoldMode;
        const action: ActionObject = {
          id,
          delta,
          type: BlockActionTypes.update,
        };
        const antiAction = getAntiAction(action);
        handleUpdateAction(action);
        saveActions.push(action);
        undoObject.actions.push(antiAction);
      });
    } else {
      checkForNormalToggle({
        selectedBlocks,
        dict: newState.dict,
        undoObject: undoObject,
        saveActions: saveActions,
      });
    }
  }

  if (saveActions.length > 0) {
    store.dispatch({
      type: REFOCUS,
      param: {
        newFocus: { ...$focusOn.value },
      },
    });

    store.dispatch({
      type: SET_UNDO,
      param: {
        undoObject,
        contextId: toggleProps.context.id,
      },
    });
    setUpdateObjectToStore(saveActions, toggleProps.context);
  }

  return true;
};

export const unfoldAllAncestors = (toggleProps: {
  blockId: string;
  context: IBlockContext;
}) => {
  const saveActions: ActionObject[] = [];
  const undoObject: ActionWrapperObject = createActionWrapperObject(
    toggleProps.context
  );

  const delta: Partial<Block> = {
    dateModified: new Date(),
    modifiedBy: prevState.value.user?.id,
  };
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, toggleProps.blockId);
  batch(() => {
    if (block.parentId && block.parentId !== "") {
      handleSingleBlockUnfold({
        blockId: block.parentId,
        dict: newState.dict,
        delta,
        saveActions,
        undoObject,
      });
    }
    if (saveActions.length > 0) {
      store.dispatch({
        type: REFOCUS,
        param: {
          newFocus: { ...$focusOn.value },
        },
      });

      store.dispatch({
        type: SET_UNDO,
        param: {
          undoObject,
          contextId: toggleProps.context.id,
        },
      });
      setUpdateObjectToStore(saveActions, toggleProps.context);
    }
  });
};

const handleSingleBlockUnfold = (toggleProps: {
  blockId: string;
  dict: BlockObject;
  delta: Partial<Block>;
  saveActions: ActionObject[];
  undoObject: ActionWrapperObject;
}) => {
  const block = getBlockById(toggleProps.dict, toggleProps.blockId);
  if (block.isFolded) {
    const delta = { ...toggleProps.delta };
    const action: ActionObject = {
      id: block.id,
      delta,
      type: BlockActionTypes.update,
    };
    delta.isFolded = false;
    const antiAction = getAntiAction(action);
    toggleProps.saveActions.push(action);
    toggleProps.undoObject.actions.push(antiAction);
    handleUpdateAction(action, true);
  }
  if (block.parentId && block.parentId !== "") {
    handleSingleBlockUnfold({
      blockId: block.parentId,
      dict: toggleProps.dict,
      delta: toggleProps.delta,
      saveActions: toggleProps.saveActions,
      undoObject: toggleProps.undoObject,
    });
  }
};

const checkForNormalToggle = (props: {
  selectedBlocks: string[];
  dict: BlockObject;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
}) => {
  const newState = store.getState().blocks;
  let presets = {
    isCollapsed: false,
    onlyRoot: true,
    deep: false,
    skipRoot: false,
  };

  let hasNonCollapsedParent = false;
  for (const blockId of props.selectedBlocks) {
    const block = getBlockById(newState.dict, blockId);
    if (block.children.length > 0 && !block.isFolded) {
      hasNonCollapsedParent = true;
      break;
    }
  }

  if (hasNonCollapsedParent) {
    presets = {
      deep: false,
      skipRoot: false,
      onlyRoot: true,
      isCollapsed: true,
    };
  } else {
    presets = {
      deep: false,
      skipRoot: false,
      onlyRoot: true,
      isCollapsed: false,
    };
  }
  batch(() => {
    handleBlockArrayToggle({
      blockIdArray: props.selectedBlocks,
      dict: props.dict,
      presets: presets,
      undoObject: props.undoObject,
      saveActions: props.saveActions,
      root: true,
    });
  });
};

const checkShiftCollapse = (props: {
  selectedBlocks: string[];
  dict: BlockObject;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
}) => {
  const newState = store.getState().blocks;
  let presets = {
    isCollapsed: false,
    deep: false,
    skipRoot: false,
    onlyRoot: false,
  };
  let hasNotCollapsedChildren = false;
  let hasCollapsedParent = false;
  for (const blockId of props.selectedBlocks) {
    const block = getBlockById(newState.dict, blockId);
    if (block.children.length > 0 && block.isFolded) {
      hasCollapsedParent = true;
      break;
    }
    const hasAnyUnflodedChild = isAnyChildUnfolded(block);
    if (hasAnyUnflodedChild) {
      hasNotCollapsedChildren = true;
      break;
    }
  }

  if (hasCollapsedParent) {
    presets = {
      deep: true,
      skipRoot: false,
      isCollapsed: false,
      onlyRoot: false,
    };
  } else if (hasNotCollapsedChildren) {
    presets = {
      deep: false,
      skipRoot: true,
      isCollapsed: true,
      onlyRoot: false,
    };
  } else {
    presets = {
      deep: true,
      skipRoot: false,
      isCollapsed: false,
      onlyRoot: false,
    };
  }

  batch(() => {
    handleBlockArrayToggle({
      blockIdArray: props.selectedBlocks,
      dict: props.dict,
      presets: presets,
      undoObject: props.undoObject,
      saveActions: props.saveActions,
      root: true,
    });
  });
};

const handleBlockArrayToggle = (props: {
  blockIdArray: string[];
  dict: BlockObject;
  presets: {
    isCollapsed: boolean;
    deep: boolean;
    skipRoot: boolean;
    onlyRoot: boolean;
  };
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
  root: boolean;
}) => {
  props.blockIdArray.forEach((id) => {
    const block = getBlockById(props.dict, id);
    if (!props.presets.skipRoot || !props.root) {
      if (block.isFolded !== props.presets.isCollapsed) {
        const delta: Partial<Block> = {
          dateModified: new Date(),
          modifiedBy: prevState.value.user?.id,
          isFolded: props.presets.isCollapsed,
        };
        const action: ActionObject = {
          id: block.id,
          delta,
          type: BlockActionTypes.update,
        };
        props.saveActions.push(action);
        const antiDelta = getAntiDelta(delta, block);
        const antiAction: ActionObject = {
          id: block.id,
          delta: antiDelta,
          type: BlockActionTypes.update,
        };
        props.undoObject.actions.push(antiAction);
        handleUpdateAction(action, true);
      }
    }
    if (props.root && !props.presets.onlyRoot) {
      if (block.children?.length > 0) {
        handleBlockArrayToggle({
          blockIdArray: block.children,
          dict: props.dict,
          presets: props.presets,
          undoObject: props.undoObject,
          saveActions: props.saveActions,
          root: false,
        });
      }
    } else {
      if (props.presets.deep && props.presets.isCollapsed === false) {
        if (block.children?.length > 0) {
          handleBlockArrayToggle({
            blockIdArray: block.children,
            dict: props.dict,
            presets: props.presets,
            undoObject: props.undoObject,
            saveActions: props.saveActions,
            root: false,
          });
        }
      }
    }
  });
};
