import { SyntheticEvent } from "react";
import { Block, FocusObject, FocusType } from "store/reducers/blockReducer";
import store, { $documentMode, $focusOn, prevState } from "store/storeExporter";
import * as actionTypes from "store/actions";
import { batch } from "react-redux";
import { batchActions } from "redux-batched-actions";
import { createRange } from "utilities/caretMovement";
import {
  allowChildBlocks,
  entityKeepTypes,
  ILineValue,
  LineStatus,
  LineType,
  LineValueType,
} from "utilities/lineUtilities";

import {
  getCurrentContext,
  getPreviousBlock,
  getNextBlockId,
  getPreviousSibling,
  getBlockById,
  getNextSibling,
  checkIfBlockIsTitle,
  getSortedSelectedBlocks,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";

import { TypeOfLineMovement } from "utilities/movementTypes";
import {
  BlockMenuTypes,
  BlockStateMenu,
  ContainerTypes,
  DocumentModes,
  IBlockContext,
  UserRole,
} from "utilities/types";
import * as KeyEvents from "keycode-js";
import {
  addBlock,
  ADD_BLOCK_TYPES,
  handleSoftReturn,
} from "./specificActions/addBlockActions";
import { handleBlockDelete } from "./specificActions/deleteBlockActions";
import { checkFocusTitle, moveBlocks } from "./specificActions/moveActions";
import { BlockProps } from "editor/blockContainer/Block";
import {
  handleCreateLink,
  handleInlineCodeStylingAndSave,
  handleStartComment,
  tagSelectionCreation,
} from "./specificActions/textUpdateActions";
import {
  ActionObject,
  ActionWrapperObject,
  createActionWrapperObject,
} from "./specificActions/undoUtils";
import {
  handleBlockNativeStylingSave,
  removeEmptyNodes,
  removeFromPosition,
} from "./specificActions/textUpdateUtils";
import { BehaviorSubject } from "rxjs";
import { resetBlockMenu } from "./blockMenusActions/menuActions";
import {
  changeParentListType,
  changeSingleBlockType,
  cycleOutlineMode,
} from "./specificActions/blockTypesActions";
import { BlockPropsWithRef } from "editor/blockContainer/BlockSplitter";
import {
  addedSlashMenuOptions,
  slashMenuOptions,
} from "editor/blockMenus/blockMenuVariants/SlashCommandMenu";
import {
  getAntiDelta,
  handleUpdateAction,
  updateBlockAndHandleSideEffects,
  updateSingleBlock,
} from "./primitiveActions/primitiveActions";
import { checkCheckboxCases } from "./blockModeEvents";
import { checkIfSelectionHasText } from "./specificActions/textCheckingUtils";
import { toggleBlockCollapse } from "./specificActions/collapseActions";
import { setUpdateObjectToStore } from "./specificActions/persistActions";
import { clearappClipboardData } from "App";
import { DebouncedFunc } from "lodash";
import { blockApi } from "./blockActionsApi";
import { checkIfCannotAddBlocks } from "modules/appService";
import { breakDownHtml } from "./blockValueHelpers";
import {
  checkCaretPosition,
  checkSelectionDetails,
  getSelectionTextInfo,
  isSelectionBackwards,
} from "./caretUtils";

export enum BlockActionTypes {
  update = "update",
  insert = "insert",
  recoverDelete = "recoverDelete",
  move = "move",
  delete = "delete",
}

export const checkEditableEvent = (
  e: React.KeyboardEvent<HTMLDivElement>,
  props: BlockPropsWithRef,
  menuStateRef: React.MutableRefObject<BehaviorSubject<BlockStateMenu>>,
  throttleRef: React.MutableRefObject<DebouncedFunc<(e: any) => void>>
) => {
  if (menuStateRef.current.value.isOpened && props.context.canEdit) {
    switch (e.key) {
      case KeyEvents.VALUE_UP: {
        e.preventDefault();
        e.stopPropagation();
        if (menuStateRef.current.value.hoveredItem > 0) {
          const state = { ...menuStateRef.current.value };
          state.hoveredItem = state.hoveredItem - 1;
          menuStateRef.current.next(state);
        }
        return;
      }
      case KeyEvents.VALUE_DOWN: {
        e.preventDefault();
        e.stopPropagation();
        const state = { ...menuStateRef.current.value };
        state.hoveredItem = state.hoveredItem + 1;
        menuStateRef.current.next(state);
        return;
      }
      case KeyEvents.VALUE_TAB: {
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          if (menuStateRef.current.value.hoveredItem > 0) {
            const state = { ...menuStateRef.current.value };
            state.hoveredItem = state.hoveredItem - 1;
            menuStateRef.current.next(state);
          }
        } else {
          const state = { ...menuStateRef.current.value };
          state.hoveredItem = state.hoveredItem + 1;
          menuStateRef.current.next(state);
        }
        return;
      }
      case KeyEvents.VALUE_ENTER: {
        e.preventDefault();
        e.stopPropagation();
        const state = { ...menuStateRef.current.value };
        state.executeSelection = true;
        menuStateRef.current.next(state);
        return;
      }
    }
  }
  switch (e.key) {
    case KeyEvents.VALUE_ESCAPE: {
      e.preventDefault();
      e.stopPropagation();
      if (menuStateRef.current.value.isOpened) {
        resetBlockMenu(menuStateRef);
        return;
      }
      throttleRef.current.flush();
      toggleDocumentMode(props.blockData.id);
      break;
    }

    case KeyEvents.VALUE_A:
    case KeyEvents.VALUE_A.toUpperCase(): {
      if (e.ctrlKey || e.metaKey) {
        e.stopPropagation();
        const selection = document.getSelection();
        if (selection?.toString() !== props.blockRef.current?.textContent)
          return;
        selection?.collapseToEnd();
        e.preventDefault();
        batch(() => {
          if (props.context.type === "container") {
            toggleDocumentMode(props.blockData.id, DocumentModes.BLOCK);
            store.dispatch({
              type: actionTypes.SELECT_ALL_BLOCKS,
              param: {
                contextId: props.context.id,
              },
            });
          }
        });
      }
      break;
    }

    case KeyEvents.VALUE_TAB: {
      e.preventDefault();
      e.stopPropagation();
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      throttleRef.current.flush();
      if (e.shiftKey) handleBlockOutdent(props.blockData.id, props);
      else handleBlockIndent(props);
      break;
    }

    case KeyEvents.VALUE_SLASH: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (menuStateRef.current.value.isOpened === false) {
        const caretPosition = checkCaretPosition(e.currentTarget);
        if (caretPosition > 0) {
          const prevChar = e.currentTarget.textContent?.charAt(
            caretPosition - 1
          );
          if (prevChar && prevChar !== " ") break;
        }
        e.stopPropagation();
        e.preventDefault();
        throttleRef.current.flush();
        const currentMenuValue = { ...menuStateRef.current.value };
        menuStateRef.current.next({
          ...currentMenuValue,
          isOpened: true,
          type: BlockMenuTypes.slashMenu,
        });
      }
      break;
    }

    case KeyEvents.VALUE_U:
    case KeyEvents.VALUE_U.toUpperCase(): {
      const userRole = store.getState().client.roleType;

      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.metaKey || e.ctrlKey) {
        if (
          !menuStateRef.current.value.isOpened &&
          userRole !== UserRole.GUEST
        ) {
          e.stopPropagation();
          e.preventDefault();
          const selection = document.getSelection();
          const element = selection?.focusNode?.parentElement;

          if (element?.closest("mention")) return;

          const executed = checkTagCreationCases(props, e);
          if (executed) return;
          const currentMenuValue = { ...menuStateRef.current.value };
          menuStateRef.current.next({
            ...currentMenuValue,
            isOpened: true,
            type: BlockMenuTypes.tagsMenu,
          });
        } else {
          const matches =
            menuStateRef.current.value.filterMenuBy.match(/\uFEFF/g);
          const nrOfEmpty = matches ? matches.length : 0;
          if (
            nrOfEmpty === 0 &&
            menuStateRef.current.value.filterMenuBy !== ""
          ) {
            const menuState = { ...menuStateRef.current.value };
            menuStateRef.current.next({ ...menuState, executeSelection: true });
          } else {
            resetBlockMenu(menuStateRef);
          }
          return;
        }
      }
      break;
    }

    case KeyEvents.VALUE_SEMICOLON: {
      const userRole = store.getState().client.roleType;

      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      const caretPosition = checkCaretPosition(props.blockRef.current);
      if (caretPosition > 0) return;
      if (!menuStateRef.current.value.isOpened && userRole !== UserRole.GUEST) {
        e.stopPropagation();
        e.preventDefault();
        const currentMenuValue = { ...menuStateRef.current.value };
        menuStateRef.current.next({
          ...currentMenuValue,
          isOpened: true,
          type: BlockMenuTypes.snippetMenu,
        });
      }
      break;
    }

    case KeyEvents.VALUE_J:
    case KeyEvents.VALUE_J.toUpperCase(): {
      e.stopPropagation();
      break;
    }

    case "#": {
      const userRole = store.getState().client.roleType;
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      const text: string = e.currentTarget.innerText;
      const caretPosition = checkCaretPosition(e.currentTarget);
      if (text.charAt(caretPosition - 1) === "#") {
        if (
          menuStateRef.current.value.isOpened &&
          menuStateRef.current.value.type === BlockMenuTypes.tagsMenu
        ) {
          resetBlockMenu(menuStateRef);
          store.dispatch({
            type: actionTypes.REFOCUS,
            param: {
              newFocus: {
                ...$focusOn.value,
                caretPosition: $focusOn.value.caretPosition,
              },
            },
          });
        }
        return;
      }
      if (!menuStateRef.current.value.isOpened && userRole !== UserRole.GUEST) {
        e.stopPropagation();
        e.preventDefault();
        const currentMenuValue = { ...menuStateRef.current.value };
        menuStateRef.current.next({
          ...currentMenuValue,
          isOpened: true,
          type: BlockMenuTypes.tagsMenu,
          options: { hashtag: true },
        });
      }
      break;
    }

    case "@": {
      const userRole = store.getState().client.roleType;

      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (!menuStateRef.current.value.isOpened && userRole !== UserRole.GUEST) {
        e.stopPropagation();
        e.preventDefault();
        const currentMenuValue = { ...menuStateRef.current.value };
        menuStateRef.current.next({
          ...currentMenuValue,
          isOpened: true,
          type: BlockMenuTypes.userMentions,
        });
      }
      break;
    }

    case KeyEvents.VALUE_OPEN_BRACKET: {
      e.stopPropagation();
      break;
    }

    case KeyEvents.VALUE_CLOSE_BRACKET: {
      e.stopPropagation();
      break;
    }

    case KeyEvents.VALUE_B:
    case KeyEvents.VALUE_B.toUpperCase(): {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.ctrlKey || e.metaKey)
        handleBlockNativeStylingSave(e, "bold", props);
      break;
    }

    case KeyEvents.VALUE_X:
    case KeyEvents.VALUE_X.toUpperCase(): {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
        e.stopPropagation();
        e.preventDefault();
        handleBlockNativeStylingSave(e, "strikethrough", props);
      }
      break;
    }

    case KeyEvents.VALUE_I:
    case KeyEvents.VALUE_I.toUpperCase(): {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.ctrlKey || e.metaKey)
        handleBlockNativeStylingSave(e, "italic", props);
      break;
    }

    case KeyEvents.VALUE_E:
    case KeyEvents.VALUE_E.toUpperCase(): {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.ctrlKey || e.metaKey) {
        e.stopPropagation();
        return handleInlineCodeStylingAndSave(e, props, props.blockRef.current);
      }
      break;
    }

    case KeyEvents.VALUE_K:
    case KeyEvents.VALUE_K.toUpperCase(): {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.metaKey || e.ctrlKey) {
        const selection = document.getSelection();
        if (selection && selection?.toString().length > 0) {
          e.preventDefault();
          e.stopPropagation();
          handleCreateLink(e);
          return updateImmediateText(
            props.blockData,
            props.blockRef,
            props.context
          );
        }
      } else {
        e.stopPropagation();
      }
      break;
    }

    case "µ":
    case "µ".toUpperCase(): {
      // value of m when altKey pressed
      if ((e.ctrlKey || e.metaKey) && e.altKey && props.context.canComment) {
        if (props.blockData.containerType === ContainerTypes.WORK_ACTIVITY)
          break;
        e.preventDefault();
        e.stopPropagation();
        handleStartComment(props);
        break;
      }
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      break;
    }

    case KeyEvents.VALUE_ENTER: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (
        (e.metaKey || e.ctrlKey) &&
        props.context.container.type === ContainerTypes.WORK_ACTIVITY
      ) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();
      throttleRef.current.flush();
      const selection = document.getSelection();
      if (selection && !selection.isCollapsed) {
        selection.deleteFromDocument();
      }

      if (e.shiftKey) {
        handleSoftReturn(e);
        if (props.blockRef.current) {
          updateBlockText(
            props.blockData,
            { currentTarget: props.blockRef.current },
            props.context
          );
        }
        return;
      }
      e.preventDefault();
      handleEnterCases(e, props);
      break;
    }

    case KeyEvents.VALUE_BACK_SPACE: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      const caretPosition = checkCaretPosition(props.blockRef.current);
      e.stopPropagation();
      if (caretPosition === 0) {
        handleBlockBackspace(props);
        e.preventDefault();
      }
      break;
    }

    case KeyEvents.VALUE_DELETE: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      const selection = document.getSelection();
      e.stopPropagation();
      if (selection && selection.toString().length > 0) return;
      const caretPosition = checkCaretPosition(e.currentTarget);
      if (caretPosition === e.currentTarget.innerText.length) {
        e.preventDefault();
        handleDeleteNextBlockInsertMode(props);
      }
      break;
    }

    case KeyEvents.VALUE_UP: {
      e.stopPropagation();
      handleKeyArrowUp(e, props);
      break;
    }

    case KeyEvents.VALUE_DOWN: {
      e.stopPropagation();
      handleKeyArrowDown(e, props);
      break;
    }

    case KeyEvents.VALUE_LEFT: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      e.stopPropagation();
      handleKeyArrowLeft(e, props, menuStateRef);
      break;
    }

    case KeyEvents.VALUE_RIGHT: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      e.stopPropagation();
      handleKeyArrowRight(e, props, menuStateRef);
      break;
    }

    case KeyEvents.VALUE_Z:
    case KeyEvents.VALUE_Z.toUpperCase(): {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          redoAction(props.context);
          break;
        }
        undoAction(props.context);
      }
      break;
    }

    case KeyEvents.VALUE_Y:
    case KeyEvents.VALUE_Y.toUpperCase(): {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          handleInlineCodeStylingAndSave(
            e,
            props,
            props.blockRef.current,
            LineValueType.highlight
          );
          return;
        }
        redoAction(props.context);
      }
      break;
    }

    case KeyEvents.VALUE_SPACE: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      const caretPosition = checkCaretPosition(e.currentTarget);
      e.stopPropagation();
      if (caretPosition <= 3) {
        const text = e.currentTarget.innerText.substring(0, caretPosition);
        checkPrefix(props, e, menuStateRef, text, throttleRef);
      }
      break;
    }

    case KeyEvents.VALUE_C:
    case KeyEvents.VALUE_C.toUpperCase(): {
      if ((e.metaKey || e.ctrlKey) && e.shiftKey) {
        return;
      }
      if ((e.metaKey || e.ctrlKey) && !e.shiftKey) {
        clearappClipboardData();
        e.stopPropagation();
      }
      return;
    }

    case KeyEvents.VALUE_F:
    case KeyEvents.VALUE_F.toUpperCase(): {
      e.stopPropagation();
    }
  }
};

export const checkCodeBlockEvent = (
  e: React.KeyboardEvent<HTMLDivElement>,
  props: BlockPropsWithRef
) => {
  switch (e.key) {
    case KeyEvents.VALUE_UP: {
      e.stopPropagation();
      handleKeyArrowUp(e, props);
      break;
    }

    case KeyEvents.VALUE_DOWN: {
      e.stopPropagation();
      handleKeyArrowDown(e, props);
      break;
    }

    case KeyEvents.VALUE_LEFT: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      e.stopPropagation();
      handleKeyArrowLeft(e, props);
      break;
    }

    case KeyEvents.VALUE_RIGHT: {
      if (!props.context.canEdit) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      e.stopPropagation();
      handleKeyArrowRight(e, props);
      break;
    }
  }
};

export const checkTagCreationCases = (
  props: BlockPropsWithRef,
  event: SyntheticEvent<any>
) => {
  const hasText = checkIfSelectionHasText();

  if (hasText) {
    tagSelectionCreation(event, props);
    return true;
  }
  return false;
};

const checkPrefix = (
  props: BlockPropsWithRef,
  event: React.KeyboardEvent<HTMLDivElement>,
  menuStateRef: React.MutableRefObject<BehaviorSubject<BlockStateMenu>>,
  text: string,
  throttleRef: React.MutableRefObject<DebouncedFunc<(e: any) => void>>
) => {
  const block = { ...props.blockData, ...props.blockData.blockSubject.value };
  if (text.trim().length === 0) return;
  const fullOptions = [...slashMenuOptions, ...addedSlashMenuOptions];
  let checked = fullOptions.filter(
    (option) =>
      (option.prefix === text.trimRight() ||
        option.aliases?.includes(text.trimRight())) &&
      option.filterTrigger(props.blockData, props.context)
  );

  if (checked.length > 0) {
    throttleRef.current.cancel();
    removeFromPosition(text.length);

    event.preventDefault();
    // store.dispatch({
    //   type: actionTypes.REFOCUS,
    //   param: {
    //     newFocus: {
    //       ...$focusOn.value,
    //       focusBlockId: props.blockData.id,
    //       caretPosition: 0,
    //     },
    //   },
    // });
    $focusOn.next({ ...$focusOn.value, caretPosition: 0 });

    if (checked[0].value !== LineType.code)
      updateBlockText(block, event, props.context, true);

    batch(() => {
      resetBlockMenu(menuStateRef);
      checked[0].action({
        menuStateValue: menuStateRef,
        isFirst: props.isFirst,
        menuState: menuStateRef.current.value,
        blockRef: props.blockRef,
        blockData: props.blockData,
        context: props.context,
      });
    });
    return;
  }
};

const handleKeyArrowUp = (
  e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent,
  props: BlockPropsWithRef
) => {
  const newState = store.getState();
  const block = getBlockById(newState.blocks.dict, props.blockData.id);
  if (e.shiftKey && (e.metaKey || e.ctrlKey) && props.context.canEdit) {
    e.stopPropagation();
    e.preventDefault();
    handleMoveBlockSingle(props, "up");
    return;
  }

  if ((e.metaKey || e.ctrlKey) && props.context.canEdit) {
    if (block.children.length > 0) {
      toggleBlockCollapse({
        blockId: props.blockData.id,
        context: props.context,
        presetFoldMode: true,
      });
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  }

  if (e.shiftKey) {
    const selection: any = document.getSelection();
    if (selection && $documentMode.value !== DocumentModes.BLOCK) {
      const range: Range = selection.getRangeAt(0);
      if (range.startOffset > 0) return;
      range.collapse(true);
    }
    e.preventDefault();
    e.stopPropagation();

    if ($documentMode.value === DocumentModes.INSERT) {
      selection.removeAllRanges();
      selectBlock(props.blockData.id);
      return;
    } else {
      batch(() => {
        selectNext({
          id: props.blockData.id,
          type: "up",
          context: props.context,
        });
      });
    }
    return;
  }

  const isOverEl = getSelectionTextInfo(e.target, e);
  if (!isOverEl.atStart && !isOverEl.singleLine) return;
  const caret = checkCaretPosition(props.blockRef.current);
  const blockState = store.getState().blocks;
  const currentContext = getCurrentContext(blockState, props.context.id);
  let prevBlockId = getPreviousBlock(blockState, block, currentContext);

  if (prevBlockId) {
    const prevIsTitle = checkIfBlockIsTitle(newState.blocks, prevBlockId);
    e.preventDefault();
    e.stopPropagation();
    const focusedBlock: any = props.context.ref.current?.querySelectorAll(
      `[data-block-id="${prevBlockId}"]`
    )[0];
    props.changeBlock(
      props.blockData.id,
      focusedBlock,
      e,
      prevIsTitle
        ? TypeOfLineMovement.endOfLine
        : TypeOfLineMovement.previousCursorState,
      caret,
      DocumentModes.INSERT,
      prevBlockId
    );
  } else {
    const titleRef = checkFocusTitle(props.blockData, props.context);
    if (titleRef) {
      e.preventDefault();
      e.stopPropagation();
      props.changeBlock(
        props.blockData.id,
        titleRef,
        e,
        TypeOfLineMovement.previousCursorState,
        0,
        DocumentModes.BLOCK,
        ""
      );
    }
  }
};

const handleKeyArrowDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  props: BlockPropsWithRef
) => {
  const newState = store.getState();
  const block = getBlockById(newState.blocks.dict, props.blockData.id);
  if (e.shiftKey && (e.metaKey || e.ctrlKey) && props.context.canEdit) {
    e.stopPropagation();
    e.preventDefault();
    handleMoveBlockSingle(props, "down");
    return;
  }
  if ((e.metaKey || e.ctrlKey) && props.context.canEdit) {
    if (block.children.length > 0) {
      toggleBlockCollapse({
        blockId: props.blockData.id,
        context: props.context,
        presetFoldMode: false,
      });
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  }
  if (e.shiftKey) {
    const selection: any = document.getSelection();
    const caretPosition = checkCaretPosition(e.currentTarget);
    if (
      selection &&
      $documentMode.value !== DocumentModes.BLOCK &&
      selection.rangeCount > 0
    ) {
      const range: Range = selection.getRangeAt(0);
      if (caretPosition < e.currentTarget.innerText.length) return;
      range.collapse(true);
    }
    e.preventDefault();
    e.stopPropagation();
    if ($documentMode.value === DocumentModes.INSERT) {
      selection.removeAllRanges();
      selectBlock(props.blockData.id);
      return;
    } else {
      batch(() => {
        selectNext({
          id: props.blockData.id,
          type: "down",
          context: props.context,
        });
      });
    }

    return;
  }

  const isOverEl = getSelectionTextInfo(e.currentTarget, e);

  if (!isOverEl.atEnd && !isOverEl.singleLine) return;

  const caret = checkCaretPosition(props.blockRef.current);
  const currentContext = getCurrentContext(newState.blocks, props.context.id);
  const nextBlockId = getNextBlockId(newState.blocks, block, currentContext);

  if (nextBlockId) {
    e.preventDefault();
    e.stopPropagation();
    const focusedBlock: any = props.context.ref.current?.querySelectorAll(
      `[data-block-id="${nextBlockId}"]`
    )[0];
    if (focusedBlock)
      props.changeBlock(
        props.blockData.id,
        focusedBlock,
        e,
        TypeOfLineMovement.previousCursorState,
        caret,
        DocumentModes.INSERT,
        nextBlockId
      );
  }
};

const handleKeyArrowLeft = (
  e: React.KeyboardEvent<HTMLDivElement>,
  props: any,
  menuStateRef?: React.MutableRefObject<BehaviorSubject<BlockStateMenu>>
) => {
  const isOverEl = getSelectionTextInfo(e.target, e);
  const caretPosition = checkSelectionDetails(e.target);
  const backward = isSelectionBackwards();
  const caretLeft = backward
    ? caretPosition.caretEndOffset -
      (e.currentTarget.textContent ? e.currentTarget.textContent.length : 0)
    : caretPosition.caretStartOffset;

  if (e.shiftKey) {
    const selection: any = document.getSelection();
    if (selection && $documentMode.value !== DocumentModes.BLOCK) {
      const range: Range = selection.getRangeAt(0);
      if (
        caretLeft === 0 &&
        (isOverEl.atStart === true || isOverEl.singleLine === true)
      ) {
        range.collapse(true);
        selection.removeAllRanges();
        selectBlock(props.blockData.id);
        e.preventDefault();
        return;
      }
    }
    return;
  }

  const caret = checkCaretPosition(props.blockRef.current);

  if (caret > 0) return;
  const blockState = store.getState().blocks;
  const currentContext = getCurrentContext(blockState, props.context.id);
  const prevBlockId = getPreviousBlock(
    blockState,
    props.blockData.blockSubject.value,
    currentContext
  );
  if (prevBlockId) {
    if (menuStateRef) resetBlockMenu(menuStateRef);
    e.preventDefault();
    e.stopPropagation();
    const focusedBlock: any = props.context.ref.current.querySelectorAll(
      `[data-block-id="${prevBlockId}"]`
    )[0];
    props.changeBlock(
      props.blockData.id,
      focusedBlock,
      e,
      TypeOfLineMovement.endOfLine,
      caret,
      DocumentModes.INSERT,
      prevBlockId
    );
  }
};

const handleKeyArrowRight = (
  e: React.KeyboardEvent<HTMLDivElement> | any,
  props: any,
  menuStateRef?: React.MutableRefObject<BehaviorSubject<BlockStateMenu>>
) => {
  const isOverEl = getSelectionTextInfo(e.target, e);
  let caretPosition = checkCaretPosition(e.currentTarget);

  let text = e.currentTarget.textContent;

  if (e.currentTarget.classList.contains("code")) {
    const el = e.currentTarget.querySelector(".cm-content");
    if (!el) return;
    text = el.textContent;
  }

  text.replace(/(\r\n|\n|\r)/gm, "");

  if (e.shiftKey) {
    const selection: any = window.getSelection();
    if (selection && props.documentMode !== DocumentModes.BLOCK) {
      const range: Range = selection.getRangeAt(0);
      const backward = isSelectionBackwards();
      if (
        ((isOverEl.atEnd === true && caretPosition === text.length) ||
          text.length === 0) &&
        !backward
      ) {
        range.collapse(true);
        e.preventDefault();
        selection.removeAllRanges();
        selectBlock(props.blockData.id);
        return;
      }
      return;
    }
  }
  if (
    (isOverEl.atEnd === true && caretPosition === text.length) ||
    text.length === 0
  ) {
    if (document.getSelection() && document.getSelection()?.toString() !== "")
      return;
    e.preventDefault();
    caretPosition = isOverEl.singleLine ? caretPosition : 0;
    const blockState = store.getState().blocks;
    const currentContext = getCurrentContext(blockState, props.context.id);
    const nextBlockId = getNextBlockId(
      blockState,
      props.blockData.blockSubject.value,
      currentContext
    );
    if (nextBlockId) {
      if (menuStateRef) resetBlockMenu(menuStateRef);
      e.preventDefault();
      e.stopPropagation();
      const focusedBlock: any = props.context.ref.current.querySelectorAll(
        `[data-block-id="${nextBlockId}"]`
      )[0];
      props.changeBlock(
        props.blockData.id,
        focusedBlock,
        e,
        TypeOfLineMovement.startOfLine,
        caretPosition,
        DocumentModes.INSERT,
        nextBlockId
      );
      return;
    }
  }
};

const confirmUnselect = (props: BlockProps, event: KeyboardEvent | any) => {
  let type = "currentPosition";
  unselectBlocks({ id: props.blockData.id, type });
};

let textChangeTimmer: any = false;
let textChangeAction: any = null;

export const executeTextSaveTimeout = () => {
  if (textChangeAction) textChangeAction(true);
  clearTextSaveTimeout();
  textChangeAction = null;
};

export const clearBlockSelection = () => {
  $documentMode.next(DocumentModes.INSERT);
  store.dispatch({
    type: actionTypes.CLEAR_BLOCKS_SELECTION,
  });
};

const startTextSaveTimeout = (blockData: Block, context: IBlockContext) => {
  clearTimeout(textChangeTimmer);
  textChangeAction = (immediate?: boolean) => {
    const newState = store.getState().blocks;
    const block = getBlockById(newState.dict, blockData.id);
    if (!immediate) {
      store.dispatch({
        type: actionTypes.SET_UNDO,
        param: {
          undoObject: {
            actions: [
              {
                type: BlockActionTypes.update,
                delta: { value: block.value },
                id: blockData.id,
              },
            ],
            context: context,
            focusOn: $focusOn.value,
            selectedBlocks: [...store.getState().blocks.selectedBlocks],
          } as ActionWrapperObject,
          contextId: context.id,
        },
      });
    }

    const actions: ActionObject[] = [];
    const newValue = blockData.blockSubject.value.value;
    const action: ActionObject = {
      id: blockData.id,
      delta: {
        value: newValue,
      },
      type: BlockActionTypes.update,
    };

    handleUpdateAction(action, true);
    actions.push(action);
    setUpdateObjectToStore(actions, context);
  };
  textChangeTimmer = setTimeout(() => {
    textChangeAction();
    textChangeAction = null;
  }, 1);
};

export const getAntiAction = (action: ActionObject) => {
  const antiaction = { ...action };
  antiaction.delta = { ...antiaction.delta };
  if (action.type === BlockActionTypes.update) {
    let block = store.getState().blocks.dict[action.id];
    antiaction.delta = getAntiDelta(action.delta, block);
  }
  if (action.type === BlockActionTypes.delete) {
    const newState = store.getState().blocks;
    const block = getBlockById(newState.dict, action.id);
    antiaction.type = BlockActionTypes.insert;
    if (action.options?.newBlock) antiaction.delta = {};
    else antiaction.delta = getAntiDelta(action.delta, block);
  }

  if (action.type === BlockActionTypes.insert) {
    const newState = store.getState().blocks;
    let block = getBlockById(newState.dict, action.id);
    antiaction.type = BlockActionTypes.delete;
    if (action.options?.newBlock) {
      antiaction.delta = {};
      antiaction.options = { ...antiaction.options };
      antiaction.options.newBlock = false;
    } else antiaction.delta = getAntiDelta(action.delta, block);
  }
  return antiaction;
};

export const handleEnterCases = (
  e: React.KeyboardEvent<HTMLDivElement>,
  props: any,
  keepText?: boolean
) => {
  if (e.ctrlKey || e.metaKey) {
    checkCheckboxCases(props.blockData.id, props.context);
    return;
  }
  executeTextSaveTimeout();
  const text = e.currentTarget.textContent?.length;
  if (
    text === 0 &&
    props.blockData.parentId !== "" &&
    props.blockData.children?.length === 0 &&
    !entityKeepTypes.includes(props.blockData.lineType)
  )
    return handleBlockOutdent(props.blockData.id, props);

  const caretPosition = checkCaretPosition(props.blockRef.current);
  const presetData: Partial<Block> = {};
  if (
    [LineType.heading1, LineType.heading2, LineType.heading3].includes(
      props.blockData.lineType
    )
  ) {
    if (caretPosition === 0 || caretPosition === text)
      presetData.lineType = LineType.text;
  }

  if ([LineType.quote, LineType.callout].includes(props.blockData.lineType)) {
    presetData.lineType = LineType.text;
  }
  const selection = document.getSelection();
  let newBlockValue: ILineValue[] = [
    {
      type: LineValueType.text,
      value: "",
    },
  ];

  const inZoomed =
    props.context.zoomedBlockId &&
    props.context.zoomedBlockId === props.blockData.id
      ? ADD_BLOCK_TYPES.addChildBlock
      : null;

  if (caretPosition === 0) {
    if (inZoomed) return;
    batch(() => {
      addBlock({
        event: e,
        currentBlock: props.blockData,
        newBlockValue,
        context: props.context,
        type: inZoomed ? inZoomed : ADD_BLOCK_TYPES.addBlockBefore,
        focus:
          props.blockRef.current.textContent.length > 0
            ? "newBlock"
            : "currentBlock",
        presetData,
      });
    });
    return;
  }

  if (inZoomed) {
    if (selection && selection.rangeCount > 0) {
      const newRange = createRange(
        props.blockRef.current,
        caretPosition,
        props.blockRef.current.textContent.length
      );
      const fragment = newRange.cloneContents();
      newRange.deleteContents();
      for (const child of Array.from(fragment.childNodes)) {
        newBlockValue.push(breakDownHtml(child));
      }
      batch(() => {
        addBlock({
          event: e,
          currentBlock: props.blockData,
          newBlockValue,
          context: props.context,
          type: ADD_BLOCK_TYPES.addChildBlock,
          focus: inZoomed ? "newBlock" : "currentBlock",
          presetData,
        });
      });
      return;
    }
  }

  if (
    props.blockData.blockSubject.value.children.length > 0 &&
    !props.blockData.blockSubject.value.isFolded
  ) {
    if (
      selection &&
      selection.rangeCount > 0 &&
      caretPosition < props.blockRef.current.textContent.length
    ) {
      const newRange = createRange(props.blockRef.current, 0, caretPosition);
      const fragment = newRange.cloneContents();
      newRange.deleteContents();
      for (const child of Array.from(fragment.childNodes)) {
        newBlockValue.push(breakDownHtml(child));
      }
      batch(() => {
        addBlock({
          event: e,
          currentBlock: props.blockData,
          newBlockValue,
          context: props.context,
          type: inZoomed ? inZoomed : ADD_BLOCK_TYPES.addBlockBefore,
          focus: inZoomed ? "newBlock" : "currentBlock",
          presetData,
        });
      });
      return;
    }

    if (caretPosition === props.blockRef.current.textContent.length) {
      batch(() => {
        addBlock({
          event: e,
          currentBlock: props.blockData,
          newBlockValue,
          context: props.context,
          type: ADD_BLOCK_TYPES.addChildBlock,
          focus: "newBlock",
          presetData,
        });
      });
    }
    return;
  } else {
    const cannotAddBlock = checkIfCannotAddBlocks();
    if (cannotAddBlock) return;
    if (
      selection &&
      selection.rangeCount > 0 &&
      caretPosition < props.blockRef.current.textContent.length
    ) {
      const newRange = createRange(
        props.blockRef.current,
        caretPosition,
        props.blockRef.current.textContent.length
      );
      const fragment = newRange.cloneContents();
      removeEmptyNodes(fragment);
      newRange.deleteContents();
      selection.removeAllRanges();

      for (const child of Array.from(fragment.childNodes)) {
        newBlockValue.push(breakDownHtml(child));
      }
    }

    batch(() => {
      addBlock({
        event: e,
        currentBlock: props.blockData,
        newBlockValue,
        context: props.context,
        type: inZoomed ? inZoomed : ADD_BLOCK_TYPES.addBlockAfter,
        focus: "newBlock",
        presetData,
      });
    });
  }
};

export const toggleInsertModeFocusLastBlock = () => {
  const newState = store.getState().blocks;
  const selectedBlocks = [...newState.selectedBlocks];
  let focusId = $focusOn.value.focusBlockId;
  if (selectedBlocks.length > 0) {
    const sortedSelection = getSortedSelectedBlocks(selectedBlocks);
    focusId = sortedSelection[sortedSelection.length - 1];
  }
  $documentMode.next(DocumentModes.INSERT);
  const newFocus = { ...$focusOn.value };
  newFocus.diff = 0;
  newFocus.focusBlockId = focusId;
  newFocus.type = FocusType.withDiff;
  store.dispatch({
    type: actionTypes.REFOCUS,
    param: {
      newFocus,
    },
  });
};

export const toggleDocumentMode = (
  id: string,
  presetToggle?: DocumentModes
) => {
  const documentMode = $documentMode.value;
  if (presetToggle) {
    if (documentMode !== presetToggle) $documentMode.next(presetToggle);
    else return;
  } else {
    if (documentMode === DocumentModes.INSERT)
      $documentMode.next(DocumentModes.BLOCK);
    else $documentMode.next(DocumentModes.INSERT);
  }

  const newState = store.getState().blocks;
  const blockData = getBlockById(newState.dict, id);
  batch(() => {
    store.dispatch({
      type: actionTypes.SAVE_BLOCK_DATA,
      param: {
        id: blockData.id,
        blockData,
      },
    });
    if ($documentMode.value === DocumentModes.INSERT) {
      const newFocus = { ...$focusOn.value };
      newFocus.diff = 0;
      newFocus.focusBlockId = id;
      newFocus.type = FocusType.withDiff;
      store.dispatch({
        type: actionTypes.REFOCUS,
        param: {
          newFocus,
        },
      });
    }
  });
};

export const selectBlock = (id: string) => {
  toggleDocumentMode(id, DocumentModes.BLOCK);
  store.dispatch({
    type: actionTypes.SELECT_BLOCK,
    param: {
      id,
    },
  });
};

export const selectNext = (param: {
  id: string;
  type: string;
  context: any;
}) => {
  toggleDocumentMode(param.id, DocumentModes.BLOCK);
  store.dispatch({
    type: actionTypes.SELECT_NEXT_BLOCK,
    param,
  });
};

export const handleBlockIndent = (props: any) => {
  executeTextSaveTimeout();
  let childrenInSelection = false;
  const blockRef: Block = {
    ...props.blockData,
    ...props.blockData.blockSubject.value,
  };

  const inZoomed =
    props.context.zoomedBlockId &&
    props.context.zoomedBlockId === props.blockData.id
      ? true
      : false;
  if (inZoomed) return;

  const newState = store.getState().blocks;
  const context = getCurrentContext(newState, props.context.id);

  let selectedBlocks: string[] = [];

  if (
    newState.selectedBlocks.length > 0 &&
    $documentMode.value === DocumentModes.BLOCK
  )
    selectedBlocks = getSortedSelectedBlocks(newState.selectedBlocks);
  else selectedBlocks.push(blockRef.id);

  if ($documentMode.value === DocumentModes.INSERT && !blockRef.isFolded) {
    blockRef.children.forEach((childId: string) => {
      selectedBlocks.push(childId);
    });
    childrenInSelection = true;
  }

  if (props.isFirst && props.blockData.indentLevel === 0) {
    cycleOutlineMode(props.context);
    return;
  }

  const firstBlock = getBlockById(newState.dict, selectedBlocks[0]);
  const prevSiblingId = getPreviousSibling(newState, firstBlock, context);

  if (!prevSiblingId) {
    const parentBlock = getBlockById(newState.dict, firstBlock.parentId);
    if (!parentBlock || !parentBlock.id) return;
    if (
      $documentMode.value === DocumentModes.INSERT &&
      parentBlock.children.length === 1 &&
      props.blockRef &&
      props.blockRef.current.innerText.length === 0
    ) {
      const nextListType =
        parentBlock.subLinesList === LineType.bulletedList
          ? LineType.numberedList
          : LineType.bulletedList;
      changeParentListType(firstBlock.id, nextListType, props.context);
    }
    return;
  }
  const prevSibling = getBlockById(newState.dict, prevSiblingId);

  if (prevSibling.lineType === LineType.table) {
    return;
  }

  if (
    entityKeepTypes.includes(prevSibling.lineType) &&
    !allowChildBlocks.includes(prevSibling.lineType)
  )
    return;

  const undoObject: ActionWrapperObject = {
    actions: [],
    context: props.context,
    focusOn: { ...$focusOn.value },
    selectedBlocks: [...newState.selectedBlocks],
    documentMode: newState.documentMode,
  };

  const saveActions: ActionObject[] = [];
  const reduxActions: any = [];

  batch(() => {
    if (prevSibling.isFolded) {
      const action: ActionObject = {
        id: prevSibling.id,
        delta: {
          isFolded: false,
        },
        type: BlockActionTypes.update,
      };
      const antiAction = getAntiAction(action);
      store.dispatch({
        type: actionTypes.SAVE_BLOCK_DATA,
        param: {
          id: prevSibling.id,
          blockData: prevSibling,
          delta: action.delta,
        },
      });
      saveActions.push(action);
      undoObject.actions.push(antiAction);
    }
    if (prevSibling.children.length > 0) {
      const referenceId = prevSibling.children[prevSibling.children.length - 1];
      moveBlocks({
        type: "after",
        options: "sibling",
        referenceId,
        selectedBlocks,
        undoObject,
        saveActions,
        childrenInSelection,
      });
    } else {
      const referenceId = prevSibling.id;
      moveBlocks({
        type: "after",
        options: "child",
        referenceId,
        selectedBlocks,
        undoObject,
        saveActions,
        childrenInSelection,
      });
    }

    const newValue: FocusObject = {
      ...$focusOn.value,
      focusBlockId: blockRef.id,
      focusContext: props.context,
    };

    reduxActions.push({
      type: actionTypes.REFOCUS,
      param: {
        newFocus: newValue,
      },
    });

    reduxActions.push({
      type: actionTypes.SET_UNDO,
      param: {
        undoObject,
        contextId: props.context.id,
      },
    });

    store.dispatch(batchActions(reduxActions));

    setUpdateObjectToStore(saveActions, props.context);
  });
};

export const handleBlockOutdent = (
  id: string,
  props: BlockPropsWithRef | BlockProps
) => {
  executeTextSaveTimeout();
  const newState = store.getState().blocks;
  let zoomedBlock;
  const inZoomed =
    props.context.zoomedBlockId &&
    props.context.zoomedBlockId === props.blockData.id
      ? true
      : false;
  if (inZoomed) return;
  if (props.context.zoomedBlockId)
    zoomedBlock = getBlockById(newState.dict, props.context.zoomedBlockId);

  let block = getBlockById(newState.dict, id);
  if (block.parentId === zoomedBlock?.id || block.parentId === "") return;

  let selectedBlocks: string[] = [];
  let lastSelectedBlock = block;

  if (
    newState.selectedBlocks.length > 0 &&
    newState.documentMode === DocumentModes.BLOCK
  ) {
    selectedBlocks = getSortedSelectedBlocks(newState.selectedBlocks);
    const lastBlockId = selectedBlocks[selectedBlocks.length - 1];
    lastSelectedBlock = getBlockById(newState.dict, lastBlockId);
    block = getBlockById(newState.dict, selectedBlocks[0]);
  } else selectedBlocks.push(block.id);

  let toRemoveFromParent: string[] = [];

  const parentBlock = getBlockById(newState.dict, lastSelectedBlock.parentId);
  if (parentBlock.id && parentBlock.children.length > 1) {
    const index = parentBlock.children.indexOf(lastSelectedBlock.id);
    if (index > -1) toRemoveFromParent = parentBlock.children.slice(index + 1);
  }

  const undoObject: ActionWrapperObject = {
    actions: [],
    context: props.context,
    focusOn: { ...$focusOn.value },
    selectedBlocks: [...newState.selectedBlocks],
    documentMode: newState.documentMode,
  };

  const saveActions: ActionObject[] = [];

  batch(() => {
    moveBlocks({
      type: "after",
      options: "sibling",
      referenceId: block.parentId,
      selectedBlocks,
      undoObject,
      saveActions,
    });

    if (toRemoveFromParent.length > 0) {
      if (lastSelectedBlock.children.length > 0) {
        const lastChild =
          lastSelectedBlock.children[lastSelectedBlock.children.length - 1];
        moveBlocks({
          type: "after",
          options: "sibling",
          referenceId: lastChild,
          selectedBlocks: toRemoveFromParent,
          undoObject,
          saveActions,
        });
      } else {
        moveBlocks({
          type: "after",
          options: "child",
          referenceId: lastSelectedBlock.id,
          selectedBlocks: toRemoveFromParent,
          undoObject,
          saveActions,
        });
      }
    }

    const newValue: FocusObject = {
      ...$focusOn.value,
      focusBlockId: id,
      focusContext: props.context,
    };

    store.dispatch({
      type: actionTypes.REFOCUS,
      param: {
        newFocus: newValue,
      },
    });
    store.dispatch({
      type: actionTypes.SET_UNDO,
      param: {
        undoObject,
        contextId: props.context.id,
      },
    });
    setUpdateObjectToStore(saveActions, props.context);
  });
};

export const handleBlockBackspace = (props: BlockPropsWithRef) => {
  if (!props.blockRef.current) return;
  const text = props.blockRef.current.innerText;
  const selection = document.getSelection();
  if (props.blockData.lineType !== LineType.text) {
    batch(() => {
      changeSingleBlockType(
        props.blockData.id,
        LineType.text,
        props.context,
        props.blockRef
      );
    });
    return;
  }
  if (props.isFirst && props.blockData.indentLevel === 0) {
    const currentContext = getCurrentContext(
      store.getState().blocks,
      props.context.id
    );
    if (currentContext.outlineMode !== "noOutline") {
      changeParentListType(props.blockData.id, "noOutline", props.context);
      return;
    }
  }

  const blockState = store.getState().blocks;
  const block = blockState.dict[props.blockData.id];

  const currentContext = getCurrentContext(blockState, props.context.id);
  let prevBlockId = getPreviousBlock(blockState, block, currentContext);

  if (prevBlockId) {
    const prevBlock = blockState.dict[prevBlockId];
    if (
      prevBlock.lineType === LineType.tableRow ||
      prevBlock.lineType === LineType.table
    )
      return;
  }

  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const newRange = range.cloneRange();
    newRange.selectNodeContents(props.blockRef.current);
    const fragment = newRange.cloneContents();
    batch(() => {
      handleBlockDelete({
        currentBlock: props.blockData,
        context: props.context,
        addedText: text.length > 0 ? fragment : null,
        diff: text.length,
      });
    });
  }
  return;
};

export const handleDeleteNextBlockInsertMode = (props: any) => {
  const newState = store.getState().blocks;
  const currentBlock = getBlockById(newState.dict, props.blockData.id);
  const currentContext = getCurrentContext(newState, props.context.id);
  if (currentContext.id) {
    const nextBlockId = getNextBlockId(newState, currentBlock, currentContext);
    if (nextBlockId) {
      const nextBlock = getBlockById(newState.dict, nextBlockId);
      if (
        nextBlock.lineType === LineType.Title ||
        nextBlock.lineType === LineType.table ||
        nextBlock.lineType === LineType.tableRow
      )
        return;
      const focusedBlock: any = props.context.ref.current.querySelectorAll(
        `[data-block-id="${nextBlock.id}"]`
      )[0];
      if (focusedBlock) {
        batch(() => {
          const newRange = new Range();
          let text = "";
          if (!entityKeepTypes.includes(nextBlock.lineType)) {
            newRange.selectNodeContents(focusedBlock);
            text = focusedBlock.innerText;
          }
          const fragment = newRange.cloneContents();
          handleBlockDelete({
            currentBlock: nextBlock,
            context: props.context,
            addedText: text.length > 0 ? fragment : null,
            diff: text.length,
          });
        });
      }
    } else {
      return;
    }
  }
};

export const handleMoveBlockSingle = (props: any, type: "up" | "down") => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, props.blockData.id);
  const currentContext = getCurrentContext(newState, props.context.id);
  const zoomedBlockId = props.context.zoomedBlockId;

  if (block.id === zoomedBlockId) return;
  const undoObject: ActionWrapperObject = {
    actions: [],
    context: props.context,
    focusOn: { ...$focusOn.value },
    selectedBlocks: [...newState.selectedBlocks],
    documentMode: newState.documentMode,
  };
  const saveActions: ActionObject[] = [];
  if (type === "up") {
    let selectedBlocks: string[] = [];
    let firstBlock = block;
    if (newState.selectedBlocks.length > 0) {
      selectedBlocks = getSortedSelectedBlocks(newState.selectedBlocks);
      firstBlock = getBlockById(newState.dict, selectedBlocks[0]);
    } else {
      selectedBlocks.push(block.id);
    }
    const previousSiblingId = getPreviousSibling(
      newState,
      firstBlock,
      currentContext
    );
    if (previousSiblingId) {
      const prevSibling = getBlockById(newState.dict, previousSiblingId);
      const isTitle = checkIfBlockIsTitle(newState, prevSibling.id);
      if (isTitle) return;
      props.blockRef.current?.blur();
      if (
        prevSibling.children.length > 0 &&
        !prevSibling.isFolded &&
        prevSibling.lineType !== LineType.table
      ) {
        const lastChild = prevSibling.children[prevSibling.children.length - 1];
        batch(() => {
          moveBlocks({
            type: "after",
            options: "sibling",
            referenceId: lastChild,
            selectedBlocks,
            saveActions,
          });
          const newValue: FocusObject = {
            ...$focusOn.value,
            focusBlockId: block.id,
            focusContext: props.context,
          };

          store.dispatch({
            type: actionTypes.REFOCUS,
            param: {
              newFocus: newValue,
            },
          });
        });
      } else {
        batch(() => {
          moveBlocks({
            type: "before",
            options: "sibling",
            referenceId: previousSiblingId,
            selectedBlocks,
            undoObject,
            saveActions,
          });
          const newValue: FocusObject = {
            ...$focusOn.value,
            focusBlockId: block.id,
            focusContext: props.context,
          };

          store.dispatch({
            type: actionTypes.REFOCUS,
            param: {
              newFocus: newValue,
            },
          });
          store.dispatch({
            type: actionTypes.SET_UNDO,
            param: {
              undoObject,
              contextId: props.context.id,
            },
          });
        });
      }
    } else {
      const prevBlockId = getPreviousBlock(
        newState,
        firstBlock,
        currentContext
      );
      if (prevBlockId === zoomedBlockId) return;
      if (!prevBlockId) return;
      props.blockRef.current?.blur();
      batch(() => {
        moveBlocks({
          type: "before",
          options: "sibling",
          referenceId: prevBlockId,
          selectedBlocks,
          undoObject,
          saveActions,
        });
        const newValue: FocusObject = {
          ...$focusOn.value,
          focusBlockId: block.id,
          focusContext: props.context,
        };

        store.dispatch({
          type: actionTypes.REFOCUS,
          param: {
            newFocus: newValue,
          },
        });
        store.dispatch({
          type: actionTypes.SET_UNDO,
          param: {
            undoObject,
            contextId: props.context.id,
          },
        });
      });
    }
  }
  if (type === "down") {
    let selectedBlocks: string[] = [];
    let lastBlock = block;
    if (newState.selectedBlocks.length > 0) {
      selectedBlocks = getSortedSelectedBlocks(newState.selectedBlocks);
      lastBlock = getBlockById(
        newState.dict,
        selectedBlocks[selectedBlocks.length - 1]
      );
    } else {
      selectedBlocks.push(block.id);
    }
    const nextSiblingId = getNextSibling(newState, lastBlock, currentContext);
    if (nextSiblingId) {
      props.blockRef.current?.blur();
      const nextSiblingBlock = getBlockById(newState.dict, nextSiblingId);
      batch(() => {
        if (
          nextSiblingBlock.children.length > 0 &&
          !nextSiblingBlock.isFolded &&
          nextSiblingBlock.lineType !== LineType.table
        ) {
          moveBlocks({
            type: "after",
            options: "child",
            referenceId: nextSiblingId,
            selectedBlocks,
            undoObject,
            saveActions,
          });
        } else {
          props.blockRef.current?.blur();
          moveBlocks({
            type: "after",
            options: "sibling",
            referenceId: nextSiblingId,
            selectedBlocks,
            undoObject,
            saveActions,
          });
        }

        const newValue: FocusObject = {
          ...$focusOn.value,
          focusBlockId: block.id,
          focusContext: props.context,
        };

        store.dispatch({
          type: actionTypes.REFOCUS,
          param: {
            newFocus: newValue,
          },
        });
        store.dispatch({
          type: actionTypes.SET_UNDO,
          param: {
            undoObject,
            contextId: props.context.id,
          },
        });
      });
    } else {
      if (lastBlock.parentId === "") return;
      if (lastBlock.parentId === zoomedBlockId) return;
      props.blockRef.current?.blur();
      batch(() => {
        moveBlocks({
          type: "after",
          options: "sibling",
          referenceId: lastBlock.parentId,
          selectedBlocks,
          undoObject,
          saveActions,
        });
        const newValue: FocusObject = {
          ...$focusOn.value,
          focusBlockId: block.id,
          focusContext: props.context,
        };

        store.dispatch({
          type: actionTypes.REFOCUS,
          param: {
            newFocus: newValue,
          },
        });
        store.dispatch({
          type: actionTypes.SET_UNDO,
          param: {
            undoObject,
            contextId: props.context.id,
          },
        });
      });
    }
  }
  setUpdateObjectToStore(saveActions, props.context);
};

export const clearTextSaveTimeout = () => {
  clearTimeout(textChangeTimmer);
  textChangeAction = null;
};

export const updateBlockText = (
  block: Block,
  event: SyntheticEvent | any,
  context: any,
  immediate?: boolean
) => {
  if (event) {
    const blockCopy = { ...block, ...block.blockSubject.value };
    blockCopy.value = [];
    removeEmptyNodes(event.currentTarget);
    event.currentTarget.childNodes.forEach((element: any) => {
      blockCopy.value.push(breakDownHtml(element));
    });
    blockCopy.blockSubject.next(blockCopy);
    startTextSaveTimeout(blockCopy, context);
    if (immediate) executeTextSaveTimeout();
  }
};

export const updateCodeBlockText = (
  block: Block,
  text: string,
  context: IBlockContext,
  language?: string
) => {
  const blockCopy = { ...block, ...block.blockSubject.value };

  const newValue: ILineValue[] = [
    {
      type: LineValueType.text,
      value: text,
    },
  ];
  if (language) {
    newValue[0].codeBlockLanguage = language;
  }
  blockCopy.value = newValue;
  blockCopy.blockSubject.next(blockCopy);
  startTextSaveTimeout(blockCopy, context);
};

export const updateBlockTextInState = (
  block: Block,
  blockRef: React.MutableRefObject<HTMLDivElement | null>,
  context: any
) => {
  const blockCopy = { ...block, ...block.blockSubject.value };
  blockCopy.value = [];
  blockRef.current?.childNodes.forEach((element: any) => {
    blockCopy.value.push(breakDownHtml(element));
  });
  startTextSaveTimeout(blockCopy, context);
  blockCopy.blockSubject.next(blockCopy);
  block.value = blockCopy.value;
  store.dispatch({
    type: actionTypes.SAVE_BLOCK_DATA,
    param: {
      id: blockCopy.id,
      blockData: blockCopy,
      delta: { value: blockCopy.value },
    },
  });
};

export const updateImmediateText = (
  block: Block,
  ref: React.MutableRefObject<HTMLDivElement | null>,
  context: IBlockContext
) => {
  clearTextSaveTimeout();
  const newState = store.getState().blocks;

  const undoObject: ActionWrapperObject = {
    actions: [],
    context: context,
    focusOn: { ...$focusOn.value },
    selectedBlocks: [...newState.selectedBlocks],
    documentMode: newState.documentMode,
  };
  const saveActions: ActionObject[] = [];

  if (ref.current) {
    const blockCopy = { ...block, ...block.blockSubject.value };
    blockCopy.value = [];
    ref.current.childNodes.forEach((element: any) => {
      blockCopy.value.push(breakDownHtml(element));
    });

    const delta = {
      value: blockCopy.value,
      dateModified: new Date(),
      modifiedBy: prevState.value.user?.id,
    };

    const action: ActionObject = {
      id: blockCopy.id,
      type: BlockActionTypes.update,
      delta,
    };
    saveActions.push(action);

    const antiAction = getAntiAction(action);

    undoObject.actions.push(antiAction);

    batch(() => {
      updateSingleBlock({
        blockId: block.id,
        undoObject,
        saveActions,
        context: context,
        delta,
      });
      store.dispatch({
        type: actionTypes.SET_UNDO,
        param: {
          context,
          contextId: context.id,
          undoObject,
        },
      });
    });
    setUpdateObjectToStore(saveActions, context);
  }
};

export const changeBlockStatus = (props: BlockProps) => {
  const pseudoContext = { ...props.context };
  if (pseudoContext.canChangeCheckbox) {
    pseudoContext.canEdit = true;
    pseudoContext.autosave = true;
  }

  let newStatus: LineStatus = LineStatus.todo;
  const undoObject = createActionWrapperObject(props.context);
  const saveActions: ActionObject[] = [];
  if (props.blockData.checkboxStatus !== LineStatus.done)
    newStatus = LineStatus.done;
  else newStatus = LineStatus.todo;
  const delta: Partial<Block> = { checkboxStatus: newStatus };
  batch(() => {
    updateSingleBlock({
      blockId: props.blockData.id,
      undoObject,
      saveActions,
      context: pseudoContext,
      delta,
    });
    store.dispatch({
      type: actionTypes.SET_UNDO,
      param: {
        context: pseudoContext,
        contextId: pseudoContext.id,
        undoObject,
        isRedoAction: true,
      },
    });
    store.dispatch({
      type: actionTypes.REFOCUS,
      param: {
        newFocus: undoObject.focusOn,
      },
    });
  });

  setUpdateObjectToStore(saveActions, pseudoContext);
};

export const undoAction = (context: IBlockContext) => {
  const state = store.getState();
  const stateContext = state.blocks.contexts[context.id];
  const undoStack = stateContext.state.undoStack;
  const length = undoStack.length;
  if (length > 0) {
    const undoObject = undoStack[length - 1];
    const redoActions: any[] = [];
    const redoObject: ActionWrapperObject = {
      actions: redoActions,
      context: context,
      focusOn: $focusOn.value,
      selectedBlocks: [...state.blocks.selectedBlocks],
      documentMode: state.blocks.documentMode,
    };
    const saveActions: ActionObject[] = [];

    batch(() => {
      undoObject.actions.forEach((action: any) => {
        const antiAction = getAntiAction(action);
        redoActions.splice(0, 0, antiAction);
        saveActions.push(action);
        updateBlockAndHandleSideEffects(action);
      });

      store.dispatch({
        type: actionTypes.BLOCK_UNDO,
        param: {
          context,
          redoObject,
        },
      });
      store.dispatch({
        type: actionTypes.REFOCUS,
        param: {
          newFocus: undoObject.focusOn,
        },
      });
      setUpdateObjectToStore(saveActions, context);
    });
  }
};

export const redoAction = (context: any) => {
  const state = store.getState();
  const stateContext = state.blocks.contexts[context.id];
  const redoStack = stateContext.state.redoStack;
  const length = redoStack.length;
  if (length > 0) {
    const redoObject = redoStack[length - 1];
    const undoActions: any[] = [];
    const undoObject: ActionWrapperObject = {
      actions: undoActions,
      context: context,
      focusOn: $focusOn.value,
      selectedBlocks: [...state.blocks.selectedBlocks],
      documentMode: state.blocks.documentMode,
    };
    const saveActions: ActionObject[] = [];
    batch(() => {
      redoObject.actions.forEach((action: any) => {
        const antiAction = getAntiAction(action);
        undoActions.splice(0, 0, antiAction);
        saveActions.push(action);
        updateBlockAndHandleSideEffects(action);
      });
      store.dispatch({
        type: actionTypes.SET_UNDO,
        param: {
          context,
          contextId: context.id,
          undoObject,
          isRedoAction: true,
        },
      });
      store.dispatch({
        type: actionTypes.REFOCUS,
        param: {
          newFocus: redoObject.focusOn,
        },
      });
      setUpdateObjectToStore(saveActions, context);
    });
  }
};

export const allowedKeysInMultiselect = [
  KeyEvents.VALUE_TAB,
  KeyEvents.VALUE_BACK_SPACE,
  KeyEvents.VALUE_DELETE,
  KeyEvents.VALUE_D,
  KeyEvents.VALUE_F,
  KeyEvents.VALUE_F.toUpperCase(),
  KeyEvents.VALUE_META,
  KeyEvents.VALUE_LEFT_CMD,
  KeyEvents.VALUE_RIGHT_CMD,
  KeyEvents.VALUE_CONTROL,
  KeyEvents.VALUE_SHIFT,
];

export const blockModeActionsWithMetaModifier = [
  KeyEvents.VALUE_OPEN_BRACKET,
  KeyEvents.VALUE_CLOSE_BRACKET,
  KeyEvents.VALUE_A,
  KeyEvents.VALUE_C,
  KeyEvents.VALUE_Z,
  KeyEvents.VALUE_Y,
  KeyEvents.VALUE_V,
  KeyEvents.VALUE_X,
];

export const blockModeActionsWithShiftModifier = [
  KeyEvents.VALUE_UP,
  KeyEvents.VALUE_DOWN,
  KeyEvents.VALUE_K,
  KeyEvents.VALUE_J,
  KeyEvents.VALUE_F,
  KeyEvents.VALUE_F.toUpperCase(),
];

export const checkToUnselectBlocks = (
  props: BlockProps,
  event: KeyboardEvent | any
) => {
  if (allowedKeysInMultiselect.includes(event.key)) return;

  if (
    [KeyEvents.VALUE_K, KeyEvents.VALUE_ENTER].includes(event.key) &&
    (event.metaKey || event.ctrlKey)
  ) {
    return;
  }

  // if (!event.shiftKey && !event.altKey && !(event.metaKey || event.ctrlKey)) {
  //   return;
  // }

  if (event.metaKey || event.ctrlKey) {
    if (blockModeActionsWithMetaModifier.includes(event.key)) return;
  }

  if (event.shiftKey) {
    if (blockModeActionsWithShiftModifier.includes(event.key)) return;
  }
  confirmUnselect(props, event);
};

export const unselectBlocks = (param: { id: string; type: string }) => {
  if (store.getState().blocks.selectedBlocks.length > 0)
    store.dispatch({
      type: actionTypes.UNSELECT_BLOCKS,
      param,
    });
  const currentFocus = $focusOn.value.focusBlockId;
  $focusOn.next({ ...$focusOn.value, focusBlockId: undefined });
  if (currentFocus) {
    blockApi.blockAction.setBlock(currentFocus, {}, true);
  }
};

export const getClassNameFromBlockType = (blockType: LineType): string => {
  switch (blockType) {
    case LineType.heading1:
      return "block-h1 ";
    case LineType.heading2:
      return "block-h2 ";
    case LineType.heading3:
      return "block-h3 ";
    case LineType.checkbox:
      return "checkbox ";
    case LineType.quote:
      return "quote ";
    case LineType.callout:
      return "callout ";
    case LineType.pdf:
      return "block-pdf ";
    case LineType.code:
      return "code ";
    default:
      return "";
  }
};

export function keys<O extends object>(obj: O): Array<keyof O> {
  return Object.keys(obj) as Array<keyof O>;
}
