import TabLayout from "./TabLayout";
import styles from "./workSettings/workSettings.module.scss";
import { connect } from "react-redux";
import { Abilities, IWorkspaceObj } from "utilities/types";
import { groupBy, values } from "lodash";
import store from "store/storeExporter";
import { ReactComponent as StatusInprogressIcon } from "../../../../icons/light-status-inprogress.svg";
import { ReactComponent as StatusUnstartedIcon } from "../../../../icons/light-status-unstarted.svg";
import StatusSomedayIcon from "icons/LightStatusSomeday";
import StatusCompletedIcon from "icons/LightStatusCompleted";
import StatusCanceledIcon from "icons/LightStatusCanceled";
import StatusCategory from "./workSettings/StatusCategory";
import { useAbilityChecker } from "editor/utils/customHooks";

interface IProps {
  currentWorkStatuses: string[];
  base: IWorkspaceObj;
}
const getStatusIcon = (categoryId: string) => {
  switch (categoryId) {
    case "1":
      return StatusSomedayIcon;
    case "2":
      return StatusUnstartedIcon;
    case "3":
      return StatusInprogressIcon;
    case "4":
      return StatusCompletedIcon;
    case "5":
      return StatusCanceledIcon;
    default:
      return null;
  }
};

const getCategoryName = (categoryId: string) => {
  switch (categoryId) {
    case "1":
      return "Someday";
    case "2":
      return "To Do";
    case "3":
      return "In Progress";
    case "4":
      return "Done";
    case "5":
      return "Canceled";
    default:
      return null;
  }
};

const WorkSettings = (props: IProps) => {
  const statusDict = store.getState().work.statuses.dict;
  const categories = groupBy(
    props.currentWorkStatuses.map((statusId) => statusDict[statusId]),
    "categoryId"
  );
  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });
  return (
    <>
      <TabLayout
        title="Work Statuses"
        description="Manage the progress statuses that can be set for tasks, projects, and initiatives"
      >
        <div className={styles.workSettings}>
          <span className={styles.listContainer}>
            {Object.entries(categories).map(([categoryId, statuses]) => {
              return (
                <div className={styles.statusRow} key={categoryId}>
                  <StatusCategory
                    key={categoryId}
                    canEditSettings={canEditSettings}
                    icon={getStatusIcon(categoryId)}
                    categoryId={categoryId}
                    statuses={statuses}
                    name={getCategoryName(categoryId)}
                    baseId={props.base.id}
                  />
                </div>
              );
            })}
          </span>
        </div>
      </TabLayout>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    currentWorkStatuses: values(state.work.statuses.statusArray),
  };
};

export default connect(mapStateToProps)(WorkSettings);
