import { BlockReducerState } from "../blockReducer";
import {
  getBlockById,
  getBlockIndex,
  getCurrentContext,
  getPreviousSibling,
  updateCurrentBlock,
  updateFocus,
  UPDATE_TYPES,
} from "./generalBlockHelpers";

export const indentBlock = (newState: BlockReducerState, id: string) => {
  let currentBlock = getBlockById(newState.dict, id);
  let currentContext = getCurrentContext(
    newState,
    currentBlock.containerType + currentBlock.containerId
  );
  const index = getBlockIndex(newState, currentBlock, currentContext);
  if (index === 0) return newState;
  const prevId = getPreviousSibling(newState, currentBlock, currentContext);
  const prevBlock = getBlockById(newState.dict, prevId);

  updateCurrentBlock(newState, currentBlock, UPDATE_TYPES.update, {
    parentId: prevBlock.id,
  });

  if (currentBlock.children.length > 0) {
    currentBlock.children.forEach((childId) => {
      const childBlock = getBlockById(newState.dict, childId);
      updateCurrentBlock(newState, childBlock, UPDATE_TYPES.update, {
        parentId: prevBlock.id,
      });
    });
  }

  updateFocus(newState, {});
  return newState;
};

export const outdentBlock = (newState: BlockReducerState, id: string) => {
  let currentBlock = getBlockById(newState.dict, id);
  if (
    currentBlock.parentId === "" ||
    newState.focusOn.focusContext?.zoomedBlockId === currentBlock.parentId
  )
    return newState;
  const parentBlock = getBlockById(newState.dict, currentBlock.parentId);

  const index = parentBlock.children.indexOf(currentBlock.id);
  const toRemoveFromParent = parentBlock.children.slice(index + 1);

  updateCurrentBlock(newState, currentBlock, UPDATE_TYPES.update, {
    parentId: parentBlock.parentId,
  });

  currentBlock = getBlockById(newState.dict, id);
  if (currentBlock.children.length > 0) {
    currentBlock.children.forEach((childId) => {
      const childBlock = getBlockById(newState.dict, childId);
      updateCurrentBlock(newState, childBlock, UPDATE_TYPES.update, {
        indentLevel: currentBlock.indentLevel + 1,
      });
    });
  }
  if (toRemoveFromParent.length > 0) {
    toRemoveFromParent.forEach((id) => {
      const childBlock = getBlockById(newState.dict, id);
      updateCurrentBlock(newState, childBlock, UPDATE_TYPES.update, {
        indentLevel: currentBlock.indentLevel + 1,
        parentId: currentBlock.id,
      });
    });
  }
  updateFocus(newState, {});
  return newState;
};
