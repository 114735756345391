import Button, { ButtonTypes, IconSides } from "components/Button";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import { PlusOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import styles from "./cyclesEditor.module.scss";
import workApi from "clientApi/workApi";
import { useOptionalClassName } from "utilities/hooks";
import { VALUE_ENTER, VALUE_ESCAPE } from "keycode-js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SixDotImg from "icons/six-dot-handle.svg";
import {
  getNextRank,
  getPreviousRank,
  getRankBetween,
} from "utilities/containerRankHelpers";
import notificationsApi from "clientApi/notificationsApi";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities } from "utilities/types";
import {
  CLEAR_JUST_CLOSED_WORK_ITEMS,
  DELETE_SECTION,
  PATCH_SECTION,
} from "store/actions";
import { socket } from "App";
import { axiosInstance } from "index";

const CyclesEditor: React.FC<{
  mode: "active" | "closed";
  groupId: string;
}> = ({ mode, groupId }): JSX.Element => {
  const [addingCycle, setaddingCycle] = useState(false);
  const canEditCycles = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_CYCLES,
    isGroupMember: groupId,
  });

  useEffect(() => {
    return () => {
      store.dispatch({ type: CLEAR_JUST_CLOSED_WORK_ITEMS });
    };
  }, []);

  return (
    <div style={{ padding: "12px" }}>
      <h4 style={{ marginBottom: "16px" }}>
        Edit {mode === "active" ? "active" : "closed"} sprints
      </h4>
      {mode === "active" && <CyclesList groupId={groupId} />}
      {mode === "closed" && <ClosedCyclesList groupId={groupId} />}

      {addingCycle && mode === "active" && (
        <AddingCycle setaddingCycle={setaddingCycle} groupId={groupId} />
      )}
      {mode === "active" && (
        <Button
          icon={<PlusOutlined />}
          style={{ marginTop: "32px" }}
          disabled={!canEditCycles}
          iconSide={IconSides.LEFT}
          onClick={() => setaddingCycle(!addingCycle)}
        >
          Add Sprint
        </Button>
      )}
    </div>
  );
};

const CyclesList: React.FC<{ groupId: string }> = React.memo(({ groupId }) => {
  const openCycleIds = useSelector(
    (state: ClarityStore) =>
      state.work.groupCycles[groupId]?.openTimeframeIds ?? [],
    shallowEqual
  );

  const canEditCycles = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_CYCLES,
  });

  const handleDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) return;
    if (!canEditCycles) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const state = store.getState().work;
    const openCycleIds = [...state.groupCycles[groupId].openTimeframeIds];
    const dict = state.sections;
    const movingId = openCycleIds[source.index];
    openCycleIds.splice(source.index, 1);

    let newRank = "";

    if (destination.index === 0) {
      const currentFirstRankId = openCycleIds[0];
      const currentFirstRank = dict[currentFirstRankId].rank;
      if (currentFirstRank) {
        newRank = getPreviousRank(currentFirstRank);
      }
    } else if (destination.index === openCycleIds.length) {
      const currentLastRank = dict[openCycleIds[openCycleIds.length - 1]].rank;
      if (currentLastRank) {
        newRank = getNextRank(currentLastRank);
      }
    } else {
      const rankBefore = dict[openCycleIds[destination.index - 1]].rank;
      const rankAfter = dict[openCycleIds[destination.index]].rank;
      newRank = getRankBetween(rankBefore, rankAfter);
    }

    store.dispatch({
      type: PATCH_SECTION,
      patchSection: {
        id: movingId,
        patch: { rank: newRank },
        preset: true,
      },
    });

    // send request to update on server
    axiosInstance
      .patch("/api/workSection", {
        sectionId: movingId,
        patch: [{ op: "replace", path: "/rank", value: newRank }],
        clientId: socket.id,
      })
      .then((res) => {
        // no response expected
      });
  };

  return (
    <DragDropContext onDragEnd={(result: any) => handleDragEnd(result)}>
      <Droppable isDropDisabled={!canEditCycles} droppableId="droppable">
        {(provided, snapshot) => (
          <>
            <div
              {...provided.droppableProps}
              className={
                snapshot.isDraggingOver ? styles.isDraggingOver : undefined
              }
              ref={provided.innerRef}
            >
              {openCycleIds.map((id, index) => (
                <CycleRow
                  canEditCycles={canEditCycles}
                  id={id}
                  key={id}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </div>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const ClosedCyclesList: React.FC<{ groupId: string }> = React.memo(
  ({ groupId }) => {
    const closedCycles = useSelector(
      (state: ClarityStore) =>
        state.work.groupCycles[groupId]?.closedTimeframeIds ?? [],
      shallowEqual
    );
    const [orderedCycles, setorderedCycles] = useState(closedCycles);

    useEffect(() => {
      const cyclesObj = store.getState().work.sections;

      const orderedCycles = closedCycles.sort((a, b) => {
        const workItemA = cyclesObj[a];
        const workItemB = cyclesObj[b];
        const dateA = workItemA.dateCreated;
        const dateB = workItemB.dateCreated;
        if (!dateA || !dateB) return 0;
        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;
        return 0;
      });

      setorderedCycles(orderedCycles);
    }, [closedCycles]);

    const canEditCycles = useAbilityChecker({
      abilityName: Abilities.CAN_MANAGE_CYCLES,
    });

    return (
      <DragDropContext onDragEnd={(result: any) => {}}>
        <Droppable isDropDisabled={!true} droppableId="droppable">
          {(provided, snapshot) => (
            <>
              <div
                {...provided.droppableProps}
                className={
                  snapshot.isDraggingOver ? styles.isDraggingOver : undefined
                }
                ref={provided.innerRef}
              >
                {orderedCycles.map((id, index) => (
                  <CycleRow
                    canEditCycles={canEditCycles}
                    disableDrag={true}
                    id={id}
                    key={id}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </div>
            </>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

const CycleRow: React.FC<{
  id: string;
  index: number;
  canEditCycles: boolean;
  disableDrag?: boolean;
}> = ({ id, index, canEditCycles, disableDrag }) => {
  const cycle = useSelector(
    (state: ClarityStore) => state.work.sections[id],
    shallowEqual
  );

  const [name, setname] = useState(cycle?.name ?? "");

  useEffect(() => {
    setname(cycle?.name ?? "");
  }, [cycle?.name]);

  const save = () => {
    if (name.trim().length < 1) return;
    if (name && name !== cycle.name) {
      axiosInstance
        .patch("/api/workSection", {
          sectionId: id,
          patch: [{ op: "replace", path: "/name", value: name }],
          clientId: socket.id,
        })
        .then(() => {});

      store.dispatch({
        type: PATCH_SECTION,
        sectionId: id,
        patchSection: {
          id,
          patch: { isBeingRenamed: false, name },
          clientId: socket.id,
        },
      });
    } else cancel();
  };

  const cancel = () => {
    setname(cycle?.name ?? "");
  };

  const handleIsClosedChange = (cycleId: string) => {
    const workDict = store.getState().work.dict;
    const openItems = cycle.workIds.filter(
      (id: string) => !workDict[id].isClosed
    );

    if (openItems.length > 0 && !cycle.isClosed) {
      return notificationsApi.displayError({
        title: "Cannot close sprint",
        body: <span>Sprint contains active tasks</span>,
        duration: 3,
      });
    }

    axiosInstance
      .patch("/api/workSection", {
        sectionId: cycleId,
        patch: [
          {
            op: "replace",
            path: "/isClosed",
            value: true,
          },
        ],
        clientId: socket.id,
      })
      .then(() => {});

    store.dispatch({
      type: PATCH_SECTION,
      patchSection: {
        id: cycleId,
        patch: { isClosed: true, isLocalClose: true },
        clientId: socket.id,
      },
    });
  };

  const handleReopen = (cycleId: string) => {
    axiosInstance
      .patch("/api/workSection", {
        sectionId: cycleId,
        patch: [{ op: "replace", path: "/isClosed", value: false }],
        clientId: socket.id,
      })
      .then(() => {});

    store.dispatch({
      type: PATCH_SECTION,
      patchSection: {
        id: cycleId,
        patch: { isClosed: false, isReopen: true },
        clientId: socket.id,
      },
    });
  };

  const deleteCycle = () => {
    if (cycle.workIds.length > 0 && !cycle.isClosed) {
      return notificationsApi.displayError({
        title: "Cannot delete sprint",
        body: <span>Sprint contains tasks</span>,
        duration: 3,
      });
    }
    axiosInstance
      .delete(`/api/workSection`, {
        data: {
          sectionId: id,
          clientId: socket.id,
        },
      })
      .then(() => {
        store.dispatch({ type: DELETE_SECTION, sectionId: id });
      });
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
    visibility: disableDrag ? "hidden" : undefined,
  });

  if (!cycle)
    return (
      <Draggable
        isDragDisabled={!canEditCycles || disableDrag}
        key={id}
        draggableId={id}
        index={index}
      >
        {(provided, snapshot) => <></>}
      </Draggable>
    );

  return (
    <Draggable
      isDragDisabled={!canEditCycles || disableDrag}
      key={id}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...provided.draggableProps}
        >
          <div className={styles.rowContainer}>
            <div className={styles.grabHandleIconContainer}>
              <img
                src={SixDotImg}
                alt="Grab Handle"
                style={{ visibility: disableDrag ? "hidden" : undefined }}
                className={
                  styles.grabHandleIcon +
                  " " +
                  (snapshot.isDragging ? styles.dragging : "")
                }
                {...provided.dragHandleProps}
              />
            </div>

            <NameEditor
              name={name}
              disabled={!canEditCycles}
              setname={setname}
              save={save}
              cancel={cancel}
              isClosed={cycle.isClosed}
            >
              <span className={styles.deleteBtn}>
                <Button
                  onClick={() => {
                    try {
                      deleteCycle();
                    } catch (e: any) {
                      notificationsApi.displayError({
                        body: <span>{e.message}</span>,
                        duration: 3,
                      });
                    }
                  }}
                  buttonType={ButtonTypes.LINK}
                  disabled={!canEditCycles}
                  icon={<DeleteOutlined />}
                  style={{ marginRight: "8px" }}
                />
              </span>
              <Button
                disabled={!canEditCycles}
                onClick={() => {
                  try {
                    cycle.isClosed
                      ? handleReopen(id)
                      : handleIsClosedChange(id);
                  } catch (e: any) {
                    notificationsApi.displayError({
                      body: <span>{e.message}</span>,
                      duration: 3,
                    });
                  }
                }}
                iconStyle={cycle.isClosed ? { color: "#fff" } : undefined}
                className={cycle.isClosed ? styles.doneIcon : undefined}
                icon={<CheckOutlined />}
              />
            </NameEditor>
          </div>
        </div>
      )}
    </Draggable>
  );
};

const AddingCycle: React.FC<{
  setaddingCycle: React.Dispatch<React.SetStateAction<boolean>>;
  groupId: string;
}> = ({ setaddingCycle, groupId }) => {
  const [name, setname] = useState("");
  const save = (blurring: boolean) => {
    if (!name) {
      setaddingCycle(false);
    } else workApi.saveCycle(name, groupId);
    setname("");
    if (blurring) {
      setaddingCycle(false);
    }
  };

  const cancel = () => {
    setname("");
    setaddingCycle(false);
  };

  return (
    <NameEditor
      name={name}
      setname={setname}
      save={save}
      cancel={cancel}
      addingNew={true}
    />
  );
};

const NameEditor: React.FC<{
  name: string;
  setname: React.Dispatch<React.SetStateAction<string>>;
  save: any;
  cancel: any;
  isClosed?: boolean;
  disabled?: boolean;
  addingNew?: boolean;
}> = ({
  name,
  setname,
  children,
  save,
  cancel,
  isClosed,
  addingNew,
  disabled,
}) => {
  const [active, setactive] = useState(false);
  const className = useOptionalClassName({
    baseStyle: styles.rowEditor,
    pairs: [
      { extraStyle: styles.active, withExtra: active },
      { extraStyle: styles.done, withExtra: isClosed },
      { extraStyle: styles.addingNew, withExtra: addingNew },
    ],
  });

  return (
    <div className={className}>
      <input
        value={name}
        style={{ lineHeight: "28px" }}
        className="caption primary medium"
        onBlur={() => {
          setactive(false);
          save(true);
        }}
        autoFocus={addingNew}
        disabled={disabled}
        onChange={(e) => setname(e.target.value)}
        onFocus={() => setactive(true)}
        onKeyDown={(e) => {
          if (e.key === VALUE_ENTER) {
            save();
            return;
          }
          if (e.key === VALUE_ESCAPE) {
            cancel();
            return;
          }
        }}
      />
      {children}
    </div>
  );
};

export default CyclesEditor;
