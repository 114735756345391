import { batch } from "react-redux";
import {
  DELETE_BLOCK,
  OPEN_CONFIRMATION_MODAL,
  REFOCUS,
  SET_UNDO,
  UPDATE_CURRENT_MONTHLY_COUNT,
} from "store/actions";
import { Block, FocusType } from "store/reducers/blockReducer";
import {
  checkPreviousBlockIsTitle,
  getBlockById,
  getCurrentContext,
  getNextSibling,
  getPreviousBlock,
  getPreviousSibling,
  getPreviousSiblingOrParent,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $documentMode, $focusOn, prevState } from "store/storeExporter";
import { moveCaretAtLineEnd } from "utilities/caretMovement";
import { entityKeepTypes, ILineValue, LineType } from "utilities/lineUtilities";
import { stripHtml } from "utilities/stringUtilities";
import { ContainerTypes, DocumentModes, IBlockContext } from "utilities/types";
import {
  BlockActionTypes,
  clearTextSaveTimeout,
  getAntiAction,
  unselectBlocks,
} from "../blockActions";
import { breakDownHtml, getHtml } from "../blockValueHelpers";
import { handleUpdateAction } from "../primitiveActions/primitiveActions";
import { changeSingleBlockType } from "./blockTypesActions";
import { checkFocusTitle, moveBlocks } from "./moveActions";
import { setUpdateObjectToStore } from "./persistActions";
import { handleLocalEntityDisconnect } from "./textUpdateActions";
import {
  ActionObject,
  ActionWrapperObject,
  createActionWrapperObject,
} from "./undoUtils";

export const handleBlockDelete = (deleteProps: {
  currentBlock: Block;
  context: any;
  addedText: DocumentFragment | null;
  diff: number;
}) => {
  clearTextSaveTimeout();
  const currentBlockRef = {
    ...deleteProps.currentBlock,
    ...deleteProps.currentBlock.blockSubject.value,
  };
  const newState = store.getState().blocks;
  const context = newState.contexts[deleteProps.context.id];
  const prevBlockId = getPreviousBlock(newState, currentBlockRef, context);
  const prevSiblingId = getPreviousSibling(newState, currentBlockRef, context);

  const saveActions: ActionObject[] = [];
  const undoObject: ActionWrapperObject = {
    actions: [],
    context: deleteProps.context,
    focusOn: { ...$focusOn.value },
    selectedBlocks: store.getState().blocks.selectedBlocks,
    documentMode: store.getState().blocks.documentMode,
  };

  if (prevBlockId) {
    const prevIsTitle = checkPreviousBlockIsTitle(
      newState,
      deleteProps.currentBlock.id
    );
    if (prevIsTitle && currentBlockRef.children.length > 0) return;

    const prevBlock = getBlockById(newState.dict, prevBlockId);
    let referenceId = prevBlockId;
    let options: "child" | "sibling" = "child";
    if (!entityKeepTypes.includes(prevBlock.lineType)) {
      if (deleteProps.addedText) {
        const prevBlockHtmlRef: any =
          deleteProps.context.ref.current.querySelectorAll(
            `[data-block-id="${prevBlockId}"]`
          )[0];
        prevBlockHtmlRef.focus();
        moveCaretAtLineEnd(prevBlockHtmlRef, 0);
        const lastChild = prevBlockHtmlRef.lastChild;

        const selection = document.getSelection();
        const range = selection?.getRangeAt(0);
        const selectedNode = range?.endContainer;
        if (selectedNode) {
          const notEditble = selectedNode.parentElement?.closest(
            `[contenteditable="false"]`
          );
          if (notEditble) range?.setStartAfter(notEditble);
        }
        prevBlockHtmlRef.normalize();
        if (lastChild) {
          range?.setStartAfter(lastChild);
          range?.setEndAfter(lastChild);
        }

        range?.insertNode(deleteProps.addedText);
        prevBlockHtmlRef.normalize();
        range?.collapse(true);

        let prevValue: ILineValue[] = [];
        prevBlockHtmlRef.childNodes.forEach((child: any) => {
          prevValue.push(breakDownHtml(child));
        });

        const newDate = new Date();
        const updateValueAction: ActionObject = {
          id: prevBlock.id,
          delta: {
            value: prevValue,
            modifiedBy: prevState.value.user?.id,
            textModifiedBy: prevState.value.user?.id,
            dateModified: newDate,
            textDateModified: newDate,
          },
          type: BlockActionTypes.update,
        };

        saveActions.push(updateValueAction);
        const reverseAction: ActionObject = getAntiAction(updateValueAction);
        undoObject.actions.push(reverseAction);
        handleUpdateAction(updateValueAction);
      }
    } else {
      if (deleteProps.addedText) return;
    }

    const prevSibling = getBlockById(newState.dict, prevSiblingId);
    if (prevSiblingId) {
      referenceId = prevSiblingId;
      options = "child";
    }

    if (prevSibling && prevSibling.id) {
      if (prevSibling.children.length > 0) {
        referenceId = prevSibling.children[prevSibling.children.length - 1];
        options = "sibling";
      }
    }

    if (currentBlockRef.children.length > 0) {
      const selectedBlocks = currentBlockRef.children;
      moveBlocks({
        type: "after",
        options,
        referenceId,
        selectedBlocks,
        undoObject,
        saveActions,
      });
    }

    deleteBlockMiddleware(currentBlockRef.id);

    if (undoObject) {
      const action: ActionObject = {
        delta: {},
        type: BlockActionTypes.insert,
        id: currentBlockRef.id,
        options: { recoverDelete: true },
      };
      undoObject.actions.push(action);
    }

    if (saveActions) {
      const action: ActionObject = {
        delta: {},
        type: BlockActionTypes.delete,
        id: currentBlockRef.id,
      };
      saveActions.push(action);
    }

    const newFocusObject = {
      ...$focusOn.value,
      ...{
        focusBlockId: prevBlockId,
        type: FocusType.withDiff,
        caretPosition: 0,
        diff: deleteProps.diff,
      },
    };

    store.dispatch({
      type: REFOCUS,
      param: {
        newFocus: newFocusObject,
      },
    });
    store.dispatch({
      type: SET_UNDO,
      param: {
        context: deleteProps.context,
        contextId: deleteProps.context.id,
        undoObject,
      },
    });
    setUpdateObjectToStore(saveActions, deleteProps.context);
  } else {
    if (
      currentBlockRef.children.length === 0 &&
      stripHtml(getHtml(currentBlockRef.value)).length === 0
    ) {
      const titleRef: any = checkFocusTitle(
        currentBlockRef,
        deleteProps.context
      );

      if (!titleRef) return;
      deleteBlockMiddleware(currentBlockRef.id);
      if (undoObject) {
        const action: ActionObject = {
          delta: {},
          type: BlockActionTypes.insert,
          id: currentBlockRef.id,
          options: { recoverDelete: true },
        };
        undoObject.actions.push(action);
      }

      if (saveActions) {
        const action: ActionObject = {
          delta: {},
          type: BlockActionTypes.delete,
          id: currentBlockRef.id,
        };
        saveActions.push(action);
      }

      if (titleRef) moveCaretAtLineEnd(titleRef, 0);
      store.dispatch({
        type: SET_UNDO,
        param: {
          context: deleteProps.context,
          contextId: deleteProps.context.id,
          undoObject,
        },
      });
      setUpdateObjectToStore(saveActions, deleteProps.context);
    }
  }
};

export const blockModeDelete = (deleteProps: {
  blockId: string;
  context: IBlockContext;
  selectedBlocks?: string[];
}) => {
  batch(() => {
    const newState = store.getState().blocks;
    const block = getBlockById(newState.dict, deleteProps.blockId);
    const currentContext = getCurrentContext(newState, deleteProps.context.id);
    let selectedBlocks =
      newState.selectedBlocks.length > 0
        ? [...newState.selectedBlocks]
        : [block.id];

    if (deleteProps.selectedBlocks) selectedBlocks = deleteProps.selectedBlocks;

    const selectedWorkIds = [];
    selectedBlocks.forEach((id: string) => {
      const block = getBlockById(newState.dict, id);
      if (block && [LineType.Title].includes(block.lineType)) {
        if (
          block.referencingContainerId &&
          block.referencingContainerType === ContainerTypes.PROJECT
        )
          selectedWorkIds.push(block.referencingContainerId);
      }
    });

    const actionExecute = () => {
      if (
        block.lineType === LineType.image &&
        $documentMode.value === DocumentModes.INSERT
      )
        return changeSingleBlockType(
          block.id,
          LineType.text,
          deleteProps.context
        );

      const firstBlock = getBlockById(newState.dict, selectedBlocks[0]);
      const lastBlock = getBlockById(
        newState.dict,
        selectedBlocks[selectedBlocks.length - 1]
      );
      let nextFocusBlockId = getNextSibling(
        newState,
        lastBlock,
        currentContext
      );
      if (!nextFocusBlockId) {
        nextFocusBlockId = getPreviousSiblingOrParent(
          newState,
          firstBlock,
          currentContext
        );
      }

      const saveActions: ActionObject[] = [];
      unselectBlocks({ id: block.id, type: "currentBlock" });
      const undoObject = createActionWrapperObject(deleteProps.context);
      for (const blockId of selectedBlocks) {
        deleteChildrenAndBlock({
          blockId,
          saveActions,
          undoObject,
        });
      }

      const newFocusObject = {
        ...$focusOn.value,
        ...{
          focusBlockId: nextFocusBlockId,
          type: FocusType.prevPosition,
          caretPosition: 0,
          diff: 0,
        },
      };

      store.dispatch({
        type: REFOCUS,
        param: {
          newFocus: newFocusObject,
        },
      });
      store.dispatch({
        type: SET_UNDO,
        param: {
          context: deleteProps.context,
          contextId: deleteProps.context.id,
          undoObject,
        },
      });
      setUpdateObjectToStore(saveActions, deleteProps.context);
    };

    if (selectedWorkIds.length > 0) {
      store.dispatch({
        type: OPEN_CONFIRMATION_MODAL,
        message: "",
        body: "This action will delete the work items",
        title: `Confirm delete`,
        confirmMessage: "Confirm",
        confirmAction: () => {
          actionExecute();
        },
        cancelAction: () => {
          store.dispatch({
            type: REFOCUS,
            param: {
              newFocus: { ...$focusOn.value },
            },
          });
        },
        display: true,
      });
    } else actionExecute();
  });
};

const deleteChildrenAndBlock = (deleteProps: {
  blockId: string;
  saveActions: ActionObject[];
  undoObject: ActionWrapperObject;
}) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, deleteProps.blockId);
  if (block.children && block.children.length > 0) {
    const children = [...block.children].reverse();
    for (const childId of children) {
      deleteChildrenAndBlock({
        blockId: childId,
        saveActions: deleteProps.saveActions,
        undoObject: deleteProps.undoObject,
      });
    }
  }
  deleteBlockMiddleware(deleteProps.blockId);
  const action: ActionObject = {
    id: deleteProps.blockId,
    delta: {},
    type: BlockActionTypes.delete,
  };
  const antiAction = getAntiAction(action);
  deleteProps.saveActions.push(action);
  deleteProps.undoObject.actions.push(antiAction);
};

export const deleteBlockMiddleware = (
  blockId: string,
  options?: {
    socketUpdate: boolean;
  }
) => {
  batch(() => {
    const block = getBlockById(store.getState().blocks.dict, blockId);
    if (block && block.id) {
      handleLocalEntityDisconnect(block, {
        immediate: true,
        socketUpdate: options?.socketUpdate,
      });
    }

    store.dispatch({
      type: UPDATE_CURRENT_MONTHLY_COUNT,
      param: {
        delta: -1,
      },
    });

    store.dispatch({
      type: DELETE_BLOCK,
      param: {
        id: blockId,
      },
    });
  });
};
