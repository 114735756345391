import { REFOCUS, SET_NEW_CONTEXT } from "store/actions";
import store, { $focusOn } from "store/storeExporter";
import { IBlockContext } from "utilities/types";

export const createContextInState = (context: IBlockContext) => {
  store.dispatch({
    type: SET_NEW_CONTEXT,
    param: {
      blockContext: context,
    },
  });
};

export const defaultRefocus = () => {
  store.dispatch({
    type: REFOCUS,
    param: {
      newFocus: $focusOn.value,
    },
  });
};
