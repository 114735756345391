import Button, { ButtonTypes } from "components/Button";
import { useState } from "react";
import TabLayout from "./TabLayout";
import styles from "./options/options.module.scss";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities } from "utilities/types";
import { axiosInstance } from "index";
import { useBase } from "store/reducers/workspaceReducer";

enum DiscordIntegrationSteps {
  BaseConnection,
  UserConnection,
  GroupConnection,
}

const DiscordIntegration = (props: any) => {
  const [loading, setLoading] = useState<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeModes, setActiveModes] = useState<DiscordIntegrationSteps[]>([
    DiscordIntegrationSteps.BaseConnection,
    DiscordIntegrationSteps.UserConnection,
  ]);
  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });
  const base = useBase();
  return (
    <TabLayout title="Connect to Discord">
      {activeModes.includes(DiscordIntegrationSteps.BaseConnection) && (
        <div className={styles.options} style={{ paddingLeft: "8px" }}>
          <p className="body2 secondary" style={{ marginBottom: "24px" }}>
            Get project & task updates directly in Discord. Clarity will create
            threads, invite relevant contributors, and clean-up threads
            automatically. Keeping your server (and conversations) organized.
          </p>
          {!props.base.integrations?.discord?.activated && (
            <Button
              isLoading={loading}
              disabled={
                props.base.integrations?.discord?.activated || !canEditSettings
              }
              buttonType={ButtonTypes.PRIMARY}
              onClick={() => {
                setLoading(true);
                window.open(
                  `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_APP_CLIENT_ID}&permissions=${process.env.REACT_APP_DISCORD_PERMISSIONS}&state=${props.base.id}&redirect_uri=${process.env.REACT_APP_DISCORD_CALLBACK_URL}&response_type=code&scope=bot%20identify`,
                  "_blank"
                );
              }}
            >
              Connect to Discord
            </Button>
          )}
          {base.base.integrations?.discord?.activated && (
            <>
              <p className="body2 secondary" style={{ marginBottom: "24px" }}>
                This base is connected to the server:{" "}
                <b>{base.base.integrations?.discord?.serverName}</b>
              </p>
              <Button
                isLoading={loading}
                disabled={
                  (props.base.integrations
                    ? props.base.integrations.slack
                    : false) || !canEditSettings
                }
                buttonType={ButtonTypes.PRIMARY}
                onClick={() => {
                  setLoading(true);
                  axiosInstance
                    .post(`/api/workspace/discord/disconnect`, {
                      baseId: base.base.id,
                    })
                    .then(() => {
                      setLoading(false);
                    })
                    .catch((e) => {
                      setLoading(false);
                      console.log("e", e);
                    });
                }}
              >
                Disconnect
              </Button>
            </>
          )}
        </div>
      )}
    </TabLayout>
  );
};

export default DiscordIntegration;
