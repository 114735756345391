import {
  SET_ACTIVE_WORKSPACE,
  SET_BASE_TOKENS,
  UPDATE_TOKEN,
} from "store/actions";
import { IToken } from "utilities/types";

interface ITokenReducerState {
  dict: TokenDict;
  ids: string[];
  networkTokens: {
    [networkId: string]: string[];
  };
}

interface TokenDict {
  [id: string]: IToken;
}

const initialState: ITokenReducerState = {
  dict: {},
  ids: [],
  networkTokens: {},
};

interface updateGateAction {
  type: string;
  param: {
    id: string;
    type: "add" | "delete" | "update";
    delta: Partial<IToken>;
  };
}

interface SetBaseGatesAction {
  type: string;
  param: {
    tokens: IToken[];
  };
}

export default function tokenReducer(
  state: ITokenReducerState = initialState,
  action: updateGateAction & SetBaseGatesAction
) {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      return initialState;
    }

    case SET_BASE_TOKENS: {
      if (action.param.tokens) {
        const newState: ITokenReducerState = {
          ids: [],
          dict: {},
          networkTokens: {},
        };

        let defaultToken;

        action.param.tokens.forEach((token) => {
          if (token.default) defaultToken = token.id;
          else {
            newState.ids.push(token.id);
            if (newState.networkTokens[token.networkId]) {
              newState.networkTokens[token.networkId].push(token.id);
            } else {
              newState.networkTokens[token.networkId] = [token.id];
            }
          }
          newState.dict[token.id] = token;
        });
        if (defaultToken) {
          newState.ids.splice(0, 0, defaultToken);
        }
        return newState;
      }
      return state;
    }

    case UPDATE_TOKEN: {
      const newState = { ...state };

      newState.dict = { ...newState.dict };
      if (action.param.type === "add") {
        newState.ids = [...newState.ids];
        const defaultValue = action.param.delta.default;
        if (defaultValue) {
          newState.ids.splice(0, 0, action.param.id);
        } else {
          const insertIndex = newState.ids.length;
          // - tokenApi.presetTokens.length;
          newState.ids.splice(insertIndex, 0, action.param.id);
          if (action.param.delta.networkId) {
            if (newState.networkTokens[action.param.delta.networkId]) {
              newState.networkTokens[action.param.delta.networkId].push(
                action.param.id
              );
            } else {
              newState.networkTokens[action.param.delta.networkId] = [
                action.param.id,
              ];
            }
          }
        }
        newState.dict[action.param.id] = action.param.delta as IToken;
        return newState;
      }
      if (action.param.type === "delete") {
        newState.ids = [...newState.ids];
        const id = action.param.id;
        const index = newState.ids.indexOf(id);
        if (index < 0) return state;
        const token = newState.dict[id];
        const networkIndex =
          newState.networkTokens[token.networkId].indexOf(id);
        newState.networkTokens[token.networkId].splice(networkIndex, 1);
        newState.ids.splice(index, 1);
        delete newState.dict[action.param.id];
        return newState;
      }
      if (action.param.type === "update") {
        if (!newState.dict[action.param.id]) return state;
        const id = action.param.id;
        if (action.param.delta.default) {
          newState.ids = [...newState.ids];
          const index = newState.ids.indexOf(id);
          if (index > 0) {
            newState.ids.splice(index, 1);
            newState.ids.splice(0, 0, id);
          }
        }
        newState.dict[action.param.id] = {
          ...newState.dict[action.param.id],
          ...action.param.delta,
        };
        return newState;
      }
      return initialState;
    }
    default:
      return state;
  }
}
