import { DocumentModes, ContainerTypes } from "./types";

export interface Line {
  id: string;
  ref: any;
  lineType: LineType;
  value: ILineValue[];
  checkboxStatus?: string; // TODO: add enumaration or interface for the status
  referencingContainerId?: string;
  referencingContainerType?: ContainerTypes;
  children: string[];
  parentId: string;
  indentLevel: number;
  documentRank?: string;
  containerId: string;
  containerType: ContainerTypes;
  dateCreated: Date;
  dateModified: Date;
  textDateModified: Date;
  subLinesList: LineType;
  selected?: boolean;
  frozen?: boolean;
  frozenBy?: any;
  options?: any;
  ancestors?: any[];
  isFolded: boolean;
  baseId: string;
  createdBy: string;
  modifiedBy: string;
  textModifiedBy: string;
}

export interface LineMenuState {
  isOpened: boolean;
  isAtBegining: boolean;
  hoveredItem: number;
  filterMenuBy: string;
  type: string;
  executeSelection: boolean;
  preOpenedCaretPosition: number;
  options: any;
}

export interface ILineValue {
  type: LineValueType;
  value: string;
  children?: ILineValue[];
  linkTo?: string;
  options?: any;
  codeBlockLanguage?: string;
  tableBlockColumnData?: {
    order: string[];
    dict: {
      [id: string]: {
        id: string;
        manualSize?: number;
      };
    };
    hasHeaderColumn?: boolean;
    hasHeaderRow?: boolean;
  };
  nodeId?: string;
}

export enum LineValueType {
  text = "#text",
  b = "B",
  i = "I",
  em = "EM",
  code = "CODE",
  a = "A",
  break = "break",
  span = "SPAN",
  childrenText = "showChildren",
  strike = "STRIKE",
  s = "S",
  strong = "STRONG",
  mention = "MENTION",
  title = "TITLE",
  citation = "CITATION",
  comment = "COMMENT",
  highlight = "HIGHLIGHT",
  del = "DEL",
  div = "DIV",
  usermention = "USERMENTION",
  citationPlaceholder = "CITATIONPLACEHOLDER",
}

export enum LineType {
  heading1 = "Heading 1",
  heading2 = "Heading 2",
  heading3 = "Heading 3",
  numberedList = "Numbered List",
  bulletedList = "Bulleted List",
  document = "Doc",
  text = "Text",
  work = "Work",
  checkbox = "Checkbox",
  image = "Image",
  Title = "Title",
  tagsBlock = "TagsBlock",
  video = "Video",
  divider = "Divider",
  widget = "Widget",
  loom = "Loom",
  figma = "Figma",
  code = "Code",
  pdf = "Pdf",
  webLink = "WebLink",
  callout = "Callout",
  quote = "Quote",
  table = "Table",
  tableRow = "TableRow",
}

export const entityKeepTypes = [
  LineType.document,
  LineType.image,
  LineType.Title,
  LineType.tagsBlock,
  LineType.video,
  LineType.divider,
  LineType.widget,
  LineType.loom,
  LineType.work,
  LineType.figma,
  LineType.pdf,
  LineType.webLink,
];

export const allowChildBlocks = [
  LineType.widget,
  LineType.loom,
  LineType.work,
  LineType.figma,
  LineType.pdf,
  LineType.webLink,
  LineType.image,
];

export const headingTypes = [
  LineType.heading1,
  LineType.heading2,
  LineType.heading3,
];

export enum ListType {
  numberedList = "Numbered List",
  bulletedList = "Bulleted List",
}

export enum LineStatus {
  inProgress = "In Progress",
  todo = "To do",
  done = "Done",
}

export enum TypeOfChange {
  update = "update",
  delete = "delete",
  add = "add",
}

export interface StateLineObject {
  entities: LinesObjectEntities;
  rootLinesId: string[];
  selectedLinesIds: string[];
}

export interface LinesObjectEntities {
  lines: LinesObject;
}

export interface LinesObject {
  [id: string]: Line;
}

export enum TypeOfLineAdding {
  addLineAfterFocusedLine = "addLineAfterFocusedLine",
  addLineBeforeFocusedLine = "addLineBeforeFocusedLine",
  addLineAsChildOfFocusedLine = "addLineAsChildOfFocusedLine",
}

export enum TypeOfDeletingLine {
  deleteLineAddTextToPrevious = "deleteLineAddTextToPrevious",
  deletePreviousLine = "deletePreviousLine",
  deletePreviousLinePrependText = "deletePreviousLinePrependText",
  deleteLineFocusLastChildOfPerviousLine = "deleteLineFocusLastChildOfPerviousLine",
  deleteLineFindNewFocus = "deleteLineFindNewFocus",
  deleteLineAddTextToParent = "deleteLineAddTextToParent",
  deleteLineAddTextToLastChild = "deleteLineAddTextToLastChild",
  none = "none",
  deleteLineAddChildrenToPreviousLine = "deleteLineAddChildrenToPreviousLine",
}

export interface NewLineActionParamters {
  typeOfLineAdding: TypeOfLineAdding;
  newTextForExistingLine: ILineValue[];
  textForNewLine: ILineValue[];
  insertionIndex: number;
  ref: any;
  lineType?: LineType;
  hasChildrenLines: boolean;
  indexOfEnteredElement: number;
  id: string;
  indentLevel: number;
  caretPosition: number;
  numberOfCharacters: number;
  newRefToFocus: any;
  diff: number;
  parentId: string;
  index?: string;
  referencingContainerId?: string;
  referencingContainerType?: ContainerTypes;
  projectNr?: number;
  listType?: LineType;
  containerId: string;
  containerType: ContainerTypes;
  menuStateCopy?: LineMenuState;
}

export interface IndentActionParameters {
  newListType?: LineType;
  id: string;
  lineNumber: number;
  caretPosition: number;
  lineText: any;
  parentId?: string;
  indentLevel: number;
  documentMode: DocumentModes;
  event: any;
}

export interface DeleteActionParameters {
  typeOfDeletingLine: TypeOfDeletingLine;
  lineId: string;
  lineIndex: number;
  indentLevel: number;
  parentId: string;
  textAdded: ILineValue[];
  newRefToFocus: any;
  diff: number;
}

export interface OutdentActionParameters {
  lineText: any;
  id: string;
  lineNumber: number;
  indentLevel: number;
  caretPosition: number;
  newRefToFocus: any;
  parentId: string;
  event: any;
}

export enum LineKeys {
  rootLines = "rootLines",
  lines = "lines",
}

export enum ReplyType {
  TEXT = "text",
  RESOLVE = "resolve",
  REOPEN = "reopen",
}

export enum DiscussionFormType {
  REPLY = "Reply",
  REOPEN = "Re-open",
  SAVE = "Save",
}

export enum KeyCodes {
  a = 65,
  p = 80,
  backspace = 8,
  delete = 46,
  enter = 13,
  arrow_right = 39,
  arrow_left = 37,
  arrow_up = 38,
  arrow_down = 40,
  tab = 9,
  z = 90,
  y = 89,
  esc = 27,
  e = 69,
  b = 66,
  i = 73,
  j = 74,
  k = 75,
  space = 32,
  slash = 191,
  c = 67,
  x = 88,
  bracketLeft = 219,
  bracketRight = 221,
  u = 85,
  num0 = 48,
  numpPad0 = 96,
  num1 = 49,
  num2 = 50,
  num3 = 51,
  d = 68,
  meta = 91,
  metaRight = 93,
  ctrlKey = 17,
  shift = 16,
  f = 70,
  m = 77,
  fireMeta = 224,
}
