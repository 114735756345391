import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import StatusDisplay from "components/StatusDisplay";
import { useAbilityChecker } from "editor/utils/customHooks";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import { useShallowSelector } from "utilities/hooks";
import {
  Abilities,
  CommandPaletteContext,
  ContainerTypes,
  WorkTypes,
} from "utilities/types";

const StatusRow: React.FC<{
  statusId?: string;
  canEditEntity: boolean;
  workType: WorkTypes;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ statusId, canEditEntity, workType, containerType, containerId }) => {
  const status = useShallowSelector((store) =>
    statusId ? store.work.statuses.dict[statusId] : undefined
  );
  const canBeReviewer = useAbilityChecker({
    abilityName: Abilities.CAN_BE_REVIEWER,
  });
  const canEditReviewer = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_REVIEWER,
  });

  const handleStatusClick = () =>
    openCommandPalette(CommandPaletteContext.STATUS, {
      selectedItemIds: [containerId],
      slectedItemsType: containerType,
    });

  return (
    <MetadataBlock
      title="Status"
      infoText={status?.name ?? ""}
      disabled={!(canEditEntity || canBeReviewer || canEditReviewer)}
      onClick={handleStatusClick}
      prefix={
        <StatusDisplay
          noSpace
          statusId={statusId as string}
          workType={workType}
          size="xlarge"
          showName={false}
        />
      }
    />
  );
};

export default StatusRow;
