import {
  CommandPaletteContext,
  ContainerTypes,
  UserRole,
  Abilities,
  ViewNames,
  WorkTypes,
} from "./../utilities/types";
import styles from "./containerDetailMetadata/containerDetailMetadata.module.scss";
import { useWork } from "store/reducers/workReducer";

import { ChunkDestination } from "utilities/stateTypes";
import { useCommandPaletteShower } from "store/reducers/commandPaletteReducer";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import Conditional from "components/Conditional";
import { useAbilityChecker } from "editor/utils/customHooks";
import navigationApi from "clientApi/navigationApi";
import CycleRow from "./containerDetailMetadata/metadataTypes/Cycle";
import AssigneeRow from "./containerDetailMetadata/metadataTypes/Assignee";
import StatusRow from "./containerDetailMetadata/metadataTypes/Status";
import PriorityRow from "./containerDetailMetadata/metadataTypes/Priority";
import GroupsRow from "./containerDetailMetadata/metadataTypes/Groups";
import ContributorsRow from "./containerDetailMetadata/metadataTypes/Contributors";
import TargetDateRow from "./containerDetailMetadata/metadataTypes/TargetDate";
import MilestoneRow from "./containerDetailMetadata/metadataTypes/Milestone";
import ReviewerRow from "./containerDetailMetadata/metadataTypes/Reviewer";
import RewardRow from "./containerDetailMetadata/metadataTypes/Reward";
import TagsRow from "./containerDetailMetadata/metadataTypes/Tags";
import ParentRow from "./containerDetailMetadata/metadataTypes/Parent";
import { memo } from "react";
import { shallowEqual } from "react-redux";
import { usePaneId } from "store/reducers/filterReducer";

interface IDetailMetadataProps {
  containerId: string;
  containerType: ContainerTypes;
  noPersist?: boolean;
  paneId: ChunkDestination;
  presave?: boolean;
  rowMode?: boolean;
}

export const ContainerDetailMetadata = memo((props: IDetailMetadataProps) => {
  if (props.containerType === ContainerTypes.TEMPLATE)
    return <TemplateMetadata {...props} />;
  return <WorkItemMetadata {...props} />;
}, shallowEqual);

const WorkItemMetadata = (props: IDetailMetadataProps) => {
  const workItem = useShallowSelector((state) => {
    return state.work.dict[props.containerId];
  });

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember:
      workItem.workType === WorkTypes.TASK
        ? workItem.groupId
        : workItem.groupIds,
  });

  return (
    <>
      <div
        className={`${styles.container} ${
          props.rowMode ? styles.RowContainer : ""
        }`}
      >
        {props.rowMode && <div className="body bold secondary">Properties</div>}

        <div
          className={`${styles.metadataContainer} ${
            props.rowMode ? styles.metadataRowContainer : ""
          }`}
        >
          <StatusRow
            canEditEntity={canEditEntity}
            workType={workItem.workType}
            statusId={workItem.statusId}
            containerId={props.containerId}
            containerType={ContainerTypes.WORK}
          />

          <AssigneeRow
            canEditEntity={canEditEntity}
            workType={workItem.workType}
            assigneeId={workItem.assigneeId}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <GroupsRow
            workType={workItem.workType}
            canEditEntity={canEditEntity}
            groupIds={workItem.groupIds}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <ContributorsRow
            canEditEntity={canEditEntity}
            workType={workItem.workType}
            contributorIds={workItem.contributorIds}
            assigneeId={workItem.assigneeId}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <TargetDateRow
            canEditEntity={canEditEntity}
            dueDate={workItem.dueDate}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <PriorityRow
            canEditEntity={canEditEntity}
            priority={workItem.priority}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <MilestoneRow
            canEditEntity={canEditEntity}
            workType={workItem.workType}
            milestoneId={workItem.milestoneId}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <CycleRow
            itemId={workItem.id}
            cycleId={workItem.workSectionId}
            groupId={workItem.groupId}
            workType={workItem.workType}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <ReviewerRow
            reviewerId={workItem.reviewerId}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <RewardRow
            reward={workItem.reward}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <TagsRow
            canEditEntity={canEditEntity}
            workItem={workItem}
            paneId={props.paneId}
            noPersist={props.noPersist}
            rowMode={props.rowMode}
            containerId={workItem.id}
            containerType={ContainerTypes.WORK}
          />

          <ParentRow
            canEditEntity={canEditEntity}
            workType={workItem.workType}
            containerId={workItem.id}
            id={workItem.parentId}
          />
        </div>
      </div>
    </>
  );
};

const TemplateMetadata: React.FC<IDetailMetadataProps> = (props) => {
  const templateItem = useShallowSelector(
    (store) => store.templates.dict[props.containerId]
  );

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  return (
    <>
      <div
        className={`${styles.container} ${
          props.rowMode ? styles.RowContainer : ""
        }`}
      >
        {props.rowMode && <div className="body bold secondary">Properties</div>}

        <div
          className={`${styles.metadataContainer} ${
            props.rowMode ? styles.metadataRowContainer : ""
          }`}
        >
          <StatusRow
            canEditEntity={canEditEntity}
            workType={templateItem.workType}
            statusId={templateItem.statusId}
            containerId={props.containerId}
            containerType={ContainerTypes.TEMPLATE}
          />

          <AssigneeRow
            canEditEntity={canEditEntity}
            workType={templateItem.workType}
            assigneeId={templateItem.assigneeId}
            containerId={templateItem.id}
            containerType={ContainerTypes.TEMPLATE}
          />

          <ContributorsRow
            canEditEntity={canEditEntity}
            workType={templateItem.workType}
            contributorIds={templateItem.contributorIds}
            assigneeId={templateItem.assigneeId}
            containerId={templateItem.id}
            containerType={ContainerTypes.TEMPLATE}
          />

          <TargetDateRow
            canEditEntity={canEditEntity}
            dueDate={templateItem.dueDate}
            containerId={templateItem.id}
            containerType={ContainerTypes.TEMPLATE}
          />

          <PriorityRow
            canEditEntity={canEditEntity}
            priority={templateItem.priority}
            containerId={templateItem.id}
            containerType={ContainerTypes.TEMPLATE}
          />

          <MilestoneRow
            canEditEntity={canEditEntity}
            workType={templateItem.workType}
            milestoneId={templateItem.milestoneId}
            containerId={templateItem.id}
            containerType={ContainerTypes.TEMPLATE}
          />

          <ReviewerRow
            reviewerId={templateItem.reviewerId}
            containerId={templateItem.id}
            containerType={ContainerTypes.TEMPLATE}
          />

          <RewardRow
            reward={templateItem.reward}
            containerId={templateItem.id}
            containerType={ContainerTypes.TEMPLATE}
          />
        </div>
      </div>
    </>
  );
};

export function useMetadataPaletteEdit({
  itemDetails,
  paletteContextType,
  params,
}: {
  itemDetails: {
    itemId: string;
    entityType: ContainerTypes;
    workType: WorkTypes;
  };
  paletteContextType: CommandPaletteContext;
  params?: any;
}): React.MouseEventHandler {
  const { isBaseMember, userRole } = useShallowSelector((state) => ({
    isBaseMember: state.client.isBaseMember,
    userRole: state.client.roleType,
  }));

  const showCommandPalette = useCommandPaletteShower({
    itemId: itemDetails.itemId,
    entityType: itemDetails.entityType,
    workType: itemDetails.workType,
  });

  return (event) => {
    event.stopPropagation();
    if (!isBaseMember || userRole === UserRole.GUEST) {
      event.preventDefault();
      return;
    }
    showCommandPalette(paletteContextType, params);
  };
}

interface IMetadataBlockProps {
  title?: string;
  prefix?: React.ReactElement;
  infoText?: string | React.ReactElement;
  children?: any;
  onClick?: React.MouseEventHandler;
  suffix?: React.ReactElement;
  customStyles?: React.CSSProperties;
  disabled?: boolean;
}

export function MetadataBlock({
  title,
  prefix,
  infoText = "",
  children,
  onClick,
  suffix,
  customStyles,
  disabled,
}: IMetadataBlockProps) {
  const style = {
    ...(onClick ? { cursor: "pointer" } : {}),
    ...(customStyles ?? {}),
  };

  const classnames = useOptionalClassName({
    baseStyle: `${styles.metadataBlock} 
    ${title === "Target" ? styles.borderTopCover : ""}
    ${title === "Reviewer" ? styles.borderTopCover : ""}
    ${title === "Tags" ? styles.borderTopCover : ""}
    ${title === "Project" ? styles.borderTopCover : ""}`,
    pairs: [{ extraStyle: "no-permissions", withExtra: disabled }],
  });

  return (
    <div
      className={classnames}
      onClick={!disabled ? onClick : () => {}}
      style={style}
    >
      {title && (
        <div className={styles.metadataBlockTop}>
          <span className={styles.title}>{title}</span>
        </div>
      )}
      <div
        className={`${styles.metadataBlockBottom} ${styles.metadataBlockSplit}`}
        style={title === "Tags" ? { minWidth: "100%" } : {}}
      >
        <Conditional on={prefix}>
          <div className={styles.prefixContainer}>{prefix}</div>
        </Conditional>
        <Conditional on={infoText}>
          <span className={styles.infoText}>{infoText}</span>
        </Conditional>
        <Conditional on={children}>{children}</Conditional>
        <Conditional on={suffix}>
          <div className={styles.suffixContainer}>{suffix}</div>
        </Conditional>
      </div>
    </div>
  );
}

export function useParentClickHandler({
  container,
}: {
  container: any;
}) {
  const { workDict } = useWork();
  const showCommandPalette = useCommandPaletteShower(container);
  const paneId = usePaneId();

  const { isBaseMember, userRole } = useShallowSelector((state) => ({
    isBaseMember: state.client.isBaseMember,
    userRole: state.client.roleType,
  }));

  const handleParentClick: React.MouseEventHandler = (e) => {
    if (!isBaseMember || userRole === UserRole.GUEST) {
      e.preventDefault();
      e.stopPropagation();
      return;
    } else if (container.parentId) {
      const parentWorkItem = workDict[container.parentId];
      navigationApi.contextBasedNavigate({
        navigationChunk: {
          viewName: ViewNames.Detail,
          entity: {
            containerId: parentWorkItem.id,
            containerType: ContainerTypes.WORK,
            workType: parentWorkItem.workType,
          },
        },
        currentPane: paneId,
        shiftKey: e.shiftKey,
        forceToPane: ChunkDestination.peek,
      });
      e.preventDefault();
      e.stopPropagation();
    } else {
      showCommandPalette(CommandPaletteContext.PARENT);
    }
  };

  return handleParentClick;
}

export function useIsGuest() {
  return useShallowSelector(
    (state) => state.client.roleType === UserRole.GUEST
  );
}

export default ContainerDetailMetadata;
