import * as mixpanel from "mixpanel-browser";
import { UserEventTypes } from "utilities/types";

export default class Mixpanel {
  constructor() {
    try {
      if (process.env.MIXPANEL_PROJECT_TOKEN) {
        mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN);
      }
    } catch {
      // not initiated
    }
  }

  initiated: boolean = false;
  track(event: UserEventTypes, properties?: mixpanel.Dict) {
    if (this.initiated) {
      try {
        properties ? mixpanel.track(event, properties) : mixpanel.track(event);
      } catch {}
    }
  }
}
