import { ILineValue, LineType } from "./lineUtilities";
import store from "store/storeExporter";
import { cloneBlock } from "editor/utils/specificActions/cloneBlockActions";
import { createCitation } from "./createNewEntity";
import { ADD_CITATION } from "store/actions";
const { v4: uuidv4 } = require("uuid");

const showdown = require("showdown");

let linesBuffer: any[] = [];

const clearTags = (parsedHtml: HTMLDivElement) => {
  const els = parsedHtml?.getElementsByTagName("mention");
  if (els) {
    Array.from(els).forEach((el) => {
      el.outerHTML = el.textContent ? el.textContent : "";
    });
  }
};

export const pasteParser = (e: any, currentLineType: LineType) => {
  const dataTypes = e.types;
  let newLines: any[] | undefined = [];
  let data = "";

  let parsedHtml;

  if (dataTypes.includes("text/html")) {
    data = e.getData("text/html");
    const isNotion = e.types.includes("text/_notion-blocks-v2-production");
    const isWord =
      JSON.stringify(dataTypes) ===
        JSON.stringify(["text/plain", "text/html", "text/rtf", "Files"]) ||
      JSON.stringify(dataTypes) ===
        JSON.stringify(["text/plain", "text/html", "text/rtf"]);
    parsedHtml = htmlParser(data, isNotion, isWord);
    const clarityDataString = e.getData("clarity/internal");
    const clarityData = clarityDataString
      ? JSON.parse(clarityDataString)
      : undefined;
    const storeData = store.getState();
    const baseId = storeData.workspace.id;
    if (
      dataTypes.includes("clarity/blocks") &&
      baseId === clarityData?.sourceBase
    ) {
      try {
        const clarityDataString = e.getData("clarity/blocks");
        const clarityData = JSON.parse(clarityDataString);
        const state = store.getState();
        const blockEntities = state.blocks.dict;
        const containsWorkspaceId = dataTypes.includes("clarity/internal");
        const storeData = store.getState();
        const baseId = storeData.workspace.id;

        const clarityBaseId = JSON.parse(e.getData("clarity/internal"));
        const clonedBlocks = clarityData.clonedBlocks
          .filter((blockId: string) => {
            if (containsWorkspaceId && baseId !== clarityBaseId.sourceBase) {
              const block = blockEntities[blockId];
              if (block) {
                return block.lineType !== LineType.widget;
              }
              return false;
            }
            return true;
          })
          .map((blockId: string) => {
            const block = blockEntities[blockId];
            if (containsWorkspaceId && baseId !== clarityBaseId.sourceBase) {
              if ([LineType.work].includes(block.lineType)) {
                block.lineType = LineType.text;
              }
            }
            const clonedBlock: any = cloneBlock(block, {
              parentId: block?.parentId,
            });
            clonedBlock.children = block.children;
            clonedBlock.oldUuid = blockId;
            clonedBlock.subLinesList = block.subLinesList;

            return clonedBlock;
          })
          .map((block: any) => {
            const toReturn: any = {
              uuid: block.id,
              lineType: block.lineType,
              lineValues: block.value.slice().reverse(),
              indentLevel: block.indentLevel,
              children: block.children,
              parentId: block.parentId,
              oldUuid: block.oldUuid,
              src: "",
              checkboxStatus: block.checkboxStatus,
              subLinesList: block.subLinesList,
              documentRank: block.documentRank,
            };

            if (
              [LineType.image, LineType.loom, LineType.video].includes(
                block.lineType
              )
            ) {
              const resources = state.workspace.resources;
              const referencingResource =
                resources[block.referencingContainerId];
              if (referencingResource?.url)
                toReturn.src = referencingResource.url;
            } else if (
              [
                LineType.work,
                LineType.widget,
                LineType.webLink,
                LineType.figma,
                LineType.pdf,
              ].includes(block.lineType)
            ) {
              toReturn.referencingContainerId = block.referencingContainerId;
              toReturn.referencingContainerType =
                block.referencingContainerType;
            }
            const hasCitation = (lineValues: ILineValue[]): any => {
              for (const lineValue of lineValues) {
                if (lineValue.type === "CITATION") {
                  return true;
                } else {
                  if (lineValue.children && lineValue.children.length > 0) {
                    return hasCitation(lineValue.children);
                  }
                }
              }
              return false;
            };

            const createCitationForNewValues = (
              lineValues: any[],
              toReturn: any
            ): any => {
              for (const lineValue of lineValues) {
                if (lineValue.type === "CITATION") {
                  const oldCitation =
                    store.getState().citations.dict[
                      lineValue.options.citationId
                    ];
                  const now = new Date();
                  const newCitation = createCitation(
                    {
                      referencingBlockId: toReturn.id,
                      sourceBlockId: oldCitation.sourceBlockId,
                      dateAccessed: now,
                      dateModified: now,
                      referencingContainerId: toReturn.containerId,
                      sourceBlockBaseId: oldCitation.sourceBlockBaseId,
                      sourceContainerId: oldCitation.containerId,
                      sourceContanierType: oldCitation.containerType,
                      referencingContainerType: toReturn.containerType,
                      synchronize: oldCitation.synchronize,
                      dateCreated: now,
                    },
                    oldCitation.value
                  );

                  store.dispatch({
                    type: ADD_CITATION,
                    param: {
                      citation: newCitation,
                    },
                  });
                  lineValue.options.citationId = newCitation.citationId;
                  lineValue.citationId = newCitation.citationId;
                }
                if (lineValue.children && lineValue.children.length > 0) {
                  createCitationForNewValues(lineValue.children, toReturn);
                }
              }
            };
            if (hasCitation(toReturn.lineValues)) {
              createCitationForNewValues(toReturn.lineValues, toReturn);
            }
            return toReturn;
          });

        clonedBlocks.forEach((block: any) => {
          const parentBlock = clonedBlocks.find(
            (block1: any) => block1.oldUuid === block.parentId
          );
          block.parentId = parentBlock ? parentBlock.uuid : "";
        });

        return clonedBlocks;
      } catch {}
    }
    if (dataTypes.includes("clarity/internal")) {
      if (baseId !== clarityData?.sourceBase) {
        if (parsedHtml) clearTags(parsedHtml);
      }
    } else {
      if (parsedHtml) clearTags(parsedHtml);
    }

    let newLine = {
      uuid: uuidv4(),
      lineType: LineType.text,
      lineValues: [],
      htmlLineNodes: [],
      indentLevel: 0,
      children: [],
      parentId: "",
      htmlElement: undefined,
      firstLine: true,
    };
    const linesArrays = [newLine];
    newLines = documentIterator2(
      parsedHtml,
      linesArrays,
      [...linesArrays],
      [newLine],
      0,
      false
    );
  } else {
    data = e.getData("text/plain");
    newLines = data.split("\n").map((textValues) => {
      return {
        uuid: uuidv4(),
        lineType: LineType.text,
        lineValues: [
          new LineValueTest({
            type: "#text",
            name: "#text",
            value: textValues,
            lineValues: [],
            htmlElement: undefined,
            children: [],
          }),
        ],
        htmlLineNodes: [],
        indentLevel: 0,
        children: [],
        parentId: "",
      };
    });
  }
  return newLines
    ?.filter(
      (value) =>
        value.lineType === LineType.image ||
        value.lineValues.length > 0 ||
        ["UL", "OL"].includes(value.listHtmlElement?.nodeName)
    )
    .filter((value) => {
      return (
        value.lineValues.filter((lineValue: any) => lineValue.value !== "")
          .length !== value.lineValues
      );
    })
    .map((value) => {
      return {
        ...value,
        lineValues: reversLineValues(
          value.lineValues.filter((value1: any) => {
            return value1.value !== null || value1.children.length > 0;
          })
        ),
      };
    });
};

const reversLineValues = (lineValues: any[]) => {
  return lineValues
    .map((lineValue) => {
      return {
        ...lineValue,
        value:
          lineValue.value === null && lineValue.children.length === 0
            ? " "
            : lineValue.value,
      };
    })
    .map((lineValue) => {
      lineValue.children = reversLineValues(lineValue.children);
      return lineValue;
    })
    .reverse();
};

class LineValueTest {
  readonly uuid!: string;
  readonly name!: string;
  value!: string;
  readonly type!: string;
  readonly children: LineValueTest[] = [];
  readonly lineValues: LineValueTest[] = [];
  readonly link?: string;
  constructor(data: {
    name: string;
    value: string | null;
    type: string;
    lineValues: LineValueTest[];
    children: LineValueTest[];
    link?: string;
    htmlElement: any;
  }) {
    this.uuid = uuidv4();
    Object.assign(this, data);
  }
}

export const htmlParser = (
  htmlString: string,
  isNotion: boolean = false,
  isWord: boolean = false
) => {
  let htmlToParse = htmlString.replace(
    /(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g,
    ""
  );
  if (isNotion) {
    let test = new showdown.Converter({
      strikethrough: true,
    });
    htmlToParse = test.makeHtml(htmlToParse.replace(/\n/g, ""));
  }

  if (isWord || navigator.platform === "Win32") {
    htmlToParse = htmlToParse
      .replace(/>\s+</g, "><")
      .replace(/\n/g, "")
      .replace(/(\r\n|\n|\r)/gm, " ")
      .replace(/\s+/g, " ");
  }

  const placeholder = document.createElement("div");

  placeholder.insertAdjacentHTML("afterbegin", htmlToParse);
  return placeholder;
};

const documentIterator2 = (
  htmlElement: HTMLElement | ChildNode,
  lines: any[],
  viewedHtmlElements: any[],
  lastViewedLine: any[],
  indentLevel: number,
  skip?: boolean
) => {
  if (notAllowedHtmlTags.includes(htmlElement.nodeName)) {
    return;
  }
  let lastViewedLine2 = lastViewedLine;
  let newLine: {
    uuid: any;
    lineType?: LineType;
    lineValues?: never[];
    htmlLineNodes?: never[];
    indentLevel?: number;
    children?: never[];
    parentId?: any;
    htmlElement?: HTMLElement | ChildNode;
    subLinesList?: string;
    src?: string | null;
    firstLine?: boolean;
    checkboxStatus?: string;
  };
  if (htmlElement.nodeType === 1) {
    if (tagToBlockType[htmlElement.nodeName] !== undefined) {
      if (htmlElement.nodeName === "UL" || htmlElement.nodeName === "OL") {
        if (lastViewedLine2[0].lineValues.length !== 0) {
          indentLevel = indentLevel + 1;
          lastViewedLine2[0].htmlElement = htmlElement;
          lastViewedLine2[0].listHtmlElement = htmlElement;

          lastViewedLine2[0].subLinesList =
            htmlElement.nodeName === "UL"
              ? LineType.bulletedList
              : LineType.numberedList;
        }
      } else if (htmlElement.nodeName === "LI") {
        if (!skip) {
          const parent = getListParent(viewedHtmlElements, htmlElement);
          newLine = {
            uuid: uuidv4(),
            lineType: LineType.text,
            lineValues: [],
            htmlLineNodes: [],
            indentLevel: indentLevel,
            children: [],
            parentId: parent ? parent.uuid : "",
            htmlElement: htmlElement,
            firstLine: false,
          };
          lastViewedLine2.pop();
          lastViewedLine2.push(newLine);
          linesBuffer.push(newLine);
          lines.push(newLine);
          viewedHtmlElements.push(...lastViewedLine2);
        }
      } else if (htmlElement.nodeName === "IMG") {
        if (htmlElement instanceof HTMLElement) {
          if (
            lastViewedLine2[0].lineType === LineType.text &&
            lastViewedLine2[0].lineValues.length === 0
          ) {
            lastViewedLine2[0].lineType = tagToBlockType[htmlElement.nodeName];
            lastViewedLine2[0].indentLevel = indentLevel;
            lastViewedLine2[0].htmlElement = htmlElement;
            lastViewedLine2[0].src =
              htmlElement.nodeName === "IMG"
                ? htmlElement.getAttribute("src")
                : "";
          } else {
            if (!skip) {
              const parent = getListParent(viewedHtmlElements, htmlElement);
              newLine = {
                uuid: uuidv4(),
                lineType: tagToBlockType[htmlElement.nodeName],
                lineValues: [],
                htmlLineNodes: [],
                indentLevel: indentLevel,
                children: [],
                parentId: parent ? parent.uuid : "",
                htmlElement: htmlElement,
                src:
                  htmlElement.nodeName === "IMG"
                    ? htmlElement.getAttribute("src")
                    : "",
                firstLine: false,
              };
              lastViewedLine.pop();
              lastViewedLine2.push(newLine);
              linesBuffer.push(newLine);
              lines.push(newLine);
              viewedHtmlElements.push(...lastViewedLine2);
            }
          }
        }
      } else if (
        htmlElement.nodeName === "BR" ||
        htmlElement.nodeName === "WBR"
      ) {
        if (htmlElement instanceof HTMLElement) {
          if (!skip) {
            const parent = getListParent(viewedHtmlElements, htmlElement);

            newLine = {
              uuid: uuidv4(),
              lineType: tagToBlockType[htmlElement.nodeName],
              lineValues: [],
              htmlLineNodes: [],
              indentLevel: indentLevel,
              children: [],
              parentId: parent ? parent.uuid : "",
              firstLine: false,
            };
            lastViewedLine.pop();
            lastViewedLine2.push(newLine);
            linesBuffer.push(newLine);
            lines.push(newLine);
            viewedHtmlElements.push(...lastViewedLine2);
          }
        }
      } else if (testNodes.includes(htmlElement.nodeName)) {
        if (
          lastViewedLine2[0].lineType === LineType.text &&
          lastViewedLine2[0].lineValues.length === 0
        ) {
          const parent = getListParent(viewedHtmlElements, htmlElement);
          if (parent) {
            lastViewedLine2[0].parentId = parent.uuid;
          }
          lastViewedLine2[0].lineType = tagToBlockType[htmlElement.nodeName];
          lastViewedLine2[0].indentLevel = indentLevel;
          lastViewedLine2[0].htmlElement = htmlElement;
          if (htmlElement.nodeName === "DONECHECKBOX") {
            lastViewedLine2[0].checkboxStatus = "Done";
          }
        } else {
          if (!skip) {
            const parent = getListParent(viewedHtmlElements, htmlElement);
            newLine = {
              uuid: uuidv4(),
              lineType: tagToBlockType[htmlElement.nodeName],
              lineValues: [],
              htmlLineNodes: [],
              indentLevel: indentLevel,
              children: [],
              parentId: parent ? parent.uuid : "",
              htmlElement: htmlElement,
              firstLine: false,
            };
            if (htmlElement.nodeName === "DONECHECKBOX") {
              newLine.checkboxStatus = "Done";
            }
            lastViewedLine.pop();
            lastViewedLine2.push(newLine);
            lines.push(newLine);
            linesBuffer.push(newLine);
            viewedHtmlElements.push(...lastViewedLine2);
          }
        }
      }
    } else if (htmlTagToLineValueTag[htmlElement.nodeName] !== undefined) {
      if (htmlTagToLineValueTag[htmlElement.nodeName] === "A") {
        //Check if should create new Line
        if (
          [LineType.heading1, LineType.heading2, LineType.heading3].includes(
            lastViewedLine[0].lineType
          )
        ) {
          const listParent = getListParent(viewedHtmlElements, htmlElement);

          const newLine: any = {
            uuid: uuidv4(),
            lineType: LineType.text,
            lineValues: [],
            htmlLineNodes: [],
            indentLevel: indentLevel,
            children: [],
            parentId: listParent ? listParent.uuid : "",
            // htmlElement: htmlElement,
            firstLine: false,
          };
          lastViewedLine.pop();
          lastViewedLine2.push(newLine);
          lines.push(newLine);
          linesBuffer.push(newLine);
          viewedHtmlElements.push(...lastViewedLine2);
          const newLineValue: LineValueTest = createNewLineValue(htmlElement);
          viewedHtmlElements.push(newLineValue);
          const parent = newLine;
          if (parent.indentLevel !== undefined) {
            parent.lineValues.push(newLineValue);
          } else {
            parent.children.push(newLineValue);
          }
        } else {
          const parent = getParent(viewedHtmlElements, htmlElement);
          const newLineValue: LineValueTest = createNewLineValue(htmlElement);
          viewedHtmlElements.push(newLineValue);

          if (parent) {
            if (parent.indentLevel !== undefined) {
              parent.lineValues.push(newLineValue);
            } else {
              parent.children.push(newLineValue);
            }
          } else {
            lastViewedLine2[0].lineValues.push(newLineValue);
          }
        }
      }
      //TODO get font-style - if italic create new em
      //TODO get text decoration: line-through per underline
      else if (htmlTagToLineValueTag[htmlElement.nodeName] === "B") {
        if (htmlElement instanceof HTMLElement) {
          const fontWeight = htmlElement.style.fontWeight;

          if (!fontWeight || fontWeight !== "normal") {
            const parent = getParent(viewedHtmlElements, htmlElement);
            const newLineValue: LineValueTest = createNewLineValue(htmlElement);
            viewedHtmlElements.push(newLineValue);

            if (parent) {
              if (parent.indentLevel !== undefined) {
                parent.lineValues.push(newLineValue);
              } else {
                parent.children.push(newLineValue);
              }
            } else {
              lastViewedLine2[0].lineValues.push(newLineValue);
            }
          }
        }
      } else {
        const parent = getParent(viewedHtmlElements, htmlElement);
        const newLineValue: LineValueTest = createNewLineValue(htmlElement);
        viewedHtmlElements.push(newLineValue);

        if (parent) {
          if (parent.indentLevel !== undefined) {
            parent.lineValues.push(newLineValue);
          } else {
            parent.children.push(newLineValue);
          }
        } else {
          lastViewedLine2[0].lineValues.push(newLineValue);
        }
      }
    }
    viewedHtmlElements.push(...lastViewedLine2);
    var children = htmlElement.childNodes;
    for (let i = 0; i < children.length; i++) {
      let element = children[i];
      if (htmlTagToLineValueTag[element.nodeName] === "SPAN") {
        element = handleSpanStyles(children[i], document);
      }
      const newLines = documentIterator2(
        element,
        [],
        viewedHtmlElements,
        lastViewedLine2,
        indentLevel,
        squashable[htmlElement.nodeName] !== undefined &&
          squashable[children[0].nodeName] !== undefined
      );
      if (newLines) {
        lines.push(...newLines);
        // linesBuffer.push(...newLines);
      }
    }
    if (htmlElement.nodeName === "UL" || htmlElement.nodeName === "OL") {
      indentLevel = indentLevel - 1;
      lastViewedLine2[0].indentLevel = lastViewedLine2[0].indentLevel - 1;
      if (lastViewedLine2[0].indentLevel === 0) {
        lastViewedLine2[0].parentId = "";
      }
    } else if (containsList(htmlElement)) {
    } else if (
      htmlElement.nextSibling?.nodeName === "UL" ||
      htmlElement.nextSibling?.nodeName === "OL"
    ) {
    } else if (tagToBlockTypeNewLine[htmlElement.nodeName] !== undefined) {
      if (!skip) {
        const parent = getListParent(viewedHtmlElements, htmlElement);
        newLine = {
          uuid: uuidv4(),
          lineType: LineType.text,
          lineValues: [],
          htmlLineNodes: [],
          indentLevel: indentLevel,
          children: [],
          parentId: parent ? parent.uuid : "",
          firstLine: false,
        };
        lastViewedLine2.pop();
        lastViewedLine2.push(newLine);
        lines.push(newLine);
        linesBuffer.push(newLine);
        viewedHtmlElements.push(...lastViewedLine2);
      }
    }
  } else if (htmlElement.nodeType === 3) {
    const parent = getParent(viewedHtmlElements, htmlElement);
    if (parent) {
      if (parent.name === "#text") {
        const resolvedParentValue = parent.value !== null ? parent.value : "";
        parent.value =
          htmlElement.textContent !== null
            ? resolvedParentValue + htmlElement.textContent
            : "";
      } else {
        const newTextLineValue = new LineValueTest({
          type: "#text",
          name: "#text",
          value: htmlElement.textContent,
          lineValues: [],
          htmlElement: htmlElement,
          children: [],
        });
        if (parent.indentLevel !== undefined) {
          parent.lineValues.push(newTextLineValue);
        } else {
          parent.children.push(newTextLineValue);
        }
        viewedHtmlElements.push(newTextLineValue);
      }
    } else {
      const newTextLineValue = new LineValueTest({
        type: "#text",
        name: "#text",
        value: htmlElement.textContent,
        lineValues: [],
        htmlElement: htmlElement,
        children: [],
      });
      lastViewedLine2[0].lineValues.push(newTextLineValue);
      viewedHtmlElements.push(newTextLineValue);
    }
  }

  return lines;
};

const containsList = (htmlElement: any) => {
  let bool = false;
  for (const childNode of htmlElement.childNodes) {
    if (childNode.nodeName === "UL" || childNode.nodeName === "OL") bool = true;
  }
  return bool;
};

const handleSpanStyles = (htmlElement: any, document: any) => {
  const fontWeight = htmlElement.style.fontWeight;
  const fontStyle = htmlElement.style.fontStyle;
  const textDecoration = htmlElement.style.textDecoration;
  const brSpan = htmlElement.classList.contains("c-mrkdwn__br");
  if (
    fontWeight > 400 ||
    fontStyle === "italic" ||
    textDecoration === "line-through" ||
    brSpan
  ) {
    let newNode;
    let nodeToAddChildren;

    if (fontWeight > 400) {
      newNode = document.createElement("b");
      nodeToAddChildren = newNode;
    }

    if (fontStyle === "italic") {
      if (newNode) {
        const newINode = document.createElement("i");
        newNode.appendChild(newINode);
        nodeToAddChildren = newINode;
      } else {
        newNode = document.createElement("i");
        nodeToAddChildren = newNode;
      }
    }

    if (textDecoration === "line-through") {
      if (newNode) {
        const newINode = document.createElement("strike");
        newNode.appendChild(newINode);
        nodeToAddChildren = newINode;
      } else {
        newNode = document.createElement("strike");
        nodeToAddChildren = newNode;
      }
    }

    if (brSpan) {
      if (newNode) {
        const newINode = document.createElement("br");
        newNode.appendChild(newINode);
        nodeToAddChildren = newINode;
      } else {
        newNode = document.createElement("br");
        nodeToAddChildren = newNode;
      }
    }

    while (htmlElement.childNodes.length) {
      if (nodeToAddChildren) {
        nodeToAddChildren.appendChild(htmlElement.firstChild);
      }
    }

    const parent = htmlElement.parentElement;
    parent.replaceChild(newNode, htmlElement);
    return newNode;
  } else {
    return htmlElement;
  }
};

const createNewLineValue = (htmlElement: any, exactType?: string) => {
  return new LineValueTest({
    type: exactType ?? htmlTagToLineValueTag[htmlElement.nodeName],
    name: exactType ?? htmlTagToLineValueTag[htmlElement.nodeName],
    value: htmlElement.nodeValue,
    lineValues: [],
    link: htmlElement.nodeName === "A" ? htmlElement.getAttribute("href") : "",
    htmlElement: htmlElement,
    children: [],
  });
};

const getParent = (
  lineValuesStack: any[],
  currentElementBecomingALine: HTMLElement | ChildNode
): any => {
  return lineValuesStack
    .filter((value) => value.htmlElement)
    .filter((value: any) =>
      value.htmlElement.isSameNode(currentElementBecomingALine.parentElement)
    )
    .shift();
};

const getListParent = (
  lineValuesStack: any[],
  currentElementBecomingALine: HTMLElement | ChildNode
): any => {
  return lineValuesStack
    .filter((value) => value.listHtmlElement)
    .filter((value: any) =>
      value.listHtmlElement.isSameNode(
        currentElementBecomingALine.parentElement
      )
    )
    .shift();
};

type TlineToBlockName = {
  [key: string]: LineType;
};

const tagToBlockType: TlineToBlockName = {
  H1: LineType.heading1,
  H2: LineType.heading2,
  H3: LineType.heading3,
  UL: LineType.bulletedList,
  OL: LineType.numberedList,
  LI: LineType.bulletedList,
  P: LineType.text,
  BR: LineType.text,
  IMG: LineType.image,
  CHECKBOX: LineType.checkbox,
  DONECHECKBOX: LineType.checkbox,
  WORKBLOCK: LineType.text,
};

const tagToBlockTypeNewLine: TlineToBlockName = {
  H1: LineType.heading1,
  H2: LineType.heading2,
  H3: LineType.heading3,
  UL: LineType.bulletedList,
  OL: LineType.numberedList,
  P: LineType.text,
  BR: LineType.text,
  IMG: LineType.image,
  LI: LineType.text,
  CHECKBOX: LineType.checkbox,
  DONECHECKBOX: LineType.checkbox,
  WORKBLOCK: LineType.text,
};

const squashable: TlineToBlockName = {
  P: LineType.text,
  LI: LineType.text,
};

const htmlTagToLineValueTag: any = {
  STRONG: "B",
  B: "B",
  EM: "I",
  I: "I",
  TEXT: "#text",
  STRIKE: "STRIKE",
  S: "STRIKE",
  DEL: "STRIKE",
  A: "A",
  SPAN: "SPAN",
  H4: "B",
  H5: "B",
  H6: "B",
  CODE: "CODE",
  MONO: "CODE",
  MENTION: "MENTION",
  HIGHLIGHT: "HIGHLIGHT",
};

const notAllowedHtmlTags = [
  "META",
  "HEAD",
  "#comment",
  "link",
  "style",
  "STYLE",
];

const testNodes = [
  "H1",
  "H2",
  "H3",
  "BR",
  "P",
  "CHECKBOX",
  "WORKBLOCK",
  "DONECHECKBOX",
];
