import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IUserObj } from "utilities/types";
import * as actionTypes from "store/actions";
import SignUpLoginSwitcher from "components/SignUpLoginSwitcher";
import DefaultPageHoc from "./templates/DefaultPageHoc";
import BaseAvatar from "components/BaseAvatar";
import styles from "./templates/defaultPageHoc/defaultPageHoc.module.scss";
import Button, { ButtonTypes } from "components/Button";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import { axiosInstance } from "index";

interface Props {
  user: IUserObj;
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

const InvitationRedeem: React.FC<Props> = ({ user, setAuthenticatedUser }) => {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  // const location = useLocation();
  const query = useQuery();
  const [invitationRecord, setInvitationRecord] = useState<any>();
  const inviteToken = query.get("invt") || "";
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (inviteToken) {
      axiosInstance
        .get(`/api/invitation/${inviteToken}`)
        .then(({ data }) => {
          if (data.invitationRecord?.redeemed) {
            setIsError(true);
            setErrorMessage("Sorry, this invitation has been already redeemed");
          }
          if (data.isExpired) {
            setIsError(true);
            setErrorMessage("Sorry, this invitation has expired");
          } else {
            setInvitationRecord(data.invitationRecord);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 404) {
            setIsError(true);
            setErrorMessage(
              "Sorry, this invitation is invalid, or it was revoked by the Base Owner. Please request another invitation from the owner of the Base you are trying to join."
            );
          }
        });
    } else {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptClick = async () => {
    try {
      const { data } = await axiosInstance.post("/api/invitation/redeem", {
        inviteToken: inviteToken,
      });

      if (data.status === 1) {
        const { workspace } = data.payload.roleAssignment;
        const {
          data: { payload: user },
        } = await axiosInstance.get("/api/user");
        setAuthenticatedUser(user);
        history.push(`/${workspace.slug}`);
      } else {
        setIsError(true);
        setErrorMessage(data.info);
        if (data.info?.includes("You are already a member of this Base")) {
          history.push(`/`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!invitationRecord) return <></>;

  return (
    <DefaultPageHoc cardBackground cardOutline showLogo>
      <div>
        <BaseAvatar
          base={invitationRecord.workspace}
          className={styles.baseAvatar}
          aviBorderStyles={{ borderRadius: "20px", display: "none" }}
        />
      </div>
      <h1 className={styles.h1}>Join {invitationRecord.workspace.name}</h1>
      <p
        className={`${styles.description} ${styles.alignCenter} caption regular secondary`}
      >
        {invitationRecord.workspace.tagline}
      </p>
      <div className={styles.divider}></div>
      {!user || !user.id ? (
        <>
          <SignUpLoginSwitcher
            onAcceptInvitation={false}
            inviteToken={inviteToken}
            invitationRecord={invitationRecord}
          />
          <div
            className="caption regular disabled"
            style={{ textAlign: "center" }}
          >
            You’ve been invited to join the {invitationRecord.workspace.name}{" "}
            base. You must be logged in to accept the invitation. You can log
            into an existing account, or create a new one using the buttons
            above.
          </div>
        </>
      ) : (
        <>
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
          {!errorMessage && (
            <Button
              buttonType={ButtonTypes.LARGE_PRIMARY}
              disabled={isError}
              onClick={handleAcceptClick}
            >
              Accept Invitation
            </Button>
          )}
          <p
            className="caption regular disabled"
            style={{
              margin: 0,
              padding: 0,
              textAlign: "center",
              marginTop: "72px",
            }}
          >
            You are logged in as{" "}
            <b>{usernameSplitter(user.name || "@" + user.username)}</b>.
          </p>
        </>
      )}
    </DefaultPageHoc>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAuthenticatedUser: (user: IUserObj) =>
    dispatch({ type: actionTypes.SET_AUTHENTICATED_USER, user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationRedeem);
