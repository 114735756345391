import store from "store/storeExporter";
import { ContainerTypes } from "utilities/types";
import * as actionTypes from "store/actions";
import { IPageDict } from "store/reducers/pagesReducer";
import { INotesDict } from "store/reducers/notesReducer";
import { WorkEntry } from "store/reducers/workReducer";
import {
  getContainerByType,
  getLinkFromContainer,
  getNameFromContainer,
} from "./containerHelpers";
import { getCorrectLink } from "utilities/linkUtilities";
import { axiosInstance } from "index";
import { TemplateDict } from "store/reducers/templateReducer";
import { SnippetDict } from "store/reducers/snippetReducer";

export const getLabelBasedOnContainer = (
  containerType: ContainerTypes,
  containerId: string
) => {
  const clarityStore = store.getState();
  const container = getContainerByType(
    {
      containerId,
      containerType,
    },
    clarityStore
  );

  const label = getNameFromContainer(container, containerType);
  const link = getLinkFromContainer(container, containerType);
  return {
    label,
    link: link,
    entity: container,
  };
};

export const getUser = (userId: string) => {
  const userDict = store.getState().members.dict;

  const user = userDict[userId];
  if (user) {
    if (user.name) return user.name;
    if (user.username) return "@" + user.username;
  }
  return null;
};

export const getContainerLink = (
  containerId: string,
  containerType: ContainerTypes
): string | null => {
  const state = store.getState();
  const base = state.workspace;
  if (containerType === ContainerTypes.DOCUMENT)
    return `/${base.slug}/tags/${containerId}`;
  if (containerType === ContainerTypes.NOTE)
    return `/${base.slug}/docs/${containerId}`;
  if (
    containerType === ContainerTypes.PROJECT ||
    containerType === ContainerTypes.TASK
  ) {
    const entity = state.work.dict[containerId];
    if (entity && entity.id) {
      return getCorrectLink(base, entity);
    }
  }
  return null;
};

export const getContainerThroughType = (
  containerId: string,
  containerType: ContainerTypes,
  entityStores?: {
    pagesDict: IPageDict;
    notesDict: INotesDict;
    weeklyNotesDict: INotesDict;
    workDict: WorkEntry;
    templatesDict: TemplateDict;
    snippetsDict: SnippetDict;
  }
) => {
  if (!entityStores) {
    const storeDate = store.getState();
    entityStores = {
      pagesDict: storeDate.pages.dict,
      notesDict: storeDate.notes.dict,
      weeklyNotesDict: storeDate.notes.weeklyNotesDict,
      workDict: storeDate.work.dict,
      snippetsDict: storeDate.snippets.dict,
      templatesDict: storeDate.templates.dict,
    };
  }

  const { pagesDict, notesDict, weeklyNotesDict, workDict } = entityStores;

  switch (containerType) {
    case ContainerTypes.DOCUMENT: {
      const page = pagesDict[containerId];
      return page;
    }
    case ContainerTypes.NOTE: {
      let note = notesDict[containerId];
      if (note) return note;
      note = weeklyNotesDict[containerId];
      if (note) return note;
      return null;
    }
    case ContainerTypes.PROJECT: {
      let work = workDict ? workDict[containerId] : null;
      if (work) return work;
      return null;
    }
    case ContainerTypes.TASK: {
      let work = workDict ? workDict[containerId] : null;
      if (work) return work;
      return null;
    }
    case ContainerTypes.TEMPLATE: {
      const storeDate = store.getState();
      let templateObj = storeDate.templates.dict
        ? storeDate.templates.dict[containerId]
        : null;
      if (templateObj) return templateObj;
      return null;
    }
    case ContainerTypes.SNIPPET: {
      const storeDate = store.getState();
      let snippetsObj = storeDate.snippets.dict
        ? storeDate.snippets.dict[containerId]
        : null;
      if (snippetsObj) return snippetsObj;
      return null;
    }
    default: {
      return null;
    }
  }
};

export const persistContainerOutline = (persistData: {
  containerType: ContainerTypes;
  containerId: string;
  newOutlineMode: string;
}) => {
  let route;
  if (persistData.containerType === ContainerTypes.DOCUMENT) {
    route = "document";
  }
  if (persistData.containerType === ContainerTypes.PROJECT) {
    route = "project";
  }

  if (persistData.containerType === ContainerTypes.NOTE) {
    route = "note";
  }
  if (route) {
    axiosInstance.patch(`/api/${route}/changeOutlineMode`, {
      id: persistData.containerId,
      outlineMode: persistData.newOutlineMode,
    });
  }
};

export const updateOutlineModeInState = (persistData: {
  containerType: ContainerTypes;
  containerId: string;
  newOutlineMode: string;
  container: any;
}) => {
  if (persistData.containerType === ContainerTypes.DOCUMENT) {
    store.dispatch({
      type: actionTypes.PATCH_DOCUMENT,
      patchContainer: {
        container: {
          containerId: persistData.containerId,
          containerType: ContainerTypes.DOCUMENT,
        },
        patch: {
          outlineMode: persistData.newOutlineMode,
        },
      },
    });
  }
  if (persistData.containerType === ContainerTypes.PROJECT) {
    const updatedProject = { ...persistData.container };
    updatedProject.outlineMode = persistData.newOutlineMode;
    store.dispatch({
      type: actionTypes.UPDATE_WORK_ITEM,
      workItem: updatedProject,
    });
  }
  if (persistData.containerType === ContainerTypes.NOTE) {
    store.dispatch({
      type: actionTypes.PATCH_NOTE,
      patchContainer: {
        container: {
          containerId: persistData.containerId,
          containerType: ContainerTypes.NOTE,
        },
        isWeekly:
          store.getState().notes.weeklyNotesDict?.[persistData.containerId],
        patch: {
          outlineMode: persistData.newOutlineMode,
        },
      },
    });
  }
};
