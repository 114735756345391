import React, { memo, useState, useLayoutEffect, useRef } from "react";
import { useStore } from "react-redux";
import PopupCard from "./PopupCard";
import { ContainerTypes } from "../utilities/types";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import { getNameFromContainer } from "modules/containerHelpers";
import { getHtml } from "editor/utils/blockValueHelpers";
import { getCorrectLink } from "utilities/linkUtilities";
import { axiosInstance } from "index";

const CitationPopup: React.FC<any> = (props: any) => {
  const state = useStore();
  const stateData = state.getState();

  const [citationContainerData, setcitationContainerData] = useState<any>(null);
  const blockDataRef = useRef<any>(null);
  const citationData = useRef<any>(null);

  useLayoutEffect(() => {
    let blockData;
    if (props.blockId) {
      const newState = state.getState().blocks;
      blockData = getBlockById(newState.dict, props.blockId);
    }

    blockDataRef.current = blockData;

    const citationData1 = stateData.citations.dict[props.citationId];
    if (citationData1) citationData.current = citationData1;
  }, [props.citationId]);

  useLayoutEffect(() => {
    if (citationData.current) {
      if (citationData.current.containerType === ContainerTypes.DOCUMENT) {
        axiosInstance
          .get(`/api/document/getDocumentAndFirstBlock`, {
            params: {
              id: citationData.current.containerId,
            },
          })
          .then((res) => {
            setcitationContainerData(res.data);
          });
      }
      if (citationData.current.containerType === ContainerTypes.PROJECT) {
        axiosInstance
          .get(`/api/project/getProjectAndFirstBlock`, {
            params: {
              id: citationData.current.containerId,
            },
          })
          .then((res) => {
            setcitationContainerData(res.data);
          });
      }
      if (citationData.current.containerType === ContainerTypes.NOTE) {
        axiosInstance
          .get(`/api/note/getNoteAndFirstBlock`, {
            params: {
              id: citationData.current.containerId,
            },
          })
          .then((res) => {
            setcitationContainerData(res.data);
          });
      }
    } else {
      axiosInstance
        .get(`/api/citation/citationConnectedData`, {
          params: {
            citationId: props.citationId,
          },
        })
        .then((res) => {
          if (res.data.refBlock) blockDataRef.current = res.data.refBlock;
          if (res.data.citationData)
            citationData.current = res.data.citationData;
          setcitationContainerData(res.data);
        });
    }

    return () => {
      setcitationContainerData(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.citationId]);

  if (!citationContainerData || !blockDataRef.current)
    return <PopupCard loading={true}></PopupCard>;
  return (
    <div>
      <PopupCard
        blockData={blockDataRef.current}
        citationData={citationData.current}
        cardData={{
          title: getTitle(citationData.current, citationContainerData),
          firstBlock: getHtml(
            citationContainerData?.block
              ? citationContainerData?.block.value
              : []
          ),
          link: getLink(citationData.current, citationContainerData),
          citationData: citationData.current,
        }}
        destination={props.destination}
      ></PopupCard>
    </div>
  );
};

const getTitle = (citationData: any, citationContainerData: any) => {
  if (!citationData) return "";
  if (citationData.containerType === ContainerTypes.DOCUMENT)
    return getHtml(citationContainerData?.container.nameValue);
  if (
    citationData.containerType === ContainerTypes.PROJECT ||
    citationData.containerType === ContainerTypes.WORK_ACTIVITY
  )
    return `#${citationContainerData?.container.projectId} - ${getHtml(
      citationContainerData?.container.nameValue
    )}`;

  if (citationData.containerType === ContainerTypes.NOTE) {
    return getNameFromContainer(
      citationContainerData?.container,
      ContainerTypes.NOTE
    );
  }
  return citationContainerData?.container.name;
};

const getLink = (citationData: any, citationContainerData: any) => {
  if (
    citationData.containerType === ContainerTypes.PROJECT ||
    citationData.containerType === ContainerTypes.WORK_ACTIVITY
  )
    return getCorrectLink(
      citationContainerData?.container.workspace,
      citationContainerData?.container
    );
  if (citationData.containerType === ContainerTypes.NOTE)
    return `/${citationContainerData?.container.workspace.slug}/docs/${citationContainerData?.container.id}`;
  return `/${citationContainerData?.container.workspace.slug}/tags/${citationContainerData?.container.id}`;
};

const equalComp = (prevProps: any, nextProps: any) => {
  return prevProps.citationId === nextProps.citationId;
};

export default memo(CitationPopup, equalComp);
