import { Dropdown, Skeleton } from "antd";
import { baseApi } from "clientApi/baseApi";
import { userApi } from "clientApi/userApi";
import Button, { ButtonTypes, IconSides } from "components/Button";
import { Menu, MenuItem } from "components/FabBtn";
import ChevronDown from "icons/Components/ChevronDown";
import React, { Suspense, useState } from "react";
import { SET_SHOW_LEAVE_BASE_MODAL } from "store/actions";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { BaseType, HomeScreenObj, UserRole } from "utilities/types";
import { LogoutOutlined } from "@ant-design/icons";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
import BaseMembers from "../BaseMembers";
import ProfileMoreMenu from "../../profileEditor/ProfileMoreMenu";
import ReactDOM from "react-dom";
import { useIsMobile } from "utilities/hooks";
import {
  useAddOrEditHomeSection,
  useDeleteHomeSection,
  useReorderHomeSection,
} from "store/reducers/workspaceReducer";
import Modal from "clarity-ui/Modal";
import ProfileEdit from "../../profileEditor/ProfileEdit";
import UnlockTwoTone from "icons/Components/UnlockTwoTone";
import LockTwoTone from "icons/Components/LockTwoTone";

const CollectionsEditor = React.lazy(
  () => import("../../pinsBoard/CollectionsEditor")
);

const BaseDetails: React.FC = () => {
  const [editProfile, seteditProfile] = useState(false);
  const [showMoreMenu, setshowMoreMenu] = useState(false);
  const [editCollections, seteditCollections] = useState(false);
  const base = useShallowSelector((store) => store.workspace);
  const isMobile = useIsMobile();

  return (
    <div
      className={
        isMobile
          ? ` baseOverViewRight baseOverViewRightCol`
          : "baseOverViewRight"
      }
    >
      <div style={{ display: "flex", gap: "8px" }}>
        <MembershipStatus />
        <Dropdown
          overlay={
            <ProfileMoreMenu
              hide={() => setshowMoreMenu(false)}
              seteditCollections={seteditCollections}
              seteditProfile={seteditProfile}
            />
          }
          visible={showMoreMenu}
        >
          <Button
            style={{ height: 28 }}
            buttonType={ButtonTypes.MEDIUM_PLAIN}
            onClick={() => setshowMoreMenu(true)}
            icon={<MoreHorizontalDots />}
          />
        </Dropdown>
      </div>
      <MembersAndType />
      <ProfileEditorListener
        editProfile={editProfile}
        seteditProfile={seteditProfile}
      />
      <CollectionEditorListener
        collections={base.home}
        editCollections={editCollections}
        seteditCollections={seteditCollections}
      />
    </div>
  );
};

const MembersAndType: React.FC = () => {
  const baseType = useShallowSelector((store) => store.workspace.type);

  return (
    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <BasePolicy type={baseType} />
      <span>•</span>
      <BaseMembers />
    </div>
  );
};

const MembershipStatus: React.FC = () => {
  const base = useShallowSelector((store) => store.workspace);
  const userId = useShallowSelector((store) => store.user?.id);
  const hasJoined = base.roleIds && base.roleIds?.length > 0;
  const [showDropdown, setshowDropdown] = useState(false);
  const [pressed, setpressed] = useState(false);

  const joinBase = () => {
    if (pressed) return;
    setpressed(true);
    baseApi.joinBase(base.id).then(() => {
      userApi.resetUserWithBaseSelected();
      setpressed(false);
    });
  };

  if (hasJoined) {
    return (
      <Dropdown
        overlay={
          <MembershipChangeDropdown hide={() => setshowDropdown(false)} />
        }
        visible={showDropdown}
      >
        <Button
          style={{ height: 28 }}
          onClick={() => setshowDropdown(true)}
          buttonType={
            hasJoined ? ButtonTypes.MEDIUM_PLAIN : ButtonTypes.MEDIUM_PRIMARY
          }
          icon={<ChevronDown />}
          iconSide={IconSides.RIGHT}
        >
          {hasJoined ? "Joined" : "Not Joined"}
        </Button>
      </Dropdown>
    );
  } else {
    return (
      <Button
        disabled={!userId}
        onClick={joinBase}
        buttonType={ButtonTypes.PRIMARY}
      >
        Join base
      </Button>
    );
  }
};

const MembershipChangeDropdown: React.FC<{ hide: () => void }> = ({ hide }) => {
  const base = useShallowSelector((store) => store.workspace);
  const leaveBase = () => {
    if (!base.id) return;
    if (base.roleType === UserRole.OWNER) return;

    store.dispatch({
      type: SET_SHOW_LEAVE_BASE_MODAL,
      param: {
        showLeaveBaseConfirmation: true,
        leaveBaseId: base.id,
      },
    });
  };

  return (
    <Menu slim={true} close={hide} yDir="down" xDir="left">
      <MenuItem
        title="Leave base"
        disabled={base.roleType === UserRole.OWNER}
        icon={<LogoutOutlined />}
        onClick={() => {
          leaveBase();
          hide();
        }}
      />
    </Menu>
  );
};

const ProfileEditorListener: React.FC<{
  editProfile: boolean;
  seteditProfile: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ editProfile, seteditProfile }) => {
  const closeAction = () => seteditProfile(false);
  return (
    <>
      {editProfile &&
        ReactDOM.createPortal(
          <Modal hideModal={closeAction}>
            <ProfileEdit mode="base" closeAction={closeAction} />
          </Modal>,
          document.body
        )}
    </>
  );
};

const CollectionEditorListener: React.FC<{
  editCollections: boolean;
  seteditCollections: React.Dispatch<React.SetStateAction<boolean>>;
  collections: HomeScreenObj;
}> = ({ editCollections, seteditCollections, collections }) => {
  const addOrEditHomeCollection = useAddOrEditHomeSection();
  const reorderCollection = useReorderHomeSection();
  const deleteCollection = useDeleteHomeSection();
  return (
    <>
      {editCollections && (
        <>
          {ReactDOM.createPortal(
            <Modal hideModal={() => seteditCollections(false)}>
              <Suspense fallback={<Skeleton avatar={false} />}>
                <CollectionsEditor
                  collections={collections}
                  updateOrAddCollection={addOrEditHomeCollection}
                  reorderCollection={reorderCollection}
                  deleteCollection={deleteCollection}
                />
              </Suspense>
            </Modal>,
            document.body
          )}
        </>
      )}
    </>
  );
};

const BasePolicy: React.FC<{ type: BaseType }> = ({ type }) => {
  const getName = () => {
    if (type === BaseType.Private) return "Private";
    if (type === BaseType.Public) return "Public";
    return "Secret";
  };

  return (
    <span className="caption medium secondary">
      {type === BaseType.Public ? <UnlockTwoTone /> : <LockTwoTone />}{" "}
      {getName()} base
    </span>
  );
};

export default BaseDetails;
