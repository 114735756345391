import moment from "moment";
import { LOAD_INVITATION_LINKS, PATCH_INVITATION_LINK } from "store/actions";
import store from "store/storeExporter";
import { IInviteLink } from "utilities/types";

class InvitationsApi {
  useArray = [0, 1, 5, 10, 25, 50, 100];
  getTime = (id: string) => {
    const date = new Date();
    switch (id) {
      case "1": {
        const secondDate = moment(date).add(30, "minutes");
        return secondDate.toDate();
      }
      case "2": {
        const secondDate = moment(date).add(1, "hour");
        return secondDate.toDate();
      }
      case "3": {
        const secondDate = moment(date).add(6, "hours");
        return secondDate.toDate();
      }
      case "4": {
        const secondDate = moment(date).add(12, "hours");
        return secondDate.toDate();
      }
      case "5": {
        const secondDate = moment(date).add(1, "day");
        return secondDate.toDate();
      }
      case "6": {
        const secondDate = moment(date).add(7, "days");
        return secondDate.toDate();
      }
    }
  };

  getInvitationLinks(baseId: string) {
    return store.dispatch({
      type: LOAD_INVITATION_LINKS,
      invitationLinks: [],
    });
  }

  setBaseInvitationLinks(invitationLinks: IInviteLink[]) {
    return store.dispatch({
      type: LOAD_INVITATION_LINKS,
      invitationLinks,
    });
  }

  updateInvitationLink(param: {
    delta: Partial<IInviteLink>;
    type: "add" | "delete" | "update";
    id: string;
  }): {
    type: string;
    param: {
      delta: Partial<IInviteLink>;
      type: "add" | "delete" | "update";
      id: string;
    };
  } {
    return store.dispatch({
      type: PATCH_INVITATION_LINK,
      param,
    });
  }
}

export const invitationsApi = new InvitationsApi();
