import React, { useEffect } from "react";
import styles from "./sidebarSearch/sidebarSearch.module.scss";
import {
  useFullSearchActivator,
  useSearch,
  useSearchSetter,
} from "store/reducers/clientReducer";
import SearchResults from "components/searchResults/SearchResults";
import { SearchBar } from "components/TopNavBar";
import {
  SearchOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  ActiveMenuItemContext,
  useActiveMenuItemContextValue,
  useActiveMenuItemContext,
} from "components/FabBtn";
import { useHistory } from "react-router-dom";
import { useBase } from "store/reducers/workspaceReducer";
import { getCorrectLink } from "utilities/linkUtilities";
import { axiosInstance } from "index";
import { ViewNames } from "utilities/types";
import navigationApi from "clientApi/navigationApi";
import { batch } from "react-redux";
import store from "store/storeExporter";
import {
  SET_NOTE_IN_DICT,
  SET_PAGES_IN_DICT,
  SET_WORK_IN_DICT,
} from "store/actions";

export interface ISidebarSearchProps {}

export default function SidebarSearch() {
  const { activeItemContext } = useSidebarSearchState();
  const activateFullSearch = useFullSearchActivator();

  useEffect(() => {
    activateFullSearch();
  }, []);

  return (
    <div className={styles.container}>
      <ActiveMenuItemContext.Provider value={activeItemContext}>
        <InputPane />
        <ResultsPane />
      </ActiveMenuItemContext.Provider>
    </div>
  );
}

function useSidebarSearchState() {
  const activeItemContext = useActiveMenuItemContextValue();

  return {
    activeItemContext,
  };
}

interface IInputPaneProps {}
function InputPane(props: IInputPaneProps) {
  return (
    <div className={styles.inputPane}>
      <InputPaneSearchBar />
    </div>
  );
}

export function InputPaneSearchBar() {
  const { cancel, onEnter, onEmptyEnter, isSearching, queryString } =
    useInputPaneSearchBarActions();

  return (
    <SearchBar
      bootstrapValue
      isFullSearch
      prefix={<SearchOutlined className={styles.inputPaneSearchBarPrefix} />}
      suffix={
        isSearching ? (
          <LoadingOutlined />
        ) : queryString ? (
          <CloseCircleOutlined
            className={styles.inputPaneSearchBarSuffix}
            onClick={cancel}
          />
        ) : undefined
      }
      onEnter={onEnter}
      onEmptyEnter={onEmptyEnter}
      className={styles.inputPaneSearchBar}
      containerClassName={styles.sidebarSearchContainer}
    />
  );
}

function useInputPaneSearchBarActions() {
  const history = useHistory();
  const { base } = useBase();
  const { activeItemIndex, setItemsCollection, itemsCollection } =
    useActiveMenuItemContext();

  const { resultArray, queryString, isSearching } = useSearch();
  const setSearch = useSearchSetter();
  const cancel = () =>
    setSearch({
      isOpen: false,
      isSearching: false,
      resultArray: [],
      queryString: "",
    });

  const onEmptyEnter: React.KeyboardEventHandler = (e) => {
    const activeItem = itemsCollection[activeItemIndex];
    if (activeItem.onClick) {
      activeItem.onClick(e as any);
    } else {
      cancel();
    }
  };

  useEffect(() => setItemsCollection(resultArray), [resultArray]);

  const onEnter: React.KeyboardEventHandler = (e) => {
    const container = resultArray[activeItemIndex];
    if (container) {
      if (e.shiftKey) {
        navigationApi.openSplitView({
          viewName: ViewNames.Detail,
          containerId: container.id,
          containerType: container.type,
        });
      } else {
        history.push(getCorrectLink(base, container));
      }
    }
  };

  return {
    cancel,
    onEnter,
    isSearching,
    queryString,
    onEmptyEnter,
  };
}

interface IResultsPaneProps {}
function ResultsPane(props: IResultsPaneProps) {
  return (
    <div className={styles.resultsPane}>
      <SearchResults />
    </div>
  );
}

export function useSearchIsOpen() {
  const { isOpen } = useSearch();
  return isOpen;
}

export function useSearcher() {
  const setSearch = useSearchSetter();
  const { base } = useBase();

  return (queryString: string) =>
    performSearch({ baseId: base.id ?? "", queryString, setSearch });
}

function performSearch({
  baseId,
  queryString,
  setSearch,
}: {
  baseId: string;
  queryString: string;
  setSearch: ReturnType<typeof useSearchSetter>;
}) {
  setSearch({
    isOpen: true,
    isSearching: true,
    fullSearchActive: true,
    queryString,
  });

  axiosInstance
    .get("/api/lines/searchResults", {
      params: {
        queryString,
        baseId,
      },
    })
    .then((res) => {
      if (res.data.res) {
        const containers = {
          projects: {} as any,
          pages: {} as any,
          notes: {} as any,
        };
        Object.values(res.data.res).forEach((item: any) => {
          if (item.type === "Task") {
            containers.projects[item.id] = item;
          }
          if (item.type === "Document") {
            containers.pages[item.id] = item;
          }
          if (item.type === "Note") {
            containers.notes[item.id] = item;
          }
        });

        batch(() => {
          store.dispatch({
            type: SET_PAGES_IN_DICT,
            pages: containers.pages,
          });
          store.dispatch({
            type: SET_WORK_IN_DICT,
            workObj: containers.projects,
          });
          store.dispatch({
            type: SET_NOTE_IN_DICT,
            noteObj: containers.notes,
          });
        });
      }
      setSearch({
        isSearching: false,
        resultArray: Object.values(res.data.res),
      });
    });
}
