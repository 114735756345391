import { CLIENT_ONLINE, CLIENT_OFFLINE, ADD_ERROR_MESSAGE } from "../actions";
import { useShallowSelector } from "utilities/hooks";

const initialState = {
  connected: true,
  error: {
    nr: 0,
    message: "",
  },
};

export default function networkReducer(state = initialState, action: any) {
  switch (action.type) {
    case CLIENT_OFFLINE: {
      const newState = { ...state };
      newState.connected = false;
      return newState;
    }

    case CLIENT_ONLINE: {
      const newState = { ...state, connected: true };
      return newState;
    }

    case ADD_ERROR_MESSAGE: {
      const newState = {
        ...state,
        error: {
          nr: state.error.nr + 1,
          message: action.message,
        },
      };
      return newState;
    }

    default:
      return state;
  }
}

export function useConnected() {
  return useShallowSelector((state) => state.network.connected);
}
