import { checkIfCannotAddBlocks } from "modules/appService";
import { createNewBlock } from "modules/entityService";
import { batch } from "react-redux";
import { BehaviorSubject } from "rxjs";
import {
  ADD_BLOCK,
  REFOCUS,
  SET_BLOCK_IN_DICT,
  SET_SINGLE_BLOCK,
  SET_UNDO,
  SIDE_EFFECT_ADD_FIRST_BLOCK,
  UPDATE_CURRENT_MONTHLY_COUNT,
} from "store/actions";
import {
  Block,
  BlockData,
  ContainerContext,
  FocusType,
} from "store/reducers/blockReducer";
import {
  getBlockById,
  getCurrentContext,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $focusOn, prevState } from "store/storeExporter";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { entityKeepTypes, ILineValue, LineType } from "utilities/lineUtilities";
import { IBlockContext } from "utilities/types";
import {
  BlockActionTypes,
  executeTextSaveTimeout,
  getAntiAction,
} from "../blockActions";
import { breakDownHtml } from "../blockValueHelpers";
import { checkCaretPosition } from "../caretUtils";
import {
  primitiveAddSingleBlock,
  BlockCreateProps,
  getAntiDelta,
  handleUpdateAction,
} from "../primitiveActions/primitiveActions";
import { changeBlockOrSelectionType } from "./blockTypesActions";
import { setUpdateObjectToStore } from "./persistActions";
import { handleLocalEntityConnect } from "./textUpdateActions";
import { ActionObject, ActionWrapperObject } from "./undoUtils";

export enum ADD_BLOCK_TYPES {
  addBlockAfter = "addBlockAfter",
  addBlockBefore = "addBlockBefore",
  addChildBlock = "addChildBlock",
}

export const addBlock = (addProps: {
  event?: React.KeyboardEvent<HTMLDivElement>;
  currentBlock: Block;
  context: any;
  newBlockValue: ILineValue[];
  type: ADD_BLOCK_TYPES;
  focus: "newBlock" | "currentBlock";
  presetData?: Partial<Block>;
}) => {
  const newState = store.getState();
  const cannotAddBlock = checkIfCannotAddBlocks();
  if (cannotAddBlock) return;
  const saveActions: ActionObject[] = [];
  const undoObject: ActionWrapperObject = {
    actions: [],
    context: addProps.context,
    focusOn: { ...$focusOn.value },
    selectedBlocks: [...newState.blocks.selectedBlocks],
    documentMode: newState.blocks.documentMode,
  };

  executeTextSaveTimeout();

  const currentBlockRef = {
    ...addProps.currentBlock,
    ...addProps.currentBlock.blockSubject.value,
  };

  if (addProps.event) {
    const currentBlockNewValue: ILineValue[] = [];
    addProps.event.currentTarget.childNodes.forEach((child) => {
      currentBlockNewValue.push(breakDownHtml(child));
    });

    const newDate = new Date();

    const delta = {
      value: currentBlockNewValue,
      modifiedBy: prevState.value.user?.id,
      textModifiedBy: prevState.value.user?.id,
      dateModified: newDate,
      textDateModified: newDate,
    };

    const action: ActionObject = {
      id: currentBlockRef.id,
      delta,
      type: BlockActionTypes.update,
    };

    handleUpdateAction(action);

    saveActions.push(action);

    const revrseAction: ActionObject = {
      type: BlockActionTypes.update,
      id: currentBlockRef.id,
      delta: getAntiDelta(delta, currentBlockRef),
    };

    undoObject.actions.push(revrseAction);
  }

  const presets: BlockCreateProps = {
    baseId: currentBlockRef.baseId,
    value: addProps.newBlockValue,
    containerId: currentBlockRef.containerId,
    containerType: currentBlockRef.containerType,
    parentId: currentBlockRef.parentId,
    indentLevel: currentBlockRef.indentLevel,
    lineType: LineType.text,
    ...(addProps.presetData ? addProps.presetData : {}),
  };

  const getBlockType = () => {
    if (currentBlockRef.lineType === LineType.checkbox) {
      presets.lineType = currentBlockRef.lineType;
      return;
    }

    if (addProps.presetData?.lineType) {
      presets.lineType = addProps.presetData.lineType;
      return;
    }

    if (!entityKeepTypes.includes(currentBlockRef.lineType))
      presets.lineType = currentBlockRef.lineType;
    else presets.lineType = LineType.text;
    return;
  };

  switch (addProps.type) {
    case ADD_BLOCK_TYPES.addBlockBefore:
    case ADD_BLOCK_TYPES.addBlockAfter: {
      presets.parentId = currentBlockRef.parentId;
      presets.indentLevel = currentBlockRef.indentLevel;
      getBlockType();
      break;
    }

    case ADD_BLOCK_TYPES.addChildBlock: {
      presets.parentId = currentBlockRef.id;
      presets.indentLevel = currentBlockRef.indentLevel + 1;
      getBlockType();
      break;
    }
  }

  const newBlock = primitiveAddSingleBlock({
    newBlockValue: addProps.newBlockValue,
    referencePointBlockId: currentBlockRef.id,
    context: addProps.context,
    type: addProps.type,
    presets,
    saveActions,
    undoObject,
  });

  const newFocusObject = {
    ...$focusOn.value,
    ...{
      focusBlockId:
        addProps.focus === "newBlock" ? newBlock.id : currentBlockRef.id,
      type: FocusType.prevPosition,
      caretPosition: 0,
    },
  };

  store.dispatch({
    type: REFOCUS,
    param: {
      newFocus: newFocusObject,
    },
  });

  store.dispatch({
    type: SET_UNDO,
    param: {
      context: addProps.context,
      contextId: addProps.context.id,
      undoObject,
    },
  });

  setUpdateObjectToStore(saveActions, addProps.context);

  return newBlock;
};

export const addNewBlockKeepValue = (
  block: Block,
  context: any,
  presetData?: Partial<Block>
) => {
  const inZoomed =
    context.zoomedBlockId && context.zoomedBlockId === block.id
      ? ADD_BLOCK_TYPES.addChildBlock
      : null;

  if (inZoomed) return;

  const newBlock = addBlock({
    currentBlock: block,
    newBlockValue: [],
    context,
    type: ADD_BLOCK_TYPES.addBlockAfter,
    focus: "newBlock",
    presetData,
  });
  return newBlock;
};

export const createFirstBlock = (context: IBlockContext) => {
  const newState = store.getState();
  const currentContext = getCurrentContext(newState.blocks, context.id);
  const firstBlockId = currentContext.state.rootBlocksIds[0];
  const firstBlock = getBlockById(newState.blocks.dict, firstBlockId);
  addBlock({
    context,
    currentBlock: firstBlock,
    focus: "newBlock",
    newBlockValue: [],
    type: ADD_BLOCK_TYPES.addBlockBefore,
    presetData: {
      lineType: LineType.text,
    },
  });
};

export const singleBlockAddAction = (
  type: ADD_BLOCK_TYPES,
  referencePoint: string,
  block: Block,
  context: any,
  options?: {
    socketUpdate?: boolean;
  }
) => {
  batch(() => {
    store.dispatch({
      type: UPDATE_CURRENT_MONTHLY_COUNT,
      param: {
        delta: 1,
      },
    });
    store.dispatch({
      type: ADD_BLOCK,
      param: {
        type,
        referencePoint,
        block,
        context: context,
      },
    });
    handleLocalEntityConnect(block, {
      immediate: true,
      socketUpdate: options?.socketUpdate,
    });
  });
};

export const checkBlockRecover = (
  block: Block,
  options?: {
    socketUpdate: boolean;
  }
) => {
  const parentId = block.parentId;
  const newState = store.getState();
  let containerArray: string[] = [];

  const currentContext = getCurrentContext(
    newState.blocks,
    block.containerType + block.containerId
  );

  if (!currentContext || !currentContext.state) {
    store.dispatch({
      type: SET_BLOCK_IN_DICT,
      block,
    });
    return;
  }

  if (parentId === "" || !parentId) {
    containerArray = currentContext.state.rootBlocksIds;
  } else {
    const parentBlock = getBlockById(newState.blocks.dict, block.parentId);
    containerArray = parentBlock.children;
  }

  batch(() => {
    if (containerArray) {
      for (const blockId of containerArray) {
        const refBlock = getBlockById(newState.blocks.dict, blockId);
        if (
          refBlock.documentRank &&
          block.documentRank &&
          refBlock.documentRank.localeCompare(block.documentRank, "en") > 0
        ) {
          singleBlockAddAction(
            ADD_BLOCK_TYPES.addBlockBefore,
            refBlock.id,
            block,
            currentContext,
            options
          );
          return refBlock;
        }
      }

      if (containerArray.length === 0 && block.parentId) {
        return singleBlockAddAction(
          ADD_BLOCK_TYPES.addChildBlock,
          block.parentId,
          block,
          currentContext,
          options
        );
      }
      if (containerArray.length > 0) {
        const refPoint = containerArray[containerArray.length - 1];
        return singleBlockAddAction(
          ADD_BLOCK_TYPES.addBlockAfter,
          refPoint,
          block,
          currentContext,
          options
        );
      }
    }

    store.dispatch({
      type: SET_BLOCK_IN_DICT,
      block,
    });
  });
};

export const addBlockAfterAndReopenNewTask = async (
  refBlockId: string,
  context: IBlockContext
) => {
  const block = await addAndWaitForNewBlock(refBlockId, context);
  if (block) {
    changeBlockOrSelectionType(
      block.id,
      { current: null },
      LineType.work,
      context
    );
  }
};

export const addAndWaitForNewBlock = async (
  refBlockId: string,
  context: IBlockContext
) => {
  const newState = store.getState();
  const block = getBlockById(newState.blocks.dict, refBlockId);
  let newBlock;
  const addBlock = () =>
    new Promise<Block | undefined>((resolve, reject) => {
      newBlock = addNewBlockKeepValue(block, context);
      resolve(newBlock);
    });
  return await addBlock();
};

export const insertFirstInContext = (context: ContainerContext) => {
  const newState = store.getState();
  let newBlock = createNewBlock(
    {},
    {
      containerId: context.containerId,
      containerType: context.containerType,
      baseId: newState.workspace.id,
    }
  );

  const fullBlock: Block = {
    ...newBlock,
    blockSubject: new BehaviorSubject<Partial<Block>>({}),
    children: [],
  };
  const action = {
    id: fullBlock.id,
    delta: {
      ...newBlock,
    },
    type: BlockActionTypes.insert,
    options: {
      newBlock: true,
    },
  };
  const antiAction = getAntiAction(action);
  batch(() => {
    store.dispatch({
      type: UPDATE_CURRENT_MONTHLY_COUNT,
      param: {
        delta: 1,
      },
    });
    store.dispatch({
      type: SIDE_EFFECT_ADD_FIRST_BLOCK,
      param: {
        context,
        block: fullBlock,
        action: antiAction,
      },
    });
    const newFocusObject = {
      ...$focusOn.value,
      ...{
        focusBlockId: fullBlock.id,
        type: FocusType.prevPosition,
        caretPosition: 0,
      },
    };
    store.dispatch({
      type: REFOCUS,
      param: {
        newFocus: newFocusObject,
      },
    });
  });

  setUpdateObjectToStore([action], { autosave: true } as IBlockContext);
};

export const handleSoftReturn = (
  event: React.KeyboardEvent<HTMLDivElement>
) => {
  const target = event.currentTarget;
  if (!target) return;
  const caretPosition = checkCaretPosition(target);
  let depth = 0;
  const tryInsertion = () => {
    target.normalize();
    if (caretPosition === target.innerText.length) {
      target.innerHTML = target.innerHTML + "\n\n";
    } else {
      const selection = document.getSelection();
      const range = selection?.getRangeAt(0);
      const text = document.createTextNode("\n");
      range?.insertNode(text);
      range?.setStartAfter(text);
      const newRange = range?.cloneRange();
      selection?.removeAllRanges();
      if (newRange) {
        selection?.addRange(newRange);
      }
    }

    moveCaretToPreviousPosition(target, caretPosition + 1);

    const afterInsertionCaretPosition = checkCaretPosition(target);
    if (afterInsertionCaretPosition === caretPosition && depth < 10) {
      tryInsertion();
    }
  };
  tryInsertion();

  event.stopPropagation();
  event.preventDefault();
};

export const setNewBlock = (blockData: BlockData) => {
  store.dispatch({
    type: SET_SINGLE_BLOCK,
    param: blockData,
  });
};
