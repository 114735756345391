import React from "react";
import { BlockPropsWithRef } from "../BlockSplitter";
import styles from "./styles/blockComponent.module.scss";
import { getClassNameFromBlockType } from "editor/utils/blockActions";

const DividerBlock: React.FC<BlockPropsWithRef> = (props) => {
  return (
    <div
      data-block-id={props.blockData.id}
      data-root={true}
      tabIndex={-1}
      ref={props.blockRef}
      contentEditable={false}
      className={
        "content-section clarity-selectable " +
        getClassNameFromBlockType(props.blockData.lineType) +
        styles.documentBlock
      }
      style={{
        paddingLeft: props.blockData.indentLevel > 0 ? 3 + "px" : 0,
        marginLeft: "-5px",
        marginTop: "-1px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={styles.divderContaier}>
        <div className={styles.divderContaier__dividerBlock}></div>
      </div>
    </div>
  );
};

export default DividerBlock;
