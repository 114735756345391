import React from "react";
import { connect } from "react-redux";
import ModalScrimComponent from "components/ModalScrimComponent";
import { CLOSE_CONFIRMATION_MODAL } from "store/actions";
import { Modal } from "antd";

const { confirm } = Modal;

const showModal = (props: Props) => {
  confirm({
    title: props.confirmationData.title,
    content: (
      <div
        dangerouslySetInnerHTML={{ __html: props.confirmationData.body }}
      ></div>
    ),
    okText: props.confirmationData.confirmMessage,
    okType: "primary",
    cancelText: "Cancel",
    autoFocusButton: "ok",
    keyboard: true,
    getContainer: () => document.body,
    onOk() {
      props.confirmationData.confirmAction();
      props.setModalVisibility({});
    },
    onCancel() {
      if (props.confirmationData.cancelAction)
        props.confirmationData.cancelAction();
      props.setModalVisibility({});
    },
  });
};

interface Props {
  confirmationData: any;
  setModalVisibility: (details: any) => void;
}

const ConfirmationModal: React.FC<Props> = (props) => {
  return (
    <>
      {showModal(props)}
      {/* <Modal
        keyboard={true}
        visible={true}
        title={props.confirmationData.title}
        okText={props.confirmationData.confirmMessage}
        onOk={(e) => {
          props.confirmationData.confirmAction();
          props.setModalVisibility({});
        }}
        onCancel={() => {
          props.setModalVisibility({});
        }}
      >
        <p>{props.confirmationData.message}</p>
      </Modal> */}
      <ModalScrimComponent
        hideModal={() => {
          props.setModalVisibility({});
        }}
        light
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  confirmationData: state.confirmationModal.confirmationModal,
});
const mapDispatchToProps = (dispatch: any) => ({
  setModalVisibility: (details: any) =>
    dispatch({ type: CLOSE_CONFIRMATION_MODAL, details }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
