import navigationApi from "clientApi/navigationApi";
import Conditional from "components/Conditional";
import { locationSubject } from "components/LocationListener";
import { useGetContainerFromType } from "modules/containerHelpers";
import React, { useLayoutEffect, useState } from "react";
import {
  navigateToBase,
  navigateToGroup,
} from "screens/base/NavigationContext";
import { TopNavbarType } from "store/reducers/topNavReducer";
import { useBase } from "store/reducers/workspaceReducer";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import {
  ContainerTypes,
  GeneralViewsNames,
  PayoutsViewsNames,
  WorkTypes,
} from "utilities/types";

const Breadcrumbs: React.FC<{
  viewType?: TopNavbarType;
  paneId: ChunkDestination;
}> = ({ viewType, children, paneId }) => {
  if (viewType === TopNavbarType.baseOverview)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "inherit",
          gap: "6px",
          width: "100%",
        }}
      >
        <BaseCrumbOnly paneId={paneId} />
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "inherit",
        gap: "6px",
        width: "100%",
      }}
    >
      <BaseCrumbNavigator paneId={paneId} />
      <Conditional on={viewType !== TopNavbarType.group}>
        <GroupCrumbNavigatorHoc paneId={paneId} />
      </Conditional>
      <Conditional on={viewType === TopNavbarType.detail}>
        <DetailPageCrumbNavigator paneId={paneId} />
      </Conditional>

      {children && <span className="body white disabled bold"> / </span>}
      {children && (
        <>
          {React.Children.map(children, (child, index) => (
            <>
              <Crumb>{child}</Crumb>
              {index !== React.Children.count(children) - 1 && (
                <span className="body white disabled bold"> / </span>
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
};

const BaseCrumbNavigator: React.FC<{
  paneId: ChunkDestination;
}> = ({ paneId }) => {
  const base = useBase();

  return (
    <Crumb>
      <span
        style={{
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigateToBase(paneId, e.shiftKey);
        }}
        className={`body secondary regular white exerpt`}
      >
        {base.base.name}
      </span>
    </Crumb>
  );
};

const BaseCrumbOnly: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  const base = useBase();
  const showBaseAsBreadcrumb = useShallowSelector(
    (store) => store.topNav[paneId]?.showBaseAsBreadcrumb
  );

  if (!showBaseAsBreadcrumb) return <></>;

  return (
    <Crumb>
      <span className={`body primary regular white`}>{base.base.name}</span>
    </Crumb>
  );
};

const GroupCrumbNavigatorHoc: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const groupId = useGetGroupIdFromNavigationChunk(paneId);

  if (!groupId) return <></>;
  return <GroupCrumbNavigator paneId={paneId} groupId={groupId} />;
};

const GroupCrumbNavigator: React.FC<{
  paneId: ChunkDestination;
  groupId: string;
}> = ({ paneId, groupId }) => {
  const group = useShallowSelector((store) => store.groups.dict[groupId]);
  if (!group) return <></>;
  return (
    <>
      <span className="body disabled regular white"> / </span>
      <Crumb>
        <span
          className="body secondary regular white exerpt"
          style={{ cursor: "pointer" }}
          onClick={(e) => navigateToGroup(groupId, paneId, e.shiftKey)}
        >
          {group.name}
        </span>
      </Crumb>
    </>
  );
};

const DetailPageCrumbNavigator: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const paneDetail = useShallowSelector(
    (store) => store.navigation.navigation[paneId]
  );
  if (paneDetail.entity?.containerType === ContainerTypes.TEMPLATE)
    return <TemplatesPageCrumb paneId={paneId} />;
  if (paneDetail.entity?.containerType === ContainerTypes.SNIPPET)
    return <SnippetsPageCrumb paneId={paneId} />;

  return <></>;
};

const TemplatesPageCrumb: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const navigate = (e: React.MouseEvent) => {
    const baseSlug = store.getState().workspace.slug;
    const openInSplit = () => {
      navigationApi.openSplitView({
        viewName: GeneralViewsNames.Templates,
      });
    };
    const openInMain = () => {
      locationSubject.next(`/${baseSlug}/templates`);
    };

    if (paneId === ChunkDestination.primary) {
      if (e.shiftKey) return openInSplit();
      else return openInMain();
    } else {
      if (e.shiftKey) return openInMain();
      else return openInSplit();
    }
  };
  return (
    <>
      <span className="body disabled regular white"> / </span>
      <Crumb onClick={navigate}>
        <span
          style={{ cursor: "pointer" }}
          className={`body secondary regular white exerpt`}
        >
          Templates
        </span>
      </Crumb>
    </>
  );
};

const SnippetsPageCrumb: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const navigate = (e: React.MouseEvent) => {
    const baseSlug = store.getState().workspace.slug;
    const openInSplit = () => {
      navigationApi.openSplitView({
        viewName: GeneralViewsNames.Snippets,
      });
    };
    const openInMain = () => {
      locationSubject.next(`/${baseSlug}/snippets`);
    };

    if (paneId === ChunkDestination.primary) {
      if (e.shiftKey) return openInSplit();
      else return openInMain();
    } else {
      if (e.shiftKey) return openInMain();
      else return openInSplit();
    }
  };
  return (
    <>
      <span className="body disabled regular white"> / </span>
      <Crumb onClick={navigate}>
        <span
          style={{ cursor: "pointer" }}
          className={`body secondary regular white exerpt`}
        >
          Snippets
        </span>
      </Crumb>
    </>
  );
};

export const PayoutCrumbNavigator: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const navigate = (e: React.MouseEvent) => {
    const baseSlug = store.getState().workspace.slug;
    const openInSplit = () => {
      navigationApi.openSplitView({
        viewName: PayoutsViewsNames.Payouts,
      });
    };
    const openInMain = () => {
      locationSubject.next(`/${baseSlug}/payouts`);
    };

    if (paneId === ChunkDestination.primary) {
      if (e.shiftKey) return openInSplit();
      else return openInMain();
    } else {
      if (e.shiftKey) return openInMain();
      else return openInSplit();
    }
  };

  return (
    <>
      <span
        className="body secondary regular white exerpt"
        style={{ cursor: "pointer" }}
        onClick={navigate}
      >
        Payouts
      </span>
    </>
  );
};

const useGetGroupIdFromNavigationChunk = (paneId: ChunkDestination) => {
  const navigationChunk = useShallowSelector(
    (store) => store.navigation.navigation[paneId]
  );

  const [groupId, setGroupId] = useState<string | undefined>(undefined);
  const container = useGetContainerFromType(
    navigationChunk?.entity?.containerId,
    navigationChunk?.entity?.containerType
  );

  useLayoutEffect(() => {
    if (!navigationChunk) return setGroupId(undefined);
    if (navigationChunk.groupSlug) {
      const groupId =
        store.getState().groups.slugDict[navigationChunk.groupSlug].id;
      setGroupId(groupId);
      return;
    }
    if (navigationChunk.groupId) {
      setGroupId(navigationChunk.groupId);
      return;
    }
    if (container && (container as any).groupId) {
      if ((container as any).workType) {
        if ((container as any).workType === WorkTypes.TASK) {
          return setGroupId((container as any).groupId);
        } else return setGroupId(undefined);
      }
      return setGroupId((container as any).groupId);
    }
    return setGroupId(undefined);
  }, [navigationChunk?.groupSlug, navigationChunk?.groupId, container]);

  return groupId;
};

const Crumb: React.FC<{
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ children, onClick }) => {
  return (
    <div
      style={{
        minWidth: 0,
        flexShrink: 1,
        alignItems: "center",
        display: "inline-flex",
      }}
      onClick={onClick}
    >
      <div style={{ display: "flex", minWidth: 0, maxWidth: "180px" }}>
        {children}
      </div>
    </div>
  );
};

export default Breadcrumbs;
