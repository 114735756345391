// Vendor Libraries
import React, { useState, useLayoutEffect, useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

// Internal Modules
import { IWorkspaceObj, UserRole, IUserObj } from "utilities/types";

// Components
import Button, { ButtonTypes } from "components/Button";
import SuperSidebar from "screens/base/sidebar/SuperSidebar";
import BaseAvatar from "components/BaseAvatar";
import Conditional from "components/Conditional";

// Styles
import styles from "./userProfile/userProfile.module.scss";
import moment from "moment";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import { batch, shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import { locationSubject } from "components/LocationListener";
import { CLEAR_WORKSPACE_DATA } from "store/actions";
import LeaveBase, { LeaveBaseTypes } from "components/LeaveBase";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";

export default function UserProfile() {
  const user = useSelector((store: ClarityStore) => store.user, shallowEqual);

  useLayoutEffect(() => {
    if (!user?.id) locationSubject.next("/");
  }, [user]);

  useEffect(() => {
    batch(() => {
      store.dispatch({ type: CLEAR_WORKSPACE_DATA });
    });
  }, []);

  if (!user?.id) return <></>;

  return (
    <div className={styles.container}>
      <Title user={user} />
      <div className={styles.contentContainer}>
        <ProfileCard user={user} />
        <Bases user={user} />
      </div>
    </div>
  );
}

const Title: React.FC<{ user: IUserObj }> = ({ user }) => {
  const title = user ? user.name || `@${user.username}` : "User not found";

  return (
    <Helmet>
      <title>{`${title} • Clarity`}</title>
    </Helmet>
  );
};

export function BaseSwitcher() {
  return (
    <div className={styles.superSidebarContainer}>
      <SuperSidebar />
    </div>
  );
}

const ProfileCard: React.FC<{ user: IUserObj }> = ({ user }) => {
  // const localTime = "22:58 (EST)";
  // const website = "clarity.so";
  const joinDate = moment(
    user ? new Date(user.dateCreated) : new Date()
  ).format("MMMM D, YYYY");

  const publicKey = user?.publicEthAdress
    ? usernameSplitter(user?.publicEthAdress ? user.publicEthAdress : "")
    : undefined;

  return (
    <div className={styles.profileCard}>
      <div className={styles.profileCardLeft}>
        <Avatar user={user} />
        <span className={styles.profileCardName}>
          {user.name ? user.name : "@" + user.username}
        </span>
        <div className={styles.profileCardRow}>
          <Conditional on={user?.username}>
            <span className={styles.profileCardUsername}>
              @{user?.username}
            </span>
          </Conditional>
          <Conditional on={publicKey}>
            <div className={styles.profileCardPublicKey}>
              <span className={styles.profileCardPublicKeyText}>
                {publicKey}
              </span>
              <CheckCircleTwoTone style={{ marginLeft: "4px" }} />
            </div>
          </Conditional>
        </div>
        <Conditional on={user?.bio}>
          <span className={styles.profileCardBio}>{user?.bio}</span>
        </Conditional>
        <div className={styles.profileCardInfo}>
          <div className={styles.profileCardInfoItem}>
            <span className={styles.title}>Joined</span>
            <span className={styles.data}>{joinDate}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

function Avatar({ user }: { user: IUserObj }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        className={styles.avatar}
        style={{ backgroundImage: `url(${user?.avatar})` }}
      />
      <Conditional on={user}>
        <div className={styles.profileCardRight}>
          <SettingsBtn />
        </div>
      </Conditional>
    </div>
  );
}

function SettingsBtn() {
  return (
    <Link to={`/profile/settings`}>
      <Button>Settings</Button>
    </Link>
  );
}

function Bases({ user }: { user: IUserObj }) {
  const { myBases, joinedBases, asGuestBases } = useBasesState({ user });
  return (
    <div className={styles.basesContainer}>
      {myBases.length === 0 &&
      joinedBases.length === 0 &&
      asGuestBases.length === 0 ? (
        <JoinBase />
      ) : (
        <>
          <BaseList title="My Bases" bases={myBases} />
          <BaseList title="Bases I've Joined" bases={joinedBases} />
          <BaseList title="Guest Access" bases={asGuestBases} />
        </>
      )}
    </div>
  );
}

function useBasesState({ user }: { user: IUserObj }) {
  const [myBases, setMyBases] = useState<IWorkspaceObj[]>([]);
  const [joinedBases, setJoinedBases] = useState<IWorkspaceObj[]>([]);
  const [asGuestBases, setAsGuestBases] = useState<IWorkspaceObj[]>([]);

  useEffect(() => {
    if (!user?.workspaces) {
      setMyBases([]);
      setJoinedBases([]);
      setAsGuestBases([]);
    } else {
      const { workspaces } = user;
      const { myBases, joinedBases, asGuestBases } = groupBases({
        bases: workspaces,
      });

      setMyBases(myBases);
      setJoinedBases(joinedBases);
      setAsGuestBases(asGuestBases);
    }
  }, [user?.workspaces]);

  return {
    myBases,
    joinedBases,
    asGuestBases,
  };
}

function groupBases({
  bases,
}: {
  bases: IWorkspaceObj[];
}): Record<"myBases" | "joinedBases" | "asGuestBases", IWorkspaceObj[]> {
  const myBases: IWorkspaceObj[] = [],
    joinedBases: IWorkspaceObj[] = [],
    asGuestBases: IWorkspaceObj[] = [];

  bases.forEach((base) => {
    switch (base.roleType) {
      case UserRole.OWNER: {
        myBases.push(base);
        break;
      }
      case UserRole.GUEST: {
        asGuestBases.push(base);
        break;
      }
      default: {
        joinedBases.push(base);
        break;
      }
    }
  });

  myBases.sort((a, b) => {
    return a.name.localeCompare(b.name, "en");
  });
  joinedBases.sort((a, b) => {
    return a.name.localeCompare(b.name, "en");
  });
  asGuestBases.sort((a, b) => {
    return a.name.localeCompare(b.name, "en");
  });

  return {
    myBases,
    joinedBases,
    asGuestBases,
  };
}

function BaseList({ title, bases }: { title: string; bases: IWorkspaceObj[] }) {
  if (bases.length === 0) return <></>;
  return (
    <div className={styles.baseList}>
      <h5 className={styles.baseListTitle}>{title}</h5>
      <div className={styles.baseListBasesContainer}>
        {bases.map((base) => (
          <Base base={base} key={base.id} />
        ))}
      </div>
    </div>
  );
}

function JoinBase() {
  return (
    <div className={styles.nobaseFound}>
      <div className="body bold secondary">
        You aren’t a member of any bases
      </div>
      <div className="body secondary mb-12">
        Create a new base to start using Clarity now
      </div>
      <div className={styles.baseListBasesContainer}>
        <Button
          buttonType={ButtonTypes.MEDIUM_PRIMARY}
          type="submit"
          onClick={() => locationSubject.next("/launch-base")}
        >
          Create base
        </Button>
      </div>
    </div>
  );
}

function Base({ base }: { base: IWorkspaceObj }) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        locationSubject.next(`/${base.slug}`);
      }}
      className={styles.base}
    >
      <div className={styles.baseAvatarContainer}>
        <BaseAvatar
          base={base}
          className={styles.baseAvatar}
          aviBorderStyles={{ borderRadius: "13px" }}
        />
      </div>
      <div className={styles.baseInfo}>
        <span className={styles.baseName}>{base.name}</span>
        <span className={styles.baseTagline}>{base.tagline}</span>
      </div>
      <div className={styles.leave}>
        {base.roleType !== "Owner" && (
          <LeaveBase leaveBaseType={LeaveBaseTypes.Icon} baseId={base.id} />
        )}
      </div>
    </div>
  );
}
