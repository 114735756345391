import { locationSubject } from "components/LocationListener";
import React from "react";
import { IWorkspaceObj, UserRole } from "utilities/types";
import styles from "../claritySidebar.module.scss";
import Logo from "images/clarity-logomark-white.svg";
import { PlusOutlined } from "@ant-design/icons";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import Button, { ButtonTypes } from "components/Button";
import { Dropdown, Menu, Radio, Tooltip } from "antd";
import { DraggableProvided } from "react-beautiful-dnd";
import { useLocation, Link } from "react-router-dom";
import BaseAvatar from "components/BaseAvatar";
import LeaveBase, { LeaveBaseTypes } from "components/LeaveBase";
import { socket } from "App";
import store, { ClarityStore } from "store/storeExporter";
import { UPDATE_WORKSPACE_USER_SETTINGS } from "store/actions";
import { axiosInstance } from "index";

interface ISuperSidebarItemProps {
  baseId: string;
  isActive: boolean;
  isAvatar?: boolean;
  containerClass?: string;
  itemClass?: string;
  isHoverable?: boolean;
  noTooltip?: boolean;
  noClick?: boolean;
  provided?: DraggableProvided;
  style?: React.CSSProperties;
}

const SuperSidebarItem: React.FC<ISuperSidebarItemProps> = ({
  baseId,
  isActive,
  isAvatar = false,
  containerClass,
  itemClass = "",
  isHoverable = true,
  noTooltip = false,
  noClick = false,
  provided,
  style,
}) => {
  const base = useShallowSelector((store: ClarityStore) => {
    if (store.user?.workspaceEntries[baseId])
      return store.user?.workspaceEntries[baseId];
    if (baseId === store.workspace?.id) return store.workspace;
  });
  const { itemClassName, tooltipStyle } = useSuperSidebarItemState({
    isAvatar,
    itemClass,
    baseId,
  });

  if (!base) return <></>;

  return (
    <SSItemContainer
      isActive={isActive}
      isHoverable={isAvatar && !isActive && isHoverable}
      containerClass={containerClass}
      provided={provided}
      style={style}
      userRole={base.roleType}
      base={base}
    >
      <ItemDisplay
        {...{
          itemClassName,
          base,
          isActive,
          isAvatar,
          tooltipStyle,
          noTooltip,
          noClick,
        }}
      />
    </SSItemContainer>
  );
};

interface IItemDisplayProps {
  itemClassName: string;
  base: IWorkspaceObj;
  isActive: boolean;
  isAvatar: boolean;
  tooltipStyle: any;
  noTooltip: boolean;
  noClick: boolean;
}

function ItemDisplay({
  itemClassName,
  base,
  isActive,
  isAvatar,
  tooltipStyle,
  noTooltip,
  noClick,
}: IItemDisplayProps) {
  const onClick = () => {
    if (!isActive && !noClick) {
      locationSubject.next(`/${base.slug}`);
    }
  };

  return noTooltip ? (
    <BaseAvatar base={base} className={itemClassName} onClick={onClick} />
  ) : (
    <Tooltip
      title={base.name}
      placement="right"
      style={tooltipStyle}
      overlayClassName={styles.superSidebarItemTooltip}
      color="#594354"
    >
      <div>
        <BaseAvatar base={base} className={itemClassName} onClick={onClick} />
      </div>
    </Tooltip>
  );
}

function useSuperSidebarItemState({
  isAvatar,
  itemClass,
  baseId,
}: Pick<ISuperSidebarItemProps, "isAvatar" | "baseId"> &
  Required<Pick<ISuperSidebarItemProps, "itemClass">>) {
  const itemClassName = useOptionalClassName({
    baseStyle: styles.superSidebarItem,
    pairs: [
      {
        extraStyle: itemClass,
        withExtra: Boolean(itemClass),
      },
    ],
  });

  const tooltipStyle = { display: isAvatar ? "" : "none" };

  return {
    itemClassName,
    tooltipStyle,
  };
}

interface ISSItemContainerProps {
  children: React.ReactChild;
  isActive?: boolean;
  isTop?: boolean;
  containerClass?: string;
  isHoverable?: boolean;
  userRole?: UserRole;
  base?: IWorkspaceObj;
}

function SSItemContainer({
  children,
  isActive = false,
  isTop = false,
  containerClass = "",
  isHoverable = false,
  provided,
  style = {},
  userRole,
  base,
}: ISSItemContainerProps & Pick<ISuperSidebarItemProps, "provided" | "style">) {
  const { containerClassName } = useSSItemContainerState({
    isActive,
    isTop,
    containerClass,
    isHoverable,
  });

  const providedProps = !provided
    ? {}
    : {
        ref: provided.innerRef,
        ...provided.draggableProps,
        ...provided.dragHandleProps,
      };

  const menu = (
    <Menu
      mode={"vertical"}
      onClick={(e) => {
        e.domEvent.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.nativeEvent.stopPropagation();
      }}
    >
      <Menu.SubMenu
        style={{
          display: "flex",
          alignItems: "center",
        }}
        title={
          <>
            <span>Email notifications: </span>
            <b>
              {base?.userBaseSettings?.settings.emailNotifications ?? "All"}
            </b>
          </>
        }
        key={"notificationSub"}
      >
        <Radio.Group
          key="group"
          value={base?.userBaseSettings?.settings.emailNotifications ?? "All"}
          onChange={async (e) => {
            await axiosInstance
              .patch(`/api/user-base-settings`, {
                userBaseSettings: {
                  ...base?.userBaseSettings,
                  settings: {
                    ...base?.userBaseSettings?.settings,
                    emailNotifications: e.target.value,
                  },
                },
                clientId: socket.id,
              })
              .then(() => {
                store.dispatch({
                  type: UPDATE_WORKSPACE_USER_SETTINGS,
                  id: base?.id,
                  newUserBaseSettings: {
                    ...base?.userBaseSettings,
                    settings: {
                      ...base?.userBaseSettings?.settings,
                      emailNotifications: e.target.value,
                    },
                  },
                });
              });
          }}
        >
          <Menu.Item key="none" title="None">
            <Radio value="None">None</Radio>
          </Menu.Item>
          <Menu.Item key="mentions" title="Mentions">
            <Radio value="Mentions">Mentions</Radio>
          </Menu.Item>
          <Menu.Item key="all" title="All">
            <Radio value="All">All</Radio>
          </Menu.Item>
        </Radio.Group>
      </Menu.SubMenu>
      <Menu.Item key="1" disabled={userRole === UserRole.OWNER}>
        <LeaveBase
          disabled={userRole === UserRole.OWNER}
          leaveBaseType={LeaveBaseTypes.Span}
          baseId={base?.id}
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {base && base?.userBaseSettings?.settings ? (
        <Dropdown overlay={menu} trigger={["contextMenu"]}>
          <div style={style} {...providedProps} className={containerClassName}>
            {children}
          </div>
        </Dropdown>
      ) : (
        <div style={style} {...providedProps} className={containerClassName}>
          {children}
        </div>
      )}
    </>
  );
}

function useSSItemContainerState({
  isActive,
  isTop,
  containerClass,
  isHoverable,
}: Pick<ISSItemContainerProps, "isActive" | "isTop"> &
  Required<Pick<ISSItemContainerProps, "containerClass" | "isHoverable">>) {
  const containerClassName = useOptionalClassName({
    baseStyle: styles.superSidebarItemContainer,
    pairs: [
      {
        extraStyle: styles.superSidebarItemContainerActive,
        withExtra: isActive,
      },
      {
        extraStyle: styles.superSidebarItemContainerTop,
        withExtra: isTop,
      },
      {
        extraStyle: containerClass,
        withExtra: Boolean(containerClass),
      },
      {
        extraStyle: styles.superSidebarItemContainerHoverable,
        withExtra: isHoverable,
      },
    ],
  });

  return {
    containerClassName,
  };
}

export function SuperSidebarIcon() {
  const { linkTo, isActive } = useSSIconState();
  return (
    <Tooltip title="Home" placement="right">
      <Link to={linkTo}>
        <SSItemContainer isTop={true} isActive={isActive} isHoverable={true}>
          <div
            className={styles.superSidebarItem + " " + styles.clarityIconHolder}
          >
            <img src={Logo} alt="clarity logo" />
          </div>
        </SSItemContainer>
      </Link>
    </Tooltip>
  );
}

function useSSIconState() {
  const location = useLocation();
  const linkTo = `/profile`;
  const isActive = location.pathname.includes(`/profile`);

  return {
    linkTo,
    isActive,
  };
}

export function IconDivider() {
  return (
    <div className={styles.iconDividerContainer}>
      <div className={styles.iconDivider} />
    </div>
  );
}

export const AddBaseSidebarIcon: React.FC = () => {
  return (
    <Button
      buttonType={ButtonTypes.LINK}
      className={styles.addBaseButton}
      icon={<PlusOutlined />}
      onClick={() => locationSubject.next("/launch-base")}
    />
  );
};

export default SuperSidebarItem;
