import {
  IUserObj,
  IWorkspaceEntry,
  IWorkspaceObj,
  UserBaseSettings,
} from "./../../utilities/types";
import {
  SET_AUTHENTICATED_USER,
  REMOVE_AUTHENTICATED_USER,
  ADD_NEW_WORKSPACE,
  UPDATE_USER,
  UPDATE_WORKSPACE_RANK,
  UPDATE_WORKSPACE_USER_SETTINGS,
} from "../actions";
import { useShallowSelector } from "utilities/hooks";

interface IUserActionParameters {
  type: string;
  user: IUserObj;
}

interface IAddNewWorkspaceActionParameters {
  type: string;
  workspace: IWorkspaceObj;
}

interface IUpdateUserActionParameters {
  type: string;
  user: IUserObj;
}

interface IUpdateWorkspaceRankActionParameters {
  type: string;
  id: string;
  newRank: string;
}

interface IUpdateWorkspaceUserBaseSettings {
  id: string;
  newUserBaseSettings: UserBaseSettings;
}

const initialState: IUserObj = {
  id: "",
  username: "",
  name: "",
  email: "",
  bio: "",
  avatar: "",
  dateCreated: "",
  lastLogin: "",
  gettingStartedProgress: null,
  workspaces: [],
  workspaceIds: [],
  workspaceEntries: {},
};

export default function userReducer(
  state: IUserObj = initialState,
  action: IUserActionParameters &
    IAddNewWorkspaceActionParameters &
    IUpdateUserActionParameters &
    IUpdateWorkspaceRankActionParameters &
    IUpdateWorkspaceUserBaseSettings
) {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      if (action.user) {
        const newState = {
          ...state,
          ...action.user,
          workspaceEntries: {} as IWorkspaceEntry,
          workspaceIds: [] as string[],
        };

        if (action.user.workspaces && Array.isArray(action.user.workspaces)) {
          const workspaceIds = action.user.workspaces.map(
            (workspace: IWorkspaceObj) => {
              newState.workspaceEntries[workspace.id] = workspace;
              return workspace.id;
            }
          );
          newState.workspaceIds = [...workspaceIds];
        }
        return newState;
      }
      return state;

    case REMOVE_AUTHENTICATED_USER:
      return initialState;

    case ADD_NEW_WORKSPACE:
      if (state.workspaces && action.workspace) {
        const newState = {
          ...state,
          workspaceEntries: { ...state.workspaceEntries },
          workspaceIds: [...state.workspaceIds, action.workspace.id],
          workspaces: [...state.workspaces, action.workspace],
        };
        newState.workspaceEntries[action.workspace.id] = action.workspace;
        return newState;
      }
      break;
    case UPDATE_USER:
      if (action.user) {
        const newState = {
          ...state,
          ...action.user,
          workspaces: [...state.workspaces],
        };
        return newState;
      }
      break;
    case UPDATE_WORKSPACE_RANK:
      if (action.id) {
        const newState = {
          ...state,
          workspaceEntries: { ...state.workspaceEntries },
          workspaceIds: [] as string[],
        };
        newState.workspaceEntries[action.id].rank = action.newRank;
        newState.workspaceIds = [...state.workspaceIds].sort(
          (a: string, b: string) => {
            if (
              newState.workspaceEntries[a].rank &&
              newState.workspaceEntries[b].rank
            ) {
              if (
                newState.workspaceEntries[a].rank <
                newState.workspaceEntries[b].rank
              ) {
                return -1;
              }
              if (
                newState.workspaceEntries[a].rank >
                newState.workspaceEntries[b].rank
              ) {
                return 1;
              }
            }
            return 0;
          }
        );
        return newState;
      }
      break;
    case UPDATE_WORKSPACE_USER_SETTINGS:
      const newState = { ...state };

      if (action.id) {
        newState.workspaceEntries[action.id] = {
          ...newState.workspaceEntries[action.id],
          userBaseSettings: action.newUserBaseSettings,
        };
      }
      return newState;
    default:
      return state;
  }
}

export function useUser() {
  return useShallowSelector((state) => state.user ?? initialState);
}
