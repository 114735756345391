import notificationsApi from "clientApi/notificationsApi";
import { userApi } from "clientApi/userApi";
import Button, { ButtonTypes } from "components/Button";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";

const DiscordSection: React.FC = () => {
  const user = useSelector((store: ClarityStore) => store.user, shallowEqual);

  return (
    <>
      <DiscordAccount discordUsername={user?.discordUsername} />
    </>
  );
};

const DiscordAccount: React.FC<{ discordUsername?: string | undefined }> = ({
  discordUsername,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDisconnect = () => {
    setLoading(true);
    userApi
      .disconnectDiscord()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Oops something went wrong",
          body: "Sorry, we could not disconnect your account. Please try again later",
        });
        console.log("e", e);
      });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "26px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
        }}
      >
        {discordUsername && (
          <span className="body2 bold">@{discordUsername}</span>
        )}
      </div>

      <div style={{ display: "flex", gap: "16px" }}>
        {!discordUsername && (
          <Button
            isLoading={false}
            buttonType={ButtonTypes.LARGE_PRIMARY}
            style={{ width: "154px" }}
            onClick={() => {
              userApi.connectDiscord();
            }}
          >
            Connect
          </Button>
        )}
        {discordUsername && (
          <Button
            isLoading={loading}
            buttonType={ButtonTypes.LARGE_SECONDARY}
            style={{ width: "154px" }}
            onClick={handleDisconnect}
          >
            Disconnect
          </Button>
        )}
      </div>
    </div>
  );
};

export default DiscordSection;
