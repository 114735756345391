import { getHtml } from "editor/utils/blockValueHelpers";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { stripHtml } from "utilities/stringUtilities";
import { IProjectObj } from "utilities/types";
import styles from "./parentItemTitle/parentItemTitle.module.scss";

interface Props {
  parentId: string;
}

const ParentItemTitle: React.FC<Props> = (props) => {
  const parentItem: IProjectObj | null = useSelector((state: any) => {
    return state.work.dict[props.parentId];
  }, shallowEqual);

  if (parentItem)
    return (
      <span className={styles.parentItemTitle}>
        {stripHtml(getHtml(parentItem.nameValue))}
      </span>
    );
  else return <></>;
};

export default ParentItemTitle;
