import { notificationPageSize } from "modules/notificationsService";
import {
  SET_ACTIVE_WORKSPACE,
  SET_DONE_NOTIFICATIONS,
  SET_NEW_NOTIFICATIONS,
  SET_NOTIFICATIONS_COUNT,
  UPDATE_THREAD,
} from "store/actions";
import { IThreadView } from "utilities/types";

interface INotificationsDict {
  [id: string]: IThreadView;
}

export interface IinitialState {
  dict: INotificationsDict;
  inboxNotifications: string[];
  doneNotifications: string[];
  allUnreadCount: number;
  allMentionsCount: number;
  position: {
    page: number;
    size: number;
    allLoaded: boolean;
  };
  donePosition: {
    page: number;
    size: number;
    allLoaded: boolean;
  };
}

const initialState: IinitialState = {
  dict: {} as INotificationsDict,
  inboxNotifications: [],
  doneNotifications: [],
  allUnreadCount: 0,
  allMentionsCount: 0,
  position: {
    page: 0,
    size: notificationPageSize,
    allLoaded: false,
  },
  donePosition: {
    page: 0,
    size: notificationPageSize,
    allLoaded: false,
  },
};

interface IInAppNotificationsParameters {
  type: string;
  notifications: IThreadView[];
  position: {
    page: number;
    size: number;
    allLoaded: boolean;
  };
}

interface INewNotificationParameters {
  type: string;
  notification: IThreadView;
}

interface IThreadUpdateParameters {
  type: string;
  threadDelta: Partial<IThreadView>;
  id: string;
}

interface IThreadCountParamters {
  type: string;
  allMentionsCount: number;
  allUnreadCount: number;
}

export default function inAppNotificationReducer(
  state = initialState,
  action: IInAppNotificationsParameters &
    INewNotificationParameters &
    IThreadUpdateParameters &
    IThreadCountParamters
) {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      return initialState;
    }

    case SET_NEW_NOTIFICATIONS: {
      if (action.notifications) {
        const newState: IinitialState = {
          ...state,
          dict: { ...state.dict },
          inboxNotifications: [...state.inboxNotifications],
        };
        action.notifications.forEach((notification) => {
          if (!newState.inboxNotifications.includes(notification.id))
            newState.inboxNotifications.push(notification.id);
          newState.dict[notification.id] = notification;
        });

        if (action.position) newState.position = action.position;
        return newState;
      }
      return state;
    }

    case SET_DONE_NOTIFICATIONS: {
      if (action.notifications) {
        const newState: IinitialState = {
          ...state,
          dict: { ...state.dict },
          doneNotifications: [...state.doneNotifications],
        };
        action.notifications.forEach((notification) => {
          if (!newState.doneNotifications.includes(notification.id))
            newState.doneNotifications.push(notification.id);
          newState.dict[notification.id] = notification;
          return notification.id;
        });
        if (action.position) newState.donePosition = action.position;
        return newState;
      }
      return state;
    }

    case SET_NOTIFICATIONS_COUNT: {
      const newState: IinitialState = {
        ...state,
        dict: { ...state.dict },
        allMentionsCount: action.allMentionsCount,
        allUnreadCount: action.allUnreadCount,
      };
      return newState;
    }

    case UPDATE_THREAD: {
      const newState: IinitialState = {
        ...state,
        dict: { ...state.dict },
        inboxNotifications: [...state.inboxNotifications],
        doneNotifications: [...state.doneNotifications],
      };
      const currentThread = { ...newState.dict[action.id] };
      const newThread = { ...currentThread, ...action.threadDelta };

      newState.dict[newThread.id] = newThread;

      if (currentThread && currentThread.id) {
        if (currentThread.done !== newThread.done) {
          if (currentThread.done) {
            const index = newState.doneNotifications.indexOf(currentThread.id);
            newState.doneNotifications.splice(index, 1);
          } else {
            const index = newState.inboxNotifications.indexOf(currentThread.id);
            newState.inboxNotifications.splice(index, 1);
          }

          if (!newThread.done) {
            newState.inboxNotifications.splice(0, 0, newThread.id);
            orderByUpdate(newState.dict, newState.inboxNotifications);
          } else {
            newState.doneNotifications.splice(0, 0, newThread.id);
            orderByUpdate(newState.dict, newState.doneNotifications);
          }
        }
        if (currentThread.read !== newThread.read) {
          if (!newThread.read) {
            newState.allUnreadCount = newState.allUnreadCount + 1;
            newState.allMentionsCount =
              newThread.mentionCount + newState.allMentionsCount;
          } else {
            newState.allUnreadCount = newState.allUnreadCount - 1;
            newState.allMentionsCount =
              newState.allMentionsCount - currentThread.mentionCount;
          }
        } else {
          if (currentThread.mentionCount !== newThread.mentionCount) {
            newState.allMentionsCount =
              newState.allMentionsCount -
              currentThread.mentionCount +
              newThread.mentionCount;
          }
        }
      } else {
        if (!newThread.done) {
          if (newState.inboxNotifications.indexOf(newThread.id) === -1) {
            newState.inboxNotifications.splice(0, 0, newThread.id);
            orderByUpdate(newState.dict, newState.inboxNotifications);
          }
          if (!newThread.read) {
            newState.allUnreadCount = newState.allUnreadCount + 1;
            newState.allMentionsCount =
              newThread.mentionCount + newState.allMentionsCount;
          }
        } else {
          if (newState.doneNotifications.indexOf(newThread.id) === -1) {
            newState.doneNotifications.splice(0, 0, newThread.id);
            orderByUpdate(newState.dict, newState.doneNotifications);
          }
        }
      }

      return newState;
    }

    default:
      return state;
  }
}

const orderByUpdate = (dict: INotificationsDict, list: string[]) => {
  list.sort((a: string, b: string) => {
    const threadA = dict[a];
    const threadB = dict[b];
    return (
      new Date(threadB.dateUpdated).getTime() -
      new Date(threadA.dateUpdated).getTime()
    );
  });
};
