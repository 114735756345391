import { usernameSplitter } from "clarity-ui/UserDisplay";
import { userApi } from "clientApi/userApi";
import Button, { ButtonTypes } from "components/Button";
import { ConnectWalletButton } from "components/ConnectWalletButton";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { IUserObj } from "utilities/types";
import styles from "./walletConnect/walletConnect.module.scss";
import { CheckCircleOutlined } from "@ant-design/icons";
import { web3Api } from "clientApi/web3Api";
import ClarityInput from "components/ClarityInput";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import { Checkbox } from "antd";
import notificationsApi from "clientApi/notificationsApi";

const WalletConnection: React.FC = () => {
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);

  if (!user) return <></>;
  if (!user.publicEthAdress) return <ConnectWalletButton userId={user.id} />;
  return (
    <>
      <WalletPresence user={user} />
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <ConnectSecondary user={user} />
      </div>
    </>
  );
};

const ConnectSecondary: React.FC<{ user: IUserObj }> = ({ user }) => {
  const [addingAddr, setAddingAddr] = useState(
    user.secondaryWalletAddress ? true : false
  );
  const check = () => {
    setAddingAddr(true);
  };

  const remove = () => {
    if (user.secondaryWalletAddress) {
      userApi.persistChanges({
        secondaryWalletAddress: null,
      });
      userApi.updateUser({ secondaryWalletAddress: null });
    }
    setAddingAddr(false);
  };

  return (
    <>
      <Checkbox
        checked={addingAddr}
        onChange={() => {
          if (addingAddr) remove();
          else check();
        }}
      >
        <p className="caption secondary bold" style={{ margin: 0, padding: 0 }}>
          Use a different wallet to receive payments
        </p>
      </Checkbox>
      {addingAddr && <SecondaryWalletAdd user={user} />}
    </>
  );
};

const SecondaryWalletAdd: React.FC<{
  user: IUserObj;
}> = ({ user }) => {
  const [secondaryAddr, setsecondaryAddr] = useState("");
  const [isValid, setisValid] = useState(true);
  const [showError, setshowError] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const updatePayeeAddress = (force?: boolean) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!(isValid || force)) return;
    if (secondaryAddr === user.secondaryWalletAddress) return;
    userApi
      .persistChanges({
        secondaryWalletAddress: secondaryAddr,
      })
      .then(() => {
        notificationsApi.displayConfirmation({
          title: <span>Payments wallet updated</span>,
        });
      });
    userApi.updateUser({ secondaryWalletAddress: secondaryAddr });
  };

  useEffect(() => {
    const addr = user.secondaryWalletAddress ? user.secondaryWalletAddress : "";
    setsecondaryAddr(addr);
  }, [user.secondaryWalletAddress]);

  useEffect(() => {
    if (secondaryAddr === "") {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setisValid(false);
      setshowError(false);
      return;
    }
    const isValid = web3Api.validateAccount(secondaryAddr);
    if (!isValid) {
      setisValid(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setshowError(true);
      }, 500);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setisValid(true);
      timeoutRef.current = setTimeout(() => {
        updatePayeeAddress(true);
      }, 500);
      setshowError(false);
    }
  }, [secondaryAddr]);

  useEffect(() => {
    if (!user.secondaryWalletAddress) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <div>
        <ClarityInput
          // autoFocus={true}
          onBlur={() => updatePayeeAddress()}
          componentRef={inputRef}
          value={secondaryAddr}
          onChange={(e) => setsecondaryAddr(e.target.value)}
        />
        {showError && (
          <ClarityLabel
            message={"Hmm the address does not look correct"}
            type={MessageLabelTypes.error}
          />
        )}
      </div>
      {user.secondaryWalletAddress && (
        <p className="body2 secondary">
          Your wallet address for Sign-in with Ethereum and token-gated content
          is <b>{getPrimaryWallet(user)}</b> <br />
          Your wallet address for receiving contribution payouts is{" "}
          <b>{usernameSplitter(user.secondaryWalletAddress)}</b>
        </p>
      )}
    </div>
  );
};

const getPrimaryWallet = (user: IUserObj) => {
  const isUsingEns = user.username && user.username.includes(".eth");
  if (isUsingEns) return usernameSplitter(user.username);
  if (user.publicEthAdress) return usernameSplitter(user.publicEthAdress);
  return "";
};

const WalletPresence: React.FC<{ user: IUserObj }> = ({ user }) => {
  const isUsingEns = user.username && user.username.includes(".eth");
  const [ensName, setEnsName] = useState<string>("");
  const isDisabled =
    isUsingEns || !user.email || user.publicEthAdress === user.username;

  const [isLoading, setisLoading] = useState(false);

  useLayoutEffect(() => {
    const subscription = web3Api.ensDataSubject.subscribe((ens) => {
      if (ens && ens.name) {
        setEnsName(ens.name);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const startWalletDisconnect = async () => {
    if (!user || !user.publicEthAdress) return;
    setisLoading(true);
    await userApi.unlinkWallet(user.publicEthAdress);
    setisLoading(false);
  };

  if (!user.publicEthAdress) return <></>;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "26px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
        }}
      >
        {ensName && <span className="body2 bold">@{ensName}</span>}
        <span className={`${styles.addressLabel}`}>
          <span className="small secondary">
            {usernameSplitter(user.publicEthAdress)}
          </span>
          <span className={styles.addressLabelIcon}>
            <CheckCircleOutlined />
          </span>
        </span>
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
        <Button
          disabled={isDisabled || isLoading}
          isLoading={isLoading}
          buttonType={ButtonTypes.LARGE_SECONDARY}
          onClick={startWalletDisconnect}
          style={{ width: "154px" }}
        >
          Unlink wallet
        </Button>
        {isDisabled && (
          <div className="small secondary">
            <span>
              Before you can unlink your wallet, you must do the following:
            </span>
            <ul className={styles.explainer}>
              <li>Set a custom username</li>
              <li>Add an email address to your account</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletConnection;
