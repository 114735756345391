import ConfirmModal from "clarity-ui/ConfirmModal";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useBase } from "store/reducers/workspaceReducer";
import store, { ClarityStore } from "store/storeExporter";
import { IWorkspaceObj } from "utilities/types";
import Button, { ButtonTypes } from "./Button";
import ClarityInput from "./ClarityInput";
import { Form } from "antd";
import { validateDomainName } from "utilities/regexUtilities";
import {
  CLEAR_WORKSPACE_DATA,
  UPDATE_WORKSPACE,
  SET_AUTHENTICATED_USER,
} from "store/actions";
import { useHistory } from "react-router";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import { axiosInstance } from "index";
import notificationsApi from "clientApi/notificationsApi";

const ChangeBaseDomain: React.FC<{}> = () => {
  const history = useHistory();
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);
  const [domainChanged, setDomainChanged] = useState(false);
  const base = useBase();
  const [baseSettings, setBaseSettings] = useState<IWorkspaceObj>(base.base);
  const handleLeaveBase = async () => {
    handleSaveChanges();
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value, name } = e.currentTarget;
    setBaseSettings({ ...baseSettings, [name]: value.trim() });
    if (base.base.slug !== value.trim()) {
      setDomainChanged(true);
    } else {
      setDomainChanged(false);
    }
  };
  const user = useSelector((store: ClarityStore) => store.user, shallowEqual);
  if (!user) return <></>;
  const workspaces = user.workspaces;
  const ownedWorkspaces = workspaces
    .filter((workspace) => workspace.ownerName === base.base.ownerName)
    .filter((workspace) => workspace.slug !== base.base.slug);

  const isSlugNameValid =
    !!baseSettings.slug &&
    ownedWorkspaces.filter((workspace) => workspace.slug === baseSettings.slug)
      .length < 1 &&
    validateDomainName(baseSettings.slug) &&
    baseSettings.slug.length < 40;

  const handleSaveChanges = async () => {
    if (!isSlugNameValid || !domainChanged) {
      return;
    }
    try {
      const { data } = await axiosInstance.patch(`/api/workspace/profile`, {
        baseId: baseSettings.id,
        patch: [
          {
            op: "replace",
            path: "/slug",
            value: baseSettings.slug,
          },
        ],
      });
      if (data.status === 1) {
        store.dispatch({
          type: UPDATE_WORKSPACE,
          params: {
            delta: baseSettings,
          },
        });
        const { data } = await axiosInstance.get("/api/user");
        const user = data.payload;
        store.dispatch({ type: SET_AUTHENTICATED_USER, user });
        if (base.base.slug !== baseSettings.slug) {
          store.dispatch({ type: CLEAR_WORKSPACE_DATA });

          history.push(`/${baseSettings.slug}/`);
        }
      }
    } catch (e: any) {
      let message =
        "Sorry, we could not save your changes. Please try again later";
      if (e?.response?.data?.message.includes("Slug")) {
        message =
          "Sorry, we could not save your changes. The domain name is not unique";
      }
      notificationsApi.displayError({
        title: "Oops something went wrong",
        body: message,
      });
    }
  };

  return (
    <>
      <Button
        buttonType={ButtonTypes.DANGER}
        onClick={(e: any) => {
          setIsConfirmationModalVisible(true);
        }}
      >
        {"Change domain"}
      </Button>
      {isConfirmationModalVisible && (
        <ConfirmModal
          onConfirm={handleLeaveBase}
          confirmDisabled={!isSlugNameValid || !domainChanged}
          confirmationText="Confirm change"
          title="Change Domain"
          close={() => setIsConfirmationModalVisible(false)}
        >
          <div
            className="body2 regular"
            style={{
              marginTop: "24px",
              marginBottom: "40px",
            }}
          >
            <span>
              Please note: When you change the domain of this base, it will
              disrupt all links you have shared to documents in this base.
            </span>
            <Form>
              <Form.Item
                initialValue={base.base.slug}
                name="slug"
                style={{ marginTop: 32, marginBottom: 0 }}
              >
                <ClarityInput
                  label="Domain"
                  name="slug"
                  value={base.base.slug || ""}
                  onChange={handleInputChange}
                />
              </Form.Item>
              {isSlugNameValid && domainChanged && (
                <ClarityLabel
                  message={"This domain is available"}
                  type={MessageLabelTypes.success}
                />
              )}
            </Form>
            {!isSlugNameValid && (
              <ClarityLabel
                message={"Please enter a valid domain name"}
                type={MessageLabelTypes.error}
              />
            )}
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default ChangeBaseDomain;
