import React, { useContext, useLayoutEffect, useState } from "react";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { CommandPaletteContextSelectionsTypes } from "utilities/types";
import OptionLister from "../components/OptionLister";
import {
  CommandPaletteOption,
  CommandPaletteState,
  filterscontextMatch,
} from "../helpers/types";

const DefaultFiltersMode: React.FC<{
  context: CommandPaletteContextSelectionsTypes;
}> = ({ context }) => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const paneId = useShallowSelector(
    (store) => store.commandPalette.params.paneId ?? ChunkDestination.primary
  );
  const paletteStateContext = useContext(CommandPaletteState);

  useShallowSelector((store) => store.filter.currentDict[paneId].filters);

  useLayoutEffect(() => {
    const contextConfig = filterscontextMatch[context];
    setOptions(contextConfig.getOptions(paneId ?? ChunkDestination.primary));
    paletteStateContext.topBarText.next(contextConfig.topBarText);
  }, [context, paneId]);

  return <OptionLister options={options} />;
};

export default DefaultFiltersMode;
