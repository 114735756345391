import { useMemo } from "react";
import { useShallowSelector } from "utilities/hooks";
import { GeneralViewsNames } from "utilities/types";
import GroupsIcon from "icons/Components/GroupsIcon";
import IdcardTwoTone from "icons/Components/IdcardTwoTone";

enum Options {
  groups = "groups",
  roles = "roles",
}

export const useGroupRolesNavigationItems = () => {
  const base = useShallowSelector((store) => store.workspace);

  const options = useMemo(() => {
    const possibleOptions: Options[] = [];
    orderedOptions.forEach((option) => {
      possibleOptions.push(option);
    });
    return possibleOptions.map((option) => optionsData[option]);
  }, [base.id]);

  return options;
};

const optionsData = {
  [Options.groups]: {
    key: Options.groups,
    name: () => "Groups",
    icon: <GroupsIcon />,
    url: () => "groups",
    secondaryViewConfig: () => {
      return {
        viewName: GeneralViewsNames.Groups,
      };
    },
  },
  [Options.roles]: {
    key: Options.roles,
    name: () => "Roles",
    icon: <IdcardTwoTone />,
    url: () => "roles",
    secondaryViewConfig: () => ({
      viewName: GeneralViewsNames.Roles,
    }),
  },
};

const orderedOptions = [Options.groups, Options.roles];
