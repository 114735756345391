import ConfirmModal from "clarity-ui/ConfirmModal";
import React, { useEffect, useState } from "react";
import { IGroup } from "utilities/types";
import Button, { ButtonTypes } from "./Button";
import { groupApi } from "clientApi/groupsApi";
import notificationsApi from "clientApi/notificationsApi";
import dangerZoneConfrimstyles from "./confirmModal/confirmModal.module.scss";
import ClarityInput from "./ClarityInput";

const ArchiveGroup: React.FC<{
  group: IGroup;
  onArchive: any;
}> = ({ group, onArchive }) => {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [existingGroup, setexistingGroup] = useState<IGroup>(group);

  useEffect(() => {
    setexistingGroup(group);
  }, [group, isConfirmationModalVisible]);
  const handleArchiveGroup = async () => {
    handleSaveChanges();
  };

  const [disabled, setDisabled] = useState<boolean>(true);
  const [passPhrase, setPassPhrase] = useState<string>("");

  useEffect(() => {
    if (passPhrase === "ARCHIVE") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [passPhrase]);

  const handleSaveChanges = async () => {
    if (disabled) {
      return;
    }
    groupApi.deleteGroup(group.id).then(() => {
      notificationsApi.displayConfirmation({
        title: "Success",
        duration: 4,
        body: "Group archived",
      });
      onArchive();
    });
  };

  return (
    <>
      <Button
        buttonType={ButtonTypes.DANGER}
        onClick={(e: any) => {
          setIsConfirmationModalVisible(true);
        }}
      >
        {"Archive group"}
      </Button>
      {isConfirmationModalVisible && (
        <ConfirmModal
          onConfirm={handleArchiveGroup}
          confirmationText="Archive"
          title="Archive this group"
          confirmDisabled={disabled}
          close={() => setIsConfirmationModalVisible(false)}
        >
          <div className={dangerZoneConfrimstyles.container}>
            <div className={"body2 regular secondary"}>
              <span>Are you sure you want to Archive this group?</span>
              <p>Please writte "ARCHIVE" below to confirm the action</p>
            </div>
            <ClarityInput
              onChange={(e) => {
                setPassPhrase(e.target.value);
              }}
              placeholder="ARCHIVE"
            />
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default ArchiveGroup;
