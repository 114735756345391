import { axiosInstance } from "index";
import { batch } from "react-redux";
import {
  CLEAR_WORKSPACE_DATA,
  RELOAD_BASE,
  SET_AUTHENTICATED_USER,
  SET_USER_IN_DICT,
} from "store/actions";
import store from "store/storeExporter";
import { setAccessToken } from "utilities/authTokens";
import { IUserObj } from "utilities/types";

class UserApi {
  resetUser() {
    const baseId = store.getState().workspace.id;

    if (baseId) {
      store.dispatch({ type: CLEAR_WORKSPACE_DATA });
      store.dispatch({ type: RELOAD_BASE });
    } else {
      axiosInstance
        .get("/api/user")
        .then((res) => {
          const { payload: user } = res.data;
          console.clear();
          batch(() => {
            store.dispatch({ type: SET_AUTHENTICATED_USER, user });
            store.dispatch({ type: CLEAR_WORKSPACE_DATA });
            store.dispatch({ type: RELOAD_BASE });
          });
        })
        .catch((err) => {});
    }
  }

  resetUserWithBaseSelected() {
    axiosInstance
      .get("/api/user")
      .then((res) => {
        const { payload: user } = res.data;
        console.clear();
        batch(() => {
          store.dispatch({ type: SET_AUTHENTICATED_USER, user });
          store.dispatch({ type: CLEAR_WORKSPACE_DATA });
          store.dispatch({ type: RELOAD_BASE });
        });
      })
      .catch((err) => {});
  }

  createEmailChangeOtp(email: string) {
    return axiosInstance.post("/api/user/email/otp", {
      email,
    });
  }

  redeemEmailChangeOtp(otp: string) {
    return axiosInstance
      .post("/api/user/verify/otp", {
        otp,
      })
      .then((res) => {
        if (res?.data?.status === 1) {
          this.updateUser({ email: res.data.email });
        }
        return res;
      });
  }

  unlinkWallet(publicEthAdress: string) {
    axiosInstance
      .post("/api/user/disconnectWallet", {
        wallet: publicEthAdress,
      })
      .then(() => {
        this.updateUser({ publicEthAdress: undefined });
      });
  }

  async persistChanges(
    delta: Partial<IUserObj>,
    details?: {
      password: string;
    }
  ) {
    const patch: any[] = [];
    Object.entries(delta).forEach(([key, value]) => {
      patch.push({
        op: "replace",
        path: `/${key}`,
        value,
      });
    });
    if (details?.password) {
      delta.hasPassword = true;
      patch.push({
        op: "replace",
        path: `/password`,
        value: details.password,
      });
    }
    return axiosInstance
      .patch("/api/user", {
        patch,
      })
      .then((res) => {
        this.updateUser(delta);
        return res;
      })
      .catch((e) => {
        return {
          data: {
            status: 0,
            info: "Error while updating user",
          },
        };
      });
  }

  updateUser(delta: Partial<IUserObj>) {
    const prevUser = store.getState().user;
    const user = { ...prevUser, ...delta };
    store.dispatch({ type: SET_AUTHENTICATED_USER, user });
  }

  async checkUsernameUnique(username: string) {
    try {
      const { data } = await axiosInstance.post("/api/user/checkUsername", {
        username,
      });
      return {
        info: data.info as string,
        status: data.status as number,
      };
    } catch (e) {
      return {
        info: "Oops, something went wrong!",
        status: 0,
      };
    }
  }

  async changeUsername(username: string) {
    try {
      const { data } = await axiosInstance.patch("/api/user", {
        patch: [
          {
            op: "replace",
            path: "/username",
            value: username,
          },
        ],
      });
      this.updateUser({ username: data.payload.username });
    } catch (e) {
      console.log(e);
    }
  }

  async removePassword(currentPassword: string) {
    return await axiosInstance
      .post("/api/user/removePassword", { currentPassword })
      .then((res) => {
        if (res.data.status === 0) return res;
        const delta: Partial<IUserObj> = { hasPassword: false };
        this.updateUser(delta);
        return res;
      });
  }

  async tryChangePassword(currentPassword: string | null, newPassword: string) {
    return axiosInstance
      .post("/api/user/tryChangePassword", {
        currentPassword,
        newPassword,
      })
      .then((res) => {
        this.updateUser({
          hasPassword: true,
        });
        return res;
      });
  }

  async generateOtp(email: string, params: any) {
    return axiosInstance.post("/api/otp", {
      email: email,
      options: params,
    });
  }

  async loginOtp(email: string, password: string) {
    return axiosInstance
      .post("/api/auth/login", {
        username: email,
        password,
      })
      .then((res) => {
        return setAccessToken(res.data.accessToken);
      });
  }

  async getUser(id: string) {
    axiosInstance
      .get("/api/user/one", {
        params: { id },
      })
      .then((res) => {
        if (res.data.payload) {
          store.dispatch({
            type: SET_USER_IN_DICT,
            param: { user: res.data.payload },
          });
        }
      });
  }

  getUsernameForUser(id: string | null | undefined) {
    if (!id) return null;
    const userInDict = store.getState().members.dict[id];
    if (!userInDict) return null;
    return userInDict.name ? userInDict.name : `@${userInDict.username}`;
  }

  async disconnectDiscord() {
    return axiosInstance.post(`/api/user/discord/disconnect`);
  }

  async connectDiscord() {
    window.open(
      `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DISCORD_CALLBACK_URL}&response_type=code&scope=identify`,
      "_blank"
    );
  }
}

export const userApi = new UserApi();
