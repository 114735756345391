import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
  IWorkspaceObj,
  IUserObj,
  ContainerTypes,
  ITokenGate,
} from "utilities/types";
import { connect, shallowEqual, useSelector } from "react-redux";
import TokenGate, { TokenGateReason } from "screens/TokenGate";
import store, { ClarityStore } from "store/storeExporter";
import { tokenGateApi } from "clientApi/tokenGatesApi";
import DefaultPageHoc from "screens/templates/DefaultPageHoc";
import styles from "screens/templates/defaultPageHoc/defaultPageHoc.module.scss";
import BaseAvatar from "components/BaseAvatar";
import SignUpLoginSwitcher from "components/SignUpLoginSwitcher";
import { SET_OPEN_BASE_VIEW } from "store/actions";
import Button, { ButtonTypes } from "components/Button";
import { Link, useLocation } from "react-router-dom";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import { axiosInstance } from "index";

interface IPrivatePageProps {
  baseSlug: string;
  containerType: ContainerTypes;
  containerId: string;
}

interface IMapStateToProps {
  user: IUserObj;
}

const checkIfInBase = () => {
  const workspace = store.getState().workspace;
  return workspace.id ? workspace : null;
};

function PrivatePage(props: IPrivatePageProps & IMapStateToProps) {
  const { baseSlug, containerId, containerType } = props;
  const [base, setBase] = useState<IWorkspaceObj | null>(checkIfInBase());
  const [tokenGate, setTokenGate] = useState<ITokenGate | null>(null);
  const [isLoading, setisLoading] = useState(true);
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);
  const location = useLocation();

  const loadRelatedData = useCallback(async () => {
    if (containerType === ContainerTypes.CUSTOM_VIEW) {
      setisLoading(false);
      return;
    }
    try {
      const checkTokenGate = async (base: any) => {
        return axiosInstance
          .get("/api/container/gates", {
            params: {
              containerId,
              containerType,
              baseId: base.id,
            },
          })
          .then((res) => {
            if (res.data) {
              const gate = res.data.gate;
              if (gate) setTokenGate(gate);
            }
            setisLoading(false);
          })
          .catch(() => {
            setisLoading(false);
          });
      };
      if (!base) {
        axiosInstance
          .get(`/api/workspace/getOneBySlug/public/${baseSlug}`)
          .then(({ data }) => {
            const base = data.payload.base;
            if (base) {
              checkTokenGate(base).then(() => setBase(base));
            }
          })
          .catch(() => {
            setisLoading(false);
          });
      } else {
        checkTokenGate(base);
      }
    } catch {
      setisLoading(false);
    }
  }, [baseSlug, containerId, containerType]);

  useLayoutEffect(() => {
    loadRelatedData();
  }, []);

  if (isLoading) return <></>;

  return base && base?.name ? (
    <DefaultPageHoc cardBackground cardOutline showLogo>
      <div>
        <BaseAvatar
          base={base}
          className={styles.baseAvatar}
          aviBorderStyles={{ borderRadius: "20px", display: "none" }}
        />
      </div>
      <h3 className={styles.h3}>This Page is Private</h3>
      <div className={styles.spacer}></div>
      <ConditionalTokenGate
        tokenGate={tokenGate}
        showContent={false}
        containerId={containerId}
        containerType={containerType}
        baseId={base.id}
      >
        {!user || !user.id ? (
          <>
            <SignUpLoginSwitcher />
            <div
              className="caption regular disabled"
              style={{ textAlign: "center" }}
            >
              You must be logged into Clarity to join this base. You can log
              into an existing account, or create a new one using the buttons
              above.
            </div>
          </>
        ) : (
          <>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              onClick={() => window.location.replace(`/`)}
            >
              Return to my content
            </Button>
            <p
              className="caption regular disabled"
              style={{
                textAlign: "center",
                marginTop: "36px",
              }}
            >
              You are logged in as{" "}
              <b>{usernameSplitter(user.name || "@" + user.username)}</b>.{" "}
              <Link
                to={`/logout?redirect=${location.pathname}`}
                style={{ textDecoration: "underline", color: "#1b1b1b" }}
              >
                Not you?
              </Link>
            </p>
          </>
        )}
      </ConditionalTokenGate>
    </DefaultPageHoc>
  ) : (
    <></>
  );
}

export const ConditionalTokenGate: React.FC<{
  tokenGate: ITokenGate | null;
  showContent: boolean;
  containerId: string;
  containerType: ContainerTypes;
  baseId: string;
}> = ({
  tokenGate,
  showContent,
  children,
  containerId,
  containerType,
  baseId,
}) => {
  const beforeSettingUser = useCallback(
    async (userId: string) => {
      if (tokenGate && !showContent) {
        await tokenGateApi.redeemGate(
          tokenGate.id,
          userId,
          containerId,
          containerType,
          baseId
        );
        store.dispatch({
          type: SET_OPEN_BASE_VIEW,
          param: {
            openBaseView: true,
          },
        });
      }
    },
    [tokenGate?.id]
  );

  return useMemo(() => {
    if (tokenGate)
      return (
        <TokenGate
          tokenRequirements={tokenGate.tokenRequirements}
          beforeSettingUser={beforeSettingUser}
          reason={TokenGateReason.viewDocument}
          rule={tokenGate.rule}
          showContent={showContent}
        >
          {children}
        </TokenGate>
      );
    return <>{children}</>;
  }, [tokenGate?.id]);
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PrivatePage);
