import React from "react";
import { ChainNames, RPC, supportedChains } from "utilities/web3/connectors";
import ClaritySelect, { IOptions } from "./ClaritySelect";

const NetworkSelect: React.FC<{
  selectedNetworkId: number;
  onSelectionChanged: (newSelection: number) => void;
  disabled?: boolean;
}> = ({ selectedNetworkId, onSelectionChanged, disabled }) => {
  const options: IOptions[] = supportedChains.map((id) => ({
    label: <ItemLabel id={id} />,
    value: RPC[id].chainId,
  }));

  return (
    <ClaritySelect
      label="Network"
      value={selectedNetworkId}
      onChange={(e: number) => {
        onSelectionChanged(e);
      }}
      disabled={disabled}
      options={options}
    />
  );
};

const ItemLabel: React.FC<{ id: ChainNames }> = ({ id }) => {
  return (
    <span>
      <img
        height={20}
        width={20}
        style={{
          marginRight: "10px",
        }}
        src={RPC[id].iconUrl}
        alt={RPC[id].chainName}
      />
      {RPC[id].chainName}
    </span>
  );
};

export default NetworkSelect;
