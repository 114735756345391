import { SET_CLARITY_SIDEBAR_MODE } from "store/actions";
import { SidebarModes } from "store/reducers/sidebarReducer";
import store from "store/storeExporter";

class SidebarApi {
  setSidebarMode(param: {
    newMode?: SidebarModes;
    isTemporary?: boolean;
    isOpen?: boolean;
  }) {
    return store.dispatch({
      type: SET_CLARITY_SIDEBAR_MODE,
      ...param,
    });
  }

  toggleSidebarMode() {
    const sidebarState = store.getState().sidebar;
    if (sidebarState.isOpen && !sidebarState.isTemporary) {
      return sidebarApi.setSidebarMode({ isOpen: false, isTemporary: true });
    } else {
      return sidebarApi.setSidebarMode({ isOpen: true, isTemporary: false });
    }
  }
}

export const sidebarApi = new SidebarApi();
