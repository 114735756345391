import React, { useState } from "react";
import { connect } from "react-redux";
import ModalScrimComponent from "components/ModalScrimComponent";
import { CLOSE_SELECT_GROUP_MODAL } from "store/actions";
import { Modal } from "antd";
import { ClarityStore } from "store/storeExporter";
import ClaritySelect from "./ClaritySelect";

import styles from "./groupSelector/groupSelector.module.scss";
import { VALUE_ENTER, VALUE_ESCAPE } from "keycode-js";

interface Props {
  confirmationData: any;
  groupsDict: any;
  setModalVisibility: (details: any) => void;
}

const GroupSelector: React.FC<Props> = (props) => {
  const [groupId, setGroupId] = useState<string>(
    props.confirmationData.groupIds[0]
  );
  const options = props.confirmationData.groupIds.map((groupId: string) => {
    const group = props.groupsDict[groupId];
    return {
      value: groupId,
      label: group.name,
    };
  });

  const handleKeydown = (event: any) => {
    const { key } = event;
    if ((event.ctrlKey || event.metaKey) && key === VALUE_ENTER) {
      props.confirmationData.confirmAction(groupId);
      props.setModalVisibility({});
    } else if (key === VALUE_ESCAPE) {
      props.confirmationData.cancelAction();
      props.setModalVisibility({});
    }
  };

  return (
    <>
      <Modal
        keyboard={true}
        visible={true}
        title={props.confirmationData.title}
        okText={props.confirmationData.confirmMessage}
        onOk={(e) => {
          props.confirmationData.confirmAction(groupId);
          props.setModalVisibility({});
        }}
        okType="primary"
        cancelText="Cancel"
        onCancel={() => {
          if (props.confirmationData.cancelAction)
            props.confirmationData.cancelAction();
          props.setModalVisibility({});
        }}
      >
        <div
          tabIndex={0}
          onKeyDown={(e) => {
            e.stopPropagation();
            handleKeydown(e);
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: props.confirmationData.body }}
          ></div>
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdownLabel}>Select the group: </div>
            <ClaritySelect
              onChange={(groupId: string) => {
                setGroupId(groupId);
              }}
              autoFocus={true}
              stopPropagation={false}
              options={options}
              defaultValue={options[0].value}
              value={groupId}
              searchable={true}
            />
          </div>
        </div>
      </Modal>
      <ModalScrimComponent
        hideModal={() => {
          props.setModalVisibility({});
        }}
        light
      />
    </>
  );
};

const mapStateToProps = (state: ClarityStore) => ({
  confirmationData: state.confirmationModal.selectGroupModal,
  groupsDict: state.groups.dict,
});
const mapDispatchToProps = (dispatch: any) => ({
  setModalVisibility: (details: any) =>
    dispatch({ type: CLOSE_SELECT_GROUP_MODAL, details }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelector);
