import notificationsApi from "clientApi/notificationsApi";
import React, { useEffect } from "react";
import { GiftTwoTone } from "@ant-design/icons";

const NewVersionBanner: React.FC = () => {
  useEffect(() => {
    notificationsApi.displayBanner({
      actions: [
        {
          action: () => window.location.reload(),
          content: "Update",
        },
      ],
      body: "There is a new version of Clarity available",
      icon: <GiftTwoTone twoToneColor={notificationsApi.twoToneColors} />,
    });
  }, []);

  return <></>;
};

export default NewVersionBanner;
