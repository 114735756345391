import Icon from "@ant-design/icons";
import React from "react";

export default function TagTwoTone(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        opacity="0.1"
        d="M13.8039 3.85822L4.43591 13.2262L10.7734 19.5637L20.1414 10.1957L19.5929 4.40665L13.8039 3.85822ZM15.9367 10.1254C14.8 10.1254 13.8742 9.19962 13.8742 8.0629C13.8742 6.92618 14.8 6.0004 15.9367 6.0004C17.0734 6.0004 17.9992 6.92618 17.9992 8.0629C17.9992 9.19962 17.0734 10.1254 15.9367 10.1254Z"
        fill="currentColor"
      />
      <path
        d="M15.9375 6C14.8008 6 13.875 6.92578 13.875 8.0625C13.875 9.19922 14.8008 10.125 15.9375 10.125C17.0742 10.125 18 9.19922 18 8.0625C18 6.92578 17.0742 6 15.9375 6ZM15.9375 8.8125C15.5227 8.8125 15.1875 8.47734 15.1875 8.0625C15.1875 7.64766 15.5227 7.3125 15.9375 7.3125C16.3523 7.3125 16.6875 7.64766 16.6875 8.0625C16.6875 8.47734 16.3523 8.8125 15.9375 8.8125Z"
        fill="currentColor"
      />
      <path
        d="M21.9853 10.7531L21.2916 3.42656C21.2564 3.04687 20.9541 2.74687 20.5744 2.70937L13.2478 2.01562H13.2384C13.1634 2.01562 13.1048 2.03906 13.0603 2.08359L2.08453 13.0594C2.06281 13.0811 2.04557 13.1068 2.03381 13.1352C2.02204 13.1635 2.01599 13.1939 2.01599 13.2246C2.01599 13.2553 2.02204 13.2857 2.03381 13.3141C2.04557 13.3424 2.06281 13.3682 2.08453 13.3898L10.6111 21.9164C10.6329 21.9382 10.6589 21.9555 10.6874 21.9672C10.716 21.9788 10.7466 21.9847 10.7775 21.9844C10.8408 21.9844 10.8994 21.9609 10.9439 21.9164L21.9197 10.9406C21.9666 10.8914 21.99 10.8234 21.9853 10.7531ZM10.7752 19.5633L4.43766 13.2258L13.8056 3.85781L19.5947 4.40625L20.1431 10.1953L10.7752 19.5633Z"
        fill="currentColor"
      />
    </svg>
  );
}
