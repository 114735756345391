import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import PriorityDisplay from "components/PriorityDisplay";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import {
  CommandPaletteContext,
  ContainerTypes,
  getPriorityName,
} from "utilities/types";

const PriorityRow: React.FC<{
  priority: number;
  canEditEntity: boolean;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ priority, canEditEntity, containerId, containerType }) => {
  const priorityName = getPriorityName(priority);

  const handlePriorityClick = () =>
    openCommandPalette(CommandPaletteContext.PRIORITY, {
      selectedItemIds: [containerId],
      slectedItemsType: containerType,
    });

  return (
    <MetadataBlock
      title="Priority"
      infoText={
        priority > 1 ? (
          priorityName
        ) : (
          <span className="disabled"> No priority </span>
        )
      }
      disabled={!canEditEntity}
      onClick={handlePriorityClick}
      prefix={
        <PriorityDisplay
          priorityLevel={priority}
          size="large"
          showName={false}
        />
      }
    />
  );
};

export default PriorityRow;
