import React, { useEffect, useState, useRef } from "react";
import Button, { ButtonTypes } from "components/Button";
import { MailOutlined, CloseOutlined } from "@ant-design/icons";
import { debounce } from "throttle-debounce";
import * as actionTypes from "store/actions";
import { useDispatch } from "react-redux";
import {
  useContainerInviteModalSetter,
  useContainerInviteModalState,
  useSharingAndPermsModalState,
} from "store/reducers/clientReducer";

import {
  IPartialUser,
  UserInvitationStatus,
  IWorkspaceObj,
  ISubscriptionObj,
  PermissionTypes,
  ContainerTypeToPrimitive,
} from "utilities/types";

import styles from "./containerInviteModal/containerInviteModal.module.scss";
import { validateEmail } from "utilities/regexUtilities";
import { connect } from "react-redux";
import ModalScrimComponent from "components/ModalScrimComponent";
import UserInfo from "screens/base/main/settings/members/modalInvitations/UserInfo";
import {
  getContainerByType,
  getNameFromContainer,
} from "modules/containerHelpers";
import store from "store/storeExporter";
import { axiosInstance } from "index";

const { v4: uuidv4 } = require("uuid");

interface IModalInvitationProps {}

interface ISearchResult {
  user: IPartialUser;
  status: UserInvitationStatus;
}

interface IInvitationUser {
  type: "username" | "email";
  content: string;
  avatar?: string;
  id?: string;
  status?: UserInvitationStatus;
}

interface IMapStateToProps {
  base: IWorkspaceObj;
  subscription: ISubscriptionObj;
}

const ContainerInviteModal = (
  props: IModalInvitationProps & IMapStateToProps
) => {
  const [searchWord, setSearchWord] = useState<string>("");
  const [emailEntered, setEmailEntered] = useState(false);
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
  const [invitationUsers, setInvitationUsers] = useState<IInvitationUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { containerId } = useContainerInviteModalState();
  const { base } = props;

  const { containerType } = useSharingAndPermsModalState();

  const hideModal = useHideModal();

  useEffect(() => {
    async function fetchResults() {
      if (searchWord) {
        try {
          const { data } = await axiosInstance.get<{
            status: number;
            info: string;
            payload: {
              alreadyInvited: IPartialUser[];
              alreadyMemberExactMatch: IPartialUser[];
              nonMembers: IPartialUser[];
              guests: IPartialUser[];
            };
          }>(`/api/invitation/users/container/${base.id}/${searchWord}`);
          if (data.status === 1) {
            const { payload } = data;
            const _invitedMembers = payload.alreadyInvited.map(
              (m: IPartialUser) => ({
                status: UserInvitationStatus.INVITED_MEMBER,
                user: m,
              })
            );
            const _alreadyMembers = payload.alreadyMemberExactMatch.map(
              (m: IPartialUser) => ({
                status: UserInvitationStatus.ALREADY_MEMBER,
                user: m,
              })
            );
            const _nonMembers = payload.nonMembers.map((m: IPartialUser) => ({
              status: UserInvitationStatus.NON_MEMBER,
              user: m,
            }));
            const _guests = payload.guests.map((m: IPartialUser) => ({
              status: UserInvitationStatus.GUEST,
              user: m,
            }));
            setSearchResults([
              ..._nonMembers,
              ..._alreadyMembers,
              ..._invitedMembers,
              ..._guests,
            ]);
          } else {
            setSearchResults([]);
          }
        } catch {}
      }
    }
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord]);

  const handleInputChange = debounce(1000, async (text: string) => {
    setSearchWord(text);
    if (validateEmail(text)) {
      setEmailEntered(true);
    } else {
      setEmailEntered(false);
    }
  });

  const handleEmailResultClick = (email: string) => {
    setSearchWord("");
    setEmailEntered(false);
    if (SearchWordInput && SearchWordInput.current) {
      SearchWordInput.current.value = "";
    }
    if (invitationUsers.filter((m) => m.content === searchWord).length === 0) {
      const newUser: IInvitationUser = {
        id: new uuidv4(),
        avatar: "",
        content: email,
        type: "email",
      };
      // TODO block here for unique and inviting
      setInvitationUsers([...invitationUsers, newUser]);
    }
  };

  const handleSearchResultClick = (result: ISearchResult) => {
    setSearchWord("");
    setSearchResults([]);
    if (SearchWordInput && SearchWordInput.current) {
      SearchWordInput.current.value = "";
    }
    if (
      result.status === UserInvitationStatus.NON_MEMBER ||
      result.status === UserInvitationStatus.GUEST
    ) {
      // If the current search result is not included in the invitation list, then let's include it in invitation list.
      if (
        invitationUsers.filter((user) => user.id === result.user.id).length ===
        0
      ) {
        setInvitationUsers([
          ...invitationUsers,
          {
            type: "username",
            content: result.user.username,
            avatar: result.user.avatar,
            id: result.user.id,
            status: result.status,
          },
        ]);
      }
    } else {
      alert("Invitations can be only sent to non members of this base.");
    }
  };

  const handleInviteClick = async () => {
    setLoading(true);

    const newUsers = invitationUsers.filter(
      (user) => user.status !== UserInvitationStatus.GUEST
    );
    const existingGuests = invitationUsers.filter(
      (user) => user.status === UserInvitationStatus.GUEST
    );

    const invites = newUsers.map((m) => {
      if (m.type === "email") return { email: m.content };
      else return { username: m.content };
    });

    let containerName;
    if (containerId && containerType) {
      const container = getContainerByType(
        {
          containerId: containerId,
          containerType: containerType,
        },
        store.getState()
      );
      containerName = getNameFromContainer(container, containerType);
    }
    try {
      await axiosInstance.post("/api/invitation", {
        baseId: base.id,
        invites,
        guests: true,
        containerId,
        containerType: containerType,
        containerName: containerName,
      });
      if (existingGuests.length > 0) {
        const primitive = ContainerTypeToPrimitive[containerType];
        await axiosInstance.post("/api/role-assignment/role", {
          baseId: base.id,
          users: existingGuests.map((u) => u.id),
          containerId,
          containerType: containerType,
          containerName: containerName,
          deltas: existingGuests
            .map((u) => u.id)
            .map((userId) => {
              return {
                type: "add",
                value: containerId,
                primitive: primitive,
                permissionType: PermissionTypes.SUGGEST,
                userId: userId,
              };
            }),
        });
      }
      // TODO existing guests update role.
      hideModal();
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const removeInvitationUser = (idx: number) => {
    const _invitationUsers = [...invitationUsers];
    _invitationUsers.splice(idx, 1);
    setInvitationUsers(_invitationUsers);
  };

  const renderSearchResults = () =>
    searchResults.map((result) => (
      <div
        className={styles.invitation_modal_search_result}
        key={result.user.id}
        onClick={(e) => handleSearchResultClick(result)}
      >
        <div className={styles.invitation_modal_search_result_wrapper}>
          <>
            <UserInfo
              name={result.user.name || result.user.username}
              username={result.user.name ? result.user.username : ""}
              avatar={result.user.avatar}
            />
            {result.status === UserInvitationStatus.ALREADY_MEMBER && (
              <p className="caption secondary">Already a member of this base</p>
            )}
            {result.status === UserInvitationStatus.GUEST && (
              <p className="caption secondary">Guest of this base</p>
            )}
            {result.status === UserInvitationStatus.INVITED_MEMBER && (
              <p className="caption secondary">Already invited to this base</p>
            )}
          </>
        </div>
      </div>
    ));

  const SearchWordInput = useRef<HTMLInputElement>(null);

  const renderInvitationUsersList = () => (
    <div className={styles.invitation_modal_invitation}>
      {invitationUsers.map((user: IInvitationUser, idx: number) => (
        <div key={user.id} className={styles.invitation_modal_invitation_user}>
          {user.type === "email" && <p>{user.content}</p>}
          {user.type === "username" && <p>@{user.content}</p>}
          <Button
            icon={<CloseOutlined />}
            buttonType={ButtonTypes.DEFAULT}
            onClick={() => removeInvitationUser(idx)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        zIndex: 99999,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <div className={styles.invitation_modal} style={{ zIndex: 1 }}>
        <div>
          <div>
            <h4 className="bold">Invite guests</h4>
            <div className="small secondary mt-8 mb-12">
              A guest is someone who is not a full member of the base. They can
              only see the specific documents where they are invited.
            </div>
          </div>
          {invitationUsers.length > 0 && renderInvitationUsersList()}
          <input
            className={styles.invitation_modal_search}
            placeholder="Search by username, display name, or email address"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputChange(e.currentTarget.value);
            }}
            ref={SearchWordInput}
          />
        </div>
        <div className={styles.invitation_modal_search_results_container}>
          {emailEntered ? (
            <div
              className={styles.user_info}
              onClick={() => handleEmailResultClick(searchWord)}
            >
              <div className={styles.user_info__emailIcon}>
                <MailOutlined style={{ fontSize: "16px" }} />
              </div>
              <div className={styles.user_info_texts}>
                <p>{searchWord}</p>
                <p>Send invitation by email</p>
              </div>
            </div>
          ) : searchResults.length > 0 ? (
            renderSearchResults()
          ) : searchWord === "" ? (
            <p className={styles.invitation_modal_no_results}></p>
          ) : (
            <div
              className={`${styles.invitation_modal_no_result} small secondary`}
            >
              <div className="bold">
                {" "}
                There are no results for the current search.
              </div>
              If you are searching for someone who has an account, you must
              enter their exact name. It’s case sensitive.
            </div>
          )}
        </div>
        <div className={styles.invitation_modal_footer}>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={handleInviteClick}
            isLoading={loading}
          >
            Send Invitations
          </Button>
        </div>
      </div>

      <ModalScrimComponent hideModal={hideModal} />
    </div>
  );
};

function useHideModal() {
  const setContainerInviteModalState = useContainerInviteModalSetter();

  return () =>
    setContainerInviteModalState({
      isOpen: false,
    });
}

const mapStateToProps = (state: any) => ({
  base: state.workspace,
  subscription: state.subscription,
});

export function useInvitationsModalSetter() {
  const dispatch = useDispatch();

  const showInvitationsModal = () =>
    dispatch({
      type: actionTypes.SET_INVITATIONS_MODAL,
      isOpen: true,
    });

  return {
    showInvitationsModal,
  };
}

export default connect(mapStateToProps)(ContainerInviteModal);
