import React, { useEffect, useState } from "react";
import { batch, connect, useDispatch } from "react-redux";
import { ContainerTypes } from "../../../utilities/types";
import { BlockPropsWithRef } from "../BlockSplitter";
import styles from "./styles/blockComponent.module.scss";
import Button, { ButtonTypes } from "components/Button";
import { VALUE_ESCAPE } from "keycode-js";
import { socket } from "../../../App";
import { getClassNameFromBlockType } from "editor/utils/blockActions";
import { selectionStarted } from "components/Overlay";
import { FileImageOutlined } from "@ant-design/icons";
import { batchBlockUpdate } from "editor/utils/specificActions/batchBlockUpdate";
import * as actionTypes from "store/actions";
import { axiosInstance } from "index";
import { useGetResourceById } from "editor/utils/resourceGetterHooks/resourceGetter";
import { $focusOn } from "store/storeExporter";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";

interface IMapStateToProps {
  workspaceId: string;
  passedEntity: any;
}

const FigmaBlock: React.FC<BlockPropsWithRef & IMapStateToProps> = (props) => {
  const blockImageData = props.blockData.value[0];
  const { workspaceId } = props;
  let initialUrlValue = null;
  const { resource } = useGetResourceById(
    props.blockData.referencingContainerId
  );
  if (resource) {
    initialUrlValue = resource.url;
  } else {
    if (blockImageData?.options?.src)
      initialUrlValue = blockImageData.options.src;
  }
  const [videoUrl, setVideoUrl] = useState<string>(initialUrlValue);
  const [embedInput, setEmbedInput] = useState(false);
  const [embedInputValue, setEmbedInputValue] = useState("");
  const [readyToPlay, setreadyToPlay] = useState(false);
  const [allowedUrl, setAllowedUrl] = useState(false);
  const [inView, setinView] = useState(false);
  const dispatcher = useDispatch();

  let videoFocusedTimmer: any = null;

  useEffect(() => {
    if (resource) setVideoUrl(resource.url);
  }, [resource]);

  const handleEmbedInputChange = (event: any) => {
    const { value } = event.target;
    setEmbedInputValue(value);
    if (
      value.match(
        // eslint-disable-next-line no-useless-escape
        /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/
      )
    ) {
      setAllowedUrl(true);
    } else {
      setAllowedUrl(false);
    }
  };

  const clearFocusTimeout = (action: any) => {
    clearTimeout(videoFocusedTimmer);
    action(false);
  };

  const startUpdateTimeout = (action?: any) => {
    clearTimeout(videoFocusedTimmer);
    videoFocusedTimmer = setTimeout(() => {
      if (!selectionStarted) {
        action(true);
      }
    }, 1000);
  };

  const handleFigmaEmbed = async (videoUrl: string) => {
    setVideoUrl(videoUrl);
    handleEntityCreation(videoUrl);
    setEmbedInput(false);
  };

  const handleEntityCreation = (imgUrl: string) => {
    axiosInstance
      .post(`/api/resource/`, {
        name: "",
        url: imgUrl,
        caption: "",
        type: "Figma",
        baseId: workspaceId,
        clientId: socket.id,
      })
      .then((res) => {
        batch(() => {
          dispatcher({
            type: actionTypes.ADD_RESOURCE,
            resource: res.data.payload,
          });

          const resourceId: string = res.data.payload.id;

          batchBlockUpdate({
            id: props.blockData.id,
            context: props.context,
            delta: {
              referencingContainerType: ContainerTypes.RESOURCE,
              referencingContainerId: resourceId,
            },
          });

          dispatcher({
            type: actionTypes.CLEAR_RESOURCE_TO_UPLOAD,
            param: {
              blockId: props.blockData.id,
            },
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EmbedStep = () => {
    return (
      <div
        className={styles.imageBlockInput}
        style={{
          margin: props.blockData.indentLevel === 0 ? "-16px 0" : "0",
        }}
      >
        <div className={styles.imageBlockInput__icon}>
          <FileImageOutlined />
        </div>
        <div className={styles.imageBlockInput__embedInput}>
          <input
            autoFocus={props.blockData.referencingContainerId ? false : true}
            className={"embed-input"}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onPaste={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === VALUE_ESCAPE) {
                setEmbedInput(false);
              }
            }}
            onChange={handleEmbedInputChange}
            value={embedInputValue}
            placeholder="Paste Figma public file share link here to embed"
          />
        </div>
        <div>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={(event: any) => {
              event.stopPropagation();
              handleFigmaEmbed(embedInputValue);
            }}
            disabled={!allowedUrl}
          >
            Embed
          </Button>
        </div>
      </div>
    );
  };

  const EmptyBlock = () => {
    return (
      <div
        className={styles.imageBlockInput}
        style={{
          margin: props.blockData.indentLevel === 0 ? "-16px 0" : "0",
        }}
      >
        <div className={styles.imageBlockInput__icon}>
          <FileImageOutlined />
        </div>
        <>
          <span
            className={styles.imageBlockInput__button}
            onClick={(event: any) => {
              setEmbedInput(true);
              event.stopPropagation();
            }}
          >
            Embed a live public Figma file
          </span>
        </>
      </div>
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setinView(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (props.blockRef.current) {
      observer.observe(props.blockRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [props.blockRef, props.blockData.referencingContainerId]);

  return (
    <div
      data-block-id={props.blockData.id}
      tabIndex={0}
      data-root={true}
      ref={props.blockRef}
      contentEditable={false}
      style={{ alignItems: "center" }}
      className={
        "content-section clarity-selectable idle-project " +
        getClassNameFromBlockType(props.blockData.lineType) +
        styles.documentBlock
      }
    >
      <div
        style={{
          textAlign: props.blockData.indentLevel > 0 ? "left" : "center",
          position: "relative",
          padding: props.blockData.indentLevel === 0 ? "16px 0" : "1px",
        }}
        onMouseEnter={() => startUpdateTimeout(setreadyToPlay)}
        onMouseLeave={() => clearFocusTimeout(setreadyToPlay)}
        onClick={(e) => {}}
      >
        {!videoUrl ? embedInput ? EmbedStep() : EmptyBlock() : <></>}
        {videoUrl && (
          <div
            style={{
              borderRadius: "3px",
              overflow: "hidden",
              position: "relative",
              height: "450px",
              zIndex: 9,
              pointerEvents: readyToPlay ? "auto" : "none",
            }}
          >
            <iframe
              style={{ border: "none", display: inView ? "unset" : "none" }}
              onLoad={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (document.activeElement?.tagName === "IFRAME") {
                  (document.activeElement as any).blur();
                }
                document.body.focus();
                const blockId = $focusOn.value.focusBlockId;
                const block: any = document.querySelectorAll(
                  `[data-block-id="${blockId}"]`
                )[0];
                if (block) {
                  moveCaretToPreviousPosition(block, 0);
                }
              }}
              height="100%"
              title={"figma" + props.blockData.id}
              width="100%"
              src={`https://www.figma.com/embed?embed_host=clarity&url=\
       ${videoUrl}`}
              allowFullScreen
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: BlockPropsWithRef) => {
  return {
    workspaceId: state.workspace.id,
    passedEntity: state.blocks?.resourceToUpload?.[ownProps.blockData.id],
  };
};
export default connect(mapStateToProps)(FigmaBlock);
