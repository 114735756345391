import { shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore, prevState } from "store/storeExporter";
import {
  ContainerTypes,
  CustomWorkView,
  INotesObj,
  IPageObj,
  IProjectObj,
  ISnippetObj,
  ITemplateObj,
  WorkTypes,
} from "utilities/types";
import moment from "moment";
import { stripHtml } from "utilities/stringUtilities";
import { getHtml } from "editor/utils/blockValueHelpers";
import { getCorrectLink } from "utilities/linkUtilities";
import { fullMonths } from "utilities/dateTime";
import { Cycle } from "store/reducers/workReducer";

export const useGetContainerFromType = (
  containerId?: string,
  containerType?: ContainerTypes
) => {
  const container = useSelector(
    (state: ClarityStore) =>
      !containerId || !containerType
        ? ({} as IPageObj)
        : getContainerByType({ containerId, containerType }, state),
    shallowEqual
  );
  return container;
};

export const getContainerByType = (
  container: {
    containerType: ContainerTypes;
    containerId: string;
  },
  storeData?: ClarityStore
) => {
  if (!storeData) storeData = store.getState();

  switch (container.containerType) {
    case ContainerTypes.DOCUMENT:
      return storeData.pages.dict[container.containerId];
    case ContainerTypes.PROJECT:
    case ContainerTypes.TASK:
    case ContainerTypes.WORK:
      return storeData.work.dict[container.containerId];
    case ContainerTypes.NOTE:
      return (
        storeData.notes.dict[container.containerId] ??
        storeData.notes.weeklyNotesDict[container.containerId]
      );
    case ContainerTypes.TEMPLATE:
      return storeData.templates.dict[container.containerId];

    case ContainerTypes.SNIPPET:
      return storeData.snippets.dict[container.containerId];
    case ContainerTypes.WORK_ACTIVITY: {
      const containerData =
        storeData.work.workActivities[container.containerId];
      const workId = containerData?.taskId;
      if (workId) return storeData.work.dict[workId];
      return storeData.pages.dict[container.containerId];
    }
    case ContainerTypes.CUSTOM_VIEW:
      return storeData.customWork.dict[container.containerId];
    default: {
      return storeData.pages.dict[container.containerId];
    }
  }
};

export const getNameFromContainer = (
  container:
    | IProjectObj
    | INotesObj
    | IPageObj
    | ITemplateObj
    | ISnippetObj
    | CustomWorkView
    | Cycle,
  containerType: ContainerTypes,
  isIdRequired = true,
  fullNoteName = true
) => {
  if (containerType === ContainerTypes.NOTE) {
    const noteContainer = container as INotesObj;
    if (!container) return "Doc";
    if ((container as INotesObj).isWeekly) {
      return `Week of ${moment(noteContainer.dateCreated).format(
        "MMMM D, YYYY"
      )}`;
    }
    if (noteContainer.title && noteContainer.title !== "")
      return noteContainer.title ?? "";
    if (fullNoteName) return getUntitledNoteTitle(noteContainer);
    return "";
  }

  if (!container) return "";

  if (containerType === ContainerTypes.DOCUMENT)
    return stripHtml(getHtml((container as IPageObj).nameValue));

  if (containerType === ContainerTypes.TEMPLATE)
    return stripHtml(getHtml((container as ITemplateObj).nameValue));

  if (containerType === ContainerTypes.SNIPPET) {
    const containerRef = container as ISnippetObj;
    if (containerRef.name) return containerRef.name;
    else return "Untitled snippet";
  }

  if (
    [ContainerTypes.WORK, ContainerTypes.PROJECT, ContainerTypes.TASK].includes(
      containerType
    )
  ) {
    const workContainer: IProjectObj = container as IProjectObj;
    if (workContainer.workType === WorkTypes.TASK) {
      return (
        getGroupPrefixForTasks(workContainer.projectId, workContainer.groupId) +
        " " +
        stripHtml(getHtml(workContainer.nameValue))
      );
    } else {
      return (
        getGroupPrefixForProjects(
          workContainer.projectId,
          workContainer.workType
        ) +
        " " +
        stripHtml(getHtml(workContainer.nameValue))
      );
    }
  }

  if (ContainerTypes.CUSTOM_VIEW === containerType) {
    const workView = container as CustomWorkView;
    return workView.name;
  }

  if (ContainerTypes.CYCLE === containerType) {
    const sprint = container as Cycle;
    return sprint.name;
  }

  return stripHtml(getHtml((container as IProjectObj).nameValue));
};

export const getNameFromContainerForEmail = (
  container: IProjectObj | INotesObj | IPageObj,
  containerType: ContainerTypes
) => {
  if (
    containerType === ContainerTypes.NOTE ||
    containerType === ContainerTypes.TEMPLATE ||
    containerType === ContainerTypes.SNIPPET
  ) {
    getNameFromContainer(container, containerType);
  }
  if (!container) return "";
  if (containerType === ContainerTypes.DOCUMENT)
    return stripHtml(getHtml((container as IPageObj).nameValue));
  return stripHtml(getHtml((container as IProjectObj).nameValue));
};

export const getNameFromContainerForDiscord = (
  container: IProjectObj | INotesObj | IPageObj | CustomWorkView,
  containerType: ContainerTypes,
  isIdRequired = true,
  fullNoteName = true
) => {
  if (containerType === ContainerTypes.NOTE) {
    const noteContainer = container as INotesObj;
    if (!container) return "Doc";
    if ((container as INotesObj).isWeekly) {
      return `Week of ${moment(noteContainer.dateCreated).format(
        "MMMM D, YYYY"
      )}`;
    }
    if (noteContainer.title && noteContainer.title !== "")
      return noteContainer.title ?? "";
    if (fullNoteName) return getUntitledNoteTitle(noteContainer);
    return "";
  }

  if (!container) return "";

  if (containerType === ContainerTypes.DOCUMENT)
    return stripHtml(getHtml((container as IPageObj).nameValue));

  if (
    [ContainerTypes.WORK, ContainerTypes.PROJECT, ContainerTypes.TASK].includes(
      containerType
    )
  ) {
    const workContainer: IProjectObj = container as IProjectObj;
    if (workContainer.workType === WorkTypes.TASK) {
      return (
        workContainer.projectId +
        " " +
        stripHtml(getHtml(workContainer.nameValue))
      );
    } else {
      return (
        getGroupPrefixForProjects(
          workContainer.projectId,
          workContainer.workType
        ) +
        " " +
        stripHtml(getHtml(workContainer.nameValue))
      );
    }
  }

  if (ContainerTypes.CUSTOM_VIEW === containerType) {
    const workView = container as CustomWorkView;
    return workView.name;
  }

  return stripHtml(getHtml((container as IProjectObj).nameValue));
};

export const getLinkFromContainer = (
  container:
    | IProjectObj
    | INotesObj
    | IPageObj
    | CustomWorkView
    | ITemplateObj
    | ISnippetObj,
  containerType: ContainerTypes
) => {
  const base = store.getState().workspace;

  if (!container) return "/";
  (container as any).type = containerType;
  return getCorrectLink(base, container);
};

export const getCorrectPrefixForWorkItem = (workItem: IProjectObj) => {
  if (workItem.workType === WorkTypes.TASK)
    return getGroupPrefixForTasks(workItem.projectId, workItem.groupId);
  return getGroupPrefixForProjects(workItem.projectId, workItem.workType);
};

export const getGroupPrefixForProjects = (
  projectId: number,
  workType: WorkTypes
) => {
  const prefix = workType === WorkTypes.PROJECT ? "P" : "G";
  return prefix ? prefix + "-" + projectId : projectId;
};

export const getGroupPrefixForTasks = (projectId: number, groupId: string) => {
  const groupSlug = prevState.value.groups.dict[groupId]?.slug;
  return groupSlug ? groupSlug + "-" + projectId : projectId;
};

export const getUntitledNoteTitle = (note: INotesObj) => {
  return `Doc from ${getTime(moment(note.dateCreated))}`;
};

export const getTime = (momentDate: moment.Moment) => {
  return momentDate.format("MMM Do") + " at " + momentDate.format("hh:mm A");
};

export const getCreatedString = (dateCreated: Date) => {
  const noteDateCreated = new Date(dateCreated);
  const month = fullMonths[noteDateCreated.getMonth()];
  const date = noteDateCreated.getDate();
  const year = noteDateCreated.getFullYear();
  return `${month} ${date}, ${year}`;
};

export const getGroupsFromEntity = (
  entity: IProjectObj | INotesObj,
  containerType: ContainerTypes
) => {
  if (!entity) return undefined;
  if (containerType === ContainerTypes.NOTE) {
    const noteContainer = entity as INotesObj;
    return noteContainer?.groupId;
  }

  if (
    containerType === ContainerTypes.PROJECT ||
    containerType === ContainerTypes.WORK ||
    containerType === ContainerTypes.TASK
  ) {
    const projectEntity = entity as IProjectObj;

    if (projectEntity.workType === WorkTypes.TASK) {
      return projectEntity.groupId;
    } else {
      return projectEntity.groupIds;
    }
  }

  return undefined;
};

export const getGroupsFromEntityId = (
  entityId?: string,
  containerType?: ContainerTypes
) => {
  if (!entityId) {
    return undefined;
  }
  if (containerType === ContainerTypes.NOTE) {
    const noteContainer = store.getState().notes.dict[entityId];
    return noteContainer?.groupId;
  }

  if (
    containerType === ContainerTypes.PROJECT ||
    containerType === ContainerTypes.WORK ||
    containerType === ContainerTypes.TASK
  ) {
    const projectEntity = store.getState().work.dict[entityId];

    if (!projectEntity) return undefined;

    if (projectEntity.workType === WorkTypes.TASK) {
      return projectEntity.groupId;
    } else {
      return projectEntity.groupIds;
    }
  }

  return undefined;
};
