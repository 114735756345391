import React, { useEffect } from "react";
import { batch } from "react-redux";
import {
  setPaneSubroutes,
  setPaneTopNavViewType,
} from "store/reducers/topNavReducer";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { GeneralViewsNames } from "utilities/types";
import Roadmap from "../../Roadmap";

const MyRoadmap: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  const userId = useShallowSelector((store) => store.user?.id);
  useEffect(() => {
    batch(() => {
      if (userId) {
        setPaneSubroutes({
          paneId,
          subRoutesParams: {
            type: "myWork",
            activeKey: "roadmap",
          },
        });
      }
    });

    return () => {
      batch(() => {
        setPaneSubroutes({
          paneId,
        });
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, [userId, paneId]);

  return (
    <div
      className={`flex-fill page__mxWidth1355`}
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      <Roadmap
        paneId={paneId}
        assigneeId={userId}
        viewId={`${GeneralViewsNames.MyProjects}`}
      />
    </div>
  );
};

export default MyRoadmap;
