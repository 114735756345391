import {
  SET_LAST_VISITED_GROUP_LOCATION,
  SET_LAST_VISITED_HOME_LOCATION,
  SET_RECENTLY_OPENED,
} from "store/actions";
import store from "store/storeExporter";
import { NavigationChunk, RecentlyOpened } from "utilities/types";

const MAX_NUMBER_OF_RECENTLY_OPENED = 20;

class RecentlyOpenedApi {
  addRecentlyOpened(recentlyOpen: RecentlyOpened) {
    const state = store.getState();
    const baseId = state.workspace.id;

    const exitingRecentlyOpened = state.recentlyOpened.dict[baseId];

    const navigationChunk = recentlyOpen.navigationChunk;
    const recentlyOpenedIndex = exitingRecentlyOpened
      ? exitingRecentlyOpened
          .map((i) => i.navigationChunk)
          .findIndex(
            (item) =>
              item.viewName === navigationChunk.viewName &&
              item.entity?.containerId ===
                navigationChunk.entity?.containerId &&
              item.groupSlug === navigationChunk.groupSlug
          )
      : -1;
    let newRecentlyOpened;
    if (recentlyOpenedIndex === -1) {
      newRecentlyOpened = exitingRecentlyOpened
        ? [recentlyOpen, ...exitingRecentlyOpened]
        : [recentlyOpen];

      store.dispatch({
        type: SET_RECENTLY_OPENED,
        recentlyOpened: newRecentlyOpened,
        baseId,
      });
    } else {
      exitingRecentlyOpened.splice(recentlyOpenedIndex, 1);
      newRecentlyOpened = [recentlyOpen, ...exitingRecentlyOpened];
    }
    const toSet = this.checkIfMaxNumberReached(newRecentlyOpened);
    store.dispatch({
      type: SET_RECENTLY_OPENED,
      recentlyOpened: toSet,
      baseId,
    });
  }

  checkIfMaxNumberReached(recentlyOpened: RecentlyOpened[]) {
    if (recentlyOpened.length >= MAX_NUMBER_OF_RECENTLY_OPENED) {
      const removedLast = [
        ...recentlyOpened.slice(0, recentlyOpened.length - 1),
      ];

      return removedLast;
    }
    return recentlyOpened;
  }

  setLastOpenedGroupLocation(navigationChunk: NavigationChunk) {
    const baseId = store.getState().workspace.id;

    store.dispatch({
      type: SET_LAST_VISITED_GROUP_LOCATION,
      baseId,
      groupSlug: navigationChunk.groupSlug,
      location: navigationChunk,
    });
  }

  setLastOpenedHomeLocation(navigationChunk: NavigationChunk) {
    const baseId = store.getState().workspace.id;

    store.dispatch({
      type: SET_LAST_VISITED_HOME_LOCATION,
      baseId,
      location: navigationChunk,
    });
  }
}

const recentlyOpenedApi = new RecentlyOpenedApi();

export default recentlyOpenedApi;
