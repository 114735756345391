import { SyntheticEvent } from "react";
import { Block } from "store/reducers/blockReducer";
import {
  getInnerCaretPosition,
  moveCaretToPreviousPosition,
} from "utilities/caretMovement";
import { ILineValue, LineValueType } from "utilities/lineUtilities";
import { IBlockContext } from "utilities/types";
import { updateBlockText } from "./blockActions";
import { breakDownHtml, getHtml } from "./blockValueHelpers";

export const checkCaretPosition = (
  element: HTMLDivElement | null | HTMLElement
) => {
  if (element) {
    if (element.classList.contains("code")) {
      element = element.querySelector(".cm-content");
      if (!element) return 0;
    }
    let caretOffset = 0;
    const selection = document.getSelection();
    if (
      document &&
      selection &&
      selection !== null &&
      selection.rangeCount > 0
    ) {
      let range = selection.getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  } else return 0;
};

export const checkSelectionDetails = (element: any) => {
  if (element) {
    let caretEndOffset = 0;
    let caretStartOffset = 0;
    const selection = document.getSelection();
    if (
      document &&
      selection &&
      selection !== null &&
      selection.rangeCount > 0
    ) {
      let range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      const postCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      postCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      postCaretRange.setStart(range.startContainer, range.startOffset);
      caretStartOffset = preCaretRange.toString().length;
      caretEndOffset = postCaretRange.toString().length;
    }
    return {
      caretStartOffset,
      caretEndOffset,
    };
  } else return { caretStartOffset: 0, caretEndOffset: 0 };
};

export const getSelectionTextInfo = (
  el: any,
  event: SyntheticEvent | any
): any => {
  let element = event.currentTarget;
  if (el.classList.contains("code")) {
    el = element.querySelector(".cm-content");
  }
  const computedStyle = window.getComputedStyle(element);
  const lineHeightString = computedStyle.lineHeight;
  const paddingBottomString = computedStyle.paddingBottom ?? "0px";
  const paddingTopString = computedStyle.paddingTop ?? "0px";
  const paddingBottom: number =
    Number(paddingBottomString.substring(0, paddingBottomString.length - 2)) ??
    0;
  const paddingTop =
    Number(paddingTopString.substring(0, paddingTopString.length - 2)) ?? 0;
  const numberOfChars = lineHeightString.length;
  const lineHeight: number = Number(
    lineHeightString.substring(0, numberOfChars - 2)
  );

  const offs = element.getBoundingClientRect();
  const nrOfLines = Math.floor(
    (offs.height - paddingBottom - paddingTop) / lineHeight
  );

  let atStart = false,
    atEnd = false;
  let selRange, testRange;

  if (window.getSelection) {
    let details;
    let sel = window.getSelection();
    if (sel !== null && sel.rangeCount > 0) {
      try {
        selRange = sel.getRangeAt(0);
        testRange = selRange.cloneRange();

        testRange.selectNodeContents(el);
        testRange.setStart(testRange.startContainer, 0);
        const endOffsetEl = selRange.endContainer;
        let newEl;
        if (
          endOffsetEl.childNodes.length === 1 &&
          endOffsetEl.childNodes[0].nodeName === "BR"
        ) {
          const brEl = endOffsetEl.childNodes[0];
          newEl = document.createTextNode("0");
          endOffsetEl.appendChild(newEl);
          endOffsetEl.removeChild(brEl);
          testRange.setEnd(endOffsetEl, 1);

          details = testRange.getBoundingClientRect();
          endOffsetEl.appendChild(brEl);
          endOffsetEl.removeChild(newEl);
        } else {
          testRange.setEnd(selRange.endContainer, selRange.endOffset);
          details = testRange.getBoundingClientRect();
        }

        // if (selRange.endContainer.textContent) {
        //   const length = selRange.endContainer.textContent.length;
        //   testRange.setEnd(
        //     selRange.startContainer,
        //     length === 0 || selRange.startOffset === length
        //       ? selRange.startOffset
        //       : selRange.startOffset + 1
        //   );
        // } else testRange.setEnd(selRange.startContainer, selRange.startOffset);

        atStart = Math.ceil(details.height / lineHeight) === 1;
        atEnd = Math.ceil(details.height / lineHeight) === nrOfLines;
        if (newEl) newEl.remove();
      } catch {}
    }
  }

  const responseObject = {
    atStart,
    atEnd,
    singleLine: false,
  };

  if (nrOfLines === 1) {
    responseObject.singleLine = true;
  }
  return responseObject;
};

export const focusTitle = (
  destination: string | undefined,
  caretPosition: number
) => {
  const titleId = destination ? "title_" + destination : "title_primary";
  const title = document.getElementById(titleId);
  if (title) {
    title.focus();
    moveCaretToPreviousPosition(title, caretPosition);
  }
};

export const checkIfKeyIsPrintable = (event: any) => {
  const keycode = event.keyCode;

  const valid =
    (keycode > 47 && keycode < 58) || // number keys
    keycode === 32 ||
    keycode === 13 || // spacebar & return key(s) (if you want to allow carriage returns)
    (keycode > 64 && keycode < 91) || // letter keys
    (keycode > 95 && keycode < 112) || // numpad keys
    (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
    (keycode > 218 && keycode < 223); // [\]' (in order)

  return valid;
};

export const checkIfPreviousEmptyAndHasACodeBlock = (
  node: HTMLElement,
  event: any,
  caretPosition: number,
  range: any
) => {
  const previousElement = node.previousElementSibling
    ? node.previousElementSibling
    : node.parentElement
    ? node.parentElement.previousElementSibling
    : null;
  const nextElement = node.nextElementSibling
    ? node.nextElementSibling
    : node.parentElement
    ? node.parentElement.nextElementSibling
    : null;
  const prevHasCode = previousElement ? checkIfInsideCode(previousElement) : [];
  const nextHasCode = nextElement ? checkIfInsideCode(nextElement) : [];
  if (node.nodeValue) {
    const matchesNr = node.nodeValue.match(/\uFEFF/g);
    const innerPosition = getInnerCaretPosition(
      event.currentTarget,
      node,
      caretPosition
    );
    const positionCheck =
      (matchesNr && matchesNr.length === 1 && innerPosition === 1) ||
      innerPosition === 0;
    if (prevHasCode.includes(LineValueType.code) && positionCheck) {
      if (
        node.textContent &&
        node.textContent.length === 1 &&
        matchesNr &&
        matchesNr.length === 1
      ) {
        node.textContent = node.textContent.replace(/\uFEFF/g, ``);
        caretPosition = caretPosition - 1;
        moveCaretToPreviousPosition(event.currentTarget, caretPosition);
      } else {
        const textPart = document.createTextNode("\uFEFF");
        range.insertNode(textPart);
        caretPosition = caretPosition + 1;
        moveCaretToPreviousPosition(event.currentTarget, caretPosition);
      }
      return true;
    }
    const positionCheck2 =
      (matchesNr &&
        matchesNr.length === 1 &&
        node.textContent &&
        innerPosition === node.textContent.length - 2) ||
      (node.textContent &&
        (innerPosition === node.textContent.length - 1 ||
          innerPosition === node.textContent.length));

    if (nextHasCode.includes(LineValueType.code) && positionCheck2) {
      if (node.textContent) {
        if (nextElement) {
          const textPart = document.createTextNode("\uFEFF");
          nextElement.prepend(textPart);
          caretPosition = caretPosition + 1;
          moveCaretToPreviousPosition(event.currentTarget, caretPosition);
          return true;
        }
      }
    }
  }
  return false;
};

export const checkIfInsideCode = (node: Node): LineValueType[] => {
  const arrayOfFormats: LineValueType[] = [];
  if (node) checkFormats(node, arrayOfFormats);
  return arrayOfFormats;
};

const checkFormats = (node: Node, classList: string[]): string[] | any => {
  if (
    [
      LineValueType.a,
      LineValueType.b,
      LineValueType.code,
      LineValueType.strike,
      LineValueType.i,
      LineValueType.highlight,
    ].includes(node.nodeName as LineValueType)
  ) {
    classList.push(node.nodeName);
  }
  if (
    node.parentElement &&
    !node.parentElement.classList.contains("content-section")
  )
    return checkFormats(node.parentElement, classList);
};

export const checkIfInsideMention = (node: HTMLElement | null): any => {
  if (node) {
    if (
      node.classList?.contains("mention") &&
      !node.classList?.contains("hashtag")
    )
      return true;
    if (
      node.parentElement &&
      !node.parentElement.classList.contains("content-section")
    )
      return checkIfInsideMention(node.parentElement);
    else return false;
  } else return false;
};

export const checkIfAtLastElementOfNode = (
  event: any,
  element: Node | null,
  caretPosition: number
): any => {
  if (element) {
    const textLength = element.textContent ? element.textContent.length : 0;
    const innerPos = getInnerCaretPosition(
      event.currentTarget,
      element,
      caretPosition
    );

    if (innerPos === textLength) {
      if (element.parentElement) {
        const parentIsTag = checkIfInsideMention(element.parentElement);
        if (parentIsTag) {
          return checkIfAtLastElementOfNode(
            event,
            element.parentNode,
            caretPosition
          );
        } else return true;
      } else return true;
    } else return false;
  } else return false;
};

export const getRootTag = (node: HTMLElement): any => {
  if (
    node.parentElement &&
    !node.parentElement.classList.contains("content-section")
  ) {
    if (!node.parentElement.classList?.contains("mention")) return node;
    else return getRootTag(node.parentElement);
  }
  return node;
};

export const checkifBeginingOfTag = (range: Range): boolean => {
  if (range.startOffset === 0) return true;
  else return false;
};

export const checkIfEndOfTag = (range?: Range): boolean => {
  if (!range) {
    const selection = document.getSelection();
    if (selection && selection.rangeCount > 0) range = selection.getRangeAt(0);
  }
  if (range) {
    const textContent = range.endContainer.textContent;
    const numberOfChars = textContent ? textContent.length : 0;
    if (range.endOffset === numberOfChars) return true;
    else return false;
  }
  return false;
};

export const isSelectionBackwards = () => {
  var backwards = false;
  if (window.getSelection) {
    var sel = window.getSelection();
    if (sel && !sel.isCollapsed) {
      var range = document.createRange();
      if (sel.anchorNode && sel.focusNode) {
        range.setStart(sel.anchorNode, sel.anchorOffset);
        range.setEnd(sel.focusNode, sel.focusOffset);
        backwards = range.collapsed;
      }
    }
  }
  return backwards;
};

export const checkIfAtEdgeOfCitation = (event: any) => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    let outerCitation = selection.focusNode?.parentElement?.closest("citation");
    if (!outerCitation)
      outerCitation = selection.focusNode?.parentElement?.closest("comment");
    if (!outerCitation) return null;
    const caretPosition = checkCaretPosition(event.currentTarget);
    if (caretPosition === 0) {
      const range = selection.getRangeAt(0);
      const newRange = range.cloneRange();
      selection.removeAllRanges();
      newRange.setEndBefore(outerCitation);
      const textPart = document.createTextNode("\uFEFF");
      newRange.insertNode(textPart);
      newRange.setEndBefore(textPart);
      selection.addRange(newRange);
      return true;
    }
    const isAtEdge = checkIfAtLastElementOfNode(
      event,
      outerCitation,
      caretPosition
    );
    if (isAtEdge) {
      const range = selection.getRangeAt(0);
      const newRange = range.cloneRange();
      selection.removeAllRanges();
      newRange.setStartAfter(outerCitation);
      const textPart = document.createTextNode("\uFEFF");
      newRange.insertNode(textPart);
      newRange.setStartAfter(textPart);
      selection.addRange(newRange);
      return true;
    }
  }
};

export const checkIfAtEdgeOfTag = (event: any) => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const outerCitation =
      selection.focusNode?.parentElement?.closest("mention");

    if (!outerCitation) return null;
    const caretPosition = checkCaretPosition(event.currentTarget);
    if (caretPosition === 0) {
      const range = selection.getRangeAt(0);
      const newRange = range.cloneRange();
      selection.removeAllRanges();
      newRange.setEndBefore(outerCitation);
      const textPart = document.createTextNode("\uFEFF");
      newRange.insertNode(textPart);
      newRange.setEndBefore(textPart);
      selection.addRange(newRange);
      return true;
    }
    const isAtEdge = checkIfAtLastElementOfNode(
      event,
      outerCitation,
      caretPosition
    );
    if (isAtEdge) {
      const range = selection.getRangeAt(0);
      const newRange = range.cloneRange();
      selection.removeAllRanges();
      newRange.setStartAfter(outerCitation);
      const textPart = document.createTextNode("\uFEFF");
      newRange.insertNode(textPart);
      newRange.setStartAfter(textPart);
      selection.addRange(newRange);
      return true;
    }
  }
};

export const handleStartHighlight = (event: SyntheticEvent) => {
  const selection = document.getSelection();
  const selectionText = selection?.toString();
  if (selection && selectionText && selectionText?.length > 1) {
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const prevEl = range.cloneContents();
      range.deleteContents();
      const newNode = document.createElement("highlight");
      newNode.append(prevEl);
      const newRange = range.cloneRange();
      newRange.insertNode(newNode);
      selection.removeAllRanges();
      selection.addRange(newRange);
      newRange.collapse();
    }
  }
};

export const removeCitation = (
  blockData: any,
  citationData: any,
  dispatcher: any,
  destination?: string
) => {
  const blockRef = document.querySelector(
    `[data-block-id="${blockData.id}"]`
  ) as HTMLDivElement;

  if (!blockRef) return;

  const caretPosition = checkCaretPosition(blockRef);
  const citations = blockRef.querySelectorAll(
    `[data-citation-id="${citationData.citationId}"]`
  );
  Array.from(citations).forEach((el: any) => {
    const newText = document.createTextNode(el.textContent);
    el.parentNode.replaceChild(newText, el);
  });
  const pseudoevent = {
    target: blockRef,
    currentTarget: blockRef,
  };
  const pseudoContext = {
    id: blockData.containerType + blockData.containerId,
    autosave: true,
    canEdit: true,
  } as IBlockContext;

  updateBlockText(blockData, pseudoevent, pseudoContext);
  moveCaretToPreviousPosition(blockRef, caretPosition);
};

export const removeTag = (
  blockData: Block,
  focusedElement: any,
  dispatcher: any,
  destination?: string
) => {
  const blockRef = document.querySelector(
    `[data-block-id="${blockData.id}"]`
  ) as HTMLDivElement;

  if (!blockRef) return;

  const caretPosition = checkCaretPosition(blockRef);
  const innerTag = document.createDocumentFragment();
  focusedElement.childNodes.forEach((child: any) => {
    innerTag.appendChild(child);
  });
  focusedElement.replaceWith(innerTag);
  const pseudoevent = {
    target: blockRef,
    currentTarget: blockRef,
  };
  const pseudoContext = {
    id: blockData.containerType + blockData.containerId,
    autosave: true,
    canEdit: true,
  } as IBlockContext;

  updateBlockText(blockData, pseudoevent, pseudoContext, true);
  moveCaretToPreviousPosition(blockRef, caretPosition);
};

export const getPopupCoordinates = (props: any) => {
  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  if (selection && selection.rangeCount > 0) {
    const range: Range = selection.getRangeAt(0);
    let rangeX = range.getBoundingClientRect().x;
    let rangeR = range.getBoundingClientRect().width;
    let rangeY = range.getBoundingClientRect().top;
    let componentX = props.lineData.ref.current
      .closest(".section-big")
      ?.getBoundingClientRect().x;
    let componentY = props.lineData.ref.current.getBoundingClientRect().y;
    const computed = window
      .getComputedStyle(props.lineData.ref.current)
      .getPropertyValue("margin-top");
    const marginString = computed.substring(0, computed.length - 2);
    const margin = Number(marginString);

    x = rangeX === 0 ? 10 : rangeX - componentX + rangeR / 2;
    y = rangeY === 0 ? 10 : rangeY + margin - 6 - componentY;
    return { x, y };
  }
};

export const setNewBlockValue = (ref: any) => {
  const newValue: ILineValue[] = [];
  ref.childNodes.forEach((child: any) => {
    newValue.push(breakDownHtml(child));
  });
  ref.innerHTML = getHtml(newValue);
};
