import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ISingleMention } from "store/reducers/mentionsReducer";
import { prevState } from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import {
  ContainerTypes,
  IBlockContext,
  IPageObj,
  IProjectObj,
  ViewNames,
} from "utilities/types";
import styles from "clarity-ui/mentionsSection/mentionSubSection/containerGroup/containerGroup.module.scss";
import { stripHtml } from "utilities/stringUtilities";
import { LineType } from "utilities/lineUtilities";
import ReadOnlyBlocksContainer from "components/readOnlyBlocks/ReadOnlyBlocksContainer";
import FoldButton from "shannon/FoldButton";
import {
  getNameFromContainer,
  useGetContainerFromType,
} from "modules/containerHelpers";
import { useShallowSelector } from "utilities/hooks";
import { getHtml } from "editor/utils/blockValueHelpers";
import navigationApi from "clientApi/navigationApi";

interface Props {
  container: ISingleMention;
  paneId: ChunkDestination;
  linkButton?: any;
  unlinked?: boolean;
  contextPresets?: Partial<IBlockContext>;
}

const ContainerGroup: React.FC<Props> = (props) => {
  const [folded, setfolded] = useState(false);

  const entity = useGetContainerFromType(
    props.container.containerId,
    props.container.containerType
  );

  const clickAction = (e: any) => {
    navigationApi.contextBasedNavigate({
      navigationChunk: {
        viewName: ViewNames.Detail,
        entity: {
          containerId: props.container.containerId,
          containerType: props.container.containerType,
        },
      },
      currentPane: props.paneId,
      shiftKey: e.shiftKey,
      forceToPane: ChunkDestination.peek,
    });
  };

  const checkCorrectType = () => {
    if (props.container.containerType === ContainerTypes.NOTE) {
      if (!entity) return "Doc";
      return getNameFromContainer(entity, ContainerTypes.NOTE);
    }
    if (!entity) return "";
    if (props.container.containerType === ContainerTypes.DOCUMENT)
      return stripHtml(getHtml((entity as IPageObj).nameValue));

    return (
      "#" +
      (entity as IProjectObj).projectId +
      " " +
      stripHtml(getHtml((entity as IProjectObj).nameValue))
    );
  };

  return (
    <div
      className={`${styles.ContainerGroup} boundry ${
        folded && styles.ContainerGroup__folded
      }`}
      key={props.container.containerId}
    >
      <div className={styles.ContainerGroup__header}>
        <FoldButton
          isFolded={folded}
          onClick={() => {
            setfolded(!folded);
          }}
          style={{ marginRight: "6px" }}
        />
        <Link
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            clickAction(e);
          }}
          to={""}
          style={{ lineHeight: 1 }}
        >
          <span
            className={styles.ContainerGroup__header__name}
            dangerouslySetInnerHTML={{
              __html: checkCorrectType(),
            }}
          ></span>
          {props.container.blocks.length > 1 && (
            <span className={styles.ContainerGroup__header__count}>
              ({props.container.blocks.length})
            </span>
          )}
        </Link>
      </div>
      {!folded && (
        <div className={styles.ContainerGroup__body}>
          <GroupBlocks {...props} entity={entity} paneId={props.paneId} />
        </div>
      )}
    </div>
  );
};

export const GroupBlocks: React.FC<
  Props & { entity: any; paneId: ChunkDestination }
> = (props) => {
  let groupBlockIds = props.container.blocks;
  const block = useShallowSelector(
    (store) => store.blocks.dict[groupBlockIds[0]]
  );

  const containerRef = useRef(null);
  if (groupBlockIds.length === 1) {
    if (!block) return <></>;
    if (
      block.lineType === LineType.Title &&
      !props.unlinked &&
      !(
        block.containerType === ContainerTypes.NOTE &&
        block.referencingContainerType === ContainerTypes.NOTE
      )
    ) {
      return (
        <div className={styles.mentionBlock} key={block.id}>
          <span style={{ opacity: "0.4" }}>
            The mention appears to be in the title
          </span>
        </div>
      );
    }
  }

  return (
    <>
      <ReadOnlyBlocksContainer
        unlinked={props.unlinked}
        linkButton={props.linkButton}
        blocksObject={prevState.value.blocks.dict}
        blockIds={groupBlockIds}
        indentLevel={0}
        allowToggle={true}
        parentId={""}
        linkedEntity={{
          entityType: props.container.containerType,
          entityId: props.container.containerId,
          projectId: props.entity?.projectId,
        }}
        rootCustomClass={styles.mentionBlock}
        showDefaultRootBullets={true}
        parentListType={"bullet-1"}
        contextPresets={props.contextPresets}
        containerRef={containerRef}
        paneId={props.paneId}
      />
    </>
  );
};

export default ContainerGroup;
