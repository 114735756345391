import FabBtn, {
  IFabBtnProps,
  MenuItem,
  useMenuControllerState,
} from "components/FabBtn";
import {
  DownOutlined,
  PlusOutlined,
  FileDoneOutlined,
  AimOutlined,
} from "@ant-design/icons";
import styles from "./addNewFabBtn/addNewFabBtn.module.scss";
import {
  createAndSetNewProject,
  createAndSetNewInitiative,
} from "utilities/createNewContainers";
import { useSelector, shallowEqual } from "react-redux";
import { useOptionalClassName } from "utilities/hooks";
import { ClarityStore } from "store/storeExporter";
import { Abilities, UserRole } from "utilities/types";
import { useAbilityChecker } from "editor/utils/customHooks";
import Button, { ButtonTypes, IconSides } from "components/Button";
import { usePaneId } from "store/reducers/filterReducer";

interface IProjectsMenuBtnProps {
  standalone?: boolean;
  menuYDir: IFabBtnProps["menuYDir"];
  menuXDir: IFabBtnProps["menuXDir"];
  btnClass?: string;
  containerClass?: string;
  menuClass?: string;
  hideWhenOpen?: boolean;
  buttonType?: ButtonTypes;
  groupId?: string;
  disabled?: boolean;
}

export default function ProjectsMenuBtn({
  standalone,
  menuYDir,
  menuXDir,
  btnClass = "",
  containerClass = "",
  menuClass = "",
  hideWhenOpen = true,
  buttonType,
  groupId,
  disabled,
}: IProjectsMenuBtnProps) {
  const { menuIsOpen, openMenu, closeMenu } = useMenuControllerState();
  const { btnClassName } = useAddNewFabBtnState({ standalone, btnClass });
  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupId,
  });
  const { createProject, createInitiative } = useMenuState({ closeMenu });

  const role = useSelector(
    (state: ClarityStore) => state.client.roleType,
    shallowEqual
  );

  if (role === UserRole.GUEST) return <></>;
  return (
    <>
      {groupId === "" ? (
        <FabBtn
          hideWhenOpen={hideWhenOpen}
          open={openMenu}
          close={closeMenu}
          isOpen={menuIsOpen}
          icon={<DownOutlined />}
          containerClass={containerClass}
          btnClass={btnClassName}
          menuYDir={menuYDir}
          menuXDir={menuXDir}
          menuClass={menuClass}
          label="Create"
          disabled={disabled}
          buttonType={buttonType}
        >
          <MenuItem
            title="Project"
            description="For mid-sized efforts"
            disabled={!canEdit}
            icon={<FileDoneOutlined />}
            onClick={(e) => createProject(e)}
          />

          <MenuItem
            title="Goal"
            description="For your biggest & most important efforts"
            icon={<AimOutlined />}
            onClick={(e) => {
              createInitiative(e);
            }}
          />
        </FabBtn>
      ) : (
        <Button
          disabled={disabled}
          buttonType={ButtonTypes.PRIMARY}
          onClick={(e: any) => createProject(e)}
          icon={<PlusOutlined />}
          iconSide={IconSides.LEFT}
        >
          Project
        </Button>
      )}
    </>
  );
}

function useAddNewFabBtnState({
  standalone,
  btnClass,
}: {
  standalone?: boolean;
  btnClass: string;
}) {
  const btnClassName = useOptionalClassName({
    baseStyle: btnClass,
    pairs: [
      {
        extraStyle: styles.standalone,
        withExtra: standalone,
      },
    ],
  });

  return {
    btnClassName,
  };
}

function useMenuState({ closeMenu }: { closeMenu: () => void }) {
  const { base } = useSelector(
    (state: any) => ({
      base: state.workspace,
    }),
    shallowEqual
  );
  const paneId = usePaneId();
  const userRole = useSelector((state: ClarityStore) => state.client.roleType);

  const createProject = (event: any) => {
    createAndSetNewProject(event, base.id, paneId);
    closeMenu();
  };

  const createInitiative = (event: any) => {
    createAndSetNewInitiative(event, base.id, paneId);
    closeMenu();
  };

  return {
    createProject,
    createInitiative,
    userRole,
  };
}
