import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useLayoutEffect, useState } from "react";
import { Abilities } from "utilities/types";
import OptionLister from "../components/OptionLister";
import { getGeneralModeOptions } from "../helpers/contextOptionGetters";
import { CommandPaletteOption } from "../helpers/types";

const GeneralMode: React.FC = () => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  useLayoutEffect(() => {
    setOptions(getGeneralModeOptions());
  }, [canEditEntity]);

  return <OptionLister options={options} />;
};

export default GeneralMode;
