import { Menu, MenuItem } from "components/FabBtn";
import styles from "./sharingAndPermsModal.module.scss";
import {
  EditOutlined,
  CommentOutlined,
  UserOutlined,
  LockFilled,
} from "@ant-design/icons";
import ChevronDown from "icons/chevron-down.svg";
import { useEffect, useState } from "react";
import { DeleteOutlined, IMenuItem } from "./util";
import { ContainerVisibility } from "utilities/types";

const GUESSACCESS: IMenuItem[] = [
  {
    title: "Editor",
    description: "Modify the document",
    icon: <EditOutlined />,
    key: ContainerVisibility.canEdit,
  },
  {
    title: "Commenter",
    description: "Create & reply to comments",
    icon: <CommentOutlined />,
    key: ContainerVisibility.canComment,
  },
  {
    title: "Viewer",
    description: "Read and click only",
    icon: <UserOutlined />,
    key: ContainerVisibility.canView,
  },
  {
    title: "Remove",
    description: "Remove the permissions",
    icon: <DeleteOutlined />,
    key: ContainerVisibility.remove,
  },
];

export function PermissionsDropdownComponent({
  initialValue,
  readOnly,
  onChange,
  permissions,
  userId,
  type,
  ...props
}: any) {
  const { getPermUpdater, permSelected, permToDisplayText, editable } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const closeMenu = () => {
    setMenuIsOpen(false);
  };
  useEffect(() => {
    closeMenu();

    return () => {
      closeMenu();
    };
  }, [permToDisplayText[permSelected]]);

  return (
    <div className={styles.permissionsDropdownContainer}>
      <div
        className={styles.permissionsDropdownTrigger}
        onClick={() => (readOnly || !editable ? null : setMenuIsOpen(true))}
      >
        <span className="small primary">{permToDisplayText[permSelected]}</span>
        {readOnly || !editable ? (
          <LockFilled />
        ) : (
          <img src={ChevronDown} alt="chevron pointing down" />
        )}
      </div>
      {menuIsOpen && (
        <Menu
          close={closeMenu}
          yDir="down"
          xDir="left"
          className={styles.permissionsDropdownMenu}
        >
          {GUESSACCESS?.map((item: IMenuItem) => (
            <MenuItem
              title={item.title}
              description={item.description}
              icon={item.icon}
              onClick={getPermUpdater(item.key)}
              key={item.key}
            />
          ))}
        </Menu>
      )}
    </div>
  );
}
