import React, { useEffect, useRef, useState } from "react";
import store, { $focusOn } from "store/storeExporter";
import { batch, connect } from "react-redux";
import { ReactComponent as HandleIcon } from "icons/six-dot-handle.svg";
import { BlockProps } from "./Block";
import {
  clearBlockSelection,
  selectBlock,
  unselectBlocks,
} from "editor/utils/blockActions";
import { Dropdown, Input, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { getFullTime } from "components/TimePassed";
import { getUser } from "modules/containersService";
import moment from "moment";
import {
  ContainerTypes,
  CommandPaletteContext,
  UserRole,
} from "utilities/types";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import { isAnyChildUnfolded } from "editor/utils/specificActions/blockTreeCheckingUtils";
import * as actionTypes from "store/actions";
import { Block } from "store/reducers/blockReducer";
import contextMenuStyle from "./styles/context-menu.module.scss";
import { toggleBlockCollapse } from "editor/utils/specificActions/collapseActions";
import { handleStartComment } from "editor/utils/specificActions/textUpdateActions";
import { copyCitation } from "editor/utils/blockModeEvents";
import { blockModeDelete } from "editor/utils/specificActions/deleteBlockActions";
import { LineType } from "utilities/lineUtilities";
import { blockApi } from "editor/utils/blockActionsApi";
import { changeParentListType } from "editor/utils/specificActions/blockTypesActions";
import notificationsApi from "clientApi/notificationsApi";

interface Props {
  id: string;
  parentProps: BlockProps & {
    blockRef: React.MutableRefObject<HTMLDivElement | null>;
  };
  blockRef: React.MutableRefObject<HTMLDivElement | null>;
  setisHandleClicked: (isClicked: boolean) => any;
  toggleDragMode: (toogleMode: boolean) => any;
  isHandleClicked: boolean;
  isReadOnly?: boolean;
  dragStart?: boolean;
}

const nonTableBlocksOptions: any[] = [1, 2, 3, 4, 5, 6, 7];
const tableBlockOptions: any[] = [2, 3, 4];

const BlockHandle: React.FC<Props> = (props) => {
  const ref = useRef<any>();
  const dropdownRef = useRef<any>();
  useEffect(() => {
    return () => {
      props.setisHandleClicked(false);
    };
  }, []);

  return (
    <>
      <Dropdown
        mouseLeaveDelay={200}
        placement="topRight"
        onVisibleChange={(e) => {
          if (ref.current) {
            props.setisHandleClicked(e);
          }
        }}
        overlay={
          <BlockContextMenu
            blockProps={props.parentProps}
            dropDownRef={dropdownRef}
          />
        }
        trigger={["click", "contextMenu"]}
        visible={props.isHandleClicked}
      >
        <div
          ref={dropdownRef}
          onClick={(e) => {
            e.stopPropagation();
            store.dispatch({
              type: actionTypes.REFOCUS,
              param: {
                newFocus: {
                  ...$focusOn.value,
                  caretPosition: 0,
                  focusBlockId: props.parentProps.blockData.id,
                },
              },
            });
            props.setisHandleClicked(true);
            if (
              !props.parentProps.parentSelected &&
              !props.parentProps.blockData.selected
            ) {
              batch(() => {
                unselectBlocks({ id: props.id, type: "currentPosition" });
                selectBlock(props.id);
              });
            }
          }}
          style={{
            paddingTop: "5px",
            position: "absolute",
            right: 0,
            textAlign: "center",
            width: "19px",
          }}
        >
          <span
            ref={ref}
            draggable={props.isReadOnly ? false : true}
            className={`clarity-draggable ${contextMenuStyle.draggableCustome}`}
            onMouseDown={(e) => {
              e.currentTarget.style.cursor = "grabbing";
              e.stopPropagation();
            }}
            onMouseUp={(e) => (e.currentTarget.style.cursor = "grab")}
            onDragStart={(e) => {
              e.stopPropagation();
              store.dispatch({
                type: actionTypes.REFOCUS,
                param: {
                  newFocus: {
                    ...$focusOn.value,
                    caretPosition: 0,
                    focusBlockId: props.parentProps.blockData.id,
                  },
                },
              });
              if (
                !props.parentProps.parentSelected &&
                !props.parentProps.blockData.selected
              ) {
                batch(() => {
                  clearBlockSelection();
                  selectBlock(props.id);
                });
              }

              // e.preventDefault();
              if (props.isReadOnly) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }

              setTimeout(() => {
                props.toggleDragMode(true);
              }, 0);

              const clarityDragObj = {
                draggedBlockId: props.id,
              };
              e.dataTransfer.setData("text/plain", "");
              e.dataTransfer.effectAllowed = "move";
              e.dataTransfer.setData(
                "clarity-move",
                JSON.stringify(clarityDragObj)
              );
              if (e.currentTarget.parentElement) {
                e.dataTransfer.setDragImage(
                  e.currentTarget.parentElement,
                  10,
                  10
                );
              }

              return true;
            }}
            onDragEnd={(e) => {
              e.currentTarget.style.cursor = "grab";
              setTimeout(() => {
                props.toggleDragMode(false);
              }, 0);
            }}
          >
            <HandleIcon style={{ pointerEvents: "none", margin: "auto" }} />
          </span>
        </div>
      </Dropdown>
    </>
  );
};

const BlockContextMenu: React.FC<{
  blockProps: BlockProps & {
    blockRef: React.MutableRefObject<HTMLDivElement | null>;
  };
  dropDownRef: React.MutableRefObject<HTMLDivElement | null>;
  readOnly?: boolean;
}> = ({ blockProps, dropDownRef, readOnly }) => {
  const newState = store.getState().blocks;

  const block = getBlockById(newState.dict, blockProps.blockData.id);

  const checkForChildren = () => {
    if (block.containerType === ContainerTypes.WORK_ACTIVITY) {
      return false;
    } else return isAnyChildUnfolded(block);
  };

  const potentialItems = [
    {
      id: 1,
      name:
        block.isFolded || !checkForChildren()
          ? "Expand all children"
          : "Collapse all children",
      action: () => {
        toggleBlockCollapse({
          blockId: block.id,
          context: blockProps.context,
          shiftKey: true,
        });
        dropDownRef.current?.click();
      },
      children: [],
      subMenu: false,
      disabled: () => {
        return (
          block.containerType === ContainerTypes.WORK_ACTIVITY ||
          block.children.length === 0
        );
      },
    },
    {
      id: 2,
      name: "Change List Type",
      action: () => {},
      subMenu: true,
      children: [
        {
          name: "No Outline",
          action: () => {
            changeParentListType(
              blockProps.blockData.id,
              "noOutline",
              blockProps.context
            );
            dropDownRef.current?.click();
          },
          disabled: () => {
            return blockProps.blockData.indentLevel > 0;
          },
        },
        {
          name: "Bulleted List",
          action: () => {
            dropDownRef.current?.click();
            changeParentListType(
              blockProps.blockData.id,
              LineType.bulletedList,
              blockProps.context
            );
            blockApi.blockAction.refocus();
          },
        },
        {
          name: "Numbered List",
          action: () => {
            dropDownRef.current?.click();
            changeParentListType(
              blockProps.blockData.id,
              LineType.numberedList,
              blockProps.context
            );
            blockApi.blockAction.refocus();
          },
        },
      ],
      disabled: () => {
        return (
          block.containerType === ContainerTypes.WORK_ACTIVITY &&
          block.indentLevel === 0
        );
        // return false;
      },
    },
    {
      id: 3,
      name: "Delete Block",
      action: () => {
        batch(() => {
          blockModeDelete({
            blockId: blockProps.blockData.id,
            context: blockProps.context,
          });
        });
      },
      children: [],
    },
    {
      id: 4,
      name: "Comment",
      disabled: () => {
        return block.containerType === ContainerTypes.WORK_ACTIVITY;
      },
      action: () => {
        unselectBlocks({
          id: blockProps.blockData.id,
          type: "currentBlock",
        });
        handleStartComment(blockProps);
        dropDownRef.current?.click();
      },
    },
    {
      id: 5,
      name: "Copy Citation",
      disabled: () => {
        return block.containerType === ContainerTypes.WORK_ACTIVITY;
      },
      action: () => {
        copyCitation(blockProps, blockProps.blockRef);
        notificationsApi.displayConfirmation({
          title: "Citation copied!",
        });
        dropDownRef.current?.click();
      },
    },
    {
      id: 6,
      name: "Move To...",
      disabled: () => {
        return block.containerType === ContainerTypes.WORK_ACTIVITY;
      },
      action: () => {
        batch(() => {
          dropDownRef.current?.click();
          setTimeout(() => {
            store.dispatch({
              type: actionTypes.SHOW_COMMAND_PALETTE,
              context: CommandPaletteContext.MOVE_TO,
              params: {
                containerData: {
                  containerId: block.containerId,
                  containerType: block.containerType,
                },
                blockData: block,
                blockRef: blockProps.blockRef,
                context: blockProps.context,
              },
            });
          }, 1);
        });
      },
    },
    {
      id: 7,
      name: "Turn Into...",
      disabled: () => {
        const userRole = store.getState().client.roleType;

        return userRole === UserRole.GUEST;
      },
      action: () => {
        batch(() => {
          dropDownRef.current?.click();
          setTimeout(() => {
            store.dispatch({
              type: actionTypes.SHOW_COMMAND_PALETTE,
              context: CommandPaletteContext.TURN_INTO,
              params: {
                containerData: {
                  containerId: block.containerId,
                  containerType: block.containerType,
                },
                blockData: block,
                blockRef: blockProps.blockRef,
                context: blockProps.context,
              },
            });
          }, 1);
        });
      },
    },
  ];

  const menuItems = !readOnly ? potentialItems : [];

  const filterItems = (items: any, filterString: string): any[] => {
    return items.filter((item: any) => {
      if (block.lineType === LineType.table) {
        if (!tableBlockOptions.includes(item.id)) return false;
      } else {
        if (!nonTableBlocksOptions.includes(item.id)) return false;
      }
      const isIn = item.name
        .toLowerCase()
        .trim()
        .includes(filterString.toLowerCase().trim());

      return isIn;
    });
  };

  const [filteredItems, setfilteredItems] = useState(
    filterItems(menuItems, "")
  );

  const searchRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      (searchRef.current as HTMLInputElement).focus({ preventScroll: true });
    }, 1);

    return () => {
      if (searchRef.current) searchRef.current.innerHTML = "";
      setfilteredItems(menuItems);
    };
  }, []);

  const filterUpdate = (e: any) => {
    const items = filterItems(menuItems, e);
    setfilteredItems(items);
  };

  return (
    <div className="context-menu-container" style={{ width: 200 }}>
      <div style={{ padding: 6 }}>
        <Input
          className="menu-icon"
          key="menuInput"
          onChange={(e) => {
            filterUpdate(e.currentTarget.value);
          }}
          ref={searchRef}
          placeholder="Filter"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          bordered={false}
        />
      </div>
      <Menu
        className={contextMenuStyle.contextMenu + " menu-container"}
        mode="vertical"
      >
        {filteredItems.map((item, index) => {
          if (item.subMenu) {
            if (!item.disabled || !item.disabled()) {
              return (
                <SubMenu
                  className={contextMenuStyle.contextMenuItem}
                  key={item.name + index}
                  title={item.name}
                >
                  {item.children?.map((child: any) => {
                    return (
                      <Menu.Item
                        hidden={child.disabled ? child.disabled() : false}
                        className={contextMenuStyle.subtMenuItem + " item"}
                        key={child.name}
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          e.domEvent.stopPropagation();
                          batch(() => {
                            child.action();
                            store.dispatch({
                              type: actionTypes.REFOCUS,
                              param: { newFocus: $focusOn.value },
                            });
                          });
                        }}
                      >
                        {child.name}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            } else {
              return <React.Fragment key={item.name + index}></React.Fragment>;
            }
          }
          return (
            <Menu.Item
              hidden={item.disabled ? item.disabled() : false}
              className={contextMenuStyle.contextMenuItem + " item"}
              key={item.name + index}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.domEvent.stopPropagation();
                item.action();
                store.dispatch({
                  type: actionTypes.REFOCUS,
                  param: { newFocus: $focusOn.value },
                });
              }}
            >
              {item.name}
            </Menu.Item>
          );
        })}
      </Menu>

      <div style={{ width: "100%", padding: 10 }}>
        <span style={{ opacity: 0.6, fontSize: "small" }}>
          {getEditedText(block)}
        </span>
      </div>
    </div>
  );
};

const getEditedText = (block: Block) => {
  if (block.textModifiedBy) {
    return (
      <>
        Last edited{" "}
        {getFullTime(
          moment(
            block.textDateModified ? block.textDateModified : block.dateModified
          )
        )}{" "}
        by {getUser(block.textModifiedBy)}
      </>
    );
  } else {
    return (
      <>
        Last edited{" "}
        {getFullTime(
          moment(
            block.textDateModified ? block.textDateModified : block.dateModified
          )
        )}
      </>
    );
  }
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    dragStart: state.container.dragStart,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    toggleDragMode: (dragState: boolean) =>
      dispatch({
        type: actionTypes.DRAG_TOGGLE,
        dragState,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockHandle);
