import EmptyState from "clarity-ui/EmptyState";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { batch, useSelector } from "react-redux";
import {
  SET_CURRENT_FILTERS,
  SET_INITIAL_FILTERS,
  SET_IS_WORK_VIEW,
  SET_PAGE_DATA,
  SET_SHARE_DATA,
} from "store/actions";
import { ViewSettings } from "store/reducers/filterReducer";
import store, { ClarityStore, prevState } from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import {
  ContainerTypes,
  CustomWorkView,
  TasksViewModes,
} from "utilities/types";
import { useWorkBtnsSetter } from "./defaultWorkView/actions";
import { IWorkViewConfig } from "./defaultWorkView/types";
import WorkView from "./defaultWorkView/WorkView";
import AllCycles from "./workViews/AllCycles";

const generateViewConfigFromCustomView = (customWorkView: CustomWorkView) => {
  const viewConfig: IWorkViewConfig = {
    id: customWorkView.id,
    description: customWorkView.description,
    emptyState: "No work items match your filters",
    getFilters: () => customWorkView.filters,
    presetMetadata: () => ({}),
    groupBy: customWorkView.groupBy,
    orderBy: customWorkView.orderBy,
    viewAs: customWorkView.viewAs,
    path: `/view/${customWorkView.id}`,
    title: customWorkView.name,
    viewMode: TasksViewModes.CustomView,
    customView: true,
    showActiveProjects: customWorkView.showActiveProjects,
  };
  return viewConfig;
};

const WorkViewConfigurator: React.FC<{
  paneId: ChunkDestination;
  workViewDetails: IWorkViewConfig | null;
}> = ({ workViewDetails, paneId }) => {
  const customWorkView = useSelector((store: ClarityStore) => {
    if (workViewDetails?.customView && workViewDetails.id)
      return store.customWork.dict[workViewDetails.id];
  });

  const groupContext = useShallowSelector(
    (state) => state.client.paneGroupContext[paneId]
  );

  const [viewConfig, setviewConfig] = useState<IWorkViewConfig | null>(null);

  useWorkBtnsSetter(paneId, viewConfig);

  useEffect(() => {
    if (!viewConfig) return;
    if (viewConfig?.id.includes(TasksViewModes.ProjectWork)) return;
    if (
      viewConfig.viewMode === TasksViewModes.Tasks ||
      viewConfig.viewMode === TasksViewModes.OpenCycles
    ) {
      const groupId = prevState.value.client.paneGroupContext[paneId]?.groupId;
      if (!groupId) return;
      const groupName = prevState.value.groups.dict[groupId]?.name;
      if (!groupName) return;

      store.dispatch({
        type: SET_PAGE_DATA,
        paneId,
        title: groupName,
      });
      return;
    }

    store.dispatch({
      type: SET_PAGE_DATA,
      paneId,
      title: viewConfig.title,
    });

    if (viewConfig.customView) {
      store.dispatch({
        type: SET_SHARE_DATA,
        paneId,
        activeEntity: { ...customWorkView, type: ContainerTypes.CUSTOM_VIEW },
        containerId: customWorkView?.id,
        containerType: ContainerTypes.CUSTOM_VIEW,
      });
    }
    return () => {
      store.dispatch({
        type: SET_SHARE_DATA,
        paneId,
      });
    };
  }, [viewConfig, customWorkView, paneId]);

  useLayoutEffect(() => {
    const checkForLocalstorageAndSet = (config: IWorkViewConfig) => {
      if (config.viewMode === TasksViewModes.NewCustomView) {
        setviewConfig(config);
        return;
      }

      const baseId = store.getState().workspace.id;
      const workViewsString = localStorage.getItem("workViews");
      const workViews = JSON.parse(workViewsString ?? "{}");
      if (
        workViews &&
        workViews[baseId] &&
        workViews[baseId][
          groupContext?.groupId ? groupContext.groupId + config.id : config.id
        ]
      ) {
        const prevConfigData =
          workViews[baseId][
            groupContext?.groupId ? groupContext.groupId + config.id : config.id
          ];
        const newViewSettings: ViewSettings = {
          viewAs: prevConfigData.viewAs,
          orderBy: prevConfigData.orderBy,
          groupBy: prevConfigData.groupBy,
          filters: prevConfigData.filters,
          showActiveProjects: prevConfigData.showActiveProjects,
        };
        setCurrentFilters(newViewSettings);
      }
      setviewConfig(config);
    };

    const setInitialFilters = (config: IWorkViewConfig) => {
      store.dispatch({
        type: SET_INITIAL_FILTERS,
        param: {
          paneId,
          viewAs: config.viewAs,
          orderBy: config.orderBy,
          groupBy: config.groupBy,
          filters: config.getFilters(paneId),
          showActiveProjects: config.showActiveProjects,
          viewConfig: config,
        },
      });
    };

    const setCurrentFilters = (config: ViewSettings) => {
      store.dispatch({
        type: SET_CURRENT_FILTERS,
        paneId,
        currentFilters: {
          viewAs: config.viewAs,
          orderBy: config.orderBy,
          groupBy: config.groupBy,
          filters: config.filters,
          showActiveProjects: config.showActiveProjects,
        },
      });
    };

    if (customWorkView && customWorkView.id) {
      batch(() => {
        const config = generateViewConfigFromCustomView(customWorkView);
        setInitialFilters(config);
        checkForLocalstorageAndSet(config);
      });
      return;
    }

    if (workViewDetails) {
      batch(() => {
        setInitialFilters(workViewDetails);
        checkForLocalstorageAndSet(workViewDetails);
      });
    }
  }, [
    workViewDetails,
    workViewDetails?.id,
    customWorkView,
    groupContext?.groupId,
  ]);

  useLayoutEffect(() => {
    if (!viewConfig) return;
    if (viewConfig?.forceEmptyState) return;
    if (
      viewConfig?.viewMode &&
      [TasksViewModes.OpenCycles, TasksViewModes.ClosedCycles].includes(
        viewConfig.viewMode
      )
    ) {
      store.dispatch({
        type: SET_IS_WORK_VIEW,
        paneId,
        isWorkView: false,
      });
      return;
    }
    store.dispatch({
      type: SET_IS_WORK_VIEW,
      paneId,
      isWorkView: true,
    });
    return () => {
      store.dispatch({
        type: SET_IS_WORK_VIEW,
        paneId,
        isWorkView: false,
      });
    };
  }, [viewConfig?.forceEmptyState, viewConfig?.id]);

  if (viewConfig?.forceEmptyState || !viewConfig)
    return (
      <EmptyState
        heading={viewConfig?.description ?? ""}
        caption={viewConfig?.emptyState ?? ""}
        hideButton={true}
      />
    );

  switch (viewConfig.id) {
    case TasksViewModes.OpenCycles:
      return (
        <AllCycles mode="active" paneId={paneId} viewConfig={viewConfig} />
      );
    case TasksViewModes.ClosedCycles:
      return (
        <AllCycles mode="closed" paneId={paneId} viewConfig={viewConfig} />
      );
    default:
      return <WorkView paneId={paneId} viewConfig={viewConfig} />;
  }
};

export default WorkViewConfigurator;
