import { useShallowSelector } from "utilities/hooks";
import {
  getCreatedString,
  getNameFromContainer,
} from "modules/containerHelpers";
import { ContainerTypes, ViewNames } from "utilities/types";
import { locationSubject } from "components/LocationListener";
import store from "store/storeExporter";
import style from "./notes.module.scss";
import NotePreviewTags from "./notePreview/NotePreviewTags";
import navigationApi from "clientApi/navigationApi";
import { navigateToUrl } from "utilities/paneNavigationUtils";
import { usePaneId } from "store/reducers/filterReducer";
import UserDisplay from "clarity-ui/UserDisplay";

const NotePreview: React.FC<{ noteId: string }> = ({ noteId }) => {
  const note = useShallowSelector((state) => state.notes.dict[noteId]);
  const paneId = usePaneId();
  const navigate = (e: any) => {
    const openPrimary = () => {
      const baseSlug = store.getState().workspace.slug;
      locationSubject.next(`/${baseSlug}/notes/${noteId}`);
    };
    const openSecondary = () => {
      navigationApi.openSplitView({
        viewName: ViewNames.Detail,
        containerId: noteId,
        containerType: ContainerTypes.NOTE,
      });
    };
    navigateToUrl(e, paneId, openPrimary, openSecondary);
  };
  if (!note) return <></>;
  return (
    <div className={style.NoteItem}>
      <div className={style.itemHead}>
        <span
          className={`body regular exerpt  ${style.title}`}
          onClick={navigate}
        >
          {getNameFromContainer(note, ContainerTypes.NOTE)}
        </span>
      </div>
      <div className={style.itemTail}>
        <NotePreviewTags
          className={`${style.field} ${style.column} ${style.tags} exerpt`}
          blockId={note.titleBlockId}
        />
        <span
          className={`${style.field} ${style.column} ${style.date} caption regular  exerpt`}
        >
          <UserDisplay hideAvatar={true} id={note.createdById} />
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date}  secondary caption regular`}
        >
          {getCreatedString(new Date(note.dateModified ?? note.dateCreated))}
        </span>
      </div>
    </div>
  );
};

export const NotePreviewTitles: React.FC<{ width: number }> = ({ width }) => {
  return (
    <div
      className={`${style.NoteItem} ${style.titleRow}`}
      style={{ width: width }}
    >
      <div className={`${style.itemHead} label medium disabled`}>Name</div>
      <div className={style.itemTail}>
        <span
          className={`${style.field} ${style.column} ${style.tags} label disabled medium`}
        >
          Tags
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date} label disabled medium`}
        >
          Created By
        </span>
        <span
          className={`${style.field} ${style.column} ${style.date} label disabled medium`}
        >
          Updated
        </span>
      </div>
    </div>
  );
};

export default NotePreview;
