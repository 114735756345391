import { locationSubject } from "components/LocationListener";
import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePaneId } from "store/reducers/filterReducer";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import {
  ContainerTypes,
  GeneralViewsNames,
  GroupGeneralViews,
  NavigationChunk,
  PayoutsViewsNames,
  TasksViewModes,
} from "utilities/types";
import NavigationContext from "../NavigationContext";
import PrivatePage from "./detailView/PrivatePage";

const TasksViewRouter: React.FC<any> = (props) => {
  const location = useLocation();
  const splitPathname = location.pathname.split("/");
  const parentSlug = splitPathname[splitPathname.length - 2];
  const slug = location.pathname.split("/").pop();
  const paneId = usePaneId();
  const groupContext = useShallowSelector(
    (state) => state.client.paneGroupContext[paneId]?.groupId
  );

  const [workViewNavigation, setWorkViewNavigation] = useState<NavigationChunk>(
    {
      viewName: GroupGeneralViews.Tasks,
    } as NavigationChunk
  );

  useLayoutEffect(() => {
    switch (slug) {
      case "tasks": {
        setWorkViewNavigation({
          viewName: GeneralViewsNames.Tasks,
          taskViewMode: TasksViewModes.Tasks,
        });
        break;
      }
      case "assigned-to-me":
      case "my-work": {
        const baseSlug = store.getState().workspace.slug;

        locationSubject.next(`/${baseSlug}/my-work`);
        setWorkViewNavigation({
          viewName: GeneralViewsNames.MyTasks,
          taskViewMode: TasksViewModes.MyTasks,
        });
        break;
      }
      case "current": {
        if (parentSlug === "tasks") {
          setWorkViewNavigation({
            viewName: GeneralViewsNames.CurrentTasks,
            taskViewMode: TasksViewModes.CurrentTasks,
          });
        }
        break;
      }
      case "active": {
        setWorkViewNavigation({
          viewName: GroupGeneralViews.CyclesActive,
          taskViewMode: TasksViewModes.ActiveCycle,
        });
        break;
      }
      case "someday": {
        setWorkViewNavigation({
          viewName: GeneralViewsNames.SomedayTasks,
          taskViewMode: TasksViewModes.Someday,
        });
        break;
      }
      case "archive": {
        if (parentSlug === "tasks") {
          setWorkViewNavigation({
            viewName: GeneralViewsNames.TasksArchive,
            taskViewMode: TasksViewModes.TasksArchive,
          });
        }
        break;
      }
      case "next": {
        setWorkViewNavigation({
          viewName: GroupGeneralViews.CyclesNext,
          taskViewMode: TasksViewModes.NextCycle,
        });
        break;
      }
      case "new": {
        if (parentSlug === "view") {
          setWorkViewNavigation({
            viewName: GeneralViewsNames.NewCustomView,
            taskViewMode: TasksViewModes.NewCustomView,
          });
        }
        break;
      }
      case "sprints": {
        setWorkViewNavigation({
          viewName: GroupGeneralViews.CyclesActive,
          taskViewMode: TasksViewModes.OpenCycles,
        });
        break;
      }
      case "closed": {
        if (parentSlug === "sprints") {
          setWorkViewNavigation({
            viewName: GroupGeneralViews.CyclesClosed,
            taskViewMode: TasksViewModes.ClosedCycles,
          });
        }
        break;
      }
      case "unclaimed": {
        setWorkViewNavigation({
          viewName: PayoutsViewsNames.UnclaimedContributions,
          taskViewMode: TasksViewModes.UnclaimedContributions,
        });

        break;
      }
      case "unstarted": {
        setWorkViewNavigation({
          viewName: PayoutsViewsNames.UnstartedContributions,
          taskViewMode: TasksViewModes.UnstartedContributions,
        });
        break;
      }
      case "in-progress": {
        setWorkViewNavigation({
          viewName: PayoutsViewsNames.InProgressContributions,
          taskViewMode: TasksViewModes.InProgressContributions,
        });
        break;
      }
      case "awaiting-reward-approval": {
        setWorkViewNavigation({
          viewName: PayoutsViewsNames.AwitingRewardApprovalContributions,
          taskViewMode: TasksViewModes.AwitingRewardApprovalContributions,
        });
        break;
      }
      case "approved-for-payment": {
        setWorkViewNavigation({
          viewName: PayoutsViewsNames.ApprovedForPaymentContributions,
          taskViewMode: TasksViewModes.ApprovedForPaymentContributions,
        });
        break;
      }
      case "paid": {
        setWorkViewNavigation({
          viewName: PayoutsViewsNames.PaidContributions,
          taskViewMode: TasksViewModes.PaidContributions,
        });
        break;
      }
      default: {
        if (parentSlug === "view") {
          // const context = query.get("context") ?? undefined;
          if (slug) {
            setWorkViewNavigation({
              viewName: GeneralViewsNames.ViewEntity,
              taskViewMode: TasksViewModes.CustomView,
              entity: {
                containerId: slug,
                containerType: ContainerTypes.CUSTOM_VIEW,
              },
            });
          } else {
            setWorkViewNavigation({
              viewName: GeneralViewsNames.NewCustomView,
              taskViewMode: TasksViewModes.NewCustomView,
            });
          }
        }
        if (parentSlug === "sprint" && slug) {
          return;
        }
        break;
      }
    }
  }, [slug, groupContext]);

  if (!workViewNavigation) {
    return slug ? (
      <PrivatePage
        baseSlug={splitPathname[1]}
        containerType={ContainerTypes.CUSTOM_VIEW}
        containerId={slug}
      />
    ) : (
      <></>
    );
  }

  return (
    <NavigationContext
      viewName={workViewNavigation.viewName}
      taskViewMode={workViewNavigation.taskViewMode}
      containerId={workViewNavigation.entity?.containerId}
      containerType={workViewNavigation.entity?.containerType}
    />
  );
};

export default TasksViewRouter;
