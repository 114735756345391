import React from "react";
import {
  NavigationChunk,
  UserRole,
  ViewNames,
  WorkActivityTypes,
} from "utilities/types";
import styles from "../styles/activity.module.scss";
import Button, { ButtonTypes } from "components/Button";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal } from "antd";
import store from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import { deleteWorkActivity } from "modules/taskService";
import { MetadataTypeIndicator } from "./MetadataTypeIndicator";
import { MetadataConditionalText } from "./MetadataConditionalText";
import { IWorkActivityProps } from "../types";
import navigationApi from "clientApi/navigationApi";
import ClaimRequest from "./ClaimRequest";

const { confirm } = Modal;

const ActivityContent: React.FC<IWorkActivityProps> = (props) => {
  const { author } = props.workActivity;
  const isSameUser = author?.id === props.user?.id;
  const userRole = store.getState().client.roleType;
  const isOwner = userRole === UserRole.OWNER;

  if (
    props.workActivity.type === WorkActivityTypes.METADATA_CHANGE ||
    props.workActivity.type === WorkActivityTypes.WORK_ITEM_CREATED ||
    props.workActivity.type === WorkActivityTypes.REVIEW_REQUEST ||
    props.workActivity.type === WorkActivityTypes.REVIEW_REMINDER ||
    props.workActivity.type === WorkActivityTypes.REVIEW_REQUEST_ACCEPTED
  )
    return (
      <TaskWrapper
        headerIcon={<MetadataTypeIndicator {...props} />}
        isLastActivityEntry={
          props.isLastActivityEntry ? props.isLastActivityEntry : false
        }
        type={props.workActivity.type}
      >
        <div className={styles.TaskComment__main__header}>
          <MetadataConditionalText {...props} />
        </div>
      </TaskWrapper>
    );

  if (props.workActivity.type === WorkActivityTypes.CLAIM_REQUEST) {
    return (
      <ClaimRequest
        workActivity={props.workActivity}
        workItem={props.workItem}
      />
    );
  }

  return (
    <TaskWrapper
      headerIcon={<MetadataTypeIndicator {...props} />}
      isLastActivityEntry={
        props.isLastActivityEntry ? props.isLastActivityEntry : false
      }
      type={props.workActivity.type}
    >
      <div style={{ paddingTop: "3px" }}>
        <div style={{ flexGrow: 1 }}>
          {props.type === WorkActivityTypes.COMMENT ? (
            <></>
          ) : (
            <span className={styles.TaskComment__main__header__caption}>
              {props.mentionDeleted ? (
                <span className="caption secondary">
                  Mentioned this, but it was deleted
                </span>
              ) : (
                <>
                  <span className="caption secondary">
                    Mentioned this in&nbsp;
                  </span>
                  <span
                    className="caption"
                    onClick={(e) => {
                      const navigationChunk: NavigationChunk = {
                        viewName: ViewNames.Detail,
                        entity: {
                          containerId: props.linkingContainer.containerId,
                          containerType: props.linkingContainer.containerType,
                        },
                      };
                      if (e.shiftKey) {
                        navigationApi.openSplitViewNavigationChunk(
                          navigationChunk
                        );
                        return;
                      }
                      navigationApi.navigateMain(navigationChunk);
                      return;
                    }}
                    style={{ fontWeight: 600, cursor: "pointer" }}
                  >
                    {props.mentionContainerLabel}
                  </span>
                </>
              )}
            </span>
          )}
        </div>
      </div>
      <div className={styles.TaskComment__main__body}>
        {props.children}
        {(isSameUser || isOwner) &&
          props.workActivity.type === WorkActivityTypes.COMMENT && (
            <Dropdown
              overlay={settingsMenu({
                setcommentIsBeingEdited: props.setcommentIsBeingEdited,
                setListIsEditing: props.setisEditingComment,
                blocksState: props.workActivity.blocksState,
                workActivity: props.workActivity,
                user: props.user,
                task: props.workItem,
                isOwner,
                isSameUser,
              })}
              disabled={
                props.isEditingComment ||
                props.isCreatingNewComment ||
                !props.canStartEdit
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button
                style={{ marginTop: "-4px" }}
                buttonType={ButtonTypes.LINK}
                icon={<MoreHorizontalDots alt="More options menu" />}
              />
            </Dropdown>
          )}
      </div>
    </TaskWrapper>
  );
};

const TaskWrapper: React.FC<{
  headerIcon: JSX.Element;
  isLastActivityEntry: boolean;
  type: WorkActivityTypes;
}> = (props) => {
  return (
    <div
      className={`${styles.TaskComment} ${
        props.type !== WorkActivityTypes.COMMENT
          ? styles.TaskComment__activity
          : "comment-only"
      }`}
    >
      {/* <div className={styles.TaskComment__pre}>{props.headerIcon}</div> */}
      <div className={styles.TaskComment__main}>{props.children}</div>
    </div>
  );
};

const settingsMenu = (options: any) => {
  return (
    <Menu>
      {options.isSameUser && (
        <Menu.Item
          key="0"
          onClick={() => {
            if (options.setcommentIsBeingEdited)
              options.setcommentIsBeingEdited(true);
            if (options.setListIsEditing) options.setListIsEditing(true);
            if (options.blocksState)
              store.dispatch({
                type: "SET_BLOCK_STATE",
                destination: ChunkDestination.taskComments,
                blocksState: options.blocksState,
              });
          }}
        >
          Edit
        </Menu.Item>
      )}
      <Menu.Item key="1" onClick={() => showDeleteCommentConfirm(options)}>
        Delete...
      </Menu.Item>
    </Menu>
  );
};

const showDeleteCommentConfirm = (options: any) => {
  return confirm({
    title: `Are you sure you want to delete this comment?`,
    icon: <ExclamationCircleOutlined />,
    content: "",
    maskClosable: true,
    okText: "Delete",

    onOk() {
      deleteWorkActivity(options.workActivity, options.task);
    },
    onCancel() {},
  });
};

export default ActivityContent;
