import Button, { ButtonTypes } from "components/Button";
import Otp from "components/Otp";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";

const Email: React.FC = () => {
  const [showEmailChange, setshowEmailChange] = useState(false);
  const user = useSelector((store: ClarityStore) => store.user, shallowEqual);

  if ((!user?.email || user.email === "") && !showEmailChange) {
    return (
      <Button
        buttonType={ButtonTypes.LARGE_SECONDARY}
        onClick={() => setshowEmailChange(true)}
        style={{ width: "154px" }}
      >
        Add email address
      </Button>
    );
  }

  return <Otp />;
};

export default Email;
