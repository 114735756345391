export const abbreviatedDays = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];
export const abbreviatedMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const fullMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonday = (d: Date) => {
  let fullDate: Date = new Date(d);
  if (fullDate.getDay() === 0) {
    fullDate = new Date(fullDate.setDate(fullDate.getUTCDate() + 1));
  }
  const day = fullDate.getDay(),
    diff = fullDate.getDate() - day + (day === 0 ? -6 : 1);
  const mondayDate = new Date(fullDate.setDate(diff));
  mondayDate.setUTCHours(12, 0, 0, 0);
  return mondayDate;
};

export const getSunday = (d: Date) => {
  var lastday = new Date(d.setDate(d.getDate() - d.getDay() + 7));
  lastday.setUTCHours(12, 0, 0, 0);
  return lastday;
};
