import { axiosInstance } from "index";
import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { LOAD_RELATED_WORK, SET_BASE_GROUPS } from "store/actions";
import { workItemNrToId } from "store/reducers/workReducer";
import { useBaseSlug } from "store/reducers/workspaceReducer";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ContainerTypes, IProjectObj } from "utilities/types";
const { validate } = require("uuid");

export const useEntityLoader = (
  containerId?: string,
  containerType?: ContainerTypes
) => {
  const workDict = useShallowSelector((state) => state.work.dict);
  const baseSlug = useBaseSlug();

  const [workItem, setWorkItem] = useState<IProjectObj | null>(null);
  const fetchWork = useCallback(
    async (workNr: string) => {
      if (workDict) {
        if (typeof workNr === "string" && workNr.includes("-")) {
          const parsedGroupContainerId = workNr.split("-");

          const groupSlug = parsedGroupContainerId[0];
          const projectId = parsedGroupContainerId[1];

          if (!validate(workNr)) {
            const group = store.getState().groups.slugDict[groupSlug];
            if (group) {
              const work = workItemNrToId[group.id + projectId];
              if (workDict[work]) {
                setWorkItem({
                  ...workDict[work],
                });
                return;
              }
            }
          } else {
            const work = workDict[workNr];

            if (work) {
              setWorkItem({
                ...work,
              });
              return;
            }
          }
        } else {
          const projectId = workItemNrToId[workNr];
          if (projectId) {
            if (workDict[projectId]) {
              setWorkItem({
                ...workDict[projectId],
              });
              return;
            }
          }
        }
      }

      return await axiosInstance
        .get(`/api/project/${baseSlug}/${workNr}`)
        .then((res) => {
          const workItem = res.data.payload?.container;
          if (res && res.data.status === 1) {
            setWorkItem({
              ...workItem,
            });
            batch(() => {
              if (res.data.payload?.group) {
                store.dispatch({
                  type: SET_BASE_GROUPS,
                  groups: [res.data.payload?.group],
                });
              }
              store.dispatch({
                type: LOAD_RELATED_WORK,
                work: [workItem],
              });
            });
          }
          return res.data;
        })
        .catch((err) => console.error(err));
    },
    [containerId, containerType]
  );

  useEffect(() => {
    if (
      containerId &&
      containerType &&
      (containerType === ContainerTypes.WORK ||
        containerType === ContainerTypes.PROJECT ||
        containerType === ContainerTypes.TASK)
    ) {
      fetchWork(containerId);
    }
  }, [containerType, containerId]);

  return workItem;
};
