import React from "react";
import { Abilities, BaseType } from "utilities/types";
import styles from "./options.module.scss";
import { Radio } from "antd";
import { LinkTokenGate } from "clarity-ui/inviteLinkContainer/NewInviteLinkRow";
import OptionGroup from "clarity-ui/OptionGroup";
import { KeyOutlined } from "@ant-design/icons";
import Conditional from "components/Conditional";
import { useAbilityChecker } from "editor/utils/customHooks";

const visibilityOptions = [
  {
    visibility: BaseType.Public,
    title: "Public",
    hint: "The content of a public base is visible to anyone without joining.",
  },
  {
    visibility: BaseType.Private,
    title: "Private",
    hint: "The content of the base is only visible to members. In order to join, you must be invited or meet specific token requirements. If you set token requirements, then anyone who has the right tokens can join via your base's profile.",
  },
  {
    visibility: BaseType.Secret,
    title: "Secret",
    hint: `The content of the base is only visible to members. The only way to join is by an explicit invite. If someone tries to visit the base's profile, it will say "Base Not Found".`,
  },
];

const BaseVisibilitySelector: React.FC<{
  currentVisibility: BaseType;
  tokenGateId?: string | undefined;
  handleVisibilityChange: (type: BaseType) => void;
  onGateSelect: (id: string) => void;
  onGateRemove: () => void;
}> = ({
  tokenGateId,
  currentVisibility,
  handleVisibilityChange,
  onGateRemove,
  onGateSelect,
}): JSX.Element => {
  return (
    <div>
      <div className={styles.optionsContainer}>
        <h4 className="body2 bold mb-2">Base Privacy</h4>
        <div className={styles.visibilityOptions}>
          {visibilityOptions.map((option) => (
            <VisibilityOption
              key={option.visibility}
              option={option}
              currentVisibility={currentVisibility}
              handleVisibilityChange={handleVisibilityChange}
              onGateRemove={onGateRemove}
              onGateSelect={onGateSelect}
              tokenGateId={tokenGateId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const VisibilityOption: React.FC<{
  option: { visibility: BaseType; title: string; hint: string };
  currentVisibility: BaseType;
  handleVisibilityChange: (type: BaseType) => void;
  onGateSelect: (id: string) => void;
  onGateRemove: () => void;
  tokenGateId?: string | undefined;
}> = ({
  option,
  currentVisibility,
  tokenGateId,
  handleVisibilityChange,
  onGateRemove,
  onGateSelect,
}) => {
  const canManageBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });

  return (
    <div
      className={styles.visibilityOption}
      onClick={() => {
        if (!canManageBaseSettings) return;
        if (currentVisibility !== option.visibility)
          handleVisibilityChange(option.visibility);
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Radio
          checked={option.visibility === currentVisibility}
          disabled={!canManageBaseSettings}
        >
          <div className="caption bold primary">{option.title}</div>
          <div className="caption regular secondary">{option.hint}</div>
        </Radio>
      </div>
      <Conditional
        on={
          option.visibility === BaseType.Private &&
          currentVisibility === option.visibility
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: " 8px 8px 8px 12px",
          }}
        >
          <OptionGroup
            title="Token Gate"
            description="User will need to have these tokens to join this base"
            hideValue={true}
            value={""}
            icon={<KeyOutlined />}
          />
          <LinkTokenGate
            onSelect={onGateSelect}
            gateId={tokenGateId}
            noUpdate={!canManageBaseSettings}
            onRemove={() => {
              onGateRemove();
            }}
          />
        </div>
      </Conditional>
    </div>
  );
};

export default BaseVisibilitySelector;
