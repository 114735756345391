import React, { useState } from "react";
import { Popover } from "antd";
import Button, { ButtonTypes } from "components/Button";

import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { handleCreateLink } from "editor/utils/specificActions/textUpdateActions";

const SharedTooltip: React.FC<any> = (props) => {
  const selection = document.getSelection();
  const range =
    selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
  const coord = getPopupCoordinates(props);
  if (range) {
    return (
      <span
        style={{
          position: "absolute",
          left: coord ? coord.x : 0 + "px",
          top: coord ? coord.y : 0 + "px",
          zIndex: 999,
        }}
      >
        <Popover
          overlayClassName="tooltip-popup"
          getPopupContainer={() => {
            if (props.parentEl) return props.parentEl;
            else return document.body;
          }}
          getTooltipContainer={() => {
            if (props.parentEl) return props.parentEl;
            else return document.body;
          }}
          visible={true}
          content={<ToolTip {...props} />}
        ></Popover>
      </span>
    );
  } else return <></>;
};

export const getPopupCoordinates = (props: any) => {
  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  if (selection && selection.rangeCount > 0) {
    const range: Range = selection.getRangeAt(0);
    let rangeX = range.getBoundingClientRect().x;
    let rangeR = range.getBoundingClientRect().width;
    let rangeY = range.getBoundingClientRect().top;
    let componentX = props.parentEl?.getBoundingClientRect().x;
    let componentY = props.parentEl?.getBoundingClientRect().y;
    const computed = window
      .getComputedStyle(props.parentEl)
      .getPropertyValue("margin-top");
    const marginString = computed.substring(0, computed.length - 2);
    const margin = Number(marginString);

    x = rangeX === 0 ? 10 : rangeX - componentX + rangeR / 2;
    y = rangeY === 0 ? 10 : rangeY + margin + 20 - componentY;
    return { x, y };
  }
};

const ToolTip: React.FC<any> = (props) => {
  const [check, setCheck] = useState(false);
  const selection = document.getSelection();

  const element = selection?.focusNode?.parentElement;

  const handleNativeStylingSave = (event: any, type: string) => {
    document.execCommand(type, false);
  };

  return (
    <div className="style-selector">
      <Button
        className="no-border"
        type={
          element && element?.closest("b")
            ? ButtonTypes.PRIMARY
            : ButtonTypes.DEFAULT
        }
        disabled={element && element?.closest("mention") ? true : false}
        icon={<BoldOutlined />}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          handleNativeStylingSave(e, "bold");
          setCheck(!check);
        }}
      />
      <Button
        className="no-border"
        icon={<ItalicOutlined />}
        disabled={element && element?.closest("mention") ? true : false}
        type={
          element && element?.closest("i")
            ? ButtonTypes.PRIMARY
            : ButtonTypes.DEFAULT
        }
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          handleNativeStylingSave(e, "italic");
          setCheck(!check);
        }}
      />
      <Button
        className="no-border"
        icon={<StrikethroughOutlined />}
        disabled={element && element?.closest("mention") ? true : false}
        type={
          element && element?.closest("strike")
            ? ButtonTypes.PRIMARY
            : ButtonTypes.DEFAULT
        }
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          handleNativeStylingSave(e, "strikethrough");
          setCheck(!check);
        }}
      />

      <Button
        className="no-border"
        icon={<LinkOutlined />}
        disabled={element && element?.closest("mention") ? true : false}
        type={
          element && element?.closest("a")
            ? ButtonTypes.PRIMARY
            : ButtonTypes.DEFAULT
        }
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          handleCreateLink(e);
          setCheck(!check);
        }}
      />
    </div>
  );
};

export default SharedTooltip;
