import EmptyState from "clarity-ui/EmptyState";
import WorkItem from "clarity-ui/WorkItem";
import navigationApi from "clientApi/navigationApi";
import { useGroupContextInSplit } from "editor/utils/customHooks";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoSizer,
  List,
  ListRowProps,
  ListRowRenderer,
} from "react-virtualized";
import { usePaneId } from "store/reducers/filterReducer";
import { KeyCodes } from "utilities/lineUtilities";
import { ChunkDestination } from "utilities/stateTypes";
import { ContainerTypes, ViewNames } from "utilities/types";
import GroupHeader, {
  EmptyGroupElement,
} from "../workTwo/workSection/GroupHeader";
import { useSelectActions } from "./actions";
import { IWorkViewConfig } from "./types";
import {
  IGroupEmptyEntry,
  IGroupHeader,
  IWorkViewStoreContext,
  WorkViewStoreContext,
} from "./WorkView";

const ListView: React.FC<{
  fitleredElements: (string | IGroupHeader | IGroupEmptyEntry)[];
  viewConfig: IWorkViewConfig | null;
}> = ({ fitleredElements, viewConfig }) => {
  const _listRef = useRef(null);
  const viewRef = useRef<HTMLDivElement | null>(null);
  const workViewStore = useContext(WorkViewStoreContext);
  const paneId = usePaneId();
  const groupContext = useGroupContextInSplit(paneId);
  const { selectWork, selectRange, selectAll, deselectAll } =
    useSelectActions(workViewStore);
  const handleTaskClick = (id: string) => {
    return navigationApi.contextBasedNavigate({
      currentPane: ChunkDestination.peek,
      navigationChunk: {
        viewName: ViewNames.Detail,
        entity: {
          containerId: id,
          containerType: ContainerTypes.WORK,
        },
      },
    });
  };
  const actions = useMemo(
    () => ({
      handleTaskClick,
      selectWork,
      selectRange,
    }),
    []
  );

  const rowRenderer: ListRowRenderer = (data: ListRowProps) => {
    const element = fitleredElements[data.index];
    return (
      <span style={data.style} key={data.key}>
        {typeof element === "string" ? (
          <WorkItemWrapper
            workViewStore={workViewStore}
            data={data}
            itemId={element}
            key={data.key}
            actions={actions}
          />
        ) : (
          <>
            {"isEmptyElement" in element ? (
              <EmptyGroupElement {...element} />
            ) : (
              <GroupHeader
                count={element.count}
                groupId={element.groupId}
                baseGroupId={groupContext}
                presetTaskMetadata={element.presetTaskMetadata}
                type={element.type}
                mode={viewConfig?.viewMode}
              />
            )}
          </>
        )}
      </span>
    );
  };

  useEffect(() => {
    viewRef.current?.focus();
  }, [fitleredElements]);

  if (fitleredElements.length === 0)
    return (
      <EmptyState
        heading={viewConfig?.description ?? ""}
        caption={viewConfig?.emptyState ?? ""}
      />
    );

  return (
    <div
      ref={viewRef}
      style={{
        height: "100%",
        width: "100%",
      }}
      tabIndex={0}
      onKeyDown={(event) => {
        if ((event.ctrlKey || event.metaKey) && event.keyCode === KeyCodes.a) {
          event.preventDefault();
          event.stopPropagation();
          selectAll();
        }
        if (event.keyCode === KeyCodes.esc) {
          event.preventDefault();
          event.stopPropagation();
          deselectAll();
        }
      }}
    >
      <AutoSizer>
        {({ width, height }) => {
          return (
            <>
              <List
                ref={(ref: any) => {
                  _listRef.current = ref;
                }}
                width={width}
                autoContainerWidth={true}
                height={height}
                rowCount={fitleredElements.length}
                rowHeight={45}
                rowRenderer={rowRenderer}
                overscanRowCount={5}
              />
            </>
          );
        }}
      </AutoSizer>
    </div>
  );
};

const WorkItemWrapper: React.FC<{
  workViewStore: IWorkViewStoreContext;
  data: ListRowProps;
  itemId: string;
  actions: {
    handleTaskClick: (id: string) => void;
    selectWork: (data: { taskId: string; index: number }) => void;
    selectRange: (end: { index: number; id: string }) => void;
  };
}> = ({ workViewStore, data, actions, itemId }) => {
  const [selected, setselected] = useState(false);
  useLayoutEffect(() => {
    const sub = workViewStore.selectedDict.subscribe((value) => {
      setselected(value[itemId] ?? false);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [itemId]);
  return (
    <WorkItem
      itemId={itemId}
      index={data.index}
      key={data.key}
      actions={actions}
      isSelected={selected}
      canSelect={true}
      selectedTasksSubject={workViewStore.selectedItems}
    />
  );
};

export default ListView;
