import navigationApi from "clientApi/navigationApi";
import Button, { ButtonTypes, IconSides } from "components/Button";
import React from "react";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import styles from "./navigationSection/navigationSection.module.scss";

const NavigationSection: React.FC<{
  routes: any[];
  paneId: ChunkDestination;
}> = ({ routes, paneId }) => {
  const subRoutesParams = useShallowSelector(
    (store) => store.topNav[paneId]?.subRoutesParams
  );
  const navigate = (e: React.MouseEvent, route: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (route.action) {
      route.action();
      return;
    }

    const config = route.secondaryViewConfig();

    navigationApi.contextBasedNavigate({
      currentPane: paneId,
      shiftKey: e.shiftKey,
      navigationChunk: {
        ...config,
      },
    });
  };

  const isActive = (route: any) => {
    if (route.activeOn && route.activeOn.includes(subRoutesParams?.activeKey))
      return true;
    return subRoutesParams?.activeKey === route.key;
  };

  return (
    <div className={styles.row}>
      {routes.map((tab) => (
        <React.Fragment key={tab.key}>
          <div className={styles.tabContainer}>
            {tab.name() === "Details" || tab.name() === "Subtasks" ? (
              <div
                className={`${styles.customButton} ${
                  isActive(tab) ? styles.tabAcitive : ""
                }`}
                onClick={(e: React.MouseEvent) => navigate(e, tab)}
              >
                {tab.name()}
              </div>
            ) : (
              <div
                className={`${styles.tab} ${
                  isActive(tab) ? styles.active : ""
                }`}
              >
                <Button
                  buttonType={ButtonTypes.LINK}
                  iconSide={tab?.icon ? IconSides.LEFT : undefined}
                  icon={tab?.icon && tab.icon}
                  className={isActive(tab) ? styles.activeButton : undefined}
                  labelStyle={{
                    color: "$text-secondary",
                    marginLeft: "4px",
                  }}
                  onClick={(e: React.MouseEvent) => navigate(e, tab)}
                  iconStyle={{ fontSize: "24px" }}
                  additionalIconClass={styles.ButtonIcon}
                >
                  {tab.name()}
                </Button>
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default NavigationSection;
