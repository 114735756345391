import React from "react";
import { ChunkDestination } from "utilities/stateTypes";
import styles from "./filtersBar.module.scss";

const TopNavFiltersBar: React.FC<{ paneId: ChunkDestination }> = ({
  children,
  paneId,
}) => {
  return (
    <div className={styles.filterRow}>
      {/* <div className={`content w-100 ${styles.contentData}`}>{children}</div> */}
      <div className={`w-100 ${styles.contentData}`}>{children}</div>
    </div>
  );
};

export default TopNavFiltersBar;
