import workApi from "clientApi/workApi";
import Button, { ButtonTypes } from "components/Button";
import { useShallowSelector } from "utilities/hooks";
import { AuditOutlined } from "@ant-design/icons";
import { DeltaAction, IWorkActivity, WorkActivityTypes } from "utilities/types";
import ActionCTAContent from "./ActionCTAContent";
import { useEffect, useState } from "react";
import { useUser } from "store/reducers/userReducer";
import moment from "moment";
import store from "store/storeExporter";
import { SET_NUDGE_SENT_WORK_ITEM } from "store/actions";
import Conditional from "components/Conditional";

const RequestReviewCTA: React.FC<{ workItemId: string }> = ({ workItemId }) => {
  const storeData = useShallowSelector((store) => {
    const workItem = store.work.dict[workItemId];
    return {
      requests: workItem?.requests,
      type: workItem?.workType,
      assigneeId: workItem?.assigneeId,
      reviewerId: workItem?.reviewerId,
      userId: store.user?.id,
    };
  });

  if (!storeData.type) return <></>;

  if (
    storeData.userId &&
    storeData.userId !== storeData.assigneeId &&
    storeData.userId !== storeData.reviewerId
  )
    return <></>;

  if (
    storeData.reviewerId === storeData.userId &&
    storeData.reviewerId !== storeData.assigneeId &&
    !storeData.requests?.activeReviewRequest
  )
    return <></>;

  const buttonDisabled = !workApi.canRequestReview(workItemId);

  const getTitle = () => {
    if (storeData.requests?.activeReviewRequest) return "Review requested";
    else return "Request a review of your work";
  };

  const getDescription = () => {
    if (storeData.requests?.activeReviewRequest)
      return "A review has been requested on this task. The reviewer has been notified.";
    else return "Submit a request for the reviewer to check out your work";
  };

  const title = getTitle();

  const description = getDescription();

  return (
    <>
      <ActionCTAContent
        title={title}
        description={description}
        icon={<AuditOutlined />}
        accentColor={
          storeData.requests?.activeReviewRequest
            ? "rgba(71, 128, 81, 0.95)"
            : undefined
        }
        border={storeData.requests?.activeReviewRequest ? true : false}
      >
        <Conditional on={!storeData.requests?.activeReviewRequest}>
          <Button
            onClick={() => {
              workApi.requestReview(workItemId);
            }}
            style={{
              minWidth: "91px",
            }}
            disabled={buttonDisabled}
            buttonType={ButtonTypes.PRIMARY}
          >
            Request review
          </Button>
        </Conditional>
        <Conditional on={storeData.requests?.activeReviewRequest}>
          <ButtonSplitter workItemId={workItemId} />
        </Conditional>
      </ActionCTAContent>
    </>
  );
};

const ButtonSplitter: React.FC<{ workItemId: string }> = ({ workItemId }) => {
  const user = useUser();
  const [activeWorkActivity, setactiveWorkActivity] = useState<string | null>(
    null
  );

  const workActivities = useShallowSelector(
    (store) => store.work.dict[workItemId]?.workActivities
  );
  const workActivity = useShallowSelector((store) =>
    activeWorkActivity ? store.work.workActivities[activeWorkActivity] : null
  );
  const assigneeId = useShallowSelector(
    (store) => store.work.dict[workItemId]?.assigneeId
  );
  const reviewerId = useShallowSelector(
    (store) => store.work.dict[workItemId]?.reviewerId
  );

  useEffect(() => {
    const activitiesDict = store.getState().work.workActivities;
    if (!workActivities) return;

    for (var i = workActivities.length - 1; i >= 0; i--) {
      const el = workActivities[i];
      const activityobj = activitiesDict[el];
      if (
        activityobj.type === WorkActivityTypes.REVIEW_REQUEST &&
        activityobj.delta?.action === DeltaAction.PENDING
      ) {
        setactiveWorkActivity(el);
        break;
      }
    }
  }, [workActivities]);

  if (!workActivity) return <></>;

  if (
    workActivity.delta?.action === DeltaAction.PENDING &&
    user.id === reviewerId
  ) {
    return (
      <div>
        <Button
          type={ButtonTypes.PRIMARY}
          style={{
            background: "#478051",
            color: "white",
          }}
          onClick={() => {
            workApi.acceptReview(workActivity);
          }}
        >
          Mark reviewed
        </Button>
      </div>
    );
  }

  if (
    workActivity.delta?.action === DeltaAction.PENDING &&
    user.id === assigneeId &&
    reviewerId
  ) {
    return <NudgeButton workActivity={workActivity} workItemId={workItemId} />;
  }

  return <></>;
};

const NudgeButton: React.FC<{
  workActivity: IWorkActivity;
  workItemId: string;
}> = ({ workActivity, workItemId }) => {
  const [loading, setLoading] = useState(false);
  const lastSentNudge = useShallowSelector((state) => {
    return state.client.workItemReviewNudges[workItemId];
  });

  useEffect(() => {
    if (!lastSentNudge) {
      setLoading(false);
    } else {
      const now = moment();
      const lastSentNudgeMoment = moment(lastSentNudge);

      var duration = moment.duration(now.diff(lastSentNudgeMoment));
      var minutes = duration.asMinutes();

      if (minutes > 10) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }, [lastSentNudge]);

  return (
    <Button
      disabled={loading}
      style={{
        minWidth: "126px",
      }}
      onClick={() => {
        setLoading(true);
        workApi.remindReview(workActivity.id, workActivity.taskId).then(() => {
          store.dispatch({
            type: SET_NUDGE_SENT_WORK_ITEM,
            params: {
              workId: workActivity.taskId,
              date: Date.now(),
            },
          });
        });
      }}
    >
      Nudge
    </Button>
  );
};

export default RequestReviewCTA;
