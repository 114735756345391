import { ContainerTypes, ITokenGate, PermissionTypes } from "utilities/types";
import store from "store/storeExporter";
import {
  SET_BASE_TOKEN_GATES,
  SET_SHARING_AND_PERMS_MODAL,
  UPDATE_TOKEN_GATES,
} from "store/actions";
import { batch } from "react-redux";
import { userApi } from "./userApi";
import { axiosInstance } from "index";
import { permissionsApi } from "./permissionsApi";

class TokenGateApi {
  async getBaseTokenGates(baseId: string) {
    return axiosInstance
      .get(`/api/token-gate/gates`, {
        params: {
          baseId,
        },
      })
      .then((res) => {
        tokenGateApi.setExistingTokenGate(res.data);
      });
  }

  addNewTokenGate(
    tokenGate: Partial<ITokenGate>,
    insertFunction: any,
    containerId?: string,
    containerType?: ContainerTypes
  ) {
    return axiosInstance
      .post("/api/token-gate", {
        name: tokenGate.name,
        description: "",
        baseId: store.getState().workspace.id,
        tokenRequirements: tokenGate.tokenRequirements,
        containerId: containerId,
        containerType: containerType,
      })
      .then((res) => {
        const data = res.data;
        if (data) {
          this.updateStoreTokenGate({
            id: data.id,
            type: "add",
            delta: data,
          });
          if (insertFunction) insertFunction(res.data.id);
        }
      });
  }

  setExistingTokenGate(tokenGates: ITokenGate[]) {
    store.dispatch({
      type: SET_BASE_TOKEN_GATES,
      param: {
        tokenGates,
      },
    });
  }

  updateStoreTokenGate(param: {
    id: string;
    type: "add" | "delete" | "update";
    delta: Partial<ITokenGate>;
  }) {
    store.dispatch({
      type: UPDATE_TOKEN_GATES,
      param,
    });
  }

  setAssignedGate(tokenGate: ITokenGate, gateLevel: PermissionTypes) {
    batch(() => {
      this.updateStoreTokenGate({
        id: tokenGate.id,
        type: "add",
        delta: tokenGate,
      });
      const sharingModalState = store.getState().client.sharingAndPermsModal;
      sharingModalState.gateId = tokenGate.id;
      sharingModalState.gateLevel = gateLevel
        ? gateLevel
        : PermissionTypes.SUGGEST;
      store.dispatch({
        type: SET_SHARING_AND_PERMS_MODAL,
        ...sharingModalState,
      });
    });
  }

  assignGateToContainer(assignProps: {
    containerId: string;
    containerType: ContainerTypes;
    gateId?: string;
  }) {
    const { containerId, containerType, gateId } = assignProps;
    const state = store.getState();
    axiosInstance
      .post("/api/container/gates/token-gate", {
        baseId: state.workspace.id,
        containerId,
        containerType,
        gateId: gateId,
      })
      .then((res) => {
        const sharingModalState = store.getState().client.sharingAndPermsModal;
        sharingModalState.gateId = gateId;
        store.dispatch({
          type: SET_SHARING_AND_PERMS_MODAL,
          ...sharingModalState,
        });
      });
  }

  redeemGate(
    gateId: string,
    userId: string,
    containerId: string,
    containerType: ContainerTypes,
    baseId: string
  ) {
    return axiosInstance
      .post("/api/token-gate/reedem", {
        userId,
        gateId: gateId,
        containerId,
        containerType,
        baseId: baseId,
        skipAuthRefresh: true,
      })
      .then((e) => {
        userApi.resetUserWithBaseSelected();
      })
      .catch(async (e) => {
        return await this.getTokenGateData(containerId, containerType, baseId);
      });
  }

  updateTokenGate(
    tokenGateId: string,
    change: {
      containerId?: string;
      containerType?: ContainerTypes;
      update?: Partial<ITokenGate>;
      gateLevel?: PermissionTypes;
    }
  ) {
    if (change.update) {
      this.updateStoreTokenGate({
        id: tokenGateId,
        type: "update",
        delta: change.update,
      });
    }
    axiosInstance
      .post("/api/token-gate/update", {
        tokenGateId,
        containerId: change.containerId,
        containerType: change.containerType,
        update: change.update,
        gateLevel: change.gateLevel,
        baseId: store.getState().workspace.id,
      })
      .then((res) => {});
  }

  async deleteTokenGate(tokenGateId: string) {
    try {
      await axiosInstance.delete("/api/token-gate", {
        data: { tokenGateId },
      });
      this.updateStoreTokenGate({
        id: tokenGateId,
        type: "delete",
        delta: {},
      });
    } catch (e) {
      throw e;
    }
  }

  async getTokenGateData(
    containerId: string,
    containerType: ContainerTypes,
    baseId: string
  ) {
    const gateData = await axiosInstance.get("/api/container/gates", {
      params: {
        containerId,
        containerType,
        baseId,
      },
    });

    if (gateData.data)
      permissionsApi.addPermission({
        containerId,
        containerType,
        accessLevel: gateData.data.accessLevel,
      });
  }
}

export const tokenGateApi = new TokenGateApi();
