import { AimOutlined } from "@ant-design/icons";
import DueDateDisplay from "clarity-ui/DueDateDisplay";
import UserDisplay from "clarity-ui/UserDisplay";
import navigationApi from "clientApi/navigationApi";
import Conditional from "components/Conditional";
import { locationSubject } from "components/LocationListener";
import { getHtml } from "editor/utils/blockValueHelpers";
import { sortByMetadata } from "modules/taskService";
import React, { useCallback, useEffect, useState } from "react";
import { useInitiativeIds, useWork } from "store/reducers/workReducer";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { getCorrectLink } from "utilities/linkUtilities";
import { ChunkDestination } from "utilities/stateTypes";
import { stripHtml } from "utilities/stringUtilities";
import { ContainerTypes, OrderTypes, ViewNames } from "utilities/types";

const ActiveGoals: React.FC<{ paneId: ChunkDestination; groupId?: string }> = ({
  paneId,
  groupId,
}) => {
  const activeGoalIds = useActiveGoals(groupId);
  return (
    <div className="homeWidget">
      <h4 className="h4 bold primary homeWidget__title">Current Goals</h4>
      <Conditional on={activeGoalIds.length === 0}>
        <span className="disabled">
          In progress goals will show here automatically
        </span>
      </Conditional>
      <Conditional on={activeGoalIds.length > 0}>
        <>
          {activeGoalIds.map((id) => (
            <GoalItem key={id} id={id} paneId={paneId} />
          ))}
        </>
      </Conditional>
    </div>
  );
};

const GoalItem: React.FC<{ id: string; paneId: ChunkDestination }> = ({
  id,
  paneId,
}) => {
  const goal = useShallowSelector((store) => store.work.dict[id]);
  const navigate = useCallback(
    (e: React.MouseEvent) => {
      const navigateByUrl = () => {
        const base = store.getState().workspace;
        locationSubject.next(getCorrectLink(base, goal));
      };
      const openInModal = () => {
        navigationApi.openPeekView({
          viewName: ViewNames.Detail,
          entity: {
            containerId: id,
            containerType: ContainerTypes.WORK,
          },
        });
      };
      const navigateInSplit = () => {
        navigationApi.openSplitView({
          viewName: ViewNames.Detail,
          containerId: id,
          containerType: ContainerTypes.WORK,
        });
      };

      if (paneId === ChunkDestination.secondary) {
        if (e.shiftKey) navigateByUrl();
        else openInModal();
      } else {
        if (e.shiftKey) navigateInSplit();
        else openInModal();
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [goal?.projectId]
  );
  if (!goal) return <></>;
  return (
    <div className="homeGoalItem homeWidget__item">
      <div className="homeGoalItem__head">
        <span className="homeGoalItem__head__icon">
          <AimOutlined className="secondary" />
        </span>
        <span
          className="body medium primary underlineHover homeGoalItem__head__title"
          onClick={navigate}
        >
          {stripHtml(getHtml(goal.nameValue))}
        </span>
      </div>
      <div className="homeGoalItem__tail">
        <Conditional on={goal.dueDate}>
          <span className={"homeGoalItem__tail__initiativeDueDate"}>
            <DueDateDisplay
              dueDate={goal.dueDate}
              isClosed={goal.isClosed}
              editId={undefined}
              extraClass={
                "homeGoalItem__tail__initiativeDueDate__dueDate medium caption"
              }
            />
          </span>
        </Conditional>
        <span className={"homeGoalItem__tail__initiativeLead"}>
          <UserDisplay
            id={goal.assigneeId}
            hideName={true}
            avatarSize={22}
            hideTooltip={true}
          />
        </span>
      </div>
    </div>
  );
};

export const useActiveGoals = (groupId?: string) => {
  const [activeGoalsIds, setactiveGoalsIds] = useState<string[]>([]);
  const initiatives = useInitiativeIds();
  const work = useWork();

  useEffect(() => {
    const activeInitiatives = initiatives.filter((id) => {
      const workItem = work.workDict[id];
      if (!workItem) return false;
      if (!workItem.statusId) return false;
      if (groupId && !workItem.groupIds?.includes(groupId)) return false;
      const status = work.statuses.dict[workItem.statusId];
      if (!status) return false;
      if (status.categoryId === "3") return true;
      return false;
    });
    sortByMetadata(OrderTypes.dueDate, activeInitiatives);
    if (JSON.stringify(activeInitiatives) !== JSON.stringify(activeGoalsIds))
      setactiveGoalsIds(activeInitiatives);
  }, [work.workDict, initiatives]);

  return activeGoalsIds;
};

export default ActiveGoals;
