import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./documentCellMain/documentCellMain.module.scss";
import {
  ContainerTypes,
  GeneralViewsNames,
  IUserObj,
  TasksViewModes,
} from "../utilities/types";
import { KeyCodes } from "../utilities/lineUtilities";
import { getHtml } from "editor/utils/blockValueHelpers";
import navigationApi from "clientApi/navigationApi";
import { ChunkDestination } from "utilities/stateTypes";

interface IDocumentCellMainProps {
  cell: any;
  paneId: ChunkDestination;
}

interface IMapStateToProps {
  base: any;
  user: IUserObj;
}

function WorkViewCellMain(props: IDocumentCellMainProps & IMapStateToProps) {
  const { cell, base, paneId } = props;

  return (
    <div className={styles.documentList__cell_main}>
      <div className={styles.documentList__cell_main__bodyContainer}>
        <Link
          to={`/${base.slug}/view/${cell.row.original.id}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigationApi.contextBasedNavigate({
              currentPane: paneId,
              shiftKey: e.shiftKey,
              navigationChunk: {
                viewName: GeneralViewsNames.ViewEntity,
                taskViewMode: TasksViewModes.CustomView,
                entity: {
                  containerId: cell.row.original.id,
                  containerType: ContainerTypes.CUSTOM_VIEW,
                },
              },
            });
          }}
          onKeyDown={(e) => {
            if (e.keyCode === KeyCodes.enter) {
              navigationApi.contextBasedNavigate({
                currentPane: paneId,
                shiftKey: e.shiftKey,
                navigationChunk: {
                  viewName: GeneralViewsNames.ViewEntity,
                  taskViewMode: TasksViewModes.CustomView,
                  entity: {
                    containerId: cell.row.original.id,
                    containerType: ContainerTypes.CUSTOM_VIEW,
                  },
                },
              });
            }
          }}
        >
          {cell.row.original.nameValue ? (
            <span
              className={styles.documentList__cell_main__name}
              dangerouslySetInnerHTML={{
                __html: getHtml(cell.row.original.nameValue),
              }}
            ></span>
          ) : (
            <span className={styles.documentList__cell_main__name}>
              {cell.row.original.name}
            </span>
          )}
        </Link>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  base: state.workspace,
  user: state.user,
});

export default connect(mapStateToProps)(WorkViewCellMain);
