import React, { useContext, useEffect, useRef, useState } from "react";
import { CommandPaletteOption, CommandPaletteState } from "../helpers/types";
import styles from "../commandPalette.module.scss";
import {
  hideCommandPalette,
  paletteGetSelectedEntities,
} from "../helpers/actions";
import { batch } from "react-redux";
import { CommandPaletteContext, ContainerTypes } from "utilities/types";
import Checkbox from "antd/lib/checkbox/Checkbox";

const OptionRow: React.FC<{
  option: CommandPaletteOption;
  index: number;
  paletteContext: CommandPaletteContext;
}> = ({ option, index }) => {
  const paletteStateContext = useContext(CommandPaletteState);
  const [isActive, setisActive] = useState(false);
  const didMountRef = useRef(false);

  useEffect(() => {
    const sub = paletteStateContext.selectedItemIndex.subscribe(
      (activeIndex) => {
        setisActive(index === activeIndex);
      }
    );
    didMountRef.current = true;
    return () => {
      sub.unsubscribe();
    };
  }, [index]);

  return (
    <div
      style={
        option.disable && option.disable()
          ? { opacity: "0.3", cursor: "not-allowed" }
          : {}
      }
      onClick={(event) => {
        if (option.disable && option.disable()) return;
        event.stopPropagation();
        event.preventDefault();
        option.action(event);
        if (option.closeAfter) {
          hideCommandPalette();
        }
      }}
      className={
        isActive
          ? styles.listContainer__listItem +
            " " +
            styles.listContainer__listItem_selected +
            " selected"
          : styles.listContainer__listItem
      }
      data-index={index}
      key={index}
      onMouseEnter={() => {
        batch(() => {
          if (didMountRef.current) {
            paletteStateContext.selectedItemIndex.next(index);
            paletteStateContext.selectedItemName.next(option.name);
          }
        });
      }}
    >
      <RowContentHoc option={option}>
        <RowContent option={option} isActive={isActive} />
      </RowContentHoc>
    </div>
  );
};

const RowContent: React.FC<{
  option: CommandPaletteOption;
  isActive: boolean;
}> = ({ option, isActive }) => {
  return (
    <>
      {option.icon ? option.icon : <></>}
      {option.name}
      {isActive && <SelectedLabel />}
    </>
  );
};

const RowContentHoc: React.FC<{
  option: CommandPaletteOption;
}> = ({ children, option }) => {
  if (option.multiselect) {
    return (
      <Checkbox
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
        checked={option.checked ? option.checked() : false}
        disabled={option.disable && option.disable() ? true : false}
        onChange={(event) => {
          if (option.disable && option.disable()) return;
          event.preventDefault();
          option.action(event);
        }}
      >
        <span
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            height: "100%",
          }}
        >
          {children}
        </span>
      </Checkbox>
    );
  }
  return <>{children}</>;
};

const SelectedLabel: React.FC = () => {
  const { ids, type } = paletteGetSelectedEntities();

  if (type === ContainerTypes.WORK && ids.length > 1) {
    return (
      <span className={styles.listContainer__listItem__selectedLabel}>
        ({ids.length} selected)
      </span>
    );
  }
  return <></>;
};

export default OptionRow;
