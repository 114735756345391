import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import UserDisplay, { usernameSplitter } from "clarity-ui/UserDisplay";
import { userApi } from "clientApi/userApi";
import { getWorkAssigneeLabelByType } from "modules/taskService";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import { useShallowSelector } from "utilities/hooks";
import {
  CommandPaletteContext,
  ContainerTypes,
  WorkTypes,
} from "utilities/types";

const AssigneeRow: React.FC<{
  canEditEntity: boolean;
  assigneeId?: string | null;
  workType: WorkTypes;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ canEditEntity, assigneeId, workType, containerId, containerType }) => {
  const user = useShallowSelector((store) =>
    assigneeId ? store.members.dict[assigneeId] : undefined
  );
  const assigneeName =
    user?.id && usernameSplitter(userApi.getUsernameForUser(assigneeId));

  const handleAssigneeClick = () => {
    openCommandPalette(CommandPaletteContext.ASSIGNEE, {
      selectedItemIds: [containerId],
      slectedItemsType: containerType,
    });
  };

  return (
    <MetadataBlock
      title={getWorkAssigneeLabelByType(workType, true)}
      disabled={!canEditEntity}
      infoText={assigneeName || <span className="disabled"> Unassigned </span>}
      prefix={
        <UserDisplay
          id={assigneeId}
          borderColor="white"
          avatarSize={18}
          hideName={true}
        />
      }
      onClick={handleAssigneeClick}
    ></MetadataBlock>
  );
};

export default AssigneeRow;
