import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import styles from "../groups/groups.module.scss";
import { EditOutlined, IdcardOutlined } from "@ant-design/icons";
import ItemDescription from "clarity-ui/ItemDescription";
import Button, { ButtonTypes } from "components/Button";
import AddOrEditGroup from "./AddOrEditGroup";
import { Draggable } from "react-beautiful-dnd";
import SixDotImg from "icons/six-dot-handle.svg";

const GroupRow: React.FC<{
  id: string;
  canEdit?: boolean;
  canViewDetails?: boolean;
  index: number;
}> = ({ id, canEdit, canViewDetails, index }) => {
  const group = useSelector(
    (clarity: ClarityStore) => clarity.groups.dict[id],
    shallowEqual
  );
  const [isHovered, setisHovered] = useState(false);
  const [isEditing, setisEditing] = useState(false);
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
  });
  return (
    <Draggable
      // isDragDisabled={!canEditBaseSettings}
      key={id}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...provided.draggableProps}
        >
          <div
            onMouseEnter={() => setisHovered(true)}
            onMouseLeave={() => setisHovered(false)}
            className={styles.roleRow}
          >
            {false && (
              <div className={styles.grabHandleIconContainer}>
                <img
                  src={SixDotImg}
                  alt="Grab Handle"
                  className={
                    styles.grabHandleIcon +
                    " " +
                    (snapshot.isDragging ? styles.dragging : "")
                  }
                  {...provided.dragHandleProps}
                />
              </div>
            )}
            <ItemDescription
              noPadding={true}
              title={group.name}
              description={group.description}
              icon={<IdcardOutlined />}
            />
            {isHovered && canViewDetails && (
              <div
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Button
                  className={styles.tokenGateRowEditBtn}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setisEditing(true);
                  }}
                  buttonType={ButtonTypes.LINK}
                  icon={<EditOutlined />}
                />
              </div>
            )}
            {isEditing && (
              <AddOrEditGroup
                id={group.id}
                disabled={!canEdit}
                setdetailsOpen={setisEditing}
              />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default GroupRow;
