import BaseAvatar from "components/BaseAvatar";
import React, { useEffect, useRef } from "react";
import { SET_SHOW_BASE_AS_BREADCRUMB } from "store/actions";
import { useBase } from "store/reducers/workspaceReducer";
import store from "store/storeExporter";
import { SectionWidthModes } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import styles from "./baseTopnav.module.scss";

const BaseTopnavRow: React.FC<{
  paneId: ChunkDestination;
  sectionWidthMode?: SectionWidthModes;
}> = ({ paneId, sectionWidthMode }) => {
  const base = useBase();
  const element = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        store.dispatch({
          type: SET_SHOW_BASE_AS_BREADCRUMB,
          showBaseAsBreadcrumb: !entry.isIntersecting,
          paneId,
        });
      });
    };

    let observer = new IntersectionObserver(callback);

    if (element.current) observer.observe(element.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    return () => {
      store.dispatch({
        type: SET_SHOW_BASE_AS_BREADCRUMB,
        showBaseAsBreadcrumb: false,
        paneId,
      });
    };
  }, []);

  return (
    <div ref={element} className={styles.baseContainer}>
      <div>
        <BaseAvatar
          base={base.base}
          className={styles.baseAvatar}
          aviBorderStyles={{ borderRadius: "8px", display: "none" }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="h1 primary bold  mt-20">{base.base.name}</span>
        <span className="caption regular secondary  mt-8">{base.tagline}</span>
      </div>
    </div>
  );
};

export default BaseTopnavRow;
