import {
  SET_ACTIVE_WORKSPACE,
  CLEAR_WORKSPACE_DATA,
  LOAD_BASE_RESOURCES,
  SET_BASE_LABELS,
  ADD_BASE_LABEL,
  ADD_RESOURCE,
  LOAD_RELATED_RESOURCES,
  UPDATE_WORKSPACE,
  LOAD_INVITATION_LINKS,
  PATCH_INVITATION_LINK,
  UPDATE_HOME_OBJ,
  RELOAD_BASE,
} from "../actions";
import { setUser, socket } from "../../App";
import {
  IResourceObj,
  INotesObj,
  ContainerTypes,
  IWorkSectionObj,
  ILabelsObj,
  IInviteLink,
  HomeScreenObj,
  HomeSectionDict,
  IWorkspaceObj,
  HomeSectionRow,
  HomeSectionObj,
  BaseType,
} from "../../utilities/types";
import store, { joinedRooms } from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { useDispatch } from "react-redux";
import {
  updateSection,
  updateSectionPin,
  updateSectionPinsOrder,
} from "utilities/homeUtilities";
import { axiosInstance } from "index";
import notificationsApi from "clientApi/notificationsApi";

export interface ISetActiveWorkspaceActionParameters {
  type: string;
  workspace?: any;
  mentions?: any;
  comments?: any;
  mode?: boolean;
  documentId?: string;
  citations?: any;
  citation: any;
}
export interface IClearWorkspaceDataActionParameters {
  type: string;
}

export interface ResourceEntry {
  [id: string]: IResourceObj;
}

export interface LabelsEntry {
  [id: string]: ILabelsObj;
}

export interface SectionEntry {
  [id: string]: IWorkSectionObj;
}

export interface ILoadBaseResourcesActionParameters {
  type: string;
  resources: IResourceObj[];
}

export interface ILoadBaseNotesActionParameters {
  type: string;
  params: {
    notes: INotesObj[];
    allLoaded: boolean;
    startFrom: number;
    delta?: any;
    permissions?: any;
  };
}

export interface IAddNoteActionParameters {
  type: string;
  note: INotesObj;
}

export interface IPatchContainerParameters {
  type: string;
  patchContainer: {
    isWeekly?: boolean;
    container: {
      containerId: string;
      containerType: ContainerTypes;
    };
    patch: any;
  };
}

export interface IUpdateEntityPermission {
  docId: string;
  isPublicAccess: boolean;
}

interface IRemoveNoteActionParameters {
  type: string;
  noteId: string;
}

interface IAddMentionToDocument {
  type: string;
  documentId: string;
  block: any;
  options?: any;
}

interface IAddResource {
  type: string;
  resource: IResourceObj;
}

interface IAddLabelsToBase {
  type: string;
  labels: ILabelsObj[];
  label: ILabelsObj;
  labelPatch: ILabelsObj;
}

interface ILoadBaseInvitationLinks {
  type: string;
  invitationLinks: IInviteLink[];
}

interface IPatchInvitationLink {
  type: string;
  param: {
    type: "add" | "update" | "delete";
    delta: Partial<IInviteLink>;
    id: string;
  };
}

interface IPatchHomeScreen {
  home: HomeScreenObj;
}

interface InvitationLinkDict {
  [id: string]: IInviteLink;
}

export interface IWorkspaceState extends IWorkspaceObj {
  resources: ResourceEntry;
  invitationLinksDict: InvitationLinkDict;
  invitationLinksIds: string[];
  reloadNumber: number;
  labels: LabelsEntry;
}

const initialState: IWorkspaceState = {
  id: "",
  slug: "",
  name: "",
  tagline: "",
  labels: {} as LabelsEntry,
  resources: {} as ResourceEntry,
  rank: "0",
  integrations: null,
  showCycles: false,
  showWeekly: false,
  showContributions: false,
  showMilestones: false,
  invitationLinksDict: {} as InvitationLinkDict,
  invitationLinksIds: [],
  isPublic: false,
  memberCount: 0,
  ownerId: "",
  home: {
    about: "",
    sectionIds: [] as string[],
    sectionsDict: {} as HomeSectionDict,
  } as HomeScreenObj,
  reloadNumber: 0,
  type: BaseType.Secret,
  discordIntegration: false,
};

const workspaceReducer = (
  state: IWorkspaceState = initialState,
  action: ISetActiveWorkspaceActionParameters &
    IUpdateEntityPermission &
    ILoadBaseResourcesActionParameters &
    ILoadBaseNotesActionParameters &
    IAddNoteActionParameters &
    IPatchContainerParameters &
    IAddMentionToDocument &
    IRemoveNoteActionParameters &
    IAddLabelsToBase &
    IAddResource &
    ILoadBaseInvitationLinks &
    IPatchInvitationLink &
    IPatchHomeScreen
): IWorkspaceState => {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      if (action.workspace && action.workspace.id !== state.id) {
        const roomId = action.workspace?.id;
        const newRoomArray = [...joinedRooms.value];

        if (state.id && newRoomArray.includes(state.id)) {
          const index = newRoomArray.indexOf(state.id);
          if (index > -1) {
            newRoomArray.splice(index, 1);
            joinedRooms.next(newRoomArray);
          }
        }
        if (!newRoomArray.includes(roomId)) {
          newRoomArray.push(roomId);
          joinedRooms.next(newRoomArray);
        }

        if (state.id && action.workspace.id !== state.id) {
          socket.emit("disconnectFromRoom", { roomId: state.id });
        }
        setUser();
        const newState = { ...initialState, ...action.workspace };
        if (!newState.home) {
          newState.home = {
            about: "",
            sectionIds: [] as string[],
            sectionsDict: {} as HomeSectionDict,
          };
        }
        return newState;
      }
      return state;
    }

    case UPDATE_WORKSPACE: {
      let newState = { ...state };
      if (action.params.delta) {
        newState = { ...newState, ...action.params.delta };
      }
      return newState;
    }

    case UPDATE_HOME_OBJ: {
      const newState = { ...state, home: { ...state.home } };
      newState.home = { ...action.home };
      return newState;
    }

    case LOAD_BASE_RESOURCES:
    case LOAD_RELATED_RESOURCES: {
      if (action.resources) {
        const newState = { ...state, resources: {} as ResourceEntry };
        if (action.resources && Array.isArray(action.resources)) {
          action.resources.map((resource: IResourceObj) => {
            return (newState.resources[resource.id] = resource);
          });
        }
        return newState;
      }
      return state;
    }

    case ADD_RESOURCE: {
      if (action.resource) {
        const newState = { ...state, resources: { ...state.resources } };
        newState.resources[action.resource.id] = action.resource;
        return newState;
      }
      return state;
    }

    case CLEAR_WORKSPACE_DATA: {
      return initialState;
    }

    case SET_BASE_LABELS: {
      if (action.labels) {
        const newState = { ...state, labels: { ...state.labels } };
        const labelObj: LabelsEntry = {};
        action.labels.forEach((label) => {
          labelObj[label.id] = label;
        });
        newState.labels = labelObj;
        return newState;
      }
      return state;
    }

    case ADD_BASE_LABEL: {
      if (action.label) {
        const newState = { ...state, labels: { ...state.labels } };
        newState.labels[action.label.id] = action.label;
        return newState;
      }
      return state;
    }

    case LOAD_INVITATION_LINKS: {
      if (action.invitationLinks) {
        const newState = { ...state };
        newState.invitationLinksIds = [];
        action.invitationLinks.forEach((link) => {
          newState.invitationLinksIds.push(link.id);
          newState.invitationLinksDict[link.id] = link;
        });
        return newState;
      } else return state;
    }

    case PATCH_INVITATION_LINK: {
      const newState = { ...state };
      if (action.param.type === "update") {
        const id = action.param.id;
        newState.invitationLinksDict[id] = {
          ...newState.invitationLinksDict[id],
          ...action.param.delta,
        };
      }

      if (action.param.type === "add") {
        const id = action.param.id;
        newState.invitationLinksDict[id] = {
          ...newState.invitationLinksDict[id],
          ...action.param.delta,
        };
        newState.invitationLinksIds.push(id);
      }

      return newState;
    }
    case RELOAD_BASE: {
      const newState: IWorkspaceState = { ...state };
      newState.reloadNumber = newState.reloadNumber + 1;
      return newState;
    }
    default:
      return state;
  }
};

export default workspaceReducer;

export function useBase() {
  return useShallowSelector((state) => ({
    isBaseMember: state.client.isBaseMember,
    base: state.workspace,
    tagline: state.workspace.tagline ?? "",
  }));
}

export function useBaseSlug() {
  return useShallowSelector((state) => state.workspace.slug);
}

export function useBaseType() {
  return useShallowSelector((state) => state.workspace.type);
}

export function useBaseId() {
  return useShallowSelector((state) => state.workspace.id);
}

export function useTaglineUpdater() {
  const dispatch = useDispatch();

  return (tagline: IWorkspaceState["tagline"]) => {
    dispatch({
      type: UPDATE_WORKSPACE,
      params: {
        delta: {
          tagline,
        },
      },
    });
  };
}

export function useActiveBaseSetter() {
  const dispatch = useDispatch();

  return (workspace: Partial<IWorkspaceObj>) =>
    dispatch({
      type: SET_ACTIVE_WORKSPACE,
      workspace,
    });
}

export function usePatchBaseHome() {
  const { base } = useBase();

  return async (home: HomeScreenObj = base.home) => {
    if (JSON.stringify(home) === JSON.stringify(base.home)) return;
    const tagline = store.getState().workspace.tagline;
    home = {
      ...home,
    };

    await axiosInstance
      .patch(`/api/workspace/profile`, {
        baseId: base.id,
        patch: [
          {
            op: "replace",
            path: "/home",
            value: home,
          },
          {
            op: "replace",
            path: "/tagline",
            value: tagline,
          },
        ],
      })
      .catch(() => {
        notificationsApi.displayError({
          title: "Oops something went wrong",
          body: "Sorry, we could not save your changes. Please try again later",
        });
      });
  };
}

export function useAddOrEditBasePin() {
  const { base } = useBase();
  const homeUpdates = usePatchBaseHome();

  return async (item: HomeSectionRow, sectionId: string) => {
    const updatedHome = updateSectionPin(base.home, item, sectionId);
    homeUpdates(updatedHome);
  };
}

export function useReorderHomePins() {
  const homeUpdates = usePatchBaseHome();
  const { base } = useBase();

  return async (items: string[], sectionId: string) => {
    const updatedHome = updateSectionPinsOrder(base.home, items, sectionId);
    homeUpdates(updatedHome);
  };
}

export function useAddOrEditHomeSection() {
  const { base } = useBase();
  const homeUpdates = usePatchBaseHome();

  return async (item: HomeSectionObj) => {
    const updatedHome = updateSection(base.home, item);
    homeUpdates(updatedHome);
  };
}

export function useReorderHomeSection() {
  const { base } = useBase();
  const homeUpdates = usePatchBaseHome();

  return async (items: string[]) => {
    const home = {
      ...base.home,
    };

    home.sectionIds = items;
    homeUpdates(home);
  };
}

export function useDeleteHomeSection() {
  const { base } = useBase();
  const homeUpdates = usePatchBaseHome();

  return async (id: string) => {
    const home = {
      ...base.home,
    };
    if (!home.sectionIds.includes(id) || !home.sectionsDict[id]) {
      return;
    }
    const newSectionIds = home.sectionIds.filter((itemId) => itemId !== id);

    home.sectionIds = newSectionIds;
    delete home.sectionsDict[id];

    homeUpdates(home);
  };
}

export function useRemoveHomePin() {
  const { base } = useBase();
  const homeUpdates = usePatchBaseHome();

  return async (pinId: string, sectionId: string) => {
    const home = {
      ...base.home,
      sectionsDict: {
        ...base.home.sectionsDict,
      },
    };
    const section = {
      ...home.sectionsDict[sectionId],
    };

    const updatedSection = { ...home.sectionsDict[sectionId] };
    const newPins = updatedSection.resourcesIds.filter((id) => id !== pinId);
    updatedSection.resourcesIds = newPins;
    updatedSection.resourcesDict = { ...updatedSection.resourcesDict };
    delete updatedSection.resourcesDict[pinId];
    home.sectionsDict[section.id] = updatedSection;
    homeUpdates(home);
  };
}
