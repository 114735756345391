import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { Abilities } from "utilities/types";
import RoleRow from "./RoleRow";
import styles from "../roles/roles.module.scss";

const RoleSection: React.FC = () => {
  const roleIds = useSelector(
    (store: ClarityStore) => store.roles.ids,
    shallowEqual
  );
  const roleDict = useSelector(
    (store: ClarityStore) => store.roles.dict,
    shallowEqual
  );

  const [rolesToShow, setRolesToShow] = useState<string[]>([]);

  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_ROLES,
  });

  useEffect(() => {
    const noShow = ["Guest", "No Role", "Owner"];
    const options = roleIds
      .filter((roleId) => !noShow.includes(roleDict[roleId].roleName))
      .filter((roleId) => roleDict[roleId].roleType !== "personal");
    options.sort((a, b) => {
      const aRole = roleDict[a];
      const bRole = roleDict[b];
      return bRole.rank?.localeCompare(aRole?.rank, "en");
    });
    setRolesToShow(options);
  }, [roleIds]);

  return (
    <>
      {useMemo(
        () => (
          <div className={styles.rowContainer}>
            {rolesToShow.length > 0 && (
              <div className="label bold secondary mb-5">ROLES</div>
            )}
            {rolesToShow.map((id) => (
              <RoleRow
                key={id}
                id={id}
                canEdit={canEdit}
                canViewDetails={true}
              />
            ))}
          </div>
        ),
        [rolesToShow]
      )}
    </>
  );
};

export default RoleSection;
