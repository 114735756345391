import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import { userApi } from "clientApi/userApi";
import Button, { ButtonTypes } from "components/Button";
import Otp, { OtpStatus } from "components/Otp";
import React, { useState } from "react";
import styles from "./stayInLoop/stayInLoop.module.scss";

enum VisibilityStatuses {
  open = "open",
  confirmClose = "confirmClose",
  closed = "closed",
}

const statusToTitle = {
  [OtpStatus.unsent]: "Stay in the loop with email notifications",
  [OtpStatus.sent]: "Verify your email address",
  [OtpStatus.waitingEmail]: "Stay in the loop with email notifications",
};

const StayInLoop: React.FC = () => {
  const [otpStatus, setOtpStatus] = useState<OtpStatus>(OtpStatus.unsent);
  const [visibilityStatus, setVisibilityStatus] = useState<VisibilityStatuses>(
    VisibilityStatuses.open
  );

  const closeClick = () => {
    if (visibilityStatus === VisibilityStatuses.open) {
      return setVisibilityStatus(VisibilityStatuses.confirmClose);
    }
    return setVisibilityStatus(VisibilityStatuses.closed);
  };

  if (visibilityStatus === VisibilityStatuses.closed) return <></>;

  return (
    <div className={styles.card}>
      <div
        className={styles.headingRow}
        style={{
          marginBottom: otpStatus !== OtpStatus.sent ? "12px" : "19px",
        }}
      >
        <p className="body bold">
          {visibilityStatus === VisibilityStatuses.confirmClose ? (
            <>Remind you next time?</>
          ) : (
            <>{statusToTitle[otpStatus]}</>
          )}
        </p>
        <CloseOutlined className={styles.closeButton} onClick={closeClick} />
      </div>
      {otpStatus !== OtpStatus.sent && (
        <p
          className="caption regular"
          style={{ marginBottom: "20px", marginLeft: "4px" }}
        >
          {visibilityStatus === VisibilityStatuses.confirmClose ? (
            <>
              Your email can also be used to recover your account. Add one later
              in your account settings.
            </>
          ) : (
            <>
              Receive relevant app notifications to your inbox when your team
              works in Clarity.
            </>
          )}
        </p>
      )}

      {visibilityStatus === VisibilityStatuses.open && (
        <Otp fullWidth={true} emitter={setOtpStatus} getOtpCTA="Continue" />
      )}

      {visibilityStatus === VisibilityStatuses.confirmClose && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "12px",
            alignItems: "baseline",
          }}
        >
          <Button
            style={{ width: "100%" }}
            buttonType={ButtonTypes.LARGE_PRIMARY}
            onClick={closeClick}
          >
            Remind me later
          </Button>
          <Button
            buttonType={ButtonTypes.LARGE_SECONDARY}
            style={{ width: "100%" }}
            onClick={() =>
              userApi.persistChanges({
                gettingStartedProgress: 0,
              })
            }
          >
            Dismiss forever
          </Button>
        </div>
      )}
    </div>
  );
};

export default StayInLoop;
