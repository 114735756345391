import { batch } from "react-redux";
import {
  getCurrentContext,
  getPreviousBlock,
  getNextBlockId,
  getBlockById,
  getSortedSelectedBlocks,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $documentMode } from "store/storeExporter";
import { TypeOfLineMovement } from "utilities/movementTypes";
import {
  CommandPaletteContext,
  ContainerTypes,
  DocumentModes,
  UserRole,
} from "utilities/types";
import * as KeyEvents from "keycode-js";
import {
  checkToUnselectBlocks,
  toggleDocumentMode,
  handleBlockOutdent,
  handleBlockIndent,
  handleMoveBlockSingle,
  selectNext,
  redoAction,
  undoAction,
} from "./blockActions";
import { Block } from "store/reducers/blockReducer";
import {
  entityKeepTypes,
  LineStatus,
  LineType,
  LineValueType,
} from "utilities/lineUtilities";
import { batchBlockUpdate } from "./specificActions/batchBlockUpdate";
import { BlockProps } from "editor/blockContainer/Block";
import { appClipboardData, clearappClipboardData } from "App";
import { addBlock, ADD_BLOCK_TYPES } from "./specificActions/addBlockActions";
import { SELECT_ALL_BLOCKS, SHOW_COMMAND_PALETTE } from "store/actions";
import { blockModeDelete } from "./specificActions/deleteBlockActions";
import { toggleBlockCollapse } from "./specificActions/collapseActions";
import { checkFocusTitle } from "./specificActions/moveActions";
import { defaultRefocus } from "./contextActions/primitiveContextActions";
import notificationsApi from "clientApi/notificationsApi";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";

export const blockModeEvents = (
  e: React.KeyboardEvent<HTMLDivElement>,
  props: BlockProps,
  blockRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  checkToUnselectBlocks(props, e);

  switch (e.key) {
    case KeyEvents.VALUE_ESCAPE: {
      e.preventDefault();
      e.stopPropagation();
      toggleDocumentMode(props.blockData.id);
      break;
    }

    case KeyEvents.VALUE_TAB: {
      e.preventDefault();
      e.stopPropagation();
      if (!props.context.canEdit) {
        return;
      }
      if (e.shiftKey) handleBlockOutdent(props.blockData.id, props);
      else handleBlockIndent(props);
      break;
    }

    case KeyEvents.VALUE_C:
    case KeyEvents.VALUE_C.toUpperCase(): {
      if (!props.context.canEdit) {
        e.stopPropagation();
        return;
      }
      return;
    }

    case KeyEvents.VALUE_F:
    case KeyEvents.VALUE_F.toUpperCase(): {
      e.stopPropagation();
      e.preventDefault();
      if (!props.context.canEdit) {
        return;
      }
      if ($documentMode.value === DocumentModes.BLOCK) {
        toggleBlockCollapse({
          blockId: props.blockData.id,
          context: props.context,
          shiftKey: e.shiftKey,
        });
      }
      return;
    }

    case KeyEvents.VALUE_CLOSE_BRACKET: {
      e.stopPropagation();
      e.preventDefault();
      if (!props.context.canEdit) {
        return;
      }
      handleBlockIndent(props);
      break;
    }

    case KeyEvents.VALUE_OPEN_BRACKET: {
      e.stopPropagation();
      e.preventDefault();
      if (!props.context.canEdit) {
        return;
      }
      handleBlockOutdent(props.blockData.id, props);
      break;
    }

    case KeyEvents.VALUE_K:
    case KeyEvents.VALUE_K.toUpperCase():
    case KeyEvents.VALUE_UP: {
      if (props.context.canEdit) {
        if (e.shiftKey && (e.metaKey || e.ctrlKey)) {
          e.stopPropagation();
          e.preventDefault();
          handleMoveBlockSingle({ ...props, blockRef }, "up");
          return;
        }

        if ((e.metaKey || e.ctrlKey) && e.key === KeyEvents.VALUE_K) return;

        if (e.shiftKey) {
          batch(() => {
            e.stopPropagation();
            e.preventDefault();
            selectNext({
              id: props.blockData.id,
              type: "up",
              context: props.context,
            });
          });
          return;
        }
      }

      const blockState = store.getState().blocks;
      const currentContext = getCurrentContext(blockState, props.context.id);
      const prevBlockId = getPreviousBlock(
        blockState,
        { ...props.blockData, ...props.blockData.blockSubject.value },
        currentContext
      );
      if (prevBlockId) {
        e.preventDefault();
        e.stopPropagation();
        const focusedBlock: any = props.context.ref.current?.querySelectorAll(
          `[data-block-id="${prevBlockId}"]`
        )[0];
        props.changeBlock(
          props.blockData.id,
          focusedBlock,
          e,
          TypeOfLineMovement.previousCursorState,
          0,
          DocumentModes.BLOCK,
          prevBlockId
        );
      } else {
        const titleRef = checkFocusTitle(props.blockData, props.context);
        if (titleRef) {
          e.preventDefault();
          e.stopPropagation();
          props.changeBlock(
            props.blockData.id,
            titleRef,
            e,
            TypeOfLineMovement.previousCursorState,
            0,
            DocumentModes.BLOCK,
            ""
          );
        }
      }
      break;
    }

    case KeyEvents.VALUE_J:
    case KeyEvents.VALUE_J.toUpperCase():
    case KeyEvents.VALUE_DOWN: {
      if (props.context.canEdit) {
        if (e.shiftKey && (e.metaKey || e.ctrlKey)) {
          e.stopPropagation();
          e.preventDefault();
          handleMoveBlockSingle({ ...props, blockRef }, "down");
          return;
        }
        if (e.shiftKey) {
          e.stopPropagation();
          e.preventDefault();
          batch(() => {
            selectNext({
              id: props.blockData.id,
              type: "down",
              context: props.context,
            });
          });
          return;
        }
      }

      const blockState = store.getState().blocks;
      const currentContext = getCurrentContext(blockState, props.context.id);
      const nextBlockId = getNextBlockId(
        blockState,
        { ...props.blockData, ...props.blockData.blockSubject.value },
        currentContext
      );

      if (nextBlockId) {
        e.preventDefault();
        e.stopPropagation();
        const focusedBlock: any = props.context.ref.current?.querySelectorAll(
          `[data-block-id="${nextBlockId}"]`
        )[0];
        props.changeBlock(
          props.blockData.id,
          focusedBlock,
          e,
          TypeOfLineMovement.previousCursorState,
          0,
          DocumentModes.BLOCK,
          nextBlockId
        );
      }
      e.preventDefault();
      e.stopPropagation();
      break;
    }

    case KeyEvents.VALUE_Z:
    case KeyEvents.VALUE_Z.toUpperCase(): {
      if (!props.context.canEdit) return;
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          redoAction(props.context);
          break;
        }
        undoAction(props.context);
      }
      break;
    }

    case KeyEvents.VALUE_Y:
    case KeyEvents.VALUE_Y.toUpperCase(): {
      if (!props.context.canEdit) return;
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        redoAction(props.context);
      }
      break;
    }

    case KeyEvents.VALUE_V: {
      e.stopPropagation();
      return;
    }

    case KeyEvents.VALUE_ENTER: {
      e.preventDefault();
      e.stopPropagation();
      if (!props.context.canEdit) return;
      if (e.metaKey || e.ctrlKey) {
        checkCheckboxCases(props.blockData.id, props.context);
        return;
      }
      const newState = store.getState().blocks;
      const selectedBlocks = [...newState.selectedBlocks];
      let sourceBlockId = props.blockData.id;
      if (selectedBlocks.length > 0) {
        const sortedSelection = getSortedSelectedBlocks(selectedBlocks);
        sourceBlockId = sortedSelection[sortedSelection.length - 1];
      }
      const sourceBlock = getBlockById(newState.dict, sourceBlockId);
      $documentMode.next(DocumentModes.INSERT);
      if (
        (props.blockData.children.length > 0 ||
          props.context.zoomedBlockId === props.blockData.id) &&
        props.blockData.lineType !== LineType.table
      ) {
        addBlock({
          context: props.context,
          currentBlock: sourceBlock,
          focus: "newBlock",
          newBlockValue: [],
          type: ADD_BLOCK_TYPES.addChildBlock,
          presetData: {
            lineType: LineType.text,
          },
        });
      } else
        addBlock({
          context: props.context,
          currentBlock: sourceBlock,
          focus: "newBlock",
          newBlockValue: [],
          type: ADD_BLOCK_TYPES.addBlockAfter,
          presetData: {
            lineType: LineType.text,
          },
        });

      return;
    }

    case KeyEvents.VALUE_X: {
      e.stopPropagation();
      if (!props.context.canEdit) return;
      return;
    }

    case KeyEvents.VALUE_BACK_SPACE:
    case KeyEvents.VALUE_DELETE: {
      if (props.blockData.lineType === LineType.Title) return;
      if (!props.context.canEdit) return;
      e.stopPropagation();
      e.preventDefault();
      blockModeDelete({ blockId: props.blockData.id, context: props.context });
      break;
    }

    case KeyEvents.VALUE_A:
    case KeyEvents.VALUE_A.toUpperCase(): {
      e.stopPropagation();
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        e.stopPropagation();
        if (props.context.type === "container") {
          store.dispatch({
            type: SELECT_ALL_BLOCKS,
            param: {
              contextId: props.context.id,
            },
          });
        }
      }
      break;
    }
  }
};

export const chechForCitationCopy = (
  e: React.KeyboardEvent<HTMLDivElement>,
  props: BlockProps,
  ref: React.MutableRefObject<HTMLDivElement | null>
) => {
  if ([KeyEvents.VALUE_C, KeyEvents.VALUE_C.toUpperCase()].includes(e.key)) {
    if (e.ctrlKey || e.metaKey) {
      if (e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        clearappClipboardData();
        copyCitation(props, ref);
        notificationsApi.displayConfirmation({
          title: "Citation copied!",
        });
        return true;
      } else {
        clearappClipboardData();
        const fragment = document.createDocumentFragment();
        copyHTMLToClipBoard(fragment, false);
      }
    }
  }
  if ([KeyEvents.VALUE_X, KeyEvents.VALUE_X.toUpperCase()].includes(e.key)) {
    if ($documentMode.value === DocumentModes.INSERT) return;
    if (e.ctrlKey || e.metaKey) {
      if (!e.shiftKey) {
        clearappClipboardData();
        const fragment = document.createDocumentFragment();
        copyHTMLToClipBoard(fragment, false);
        blockModeDelete({
          blockId: props.blockData.id,
          context: props.context,
        });
      }
    }
  }
};

export const checkCheckboxCases = (id: string, context: any) => {
  const blockState = store.getState().blocks;

  if (blockState.selectedBlocks.length > 0) {
    const selectedIds = [...blockState.selectedBlocks];
    const blocks: Block[] = [];
    selectedIds.forEach((elId: string) => {
      const block = getBlockById(blockState.dict, elId);
      if (!entityKeepTypes.includes(block.lineType)) blocks.push(block);
    });

    const { checkboxes, nonCheckboxes } = searchCheckboxes(blocks);

    if (nonCheckboxes.length > 0) {
      makeCheckboxes(nonCheckboxes, LineStatus.todo, context);
      return;
    }

    if (0 < checkboxes.length && checkboxes.length < blocks.length) {
      makeCheckboxes(blocks, LineStatus.done, context);
      return;
    }

    if (checkboxes.length === 0) {
      makeCheckboxes(blocks, LineStatus.todo, context);
      return;
    } else {
      const notDone = checkboxes.filter(
        (block) => block.checkboxStatus !== LineStatus.done
      );
      const notDoneLength = notDone.length;

      if (notDoneLength === blocks.length) {
        batchCheckboxesStatus(checkboxes, LineStatus.done, context);
        return;
      }

      if (checkboxes.length > 0 && notDoneLength === 0) {
        removeCheckBox(checkboxes, context);
        return;
      }
      batchCheckboxesStatus(checkboxes, LineStatus.done, context);
    }
  } else {
    const block = getBlockById(blockState.dict, id);
    if (!entityKeepTypes.includes(block.lineType)) {
      if (block.lineType === LineType.checkbox) {
        if (block.checkboxStatus === LineStatus.done) {
          removeCheckBox([block], context);
          return;
        } else {
          batchCheckboxesStatus([block], LineStatus.done, context);
        }
      } else {
        makeCheckboxes([block], LineStatus.todo, context);
        return;
      }
    }
  }
};

const searchCheckboxes = (blocks: Block[]) => {
  const checkboxes: Block[] = [];
  const nonCheckboxes: Block[] = [];
  for (const block of blocks) {
    if (block.lineType === LineType.checkbox) {
      checkboxes.push(block);
    } else {
      nonCheckboxes.push(block);
    }
  }
  return { checkboxes, nonCheckboxes };
};

const makeCheckboxes = (blocks: Block[], status: LineStatus, context: any) => {
  const blockIds = blocks.map((block) => block.id);
  batchBlockUpdate({
    delta: { checkboxStatus: status, lineType: LineType.checkbox },
    context,
    selectedBlocks: blockIds,
    id: blockIds[0],
  });
};

const removeCheckBox = (blocks: Block[], context: any) => {
  const blockIds = blocks.map((block) => block.id);
  batchBlockUpdate({
    delta: { lineType: LineType.text },
    context,
    selectedBlocks: blockIds,
    id: blockIds[0],
  });
};

const batchCheckboxesStatus = (
  blocks: Block[],
  status: LineStatus,
  context: any
) => {
  const blockIds = blocks.map((block) => block.id);
  batchBlockUpdate({
    delta: { checkboxStatus: status, lineType: LineType.checkbox },
    context,
    selectedBlocks: blockIds,
    id: blockIds[0],
  });
};

export const copyCitation = (
  props: BlockProps,
  ref: React.MutableRefObject<HTMLDivElement | null>
) => {
  const state = store.getState();
  const date = new Date();
  const sourceBlockId = props.blockData.id;
  appClipboardData.sourceBlockId = sourceBlockId;
  const workspace = state.workspace;
  appClipboardData.sourceBlockBaseId = workspace.id;
  appClipboardData.sourceContainer = {
    containerId: props.blockData.containerId,
    containerType: props.blockData.containerType,
  };
  appClipboardData.copyDate = date;

  const selection = document.getSelection();
  if (selection && selection.toString().length > 0) {
    appClipboardData.sync = false;
    const range = selection.getRangeAt(0);
    const fragment = range.cloneContents();
    copyHTMLToClipBoard(fragment, true);
  } else {
    if ($documentMode.value === DocumentModes.BLOCK) {
      const selectedBlocksInState = getSortedSelectedBlocks([
        ...state.blocks.selectedBlocks,
      ]);
      appClipboardData.sync = true;
      appClipboardData.selectedBlocks =
        selectedBlocksInState.length > 0
          ? selectedBlocksInState
          : [sourceBlockId];

      const fragment = document.createDocumentFragment();
      copyHTMLToClipBoard(fragment, true);
      return;
    }
    const newRange = new Range();
    if (ref.current) {
      appClipboardData.sync = true;
      newRange.selectNodeContents(ref.current);
      const fragment = newRange.cloneContents();
      replaceInnerCitations(fragment);
      copyHTMLToClipBoard(fragment, true);
    }
  }
};

export const copyHTMLToClipBoard = (
  fragment: DocumentFragment,
  shiftKey: boolean
) => {
  const el = document.createElement("div");
  el.tabIndex = -1;
  el.appendChild(fragment);
  const text = document.createTextNode("clarity-citation-copy-&-");
  if (el.innerText.trim().length === 0) {
    el.appendChild(text);
  }
  // el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  const actionHandler = (e: ClipboardEvent) => {
    if (shiftKey) {
      text.remove();
      e.clipboardData?.setData("clarity-citation-copy", "true");
      e.clipboardData?.setData("text/plain", el.innerText);
      e.clipboardData?.setData("text/html", el.innerHTML);
    } else {
      text.remove();
    }
    setTimeout(() => {
      el.remove();
    }, 0);
  };

  document.addEventListener(
    "copy",
    (e) => {
      actionHandler(e);
    },
    { capture: true }
  );

  document.body.appendChild(el);
  if (document) {
    const selection = document.getSelection();
    if (selection && selection.rangeCount > 0) {
      const prevrange = selection.getRangeAt(0);
      selection.removeAllRanges();
      const range = new Range();
      range.selectNodeContents(el);
      selection.addRange(range);
      document.execCommand("copy");
      // document.body.removeChild(el);
      if (range) {
        setTimeout(() => {
          selection.removeAllRanges();
          selection.addRange(prevrange);
        }, 1);
      }
    } else {
      if (selection) {
        selection.removeAllRanges();
        const range = new Range();
        range.selectNodeContents(el);
        selection.addRange(range);
        document.execCommand("copy");
        setTimeout(() => {
          if (range) {
            selection.removeAllRanges();
          }
        });
      }
    }
  }
  if (!shiftKey) el.remove();
  setTimeout(() => {
    document.removeEventListener(
      "copy",
      (e) => {
        actionHandler(e);
      },
      { capture: true }
    );
  }, 1);
};

export const replaceInnerCitations = (
  fragment: DocumentFragment | ChildNode
) => {
  fragment.childNodes.forEach((child: any) => {
    if (child.nodeName === LineValueType.citation) {
      const citationPlaceholder = document.createElement("citationPlaceholder");
      const sourceId = child.getAttribute("data-source-id");
      citationPlaceholder.setAttribute(
        "data-source-id",
        sourceId ? sourceId : ""
      );
      child.childNodes.forEach((innerChild: ChildNode) => {
        const copy = innerChild.cloneNode(true);
        citationPlaceholder.appendChild(copy);
      });
      child.replaceWith(citationPlaceholder);
    }
  });
};

export const checkForCommand = (
  e: React.KeyboardEvent<HTMLDivElement>,
  props: BlockProps,
  ref: React.MutableRefObject<HTMLDivElement | null>
) => {
  if (!((e.metaKey || e.ctrlKey) && e.key === KeyEvents.VALUE_K)) return;
  const selection = document.getSelection();
  if (selection && selection?.toString().length > 0) return;
  e.preventDefault();
  e.stopPropagation();
  const state = store.getState().blocks;
  const currentBlock = getBlockById(state.dict, props.blockData.id);
  const userRole = store.getState().client.roleType;

  if (userRole === UserRole.GUEST) {
    return;
  }
  const selectedIds = [...state.selectedBlocks];
  const selectedWorkIds: string[] = [];
  if (!selectedIds || selectedIds.length === 0) {
    if (
      [LineType.Title, LineType.work].includes(props.blockData.lineType) &&
      props.blockData.referencingContainerId
    ) {
      selectedWorkIds.push(props.blockData.referencingContainerId);
    }
    // selectBlock(props.blockData.id);
  } else {
    selectedIds.forEach((id: string) => {
      const block = getBlockById(state.dict, id);
      if (
        block &&
        [LineType.Title, LineType.work].includes(block.lineType) &&
        block.referencingContainerId
      ) {
        selectedWorkIds.push(block.referencingContainerId);
      }
    });
  }
  if (
    currentBlock.containerType === ContainerTypes.DOCUMENT ||
    currentBlock.containerType === ContainerTypes.NOTE ||
    currentBlock.containerType === ContainerTypes.TEMPLATE ||
    currentBlock.containerType === ContainerTypes.SNIPPET
  ) {
    if (selectedWorkIds.length > 0) {
      openCommandPalette(
        CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_PROJECT,
        {
          selectedItemIds: selectedWorkIds,
          slectedItemsType: ContainerTypes.WORK,
          containerData: {
            containerId: currentBlock.containerId,
            containerType: currentBlock.containerType,
          },
          context: props.context,
          blockData: currentBlock,
          blockRef: ref,
        },
        defaultRefocus
      );
    } else {
      openCommandPalette(
        CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_DOCUMENT,
        {
          containerData: {
            containerId: currentBlock.containerId,
            containerType: currentBlock.containerType,
          },
          context: props.context,
          blockData: currentBlock,
          blockRef: ref,
        },
        defaultRefocus
      );
    }
  } else {
    if (currentBlock.containerType === ContainerTypes.PROJECT) {
      if (selectedWorkIds.length === 0)
        selectedWorkIds.push(currentBlock.containerId);

      openCommandPalette(
        CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_PROJECT,
        {
          selectedItemIds: selectedWorkIds,
          slectedItemsType: ContainerTypes.WORK,
          containerData: {
            containerId: currentBlock.containerId,
            containerType: currentBlock.containerType,
          },
          context: props.context,
          blockData: currentBlock,
          blockRef: ref,
        },
        defaultRefocus
      );
      // store.dispatch({
      //   type: SHOW_COMMAND_PALETTE,
      //   context: CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_PROJECT,
      //   params: {
      //     containerData: {
      //       containerId: currentBlock.containerId,
      //       containerType: currentBlock.containerType,
      //     },
      //     selectedWorkIds,
      //     blockData: currentBlock,
      //     blockRef: ref,
      //     context: props.context,
      //   },
      //   afterClose: () => defaultRefocus(),
      // });
    }

    if (currentBlock.containerType === ContainerTypes.WORK_ACTIVITY) {
      if (selectedWorkIds.length === 0) {
        if (props.context.container.referenceTaskId) {
          selectedWorkIds.push(props.context.container.referenceTaskId);
        }
      }

      store.dispatch({
        type: SHOW_COMMAND_PALETTE,
        context: CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_COMMENT,
        params: {
          containerData: {
            containerId: currentBlock.containerId,
            containerType: currentBlock.containerType,
          },
          selectedWorkIds,
          blockData: currentBlock,
          blockRef: ref,
          context: props.context,
        },
        afterClose: () => defaultRefocus(),
      });
    }
  }
};
