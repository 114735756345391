import React, { useState } from "react";
import styles from "clarity-ui/mentionsSection/mentionSubSection/mentionSubSection.module.scss";
import Button, { ButtonTypes } from "components/Button";
import {
  FileDoneOutlined,
  CheckCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { ContainerTypes } from "utilities/types";
import FileTextTwoTone from "icons/Components/FileTextTwoTone";
import FoldButton from "shannon/FoldButton";

interface MentionSubSectionProps {
  title: string;
  setIsUnlinkedFolded?: (isFolded: boolean) => void;
  unlinked?: boolean;
  children?: any;
  unlinkedMentionsCount?: number;
  linkAllUnlinked?: (e: any) => void;
  setFilters?: React.Dispatch<React.SetStateAction<string[]>>;
  filters?: string[];
}

interface IMapStateToProps {}

interface IMapDispatchToProps {}

function MentionSubSection(
  props: MentionSubSectionProps & IMapDispatchToProps & IMapStateToProps
) {
  const [isFolded, setIsFolded] = useState(props.unlinked ? true : false);

  return (
    <div className={styles.MentionSubSection}>
      <div className={styles.subSectionHeader}>
        <FoldButton
          onClick={() => {
            setIsFolded(!isFolded);
            if (props.setIsUnlinkedFolded) {
              props.setIsUnlinkedFolded(!isFolded);
            }
          }}
          isFolded={isFolded}
          size={"medium"}
        />

        <div className={styles.subSectionHeader__heading}>{props.title}</div>
        {props.filters && <MentionsFilters {...props} />}
        {!isFolded &&
        props.unlinkedMentionsCount &&
        props.unlinkedMentionsCount > 0 ? (
          <>
            <span className={styles.subSectionHeader__count}>
              ({props.unlinkedMentionsCount})
            </span>
            <Button
              buttonType={ButtonTypes.LINK}
              style={{ marginLeft: "auto", marginTop: "-4px" }}
              onClick={(e: any) =>
                props.linkAllUnlinked && props.linkAllUnlinked(e)
              }
            >
              Link All
            </Button>
          </>
        ) : null}
      </div>
      {!isFolded && (
        <div className={styles.subSectionBody}>{props.children}</div>
      )}
    </div>
  );
}

const MentionsFilters: React.FC<
  MentionSubSectionProps & IMapDispatchToProps & IMapStateToProps
> = (props) => {
  return (
    <div style={{ display: "flex", marginLeft: "auto" }}>
      <Button
        icon={<FormOutlined />}
        buttonType={ButtonTypes.LINK}
        className={
          props.filters?.includes(ContainerTypes.NOTE)
            ? styles.MentionSubSection__filteres
            : styles.MentionSubSection__filtered
        }
        onClick={() => {
          if (props.filters?.includes(ContainerTypes.NOTE)) {
            const newFilters = [...props.filters];
            const index = newFilters.indexOf(ContainerTypes.NOTE);
            newFilters.splice(index, 1);
            if (props.setFilters) props.setFilters(newFilters);
          } else {
            if (props.filters) {
              const newFilters = [...props.filters];
              newFilters.push(ContainerTypes.NOTE);
              if (props.setFilters) props.setFilters(newFilters);
            }
          }
        }}
      />
      <Button
        icon={<FileTextTwoTone />}
        buttonType={ButtonTypes.LINK}
        className={
          props.filters?.includes(ContainerTypes.DOCUMENT)
            ? styles.MentionSubSection__filteres
            : styles.MentionSubSection__filtered
        }
        onClick={() => {
          if (props.filters?.includes(ContainerTypes.DOCUMENT)) {
            const newFilters = [...props.filters];
            const index = newFilters.indexOf(ContainerTypes.DOCUMENT);
            newFilters.splice(index, 1);
            if (props.setFilters) props.setFilters(newFilters);
          } else {
            if (props.filters) {
              const newFilters = [...props.filters];
              newFilters.push(ContainerTypes.DOCUMENT);
              if (props.setFilters) props.setFilters(newFilters);
            }
          }
        }}
      />
      <Button
        icon={<CheckCircleOutlined />}
        buttonType={ButtonTypes.LINK}
        className={
          props.filters?.includes(ContainerTypes.TASK)
            ? styles.MentionSubSection__filteres
            : styles.MentionSubSection__filtered
        }
        onClick={() => {
          if (props.filters?.includes(ContainerTypes.TASK)) {
            const newFilters = [...props.filters];
            const index = newFilters.indexOf(ContainerTypes.TASK);
            newFilters.splice(index, 1);
            if (props.setFilters) props.setFilters(newFilters);
          } else {
            if (props.filters) {
              const newFilters = [...props.filters];
              newFilters.push(ContainerTypes.TASK);
              if (props.setFilters) props.setFilters(newFilters);
            }
          }
        }}
      />
      <Button
        icon={<FileDoneOutlined />}
        buttonType={ButtonTypes.LINK}
        className={
          props.filters?.includes(ContainerTypes.PROJECT)
            ? styles.MentionSubSection__filteres
            : styles.MentionSubSection__filtered
        }
        onClick={() => {
          if (props.filters?.includes(ContainerTypes.PROJECT)) {
            const newFilters = [...props.filters];
            const index = newFilters.indexOf(ContainerTypes.PROJECT);
            newFilters.splice(index, 1);
            if (props.setFilters) props.setFilters(newFilters);
          } else {
            if (props.filters) {
              const newFilters = [...props.filters];
              newFilters.push(ContainerTypes.PROJECT);
              if (props.setFilters) props.setFilters(newFilters);
            }
          }
        }}
      />
    </div>
  );
};

export default MentionSubSection;
