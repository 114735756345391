import ConfirmModal from "clarity-ui/ConfirmModal";
import React, { useCallback, useEffect, useState } from "react";
import { IGroup } from "utilities/types";
import Button, { ButtonTypes } from "./Button";
import ClarityInput from "./ClarityInput";
import { Form } from "antd";
import { validateDomainName } from "utilities/regexUtilities";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import { groupApi } from "clientApi/groupsApi";
import notificationsApi from "clientApi/notificationsApi";
import dangerZoneConfrimstyles from "./confirmModal/confirmModal.module.scss";
import { throttle } from "lodash";

const ChangeGroupIdentifier: React.FC<{
  group: IGroup;
}> = ({ group }) => {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);
  const [domainChanged, setDomainChanged] = useState(false);
  const [slugAvaialble, setSlugAvaialble] = useState(true);
  const [existingGroup, setexistingGroup] = useState<IGroup>(group);
  useEffect(() => {
    setexistingGroup(group);
  }, [group, isConfirmationModalVisible]);
  const handleChangeIndentifier = async () => {
    handleSaveChanges();
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value, name } = e.currentTarget;
    setexistingGroup({ ...existingGroup, [name]: value.trim().toUpperCase() });
    if (group.slug !== value.trim().toUpperCase()) {
      setDomainChanged(true);
    } else {
      setDomainChanged(false);
    }
  };

  const isSlugNameValid =
    !!existingGroup.slug &&
    slugAvaialble &&
    validateDomainName(existingGroup.slug) &&
    existingGroup.slug.length !== 0 &&
    existingGroup.slug.length < 6;

  const handleSaveChanges = async () => {
    if (!isSlugNameValid || !domainChanged) {
      return;
    }
    groupApi.updateGroup(existingGroup, existingGroup.id).then(
      () => {
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Group updated",
        });
        setDomainChanged(false);
      },
      (error) => {
        if (error?.response?.data) {
          notificationsApi.displayError({
            title: "Error",
            duration: 4,
            body: error?.response?.data.message,
          });
        }
        // groupApi.deleteGroupDispatch(newGroup);
      }
    );
  };

  const checkUniqueSlug = async (slug: string) => {
    if (slug) {
      setSlugAvaialble(false);
      groupApi
        .checkUniqueSlug(slug.toUpperCase(), group.workspaceId, group.id)
        .then(
          (res) => {
            setSlugAvaialble(true);
          },
          (error) => {
            setSlugAvaialble(false);
          }
        );
    }
  };

  const changeSlug = useCallback(
    throttle(checkUniqueSlug, 1000, { trailing: true, leading: false }),
    []
  );

  return (
    <>
      <Button
        buttonType={ButtonTypes.DANGER}
        onClick={(e: any) => {
          setIsConfirmationModalVisible(true);
          setSlugAvaialble(true);
          setDomainChanged(false);
        }}
      >
        {"Change Identifier"}
      </Button>
      {isConfirmationModalVisible && (
        <ConfirmModal
          onConfirm={handleChangeIndentifier}
          confirmDisabled={!isSlugNameValid || !domainChanged}
          confirmationText="Confirm change"
          title="Change Group Identifier"
          close={() => setIsConfirmationModalVisible(false)}
        >
          <div className={dangerZoneConfrimstyles.container}>
            <div
              className={`body2 regular primary ${dangerZoneConfrimstyles.heading}`}
            >
              The Group Identifier is used to create IDs for tasks in this group
              ({existingGroup.slug}-1234). Task IDs make it easy to refer to
              tasks in conversation and help when tasks have the same name—such
              as with repeat tasks.
            </div>
            <div className="body2 regular secondary">
              <span className="bold">Please note:</span> When you change the
              identifier of this group, it will disrupt all links you have
              shared to tasks in this group.
            </div>
            <Form>
              <Form.Item
                initialValue={existingGroup.slug}
                name="slug"
                style={{ marginTop: 32, marginBottom: 0 }}
              >
                <ClarityInput
                  label="Identifier"
                  name="slug"
                  maxLength={5}
                  value={existingGroup.slug || ""}
                  onChange={(e) => {
                    handleInputChange(e);
                    changeSlug(e.target.value);
                  }}
                />
              </Form.Item>
              {isSlugNameValid && domainChanged && (
                <ClarityLabel
                  message={"This Identifier is available"}
                  type={MessageLabelTypes.success}
                />
              )}
            </Form>
            {!isSlugNameValid && slugAvaialble && (
              <ClarityLabel
                message={"Please enter a valid Identifier name"}
                type={MessageLabelTypes.error}
              />
            )}
            {!isSlugNameValid && !slugAvaialble && (
              <ClarityLabel
                message={"A group with this identifier already exists"}
                type={MessageLabelTypes.error}
              />
            )}
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default ChangeGroupIdentifier;
