import React, { useEffect, useState, useRef } from "react";
import Button, { ButtonTypes } from "components/Button";
import { MailOutlined, CloseOutlined } from "@ant-design/icons";
import { debounce } from "throttle-debounce";
import * as actionTypes from "store/actions";
import { useDispatch } from "react-redux";

import {
  IPartialUser,
  UserInvitationStatus,
  IWorkspaceObj,
  ISubscriptionObj,
} from "utilities/types";

import styles from "./modalInvitations/modalInvitations.module.scss";
import UserInfo from "./modalInvitations/UserInfo";
import { validateEmail } from "utilities/regexUtilities";
import { connect } from "react-redux";
import ModalScrimComponent from "components/ModalScrimComponent";
import store from "store/storeExporter";
import { ADD_NEW_UNIQUE_USER } from "store/actions";
import { axiosInstance } from "index";
import ClarityInput from "components/ClarityInput";

const { v4: uuidv4 } = require("uuid");

interface IModalInvitationProps {
  refetchCollaborators: () => Promise<void>;
  currentNumberOfInvites: number;
  groupId?: string;
}

interface ISearchResult {
  user: IPartialUser;
  status: UserInvitationStatus;
}

interface IInvitationUser {
  type: "username" | "email";
  content: string;
  avatar?: string;
  id?: string;
}

interface IMapStateToProps {
  base: IWorkspaceObj;
  subscription: ISubscriptionObj;
}

const ModalInvitations = (props: IModalInvitationProps & IMapStateToProps) => {
  const [searchWord, setSearchWord] = useState<string>("");
  const [inputWord, setInputWord] = useState<string>("");
  const [emailEntered, setEmailEntered] = useState(false);
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
  const [invitationUsers, setInvitationUsers] = useState<IInvitationUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { base, refetchCollaborators } = props;

  const SearchWordInput: React.MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement>(null);

  const hideModal = () =>
    store.dispatch({
      type: actionTypes.SET_INVITATIONS_MODAL,
      isOpen: false,
    });

  useEffect(() => {
    async function fetchResults() {
      if (searchWord) {
        try {
          const { data } = await axiosInstance.get<{
            status: number;
            info: string;
            payload: {
              alreadyInvited: IPartialUser[];
              alreadyMemberExactMatch: IPartialUser[];
              nonMembers: IPartialUser[];
              guests: IPartialUser[];
            };
          }>(`/api/invitation/users/${base.id}/${searchWord}`);
          if (data.status === 1) {
            const { payload } = data;
            if (!props.groupId) {
              const _invitedMembers = payload.alreadyInvited.map(
                (m: IPartialUser) => ({
                  status: UserInvitationStatus.INVITED_MEMBER,
                  user: m,
                })
              );
              const _alreadyMembers = payload.alreadyMemberExactMatch.map(
                (m: IPartialUser) => ({
                  status: UserInvitationStatus.ALREADY_MEMBER,
                  user: m,
                })
              );
              const _nonMembers = payload.nonMembers.map((m: IPartialUser) => ({
                status: UserInvitationStatus.NON_MEMBER,
                user: m,
              }));
              const _guests = payload.guests.map((m: IPartialUser) => ({
                status: UserInvitationStatus.GUEST,
                user: m,
              }));
              setSearchResults([
                ..._nonMembers,
                ..._alreadyMembers,
                ..._invitedMembers,
                ..._guests,
              ]);
            } else {
              const _alreadyMembers = payload.alreadyMemberExactMatch.map(
                (m: IPartialUser) => ({
                  status: UserInvitationStatus.ALREADY_MEMBER,
                  user: m,
                })
              );
              setSearchResults([..._alreadyMembers]);
            }
          } else {
            setSearchResults([]);
          }
        } catch {}
      }
    }
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord]);

  const inputChangeFnc = useRef(
    debounce(1000, async (text: string) => {
      setSearchWord(text);
      if (validateEmail(text)) {
        setEmailEntered(true);
      } else {
        setEmailEntered(false);
      }
    })
  );

  const handleInputChange = (text: string) => {
    inputChangeFnc.current(text);
    setInputWord(text);
  };

  const maxNumberOfInvitesAllowed = 100000;
  // subscription.allowedUniqueUsers - subscription.currentUniqueUsers;
  const paidCustomer = true;
  // subscription.paidCustomer;

  const handleEmailResultClick = (email: string) => {
    setSearchWord("");
    setInputWord("");
    setEmailEntered(false);
    SearchWordInput.current?.focus();

    if (invitationUsers.filter((m) => m.content === searchWord).length === 0) {
      const newUser: IInvitationUser = {
        id: new uuidv4(),
        avatar: "",
        content: email,
        type: "email",
      };
      // TODO block here for unique and inviting
      setInvitationUsers([...invitationUsers, newUser]);
    }
  };

  const handleSearchResultClick = (result: ISearchResult) => {
    setSearchWord("");
    setSearchResults([]);
    setInputWord("");
    SearchWordInput.current?.focus();

    if (
      result.status === UserInvitationStatus.NON_MEMBER ||
      result.status === UserInvitationStatus.GUEST
    ) {
      // If the current search result is not included in the invitation list, then let's include it in invitation list.
      if (
        invitationUsers.filter((user) => user.id === result.user.id).length ===
        0
      ) {
        setInvitationUsers([
          ...invitationUsers,
          {
            type: "username",
            content: result.user.username,
            avatar: result.user.avatar,
            id: result.user.id,
          },
        ]);
      }
    } else {
      alert("Invitations can be only sent to non members of this base.");
    }
  };

  const handleInviteClick = async () => {
    setLoading(true);
    const invites = invitationUsers.map((m) => {
      if (m.type === "email") return { email: m.content };
      else return { username: m.content };
    });
    try {
      await axiosInstance.post("/api/invitation", {
        baseId: base.id,
        invites,
        groupId: props.groupId,
      });
      await refetchCollaborators();
      store.dispatch({
        type: ADD_NEW_UNIQUE_USER,
        numberOfInvites: invites.length,
      });
      hideModal();
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const removeInvitationUser = (idx: number) => {
    const _invitationUsers = [...invitationUsers];
    _invitationUsers.splice(idx, 1);
    setInvitationUsers(_invitationUsers);
  };

  const renderSearchResults = () =>
    searchResults.map((result) => (
      <div
        className={styles.invitation_modal_search_result}
        key={result.user.id}
        onClick={(e) => handleSearchResultClick(result)}
      >
        <div className={styles.invitation_modal_search_result_wrapper}>
          <>
            <UserInfo
              name={result.user.name || result.user.username}
              username={result.user.name ? result.user.username : ""}
              avatar={result.user.avatar}
            />
            {result.status === UserInvitationStatus.ALREADY_MEMBER && (
              <p className="caption secondary">Already a member of this base</p>
            )}
            {result.status === UserInvitationStatus.INVITED_MEMBER && (
              <p className="caption secondary">Already invited to this base</p>
            )}
            {result.status === UserInvitationStatus.GUEST && (
              <p className="caption secondary">Guest of this base</p>
            )}
          </>
        </div>
      </div>
    ));

  const renderInvitationUsersList = () => (
    <div className={styles.invitation_modal_invitation}>
      {invitationUsers.map((user: IInvitationUser, idx: number) => (
        <div key={user.id} className={styles.invitation_modal_invitation_user}>
          {user.type === "email" && <p>{user.content}</p>}
          {user.type === "username" && <p>@{user.content}</p>}
          <Button
            icon={<CloseOutlined />}
            buttonType={ButtonTypes.DEFAULT}
            onClick={() => removeInvitationUser(idx)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        zIndex: 99999,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <div className={styles.invitation_modal} style={{ zIndex: 1 }}>
        <div>
          <div className={styles.invitation_modal_header}>
            <h3>Add teammates to {props.groupId ?? base.name}</h3>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              onClick={handleInviteClick}
              isLoading={loading}
              disabled={
                paidCustomer
                  ? invitationUsers.length < 1 ||
                    maxNumberOfInvitesAllowed < invitationUsers.length
                  : false
              }
            >
              Send Invitations
            </Button>
          </div>
          {/* {paidCustomer && (
            <div style={{ paddingBottom: "20px" }}>
              {maxNumberOfInvitesAllowed - invitationUsers.length} invites
              remaining based on your subscription.
            </div>
          )} */}
          {invitationUsers.length > 0 && renderInvitationUsersList()}
          <ClarityInput
            placeholder="Search usernames, names, or emails"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputChange(e.currentTarget.value);
            }}
            value={inputWord}
            autoFocus={true}
            disabled={
              paidCustomer
                ? maxNumberOfInvitesAllowed <= invitationUsers.length
                : false
            }
            componentRef={SearchWordInput}
          />
        </div>
        <div className={styles.invitation_modal_result_container}>
          {emailEntered ? (
            <div
              className={styles.user_info}
              onClick={() => handleEmailResultClick(searchWord)}
            >
              <div className={styles.user_info__emailIcon}>
                <MailOutlined style={{ fontSize: "20px" }} />
              </div>
              <div className={styles.user_info_texts}>
                <p>{searchWord}</p>
                <p>Send invitation</p>
              </div>
            </div>
          ) : searchResults.length > 0 ? (
            renderSearchResults()
          ) : searchWord === "" ? (
            <p className={styles.invitation_modal_no_results}>
              Select one or more people to invite
            </p>
          ) : (
            <p className={styles.invitation_modal_no_results}>
              No results for the current search
            </p>
          )}
        </div>
      </div>
      <ModalScrimComponent hideModal={hideModal} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  base: state.workspace,
  subscription: state.subscription,
});

export function useInvitationsModalSetter(groupId?: string) {
  const dispatch = useDispatch();

  const showInvitationsModal = () =>
    dispatch({
      type: actionTypes.SET_INVITATIONS_MODAL,
      isOpen: true,
      groupId,
    });

  return {
    showInvitationsModal,
  };
}

export default connect(mapStateToProps)(ModalInvitations);
