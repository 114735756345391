import React, { useEffect, useState } from "react";
import {
  BaseType,
  ITokenGate,
  ITokenGateRule,
  ITokenRequirement,
  IUserObj,
  IWorkspaceObj,
  UserEventTypes,
} from "utilities/types";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import store, { ClarityStore } from "store/storeExporter";
import { locationSubject } from "components/LocationListener";
import TokenGate, { TokenGateReason } from "./TokenGate";
import { tokenGateApi } from "clientApi/tokenGatesApi";
import { baseApi } from "clientApi/baseApi";
import { SET_AUTHENTICATED_USER, SET_OPEN_BASE_VIEW } from "store/actions";
import notificationsApi from "clientApi/notificationsApi";
import BaseAvatar from "components/BaseAvatar";
import Button, { ButtonTypes } from "components/Button";
import DefaultPageHoc from "./templates/DefaultPageHoc";
import styles from "./templates/defaultPageHoc/defaultPageHoc.module.scss";
import SignUpLoginSwitcher from "components/SignUpLoginSwitcher";
import BaseNotFound from "./BaseNotFound";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import { axiosInstance } from "index";
import { useShallowSelector } from "utilities/hooks";
import Conditional from "components/Conditional";

interface IMatchProps {
  baseId: string;
}

const JoinBase: React.FC<IMatchProps> = ({ baseId }) => {
  const base = useShallowSelector((store) => store.workspace);
  const tokenGate = useSelector((store: ClarityStore) => {
    return base?.tokenGateId ? store.tokenGates.dict[base.tokenGateId] : null;
  }, shallowEqual);
  const location = useLocation();
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);
  const query = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    if (!baseId) {
      setIsLoading(false);
      return;
    }

    if (base.tokenGateId) {
      tokenGateApi
        .getBaseTokenGates(base.id)
        .then(() => setIsLoading(false))
        .catch((err) => setIsLoading(false));
    } else setIsLoading(false);
  }, [baseId]);

  useEffect(() => {
    if (!base) return;
    if (query.has("preview")) {
      if (!user || !user.id || !user.workspaceIds.includes(base.id)) {
        locationSubject.next({
          type: "replace",
          location: location.pathname,
        });
      }
      return;
    }
    if (user?.workspaceIds.includes(base.id)) {
      const baseObj = user.workspaceEntries[base.id];
      const rolename = baseObj.roleType;

      if (rolename && rolename !== "Guest") {
        locationSubject.next({ location: `/${base.slug}`, type: "replace" });
      }
    }
  }, [base, user]);

  if (isLoading) {
    return <></>;
  }

  if (!base || !base.name) {
    return <BaseNotFound />;
  }

  if (base.type !== BaseType.Private) return <BaseNotFound />;

  if (base.tokenGateId && !tokenGate) return <></>;

  return <IsJoinableView base={base} tokenGate={tokenGate} />;
};

const IsJoinableView: React.FC<{
  base: IWorkspaceObj;
  tokenGate: ITokenGate | null;
}> = ({ base, tokenGate }) => {
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);

  if (!base) return <></>;
  return (
    <>
      <DefaultPageHoc cardBackground cardOutline showLogo>
        <div>
          <BaseAvatar
            base={base}
            className={styles.baseAvatar}
            aviBorderStyles={{ borderRadius: "20px", display: "none" }}
          />
        </div>
        <h1 className={styles.h1}>Join {base.name}</h1>
        <p
          className={`${styles.description} ${styles.alignCenter} caption regular secondary`}
        >
          {base.tagline}
        </p>
        <div className={styles.divider}></div>

        <ConditionalTokenGate
          tokenRequirements={tokenGate?.tokenRequirements}
          rule={tokenGate?.rule}
        >
          <JoinSection
            user={user}
            base={base}
            hasTokenGate={tokenGate?.tokenRequirements ? true : false}
            showNotLoggedInHint={true}
          />
        </ConditionalTokenGate>
      </DefaultPageHoc>
    </>
  );
};

export const JoinSection: React.FC<{
  user?: IUserObj;
  base: IWorkspaceObj;
  hasTokenGate: boolean;
  afterAction?: () => void;
  viralSignupType?: UserEventTypes;
  showWhatIsSiwe?: boolean;
  showNotLoggedInHint?: boolean;
  autoAcceptOnLoginOrSignup?: boolean;
}> = ({
  user,
  base,
  hasTokenGate,
  afterAction,
  showWhatIsSiwe,
  showNotLoggedInHint,
  autoAcceptOnLoginOrSignup,
  viralSignupType,
}) => {
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const query = new URLSearchParams(location.search);

  const roleName = user?.workspaceIds.includes(base.id)
    ? user.workspaceEntries[base.id].roleType
    : undefined;

  const joinBase = () => {
    if (query.has("preview")) {
      notificationsApi.displayConfirmation({
        title: "Success",
        body: "The user would have now joined the base",
      });
      return;
    }
    if (!user) return;
    setloading(true);
    baseApi
      .joinBase(base.id)
      .then((res) => {
        return axiosInstance.get("/api/user").then((res) => {
          const { payload: user } = res.data;
          store.dispatch({
            type: SET_AUTHENTICATED_USER,
            user,
            forceUpdate: true,
          });
          store.dispatch({
            type: SET_OPEN_BASE_VIEW,
            param: {
              openBaseView: true,
            },
          });
          if (afterAction) {
            afterAction();
          } else {
            if (roleName && roleName === "Guest") {
              window.location.reload();
            } else {
              locationSubject.next(`/${base.slug}`);
            }
          }
          setloading(false);
        });
      })
      .catch((err) => {
        setloading(false);
        if (err.response?.data?.status === "Already a member") {
          locationSubject.next({ location: `/${base.slug}`, type: "replace" });
        }
        console.log(err);
      });
  };

  return (
    <>
      {user && user.id ? (
        <>
          <div
            className="body"
            style={{ marginBottom: "56px", textAlign: "center" }}
          >
            <Conditional
              on={
                base.type === BaseType.Public ||
                (base.type === BaseType.Private && base.tokenGateId)
              }
            >
              <Button
                buttonType={ButtonTypes.LARGE_PRIMARY}
                isLoading={loading}
                onClick={joinBase}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                Join
              </Button>
            </Conditional>
            <Conditional
              on={base.type === BaseType.Private && !base.tokenGateId}
            >
              <span className="body secondary">
                Contact members of this base to provide you with an invite link
              </span>
              <div>
                <Button
                  buttonType={ButtonTypes.PRIMARY}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "56px",
                  }}
                  onClick={() => {
                    window.location.replace(`/`);
                  }}
                >
                  Return to my content
                </Button>
              </div>
            </Conditional>

            <LoggedInUserHint user={user}>
              {hasTokenGate && (
                <>
                  and your connected wallet meets the token requirements to join
                  this base.
                </>
              )}
            </LoggedInUserHint>
          </div>
        </>
      ) : (
        <>
          <SignUpLoginSwitcher
            showWhatIsSiwe={showWhatIsSiwe}
            onLoginOrSignup={autoAcceptOnLoginOrSignup ? joinBase : undefined}
            viralSignupType={viralSignupType}
          />
          <Conditional on={showNotLoggedInHint}>
            <div
              className="caption regular disabled"
              style={{ textAlign: "center" }}
            >
              You must be logged into Clarity to join this base. You can log
              into an existing account, or create a new one using the buttons
              above.
            </div>
          </Conditional>
        </>
      )}
    </>
  );
};

const LoggedInUserHint: React.FC<{ user?: IUserObj }> = ({
  children,
  user,
}) => {
  if (!user) return <></>;
  return (
    <div
      className="caption regular disabled"
      style={{ textAlign: "center", paddingTop: "72px" }}
    >
      You are logged in as{" "}
      <b>{usernameSplitter(user.name || "@" + user.username)}</b> {children}
    </div>
  );
};

const ConditionalTokenGate: React.FC<{
  tokenRequirements: ITokenRequirement[] | undefined;
  rule: ITokenGateRule | undefined;
}> = ({ tokenRequirements, rule, children }) => {
  if (tokenRequirements) {
    return (
      <TokenGate
        tokenRequirements={tokenRequirements}
        linkRedeem={true}
        reason={TokenGateReason.joinBase}
        rule={rule}
      >
        {children}
      </TokenGate>
    );
  } else {
    return <>{children}</>;
  }
};

export default JoinBase;
