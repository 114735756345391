import React, { Suspense } from "react";
import { Block } from "store/reducers/blockReducer";
import { LineType } from "utilities/lineUtilities";
import { ContainerTypes, IBlockContext } from "utilities/types";
import EditableBlock from "./blockVariants/EditableBlock";
import ImageBlock from "./blockVariants/ImageBlock";
import RelatedPagesBlock from "./blockVariants/RelatedPagesBlock";
import WidgetBlock from "./blockVariants/WidgetBlock";
import WorkBlock from "./blockVariants/WorkBlock";
import VideoBlock from "./blockVariants/VideoBlock";
import DividerBlock from "./blockVariants/DividerBlock";
import FigmaBlock from "./blockVariants/FigmaBlock";
import WebLinkBlock from "./blockVariants/WebLinkBlock";
import { Skeleton } from "antd";
import TableBlock from "./blockVariants/TableBlock";

const CodeBlock = React.lazy(() => import("./blockVariants/CodeBlock"));
const LoomBlock = React.lazy(() => import("./blockVariants/LoomBlock"));
const PdfBlock = React.lazy(() => import("./blockVariants/PdfBlock"));
export interface BlockPropsWithRef {
  blockData: Block;
  parentListType: string;
  parentSetIsHovered: any;
  parentSelected: boolean;
  context: IBlockContext;
  changeBlock: any;
  isFirst: boolean;
  blockComponentData: {
    isFocused: boolean;
  };
  blockRef: React.MutableRefObject<HTMLDivElement | null>;
}

const BlockSplitter: React.FC<BlockPropsWithRef> = (props) => {
  switch (props.blockData.lineType) {
    case LineType.Title: {
      if (
        props.blockData.referencingContainerType === ContainerTypes.NOTE &&
        props.blockData.containerType ===
          props.blockData.referencingContainerType
      ) {
        return <RelatedPagesBlock {...props} />;
      }
      if (props.context.zoomedBlockId === props.blockData.id) {
        return <EditableBlock {...props} />;
      }
      if (
        props.blockData.referencingContainerType &&
        [ContainerTypes.PROJECT, ContainerTypes.TASK].includes(
          props.blockData.referencingContainerType
        )
      ) {
        return <WorkBlock {...props} />;
      }
      return <EditableBlock {...props} />;
    }

    // eslint-disable-next-line no-fallthrough
    case LineType.image:
      return <ImageBlock {...props} />;
    case LineType.work:
      return <WorkBlock {...props} />;
    case LineType.video:
      return (
        <Suspense fallback={<Skeleton avatar={false} />}>
          <VideoBlock {...props} />
        </Suspense>
      );
    case LineType.divider:
      return <DividerBlock {...props} />;
    case LineType.widget:
      return <WidgetBlock {...props} />;
    case LineType.loom:
      return (
        <Suspense fallback={<Skeleton avatar={false} />}>
          <LoomBlock {...props} />
        </Suspense>
      );
    case LineType.figma:
      return <FigmaBlock {...props} />;
    case LineType.code:
      return (
        <Suspense fallback={<Skeleton avatar={false} />}>
          <CodeBlock {...props} />
        </Suspense>
      );
    case LineType.pdf:
      return (
        <Suspense fallback={<Skeleton avatar={false} />}>
          <PdfBlock {...props} />
        </Suspense>
      );
    case LineType.webLink:
      return <WebLinkBlock {...props} />;
    case LineType.quote:
      return <EditableBlock {...props} />;
    case LineType.callout:
      return <EditableBlock {...props} />;
    case LineType.table:
      return <TableBlock {...props} />;
    default: {
      return <EditableBlock {...props} />;
    }
  }
};

export default BlockSplitter;
