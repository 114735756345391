import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useShallowSelector } from "utilities/hooks";
import {
  CommandPaletteContext,
  CommandPaletteContextSelectionsTypes,
  CommandPaletteDateFilterContextTypes,
  CommandPalettePropertiesContextTypes,
} from "utilities/types";
import GeneralMode from "./modes/General";
import WorkViewMode from "./modes/WorkView";
import MoveToMode from "./modes/MoveTo";
import styles from "./commandPalette.module.scss";
import ModalScrimComponent from "components/ModalScrimComponent";
import { handleSetDueDate, hideCommandPalette } from "./helpers/actions";
import OpenMode from "./modes/Open";
import TurnIntoMode from "./modes/TurnInto";
import BlocksInWorkItemsMode from "./modes/BlocksInWorkItems";
import FiltersMode from "./modes/Filters";
import {
  CommandPaletteOption,
  CommandPaletteState,
  filterscontextMatch,
  filtersDateContextMatch,
  IPaletteState,
  propertiesContextMatch,
} from "./helpers/types";
import { BehaviorSubject } from "rxjs";
import PaletteTopNav from "./components/PaletteTopNav";
import { VALUE_DOWN, VALUE_ENTER, VALUE_ESCAPE, VALUE_UP } from "keycode-js";
import { batch } from "react-redux";
import PaletteTextSearch from "./components/PaletteTextSearch";
import DefaultFiltersMode from "./modes/DefaultFilterModes";
import BlocksInDocumentMode from "./modes/BlocksInDocumentMode";
import { Moment } from "moment";
import PaletteDatePicker from "./components/DatePicker";
import { ChunkDestination } from "utilities/stateTypes";
import DefaultPropertiesModesHOC from "./modes/DefaultPropertiesModesHOC";

const BasePalette: React.FC = () => {
  const context = useShallowSelector((store) => store.commandPalette.context);
  const paletteStateContext = useRef<IPaletteState>({
    options: new BehaviorSubject([] as CommandPaletteOption[]),
    filteredOptions: new BehaviorSubject([] as CommandPaletteOption[]),
    selectedItemIndex: new BehaviorSubject(0),
    selectedItemName: new BehaviorSubject(""),
    topBarText: new BehaviorSubject(""),
    searchText: new BehaviorSubject(""),
    showCount: new BehaviorSubject<boolean>(false),
  });

  const prepareHandleKeyDown = useCallback((e) => {
    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
      switch (e.key) {
        case VALUE_UP: {
          const currentIndex =
            paletteStateContext.current.selectedItemIndex.value;
          const nextIndex = currentIndex - 1;
          if (nextIndex >= 0) {
            const item =
              paletteStateContext.current.filteredOptions.value[nextIndex];
            batch(() => {
              paletteStateContext.current.selectedItemIndex.next(nextIndex);
              paletteStateContext.current.selectedItemName.next(item.name);
            });
          }
          e.stopPropagation();
          e.preventDefault();
          break;
        }
        case VALUE_DOWN: {
          const currentIndex =
            paletteStateContext.current.selectedItemIndex.value;
          const nextIndex = currentIndex + 1;
          const currentFilteredItems =
            paletteStateContext.current.filteredOptions.value;
          if (nextIndex < currentFilteredItems.length) {
            const item =
              paletteStateContext.current.filteredOptions.value[nextIndex];
            batch(() => {
              paletteStateContext.current.selectedItemIndex.next(nextIndex);
              paletteStateContext.current.selectedItemName.next(item.name);
            });
          }
          e.stopPropagation();
          e.preventDefault();
          break;
        }
        case VALUE_ENTER: {
          const currentIndex =
            paletteStateContext.current.selectedItemIndex.value;
          const currentFilteredItems =
            paletteStateContext.current.filteredOptions.value;

          const item = currentFilteredItems[currentIndex];
          if (!item) return;
          if (!item.disable || (item.disable && !item.disable())) {
            item.action(e);
            if (item.closeAfter) {
              hideCommandPalette();
            }
          }
          e.stopPropagation();
          e.preventDefault();
          break;
        }
        case VALUE_ESCAPE: {
          hideCommandPalette();
          break;
        }
      }
    };
    return handleKeyDown(e);
  }, []);

  const isDataPicker = checkIsDatePicker(context);

  if (isDataPicker) {
    return (
      <div className={styles.modalCommandPaletteContainer}>
        <div className={styles.modalCommandPalette} style={{ height: "auto" }}>
          <PaletteTopNav>
            <DatePickerSwitcher context={context} />
          </PaletteTopNav>
        </div>
        <ModalScrimComponent hideModal={hideCommandPalette} light />
      </div>
    );
  }

  return (
    <div
      className={styles.modalCommandPaletteContainer}
      onKeyDown={prepareHandleKeyDown}
    >
      <CommandPaletteState.Provider value={paletteStateContext.current}>
        <div className={styles.modalCommandPalette}>
          <PaletteTopNav />
          <PaletteTextSearch context={context} />
          <TypeSwitcher context={context} />
        </div>
        <ModalScrimComponent hideModal={hideCommandPalette} light />
      </CommandPaletteState.Provider>
    </div>
  );
};

const checkIsDatePicker = (context: CommandPaletteContext) => {
  if (
    filtersDateContextMatch[context as CommandPaletteDateFilterContextTypes] ||
    context === CommandPaletteContext.DUE_DATE
  )
    return true;
};

const DatePickerSwitcher: React.FC<{
  context: CommandPaletteContext;
}> = ({ context }) => {
  const paneId = useShallowSelector(
    (store) => store.commandPalette.params.paneId ?? ChunkDestination.primary
  );
  const paletteStateContext = useContext(CommandPaletteState);

  useEffect(() => {
    if (
      filtersDateContextMatch[context as CommandPaletteDateFilterContextTypes]
    ) {
      paletteStateContext.topBarText.next(
        filtersDateContextMatch[context as CommandPaletteDateFilterContextTypes]
          .topBarText
      );
    } else if (context === CommandPaletteContext.DUE_DATE)
      paletteStateContext.topBarText.next(
        propertiesContextMatch[CommandPaletteContext.DUE_DATE].topBarText
      );
  }, [context]);

  if (
    filtersDateContextMatch[context as CommandPaletteDateFilterContextTypes]
  ) {
    const { onChange, defaultValue } = filtersDateContextMatch[
      context as CommandPaletteDateFilterContextTypes
    ].getValueAndSetter(
      context as CommandPaletteDateFilterContextTypes,
      paneId
    );

    const action = (date: Moment | null) => {
      onChange(date, paneId);
    };

    return <PaletteDatePicker onChange={action} defaultValue={defaultValue} />;
  }

  const action = (date: Moment | null) => {
    if (context === CommandPaletteContext.DUE_DATE) {
      handleSetDueDate(date);
    }
  };

  return <PaletteDatePicker onChange={action} />;
};

const TypeSwitcher: React.FC<{ context: CommandPaletteContext }> = ({
  context,
}) => {
  if (filterscontextMatch[context as CommandPaletteContextSelectionsTypes]) {
    return (
      <DefaultFiltersMode
        context={context as CommandPaletteContextSelectionsTypes}
      />
    );
  }

  if (propertiesContextMatch[context as CommandPalettePropertiesContextTypes]) {
    return (
      <DefaultPropertiesModesHOC
        context={context as CommandPalettePropertiesContextTypes}
      />
    );
  }

  switch (context) {
    case CommandPaletteContext.GENERAL: {
      return <GeneralMode />;
    }
    case CommandPaletteContext.WORK_VIEW: {
      return <WorkViewMode />;
    }
    case CommandPaletteContext.OPEN: {
      return <OpenMode />;
    }
    case CommandPaletteContext.MOVE_TO: {
      return <MoveToMode />;
    }
    case CommandPaletteContext.TURN_INTO: {
      return <TurnIntoMode />;
    }
    case CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_PROJECT: {
      return <BlocksInWorkItemsMode />;
    }
    case CommandPaletteContext.MULTIPLE_BLOCKS_SELECTED_IN_DOCUMENT: {
      return <BlocksInDocumentMode />;
    }
    case CommandPaletteContext.FILTERS_GENERAL: {
      return <FiltersMode />;
    }
  }
  return <></>;
};

export default BasePalette;
