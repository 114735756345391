// Vendor Libraries
import { connect, useDispatch } from "react-redux";
import React, { useEffect, memo, useMemo, useRef, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";

// Components
import Document from "./documentContainer/Document";
import Project from "./documentContainer/Project";

// Internal Modules
import * as actionTypes from "../../../../store/actions";
import {
  Abilities,
  ContainerTypes,
  IBlockContext,
  ViewNames,
} from "../../../../utilities/types";
import { flashBlock, clearFlashBlock, socket } from "../../../../App";
import store, { prevState } from "../../../../store/storeExporter";
import { LineValueType } from "../../../../utilities/lineUtilities";
import ActiveUsers from "./documentContainer/document/ActiveUsers";
import DocumentChangesListener from "./documentContainer/document/DocumentChangesListener";
import Note from "./documentContainer/Note";
import WorkActivity from "./documentContainer/WorkActivity";
import {
  checkManualTrigger,
  insideContent,
  mouseDown,
  selectionDisable,
  selectionStarted,
  setInisdeContent,
  setMouseDown,
  setPreventClick,
  setSelectionStarted,
} from "components/Overlay";
import { throttle } from "lodash";
import DefaultContainer from "./documentContainer/DefaultContainer";
import { useAbilityChecker } from "editor/utils/customHooks";
import "./documentContainer/document/lineContainer/LineContainer.scss";
import navigationApi from "clientApi/navigationApi";
import { getGroupsFromEntityId } from "modules/containerHelpers";
import Template from "./documentContainer/Template";
import Snippet from "./documentContainer/Snippet";
import { ChunkDestination } from "utilities/stateTypes";

interface IDocumentComponentProps {
  paneId: ChunkDestination;
  showDefault?: boolean;
  containerId?: string;
  containerType?: string;
  newIdToFocus: string;
  caretPosition: number;
  typeOfNewFocus: string;
  base?: any;
  diff: number;
  connected: boolean;
  workItem?: any;
  contextPresets?: Partial<IBlockContext>;
}

export let mouseDownEvent: any;
export const setMouseDownEvent = (e: any) => {
  mouseDownEvent = e;
};

const DocumentContainer: React.FC<IDocumentComponentProps> = (props) => {
  const match = useRouteMatch<any>();
  const history = useHistory();
  const location = useLocation();
  const dispatcher = useDispatch();
  const ref = useRef<HTMLDivElement | any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [draggedOver, setDraggedOver] = useState(false);
  const userRole = store.getState().client.roleType;

  useEffect(() => {
    socket.on("replyUpdate", (data: any) => {
      if (data.type === "new") {
        store.dispatch({
          type: "ADD_REPLY_TO_DISCUSSION",
          discussionId: data.discussion.id,
          discussionType: data.discussion.type,
          containerType: data.discussion.containerType,
          id: data.discussion.containerId,
          reply: data.reply,
        });
      }
      if (data.type === "update") {
        store.dispatch({
          type: "UPDATE_REPLY_IN_DISCUSSION",
          discussionId: data.discussion.id,
          discussionType: data.discussion.type,
          containerType: data.discussion.containerType,
          discussion: data.discussion,
          id: data.discussion.containerId,
          reply: data.reply,
        });
      }
    });
    socket.on("deleteReply", (data: any) => {
      store.dispatch({
        type: "REMOVE_REPLY_FROM_DISCUSSION",
        discussionId: data.discussion.id,
        discussionType: data.discussion.type,
        containerType: data.discussion.containerType,
        discussion: data.discussion,
        id: data.discussion.containerId,
        reply: data.reply,
      });
    });
    socket.on("deleteDiscussion", (data: any) => {
      store.dispatch({
        type: "REMOVE_DISCUSSION",
        discussionId: data.discussion.id,
        discussionType: data.discussion.type,
        containerType: data.discussion.containerType,
        discussion: data.discussion,
        id: data.discussion.containerId,
      });
    });
    return function cleanup() {
      socket.removeEventListener("replyUpdate");
      socket.removeEventListener("deleteReply");
      socket.removeEventListener("deleteDiscussion");
    };
  }, []);

  useEffect(() => {
    socket.on("addDiscussion", (data: any) => {
      store.dispatch({
        type: "ADD_DISCUSSION",
        discussion: data.discussion,
      });
    });
    socket.on("updateDiscussion", (data: any) => {
      store.dispatch({
        type: "UPDATE_DISCUSSION",
        discussion: data.discussion,
      });
    });
    return function cleanup() {
      socket.removeEventListener("addDiscussion");
      socket.removeEventListener("updateDiscussion");
    };
  }, []);

  return useMemo(() => {
    if (props.containerId && props.containerId !== "")
      return (
        <>
          <div
            id="container"
            ref={ref}
            onMouseDown={(e) => {
              if (!mouseDown) {
                setMouseDown(true);
                const target: any = e.target;
                e.persist();
                mouseDownEvent = e;
                if (
                  target?.className.includes?.("content-section") ||
                  target?.closest?.(".content-section")
                ) {
                  setInisdeContent(true);
                } else {
                  setInisdeContent(false);
                }
              }
            }}
            onClick={(e: any) => {
              if (flashBlock.id) {
                const flashingElements =
                  document.getElementsByClassName("flash");
                Array.from(flashingElements).forEach((element) => {
                  element.classList.remove("flash");
                });
                clearFlashBlock();
              }

              if (
                e.target.nodeName === LineValueType.mention &&
                e.target.classList.contains("hashtag") &&
                props.containerType !== ContainerTypes.WORK_ACTIVITY
              ) {
                const workTagCheck = e.target.getAttribute("work-tag");
                const entityId = e.target.getAttribute("href");

                const containerType =
                  workTagCheck === "true"
                    ? ContainerTypes.PROJECT
                    : ContainerTypes.DOCUMENT;

                navigationApi.contextBasedNavigate({
                  currentPane: props.paneId,
                  forceToPane: ChunkDestination.peek,
                  shiftKey: e.shiftKey,
                  navigationChunk: {
                    viewName: ViewNames.Detail,
                    entity: {
                      containerId: entityId,
                      containerType,
                    },
                  },
                });
              }
            }}
            onMouseMove={(e) => {
              handleStartSelection(e);
            }}
            onContextMenu={(e) => {
              setMouseDown(false);
              setSelectionStarted(false);
              mouseDownEvent = null;
              selectionDisable();
            }}
            onMouseUp={(e) => {
              setMouseDown(false);
              setSelectionStarted(false);
              mouseDownEvent = null;
              selectionDisable();
            }}
            onDragEnter={(e) => {
              if (!draggedOver && !prevState.value.container.dragStart) {
                dispatcher({
                  type: actionTypes.DRAG_TOGGLE,
                  dragState: true,
                });
                e.preventDefault();
              }
            }}
            onDrop={(e) => {
              e.stopPropagation();
              e.preventDefault();
              dispatcher({
                type: actionTypes.DRAG_TOGGLE,
                dragState: false,
              });
            }}
            style={{ position: "relative" }}
          >
            {
              <DocumentContainerConnector
                history={history}
                sharedView={false}
                location={location}
                base={props.base}
                match={match}
                workItem={props.workItem}
                contextPresets={props.contextPresets}
                showDefault={props.showDefault}
                paneId={props.paneId}
                containerId={props.containerId}
                containerType={props.containerType}
                userRole={userRole}
              />
            }

            {draggedOver && <div className="container-dropzone"></div>}
          </div>
        </>
      );
    else return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerId, draggedOver, props.connected]);
};

const DocumentContainerConnector: React.FC<any> = (props) => {
  const canEditContainer = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: getGroupsFromEntityId(
      props.containerId,
      props.containerType
    ),
  });

  return useMemo(() => {
    if (props.showDefault) {
      return (
        <DefaultContainer
          containerId={props.containerId}
          contextPresets={props.contextPresets}
        />
      );
    }
    return (
      <>
        {[ContainerTypes.PROJECT, ContainerTypes.TASK].includes(
          props.containerType
        ) && (
          <>
            <ActiveUsers documentId={props.containerId} />
            <DocumentChangesListener
              containerId={props.containerId}
            ></DocumentChangesListener>
            <Project
              sharedView={false}
              paneId={props.paneId}
              containerId={props.containerId}
            />
          </>
        )}
        {props.containerType === ContainerTypes.DOCUMENT && (
          <>
            <ActiveUsers documentId={props.containerId} />
            <DocumentChangesListener
              containerId={props.containerId}
            ></DocumentChangesListener>
            <Document
              history={props.history}
              sharedView={false}
              location={props.location}
              key={props.containerId}
              match={props.match}
              paneId={props.paneId}
              containerId={props.containerId}
            />
          </>
        )}
        {props.containerType === ContainerTypes.NOTE && (
          <>
            <ActiveUsers documentId={props.containerId} />
            <DocumentChangesListener
              containerId={props.containerId}
            ></DocumentChangesListener>
            <Note
              history={props.history}
              sharedView={false}
              location={props.location}
              key={props.containerId}
              match={props.match}
              paneId={props.paneId}
              containerId={props.containerId}
            />
          </>
        )}
        {props.containerType === ContainerTypes.TEMPLATE && (
          <>
            <ActiveUsers documentId={props.containerId} />
            <DocumentChangesListener
              containerId={props.containerId}
            ></DocumentChangesListener>
            <Template
              history={props.history}
              sharedView={false}
              location={props.location}
              key={props.containerId}
              match={props.match}
              paneId={props.paneId}
              containerId={props.containerId}
            />
          </>
        )}
        {props.containerType === ContainerTypes.SNIPPET && (
          <>
            <ActiveUsers documentId={props.containerId} />
            <DocumentChangesListener
              containerId={props.containerId}
            ></DocumentChangesListener>
            <Snippet
              history={props.history}
              sharedView={false}
              location={props.location}
              key={props.containerId}
              match={props.match}
              paneId={props.paneId}
              containerId={props.containerId}
            />
          </>
        )}
        {props.containerType === ContainerTypes.WORK_ACTIVITY && (
          <>
            <WorkActivity
              history={props.history}
              sharedView={false}
              contextPresets={props.contextPresets}
              location={props.location}
              key={props.containerId}
              match={props.match}
              workItem={props.workItem}
              paneId={props.paneId}
              containerId={props.containerId}
            />
          </>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerId, canEditContainer]);
};

const handleStartSelection = throttle(
  (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (mouseDown && !insideContent && !selectionStarted) {
      mouseDownEvent.preventDefault();
      mouseDownEvent.stopPropagation();
      setSelectionStarted(true);
      setPreventClick(true);
      setTimeout(() => {
        checkManualTrigger(mouseDownEvent, insideContent);
      }, 1);
    }
  },
  200
);

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    connected: state.network.connected,
    base: state.workspace,
  };
};

const checkProps = (prevProps: any, nextProps: any) => {
  return prevProps.containerId === nextProps.containerId;
};

export default connect(
  mapStateToProps,
  null
)(memo(DocumentContainer, checkProps));
