import * as actions from "store/actions";
import {
  IProjectObj,
  IWorkSectionObj,
  IWorkActivity,
  WorkActivityTypes,
  IWorkStatus,
  workStatusCategories,
  WorkTypes,
} from "utilities/types";
import { LinesObject, Line } from "utilities/lineUtilities";
import React from "react";
import { ISetActiveWorkspaceActionParameters } from "./workspaceReducer";
import { updateBlockCache } from "store/storeExporter";
import { useSelector, shallowEqual } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import _ from "lodash";

export const WORK_PAGE_SIZE = 300;

// -------------------------------------------------
// TYPES
export interface Cycle {
  groupId: string;
  id: string;
  type: string;
  name: string;
  rank: string;
  isClosed: boolean;
  workIds: string[];
  isFolded: boolean;
  isBeingRenamed?: boolean;
  dateCreated: Date;
}

export interface IMilestone {
  id: string;
  type: string;
  name: string;
  rank: string;
  isClosed: boolean;
  workIds: string[];
  isFolded: boolean;
  isBeingRenamed?: boolean;
}

export interface TimeframeEntry {
  [id: string]: Cycle;
}

export interface WorkActivityEntry {
  [id: string]: IWorkActivity;
}

export interface WorkStatusEntry {
  [id: string]: IWorkStatus;
}

export interface WorkEntry {
  [id: string]: IProjectObj;
}

// -------------------------------------------------
// CREATE
interface IAddSectionActionParameters {
  type: string;
  section: IWorkSectionObj;
}

interface IAddNewProjectActionParameters {
  type: string;
  project: IProjectObj;
  projectId?: string;
}

interface IAddNewTaskActionParameters {
  type: string;
  task: IProjectObj;
  sectionId?: string;
}

interface IAddWorkCitationActionParameters {
  type: string;
  workId: string;
  citation: any;
}

// -------------------------------------------------
// READ
interface ILoadBaseWorkActionParameters {
  type: string;
  work: IProjectObj[];
  workObj: WorkEntry;
  somedayIds: string[];
  closedWorkItemIds: string[];
  allProjectIds: string[];
  allInitiativeIds: string[];
  allTaskIds: string[];
  openTimeframeIds: string[];
  closedTimeframeIds: string[];
  timeframeEntries: TimeframeEntry;
  statuses: IWorkStatus[];
  milestonesDict: { [id: string]: IMilestone };
  milestoneIds: string[];
}

interface ILoadTaskActivities {
  type: string;
  id: string;
  workActivities: IWorkActivity[];
}

interface ILoadCycles {
  type: string;
  id: string;
  cycles: any[];
}

interface ISetWorkMentionsActionParameters {
  type: string;
  workId: string;
  mentions: any;
}

interface ISetWorkCitationsActionParameters {
  type: string;
  workId: string;
  citations: any;
}

// -------------------------------------------------
// UPDATE
interface IReorderWorkItemActionParameters {
  type: string;
  endSectionId: string;
  newEndSectionArray: string[];
  startSectionId?: string;
  newStartSectionArray?: string[];
}

interface IUpdateWorkItemActionParamters {
  type: string;
  workItem: IProjectObj;
}

interface IUpdateWorkItemsActionParameters {
  type: string;
  workItems: IProjectObj[];
}

interface IPatchSectionParameters {
  type: string;
  patchSection: {
    id: string;
    patch: any;
    groupId: string;
    preset?: boolean;
  };
}

interface IChangeTaskParentActionParameters {
  type: string;
  id: string;
  idsToMove: string[];
}

interface IUpdateWorkActivityActionParameters {
  type: string;
  workActivity: IWorkActivity;
}

interface IDeleteWorkActivityActionParameters {
  type: string;
  workActivity: IWorkActivity;
}

interface IUpdateProjectsBatchActionParameters {
  type: string;
  changesForProjects: any;
}

interface ISetActiveEditingTaskActionParameters {
  type: string;
  id: string;
}

interface ISetActiveListNavigation {
  type: string;
  param: {
    listItems: string[] | null;
  };
}

interface ISetSectionFoldedActionParameters {
  type: string;
  timeframeId: string;
  isFolded: boolean;
}

interface IUpdateWorkSectionParameters {
  type: string;
  param: { delta: Partial<IWorkStatus>; id: string; type?: string };
}

interface IUpdateMilestoneParameters {
  type: string;
  param: {
    delta: Partial<IMilestone> | IMilestone;
    id: string;
    type: "update" | "insert" | "delete";
  };
}

// -------------------------------------------------
// DELETE
interface IDeleteSectionParameters {
  type: string;
  sectionId: string;
}

interface IInitialState {
  dict: WorkEntry;
  sections: TimeframeEntry;
  currentListNavigationItems: string[] | null;
  justClosedTimeframeIds: string[];
  justReopenedTimeframeIds: string[];
  workActivities: WorkActivityEntry;
  editingTask: string | null;
  activeBaseId: string | null;
  statuses: {
    statusArray: string[];
    dict: WorkStatusEntry;
  };
  allTaskIds: string[];
  allProjectIds: string[];
  allInitiativeIds: string[];
  activeWorkIds: string[];
  creatingNewCycle: boolean;
  followingWorkIds: string[];
  milestoneIds: string[];
  milestoneDict: { [id: string]: IMilestone };
  groupCycles: {
    [key: string]: {
      openTimeframeIds: string[];
      closedTimeframeIds: string[];
    };
  };
}

// when adding task -> id = workItem.groupId + workItem.projectId
// when adding project -> id = workItem.projectId
export let workItemNrToId: { [id: string]: string } = {};

const initialState: IInitialState = {
  dict: {} as WorkEntry,
  sections: {},
  justClosedTimeframeIds: [],
  justReopenedTimeframeIds: [],
  groupCycles: {},
  currentListNavigationItems: null,
  workActivities: {
    newComment: {
      id: "newComment",
      authorId: null,
      dateCreated: null,
      dateModified: null,
      isDeleted: false,
      type: WorkActivityTypes.COMMENT,
      author: null,
      blocksState: {},
      taskId: "newComment",
    },
  } as WorkActivityEntry,
  editingTask: null,
  activeBaseId: null,
  statuses: {
    statusArray: [],
    dict: {} as WorkStatusEntry,
  },
  allTaskIds: [],
  allProjectIds: [],
  allInitiativeIds: [],
  activeWorkIds: [],
  creatingNewCycle: false,
  followingWorkIds: [],
  milestoneIds: [],
  milestoneDict: {},
};

export default function workReducer(
  state = initialState,
  action: ILoadBaseWorkActionParameters &
    ILoadTaskActivities &
    IReorderWorkItemActionParameters &
    IUpdateWorkItemActionParamters &
    IAddSectionActionParameters &
    IDeleteSectionParameters &
    IPatchSectionParameters &
    IAddNewProjectActionParameters &
    IAddNewTaskActionParameters &
    IChangeTaskParentActionParameters &
    ISetWorkMentionsActionParameters &
    ISetWorkCitationsActionParameters &
    IAddWorkCitationActionParameters &
    IUpdateWorkActivityActionParameters &
    IDeleteWorkActivityActionParameters &
    IUpdateProjectsBatchActionParameters &
    ISetActiveEditingTaskActionParameters &
    ISetActiveWorkspaceActionParameters &
    ISetSectionFoldedActionParameters &
    IUpdateWorkSectionParameters &
    ISetActiveListNavigation &
    IUpdateMilestoneParameters &
    ILoadCycles &
    IUpdateWorkItemsActionParameters
) {
  switch (action.type) {
    // CREATE
    case actions.ADD_SECTION: {
      if (action.section) {
        const newState = {
          ...state,
          sections: { ...state.sections },
          groupCycles: { ...state.groupCycles },
        };
        newState.groupCycles[action.section.groupId] = {
          ...newState.groupCycles[action.section.groupId],
          openTimeframeIds: newState.groupCycles[action.section.groupId]
            ? [
                ...newState.groupCycles[action.section.groupId]
                  ?.openTimeframeIds,
                action.section.id,
              ]
            : [action.section.id],
        };
        newState.sections[action.section.id] = {
          ...action.section,
          type: "Custom",
          workIds: [],
          isFolded: false,
        };
        return newState;
      }
      return state;
    }

    case actions.ADD_NEW_PROJECT:
    case actions.ADD_NEW_WORK_ITEM: {
      if (action.project) {
        const newProject = action.project;
        const newState = {
          ...state,
        };
        addToWorkObject(newState, newProject);
        if (newProject.projectId) {
          newState.activeWorkIds = getOpenWorkIds(newState.dict);
        }
        return newState;
      }
      return state;
    }

    case actions.SET_LIST_ITEMS: {
      const newState = { ...state };
      newState.currentListNavigationItems = action.param.listItems;
      return newState;
    }

    // -----------------------------------------------------------------------
    // READ
    case actions.LOAD_BASE_WORK: {
      if (action.work) {
        const newState: IInitialState = {
          ...state,
          dict: {} as WorkEntry,
          milestoneIds: action.milestoneIds ? [...action.milestoneIds] : [],
          milestoneDict: action.milestonesDict
            ? { ...action.milestonesDict }
            : {},
          sections: {
            ...state.sections,
            closedWorkItems: {
              ...state.sections.closedWorkItems,
              workIds: [...action.closedWorkItemIds],
            },
          } as TimeframeEntry,
          allTaskIds: [...action.allTaskIds],
          allProjectIds: [...action.allProjectIds],
          allInitiativeIds: action.allInitiativeIds
            ? [...action.allInitiativeIds]
            : [],
          statuses: {
            dict: {},
            statusArray: [],
          },
        };

        if (action.work && Array.isArray(action.work)) {
          action.work.forEach((workItem: IProjectObj) => {
            addWorkItemToIdIndex(workItem);

            if (state.dict?.[workItem.id]) {
              return (newState.dict[workItem.id] = {
                ...state.dict[workItem.id],
                ...workItem,
              });
            }
            return (newState.dict[workItem.id] = workItem);
          });
        }
        if (action.openTimeframeIds && Array.isArray(action.openTimeframeIds)) {
          action.openTimeframeIds.forEach((sectionId: string) => {
            newState.sections[sectionId] = {
              ...state.sections[sectionId],
              ...action.timeframeEntries[sectionId],
            };
          });
        }
        if (
          action.closedTimeframeIds &&
          Array.isArray(action.closedTimeframeIds)
        ) {
          action.closedTimeframeIds.forEach((sectionId: string) => {
            newState.sections[sectionId] = {
              ...action.timeframeEntries[sectionId],
              isFolded: true,
            };
          });
        }
        if (action.statuses) {
          const statusObject: WorkStatusEntry = {};
          const orderedStatuses: string[] = [];
          action.statuses
            .map((status) => {
              status.composedRank =
                workStatusCategories[status.categoryId].rank + status.rank;
              statusObject[status.id] = status;
              return status;
            })
            .sort((a: IWorkStatus, b: IWorkStatus) => {
              const composeA = a.categoryId + a.composedRank;
              const composeB = b.categoryId + b.composedRank;

              if (composeA < composeB) {
                return -1;
              }
              if (composeA > composeB) {
                return 1;
              }
              return 0;
            })
            .forEach((status) => {
              orderedStatuses.push(status.id);
            });
          newState.statuses.dict = statusObject;
          newState.statuses.statusArray = orderedStatuses;
        }
        newState.activeWorkIds = getOpenWorkIds(newState.dict);

        return newState;
      }
      return state;
    }

    case actions.LOAD_TASKS: {
      const newState: IInitialState = {
        ...state,
        dict: { ...state.dict },
        allTaskIds: [...state.allTaskIds],
      };

      if (action.work && Array.isArray(action.work)) {
        action.work.forEach((workItem: IProjectObj) => {
          addWorkItemToIdIndex(workItem);
          newState.allTaskIds.push(workItem.id);
          if (state.dict?.[workItem.id])
            return (newState.dict[workItem.id] = {
              ...state.dict[workItem.id],
              ...workItem,
            });
          return (newState.dict[workItem.id] = workItem);
        });
        newState.allTaskIds = [...Array.from(new Set(newState.allTaskIds))];
        return newState;
      }
      return state;
    }

    case actions.LOAD_PROJECTS: {
      const newState: IInitialState = {
        ...state,
        dict: { ...state.dict },
        allProjectIds: [...state.allProjectIds],
        allInitiativeIds: [...state.allInitiativeIds],
        milestoneIds: action.milestoneIds
          ? [...action.milestoneIds]
          : [...state.milestoneIds],
        milestoneDict: action.milestonesDict
          ? { ...action.milestonesDict }
          : { ...state.milestoneDict },
      };

      if (action.work && Array.isArray(action.work)) {
        action.work.forEach((workItem: IProjectObj) => {
          addWorkItemToIdIndex(workItem);
          if (workItem.workType === WorkTypes.PROJECT)
            newState.allProjectIds.push(workItem.id);
          if (workItem.workType === WorkTypes.INITIATIVE)
            newState.allInitiativeIds.push(workItem.id);

          if (state.dict?.[workItem.id])
            return (newState.dict[workItem.id] = {
              ...state.dict[workItem.id],
              ...workItem,
            });
          return (newState.dict[workItem.id] = workItem);
        });
        newState.allProjectIds = [
          ...Array.from(new Set(newState.allProjectIds)),
        ];
        newState.allInitiativeIds = [
          ...Array.from(new Set(newState.allInitiativeIds)),
        ];
        return newState;
      }
      return state;
    }

    case actions.LOAD_STATUSES: {
      const newState = { ...state };
      newState.statuses = {
        ...newState.statuses,
        dict: { ...newState.statuses.dict },
        statusArray: [...newState.statuses.statusArray],
      };

      if (action.statuses) {
        const statusObject: WorkStatusEntry = {};
        const orderedStatuses: string[] = [];
        action.statuses
          .map((status) => {
            status.composedRank =
              workStatusCategories[status.categoryId].rank + status.rank;
            statusObject[status.id] = status;
            return status;
          })
          .sort((a: IWorkStatus, b: IWorkStatus) => {
            const composeA = a.categoryId + a.composedRank;
            const composeB = b.categoryId + b.composedRank;

            if (composeA < composeB) {
              return -1;
            }
            if (composeA > composeB) {
              return 1;
            }
            return 0;
          })
          .forEach((status) => {
            orderedStatuses.push(status.id);
          });
        newState.statuses.dict = statusObject;
        newState.statuses.statusArray = orderedStatuses;
        return newState;
      }
      return state;
    }

    case actions.LOAD_BASE_CYCLES: {
      const newState = {
        ...state,
        groupCycles: {
          ...state.groupCycles,
        },
        sections: {
          ...state.sections,
        },
      };

      const timeframeEntries: any = {};

      if (action.cycles && Array.isArray(action.cycles)) {
        const grouped = _.groupBy(action.cycles, "groupId");
        for (const groupId of Object.keys(grouped)) {
          const openTimeframeIds = [];

          const closedTimeframeIds = [];
          const cycles = grouped[groupId];
          for (const cycle of cycles) {
            if (cycle.isClosed) {
              closedTimeframeIds.push(cycle.id);
            } else {
              openTimeframeIds.push(cycle.id);
            }
            timeframeEntries[cycle.id] = {
              ...cycle,
            };
          }
          newState.groupCycles[groupId] = {
            openTimeframeIds: openTimeframeIds,
            closedTimeframeIds: closedTimeframeIds,
          };
        }
        newState.sections = {
          ...newState.sections,
          ...timeframeEntries,
        };
      }
      return newState;
    }

    case actions.LOAD_RELATED_WORK: {
      if (action.work) {
        const newState = {
          ...state,
          dict: { ...state.dict } as WorkEntry,
        };
        const obj: any = {};
        action.work.forEach((workItem) => {
          if (workItem) {
            addWorkItemToIdIndex(workItem);
            if (state.dict[workItem.id]) {
              obj[workItem.id] = {
                ...state.dict[workItem.id],
                ...workItem,
              };
            } else {
              obj[workItem.id] = workItem;
            }
          }
        });
        newState.dict = { ...newState.dict, ...obj };
        newState.activeWorkIds = getOpenWorkIds(newState.dict);
        return newState;
      }
      return state;
    }

    case actions.LOAD_TASK_ACTIVITIES: {
      if (action.workActivities && action.workActivities.length > 0) {
        const newState = {
          ...state,
          workActivities: { ...state.workActivities },
          dict: { ...state.dict },
        };
        if (action.id) {
          const task = { ...newState.dict[action.id] };
          if (task.workActivities?.length > 0) {
            if (
              JSON.stringify(action.workActivities.map((value) => value.id)) ===
              JSON.stringify(task.workActivities)
            ) {
              return state;
            }
          }

          task.workActivities = [];

          if (action.workActivities && Array.isArray(action.workActivities)) {
            action.workActivities.map((activity: IWorkActivity | any) => {
              if (!task.workActivities?.includes(activity.id)) {
                task.workActivities?.push(activity.id);
              }
              activity.blocksState = prepareStateForFrontend(
                activity.linesArray
              );
              updateBlockCache(activity.blocksState.lineObject.entities.lines);
              return (newState.workActivities[activity.id] = activity);
            });
          }
          newState.dict[action.id] = { ...task };
        }
        return newState;
      }
      return state;
    }

    case actions.SET_WORK_IN_DICT: {
      const newState = {
        ...state,
      };
      newState.dict = { ...newState.dict, ...action.workObj };
      return newState;
    }

    // -----------------------------------------------------------------------
    // UPDATE
    case actions.SET_SECTION_FOLDED: {
      if (action.timeframeId) {
        const prevTimeframe = state.sections[action.timeframeId];

        const newState = {
          ...state,
          sections: {
            ...state.sections,
            [action.timeframeId]: {
              ...prevTimeframe,
              isFolded: action.isFolded,
            },
          },
        };
        return newState;
      }
      return state;
    }

    case actions.REORDER_WORK_ITEM: {
      if (action.endSectionId && action.newEndSectionArray) {
        const newState = {
          ...state,
          sections: {
            ...state.sections,
            [action.endSectionId]: {
              ...state.sections[action.endSectionId],
              workIds: [...action.newEndSectionArray],
            },
          },
        };

        if (action.startSectionId && action.newStartSectionArray) {
          newState.sections[action.startSectionId] = {
            ...state.sections[action.startSectionId],
            workIds: [...action.newStartSectionArray],
          };
        }
        return newState;
      } else {
        const newState = {
          ...state,
          sections: {
            ...state.sections,
          },
        };

        if (action.startSectionId && action.newStartSectionArray) {
          newState.sections[action.startSectionId] = {
            ...state.sections[action.startSectionId],
            workIds: [...action.newStartSectionArray],
          };
        }
        return newState;
      }
    }

    case actions.CLEAR_JUST_CLOSED_WORK_ITEMS: {
      const newState = {
        ...state,
        justClosedTimeframeIds: [],
        justReopenedTimeframeIds: [],
      };
      const justClosedTimeframes = state.justClosedTimeframeIds;
      const justReopenedTimeframeIds = state.justReopenedTimeframeIds;

      if (justClosedTimeframes.length > 0) {
        const firstCycle = newState.sections[justClosedTimeframes[0]];
        newState.groupCycles = {
          ...newState.groupCycles,
        };
        newState.groupCycles[firstCycle.groupId] = {
          ...newState.groupCycles[firstCycle.groupId],
        };
        const openTimeframeIds = [
          ...newState.groupCycles[firstCycle.groupId]?.openTimeframeIds,
        ];
        const closedTimeframes = newState.groupCycles[firstCycle.groupId]
          ?.closedTimeframeIds
          ? [...newState.groupCycles[firstCycle.groupId]?.closedTimeframeIds]
          : [];
        justClosedTimeframes.forEach((id) => {
          closedTimeframes.unshift(id);
          const index = openTimeframeIds.indexOf(id);
          if (index > -1) {
            openTimeframeIds.splice(index, 1);
          }
        });
        newState.groupCycles[firstCycle.groupId].openTimeframeIds = [
          ...openTimeframeIds,
        ];
        newState.groupCycles[firstCycle.groupId].closedTimeframeIds = [
          ...closedTimeframes,
        ];
      }

      if (justReopenedTimeframeIds.length > 0) {
        const firstCycle = newState.sections[justReopenedTimeframeIds[0]];
        newState.groupCycles = {
          ...newState.groupCycles,
        };
        newState.groupCycles[firstCycle.groupId] = {
          ...newState.groupCycles[firstCycle.groupId],
        };
        const closedTimeframes = [
          ...newState.groupCycles[firstCycle.groupId].closedTimeframeIds,
        ];
        const openTimeframeIds = [
          ...newState.groupCycles[firstCycle.groupId].openTimeframeIds,
        ];
        justReopenedTimeframeIds.forEach((id) => {
          openTimeframeIds.push(id);
          const index = closedTimeframes.indexOf(id);
          if (index > -1) {
            closedTimeframes.splice(index, 1);
          }
        });
        newState.groupCycles[firstCycle.groupId].openTimeframeIds =
          openTimeframeIds.sort((a: string, b: string) => {
            if (newState.sections[a].rank && newState.sections[b].rank) {
              if (newState.sections[a].rank < newState.sections[b].rank) {
                return -1;
              }
              if (newState.sections[a].rank > newState.sections[b].rank) {
                return 1;
              }
            }
            return 0;
          });
        newState.groupCycles[firstCycle.groupId].closedTimeframeIds = [
          ...closedTimeframes,
        ];
      }

      return newState;
    }

    case actions.UPDATE_WORK_ITEM: {
      if (action.workItem && action.workItem.id) {
        const newState = { ...state, dict: { ...state.dict } };
        const updatedWork = action.workItem;
        const nonUpdatedWorkItem = { ...state.dict[updatedWork?.id] };

        if (state.dict[updatedWork.id]) {
          newState.dict[updatedWork.id] = {
            ...newState.dict[updatedWork.id],
            ...updatedWork,
          };
        } else {
          newState.dict[updatedWork.id] = updatedWork;
        }

        newState.activeWorkIds = getOpenWorkIds(newState.dict);
        if (action.workItem.workType === WorkTypes.PROJECT) {
          if (!newState.allProjectIds.includes(action.workItem.id)) {
            newState.allProjectIds.push(action.workItem.id);
          }
        } else if (action.workItem.workType === WorkTypes.INITIATIVE) {
          if (!newState.allInitiativeIds.includes(action.workItem.id)) {
            newState.allInitiativeIds.push(action.workItem.id);
          }
        } else if (
          action.workItem.workType === WorkTypes.TASK &&
          updatedWork.projectId
        ) {
          const workItem = action.workItem;

          if (workItem.workSectionId) {
            if (newState.sections[workItem.workSectionId]) {
              newState.sections[workItem.workSectionId] = {
                ...newState.sections[workItem.workSectionId],
                workIds: [...newState.sections[workItem.workSectionId].workIds],
              };
              if (
                !newState.sections[workItem.workSectionId].workIds.includes(
                  workItem.id
                )
              ) {
                newState.sections[workItem.workSectionId].workIds.unshift(
                  workItem.id
                );
              }
            }
          } else if (nonUpdatedWorkItem?.workSectionId) {
            if (newState.sections[nonUpdatedWorkItem.workSectionId]) {
              newState.sections[nonUpdatedWorkItem.workSectionId] = {
                ...newState.sections[nonUpdatedWorkItem.workSectionId],
                workIds: [
                  ...newState.sections[nonUpdatedWorkItem.workSectionId]
                    .workIds,
                ],
              };
              const index = newState.sections[
                nonUpdatedWorkItem.workSectionId
              ].workIds.indexOf(workItem.id);
              if (index > -1) {
                newState.sections[
                  nonUpdatedWorkItem.workSectionId
                ].workIds.splice(index, 1);
              }
            }
          }
          if (!newState.allTaskIds.includes(action.workItem.id)) {
            newState.allTaskIds.push(action.workItem.id);
          }
        }
        return newState;
      }
      return state;
    }

    case actions.UPDATE_WORK_ITEMS: {
      if (action.workItems && Array.isArray(action.workItems)) {
        const newState = { ...state, dict: { ...state.dict } };

        const addToCycle = (workItem: IProjectObj) => {
          if (workItem.workSectionId) {
            if (newState.sections[workItem.workSectionId]) {
              newState.sections[workItem.workSectionId] = {
                ...newState.sections[workItem.workSectionId],
                workIds: [...newState.sections[workItem.workSectionId].workIds],
              };
              if (
                !newState.sections[workItem.workSectionId].workIds.includes(
                  workItem.id
                )
              ) {
                newState.sections[workItem.workSectionId].workIds.unshift(
                  workItem.id
                );
              }
            }
          }
        };

        for (const workItem of action.workItems) {
          if (!workItem.id) {
            continue;
          }
          const updatedWork = workItem;

          if (state.dict[updatedWork.id]) {
            newState.dict[updatedWork.id] = {
              ...newState.dict[updatedWork.id],
              ...updatedWork,
            };
          } else {
            newState.dict[updatedWork.id] = updatedWork;
          }

          addToCycle(updatedWork);
          newState.activeWorkIds = getOpenWorkIds(newState.dict);
        }
        return newState;
      }
      return state;
    }

    case actions.PATCH_SECTION: {
      const newState = {
        ...state,
        sections: { ...state.sections },
        groupCycles: { ...state.groupCycles },
      };
      const section = newState.sections[action.patchSection.id];

      // keep the old rank so that it stays in place after being closed
      newState.sections[action.patchSection.id] = {
        ...section,
        ...action.patchSection.patch,
        rank: state.sections[action.patchSection.id].rank,
      };

      // update the rank only if the update is specifically to reorder the item
      if (!action.patchSection.preset) {
        let index = 0;
        if (
          action.patchSection.patch.rank &&
          action.patchSection.patch.rank !== section.rank
        ) {
          newState.groupCycles[section.groupId] = {
            ...newState.groupCycles[section.groupId],
          };
          newState.groupCycles[section.groupId].openTimeframeIds = [
            ...newState.groupCycles[section.groupId].openTimeframeIds,
          ];
          const prevIndex = newState.groupCycles[
            section.groupId
          ].openTimeframeIds.indexOf(section.id);
          if (prevIndex > -1)
            newState.groupCycles[section.groupId].openTimeframeIds.splice(
              prevIndex,
              1
            );
          const newRank = action.patchSection.patch.rank;
          for (const id of newState.groupCycles[section.groupId]
            .openTimeframeIds) {
            const stateWorkItem = newState.sections[id];
            if (stateWorkItem.rank && newRank) {
              if (stateWorkItem.rank.localeCompare(newRank, "en") >= 0) {
                break;
              }
            }
            index++;
          }
          newState.groupCycles[
            action.patchSection.groupId
          ].openTimeframeIds.splice(index, 0, section.id);
        }
      } else {
        if (
          action.patchSection.patch.rank &&
          action.patchSection.patch.rank !== section.rank
        )
          newState.sections[action.patchSection.id].rank =
            action.patchSection.patch.rank;

        newState.groupCycles[section.groupId] = {
          ...newState.groupCycles[section.groupId],
        };
        newState.groupCycles[section.groupId].openTimeframeIds = [
          ...newState.groupCycles[section.groupId].openTimeframeIds,
        ].sort((a: string, b: string) => {
          if (newState.sections[a].rank && newState.sections[b].rank) {
            if (newState.sections[a].rank < newState.sections[b].rank) {
              return -1;
            }
            if (newState.sections[a].rank > newState.sections[b].rank) {
              return 1;
            }
          }
          return 0;
        });
      }

      if (action.patchSection.patch.hasOwnProperty("isClosed")) {
        if (action.patchSection.patch.isClosed) {
          if (action.patchSection.patch.isLocalClose) {
            newState.justClosedTimeframeIds = [
              ...newState.justClosedTimeframeIds,
            ];
            newState.justClosedTimeframeIds.push(action.patchSection.id);
            newState.justReopenedTimeframeIds = [
              ...newState.justReopenedTimeframeIds,
            ];
            const index = newState.justReopenedTimeframeIds.indexOf(
              action.patchSection.id
            );
            if (index > -1) {
              newState.justReopenedTimeframeIds.splice(index, 1);
            }
          } else {
            newState.groupCycles[action.patchSection.groupId] = {
              ...newState.groupCycles[action.patchSection.groupId],
            };
            newState.groupCycles[action.patchSection.groupId].openTimeframeIds =
              [
                ...newState.groupCycles[action.patchSection.groupId]
                  .openTimeframeIds,
              ];
            newState.groupCycles[
              action.patchSection.groupId
            ].closedTimeframeIds = [
              ...newState.groupCycles[action.patchSection.groupId]
                .closedTimeframeIds,
            ];
            newState.groupCycles[
              action.patchSection.groupId
            ].openTimeframeIds.unshift(action.patchSection.id);
            const index = newState.groupCycles[
              action.patchSection.groupId
            ].closedTimeframeIds.indexOf(action.patchSection.id);
            if (index > -1) {
              newState.groupCycles[
                action.patchSection.groupId
              ].closedTimeframeIds.splice(index, 1);
            }
          }
        } else {
          if (action.patchSection.patch.isReopen) {
            newState.justClosedTimeframeIds = [
              ...newState.justClosedTimeframeIds,
            ];
            newState.justReopenedTimeframeIds = [
              ...state.justReopenedTimeframeIds,
            ];
            newState.justReopenedTimeframeIds.push(action.patchSection.id);
            const index = newState.justClosedTimeframeIds.indexOf(
              action.patchSection.id
            );
            if (index > -1) {
              newState.justClosedTimeframeIds.splice(index, 1);
            }
          } else {
            newState.groupCycles[action.patchSection.groupId] = {
              ...newState.groupCycles[action.patchSection.groupId],
            };
            newState.groupCycles[action.patchSection.groupId].openTimeframeIds =
              [
                ...newState.groupCycles[action.patchSection.groupId]
                  .openTimeframeIds,
              ];
            newState.groupCycles[
              action.patchSection.groupId
            ].closedTimeframeIds = [
              ...newState.groupCycles[action.patchSection.groupId]
                .closedTimeframeIds,
            ];
            newState.groupCycles[
              action.patchSection.groupId
            ].closedTimeframeIds.unshift(action.patchSection.id);
            const index = newState.groupCycles[
              action.patchSection.groupId
            ].openTimeframeIds.indexOf(action.patchSection.id);
            if (index > -1) {
              newState.groupCycles[
                action.patchSection.groupId
              ].openTimeframeIds.splice(index, 1);
            }
          }
        }
      }

      return newState;
    }

    case actions.UPDATE_WORK_ACTIVITY: {
      if (action.workActivity) {
        const newState = {
          ...state,
          workActivities: { ...state.workActivities },
        };
        const existingActivity =
          newState.workActivities[action.workActivity.id];
        if (existingActivity) {
          newState.workActivities[action.workActivity.id] = {
            ...existingActivity,
            ...action.workActivity,
          };
        } else {
          newState.workActivities[action.workActivity.id] = {
            ...action.workActivity,
          };
        }
        return newState;
      }
      return state;
    }

    case "SET_ACTIVE_EDITING_TASK": {
      if (action.id) {
        state.editingTask = action.id;
      } else {
        state.editingTask = null;
      }
      return { ...state };
    }

    case actions.SET_ACTIVE_WORKSPACE: {
      if (action.workspace && action.workspace.id) {
        if (action.workspace.id === state.activeBaseId) {
          const newState = { ...initialState, ...state };
          return newState;
        }
        workItemNrToId = {};
        const newState = { ...initialState };
        newState.activeBaseId = action.workspace.id;
        return newState;
      }
      return state;
    }

    case actions.LOAD_WORKSPACE_DOCUMENTS: {
      const newState = {
        ...state,
        editingTask: null,
      };
      return newState;
    }

    case actions.UPDATE_WORK_STATUS: {
      const oldStatus = state.statuses.dict[action.param.id];

      const newState = {
        ...state,
        dict: { ...state.dict },
        statuses: {
          statusArray: [...state.statuses.statusArray],
          dict: { ...state.statuses.dict },
        },
      };

      if (action.param.type === "delete") {
        const index = newState.statuses.statusArray.indexOf(action.param.id);
        if (index > -1) {
          newState.statuses.statusArray.splice(index, 1);
        }
        delete newState.statuses.dict[action.param.id];
        return newState;
      }
      const newStatus = {
        ...oldStatus,
        ...action.param.delta,
      };
      newStatus.composedRank =
        workStatusCategories[newStatus.categoryId].rank + newStatus.rank;
      newState.statuses.dict[newStatus.id] = newStatus;
      if (!oldStatus || !oldStatus.id) {
        newState.statuses.statusArray.push(newStatus.id);
      }

      newState.statuses.statusArray.sort((a, b) => {
        const statusA = newState.statuses.dict[a];
        const statusB = newState.statuses.dict[b];
        const composeA = statusA.categoryId + statusA.composedRank;
        const composeB = statusB.categoryId + statusB.composedRank;

        if (composeA < composeB) {
          return -1;
        }
        if (composeA > composeB) {
          return 1;
        }
        return 0;
      });
      return newState;
    }

    case actions.TOGGLE_CREATING_CYCLE: {
      const newState = { ...state };
      newState.creatingNewCycle = !newState.creatingNewCycle;
      return newState;
    }

    case actions.UPDATE_MILESTONE: {
      const newState = { ...state };
      const param = action.param;
      if (param.type === "insert") {
        newState.milestoneIds = [...newState.milestoneIds];
        newState.milestoneIds.push(param.id);
        newState.milestoneDict = { ...newState.milestoneDict };
        newState.milestoneDict[param.id] = param.delta as IMilestone;
        return newState;
      }
      if (param.type === "delete") {
        newState.milestoneIds = [...newState.milestoneIds];
        const index = newState.milestoneIds.indexOf(param.id);
        if (index > -1) {
          newState.milestoneIds.splice(index, 1);
        }
        newState.milestoneDict = { ...newState.milestoneDict };
        delete newState.milestoneDict[param.id];
        return newState;
      }
      if (param.type === "update") {
        newState.milestoneDict = { ...newState.milestoneDict };

        if (param.delta.rank) {
          const milestone = newState.milestoneDict[param.id];

          if (param.delta.rank && param.delta.rank !== milestone.rank) {
            newState.milestoneIds = [...newState.milestoneIds];
            const index = newState.milestoneIds.indexOf(param.id);
            newState.milestoneIds.splice(index, 1);
            let inertIndex = 0;
            const newRank = param.delta.rank;
            for (const id of newState.milestoneIds) {
              const stateWorkItem = newState.milestoneDict[id];
              if (stateWorkItem.rank && newRank) {
                if (stateWorkItem.rank.localeCompare(newRank, "en") >= 0) {
                  break;
                }
              }
              inertIndex++;
            }
            newState.milestoneIds.splice(inertIndex, 0, param.id);
          }
        }
        newState.milestoneDict[param.id] = {
          ...newState.milestoneDict[param.id],
          ...param.delta,
        };
        return newState;
      }
      return newState;
    }

    // -----------------------------------------------------------------------
    // DELETE
    case actions.DELETE_SECTION: {
      if (action.sectionId) {
        const newState = { ...state, sections: { ...state.sections } };
        const groupId = newState.sections[action.sectionId].groupId;
        delete newState.sections[action.sectionId];

        const openTimeframeIndex = newState.groupCycles[
          groupId
        ].openTimeframeIds.indexOf(action.sectionId);

        if (openTimeframeIndex > -1) {
          newState.groupCycles[groupId].openTimeframeIds = [
            ...newState.groupCycles[groupId].openTimeframeIds,
          ];
          newState.groupCycles[groupId].openTimeframeIds.splice(
            openTimeframeIndex,
            1
          );
        }

        const closedTimeframeIndex = newState.groupCycles[
          groupId
        ].closedTimeframeIds.indexOf(action.sectionId);
        if (closedTimeframeIndex > -1) {
          newState.groupCycles[groupId].closedTimeframeIds = [
            ...newState.groupCycles[groupId].closedTimeframeIds,
          ];
          newState.groupCycles[groupId].closedTimeframeIds.splice(
            closedTimeframeIndex,
            1
          );
        }

        return newState;
      }
      return state;
    }

    case actions.DELETE_WORK_ITEM: {
      if (action.id) {
        const newState = { ...state, dict: { ...state.dict } };
        const workItem = { ...newState.dict[action.id], isDeleted: true };
        delete newState.dict[action.id];

        if (workItem.workType === WorkTypes.PROJECT) {
          newState.allProjectIds = [...newState.allProjectIds];
          const index = newState.allProjectIds.indexOf(workItem.id);
          if (index > -1) {
            newState.allProjectIds.splice(index, 1);
          }
        }

        if (workItem.workType === WorkTypes.TASK) {
          newState.allTaskIds = [...newState.allTaskIds];
          const index = newState.allTaskIds.indexOf(workItem.id);
          if (index > -1) {
            newState.allTaskIds.splice(index, 1);
          }
        }

        if (workItem.workType === WorkTypes.INITIATIVE) {
          newState.allInitiativeIds = [...newState.allInitiativeIds];
          const index = newState.allInitiativeIds.indexOf(workItem.id);
          if (index > -1) {
            newState.allInitiativeIds.splice(index, 1);
          }
        }

        if (workItem.workSectionId) {
          const index = state.sections[workItem.workSectionId].workIds.indexOf(
            action.id
          );
          if (index > -1) {
            const newWorkIdsArray = [
              ...state.sections[workItem.workSectionId].workIds,
            ];
            newWorkIdsArray.splice(index, 1);
            newState.sections[workItem.workSectionId].workIds = [
              ...newWorkIdsArray,
            ];
          }
        }
        newState.activeWorkIds = getOpenWorkIds(newState.dict);
        return newState;
      }
      return state;
    }

    case actions.DELETE_WORK_ACTIVITY: {
      if (action.workActivity) {
        const newState = {
          ...state,
          workActivities: { ...state.workActivities },
        };
        const existingActivity =
          newState.workActivities[action.workActivity.id];
        if (existingActivity) {
          delete newState.workActivities[action.workActivity.id];
        }
        return newState;
      }
      return state;
    }

    case actions.SET_FOLLOWING_WORK: {
      const newState: IInitialState = {
        ...state,
        dict: { ...state.dict },
        followingWorkIds: [...state.followingWorkIds],
      };
      newState.followingWorkIds = [];
      const work = action.work;
      work.forEach((workItem) => {
        if (workItem.id) {
          newState.followingWorkIds.push(workItem.id);
          newState.dict[workItem.id] = workItem;
        }
      });
      return newState;
    }

    default:
      return state;
  }
}

const getOpenWorkIds = (dict: WorkEntry) => {
  const openWorkItems = Object.values(dict)
    .filter((workItem) => !workItem.isClosed)
    .map((item) => item.id);
  return openWorkItems;
};

const addToWorkObject = (newState: IInitialState, workItem: IProjectObj) => {
  newState.dict = { ...newState.dict };
  if (newState.dict[workItem.id]) {
    newState.dict[workItem.id] = { ...newState.dict[workItem.id], ...workItem };
    addToTypeArray(newState, workItem);
  } else {
    newState.dict[workItem.id] = workItem;
    addToTypeArray(newState, workItem);
  }
  if (!workItem.projectId) return;

  addWorkItemToIdIndex(workItem);

  if (workItem.workSectionId) {
    if (!newState.sections[workItem.workSectionId]) return;
    newState.sections[workItem.workSectionId] = {
      ...newState.sections[workItem.workSectionId],
      workIds: [...newState.sections[workItem.workSectionId].workIds],
    };
    if (
      !newState.sections[workItem.workSectionId].workIds.includes(workItem.id)
    ) {
      newState.sections[workItem.workSectionId].workIds.unshift(workItem.id);
    }
  }
};

const addWorkItemToIdIndex = (workItem: IProjectObj) => {
  if (workItem?.workType === WorkTypes.TASK)
    workItemNrToId[workItem.groupId + workItem.projectId] = workItem.id;
  else workItemNrToId[workItem?.projectId] = workItem?.id;
};

const addToTypeArray = (newState: IInitialState, workItem: IProjectObj) => {
  if (!workItem.projectId) return;
  if (workItem.workType === WorkTypes.PROJECT) {
    newState.allProjectIds = [...newState.allProjectIds];
    if (!newState.allProjectIds.includes(workItem.id)) {
      newState.allProjectIds.push(workItem.id);
    }
  } else if (workItem.workType === WorkTypes.INITIATIVE) {
    newState.allInitiativeIds = [...newState.allInitiativeIds];
    if (!newState.allInitiativeIds.includes(workItem.id)) {
      newState.allInitiativeIds.push(workItem.id);
    }
  } else {
    newState.allTaskIds = [...newState.allTaskIds];
    if (!newState.allTaskIds.includes(workItem.id)) {
      newState.allTaskIds.push(workItem.id);
    }
  }
};

export const prepareStateForFrontend = (lineArray: Line[]) => {
  const fullResponse = {
    lineObject: {
      entities: {
        lines: {} as LinesObject,
      },
      rootLinesId: [] as string[],
    },
  };
  const lines = fullResponse.lineObject.entities.lines;
  const rootLinesId = fullResponse.lineObject.rootLinesId;
  lineArray.forEach((el) => {
    if (!el.parentId || el.parentId === null) el.parentId = "";
    el.ref = React.createRef();
    if (!el.children) el.children = [];
    if (el.parentId === "") rootLinesId.push(el.id);
    else {
      if (lines && lines[el.parentId]) {
        if (!lines[el.parentId].children) lines[el.parentId].children = [];
        lines[el.parentId].children.push(el.id);
      }
    }
    if (!lines[el.id]) lines[el.id] = el;
    else lines[el.id] = { ...lines[el.id], ...el };
  });
  return fullResponse;
};

export function useCycles() {
  return useSelector(
    (store: ClarityStore) => store.work.sections,
    shallowEqual
  );
}

export function useWork() {
  return useSelector(
    (state: ClarityStore) => ({
      workDict: state.work.dict,
      statuses: state.work.statuses,
    }),
    shallowEqual
  );
}

export function useProjectIds(groupId?: string) {
  return useSelector(
    (state: ClarityStore) =>
      groupId
        ? state.work.allProjectIds.filter((projectId) => {
            const project = state.work.dict[projectId];
            return project.groupIds?.includes(groupId);
          })
        : state.work.allProjectIds ?? [],
    shallowEqual
  );
}

export function useActiveIds(groupId?: string) {
  return useSelector(
    (state: ClarityStore) =>
      groupId
        ? state.work.activeWorkIds.filter((projectId) => {
            const project = state.work.dict[projectId];
            return project.groupIds?.includes(groupId);
          })
        : state.work.allProjectIds ?? [],
    shallowEqual
  );
}

export function useTaskIds() {
  return useSelector(
    (state: ClarityStore) => state.work.allTaskIds ?? [],
    shallowEqual
  );
}

export function useInitiativeIds(groupId?: string) {
  return useSelector(
    (state: ClarityStore) =>
      groupId
        ? state.work.allInitiativeIds.filter((projectId) => {
            const project = state.work.dict[projectId];
            return project.groupIds?.includes(groupId);
          })
        : state.work.allInitiativeIds ?? [],
    shallowEqual
  );
}
