import React, { useEffect, useMemo, useState } from "react";
import { connect, batch, useSelector, shallowEqual } from "react-redux";
import {
  Abilities,
  ContainerTypes,
  GeneralViewsNames,
  IPageObj,
  UserEventTypes,
  UserRole,
} from "utilities/types";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { abbreviatedMonths } from "utilities/dateTime";
import * as actionTypes from "store/actions";
import PagesList from "./pages/PagesList";
import DocumentCellMain from "components/DocumentCellMain";
import DocumentCellSecondary from "components/DocumentCellSecondary";
import ListItemMenuHandle from "components/ListItemMenuHandle";
import { Menu, message, Modal } from "antd";
import pagesListStyles from "./pages/pagesList/pagesList.module.scss";
import { useHistory } from "react-router-dom";
import { deleteDocument } from "modules/documentService";
import store, { ClarityStore } from "store/storeExporter";
import { stripHtml } from "utilities/stringUtilities";
import { mixpanel } from "App";
import {
  setPaneTopNavViewType,
  TopNavbarType,
  usePageDataSetter,
} from "store/reducers/topNavReducer";
import { useAbilityChecker } from "editor/utils/customHooks";
import Button, { ButtonTypes } from "components/Button";
import { createAndSetNewPage } from "utilities/createNewContainers";
import { getHtml } from "editor/utils/blockValueHelpers";
import TopNavBar from "components/TopNavBar";
import { ChunkDestination } from "utilities/stateTypes";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import Conditional from "components/Conditional";
import navigationApi from "clientApi/navigationApi";

const { confirm } = Modal;

export const documentDeletedMessage = (docName: string) => {
  message.success(`Document '${docName}' was deleted`, 2);
};

interface IMapStateToProps {
  documents: {
    [id: string]: IPageObj;
  };
  documentIds: string[];
  workspace: any;
  userId?: string;
  paneId: ChunkDestination;
}

interface IMapDispatchToProps {
  updateDocument: (document: any) => any;
  removeDocument: (documentId: string) => any;
}

function Pages(props: IMapDispatchToProps & IMapStateToProps) {
  const { documents, workspace, userId, paneId, documentIds } = props;
  const history = useHistory();
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  usePageDataSetter(paneId, {
    title: "Tags",
  });

  useEffect(() => {
    batch(() => {
      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.base,
      });
    });

    return () => {
      batch(() => {
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, []);

  const createPage = (event: any) => {
    createAndSetNewPage(event, workspace.id);
  };

  const userRole = useSelector(
    (store: ClarityStore) => store.client.roleType,
    shallowEqual
  );

  useEffect(() => {
    mixpanel.track(UserEventTypes.ALL_PAGES_VIEWED, {
      distinct_id: userId,
    });
  }, []);

  let data: IPageObj[] = [];
  const [filteredDocuments, setFilteredDocuments] = useState(data);

  useEffect(() => {
    if (documents) {
      let elements = [];

      for (const documentId of documentIds) {
        elements.push(documents[documentId]);
      }
      setFilteredDocuments(elements);
    }
  }, [documentIds, documents]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (cell: any) => <DocumentCellMain cell={cell} paneId={paneId} />,
      },
      {
        Header: "Updated",
        accessor: "dateModified",
        Cell: (cell: any) => {
          if (cell.row.original.dateModified) {
            const date = new Date(cell.row.original.dateModified);
            return (
              <DocumentCellSecondary>
                <span>{`${
                  abbreviatedMonths[date.getMonth()]
                } ${date.getDate()}, ${date.getFullYear()}`}</span>
              </DocumentCellSecondary>
            );
          }
          return (
            <DocumentCellSecondary>
              <span>--</span>
            </DocumentCellSecondary>
          );
        },
      },
      {
        Header: "Created",
        accessor: "dateCreated",
        width: 144,
        Cell: (cell: any) => {
          const date = new Date(cell.row.original.dateCreated);
          return (
            <DocumentCellSecondary>
              <span>{`${
                abbreviatedMonths[date.getMonth()]
              } ${date.getDate()}, ${date.getFullYear()}`}</span>
            </DocumentCellSecondary>
          );
        },
      },
      {
        Header: "",
        accessor: "menu",
        Cell: (cell: any) => {
          return (
            <div className={pagesListStyles.documentList__cell_handle}>
              <ListItemMenuHandle
                className={pagesListStyles.sixDotHandle}
                triggerArray={["hover"]}
                menuFn={() =>
                  documentMenu(
                    cell.row.original.id,
                    stripHtml(getHtml(cell.row.original.nameValue))
                      .toLowerCase()
                      .trim() !== "weeklys",
                    canEditEntity
                  )
                }
                menuPlacement={"bottomLeft"}
                draggable={false}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documents]
  );

  const documentMenu = (
    id: string,
    isDeletable: boolean,
    canEditEntity: boolean
  ) => {
    return isDeletable ? (
      <Menu>
        <Menu.Item disabled={!canEditEntity} key={id}>
          <div
            onClick={(event) => {
              if (!canEditEntity) return;
              showDeleteDocumentConfirm(
                ContainerTypes.DOCUMENT,
                id,
                props,
                history
              );
            }}
          >
            Delete tag...
          </div>
        </Menu.Item>
      </Menu>
    ) : (
      <></>
    );
  };

  const showDeleteDocumentConfirm = (
    containerType: ContainerTypes,
    containerId: string,
    props: IMapStateToProps & IMapDispatchToProps,
    history: any
  ) => {
    return confirm({
      title: "Are you sure you want to delete this tag?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      maskClosable: true,
      okText: "Delete",

      onOk() {
        deleteDocument(containerId).then((resp: any) => {
          if (resp) {
            batch(() => {
              Object.values(resp.updatedDocuments).forEach((document) => {
                props.updateDocument(document);
              });
              store.dispatch({
                type: actionTypes.REMOVE_PIN,
                id: resp.deletedPin,
              });
            });
          }
          const name = stripHtml(
            getHtml(props.documents[containerId]?.nameValue)
          );
          navigationApi.contextBasedNavigate({
            currentPane: paneId,
            navigationChunk: {
              viewName: GeneralViewsNames.Wiki,
            },
          });

          props.removeDocument(containerId);
          documentDeletedMessage(name);
        });
      },
      onCancel() {},
    });
  };

  return useMemo(() => {
    return (
      <>
        <div
          className={`flex-fill page__mxWidth1355`}
          style={{ overflow: "hidden" }}
        >
          <div className={`flex-fill clarityScrollElement`}>
            <TopNavBar
              paneId={paneId}
              designatedType={TopNavbarType.baseOverview}
            />
            <Conditional on={Boolean(userRole !== UserRole.GUEST)}>
              <TopNavFiltersBar paneId={paneId}>
                <Button
                  buttonType={ButtonTypes.PRIMARY}
                  icon={<PlusOutlined />}
                  disabled={!canEditEntity}
                  onClick={createPage}
                >
                  Tag
                </Button>
              </TopNavFiltersBar>
            </Conditional>

            <div className={`content ${pagesListStyles.documentListContainer}`}>
              <PagesList documents={filteredDocuments} columns={columns} />
            </div>
          </div>
        </div>
      </>
    );
  }, [
    props.documentIds,
    props.documents,
    props.workspace.slug,
    filteredDocuments,
  ]);
}

const mapStateToProps = (state: ClarityStore) => ({
  documents: state.pages.dict,
  documentIds: state.pages.ids,
  workspace: state.workspace,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateDocument: (document: any) =>
    dispatch({ type: actionTypes.UPDATE_DOCUMENT, document }),
  removeDocument: (documentId: string) =>
    dispatch({ type: actionTypes.REMOVE_DOCUMENT, documentId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
