import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import LogInForm from "screens/logIn/LogInForm";
import Signup from "screens/Signup";
import { UserEventTypes } from "utilities/types";

const SignUpLoginSwitcher: React.FC<{
  onAcceptInvitation?: boolean;
  inviteToken?: string;
  invitationRecord?: any;
  inTokenGate?: boolean;
  showWhatIsSiwe?: boolean;
  viralSignupType?: UserEventTypes;
  onLoginOrSignup?: () => void;
}> = ({
  onAcceptInvitation,
  invitationRecord,
  inviteToken,
  inTokenGate,
  showWhatIsSiwe,
  onLoginOrSignup,
  viralSignupType,
}) => {
  const [currentView, setcurrentView] = useState(1);
  const location = useLocation();

  return currentView === 1 ? (
    <LogInForm
      redirectPath={location.pathname}
      onLogin={onLoginOrSignup}
      noRedirect={true}
      viralSignupType={viralSignupType}
      viewConfig={{
        showEmailLogin: !inTokenGate,
        showTokenGateEmailLogin: inTokenGate,
        showWhatIsSiwe: showWhatIsSiwe ?? false,
        buttonGap: "16px",
      }}
      setcurrentView={setcurrentView}
    />
  ) : (
    <Signup
      onAcceptInvitation={onAcceptInvitation}
      onSignup={onLoginOrSignup}
      redirectAfterLogin={location.pathname}
      invitationRecord={invitationRecord}
      noRedirect={true}
      hideLogo={true}
      inviteToken={inviteToken}
      setcurrentView={setcurrentView}
      viralSignupType={viralSignupType}
    />
  );
};

export default SignUpLoginSwitcher;
