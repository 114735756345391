import styles from "./splashModal/splashModal.module.scss";
import ClarityBackdrop from "images/clarity-2-splash-screen-compressed.jpg";
import ClarityLogo from "images/clarity-2.0.svg";
import Icon, { GiftOutlined } from "@ant-design/icons";
import { ReactComponent as ChevronRight } from "icons/chevron-right.svg";
import { motion } from "framer-motion";

import React from "react";
import { userApi } from "clientApi/userApi";

const ShowSplashModal: React.FC = () => {
  return (
    <div className={styles.fullContainer}>
      <motion.div
        exit={{
          y: -20,
          transition: {
            ease: "easeInOut",
            duration: 1,
          },
        }}
        style={{ height: "100%", width: "100%" }}
      >
        <motion.div
          className={styles.background}
          exit={{
            opacity: 0,
            transition: {
              ease: "easeInOut",
              duration: 1,
            },
          }}
          style={{ backgroundImage: `url(${ClarityBackdrop})` }}
        >
          <div className={styles.contentContainer}>
            <motion.div
              className={styles.logo}
              initial={{
                y: 20,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  ease: "easeInOut",
                  duration: 1,
                  delay: 0.3,
                },
              }}
            >
              <img
                style={{ width: "100%" }}
                src={ClarityLogo}
                alt="Clarity logo"
              />
            </motion.div>
            <motion.div
              className={styles.button}
              initial={{
                y: 20,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  ease: "easeInOut",
                  duration: 1,
                  delay: 0.6,
                },
              }}
            >
              <div
                className={styles.buttonPrimary}
                onClick={() => {
                  const wind = window.open(
                    "https://www.clarity.so/product/clarity-2-0",
                    "_blank"
                  );
                  if (wind) {
                    wind.focus();
                  }
                  userApi
                    .persistChanges({
                      showSlashModal: false,
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <>
                  <GiftOutlined style={{ fontSize: "26px" }} />
                  <h4 className="medium">See what's new</h4>
                </>
              </div>
            </motion.div>
            <motion.div
              initial={{
                y: 20,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  ease: "easeInOut",
                  duration: 1,
                  delay: 0.9,
                },
              }}
              className={styles.button}
            >
              <div
                className={styles.buttonSecondary}
                onClick={() => {
                  userApi
                    .persistChanges({
                      showSlashModal: false,
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <h4 className="medium primary">Continue to the app</h4>
                <Icon component={ChevronRight} />
              </div>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ShowSplashModal;
