import React, { Suspense, useMemo, useState } from "react";
import { useShallowSelector } from "utilities/hooks";
import { Abilities, HomeScreenObj, IGroup, UserRole } from "utilities/types";
import { LogoutOutlined, LoginOutlined } from "@ant-design/icons";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
import UsersStack from "clarity-ui/UsersStack";
import styles from "./groupOverview.module.scss";
import Button, { ButtonTypes, IconSides } from "components/Button";
import ReactDOM from "react-dom";
import Modal from "clarity-ui/Modal";
import ProfileEdit from "../profileEditor/ProfileEdit";
import { Dropdown, Skeleton } from "antd";
import ProfileMoreMenu from "../profileEditor/ProfileMoreMenu";
import { Menu, MenuItem } from "components/FabBtn";
import { groupApi } from "clientApi/groupsApi";
import notificationsApi from "clientApi/notificationsApi";
import {
  useAddOrEditGroupHomeSection,
  useDeleteGroupProfileSection,
  useReorderGroupHomeSection,
} from "store/reducers/groupReducer";
import ChevronDown from "icons/Components/ChevronDown";
import {
  useDiscordIntegrationModalOpener,
  useRoleType,
} from "store/reducers/clientReducer";
import { baseApi } from "clientApi/baseApi";
import { userApi } from "clientApi/userApi";
import ConfirmModal from "clarity-ui/ConfirmModal";
import Conditional from "components/Conditional";
import { useAbilityChecker } from "editor/utils/customHooks";
import UnlockTwoTone from "icons/Components/UnlockTwoTone";
import LockTwoTone from "icons/Components/LockTwoTone";
import store from "store/storeExporter";
import { SET_MODAL_JOIN_GROUP } from "store/actions";
import GroupMembersModal from "../groupMembers/GroupsMembersModal";

const CollectionsEditor = React.lazy(
  () => import("../pinsBoard/CollectionsEditor")
);

const GroupDetails: React.FC<{ paneId: string; groupId: string }> = ({
  paneId,
  groupId,
}) => {
  const group = useShallowSelector((store) => store.groups.dict[groupId]);

  const [showRequiredRoles, setShowRequiredRoles] = useState(false);
  return (
    <div className={styles.detailsContainer}>
      <GroupBanner group={group} />
      <div className={styles.innerContainer}>
        <h2 className={`${styles.groupName} bold primary`}>{group.name}</h2>
        {group.description ? (
          <p className="caption regular secondary mb-15">{group.description}</p>
        ) : (
          <p className="mb-4"></p>
        )}
        <div className={styles.closeGroup}>
          <ButtonsGroup
            group={group}
            setShowRequiredRoles={setShowRequiredRoles}
          />
          <GroupMembersInfo
            group={group}
            showRequiredRoles={showRequiredRoles}
          />
        </div>
      </div>
    </div>
  );
};

const GroupBanner: React.FC<{ group: IGroup }> = ({ group }) => {
  return (
    <>
      {group.coverPhotoUrl && (
        <div
          className={styles.coverPhoto}
          style={{
            backgroundImage: `url("${group.coverPhotoUrl}")`,
          }}
        ></div>
      )}
    </>
  );
};

const GroupMembersInfo: React.FC<{
  group: IGroup;
  showRequiredRoles: boolean;
}> = ({ group, showRequiredRoles }) => {
  const rolesState = useShallowSelector((state) => state.roles.dict);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div className={styles.membersInfo}>
        <GroupPolicy isPrivate={group.requiredRoles.length > 0} />
        {group.members && (
          <>
            <span>•</span>
            <GroupMembers groupId={group.id} />
          </>
        )}
      </div>
      {showRequiredRoles && (
        <>
          <span>Required roles: </span>
          <div>
            {group.requiredRoles.map((roleId) => (
              <>
                <span>{rolesState[roleId].roleName}</span>
                <span>•</span>
              </>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const ButtonsGroup: React.FC<{
  group: IGroup;
  setShowRequiredRoles: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ group, setShowRequiredRoles }) => {
  const [editProfile, seteditProfile] = useState(false);
  const [showMoreMenu, setshowMoreMenu] = useState(false);
  const [editCollections, seteditCollections] = useState(false);

  const discordIntegrationOpener = useDiscordIntegrationModalOpener(
    true,
    group.id
  );
  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });
  return (
    <div className={styles.buttonGroup}>
      <MembershipStatus
        group={group}
        setShowRequiredRoles={setShowRequiredRoles}
      />
      <Dropdown
        overlay={
          <ProfileMoreMenu
            hide={() => setshowMoreMenu(false)}
            seteditCollections={seteditCollections}
            seteditProfile={seteditProfile}
          />
        }
        visible={showMoreMenu}
      >
        <Button
          className={styles.button}
          buttonType={ButtonTypes.MEDIUM_PLAIN}
          onClick={() => setshowMoreMenu(true)}
          icon={<MoreHorizontalDots />}
        />
      </Dropdown>
      <Conditional on={!group.channelName}>
        <Button
          className={styles.button}
          buttonType={ButtonTypes.MEDIUM_PLAIN}
          onClick={() => discordIntegrationOpener()}
          disabled={!canEditSettings}
        >
          Connect to Discord
        </Button>
      </Conditional>
      <ProfileEditorListener
        editProfile={editProfile}
        seteditProfile={seteditProfile}
        groupId={group.id}
      />
      <CollectionEditorListener
        editCollections={editCollections}
        seteditCollections={seteditCollections}
        collections={group.home}
        groupId={group.id}
      />
    </div>
  );
};

const GroupMembers: React.FC<{ groupId: string }> = ({ groupId }) => {
  const [membersModalVisible, setmembersModalVisible] = useState(false);
  const members = useShallowSelector(
    (store) => store.groups.dict[groupId].members
  );
  const membersDict = useShallowSelector((store) => store.members.dict);

  const first6 = useMemo(() => {
    const toReturn: string[] = [];
    members?.forEach((memberId) => {
      if (toReturn.length === 6) return;
      const memberObj = membersDict[memberId];
      if (memberObj && memberObj.role && memberObj.role !== "Guest")
        toReturn.push(memberId);
    });
    return toReturn;
  }, [members]);

  if (!members) return <></>;

  return (
    <div className={styles.membersStack}>
      <UsersStack hideTooltip={true} userIds={first6} />
      <span
        className="caption medium secondary underlined-hover"
        onClick={() => setmembersModalVisible(true)}
      >
        {members.length} {members.length < 2 ? "member" : "members"}
      </span>
      <GroupMembersModal
        groupId={groupId}
        isVisible={membersModalVisible}
        setisVisible={setmembersModalVisible}
      />
    </div>
  );
};

const GroupPolicy: React.FC<{ isPrivate: boolean }> = ({ isPrivate }) => {
  return (
    <span className="caption medium secondary">
      {isPrivate ? (
        <>
          <LockTwoTone /> Closed group
        </>
      ) : (
        <>
          <UnlockTwoTone /> Open group
        </>
      )}
    </span>
  );
};

const ProfileEditorListener: React.FC<{
  editProfile: boolean;
  seteditProfile: React.Dispatch<React.SetStateAction<boolean>>;
  groupId: string;
}> = ({ editProfile, seteditProfile, groupId }) => {
  const closeAction = () => seteditProfile(false);
  return (
    <>
      {editProfile &&
        ReactDOM.createPortal(
          <Modal hideModal={closeAction}>
            <ProfileEdit
              mode="group"
              closeAction={closeAction}
              groupId={groupId}
            />
          </Modal>,
          document.body
        )}
    </>
  );
};

const CollectionEditorListener: React.FC<{
  editCollections: boolean;
  seteditCollections: React.Dispatch<React.SetStateAction<boolean>>;
  collections: HomeScreenObj;
  groupId: string;
}> = ({ editCollections, seteditCollections, collections, groupId }) => {
  const updateOrAddCollection = useAddOrEditGroupHomeSection(groupId);
  const reorderCollection = useReorderGroupHomeSection(groupId);
  const deleteCollection = useDeleteGroupProfileSection(groupId);
  return (
    <>
      {editCollections && (
        <>
          {ReactDOM.createPortal(
            <Modal hideModal={() => seteditCollections(false)}>
              <Suspense fallback={<Skeleton avatar={false} />}>
                <CollectionsEditor
                  collections={collections}
                  updateOrAddCollection={updateOrAddCollection}
                  reorderCollection={reorderCollection}
                  deleteCollection={deleteCollection}
                />
              </Suspense>
            </Modal>,
            document.body
          )}
        </>
      )}
    </>
  );
};

const MembershipStatus: React.FC<{
  group: IGroup;
  setShowRequiredRoles: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ group, setShowRequiredRoles }) => {
  const userGroups = useShallowSelector((store) => store.groups.userGroups);
  const baseData = useShallowSelector((store) => ({
    id: store.workspace.id,
    name: store.workspace.name,
    roleIds: store.client.roleIds,
  }));
  const userId = useShallowSelector((store) => store.user?.id);
  const userRole = useRoleType();
  const hasJoined = userGroups.includes(group.id);
  const [showDropdown, setshowDropdown] = useState(false);
  const [isLoading, setisloading] = useState(false);

  const joinGroup = () => {
    if (isLoading) return;
    setisloading(true);
    if (!userRole || userRole === UserRole.GUEST) {
      baseApi.joinBase(baseData.id).then(() => {
        if (
          group.requiredRoles &&
          group.requiredRoles.length > 0 &&
          !groupApi.canJoinGroup(group.id)
        ) {
          setShowRequiredRoles(true);
          store.dispatch({
            type: SET_MODAL_JOIN_GROUP,
            isOpen: true,
            roleIds: group.requiredRoles,
          });
        } else {
          groupApi
            .joinGroup(group.id)
            .then(() => {
              userApi.resetUserWithBaseSelected();
              setisloading(false);
              notificationsApi.displayConfirmation({
                title: <span>Joined group</span>,
                body: <span>You joined "{group.name}" successfully</span>,
                duration: 3,
              });
            })
            .catch((e) => {
              setisloading(false);
              notificationsApi.displayError({
                title: <span>Error joining group</span>,
                body: <span>{e.response?.data?.message}</span>,
                duration: 3,
              });
            });
        }
      });
    } else {
      groupApi
        .joinGroup(group.id)
        .then(() => {
          setisloading(false);
          notificationsApi.displayConfirmation({
            title: <span>Joined group</span>,
            body: <span>You joined "{group.name}" successfully</span>,
            duration: 3,
          });
        })
        .catch((e) => {
          setisloading(false);
          notificationsApi.displayError({
            title: <span>Error joining group</span>,
            body: <span>{e.response?.data?.message}</span>,
            duration: 3,
          });
        });
    }
  };

  if (!hasJoined)
    return (
      <>
        <Conditional on={showDropdown}>
          <ConfirmModal
            title={`You are about to join ${baseData.name}`}
            confirmationText="Join base & group"
            cancelText="Nevermind"
            close={() => setshowDropdown(false)}
            onConfirm={joinGroup}
          >
            <span>
              In order to join this group, you will also need to be a member of
              this base.
            </span>
          </ConfirmModal>
        </Conditional>

        <Button
          className={styles.button}
          disabled={!userId}
          onClick={() => {
            if (!userRole || userRole === UserRole.GUEST) {
              setshowDropdown(true);
            } else if (
              group.requiredRoles &&
              group.requiredRoles.length > 0 &&
              !groupApi.canJoinGroup(group.id)
            ) {
              setShowRequiredRoles(true);
              store.dispatch({
                type: SET_MODAL_JOIN_GROUP,
                isOpen: true,
                roleIds: group.requiredRoles,
              });
            } else {
              joinGroup();
            }
          }}
          buttonType={ButtonTypes.MEDIUM_PRIMARY}
        >
          Join group
        </Button>
      </>
    );
  else
    return (
      <Dropdown
        overlay={
          <MembershipChangeDropdown
            group={group}
            hide={() => setshowDropdown(false)}
          />
        }
        visible={showDropdown}
      >
        <Button
          className={styles.button}
          onClick={() => setshowDropdown(true)}
          icon={<ChevronDown />}
          iconSide={IconSides.RIGHT}
          buttonType={
            hasJoined ? ButtonTypes.MEDIUM_PLAIN : ButtonTypes.MEDIUM_PRIMARY
          }
        >
          {hasJoined ? "Joined" : "Not Joined"}
        </Button>
      </Dropdown>
    );
};

const MembershipChangeDropdown: React.FC<{
  hide: () => void;
  group: IGroup;
}> = ({ hide, group }) => {
  const userGroups = useShallowSelector((store) => store.groups.userGroups);
  const hasJoined = userGroups.includes(group.id);
  const [isLoading, setisloading] = useState(false);

  const leaveGroup = () => {
    setisloading(true);
    groupApi
      .leaveGroup(group.id)
      .then(() => {
        setisloading(false);
        notificationsApi.displayConfirmation({
          title: <span>Left group</span>,
          body: <span>You left "{group.name}"</span>,
          duration: 3,
        });
      })
      .catch((e) => {
        setisloading(false);
        notificationsApi.displayError({
          title: <span>Error leaving group</span>,
          body: <span>{e.response?.data?.message}</span>,
          duration: 3,
        });
      });
  };

  const joinGroup = () => {
    setisloading(true);
    groupApi
      .joinGroup(group.id)
      .then(() => {
        setisloading(false);
        notificationsApi.displayConfirmation({
          title: <span>Joined group</span>,
          body: <span>You joined "{group.name}" successfully</span>,
          duration: 3,
        });
      })
      .catch((e) => {
        setisloading(false);
        notificationsApi.displayError({
          title: <span>Error joining group</span>,
          body: <span>{e.response?.data?.message}</span>,
          duration: 3,
        });
      });
  };

  return (
    <Menu slim={true} close={hide} yDir="down" xDir="left">
      <>
        {hasJoined && (
          <MenuItem
            title="Leave group"
            icon={<LogoutOutlined />}
            disabled={isLoading}
            onClick={() => {
              leaveGroup();
              hide();
            }}
          />
        )}
        {!hasJoined && (
          <MenuItem
            title="Join group"
            disabled={isLoading}
            icon={<LoginOutlined />}
            onClick={() => {
              joinGroup();
              hide();
            }}
          />
        )}
      </>
    </Menu>
  );
};

export default GroupDetails;
