import { ReactElement } from "react";
import Button, { ButtonTypes } from "components/Button";
import store from "store/storeExporter";
import { Abilities, UserRole } from "utilities/types";
import { useAbilityChecker, useGroupContext } from "editor/utils/customHooks";

interface IEmptyStateProps {
  hideButton?: boolean;
  buttonFn?: any;
  buttonIcon?: any;
  buttonLabel?: string;
  heading: string;
  caption: string | ReactElement;
}

export default function EmptyState(props: IEmptyStateProps) {
  const roleType = store.getState().client.roleType;
  const groupId = useGroupContext();
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupId,
  });

  return (
    <div className="empty-state-container">
      <div className="empty-state-inner">
        <h4 className="secondary">{props.heading}</h4>
        <p className="secondary">{props.caption}</p>
        {!props.hideButton && props.buttonFn && roleType !== UserRole.GUEST && (
          <Button
            buttonType={ButtonTypes.PRIMARY}
            disabled={!canEditEntity}
            icon={props.buttonIcon}
            onClick={canEditEntity ? props.buttonFn : () => {}}
          >
            {props.buttonLabel}
          </Button>
        )}
      </div>
    </div>
  );
}
