import { locationSubject } from "components/LocationListener";
import { CLOSE_SPLIT_VIEW, SET_NAVIGATION_CHUNK } from "store/actions";
import store from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import {
  ContainerTypes,
  GeneralViewsNames,
  GroupGeneralViews,
  NavigationChunk,
  TasksViewModes,
  WorkTypes,
} from "utilities/types";

class NavigationApi {
  navigateMain(navigationChunk: NavigationChunk) {
    const url = this.getLinkFromNavigationChunk(navigationChunk);
    locationSubject.next(url);
  }

  openSplitView(params: {
    viewName?: GeneralViewsNames | string;
    groupSlug?: string;
    groupId?: string;
    containerId?: string;
    containerType?: ContainerTypes;
    taskViewMode?: TasksViewModes;
    workType?: WorkTypes;
  }) {
    if (!params) return;
    store.dispatch({
      type: SET_NAVIGATION_CHUNK,
      navigationChunk: {
        viewName: params.viewName,
        groupSlug: params.groupSlug,
        taskViewMode: params.taskViewMode,
        groupId: params.groupId,
        entity: params.containerId
          ? {
              containerId: params.containerId,
              containerType: params.containerType,
              workType: params.workType,
            }
          : undefined,
      },
      paneId: ChunkDestination.secondary,
    });
  }

  openSplitViewNavigationChunk(navigationChunk: NavigationChunk) {
    store.dispatch({
      type: SET_NAVIGATION_CHUNK,
      navigationChunk: {
        ...navigationChunk,
      },
      paneId: ChunkDestination.secondary,
    });
  }

  closeSplitViewNavigationChunk() {
    store.dispatch({
      type: CLOSE_SPLIT_VIEW,
    });
  }

  openPeekView(navigationChunk: NavigationChunk) {
    store.dispatch({
      type: SET_NAVIGATION_CHUNK,
      navigationChunk: {
        ...navigationChunk,
      },
      paneId: ChunkDestination.peek,
    });
  }

  closePeekView() {
    store.dispatch({
      type: SET_NAVIGATION_CHUNK,
      navigationChunk: undefined,
      paneId: ChunkDestination.peek,
    });
  }

  getLinkFromNavigationChunk(navigationChunk: NavigationChunk) {
    const baseSlug = store.getState().workspace.slug;

    if (!navigationChunk) return `/${baseSlug}/`;

    if (navigationChunk.entity && navigationChunk.entity.containerType) {
      const containerType = navigationChunk.entity.containerType;
      const containerId = navigationChunk.entity.containerId;
      if (containerType === ContainerTypes.DOCUMENT) {
        return `/${baseSlug}/tags/${containerId}`;
      }
      if (
        containerType === ContainerTypes.PROJECT ||
        containerType === ContainerTypes.TASK ||
        containerType === ContainerTypes.WORK_ACTIVITY ||
        containerType === ContainerTypes.WORK
      ) {
        const workItem = store.getState().work.dict[containerId];

        if (workItem) {
          if (workItem.workType === WorkTypes.TASK) {
            const group = store.getState().groups.dict[workItem.groupId];

            return `/${baseSlug}/work/${group.slug}-${workItem.projectId}`;
          }
          return `/${baseSlug}/project/${workItem.projectId}`;
        }
        return `/${baseSlug}/work/${containerId}`;
      }

      if (containerType === ContainerTypes.NOTE) {
        return `/${baseSlug}/docs/${containerId}`;
      }
      if (containerType === ContainerTypes.CUSTOM_VIEW) {
        return `/${baseSlug}/view/${containerId}`;
      }

      if (containerType === ContainerTypes.TEMPLATE) {
        return `/${baseSlug}/template/${containerId}`;
      }

      if (containerType === ContainerTypes.SNIPPET) {
        return `/${baseSlug}/snippet/${containerId}`;
      }

      if (containerType === ContainerTypes.CYCLE) {
        return `/${baseSlug}/sprint/${containerId}`;
      }

      return `/${baseSlug}/tags/${containerId}`;
    }

    if (navigationChunk.taskViewMode === TasksViewModes.Cycle) {
      return `/${baseSlug}/sprint/${navigationChunk.entity?.containerId}`;
    }

    if (navigationChunk.taskViewMode === TasksViewModes.CustomView) {
      return `/${baseSlug}/view/${navigationChunk.entity?.containerId}`;
    }

    if (navigationChunk.viewName === GeneralViewsNames.Views) {
      return `/${baseSlug}/views`;
    }

    if (navigationChunk.viewName === GeneralViewsNames.Templates) {
      return `/${baseSlug}/templates`;
    }

    if (navigationChunk.viewName === GeneralViewsNames.Snippets) {
      return `/${baseSlug}/snippets`;
    }

    if (navigationChunk.groupSlug || navigationChunk.groupId) {
      let groupSlug = navigationChunk.groupSlug;
      if (!groupSlug && navigationChunk.groupId) {
        const group = store.getState().groups.dict[navigationChunk.groupId];
        groupSlug = group.slug;
      }
      if (navigationChunk.viewName === GroupGeneralViews.Notes)
        return `/${baseSlug}/group/${groupSlug}/docs`;
      if (navigationChunk.viewName === GroupGeneralViews.Tasks)
        return `/${baseSlug}/group/${groupSlug}/tasks`;

      return `/${baseSlug}/group/${groupSlug}/${navigationChunk.viewName}`;
    }

    return `/${baseSlug}/${navigationChunk.viewName}`;
  }

  contextBasedNavigate(params: {
    navigationChunk: NavigationChunk;
    currentPane: ChunkDestination;
    shiftKey?: boolean;
    forceToPane?: ChunkDestination;
  }) {
    if (!params.shiftKey) {
      if (
        params.forceToPane === ChunkDestination.primary ||
        (!params.forceToPane && params.currentPane === ChunkDestination.primary)
      ) {
        return this.navigateMain(params.navigationChunk);
      }
      store.dispatch({
        type: SET_NAVIGATION_CHUNK,
        navigationChunk: params.navigationChunk,
        paneId: params.forceToPane ? params.forceToPane : params.currentPane,
      });
    } else {
      if (params.currentPane === ChunkDestination.primary) {
        return this.openSplitViewNavigationChunk(params.navigationChunk);
      }
      if (params.currentPane === ChunkDestination.secondary) {
        return this.navigateMain(params.navigationChunk);
      }
      if (params.currentPane === ChunkDestination.peek) {
        return this.openSplitViewNavigationChunk(params.navigationChunk);
      }
    }
  }
}

const navigationApi = new NavigationApi();

export default navigationApi;
