import ClarityInput from "components/ClarityInput";
import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useEffect, useState } from "react";
import { useShallowSelector } from "utilities/hooks";
import { Abilities, BucketAlbums, IGroup } from "utilities/types";
import { InfoCircleOutlined } from "@ant-design/icons";
import ClarityTextArea from "components/ClarityTextArea";
import styles from "../../settings/groups/groups.module.scss";
import PhotoUpload from "components/PhotoUpload";
import Button, { ButtonTypes } from "components/Button";
import notificationsApi from "clientApi/notificationsApi";
import store from "store/storeExporter";
import { UPDATE_WORKSPACE } from "store/actions";
import { groupApi } from "clientApi/groupsApi";
import { axiosInstance } from "index";
import Conditional from "components/Conditional";

interface IProfileData {
  coverPhotoUrl?: string;
  name: string;
  tagline: string;
}

const ProfileEdit: React.FC<{
  mode: "base" | "group";
  closeAction: () => void;
  groupId?: string;
}> = ({ mode, groupId, closeAction }) => {
  return (
    <>
      <h3 className="bold" style={{ marginBottom: "24px" }}>
        Edit profile
      </h3>
      {mode === "base" && <BaseProfileEdit closeAction={closeAction} />}
      {mode === "group" && groupId && (
        <GroupProfileEdit groupId={groupId} closeAction={closeAction} />
      )}
    </>
  );
};

const BaseProfileEdit: React.FC<{ closeAction: () => void }> = ({
  closeAction,
}) => {
  const base = useShallowSelector((store) => store.workspace);
  const canManageBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });

  const [profileData, setprofileData] = useState<IProfileData>({
    coverPhotoUrl: base.coverPhotoUrl,
    name: base.name,
    tagline: base.tagline,
  });

  useEffect(() => {
    setprofileData({
      coverPhotoUrl: base.coverPhotoUrl,
      name: base.name,
      tagline: base.tagline,
    });
  }, [base]);

  const save = async () => {
    if (!profileData.name) {
      notificationsApi.displayError({
        title: "Error",
        body: "Name cannot be blank",
      });
      return;
    }
    try {
      const { data } = await axiosInstance.patch(`/api/workspace/profile`, {
        baseId: base.id,
        patch: [
          {
            op: "replace",
            path: "/name",
            value: profileData.name,
          },
          {
            op: "replace",
            path: "/tagline",
            value: profileData.tagline || "",
          },
          {
            op: "replace",
            path: "/coverPhotoUrl",
            value: profileData.coverPhotoUrl || "",
          },
        ],
      });
      if (data.status === 1) {
        notificationsApi.displayConfirmation({
          title: "Success",
          body: "Details updated successfully",
        });
        store.dispatch({
          type: UPDATE_WORKSPACE,
          params: {
            delta: profileData,
          },
        });
        closeAction();
      } else {
        notificationsApi.displayError({
          title: "Error",
          body: "Sorry, we could not save your changes. Please try again later",
        });
      }
    } catch (e: any) {
      notificationsApi.displayError({
        title: "Error",
        body: "Sorry, we could not save your changes. Please try again later",
      });
    }
  };

  return (
    <SharedEdit
      disabled={!canManageBaseSettings}
      profileData={profileData}
      setprofileData={setprofileData}
      saveAction={save}
      closeAction={closeAction}
      mode="base"
    />
  );
};

const GroupProfileEdit: React.FC<{
  groupId: string;
  closeAction: () => void;
}> = ({ groupId, closeAction }) => {
  const groupData = useShallowSelector((store) => store.groups.dict[groupId]);
  const canManageGroups = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });
  const [profileData, setprofileData] = useState<IProfileData>({
    coverPhotoUrl: groupData?.coverPhotoUrl ?? "",
    name: groupData?.name ?? "",
    tagline: groupData?.description ?? "",
  });

  useEffect(() => {
    setprofileData({
      coverPhotoUrl: groupData?.coverPhotoUrl ?? "",
      name: groupData?.name ?? "",
      tagline: groupData?.description ?? "",
    });
  }, [groupData]);

  const save = () => {
    if (!profileData.name) {
      notificationsApi.displayError({
        title: "Error",
        body: "Name cannot be blank",
      });
      return;
    }

    const existingGroup: IGroup = {
      ...groupData,
      name: profileData.name,
      description: profileData.tagline,
      coverPhotoUrl: profileData.coverPhotoUrl,
    };

    groupApi.updateGroup(existingGroup, existingGroup.id).then(() => {
      closeAction();
      notificationsApi.displayConfirmation({
        title: "Success",
        duration: 4,
        body: "Group updated",
      });
    });
  };

  return (
    <SharedEdit
      disabled={!canManageGroups}
      profileData={profileData}
      setprofileData={setprofileData}
      saveAction={save}
      closeAction={closeAction}
      mode="group"
    />
  );
};

const SharedEdit: React.FC<{
  disabled: boolean;
  setprofileData: React.Dispatch<React.SetStateAction<IProfileData>>;
  profileData: IProfileData;
  saveAction: () => void;
  closeAction: () => void;
  mode: "base" | "group";
}> = ({
  disabled,
  profileData,
  setprofileData,
  saveAction,
  closeAction,
  mode,
}) => {
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setprofileData({ ...profileData, name: e.target.value });
  };
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setprofileData({ ...profileData, tagline: e.target.value });
  };
  const onUploadSuccess = (photoUrl: string) => {
    setprofileData({ ...profileData, coverPhotoUrl: photoUrl });
  };

  return (
    <>
      <div>
        <Conditional on={mode === "group"}>
          <span className="caption secondary bold">Cover Photo</span>
          <div className={styles.options_margin}>
            <div className={styles.options_avatar}>
              <PhotoUpload
                onUploadSucess={onUploadSuccess}
                disabled={disabled}
                exitingUrl={profileData.coverPhotoUrl}
                bucketName={BucketAlbums.GROUPCOVER}
                fallBackComponent={
                  <Button disabled={!disabled} buttonType={ButtonTypes.DEFAULT}>
                    Upload
                  </Button>
                }
              />
            </div>
          </div>
        </Conditional>

        <div className={styles.options_margin}>
          <ClarityInput
            name="name"
            onChange={handleNameChange}
            value={profileData.name ?? ""}
            placeholder="Name"
            label="Name"
            maxLength={50}
            required
            disabled={disabled}
          />
        </div>

        <div className={styles.options_margin}>
          <ClarityTextArea
            className=""
            label="Description"
            value={profileData.tagline ?? ""}
            onKeyDown={(e) => e.stopPropagation()}
            placeholder="Description"
            onChange={handleTextAreaChange}
            maxLength={200}
            rows={2}
            disabled={disabled}
          />
          <div>
            <span className={`caption secondary ${styles.options_hint}`}>
              <InfoCircleOutlined />{" "}
              {mode === "group"
                ? `This tagline will be displayed on the Groups list and Group Profile`
                : `This tagline will be displayed on the Base Profile`}
            </span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "12px",
            marginTop: "48px",
          }}
        >
          <Button onClick={closeAction}>Discard</Button>
          <Button
            onClick={saveAction}
            disabled={disabled}
            buttonType={ButtonTypes.PRIMARY}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProfileEdit;
