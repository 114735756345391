import { ILineValue } from "./lineUtilities";
import { Citation, CitationData } from "./types";
import { localChangesUUID } from "editor/utils/specificActions/persistActions";
import { axiosInstance } from "index";
const { v4: uuidv4 } = require("uuid");

export const createCitation = (
  citationPresetData: Omit<Citation, "id">,
  value: ILineValue[]
): CitationData => {
  const containerId =
    citationPresetData.referencingContainerId !== "newComment"
      ? citationPresetData.referencingContainerId
      : localChangesUUID;
  const isDeleted =
    citationPresetData.referencingContainerId !== "newComment" ? false : true;

  const newCitation: Citation = {
    id: uuidv4(),
    dateAccessed: citationPresetData.dateAccessed,
    dateCreated: citationPresetData.dateCreated,
    dateModified: citationPresetData.dateModified,
    referencingBlockId: citationPresetData.referencingBlockId,
    referencingContainerId: containerId
      ? containerId
      : citationPresetData.referencingContainerId,
    referencingContainerType: citationPresetData.referencingContainerType,
    sourceBlockBaseId: citationPresetData.sourceBlockBaseId,
    sourceBlockId: citationPresetData.sourceBlockId,
    sourceContainerId: citationPresetData.sourceContainerId,
    sourceContanierType: citationPresetData.sourceContanierType,
    synchronize: citationPresetData.synchronize,
    isDeleted,
  };

  axiosInstance.post("/api/citation", {
    id: newCitation.id,
    referencingBlockId: newCitation.referencingBlockId,
    sourceBlockId: newCitation.sourceBlockId,
    dateAccessed: newCitation.dateAccessed,
    dateModified: newCitation.dateModified,
    referencingContainerId: newCitation.referencingContainerId,
    sourceBlockBaseId: newCitation.sourceBlockBaseId,
    sourceContainerId: newCitation.sourceContainerId,
    sourceContanierType: newCitation.sourceContanierType,
    referencingContainerType: newCitation.referencingContainerType,
    synchronize: newCitation.synchronize,
    isDeleted,
  });

  return {
    citationId: newCitation.id,
    containerId: newCitation.sourceContainerId,
    containerType: newCitation.sourceContanierType,
    dateAccessed: newCitation.dateAccessed,
    dateModified: newCitation.dateModified,
    referencingBlockId: newCitation.referencingBlockId,
    referencingContainerType: newCitation.referencingContainerType,
    referencingContainerId: newCitation.referencingContainerId,
    sourceBlockBaseId: newCitation.sourceBlockBaseId,
    sourceBlockId: newCitation.sourceBlockId,
    synchronize: newCitation.synchronize,
    value,
  };
};
