import React from "react";
import styles from "./tabLayout/tabLayout.module.scss";

interface Props {
  title: string;
  description?: string;
  secondaryActionBtn?: React.ReactNode;
  actionBtn?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
}

const TabLayout = ({
  title,
  actionBtn,
  secondaryActionBtn,
  children,
  description,
}: Props) => (
  <div className={styles.tab_layout}>
    <div className={styles.tab_layout_header}>
      <div>
        <h2 className={styles.tab_layout_header_title}>{title}</h2>
        {description && (
          <div
            className={`body2 secondary regular ${styles.tab_layout_header_description}`}
          >
            {description}
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "24px",
          height: "100%",
        }}
      >
        {secondaryActionBtn && secondaryActionBtn}
        {actionBtn && actionBtn}
      </div>
    </div>
    {children && children}
  </div>
);

export default TabLayout;
