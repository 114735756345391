import React from "react";
import ClarityLogo from "clarity-ui/ClarityLogo";
import styles from "./resetPassword/resetPassword.module.scss";
import Helmet from "react-helmet";
import Otp, { OtpMode } from "components/Otp";

interface Props {}

const ResetPassword = (props: Props) => {
  return (
    <div className={styles.reset_password}>
      <Helmet>
        <title>{`Change your password • Clarity`}</title>
      </Helmet>
      <ClarityLogo
        containerStyle={({ isMobile }) => ({ marginBottom: "68px" })}
      />
      <Otp mode={OtpMode.FORGOT_PASSWORD} />
    </div>
  );
};

export default ResetPassword;
