import { useState, useEffect } from "react";
import Modal from "clarity-ui/Modal";
import Button, { ButtonTypes } from "components/Button";
import * as actionTypes from "store/actions";
import { connect } from "react-redux";
import { IUserObj } from "utilities/types";
import GettingStartedProgress from "components/GettingStartedProgress";
import ThumbsUp from "icons/thumbs_up.png";
import ThumbsDown from "icons/thumbs_down.png";
import Airtable from "airtable";
import styles from "./gettingStartedModal/gettingStartedModal.module.scss";
import { axiosInstance } from "index";

interface IGettingStartedModalProps {
  hideModal: () => void;
  videoIdxToStart?: number;
}

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY ?? "",
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID ?? "");

export const videoIds = [
  "DDxYZHgj6ZE",
  "mbmYhmBCP1g",
  "SqQ19eZF4Bg",
  "fuH1T_FF4XA",
  "tQm6y23PD3c",
  "P7dXzSpWYWI",
];

function GettingStartedModalFn(
  props: IGettingStartedModalProps &
    IGettingStartedMapStateToProps &
    IGettingStartedMapDispatchToProps
) {
  const { hideModal, updateUser, user, videoIdxToStart } = props;

  const [currentUrlIdx, setCurrentUrlIdx] = useState(
    videoIdxToStart ?? user.gettingStartedProgress ?? 0
  );

  const currentUrl = `https://www.youtube.com/embed/${videoIds[currentUrlIdx]}?autoplay=1&rel=0&modestbranding=1`;

  const disableBackBtn = currentUrlIdx === 0;
  // const userIdRecord = useAirtableUserIdRecord(user.id);

  const [booleanRecord, updateBooleanRecord, setBooleanResponse] =
    useAirtableBooleanRecord({
      userId: user.id,
      videoId: currentUrlIdx + 1,
    });

  const currentBoolean = booleanRecord.fields["Boolean Response"];

  const thumbsDownIsActive = currentBoolean === "False";

  const thumbsUpIsActive = currentBoolean === "True";

  useEffect(() => {
    if (user.gettingStartedProgress === null) {
      patchUserProgress({ value: 0, user, updateUser });
    }
  });

  const prev = () => setCurrentUrlIdx(currentUrlIdx - 1);

  // const reset = async () =>
  //   await patchUserProgress({ value: 0, user, updateUser });

  const next = async () => {
    const newIdx = currentUrlIdx + 1;
    const payload = { value: newIdx, user, updateUser };
    if (currentUrlIdx < videoIds.length - 1) {
      setCurrentUrlIdx(newIdx);
      if (newIdx > (user.gettingStartedProgress ?? 0)) {
        await patchUserProgress(payload);
      }
    } else {
      await patchUserProgress(payload);
      /* await reset(); */
      props.hideModal();
    }
  };

  const submitBooleanFeedback = (response: boolean) => {
    setBooleanResponse(response);
    const isCreate = !currentBoolean;

    const isUpdate =
      (currentBoolean === "True" && response === false) ||
      (currentBoolean === "False" && response === true);

    const isDelete =
      (currentBoolean === "True" && response === true) ||
      (currentBoolean === "False" && response === false);

    if (isCreate) {
      createAirtableBooleanRecord({
        userId: user.id,
        videoId: currentUrlIdx + 1,
        response: response ? "True" : "False",
        resolve: (record: any) => updateBooleanRecord(),
      });
    } else if (isUpdate) {
      updateAirtableBooleanRecord({
        recId: booleanRecord.id,
        userId: user.id,
        videoId: currentUrlIdx + 1,
        response: response ? "True" : "False",
        resolve: (record: any) => updateBooleanRecord(),
      });
    } else if (isDelete) {
      deleteAirtableBooleanRecord({
        recId: booleanRecord.id,
        resolve: (record: any) => updateBooleanRecord(),
      });
    }
  };

  const [questionFormIsOpen, setQuestionFormIsOpen] = useState(false);

  const submitQuestion = (questionText: string) => {
    sendFeedbackEmail({ questionText, videoIdx: currentUrlIdx });
    createAirtableTextResponse({
      questionText,
      videoId: `${currentUrlIdx + 1}`,
      userId: user.id,
      userRecordId: user.id,
    });
  };

  const closeQuestionField = () => setQuestionFormIsOpen(false);

  const onDiscard = () => setQuestionFormIsOpen(false);

  return (
    <Modal size={"extraLarge"} hideModal={hideModal}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h4
          style={{ marginBottom: "20px", fontWeight: 600 }}
          className="h4 medium"
        >
          Getting Started: Part {currentUrlIdx + 1} of {videoIds.length}
        </h4>
        <iframe
          style={{
            width: "770px",
            height: "432px",
            borderRadius: "4px",
          }}
          src={currentUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {questionFormIsOpen && (
            <QuestionField
              onDiscard={onDiscard}
              submitQuestion={submitQuestion}
              closeQuestionField={closeQuestionField}
            />
          )}
          {!questionFormIsOpen && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "29px",
                marginTop: "32px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h5
                  style={{
                    width: "300px",
                    height: "24px",
                    fontWeight: 500,
                    textAlign: "center",
                    color: "rgba(30, 30, 44, 0.42)",
                  }}
                  className="h4 medium"
                >
                  Was this explanation clear?
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "18px",
                  width: "290px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100px",
                    justifyContent: "space-between",
                  }}
                >
                  <EmojiButton
                    src={ThumbsDown}
                    onClick={() => submitBooleanFeedback(false)}
                    isActive={thumbsDownIsActive}
                  />
                  <EmojiButton
                    src={ThumbsUp}
                    onClick={() => submitBooleanFeedback(true)}
                    isActive={thumbsUpIsActive}
                  />
                </div>
                <AskAQuestionButton
                  onClick={() => setQuestionFormIsOpen(true)}
                />
              </div>
            </div>
          )}
          {!questionFormIsOpen && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div style={{ width: "189px" }}>
                <GettingStartedProgress
                  showInfo={true}
                  currentUrlIdx={currentUrlIdx}
                />
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flex: "1",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    style={{ marginRight: "10px" }}
                    buttonType={ButtonTypes.DEFAULT}
                    onClick={prev}
                    disabled={disableBackBtn}
                  >
                    Back
                  </Button>
                  <Button buttonType={ButtonTypes.PRIMARY} onClick={next}>
                    {currentUrlIdx + 1 === videoIds.length
                      ? "Done"
                      : "Continue"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

async function patchUserProgress({
  value,
  user,
  updateUser,
}: {
  value: number;
  user: IUserObj;
  updateUser: IGettingStartedMapDispatchToProps["updateUser"];
}) {
  const {
    data: { status, payload },
  } = await axiosInstance.patch("/api/user", {
    patch: [
      {
        op: "replace",
        path: "/gettingStartedProgress",
        value,
      },
    ],
  });
  if (status === 1) {
    updateUser({
      ...user,
      ...payload,
    });
  }
}

async function sendFeedbackEmail({
  questionText,
  videoIdx,
}: {
  questionText: string;
  videoIdx: number;
}) {
  const {
    data: { status, info },
  } = await axiosInstance.post("/api/user/submitGettingStartedQuestion", {
    questionText,
    videoIdx,
  });
  if (status === 1) {
    console.log(info);
  }
}

interface IGettingStartedMapDispatchToProps {
  updateUser: (user: IUserObj) => { type: string };
}

const gettingStartedMapDispatchToProps = (
  dispatch: any
): IGettingStartedMapDispatchToProps => ({
  updateUser: (user: IUserObj) =>
    dispatch({ type: actionTypes.UPDATE_USER, user }),
});

interface IGettingStartedMapStateToProps {
  user: IUserObj;
}

const gettingStartedMapStateToProps = (
  state: any
): IGettingStartedMapStateToProps => ({
  user: state.user,
});

const GettingStartedModal = connect(
  gettingStartedMapStateToProps,
  gettingStartedMapDispatchToProps
)(GettingStartedModalFn);

export default GettingStartedModal;

interface IEmojiButtonProps {
  src: string;
  onClick: () => void;
  isActive: boolean;
}

function EmojiButton(props: IEmojiButtonProps) {
  const { src, onClick, isActive } = props;
  const className = `${styles.emojiBtn} ${
    isActive ? styles.emojiBtn__active : ""
  }`;
  return (
    <div className={className} onClick={onClick}>
      <div
        style={{
          width: "23px",
          height: "29px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            objectFit: "contain",
            width: "100%",
            maxWidth: "100%",
          }}
          alt="emoji"
          src={src}
        />
      </div>
    </div>
  );
}

interface IAskAQuestionButtonProps {
  onClick: () => void;
}

function AskAQuestionButton(props: IAskAQuestionButtonProps) {
  const { onClick } = props;
  return (
    <div className={`${styles.askAQuestionBtn}`} onClick={onClick}>
      <p>Ask a question</p>
    </div>
  );
}

interface IQuestionFieldProps {
  onDiscard: () => void;
  submitQuestion: (questionText: string) => void;
  closeQuestionField: () => void;
}

function QuestionField(props: IQuestionFieldProps) {
  const { onDiscard, submitQuestion, closeQuestionField } = props;
  const [showInput, setShowInput] = useState(true);
  const onSubmit = (questionText: string) => {
    submitQuestion(questionText);
    setShowInput(false);
  };
  const [inputValue, setInputValue] = useState("");
  return (
    <div
      className={`${styles.QuestionField} ${
        showInput || styles.QuestionField__success
      }`}
    >
      {showInput && (
        <textarea
          value={inputValue}
          className={styles.QuestionField__textarea}
          onInput={(ev: any) => setInputValue(ev.target.value)}
          placeholder="Submit your question here and we’ll get back to you"
        />
      )}
      {!showInput && (
        <div className={styles.QuestionField__success__text}>
          <h4
            className="h4"
            style={{
              marginBottom: "6px",
            }}
          >
            Thanks for the question!
          </h4>
          <div className="body2 secondary">We'll get back to you by email</div>
        </div>
      )}
      <div className={styles.QuestionField__footer}>
        {showInput && (
          <Button buttonType={ButtonTypes.LINK} onClick={onDiscard}>
            Discard
          </Button>
        )}
        {showInput && (
          <Button
            style={{ marginLeft: "8px", marginRight: "-8px" }}
            buttonType={ButtonTypes.PRIMARY}
            onClick={() => onSubmit(inputValue)}
          >
            Submit
          </Button>
        )}
        {!showInput && (
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={() => closeQuestionField()}
            style={{ marginRight: "-8px" }}
          >
            Okay
          </Button>
        )}
      </div>
    </div>
  );
}

function createAirtableTextResponse({
  questionText,
  videoId,
  userId,
  userRecordId,
}: {
  questionText: string;
  videoId: string;
  userId: string;
  userRecordId: string;
}) {
  base("Text Responses").create(
    {
      "Feedback Content": questionText,
      "While Watching (Video ID)": [videoId],
      "Submitted By (User ID)": userId,
      "User IDs": [userRecordId],
    },
    { typecast: true },
    (err: any, record: any) => {
      if (err) {
        console.error(err);
        return;
      }
      console.log(record.getId());
    }
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useAirtableUserIdRecord(userId: string) {
  const [userIdRecord, setUserIdRecord] = useState({ id: "" });
  useEffect(() => {
    if (!userIdRecord) {
      retrieveAirtableUserIdRecord(userId, (record: any) => {
        setUserIdRecord(record);
        console.log(record);
      });
    }
  }, [userIdRecord]);
  return userIdRecord;
}

function retrieveAirtableUserIdRecord(
  userId: string,
  resolve: (record: any) => void
) {
  base("User IDs")
    .select({
      view: "Raw Data",
      filterByFormula: `{User ID} = '${userId}'`,
    })
    .firstPage((err, records) => {
      if (err) {
        console.error(err);
        return;
      }
      const userIdRecord = records ? records[0] : false;
      if (!userIdRecord) {
        createAirtableUserIdRecord(userId, resolve);
      } else {
        resolve(userIdRecord);
      }
    });
}

function createAirtableUserIdRecord(
  userId: string,
  resolve: (record: any) => void
) {
  base("User IDs").create(
    {
      "User ID": userId,
    },
    { typecast: true },
    (err, record) => {
      if (err) {
        console.error(err);
        return;
      }
      resolve(record);
    }
  );
}

function retrieveAirtableBooleanRecord({
  userId,
  videoId,
  resolve,
}: {
  userId: string;
  videoId: number;
  resolve: (record: any) => void;
}) {
  base("Boolean Responses")
    .select({
      view: "Raw Data",
      filterByFormula: `AND({Submitted By (User ID)} = '${userId}', {While Watching (Video IDs)} = '${[
        videoId,
      ]}')`,
    })
    .firstPage((err, records) => {
      if (err) {
        console.error(err);
        return;
      }
      resolve(records ? records[0] : false);
    });
}

function createAirtableBooleanRecord({
  userId,
  videoId,
  response,
  resolve,
}: {
  userId: string;
  videoId: number;
  response: "True" | "False";
  resolve: (record: any) => void;
}) {
  base("Boolean Responses").create(
    {
      "Submitted By (User ID)": userId,
      "Boolean Response": response,
      "While Watching (Video IDs)": [String(videoId)],
      "User IDs": [userId],
    },
    { typecast: true },
    (err, record) => {
      if (err) {
        console.error(err);
        return;
      }
      console.log(record?.getId());
      resolve(record);
    }
  );
}

function useAirtableBooleanRecord({
  userId,
  videoId,
}: {
  userId: string;
  videoId: number;
}): [
  { id: string; fields: { "Boolean Response": string } },
  () => void,
  (response: boolean) => void
] {
  const defaultRecord = {
    id: "",
    fields: {
      "Boolean Response": "",
    },
  };
  const [booleanRecord, setBooleanRecord] = useState(defaultRecord);
  const updateBooleanRecord = () =>
    retrieveAirtableBooleanRecord({
      userId,
      videoId,
      resolve: (record: any) => setBooleanRecord(record ?? defaultRecord),
    });
  useEffect(() => updateBooleanRecord(), [userId, videoId]);
  const setBooleanResponse = (response: boolean) => {
    const pair = [
      response ? "True" : "False",
      booleanRecord.fields["Boolean Response"],
    ];
    if (pair[0] === pair[1]) {
      setBooleanRecord({
        ...defaultRecord,
        fields: { "Boolean Response": "" },
      });
    } else if (pair[0] !== pair[1]) {
      setBooleanRecord({
        ...defaultRecord,
        fields: { "Boolean Response": pair[0] },
      });
    }
  };
  return [booleanRecord, updateBooleanRecord, setBooleanResponse];
}

function updateAirtableBooleanRecord({
  userId,
  videoId,
  response,
  resolve,
  recId,
}: {
  userId: string;
  videoId: number;
  response: "True" | "False";
  resolve: (record: any) => void;
  recId: string;
}) {
  base("Boolean Responses").update(
    recId,
    {
      "Submitted By (User ID)": userId,
      "Boolean Response": response,
      "While Watching (Video IDs)": [String(videoId)],
      "User IDs": [userId],
    },
    { typecast: true },
    (err, record) => {
      if (err) {
        console.error(err);
        return;
      }
      resolve(record);
    }
  );
}

function deleteAirtableBooleanRecord({
  resolve,
  recId,
}: {
  resolve: (record: any) => void;
  recId: string;
}) {
  base("Boolean Responses").destroy(recId, (err, record) => {
    if (err) {
      console.error(err);
      return;
    }
    resolve(record);
  });
}
