import React, { useEffect, useState } from "react";
import { connect, batch } from "react-redux";
import { Alert, Menu, message, Modal as AntModal } from "antd";
import {
  Abilities,
  ContainerTypes,
  GeneralViewsNames,
  GroupGeneralViews,
  IPinObj,
  WorkTypes,
} from "utilities/types";
import { socket } from "App";
import store, { ClarityStore } from "store/storeExporter";
import * as actionTypes from "store/actions";
import {
  ExclamationCircleOutlined,
  EditOutlined,
  HeartOutlined,
  DeleteOutlined,
  FileAddOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  deleteDocument,
  deleteProject,
  deleteNote,
} from "modules/documentService";
import { WorkEntry } from "store/reducers/workReducer";
import { stripHtml } from "utilities/stringUtilities";
import { showDeleteViewConfirm } from "screens/base/main/workViews/WorkViewList";
import { useEditViewModalSetter } from "store/reducers/clientReducer";
import EditViewModal from "components/EditViewModal";
import { useAbilityChecker } from "editor/utils/customHooks";
import { favoriteApi } from "clientApi/favoriteApi";
import { useFavorites } from "store/reducers/sidebarReducer";
import Modal from "clarity-ui/Modal";
import { useShallowSelector } from "utilities/hooks";
import ReactDOM from "react-dom";
import ClarityInput from "components/ClarityInput";
import Button, { ButtonTypes } from "components/Button";
import { noteApi } from "clientApi/noteApi";
import { VALUE_ENTER, VALUE_ESCAPE } from "keycode-js";
import { getHtml } from "editor/utils/blockValueHelpers";
import styles from "./detailViewMoreMenu.module.scss";
import { ShareBtn } from "components/TopNavBar";
import { axiosInstance } from "index";
import navigationApi from "clientApi/navigationApi";
import { ChunkDestination } from "utilities/stateTypes";
import {
  getGroupsFromEntityId,
  getNameFromContainer,
  getUntitledNoteTitle,
} from "modules/containerHelpers";
import workApi from "clientApi/workApi";
import { templatesApi } from "clientApi/templateApi";
import { updateTemplateItemAction } from "store/reducers/templateReducer";
import { updateSnippetItemAction } from "store/reducers/snippetReducer";
import { snippetsApi } from "clientApi/snippetsApi";

const { confirm } = AntModal;

interface IDetailViewMoreMenuProps {
  activeEntity: any;
  containerType: ContainerTypes;
  containerId: string;
  pinId: string | null;
  paneId: ChunkDestination;
  title: string;
}

interface IMapStateToProps {
  documents: any;
  workDict: WorkEntry;
  base: any;
}

interface IMapDispatchToProps {
  addPinnedItem: (pin: IPinObj) => { type: string; pin: IPinObj };
  removeDocument: (documentId: string) => any;
  removeNote: (noteId: string) => { type: string; noteId: string };
  updateDocument: (document: any) => any;
  removeProject: (id: string) => { type: string; id: string };
  removeTask: (id: string) => { type: string; id: string };
}

function DetailViewMoreMenu(
  props: IDetailViewMoreMenuProps & IMapDispatchToProps & IMapStateToProps
) {
  const { activeEntity, containerType, containerId } = props;
  const [showRename, setshowRename] = useState(false);
  const setEditViewModal = useEditViewModalSetter();
  const showEditViewModal = () =>
    setEditViewModal({ isOpen: true, customView: activeEntity });

  const canDeleteContainer = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: getGroupsFromEntityId(containerId, containerType),
  });

  const favoriteId = useFavoriteId({
    primaryActiveEntity: activeEntity,
  });

  const navigationChunk = useShallowSelector(
    (state) => state.navigation.navigation[props.paneId]
  );

  const showDelete =
    !props.activeEntity.isWeekly &&
    stripHtml(getHtml(props.activeEntity.nameValue)).toLowerCase().trim() !==
      "weeklys" &&
    stripHtml(getHtml(props.activeEntity.nameValue)) !== "Weekly Notes";

  if (showRename)
    return <NoteRename noteId={containerId} setshowRename={setshowRename} />;

  return (
    <>
      <Menu className={styles.menu}>
        {favoriteId ? (
          <Menu.Item
            className={styles.menuItem}
            key="0"
            onClick={() => {
              favoriteApi.deleteFavorite({ favoriteId });
            }}
          >
            <span className={styles.menuItemTitle}>
              <span className={styles.menuItemIcon}>
                <EditOutlined />
              </span>
              <span className={styles.menuItemTitle}>
                {" "}
                Remove from Favorites
              </span>
            </span>
          </Menu.Item>
        ) : (
          <Menu.Item
            className={styles.menuItem}
            key="0"
            onClick={async (e) => {
              favoriteApi.addFavorite({
                entityId: containerId,
                entityType: containerType,
                navigationChunk,
                name: getNameFromContainer(activeEntity, containerType),
              });
            }}
          >
            <span className={styles.menuItemTitle}>
              <span className={styles.menuItemIcon}>
                <HeartOutlined />
              </span>
              <span className={styles.menuItemTitle}> Add to Favorites</span>
            </span>
          </Menu.Item>
        )}

        {(containerType === ContainerTypes.WORK ||
          containerType === ContainerTypes.PROJECT ||
          containerType === ContainerTypes.TASK
          ) && (
            <Menu.Item
              className={styles.menuItem}
              key="1"
              onClick={async (e) => {
                // workApi.duplicateWorkItem(activeEntity.id);
                workApi.openDuplicatModal(activeEntity.id);
              }}
            >
              <span className={styles.menuItemTitle}>
                <span className={styles.menuItemIcon}>
                  <CopyOutlined />
                </span>
                <span className={styles.menuItemTitle}>Make a copy...</span>
              </span>
            </Menu.Item>
          )}

        {containerType === ContainerTypes.TEMPLATE && (
          <Menu.Item
            className={styles.menuItem}
            key="1"
            onClick={async (e) => {
              templatesApi.openCreateWorkItemFromTemplate(activeEntity.id);
            }}
          >
            <span className={styles.menuItemTitle}>
              <span className={styles.menuItemIcon}>
                <FileAddOutlined />
              </span>
              <span className={styles.menuItemTitle}>Use as template...</span>
            </span>
          </Menu.Item>
        )}

        {((containerType === ContainerTypes.WORK ||
          containerType === ContainerTypes.PROJECT ||
          containerType === ContainerTypes.TASK
          ) &&
            activeEntity.workType === WorkTypes.TASK) && (
              <Menu.Item
                className={styles.menuItem}
                key="2"
                onClick={async (e) => {
                  workApi.openCreateTemplatetModal(activeEntity.id);
                }}
              >
                <span className={styles.menuItemTitle}>
                  <span className={styles.menuItemIcon}>
                    <FileAddOutlined />
                  </span>
                  <span className={styles.menuItemTitle}>
                    {" "}
                    Create template...
                  </span>
                </span>
              </Menu.Item>
            )}

        {containerType === ContainerTypes.TEMPLATE && (
          <Menu.Item
            className={styles.menuItem}
            key="2"
            onClick={async (e) => {
              templatesApi.openDuplicateTemplatetModal(activeEntity.id);
            }}
          >
            <span className={styles.menuItemTitle}>
              <span className={styles.menuItemIcon}>
                <CopyOutlined />
              </span>
              <span className={styles.menuItemTitle}>Make a copy...</span>
            </span>
          </Menu.Item>
        )}

        {containerType === ContainerTypes.CUSTOM_VIEW && (
          <>
            <Menu.Divider />
            <Menu.Item
              className={styles.menuItem}
              key="3"
              onClick={showEditViewModal}
              disabled={!canDeleteContainer}
            >
              <span className={styles.menuItemTitle}>
                <span className={styles.menuItemIcon}>
                  <EditOutlined />
                </span>
                <span className={styles.menuItemTitle}>
                  {" "}
                  Edit {getContainerName(
                    containerType,
                    props.activeEntity
                  )}...{" "}
                </span>
              </span>
            </Menu.Item>
            <Menu.Divider />
          </>
        )}

        {containerType !== ContainerTypes.CUSTOM_VIEW &&
          containerType !== ContainerTypes.SNIPPET &&
          containerType !== ContainerTypes.TEMPLATE && (
            <>
              <Menu.Divider className={styles.menuItemTopSpace} />
              <Menu.Item
                className={`${styles.menuItem} ${styles.menuItemShare}`}
                key="4"
                disabled={!canDeleteContainer}
              >
                <span className={styles.menuItemTitle}>
                  <ShareBtn
                    paneId={props.paneId}
                    asMenuItems={true}
                    btnClass={styles.actionsShareBtn}
                  />
                </span>
              </Menu.Item>
              <Menu.Divider className={styles.menuItemBottomSpace} />
            </>
          )}

        {showDelete && (
          <>
            <Menu.Item
              className={styles.menuItem}
              key="5"
              disabled={!canDeleteContainer}
              onClick={() => {
                if (!canDeleteContainer) return;
                showDeleteDocumentConfirm(containerType, containerId, props);
              }}
            >
              <span className={styles.menuItemTitle}>
                <span className={styles.menuItemIcon}>
                  <DeleteOutlined />
                </span>
                <span className={styles.menuItemTitle}> Delete...</span>
              </span>
            </Menu.Item>
          </>
        )}
      </Menu>
      <EditViewModal />
    </>
  );
}

export const unpinItemFromSidebar = async (id: string) => {
  return await axiosInstance
    .delete("/api/pin", { data: { id, clientId: socket.id } })
    .then((res) => {
      const { id } = res.data.payload;
      store.dispatch({ type: actionTypes.REMOVE_PIN, id });
    });
};

const showDeleteDocumentConfirm = (
  containerType: ContainerTypes,
  containerId: string,
  props: IDetailViewMoreMenuProps & IMapStateToProps & IMapDispatchToProps
) => {
  const openedInPrimary =
    store.getState().navigation.navigation.primary.entity?.containerId;
  const openedInSplit =
    store.getState().navigation.navigation.secondary.entity?.containerId;

  const navigateToScreen = (
    viewName: GeneralViewsNames | GroupGeneralViews,
    groupSlug?: string,
    groupId?: string
  ) => {
    if (openedInPrimary === containerId) {
      navigationApi.contextBasedNavigate({
        currentPane: ChunkDestination.primary,
        navigationChunk: {
          viewName,
          groupSlug,
          groupId,
        },
      });
    }
    if (openedInSplit === containerId) {
      navigationApi.contextBasedNavigate({
        currentPane: ChunkDestination.secondary,
        navigationChunk: {
          viewName,
          groupSlug,
          groupId,
        },
      });
    }
  };

  switch (containerType) {
    case ContainerTypes.DOCUMENT:
      return confirm({
        title: "Are you sure you want to delete this tag?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        maskClosable: true,
        okText: "Delete",

        onOk() {
          deleteDocument(containerId).then((resp: any) => {
            if (resp) {
              batch(() => {
                Object.values(resp.updatedDocuments).forEach((document) => {
                  props.updateDocument(document);
                });
                store.dispatch({
                  type: actionTypes.REMOVE_PIN,
                  id: resp.deletedPin,
                });
              });
            }
            const name = stripHtml(
              getHtml(props.documents[props.activeEntity.id]?.nameValue)
            );
            navigateToScreen(GeneralViewsNames.Wiki);

            props.removeDocument(containerId);
            documentDeletedMessage(name);
          });
        },
        onCancel() {},
      });
    case ContainerTypes.CUSTOM_VIEW:
      return showDeleteViewConfirm(
        containerId,
        props.base.id,
        props.base.slug,
        props.paneId
      );

    case ContainerTypes.NOTE: {
      return confirm({
        title: "Are you sure you want to delete this doc?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        maskClosable: true,
        okText: "Delete",

        onOk() {
          deleteNote(containerId).then(({ containerId, groupSlug }) => {
            navigateToScreen(GroupGeneralViews.Notes, groupSlug);
            props.removeNote(containerId);
            documentDeletedMessage("");
          });
        },
        onCancel() {},
      });
    }

    case ContainerTypes.TASK:
    case ContainerTypes.WORK:
    case ContainerTypes.PROJECT: {
      const container = store.getState().work.dict[containerId];
      const childrenCount = container.childrenAggregate.length;
      const contentText =
        childrenCount > 0 ? (
          <Alert
            message={`Deleting it will also delete ${childrenCount} subtask${
              childrenCount > 1 ? "s" : ""
            }`}
            type="error"
          />
        ) : (
          ""
        );
      return confirm({
        title: `Are you sure you want to delete this ${
          container
            ? getContainerName(containerType, container).trim()
            : containerType
        }?`,
        icon: <ExclamationCircleOutlined />,
        content: contentText,
        maskClosable: true,
        okText: "Delete",

        onOk() {
          deleteProject(containerId).then((resp: any) => {
            const name = props.workDict[props.activeEntity.id].name;

            if (container.workType === WorkTypes.TASK) {
              navigateToScreen(
                GroupGeneralViews.Tasks,
                undefined,
                container.groupId
              );
            } else
              navigateToScreen(
                GeneralViewsNames.Roadmap,
                undefined,
                container.groupId
              );

            store.dispatch({
              type: actionTypes.DELETE_WORK_ITEM,
              id: containerId,
            });
            documentDeletedMessage(name);
          });
        },
        onCancel() {},
      });
    }
    case ContainerTypes.SNIPPET:
      return confirm({
        title: "Are you sure you want to delete this snippet?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        maskClosable: true,
        okText: "Delete",

        onOk() {
          snippetsApi.deleteSnippet(containerId).then((res) => {
            if (!res) return;
            if (res.snippetId) {
              updateSnippetItemAction({
                delta: {},
                id: res.snippetId,
                skipInsertInList: false,
                type: "delete",
              });
            }
            navigateToScreen(GeneralViewsNames.Snippets);
          });
        },
        onCancel() {},
      });
    case ContainerTypes.TEMPLATE:
      return confirm({
        title: "Are you sure you want to delete this template?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        maskClosable: true,
        okText: "Delete",

        onOk() {
          templatesApi.deleteTemplate(containerId).then((res) => {
            console.log(res);
            if (!res) return;
            if (res.templateId) {
              updateTemplateItemAction({
                delta: {},
                id: res.templateId,
                skipInsertInList: false,
                type: "delete",
              });
            }
            navigateToScreen(GeneralViewsNames.Templates);
          });
        },
        onCancel() {},
      });
  }
};

export const documentDeletedMessage = (docName: string) => {
  message.success(`Deleted successfully`, 2);
};

const mapStateToProps = (state: ClarityStore) => {
  if (state.workspace) {
    return {
      documents: state.pages.dict,
      base: state.workspace,
      workDict: state.work.dict,
      secondaryViewEntity:
        state.navigation.navigation[ChunkDestination.secondary]?.entity
          ?.containerId,
    };
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  addPinnedItem: (pin: IPinObj) =>
    dispatch({ type: actionTypes.ADD_NEW_PIN, pin }),
  updateDocument: (document: any) =>
    dispatch({ type: actionTypes.UPDATE_DOCUMENT, document }),
  removeDocument: (documentId: string) =>
    dispatch({ type: actionTypes.REMOVE_DOCUMENT, documentId }),
  removeNote: (noteId: string) =>
    dispatch({ type: actionTypes.REMOVE_NOTE, noteId }),
  removeProject: (id: string) =>
    dispatch({ type: actionTypes.DELETE_WORK_ITEM, id }),
  removeTask: (id: string) =>
    dispatch({ type: actionTypes.DELETE_WORK_ITEM, id }),
});

function useFavoriteId({ primaryActiveEntity }: { primaryActiveEntity: any }) {
  const [favoriteId, setFavoriteId] = useState<string | null>(null);
  const { favoriteIds, favorites } = useFavorites();

  useEffect(() => {
    if (favorites && favoriteIds) {
      const matchingPin = favoriteIds.filter(
        (id) => favorites[id].entityId === primaryActiveEntity?.id
      )[0];
      setFavoriteId(matchingPin || null);
    }
  }, [favorites, favoriteIds, primaryActiveEntity?.id]);

  return favoriteId;
}

const NoteRename: React.FC<{
  noteId: string;
  setshowRename: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ noteId, setshowRename }) => {
  const note = useShallowSelector((state) => state.notes.dict[noteId]);
  const [noteName, setNoteName] = useState(note.title ? note.title : "");

  const confirmRename = () => {
    noteApi.updateNoteTitle(noteId, noteName);
    setshowRename(false);
  };

  const checkKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === VALUE_ENTER) {
      confirmRename();
      return;
    }
    if (e.key === VALUE_ESCAPE) {
      setshowRename(false);
      return;
    }
  };

  return ReactDOM.createPortal(
    <Modal hideModal={() => setshowRename(false)} zIndex={999}>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <h4 style={{ marginBottom: "10px" }}>Rename note</h4>
        <ClarityInput
          placeholder={getUntitledNoteTitle(note)}
          value={noteName}
          onKeyDown={checkKeyDown}
          autoFocus={true}
          onChange={(e) => setNoteName(e.target.value)}
        />
        <div style={{ display: "flex", marginTop: "20px" }}>
          <div style={{ marginLeft: "auto", display: "flex" }}>
            <span style={{ marginRight: "10px" }}>
              <Button
                buttonType={ButtonTypes.LINK}
                onClick={() => setshowRename(false)}
              >
                Cancel
              </Button>
            </span>

            <Button
              buttonType={ButtonTypes.PRIMARY}
              disabled={noteName === "" || noteName.trim() === note.title}
              onClick={() => {
                noteApi.updateNoteTitle(noteId, noteName);
                setshowRename(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>,
    document.body
  );
};

export const getContainerName = (
  containerType: ContainerTypes,
  entity?: any
) => {
  switch (containerType) {
    case ContainerTypes.DOCUMENT:
      return " Tag";
    case ContainerTypes.NOTE:
      return " Doc";
    case ContainerTypes.TASK:
    case ContainerTypes.WORK:
    case ContainerTypes.PROJECT: {
      if (entity.workType === WorkTypes.INITIATIVE) {
        return "Goal";
      } else {
        return entity.workType;
      }
    }
    case ContainerTypes.TEMPLATE:
      return " Template";
    case ContainerTypes.SNIPPET:
      return " Snippet";
    case ContainerTypes.CUSTOM_VIEW:
      return " View";
    default:
      return " Page";
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailViewMoreMenu);
