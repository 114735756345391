import notificationsApi from "clientApi/notificationsApi";
import { useShallowSelector } from "utilities/hooks";
import ActionCTAContent from "./ActionCTAContent";
import { ReactComponent as DiscordIcon } from "icons/discord-icon.svg";
import Button, { ButtonTypes } from "components/Button";
import {
  useDiscordIntegrationModalOpener,
  useDiscordIntegrationModalState,
} from "store/reducers/clientReducer";
import { DiscordIntegrationSteps } from "screens/base/main/DiscordConnection";
import {
  Abilities,
  ContainerTypes,
  IProjectObj,
  WorkTypes,
} from "utilities/types";
import { useAbilityChecker } from "editor/utils/customHooks";
import { getGroupsFromEntity } from "modules/containerHelpers";
import { useUser } from "store/reducers/userReducer";
import workApi from "clientApi/workApi";
import Icon from "@ant-design/icons";
import { useBase } from "store/reducers/workspaceReducer";

const OpenDiscordThreadCTA: React.FC<{ workItemId: string }> = ({
  workItemId,
}) => {
  const workItem = useShallowSelector((store) => store.work.dict[workItemId]);

  const base = useBase();
  const group = useShallowSelector((store) =>
    workItem.groupId ? store.groups.dict[workItem.groupId] : undefined
  );
  const user = useUser();
  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: getGroupsFromEntity(workItem, ContainerTypes.PROJECT),
  });
  const discordIntegrationOpener = useDiscordIntegrationModalOpener(
    true,
    workItem.workType === WorkTypes.TASK ? workItem?.groupId : undefined,
    workItem?.id,
    DiscordIntegrationSteps.ProjectConnection
  );

  const discordConnectionModalState = useDiscordIntegrationModalState();

  if (workItem.discordConnected) return <DiscordBanner workItem={workItem} />;

  return (
    <>
      <ActionCTAContent
        title={"Start a thread in Discord"}
        description={"Get updates for this task in a dedicated Discord thread"}
        icon={<Icon style={{ fontSize: "22px" }} component={DiscordIcon} />}
        accentColor="#7788D4"
      >
        <Button
          type={ButtonTypes.DEFAULT}
          disabled={
            workItem.discordConnected ||
            discordConnectionModalState.isOpen ||
            !canEditSettings
          }
          onClick={() => {
            if (workItem.workType === WorkTypes.TASK) {
              if (
                base.base.integrations?.discord?.activated &&
                group &&
                group.channelName &&
                user.discordUsername
              ) {
                workApi.connectToDiscord(workItem.id).catch((e) => {
                  notificationsApi.displayError({
                    title: "Oops something went wrong",
                    body: "Sorry, we could not create the thread. Please try again later",
                  });
                });
              } else {
                discordIntegrationOpener();
              }
            } else {
              discordIntegrationOpener();
            }
          }}
          style={{
            minWidth: "104px",
          }}
        >
          Start thread
        </Button>
      </ActionCTAContent>
    </>
  );
};

const DiscordBanner: React.FC<{ workItem: IProjectObj }> = ({ workItem }) => {
  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: getGroupsFromEntity(workItem, ContainerTypes.PROJECT),
  });

  const title = `View thread in Discord`;

  return (
    <>
      <ActionCTAContent
        title={title}
        description={
          <>
            <span>
              This task is linked to a thread.{" "}
              <span
                onClick={(e) => {
                  if (!canEditSettings) {
                    return;
                  }
                  workApi.disconnectFromDiscord(workItem.id);
                }}
                className={"linkText"}
              >
                Unlink
              </span>
            </span>
          </>
        }
        icon={<Icon component={DiscordIcon} />}
        accentColor="#7788D4"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <Button
            onClick={() => {
              window.open(`${workItem.discordThreadLink}`, "_blank");
            }}
          >
            Open in Discord
          </Button>
        </div>
      </ActionCTAContent>
    </>
  );
};

export default OpenDiscordThreadCTA;
