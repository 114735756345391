import { getHtml } from "editor/utils/blockValueHelpers";
import { LineType } from "utilities/lineUtilities";
import CollaboratorAvatar from "../../CollaboratorAvatar";

const getMaxWidthDiff = (props: any): number => {
  const diff =
    props.lineData.indentLevel > 1
      ? 22
      : props.lineData.indentLevel === 1
      ? 40
      : 0;

  return diff;
};

export const getClassNameFromType = (lineType: string): string => {
  switch (lineType) {
    case LineType.heading1:
      return "h2 ";
    case LineType.heading2:
      return "h3 ";
    case LineType.heading3:
      return "h4 ";
    case LineType.checkbox:
      return "checkbox ";
    default:
      return "";
  }
};

const ReadOnlyBlock = (props: any) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: getHtml(props.lineData.value) }}
        tabIndex={0}
        ref={props.lineData.ref}
        placeholder=" "
        id={props.id}
        className={
          "content-section " + getClassNameFromType(props.lineData.lineType)
        }
        style={{
          paddingLeft: props.lineData.indentLevel > 0 ? 5 + "px" : 0,
          maxWidth:
            props.lineData.lineType === LineType.checkbox
              ? `calc(100% - ` + (24 + getMaxWidthDiff(props)) + `px)`
              : "100%",
        }}
      ></div>
      {props.lineData.frozen ? (
        <CollaboratorAvatar user={props.lineData.frozenBy} index={0} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ReadOnlyBlock;
