import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import Conditional from "components/Conditional";
import ProjectsGroupsDisplay from "components/ProjectsGroupsDisplay";
import React from "react";
import {
  CommandPaletteContext,
  ContainerTypes,
  WorkTypes,
} from "utilities/types";
import Icon from "@ant-design/icons";
import { ReactComponent as GroupsIcon } from "icons/menu/Groups.svg";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";

const GroupsRow: React.FC<{
  groupIds?: string[];
  canEditEntity: boolean;
  workType: WorkTypes;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ groupIds, canEditEntity, workType, containerId, containerType }) => {
  const handleMultipleGroupClick = () =>
    openCommandPalette(CommandPaletteContext.SELECT_MULTIPLE_GROUPS, {
      selectedItemIds: [containerId],
      slectedItemsType: containerType,
    });

  return (
    <Conditional
      on={workType === WorkTypes.PROJECT || workType === WorkTypes.INITIATIVE}
    >
      <MetadataBlock
        title="Groups"
        disabled={!canEditEntity}
        prefix={<Icon component={GroupsIcon} />}
        onClick={handleMultipleGroupClick}
      >
        <ProjectsGroupsDisplay showIcon={false} groupIds={groupIds ?? []} />
      </MetadataBlock>
    </Conditional>
  );
};

export default GroupsRow;
