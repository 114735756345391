import React from "react";
import ModalScrimComponent from "components/ModalScrimComponent";
import Button, { ButtonTypes } from "components/Button";
import styles from "./discussionModal/discussionModal.module.scss";
import { CloseOutlined } from "@ant-design/icons";

interface IModalProps {
  hideModal: () => void;
  children: any | any[];
  size?: "small" | "medium" | "large" | "extraLarge";
  title: string;
  topRowChildren?: JSX.Element;
}

export default function DiscussionModal(props: IModalProps) {
  const { children, size, hideModal, title, topRowChildren } = props;
  const maxWidth = size
    ? {
        extraLarge: "818px",
        large: "640px",
        medium: "536px",
        small: "484px",
      }[size] ?? "484px"
    : "484px";

  return (
    <div className={styles.container}>
      <div className={styles.pane} style={{ maxWidth }}>
        <div className={styles.paneTopRow}>
          <span className={styles.paneTitle}>{title}</span>
          <div className={styles.paneTopRowChildren}>{topRowChildren}</div>
          <Button
            onClick={hideModal}
            className={styles.paneCloseBtn}
            icon={<CloseOutlined />}
            buttonType={ButtonTypes.LINK}
          />
        </div>
        <div className={styles.paneBody}>{children}</div>
      </div>
      <ModalScrimComponent hideModal={hideModal} />
    </div>
  );
}
