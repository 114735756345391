import Icon from "@ant-design/icons";
import React from "react";

export default function PeekIcon(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 14 14"
      width="1em"
      height="1em"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M13.75,1.75 L2.25,1.75 C1.9734375,1.75 1.75,1.9734375 1.75,2.25 L1.75,13.75 C1.75,14.0265625 1.9734375,14.25 2.25,14.25 L7.875,14.25 C7.94375,14.25 8,14.19375 8,14.125 L8,13.25 C8,13.18125 7.94375,13.125 7.875,13.125 L2.875,13.125 L2.875,2.875 L13.125,2.875 L13.125,7.875 C13.125,7.94375 13.18125,8 13.25,8 L14.125,8 C14.19375,8 14.25,7.94375 14.25,7.875 L14.25,2.25 C14.25,1.9734375 14.0265625,1.75 13.75,1.75 Z M10.2078125,9.365625 L11.0234375,8.55 C11.096875,8.4765625 11.053125,8.35 10.95,8.3375 L8.146875,8.009375 C8.0671875,8 7.9984375,8.0671875 8.0078125,8.1484375 L8.3359375,10.9515625 C8.3484375,11.0546875 8.475,11.0984375 8.5484375,11.025 L9.3671875,10.20625 L13.3703125,14.209375 C13.41875,14.2578125 13.4984375,14.2578125 13.546875,14.209375 L14.209375,13.546875 C14.2578125,13.4984375 14.2578125,13.41875 14.209375,13.3703125 L10.2078125,9.365625 Z"
          id="path-1"
        />
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icon/Open-Main" transform="translate(-1.000000, -1.000000)">
          <mask id="mask-2" fill="currentColor">
            <use href="#path-1" />
          </mask>
          <use
            id="Shape"
            fillOpacity="100"
            fill="currentColor"
            href="#path-1"
          />
        </g>
      </g>
    </svg>
  );
}
