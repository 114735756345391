import Button, { ButtonTypes } from "components/Button";
import PricingPlans from "components/PricingPlans";
import { blockApi } from "editor/utils/blockActionsApi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SET_DEFAULT_MODAL } from "store/actions";
import store, { ClarityStore } from "store/storeExporter";
import Modal from "./Modal";
import MonthlyCount from "./MonthlyCount";
import { usernameSplitter } from "./UserDisplay";

const DefaultModalView: React.FC<{}> = () => {
  const defaultModalState = useSelector((state: ClarityStore) => {
    return state.client.defaultModal;
  }, shallowEqual);

  const innerState = useSelector((store: any) => {
    return {
      user: store.user,
      base: store.workspace,
    };
  }, shallowEqual);

  const closeModal = useCallback(() => {
    store.dispatch({
      type: SET_DEFAULT_MODAL,
      param: {
        isOpen: false,
        type: "",
      },
    });
  }, []);

  useEffect(() => {
    return () => {
      blockApi.blockAction.refocus();
    };
  }, []);

  if (!defaultModalState || !defaultModalState.isOpen) return <></>;

  const getBodyFromType = () => {
    switch (defaultModalState.type) {
      case "freeLimit":
        return <FreePlanLimit closeModal={closeModal} />;
      case "plansModal":
        return (
          <div>
            <PricingPlans base={innerState.base} user={innerState.user} />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <Modal
      size="large"
      hideModal={() => {
        closeModal();
      }}
    >
      {getBodyFromType()}
    </Modal>
  );
};

const FreePlanLimit: React.FC<{ closeModal: any }> = ({ closeModal }) => {
  const plansState = useSelector((state: ClarityStore) => {
    return {
      blockCount: state.subscription.monthlyCount.blockCount,
      workCount: state.subscription.monthlyCount.workCount,
      user: state.user,
    };
  }, shallowEqual);

  const [nameToDisplay, setnameToDisplay] = useState("");

  useEffect(() => {
    if (plansState.user?.name) {
      const index = plansState.user.name.indexOf(" ");
      if (index > 0) {
        const firstName = plansState.user.name.substr(0, index);
        setnameToDisplay(firstName);
        return;
      }
      setnameToDisplay(plansState.user.name);
      return;
    }
    if (plansState.user?.username)
      setnameToDisplay(`@${plansState.user.username}`);
  }, [plansState.user]);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      ref={ref}
      tabIndex={-1}
      style={{ display: "flex", flexDirection: "column", padding: "20px 0" }}
    >
      <h4
        style={{ marginBottom: "24px", fontWeight: 600, textAlign: "center" }}
        className="h4 medium"
      >
        Howdy {usernameSplitter(nameToDisplay)}!
      </h4>
      <div
        style={{
          marginBottom: "34px",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <p>
          Thanks for using Clarity.
          <br />
          It looks like you've reached your free monthly limit.
        </p>
        <p className="caption secondary">
          <MonthlyCount />
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ marginRight: "10px" }}
              buttonType={ButtonTypes.DEFAULT}
              onClick={() => {
                closeModal();
              }}
            >
              Go back
            </Button>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              onClick={() => {
                store.dispatch({
                  type: SET_DEFAULT_MODAL,
                  param: {
                    isOpen: true,
                    type: "plansModal",
                  },
                });
              }}
            >
              See Plans
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultModalView;
