import Button, { ButtonTypes } from "components/Button";
import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HIDE_SECONDARY_VIEW, SWAP_PANES } from "store/actions";
import store, { ClarityStore } from "store/storeExporter";
import { SwapOutlined, CloseOutlined } from "@ant-design/icons";
import { ChunkDestination } from "utilities/stateTypes";
import styles from "./topNavBar.module.scss";
import navigationApi from "clientApi/navigationApi";

export function ViewSwapBtn() {
  const secondaryView = useSelector(
    (store: ClarityStore) => store.navigation.splitView.displaySecondaryView,
    shallowEqual
  );
  const secondaryChunk = useSelector(
    (store: ClarityStore) =>
      store.navigation.navigation[ChunkDestination.secondary],
    shallowEqual
  );
  const handleViewsSwap = useCallback(() => {
    store.dispatch({ type: SWAP_PANES });
    const url = navigationApi.getLinkFromNavigationChunk(secondaryChunk);
    window.history.replaceState(null, "", url);
  }, [secondaryView, secondaryChunk]);
  if (!secondaryView) return <></>;
  return (
    <Button
      className={styles.buttonOverwrite2}
      buttonType={ButtonTypes.LINK}
      onClick={handleViewsSwap}
      icon={<SwapOutlined />}
      iconStyle={{ color: "#fff" }}
    />
  );
}

export function SplitViewCloseBtn() {
  const { hideSplitView } = useSplitViewCloseBtnState();

  return (
    <Button
      className={styles.buttonOverwrite2}
      onClick={hideSplitView}
      buttonType={ButtonTypes.LINK}
      icon={<CloseOutlined />}
      iconStyle={{ color: "#fff" }}
    />
  );
}

function useSplitViewCloseBtnState() {
  const dispatch = useDispatch();
  const hideSplitView = () =>
    dispatch({ type: HIDE_SECONDARY_VIEW, removeContainer: true });

  return {
    hideSplitView,
  };
}
