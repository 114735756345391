export const pasteNodeCreator = (
  parentNode: any,
  lineValues: any[] = [],
  document: any
) => {
  if (lineValues !== undefined) {
    lineValues.reverse().forEach((lineValue) => {
      if (lineValue.name === "#text") {
        const textNode = document.createTextNode(lineValue.value);
        parentNode.appendChild(textNode);
      } else if (lineValue.name === "a") {
        const node = document.createElement(lineValue.name);
        node.classList.add("decorated");
        node.setAttribute("href", lineValue.link);
        if (lineValue.value !== null) {
          const textNode = document.createTextNode(lineValue.value);
          node.appendChild(textNode);
        }
        parentNode.appendChild(node);
        if (lineValue.children.length > 0) {
          pasteNodeCreator(node, lineValue.children, document);
        }
      } else {
        const node = document.createElement(lineValue.name);
        if (lineValue.value !== null) {
          const textNode = document.createTextNode(lineValue.value);
          node.appendChild(textNode);
        }
        parentNode.appendChild(node);

        if (lineValue.children.length > 0) {
          pasteNodeCreator(node, lineValue.children, document);
        }
      }
    });
  }
  return parentNode;
};
