// Vendor Libraries
import { connect, batch } from "react-redux";
import Helmet from "react-helmet";
import { History, LocationState } from "history";
import { useState, useEffect, useRef, useLayoutEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

// Components
import MentionsSection from "clarity-ui/MentionsSection";

// Internal Modules
import * as actionTypes from "../../../../../store/actions";
import {
  DocumentModes,
  IPageObj,
  IUserObj,
  ContainerTypes,
  UserEventTypes,
  IWorkspaceObj,
  IBlockContext,
  BaseType,
} from "../../../../../utilities/types";
import { Line, LineType } from "../../../../../utilities/lineUtilities";
import { stripHtml } from "../../../../../utilities/stringUtilities";
import { getRelatedEntities } from "modules/documentService";
import { WorkEntry } from "store/reducers/workReducer";
import { ChunkDestination } from "utilities/stateTypes";
import { loadContainerData } from "editor/utils/containerActions/loadContainerActions";
import { Block } from "store/reducers/blockReducer";
import { $focusOn, ClarityStore } from "store/storeExporter";
import { clearBlockSelection } from "editor/utils/blockActions";
import { mixpanel } from "App";
import EditorContainer from "editor/EditorContainer";
import ContainerTitleBlockWrapper from "editor/blockContainer/ContainerTitleBlockWrapper";
import { useContainerContextSetter } from "editor/utils/customHooks";
import { useShallowSelector } from "utilities/hooks";
import { getHtml } from "editor/utils/blockValueHelpers";
import { useBaseType } from "store/reducers/workspaceReducer";

interface IDocumentComponentProps {
  history: History<LocationState>;
  lines?: Line[];
  containerId: string;
  loading: boolean;
  document: IPageObj | null;
  isBaseMember: boolean;
  changeDocumentMode: (param: any) => any;
  connected: boolean;
  paneId: ChunkDestination;
  documentComments: any;
}

interface IMapStateToProps {
  displayModalDocumentShare: boolean;
  user: IUserObj | undefined;
  newDiscussionDetail: any;
  outlineMode: null | string;
  workDict: WorkEntry;
  base: IWorkspaceObj;
}

export const getOutlineListType = (outlineMode: string | null) => {
  if (outlineMode) {
    if (outlineMode === LineType.numberedList) return "numbered-1";
    if (outlineMode === LineType.bulletedList) return "bullet-1";
  }
  return "no-outline";
};

function Document(
  props: RouteComponentProps<any> & IDocumentComponentProps & IMapStateToProps
) {
  const prevId = useRef("");
  const baseName = useShallowSelector((store) => store.workspace.name);
  const activeId = useRef(props.containerId);
  activeId.current = props.containerId;
  const documentRef = useRef<HTMLDivElement | null>(null);
  const titleBlock = useRef<Block | null>(null);
  const userRole = useShallowSelector((state: ClarityStore) => {
    return state.workspace.roleType;
  });

  const baseType = useBaseType();

  const [pageContext, setPageContext] = useState<IBlockContext>({
    id: ContainerTypes.DOCUMENT + props.containerId,
    type: "container",
    container: {
      id: props.containerId,
      type: ContainerTypes.DOCUMENT,
      outlineMode: props.outlineMode ? props.outlineMode : "noOutline",
    },
    ref: documentRef,
    paneId: props.paneId,
    persistToggle: props.connected && props.isBaseMember,
    autosave: props.connected && props.isBaseMember,
    canEdit: props.connected && props.isBaseMember,
    online: props.connected,
    canComment: props.connected && props.isBaseMember,
    showInterceptor: false,
  });

  useEffect(() => {
    return () => {
      if (
        $focusOn.value.focusContext?.id === pageContext.id &&
        $focusOn.value.focusPane === pageContext.paneId
      ) {
        $focusOn.next({ ...$focusOn.value, focusBlockId: undefined });
        clearBlockSelection();
      }
    };
  }, []);

  useContainerContextSetter(pageContext, setPageContext);

  useLayoutEffect(() => {
    if (
      (activeId && activeId.current !== prevId.current && props.document) ||
      props.connected
    ) {
      let document = props.document;
      if (document && document.id && document.id !== "") {
        props.changeDocumentMode(DocumentModes.INSERT);
        loadContainerData({
          containerId: document.id,
          containerType: ContainerTypes.DOCUMENT,
          titleBlockId: document.titleBlockId ? document.titleBlockId : "",
          titleBlock,
          outlineMode: document.outlineMode,
          isPublicAccess: document.isPublicAccess,
        }).then(() => {
          batch(() => {
            if (!props.isBaseMember && document) {
              getRelatedEntities(document.id, ContainerTypes.DOCUMENT);
            }
          });
        });

        mixpanel.track(UserEventTypes.PAGE_VIEWED, {
          distinct_id: props.user?.id,
        });
      }
    }
    prevId.current = activeId.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerId, props.connected]);

  return useMemo(() => {
    if (!props.document || !props.containerId) {
      return <></>;
    } else {
      return (
        <>
          <div className="document-wrapper container-860 wiki-detail">
            <div className="document-container">
              {props.workDict && props.paneId === ChunkDestination.primary && (
                <Helmet>
                  <title>{`${stripHtml(
                    getHtml(props.document?.nameValue)
                  )} • ${baseName}`}</title>
                </Helmet>
              )}
              <div className="document select-area" ref={documentRef}>
                <div className="titleBlockContainer">
                  <ContainerTitleBlockWrapper
                    paneId={props.paneId}
                    context={pageContext}
                    containerType={ContainerTypes.DOCUMENT}
                    containerId={props.containerId}
                    isBaseMember={props.isBaseMember}
                  />
                </div>
                <EditorContainer
                  context={pageContext}
                  isBaseMember={props.isBaseMember}
                  outlineMode={
                    props.outlineMode ? props.outlineMode : "noOutline"
                  }
                />
                {baseType === BaseType.Public ||
                (props.user?.id && userRole && userRole !== "Guest") ? (
                  <>
                    <MentionsSection
                      containerId={props.containerId}
                      containerType={ContainerTypes.DOCUMENT}
                      paneId={props.paneId}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.containerId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.document,
    props.outlineMode,
    pageContext,
    baseType,
  ]);
}

const mapStateToProps = (state: ClarityStore, ownProps: any) => {
  let docState = {
    document: state.pages.dict[ownProps.containerId],
    displayModalDocumentShare: state.client.displayModalDocumentShare,
    outlineMode:
      state.blocks.contexts[ContainerTypes.DOCUMENT + ownProps.containerId]
        ?.outlineMode,
    user: state.user,
    isBaseMember: state.client.isBaseMember,
    connected: state.network.connected,
    workDict: state.work.dict,
    base: state.workspace,
  };
  return docState;
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    changeDocumentMode: (param: any) =>
      dispatch({
        type: actionTypes.CHANGE_DOCUMENT_MODE,
        param,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
