import React, { useState, useRef, useEffect, memo, useMemo } from "react";
import styles from "./topNavBar/topNavBar.module.scss";
import { Input, InputProps, Dropdown, Menu } from "antd";
import {
  SearchOutlined,
  PoweroffOutlined,
  QuestionOutlined,
  FileDoneOutlined,
  GlobalOutlined,
  LockFilled,
  EditOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import Button, {
  ButtonShapes,
  ButtonTypes,
  IconSides,
} from "components/Button";
import SettingTwoTone from "icons/Components/SettingTwoTone";
import { useDispatch, useSelector, shallowEqual, batch } from "react-redux";
import * as actionTypes from "store/actions";
import store, { ClarityStore } from "store/storeExporter";
import {
  useSearch,
  useSearchSetter,
  useSharingAndPermsModalSetter,
  useContainerInviteModalSetter,
  useRoleType,
} from "store/reducers/clientReducer";
import {
  useMenuControllerState,
  useActiveMenuItemContext,
} from "components/FabBtn";
import {
  ChangelogMenu,
  MenuItems as HelpMenuItems,
} from "components/HelpFabBtn";
import { Menu as FabMenu, MenuItem as FabMenuItem } from "components/FabBtn";
import { useHistory } from "react-router-dom";
import { KeyCodes } from "utilities/lineUtilities";
import { useSearcher } from "components/SidebarSearch";
import {
  SectionWidthModes,
  useOptionalClassName,
  useShallowSelector,
} from "utilities/hooks";
import Fuse from "fuse.js";
import { locationSubject } from "./LocationListener";
import { sidebarApi } from "clientApi/sidebarApi";
import { SidebarModes } from "store/reducers/sidebarReducer";
import { useIsMobile } from "utilities/hooks";
import {
  Abilities,
  ContainerTypes,
  IProjectObj,
  UserRole,
  WorkTypes,
} from "utilities/types";
import {
  useShareData,
  useBtnComponents,
  useTabSets,
  isActionTrigger,
  isMenuItem,
  isMenuSubMenu,
  IMenuItem,
  ActionBtn,
  isActionMenu,
  IActionTabSet,
  TopNavbarType,
} from "store/reducers/topNavReducer";
import ReactDOM from "react-dom";
import ModalDocumentShare from "screens/base/main/detailView/documentContainer/document/ModalDocumentShare";
import ActiveUsersListener from "screens/base/main/detailView/documentContainer/document/ActiveUsersListener";
import {
  ActionBtnsMenu,
  useWidthContainer,
} from "components/SplitViewTopNavBar";
import { useUser } from "store/reducers/userReducer";
import Avatar from "components/Avatar";
import Conditional from "components/Conditional";
import {
  getGroupsFromEntityId,
  useGetContainerFromType,
} from "modules/containerHelpers";
import WorkViewTopnavBtns from "screens/base/main/defaultWorkView/WorkViewTopnavBtns";
import { changelogApi } from "clientApi/changelogApi";
import { PlusOutlined } from "@ant-design/icons";
import TopNavSubroutes from "screens/base/main/home/subroutes/TopNavSubroutes";
import { ChunkDestination } from "utilities/stateTypes";
import FileTextTwoTone from "icons/Components/FileTextTwoTone";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";
import {
  getRecentsAsViewOptions,
  getViewsOptions,
  IClarityView,
} from "utilities/containerGetters";
import RowTypeSwitcher from "./topNavBar/RowTypeSwitcher";
import { useAbilityChecker } from "editor/utils/customHooks";
import { CommandPaletteOption } from "./commandPalette/helpers/types";
import ErrorTopBar from "./ErrorTopBar";

const icons = {
  PLUS: <PlusOutlined />,
};

export interface ITopNavBarProps {
  paneId: ChunkDestination;
  designatedType?: TopNavbarType;
  sectionWidthMode?: SectionWidthModes;
  intersectionWith?: React.MutableRefObject<HTMLDivElement | null>;
}

const TopNavBar: React.FC<ITopNavBarProps> = memo(
  ({ paneId, designatedType, sectionWidthMode }) => {
    const isMobile = useIsMobile();
    const lastGroupRef = useRef();
    const totalActionBtns = useTotalActionBtns({
      paneId,
    });

    const hassubRoutesParams = useShallowSelector((store) =>
      Boolean(store.topNav[paneId]?.subRoutesParams)
    );

    const viewType = useShallowSelector(
      (store) => store.topNav[paneId]?.navType
    );

    const lastGroupClassName = useWidthContainer({
      containerRef: lastGroupRef,
      baseStyle: styles.row1LastGroup,
      groups: [[890, [styles.eightNinetyOrLess]]],
      exceptions: [totalActionBtns <= 2],
    });

    if (!designatedType && viewType === TopNavbarType.baseOverview)
      return <></>;

    return (
      <>
        <Conditional
          on={
            viewType === TopNavbarType.detail ||
            viewType === TopNavbarType.baseOverview
          }
        >
          <div
            className={`${styles.container} ${isMobile ? styles.isMobile : ""}`}
          >
            <div
              className={`${styles.content} content ${
                !hassubRoutesParams || viewType === TopNavbarType.detail
                  ? styles.showShadow
                  : ""
              }`}
            >
              <div className={`${styles.row1}`}>
                <div className={styles.row1FirstGroup}>
                  <RowTypeSwitcher
                    paneId={paneId}
                    sectionWidthMode={sectionWidthMode}
                  />
                  <ExternalTabSets paneId={paneId} />
                </div>

                <div
                  className={lastGroupClassName}
                  style={
                    viewType === TopNavbarType.baseOverview
                      ? { position: "absolute", right: "16px" }
                      : undefined
                  }
                  ref={lastGroupRef as any}
                >
                  <ActiveUsersListener paneId={paneId} />
                  <ExternalBtns paneId={paneId} />
                  <ActionBtnsMenu
                    paneId={paneId}
                    triggerClass={styles.actionBtnsTrigger}
                  />
                  <ShareBtn
                    paneId={paneId}
                    btnClass={`${styles.actionsShareBtn} hide-on-mobile`}
                  />
                </div>
              </div>
            </div>
          </div>
        </Conditional>
        <Conditional
          on={
            hassubRoutesParams &&
            viewType !== TopNavbarType.detail &&
            viewType !== TopNavbarType.baseOverview
          }
        >
          <div
            className={
              viewType !== TopNavbarType.baseOverview
                ? `${styles.containerRow2}`
                : `${styles.container}  ${isMobile ? styles.isMobile : ""}`
            }
          >
            <div className={`${styles.content} content`}>
              <Conditional on={viewType !== TopNavbarType.baseOverview}>
                <div className={`${styles.row2}`}>
                  <SubroutesListener paneId={paneId} />
                </div>
              </Conditional>
            </div>
          </div>
        </Conditional>
        <ErrorTopBar key="main" paneId={paneId} underTopnav={true} />
      </>
    );
  }
);

export default TopNavBar;

function useBaseMenuSetter() {
  const dispatch = useDispatch();

  return (visible: boolean) =>
    dispatch({ type: actionTypes.SET_SWITCH_BASE_MENU_VISIBILITY, visible });
}
interface IShareBtnProps {
  paneId: ChunkDestination;
  asMenuItems?: boolean;
  btnClass?: string;
}

export function ShareBtn(props: IShareBtnProps) {
  const { asMenuItems = false, btnClass } = props;

  const {
    displayShareModal,
    shareData,
    onShare,
    showInviteModal,
    hideShareModal,
    isPublicAccess,
    userRole,
    showShareButton,
    canEditEntity,
  } = useShareBtnState(props);

  return !showShareButton ? (
    <></>
  ) : (
    <>
      <ShareBtnWrapper
        key="shareBtn"
        {...{ onShare, isPublicAccess, asMenuItems, btnClass }}
        disabled={!canEditEntity}
      >
        Share
      </ShareBtnWrapper>
      {displayShareModal && userRole !== UserRole.GUEST ? (
        <>
          {ReactDOM.createPortal(
            <ModalDocumentShare
              hideModal={hideShareModal}
              showInviteModal={showInviteModal}
              containerType={shareData?.containerType}
            />,
            document.body
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

function useShareBtnState({ paneId, asMenuItems = false }: IShareBtnProps) {
  const [displayShareModal, setDisplayShareModal] = useState(false);
  const shareData = useShareData(paneId);

  const setSharingAndPermsModal = useSharingAndPermsModalSetter();
  const openSharingAndPermsModal = () =>
    setSharingAndPermsModal({
      isOpen: true,
      containerId: shareData?.containerId,
      containerType: shareData?.containerType,
    });
  const setContainerInviteModalState = useContainerInviteModalSetter();

  const setDisplayInviteGuests = (isOpen: boolean) =>
    setContainerInviteModalState({
      isOpen,
      containerId: isOpen ? shareData?.containerId : undefined,
      containerType: isOpen ? shareData?.containerType : undefined,
    });

  const entity = useGetContainerFromType(
    shareData?.containerId ? shareData.containerId : "",
    shareData?.containerType ? shareData.containerType : ContainerTypes.NOTE
  );

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: getGroupsFromEntityId(
      shareData?.containerId,
      shareData?.containerType
    ),
  });

  const isPublicAccess = (entity as any)?.isPublicAccess ? true : false;

  const onShare = () => {
    if (canEditEntity) openSharingAndPermsModal();
  };

  const showInviteModal = () => {
    setDisplayShareModal(false);
    setDisplayInviteGuests(true);
  };

  const hideShareModal = () => setDisplayShareModal(false);

  const showShareButton = useShowShareButton({ paneId });

  const userRole = useRoleType();

  return {
    displayShareModal,
    shareData,
    onShare,
    showInviteModal,
    hideShareModal,
    isPublicAccess,
    userRole,
    showShareButton,
    canEditEntity,
  };
}

export function useShowShareButton({ paneId }: { paneId: ChunkDestination }) {
  const shareData = useShareData(paneId);

  return (
    shareData?.containerId &&
    shareData?.containerType !== "CustomView" &&
    shareData.containerType !== ContainerTypes.TEMPLATE &&
    shareData.containerType !== ContainerTypes.SNIPPET
  );
}

export function useTotalActionBtns({ paneId }: { paneId: ChunkDestination }) {
  const showShareBtn = useShowShareButton({ paneId });
  const btnComponents = useBtnComponents(paneId);
  return (showShareBtn ? 1 : 0) + btnComponents.length;
}

function ShareBtnWrapper({
  children,
  onShare,
  isPublicAccess,
  asMenuItems,
  btnClass = "",
  disabled,
}: {
  children: React.ReactChild;
  onShare: any;
  isPublicAccess: boolean;
  asMenuItems: boolean;
  btnClass?: string;
  disabled: boolean;
}) {
  const btnClassName = useOptionalClassName({
    baseStyle: styles.shareBtn,
    pairs: [
      {
        extraStyle: btnClass,
        withExtra: Boolean(btnClass),
      },
    ],
  });

  return asMenuItems ? (
    <div className={styles.menuItem}>
      <span className={styles.menuItemTitle} onClick={onShare}>
        <span className={styles.menuItemIcon}>
          <ShareAltOutlined />
        </span>
        <span className={styles.menuItemTitle}> {children}</span>
      </span>
    </div>
  ) : (
    <Button
      className={btnClassName}
      buttonType={ButtonTypes.PRIMARY}
      onClick={onShare}
      disabled={disabled}
      icon={<AccessIcon isPublicAccess={isPublicAccess} />}
    >
      {children}
    </Button>
  );
}

interface IExternalBtnsProps {
  paneId: ChunkDestination;
  className?: string;
}

export function ExternalBtns({ paneId, className = "" }: IExternalBtnsProps) {
  const btnComponents = useBtnComponents(paneId);
  const containerClassName = useOptionalClassName({
    baseStyle: styles.externalBtns,
    pairs: [
      {
        extraStyle: className,
        withExtra: Boolean(className),
      },
    ],
  });

  return (
    <Conditional on={btnComponents.length}>
      <div className={containerClassName}>
        {btnComponents.map((btn) => (
          <ExternalActionButton {...btn} key={btn.key} />
        ))}
      </div>
    </Conditional>
  );
}

function ExternalActionButton(btn: ActionBtn) {
  if (isActionTrigger(btn) && (!btn.condition || btn.condition())) {
    return (
      <>
        {btn.standAloneCom ? (
          <btn.contents />
        ) : (
          <Button
            buttonType={btn.buttonType ?? ButtonTypes.LINK}
            icon={
              btn.icon ? (
                <btn.icon />
              ) : btn.iconRef ? (
                icons[btn.iconRef]
              ) : undefined
            }
            iconSide={IconSides.RIGHT}
            disabled={btn.disabled}
            onClick={btn.onClick}
            labelClass={styles.externalBtnsButtonLabel}
          >
            <btn.contents />
          </Button>
        )}
      </>
    );
  } else if (isActionMenu(btn) && (!btn.condition || btn.condition())) {
    return (
      <Dropdown
        overlay={
          <Menu>
            {btn.menuItems.map((menuItem, index) => {
              if (isMenuItem(menuItem)) {
                return getMenuItem(menuItem);
              } else if (isMenuSubMenu(menuItem)) {
                return (
                  <Menu.SubMenu title={menuItem.title} key={menuItem.key}>
                    {menuItem.children.map((menuItem) => getMenuItem(menuItem))}
                  </Menu.SubMenu>
                );
              } else return <React.Fragment key={index}></React.Fragment>;
            })}
          </Menu>
        }
        trigger={["click"]}
        placement="bottomRight"
      >
        <Button
          buttonType={ButtonTypes.LINK}
          labelClass={styles.externalBtnsButtonLabel}
        >
          <btn.title />
        </Button>
      </Dropdown>
    );
  } else {
    return null;
  }
}

export function ExternalTabSets({
  paneId,
  className = "",
}: IExternalBtnsProps) {
  const tabSets = useTabSets(paneId);

  const containerClassName = useOptionalClassName({
    baseStyle: styles.externalTabSets,
    pairs: [
      {
        extraStyle: className,
        withExtra: Boolean(className),
      },
    ],
  });

  return (
    <Conditional on={tabSets.length}>
      <div className={containerClassName}>
        {tabSets.map((tabSet) => (
          <ActionTabSet {...tabSet} key={tabSet.key} />
        ))}
      </div>
    </Conditional>
  );
}

export function ActionTabSet(tabSet: IActionTabSet, workItem: IProjectObj) {
  const { tabs } = tabSet;
  const displayTabs = tabs.filter((tab) =>
    tab.condition ? tab.condition() : true
  );
  return (
    <div className={styles.tabSet}>
      {displayTabs.map((tab, index) => (
        <React.Fragment key={tab.key}>
          <div className={styles.tabContainer}>
            <div className={styles.tab}>
              <Button
                buttonType={ButtonTypes.LINK}
                iconSide={tab?.icon ? IconSides.LEFT : undefined}
                className={tab.active() ? styles.active : styles.inactive}
                icon={tab?.icon && <tab.icon />}
                onClick={tab.onClick}
              >
                <tab.contents />
              </Button>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

function AccessIcon({ isPublicAccess }: { isPublicAccess: boolean }) {
  return isPublicAccess ? <GlobalOutlined /> : <LockFilled />;
}

interface ISearchBarProps {
  isQuickSearch?: boolean;
  isFullSearch?: boolean;
  className?: string;
  containerClassName?: string;
  bootstrapValue?: boolean;
  startActive?: boolean;
  onDeactivate?: () => void;
  onEnter?: React.KeyboardEventHandler;
  prefix?: InputProps["prefix"];
  suffix?: InputProps["suffix"];
  withTransition?: boolean;
  onEmptyEnter?: React.KeyboardEventHandler;
  placeholder?: string;
  inputClass?: string;
  inputPlaceholderClass?: string;
}

export function SearchBar(props: ISearchBarProps) {
  const {
    isQuickSearch,
    isFullSearch,
    onEnter,
    prefix,
    onEmptyEnter,
    placeholder,
    inputClass,
  } = props;

  const userRole = store.getState().client.roleType;

  const {
    inputRef,
    value,
    classNames,
    containerClassNames,
    menuProps,
    iconProps,
    setValue,
    quickOpenOptions,
    deactivate,
    isActive,
    searchEverything,
    activeItemIndex,
    setFocusInput,
  } = useSearchBarState(props);

  const { onChange, onKeyDown, search } = useSearchBarActions({
    setValue,
    value,
    deactivate,
    onEmptyEnter,
    onEnter,
    isQuickSearch,
    isFullSearch,
  });

  return (
    <div className={containerClassNames}>
      <div className={classNames}>
        <Input
          prefix={prefix ? <div onClick={search}>{prefix}</div> : undefined}
          ref={inputRef}
          value={value}
          onChange={onChange}
          className={`${inputClass ?? styles.searchBarInput} search-input`}
          placeholder={placeholder ?? "Search"}
          style={{ opacity: "1" }}
          bordered={false}
          suffix={undefined}
          onKeyDown={onKeyDown}
          onBlur={() => {
            setFocusInput(false);
          }}
          {...iconProps}
          {...menuProps}
        />
      </div>
      {isQuickSearch && isActive ? (
        <>
          <FabMenu
            slim
            noFocus
            className={styles.searchBarMenu}
            close={deactivate}
            skipFirst={true}
            yDir="down"
            xDir="left"
          >
            <>
              {userRole !== UserRole.GUEST && (
                <FabMenuItem
                  title={`Search everything${!value ? "" : ": "}${value}`}
                  icon={<SearchOutlined />}
                  key={"allSearch"}
                  onClick={(e: React.MouseEvent) => {
                    searchEverything();
                  }}
                  isActive={activeItemIndex === 0}
                />
              )}
              {quickOpenOptions.map((option: IClarityView, index) => {
                return (
                  <FabMenuItem
                    key={option.id}
                    title={option.name}
                    icon={option.icon}
                    onClick={(e: any) => {
                      option.action(e);
                      deactivate();
                    }}
                    isActive={index + 1 === activeItemIndex}
                  />
                );
              })}
            </>
          </FabMenu>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export function QuickOpenOptionIcon({
  option,
}: {
  option: CommandPaletteOption;
}) {
  if (option.optionType === "document") {
    return <FileTextTwoTone />;
  } else if (option.optionType === "project") {
    if (option.entity.workType === WorkTypes.TASK) {
      return <CheckCircleTwoTone />;
    } else {
      return <FileDoneOutlined />;
    }
  } else {
    return option?.icon ?? <div />;
  }
}

function useSearchBarState({
  className = "",
  containerClassName = "",
  bootstrapValue,
  isQuickSearch,
  isFullSearch,
  startActive = false,
  onDeactivate,
  withTransition = false,
}: ISearchBarProps) {
  const inputRef = useRef<Input>(null);
  const { quickSearchActive, fullSearchActive } = useSearch();
  const [value, setValue] = useState("");
  const [isActive, setIsActive] = useState(startActive);
  const [focusInput, setFocusInput] = useState(isActive);
  const activate = () => setIsActive(true);

  const { activeItemIndex, itemsCollection: quickOpenOptions } =
    useActiveMenuItemContext();

  const deactivate = () => {
    setIsActive(false);
    if (onDeactivate) {
      onDeactivate();
    }
  };

  useSearchBarEffects({
    bootstrapValue,
    deactivate,
    setValue,
    isQuickSearch,
    isFullSearch,
    isActive,
    setIsActive,
    inputRef,
    startActive,
    value,
  });

  const search = useSearcher();

  const searchEverything = () => {
    batch(() => {
      search(value);
      setValue("");
      sidebarApi.setSidebarMode({
        newMode: SidebarModes.search,
        isOpen: true,
        isTemporary: false,
      });
      return;
    });
  };

  const classNames = useOptionalClassName({
    baseStyle: styles.searchBar,
    pairs: [
      {
        extraStyle: className,
        withExtra: Boolean(className),
      },
      {
        extraStyle: `${styles.searchBarActive} active`,
        withExtra: quickSearchActive && isQuickSearch,
      },
      {
        extraStyle: styles.searchBarFullActive,
        withExtra: fullSearchActive && !isQuickSearch && focusInput,
      },
      {
        extraStyle: styles.searchBarWithTransition,
        withExtra: withTransition,
      },
    ],
  });

  const containerClassNames = useOptionalClassName({
    baseStyle: styles.searchBarContainer,
    pairs: [
      {
        extraStyle: containerClassName,
        withExtra: Boolean(containerClassName),
      },
    ],
  });

  const onFocus = () => {
    setFocusInput(true);
    activate();
  };

  const menuProps = { onFocus };
  const iconProps = !isQuickSearch
    ? {}
    : {
        suffix: !isActive ? (
          <SearchOutlined className={styles.searchBarIcon} />
        ) : (
          <></>
        ),
      };

  return {
    inputRef,
    value,
    setValue,
    classNames,
    containerClassNames,
    menuProps,
    iconProps,
    quickOpenOptions,
    deactivate,
    isActive,
    searchEverything,
    activeItemIndex,
    setFocusInput,
    focusInput,
  };
}

const exeFuseSearch = (
  options: IClarityView[]
): ((value: string) => IClarityView[]) => {
  const fuse = new Fuse(options, {
    minMatchCharLength: 2,
    threshold: 0.4,
    keys: ["name"],
  });

  return (value: string) => fuse.search(value).map((result) => result.item);
};

function useSearchBarEffects({
  bootstrapValue,
  deactivate,
  setValue,
  isQuickSearch,
  isFullSearch,
  isActive,
  inputRef,
  setIsActive,
  startActive,
  value,
}: Pick<
  ISearchBarProps,
  "bootstrapValue" | "isQuickSearch" | "isFullSearch" | "startActive"
> &
  Pick<
    ReturnType<typeof useSearchBarState>,
    "deactivate" | "setValue" | "isActive" | "inputRef" | "value"
  > & {
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  }) {
  const { queryString, quickSearchActive, fullSearchActive } = useSearch();
  const setSearch = useSearchSetter();
  const setQuickSearchActive = (quickSearchActive: boolean) =>
    setSearch({ quickSearchActive });
  const setFullSearchActive = (fullSearchActive: boolean) =>
    setSearch({ fullSearchActive });
  const {
    setActiveItemIndex,
    activeItemIndex,
    setItemsCollection: setQuickOpenOptions,
    itemsCollection,
  } = useActiveMenuItemContext();

  const options = useMemo(() => {
    if (!quickSearchActive) return [];
    const res = getViewsOptions();
    return res();
  }, [quickSearchActive]);

  const updateQuickOptions = () => {
    if (value.length < 2) {
      const res = getRecentsAsViewOptions();
      setQuickOpenOptions([...res]);
    } else {
      const newOne = exeFuseSearch(options)(value).slice(0, 100);
      setQuickOpenOptions(newOne);
    }
  };

  useEffect(
    () => setValue(bootstrapValue ? queryString : ""),
    [bootstrapValue]
  );

  useEffect(() => {
    if (startActive) {
      inputRef.current?.focus();
    }
    if (!isQuickSearch && value !== queryString) {
      setValue(queryString);
    }
  }, [queryString]);

  useEffect(() => {
    const max = itemsCollection.length - (isQuickSearch ? 0 : 1);
    if (activeItemIndex > max) {
      setActiveItemIndex(max);
    }
  }, [itemsCollection.length]);

  useEffect(() => {
    if (isActive && isQuickSearch) {
      updateQuickOptions();
    }
  }, [value]);

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus();
    }
    if (isQuickSearch && quickSearchActive !== isActive) {
      setQuickSearchActive(isActive);
    }
    if (!isActive) {
      if (isFullSearch && fullSearchActive !== isActive) {
        setFullSearchActive(isActive);
      }
      if (!isFullSearch) {
        inputRef.current?.blur();
      }
    }
    if (isActive && isQuickSearch) {
      updateQuickOptions();
    } else if (!isActive) {
      setActiveItemIndex(0);
    }
  }, [isActive]);

  useEffect(() => {
    if (isQuickSearch) {
      return setIsActive(quickSearchActive);
    }
    if (isFullSearch) {
      return setIsActive(fullSearchActive);
    }
  }, [quickSearchActive, fullSearchActive]);
}

function useSearchBarActions({
  setValue,
  value,
  deactivate,
  onEmptyEnter,
  onEnter,
  isQuickSearch,
  isFullSearch,
}: Pick<
  ReturnType<typeof useSearchBarState>,
  "setValue" | "value" | "deactivate"
> &
  Pick<
    ISearchBarProps,
    "onEmptyEnter" | "onEnter" | "isQuickSearch" | "isFullSearch"
  >) {
  const searcher = useSearcher();
  const search = () => searcher(value);
  const onKeyDown = useSearchOnKeyDown({
    onEnter,
    setValue,
    onEmptyEnter,
    deactivate,
    value,
    isQuickSearch,
    isFullSearch,
  });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setValue(e.target.value);

  return {
    onChange,
    onKeyDown,
    search,
  };
}

function useSearchOnKeyDown({
  onEnter,
  setValue,
  onEmptyEnter,
  deactivate,
  value,
  isQuickSearch,
  isFullSearch,
}: Pick<
  Parameters<typeof useSearchBarActions>[0],
  | "onEnter"
  | "setValue"
  | "onEmptyEnter"
  | "deactivate"
  | "value"
  | "isQuickSearch"
  | "isFullSearch"
>) {
  const search = useSearcher();
  const { activeItemIndex, setActiveItemIndex, itemsCollection } =
    useActiveMenuItemContext();
  const { queryString } = useSearch();
  const decreaseActiveMenuItem = () => {
    if (activeItemIndex > 0) {
      setActiveItemIndex(activeItemIndex - 1);
    }
  };

  const increaseActiveMenuItem = () => {
    if (activeItemIndex < itemsCollection.length - (isQuickSearch ? 0 : 1)) {
      setActiveItemIndex(activeItemIndex + 1);
    }
  };

  const handleAllSearch = () => {
    batch(() => {
      sidebarApi.setSidebarMode({
        newMode: SidebarModes.search,
        isOpen: true,
      });
      search(value);
    });
  };

  const onKeyDown: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
    if (e.keyCode === KeyCodes.enter) {
      if (onEnter) {
        if (!value && onEmptyEnter) {
          onEmptyEnter(e as any);
        } else if (value === queryString) {
          onEnter(e);
        } else if (value) {
          handleAllSearch();
        }
      } else if (activeItemIndex === 0) {
        if (value) {
          handleAllSearch();
          setValue("");
        } else if (!value && onEmptyEnter) {
          deactivate();
        } else {
          handleAllSearch();
        }
      } else {
        if (!isFullSearch) {
          setValue("");
          deactivate();
        }
        itemsCollection[activeItemIndex - 1].action(e);
      }
    } else if (e.keyCode === KeyCodes.arrow_down) {
      e.preventDefault();
      increaseActiveMenuItem();
    } else if (e.keyCode === KeyCodes.arrow_up) {
      e.preventDefault();
      decreaseActiveMenuItem();
    } else if (
      e.keyCode === KeyCodes.esc ||
      (e.keyCode === KeyCodes.p && (e.metaKey || e.ctrlKey))
    ) {
      setValue("");
      deactivate();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return onKeyDown;
}

export const HelpBtn: React.FC = ({ children }) => {
  const { menuIsOpen, setMenuIsOpen } = useHelpBtnState();
  const { onClick, closeMenu } = useHelpBtnActions({
    setMenuIsOpen,
  });

  const [showChangelog, setShowChangelog] = useState(false);
  const readAll = useSelector(
    (state: ClarityStore) => state.changelogs.readAll,
    shallowEqual
  );
  const changelogLength = useSelector(
    (state: ClarityStore) => state.changelogs.ids.length,
    shallowEqual
  );

  useEffect(() => {
    changelogApi.getUserChangelogs();
  }, []);

  return (
    <div className={styles.helpBtnContainer}>
      {children ?? <></>}

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!readAll ? <div className={styles.unreadBadge}></div> : null}

        <Button
          className={styles.helpBtn}
          onClick={onClick}
          labelClass={styles.label}
          buttonType={ButtonTypes.LINK}
          shape={ButtonShapes.CIRCLE}
          additionalIconClass={styles.helpBtnIcon}
          icon={<QuestionOutlined />}
        />
      </div>
      {menuIsOpen ? (
        <FabMenu
          close={() => {
            closeMenu();
            setShowChangelog(false);
          }}
          yDir="down"
          xDir="left"
          className={styles.helpBtnMenu}
        >
          {!showChangelog ? (
            <HelpMenuItems
              close={() => {
                closeMenu();
                setShowChangelog(false);
              }}
              readAllChangelog={readAll}
              showChangelog={() => {
                if (changelogLength !== 0) setShowChangelog(!showChangelog);
              }}
              disableChangelogs={changelogLength === 0}
            />
          ) : (
            <ChangelogMenu />
          )}
        </FabMenu>
      ) : (
        <></>
      )}
    </div>
  );
};

function useHelpBtnState() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return {
    menuIsOpen,
    setMenuIsOpen,
  };
}

function useHelpBtnActions({
  setMenuIsOpen,
}: Pick<ReturnType<typeof useHelpBtnState>, "setMenuIsOpen">) {
  const openMenu = () => setMenuIsOpen(true);
  const closeMenu = () => setMenuIsOpen(false);

  return {
    onClick: openMenu,
    closeMenu,
  };
}

export const SettingsBtn: React.FC<{
  noNavigation?: boolean;
}> = ({ noNavigation }) => {
  const baseData = useSelector(
    (state: ClarityStore) => state.workspace,
    shallowEqual
  );

  return (
    <div className={styles.settingsBtnContainer}>
      <Button
        className={styles.settingsBtn}
        onClick={(e: any) => {
          if (!noNavigation) locationSubject.next(`/${baseData.slug}/settings`);
        }}
        buttonType={ButtonTypes.LINK}
        icon={<SettingTwoTone className={styles.settingsBtnIcon} />}
      />
    </div>
  );
};

interface IAvatarMenuProps {}

export const FooterAvatar: React.FC<{}> = () => {
  const { user } = useAvatarMenuState();
  return <Avatar src={user?.avatar ?? ""} size={32} />;
};

export const AvatarMenu: React.FC<IAvatarMenuProps> = (props) => {
  const { menuIsOpen, openMenu, closeMenu } = useAvatarMenuState();
  const { onLogoutClick } = useAvatarMenuActions();
  const userRole = store.getState().client.roleType;

  const showDrawer = () => {
    store.dispatch({
      type: actionTypes.OPEN_BASE_SETTING_SET_INTIAL,
      param: {
        openSettings: {
          isOpen: true,
        },
      },
    });
  };

  return (
    <div className={styles.avatarMenuContainer}>
      <div onClick={() => (!menuIsOpen ? openMenu() : null)}>
        {props.children}
      </div>

      {!menuIsOpen ? (
        <></>
      ) : (
        <FabMenu
          slim
          close={closeMenu}
          yDir="up"
          xDir="right"
          className={styles.avatarMenuMenu}
        >
          <FabMenuItem
            title="Log out"
            icon={<PoweroffOutlined />}
            onClick={onLogoutClick}
          />
          <FabMenuItem
            title="My Settings"
            icon={<EditOutlined />}
            onClick={() => {
              locationSubject.next(`/profile/settings`);
              closeMenu();
            }}
          />
          <>
            {userRole !== UserRole.GUEST && (
              <FabMenuItem
                title="Base Settings"
                onClick={() => {
                  showDrawer();
                  closeMenu();
                }}
                icon={<SettingOutlined />}
              />
            )}
          </>
        </FabMenu>
      )}
    </div>
  );
};

function useAvatarMenuState() {
  const user = useUser();
  const menuControllerState = useMenuControllerState();

  return {
    user,
    ...menuControllerState,
  };
}

function useAvatarMenuActions() {
  const history = useHistory();
  const setSwitchBaseMenuVisibility = useBaseMenuSetter();

  const navToAndCloseBaseSwitchMenu = (path: string) => {
    setSwitchBaseMenuVisibility(false);
    history.push(path);
  };

  const onLogoutClick = () => navToAndCloseBaseSwitchMenu("/logout");

  return {
    onLogoutClick,
  };
}

export const IsWorkViewListener: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const isWorkView = useShallowSelector(
    (store) => store.topNav[paneId]?.isWorkView
  );

  if (isWorkView) return <WorkViewTopnavBtns paneId={paneId} />;
  return <></>;
};

export const SubroutesListener: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const subRoutesParams = useShallowSelector(
    (store) => store.topNav[paneId]?.subRoutesParams
  );

  if (subRoutesParams) return <TopNavSubroutes paneId={paneId} />;
  return <></>;
};

export function getMenuItem({ onClick, key, contents: Contents }: IMenuItem) {
  return (
    <Menu.Item onClick={onClick} key={key}>
      <Contents />
    </Menu.Item>
  );
}
