import { getDefaultStatusId } from "components/ModalNewTask";
import { checkIfCannotCreateWork } from "modules/appService";
import { UPDATE_TEMPLATE } from "store/actions";
import { INewTemplateContext } from "store/reducers/clientReducer";
import store, { ClarityStore } from "store/storeExporter";
import { WorkTypes, ITemplateObj } from "utilities/types";

const { v4: uuidv4 } = require("uuid");

export const handleNewTemplateItemCreationCases = (action: any, next: any) => {
  const cannotCreateMoreWork = checkIfCannotCreateWork();
  if (cannotCreateMoreWork) return;

  const storeData: ClarityStore = store.getState();
  const newTemplateContext = action.newTemplateContext as INewTemplateContext;

  const presetData = newTemplateContext?.presetData;

  const presetName = presetData?.name ?? "";

  const parentId = presetData?.parentId;
  let statusId = getDefaultStatusId();
  if (presetData?.statusId) {
    statusId = presetData.statusId;
  }

  if (!statusId) return;

  let assigneeId = null;
  if (presetData?.assigneeId) {
    assigneeId = presetData.assigneeId;
  }

  let dueDate = presetData?.dueDate ? presetData.dueDate : undefined;

  let workType = presetData?.workType ? presetData.workType : WorkTypes.TASK;

  let presetTaskId = presetData?.id ? presetData?.id : uuidv4();

  const presetGroupId = presetData?.groupId ?? "";

  const presetPriority = presetData?.priority ?? 1;

  const creationDate = new Date();

  const newTemplate: ITemplateObj = {
    id: presetTaskId,
    name: presetName,
    authorId: storeData.user?.id ? storeData.user.id : "",
    nameValue: [],
    assigneeId,
    priority: presetPriority,
    dateCreated: creationDate,
    dateModified: creationDate,
    workType,
    isClosed: false,
    statusId,
    parentId,
    isDeleted: false,
    dueDate,
    childrenAggregate: [],
    contributorIds: [],
    groupId: presetGroupId,
    reviewerId: presetData?.reviewerId,
    isDone: false,
    workspaceId: storeData.workspace.id,
    reward: presetData?.reward,
    isRoot: parentId ? false : true,
  };

  if (newTemplate.statusId) {
    const status = storeData.work.statuses.dict[newTemplate.statusId as string];
    if (status && (status.categoryId === "4" || status.categoryId === "5")) {
      newTemplate.isClosed = true;
    }
    if (status && status.categoryId === "4") {
      newTemplate.isDone = true;
    }
  }

  const newAction = {
    type: UPDATE_TEMPLATE,
    param: {
      delta: newTemplate,
      type: "add",
      skipInsertInList: true,
      id: newTemplate.id,
    },
  };
  next(newAction);
  if (action.newTemplateContext) action.newTemplateContext.id = newTemplate.id;
  else
    action.newTemplateContext = {
      id: newTemplate.id,
    };

  if (action.newTemplateContext?.presetData)
    action.newTemplateContext.presetData.id = newTemplate.id;
  else
    action.newTemplateContext.presetData = {
      id: newTemplate.id,
    };
};
