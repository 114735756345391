import React from "react";
import { Tooltip } from "antd";
import store from "store/storeExporter";
import ThunderboltTwoTone from "icons/Components/ThunderboltTwoTone";

const CyclePresence: React.FC<{ id: string; showName?: boolean }> = ({
  id,
  showName,
}) => {
  return (
    <>
      <span className="caption disabled" style={{ marginLeft: "8px" }}>
        <Tooltip title={store.getState().work.sections[id].name}>
          <ThunderboltTwoTone />
        </Tooltip>
      </span>
      {showName && (
        <span style={{ marginLeft: "12px" }}>
          {store.getState().work.sections[id].name}
        </span>
      )}
    </>
  );
};

export default CyclePresence;
