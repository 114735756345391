import React, { useContext, useLayoutEffect, useState } from "react";
import { CommandPaletteContext } from "utilities/types";
import OptionLister from "../components/OptionLister";
import {
  CommandPaletteOption,
  CommandPaletteState,
  contextMatch,
} from "../helpers/types";

const FiltersMode: React.FC = () => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const paletteStateContext = useContext(CommandPaletteState);

  useLayoutEffect(() => {
    const contextConfig = contextMatch[CommandPaletteContext.FILTERS_GENERAL];
    setOptions(contextConfig.getOptions());
    paletteStateContext.topBarText.next(contextConfig.topBarText);
  }, []);

  return <OptionLister options={options} />;
};

export default FiltersMode;
