import React from "react";
import GroupSection from "./groups/GroupsSection";
import TabLayout from "./TabLayout";

const Groups: React.FC = () => {
  return (
    <div
      style={{
        paddingBottom: "5vh",
      }}
    >
      <div className="base-settings-container">
        <TabLayout title="Groups" description="Manage groups for this base">
          <div className="base-settings--inner-container">
            <GroupSection />
          </div>
        </TabLayout>
      </div>
    </div>
  );
};

export default Groups;
