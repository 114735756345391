import React, { useEffect, useState } from "react";
import { ContainerTypes, INotesObj, ViewNames } from "utilities/types";

import moment from "moment";
import { Select } from "antd";
import { useHistory } from "react-router-dom";
import { batch, shallowEqual, useSelector } from "react-redux";
import Button, { ButtonTypes } from "components/Button";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import store, { ClarityStore } from "store/storeExporter";
import { axiosInstance } from "index";
import { LOAD_NEW_WEEKLY_WITHOUT_BLOCKS } from "store/actions";
import navigationApi from "clientApi/navigationApi";

interface Props {
  note: INotesObj;
  weeklyNotes: (INotesObj | undefined)[];
  style?: any;
  splitView?: boolean;
  baseId: string;
}

const WeeklyNoteHeaderInfo: React.FC<Props> = ({
  note,
  style,
  weeklyNotes,
  splitView,
  baseId,
}) => {
  const [prevId, setprevId] = useState<string | null>(null);
  const [nextId, setnextId] = useState<string | null>(null);
  const history = useHistory();
  const navigationState = useSelector((state: ClarityStore) => {
    return {
      notesArray: state.notes.weeklyNoteIds,
      base: state.workspace,
    };
  }, shallowEqual);
  const styleData = {
    ...{
      display: "flex",
      alignContent: "center",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    ...(style ? style : {}),
  };

  useEffect(() => {
    if (!note) return;
    const index = navigationState.notesArray.indexOf(note.id);
    if (navigationState.notesArray[index - 1])
      setprevId(navigationState.notesArray[index - 1]);
    else setprevId(null);
    if (navigationState.notesArray[index + 1])
      setnextId(navigationState.notesArray[index + 1]);
    else setnextId(null);
  }, [navigationState]);

  if (!note) return <></>;
  return (
    <div style={styleData}>
      <Button
        buttonType={ButtonTypes.LINK}
        icon={<ArrowLeftOutlined />}
        onClick={(e: any) => {
          if (splitView) {
            if (nextId)
              navigationApi.openSplitView({
                viewName: ViewNames.Detail,
                containerId: nextId,
                containerType: ContainerTypes.NOTE,
              });
            e.preventDefault();
            return;
          } else {
            if (e.shiftKey) {
              if (nextId)
                navigationApi.openSplitView({
                  viewName: ViewNames.Detail,
                  containerId: nextId,
                  containerType: ContainerTypes.NOTE,
                });

              e.preventDefault();
              return;
            }
            history.push(`/${navigationState.base.slug}/docs/${nextId}`);
          }
        }}
        disabled={!nextId}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Select
          defaultValue={note.id}
          bordered={false}
          showArrow={false}
          onSelect={(value) => {
            if (splitView) {
              navigationApi.openSplitView({
                viewName: ViewNames.Detail,
                containerId: value,
                containerType: ContainerTypes.NOTE,
              });
              return;
            } else {
              history.push(`/${navigationState.base.slug}/docs/${value}`);
            }
          }}
        >
          {weeklyNotes.map((weeklyNote, index) => {
            if (!weeklyNote)
              return <React.Fragment key={index}></React.Fragment>;
            const noteDateCreated = new Date(weeklyNote.dateCreated);
            const weeklyNoteHeaderTime =
              moment(noteDateCreated).format("MMMM D, YYYY");
            return (
              <Select.Option
                value={weeklyNote.id}
                key={weeklyNote.id}
                className="caption medium"
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    alignItems: "center",
                    display: "flex",
                    fontWeight: 600,
                  }}
                  className="caption secondary"
                  onClick={(e) => {
                    if (e.shiftKey) {
                      navigationApi.openSplitView({
                        viewName: ViewNames.Detail,
                        containerId: weeklyNote.id,
                        containerType: ContainerTypes.NOTE,
                      });
                      e.stopPropagation();
                      e.preventDefault();
                      return;
                    }
                  }}
                >
                  Week of {weeklyNoteHeaderTime}
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
      {!prevId ? (
        <Button
          buttonType={ButtonTypes.LINK}
          icon={<ArrowRightOutlined />}
          style={{ marginLeft: "8px" }}
          onClick={(e: any) => {
            axiosInstance
              .post("/api/note/nextWeeklyNote", {
                baseId: baseId,
                mondayDate: note.dateCreated,
              })
              .then((res) => {
                batch(() => {
                  store.dispatch({
                    type: LOAD_NEW_WEEKLY_WITHOUT_BLOCKS,
                    note: res.data,
                  });
                  if (splitView) {
                    navigationApi.openSplitView({
                      viewName: ViewNames.Detail,
                      containerId: res.data.id,
                      containerType: ContainerTypes.NOTE,
                    });

                    e.preventDefault();
                    return;
                  } else {
                    if (e.shiftKey) {
                      navigationApi.openSplitView({
                        viewName: ViewNames.Detail,
                        containerId: res.data.id,
                        containerType: ContainerTypes.NOTE,
                      });
                      e.preventDefault();
                      return;
                    }
                    history.push(
                      `/${navigationState.base.slug}/docs/${res.data.id}`
                    );
                  }
                });
              })
              .catch((e: any) => {
                console.log("Error creating weekly note", e);
              });
          }}
        />
      ) : (
        <Button
          buttonType={ButtonTypes.LINK}
          icon={<ArrowRightOutlined />}
          style={{ marginLeft: "8px" }}
          onClick={(e: any) => {
            if (splitView) {
              if (prevId)
                navigationApi.openSplitView({
                  viewName: ViewNames.Detail,
                  containerId: prevId,
                  containerType: ContainerTypes.NOTE,
                });

              e.preventDefault();
              return;
            } else {
              if (e.shiftKey) {
                if (prevId)
                  navigationApi.openSplitView({
                    viewName: ViewNames.Detail,
                    containerId: prevId,
                    containerType: ContainerTypes.NOTE,
                  });

                e.preventDefault();
                return;
              }
              history.push(`/${navigationState.base.slug}/docs/${prevId}`);
            }
          }}
        />
      )}
    </div>
  );
};

export default WeeklyNoteHeaderInfo;
