import { ArrowRightOutlined } from "@ant-design/icons";
import React, { useMemo } from "react";
import { ITokenRequirement, TokenTypes } from "utilities/types";
import styles from "./tokenDetailRow/tokenDetailRow.module.scss";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useOptionalClassName } from "utilities/hooks";
import {
  getNetworkDefaultTokenSymbol,
  getNetworkNameFromId,
  getNetworkScanner,
} from "utilities/web3/connectors";

export const getTokenDescription = (token: ITokenRequirement) => {
  const networkAppend = " • " + getNetworkNameFromId(token.networkId);
  if (token.type === TokenTypes.NATIVE_CURRENCY)
    return getNetworkDefaultTokenSymbol(token.networkId) + networkAppend;
  if (token.symbol) return token.symbol + networkAppend;
  if (token.name) return token.name + networkAppend;
  return token.contractAddress + networkAppend;
};

export const getTokenAddress = (token: ITokenRequirement) => {
  if (token.type === TokenTypes.NATIVE_CURRENCY)
    return getNetworkScanner(token.networkId);
  return `${getNetworkScanner(token.networkId)}/token/` + token.contractAddress;
};

const TokenDetailRow: React.FC<{
  token: ITokenRequirement;
  showStatus?: boolean;
  success: boolean;
  isFirst: boolean;
  isLast: boolean;
}> = ({ token, showStatus, success, isFirst, isLast }) => {
  const tokenName = useMemo(() => {
    return getTokenDescription(token);
  }, [token.contractAddress]);

  const customStyle = useMemo(() => {
    if (!showStatus) return {};
    const styles: React.CSSProperties = {
      border: "1px solid #D8D4D5",
      borderBottom: "none",
    };
    if (isFirst) {
      styles.borderTopLeftRadius = "6px";
      styles.borderTopRightRadius = "6px";
    }
    if (isLast) {
      styles.borderBottomLeftRadius = "6px";
      styles.borderBottomRightRadius = "6px";
      styles.borderBottom = "1px solid #D8D4D5";
    }
    return styles;
  }, [isFirst, isLast, showStatus]);

  const classes = useOptionalClassName({
    baseStyle: styles.tokenDetailRow,
    pairs: [{ extraStyle: styles.showStatus, withExtra: showStatus }],
  });

  return (
    <div className={classes} style={customStyle}>
      <div className={styles.description}>
        {showStatus && (
          <span style={{ marginRight: "12px" }}>
            {success ? (
              <CheckCircleOutlined />
            ) : (
              <CloseCircleOutlined style={{ color: "#B61818" }} />
            )}
          </span>
        )}
        <span style={{ marginRight: "4px" }}>{token.ammount}</span> ×{" "}
        <span style={{ marginLeft: "4px" }}>{tokenName}</span>
      </div>
      <div>
        <a target="_blank" href={getTokenAddress(token)} rel="noreferrer">
          View on explorer
          <ArrowRightOutlined
            style={{
              transform: "rotate(-45deg)",
              marginLeft: "5px",
              fontSize: 9,
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default TokenDetailRow;
