import { LineType } from "utilities/lineUtilities";
import { ContainerTypes } from "utilities/types";
import { BlockReducerState } from "../blockReducer";
import {
  getBlockById,
  getCurrentContext,
  removeBlockFromContainerArray,
  updateCurrentContext,
} from "./generalBlockHelpers";

export const deleteBlock = (newState: BlockReducerState, param: any) => {
  const id = param.id;
  const currentBlock = getBlockById(newState.dict, id);
  let currentContext = getCurrentContext(
    newState,
    currentBlock.containerType + currentBlock.containerId
  );

  if (currentContext.id)
    removeBlockFromContainerArray(newState, currentBlock, currentContext);

  if (currentBlock.containerType === ContainerTypes.PROJECT) {
    if ([LineType.Title, LineType.work].includes(currentBlock.lineType)) {
      if (currentBlock.containerId !== currentBlock.referencingContainerId) {
        if (
          currentContext.state &&
          currentContext.state.workItems.includes(currentBlock.id)
        ) {
          currentContext.state = { ...currentContext.state };
          currentContext.state.workItems = [...currentContext.state.workItems];
          const index = currentContext.state.workItems.indexOf(currentBlock.id);
          currentContext.state.workItems.splice(index, 1);
          updateCurrentContext(newState, currentContext);
        }
      }
    }
  }
  return newState;
};
