import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useContext, useLayoutEffect, useState } from "react";
import { Abilities, CommandPaletteContext } from "utilities/types";
import OptionLister from "../components/OptionLister";
import {
  CommandPaletteOption,
  CommandPaletteState,
  contextMatch,
} from "../helpers/types";

const MoveTo: React.FC = () => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const paletteStateContext = useContext(CommandPaletteState);
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  useLayoutEffect(() => {
    const contextConfig = contextMatch[CommandPaletteContext.MOVE_TO];
    setOptions(contextConfig.getOptions(canEditEntity));
    paletteStateContext.topBarText.next(contextConfig.topBarText);
  }, [canEditEntity]);

  return <OptionLister options={options} />;
};

export default MoveTo;
