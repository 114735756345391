import { Dropdown, Menu } from "antd";
import Button, {
  ButtonShapes,
  ButtonTypes,
  IconSides,
} from "components/Button";
import ChevronDown from "icons/Components/ChevronDown";
import { useCallback, useContext } from "react";
import { GroupIdContext, GroupIdContextSetter } from "screens/base/Navigator";
import { useShallowSelector } from "utilities/hooks";

const PayoutsGroupSelector: React.FC = () => {
  const groups = useShallowSelector((store) => store.groups.dict);
  const userGroups = useShallowSelector((store) => store.groups.userGroups);
  const groupId = useContext(GroupIdContext);
  const setgroupId = useContext(GroupIdContextSetter);

  const setGroupIdAction = useCallback((newGroupId: string | undefined) => {
    setgroupId(newGroupId);
  }, []);

  const getNameFromGroupId = (groupId?: string | undefined) => {
    if (!groupId) return "All my groups";
    return groups[groupId].name;
  };

  return (
    <>
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu>
            <Menu.Item key={"all"} onClick={() => setGroupIdAction(undefined)}>
              {getNameFromGroupId()}
            </Menu.Item>
            {userGroups.map((groupId) => (
              <Menu.Item
                key={groupId}
                onClick={() => setGroupIdAction(groupId)}
              >
                {getNameFromGroupId(groupId)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button
          buttonType={ButtonTypes.DEFAULT}
          style={{ marginLeft: "-4px" }}
          shape={ButtonShapes.OVAL}
          icon={<ChevronDown />}
          iconSide={IconSides.RIGHT}
        >
          {getNameFromGroupId(groupId)}
        </Button>
      </Dropdown>
    </>
  );
};

export default PayoutsGroupSelector;
