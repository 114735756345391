import React, { useCallback, useEffect, useState } from "react";
import store from "store/storeExporter";
import { ChildrenAggregate, IProjectObj } from "utilities/types";
import styles from "./workProgress/workProgress.module.scss";

interface ProgressProps {
  childrenAggregate: ChildrenAggregate[];
  type: "minimal" | "normal";
  item?: IProjectObj;
  showAsOwnProgress?: boolean;
  completed?: boolean;
}

export interface ProgressState {
  inProgress: number;
  unstarted: number;
  total: number;
  done: number;
}

const progressKeys = ["done", "inProgress", "unstarted"];

const WorkProgress: React.FC<ProgressProps> = (props) => {
  const [progressState, setprogressState] = useState<null | ProgressState>(
    null
  );
  useEffect(() => {
    const progressState: ProgressState = {
      done: 0,
      inProgress: 0,
      total: 0,
      unstarted: 0,
    };
    const aggregate = [...props.childrenAggregate];
    if (aggregate.length === 0 && props.showAsOwnProgress && props.item) {
      if (props.item.statusId) {
        const status = store.getState().work.statuses.dict[props.item.statusId];
        const ownItem: ChildrenAggregate = {
          id: props.item.id,
          open: !props.item?.isClosed,
          statusCategoryId: status.categoryId,
          statusId: status.id,
        };
        aggregate.push(ownItem);
      }
    }
    aggregate.forEach((childAggregate) => {
      if (["1", "2"].includes(childAggregate.statusCategoryId)) {
        progressState.total++;
        progressState.unstarted++;
      }
      if (childAggregate.statusCategoryId === "3") {
        progressState.total++;
        progressState.inProgress++;
      }
      if (["4", "5"].includes(childAggregate.statusCategoryId)) {
        progressState.total++;
        progressState.done++;
      }
    });
    setprogressState(progressState);
  }, [props.childrenAggregate]);

  if (!progressState) return <></>;
  return (
    <ProgressBar
      progressState={progressState}
      type={props.type}
      showAsOwnProgress={props.showAsOwnProgress}
      statusId={props.item?.statusId}
      completed={props.completed}
    />
  );
};

export const ProgressBar: React.FC<{
  progressState: ProgressState;
  type: "minimal" | "normal";
  showAsOwnProgress?: boolean;
  statusId?: string;
  showEmptyText?: string;
  completed?: boolean;
}> = ({
  progressState,
  type,
  showAsOwnProgress,
  statusId,
  showEmptyText,
  completed,
}) => {
  const checkNext = useCallback(
    (currentKey: "done" | "inProgress" | "unstarted") => {
      const index = progressKeys.indexOf(currentKey);
      if (!progressState) return false;
      for (let i = index + 1; i <= progressKeys.length; i++) {
        if (progressState[progressKeys[i] as keyof ProgressState] > 0)
          return true;
      }
      return false;
    },
    [progressState]
  );
  return (
    <div style={{ width: "100%" }}>
      <div
        className={`
          ${styles.progressBar} 
          ${type === "minimal" ? styles.minimal : ""}
          ${
            (progressState.done / progressState.total) * 100 === 100
              ? styles.progressBorder
              : ""
          }
          `}
      >
        {(progressState.inProgress > 0 || progressState.done > 0) && (
          <div
            className={styles.startedBar}
            style={{
              width: `calc(${
                ((progressState.inProgress + progressState.done) /
                  progressState.total) *
                100
              }% + 2px)`,
            }}
          ></div>
        )}

        {(progressState.done > 0 || completed) && (
          <div
            className={`${
              (progressState.done / progressState.total) * 100 < 100
                ? styles.doneBar
                : completed
                ? styles.doneBarFull
                : styles.doneBar
            }`}
            style={{
              width: `calc(${
                (progressState.done / progressState.total) * 100
              }% + 2px)`,
            }}
          ></div>
        )}
      </div>

      {type !== "minimal" && (
        <div
          className={
            styles.progressDetails
            // (type === "minimal" ? ` ${styles.minimalProgressDetails}` : "")
          }
          style={{ alignContent: "start" }}
        >
          {showAsOwnProgress ? (
            <>
              <span className="label" style={{ marginTop: "3px" }}>
                {showEmptyText ? showEmptyText : ""}
              </span>
            </>
          ) : (
            <>
              {progressState.done > 0 && (
                <span className="label">
                  {progressState.done} done{checkNext("done") && <>, </>}
                </span>
              )}
              {progressState.inProgress > 0 && (
                <span className="label">
                  {progressState.inProgress} in progress
                  {checkNext("inProgress") && <>, </>}
                </span>
              )}
              {progressState.unstarted > 0 && (
                <span className="label">
                  {progressState.unstarted} unstarted
                </span>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkProgress;
