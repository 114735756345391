import { locationSubject } from "components/LocationListener";
import { checkIfCannotCreateWork } from "modules/appService";
import { getContainerThroughType } from "modules/containersService";
import {
  createNewCustomWorkView,
  creteNewProject,
} from "modules/entityService";
import { batch } from "react-redux";
import * as actionTypes from "store/actions";
import { Block } from "store/reducers/blockReducer";
import {
  getBlockById,
  getBlockIndex,
  getCurrentContext,
  getPreviousSibling,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $focusOn, prevState } from "store/storeExporter";
import { getSunday } from "utilities/dateTime";
import {
  entityKeepTypes,
  ILineValue,
  LineType,
  LineValueType,
} from "utilities/lineUtilities";
import { ChunkDestination } from "utilities/stateTypes";
import { stripHtml } from "utilities/stringUtilities";
import {
  ContainerTypes,
  CustomWorkView,
  IBlockContext,
  IProjectObj,
  ViewNames,
  WorkTypes,
} from "utilities/types";
import {
  BlockActionTypes,
  clearTextSaveTimeout,
  executeTextSaveTimeout,
  getAntiAction,
} from "../blockActions";
import {
  getAntiDelta,
  handleUpdateAction,
  indentSingleBlock,
  primitiveAddSingleBlock,
  primitiveChangeBlockType,
  primitiveChangeLineBlockType,
  primitiveChangeSpecificKeys,
  primitiveUpdateBlockText,
} from "../primitiveActions/primitiveActions";
import { getBlockFromExtender } from "../socketActionsListener";
import { moveToContainerEnd } from "./moveActions";
import { setUpdateObjectToStore } from "./persistActions";
import {
  ActionObject,
  ActionWrapperObject,
  createActionWrapperObject,
} from "./undoUtils";
import { pick } from "lodash";
import { getDefaultStatusId } from "components/ModalNewTask";
import { breakDownHtml, getHtml } from "../blockValueHelpers";
import notificationsApi from "clientApi/notificationsApi";
import { axiosInstance } from "index";
import navigationApi from "clientApi/navigationApi";
import { batchActions } from "redux-batched-actions";
import { ADD_BLOCK_TYPES } from "./addBlockActions";
import {
  INewTaskCreationModes,
  openNewTaskModal,
} from "store/reducers/clientReducer";

const removeHashtagFromValue = (valueArr: ILineValue[]) => {
  valueArr.forEach((val) => {
    if (val.type === LineValueType.mention && val.options?.hashtag) {
      delete val.options;
      val.type = LineValueType.childrenText;
    }
  });
};

export const changeSingleBlockType = (
  blockId: string,
  newBlockType: LineType,
  context: IBlockContext,
  blockRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
  clearTextSaveTimeout();
  const newState = store.getState().blocks;
  let block = getBlockById(newState.dict, blockId);
  if (block.lineType !== newBlockType) {
    const saveActions: ActionObject[] = [];
    const undoObject: ActionWrapperObject = createActionWrapperObject(context);
    batch(() => {
      if (blockRef) {
        primitiveUpdateBlockText({
          blockId,
          blockRef,
          undoObject,
          saveActions,
          context,
        });
      }

      primitiveChangeBlockType({
        blockId,
        newBlockType,
        undoObject,
        saveActions,
        context,
      });

      store.dispatch({
        type: actionTypes.REFOCUS,
        param: {
          newFocus: { ...$focusOn.value },
        },
      });
      store.dispatch({
        type: actionTypes.SET_UNDO,
        param: {
          undoObject,
          contextId: context.id,
        },
      });
    });

    setUpdateObjectToStore(saveActions, context);
  }
};

export const changeLinkBlockType = (
  blockId: string,
  newBlockType: LineType,
  context: IBlockContext,
  blockRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
  clearTextSaveTimeout();
  const newState = store.getState().blocks;
  let block = getBlockById(newState.dict, blockId);
  if (block.lineType !== newBlockType) {
    const saveActions: ActionObject[] = [];
    const undoObject: ActionWrapperObject = createActionWrapperObject(context);
    batch(() => {
      if (blockRef) {
        primitiveUpdateBlockText({
          blockId,
          blockRef,
          undoObject,
          saveActions,
          context,
        });
      }

      primitiveChangeLineBlockType({
        blockId,
        newBlockType,
        undoObject,
        saveActions,
        context,
      });

      store.dispatch({
        type: actionTypes.REFOCUS,
        param: {
          newFocus: { ...$focusOn.value },
        },
      });
      store.dispatch({
        type: actionTypes.SET_UNDO,
        param: {
          undoObject,
          contextId: context.id,
        },
      });
    });

    setUpdateObjectToStore(saveActions, context);
  }
};

export const changeParentListType = (
  blockId: string,
  newListType: LineType | "noOutline",
  context: IBlockContext
) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, blockId);
  if (block && block.parentId !== "") {
    const parentBlock = getBlockById(newState.dict, block.parentId);
    if (parentBlock.subLinesList === newListType) return;
    const saveActions: ActionObject[] = [];
    const delta: Partial<Block> = {
      dateModified: new Date(),
      modifiedBy: prevState.value.user?.id,
      subLinesList: newListType,
    };
    const updateAction: ActionObject = {
      id: parentBlock.id,
      type: BlockActionTypes.update,
      delta,
    };
    saveActions.push(updateAction);

    const undoObject: ActionWrapperObject = createActionWrapperObject(context);
    const undoaction: ActionObject = {
      delta: getAntiDelta(delta, parentBlock),
      type: BlockActionTypes.update,
      id: parentBlock.id,
    };
    undoObject.actions.push(undoaction);

    store.dispatch({
      type: actionTypes.SAVE_BLOCK_DATA,
      param: {
        id: parentBlock.id,
        blockData: parentBlock,
        delta,
      },
    });

    store.dispatch({
      type: actionTypes.SET_UNDO,
      param: {
        undoObject,
        contextId: context.id,
      },
    });
    setUpdateObjectToStore(saveActions, context);
  } else {
    const container = getContainerThroughType(
      context.container.id,
      context.container.type
    );
    const titleBlockId = (container as any)?.titleBlockId;
    if (titleBlockId) {
      const titleBlock = getBlockById(newState.dict, titleBlockId);

      const saveActions: ActionObject[] = [];
      const delta: Partial<Block> = {
        dateModified: new Date(),
        modifiedBy: prevState.value.user?.id,
        subLinesList: newListType,
      };
      const updateAction: ActionObject = {
        id: titleBlock.id,
        type: BlockActionTypes.update,
        delta,
      };
      saveActions.push(updateAction);

      const undoObject: ActionWrapperObject =
        createActionWrapperObject(context);
      const undoaction: ActionObject = {
        delta: getAntiDelta(delta, titleBlock),
        type: BlockActionTypes.update,
        id: titleBlock.id,
      };
      undoObject.actions.push(undoaction);
      handleUpdateAction(updateAction);
      store.dispatch({
        type: actionTypes.SET_UNDO,
        param: {
          undoObject,
          contextId: context.id,
        },
      });
      setUpdateObjectToStore(saveActions, context);
    }
  }
};

export const checkForPrefix = (
  blockId: string,
  blockRef: React.MutableRefObject<HTMLDivElement>,
  newListType: LineType,
  context: IBlockContext
) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, blockId);
  const currentContext = getCurrentContext(newState, context.id);
  const index = getBlockIndex(newState, block, currentContext);
  const inZoomed =
    context.zoomedBlockId && context.zoomedBlockId === block.id ? true : false;
  if (inZoomed) return;

  if (index === 0) return changeParentListType(blockId, newListType, context);
  batch(() => {
    const saveActions: ActionObject[] = [];
    const undoObject = createActionWrapperObject(context);
    const prevBlockId = getPreviousSibling(newState, block, currentContext);
    const prevBlock = getBlockById(newState.dict, prevBlockId);
    if (
      entityKeepTypes.includes(prevBlock.lineType) ||
      prevBlock.children.length > 0
    )
      return;
    batch(() => {
      clearTextSaveTimeout();
      primitiveUpdateBlockText({
        blockId,
        context,
        saveActions,
        undoObject,
        blockRef,
      });
      indentSingleBlock({
        blockId: block.id,
        context,
        saveActions,
        undoObject,
      });
      primitiveChangeSpecificKeys({
        blockId: prevBlockId,
        delta: {
          subLinesList: newListType,
        },
        undoObject,
        saveActions,
        context,
      });
    });
    store.dispatch({
      type: actionTypes.SET_UNDO,
      param: {
        undoObject,
        contextId: context.id,
      },
    });
    setUpdateObjectToStore(saveActions, context);
  });
};

export const changeBlockOrSelectionType = (
  focusBlockId: string,
  focusedBlockRef: React.MutableRefObject<HTMLDivElement | null>,
  newBlockType: LineType,
  context: IBlockContext,
  refernecingData?: {
    referencingContainerId?: string;
    workType?: WorkTypes;
  },
  fromSlash?: boolean
) => {
  executeTextSaveTimeout();
  const newState = store.getState().blocks;
  const saveActions: ActionObject[] = [];
  const undoObject: ActionWrapperObject = createActionWrapperObject(context);
  batch(async () => {
    primitiveUpdateBlockText({
      blockId: focusBlockId,
      blockRef: focusedBlockRef,
      undoObject,
      saveActions,
      context,
    });

    if ([LineType.work, LineType.Title].includes(newBlockType)) {
      await changeMultipleBlocksType(
        focusBlockId,
        focusedBlockRef,
        newBlockType,
        context,
        refernecingData?.workType,
        fromSlash
      );
      return;
    }

    const selectedBlocks =
      newState.selectedBlocks.length > 0
        ? [...newState.selectedBlocks]
        : [focusBlockId];

    for (const blockId of selectedBlocks) {
      primitiveChangeBlockType({
        blockId,
        newBlockType,
        undoObject,
        saveActions,
        context,
        referencingContainerId: refernecingData?.referencingContainerId,
      });
    }
  });

  store.dispatch({
    type: actionTypes.REFOCUS,
    param: {
      newFocus: { ...$focusOn.value },
    },
  });

  store.dispatch({
    type: actionTypes.SET_UNDO,
    param: {
      undoObject,
      contextId: context.id,
    },
  });
  setUpdateObjectToStore(saveActions, context);
};

export const changeMultipleBlocksType = async (
  focusedBlockId: string,
  focusedBlockRef: React.MutableRefObject<HTMLDivElement | null>,
  newBlockType: LineType,
  context: IBlockContext,
  workType?: WorkTypes,
  fromSlash?: boolean
) => {
  executeTextSaveTimeout();
  const newState = store.getState().blocks;
  let multiBlocks = false;
  let selectedBlocks = [...newState.selectedBlocks];

  if (selectedBlocks.length === 0) {
    selectedBlocks.push(focusedBlockId);
  }
  selectedBlocks = selectedBlocks.filter((blockId) => {
    const block = getBlockById(newState.dict, blockId);
    return ![LineType.Title, LineType.work].includes(block.lineType);
  });

  if (selectedBlocks.length === 0) return;
  if (selectedBlocks.length > 1) multiBlocks = true;

  const confirmAction = async (groupId?: string) => {
    const arrayOfAction = [];
    for (const blockId of selectedBlocks) {
      const block = getBlockById(newState.dict, blockId);
      removeHashtagFromValue(block.value);
      const text = stripHtml(getHtml(block.value));
      const newTaskTitleText = text;

      arrayOfAction.push(
        createProjectFromBlock(
          blockId,
          newTaskTitleText,
          block.value,
          context,
          newState.selectedBlocks.length === 0,
          workType,
          groupId
        )
      );
    }
    for (const action of arrayOfAction) {
      await (
        await action
      )();
      setTimeout(() => {
        if (workType === WorkTypes.TASK) {
          store.dispatch({
            type: actionTypes.REFOCUS,
            param: {
              newFocus: { ...$focusOn.value },
            },
          });
        }
      }, 0);
    }
  };

  const container = store.getState().work.dict[context.container.id];
  const weeklyNote =
    store.getState().notes.weeklyNotesDict?.[context.container.id]?.isWeekly;

  if (
    fromSlash &&
    container &&
    context.container.type !== ContainerTypes.TEMPLATE &&
    container.workType === WorkTypes.TASK
  ) {
    const blockId = selectedBlocks[0];
    const block = getBlockById(newState.dict, blockId);
    if (block.children.length === 0) {
      const parentId =
        block.containerType === ContainerTypes.PROJECT ? block.containerId : "";

      const parent = store.getState().work.dict[parentId];

      const inheritedData = !parent
        ? {}
        : pick(parent, ["dueDate", "assigneeId", "workSectionId", "groupId"]);

      let groupId;
      if (block.containerType === ContainerTypes.NOTE) {
        const note = store.getState().notes.dict[block.containerId];

        if (note && !note.isWeekly) {
          groupId = note.groupId;
        }
      }
      openNewTaskModal({
        type: INewTaskCreationModes.new,
        presetData: {
          ...inheritedData,
          name: stripHtml(getHtml(block.value)),
          nameValue: block.value,
          workspaceId: prevState.value.workspace.id,
          parentId,
          workType,
          groupId: groupId,
        },
        disableSelectGroup: block.containerType === ContainerTypes.NOTE,
        transformBlocks: {
          selectedBlock: block,
          context,
        },
        onClose: () => {
          store.dispatch({
            type: actionTypes.REFOCUS,
            param: {
              newFocus: { ...$focusOn.value },
            },
          });
        },
      });
      return;
    }
  }

  if (!multiBlocks) {
    if (
      (container &&
        (container.workType === WorkTypes.PROJECT ||
          container.workType === WorkTypes.INITIATIVE)) ||
      weeklyNote ||
      context.container.type === ContainerTypes.TEMPLATE ||
      context.container.type === ContainerTypes.SNIPPET ||
      context.container.type === ContainerTypes.DOCUMENT
    ) {
      prepareConfirmModal(
        selectedBlocks,
        newBlockType,
        confirmAction,
        context,
        workType
      );
    } else {
      confirmAction();
    }
  } else {
    if ([LineType.work].includes(newBlockType)) {
      if (checkIfCannotCreateWork()) return;
    }
    prepareConfirmModal(
      selectedBlocks,
      newBlockType,
      confirmAction,
      context,
      workType
    );
  }
};

const prepareConfirmModal = (
  selectedBlocks: string[],
  newBlockType: LineType,
  confirmAction: any,
  context: IBlockContext,
  workType?: WorkTypes
) => {
  const newState = store.getState().blocks;
  const container = store.getState().work.dict[context.container.id];
  const weeklyNote =
    store.getState().notes.weeklyNotesDict?.[context.container.id]?.isWeekly;

  const getListOfItems = (blockToProject: any) => {
    let itemsArrayText = `<div>`;
    blockToProject.forEach((blockId: string, index: number) => {
      const block = getBlockById(newState.dict, blockId);
      let newText = stripHtml(getHtml(block.value));
      if (newText.length === 0)
        newText = `Untitled ${
          workType === WorkTypes.PROJECT ? "project" : "task"
        }`;
      let insertExerpt = "";
      if (newText.length > 80) {
        newText = newText.substring(0, 80);
        insertExerpt = "...";
      }
      itemsArrayText =
        itemsArrayText +
        `<span> <span class="bold">${index + 1}) </span> ` +
        newText +
        insertExerpt;
      itemsArrayText = itemsArrayText + ".</span><br>";
    });
    itemsArrayText = itemsArrayText + "</div>";
    return itemsArrayText;
  };
  const numberOfEntities = selectedBlocks.length;

  let groupIds: string[] | undefined = store.getState().groups.userGroups;

  const entityType = workType === WorkTypes.PROJECT ? "project" : "task";

  if (
    container &&
    (container.workType === "Project" || container.workType === "Initiative")
  ) {
    groupIds = container.groupIds?.filter((groupId) =>
      groupIds?.includes(groupId)
    );
  }

  if (!groupIds || groupIds?.length === 0) {
    notificationsApi.displayError({
      body: "You don't have any group to create the task",
    });
    return;
  }

  return store.dispatch({
    type:
      (container &&
        (container.workType === "Project" ||
          container.workType === "Initiative")) ||
      weeklyNote ||
      context.container.type === ContainerTypes.TEMPLATE ||
      context.container.type === ContainerTypes.SNIPPET ||
      context.container.type === ContainerTypes.DOCUMENT
        ? actionTypes.OPEN_SELECT_GROUP_MODAL
        : actionTypes.OPEN_CONFIRMATION_MODAL,
    message: "",
    hasChildComponent: true,
    body: getListOfItems(selectedBlocks),
    title: `You are about to create ${numberOfEntities} ${entityType}${
      numberOfEntities > 1 ? "s" : ""
    }`,
    groupIds,
    confirmMessage: `Create ${entityType}${numberOfEntities > 1 ? "s" : ""}`,
    confirmAction: (groupId?: string) => {
      confirmAction(groupId);
    },
    cancelAction: () => {
      store.dispatch({
        type: actionTypes.REFOCUS,
        param: {
          newFocus: { ...$focusOn.value },
        },
      });
    },
    display: true,
  });
};

export const createProjectFromBlock = async (
  blockId: string,
  taskTitle: string,
  blockValue: ILineValue[],
  context: IBlockContext,
  isSingle: boolean,
  workType?: WorkTypes,
  groupId?: string
) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, blockId);
  const children = [...block.children];
  const isTitleBlock = block.containerType === ContainerTypes.PROJECT;
  const name = taskTitle.length > 0 ? taskTitle : "Untitled Task";

  const getNameValue = () => {
    const newBlockValue: ILineValue[] = [];
    const strippedHtml = stripHtml(getHtml(blockValue));
    const newEl = document.createElement("div");
    newEl.innerHTML = strippedHtml;
    newEl.childNodes.forEach((element: any) => {
      newBlockValue.push(breakDownHtml(element));
    });
    return newBlockValue;
  };

  const nameValue: ILineValue[] =
    taskTitle.length > 0
      ? getNameValue()
      : ([
          { type: "#text", value: "Untitled Task", children: [] },
        ] as ILineValue[]);

  const parentId =
    block.containerType === ContainerTypes.PROJECT ? block.containerId : "";

  const parent = store.getState().work.dict[parentId];

  const inheritedData = !parent
    ? {}
    : pick(parent, ["dueDate", "assigneeId", "workSectionId", "groupId"]);

  let presetData: Partial<IProjectObj> = {
    workType: workType ? workType : WorkTypes.TASK,
    name,
    statusId: getDefaultStatusId(),
    nameValue,
    ...inheritedData,
  };
  // if (isTitleBlock) presetData.titleBlockId = block.id;

  let noteGroupId: string | undefined;
  if (context.container.type === ContainerTypes.NOTE) {
    const state = store.getState();

    const note =
      state.notes.dict[context.container.id] ??
      state.notes.weeklyNotesDict[context.container.id];
    if (note?.isWeekly) {
      const mondayDate = note.dateCreated;
      const dueDate = getSunday(new Date(mondayDate));
      presetData.dueDate = dueDate;
    } else {
      noteGroupId = note?.groupId;
    }
  }

  const newProject = creteNewProject(presetData);
  const newBlockType = LineType.work;

  const passObj: any = {
    id: newProject.id,
    workspaceId: store.getState().workspace.id,
    name: newProject.name,
    nameValue: newProject.nameValue,
    parentId: isTitleBlock ? block.containerId : null,
    titleBlockId: null,
    workType: WorkTypes.TASK,
    noFirstBlock: children.length > 0 ? true : false,
    ...presetData,
  };

  if (!passObj.titleBlockId) delete passObj.titleBlockId;
  else {
    passObj.titleBlock = getBlockFromExtender(
      getBlockById(newState.dict, passObj.titleBlockId)
    );
  }
  if (!passObj.parentId) delete passObj.parentId;

  if (presetData.dueDate) {
    passObj.dueDate = presetData.dueDate;
  }

  const finalGroupId = groupId ?? noteGroupId ?? presetData.groupId;
  store.dispatch({
    type: actionTypes.ADD_NEW_WORK_ITEM,
    workItem: {
      ...newProject,
      groupId: finalGroupId,
      groupIds: [finalGroupId],
    },
  });

  return async () =>
    await axiosInstance
      .post("/api/project/", {
        presets: { ...passObj, groupId: finalGroupId },
        titleBlock: passObj.titleBlock,
        noFirstBlock: false,
        name: passObj.name,
        groupId: finalGroupId,
      })
      .then((res) => {
        const { payload: newProject } = res.data;
        batch(async () => {
          store.dispatch({
            type: actionTypes.ADD_NEW_WORK_ITEM,
            project: newProject,
          });
          connectBlockToEntity({
            blockId: block.id,
            entityId: newProject.id,
            newBlockType,
            entityType: ContainerTypes.PROJECT,
            context,
            save: true,
          });
          if (children.length > 0) {
            await moveToContainerEnd({
              blockId: children[0],
              context,
              targetContainer: {
                containerId: newProject.id,
                containerType: ContainerTypes.PROJECT,
              },
              movingBlockIds: children,
            });
          }
          if (
            isSingle &&
            context.container?.type !== ContainerTypes.WORK_ACTIVITY
          ) {
            if (
              [WorkTypes.INITIATIVE, WorkTypes.PROJECT].includes(
                newProject.workType
              )
            )
              navigateAfterBlockUpdate(
                context,
                newProject.projectId,
                newProject.id
              );
          }
          return true;
        });
      });
};

export const createCustomViewFromBlock = async (
  blockId: string,
  taskTitle: string,
  blockValue: ILineValue[],
  context: IBlockContext,
  isSingle: boolean
) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, blockId);
  const isTitleBlock = block.containerType === ContainerTypes.PROJECT;
  const name = taskTitle.length > 0 ? taskTitle : "Untitled View";

  let presetData: Partial<CustomWorkView> = {
    name,
  };

  const newCustomWorkView = createNewCustomWorkView(presetData);
  const newBlockType = isTitleBlock ? LineType.Title : LineType.widget;

  store.dispatch({
    type: actionTypes.UPDATE_CUSTOM_VIEW,
    param: {
      type: "add",
      delta: newCustomWorkView,
      id: newCustomWorkView.id,
    },
  });
  return async () =>
    await axiosInstance
      .post(`/api/customWorkView/`, {
        ...newCustomWorkView,
      })
      .then((res) => {
        store.dispatch({
          type: actionTypes.UPDATE_CUSTOM_VIEW,
          param: {
            type: "add",
            delta: newCustomWorkView,
            id: newCustomWorkView.id,
          },
        });
        connectBlockToEntity({
          blockId: block.id,
          entityId: newCustomWorkView.id,
          newBlockType,
          entityType: ContainerTypes.WORK,
          context,
          save: true,
        });
        if (
          isSingle &&
          context.container?.type !== ContainerTypes.WORK_ACTIVITY
        ) {
          const base = store.getState().workspace;
          if (context.paneId === ChunkDestination.secondary) {
            navigationApi.openSplitView({
              viewName: ViewNames.Detail,
              groupId: newCustomWorkView.id,
              groupSlug: ContainerTypes.WORK,
            });
            //TODO
            return;
          }
          locationSubject.next(`/${base.slug}/view/${newCustomWorkView.id}`);
        }
        return true;
      })
      .catch((err) => {
        console.log(err);
      });
};

export const connectBlockToEntity = (props: {
  blockId: string;
  entityId: string;
  entityType: ContainerTypes;
  newBlockType: LineType;
  context: IBlockContext;
  save: boolean;
}) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, props.blockId);
  const saveActions: ActionObject[] = [];
  const delta: Partial<Block> = {
    dateModified: new Date(),
    modifiedBy: prevState.value.user?.id,
    lineType: props.newBlockType,
    referencingContainerId: props.entityId,
    referencingContainerType: props.entityType,
  };
  if (props.newBlockType !== LineType.Title) delta.value = [];

  const updateAction: ActionObject = {
    id: props.blockId,
    type: BlockActionTypes.update,
    delta,
  };
  saveActions.push(updateAction);

  const context = props.context;

  batch(() => {
    // const updatedBlock = { ...block, ...delta };
    store.dispatch({
      type: actionTypes.SAVE_BLOCK_DATA,
      param: {
        id: block.id,
        blockData: block,
        delta,
      },
    });
    if (props.save) {
      const undoObject: ActionWrapperObject =
        createActionWrapperObject(context);
      const undoaction: ActionObject = {
        delta: getAntiDelta(delta, block),
        type: BlockActionTypes.update,
        id: block.id,
      };
      undoObject.actions.push(undoaction);
      store.dispatch({
        type: actionTypes.SET_UNDO,
        param: {
          undoObject,
          contextId: context.id,
        },
      });
      setUpdateObjectToStore(saveActions, props.context);
    }
    store.dispatch({
      type: actionTypes.REFOCUS,
      param: {
        newFocus: { ...$focusOn.value, focusBlockId: block.id },
      },
    });
  });
};

export const navigateAfterBlockUpdate = (
  context: IBlockContext,
  projectId: number,
  id: string
) => {
  navigationApi.openPeekView({
    viewName: ViewNames.Detail,
    entity: {
      containerId: id,
      containerType: ContainerTypes.WORK,
    },
  });
  return;
};

export const cycleOutlineMode = (context: IBlockContext) => {
  executeTextSaveTimeout();
  const newState = store.getState().blocks;
  const currentContext = getCurrentContext(newState, context.id);
  let newOutlineMode = "noOutline";

  if (currentContext.outlineMode === "noOutline")
    newOutlineMode = LineType.bulletedList;
  else if (currentContext.outlineMode === LineType.bulletedList)
    newOutlineMode = LineType.numberedList;
  else if (currentContext.outlineMode === LineType.numberedList)
    newOutlineMode = LineType.bulletedList;

  store.dispatch({
    type: actionTypes.UPDATE_CONTEXT,
    param: {
      id: currentContext.id,
      delta: {
        outlineMode: newOutlineMode,
      },
    },
  });
};

export const changeOutlineMode = (
  newOutline: string,
  context: IBlockContext
) => {
  executeTextSaveTimeout();
  store.dispatch({
    type: actionTypes.UPDATE_CONTEXT,
    param: {
      id: context.id,
      delta: {
        outlineMode: newOutline,
      },
    },
  });
};

export const turnIntoTableBlock = (props: {
  blockId: string;
  context: IBlockContext;
}) => {
  const storeData = store.getState();
  const block = storeData.blocks.dict[props.blockId];
  if (!block) return;

  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(props.context);
  primitiveAddSingleBlock({
    context: props.context,
    newBlockValue: [],
    presets: {
      parentId: block.id,
      indentLevel: block.indentLevel + 1,
      documentRank: "0a",
      baseId: storeData.workspace.id,
      containerId: block.containerId,
      containerType: block.containerType,
      lineType: LineType.tableRow,
    },
    saveActions,
    undoObject: undoObj,
    type: ADD_BLOCK_TYPES.addChildBlock,
    referencePointBlockId: block.id,
  });
  primitiveAddSingleBlock({
    context: props.context,
    newBlockValue: [],
    presets: {
      parentId: block.id,
      indentLevel: block.indentLevel + 1,
      documentRank: "0a",
      baseId: storeData.workspace.id,
      containerId: block.containerId,
      containerType: block.containerType,
      lineType: LineType.tableRow,
    },
    saveActions,
    undoObject: undoObj,
    type: ADD_BLOCK_TYPES.addChildBlock,
    referencePointBlockId: block.id,
  });
  const firstRow = primitiveAddSingleBlock({
    context: props.context,
    newBlockValue: [],
    presets: {
      parentId: block.id,
      indentLevel: block.indentLevel + 1,
      documentRank: "0c",
      baseId: storeData.workspace.id,
      containerId: block.containerId,
      containerType: block.containerType,
      lineType: LineType.tableRow,
    },
    saveActions,
    undoObject: undoObj,
    type: ADD_BLOCK_TYPES.addChildBlock,
    referencePointBlockId: block.id,
  });

  const column1Id = generateRandomString();
  const column2Id = generateRandomString();

  const columnData = {
    dict: {
      [column1Id]: {
        id: column1Id,
      },
      [column2Id]: {
        id: column2Id,
      },
    },
    order: [column1Id, column2Id],
  };

  const value: ILineValue[] = [
    {
      type: LineValueType.text,
      value: "",
      children: [],
      tableBlockColumnData: columnData,
    },
  ];

  const action: ActionObject = {
    delta: {
      value,
      lineType: LineType.table,
    },
    id: block.id,
    type: BlockActionTypes.update,
  };

  $focusOn.next({
    ...$focusOn.value,
    focusBlockId: firstRow.id,
    focusTableId: block.id,
    focusNodeId: column1Id,
  });

  const antiAction = getAntiAction(action);
  saveActions.push(action);
  undoObj.actions.push(antiAction);
  reduxActions.push({
    type: actionTypes.SAVE_BLOCK_DATA,
    param: {
      id: block.id,
      blockData: block,
      delta: {
        value,
        lineType: LineType.table,
      },
    },
  });
  reduxActions.push({
    type: actionTypes.SET_UNDO,
    param: {
      context: props.context,
      contextId: props.context.id,
      undoObject: undoObj,
    },
  });

  setUpdateObjectToStore(saveActions, props.context);
  store.dispatch(batchActions(reduxActions));
};

const generateRandomString = () =>
  (Math.random() + 1).toString(36).substring(7);
