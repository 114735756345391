import Icon from "@ant-design/icons";
import React from "react";

export default function QuestionCircleIcon(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 16 16"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <g opacity="0.82">
        <path
          d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
          fill="currentColor"
          fillOpacity="100"
        />

        <path
          opacity="0.1"
          d="M8 2.1875C4.79063 2.1875 2.1875 4.79063 2.1875 8C2.1875 11.2094 4.79063 13.8125 8 13.8125C11.2094 13.8125 13.8125 11.2094 13.8125 8C13.8125 4.79063 11.2094 2.1875 8 2.1875ZM8 12.0625C7.65469 12.0625 7.375 11.7828 7.375 11.4375C7.375 11.0922 7.65469 10.8125 8 10.8125C8.34531 10.8125 8.625 11.0922 8.625 11.4375C8.625 11.7828 8.34531 12.0625 8 12.0625ZM8.98281 8.63281C8.84131 8.68746 8.71955 8.78347 8.63343 8.90834C8.54731 9.03321 8.50081 9.18113 8.5 9.33281V9.6875C8.5 9.75625 8.44375 9.8125 8.375 9.8125H7.625C7.55625 9.8125 7.5 9.75625 7.5 9.6875V9.35156C7.5 8.99062 7.60469 8.63437 7.81094 8.3375C8.0125 8.04687 8.29375 7.825 8.625 7.69844C9.15625 7.49375 9.5 7.04844 9.5 6.5625C9.5 5.87344 8.82656 5.3125 8 5.3125C7.17344 5.3125 6.5 5.87344 6.5 6.5625V6.68125C6.5 6.75 6.44375 6.80625 6.375 6.80625H5.625C5.55625 6.80625 5.5 6.75 5.5 6.68125V6.5625C5.5 5.94844 5.76875 5.375 6.25625 4.94844C6.725 4.5375 7.34375 4.3125 8 4.3125C8.65625 4.3125 9.275 4.53906 9.74375 4.94844C10.2312 5.375 10.5 5.94844 10.5 6.5625C10.5 7.46562 9.90469 8.27812 8.98281 8.63281Z"
          fill="currentColor"
          fillOpacity="100"
        />

        <path
          d="M7.375 11.4375C7.375 11.6033 7.44085 11.7622 7.55806 11.8794C7.67527 11.9967 7.83424 12.0625 8 12.0625C8.16576 12.0625 8.32473 11.9967 8.44194 11.8794C8.55915 11.7622 8.625 11.6033 8.625 11.4375C8.625 11.2717 8.55915 11.1128 8.44194 10.9956C8.32473 10.8783 8.16576 10.8125 8 10.8125C7.83424 10.8125 7.67527 10.8783 7.55806 10.9956C7.44085 11.1128 7.375 11.2717 7.375 11.4375ZM9.74375 4.94844C9.275 4.53906 8.65625 4.3125 8 4.3125C7.34375 4.3125 6.725 4.5375 6.25625 4.94844C5.76875 5.375 5.5 5.94844 5.5 6.5625V6.68125C5.5 6.75 5.55625 6.80625 5.625 6.80625H6.375C6.44375 6.80625 6.5 6.75 6.5 6.68125V6.5625C6.5 5.87344 7.17344 5.3125 8 5.3125C8.82656 5.3125 9.5 5.87344 9.5 6.5625C9.5 7.04844 9.15625 7.49375 8.625 7.69844C8.29375 7.825 8.0125 8.04687 7.81094 8.3375C7.60469 8.63437 7.5 8.99062 7.5 9.35156V9.6875C7.5 9.75625 7.55625 9.8125 7.625 9.8125H8.375C8.44375 9.8125 8.5 9.75625 8.5 9.6875V9.33281C8.50081 9.18113 8.54731 9.03321 8.63343 8.90834C8.71956 8.78347 8.84131 8.68746 8.98281 8.63281C9.90469 8.27813 10.5 7.46563 10.5 6.5625C10.5 5.94844 10.2312 5.375 9.74375 4.94844Z"
          fill="currentColor"
          fillOpacity="100"
        />
      </g>
    </svg>
  );
}
