import {
  getContainerThroughType,
  persistContainerOutline,
  updateOutlineModeInState,
} from "modules/containersService";
import { createNewBlock } from "modules/entityService";
import { batch } from "react-redux";
import { BehaviorSubject } from "rxjs";
import * as actionTypes from "store/actions";
import { Block, BlockReducerState } from "store/reducers/blockReducer";
import {
  getCurrentContext,
  getNextSibling,
  getBlockById,
  getPreviousSibling,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";

import store, { $focusOn, prevState } from "store/storeExporter";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { getRankBetween } from "utilities/containerRankHelpers";
import { entityKeepTypes, ILineValue, LineType } from "utilities/lineUtilities";
import { BlockActionTypes, keys } from "../blockActions";
import { breakDownHtml, getHtml } from "../blockValueHelpers";
import { getBlockFromExtender } from "../socketActionsListener";
import {
  ADD_BLOCK_TYPES,
  checkBlockRecover,
  singleBlockAddAction,
} from "../specificActions/addBlockActions";
import { deleteBlockMiddleware } from "../specificActions/deleteBlockActions";
import { moveBlocks } from "../specificActions/moveActions";
import { valueChecker } from "../specificActions/textUpdateActions";
import {
  ActionObject,
  ActionWrapperObject,
} from "../specificActions/undoUtils";

export type BlockCreateProps = Partial<Block> &
  Pick<
    Block,
    | "parentId"
    | "indentLevel"
    | "lineType"
    | "containerType"
    | "containerId"
    | "baseId"
  >;

export const primitiveAddSingleBlock = (props: {
  newBlockValue: ILineValue[];
  referencePointBlockId: string;
  type: ADD_BLOCK_TYPES;
  presets: BlockCreateProps;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
  context: any;
}) => {
  let newBlock = createNewBlock(props.presets, {
    containerId: props.presets.containerId,
    containerType: props.presets.containerType,
    baseId: props.presets.baseId,
  });

  const fullBlock: Block = {
    ...newBlock,
    ...props.presets,
    blockSubject: new BehaviorSubject<Partial<Block>>({}),
    children: [],
  };

  if (props.type === ADD_BLOCK_TYPES.addBlockAfter) {
    const newState = store.getState().blocks;
    const context = getCurrentContext(newState, props.context.id);
    let prevRank = undefined;
    let nextRank = undefined;
    const referencePointBlock = getBlockById(
      newState.dict,
      props.referencePointBlockId
    );
    const nextBlockId = getNextSibling(newState, referencePointBlock, context);
    if (referencePointBlock.id) {
      prevRank = referencePointBlock.documentRank;
    }
    if (nextBlockId) {
      nextRank = getBlockById(newState.dict, nextBlockId).documentRank;
    }

    fullBlock.documentRank = getRankBetween(prevRank, nextRank);
  }

  if (props.type === ADD_BLOCK_TYPES.addChildBlock) {
    const newState = store.getState().blocks;
    let prevRank = undefined;
    let nextRank = undefined;
    const referencePointBlock = getBlockById(
      newState.dict,
      props.referencePointBlockId
    );
    if (referencePointBlock.children.length > 0) {
      nextRank = getBlockById(
        newState.dict,
        referencePointBlock.children[0]
      ).documentRank;
    }
    fullBlock.documentRank = getRankBetween(prevRank, nextRank);
  }

  if (props.type === ADD_BLOCK_TYPES.addBlockBefore) {
    const newState = store.getState().blocks;
    const context = getCurrentContext(newState, props.context.id);
    let prevRank = undefined;
    let nextRank = undefined;
    const referencePointBlock = getBlockById(
      newState.dict,
      props.referencePointBlockId
    );
    const prevBlockId = getPreviousSibling(
      newState,
      referencePointBlock,
      context
    );
    if (prevBlockId) {
      prevRank = getBlockById(newState.dict, prevBlockId).documentRank;
    }
    if (referencePointBlock.id) {
      nextRank = referencePointBlock.documentRank;
    }

    fullBlock.documentRank = getRankBetween(prevRank, nextRank);
  }

  singleBlockAddAction(
    props.type,
    props.referencePointBlockId,
    fullBlock,
    props.context
  );

  const saveBlock = getBlockFromExtender(fullBlock);

  props.saveActions.push({
    id: saveBlock.id,
    delta: {
      ...saveBlock,
    },
    type: BlockActionTypes.insert,
    options: {
      newBlock: true,
    },
  });

  const reverseAction: ActionObject = {
    type: BlockActionTypes.delete,
    id: fullBlock.id,
    delta: {},
  };

  props.undoObject.actions.push(reverseAction);
  return fullBlock;
};

export const primitiveChangeSpecificKeys = (props: {
  blockId: string;
  delta: Partial<Block>;
  context: any;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
}) => {
  const newState = store.getState().blocks;
  let block = getBlockById(newState.dict, props.blockId);
  if (entityKeepTypes.includes(block.lineType)) return;
  const delta: Partial<Block> = props.delta;

  const updateAction: ActionObject = {
    id: block.id,
    type: BlockActionTypes.update,
    delta,
  };
  props.saveActions.push(updateAction);

  const undoaction: ActionObject = {
    delta: getAntiDelta(delta, block),
    type: BlockActionTypes.update,
    id: block.id,
  };
  props.undoObject.actions.push(undoaction);

  handleUpdateAction(updateAction, true);
};

export const primitiveChangeBlockType = (props: {
  blockId: string;
  newBlockType: LineType;
  context: any;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
  referencingContainerId?: string;
}) => {
  const newState = store.getState().blocks;
  let block = getBlockById(newState.dict, props.blockId);
  if (
    entityKeepTypes.includes(block.lineType) &&
    block.lineType !== LineType.image
  )
    return;

  if (block.lineType !== props.newBlockType) {
    const delta: Partial<Block> = {
      dateModified: new Date(),
      modifiedBy: prevState.value.user?.id,
      lineType: props.newBlockType,
      referencingContainerId: undefined,
      referencingContainerType: undefined,
    };

    if ([LineType.work].includes(block.lineType)) {
      delta.value = [];
    }

    if (props.newBlockType === LineType.widget) {
      if (!props.referencingContainerId) return;
      delta.referencingContainerId = props.referencingContainerId;
    }

    const updateAction: ActionObject = {
      id: block.id,
      type: BlockActionTypes.update,
      delta,
    };
    props.saveActions.push(updateAction);

    const undoaction: ActionObject = {
      delta: getAntiDelta(delta, block),
      type: BlockActionTypes.update,
      id: block.id,
    };
    props.undoObject.actions.push(undoaction);

    handleUpdateAction(updateAction, true);
  }
};
export const primitiveChangeLineBlockType = (props: {
  blockId: string;
  newBlockType: LineType;
  context: any;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
  referencingContainerId?: string;
}) => {
  const newState = store.getState().blocks;
  let block = getBlockById(newState.dict, props.blockId);

  if (block.lineType !== props.newBlockType) {
    const delta: Partial<Block> = {
      dateModified: new Date(),
      modifiedBy: prevState.value.user?.id,
      lineType: props.newBlockType,
      referencingContainerId: undefined,
      referencingContainerType: undefined,
    };

    if ([LineType.work].includes(block.lineType)) {
      delta.value = [];
    }

    if (props.newBlockType === LineType.widget) {
      if (!props.referencingContainerId) return;
      delta.referencingContainerId = props.referencingContainerId;
    }

    const updateAction: ActionObject = {
      id: block.id,
      type: BlockActionTypes.update,
      delta,
    };
    props.saveActions.push(updateAction);

    const undoaction: ActionObject = {
      delta: getAntiDelta(delta, block),
      type: BlockActionTypes.update,
      id: block.id,
    };
    props.undoObject.actions.push(undoaction);

    handleUpdateAction(updateAction, true);
  }
};

export const primitiveUpdateBlockText = (props: {
  blockId: string;
  newBlockText?: ILineValue[];
  blockRef?: React.MutableRefObject<HTMLDivElement | null>;
  context: any;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
}) => {
  if (!props.newBlockText && !props.blockRef) return;
  const newState = store.getState().blocks;
  let block = getBlockById(newState.dict, props.blockId);
  if (entityKeepTypes.includes(block.lineType)) return;
  let newValue: ILineValue[] = props.newBlockText ? props.newBlockText : [];
  if (!props.newBlockText) {
    newValue = [];
    if (props.blockRef?.current) {
      props.blockRef.current.childNodes.forEach((child) => {
        newValue.push(breakDownHtml(child));
      });
    }
  }
  const delta: Partial<Block> = {
    dateModified: new Date(),
    modifiedBy: prevState.value.user?.id,
    value: newValue,
  };
  const updateAction: ActionObject = {
    id: block.id,
    type: BlockActionTypes.update,
    delta,
  };
  props.saveActions.push(updateAction);

  const undoaction: ActionObject = {
    delta: getAntiDelta(delta, block),
    type: BlockActionTypes.update,
    id: block.id,
  };
  props.undoObject.actions.push(undoaction);

  handleUpdateAction(updateAction, true);
};

export const indentSingleBlock = (props: {
  blockId: string;
  context: any;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
}) => {
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, props.blockId);
  const currentContext = getCurrentContext(newState, props.context.id);
  const prevSiblingId = getPreviousSibling(newState, block, currentContext);
  if (!prevSiblingId) return;
  const prevSibling = getBlockById(newState.dict, prevSiblingId);
  if (!prevSibling.id) return;
  if (prevSibling.children.length > 0) {
    const referenceId = prevSibling.children[prevSibling.children.length - 1];
    moveBlocks({
      type: "after",
      options: "sibling",
      referenceId,
      selectedBlocks: [block.id],
      undoObject: props.undoObject,
      saveActions: props.saveActions,
    });
  } else {
    const referenceId = prevSibling.id;
    moveBlocks({
      type: "after",
      options: "child",
      referenceId,
      selectedBlocks: [block.id],
      undoObject: props.undoObject,
      saveActions: props.saveActions,
    });
  }
};

export const updateSingleBlock = (props: {
  blockId: string;
  delta: Partial<Block>;
  undoObject: ActionWrapperObject;
  saveActions: ActionObject[];
  context: any;
  newState?: BlockReducerState;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { blockId, delta, undoObject, saveActions, context } = {
    ...props,
  };
  const newState = props.newState ? props.newState : store.getState().blocks;
  let block = getBlockById(newState.dict, blockId);
  const undoaction: ActionObject = {
    delta: getAntiDelta(delta, block),
    type: BlockActionTypes.update,
    id: block.id,
  };
  undoObject.actions.push(undoaction);

  const updateAction: ActionObject = {
    id: block.id,
    type: BlockActionTypes.update,
    delta,
  };

  saveActions.push(updateAction);

  handleUpdateAction(updateAction);
};

export const handleUpdateAction = (
  action: ActionObject,
  local?: boolean,
  skipSpecificDelta?: boolean,
  options?: {
    socketUpdate: boolean;
  }
) => {
  const newState = store.getState().blocks;
  const currentBlock = getBlockById(newState.dict, action.id);
  if (currentBlock.id) {
    batch(() => {
      let found: any = false;
      if (!skipSpecificDelta) {
        found = checkForSpecificDelta(
          action.delta,
          action.id,
          currentBlock,
          options
        );
      }

      if (!found) {
        store.dispatch({
          type: actionTypes.SAVE_BLOCK_DATA,
          param: {
            id: currentBlock.id,
            blockData: currentBlock,
            delta: action.delta,
          },
        });
      }
    });
    if (
      action.delta.value &&
      !local &&
      !entityKeepTypes.includes(currentBlock.lineType)
    ) {
      const focusedId = document.activeElement?.getAttribute("data-block-id");
      if (focusedId === action.id && document.activeElement) {
        document.activeElement.innerHTML = getHtml(action.delta.value);
        moveCaretToPreviousPosition(
          document.activeElement,
          $focusOn.value.caretPosition ? $focusOn.value.caretPosition : 0
        );
      }
    }
  } else {
    store.dispatch({
      type: actionTypes.SET_BLOCK_IN_DICT,
      block: action.delta,
    });
  }
};

export const checkForSpecificDelta = (
  delta: Partial<Block>,
  id: string,
  currentBlock: Block,
  options?: {
    socketUpdate: boolean;
  }
) => {
  if (
    delta.documentRank ||
    delta.containerId ||
    delta.parentId ||
    delta.parentId === ""
  ) {
    const fullBlock = { ...currentBlock, ...delta };

    if (delta.parentId || delta.parentId === "" || delta.containerId) {
      deleteBlockMiddleware(id);
      checkBlockRecover(fullBlock);
    } else {
      const newState = store.getState().blocks;
      const context = getCurrentContext(
        newState,
        fullBlock.containerType + fullBlock.containerId
      );
      if (context && context.id) {
        const parent = getBlockById(newState.dict, fullBlock.parentId);
        store.dispatch({
          type: actionTypes.SAVE_BLOCK_DATA,
          param: {
            id: fullBlock.id,
            blockData: fullBlock,
            delta,
          },
        });
        store.dispatch({
          type: actionTypes.FIX_RANK_ORDER,
          param: {
            indentLevel: fullBlock.indentLevel,
            context,
            referenceBlock: parent,
          },
        });
      }
    }

    return true;
  }
  if (delta.value) {
    const newState = store.getState().blocks;
    const prevBlock = getBlockById(newState.dict, id);
    valueChecker(delta.value, prevBlock.value, id, {
      socketUpdate: options?.socketUpdate,
    });
  }
  if ("subLinesList" in delta) {
    const fullBlock = { ...currentBlock, ...delta };
    if (fullBlock.lineType === LineType.Title) {
      const newOutlineMode = fullBlock.subLinesList
        ? fullBlock.subLinesList
        : "noOutline";
      store.dispatch({
        type: actionTypes.UPDATE_CONTEXT,
        param: {
          id: fullBlock.containerType + fullBlock.containerId,
          delta: {
            outlineMode: newOutlineMode,
          },
        },
      });
      if (!options?.socketUpdate) {
        persistContainerOutline({
          containerType: fullBlock.containerType,
          containerId: fullBlock.containerId,
          newOutlineMode,
        });
      }
      const container = getContainerThroughType(
        fullBlock.containerId,
        fullBlock.containerType
      );
      if (container) {
        updateOutlineModeInState({
          containerType: fullBlock.containerType,
          containerId: fullBlock.containerId,
          newOutlineMode,
          container,
        });
      }
    }
  }
};

export const updateBlockAndHandleSideEffects = (action: ActionObject) => {
  if (action.type === BlockActionTypes.update) {
    handleUpdateAction(action);
  }
  if (action.type === BlockActionTypes.delete) {
    deleteBlockMiddleware(action.id);
  }
  if (action.type === BlockActionTypes.insert) {
    const newState = store.getState().blocks;
    let block = getBlockById(newState.dict, action.id);
    block = { ...block, ...action.delta };
    if (block.id) {
      checkBlockRecover(block);
    }
  }
};

export const getAntiDelta = (delta: Partial<Block>, block: Block) => {
  const antiDelta: Partial<Block> = {};
  for (const key of keys(delta as Partial<Block>)) {
    if (["dateCreated", "dateModified", "textDateModified"].includes(key))
      continue;
    if (key === "isFolded") {
      if (!block[key]) {
        antiDelta[key] = false;
        continue;
      }
    }
    if (block[key] || block[key] === "" || block[key] === 0) {
      antiDelta[key] = JSON.parse(JSON.stringify(block[key]));
    } else antiDelta[key] = null;
  }
  return antiDelta;
};
