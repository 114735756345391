import { userApi } from "clientApi/userApi";
import Button, { ButtonTypes } from "components/Button";
import ClarityInput from "components/ClarityInput";
import ClarityTextArea from "components/ClarityTextArea";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { BucketAlbums } from "utilities/types";
import styles from "./profile/profile.module.scss";
import short from "short-uuid";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import notificationsApi from "clientApi/notificationsApi";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import { axiosInstance } from "index";

const Profile: React.FC = () => {
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);
  const [innerUser, setinnerUser] = useState({
    avatar: user?.avatar ? user.avatar : "",
    name: user?.name ? user.name : "",
    bio: user?.bio ? user.bio : "",
  });

  useEffect(() => {
    setinnerUser({
      avatar: user?.avatar ? user.avatar : "",
      name: user?.name ? user.name : "",
      bio: user?.bio ? user.bio : "",
    });
  }, [user]);

  const updateBio = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setinnerUser({ ...innerUser, bio: value });
  };

  const updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setinnerUser({ ...innerUser, name: value });
  };

  const updateAvatar = (avatar: string) => {
    setinnerUser({ ...innerUser, avatar });
  };

  return (
    <div className={styles.container}>
      <ProfileImage avatar={innerUser.avatar} updateAvatar={updateAvatar} />
      <ClarityInput
        value={innerUser.name}
        onChange={updateName}
        label="Display Name"
        placeholder="What’s your name (or alias)?"
      />
      <div>
        <ClarityTextArea
          value={innerUser.bio}
          className=""
          label="Bio"
          onKeyDown={(e) => e.stopPropagation()}
          onChange={updateBio}
          maxLength={200}
          rows={4}
        />
        {innerUser.bio.length === 200 && (
          <ClarityLabel
            type={MessageLabelTypes.info}
            message="Bios are limited to 200 characters"
          />
        )}
      </div>

      <div>
        <Button
          style={{ width: "154px" }}
          buttonType={ButtonTypes.LARGE_PRIMARY}
          onClick={() =>
            userApi.persistChanges(innerUser).then((res) => {
              notificationsApi.displayConfirmation({
                title: <span>{res.data.info}</span>,
              });
            })
          }
          disabled={
            innerUser.bio === (user?.bio ? user.bio : "") &&
            innerUser.name === (user ? user.name : "") &&
            innerUser.avatar === (user ? user.avatar : "")
          }
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};

const ProfileImage: React.FC<{
  avatar: string;
  updateAvatar: (avatar: string) => void;
}> = ({ avatar, updateAvatar }) => {
  const imageContainer = useRef<HTMLDivElement | null>(null);
  const inputAvatarUpload = useRef<HTMLInputElement | null>(null);
  const [isAvatarUploadWaiting, setIsAvatarUploadWaiting] = useState(false);

  const handleUploadImageClick = (input: any) => {
    input.current.click();
  };

  const handleUploadImageChange = (input: any, imageContainer: any) => {
    const image = input.current.files[0];
    if (image.size / 1024 / 1024 >= 5) {
      alert("Please upload the image less than 5MB");
      return;
    }
    const splitFilename = image.name.split(".");
    setIsAvatarUploadWaiting(true);
    const fileExtension = splitFilename[splitFilename.length - 1];

    const photoKey = `${
      BucketAlbums.AVATAR
    }/${short.generate()}.${fileExtension}`;
    imageContainer.current.style.backgroundImage = `url(${URL.createObjectURL(
      image
    )})`;

    axiosInstance
      .post(`/api/auth/getSignedUrl`, { key: photoKey })
      .then((res) => {
        const signedUrl = res.data.uploadUrl;
        const authorizationHeader =
          axiosInstance.defaults.headers.common["Authorization"];
        delete axiosInstance.defaults.headers.common["Authorization"];
        axiosInstance
          .put(signedUrl, image, {
            headers: {
              "content-type": "image/*",
            },
          })
          .then((res) => {
            const hostedUrl = signedUrl.split("?")[0];
            updateAvatar(hostedUrl);
            axiosInstance.defaults.headers.common.Authorization =
              authorizationHeader;
            setIsAvatarUploadWaiting(false);
          });
      });
  };

  return (
    <div className={styles.Image}>
      <div
        className={`${styles.profileImage} ${
          isAvatarUploadWaiting ? styles.profileUploading : ""
        } `}
        style={{ backgroundImage: `url(${avatar})` }}
        ref={imageContainer}
      >
        <div
          className={styles.overlay}
          onClick={() => handleUploadImageClick(inputAvatarUpload)}
        >
          {isAvatarUploadWaiting ? (
            <LoadingOutlined />
          ) : (
            <CloudUploadOutlined color="#fff" />
          )}
        </div>
      </div>

      <input
        onChange={() =>
          handleUploadImageChange(inputAvatarUpload, imageContainer)
        }
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={inputAvatarUpload}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default Profile;
