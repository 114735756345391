// Vendor Libraries
import { connect, batch, useSelector, shallowEqual } from "react-redux";
import Helmet from "react-helmet";
import {
  useState,
  memo,
  useRef,
  useLayoutEffect,
  useMemo,
  useEffect,
} from "react";

// Internal Modules
import * as actionTypes from "store/actions";
import {
  DocumentModes,
  IUserObj,
  ContainerTypes,
  UserEventTypes,
  IBlockContext,
  WorkTypes,
} from "utilities/types";
import { stripHtml } from "utilities/stringUtilities";
import { getRelatedEntities } from "modules/documentService";
import { WorkEntry } from "store/reducers/workReducer";
import { ChunkDestination } from "utilities/stateTypes";
import { loadContainerData } from "editor/utils/containerActions/loadContainerActions";
import { Block } from "store/reducers/blockReducer";
import { $focusOn, ClarityStore } from "store/storeExporter";
import { clearBlockSelection } from "editor/utils/blockActions";
import { mixpanel } from "App";
import EditorContainer from "editor/EditorContainer";
import TaskEditor from "editor/TaskEditor";
import ContainerTitleBlockWrapper from "editor/blockContainer/ContainerTitleBlockWrapper";
import { useContainerContextSetter } from "editor/utils/customHooks";
import { getHtml } from "editor/utils/blockValueHelpers";
import ContainerDetailMetadata from "clarity-ui/ContainerDetailMetadata";
import { groupApi } from "clientApi/groupsApi";

interface IProjectComponentProps {
  workspace: any;
  containerId: string;
  loading: boolean;
  changeDocumentMode: (param: any) => any;
  sharedView: boolean;
  connected: boolean;
  paneId: ChunkDestination;
  outlineMode: string;
}

interface IMapStateToProps {
  isBaseMember: any;
  user: IUserObj | undefined;
  assignee: IUserObj;
  projectComments: [];
  newDiscussionDetail: any;
  workDict: WorkEntry;
  workType?: WorkTypes;
}

function Project(props: IProjectComponentProps & IMapStateToProps) {
  const documentRef = useRef<HTMLDivElement | null>(null);
  const prevId = useRef("");
  const activeId = useRef(props.containerId);
  activeId.current = props.containerId;
  const titleBlock = useRef<Block | null>(null);

  const userRole = useSelector(
    (state: ClarityStore) => state.client.roleType,
    shallowEqual
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [projectContext, setProjectContext] = useState<IBlockContext>({
    id: ContainerTypes.PROJECT + props.containerId,
    type: "container",
    container: {
      id: props.containerId,
      type: ContainerTypes.PROJECT,
      outlineMode: props.outlineMode ? props.outlineMode : "noOutline",
    },
    ref: documentRef,
    paneId: props.paneId,
    persistToggle: props.connected && props.isBaseMember,
    autosave: props.connected && props.isBaseMember,
    online: true,
    canComment: props.connected && props.isBaseMember,
    canEdit: props.connected && props.isBaseMember,
  });

  useContainerContextSetter(projectContext, setProjectContext);

  useLayoutEffect(() => {
    if (
      (activeId && activeId.current !== prevId.current && props.workDict) ||
      props.connected
    ) {
      if (activeId.current === "new") return;
      let project = props.workDict ? props.workDict[activeId.current] : null;
      if (project && project.id && project.id !== "") {
        props.changeDocumentMode(DocumentModes.INSERT);
        loadContainerData({
          containerId: project.id,
          containerType: ContainerTypes.PROJECT,
          titleBlockId: project.titleBlockId ? project.titleBlockId : "",
          tagsBlockId: project.tagsBlockId ? project.tagsBlockId : undefined,
          titleBlock,
          outlineMode: project.outlineMode,
          isPublicAccess: project.isPublicAccess,
        }).then(() => {
          batch(() => {
            if (
              (!props.isBaseMember && project) ||
              (project &&
                !groupApi.checkIfGroupMember(
                  project?.groupIds ?? [project?.groupId]
                ))
            ) {
              if (project?.id) {
                getRelatedEntities(project.id, ContainerTypes.PROJECT);
              }
            }
          });
        });

        mixpanel.track(UserEventTypes.PROJECT_VIEWED, {
          distinct_id: props.user?.id,
        });
      }
    }
    prevId.current = activeId.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerId, props.connected]);

  useEffect(() => {
    return () => {
      if (
        $focusOn.value.focusContext?.id === projectContext.id &&
        $focusOn.value.focusPane === projectContext.paneId
      ) {
        $focusOn.next({ ...$focusOn.value, focusBlockId: undefined });
        clearBlockSelection();
      }
    };
  }, []);

  return useMemo(() => {
    if (
      !props.workDict ||
      !activeId.current ||
      !props.workDict[props.containerId]?.id
    ) {
      return <></>;
    } else {
      return (
        <>
          <div className="document-wrapper">
            <div className="document-container">
              {props.workDict && props.paneId === ChunkDestination.primary && (
                <Helmet>
                  <title>{`${stripHtml(
                    getHtml(props.workDict[props.containerId]?.nameValue)
                  )} • ${props.workspace.name}`}</title>
                </Helmet>
              )}

              <div
                className="document projectDetail select-area"
                ref={documentRef}
              >
                <div className="container-860">
                  <div className="titleBlockContainer workDetail">
                    <ContainerTitleBlockWrapper
                      containerId={props.containerId}
                      containerType={ContainerTypes.PROJECT}
                      context={projectContext}
                      isBaseMember={props.isBaseMember}
                      paneId={props.paneId}
                    />
                  </div>
                  <TaskEditor
                    noHead
                    context={projectContext}
                    sharedView={props.sharedView}
                    userRole={userRole}
                    id={props.containerId}
                    outlineMode={props.outlineMode}
                  >
                    <EditorContainer
                      context={projectContext}
                      isBaseMember={props.isBaseMember}
                      outlineMode={props.outlineMode}
                    />
                  </TaskEditor>
                </div>
              </div>
              <div className="taskSidebar">
                <ContainerDetailMetadata
                  key={props.containerId}
                  containerId={props.containerId}
                  containerType={ContainerTypes.PROJECT}
                  paneId={ChunkDestination.peek}
                  rowMode={false}
                  noPersist={!projectContext.canEdit}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.containerId,
    props.outlineMode,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.workDict?.[props.containerId],
    projectContext,
  ]);
}

const mapStateToProps = (state: ClarityStore, ownProps: any) => {
  let docState = {
    workspace: state.workspace,
    workDict: state.work.dict,
    user: state.user,
    connected: state.network.connected,
    isBaseMember: state.client.isBaseMember,
    outlineMode:
      state.blocks.contexts[ContainerTypes.PROJECT + ownProps.containerId]
        ?.outlineMode,
  };
  return docState;
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    changeDocumentMode: (param: any) =>
      dispatch({
        type: actionTypes.CHANGE_DOCUMENT_MODE,
        param,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Project));
