import { BlockActionTypes } from "editor/utils/blockActions";
import { breakDownHtml } from "editor/utils/blockValueHelpers";
import {
  localChangesTransaction,
  resetLocalChanges,
  SnapshotData,
} from "editor/utils/specificActions/persistActions";
import { ActionWrapperObject } from "editor/utils/specificActions/undoUtils";
import { createNewBlock } from "modules/entityService";
import React from "react";
import { BehaviorSubject } from "rxjs";
import { $focusOn, prevState } from "store/storeExporter";
import { LineType, ILineValue, LineStatus } from "utilities/lineUtilities";
import { ContainerTypes, DocumentModes, IBlockContext } from "utilities/types";
import * as actionTypes from "../actions";
import { addBlock } from "./blockReducerHeplers/addBlockHelper";
import { deleteBlock } from "./blockReducerHeplers/blockDelete";
import {
  checkSelectingDownCase,
  checkSelectingUpCase,
  executeUnselectBlocksAction,
  selectBlock,
  unselectBlock,
} from "./blockReducerHeplers/blockSelectionHelper";
import {
  checkIfAncestorBlockSelected,
  checkPreviousBlockIsTitle,
  getBlockAncestors,
  getBlockById,
  getCurrentContext,
  getNextBlockId,
  getPreviousBlock,
  updateCurrentBlock,
  updateCurrentContext,
  updateFocus,
  UPDATE_TYPES,
} from "./blockReducerHeplers/generalBlockHelpers";
import {
  indentBlock,
  outdentBlock,
} from "./blockReducerHeplers/indentBlockHelper";

export interface BlocksState {
  dict: BlockObject;
}

export interface BlockObject {
  [id: string]: Block;
}

interface ContextObject {
  [id: string]: ContainerContext;
}

interface ContextState {
  rootBlocksIds: string[];
  selectedBlocks: string[];
  workItems: string[];
  undoStack: any[];
  redoStack: any[];
}

export interface ContainerContext {
  id: string; // containerType+containerId
  containerId: string;
  containerType: ContainerTypes;
  state: ContextState;
  outlineMode: string;
  activeListeners?: any;
  isFullContainerLoaded: boolean;
}
export interface BlockData {
  id: string;
  lineType: LineType;
  value: ILineValue[];
  checkboxStatus?: LineStatus;
  referencingContainerId?: string;
  referencingContainerType?: ContainerTypes;
  parentId: string;
  indentLevel: number;
  documentRank?: string;
  containerId: string;
  containerType: ContainerTypes;
  dateCreated: Date;
  dateModified: Date;
  textDateModified: Date;
  subLinesList: LineType | "noOutline";
  isFolded: boolean;
  baseId: string;
  createdBy: string;
  modifiedBy: string;
  textModifiedBy: string;
  relatedEntities: Partial<BlockRelatedEntities>;
}

export interface Block extends BlockData {
  ref?: any;
  ancestors?: string[];
  citations?: string[];
  selected?: boolean;
  frozen?: boolean;
  frozenBy?: any;
  options?: any;
  children: string[];
  blockSubject: BehaviorSubject<Partial<Block> | Block>;
}

export interface PartialBlock {
  id?: string;
  lineType?: LineType;
  value?: ILineValue[];
  checkboxStatus?: LineStatus;
  referencingContainerId?: string;
  referencingContainerType?: ContainerTypes;
  parentId?: string;
  indentLevel?: number;
  documentRank?: string;
  containerId?: string;
  containerType?: ContainerTypes;
  dateCreated?: Date;
  dateModified?: Date;
  textDateModified?: Date;
  subLinesList?: LineType;
  isFolded?: boolean;
  baseId?: string;
  createdBy?: string;
  modifiedBy?: string;
  textModifiedBy?: string;
}

export enum FocusType {
  prevPosition = "prevPosition",
  atBlockEnd = "atBlockEnd",
  withDiff = "withDiff",
}

export interface FocusObject {
  focusBlockId?: string;
  focusPane?: string;
  focusContext?: IBlockContext;
  caretPosition?: number;
  caretEndPosition?: number;
  diff?: number;
  type?: FocusType;
  refocusToggle?: boolean;
  fromRefocus?: boolean;
  focusBlockRef?: React.MutableRefObject<HTMLDivElement | Element | null>;
  focusTableId?: string;
  focusNodeId?: string;
}

export interface IRemoteFocus {
  blockId: string | undefined;
  containerId: string;
  baseId: string;
}

export interface BlockReducerState {
  dict: BlockObject;
  contexts: ContextObject;
  focusOn: FocusObject;
  documentMode: DocumentModes;
  selectedBlocks: string[];
  resourceToUpload: any;
}

interface InlineDiscussion {
  id: string;
  partecipants: string[];
  lastUpdated: Date;
  replyCount: number;
}

export interface BlockRelatedEntities {
  discussion: InlineDiscussion;
  sourceTags: any;
  sourceCitations: any;
}

const initialState = {
  dict: {} as BlockObject,
  contexts: {} as ContextObject,
  focusOn: {} as FocusObject,
  documentMode: DocumentModes.INSERT,
  selectedBlocks: [],
  resourceToUpload: {},
};

const createAndSetContext = (
  newState: BlockReducerState,
  blockContext: IBlockContext,
  localReset?: boolean
) => {
  const newContext: ContainerContext = {
    containerId: blockContext.container.id,
    containerType: blockContext.container.type,
    id: blockContext.id,
    outlineMode: "noOutline",
    state: {
      rootBlocksIds: [],
      selectedBlocks: [],
      workItems: [],
      undoStack: [],
      redoStack: [],
    },
    activeListeners: [],
    isFullContainerLoaded:
      blockContext.container.id === "newComment" ? true : false,
  };
  const newBlock = createNewBlock(
    {},
    {
      containerId: newContext.containerId,
      containerType: newContext.containerType,
      baseId: prevState.value.workspace.id,
    }
  );
  if (localReset || !blockContext.autosave) {
    if (!localChangesTransaction[newContext.id])
      localChangesTransaction[newContext.id] = {};
    localChangesTransaction[newContext.id][newBlock.id] = {
      id: newBlock.id,
      type: BlockActionTypes.insert,
      delta: newBlock,
      options: {
        newBlock: true,
      },
    };
  }
  const fullBlock: Block = {
    ...newBlock,
    blockSubject: new BehaviorSubject<Partial<Block>>(newBlock),
    children: [],
  };
  fullBlock.blockSubject.next(fullBlock);
  newContext.state.rootBlocksIds.push(fullBlock.id);
  newState.dict[fullBlock.id] = fullBlock;
  newState.contexts[newContext.id] = newContext;
};

const blockReducer = (state: BlockReducerState = initialState, action: any) => {
  switch (action.type) {
    case "LOAD_WORK_ACTIVITY_BLOCKS": {
      const newState = { ...state };
      const context = getCurrentContext(newState, action.param.context.id);
      if (!context.id || context.state.rootBlocksIds.length === 0) {
        const newState: BlockReducerState = {
          ...state,
          contexts: state.contexts,
          dict: state.dict,
        };
        createAndSetContext(newState, action.param.context, true);
        return newState;
      }
      return state;
    }

    case "RESET_WORK_ACTIVITY": {
      const newState: BlockReducerState = {
        ...state,
        contexts: state.contexts,
        dict: state.dict,
      };
      const context: IBlockContext = {
        id: ContainerTypes.WORK_ACTIVITY + "newComment",
        container: {
          id: "newComment",
          type: ContainerTypes.WORK_ACTIVITY,
        },
      } as IBlockContext;
      resetLocalChanges();
      createAndSetContext(newState, context, true);

      return newState;
    }

    case actionTypes.RESET_CONTEXT_SNAPSHOT: {
      const newState = { ...state, dict: { ...state.dict } };
      const param: SnapshotData = action.param;
      const contextId = param.contextId;
      const currentContext = getCurrentContext(newState, contextId);
      currentContext.state = { ...currentContext.state };
      currentContext.state = {
        redoStack: [],
        workItems: [],
        rootBlocksIds: param.rootBlocksIds ? param.rootBlocksIds : [],
        selectedBlocks: [],
        undoStack: [],
      };
      if (param.innerDict) {
        Object.values(param.innerDict).forEach((block) => {
          updateCurrentBlock(newState, block, UPDATE_TYPES.update);
        });
      }
      return newState;
    }

    case actionTypes.SET_SINGLE_BLOCK: {
      const newState = { ...state, dict: { ...state.dict } };
      const blockData = action.param.blockData;
      const fullBlock = { ...blockData, children: [] };
      newState.dict[blockData.id] = fullBlock;
      if (!newState.dict[fullBlock.id].blockSubject)
        newState.dict[fullBlock.id].blockSubject = new BehaviorSubject<
          Partial<Block>
        >({});
      updateCurrentBlock(
        newState,
        newState.dict[fullBlock.id],
        UPDATE_TYPES.update
      );
      return newState;
    }

    case actionTypes.SET_BLOCK_IN_DICT: {
      const newState = { ...state, dict: { ...state.dict } };
      const block = action.block;
      if (!block.children) block.children = [];
      if (!block.parentId) block.parentId = "";
      newState.dict[block.id] = block;
      if (!newState.dict[block.id].blockSubject)
        newState.dict[block.id].blockSubject = new BehaviorSubject<
          Partial<Block>
        >({});

      const contextId = block.containerType + block.containerId;
      if (newState.contexts[contextId] && newState.contexts[contextId].id) {
        newState.contexts[contextId] = {
          ...newState.contexts[contextId],
        };
      } else {
        newState.contexts[contextId] = {
          containerId: block.containerId,
          containerType: block.containerType,
          outlineMode: "noOutline",
          state: {
            rootBlocksIds: [],
            workItems: [],
            selectedBlocks: [],
            undoStack: [],
            redoStack: [],
          },
          id: contextId,
          isFullContainerLoaded: false,
        };
      }

      updateCurrentContext(newState, newState.contexts[contextId]);

      updateCurrentBlock(
        newState,
        newState.dict[block.id],
        UPDATE_TYPES.update
      );

      return newState;
    }

    case actionTypes.TRIGGER_BLOCK_UPDATE: {
      const newState = { ...state, dict: { ...state.dict } };
      const block = { ...getBlockById(newState.dict, action.param.id) };
      if (!block.id) return state;
      updateCurrentBlock(newState, block, UPDATE_TYPES.update);
      return newState;
    }

    case actionTypes.SET_BLOCKS_FROM_CONTAINER: {
      const newState = {
        ...state,
        dict: { ...state.dict },
        contexts: { ...state.contexts },
        focusOn: {} as FocusObject,
      };
      const contextId =
        action.param.container.containerType +
        action.param.container.containerId;

      newState.contexts[contextId] = {
        containerId: action.param.container.containerId,
        containerType: action.param.container.containerType,
        outlineMode: action.param.container.outlineMode
          ? action.param.container.outlineMode
          : "noOutline",
        state: {
          rootBlocksIds: action.param.rootLinesId,
          workItems: [],
          selectedBlocks: [],
          undoStack: [],
          redoStack: [],
        },
        id: contextId,
        isFullContainerLoaded: action.param.notFullyLoad
          ? Boolean(state.contexts[contextId])
          : true,
      };

      updateCurrentContext(newState, newState.contexts[contextId]);

      const loadedBlocks = Object.values(action.param.blocks);

      loadedBlocks.forEach((loadedBlock: any) => {
        if (loadedBlock.id) {
          if (!loadedBlock.children) loadedBlock.children = [];
          if (!loadedBlock.parentId) loadedBlock.parentId = "";
          const loadedId: string = loadedBlock.id;
          newState.dict[loadedId] = { ...state.dict[loadedId], ...loadedBlock };
          newState.dict[loadedId].ancestors = getBlockAncestors(
            newState.dict,
            newState.dict[loadedId]
          );
          if (!newState.dict[loadedId].blockSubject)
            newState.dict[loadedId].blockSubject = new BehaviorSubject<
              Partial<Block>
            >({});
          updateCurrentBlock(
            newState,
            newState.dict[loadedId],
            UPDATE_TYPES.update
          );
        }
      });

      return newState;
    }

    case actionTypes.SET_BLOCKS_ARRAY_FROM_CONTAINER: {
      const newState = {
        ...state,
        dict: { ...state.dict },
        contexts: { ...state.contexts },
        focusOn: {} as FocusObject,
      };

      const contextId =
        action.param.container.containerType +
        action.param.container.containerId;

      newState.contexts[contextId] = {
        containerId: action.param.container.containerId,
        containerType: action.param.container.containerType,
        outlineMode: action.param.container.outlineMode
          ? action.param.container.outlineMode
          : "noOutline",
        state: {
          rootBlocksIds: [],
          workItems: [],
          selectedBlocks: [],
          undoStack: [],
          redoStack: [],
        },
        id: contextId,
        isFullContainerLoaded: action.param.notFullyLoad
          ? Boolean(state.contexts[contextId])
          : true,
      };
      const context = newState.contexts[contextId];

      const loadedBlocks = action.param.blocks;
      const rootBlocksIds: string[] = [];

      loadedBlocks.forEach((loadedBlock: any) => {
        if (loadedBlock.id) {
          if (!loadedBlock.children) loadedBlock.children = [];
          if (!loadedBlock.parentId || loadedBlock.parentId === "") {
            loadedBlock.parentId = "";
            rootBlocksIds.push(loadedBlock.id);
          }
          const loadedId: string = loadedBlock.id;
          newState.dict[loadedId] = { ...state.dict[loadedId], ...loadedBlock };
          newState.dict[loadedId].ancestors = getBlockAncestors(
            newState.dict,
            newState.dict[loadedId]
          );
          if (!newState.dict[loadedId].blockSubject)
            newState.dict[loadedId].blockSubject = new BehaviorSubject<
              Partial<Block>
            >({});
          updateCurrentBlock(
            newState,
            newState.dict[loadedId],
            UPDATE_TYPES.update
          );
        }
      });

      context.state.rootBlocksIds = rootBlocksIds;
      updateCurrentContext(newState, newState.contexts[contextId]);

      return newState;
    }

    case actionTypes.CHANGE_DOCUMENT_MODE: {
      const newState = { ...state };
      if (action.param.documentMode)
        newState.documentMode = action.param.documentMode;
      else {
        if (state.documentMode === DocumentModes.INSERT)
          newState.documentMode = DocumentModes.BLOCK;
        else newState.documentMode = DocumentModes.INSERT;
      }

      if (newState.documentMode === DocumentModes.INSERT)
        executeUnselectBlocksAction(newState);
      const currentId = $focusOn.value.focusBlockId;
      if (currentId) {
        newState.dict = { ...newState.dict };
        const block = getBlockById(newState.dict, currentId);
        updateCurrentBlock(newState, block, UPDATE_TYPES.update);
      }
      return newState;
    }

    case actionTypes.SELECT_ALL_BLOCKS: {
      const newState = { ...state };
      const contextId = action.param.contextId;
      if (!contextId) return state;
      newState.documentMode = DocumentModes.BLOCK;
      const currentContext = getCurrentContext(newState, contextId);
      currentContext.state.rootBlocksIds.forEach((blockId) => {
        const block = getBlockById(newState.dict, blockId);
        if (
          block.lineType !== LineType.Title &&
          !(block.containerId === block.referencingContainerId)
        ) {
          selectBlock(newState, blockId, "down");
        }
      });
      const lastBlock =
        newState.selectedBlocks[newState.selectedBlocks.length - 1];
      if (lastBlock) {
        updateFocus(newState, {
          focusBlockId: lastBlock,
        });
      }
      return newState;
    }

    case actionTypes.SELECT_BLOCK: {
      const newState = { ...state, dict: state.dict };
      const mouseSelection = action.param.mouseSelection;
      const block = getBlockById(newState.dict, action.param.id);
      if (mouseSelection) {
        if (block.parentId && block.parentId !== "") {
          const hasAncestorSelected = checkIfAncestorBlockSelected(
            newState,
            block.parentId
          );
          if (hasAncestorSelected) return newState;
        }
      }
      if (block && block.id) {
        selectBlock(newState, block.id, "down", false, mouseSelection);
      }
      newState.focusOn = { ...newState.focusOn };
      newState.focusOn.focusBlockId = block.id;
      updateFocus(newState, {});
      return newState;
    }

    case actionTypes.UNSELECT_BLOCK: {
      const newState = { ...state, dict: state.dict };
      const mouseSelection = action.param.mouseSelection;
      unselectBlock(newState, action.param.id, mouseSelection);
      return newState;
    }

    case actionTypes.SELECT_NEXT_BLOCK: {
      const newState = { ...state, dict: state.dict };
      const blockId = action.param.id;
      const type = action.param.type;
      const context = action.param.context;
      const editingBlock = getBlockById(newState.dict, blockId);

      selectBlock(newState, editingBlock.id, type, true);

      if (type === "up") {
        const isPrevTitle = checkPreviousBlockIsTitle(newState, blockId);
        if (isPrevTitle) {
          return newState;
        }
      }
      const nextPos =
        type === "up"
          ? checkSelectingUpCase(newState, editingBlock.id, context)
          : checkSelectingDownCase(newState, editingBlock.id, context);

      if (nextPos) {
        const idBlock = getBlockById(newState.dict, nextPos);
        const hasAncestorSelected = checkIfAncestorBlockSelected(
          newState,
          idBlock.parentId
        );
        if (!hasAncestorSelected) {
          updateCurrentBlock(newState, idBlock, UPDATE_TYPES.update);
          selectBlock(newState, nextPos, type, false);
        }
        newState.focusOn = { ...newState.focusOn };
        newState.focusOn.focusBlockId = nextPos;
      }
      return newState;
    }

    case actionTypes.UNSELECT_BLOCKS: {
      const newState = { ...state, dict: { ...state.dict } };
      newState.selectedBlocks = [...newState.selectedBlocks];
      const selectedBlocksIds = newState.selectedBlocks;

      if (selectedBlocksIds && selectedBlocksIds.length > 0) {
        const type = action.param.type;
        let nextFocus;
        if (type === "down") {
          nextFocus = selectedBlocksIds[selectedBlocksIds.length - 1];
        } else nextFocus = selectedBlocksIds[0];

        executeUnselectBlocksAction(newState);

        if (nextFocus) {
          const block = getBlockById(newState.dict, nextFocus);
          const currentContext = getCurrentContext(
            newState,
            block.containerType + block.containerId
          );
          let nextId;
          if (type === "down")
            nextId = getNextBlockId(newState, block, currentContext);
          else nextId = getPreviousBlock(newState, block, currentContext);

          if (nextId) {
            const nextBlock = getBlockById(newState.dict, nextId);
            if (nextBlock && nextBlock.id) {
              newState.focusOn = { ...newState.focusOn };
              newState.focusOn.focusBlockId = nextBlock.id;
            }
          }
        }
        newState.selectedBlocks = [];
        return newState;
      }
      return state;
    }

    case actionTypes.CLEAR_BLOCKS_SELECTION: {
      const newState = { ...state, dict: { ...state.dict } };
      newState.selectedBlocks = [...newState.selectedBlocks];
      const selectedBlocksIds = newState.selectedBlocks;
      if (selectedBlocksIds && selectedBlocksIds.length > 0)
        executeUnselectBlocksAction(newState);
      newState.selectedBlocks = [];
      newState.documentMode = DocumentModes.INSERT;
      return newState;
    }

    case actionTypes.SET_UNDO: {
      const newState = { ...state, contexts: { ...state.contexts } };
      const param = action.param;
      const contextId: string = param.contextId;
      newState.contexts[contextId] = {
        ...newState.contexts[contextId],
        state: { ...newState.contexts[contextId].state },
      };
      const contextState = newState.contexts[contextId].state;
      if (!contextState.undoStack) contextState.undoStack = [];
      if (contextState.undoStack.length === 20) {
        contextState.undoStack.splice(0, 1);
      }

      contextState.undoStack.push(param.undoObject);
      if (!param.isRedoAction) contextState.redoStack = [];
      else {
        contextState.redoStack = [...contextState.redoStack];
        contextState.redoStack.pop();
      }
      return newState;
    }

    case actionTypes.SIDE_EFFECT_ADD_FIRST_BLOCK: {
      const newState = { ...state, contexts: { ...state.contexts } };
      const param = action.param;
      const contextId = param.context.id;
      newState.contexts[contextId] = {
        ...newState.contexts[contextId],
        state: { ...newState.contexts[contextId].state },
      };
      const block = param.block;
      const precontext = newState.contexts[contextId];
      if (
        precontext.state.rootBlocksIds.length === 0 ||
        (precontext.containerType === ContainerTypes.NOTE &&
          precontext.state.rootBlocksIds.length === 1)
      ) {
        newState.contexts[contextId].state.rootBlocksIds = [
          ...newState.contexts[contextId].state.rootBlocksIds,
        ];
        newState.contexts[contextId].state.rootBlocksIds.push(block.id);
        newState.dict = { ...newState.dict };
        newState.dict[block.id] = block;
        updateCurrentBlock(newState, block, UPDATE_TYPES.update);
        const context = newState.contexts[contextId];
        const lastAction =
          context.state.undoStack[context.state.undoStack.length - 1];
        lastAction?.actions?.push(param.action);
        return newState;
      } else return state;
    }

    case actionTypes.BLOCK_UNDO: {
      const newState = { ...state, contexts: { ...state.contexts } };
      const param = action.param;
      const contextId: string = param.context.id;
      newState.contexts[contextId] = {
        ...newState.contexts[contextId],
        state: { ...newState.contexts[contextId].state },
      };
      const contextState = newState.contexts[contextId].state;
      const executing: ActionWrapperObject = contextState.undoStack[0];

      // if (newState.selectedBlocks !== executing.selectedBlocks) {
      //   executeUnselectBlocksAction(newState);
      //   newState.selectedBlocks = executing.selectedBlocks;
      //   newState.selectedBlocks.forEach((blockId) => {
      //     selectBlock(newState, blockId, "down");
      //   });
      // }

      newState.documentMode = executing.documentMode;
      contextState.undoStack.pop();
      contextState.redoStack.push(param.redoObject);
      updateFocus(newState, {});
      return newState;
    }

    case actionTypes.SET_NOTE_OBJECTS: {
      return state;
    }

    case actionTypes.SET_MENTION_OBJECTS:
    case actionTypes.SET_BLOCK_OBJ:
    case actionTypes.LOAD_UNLINKED_MENTIONS: {
      const newState = { ...state, dict: { ...state.dict } };
      const blocks = action.param.blocks;
      const blocksArray: any[] = Object.values(blocks);
      blocksArray.forEach((loadedBlock: any) => {
        if (loadedBlock.id) {
          const contextId = loadedBlock.containerType + loadedBlock.containerId;
          if (
            !newState.contexts[contextId] ||
            !newState.contexts[contextId].id
          ) {
            newState.contexts[contextId] = {
              containerId: loadedBlock.containerId,
              containerType: loadedBlock.containerType,
              outlineMode: "noOutline",
              state: {
                rootBlocksIds: [],
                workItems: [],
                selectedBlocks: [],
                undoStack: [],
                redoStack: [],
              },
              id: contextId,
              isFullContainerLoaded: false,
            };
          }

          const loadedId: string = loadedBlock.id;
          if (!loadedBlock.parentId) loadedBlock.parentId = "";
          newState.dict[loadedId] = {
            ...loadedBlock,
            ...newState.dict[loadedId],
          };

          if (!newState.dict[loadedId].blockSubject)
            newState.dict[loadedId].blockSubject = new BehaviorSubject<
              Partial<Block>
            >(newState.dict[loadedId]);
          updateCurrentBlock(
            newState,
            newState.dict[loadedId],
            UPDATE_TYPES.update
          );
        }
      });
      for (const loadedBlock of blocksArray) {
        const block = getBlockById(newState.dict, loadedBlock.id);
        if (block.id) {
          block.ancestors = getBlockAncestors(newState.dict, block);
          updateCurrentBlock(newState, block, UPDATE_TYPES.update);
        }
      }
      return newState;
    }

    case actionTypes.SET_BLOCKS: {
      const newState = { ...state, dict: { ...state.dict } };
      const blocks = action.param.blocks;
      Object.values(blocks).forEach((loadedBlock: any) => {
        if (loadedBlock.id) {
          const loadedId: string = loadedBlock.id;
          if (!loadedBlock.parentId) loadedBlock.parentId = "";
          newState.dict[loadedId] = {
            ...newState.dict[loadedId],
            ...loadedBlock,
          };
          if (!newState.dict[loadedId].blockSubject)
            newState.dict[loadedId].blockSubject = new BehaviorSubject<
              Partial<Block>
            >(newState.dict[loadedId]);

          const contextId = loadedBlock.containerType + loadedBlock.containerId;
          if (
            !newState.contexts[contextId] ||
            !newState.contexts[contextId].id
          ) {
            newState.contexts[contextId] = {
              containerId: loadedBlock.containerId,
              containerType: loadedBlock.containerType,
              outlineMode: "noOutline",
              state: {
                rootBlocksIds: [],
                selectedBlocks: [],
                undoStack: [],
                redoStack: [],
                workItems: [],
              },
              id: contextId,
              isFullContainerLoaded: false,
            };
          }

          updateCurrentBlock(
            newState,
            newState.dict[loadedId],
            UPDATE_TYPES.update
          );
        }
      });
      return newState;
    }

    case actionTypes.SET_NEW_CONTEXT: {
      const newState: BlockReducerState = {
        ...state,
        contexts: state.contexts,
        dict: state.dict,
      };
      createAndSetContext(newState, action.param.blockContext);
      return newState;
    }

    case actionTypes.SET_FOCUS: {
      const newState = { ...state };
      newState.focusOn = {
        ...action.param.newFocus,
      };
      return newState;
    }

    case actionTypes.REFOCUS: {
      const newState = { ...state };
      newState.focusOn = {
        ...action.param.newFocus,
      };
      newState.focusOn.refocusToggle = !state.focusOn.refocusToggle;
      newState.focusOn.fromRefocus = true;
      updateFocus(newState, {});
      return newState;
    }

    case actionTypes.ADD_BLOCK: {
      const newState = { ...state, dict: state.dict };
      const param = action.param;
      addBlock(newState, param);
      return newState;
    }

    case actionTypes.INDENT_BLOCK: {
      const newState = { ...state, dict: state.dict };
      const param = action.param;
      indentBlock(newState, param.id);
      return newState;
    }

    case actionTypes.OUTDENT_BLOCK: {
      const newState = { ...state, dict: state.dict };
      const param = action.param;
      outdentBlock(newState, param.id);
      return newState;
    }

    case actionTypes.DELETE_BLOCK: {
      const newState = { ...state, dict: state.dict };
      const param = action.param;
      deleteBlock(newState, param);
      return newState;
    }

    case actionTypes.BLOCK_UPDATE: {
      const newState = { ...state, dict: { ...state.dict } };
      newState.dict[action.param.id] = { ...state.dict[action.param.id] };
      const block = newState.dict[action.param.id];
      block.value = [];
      action.param.childNodes.forEach((element: any) => {
        block.value.push(breakDownHtml(element));
      });
      block.blockSubject.next(block);
      return newState;
    }

    case actionTypes.SAVE_BLOCK_DATA: {
      const newState = { ...state, dict: { ...state.dict } };
      const block = getBlockById(newState.dict, action.param.id);
      const fullBlock = { ...block, ...action.param.delta };
      updateCurrentBlock(newState, fullBlock, UPDATE_TYPES.update);
      return newState;
    }

    case actionTypes.SAVE_BLOCK_DATA_WITH_SIDE_EFFECTS: {
      const newState = { ...state, dict: { ...state.dict } };
      newState.dict[action.param.id] = { ...state.dict[action.param.id] };
      updateCurrentBlock(
        newState,
        action.param.blockData,
        UPDATE_TYPES.update,
        action.param.delta
      );
      return newState;
    }

    case actionTypes.FIX_RANK_ORDER: {
      const newState = { ...state, dict: { ...state.dict } };
      const param = action.param;
      if (param.indentLevel === 0) {
        const context = getCurrentContext(newState, param.context.id);
        context.state = { ...context.state };
        context.state.rootBlocksIds = [...context.state.rootBlocksIds];
        context.state.rootBlocksIds = context.state.rootBlocksIds.sort(
          (a, b) => {
            const blockA = newState.dict[a];
            const blockB = newState.dict[b];
            const rankA = blockA.documentRank;
            const rankB = blockB.documentRank;
            if (rankA && rankB) {
              return rankA.localeCompare(rankB, "en");
            }
            return 0;
          }
        );
        updateCurrentContext(newState, context);
      } else {
        const block = getBlockById(newState.dict, param.referenceBlock.id);
        block.children = [...block.children];
        block.children = block.children.sort((a, b) => {
          const blockA = newState.dict[a];
          const blockB = newState.dict[b];
          const rankA = blockA.documentRank;
          const rankB = blockB.documentRank;
          if (rankA && rankB) {
            return rankA.localeCompare(rankB, "en");
          }
          return 0;
        });
        updateCurrentBlock(newState, block, UPDATE_TYPES.update);
      }
      return newState;
    }

    case actionTypes.PASS_RESOURCE_TO_UPLOAD: {
      const newState = { ...state };
      const id = action.param.blockId;
      newState.resourceToUpload = {};
      newState.resourceToUpload[id] = {
        type: LineType.image,
        data: action.param,
      };
      return newState;
    }

    case actionTypes.CLEAR_RESOURCE_TO_UPLOAD: {
      const newState = { ...state };
      const id = action.param.blockId;
      newState.resourceToUpload = { ...newState.resourceToUpload };
      newState.resourceToUpload[id] = null;
      return newState;
    }

    case actionTypes.UPDATE_CONTEXT: {
      const newState = { ...state };
      const contextId = action.param.id;
      newState.contexts = { ...newState.contexts };
      const currentContext = getCurrentContext(newState, contextId);
      if (action.param.delta.outlineMode) {
        currentContext.outlineMode = action.param.delta.outlineMode;
      }
      updateCurrentContext(newState, currentContext);
      return newState;
    }

    default:
      return state;
  }
};

export default blockReducer;
