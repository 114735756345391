import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import notificationsApi from "clientApi/notificationsApi";
import { userApi } from "clientApi/userApi";
import Button, { ButtonTypes } from "components/Button";
import ClarityInput from "components/ClarityInput";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { validateEmail } from "utilities/regexUtilities";
import { IMesssageInterface, IUserObj } from "utilities/types";
import { locationSubject } from "./LocationListener";

export enum OtpStatus {
  unsent = "unsent",
  waitingEmail = "waitingEmail",
  sent = "sent",
}

export enum OtpMode {
  EMAIL_VERIFICATION,
  FORGOT_PASSWORD,
}

const Otp: React.FC<{
  fullWidth?: boolean;
  emitter?: React.Dispatch<React.SetStateAction<OtpStatus>>;
  mode?: OtpMode;
  getOtpCTA?: string;
}> = ({ fullWidth, emitter, mode = OtpMode.EMAIL_VERIFICATION, getOtpCTA }) => {
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);
  const [email, setemail] = useState(user ? user.email : "");
  const [messasge, setmessage] = useState<IMesssageInterface | null>(null);
  useEffect(() => {
    setemail(user ? user.email : "");
  }, [user?.email]);

  const [status, setStatus] = useState(OtpStatus.unsent);

  const sendOtp = () => {
    setStatus(OtpStatus.waitingEmail);
    if (mode === OtpMode.EMAIL_VERIFICATION) {
      userApi
        .createEmailChangeOtp(email)
        .then((res) => {
          setmessage({
            info: res.data.info,
            status: res.data.status,
          });
          if (res.data.status === 1) setStatus(OtpStatus.sent);
        })
        .catch((err) => {
          console.log(err);
          setmessage({
            info: "An error ocurred",
            status: 0,
          });
        });
    } else {
      userApi
        .generateOtp(email, { removePassword: true, passwordReset: true })
        .then((res) => {
          setmessage({
            info: res.data.info,
            status: res.data.status,
          });
          setStatus(OtpStatus.sent);
        })
        .catch((err) => {
          console.log(err);
          setmessage({
            info: "An error ocurred",
            status: 0,
          });
        });
    }
  };

  useEffect(() => {
    if (emitter) {
      emitter(status);
    }
  }, [status]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: fullWidth ? "100%" : "50%",
        gap: "20px",
        alignItems: "baseline",
      }}
    >
      <div style={{ width: "100%" }}>
        <ClarityInput
          placeholder="Your email"
          label={mode === OtpMode.FORGOT_PASSWORD ? "Email" : undefined}
          value={email}
          onChange={(e) => {
            setmessage(null);
            setStatus(OtpStatus.unsent);
            setemail(e.target.value);
          }}
        />
        {messasge?.status === 0 && (
          <ClarityLabel
            message={messasge ? messasge.info : ""}
            type={MessageLabelTypes.error}
          />
        )}
      </div>
      {status !== OtpStatus.sent && (
        <>
          <Button
            buttonType={ButtonTypes.LARGE_PRIMARY}
            disabled={
              status === OtpStatus.waitingEmail ||
              !validateEmail(email) ||
              email === user?.email ||
              email.length < 4
            }
            onClick={sendOtp}
            style={{ width: fullWidth ? "100%" : "154px" }}
          >
            {mode === OtpMode.EMAIL_VERIFICATION
              ? getOtpCTA
                ? getOtpCTA
                : "Change email"
              : "Send login code"}
          </Button>
        </>
      )}
      {status === OtpStatus.sent && (
        <OtpVerification
          user={user}
          reset={(message: IMesssageInterface | null) => {
            setmessage(message);
            setStatus(OtpStatus.unsent);
          }}
          sendOtp={sendOtp}
          fullWidth={fullWidth}
          email={email}
          mode={mode}
          getOtpCTA={getOtpCTA}
        />
      )}
    </div>
  );
};

const OtpVerification: React.FC<{
  user: IUserObj | undefined;
  reset: (message: IMesssageInterface | null) => void;
  fullWidth?: boolean;
  mode: OtpMode;
  email: string;
  sendOtp: () => void;
  getOtpCTA?: string;
}> = ({ user, reset, fullWidth, mode, email, sendOtp, getOtpCTA }) => {
  const [otp, setotp] = useState("");
  const [messasge, setmessage] = useState<IMesssageInterface | null>(null);
  const [loading, setloading] = useState(false);

  const checkOtp = async () => {
    setloading(true);
    if (mode === OtpMode.EMAIL_VERIFICATION) {
      await userApi.redeemEmailChangeOtp(otp).then((res) => {
        if (res.data.status === 1) {
          reset(res.data);
          notificationsApi.displayConfirmation({
            title: <span>res.data.info</span>,
          });
        } else {
          setmessage({
            info: res.data.info,
            status: res.data.status,
          });
        }
      });
    } else {
      await userApi
        .loginOtp(email, otp)
        .then((res) => {
          locationSubject.next("/profile/settings?focusid=password-section");
        })
        .catch((e) => {
          setmessage({
            info: "Oops, something went wrong!",
            status: 0,
          });
        });
    }
    setloading(false);
  };

  if (!user && mode === OtpMode.EMAIL_VERIFICATION) return <></>;
  return (
    <>
      <div
        className="body2 secondary"
        style={{
          margin: "4px 0",
          marginLeft: "4px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <span>
          We just sent a single-use code to the email above. Please check your
          inbox.{" "}
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={(e) => {
              e.preventDefault();
              sendOtp();
            }}
          >
            Resend code
          </span>{" "}
          or{" "}
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={(e) => {
              e.stopPropagation();
              reset(null);
            }}
          >
            try another email
          </span>
        </span>
        {mode === OtpMode.FORGOT_PASSWORD && (
          <span>
            When you submit this code below, your password will be removed from
            your account, and you'll be taken to your Settings to create a new
            password.
          </span>
        )}
      </div>
      <div style={{ width: "100%" }}>
        <ClarityInput
          label="Single-use code"
          value={otp}
          onChange={(e) => {
            setmessage(null);
            setotp(e.target.value);
          }}
        />
        {messasge?.status === 0 && (
          <ClarityLabel
            message={messasge ? messasge.info : ""}
            type={MessageLabelTypes.error}
          />
        )}
      </div>
      <Button
        onClick={checkOtp}
        buttonType={ButtonTypes.LARGE_PRIMARY}
        style={{ width: fullWidth ? "100%" : "154px" }}
        disabled={(messasge !== null && messasge.status === 0) || loading}
      >
        {mode === OtpMode.EMAIL_VERIFICATION
          ? getOtpCTA
            ? getOtpCTA
            : "Verify email"
          : "Reset password"}
      </Button>
    </>
  );
};

export default Otp;
