import { baseApi } from "clientApi/baseApi";
import roleApi from "clientApi/rolesApi";
import { tokenGateApi } from "clientApi/tokenGatesApi";
import { getUserNotifications } from "modules/notificationsService";
import { useEffect, useRef } from "react";
import { batch } from "react-redux";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { BaseType, PermissionType, UserRole } from "utilities/types";
import * as actionTypes from "store/actions";
import { axiosInstance } from "index";
import { batchActions } from "redux-batched-actions";
import { snippetsApi } from "clientApi/snippetsApi";
import { templatesApi } from "clientApi/templateApi";

const setIsBaseMember = (isBaseMember: boolean) =>
  store.dispatch({
    type: actionTypes.SET_IS_BASE_MEMBER,
    params: { isBaseMember },
  });

export const useBaseLoader = (
  baseSlug: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>,
  setIsNotFound: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { connected, baseId, user } = useShallowSelector((store) => ({
    connected: store.network.connected,
    baseId: store.workspace.id,
    user: store.user,
  }));

  const previousValue = useRef<string>();
  const shouldLoadBase = useRef<boolean>(false);
  const prevBase = useRef<null | string>(null);
  const reloadNumber = useShallowSelector(
    (store) => store.workspace.reloadNumber
  );

  useEffect(() => {
    if (previousValue.current && connected) {
      shouldLoadBase.current = true;
    } else {
      shouldLoadBase.current = false;
    }
  }, [connected, user?.id]);

  useEffect(() => {
    if (!baseId) return;
    batch(async () => {
      if (shouldLoadBase.current === true) {
        previousValue.current = baseId;
        if (user) getUserNotifications(user.id, baseId);
        const stateClient = store.getState().client;
        const roleType = (stateClient as any).roleType;
        const permissions = (stateClient as any).permissions?.permissions;
        const base = store.getState().workspace;

        if (
          base.type === BaseType.Public &&
          (!user?.id || !roleType || roleType === UserRole.GUEST)
        ) {
          const baseData = baseApi.getCurrentBase();
          if (baseId && baseData.showWeekly) {
            await baseApi.createWeeklyNote(baseId);
          }
          await baseApi.fetchAndSetGroups(baseId);

          const userGroups = store.getState().groups.userGroups;
          const activeGroups = store.getState().groups.activeGroupids;

          const promises = [
            baseApi.fetchAndSetBaseSub(baseId),
            baseApi.fetchAndSetBasePages(baseId),
            baseApi.fetchAndSetBaseCycles(baseId, userGroups),
            baseApi.fetchAndSetBaseTasks(baseId, userGroups),
            baseApi.fetchAndSetBaseProjects(baseId, activeGroups),
            tokenGateApi.getBaseTokenGates(baseId),
            baseApi.fetchAndSetBaseMembers(baseId),
            baseApi.fetchAndSetBaseViews(baseId),
            roleApi.getRolesForBase(baseId),
            templatesApi.getBaseTemplates(baseId),
            snippetsApi.getBaseSnippets(baseId),
          ];

          if (baseData.showWeekly) {
            promises.push(baseApi.fetchAndSetBaseWeeklNotes(baseId));
          }

          await Promise.all([...promises])
            .then(() => {
              batch(() => {
                store.dispatch({
                  type: actionTypes.SET_LAST_VISITED_BASE,
                  param: {
                    baseId,
                  },
                });
                setIsLoading(false);
                setShowSpinner(false);
                setIsNotFound(false);
              });
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          if (roleType === UserRole.GUEST) {
            const promises = [
              baseApi.fetchAndSetUserBaseSettings(baseId),
              baseApi.fetchAndSetGroups(baseId),
              baseApi.fetchAndSetGuestBaseWork(baseId, permissions?.work?.read),
              baseApi.fetchAndSetBaseGuestPages(
                baseId,
                permissions?.pages?.read
              ),
              baseApi.fetchAndSetBaseGuestNotes(
                baseId,
                permissions?.notes?.read
              ),
              baseApi.fetchAndSetBaseMembers(baseId),
              baseApi.fetchAndSetBaseViews(baseId),
              roleApi.getRolesForBase(baseId),
              tokenGateApi.getBaseTokenGates(baseId),
              baseApi.syncRecents(baseId),
            ];

            await Promise.all([...promises])
              .then(() => {
                store.dispatch({
                  type: actionTypes.SET_LAST_VISITED_BASE,
                  param: {
                    baseId: baseId,
                  },
                });
                setIsLoading(false);
                setShowSpinner(false);
                setIsNotFound(false);
              })
              .catch((e) => {});
            await baseApi.fetchAndSetFollowingContainers(baseId);
          } else {
            const baseData = baseApi.getCurrentBase();
            if (baseId && baseData.showWeekly) {
              await baseApi.createWeeklyNote(baseId);
            }
            await baseApi.fetchAndSetGroups(baseId);

            const userGroups = store.getState().groups.userGroups;
            const activeGroups = store.getState().groups.activeGroupids;

            const promises = [
              baseApi.fetchAndSetUserBaseSettings(baseId),
              baseApi.fetchAndSetBaseSub(baseId),
              baseApi.fetchAndSetBasePages(baseId),
              baseApi.fetchAndSetBasePins(baseId),
              baseApi.fetchAndSetBaseFavorites(baseId),
              baseApi.fetchAndSetBaseCycles(baseId, userGroups),
              baseApi.fetchAndSetBaseTasks(baseId, userGroups),
              baseApi.fetchAndSetBaseProjects(baseId, activeGroups),
              baseApi.fetchAndSetBaseMembers(baseId),
              baseApi.fetchAndSetBaseViews(baseId),
              baseApi.fetchAndSetBaseTokens(baseId),
              roleApi.getRolesForBase(baseId),
              templatesApi.getBaseTemplates(baseId),
              snippetsApi.getBaseSnippets(baseId),
              tokenGateApi.getBaseTokenGates(baseId),
              baseApi.syncRecents(baseId),
            ];

            if (baseData.showWeekly) {
              promises.push(baseApi.fetchAndSetBaseWeeklNotes(baseId));
            }

            await Promise.all([...promises])
              .then(() => {
                batch(() => {
                  store.dispatch({
                    type: actionTypes.SET_LAST_VISITED_BASE,
                    param: {
                      baseId,
                    },
                  });
                  setIsLoading(false);
                  setShowSpinner(false);
                  setIsNotFound(false);
                });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        prevBase.current = baseId;
      } else {
        setIsLoading(false);
        setShowSpinner(false);
        setIsNotFound(false);
      }
    });
  }, [baseId, connected, user?.id]);

  useEffect(() => {
    if (!baseSlug) {
      return;
    }
    const baseData = baseApi.getCurrentBase();

    if (baseSlug !== baseData.slug) {
      if (user && user.id) {
        axiosInstance
          .get(`/api/workspace/getOneBySlug/${baseSlug}`)
          .then((res) => {
            const { base } = res.data.payload;
            if (res.data.status === 1) {
              shouldLoadBase.current = true;
              const reduxActions = [];

              reduxActions.push({
                type: actionTypes.SET_USER_PERMISSIONS,
                params: {
                  roleType: base.roleType,
                  permissions: base.permissions,
                  roleIds: base.roleIds,
                },
              });

              reduxActions.push({
                type: actionTypes.SET_IS_BASE_MEMBER,
                params: { isBaseMember: Boolean(base.roleType) },
              });

              reduxActions.push({
                type: actionTypes.SET_USER_GROUPS,
                userGroups: base.userGroups,
              });
              reduxActions.push({
                type: actionTypes.SET_ACTIVE_WORKSPACE,
                workspace: base,
              });

              store.dispatch(batchActions(reduxActions));
              return;
            } else if (res.data.status === 0) {
              const reduxActions = [];
              reduxActions.push({
                type: actionTypes.SET_IS_BASE_MEMBER,
                params: { isBaseMember: false },
              });
              reduxActions.push({
                type: actionTypes.SET_OPEN_BASE_VIEW,
                param: {
                  openBaseView: false,
                },
              });
              reduxActions.push({
                type: actionTypes.SET_USER_GROUPS,
                userGroups: base.userGroups,
              });
              reduxActions.push({
                type: actionTypes.SET_ACTIVE_WORKSPACE,
                workspace: base,
              });

              store.dispatch(batchActions(reduxActions));
              shouldLoadBase.current = false;
              setIsBaseMember(false);
              setIsLoading(false);
              setIsNotFound(false);
            }
          })
          .catch((err) => {
            if (err.response.data.statusCode === 403) {
              shouldLoadBase.current = false;
              setIsBaseMember(false);
              setIsLoading(false);
            } else if (err.response.data.statusCode === 404) {
              shouldLoadBase.current = false;
              setIsBaseMember(false);
              setIsNotFound(true);
              setIsLoading(false);
            }
          });
      } else {
        axiosInstance
          .get(`/api/workspace/getOneBySlug/public/${baseSlug}`)
          .then((res) => {
            const { base } = res.data.payload;
            if (res.data.status === 1) {
              shouldLoadBase.current = true;
              const reduxActions = [];
              reduxActions.push({
                type: actionTypes.SET_USER_PERMISSIONS,
                params: {
                  roleType: base.roleType,
                  permissions: base.permissions,
                  roleIds: base.roleIds,
                },
              });
              reduxActions.push({
                type: actionTypes.SET_IS_BASE_MEMBER,
                params: { isBaseMember: Boolean(base.roleType) },
              });
              reduxActions.push({
                type: actionTypes.SET_ACTIVE_WORKSPACE,
                workspace: base,
              });
              store.dispatch(batchActions(reduxActions));
              // setIsLoading(false);
              setIsNotFound(false);
            } else if (res.data.status === 0) {
              shouldLoadBase.current = false;
              setIsBaseMember(false);
              setShowSpinner(false);
              const reduxActions = [];
              reduxActions.push({
                type: actionTypes.SET_OPEN_BASE_VIEW,
                param: {
                  openBaseView: false,
                },
              });
              reduxActions.push({
                type: actionTypes.SET_ACTIVE_WORKSPACE,
                workspace: base,
              });
              store.dispatch(batchActions(reduxActions));
              setIsLoading(false);
              setIsNotFound(false);
            }
          })
          .catch((err) => {
            if (err.response.data.statusCode === 403) {
              setIsBaseMember(false);
              setIsLoading(false);
              shouldLoadBase.current = false;
            } else if (err.response.data.statusCode === 404) {
              setIsBaseMember(false);
              setIsNotFound(true);
              setIsLoading(false);
              shouldLoadBase.current = false;
            }
          });
      }
    } else {
      const isBaseMember = user
        ? user.workspaceIds.indexOf(baseId) > -1
        : false;

      let userBaseObj = user?.workspaceEntries[baseId];

      const reduxActions = [];

      reduxActions.push({
        type: actionTypes.SET_IS_BASE_MEMBER,
        params: { isBaseMember: isBaseMember },
      });

      if (isBaseMember) {
        if (userBaseObj) {
          store.dispatch({
            type: actionTypes.SET_USER_PERMISSIONS,
            params: {
              roleType: userBaseObj.roleType,
              permissions: userBaseObj.permissions,
              roleIds: userBaseObj.roleIds,
            },
          });
        }
      }

      if (!isBaseMember) {
        shouldLoadBase.current = false;
        reduxActions.push({
          type: actionTypes.SET_IS_BASE_MEMBER,
          params: { isBaseMember: false },
        });
        setShowSpinner(false);
        setIsLoading(false);
        setIsNotFound(false);
        reduxActions.push({
          type: actionTypes.SET_USER_PERMISSIONS,
          params: {
            roleType: "",
            permissions: {} as PermissionType,
            roleIds: [],
          },
        });
        store.dispatch(batchActions(reduxActions));
        return;
      }

      if (userBaseObj) {
        reduxActions.push({
          type: actionTypes.UPDATE_WORKSPACE,
          params: {
            delta: userBaseObj,
          },
        });
      }

      shouldLoadBase.current = true;
      setShowSpinner(false);
      setIsLoading(false);
      setIsNotFound(false);
      store.dispatch(batchActions(reduxActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseSlug, user?.id, reloadNumber]);
};
