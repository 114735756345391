import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import Modal from "clarity-ui/Modal";
import Button, { ButtonTypes } from "components/Button";
import { UPDATE_CUSTOM_VIEW } from "store/actions";
import {
  useEditViewModalState,
  useEditViewModalSetter,
} from "store/reducers/clientReducer";
import styles from "./editViewModal/editViewModal.module.scss";
import { axiosInstance } from "index";

interface IEditViewModalProps {}

export default function EditViewModal(props: IEditViewModalProps) {
  const { isOpen, title, subtitle, hideModal, setTitle, setSubtitle, onSave } =
    useModalState();

  return (
    <>
      {isOpen && (
        <Modal hideModal={hideModal} size="small">
          <div className={styles.editViewModalContainer}>
            <div className={styles.editViewModalBody}>
              <span className={styles.editViewModalTitle}>Edit View</span>
              <div className={styles.editViewModalInputTitle}>
                <span>Title</span>
                <Input
                  onKeyDown={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className={styles.editViewModalInputSubtitle}>
                <span>Subtitle</span>
                <Input.TextArea
                  onKeyDown={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.editViewModalActions}>
              <Button buttonType={ButtonTypes.DEFAULT} onClick={hideModal}>
                Cancel
              </Button>
              <Button buttonType={ButtonTypes.PRIMARY} onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

function useModalState() {
  const dispatch = useDispatch();
  const { isOpen, customView } = useEditViewModalState();
  const setEditViewModal = useEditViewModalSetter();
  const hideModal = () =>
    setEditViewModal({ isOpen: false, customView: undefined });
  const [title, setTitle] = useState(customView?.name ?? "");
  const [subtitle, setSubtitle] = useState(customView?.description ?? "");

  useEffect(() => {
    if (customView) {
      setTitle(customView?.name ?? "");
      setSubtitle(customView?.description ?? "");
    }
  }, [customView]);

  const updateView = () => {
    dispatch({
      type: UPDATE_CUSTOM_VIEW,
      param: {
        type: "update",
        delta: {
          name: title,
          description: subtitle,
        },
        id: customView?.id ?? "",
      },
    });

    axiosInstance.patch("/api/customWorkView/", {
      id: customView?.id ?? "",
      changes: {
        name: title,
        description: subtitle,
      },
    });
  };

  const onSave = () => {
    updateView();
    hideModal();
  };

  return {
    isOpen,
    customView,
    title,
    subtitle,
    hideModal,
    setTitle,
    setSubtitle,
    onSave,
  };
}
