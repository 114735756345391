import { UPDATE_LOCAL_PERMISSIONS } from "store/actions";
import store from "store/storeExporter";
import {
  ContainerTypes,
  ContainerTypeToPrimitive,
  ContainerVisibility,
} from "utilities/types";
import { emptyPermissions } from "./rolesApi";

class PermissionApi {
  addPermission(params: {
    containerId: string;
    containerType: ContainerTypes;
    accessLevel: ContainerVisibility;
  }) {
    const primitive = ContainerTypeToPrimitive[params.containerType];
    if (!primitive) return;

    let currentPermissions: any = {
      ...store.getState().client?.permissions?.permissions,
    };
    let hasChanges = false;
    if (Object.keys(currentPermissions).length === 0) {
      currentPermissions = {
        ...emptyPermissions,
      };
    }

    currentPermissions[primitive] = { ...currentPermissions[primitive] };

    if (!currentPermissions[primitive].read) return false;

    currentPermissions[primitive].suggest = [
      ...currentPermissions[primitive].suggest,
    ];
    currentPermissions[primitive].read = [
      ...currentPermissions[primitive].read,
    ];

    if (params.accessLevel === ContainerVisibility.canComment) {
      if (!currentPermissions[primitive].suggest.includes(params.containerId)) {
        hasChanges = true;
        currentPermissions[primitive].read.push(params.containerId);
        currentPermissions[primitive].suggest.push(params.containerId);
      }
    } else if (params.accessLevel === ContainerVisibility.canEdit) {
      if (!currentPermissions[primitive].edit.includes(params.containerId)) {
        hasChanges = true;
        currentPermissions[primitive].suggest.push(params.containerId);
        currentPermissions[primitive].read.push(params.containerId);
        currentPermissions[primitive].edit.push(params.containerId);
      }
    } else if (params.accessLevel === ContainerVisibility.canView) {
      if (!currentPermissions[primitive].read.includes(params.containerId)) {
        hasChanges = true;
        currentPermissions[primitive].read.push(params.containerId);
      }
    }

    if (hasChanges)
      store.dispatch({
        type: UPDATE_LOCAL_PERMISSIONS,
        params: {
          permissions: currentPermissions,
        },
      });
  }
}

export const permissionsApi = new PermissionApi();
