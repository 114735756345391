import { Select } from "antd";
import React, { useLayoutEffect, useState } from "react";
import Conditional from "components/Conditional";
import "./clarityInput/clarityInput.scss";
import styles from "components/clarityInput/clarityInput.module.scss";

const { Option } = Select;

export interface IOptions {
  label: string | number | JSX.Element;
  value: string | number;
  disabled?: boolean;
  icon?: JSX.Element;
}

const ClaritySelect: React.FC<{
  classNames?: string;
  defaultValue?: string | number | string[];
  value?: string | number | string[];
  options: IOptions[];
  searchable?: boolean;
  onChange: any;
  container?: any;
  minimal?: boolean;
  disabled?: boolean;
  label?: string;
  selectedItem?: string;
  mode?: "multiple" | "tags";
  placeholder?: string;
  manualOpen?: boolean;
  open?: boolean;
  hoverItem?: string;
  dropdownMatchSelectWidth?: boolean;
  autoFocus?: boolean;
  stopPropagation?: boolean;
}> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValue, setsearchValue] = useState("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const { stopPropagation = true } = props;
  useLayoutEffect(() => {
    if (props.searchable) {
      const optionsData = props.options.filter(
        (value) =>
          String(value.label).includes(searchValue) ||
          String(value.value).includes(searchValue)
      );
      return setOptions(optionsData);
    }
    const optionsData = props.options;
    return setOptions(optionsData);
  }, [props.options, searchValue]);

  return (
    <div className={styles.container}>
      <Conditional on={props.label}>
        <label className={styles.label}>{props.label}</label>
      </Conditional>
      <Select
        defaultValue={props.defaultValue}
        virtual={true}
        value={props.value}
        disabled={props.disabled}
        listItemHeight={42}
        autoFocus={props.autoFocus}
        getPopupContainer={() => props.container ?? document.body}
        className={`w-100 claritySelect ${props.minimal ? "minimal" : ""} ${
          props.classNames
        }`}
        onKeyDown={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
        }}
        open={props.manualOpen ? props.open : undefined}
        dropdownClassName="clr-menu"
        onChange={(e) => {
          props.onChange(e);
        }}
        onSearch={(value: string) => {
          setsearchValue(value);
        }}
        showSearch={props.searchable}
        mode={props.mode}
        placeholder={props.placeholder}
        filterOption={(inputValue, option) => {
          if (option?.label)
            return option?.label
              ?.toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase());

          return false;
        }}
        optionFilterProp={"label"}
      >
        {options.map((option) => (
          <Option
            className={
              props.hoverItem && props.hoverItem === option.value
                ? "hovered"
                : undefined
            }
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            label={option.label}
          >
            {option.icon && <span className={styles.icon}>{option.icon}</span>}
            <span>{option.label}</span>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default ClaritySelect;
