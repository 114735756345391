import EmptyState from "clarity-ui/EmptyState";
import Conditional from "components/Conditional";
import React from "react";
import { useShallowSelector } from "utilities/hooks";
import { ContainerTypes } from "utilities/types";
import DetailView from "./DetailView";

const WeeklyHoc: React.FC = () => {
  const isWeeklyActive = useShallowSelector(
    (state) => state.workspace.showWeekly
  );

  const currentWeekly = useShallowSelector((state) => state.notes.weeklyNoteId);

  return (
    <>
      <Conditional on={!isWeeklyActive}>
        <EmptyState
          caption={"To activate it navigate open Settings"}
          heading={"The weekly is not activated for this base"}
        />
      </Conditional>
      <Conditional on={isWeeklyActive && currentWeekly}>
        <DetailView
          containerId={currentWeekly}
          containerType={ContainerTypes.NOTE}
        />
      </Conditional>
    </>
  );
};

export default WeeklyHoc;
