import React, { useEffect, useMemo, useRef, useState } from "react";
import { $documentMode, $focusOn } from "store/storeExporter";
import { LineType } from "utilities/lineUtilities";
import { BehaviorSubject } from "rxjs";
import { FocusType } from "store/reducers/blockReducer";
import {
  checkEditableEvent,
  getClassNameFromBlockType,
  toggleInsertModeFocusLastBlock,
  updateBlockText,
  updateBlockTextInState,
} from "editor/utils/blockActions";
import { selectionStarted } from "components/Overlay";
import { BlockMenuTypes, BlockStateMenu, DocumentModes } from "utilities/types";
import {
  checkBlockEmptyChars,
  replacePlacehoders,
} from "editor/utils/specificActions/textUpdateUtils";
import BlockMenu from "editor/blockMenus/BlockMenu";
import { Dropdown } from "antd";
import { checkOpenMenuOptions } from "editor/utils/blockMenusActions/menuPlaceholderActions";
import { checkIfOpenedBlockMenu } from "editor/utils/blockMenusActions/menuActions";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { BlockPropsWithRef } from "../BlockSplitter";
import BlockTooltip from "editor/blockMenus/BlockTooltip";
import { handleBlockPaste } from "editor/utils/specificActions/pasteActions";
import { throttle } from "lodash";
import {
  VALUE_BACK_SPACE,
  VALUE_C,
  VALUE_DELETE,
  VALUE_ENTER,
} from "keycode-js";
import {
  addNoScrollListener,
  removeNoScrollListener,
} from "editor/blockHelpers/containerElementUtils";
import { handleCheckCitationUpdate } from "../ContainerWrapper";
import {
  checkCaretPosition,
  checkIfAtEdgeOfCitation,
  checkIfAtEdgeOfTag,
} from "editor/utils/caretUtils";
import {
  checkIfNeedsCombination,
  getHtml,
} from "editor/utils/blockValueHelpers";
import {
  ActionIntercept,
  setActionInterceptor,
} from "store/reducers/clientReducer";

const EditableBlock: React.FC<BlockPropsWithRef> = (props) => {
  const [menuState, setMenuState] = useState<BlockStateMenu>({
    isOpened: false,
    isAtBegining: true,
    hoveredItem: 0,
    filterMenuBy: "",
    type: BlockMenuTypes.slashMenu,
    executeSelection: false,
    preOpenedCaretPosition: 0,
    manualReset: false,
    options: {
      hashtag: false,
      entityType: null,
    },
  });
  const menuStateValue = useRef(new BehaviorSubject(menuState));
  const [showTooltip, setShowTooltip] = useState(false);
  const firstLoad = useRef(true);
  const { checkboxStatus } = props.blockData;

  const throttledUpdate = useRef(
    throttle(
      (e) => {
        if (props.blockRef.current) {
          updateBlockText(
            props.blockData,
            { currentTarget: props.blockRef.current },
            props.context
          );
        }
      },
      600,
      { trailing: true, leading: false }
    )
  );

  useEffect(() => {
    const sub = menuStateValue.current.subscribe((newMenuState) => {
      if (firstLoad.current) return;
      setMenuState({ ...newMenuState });
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (props.blockRef.current && !props.blockComponentData.isFocused) {
      props.blockRef.current.innerHTML = getHtml(props.blockData.value);
    }
  }, [props.blockData.value]);

  useEffect(() => {
    if (props.blockData.options?.presetMenu) {
      const newMenu = { ...props.blockData.options.presetMenu };
      setTimeout(() => {
        menuStateValue.current.next({ ...newMenu });
      }, 1);
      const blockCopy = { ...props.blockData };
      delete blockCopy.options?.presetMenu;
      updateBlockTextInState(blockCopy, props.blockRef, props.context);
    }
  }, [props.blockData.options?.presetMenu]);

  useEffect(() => {
    let container: any;
    if (firstLoad.current) {
      return;
    }
    if (menuState && props.blockRef.current) {
      if (menuState.isOpened) {
        checkOpenMenuOptions(menuState);
        // setMenuState({ ...menuState });
        container = props.blockRef.current?.closest(".document-view");
        // container?.classList.add("noscroll");
        if (container) {
          addNoScrollListener(container as HTMLDivElement);
        }
      }
      if (!menuState.isOpened) {
        container = props.blockRef.current?.closest(".document-view");
        container?.classList.remove("noscroll");
        if (container) {
          removeNoScrollListener(container as HTMLDivElement);
        }
        moveCaretToPreviousPosition(
          props.blockRef.current,
          $focusOn.value.caretPosition ? $focusOn.value.caretPosition : 0
        );
        replacePlacehoders(props.blockRef.current);
        props.blockRef.current.normalize();
        const focusObject = $focusOn.value;
        if (focusObject.focusBlockId === props.blockData.id) {
          moveCaretToPreviousPosition(
            props.blockRef.current,
            focusObject.caretPosition ? focusObject.caretPosition : 0
          );
        }
        checkBlockEmptyChars();
      }
    }

    return () => {
      if (menuState?.isOpened) {
        container?.classList.remove("noscroll");
        if (container) {
          removeNoScrollListener(container as HTMLDivElement);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState.isOpened]);

  useEffect(() => {
    if (firstLoad.current) {
      return;
    }
    const selection = document.getSelection();
    if (
      selection?.toString() === "" &&
      props.blockRef.current === document.activeElement
    ) {
      if (props.blockRef.current?.innerHTML === "") {
        props.blockRef.current.blur();
        props.blockRef.current.focus();
        props.blockRef.current.innerHTML = "<b></b>";
        props.blockRef.current.innerHTML = "";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.blockData.lineType, props.blockData.frozen]);

  useEffect(() => {
    firstLoad.current = false;
    return () => {
      firstLoad.current = true;
    };
  }, []);

  return (
    <>
      {useMemo(() => {
        return (
          <>
            {showTooltip &&
              (props.context.canEdit || props.context.canComment) && (
                <BlockTooltip {...props} />
              )}
          </>
        );
      }, [showTooltip, props.context.canEdit, props.context.canComment])}

      {useMemo(() => {
        return (
          <>
            {menuState.isOpened && (
              <EditableMenuInner
                menuStateValue={menuStateValue}
                menuState={menuState}
                blockRef={props.blockRef}
                blockData={props.blockData}
                context={props.context}
                isFirst={props.isFirst}
              />
            )}
          </>
        );
      }, [menuState])}

      {useMemo(() => {
        return (
          <div
            contentEditable={"true"}
            tabIndex={-1}
            ref={props.blockRef}
            onBeforeInput={(e) => {
              if (!props.context.canEdit) {
                e.stopPropagation();
                e.preventDefault();
                return;
              }
              if ($documentMode.value === DocumentModes.BLOCK) {
                e.preventDefault();
                return;
              }
              checkIfAtEdgeOfTag(e);
              checkIfAtEdgeOfCitation(e);
            }}
            onInput={(e) => {
              e.stopPropagation();
              handleCheckCitationUpdate(e);
              checkIfOpenedBlockMenu(menuStateValue, props.blockRef);
              if (!menuStateValue.current.value.isOpened) {
                checkBlockEmptyChars();
              }
              throttledUpdate.current(e);
            }}
            onPaste={(e) => {
              handleBlockPaste(e, props);
            }}
            data-block-id={props.blockData.id}
            data-root={true}
            onKeyDown={(e) => {
              if (selectionStarted) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }

              if ($documentMode.value === DocumentModes.BLOCK) {
                if (e.key.toLowerCase() !== VALUE_C) e.preventDefault();
                if (e.key === VALUE_ENTER && !(e.metaKey || e.ctrlKey)) {
                  toggleInsertModeFocusLastBlock();
                  e.stopPropagation();
                }
                return;
              }
              if (props.context.showInterceptor)
                setActionInterceptor(ActionIntercept.authedUserInPublicBase);
              checkEditableEvent(e, props, menuStateValue, throttledUpdate);
            }}
            onKeyUp={(e) => {
              if (
                [VALUE_BACK_SPACE, VALUE_DELETE].includes(e.key) &&
                !menuStateValue.current.value.isOpened
              ) {
                checkIfNeedsCombination(e, props.blockData);
              }
            }}
            onMouseDown={(e) => {
              setShowTooltip(false);
            }}
            onMouseUp={(e) => {
              if (!props.context.canEdit) {
                const selection = document.getSelection();
                if (selection && selection.toString().length > 0)
                  setShowTooltip(true);
                else setShowTooltip(false);
              }
            }}
            onSelect={(e) => {
              e.stopPropagation();
              const selection = document.getSelection();
              if (selection && selection.toString().length > 0)
                setShowTooltip(true);
              else setShowTooltip(false);
              const caretPosition = checkCaretPosition(e.currentTarget);

              $focusOn.next({
                caretPosition,
                focusBlockId: props.blockData.id,
                focusContext: props.context,
                focusPane: props.context.paneId,
                type: FocusType.prevPosition,
                refocusToggle: false,
                focusBlockRef: props.blockRef,
              });
            }}
            style={{
              marginTop:
                props.blockData.indentLevel > 0 || props.isFirst ? "0" : "",
              caretColor:
                !props.context.canEdit && !props.context.showInterceptor
                  ? "transparent"
                  : "inherit",
              paddingLeft:
                props.blockData.lineType === LineType.checkbox ? 4 + "px" : 0,
              maxWidth:
                props.blockData.lineType === LineType.checkbox
                  ? `calc(100% - 18px)`
                  : ``,
              width:
                props.blockData.lineType === LineType.checkbox
                  ? `calc(100% - 24px)`
                  : `100%`,
            }}
            className={`content-section clarity-selectable  ${getClassNameFromBlockType(
              props.blockData.lineType
            )} ${
              props.blockData.lineType === LineType.checkbox &&
              checkboxStatus === "Done"
                ? "clarity-checkbox-done"
                : ""
            }`}
            onBlur={(e) => {
              setShowTooltip(false);
              throttledUpdate.current.flush();
            }}
            dangerouslySetInnerHTML={{ __html: getHtml(props.blockData.value) }}
            placeholder={
              props.blockData.lineType === LineType.heading1 ||
              props.blockData.lineType === LineType.heading2 ||
              props.blockData.lineType === LineType.heading3
                ? "Heading"
                : props.blockData.lineType === LineType.checkbox
                ? "Checklist"
                : props.blockData.containerType === "Work_Activity" // need to add enum
                ? `Write a comment`
                : `Type "/" for actions...`
            }
          ></div>
        );
      }, [
        props.blockData.lineType,
        props.blockData.checkboxStatus,
        props.context.canEdit,
        props.isFirst,
      ])}
    </>
  );
};

const EditableMenuInner: React.FC<any> = (props) => {
  return (
    <Dropdown
      mouseLeaveDelay={200}
      placement="bottomRight"
      overlay={<BlockMenu {...props} />}
      visible={props.menuStateValue.current.value.isOpened}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></div>
    </Dropdown>
  );
};

export default EditableBlock;
