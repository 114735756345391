import UserDisplay from "clarity-ui/UserDisplay";
import { getFullTime } from "components/TimePassed";
import moment from "moment";
import React, { ReactChild } from "react";
import { IInviteLink } from "utilities/types";
import styles from "./inviteLinkContainer.module.scss";
import { InfoCircleOutlined, TeamOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const InviteDetailsSummary: React.FC<{
  link: IInviteLink;
  status: { icon: ReactChild; code: string; reason: string };
}> = ({ link, status }) => {
  return (
    <div className={styles.summaryRow}>
      <DetailCard
        title={"Created by"}
        icon={<InfoCircleOutlined />}
        value={<UserDisplay id={link.createdBy} />}
        description={` ${getFullTime(moment(link.createdAt))}`}
        hasDivider={true}
      />
      <DetailCard
        title={"Status"}
        icon={status.icon}
        value={status.code}
        description={` ${status.reason}`}
        hasDivider={true}
      />
      <DetailCard
        title={"Total Usage"}
        icon={<TeamOutlined />}
        value={link.totalCount.toString()}
        description={"Total number redeems"}
      />
    </div>
  );
};

const DetailCard: React.FC<{
  title: string;
  value: string | ReactChild;
  description?: string;
  hasDivider?: boolean;
  icon: ReactChild;
  addValue?: string;
}> = ({ title, value, description, hasDivider, icon, addValue }) => {
  return (
    <div className={styles.summaryCard}>
      <p className={"caption primary bold"}>
        <Tooltip title={description}>{title}</Tooltip>
      </p>
      <p className={`"body" ${styles.summaryCard__iconContainer}`}>{icon}</p>
      <p className={"caption primary"}>
        {value}{" "}
        {description && (
          <>
            <br />
            <span className={"small secondary"}>{description}</span>
          </>
        )}
      </p>
      {hasDivider && <div className={styles.cardDivider} />}
    </div>
  );
};

export default InviteDetailsSummary;
