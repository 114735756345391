import { createNewBlock } from "modules/entityService";
import { BehaviorSubject } from "rxjs";
import { Block } from "store/reducers/blockReducer";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store from "store/storeExporter";
import { getNextRank } from "utilities/containerRankHelpers";
import { ContainerTypes } from "utilities/types";
import { BlockCreateProps } from "../primitiveActions/primitiveActions";

export const cloneBlock = (
  prevBlock: Block,
  presetData: Partial<Block>,
  resetEditHistory?: boolean
) => {
  const cloneBlockPresets: BlockCreateProps = {
    baseId: prevBlock.baseId,
    lineType: prevBlock.lineType,
    containerId: prevBlock.containerId,
    value: prevBlock.value,
    checkboxStatus: prevBlock.checkboxStatus,
    containerType: prevBlock.containerType,
    indentLevel: prevBlock.indentLevel,
    referencingContainerId: prevBlock.referencingContainerId,
    referencingContainerType: prevBlock.referencingContainerType,
    parentId: "",
  };

  if (resetEditHistory) {
    const now = new Date();
    const myId = store.getState().user?.id;
    cloneBlockPresets.dateCreated = now;
    cloneBlockPresets.dateModified = now;
    cloneBlockPresets.textDateModified = now;
    if (myId) {
      cloneBlockPresets.createdBy = myId;
      cloneBlockPresets.modifiedBy = myId;
      cloneBlockPresets.textModifiedBy = myId;
    }
  }

  const fullPresets: BlockCreateProps = { ...cloneBlockPresets, ...presetData };

  const cloneBlock = createNewBlock(fullPresets, {
    containerId: fullPresets.containerId,
    containerType: fullPresets.containerType,
    baseId: fullPresets.baseId,
  });

  const fullBlock: Block = {
    ...cloneBlock,
    children: [],
    blockSubject: new BehaviorSubject<Partial<Block>>({}),
  };

  return fullBlock;
};

export const cloneBlockBranch = (
  idsList: string[],
  parentId: string,
  indentLevel: number,
  containerId: string,
  containerType: ContainerTypes,
  newBlocksArray: Block[]
) => {
  let documentRank: string;
  const newState = store.getState().blocks;
  const newIdsList = idsList.map((blockId: string) => {
    documentRank = getNextRank(documentRank);
    const block = getBlockById(newState.dict, blockId);
    const newBlock = cloneBlock(block, {
      indentLevel,
      parentId,
      containerId: containerId,
      containerType: containerType,
      documentRank,
    });
    newBlocksArray.push(newBlock);
    if (block.children && block.children.length > 0) {
      newBlock.children = cloneBlockBranch(
        block.children,
        newBlock.id,
        indentLevel + 1,
        containerId,
        containerType,
        newBlocksArray
      );
    }
    return newBlock.id;
  });
  return newIdsList;
};
