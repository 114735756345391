import React from "react";
import { Route, Switch } from "react-router-dom";
import NavigationContext from "screens/base/NavigationContext";
import { PayoutsViewsNames, TasksViewModes } from "utilities/types";

const PayoutRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/:baseSlug/payouts/unclaimed"
        render={(props) => (
          <NavigationContext
            viewName={PayoutsViewsNames.UnclaimedContributions}
            taskViewMode={TasksViewModes.UnclaimedContributions}
          />
        )}
      />
      <Route
        path="/:baseSlug/payouts/unstarted"
        render={(props) => (
          <NavigationContext
            viewName={PayoutsViewsNames.UnstartedContributions}
            taskViewMode={TasksViewModes.UnstartedContributions}
          />
        )}
      />
      <Route
        path="/:baseSlug/payouts/in-progress"
        render={(props) => (
          <NavigationContext
            viewName={PayoutsViewsNames.InProgressContributions}
            taskViewMode={TasksViewModes.InProgressContributions}
          />
        )}
      />

      <Route
        path="/:baseSlug/payouts/awaiting-reward-approval"
        render={(props) => (
          <NavigationContext
            viewName={PayoutsViewsNames.AwitingRewardApprovalContributions}
            taskViewMode={TasksViewModes.AwitingRewardApprovalContributions}
          />
        )}
      />

      <Route
        path="/:baseSlug/payouts/approved-for-payment"
        render={(props) => (
          <NavigationContext
            viewName={PayoutsViewsNames.ApprovedForPaymentContributions}
            taskViewMode={TasksViewModes.ApprovedForPaymentContributions}
          />
        )}
      />
      <Route
        path="/:baseSlug/payouts/paid"
        render={(props) => (
          <NavigationContext
            viewName={PayoutsViewsNames.PaidContributions}
            taskViewMode={TasksViewModes.PaidContributions}
          />
        )}
      />
      <Route
        path="/:baseSlug/payouts"
        render={(props) => (
          <NavigationContext viewName={PayoutsViewsNames.Payouts} />
        )}
      />
    </Switch>
  );
};

export default PayoutRouter;
