import { Skeleton } from "antd";
import { tokenGateApi } from "clientApi/tokenGatesApi";
import Button, { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import { axiosInstance } from "index";
import React, { createContext, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import { Abilities } from "utilities/types";
import AddOrEditTokenGate from "./tokenGateContainer/AddOrEditTokenGate";
import TokenGateRow from "./tokenGateContainer/TokenGateRow";

const TokenGateContainer: React.FC<{
  onSelect?: any;
  addingRemote?: boolean;
  setAddingRemote?: any;
  showAdd?: boolean;
  onRemove?: () => void;
}> = ({
  onSelect,
  addingRemote = false,
  setAddingRemote,
  showAdd = true,
  onRemove,
}) => {
  const [adding, setadding] = useState(addingRemote);
  const [loading, setLoading] = useState(true);
  const [selectedId, setselectedId] = useState<string | null>(null);
  const canEditTokenGate = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_TOKEN_GATES,
  });

  const gatesIds = useSelector(
    (state: ClarityStore) => state.tokenGates.ids,
    shallowEqual
  );

  useEffect(() => {
    setadding(addingRemote);
  }, [addingRemote]);

  useEffect(() => {
    if (!adding) {
      if (setAddingRemote) {
        setAddingRemote(false);
      }
    }
  }, [adding]);

  useEffect(() => {
    const baseId = store.getState().workspace.id;
    axiosInstance
      .get(`/api/token-gate/gates`, {
        params: {
          baseId,
        },
      })
      .then((res) => {
        setLoading(false);
        tokenGateApi.setExistingTokenGate(res.data);
      });
  }, []);

  return (
    <>
      {onSelect && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <h4>Set Token Requirements</h4>
          <div style={{ display: "flex", gap: "5px" }}>
            {onRemove && (
              <Button
                disabled={!canEditTokenGate}
                onClick={() => onRemove()}
                buttonType={ButtonTypes.LINK}
              >
                Remove
              </Button>
            )}
            {!adding && (
              <Button
                onClick={() => setadding(true)}
                disabled={!canEditTokenGate}
                buttonType={ButtonTypes.PRIMARY}
              >
                Create New
              </Button>
            )}
          </div>
        </div>
      )}
      {gatesIds.length === 0 && (
        <span className="caption secondary">No token gates yet</span>
      )}
      {gatesIds.length > 0 && (
        <div className="label bold secondary mb-5">GATES</div>
      )}
      {loading && (
        <Skeleton
          avatar={true}
          active={true}
          title={true}
          paragraph={{ rows: 1 }}
        />
      )}
      {!loading &&
        gatesIds.map((gateId, index) => (
          <SelectHOC
            key={index + gateId}
            id={gateId}
            onSelect={onSelect}
            selectedId={selectedId}
            setselectedId={setselectedId}
          >
            <TokenGateRow gateId={gateId} canEditTokenGate={canEditTokenGate} />
          </SelectHOC>
        ))}

      {!adding && !onSelect && showAdd && (
        <Button
          onClick={() => setadding(true)}
          disabled={!canEditTokenGate}
          buttonType={ButtonTypes.LINK}
          style={{ marginTop: "20px" }}
        >
          Create New
        </Button>
      )}
      {adding && (
        <AddOrEditTokenGate
          isInseringInContainer={onSelect}
          setadding={setadding}
        />
      )}
    </>
  );
};

export const IsInSertingInContainer = createContext<any>(undefined);

const SelectHOC: React.FC<{
  onSelect?: any;
  id: string;
  selectedId: string | null;
  setselectedId: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ selectedId, onSelect, setselectedId, children, id }) => {
  if (onSelect) {
    return (
      <IsInSertingInContainer.Provider value={onSelect}>
        <div onClick={() => onSelect(id)}>{children}</div>
      </IsInSertingInContainer.Provider>
    );
  }
  return (
    <IsInSertingInContainer.Provider value={undefined}>
      {children}
    </IsInSertingInContainer.Provider>
  );
};

export default TokenGateContainer;
