import React, { useEffect, useState } from "react";

export const useTranslucentBackground = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  url: string | undefined
) => {
  const [image, setimage] = useState(url);
  const [parent, setparent] = useState(ref.current ? ref.current : null);

  useEffect(() => {
    if (url !== image) setimage(url);
    if (parent !== ref.current) setparent(ref.current ? ref.current : null);
  }, [url, ref]);

  return { image, parent };
};
