import {
  Abilities,
  ICollaborator,
  IPendingCollaborator,
  IRole,
  UserRole,
} from "utilities/types";
import { MailOutlined, CloseOutlined, CrownOutlined } from "@ant-design/icons";

import styles from "./collaboratorRow/collaboratorRow.module.scss";
import Button, { ButtonTypes } from "components/Button";
import store, { ClarityStore } from "store/storeExporter";
import ClaritySelect from "components/ClaritySelect";
import _ from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import rolesApi from "clientApi/rolesApi";
import { useAbilityChecker } from "editor/utils/customHooks";

interface Props {
  collaborator?: ICollaborator;
  pendingCollaborator?: IPendingCollaborator;
  pending?: boolean;
  baseId: string;
  isOwner: boolean;
  isGuestCollab?: boolean;
  userId: string | undefined;
  showConfirmation: (type: "membership" | "invitation", info: any) => void;
  showCommandPalette?: (userId: string, roleIds: string[]) => void;
}

const CollaboratorRow = (props: Props) => {
  const {
    collaborator,
    pending,
    pendingCollaborator,
    baseId,
    isGuestCollab,
    showConfirmation,
  } = props;

  const member = useSelector((store: ClarityStore) => {
    if (collaborator?.user) return store.members.dict[collaborator?.user?.id];
  }, shallowEqual);

  const canEditBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MEMBERS,
  });

  const [userRoles, setuserRoles] = useState<string[]>([]);
  const [isOwnerCollab, setIsOwnerCollab] = useState<boolean>(false);

  const handleRevokeClick = () => {
    if (pending && pendingCollaborator) {
      showConfirmation("invitation", {
        invitationId: pendingCollaborator.id,
      });
    } else if (collaborator) {
      showConfirmation("membership", {
        userId: collaborator.user.id,
        baseId,
        username: collaborator.user.username,
        name: collaborator.user.name,
      });
    }
  };

  const getRoleOptions = () => {
    const roleState = store.getState().roles;
    const selectableRoles = roleState.ids.map((id) => {
      return roleState.dict[id];
    });

    const noShow = ["Guest", "No Role", "Owner"];
    const options = selectableRoles
      .filter((role) => !noShow.includes(role.roleName))
      .filter((role) => role.roleType !== "personal")
      .map((role, index) => ({
        label: role.roleName,
        value: role.id,
      }));
    return options;
  };

  const changeRole = useCallback(
    (e) => {
      const toDelete = _.difference(userRoles, e);
      const toCreate =
        member && member.roleIds ? _.difference(e, userRoles) : [];

      rolesApi.changeUserRole({
        userId: member?.id,
        baseId,
        removedRoles: toDelete,
        newRoles: toCreate,
      });
    },
    [member, userRoles]
  );

  useEffect(() => {
    const roleState = store.getState().roles;
    const displayRoles: IRole[] = [];
    const guestRoles: IRole[] = [];
    let isCollabOwner = false;

    member?.roleIds?.forEach((id) => {
      const role = roleState.dict[id];

      if (!role) return;
      if (role.roleName === "Guest") {
        guestRoles.push(role);
        return;
      }
      if (role.roleName === "Owner") {
        isCollabOwner = true;
      }
      if (
        !(
          role.roleName === "No Role" ||
          role.roleName === "Owner" ||
          role.roleType === "personal"
        )
      ) {
        displayRoles.push(role);
      }
    });
    if (displayRoles.length === 0 && guestRoles.length > 0) {
      const roleData = guestRoles[0];
      setuserRoles([roleData.id]);
      return;
    }

    const update = displayRoles
      .sort(function (a: IRole, b: IRole) {
        return a.rank.localeCompare(b.rank, "en");
      })
      .map((role) => role.id);
    setuserRoles(update);
    setIsOwnerCollab(isCollabOwner);
  }, [member?.roleIds]);

  return (
    <div className={styles.collaborator_row}>
      {pending && pendingCollaborator ? (
        <div className={styles.user_info}>
          {pendingCollaborator.invitedEmail ? (
            <div className={styles.user_info__emailIcon}>
              <MailOutlined style={{ fontSize: "20px", opacity: "0.55" }} />
            </div>
          ) : (
            <img
              src={pendingCollaborator.invited.avatar}
              alt="Avatar"
              className={styles.avatarPending}
            />
          )}
          <div className={styles.user_info_texts}>
            <p className={styles.pending}>
              {pendingCollaborator.invitedEmail ||
                pendingCollaborator.invited.name ||
                pendingCollaborator.invited.username}
            </p>
            {pendingCollaborator.invited && pendingCollaborator.invited.name ? (
              <p>{`${pendingCollaborator.invited.username} • Pending invitation`}</p>
            ) : (
              <p>Pending invitation</p>
            )}
          </div>
        </div>
      ) : collaborator ? (
        <div className={styles.user_info}>
          <img src={collaborator.user.avatar} alt="Avatar" />
          <div className={styles.user_info_texts}>
            <p style={{ width: "max-content" }}>
              {collaborator.user.name || collaborator.user.username}
            </p>
          </div>
          {!isOwnerCollab && !isGuestCollab && (
            <ClaritySelect
              options={getRoleOptions()}
              defaultValue={userRoles}
              value={userRoles}
              onChange={changeRole}
              mode={"multiple"}
              minimal={true}
              disabled={!canEditBaseSettings}
              placeholder="Select roles"
            ></ClaritySelect>
          )}
          {isOwnerCollab && <CrownOutlined style={{ marginLeft: "8px" }} />}
        </div>
      ) : (
        "None"
      )}
      {canEditBaseSettings &&
        ((collaborator && collaborator.role !== UserRole.OWNER) || pending) && (
          <Button
            buttonType={ButtonTypes.LINK}
            className={styles.revokeBtn}
            onClick={handleRevokeClick}
          >
            <CloseOutlined style={{ fontSize: 14 }} />
          </Button>
        )}
    </div>
  );
};

export default CollaboratorRow;
