import { batch } from "react-redux";
import {
  SET_ACCESS_TOKEN,
  REMOVE_ACCESS_TOKEN,
  REMOVE_AUTHENTICATED_USER,
  CLEAR_WORKSPACE_DATA,
} from "./../store/actions";
import Cookies from "js-cookie";
import store from "../store/storeExporter";
import { locationSubject } from "components/LocationListener";
import { axiosInstance } from "index";

export const refreshTheToken = (failedRequest?: any) => {
  const userId = store.getState().user?.id;
  if (!userId) {
    return Promise.resolve();
  }
  return axiosInstance
    .post("/api/auth/refresh")
    .then((tokenRefreshResponse) => {
      setAccessToken(tokenRefreshResponse.data.accessToken);
      const accessToken = tokenRefreshResponse.data?.accessToken;

      if (failedRequest) {
        failedRequest.response.config.headers[
          "Authorization"
        ] = `Bearer ${tokenRefreshResponse.data.accessToken}`;
      }

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${
        accessToken ? accessToken : ""
      }`;
      return Promise.resolve();
    })
    .catch(async (error) => {
      console.log("error here", error);
      const redirectUrl: any = store.getState().client.refreshRedirectUrl;
      if (
        !failedRequest ||
        !failedRequest?.reponse ||
        !failedRequest.reponse.config ||
        failedRequest.response.config.url === "/api/user"
      ) {
        await logUserOut("", true);
      } else {
        await logUserOut(redirectUrl || "/", true);
      }
      return Promise.reject(error);
    });
};

export function setAccessToken(accessToken: any) {
  store.dispatch({ type: SET_ACCESS_TOKEN, accessToken });
  return Cookies.set("x-at", accessToken, {
    path: "/",
    sameSite: "lax",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    secure: true,
    expires: 1 / 48,
    signed: true,
  });
}

export async function logUserOut(
  redirectUrl?: string,
  noBackendCall?: boolean
) {
  console.log("logging out");
  if (!noBackendCall) {
    try {
      await axiosInstance.post("/api/auth/logout");
    } catch {}
  }

  Cookies.remove("x-at", {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  Cookies.remove("x-rt", {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  batch(() => {
    store.dispatch({ type: SET_ACCESS_TOKEN, accessToken: undefined });
    store.dispatch({ type: REMOVE_AUTHENTICATED_USER });
    store.dispatch({ type: REMOVE_ACCESS_TOKEN });
    store.dispatch({ type: CLEAR_WORKSPACE_DATA });
  });
  localStorage.clear();
  console.log(redirectUrl);
  if (redirectUrl) {
    locationSubject.next(redirectUrl);
  } else locationSubject.next("/login");
  return;
}
