import ConfirmModal from "clarity-ui/ConfirmModal";
import { axiosInstance } from "index";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useBase } from "store/reducers/workspaceReducer";
import { ClarityStore } from "store/storeExporter";
import { UserRole } from "utilities/types";
import Button, { ButtonTypes } from "./Button";
import ClarityInput from "./ClarityInput";

const DeleteBase: React.FC<{}> = () => {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);
  const userRole = useSelector(
    (store: ClarityStore) => store.client.roleType,
    shallowEqual
  );
  const isOwner = userRole === UserRole.OWNER;

  const [disabled, setDisabled] = useState<boolean>(true);
  const [passPhrase, setPassPhrase] = useState<string>("");

  useEffect(() => {
    if (passPhrase === "DELETE") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [passPhrase]);

  const base = useBase();
  const handleLeaveBase = async () => {
    if (disabled) {
      return;
    }
    if (isOwner) {
      await axiosInstance.post(`/api/workspace/deleteBase`, {
        baseId: base.base.id,
      });
    }
  };

  return (
    <>
      <Button
        buttonType={ButtonTypes.DANGER}
        onClick={(e: any) => {
          setTimeout(() => setIsConfirmationModalVisible(true), 0);
        }}
      >
        {"Delete this base"}
      </Button>
      {isConfirmationModalVisible && (
        <ConfirmModal
          onConfirm={handleLeaveBase}
          confirmDisabled={disabled}
          confirmationText="Delete"
          title="Delete Base"
          close={() => setIsConfirmationModalVisible(false)}
        >
          <div
            className="body2 regular"
            style={{
              marginTop: "24px",
              marginBottom: "40px",
            }}
          >
            <span>Are you sure you want to delete the base?</span>
            <p>Please writte "DELETE" below to confirm the action</p>

            <ClarityInput
              onChange={(e) => {
                setPassPhrase(e.target.value);
              }}
              placeholder="DELETE"
            />
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default DeleteBase;
