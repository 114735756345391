import {
  CLOSE_CONFIRMATION_MODAL,
  CLOSE_SELECT_GROUP_MODAL,
  OPEN_CONFIRMATION_MODAL,
  OPEN_SELECT_GROUP_MODAL,
} from "../../store/actions";

export interface ISetConfirmModal {
  type: string;
  message: string;
  title: string;
  confirmMessage: string;
  confirmAction: any;
  display: boolean;
  body: string;
  hasChildComponent: boolean;
  cancelAction: any;
  groupIds?: string[];
}

interface IInitialState {
  confirmationModal: {
    message: string;
    title: string;
    confirmMessage: string;
    confirmAction: any;
    display: boolean;
    body: string;
    hasChildComponent: boolean | null;
    cancelAction: any;
  };
  selectGroupModal: {
    message: string;
    title: string;
    confirmMessage: string;
    confirmAction: any;
    display: boolean;
    body: string;
    hasChildComponent: boolean | null;
    cancelAction: any;
    groupIds?: string[];
  };
}

const initialState: IInitialState = {
  confirmationModal: {
    message: "",
    title: "",
    confirmMessage: "",
    confirmAction: null,
    display: false,
    hasChildComponent: false,
    body: "",
    cancelAction: null,
  },
  selectGroupModal: {
    message: "",
    title: "",
    confirmMessage: "",
    confirmAction: null,
    display: false,
    hasChildComponent: false,
    body: "",
    cancelAction: null,
    groupIds: [],
  },
};

export default function confirmationModalReducer(
  state = initialState,
  action: ISetConfirmModal
) {
  switch (action.type) {
    case OPEN_CONFIRMATION_MODAL: {
      const newState = {
        ...state,
        confirmationModal: { ...state.confirmationModal },
      };
      newState.confirmationModal.message = action.message;
      newState.confirmationModal.title = action.title;
      newState.confirmationModal.confirmMessage = action.confirmMessage;
      newState.confirmationModal.confirmAction = action.confirmAction;
      newState.confirmationModal.display = true;
      newState.confirmationModal.body = action.body;
      newState.confirmationModal.hasChildComponent = action.hasChildComponent;
      newState.confirmationModal.cancelAction = action.cancelAction;
      return newState;
    }

    case OPEN_SELECT_GROUP_MODAL: {
      const newState = {
        ...state,
        selectGroupModal: { ...state.selectGroupModal },
      };
      newState.selectGroupModal.message = action.message;
      newState.selectGroupModal.title = action.title;
      newState.selectGroupModal.confirmMessage = action.confirmMessage;
      newState.selectGroupModal.confirmAction = action.confirmAction;
      newState.selectGroupModal.display = true;
      newState.selectGroupModal.body = action.body;
      newState.selectGroupModal.hasChildComponent = action.hasChildComponent;
      newState.selectGroupModal.cancelAction = action.cancelAction;
      newState.selectGroupModal.groupIds = action.groupIds;
      return newState;
    }

    case CLOSE_CONFIRMATION_MODAL: {
      if (state.confirmationModal.display !== false) {
        const newState = {
          ...state,
        };
        newState.confirmationModal.message = "";
        newState.confirmationModal.title = "";
        newState.confirmationModal.confirmMessage = "";
        newState.confirmationModal.confirmAction = null;
        newState.confirmationModal.display = false;
        newState.confirmationModal.hasChildComponent = null;
        return newState;
      }
      return state;
    }

    case CLOSE_SELECT_GROUP_MODAL: {
      if (state.selectGroupModal.display !== false) {
        const newState = {
          ...state,
        };
        newState.selectGroupModal.message = "";
        newState.selectGroupModal.title = "";
        newState.selectGroupModal.confirmMessage = "";
        newState.selectGroupModal.confirmAction = null;
        newState.selectGroupModal.display = false;
        newState.selectGroupModal.hasChildComponent = null;
        return newState;
      }
      return state;
    }

    default:
      return state;
  }
}
