import * as actionTypes from "../actions";
import { insertFirstInContext } from "editor/utils/specificActions/addBlockActions";
import { batch } from "react-redux";
import {
  $documentMode,
  contextWithNoBlocks,
  resetContextWithNoBlocks,
} from "store/storeExporter";

const cleanupMiddleware = (store: any) => (next: any) => (action: any) => {
  let result = next(action);

  if (action.type === actionTypes.SET_UNDO) {
    if (Object.values(contextWithNoBlocks).length > 0) {
      batch(() => {
        Object.values(contextWithNoBlocks).forEach((context: any) => {
          insertFirstInContext(context);
        });
        resetContextWithNoBlocks();
      });
    }
  }

  if (action.type === actionTypes.CHANGE_DOCUMENT_MODE) {
    if ($documentMode.value !== action.param.documentMode)
      $documentMode.next(action.param.documentMode);
  }

  return result;
};

export default cleanupMiddleware;
