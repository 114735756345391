import { BlockActionTypes, getAntiAction } from "editor/utils/blockActions";
import { getHtml } from "editor/utils/blockValueHelpers";
import { checkCaretPosition } from "editor/utils/caretUtils";
import { primitiveAddSingleBlock } from "editor/utils/primitiveActions/primitiveActions";
import {
  addBlock,
  ADD_BLOCK_TYPES,
} from "editor/utils/specificActions/addBlockActions";
import { blockModeDelete } from "editor/utils/specificActions/deleteBlockActions";
import {
  checkFocusTitle,
  moveBlocks,
} from "editor/utils/specificActions/moveActions";
import { setUpdateObjectToStore } from "editor/utils/specificActions/persistActions";
import {
  ActionObject,
  ActionWrapperObject,
  createActionWrapperObject,
} from "editor/utils/specificActions/undoUtils";
import linkifyHtml from "linkifyjs/html";
import { throttle } from "lodash";
import React from "react";
import { batchActions } from "redux-batched-actions";
import { SAVE_BLOCK_DATA, SET_UNDO } from "store/actions";
import {
  getBlockById,
  getCurrentContext,
  getNextBlockId,
  getPreviousBlock,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store from "store/storeExporter";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { ILineValue, LineType, LineValueType } from "utilities/lineUtilities";
import { TypeOfLineMovement } from "utilities/movementTypes";
import { stripHtml } from "utilities/stringUtilities";
import { DocumentModes, IBlockContext } from "utilities/types";
import {
  tableDndObserver,
  tableListeners,
  tableStoreData,
} from "../TableBlock";
import { setEdgeWasDragged } from "./TableEdges";
const linkifyTest = require("linkifyjs");

const xThrottleDistance = 110;
const yThrottleDistance = 25;

export function toggleHeader(params: {
  tableId: string;
  type: "row" | "column";
  context: IBlockContext;
}) {
  const block = store.getState().blocks.dict[params.tableId];
  if (!block || !block.value[0].tableBlockColumnData) return;

  const newBlockValue = [...block.value];
  newBlockValue[0] = {
    ...newBlockValue[0],
    tableBlockColumnData: { ...(newBlockValue[0].tableBlockColumnData as any) },
  };

  if (!newBlockValue[0].tableBlockColumnData) return;

  if (params.type === "row") {
    newBlockValue[0].tableBlockColumnData.hasHeaderRow = !Boolean(
      newBlockValue[0].tableBlockColumnData?.hasHeaderRow
    );
  }

  if (params.type === "column") {
    newBlockValue[0].tableBlockColumnData.hasHeaderColumn = !Boolean(
      newBlockValue[0].tableBlockColumnData.hasHeaderColumn
    );
  }

  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(
    params.context
  );
  const action: ActionObject = {
    delta: {
      value: newBlockValue,
    },
    id: params.tableId,
    type: BlockActionTypes.update,
  };

  const antiAction = getAntiAction(action);
  saveActions.push(action);
  undoObj.actions.push(antiAction);
  reduxActions.push({
    type: SAVE_BLOCK_DATA,
    param: {
      id: block.id,
      blockData: block,
      delta: {
        value: newBlockValue,
      },
    },
  });

  setUpdateObjectToStore(saveActions, params.context);
  reduxActions.push({
    type: SET_UNDO,
    param: {
      undoObject: undoObj,
      contextId: params.context.id,
    },
  });
  store.dispatch(batchActions(reduxActions));
}

export function clearContent(params: {
  id: string;
  tableId: string;
  type: "row" | "column";
  context: IBlockContext;
}) {
  const block = store.getState().blocks.dict[params.tableId];
  if (!block || !block.value[0].tableBlockColumnData) return;

  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(
    params.context
  );

  if (params.type === "row") {
    const row = store.getState().blocks.dict[params.id];
    if (!row) return;
    const newBlockValue = row.value.map((val) => {
      const updatedVal = { ...val };
      updatedVal.type = LineValueType.text;
      updatedVal.value = "";
      return updatedVal;
    });
    const action: ActionObject = {
      delta: {
        value: newBlockValue,
      },
      id: row.id,
      type: BlockActionTypes.update,
    };
    const antiAction = getAntiAction(action);
    saveActions.push(action);
    undoObj.actions.push(antiAction);
    reduxActions.push({
      type: SAVE_BLOCK_DATA,
      param: {
        id: row.id,
        blockData: row,
        delta: {
          value: newBlockValue,
        },
      },
    });
  }

  if (params.type === "column") {
    for (const rowId of block.children) {
      const row = store.getState().blocks.dict[rowId];
      if (!row) continue;
      const newBlockValue = row.value.map((val) => {
        if (val.nodeId === params.id) {
          const updatedVal = { ...val };
          updatedVal.type = LineValueType.text;
          updatedVal.value = "";
          return updatedVal;
        }
        return val;
      });
      const action: ActionObject = {
        delta: {
          value: newBlockValue,
        },
        id: rowId,
        type: BlockActionTypes.update,
      };
      const antiAction = getAntiAction(action);
      saveActions.push(action);
      undoObj.actions.push(antiAction);

      reduxActions.push({
        type: SAVE_BLOCK_DATA,
        param: {
          id: row.id,
          blockData: row,
          delta: {
            value: newBlockValue,
          },
        },
      });
    }
  }

  setUpdateObjectToStore(saveActions, params.context);
  reduxActions.push({
    type: SET_UNDO,
    param: {
      undoObject: undoObj,
      contextId: params.context.id,
    },
  });
  store.dispatch(batchActions(reduxActions));
}

export function addRow(params: {
  tableId: string;
  insert: "after" | "before";
  refRowId: string;
  context: IBlockContext;
  count: number;
}) {
  const storeData = store.getState();
  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(
    params.context
  );
  const block = storeData.blocks.dict[params.tableId];
  if (!block) return;

  for (let i = 0; i < params.count; i++) {
    primitiveAddSingleBlock({
      context: params.context,
      newBlockValue: [],
      presets: {
        parentId: block.id,
        indentLevel: block.indentLevel + 1,
        baseId: storeData.workspace.id,
        containerId: block.containerId,
        containerType: block.containerType,
        lineType: LineType.tableRow,
      },
      saveActions,
      undoObject: undoObj,
      type:
        params.insert === "after"
          ? ADD_BLOCK_TYPES.addBlockAfter
          : ADD_BLOCK_TYPES.addBlockBefore,
      referencePointBlockId: params.refRowId,
    });
  }
  reduxActions.push({
    type: SET_UNDO,
    param: {
      undoObject: undoObj,
      contextId: params.context.id,
    },
  });
  setUpdateObjectToStore(saveActions, params.context);
  store.dispatch(batchActions(reduxActions));
}

export function addColumn(params: {
  tableId: string;
  insert: "after" | "before";
  refColumnId: string;
  context: IBlockContext;
  count: number;
}) {
  const block = store.getState().blocks.dict[params.tableId];
  if (!block || !block.value[0].tableBlockColumnData) return;
  const newBlockValue = [...block.value];
  newBlockValue[0] = {
    ...newBlockValue[0],
    tableBlockColumnData: { ...(newBlockValue[0].tableBlockColumnData as any) },
  };

  const currentColumns = newBlockValue[0].tableBlockColumnData?.order
    ? [...newBlockValue[0].tableBlockColumnData?.order]
    : [];

  const currentDict = { ...newBlockValue[0].tableBlockColumnData?.dict };

  const refIndex = currentColumns?.indexOf(params.refColumnId);

  for (let i = 0; i < params.count; i++) {
    const newColumnId = generateRandomString();
    const dictEntry = { id: newColumnId };
    const insertInIndex = params.insert === "after" ? refIndex + 1 : refIndex;
    currentColumns.splice(insertInIndex, 0, newColumnId);
    currentDict[newColumnId] = dictEntry;
  }

  newBlockValue[0].tableBlockColumnData = {
    ...newBlockValue[0].tableBlockColumnData,
    order: currentColumns,
    dict: currentDict,
  };

  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(
    params.context
  );
  const action: ActionObject = {
    delta: {
      value: newBlockValue,
    },
    id: params.tableId,
    type: BlockActionTypes.update,
  };

  const antiAction = getAntiAction(action);
  saveActions.push(action);
  undoObj.actions.push(antiAction);
  reduxActions.push({
    type: SAVE_BLOCK_DATA,
    param: {
      id: block.id,
      blockData: block,
      delta: {
        value: newBlockValue,
      },
    },
  });

  setUpdateObjectToStore(saveActions, params.context);
  reduxActions.push({
    type: SET_UNDO,
    param: {
      undoObject: undoObj,
      contextId: params.context.id,
    },
  });
  store.dispatch(batchActions(reduxActions));
}

export function removeRow(params: {
  tableId: string;
  refRowId: string;
  context: IBlockContext;
  includeThis: boolean;
  stopOnNonEmpty?: boolean;
}) {
  const block = store.getState().blocks.dict[params.tableId];

  if (block.children.length === 1) return;

  if (params.stopOnNonEmpty) {
    const targetBlock = store.getState().blocks.dict[params.refRowId];
    const textValue = stripHtml(getHtml(targetBlock.value));
    if (textValue.length > 0) return;
  }
  if (tableListeners.value.focusRowId === params.refRowId) {
    tableListeners.next({
      ...tableListeners.value,
      focusRowId: "",
      focusColumnId: "",
      focusTableId: "",
    });
  }

  blockModeDelete({
    context: params.context,
    blockId: params.refRowId,
    selectedBlocks: [params.refRowId],
  });
  return 1;
}

export function removeColumn(params: {
  tableId: string;
  remove: "after" | "before";
  refColumnId: string;
  context: IBlockContext;
  count: number;
  includeThis: boolean;
  stopOnNonEmpty?: boolean;
}) {
  const block = store.getState().blocks.dict[params.tableId];
  if (!block || !block.value[0].tableBlockColumnData) return;

  const newBlockValue = [...block.value];
  newBlockValue[0] = {
    ...newBlockValue[0],
    tableBlockColumnData: { ...(newBlockValue[0].tableBlockColumnData as any) },
  };

  const currentColumns = newBlockValue[0].tableBlockColumnData?.order
    ? [...newBlockValue[0].tableBlockColumnData?.order]
    : [];

  if (currentColumns.length === 1) return;

  const currentDict = { ...newBlockValue[0].tableBlockColumnData?.dict };

  const refIndex = currentColumns?.indexOf(params.refColumnId);
  let removeFromIndex;

  if (params.remove === "after") {
    if (params.includeThis) removeFromIndex = refIndex;
    else removeFromIndex = refIndex + 1;
  }

  if (params.remove === "before") {
    if (params.includeThis) removeFromIndex = refIndex - params.count + 1;
    else removeFromIndex = refIndex - params.count;
  }

  if (!removeFromIndex && removeFromIndex !== 0) return;

  const removedElements = currentColumns.splice(removeFromIndex, params.count);

  if (params.stopOnNonEmpty) {
    const rows = block.children;
    const cellCache = tableStoreData.value[params.tableId].cellCache;
    for (const column of removedElements) {
      for (const rowId of rows) {
        const address = rowId + column;
        const cell = cellCache.current[address];
        if (cell && cell.innerText.length > 0) {
          return;
        }
      }
    }
  }

  removedElements.forEach((el) => delete currentDict[el]);

  newBlockValue[0].tableBlockColumnData = {
    ...newBlockValue[0].tableBlockColumnData,
    order: [...currentColumns],
    dict: { ...currentDict },
  };

  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(
    params.context
  );
  const action: ActionObject = {
    delta: {
      value: newBlockValue,
    },
    id: params.tableId,
    type: BlockActionTypes.update,
  };

  const antiAction = getAntiAction(action);
  saveActions.push(action);
  undoObj.actions.push(antiAction);
  reduxActions.push({
    type: SAVE_BLOCK_DATA,
    param: {
      id: block.id,
      blockData: block,
      delta: {
        value: newBlockValue,
      },
    },
  });

  setUpdateObjectToStore(saveActions, params.context);
  reduxActions.push({
    type: SET_UNDO,
    param: {
      undoObject: undoObj,
      contextId: params.context.id,
    },
  });

  if (tableListeners.value.focusColumnId === params.refColumnId) {
    tableListeners.next({
      ...tableListeners.value,
      focusRowId: "",
      focusColumnId: "",
      focusTableId: "",
    });
  }

  store.dispatch(batchActions(reduxActions));

  return removedElements.length;
}

export function moveRow(params: {
  movingRowId: string;
  refRowId: string;
  tableId: string;
  context: IBlockContext;
  mode: "before" | "after";
}) {
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(
    params.context
  );
  moveBlocks({
    type: params.mode,
    options: "sibling",
    referenceId: params.refRowId,
    selectedBlocks: [params.movingRowId],
    saveActions,
    undoObject: undoObj,
  });
  setUpdateObjectToStore(saveActions, params.context);
  store.dispatch({
    type: SET_UNDO,
    param: {
      undoObject: undoObj,
      contextId: params.context.id,
    },
  });
}

export function moveColumn(params: {
  movingColumnId: string;
  refColumnId: string;
  tableId: string;
  context: IBlockContext;
  mode: "before" | "after";
}) {
  const block = store.getState().blocks.dict[params.tableId];
  if (!block || !block.value[0].tableBlockColumnData) return;
  const newBlockValue = [...block.value];
  newBlockValue[0] = {
    ...newBlockValue[0],
    tableBlockColumnData: { ...(newBlockValue[0].tableBlockColumnData as any) },
  };

  const currentColumns = newBlockValue[0].tableBlockColumnData?.order
    ? [...newBlockValue[0].tableBlockColumnData?.order]
    : [];

  const currentDict = { ...newBlockValue[0].tableBlockColumnData?.dict };

  const currentIndex = currentColumns.indexOf(params.movingColumnId);

  currentColumns.splice(currentIndex, 1);

  const refIndex = currentColumns.indexOf(params.refColumnId);

  const insertInIndex = params.mode === "after" ? refIndex + 1 : refIndex;
  currentColumns.splice(insertInIndex, 0, params.movingColumnId);

  newBlockValue[0].tableBlockColumnData = {
    ...newBlockValue[0].tableBlockColumnData,
    order: currentColumns,
    dict: currentDict,
  };

  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(
    params.context
  );
  const action: ActionObject = {
    delta: {
      value: newBlockValue,
    },
    id: params.tableId,
    type: BlockActionTypes.update,
  };

  const antiAction = getAntiAction(action);
  saveActions.push(action);
  undoObj.actions.push(antiAction);
  reduxActions.push({
    type: SAVE_BLOCK_DATA,
    param: {
      id: block.id,
      blockData: block,
      delta: {
        value: newBlockValue,
      },
    },
  });

  setUpdateObjectToStore(saveActions, params.context);
  reduxActions.push({
    type: SET_UNDO,
    param: {
      undoObject: undoObj,
      contextId: params.context.id,
    },
  });
  store.dispatch(batchActions(reduxActions));
}

const throttleUpdates = throttle(
  (saveActions, context) => {
    setUpdateObjectToStore(saveActions, context);
  },
  1000,
  { trailing: true }
);

const resizeColumn = (params: {
  tableId: string;
  context: IBlockContext;
  newSize: number;
  columnId: string;
}) => {
  const storeData = store.getState();
  const reduxActions: any[] = [];
  const saveActions: ActionObject[] = [];
  const undoObj: ActionWrapperObject = createActionWrapperObject(
    params.context
  );
  const block = storeData.blocks.dict[params.tableId];
  if (!block || !block.value[0].tableBlockColumnData) return;

  const columnData = {
    ...block.value[0].tableBlockColumnData,
  };

  columnData.dict = { ...columnData.dict };
  columnData.dict[params.columnId] = { ...columnData.dict[params.columnId] };
  columnData.dict[params.columnId].manualSize = params.newSize;

  const value: ILineValue[] = [
    {
      type: LineValueType.text,
      value: "",
      children: [],
      tableBlockColumnData: columnData,
    },
  ];

  const action: ActionObject = {
    delta: {
      value,
    },
    id: block.id,
    type: BlockActionTypes.update,
  };

  const antiAction = getAntiAction(action);
  saveActions.push(action);
  undoObj.actions.push(antiAction);
  reduxActions.push({
    type: SAVE_BLOCK_DATA,
    param: {
      id: block.id,
      blockData: block,
      delta: {
        value,
      },
    },
  });

  reduxActions.push({
    type: SET_UNDO,
    param: {
      undoObject: undoObj,
      contextId: params.context.id,
    },
  });
  throttleUpdates(saveActions, params.context);

  store.dispatch(batchActions(reduxActions));
};

export const resizeHandler = (
  mouseDownEvent: React.MouseEvent,
  cellData: {
    tableId: string;
    columnId: string;
    context: IBlockContext;
    ref: React.MutableRefObject<HTMLTableCellElement | null>;
    onStopAction: () => void;
  }
) => {
  let startSize = 40;
  startSize = cellData.ref.current
    ? cellData.ref.current.getBoundingClientRect().width
    : 40;

  const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

  const onMouseMove = throttle((mouseMoveEvent: any) => {
    const newX = startSize - startPosition.x + mouseMoveEvent.pageX;
    resizeColumn({
      tableId: cellData.tableId,
      context: cellData.context,
      newSize: newX < 40 ? 40 : newX,
      columnId: cellData.columnId,
    });
  }, 10);

  function onMouseUp() {
    cellData.onStopAction();
    document.body.removeEventListener("mousemove", onMouseMove);
    // uncomment the following line if not using `{ once: true }`
    document.body.removeEventListener("mouseup", onMouseUp, { capture: true });
    document.body.style.cursor = "unset";
  }

  document.body.style.cursor = "col-resize";
  document.body.addEventListener("mousemove", onMouseMove);
  document.body.addEventListener("mouseup", onMouseUp, { capture: true });
};

export const dragAndDropHandler = (
  target: EventTarget & Element,
  cellData: {
    tableId: string;
    rowId?: string;
    columnId?: string;
    columnIndex?: number;
    rowIndex?: number;
    context: IBlockContext;
  }
) => {
  const tableEl = tableStoreData.value[cellData.tableId]?.tableRef.current;
  // let rows: string[] = [];
  // let columns: string[] = [];

  const element = target;

  if (!tableEl || !element) return;
  const dimentions = tableEl.getBoundingClientRect();

  const parentElement = element.parentElement;
  const targetDimensions = parentElement?.getBoundingClientRect();

  const maxWidth =
    dimentions.width - (targetDimensions ? targetDimensions.width / 2 : 0);

  const maxHeight =
    dimentions.height - (targetDimensions ? targetDimensions.height / 2 : 0);

  const tableBlock = store.getState().blocks.dict[cellData.tableId];
  if (!tableBlock || !tableBlock.value[0].tableBlockColumnData) return;

  // rows = tableBlock.children;
  // columns = tableBlock.value[0].tableBlockColumnData.order;

  const onMouseMove = throttle((mouseMoveEvent: any) => {
    if (cellData.columnId) {
      let newX =
        mouseMoveEvent.pageX -
        (dimentions ? dimentions.left : 0) -
        (targetDimensions ? targetDimensions.width / 2 : 0);

      if (newX < 0) newX = 0;
      if (newX > maxWidth) newX = maxWidth;

      tableDndObserver.next({
        ...tableDndObserver.value,
        tableId: cellData.tableId,
        dragColumnId: cellData.columnId,
        dragColumnPosition: newX,
        dragColumnIndex: cellData.columnIndex,
        dragRowId: "",
        dragRowPosition: 0,
      });
    }

    if (cellData.rowId) {
      let newY =
        mouseMoveEvent.pageY -
        (dimentions ? dimentions.top : 0) -
        (targetDimensions ? targetDimensions.height / 2 : 0);

      if (newY < 0) newY = 0;
      if (newY > maxHeight) newY = maxHeight;
      tableDndObserver.next({
        ...tableDndObserver.value,
        tableId: cellData.tableId,
        dragRowId: cellData.rowId,
        dragRowPosition: newY,
        dragRowIndex: cellData.rowIndex,
        dragColumnId: "",
        dragColumnPosition: 0,
      });
    }
  }, 10);

  function onMouseUp() {
    document.body.removeEventListener("mousemove", onMouseMove);
    document.body.removeEventListener("mouseup", onMouseUp);
    document.body.removeEventListener("blur", onMouseUp);
    tableDndObserver.next({
      tableId: "",
      hoveredDropZoneColumnId: "",
      hoveredDropZoneRowId: "",
      dragColumnId: "",
      dragColumnPosition: 0,
      dragRowId: "",
      dragRowPosition: 0,
      dropZoneMode: "",
    });

    document.body.style.setProperty("cursor", "unset");
    (element as any).style.setProperty("cursor", "pointer");
  }

  // const getHoveredColumn = (newX: number) => {
  //   const cellCache = tableStoreData.value[cellData.tableId]?.cellCache.current;
  //   const assumedPosition = Math.floor(newX / xThrottleDistance);
  //   const assumedId = columns[assumedPosition];
  //   console.log(assumedId);
  // };

  // const getHoveredRow = (newY: number) => {
  //   const cellCache = tableStoreData.value[cellData.tableId]?.cellCache.current;
  //   const assumedPosition = Math.floor(newY / yThrottleDistance);
  //   const assumedId = rows[assumedPosition];
  //   console.log(assumedId);
  // };

  document.body.style.setProperty("cursor", "grab", "important");
  (element as any).style.setProperty("cursor", "grab", "important");

  document.body.addEventListener("mousemove", onMouseMove);
  document.body.addEventListener("mouseup", onMouseUp);
  document.body.addEventListener("blur", onMouseUp);
};

export const dragAndDropStartHandler = (
  mouseDownEvent: React.MouseEvent,
  cellData: {
    tableId: string;
    rowId?: string;
    columnId?: string;
    columnIndex?: number;
    rowIndex?: number;
    context: IBlockContext;
  }
) => {
  const MOVE_BUFFER = 10;
  const target = mouseDownEvent.currentTarget;

  const onMouseMove = (mouseMoveEvent: any) => {
    const dragDistance = distance(
      coords(mouseDownEvent),
      coords(mouseMoveEvent)
    );

    if (dragDistance >= MOVE_BUFFER) {
      onMouseUp();
      dragAndDropHandler(target, cellData);
    }
  };

  function onMouseUp() {
    document.body.removeEventListener("mousemove", onMouseMove);
    document.body.removeEventListener("mouseup", onMouseUp);
  }

  document.body.addEventListener("mousemove", onMouseMove);
  document.body.addEventListener("mouseup", onMouseUp);
};

export const resizeTableHandler = (
  mouseDownEvent: React.MouseEvent,
  cellData: {
    tableId: string;
    context: IBlockContext;
    direction: "x" | "y" | "all";
    tableRef: React.MutableRefObject<HTMLTableElement | null>;
  }
) => {
  if (!cellData.context.canEdit) return;
  let addedColumns = 0;
  let addedRows = 0;
  const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

  const onMouseMove = throttle((mouseMoveEvent: any) => {
    const newX = mouseMoveEvent.pageX - startPosition.x;
    const newY = mouseMoveEvent.pageY - startPosition.y;
    setEdgeWasDragged();

    if (
      (cellData.direction === "x" || cellData.direction === "all") &&
      Math.floor(
        (newX - addedColumns * xThrottleDistance) / xThrottleDistance
      ) > 0
    ) {
      const count = Math.floor(newX / xThrottleDistance) - addedColumns;
      addedColumns = addedColumns + count;
      const blockData = store.getState().blocks.dict[cellData.tableId];
      const columnData = blockData.value[0].tableBlockColumnData;
      if (!columnData) return;
      const lastColumn = columnData.order[columnData.order.length - 1];
      addColumn({
        tableId: cellData.tableId,
        insert: "after",
        refColumnId: lastColumn,
        context: cellData.context,
        count,
      });
    }

    if (
      (cellData.direction === "y" || cellData.direction === "all") &&
      Math.floor((newY - addedRows * yThrottleDistance) / yThrottleDistance) > 0
    ) {
      const count = Math.floor(
        (newY - addedRows * yThrottleDistance) / yThrottleDistance
      );
      addedRows = addedRows + count;
      const blockData = store.getState().blocks.dict[cellData.tableId];
      const rowData = blockData.children;
      if (!rowData) return;
      const lastRow = rowData[rowData.length - 1];
      addRow({
        tableId: cellData.tableId,
        insert: "after",
        refRowId: lastRow,
        context: cellData.context,
        count,
      });
    }

    if (
      (cellData.direction === "x" || cellData.direction === "all") &&
      Math.floor(
        (-newX + addedColumns * xThrottleDistance) / xThrottleDistance
      ) > 0
    ) {
      const count = Math.floor(
        (-newX + addedColumns * xThrottleDistance) / xThrottleDistance
      );

      const blockData = store.getState().blocks.dict[cellData.tableId];
      const columnData = blockData.value[0].tableBlockColumnData;
      if (!columnData || columnData.order.length === 1) return;
      const lastColumn = columnData.order[columnData.order.length - 1];
      const removed = removeColumn({
        tableId: cellData.tableId,
        includeThis: true,
        refColumnId: lastColumn,
        remove: "before",
        stopOnNonEmpty: true,
        context: cellData.context,
        count,
      });

      if (removed) addedColumns = addedColumns - count;
    }

    if (
      (cellData.direction === "y" || cellData.direction === "all") &&
      Math.floor((-newY + addedRows * yThrottleDistance) / yThrottleDistance) >
        0
    ) {
      const blockData = store.getState().blocks.dict[cellData.tableId];
      const rowData = blockData.children;
      if (!rowData || rowData.length === 1) return;

      const lastRow = rowData[rowData.length - 1];

      const removed = removeRow({
        tableId: cellData.tableId,
        context: cellData.context,
        includeThis: true,
        refRowId: lastRow,
        stopOnNonEmpty: true,
      });
      if (removed) addedRows = addedRows - removed;
    }
  }, 10);

  function onMouseUp(e: any) {
    onMouseMove.flush();
    document.body.removeEventListener("mousemove", onMouseMove);
    document.body.removeEventListener("mouseup", onMouseUp, { capture: true });
    document.body.style.cursor = "unset";
  }

  if (cellData.direction === "x") document.body.style.cursor = "col-resize";
  if (cellData.direction === "y") document.body.style.cursor = "row-resize";
  if (cellData.direction === "all") document.body.style.cursor = "nwse-resize";
  document.body.addEventListener("mousemove", onMouseMove);
  document.body.addEventListener("mouseup", onMouseUp, { capture: true });
};

export const handleMoveToPreviousBlock = (
  e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent,
  currentBlockId: string,
  context: IBlockContext,
  changeBlock: any
) => {
  const newState = store.getState();
  const block = getBlockById(newState.blocks.dict, currentBlockId);

  const caret = 0;
  const blockState = store.getState().blocks;
  const currentContext = getCurrentContext(blockState, context.id);
  let prevBlockId = getPreviousBlock(blockState, block, currentContext);

  if (prevBlockId) {
    e.preventDefault();
    e.stopPropagation();
    const newFocusedBlock: any = context.ref.current?.querySelectorAll(
      `[data-block-id="${prevBlockId}"]`
    )[0];
    changeBlock(
      currentBlockId,
      newFocusedBlock,
      e,
      TypeOfLineMovement.endOfLine,
      caret,
      DocumentModes.INSERT,
      prevBlockId
    );
  } else {
    const titleRef = checkFocusTitle(block, context);
    if (titleRef) {
      e.preventDefault();
      e.stopPropagation();
      changeBlock(
        currentBlockId,
        titleRef,
        e,
        TypeOfLineMovement.previousCursorState,
        0,
        DocumentModes.BLOCK,
        ""
      );
    }
  }
};

export const handleMoveToNextBlock = (
  e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent,
  currentBlockId: string,
  context: IBlockContext,
  changeBlock: any
) => {
  const newState = store.getState();
  const block = getBlockById(newState.blocks.dict, currentBlockId);

  const caret = 0;
  const currentContext = getCurrentContext(newState.blocks, context.id);
  const nextBlockId = getNextBlockId(newState.blocks, block, currentContext);

  if (nextBlockId) {
    e.preventDefault();
    e.stopPropagation();
    const focusedBlock: any = context.ref.current?.querySelectorAll(
      `[data-block-id="${nextBlockId}"]`
    )[0];
    if (focusedBlock)
      changeBlock(
        currentBlockId,
        focusedBlock,
        e,
        TypeOfLineMovement.previousCursorState,
        caret,
        DocumentModes.INSERT,
        nextBlockId
      );
  } else {
    if (context.canEdit) {
      addBlock({
        context,
        currentBlock: block,
        focus: "newBlock",
        newBlockValue: [],
        type: ADD_BLOCK_TYPES.addBlockAfter,
        presetData: { lineType: LineType.text },
      });
    }
  }
};

export const pasteHandler = (e: React.ClipboardEvent<HTMLElement>) => {
  e.preventDefault();
  const addedText = e.clipboardData.getData("text/plain");
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    if (selection && selection.rangeCount > 0) {
      const caret = selection.getRangeAt(0);
      if (caret.toString().length > 0) {
        const test = linkifyTest.find(addedText);
        if (test.length === 1) {
          const caretPosition = checkCaretPosition(e.currentTarget);
          const entry = test[0];
          const contents = caret.cloneContents();
          contents.childNodes.forEach((child) => {
            if (child.nodeType === 3) {
              const newChild = document.createElement("a");
              newChild.classList.add("decorated");
              newChild.href = entry.href;
              newChild.textContent = child.textContent;
              contents.replaceChild(newChild, child);
            }
          });

          caret.deleteContents();
          caret.insertNode(contents);
          moveCaretToPreviousPosition(e.currentTarget, caretPosition);

          return;
        }
      }
      caret.deleteContents();
      const textNode1 = document.createTextNode(" ");
      caret.insertNode(textNode1);
      caret.setStartAfter(textNode1);
      caret.setEndAfter(textNode1);
      selection.removeAllRanges();
      const range = new Range();

      const lastEl = document.createTextNode(addedText);
      caret.insertNode(lastEl);

      range.setStartAfter(lastEl);
      range.setEndAfter(lastEl);
      const textNode = document.createTextNode(" ");
      range.collapse();
      range.insertNode(textNode);
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.addRange(range);

      const caretPosition = checkCaretPosition(
        e.currentTarget as HTMLDivElement
      );

      const element = e.currentTarget;

      setTimeout(() => {
        element.innerHTML = linkifyHtml(element.innerHTML, {
          defaultProtocol: "https",
          validate: true,
        });
        moveCaretToPreviousPosition(element, caretPosition);
      }, 0);
    }
  }
};

const generateRandomString = () =>
  (Math.random() + 1).toString(36).substring(7);

function coords(event: React.MouseEvent) {
  return { x: event.clientX, y: event.clientY };
}

function distance(
  pos1: { x: number; y: number },
  pos2: { x: number; y: number }
) {
  const dx = pos1.x - pos2.x;
  const dy = pos1.y - pos2.y;
  return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
}
