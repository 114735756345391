import { useMemo } from "react";
import { useShallowSelector } from "utilities/hooks";
import { AuditOutlined, IssuesCloseOutlined } from "@ant-design/icons";
import { GeneralViewsNames, TasksViewModes } from "utilities/types";
import CheckSquareTwoTone from "icons/Components/CheckSquareTwoTone";

enum Options {
  tasks = "tasks",
  projects = "projects",
  reviewing = "reviewing",
}

export const useMyWorkNavigationItems = () => {
  const base = useShallowSelector((store) => store.workspace);

  const options = useMemo(() => {
    const possibleOptions: Options[] = [];
    orderedOptions.forEach((option) => {
      possibleOptions.push(option);
    });
    return possibleOptions.map((option) => optionsData[option]);
  }, [base.id]);

  return options;
};

const optionsData = {
  [Options.tasks]: {
    key: Options.tasks,
    name: () => "Tasks",
    icon: <AuditOutlined />,
    url: () => "my-work",
    secondaryViewConfig: () => {
      return {
        viewName: GeneralViewsNames.MyTasks,
        taskViewMode: TasksViewModes.MyTasks,
      };
    },
  },
  [Options.projects]: {
    key: "roadmap",
    name: () => {
      return "Projects";
    },
    icon: <CheckSquareTwoTone />,
    url: () => `my-work/projects`,
    secondaryViewConfig: () => ({
      viewName: GeneralViewsNames.MyProjects,
    }),
  },
  [Options.reviewing]: {
    key: "reviewing",
    name: () => {
      return "Reviewing";
    },
    icon: <IssuesCloseOutlined />,
    url: () => `my-work/reviewing`,
    secondaryViewConfig: () => ({
      viewName: GeneralViewsNames.Reviewing,
      taskViewMode: TasksViewModes.Reviewing,
    }),
  },
};

const orderedOptions = [Options.tasks, Options.projects, Options.reviewing];
