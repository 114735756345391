import { groupApi } from "clientApi/groupsApi";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import NotFound from "screens/NotFound";
import { REMOVE_GROUP_CONTEXT, SET_GROUP_CONTEXT } from "store/actions";
import { usePaneId } from "store/reducers/filterReducer";
import { useShallowSelector } from "utilities/hooks";

const Group: React.FC<{ groupSlug: string; groupId?: string }> = ({
  children,
  groupId,
  groupSlug,
}) => {
  const dispatch = useDispatch();
  const group = useShallowSelector((state) => {
    return !groupId
      ? state.groups.dict[state.groups.slugDict[groupSlug]?.id]
      : state.groups.dict[groupId];
  });
  const userGroups = useShallowSelector((state) => state.groups.userGroups);

  const paneId = usePaneId();

  useEffect(() => {
    if (group) {
      dispatch({
        type: SET_GROUP_CONTEXT,
        paneId: paneId,
        groupId: group.id,
        groupSlug: groupSlug,
      });
      if (!userGroups.includes(group.id)) {
        groupApi.loadGroupWork(group.id);
      }
    }
  }, [group, paneId]);

  useEffect(() => {
    return () => {
      dispatch({
        type: REMOVE_GROUP_CONTEXT,
        paneId: paneId,
      });
    };
  }, [paneId]);

  if (!group) {
    return <NotFound />;
  }
  return <>{children}</>;
};

export default Group;
