import Conditional from "components/Conditional";
import styles from "./dangerZone/dangerZone.module.scss";

interface section {
  heading: any;
  description: any;
  component: any;
}
export type DangerSections = Array<section>;

const DangerZone: React.FC<{
  DangerSections: DangerSections;
}> = ({ DangerSections, children }) => {
  if (DangerSections.length === 0) {
    return <></>;
  }
  return (
    <>
      <h4 className="secondary">Danger Zone</h4>
      <div className={styles.dangerZone}>
        <Conditional on={children}>
          <div className={styles.dangerZoneSection}>{children}</div>
        </Conditional>
        {DangerSections.map((ele, i) => (
          <div key={i} className={styles.dangerZoneSection}>
            <div>
              <div className="body2 bold mb-2">{ele.heading}</div>
              <div className="caption ">
                <span className="secondary">{ele.description}</span>
              </div>
            </div>
            <div>{ele.component}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DangerZone;
