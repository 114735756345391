import { AppstoreOutlined } from "@ant-design/icons";
import CheckSquareTwoTone from "icons/Components/CheckSquareTwoTone";
import React from "react";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { useMyWorkNavigationItems } from "../../defaultWorkView/myWorkUtils/MyWorkNavigationItems";
import { ContainerViewModes } from "../../detailView/ContainerViews";
import { useTemplatesNavigationOptions } from "../../templates/TemplatesNavigationItems";
import { useGroupRolesNavigationItems } from "../../groups&roles/Groups-rolesNavigationsItems";
import { useBaseNavigationOptions } from "../baseOverview/BaseNavigationItems";
import { useGroupNavigationOptions } from "../groupOverview/GroupNavigationItems";
import NavigationSection from "./NavigationSection";

const TopNavSubroutes: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const subRoutesParams = useShallowSelector(
    (store) => store.topNav[paneId]?.subRoutesParams
  );

  if (!subRoutesParams) return <></>;
  switch (subRoutesParams.type) {
    case "workItem":
      return <WorkItemSubroutes paneId={paneId} />;
    case "base":
      return <BaseNavigationItems paneId={paneId} />;
    case "myWork":
      return <MyWorkNavigationItems paneId={paneId} />;
    case "templates":
      return <TemplatesNavigationItems paneId={paneId} />;
    case "groups&roles":
      return <GroupsAndRolesNavigationItems paneId={paneId} />;
  }
  if (subRoutesParams?.groupId)
    return (
      <GroupNavigationItems groupId={subRoutesParams.groupId} paneId={paneId} />
    );
  return <></>;
};

const BaseNavigationItems: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const baseNavigationItems = useBaseNavigationOptions();
  return <NavigationSection routes={baseNavigationItems} paneId={paneId} />;
};

const GroupNavigationItems: React.FC<{
  groupId: string;
  paneId: ChunkDestination;
}> = ({ groupId, paneId }) => {
  const groupNavigationItems = useGroupNavigationOptions(groupId);
  return <NavigationSection routes={groupNavigationItems} paneId={paneId} />;
};

const MyWorkNavigationItems: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const baseNavigationItems = useMyWorkNavigationItems();
  return <NavigationSection routes={baseNavigationItems} paneId={paneId} />;
};

const TemplatesNavigationItems: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const templatesNavigationItems = useTemplatesNavigationOptions();
  return (
    <NavigationSection routes={templatesNavigationItems} paneId={paneId} />
  );
};

const WorkItemSubroutes: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const subRoutesParams = useShallowSelector(
    (store) => store.topNav[paneId]?.subRoutesParams
  );
  const workItemSubroutes = [
    {
      key: ContainerViewModes.document,
      name: () => "Details",
      icon: <AppstoreOutlined />,
      action: () => {
        if (subRoutesParams?.action) {
          subRoutesParams.action(ContainerViewModes.document);
        }
      },
      url: () => "",
      secondaryViewConfig: () => {},
    },
    {
      key: ContainerViewModes.work,
      name: () => "Subtasks",
      icon: <CheckSquareTwoTone />,
      action: () => {
        if (subRoutesParams?.action) {
          subRoutesParams.action(ContainerViewModes.work);
        }
      },
      url: () => "",
      secondaryViewConfig: () => {},
    },
  ];
  return <NavigationSection routes={workItemSubroutes} paneId={paneId} />;
};

const GroupsAndRolesNavigationItems: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const groupRolesNavigationItems = useGroupRolesNavigationItems();
  return (
    <NavigationSection routes={groupRolesNavigationItems} paneId={paneId} />
  );
};

export default TopNavSubroutes;
