import { Input, Switch, Popover, Modal as AntModal } from "antd";
import Button, { ButtonTypes } from "components/Button";
import React, {
  ReactChild,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ClarityStore } from "store/storeExporter";
import { Abilities, IInviteLink, UserRole } from "utilities/types";
import {
  EditingLink,
  LinkTokenGate,
} from "./inviteLinkContainer/NewInviteLinkRow";
import styles from "./inviteLinkContainer/inviteLinkContainer.module.scss";
import Modal from "./Modal";
import { invitationsApi } from "clientApi/invitationsApi";
import { shallowEqual, useSelector } from "react-redux";
import {
  EditOutlined,
  ClockCircleOutlined,
  UserAddOutlined,
  FontColorsOutlined,
  IdcardOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  CloseOutlined,
  KeyOutlined,
  LinkOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  useAbilityChecker,
  useRelativeExpirationDate,
  useRelativeExpirationDateValue,
} from "editor/utils/customHooks";
import OptionGroup from "./OptionGroup";
import InviteDetailsSummary from "./inviteLinkContainer/InviteDetailsSummary";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { copyTextToClipboard } from "utilities/copyUtils";
import { axiosInstance } from "index";

const { confirm } = AntModal;

const InviteLinkContainer: React.FC<{
  baseId: string;
  showTitle: boolean;
}> = ({ showTitle }) => {
  const base_url = window.location.origin;
  const links: string[] = useSelector(
    (state: ClarityStore) => state.workspace.invitationLinksIds,
    shallowEqual
  );

  const baseId: string = useSelector(
    (state: ClarityStore) => state.workspace.id,
    shallowEqual
  );

  useEffect(() => {
    axiosInstance.get(`/api/invite-link/all/${baseId}`).then((res) => {
      invitationsApi.setBaseInvitationLinks(res.data);
    });
  }, []);

  const canEditLink = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MEMBERS,
  });

  return (
    <div className={styles.linkContainer}>
      {showTitle && <h3>Invite through link</h3>}
      {links.length === 0 && (
        <span className="caption secondary">No invite links yet</span>
      )}
      {links.length > 0 && (
        <div className="label bold secondary mb-5">LINKS</div>
      )}
      <>
        {links.map((link) => (
          <InviteLink
            key={link}
            linkId={link}
            canEditLink={canEditLink}
            base_url={base_url}
            baseId={baseId}
          />
        ))}
      </>
    </div>
  );
};

const InviteLink: React.FC<{
  linkId: string;
  base_url: string;
  baseId: string;
  canEditLink: boolean;
}> = ({ linkId, base_url, baseId, canEditLink }) => {
  const link: IInviteLink = useSelector(
    (state: ClarityStore) => state.workspace.invitationLinksDict[linkId],
    shallowEqual
  );
  const [isCopied, setIsCopied] = useState(false);
  const [popoverEnabled, setPopoverEnabled] = useState(false);
  const [isHovered, setisHovered] = useState(false);
  const isCopiedTimeout = useRef<null | NodeJS.Timeout>(null);
  const inputRef = useRef<any>(null);
  const [detailsOpen, setdetailsOpen] = useState(false);
  const relativeExpiration = useRelativeExpirationDateValue(link.endDate);

  const tokenGate = useSelector(
    (state: ClarityStore) =>
      link && link.tokenGateId
        ? state.tokenGates.dict[link.tokenGateId]
        : undefined,
    shallowEqual
  );

  const status = useMemo(() => {
    const hasExpired = link.endDate
      ? new Date() > new Date(link.endDate)
      : false;
    let detail;
    if (
      (link.endDate && !hasExpired) ||
      (link.reedemLimit && link.reedemLimit > link.currentCount)
    ) {
      detail = `This link will expire `;
      if (relativeExpiration) {
        detail = detail + relativeExpiration;
        if (link.reedemLimit) detail = detail + " or, ";
      }
      if (link.reedemLimit) {
        detail = detail + `after ${link.reedemLimit - link.currentCount} uses`;
      }
    }
    if (hasExpired) {
      return {
        code: "Expired",
        reason: "Link deadline has expired",
        detail,
        icon: <EyeInvisibleOutlined />,
      };
    }
    if (link.reedemLimit && link.currentCount >= link.reedemLimit) {
      return {
        code: "Expired",
        reason: "Link has hit its reedem limit",
        detail,
        icon: <EyeInvisibleOutlined />,
      };
    }
    if (link.retired) {
      return {
        code: "Retired",
        reason: "Link has been retired",
        detail,
        icon: <EyeInvisibleOutlined />,
      };
    }

    return {
      code: "Active",
      reason: "Link is active and ready to be shared",
      detail,
      icon: <EyeOutlined />,
    };
  }, [link, relativeExpiration]);

  const getNameClassName = useCallback(() => {
    const addedClasses = !link.name ? " secondary" : "";
    return "body2 bold w100 ellipsis-nowrap" + addedClasses;
  }, [link?.name]);

  const getRowClassName = useCallback(() => {
    let className = styles.inviteRow;
    if (status.code === "Expired")
      className = className + " " + styles.inactive;

    return className;
  }, [link, status]);

  const getInviteLinkClassName = useCallback(() => {
    let classNames = styles.inviteLink;
    if (isCopied) classNames = classNames + " " + styles.copied;
    return classNames;
  }, [isCopied]);

  const copyLink = useCallback(() => {
    inputRef.current?.focus({
      cursor: "all",
    });
    copyTextToClipboard(base_url + "/invite-link/" + link.token);
    setIsCopied(true);
    if (isCopiedTimeout.current) clearTimeout(isCopiedTimeout.current);
    isCopiedTimeout.current = setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }, [link?.token]);

  const openpopover = () => {
    setPopoverEnabled(true);
  };
  const handleVisibleChange = (visible: boolean) => {
    setPopoverEnabled(visible);
  };

  const buttonStyles = useMemo(() => {
    const buttonStylesObj = {
      button: {
        transition: "all .3s",
      } as React.CSSProperties,
      label: {} as React.CSSProperties,
    };
    if (isCopied) {
      buttonStylesObj.button = {
        ...buttonStylesObj.button,
        backgroundColor: "#52c41a",
      };
      buttonStylesObj.label = {
        opacity: 1,
        color: "#fff",
      };
    }
    return buttonStylesObj;
  }, [isCopied]);

  if (!link) return <></>;

  return (
    <div
      className={getRowClassName()}
      onMouseEnter={() => setisHovered(true)}
      onMouseLeave={() => setisHovered(false)}
    >
      <div className={styles.linkRow}>
        <div className={styles.linkRow_left}>
          {<LinkOutlined className={styles.linkRow_icon} />}
          <div>
            <div className={getNameClassName()}>
              {link.name ? link.name : "No Description"}
            </div>
            {link.tokenGateId && (
              <span className={`small secondary lh19`}>
                {" "}
                {tokenGate?.name} .{" "}
              </span>
            )}
            {status.code === "Expired" && (
              <span className="small secondary">Expired : {status.reason}</span>
            )}
            {status.detail && status.code !== "Expired" && (
              <span className="small secondary">{status.detail}</span>
            )}
          </div>
        </div>
        <div className={styles.linkRow_right}>
          {isHovered && (
            <Button
              onClick={() => setdetailsOpen(true)}
              buttonType={ButtonTypes.LINK}
              disabled={!canEditLink}
              icon={<EditOutlined />}
            />
          )}
          <Popover
            content={
              <>
                <div
                  className={`${getInviteLinkClassName()} ${
                    styles.copySection
                  }`}
                >
                  <Input
                    className={styles.noStyleLink}
                    ref={inputRef}
                    readOnly={true}
                    defaultValue={base_url + "/invite-link/" + link.token}
                    onBeforeInput={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  />
                  <Button
                    buttonType={ButtonTypes.LINK}
                    style={buttonStyles.button}
                    labelStyle={buttonStyles.label}
                    onClick={copyLink}
                  >
                    <LinkOutlined /> {isCopied ? "Copied" : "Copy Link"}
                  </Button>
                </div>
              </>
            }
            trigger="click"
            visible={popoverEnabled}
            placement="bottom"
            onVisibleChange={handleVisibleChange}
          >
            <Button buttonType={ButtonTypes.LINK} onClick={openpopover}>
              Copy Link
            </Button>
          </Popover>
          <Switch
            onChange={(e) => {
              const delta = {
                retired: !e,
              };
              axiosInstance
                .patch("/api/invite-link", {
                  inviteLinkId: link.id,
                  delta,
                  baseId: baseId,
                })
                .then((res) => {
                  const link = res.data;
                  invitationsApi.updateInvitationLink({
                    delta: link,
                    type: "update",
                    id: link.id,
                  });
                });
            }}
            checked={!link.retired}
            disabled={!canEditLink}
            checkedChildren={<CheckOutlined />}
          />
        </div>
      </div>
      <div>
        {detailsOpen && (
          <InvitationDetails
            setdetailsOpen={setdetailsOpen}
            link={link}
            status={status}
            baseId={baseId}
          />
        )}
      </div>
    </div>
  );
};

const InvitationDetails: React.FC<{
  setdetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  link: IInviteLink;
  status: {
    icon: ReactChild;
    code: string;
    reason: string;
  };
  baseId: string;
}> = ({ setdetailsOpen, link, status, baseId }) => {
  const [mode, setMode] = useState<"details" | "edit">("details");

  return (
    <Modal hideModal={() => setdetailsOpen(false)}>
      {mode === "details" && (
        <DetailView
          link={link}
          status={status}
          setMode={setMode}
          setdetailsOpen={setdetailsOpen}
          baseId={baseId}
        />
      )}
      {mode === "edit" && (
        <EditView link={link} setMode={setMode} baseId={baseId} />
      )}
    </Modal>
  );
};

const DetailView: React.FC<{
  link: IInviteLink;
  status: {
    icon: ReactChild;
    code: string;
    reason: string;
  };
  setdetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMode: React.Dispatch<React.SetStateAction<"details" | "edit">>;
  baseId: string;
}> = ({ link, status, setMode, setdetailsOpen, baseId }) => {
  const expiration = useRelativeExpirationDate(link.endDate);
  const hasExpired = link.endDate ? new Date() > new Date(link.endDate) : false;
  const deleteLink = useCallback(() => {
    const delta: Partial<IInviteLink> = {
      deleted: true,
    };
    axiosInstance
      .patch("/api/invite-link", {
        inviteLinkId: link.id,
        delta,
        baseId: baseId,
      })
      .then((res) => {
        setdetailsOpen(false);
        axiosInstance.get(`/api/invite-link/all/${baseId}`).then((res) => {
          invitationsApi.setBaseInvitationLinks(res.data);
        });
      })
      .catch(() => {
        setMode("details");
      });
  }, []);

  const showDeleteLinkConfirm = () => {
    return confirm({
      title: `Are you sure you want to delete this link?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      maskClosable: true,
      okText: "Delete",
      centered: true,
      onOk() {
        deleteLink();
      },
      onCancel() {},
    });
  };

  return (
    <>
      <div className={styles.titleRow}>
        <h4>Link details</h4>
        <Button
          buttonType={ButtonTypes.LINK}
          onClick={() => setdetailsOpen(false)}
          icon={<CloseOutlined />}
        ></Button>
      </div>
      <InviteDetailsSummary link={link} status={status} />
      <OptionGroup
        title={"Description"}
        description={"A way to identify this link"}
        value={link.name}
        readOnly={true}
        icon={<FontColorsOutlined />}
      />
      <OptionGroup
        title={hasExpired ? "Expired" : "Expires"}
        description={
          hasExpired
            ? "This link has expired"
            : expiration
            ? "This link is valid until"
            : "This link does not expire"
        }
        value={expiration ? expiration : "Never"}
        readOnly={true}
        icon={<ClockCircleOutlined />}
      />
      <OptionGroup
        title={"Uses left"}
        description={"How many users can redeem this link"}
        value={
          link.reedemLimit
            ? (link.reedemLimit - link.currentCount).toString()
            : "No limit"
        }
        readOnly={true}
        icon={<UserAddOutlined />}
      />
      <OptionGroup
        title={"Assigned Role"}
        description={"The default role users redeeming this link are assigned"}
        icon={<IdcardOutlined />}
        readOnly={true}
        value={UserRole.MEMBER}
      />
      <div className={styles.row + " inv-row " + styles.wrapRow}>
        <OptionGroup
          title="Token Gate"
          description="Link is shown to those that have these tokens"
          hideValue={link.tokenGateId ? true : false}
          readOnly={true}
          value={link.tokenGateId ? "" : "None"}
          icon={<KeyOutlined />}
        />
        {link.tokenGateId && (
          <LinkTokenGate
            onSelect={() => {}}
            gateId={link.tokenGateId}
            noUpdate={true}
          />
        )}
      </div>
      <div className={styles.footerRow}>
        <Button buttonType={ButtonTypes.LINK} onClick={showDeleteLinkConfirm}>
          Delete
        </Button>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          onClick={() => setMode("edit")}
        >
          Edit
        </Button>
      </div>
    </>
  );
};

const EditView: React.FC<{
  setMode: React.Dispatch<React.SetStateAction<"details" | "edit">>;
  link: IInviteLink;
  baseId: string;
}> = ({ setMode, link, baseId }) => {
  const linkRef = useRef({
    name: link.name,
    expire: link.previousEdit.expire,
    numberOfUses: link.previousEdit.numberOfUses,
    tokenGateId: link.tokenGateId ? link.tokenGateId : undefined,
  });
  const [updatingLink, setUpdatingLink] = useState(false);

  const updateLink = useCallback(() => {
    const newLink = linkRef.current;
    setUpdatingLink(true);

    const delta: Partial<IInviteLink> = {
      reedemLimit: newLink.numberOfUses
        ? invitationsApi.useArray[Number(newLink.numberOfUses)]
        : null,
      name: newLink.name ? newLink.name : "",
      endDate: newLink.expire ? invitationsApi.getTime(newLink.expire) : null,
      retired: false,
      previousEdit: newLink,
      tokenGateId: newLink.tokenGateId ? newLink.tokenGateId : null,
    };
    axiosInstance
      .patch("/api/invite-link", {
        inviteLinkId: link.id,
        delta,
        baseId: baseId,
      })
      .then((res) => {
        const link = res.data;
        invitationsApi.updateInvitationLink({
          delta: link,
          type: "update",
          id: link.id,
        });
        setUpdatingLink(false);
        setMode("details");
      })
      .catch(() => {
        setUpdatingLink(false);
        setMode("details");
      });
  }, []);

  return (
    <>
      <h4>Edit Link</h4>
      <EditingLink linkRef={linkRef} />
      <div className={styles.footerRow}>
        <Button
          buttonType={ButtonTypes.LINK}
          onClick={() => setMode("details")}
        >
          Cancel
        </Button>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          style={{ float: "right" }}
          disabled={updatingLink}
          onClick={updateLink}
        >
          {updatingLink ? "Saving..." : "Save"}
        </Button>
      </div>
    </>
  );
};

export default InviteLinkContainer;
