import Modal from "clarity-ui/Modal";
import React, { useEffect } from "react";
import { batch } from "react-redux";
import { JoinSection } from "screens/JoinBase";
import { CLEAR_WORKSPACE_DATA, RELOAD_BASE } from "store/actions";
import {
  ActionIntercept,
  setActionInterceptor,
  useRoleType,
} from "store/reducers/clientReducer";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { UserEventTypes, UserRole } from "utilities/types";
import Conditional from "./Conditional";
import styles from "./actionInterceptor/actionInterceptor.module.scss";
import eventApi from "clientApi/eventApi";
import SignUpLoginSwitcher from "./SignUpLoginSwitcher";
import ClarityLogo from "clarity-ui/ClarityLogo";

const ActionInterceptor: React.FC = () => {
  const interceptedAction = useShallowSelector(
    (store) => store.client.interceptedAction
  );
  const userRole = useRoleType();

  useEffect(() => {
    if (userRole && userRole !== UserRole.GUEST) {
      setActionInterceptor();
    }
  }, [userRole]);

  if (!interceptedAction) return <></>;
  return (
    <Modal
      zIndex={9998}
      padding={false}
      className={styles.modal}
      size={"medium"}
      hideModal={() => setActionInterceptor()}
    >
      <SwitchInterceptedAction interceptedAction={interceptedAction} />
    </Modal>
  );
};

const SwitchInterceptedAction: React.FC<{
  interceptedAction: ActionIntercept;
}> = ({ interceptedAction }) => {
  switch (interceptedAction) {
    case ActionIntercept.notAuthUserInPublicBase:
      return <NotAuthedUserInterceptor />;
    case ActionIntercept.authedUserInPublicBase:
      return <AuthedNonMember />;
    case ActionIntercept.guestInPublicBase:
      return <AuthedNonMember />;
    case ActionIntercept.login:
      return <LoginInterceptor />;
    default:
      return <></>;
  }
};

const JoinSectionWrapper: React.FC<{ viralSignupType?: UserEventTypes }> = ({
  viralSignupType,
}) => {
  const storeData = useShallowSelector((store) => ({
    base: store.workspace,
    user: store.user,
  }));

  const afterAction = () => {
    batch(() => {
      store.dispatch({ type: CLEAR_WORKSPACE_DATA });
      store.dispatch({ type: RELOAD_BASE });
    });
  };

  return (
    <JoinSection
      base={storeData.base}
      hasTokenGate={false}
      user={storeData.user}
      afterAction={afterAction}
      showWhatIsSiwe={true}
      autoAcceptOnLoginOrSignup={true}
      viralSignupType={viralSignupType}
    />
  );
};

const NotAuthedUserInterceptor: React.FC = () => {
  const storeData = useShallowSelector((store) => ({
    base: store.workspace,
    user: store.user,
  }));

  useEffect(() => {
    if (!storeData.user?.id)
      eventApi.postEvent(UserEventTypes.PUBLIC_SIGN_IN_PROMPTED, {});
  }, []);

  return (
    <div>
      <Conditional on={!storeData.user || !storeData.user.id}>
        <div className={styles.headingHint}>
          <h4>You must be signed in to do that</h4>
          <span className="small regular secondary">
            If you do not have an account, you can create one using your wallet
            or email below
          </span>
        </div>
      </Conditional>
      <JoinSectionWrapper
        viralSignupType={UserEventTypes.SIGNED_UP_BY_PUBLIC_PROMPT_VIRAL}
      />
    </div>
  );
};

const AuthedNonMember: React.FC = () => {
  const baseName = useShallowSelector((store) => store.workspace.name);
  return (
    <div>
      <div className={styles.headingHint}>
        <h4>Only members of {baseName} can take that action</h4>
        <span className="small regular secondary">
          This is a public base, which means that anyone can view its contents.
          However, if you would like to make changes, you will need to join
          first
        </span>
      </div>
      <div style={{ marginBottom: "-32px" }}>
        <JoinSectionWrapper />
      </div>
    </div>
  );
};

const LoginInterceptor: React.FC = () => {
  const storeData = useShallowSelector((store) => ({
    base: store.workspace,
    user: store.user,
  }));

  useEffect(() => {
    if (storeData.user?.id) setActionInterceptor();
  }, [storeData.user?.id]);
  return (
    <div>
      <div className={styles.headingHint}>
        <ClarityLogo />
      </div>
      <SignUpLoginSwitcher
        viralSignupType={UserEventTypes.SIGNED_UP_BY_PUBLIC_PROMPT_VIRAL}
        showWhatIsSiwe={true}
      />
    </div>
  );
};

export default ActionInterceptor;
