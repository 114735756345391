import React, { useState, useRef, useEffect } from "react";
import ModalScrimComponent from "components/ModalScrimComponent";
import styles from "./modalDocumentShare/modalDocumentShare.module.scss";
import { GlobalOutlined } from "@ant-design/icons";
import Button, { ButtonTypes } from "components/Button";
import { Switch } from "antd";
import { connect } from "react-redux";
import * as actionTypes from "store/actions";
import { ContainerTypes, IProjectObj, UserEventTypes } from "utilities/types";
import store from "store/storeExporter";
import { mixpanel } from "App";
import { axiosInstance } from "index";

interface IModalDocumentShareProps {
  hideModal: () => void;
  showInviteModal: () => void;
  containerType?: ContainerTypes;
}

interface IMapStateToProps {
  entity?: any;
  userId: string;
  baseId: string;
}

interface IMapDispatchToProps {
  changeDocPublicAccess: (
    docId: string,
    isPublicAccess: boolean,
    entityType?: ContainerTypes
  ) => any;
  updateWorkItem: (workItem: IProjectObj) => {
    type: string;
    workItem: IProjectObj;
  };
}

function ModalDocumentShare(
  props: IModalDocumentShareProps & IMapStateToProps & IMapDispatchToProps
) {
  const {
    entity,
    changeDocPublicAccess,
    updateWorkItem,
    userId,
    showInviteModal,
    baseId,
  } = props;
  const passedEntity = entity ? entity : {};
  const [copyLinkState, setCopyLinkState] = useState("");
  const [isPublicSwitchLoading, setIsPublicSwitchLoading] = useState(false);
  const shareUrl = window.location.href;
  const [pendingGuestInvites, setPendingGuestInvites] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [acceptedGuestUsers, setAcceptedGuestUsers] = useState([]);

  const handlePermissionChangeResponse = (response: any, passedEntity: any) => {
    const { isPublicAccess } = response.data;
    passedEntity.isPublicAccess = isPublicAccess;

    if (passedEntity.type === ContainerTypes.DOCUMENT) {
      changeDocPublicAccess(
        passedEntity.id,
        isPublicAccess,
        passedEntity.containerType
      );
    } else if (passedEntity.type === ContainerTypes.NOTE) {
      store.dispatch({
        type: actionTypes.PATCH_NOTE,
        patchContainer: {
          container: {
            containerId: passedEntity.id,
            containerType: ContainerTypes.NOTE,
          },
          patch: {
            isPublicAccess,
          },
        },
      });
    } else {
      updateWorkItem(passedEntity);
    }
    setIsPublicSwitchLoading(false);
  };

  const handleIsPublicAccessChange = (checked: boolean, event: any) => {
    setIsPublicSwitchLoading(true);
    if (passedEntity.type === ContainerTypes.DOCUMENT) {
      axiosInstance
        .post("/api/document/permission", {
          docId: passedEntity.id,
          isPublicAccess: checked,
        })
        .then((res) => handlePermissionChangeResponse(res, passedEntity));
    }
    if (passedEntity.type === ContainerTypes.NOTE) {
      axiosInstance
        .post("/api/note/permission", {
          id: passedEntity.id,
          isPublicAccess: checked,
        })
        .then((res) => {
          handlePermissionChangeResponse(res, passedEntity);
        });
    }
    if (
      passedEntity.type === ContainerTypes.PROJECT ||
      passedEntity.type === ContainerTypes.TASK
    ) {
      axiosInstance
        .post("/api/project/permission", {
          id: passedEntity.id,
          isPublicAccess: checked,
        })
        .then((res) => handlePermissionChangeResponse(res, passedEntity));
    }
  };

  const shareUrlInput = useRef(null);

  const handleCopyLinkClick = async (shareUrlInput: any) => {
    setCopyLinkState("loading");
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      const range = document.createRange();
      range.selectNodeContents(shareUrlInput);
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
      shareUrlInput.current.setSelectionRange(0, 999999);
    } else {
      shareUrlInput.current.select();
    }
    document.execCommand("copy");
    setCopyLinkState("success");
  };

  useEffect(() => {
    if (passedEntity.type === ContainerTypes.DOCUMENT) {
      mixpanel.track(UserEventTypes.PAGE_SHARE_OPTIONS_VIEWED, {
        distinct_id: userId,
      });
    } else if (passedEntity.type === ContainerTypes.NOTE) {
      mixpanel.track(UserEventTypes.NOTE_SHARE_OPTIONS_VIEWED, {
        distinct_id: userId,
      });
    } else if (passedEntity.type === ContainerTypes.PROJECT) {
      mixpanel.track(UserEventTypes.PROJECT_SHARE_OPTIONS_VIEWED, {
        distinct_id: userId,
      });
    } else if (passedEntity.type === ContainerTypes.TASK) {
      mixpanel.track(UserEventTypes.TASK_SHARE_OPTIONS_VIEWED, {
        distinct_id: userId,
      });
    }
    if (props.containerType === "Project") {
      loadGuests();
    }
  }, []);

  const loadGuests = async () => {
    axiosInstance
      .get("/container", {
        params: {
          containerId: passedEntity.id,
          containerType: ContainerTypes.PROJECT,
          baseId: baseId,
        },
      })
      .then((res) => {
        setAcceptedGuestUsers(res.data.acceptedGuestUsers);
        setPendingGuestInvites(res.data.pendingGuestInvites);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        zIndex: 99999,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <div className={styles.ModalDocumentShareContainer} style={{ zIndex: 1 }}>
        <div className={styles.ModalDocumentShareContainer__header}>
          <h4>Share with others</h4>
        </div>
        <div className={styles.shareLinkContainer}>
          <input
            className={styles.shareLinkContainer__field}
            ref={shareUrlInput}
            value={shareUrl}
            readOnly
          ></input>
          <Button
            className={styles.shareLinkContainer__button}
            onClick={() => {
              handleCopyLinkClick(shareUrlInput);
              if (passedEntity.type === ContainerTypes.DOCUMENT) {
                mixpanel.track(UserEventTypes.PAGE_SHARE_COPY_LINK_CLICKED, {
                  distinct_id: userId,
                });
              } else if (passedEntity.type === ContainerTypes.PROJECT) {
                mixpanel.track(UserEventTypes.PROJECT_SHARE_COPY_LINK_CLICKED, {
                  distinct_id: userId,
                });
              } else if (passedEntity.type === ContainerTypes.TASK) {
                mixpanel.track(UserEventTypes.TASK_SHARE_COPY_LINK_CLICKED, {
                  distinct_id: userId,
                });
              } else if (passedEntity.type === ContainerTypes.NOTE) {
                mixpanel.track(UserEventTypes.NOTE_SHARE_COPY_LINK_CLICKED, {
                  distinct_id: userId,
                });
              }
            }}
            isLoading={copyLinkState === "loading"}
          >
            {copyLinkState === "success" ? "Copied" : "Copy Link"}
          </Button>
        </div>
        <div className={styles.ModalDocumentShareContainer__body}>
          <div className={styles.permissionGroupListItem}>
            <div className={styles.permissionGroupListItem__iconContainer}>
              <GlobalOutlined
                style={{ fontSize: "1.5rem", color: "#8C8C8C" }}
              ></GlobalOutlined>
            </div>
            <div className={styles.permissionGroupListItem__labelContainer}>
              <span
                className={styles.permissionGroupListItem__labelContainer__name}
              >
                Public Access
              </span>
              <span
                className={
                  styles.permissionGroupListItem__labelContainer__caption
                }
              >
                Anyone with the link can view & comment on this document
              </span>
            </div>
            <div className={styles.permissionGroupListItem__switchContainer}>
              <Switch
                onChange={handleIsPublicAccessChange}
                checked={
                  passedEntity.id
                    ? passedEntity.isPublicAccess
                    : passedEntity.id
                    ? passedEntity.isPublicAccess
                    : false
                }
                loading={isPublicSwitchLoading}
              ></Switch>
            </div>
          </div>
        </div>
        {props.containerType === "Project" ? (
          <div className={styles.ModalDocumentShareContainer__body}>
            <div className={styles.permissionGroupListItem}>
              <div className={styles.permissionGroupListItem__iconContainer}>
                <GlobalOutlined
                  style={{ fontSize: "1.5rem", color: "#8C8C8C" }}
                ></GlobalOutlined>
              </div>
              <div className={styles.permissionGroupListItem__labelContainer}>
                <span
                  className={
                    styles.permissionGroupListItem__labelContainer__name
                  }
                >
                  Invite User
                </span>
                <span
                  className={
                    styles.permissionGroupListItem__labelContainer__caption
                  }
                >
                  Share with people
                </span>
              </div>
              <div className={styles.invitation_modal} style={{ zIndex: 1 }}>
                <div>
                  <div className={styles.invitation_modal_header}>
                    <Button
                      buttonType={ButtonTypes.PRIMARY}
                      onClick={() => {
                        showInviteModal();
                      }}
                    >
                      Add new Guest
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.ModalDocumentShareContainer__body}>
                {pendingGuestInvites.map((user: any) =>
                  user.invitedEmail ? (
                    <>{user.invitedEmail}</>
                  ) : (
                    <>{user.invited.username}</>
                  )
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <ModalScrimComponent hideModal={props.hideModal} />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  userId: state.user.id,
  baseId: state.workspace.id,
});

const mapDispatchToProps = (dispatch: any) => ({
  changeDocPublicAccess: (
    docId: string,
    isPublicAccess: boolean,
    entityType?: ContainerTypes
  ) =>
    dispatch({
      type: actionTypes.CHANGE_DOC_PUBLIC_ACCESS,
      docId,
      isPublicAccess,
      entityType,
    }),
  updateWorkItem: (workItem: IProjectObj) =>
    dispatch({ type: actionTypes.UPDATE_WORK_ITEM }, workItem),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDocumentShare);
