import { socket } from "App";
import store from "store/storeExporter";
import { UPDATE_LINE_TEXT } from "store/actions";
import { axiosInstance } from "index";

export const deleteReply = (reply: any, discussion: any, commentEl: any) => {
  if (reply.orderInDiscussion === 0) {
    store.dispatch({
      type: "REMOVE_DISCUSSION",
      discussionId: discussion.id,
      discussionType: discussion.type,
      containerType: discussion.containerType,
      discussion,
      id: discussion.containerId,
    });
    const blockRef = document.getElementById(discussion.blockId);
    commentEl?.classList.remove("active");
    commentEl?.classList.remove("focused");
    store.dispatch({
      type: UPDATE_LINE_TEXT,
      param: {
        event: {
          target: blockRef,
          currentTarget: blockRef,
        },
        id: discussion.blockId,
      },
      destination: "primary",
    });
    axiosInstance
      .delete("/api/discussion/delete", {
        data: {
          discussion,
          clientId: socket.id,
        },
      })
      .then((resp) => {});
  }

  axiosInstance
    .delete("/api/reply/delete", {
      data: {
        reply,
        clientId: socket.id,
      },
    })
    .then((resp) => {
      store.dispatch({
        type: "REMOVE_REPLY_FROM_DISCUSSION",
        discussionId: discussion.id,
        discussionType: discussion.type,
        containerType: discussion.containerType,
        discussion,
        id: discussion.containerId,
        reply: reply,
      });
    });
};

export const deleteInlineReply = (reply: any, discussion: any) => {
  if (reply.orderInDiscussion === 0) {
    store.dispatch({
      type: "REMOVE_DISCUSSION",
      discussionId: discussion.id,
      discussionType: discussion.type,
      containerType: discussion.containerType,
      discussion,
      id: discussion.containerId,
    });

    axiosInstance
      .delete("/api/discussion/delete", {
        data: {
          discussion,
          clientId: socket.id,
        },
      })
      .then((resp) => {});
  }

  axiosInstance
    .delete("/api/reply/delete", {
      data: {
        reply,
        clientId: socket.id,
      },
    })
    .then((resp) => {
      store.dispatch({
        type: "REMOVE_REPLY_FROM_DISCUSSION",
        discussionId: discussion.id,
        discussionType: discussion.type,
        containerType: discussion.containerType,
        discussion,
        id: discussion.containerId,
        reply: reply,
      });
    });
};
