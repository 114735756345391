import TokenSection from "clarity-ui/TokenSection";

const Tokens = () => {
  return (
    <>
      <TokenSection />
    </>
  );
};

export default Tokens;
