import store, { ClarityStore } from "store/storeExporter";
import {
  ContainerTypes,
  IDocumentCreationMethods,
  IProjectObj,
  WorkTypes,
} from "utilities/types";
import { SyntheticEvent } from "react";
import * as actionTypes from "store/actions";
import { batch } from "react-redux";
import { locationSubject } from "components/LocationListener";
import { useSelector, shallowEqual } from "react-redux";
import { partialRight } from "lodash";
import { checkIfCannotCreateWork } from "modules/appService";
import { getCorrectLink } from "./linkUtilities";
import { ChunkDestination } from "./stateTypes";
import notificationsApi from "clientApi/notificationsApi";
import { getCorrectGroupId } from "./groupUtilities";
import { axiosInstance } from "index";
import navigationApi from "clientApi/navigationApi";

export const createAndSetNewPage = (
  event: SyntheticEvent | any,
  baseId: string
) => {
  event.stopPropagation();
  event.preventDefault();
  const shiftKey = event.shiftKey;
  axiosInstance
    .post("/api/document/create", {
      workspaceId: baseId,
      method: IDocumentCreationMethods.SCRATCH,
    })
    .then(({ data }) => {
      batch(() => {
        store.dispatch({
          type: actionTypes.ADD_NEW_DOCUMENT,
          document: data,
        });
        if (shiftKey) {
          navigationApi.openSplitView({
            viewName: data.id,
            containerId: data.id,
            containerType: ContainerTypes.DOCUMENT,
          });
          return;
        }
        locationSubject.next(
          `/${store.getState().workspace.slug}/tags/${data.id}`
        );
      });
    });
};

export const createAndSetNewNote = (
  event: SyntheticEvent | any,
  groupId: string,
  baseId: string
) => {
  event.stopPropagation();
  event.preventDefault();
  const shiftKey = event.shiftKey;
  axiosInstance
    .post("/api/note", {
      baseId,
      groupId,
    })
    .then(({ data }) => {
      store.dispatch({
        type: actionTypes.ADD_NEW_NOTE,
        note: data,
      });
      if (shiftKey) {
        navigationApi.openSplitView({
          viewName: data.id,
          containerId: data.id,
          containerType: ContainerTypes.NOTE,
        });
        return;
      }
      locationSubject.next(
        `/${store.getState().workspace.slug}/docs/${data.id}`
      );
    });
};

export const useCreateAndSetNewProject = () => {
  const base = useSelector(
    (state: ClarityStore) => state.workspace,
    shallowEqual
  );

  return partialRight(createAndSetNewProject, base.id);
};

export const useCreateAndSetNewInitiative = () => {
  const base = useSelector(
    (state: ClarityStore) => state.workspace,
    shallowEqual
  );

  return partialRight(createAndSetNewInitiative, base.id);
};

export const createAndSetNewWorkItem = async (
  event: SyntheticEvent | any,
  workType: WorkTypes,
  baseId: string,
  paneId: ChunkDestination,
  groupId?: string,
  presets?: Partial<IProjectObj>
) => {
  event.stopPropagation();
  event.preventDefault();
  const cannotCreateWork = checkIfCannotCreateWork();

  const correctGroupId = getCorrectGroupId(paneId, groupId);
  if (!correctGroupId) {
    notificationsApi.displayError({
      body: "Cannot create task: you must be part of a group to create tasks",
    });
    return;
  }
  if (cannotCreateWork) return;
  const shiftKey = event.shiftKey;
  return axiosInstance
    .post("/api/project/unnamed", {
      baseId,
      workType,
      presets,
      groupId: correctGroupId,
    })
    .then(({ data }) => {
      store.dispatch({
        type: actionTypes.ADD_NEW_PROJECT,
        project: data.payload,
      });
      if (shiftKey) {
        navigationApi.openSplitView({
          viewName: data.payload.projectId,
          containerId: data.payload.projectId,
          containerType: ContainerTypes.WORK,
        });
        return;
      }
      locationSubject.next(
        getCorrectLink(store.getState().workspace, {
          ...data.payload,
          type: ContainerTypes.PROJECT,
        })
      );
    });
};

export const createAndSetNewProject = async (
  event: SyntheticEvent | any,
  baseId: string,
  paneId: ChunkDestination,
  groupId?: string
) => {
  return createAndSetNewWorkItem(
    event,
    WorkTypes.PROJECT,
    baseId,
    paneId,
    groupId
  );
};

export const createAndSetNewInitiative = async (
  event: SyntheticEvent | any,
  baseId: string,
  paneId: ChunkDestination
) => {
  return createAndSetNewWorkItem(event, WorkTypes.INITIATIVE, baseId, paneId);
};

export const createUnnamedWork = (paneId: ChunkDestination) => {
  const baseId = store.getState().workspace.id;

  const correctGroupId = getCorrectGroupId(paneId);
  if (!correctGroupId) {
    notificationsApi.displayError({
      body: "Cannot create task: you must be part of a group to create tasks",
    });
    return;
  }
  return axiosInstance
    .post("/api/project/unnamed", {
      baseId,
      groupId: correctGroupId,
    })
    .then(({ data }) => {
      store.dispatch({
        type: actionTypes.ADD_NEW_PROJECT,
        project: data.payload,
      });
      locationSubject.next(
        getCorrectLink(store.getState().workspace, data.payload)
      );
    });
};

export const setNewView = (event: any, baseId: string) => {
  event.preventDefault();
  const openInSplit = () => {
    // navigationApi.openSplitView(
    //   data.payload.projectId,
    //   undefined,
    //   data.payload.projectId,
    //   ContainerTypes.WORK
    // );
    // TODO store.dispatch({
    //   type: actionTypes.SET_SECONDARY_VIEW,
    //   secondaryViewType: "AllView",
    //   entityId: "View",
    //   tasksViewMode: TasksViewModes.NewCustomView,
    // });
  };
  const openInPrimary = () => {
    const state = store.getState();
    locationSubject.next(`/${state.workspace.slug}/view/new`);
  };
  if (event.shiftKey) {
    return openInSplit();
  }
  return openInPrimary();
};
