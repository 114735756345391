import { useEffect, useLayoutEffect, useState } from "react";

import TabLayout from "./TabLayout";
import styles from "./members/members.module.scss";
import { useHistory } from "react-router-dom";
import {
  Abilities,
  ICollaborator,
  IPendingCollaborator,
  IUserObj,
  IWorkspaceObj,
  UserRole,
} from "utilities/types";
import CollaboratorRow from "./members/CollaboratorRow";
import { useInvitationsModalSetter } from "./members/ModalInvitations";
import FreeTrialModal from "./members/FreeTrialModal";
import ModalConfirmation from "./members/ModalConfirmation";
import Button, { ButtonTypes } from "components/Button";
import store, { ClarityStore } from "store/storeExporter";
import { shallowEqual, useSelector } from "react-redux";
import InviteLinkContainer from "clarity-ui/InviteLinkContainer";
import TokenGateContainer from "clarity-ui/TokenGateContainer";
import NewInviteLinkRow from "clarity-ui/inviteLinkContainer/NewInviteLinkRow";
import { useAbilityChecker } from "editor/utils/customHooks";
import { PlusOutlined } from "@ant-design/icons";
import { useShallowSelector } from "utilities/hooks";
interface Props {
  base: IWorkspaceObj;
  user: IUserObj;
  getStarted?: boolean;
  active?: string;
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

const Members = ({ base, getStarted, active }: Props) => {
  const history = useHistory();
  const { id: baseId, slug: baseSlug } = base;

  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MEMBERS,
  });

  const canManageTokenGates = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_TOKEN_GATES,
  });

  const { showInvitationsModal } = useInvitationsModalSetter();
  const userRole = store.getState().client.roleType;
  const userId = store.getState().user?.id;
  const userRoleIds = useSelector((store: ClarityStore) => {
    return store.client.roleIds;
  });
  const rolesDict = useSelector((store: ClarityStore) => {
    return store.roles.dict;
  });
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const [addingTokenGate, setAddingTokenGate] = useState<boolean>(false);
  const { collaborators, pendingCollaborators, guests, pendingGuests } =
    useCollaborators({
      getStarted,
      baseId,
    });

  const [isFreeTrialModalVisible, setFreeTrialModalVisible] =
    useState<boolean>(false);

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);
  const [confirmationModalType, setConfirmationModalType] = useState<
    "membership" | "invitation"
  >("membership");
  const [info, setInfo] = useState<any>({});

  const handleConfirmation = (type: "membership" | "invitation", info: any) => {
    setConfirmationModalType(type);
    setIsConfirmationModalVisible(true);
    setInfo(info);
  };

  useEffect(() => {
    userRoleIds?.forEach((id) => {
      const role = rolesDict[id];
      if (role?.roleName === "Owner") {
        setIsOwner(true);
      }
    });
  }, [userRoleIds]);

  const renderCollaborators = () => (
    <div className={styles.members}>
      {pendingCollaborators.map((collaborator: any) => (
        <CollaboratorRow
          key={collaborator?.id}
          pending
          pendingCollaborator={collaborator}
          isOwner={isOwner}
          baseId={base.id}
          showConfirmation={handleConfirmation}
          userId={userId}
        />
      ))}
      {collaborators.map((collaborator: any) => (
        <CollaboratorRow
          key={collaborator.id}
          collaborator={collaborator}
          baseId={base.id}
          isOwner={isOwner}
          showConfirmation={handleConfirmation}
          userId={userId}
        />
      ))}
    </div>
  );

  const renderGuests = () => (
    <div className={styles.members}>
      {pendingGuests.map((collaborator: any) => (
        <CollaboratorRow
          key={collaborator?.id}
          pending
          pendingCollaborator={collaborator}
          isOwner={isOwner}
          baseId={base.id}
          showConfirmation={handleConfirmation}
          userId={userId}
        />
      ))}
      {guests.map((collaborator: any) => (
        <CollaboratorRow
          key={collaborator.id}
          collaborator={collaborator}
          baseId={base.id}
          isOwner={isOwner}
          showConfirmation={handleConfirmation}
          isGuestCollab={true}
          userId={userId}
        />
      ))}
    </div>
  );

  return getStarted &&
    collaborators.length < 1 &&
    pendingCollaborators.length < 1 ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        buttonType={
          pendingCollaborators.length < 1
            ? ButtonTypes.LINK
            : ButtonTypes.PRIMARY
        }
        disabled={!canEditSettings}
        onClick={() => {
          history.replace(`/${baseSlug}/notes`);
          window.location.reload();
        }}
        style={{ marginRight: "12px" }}
      >
        Skip for now
      </Button>
      <Button
        disabled={!canEditSettings}
        buttonType={
          pendingCollaborators.length < 1 || !getStarted
            ? ButtonTypes.PRIMARY
            : ButtonTypes.LINK
        }
        onClick={showInvitationsModal}
      >
        <PlusOutlined /> Members
      </Button>
      {isFreeTrialModalVisible && (
        <FreeTrialModal hideModal={() => setFreeTrialModalVisible(false)} />
      )}
      {isConfirmationModalVisible && (
        <ModalConfirmation
          hideModal={() => setIsConfirmationModalVisible(false)}
          type={confirmationModalType}
          info={info}
        />
      )}
    </div>
  ) : (
    <>
      {userRole && userRole !== UserRole.GUEST && active === "invite-links" && (
        <div className="base-settings--inner-container">
          <TabLayout
            title="Invite Links"
            description="Create secret links that new members can use to join your base"
            actionBtn={<NewInviteLinkRow buttonType={ButtonTypes.PRIMARY} />}
          >
            <InviteLinkContainer baseId={base.id} showTitle={false} />
          </TabLayout>
          <div style={{ height: "50px" }}></div>
        </div>
      )}
      {userRole && userRole !== UserRole.GUEST && active === "token-gates" && (
        <div className="base-settings--inner-container">
          <TabLayout
            title="Token Gates"
            description="Create reusable token requirements that you can apply to docs and invite links"
            actionBtn={
              <Button
                onClick={() => setAddingTokenGate(true)}
                disabled={!canManageTokenGates}
                buttonType={ButtonTypes.PRIMARY}
              >
                <PlusOutlined /> Token gate
              </Button>
            }
          >
            <TokenGateContainer
              addingRemote={addingTokenGate}
              setAddingRemote={setAddingTokenGate}
              showAdd={false}
            />
          </TabLayout>
          <div style={{ height: "50px" }}></div>
        </div>
      )}
      {active === "members" && (
        <div className="base-settings--fullwidth-container">
          <TabLayout
            title={getStarted ? "Invited" : "Members"}
            description="Manage the people who have access to your base"
            actionBtn={
              <Button
                disabled={!canEditSettings}
                buttonType={
                  pendingCollaborators.length < 1 || !getStarted
                    ? ButtonTypes.PRIMARY
                    : ButtonTypes.LINK || !canEditSettings
                }
                onClick={showInvitationsModal}
              >
                <PlusOutlined /> Members
              </Button>
            }
          >
            {/* {isLoading && <LoadingSpinner />} */}
            {renderCollaborators()}
            {isFreeTrialModalVisible && (
              <FreeTrialModal
                hideModal={() => setFreeTrialModalVisible(false)}
              />
            )}
            {isConfirmationModalVisible && (
              <ModalConfirmation
                hideModal={() => setIsConfirmationModalVisible(false)}
                type={confirmationModalType}
                info={info}
              />
            )}
          </TabLayout>
          <div style={{ height: "50px" }}></div>
          <TabLayout title={"Guests"}>{renderGuests()}</TabLayout>
          <div style={{ height: "50px" }}></div>
        </div>
      )}
    </>
  );
};

export function useCollaborators({
  getStarted,
  baseId,
}: {
  getStarted?: boolean;
  baseId: string;
}) {
  const baseOwnerName = store.getState().workspace.ownerName;
  const [collaborators, setCollaborators] = useState<ICollaborator[]>([]);
  const [guests, setGuests] = useState<ICollaborator[]>([]);
  const [pendingCollaborators, setPendingCollaborators] = useState<
    IPendingCollaborator[]
  >([]);
  const [pendingGuests, setPendingGuests] = useState<IPendingCollaborator[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const stateCollaboratorsIds = useSelector((state: any) => {
    return state.members.ids;
  }, shallowEqual);

  const pendingStateCollaboratorsId = useSelector((state: any) => {
    return state.members.invitedIds;
  }, shallowEqual);

  const collaboratorsDict = useSelector((state: any) => {
    return state.members.dict ? state.members.dict : {};
  }, shallowEqual);

  const pendingCollaboratorsDict = useSelector((state: any) => {
    return state.members.invitedDict;
  }, shallowEqual);

  const pendingGuestsIds = useShallowSelector(
    (state) => state.members.pendingGuests
  );

  useLayoutEffect(() => {
    if (!baseId) return;

    const guestRole = Object.values(store.getState().roles.dict).find(
      (role) => role.roleName === "Guest"
    );

    if (getStarted) {
      setCollaborators(
        stateCollaboratorsIds
          .map((id: string) => collaboratorsDict[id])
          .filter((c: any) => c.username !== baseOwnerName)
          .map((user: IUserObj) => ({
            id: user.id,
            role: UserRole.MEMBER,
            user: user,
          }))
      );
    } else {
      const collaborators = stateCollaboratorsIds
        .map((id: string) => collaboratorsDict[id])
        .filter((user: IUserObj) => {
          if (guestRole) return !user?.roleIds?.includes(guestRole?.id);
          return true;
        })
        .map((user: IUserObj) => ({
          id: user.id,
          role: user.role,
          roleIds: user.roleIds,
          user: user,
        }));
      collaborators.sort((a: any, b: any) => {
        if (a.user.username < b.user.username) return -1;
        if (a.user.username > b.user.username) return 1;
        return 0;
      });
      const guests = stateCollaboratorsIds
        .map((id: string) => collaboratorsDict[id])
        .filter((user: IUserObj) => {
          if (guestRole) return user.roleIds?.includes(guestRole?.id);
          return true;
        })
        .map((user: IUserObj) => ({
          id: user.id,
          role: user.role,
          roleIds: user.roleIds,
          user: user,
        }));
      setCollaborators(collaborators);
      setGuests(guests);
    }

    setPendingCollaborators(
      pendingStateCollaboratorsId
        .filter(
          (id: string) =>
            !pendingGuestsIds.includes(
              pendingCollaboratorsDict[id]?.roleAssignmentId
            )
        )
        .map((id: string) => pendingCollaboratorsDict[id])
    );
    setPendingGuests(
      pendingStateCollaboratorsId
        .filter((id: string) => pendingCollaboratorsDict[id]?.roleAssignmentId)
        .map((id: string) => pendingCollaboratorsDict[id])
    );
    setIsLoading(false);
  }, [
    baseId,
    stateCollaboratorsIds,
    pendingStateCollaboratorsId,
    pendingGuestsIds,
  ]);

  const refetchCollaborators = async () => {
    if (getStarted) {
      const collaborators = stateCollaboratorsIds
        .map((id: string) => collaboratorsDict[id])
        .filter((user: IUserObj) => {
          return user.role !== "Guest";
        })
        .map((user: IUserObj) => ({
          id: user.id,
          role: user.role,
          roleIds: user.roleIds,
          user: user,
        }));
      collaborators.sort((a: any, b: any) => {
        if (a.user.username < b.user.username) return -1;
        if (a.user.username > b.user.username) return 1;
        return 0;
      });
      const guests = stateCollaboratorsIds
        .map((id: string) => collaboratorsDict[id])
        .filter((user: IUserObj) => {
          return user.role === "Guest";
        })
        .map((user: IUserObj) => ({
          id: user.id,
          role: user.role,
          roleIds: user.roleIds,
          user: user,
        }));
      setGuests(guests);
      setCollaborators(collaborators);
    } else {
      stateCollaboratorsIds
        .map((id: string) => collaboratorsDict[id])
        .map((user: IUserObj) => ({
          id: user.id,
          role: user.role,
          user: user,
        }));
    }
    setPendingCollaborators(
      pendingStateCollaboratorsId.map(
        (id: string) => pendingCollaboratorsDict[id]
      )
    );
  };

  return {
    collaborators,
    guests,
    pendingCollaborators,
    pendingGuests,
    isLoading,
    refetchCollaborators,
  };
}

export default Members;
