import React, { useEffect, useState, useRef } from "react";
import Button, { ButtonTypes } from "components/Button";
import { CloseOutlined } from "@ant-design/icons";
import * as actionTypes from "store/actions";
import { useDispatch } from "react-redux";

import {
  IPartialUser,
  IWorkspaceObj,
  ISubscriptionObj,
  Abilities,
} from "utilities/types";

import styles from "./modalInvitations/modalInvitations.module.scss";
import UserInfo from "./modalInvitations/UserInfo";
import { connect } from "react-redux";
import ModalScrimComponent from "components/ModalScrimComponent";
import store from "store/storeExporter";
import { axiosInstance } from "index";
import { useShallowSelector } from "utilities/hooks";
import { useAbilityChecker } from "editor/utils/customHooks";
import ClarityInput from "components/ClarityInput";

interface IModalInvitationProps {
  refetchCollaborators: () => Promise<void>;
  currentNumberOfInvites: number;
  groupId?: string;
}

interface ISearchResult {
  user: IPartialUser;
}

interface IInvitationUser {
  type: "username" | "email";
  content: string;
  avatar?: string;
  id?: string;
}

interface IMapStateToProps {
  base: IWorkspaceObj;
  subscription: ISubscriptionObj;
}

const InviteGroupMemberModal = (
  props: IModalInvitationProps & IMapStateToProps
) => {
  const group = useShallowSelector((store) =>
    props.groupId ? store.groups.dict[props.groupId] : undefined
  );

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
  const [invitationUsers, setInvitationUsers] = useState<IInvitationUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { refetchCollaborators } = props;
  const canManageMembers = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });
  const memberDict = useShallowSelector((store) => store.members.dict);
  const memberIds = useShallowSelector((store) => store.members.ids);
  const hideModal = () =>
    store.dispatch({
      type: actionTypes.SET_GROUP_INVITATIONS_MODAL,
      isOpen: false,
    });

  const resetSearch = () => {
    const allMembers = memberIds.map((id) => {
      const user = memberDict[id];

      return {
        user,
      };
    });
    setSearchResults(allMembers);
  };

  useEffect(() => {
    resetSearch();
  }, [props.groupId]);

  useEffect(() => {
    async function fetchResults() {
      if (searchWord) {
        const allMembers = memberIds
          .map((id) => {
            const user = memberDict[id];
            return {
              user,
            };
          })
          .filter(
            (user) =>
              user.user.username
                ?.toLowerCase()
                .includes(searchWord.toLowerCase()) ||
              user.user.name?.toLowerCase().includes(searchWord.toLowerCase())
          );

        setSearchResults(allMembers);
      } else {
        const allMembers = memberIds.map((id) => {
          const user = memberDict[id];

          return {
            user,
          };
        });
        setSearchResults(allMembers);
      }
    }
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord]);

  const handleInputChange = (text: string) => {
    setSearchWord(text);
  };

  const handleSearchResultClick = (result: ISearchResult) => {
    setSearchWord("");
    if (searchWordInput && searchWordInput.current) {
      searchWordInput.current.value = "";
      searchWordInput.current.focus();
    }

    if (!group?.members?.includes(result.user.id)) {
      if (
        invitationUsers.filter((user) => user.id === result.user.id).length ===
        0
      ) {
        setInvitationUsers([
          ...invitationUsers,
          {
            type: "username",
            content: result.user.username,
            avatar: result.user.avatar,
            id: result.user.id,
          },
        ]);
        setSearchResults(
          searchResults.filter((user) => user.user.id !== result.user.id)
        );
      }
    } else {
      alert("Invitations can be only sent to non members of this group.");
    }
  };

  const handleInviteClick = async () => {
    setLoading(true);
    const invites = invitationUsers.map((m) => {
      return m.id;
    });
    try {
      await axiosInstance.post("/api/group/add", {
        groupId: props.groupId,
        userIds: invites,
      });
      await refetchCollaborators();
      hideModal();
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const removeInvitationUser = (idx: number) => {
    const _invitationUsers = [...invitationUsers];
    _invitationUsers.splice(idx, 1);
    setInvitationUsers(_invitationUsers);
    const allMembers = memberIds
      .map((id) => {
        const user = memberDict[id];

        return {
          user,
        };
      })
      .filter(
        (i) => _invitationUsers.findIndex((j) => j.id === i.user.id) === -1
      );
    setSearchResults(allMembers);
  };

  const renderSearchResults = () =>
    searchResults.map((result) => (
      <div
        className={styles.invitation_modal_search_result}
        key={result.user.id}
        onClick={(e) => handleSearchResultClick(result)}
      >
        <div className={styles.invitation_modal_search_result_wrapper}>
          <>
            <UserInfo
              name={result.user.name || result.user.username}
              username={result.user.name ? result.user.username : ""}
              avatar={result.user.avatar}
            />
            {group?.members?.includes(result.user.id) && (
              <p className="caption secondary">
                Already a member of this group
              </p>
            )}
          </>
        </div>
      </div>
    ));

  const searchWordInput = useRef<HTMLInputElement>(null);

  const renderInvitationUsersList = () => (
    <div className={styles.invitation_modal_invitation}>
      {invitationUsers.map((user: IInvitationUser, idx: number) => (
        <div key={user.id} className={styles.invitation_modal_invitation_user}>
          {user.type === "email" && <p>{user.content}</p>}
          {user.type === "username" && <p>@{user.content}</p>}
          <Button
            icon={<CloseOutlined />}
            buttonType={ButtonTypes.DEFAULT}
            onClick={() => removeInvitationUser(idx)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        zIndex: 99999,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <div className={styles.invitation_modal} style={{ zIndex: 1 }}>
        <div>
          <div className={styles.invitation_modal_header}>
            <h3>Add members to {group?.name}</h3>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              onClick={handleInviteClick}
              isLoading={loading}
              disabled={invitationUsers.length < 1 || !canManageMembers}
            >
              Add
            </Button>
          </div>
          {invitationUsers.length > 0 && renderInvitationUsersList()}
          <ClarityInput
            placeholder={"Search usernames or names"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputChange(e.currentTarget.value);
            }}
            autoFocus={true}
            componentRef={searchWordInput}
            value={searchWord}
          />
        </div>
        <div className={styles.invitation_modal_result_container}>
          {searchResults.length > 0 ? (
            renderSearchResults()
          ) : searchWord === "" ? (
            <p className={styles.invitation_modal_no_results}>
              Select one or more people to invite
            </p>
          ) : (
            <p className={styles.invitation_modal_no_results}>
              No results for the current search
            </p>
          )}
        </div>
      </div>
      <ModalScrimComponent hideModal={hideModal} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  base: state.workspace,
  subscription: state.subscription,
});

export function useImmediateGroupInvitationsModalSetter(groupId?: string) {
  const dispatch = useDispatch();

  const showInvitationsModal = () =>
    dispatch({
      type: actionTypes.SET_GROUP_INVITATIONS_MODAL,
      isOpen: true,
      groupId,
    });

  return {
    showInvitationsModal,
  };
}

export default connect(mapStateToProps)(InviteGroupMemberModal);
