import {
  SET_ACTIVE_WORKSPACE,
  SET_BASE_TOKEN_GATES,
  UPDATE_TOKEN_GATES,
} from "store/actions";
import { ITokenGate } from "utilities/types";

interface ITokenGatesReducerState {
  dict: TokenGateDict;
  ids: string[];
}

interface TokenGateDict {
  [id: string]: ITokenGate;
}

const initialState: ITokenGatesReducerState = {
  dict: {},
  ids: [],
};

interface updateGateAction {
  type: string;
  param: {
    id: string;
    type: "add" | "delete" | "update";
    delta: Partial<ITokenGate>;
  };
}

interface SetBaseGatesAction {
  type: string;
  param: {
    tokenGates: ITokenGate[];
  };
}

export default function tokenGatesReducer(
  state: ITokenGatesReducerState = initialState,
  action: updateGateAction & SetBaseGatesAction
) {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      return initialState;
    }

    case SET_BASE_TOKEN_GATES: {
      if (action.param.tokenGates) {
        const newState: ITokenGatesReducerState = {
          ids: [],
          dict: {},
        };
        action.param.tokenGates.forEach((tokenGate) => {
          newState.ids.push(tokenGate.id);
          newState.dict[tokenGate.id] = tokenGate;
        });
        return newState;
      }
      return state;
    }

    case UPDATE_TOKEN_GATES: {
      const newState = { ...state };

      newState.dict = { ...newState.dict };
      if (action.param.type === "add") {
        newState.ids = [...newState.ids];
        newState.ids.push(action.param.id);
        newState.dict[action.param.id] = action.param.delta as ITokenGate;
        return newState;
      }
      if (action.param.type === "delete") {
        newState.ids = [...newState.ids];
        const id = action.param.id;
        const index = newState.ids.indexOf(id);
        if (index < 0) return state;
        newState.ids.splice(index, 1);
        delete newState.dict[action.param.id];
        return newState;
      }
      if (action.param.type === "update") {
        if (!newState.dict[action.param.id]) return state;
        newState.dict[action.param.id] = {
          ...newState.dict[action.param.id],
          ...action.param.delta,
        };
        return newState;
      }
      return initialState;
    }
    default:
      return state;
  }
}
