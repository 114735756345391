import { noteApi } from "clientApi/noteApi";
import { checkCaretPosition } from "editor/utils/caretUtils";
import { useAbilityChecker } from "editor/utils/customHooks";
import { VALUE_DOWN, VALUE_ENTER } from "keycode-js";
import { throttle } from "lodash";
import { getUntitledNoteTitle } from "modules/containerHelpers";
import React, { useCallback, useMemo, useRef } from "react";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { useShallowSelector } from "utilities/hooks";
import { Abilities, INotesObj } from "utilities/types";
import styles from "./noteTitle/noteTitle.module.scss";

const NoteTitle: React.FC<{ noteId: string; customClassName: string }> = ({
  noteId,
  customClassName,
}) => {
  const note = useShallowSelector((store) => store.notes.dict[noteId]);
  if (!note || note.isWeekly) return <></>;

  return (
    <div className={customClassName} style={{ marginBottom: "10px" }}>
      <div className={styles.titleContainer__row1}>
        <NoteTitleInput note={note} />
      </div>
    </div>
  );
};

const NoteTitleInput: React.FC<{ note: INotesObj }> = ({ note }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const canEditName = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: note.groupId,
  });

  const onInput = (ev: React.FormEvent<HTMLDivElement>) => {
    if (ev.currentTarget.childElementCount > 1) {
      if (ref.current) {
        const caretPosition = checkCaretPosition(ref.current);
        moveCaretToPreviousPosition(ref.current, caretPosition);
        ev.currentTarget.innerHTML = ev.currentTarget.textContent as string;
      }
    }
    changeTitle(ev.currentTarget?.textContent ?? "");
  };

  const updateNoteName = async (noteName: string) => {
    noteApi.updateNoteTitle(note.id, noteName);
    if (ref.current && document.activeElement === ref.current) {
      const caretPosition = checkCaretPosition(ref.current);
      moveCaretToPreviousPosition(ref.current, caretPosition);
    }
  };

  const changeTitle = useCallback(
    throttle(updateNoteName, 500, { trailing: true, leading: false }),
    []
  );

  const navigateToFirstBlock = (e: React.KeyboardEvent) => {
    const nextBlock = document.getElementsByClassName(
      "Block related-pages"
    )?.[0];
    if (nextBlock) {
      const content = nextBlock.getElementsByClassName("content-section")[0];
      if (content) {
        e.preventDefault();
        e.stopPropagation();
        (content as HTMLDivElement).focus();
      }
    }
  };

  const handleKeyActions = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case VALUE_DOWN:
      case VALUE_ENTER: {
        navigateToFirstBlock(e);
        break;
      }
    }
  };

  return useMemo(() => {
    return (
      <div
        contentEditable={canEditName ? true : false}
        ref={ref}
        onInput={onInput}
        placeholder={getUntitledNoteTitle(note)}
        onKeyDown={handleKeyActions}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        className={`${styles.title} Block noteTitle`}
        dangerouslySetInnerHTML={{ __html: note?.title ?? "" }}
      ></div>
    );
  }, [note.id]);
};

export default NoteTitle;
