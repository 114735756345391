import { BehaviorSubject } from "rxjs";
import { ClarityNotification } from "utilities/types";
import { v4 as uuid } from "uuid";
import { CloseCircleTwoTone, SmileTwoTone } from "@ant-design/icons";

class NotificationApi {
  notificationsSubject: BehaviorSubject<ClarityNotification | null>;
  twoToneColors: [string, string] = [
    "rgba(253, 250, 251, 1)",
    "rgba(253, 250, 251, 0.1)",
  ];

  constructor() {
    this.notificationsSubject = new BehaviorSubject<ClarityNotification | null>(
      null
    );
  }

  displayConfirmation(notification: Partial<ClarityNotification>) {
    const id = uuid();
    if (!notification.icon) {
      notification.icon = <SmileTwoTone twoToneColor={this.twoToneColors} />;
    }

    this.notificationsSubject.next({
      dismissable: false,
      autodismissable: true,
      duration: 4,
      ...notification,
      id: id,
    });

    if (this.notificationsSubject.value?.autodismissable) {
      setTimeout(() => {
        this.notificationsSubject.next({
          id: id,
          duration: 4,
          leaving: true,
        });

        setTimeout(() => {
          this.notificationsSubject.next({
            id: id,
            duration: 4,
            removed: true,
          });
        }, 1000);
      }, (notification.duration ?? 4) * 1000);
    }
  }

  displayBanner(notification: Partial<ClarityNotification>) {
    const id = uuid();
    if (!notification.icon) {
      notification.icon = <SmileTwoTone twoToneColor={this.twoToneColors} />;
    }
    this.notificationsSubject.next({
      ...notification,
      id: id,
      duration: 4,
      autodismissable: false,
      dismissable: false,
    });
  }

  displayError(notification: Partial<ClarityNotification>) {
    const id = uuid();
    this.notificationsSubject.next({
      duration: 4,
      dismissable: false,
      autodismissable: true,
      ...notification,
      id: id,
      icon: <CloseCircleTwoTone twoToneColor={this.twoToneColors} />,
    });

    if (this.notificationsSubject.value?.autodismissable) {
      setTimeout(() => {
        this.notificationsSubject.next({
          id: id,
          duration: 4,
          leaving: true,
        });

        setTimeout(() => {
          this.notificationsSubject.next({
            id: id,
            duration: 4,
            removed: true,
          });
        }, 1000);
      }, (notification.duration ?? 4) * 1000);
    }
  }

  removeNotification(id: string) {
    this.notificationsSubject.next({
      id: id,
      duration: 4,
      removed: true,
    });
  }
}

export default new NotificationApi();
