import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import styles from "components/subTasks/subTasks.module.scss";
import Button, { ButtonTypes } from "components/Button";
import { useShallowSelector, useOptionalClassName } from "utilities/hooks";
import {
  INewTaskCreationModes,
  openNewTaskModal,
  useTemplateModalToggle,
} from "store/reducers/clientReducer";
import StatusDisplay from "components/StatusDisplay";
import { WorkItemAvatar } from "clarity-ui/WorkItem";
import DueDateDisplay from "clarity-ui/DueDateDisplay";
import { pick } from "lodash";
import { useCommandPaletteShower } from "store/reducers/commandPaletteReducer";
import {
  Abilities,
  CommandPaletteContext,
  ContainerTypes,
  IProjectObj,
  OrderTypes,
  ViewNames,
  WorkTypes,
} from "utilities/types";
import { sortByMetadata } from "modules/taskService";
import { useAbilityChecker } from "editor/utils/customHooks";
import { getCorrectPrefixForWorkItem } from "modules/containerHelpers";
import { Dropdown, Menu } from "antd";
import { createAndSetNewWorkItem } from "utilities/createNewContainers";
import { shallowEqual, useSelector } from "react-redux";
import { usePaneId } from "store/reducers/filterReducer";
import { createTemplate } from "screens/base/main/TemplatesList";
import TriangleIconSvg from "icons/Components/TriangleIconSvg";
import navigationApi from "clientApi/navigationApi";
import store from "store/storeExporter";

interface ISubTasksProps {
  parentWorkId: string;
  workType?: WorkTypes;
  groupIds?: string[];
  containerType: ContainerTypes.WORK | ContainerTypes.TEMPLATE;
  // workItem: IProjectObj;
}

export default function SubTasks(props: ISubTasksProps) {
  const { subtasks, openNewTaskModalOrTaskTemplate } = useSubTasksState(props);

  const { open, toggle } = useFoldButton(true);
  const [isDropDownEnabled, setDropDownEnabled] = useState(true);
  const { workType, groupIds, containerType } = props;
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupIds,
  });
  const { isBaseMember } = useShallowSelector((state) => ({
    isBaseMember: state.client.isBaseMember,
    userRole: state.client.roleType,
  }));

  useEffect(() => {
    if (workType === WorkTypes.INITIATIVE) {
      setDropDownEnabled(true);
    } else {
      setDropDownEnabled(false);
    }
  }, [workType]);

  return !subtasks.length ? (
    <>
      {isDropDownEnabled ? (
        <CreateOptions props={props} isIconOnly={false} />
      ) : (
        <Button
          className={styles.emptyStateAddSubtaskBtn}
          icon={<PlusOutlined />}
          buttonType={ButtonTypes.DEFAULT}
          disabled={!canEditEntity || !isBaseMember}
          onClick={canEditEntity ? openNewTaskModalOrTaskTemplate : () => {}}
        >
          Add subtasks
        </Button>
      )}
    </>
  ) : (
    <div className={styles.container}>
      <div className={styles.subtasksTable}>
        <div className={styles.headerRow}>
          <span className={styles.title}>
            Subtasks <span>({subtasks.length})</span>
            <FoldButton open={open} onClick={toggle} />
          </span>
          {isDropDownEnabled ? (
            <CreateOptions props={props} isIconOnly={true} />
          ) : (
            <Button
              buttonType={ButtonTypes.MEDIUM_PLAIN}
              disabled={!canEditEntity || !isBaseMember}
              onClick={
                canEditEntity ? openNewTaskModalOrTaskTemplate : () => {}
              }
              icon={<PlusOutlined />}
            />
          )}
        </div>
        <div className={styles.subTaskContainer}>
          {(!open ? [] : subtasks).map((subTask, index) => (
            <SubTask
              key={subTask}
              id={subTask}
              canEditEntity={canEditEntity}
              containerType={containerType}
              last={index === subtasks.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
interface ICreateOptions {
  props: ISubTasksProps;
  isIconOnly?: boolean;
}

function CreateOptions(props: ICreateOptions) {
  const { openNewTaskModalOrTaskTemplate, createNewProjectOrProjectTemplate } =
    useSubTasksState(props.props);
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: props.props.groupIds,
  });

  const [ismenuOpened, setIsmenuOpened] = useState(false);
  const handleVisibleChange = (flag: boolean) => {
    setIsmenuOpened(flag);
  };

  const createProject = (e: any) => {
    if (!canEditEntity) return;
    navigationApi.closePeekView();
    createNewProjectOrProjectTemplate(e.domEvent);
    setIsmenuOpened(false);
  };
  const createSubTask = (e: any) => {
    if (!canEditEntity) return;
    openNewTaskModalOrTaskTemplate(e.domEvent);
    setIsmenuOpened(false);
  };
  const checkIfIsDisabeld = () => {
    return !canEditEntity;
  };

  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={ismenuOpened}
      overlay={
        <Menu>
          <Menu.Item
            key="project"
            disabled={checkIfIsDisabeld()}
            onClick={(e: any) => createProject(e)}
          >
            <span className={styles.menuItemTitle}>
              <span className={styles.menuItemTitle}> Project</span>
            </span>
          </Menu.Item>
          <Menu.Item
            key="task"
            disabled={checkIfIsDisabeld()}
            onClick={(e: any) => createSubTask(e)}
          >
            <span className={styles.menuItemTitle}>
              <span className={styles.menuItemTitle}> Task</span>
            </span>
          </Menu.Item>
        </Menu>
      }
      trigger={["click"]}
    >
      <Button
        disabled={!canEditEntity}
        buttonType={
          props.isIconOnly ? ButtonTypes.MEDIUM_PLAIN : ButtonTypes.DEFAULT
        }
        onClick={(e: any) => {
          e.stopPropagation();
          setIsmenuOpened(false);
        }}
        icon={<PlusOutlined />}
      >
        {props.isIconOnly ? null : "Add subtasks"}
      </Button>
    </Dropdown>
  );
}

function useSubTasksState({ parentWorkId, containerType }: ISubTasksProps) {
  const parent = useShallowSelector((state) => {
    if (containerType === ContainerTypes.TEMPLATE)
      return state.templates.dict[parentWorkId];

    return state.work.dict[parentWorkId];
  });

  const [subtasks, setsubtasks] = useState<string[]>([]);

  const { base } = useSelector(
    (state: any) => ({
      base: state.workspace,
    }),
    shallowEqual
  );
  const paneId = usePaneId();

  const groupId = parent.groupIds && parent.groupIds[0];
  const preset = {
    parentId: parent.id,
    ...pick(parent, ["dueDate", "assigneeId", "workSectionId"]),
  };

  const openNewTaskModalOrTaskTemplate: React.MouseEventHandler = useCallback(
    (e) => {
      e?.stopPropagation();
      if (containerType === ContainerTypes.TEMPLATE) {
        createTemplate(WorkTypes.TASK, {
          parentId: parentWorkId,
          dueDate: parent.dueDate,
          assigneeId: parent.assigneeId,
        });
      } else {
        openNewTaskModal({
          type: INewTaskCreationModes.new,
          presetData: {
            dueDate: parent.dueDate,
            assigneeId: parent.assigneeId,
            parentId: parentWorkId,
          },
        });
      }
    },
    [parent]
  );

  const createNewProjectOrProjectTemplate: React.MouseEventHandler =
    useCallback(
      (e) => {
        if (containerType === ContainerTypes.TEMPLATE) {
          createTemplate(WorkTypes.PROJECT, {
            parentId: parent.id,
            dueDate: preset.dueDate,
            assigneeId: parent.assigneeId,
          });
        } else {
          createAndSetNewWorkItem(
            e,
            WorkTypes.PROJECT,
            base.id,
            paneId,
            groupId,
            {
              parentId: parent.id,
              dueDate: preset.dueDate,
              assigneeId: parent.assigneeId,
              workSectionId: (parent as IProjectObj).workSectionId,
              milestoneId: (parent as IProjectObj).milestoneId,
              groupIds: parent.groupIds ?? [parent.groupId],
            }
          );
        }
      },
      [parent]
    );

  useEffect(() => {
    const ids = parent.childrenAggregate.map((aggregate) => aggregate.id);
    const subTasks = sortByMetadata(OrderTypes.dueDate, ids);
    setsubtasks(subTasks);
  }, [parent.childrenAggregate]);

  const returnObjt = useMemo(() => {
    return {
      subtasks,
      openNewTaskModalOrTaskTemplate,
      createNewProjectOrProjectTemplate,
    };
  }, [
    subtasks,
    openNewTaskModalOrTaskTemplate,
    createNewProjectOrProjectTemplate,
  ]);

  return returnObjt;
}

interface ISubTaskProps {
  id: string;
  last?: boolean;
  canEditEntity: boolean;
  containerType: ContainerTypes;
}

function SubTask(props: ISubTaskProps) {
  const {
    containerClass,
    task,
    handleTaskClick,
    nameClassName,
    tailClassName,
  } = useSubTaskState(props);
  if (!task) return <></>;

  return (
    <div className={containerClass} onClick={handleTaskClick}>
      <div className={styles.head}>
        <StatusDisplay
          noSpace
          size="large"
          statusId={task.statusId as string}
          workType={task.workType}
          containerType={props.containerType}
          showName={false}
          editId={task.id as string}
        />
        {(task as IProjectObj).projectId && (
          <span
            className={`${styles.workId} ${
              task.isClosed ? "disabled" : "secondary"
            }`}
          >
            {getCorrectPrefixForWorkItem(task as IProjectObj)}
          </span>
        )}
        <span className={nameClassName}>{task.name}</span>
      </div>
      <div className={tailClassName}>
        <DueDateDisplay
          dueDate={task.dueDate}
          isClosed={(task as IProjectObj).isClosed}
          editId={task.id as string}
          containerType={props.containerType}
        />
        <WorkItemAvatar
          userId={task.assigneeId}
          editId={props.canEditEntity ? (task.id as string) : undefined}
          containerType={props.containerType}
        />
      </div>
    </div>
  );
}

function useSubTaskState({ id, last = false, containerType }: ISubTaskProps) {
  const task = useShallowSelector((state) => {
    if (containerType === ContainerTypes.TEMPLATE)
      return state.templates.dict[id];
    return state.work.dict[id];
  });
  const openPalette = useCommandPaletteShower();

  const editDueDate = () =>
    openPalette(CommandPaletteContext.DUE_DATE, {
      selectedItemIds: [id],
      slectedItemsType: containerType,
    });

  const editAssignee = () =>
    openPalette(CommandPaletteContext.ASSIGNEE, {
      selectedItemIds: [id],
      slectedItemsType: containerType,
    });

  const containerClass = useOptionalClassName({
    baseStyle: styles.subTask,
    pairs: [
      {
        extraStyle: styles.subTaskLast,
        withExtra: last,
      },
    ],
  });

  const nameClassName = useOptionalClassName({
    baseStyle: styles.name,
    pairs: [
      {
        extraStyle: styles.closed,
        withExtra: task?.isClosed,
      },
    ],
  });

  const tailClassName = useOptionalClassName({
    baseStyle: styles.tail,
    pairs: [
      {
        extraStyle: styles.closed,
        withExtra: task?.isClosed,
      },
    ],
  });

  const toggleTaskModal = (id: string) => {
    const currentPeekView =
      store.getState().navigation.navigation?.peek?.entity?.containerId;
    if (currentPeekView === id) return navigationApi.closePeekView();
    navigationApi.openPeekView({
      viewName: ViewNames.Detail,
      entity: {
        containerId: id,
        containerType: ContainerTypes.WORK,
      },
    });
  };
  const toggleTemplateModal = useTemplateModalToggle();

  const handleTaskClick =
    containerType === ContainerTypes.TEMPLATE
      ? () => toggleTemplateModal(id)
      : () => toggleTaskModal(id);

  return {
    containerClass,
    task,
    editDueDate,
    editAssignee,
    handleTaskClick,
    nameClassName,
    tailClassName,
  };
}

interface IFoldButtonProps {
  open: boolean;
  onClick: React.MouseEventHandler;
}

function FoldButton({ open, onClick }: IFoldButtonProps) {
  const className = useOptionalClassName({
    baseStyle: styles.foldButton,
    pairs: [
      {
        extraStyle: styles.foldButtonClosed,
        withExtra: !open,
      },
    ],
  });

  return (
    <Button
      onClick={onClick}
      className={className}
      icon={
        <TriangleIconSvg alt="Fold & unfold button" className={className} />
      }
      buttonType={ButtonTypes.LINK}
    />
  );
}

export function useFoldButton(defaultState = false) {
  const [open, setOpen] = useState(defaultState);

  const toggle: React.MouseEventHandler = (e) => setOpen(!open);

  return {
    open,
    toggle,
  };
}
