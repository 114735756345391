import React, { useCallback, useEffect, useState } from "react";
import Button, { ButtonTypes } from "components/Button";
import styles from "./workSettings.module.scss";
import {
  getNextRank,
  getPreviousRank,
  getRankBetween,
} from "utilities/containerRankHelpers";
import store from "store/storeExporter";
import { UPDATE_WORK_STATUS } from "store/actions";
import { Abilities, IWorkStatus } from "utilities/types";
import { VALUE_ENTER, VALUE_ESCAPE } from "keycode-js";
import { useOptionalClassName } from "utilities/hooks";
import { useAbilityChecker, useGroupContext } from "editor/utils/customHooks";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SixDotImg from "icons/six-dot-handle.svg";
import notificationsApi from "clientApi/notificationsApi";
import { axiosInstance } from "index";
import StatusDisplay from "components/StatusDisplay";

const { v4: uuidv4 } = require("uuid");

interface IProps {
  icon: any;
  categoryId: string;
  statuses: any[];
  name: string | null;
  baseId: string;
  canEditSettings: boolean;
}

const StatusCategory = (props: IProps) => {
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<string>("");
  const [notAllowedName, setNotAllowedName] = useState<string[]>([]);
  const groupContext = useGroupContext();
  const canEditBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupContext,
  });
  useEffect(() => {
    const notAllowed: string[] = [];
    Object.values(store.getState().work.statuses.dict).forEach((status) => {
      if (status.categoryId === props.categoryId) {
        notAllowed.push(status.name);
      }
    });
    setNotAllowedName(notAllowed);
  }, [props.statuses]);

  const showForm = () => {
    setIsFormVisible(!isFormVisible);
    setNewStatus("");
  };

  const addNewStatus = () => {
    if (!newStatus) return;
    if (newStatus.trim().length === 0) return;
    const lastRank = props.statuses[props.statuses.length - 1].rank;
    const newRank = getRankBetween(lastRank, undefined);
    const newWorkStatus: IWorkStatus = {
      id: uuidv4(),
      categoryId: props.categoryId,
      name: newStatus,
      baseId: props.baseId,
      rank: newRank,
      dateCreated: new Date(),
      composedRank: "",
    };
    setIsFormVisible(!isFormVisible);

    store.dispatch({
      type: UPDATE_WORK_STATUS,
      param: {
        id: newWorkStatus.id,
        delta: {
          ...newWorkStatus,
        },
      },
    });
    axiosInstance
      .post("/api/work-status", {
        ...newWorkStatus,
      })
      .then((res) => {
        setNewStatus("");
      })
      .catch((e) => {
        console.log("Error while creating new status", e);
      });
  };

  const handleDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) return;
    if (!canEditBaseSettings) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const reveresedStatuses = [...props.statuses];
    const workStatusChanging = reveresedStatuses[source.index];

    let newRank = "";
    if (destination.index === 0) {
      const currentFirst = reveresedStatuses[0];
      const currentFirstRank = currentFirst.rank;
      newRank = getPreviousRank(currentFirstRank);
    } else if (destination.index === reveresedStatuses.length - 1) {
      const currentLastRank =
        reveresedStatuses[reveresedStatuses.length - 1].rank;

      if (currentLastRank) {
        newRank = getNextRank(currentLastRank);
      }
    } else {
      if (destination.index > source.index) {
        const rankBefore = reveresedStatuses[destination.index].rank;
        const rankAfter = reveresedStatuses[destination.index + 1].rank;
        newRank = getRankBetween(rankBefore, rankAfter);
      } else if (destination.index < source.index) {
        const rankBefore = reveresedStatuses[destination.index - 1].rank;
        const rankAfter = reveresedStatuses[destination.index].rank;
        newRank = getRankBetween(rankBefore, rankAfter);
      }
    }
    store.dispatch({
      type: UPDATE_WORK_STATUS,
      param: {
        id: workStatusChanging.id,
        delta: {
          rank: newRank,
        },
      },
    });
    axiosInstance
      .patch("/api/work-status", {
        id: workStatusChanging.id,
        rank: newRank,
      })
      .then((res) => {})
      .catch((e) => {
        console.log("Error while updating", e);
      });
  };

  return (
    <div key={props.categoryId} className={styles.categoryContainer}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          padding: "0px 2px 9px",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <span className="body bold">{props.name}</span>
        </div>
        <div className={styles.addicon}>
          <Button
            buttonType={ButtonTypes.LINK}
            disabled={!props.canEditSettings || isFormVisible}
            icon={<PlusOutlined />}
            onClick={() => {
              setIsFormVisible(true);
            }}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={(result: any) => handleDragEnd(result)}>
        <Droppable
          isDropDisabled={!canEditBaseSettings || isFormVisible}
          droppableId={props.categoryId}
          mode="standard"
        >
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {props.statuses.map((status, index) => {
                return (
                  <WorkStatusEntry
                    key={status.id}
                    status={status}
                    icon={props.icon}
                    canDelete={props.statuses.length > 1}
                    index={index}
                    last={props.statuses.length - 1 === index}
                    notAllowedName={notAllowedName}
                    editing={false}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isFormVisible ? (
        <div className={styles.addform}>
          <NameEditor
            name={newStatus}
            addingNew={true}
            setname={setNewStatus}
            save={addNewStatus}
            cancel={showForm}
            notAllowed={notAllowedName}
            prefix={props.icon ? <props.icon /> : <></>}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const NameEditor: React.FC<{
  name: string;
  setname: React.Dispatch<React.SetStateAction<string>>;
  save: any;
  cancel: any;
  isClosed?: boolean;
  disabled?: boolean;
  addingNew?: boolean;
  prefix?: any;
  notAllowed: string[];
}> = ({
  name,
  setname,
  children,
  save,
  cancel,
  isClosed,
  addingNew,
  disabled,
  prefix,
  notAllowed,
}) => {
  const [active, setactive] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const className = useOptionalClassName({
    baseStyle: styles.rowEditor,
    pairs: [
      { extraStyle: styles.active, withExtra: active },
      { extraStyle: styles.done, withExtra: isClosed },
      { extraStyle: styles.addingNew, withExtra: addingNew },
      { extraStyle: styles.error, withExtra: error },
    ],
  });

  useEffect(() => {
    if (notAllowed.includes(name)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [name]);

  return (
    <div className={className}>
      {prefix}
      <input
        value={name}
        style={{ lineHeight: "28px" }}
        className="caption primary medium"
        onBlur={() => {
          setactive(false);
          if (!error) {
            save();
          }
        }}
        autoFocus={addingNew}
        disabled={disabled}
        onChange={(e) => setname(e.target.value)}
        onFocus={() => setactive(true)}
        onKeyDown={(e) => {
          if (e.key === VALUE_ENTER) {
            e.preventDefault();
            e.stopPropagation();
            if (!error) save();
            return;
          }
          if (e.key === VALUE_ESCAPE) {
            cancel();
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }}
      />
      {children}
    </div>
  );
};

interface WorkStatusEntryProps {
  status: any;
  icon: any;
  canDelete: boolean;
  index: number;
  last: boolean;
  notAllowedName: string[];
  editing: boolean;
}

const WorkStatusEntry = (props: WorkStatusEntryProps) => {
  const [newName, setNewName] = useState<string>(props.status.name);

  const groupContext = useGroupContext();
  const canEditBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupContext,
  });

  const deleteItem = useCallback(() => {
    axiosInstance
      .delete("/api/work-status", {
        data: { statusId: props.status.id },
      })
      .then(() => {
        store.dispatch({
          type: UPDATE_WORK_STATUS,
          param: {
            id: props.status.id,
            type: "delete",
            delta: {},
          },
        });
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: "Cannot delete status",
          body: "The status has issues assigned. Please change them before deleting the status.",
        });
      });
  }, []);

  const editItem = useCallback(() => {
    if (newName === props.status.name) return;
    if (newName.trim().length === 0) return;

    store.dispatch({
      type: UPDATE_WORK_STATUS,
      param: {
        id: props.status.id,
        delta: {
          name: newName,
        },
      },
    });
    axiosInstance
      .patch("/api/work-status", {
        id: props.status.id,
        name: newName,
        baseId: props.status.baseId,
      })
      .catch((e) => {
        console.log("Error while updating", e);
      });
  }, [props.status.name, newName]);

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
  });

  return (
    <Draggable
      isDragDisabled={!canEditBaseSettings}
      key={props.status.id}
      draggableId={props.status.id}
      index={props.index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...provided.draggableProps}
        >
          <div className={styles.listItem}>
            <div className={styles.grabHandleIconContainer}>
              <img
                src={SixDotImg}
                alt="Grab Handle"
                className={
                  styles.grabHandleIcon +
                  " " +
                  (snapshot.isDragging ? styles.dragging : "")
                }
                {...provided.dragHandleProps}
              />
            </div>
            <NameEditor
              name={newName}
              addingNew={false}
              setname={setNewName}
              save={editItem}
              disabled={!canEditBaseSettings}
              cancel={() => {
                setNewName(props.status.name);
              }}
              notAllowed={props.notAllowedName}
              prefix={
                <StatusDisplay statusId={props.status.id} showName={false} />
              }
            >
              {props.canDelete && (
                <Button
                  buttonType={ButtonTypes.LINK}
                  onClick={deleteItem}
                  className={styles.deleteBtn}
                  disabled={!canEditBaseSettings}
                  icon={<DeleteOutlined />}
                />
              )}
            </NameEditor>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default StatusCategory;
