import React from "react";
import { useUser } from "store/reducers/userReducer";

export const MemberOnly: React.FC<{}> = ({ children }) => {
  const user = useUser();
  if (!user.id) {
    return <></>;
  }
  return <>{children}</>;
};
