import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useShallowSelector } from "utilities/hooks";
import {
  CommandPaletteContext,
  CommandPalettePropertiesContextTypes,
} from "utilities/types";
import OptionLister from "../components/OptionLister";
import {
  CommandPaletteOption,
  CommandPaletteState,
  propertiesContextMatch,
} from "../helpers/types";

const DefaultPropertiesModesHOC: React.FC<{
  context: CommandPalettePropertiesContextTypes;
}> = ({ context }) => {
  if (context === CommandPaletteContext.CONTRIBUTORS)
    return <MultiSelectMode context={context} />;
  if (context === CommandPaletteContext.SELECT_MULTIPLE_GROUPS)
    return <MultiSelectMode context={context} />;
  return <DefaultPropertiesModes context={context} />;
};

const MultiSelectMode: React.FC<{
  context: CommandPalettePropertiesContextTypes;
}> = ({ context }) => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const firstElementId = useShallowSelector(
    (store) => store.commandPalette.params.selectedItemIds?.[0]
  );
  const firstElement = useShallowSelector((store) =>
    firstElementId ? store.work.dict[firstElementId] : undefined
  );
  const paletteStateContext = useContext(CommandPaletteState);

  useLayoutEffect(() => {
    const contextConfig = propertiesContextMatch[context];
    setOptions(contextConfig.getOptions());
    paletteStateContext.topBarText.next(contextConfig.topBarText);
  }, [context, firstElement]);

  return <OptionLister options={options} />;
};

const DefaultPropertiesModes: React.FC<{
  context: CommandPalettePropertiesContextTypes;
}> = ({ context }) => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const paneId = useShallowSelector(
    (store) => store.commandPalette.params.paneId
  );
  const paletteStateContext = useContext(CommandPaletteState);

  useLayoutEffect(() => {
    const contextConfig = propertiesContextMatch[context];
    setOptions(contextConfig.getOptions());
    paletteStateContext.topBarText.next(contextConfig.topBarText);
  }, [context, paneId]);

  return <OptionLister options={options} />;
};

export default DefaultPropertiesModesHOC;
