import { groupApi } from "clientApi/groupsApi";
import {
  useAbilityChecker,
  useGroupContextInSplit,
} from "editor/utils/customHooks";
import { getGroupsFromEntityId } from "modules/containerHelpers";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useShareData } from "store/reducers/topNavReducer";
import { useShallowSelector } from "utilities/hooks";
import styles from "./errorTopBar/errorTopBar.module.scss";
import { InfoCircleOutlined, WifiOutlined } from "@ant-design/icons";
import { useUser } from "store/reducers/userReducer";
import { useIsBaseMember } from "store/reducers/clientReducer";
import store from "store/storeExporter";
import { SET_MODAL_JOIN_GROUP } from "store/actions";
import notificationsApi from "clientApi/notificationsApi";
import Conditional from "./Conditional";
import { Abilities, ContainerTypes } from "utilities/types";
import { templatesApi } from "clientApi/templateApi";
import { ChunkDestination } from "utilities/stateTypes";

enum ErrorTopBarModes {
  NO_ERROR,
  NOT_A_GROUP_MEMBER,
  DISCONNECTED_FROM_SOCKET,
  EDITING_A_TEMPLATE,
  EDITING_A_SNIPPET,
}

const ErrorTopBar: React.FC<{
  paneId: ChunkDestination;
  underTopnav?: boolean;
}> = ({ paneId, underTopnav }) => {
  const groupContext = useGroupContextInSplit(paneId);

  const userGroups = useShallowSelector((state) => state.groups.userGroups);

  const connected = useShallowSelector((state) => state.network.connected);

  const [mode, setMode] = useState(ErrorTopBarModes.NO_ERROR);

  const shareData = useShareData(paneId);

  const user = useUser();

  const isBasMember = useIsBaseMember();

  const [entityGroups, setEntityGroups] = useState<
    string[] | string | undefined
  >([]);

  useEffect(() => {
    if (!connected) {
      setMode(ErrorTopBarModes.DISCONNECTED_FROM_SOCKET);
      return;
    } else {
      setMode(ErrorTopBarModes.NO_ERROR);
    }
    const entityGroups = getGroupsFromEntityId(
      shareData?.containerId,
      shareData?.containerType
    );
    setEntityGroups(entityGroups);

    if (shareData?.containerType === ContainerTypes.TEMPLATE && underTopnav)
      return setMode(ErrorTopBarModes.EDITING_A_TEMPLATE);
    if (shareData?.containerType === ContainerTypes.SNIPPET && underTopnav)
      return setMode(ErrorTopBarModes.EDITING_A_SNIPPET);

    if (
      isBasMember &&
      userGroups.length === 0 &&
      !entityGroups &&
      !shareData?.containerId
    ) {
      setMode(ErrorTopBarModes.NOT_A_GROUP_MEMBER);
    }

    if (!groupContext) {
      if (!shareData?.containerId) {
        setMode(ErrorTopBarModes.NO_ERROR);
      } else {
        if (
          isBasMember &&
          entityGroups &&
          !groupApi.isGroupMember(entityGroups)
        ) {
          setMode(ErrorTopBarModes.NOT_A_GROUP_MEMBER);
        }
      }
    } else {
      if (isBasMember && !userGroups.includes(groupContext)) {
        setMode(ErrorTopBarModes.NOT_A_GROUP_MEMBER);
      }
    }
  }, [
    groupContext,
    userGroups,
    shareData?.containerId,
    connected,
    paneId,
    underTopnav,
  ]);

  return useMemo(() => {
    if (!user?.id) {
      return <></>;
    }
    return (
      <>
        {mode === ErrorTopBarModes.NOT_A_GROUP_MEMBER && !underTopnav && (
          <NotAGroupMember
            groupContext={groupContext}
            entityGroups={entityGroups}
          />
        )}
        {mode === ErrorTopBarModes.EDITING_A_TEMPLATE && (
          <EditingATemplate containerId={shareData?.containerId} />
        )}
        {mode === ErrorTopBarModes.EDITING_A_SNIPPET && <EditingASnippet />}
        {mode === ErrorTopBarModes.NO_ERROR && <></>}
        {mode === ErrorTopBarModes.DISCONNECTED_FROM_SOCKET && !underTopnav && (
          <DisconnectedFromSocket />
        )}
      </>
    );
  }, [mode, paneId, user?.id]);
};

const NotAGroupMember: React.FC<{
  groupContext: string[] | string | undefined;
  entityGroups: string[] | string | undefined;
}> = ({ groupContext, entityGroups }) => {
  const [showJoinButton, setShowJoinButton] = useState(false);
  const [groupId, setGroupId] = useState<string | undefined>(undefined);
  const clicked = useRef(false);

  const group = useShallowSelector((state) =>
    groupId ? state.groups.dict[groupId] : undefined
  );

  const joinGroup = useCallback(() => {
    if (!groupId || !group) {
      return;
    }
    if (clicked.current) return;
    clicked.current = true;
    if (
      group.requiredRoles &&
      group.requiredRoles.length > 0 &&
      !groupApi.canJoinGroup(group.id)
    ) {
      store.dispatch({
        type: SET_MODAL_JOIN_GROUP,
        isOpen: true,
        roleIds: group.requiredRoles,
      });
      clicked.current = false;
    } else {
      groupApi
        .joinGroup(groupId)
        .then(() => {
          notificationsApi.displayConfirmation({
            title: <span>Joined group</span>,
            body: <span>You joined "{group.name}" successfully</span>,
            duration: 3,
          });
          clicked.current = false;
        })
        .catch((e) => {
          notificationsApi.displayError({
            title: <span>Error joining group</span>,
            body: <span>{e.response?.data?.message}</span>,
            duration: 3,
          });
          clicked.current = false;
        });
    }
  }, [groupId]);

  useEffect(() => {
    if (groupContext && groupContext && !Array.isArray(groupContext)) {
      setShowJoinButton(true);
      setGroupId(groupContext);
    } else if (
      entityGroups &&
      Array.isArray(entityGroups) &&
      entityGroups.length === 1
    ) {
      setShowJoinButton(true);
      setGroupId(entityGroups[0]);
    } else if (entityGroups && !Array.isArray(entityGroups)) {
      setShowJoinButton(true);
      setGroupId(entityGroups);
    } else {
      setShowJoinButton(false);
      setGroupId(undefined);
    }
  }, [groupContext, entityGroups]);

  return (
    <div className={styles.notAGroupMember}>
      <InfoCircleOutlined
        style={{
          color: "var(--error-banner-text-color)",
          paddingRight: "8px",
        }}
      />
      <span
        className="caption medium"
        style={{
          color: "var(--error-banner-text-color)",
        }}
      >
        <Conditional on={group?.deleted}>
          <>
            This group is archived, so you may not be able to take certain
            actions
          </>
        </Conditional>
        <Conditional on={!group?.deleted}>
          <>
            You are not a member of this group, so you may not be able to take
            certain actions
          </>
        </Conditional>
      </span>
      {showJoinButton && (
        <span
          className="underlined caption medium"
          style={{ cursor: "pointer" }}
          onClick={joinGroup}
        >
          <Conditional on={group?.deleted}>
            <>Unarchive & Join</>
          </Conditional>
          <Conditional on={!group?.deleted}>
            <>Join</>
          </Conditional>
        </span>
      )}
    </div>
  );
};

const EditingATemplate: React.FC<{ containerId?: string }> = ({
  containerId,
}) => {
  const canCreateEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });
  const useTemplate = () => {
    if (!containerId || !canCreateEntity) return;
    templatesApi.openCreateWorkItemFromTemplate(containerId);
  };

  return (
    <div className={styles.notAGroupMember}>
      <InfoCircleOutlined
        style={{
          color: "var(--error-banner-text-color)",
          paddingRight: "8px",
        }}
      />
      <span
        className="caption medium"
        style={{
          color: "var(--error-banner-text-color)",
        }}
      >
        This is a template, you can edit it
      </span>
      <span
        className="underlined caption medium"
        style={{ cursor: "pointer", color: "var(--error-banner-text-color)" }}
        onClick={useTemplate}
      >
        {" "}
        or use it to create a task
      </span>
    </div>
  );
};

const EditingASnippet: React.FC = () => {
  return (
    <div className={styles.notAGroupMember}>
      <InfoCircleOutlined
        style={{
          color: "var(--error-banner-text-color)",
          paddingRight: "8px",
        }}
      />
      <span
        className="caption medium"
        style={{
          color: "var(--error-banner-text-color)",
        }}
      >
        You are editing a snippet
      </span>
    </div>
  );
};

const DisconnectedFromSocket: React.FC = () => {
  return (
    <div className={styles.disconnectedFromSocket}>
      <WifiOutlined
        style={{
          color: "#993636",
          paddingRight: "8px",
        }}
      />
      <span
        className="caption medium"
        style={{
          color: "#993636",
        }}
      >
        You are not connected to the internet. Your changes will not be saved
        until a connection is established.
      </span>
    </div>
  );
};

export default ErrorTopBar;
