import React, { useCallback, useEffect, useState } from "react";
import { socket } from "App";
import styles from "./activeUsersListener/activeUsersListener.module.scss";
import { IUserObj } from "utilities/types";
import { useConnected } from "store/reducers/networkReducer";
import { useUser } from "store/reducers/userReducer";
import { useShareData } from "store/reducers/topNavReducer";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import UsersStack from "clarity-ui/UsersStack";
import { ChunkDestination } from "utilities/stateTypes";

const ActiveUsersListener: React.FC<{
  containerClass?: string;
  paneId: ChunkDestination;
}> = ({ containerClass = "", paneId }) => {
  const userId = useShallowSelector((store) => store.user?.id);

  const { users, displayUsers } = useUsers({
    paneId,
    defaultUserId: userId,
  });
  const connected = useConnected();

  const listenerClassName = useOptionalClassName({
    baseStyle: styles.activeUsersListener,
    pairs: [
      {
        extraStyle: containerClass,
        withExtra: Boolean(containerClass),
      },
    ],
  });

  if (!connected) {
    return <></>;
  }

  return !users.length ? (
    <></>
  ) : (
    <div className={listenerClassName}>
      <AvatarsGroup users={displayUsers} />
      {users.length > 3 && (
        <div className={styles.activeUsersListenerCount}>
          <span>{users.length}</span>
        </div>
      )}
    </div>
  );
};

function useUsers({
  paneId,
  defaultUserId = "",
}: {
  paneId: ChunkDestination;
  defaultUserId?: string;
}) {
  const userId = useUserId(defaultUserId);
  const containerId = useContainerId(paneId);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [users, setUsers] = useState<any>([]);

  const handleTheData = useCallback(
    (res: { users: any[]; containerId: string }) => {
      if (res.containerId === containerId) {
        const data = [...res.users].filter((user) => userId !== user.id);
        setUsers([...data]);
      }
    },
    [containerId, userId]
  );

  useEffect(() => {
    if (!containerId) {
      setUsers([]);
    }
  }, [containerId]);

  useEffect(
    () => setDisplayUsers(users.length <= 3 ? users : users.slice(0, 3)),
    [users]
  );

  useEffect(() => {
    socket.on("usersInRoom", handleTheData);
    return () => {
      socket.removeListener("usersInRoom", handleTheData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTheData]);

  return {
    users,
    displayUsers,
  };
}

function useUserId(defaultUserId: string) {
  const [userId, setUserId] = useState(defaultUserId);
  const user = useUser();
  useEffect(() => {
    if (user) {
      setUserId(user.id);
    } else {
      setUserId("");
    }
  }, [user]);

  return userId;
}

function useContainerId(paneId: ChunkDestination) {
  const [containerId, setContainerId] = useState("");
  const shareData = useShareData(paneId);
  useEffect(() => {
    if (shareData?.containerId) {
      setContainerId(shareData.containerId);
    } else {
      setContainerId("");
    }
  }, [shareData]);

  return containerId;
}

interface IAvatarsGroupProps {
  users: IUserObj[];
}

function AvatarsGroup({ users }: IAvatarsGroupProps) {
  const userIds = users
    .map((user) => {
      return user.id;
    })
    .slice(0, 2);
  return (
    <div className={styles.avatarsGroup}>
      <UsersStack userIds={userIds} />
    </div>
  );
}

export default ActiveUsersListener;
