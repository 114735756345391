import { DisconnectOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import Button, { ButtonTypes } from "components/Button";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ClarityStore } from "store/storeExporter";
import { logUserOut } from "utilities/authTokens";
import ItemDescription from "./ItemDescription";
import { usernameSplitter } from "./UserDisplay";
import styles from "./userWalletPresenceCard/walletCard.module.scss";

const UserWalletPresenceCard: React.FC = () => {
  const user = useSelector((store: ClarityStore) => store.user, shallowEqual);
  const location = useLocation();

  return (
    <div className={styles.card}>
      <ItemDescription
        icon={
          <Avatar
            shape="square"
            style={{ borderRadius: "8px" }}
            src={user?.avatar}
          />
        }
        title={user?.username ? usernameSplitter(user.username) : ""}
        description={"Main Network"}
        tail={
          <div style={{ flexGrow: 1 }}>
            <div style={{ flexGrow: 1 }}></div>
            <Button
              onClick={() => logUserOut(location.pathname)}
              style={{ float: "right" }}
              icon={<DisconnectOutlined />}
              buttonType={ButtonTypes.LINK}
            />
          </div>
        }
      />
    </div>
  );
};

export default UserWalletPresenceCard;
