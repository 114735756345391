import { shallowEqual, useSelector } from "react-redux";
import {
  CLEAR_NAVIGATION_CHUNK,
  CLOSE_SPLIT_VIEW,
  HIDE_SECONDARY_VIEW,
  SET_NAVIGATION_CHUNK,
  SET_SECONDARY_VIEW_TEMPORARY_VIEW,
  SET_SIDEBAR_WIDTH,
  SET_SPLITVIEW_WIDTH,
  SHOW_SECONDARY_VIEW,
  SWAP_PANES,
  TOGGLE_SECONDARY_VIEW,
} from "store/actions";
import { ClarityStore } from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import { NavigationChunk } from "utilities/types";

type NavigationEntry = {
  [paneId in ChunkDestination]: NavigationChunk;
};
export type IInitialState = {
  navigation: NavigationEntry;
  splitView: {
    displaySecondaryView: boolean;
  };
  dimension: {
    sideBarWidth: number;
    splitViewWidth: number;
  };
};
const initialState: IInitialState = {
  navigation: {} as NavigationEntry,
  splitView: {
    displaySecondaryView: false,
  },
  dimension: {
    sideBarWidth: 265,
    splitViewWidth: 440,
  },
} as IInitialState;

export interface ISetNavigationChunk {
  type: string;
  navigationChunk: NavigationChunk;
  paneId: ChunkDestination;
}

export interface ISetSplitViewWidth {
  type: string;
  width: number;
}

export default function navigationReducer(
  state: IInitialState = initialState,
  action: ISetNavigationChunk & ISetSplitViewWidth
) {
  switch (action.type) {
    case SET_NAVIGATION_CHUNK: {
      const newState = {
        ...state,
        navigation: {
          ...state.navigation,
        },
        splitView: {
          ...state.splitView,
        },
      };
      newState.navigation[action.paneId] = {
        ...action.navigationChunk,
      };

      if (action.paneId === ChunkDestination.secondary) {
        newState.splitView.displaySecondaryView = true;
      }

      return newState;
    }

    case CLEAR_NAVIGATION_CHUNK: {
      const newState = {
        ...state,
        navigation: {
          ...state.navigation,
        },
      };

      delete newState.navigation[action.paneId];
      return newState;
    }

    case TOGGLE_SECONDARY_VIEW: {
      const newState: IInitialState = {
        ...state,
        splitView: {
          ...state.splitView,
        },
      };
      newState.splitView.displaySecondaryView =
        !state.splitView.displaySecondaryView;
      return newState;
    }

    case SHOW_SECONDARY_VIEW: {
      const newState = {
        ...state,
        splitView: {
          ...state.splitView,
        },
      };
      newState.splitView.displaySecondaryView = true;
      return newState;
    }

    case HIDE_SECONDARY_VIEW: {
      const newState = {
        ...state,
        splitView: {
          ...state.splitView,
        },
      };
      newState.splitView.displaySecondaryView = false;
      return newState;
    }

    case SET_SECONDARY_VIEW_TEMPORARY_VIEW: {
      if (!state.splitView.displaySecondaryView) {
        const newState = {
          ...state,
          splitView: {
            ...state.splitView,
          },
        };
        newState.splitView.displaySecondaryView = true;
        return newState;
      }
      return state;
    }

    case SET_SIDEBAR_WIDTH:
      return {
        ...state,
        dimension: {
          ...state.dimension,
          sideBarWidth: action.width,
        },
      };

    case SET_SPLITVIEW_WIDTH:
      return {
        ...state,
        dimension: {
          ...state.dimension,
          splitViewWidth: action.width,
        },
      };

    case SWAP_PANES: {
      const newState = {
        ...state,
        navigation: {
          ...state.navigation,
        },
      };
      const primararyView = {
        ...newState.navigation[ChunkDestination.primary],
      };
      newState.navigation[ChunkDestination.primary] = {
        ...newState.navigation[ChunkDestination.secondary],
      };
      newState.navigation[ChunkDestination.secondary] = primararyView;
      return newState;
    }

    case CLOSE_SPLIT_VIEW: {
      return {
        ...state,
        splitView: {
          ...state.splitView,
          displaySecondaryView: false,
        },
      };
    }
    default:
      return state;
  }
}

export function useSplitOpen() {
  return useSelector(
    (state: ClarityStore) => state.navigation.splitView.displaySecondaryView,
    shallowEqual
  );
}
