import React, { useEffect, useState, useMemo, useRef } from "react";
import CitationPopup from "./CitationPopup";
import ReactDOM from "react-dom";
import { openCard, activeDiscussion } from "../store/storeExporter";
import TagPopup from "./TagPopup";
import LinkPreviewCard from "./LinkPreviewCard";
import { getInnerCaretPosition } from "utilities/caretMovement";
import { ChunkDestination } from "utilities/stateTypes";
import { checkCaretPosition } from "editor/utils/caretUtils";

interface Props {
  setCurrentDiscussionId: (id: string) => void;
  setCurrentPrediscussionId: (id: string) => void;
}

const SelectionListener: React.FC<Props> = ({
  setCurrentDiscussionId,
  setCurrentPrediscussionId,
}: Props) => {
  const [openPopupData, setopenPopupData] = useState<any>(null);
  const dataRef = useRef(openPopupData);
  const citationRef = useRef<any>();
  const prevFocusedComment = useRef<string | null>(null);

  useEffect(() => {
    dataRef.current = openPopupData;
  }, [openPopupData]);

  useEffect(() => {
    activeDiscussion.subscribe((discussionId: any) => {
      prevFocusedComment.current = discussionId;
    });
  }, []);

  useEffect(() => {
    openCard.subscribe((res) => {
      if (!res) setopenPopupData(null);
    });
  }, []);

  useEffect(() => {
    let mouseDownAction = false;

    document.onkeydown = (e) => {
      mouseDownAction = false;
    };

    document.onselectionchange = (e: any) => {
      const checkSelection = document.getSelection();
      if (!checkSelection || checkSelection.rangeCount === 0) return;
      const range = checkSelection.getRangeAt(0);
      let contentIsEditable = true;
      let contentSection: HTMLDivElement | null | undefined;
      try {
        contentSection =
          range.commonAncestorContainer?.parentElement?.closest(
            ".content-section"
          );
      } catch {}

      if (!contentSection) return;
      contentIsEditable = contentSection.isContentEditable;

      let data: any = checkCommentPopup(e);
      let prediscussionData: any = checkPrediscussionPopup();
      if (!data && prevFocusedComment.current) {
        const selection = document.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          let commentRelated =
            range.commonAncestorContainer?.parentElement?.closest(
              `[data-discussion-id="${prevFocusedComment.current}"]`
            );
          if (!commentRelated) {
            setCurrentDiscussionId("");
            prevFocusedComment.current = null;
          }
        }
      }

      if (!data) data = checkForLinkPopup(e);

      if (contentIsEditable) {
        const selection = document.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const inNonEditable =
            range.commonAncestorContainer?.parentElement?.closest(
              `[contenteditable="false"]`
            );

          if (inNonEditable && !mouseDownAction) {
            const block: any =
              range.commonAncestorContainer?.parentElement?.closest(
                `div.content-section`
              );
            const caretPosition = checkCaretPosition(block);
            const innerCaretPosition = getInnerCaretPosition(
              block,
              inNonEditable,
              caretPosition
            );
            const length = inNonEditable.textContent
              ? inNonEditable.textContent.length
              : 0;
            if (innerCaretPosition >= length / 2) {
              range.setStartAfter(inNonEditable);
              if (
                inNonEditable.nextElementSibling?.parentElement
                  ?.contentEditable === "false"
              ) {
                const textNode = document.createTextNode(" ");
                range.insertNode(textNode);
                range.setStartAfter(textNode);
              }
              const textNode = document.createTextNode("\uFEFF");
              range.insertNode(textNode);
              range.setStartAfter(textNode);
              const newRange = new Range();
              newRange.selectNode(textNode);
              newRange.collapse();
              selection.removeAllRanges();
              selection.addRange(newRange);
              textNode.remove();
              return;
            } else {
              if (block) {
                range.setStartBefore(inNonEditable);
                const newNode = document.createTextNode("\uFEFF");
                range.insertNode(newNode);
                range.setStartAfter(newNode);
                const newRange = new Range();
                newRange.selectNode(newNode);
                newRange.collapse(true);
                selection.removeAllRanges();
                selection.addRange(newRange);
                newNode.remove();
              }
            }
          }
        }
      }

      if (citationRef.current)
        citationRef.current.classList.remove("citation-active");
      if (!!prediscussionData) {
        setopenPopupData(prediscussionData);
      }
      if (data) {
        setopenPopupData(data);
        citationRef.current = data.focusElement;
        mouseDownAction = false;
      } else {
        let data: any = checkCitationPopup(e);
        if (!data) data = checkTagPopup(e);
        if (data && mouseDownAction) {
          setopenPopupData(data);
          citationRef.current = data.focusElement;
          if (data.type === "citation")
            citationRef.current.classList.add("citation-active");
          return;
        }
        mouseDownAction = false;
        if (
          dataRef.current &&
          e.currentTarget.activeElement !== document.body
        ) {
          if (e.currentTarget.activeElement.closest(".popup-container")) return;
          setopenPopupData(null);
        }
      }
    };

    document.onmousedown = (e: any) => {
      if (e.target) {
        mouseDownAction = true;
        if (e.target.closest(".popup-container")) {
          return;
        }
        setopenPopupData(null);
      }
    };

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    if (openPopupData?.citationId) {
      return showCardCase(openPopupData);
    }
    if (openPopupData?.commentId) {
      setCurrentDiscussionId(openPopupData.commentId);
      prevFocusedComment.current = openPopupData.commentId;
      return <></>;
    }
    if (openPopupData?.prediscussionId) {
      setCurrentPrediscussionId(openPopupData.prediscussionId);
      return <></>;
    }
    if (openPopupData?.documentId) return showTagCardCase(openPopupData);
    if (openPopupData?.linkTo) return showLinkCardCase(openPopupData);
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    openPopupData?.citationId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    openPopupData?.commentId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    openPopupData?.documentId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    openPopupData?.linkTo,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    openPopupData?.prediscussionId,
  ]);
};

const checkCitationPopup = (event: any) => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const citation = range.startContainer?.parentElement?.closest("citation");
    if (citation) {
      const attr = citation?.getAttribute("data-citation-id");
      const block = citation.closest(".content-section") as HTMLDivElement;
      const blockId = block?.getAttribute("data-block-id");
      const caretPosition = checkCaretPosition(block);
      if (caretPosition === 0) return null;
      if (
        citation.contains(range.endContainer) &&
        range.endOffset === citation.textContent?.length
      )
        return null;

      const position = getDestination(block);

      return {
        citationId: attr,
        type: "citation",
        blockId,
        destination: position.destination,
        containerRef: position.containerRef,
        focusElement: citation,
      };
    }
    return null;
  }
};

export const checkPrediscussionPopup = () => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    let prediscussion =
      range.startContainer?.parentElement?.closest("prediscussion");

    if (!prediscussion) {
      if (selection.rangeCount > 1) {
        const range2 = selection.getRangeAt(1);
        prediscussion =
          range2.startContainer?.parentElement?.closest("prediscussion");
      }
    }
    if (prediscussion) {
      const newRange = range.cloneRange();
      selection.addRange(newRange);
      const attr = prediscussion?.getAttribute("data-prediscussion-id");
      const block = prediscussion.closest(".content-section") as HTMLDivElement;
      const blockId = block?.getAttribute("data-block-id");
      const caretPosition = checkCaretPosition(block);
      if (caretPosition === 0) return null;
      if (
        prediscussion.contains(range.endContainer) &&
        range.endOffset === prediscussion.textContent?.length
      )
        return null;

      const position = getDestination(block);

      return {
        prediscussionId: attr,
        type: "comment",
        blockId,
        destination: position.destination,
        containerRef: position.containerRef,
        focusElement: prediscussion,
      };
    }
    return null;
  }
};

const checkCommentPopup = (event: any) => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    let comment =
      range.startContainer?.parentElement?.closest("comment.active");

    if (!comment) {
      if (selection.rangeCount > 1) {
        const range2 = selection.getRangeAt(1);
        comment =
          range2.startContainer?.parentElement?.closest("comment.active");
      }
    }
    if (comment && comment.classList.contains("active")) {
      const newRange = range.cloneRange();
      selection.addRange(newRange);
      const attr = comment?.getAttribute("data-comment-id");
      const block = comment.closest(".content-section") as HTMLDivElement;
      const blockId = block?.getAttribute("data-block-id");
      const caretPosition = checkCaretPosition(block);
      if (caretPosition === 0) return null;
      if (
        comment.contains(range.endContainer) &&
        range.endOffset === comment.textContent?.length
      )
        return null;

      const position = getDestination(block);

      return {
        commentId: attr,
        type: "comment",
        blockId,
        destination: position.destination,
        containerRef: position.containerRef,
        focusElement: comment,
      };
    }
    return null;
  }
};

const checkTagPopup = (event: any) => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    let tag = range.startContainer?.parentElement?.closest("mention");
    if (tag && tag.classList.contains("hashtag")) tag = null;
    if (tag && !tag.classList.contains("not-active")) {
      // const newRange = range.cloneRange();
      // selection.addRange(newRange);
      const attr = tag?.getAttribute("href");
      const block = tag.closest(".content-section") as HTMLDivElement;
      const blockId = block?.getAttribute("data-block-id");
      const caretPosition = checkCaretPosition(block);
      if (caretPosition === 0) return null;
      if (
        tag.contains(range.endContainer) &&
        range.endOffset === tag.textContent?.length
      )
        return null;

      const position = getDestination(block);

      return {
        documentId: attr,
        type: "tag",
        blockId,
        destination: position.destination,
        containerRef: position.containerRef,
        focusElement: tag,
      };
    }
    return null;
  }
};

const getDestination = (block: Element | null) => {
  const destinationTest = block?.closest(".commentContainer");
  let destination = "primary";
  let containerRef = null;
  if (!destinationTest) {
    const destinationTest = block?.closest("#primary");
    destination = destinationTest ? "primary" : "secondary";
    if (destination === "primary") containerRef = destinationTest;
    else {
      containerRef = document.getElementById("secondary");
    }
  } else {
    destination = ChunkDestination.taskComments;
    const preCheck = destinationTest.closest(".document-view");
    if (preCheck) containerRef = preCheck.parentElement;
    else
      containerRef =
        destinationTest.closest(".taskScreen")?.parentElement?.parentElement;
  }
  return {
    destination,
    containerRef,
  };
};

const checkForLinkPopup = (e: any) => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    let link = range.startContainer?.parentElement?.closest("a");
    if (link) {
      let inProject =
        range.startContainer?.parentElement?.closest("div.project-style");
      if (inProject) return null;
      const newRange = range.cloneRange();
      selection.addRange(newRange);
      const attr = link?.getAttribute("href");
      const block = link.closest(".content-section") as HTMLDivElement;
      const blockId = block?.getAttribute("data-block-id");
      const caretPosition = checkCaretPosition(block);
      if (caretPosition === 0) return null;
      if (
        link.contains(range.endContainer) &&
        range.endOffset === link.textContent?.length
      )
        return null;

      const position = getDestination(block);

      return {
        linkTo: attr,
        type: "link",
        blockId,
        destination: position.destination,
        containerRef: position.containerRef,
        focusElement: link,
      };
    }
    return null;
  }
};

const getCardCoordinates = (popupData: {
  blockId: string;
  destination: string;
  citationId?: string;
  commentId?: string;
  containerRef: HTMLElement | null;
  focusElement: HTMLElement;
}) => {
  const bodyRect = document.body.getBoundingClientRect(),
    elemRect = popupData.focusElement.getBoundingClientRect();
  return {
    left: elemRect.left - bodyRect.left,
    top: elemRect.top - bodyRect.top + 20,
  };
};

const showCardCase = (popupData: {
  blockId: string;
  destination: string;
  citationId: string;
  containerRef: HTMLElement | null;
  focusElement: HTMLElement;
}) => {
  const coordinates = getCardCoordinates(popupData);
  const el = (
    <div
      className="popup-container"
      style={{
        position: "absolute",
        left: coordinates.left,
        top: coordinates.top,
        zIndex: 999,
      }}
    >
      <CitationPopup
        destination={popupData.destination}
        citationId={popupData.citationId}
        blockId={popupData.blockId}
      />
    </div>
  );
  if (popupData.containerRef) return ReactDOM.createPortal(el, document.body);
  else return <></>;
};

const showTagCardCase = (popupData: {
  blockId: string;
  destination: string;
  documentId: string;
  containerRef: HTMLElement | null;
  focusElement: HTMLElement;
}) => {
  const coordinates = getCardCoordinates(popupData);

  const el = (
    <div
      className="popup-container"
      style={{
        position: "absolute",
        left: coordinates.left,
        top: coordinates.top,
        zIndex: 999,
      }}
    >
      <TagPopup
        destination={popupData.destination}
        documentId={popupData.documentId}
        blockId={popupData.blockId}
        focusElement={popupData.focusElement}
      />
    </div>
  );
  if (popupData.containerRef) return ReactDOM.createPortal(el, document.body);
  else return <></>;
};

const showLinkCardCase = (popupData: {
  blockId: string;
  destination: string;
  linkTo: string;
  containerRef: HTMLElement | null;
  focusElement: HTMLElement;
}) => {
  // let container;
  // if (popupData.containerRef) container = popupData.containerRef.children[0];
  const coordinates = getCardCoordinates(popupData);

  const el = (
    <div
      className="popup-container"
      style={{
        position: "absolute",
        left: coordinates.left,
        top: coordinates.top,
        zIndex: 999,
      }}
    >
      <LinkPreviewCard popupData={popupData} />
    </div>
  );
  if (popupData.focusElement) return ReactDOM.createPortal(el, document.body);
  else return <></>;
};

export default SelectionListener;
