import { groupApi } from "clientApi/groupsApi";
import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useMemo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import {
  getNextRank,
  getPreviousRank,
  getRankBetween,
} from "utilities/containerRankHelpers";
import { Abilities } from "utilities/types";
import styles from "../groups/groups.module.scss";
import GroupRow from "./GroupRow";

const GroupSection: React.FC = () => {
  const groupIds = useSelector(
    (store: ClarityStore) => store.groups.ids,
    shallowEqual
  );

  const groups = useSelector(
    (store: ClarityStore) => Object.values(store.groups.dict),
    shallowEqual
  );

  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });

  const handleDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) return;
    if (!canEdit) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const reveresedStatuses = [...groups];
    const workStatusChanging = reveresedStatuses[source.index];
    let newRank = "";
    if (destination.index === 0) {
      const currentFirst = reveresedStatuses[0];
      const currentFirstRank = currentFirst.rank;
      newRank = getPreviousRank(currentFirstRank);
    } else if (destination.index === reveresedStatuses.length - 1) {
      const currentLastRank =
        reveresedStatuses[reveresedStatuses.length - 1].rank;

      if (currentLastRank) {
        newRank = getNextRank(currentLastRank);
      }
    } else {
      if (destination.index > source.index) {
        const rankBefore = reveresedStatuses[destination.index].rank;
        const rankAfter = reveresedStatuses[destination.index + 1].rank;
        newRank = getRankBetween(rankBefore, rankAfter);
      } else if (destination.index < source.index) {
        const rankBefore = reveresedStatuses[destination.index - 1].rank;
        const rankAfter = reveresedStatuses[destination.index].rank;
        newRank = getRankBetween(rankBefore, rankAfter);
      }
    }

    groupApi.updateGroup(
      {
        rank: newRank,
      },
      workStatusChanging.id
    );
  };
  return (
    <>
      {useMemo(
        () => (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
              // isDropDisabled={!canEditBaseSettings || isFormVisible}
              droppableId={"groupsDnD"}
              mode="standard"
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.rowContainer}
                >
                  {groupIds.length && (
                    <div className="label bold secondary mb-5">GROUPS</div>
                  )}
                  {groupIds.map((id, index) => (
                    <GroupRow
                      key={id}
                      id={id}
                      index={index}
                      canEdit={canEdit}
                      canViewDetails={true}
                    />
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ),
        [groupIds]
      )}
    </>
  );
};

export default GroupSection;
