import { getContainerThroughType } from "modules/containersService";
import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BaseType,
  ContainerTypes,
  ITasksViewDetail,
  TasksViewModes,
  ViewAsTypes,
} from "utilities/types";
import DocumentContainer from "./DocumentContainer";
import {
  useShareDataSetter,
  usePageDataSetter,
  useBtnComponentsSetter,
  getActionTrigger,
  TopNavbarType,
  setPaneSubroutes,
  setPaneTopNavViewType,
} from "store/reducers/topNavReducer";
import { generatePageData } from "screens/base/main/DetailView";
import store from "store/storeExporter";
import { SET_SHARE_DATA, SET_TOP_NAV_BTN_COMPONENTS } from "store/actions";
import { useShallowSelector } from "utilities/hooks";
import { ButtonTypes } from "components/Button";
import { containerApi } from "clientApi/containerApi";
import { locationSubject } from "components/LocationListener";
import { useLocation } from "react-router-dom";
import WorkViewConfigurator from "../WorkViewConfigurator";
import { viewModeToConfigDict } from "../defaultWorkView/types";
import { usePaneId } from "store/reducers/filterReducer";
import { useBaseType } from "store/reducers/workspaceReducer";
import { useIsBaseMember } from "store/reducers/clientReducer";
import { batch } from "react-redux";

export enum ContainerViewModes {
  document = "Document View",
  work = "Work View",
}

const ContainerViews: React.FC<{
  containerId: string;
  containerType: ContainerTypes;
}> = ({ containerType, containerId }) => {
  const container: any = useContainerGetter(containerId, containerType);
  const paneId = usePaneId();

  const [containerView, setContainerView] = useState(<></>);
  const [viewMode, setviewMode] = useState<ContainerViewModes>(
    ContainerViewModes.document
  );

  const baseType = useBaseType();
  const location = useLocation();
  const isMember = useIsBaseMember();

  const firstLoad = useRef(true);

  useShareDataSetter(
    paneId,
    containerType === ContainerTypes.TEMPLATE ||
      containerType === ContainerTypes.SNIPPET
      ? undefined
      : {
          activeEntity: container,
          containerId,
          containerType,
        }
  );

  usePageDataSetter(paneId);

  useEffect(() => {
    batch(() => {
      if (containerType === ContainerTypes.PROJECT) {
        setPaneSubroutes({
          paneId,
          subRoutesParams: {
            type: "workItem",
            activeKey: viewMode,
            action: setviewMode,
          },
        });
      }
      setPaneTopNavViewType({
        paneId,
        navType: TopNavbarType.detail,
      });
    });

    return () => {
      batch(() => {
        setPaneSubroutes({
          paneId,
        });
        setPaneTopNavViewType({
          paneId,
        });
      });
    };
  }, [viewMode]);

  const btns = useMemo(() => {
    return [
      getActionTrigger({
        key: "link",
        buttonType: ButtonTypes.DEFAULT,
        condition: () => !isMember,
        disabled: baseType === BaseType.Public,
        onClick: () => {
          const userId = store.getState().user?.id;
          if (userId) {
            containerApi.followContainer({
              baseId: container.workspaceId,
              containerId,
              containerType,
            });
          } else {
            locationSubject.next(`/login?redirectTo=${location.pathname}`);
          }
        },
        contents: () => "Follow",
      }),
    ];
  }, [container?.id, viewMode]);

  useBtnComponentsSetter(
    paneId,
    {
      btnComponents: btns,
    },
    [btns]
  );

  const workViewDetails = useRef({
    mode: TasksViewModes.DueThisWeek,
    context: container?.dateCreated,
    viewSettings: {
      viewAs: ViewAsTypes.board,
    },
    hidePaneHeader: true,
    pageData: generatePageData(containerType, container),
    container,
    containerType,
  } as ITasksViewDetail);

  const hiddenStyle: CSSProperties = {
    visibility: "hidden",
    position: "absolute",
    top: "-9999px",
    left: "-9999px",
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }
    if (viewMode === ContainerViewModes.document) {
      const data = {
        activeEntity: container,
        containerId,
        containerType,
      };
      store.dispatch({ type: SET_SHARE_DATA, paneId, ...data });
      if (containerType === ContainerTypes.NOTE) {
        if (checkIfIsCurrentWeekly(containerId)) {
          return;
        }
      }
    }
  }, [viewMode, container]);

  useLayoutEffect(() => {
    setContainerView(
      <DocumentContainer
        paneId={paneId}
        key={containerId}
        document={container}
        containerId={containerId}
        containerType={containerType}
      />
    );
  }, [container, containerId, containerType, paneId]);

  useEffect(() => {
    return () => {
      setviewMode(ContainerViewModes.document);
      if (containerType === ContainerTypes.PROJECT) {
        store.dispatch({
          type: SET_TOP_NAV_BTN_COMPONENTS,
          paneId,
          btnComponents: [],
        });
      }
    };
  }, []);

  if (container) {
    if (containerType === ContainerTypes.PROJECT) {
      workViewDetails.current = {
        ...workViewDetails.current,
        mode: TasksViewModes.ProjectWork,
        viewSettings: {
          parents: [container.id],
          viewAs: ViewAsTypes.board,
        },
        extraBtns: btns,
        context: undefined,
      };

      return (
        <>
          <div
            style={
              viewMode === ContainerViewModes.work ? hiddenStyle : undefined
            }
          >
            {containerView}
          </div>
          {viewMode === ContainerViewModes.work && (
            <div
              style={{
                flexGrow: 1,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <WorkViewConfigurator
                paneId={paneId}
                workViewDetails={viewModeToConfigDict[
                  TasksViewModes.ProjectWork
                ](container.id)}
              />
            </div>
          )}
        </>
      );
    }
  }
  return containerView;
};

const checkIfIsCurrentWeekly = (containerId: string) => {
  const currentWeekly = store.getState().notes.weeklyNoteId;
  return currentWeekly === containerId;
};

const useContainerGetter = (
  containerId: string,
  containerType: ContainerTypes
) => {
  const [container, setcontainer] = useState<any>(null);
  const entityStores = useShallowSelector((state) => ({
    pagesDict: state.pages.dict,
    notesDict: state.notes.dict,
    weeklyNotesDict: state.notes.weeklyNotesDict,
    workDict: state.work.dict,
    templatesDict: state.templates.dict,
    snippetsDict: state.snippets.dict,
  }));

  useLayoutEffect(() => {
    const container = getContainerThroughType(
      containerId,
      containerType,
      entityStores
    );
    setcontainer({ ...container, type: containerType });
  }, [containerId, containerType, entityStores]);

  return container;
};

export default ContainerViews;
