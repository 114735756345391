import store from "store/storeExporter";
import {
  ContainerTypes,
  GeneralViewsNames,
  GroupGeneralViews,
  NavigationChunk,
  TasksViewModes,
  UserRole,
  ViewNames,
  WorkTypes,
} from "./types";
import {
  FormOutlined,
  FileDoneOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { stripHtml } from "./stringUtilities";
import { getHtml } from "editor/utils/blockValueHelpers";
import {
  getContainerByType,
  getNameFromContainer,
} from "modules/containerHelpers";
import FileTextTwoTone from "icons/Components/FileTextTwoTone";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";
import StarTwoTone from "icons/Components/StarTwoTone";
import ControlTwoTone from "icons/Components/ControlTwoTone";
import navigationApi from "clientApi/navigationApi";
import { getIconFromNavigationChunk } from "./iconHelpers";
import TagsTwoTone from "icons/Components/TagsTwoTone";

export interface IClarityView {
  id: string;
  name: string;
  navigationChunk: NavigationChunk;
  icon: JSX.Element;
  action: (e: any) => void;
}

export const getViewsOptions = () => {
  const storeData = store.getState();
  const data = {
    groupIds: storeData.groups.userGroups,
    groupDict: storeData.groups.dict,
    workDict: storeData.work.dict,
    allProjectIds: storeData.work.allProjectIds,
    allInitiativeIds: storeData.work.allInitiativeIds,
    allTaskIds: storeData.work.allTaskIds,
    pages: storeData.pages.ids,
    pagesDict: storeData.pages.dict,
    base: storeData.workspace,
    customViews: storeData.customWork.dict,
    userRole: storeData.client.roleType,
    weeklyNoteId: storeData.notes.weeklyNoteId,
  };

  const navigate = (navigationChunk: NavigationChunk) => (e: any) => {
    if (e.shiftKey) {
      navigationApi.openSplitViewNavigationChunk(navigationChunk);
    } else {
      navigationApi.navigateMain(navigationChunk);
    }
  };

  return () => {
    const builtInViews: IClarityView[] =
      data.userRole !== UserRole.GUEST
        ? [
            ...(!data.base.showWeekly
              ? []
              : [
                  {
                    name: "Weekly",
                    id: "weekly",
                    navigationChunk: {
                      viewName: GeneralViewsNames.TheWeekly,
                    },

                    action: navigate({
                      viewName: GeneralViewsNames.TheWeekly,
                    }),
                    icon: <StarTwoTone />,
                  },
                ]),
            ...data.groupIds.map((groupId) => {
              const group = data.groupDict[groupId];

              return {
                name: `${group.name} Docs`,
                id: `${group.name} Docs`,
                navigationChunk: {
                  viewName: GroupGeneralViews.Notes,
                  groupSlug: group.slug,
                },
                action: navigate({
                  viewName: GroupGeneralViews.Notes,
                  groupSlug: group.slug,
                }),
                icon: <FormOutlined />,
              };
            }),
            ...data.groupIds.map((groupId) => {
              const group = data.groupDict[groupId];

              return {
                name: `${group.name} Tasks`,
                id: `${group.name} Tasks`,
                navigationChunk: {
                  viewName: GroupGeneralViews.Tasks,
                  groupSlug: group.slug,
                  taskViewMode: TasksViewModes.Tasks,
                },
                action: navigate({
                  viewName: GroupGeneralViews.Tasks,
                  groupSlug: group.slug,
                  taskViewMode: TasksViewModes.Tasks,
                }),
                icon: <FormOutlined />,
              };
            }),
            {
              name: "My Work",
              id: "My Work",
              navigationChunk: {
                viewName: GeneralViewsNames.MyTasks,
                taskViewMode: TasksViewModes.MyTasks,
              },
              action: navigate({
                viewName: GeneralViewsNames.MyTasks,
                taskViewMode: TasksViewModes.MyTasks,
              }),
              icon: <AuditOutlined />,
            },
            {
              name: "Tags",
              id: "tags",
              navigationChunk: {
                viewName: GeneralViewsNames.Wiki,
              },
              action: navigate({
                viewName: GeneralViewsNames.Wiki,
              }),
              icon: <TagsTwoTone />,
            },
            {
              name: "Roadmap",
              id: "roadmap",
              navigationChunk: {
                viewName: GeneralViewsNames.Roadmap,
              },
              action: navigate({
                viewName: GeneralViewsNames.Roadmap,
              }),
              icon: <FileDoneOutlined />,
            },
            {
              name: "Views",
              id: "Views",
              navigationChunk: {
                viewName: GeneralViewsNames.Views,
              },
              action: navigate({
                viewName: GeneralViewsNames.Views,
              }),
              icon: <ControlTwoTone />,
            },
          ]
        : [
            {
              name: "My Work",
              id: "My Work",
              navigationChunk: {
                viewName: GeneralViewsNames.MyTasks,
                taskViewMode: TasksViewModes.MyTasks,
              },
              action: navigate({
                viewName: GeneralViewsNames.MyTasks,
                taskViewMode: TasksViewModes.MyTasks,
              }),
              icon: <AuditOutlined />,
            },
          ];

    const userViews: IClarityView[] = Object.values(data.customViews).map(
      (view) => ({
        id: view.id,
        name: view.name,
        navigationChunk: {
          viewName: GeneralViewsNames.ViewEntity,
          taskViewMode: TasksViewModes.CustomView,
          entity: {
            containerId: view.id,
            containerType: ContainerTypes.CUSTOM_VIEW,
          },
        },
        action: navigate({
          viewName: GeneralViewsNames.ViewEntity,
          taskViewMode: TasksViewModes.CustomView,
          entity: {
            containerId: view.id,
            containerType: ContainerTypes.CUSTOM_VIEW,
          },
        }),
        icon: <ControlTwoTone />,
      })
    );

    const documents: IClarityView[] = data.pages.map((id: string) => ({
      id,
      name: stripHtml(getHtml(data.pagesDict[id].nameValue)),
      navigationChunk: {
        viewName: ViewNames.Detail,
        entity: {
          containerId: id,
          containerType: ContainerTypes.DOCUMENT,
        },
      },
      action: navigate({
        viewName: ViewNames.Detail,
        entity: {
          containerId: id,
          containerType: ContainerTypes.DOCUMENT,
        },
      }),
      icon: <FileTextTwoTone />,
    }));

    const projects: IClarityView[] = [
      ...data.allProjectIds,
      ...data.allTaskIds,
      ...data.allInitiativeIds,
    ].map((id) => {
      const project = data.workDict[id];
      return {
        id,
        name: getNameFromContainer(project, ContainerTypes.PROJECT),
        navigationChunk: {
          viewName: ViewNames.Detail,
          entity: {
            containerId: project.id,
            containerType: ContainerTypes.WORK,
            workType: project.workType,
          },
        },
        action: navigate({
          viewName: ViewNames.Detail,
          entity: {
            containerId: project.id,
            containerType: ContainerTypes.WORK,
            workType: project.workType,
          },
        }),
        icon:
          project.workType === WorkTypes.TASK ? (
            <CheckCircleTwoTone />
          ) : (
            <FileDoneOutlined />
          ),
      };
    });

    return [
      ...builtInViews,
      ...userViews,
      ...documents,
      ...projects,
    ] as IClarityView[];
  };
};

export const getRecentsAsViewOptions = () => {
  const state = store.getState();
  const baseId = state.workspace.id;
  if (!baseId) return [];
  const hasRecentlyOpened =
    state.recentlyOpened.dict && state.recentlyOpened.dict[baseId];
  const recentlyOpened = hasRecentlyOpened
    ? state.recentlyOpened.dict[baseId]
    : [];

  const navigate = (navigationChunk: NavigationChunk) => (e: any) => {
    if (e.shiftKey) {
      navigationApi.openSplitViewNavigationChunk(navigationChunk);
    } else {
      navigationApi.navigateMain(navigationChunk);
    }
  };

  const viewOptions: IClarityView[] = recentlyOpened.map((item) => {
    let container: any;
    let containerType = ContainerTypes.DOCUMENT;

    if (item.navigationChunk.entity) {
      containerType =
        item.navigationChunk.entity.containerType ?? ContainerTypes.DOCUMENT;

      container = getContainerByType({
        containerId: item.navigationChunk.entity.containerId,
        containerType,
      });
    }

    const title = () => {
      const title = getNameFromContainer(
        container,
        containerType ?? ContainerTypes.DOCUMENT,
        false
      );
      if (!title || title === "") {
        if (item.name) {
          return item.name;
        }
      }

      return title;
    };

    return {
      id: item.navigationChunk.entity?.containerId ?? "",
      icon: getIconFromNavigationChunk(item.navigationChunk),
      name: title(),
      action: navigate(item.navigationChunk),
      navigationChunk: item.navigationChunk,
    };
  });

  return viewOptions;
};
