import Dexie, { Table } from "dexie";
import { IAction } from "./queueService";

class QueueDB extends Dexie {
  byTimeStamp!: Table<IAction, number>;
  constructor() {
    super("QueueDB");
    this.version(7).stores({
      byTimeStamp: "&timestamp",
    });
  }
}

export const db = new QueueDB();
