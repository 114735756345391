import React from "react";
import { every } from "lodash";

interface IConditionalProps {
  on?: any | any[];
  children: React.ReactElement | React.ReactChild[];
}

export default function Conditional({ on, children }: IConditionalProps) {
  const show = !Array.isArray(Boolean(on)) ? on : every(on, Boolean);

  return <>{!show ? null : children}</>;
}
