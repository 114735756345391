import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import ModalScrimComponent from "./ModalScrimComponent";
import styles from "./modalNewTask/modalNewTask.module.scss";
import workItemModalStyles from "screens/base/main/workItemPreviewModal/workItemPreviewModal.module.scss";
import taskPreviewStyles from "screens/base/main/detailView/documentContainer/task/task.module.scss";
import * as actionTypes from "store/actions";
import Button, { ButtonTypes } from "./Button";
import {
  IProjectObj,
  IWorkspaceObj,
  ContainerTypes,
  IBlockContext,
  workStatusCategories,
  WorkTypes,
  ViewNames,
} from "utilities/types";
import { KeyCodes, LineType, LineValueType } from "utilities/lineUtilities";
import ContainerDetailMetadata from "clarity-ui/ContainerDetailMetadata";
import store from "store/storeExporter";
import { sendOnlyTaskNotification } from "modules/taskService";
import { Switch, Tooltip } from "antd";
import {
  connectBlockToEntity,
  navigateAfterBlockUpdate,
} from "editor/utils/specificActions/blockTypesActions";
import { addBlockAfterAndReopenNewTask } from "editor/utils/specificActions/addBlockActions";
import {
  executeTransaction,
  localChangesTransaction,
} from "editor/utils/specificActions/persistActions";
import { getBlockFromExtender } from "editor/utils/socketActionsListener";
import { setMultipleBlocks } from "editor/utils/specificActions/textUpdateActions";
import { useIsMobile, useShallowSelector } from "utilities/hooks";
import { wordTruncate } from "utilities/ellipsisText";
import { executeTextSaveTimeout } from "editor/utils/blockActions";
import DocumentContainer from "screens/base/main/detailView/DocumentContainer";
import { find } from "lodash";
import notificationsApi from "clientApi/notificationsApi";
import workApi from "clientApi/workApi";
import ClaritySelect from "./ClaritySelect";
import { useOnClickOutside } from "editor/utils/customHooks";
import ClosedGroup from "icons/Components/ClosedGroup";
import GroupsIcon from "icons/Components/GroupsIcon";
import { axiosInstance } from "index";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";
import CloseSquareTwoTone from "icons/Components/CloseSquareTwoTone";
import {
  INewTaskCreationModes,
  openNewTaskModal,
} from "store/reducers/clientReducer";
import Conditional from "./Conditional";
import { templatesApi } from "clientApi/templateApi";
import { getCorrectPrefixForWorkItem } from "modules/containerHelpers";
import { ChunkDestination } from "utilities/stateTypes";
import navigationApi from "clientApi/navigationApi";

interface IMapStateToProps {
  base: IWorkspaceObj;
  workDict: any;
}

interface IMapDispatchToProps {
  hideModalNewTask: () => { type: string };
  addNewTask: (project: IProjectObj) => {
    type: string;
    task: IProjectObj;
    sectionId: string | null;
  };
}

function ModalNewTask(props: IMapDispatchToProps & IMapStateToProps) {
  const { base, hideModalNewTask, addNewTask } = props;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const [includeChidlren, setincludeChildren] = useState(true);
  const newTaskContext = useShallowSelector(
    (store) => store.client.newTaskContext
  );
  const presaveTask = {
    statusId: getDefaultStatusId(),
    ...props.workDict[newTaskContext?.presetData?.id ?? ""],
  };

  const taskName = useRef(presaveTask?.name);
  const groupId = useRef(presaveTask?.groupId);

  useEffect(() => {
    taskName.current = presaveTask.name;
    if (taskName.current === "") setIsSaveDisabled(true);
    else setIsSaveDisabled(false);
  }, []);

  const reopenNewTask = () => {
    const presetData = newTaskContext?.presetData
      ? { ...newTaskContext.presetData }
      : {};
    delete presetData.id;

    setTimeout(() => {
      openNewTaskModal({
        type: INewTaskCreationModes.new,
        presetData,
      });
    }, 0);
  };

  const handleCancel = () => {
    taskName.current = "";
    groupId.current = null;
    setIsSaveDisabled(true);
    setIsSaving(false);
    hideModalNewTask();
    if (presaveTask) {
      store.dispatch({
        type: actionTypes.DELETE_WORK_ITEM,
        id: presaveTask.id,
      });
    }
  };

  const handleTaskClick = (task: IProjectObj) => {
    navigationApi.openPeekView({
      viewName: ViewNames.Detail,
      entity: {
        containerId: task.id,
        containerType: ContainerTypes.WORK,
      },
    });
  };

  const handleSave = (taskName: string, reopen: boolean) => {
    if (!newTaskContext) return;
    if (taskName.length < 1) return;

    setIsSaving(true);

    if (taskName.length < 1) return;
    setIsSaving(true);

    let titleBlock = null;
    let executed = false;

    if (presaveTask.tagsBlockId) {
      const block = store.getState().blocks.dict[presaveTask.tagsBlockId];
      if (block) {
        setMultipleBlocks(
          [{ id: block.id, blockData: getBlockFromExtender(block) }],
          {
            autosave: true,
          } as any
        );

        const workItemSaveMode: {
          containerType: ContainerTypes;
          containerId: string;
        }[] = [];
        block.value.forEach((tag: any) => {
          if (tag.linkTo && tag.linkTo !== "") {
            const workItemEntry = {
              containerType: tag.options?.workTag
                ? ContainerTypes.WORK
                : ContainerTypes.DOCUMENT,
              containerId: tag.linkTo ? tag.linkTo : "",
            };
            workItemSaveMode.push(workItemEntry);
          }
        });
        presaveTask.tags = workItemSaveMode;
      }
    }

    if (newTaskContext?.transformBlocks?.selectedBlock.id)
      connectBlockToEntity({
        blockId: newTaskContext.transformBlocks.selectedBlock.id,
        entityId: presaveTask.id,
        entityType: ContainerTypes.PROJECT,
        newBlockType: LineType.work,
        context: newTaskContext.transformBlocks.context,
        save: true,
      });
    executeTextSaveTimeout();

    const localChangesCopy = { ...localChangesTransaction };

    executeTransaction(
      localChangesCopy,
      undefined,
      ContainerTypes.PROJECT + presaveTask.id
    );

    const handleAfterCreation = (newWorkItem: IProjectObj) => {
      addNewTask(newWorkItem);

      const name = wordTruncate(newWorkItem.name, 14);
      notificationsApi.displayConfirmation({
        title: <span>Task created</span>,
        duration: 4,
        actions: [
          { action: () => handleTaskClick(newWorkItem), content: "View" },
        ],
        dismissable: true,
        body: (
          <span className={styles.notification__description}>
            <span>{getCorrectPrefixForWorkItem(newWorkItem)}</span>{" "}
            <span>{name}</span>
          </span>
        ),
        icon: <CheckCircleTwoTone />,
      });

      if (presaveTask.assigneeId) sendOnlyTaskNotification(newWorkItem.id);

      if (newTaskContext?.transformBlocks?.selectedBlock?.id) {
        connectBlockToEntity({
          blockId: newTaskContext.transformBlocks.selectedBlock.id,
          entityId: presaveTask.id,
          entityType: ContainerTypes.PROJECT,
          newBlockType: LineType.work,
          context: newTaskContext.transformBlocks.context,
          save: true,
        });
        if (
          [WorkTypes.INITIATIVE, WorkTypes.PROJECT].includes(
            newWorkItem.workType
          )
        ) {
          navigateAfterBlockUpdate(
            newTaskContext.transformBlocks.context,
            newWorkItem.projectId,
            newWorkItem.id
          );
        }
      }
    };

    const presets: Partial<IProjectObj> = {
      workspaceId: base.id,
      name: taskName,
      nameValue: [{ type: LineValueType.text, value: taskName }],
      workType: presaveTask.workType,
      workSectionId: presaveTask.workSectionId
        ? presaveTask.workSectionId
        : null,
      dueDate: presaveTask.dueDate,
      assigneeId: presaveTask.assigneeId,
      statusId: presaveTask.statusId,
      priority: presaveTask.priority,
      id: presaveTask.id,
      parentId: presaveTask.parentId,
      isClosed: presaveTask.isClosed,
      isDone: presaveTask.isDone,
      titleBlockId: presaveTask.titleBlockId,
      tags: presaveTask.tags,
      tagsBlockId: presaveTask.tagsBlockId,
      contributorIds: presaveTask.contributorIds,
      reviewerId: presaveTask.reviewerId,
      groupId: presaveTask.groupId,
      groupIds: presaveTask.groupIds,
      milestoneId: presaveTask.milestoneId,
      reward: presaveTask.reward,
    };

    if (
      newTaskContext.type === INewTaskCreationModes.duplicate &&
      newTaskContext.generatedFromId
    ) {
      workApi.duplicateWorkItem(
        newTaskContext.generatedFromId,
        presets,
        includeChidlren,
        handleAfterCreation
      );
      executed = true;
    }

    if (
      newTaskContext.type === INewTaskCreationModes.fromTemplate &&
      newTaskContext.generatedFromId
    ) {
      templatesApi.createWorkItemFromTemplate(
        newTaskContext.generatedFromId,
        presets,
        includeChidlren,
        handleAfterCreation
      );
      executed = true;
    }

    if (!executed) {
      axiosInstance
        .post("/api/project/", {
          presets,
          titleBlock,
          noFirstBlock: true,
          name: taskName,
          groupId: groupId.current,
        })
        .then(({ data }) => handleAfterCreation(data.payload));
    }

    setIsSaving(false);
    hideModalNewTask();

    if (reopen) {
      if (newTaskContext?.transformBlocks?.selectedBlock?.id) {
        const id = newTaskContext.transformBlocks.selectedBlock.id;
        setTimeout(() => {
          if (newTaskContext.transformBlocks?.context) {
            addBlockAfterAndReopenNewTask(
              id,
              newTaskContext.transformBlocks?.context
            );
          }
        }, 1);
      } else reopenNewTask();
    }
  };

  const handleKeydown = (event: any, taskName: string) => {
    const { keyCode } = event;
    if ((event.ctrlKey || event.metaKey) && keyCode === KeyCodes.enter) {
      if (event.shiftKey) {
        handleSave(taskName, true);
      } else handleSave(taskName, false);
    } else if (keyCode === KeyCodes.esc) {
      if (taskName === "") handleCancel();
    }
  };

  const isMobile = useIsMobile();

  return (
    <>
      <div
        style={{
          display: "flex",
          zIndex: 99,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <div
          style={{
            maxHeight: "616px",
          }}
          className={`${workItemModalStyles.item_preview_modal} task-modal ${
            workItemModalStyles.item_preview_modal__new
          }  ${isMobile ? workItemModalStyles.item_preview_modal__mobile : ""}`}
        >
          <div
            style={{
              boxShadow: "none",
              height: "100%",
            }}
            onKeyDown={(event) => handleKeydown(event, taskName.current)}
          >
            <div className={taskPreviewStyles.taskBodyContainer_new}>
              <div className={taskPreviewStyles.taskBodyContainer_new_content}>
                <div style={{ paddingLeft: "6px", paddingTop: "32px" }}>
                  {presaveTask.workType === WorkTypes.TASK && (
                    <GroupHolder
                      groupId={groupId}
                      disabled={newTaskContext?.disableSelectGroup}
                      parentId={presaveTask.parentId}
                      workId={presaveTask.id}
                    />
                  )}
                </div>
                <div
                  className={styles.modalNewTask__body__name}
                  style={{ paddingTop: "32px" }}
                >
                  <NameHolder
                    setIsSaveDisabled={setIsSaveDisabled}
                    taskName={taskName}
                  />
                </div>
                <div className={taskPreviewStyles.taskBody__metadata_1}>
                  <div
                    className={
                      taskPreviewStyles.taskBody__metadata_1__workIdContainer
                    }
                  ></div>
                </div>
                <div>
                  <DocumentContainer
                    containerId={presaveTask.id}
                    containerType={ContainerTypes.PROJECT}
                    paneId={ChunkDestination.peek}
                    contextPresets={
                      {
                        autosave: false,
                        id: ContainerTypes.PROJECT + presaveTask.id,
                        container: {
                          id: presaveTask.id,
                          type: ContainerTypes.PROJECT,
                        },
                      } as IBlockContext
                    }
                    showDefault={true}
                  />
                </div>
                <div className="new-task-mobile-sidebar">
                  <ContainerDetailMetadata
                    key={presaveTask.id}
                    noPersist={true}
                    containerId={presaveTask.id}
                    containerType={ContainerTypes.TASK}
                    paneId={ChunkDestination.peek}
                    presave={true}
                    rowMode={true}
                  />
                </div>
              </div>
              <div
                className={`${taskPreviewStyles.taskBodyContainer_new_sidebar} new-task-sidebar`}
              >
                <div
                  className={
                    taskPreviewStyles.taskBodyContainer_new_sidebar_heading
                  }
                >
                  {/* <Conditional on={presaveTask.workType !== WorkTypes.TASK}>
                    <div className="body2 medium disabled">Properties</div>
                  </Conditional> */}
                  <Button buttonType={ButtonTypes.LINK} onClick={handleCancel}>
                    {<CloseSquareTwoTone />}
                  </Button>
                </div>
                {presaveTask && (
                  <ContainerDetailMetadata
                    key={presaveTask.id}
                    noPersist={true}
                    containerId={presaveTask.id}
                    containerType={ContainerTypes.TASK}
                    paneId={ChunkDestination.peek}
                    presave={true}
                    rowMode={false}
                  />
                )}
              </div>
            </div>
            <div
              className={taskPreviewStyles.taskBodyContainer_new_sidebar_footer}
            >
              <p
                className={`small regular disabled hint-bar ${taskPreviewStyles.taskBodyContainer_new_sidebar_hint}`}
              >
                Type “/” to add images and other content to the description
              </p>
              <Button
                className="footer-close"
                buttonType={ButtonTypes.DEFAULT}
                onClick={handleCancel}
              >
                Close
              </Button>
              <div
                className={
                  taskPreviewStyles.taskBodyContainer_new_sidebar_footer_action
                }
              >
                <div>
                  <Conditional
                    on={newTaskContext?.type === INewTaskCreationModes.new}
                  >
                    <Switch onChange={setCreateAnother} />
                    <span className="small regular secondary">
                      {" "}
                      Create another
                    </span>
                  </Conditional>
                  <Conditional
                    on={
                      newTaskContext?.type ===
                        INewTaskCreationModes.duplicate ||
                      newTaskContext?.type ===
                        INewTaskCreationModes.fromTemplate
                    }
                  >
                    <Switch
                      onChange={setincludeChildren}
                      checked={includeChidlren}
                    />
                    <span className="small regular secondary">
                      {" "}
                      Include subtasks
                    </span>
                  </Conditional>
                </div>

                <Tooltip title="Cmd+Enter" trigger={"hover"}>
                  <span>
                    <Button
                      buttonType={ButtonTypes.PRIMARY}
                      onClick={() =>
                        handleSave(taskName.current, createAnother)
                      }
                      style={{ marginLeft: "8px", minWidth: "137px" }} // need to fix this
                      className={
                        isSaveDisabled || isSaving ? styles.disabledButton : ""
                      }
                      disabled={isSaveDisabled || isSaving}
                    >
                      {isSaving ? "Saving..." : "Save"}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <ModalScrimComponent hideModal={() => {}} />
      </div>
    </>
  );
}

const NameHolder: React.FC<{
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  taskName: React.MutableRefObject<string>;
}> = ({ setIsSaveDisabled, taskName }) => {
  const handleTaskNameChange = (event: any) => {
    const { value } = event.target;

    if (value === "") setIsSaveDisabled(true);
    else setIsSaveDisabled(false);
    taskName.current = value;
    setName(value);
  };

  const [name, setName] = useState(taskName.current);
  const InputTaskName = useRef<HTMLInputElement | null>(null);

  const setFocus = useCallback((el: HTMLInputElement | null) => {
    if (el) {
      InputTaskName.current = el;
      setTimeout(() => {
        el.focus();
      }, 1);
    }
  }, []);

  return (
    <input
      style={{ width: "100%", paddingLeft: 0 }}
      type="text"
      value={name}
      onChange={handleTaskNameChange}
      placeholder="Untitled task"
      ref={(el) => setFocus(el)}
    />
  );
};

const GroupHolder: React.FC<{
  groupId: React.MutableRefObject<string>;
  groupOptions?: string[];
  disabled?: boolean;
  parentId: string;
  workId: string;
}> = ({ groupId, groupOptions, disabled, parentId, workId }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [options, setOptions] = useState<any>([]);
  const [selectedGroup, setSelectedGroup] = useState(groupId.current);
  const [selectedGroupSlug, setSelectedGroupSlug] = useState<string>("");
  const groupDict = useShallowSelector((state) => state.groups.dict);
  const workDict = useShallowSelector((state) => state.work.dict);
  const userGroups = useShallowSelector((state) => state.groups.userGroups);
  const [selectionOpen, setselectionOpen] = useState(false);
  const toggleSelectionOpen = (isOpen: boolean) => {
    setselectionOpen(isOpen);
  };

  const handleGroupIdChange = (id: string) => {
    groupId.current = id;
    workApi.updateWorkItem(workId, {
      groupId: id,
      workSectionId: undefined,
    });
    setSelectedGroup(id);
    closeSelection();
    ref.current?.blur();
  };

  const closeSelection = () => {
    toggleSelectionOpen(false);
  };

  useOnClickOutside(ref, closeSelection);

  useLayoutEffect(() => {
    if (parentId) {
      const parent = workDict[parentId];

      if (parent.groupIds) {
        if (parent.groupIds[0]) {
          groupId.current = parent.groupIds[0];
          setSelectedGroup(groupId.current);
        }
      } else {
        groupId.current = parent.groupId;
        setSelectedGroup(groupId.current);
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (groupOptions || parentId) {
      const parent = workDict[parentId];

      let optionsHere;
      if (groupOptions) {
        optionsHere = groupOptions.map((groupId) => {
          const group = groupDict[groupId];
          return {
            value: group.id,
            label: group.slug,
          };
        });
      } else {
        if (parent.groupIds && parent.groupIds.length > 0) {
          optionsHere = parent.groupIds.map((groupId) => {
            const group = groupDict[groupId];
            return {
              value: group.id,
              label: group.name,
            };
          });
        } else {
          const group = groupDict[parent.groupId];
          optionsHere = [
            {
              value: group.id,
              label: group.name,
            },
          ];
        }
      }
      setOptions(optionsHere);
      if (!groupId.current) {
        groupId.current = optionsHere[0]?.value;
        setSelectedGroup(optionsHere[0]?.value);
      }
    } else {
      const optionsHere = userGroups.map((groupId) => {
        const group = groupDict[groupId];
        return {
          value: group.id,
          label: group.name,
          icon: <GroupsIcon />,
        };
      });
      setOptions(optionsHere);
      if (!groupId.current) {
        groupId.current = optionsHere[0]?.value;
        setSelectedGroup(optionsHere[0]?.value);
      }
    }
  }, [groupOptions]);

  useEffect(() => {
    const group = groupDict[selectedGroup];
    setSelectedGroupSlug(group?.name);
    workApi.updateWorkItem(workId, {
      groupId: selectedGroup,
    });
  }, [selectedGroup]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        className="caption medium disabled "
        style={{ paddingRight: "2px" }}
      >
        Create task in
      </span>
      <div className={styles.hiddenContainer}>
        <ClaritySelect
          onChange={handleGroupIdChange}
          options={options}
          defaultValue={groupId.current}
          value={selectedGroup}
          searchable={false}
          minimal={true}
          classNames="hiddenInput"
          open={selectionOpen}
          manualOpen={true}
          container={ref.current}
          dropdownMatchSelectWidth={false}
        />
      </div>
      <div
        className={`${styles.groupName} ${
          selectionOpen && !disabled ? styles.focus : ""
        } ${disabled ? styles.disabled : ""}`}
        ref={ref}
        onFocus={() => {
          if (!disabled || !groupId.current) toggleSelectionOpen(true);
        }}
        tabIndex={-1}
      >
        {disabled ? <ClosedGroup /> : <GroupsIcon />}
        {selectedGroupSlug}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  base: state.workspace,
  workDict: state.work.dict,
});

const mapDispatchToProps = (dispatch: any) => ({
  hideModalNewTask: () =>
    dispatch({ type: actionTypes.HIDE_MODAL_NEW_PROJECT }),
  addNewTask: (project: IProjectObj) =>
    dispatch({ type: actionTypes.ADD_NEW_WORK_ITEM, project }),
});

export function getDefaultStatusId() {
  const todoStatusCategory = find(Object.values(workStatusCategories), {
    name: "Unstarted",
  })?.id;
  const { statusArray, dict: statusDict } = store.getState().work.statuses;
  const statuses = statusArray.map((statusId) => statusDict[statusId]);
  const defaultStatus = find(statuses, {
    categoryId: todoStatusCategory,
  });
  return defaultStatus ? defaultStatus.id : "";
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNewTask);
