import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { BehaviorSubject } from "rxjs";

interface ILocationAction {
  type: "push" | "replace";
  location: string;
}

export const locationSubject: BehaviorSubject<string | ILocationAction | null> =
  new BehaviorSubject(null as string | ILocationAction | null);

const LocationListener: React.FC<{}> = () => {
  const history = useHistory();

  useEffect(() => {
    const sub = locationSubject.subscribe((location) => {
      if (!location) return;
      if (typeof location === "string") {
        if (location !== "") {
          const locationData = location;
          history.push(locationData);
          locationSubject.next(null);
        }
        return;
      }

      if (location.type) {
        if (location.type === "replace") {
          const locationData = location.location;
          history.replace(locationData);
          locationSubject.next(null);
        }
        return;
      }
    });
    return () => {
      if (sub) sub.unsubscribe();
    };
  }, []);

  return <></>;
};

export default LocationListener;
