import { BlockData } from "store/reducers/blockReducer";
import store, { prevState } from "store/storeExporter";
import { LineType } from "utilities/lineUtilities";
import {
  ContainerTypes,
  CustomWorkView,
  GroupTypes,
  INotesObj,
  IProjectObj,
  OrderTypes,
  ViewAsTypes,
  WorkTypes,
} from "utilities/types";
import { generateNewChunk } from "store/reducers/filterReducer";
const { v4: uuidv4 } = require("uuid");

export const createNewBlock = (
  partialBlock: Partial<BlockData>,
  containerData: {
    containerId: string;
    containerType: ContainerTypes;
    baseId: string;
  }
): BlockData => {
  const state = prevState.value;
  const baseId = state?.workspace.id;
  const userId = state && state.user ? state.user.id : "";
  const createdAt = new Date();
  const newId = uuidv4();
  const newBlock: BlockData = {
    id: newId,
    lineType: LineType.text,
    containerId: containerData.containerId,
    containerType: containerData.containerType,
    createdBy: userId,
    dateCreated: createdAt,
    modifiedBy: userId,
    dateModified: createdAt,
    textModifiedBy: userId,
    textDateModified: createdAt,
    baseId: containerData.baseId ? containerData.baseId : baseId,
    indentLevel: 0,
    isFolded: false,
    parentId: "",
    subLinesList: LineType.bulletedList,
    value: [],
    documentRank: "0a",
    relatedEntities: {},
  };
  return { ...newBlock, ...partialBlock };
};

export const createNewNote = () => {
  const state = store.getState();
  const baseId = state.workspace.id;
  const userId = state.user?.id;
  const createdAt = new Date();
  const newId = uuidv4();

  const newNote: INotesObj = {
    id: newId,
    blocksState: [],
    createdById: userId ? userId : "",
    workspaceId: baseId,
    dateCreated: createdAt,
    outlineMode: "noOutline",
    linesArray: [],
    isWeekly: false,
    citations: {},
    titleBlockId: "",
    isPublicAccess: false,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const titleBlock = createNewBlock(
    {
      lineType: LineType.Title,
      containerId: newNote.id,
      containerType: ContainerTypes.NOTE,
      referencingContainerId: newNote.id,
      referencingContainerType: ContainerTypes.NOTE,
      documentRank: "0",
    },
    { containerId: newNote.id, containerType: ContainerTypes.NOTE, baseId }
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const firstBlock = createNewBlock(
    {
      lineType: LineType.text,
      containerId: newNote.id,
      containerType: ContainerTypes.NOTE,
      referencingContainerId: newNote.id,
      referencingContainerType: ContainerTypes.NOTE,
      documentRank: "0a",
    },
    { containerId: newNote.id, containerType: ContainerTypes.NOTE, baseId }
  );
};

export const creteNewProject = (presetData: Partial<IProjectObj>) => {
  const state = store.getState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const baseId = state.workspace.id;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userId = state.user?.id ? state.user.id : "";
  const createdAt = new Date();
  const statuses = state.work.statuses;
  const newId = uuidv4();
  let newWorkItem: IProjectObj = {
    id: newId,
    dateCreated: createdAt,
    authorId: userId,
    dueDate: undefined,
    isClosed: false,
    isDeleted: false,
    name: "",
    nameValue: [],
    parentId: "",
    projectId: 0,
    priority: 0,
    statusId: statuses.statusArray[0],
    titleBlockId: "",
    workType: WorkTypes.TASK,
    tagsBlockId: "",
    childrenAggregate: [],
    workActivities: [],
    contributorIds: [],
    groupId: presetData.groupId ?? "",
    workspaceId: baseId,
    isDone: false,
  };
  //TODO here in context
  newWorkItem = { ...newWorkItem, ...presetData };
  return newWorkItem;
};

export const createNewCustomWorkView = (
  presetData: Partial<CustomWorkView>
) => {
  const state = store.getState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const baseId = state.workspace.id;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userId = state.user ? state.user.id : "";
  const createdAt = new Date();
  const newId = uuidv4();

  let customWorkView: CustomWorkView = {
    id: newId,
    name: "",
    description: "",
    baseId: baseId,
    userId: userId,
    groupBy: GroupTypes.none,
    orderBy: OrderTypes.status,
    viewAs: ViewAsTypes.list,
    showActiveProjects: false,
    filters: generateNewChunk(),
    shared: false,
    dateCreated: createdAt,
    dateModified: createdAt,
    isDeleted: false,
  };

  customWorkView = { ...customWorkView, ...presetData };
  return customWorkView;
};
