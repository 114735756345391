import React, { useEffect, useRef, useState } from "react";
import RelatedPagesInner from "screens/base/main/detailView/documentContainer/document/lineComponent/lineVariants/RelatedPagesInner";
import { IProjectObj } from "utilities/types";
import { BlockPropsWithRef } from "../BlockSplitter";
import { TagsOutlined } from "@ant-design/icons";
import Conditional from "components/Conditional";

const RelatedPagesBlock: React.FC<
  BlockPropsWithRef & {
    isInWorkItem?: boolean;
    workItem?: IProjectObj;
    workItemId?: string;
    disabled?: boolean;
  }
> = (props) => {
  const firstLoad = useRef(true);
  const [toggleFocusToInput, setToggleFocusToInput] = useState(false);
  const [isFocused, setisFocused] = useState(false);

  useEffect(() => {
    firstLoad.current = false;
    return () => {
      firstLoad.current = true;
    };
  }, []);

  return (
    <>
      <Conditional on={props.workItemId}>
        <TagsOutlined className={isFocused ? "tag-icon" : "tag-icon__active"} />
      </Conditional>

      <div
        tabIndex={0}
        className={"content-section Block tags-info"}
        placeholder={props.workItemId ? "Add tags..." : "No tags"}
        ref={props.blockRef}
        data-block-id={props.blockData.id}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (props.context.canEdit) {
            setisFocused(true);
            setToggleFocusToInput(!toggleFocusToInput);
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onFocus={(e) => {
          e.stopPropagation();
          if (props.context.canEdit) setisFocused(true);
        }}
        onBlur={() => {
          if (props.context.canEdit) setisFocused(false);
        }}
        style={{ minWidth: "100px" }}
      >
        <RelatedPagesInner
          {...props}
          isFocused={isFocused}
          setisFocused={setisFocused}
          toggleFocusToInput={toggleFocusToInput}
          shouldPersist={true}
          setToggleFocusToInput={setToggleFocusToInput}
          isInWorkItem={true}
          workItem={props.workItem}
          workItemId={props.workItem?.id}
        />
      </div>
    </>
  );
};

export default RelatedPagesBlock;
