import UserDisplay from "clarity-ui/UserDisplay";
import moment from "moment";
import React from "react";
import { getFullTimeWithYear } from "./TimePassed";

const AuthorDisplay: React.FC<{
  authorId: string;
  creationDate: Date;
  editDate?: Date;
  avtarSize?: number;
  shape?: "circle" | "square";
}> = ({
  authorId,
  creationDate,
  avtarSize = 40,
  shape = "circle",
  editDate,
}) => {
  let caption = getFullTimeWithYear(moment(creationDate));
  if (editDate)
    caption = caption + " • Edited: " + getFullTimeWithYear(moment(editDate));

  return (
    <UserDisplay
      avatarSize={avtarSize}
      labelClassname="body bold primary"
      caption={caption}
      captionClassName="small medium disabled mt-1"
      avatarStyles={{ marginRight: "9px", marginTop: "4px" }}
      id={authorId}
      shape={shape}
    />
  );
};

export default AuthorDisplay;
