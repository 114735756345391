import Icon from "@ant-design/icons";
import React from "react";

export default function ScheduleTwoTone(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        opacity="0.1"
        d="M18 8.25C18 8.35312 17.9156 8.4375 17.8125 8.4375H16.5C16.3969 8.4375 16.3125 8.35312 16.3125 8.25V6.9375H12.8438V8.25C12.8438 8.35312 12.7594 8.4375 12.6562 8.4375H11.3438C11.2406 8.4375 11.1562 8.35312 11.1562 8.25V6.9375H7.6875V8.25C7.6875 8.35312 7.60313 8.4375 7.5 8.4375H6.1875C6.08437 8.4375 6 8.35312 6 8.25V6.9375H3.1875V18.5625H20.8125V6.9375H18V8.25ZM9.9375 16.125C9.9375 16.2281 9.85313 16.3125 9.75 16.3125H5.4375C5.33437 16.3125 5.25 16.2281 5.25 16.125V15C5.25 14.8969 5.33437 14.8125 5.4375 14.8125H9.75C9.85313 14.8125 9.9375 14.8969 9.9375 15V16.125ZM9.9375 12.9375C9.9375 13.0406 9.85313 13.125 9.75 13.125H5.4375C5.33437 13.125 5.25 13.0406 5.25 12.9375V11.8125C5.25 11.7094 5.33437 11.625 5.4375 11.625H9.75C9.85313 11.625 9.9375 11.7094 9.9375 11.8125V12.9375ZM18.7125 10.8L14.8453 16.1602C14.8108 16.2081 14.7654 16.2471 14.7128 16.274C14.6602 16.3009 14.602 16.315 14.543 16.315C14.4839 16.315 14.4257 16.3009 14.3731 16.274C14.3206 16.2471 14.2751 16.2081 14.2406 16.1602L11.5664 12.4523C11.4773 12.3281 11.5664 12.1547 11.7188 12.1547H13.0055C13.125 12.1547 13.2375 12.2109 13.3078 12.3094L14.5453 14.0227L16.9734 10.6547C17.0438 10.5586 17.1562 10.5 17.2734 10.5023H18.5602C18.7125 10.5023 18.8016 10.6758 18.7125 10.8Z"
        fill="currentColor"
      />
      <path
        d="M16.9734 10.6541L14.5453 14.0221L13.3078 12.3088C13.2375 12.2104 13.125 12.1541 13.0055 12.1541H11.7188C11.5664 12.1541 11.4773 12.3276 11.5664 12.4518L14.2406 16.1596C14.2751 16.2075 14.3206 16.2465 14.3731 16.2734C14.4257 16.3004 14.4839 16.3144 14.543 16.3144C14.602 16.3144 14.6602 16.3004 14.7128 16.2734C14.7654 16.2465 14.8108 16.2075 14.8453 16.1596L18.7125 10.7994C18.8016 10.6752 18.7125 10.5018 18.5602 10.5018H17.2734C17.1563 10.4994 17.0438 10.558 16.9734 10.6541ZM9.75 11.6244H5.4375C5.33438 11.6244 5.25 11.7088 5.25 11.8119V12.9369C5.25 13.0401 5.33438 13.1244 5.4375 13.1244H9.75C9.85313 13.1244 9.9375 13.0401 9.9375 12.9369V11.8119C9.9375 11.7088 9.85313 11.6244 9.75 11.6244Z"
        fill="currentColor"
      />
      <path
        d="M21.75 5.25H18V3.9375C18 3.83437 17.9156 3.75 17.8125 3.75H16.5C16.3969 3.75 16.3125 3.83437 16.3125 3.9375V5.25H12.8438V3.9375C12.8438 3.83437 12.7594 3.75 12.6562 3.75H11.3438C11.2406 3.75 11.1562 3.83437 11.1562 3.9375V5.25H7.6875V3.9375C7.6875 3.83437 7.60313 3.75 7.5 3.75H6.1875C6.08437 3.75 6 3.83437 6 3.9375V5.25H2.25C1.83516 5.25 1.5 5.58516 1.5 6V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V6C22.5 5.58516 22.1648 5.25 21.75 5.25ZM20.8125 18.5625H3.1875V6.9375H6V8.25C6 8.35313 6.08437 8.4375 6.1875 8.4375H7.5C7.60313 8.4375 7.6875 8.35313 7.6875 8.25V6.9375H11.1562V8.25C11.1562 8.35313 11.2406 8.4375 11.3438 8.4375H12.6562C12.7594 8.4375 12.8438 8.35313 12.8438 8.25V6.9375H16.3125V8.25C16.3125 8.35313 16.3969 8.4375 16.5 8.4375H17.8125C17.9156 8.4375 18 8.35313 18 8.25V6.9375H20.8125V18.5625Z"
        fill="currentColor"
      />
      <path
        d="M9.75 14.8125H5.4375C5.33437 14.8125 5.25 14.8969 5.25 15V16.125C5.25 16.2281 5.33437 16.3125 5.4375 16.3125H9.75C9.85313 16.3125 9.9375 16.2281 9.9375 16.125V15C9.9375 14.8969 9.85313 14.8125 9.75 14.8125Z"
        fill="currentColor"
      />
    </svg>
  );
}
