import Icon from "@ant-design/icons";
import React from "react";

export default function StarTwoTone(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        opacity="0.1"
        d="M12.0114 4.4621L9.79888 8.94569L4.84888 9.66522L8.43013 13.1574L7.58403 18.0863L12.0114 15.759L16.4387 18.084L15.5926 13.1551L19.1739 9.66522L14.2239 8.94569L12.0114 4.4621Z"
        fill="currentColor"
      />
      <path
        d="M21.2954 8.26839L15.3446 7.40355L12.6845 2.01058C12.6118 1.86292 12.4923 1.74339 12.3446 1.67074C11.9743 1.48792 11.5243 1.64027 11.3392 2.01058L8.67901 7.40355L2.72823 8.26839C2.56417 8.29183 2.41417 8.36917 2.29932 8.48636C2.16048 8.62906 2.08398 8.82105 2.08661 9.02013C2.08925 9.21921 2.17082 9.4091 2.31339 9.54808L6.61885 13.7457L5.60167 19.6731C5.57781 19.811 5.59307 19.9528 5.64571 20.0824C5.69835 20.2121 5.78626 20.3244 5.89948 20.4066C6.0127 20.4888 6.1467 20.5377 6.28628 20.5477C6.42585 20.5576 6.56543 20.5283 6.68917 20.4629L12.0118 17.6645L17.3345 20.4629C17.4798 20.5403 17.6485 20.566 17.8103 20.5379C18.2181 20.4676 18.4923 20.0809 18.422 19.6731L17.4048 13.7457L21.7103 9.54808C21.8274 9.43324 21.9048 9.28324 21.9282 9.11917C21.9915 8.70902 21.7056 8.32933 21.2954 8.26839ZM15.5931 13.1551L16.4392 18.084L12.0118 15.759L7.58448 18.0864L8.43057 13.1575L4.84932 9.66527L9.79932 8.94574L12.0118 4.46214L14.2243 8.94574L19.1743 9.66527L15.5931 13.1551Z"
        fill="currentColor"
      />
    </svg>
  );
}
