import React, { memo, useLayoutEffect, useState } from "react";
import { Block } from "store/reducers/blockReducer";
import { prevState } from "store/storeExporter";
import { LineType } from "utilities/lineUtilities";
import { IBlockContext } from "utilities/types";
import BlockEntry from "./Block";

interface IBlockWrapper {
  blockId: string;
  context: IBlockContext;
  parentListType: string;
  parentSetIsHovered?: any;
  changeBlock: any;
  parentSelected: boolean;
  isFirst: boolean;
}

interface IBlockWrapperContentProps {
  changeBlock: any;
  isFirst: boolean;
  parentSelected: boolean;
  blockData: Block;
  context: IBlockContext;
  parentListType: string;
  parentSetIsHovered?: any;
}

const BlockWrapper: React.FC<IBlockWrapper> = (props) => {
  const [blockData, setblockData] = useState<Block>(
    prevState.value.blocks?.dict[props.blockId]
  );

  useLayoutEffect(() => {
    const block = prevState.value.blocks.dict[props.blockId] as Block;
    const sub = !block?.blockSubject.subscribe
      ? undefined
      : block.blockSubject.subscribe((block) => {
          if (block && block !== blockData) setblockData(block as Block);
        });
    return () => {
      sub?.unsubscribe();
    };
  }, []);

  if (!blockData) return <></>;

  if (blockData.lineType === LineType.table)
    return (
      <TableWrapper context={props.context}>
        <BlockWrapperContent
          changeBlock={props.changeBlock}
          isFirst={props.isFirst}
          blockData={blockData}
          context={props.context}
          parentListType={props.parentListType}
          parentSelected={props.parentSelected}
          parentSetIsHovered={props.parentSetIsHovered}
        />
      </TableWrapper>
    );
  return (
    <BlockWrapperContent
      changeBlock={props.changeBlock}
      isFirst={props.isFirst}
      blockData={blockData}
      context={props.context}
      parentListType={props.parentListType}
      parentSelected={props.parentSelected}
      parentSetIsHovered={props.parentSetIsHovered}
    />
  );
};

const BlockWrapperContent: React.FC<IBlockWrapperContentProps> = ({
  changeBlock,
  isFirst,
  parentListType,
  parentSelected,
  parentSetIsHovered,
  context,
  blockData,
}) => {
  return (
    <React.Fragment>
      <BlockEntry
        changeBlock={changeBlock}
        isFirst={isFirst}
        parentSelected={parentSelected}
        blockData={blockData}
        context={context}
        parentListType={parentListType}
        parentSetIsHovered={parentSetIsHovered}
      />
    </React.Fragment>
  );
};

const TableWrapper: React.FC<{ context: IBlockContext }> = ({
  context,
  children,
}) => {
  return (
    <div
      className="styledScrollbarContainer Block single-row row "
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div
        className="styledScrollbar"
        style={{
          width: "100%",
          overflowY: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(BlockWrapper);
