import { Popover } from "antd";
import Button, { ButtonTypes } from "components/Button";
import { BlockPropsWithRef } from "editor/blockContainer/BlockSplitter";
import { getPopupCoordinates } from "editor/utils/blockMenusActions/menuActions";
import {
  handleCreateLink,
  handleInlineCodeStylingAndSave,
  handleStartComment,
} from "editor/utils/specificActions/textUpdateActions";
import { handleBlockNativeStylingSave } from "editor/utils/specificActions/textUpdateUtils";
import React, { useState } from "react";
import { LineValueType } from "utilities/lineUtilities";
import { ContainerTypes, UserRole } from "utilities/types";
import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  LinkOutlined,
  TagOutlined,
  HighlightOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import {
  checkTagCreationCases,
  updateImmediateText,
} from "editor/utils/blockActions";
import store from "store/storeExporter";

const BlockTooltip: React.FC<BlockPropsWithRef> = (props) => {
  const selection = document.getSelection();
  const range =
    selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
  const coord = getPopupCoordinates(props);
  if (range && coord) {
    return (
      <span
        style={{
          position: "absolute",
          left: coord.x + "px",
          top: coord.y + "px",
          zIndex: 999,
        }}
      >
        <Popover
          overlayClassName="tooltip-popup"
          // align={AlignType}
          overlayStyle={{ zIndex: 99 }}
          placement="top"
          getPopupContainer={() => {
            if (props.context.container.type === ContainerTypes.WORK_ACTIVITY) {
              if (props.context.ref.current) {
                const el: any =
                  props.context.ref.current.closest(".taskScreen");
                if (el && el !== null) return el;
              }
            }
            if (props.context.ref.current) return props.context.ref.current;
            else return document.body;
          }}
          getTooltipContainer={() => {
            if (props.context.container.type === ContainerTypes.WORK_ACTIVITY) {
              if (props.context.ref.current) {
                const el: any =
                  props.context.ref.current.closest(".taskScreen");
                if (el && el !== null) return el;
              }
            }
            if (props.context.ref.current) return props.context.ref.current;
            else return document.body;
          }}
          visible={true}
          content={<ToolTip {...props} />}
        ></Popover>
      </span>
    );
  } else return <></>;
};

const ToolTip: React.FC<BlockPropsWithRef> = (props) => {
  const [check, setCheck] = useState(false);
  const readOnly = !props.context.canEdit;
  const canComment = props.context.canComment;
  const selection = document.getSelection();

  const element = selection?.focusNode?.parentElement;
  const userRole = store.getState().client.roleType;

  return (
    <div className="style-selector Block">
      {!readOnly &&
        props.blockData.containerType !== ContainerTypes.WORK_ACTIVITY &&
        userRole !== UserRole.GUEST && (
          <Button
            className="no-border"
            buttonType={ButtonTypes.LINK}
            icon={<TagOutlined />}
            disabled={element && element?.closest("mention") ? true : false}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseDown={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              if (selection) {
                const text = selection.toString();
                if (text.length > 0) checkTagCreationCases(props, e);
              }
              setCheck(!check);
            }}
          />
        )}
      {!readOnly && (
        <Button
          className="no-border"
          buttonType={
            element && element?.closest("b")
              ? ButtonTypes.PRIMARY
              : ButtonTypes.LINK
          }
          disabled={element && element?.closest("mention") ? true : false}
          icon={<BoldOutlined />}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            handleBlockNativeStylingSave(e, "bold", props);
            setCheck(!check);
          }}
        />
      )}
      {!readOnly && (
        <Button
          className="no-border"
          icon={<ItalicOutlined />}
          disabled={element && element?.closest("mention") ? true : false}
          buttonType={
            element && element?.closest("i")
              ? ButtonTypes.PRIMARY
              : ButtonTypes.LINK
          }
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            handleBlockNativeStylingSave(e, "italic", props);
            setCheck(!check);
          }}
        />
      )}
      {!readOnly && (
        <Button
          className="no-border"
          icon={<StrikethroughOutlined />}
          disabled={element && element?.closest("mention") ? true : false}
          buttonType={
            element && element?.closest("strike")
              ? ButtonTypes.PRIMARY
              : ButtonTypes.LINK
          }
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            handleBlockNativeStylingSave(e, "strikethrough", props);
            setCheck(!check);
          }}
        />
      )}
      {props.blockData.containerType !== ContainerTypes.WORK_ACTIVITY &&
        canComment && (
          <Button
            className="no-border"
            icon={<CommentOutlined />}
            disabled={element && element?.closest("mention") ? true : false}
            buttonType={
              element &&
              element?.closest("comment") &&
              element?.closest("comment")?.classList.contains("active")
                ? ButtonTypes.PRIMARY
                : ButtonTypes.LINK
            }
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseDown={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleStartComment(props);
              setCheck(!check);
            }}
          />
        )}

      {!readOnly && (
        <Button
          className="no-border"
          icon={<HighlightOutlined />}
          buttonType={
            element &&
            (element.nodeName === LineValueType.highlight ||
              element?.closest("highlight"))
              ? ButtonTypes.PRIMARY
              : ButtonTypes.LINK
          }
          disabled={element && element?.closest("mention") ? true : false}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            handleInlineCodeStylingAndSave(
              e,
              props,
              props.blockRef.current,
              LineValueType.highlight
            );
            setCheck(!check);
          }}
        ></Button>
      )}

      {!readOnly && (
        <Button
          className="no-border"
          icon={<CodeIcon />}
          buttonType={
            element &&
            (element.nodeName === LineValueType.code ||
              element?.closest("code"))
              ? ButtonTypes.PRIMARY
              : ButtonTypes.LINK
          }
          disabled={element && element?.closest("mention") ? true : false}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            handleInlineCodeStylingAndSave(
              e,
              props,
              props.blockRef.current,
              LineValueType.code
            );
            setCheck(!check);
          }}
        ></Button>
      )}

      {!readOnly && (
        <Button
          className="no-border"
          icon={<LinkOutlined />}
          disabled={element && element?.closest("mention") ? true : false}
          buttonType={
            element && element?.closest("a")
              ? ButtonTypes.PRIMARY
              : ButtonTypes.LINK
          }
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            handleCreateLink(e);
            updateImmediateText(props.blockData, props.blockRef, props.context);
            setCheck(!check);
          }}
        />
      )}
    </div>
  );
};

const CodeIcon = () => {
  return (
    <span
      role="img"
      aria-label="code"
      style={{
        height: "100%",
        display: "block",
      }}
      className="anticon anticon-code"
    >
      <svg
        viewBox="0 0 30 30"
        style={{
          width: "1em",
          height: "1em",
          fill: "currentcolor",
        }}
        className="anticon"
      >
        <path d="M11.625,4L0,15l11.625,11L13,24.563L2.906,15L13,5.438L11.625,4z M18.375,4L17,5.438L27.094,15L17,24.563L18.375,26L30,15L18.375,4z"></path>
      </svg>
    </span>
  );
};

export default BlockTooltip;
