import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import LabelsSelection from "components/LabelsSelection";
import React from "react";
import { ChunkDestination } from "utilities/stateTypes";
import { ContainerTypes, IProjectObj } from "utilities/types";

const TagsRow: React.FC<{
  canEditEntity: boolean;
  workItem: IProjectObj;
  noPersist?: boolean;
  rowMode?: boolean;
  paneId: ChunkDestination;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ canEditEntity, workItem, noPersist, rowMode, paneId }) => {
  return (
    <MetadataBlock disabled={!canEditEntity} title="Tags">
      <LabelsSelection
        workItem={workItem}
        paneId={paneId}
        disabled={!canEditEntity}
        noPersist={noPersist}
        maxWidth={rowMode ? "" : "200px"}
      />
    </MetadataBlock>
  );
};

export default TagsRow;
