import React, { useContext, useEffect, useRef, useState } from "react";
import { entityKeepTypes, LineStatus, LineType } from "utilities/lineUtilities";
import BlockSplitter, { BlockPropsWithRef } from "./BlockSplitter";
import { Block, FocusType } from "store/reducers/blockReducer";
import {
  checkManualTrigger,
  mouseDown,
  selectionStarted,
} from "components/Overlay";
import FoldButton from "shannon/FoldButton";
import {
  ContainerTypes,
  DocumentModes,
  IBlockContext,
  WorkTypes,
} from "utilities/types";
import { batch } from "react-redux";
import { mouseDownEvent } from "../../screens/base/main/detailView/DocumentContainer";
import BlockHandle from "./BlockHandle";
import BlocksContainer from "./BlocksContainer";
import store, { $documentMode, $focusOn } from "store/storeExporter";
import {
  changeBlockStatus,
  executeTextSaveTimeout,
} from "editor/utils/blockActions";
import {
  blockModeEvents,
  chechForCitationCopy,
  checkForCommand,
} from "editor/utils/blockModeEvents";
import { Checkbox } from "antd";
import BlockDropArea from "./BlockDropArea";
import { batchBlockUpdate } from "editor/utils/specificActions/batchBlockUpdate";
import SourceCitations from "screens/base/main/detailView/documentContainer/document/lineComponent/SourceCitations";
import { BlockContextRef, ContextBlocks } from "./BlockContext";
import { checkImageDrop } from "editor/utils/specificActions/pasteActions";
import PortalPath from "clarity-ui/portalContainer/PortalPath";
import { CheckCircleOutlined, FileDoneOutlined } from "@ant-design/icons";
import { BlockPresence, IUserInfo } from "store/localSync";
import CollaboratorAvatar from "screens/base/main/detailView/documentContainer/document/CollaboratorAvatar";
import BlockDiscussion from "clarity-ui/BlockDiscussion";
import TimePassed from "components/TimePassed";
import UserDisplay from "clarity-ui/UserDisplay";
import DiscussionModal from "clarity-ui/DiscussionModal";
import ReactDOM from "react-dom";
import notificationsApi from "clientApi/notificationsApi";
import Conditional from "components/Conditional";
import {
  ActionIntercept,
  setActionInterceptor,
} from "store/reducers/clientReducer";

export interface BlockProps {
  blockData: Block;
  parentListType: string;
  parentSetIsHovered: any;
  parentSelected: boolean;
  context: IBlockContext;
  changeBlock: any;
  isFirst: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStyleForPositionType = (props: BlockProps): any => {
  let addedNegativeSpace = 0;
  if (
    props.blockData.lineType === LineType.Title &&
    props.blockData.referencingContainerId === props.blockData.containerId &&
    props.parentListType === "no-outline"
  ) {
    addedNegativeSpace = 17;
  }
  return {
    position: "relative",
    marginLeft:
      props.blockData.indentLevel === 0
        ? String(addedNegativeSpace + "px")
        : String(addedNegativeSpace + "px"),
  };
};

const getSubLinesListType = (props: BlockProps) => {
  if (props.parentListType === "bullet-1") {
    if (props.blockData.subLinesList === LineType.numberedList)
      return "numbered-1";
    else return "bullet-2";
  }
  if (props.parentListType === "bullet-2") {
    if (props.blockData.subLinesList === LineType.numberedList)
      return "numbered-1";
    else return "bullet-1";
  }
  if (props.parentListType === "numbered-1") {
    if (props.blockData.subLinesList === LineType.numberedList)
      return "numbered-2";
    else return "bullet-1";
  }
  if (props.parentListType === "numbered-2") {
    if (props.blockData.subLinesList === LineType.numberedList)
      return "numbered-1";
    else return "bullet-1";
  }
  if (props.blockData.subLinesList === LineType.numberedList)
    return "numbered-1";
  if (props.blockData.subLinesList === LineType.bulletedList) return "bullet-1";
  return "bullet-1";
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkForZoomedBlock = (isZoomedBlock: boolean) => {
  if (isZoomedBlock) return " content";
  else return "";
};

const getParentSelected = (props: BlockProps, isFocused: boolean) => {
  if (props.blockData.selected || props.parentSelected) {
    return true;
  }
  return false;
};

const getBlockTypeHandlersPositionMiddle = (
  blockData: Block,
  isFirst: boolean,
  blockRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
  return getBlockTypeHandlersPosition(blockData, isFirst);
};

export const getBlockTypeHandlersPosition = (
  blockData: Block,
  isFirst: boolean
) => {
  if (isFirst) {
    return {
      paddingTop:
        blockData.lineType === LineType.image && blockData.indentLevel === 0
          ? "1rem"
          : blockData.lineType === LineType.heading1 &&
            blockData.indentLevel === 0
          ? "0.4rem"
          : blockData.lineType === LineType.heading2 &&
            blockData.indentLevel === 0
          ? "0.2rem"
          : blockData.lineType === LineType.heading3 &&
            blockData.indentLevel === 0
          ? "0.1rem"
          : blockData.lineType === LineType.heading1 &&
            blockData.indentLevel > 0
          ? "0.47rem"
          : blockData.lineType === LineType.heading2 &&
            blockData.indentLevel > 0
          ? "0.25rem"
          : blockData.lineType === LineType.heading3 &&
            blockData.indentLevel > 0
          ? "0.15rem"
          : blockData.lineType === LineType.work ||
            blockData.lineType === LineType.Title
          ? "0.25rem"
          : "5px",
    };
  } else {
    return {
      paddingTop:
        blockData.lineType === LineType.image && blockData.indentLevel === 0
          ? "1rem"
          : blockData.lineType === LineType.heading1 &&
            blockData.indentLevel === 0
          ? "1.5rem"
          : blockData.lineType === LineType.heading2 &&
            blockData.indentLevel === 0
          ? "1.2rem"
          : blockData.lineType === LineType.heading3 &&
            blockData.indentLevel === 0
          ? "0.75rem"
          : blockData.lineType === LineType.heading1 &&
            blockData.indentLevel > 0
          ? "0.47rem"
          : blockData.lineType === LineType.heading2 &&
            blockData.indentLevel > 0
          ? "0.25rem"
          : blockData.lineType === LineType.heading3 &&
            blockData.indentLevel > 0
          ? "0.15rem"
          : blockData.lineType === LineType.work ||
            blockData.lineType === LineType.Title
          ? "0.25rem"
          : "5px",
    };
  }
};

const getBlockClassByType = (blockType: LineType) => {
  switch (blockType) {
    case LineType.quote:
      return "quote";
    case LineType.callout:
      return "callout";
    default:
      return "";
  }
};

const checkIfHighlighted = (props: BlockProps, isFocused: boolean): string => {
  const documentMode = $documentMode.value;
  if (
    props.blockData.lineType === LineType.Title &&
    props.blockData.containerType === ContainerTypes.NOTE
  ) {
    return " related-pages";
  }
  return ((isFocused &&
    ((documentMode === DocumentModes.BLOCK && !selectionStarted) ||
      (entityKeepTypes.includes(props.blockData.lineType) &&
        !selectionStarted))) ||
    props.blockData.selected === true) &&
    !props.parentSelected
    ? " block-mode-selected"
    : "";
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInitialToggleState = (
  props: BlockProps,
  blockContext: IBlockContext
) => {
  if (blockContext.persistToggle) return props.blockData.isFolded;
  else {
    const zoomedBlockId = blockContext.zoomedBlockId;
    if (!zoomedBlockId) return props.blockData.isFolded;
    if (blockContext.container.zoomedFullNote)
      return !(
        props.blockData.id === zoomedBlockId ||
        props.blockData.parentId === zoomedBlockId
      );
    else return !(props.blockData.id === zoomedBlockId);
  }
};

const getCorrectIcon = (blockData: Block) => {
  if (blockData.referencingContainerId) {
    const workItem =
      store.getState().work.dict[blockData.referencingContainerId];
    if (workItem && workItem.workType) {
      if (workItem.workType === WorkTypes.INITIATIVE)
        return <FileDoneOutlined />;
      if (workItem.workType === WorkTypes.PROJECT) return <FileDoneOutlined />;
      if (workItem.workType === WorkTypes.TASK) return <CheckCircleOutlined />;
    }
    return false;
  } else return false;
};

const BlockEntry: React.FC<BlockProps> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const citationRef = useRef<HTMLDivElement | null>(null);
  const [isFocused, setisFocused] = useState(false);
  const blocksRef = useContext(BlockContextRef);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isZoomedBlock, setisZoomedBlock] = useState(
    props.context.zoomedBlockId &&
      props.context.zoomedBlockId === props.blockData.id
      ? true
      : false
  );
  // const blockContext = useContext(BlockContextPass);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFolded, setisFolded] = useState(props.blockData.isFolded);

  useEffect(() => {
    if (props.context.persistToggle) setisFolded(props.blockData.isFolded);
  }, [props.blockData.isFolded]);

  useEffect(() => {
    blocksRef.current[props.blockData.id] = ref;
  }, []);

  const showOnlyIcon = isZoomedBlock
    ? [LineType.Title, LineType.work].includes(props.blockData.lineType)
      ? getCorrectIcon(props.blockData)
      : false
    : false;

  return (
    <>
      {isZoomedBlock && (
        <DisplayBlockAncestors
          ancestors={props.blockData.ancestors ? props.blockData.ancestors : []}
          context={props.context}
          linkTagType={showOnlyIcon}
        />
      )}
      {!showOnlyIcon && (
        <div
          className={
            "Block single-row row" +
            checkIfHighlighted(props, isFocused) +
            (props.isFirst && props.blockData.indentLevel === 0 ? " first" : "")
          }
        >
          <div
            className="section-big"
            tabIndex={-1}
            onFocus={(e) => {
              setisFocused(true);
              e.stopPropagation();
              if (entityKeepTypes.includes(props.blockData.lineType)) {
                $focusOn.next({
                  caretPosition: 0,
                  focusBlockId: props.blockData.id,
                  focusContext: props.context,
                  focusPane: props.context.paneId,
                  type: FocusType.prevPosition,
                  refocusToggle: false,
                  focusBlockRef: ref,
                });
              }
            }}
            onBlur={(e) => {
              setisFocused(false);
              if (props.context.autosave) executeTextSaveTimeout();
              e.stopPropagation();
            }}
            onPaste={(e) => {
              if (!props.context.canEdit) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              if (e.clipboardData.files.length === 1) {
                e.preventDefault();
                e.stopPropagation();
                const text = e.currentTarget.innerText;
                if (
                  text === "" &&
                  !entityKeepTypes.includes(props.blockData.lineType)
                )
                  checkImageDrop(e, ref, e.clipboardData, props, true);
                else checkImageDrop(e, ref, e.clipboardData, props, false);
                return;
              }
            }}
            onCut={(e) => {
              if (!props.context.canEdit) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
            }}
            onMouseEnter={(e) => {
              if (mouseDown && !selectionStarted) {
                checkManualTrigger(mouseDownEvent, true);
              }
              if (!selectionStarted) {
                if (props.parentSetIsHovered) {
                  props.parentSetIsHovered(false);
                }
              }
            }}
            onKeyDown={(e) => {
              if (props.context.showInterceptor)
                setActionInterceptor(ActionIntercept.authedUserInPublicBase);
              if (props.context.canEdit) {
                const citationCopied = chechForCitationCopy(e, props, ref);
                if (citationCopied) return;
                checkForCommand(e, props, ref);
              }

              if (
                $documentMode.value === DocumentModes.BLOCK ||
                entityKeepTypes.includes(props.blockData.lineType) ||
                props.blockData.lineType === LineType.code
              ) {
                blockModeEvents(e, props, ref);
              }
            }}
            onMouseLeave={(e) => {
              batch(() => {
                if (mouseDown && !selectionStarted) {
                  checkManualTrigger(mouseDownEvent, true);
                }
              });
            }}
            onMouseDown={(e) => {
              $documentMode.next(DocumentModes.INSERT);
            }}
          >
            <BlockPreContent
              {...props}
              blockRef={ref}
              isFolded={false}
              blocksRef={blocksRef}
            />
            <div
              className={`flex-section ${getBlockClassByType(
                props.blockData.lineType
              )}`}
              style={{ flexWrap: "wrap" }}
            >
              <>
                {props.isFirst &&
                  (!props.context.zoomedBlockId ||
                    props.blockData.id !== props.context.zoomedBlockId) &&
                  !(
                    props.blockData.lineType === LineType.Title &&
                    props.blockData.referencingContainerType ===
                      ContainerTypes.NOTE
                  ) &&
                  props.context.canEdit &&
                  props.blockData.indentLevel === 0 && (
                    <BlockDropArea
                      dropType="sibling"
                      options="before"
                      context={props.context}
                      parentProps={props}
                      readOnly={false}
                      selected={
                        props.blockData.selected || props.parentSelected
                      }
                      id={props.blockData.id}
                      indentLevel={props.blockData.indentLevel}
                    />
                  )}
              </>
              <div
                style={{ display: "flex", width: "100%" }}
                className={"Block__body"}
              >
                {props.blockData.lineType === LineType.checkbox && (
                  <Checkbox
                    onClick={(e) => {
                      e.nativeEvent.stopPropagation();
                      if (
                        !props.context.canEdit &&
                        !props.context.canChangeCheckbox
                      ) {
                        e.nativeEvent.preventDefault();
                        return;
                      }
                    }}
                    style={{
                      paddingRight: "3px",
                      zIndex: 81,
                    }}
                    checked={props.blockData.checkboxStatus === LineStatus.done}
                    disabled={false}
                    onChange={(e) => {
                      e.stopPropagation();
                      if (
                        !props.context.canEdit &&
                        !props.context.canChangeCheckbox
                      ) {
                        return;
                      }
                      changeBlockStatus(props);
                    }}
                  ></Checkbox>
                )}
                <BlockSplitter
                  {...props}
                  blockRef={ref}
                  blockComponentData={{ isFocused }}
                />
                <SourceCitations
                  id={props.blockData.id}
                  blockData={props.blockData}
                  blockRef={citationRef}
                  paneId={props.context.paneId}
                ></SourceCitations>
              </div>
              <DiscussionChecker
                {...props}
                blockRef={ref}
                blockComponentData={{ isFocused }}
              />
              {/* <div style={{ width: "100%" }}>1 comment</div> */}
              <div style={{ width: "100%" }} ref={citationRef}></div>
              <ChildrenHandler
                {...props}
                isZoomedBlock={isZoomedBlock}
                isFocused={isFocused}
                isFolded={false}
              />
              {(!props.context.zoomedBlockId ||
                props.blockData.id !== props.context.zoomedBlockId) &&
                props.context.canEdit && (
                  <BlockDropArea
                    dropType="sibling"
                    options="after"
                    context={props.context}
                    parentProps={props}
                    readOnly={false}
                    selected={props.blockData.selected || props.parentSelected}
                    id={props.blockData.id}
                    indentLevel={props.blockData.indentLevel}
                  />
                )}
              <Conditional on={props.blockData.lineType !== LineType.table}>
                <div
                  className={
                    props.blockData.indentLevel > 0
                      ? "selection-fill list-style-fill"
                      : " selection-fill"
                  }
                ></div>
              </Conditional>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ChildrenHandler: React.FC<
  BlockProps & { isFocused: boolean; isFolded: boolean; isZoomedBlock: boolean }
> = (props) => {
  const checkToggle = () => props.blockData.isFolded;
  return (
    <>
      {props.context.canEdit &&
        ![LineType.work, LineType.Title, LineType.table].includes(
          props.blockData.lineType
        ) && (
          <BlockDropArea
            dropType="child"
            options="after"
            context={props.context}
            parentProps={props}
            readOnly={false}
            selected={props.blockData.selected || props.parentSelected}
            id={props.blockData.id}
            indentLevel={props.blockData.indentLevel}
          />
        )}
      {LineType.table !== props.blockData.lineType &&
        props.blockData.children &&
        props.blockData.children.length > 0 &&
        !checkToggle() && (
          <>
            <ol
              className={"list-type-" + getSubLinesListType(props)}
              style={{
                marginTop: "-2px",
              }}
            >
              <BlocksContainer
                changeBlock={props.changeBlock}
                parentListType={getSubLinesListType(props)}
                parentSelected={getParentSelected(props, props.isFocused)}
                blockId={props.blockData.id}
                context={props.context}
                childIds={props.blockData.children}
              />
            </ol>
          </>
        )}
    </>
  );
};

const BlockPreContent: React.FC<
  BlockProps & {
    blockRef: React.MutableRefObject<HTMLDivElement | null>;
    isFolded: boolean;
    blocksRef: React.MutableRefObject<ContextBlocks>;
  }
> = (props) => {
  const [isHovered, setisHovered] = useState(false);
  const [isHandleClicked, setisHandleClicked] = useState(false);
  const checkToggle = () => props.blockData.isFolded;

  const hoverState = useRef({
    isHovered,
  });

  const presence = BlockPresence[props.blockData.id];

  useEffect(() => {
    const handleMouseOver = (e: Event) => {
      e.stopPropagation();
      if (!hoverState.current.isHovered) {
        setisHovered(true);
        hoverState.current.isHovered = true;

        const parentEl = props.blocksRef.current[props.blockData.parentId];

        if (!parentEl) return;

        const parent = parentEl.current?.closest(".Block");
        const event = new Event("mouseleave");
        if (parent) parent.dispatchEvent(event);
      }
    };

    const block = props.blockRef.current;

    if (block) {
      block.closest(".Block")?.addEventListener("mouseover", (e) => {
        handleMouseOver(e);
      });

      block.closest(".Block")?.addEventListener("mouseleave", (e) => {
        e?.stopPropagation();
        if (hoverState.current.isHovered) {
          setisHovered(false);
          hoverState.current.isHovered = false;
        }
      });
    }

    return () => {
      if (block) {
        block.closest(".Block")?.removeEventListener("mouseover", (e) => {
          handleMouseOver(e);
        });
        block.closest(".Block")?.removeEventListener("mouseleave", (e) => {
          e?.stopPropagation();
          if (hoverState.current.isHovered) {
            setisHovered(false);
            hoverState.current.isHovered = false;
          }
        });
      }
    };
  }, []);

  return (
    <div
      title={props.blockData.lineType}
      className="Block__head"
      style={{
        ...getBlockTypeHandlersPositionMiddle(
          props.blockData,
          props.isFirst,
          props.blockRef
        ),
      }}
    >
      {presence && presence.length > 0 && (
        <PresenceHolder id={props.blockData.id} presence={presence} />
      )}
      <Conditional
        on={props.blockData.containerType !== ContainerTypes.WORK_ACTIVITY}
      >
        <div className="Block__head__foldButtonContainer">
          {(isHovered || checkToggle()) &&
            props.blockData.lineType !== LineType.table &&
            props.blockData.children &&
            props.blockData.children.length > 0 && (
              <>
                <FoldButton
                  isFolded={checkToggle()}
                  style={{
                    paddingRight: "2px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                  size="micro"
                  onClick={() => {
                    batch(() => {
                      batchBlockUpdate({
                        context: props.context,
                        delta: { isFolded: !props.blockData.isFolded },
                        id: props.blockData.id,
                        selectedBlocks: [props.blockData.id],
                      });
                    });
                  }}
                />
              </>
            )}
        </div>
      </Conditional>

      <div
        className="Block__head__numeralContainer"
        onMouseOver={(e) => e.preventDefault()}
      >
        {(isHovered || isHandleClicked) &&
          props.context.canEdit &&
          !(
            props.blockData.lineType === LineType.Title &&
            props.blockData.containerType === ContainerTypes.NOTE
          ) && (
            <>
              {
                <BlockHandle
                  id={props.blockData.id}
                  setisHandleClicked={setisHandleClicked}
                  isHandleClicked={isHandleClicked}
                  parentProps={props}
                />
              }
            </>
          )}
        <>
          {!(
            props.blockData.lineType === LineType.Title &&
            props.blockData.containerType === ContainerTypes.NOTE
          ) && (
            <span
              className="Block__head__numeralContainer__numeral"
              style={{ color: isHovered ? "transparent" : "inherit" }}
            ></span>
          )}
        </>
      </div>
    </div>
  );
};

const PresenceHolder: React.FC<{
  id: string;
  presence: IUserInfo[];
}> = ({ id, presence }) => {
  return (
    <div
      key={id}
      style={{ position: "relative", height: "22px", marginTop: "4px" }}
    >
      {presence.map((user, index) => (
        <CollaboratorAvatar
          user={user}
          key={user.clientId}
          index={presence.length - index}
        />
      ))}
    </div>
  );
};

const DisplayBlockAncestors: React.FC<{
  ancestors: string[];
  linkTagType?: any;
  context: IBlockContext;
}> = ({ ancestors, linkTagType, context }) => {
  // const ancestorsCount = ancestors ? ancestors.length : 0;
  // const setContext = useContext(BlockContextUpdater);
  // const blockContext = useContext(BlockContextPass);
  const blockId = context.zoomedBlockId;

  return (
    <PortalPath
      ancestors={ancestors}
      blockId={blockId}
      linkedEntity={{}}
      linkTagIcon={linkTagType && <span>{linkTagType}</span>}
    />
  );
};

const DiscussionChecker: React.FC<BlockPropsWithRef> = (props) => {
  const [openPoral, setopenPortal] = useState(false);
  const [hasUnconfirmed, sethasUnconfirmed] = useState(false);
  const actions = useRef({
    setopenPortal,
    sethasUnconfirmed,
  });

  useEffect(() => {
    if (props.blockData.options?.creatingDiscussion) setopenPortal(true);
    if (props.blockData.options?.editingDiscussion) setopenPortal(true);
  }, [props.blockData.options]);

  useEffect(() => {
    if (openPoral) props.blockRef.current?.classList.add("flash");
    else props.blockRef.current?.classList.remove("flash");
  }, [openPoral]);

  if (openPoral)
    return (
      <>
        <DiscussionEntry blockData={props.blockData} actions={actions} />
        {ReactDOM.createPortal(
          <DiscussionModal
            title="Discussion"
            hideModal={() => {
              if (hasUnconfirmed) {
                notificationsApi.displayError({
                  body: <span> You have an unsaved comment</span>,
                  duration: 3,
                });
              } else setopenPortal(false);
            }}
          >
            <BlockDiscussion
              actions={actions}
              hasUnconfirmed={hasUnconfirmed}
              discussionId={
                props.blockData.relatedEntities.discussion
                  ? props.blockData.relatedEntities.discussion.id
                  : null
              }
              relatedBlock={props.blockData}
            />
          </DiscussionModal>,
          document.body
        )}
      </>
    );
  // eslint-disable-next-line no-lone-blocks
  return <DiscussionEntry blockData={props.blockData} actions={actions} />;
};

const DiscussionEntry: React.FC<{
  blockData: Block;
  actions: React.MutableRefObject<{
    setopenPortal: React.Dispatch<React.SetStateAction<boolean>>;
  }>;
}> = (props) => {
  if (props.blockData.relatedEntities?.discussion) {
    const discussion = props.blockData.relatedEntities.discussion;
    return (
      <div
        onClick={() => props.actions.current.setopenPortal(true)}
        className="discussion-strip"
      >
        {discussion.partecipants.map((partecipant) => (
          <UserDisplay id={partecipant} key={partecipant} hideName={true} />
        ))}
        <span
          className="caption medium secondary"
          style={{ marginLeft: "7px", fontSize: "0.8125rem" }}
        >
          {discussion.replyCount}{" "}
          {discussion.replyCount < 2 ? "reply" : "replies"}
        </span>
        <span
          className="small secondary"
          style={{ marginBottom: 0, marginLeft: "7px", lineHeight: "13px" }}
        >
          last reply{" "}
          <TimePassed
            startTime={new Date(discussion.lastUpdated)}
            noTooltip={true}
          />
        </span>
      </div>
    );
  } else return <></>;
};

export default BlockEntry;
