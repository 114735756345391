import Icon from "@ant-design/icons";
import React from "react";

export default function SnippetsTwoTone(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        opacity="0.1"
        d="M10.5469 11.9531V7.875H5.4375V20.8125H15.5625V12.8906H11.4844C10.9664 12.8906 10.5469 12.4711 10.5469 11.9531Z"
        fill="currentColor"
      />
      <path
        d="M19.5 2.625H16.9688V1.6875C16.9688 1.58437 16.8844 1.5 16.7812 1.5H15.4688C15.3656 1.5 15.2812 1.58437 15.2812 1.6875V2.625H11.7188V1.6875C11.7188 1.58437 11.6344 1.5 11.5312 1.5H10.2188C10.1156 1.5 10.0312 1.58437 10.0312 1.6875V2.625H7.5C7.08516 2.625 6.75 2.96016 6.75 3.375V6.1875H4.5C4.08516 6.1875 3.75 6.52266 3.75 6.9375V21.75C3.75 22.1648 4.08516 22.5 4.5 22.5H16.5C16.9148 22.5 17.25 22.1648 17.25 21.75V19.5H19.5C19.9148 19.5 20.25 19.1648 20.25 18.75V3.375C20.25 2.96016 19.9148 2.625 19.5 2.625ZM15.5625 20.8125H5.4375V7.875H10.5469V11.9531C10.5469 12.4711 10.9664 12.8906 11.4844 12.8906H15.5625V20.8125ZM15.5625 11.3906H12.0469V7.875H12.0516L15.5625 11.3859V11.3906ZM18.5625 17.8125H17.25V10.6875L12.75 6.1875H8.4375V4.3125H10.0312V5.0625C10.0312 5.16563 10.1156 5.25 10.2188 5.25H11.5312C11.6344 5.25 11.7188 5.16563 11.7188 5.0625V4.3125H15.2812V5.0625C15.2812 5.16563 15.3656 5.25 15.4688 5.25H16.7812C16.8844 5.25 16.9688 5.16563 16.9688 5.0625V4.3125H18.5625V17.8125Z"
        fill="currentColor"
      />
    </svg>
  );
}
