import { UPDATE_SNIPPET } from "store/actions";
import store, { ClarityStore } from "store/storeExporter";
import { ISnippetObj } from "utilities/types";

const { v4: uuidv4 } = require("uuid");

export const handleNewSnippetItemCreationCases = (action: any, next: any) => {
  const storeData: ClarityStore = store.getState();
  const presetData = action.newSnippetContext ?? {};

  const creationDate = new Date();

  let newSnippet: ISnippetObj = {
    id: uuidv4(),
    authorId: storeData.user?.id,
    dateCreated: creationDate,
    isShared: true,
    name: "",
    outlineMode: "noOutline",
    workspaceId: storeData.workspace.id,
    description: "",
  };

  newSnippet = { ...newSnippet, ...presetData };

  const newAction = {
    type: UPDATE_SNIPPET,
    param: {
      delta: newSnippet,
      type: "add",
      skipInsertInList: true,
      id: newSnippet.id,
    },
  };
  next(newAction);

  if (action.newSnippetContext) action.newSnippetContext.id = newSnippet.id;
  else
    action.newSnippetContext = {
      id: newSnippet.id,
    };
};
