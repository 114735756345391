import React, { useEffect, memo } from "react";
import { socket } from "App";
import { joinedRooms } from "store/storeExporter";

interface Props {
  documentId: string;
}

const ActiveUsers: React.FC<Props> = (props) => {
  useEffect(() => {
    if (!joinedRooms.value.includes(props.documentId)) {
      const newRoomArray = [...joinedRooms.value];
      newRoomArray.push(props.documentId);
      joinedRooms.next(newRoomArray);
      socket.emit("connectToRoom", { roomId: props.documentId });
    }

    socket.on("newUserInRoom", () => {
      socket.emit("getRoomUsers", { roomId: props.documentId });
    });

    socket.on("userLeftRoom", () => {
      socket.emit("getRoomUsers", { roomId: props.documentId });
    });

    return () => {
      socket.removeListener("newUserInRoom");
      socket.removeListener("userLeftRoom");
      if (joinedRooms.value.includes(props.documentId)) {
        const newRoomArray = [...joinedRooms.value];
        const index = newRoomArray.indexOf(props.documentId);
        newRoomArray.splice(index, 1);
        joinedRooms.next(newRoomArray);
        socket.emit("disconnectFromRoom", { roomId: props.documentId });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.documentId]);

  return <></>;
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.documentId !== nextProps.documentId;

export default memo(ActiveUsers, areEqual);
