import { locationSubject } from "components/LocationListener";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import store from "store/storeExporter";

const SubscriptionSuccess = (props: any) => {
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const workspaces: any = store.getState().user?.workspaceEntries;
    const workspace: any = Object.values(workspaces).find(
      (workspace: any) => workspace.slug === query.get("baseSlug")
    );
    locationSubject.next(`/${workspace?.slug}`);
  }, []);
  return <></>;
};

export default SubscriptionSuccess;
