import FabBtn, {
  IFabBtnProps,
  MenuItem,
  useMenuControllerState,
} from "components/FabBtn";
import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import styles from "./addNewFabBtn/addNewFabBtn.module.scss";
import {
  createAndSetNewPage,
  createAndSetNewProject,
  createAndSetNewInitiative,
  setNewView,
} from "utilities/createNewContainers";
import { useSelector, shallowEqual } from "react-redux";
import { useInvitationsModalSetter } from "screens/base/main/settings/members/ModalInvitations";
import {
  INewTaskCreationModes,
  openNewTaskModal,
} from "store/reducers/clientReducer";
import { useOptionalClassName } from "utilities/hooks";
import store, { ClarityStore } from "store/storeExporter";
import { Abilities, IGroup, UserRole } from "utilities/types";
import { ADD_NEW_NOTE } from "store/actions";
import { useAbilityChecker } from "editor/utils/customHooks";
import { ButtonTypes } from "components/Button";
import { locationSubject } from "./LocationListener";
import { usePaneId } from "store/reducers/filterReducer";
import { axiosInstance } from "index";
import ChevronDown from "icons/Components/ChevronDown";

interface IAddNewFabBtnProps {
  standalone?: boolean;
  menuYDir: IFabBtnProps["menuYDir"];
  menuXDir: IFabBtnProps["menuXDir"];
  btnClass?: string;
  containerClass?: string;
  menuClass?: string;
  hideWhenOpen?: boolean;
  buttonType?: ButtonTypes;
  group?: IGroup;
  disabled?: boolean;
  slim?: boolean;
}

export default function AddNewFabBtn({
  standalone,
  menuYDir,
  menuXDir,
  btnClass = "",
  containerClass = "",
  menuClass = "",
  hideWhenOpen = true,
  buttonType,
  group,
  disabled,
  slim = false,
}: IAddNewFabBtnProps) {
  const { menuIsOpen, openMenu, closeMenu } = useMenuControllerState();
  const { btnClassName } = useAddNewFabBtnState({ standalone, btnClass });
  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: group?.id,
  });
  const { openNewTaskModalAction, createProject } = useMenuState({ closeMenu });

  const role = useSelector(
    (state: ClarityStore) => state.client.roleType,
    shallowEqual
  );

  const handleNewNoteClick = async () => {
    const base = store.getState().workspace;
    axiosInstance
      .post("/api/note", {
        baseId: base.id,
        groupId: group?.id,
      })
      .then(({ data }) => {
        store.dispatch({
          type: ADD_NEW_NOTE,
          note: data,
        });
        locationSubject.next(`/${base.slug}/docs/${data.id}`);
      });
  };

  if (role === UserRole.GUEST) return <></>;

  return (
    <>
      <FabBtn
        hideWhenOpen={hideWhenOpen}
        open={openMenu}
        close={closeMenu}
        isOpen={menuIsOpen}
        icon={<ChevronDown />}
        containerClass={containerClass}
        btnClass={btnClassName}
        menuYDir={menuYDir}
        menuXDir={menuXDir}
        menuClass={menuClass}
        label="Create"
        disabled={disabled}
        buttonType={buttonType}
      >
        <MenuItem
          title="Doc"
          slim={slim}
          disabled={!canEdit}
          icon={<SnippetsOutlined />}
          onClick={handleNewNoteClick}
        />
        <MenuItem
          title="Task"
          slim={slim}
          disabled={!canEdit}
          icon={<CheckCircleOutlined />}
          onClick={openNewTaskModalAction}
        />
        <MenuItem
          title="Project"
          slim={slim}
          disabled={!canEdit}
          icon={<CheckSquareOutlined />}
          onClick={createProject}
        />
      </FabBtn>
    </>
  );
}

function useAddNewFabBtnState({
  standalone,
  btnClass,
}: {
  standalone?: boolean;
  btnClass: string;
}) {
  const btnClassName = useOptionalClassName({
    baseStyle: btnClass,
    pairs: [
      {
        extraStyle: styles.standalone,
        withExtra: standalone,
      },
    ],
  });

  return {
    btnClassName,
  };
}

function useMenuState({ closeMenu }: { closeMenu: () => void }) {
  const { base } = useSelector(
    (state: any) => ({
      base: state.workspace,
    }),
    shallowEqual
  );
  const paneId = usePaneId();

  const userRole = useSelector(
    (state: ClarityStore) => state.client.roleType,
    shallowEqual
  );

  const createPage = (event: any) => {
    createAndSetNewPage(event, base.id);
    closeMenu();
  };

  const createProject = (event: any) => {
    createAndSetNewProject(event, base.id, paneId);
    closeMenu();
  };

  const createInitiative = (event: any) => {
    createAndSetNewInitiative(event, base.id, paneId);
    closeMenu();
  };

  const createView = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setNewView(event, base.id);
    closeMenu();
  };

  const openNewTaskModalAction = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    openNewTaskModal({
      type: INewTaskCreationModes.new,
      presetData: {},
    });
    // showNewTaskModal();
    closeMenu();
  };

  const { showInvitationsModal } = useInvitationsModalSetter();

  const openInvitationsModal = () => {
    showInvitationsModal();
    closeMenu();
  };

  return {
    createPage,
    createProject,
    createView,
    createInitiative,
    openNewTaskModalAction,
    openInvitationsModal,
    userRole,
  };
}
