import store from "store/storeExporter";
import { ContainerTypes } from "./types";

export const getCorrectLink = (
  base: any,
  container: any,
  viewType: "Entity" | "AllView" = "Entity",
  allViewLink?: string
) => {
  if (
    viewType === "AllView" &&
    allViewLink &&
    container.type !== ContainerTypes.CUSTOM_VIEW
  ) {
    return `/${base.slug}${allViewLink}`;
  }
  if (container.type === ContainerTypes.DOCUMENT) {
    return `/${base.slug}/tags/${container.id}`;
  }
  if (
    container.type === ContainerTypes.PROJECT ||
    container.type === ContainerTypes.TASK ||
    container.type === ContainerTypes.WORK_ACTIVITY ||
    container.type === ContainerTypes.WORK ||
    container.type === "Initiative" ||
    (container.workType && container.type !== ContainerTypes.TEMPLATE)
  ) {
    if (container.workType === "Task") {
      let work = store.getState().work.dict[container.id];
      if (!work) {
        return `/${base.slug}/work/${container.id}`;
      }
      const group = store.getState().groups.dict[work.groupId];
      if (group) {
        return `/${base.slug}/work/${group.slug}-${container.projectId}`;
      }
    }
    return `/${base.slug}/project/${container.projectId}`;
  }

  if (container.type === ContainerTypes.NOTE) {
    return `/${base.slug}/docs/${container.id}`;
  }
  if (container.type === ContainerTypes.CUSTOM_VIEW) {
    return `/${base.slug}/view/${container.id}`;
  }
  if (container.type === ContainerTypes.TEMPLATE) {
    return `/${base.slug}/template/${container.id}`;
  }
  if (container.type === ContainerTypes.SNIPPET) {
    return `/${base.slug}/snippet/${container.id}`;
  }
  return `/${base.slug}/tags/${container.id}`;
};

export function isImageUrl(url: string) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

export function isPdfUrl(url: string) {
  return url.match(/\.(pdf)$/) != null;
}
