import React from "react";
import styles from "./loadingSpinner/loadingSpinner.module.scss";

interface ILoadingSpinnerProps {
  style?: any;
}

export default function LoadingSpinner(props: ILoadingSpinnerProps) {
  return <div className={styles.loadingSpinner} style={props.style}></div>;
}
