import React, { useState, useEffect, useCallback } from "react";
import DiscussionModal from "clarity-ui/DiscussionModal";
import Button, { ButtonTypes, IButtonProps } from "components/Button";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import * as actionTypes from "store/actions";
import styles from "./filtersModal/filtersModal.module.scss";
import { Select, Dropdown, Menu, Tag } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import {
  CommandPaletteContext,
  CommandPaletteFilterContextTypes,
  CommandPaletteDateFilterContextTypes,
  IFilterKey,
  IFilterState,
  IUserObj,
  ContainerTypes,
  priorities,
  ViewAsTypes,
  IProjectObj,
  RewardFilter,
} from "utilities/types";
import { ChunkDestination } from "utilities/stateTypes";
import { partial, find, mergeWith, some, isEqual, pick } from "lodash";
import { stripHtml } from "utilities/stringUtilities";
import moment from "moment";
import {
  updateFilterAction,
  getDateFilterPropertyNames,
  usePresets,
  generateNewChunk,
  useViewAs,
} from "store/reducers/filterReducer";
import { WorkTypes } from "utilities/types";
import { getMonday } from "utilities/dateTime";
import { useOptionalClassName, useShallowSelector } from "utilities/hooks";
import { useBase } from "store/reducers/workspaceReducer";
import { useCycles } from "store/reducers/workReducer";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import { getHtml } from "editor/utils/blockValueHelpers";
import { getNameFromContainer } from "modules/containerHelpers";

const UNNASIGNED_TEXT = "is unnasigned";

interface IFiltersModalProps {}

function FiltersModal(props: IFiltersModalProps) {
  const { categories, typeSelectActive } = useModalState();
  const { hideModal } = useModalActions();

  return (
    <DiscussionModal
      title="Filters"
      size="small"
      hideModal={hideModal}
      topRowChildren={<AddFilterBtn key="addFilterBtn" />}
    >
      <div className={styles.container}>
        <TypeSelect />
        {categories.map((category, idx) => (
          <ConditionalSelect
            withAnd={idx > 0 || typeSelectActive}
            key={category.title}
            title={category.title}
            {...pick(category, ["choices", "locked", "notApplied"])}
          />
        ))}
      </div>
    </DiscussionModal>
  );
}

function useModalActions() {
  const dispatch = useDispatch();

  const hideModal = useCallback(
    () =>
      dispatch({
        type: actionTypes.SET_FILTERS_MODAL,
        isOpen: false,
      }),
    []
  );

  return {
    hideModal,
  };
}

function useModalState() {
  const categories = useCategories({}).filter((category) => {
    return Boolean(category.containers.length);
  });

  const storeState = useSelector(
    (state: ClarityStore) => ({
      work: state.work,
      user: state.user,
      modal: state.client.filtersModal,
    }),
    shallowEqual
  );

  const typeSelectActive = useTypeSelectActive();

  return {
    categories,
    ...storeState,
    typeSelectActive,
  };
}

function useTypeSelectActive() {
  const value = useTypeSelectValue();
  return Boolean(value);
}

function useTypeFixed() {
  const paneId = useSelector(
    (state: ClarityStore) => state.client.filtersModal.paneId,
    shallowEqual
  );
  const { presets } = usePresets(paneId);

  return Boolean(presets.filters?.type?.length);
}

function useCategories({
  excludeActiveChoices,
}: {
  excludeActiveChoices?: boolean;
}) {
  const paneId = useSelector(
    (state: ClarityStore) => state.client.filtersModal.paneId,
    shallowEqual
  );
  const choicesByTitle = useChoices();
  const getContainers = useContainersGetter();

  const { presets } = usePresets(paneId);
  const workConfig = useShallowSelector(
    (store) => store.filter.viewConfigDict[paneId]
  );
  const filters = useStore((store: ClarityStore) => {
    const filters = store.filter.currentDict[paneId].filters;
    return filters;
  });
  const { viewAs } = useViewAs();

  type ChoicesKey = keyof typeof choicesByTitle;

  let categories = Object.keys(choicesByTitle).map((key: string) => {
    const title = key;
    const containers = getContainers(choicesByTitle[key as ChoicesKey]);
    let choices: ICondSelectChoice[] = choicesByTitle[key as ChoicesKey];
    const paletteContexts = choices.map((choice) => choice.paletteCtx);
    const newChunk = generateNewChunk();
    let locked = some(
      paletteContexts,
      (paletteCtx) =>
        !isEqual(
          newChunk[ctxTypeToFilterKey[paletteCtx]],
          presets.filters[ctxTypeToFilterKey[paletteCtx]]
        )
    );
    if (workConfig.customView) locked = false;

    const notApplied = Boolean(
      viewAs === ViewAsTypes.board &&
        title === "Status" &&
        (presets.filters?.statuses.length ||
          filters.statuses.length ||
          presets.filters?.excludedStatuses?.length ||
          filters.excludedStatuses?.length)
    );

    if (excludeActiveChoices) {
      choices = choices.filter(({ title }) =>
        Boolean(!locked && !find(containers, { title })?.tags.length)
      );
    }

    return {
      title,
      choices,
      containers,
      locked,
      notApplied,
    };
  });

  if (excludeActiveChoices) {
    categories = categories.filter((category) =>
      Boolean(category.choices.length)
    );
  }

  return categories;
}

function useChoices(): Record<string, ICondSelectChoice[]> {
  const ctxTypes = CommandPaletteContext;
  const paneId = useSelector(
    (state: ClarityStore) => state.client.filtersModal.paneId,
    shallowEqual
  );

  const workViewContext = useShallowSelector(
    (store) => store.filter.viewConfigDict[paneId]
  );

  const categories: any = {
    Assignee: [
      {
        menuText: "is...",
        title: "is any of these",
        paletteCtx: ctxTypes.FILTER_ASSIGNEE,
      },
      {
        menuText: "is unnassigned",
        title: UNNASIGNED_TEXT,
        paletteCtx: ctxTypes.FILTER_ASSIGNEE,
        action: () => {
          const currentNotAssignees = [
            ...store.getState().filter.currentDict[paneId].filters
              .excludedAssignees,
          ];
          currentNotAssignees.push("*");
          updateFilterAction({
            paneId: paneId,
            delta: {
              excludedAssignees: currentNotAssignees,
            },
          });
        },
      },
      {
        menuText: "is not...",
        title: "is not any of these",
        exclude: ["*"],
        paletteCtx: ctxTypes.FILTER_ASSIGNEE_NOT,
      },
    ],
    "Created Date": [
      {
        menuText: "is this week",
        title: "this week",
        category: "createdDate",
        paletteCtx: ctxTypes.FILTER_CREATED_EXACTLY_DATE,
        action: () => {
          const monday = getMonday(new Date());
          monday.setHours(0, 0, 0, 0);

          const newDate = new Date(monday);
          const lastday = new Date(
            newDate.setDate(newDate.getDate() - newDate.getDay() + 7)
          );
          lastday.setHours(0, 0, 0, 0);
          const currentCreated = store.getState().filter.currentDict[paneId]
            .filters
            ? store.getState().filter.currentDict[paneId].filters.createdDate
            : getEmptyDateState();
          updateFilterAction({
            paneId: paneId,
            delta: {
              createdDate: {
                ...currentCreated,
                isWeekView: true,
              },
            },
          });
        },
      },
      {
        menuText: "is before...",
        title: "is before",
        paletteCtx: ctxTypes.FILTER_CREATED_BEFORE_DATE,
      },
      {
        menuText: "is after...",
        title: "is after",
        paletteCtx: ctxTypes.FILTER_CREATED_AFTER_DATE,
      },
      {
        menuText: "is exactly...",
        title: "is exactly",
        paletteCtx: ctxTypes.FILTER_CREATED_EXACTLY_DATE,
      },
      {
        menuText: "is not...",
        title: "is not",
        paletteCtx: ctxTypes.FILTER_CREATED_NOT_DATE,
      },
    ],
    "Closed Date": [
      {
        menuText: "is this week",
        title: "this week",
        category: "closedDate",
        paletteCtx: ctxTypes.FILTER_CLOSED_EXACTLY_DATE,
        action: () => {
          const monday = getMonday(new Date());
          monday.setHours(0, 0, 0, 0);

          const newDate = new Date(monday);
          const lastday = new Date(
            newDate.setDate(newDate.getDate() - newDate.getDay() + 7)
          );
          lastday.setHours(0, 0, 0, 0);

          const currentClosed = store.getState().filter.currentDict[paneId]
            .filters
            ? store.getState().filter.currentDict[paneId].filters.closedDate
            : getEmptyDateState();
          updateFilterAction({
            paneId: paneId,
            delta: {
              closedDate: {
                ...currentClosed,
                isWeekView: true,
              },
            },
          });
        },
      },
      {
        menuText: "is before...",
        title: "is before",
        paletteCtx: ctxTypes.FILTER_CLOSED_BEFORE_DATE,
      },
      {
        menuText: "is after...",
        title: "is after",
        paletteCtx: ctxTypes.FILTER_CLOSED_AFTER_DATE,
      },
      {
        menuText: "is exactly...",
        title: "is exactly",
        paletteCtx: ctxTypes.FILTER_CLOSED_EXACTLY_DATE,
      },
      {
        menuText: "is not...",
        title: "is not",
        paletteCtx: ctxTypes.FILTER_CLOSED_NOT_DATE,
      },
    ],
    "Due Date": [
      {
        menuText: "is this week",
        title: "this week",
        category: "dueDate",
        paletteCtx: ctxTypes.FILTER_DUE_EXACTLY_DATE,
        action: () => {
          const monday = getMonday(new Date());
          monday.setHours(0, 0, 0, 0);

          const newDate = new Date(monday);
          const lastday = new Date(
            newDate.setDate(newDate.getDate() - newDate.getDay() + 7)
          );
          lastday.setHours(0, 0, 0, 0);
          const currentDue = store.getState().filter.currentDict[paneId].filters
            ? store.getState().filter.currentDict[paneId].filters.dueDate
            : getEmptyDateState();
          updateFilterAction({
            paneId: paneId,
            delta: {
              dueDate: {
                ...currentDue,
                isWeekView: true,
              },
            },
          });
        },
      },
      {
        menuText: "is overdue",
        title: "is overdue",
        category: "dueDate",
        paletteCtx: ctxTypes.FILTER_DUE_EXACTLY_DATE,
        action: () => {
          const currentDue = store.getState().filter.currentDict[paneId].filters
            ? store.getState().filter.currentDict[paneId].filters.dueDate
            : getEmptyDateState();

          updateFilterAction({
            paneId: paneId,
            delta: {
              dueDate: {
                ...currentDue,
                isOverdue: true,
              },
            },
          });
        },
      },
      {
        menuText: "is before...",
        title: "is before",
        paletteCtx: ctxTypes.FILTER_DUE_BEFORE_DATE,
      },
      {
        menuText: "is after...",
        title: "is after",
        paletteCtx: ctxTypes.FILTER_DUE_AFTER_DATE,
      },
      {
        menuText: "is exactly...",
        title: "is exactly",
        paletteCtx: ctxTypes.FILTER_DUE_EXACTLY_DATE,
      },
      {
        menuText: "is not...",
        title: "is not",
        paletteCtx: ctxTypes.FILTER_DUE_NOT_DATE,
      },
    ],
    Priority: [
      {
        menuText: "is...",
        title: "is any of these",
        paletteCtx: ctxTypes.FILTER_PRIORITY,
      },
      {
        menuText: "is not...",
        title: "is not any of these",
        paletteCtx: ctxTypes.FILTER_PRIORITY_NOT,
      },
    ],
    Parent: [
      {
        menuText: "is...",
        title: "is any of these",
        paletteCtx: ctxTypes.FILTER_PARENT,
      },
      {
        menuText: "is not...",
        title: "is not any of these",
        paletteCtx: ctxTypes.FILTER_PARENT_NOT,
      },
    ],
    Cycle: [
      {
        menuText: "is...",
        title: "is any of these",
        paletteCtx: ctxTypes.FILTER_CYCLE,
      },
      {
        menuText: "is not...",
        title: "is not any of these",
        paletteCtx: ctxTypes.FILTER_CYCLE_NOT,
      },
    ],
    Status: [
      {
        menuText: "is...",
        title: "is any of these",
        paletteCtx: ctxTypes.FILTER_STATUS,
      },
      {
        menuText: "is not...",
        title: "is not any of these",
        paletteCtx: ctxTypes.FILTER_STATUS_NOT,
      },
    ],
    Tags: [
      {
        menuText: "is...",
        title: "is any of these",
        paletteCtx: ctxTypes.FILTER_LABEL,
      },
      {
        menuText: "is not...",
        title: "is not any of these",
        paletteCtx: ctxTypes.FILTER_LABEL_NOT,
      },
    ],
    Reward: [
      {
        menuText: "has reward",
        title: "has reward",
        paletteCtx: ctxTypes.HAS_REWARD,
        action: () => {
          updateFilterAction({
            paneId: paneId,
            delta: {
              hasReward: RewardFilter.HAS_REWARD,
            },
          });
        },
      },
      {
        menuText: "does not have reward",
        title: "does not have reward",
        paletteCtx: ctxTypes.HAS_REWARD,
        action: () => {
          updateFilterAction({
            paneId: paneId,
            delta: {
              hasReward: RewardFilter.HAS_NOT_REWARD,
            },
          });
        },
      },
      {
        menuText: "is approved for payment",
        title: "is approved for payment",
        paletteCtx: ctxTypes.HAS_REWARD,
        action: () => {
          updateFilterAction({
            paneId: paneId,
            delta: {
              hasReward: RewardFilter.IS_APPROVED,
            },
          });
        },
      },
      {
        menuText: "is not approved for payment",
        title: "is not approved for payment",
        paletteCtx: ctxTypes.HAS_REWARD,
        action: () => {
          updateFilterAction({
            paneId: paneId,
            delta: {
              hasReward: RewardFilter.IS_NOT_APPROVED,
            },
          });
        },
      },
      {
        menuText: "is paid",
        title: "is paid",
        paletteCtx: ctxTypes.HAS_REWARD,
        action: () => {
          updateFilterAction({
            paneId: paneId,
            delta: {
              hasReward: RewardFilter.IS_PAID,
            },
          });
        },
      },
      {
        menuText: "is not paid",
        title: "is not paid",
        paletteCtx: ctxTypes.HAS_REWARD,
        action: () => {
          updateFilterAction({
            paneId: paneId,
            delta: {
              hasReward: RewardFilter.IS_NOT_PAID,
            },
          });
        },
      },
    ],
  };

  if (workViewContext.customView) {
    categories.Group = [
      {
        menuText: "is...",
        title: "is any of these",
        paletteCtx: ctxTypes.FILTER_GROUP,
      },
      {
        menuText: "is not...",
        title: "is not any of these",
        paletteCtx: ctxTypes.FILTER_GROUP_NOT,
      },
    ];
  }

  return categories;
}

interface IAddFilterBtnProps {}

function AddFilterBtn(props: IAddFilterBtnProps) {
  const { getTypeFilterSetter, typeSelectActive } = useAddFilterActions();
  const { categories, showCommandPalette, typeFixed } = useAddFilterBtnState();
  const showCycles = useShowCycles();

  return (
    <div className={styles.addFilterBtnContainer}>
      <Dropdown
        overlay={
          <Menu>
            {categories.map((category) =>
              category.title === "Cycle" && !showCycles ? (
                <React.Fragment key={category.title}></React.Fragment>
              ) : (
                <Menu.SubMenu title={category.title} key={category.title}>
                  {category.choices.map((choice) => (
                    <Menu.Item
                      key={`${category.title}${choice.title}`}
                      onClick={
                        choice.action ??
                        partial(showCommandPalette, choice.paletteCtx)
                      }
                    >
                      {choice.menuText}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )
            )}
            {!typeSelectActive && !typeFixed && (
              <Menu.SubMenu title="Type" key="type">
                <Menu.Item
                  key="tasksAndProjectsAndInitiatives"
                  onClick={getTypeFilterSetter({
                    task: true,
                    project: true,
                    initiative: true,
                  })}
                >
                  Any
                </Menu.Item>
                <Menu.Item
                  key="initiatives"
                  onClick={getTypeFilterSetter({ initiative: true })}
                >
                  Goals
                </Menu.Item>
                <Menu.Item
                  key="projects"
                  onClick={getTypeFilterSetter({ project: true })}
                >
                  Projects
                </Menu.Item>
                <Menu.Item
                  key="tasks"
                  onClick={getTypeFilterSetter({ task: true })}
                >
                  Tasks
                </Menu.Item>
              </Menu.SubMenu>
            )}
          </Menu>
        }
        trigger={["click"]}
        placement="bottomLeft"
      >
        <Button
          className={styles.addFilterBtn}
          icon={<PlusOutlined />}
          buttonType={ButtonTypes.DEFAULT}
        >
          Add filter
        </Button>
      </Dropdown>
    </div>
  );
}

function useAddFilterBtnState() {
  const categories = useCategories({ excludeActiveChoices: true });
  const showCommandPalette = useShowCommandPalette();
  const typeFixed = useTypeFixed();

  return {
    categories,
    showCommandPalette,
    typeFixed,
  };
}

function useAddFilterActions() {
  const typeSetter = useFiltersTypeSetter();
  const getTypeFilterSetter = (value: Parameters<typeof typeSetter>[0]) => () =>
    typeSetter(value);

  const typeSelectActive = useTypeSelectActive();

  return {
    getTypeFilterSetter,
    typeSelectActive,
  };
}

interface ITypeSelectProps {}

function TypeSelect(props: ITypeSelectProps) {
  const { value, typeFixed, containerClassName } = useTypeSelectState();
  const { onTypeChange, onClear } = useTypeSelectActions();
  const { Option } = Select;

  return !value ? (
    <></>
  ) : (
    <div className={containerClassName}>
      <span className={styles.typeSelectTitle}>Type</span>
      <div className={styles.typeSelectBottom}>
        <Select
          disabled={typeFixed}
          className={styles.typeSelect}
          value={value}
          onChange={onTypeChange}
        >
          <Option
            value="tasksAndProjectsAndInitiatives"
            disabled={typeFixed && "tasksAndProjectsAndInitiatives" !== value}
          >
            Any
          </Option>
          <Option
            value="initiatives"
            disabled={typeFixed && "initiatives" !== value}
          >
            Goals
          </Option>
          <Option value="projects" disabled={typeFixed && "projects" !== value}>
            Projects
          </Option>
          <Option value="tasks" disabled={typeFixed && "tasks" !== value}>
            Tasks
          </Option>
        </Select>
        {!typeFixed && (
          <ClearBtn onClick={onClear} className={styles.typeSelectClearBtn} />
        )}
      </div>
    </div>
  );
}

function useTypeSelectState() {
  const value = useTypeSelectValue();
  const typeFixed = useTypeFixed();

  const containerClassName = useOptionalClassName({
    baseStyle: styles.typeSelectContainer,
    pairs: [
      {
        extraStyle: styles.typeSelectContainerIsLocked,
        withExtra: typeFixed,
      },
    ],
  });

  return {
    value,
    typeFixed,
    containerClassName,
  };
}

function useTypeSelectValue() {
  const paneId: ChunkDestination.primary | ChunkDestination.secondary =
    useSelector(
      (state: ClarityStore) =>
        state.client.filtersModal
          ? state.client.filtersModal.paneId
          : ChunkDestination.primary,
      shallowEqual
    );
  const [value, setValue] = useState<
    "tasks" | "projects" | "initiatives" | "tasksAndProjectsAndInitiatives"
  >();

  const filtersType = useStore((store: ClarityStore) => {
    const filters = store.filter.currentDict[paneId].filters;
    const presetFilters = store.filter.presetsDict[paneId].filters;
    return [...(filters?.type ?? []), ...(presetFilters?.type ?? [])];
  });

  const filtersTypeToValue = ():
    | "tasks"
    | "projects"
    | "initiatives"
    | "tasksAndProjectsAndInitiatives"
    | undefined => {
    const tasks = filtersType?.includes(WorkTypes.TASK);
    const projects = filtersType?.includes(WorkTypes.PROJECT);
    const initiatives = filtersType?.includes(WorkTypes.INITIATIVE);

    return tasks && projects && initiatives
      ? "tasksAndProjectsAndInitiatives"
      : initiatives
      ? "initiatives"
      : projects
      ? "projects"
      : tasks
      ? "tasks"
      : undefined;
  };

  useEffect(() => setValue(filtersTypeToValue()), [filtersType]);

  return value;
}

function useTypeSelectActions() {
  const typeSetter = useFiltersTypeSetter();
  const paneId: ChunkDestination.primary | ChunkDestination.secondary =
    useSelector(
      (state: ClarityStore) =>
        state.client.filtersModal
          ? state.client.filtersModal.paneId
          : ChunkDestination.primary,
      shallowEqual
    );

  const onClear = () =>
    updateFilterAction({
      paneId,
      delta: {
        type: [],
      },
    });

  const onTypeChange = (value: string) => {
    const project = value.toLowerCase().includes("projects");
    const task = value.toLowerCase().includes("tasks");
    const initiative = value.toLowerCase().includes("initiatives");
    typeSetter({ project, task, initiative });
  };

  return {
    onTypeChange,
    onClear,
  };
}

interface IConditionalSelectProps {
  title: string;
  choices: ICondSelectChoice[];
  locked?: boolean;
  notApplied?: boolean;
  style?: React.CSSProperties;
  withAnd?: boolean;
}

interface ICondSelectChoice {
  menuText: string;
  title: string;
  paletteCtx: CommandPaletteFilterContextTypes;
  category?: "dueDate" | "createdDate" | "closedDate";
  exclude?: string[];
  action?: any;
}

function ConditionalSelect(props: IConditionalSelectProps) {
  const { title, choices, style, withAnd, locked, notApplied } = props;
  const { containers, hidden, containerClassName } = useConditionalSelectState({
    choices,
    locked,
  });

  return hidden ? (
    <></>
  ) : (
    <>
      {withAnd && <AndSeparator />}
      <div
        className={
          notApplied ? styles.conditionalSelectContainerIsNotApplied : ""
        }
      >
        <div className={containerClassName} style={style}>
          <div className={styles.conditionalSelectContainerTop}>
            <span className={styles.conditionalSelectTitle}>{title}</span>
          </div>
          <div className={styles.conditionalSelectContainerBottom}>
            {containers.map((container: ITagsContainer, idx: number) => (
              <div key={container.title}>
                {Boolean(idx) && <AndSeparator withLines />}
                <TagsContainer {...container} locked={locked} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {notApplied && (
        <span className={styles.conditionalSelectContainerNotAppliedText}>
          This filter is not applied due to the current View As setting
        </span>
      )}
    </>
  );
}

function useShowCommandPalette() {
  const paneId: ChunkDestination.primary | ChunkDestination.secondary =
    useSelector(
      (state: ClarityStore) =>
        state.client.filtersModal
          ? state.client.filtersModal.paneId
          : ChunkDestination.primary,
      shallowEqual
    );
  const dispatch = useDispatch();

  const showCommandPalette = (context: CommandPaletteContext) =>
    dispatch({
      type: actionTypes.SHOW_COMMAND_PALETTE,
      context,
      params: {
        destination: paneId,
      },
    });

  return showCommandPalette;
}

function useConditionalSelectState({
  choices,
  locked,
}: Pick<IConditionalSelectProps, "choices" | "locked">) {
  const getContainers = useContainersGetter();
  const containers = getContainers(choices);
  const hidden = containers.length === 0;

  const containerClassName = useOptionalClassName({
    baseStyle: styles.conditionalSelectContainer,
    pairs: [
      {
        extraStyle: styles.conditionalSelectContainerIsLocked,
        withExtra: locked,
      },
    ],
  });

  return {
    containers,
    hidden,
    containerClassName,
  };
}

function useContainersGetter() {
  const paneId: ChunkDestination.primary | ChunkDestination.secondary =
    useSelector(
      (state: ClarityStore) =>
        state.client.filtersModal
          ? state.client.filtersModal.paneId
          : ChunkDestination.primary,
      shallowEqual
    );
  const { members, filters } = useSelector(
    (state: ClarityStore) => ({
      members: state.members.dict,
      filters: mergeWith(
        {},
        state.filter.currentDict[paneId].filters,
        state.filter.viewConfigDict[paneId].customView
          ? {}
          : state.filter.presetsDict[paneId].filters,
        (objValue, srcValue) => {
          if (srcValue === null) {
            return objValue;
          }
        }
      ) as IFilterState,
    }),
    shallowEqual
  );

  const getTagName = useTagNamer();
  const getStatusName = useStatusNamer();
  const getPriorityName = usePriorityNamer();
  const getCycleName = useCycleNamer();
  const getGroupName = useGetGroupName();

  const getDatePropertyByFilterContextType = (
    dateFilterCtxType: CommandPaletteDateFilterContextTypes
  ): "before" | "after" | "exactly" | "not" => {
    const is = (substring: string) => dateFilterCtxType.includes(substring);

    if (is("Before")) {
      return "before";
    } else if (is("After")) {
      return "after";
    } else if (is("Exactly")) {
      return "exactly";
    } else {
      return "not";
    }
  };

  const getTagsForFilterKey = (
    filterKey: IAllFilterKeys,
    paletteCtx: CommandPaletteFilterContextTypes,
    exclude?: string[]
  ): ITag[] => {
    if (["assignees", "excludedAssignees"].includes(filterKey)) {
      const memberIds = filters
        ? filters[filterKey as "assignees" | "excludedAssignees"]
        : [];

      const membersToReturn: any = memberIds
        .map((id: string) => members[id])
        .filter(Boolean)
        .map((member: IUserObj) => {
          return {
            name: usernameSplitter(member.name || `@${member.username}`),
          };
        });
      if (memberIds.includes("*") && !exclude?.includes("*")) {
        membersToReturn.push({
          name: "Anyone",
        });
      }
      return membersToReturn;
    } else if (["labels", "excludedLabels"].includes(filterKey)) {
      const complexIds = filters
        ? filters[filterKey as "labels" | "excludedLabels"]
        : [];

      return complexIds.map((complexId: string) => ({
        name: getTagName(complexId),
      }));
    } else if (["statuses", "excludedStatuses"].includes(filterKey)) {
      const statusIds = filters
        ? filters[filterKey as "statuses" | "excludedStatuses"] ?? []
        : [];

      return statusIds.map((statusId: string) => ({
        name: getStatusName(statusId),
      }));
    } else if (["priorities", "excludedPriorities"].includes(filterKey)) {
      const priorityIds = filters
        ? filters[filterKey as "priorities" | "excludedPriorities"]
        : [];

      return priorityIds.map((priorityId: number) => ({
        name: getPriorityName(priorityId),
      }));
    } else if (["cycles", "excludedCycles"].includes(filterKey)) {
      const cycleIds = filters
        ? filters[filterKey as "cycles" | "excludedCycles"]
        : [];

      return cycleIds.map((cycleId: string) => ({
        name: getCycleName(cycleId),
      }));
    } else if (["parents", "excludedParents"].includes(filterKey)) {
      const parentIds = filters
        ? filters[filterKey as "parents" | "excludedParents"]
        : [];
      const workDict = store.getState().work.dict;
      return parentIds.map((parentId: string) => ({
        name: getNameFromContainer(workDict[parentId], ContainerTypes.PROJECT),
      }));
    } else if (["createdDate"].includes(filterKey)) {
      const property = getDatePropertyByFilterContextType(
        paletteCtx as CommandPaletteDateFilterContextTypes
      );

      const date = filters
        ? filters[filterKey as "createdDate"][property]
        : null;

      const name = date ? formatDate(date) : "";

      return name ? [{ name }] : [];
    } else if (["dueDate"].includes(filterKey)) {
      const property = getDatePropertyByFilterContextType(
        paletteCtx as CommandPaletteDateFilterContextTypes
      );

      const date = filters ? filters[filterKey as "dueDate"][property] : null;

      const name = date ? formatDate(date) : "";

      return name ? [{ name }] : [];
    } else if (["closedDate"].includes(filterKey)) {
      const property = getDatePropertyByFilterContextType(
        paletteCtx as CommandPaletteDateFilterContextTypes
      );

      const date = filters
        ? filters[filterKey as "closedDate"][property]
        : null;

      const name = date ? formatDate(date) : "";

      return name ? [{ name }] : [];
    } else if (["groups", "excludedGroups"].includes(filterKey)) {
      const groupIds = filters
        ? filters[filterKey as "groups" | "excludedGroups"]
        : [];
      return groupIds
        ? groupIds.map((cycleId: string) => ({
            name: getGroupName(cycleId),
          }))
        : [];
    } else {
      return [];
    }
  };

  return (choices: IConditionalSelectProps["choices"]) =>
    choices
      .map((choice: ICondSelectChoice) => {
        const filterKey = ctxTypeToFilterKey[choice.paletteCtx];
        const { title, paletteCtx } = choice;

        let tags: any = [];
        if (filters && title === "this week" && choice.category) {
          if (filters[choice.category].isWeekView) tags = [true];
        } else if (
          filters &&
          title === "is overdue" &&
          choice.category &&
          choice.category === "dueDate"
        ) {
          if (filters[choice.category].isOverdue) tags = [true];
        } else if (filters && title === UNNASIGNED_TEXT) {
          if (filters?.excludedAssignees?.includes("*")) tags = [true];
        } else if (
          filters &&
          title === "has reward" &&
          filters.hasReward === RewardFilter.HAS_REWARD
        ) {
          tags = [true];
        } else if (
          filters &&
          title === "does not have reward" &&
          filters.hasReward === RewardFilter.HAS_NOT_REWARD
        ) {
          tags = [true];
        } else if (
          filters &&
          title === "is approved for payment" &&
          filters.hasReward === RewardFilter.IS_APPROVED
        ) {
          tags = [true];
        } else if (
          filters &&
          title === "is not approved for payment" &&
          filters.hasReward === RewardFilter.IS_NOT_APPROVED
        ) {
          tags = [true];
        } else if (
          filters &&
          title === "is paid" &&
          filters.hasReward === RewardFilter.IS_PAID
        ) {
          tags = [true];
        } else if (
          filters &&
          title === "is not paid" &&
          filters.hasReward === RewardFilter.IS_NOT_PAID
        ) {
          tags = [true];
        } else {
          tags = getTagsForFilterKey(
            filterKey as IAllFilterKeys,
            choice.paletteCtx,
            choice.exclude
          );
        }

        const onClear = (event: any) => {
          event.stopPropagation();
          if (title === "this week") {
            if (choice.category) {
              const currentKey = filters
                ? filters[choice.category]
                : getEmptyDateState();
              updateFilterAction({
                paneId,
                delta: {
                  [choice.category]: {
                    ...currentKey,
                    isWeekView: false,
                  },
                },
              });
            }
          } else if (title === UNNASIGNED_TEXT) {
            const currentNotAssignees = [
              ...store.getState().filter.currentDict[paneId].filters
                .excludedAssignees,
            ];
            const index = currentNotAssignees.indexOf("*");

            if (index > -1) {
              currentNotAssignees.splice(index, 1);
              updateFilterAction({
                paneId: paneId,
                delta: {
                  excludedAssignees: currentNotAssignees,
                },
              });
            }
          } else if (title === "is overdue") {
            if (choice.category) {
              const currentKey = filters
                ? filters[choice.category]
                : getEmptyDateState();
              updateFilterAction({
                paneId,
                delta: {
                  [choice.category]: {
                    ...currentKey,
                    isOverdue: false,
                  },
                },
              });
            }
          } else if (
            title === "has reward" ||
            title === "does not have reward" ||
            title === "is approved for payment" ||
            title === "is not approved for payment" ||
            title === "is paid" ||
            title === "is not paid"
          ) {
            updateFilterAction({
              paneId,
              delta: {
                hasReward: undefined,
              },
            });
          } else {
            if (Array.isArray(filters && filters[filterKey])) {
              updateFilterAction({
                paneId,
                delta: {
                  [filterKey]: [],
                },
              });
            } else {
              const { dateFilterName, typeName } = getDateFilterPropertyNames(
                choice.paletteCtx as CommandPaletteDateFilterContextTypes
              );

              const date = filters
                ? filters[dateFilterName]
                : {
                    after: null,
                    before: null,
                    exactly: null,
                    not: null,
                    isWeekView: null,
                  };

              updateFilterAction({
                paneId,
                delta: {
                  [dateFilterName]: {
                    ...date,
                    [typeName]: null,
                  },
                },
              });
            }
          }
        };

        return {
          title,
          tags,
          paletteCtx,
          onClear,
        };
      })
      .filter(({ title, tags }: ITagsContainer) => tags.length);
}

interface ITagsContainer {
  title: string;
  tags: ITag[];
  paletteCtx: ICondSelectChoice["paletteCtx"];
  category?: "dueDate" | "createdDate";
  onClear: (event: any) => void;
  locked?: boolean;
}

interface ITag {
  name: string;
}

function TagsContainer(props: ITagsContainer) {
  const { title, tags, onClear, paletteCtx, locked } = props;
  const { showCommandPalette } = useTagsContainerActions();

  if (
    title === "this week" ||
    title === "is overdue" ||
    title === UNNASIGNED_TEXT ||
    title === "has reward" ||
    title === "does not have reward" ||
    title === "is approved for payment" ||
    title === "is not approved for payment" ||
    title === "is paid" ||
    title === "is not paid"
  )
    return (
      <div
        className={styles.tagsContainer}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span className={styles.tagsContainerTitle}>{title}</span>
        <span style={{ marginLeft: "auto" }}>
          <ClearBtn
            onClick={onClear}
            disabled={locked}
            className={styles.tagsContainerClearBtn}
          />
        </span>
      </div>
    );

  return (
    <div className={styles.tagsContainer}>
      <span className={styles.tagsContainerTitle}>{title}</span>
      <div
        title={!locked ? undefined : "Preset filters may not be edited"}
        className={styles.tagsContainerBottom}
        onClick={locked ? undefined : partial(showCommandPalette, paletteCtx)}
      >
        <div className={styles.tagsContainerBody}>
          {!tags.length && (
            <span className={styles.tagsContainerBodyNoneMessage}>
              None selected
            </span>
          )}
          {tags.map(({ name }: { name: string }) => (
            <Tag key={name}>{name}</Tag>
          ))}
        </div>
        <ClearBtn
          disabled={locked}
          onClick={onClear}
          className={styles.tagsContainerClearBtn}
        />
      </div>
    </div>
  );
}

function useTagsContainerActions() {
  const showCommandPalette = useShowCommandPalette();

  return {
    showCommandPalette,
  };
}

interface IAndSeparatorProps {
  withLines?: boolean;
}

function AndSeparator(props: IAndSeparatorProps) {
  const { withLines } = props;

  return (
    <div className={styles.andSeparatorContainer}>
      {withLines && (
        <div
          className={`${styles.andSeparatorLine} ${styles.andSeparatorLineLeft}`}
        />
      )}
      <span className={styles.andSeparatorText}>AND</span>
      {withLines && (
        <div
          className={`${styles.andSeparatorLine} ${styles.andSeparatorLineRight}`}
        />
      )}
    </div>
  );
}

interface IClearBtnProps {
  className: IButtonProps["className"];
  onClick: IButtonProps["onClick"];
  disabled?: boolean;
}

function ClearBtn(props: IClearBtnProps) {
  const { onClick, className, disabled } = props;

  return (
    <Button
      onClick={onClick}
      className={className}
      icon={<CloseOutlined />}
      buttonType={ButtonTypes.LINK}
      disabled={disabled}
    />
  );
}

function useTagNamer() {
  const { documentsObj, workDict } = useSelector((state: ClarityStore) => {
    const documentsObj = state.pages.dict;
    const workDict = state.work.dict;
    return {
      documentsObj,
      workDict,
    };
  }, shallowEqual);

  const getTagName = (complexId: string) => {
    const tagType = complexId.includes(ContainerTypes.WORK)
      ? ContainerTypes.WORK
      : ContainerTypes.DOCUMENT;
    const isWork = tagType === ContainerTypes.WORK;
    const id = complexId.slice(tagType.length, complexId.length);
    const resource = isWork ? workDict[id] : documentsObj[id];
    return tagType === ContainerTypes.WORK
      ? `#${(resource as IProjectObj).projectId} • ${stripHtml(
          getHtml(workDict[id].nameValue)
        )}`
      : stripHtml(getHtml(documentsObj[id].nameValue));
  };

  return getTagName;
}

function useStatusNamer() {
  const { statusesDict } = useSelector((state: ClarityStore) => {
    const statusesDict = state.work.statuses.dict;
    return {
      statusesDict,
    };
  }, shallowEqual);

  const getStatusById = (id: string) => statusesDict[id];
  const getStatusName = (id: string) =>
    getStatusById(id) ? getStatusById(id).name : "";

  return getStatusName;
}

function usePriorityNamer() {
  const getPriorityById = (id: number) => priorities[id - 1];

  return getPriorityById;
}

function useCycleNamer() {
  const cycles = useCycles();
  const getCycleById = (id: string) => cycles[id];

  return (id: string) => getCycleById(id).name;
}

const useGetGroupName = () => {
  const groupsDict = useShallowSelector((store) => store.groups.dict);
  return (id: string) => (groupsDict[id] ? groupsDict[id].name : "");
};

function formatDate(date: any) {
  return moment.utc(date).format("MMM D");
}

export function useFiltersTypeSetter() {
  const paneId: ChunkDestination.primary | ChunkDestination.secondary =
    useSelector(
      (state: ClarityStore) =>
        state.client.filtersModal
          ? state.client.filtersModal.paneId
          : ChunkDestination.primary,
      shallowEqual
    );

  return ({
    task,
    project,
    initiative,
  }: {
    task?: boolean;
    project?: boolean;
    initiative?: boolean;
  }) =>
    updateFilterAction({
      paneId,
      delta: {
        type: [
          ...(project ? [WorkTypes.PROJECT] : []),
          ...(task ? [WorkTypes.TASK] : []),
          ...(initiative ? [WorkTypes.INITIATIVE] : []),
        ],
      },
    });
}

const getEmptyDateState = () => {
  return {
    before: null,
    after: null,
    exactly: null,
    not: null,
    isWeekView: null,
    isOverdue: null,
  };
};

function useStore<TSelected = unknown>(
  selector: (store: ClarityStore) => TSelected
): TSelected {
  return useSelector(selector, shallowEqual);
}

function useShowCycles() {
  const { base } = useBase();
  return base.showCycles;
}

type IDateFilterKey = "dueDate" | "createdDate" | "closedDate";
type IAllFilterKeys = IFilterKey | IDateFilterKey;

const ctxTypes = CommandPaletteContext;

const ctxTypeToFilterKey: Record<
  CommandPaletteFilterContextTypes,
  IAllFilterKeys
> = {
  [ctxTypes.FILTER_ASSIGNEE]: "assignees",
  [ctxTypes.FILTER_ASSIGNEE_NOT]: "excludedAssignees",
  [ctxTypes.FILTER_LABEL]: "labels",
  [ctxTypes.FILTER_LABEL_NOT]: "excludedLabels",
  [ctxTypes.FILTER_STATUS]: "statuses",
  [ctxTypes.FILTER_STATUS_NOT]: "excludedStatuses",
  [ctxTypes.FILTER_PRIORITY]: "priorities",
  [ctxTypes.FILTER_PRIORITY_NOT]: "excludedPriorities",
  [ctxTypes.FILTER_PARENT]: "parents",
  [ctxTypes.FILTER_PARENT_NOT]: "excludedParents",
  [ctxTypes.FILTER_CYCLE]: "cycles",
  [ctxTypes.FILTER_CYCLE_NOT]: "excludedCycles",
  [ctxTypes.FILTER_DUE_BEFORE_DATE]: "dueDate",
  [ctxTypes.FILTER_DUE_AFTER_DATE]: "dueDate",
  [ctxTypes.FILTER_DUE_EXACTLY_DATE]: "dueDate",
  [ctxTypes.FILTER_DUE_NOT_DATE]: "dueDate",
  [ctxTypes.FILTER_CREATED_BEFORE_DATE]: "createdDate",
  [ctxTypes.FILTER_CREATED_AFTER_DATE]: "createdDate",
  [ctxTypes.FILTER_CREATED_EXACTLY_DATE]: "createdDate",
  [ctxTypes.FILTER_CREATED_NOT_DATE]: "createdDate",
  [ctxTypes.FILTER_CLOSED_BEFORE_DATE]: "closedDate",
  [ctxTypes.FILTER_CLOSED_AFTER_DATE]: "closedDate",
  [ctxTypes.FILTER_CLOSED_EXACTLY_DATE]: "closedDate",
  [ctxTypes.FILTER_CLOSED_NOT_DATE]: "closedDate",
  [ctxTypes.FILTER_GROUP]: "groups",
  [ctxTypes.FILTER_GROUP_NOT]: "excludedGroups",
  [ctxTypes.HAS_REWARD]: "hasReward",
};

export default FiltersModal;
