import React from "react";
import styles from "./loginWithEmailButton/styles.module.scss";
import { useOptionalClassName } from "utilities/hooks";
import MailOutlined from "icons/Components/MailOutlined";

export const LoginWithEmailButton: React.FC<{
  loading?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}> = ({ loading, onClick, style }) => {
  const iconClassName = useOptionalClassName({
    baseStyle: styles.buttonIcon,
    pairs: [
      {
        extraStyle: styles.buttonIconDisabled,
        withExtra: loading,
      },
    ],
  });

  return (
    <>
      <button
        className={styles.button}
        style={style}
        disabled={loading}
        onClick={onClick}
      >
        <MailOutlined className={iconClassName} />
        <span className={styles.textButtonLabel}>Continue with email</span>
      </button>
    </>
  );
};
