import store from "store/storeExporter";
import { ChunkDestination } from "./stateTypes";

export const getCorrectGroupId = (
  paneId: ChunkDestination,
  groupId?: string
) => {
  if (groupId) {
    return groupId;
  }
  const contextGroupId =
    store.getState().client.paneGroupContext[paneId]?.groupId;
  if (contextGroupId) {
    return contextGroupId;
  }
  const userGroup = store.getState().groups.userGroups;

  const firstUserGroup = userGroup.length > 0 ? userGroup[0] : "";

  if (firstUserGroup !== "") {
    return firstUserGroup;
  }
};

export const getPaneGroupContext = (paneId?: ChunkDestination) => {
  if (!paneId) {
    return;
  }
  const contextGroupId =
    store.getState().client.paneGroupContext[paneId]?.groupId;
  if (contextGroupId) {
    return contextGroupId;
  }
};
