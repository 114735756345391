import { groupApi } from "clientApi/groupsApi";
import roleApi from "clientApi/rolesApi";
import { web3Api } from "clientApi/web3Api";
import { getGroupsFromEntityId } from "modules/containerHelpers";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { usePaneId } from "store/reducers/filterReducer";
import { ClarityStore } from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import {
  Abilities,
  BaseType,
  ContainerTypes,
  ContainerTypeToPrimitive,
  IBlockContext,
  PermissionType,
  UserRole,
} from "utilities/types";

export const useOnClickOutside = (
  ref: React.MutableRefObject<any>,
  handler: (event: any) => any,
  options?: {
    skipFirst?: boolean;
    allowClickOn?: string[];
  }
) => {
  const firstLoad = useRef(true);

  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      if (options?.allowClickOn) {
        for (const allowedClass of options?.allowClickOn) {
          if (event.target.closest(`.${allowedClass}`)) return;
        }
      }

      if (options?.skipFirst) {
        if (firstLoad.current) {
          firstLoad.current = false;
          return;
        }
      }
      handler(event);
    };
    document.body.addEventListener("click", listener, { capture: true });
    return () => {
      document.body.removeEventListener("click", listener, { capture: true });
    };
  }, [ref, handler]);
};

export const useOnMouseOutside = (
  ref: React.MutableRefObject<any>,
  handler: (event: any) => any,
  options?: {
    skipFirst?: boolean;
    allowClickOn?: string[];
  }
) => {
  const firstLoad = useRef(true);

  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      if (options?.allowClickOn) {
        for (const allowedClass of options?.allowClickOn) {
          if (event.target.closest(`.${allowedClass}`)) return;
        }
      }

      if (options?.skipFirst) {
        if (firstLoad.current) {
          firstLoad.current = false;
          return;
        }
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener, { capture: true });
    return () => {
      document.removeEventListener("mousedown", listener, { capture: true });
    };
  }, [ref, handler]);
};

export enum DeviceType {
  mobile = "mobile",
  desktop = "desktop",
  tablet = "tablet",
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return DeviceType.tablet;
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return DeviceType.mobile;
  }
  return DeviceType.desktop;
};

export const useDeviceIdentifier = () => {
  const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.desktop);
  useLayoutEffect(() => {
    setDeviceType(getDeviceType());
  }, []);
  return deviceType;
};

export function useWhyDidYouUpdate(name: any, props: any) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<any>({});
  useEffect(() => {
    if (previousProps && previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj: any = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      } else {
        console.log("[why-did-you-update-nothing-changed]", name);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

export const useRelativeExpirationDate = (expirationDate: Date | null) => {
  const diffString = useMemo(() => {
    if (!expirationDate) return null;
    const expiration = expirationDate
      ? moment.utc(new Date(expirationDate).setUTCHours(12, 0, 0, 0))
      : null;
    if (!expiration) return "";
    const today = moment.utc(new Date().setUTCHours(12, 0, 0, 0));
    let difference = expiration.diff(today, "days");
    let differenceString = "";
    if (difference === 0 && expirationDate)
      differenceString = `today at ${moment(new Date(expirationDate)).format(
        "hh:mm A"
      )}`;
    if (difference > 0) {
      differenceString = `${difference}`;
      if (difference === 1)
        differenceString = differenceString + " day remaining";
      else differenceString = differenceString + " days remaining";
    }

    if (difference < 0) {
      differenceString = `${-difference}`;
      if (difference === -1) differenceString = differenceString + " day ago";
      else differenceString = differenceString + " days ago";
    }

    return differenceString;
  }, [expirationDate]);

  return diffString;
};

export const useRelativeExpirationDateValue = (expirationDate: Date | null) => {
  const diffString = useMemo(() => {
    return getRelativeExpirationDateValue(expirationDate);
  }, [expirationDate]);

  return diffString;
};

export const getRelativeExpirationDateValue = (expirationDate: Date | null) => {
  const expiration = expirationDate
    ? moment.utc(new Date(expirationDate).setUTCHours(12, 0, 0, 0))
    : null;
  if (!expiration) return "";
  const today = moment.utc(new Date().setUTCHours(12, 0, 0, 0));
  let difference = expiration.diff(today, "days");
  let differenceString = "";
  if (difference === 0 && expirationDate)
    differenceString = `today at ${moment(new Date(expirationDate)).format(
      "hh:mm A"
    )}`;
  if (difference > 0) {
    differenceString = `${difference}`;
    if (difference === 1) differenceString = "in " + differenceString + " day";
    else differenceString = "in " + differenceString + " days";
  }
  return differenceString;
};

export const useContainerContextSetter = (
  containerContext: IBlockContext,
  setContainerContext: React.Dispatch<React.SetStateAction<IBlockContext>>
) => {
  const contextRef = useRef<IBlockContext>(containerContext);

  const {
    userRole,
    permissions,
    isBaseMember,
    connected,
    abilities,
    userId,
    baseType,
  } = useSelector((state: ClarityStore) => {
    return {
      userRole: state.client.roleType,
      permissions: state.client.permissions?.permissions,
      abilities: state.client.permissions,
      isBaseMember: state.client.isBaseMember,
      connected: state.network.connected,
      userId: state.user?.id,
      baseType: state.workspace.type,
    };
  }, shallowEqual);

  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    entityId: contextRef.current.container.id,
    entityType: contextRef.current.container.type,
    isGroupMember: getGroupsFromEntityId(
      contextRef.current.container.id,
      contextRef.current.container.type
    ),
  });

  useLayoutEffect(() => {
    const context = {
      ...contextRef.current,
    };
    context.canEdit = false;
    context.autosave = false;
    context.persistToggle = false;
    context.canComment = false;
    const primitive = ContainerTypeToPrimitive[
      contextRef.current.container.type
    ] as keyof PermissionType;
    if (isBaseMember) {
      if (userRole === UserRole.GUEST || userRole === "") {
        if (
          permissions &&
          permissions[primitive] &&
          permissions[primitive]?.edit.includes(contextRef.current.container.id)
        ) {
          context.canEdit = true;
          context.autosave = true;
          context.canComment = true;
          context.persistToggle = true;
        } else if (
          permissions &&
          permissions[primitive] &&
          permissions[primitive]?.suggest.includes(
            contextRef.current.container.id
          )
        ) {
          context.canComment = true;
        }
        setContainerContext(context);
      } else {
        setContainerContext({
          ...contextRef.current,
          canEdit: connected && isBaseMember && canEdit,
          canComment: roleApi.checkAbility({
            abilityName: Abilities.CAN_COMMENT_ENTITY,
            entityId: contextRef.current.container.id,
            entityType: contextRef.current.container.type,
          }),
        });
      }
    } else {
      if (
        permissions &&
        permissions[primitive] &&
        permissions[primitive]?.edit.includes(contextRef.current.container.id)
      ) {
        if (userId) {
          context.canEdit = true;
          context.autosave = true;
          context.canComment = true;
          context.persistToggle = true;
        } else {
          if (baseType === BaseType.Public) context.showInterceptor = true;
        }
      } else if (
        permissions &&
        permissions[primitive] &&
        permissions[primitive]?.suggest.includes(
          contextRef.current.container.id
        )
      ) {
        context.canComment = true;
      }
      setContainerContext(context);
    }
  }, [permissions, userRole, connected, abilities]);

  useEffect(() => {
    contextRef.current = containerContext;
  }, [containerContext]);
};

export const useAbilityChecker = (param: {
  abilityName: Abilities;
  entityId?: string;
  entityType?: ContainerTypes;
  isGroupMember?: string | string[];
}) => {
  const permissions = useSelector(
    (store: ClarityStore) => store.client.permissions,
    shallowEqual
  );

  const checkAbility = () => {
    let groupAccess = true;
    groupAccess = groupApi.isGroupMember(param.isGroupMember);

    return (
      groupAccess &&
      roleApi.checkAbility({
        abilityName: param.abilityName,
        entityId: param.entityId,
        entityType: param.entityType,
      })
    );
  };

  const [hasPermission, setHasPermission] = useState<boolean>(checkAbility());

  useLayoutEffect(() => {
    setHasPermission(checkAbility());
  }, [permissions, param.isGroupMember]);

  return hasPermission;
};

export const useWalletConnectionChecker = () => {
  const [loading, setLoading] = useState(true);
  const [walletConnected, setwalletConnected] = useState(false);
  const user = useSelector((store: ClarityStore) => store.user, shallowEqual);

  const checkWallet = useCallback(async () => {
    if (!user || !user.publicEthAdress) {
      setwalletConnected(false);
      return setLoading(false);
    }
    const address = await web3Api.getAccount();
    setwalletConnected(address ? true : false);
    setLoading(false);
  }, [user?.id, user?.publicEthAdress]);

  useLayoutEffect(() => {
    const sub = web3Api.accountSubject.subscribe((account) => {
      checkWallet();
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    checkWallet();
  }, [user?.id, user?.publicEthAdress]);

  const memodResult = useMemo(
    () => ({ loading, walletConnected }),
    [loading, walletConnected]
  );

  return memodResult;
};

export const useGroupContext = () => {
  const paneId = usePaneId();

  const groupContext = useShallowSelector(
    (state) => state.client.paneGroupContext[paneId]?.groupId
  );

  return groupContext;
};

export const useGroupContextInSplit = (paneId: ChunkDestination) => {
  const groupContext = useShallowSelector(
    (state) => state.client.paneGroupContext[paneId]?.groupId
  );

  return groupContext;
};
