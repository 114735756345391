import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import {
  LOAD_NOTE_WITHOUT_BLOCKS,
  LOAD_NEW_WEEKLY_WITHOUT_BLOCKS,
  RESET_NOTES,
  LOAD_BASE_NOTES,
  LOAD_BASE_WEEKLY_NOTES,
  LOAD_NEXT_NOTE_BATCH,
  ADD_NEW_NOTE,
  SET_NOTE_OBJECTS,
  REMOVE_NOTE,
  PATCH_NOTE,
  SET_ACTIVE_WORKSPACE,
  SET_FOLLOWING_NOTES,
  UPDATE_NOTE,
  SET_NOTE_IN_DICT,
} from "store/actions";
import { ClarityStore } from "store/storeExporter";
import { INotesObj } from "utilities/types";
import { prepareStateForFrontend } from "./workReducer";

export interface INotesDict {
  [id: string]: INotesObj;
}

export const NOTE_PAGE_SIZE = 12; // 16;

interface INotesState {
  dict: INotesDict;
  weeklyNotesDict: INotesDict;
  ids: string[];
  weeklyNoteIds: string[];
  weeklyNoteId: string;
  followingIds: string[];
  notesLoadStatus: {
    startFrom: number;
    allLoaded: boolean;
  };
  groupNotes: {
    [groupId: string]: {
      noteIds: string[];
      cursorPosition: {
        startFrom: number;
        allLoaded: boolean;
      };
    };
  };
}

const initialState: INotesState = {
  dict: {},
  weeklyNotesDict: {},
  ids: [],
  weeklyNoteIds: [],
  weeklyNoteId: "",
  followingIds: [],
  notesLoadStatus: {
    startFrom: 0,
    allLoaded: false,
  },
  groupNotes: {},
};

export default function notesReducer(
  state: INotesState = initialState,
  action: any
) {
  switch (action.type) {
    case LOAD_NOTE_WITHOUT_BLOCKS: {
      const newState: INotesState = {
        ...state,
        weeklyNotesDict: {
          ...{ [action.note.id]: action.note },
          ...state.weeklyNotesDict,
        },
        weeklyNoteId: action.note.id,
      };
      return newState;
    }

    case LOAD_NEW_WEEKLY_WITHOUT_BLOCKS: {
      if (!action.note?.id) return state;
      const newState: INotesState = {
        ...state,
        weeklyNotesDict: {
          ...{ [action.note.id]: action.note },
          ...state.weeklyNotesDict,
        },
        weeklyNoteIds: [action.note.id, ...state.weeklyNoteIds],
      };

      return newState;
    }

    case SET_ACTIVE_WORKSPACE:
    case RESET_NOTES: {
      return initialState;
    }

    case LOAD_BASE_NOTES: {
      if (action.params.notes) {
        const newState: INotesState = {
          ...state,
          dict: { ...state.dict },
          ids: [...state.ids],
          notesLoadStatus: { ...state.notesLoadStatus },
          groupNotes: { ...state.groupNotes },
        };
        let groupId = null;
        const addedIds: string[] = [];

        if (action.params.notes && Array.isArray(action.params.notes)) {
          action.params.notes.map((note: INotesObj) => {
            if (note.groupId) {
              groupId = note.groupId;
              if (!newState.groupNotes[note.groupId]) {
                newState.groupNotes[note.groupId] = {
                  noteIds: [],
                  cursorPosition: {
                    startFrom: 0,
                    allLoaded: false,
                  },
                };
              }
              addedIds.push(note.id);
            } else {
              newState.ids.push(note.id);
            }
            // note.blocksState = prepareStateForFrontend(note.linesArray);
            return (newState.dict[note.id] = note);
          });
          if (groupId) {
            const allLoaded = addedIds.length < NOTE_PAGE_SIZE;
            newState.groupNotes[groupId] = { ...newState.groupNotes[groupId] };
            newState.groupNotes[groupId].cursorPosition = {
              ...newState.groupNotes[groupId].cursorPosition,
            };
            newState.groupNotes[groupId].cursorPosition.allLoaded = allLoaded;
            newState.groupNotes[groupId].cursorPosition.startFrom =
              newState.groupNotes[groupId].cursorPosition.startFrom +
              addedIds.length;
            newState.groupNotes[groupId].noteIds = _.uniq([
              ...newState.groupNotes[groupId].noteIds,
              ...addedIds,
            ]);
          }
        }

        newState.notesLoadStatus.allLoaded = action.params.allLoaded;
        newState.notesLoadStatus.startFrom = Number(action.params.startFrom);
        return newState;
      }
      return state;
    }

    case LOAD_BASE_WEEKLY_NOTES: {
      if (action.params.notes) {
        const newState: INotesState = {
          ...state,
          weeklyNotesDict: { ...state.weeklyNotesDict },
          weeklyNoteIds: [...state.weeklyNoteIds],
        };
        if (action.params.notes && Array.isArray(action.params.notes)) {
          action.params.notes.map((note: INotesObj) => {
            newState.weeklyNoteIds.push(note.id);
            // note.blocksState = prepareStateForFrontend(note.linesArray);
            return (newState.weeklyNotesDict[note.id] = note);
          });
        }
        return newState;
      }
      return state;
    }

    case "UPDATE_NOTE_BLOCK_OBJECT": {
      const newState: INotesState = {
        ...state,
        dict: { ...state.dict },
        weeklyNotesDict: { ...state.weeklyNotesDict },
      };
      if (newState.dict[action.note.id]) {
        newState.dict[action.note.id] = { ...state.dict[action.note.id] };
        newState.dict[action.note.id].blocksState = {
          ...action.note.blocksState,
        };
      } else if (newState.weeklyNotesDict[action.note.id]) {
        newState.weeklyNotesDict[action.note.id] = {
          ...state.weeklyNotesDict[action.note.id],
        };
        newState.weeklyNotesDict[action.note.id].blocksState = {
          ...action.note.blocksState,
        };
      }
      return newState;
    }

    case LOAD_NEXT_NOTE_BATCH: {
      if (action.params.notes) {
        const newState: INotesState = {
          ...state,
          dict: { ...state.dict },
          ids: [...state.ids],
          notesLoadStatus: { ...state.notesLoadStatus },
          groupNotes: {
            ...state.groupNotes,
          },
        };

        let groupId = action.params.groupId;

        if (!groupId) return state;

        if (newState.groupNotes[groupId]) {
          newState.groupNotes[groupId] = {
            ...newState.groupNotes[groupId],
          };
          newState.groupNotes[groupId].noteIds = [
            ...newState.groupNotes[groupId].noteIds,
          ];
          newState.groupNotes[groupId].cursorPosition = {
            ...newState.groupNotes[groupId].cursorPosition,
          };
        } else {
          newState.groupNotes[groupId] = {
            noteIds: [],
            cursorPosition: {
              startFrom: 0,
              allLoaded: false,
            },
          };
        }

        let addedIds: string[] = [];

        if (action.params.notes && Array.isArray(action.params.notes)) {
          action.params.notes.map((note: INotesObj) => {
            if (note.groupId) {
              addedIds.push(note.id);
            } else {
              newState.ids.push(note.id);
            }
            //  note.blocksState = prepareStateForFrontend(note.linesArray);
            return (newState.dict[note.id] = note);
          });
        }

        const loaded = action.params.notes.length < NOTE_PAGE_SIZE;

        newState.groupNotes[groupId].noteIds = _.uniq([
          ...newState.groupNotes[groupId].noteIds,
          ...addedIds,
        ]);
        newState.groupNotes[groupId].cursorPosition.startFrom =
          newState.groupNotes[groupId].cursorPosition.startFrom +
          action.params.notes.length;
        newState.groupNotes[groupId].cursorPosition.allLoaded = loaded;

        newState.notesLoadStatus.allLoaded = action.params.allLoaded;
        newState.notesLoadStatus.startFrom = Number(action.params.startFrom);
        return newState;
      }
      return state;
    }

    case ADD_NEW_NOTE: {
      if (action.note) {
        const newState: INotesState = {
          ...state,
          dict: { ...state.dict },
          ids: [...state.ids],
          groupNotes: { ...state.groupNotes },
          notesLoadStatus: state.notesLoadStatus,
        };
        if (action.note.groupId) {
          if (!newState.groupNotes[action.note.groupId]) {
            newState.groupNotes[action.note.groupId] = {
              noteIds: [action.note.id],
              cursorPosition: {
                startFrom: 1,
                allLoaded: false,
              },
            };
          } else {
            const alreadyInState = newState.groupNotes[
              action.note.groupId
            ].noteIds.includes(action.note.id);

            const newIds: string[] = alreadyInState
              ? newState.groupNotes[action.note.groupId].noteIds
              : [
                  action.note.id,
                  ...newState.groupNotes[action.note.groupId].noteIds,
                ];

            newState.groupNotes[action.note.groupId] = {
              noteIds: newIds,
              cursorPosition: {
                startFrom: alreadyInState
                  ? newState.groupNotes[action.note.groupId].cursorPosition
                      .startFrom
                  : newState.groupNotes[action.note.groupId].cursorPosition
                      .startFrom + 1,
                allLoaded: false,
              },
            };
          }
        } else {
          if (!newState.ids.includes(action.note.id)) {
            newState.ids.splice(0, 0, action.note.id);
          }
        }
        action.note.blocksState = prepareStateForFrontend(
          action.note.linesArray
        );
        newState.dict[action.note.id] = { ...action.note };
        return newState;
      }
      return state;
    }

    case SET_NOTE_OBJECTS: {
      if (action.params.notes) {
        const newState: INotesState = {
          ...state,
          dict: { ...state.dict },
          weeklyNotesDict: { ...state.weeklyNotesDict },
        };
        if (action.params.notes && Array.isArray(action.params.notes)) {
          action.params.notes.map((note: INotesObj) => {
            if (note.isWeekly) {
              return (newState.weeklyNotesDict[note.id] = note);
            }
            return (newState.dict[note.id] = note);
          });
        }
        return newState;
      }
      return state;
    }

    case REMOVE_NOTE: {
      if (action.noteId) {
        const newState: INotesState = { ...state, dict: { ...state.dict } };
        const note = newState.dict[action.noteId];
        delete newState.dict[action.noteId];
        if (note?.groupId) {
          const group = newState.groupNotes[note.groupId];
          const indexToRemove = group.noteIds.indexOf(action.noteId);
          if (indexToRemove > -1) {
            newState.groupNotes[note.groupId].noteIds.splice(indexToRemove, 1);
          }
        } else {
          const indexToRemove = state.ids.indexOf(action.noteId);
          if (indexToRemove > -1) {
            newState.ids.splice(indexToRemove, 1);
          }
        }
        return newState;
      }
      return state;
    }

    case PATCH_NOTE: {
      const newState: INotesState = { ...state };
      if (action.patchContainer.isWeekly) {
        newState.weeklyNotesDict = { ...state.weeklyNotesDict };
        const container =
          newState.weeklyNotesDict[action.patchContainer.container.containerId];
        newState.weeklyNotesDict[action.patchContainer.container.containerId] =
          {
            ...container,
            ...action.patchContainer.patch,
          };
        return newState;
      }
      newState.dict = { ...state.dict };
      const container =
        newState.dict[action.patchContainer.container.containerId];
      newState.dict[action.patchContainer.container.containerId] = {
        ...container,
        ...action.patchContainer.patch,
      };
      return newState;
    }

    case SET_FOLLOWING_NOTES: {
      const newState: INotesState = { ...state };
      const notes = action.notes;
      if (notes.length > 0) {
        newState.dict = { ...newState.dict };
        newState.followingIds = [];
        notes.forEach((note: INotesObj) => {
          newState.followingIds.push(note.id);
          newState.dict[note.id] = note;
        });
      }
      return newState;
    }

    case UPDATE_NOTE: {
      const newState: INotesState = { ...state, dict: { ...state.dict } };

      if (action.note.id) {
        if (newState.dict[action.note?.id]?.id) {
          newState.dict[action.note?.id] = {
            ...newState.dict[action.note?.id],
            ...action.note,
          };
        } else {
          newState.dict[action.note?.id] = {
            ...action.note,
          };
        }
      }
      return newState;
    }

    case SET_NOTE_IN_DICT: {
      const newState = { ...state };
      newState.dict = { ...newState.dict, ...action.noteObj };
      return newState;
    }
    default:
      return state;
  }
}

export function useWeeklyNoteId() {
  return useSelector(
    (state: ClarityStore) => state.notes.weeklyNoteId,
    shallowEqual
  );
}
