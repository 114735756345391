import React from "react";
import styles from "./baseAvatar/baseAvatar.module.scss";
import { IWorkspaceObj } from "utilities/types";
import { useOptionalClassName } from "utilities/hooks";

interface IBaseAvatarProps {
  base: IWorkspaceObj;
  className?: string;
  onClick?: React.MouseEventHandler;
  aviBorderStyles?: React.CSSProperties;
}

export default function BaseAvatar({
  base,
  className,
  aviBorderStyles,
  onClick,
}: IBaseAvatarProps) {
  const { styleProps, textContents } = useBaseAvatarState({ base, className });
  return (
    <div onClick={onClick} {...styleProps}>
      <div
        className={styles.aviBorder}
        style={aviBorderStyles ? aviBorderStyles : {}}
      />
      {textContents}
    </div>
  );
}

function useBaseAvatarState({
  base,
  className = "",
}: Pick<IBaseAvatarProps, "className" | "base">) {
  const classNames = useOptionalClassName({
    baseStyle: styles.container,
    pairs: [
      {
        extraStyle: className,
        withExtra: Boolean(className),
      },
      {
        extraStyle: styles.withImg,
        withExtra: Boolean(base.avatar),
      },
    ],
  });

  const styleProps = {
    style: base.avatar ? { backgroundImage: `url(${base.avatar})` } : {},
    className: classNames,
  };

  const textContents = base.avatar
    ? undefined
    : base.name.charAt(0).toUpperCase();

  return {
    styleProps,
    textContents,
  };
}
