import { mixpanel } from "App";
import { axiosInstance } from "index";
import { batch } from "react-redux";
import {
  SET_DONE_NOTIFICATIONS,
  SET_NEW_NOTIFICATIONS,
  SET_NOTIFICATIONS_COUNT,
  UPDATE_THREAD,
} from "store/actions";
import store, { prevState } from "store/storeExporter";
import { IThreadView, UserEventTypes } from "utilities/types";
export const notificationPageSize = 30;

// TODO: better eror handling
// send both get requests at the same time
// user threads are loaded in batches so this needs to be called when opening the base,
// changing the base, scrolling at the bottom of the loaded ones

export const getUserNotifications = (userId: string, baseId: string) => {
  getUserThreadCount(userId, baseId);
  getUserThreads(userId, baseId, 0);
  getUserThreads(userId, baseId, 0, true);
};

export const getUserThreads = (
  userId: string,
  baseId: string,
  page: number,
  done?: boolean
) => {
  axiosInstance
    .get("/api/user-thread/to-do", {
      params: {
        userId,
        baseId,
        startFrom: page,
        pageSize: notificationPageSize,
        done,
      },
    })
    .then((res) => {
      if (!done) {
        store.dispatch({
          type: SET_NEW_NOTIFICATIONS,
          notifications: res.data,
          position: {
            page: page + 1,
            size: notificationPageSize,
            allLoaded: res.data.length < notificationPageSize,
          },
        });
      } else {
        store.dispatch({
          type: SET_DONE_NOTIFICATIONS,
          notifications: res.data,
          position: {
            page: page + 1,
            size: notificationPageSize,
            allLoaded: res.data.length < notificationPageSize,
          },
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getUserThreadCount = (userId: string, baseId: string) => {
  axiosInstance
    .get("/api/user-thread/count", {
      params: {
        userId,
        baseId,
      },
    })
    .then((res) => {
      store.dispatch({
        type: SET_NOTIFICATIONS_COUNT,
        allMentionsCount: res.data.allMentionsCount,
        allUnreadCount: res.data.allUnreadCount,
      });
    })
    .catch((err) => console.log(err));
};

export const readUserThreads = (threadIds: string[]) => {
  mixpanel.track(UserEventTypes.NOTIFICATION_READ, {
    distinct_id: prevState.value?.user?.id,
  });

  batch(() => {
    threadIds.forEach((threadId) => {
      store.dispatch({
        type: UPDATE_THREAD,
        id: threadId,
        threadDelta: { read: true, mentionCount: 0 } as IThreadView,
      });
    });
  });
  axiosInstance
    .post("/api/user-thread/read", {
      threadIds,
    })
    .catch();
};

export const markThreadAsDone = (threadId: string) => {
  mixpanel.track(UserEventTypes.NOTIFICATION_MARKED_DONE, {
    distinct_id: prevState.value?.user?.id,
  });

  store.dispatch({
    type: UPDATE_THREAD,
    id: threadId,
    threadDelta: { done: true, read: true, mentionCount: 0 },
  });
  axiosInstance
    .post("/api/user-thread/done", {
      threadId,
    })
    .catch();
};

export const markThreadAsNotDone = (threadId: string) => {
  mixpanel.track(UserEventTypes.NOTIFICATION_MARKED_NOT_DONE, {
    distinct_id: prevState.value?.user?.id,
  });
  store.dispatch({
    type: UPDATE_THREAD,
    id: threadId,
    threadDelta: { done: false },
  });
  axiosInstance
    .post("/api/user-thread/not-done", {
      threadId,
    })
    .catch();
};

export const syncNotifications = () => {
  const state = store.getState();
  if (!state.user?.id) return;

  let lastSync;
  const id = state.inAppNotifications.inboxNotifications[0];
  if (id) lastSync = state.inAppNotifications.dict[id]?.dateUpdated;

  axiosInstance
    .get("/api/user-thread/sync", {
      params: {
        userId: state.user?.id,
        baseId: state.workspace.id,
        lastSync,
      },
    })
    .then((res) => {
      res.data.forEach((threadView: IThreadView) => {
        store.dispatch({
          type: UPDATE_THREAD,
          id: threadView.id,
          threadDelta: threadView,
        });
      });
    })
    .catch((err) => console.log(err));
};
