export enum TypeOfLineMovement {
  endOfLine = "endOfLine",
  startOfLine = "startOfLine",
  previousCursorState = "previousCursorState",
}

export enum TypeOfNewFocus {
  prevPosition = "prevPosition",
  newFocus = "newFocus",
  moveToEnd = "moveToEnd",
  None = "None",
}
