import { batch } from "react-redux";
import { REFOCUS, SET_UNDO } from "store/actions";
import { Block } from "store/reducers/blockReducer";
import store, { $focusOn, prevState } from "store/storeExporter";
import { IBlockContext } from "utilities/types";
import { updateSingleBlock } from "../primitiveActions/primitiveActions";
import { setUpdateObjectToStore } from "./persistActions";
import {
  ActionObject,
  ActionWrapperObject,
  createActionWrapperObject,
} from "./undoUtils";

export const batchBlockUpdate = (batchAction: {
  selectedBlocks?: string[];
  id: string;
  context: IBlockContext;
  delta: Partial<Block>;
}) => {
  const newState = store.getState().blocks;
  let selectedBlocks = [...newState.selectedBlocks];
  if (batchAction.selectedBlocks) selectedBlocks = batchAction.selectedBlocks;
  if (selectedBlocks.length === 0) selectedBlocks.push(batchAction.id);

  const saveActions: ActionObject[] = [];
  const defaultDelta: Partial<Block> = {
    dateModified: new Date(),
    modifiedBy: prevState.value.user?.id,
  };

  const delta = { ...defaultDelta, ...batchAction.delta };

  const undoObject: ActionWrapperObject = createActionWrapperObject(
    batchAction.context
  );

  batch(() => {
    for (const id of selectedBlocks) {
      updateSingleBlock({
        blockId: id,
        delta,
        undoObject,
        saveActions,
        context: batchAction.context,
        newState,
      });
      // prepareUpdateAndUndoForSingleBlock(block, delta, saveActions, undoObject);
    }

    store.dispatch({
      type: SET_UNDO,
      param: {
        undoObject,
        contextId: batchAction.context.id,
      },
    });
    store.dispatch({
      type: REFOCUS,
      param: {
        newFocus: { ...$focusOn.value },
      },
    });
  });
  setUpdateObjectToStore(saveActions, batchAction.context);
};
