import { TeamOutlined } from "@ant-design/icons";
import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import UsersStack from "clarity-ui/UsersStack";
import Conditional from "components/Conditional";
import React from "react";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import {
  CommandPaletteContext,
  ContainerTypes,
  WorkTypes,
} from "utilities/types";
import styles from "../../containerDetailMetadata/containerDetailMetadata.module.scss";

const ContributorsRow: React.FC<{
  workType: WorkTypes;
  assigneeId?: string | null;
  contributorIds?: string[];
  canEditEntity: boolean;
  containerId: string;
  containerType: ContainerTypes;
}> = ({
  workType,
  assigneeId,
  contributorIds,
  canEditEntity,
  containerId,
  containerType,
}) => {
  const handleContributorsClick = () =>
    openCommandPalette(CommandPaletteContext.CONTRIBUTORS, {
      selectedItemIds: [containerId],
      slectedItemsType: containerType,
    });

  const filteredContributorIds =
    contributorIds &&
    contributorIds.filter(
      (contributorId: string) => contributorId !== assigneeId
    );

  return (
    <Conditional
      on={workType === WorkTypes.PROJECT || workType === WorkTypes.INITIATIVE}
    >
      <MetadataBlock
        title="Contributors"
        disabled={!canEditEntity}
        onClick={handleContributorsClick}
        prefix={
          !(filteredContributorIds && filteredContributorIds.length > 0) ? (
            <span
              className={
                filteredContributorIds && filteredContributorIds.length === 0
                  ? "disabled"
                  : ""
              }
            >
              {ContributorsDisplay()}
            </span>
          ) : undefined
        }
        infoText={
          filteredContributorIds && filteredContributorIds.length > 0 ? (
            ""
          ) : (
            <span className="disabled"> None </span>
          )
        }
      >
        <>
          <UsersStack
            avatarSize={18}
            userIds={filteredContributorIds ? filteredContributorIds : []}
          />
          <ContributorsCount
            count={filteredContributorIds ? filteredContributorIds.length : 0}
          />
        </>
      </MetadataBlock>
    </Conditional>
  );
};

function ContributorsDisplay() {
  return (
    <div className={styles.cycleDisplay}>
      <TeamOutlined className={styles.icon} />
    </div>
  );
}

const ContributorsCount: React.FC<{ count: number }> = ({ count }) => {
  if (count === 0) return <></>;
  return (
    <span className={styles.infoText}>
      {count} {count > 1 ? "contributors" : "contributor"}
    </span>
  );
};

export default ContributorsRow;
