import React from "react";
import {
  CloseOutlined,
  UndoOutlined,
  QuestionCircleOutlined,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import workApi from "clientApi/workApi";
import Conditional from "components/Conditional";
import { useUser } from "store/reducers/userReducer";
import styles from "../styles/activity.module.scss";
import Button, { ButtonTypes } from "components/Button";
import {
  DeltaAction,
  IProjectObj,
  IWorkActivity,
  WorkTypes,
} from "utilities/types";
import { useShallowSelector } from "utilities/hooks";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import { Tooltip } from "antd";
import { getNameFromWorkType } from "utilities/workUtils";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";

const ClaimRequest: React.FC<{
  workActivity: IWorkActivity;
  workItem: IProjectObj;
}> = ({ workActivity, workItem }) => {
  const user = useUser();

  const acceptedUser = useShallowSelector((state) =>
    workActivity.delta?.metadata?.nextValue
      ? state.members.dict[workActivity.delta?.metadata?.nextValue]
      : undefined
  );
  return (
    <>
      <div className={styles.requestReviewBanner}>
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Conditional on={workActivity.delta?.action === DeltaAction.PENDING}>
            <QuestionCircleOutlined className="primary" />
            <span className={`primary ${styles.bodyText}`}>
              {workItem.workType === WorkTypes.TASK
                ? "Requested to claim this task"
                : `Requested to contribute to this ${getNameFromWorkType(
                    workItem.workType,
                    false
                  )}`}
            </span>
          </Conditional>
          <Conditional on={workActivity.delta?.action === DeltaAction.ACCEPTED}>
            <CheckCircleTwoTone />
            <span className={`secondary ${styles.bodyText}`}>
              Request to{" "}
              {workItem.workType === WorkTypes.TASK
                ? "claim this task"
                : `contribute to this ${getNameFromWorkType(
                    workItem.workType,
                    false
                  )}`}{" "}
              and was approved by{" "}
              <b>
                {usernameSplitter(
                  acceptedUser?.name || "@" + acceptedUser?.username
                )}
              </b>{" "}
            </span>
          </Conditional>

          <Conditional on={workActivity.delta?.action === DeltaAction.DENIED}>
            <CloseCircleTwoTone />
            <span className={`secondary ${styles.bodyText}`}>
              Request to{" "}
              {workItem.workType === WorkTypes.TASK
                ? "claim this task"
                : `contribute to this  ${getNameFromWorkType(
                    workItem.workType,
                    false
                  )}`}{" "}
              and was denied by{" "}
              <b>
                {usernameSplitter(
                  acceptedUser?.name || "@" + acceptedUser?.username
                )}
              </b>{" "}
            </span>
          </Conditional>
        </div>
        <Conditional
          on={
            user.id === workItem.reviewerId ||
            user.id === workItem.authorId ||
            (workItem.workType !== WorkTypes.TASK &&
              user.id === workItem.assigneeId)
          }
        >
          <div className={styles.reviewButtons}>
            {workActivity.delta?.action === DeltaAction.PENDING && (
              <Button
                type={ButtonTypes.LARGE_SECONDARY}
                onClick={() => {
                  workApi.acceptClaim(workActivity);
                }}
              >
                Approve
              </Button>
            )}
            {workActivity.delta?.action === DeltaAction.DENIED && (
              <Tooltip title={"Undo & Accept"} trigger={"hover"}>
                <span>
                  <Button
                    type={ButtonTypes.LINK}
                    icon={<UndoOutlined />}
                    className={styles.denyButtonOverwrite}
                    onClick={() => {
                      workApi.acceptClaim(workActivity);
                    }}
                  ></Button>
                </span>
              </Tooltip>
            )}
            {workActivity.delta?.action === DeltaAction.PENDING && (
              <Button
                type={ButtonTypes.LINK}
                icon={<CloseOutlined />}
                className={styles.denyButtonOverwrite}
                onClick={() => {
                  workApi.denyClaim(workActivity);
                }}
              ></Button>
            )}
          </div>
        </Conditional>
      </div>
    </>
  );
};

export default ClaimRequest;
