// Vendor Libraries
import { Form } from "antd";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useState, useRef, useEffect } from "react";

// Internal Modules
import * as actionTypes from "../store/actions";
import { IWorkspaceObj, IUserObj } from "../utilities/types";
import { useHistory } from "react-router-dom";
import ClarityLogo from "clarity-ui/ClarityLogo";
import ClarityInput from "components/ClarityInput";
import Button, { ButtonTypes } from "components/Button";

import styles from "./launchBase/styles.module.scss";
import { axiosInstance } from "index";

interface IMapStateToProps {
  user: IUserObj;
}

interface IMapDispatchToProps {
  setActiveWorkspace: (workspace: IWorkspaceObj) => {
    type: string;
    workspace: IWorkspaceObj;
  };
  addNewWorkspace: (workspace: IWorkspaceObj) => {
    type: string;
    workspace: IWorkspaceObj;
  };
}

function LaunchBase(props: IMapStateToProps & IMapDispatchToProps) {
  const history = useHistory();

  const [base, setBase] = useState("");
  const [errorStatus, setErrorStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const isBaseNameValid =
    base && base.trim().length > 0 && base.trim().length < 40;
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case "base":
        setBase(value);
        break;
      default:
        console.log("unanticipated form field");
        break;
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    axiosInstance
      .post("/api/workspace", {
        name: base,
      })
      .then((res) => {
        const { payload: base } = res.data;
        setErrorStatus(0);
        setErrorMessage("");
        props.addNewWorkspace(base);
        history.push(`/${base.slug}`);
      })
      .catch((err) => {
        if (err.response) {
          const { message, status } = err.response.data;
          setErrorStatus(status);
          setErrorMessage(message);
        }
        console.log("Error: ", err);
      });
  };

  const BaseNameInputRef: any = useRef();

  useEffect(() => {
    if (BaseNameInputRef && BaseNameInputRef.current) {
      const initialName = `${props.user.username}'s Base`;
      BaseNameInputRef.current.value = initialName;
      setBase(initialName);
    }
  }, [props.user.username]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Launch New Base • Clarity</title>
      </Helmet>
      <ClarityLogo containerStyle={({ isMobile }) => ({ marginBottom: 68 })} />
      <h5 className={styles.info}>
        <span>Launch a new base.</span>
        <br />
        <span>Start a new collection of docs, tags, tasks, and projects.</span>
        <br />
        <span>Invite collaborators to a shared workspace.</span>
      </h5>
      <Form onSubmitCapture={handleSubmit} className={styles.form}>
        <div className={styles.inputRow}>
          <Form.Item className={styles.inputRowInputItem}>
            <ClarityInput
              label="Name your base"
              className={styles.inputRowInput}
              onChange={handleChange}
              componentRef={BaseNameInputRef}
              placeholder="Base name"
              value={base}
              name="base"
            />
          </Form.Item>
        </div>
        <div className={styles.inputRow}>
          <Form.Item>
            <Button
              disabled={!isBaseNameValid}
              buttonType={ButtonTypes.LARGE_PRIMARY}
              type="submit"
            >
              <span className={styles.continueBtnText}>Create base</span>
            </Button>
          </Form.Item>
        </div>
        {errorStatus ? (
          <div style={{ border: "solid 1px red", backgroundColor: "salmon" }}>
            {errorStatus === 2 ? (
              <span>
                Clarity is invite-only at this time. Please{" "}
                <a href="/request-access">request access</a> and we will contact
                you with an invitation.
              </span>
            ) : errorStatus ? (
              errorMessage
            ) : null}
          </div>
        ) : null}
      </Form>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setActiveWorkspace: (workspace: IWorkspaceObj) =>
    dispatch({
      type: actionTypes.SET_ACTIVE_WORKSPACE,
      workspace,
    }),
  addNewWorkspace: (workspace: IWorkspaceObj) =>
    dispatch({
      type: actionTypes.ADD_NEW_WORKSPACE,
      workspace,
    }),
  setUserPermissions: (workspace: any) =>
    dispatch({
      type: actionTypes.SET_USER_PERMISSIONS,
      params: {
        roleType: workspace.roleType,
        permissions: workspace.permissions,
        roleIds: workspace.roleIds,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LaunchBase);
