import { generateNewChunk } from "store/reducers/filterReducer";
import { IActionBtn } from "store/reducers/topNavReducer";
import store from "store/storeExporter";
import { getSunday } from "utilities/dateTime";
import { getPaneGroupContext } from "utilities/groupUtilities";
import { ChunkDestination } from "utilities/stateTypes";
import {
  GroupTypes,
  IFilterState,
  IProjectObj,
  IWorkStatus,
  OrderTypes,
  PayoutsViewsNames,
  RewardFilter,
  TasksViewModes,
  ViewAsTypes,
  WorkTypes,
} from "utilities/types";

export interface IWorkViewDict {
  [id: string]: IWorkViewConfig;
}

export interface IWorkViewConfig {
  id: string;
  title: string;
  description: string;
  emptyState: string;
  path: string;
  viewMode: TasksViewModes;
  groupBy: GroupTypes;
  orderBy: OrderTypes;
  viewAs: ViewAsTypes;
  getFilters: (paneId?: ChunkDestination) => IFilterState;
  presetMetadata: () => Partial<IProjectObj>;
  customView?: boolean;
  showActiveProjects?: boolean;
  context?: string;
  extraBtns?: () => IActionBtn[];
  forceEmptyState?: boolean;
  canPersistLocally?: boolean;
}

export const getClosedStatuses = () => {
  const statuses = store.getState().work.statuses;
  const statusObjs = statuses.statusArray.map(
    (statusId: string) => statuses.dict[statusId]
  );
  const closedStatusIds = statusObjs
    .filter((status: IWorkStatus) => parseInt(status.categoryId) >= 4)
    .map((status: IWorkStatus) => status.id);

  return closedStatusIds;
};

export const customWorkViewGenerator = (
  viewId?: string,
  context?: string
): IWorkViewConfig => {
  if (!viewId) return defaultworkViewsDict[TasksViewModes.NewCustomView];
  const customview = store.getState().customWork.dict[viewId];
  return {
    id: viewId,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    emptyState: "There are no tasks or projects matching your filters",
    description: "",
    customView: true,
    viewAs: ViewAsTypes.list,
    context,
    path: `/view/${viewId}`,
    title: customview?.name,
    viewMode: TasksViewModes.CustomView,
    presetMetadata: () => ({}),
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
      };
      return filters;
    },
  };
};

export const getMyWorkViewGenerator = (): IWorkViewConfig => {
  const user = store.getState().user;
  return {
    id: TasksViewModes.MyTasks,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    viewAs: ViewAsTypes.board,
    emptyState:
      "Tasks will automatically show up here when you are the assignee.",
    description: "My Tasks",
    customView: false,
    path: "/my-work",
    title: "My Work",
    viewMode: TasksViewModes.MyTasks,
    presetMetadata: () => ({
      assigneeId: user ? user.id : undefined,
    }),
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
        assignees: [user ? user.id : ""],
        type: [WorkTypes.TASK],
        hasActiveFilters: true,
      };
      return filters;
    },
  };
};

export const getReviwingViewGenerator = (): IWorkViewConfig => {
  const user = store.getState().user;
  return {
    id: TasksViewModes.Reviewing,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    viewAs: ViewAsTypes.board,
    emptyState:
      "Tasks will automatically show up here when you are the reviewer.",
    description: "Reviewing",
    customView: false,
    path: "/my-work/reviewing",
    title: "Reviewing",
    viewMode: TasksViewModes.Reviewing,
    presetMetadata: () => ({
      reviewerId: user ? user.id : undefined,
    }),
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
        reviewers: [user ? user.id : ""],
        type: [WorkTypes.TASK],
        hasActiveFilters: true,
      };
      return filters;
    },
  };
};

export const getMyProjectsViewGenerator = (): IWorkViewConfig => {
  const user = store.getState().user;
  const userGroups = store.getState().groups.userGroups;
  return {
    id: TasksViewModes.MyProjects,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    viewAs: ViewAsTypes.board,
    emptyState:
      "Any work assigned to you will show on this screen automatically.",
    description: "Keep track of work assigned to you",
    customView: false,
    path: "/view/new",
    title: "My Projects",
    viewMode: TasksViewModes.MyProjects,
    presetMetadata: () => ({
      assigneeId: user ? user.id : undefined,
      workType: WorkTypes.PROJECT,
      groupIds: userGroups.length > 0 ? [userGroups[0]] : [],
    }),
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
        assignees: [user ? user.id : ""],
        hasActiveFilters: true,
        type: [WorkTypes.PROJECT],
      };
      return filters;
    },
  };
};

const getThisWeekView = (context?: string) => {
  return {
    id: TasksViewModes.DueThisWeek,
    title: "Due this week",
    description: "Tasks due this week",
    emptyState: `All task due this week will show here`,
    path: "view/due_this_week",
    viewMode: TasksViewModes.DueThisWeek,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => {
      return {
        dueDate: getSunday(context ? new Date(context) : new Date()),
      };
    },
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
        dueDate: {
          isWeekView: true,
          isOverdue: null,
          after: null,
          before: null,
          exactly: null,
          not: null,
        },
        hasActiveFilters: true,
      };
      return filters;
    },
  };
};

const getNextCycle = (paneId: ChunkDestination) => {
  const groupId = getPaneGroupContext(paneId);

  const cylces =
    groupId && store.getState().work.groupCycles[groupId]
      ? store.getState().work.groupCycles[groupId]?.openTimeframeIds
      : [];
  const cycleId = cylces[1];
  const preset: IWorkViewConfig = {
    id: TasksViewModes.Cycle + cycleId,
    title: "Next sprint",
    description: "Tasks in the next sprint",
    emptyState: `All task in the next sprint will show here`,
    path: "sprint/next",
    viewMode: TasksViewModes.NextCycle,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    viewAs: ViewAsTypes.board,
    canPersistLocally: true,
    presetMetadata: () => {
      return {
        workSectionId: cycleId,
      } as Partial<IProjectObj>;
    },
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
      };
      return filters;
    },
  };
  if (!cycleId) {
    preset.forceEmptyState = true;
  } else {
    const cycle = store.getState().work.sections[cycleId];
    preset.title = `Next (${cycle.name})`;
    preset.forceEmptyState = false;
    preset.getFilters = () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
        cycles: [cycleId],
      };
      return filters;
    };
    preset.forceEmptyState = false;
  }
  return preset;
};

const getActiveCycle = (paneId: ChunkDestination) => {
  const groupId = getPaneGroupContext(paneId) ?? "";

  const cylces = store.getState().work.groupCycles[groupId]?.openTimeframeIds;
  const cycleId = cylces?.length > 0 ? cylces[0] : undefined;
  const preset: IWorkViewConfig = {
    id: TasksViewModes.Cycle + cycleId,
    title: "Active sprint",
    description: "See all tasks in the active sprint",
    emptyState: `The active sprint is the first one on the Sprints screen.`,
    path: "sprint/active",
    viewMode: TasksViewModes.ActiveCycle,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => {
      return {
        workSectionId: cycleId,
      } as Partial<IProjectObj>;
    },
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
      };
      return filters;
    },
  };
  if (!cycleId) {
    preset.forceEmptyState = true;
  } else {
    const cycle = store.getState().work.sections[cycleId];
    preset.getFilters = () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
        cycles: [cycleId],
      };
      return filters;
    };
    preset.title = `Active (${cycle.name})`;
    preset.forceEmptyState = false;
  }
  return preset;
};

const getCycle = (paneId: ChunkDestination, cycleId?: string) => {
  // const groupId = store.getState().client.groupContext.groupId;

  // if (!cycleId) return viewModeToConfigDict[TasksViewModes.OpenCycles];

  // const cycle = store.getState().work.sections[cycleId];

  // const cycles =
  //   store.getState().work.groupCycles[cycle.groupId].openTimeframeIds;
  // const activeCycleId = cycles[0];
  // const nextCycleId = cycles[1];

  // if (activeCycleId === cycleId) return getActiveCycle(paneId);
  // if (nextCycleId === cycleId) return getNextCycle(paneId);

  const preset: IWorkViewConfig = {
    id: TasksViewModes.Cycle + cycleId,
    title: "Sprint",
    description: "See all tasks in this sprint",
    emptyState: `There seem to be no tasks attached to this sprint`,
    path: "sprint/active",
    viewMode: TasksViewModes.Cycle,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => {
      return {
        workSectionId: cycleId,
      } as Partial<IProjectObj>;
    },
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
      };
      return filters;
    },
  };

  if (!cycleId) {
    preset.forceEmptyState = true;
  } else {
    const cycle = store.getState().work.sections[cycleId];
    preset.getFilters = () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
        cycles: [cycleId],
      };
      return filters;
    };
    preset.title = `${cycle.name}`;
    preset.forceEmptyState = false;
  }
  return preset;
};

const getProjectsWork = (workId: string) => {
  const preset: IWorkViewConfig = {
    id: TasksViewModes.ProjectWork + workId,
    title: "Projects work",
    description: "See all tasks in this work item",
    emptyState: `All work items with this parent will show here`,
    path: "sprints/active",
    viewMode: TasksViewModes.ProjectWork,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => {
      return {
        parentId: workId,
      } as Partial<IProjectObj>;
    },
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
        parents: [workId],
      };
      return filters;
    },
  };
  return preset;
};

export const defaultworkViewsDict: IWorkViewDict = {
  [TasksViewModes.Tasks]: {
    id: TasksViewModes.Tasks,
    title: "Tasks",
    emptyState:
      "Tasks you create in any document that belongs to this group will automatically show up here.",
    description: "All the tasks in this group",
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    path: "/tasks",
    viewMode: TasksViewModes.Tasks,
    viewAs: ViewAsTypes.board,
    showActiveProjects: true,
    presetMetadata: () => ({}),
    getFilters: () => {
      const closedStatusIds = getClosedStatuses();
      const filters: IFilterState = {
        ...generateNewChunk(),
        excludedStatuses: closedStatusIds,
        hasActiveFilters: true,
        type: [WorkTypes.TASK],
      };
      return filters;
    },
  },

  [TasksViewModes.Someday]: {
    id: "work",
    title: "Work",
    emptyState:
      "Tasks you create in any document that belongs to this group will automatically show up here.",
    description: "All the tasks in this group",
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    path: "/tasks",
    viewMode: TasksViewModes.Tasks,
    viewAs: ViewAsTypes.board,
    showActiveProjects: true,
    presetMetadata: () => ({}),
    extraBtns: () => {
      const showContributions = store.getState().workspace.showContributions;
      if (!showContributions) return [];

      return [];
    },
    getFilters: () => {
      const closedStatusIds = getClosedStatuses();
      const filters: IFilterState = {
        ...generateNewChunk(),
        excludedStatuses: closedStatusIds,
        hasActiveFilters: true,
        type: [WorkTypes.TASK],
      };
      return filters;
    },
  },

  [TasksViewModes.TasksArchive]: {
    id: "work",
    title: "Work",
    emptyState:
      "Tasks you create in any document that belongs to this group will automatically show up here.",
    description: "All the tasks in this group",
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.closeDate,
    path: "/tasks",
    viewMode: TasksViewModes.Tasks,
    viewAs: ViewAsTypes.list,
    showActiveProjects: true,
    presetMetadata: () => ({}),
    getFilters: () => {
      const closedStatusIds = getClosedStatuses();
      const filters: IFilterState = {
        ...generateNewChunk(),
        statuses: closedStatusIds,
        hasActiveFilters: true,
        type: [WorkTypes.TASK],
      };
      return filters;
    },
  },

  [TasksViewModes.NewCustomView]: {
    id: TasksViewModes.NewCustomView,
    groupBy: GroupTypes.status,
    orderBy: OrderTypes.priority,
    emptyState: "",
    description: "",
    customView: true,
    viewAs: ViewAsTypes.list,
    path: "/view/new",
    title: "New",
    viewMode: TasksViewModes.NewCustomView,
    presetMetadata: () => ({}),
    getFilters: () => {
      const filters: IFilterState = {
        ...generateNewChunk(),
      };
      return filters;
    },
  },

  [TasksViewModes.UnclaimedContributions]: {
    title: "Unclaimed",
    description: "Work with no assignee or lead contributor",
    emptyState:
      "Nothing to show. Work will automatically be collected here when it has a reward, but does not have an assignee or lead contributor.",
    path: PayoutsViewsNames.UnclaimedContributions,
    viewMode: TasksViewModes.UnclaimedContributions,
    groupBy: GroupTypes.priority,
    orderBy: OrderTypes.dueDate,
    id: TasksViewModes.UnclaimedContributions,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => ({}),
    getFilters: (paneId?: ChunkDestination) => {
      const groupId = getPaneGroupContext(paneId) ?? "";

      const filters: IFilterState = {
        ...generateNewChunk(),
        excludedAssignees: ["*"],
        isClosed: false,
        hasActiveFilters: true,
        hasReward: RewardFilter.HAS_REWARD,
        sponsorGroup: groupId,
      };
      return filters;
    },
  },

  [TasksViewModes.UnstartedContributions]: {
    id: TasksViewModes.UnstartedContributions,
    title: "Unstarted",
    description: "Assigned work with a status of To do",
    emptyState: `Nothing to show. Work will automatically be collected here when it has a reward, is assigned, and has a status of "To do".`,
    path: PayoutsViewsNames.UnstartedContributions,
    viewMode: TasksViewModes.UnstartedContributions,
    groupBy: GroupTypes.assignee,
    orderBy: OrderTypes.dueDate,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => ({}),
    getFilters: (paneId?: ChunkDestination) => {
      const groupId = getPaneGroupContext(paneId) ?? null;

      const statuses = store.getState().work.statuses;
      const statusObjs = statuses.statusArray.map(
        (statusId: string) => statuses.dict[statusId]
      );
      const includeStatuses = statusObjs
        .filter((status: IWorkStatus) => parseInt(status.categoryId) === 2)
        .map((status: IWorkStatus) => status.id);

      const filters: IFilterState = {
        ...generateNewChunk(),
        assignees: ["*"],
        statuses: includeStatuses,
        hasActiveFilters: true,
        hasReward: RewardFilter.IS_NOT_APPROVED,
        sponsorGroup: groupId,
      };
      return filters;
    },
  },

  [TasksViewModes.InProgressContributions]: {
    id: TasksViewModes.InProgressContributions,
    title: "In Progress",
    description: "Assigned work with a status of In Progress",
    emptyState: `Nothing to show. Work will automatically be collected here when it has a reward, is assigned, and has a status of "In Progress".`,
    path: PayoutsViewsNames.InProgressContributions,
    viewMode: TasksViewModes.InProgressContributions,
    groupBy: GroupTypes.assignee,
    orderBy: OrderTypes.dueDate,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => ({}),
    getFilters: (paneId?: ChunkDestination) => {
      const groupId = getPaneGroupContext(paneId) ?? null;

      const statuses = store.getState().work.statuses;
      const statusObjs = statuses.statusArray.map(
        (statusId: string) => statuses.dict[statusId]
      );
      const includeStatuses = statusObjs
        .filter((status: IWorkStatus) => parseInt(status.categoryId) === 3)
        .map((status: IWorkStatus) => status.id);

      const filters: IFilterState = {
        ...generateNewChunk(),
        assignees: ["*"],
        statuses: includeStatuses,
        hasActiveFilters: true,
        hasReward: RewardFilter.IS_NOT_APPROVED,
        sponsorGroup: groupId,
      };
      return filters;
    },
  },

  [TasksViewModes.AwitingRewardApprovalContributions]: {
    id: TasksViewModes.AwitingRewardApprovalContributions,
    title: "Awaiting Reward Approval",
    description: "Completed work with an unapproved reward",
    emptyState: `Nothing to show. Work will automatically be collected here when it has a reward, is assigned, and has a status of "Done".`,
    path: PayoutsViewsNames.AwitingRewardApprovalContributions,
    viewMode: TasksViewModes.AwitingRewardApprovalContributions,
    groupBy: GroupTypes.assignee,
    orderBy: OrderTypes.dueDate,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => ({}),
    getFilters: (paneId?: ChunkDestination) => {
      const groupId = getPaneGroupContext(paneId) ?? null;

      const statuses = store.getState().work.statuses;
      const statusObjs = statuses.statusArray.map(
        (statusId: string) => statuses.dict[statusId]
      );
      const includeStatuses = statusObjs
        .filter((status: IWorkStatus) => parseInt(status.categoryId) === 4)
        .map((status: IWorkStatus) => status.id);

      const filters: IFilterState = {
        ...generateNewChunk(),
        assignees: ["*"],
        statuses: includeStatuses,
        hasActiveFilters: true,
        hasReward: RewardFilter.IS_NOT_APPROVED,
        sponsorGroup: groupId,
      };
      return filters;
    },
  },

  [TasksViewModes.ApprovedForPaymentContributions]: {
    id: TasksViewModes.ApprovedForPaymentContributions,
    title: "Approved for Payment",
    description: "Completed work with an approved reward",
    emptyState: `Nothing to show. Work will automatically be collected here when it has a reward, is assigned, has a status of "Done", and the reward has been approved for payment.`,
    path: PayoutsViewsNames.ApprovedForPaymentContributions,
    viewMode: TasksViewModes.ApprovedForPaymentContributions,
    groupBy: GroupTypes.assignee,
    orderBy: OrderTypes.closeDate,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => ({}),
    getFilters: (paneId?: ChunkDestination) => {
      const groupId = getPaneGroupContext(paneId) ?? null;
      const filters: IFilterState = {
        ...generateNewChunk(),
        isDone: true,
        hasActiveFilters: true,
        assignees: ["*"],
        hasReward: RewardFilter.IS_APPROVED,
        sponsorGroup: groupId,
      };
      return filters;
    },
  },

  [TasksViewModes.PaidContributions]: {
    id: TasksViewModes.PaidContributions,
    title: "Paid",
    description: "Completed work with a paid reward",
    emptyState: `Nothing to show. Work will automatically be collected here when it has been marked as paid.`,
    path: PayoutsViewsNames.PaidContributions,
    viewMode: TasksViewModes.PaidContributions,
    groupBy: GroupTypes.assignee,
    orderBy: OrderTypes.closeDate,
    viewAs: ViewAsTypes.board,
    presetMetadata: () => ({}),
    getFilters: (paneId?: ChunkDestination) => {
      const groupId = getPaneGroupContext(paneId) ?? null;
      const filters: IFilterState = {
        ...generateNewChunk(),
        assignees: ["*"],
        hasActiveFilters: true,
        hasReward: RewardFilter.IS_PAID,
        sponsorGroup: groupId,
        isDone: true,
      };
      return filters;
    },
  },

  [TasksViewModes.OpenCycles]: {
    id: TasksViewModes.OpenCycles,
    title: "Sprints",
    description: "Work on a consistent rhythm",
    emptyState: `Use sprints to group tasks by the timeframe in which they will be completed.`,
    path: "/sprints",
    viewMode: TasksViewModes.OpenCycles,
    orderBy: OrderTypes.status,
    groupBy: GroupTypes.cycle,
    viewAs: ViewAsTypes.list,
    presetMetadata: () => ({}),
    getFilters: (paneId?: ChunkDestination) => {
      const state = store.getState();

      const groupContext = getPaneGroupContext(paneId) ?? "";

      const filters: IFilterState = {
        ...generateNewChunk(),
        cycles: state.work.groupCycles[groupContext]
          ? state.work.groupCycles[groupContext].openTimeframeIds
          : [],
        hasActiveFilters: true,
      };
      return filters;
    },
  },

  [TasksViewModes.ClosedCycles]: {
    id: TasksViewModes.ClosedCycles,
    title: "Sprints",
    description: "View your past sprints",
    emptyState: `Completed sprints will be moved here for safe keeping.`,
    path: "/sprints/closed",
    viewMode: TasksViewModes.ClosedCycles,
    orderBy: OrderTypes.status,
    groupBy: GroupTypes.cycle,
    viewAs: ViewAsTypes.list,
    presetMetadata: () => ({}),
    getFilters: (paneId?: ChunkDestination) => {
      const state = store.getState();
      const groupContext = getPaneGroupContext(paneId) ?? "";
      const filters: IFilterState = {
        ...generateNewChunk(),
        excludedCycles: state.work.groupCycles[groupContext]
          ? state.work.groupCycles[groupContext].openTimeframeIds
          : [],
        hasActiveFilters: true,
      };
      return filters;
    },
  },
};

export const viewModeToConfigDict = {
  [TasksViewModes.ActiveCycle]: (paneId: ChunkDestination) =>
    getActiveCycle(paneId),

  [TasksViewModes.Cycle]: (paneId: ChunkDestination, cycleId?: string) =>
    getCycle(paneId, cycleId),

  [TasksViewModes.ApprovedForPaymentContributions]: (
    paneId: ChunkDestination
  ) => defaultworkViewsDict[TasksViewModes.ApprovedForPaymentContributions],

  [TasksViewModes.AwitingRewardApprovalContributions]: (
    paneId: ChunkDestination
  ) => defaultworkViewsDict[TasksViewModes.AwitingRewardApprovalContributions],

  [TasksViewModes.ClosedCycles]: () =>
    defaultworkViewsDict[TasksViewModes.ClosedCycles],

  [TasksViewModes.CurrentTasks]: () =>
    defaultworkViewsDict[TasksViewModes.CurrentTasks],

  [TasksViewModes.CustomView]: (viewId?: string, context?: string) =>
    customWorkViewGenerator(viewId, context),

  [TasksViewModes.DueThisWeek]: (context?: string) => () =>
    getThisWeekView(context),

  [TasksViewModes.InProgressContributions]: (paneId: ChunkDestination) =>
    defaultworkViewsDict[TasksViewModes.InProgressContributions],

  [TasksViewModes.MyTasks]: () => getMyWorkViewGenerator(),

  [TasksViewModes.MyProjects]: () => getMyProjectsViewGenerator(),

  [TasksViewModes.NewCustomView]: () =>
    defaultworkViewsDict[TasksViewModes.NewCustomView],

  [TasksViewModes.NextCycle]: (paneId: ChunkDestination) =>
    getNextCycle(paneId),

  [TasksViewModes.OpenCycles]: () =>
    defaultworkViewsDict[TasksViewModes.OpenCycles],

  [TasksViewModes.PaidContributions]: () =>
    defaultworkViewsDict[TasksViewModes.PaidContributions],

  [TasksViewModes.ProjectWork]: (workId: string) => getProjectsWork(workId),

  [TasksViewModes.Tasks]: () => defaultworkViewsDict[TasksViewModes.Tasks],

  [TasksViewModes.TasksArchive]: () =>
    defaultworkViewsDict[TasksViewModes.TasksArchive],

  [TasksViewModes.UnclaimedContributions]: (paneId: ChunkDestination) =>
    defaultworkViewsDict[TasksViewModes.UnclaimedContributions],

  [TasksViewModes.UnstartedContributions]: (paneId: ChunkDestination) =>
    defaultworkViewsDict[TasksViewModes.UnstartedContributions],

  [TasksViewModes.Someday]: () => defaultworkViewsDict[TasksViewModes.Someday],

  [TasksViewModes.Reviewing]: () => getReviwingViewGenerator(),
};

export const notSavedLocallyViews = [
  TasksViewModes.NewCustomView,
  TasksViewModes.UnclaimedContributions,
  TasksViewModes.UnstartedContributions,
  TasksViewModes.PaidContributions,
  TasksViewModes.AwitingRewardApprovalContributions,
  TasksViewModes.ApprovedForPaymentContributions,
  TasksViewModes.InProgressContributions,
];
