import { ITokenRequirement, TokenTypes, UserEventTypes } from "utilities/types";
import Web3 from "web3";
import ERC20 from "../abis/erc20.json";
import ERC721 from "../abis/erc-721.json";
import ERC1155 from "../abis/erc-1155.json";
// import ENS from "../abis/ens.json";
// import ENS, { getEnsAddress } from "@ensdomains/ensjs";
import { SiweMessage, SignatureType } from "../siwe/siwe";
import * as Ethers from "ethers";
import { BehaviorSubject } from "rxjs";
import { axiosInstance } from "index";

export interface ITokenVerification {
  tokenReq: ITokenRequirement;
  success: boolean;
}

class Web3Api {
  web3;
  account: string | null | undefined;
  accountSubject: BehaviorSubject<string | null | undefined>;
  ensDataSubject: BehaviorSubject<any>;
  networkId: number;

  constructor() {
    const provider = Web3.givenProvider || "ws://localhost:8545";
    this.web3 = new Web3(provider);
    this.accountSubject = new BehaviorSubject<string | null | undefined>(null);
    this.ensDataSubject = new BehaviorSubject<any>(null);
    this.account = null;
    this.networkId = 1;
    this.pingProvider();
    this.setAccount();
  }

  async setAccount() {
    const account = await this.getAccount();
    this.account = account;
    const ensData = await this.getEnsData();
    if (this.accountSubject.value !== account) {
      this.accountSubject.next(account);
    }
    if (ensData) {
      this.ensDataSubject.next(ensData);
    }
    const networkId = await this.web3?.eth?.net
      .getId((error, id) => {
        if (id) return id;
        else return 1;
      })
      .catch((e) => {
        console.log("No wallet");
      });
    if (networkId) this.networkId = networkId;
  }

  validateAccount(account: string) {
    let isAccount = this.web3.utils.isAddress(account);
    if (!isAccount) {
      isAccount = account.endsWith(".eth");
    }

    return isAccount;
  }

  reinitializeData() {
    this.account = null;
    this.accountSubject.next(null);
    this.ensDataSubject.next(null);
  }

  async pingProvider() {
    const account = await this.getAccount();
    if (!account) {
      this.reinitializeData();
    }

    setTimeout(() => {
      this.pingProvider();
    }, 30000);
  }

  async recreateConnection(provider: any) {
    if (provider) {
      this.web3 = new Web3(provider);
    } else {
      const provider = Web3.givenProvider || "ws://localhost:8545";
      this.web3 = new Web3(provider);
    }
    this.setAccount();
  }

  async connectToAccount() {
    try {
      const requestedAccount = await this.web3.eth.requestAccounts();
      await this.setAccount();
      return requestedAccount;
    } catch (e) {
      // throw new Error("Connection to wallet refused");
    }
  }

  async getAccount() {
    try {
      const accounts = await this.web3.eth.getAccounts();
      return accounts[0];
    } catch (e) {
      await this.connectToAccount();
    }
  }

  async getEnsData() {
    try {
      const account = await this.getAccount();
      if (!account) return {};
      var provider = new Ethers.providers.Web3Provider(this.web3.givenProvider);
      let returnObj: any = {
        publicAddress: account,
      };
      const name = await provider.lookupAddress(account);
      if (name) {
        returnObj.name = name;
        const resolver = await provider.getResolver(name);
        const avatar = await provider.getAvatar(name);
        if (avatar) returnObj.avatar = avatar;
        if (resolver) {
          const email = await resolver.getText("email");
          if (email) returnObj.email = email;
          const description = await resolver.getText("description");
          if (description) returnObj.description = description;
        }
      }
      return returnObj;
    } catch (e) {
      return {};
    }
  }

  async signWithWallet(user: any) {
    const account = await this.getAccount();
    if (!account) return;
    const message = new SiweMessage({
      domain: document.location.host,
      address: account,
      chainId: `${await this.web3.eth.getChainId()}`,
      uri: document.location.origin,
      version: "1",
      statement: "Clarity Wallet Sign In",
      type: SignatureType.PERSONAL_SIGNATURE,
      nonce: user.nonce,
    });
    let signature;
    try {
      signature = await this.web3.eth.personal.sign(
        message.signMessage(),
        account,
        user.nonce,
        (err, signature) => {
          if (signature) return signature;
        }
      );
    } catch (e) {
      throw new Error("Signing canceled or unsuccessful");
    }

    message.signature = signature;
    (message as any).textMessage = message.signMessage();

    if (signature) {
      return axiosInstance
        .post("/api/auth/loginWithMetamask", {
          publicAccount: account,
          signature,
          message: {
            ...message,
          },
          nonce: user.nonce,
        })
        .then((res) => {
          return res.data;
        });
    }
  }

  async singupWithWallet(params: {
    email?: string;
    inviteToken?: string;
    roleId?: string;
    userId?: string;
    viralSignupType?: UserEventTypes;
    mode?: "organic" | "viral";
  }) {
    let account = await this.getAccount();
    if (!account) await this.connectToAccount();
    account = await this.getAccount();
    if (!account) throw Error("No account");
    const ensData = await this.getEnsData();
    const email = params.email
      ? params.email
      : ensData.email
      ? ensData.email
      : undefined;

    return axiosInstance
      .post("/api/auth/checkMetamaskLogin", {
        publicAdress: account,
        email,
        inviteToken: params.inviteToken,
        username: ensData.name,
        roleId: params.roleId,
        bio: ensData.description,
        avatar: ensData.avatar,
        userId: params.userId,
        mode: params.mode,
        viralSignupType: params.viralSignupType,
      })
      .then((res) => {
        if (res.data.nonce) {
          return this.signWithWallet(res.data);
        }
      });
  }

  async getRC20Tokens() {
    const tokens = await axiosInstance.get(
      "https://tokens.coingecko.com/uniswap/all.json"
    );
    return tokens.data;
  }

  async getERC721Tokens() {
    const tokens = await axiosInstance.get(
      "https://raw.githubusercontent.com/0xsequence/token-directory/main/index/mainnet/erc721.json"
    );
    return tokens.data;
  }

  async getTokenByAddress(contractAddress: string, tokenType: TokenTypes) {
    let web3Instance = this.web3;
    const recreateFunction = async () => {
      web3Instance = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider(
          "https://mainnet.infura.io/v3/283b0f72c9944c19a1c2a619fe69233e"
        )
      );
    };

    try {
      const chain = await web3Instance.eth.getChainId();
      if (!chain) {
        await recreateFunction();
      }
    } catch (err) {
      await recreateFunction();
    }
    try {
      if (tokenType === TokenTypes.ERC20) {
        const contract = new web3Instance.eth.Contract(
          ERC20 as any,
          contractAddress
        );
        if (contract) return await this.getTokenData(contract);
      }
      if (tokenType === TokenTypes.ERC721) {
        const contract = new web3Instance.eth.Contract(
          ERC721 as any,
          contractAddress
        );
        if (contract) return await this.getTokenData(contract);
      }
      if (tokenType === TokenTypes.ERC1155) {
        const contract = new web3Instance.eth.Contract(
          ERC1155 as any,
          contractAddress
        );
        if (contract) return await this.getTokenData(contract);
      }
    } catch (err) {
      return false;
    }
  }

  async getTokenData(contract: any) {
    const tokenData: Partial<ITokenRequirement> = {};
    await contract.methods.name().call((err: any, val: any) => {
      if (val) tokenData.name = val;
    });

    await contract.methods.symbol().call((err: any, val: any) => {
      if (val) tokenData.symbol = val;
    });

    return tokenData;
  }

  createContract(contractAddress: string, account: string, contractType: any) {
    const contract = new this.web3.eth.Contract(
      contractType as any,
      contractAddress,
      {
        from: account,
      }
    );
    return contract;
  }

  async checkHasTokens(tokenRequirements: ITokenRequirement[]) {
    const account = await this.getAccount();
    if (!account) {
      const res: ITokenVerification[] = [
        {
          success: false,
          tokenReq: {
            ammount: 0,
            contractAddress: "",
            networkId: 1,
            symbol: "",
            name: "",
            type: TokenTypes.ERC20,
          },
        },
      ];
      return res;
    }

    const tokenStatuses: ITokenVerification[] = [];
    const checkAmmount = (token: ITokenRequirement, balance: number) => {
      if (balance >= token.ammount) {
        return {
          tokenReq: token,
          success: true,
        };
      } else {
        return {
          tokenReq: token,
          success: false,
        };
      }
    };

    const checkToken = async (token: ITokenRequirement) => {
      switch (token.type) {
        case TokenTypes.ERC20: {
          const contract = this.createContract(
            token.contractAddress,
            account,
            ERC20
          );
          const tokenBalance = await contract.methods
            .balanceOf(account)
            .call()
            .catch(0);
          const tokenBalanceInEth = this.web3.utils.fromWei(tokenBalance);
          return checkAmmount(token, Number(tokenBalanceInEth));
        }
        case TokenTypes.ERC721: {
          const contract = this.createContract(
            token.contractAddress,
            account,
            ERC721
          );
          const tokenBalance = await contract.methods
            .balanceOf(account)
            .call()
            .catch(0);

          return checkAmmount(token, tokenBalance);
        }
        case TokenTypes.ERC1155: {
          const contract = this.createContract(
            token.contractAddress,
            account,
            ERC721
          );
          const tokenBalance = await contract.methods
            .balanceOf(account)
            .call()
            .catch(0);
          return checkAmmount(token, tokenBalance);
        }
        case TokenTypes.NATIVE_CURRENCY: {
          const balance = await this.web3.eth.getBalance(account);
          const balanceInEth = this.web3.utils.fromWei(balance);
          return {
            tokenReq: token,
            success: Number(balanceInEth) >= token.ammount,
          };
        }
      }
    };
    for (const tokenReq of tokenRequirements) {
      const res = await checkToken(tokenReq);
      if (res) tokenStatuses.push(res);
    }
    return tokenStatuses;
  }

  async checkIfTokenExists(
    networkId: number,
    contractAddress: string,
    tokenType: TokenTypes
  ) {
    const walletAddress = await this.getAccount();
    return axiosInstance
      .post("/api/web3-utilities/checkToken", {
        networkId,
        contractAddress,
        tokenType,
        walletAddress,
      })
      .then((res) => {
        if (res.data) {
          return {
            name: res.data.name,
            symbol: res.data.symbol,
            icon: res.data.icon,
          };
        }
      });
  }

  async checkTokenRequirements(tokenRequirements: ITokenRequirement[]) {
    const walletAddress = await this.getAccount();
    return axiosInstance
      .post("/api/web3-utilities/hasTokens", {
        tokenRequirements,
        walletAddress,
      })
      .then((res) => {
        if (res.data) {
          return res.data as ITokenVerification[];
        } else {
          return [
            {
              success: false,
            },
          ] as ITokenVerification[];
        }
      });
  }

  async getMainNetNfts(chainId: number) {
    return axiosInstance.get(`/api/web3-utilities/nfts/${chainId}`);
  }
}

export const web3Api = new Web3Api();
