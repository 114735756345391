import Icon from "@ant-design/icons";
import React from "react";

export default function ClosedGroup(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      width="1.1em"
      height="1.1em"
      viewBox="0 0 18 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.1"
        cx="8"
        cy="8"
        r="6"
        fill="currentColor"
        fillOpacity="0.65"
      />
      <circle
        cx="8.00095"
        cy="5.67496"
        r="1.42576"
        fill="currentColor"
        fillOpacity="0.65"
      />

      <ellipse
        cx="5.69754"
        cy="9.6632"
        rx="1.42548"
        ry="1.42548"
        transform="rotate(-120 5.69754 9.6632)"
        fill="currentColor"
        fillOpacity="0.65"
      />

      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M1 8C1 4.13438 4.13438 1 8 1C11.1707 1 13.8494 3.10869 14.7101 6H13.4591C12.6435 3.77547 10.5067 2.1875 8 2.1875C4.79063 2.1875 2.1875 4.79063 2.1875 8C2.1875 11.2094 4.79063 13.8125 8 13.8125V14C8 14.3623 8.09631 14.702 8.26473 14.9951C8.17689 14.9984 8.08864 15 8 15C4.13438 15 1 11.8656 1 8Z"
        fill="currentColor"
        fillOpacity="0.65"
      />

      <path
        d="M16.125 10.5312H15.4609V8.34375C15.4609 7.65332 14.9014 7.09375 14.2109 7.09375H11.7891C11.0986 7.09375 10.5391 7.65332 10.5391 8.34375V10.5312H9.875C9.70215 10.5312 9.5625 10.6709 9.5625 10.8438V14.5938C9.5625 14.7666 9.70215 14.9062 9.875 14.9062H16.125C16.2979 14.9062 16.4375 14.7666 16.4375 14.5938V10.8438C16.4375 10.6709 16.2979 10.5312 16.125 10.5312ZM13.2734 12.8457V13.3633C13.2734 13.4062 13.2383 13.4414 13.1953 13.4414H12.8047C12.7617 13.4414 12.7266 13.4062 12.7266 13.3633V12.8457C12.646 12.7878 12.5858 12.7059 12.5547 12.6116C12.5237 12.5174 12.5233 12.4157 12.5537 12.3212C12.5841 12.2268 12.6437 12.1444 12.7239 12.0859C12.8041 12.0275 12.9008 11.996 13 11.996C13.0992 11.996 13.1959 12.0275 13.2761 12.0859C13.3563 12.1444 13.4159 12.2268 13.4463 12.3212C13.4767 12.4157 13.4763 12.5174 13.4453 12.6116C13.4142 12.7059 13.354 12.7878 13.2734 12.8457ZM14.7578 10.5312H11.2422V8.34375C11.2422 8.04199 11.4873 7.79688 11.7891 7.79688H14.2109C14.5127 7.79688 14.7578 8.04199 14.7578 8.34375V10.5312Z"
        fill="currentColor"
        fillOpacity="0.65"
      />
    </svg>
  );
}
