import Icon from "@ant-design/icons";
import React from "react";

export default function CheckCircleTwoTone(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
        fill="currentColor"
      />
      <path
        opacity="0.1"
        d="M12 3.28125C7.18594 3.28125 3.28125 7.18594 3.28125 12C3.28125 16.8141 7.18594 20.7188 12 20.7188C16.8141 20.7188 20.7188 16.8141 20.7188 12C20.7188 7.18594 16.8141 3.28125 12 3.28125ZM16.5328 8.57109L11.5969 15.4148C11.5279 15.5111 11.4369 15.5896 11.3316 15.6437C11.2262 15.6978 11.1095 15.7261 10.991 15.7261C10.8726 15.7261 10.7558 15.6978 10.6505 15.6437C10.5451 15.5896 10.4541 15.5111 10.3852 15.4148L7.46484 11.3648C7.37578 11.2406 7.46484 11.0672 7.61719 11.0672H8.71641C8.95781 11.0672 9.18281 11.1844 9.32344 11.3789L10.9922 13.6945L14.6766 8.58516C14.8172 8.38828 15.0445 8.27344 15.2836 8.27344H16.3828C16.5352 8.27344 16.6242 8.44688 16.5328 8.57109Z"
        fill="currentColor"
      />
      <path
        d="M16.3819 8.27344H15.2827C15.0436 8.27344 14.8163 8.38828 14.6757 8.58516L10.9913 13.6945L9.32252 11.3789C9.18189 11.1844 8.95689 11.0672 8.71549 11.0672H7.61626C7.46392 11.0672 7.37486 11.2406 7.46392 11.3648L10.3842 15.4148C10.4532 15.5111 10.5442 15.5896 10.6495 15.6437C10.7549 15.6978 10.8717 15.7261 10.9901 15.7261C11.1086 15.7261 11.2253 15.6978 11.3307 15.6437C11.436 15.5896 11.527 15.5111 11.596 15.4148L16.5319 8.57109C16.6233 8.44688 16.5343 8.27344 16.3819 8.27344Z"
        fill="currentColor"
      />
    </svg>
  );
}
