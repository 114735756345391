import {
  REMOVE_DOCUMENT_SIDEEFFECT,
  REMOVE_NOTE_SIDEEFFECT,
  REMOVE_SNIPPET_SIDEEFFECT,
  REMOVE_TEMPLATE_SIDEEFFECT,
  REMOVE_VIEW_SIDEEFFECT,
  REMOVE_WORKITEM_SIDEEFFECT,
  SET_LAST_VISITED_BASE,
  SET_LAST_VISITED_GROUP_LOCATION,
  SET_LAST_VISITED_HOME_LOCATION,
  SET_LAST_VISITED_LOCATION,
  SET_RECENTLY_OPENED,
} from "store/actions";
import {
  GeneralViewsNames,
  GroupGeneralViews,
  NavigationChunk,
  RecentlyOpened,
  ViewNameType,
} from "utilities/types";

interface IInitialState {
  dict: { [baseId: string]: RecentlyOpened[] };
  lastVisitedPage: RecentlyOpened;
  lastVisitedBase: string;
  lastVisitedGroupLocation: {
    [baseId: string]: {
      [groupSlug: string]: NavigationChunk;
    };
  };
  lastVisitedHomeLocation: {
    [baseId: string]: NavigationChunk;
  };
  lastVisitedLocation: {
    [baseId: string]: NavigationChunk;
  };
}
const initialState: IInitialState = {
  dict: {},
  lastVisitedPage: {} as RecentlyOpened,
  lastVisitedBase: "",
  lastVisitedGroupLocation: {},
  lastVisitedHomeLocation: {},
  lastVisitedLocation: {},
};

interface ISetRecentlyOpened {
  type: string;
  baseId: string;
  recentlyOpened: RecentlyOpened[];
}

interface ISetLastVisitedBase {
  type: string;
  param: {
    baseId: string;
  };
}

interface ISetLastVisitedGroupLocation {
  type: string;
  baseId: string;
  groupSlug: string;
  location: NavigationChunk;
}

interface IDeleteNote {
  type: string;
  containerId: string;
  baseId: string;
}
export default function recentlyOpenedReducer(
  state: IInitialState = initialState,
  action: ISetRecentlyOpened &
    ISetLastVisitedBase &
    IDeleteNote &
    ISetLastVisitedGroupLocation
) {
  switch (action.type) {
    case SET_RECENTLY_OPENED: {
      const newState = {
        ...state,
        dict: {
          ...state.dict,
        },
      };
      if (Array.isArray(action.recentlyOpened) && action.baseId) {
        newState.dict[action.baseId] = [...action.recentlyOpened];
        return newState;
      }
      return state;
    }
    case SET_LAST_VISITED_BASE: {
      return {
        ...state,
        lastVisitedBase: action.param.baseId,
      };
    }
    case REMOVE_WORKITEM_SIDEEFFECT:
    case REMOVE_VIEW_SIDEEFFECT:
    case REMOVE_DOCUMENT_SIDEEFFECT:
    case REMOVE_TEMPLATE_SIDEEFFECT:
    case REMOVE_SNIPPET_SIDEEFFECT:
    case REMOVE_NOTE_SIDEEFFECT: {
      const newState = { ...state, dict: { ...state.dict } };

      const newArray = newState.dict[action.baseId].filter(
        (item) =>
          item.navigationChunk.entity?.containerId !== action.containerId
      );
      newState.dict[action.baseId] = [...newArray];
      return newState;
    }

    case SET_LAST_VISITED_LOCATION: {
      return {
        ...state,
        lastVisitedLocation: {
          ...state.lastVisitedLocation,
          [action.baseId]: {
            ...action.location,
          },
        },
      };
    }

    case SET_LAST_VISITED_GROUP_LOCATION: {
      const newState = {
        ...state,
        lastVisitedGroupLocation: {
          ...state.lastVisitedGroupLocation,
        },
      };
      if (newState.lastVisitedGroupLocation[action.baseId]) {
        newState.lastVisitedGroupLocation[action.baseId][action.groupSlug] = {
          ...action.location,
        };
      } else {
        newState.lastVisitedGroupLocation[action.baseId] = {
          [action.groupSlug]: {
            ...action.location,
          },
        };
      }
      return newState;
    }

    case SET_LAST_VISITED_HOME_LOCATION: {
      const newState = {
        ...state,
        lastVisitedHomeLocation: {
          ...state.lastVisitedHomeLocation,
        },
      };
      if (newState.lastVisitedHomeLocation[action.baseId]) {
        newState.lastVisitedHomeLocation[action.baseId] = {
          ...action.location,
        };
      } else {
        newState.lastVisitedHomeLocation[action.baseId] = {
          ...action.location,
        };
      }
      return newState;
    }

    default: {
      return state;
    }
  }
}

export const groupGeneralTabs: ViewNameType[] = [
  GroupGeneralViews.GroupProfile,
  GroupGeneralViews.Notes,
  GroupGeneralViews.Cycles,
  GroupGeneralViews.Tasks,
  GroupGeneralViews.Projects,
  GroupGeneralViews.ProjectsArchive,
];

export const homeGeneralTabs: ViewNameType[] = [
  GeneralViewsNames.Home,
  GeneralViewsNames.Roadmap,
  GeneralViewsNames.RoadmapArchive,
  // GeneralViewsNames.Roles,
  // GeneralViewsNames.Groups,
  GeneralViewsNames.Wiki,
];
