import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TasksViewRouter from "screens/base/main/TasksViewRouter";
import NavigationContext from "screens/base/NavigationContext";
import {
  ContainerTypes,
  GroupGeneralViews,
  TasksViewModes,
  ViewNames,
  WorkTypes,
} from "utilities/types";

const DetailRouter: React.FC = () => {
  return (
    <Switch>
      <Redirect
        from="/:baseSlug/notes/:containerId"
        to="/:baseSlug/docs/:containerId"
      />
      <Route
        path="/:baseSlug/docs/:containerId"
        render={(props) => (
          <NavigationContext
            viewName={ViewNames.Detail}
            containerId={props.match.params.containerId}
            containerType={ContainerTypes.NOTE}
          />
        )}
      />
      <Route
        path="/:baseSlug/project/:containerId"
        render={(props) => (
          <NavigationContext
            viewName={ViewNames.Detail}
            containerId={props.match.params.containerId}
            containerType={ContainerTypes.WORK}
          />
        )}
      />
      <Route
        path="/:baseSlug/work/:groupContainerId"
        render={(props) => (
          <NavigationContext
            viewName={ViewNames.Detail}
            containerId={props.match.params.groupContainerId}
            containerType={ContainerTypes.WORK}
            workType={WorkTypes.TASK}
          />
        )}
      />

      <Redirect
        from="/:baseSlug/pages/:containerId"
        to="/:baseSlug/tags/:containerId"
      />

      <Route
        path="/:baseSlug/tags/:containerId"
        render={(props) => (
          <NavigationContext
            viewName={ViewNames.Detail}
            containerId={props.match.params.containerId}
            containerType={ContainerTypes.DOCUMENT}
          />
        )}
      />
      <Route
        path="/:baseSlug/template/:containerId"
        render={(props) => {
          return (
            <NavigationContext
              viewName={ViewNames.Detail}
              containerId={props.match.params.containerId}
              containerType={ContainerTypes.TEMPLATE}
            />
          );
        }}
      />
      <Route
        path="/:baseSlug/snippet/:containerId"
        render={(props) => {
          return (
            <NavigationContext
              viewName={ViewNames.Detail}
              containerId={props.match.params.containerId}
              containerType={ContainerTypes.SNIPPET}
            />
          );
        }}
      />
      <Redirect
        from="/:baseSlug/cycle/:cycleId"
        to="/:baseSlug/sprint/:cycleId"
      />
      <Route
        path="/:baseSlug/sprint/:sprint"
        render={(props) => {
          return (
            <NavigationContext
              viewName={GroupGeneralViews.CycleEntity}
              taskViewMode={TasksViewModes.Cycle}
              containerId={props.match.params.sprint}
            />
          );
        }}
      />
      <Route
        path="/:baseSlug/view/:viewId"
        render={() => {
          return <TasksViewRouter />;
        }}
      />
    </Switch>
  );
};

export default DetailRouter;
