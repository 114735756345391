import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { IProjectObj } from "utilities/types";

interface Props {
  cycleId: string;
}

const CycleTitle: React.FC<Props> = (props) => {
  const cycle: IProjectObj | null = useSelector((state: any) => {
    return state.work.sections[props.cycleId];
  }, shallowEqual);

  if (cycle)
    return (
      <span className={"small cycleTitle"}>
        <span className="disabled small">{cycle.name}</span>
      </span>
    );
  else return <></>;
};

export default CycleTitle;
