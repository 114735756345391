import { FileDoneOutlined } from "@ant-design/icons";
import DueDateDisplay from "clarity-ui/DueDateDisplay";
import UserDisplay from "clarity-ui/UserDisplay";
import navigationApi from "clientApi/navigationApi";
import Conditional from "components/Conditional";
import { getHtml } from "editor/utils/blockValueHelpers";
import { sortByMetadata } from "modules/taskService";
import React, { useCallback, useEffect, useState } from "react";
import { useProjectIds, useWork } from "store/reducers/workReducer";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { stripHtml } from "utilities/stringUtilities";
import { ContainerTypes, OrderTypes, ViewNames } from "utilities/types";

const ActiveProjects: React.FC<{
  paneId: ChunkDestination;
  groupId?: string;
}> = ({ paneId, groupId }) => {
  const activeProjectsIds = useActiveProjects(groupId);
  return (
    <div className="homeWidget">
      <h4 className="h4 bold primary homeWidget__title">Active Projects</h4>
      <Conditional on={activeProjectsIds.length === 0}>
        <span className="disabled">
          In progress projects wills how here automatically
        </span>
      </Conditional>
      <Conditional on={activeProjectsIds.length > 0}>
        <>
          {activeProjectsIds.map((id) => (
            <ProjectItem key={id} id={id} paneId={paneId} />
          ))}
        </>
      </Conditional>
    </div>
  );
};

const ProjectItem: React.FC<{ id: string; paneId: ChunkDestination }> = ({
  id,
  paneId,
}) => {
  const goal = useShallowSelector((store) => store.work.dict[id]);
  const navigate = useCallback(
    (e: React.MouseEvent) => {
      navigationApi.contextBasedNavigate({
        currentPane: paneId,
        shiftKey: e.shiftKey,
        forceToPane: ChunkDestination.peek,
        navigationChunk: {
          viewName: ViewNames.Detail,
          entity: {
            containerId: id,
            containerType: ContainerTypes.WORK,
          },
        },
      });

      e.stopPropagation();
      e.preventDefault();
    },
    [goal?.projectId]
  );
  if (!goal) return <></>;
  return (
    <div className="homeGoalItem homeWidget__item">
      <div className="homeGoalItem__head">
        <span className="homeGoalItem__head__icon">
          <FileDoneOutlined className="secondary" />
        </span>
        <span
          className="body medium primary underlineHover homeGoalItem__head__title"
          onClick={navigate}
        >
          {stripHtml(getHtml(goal.nameValue))}
        </span>
      </div>
      <div className="homeGoalItem__tail">
        <Conditional on={goal.dueDate}>
          <span className={"homeGoalItem__tail__initiativeDueDate"}>
            <DueDateDisplay
              dueDate={goal.dueDate}
              isClosed={goal.isClosed}
              editId={undefined}
              extraClass={
                "homeGoalItem__tail__initiativeDueDate__dueDate medium caption"
              }
            />
          </span>
        </Conditional>
        <span className={"homeGoalItem__tail__initiativeLead"}>
          <UserDisplay
            id={goal.assigneeId}
            hideName={true}
            avatarSize={22}
            hideTooltip={true}
          />
        </span>
      </div>
    </div>
  );
};

export const useActiveProjects = (groupId?: string) => {
  const [activeProjectIds, setactiveProjectIds] = useState<string[]>([]);
  const projects = useProjectIds();
  const work = useWork();

  useEffect(() => {
    const activeProjects = projects.filter((id) => {
      const workItem = work.workDict[id];
      if (!workItem) return false;
      if (!workItem.statusId) return false;
      if (groupId && !workItem.groupIds?.includes(groupId)) return false;
      const status = work.statuses.dict[workItem.statusId];
      if (!status) return false;
      if (status.categoryId === "3") return true;
      return false;
    });
    sortByMetadata(OrderTypes.dueDate, activeProjects);
    if (JSON.stringify(activeProjects) !== JSON.stringify(activeProjectIds))
      setactiveProjectIds(activeProjects);
  }, [work.workDict, projects]);

  return activeProjectIds;
};

export default ActiveProjects;
