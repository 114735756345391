import {
  SET_ACTIVE_WORKSPACE,
  SET_BASE_TEMPLATES,
  UPDATE_MULTIPLE_TEMPLATE_ITEMS,
  UPDATE_TEMPLATE,
} from "store/actions";
import store from "store/storeExporter";
import { ITemplateObj } from "utilities/types";

interface ITemplateReducerState {
  dict: TemplateDict;
  rootTemplateIds: string[];
}

export interface TemplateDict {
  [id: string]: ITemplateObj;
}

const initialState: ITemplateReducerState = {
  dict: {},
  rootTemplateIds: [],
};

interface IUpdateTemplateParam {
  id: string;
  type: "add" | "delete" | "update";
  delta: Partial<ITemplateObj>;
  skipInsertInList: boolean;
}

interface IUpdateMultipleTemplateParam {
  templates: ITemplateObj[];
}

interface updateTemplateAction {
  type: string;
  param: IUpdateTemplateParam;
}

interface updateMultipleTemplateAction {
  type: string;
  param: IUpdateMultipleTemplateParam;
}

interface SetBaseTemplatesAction {
  type: string;
  param: {
    templates: ITemplateObj[];
  };
}

export default function templatesReducer(
  state: ITemplateReducerState = initialState,
  action: updateTemplateAction &
    SetBaseTemplatesAction &
    updateMultipleTemplateAction
) {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      return initialState;
    }

    case SET_BASE_TEMPLATES: {
      if (action.param.templates) {
        const newState: ITemplateReducerState = {
          rootTemplateIds: [],
          dict: {},
        };

        action.param.templates.forEach((template) => {
          if (template.isRoot) {
            newState.rootTemplateIds.push(template.id);
          }

          newState.dict[template.id] = template;
        });

        return newState;
      }
      return state;
    }

    case UPDATE_TEMPLATE: {
      const newState = { ...state };
      newState.dict = { ...newState.dict };
      if (action.param.type === "add") {
        const templateObj = action.param.delta;
        if (templateObj.isRoot && !action.param.skipInsertInList) {
          if (!newState.rootTemplateIds.includes(action.param.id)) {
            newState.rootTemplateIds = [...newState.rootTemplateIds];
            newState.rootTemplateIds.splice(0, 0, action.param.id);
          }
        }

        newState.dict[action.param.id] = action.param.delta as ITemplateObj;
        return newState;
      }
      if (action.param.type === "delete") {
        const template = newState.dict[action.param.id];
        if (!template) return state;
        if (template.isRoot) {
          newState.rootTemplateIds = [...newState.rootTemplateIds];
          const id = action.param.id;
          const index = newState.rootTemplateIds.indexOf(id);
          if (index > -1) {
            newState.rootTemplateIds.splice(index, 1);
          }
        }

        delete newState.dict[action.param.id];
        return newState;
      }
      if (action.param.type === "update") {
        if (!newState.dict[action.param.id]) return state;
        newState.dict[action.param.id] = {
          ...newState.dict[action.param.id],
          ...action.param.delta,
        };
        return newState;
      }
      return initialState;
    }

    case UPDATE_MULTIPLE_TEMPLATE_ITEMS: {
      if (action.param.templates && Array.isArray(action.param.templates)) {
        const newState = { ...state, dict: { ...state.dict } }; // if (action.workItem.workType === WorkTypes.TASK)

        for (const template of action.param.templates) {
          if (!template.id) {
            continue;
          }
          const updatedTemplate = template;

          if (state.dict[updatedTemplate.id]) {
            newState.dict[updatedTemplate.id] = {
              ...newState.dict[updatedTemplate.id],
              ...updatedTemplate,
            };
          } else {
            newState.dict[updatedTemplate.id] = updatedTemplate;
          }
        }
        return newState;
      }
      return state;
    }
    default:
      return state;
  }
}

export const updateTemplateItemAction = (param: IUpdateTemplateParam) => {
  store.dispatch({
    type: UPDATE_TEMPLATE,
    param,
  });
};

export const multipleUpdateTemplateItemsAction = (
  param: IUpdateMultipleTemplateParam
) => {
  store.dispatch({
    type: UPDATE_MULTIPLE_TEMPLATE_ITEMS,
    param,
  });
};
