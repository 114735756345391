import { Avatar, Tooltip } from "antd";
import React, { useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { userApi } from "clientApi/userApi";
import styles from "./userDisplay/userDisplay.module.scss";
import Conditional from "components/Conditional";
import { useShallowSelector } from "utilities/hooks";

interface Props {
  id?: string | null;
  customStyles?: any;
  hideName?: boolean;
  hideAvatar?: boolean;
  avatarSize?: number;
  labelClassname?: string;
  caption?: string;
  avatarStyles?: React.CSSProperties;
  captionStyles?: React.CSSProperties;
  captionClassName?: string;
  shape?: "circle" | "square";
  borderColor?: "black" | "white";
  disabled?: boolean;
  hideTooltip?: boolean;
  showPresence?: boolean;
}

export const usernameSplitter = (username: string | null) => {
  if (!username) return "";
  if (username.length > 20) {
    const start = username.substr(0, 6);
    const end = username.substr(username.length - 4, username.length - 1);
    return start + "..." + end;
  } else return username;
};

const UserDisplay: React.FC<Props> = (props) => {
  const user = useShallowSelector((state) => {
    if (!props.id) return null;
    if (state.members.dict[props.id]) return state.members.dict[props.id];
    if (props.id === state.user?.id) return state.user;
    return;
  });

  const borderColor = props.borderColor || "black";

  useEffect(() => {
    if (props.id && !user?.id) {
      userApi.getUser(props.id);
    }
  }, [props.id]);

  if (!user?.id) {
    const size = props.avatarSize ?? 20;
    const shape = props.shape || "square";
    const style: React.CSSProperties = props.customStyles
      ? {
          ...props.customStyles,
          display: "flex",
          alignItems: "center",
          // justifyContent: "center",
        }
      : {
          display: "flex",
          alignItems: "center",
          // justifyContent: "center",
        };
    return (
      <span
        style={style}
        className={`${styles.user} disabled ${
          (props.hideName ? styles.user_nomargin : null,
          props.disabled ? styles.user_disabled : null)
        }`}
      >
        <Conditional on={!props.hideAvatar}>
          <UserOutlined
            className={styles.userImage}
            shape={shape}
            style={{
              height: `${size}px`,
              width: `${size}px`,
              fontSize: props.avatarSize ? props.avatarSize * 0.9 : 14,
              marginRight: "-2px",
              marginTop: "-2px",
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
              ...(props.avatarStyles ?? {}),
            }}
          />
        </Conditional>

        {!props.hideName && <span>Anonymous</span>}
      </span>
    );
  }
  return (
    <span
      className={`
         ${styles.user} 
         ${props.hideName ? styles.user_nomargin : null}
         ${props.disabled ? styles.user_disabled : null}
         ${user.avatar ? styles.user_border : null}
         ${borderColor ? styles[`user_border_${borderColor}`] : null}
         
         `}
      style={props.customStyles ? props.customStyles : {}}
    >
      <Conditional on={!props.hideAvatar}>
        <Tooltip
          title={user?.name || `@${user?.username}`}
          visible={props.hideTooltip ? false : undefined}
        >
          <span className={styles.userImageContainer}>
            <Avatar
              className={styles.userImage}
              src={user.avatar}
              style={{
                ...(props.avatarStyles ?? {}),
                position: "relative",
              }}
              size={props.avatarSize ?? 20}
              shape={props.shape}
            ></Avatar>
            {props.id && props.showPresence && (
              <PresenceIndicator id={props.id} />
            )}
          </span>
        </Tooltip>
      </Conditional>

      {!props.hideName && (
        <span className={`caption regular secondary ${props.labelClassname}`}>
          {usernameSplitter(user.name || "@" + user.username)}
        </span>
      )}

      <Conditional on={props.caption}>
        <span
          className={
            props.captionClassName ? props.captionClassName : styles.caption
          }
          style={props.captionStyles}
        >
          {props.caption}
        </span>
      </Conditional>
    </span>
  );
};

const PresenceIndicator: React.FC<{ id: string }> = ({ id }) => {
  const userPresence = useShallowSelector((store) =>
    store.members.onlineMembers.includes(id)
  );
  return userPresence ? (
    <div className={`${styles.indicatorSvg} ${styles.presenceIndicator}`} />
  ) : (
    <></>
  );
};

export default UserDisplay;
