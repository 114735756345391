import { Divider, Dropdown, Menu, Switch } from "antd";
import Button, { ButtonTypes } from "components/Button";
import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  RESET_ROADMAP_VIEW,
  TOGGLE_CLOSED_PROJECT,
  UPDATE_ROADMAP_MILESTONE_MODE,
} from "store/actions";
import store from "store/storeExporter";
import { ControlOutlined, DownOutlined } from "@ant-design/icons";
import { useShallowSelector } from "utilities/hooks";
import { Menu as FabMenu } from "components/FabBtn";
import TopnavButtonsStyles from "../defaultWorkView/topnavBtns/topnavBtns.module.scss";
import {
  DEFAULT_EMPTY_MILESTONE_MODE,
  RoadmapMilestonesMode,
} from "store/reducers/roadmapFilterReducer";
import { useIsRoadmapViewActive } from "./hooks";
import { ChunkDestination } from "utilities/stateTypes";
import ProjectsMenuBtn from "components/AddProjectMenu";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities } from "utilities/types";
import roadmapStyles from "./roadmap.module.scss";
import Conditional from "components/Conditional";
import Modal from "clarity-ui/Modal";
import ReactDOM from "react-dom";
import MilestoneEditor from "./MilestoneEditor";
import GroupWorkSubroutes from "../home/subroutes/GroupWorkSubroutes";

const RoadmapTopNavFilters: React.FC<{
  viewId?: string;
  baseId: string;
  paneId: ChunkDestination;
  groupId?: string;
}> = ({ viewId, baseId, paneId, groupId }) => {
  const showMilestones = useShallowSelector(
    (store) => store.workspace.showMilestones
  );
  const [openMenu, setopenMenu] = useState(false);
  const [editMilestones, seteditMilestones] = useState(false);

  const isActive = useIsRoadmapViewActive(baseId, viewId);

  const containerRef = useRef<HTMLDivElement>(null);
  const menuBtnRef = useRef<HTMLDivElement>(null);

  const toggleMenu = useCallback(() => {
    setopenMenu((current) => !current);
  }, []);

  const canManageMilestones = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MILESTONES,
  });

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupId,
  });

  return (
    <TopNavFiltersBar paneId={paneId}>
      {groupId && <GroupWorkSubroutes groupId={groupId} paneId={paneId} />}
      <div
        ref={containerRef}
        style={{
          position: "relative",
          display: "flex",
          gap: "8px",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div ref={menuBtnRef} style={{ position: "relative" }}>
          <Button
            buttonType={ButtonTypes.LINK}
            icon={<ControlOutlined />}
            onClick={toggleMenu}
            className={
              isActive
                ? TopnavButtonsStyles.customStyles
                : TopnavButtonsStyles.iconSize
            }
          />
          {openMenu && (
            <FabMenu
              slim
              noFocus
              className={TopnavButtonsStyles.menu}
              clickRef={menuBtnRef}
              allowClickOn={["allowClick"]}
              close={toggleMenu}
              yDir="down"
              xDir="left"
            >
              <MenuContent
                containerRef={menuBtnRef}
                hasChanges={isActive}
                baseId={baseId}
                viewId={viewId}
              />
            </FabMenu>
          )}
        </div>

        <Conditional on={showMilestones && (groupId === "" || !groupId)}>
          <Button
            // icon={<ControlOutlined />}
            disabled={!canManageMilestones}
            onClick={() => {
              seteditMilestones(!editMilestones);
            }}
          >
            Edit milestones
          </Button>
        </Conditional>

        <ProjectsMenuBtn
          hideWhenOpen={false}
          buttonType={ButtonTypes.PRIMARY}
          menuYDir="down"
          disabled={!canEditEntity}
          menuClass={roadmapStyles.menu}
          menuXDir="left"
          containerClass={roadmapStyles.addNewBtn}
          groupId={groupId}
        />
        {useMemo(
          () => (
            <>
              {editMilestones &&
                ReactDOM.createPortal(
                  <Modal
                    size="medium"
                    hideModal={() => seteditMilestones(false)}
                  >
                    <MilestoneEditor />
                  </Modal>,
                  document.body
                )}
            </>
          ),
          [editMilestones]
        )}
      </div>
    </TopNavFiltersBar>
  );
};

const MenuContent: React.FC<{
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  hasChanges?: boolean;
  baseId: string;
  viewId?: string;
}> = ({ containerRef, hasChanges, baseId, viewId }) => {
  return (
    <div className={TopnavButtonsStyles.optionRowContainer}>
      <div className={TopnavButtonsStyles.optionRow}>
        <span className="caption secondary">Milestones</span>
        <div>
          <EmptyMilestoneOptions baseId={baseId} viewId={viewId} />
        </div>
      </div>
      <div className={TopnavButtonsStyles.optionRow}>
        <span className="caption secondary">Show closed projects</span>
        <div>
          <ClosedProjectsToggle baseId={baseId} viewId={viewId} />
        </div>
      </div>
      {hasChanges && <ViewOptions baseId={baseId} viewId={viewId} />}
    </div>
  );
};

const ClosedProjectsToggle: React.FC<{ baseId: string; viewId?: string }> = ({
  baseId,
  viewId,
}) => {
  const showClosedProjects = useShallowSelector((store) =>
    Boolean(
      viewId &&
        store.roadmapFilters &&
        (store.roadmapFilters[baseId]?.[viewId]?.showClosedProjects ||
          store.roadmapFilters[baseId]?.[viewId]?.roadmapMilestonesMode ===
            RoadmapMilestonesMode.completed)
    )
  );

  const roadmapMilestonesMode = useShallowSelector(
    (store) =>
      (viewId &&
        store.roadmapFilters &&
        store.roadmapFilters[baseId]?.[viewId]?.roadmapMilestonesMode) ??
      DEFAULT_EMPTY_MILESTONE_MODE
  );

  const onChange = (checked: boolean) => {
    if (roadmapMilestonesMode === RoadmapMilestonesMode.completed) return;
    store.dispatch({
      type: TOGGLE_CLOSED_PROJECT,
      value: checked,
      viewId: viewId,
      baseId: baseId,
    });
  };

  return (
    <Switch
      disabled={roadmapMilestonesMode === RoadmapMilestonesMode.completed}
      onChange={onChange}
      checked={Boolean(showClosedProjects)}
    />
  );
};

const EmptyMilestoneOptions: React.FC<{
  baseId: string;
  viewId?: string;
}> = ({ baseId, viewId }) => {
  const menu = useEmptyMilestoneActions(baseId, viewId);

  const roadmapMilestonesMode = useShallowSelector(
    (store) =>
      (viewId &&
        store.roadmapFilters &&
        store.roadmapFilters[baseId]?.[viewId]?.roadmapMilestonesMode) ??
      DEFAULT_EMPTY_MILESTONE_MODE
  );

  if (!menu) return <></>;
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      destroyPopupOnHide={true}
      placement="bottomRight"
    >
      <span className="caption secondary bold" style={{ cursor: "pointer" }}>
        {roadmapMilestonesMode} <DownOutlined />
      </span>
    </Dropdown>
  );
};

const useEmptyMilestoneActions = (baseId: string, viewId?: string) => {
  const [menu, setmenu] = useState<JSX.Element | null>(null);
  const getMenuItem = ({
    key,
    contents,
    onClick,
    disable,
  }: {
    key: string;
    contents: () => JSX.Element;
    disable?: () => boolean;
    onClick: any;
  }) => {
    if (disable && disable())
      return <React.Fragment key={key}></React.Fragment>;
    return (
      <Menu.Item
        key={key}
        onClick={(e) => {
          onClick();
          e.domEvent.stopPropagation();
        }}
      >
        {contents()}
      </Menu.Item>
    );
  };

  const setEmptyMilestones = (emptyMilestoneMode: RoadmapMilestonesMode) => {
    store.dispatch({
      type: UPDATE_ROADMAP_MILESTONE_MODE,
      value: emptyMilestoneMode,
      baseId,
      viewId,
    });
  };

  useLayoutEffect(() => {
    const menuItems = [
      getMenuItem({
        key: RoadmapMilestonesMode.upcoming,
        contents: () => <span>{RoadmapMilestonesMode.upcoming}</span>,
        onClick: () => setEmptyMilestones(RoadmapMilestonesMode.upcoming),
      }),
      getMenuItem({
        key: RoadmapMilestonesMode.completed,
        contents: () => <span>{RoadmapMilestonesMode.completed}</span>,
        onClick: () => setEmptyMilestones(RoadmapMilestonesMode.completed),
      }),
      getMenuItem({
        key: RoadmapMilestonesMode.all,
        contents: () => <span>{RoadmapMilestonesMode.all}</span>,
        onClick: () => setEmptyMilestones(RoadmapMilestonesMode.all),
      }),
    ];

    const menu = (
      <Menu className="allowClick">{menuItems.map((item) => item)}</Menu>
    );
    setmenu(menu);
  }, []);

  return menu;
};

const ViewOptions: React.FC<{ baseId: string; viewId?: string }> = ({
  baseId,
  viewId,
}) => {
  const resetView = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    store.dispatch({
      type: RESET_ROADMAP_VIEW,
      baseId,
      viewId,
    });
  };

  return (
    <div>
      <Divider style={{ margin: "4px 0" }} />
      <div className={TopnavButtonsStyles.optionRow}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            paddingTop: "4px",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            style={{ width: "100%" }}
            buttonType={ButtonTypes.LINK}
            onClick={resetView}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RoadmapTopNavFilters;
