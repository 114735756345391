import navigationApi from "clientApi/navigationApi";
import { locationSubject } from "components/LocationListener";
import CheckSquareTwoTone from "icons/Components/CheckSquareTwoTone";
import ProjectTwoTone from "icons/Components/ProjectTwoTone";
import ThunderboltTwoTone from "icons/Components/ThunderboltTwoTone";
import { useMemo } from "react";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { navigateToUrl } from "utilities/paneNavigationUtils";
import { ChunkDestination } from "utilities/stateTypes";
import { GroupGeneralViews, TasksViewModes } from "utilities/types";
import styles from "./workSubroutes/workSubroutes.module.scss";

enum Options {
  projects = "projects",
  tasks = "tasks",
  cycles = "sprints",
}

const orderedOptions = [Options.tasks, Options.projects, Options.cycles];

export const useGroupWorkNavigationOptions = (groupId: string) => {
  const group = useShallowSelector((store) => store.groups.dict[groupId]);
  const showPayouts = useShallowSelector(
    (store) => store.workspace.showContributions
  );

  const options = useMemo(() => {
    const possibleOptions: Options[] = [];
    orderedOptions.forEach((option) => {
      if (option === Options.cycles) {
        if (group?.showCycles) {
          possibleOptions.push(option);
        }
        return;
      }
      // if (option === Options.payouts) {
      //   if (showPayouts) {
      //     possibleOptions.push(option);
      //   }
      //   return;
      // }
      possibleOptions.push(option);
    });

    return possibleOptions.map((option) => optionsData(group?.slug)[option]);
  }, [group?.id, group?.showCycles, showPayouts]);

  return options;
};

const optionsData = (groupSlug: string) => {
  return {
    [Options.projects]: {
      key: "roadmap",
      name: () => "Projects",
      icon: <CheckSquareTwoTone />,
      url: () => `group/${groupSlug}/projects`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.Projects,
        groupSlug,
      }),
    },
    [Options.tasks]: {
      key: "tasks",
      name: () => "Tasks",
      icon: <ProjectTwoTone />,
      url: () => `group/${groupSlug}/tasks`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.Tasks,
        taskViewMode: TasksViewModes.Tasks,
        groupSlug,
      }),
    },
    [Options.cycles]: {
      key: "sprints",
      name: () => "Sprints",
      icon: <ThunderboltTwoTone />,
      url: () => `group/${groupSlug}/sprints`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.Cycles,
        taskViewMode: TasksViewModes.Cycle,
        groupSlug,
      }),
    },
    // [Options.payouts]: {
    //   key: "payouts",
    //   name: () => "Payouts",
    //   icon: <TrophyTwoTone />,
    //   url: () => `group/${groupSlug}/payouts`,
    //   secondaryViewConfig: () => ({
    //     viewName: GroupGeneralViews.Contributions,
    //     groupSlug,
    //   }),
    // },
  };
};

const GroupWorkSubroutes: React.FC<{
  groupId: string;
  paneId: ChunkDestination;
}> = ({ groupId, paneId }) => {
  const subRoutesParams = useShallowSelector(
    (store) => store.topNav[paneId]?.subRoutesParams
  );

  const routes = useGroupWorkNavigationOptions(groupId);

  const isActive = (route: any) => {
    if (route.activeOn && route.activeOn.includes(subRoutesParams?.activeKey))
      return true;
    return subRoutesParams?.activeKey === route.key;
  };

  const navigate = (e: React.MouseEvent, route: any) => {
    if (route.action) {
      route.action();
      return;
    }

    const openInSecondary = () => {
      e.stopPropagation();
      e.preventDefault();
      const config = route.secondaryViewConfig();
      if (config) navigationApi.openSplitView({ ...config });
      return;
    };

    const openInPrimary = () => {
      e.stopPropagation();
      e.preventDefault();
      const baseSlug = store.getState().workspace.slug;
      locationSubject.next(`/${baseSlug}/${route.url()}`);
      return;
    };
    navigateToUrl(e, paneId, openInPrimary, openInSecondary);
  };

  return (
    <div style={{ display: "flex", gap: "4px" }}>
      {routes.map((tab) => (
        <div
          key={tab.key}
          className={
            isActive(tab) ? `${styles.pill} ${styles.active}` : `${styles.pill}`
          }
          onClick={(e: React.MouseEvent) => navigate(e, tab)}
        >
          {tab.name()}
        </div>
      ))}
    </div>
  );
};

export default GroupWorkSubroutes;
