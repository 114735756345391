import React from "react";
import { ChunkDestination } from "utilities/stateTypes";
import BasePinsHoc from "../BasePinsHoc";
import BaseDetails from "./baseProfile/BaseDetails";

const BaseProfile: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  return (
    <>
      <BaseDetails />
      <BasePinsHoc paneId={paneId} />
    </>
  );
};

export default BaseProfile;
