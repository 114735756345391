import Icon from "@ant-design/icons";
import React from "react";

export default function ClarityLogoGray(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      width="118"
      height="36"
      viewBox="0 0 311 95"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.4 75.9C51.1 75.9 59.3 67 61.9 62.2L58.9 59.7C53.7 65.5 47.8 66.7 43.6 66.7C32.1 66.7 19.9 57.4 19.7 37C19.5 24 24.1 15.3 32.9 15.3C40.6 15.3 43.5 21.9 43.9 29C44.2 33.6 46.8 37.9 53.1 37.9C59.7 37.9 62.3 33.2 62.3 28.7C62.3 20 52.9 11.2 36.2 11.2C16.6 11.2 1 23.1 1 43.4C1 64.2 17.3 75.9 35.4 75.9Z"
        fill="currentColor"
        fillOpacity="0.3"
      />

      <path
        d="M80.6828 75.5C83.8828 75.5 87.4828 74.6 88.8828 71.2V69C85.9828 70.2 84.6828 68 84.6828 64.7V13.6C84.6828 10.6 83.0828 9.1 79.6828 9.6L63.3828 12.3V16.2C67.0828 15.9 69.6828 16.7 69.6828 21.4V64.5C69.6828 71.9 74.3828 75.5 80.6828 75.5Z"
        fill="currentColor"
        fillOpacity="0.3"
      />

      <path
        d="M105.563 75.5C113.463 75.5 117.763 71.1 119.963 68.4C120.963 67.1 121.763 67.5 121.963 68.9C122.563 71.4 124.863 75.5 131.963 75.5C135.463 75.5 138.763 74.9 140.363 71.7V69.4C137.063 70.5 136.563 68 136.563 64.7V48.4C136.563 32.4 130.663 28.9 118.863 28.9C106.663 28.9 95.2633 32.8 95.2633 40.6C95.2633 44 97.5633 47.1 102.363 47.1C113.963 47.1 105.163 33.2 115.763 33.2C120.063 33.2 121.563 36.2 121.563 43.4V44.6C121.563 47.6 120.863 48.4 117.063 49C99.7633 52 92.3633 56.1 92.3633 64.5C92.3633 72.3 98.6633 75.5 105.563 75.5ZM107.463 61.6C107.463 58.4 109.363 53.6 117.563 53.1C120.663 52.9 121.563 53.6 121.563 55.9V57C121.563 62.8 118.263 67.2 112.863 67.2C109.063 67.2 107.463 64.7 107.463 61.6Z"
        fill="currentColor"
        fillOpacity="0.3"
      />

      <path
        d="M142.767 71V74.9H171.067V71C165.867 70.7 164.167 69.2 164.167 63.8V54.4C164.167 41.3 167.867 36.1 172.067 36.1C178.067 36.1 171.267 47.1 181.267 47.1C186.867 47.1 190.167 43.3 190.167 38.6C190.167 32.5 184.967 28.5 178.767 28.5C173.567 28.5 170.167 31.2 167.367 34.5C165.967 36.3 164.167 36.2 164.167 33.8V32.3C164.167 30.1 162.567 28.6 159.167 29.1L142.867 31.8V35.7C146.567 35.4 149.167 36.2 149.167 40.9V63.8C149.167 69.2 147.467 70.7 142.767 71Z"
        fill="currentColor"
        fillOpacity="0.3"
      />

      <path
        d="M191.137 71V74.9H218.637V71C214.137 70.7 212.437 69.2 212.437 63.8V33.1C212.437 30.1 210.837 28.6 207.437 29.1L191.237 31.8V35.7C194.837 35.4 197.437 36.2 197.437 40.9V63.8C197.437 69.2 195.737 70.7 191.137 71ZM195.037 16.5C195.037 21.3 199.037 25 203.737 25C208.337 25 212.237 21.3 212.237 16.5C212.237 11.7 208.337 8 203.737 8C199.037 8 195.037 11.7 195.037 16.5Z"
        fill="currentColor"
        fillOpacity="0.3"
      />

      <path
        d="M240.591 75.4C245.591 75.4 249.691 73.9 251.991 71.9C253.291 70.7 252.791 69.3 251.491 67.7C249.591 69.1 247.591 69.3 246.291 69.3C241.391 69.3 240.791 65.6 240.791 60.1V38.9C240.791 35.3 241.891 34.4 246.991 34.7L253.491 35.1V29.8H246.991C241.891 29.8 240.791 29.2 240.791 27L240.891 20.8C240.891 19.3 239.991 18.4 238.491 18.4H236.691C235.391 18.4 234.691 18.8 233.891 20.1C230.491 25.6 226.491 29.4 218.191 31.3V34.5H222.691C224.991 34.5 225.791 35.2 225.791 38.7V60.6C225.791 70.3 231.391 75.4 240.591 75.4Z"
        fill="currentColor"
        fillOpacity="0.3"
      />

      <path
        d="M269.735 94.7C277.235 94.7 281.835 91.3 286.335 79.5L300.435 42.2C302.335 37.4 303.735 35.1 309.935 33.6V29.8H289.035V33.6C295.335 35.1 296.935 37.9 292.235 51.3L289.235 59.4C287.635 64 286.235 64 284.535 59.5L277.735 42.2C275.935 37.2 275.735 35.1 280.935 33.6V29.8H254.535V33.6C258.835 35.1 261.035 37.5 262.735 42.2L275.835 76.3C277.635 81 278.035 84.4 276.835 87.6C276.435 89.1 275.035 90.2 273.335 90.2C268.135 90.2 272.535 79.6 264.035 79.6C259.335 79.6 257.235 82.9 257.235 86.1C257.235 91.1 262.535 94.7 269.735 94.7Z"
        fill="currentColor"
        fillOpacity="0.3"
      />
    </svg>
  );
}
