import {
  getInnerCaretPosition,
  moveCaretToPreviousPosition,
} from "utilities/caretMovement";
import { LineValueType } from "utilities/lineUtilities";

export const getSelectionHtml = () => {
  let range;
  if (window.getSelection) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      range = selection.getRangeAt(0);
      const clonedSelection = range.cloneContents();
      const div = document.createElement("div");
      div.appendChild(clonedSelection);
      return div.innerHTML;
    } else {
      return "";
    }
  }
};

export const checkIfSelectionHasText = () => {
  const selection = document.getSelection();
  return selection && selection.toString().length > 0;
};

export const checkIfEmptyTagCreated = (el: HTMLElement) => {
  const tags = el.querySelectorAll("mention");
  tags.forEach((tag) => {
    if (tag.textContent === "") tag.remove();
  });
};

export const checkCareIsInsideTag = (): boolean => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    if (!range.collapsed) return false;
    const isIt = checkIfInsideMention(range.startContainer.parentElement);
    return isIt;
  }
  return false;
};

export const checkCareIsInsideThisTag = (tag: Element): boolean => {
  const selection = document.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    if (!range.collapsed) return false;
    if (tag.contains(range.startContainer)) return true;
  }
  return false;
};

export const checkIfInsideMention = (node: HTMLElement | null): any => {
  if (node) {
    if (
      node.classList?.contains("mention") &&
      !node.classList?.contains("hashtag")
    )
      return true;
    if (
      node.parentElement &&
      !node.parentElement.classList.contains("content-section")
    )
      return checkIfInsideMention(node.parentElement);
    else return false;
  } else return false;
};

export const checkifBeginingOfTag = (range: Range): boolean => {
  if (range.startOffset === 0) return true;
  else return false;
};

export const checkIfEndOfTag = (range?: Range): boolean => {
  if (!range) {
    const selection = document.getSelection();
    if (selection && selection.rangeCount > 0) range = selection.getRangeAt(0);
  }
  if (range) {
    const textContent = range.endContainer.textContent;
    const numberOfChars = textContent ? textContent.length : 0;
    if (range.endOffset === numberOfChars) return true;
    else return false;
  }
  return false;
};

export const checkIfInsideCode = (node: Node): LineValueType[] => {
  const arrayOfFormats: LineValueType[] = [];
  if (node) checkFormats(node, arrayOfFormats);
  return arrayOfFormats;
};

export const checkFormats = (
  node: Node,
  classList: string[]
): string[] | any => {
  if (
    [
      LineValueType.a,
      LineValueType.b,
      LineValueType.code,
      LineValueType.strike,
      LineValueType.i,
      LineValueType.highlight,
    ].includes(node.nodeName as LineValueType)
  ) {
    classList.push(node.nodeName);
  }
  if (
    node.parentElement &&
    !node.parentElement.classList.contains("content-section")
  )
    return checkFormats(node.parentElement, classList);
};

export const getFirstChild = (node: Node): Node => {
  return node.firstChild ? getFirstChild(node.firstChild) : node;
};

export const getLastChild = (node: Node): Node => {
  return node.lastChild ? getLastChild(node.lastChild) : node;
};

export const checkIfPreviousEmptyAndHasACodeBlock = (
  node: HTMLElement,
  target: any,
  caretPosition: number,
  range: any
) => {
  const previousElement = node.previousElementSibling
    ? node.previousElementSibling
    : node.parentElement
    ? node.parentElement.previousElementSibling
    : null;
  const nextElement = node.nextElementSibling
    ? node.nextElementSibling
    : node.parentElement
    ? node.parentElement.nextElementSibling
    : null;
  const prevHasCode = previousElement ? checkIfInsideCode(previousElement) : [];
  const nextHasCode = nextElement ? checkIfInsideCode(nextElement) : [];
  if (node.nodeValue) {
    const matchesNr = node.nodeValue.match(/\uFEFF/g);
    const innerPosition = getInnerCaretPosition(target, node, caretPosition);
    const positionCheck =
      (matchesNr && matchesNr.length === 1 && innerPosition === 1) ||
      innerPosition === 0;
    if (prevHasCode.includes(LineValueType.code) && positionCheck) {
      if (
        node.textContent &&
        node.textContent.length === 1 &&
        matchesNr &&
        matchesNr.length === 1
      ) {
        node.textContent = node.textContent.replace(/\uFEFF/g, ``);
        caretPosition = caretPosition - 1;
        moveCaretToPreviousPosition(target, caretPosition);
      } else {
        const textPart = document.createTextNode("\uFEFF");
        range.insertNode(textPart);
        caretPosition = caretPosition + 1;
        moveCaretToPreviousPosition(target, caretPosition);
      }
      return true;
    }
    const positionCheck2 =
      (matchesNr &&
        matchesNr.length === 1 &&
        node.textContent &&
        innerPosition === node.textContent.length - 2) ||
      (node.textContent &&
        (innerPosition === node.textContent.length - 1 ||
          innerPosition === node.textContent.length));

    if (nextHasCode.includes(LineValueType.code) && positionCheck2) {
      if (node.textContent) {
        if (nextElement) {
          const textPart = document.createTextNode("\uFEFF");
          nextElement.prepend(textPart);
          caretPosition = caretPosition + 1;
          moveCaretToPreviousPosition(target, caretPosition);
          return true;
        }
      }
    }
  }
  return false;
};

export const checkIfShouldNotCreateTag = (
  range: Range,
  newPlainText: string
) => {
  if (checkIfInsideMention(range.startContainer.parentElement)) {
    const textOfParent = range.startContainer.parentElement?.textContent;
    if (textOfParent?.trim() === newPlainText.trim()) {
      range.collapse();
      return true;
    }
  }
  if (checkIfInsideMention(range.endContainer.parentElement)) {
    const textOfParent = range.endContainer.parentElement?.textContent;
    if (textOfParent?.trim() === newPlainText.trim()) {
      range.collapse();
      return true;
    }
  }
  return false;
};
