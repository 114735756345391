import Button, { ButtonTypes, IconSides } from "components/Button";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./milestoneEditor/milestoneEditor.module.scss";
import workApi from "clientApi/workApi";
import { useOptionalClassName } from "utilities/hooks";
import { VALUE_ENTER, VALUE_ESCAPE } from "keycode-js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SixDotImg from "icons/six-dot-handle.svg";
import {
  getNextRank,
  getPreviousRank,
  getRankBetween,
} from "utilities/containerRankHelpers";
import notificationsApi from "clientApi/notificationsApi";
import { useAbilityChecker } from "editor/utils/customHooks";
import { Abilities } from "utilities/types";

const MilestoneEditor: React.FC = () => {
  const [addingMilestone, setaddingMilestone] = useState(false);
  const canEditMilestones = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MILESTONES,
  });
  return (
    <div style={{ padding: "12px" }}>
      <h4 style={{ marginBottom: "16px" }}>Edit milestones</h4>
      <MilesoneList />
      {addingMilestone && (
        <AddingMilestone setaddingMilestone={setaddingMilestone} />
      )}
      <Button
        icon={<PlusOutlined />}
        style={{ marginTop: "32px" }}
        disabled={!canEditMilestones}
        iconSide={IconSides.LEFT}
        onClick={() => setaddingMilestone(!addingMilestone)}
      >
        Add Milestone
      </Button>
    </div>
  );
};

const MilesoneList: React.FC = React.memo(() => {
  const milestones = useSelector(
    (state: ClarityStore) => state.work.milestoneIds,
    shallowEqual
  );
  const canEditMilestones = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MILESTONES,
  });
  const handleDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) return;
    if (!canEditMilestones) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const state = store.getState().work;
    const milestoneIds = [...state.milestoneIds];
    const dict = state.milestoneDict;
    const movingId = milestoneIds[source.index];
    milestoneIds.splice(source.index, 1);

    let newRank = "";

    if (destination.index === 0) {
      const currentFirstRankId = milestoneIds[0];
      const currentFirstRank = dict[currentFirstRankId].rank;
      if (currentFirstRank) {
        newRank = getPreviousRank(currentFirstRank);
      }
    } else if (destination.index === milestoneIds.length) {
      const currentLastRank = dict[milestoneIds[milestoneIds.length - 1]].rank;
      if (currentLastRank) {
        newRank = getNextRank(currentLastRank);
      }
    } else {
      const rankBefore = dict[milestoneIds[destination.index - 1]].rank;
      const rankAfter = dict[milestoneIds[destination.index]].rank;
      newRank = getRankBetween(rankBefore, rankAfter);
    }

    workApi.updateMilestone(movingId, { rank: newRank });
  };

  return (
    <DragDropContext onDragEnd={(result: any) => handleDragEnd(result)}>
      <Droppable isDropDisabled={!canEditMilestones} droppableId="droppable">
        {(provided, snapshot) => (
          <>
            <div
              {...provided.droppableProps}
              className={
                snapshot.isDraggingOver ? styles.isDraggingOver : undefined
              }
              ref={provided.innerRef}
            >
              {milestones.map((id, index) => (
                <MilestoneRow
                  canEditMilestones={canEditMilestones}
                  id={id}
                  key={id}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </div>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const MilestoneRow: React.FC<{
  id: string;
  index: number;
  canEditMilestones: boolean;
}> = ({ id, index, canEditMilestones }) => {
  const milestone = useSelector(
    (state: ClarityStore) => state.work.milestoneDict[id],
    shallowEqual
  );

  const [name, setname] = useState(milestone?.name ?? "");

  useEffect(() => {
    setname(milestone?.name ?? "");
  }, [milestone?.name]);

  const save = () => {
    if (name && name !== milestone.name) workApi.updateMilestone(id, { name });
    else cancel();
  };

  const cancel = () => {
    setname(milestone?.name ?? "");
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
  });

  if (!milestone)
    return (
      <Draggable
        isDragDisabled={!canEditMilestones}
        key={id}
        draggableId={id}
        index={index}
      >
        {(provided, snapshot) => <></>}
      </Draggable>
    );

  return (
    <Draggable
      isDragDisabled={!canEditMilestones}
      key={id}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...provided.draggableProps}
        >
          <div className={styles.rowContainer}>
            <div className={styles.grabHandleIconContainer}>
              <img
                src={SixDotImg}
                alt="Grab Handle"
                className={
                  styles.grabHandleIcon +
                  " " +
                  (snapshot.isDragging ? styles.dragging : "")
                }
                {...provided.dragHandleProps}
              />
            </div>

            <NameEditor
              name={name}
              disabled={!canEditMilestones}
              setname={setname}
              save={save}
              cancel={cancel}
            >
              <span className={styles.deleteBtn}>
                <Button
                  onClick={() => {
                    workApi.deleteMilestone(id).catch((e) => {
                      notificationsApi.displayError({
                        body: <span>{e.response.data.message}</span>,
                        duration: 3,
                      });
                    });
                  }}
                  buttonType={ButtonTypes.LINK}
                  disabled={!canEditMilestones}
                  icon={<DeleteOutlined />}
                  style={{ marginRight: "8px" }}
                />
              </span>
            </NameEditor>
          </div>
        </div>
      )}
    </Draggable>
  );
};

const AddingMilestone: React.FC<{
  setaddingMilestone: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setaddingMilestone }) => {
  const [name, setname] = useState("");
  const save = (blurring: boolean) => {
    if (!name) {
      setaddingMilestone(false);
    } else workApi.saveMilestone(name);
    setname("");
    if (blurring) {
      setaddingMilestone(false);
    }
  };

  const cancel = () => {
    setname("");
    setaddingMilestone(false);
  };

  return (
    <NameEditor
      name={name}
      setname={setname}
      save={save}
      cancel={cancel}
      addingNew={true}
    />
  );
};

const NameEditor: React.FC<{
  name: string;
  setname: React.Dispatch<React.SetStateAction<string>>;
  save: any;
  cancel: any;
  isClosed?: boolean;
  disabled?: boolean;
  addingNew?: boolean;
}> = ({
  name,
  setname,
  children,
  save,
  cancel,
  isClosed,
  addingNew,
  disabled,
}) => {
  const [active, setactive] = useState(false);
  const className = useOptionalClassName({
    baseStyle: styles.rowEditor,
    pairs: [
      { extraStyle: styles.active, withExtra: active },
      // { extraStyle: styles.done, withExtra: isClosed },
      { extraStyle: styles.addingNew, withExtra: addingNew },
    ],
  });

  return (
    <div className={className}>
      <input
        value={name}
        style={{ lineHeight: "28px" }}
        className="caption primary medium"
        onBlur={() => {
          setactive(false);
          save(true);
        }}
        autoFocus={addingNew}
        disabled={disabled}
        onChange={(e) => setname(e.target.value)}
        onFocus={() => setactive(true)}
        onKeyDown={(e) => {
          if (e.key === VALUE_ENTER) {
            save();
            return;
          }
          if (e.key === VALUE_ESCAPE) {
            cancel();
            return;
          }
        }}
      />
      {children}
    </div>
  );
};

export default MilestoneEditor;
