import Modal from "clarity-ui/Modal";
import React from "react";
import { RoleGalleryContent } from "screens/base/main/RoleGallery";
import { SET_MODAL_JOIN_GROUP } from "store/actions";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import Button from "./Button";
import styles from "./joinClosedGroupModal/joinClosedGroupModal.module.scss";

const JoinClosedGroupModal: React.FC<{}> = () => {
  const roleIds = useShallowSelector(
    (state) => state.client.modalJoinClosedGroup?.roleIds
  );
  return (
    <Modal
      hideModal={() => {
        store.dispatch({
          type: SET_MODAL_JOIN_GROUP,
          isOpen: false,
        });
      }}
      size="extraLarge"
      className={styles.joinClosedGroupModal}
    >
      <div className={styles.content}>
        <h3 style={{ marginBottom: "20px" }} tabIndex={0}>
          You need more roles to join this group
        </h3>
        <div
          className="body2 secondary regular"
          style={{ marginBottom: "20px" }}
        >
          This is a closed group that requires specific roles to join. You can
          see the required roles in the list below. You may be able to add the
          roles from the list. If you cannot add all the required roles
          yourself, please contact an admin of this workspace to add them for
          you.
        </div>
        <div className={styles.contentScroll}>
          <RoleGalleryContent roleIds={roleIds} />
        </div>

        <div
          style={{
            marginTop: "8px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Button
            onClick={() => {
              store.dispatch({
                type: SET_MODAL_JOIN_GROUP,
                isOpen: false,
              });
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default JoinClosedGroupModal;
