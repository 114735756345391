import { locationSubject } from "components/LocationListener";
import { blockApi } from "editor/utils/blockActionsApi";
import React, { useEffect, useRef } from "react";
import { batch, shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SET_MODAL_ITEM_SELECT } from "store/actions";
import store from "store/storeExporter";
import Modal from "./Modal";
import WidgetEntry from "./WidgetEntry";

interface Props {}

const ModalSelectItem: React.FC<Props> = (props) => {
  const modalState = useSelector((state: any) => {
    return state.client.modalSelectItem;
  }, shallowEqual);

  const ref = useRef<any>(null);

  useEffect(() => {
    if (modalState?.display) {
      ref.current?.focus();
    }
  }, [modalState]);

  if (!modalState?.display) return <></>;
  if (modalState.type === "widget") {
    return (
      <Modal
        hideModal={() => {
          batch(() => {
            store.dispatch({
              type: SET_MODAL_ITEM_SELECT,
              param: {
                display: false,
              },
            });
            blockApi.blockAction.refocus();
          });
        }}
      >
        <h3 ref={ref} style={{ marginBottom: "20px" }} tabIndex={0}>
          Select Widget
        </h3>
        <div style={{ maxHeight: "40vh", overflow: "auto" }}>
          <CustomViewSelector modalState={modalState} />
        </div>
      </Modal>
    );
  }
  return <></>;
};

const CustomViewSelector: React.FC<any> = (props) => {
  const customViews = useSelector((state: any) => {
    return state.customWork.dict;
  });

  if (Object.values(customViews).length === 0) {
    return (
      <div>
        There are no widgets to show beacuse this base does not have any Views.{" "}
        <Link
          to=""
          // className={}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const base = store.getState().workspace;
            locationSubject.next(`/${base.slug}/view/new`);
            store.dispatch({
              type: SET_MODAL_ITEM_SELECT,
              param: {
                display: false,
              },
            });
          }}
        >
          Click here
        </Link>{" "}
        to create your first View and then come back to use widgets
      </div>
    );
  }

  return (
    <>
      {Object.values(customViews).map((view: any) => (
        <div
          onClick={(e) => {
            batch(() => {
              props.modalState.onConfirm(view.id);
              store.dispatch({
                type: SET_MODAL_ITEM_SELECT,
                param: {
                  display: false,
                },
              });
            });
          }}
          key={view.id}
          style={{ marginTop: "5px", marginBottom: "5px", cursor: "pointer" }}
        >
          <WidgetEntry
            customViewId={view.id}
            listed={true}
            blockContext={props.modalState.blockContext}
          />
        </div>
      ))}
    </>
  );
};

export default ModalSelectItem;
