import OpenDiscordThreadCTA from "./OpenDiscordThreadCTA";
import RequestClaimCTA from "./RequestClaimCTA";
import RequestReviewCTA from "./RequestReviewCTA";
import ReviewWorkItemCTA from "./ReviewWorkItemCTA";
import styles from "../styles/taskWorkItems.module.scss";

const WorkItemActions: React.FC<{ workItemId: string }> = ({ workItemId }) => {
  return (
    <div className={`${styles.actionsContainer} ignoreBubbleUp`}>
      <OpenDiscordThreadCTA workItemId={workItemId} />
      <RequestClaimCTA workItemId={workItemId} />
      <RequestReviewCTA workItemId={workItemId} />
      <ReviewWorkItemCTA workItemId={workItemId} />
    </div>
  );
};

export default WorkItemActions;
