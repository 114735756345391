import React, { useState, useEffect, useRef, useCallback } from "react";
import ModalScrimComponent from "./ModalScrimComponent";
import styles from "./modalNewTask/modalNewTask.module.scss";
import workItemModalStyles from "screens/base/main/workItemPreviewModal/workItemPreviewModal.module.scss";
import taskPreviewStyles from "screens/base/main/detailView/documentContainer/task/task.module.scss";
import * as actionTypes from "store/actions";
import Button, { ButtonTypes } from "./Button";
import { ContainerTypes, IBlockContext } from "utilities/types";
import { KeyCodes } from "utilities/lineUtilities";
import store from "store/storeExporter";
import { Switch, Tooltip } from "antd";
import {
  executeTransaction,
  localChangesTransaction,
} from "editor/utils/specificActions/persistActions";
import { useIsMobile, useShallowSelector } from "utilities/hooks";
import { executeTextSaveTimeout } from "editor/utils/blockActions";
import DocumentContainer from "screens/base/main/detailView/DocumentContainer";
import { snippetsApi } from "clientApi/snippetsApi";
import { ChunkDestination } from "utilities/stateTypes";

function NewSnippetModal() {
  const storeData = useShallowSelector((store) => ({
    baseId: store.workspace.id,
    newSnippetContext: store.client.newSnippetContext,
    snippetObject: store.snippets.dict[store.client.newSnippetContext.id],
  }));

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);

  const presaveSnippet = {
    ...storeData.snippetObject,
  };

  const snippetName = useRef(presaveSnippet?.name);

  useEffect(() => {
    snippetName.current = presaveSnippet.name;
    if (snippetName.current === "") setIsSaveDisabled(true);
    else setIsSaveDisabled(false);
  }, []);

  const reopenNewTask = () => {
    const context = storeData.newSnippetContext;
    setTimeout(() => {
      store.dispatch({
        type: actionTypes.SHOW_MODAL_NEW_SNIPPET,
        newTaskContext: context,
      });
    }, 0);
  };

  const handleCancel = () => {
    snippetName.current = "";
    setIsSaveDisabled(true);
    setIsSaving(false);
    hideModal();
    if (presaveSnippet) {
      store.dispatch({
        type: actionTypes.UPDATE_SNIPPET,
        param: {
          id: presaveSnippet.id,
          delta: {},
          type: "delete",
        },
      });
    }
  };

  const handleSave = (snippetName: string, reopen: boolean) => {
    if (snippetName.length < 1) return;
    setIsSaving(true);
    executeTextSaveTimeout();

    const localChangesCopy = { ...localChangesTransaction };
    executeTransaction(
      localChangesCopy,
      undefined,
      ContainerTypes.SNIPPET + presaveSnippet.id
    );

    presaveSnippet.name = snippetName;

    snippetsApi.createSnippet(presaveSnippet);

    hideModal();
    if (reopen) {
      reopenNewTask();
    }
  };

  const handleKeydown = (event: any, taskName: string) => {
    const { keyCode } = event;
    if ((event.ctrlKey || event.metaKey) && keyCode === KeyCodes.enter) {
      if (event.shiftKey) {
        handleSave(taskName, true);
      } else handleSave(taskName, false);
    } else if (keyCode === KeyCodes.esc) {
      if (taskName === "") handleCancel();
    }
  };

  const isMobile = useIsMobile();

  return (
    <>
      <div
        style={{
          display: "flex",
          zIndex: 99,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <div
          style={{
            maxHeight: "616px",
          }}
          className={`${workItemModalStyles.item_preview_modal} task-modal ${
            workItemModalStyles.item_preview_modal__new
          }  ${isMobile ? workItemModalStyles.item_preview_modal__mobile : ""}`}
        >
          <div
            style={{
              boxShadow: "none",
              overflowY: "auto",
              height: "100%",
            }}
            onKeyDown={(event) => handleKeydown(event, snippetName.current)}
          >
            <div className={taskPreviewStyles.taskBodyContainer_new}>
              <div className={taskPreviewStyles.taskBodyContainer_new_content}>
                <div
                  className={styles.modalNewTask__body__name}
                  style={{ paddingTop: "32px" }}
                >
                  <NameHolder
                    setIsSaveDisabled={setIsSaveDisabled}
                    snippetName={snippetName}
                  />
                </div>
                <div className={taskPreviewStyles.taskBody__metadata_1}>
                  <div
                    className={
                      taskPreviewStyles.taskBody__metadata_1__workIdContainer
                    }
                  ></div>
                </div>
                <div>
                  <DocumentContainer
                    containerId={presaveSnippet.id}
                    containerType={ContainerTypes.SNIPPET}
                    paneId={ChunkDestination.peek}
                    showDefault={true}
                    contextPresets={
                      {
                        autosave: false,
                        id: ContainerTypes.SNIPPET + presaveSnippet.id,
                        container: {
                          id: presaveSnippet.id,
                          type: ContainerTypes.SNIPPET,
                        },
                      } as IBlockContext
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className={taskPreviewStyles.taskBodyContainer_new_sidebar_footer}
            >
              <p
                className={`small regular disabled hint-bar ${taskPreviewStyles.taskBodyContainer_new_sidebar_hint}`}
              >
                Type “/” to add images and other content to the description
              </p>
              <Button
                className="footer-close"
                buttonType={ButtonTypes.DEFAULT}
                onClick={handleCancel}
              >
                Close
              </Button>
              <div
                className={
                  taskPreviewStyles.taskBodyContainer_new_sidebar_footer_action
                }
              >
                <div>
                  <Switch onChange={setCreateAnother} />
                  <span className="small regular secondary">
                    {" "}
                    Create another
                  </span>
                </div>
                <Tooltip title="Cmd+Enter" trigger={"hover"}>
                  <span>
                    <Button
                      buttonType={ButtonTypes.PRIMARY}
                      onClick={() =>
                        handleSave(snippetName.current, createAnother)
                      }
                      style={{ marginLeft: "8px", minWidth: "137px" }} // need to fix this
                      className={
                        isSaveDisabled || isSaving ? styles.disabledButton : ""
                      }
                      disabled={isSaveDisabled || isSaving}
                    >
                      {isSaving ? "Saving..." : "Save"}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <ModalScrimComponent hideModal={() => {}} />
      </div>
    </>
  );
}

const NameHolder: React.FC<{
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  snippetName: React.MutableRefObject<string>;
}> = ({ setIsSaveDisabled, snippetName }) => {
  const handleTaskNameChange = (event: any) => {
    const { value } = event.target;

    if (value === "") setIsSaveDisabled(true);
    else setIsSaveDisabled(false);
    snippetName.current = value;
    setName(value);
  };

  const [name, setName] = useState(snippetName.current);
  const InputTaskName = useRef<HTMLInputElement | null>(null);

  const setFocus = useCallback((el: HTMLInputElement | null) => {
    if (el) {
      InputTaskName.current = el;
      setTimeout(() => {
        el.focus();
      }, 1);
    }
  }, []);

  return (
    <input
      style={{ width: "100%", paddingLeft: 0 }}
      type="text"
      value={name}
      onChange={handleTaskNameChange}
      placeholder={`Untitled snippet`}
      ref={(el) => setFocus(el)}
    />
  );
};

const hideModal = () =>
  store.dispatch({ type: actionTypes.HIDE_MODAL_NEW_SNIPPET });

export default NewSnippetModal;
