import { useRef } from "react";

const { v4: uuidv4 } = require("uuid");

export default function LightStatusCanceled() {
  const idRef = useRef(uuidv4());
  const pathIdRef = useRef(uuidv4());

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>light-status-canceled</title>
      <defs>
        <polygon
          id={pathIdRef.current}
          points="20.125 5.51160713 18.4883929 3.87499999 12 10.3633929 5.51160713 3.87499999 3.87499999 5.51160713 10.3633929 12 3.87499999 18.4883929 5.51160713 20.125 12 13.6366071 18.4883929 20.125 20.125 18.4883929 13.6366071 12"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop-HD-Copy-3"
          transform="translate(-617.000000, -925.000000)"
        >
          <g
            id="light-status-canceled"
            transform="translate(617.000000, 925.000000)"
          >
            <circle
              id="Oval-Copy-4"
              stroke="#D6CBD1"
              strokeWidth="4"
              fill="#D6CBD1"
              cx="16"
              cy="16"
              r="14"
            ></circle>
            <g id="Icon/Task-Copy-2" transform="translate(4.000000, 4.000000)">
              <mask id={idRef.current} fill="white">
                <use xlinkHref={`#${pathIdRef.current}`}></use>
              </mask>
              <g id="Path" fillRule="nonzero"></g>
              <g
                id="Group"
                mask={`url(#${idRef.current})`}
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="Fill/SidebarIcon/Inactive">
                  <rect
                    id="Fill/White/Default"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
