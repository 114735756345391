import { axiosInstance } from "index";
import { batch } from "react-redux";
import * as actionTypes from "store/actions";
import { NOTE_PAGE_SIZE } from "store/reducers/notesReducer";
import { WORK_PAGE_SIZE } from "store/reducers/workReducer";
import store from "store/storeExporter";
import { TasksViewModes } from "utilities/types";
import roleApi from "./rolesApi";
import { tokenApi } from "./tokenApi";
import { tokenGateApi } from "./tokenGatesApi";

class BaseApi {
  fetchAndSetBaseWeeklNotes(baseId: string) {
    return axiosInstance
      .get("/api/note/weekly/all", {
        params: {
          workspaceId: baseId,
        },
      })
      .then((res: any) => {
        store.dispatch({
          type: actionTypes.LOAD_BASE_WEEKLY_NOTES,
          params: {
            notes: res.data,
            startFrom: 0,
            allLoaded: true,
          },
        });
        return true;
      });
  }

  async fetchAndSetBaseMembers(baseId: string) {
    return axiosInstance.get(`/api/workspace/members/${baseId}`).then((res) => {
      const members = res.data.payload.members;
      const pendingMembers = res.data.payload.pendingMembers;
      const onlineMembers = res.data.payload.onlineMembers;
      const pendingGuests = res.data.payload.pendingGuests;

      store.dispatch({
        type: actionTypes.SET_BASE_MEMBERS,
        members,
        pendingMembers,
        onlineMembers,
        pendingGuests,
      });
      return true;
    });
  }

  async fetchAndSetBaseNotes(baseId: string, defaultGroupId?: string) {
    return axiosInstance
      .get("/api/note/all", {
        params: {
          workspaceId: baseId,
          groupIds: [defaultGroupId],
        },
      })
      .then((res: any) => {
        const showWeekly = store.getState().workspace.showWeekly;
        if (!showWeekly) {
          store.dispatch({
            type: actionTypes.RESET_NOTES,
          });
        }
        store.dispatch({ type: actionTypes.LOAD_BASE_NOTES, params: res.data });
        return true;
      });
  }

  async fetchAndSetBaseGuestNotes(baseId: string, noteIds: string[]) {
    return axiosInstance
      .post("/api/note/all/guest", {
        workspaceId: baseId,
        startFrom: 0,
        pageSize: NOTE_PAGE_SIZE,
        noteIds: noteIds,
      })
      .then((res: any) => {
        store.dispatch({ type: actionTypes.LOAD_BASE_NOTES, params: res.data });
        return true;
      });
  }

  async fetchAndSetBaseSub(baseId: string) {
    return axiosInstance
      .get("/api/payments/baseSubscription", {
        params: {
          baseId: baseId,
        },
      })
      .then((res: any) => {
        store.dispatch({
          type: actionTypes.LOAD_BASE_SUBSCRIPTION,
          subscription: res.data,
        });
        return true;
      });
  }

  async fetchAndSetBaseViews(baseId: string) {
    return axiosInstance
      .get("/api/customWorkView", {
        params: {
          baseId,
        },
      })
      .then((res: any) => {
        store.dispatch({
          type: actionTypes.SET_WORK_VIEWS,
          param: {
            workViews: res.data.customWorkViews,
          },
        });
        return true;
      });
  }

  async fetchAndSetBaseTokens(baseId: string) {
    tokenApi.getTokensForBase(baseId);
  }

  async fetchAndSetBasePages(baseId: string) {
    return axiosInstance.get(`/api/document/all/${baseId}`).then((res) => {
      store.dispatch({
        type: actionTypes.LOAD_WORKSPACE_DOCUMENTS,
        documents: res.data,
      });
      return true;
    });
  }

  async fetchAndSetBaseGuestPages(baseId: string, pageIds: string[]) {
    return axiosInstance
      .post(`/api/document/all/guest/${baseId}`, {
        pageIds: pageIds,
      })
      .then((res) => {
        store.dispatch({
          type: actionTypes.LOAD_WORKSPACE_DOCUMENTS,
          documents: res.data,
        });
        return true;
      });
  }

  async fetchAndSetProjectChildren(baseId: string, startFrom: number) {
    await axiosInstance
      .get(`/api/project/projectsWithChildren`, {
        params: {
          baseId,
          startFrom,
        },
      })
      .then((res) => {
        store.dispatch({
          type: actionTypes.SET_BLOCK_OBJ,
          param: {
            blocks: res.data.payload.blocks,
          },
        });
        if (res.data.payload.count === 30)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          this.fetchAndSetProjectChildren(baseId, startFrom + 30);
      });
  }

  async recursiveFetchClosedTasks(
    baseId: string,
    groupIds: string[] = [],
    startFromId: string | null,
    startFromDate: Date | null
  ) {
    axiosInstance
      .get(`/api/project/closedTasks`, {
        params: {
          baseId,
          groupIds,
          startFromId,
          startFromDate,
          pageSize: WORK_PAGE_SIZE,
        },
      })
      .then((res) => {
        const payload = res.data.payload;
        store.dispatch({
          type: actionTypes.LOAD_TASKS,
          work: payload.closedTasks,
        });

        if (payload.closedTasks.length === WORK_PAGE_SIZE) {
          const lastEl = payload.closedTasks[payload.closedTasks.length - 1];
          const startFromId = lastEl.id;
          const startFromDate =
            lastEl.dateClosed || lastEl.dateModified || lastEl.dateCreated;
          this.recursiveFetchClosedTasks(
            baseId,
            groupIds,
            startFromId,
            startFromDate
          );
        }
        return true;
      });
  }

  async fetchAndSetBaseTasks(baseId: string, groupIds: string[] = []) {
    const blocker = axiosInstance
      .get(`/api/project/activeTasks`, { params: { baseId, groupIds } })
      .then((res) => {
        const payload = res.data.payload;
        batch(() => {
          store.dispatch({
            type: actionTypes.LOAD_TASKS,
            work: payload.allGroupsActiveTasks,
          });
          store.dispatch({
            type: actionTypes.LOAD_STATUSES,
            statuses: payload.statuses,
          });
        });
        return true;
      });
    if (groupIds && groupIds.length > 0)
      this.recursiveFetchClosedTasks(baseId, groupIds, null, null);
    return blocker;
  }

  async recursiveFetchClosedProjects(
    baseId: string,
    groupIds: string[] = [],
    startFromId: string | null,
    startFromDate: Date | null
  ) {
    axiosInstance
      .get(`/api/project/closedProjects`, {
        params: {
          baseId,
          groupIds,
          startFromId,
          startFromDate,
          ageSize: WORK_PAGE_SIZE,
        },
      })
      .then((res) => {
        const payload = res.data.payload;
        store.dispatch({
          type: actionTypes.LOAD_PROJECTS,
          work: payload.closedProjects,
        });

        if (payload.closedProjects.length === WORK_PAGE_SIZE) {
          const lastEl =
            payload.closedProjects[payload.closedProjects.length - 1];
          const startFromId = lastEl.id;
          const startFromDate =
            lastEl.dateClosed || lastEl.dateModified || lastEl.dateCreated;

          this.recursiveFetchClosedProjects(
            baseId,
            groupIds,
            startFromId,
            startFromDate
          );
        }
        return true;
      });
  }

  async fetchAndSetBaseProjects(baseId: string, groupIds: string[] = []) {
    if (groupIds.length === 0) {
      return;
    }
    const blocker = axiosInstance
      .get(`/api/project/activeProjects`, { params: { baseId, groupIds } })
      .then((res) => {
        const payload = res.data.payload;
        batch(() => {
          store.dispatch({
            type: actionTypes.LOAD_PROJECTS,
            work: payload.allGroupsActiveProjects,
            milestoneIds: payload.milestoneIds,
            milestonesDict: payload.milestonesDict,
          });
        });
        return true;
      });
    this.recursiveFetchClosedProjects(baseId, groupIds, null, null);
    return blocker;
  }

  async fetchAndSetBaseCycles(baseId: string, groupIds: string[]) {
    if (groupIds.length === 0) {
      return;
    }
    const blocker = axiosInstance
      .post(`/api/workSection/${baseId}`, {
        groupIds,
      })
      .then((res) => {
        store.dispatch({
          type: actionTypes.LOAD_BASE_CYCLES,
          cycles: res.data,
        });
      });
    return blocker;
  }

  async fetchAndSetGuestBaseWork(baseId: string, projectIds: string[]) {
    return axiosInstance
      .post(`/api/project/all/work/${baseId}`, {
        projectIds: projectIds,
      })
      .then((res) => {
        const work = res.data.payload;
        store.dispatch({
          type: actionTypes.LOAD_BASE_WORK,
          ...work,
        });
        // const workTreeView = prepareWorkTreeView(res.data.payload.work);
        // props.setBaseProjectTree(workTreeView);
        return true;
      });
  }

  async fetchAndSetFollowingContainers(baseId: string) {
    return axiosInstance
      .post("/api/follower", {
        baseId: baseId,
      })
      .then((res) => {
        batch(() => {
          store.dispatch({
            type: actionTypes.SET_FOLLOWING_PAGES,
            pages: res.data.documents,
          });
          store.dispatch({
            type: actionTypes.SET_FOLLOWING_NOTES,
            notes: res.data.notes,
          });
          store.dispatch({
            type: actionTypes.SET_FOLLOWING_WORK,
            work: res.data.projects,
          });
        });
        return true;
      });
  }

  async fetchAndSetBasePins(baseId: string) {
    return axiosInstance.get(`/api/pin/${baseId}`).then((res) => {
      batch(() => {
        store.dispatch({
          type: actionTypes.LOAD_BASE_PINS,
          pins: res.data.payload.pins,
        });
        if (res.data.payload.notes && res.data.payload.notes.length > 0) {
          store.dispatch({
            type: actionTypes.SET_NOTE_OBJECTS,
            params: {
              notes: res.data.payload.notes,
            },
          });
        }
      });
      return true;
    });
  }

  async fetchAndSetBaseFavorites(baseId: string) {
    return axiosInstance.get(`/api/favorite/${baseId}`).then((res) => {
      batch(() => {
        store.dispatch({
          type: actionTypes.LOAD_BASE_FAVORITES,
          favorites: res.data.favorites,
        });
        if (res.data.notes && res.data.notes.length > 0) {
          store.dispatch({
            type: actionTypes.SET_NOTE_OBJECTS,
            params: {
              notes: res.data.notes,
            },
          });
        }
      });
      return true;
    });
  }

  async fetchAndSetBaseResources(baseId: string) {
    return axiosInstance
      .get(`/api/resource/allResources/${baseId}`)
      .then((res) => {
        store.dispatch({
          type: actionTypes.LOAD_BASE_RESOURCES,
          resources: res.data.payload,
        });
        return true;
      });
  }

  async fetchAndSetUserBaseSettings(baseId: string) {
    return axiosInstance
      .get(`/api/user-base-settings`, {
        params: {
          baseId,
        },
      })
      .then((res) => {
        const settings = {
          ...res.data,
        };
        store.dispatch({
          type: actionTypes.SET_USER_BASE_SETTINGS,
          userBaseSettings: settings,
        });
        return true;
      });
  }

  async fetchAndSetGroups(baseId: string) {
    return axiosInstance
      .get(`/api/group/all/${baseId}`, {
        params: {
          baseId,
        },
      })
      .then((res) => {
        const groups = [...res.data];
        store.dispatch({
          type: actionTypes.SET_BASE_GROUPS,
          groups: groups,
        });
        return true;
      });
  }

  async createWeeklyNote(baseId: string, reset: boolean = true) {
    if (!baseId) {
      return;
    }
    return axiosInstance
      .post("/api/note/weeklyNote", {
        baseId: baseId,
      })
      .then((res) => {
        batch(() => {
          if (reset) {
            store.dispatch({
              type: actionTypes.RESET_NOTES,
            });
          }
          store.dispatch({
            type: actionTypes.LOAD_NOTE_WITHOUT_BLOCKS,
            note: res.data,
          });
        });
        return true;
      })
      .catch((e) => {
        console.log("Error creating weekly note", e);
      });
  }

  async joinBase(baseId: string) {
    const userId = store.getState().user?.id;

    return axiosInstance.post("/api/workspace/joinBase", {
      userId,
      baseId,
    });
  }

  getCurrentBase() {
    return store.getState().workspace;
  }

  async syncRecents(baseId: string) {
    const currentRecents = store.getState().recentlyOpened.dict[baseId];

    if (!currentRecents) return;

    const containers = currentRecents.map((recent) => {
      let containerType: any = recent.navigationChunk.entity?.containerType;
      if (recent.navigationChunk.taskViewMode === TasksViewModes.Cycle) {
        containerType = "Cycle";
      }
      if (recent.navigationChunk.taskViewMode === TasksViewModes.CustomView) {
        containerType = "CustomWorkView";
      }
      return {
        containerId: recent.navigationChunk.entity?.containerId,
        containerType,
        workType: recent.navigationChunk.entity?.workType,
      };
    });

    return axiosInstance
      .post("/api/container/sync-recents", {
        containers,
        baseId,
      })
      .then((res) => {
        const existing = res.data;

        const newRecent = currentRecents.filter((recent) => {
          return existing.includes(recent.navigationChunk.entity?.containerId);
        });
        store.dispatch({
          type: actionTypes.SET_RECENTLY_OPENED,
          recentlyOpened: newRecent,
          baseId,
        });
      });
  }

  async reloadGuestData(baseId: string, permissions: any) {
    const promises = [
      this.fetchAndSetUserBaseSettings(baseId),
      this.fetchAndSetGroups(baseId),
      this.fetchAndSetGuestBaseWork(baseId, permissions?.work?.read),
      this.fetchAndSetBaseGuestPages(baseId, permissions?.pages?.read),
      this.fetchAndSetBaseGuestNotes(baseId, permissions?.notes?.read),
      this.fetchAndSetBaseMembers(baseId),
      this.fetchAndSetBaseViews(baseId),
      roleApi.getRolesForBase(baseId),
      tokenGateApi.getBaseTokenGates(baseId),
      baseApi.syncRecents(baseId),
    ];

    await Promise.all([...promises])
      .then(() => {
        store.dispatch({
          type: actionTypes.SET_LAST_VISITED_BASE,
          param: {
            baseId: baseId,
          },
        });
      })
      .catch((e) => {});
    await baseApi.fetchAndSetFollowingContainers(baseId);
  }
}

export const baseApi = new BaseApi();
