import Icon from "@ant-design/icons";
import React from "react";

export default function TagsTwoTone(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        opacity="0.1"
        d="M11.1911 16.266L18.5012 8.95822L18.0559 3.69415L12.7918 3.24884L5.48169 10.559L11.1911 16.266ZM13.9098 6.52775C13.9606 6.33709 14.0608 6.16321 14.2003 6.0237C14.3398 5.88418 14.5137 5.78398 14.7043 5.73322C15.0934 5.63009 15.5059 5.74025 15.7918 6.02384C15.9317 6.16352 16.0322 6.33762 16.0832 6.52856C16.1342 6.7195 16.134 6.92053 16.0825 7.11134C16.0317 7.302 15.9315 7.47588 15.792 7.61539C15.6525 7.75491 15.4786 7.85511 15.2879 7.90587C14.8989 8.009 14.4864 7.89884 14.2004 7.61525C14.0614 7.47507 13.9614 7.30095 13.9104 7.11018C13.8594 6.91941 13.8592 6.71862 13.9098 6.52775Z"
        fill="currentColor"
      />
      <path
        opacity="0.1"
        d="M11.1703 18.577C11.1304 18.5723 11.0906 18.5535 11.0601 18.523L3.22732 10.6902C3.19243 10.655 3.17285 10.6074 3.17285 10.5578C3.17285 10.5082 3.19243 10.4606 3.22732 10.4254L12.0914 1.56133C12.1195 1.53086 12.1594 1.51211 12.2015 1.50742H12.1922C12.1383 1.50273 12.082 1.52148 12.0445 1.56133L3.18045 10.4254C3.14555 10.4606 3.12598 10.5082 3.12598 10.5578C3.12598 10.6074 3.14555 10.655 3.18045 10.6902L11.0133 18.523C11.0554 18.5676 11.114 18.584 11.1703 18.577Z"
        fill="currentColor"
      />
      <path
        d="M20.8528 12.652L19.9247 11.7262C19.8894 11.6913 19.8418 11.6718 19.7922 11.6718C19.7426 11.6718 19.6951 11.6913 19.6598 11.7262L11.1754 20.1942L5.60669 14.6395C5.57145 14.6046 5.52386 14.5851 5.47427 14.5851C5.42468 14.5851 5.37709 14.6046 5.34185 14.6395L4.41372 15.5653C4.37883 15.6005 4.35925 15.6481 4.35925 15.6977C4.35925 15.7473 4.37883 15.7949 4.41372 15.8302L10.1137 21.5208L11.0418 22.4466C11.1145 22.5192 11.234 22.5192 11.3067 22.4466L20.8528 12.9169C20.9254 12.8442 20.9254 12.7247 20.8528 12.652ZM15.2887 7.90593C15.4794 7.85517 15.6533 7.75496 15.7928 7.61545C15.9323 7.47594 16.0325 7.30206 16.0833 7.1114C16.1864 6.72234 16.0762 6.30984 15.7926 6.0239C15.6529 5.88407 15.4788 5.78357 15.2879 5.73254C15.097 5.68152 14.8959 5.68177 14.7051 5.73327C14.5145 5.78404 14.3406 5.88424 14.2011 6.02375C14.0616 6.16327 13.9614 6.33714 13.9106 6.52781C13.8075 6.91687 13.9176 7.32937 14.2012 7.61531C14.3414 7.75439 14.5155 7.85439 14.7063 7.90537C14.8971 7.95635 15.0979 7.95655 15.2887 7.90593Z"
        fill="currentColor"
      />
      <path
        d="M3.22732 10.4243C3.19243 10.4595 3.17285 10.5071 3.17285 10.5567C3.17285 10.6063 3.19243 10.6539 3.22732 10.6891L11.0601 18.5219C11.0906 18.5524 11.1281 18.5711 11.1703 18.5758C11.2265 18.5828 11.2828 18.5641 11.325 18.5219L20.189 9.65551C20.2289 9.61566 20.2476 9.56176 20.2429 9.50785L19.6453 2.44379C19.6289 2.26097 19.4859 2.11801 19.3031 2.1016L12.239 1.50629H12.2015C12.1594 1.51097 12.1219 1.52972 12.0914 1.56019L3.22732 10.4243ZM12.7922 3.24769L18.0562 3.69301L18.5015 8.95707L11.1914 16.2649L5.48201 10.5578L12.7922 3.24769Z"
        fill="currentColor"
      />
    </svg>
  );
}
