import { DollarCircleOutlined } from "@ant-design/icons";
import { MetadataBlock } from "clarity-ui/ContainerDetailMetadata";
import Modal from "clarity-ui/Modal";
import { RewardCard } from "clarity-ui/WorkReward";
import { TemplateRewardCard } from "clarity-ui/workReward/TemplateReward";
import Conditional from "components/Conditional";
import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useIsBaseMember } from "store/reducers/clientReducer";
import { useShallowSelector } from "utilities/hooks";
import { numberWithCommas } from "utilities/regexUtilities";
import { Abilities, ContainerTypes, IReward } from "utilities/types";

const RewardRow: React.FC<{
  reward?: IReward | null;
  containerId: string;
  containerType: ContainerTypes;
}> = ({ reward, containerId, containerType }) => {
  const showContributions = useShallowSelector(
    (store) => store.workspace.showContributions
  );
  const canManageReward = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_REWARDS,
  });

  const [rewardModal, setRewardModal] = useState(false);
  const isBaseMember = useIsBaseMember();

  const closeRewardModal = () => {
    setRewardModal(false);
  };

  const openRewardModal = () => {
    if (!isBaseMember) return;
    setRewardModal(true);
  };

  return (
    <>
      <Conditional on={showContributions}>
        <MetadataBlock
          title="Reward"
          disabled={!canManageReward}
          infoText={
            reward ? (
              `${numberWithCommas(reward.amount)} ${
                reward.symbol ?? reward.tokenName
              }`
            ) : (
              <span className="disabled">No reward </span>
            )
          }
          prefix={
            <DollarCircleOutlined
              className={reward ? "" : "disabled"}
              style={reward?.isPaid ? { color: "#70d0b6" } : undefined}
            />
          }
          onClick={openRewardModal}
        />
      </Conditional>
      {rewardModal &&
        ReactDOM.createPortal(
          <Modal
            padding={false}
            size="medium"
            hideModal={() => setRewardModal(false)}
          >
            <Conditional on={containerType === ContainerTypes.WORK}>
              <RewardCard
                workId={containerId}
                editing={reward?.id ? true : false}
                closeRewardModal={closeRewardModal}
              />
            </Conditional>
            <Conditional on={containerType === ContainerTypes.TEMPLATE}>
              <TemplateRewardCard
                key={containerId}
                editing={reward?.id ? true : false}
                closeRewardModal={closeRewardModal}
                templateItemId={containerId}
              />
            </Conditional>
          </Modal>,
          document.body
        )}
    </>
  );
};

export default RewardRow;
