import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { FileDoneOutlined } from "@ant-design/icons";
import { IProjectObj } from "utilities/types";
import { getHtml } from "editor/utils/blockValueHelpers";

interface Props {
  id: string;
}

const ProjectDisplay: React.FC<Props> = (props) => {
  const project: IProjectObj = useSelector(
    (state: any) => state.work.dict[props.id],
    shallowEqual
  );
  return (
    <>
      <FileDoneOutlined
        style={{
          color: "rgba(86,53,71,0.57)",
          fontSize: "0.9375rem",
          marginRight: "8px",
        }}
      />
      <span
        dangerouslySetInnerHTML={{ __html: getHtml(project.nameValue) }}
      ></span>
    </>
  );
};

export default ProjectDisplay;
