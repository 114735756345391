import React from "react";
import { BehaviorSubject } from "rxjs";
import { Block } from "store/reducers/blockReducer";
import { BlockMenuTypes, BlockStateMenu } from "utilities/types";
import LinkEntityMenu from "./blockMenuVariants/LinkEntityMenu";
import SlashCommandMenu from "./blockMenuVariants/SlashCommandMenu";
import SnippetMenu from "./blockMenuVariants/SnippetMenu";
import TagMenu from "./blockMenuVariants/TagMenu";
import UserMentionMenu from "./blockMenuVariants/UserMentionMenu";

export interface MenuProps {
  menuStateValue: React.MutableRefObject<BehaviorSubject<BlockStateMenu>>;
  blockRef: React.MutableRefObject<HTMLDivElement>;
  blockData: Block;
  context: any;
  menuState: BlockStateMenu;
  isFirst: boolean;
}

const BlockMenu: React.FC<MenuProps> = (props) => {
  if (props.menuState.type === BlockMenuTypes.snippetMenu)
    return <SnippetMenu {...props} />;
  if (props.menuState.type === BlockMenuTypes.linkEntityMenu)
    return <LinkEntityMenu {...props} />;
  if (props.menuState.type === BlockMenuTypes.userMentions)
    return <UserMentionMenu {...props} />;
  if (props.menuState.type === BlockMenuTypes.tagsMenu)
    return <TagMenu {...props} />;
  return <SlashCommandMenu {...props} />;
};

export default BlockMenu;
