import React, { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import styles from "./workBlockItem.module.scss";
import { stripHtml } from "utilities/stringUtilities";
import {
  CommandPaletteContext,
  ContainerTypes,
  ViewNames,
} from "utilities/types";
import { SHOW_COMMAND_PALETTE } from "store/actions";
import store, { ClarityStore } from "store/storeExporter";
import StatusDisplay from "components/StatusDisplay";
import DueDateDisplay from "clarity-ui/DueDateDisplay";
import UserDisplay from "clarity-ui/UserDisplay";
import { locationSubject } from "components/LocationListener";
import { getHtml } from "editor/utils/blockValueHelpers";
import { getCorrectLink } from "utilities/linkUtilities";
import { getCorrectPrefixForWorkItem } from "modules/containerHelpers";
import navigationApi from "clientApi/navigationApi";

const WorkBlockItem: React.FC<{
  workItemId: string;
  disableNavigation: boolean;
  paneId: string;
  canEdit: boolean;
}> = ({ workItemId, disableNavigation, paneId, canEdit }) => {
  const workItem = useSelector(
    (state: ClarityStore) => state.work.dict[workItemId],
    shallowEqual
  );

  const handleTaskClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (disableNavigation || !workItem.id) return;
      if (event.shiftKey) {
        if (paneId === "secondary") {
          const base = store.getState().workspace;
          locationSubject.next(getCorrectLink(base, workItem));
          return;
        } else {
          return navigationApi.openSplitView({
            viewName: ViewNames.Detail,
            containerId: workItem.id,
            containerType: ContainerTypes.WORK,
          });
        }
      }
      if (workItem.workType) {
        navigationApi.openPeekView({
          viewName: ViewNames.Detail,
          entity: {
            containerId: workItem.id,
            containerType: ContainerTypes.WORK,
            workType: workItem.workType,
          },
        });
      } else {
        const base = store.getState().workspace;
        locationSubject.next(getCorrectLink(base, workItem));
      }
    },
    [workItem.projectId]
  );

  if (!workItem) return <></>;

  return (
    <div
      className={`${styles.workItem} ${
        workItem.isClosed ? styles.isClosed : ""
      }`}
    >
      <div className={styles.workItem_head}>
        <div
          className={`${styles.selectable} ${styles.statusHolder}`}
          onClick={(e) => {
            if (!canEdit) return;
            e.stopPropagation();
            e.preventDefault();
            store.dispatch({
              type: SHOW_COMMAND_PALETTE,
              context: CommandPaletteContext.STATUS,
              params: {
                selectedItemIds: [workItem.id],
                slectedItemsType: ContainerTypes.WORK,
              },
            });
          }}
        >
          {workItem.statusId && (
            <StatusDisplay
              showName={false}
              noSpace={true}
              customStyle={{ marginRight: "0", height: "17px", width: "17px" }}
              size={"large"}
              statusId={workItem.statusId}
              workType={workItem.workType}
            />
          )}
        </div>
      </div>
      <div className={styles.workItem_body}>
        <span
          onClick={handleTaskClick}
          className={`body2 medium ${styles.caption} ${styles.selectable} ${styles.workNr}`}
          style={{ marginRight: "8px" }}
        >
          {getCorrectPrefixForWorkItem(workItem)}
        </span>
        <span
          className={`body2 medium ${styles.selectable}`}
          onClick={handleTaskClick}
        >
          {workItem.name}
        </span>
        {workItem.parentId && <ParentName parentId={workItem.parentId} />}
      </div>
      <div className={styles.workItem_tail}>
        <div className={styles.workItem_tail_items}>
          <div className={styles.workItem_tail_deadlineContainer}>
            <DueDateDisplay
              dueDate={workItem.dueDate}
              isClosed={workItem.isClosed}
            />
          </div>
          <div className={styles.workItem_tail_assigneeContainer}>
            {workItem.assignee && (
              <UserDisplay
                avatarSize={19}
                id={workItem.assigneeId}
                hideName={true}
                customStyles={{ verticalAlign: "initial", height: "100%" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ParentName: React.FC<{ parentId: string }> = ({ parentId }) => {
  const workItem = useSelector(
    (state: ClarityStore) => state.work.dict[parentId],
    shallowEqual
  );

  if (!workItem) return <></>;

  return (
    <>
      <span
        className={`body2 medium ${styles.caption}`}
        style={{ marginLeft: "8px" }}
      >
        {stripHtml(getHtml(workItem.nameValue))}
      </span>
    </>
  );
};

export default WorkBlockItem;
