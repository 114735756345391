import React from "react";

import styles from "./baseMenuItem/baseMenuItem.module.scss";
import { useHistory } from "react-router-dom";
import store from "store/storeExporter";
import { IUserObj } from "utilities/types";
import { getUserNotifications } from "modules/notificationsService";
import SuperSidebarItem from "screens/base/sidebar/superSidebar/SuperSidebarItem";
import navigationApi from "clientApi/navigationApi";

interface Props {
  workspace: any;
  setActiveWorkspace: (workspace: any) => void;
  setSwitchBaseMenuVisibility: (visible: boolean) => void;
  user: IUserObj;
}

const BaseMenuItem = (props: Props) => {
  const { workspace, setSwitchBaseMenuVisibility, user } = props;
  const history = useHistory();

  const handleClick = () => {
    const currentBase = store.getState().workspace;
    if (currentBase.id !== workspace.id) {
      // setActiveWorkspace(workspace);
      navigationApi.closeSplitViewNavigationChunk();
      getUserNotifications(user.id, workspace.id);
      history.push(`/${workspace.slug}/`);
    }
    setSwitchBaseMenuVisibility(false);
  };

  const generateMemberCountText = () => {
    if (workspace.memberCount === 1) {
      return "Only me";
    } else if (workspace.memberCount > 1) {
      return `${workspace.memberCount} members`;
    }
  };

  return (
    <div className={styles.baseMenuItem} onClick={handleClick}>
      <SuperSidebarItem
        baseId={workspace.id}
        isActive={false}
        isAvatar={true}
        isHoverable={false}
        noTooltip={true}
        noClick={true}
      />
      <div className={styles.baseMenuItem_detail}>
        <span className={"caption bold primary"}>{workspace.name}</span>
        <span
          className={"small regular secondary"}
        >{`${generateMemberCountText()}`}</span>
      </div>
    </div>
  );
};

export default BaseMenuItem;
