import store from "store/storeExporter";
import * as actionTypes from "store/actions";
import {
  MAX_FREE_BLOCK_COUNT,
  MAX_FREE_OPEN_WORK,
} from "store/reducers/subscriptionReducer";
import { axiosInstance } from "index";

export const syncData = (baseId: string) => {
  return axiosInstance
    .get("/api/payments/baseSubscription", {
      params: {
        baseId: baseId,
      },
    })
    .then((res: any) => {
      store.dispatch({
        type: actionTypes.LOAD_BASE_SUBSCRIPTION,
        subscription: res.data,
      });
      return true;
    });
};

export const checkIfCannotAddBlocks = (options?: { skipModal: boolean }) => {
  const newState = store.getState();
  if (
    !newState.subscription.isFree &&
    !newState.subscription.activeSubscription &&
    newState.subscription.monthlyCount.blockCount >= MAX_FREE_BLOCK_COUNT
  ) {
    if (!options?.skipModal) {
      store.dispatch({
        type: actionTypes.SET_DEFAULT_MODAL,
        param: {
          isOpen: true,
          type: "freeLimit",
        },
      });
    }

    return true;
  }

  return false;
};

export const checkIfCannotCreateWork = (options?: { skipModal: boolean }) => {
  const newState = store.getState();
  if (
    !newState.subscription.isFree &&
    !newState.subscription.activeSubscription &&
    newState.subscription.monthlyCount.workCount >= MAX_FREE_OPEN_WORK
  ) {
    if (!options?.skipModal) {
      store.dispatch({
        type: actionTypes.SET_DEFAULT_MODAL,
        param: {
          isOpen: true,
          type: "freeLimit",
        },
      });
    }
    return true;
  }
  return false;
};
