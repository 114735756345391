import recentlyOpenedApi from "clientApi/recentlyOpenedApi";
import Group from "components/GroupSetter";
import { useGuestVisibility } from "editor/utils/guestVisibilityHook/guestVisibilityHook";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { SET_LAST_VISITED_LOCATION } from "store/actions";
import { usePaneId } from "store/reducers/filterReducer";
import {
  groupGeneralTabs,
  homeGeneralTabs,
} from "store/reducers/recentlyOpenedReducer";
import { useBaseId } from "store/reducers/workspaceReducer";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import {
  ContainerTypes,
  GeneralViewsNames,
  GroupGeneralViews,
  NavigationChunk,
  PayoutsViewsNames,
  TasksViewModes,
} from "utilities/types";
import BaseOverview from "./main/BaseOverview";
import {
  IWorkViewConfig,
  viewModeToConfigDict,
} from "./main/defaultWorkView/types";
import DetailViewWrapper from "./main/detailView/DetailViewWrapper";
import GroupGallery from "./main/GroupGallery";
import GroupMembers from "./main/GroupMembers";
import GroupOverview from "./main/GroupOverview";
import Notes from "./main/Notes";
import Pages from "./main/Pages";
import BaseRoadmap from "./main/roadmap/modes/BaseRoadmap";
import GroupRoadmap from "./main/roadmap/modes/GroupRoadmap";
import MyRoadmap from "./main/roadmap/modes/MyRoadmap";
import RoleGallery from "./main/RoleGallery";
import SnippetsList from "./main/SnippetsList";
import TemplatesList from "./main/TemplatesList";
import WeeklyHoc from "./main/WeeklyHOC";
import WorkViewConfigurator from "./main/WorkViewConfigurator";
import WorkViewList from "./main/workViews/WorkViewList";
import Payouts from "./main/Payouts";
import Conditional from "components/Conditional";

const Navigator: React.FC = () => {
  const paneId = usePaneId();

  const baseId = useBaseId();

  const navigationChunk = useShallowSelector((state) => {
    if (
      state.navigation &&
      state.navigation.navigation &&
      state.navigation?.navigation[paneId]
    )
      return state.navigation?.navigation[paneId];
  });

  useLayoutEffect(() => {
    if (navigationChunk) {
      store.dispatch({
        type: SET_LAST_VISITED_LOCATION,
        baseId: baseId,
        location: navigationChunk,
      });
    }
  }, [navigationChunk, baseId]);

  if (!navigationChunk) {
    return <></>;
  }

  if (
    navigationChunk.entity &&
    navigationChunk.entity.containerType &&
    !["CustomView", "Cycle"].includes(navigationChunk.entity.containerType)
  ) {
    return (
      <ContainerNavigator
        containerType={navigationChunk.entity.containerType}
        containerId={navigationChunk.entity.containerId}
      />
    );
  }

  if (navigationChunk.groupSlug) {
    return <GroupNavigator navigationChunk={navigationChunk} paneId={paneId} />;
  }

  if (
    Object.values(PayoutsViewsNames).includes(
      navigationChunk.viewName as PayoutsViewsNames
    )
  ) {
    return (
      <PayoutsNavigator navigationChunk={navigationChunk} paneId={paneId} />
    );
  }

  if (navigationChunk.taskViewMode) {
    return (
      <WorkViewRouter
        mode={navigationChunk.taskViewMode}
        groupContext={navigationChunk.groupSlug}
        viewId={navigationChunk.entity?.containerId}
      />
    );
  }

  if (homeGeneralTabs.includes(navigationChunk.viewName)) {
    recentlyOpenedApi.setLastOpenedHomeLocation(navigationChunk);
  }

  return (
    <AllViewNavigator
      viewName={navigationChunk?.viewName}
      paneId={paneId}
      taskViewMode={navigationChunk?.taskViewMode}
      entityId={navigationChunk.entity?.containerId}
    />
  );
};

const AllViewNavigator: React.FC<{
  viewName?: string;
  paneId: ChunkDestination;
  taskViewMode?: TasksViewModes;
  entityId?: string;
}> = ({ viewName, paneId, taskViewMode, entityId }) => {
  useGuestVisibility();

  switch (viewName) {
    case GeneralViewsNames.Views:
      return <WorkViewList paneId={paneId} />;
    case GeneralViewsNames.Templates:
      return <TemplatesList paneId={paneId} />;
    case GeneralViewsNames.Snippets:
      return <SnippetsList paneId={paneId} />;
    case GeneralViewsNames.Home:
      return <BaseOverview paneId={paneId} />;
    case GeneralViewsNames.Roadmap:
      return <BaseRoadmap paneId={paneId} />;
    case GeneralViewsNames.Roles:
      return <RoleGallery paneId={paneId} />;
    case GeneralViewsNames.Groups:
      return <GroupGallery paneId={paneId} />;
    case GeneralViewsNames.MyProjects: {
      return <MyRoadmap paneId={paneId} />;
    }
    case GeneralViewsNames.TheWeekly: {
      return <WeeklyHoc />;
    }
    case GeneralViewsNames.Tasks:
    case GeneralViewsNames.CurrentTasks:
    case GeneralViewsNames.SomedayTasks:
    case GeneralViewsNames.TasksArchive:
    case GeneralViewsNames.NewCustomView:
    case GeneralViewsNames.ViewEntity:
      return <WorkViewRouter mode={taskViewMode} viewId={entityId} />;
    default:
      return <Pages paneId={paneId} />;
  }
};

const ContainerNavigator: React.FC<{
  containerType: ContainerTypes;
  containerId: string;
}> = ({ containerType, containerId }) => {
  return (
    <DetailViewWrapper
      containerType={containerType}
      containerId={containerId}
    />
  );
};

const GroupNavigator: React.FC<{
  navigationChunk: NavigationChunk;
  paneId: ChunkDestination;
}> = ({ navigationChunk, paneId }) => {
  const groupSlug = navigationChunk.groupSlug ?? "";
  useGuestVisibility();

  const getView = useCallback(() => {
    if (!navigationChunk) {
      return <></>;
    }
    if (groupGeneralTabs.includes(navigationChunk.viewName)) {
      recentlyOpenedApi.setLastOpenedGroupLocation(navigationChunk);
    }
    switch (navigationChunk.viewName) {
      case GroupGeneralViews.Notes:
        return <Notes paneId={paneId} groupSlug={groupSlug} />;
      case GroupGeneralViews.GroupProfile:
        return (
          <GroupOverview
            key={groupSlug}
            groupSlug={groupSlug}
            paneId={paneId}
          />
        );
      case GroupGeneralViews.Tasks:
      case GroupGeneralViews.CyclesActive:
      case GroupGeneralViews.CyclesNext:
      case GroupGeneralViews.CyclesClosed:
        return <WorkViewRouter mode={navigationChunk.taskViewMode} />;
      case GroupGeneralViews.Cycles:
      case GroupGeneralViews.CycleEntity:
        return (
          <WorkViewRouter
            mode={navigationChunk.taskViewMode}
            viewId={navigationChunk.entity?.containerId}
          />
        );
      case GroupGeneralViews.Projects:
        return <GroupRoadmap paneId={paneId} groupSlug={groupSlug} />;
      case GroupGeneralViews.Members:
        return <GroupMembers paneId={paneId} groupSlug={groupSlug} />;
      default:
        return (
          <GroupOverview
            key={groupSlug}
            groupSlug={groupSlug}
            paneId={paneId}
          />
        );
    }
  }, [navigationChunk?.viewName, navigationChunk?.groupSlug]);

  return useMemo(() => {
    if (!navigationChunk) {
      return <></>;
    }

    return (
      <Group groupSlug={groupSlug} groupId={navigationChunk.groupId}>
        {getView()}
      </Group>
    );
  }, [navigationChunk?.viewName, navigationChunk?.groupSlug, paneId]);
};

const WorkViewRouter: React.FC<{
  mode?: TasksViewModes;
  viewId?: string | undefined;
  context?: string | undefined;
  groupContext?: string;
}> = ({ mode, viewId, context, groupContext }) => {
  const [workViewDetails, setworkViewDetails] =
    useState<IWorkViewConfig | null>(null);

  const paneId = usePaneId();

  useEffect(() => {
    switch (mode) {
      case TasksViewModes.CustomView: {
        const toReturn = viewModeToConfigDict[TasksViewModes.CustomView](
          viewId,
          viewId
        );
        recentlyOpenedApi.addRecentlyOpened({
          navigationChunk: {
            viewName: GeneralViewsNames.ViewEntity,
            entity: {
              containerId: viewId ?? "",
              containerType: ContainerTypes.CUSTOM_VIEW,
            },
            taskViewMode: TasksViewModes.CustomView,
            groupSlug: groupContext,
          },
          name: "title" in toReturn ? toReturn.title : "",
        });
        return setworkViewDetails(toReturn);
      }
      case TasksViewModes.Cycle: {
        const toReturn = viewModeToConfigDict[TasksViewModes.Cycle](
          paneId,
          viewId
        );
        recentlyOpenedApi.addRecentlyOpened({
          navigationChunk: {
            viewName: GroupGeneralViews.CycleEntity,
            entity: {
              containerId: viewId ?? "",
              containerType: ContainerTypes.CYCLE,
            },
            taskViewMode: TasksViewModes.Cycle,
            groupSlug: groupContext,
          },
          name: "title" in toReturn ? toReturn.title : "",
        });
        return setworkViewDetails(toReturn);
      }
      case TasksViewModes.ProjectWork:
        return setworkViewDetails(
          viewModeToConfigDict[TasksViewModes.ProjectWork](viewId ?? "")
        );
      case TasksViewModes.DueThisWeek:
        return setworkViewDetails(
          viewModeToConfigDict[TasksViewModes.DueThisWeek](context)
        );
      default: {
        if (mode) setworkViewDetails(viewModeToConfigDict[mode](paneId));
      }
    }
  }, [mode, viewId, groupContext]);

  if (!workViewDetails) return <></>;
  return (
    <>
      <WorkViewConfigurator paneId={paneId} workViewDetails={workViewDetails} />
    </>
  );
};

export const GroupIdContext = React.createContext<string | undefined>(
  undefined
);

export const GroupIdContextSetter = React.createContext<
  (groupId: string | undefined) => void
>(() => {});

const PayoutsNavigator: React.FC<{
  navigationChunk: NavigationChunk;
  paneId: ChunkDestination;
}> = ({ navigationChunk, paneId }) => {
  const [groupId, setgroupId] = useState<string | undefined>(undefined);
  const groups = useShallowSelector((store) => store.groups.dict);

  const getGroupSlugFromId = (groupId: string | undefined) => {
    return groupId ? groups[groupId]?.slug : "";
  };

  const getContent = () => {
    if (navigationChunk.taskViewMode) {
      return (
        <WorkViewRouter
          mode={navigationChunk.taskViewMode}
          groupContext={getGroupSlugFromId(groupId)}
          viewId={navigationChunk.entity?.containerId}
        />
      );
    }
    return <Payouts paneId={paneId} />;
  };

  return (
    <GroupIdContextSetter.Provider value={setgroupId}>
      <GroupIdContext.Provider value={groupId}>
        <div
          className="flex-fill full-flex"
          style={{ flexDirection: "column" }}
        >
          <Conditional on={!groupId}>{getContent()}</Conditional>
          <Conditional on={groupId}>
            <Group
              groupId={groupId}
              groupSlug={getGroupSlugFromId(groupId as string)}
            >
              {getContent()}
            </Group>
          </Conditional>
        </div>
      </GroupIdContext.Provider>
    </GroupIdContextSetter.Provider>
  );
};

export default Navigator;
