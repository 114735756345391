const handledEvent = (e: Event) => {
  e.stopPropagation();
  e.preventDefault();
};

export const addNoScrollListener = (element: HTMLDivElement) => {
  // var x = element.scrollTop;
  // var y = element.scrollLeft;
  element.addEventListener("scroll", handledEvent);
  element.addEventListener("DOMMouseScroll", handledEvent); // older FF
  element.addEventListener("wheel", handledEvent); // modern desktop
  element.addEventListener("mousewheel", handledEvent); // modern desktop
  element.addEventListener("touchmove", handledEvent); // mobile
};

export const removeNoScrollListener = (element: HTMLDivElement) => {
  element.removeEventListener("scroll", handledEvent);
  element.removeEventListener("DOMMouseScroll", handledEvent); // older FF
  element.removeEventListener("wheel", handledEvent); // modern desktop
  element.removeEventListener("mousewheel", handledEvent); // modern desktop
  element.removeEventListener("touchmove", handledEvent); // mobile
};
