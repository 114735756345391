import Icon from "@ant-design/icons";
import React from "react";

export default function ChevronDown(
  props: React.ComponentProps<typeof Icon>
): JSX.Element {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      width="1.1em"
      height="1.1em"
      viewBox="0 0 13 7"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Doc/Mentions"
          transform="translate(-340.000000, -523.000000)"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(279.000000, 183.000000)">
            <g
              id="chevron-up"
              transform="translate(67.500000, 343.500000) scale(1, -1) translate(-67.500000, -343.500000) translate(61.000000, 337.000000)"
            >
              <rect
                id="Rectangle"
                fill="currentColor"
                opacity="0"
                x="0"
                y="0"
                width="13"
                height="13"
              />

              <path
                d="M6.9309043,3.31408594 L11.8653945,8.24857617 C12.1033809,8.4865625 12.1033809,8.87239844 11.8653945,9.11035938 L11.2898652,9.68588867 C11.0522852,9.92346875 10.6672363,9.92392578 10.4290977,9.6869043 L6.5,5.77624023 L2.57092773,9.68692969 C2.33278906,9.92395117 1.94774023,9.92349414 1.71016016,9.68591406 L1.13463086,9.11038477 C0.896644531,8.87239844 0.896644531,8.4865625 1.13463086,8.24860156 L6.06912109,3.31411133 C6.30708203,3.076125 6.69291797,3.076125 6.9309043,3.31408594 Z"
                id="Path"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
