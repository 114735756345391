import { useRef } from "react";

const { v4: uuidv4 } = require("uuid");

export default function LightStatusCompleted() {
  const idRef = useRef(uuidv4());
  const pathIdRef = useRef(uuidv4());

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>light-status-completed</title>
      <defs>
        <path
          d="M25.5904322,7.99960938 L23.1852265,7.99960938 C22.6621327,7.99960938 22.1646808,8.24616964 21.8569786,8.66884438 L13.7951804,19.6382602 L10.1437806,14.6668002 C9.83607842,14.2491573 9.34375486,13.9975652 8.81553271,13.9975652 L6.410327,13.9975652 C6.07698293,13.9975652 5.88210486,14.3699215 6.07698293,14.6366091 L12.4669324,23.3316323 C13.1182355,24.2222684 14.4669969,24.2222684 15.1182999,23.3316323 L25.9186479,8.63865332 C26.1186544,8.37196569 25.9237763,7.99960938 25.5904322,7.99960938 Z"
          id={pathIdRef.current}
        ></path>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop-HD-Copy-3"
          transform="translate(-617.000000, -833.000000)"
        >
          <g
            id="light-status-completed"
            transform="translate(617.000000, 833.000000)"
          >
            <circle
              id="Oval-Copy-3"
              stroke="#60BAA2"
              strokeWidth="2"
              fill="#6ACEB3"
              cx="16"
              cy="16"
              r="15"
            ></circle>
            <g id="Icon/Task-Copy">
              <mask id={idRef.current} fill="white">
                <use xlinkHref={`#${pathIdRef.current}`}></use>
              </mask>
              <g id="Shape" fillRule="nonzero"></g>
              <g
                id="Group"
                mask={`url(#${idRef.current})`}
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="Fill/SidebarIcon/Inactive">
                  <rect
                    id="Fill/White/Default"
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
