import _ from "lodash";
import {
  ADD_ROLES,
  DELETE_ROLES,
  SET_ACTIVE_WORKSPACE,
  SET_BASE_ROLES,
  UPDATE_ROLE,
  UPDATE_ROLES,
} from "store/actions";
import { IRole } from "utilities/types";

export interface RoleEntry {
  [key: string]: IRole;
}

export interface IInitialState {
  dict: RoleEntry;
  ids: string[];
}

const initialState: IInitialState = {
  dict: {} as RoleEntry,
  ids: [],
};

interface ISetBaseRoleActionParameters {
  type: string;
  roles: IRole[];
}

interface IUpdateRoleActionParameters {
  delta: Partial<IRole>;
  deltas: Partial<IRole>[];
  id: string;
}

interface IAddNewRoles {
  newRoles: IRole[];
}

interface IDeleteRoles {
  deletedRoleIds: string[];
}

export default function roleReducer(
  state = initialState,
  action: ISetBaseRoleActionParameters &
    IUpdateRoleActionParameters &
    IAddNewRoles &
    IDeleteRoles
) {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE: {
      return initialState;
    }
    case SET_BASE_ROLES: {
      const newState = {
        ...initialState,
      };

      if (action.roles && Array.isArray(action.roles)) {
        const ids = action.roles.map((role) => role.id);
        newState.ids = _.uniq(ids);

        for (const role of action.roles) {
          if (newState.dict[role.id]) {
            newState.dict[role.id] = {
              ...newState.dict[role.id],
              ...role,
            };
          } else {
            newState.dict[role.id] = role;
          }
        }
        return newState;
      }
      return newState;
    }
    case UPDATE_ROLE: {
      const newState = {
        ...state,
      };

      if (action.delta && action.id && newState.dict[action.id]) {
        newState.dict[action.id] = {
          ...newState.dict[action.id],
          ...action.delta,
        };
      }
      return newState;
    }
    case UPDATE_ROLES: {
      const newState = {
        ...state,
      };

      if (action.deltas) {
        for (const delta of action.deltas) {
          if (delta.id) {
            newState.dict[delta.id] = {
              ...newState.dict[delta.id],
              ...delta,
            };
          }
        }
      }
      return newState;
    }
    case ADD_ROLES: {
      const newState = {
        ...state,
      };

      if (action.newRoles) {
        newState.ids = _.uniq([
          ...newState.ids,
          ...action.newRoles.map((i) => i.id),
        ]);

        for (const role of action.newRoles) {
          newState.dict[role.id] = {
            ...role,
          };
        }
      }
      return newState;
    }
    case DELETE_ROLES: {
      const newState = {
        ...state,
      };

      if (action.deletedRoleIds) {
        newState.ids = newState.ids.filter(
          (id) => !action.deletedRoleIds.includes(id)
        );

        for (const id of action.deletedRoleIds) {
          delete newState.dict[id];
        }
      }
      return newState;
    }
    default:
      return state;
  }
}
