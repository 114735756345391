import React from "react";
import ModalScrimComponent from "components/ModalScrimComponent";
import { ContainerTypes, NavigationChunk } from "utilities/types";
import styles from "./peekView.module.scss";

import { useIsMobile } from "utilities/hooks";
import navigationApi from "clientApi/navigationApi";
import Navigator from "../Navigator";
import { PaneContext } from "App";
import { ChunkDestination } from "utilities/stateTypes";
import { VALUE_ESCAPE } from "keycode-js";
import PeekViewHeader from "./PeekViewHeader";

const PeekViewModal: React.FC<{ navigationChunk: NavigationChunk }> = ({
  navigationChunk,
}) => {
  const isMobile = useIsMobile();
  const hideModal = () => navigationApi.closePeekView();

  return (
    <div
      style={{
        display: "flex",
        zIndex: 99,
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
      onKeyDown={(e) => {
        if (e.key === VALUE_ESCAPE) {
          navigationApi.closePeekView();
        }
      }}
    >
      <div
        className={`${
          styles.item_preview_modal
        } document-view task-modal peekView ${
          isMobile ? styles.item_preview_modal__mobile : ""
        }`}
      >
        <div className={styles.taskBodyContainer + " commentContainer"}>
          <PeekViewHeader navigationChunk={navigationChunk} />
          <PaneContext.Provider value={ChunkDestination.peek}>
            <Navigator />
          </PaneContext.Provider>
        </div>
      </div>
      <ModalScrimComponent
        taskId={
          navigationChunk.entity?.containerType === ContainerTypes.PROJECT ||
          navigationChunk.entity?.containerType === ContainerTypes.TASK ||
          navigationChunk.entity?.containerType === ContainerTypes.WORK
            ? navigationChunk.entity.containerId
            : undefined
        }
        hideModal={hideModal}
      />
    </div>
  );
};

export default PeekViewModal;
