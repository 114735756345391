import { BehaviorSubject } from "rxjs";
import {
  ContainerVisibility,
  PermissionTypes,
  UserRole,
} from "utilities/types";
import {
  EditOutlined,
  CommentOutlined,
  UserOutlined,
  LockFilled,
  DeleteOutlined,
  GlobalOutlined,
  TeamOutlined,
  PlusOutlined,
  CloseOutlined,
  UsergroupAddOutlined,
  LinkOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import store, { ClarityStore } from "store/storeExporter";
import { useState } from "react";
import { useShallowSelector } from "utilities/hooks";

export type Permission = "edit" | "suggest" | "locked" | "read";

export const permToPermissionType = {
  suggest: PermissionTypes.SUGGEST,
  edit: PermissionTypes.EDIT,
  read: PermissionTypes.READ,
  locked: PermissionTypes.NO_EDIT,
};

export const TokenPerMissionDisplay: Record<Permission, string> = {
  edit: "edit",
  suggest: "comment",
  read: "view",
  locked: "cannot view",
};

export const DropDownType = {
  public: "Public",
  guest: "Guest",
};
export let loadingData = new BehaviorSubject(false);

export let visibilityData = new BehaviorSubject(ContainerVisibility.canView);
export function useEditable() {
  const userRole = store.getState().client.roleType;

  return {
    editable: userRole !== UserRole.GUEST,
  };
}

export function useChangeDeltas() {
  const [deltas, setDeltas] = useState<any>([]);

  return {
    deltas,
    setDeltas,
  };
}

export {
  EditOutlined,
  CommentOutlined,
  UserOutlined,
  LockFilled,
  GlobalOutlined,
  TeamOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
  UsergroupAddOutlined,
  LinkOutlined,
  CheckOutlined,
};
export function useGuestSearchContextValue() {
  const [isEmail, setIsEmail] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  return {
    searchValue,
    setSearchValue,
    isEmail,
    setIsEmail,
  };
}

export function useSelectedWorkspace() {
  return useShallowSelector((state: ClarityStore) => state.workspace.id);
}

export interface IMenuItem {
  title: string;
  description: string;
  icon: any;
  key: ContainerVisibility;
}
