import React, { useCallback, useEffect, useRef, useState } from "react";
import store, { ClarityStore } from "store/storeExporter";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router";
import { locationSubject } from "components/LocationListener";
import { SET_AUTHENTICATED_USER } from "store/actions";
import Button, { ButtonTypes } from "components/Button";
import TokenGate, { TokenGateReason } from "./TokenGate";
import { ITokenGateRule, ITokenRequirement, IUserObj } from "utilities/types";
import DefaultPageHoc from "./templates/DefaultPageHoc";
import BaseAvatar from "components/BaseAvatar";
import SignUpLoginSwitcher from "components/SignUpLoginSwitcher";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import styles from "./templates/defaultPageHoc/defaultPageHoc.module.scss";
import { WarningTwoTone } from "@ant-design/icons";
import { axiosInstance } from "index";

interface ILinkError {
  type: LinkRedeemErrors;
  message: string;
}

enum LinkRedeemErrors {
  LINK_NOT_VALID = "LINK_NOT_VALID",
  LINK_NOT_FOUND = "LINK_NOT_FOUND",
  USER_ALREADY_MEMBER_OF_BASE = "USER_ALREADY_MEMBER_OF_BASE",
  LINK_NOT_UPDATED = "LINK_NOT_UPDATED",
  ROLE_ASSIGNMENT_NOT_CREATED = "ROLE_ASSIGNMENT_NOT_CREATED",
}

const LinkInvitationRedeem: React.FC<{}> = () => {
  const param: any = useParams();
  const isClicked = useRef(false);
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);
  const [error, seterror] = useState<ILinkError | null>(null);
  const [invitationData, setinvitationData] = useState<any | null>(null);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    setisLoading(true);
    axiosInstance
      .get(`/api/invite-link/${param.token}`)
      .then((res) => {
        setinvitationData(res.data);
        setisLoading(false);
      })
      .catch((err) => {
        seterror({
          message: err.response.data.message as string,
          type: err.response.data.code as LinkRedeemErrors,
        });
        setisLoading(false);
      });
  }, []);

  const redeemInvitation = useCallback(() => {
    if (isClicked.current) return;
    isClicked.current = true;
    const params = {
      token: param.token,
    };
    axiosInstance
      .post("/api/invite-link/redeem", {
        ...params,
      })
      .then((res) => {
        const baseSlug = res.data?.baseSlug;
        return axiosInstance.get("/api/user").then((res) => {
          const { payload: user } = res.data;
          store.dispatch({
            type: SET_AUTHENTICATED_USER,
            user,
            forceUpdate: true,
          });
          if (baseSlug) locationSubject.next(`/${baseSlug}`);
          else locationSubject.next("/");
          isClicked.current = true;
        });
      })
      .catch((err) => {
        isClicked.current = true;
        seterror({
          message: err.response.data.message as string,
          type: err.response.data.code as LinkRedeemErrors,
        });
      });
  }, []);

  if (error) {
    return (
      <DefaultPageHoc cardOutline showLogo>
        <div className={styles.errorIcon}>
          <WarningTwoTone
            twoToneColor={["var(--icon-color)", "var(--icon-color)"]}
          />
        </div>
        <h1 className={styles.h1}>Link error</h1>
        <p className={styles.error}>{error.message}</p>
      </DefaultPageHoc>
    );
  }

  if (!invitationData || isLoading) return <></>;

  return (
    <DefaultPageHoc cardBackground cardOutline showLogo>
      <div>
        <BaseAvatar
          base={invitationData.inviteLink.workspace}
          className={styles.baseAvatar}
          aviBorderStyles={{ borderRadius: "20px", display: "none" }}
        />
      </div>
      <h1 className={styles.h1}>
        Join {invitationData.inviteLink.workspace.name}
      </h1>
      <p
        className={`${styles.description} ${styles.alignCenter} caption regular secondary`}
      >
        {invitationData.inviteLink.workspace.tagline}
      </p>
      <div className={styles.divider}></div>
      {!error && (
        <ConditionalTokenGate
          tokenRequirements={
            invitationData.inviteLink?.tokenGate?.tokenRequirements
          }
          rule={invitationData.inviteLink?.tokenGate?.rule}
        >
          {!user || !user.id ? (
            <>
              <SignUpLoginSwitcher onAcceptInvitation />
              <div
                className="caption regular disabled"
                style={{ textAlign: "center" }}
              >
                You must be logged into Clarity to join this base. You can log
                into an existing account, or create a new one using the buttons
                above.
              </div>
            </>
          ) : (
            <JoinSection
              error={error}
              hasTokenGate={invitationData.inviteLink?.tokenGate}
              redeemInvitation={redeemInvitation}
              user={user}
            />
          )}
        </ConditionalTokenGate>
      )}
    </DefaultPageHoc>
  );
};

const JoinSection: React.FC<{
  error: ILinkError | null;
  redeemInvitation: () => void;
  user: IUserObj;
  hasTokenGate: boolean;
}> = ({ error, redeemInvitation, user, hasTokenGate }) => {
  return (
    <>
      {error && <p className={styles.error}>{error.message}</p>}
      {!error && (
        <Button
          buttonType={ButtonTypes.LARGE_PRIMARY}
          onClick={() => redeemInvitation()}
        >
          Join
        </Button>
      )}

      <div
        className="caption regular disabled"
        style={{ textAlign: "center", paddingTop: "72px" }}
      >
        You are logged in as{" "}
        <b>{usernameSplitter(user.name || "@" + user.username)}</b>{" "}
        {hasTokenGate && (
          <>
            and your connected wallet meets the token requirements to join this
            base.
          </>
        )}
      </div>
    </>
  );
};

const ConditionalTokenGate: React.FC<{
  tokenRequirements: ITokenRequirement[] | undefined;
  rule: ITokenGateRule | undefined;
}> = ({ tokenRequirements, rule, children }) => {
  if (tokenRequirements) {
    return (
      <TokenGate
        tokenRequirements={tokenRequirements}
        linkRedeem={true}
        reason={TokenGateReason.joinBase}
        rule={rule}
      >
        {children}
      </TokenGate>
    );
  } else {
    return <>{children}</>;
  }
};

export default LinkInvitationRedeem;
