import React from "react";
import { GroupOutlined } from "@ant-design/icons";
import { Menu, MenuItem } from "components/FabBtn";
import EditTwoTone from "icons/Components/EditTwoTone";

const ProfileMoreMenu: React.FC<{
  seteditProfile: React.Dispatch<React.SetStateAction<boolean>>;
  seteditCollections: React.Dispatch<React.SetStateAction<boolean>>;
  hide: () => void;
}> = ({ seteditProfile, seteditCollections, hide }) => {
  return (
    <Menu slim={true} close={hide} yDir="down" xDir="left">
      <MenuItem
        title="Edit profile"
        icon={<EditTwoTone />}
        onClick={() => {
          seteditProfile(true);
          hide();
        }}
      />
      <MenuItem
        title="Edit collections"
        icon={<GroupOutlined />}
        onClick={() => {
          seteditCollections(true);
          hide();
        }}
      />
    </Menu>
  );
};

export default ProfileMoreMenu;
