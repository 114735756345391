import React, { useEffect, useState } from "react";
import Button, { ButtonTypes } from "../components/Button";
import {
  GlobalOutlined,
  DisconnectOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import store from "../store/storeExporter";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import { copyTextToClipboard } from "utilities/copyUtils";
import { removeTag } from "editor/utils/caretUtils";
import { axiosInstance } from "index";

const LinkPreviewCard: React.FC<any> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoaded, setisLoaded] = useState(false);
  const [linkDetails, setlinkDetails] = useState<any>({});
  const dispatcher = useDispatch();
  useEffect(() => {
    axiosInstance
      .get(`/api/preview/link`, {
        params: {
          linkString: props.popupData.linkTo,
        },
      })
      .then((res) => {
        setlinkDetails(res.data);
        setisLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className={
            linkDetails
              ? `" linkDetailsCard_isLinkDetails linkDetailsCard__top"`
              : `"linkDetailsCard_LinkDetails linkDetailsCard__top"`
          }
        >
          <div style={{ display: "flex", flexGrow: 1 }}>
            <div className="linkDetailsCard__top__icon">
              <GlobalOutlined />
            </div>
            <div className="linkDetailsCard__top__url">
              <a
                href={props.popupData.linkTo}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const wind = window.open(e.target.href, "_blank");
                  if (!(e.metaKey || e.ctrlKey)) {
                    if (wind) wind.focus();
                  }
                }}
              >
                {props.popupData.linkTo}
              </a>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Button
              buttonType={ButtonTypes.LINK}
              style={{
                marginTop: "-8px",
                marginRight: "-4px",
                marginBottom: "-12px",
              }}
              icon={<CopyOutlined />}
              onClick={(e: any) => {
                copyTextToClipboard(props.popupData.linkTo);
              }}
            />
            <Button
              buttonType={ButtonTypes.LINK}
              icon={<DisconnectOutlined />}
              style={{
                marginTop: "-8px",
                marginRight: "-4px",
                marginBottom: "-12px",
              }}
              onClick={(e: any) => {
                const stateDate = store.getState();
                let blockData = getBlockById(
                  stateDate.blocks.dict,
                  props.popupData.blockId
                );

                if (props.popupData.focusElement) {
                  removeTag(
                    blockData,
                    props.popupData.focusElement,
                    dispatcher,
                    props.popupData.destination
                  );
                }
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        </div>
        <div style={{ flex: "1 1 0px" }}></div>
      </div>
      {linkDetails.title && (
        <a
          href={props.popupData.linkTo}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const wind = window.open(props.popupData.linkTo, "_blank");
            if (!(e.metaKey || e.ctrlKey)) {
              if (wind) wind.focus();
            }
          }}
        >
          <div className="linkDetailsCard__bottom">
            {linkDetails.image && (
              <div
                style={{
                  height: "58px",
                  width: "72px",
                  minWidth: "72px",
                  marginLeft: "2px",
                  marginTop: "1px",
                  borderRadius: "4px",
                  backgroundImage: `url('${linkDetails.image}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              ></div>
            )}
            <div style={{ paddingLeft: linkDetails.image ? "11px" : "4px" }}>
              <div className="caption primary bold linkDetailsCard__bottom__title">
                {linkDetails.title}
              </div>
              <div className="small secondary linkDetailsCard__bottom__description">
                {linkDetails.description
                  ? linkDetails.description
                  : "No description"}
              </div>
            </div>
          </div>
        </a>
      )}
    </>
  );
};

export default LinkPreviewCard;
