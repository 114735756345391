import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import ConfirmModal from "clarity-ui/ConfirmModal";
import notificationsApi from "clientApi/notificationsApi";
import { userApi } from "clientApi/userApi";
import Button, { ButtonTypes } from "components/Button";
import ClarityInput from "components/ClarityInput";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { PasswordRequirements } from "screens/Signup";
import { ClarityStore } from "store/storeExporter";
import { validatePassword } from "utilities/regexUtilities";
import { IMesssageInterface, IUserObj } from "utilities/types";

const PasswordChange: React.FC = () => {
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);
  const [currentPassword, setcurrentPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [message, setmessage] = useState<IMesssageInterface | null>(null);
  const [clearToggle, setClearToggle] = useState(false);
  const [showRemovePassword, setshowRemovePassword] = useState(false);

  useEffect(() => {
    if (clearToggle) {
      setcurrentPassword("");
      setnewPassword("");
      setmessage(null);
      setshowRemovePassword(false);
    }
  }, [clearToggle]);

  if (!user) return <></>;

  if (!user?.hasPassword) return <CreatePassword user={user} />;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "28px" }}>
      <div style={{ width: "50%" }}>
        <ClarityInput
          label="Current Password"
          type="password"
          value={currentPassword}
          onChange={(e) => {
            setcurrentPassword(e.target.value);
            setmessage(null);
          }}
        />
        {message !== null && message.info && (
          <ClarityLabel message={message.info} type={MessageLabelTypes.error} />
        )}
      </div>
      <ConfirmPassword
        setnewPassword={setnewPassword}
        clearToggle={clearToggle}
      />
      <div style={{ display: "flex", gap: "16px", marginTop: "-30px" }}>
        <Button
          style={{ width: "154px" }}
          buttonType={ButtonTypes.LARGE_PRIMARY}
          disabled={!newPassword || !currentPassword || message !== null}
          onClick={() =>
            userApi
              .tryChangePassword(currentPassword, newPassword)
              .then((res) => {
                if (res.data.status === 1) {
                  notificationsApi.displayConfirmation({
                    title: <span>{res.data.info}</span>,
                  });
                  setClearToggle(!clearToggle);
                } else {
                  setmessage({
                    info: res?.data.info,
                    status: res?.data.status,
                  });
                }
              })
          }
        >
          Change Password
        </Button>
        <Button
          style={{ width: "154px" }}
          buttonType={ButtonTypes.LARGE_SECONDARY}
          onClick={() => setshowRemovePassword(true)}
        >
          Remove Password
        </Button>
        {showRemovePassword && (
          <RemovePassword setshowRemovePassword={setshowRemovePassword} />
        )}
      </div>
    </div>
  );
};

const RemovePassword: React.FC<{
  setshowRemovePassword: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setshowRemovePassword }) => {
  const [message, setmessage] = useState<IMesssageInterface | null>(null);
  const [currentPassword, setcurrentPassword] = useState("");

  const confirm = useCallback((currentPassword) => {
    userApi
      .removePassword(currentPassword)
      .then((res) => {
        if (res.data.status === 0) {
          setmessage(res.data);
          return;
        }
        notificationsApi.displayConfirmation({
          title: <span>Password removed</span>,
        });
        setshowRemovePassword(false);
      })
      .catch((res: IMesssageInterface) => {
        setmessage(res);
      });
  }, []);

  return (
    <ConfirmModal
      title="Remove password"
      close={() => setshowRemovePassword(false)}
      onConfirm={() => confirm(currentPassword)}
      preventDefaultCloseOnConfirm={true}
      confirmDisabled={currentPassword.length === 0}
      confirmationText="Remove password"
      primarylabelStyle={{ paddingLeft: "6px", paddingRight: "6px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <span>
          This process will remove your password. When you want to access
          Clarity, we’ll send single-use login codes to your email inbox.
        </span>
        <div>
          <ClarityInput
            label="Current Password"
            type="password"
            value={currentPassword}
            onChange={(e) => {
              setcurrentPassword(e.target.value);
              setmessage(null);
            }}
          />
          {message !== null && message.info && (
            <ClarityLabel
              message={message.info}
              type={MessageLabelTypes.error}
            />
          )}
        </div>
      </div>
    </ConfirmModal>
  );
};

const CreatePassword: React.FC<{ user: IUserObj }> = ({ user }) => {
  const [showFields, setshowFields] = useState(false);
  const [newPassword, setnewPassword] = useState("");

  if (!showFields)
    return (
      <Button
        buttonType={ButtonTypes.LARGE_SECONDARY}
        onClick={() => setshowFields(true)}
        style={{ width: "154px" }}
      >
        Create a password
      </Button>
    );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ConfirmPassword setnewPassword={setnewPassword} />
      <div style={{ display: "flex", gap: "16px" }}>
        <Button
          buttonType={ButtonTypes.LARGE_PRIMARY}
          style={{ width: "154px" }}
          disabled={!newPassword || newPassword === ""}
          onClick={() =>
            userApi.tryChangePassword(null, newPassword).then(() => {
              notificationsApi.displayConfirmation({
                title: <span>Password created</span>,
              });
            })
          }
        >
          Save
        </Button>
        <Button
          buttonType={ButtonTypes.LARGE_SECONDARY}
          onClick={() => setshowFields(false)}
          style={{ width: "154px" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const ConfirmPassword: React.FC<{
  setnewPassword: React.Dispatch<React.SetStateAction<string>>;
  clearToggle?: boolean;
}> = ({ setnewPassword, clearToggle }) => {
  const [password, setpassword] = useState("");
  const [repeatPassword, setrepeatPassword] = useState("");
  const [confirmPasswordWasFocused, setConfirmPasswordWasFocused] =
    useState(false);

  useEffect(() => {
    const isValid = validatePassword(password);
    if (password === repeatPassword && isValid) {
      setnewPassword(password);
    } else setnewPassword("");
  }, [password, repeatPassword]);

  useEffect(() => {
    if (clearToggle) {
      setpassword("");
      setrepeatPassword("");
      setConfirmPasswordWasFocused(false);
    }
  }, [clearToggle]);

  return (
    <div style={{ display: "flex", gap: "16px" }}>
      <div
        style={{
          width: "100%",
          gap: "18px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ClarityInput
          label="New Password"
          type="password"
          value={password}
          onChange={(e) => setpassword(e.target.value)}
        />
        <PasswordRequirements password={password} />
      </div>
      <div style={{ width: "100%" }}>
        <ClarityInput
          label="Confirm new password"
          type="password"
          value={repeatPassword}
          onBlur={() => setConfirmPasswordWasFocused(true)}
          onChange={(e) => setrepeatPassword(e.target.value)}
        />
        {password !== repeatPassword && confirmPasswordWasFocused && (
          <ClarityLabel
            message={"Password must match"}
            type={MessageLabelTypes.error}
          />
        )}
      </div>
    </div>
  );
};

export default PasswordChange;
