import store from "store/storeExporter";
import { IProjectObj, IWorkActivity, WorkActivityTypes } from "utilities/types";

export interface IGroupedActivity {
  authorId: string;
  activityIds: string[];
  dateCreated: Date | string;
  dateModified?: Date | string;
  type: WorkActivityTypes.COMMENT | WorkActivityTypes.METADATA_CHANGE;
}

const INTERVAL = 5; // in minutes

const checkIsInGroup = (
  lastGroupedItem: IGroupedActivity,
  activity: IWorkActivity
) => {
  if (activity.authorId !== lastGroupedItem.authorId) return false;

  const lastItemDate = new Date(lastGroupedItem.dateCreated);
  const activityDate = activity.dateCreated
    ? new Date(activity.dateCreated)
    : new Date();
  const activityInterval = Math.abs(
    activityDate.getTime() - lastItemDate.getTime()
  );
  const intervalInMiliseconds = INTERVAL * 60 * 1000;
  if (activityInterval <= intervalInMiliseconds) return true;
  return false;
};

export const getGroupedActivityFromWorkItemActivities = (
  workItem: IProjectObj
) => {
  const groupedActivities: IGroupedActivity[] = [];

  let lastGroupedItem: IGroupedActivity = {
    activityIds: ["created"],
    authorId: workItem.authorId,
    dateCreated: workItem.dateCreated,
    dateModified: undefined,
    type: WorkActivityTypes.METADATA_CHANGE,
  };

  const activityDict = store.getState().work.workActivities;

  const addGroupAndContinue = (activity: IWorkActivity, activityId: string) => {
    groupedActivities.push(lastGroupedItem);
    lastGroupedItem = {
      activityIds: [activityId],
      authorId: activity.authorId || "",
      dateCreated: activity.dateCreated ?? new Date(),
      dateModified:
        activity.dateModified && activity.dateCreated !== activity.dateModified
          ? activity.dateModified
          : undefined,
      type:
        activity.type === WorkActivityTypes.COMMENT
          ? WorkActivityTypes.COMMENT
          : WorkActivityTypes.METADATA_CHANGE,
    };
  };

  workItem?.workActivities?.forEach((activityId, index) => {
    if (activityDict[activityId]) {
      const activity = activityDict[activityId];

      const prevId = workItem.workActivities[index - 1];
      if (prevId && activityDict[prevId]) {
        const prevActivity = activityDict[prevId];
        if (prevActivity.type === WorkActivityTypes.COMMENT) {
          addGroupAndContinue(activity, activityId);
          return;
        }
        if (prevActivity.type === WorkActivityTypes.REVIEW_REQUEST) {
          addGroupAndContinue(activity, activityId);
          return;
        }
        if (prevActivity.type === WorkActivityTypes.CLAIM_REQUEST) {
          addGroupAndContinue(activity, activityId);
          return;
        }
      }

      if (activity.type === WorkActivityTypes.COMMENT) {
        addGroupAndContinue(activity, activityId);
        return;
      }

      if (activity.type === WorkActivityTypes.REVIEW_REQUEST) {
        addGroupAndContinue(activity, activityId);
        return;
      }

      if (activity.type === WorkActivityTypes.CLAIM_REQUEST) {
        addGroupAndContinue(activity, activityId);
        return;
      }
      const isInTimeframe = checkIsInGroup(lastGroupedItem, activity);
      if (isInTimeframe) {
        lastGroupedItem.activityIds.push(activityId);
      } else {
        addGroupAndContinue(activity, activityId);
      }
    }
  });

  if (lastGroupedItem.activityIds.length > 0)
    groupedActivities.push(lastGroupedItem);

  return groupedActivities;
};
