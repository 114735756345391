import TopNavFiltersBar from "components/topNavBar/TopNavFiltersBar";
import { VALUE_K } from "keycode-js";
import {
  applyFiltersToItems,
  groupWorkBy,
  orderIdsByKey,
  sortByMetadata,
} from "modules/taskService";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, shallowEqual, useSelector } from "react-redux";
import { BehaviorSubject } from "rxjs";
import {
  SET_LIST_ITEMS,
  SET_PAGE_DATA,
  SET_SHOW_SUBROUTES,
  SET_TOPNAV_VIEW_TYPE,
} from "store/actions";
import { openCommandPalette } from "store/reducers/commandPaletteReducer";
import { ViewSettings } from "store/reducers/filterReducer";
import { TopNavbarType } from "store/reducers/topNavReducer";
import store, { ClarityStore } from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import {
  CommandPaletteContext,
  ContainerTypes,
  GroupTypes,
  IProjectObj,
  PayoutsViewsNames,
  TasksViewModes,
  ViewAsTypes,
} from "utilities/types";
import { getPresetTaskMetadata } from "./actions";
import BoardView from "./BoardView";
import ListView from "./ListView";
import { IWorkViewConfig, notSavedLocallyViews } from "./types";
import WorkProjectsSection from "./WorkProjectsSection";
import WorkViewTopnavBtns from "./WorkViewTopnavBtns";
import GroupWorkSubroutes from "../home/subroutes/GroupWorkSubroutes";
import PayoutsGroupSelector from "../contributions/PayoutsGroupSelector";
import { useShallowSelector } from "utilities/hooks";
const { v4: uuidv4 } = require("uuid");
export interface IGroupHeader {
  groupId: string;
  type: string;
  presetTaskMetadata: null | Partial<IProjectObj>;
  count: number;
  baseGroupId?: string;
}

export interface IGroupEmptyEntry {
  id: string;
  type: string;
  isEmptyElement: boolean;
}
export interface IWorkViewStoreContext {
  groups: BehaviorSubject<{ [id: string]: string[] }>;
  filteredIds: BehaviorSubject<string[]>;
  groupsHeaders: BehaviorSubject<{ [id: string]: IGroupHeader }>;
  selectedItems: BehaviorSubject<string[]>;
  groupsCount: BehaviorSubject<{ [id: string]: number }>;
  selectedDict: BehaviorSubject<{ [id: string]: boolean }>;
  hoveredItem: BehaviorSubject<{ id: string; index: number } | null>;
  lastSelected: BehaviorSubject<{ id: string; index: number } | null>;
  groupContext: BehaviorSubject<string | null>;
}

export const WorkViewStoreContext = React.createContext<IWorkViewStoreContext>({
  groups: new BehaviorSubject<{ [id: string]: string[] }>({}),
  selectedItems: new BehaviorSubject<string[]>([]),
  filteredIds: new BehaviorSubject<string[]>([]),
  groupsCount: new BehaviorSubject<{ [id: string]: number }>({}),
  selectedDict: new BehaviorSubject<{ [id: string]: boolean }>({}),
  hoveredItem: new BehaviorSubject<{ id: string; index: number } | null>(null),
  groupsHeaders: new BehaviorSubject<{ [id: string]: IGroupHeader }>({}),
  lastSelected: new BehaviorSubject<{ id: string; index: number } | null>(null),
  groupContext: new BehaviorSubject<string | null>(null),
});

const saveInLocalStorage = (currentDict: ViewSettings, viewId: string) => {
  const baseId = store.getState().workspace.id;
  const workViewsString = localStorage.getItem("workViews");
  const workViews = JSON.parse(workViewsString ?? "{}");
  if (!workViews[baseId]) workViews[baseId] = {};
  workViews[baseId][viewId] = currentDict;
  localStorage.setItem("workViews", JSON.stringify(workViews));
};

const resetItemsStore = (itemsStore: IWorkViewStoreContext) => {
  batch(() => {
    itemsStore.filteredIds.next([]);
    itemsStore.groups.next({});
    itemsStore.groupsCount.next({});
    itemsStore.groupsHeaders.next({});
    itemsStore.hoveredItem.next(null);
    itemsStore.lastSelected.next(null);
    itemsStore.selectedDict.next({});
    itemsStore.selectedItems.next([]);
  });
};

const WorkView: React.FC<{
  paneId: ChunkDestination;
  viewConfig: IWorkViewConfig | null;
}> = ({ paneId, viewConfig }) => {
  const workViewState = useSelector(
    (store: ClarityStore) => ({
      dict: store.work.dict,
      tasks: store.work.allTaskIds,
      projects: store.work.allProjectIds,
      initiatives: store.work.allInitiativeIds,
      currentDict: store.filter.currentDict[paneId],
      presetsDict: store.filter.presetsDict[paneId],
      viewConfig: store.filter.viewConfigDict[paneId],
      groupContext: store.client.paneGroupContext[paneId]?.groupId,
      statuses: store.work.statuses,
      userGroups: store.groups.userGroups,
    }),
    shallowEqual
  );

  const [filteredElements, setfilteredElements] = useState<
    (string | IGroupHeader | IGroupEmptyEntry)[]
  >([]);

  const [groupIds, setgroupIds] = useState<string[]>([]);
  const isFirstLoad = useRef(true);
  const currentViewId = useRef("");
  const currentViewRef = useRef<HTMLDivElement | null>(null);

  const itemsStore = useRef<IWorkViewStoreContext>({
    groups: new BehaviorSubject<{ [id: string]: string[] }>({}),
    filteredIds: new BehaviorSubject<string[]>([]),
    selectedItems: new BehaviorSubject<string[]>([]),
    selectedDict: new BehaviorSubject<{ [id: string]: boolean }>({}),
    groupsCount: new BehaviorSubject<{ [id: string]: number }>({}),
    hoveredItem: new BehaviorSubject<{ id: string; index: number } | null>(
      null
    ),
    groupsHeaders: new BehaviorSubject<{ [id: string]: IGroupHeader }>({}),
    lastSelected: new BehaviorSubject<{ id: string; index: number } | null>(
      null
    ),
    groupContext: new BehaviorSubject<string | null>(null),
  });

  useEffect(() => {
    if (workViewState.viewConfig.viewMode === TasksViewModes.MyTasks) {
      store.dispatch({
        type: SET_SHOW_SUBROUTES,
        paneId,
        subRoutesParams: {
          type: "myWork",
          activeKey: "tasks",
        },
      });
      store.dispatch({
        type: SET_TOPNAV_VIEW_TYPE,
        paneId,
        navType: TopNavbarType.mywork,
      });
    }
    if (workViewState.viewConfig.viewMode === TasksViewModes.Reviewing) {
      store.dispatch({
        type: SET_SHOW_SUBROUTES,
        paneId,
        subRoutesParams: {
          type: "myWork",
          activeKey: "reviewing",
        },
      });
      store.dispatch({
        type: SET_TOPNAV_VIEW_TYPE,
        paneId,
        navType: TopNavbarType.mywork,
      });
    }
    if (workViewState.viewConfig.viewMode === TasksViewModes.Tasks) {
      batch(() => {
        const groupContext =
          store.getState().groups.dict[workViewState.groupContext];
        if (!groupContext) return;
        const groupName = groupContext.name;
        store.dispatch({
          type: SET_PAGE_DATA,
          paneId,
          title: groupName,
        });

        store.dispatch({
          type: SET_SHOW_SUBROUTES,
          paneId,
          subRoutesParams: {
            type: "group",
            groupId: workViewState.groupContext,
            activeKey: "tasks",
          },
        });
        store.dispatch({
          type: SET_TOPNAV_VIEW_TYPE,
          paneId,
          navType: TopNavbarType.group,
        });
      });
    }

    if (
      [
        TasksViewModes.UnclaimedContributions,
        TasksViewModes.UnstartedContributions,
        TasksViewModes.InProgressContributions,
        TasksViewModes.AwitingRewardApprovalContributions,
        TasksViewModes.ApprovedForPaymentContributions,
        TasksViewModes.PaidContributions,
      ].includes(workViewState.viewConfig.viewMode)
    ) {
      store.dispatch({
        type: SET_TOPNAV_VIEW_TYPE,
        paneId,
        navType: TopNavbarType.payoutScreen,
      });
    }

    if (workViewState.viewConfig.viewMode === TasksViewModes.CustomView) {
      store.dispatch({
        type: SET_TOPNAV_VIEW_TYPE,
        paneId,
        navType: TopNavbarType.detail,
      });
    }
    return () => {
      store.dispatch({
        type: SET_SHOW_SUBROUTES,
        paneId,
      });
      store.dispatch({
        type: SET_TOPNAV_VIEW_TYPE,
        paneId,
      });
    };
  }, [workViewState.groupContext, workViewState.viewConfig?.viewMode]);

  useEffect(() => {
    if (!workViewState.viewConfig) return;

    return () => {
      if (!workViewState.viewConfig) return;
      batch(() => {
        setgroupIds([]);
        setfilteredElements([]);
        resetItemsStore(itemsStore.current);
      });
    };
  }, [workViewState.viewConfig?.id]);

  useEffect(() => {
    if (
      !workViewState.currentDict?.filters ||
      !workViewState.currentDict?.viewAs
    )
      return;

    const data = [
      ...workViewState.tasks,
      ...workViewState.projects,
      ...workViewState.initiatives,
    ];

    const filters = { ...workViewState.currentDict.filters };

    if (workViewState.groupContext && !filters.sponsorGroup) {
      filters.groups = [workViewState.groupContext];
    }

    if (filters.sponsorGroup) {
      filters.groups = [];
    }

    if (
      !workViewState.groupContext &&
      !filters.groups &&
      !filters.excludedGroups
    ) {
      filters.groups = [...workViewState.userGroups];
    }

    if (workViewState.currentDict.viewAs === ViewAsTypes.board) {
      filters.excludedStatuses = [];
    }

    const orderByClause = workViewState.currentDict.orderBy;
    let _filteredItems = applyFiltersToItems(data, workViewState.dict, filters);
    sortByMetadata(orderByClause, _filteredItems);

    const viewAs = workViewState.currentDict.viewAs;
    const groupBy =
      viewAs === ViewAsTypes.board
        ? GroupTypes.status
        : workViewState.currentDict.groupBy;

    const groups: { [id: string]: string[] } = groupWorkBy(
      _filteredItems,
      groupBy,
      workViewState.currentDict.viewAs
    );

    let _items: (string | IGroupHeader | IGroupEmptyEntry)[] = [];
    const _orderedFilteredItems: string[] = [];
    const _groupHeaders: { [id: string]: IGroupHeader } = {};
    const orderedKeys =
      viewAs === ViewAsTypes.board
        ? workViewState.statuses.statusArray
        : orderIdsByKey(Object.keys(groups), groupBy);

    if (groupBy !== GroupTypes.none) {
      orderedKeys.forEach((key) => {
        const keyItem: IGroupHeader = {
          groupId: key,
          presetTaskMetadata: {
            ...(workViewState.viewConfig
              ? workViewState.viewConfig.presetMetadata()
              : {}),
            ...getPresetTaskMetadata(key, groupBy),
          },
          count: groups[key].length,
          type: groupBy,
        };

        _items.push(keyItem);
        _groupHeaders[keyItem.groupId] = keyItem;
        if (keyItem.count === 0) {
          const emptyItem: IGroupEmptyEntry = {
            id: uuidv4(),
            isEmptyElement: true,
            type: groupBy,
          };
          _items.push(emptyItem);
        }
        groups[key].forEach((item) => {
          _items.push(item);
          _orderedFilteredItems.push(item);
        });
      });
    } else {
      _items = _filteredItems;
    }

    batch(() => {
      setgroupIds(orderedKeys);
      if (
        JSON.stringify(_items) !== JSON.stringify(filteredElements) ||
        currentViewId.current !== workViewState.viewConfig.id
      ) {
        currentViewId.current = workViewState.viewConfig.id;
        if (itemsStore.current.selectedItems.value.length > 0) {
          const selectedObj = itemsStore.current.selectedDict.value;
          const newSelectObject: any = {};
          const selectedTasksInner: string[] = [];
          _items.forEach((workId) => {
            if (typeof workId === "string" && selectedObj[workId]) {
              newSelectObject[workId] = true;
              selectedTasksInner.push(workId);
            }
          });
          itemsStore.current.selectedItems.next(selectedTasksInner);
          itemsStore.current.selectedDict.next(newSelectObject);
        }
        setfilteredElements(_items);
      }
      itemsStore.current.groups.next(groups);
      itemsStore.current.filteredIds.next(_orderedFilteredItems);
      itemsStore.current.groupsHeaders.next(_groupHeaders);
      itemsStore.current.groupContext.next(workViewState.groupContext);
    });

    if (!isFirstLoad.current) {
      if (!notSavedLocallyViews.includes(workViewState.viewConfig.viewMode)) {
        saveInLocalStorage(
          workViewState.currentDict,
          workViewState.groupContext
            ? workViewState.groupContext + workViewState.viewConfig.id
            : workViewState.viewConfig.id
        );
      }
    }
  }, [
    workViewState.viewConfig?.id,
    workViewState.groupContext,
    workViewState.dict,
    workViewState.currentDict?.groupBy,
    workViewState.currentDict?.orderBy,
    workViewState.currentDict?.viewAs,
    workViewState.currentDict?.filters,
    workViewState.currentDict?.showActiveProjects,
    workViewState.viewConfig,
    workViewState.statuses,
  ]);

  useEffect(() => {
    if (!workViewState.viewConfig) return;
    isFirstLoad.current = false;

    return () => {
      isFirstLoad.current = true;
    };
  }, [workViewState.viewConfig?.id]);

  useEffect(() => {
    currentViewRef.current?.focus();
  }, [workViewState.viewConfig?.id]);

  return useMemo(() => {
    if (!workViewState.currentDict?.viewAs) return <></>;
    return (
      <WorkViewStoreContext.Provider value={itemsStore.current}>
        <TopNavFiltersBar paneId={paneId}>
          <div
            style={{
              display: "flex",
              flexGrow: "1",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <WorkViewFiltersSwitcher
              viewConfig={workViewState.viewConfig}
              groupContext={workViewState.groupContext}
              paneId={paneId}
            />

            <div style={{ flexGrow: "1" }} />
            <WorkViewTopnavBtns paneId={paneId} />
          </div>
        </TopNavFiltersBar>
        <OpenWorkListener filteredElements={filteredElements} />
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key.toLocaleLowerCase() === VALUE_K) {
              if (e.ctrlKey || e.metaKey) {
                e.stopPropagation();
                e.preventDefault();
                openCommandPalette(CommandPaletteContext.WORK_VIEW, {
                  paneId,
                  selectedItemIds: itemsStore.current.selectedItems.value,
                  slectedItemsType: ContainerTypes.WORK,
                  afterCloseFocus: currentViewRef,
                });
              }
            }
          }}
          ref={currentViewRef}
        >
          {workViewState.viewConfig.viewMode === TasksViewModes.Reviewing && (
            <WorkProjectsSection />
          )}

          {workViewState.currentDict.viewAs === ViewAsTypes.list && (
            <ListView
              fitleredElements={filteredElements}
              viewConfig={viewConfig}
            />
          )}
          {workViewState.currentDict.viewAs === ViewAsTypes.board && (
            <BoardView
              groupIds={groupIds}
              showingProjects={workViewState.currentDict.showActiveProjects}
            />
          )}
        </div>
      </WorkViewStoreContext.Provider>
    );
  }, [
    filteredElements,
    workViewState.currentDict?.viewAs,
    workViewState.currentDict?.showActiveProjects,
    workViewState.viewConfig?.id,
    workViewState.groupContext,
    groupIds,
  ]);
};

const OpenWorkListener: React.FC<{
  filteredElements: (string | IGroupHeader | IGroupEmptyEntry)[];
}> = ({ filteredElements }) => {
  const peekViewItem = useShallowSelector(
    (store) => store.navigation.navigation.peek?.entity?.containerId
  );

  useEffect(() => {
    if (peekViewItem) {
      const itemsInState = filteredElements.filter(
        (item) => typeof item === "string"
      );
      store.dispatch({
        type: SET_LIST_ITEMS,
        param: {
          listItems: itemsInState,
        },
      });
    } else {
      store.dispatch({
        type: SET_LIST_ITEMS,
        param: {
          listItems: null,
        },
      });
    }
  }, [peekViewItem]);

  useEffect(() => {
    store.dispatch({
      type: SET_LIST_ITEMS,
      param: {
        listItems: null,
      },
    });
  }, []);
  return <></>;
};

const WorkViewFiltersSwitcher: React.FC<{
  viewConfig: IWorkViewConfig;
  groupContext: string;
  paneId: ChunkDestination;
}> = ({ viewConfig, groupContext, paneId }) => {
  if (
    Object.values(PayoutsViewsNames).includes(
      viewConfig.path as PayoutsViewsNames
    )
  ) {
    return <PayoutsGroupSelector />;
  }
  if (groupContext)
    return <GroupWorkSubroutes groupId={groupContext} paneId={paneId} />;

  return <></>;
};

export default WorkView;
