import { TokenDetailContainer } from "clarity-ui/tokenGateContainer/TokenGateRow";
import UserWalletPresenceCard from "clarity-ui/UserWalletPresenceCard";
import { ITokenVerification, web3Api } from "clientApi/web3Api";
import { WalletLoginButton } from "components/WalletLoginButton";
import { useWalletConnectionChecker } from "editor/utils/customHooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import {
  ITokenGateRule,
  ITokenRequirement,
  UserEventTypes,
} from "utilities/types";
import PrivatePageInfo from "./base/main/detailView/privatePage/PrivatePageInfo";
import styles from "./tokenGate/tokenGate.module.scss";
// import FluidLoader from "icons/fluid-loader.svg";
import eventApi from "clientApi/eventApi";
import SignUpLoginSwitcher from "components/SignUpLoginSwitcher";
import { usernameSplitter } from "clarity-ui/UserDisplay";
import { Link, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

let lastTokenGateEvent: UserEventTypes | null = null;

export enum TokenGateReason {
  joinBase = "joinBase",
  viewDocument = "viewDocument",
}

const getReqTextFromReason = (reason?: TokenGateReason) => {
  if (reason === TokenGateReason.viewDocument) return "view this page";
  return "join this base";
};

const getSignInReason = (reason?: TokenGateReason) => {
  if (reason === TokenGateReason.viewDocument)
    return "this document has token requirements to view";
  return "this base has token requirements to join";
};

const TokenGate: React.FC<{
  tokenRequirements: ITokenRequirement[];
  beforeSettingUser?: (userId: string) => Promise<any>;
  showContent?: boolean;
  fullScreen?: boolean;
  linkRedeem?: boolean;
  reason?: TokenGateReason;
  rule?: ITokenGateRule;
}> = ({
  children,
  tokenRequirements,
  beforeSettingUser,
  linkRedeem,
  fullScreen,
  reason,
  rule,
  showContent,
}) => {
  const [matchesReq, setMatchesReq] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [tokenRes, settokenRes] = useState<ITokenVerification[]>([]);
  const user = useSelector((store: ClarityStore) => store.user, shallowEqual);

  const { loading, walletConnected } = useWalletConnectionChecker();

  enum GateModes {
    loading = "loading",
    notConnected = "notConnected",
    failed = "failed",
    success = "success",
  }

  const checkTokenBlance = useCallback(async () => {
    if (tokenRequirements) {
      setisLoading(true);
      const res = await web3Api.checkTokenRequirements(tokenRequirements);
      settokenRes(res);
      let hasAll = false;
      if (rule && rule === ITokenGateRule.AND) {
        hasAll = res.length === 0 || res.every((value) => value.success);
      } else if (rule && rule === ITokenGateRule.OR) {
        hasAll = res.length === 0 || res.some((value) => value.success);
      }
      if (hasAll && user?.id && beforeSettingUser) {
        lastTokenGateEvent = UserEventTypes.TOKEN_GATE_PASSED;
        eventApi.postEvent(UserEventTypes.TOKEN_GATE_PASSED, {});
        await beforeSettingUser(user.id);
      }
      setMatchesReq(hasAll);
    }
    setisLoading(false);
    return true;
  }, [tokenRequirements]);

  useEffect(() => {
    if (!walletConnected && !loading) return setisLoading(false);
    if (walletConnected && !loading) {
      checkTokenBlance();
    }
  }, [walletConnected, loading]);

  useEffect(() => {
    if (
      !lastTokenGateEvent ||
      lastTokenGateEvent === UserEventTypes.TOKEN_GATE_PASSED ||
      lastTokenGateEvent === UserEventTypes.TOKEN_GATE_REJECTED
    )
      eventApi.postEvent(UserEventTypes.TOKEN_GATE_ENCOUNTERED, {});
  }, []);

  function getContent() {
    return (
      <div
        className={`${styles.content} ${!fullScreen ? styles.parentFill : ""}`}
      >
        {GateModes.notConnected === mode && (
          <ConnectWalletScreen
            reason={reason}
            afterEffect={() => {
              eventApi.postEvent(
                UserEventTypes.TOKEN_GATE_WALLET_CONNECTED,
                {}
              );
            }}
          />
        )}
        {GateModes.failed === mode && (
          <>
            <p
              className="caption regular secondary"
              style={{ margin: 0, padding: 0 }}
            >
              The connected wallet does not meet the token requirements to{" "}
              {getReqTextFromReason(reason)}
            </p>
            <TokenDetailContainer
              tokenRequirements={tokenRes}
              showStatus={true}
            />
            <div>
              <UserWalletPresenceCard />
            </div>
          </>
        )}
      </div>
    );
  }

  const mode = useMemo(() => {
    if (isLoading || loading) return GateModes.loading;
    if (
      !walletConnected ||
      !matchesReq ||
      !user ||
      !user.id ||
      user.id === ""
    ) {
      if (!walletConnected || !user || !user.id || user.id === "") {
        return GateModes.notConnected;
      } else {
        lastTokenGateEvent = UserEventTypes.TOKEN_GATE_REJECTED;
        eventApi.postEvent(UserEventTypes.TOKEN_GATE_REJECTED, {});
        return GateModes.failed;
      }
    }
    const baseId = store.getState().workspace.id;
    if (!baseId && !linkRedeem) {
      return GateModes.loading;
    }
    if (
      !beforeSettingUser &&
      lastTokenGateEvent !== UserEventTypes.TOKEN_GATE_PASSED
    ) {
      lastTokenGateEvent = UserEventTypes.TOKEN_GATE_PASSED;
      eventApi.postEvent(UserEventTypes.TOKEN_GATE_PASSED, {});
    }

    return GateModes.success;
  }, [isLoading, loading, walletConnected, matchesReq, user]);

  if (showContent) return <>{children}</>;
  if (mode === GateModes.loading) {
    return (
      <>
        <LoadingOutlined />
      </>
    );
  }
  if (mode === GateModes.notConnected || mode === GateModes.failed) {
    if (fullScreen) {
      return (
        <PrivatePageInfo presetClassName={styles.screen}>
          {getContent()}
        </PrivatePageInfo>
      );
    }
    return <>{getContent()}</>;
  }

  return <>{children}</>;
};

const ConnectWalletScreen: React.FC<{
  afterEffect?: any;
  reason?: TokenGateReason;
}> = ({ afterEffect, reason }) => {
  const user = useSelector((state: ClarityStore) => state.user, shallowEqual);
  const location = useLocation();

  if (!user || !user.id || user.id === "")
    return (
      <>
        <SignUpLoginSwitcher inTokenGate />
        <p
          className="caption regular disabled"
          style={{
            margin: 0,
            padding: 0,
            textAlign: "center",
          }}
        >
          You are not logged in, and {getSignInReason(reason)}. Therefore you
          must use Sign-in with Ethereum to proceed.
        </p>
      </>
    );

  return (
    <>
      {reason === TokenGateReason.joinBase && (
        <p
          className="body2 bold secondary"
          style={{ margin: 0, paddingBottom: "24px" }}
        >
          Please connect your wallet to {getReqTextFromReason(reason)}
        </p>
      )}
      {reason === TokenGateReason.viewDocument && (
        <p
          className="caption regular secondary"
          style={{ margin: 0, paddingBottom: "40px" }}
        >
          Please connect your wallet to {getReqTextFromReason(reason)}
        </p>
      )}

      <WalletLoginButton
        afterEffect={afterEffect}
        message={"Connect your wallet"}
        mode="viral"
      />

      <p
        className="caption regular disabled"
        style={{
          margin: 0,
          padding: 0,
          textAlign: "center",
          marginTop: "72px",
        }}
      >
        You are logged in as{" "}
        <b>{usernameSplitter(user.name || "@" + user.username)}</b> but there
        isn’t a wallet connected to your account. Connect your wallet, or{" "}
        <Link
          to={`/logout?redirect=${location.pathname}`}
          style={{ textDecoration: "underline", color: "#1b1b1b" }}
        >
          switch to another account.
        </Link>
      </p>
    </>
  );
};

export default TokenGate;
