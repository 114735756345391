/* eslint-disable no-fallthrough */
import {
  SET_IS_BASE_MEMBER,
  CHANGE_DOCUMENT_MODE,
  REMOVE_ACCESS_TOKEN,
  SET_ACCESS_TOKEN,
  SHOW_MODAL_DOCUMENT_SHARE,
  HIDE_MODAL_DOCUMENT_SHARE,
  SHOW_MODAL_BASE_SETTINGS,
  HIDE_MODAL_BASE_SETTINGS,
  SET_REFRESH_REDIRECT_URL,
  SHOW_MODAL_NEW_PROJECT,
  HIDE_MODAL_NEW_PROJECT,
  TOGGLE_SIDEBAR_VISIBILITY,
  SET_MODAL_IMAGE_SELECT,
  SET_MODAL_CITATION_SYNC,
  ADD_SELECTED_ENTITIES,
  REMOVE_SELECTED_ENTITIES,
  CLEAR_SELECTED_ENTITIES,
  SELECT_ONE_ENTITY,
  SET_SWITCH_BASE_MENU_VISIBILITY,
  SET_CURRENT_DISCUSSION_ID,
  SET_NEW_DISCUSSION_DETAIL,
  SET_ALL_DISCUSSIONS_MODAL_VISIBILITY,
  SET_CURRENT_PREDISCUSSION_ID,
  REMOVE_PREDISCUSSION_ID,
  TOGGLE_IMAGE_MODAL,
  SET_MOUSE_POS,
  SET_MODAL_ITEM_SELECT,
  SET_INVITATIONS_MODAL,
  SET_MENTIONS_AND_REPLIES_INBOX,
  SET_FILTERS_MODAL,
  SET_SEARCH,
  SET_DEFAULT_MODAL,
  SET_SHARING_AND_PERMS_MODAL,
  SET_CONTAINER_INVITE_MODAL,
  SET_USER_PERMISSIONS,
  SET_ACTIVE_WORKSPACE,
  SET_LAST_VISITED_PAGE,
  UPDATE_LOCAL_PERMISSIONS,
  SET_EDIT_VIEW_MODAL,
  UPDATE_USER_ROLES,
  SET_OPEN_BASE_VIEW,
  SET_SHOW_LEAVE_BASE_MODAL,
  SET_BASE_SETTINGS_HAS_CHANGES,
  OPEN_BASE_SETTING_SET_INTIAL,
  SET_GROUP_CONTEXT,
  REMOVE_GROUP_CONTEXT,
  SET_ACTION_INTERCEPTOR,
  OPEN_DISCORD_INTEGRATION_MODAL,
  SET_NUDGE_SENT_WORK_ITEM,
  SET_LAST_USED_TOKEN,
  SHOW_MODAL_NEW_TEMPLATE,
  HIDE_MODAL_NEW_TEMPLATE,
  SHOW_MODAL_NEW_SNIPPET,
  HIDE_MODAL_NEW_SNIPPET,
  TOGGLE_TEMPLATE_MODAL,
  SET_GROUP_INVITATIONS_MODAL,
  SET_MODAL_JOIN_GROUP,
} from "../actions";

import {
  DocumentModes,
  CommandPaletteContext,
  PermissionType,
  ContainerTypes,
  CustomWorkView,
  IUserPermissions,
  ITemplateObj,
  ISnippetObj,
  IBlockContext,
} from "../../utilities/types";
import { IUserObj, EntityTypes, IProjectObj } from "../../utilities/types";
import store, { activeDiscussion, ClarityStore } from "store/storeExporter";
import { pick } from "lodash";
import { useCallback, useEffect } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { ChunkDestination } from "utilities/stateTypes";
import { useShallowSelector } from "utilities/hooks";
import { DiscordIntegrationSteps } from "screens/base/main/DiscordConnection";
import { Block } from "./blockReducer";

export enum ActionIntercept {
  default = "default",
  notAuthUserInPublicBase = "notAuthUserInPublicBase",
  guestInPublicBase = "guestInPublicBase",
  authedUserInPublicBase = "authedUserInPublicBase",
  login = "login",
}
interface ISetCurrentDiscussionId {
  type: string;
  id: string;
}

interface ISetCurrentDiscussionId {
  type: string;
  id: string;
}

interface IChangeDocumentModeActionParameters {
  type: string;
  param: DocumentModes;
}

interface ISetSwitchBaseMenuVisibility {
  type: string;
  visible: boolean;
}

interface ISetAuthenticatedUserActionParameters {
  type: string;
  user: IUserObj;
}

interface ISetPublicViewActionParameters {
  type: string;
  publicView: boolean;
}

interface IRemoveAccessTokenActionParameters {
  type: string;
}

interface ISetAccessTokenActionParameters {
  type: string;
  accessToken: null & string;
}

interface ISetmMouseDown {
  type: string;
  mouseDown: boolean;
}

interface IShowModalDocumentShareActionParameters {
  type: string;
}

interface ISetEditViewModal {
  type: string;
  isOpen?: boolean;
  customView?: CustomWorkView;
}

interface IHideModalDocumentShareActionParameters {
  type: string;
}

interface ISetRefreshRedirectUrlActionParameters {
  type: string;
  url: string;
}

interface IShowModalBaseSettingsActionParameters {
  type: string;
  baseId: string;
}

interface IHideModalBaseSettingsActionParameters {
  type: string;
}

interface IToggleSidebarVisibilityActionParameters {
  type: string;
}

export type SidebarModeTypes =
  | "mobile"
  | "hover-open"
  | "hover-closed"
  | "normal";

interface IShowModalNewTaskActionParameters {
  type: string;
}

interface IHideModalNewTaskActionParameters {
  type: string;
}

interface IShowSecondaryViewActionParameters {
  type: string;
}

interface IHideSecondaryViewActionParameters {
  type: string;
}

interface IAddSelectedEntities {
  type: string;
  entityType: EntityTypes;
  ids: string[];
}

interface IRemoveSelectedEntities {
  type: string;
  entityType: EntityTypes;
  ids: string[];
}

interface IClearSelectedEntities {
  type: string;
}

interface ISetNewDiscussionDetail {
  type: string;
  detail: any;
}

interface ISetCurrentPrediscussionId {
  type: string;
  id: string;
}

interface IRemovePrediscussionId {
  type: string;
  id: string;
}

interface ISetAllDiscussionsModalVisibility {
  type: string;
  details: {
    containerId: string;
    containerType: string;
    visible: boolean;
  };
}

interface ISetIsBaseMember {
  type: string;
  params: {
    isBaseMember: boolean;
  };
}

interface ISetTaskModal {
  type: string;
  isOpen: boolean;
  taskId: string | null;
}

interface ISetImageModal {
  type: string;
  isOpen: boolean;
}

interface ISetJoinModalCloseGroups {
  isOpen: boolean;
  roleIds: boolean;
}

export interface ISetSearch {
  type: string;
  isOpen: boolean;
  resultArray: any[];
  isSearching: boolean;
  queryString: string;
  quickSearchActive: boolean;
  fullSearchActive: boolean;
}

interface ISetMousePos {
  type: string;
  x: number;
  y: number;
}

interface ISetSelectModal {
  type: string;
  param: {
    type?: string;
    onConfirm?: any;
    display: boolean;
  };
}

interface ISetDefaultModal {
  type: string;
  param: {
    type?: string;
    children?: any;
    isOpen: boolean;
  };
}

interface ISetLastOpenedPage {
  type: string;
  param: {
    containerId: string;
    containerType: ContainerTypes;
    type: "AllPages" | "Entity";
    baseId: string;
  };
}

interface ISetLastOpenedBase {
  type: string;
  param: {
    baseId: string;
  };
}

export interface ISetSharingAndPermsModal {
  isOpen: boolean;
  containerId?: string;
  containerType?: string;
  gateId?: string;
}

export interface ISetContainerInviteModal {
  isOpen: boolean;
  containerId?: string;
  containerType?: string;
}

export interface ISetGroupContext {
  paneId: string;
  groupId: string;
  groupSlug: string;
}

interface IPaneGroupContext {
  [paneId: string]: {
    groupId: string;
    groupSlug: string;
  };
}

export enum INewTaskCreationModes {
  new = "new",
  duplicate = "duplicate",
  fromTemplate = "fromTemplate",
}

export enum INewTemplateCreationModes {
  new = "new",
  duplicate = "duplicate",
  fromTask = "fromTemplate",
}

export interface INewTaskContext {
  type: INewTaskCreationModes;
  presetData?: Partial<IProjectObj>;
  disableSelectGroup?: boolean;
  generatedFromId?: string;
  transformBlocks?: {
    selectedBlock: Block;
    context: IBlockContext;
  };
  onClose?: () => void;
}

export interface INewTemplateContext {
  type: INewTemplateCreationModes;
  presetData?: Partial<ITemplateObj>;
  onClose?: () => void;
  generatedFromId?: string;
}

interface IInitialState {
  isSidebarHidden: boolean;
  isSidebarHovered: boolean;
  isSwitchBaseMenuVisible: boolean;
  primaryActiveDocumentId: string;
  documentMode: DocumentModes;
  accessToken: null | string;
  roleType: any;
  roleIds: string[];
  permissions: IUserPermissions;
  displayModalDocumentShare: boolean;
  modalBaseSettings: {
    display: boolean;
    baseId: string;
  };
  refreshRedirectUrl: string;
  isModalNewTaskVisible: boolean;
  isNewTemplateVisible: boolean;
  isModalNewSnippetVisible: boolean;
  onModalClosed: any;
  newTaskContext?: INewTaskContext;
  newTemplateContext?: INewTemplateContext;
  newSnippetContext: ISnippetObj;
  isCommandPaletteVisible: boolean;
  commandPaletteContext: CommandPaletteContext;
  modalImageLink: {
    display: boolean;
    blockId: null | string;
    destination: null | string;
  };
  modalCitationsSync: {
    display: boolean;
    blockId: null | string;
    blockData: any;
    citationData: any;
    destination: null | string;
  };
  modalSelectItem: {
    display: boolean;
    onConfirm: undefined | any;
    type: undefined | any;
    blockData: undefined | any;
  };
  modalJoinClosedGroup: {
    isOpen: boolean;
    roleIds?: string[];
  };
  selectedEntities: {
    type: null | any;
    ids: string[];
  };
  currentDiscussionId: string;
  currentPrediscussionId: string;
  newDiscussionDetail: any[];
  allDiscussionsModal: {
    visible: boolean;
    containerId: string;
    containerType: string;
  };
  templateModalData: {
    isOpen: boolean;
    id: null | string;
  };
  isBaseMember: boolean;
  mousePos: {
    x: number;
    y: number;
  };
  imageModal: {
    isOpen: boolean;
    imageUrl: string;
  };
  invitationsModal: {
    isOpen: boolean;
  };
  groupInvitationsModal: {
    isOpen: boolean;
    groupId?: string;
  };
  mentionsAndRepliesInbox: {
    isOpen: boolean;
  };
  filtersModal: {
    isOpen: boolean;
    fixedType: boolean;
    paneId: ChunkDestination;
  };
  defaultModal: {
    isOpen: boolean;
    type: string;
  };
  search: {
    isOpen: boolean;
    resultArray: any[];
    isSearching: boolean;
    queryString: "";
    quickSearchActive: false;
    fullSearchActive: false;
  };
  sharingAndPermsModal: {
    isOpen: boolean;
    containerId?: string;
    containerType?: string;
    gateId?: string;
    gateLevel?: PermissionType;
  };
  containerInviteModal: {
    isOpen: boolean;
    containerId?: string;
    containerTitle?: string;
  };
  discordIntegrationModal: {
    isOpen: boolean;
    groupId?: string;
    workId?: string;
    intendedStep: DiscordIntegrationSteps;
  };
  editViewModal: {
    isOpen: boolean;
    customView?: CustomWorkView;
  };
  lastVisitedPages: {
    [baseId: string]: {
      containerId: string;
      containerType: ContainerTypes;
      type: "AllPages" | "Entity";
    };
  };
  openBaseView: boolean;
  showLeaveBaseConfirmation: boolean;
  baseSettingsHasChanges: boolean;
  leaveBaseId?: string;
  openSettings?: {
    isOpen?: boolean;
    selectedSection?: string;
    selectedGroup?: string;
  };
  paneGroupContext: IPaneGroupContext;
  interceptedAction?: ActionIntercept;
  showClosedProjects: boolean;
  workItemReviewNudges: {
    [key: string]: Date;
  };
  lastUsedToken: {
    contractAddress: string;
    networkId: number;
  };
}

const initialState: IInitialState = {
  isSidebarHidden: false,
  isSidebarHovered: false,
  isSwitchBaseMenuVisible: false,
  primaryActiveDocumentId: "",
  documentMode: DocumentModes.INSERT,
  accessToken: null,
  roleType: "",
  roleIds: [],
  permissions: {} as IUserPermissions,
  displayModalDocumentShare: false,
  modalBaseSettings: {
    display: false,
    baseId: "",
  },
  refreshRedirectUrl: "",
  isModalNewTaskVisible: false,
  isNewTemplateVisible: false,
  isModalNewSnippetVisible: false,
  onModalClosed: null,
  newTaskContext: undefined,
  newTemplateContext: undefined,
  newSnippetContext: {} as ISnippetObj,
  isCommandPaletteVisible: false,
  commandPaletteContext: CommandPaletteContext.GENERAL,
  openBaseView: true,
  modalImageLink: {
    display: false,
    blockId: null,
    destination: null,
  },
  modalCitationsSync: {
    display: false,
    blockId: null,
    blockData: null,
    citationData: null,
    destination: null,
  },
  modalSelectItem: {
    display: false,
    onConfirm: undefined,
    type: undefined,
    blockData: undefined,
  },
  selectedEntities: {
    type: null,
    ids: [] as string[],
  },
  currentDiscussionId: "",
  currentPrediscussionId: "",
  newDiscussionDetail: [],
  allDiscussionsModal: {
    visible: false,
    containerId: "",
    containerType: "",
  },
  templateModalData: {
    isOpen: false,
    id: null,
  },
  isBaseMember: false,

  mousePos: {
    x: 0,
    y: 0,
  },
  imageModal: {
    isOpen: false,
    imageUrl: "",
  },
  invitationsModal: {
    isOpen: false,
  },
  groupInvitationsModal: {
    isOpen: false,
  },
  mentionsAndRepliesInbox: {
    isOpen: false,
  },
  filtersModal: {
    isOpen: false,
    fixedType: false,
    paneId: ChunkDestination.primary,
  },
  defaultModal: {
    isOpen: false,
    type: "",
  },
  search: {
    isOpen: false,
    resultArray: [] as any[],
    isSearching: false,
    queryString: "",
    quickSearchActive: false,
    fullSearchActive: false,
  },
  sharingAndPermsModal: {
    isOpen: false,
  },
  containerInviteModal: {
    isOpen: false,
  },
  discordIntegrationModal: {
    isOpen: false,
    intendedStep: DiscordIntegrationSteps.GroupConnection,
  },
  editViewModal: {
    isOpen: false,
  },
  lastVisitedPages: {},
  // lastVisitedLocation: "",
  showLeaveBaseConfirmation: false,
  baseSettingsHasChanges: false,
  openSettings: {},
  paneGroupContext: {} as IPaneGroupContext,
  showClosedProjects: false,
  workItemReviewNudges: {},
  lastUsedToken: {
    networkId: 1,
    contractAddress: "0x0000000000000000000000000000000000000000",
  },
  modalJoinClosedGroup: {
    isOpen: false,
    roleIds: [],
  },
};

export default function clientReducer(
  state: IInitialState = initialState,
  action: IChangeDocumentModeActionParameters &
    ISetAuthenticatedUserActionParameters &
    ISetIsBaseMember &
    ISetPublicViewActionParameters &
    IRemoveAccessTokenActionParameters &
    ISetAccessTokenActionParameters &
    ISetmMouseDown &
    any &
    IShowModalDocumentShareActionParameters &
    IHideModalDocumentShareActionParameters &
    ISetRefreshRedirectUrlActionParameters &
    IShowModalBaseSettingsActionParameters &
    IHideModalBaseSettingsActionParameters &
    IToggleSidebarVisibilityActionParameters &
    IShowModalNewTaskActionParameters &
    IHideModalNewTaskActionParameters &
    IShowSecondaryViewActionParameters &
    IHideSecondaryViewActionParameters &
    IAddSelectedEntities &
    IRemoveSelectedEntities &
    IClearSelectedEntities &
    ISetSwitchBaseMenuVisibility &
    ISetCurrentDiscussionId &
    ISetNewDiscussionDetail &
    ISetAllDiscussionsModalVisibility &
    ISetCurrentPrediscussionId &
    IRemovePrediscussionId &
    ISetTaskModal &
    ISetImageModal &
    ISetSelectModal &
    ISetMousePos &
    ISetDefaultModal &
    ISetLastOpenedPage &
    ISetLastOpenedBase &
    ISetGroupContext &
    ISetJoinModalCloseGroups
) {
  switch (action.type) {
    case SET_IS_BASE_MEMBER: {
      if (action.params) {
        const newState = { ...state };
        newState.isBaseMember = action.params.isBaseMember;
        return newState;
      }
      return state;
    }
    case SET_USER_PERMISSIONS: {
      if (action.params) {
        const newState = { ...state };
        newState.permissions = action.params.permissions;
        newState.roleType = action.params.roleType;
        newState.roleIds = action.params.roleIds;
        return newState;
      }
      return state;
    }
    case UPDATE_USER_ROLES: {
      if (action.params) {
        const newState = { ...state };
        newState.roleIds = action.params.roleIds;
        newState.permissions = action.params.permissions;
        return newState;
      }
      return state;
    }
    case CHANGE_DOCUMENT_MODE:
      if (state.documentMode !== action.param.documentMode) {
        const newState = { ...state };
        newState.documentMode = action.param.documentMode;
        if (newState.documentMode === DocumentModes.BLOCK) {
          const selection = document.getSelection();
          if (selection && selection.rangeCount > 0)
            selection.removeAllRanges();
        }
        return newState;
      }
      return state;

    case SHOW_MODAL_DOCUMENT_SHARE:
      if (state.displayModalDocumentShare !== true) {
        const newState = { ...state };
        newState.displayModalDocumentShare = true;
        return newState;
      }
      return state;

    case HIDE_MODAL_DOCUMENT_SHARE:
      if (state.displayModalDocumentShare !== false) {
        const newState = { ...state };
        newState.displayModalDocumentShare = false;
        return newState;
      }
      return state;

    // case SHOW_COMMAND_PALETTE:
    //   if (!state.commandPalette || state.commandPalette.visible !== true) {
    //     return {
    //       ...state,
    //       isCommandPaletteVisible: true,
    //       commandPaletteContext: action.context,
    //     };
    //   }
    //   return state;

    // case HIDE_COMMAND_PALETTE:
    //   if (state.commandPalette.visible !== false) {
    //     return {
    //       ...state,
    //       isCommandPaletteVisible: false,
    //       commandPaletteContext: CommandPaletteContext.GENERAL,
    //     };
    //   }
    //   return state;

    case SET_SWITCH_BASE_MENU_VISIBILITY:
      return { ...state, isSwitchBaseMenuVisible: action.visible };

    case SHOW_MODAL_BASE_SETTINGS:
      if (
        !state.modalBaseSettings ||
        state.modalBaseSettings.display !== true
      ) {
        const newState = { ...state };
        newState.modalBaseSettings = {
          display: true,
          baseId: action.baseId,
        };
        return newState;
      }
      return state;

    case HIDE_MODAL_BASE_SETTINGS: {
      if (state.modalBaseSettings.display !== false) {
        const newState = {
          ...state,
        };
        newState.modalBaseSettings = { display: false, baseId: "" };
        return newState;
      }
      return state;
    }

    case SET_MODAL_IMAGE_SELECT: {
      const newState = { ...state };
      newState.modalImageLink = {
        blockId: action.param.blockId ? action.param.blockId : null,
        display: action.param.display,
        destination: action.destination ? action.destination : null,
      };
      return newState;
    }

    case SET_MODAL_CITATION_SYNC: {
      const newState = { ...state };
      newState.modalCitationsSync = {
        blockId: action.param.blockId ? action.param.blockId : null,
        blockData: action.param.blockData ? action.param.blockData : null,
        display: action.param.display,
        destination: action.destination ? action.destination : null,
        citationData: action.param.citationData
          ? action.param.citationData
          : null,
      };
      return newState;
    }

    case SET_ACCESS_TOKEN:
      if (true) {
        const newState = { ...state };
        newState.accessToken = action.accessToken;
        return newState;
      }

    case REMOVE_ACCESS_TOKEN:
      if (true) {
        const newState = { ...state };
        newState.accessToken = initialState.accessToken;
        return newState;
      }

    case SET_REFRESH_REDIRECT_URL:
      if (true) {
        const newState = { ...state };
        newState.refreshRedirectUrl = action.url;
        return newState;
      }

    case TOGGLE_SIDEBAR_VISIBILITY: {
      const newState = { ...state };
      if (!state.isSidebarHidden) state.isSidebarHidden = false;
      newState.isSidebarHidden = !state.isSidebarHidden;
      return newState;
    }

    case SHOW_MODAL_NEW_PROJECT: {
      const newState = { ...state };
      newState.isModalNewTaskVisible = true;
      if (action.newTaskContext) {
        newState.newTaskContext = action.newTaskContext;
      }
      if (action.onClose) newState.onModalClosed = action.onClose;
      return newState;
    }

    case HIDE_MODAL_NEW_PROJECT:
      if (true) {
        const newState = { ...state };
        newState.isModalNewTaskVisible = false;
        newState.newTaskContext = undefined;
        newState.onModalClosed = null;
        return newState;
      }

    case SHOW_MODAL_NEW_SNIPPET: {
      const newState = { ...state };
      newState.isModalNewSnippetVisible = true;
      if (action.newSnippetContext) {
        newState.newSnippetContext = action.newSnippetContext;
      }
      if (action.onClose) newState.onModalClosed = action.onClose;
      return newState;
    }

    case HIDE_MODAL_NEW_SNIPPET:
      if (true) {
        const newState = { ...state };
        newState.isModalNewSnippetVisible = false;
        newState.newSnippetContext = {} as ISnippetObj;
        newState.onModalClosed = null;
        return newState;
      }

    case SHOW_MODAL_NEW_TEMPLATE: {
      const newState = { ...state };
      newState.isNewTemplateVisible = true;
      if (action.newTemplateContext) {
        newState.newTemplateContext = action.newTemplateContext;
      }
      if (action.onClose) newState.onModalClosed = action.onClose;
      return newState;
    }

    case HIDE_MODAL_NEW_TEMPLATE:
      if (true) {
        const newState = { ...state };
        newState.isNewTemplateVisible = false;
        newState.newTemplateContext = undefined;
        newState.onModalClosed = null;
        return newState;
      }

    case ADD_SELECTED_ENTITIES:
      if (action.entityType && action.ids.length > 0) {
        const newState = { ...state };
        if (state.selectedEntities && state.selectedEntities.type) {
          newState.selectedEntities = {
            type: action.entityType,
            ids: [...newState.selectedEntities.ids, ...action.ids],
          };
        } else {
          newState.selectedEntities = {
            type: action.entityType,
            ids: [...action.ids],
          };
        }
        return newState;
      }
      return state;

    case SELECT_ONE_ENTITY:
      if (action.entityType && action.ids.length > 0) {
        const newState = { ...state };
        newState.selectedEntities = {
          type: action.entityType,
          ids: [...action.ids],
        };
        return newState;
      }
      return state;

    case REMOVE_SELECTED_ENTITIES:
      if (action.entityType && action.ids.length > 0) {
        const newState = {
          ...state,
          selectedEntities: { ...state.selectedEntities },
        };
        newState.selectedEntities = {
          type: action.entityType,
          ids: state.selectedEntities.ids.filter(
            (id: any) => !action.ids.includes(id)
          ),
        };
      }
      return state;

    case CLEAR_SELECTED_ENTITIES:
      if (true) {
        const newState = {
          ...state,
          selectedEntities: { ...state.selectedEntities },
        };
        newState.selectedEntities = {
          type: null,
          ids: [],
        };
        return newState;
      }

    case SET_CURRENT_DISCUSSION_ID:
      activeDiscussion.next(action.id);
      return {
        ...state,
        currentDiscussionId: action.id,
      };

    case SET_NEW_DISCUSSION_DETAIL:
      let newDiscussionDetail: any[] = [...(state.newDiscussionDetail || [])];
      newDiscussionDetail.push(action.detail);
      return {
        ...state,
        newDiscussionDetail,
      };

    case SET_CURRENT_PREDISCUSSION_ID:
      return {
        ...state,
        currentPrediscussionId: action.id,
      };

    case REMOVE_PREDISCUSSION_ID:
      const modifiedDiscussionDetail = [...state.newDiscussionDetail].filter(
        (n: any) => n.id !== action.id
      );
      return {
        ...state,
        newDiscussionDetail: modifiedDiscussionDetail,
      };

    case SET_ALL_DISCUSSIONS_MODAL_VISIBILITY:
      return {
        ...state,
        allDiscussionsModal: action.details,
      };

    case TOGGLE_TEMPLATE_MODAL: {
      const newState = { ...state };
      newState.templateModalData = {
        isOpen: action.isOpen,
        id: action.id,
      };
      return newState;
    }

    case TOGGLE_IMAGE_MODAL: {
      return {
        ...state,
        imageModal: pick(action, ["isOpen", "imageUrl"]),
      };
    }

    case SET_FILTERS_MODAL: {
      return {
        ...state,
        filtersModal: pick(action, ["isOpen", "fixedType", "paneId"]),
      };
    }

    case SET_INVITATIONS_MODAL: {
      return {
        ...state,
        invitationsModal: pick(action, ["isOpen"]),
      };
    }

    case SET_GROUP_INVITATIONS_MODAL: {
      return {
        ...state,
        groupInvitationsModal: pick(action, ["isOpen", "groupId"]),
      };
    }

    case SET_MENTIONS_AND_REPLIES_INBOX: {
      return {
        ...state,
        mentionsAndRepliesInbox: pick(action, ["isOpen"]),
      };
    }

    case SET_ACTIVE_WORKSPACE: {
      return {
        ...initialState,
        ...state,
        search: {
          ...initialState.search,
        },
      };
    }

    case SET_SEARCH: {
      const search = state.search ?? initialState.search;
      return {
        ...state,
        search: {
          ...search,
          ...pick(
            action,
            Object.keys(action).filter((key: string) =>
              [
                "isOpen",
                "resultArray",
                "isSearching",
                "queryString",
                "quickSearchActive",
                "fullSearchActive",
              ].includes(key)
            )
          ),
        },
      };
    }

    case SET_MOUSE_POS: {
      return { ...state, mousePos: pick(action, ["x", "y"]) };
    }

    case SET_MODAL_ITEM_SELECT: {
      const newState = { ...state };
      newState.modalSelectItem = { ...state.modalSelectItem };
      newState.modalSelectItem = action.param;
      return newState;
    }

    case SET_DEFAULT_MODAL: {
      const newState = { ...state };
      newState.defaultModal = action.param;
      return newState;
    }

    case UPDATE_LOCAL_PERMISSIONS: {
      const newPermissions = action.params.permissions;
      const newState = { ...state };
      newState.permissions = {
        ...newState.permissions,
        permissions: { ...newPermissions },
      };
      return newState;
    }

    case SET_SHARING_AND_PERMS_MODAL: {
      return {
        ...state,
        sharingAndPermsModal: pick(action, [
          "isOpen",
          "containerId",
          "containerType",
          "gateId",
          "gateLevel",
        ]),
      };
    }

    case SET_CONTAINER_INVITE_MODAL: {
      return {
        ...state,
        containerInviteModal: pick(action, [
          "isOpen",
          "containerId",
          "containerTitle",
          "gateLevel",
        ]),
      };
    }

    case SET_EDIT_VIEW_MODAL: {
      return {
        ...state,
        editViewModal: pick(action, ["isOpen", "customView"]),
      };
    }

    case SET_MODAL_JOIN_GROUP: {
      return {
        ...state,
        modalJoinClosedGroup: {
          isOpen: action.isOpen,
          roleIds: action.roleIds ?? [],
        },
      };
    }

    case SET_LAST_VISITED_PAGE: {
      const existingLastPages = { ...state.lastVisitedPages };
      existingLastPages[action.param.baseId] = {
        ...action.param,
      };

      return {
        ...state,
        lastVisitedPages: existingLastPages,
      };
    }

    case SET_OPEN_BASE_VIEW: {
      return {
        ...state,
        openBaseView: action.param.openBaseView,
      };
    }

    case SET_SHOW_LEAVE_BASE_MODAL: {
      return {
        ...state,
        showLeaveBaseConfirmation: action.param.showLeaveBaseConfirmation,
        leaveBaseId: action.param.showLeaveBaseConfirmation
          ? action.param.leaveBaseId
          : undefined,
      };
    }

    case SET_BASE_SETTINGS_HAS_CHANGES: {
      return {
        ...state,
        baseSettingsHasChanges: action.param.hasChanges ?? false,
      };
    }

    case OPEN_BASE_SETTING_SET_INTIAL: {
      return {
        ...state,
        openSettings: action.param.openSettings,
      };
    }

    case SET_GROUP_CONTEXT: {
      return {
        ...state,
        paneGroupContext: {
          ...state.paneGroupContext,
          [action.paneId]: {
            groupId: action.groupId,
            groupSlug: action.groupSlug,
          },
        },
      };
    }

    case REMOVE_GROUP_CONTEXT: {
      return {
        ...state,
        paneGroupContext: {
          ...state.paneGroupContext,
          [action.paneId]: {
            groupId: "",
            groupSlug: "",
          },
        },
      };
    }

    case OPEN_DISCORD_INTEGRATION_MODAL: {
      return {
        ...state,
        discordIntegrationModal: {
          isOpen: action.params.isOpen,
          groupId: action.params.groupId,
          workId: action.params.workId,
          intendedStep: action.params.intendedStep,
        },
      };
    }

    case SET_NUDGE_SENT_WORK_ITEM: {
      return {
        ...state,
        workItemReviewNudges: {
          ...state.workItemReviewNudges,
          [action.params.workId]: action.params.date,
        },
      };
    }
    case SET_LAST_USED_TOKEN: {
      return {
        ...state,
        lastUsedToken: {
          networkId: action.params.networkId,
          contractAddress: action.params.contractAddress,
        },
      };
    }

    case SET_ACTION_INTERCEPTOR: {
      return { ...state, interceptedAction: action.param.interceptedAction };
    }

    default:
      return state;
  }
}

export function useTemplateModalToggle() {
  const dispatch = useDispatch();

  return useCallback(
    (id: string) =>
      dispatch({
        type: TOGGLE_TEMPLATE_MODAL,
        isOpen: true,
        id,
      }),
    []
  );
}

export function useSharingAndPermsModalSetter() {
  const dispatch = useDispatch();
  return (state: Partial<ISetSharingAndPermsModal>) =>
    dispatch({
      type: SET_SHARING_AND_PERMS_MODAL,
      ...state,
    });
}

export function useSharingAndPermsModalState() {
  return useShallowSelector(
    (state) => state.client?.sharingAndPermsModal ?? false
  );
}

export function useContainerInviteModalSetter() {
  const dispatch = useDispatch();

  return (state: ISetContainerInviteModal) =>
    dispatch({
      type: SET_CONTAINER_INVITE_MODAL,
      ...state,
    });
}

export function useContainerInviteModalState() {
  return (
    useShallowSelector((state) => state.client.containerInviteModal) ??
    initialState.containerInviteModal
  );
}

export function useDiscordIntegrationModalState() {
  return (
    useShallowSelector((state) => state.client.discordIntegrationModal) ??
    initialState.discordIntegrationModal
  );
}

export function useEditViewModalSetter() {
  const dispatch = useDispatch();

  return (state: Omit<ISetEditViewModal, "type">) =>
    dispatch({
      type: SET_EDIT_VIEW_MODAL,
      ...state,
    });
}

export function useEditViewModalState() {
  return (
    useShallowSelector((state) => state.client.editViewModal) ??
    initialState.editViewModal
  );
}

export function useIsSidebarHidden() {
  return useSelector(
    (state: ClarityStore) => state.client.isSidebarHidden,
    shallowEqual
  );
}

type State = typeof initialState;

export function useSearch(): State["search"] {
  return useSelector(
    (state: ClarityStore) => state.client.search ?? initialState.search,
    shallowEqual
  );
}

export function useSearchSetter() {
  const dispatch = useDispatch();

  return (data: Partial<Omit<ISetSearch, "type">>) =>
    dispatch({
      type: SET_SEARCH,
      ...data,
    });
}

export function useQuickSearchActivator() {
  const setSearch = useSearchSetter();
  return () => setSearch({ quickSearchActive: true, fullSearchActive: false });
}

export function useFullSearchActivator() {
  const setSearch = useSearchSetter();
  return () =>
    setSearch({
      isOpen: true,
      fullSearchActive: true,
      quickSearchActive: false,
    });
}

export function useRoleType() {
  return useShallowSelector((state) => state.client?.roleType);
}

export function useIsBaseMember() {
  return useShallowSelector((state) => state.client?.isBaseMember);
}

export const useSetBaseSettingHasChanges = (clause: boolean, deps: any[]) => {
  useEffect(() => {
    store.dispatch({
      type: SET_BASE_SETTINGS_HAS_CHANGES,
      param: {
        hasChanges: clause,
      },
    });

    return () => {
      store.dispatch({
        type: SET_BASE_SETTINGS_HAS_CHANGES,
        param: {
          hasChanges: false,
        },
      });
    };
  }, [...deps]);
};

export const openSettings = (options: any) => {
  batch(() => {
    store.dispatch({
      type: OPEN_BASE_SETTING_SET_INTIAL,
      param: {
        openSettings: options,
      },
    });
  });
};

export const useCheckBaseSettingsHasChanges = () => {
  return useShallowSelector((state) => state.client?.baseSettingsHasChanges);
};

export const useSettingsOpened = () => {
  return useShallowSelector((state) => state.client?.openSettings);
};

export const setActionInterceptor = (interceptedAction?: ActionIntercept) => {
  return store.dispatch({
    type: SET_ACTION_INTERCEPTOR,
    param: {
      interceptedAction,
    },
  });
};

export const useDiscordIntegrationModalOpener = (
  open: boolean,
  groupId?: string,
  containerId?: string,
  intendedStep: DiscordIntegrationSteps = DiscordIntegrationSteps.GroupConnection
) => {
  return () =>
    store.dispatch({
      type: OPEN_DISCORD_INTEGRATION_MODAL,
      params: {
        isOpen: open,
        groupId,
        workId: containerId,
        intendedStep,
      },
    });
};

export const openNewTaskModal = (newTaskContext: INewTaskContext) => {
  store.dispatch({
    type: SHOW_MODAL_NEW_PROJECT,
    newTaskContext: newTaskContext,
  });
};

export const openNewTemplateModal = (
  newTemplateContext: INewTemplateContext
) => {
  store.dispatch({
    type: SHOW_MODAL_NEW_TEMPLATE,
    newTemplateContext,
  });
};
