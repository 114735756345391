import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "clarity-ui/Modal";
import Button, { ButtonTypes } from "components/Button";
import { IGroup, IRole } from "utilities/types";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { groupApi } from "clientApi/groupsApi";
import styles from "../groups/groups.module.scss";
import { ActionTabSet } from "components/TopNavBar";
import { ActionBtnKind } from "store/reducers/topNavReducer";
import OptionGroup from "clarity-ui/OptionGroup";
import ClarityInput from "components/ClarityInput";
import notificationsApi from "clientApi/notificationsApi";
import RoleRow from "../roles/RoleRow";
import ItemDescription from "clarity-ui/ItemDescription";
import { IdcardOutlined, CloseOutlined } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import UserDisplay from "clarity-ui/UserDisplay";

enum EditorMode {
  details = "Details",
  // requirements = "Requirements",
  members = "Members",
}
const AddOrEditGroup: React.FC<{
  id?: string;
  setdetailsOpen: any;
  disabled?: boolean;
  noRole?: boolean;
}> = ({ id, setdetailsOpen, disabled = false, noRole }) => {
  return (
    <Modal size="medium" hideModal={() => null}>
      <DetailView id={id} setdetailsOpen={setdetailsOpen} disabled={disabled} />
    </Modal>
  );
};

const DetailView: React.FC<{
  id?: string;
  setdetailsOpen: any;
  disabled: boolean;
}> = ({ id, setdetailsOpen, disabled }) => {
  const [isSlugValid, setIsSlugValid] = useState<boolean>(true);

  const existingGroup = useSelector(
    (state: ClarityStore) => (id ? state.groups.dict[id] : null),
    shallowEqual
  );

  const groupDict = useSelector(
    (state: ClarityStore) => state.groups.dict,
    shallowEqual
  );

  const getHeaderText = () => {
    if (id) return "Modify group";
    return "Add new group";
  };

  const [newGroup, setNewGroup] = useState<IGroup>(
    existingGroup ?? groupApi.createNewEmptyGroup()
  );
  const [mode, setmode] = useState(EditorMode.details);

  const tabs = useMemo(() => {
    const tabsArray = [
      EditorMode.details,
      // EditorMode.requirements,
      EditorMode.members,
    ];
    return tabsArray.map((inmode) => {
      return {
        key: inmode,
        onClick: () => setmode(inmode),
        contents: () => <span>{inmode}</span>,
        active: () => inmode === mode,
      };
    });
  }, [mode]);

  useEffect(() => {
    const existingSlugs = Object.values(groupDict).map((group) =>
      group.slug.toLowerCase()
    );

    if (existingSlugs.includes(newGroup.slug.toLowerCase())) {
      if (
        existingGroup &&
        existingGroup.slug.toLowerCase() === newGroup.slug.toLowerCase()
      ) {
        setIsSlugValid(true);
      } else {
        setIsSlugValid(false);
      }
    } else {
      setIsSlugValid(true);
    }
  }, [newGroup.slug, groupDict]);

  useEffect(() => {
    if (existingGroup) {
      setNewGroup({
        ...newGroup,
        ...existingGroup,
      });
    }
  }, [existingGroup]);
  const addGroup = () => {
    if (disabled) return;

    if (existingGroup) {
      groupApi.updateGroup(newGroup, newGroup.id).then(() => {
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Group updated",
        });
      });
      setdetailsOpen(false);
      return;
    } else {
      groupApi.addGroup(newGroup).then(() => {
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Group added",
        });
      });
      setdetailsOpen(false);
      return;
    }
  };

  const deleteGroup = () => {
    if (disabled) return;

    groupApi.deleteGroup(newGroup.id).then(() => {
      notificationsApi.displayConfirmation({
        title: "Success",
        duration: 4,
        body: "Group archived",
      });
    });
    setdetailsOpen(false);
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "12px" }}>
        <h4>{getHeaderText()}</h4>
        <div style={{ flexGrow: 1 }}></div>
        {/* todo: delete */}
        {id && (
          <>
            <Button
              disabled={disabled}
              buttonType={ButtonTypes.LINK}
              onClick={deleteGroup}
            >
              Archive
            </Button>
          </>
        )}
        <Button
          buttonType={ButtonTypes.DEFAULT}
          onClick={() => setdetailsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          onClick={addGroup}
          disabled={
            disabled ||
            newGroup.name.length === 0 ||
            newGroup.slug.length === 0 ||
            !isSlugValid ||
            shallowEqual(newGroup, existingGroup)
          }
        >
          {id ? "Save & Apply" : "Save"}
        </Button>
      </div>
      <div className={styles.modeChecker}>
        <ActionTabSet
          key="tab-set"
          kind={ActionBtnKind.TabSet}
          tabs={tabs}
          noFirstDivider={true}
        />
      </div>

      {mode === EditorMode.details && (
        <GroupDetails
          group={newGroup}
          setNewGroup={setNewGroup}
          disabled={disabled}
          isSlugValid={isSlugValid}
        />
      )}
      {/* {mode === EditorMode.requirements && (
        <GroupRoleRequirements
          group={newGroup}
          setNewGroup={setNewGroup}
          disabled={disabled}
        />
      )} */}
      {mode === EditorMode.members && (
        <GroupMembers group={newGroup} disabled={disabled} />
      )}
    </div>
  );
};

const GroupDetails: React.FC<{
  group: IGroup;
  setNewGroup: React.Dispatch<React.SetStateAction<IGroup>>;
  isSlugValid: boolean;
  disabled: boolean;
}> = ({ group, setNewGroup, disabled, isSlugValid }) => {
  const updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewGroup({ ...group, name: e.target.value });
  };

  const updateDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewGroup({ ...group, description: e.target.value });
  };

  const updateSlug = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewGroup({ ...group, slug: e.target.value.toUpperCase() });
  };

  return (
    <div>
      <div className={styles.editItemRow}>
        <OptionGroup
          title="Group name"
          description="An identifier for this group"
          value=""
          hideValue={true}
        />
        <ClarityInput
          disabled={disabled}
          value={group.name}
          onChange={updateName}
          maxLength={40}
        />
      </div>

      <div className={styles.editItemRow}>
        <OptionGroup
          title="Group description"
          description="This will show in the Group Gallery for all members to see"
          value=""
          hideValue={true}
        />
        <ClarityInput
          disabled={disabled}
          placeholder="What does this group do"
          value={group.description}
          onChange={updateDescription}
        />
      </div>

      <div className={styles.editItemRow}>
        <OptionGroup
          title="Group slug"
          description="Unique slug for the group inside the base"
          value=""
          hideValue={true}
        />
        <ClarityInput
          disabled={disabled}
          placeholder="Slug"
          value={group.slug}
          onChange={updateSlug}
          maxLength={5}
        />
      </div>
      {!isSlugValid && <span>The slug is not unique</span>}
    </div>
  );
};

const GroupMembers: React.FC<{
  group: IGroup;
  disabled: boolean;
}> = ({ group, disabled }) => {
  const kickFromGroup = (userId: string) => {
    groupApi
      .kickFromGroup(group.id, userId)
      .then(() => {
        notificationsApi.displayConfirmation({
          title: <span>Kicked from group</span>,
          body: <span>Kicked user from "{group.name}"</span>,
          duration: 3,
        });
      })
      .catch((e) => {
        notificationsApi.displayError({
          title: <span>Error kicking from group</span>,
          body: <span>{e.response?.data?.message}</span>,
          duration: 3,
        });
      });
  };
  return (
    <div
      style={{
        marginTop: "8px",
        marginBottom: "8px",
      }}
    >
      {group.members?.length === 0 && (
        <span className={"caption secondary"}>
          No members have joined this group
        </span>
      )}
      {group.members?.length !== 0 && (
        <span className={"caption secondary"}>Current members</span>
      )}
      {group.members?.map((memberId) => (
        <GroupMember
          userId={memberId}
          kickFromGroup={kickFromGroup}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

const GroupMember: React.FC<{
  userId: string;
  kickFromGroup: (userId: string) => void;
  disabled: boolean;
}> = ({ userId, kickFromGroup, disabled }) => {
  const user = useSelector(
    (state: ClarityStore) => state.members.dict[userId],
    shallowEqual
  );

  const [isHovered, setisHovered] = useState(false);

  return (
    <div
      className={styles.memberRow}
      onMouseEnter={() => setisHovered(true)}
      onMouseLeave={() => setisHovered(false)}
    >
      <ItemDescription
        noPadding={true}
        title={user.username}
        customIconStyles={{
          border: "none",
          backgroundColor: "transparent",
        }}
        icon={<UserDisplay id={user.id} hideName={true} avatarSize={28} />}
      />
      {isHovered && (
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Button
            className={styles.tokenGateRowEditBtn}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              kickFromGroup(user.id);
            }}
            disabled={disabled}
            buttonType={ButtonTypes.LINK}
            icon={<CloseOutlined />}
          />
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GroupRoleRequirements: React.FC<{
  group: IGroup;
  setNewGroup: React.Dispatch<React.SetStateAction<IGroup>>;
  disabled: boolean;
}> = ({ group, setNewGroup, disabled }) => {
  const [selecting, setSelecting] = useState<boolean>(false);

  const onSelect = useCallback(
    (roleId: string) => {
      if (group.requiredRoles.includes(roleId)) {
        const newArray = group.requiredRoles.filter(
          (existingRoleId) => roleId !== existingRoleId
        );
        setNewGroup({
          ...group,
          requiredRoles: newArray,
        });
      } else {
        setNewGroup({
          ...group,
          requiredRoles: [...group.requiredRoles, roleId],
        });
      }
    },
    [group]
  );
  return (
    <div>
      <div
        className={styles.editItemRow}
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <span className={"caption secondary"}>
          {group.requiredRoles.length === 0
            ? "Any member of this base can join"
            : "Only members with one of the following roles can join"}
        </span>
        <Button
          buttonType={ButtonTypes.LINK}
          onClick={() => setSelecting(true)}
        >
          Add new
        </Button>
      </div>
      <RoleSelector
        selectedRoles={group.requiredRoles}
        editable={true}
        selecting={selecting}
        setSelecting={setSelecting}
        onSelect={onSelect}
      />
    </div>
  );
};

const RoleSelector: React.FC<{
  selectedRoles: string[];
  setSelecting: React.Dispatch<React.SetStateAction<boolean>>;
  selecting: boolean;
  editable: boolean;
  onSelect: (roleId: string) => void;
}> = ({ selectedRoles, selecting, editable, setSelecting, onSelect }) => {
  return (
    <div>
      {selectedRoles.map((roleId) => (
        <RoleRow
          key={roleId}
          id={roleId}
          canEdit={false}
          canViewDetails={true}
        />
      ))}

      {selecting && editable && (
        <RoleSelectorModal
          setSelecting={setSelecting}
          selectedRoles={selectedRoles}
          onSelect={onSelect}
        />
      )}
    </div>
  );
};

const RoleSelectorModal: React.FC<{
  selectedRoles: string[];
  setSelecting: React.Dispatch<React.SetStateAction<boolean>>;
  onSelect: (roleId: string) => void;
}> = ({ setSelecting, selectedRoles, onSelect }) => {
  const roleIds = useSelector(
    (store: ClarityStore) => store.roles.ids,
    shallowEqual
  );
  const roleDict = useSelector(
    (store: ClarityStore) => store.roles.dict,
    shallowEqual
  );

  const [rolesToShow, setRolesToShow] = useState<IRole[]>([]);

  useEffect(() => {
    const noShow = ["Guest", "No Role", "Owner"];
    const options = roleIds
      .map((roleId) => roleDict[roleId])
      .filter((role) => !noShow.includes(role.roleName))
      .filter((role) => role.roleType !== "personal");
    options.sort((a, b) => {
      return b.rank?.localeCompare(a?.rank, "en");
    });
    setRolesToShow(options);
  }, [roleIds]);

  return (
    <Modal size="large" hideModal={() => setSelecting(false)}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <h4>Set Role Requirements</h4>
        <div
          style={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            marginTop: "16px",
          }}
        >
          {rolesToShow.map((role) => (
            <ItemDescription
              noPadding={true}
              title={role.roleName}
              description={role.description}
              icon={<IdcardOutlined />}
              tail={
                <Checkbox
                  checked={selectedRoles.includes(role.id)}
                  onChange={() => onSelect(role.id)}
                />
              }
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
export default AddOrEditGroup;
