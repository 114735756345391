import Icon from "@ant-design/icons";
import React from "react";

export default function PriorityHighIcon(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 13 15"
      width="13px"
      height="13px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop-HD-Copy-5"
          transform="translate(-414.000000, -695.000000)"
          fill="#B396A5"
        >
          <g
            id="light-priority-high"
            transform="translate(414.000000, 695.000000)"
          >
            <rect id="Rectangle" x="0" y="8" width="3" height="7" rx="1.5" />
            <rect
              id="Rectangle-Copy-3"
              x="5"
              y="4"
              width="3"
              height="11"
              rx="1.5"
            />
            <rect
              id="Rectangle-Copy-3"
              x="10"
              y="0"
              width="3"
              height="15"
              rx="1.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
