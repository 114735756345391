import Icon from "@ant-design/icons";
import React from "react";

export default function PriorityUrgentIcon(
  props: React.ComponentProps<typeof Icon>
) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 18 18"
      width="13"
      height="13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop-HD-Copy-5"
          transform="translate(-439.000000, -695.000000)"
          fillRule="nonzero"
        >
          <g
            id="light-priority-urgent"
            transform="translate(439.000000, 695.000000)"
          >
            <rect
              id="Rectangle"
              fill="currentColor"
              opacity="0"
              x="0"
              y="0"
              width="18"
              height="18"
            ></rect>
            <path
              d="M4.43089285,15.9345 L13.5765,15.9345 C15.1511786,15.9345 15.9345,15.1511786 15.9345,13.5993214 L15.9345,4.40035715 C15.9345,2.8485 15.1511786,2.06517857 13.5765,2.06517857 L4.43089285,2.06517857 C2.86392857,2.06517857 2.06517857,2.84110713 2.06517857,4.40035715 L2.06517857,13.5996428 C2.06517857,15.1588928 2.86392857,15.9348214 4.43089285,15.9348214 L4.43089285,15.9345 Z M9.00385715,10.1188928 C8.60464287,10.1188928 8.38607143,9.88521428 8.37064285,9.48567857 L8.26553572,5.7645 C8.25042857,5.34985715 8.56671428,5.05607143 8.99614285,5.05607143 C9.41817857,5.05607143 9.74957142,5.35757143 9.73446428,5.77189285 L9.62132143,9.486 C9.60653572,9.89260715 9.38796428,10.1185714 9.00385715,10.1185714 L9.00385715,10.1188928 Z M9.00385715,12.8908928 C8.5667143,12.8908928 8.16010715,12.5443928 8.16010715,12.0776786 C8.16010715,11.6103214 8.55932143,11.2638214 9.00385715,11.2638214 C9.44067858,11.2638214 9.84760715,11.6029286 9.84760715,12.0773572 C9.84760715,12.5521072 9.4329643,12.8912143 9.00385715,12.8912143 L9.00385715,12.8908928 Z"
              id="Shape"
              fill="#C22F2F"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
