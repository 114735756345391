import { useRef } from "react";

const { v4: uuidv4 } = require("uuid");

const LightStatusSomeday: React.FC = () => {
  const pathId = useRef(uuidv4());
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>light-status-someday</title>
      <defs>
        <circle id={`path-${pathId.current}`} cx="633" cy="573" r="16"></circle>
        <mask
          id={`mask-2-${pathId.current}`}
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="32"
          height="32"
          fill="white"
        >
          <use xlinkHref={`#path-${pathId.current}`}></use>
        </mask>
      </defs>
      <g
        id={`Page-1-${pathId.current}`}
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeDasharray="5,4"
      >
        <g
          id={`Desktop-HD-Copy-3-${pathId.current}`}
          transform="translate(-617.000000, -557.000000)"
          stroke="#CCBFC6"
          strokeWidth="12"
        >
          <use
            id={`light-status-someday-${pathId.current}`}
            mask={`url(#mask-2-${pathId.current})`}
            xlinkHref={`#path-${pathId.current}`}
          ></use>
        </g>
      </g>
    </svg>
  );
};

export default LightStatusSomeday;
