import { Modal } from "antd";
import {
  useSharingAndPermsModalSetter,
  useSharingAndPermsModalState,
} from "store/reducers/clientReducer";
import styles from "components/sharingAndPermsModal/sharingAndPermsModal.module.scss";
import { useRef, useState } from "react";
import { copyTextToClipboard } from "utilities/copyUtils";
import Button, { ButtonTypes } from "./Button";
import { HeadingSection } from "./sharingAndPermsModal/Headings";
import { TokenRequirements } from "./sharingAndPermsModal/TokenRequirments";
import { PermGroup } from "./sharingAndPermsModal/PremGroup";
import { SavePermissionDeltaContext } from "./sharingAndPermsModal/dropdownstate";
import {
  CheckOutlined,
  LinkOutlined,
  useChangeDeltas,
  useEditable,
  useGuestSearchContextValue,
  useSelectedWorkspace,
} from "./sharingAndPermsModal/util";
import {
  GuestInviteSection,
  GuestSearchContext,
} from "./sharingAndPermsModal/UserInvite";
import notificationsApi from "clientApi/notificationsApi";
import { getCorrectLink } from "utilities/linkUtilities";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { useGetContainerFromType } from "modules/containerHelpers";

interface ISharingAndPermissionsModalProps {}

export default function SharingAndPermissionsModal(
  props: ISharingAndPermissionsModalProps
) {
  const { isOpen } = useModalState();
  const { hideModal } = useModalActions();
  const { deltas, setDeltas } = useChangeDeltas();
  const guestSearchCtxValue = useGuestSearchContextValue();
  const baseId = useSelectedWorkspace();
  const { containerId, containerType } = useSharingAndPermsModalState();

  const { editable } = useEditable();
  return (
    <Modal
      visible={isOpen}
      closable={false}
      onCancel={hideModal}
      destroyOnClose={true}
      wrapClassName={"share-modal"}
      footer={[
        <div className={styles.footer}>
          <ShareLink />
          <Button
            key="submit"
            className={styles.footerDone}
            buttonType={ButtonTypes.PRIMARY}
            onClick={hideModal}
          >
            Done
          </Button>
        </div>,
      ]}
    >
      <SavePermissionDeltaContext.Provider
        value={{
          deltas: deltas,
          setDeltas: setDeltas,
          editable: editable,
        }}
      >
        <div className={styles.container}>
          <HeadingSection
            containerId={containerId}
            containerType={containerType}
          />
          <GuestSearchContext.Provider value={guestSearchCtxValue}>
            <GuestInviteSection
              baseId={baseId}
              containerId={containerId}
              containerType={containerType}
            />
          </GuestSearchContext.Provider>
          <TokenRequirements />
          <PermGroup key={"anonymous-guests"} />
        </div>
      </SavePermissionDeltaContext.Provider>
    </Modal>
  );
}

interface IShareLinkProps {}
function ShareLink(props: IShareLinkProps) {
  const {
    shareUrl,
    copyLinkState,
    shareUrlInput,
    handleCopyLinkClick,
    copyLinkStateToText,
  } = useShareLinkState();

  return (
    <div className={styles.shareLinkContainer}>
      <input
        className={styles.shareLinkInput}
        value={shareUrl}
        readOnly
        ref={shareUrlInput}
      />
      <Button
        className={styles.shareLinkButton}
        buttonType={ButtonTypes.DEFAULT}
        onClick={handleCopyLinkClick}
        icon={
          copyLinkState === "success" ? <CheckOutlined /> : <LinkOutlined />
        }
      >
        {copyLinkStateToText[copyLinkState]}
      </Button>
    </div>
  );
}

function useModalState() {
  const { isOpen } = useSharingAndPermsModalState();

  return {
    isOpen,
  };
}

function useModalActions() {
  const setSharingAndPermsModal = useSharingAndPermsModalSetter();

  const hideModal = () => setSharingAndPermsModal({ isOpen: false });

  return {
    hideModal,
  };
}

function useShareLinkState() {
  const originUrl = window.location.origin;
  const [copyLinkState, setCopyLinkState] = useState<"default" | "success">(
    "default"
  );
  const [shareUrl, setShareUrl] = useState("");

  const copyLinkStateToText = {
    default: "Copy link",
    success: "Success",
  };
  const base = useSelector(
    (state: ClarityStore) => state.workspace,
    shallowEqual
  );
  const { containerId, containerType } = useSharingAndPermsModalState();
  const container = useGetContainerFromType(containerId, containerType);
  const shareUrlInput = useRef<HTMLInputElement | null>(null);
  const handleCopyLinkClick = async () => {
    const containerWithType = { ...container, type: containerType };
    const url: string = `${originUrl}${getCorrectLink(
      base,
      containerWithType
    )}`;
    setShareUrl(url);
    copyTextToClipboard(url);
    setCopyLinkState("success");
    notificationsApi.displayConfirmation({
      title: "Success",
      duration: 4,
      body: "Link Copied",
    });
    setTimeout(() => setCopyLinkState("default"), 2000);
  };

  return {
    shareUrl,
    copyLinkState,
    shareUrlInput,
    handleCopyLinkClick,
    copyLinkStateToText,
  };
}
