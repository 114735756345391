import { ReactComponent as GroupsIcon } from "../../icons/menu/Groups.svg";

import Icon, {
  CloseOutlined,
  UnlockOutlined,
  RightOutlined,
  CheckOutlined,
  CopyOutlined,
  LogoutOutlined,
  SettingOutlined,
  PlusOutlined,
  AuditOutlined,
  SnippetsOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
import { sidebarApi } from "clientApi/sidebarApi";
import InboxHoc from "components/mentionsAndRepliesInbox/InboxHoc";
import ModalScrimComponent from "components/ModalScrimComponent";
import SidebarSearch from "components/SidebarSearch";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { SidebarModes } from "store/reducers/sidebarReducer";
import store, { ClarityStore } from "store/storeExporter";
import {
  Abilities,
  BaseType,
  ContainerTypes,
  GeneralViewsNames,
  GroupGeneralViews,
  IGroup,
  PayoutsViewsNames,
  TasksViewModes,
  UserRole,
  ViewNames,
} from "utilities/types";
import SidebarWrapper, {
  SidebarFavoriteGroup,
  SidebarGroup,
  SidebarItem,
  SidebarItemAction,
  SidebarSection,
} from "./Sidebar";
import claritySidebarStyle from "./sidebar/claritySidebar.module.scss";
import {
  SectionNumber,
  useIsMobile,
  useShallowSelector,
} from "utilities/hooks";
import Button, { ButtonShapes, ButtonTypes } from "components/Button";
import {
  getLinkFromContainer,
  getNameFromContainer,
  useGetContainerFromType,
} from "modules/containerHelpers";
import { VariableContainerIcon } from "clarity-ui/VariableContainerIcon";
import { useAbilityChecker } from "editor/utils/customHooks";
import { copyTextToClipboard } from "utilities/copyUtils";
import { groupApi } from "clientApi/groupsApi";
import notificationsApi from "clientApi/notificationsApi";
import {
  useIsBaseMember,
  openNewTaskModal,
  INewTaskCreationModes,
  openSettings,
} from "store/reducers/clientReducer";
import { locationSubject } from "components/LocationListener";
import Conditional from "components/Conditional";
import { Divider, Dropdown } from "antd";
import navigationApi from "clientApi/navigationApi";
import {
  createAndSetNewNote,
  createAndSetNewProject,
} from "utilities/createNewContainers";
import { usePaneId } from "store/reducers/filterReducer";
import { InboxHeader } from "components/mentionsAndRepliesInbox/MentionsAndRepliesInbox";
import ControlTwoTone from "icons/Components/ControlTwoTone";
import { ChunkDestination } from "utilities/stateTypes";
import { navigateToUrl } from "utilities/paneNavigationUtils";
import { MemberOnly } from "components/MemberOnly";
import StarTwoTone from "icons/Components/StarTwoTone";
import { navigateToBase, navigateToGroup } from "./NavigationContext";
import HomeTwoTone from "icons/Components/HomeTwoTone";
import IdcardTwoTone from "icons/Components/IdcardTwoTone";
import { SizeContext } from "App";
import ScheduleTwoTone from "icons/Components/ScheduleTwoTone";
import SettingTwoTone from "icons/Components/SettingTwoTone";
import TagTwoTone from "icons/Components/TagTwoTone";
import * as actionTypes from "store/actions";
import TrophyTwoTone from "icons/Components/TrophyTwoTone";

const ClaritySidebar: React.FC = () => {
  const isMobile = useIsMobile();
  useLayoutEffect(() => {
    if (isMobile) sidebarApi.setSidebarMode({ isOpen: false });
  }, [isMobile]);

  return (
    <>
      <Conditional on={!isMobile}>
        <DesktopSidebar />
      </Conditional>
      <Conditional on={isMobile}>
        <MobileSidebar />
      </Conditional>
    </>
  );
};

const DesktopSidebar: React.FC = () => {
  const isTemporary = useShallowSelector((state) => state.sidebar.isTemporary);
  const isOpen = useShallowSelector((state) => state.sidebar.isOpen);

  return (
    <div className={`${claritySidebarStyle.sidebarContainer} h-100`}>
      <Conditional on={!isTemporary && isOpen}>
        <OpenSidebar isTemporary={isTemporary} />
      </Conditional>
    </div>
  );
};

const MobileSidebar: React.FC = () => {
  const isOpen = useShallowSelector((state) => state.sidebar.isOpen);
  return (
    <>
      <Conditional on={isOpen}>
        <SidebarLocationListener />
        <div
          className={`${claritySidebarStyle.sidebarContainer} ${claritySidebarStyle.isMobile} h-100`}
        >
          <OpenSidebar isTemporary={false} />
        </div>
      </Conditional>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TemporarySidebar: React.FC = () => {
  const isOpen = useShallowSelector((state) => state.sidebar.isOpen);
  const maxWidth = useShallowSelector(
    (state) => state.navigation.dimension.sideBarWidth ?? 265
  );

  return (
    <>
      <div className={claritySidebarStyle.sidebarPlaceholder}></div>
      <Conditional on={!isOpen}>
        <Button
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            sidebarApi.setSidebarMode({ isOpen: true, isTemporary: false });
          }}
          className={claritySidebarStyle.floatButton}
          shape={ButtonShapes.CIRCLE}
          icon={<RightOutlined />}
        />
      </Conditional>

      <div
        className={`${claritySidebarStyle.sidebarWrapper} ${
          isOpen ? claritySidebarStyle.temporary : ""
        }`}
        style={{ width: isOpen ? maxWidth : "16px" }}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          if (isOpen) return;
          sidebarApi.setSidebarMode({ isOpen: true, isTemporary: true });
        }}
        onMouseLeave={() =>
          sidebarApi.setSidebarMode({ isOpen: false, isTemporary: true })
        }
      >
        <Conditional on={isOpen}>
          <div style={{ minWidth: isOpen ? maxWidth : "16px" }}>
            <OpenSidebar isTemporary={true} />
          </div>
        </Conditional>
      </div>
    </>
  );
};

const OpenSidebar: React.FC<{ isTemporary: boolean }> = ({ isTemporary }) => {
  const mode = useShallowSelector((store) => store.sidebar.sidebarMode);

  const classNames = useMemo(() => {
    return claritySidebarStyle.sidebar + ` ${claritySidebarStyle.open}`;
  }, [mode]);

  const [inboxMode, setinboxMode] = useState<"inbox" | "done">("inbox");

  return (
    <>
      <div tabIndex={0} className={classNames}>
        <SidebarHeader
          isTemporary={isTemporary}
          mode={mode}
          inboxMode={inboxMode}
          setinboxMode={setinboxMode}
        />

        <div className={claritySidebarStyle.modeContainer}>
          <ModeVisualizer inboxMode={inboxMode} />
        </div>
      </div>
    </>
  );
};

const ModeVisualizer: React.FC<{ inboxMode: "inbox" | "done" }> = ({
  inboxMode,
}) => {
  const mode = useShallowSelector((store) => store.sidebar.sidebarMode);
  return useMemo(() => {
    switch (mode) {
      case SidebarModes.home: {
        return <BaseHomeSidebar />;
      }
      case SidebarModes.search: {
        return <SidebarSearch />;
      }
      case SidebarModes.inbox: {
        return <InboxHoc inboxMode={inboxMode} />;
      }
      default:
        return <></>;
    }
  }, [mode, inboxMode]);
};

export const SidebarHeader: React.FC<{
  isTemporary: boolean;
  mode: SidebarModes;
  setinboxMode: React.Dispatch<React.SetStateAction<"inbox" | "done">>;
  inboxMode: "inbox" | "done";
}> = ({ isTemporary, mode, inboxMode, setinboxMode }) => {
  const classNames = useMemo(() => {
    let addedClasses = "";
    switch (mode) {
      case SidebarModes.closed: {
        addedClasses = " " + claritySidebarStyle.closed;
        break;
      }
    }
    return claritySidebarStyle.topBar + addedClasses;
  }, [mode]);

  const setmode = useCallback((newMode: SidebarModes) => {
    sidebarApi.setSidebarMode({ newMode });
  }, []);

  return (
    <>
      <Conditional
        on={[SidebarModes.search, SidebarModes.inbox].includes(mode)}
      >
        <div className={claritySidebarStyle.headerContainer}>
          <div className={classNames}>
            <div className={claritySidebarStyle.selector}>
              <Conditional on={SidebarModes.inbox === mode}>
                <InboxHeader mode={inboxMode} setMode={setinboxMode} />
                <Button
                  onClick={() => setmode(SidebarModes.home)}
                  buttonType={ButtonTypes.LINK}
                  icon={<CloseOutlined />}
                />
              </Conditional>
              <Conditional on={[SidebarModes.search].includes(mode)}>
                <Button
                  onClick={() => setmode(SidebarModes.home)}
                  buttonType={ButtonTypes.LINK}
                  icon={<CloseOutlined />}
                />
              </Conditional>
            </div>
          </div>
        </div>
      </Conditional>
    </>
  );
};

const SidebarLocationListener: React.FC<{}> = () => {
  const location = useLocation();
  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }
    sidebarApi.setSidebarMode({ isOpen: false });
  }, [location]);

  return (
    <>
      <ModalScrimComponent
        hideModal={() => sidebarApi.setSidebarMode({ isOpen: false })}
        customStyles={{ zIndex: 90 }}
      />
    </>
  );
};

export const InboxCounter: React.FC = () => {
  const counts = useSelector(
    (state: ClarityStore) => ({
      allUnreadCount: state.inAppNotifications.allUnreadCount,
      allMentionCount: state.inAppNotifications.allMentionsCount,
    }),
    shallowEqual
  );

  const innerBadge = useMemo(() => {
    if (counts.allUnreadCount === 0) return <></>;
    if (counts.allMentionCount > 0)
      return (
        <div className={claritySidebarStyle.mentionsBadge}>
          <span>
            {counts.allMentionCount > 9 ? "9+" : counts.allMentionCount}
          </span>
        </div>
      );
    return <div className={claritySidebarStyle.badge}></div>;
  }, [counts.allMentionCount, counts.allUnreadCount]);

  if (counts.allUnreadCount === 0) return <></>;
  return (
    <span
      className={claritySidebarStyle.inboxBadge}
      style={
        counts.allMentionCount > 0 ? { marginLeft: 0, marginRight: 0 } : {}
      }
    >
      {innerBadge}
    </span>
  );
};

const BaseHomeSidebar: React.FC<{}> = () => {
  const userRole = useSelector(
    (state: ClarityStore) => state.client.roleType,
    shallowEqual
  );
  const baseType = useShallowSelector((state) => state.workspace.type);

  if (userRole !== UserRole.GUEST || baseType === BaseType.Public) {
    return <MemberSidebar userRole={userRole} />;
  }
  return <GuestSidebar />;
};

const MemberSidebar: React.FC<{
  userRole: UserRole;
}> = ({ userRole }) => {
  const baseData = useSelector((state: ClarityStore) => {
    return {
      baseSlug: state.workspace.slug,
      showCycles: state.workspace.showCycles,
      showWeekly: state.workspace.showWeekly,
      showMilestones: state.workspace.showMilestones,
      showContributions: state.workspace.showContributions,
      name: state.workspace.name,
      groupsDict: state.groups.dict,
      userGroups: state.groups.userGroups,
      groupIds: state.groups.ids,
      baseId: state.workspace.id,
      baseType: state.workspace.type,
      userId: state.user?.id,
    };
  }, shallowEqual);

  const isBaseMember = useIsBaseMember();

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });
  const size = useContext(SizeContext);

  const showDrawer = () => {
    store.dispatch({
      type: actionTypes.OPEN_BASE_SETTING_SET_INTIAL,
      param: {
        openSettings: {
          isOpen: true,
        },
      },
    });
  };

  return (
    <>
      <div style={{ paddingTop: "22px", width: "100%", height: "0" }}></div>
      <SidebarWrapper withHeader>
        <Conditional on={isBaseMember && SectionNumber[size] < 3}>
          <MemberOnly>
            <SidebarGroup>
              <SidebarItem
                to="inbox"
                ItemIcon={InboxOutlined}
                label="Inbox"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  sidebarApi.setSidebarMode({
                    newMode: SidebarModes.inbox,
                    isTemporary: false,
                  });
                }}
              >
                <InboxCounter />
              </SidebarItem>

              <SidebarItem
                to={`/${baseData.baseSlug}/my-work`}
                ItemIcon={AuditOutlined}
                label="My Work"
                tasksViewMode={TasksViewModes.MyTasks}
                viewName={GeneralViewsNames.Tasks}
              />
              <Divider style={{ marginBottom: 0, marginTop: "21px" }} />
            </SidebarGroup>
          </MemberOnly>
        </Conditional>

        <SidebarGroup>
          <MemberOnly>
            <SidebarItem
              to={`/${baseData.baseSlug}`}
              ItemIcon={HomeTwoTone}
              label={"Home"}
              onClick={(e) => {
                navigateToBase(ChunkDestination.primary, e.shiftKey);
              }}
              viewName={GeneralViewsNames.Home}
            />

            <Conditional on={isBaseMember && baseData.showWeekly}>
              <SidebarItem
                ItemIcon={StarTwoTone}
                to={`/${baseData.baseSlug}/weekly`}
                label="Weekly"
                viewName={GeneralViewsNames.TheWeekly}
              />
            </Conditional>
          </MemberOnly>

          <SidebarItem
            to={`/${baseData.baseSlug}/roadmap`}
            ItemIcon={ScheduleTwoTone}
            label={"Roadmap"}
            actionFn={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              locationSubject.next(`/${baseData.baseSlug}/roadmap`);
            }}
            viewName={GeneralViewsNames.Roadmap}
          />
          <SidebarItem
            ItemIcon={IdcardTwoTone}
            to={`/${baseData.baseSlug}/groups`}
            label="Groups & Roles"
            actionFn={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              locationSubject.next(`/${baseData.baseSlug}/groups`);
            }}
            viewName={GeneralViewsNames.Groups}
          />
          <SidebarItem
            ItemIcon={SnippetsOutlined}
            to={`/${baseData.baseSlug}/templates`}
            label="Templates"
            actionFn={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              locationSubject.next(`/${baseData.baseSlug}/templates`);
            }}
            viewName={GeneralViewsNames.Templates}
          />

          <SidebarItem
            to={`/${baseData.baseSlug}/settings`}
            ItemIcon={SettingTwoTone}
            label={"Settings"}
            onClick={(e) => {
              showDrawer();
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <MoreMenu
            baseSlug={baseData.baseSlug}
            canEditEntity={canEditEntity}
            showPayouts={baseData.showContributions}
          />
        </SidebarGroup>

        <SidebarGroup>
          <SidebarSection title="My Groups" noToggle={true}>
            <MemberOnly>
              <Conditional on={baseData.userGroups.length === 0}>
                <div style={{ paddingLeft: "16px" }}>
                  <span className="small disabled">
                    You are not a member of any groups in this base.{" "}
                    <span
                      onClick={(e) => {
                        if (e.shiftKey) {
                          return navigationApi.openSplitView({
                            viewName: GeneralViewsNames.Groups,
                          });
                        } else {
                          locationSubject.next(`/${baseData.baseSlug}/groups`);
                        }
                      }}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Join a group here
                    </span>
                  </span>
                </div>
              </Conditional>
              {baseData.userGroups.map((groupId: string) => {
                const group = baseData.groupsDict[groupId];
                if (!group || group.workspaceId !== baseData.baseId) {
                  return <React.Fragment key={groupId}></React.Fragment>;
                }

                return (
                  <GroupItem
                    key={groupId}
                    baseData={baseData}
                    group={group}
                    groupId={groupId}
                  />
                );
              })}
            </MemberOnly>
            {baseData.baseType === BaseType.Public &&
              (!isBaseMember || !baseData.userId) &&
              baseData.groupIds.map((groupId: string) => {
                const group = baseData.groupsDict[groupId];
                if (!group || group.workspaceId !== baseData.baseId) {
                  return <React.Fragment key={groupId}></React.Fragment>;
                }

                return (
                  <GroupItem
                    key={groupId}
                    baseData={baseData}
                    group={group}
                    groupId={groupId}
                  />
                );
              })}
          </SidebarSection>
        </SidebarGroup>
        <Conditional on={isBaseMember}>
          <SidebarFavoriteSection />
        </Conditional>
      </SidebarWrapper>
    </>
  );
};

const GroupItem: React.FC<{
  baseData: any;
  group: IGroup;
  groupId: string;
}> = ({ groupId, baseData, group }) => {
  return (
    <SidebarItem
      icon={<Icon component={GroupsIcon} />}
      menu={<GroupMoreMenu group={group} baseData={baseData} />}
      addMenu={<GroupAddOptionsMenu group={group} baseId={baseData.baseId} />}
      key={groupId}
      label={group?.name}
      to={`/${baseData.baseSlug}/group/${group?.slug}`}
      groupSlug={group?.slug}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigateToGroup(groupId, ChunkDestination.primary, e.shiftKey);
      }}
      viewName={GroupGeneralViews.GroupProfile}
    />
  );
};

const GroupMoreMenu: React.FC<{ group: IGroup; baseData: any }> = ({
  group,
  baseData,
}) => {
  const base_url = window.location.origin;
  const canManageRoles = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });

  const [copied, setCopied] = useState(false);
  const [ismenuOpened, setIsmenuOpened] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupSetting, setGroupSetting] = useState({});
  const isCopiedTimeout = useRef<null | NodeJS.Timeout>(null);

  const openSidebar = () => {
    const groupSetting = {
      isOpen: true,
      selectedSection: "editGroup",
      selectedGroup: group.id,
    };
    openSettings(groupSetting);
    setTimeout(() => {
      setGroupSetting({});
    }, 0);
    setIsmenuOpened(false);
    openSettings(groupSetting);
  };

  const copyGroupLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    copyTextToClipboard(
      `${base_url}/${baseData.baseSlug}/group/${group?.slug}`
    );
    setCopied(true);
    if (isCopiedTimeout.current) clearTimeout(isCopiedTimeout.current);
    isCopiedTimeout.current = setTimeout(() => {
      setCopied(false);
    }, 1500);
    setIsmenuOpened(false);
  };

  const handleVisibleChange = (flag: boolean) => {
    setIsmenuOpened(flag);
  };

  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={ismenuOpened}
      overlay={
        <div className="ant-dropdown-menu">
          <SidebarItemAction
            icon={copied ? <CheckOutlined /> : <CopyOutlined />}
            label="Copy link"
            onClick={copyGroupLink}
            isActive={false}
            parentClass={false}
          />
          {baseData.userGroups.includes(group.id) && (
            <SidebarItemAction
              icon={<LogoutOutlined />}
              label="Leave group"
              parentClass={false}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                groupApi
                  .leaveGroup(group.id)
                  .then(() => {
                    notificationsApi.displayConfirmation({
                      title: <span>Left group</span>,
                      body: <span>You left "{group.name}"</span>,
                      duration: 3,
                    });
                  })
                  .catch((e) => {
                    notificationsApi.displayError({
                      title: <span>Error leaving group</span>,
                      body: <span>{e.response?.data?.message}</span>,
                      duration: 3,
                    });
                  });
              }}
              isActive={false}
            />
          )}
          {canManageRoles && (
            <SidebarItemAction
              parentClass={false}
              icon={<SettingOutlined />}
              label="Settings"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                openSidebar();
              }}
              isActive={false}
            />
          )}
        </div>
      }
      trigger={["click"]}
    >
      <Button
        className={claritySidebarStyle.action}
        buttonType={ButtonTypes.LINK}
        onClick={(e: any) => {
          e.stopPropagation();
          setIsmenuOpened(false);
        }}
        icon={<MoreHorizontalDots />}
      ></Button>
    </Dropdown>
  );
};

const GroupAddOptionsMenu: React.FC<{ group: IGroup; baseId: string }> = ({
  group,
  baseId,
}) => {
  const canManageRoles = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });
  const [ismenuOpened, setIsmenuOpened] = useState(false);
  const paneId = usePaneId();

  const createNote = (e: any) => {
    e.preventDefault();
    if (canManageRoles) {
      createAndSetNewNote(e, group.id, baseId);
    }
    setIsmenuOpened(false);
  };

  const createProject = (e: any) => {
    e.preventDefault();
    if (canManageRoles) {
      createAndSetNewProject(e, baseId, paneId, group.id);
    }
    setIsmenuOpened(false);
  };

  const createTask = (e: any) => {
    e.preventDefault();
    if (canManageRoles) {
      openNewTaskModal({
        type: INewTaskCreationModes.new,
        presetData: { groupId: group.id },
      });
    }
    setIsmenuOpened(false);
  };

  const handleVisibleChange = (flag: boolean) => {
    setIsmenuOpened(flag);
  };

  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={ismenuOpened}
      overlay={
        <div className="ant-dropdown-menu">
          <SidebarItemAction
            icon={<SnippetsOutlined />}
            label="Doc"
            onClick={(e) => {
              createNote(e);
            }}
            disable={!canManageRoles}
            parentClass={false}
          />
          <SidebarItemAction
            icon={<CheckCircleOutlined />}
            label="Task"
            disable={!canManageRoles}
            onClick={(e) => {
              createTask(e);
            }}
            parentClass={false}
          />
          <SidebarItemAction
            icon={<CheckSquareOutlined />}
            label="Project"
            disable={!canManageRoles}
            onClick={(e) => {
              createProject(e);
            }}
            parentClass={false}
          />
        </div>
      }
      trigger={["click"]}
    >
      <Button
        className={claritySidebarStyle.action}
        buttonType={ButtonTypes.LINK}
        onClick={(e: any) => {
          e.stopPropagation();
          setIsmenuOpened(false);
        }}
        icon={<PlusOutlined />}
      ></Button>
    </Dropdown>
  );
};

const GuestSidebar: React.FC = () => {
  const baseSlug = useSelector(
    (store: ClarityStore) => store.workspace.slug,
    shallowEqual
  );

  const baseIsJoinable = useSelector((store: ClarityStore) => {
    return store.workspace.isJoinable;
  }, shallowEqual);

  return (
    <SidebarWrapper withHeader>
      <SidebarGroup>
        {baseIsJoinable && (
          <SidebarItem
            to={`/${baseSlug}/join`}
            icon={<UnlockOutlined />}
            label="Join base"
          />
        )}
        <SidebarItem
          to={`/${baseSlug}/my-work`}
          ItemIcon={AuditOutlined}
          label="My Work"
          tasksViewMode={TasksViewModes.MyTasks}
        />
      </SidebarGroup>
      <GuestSharedContainers />
      <GuestFollowingContainers />
    </SidebarWrapper>
  );
};

const SidebarFavoriteSection: React.FC<{}> = () => {
  const state = useSelector((state: ClarityStore) => {
    return {
      base: state.workspace,
      favorites: state.sidebar.favorites,
      favoriteIds: state.sidebar.favoriteIds,
    };
  }, shallowEqual);

  return (
    <SidebarFavoriteGroup
      favoriteIds={state.favoriteIds}
      favorites={state.favorites}
    />
  );
};

const GuestSharedContainers: React.FC = () => {
  const [sharedEntries, setSharedEntries] = useState<any[]>([]);
  const emptyArray = useRef([]);

  const followingEntities = useSelector((store: ClarityStore) => {
    return {
      work: store.work.followingWorkIds,
      pages: store.pages.followingIds,
      notes: store.notes.followingIds,
    };
  }, shallowEqual);

  const sharedEntities = useSelector((store: ClarityStore) => {
    return {
      projects: store.client.permissions?.permissions?.work
        ? store.client.permissions.permissions.work.read
        : emptyArray.current,
      pages: store.client.permissions?.permissions?.pages
        ? store.client.permissions.permissions.pages.read
        : emptyArray.current,
      notes: store.client.permissions?.permissions?.notes
        ? store.client.permissions.permissions.notes.read
        : emptyArray.current,
    };
  }, shallowEqual);

  useEffect(() => {
    const items: any[] = [];

    sharedEntities.projects.forEach((item) => {
      if (!followingEntities.work.includes(item) && !items.includes(item))
        items.push({
          containerId: item,
          containerType: ContainerTypes.PROJECT,
        });
    });

    sharedEntities.notes.forEach((item) => {
      if (!followingEntities.notes.includes(item) && !items.includes(item))
        items.push({ containerId: item, containerType: ContainerTypes.NOTE });
    });
    sharedEntities.pages.forEach((item) => {
      if (!followingEntities.pages.includes(item) && !items.includes(item))
        items.push({
          containerId: item,
          containerType: ContainerTypes.DOCUMENT,
        });
    });
    setSharedEntries(items);
  }, [sharedEntities, followingEntities]);

  return (
    <SidebarGroup>
      <SidebarSection noToggle={true} title="Shared with me">
        {sharedEntries.length === 0 && (
          <div style={{ paddingLeft: "25px" }}>
            <span className="small disabled">
              Nothing is shared with you yet
            </span>
          </div>
        )}
        {sharedEntries.map((item) => (
          <VariableItem
            key={item.containerId}
            containerId={item.containerId}
            containerType={item.containerType}
          />
        ))}
      </SidebarSection>
    </SidebarGroup>
  );
};

const GuestFollowingContainers: React.FC = () => {
  const [followingEntries, setfollowingEntries] = useState<any[]>([]);
  const emptyArray = useRef([]);

  const followingEntities = useSelector((store: ClarityStore) => {
    return {
      work: store.work.followingWorkIds,
      pages: store.pages.followingIds,
      notes: store.notes.followingIds,
    };
  }, shallowEqual);

  const sharedEntities = useSelector((store: ClarityStore) => {
    return {
      projects: store.client.permissions?.permissions?.work
        ? store.client.permissions.permissions.work.read
        : emptyArray.current,
      pages: store.client.permissions?.permissions?.pages
        ? store.client.permissions.permissions.pages.read
        : emptyArray.current,
      notes: store.client.permissions?.permissions?.notes
        ? store.client.permissions.permissions.notes.read
        : emptyArray.current,
    };
  }, shallowEqual);

  useEffect(() => {
    const items: any[] = [];
    followingEntities.work.forEach((item) => {
      if (!items.includes(item))
        items.push({
          containerId: item,
          containerType: ContainerTypes.PROJECT,
        });
    });

    followingEntities.pages.forEach((item) => {
      if (!items.includes(item))
        items.push({
          containerId: item,
          containerType: ContainerTypes.DOCUMENT,
        });
    });
    followingEntities.notes.forEach((item) => {
      if (!items.includes(item))
        items.push({ containerId: item, containerType: ContainerTypes.NOTE });
    });
    setfollowingEntries(items);
  }, [followingEntities, sharedEntities]);

  return (
    <SidebarGroup>
      <SidebarSection title="Following">
        {followingEntries.length === 0 && (
          <div style={{ paddingLeft: "25px" }}>
            <span className="small disabled">Not following anything yet</span>
          </div>
        )}
        {followingEntries.map((item) => (
          <VariableItem
            key={item.containerId}
            containerId={item.containerId}
            containerType={item.containerType}
          />
        ))}
      </SidebarSection>
    </SidebarGroup>
  );
};

export const VariableItem: React.FC<{
  containerId: string;
  containerType: ContainerTypes;
}> = ({ containerId, containerType }) => {
  const container = useGetContainerFromType(containerId, containerType);

  const name = useMemo(() => {
    return getNameFromContainer(container, containerType);
  }, [container, containerType]);

  return (
    <SidebarItem
      to={getLinkFromContainer(container, containerType)}
      icon={<VariableContainerIcon containerType={containerType} />}
      label={name}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const openPrimary = () => {
          locationSubject.next(getLinkFromContainer(container, containerType));
        };

        const openSecondary = () => {
          navigationApi.openSplitView({
            viewName: ViewNames.Detail,
            containerId: container.id,
            containerType: containerType,
          });
        };

        navigateToUrl(e, ChunkDestination.primary, openPrimary, openSecondary);
      }}
    />
  );
};

const MoreMenu: React.FC<{
  baseSlug: string;
  canEditEntity: boolean;
  showPayouts: boolean;
}> = ({ baseSlug, canEditEntity, showPayouts }) => {
  const [ismenuOpened, setIsmenuOpened] = useState(false);

  const handleVisibleChange = () => setIsmenuOpened(!ismenuOpened);

  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={ismenuOpened}
      overlay={
        <div className="ant-dropdown-menu">
          <SidebarItem
            ItemIcon={ControlTwoTone}
            to={`/${baseSlug}/views`}
            parentClass={false}
            label="Views"
            actionFn={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              locationSubject.next(`/${baseSlug}/view/new`);
              handleVisibleChange();
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleVisibleChange();
              if (e.shiftKey) {
                return navigationApi.openSplitView({
                  viewName: GeneralViewsNames.Views,
                });
              } else {
                locationSubject.next(`/${baseSlug}/views`);
              }
            }}
            viewName={GeneralViewsNames.Views}
            actionIcon={canEditEntity ? <PlusOutlined /> : null}
          />
          <SidebarItem
            to={`/${baseSlug}/tags`}
            ItemIcon={TagTwoTone}
            parentClass={false}
            label={"Tags"}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleVisibleChange();
              if (e.shiftKey) {
                return navigationApi.openSplitView({
                  viewName: GeneralViewsNames.Wiki,
                });
              } else {
                locationSubject.next(`/${baseSlug}/tags`);
              }
            }}
            viewName={GeneralViewsNames.Wiki}
          />
          {showPayouts && (
            <SidebarItem
              to={`/${baseSlug}/payouts`}
              ItemIcon={TrophyTwoTone}
              parentClass={false}
              label={"Payouts"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleVisibleChange();
                if (e.shiftKey) {
                  return navigationApi.openSplitView({
                    viewName: PayoutsViewsNames.Payouts,
                  });
                } else {
                  locationSubject.next(`/${baseSlug}/payouts`);
                }
              }}
              viewName={PayoutsViewsNames.Payouts}
            />
          )}
        </div>
      }
      trigger={["click"]}
    >
      <SidebarItem
        to="more"
        label="More"
        ItemIcon={MoreHorizontalDots}
        onClick={(e: any) => {
          e.stopPropagation();
          setIsmenuOpened(true);
        }}
        icon={<MoreHorizontalDots />}
      />
    </Dropdown>
  );
};

export default ClaritySidebar;
