import { ReactComponent as StatusInprogressIcon } from "../icons/light-status-inprogress.svg";
import { ReactComponent as StatusUnstartedIcon } from "../icons/light-status-unstarted.svg";
import StatusSomedayIcon from "icons/LightStatusSomeday";
import StatusCompletedIcon from "../icons/LightStatusCompleted";
import StatusCanceledIcon from "../icons/LightStatusCanceled";
import { ReactComponent as StatusProjectSomedayIcon } from "../icons/light-status-project-someday.svg";
import { ReactComponent as StatusProjectInprogressIcon } from "../icons/light-status-project-inprogress.svg";
import { ReactComponent as StatusProjectUnstartedIcon } from "../icons/light-status-project-todo.svg";
import { ReactComponent as StatusProjectCompletedIcon } from "../icons/light-status-project-completed.svg";
import { ReactComponent as StatusProjectCanceledIcon } from "../icons/light-status-project-canceled.svg";
import store from "store/storeExporter";
import React from "react";
import { ContainerTypes, IWorkStatus, WorkTypes } from "utilities/types";
import { Tooltip } from "antd";
import styles from "./statusDisplay/statusDisplay.module.scss";
import { useOptionalClassName } from "utilities/hooks";
import { useCommandPaletteShower } from "store/reducers/commandPaletteReducer";
import { CommandPaletteContext } from "utilities/types";
import Icon from "@ant-design/icons";

const getStatusIcon = (status: IWorkStatus, isProject?: boolean) =>
  getIconFromCategory(status?.categoryId, isProject);

const getIconFromCategory = (categoryId: string, isProject?: boolean) => {
  switch (categoryId) {
    case "1":
      if (isProject) return StatusProjectSomedayIcon;
      return StatusSomedayIcon;
    case "2":
      if (isProject) return StatusProjectUnstartedIcon;
      return StatusUnstartedIcon;
    case "3":
      if (isProject) return StatusProjectInprogressIcon;
      return StatusInprogressIcon;
    case "4":
      if (isProject) return StatusProjectCompletedIcon;
      return StatusCompletedIcon as any;
    case "5":
      if (isProject) return StatusProjectCanceledIcon;
      return StatusCanceledIcon;
    default:
      return null;
  }
};

interface StatusIndicatorProps {
  statusId: string;
  size?: "small" | "medium" | "large" | "xlarge";
  showName: boolean;
  hideIcon?: boolean;
  workType?: WorkTypes;
  customStyle?: React.CSSProperties;
  captionClass?: "small" | "caption" | string;
  heavyCaption?: boolean;
  noSpace?: boolean;
  editId?: string;
  captionStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  containerType?: ContainerTypes;
}

const StatusDisplay: React.FC<StatusIndicatorProps> = (props) => {
  const status = store.getState().work.statuses?.dict?.[props.statusId];
  const openPalette = useCommandPaletteShower();
  const edit: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.disabled) return;
    openPalette(CommandPaletteContext.STATUS, {
      selectedItemIds: props?.editId ? [props?.editId] : [],
      slectedItemsType: props.containerType ?? ContainerTypes.WORK,
    });
  };

  const icon = getStatusIcon(
    status,
    props.workType &&
      [WorkTypes.PROJECT, WorkTypes.INITIATIVE].includes(props.workType)
  );

  const captionClass = useOptionalClassName({
    baseStyle: styles.caption,
    pairs: [
      {
        extraStyle: styles.captionHeavy,
        withExtra: Boolean(props.heavyCaption),
      },
      {
        extraStyle: props.captionClass ?? "",
        withExtra: Boolean(props.captionClass),
      },
      /* {
       *   extraStyle: "caption",
       *   withExtra: !Boolean(props.captionClass),
       * }, */
    ],
  });

  const style = {
    ...(props.noSpace ? { gap: 0 } : {}),
    ...(props.editId ? { cursor: "pointer" } : {}),
    ...(props.containerStyle ?? {}),
  };

  if (!status) return <></>;

  return (
    <div
      className={styles.container}
      style={style}
      onClick={!props.editId ? undefined : edit}
    >
      {icon && !props.hideIcon ? (
        <Tooltip title={status.name} placement="top">
          <Icon component={icon} />
        </Tooltip>
      ) : (
        <></>
      )}
      {props.showName && (
        <span className={captionClass} style={props.captionStyle}>
          {status.name}
        </span>
      )}
    </div>
  );
};

export const StatusCategoryDisplay: React.FC<{
  categoryId: string;
  size: "large" | "medium" | "small";
}> = ({ categoryId, size }) => {
  const icon = getIconFromCategory(categoryId);

  return <>{icon ? <Icon component={icon} /> : <></>}</>;
};

export default StatusDisplay;
