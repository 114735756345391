import { WorkTypes } from "./types";

export const getNameFromWorkType = (
  workType: WorkTypes,
  capitalizeFirstLetter = true
) => {
  if (workType === WorkTypes.TASK)
    return capitalizeFirstLetter ? "Task" : "task";
  if (workType === WorkTypes.PROJECT)
    return capitalizeFirstLetter ? "Project" : "project";
  if (workType === WorkTypes.INITIATIVE)
    return capitalizeFirstLetter ? "Goal" : "goal";
  return "";
};
