import { Divider } from "antd";
import React from "react";
import styles from "./userSettingsSection/userSettingsSection.module.scss";

const UserSettingsSection: React.FC<{
  title: string;
  description?: string;
  id?: string;
}> = ({ title, description, children, id }) => {
  return (
    <div className={styles.section} id={id}>
      <h3>{title}</h3>
      {description && <span className="caption secondary">{description}</span>}
      <Divider className={styles.divider} />
      <div>{children}</div>
    </div>
  );
};

export default UserSettingsSection;
