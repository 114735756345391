import { InlineDiscussion } from "utilities/types";

interface IDiscussionDict {
  [id: string]: InlineDiscussion;
}

interface IDiscussionState {
  dict: IDiscussionDict;
}

const initialState: IDiscussionState = {
  dict: {},
};
export default function discussionReducer(state = initialState, action: any) {
  switch (action.type) {
    case "ADD_ENTITY_CONNECTIONS_OBJECT": {
      const newState = { ...state, dict: { ...state.dict } };
      action.comments.forEach(
        (discussion: InlineDiscussion) =>
          (newState.dict[discussion.id] = discussion)
      );
      return newState;
    }

    case "ADD_REPLY_TO_DISCUSSION": {
      const newState = { ...state, dict: { ...state.dict } };
      const discussionId = action.discussionId;
      const discussion = { ...newState.dict[discussionId] };
      const newReply = action.reply;
      let found = false;

      discussion.replies.forEach((existingReply) => {
        if (existingReply.id === newReply.id) found = true;
      });

      if (!found) {
        discussion.replies = [...discussion.replies];
        discussion.replies.push(newReply);
      }
      newState.dict[discussionId] = discussion;
      return newState;
    }

    case "ADD_DISCUSSION": {
      const newState = { ...state, dict: { ...state.dict } };
      const discussion = action.discussion;
      if (!discussion.replies) discussion.replies = [];
      newState.dict[discussion.id] = discussion;
      return newState;
    }

    case "UPDATE_DISCUSSION": {
      const newState = { ...state, dict: { ...state.dict } };
      const discussion = action.discussion;
      if (!discussion.replies) discussion.replies = [];
      newState.dict[discussion.id] = discussion;
      return newState;
    }

    case "UPDATE_REPLY_IN_DISCUSSION": {
      const newState = { ...state, dict: { ...state.dict } };
      const discussion = action.discussion;
      const stateDiscussion = { ...newState.dict[discussion.id] };
      stateDiscussion.replies = [...stateDiscussion.replies];
      const updatedReply = action.reply;
      stateDiscussion.replies.forEach((reply, index) => {
        if (reply.id === updatedReply.id)
          stateDiscussion.replies[index] = updatedReply;
      });
      newState.dict[discussion.id] = stateDiscussion;
      return newState;
    }

    case "REMOVE_REPLY_FROM_DISCUSSION": {
      const newState = { ...state, dict: { ...state.dict } };
      const discussion = action.discussion;
      const stateDiscussion = { ...newState.dict[discussion.id] };
      if (!stateDiscussion || !stateDiscussion.id) return state;
      stateDiscussion.replies = [...stateDiscussion.replies];
      const updatedReply = action.reply;
      let foundIndex = -1;
      stateDiscussion.replies.forEach((reply, index) => {
        if (reply.id === updatedReply.id) foundIndex = index;
      });
      if (foundIndex > -1) {
        stateDiscussion.replies.splice(foundIndex, 1);
      }
      newState.dict[discussion.id] = stateDiscussion;
      return newState;
    }

    case "REMOVE_DISCUSSION": {
      const newState = { ...state, dict: { ...state.dict } };
      const discussion = action.discussion;
      delete newState.dict[discussion.id];
      return newState;
    }
    default:
      return state;
  }
}
