import React, { useContext, useEffect, useState } from "react";
import styles from "../commandPalette.module.scss";
import { CommandPaletteState } from "../helpers/types";

const PaletteTopNav: React.FC = ({ children }) => {
  const paletteStateContext = useContext(CommandPaletteState);
  const [topbarText, setTopBarText] = useState("Select");
  const [optionName, setoptionName] = useState("");

  useEffect(() => {
    const topbarTextSub = paletteStateContext.topBarText.subscribe((text) =>
      setTopBarText(text)
    );
    const optionNameSub = paletteStateContext.selectedItemName.subscribe(
      (name) => setoptionName(name)
    );

    return () => {
      topbarTextSub.unsubscribe();
      optionNameSub.unsubscribe();
    };
  }, []);

  return (
    <div className={styles.topBar}>
      <span className={styles.topBar_first}>
        {topbarText} <span className="caption disabled">{optionName}</span>
      </span>
      {children}
    </div>
  );
};

export default PaletteTopNav;
