import Button, { ButtonTypes } from "components/Button";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  FilterOutlined,
  ControlOutlined,
  UnorderedListOutlined,
  ProjectOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { isEqual } from "lodash";
import store from "store/storeExporter";
import {
  RESET_VIEW,
  SET_FILTERS_MODAL,
  SET_GROUP_BY,
  SET_ORDER_BY,
  SET_VIEW_AS,
  UPDATE_CUSTOM_VIEW,
} from "store/actions";
import { Menu as FabMenu } from "components/FabBtn";
import styles from "./topnavBtns/topnavBtns.module.scss";
import {
  Abilities,
  CustomWorkView,
  GroupTypes,
  OrderTypes,
  TasksViewModes,
  ViewAsTypes,
} from "utilities/types";
import { Divider, Dropdown, Menu, Tooltip } from "antd";
import { useAbilityChecker, useGroupContext } from "editor/utils/customHooks";
import Conditional from "components/Conditional";
import Modal from "clarity-ui/Modal";
import ViewCreator from "./viewCreator/ViewCreator";
import { PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "index";
import {
  INewTaskCreationModes,
  openNewTaskModal,
} from "store/reducers/clientReducer";

const WorkViewTopnavBtns: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  return (
    <>
      <ViewSettings paneId={paneId} />
      <ViewFilters paneId={paneId} />
      <CreateTaskChecker paneId={paneId} />
    </>
  );
};

const ViewFilters: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  const viewFilters = useShallowSelector((store) => ({
    currentFilters: store.filter.currentDict[paneId]?.filters,
    presetFilters: store.filter.presetsDict[paneId]?.filters,
  }));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    if (isEqual(viewFilters.currentFilters, viewFilters.presetFilters)) {
      return setisActive(false);
    }
    return setisActive(true);
  }, [viewFilters.currentFilters, viewFilters.presetFilters]);

  const openFilters = () => {
    store.dispatch({
      type: SET_FILTERS_MODAL,
      isOpen: true,
      paneId,
    });
  };

  return (
    <Button
      buttonType={ButtonTypes.LINK}
      icon={<FilterOutlined />}
      onClick={openFilters}
      className={styles.iconSize}
    />
  );
};

const ViewSettings: React.FC<{ paneId: ChunkDestination }> = ({ paneId }) => {
  const viewFilters = useShallowSelector((store) => ({
    current: store.filter.currentDict[paneId],
    presets: store.filter.presetsDict[paneId],
  }));

  const [isActive, setisActive] = useState(false);
  const [openMenu, setopenMenu] = useState(false);
  const { canSave, hasChanges } = useCanSaveView(paneId);

  useEffect(() => {
    if (!viewFilters.presets || !viewFilters.current) return;
    if (
      viewFilters.current.groupBy !== viewFilters.presets.groupBy ||
      viewFilters.current.orderBy !== viewFilters.presets.orderBy ||
      viewFilters.current.viewAs !== viewFilters.presets.viewAs ||
      viewFilters.current.showActiveProjects !==
        viewFilters.presets.showActiveProjects
    ) {
      return setisActive(true);
    }
    return setisActive(false);
  }, [
    viewFilters.current?.groupBy,
    viewFilters.current?.orderBy,
    viewFilters.current?.viewAs,
    viewFilters.presets?.showActiveProjects,
    viewFilters.presets,
  ]);

  const toggleMenu = () => {
    setopenMenu(!openMenu);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.container} ref={containerRef}>
      {canSave && hasChanges && (
        <div style={{ position: "absolute", right: "4px", top: "4px" }}>
          <NotificationBadge />
        </div>
      )}
      <Button
        buttonType={ButtonTypes.LINK}
        icon={<ControlOutlined />}
        onClick={toggleMenu}
        className={isActive ? styles.customStyles : styles.iconSize}
      />

      {openMenu && (
        <FabMenu
          slim
          noFocus
          className={styles.menu}
          clickRef={containerRef}
          allowClickOn={["allowClick"]}
          close={toggleMenu}
          yDir="down"
          xDir="left"
        >
          <MenuContent
            paneId={paneId}
            viewAs={viewFilters.current?.viewAs}
            containerRef={containerRef}
            hasChanges={hasChanges}
            canSave={canSave}
          />
        </FabMenu>
      )}
    </div>
  );
};

const CreateTaskChecker: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const viewFilters = useShallowSelector((store) => ({
    config: store.filter.viewConfigDict[paneId],
  }));

  const groupContext = useGroupContext();
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupContext,
  });

  if (viewFilters.config.viewMode === TasksViewModes.Tasks)
    return (
      <Button
        buttonType={ButtonTypes.PRIMARY}
        icon={<PlusOutlined />}
        disabled={!canEditEntity}
        onClick={() => {
          openNewTaskModal({
            type: INewTaskCreationModes.new,
            presetData: {
              groupId: groupContext,
            },
          });
        }}
      >
        Task
      </Button>
    );
  return <></>;
};

const MenuContent: React.FC<{
  paneId: ChunkDestination;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  canSave?: boolean;
  hasChanges?: boolean;
  viewAs?: ViewAsTypes;
}> = ({ paneId, containerRef, canSave, hasChanges, viewAs }) => {
  return (
    <div className={styles.optionRowContainer}>
      <div className={styles.optionRow}>
        <span className="caption secondary">View as:</span>
        <div>
          <ViewAsSwitch paneId={paneId} />
        </div>
      </div>
      <Conditional on={viewAs !== ViewAsTypes.board}>
        <div className={styles.optionRow}>
          <span className="caption secondary">Group by:</span>
          <div>
            <GroupBySwitch paneId={paneId} containerRef={containerRef} />
          </div>
        </div>
      </Conditional>

      <div className={styles.optionRow}>
        <span className="caption secondary">Order by:</span>
        <div>
          <OrderBySwitch paneId={paneId} containerRef={containerRef} />
        </div>
      </div>
      {hasChanges && <ViewOptions paneId={paneId} canSave={canSave} />}
    </div>
  );
};

const ViewOptions: React.FC<{
  paneId: ChunkDestination;
  canSave?: boolean;
}> = ({ paneId, canSave }) => {
  const resetView = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    store.dispatch({
      type: RESET_VIEW,
      paneId,
    });
  };

  return (
    <div>
      <Divider style={{ margin: "4px 0" }} />
      <div className={styles.optionRow}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            paddingTop: "4px",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            style={{ width: "100%" }}
            buttonType={ButtonTypes.LINK}
            onClick={resetView}
          >
            Reset
          </Button>

          {canSave && <SaveOrUpdateView paneId={paneId} />}
        </div>
      </div>
    </div>
  );
};

const ViewAsSwitch: React.FC<{ paneId: string }> = ({ paneId }) => {
  const viewAs = useShallowSelector(
    (state) => state.filter.currentDict[paneId]?.viewAs
  );
  const changeViewAs = (viewAs: ViewAsTypes) => {
    store.dispatch({
      type: SET_VIEW_AS,
      paneId,
      viewAs,
    });
  };

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <Tooltip title="board">
        <Button
          buttonType={ButtonTypes.LINK}
          icon={<ProjectOutlined />}
          className={
            ViewAsTypes.board === viewAs ? styles.customStyles : styles.iconSize
          }
          onClick={() => changeViewAs(ViewAsTypes.board)}
        />
      </Tooltip>
      <Tooltip title="list">
        <Button
          buttonType={ButtonTypes.LINK}
          icon={<UnorderedListOutlined />}
          className={
            ViewAsTypes.list === viewAs ? styles.customStyles : styles.iconSize
          }
          onClick={() => changeViewAs(ViewAsTypes.list)}
        />
      </Tooltip>
    </div>
  );
};

const GroupBySwitch: React.FC<{
  paneId: string;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ paneId, containerRef }) => {
  const menu = useGroupByMenu(paneId);
  const groupBy = useShallowSelector(
    (state) => state.filter.currentDict[paneId]?.groupBy
  );

  if (!menu) return <></>;
  return (
    <Dropdown overlay={menu} trigger={["click"]} destroyPopupOnHide={true}>
      <span className="caption secondary bold" style={{ cursor: "pointer" }}>
        {getGroupName(groupBy, true)} <DownOutlined />
      </span>
    </Dropdown>
  );
};

const OrderBySwitch: React.FC<{
  paneId: string;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ paneId, containerRef }) => {
  const menu = useOrderByMenu(paneId);
  const orderBy = useShallowSelector(
    (state) => state.filter.currentDict[paneId]?.orderBy
  );

  if (!menu) return <></>;
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      getPopupContainer={() =>
        containerRef && containerRef.current
          ? containerRef.current
          : document.body
      }
    >
      <span className="caption secondary bold" style={{ cursor: "pointer" }}>
        {getSortName(orderBy)} <DownOutlined />
      </span>
    </Dropdown>
  );
};

const useGroupByMenu = (paneId: string) => {
  const [menu, setmenu] = useState<JSX.Element | null>(null);
  const groupContext = useShallowSelector(
    (state) => state.client.paneGroupContext[paneId]
  );
  const getMenuItem = ({
    key,
    contents,
    onClick,
    disable,
  }: {
    key: string;
    contents: () => JSX.Element;
    disable?: () => boolean;
    onClick: any;
  }) => {
    if (disable && disable())
      return <React.Fragment key={key}></React.Fragment>;
    return (
      <Menu.Item
        key={key}
        onClick={(e) => {
          onClick();
          e.domEvent.stopPropagation();
        }}
      >
        {contents()}
      </Menu.Item>
    );
  };

  const getMenuSubMenu = ({
    key,
    title,
    subitems,
  }: {
    key: string;
    title: string;
    subitems: JSX.Element[];
  }) => {
    return (
      <Menu.SubMenu className="allowClick" key={key} title={title}>
        {subitems.map((child) => child)}
      </Menu.SubMenu>
    );
  };

  const setgroupBy = (groupBy: GroupTypes) => {
    store.dispatch({
      type: SET_GROUP_BY,
      paneId,
      groupBy,
    });
  };

  useLayoutEffect(() => {
    const menuItems = [
      getMenuItem({
        key: GroupTypes.none,
        contents: () => <span>None</span>,
        onClick: () => setgroupBy(GroupTypes.none),
      }),
      getMenuItem({
        key: GroupTypes.assignee,
        contents: () => <span>Assignee</span>,
        onClick: () => setgroupBy(GroupTypes.assignee),
      }),
      getMenuItem({
        key: GroupTypes.cycle,
        contents: () => <span>Sprint</span>,
        onClick: () => setgroupBy(GroupTypes.cycle),
        disable: () =>
          groupContext && groupContext.groupId
            ? !store.getState().groups.dict[groupContext.groupId].showCycles
            : true,
      }),
      getMenuItem({
        key: GroupTypes.priority,
        contents: () => <span>Priority</span>,
        onClick: () => setgroupBy(GroupTypes.priority),
      }),
      getMenuItem({
        key: GroupTypes.project,
        contents: () => <span>Project</span>,
        onClick: () => setgroupBy(GroupTypes.project),
      }),
      getMenuItem({
        key: GroupTypes.status,
        contents: () => <span>Status</span>,
        onClick: () => setgroupBy(GroupTypes.status),
      }),
      getMenuSubMenu({
        key: "week",
        title: "Week",
        subitems: [
          getMenuItem({
            key: GroupTypes.weekClosed,
            contents: () => <span className="allowClick">Closed</span>,
            onClick: () => setgroupBy(GroupTypes.weekClosed),
          }),
          getMenuItem({
            key: GroupTypes.weekCreated,
            contents: () => <span className="allowClick">Created</span>,
            onClick: () => setgroupBy(GroupTypes.weekCreated),
          }),
          getMenuItem({
            key: GroupTypes.weekDue,
            contents: () => <span className="allowClick">Due</span>,
            onClick: () => setgroupBy(GroupTypes.weekDue),
          }),
        ],
      }),
    ];

    const menu = (
      <Menu className="allowClick">{menuItems.map((item) => item)}</Menu>
    );
    setmenu(menu);
  }, []);

  return menu;
};

const useOrderByMenu = (paneId: string) => {
  const [menu, setmenu] = useState<JSX.Element | null>(null);

  const getMenuItem = ({
    key,
    contents,
    onClick,
    disable,
  }: {
    key: string;
    contents: () => JSX.Element;
    disable?: () => boolean;
    onClick: any;
  }) => {
    if (disable && disable())
      return <React.Fragment key={key}></React.Fragment>;
    return (
      <Menu.Item
        key={key}
        onClick={(e) => {
          onClick();
          e.domEvent.stopPropagation();
        }}
      >
        {contents()}
      </Menu.Item>
    );
  };

  const setorderBy = (orderBy: OrderTypes) => {
    store.dispatch({
      type: SET_ORDER_BY,
      paneId,
      orderBy,
    });
  };

  useLayoutEffect(() => {
    const menuItems = Object.values(OrderTypes).map((group) =>
      getMenuItem({
        key: `orderBy-${group}`,
        contents: () => <span>{getSortName(group)}</span>,
        onClick: () => setorderBy && setorderBy(group),
      })
    );
    const menu = (
      <Menu className="allowClick">{menuItems.map((item) => item)}</Menu>
    );
    setmenu(menu);
  }, []);

  return menu;
};

const useCanSaveView = (paneId: ChunkDestination) => {
  const [canSave, setcanSave] = useState(false);
  const [hasChanges, sethasChanges] = useState(false);
  const groupContext = useGroupContext();

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupContext,
  });
  const viewData = useShallowSelector((store) => ({
    current: store.filter.currentDict[paneId],
    preset: store.filter.presetsDict[paneId],
    viewConfig: store.filter.viewConfigDict[paneId],
  }));

  useEffect(() => {
    if (!canEditEntity) setcanSave(false);
    else setcanSave(true);

    if (!viewData.current || !viewData.preset) return sethasChanges(false);
    if (viewData.viewConfig?.viewMode === TasksViewModes.NewCustomView)
      return sethasChanges(true);
    if (!isEqual(viewData.current, viewData.preset)) {
      return sethasChanges(true);
    }
    if (
      viewData.current.groupBy === viewData.preset.groupBy &&
      viewData.current.orderBy === viewData.preset.orderBy &&
      viewData.current.viewAs === viewData.preset.viewAs &&
      viewData.current.showActiveProjects === viewData.preset.showActiveProjects
    ) {
      sethasChanges(false);
      return;
    }
    sethasChanges(true);
  }, [viewData.current, viewData.preset, viewData.viewConfig]);

  return { canSave, hasChanges };
};

const getGroupName = (group: GroupTypes, includeNone: boolean) => {
  switch (group) {
    case GroupTypes.none: {
      if (includeNone) return " None";
      return "";
    }
    case GroupTypes.priority:
      return " Priority";
    case GroupTypes.assignee:
      return " Assignee";
    case GroupTypes.cycle:
      return " Sprint";
    case GroupTypes.project:
      return " Project";
    case GroupTypes.status:
      return " Status";
    case GroupTypes.weekClosed:
      return " Week Closed";
    case GroupTypes.weekCreated:
      return " Week Created";
    case GroupTypes.weekDue:
      return " Week Due";
  }
};

const getSortName = (sort: OrderTypes) => {
  switch (sort) {
    case OrderTypes.createDate:
      return " Last created";
    case OrderTypes.closeDate:
      return " Last closed";
    case OrderTypes.dueDate:
      return " Due date";
    case OrderTypes.priority:
      return " Priority";
    case OrderTypes.status:
      return " Status";
  }
};

const NotificationBadge: React.FC = () => {
  return (
    <div
      style={{
        height: "7px",
        width: "7px",
        borderRadius: "50%",
        background: "#d14747",
      }}
    ></div>
  );
};

const SaveOrUpdateView: React.FC<{ paneId: ChunkDestination }> = ({
  paneId,
}) => {
  const viewType = useShallowSelector(
    (store) => store.filter.viewConfigDict[paneId]
  );

  const customView = useShallowSelector(
    (store) => store.customWork.dict[viewType.id]
  );
  const groupContext = useGroupContext();

  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember: groupContext,
  });

  const updateCustomView = () => {
    if (!canEditEntity) return;
    if (customView) {
      const currentState = store.getState().filter.currentDict[paneId];
      axiosInstance
        .patch(`/api/customWorkView`, {
          id: customView.id,
          changes: {
            filters: currentState.filters,
            orderBy: currentState.orderBy,
            groupBy: currentState.groupBy,
            viewAs: currentState.viewAs,
          },
        })
        .catch((err) => {
          console.log(err);
        });
      store.dispatch({
        type: UPDATE_CUSTOM_VIEW,
        param: {
          type: "update",
          delta: {
            filters: currentState.filters,
            orderBy: currentState.orderBy,
            groupBy: currentState.groupBy,
            viewAs: currentState.viewAs,
            updatedAt: new Date(),
          } as Partial<CustomWorkView>,
          id: customView.id,
        },
      });
    }
  };

  const [isAdding, setisAdding] = useState(false);

  if (viewType.viewMode === TasksViewModes.NewCustomView) {
    return (
      <>
        <Button
          style={{ width: "100%" }}
          buttonType={ButtonTypes.PRIMARY}
          disabled={!canEditEntity}
          onClick={() => (canEditEntity ? setisAdding(true) : null)}
        >
          Save view...
        </Button>
        {isAdding && (
          <Modal hideModal={() => setisAdding(false)}>
            <ViewCreator paneId={paneId} onCancel={() => setisAdding(false)} />
          </Modal>
        )}
      </>
    );
  }

  return (
    <>
      <Button
        style={{ width: "100%" }}
        buttonType={ButtonTypes.PRIMARY}
        disabled={!canEditEntity}
        onClick={() =>
          canEditEntity
            ? viewType.customView
              ? updateCustomView()
              : setisAdding(true)
            : null
        }
      >
        {viewType.customView ? "Update view" : "Save..."}
      </Button>
      {isAdding && (
        <Modal hideModal={() => setisAdding(false)}>
          <ViewCreator paneId={paneId} onCancel={() => setisAdding(false)} />
        </Modal>
      )}
    </>
  );
};

export default WorkViewTopnavBtns;
