import roleApi from "clientApi/rolesApi";
import { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import { useCallback, useEffect } from "react";
import { SET_TOP_NAV_BTN_COMPONENTS } from "store/actions";
import {
  INewTaskCreationModes,
  openNewTaskModal,
} from "store/reducers/clientReducer";
import { getActionTrigger, ICON } from "store/reducers/topNavReducer";
import store from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import {
  Abilities,
  GroupTypes,
  IFilterState,
  IProjectObj,
  TasksViewModes,
} from "utilities/types";
import { IWorkViewConfig } from "./types";
import { IWorkViewStoreContext } from "./WorkView";

export const useSelectActions = (itemsStore: IWorkViewStoreContext) => {
  const setselectedObj = useCallback(
    (nextSelectedObj: { [id: string]: boolean }) => {
      itemsStore.selectedDict.next(nextSelectedObj);
    },
    []
  );

  const selectWork = useCallback((data: { taskId: string; index: number }) => {
    const selectedDict = itemsStore.selectedDict.value;
    const selectedItems = [...itemsStore.selectedItems.value];
    const obj = { ...selectedDict };
    if (!obj[data.taskId]) {
      selectedItems.push(data.taskId);
      obj[data.taskId] = true;
    } else {
      const spliceIndex = selectedItems.indexOf(data.taskId);
      selectedItems.splice(spliceIndex, 1);
      delete obj[data.taskId];
    }

    itemsStore.lastSelected.next({
      id: data.taskId,
      index: data.index,
    });
    itemsStore.selectedItems.next(selectedItems);
    setselectedObj(obj);
  }, []);

  const selectRange = useCallback((end: { index: number; id: string }) => {
    const lastSelected = itemsStore.lastSelected.value;
    if (lastSelected) {
      if (end.index === lastSelected.index) return;
      if (end.index > lastSelected.index) {
        selectRangeHandle(lastSelected.id, end.id);
      } else {
        selectRangeHandle(end.id, lastSelected.id);
      }
    }
  }, []);

  const selectRangeHandle = (startId: string, endId: string) => {
    const selectedDict = itemsStore.selectedDict.value;
    const obj = { ...selectedDict };
    const selectedTasks: string[] = [...itemsStore.selectedItems.value];
    const allItems = itemsStore.filteredIds.value;
    let index = 0;
    let startIndex = 0;
    let endIndex = 0;

    for (const item of allItems) {
      if (item === startId) startIndex = index;
      if (item === endId) endIndex = index;
      index++;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      const id = allItems[i];
      if (!obj[id]) {
        obj[id] = true;
        selectedTasks.push(id);
      }
    }

    itemsStore.selectedItems.next(selectedTasks);
    setselectedObj(obj);
  };

  const selectAll = useCallback(() => {
    const obj: any = {};
    const selectedTasks: string[] = [];
    const allItems = itemsStore.filteredIds.value;

    allItems.forEach((workId) => {
      obj[workId] = true;
      selectedTasks.push(workId);
    });
    itemsStore.selectedItems.next(selectedTasks);
    setselectedObj(obj);
  }, []);

  const deselectAll = useCallback(() => {
    const obj: any = {};
    const selectedTasks: string[] = [];
    itemsStore.selectedItems.next(selectedTasks);
    setselectedObj(obj);
  }, []);

  return { selectWork, selectRange, selectAll, deselectAll };
};

export const getFilterString = (filterObj: Partial<IFilterState>) => {
  let filterString = "";
  const iterateThroughObject = (object: any) => {
    Object.entries(object).forEach(([key, value]) => {
      if (typeof value === "object" && value) {
        if (value instanceof Array) {
          if (value.length > 0)
            filterString = filterString + key + JSON.stringify(value);
        } else iterateThroughObject(value);
      } else if (value !== undefined)
        filterString = filterString + key + JSON.stringify(value);
    });
  };
  iterateThroughObject(filterObj);
  return filterString;
};

export const getPresetTaskMetadata = (
  itemId: string,
  groupBy: GroupTypes
): Partial<IProjectObj & { workSection: any }> => {
  const statuses = store.getState().work.statuses;
  if (groupBy === GroupTypes.status)
    return {
      statusId: itemId,
    };
  if (groupBy === GroupTypes.assignee)
    return {
      assigneeId: itemId,
    };
  if (groupBy === GroupTypes.cycle) {
    const workSection = store.getState().work.sections[itemId];
    if (workSection && workSection.id) {
      return {
        statusId: statuses.statusArray[1],
        workSectionId: workSection.id,
      };
    } else {
      return {
        statusId: statuses.statusArray[1],
      };
    }
  }

  if (groupBy === GroupTypes.priority)
    return {
      priority: Number(itemId),
    };
  if (groupBy === GroupTypes.project)
    return {
      parentId: itemId,
    };
  return {};
};

export const useWorkBtnsSetter = (
  paneId: ChunkDestination,
  viewConfig: IWorkViewConfig | null
) => {
  const showPayouts = useShallowSelector(
    (store) => store.workspace.showContributions
  );
  const groupContext = useShallowSelector(
    (store) => store.client.paneGroupContext[paneId]?.groupId
  );

  useEffect(() => {
    if (!viewConfig) return;
    if (viewConfig.id.includes(TasksViewModes.ProjectWork)) return;
    if (
      [TasksViewModes.OpenCycles, TasksViewModes.ClosedCycles].includes(
        viewConfig.viewMode
      )
    )
      return;

    let btnComponents = [];
    if (viewConfig.viewMode === TasksViewModes.Tasks) {
      const showContributions = store.getState().workspace.showContributions;

      if (!showContributions)
        btnComponents.push(
          getActionTrigger({
            key: "task",
            onClick: (e: any) => {
              openNewTaskModal({
                type: INewTaskCreationModes.new,
                presetData: {
                  groupId: groupContext,
                },
                disableSelectGroup: true,
              });
            },
            iconRef: ICON.PLUS,
            contents: () => "Task",
            disabled: !roleApi.checkAbility({
              abilityName: Abilities.CAN_EDIT_ENTITY,
            }),
            buttonType: ButtonTypes.PRIMARY,
          })
        );
      else {
        btnComponents.push(
          getActionTrigger({
            key: "task",
            onClick: (e: any) => {
              openNewTaskModal({
                type: INewTaskCreationModes.new,
              });
            },
            iconRef: ICON.PLUS,
            contents: () => "Task",
            disabled: !roleApi.checkAbility({
              abilityName: Abilities.CAN_EDIT_ENTITY,
            }),
            buttonType: ButtonTypes.PRIMARY,
          })
        );
      }
    }

    btnComponents = [
      ...btnComponents,
      ...(viewConfig?.extraBtns ? viewConfig.extraBtns() : []),
    ];

    store.dispatch({
      type: SET_TOP_NAV_BTN_COMPONENTS,
      paneId,
      btnComponents,
    });
    return () => {
      store.dispatch({
        type: SET_TOP_NAV_BTN_COMPONENTS,
        paneId,
        btnComponents: [],
      });
    };
  }, [showPayouts, viewConfig, groupContext]);
};

export const useAllCyclesBtnsSetter = (
  paneId: ChunkDestination,
  viewConfig: IWorkViewConfig | null,
  action: any,
  archive: boolean
) => {
  const canManageCycles = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_CYCLES,
  });

  useEffect(() => {
    if (!viewConfig) return;
    if (viewConfig.id.includes(TasksViewModes.ProjectWork)) return;
    store.dispatch({
      type: SET_TOP_NAV_BTN_COMPONENTS,
      paneId,
      btnComponents: [
        ...(viewConfig?.extraBtns ? viewConfig.extraBtns() : []),
        getActionTrigger({
          key: "cycleEdit",
          buttonType: ButtonTypes.DEFAULT,
          disabled: !canManageCycles,
          onClick: () => action(true),
          contents: () => "Edit sprints",
        }),
      ],
    });
    return () => {
      store.dispatch({
        type: SET_TOP_NAV_BTN_COMPONENTS,
        paneId,
        btnComponents: [],
      });
    };
  }, [viewConfig, canManageCycles]);
};
