import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./userMentionMenu.module.scss";
import { resetBlockMenu } from "editor/utils/blockMenusActions/menuActions";
import { Avatar, Menu } from "antd";
import { MenuProps } from "../BlockMenu";
import { batch } from "react-redux";
import { removePlaceholderPlaceCaret } from "editor/utils/specificActions/textUpdateUtils";
import { useOnClickOutside } from "editor/utils/customHooks";
import { $focusOn, prevState } from "store/storeExporter";
import {
  clearTextSaveTimeout,
  updateImmediateText,
} from "editor/utils/blockActions";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { checkCaretPosition } from "editor/utils/caretUtils";

const endSpace = /\s$/;

const UserMentionsMenu: React.FC<MenuProps> = (props) => {
  const prevPosition = useRef({
    left: `0px`,
    top: `0px`,
  });

  const optionsToFilterRef = useRef<any[]>([]);
  const [optionsToDisplay, setoptionsToDisplay] = useState<any[]>([]);

  useLayoutEffect(() => {
    const storeData = prevState.value;
    const membersAndGuestList = [...storeData.members.ids];
    const memberDict = storeData.members.dict;
    const membersArray = membersAndGuestList.map((id) => memberDict[id]);
    optionsToFilterRef.current = [...membersArray];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuRef = useRef(null);

  const closeMenu = useCallback((event) => {
    if (event) {
      resetBlockMenu(props.menuStateValue);
    }
  }, []);

  useOnClickOutside(menuRef, closeMenu);

  let hoveredOption = props.menuState.hoveredItem;
  const text = props.menuState.filterMenuBy;

  const activateCheckedOption = (index: number) => {
    batch(() => {
      clearTextSaveTimeout();
      if (optionsToDisplay[index] && !optionsToDisplay[index].root) {
        const option = optionsToDisplay[index];
        removePlaceholderPlaceCaret(props.blockRef.current);
        const newContainer = document.createDocumentFragment();
        const newElement = document.createElement("usermention");
        newElement.setAttribute("user-id", option.id);
        const newSpace = document.createTextNode(" ");
        newContainer.appendChild(newElement);
        newContainer.appendChild(newSpace);
        newElement.contentEditable = "false";

        newElement.innerHTML = option.name
          ? "@" + option.name
          : "@" + option.username;
        const selection = document.getSelection();
        if (selection) {
          if (selection.rangeCount === 0)
            moveCaretToPreviousPosition(
              props.blockRef.current,
              $focusOn.value.caretEndPosition
                ? $focusOn.value.caretEndPosition
                : 0
            );

          const range = selection.getRangeAt(0);
          const newRange = range.cloneRange();
          newRange.insertNode(newContainer);
          newRange.setStartAfter(newSpace);
          newRange.setEndAfter(newSpace);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
        const caretPosition = checkCaretPosition(props.blockRef.current);
        $focusOn.next({ ...$focusOn.value, caretPosition });
      }
      updateImmediateText(props.blockData, props.blockRef, props.context);
      resetBlockMenu(props.menuStateValue);
    });
  };

  const firstEl = [
    {
      name: 'No results for "' + props.menuState.filterMenuBy + '"',
      id: "idString",
      root: true,
    },
  ];

  useLayoutEffect(() => {
    const checkFilter = (filterableName: any) => {
      if (!filterableName) return false;
      return filterableName
        .toLowerCase()
        .trim()
        .includes(props.menuState.filterMenuBy.toLowerCase().trim());
    };

    if (props.menuState.isOpened) {
      let optionsToDisplayTrigger = [];
      if (props.menuState.filterMenuBy !== "") {
        optionsToDisplayTrigger = optionsToFilterRef.current.filter((el) => {
          return checkFilter(el.name) || checkFilter(el.username);
        });

        if (optionsToDisplayTrigger.length === 0)
          optionsToDisplayTrigger = [...firstEl];
      } else {
        const storeData = prevState.value;
        const membersAndGuestList = [...storeData.members.ids];
        const memberDict = storeData.members.dict;
        const membersArray = membersAndGuestList.map((id) => memberDict[id]);
        optionsToDisplayTrigger = [...membersArray];
      }

      setoptionsToDisplay(optionsToDisplayTrigger);
    }
  }, [props.menuState.filterMenuBy, props.menuState.isOpened]);

  useEffect(() => {
    if (!props.menuState.isOpened) {
      return;
    }
    if (
      optionsToDisplay.length > 0 &&
      props.menuState.hoveredItem >= optionsToDisplay.length
    ) {
      hoveredOption = optionsToDisplay.length - 1;
      if (hoveredOption < 0) hoveredOption = 0;
      const menuStateCopy = { ...props.menuState };
      menuStateCopy.hoveredItem = hoveredOption;
      props.menuStateValue.current.next(menuStateCopy);
    }
  }, [props]);

  useEffect(() => {
    if (!props.menuState.isOpened) {
      return;
    }
    if (endSpace.test(text) && optionsToDisplay.length === 0) {
      resetBlockMenu(props.menuStateValue);
    }
  }, [props.menuState.filterMenuBy]);

  useEffect(() => {
    if (!props.menuState.isOpened) return;
    const el = document.getElementById(
      "menuItem" + props.menuState.hoveredItem
    );
    el?.scrollIntoView({ block: "nearest" });
  }, [props.menuState.hoveredItem]);

  useEffect(() => {
    if (!props.menuState.isOpened) return;
    if (props.menuState.executeSelection === true) {
      resetBlockMenu(props.menuStateValue);
      activateCheckedOption(props.menuState.hoveredItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuState.executeSelection]);

  const selection = document.getSelection();
  let x = 0;
  let y = 0;
  let styleObject: any = {
    left: `${x}px`,
    top: `${y}px`,
  };

  if (selection && selection.rangeCount > 0) {
    const range: Range = selection.getRangeAt(0);
    const boundingRect = range.getBoundingClientRect();
    let rangeX = boundingRect.x;
    let rangeY = boundingRect.bottom;
    if (true) {
      const placeholder = props.blockRef.current.querySelectorAll(
        ".user-mention-placeholder"
      );

      if (placeholder[0]) {
        const placeholderSizes = placeholder[0].getBoundingClientRect();
        rangeX = placeholderSizes.x + placeholderSizes.width;
        rangeY = placeholderSizes.bottom;
        // const closestEl = props.blockRef.current.closest(".section-big");
        const closestEl = props.blockRef.current.closest(".flex-section");
        let componentX = closestEl ? closestEl.getBoundingClientRect().x : 0;
        let componentY = props.blockRef.current.getBoundingClientRect().y;
        const computed = window
          .getComputedStyle(props.blockRef.current)
          .getPropertyValue("margin-top");
        const marginString = computed.substring(0, computed.length - 2);
        const margin = Number(marginString);

        x = rangeX === 0 ? 10 : rangeX - componentX;
        y = rangeY === 0 ? 10 : rangeY + margin - componentY;

        if (componentX + x + 350 > window.outerWidth) {
          styleObject = {
            right: `${-x}px`,
            top: `${y}px`,
          };
        } else {
          styleObject = {
            left: `${x}px`,
            top: `${y}px`,
          };
        }
        prevPosition.current = styleObject;
      } else {
        styleObject = prevPosition.current;
      }
    }
  }

  return (
    <>
      <div
        className={styles.slashCommandContainer}
        style={styleObject}
        ref={menuRef}
      >
        <Menu className={styles.atMentionsMenu} selectable>
          {optionsToDisplay.map((option, index) => (
            <Menu.Item
              key={index}
              id={"menuItem" + index}
              disabled={option.id === "idString"}
              role="button"
              style={{
                padding: "0 4px 0 7px",
              }}
              onClick={() => {
                if (option.id === "idString") {
                  return;
                }
                activateCheckedOption(index);
              }}
              className={
                hoveredOption === index
                  ? `${styles.menuItem} ${styles.nice}`
                  : styles.menuItem
              }
            >
              {option.root ? (
                <></>
              ) : (
                <>
                  <Avatar src={option.avatar} shape="square" size={21} />
                </>
              )}
              <span className={styles.labelContainer}>
                {option.name || "@" + option.username}
              </span>
              {option.name &&
                option.username &&
                option.name !== option.username && (
                  <span className={styles.suffixContainer}>
                    {`@${option.username}`}
                  </span>
                )}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default UserMentionsMenu;
