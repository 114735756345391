import { axiosInstance } from "index";
import { batch } from "react-redux";
import {
  ADD_BLOCK,
  DELETE_BLOCK,
  FIX_RANK_ORDER,
  REFOCUS,
  SAVE_BLOCK_DATA,
  SET_UNDO,
} from "store/actions";
import { Block, FocusObject } from "store/reducers/blockReducer";
import {
  getBlockById,
  getBlockIndex,
  getCurrentContext,
  getNextSibling,
  getPreviousBlock,
  getPreviousSibling,
  getSortedSelectedBlocks,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $focusOn, prevState } from "store/storeExporter";
import { getRankBetween } from "utilities/containerRankHelpers";
import { LineType } from "utilities/lineUtilities";
import { ContainerTypes, IBlockContext, IProjectObj } from "utilities/types";
import { BlockActionTypes, getAntiAction } from "../blockActions";
import {
  getAntiDelta,
  handleUpdateAction,
} from "../primitiveActions/primitiveActions";
import { ADD_BLOCK_TYPES } from "./addBlockActions";
import { setUpdateObjectToStore } from "./persistActions";
import {
  handleLocalEntityConnect,
  handleLocalEntityDisconnect,
} from "./textUpdateActions";
import {
  ActionObject,
  ActionWrapperObject,
  createActionWrapperObject,
} from "./undoUtils";

export const moveBlocks = (moveProps: {
  type: "after" | "before";
  options: "sibling" | "child";
  referenceId: string;
  selectedBlocks: string[];
  childrenInSelection?: boolean;
  undoObject?: ActionWrapperObject;
  saveActions?: ActionObject[];
  fromPalette?: boolean;
}) => {
  const newState = store.getState().blocks;
  const referenceBlock = getBlockById(newState.dict, moveProps.referenceId);
  const endContext = getCurrentContext(
    newState,
    referenceBlock.containerType + referenceBlock.containerId
  );

  if (moveProps.type === "after") {
    if (moveProps.options === "sibling") {
      const nextBlockForRefId = getNextSibling(
        newState,
        referenceBlock,
        endContext
      );
      const nextBlockForRef = getBlockById(newState.dict, nextBlockForRefId);
      let prevRank: string | undefined = referenceBlock.documentRank
        ? referenceBlock.documentRank
        : undefined;
      let nextRank: string | undefined = nextBlockForRef
        ? nextBlockForRef.documentRank
        : undefined;
      let referencePoint = referenceBlock.id;

      const orderedBlocks = getSortedSelectedBlocks(moveProps.selectedBlocks);

      orderedBlocks.forEach((selectedBlockId) => {
        const selectedBlock = getBlockById(newState.dict, selectedBlockId);
        let delta: Partial<Block> = {};
        if (selectedBlock.parentId !== referenceBlock.parentId) {
          delta.parentId = referenceBlock.parentId;
          delta.indentLevel = referenceBlock.indentLevel;
        }

        if (selectedBlock.containerId !== referenceBlock.containerId) {
          delta.containerId = referenceBlock.containerId;
          delta.containerType = referenceBlock.containerType;
        }

        const newRank = getRankBetween(prevRank, nextRank);
        delta.documentRank = newRank;

        if (moveProps.saveActions) {
          const action: ActionObject = {
            id: selectedBlock.id,
            type: BlockActionTypes.update,
            delta: {
              ...delta,
              ...{
                dateModified: new Date(),
                modifiedBy: prevState.value.user?.id,
              },
            },
          };
          moveProps.saveActions.push(action);
        }

        if (moveProps.undoObject) {
          const action: ActionObject = {
            delta: getAntiDelta(delta, selectedBlock),
            type: BlockActionTypes.update,
            id: selectedBlock.id,
          };
          moveProps.undoObject.actions.push(action);
        }

        if (delta.parentId || delta.parentId === "" || delta.containerId) {
          if (delta.containerId)
            handleLocalEntityDisconnect(selectedBlock, { immediate: true });
          store.dispatch({
            type: DELETE_BLOCK,
            param: {
              id: selectedBlock.id,
            },
          });

          const endselectedBlock = {
            ...selectedBlock,
            ...selectedBlock.blockSubject.value,
            ...delta,
          };

          store.dispatch({
            type: ADD_BLOCK,
            param: {
              type: ADD_BLOCK_TYPES.addBlockAfter,
              referencePoint: referencePoint,
              block: endselectedBlock,
              context: endContext,
            },
          });

          if (!moveProps.childrenInSelection) {
            updateChildrenTree({
              blockId: selectedBlock.id,
              delta,
              saveActions: moveProps.saveActions,
              undoObject: moveProps.undoObject,
            });
          }
          if (delta.containerId)
            handleLocalEntityConnect(selectedBlock, { immediate: true });
        } else {
          const endselectedBlock = {
            ...selectedBlock,
            ...selectedBlock.blockSubject.value,
            ...delta,
          };
          store.dispatch({
            type: SAVE_BLOCK_DATA,
            param: {
              id: endselectedBlock.id,
              blockData: endselectedBlock,
              delta,
            },
          });
        }
        prevRank = newRank;
        referencePoint = selectedBlock.id;
      });
      const parentBlock = getBlockById(newState.dict, referenceBlock.parentId);
      store.dispatch({
        type: FIX_RANK_ORDER,
        param: {
          indentLevel: referenceBlock.indentLevel,
          context: endContext,
          referenceBlock: parentBlock,
        },
      });
    }
    if (moveProps.options === "child") {
      const nextBlockForRefId = referenceBlock.children[0];
      const nextBlockForRef = getBlockById(newState.dict, nextBlockForRefId);
      let prevRank: string | undefined = undefined;
      let nextRank: string | undefined = nextBlockForRef?.documentRank
        ? nextBlockForRef.documentRank
        : undefined;
      let referencePoint = referenceBlock.id;

      if (referenceBlock.children.length === 0) {
        const action: ActionObject = {
          id: referenceBlock.id,
          type: BlockActionTypes.update,
          delta: {
            subLinesList: LineType.bulletedList,
          },
        };
        handleUpdateAction(action, true);
        moveProps.saveActions && moveProps.saveActions.push(action);
      }

      const sortedBlocks = getSortedSelectedBlocks(moveProps.selectedBlocks);
      sortedBlocks.forEach((selectedBlockId, index) => {
        const selectedBlock = getBlockById(newState.dict, selectedBlockId);
        let delta: Partial<Block> = {};
        if (selectedBlock.parentId !== referenceBlock.id) {
          delta.parentId = referenceBlock.id;
          delta.indentLevel = referenceBlock.indentLevel + 1;
        }

        if (selectedBlock.containerId !== referenceBlock.containerId) {
          delta.containerId = referenceBlock.containerId;
          delta.containerType = referenceBlock.containerType;
        }

        const newRank = getRankBetween(prevRank, nextRank);
        delta.documentRank = newRank;

        if (moveProps.saveActions) {
          const action: ActionObject = {
            id: selectedBlock.id,
            type: BlockActionTypes.update,
            delta: {
              ...delta,
              ...{
                dateModified: new Date(),
                modifiedBy: prevState.value.user?.id,
              },
            },
          };
          moveProps.saveActions.push(action);
        }

        if (moveProps.undoObject) {
          const action: ActionObject = {
            delta: getAntiDelta(delta, selectedBlock),
            type: BlockActionTypes.update,
            id: selectedBlock.id,
          };
          moveProps.undoObject.actions.push(action);
        }

        if (delta.parentId || delta.parentId === "" || delta.containerId) {
          if (delta.containerId)
            handleLocalEntityDisconnect(selectedBlock, { immediate: true });
          store.dispatch({
            type: DELETE_BLOCK,
            param: {
              id: selectedBlock.id,
            },
          });

          const endselectedBlock = {
            ...selectedBlock,
            ...selectedBlock.blockSubject.value,
            ...delta,
          };

          store.dispatch({
            type: ADD_BLOCK,
            param: {
              type:
                index === 0
                  ? ADD_BLOCK_TYPES.addChildBlock
                  : ADD_BLOCK_TYPES.addBlockAfter,
              referencePoint: referencePoint,
              block: endselectedBlock,
              context: endContext,
            },
          });
          if (!moveProps.childrenInSelection) {
            updateChildrenTree({
              blockId: selectedBlock.id,
              delta,
              saveActions: moveProps.saveActions,
              undoObject: moveProps.undoObject,
            });
          }
          if (delta.containerId)
            handleLocalEntityConnect(selectedBlock, { immediate: true });
        } else {
          const endselectedBlock = {
            ...selectedBlock,
            ...selectedBlock.blockSubject.value,
            ...delta,
          };
          store.dispatch({
            type: SAVE_BLOCK_DATA,
            param: {
              id: endselectedBlock.id,
              blockData: endselectedBlock,
              delta,
            },
          });
        }
        prevRank = newRank;
        referencePoint = selectedBlock.id;
      });

      store.dispatch({
        type: FIX_RANK_ORDER,
        param: {
          indentLevel: referenceBlock.indentLevel + 1,
          context: endContext,
          referenceBlock,
        },
      });
    }
  }
  if (moveProps.type === "before") {
    if (moveProps.options === "sibling") {
      const prevBlockForRefId = getPreviousSibling(
        newState,
        referenceBlock,
        endContext
      );
      const prevBlockForRef = getBlockById(newState.dict, prevBlockForRefId);
      let prevRank: string | undefined =
        prevBlockForRef && prevBlockForRef.documentRank !== "0"
          ? prevBlockForRef.documentRank
          : undefined;
      let nextRank: string | undefined = referenceBlock.documentRank;
      let referencePoint = referenceBlock.id;

      const sortedBlocks = getSortedSelectedBlocks(moveProps.selectedBlocks);
      sortedBlocks.forEach((selectedBlockId, index) => {
        const selectedBlock = getBlockById(newState.dict, selectedBlockId);
        let delta: Partial<Block> = {};
        if (selectedBlock.parentId !== referenceBlock.parentId) {
          delta.parentId = referenceBlock.parentId;
          delta.indentLevel = referenceBlock.indentLevel;
        }

        if (selectedBlock.containerId !== referenceBlock.containerId) {
          delta.containerId = referenceBlock.containerId;
          delta.containerType = referenceBlock.containerType;
        }

        const newRank = getRankBetween(prevRank, nextRank);
        delta.documentRank = newRank;

        if (moveProps.saveActions) {
          const action: ActionObject = {
            id: selectedBlock.id,
            type: BlockActionTypes.update,
            delta: {
              ...delta,
              ...{
                dateModified: new Date(),
                modifiedBy: prevState.value.user?.id,
              },
            },
          };
          moveProps.saveActions.push(action);
        }

        if (moveProps.undoObject) {
          const action: ActionObject = {
            delta: getAntiDelta(delta, selectedBlock),
            type: BlockActionTypes.update,
            id: selectedBlock.id,
          };
          moveProps.undoObject.actions.push(action);
        }

        if (delta.parentId || delta.parentId === "" || delta.containerId) {
          if (delta.containerId)
            handleLocalEntityDisconnect(selectedBlock, { immediate: true });
          store.dispatch({
            type: DELETE_BLOCK,
            param: {
              id: selectedBlock.id,
            },
          });

          const endselectedBlock = {
            ...selectedBlock,
            ...selectedBlock.blockSubject.value,
            ...delta,
          };

          store.dispatch({
            type: ADD_BLOCK,
            param: {
              type:
                index === 0
                  ? ADD_BLOCK_TYPES.addBlockBefore
                  : ADD_BLOCK_TYPES.addBlockAfter,
              referencePoint: referencePoint,
              block: endselectedBlock,
              context: endContext,
            },
          });
          if (!moveProps.childrenInSelection) {
            updateChildrenTree({
              blockId: selectedBlock.id,
              delta,
              saveActions: moveProps.saveActions,
              undoObject: moveProps.undoObject,
            });
          }
          if (delta.containerId)
            handleLocalEntityConnect(selectedBlock, { immediate: true });
        } else {
          const endselectedBlock = {
            ...selectedBlock,
            ...selectedBlock.blockSubject.value,
            ...delta,
          };
          store.dispatch({
            type: SAVE_BLOCK_DATA,
            param: {
              id: endselectedBlock.id,
              blockData: endselectedBlock,
              delta,
            },
          });
        }
        prevRank = newRank;
        nextRank = referenceBlock.documentRank;
        referencePoint = selectedBlock.id;
      });
      const parentBlock = getBlockById(newState.dict, referenceBlock.parentId);
      store.dispatch({
        type: FIX_RANK_ORDER,
        param: {
          indentLevel: referenceBlock.indentLevel,
          context: endContext,
          referenceBlock: parentBlock,
        },
      });
    }
  }
};

export const startBlockMove = (moveProps: {
  type: "after" | "before";
  options: "sibling" | "child";
  referenceId: string;
  movedBlockId: string;
  context: IBlockContext;
  fromPalette?: boolean;
}) => {
  const newState = store.getState().blocks;
  let saveFullBlock = false;
  const selectedBlocks = getSortedSelectedBlocks(newState.selectedBlocks);
  let movedBlockId = moveProps.movedBlockId;
  const block = getBlockById(newState.dict, movedBlockId);
  const context = getCurrentContext(
    newState,
    block.containerType + block.containerId
  );

  if (context.containerId === "newComment") saveFullBlock = true;

  if (selectedBlocks.length > 0) {
    movedBlockId = selectedBlocks[0];
  } else {
    selectedBlocks.push(movedBlockId);
  }

  const undoObject: ActionWrapperObject = {
    actions: [],
    context: context,
    focusOn: { ...$focusOn.value },
    selectedBlocks: [...newState.selectedBlocks],
    documentMode: newState.documentMode,
  };

  const saveActions: ActionObject[] = [];

  batch(() => {
    moveBlocks({
      type: moveProps.type,
      options: moveProps.options,
      referenceId: moveProps.referenceId,
      selectedBlocks,
      undoObject,
      saveActions,
    });
  });

  const newValue: FocusObject = {
    ...$focusOn.value,
    focusBlockId: movedBlockId,
    focusContext: moveProps.context,
  };

  store.dispatch({
    type: REFOCUS,
    param: {
      newFocus: newValue,
    },
  });

  store.dispatch({
    type: SET_UNDO,
    param: {
      undoObject,
      contextId: context.id,
    },
  });

  // store.dispatch(batchActions(reduxActions));

  setUpdateObjectToStore(saveActions, moveProps.context, { saveFullBlock });
};

export const moveToContainerEnd = async (moveProps: {
  blockId: string;
  context: any;
  targetContainer: {
    containerId: string;
    containerType: ContainerTypes;
  };
  movingBlockIds?: string[];
}) => {
  let newFocusBlock = null;
  const newState = store.getState().blocks;
  const currentBlock = getBlockById(newState.dict, moveProps.blockId);
  const currentContext = getCurrentContext(newState, moveProps.context.id);
  const endContextId =
    moveProps.targetContainer.containerType +
    moveProps.targetContainer.containerId;
  const endContext = getCurrentContext(newState, endContextId);

  const selectedBlocks = moveProps.movingBlockIds
    ? getSortedSelectedBlocks(moveProps.movingBlockIds)
    : newState.selectedBlocks.length > 0
    ? getSortedSelectedBlocks(newState.selectedBlocks)
    : [currentBlock.id];

  const firstBlock = getBlockById(newState.dict, selectedBlocks[0]);

  if (
    firstBlock.id &&
    !(
      firstBlock.lineType === LineType.Title &&
      firstBlock.referencingContainerId === firstBlock.containerId
    )
  )
    newFocusBlock = getPreviousBlock(newState, firstBlock, currentContext);

  if (
    endContext &&
    endContext.state &&
    currentBlock.id &&
    endContext.isFullContainerLoaded &&
    endContext.state.rootBlocksIds.length > 0
  ) {
    const lastBlockId =
      endContext.state.rootBlocksIds[endContext.state.rootBlocksIds.length - 1];

    startBlockMove({
      type: "after",
      referenceId: lastBlockId,
      movedBlockId: currentBlock.id,
      context: moveProps.context,
      options: "sibling",
      fromPalette: true,
    });
  } else {
    if (currentBlock.id) {
      const saveActions = removeBlocksFromContainer({
        blockIds: selectedBlocks,
        targetContainer: moveProps.targetContainer,
        context: moveProps.context,
      });

      let saveFullBlock = false;
      if (currentContext.containerId === "newComment") {
        saveFullBlock = true;
      }

      if (saveFullBlock) {
        await setUpdateObjectToStore(saveActions, moveProps.context, {
          saveFullBlock,
          waitForActions: saveFullBlock,
        });
      }
    }

    return await changeContainer(
      selectedBlocks,
      moveProps.targetContainer.containerId,
      moveProps.targetContainer.containerType,
      currentBlock.baseId,
      true,
      true
    );
  }

  const newValue: FocusObject = {
    ...$focusOn.value,
    focusBlockId: newFocusBlock ? newFocusBlock : undefined,
    focusContext: moveProps.context,
  };

  store.dispatch({
    type: REFOCUS,
    param: {
      newFocus: newValue,
    },
  });
};

export const handleRemoveWorkTitleFromContainer = (workItem: IProjectObj) => {
  const titleBlockId = workItem.titleBlockId;

  const newState = store.getState().blocks;
  const titleBlock = getBlockById(newState.dict, titleBlockId);
  if (!titleBlock.id) return;

  const delta: Partial<Block> = {
    containerId: workItem.id,
    containerType: ContainerTypes.PROJECT,
    documentRank: "0",
    indentLevel: 0,
    parentId: "",
  };

  const saveActions: ActionObject[] = [];
  const action: ActionObject = {
    id: titleBlock.id,
    type: BlockActionTypes.update,
    delta: {
      ...delta,
      ...{
        dateModified: new Date(),
        modifiedBy: prevState.value.user?.id,
      },
    },
  };
  saveActions.push(action);

  store.dispatch({
    type: DELETE_BLOCK,
    param: {
      id: titleBlock.id,
    },
  });

  const pseudoContext = {
    autosave: true,
  } as IBlockContext;

  setUpdateObjectToStore(saveActions, pseudoContext);
};

const removeBlocksFromContainer = (moveProps: {
  blockIds: string[];
  context: any;
  targetContainer: {
    containerId: string;
    containerType: ContainerTypes;
  };
  presetDelta?: Partial<Block>;
}) => {
  const newState = store.getState().blocks;

  const saveActions: ActionObject[] = [];
  const undoObject: ActionWrapperObject = createActionWrapperObject(
    moveProps.context
  );
  batch(() => {
    moveProps.blockIds.forEach((selectedBlockId) => {
      const selectedBlock = getBlockById(newState.dict, selectedBlockId);
      let delta: Partial<Block> = {};
      delta.parentId = "";
      delta.documentRank = "z";
      delta.indentLevel = 0;
      delta.containerId = moveProps.targetContainer.containerId;
      delta.containerType = moveProps.targetContainer.containerType;

      if (moveProps.presetDelta) {
        delta = { ...delta, ...moveProps.presetDelta };
      }

      const action: ActionObject = {
        id: selectedBlock.id,
        type: BlockActionTypes.update,
        delta: {
          ...delta,
          ...{
            dateModified: new Date(),
            modifiedBy: prevState.value.user?.id,
          },
        },
      };

      if (saveActions) {
        saveActions.push(action);
      }

      if (undoObject) {
        const action: ActionObject = {
          delta: getAntiDelta(delta, selectedBlock),
          type: BlockActionTypes.update,
          id: selectedBlock.id,
        };
        undoObject.actions.push(action);
      }

      if (delta.parentId || delta.parentId === "" || delta.containerId) {
        if (delta.containerId)
          handleLocalEntityDisconnect(selectedBlock, { immediate: true });
        store.dispatch({
          type: DELETE_BLOCK,
          param: {
            id: selectedBlock.id,
          },
        });

        updateChildrenTree({
          blockId: selectedBlock.id,
          delta,
          saveActions: saveActions,
          undoObject: undoObject,
        });
        handleUpdateAction(action, true);
        if (delta.containerId)
          handleLocalEntityConnect(selectedBlock, { immediate: true });
      }
    });

    store.dispatch({
      type: SET_UNDO,
      param: {
        undoObject,
        contextId: moveProps.context.id,
      },
    });
  });
  return saveActions;
};

export const updateChildrenTree = (moveProps: {
  blockId: string;
  delta: Partial<Block>;
  saveActions?: ActionObject[];
  undoObject?: ActionWrapperObject;
}) => {
  const innerDelta: Partial<Block> = {};
  if (moveProps.delta.indentLevel || moveProps.delta.indentLevel === 0)
    innerDelta.indentLevel = moveProps.delta.indentLevel + 1;
  if (moveProps.delta.containerId)
    innerDelta.containerId = moveProps.delta.containerId;
  if (moveProps.delta.containerType)
    innerDelta.containerType = moveProps.delta.containerType;

  const blockId = moveProps.blockId;
  const newState = store.getState().blocks;
  const block = getBlockById(newState.dict, blockId);

  block.children?.forEach((childId) => {
    const child = getBlockById(newState.dict, childId);
    const action: ActionObject = {
      id: childId,
      type: BlockActionTypes.update,
      delta: innerDelta,
    };
    store.dispatch({
      type: SAVE_BLOCK_DATA,
      param: {
        id: child.id,
        blockData: child,
        delta: innerDelta,
      },
    });
    if (moveProps.saveActions) moveProps.saveActions.push(action);
    if (moveProps.undoObject) {
      const antiaction = getAntiAction(action);
      moveProps.undoObject.actions.push(antiaction);
    }
    if (child.children && child.children.length > 0)
      updateChildrenTree({
        blockId: childId,
        delta: innerDelta,
        saveActions: moveProps.saveActions,
        undoObject: moveProps.undoObject,
      });
  });
};

export const changeContainer = async (
  blockIds: string[],
  containerId: string,
  containerType: ContainerTypes,
  baseId: string,
  moveChildrenBackend?: boolean,
  toBottom?: boolean
) => {
  return axiosInstance.post("/api/lines/changeContainer", {
    blockIds,
    containerId,
    containerType,
    baseId,
    moveChildrenBackend,
    toBottom,
  });
};

export const checkFocusTitle = (block: Block, context: IBlockContext) => {
  if (context.type === "container") {
    const newState = store.getState().blocks;
    const currentContext = getCurrentContext(newState, context.id);
    const index = getBlockIndex(newState, block, currentContext);
    if (index === 0) {
      return context.ref.current?.getElementsByClassName("title")[0];
    }
  }
};
