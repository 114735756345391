// Vendor Libraries
import React, { memo, useMemo, useRef } from "react";
import { useWidthStyles } from "./Main";

// Styles
import styles from "./splitView/splitView.module.scss";
import { ChunkDestination } from "utilities/stateTypes";
import Navigator from "./Navigator";
import TopNavBar from "components/TopNavBar";
import { SplitGlobalTopNav } from "components/GlobalTopNav";
import { PaneContext } from "App";
import ErrorTopBar from "components/ErrorTopBar";

function SplitView() {
  return (
    <SplitAllViewWrapper>
      <Navigator />
    </SplitAllViewWrapper>
  );
}
export const SplitViewRef: React.MutableRefObject<HTMLDivElement | null> = {
  current: null,
};

function SplitViewWrapper() {
  return useMemo(
    () => (
      <div
        className={styles.wrapperContainer}
        ref={SplitViewRef}
        id="secondary"
      >
        <SplitGlobalTopNav />
        <ErrorTopBar paneId={ChunkDestination.secondary} key="split" />
        <TopNavBar paneId={ChunkDestination.secondary} />
        <SplitView />
      </div>
    ),
    []
  );
}

const SplitAllViewWrapper: React.FC<any> = (props) => {
  const containerRef = useRef(null);
  const widthStyles = useWidthStyles(containerRef);

  return (
    <PaneContext.Provider value={ChunkDestination.secondary}>
      <div
        className={`${styles.client__split} ${widthStyles.join(" ")}`}
        ref={containerRef}
      >
        {props.children}
      </div>
    </PaneContext.Provider>
  );
};

export default memo(SplitViewWrapper);
