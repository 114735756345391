import { useEffect, useState } from "react";
import { Alert, Form, Radio } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { IWorkspaceObj, UserBaseSettings } from "utilities/types";
import TabLayout from "./TabLayout";
import styles from "./options/options.module.scss";
import { socket } from "App";
import Button, { ButtonTypes } from "components/Button";
import { axiosInstance } from "index";

interface Props {
  base: IWorkspaceObj;
  userSettings: UserBaseSettings;
}

interface Status {
  message: string;
  type: "error" | "success";
}

const Notifications = ({ base, userSettings }: Props) => {
  const [userEmailStatus, setUserEmailStatus] = useState<string>(
    userSettings?.settings?.emailNotifications
  );
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      "email-notification": userSettings?.settings?.emailNotifications,
    });
  }, [userSettings]);

  const [userBaseSettings, setUserBaseSettings] =
    useState<UserBaseSettings>(userSettings);

  const [userSettingsStatus, setUserSettingsStatus] = useState<Status>({
    message: "",
    type: "error",
  });

  const handleSaveUserBaseSettings = async () => {
    try {
      const data = await axiosInstance.patch(`/api/user-base-settings`, {
        userBaseSettings: {
          ...userBaseSettings,
          settings: userBaseSettings.settings,
        },
        clientId: socket.id,
      });
      if (data.status === 200) {
        setUserSettingsStatus({
          type: "success",
          message: "User Settings updated successfully",
        });
      }
    } catch (e) {
      setUserSettingsStatus({
        type: "error",
        message:
          "Sorry, we could not save your changes. Please try again later",
      });
    }
  };

  return (
    <>
      <TabLayout
        title="Notifications"
        description="Show additional metadata about Notifications"
      >
        <Form
          initialValues={{
            "email-notification": userEmailStatus,
          }}
          form={form}
        >
          <div className={styles.options}>
            <label htmlFor="email-notification">
              <b>Email Notifications</b>
            </label>
            <Form.Item name="email-notification" style={{ marginTop: "10px" }}>
              <Radio.Group
                value={userEmailStatus ? userEmailStatus : "All"}
                onChange={(e) => {
                  setUserBaseSettings({
                    ...userBaseSettings,
                    settings: {
                      ...userBaseSettings.settings,
                      emailNotifications: e.target.value,
                    },
                  });
                  setUserEmailStatus(e.target.value);
                }}
              >
                <Radio
                  value="None"
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  None
                  <span style={{ opacity: 0.5, marginLeft: "10px" }}>
                    You will not receive any notification by email about
                    activity in this base
                  </span>
                </Radio>
                <Radio
                  value="Mentions"
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  Mentions
                  <span style={{ opacity: 0.5, marginLeft: "10px" }}>
                    You will only receive notifications by email when someone
                    @mentions you
                  </span>
                </Radio>
                <Radio value="All" style={{ width: "100%" }}>
                  All
                  <span style={{ opacity: 0.5, marginLeft: "10px" }}>
                    Receive notifications by email for all activity that is
                    relevant to you
                  </span>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {userSettingsStatus.type === "error" &&
              !!userSettingsStatus.message && (
                <Alert
                  type="error"
                  message={
                    <div>
                      <WarningOutlined /> {userSettingsStatus.message}
                    </div>
                  }
                  style={{
                    marginBottom: 28,
                    backgroundColor: " #fcdbdc",
                    borderRadius: "4px",
                    border: "solid 1px #dbabac",
                    color: "#723b3e",
                  }}
                />
              )}
            {userSettingsStatus.type === "success" &&
              !!userSettingsStatus.message && (
                <Alert
                  type="success"
                  message={userSettingsStatus.message}
                  style={{ marginBottom: 28 }}
                />
              )}
            <Button
              buttonType={ButtonTypes.PRIMARY}
              onClick={handleSaveUserBaseSettings}
              style={{ borderRadius: 3 }}
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </TabLayout>
    </>
  );
};

export default Notifications;
