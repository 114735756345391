import { useEffect, useLayoutEffect, useState } from "react";
import {
  DEFAULT_EMPTY_MILESTONE_MODE,
  RoadmapMilestonesMode,
} from "store/reducers/roadmapFilterReducer";

import { useInitiativeIds, useProjectIds } from "store/reducers/workReducer";
import { useShallowSelector } from "utilities/hooks";
import { IProjectObj } from "utilities/types";

export const useIsRoadmapViewActive = (baseId: string, viewId?: string) => {
  const [isActive, setisActive] = useState(false);

  const roadmapItemsFilters = useShallowSelector(
    (store) =>
      viewId && store.roadmapFilters && store.roadmapFilters[baseId]?.[viewId]
  );

  useLayoutEffect(() => {
    if (
      roadmapItemsFilters &&
      (roadmapItemsFilters.showClosedProjects ||
        (roadmapItemsFilters.roadmapMilestonesMode &&
          roadmapItemsFilters.roadmapMilestonesMode !==
            DEFAULT_EMPTY_MILESTONE_MODE))
    ) {
      setisActive(true);
    } else setisActive(false);
  }, [roadmapItemsFilters]);

  return isActive;
};

export const useGetMilestonesToShow = (param: {
  baseId: string;
  viewId?: string;
  groupId?: string;
  all?: boolean;
  assigneeId?: string;
}) => {
  const [showMilestoneIds, setshowMilestoneIds] = useState<string[]>([]);

  const milestoneIds = useShallowSelector((store) => store.work.milestoneIds);

  const roadmapItemsFilters = useShallowSelector(
    (store) =>
      param.viewId &&
      store.roadmapFilters &&
      store.roadmapFilters[param.baseId]?.[param.viewId]
  );
  // const initiativeIds = useInitiativeIds(param.groupId);
  // const projectIds = useProjectIds(param.groupId);
  const initiativeIds = useInitiativeIds();
  const projectIds = useProjectIds();
  const workDict = useShallowSelector((store) => store.work.dict);

  useEffect(() => {
    let foundFirst = false;

    const groupedItems: { [id: string]: boolean } = {};
    const hasEmptyProject: { [id: string]: boolean } = {};
    const hasNonEmptyProjects: { [id: string]: boolean } = {};

    const showUpcoming =
      !roadmapItemsFilters ||
      !roadmapItemsFilters.roadmapMilestonesMode ||
      (roadmapItemsFilters &&
        roadmapItemsFilters.roadmapMilestonesMode ===
          RoadmapMilestonesMode.upcoming);

    const showAll =
      roadmapItemsFilters &&
      roadmapItemsFilters.roadmapMilestonesMode === RoadmapMilestonesMode.all;

    const showNone =
      roadmapItemsFilters &&
      roadmapItemsFilters.roadmapMilestonesMode ===
        RoadmapMilestonesMode.completed;

    const confirmPassedFilters = (milestoneId: string, isClosed: boolean) => {
      groupedItems[milestoneId] = true;
      if (isClosed) hasEmptyProject[milestoneId] = true;
      else hasNonEmptyProjects[milestoneId] = true;
    };

    const checkIfCanShow = (workItem: IProjectObj) => {
      return confirmPassedFilters(
        workItem.milestoneId ?? "null",
        workItem.isClosed
      );
    };

    const filterItem = (id: string) => {
      const workItem = workDict[id];
      if (!workItem || !workItem.projectId) return;
      if (param.all) {
        checkIfCanShow(workItem);
      }
      checkIfCanShow(workItem);
    };

    initiativeIds.forEach((id) => filterItem(id));
    projectIds.forEach((id) => filterItem(id));

    let activeIds: string[] = [];

    milestoneIds.forEach((id) => {
      if (
        hasNonEmptyProjects[id] ||
        (!hasNonEmptyProjects[id] && !hasEmptyProject[id])
      ) {
        if (!foundFirst) foundFirst = true;
      }

      if (showAll) {
        activeIds.push(id);
      } else if (showUpcoming) {
        if (
          hasNonEmptyProjects[id] ||
          (!hasNonEmptyProjects[id] && !hasEmptyProject[id])
        ) {
          activeIds.push(id);
        } else {
          if (foundFirst) activeIds.push(id);
        }
      } else if (showNone) {
        if (foundFirst) return;
        if (hasEmptyProject[id]) activeIds.push(id);
      }
    });

    if (JSON.stringify(activeIds) !== JSON.stringify(showMilestoneIds))
      setshowMilestoneIds(activeIds);
  }, [
    milestoneIds,
    roadmapItemsFilters,
    initiativeIds,
    projectIds,
    workDict,
    param.assigneeId,
    param.all,
  ]);

  return { showMilestoneIds };
};
