import ConfirmModal from "clarity-ui/ConfirmModal";
import { axiosInstance } from "index";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { batch } from "react-redux";
import {
  CLEAR_WORKSPACE_DATA,
  REMOVE_ACCESS_TOKEN,
  REMOVE_AUTHENTICATED_USER,
} from "store/actions";
import store from "store/storeExporter";
import Button, { ButtonTypes } from "./Button";
import ClarityInput from "./ClarityInput";
import { locationSubject } from "./LocationListener";

const DeleteUser: React.FC<{}> = () => {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);

  const [disabled, setDisabled] = useState<boolean>(true);
  const [passPhrase, setPassPhrase] = useState<string>("");

  useEffect(() => {
    if (passPhrase === "DELETE") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [passPhrase]);

  const handleLeaveBase = async () => {
    if (disabled) {
      return;
    }
    await axiosInstance.post(`/api/user/delete`).then(() => {
      Cookies.remove("x-at", {
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      Cookies.remove("x-rt", {
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      batch(() => {
        store.dispatch({ type: REMOVE_AUTHENTICATED_USER });
        store.dispatch({ type: REMOVE_ACCESS_TOKEN });
        store.dispatch({ type: CLEAR_WORKSPACE_DATA });
      });
      localStorage.clear();
      locationSubject.next("/");
    });
  };

  return (
    <>
      <Button
        buttonType={ButtonTypes.DANGER}
        onClick={() => {
          setIsConfirmationModalVisible(true);
        }}
      >
        {"Delete account"}
      </Button>
      {isConfirmationModalVisible && (
        <ConfirmModal
          onConfirm={handleLeaveBase}
          confirmDisabled={disabled}
          confirmationText="Delete"
          title="Delete account"
          close={() => setIsConfirmationModalVisible(false)}
        >
          <div
            className="body2 regular"
            style={{
              marginTop: "24px",
              marginBottom: "40px",
            }}
          >
            <span>Are you sure you want to delete the user?</span>
            <p>Please writte "DELETE" below to confirm the action</p>

            <ClarityInput
              onChange={(e) => {
                setPassPhrase(e.target.value);
              }}
              placeholder="DELETE"
            />
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default DeleteUser;
