import { Dropdown, Menu, Skeleton } from "antd";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReplyEditor from "./blockDiscussion/ReplyEditor";
import styles from "./blockDiscussion/discussion.module.scss";
import { Block } from "store/reducers/blockReducer";
import { shallowEqual, useSelector } from "react-redux";
import { Abilities, InlineDiscussion, Reply, ReplyType } from "utilities/types";
import UserDisplay from "./UserDisplay";
import TimePassed from "components/TimePassed";
import store, { prevState } from "store/storeExporter";
import { deleteInlineReply } from "screens/base/splitView/components/discussion/discussionHelpers";
import MoreHorizontalDots from "icons/Components/MoreHorizontalDots";
import { CloseOutlined } from "@ant-design/icons";
import { OPEN_CONFIRMATION_MODAL } from "store/actions";
import { blockApi } from "editor/utils/blockActionsApi";
import notificationsApi from "clientApi/notificationsApi";
import { useAbilityChecker } from "editor/utils/customHooks";
import { getHtml } from "editor/utils/blockValueHelpers";
import { axiosInstance } from "index";

interface Props {
  actions: React.MutableRefObject<{
    setopenPortal: React.Dispatch<React.SetStateAction<boolean>>;
    sethasUnconfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  }>;
  discussionId: string | null;
  relatedBlock: Block;
  hasUnconfirmed: boolean;
}

interface DiscussionViewProps {
  discussion?: InlineDiscussion;
  relatedBlock: Block;
  replyEditingState: React.MutableRefObject<{
    [id: string]: boolean;
  }>;
  sethasUnconfirmed: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DiscussionItemProps {
  reply: Reply;
  discussion: InlineDiscussion;
  showAuthor: boolean;
  relatedBlock: Block;
  replyEditingState: React.MutableRefObject<{
    [id: string]: boolean;
  }>;
  sethasUnconfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  moderateComment: boolean;
}

const BlockDiscussion: React.FC<Props> = (props) => {
  const onUnload = (e: BeforeUnloadEvent) => {
    if (props.hasUnconfirmed) {
      e.preventDefault();
      e.returnValue = "show warning";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return function remove() {
      window.removeEventListener("beforeunload", onUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hasUnconfirmed]);

  const discussion: InlineDiscussion | undefined = useSelector(
    (state: any) =>
      props.discussionId
        ? state.inlineDiscussions.dict[props.discussionId]
        : null,
    shallowEqual
  );
  const [isLoading, setisLoading] = useState(false);
  const replyEditingState = useRef<{ [id: string]: boolean }>({});
  const isLoaded = useRef(false);

  useEffect(() => {
    if (props.discussionId && (!discussion || !discussion.id)) {
      setisLoading(true);

      axiosInstance
        .get("/api/discussion/byId", {
          params: {
            id: props.discussionId,
          },
        })
        .then((res) => {
          store.dispatch({
            type: "ADD_ENTITY_CONNECTIONS_OBJECT",
            id: props.relatedBlock.containerId,
            containerType: props.relatedBlock.containerType,
            comments: [res.data],
          });
          setisLoading(false);
        });
    }
    return () => {
      blockApi.blockAction.confirmComment(props.relatedBlock.id);
    };
  }, []);

  const [quotedText, setquotedText] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {
    if (isLoaded.current) setquotedText(undefined);
  }, [discussion?.replies]);

  useLayoutEffect(() => {
    if (props.relatedBlock.options?.quotedText)
      setquotedText(props.relatedBlock.options.quotedText);
    else setquotedText(undefined);
  }, [props.relatedBlock.options]);

  useEffect(() => {
    isLoaded.current = true;
  }, []);

  if (isLoading) {
    return (
      <>
        <DiscussionViewHeader {...props} />
        <div className={styles.discussion_portal}>
          <div
            className={styles.header + " " + styles.rowPadding}
            onClick={() => {
              if (props.hasUnconfirmed) {
                notificationsApi.displayError({
                  body: <span> You have an unsaved comment</span>,
                  duration: 3,
                });
              } else props.actions.current.setopenPortal(false);
            }}
          >
            <span style={{ marginRight: "10px" }}>
              <CloseOutlined />
            </span>
            <span>Hide replies</span>
          </div>
          <div style={{ width: "100%", padding: "12px" }}>
            <Skeleton
              avatar
              active={true}
              loading={true}
              paragraph={{ rows: 2 }}
            />
            <Skeleton
              avatar
              active={true}
              loading={true}
              paragraph={{ rows: 2 }}
            />
            <Skeleton
              avatar
              active={true}
              loading={true}
              paragraph={{ rows: 2 }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DiscussionViewHeader {...props} />
      {discussion && <DiscussionDivider discussion={discussion} />}
      <div className={styles.discussion_portal}>
        <div style={{ width: "100%" }}>
          <DiscussionView
            discussion={discussion}
            relatedBlock={props.relatedBlock}
            replyEditingState={replyEditingState}
            sethasUnconfirmed={props.actions.current.sethasUnconfirmed}
          />
          <ReplyEditor
            discussion={discussion}
            quotedText={quotedText}
            relatedBlock={props.relatedBlock}
            replyEditingState={replyEditingState}
            sethasUnconfirmed={props.actions.current.sethasUnconfirmed}
            editing={false}
          />
        </div>
      </div>
    </>
  );
};

const DiscussionView: React.FC<DiscussionViewProps> = (props) => {
  const canModerateComment = useAbilityChecker({
    abilityName: Abilities.MODERATE_COMMENTS,
  });

  if (!props.discussion) return <></>;

  return (
    <>
      {props.discussion.replies.map((reply, index) => (
        <DiscussionItem
          reply={reply}
          replyEditingState={props.replyEditingState}
          discussion={props.discussion as InlineDiscussion}
          relatedBlock={props.relatedBlock}
          key={reply.id}
          sethasUnconfirmed={props.sethasUnconfirmed}
          moderateComment={canModerateComment}
          showAuthor={
            // props.discussion?.replies[index - 1]?.authorId !== reply.authorId
            true
          }
        />
      ))}
    </>
  );
};

const DiscussionViewHeader: React.FC<Props> = (props) => {
  return (
    <>
      <div
        className={styles.related_block}
        dangerouslySetInnerHTML={{
          __html: getHtml(props.relatedBlock.value),
        }}
      ></div>
    </>
  );
};

const DiscussionDivider: React.FC<{ discussion: InlineDiscussion }> = ({
  discussion,
}) => {
  return (
    <div className={styles.discussion_divider}>
      <span className={styles.divider_label_container}>
        <span className={styles.divider_label}>
          {discussion.replies.length}{" "}
          {discussion.replies.length > 1 ? "comments" : "comment"}
        </span>
      </span>
    </div>
  );
};

const DiscussionItem: React.FC<DiscussionItemProps> = (props) => {
  const [isEditing, setisEditing] = useState(false);
  const user = prevState.value.user;

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        disabled={
          props.moderateComment ? false : props.reply?.authorId !== user?.id
        }
        onClick={() => setisEditing(true)}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        disabled={
          props.moderateComment
            ? props.reply.orderInDiscussion === 0 &&
              props.discussion.replies.length > 1
            : props.reply?.authorId !== user?.id ||
              (props.reply.orderInDiscussion === 0 &&
                props.discussion.replies.length > 1)
        }
        onClick={() => {
          store.dispatch({
            type: OPEN_CONFIRMATION_MODAL,
            message: "",
            // body: "This action will delete the work items",
            title: `Confirm delete`,
            confirmMessage: "Confirm",
            confirmAction: () => {
              deleteInlineReply(props.reply, props.discussion);
            },
            display: true,
          });
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={styles.reply_item + " " + styles.rowPadding}>
        <div className={styles.reply_header}>
          <UserDisplay
            id={props.reply.authorId}
            customStyles={{ fontWeight: "500" }}
            avatarSize={30}
          />
          <span
            style={{
              marginLeft: "10px",
              display: "flex",
              // alignItems: "center",
            }}
            className="small secondary"
          >
            <TimePassed startTime={props.reply.dateCreated} />
          </span>
          <span style={{ marginLeft: "auto", cursor: "pointer" }}>
            {(!props.reply.type || props.reply.type === ReplyType.TEXT) && (
              <Dropdown overlay={menu} trigger={["click"]}>
                <MoreHorizontalDots alt="more options menu" />
                {/* <img src={MoreHorizontal} alt="more options menu" /> */}
              </Dropdown>
            )}
          </span>
        </div>

        <div className={styles.reply_content}>
          {props.reply?.quotedText && (
            <div className={styles.reply_quote} style={{ marginLeft: "0" }}>
              <span className="caption secondary">
                {props.reply.quotedText}
              </span>
            </div>
          )}
          {isEditing ? (
            <ReplyEditor
              discussion={props.discussion}
              sethasUnconfirmed={props.sethasUnconfirmed}
              editing={true}
              replyEditingState={props.replyEditingState}
              setisEditing={setisEditing}
              relatedBlock={props.relatedBlock}
              innerValue={getHtml(props.reply.value)}
              reply={props.reply}
              quotedText={props.reply.quotedText}
            />
          ) : (
            <>
              <span
                onMouseDown={(e) => e.stopPropagation()}
                onSelect={(e) => e.stopPropagation()}
                dangerouslySetInnerHTML={{ __html: getHtml(props.reply.value) }}
              ></span>{" "}
              {props.reply.dateModified !== props.reply.dateCreated && (
                <span style={{ marginLeft: "5px" }} className="small disabled">
                  (Edited)
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BlockDiscussion;
