export function createFakeElement(value: string) {
  var isRTL = document.documentElement.getAttribute("dir") === "rtl";
  var fakeElement = document.createElement("textarea"); // Prevent zooming on iOS

  fakeElement.style.fontSize = "12pt"; // Reset box model

  fakeElement.style.border = "0";
  fakeElement.style.padding = "0";
  fakeElement.style.margin = "0"; // Move element out of screen horizontally

  fakeElement.style.position = "absolute";
  fakeElement.style[isRTL ? "right" : "left"] = "-9999px"; // Move element to the same position vertically

  var yPosition = String(
    window.pageYOffset || document.documentElement.scrollTop
  );
  fakeElement.style.top = "".concat(yPosition, "px");
  fakeElement.value = value;
  fakeElement.innerText = value;
  fakeElement.setAttribute("readonly", "");
  return fakeElement;
}

export function copyTextToClipboard(value: string) {
  const el = createFakeElement(value);
  document.body.append(el);
  el.select();
  const selection = document.getSelection();
  if (selection?.toString() !== value) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  document.execCommand("copy");
  setTimeout(() => {
    el.remove();
  }, 10);
}
