import Modal from "clarity-ui/Modal";
import UserList from "clarity-ui/UserList";
import { groupApi } from "clientApi/groupsApi";
import Button, { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import { useShallowSelector } from "utilities/hooks";
import { Abilities } from "utilities/types";
import { useImmediateGroupInvitationsModalSetter } from "../../settings/members/InviteGroupMemberModal";
import styles from "./groupMembers.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import { VALUE_ESCAPE } from "keycode-js";
import listStyles from "clarity-ui/userList/userList.module.scss";

const GroupMembersModal: React.FC<{
  isVisible: boolean;
  setisVisible: (isVisible: boolean) => void;
  groupId: string;
}> = ({ isVisible, setisVisible, groupId }) => {
  if (!isVisible) return <></>;
  else
    return (
      <GroupMembersModalContent groupId={groupId} setisVisible={setisVisible} />
    );
};

const GroupMembersModalContent: React.FC<{
  groupId: string;
  setisVisible: (isVisible: boolean) => void;
}> = ({ groupId, setisVisible }) => {
  const members = useShallowSelector(
    (store) => store.groups.dict[groupId]?.members
  );
  const groupName = useShallowSelector(
    (store) => store.groups.dict[groupId]?.name
  );

  const emptyList = useRef([]);

  const canManageMembers = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });
  const { showInvitationsModal } =
    useImmediateGroupInvitationsModalSetter(groupId);

  const RemoveFromGroupAction = useCallback(
    (id: string) => {
      return (
        <Button
          disabled={!canManageMembers}
          onClick={() => {
            groupApi.kickFromGroup(groupId, id);
          }}
          style={{
            minWidth: "126px",
          }}
          className={listStyles.showOnMemberHover}
          buttonType={ButtonTypes.DEFAULT}
        >
          Remove
        </Button>
      );
    },
    [groupId, canManageMembers]
  );

  return ReactDOM.createPortal(
    <Modal
      size="medium"
      className={styles.modalClass}
      onKeyDown={(e) => {
        if (e.key === VALUE_ESCAPE) setisVisible(false);
      }}
      hideModal={() => setisVisible(false)}
    >
      <div className={styles.modalTitle}>
        <h3 className="exerpt">Members of group "{groupName}"</h3>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          icon={<PlusOutlined />}
          onClick={showInvitationsModal}
          disabled={!canManageMembers}
          className={styles.button}
        >
          Add Member
        </Button>
      </div>
      <UserList
        userIds={members ?? emptyList.current}
        showSearch={true}
        memberAction={RemoveFromGroupAction}
      />
      <div>
        <Button onClick={() => setisVisible(false)}>Close</Button>
      </div>
    </Modal>,
    document.body
  );
};

export default GroupMembersModal;
