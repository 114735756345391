import React from "react";
import styles from "clarity-ui/portalContainer/portalContainer.module.scss";

interface IPortalContainerProps {
  children: any;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  styles?: any;
}

export default function PortalContainer(props: IPortalContainerProps) {
  return (
    <div
      className={styles.PortalContainer}
      style={{ ...props.styles }}
      ref={props.containerRef}
    >
      {props.children}
    </div>
  );
}
