import Icon from "@ant-design/icons";
import React from "react";

export default function GoldTwoTone(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      width="1.1em"
      height="1.1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.80781 8.72969C6.79844 8.66875 6.74531 8.625 6.68437 8.625H2.59687C2.53594 8.625 2.48281 8.66875 2.47344 8.72969L1.84531 12.6047C1.84375 12.6109 1.84375 12.6187 1.84375 12.625C1.84375 12.6938 1.9 12.75 1.96875 12.75H7.3125C7.31875 12.75 7.32656 12.75 7.33281 12.7484C7.40156 12.7375 7.44687 12.6734 7.43594 12.6047L6.80781 8.72969V8.72969ZM3.07031 11.6875L3.39375 9.6875H5.88594L6.20937 11.6875H3.07031ZM14.1547 12.6047L13.5266 8.72969C13.5172 8.66875 13.4641 8.625 13.4031 8.625H9.31563C9.25469 8.625 9.20156 8.66875 9.19219 8.72969L8.56406 12.6047C8.5625 12.6109 8.5625 12.6187 8.5625 12.625C8.5625 12.6938 8.61875 12.75 8.6875 12.75H14.0312C14.0375 12.75 14.0453 12.75 14.0516 12.7484C14.1187 12.7375 14.1656 12.6734 14.1547 12.6047V12.6047ZM9.78906 11.6875L10.1125 9.6875H12.6047L12.9281 11.6875H9.78906ZM5.34375 7.375H10.6875C10.6938 7.375 10.7016 7.375 10.7078 7.37344C10.7766 7.3625 10.8219 7.29844 10.8109 7.22969L10.1828 3.35469C10.1734 3.29375 10.1203 3.25 10.0594 3.25H5.97188C5.91094 3.25 5.85781 3.29375 5.84844 3.35469L5.22031 7.22969C5.21875 7.23594 5.21875 7.24375 5.21875 7.25C5.21875 7.31875 5.275 7.375 5.34375 7.375ZM6.76875 4.3125H9.26094L9.58438 6.3125H6.44375L6.76875 4.3125V4.3125Z"
        fill="currentColor"
      />

      <path
        d="M9.26094 4.3125H6.76875L6.44375 6.3125H9.58438L9.26094 4.3125ZM3.39375 9.6875L3.07031 11.6875H6.20938L5.88594 9.6875H3.39375ZM10.1125 9.6875L9.78906 11.6875H12.9281L12.6047 9.6875H10.1125Z"
        fill="currentColor"
        fillOpacity="0.57"
        opacity="0.1"
      />
    </svg>
  );
}
