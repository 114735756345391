import React, {
  memo,
  useLayoutEffect,
  useState,
  useRef,
  useEffect,
} from "react";
import { batch, connect } from "react-redux";
import {
  BaseType,
  ContainerTypes,
  ContainerVisibility,
  mapContainerTypeToPrimitive,
  PrimitiveScopes,
  UserRole,
  ViewNames,
  WorkTypes,
} from "utilities/types";
import * as actionTypes from "../../../store/actions";
import PrivatePage from "./detailView/PrivatePage";
import store, { ClarityStore } from "store/storeExporter";
import { workItemNrToId } from "store/reducers/workReducer";
import { stripHtml } from "utilities/stringUtilities";
import ContainerViews from "./detailView/ContainerViews";
import NotFound from "screens/NotFound";
import {
  usePageDataSetter,
  ISetPageDataParameters,
} from "store/reducers/topNavReducer";
import { FileDoneOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { getNameFromContainer } from "modules/containerHelpers";
import { getHtml } from "editor/utils/blockValueHelpers";
import { axiosInstance } from "index";
import {
  useBaseId,
  useBaseSlug,
  useBaseType,
} from "store/reducers/workspaceReducer";
import recentlyOpenedApi from "clientApi/recentlyOpenedApi";
import { usePaneId } from "store/reducers/filterReducer";
import { ChunkDestination } from "utilities/stateTypes";
import { useLocation } from "react-router-dom";
import { flashBlockSubject } from "App";
import { permissionsApi } from "clientApi/permissionsApi";
import { tokenGateApi } from "clientApi/tokenGatesApi";
const { validate } = require("uuid");

interface IDetailViewProps {
  containerId: string;
  containerType: ContainerTypes;
}

interface IMapStateToProps {
  isBaseMember: boolean;
  userId: string | undefined;
  permissions: any;
  setBaseWork: (work: any) => {
    type: string;
    work: any;
  };
}

interface IMapDispatchToProps {
  setRefreshRedirectUrl: (url: string) => { type: string; url: string };
  // TODO: Change type to be only the container objects
  setPrimaryActiveEntity: (container: any) => { type: string; entity: any };
}

export function generatePageData(
  containerType: ContainerTypes,
  container: any
): ISetPageDataParameters {
  if (!container) {
    return { title: "" };
  } else if (containerType === ContainerTypes.NOTE) {
    return { title: getNameFromContainer(container, ContainerTypes.NOTE) };
  } else if (
    containerType === ContainerTypes.PROJECT ||
    containerType === ContainerTypes.TASK ||
    containerType === ContainerTypes.WORK
  ) {
    if (container.workType === "Task") {
      const group = store.getState().groups.dict[container.groupId];
      return {
        title: `${stripHtml(getHtml(container.nameValue))}`,
        subtitle: `${group.slug}-${container.projectId}`,
        icon: () =>
          containerType === ContainerTypes.TASK ? (
            <CheckCircleOutlined />
          ) : (
            <FileDoneOutlined />
          ),
      };
    }
    return {
      title: `${stripHtml(getHtml(container.nameValue))}`,
      subtitle: `#${container.projectId}`,
      icon: () =>
        containerType === ContainerTypes.TASK ? (
          <CheckCircleOutlined />
        ) : (
          <FileDoneOutlined />
        ),
    };
  }
  return { title: getNameFromContainer(container, containerType) };
}

const DetailView: React.FC<
  IDetailViewProps & IMapStateToProps & IMapDispatchToProps
> = (props) => {
  const [pageData, setPageData] = useState<ISetPageDataParameters>({
    title: "Loading...",
  });

  const [isAllowedToView, setIsAllowedToView] = useState(false);
  const [containerExist, setContainerExist] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stateContainerId, setStateContainerId] = useState<string>("");
  const [stateContainerType, setStateContainerType] = useState<ContainerTypes>(
    ContainerTypes.DOCUMENT
  );

  const baseSlug = useBaseSlug();
  const baseId = useBaseId();
  const baseType = useBaseType();
  const {
    containerId,
    containerType,
    isBaseMember,
    userId,
    setPrimaryActiveEntity,
    permissions,
  } = props;

  const isFirstLoad = useRef(0);
  const paneId = usePaneId();

  usePageDataSetter(paneId, pageData);

  const fetchPage = async (baseSlug: string, pageId: string) => {
    const pageDict = store.getState().pages.dict;

    if (pageDict && pageDict[pageId]) {
      return {
        status: 1,
        info: "Success",
        payload: {
          container: pageDict[pageId],
        },
      };
    }
    if (userId || baseType === BaseType.Public) {
      return await axiosInstance
        .get(`/api/document/${baseSlug}/${pageId}`)
        .then((res) => {
          if (res?.data?.payload) {
            const { container } = res?.data?.payload;
            store.dispatch({
              type: actionTypes.ADD_NEW_DOCUMENT,
              document: container,
            });
          }
          return res?.data;
        })
        .catch((err) => console.error(err));
    } else {
      return await axiosInstance
        .get(`/api/document/public/${baseSlug}/${pageId}`)
        .then((res) => {
          if (res?.data?.payload) {
            const { container } = res?.data?.payload;
            store.dispatch({
              type: actionTypes.ADD_NEW_DOCUMENT,
              document: container,
            });
          }
          return res.data;
        })
        .catch((err) => console.error(err));
    }
  };

  const fetchWork = async (baseSlug: string, workNr: string) => {
    const workDict = store.getState().work.dict;

    if (workDict) {
      if (typeof workNr === "string" && workNr.includes("-")) {
        const parsedGroupContainerId = workNr.split("-");

        const groupSlug = parsedGroupContainerId[0];
        const projectId = parsedGroupContainerId[1];

        if (!validate(workNr)) {
          const group = store.getState().groups.slugDict[groupSlug];
          if (group) {
            const work = workItemNrToId[group.id + projectId];

            if (work) {
              return {
                status: 1,
                info: "Success",
                payload: {
                  container: workDict[work],
                },
              };
            }
          }
        } else {
          const work = workDict[workNr];

          if (work) {
            return {
              status: 1,
              info: "Success",
              payload: {
                container: work,
              },
            };
          }
        }
      } else {
        const projectId = workItemNrToId[workNr];
        if (projectId) {
          return {
            status: 1,
            info: "Success",
            payload: {
              container: workDict[projectId],
            },
          };
        }
      }
    }

    return await axiosInstance
      .get(`/api/project/${baseSlug}/${workNr}`)
      .then((res) => {
        const workItem = res.data.payload?.container;
        batch(() => {
          if (res.data.payload?.group) {
            store.dispatch({
              type: actionTypes.SET_BASE_GROUPS,
              groups: [res.data.payload?.group],
            });
          }
          store.dispatch({
            type: actionTypes.LOAD_RELATED_WORK,
            work: [workItem],
          });
        });
        return res.data;
      })
      .catch((err) => console.error(err));
  };

  const fetchNote = async (baseSlug: string, noteId: string) => {
    const notesDict = store.getState().notes.dict;
    const weeklyNotesDict = store.getState().notes.weeklyNotesDict;

    if (notesDict[noteId]?.id || weeklyNotesDict[noteId]?.id) {
      return {
        status: 1,
        info: "Success",
        payload: {
          container: notesDict[noteId] ?? weeklyNotesDict[noteId],
        },
      };
    }

    if (userId || baseType === BaseType.Public) {
      return await axiosInstance
        .get(`/api/note/${baseSlug}/${noteId}`)
        .then((res) => {
          const note = res.data.payload?.container;
          if (note) {
            store.dispatch({
              type: actionTypes.SET_NOTE_OBJECTS,
              params: {
                notes: [note],
              },
            });
          }
          return res.data;
        })
        .catch((err) => console.error(err));
    } else {
      return await axiosInstance
        .get(`/api/note/public/${baseSlug}/${noteId}`)
        .then((res) => {
          const note = res.data.payload?.container;
          if (note) {
            store.dispatch({
              type: actionTypes.SET_NOTE_OBJECTS,
              params: {
                notes: [note],
              },
            });
          }
          return res.data;
        })
        .catch((err) => console.error(err));
    }
  };

  const fetchTemplate = async (baseSlug: string, templateId: string) => {
    const templatesDict = store.getState().templates.dict;
    const templateObj = templatesDict[templateId];

    if (templateObj?.id) {
      return {
        status: 1,
        info: "Success",
        payload: {
          container: { ...templateObj },
        },
      };
    }
  };

  const fetchSnippet = async (baseSlug: string, snippetId: string) => {
    const snippetsDict = store.getState().snippets.dict;
    const snippetObj = snippetsDict[snippetId];

    if (snippetObj?.id) {
      return {
        status: 1,
        info: "Success",
        payload: {
          container: { ...snippetObj },
        },
      };
    }
  };

  const checkPermissionsAndSetActiveContainer = (
    res: any,
    containerType: ContainerTypes
  ) => {
    const state = store.getState();
    const permissions = state.client.permissions?.permissions;
    const role = state.client.roleType;
    const baseType = state.workspace.type;
    const permissionType: PrimitiveScopes = mapContainerTypeToPrimitive[
      containerType
    ] as PrimitiveScopes;

    function checkTokenGate(
      baseId: string,
      containerId: string,
      containerType: ContainerTypes
    ) {
      tokenGateApi.getTokenGateData(containerId, containerType, baseId).catch();
    }

    if (res && res.status === 1) {
      const { container, containerAccess } = res.payload;
      container.type = containerType;
      checkTokenGate(baseId, container.id, containerType);

      if (
        (containerAccess &&
          containerAccess.visibility !== ContainerVisibility.private) ||
        container.isPublicAccess
      ) {
        setIsAllowedToView(true);
        setPrimaryActiveEntity(container);
        setStateContainerId(container.id);
        setStateContainerType(container.type);
        setPageData(generatePageData(containerType, container));
        permissionsApi.addPermission({
          containerId: container.id,
          containerType: containerType,
          accessLevel:
            containerAccess?.visibility ?? ContainerVisibility.canView,
        });
        return container;
      }
      if (role === UserRole.GUEST && baseType !== BaseType.Public) {
        setContainerExist(true);
        if (permissions[permissionType]) {
          const allowedToView = permissions[permissionType]?.read?.includes(
            res.payload.container.id
          );
          if (!allowedToView) {
            setIsAllowedToView(false);
            setPageData({ title: "This page is private" });
            return;
          }
        } else {
          setIsAllowedToView(false);
          setPageData({ title: "This page is private" });
          return;
        }
      }

      recentlyOpenedApi.addRecentlyOpened({
        navigationChunk: {
          viewName: ViewNames.Detail,
          entity: {
            containerId: container.id,
            containerType: containerType,
            workType: container.workType,
          },
        },
        name: "name" in container ? container.name : container.title,
      });

      setStateContainerId(container.id);
      setStateContainerType(container.type);
      setIsAllowedToView(true);
      setPrimaryActiveEntity(container);
      setPageData(generatePageData(containerType, container));
      return container;
    } else if (!res) {
      setIsAllowedToView(false);
      setContainerExist(false);
      setPageData({ title: "This page does not exist" });
    } else {
      setContainerExist(true);
      setIsAllowedToView(false);
      setPageData({ title: "This page is private" });
    }
  };

  const location = useLocation();

  useLayoutEffect(() => {
    setIsLoading(true);
    if (
      ![ContainerTypes.PROJECT, ContainerTypes.WORK].includes(containerType) &&
      !validate(containerId)
    ) {
      setIsLoading(false);
      setIsAllowedToView(false);
      setContainerExist(false);
      setPageData({ title: "Page not found" });
      return;
    }
    switch (containerType) {
      case ContainerTypes.DOCUMENT:
        fetchPage(baseSlug, containerId)
          .then((res) => {
            checkPermissionsAndSetActiveContainer(res, ContainerTypes.DOCUMENT);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setContainerExist(false);
            setPageData({ title: "Page not found" });
          });
        break;

      case ContainerTypes.WORK:
      case ContainerTypes.PROJECT:
      case ContainerTypes.TASK:
        fetchWork(baseSlug, containerId)
          .then((res) => {
            if (res.status === 0) {
              setContainerExist(true);
              setIsAllowedToView(false);
              setPageData({ title: "This page is private" });
              setIsLoading(false);
              return;
            }
            const { container } = res.payload;
            if (window.location.href.includes(container.id)) {
              if (
                container.workType === WorkTypes.PROJECT ||
                container.workType === WorkTypes.INITIATIVE
              ) {
                window.history.replaceState(
                  null,
                  container.name,
                  `/${baseSlug}/project/${container.projectId}`
                );
              } else {
                const group = store.getState().groups.dict[container.groupId];
                if (group) {
                  window.history.replaceState(
                    null,
                    container.name,
                    `/${baseSlug}/work/${group.slug}-${container.projectId}`
                  );
                }
              }
              isFirstLoad.current++;
            }
            if (
              container.workType === WorkTypes.PROJECT ||
              container.workType === WorkTypes.INITIATIVE
            ) {
              checkPermissionsAndSetActiveContainer(
                res,
                ContainerTypes.PROJECT
              );
            } else {
              checkPermissionsAndSetActiveContainer(
                res,
                ContainerTypes.PROJECT
              );
            }
            if (!isBaseMember) {
              const { container, labels, ...baseWork } = res.payload;
              props.setBaseWork(baseWork);
              store.dispatch({
                type: actionTypes.SET_BASE_LABELS,
                labels,
              });
            }

            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setPageData({ title: "Page not found" });
            setIsLoading(false);
            setContainerExist(false);
            setIsAllowedToView(false);
            return;
          });
        break;

      case ContainerTypes.NOTE:
        fetchNote(baseSlug, containerId)
          .then((res) => {
            checkPermissionsAndSetActiveContainer(res, ContainerTypes.NOTE);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setPageData({ title: "Page not found" });
            setIsLoading(false);
            setContainerExist(false);
            setIsAllowedToView(false);
          });
        break;

      case ContainerTypes.TEMPLATE:
        fetchTemplate(baseSlug, containerId)
          .then((res) => {
            checkPermissionsAndSetActiveContainer(res, ContainerTypes.TEMPLATE);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setPageData({ title: "Page not found" });
            setIsLoading(false);
            setContainerExist(false);
            setIsAllowedToView(false);
          });
        break;
      case ContainerTypes.SNIPPET:
        fetchSnippet(baseSlug, containerId)
          .then((res) => {
            checkPermissionsAndSetActiveContainer(res, ContainerTypes.SNIPPET);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setPageData({ title: "Snippet not found" });
            setIsLoading(false);
            setContainerExist(false);
            setIsAllowedToView(false);
          });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBaseMember, containerId, userId, permissions]);

  useEffect(() => {
    const queryData = () => new URLSearchParams(location.search);
    const query = queryData();

    const blockId = query.get("discussionBlockId") || "";
    if (blockId) {
      flashBlockSubject.next({
        id: blockId,
        openComments: true,
        isDiscussion: true,
        containerId: containerId,
      });
      query.delete("discussionBlockId");
      window.history.replaceState(null, "", window.location.pathname);
    }
    return () => {
      if (paneId !== ChunkDestination.primary) return;
      store.dispatch({
        type: actionTypes.CLEAR_ACTIVE_DOCUMENT,
      });
    };
  }, []);

  const ref = useRef<any>(null);

  return (
    <>
      {isLoading ? (
        <></>
      ) : isAllowedToView ? (
        <>
          <div ref={ref} className="document-view">
            <ContainerViews
              containerId={stateContainerId}
              containerType={stateContainerType}
            />
          </div>
        </>
      ) : (
        <>
          {containerExist ? (
            <PrivatePage
              baseSlug={baseSlug}
              containerType={containerType}
              containerId={containerId}
            />
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: ClarityStore) => ({
  isBaseMember: state.client.isBaseMember,
  userId: state.user?.id,
  permissions: state.client.permissions,
});

const mapDispatchToProps = (dispatch: any) => ({
  setRefreshRedirectUrl: (url: string) =>
    dispatch({ type: actionTypes.SET_REFRESH_REDIRECT_URL, url }),
  setPrimaryActiveEntity: (container: any) =>
    dispatch({
      type: actionTypes.SET_PRIMARY_ACTIVE_ENTITY,
      entity: container,
    }),
  setBaseWork: (work: any) =>
    dispatch({
      type: actionTypes.LOAD_BASE_WORK,
      ...work,
    }),
});

const check = (prevProps: any, nextProps: any) => {
  return (
    prevProps.containerId === nextProps.containerId &&
    prevProps.groupContainerId === nextProps.groupContainerId &&
    prevProps.userId === nextProps.userId &&
    prevProps.permissions === nextProps.permissions
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(DetailView, check));
