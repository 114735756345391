import React, { useEffect, useState, useRef, MutableRefObject } from "react";

import { Form, Checkbox } from "antd";
import ClarityButton, { ButtonTypes } from "components/Button";
import Helmet from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";

// Internal Modules
import * as actionTypes from "../store/actions";
import { IServerUsernameStatus, UserEventTypes } from "../utilities/types";
import { setAccessToken } from "../utilities/authTokens";
import {
  validateEmail,
  validatePassword,
  validatePasswordLength,
  validatePasswordNumeric,
  validatePasswordUppercase,
  validateUsername,
} from "../utilities/regexUtilities";
import { useIsMobile, useOptionalClassName } from "utilities/hooks";
import ClarityLogo from "clarity-ui/ClarityLogo";

// Styles
import styles from "./logIn/logInForm/logInForm.module.scss";
import store from "store/storeExporter";
import ClarityInput from "components/ClarityInput";
import { debounce } from "lodash";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import { axiosInstance } from "index";

interface Props {
  onAcceptInvitation?: boolean;
  inviteToken?: string;
  invitationRecord?: any;
  redirectAfterLogin?: string;
  hideLogo?: boolean;
  setcurrentView?: React.Dispatch<React.SetStateAction<number>>;
  noRedirect?: boolean;
  viralSignupType?: UserEventTypes;
  onSignup?: () => void;
}

function Signup(props: Props) {
  const isMobile = useIsMobile();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const initialEmail =
    (props.invitationRecord && props.invitationRecord.invitedEmail) ||
    query.get("email") ||
    "";

  const history = useHistory();
  const usernameInputRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);

  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState<string>("");
  const [isUsernameEditing, setIsUsernameEditing] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUsernameTyping, setIsUsernameTyping] = useState<boolean>(false);
  const [isEmailValid, setisEmailValid] = useState(true);
  const [serverUsernameStatus, setServerUsernameStatus] =
    useState<IServerUsernameStatus>({ info: "", status: 1 });
  const isClientUsernameValid: boolean =
    !!username && username.length < 40 && validateUsername(username);
  const isUsernameValid =
    isClientUsernameValid &&
    serverUsernameStatus.status === 1 &&
    !!serverUsernameStatus.info;
  const isPasswordValid = validatePassword(password);
  const isFirstLoad = useRef(true);

  const checkValue = useRef(
    debounce((email: string) => {
      const isEmailValid = !email ? true : validateEmail(email);
      setisEmailValid(isEmailValid);
      if (email && isEmailValid) {
        axiosInstance
          .post("/api/user/createUniqueUsername", {
            email,
          })
          .then((res: any) => {
            if (res.data && res.data.payload) {
              if (usernameInputRef && usernameInputRef.current) {
                usernameInputRef.current.value = res.data.payload.username;
              }
              setUsername(res.data.payload.username);
            }
          });
      }
    }, 1000)
  );

  const checkUsername = useRef(
    debounce((username: string, isClientUsernameValid: boolean) => {
      if (username && isClientUsernameValid) {
        axiosInstance
          .post("/api/user/checkUsername", {
            username,
          })
          .then((res) => {
            const { data } = res;
            setServerUsernameStatus({
              info: data.info,
              status: data.status,
            });
            setIsUsernameTyping(false);
          });
      }
    }, 1000)
  );

  useEffect(() => {
    if (email) checkValue.current(email);
  }, [email]);

  useEffect(() => {
    checkUsername.current(username, isClientUsernameValid);
  }, [username, isClientUsernameValid]);

  const SignupEmailInput: MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement>(null);
  const SignupPasswordInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialEmail) {
      setEmail(initialEmail);
      SignupPasswordInput.current?.focus();
    } else SignupEmailInput.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialEmail]);

  const handleUsernameChange = (text: string) => {
    setUsername(text);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);

    const payload = props.onAcceptInvitation
      ? {
          email,
          username,
          password,
          inviteToken: props.inviteToken,
          viralSignupType: props.viralSignupType,
        }
      : { email, username, password, viralSignupType: props.viralSignupType };

    axiosInstance
      .post("/api/auth/signup", payload)
      .then(({ data }) => {
        setIsLoading(false);
        setErrorStatus(0);
        setErrorMessage("");
        if (data.accessToken) {
          setAccessToken(data.accessToken);
        }
      })
      .then(() => {
        return axiosInstance.get("/api/user");
      })
      .then((res) => {
        const { payload: user } = res.data;
        store.dispatch({ type: actionTypes.SET_AUTHENTICATED_USER, user });
        if (props.onSignup) {
          props.onSignup();
        }
        if (props.noRedirect) return;
        const redirectClause =
          props.redirectAfterLogin || query.get("redirectTo");
        if (redirectClause) {
          return history.push(redirectClause);
        }
        if (props.invitationRecord) {
          history.push(`/${props.invitationRecord.workspace.slug}`);
        } else {
          history.push(`/signup-2`);
        }
      })
      .catch((err) => {
        if (err.response) {
          const { message, status, statusCode } = err.response.data;
          setIsLoading(false);
          setErrorStatus(status || statusCode);
          setErrorMessage(message);
        } else {
          setErrorMessage("Check your internet connection.");
        }
      });
  };

  useEffect(() => {
    isFirstLoad.current = false;
  }, []);

  return (
    <div
      className={
        !props.onAcceptInvitation && !props.hideLogo
          ? `${styles.loginBg} ${styles.scrollElement}`
          : `${styles.scrollElement} ${styles.loginNoBg}`
      }
    >
      <div
        className={styles.loginFormContainer}
        style={{
          padding: "2px",
          paddingTop: isMobile
            ? "32px"
            : props.onAcceptInvitation || props.hideLogo
            ? "0"
            : "176px",
          overflowX: "hidden",
        }}
      >
        <Helmet>
          <title>Sign up • Clarity</title>
        </Helmet>
        {!props.onAcceptInvitation && !props.hideLogo && (
          <ClarityLogo
            containerStyle={({ isMobile }) => ({ marginBottom: "68px" })}
          />
        )}
        <div className={styles.loginForm}>
          <Form onSubmitCapture={handleSubmit}>
            <div className={styles.loginForm__formSection}>
              <Form.Item>
                <ClarityInput
                  label="Email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(event.currentTarget.value)
                  }
                  type="email"
                  value={email}
                  placeholder="Your email"
                  componentRef={SignupEmailInput}
                  style={{ width: "100%" }}
                  required
                  autoFocus={true}
                />

                {!isEmailValid && !!email && (
                  <ClarityLabel
                    message="Please enter a valid email address"
                    type={MessageLabelTypes.error}
                  />
                )}
              </Form.Item>
            </div>
            <div className={styles.loginForm__formSection}>
              <div className={styles.column}>
                <Form.Item>
                  <ClarityInput
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setIsUsernameTyping(true);
                      handleUsernameChange(event.currentTarget.value);
                    }}
                    prefix={<>@</>}
                    prefixCls="prf"
                    suffix={
                      <>
                        {!isUsernameEditing ? (
                          <ClarityButton
                            buttonType={ButtonTypes.LINK}
                            style={{
                              fontSize: "20px",
                              marginRight: "-2px",
                              marginTop: "-1px",
                              marginBottom: "-1px",
                            }}
                            icon={<EditOutlined />}
                            onClick={() => setIsUsernameEditing(true)}
                          />
                        ) : (
                          <ClarityButton buttonType={ButtonTypes.LINK} />
                        )}
                      </>
                    }
                    label="Username"
                    disabled={!isUsernameEditing}
                    className={
                      username.length !== 0
                        ? "inputNotDisabled"
                        : "inputDisabled"
                    }
                    placeholder="username"
                    defaultValue={username}
                    value={username}
                    componentRef={usernameInputRef}
                    required
                  />
                  {!isUsernameValid && (
                    <>
                      {username.length > 39 && (
                        <ClarityLabel
                          message={
                            "Usernames cannot be longer than 39 characters"
                          }
                          type={MessageLabelTypes.error}
                        />
                      )}
                      {!validateUsername(username) && (
                        <ClarityLabel
                          message={
                            "Usernames cannot contain spaces, periods, or most punctuation. Try again?"
                          }
                          type={MessageLabelTypes.error}
                        />
                      )}
                      {serverUsernameStatus.status === 0 &&
                        serverUsernameStatus.info && (
                          <ClarityLabel
                            message={serverUsernameStatus.info}
                            type={MessageLabelTypes.error}
                          />
                        )}
                    </>
                  )}
                  {isUsernameValid && (
                    <ClarityLabel
                      message={serverUsernameStatus.info}
                      type={MessageLabelTypes.confirm}
                    />
                  )}
                </Form.Item>
              </div>
            </div>
            <div className={styles.loginForm__formSection}>
              <Form.Item style={{ marginBottom: "18px" }}>
                <ClarityInput
                  label="Password"
                  onChange={handlePasswordChange}
                  componentRef={SignupPasswordInput}
                  type="password"
                  name="password"
                  placeholder="Your password"
                  value={password}
                  required
                />
              </Form.Item>
            </div>
            <div className={styles.passwordRequirements}>
              <PasswordRequirement
                isActive={
                  password !== "" && validatePasswordUppercase(password)
                }
                label="Contains at least one uppercase letter"
              />
              <PasswordRequirement
                isActive={password !== "" && validatePasswordNumeric(password)}
                label="Contains at least one number"
              />
              <PasswordRequirement
                isActive={password !== "" && validatePasswordLength(password)}
                label="8 or more characters in length"
              />
            </div>
            {errorStatus ? (
              <ClarityLabel
                message={errorMessage}
                type={MessageLabelTypes.error}
              />
            ) : null}
            <Form.Item style={{ marginBottom: "36px" }}>
              <ClarityButton
                buttonType={ButtonTypes.LARGE_PRIMARY}
                disabled={
                  !isEmailValid ||
                  !isUsernameValid ||
                  !isPasswordValid ||
                  isUsernameTyping
                }
                isLoading={isLoading}
                style={{ width: "100%" }}
              >
                Continue
              </ClarityButton>
            </Form.Item>
          </Form>
          <ClarityButton
            buttonType={ButtonTypes.LINK}
            style={{ width: "auto", height: "auto" }}
            onClick={() => {
              if (props.setcurrentView) {
                props.setcurrentView(1);
                return;
              }
              if (props.inviteToken) {
                return history.push(`/login?invt=${props.inviteToken}`);
              }
              const redirectClause =
                props.redirectAfterLogin || query.get("redirectTo");
              if (props.redirectAfterLogin || redirectClause) {
                return history.push(`/login?redirectTo=${redirectClause}`);
              }
              history.push(`/login`);
            }}
          >
            Already have an account? <b> Sign-in here</b>
          </ClarityButton>
        </div>
        <div className={styles.loginFormContainer__footer}></div>
      </div>
    </div>
  );
}

export const SignupContainer: React.FC = () => {
  return (
    <div className={styles.scrollElement}>
      <Signup />
    </div>
  );
};

interface IPasswordRequirementProps {
  isActive?: boolean;
  label: string;
}

function PasswordRequirement({
  isActive = false,
  label,
}: IPasswordRequirementProps) {
  const labelClassName = useOptionalClassName({
    baseStyle: styles.requirementLabel,
    pairs: [
      {
        extraStyle: styles.requirementLabelActive,
        withExtra: Boolean(isActive),
      },
    ],
  });

  return (
    <div className={styles.requirementContainer}>
      <div className={styles.requirementCheckboxContainer}>
        <Checkbox
          tabIndex={-1}
          className={styles.requirementCheckbox + " passwordRequirementBox"}
          checked={isActive}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </div>
      <span className={labelClassName}>{label}</span>
    </div>
  );
}

export const PasswordRequirements: React.FC<{ password: string }> = ({
  password,
}) => {
  return (
    <div className={styles.passwordRequirements}>
      <PasswordRequirement
        isActive={password !== "" && validatePasswordUppercase(password)}
        label="Contains at least one uppercase letter"
      />
      <PasswordRequirement
        isActive={password !== "" && validatePasswordNumeric(password)}
        label="Contains at least one number"
      />
      <PasswordRequirement
        isActive={password !== "" && validatePasswordLength(password)}
        label="8 or more characters in length"
      />
    </div>
  );
};
export default Signup;
