import Icon from "@ant-design/icons";
import React from "react";

export default function GroupsIcon(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      width="1.1em"
      height="1.1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
        fill="currentColor"
      />

      <circle opacity="0.1" cx="8" cy="8" r="6" fill="currentColor" />

      <circle cx="8.00132" cy="5.67496" r="1.42576" fill="currentColor" />

      <ellipse
        cx="5.6973"
        cy="9.6632"
        rx="1.42548"
        ry="1.42548"
        transform="rotate(-120 5.6973 9.6632)"
        fill="currentColor"
      />

      <ellipse
        cx="10.3045"
        cy="9.66276"
        rx="1.42807"
        ry="1.42807"
        transform="rotate(120 10.3045 9.66276)"
        fill="currentColor"
      />
    </svg>
  );
}
