import { snippetsApi } from "clientApi/snippetsApi";
import { checkCaretPosition } from "editor/utils/caretUtils";
import { useAbilityChecker } from "editor/utils/customHooks";
import { VALUE_DOWN, VALUE_ENTER } from "keycode-js";
import { throttle } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { moveCaretToPreviousPosition } from "utilities/caretMovement";
import { useShallowSelector } from "utilities/hooks";
import { Abilities, ISnippetObj } from "utilities/types";
import styles from "../../notes/noteTitle/noteTitle.module.scss";

const SnippetTitle: React.FC<{
  snippetId: string;
  customClassName: string;
}> = ({ snippetId, customClassName }) => {
  const snippet = useShallowSelector((store) => store.snippets.dict[snippetId]);
  if (!snippet) return <></>;

  return (
    <div className={customClassName} style={{ marginBottom: "10px" }}>
      <div
        className={styles.titleContainer__row1}
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        <SnippetTitleInput snippet={snippet} />
        {/* <SnippetDescriptionInput snippet={snippet} /> */}
      </div>
    </div>
  );
};

const SnippetTitleInput: React.FC<{ snippet: ISnippetObj }> = ({ snippet }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const canEditName = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  const onInput = (ev: React.FormEvent<HTMLDivElement>) => {
    if (ev.currentTarget.childElementCount > 1) {
      if (ref.current) {
        const caretPosition = checkCaretPosition(ref.current);
        moveCaretToPreviousPosition(ref.current, caretPosition);
        ev.currentTarget.innerHTML = ev.currentTarget.textContent as string;
      }
    }
    changeTitle(ev.currentTarget?.textContent ?? "");
  };

  const updateNoteName = async (noteName: string) => {
    snippetsApi.update({ name: noteName }, [snippet.id]);
    if (ref.current && document.activeElement === ref.current) {
      const caretPosition = checkCaretPosition(ref.current);
      moveCaretToPreviousPosition(ref.current, caretPosition);
    }
  };

  const changeTitle = useCallback(
    throttle(updateNoteName, 500, { trailing: true, leading: false }),
    []
  );

  const navigateToFirstBlock = (e: React.KeyboardEvent) => {
    const nextBlock = document.getElementsByClassName("Block")?.[1];
    if (nextBlock) {
      const content = nextBlock.getElementsByClassName("content-section")[0];
      if (content) {
        e.preventDefault();
        e.stopPropagation();
        (content as HTMLDivElement).focus();
      }
    }
  };

  const handleKeyActions = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case VALUE_DOWN:
      case VALUE_ENTER: {
        navigateToFirstBlock(e);
        break;
      }
    }
  };

  return useMemo(() => {
    return (
      <div
        contentEditable={canEditName ? true : false}
        ref={ref}
        onInput={onInput}
        placeholder={"Untitled snippet"}
        onKeyDown={handleKeyActions}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        className={`${styles.title} Block noteTitle`}
        dangerouslySetInnerHTML={{ __html: snippet?.name ?? "" }}
      ></div>
    );
  }, [snippet.id]);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SnippetDescriptionInput: React.FC<{ snippet: ISnippetObj }> = ({
  snippet,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const canEditName = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
  });

  const onInput = (ev: React.FormEvent<HTMLDivElement>) => {
    if (ev.currentTarget.childElementCount > 1) {
      if (ref.current) {
        const caretPosition = checkCaretPosition(ref.current);
        moveCaretToPreviousPosition(ref.current, caretPosition);
        ev.currentTarget.innerHTML = ev.currentTarget.textContent as string;
      }
    }
    changeTitle(ev.currentTarget?.textContent ?? "");
  };

  const updateNoteName = async (noteName: string) => {
    snippetsApi.update({ description: noteName }, [snippet.id]);

    if (ref.current && document.activeElement === ref.current) {
      const caretPosition = checkCaretPosition(ref.current);
      moveCaretToPreviousPosition(ref.current, caretPosition);
    }
  };

  const changeTitle = useCallback(
    throttle(updateNoteName, 500, { trailing: true, leading: false }),
    []
  );

  const navigateToFirstBlock = (e: React.KeyboardEvent) => {
    const nextBlock = document.getElementsByClassName("Block")?.[1];
    if (nextBlock) {
      const content = nextBlock.getElementsByClassName("content-section")[0];
      if (content) {
        e.preventDefault();
        e.stopPropagation();
        (content as HTMLDivElement).focus();
      }
    }
  };

  const handleKeyActions = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case VALUE_DOWN:
      case VALUE_ENTER: {
        navigateToFirstBlock(e);
        break;
      }
    }
  };

  return useMemo(() => {
    return (
      <div
        contentEditable={canEditName ? true : false}
        ref={ref}
        onInput={onInput}
        placeholder={"Add a description"}
        onKeyDown={handleKeyActions}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        className={`${styles.description} Block noteTitle `}
        dangerouslySetInnerHTML={{ __html: snippet?.description ?? "" }}
      ></div>
    );
  }, [snippet.id]);
};

export default SnippetTitle;
