import React from "react";
import store from "store/storeExporter";
import { message } from "antd";

const openNotificationWithIcon = (messageText: string, type?: string) => {
  if (type === "warning") return message.warning(messageText, 1);
  message.info(messageText, 1);
};

interface IModalScrimComponent {
  hideModal: () => any;
  light?: boolean;
  taskId?: string;
  customStyles?: React.CSSProperties;
  noBackground?: boolean;
}

export default function ModalScrimComponent(
  props: React.PropsWithChildren<IModalScrimComponent>
) {
  const { hideModal, customStyles } = props;
  return (
    <div
      className="modalScrimComponent"
      onClick={(e) => {
        if (props.taskId) {
          const editingTask = store.getState().work.editingTask;
          if (props.taskId === editingTask) {
            return openNotificationWithIcon(
              "There are unsaved changes in a comment",
              "warning"
            );
          }
        }
        e.preventDefault();
        e.stopPropagation();
        hideModal();
      }}
      style={customStyles}
    ></div>
  );
}
