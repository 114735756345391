import React, { useEffect, useState } from "react";
import { Form, Switch } from "antd";
import { InfoCircleOutlined, CheckOutlined } from "@ant-design/icons";

import TabLayout from "./TabLayout";
import styles from "./groups/groups.module.scss";
import { validateDomainName } from "utilities/regexUtilities";
import ClarityInput from "components/ClarityInput";
import ClarityTextArea from "components/ClarityTextArea";
import notificationsApi from "clientApi/notificationsApi";
import { groupApi } from "clientApi/groupsApi";
import {
  Abilities,
  BucketAlbums,
  GroupGeneralViews,
  IGroup,
  ViewNameType,
} from "utilities/types";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import FooterForSave from "./FooterForSave";
import {
  useDiscordIntegrationModalOpener,
  useSetBaseSettingHasChanges,
} from "store/reducers/clientReducer";
import { useAbilityChecker } from "editor/utils/customHooks";
import ChangeGroupIdentifier from "components/ChangeGroupIdentifier";
import GroupPolicy from "./groups/GroupPolicy";
import DangerZone, { DangerSections } from "./DangerZone";
import ArchiveGroup from "components/ArchiveGroup";
import PhotoUpload from "components/PhotoUpload";
import Button, { ButtonTypes } from "components/Button";
import store from "store/storeExporter";
import { ChunkDestination } from "utilities/stateTypes";
import { locationSubject } from "components/LocationListener";
import navigationApi from "clientApi/navigationApi";

const EditGroup: React.FC<{
  group: IGroup;
  onArchive: any;
}> = ({ group, onArchive }) => {
  const [existingGroup, setexistingGroup] = useState<IGroup>(group);

  const [form] = Form.useForm();

  const canManageGroups = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });

  const [dangerzoneArray, setDangerzoneArray] = useState<DangerSections>([]);

  useEffect(() => {
    if (group) {
      setexistingGroup(group);
      form.setFieldsValue({
        name: group.name,
      });
      setupDandefZone();
    }
  }, [group]);

  const setupDandefZone = () => {
    const DangerzoneArray: DangerSections = [];
    const obj = [
      {
        heading: "Change Group Identifier",
        description: (
          <>
            {" "}
            <span className="secondary">
              The identifier for this group is currently
            </span>{" "}
            <span className="bold">{group.slug}</span>
          </>
        ),
        component: <ChangeGroupIdentifier key={group.id} group={group} />,
      },
      {
        heading: "Archive this group",
        description: (
          <>
            {" "}
            <span className="secondary">
              This will remove all members from the group. The information in
              this group will still show up in search results. It will not be
              deleted. You can unarchive the group at any time.
            </span>
          </>
        ),
        component: <ArchiveGroup onArchive={onArchive} group={group} />,
      },
    ];
    //Archive group
    DangerzoneArray.push(...obj);
    setDangerzoneArray(DangerzoneArray);
  };
  const [saveChangesEnabled, setSaveChangesEnabled] = useState(false);
  useSetBaseSettingHasChanges(saveChangesEnabled, [saveChangesEnabled]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value, name } = e.currentTarget;
    if (name === "slug") {
      setexistingGroup({
        ...existingGroup,
        [name]: value.trim().toUpperCase(),
      });
      isFormChanges({ slug: value.trim().toUpperCase() });
    } else {
      let updatedValue = value;
      if (value.endsWith(" ")) {
        updatedValue = value.trim() + " ";
      }
      setexistingGroup({ ...existingGroup, [name]: updatedValue });
      isFormChanges({ name: value });
    }
  };

  const handleShowCycleChange = (value: any) => {
    setexistingGroup({
      ...existingGroup,
      showCycles: value,
    });
    isFormChanges({ showCycles: value });
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setexistingGroup({ ...existingGroup, description: value });
    isFormChanges({ des: value });
  };

  const isSlugNameValid = validateDomainName(existingGroup.slug);
  const isValidGroup =
    isSlugNameValid &&
    existingGroup.name.length > 0 &&
    existingGroup.slug.length > 0;

  const handleSaveChanges = async () => {
    groupApi.updateGroup(existingGroup, existingGroup.id).then(() => {
      notificationsApi.displayConfirmation({
        title: "Success",
        duration: 4,
        body: "Group updated",
      });
      if (group.showCycles !== existingGroup.showCycles) {
        const cycles: ViewNameType[] = [
          GroupGeneralViews.Cycles,
          GroupGeneralViews.CycleEntity,
          GroupGeneralViews.CyclesActive,
          GroupGeneralViews.CyclesClosed,
          GroupGeneralViews.CyclesNext,
        ];
        const secondaryNavChunk =
          store.getState().navigation.navigation[ChunkDestination.secondary];
        if (cycles.includes(secondaryNavChunk.viewName)) {
          navigationApi.openSplitView({
            viewName: GroupGeneralViews.GroupProfile,
            groupSlug: secondaryNavChunk.groupSlug,
          });
        }
        const primaryNavChunk =
          store.getState().navigation.navigation[ChunkDestination.primary];
        if (cycles.includes(primaryNavChunk.viewName)) {
          const baseSlug = store.getState().workspace.slug;
          locationSubject.next(`${baseSlug}/${primaryNavChunk.groupSlug}`);
        }
      }
      setSaveChangesEnabled(false);
    });
  };

  const isFormChanges = ({
    name = existingGroup.name,
    slug = existingGroup.slug,
    des = existingGroup.description,
    showCycles = existingGroup.showCycles,
    requiredRoles = existingGroup.requiredRoles,
  }) => {
    if (
      name !== group.name ||
      slug !== group.slug ||
      des !== group.description ||
      showCycles !== group.showCycles ||
      JSON.stringify(requiredRoles) !== JSON.stringify(group.requiredRoles)
    ) {
      setSaveChangesEnabled(true);
    } else {
      setSaveChangesEnabled(false);
    }
  };
  const handleReset = () => {
    form.setFieldsValue({
      name: group.name,
    });
    setSaveChangesEnabled(false);
    setexistingGroup(group);
  };
  const onUploadSucess = (photoUrl: any) => {
    setexistingGroup({ ...existingGroup, coverPhotoUrl: photoUrl });
    setSaveChangesEnabled(true);
  };

  if (!group) {
    return <></>;
  }

  return (
    <>
      <TabLayout
        title={group.name}
        description="Manage the settings for this group"
      >
        <Form form={form}>
          <div className={styles.options}>
            <div className={styles.options_inputs}>
              <Form.Item name="name" className={styles.options_margin}>
                <ClarityInput
                  value={existingGroup.name}
                  name="name"
                  onChange={handleInputChange}
                  label="Name"
                  required
                  disabled={!canManageGroups}
                />
                {existingGroup.name.length === 0 && (
                  <ClarityLabel
                    message={"Name can't be empty"}
                    type={MessageLabelTypes.error}
                  />
                )}
              </Form.Item>
            </div>
            <div>
              <Form.Item name="description">
                <ClarityTextArea
                  value={existingGroup.description}
                  className=""
                  label="Description"
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={handleTextAreaChange}
                  maxLength={200}
                  rows={2}
                  disabled={!canManageGroups}
                />
                <div>
                  <span className={`caption secondary ${styles.options_hint}`}>
                    <InfoCircleOutlined /> This tagline will be displayed on the
                    Groups list and Group Profile
                  </span>
                </div>
              </Form.Item>
            </div>
          </div>
        </Form>
        <h4 className={styles.options_avatar__heading}>Sprints</h4>
        <div className={styles.options_avatar}>
          <div>
            <Form.Item name="cycles">
              <Switch
                defaultChecked={existingGroup.showCycles}
                checkedChildren={<CheckOutlined />}
                disabled={!canManageGroups}
                checked={existingGroup.showCycles}
                onChange={handleShowCycleChange}
              />
            </Form.Item>
          </div>
        </div>
        <h4 className={styles.options_avatar__heading}>Cover Photo</h4>
        <div className={styles.options_avatar}>
          <PhotoUpload
            onUploadSucess={onUploadSucess}
            exitingUrl={existingGroup.coverPhotoUrl}
            bucketName={BucketAlbums.GROUPCOVER}
            disabled={!canManageGroups}
            fallBackComponent={
              <Button
                disabled={!canManageGroups}
                buttonType={ButtonTypes.DEFAULT}
              >
                Upload
              </Button>
            }
          />
        </div>
        <h4 className={styles.options_avatar__heading}>Connect to Discord</h4>
        <div className={styles.options}>
          <GroupDiscordConnector group={group} disabled={!canManageGroups} />
        </div>
        <GroupPolicy
          setRoles={(roles: string[]) => {
            setexistingGroup({ ...existingGroup, requiredRoles: roles });
            isFormChanges({ requiredRoles: roles });
          }}
          groupRequiredRoles={existingGroup.requiredRoles}
        />
        {canManageGroups && <DangerZone DangerSections={dangerzoneArray} />}

        {saveChangesEnabled && isValidGroup && (
          <FooterForSave onSave={handleSaveChanges} onCancel={handleReset} />
        )}
      </TabLayout>
    </>
  );
};

const GroupDiscordConnector: React.FC<{ group: IGroup; disabled: boolean }> = ({
  group,
  disabled,
}) => {
  const discordIntegrationOpener = useDiscordIntegrationModalOpener(
    true,
    group.id
  );

  const [loading, setLoading] = useState(false);

  const handleDisconnect = () => {
    setLoading(true);

    groupApi
      .removeGroupChannel(group.id)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);

        notificationsApi.displayError({
          title: "Oops something went wrong",
          body: "Sorry, we could not disconnect your group. Please try again later",
        });
      });
  };

  return (
    <>
      {!group.channelName && (
        <Button
          buttonType={ButtonTypes.DEFAULT}
          onClick={() => discordIntegrationOpener()}
          disabled={disabled}
        >
          Connect
        </Button>
      )}
      {group.channelName && (
        <div
          style={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <span className="body2">
            This group is connected to the channel: <b>{group.channelName}</b>
          </span>
          <Button
            buttonType={ButtonTypes.DEFAULT}
            style={{
              width: "fit-content",
            }}
            isLoading={loading}
            disabled={disabled}
            onClick={handleDisconnect}
          >
            Disconnect
          </Button>
        </div>
      )}
    </>
  );
};

export default EditGroup;
