import {
  IProjectObj,
  IWorkActivity,
  WorkActivityTypes,
  ContainerTypes,
  workStatusCategories,
  OrderTypes,
  GroupTypes,
  IFilterState,
  WeeklyWidgetContext,
  ViewAsTypes,
  WorkTypes,
  SplitType,
  IUserObj,
  IContributon,
  exerptNumber,
} from "utilities/types";
import store from "store/storeExporter";
import {
  UPDATE_WORK_ITEM,
  UPDATE_WORK_ACTIVITY,
  DELETE_WORK_ACTIVITY,
  SET_BLOCKS_FROM_CONTAINER,
  SAVE_BLOCK_DATA,
} from "store/actions";
import { batch } from "react-redux";
import { ChunkDestination } from "utilities/stateTypes";
import { ILineValue, LineValueType } from "utilities/lineUtilities";
import {
  clearLocalTransactionAndResetState,
  executeTransaction,
  localChangesTransaction,
  localChangesUUID,
  setLocalSnapshot,
} from "editor/utils/specificActions/persistActions";
import {
  getBlockById,
  getCurrentContext,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import {
  clearBlockSelection,
  executeTextSaveTimeout,
} from "editor/utils/blockActions";
import { stripHtml } from "utilities/stringUtilities";
import { groupBy } from "lodash";
import moment from "moment";
import { abbreviatedMonths } from "utilities/dateTime";
import { prepareStateForFrontend } from "store/reducers/workReducer";
import workApi from "clientApi/workApi";
import {
  checkAssignee,
  checkExcludedAssignee,
  checkLabels,
  checkExcludedLabels,
  checkStatuses,
  checkExcludedStatuses,
  checkDueDate,
  checkClosedDate,
  checkCreatedDate,
  checkPriority,
  checkExcludedPriority,
  checkCycle,
  checkParents,
  checkExcludedParents,
  checkExcludedCycle,
  checkWorkType,
  checkReward,
  getActiveFilters,
  checkIsDone,
  checkIsClosed,
} from "./taskServiceHelpers/filtersfnc";
import { getNetworkNameFromId } from "utilities/web3/connectors";
import {
  getContainerHtml,
  getContainerMarkdown,
  getHtml,
} from "editor/utils/blockValueHelpers";
import { axiosInstance } from "index";
const { v4: uuidv4 } = require("uuid");

export const createWorkActivity = (
  workActivityProp: any,
  workItem: IProjectObj,
  isNewTask: boolean,
  presetData?: {
    presetRootBlocks: string[];
    localChangesCopyPassed: any;
  }
) => {
  executeTextSaveTimeout();
  clearBlockSelection();
  const state = store.getState();
  const id = localChangesUUID ? localChangesUUID : uuidv4();
  const localChangesCopy = presetData
    ? presetData.localChangesCopyPassed
    : { ...localChangesTransaction };
  let workActivityText = "";

  if (workActivityProp.type === WorkActivityTypes.COMMENT) {
    if (state.user) {
      const newDate = new Date();
      const workActivity: IWorkActivity = {
        id,
        authorId: state.user?.id,
        author: state.user,
        dateCreated: newDate,
        dateModified: newDate,
        isDeleted: false,
        type: workActivityProp.type,
        taskId: workItem.id,
        blocksState: {},
      };
      const newState = store.getState().blocks;
      const context = getCurrentContext(
        newState,
        ContainerTypes.WORK_ACTIVITY + "newComment"
      );

      const roots = presetData
        ? presetData.presetRootBlocks
        : context.state.rootBlocksIds;

      workActivity.blocksState = {
        lineObject: { rootLinesId: roots },
      };

      const res = getContainerHtml(roots, newState.dict);
      const testMarkDown = getContainerMarkdown(roots, newState.dict).text
        .current;

      const fragment = res.fragment;
      let text = "";
      if (roots[0]) {
        const firstBlock = newState.dict[roots[0]];
        text = stripHtml(getHtml(firstBlock.value));
        if (roots[1] || firstBlock.children) text = text + " ...";
      }

      const el = document.createElement("div");
      el.appendChild(fragment);
      workActivityText = el.innerHTML;

      el.remove();

      const task: IProjectObj = { ...state.work.dict[workItem.id] };
      if (!task.workActivities) task.workActivities = [];
      task.workActivities = [...task.workActivities];
      task.workActivities.push(workActivity.id);
      batch(() => {
        store.dispatch({
          type: UPDATE_WORK_ACTIVITY,
          workActivity,
        });
        store.dispatch({
          type: UPDATE_WORK_ITEM,
          workItem: task,
        });

        store.dispatch({
          type: SET_BLOCKS_FROM_CONTAINER,
          param: {
            rootLinesId: roots,
            blocks: {},
            container: {
              containerId: workActivity.id,
              containerType: ContainerTypes.WORK_ACTIVITY,
            },
          },
        });
        store.dispatch({
          type: "RESET_WORK_ACTIVITY",
          destination: ChunkDestination.taskComments,
        });
      });

      axiosInstance
        .post("/api/workActivity", {
          id: workActivity.id,
          taskId: task.id,
          activityType: workActivity.type,
        })
        .then((res) => {
          const userMentionArray: string[] = [];
          const newState = store.getState().blocks;
          Object.values(
            localChangesCopy[ContainerTypes.WORK_ACTIVITY + "newComment"]
          ).forEach((item: any) => {
            if (item.id !== task.tagsBlockId) {
              item.delta.containerType = ContainerTypes.WORK_ACTIVITY;
              getUserMention(
                item.delta.value ? item.delta.value : [],
                userMentionArray
              );
              item.delta.containerId = id;
              const block = getBlockById(newState.dict, item.id);
              store.dispatch({
                type: SAVE_BLOCK_DATA,
                param: {
                  id: item.id,
                  blockData: block,
                  delta: item.delta,
                },
              });
            }
          });

          if (userMentionArray) {
            const executeAfterConfirm = () => {
              axiosInstance.post("/api/workActivity/notifyUsers", {
                notifiedUsers: userMentionArray,
                activityId: workActivity.id,
                workActivityText,
                isNewTask,
                markdown: text,
                testMarkDown,
              });
              axiosInstance.post("/api/workActivity/broadcastActivity", {
                activityId: workActivity.id,
              });
            };
            executeTransaction(localChangesCopy, executeAfterConfirm);
            // executeLocalTransaction(localChangesCopy, executeAfterConfirm);
          } else {
            const executeAfterConfirm = () => {
              axiosInstance.post("/api/workActivity/broadcastActivity", {
                activityId: workActivity.id,
              });
            };
            executeTransaction(localChangesCopy, executeAfterConfirm);
          }
        });
    }
  }
};

export const sendOnlyTaskNotification = (taskId: string) => {
  axiosInstance.post("/api/workActivity/notifyUsersTaskCreated", {
    notifiedUsers: [],
    taskId,
  });
};

const getUserMention = (value: ILineValue[], userMentionArray: string[]) => {
  value.forEach((val) => {
    if (val.type === LineValueType.usermention) {
      if (val.options.userId) userMentionArray.push(val.options.userId);
    }
    if (val.children && val.children.length > 0) {
      getUserMention(val.children, userMentionArray);
    }
  });
};

export const updateWorkActivity = (
  workActivityProp: any,
  workItem: IProjectObj
) => {
  executeTextSaveTimeout();
  const state = store.getState();
  const workActivity: IWorkActivity = workActivityProp.workActivity;
  if (workActivityProp.type === WorkActivityTypes.COMMENT) {
    if (state.user) {
      const task: IProjectObj = { ...state.work.dict[workItem.id] };
      if (!task.workActivities) task.workActivities = [];
      task.workActivities = [...task.workActivities];
      workActivity.dateModified = new Date();
      const id = workActivity.id;
      Object.values(
        localChangesTransaction[ContainerTypes.WORK_ACTIVITY + id]
      ).forEach((item: any) => {
        if (item.delta.containerId === "newComment")
          delete localChangesTransaction[item.id];
        else {
          item.delta.containerType = ContainerTypes.WORK_ACTIVITY;
          item.delta.containerId = workActivity.id;
        }
      });

      executeTransaction(
        undefined,
        undefined,
        ContainerTypes.WORK_ACTIVITY + id
      );

      axiosInstance.patch("/api/workActivity/updateTime", {
        id: workActivity.id,
      });

      batch(() => {
        store.dispatch({
          type: UPDATE_WORK_ACTIVITY,
          workActivity,
        });
        store.dispatch({
          type: UPDATE_WORK_ITEM,
          workItem: task,
        });
        store.dispatch({
          type: "RESET_WORK_ACTIVITY",
          destination: ChunkDestination.taskComments,
        });
      });
    }
  }
};

export const deleteWorkActivity = (
  workActivity: IWorkActivity,
  taskObj: IProjectObj
) => {
  const state = store.getState();
  if (workActivity.type === WorkActivityTypes.COMMENT) {
    if (state.user) {
      const task: IProjectObj = { ...state.work.dict[taskObj.id] };
      if (!task.workActivities) task.workActivities = [];
      task.workActivities = [...task.workActivities];
      const index = task.workActivities.indexOf(workActivity.id);
      if (index > -1) task.workActivities.splice(index, 1);
      axiosInstance.delete("/api/workActivity", {
        data: { id: workActivity.id },
      });
      batch(() => {
        store.dispatch({
          type: DELETE_WORK_ACTIVITY,
          workActivity,
        });
        store.dispatch({
          type: UPDATE_WORK_ITEM,
          workItem: task,
        });
        store.dispatch({
          type: "RESET_WORK_ACTIVITY",
          destination: ChunkDestination.taskComments,
        });
      });
    }
  }
};

export const addWorkActivities = (
  workActivities: IWorkActivity[],
  workItemId: string
) => {
  const state = store.getState();
  const task: IProjectObj = { ...state.work.dict[workItemId] };
  batch(() => {
    workActivities.forEach((workActivity) => {
      if (!task.workActivities) task.workActivities = [];
      task.workActivities = [...task.workActivities];
      if (!task.workActivities.includes(workActivity.id))
        task.workActivities.push(workActivity.id);
      if (workActivity.linesArray) {
        workActivity.blocksState = prepareStateForFrontend(
          workActivity.linesArray
        );
        store.dispatch({
          type: SET_BLOCKS_FROM_CONTAINER,
          param: {
            rootLinesId: workActivity.blocksState.lineObject.rootLinesId,
            blocks: workActivity.blocksState.lineObject.entities.lines,
            container: {
              containerId: workActivity.id,
              containerType: ContainerTypes.WORK_ACTIVITY,
            },
          },
        });
      }

      store.dispatch({
        type: UPDATE_WORK_ACTIVITY,
        workActivity,
      });
      store.dispatch({
        type: UPDATE_WORK_ITEM,
        workItem: task,
      });
    });
  });
};

export const updateWorkActivityFromSocket = (
  workActivities: IWorkActivity[]
) => {
  for (const workActivity of workActivities) {
    store.dispatch({
      type: UPDATE_WORK_ACTIVITY,
      workActivity,
    });
  }
};

export const clearWorkActivity = (workActivity?: any) => {
  clearLocalTransactionAndResetState(workActivity);
  store.dispatch({
    type: "RESET_WORK_ACTIVITY",
    destination: ChunkDestination.taskComments,
  });
  setLocalSnapshot(null);
};

export const sortByDateClosed = (itemsId: string[]) => {
  const workDict = store.getState().work.dict;
  itemsId.sort((a, b) => {
    const workItemA = workDict[a];
    const workItemB = workDict[b];
    const dateA = workItemA.dateClosed;
    const dateB = workItemB.dateClosed;

    if (!dateA || !dateB) return 0;
    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    return 0;
  });
  return itemsId;
};

export const sortByMetadata = (orderBy: OrderTypes, itemsId: string[]) => {
  switch (orderBy) {
    case OrderTypes.status: {
      return sortByStatusAndPriority(itemsId);
    }
    case OrderTypes.priority: {
      return setOrderByPriority(itemsId);
    }
    case OrderTypes.dueDate: {
      return setOrderByDueDate(itemsId);
    }
    case OrderTypes.closeDate: {
      return setOrderByCloseTime(itemsId);
    }
    case OrderTypes.createDate: {
      return setOrderByCreateTime(itemsId);
    }
  }
};

export const sortByStatusAndPriority = (itemsId: string[]) => {
  const workDict = store.getState().work.dict;
  const statusDict = store.getState().work.statuses.dict;
  itemsId.sort((a, b) => {
    const workItemA = workDict[a];
    const workItemB = workDict[b];
    const statusA = workItemA.statusId
      ? statusDict[workItemA.statusId] ?? {}
      : ({} as any);
    const statusB = workItemB.statusId
      ? statusDict[workItemB.statusId] ?? {}
      : ({} as any);
    const statusARank = statusA.composedRank ? statusA.composedRank : "0a";
    const statusBRank = statusB.composedRank ? statusB.composedRank : "0a";

    if (statusA.categoryId > statusB.categoryId) return -1;
    if (statusA.categoryId < statusB.categoryId) return 1;

    if (statusARank > statusBRank) return -1;
    if (statusARank < statusBRank) return 1;
    if (workItemA.priority < workItemB.priority) return 1;
    if (workItemA.priority > workItemB.priority) return -1;
    if (workItemA.projectId < workItemB.projectId) return 1;
    if (workItemA.projectId > workItemB.projectId) return -1;
    return 0;
  });
  return itemsId;
};

export const setOrderByPriority = (itemsId: string[]) => {
  const workDict = store.getState().work.dict;
  const statusDict = store.getState().work.statuses.dict;
  itemsId.sort((a, b) => {
    const workItemA = workDict[a];
    const workItemB = workDict[b];
    if (workItemA.priority < workItemB.priority) return 1;
    if (workItemA.priority > workItemB.priority) return -1;
    const statusA =
      workItemA.statusId && statusDict[workItemA.statusId]
        ? statusDict[workItemA.statusId]
        : ({} as any);
    const statusB =
      workItemB.statusId && statusDict[workItemB.statusId]
        ? statusDict[workItemB.statusId]
        : ({} as any);

    const statusARank = statusA.composedRank ? statusA.composedRank : "0a";
    const statusBRank = statusB.composedRank ? statusB.composedRank : "0a";

    if (statusA.categoryId > statusB.categoryId) return -1;
    if (statusA.categoryId < statusB.categoryId) return 1;

    if (statusARank > statusBRank) return -1;
    if (statusARank < statusBRank) return 1;

    if (workItemA.projectId < workItemB.projectId) return 1;
    if (workItemA.projectId > workItemB.projectId) return -1;
    return 0;
  });
  return itemsId;
};

export const setOrderByDueDate = (itemsId: string[]) => {
  const workDict = store.getState().work.dict;
  const statusDict = store.getState().work.statuses.dict;
  itemsId.sort((a, b) => {
    const workItemA = workDict[a] ?? {};
    const workItemB = workDict[b] ?? {};

    if (!workItemA.dueDate && workItemB.dueDate) return 1;
    if (!workItemB.dueDate && workItemA.dueDate) return -1;
    if (new Date(workItemA.dueDate ?? "") < new Date(workItemB.dueDate ?? ""))
      return -1;
    if (new Date(workItemA.dueDate ?? "") > new Date(workItemB.dueDate ?? ""))
      return 1;
    const statusA =
      workItemA.statusId && statusDict[workItemA.statusId]
        ? statusDict[workItemA.statusId]
        : ({} as any);
    const statusB =
      workItemB.statusId && statusDict[workItemB.statusId]
        ? statusDict[workItemB.statusId]
        : ({} as any);

    const statusARank = statusA.composedRank ? statusA.composedRank : "0a";
    const statusBRank = statusB.composedRank ? statusB.composedRank : "0a";

    if (statusA.categoryId > statusB.categoryId) return -1;
    if (statusA.categoryId < statusB.categoryId) return 1;

    if (statusARank > statusBRank) return -1;
    if (statusARank < statusBRank) return 1;
    if (workItemA.priority < workItemB.priority) return 1;
    if (workItemA.priority > workItemB.priority) return -1;
    if (workItemA.projectId < workItemB.projectId) return 1;
    if (workItemA.projectId > workItemB.projectId) return -1;
    return 0;
  });
  return itemsId;
};

export const setOrderByCreateTime = (itemsId: string[]) => {
  const workDict = store.getState().work.dict;
  itemsId.sort((a, b) => {
    const workItemA = workDict[a];
    const workItemB = workDict[b];

    if (new Date(workItemA.dateCreated) < new Date(workItemB.dateCreated))
      return 1;
    if (new Date(workItemA.dateCreated) > new Date(workItemB.dateCreated))
      return -1;
    if (workItemA.projectId < workItemB.projectId) return 1;
    if (workItemA.projectId > workItemB.projectId) return -1;
    return 0;
  });
  return itemsId;
};

export const setOrderByCloseTime = (itemsId: string[]) => {
  const workDict = store.getState().work.dict;
  itemsId.sort((a, b) => {
    const workItemA = workDict[a];
    const workItemB = workDict[b];

    if (
      new Date(workItemA.dateClosed ? workItemA.dateClosed : "") <
      new Date(workItemB.dateClosed ? workItemB.dateClosed : "")
    )
      return 1;
    if (
      new Date(workItemA.dateClosed ? workItemA.dateClosed : "") >
      new Date(workItemB.dateClosed ? workItemB.dateClosed : "")
    )
      return -1;
    if (workItemA.projectId < workItemB.projectId) return 1;
    if (workItemA.projectId > workItemB.projectId) return -1;
    return 0;
  });
  return itemsId;
};

export const orderIdsByKey = (itemsId: string[], groupType: GroupTypes) => {
  if (groupType === GroupTypes.status) {
    const statusDict = store.getState().work.statuses.dict;
    itemsId.sort((a, b) => {
      const statusA = statusDict[a];
      const statusB = statusDict[b];
      if (!statusA || !statusB) return 0;
      if (
        workStatusCategories[statusA.categoryId].rank <
        workStatusCategories[statusB.categoryId].rank
      )
        return -1;
      if (
        workStatusCategories[statusA.categoryId].rank >
        workStatusCategories[statusB.categoryId].rank
      )
        return 1;
      const statusARank = statusA.composedRank ? statusA.composedRank : "0a";
      const statusBRank = statusB.composedRank ? statusB.composedRank : "0a";

      if (statusARank > statusBRank) return -1;
      if (statusARank < statusBRank) return 1;
      return 0;
    });
  }
  if (groupType === GroupTypes.priority) {
    itemsId.sort((a, b) => Number(b) - Number(a));
  }
  if (groupType === GroupTypes.cycle) {
    const sectionDict = store.getState().work.sections;
    itemsId.sort((a, b) => {
      const sectionA = sectionDict[a];
      const sectionB = sectionDict[b];
      const workARank = sectionA?.rank ? sectionA.rank : "1z";
      const workBRank = sectionB?.rank ? sectionB.rank : "1z";

      if (workARank < workBRank) return -1;
      if (workARank > workBRank) return 1;
      return 0;
    });
  }
  if (groupType === GroupTypes.assignee || groupType === GroupTypes.project) {
    const index = itemsId.indexOf("null");
    if (index > -1) {
      itemsId.splice(index, 1);
      itemsId.push("null");
    }
  }
  if (
    [
      GroupTypes.weekClosed,
      GroupTypes.weekCreated,
      GroupTypes.weekDue,
    ].includes(groupType)
  ) {
    itemsId.sort(function (a, b) {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA.getTime() - dateB.getTime();
    });
    const index = itemsId.indexOf("Invalid date");
    if (index > -1) {
      itemsId.splice(index, 1);
      itemsId.push("Invalid date");
    }
  }
  return itemsId;
};

export const sortByStatusCategory = (itemsId: string[]) => {
  const statusDict = store.getState().work.statuses.dict;
  itemsId.sort((a, b) => {
    const statusA = statusDict[a];
    const statusB = statusDict[b];
    if (!statusA || !statusB) return 0;
    if (
      workStatusCategories[statusA.categoryId].id <
      workStatusCategories[statusB.categoryId].id
    )
      return -1;
    if (
      workStatusCategories[statusA.categoryId].id >
      workStatusCategories[statusB.categoryId].id
    )
      return 1;
    const statusARank = statusA.composedRank ? statusA.composedRank : "0a";
    const statusBRank = statusB.composedRank ? statusB.composedRank : "0a";

    if (statusARank > statusBRank) return -1;
    if (statusARank < statusBRank) return 1;
    return 0;
  });
  return itemsId;
};

export const groupWorkBy = (
  items: string[],
  groupByItem: GroupTypes,
  viewAs: ViewAsTypes
) => {
  const workDict = store.getState().work.dict;

  if (groupByItem === GroupTypes.none) return {};

  if (
    GroupTypes.weekDue === groupByItem ||
    GroupTypes.weekCreated === groupByItem ||
    GroupTypes.weekClosed === groupByItem
  ) {
    let key: "dueDate" | "dateCreated" | "dateClosed" = "dueDate";

    switch (groupByItem) {
      case GroupTypes.weekClosed: {
        key = "dateClosed";
        break;
      }
      case GroupTypes.weekDue: {
        key = "dueDate";
        break;
      }
      case GroupTypes.weekCreated: {
        key = "dateCreated";
        break;
      }
    }

    let groupedResults = groupBy(items, (item) => {
      const workItem = workDict[item];
      return moment(workItem[key]).add(1, "day").startOf("isoWeek");
    });

    return groupedResults;
  }

  const a = groupBy(items, function (n) {
    const workItem = workDict[n];
    return workItem[groupByItem];
  });

  if (viewAs === ViewAsTypes.board && groupByItem === GroupTypes.status) {
    const allStatuses = store.getState().work.statuses.statusArray;
    allStatuses.forEach((status) => {
      if (!a[status]) {
        (a[status] as any) = [];
      }
    });
  }

  return a;
};

export const deleteCustomView = async (id: string) => {
  await axiosInstance.delete(`/api/customWorkView`, {
    data: {
      customWorkViewId: id,
    },
  });
};

export const applyFiltersToItems = (
  itemIds: string[],
  workDict: { [id: string]: IProjectObj },
  filters: IFilterState | undefined,
  weeklyContext?: WeeklyWidgetContext | null,
  showContext: boolean = true
) => {
  if (!filters) return itemIds;
  const activeFilters = getActiveFilters({
    toFilter: filters,
    showContext,
    weeklyContext,
  });

  const filteredItemIds = itemIds.filter((id) => {
    for (const filter of activeFilters) {
      if (!filter(workDict[id])) return false;
    }
    return true;
  });

  return filteredItemIds;
};

export const applyFilters = (
  workItem: IProjectObj,
  filters: IFilterState | undefined,
  weeklyContext?: WeeklyWidgetContext | null,
  showContext: boolean = true
) => {
  const toFilter = filters;
  if (!toFilter) return true;
  return (
    checkAssignee({ toFilter, workItem }) &&
    checkExcludedAssignee({ toFilter, workItem }) &&
    checkLabels({ toFilter, workItem }) &&
    checkExcludedLabels({ toFilter, workItem }) &&
    checkStatuses({ toFilter, workItem }) &&
    checkExcludedStatuses({ toFilter, workItem }) &&
    checkDueDate({ toFilter, workItem, weeklyContext, showContext }) &&
    checkClosedDate({ toFilter, workItem, weeklyContext, showContext }) &&
    checkCreatedDate({ toFilter, workItem, weeklyContext, showContext }) &&
    checkPriority({ toFilter, workItem }) &&
    checkExcludedPriority({ toFilter, workItem }) &&
    checkCycle({ toFilter, workItem }) &&
    checkParents({ toFilter, workItem }) &&
    checkExcludedParents({ toFilter, workItem }) &&
    checkExcludedCycle({ toFilter, workItem }) &&
    checkWorkType({ toFilter, workItem }) &&
    checkReward({ toFilter, workItem }) &&
    checkIsDone({ toFilter, workItem }) &&
    checkIsClosed({ toFilter, workItem })
  );
};

export const getWorkAssigneeLabelByType = (
  workType: WorkTypes,
  capitalized?: boolean
) => {
  const options = {
    [WorkTypes.INITIATIVE]: "lead",
    [WorkTypes.PROJECT]: "lead",
    [WorkTypes.TASK]: "assignee",
  };
  let label = options[workType];
  if (capitalized) label = label.charAt(0).toUpperCase() + label.slice(1);
  return label;
};

const exportSharedFunctions = () => {
  const groupDict = store.getState().groups.dict;

  const getValue = (
    contribution: IContributon,
    splitType: SplitType,
    total: number,
    contributorsCount: number
  ) => {
    const amount = contribution.amount;
    if (contributorsCount === 1 && contribution.reason === "assignee") {
      return total;
    }
    if (splitType === SplitType.currency) return amount;
    else return (amount / 100) * total;
  };

  const checkName = (taskName: string, index: number, total: number) => {
    if (total === 1) return taskName;
    else return taskName + ` ${index + 1}/${total}`;
  };

  const checkEthNameOrKey = (contributor: IUserObj) => {
    if (contributor.secondaryWalletAddress)
      return contributor.secondaryWalletAddress;
    let address = contributor.publicEthAdress ?? "";
    if (contributor.username.includes(".eth")) address = contributor.username;
    return address;
  };

  const contributorsDisplayName = (contributor?: IUserObj) => {
    if (!contributor || !contributor.name) return "";
    return `${contributor.name}`;
  };

  const contributorUsername = (contributor?: IUserObj) => {
    if (!contributor || !contributor.username) return "";
    return `${contributor.username}`;
  };

  const getGroupName = (workItem: IProjectObj) => {
    const getGroupId = () => {
      if (workItem.workType === WorkTypes.TASK) return workItem.groupId;
      else return workItem.reward?.sponsorGroupId;
    };
    const groupId = getGroupId();
    if (groupId) return groupDict[groupId].name;
    return "";
  };

  return {
    getValue,
    checkName,
    checkEthNameOrKey,
    contributorsDisplayName,
    contributorUsername,
    getGroupName,
  };
};

export const prepareNotPaidViewExport = (
  presetFilteredWork: any[],
  name: string
) => {
  const headers = [
    "Network Name",
    "Token Address",
    "Recipient Address",
    "Amount",
    "Token",
    "Recipient Username",
    "Recipient Display Name",
    "Task ID",
    "Description",
    "Work Completed Date",
    "Group",
  ];

  const workDict = store.getState().work.dict;
  const membersDict = store.getState().members.dict;
  const items: any[] = [];

  const {
    getValue,
    checkName,
    checkEthNameOrKey,
    contributorsDisplayName,
    contributorUsername,
    getGroupName,
  } = exportSharedFunctions();

  presetFilteredWork.forEach((id) => {
    if (typeof id === "string") {
      const workItem = workDict[id];
      if (workItem) {
        const closedDate = workItem?.dateClosed
          ? new Date(workItem.dateClosed)
          : undefined;

        const reward = workItem.reward;
        if (!reward) return;
        let itemsToIterate = reward.contributors;
        if (reward && reward.contributors.length === 0) {
          itemsToIterate = workApi.rebuildContributors(
            workItem,
            reward.contributors,
            workItem
          );
        }
        const total = itemsToIterate.length;

        itemsToIterate.forEach((contribution, index) => {
          let contributor = membersDict[contribution.userId];
          if (!contributor && workItem.assignee)
            contributor = workItem.assignee;
          const amount = workItem.reward?.amount
            ? getValue(
                contribution,
                reward ? reward.splitType : SplitType.percentage,
                workItem.reward?.amount,
                total
              )
            : 0;
          items.push({
            networkName: getNetworkNameFromId(
              Number(workItem.reward?.networkId ?? 1)
            ),
            tokenAddress: workItem.reward?.contractAddress,
            receiver: contributor ? checkEthNameOrKey(contributor) : "",
            amount,
            token:
              workItem?.reward?.symbol ?? workItem?.reward?.tokenName ?? "",
            username: contributorUsername(contributor),
            name: contributorsDisplayName(contributor),
            taskId: workItem.projectId,
            taskName: checkName(workItem?.name ?? "", index, total),
            taskCompleted: closedDate
              ? `${
                  abbreviatedMonths[closedDate.getMonth()]
                } ${closedDate.getDate()}, ${closedDate.getFullYear()}`
              : "",
            group: getGroupName(workItem),
          });
        });
      }
    }
  });
  exportToCsv(name, items, headers);
};

export const preparePaidViewExport = (
  presetFilteredWork: any[],
  name: string
) => {
  const headers = [
    "Network Name",
    "Token Address",
    "Recipient Address",
    "Amount",
    "Token",
    "Recipient Username",
    "Recipient Display Name",
    "Task ID",
    "Description",
    "Work Completed Date",
    "Amount in USD",
    "Date Paid",
    "Group",
  ];
  const workDict = store.getState().work.dict;
  const membersDict = store.getState().members.dict;
  const items: any[] = [];

  const {
    getValue,
    checkName,
    checkEthNameOrKey,
    contributorsDisplayName,
    contributorUsername,
    getGroupName,
  } = exportSharedFunctions();

  const getPriceAtSnapshot = (
    amount: number,
    tokenPriceSnapshot?: number | null
  ) => {
    return exerptNumber(
      tokenPriceSnapshot ? amount * tokenPriceSnapshot : 0,
      2
    ).toFixed(2);
  };

  presetFilteredWork.forEach((id) => {
    if (typeof id === "string") {
      const workItem = workDict[id];
      if (workItem) {
        const closedDate = workItem?.dateClosed
          ? new Date(workItem.dateClosed)
          : undefined;

        const datePaid = workItem?.reward?.datePaid
          ? new Date(workItem?.reward?.datePaid)
          : undefined;
        const reward = workItem.reward;
        if (!reward) return;
        let itemsToIterate = reward.contributors;
        if (reward && reward.contributors.length === 0) {
          itemsToIterate = workApi.rebuildContributors(
            workItem,
            reward.contributors,
            workItem
          );
        }
        const total = itemsToIterate.length;

        itemsToIterate.forEach((contribution, index) => {
          let contributor = membersDict[contribution.userId];
          if (!contributor && workItem.assignee)
            contributor = workItem.assignee;

          const amount = workItem.reward?.amount
            ? getValue(
                contribution,
                reward ? reward.splitType : SplitType.percentage,
                workItem.reward?.amount,
                total
              )
            : 0;

          items.push({
            networkName: getNetworkNameFromId(
              Number(workItem.reward?.networkId ?? 1)
            ),
            tokenAddress: workItem.reward?.contractAddress,
            receiver: contributor ? checkEthNameOrKey(contributor) : "",
            amount,
            token:
              workItem?.reward?.symbol ?? workItem?.reward?.tokenName ?? "",

            username: contributorUsername(contributor),
            name: contributorsDisplayName(contributor),
            taskId: workItem.projectId,
            taskName: checkName(workItem?.name ?? "", index, total),
            taskCompleted: closedDate
              ? `${
                  abbreviatedMonths[closedDate.getMonth()]
                } ${closedDate.getDate()}, ${closedDate.getFullYear()}`
              : "",
            amountInUsd: getPriceAtSnapshot(
              amount,
              workItem.reward?.tokenUsdPrice
            ),
            datePaid: datePaid
              ? `${
                  abbreviatedMonths[datePaid.getMonth()]
                } ${datePaid.getDate()}, ${datePaid.getFullYear()}`
              : "",
            group: getGroupName(workItem),
          });
        });
      }
    }
  });
  exportToCsv(name, items, headers);
};

export const exportToCsv = (
  filename: string,
  rows: any[],
  headers?: string[]
): void => {
  if (!rows || !rows.length) {
    return;
  }
  const separator: string = ",";

  const keys: string[] = Object.keys(rows[0]);

  let columHearders: string[];

  if (headers) {
    columHearders = headers;
  } else {
    columHearders = keys;
  }

  const csvContent =
    // "sep=,\n" +
    columHearders.join(separator) +
    "\n" +
    rows
      .map((row: any) => {
        return keys
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? "" : row[k];

            cell =
              cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');

            if ((navigator as any).msSaveBlob) {
              // eslint-disable-next-line no-control-regex
              cell = cell.replace(/[^\x00-\x7F]/g, ""); //remove non-ascii characters
            }
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator);
      })
      .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  if ((navigator as any).msSaveBlob) {
    // In case of IE 10+
    (navigator as any).msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
