import { LineType } from "utilities/lineUtilities";
import { ContainerTypes } from "utilities/types";
import { BlockReducerState } from "../blockReducer";
import {
  getBlockById,
  getCurrentContext,
  getNextBlockOfLastChild,
  getNextSibling,
  getPreviousBlock,
  getPreviousSibling,
  updateCurrentBlock,
  UPDATE_TYPES,
} from "./generalBlockHelpers";

export const selectBlock = (
  newState: BlockReducerState,
  id: string,
  type: string,
  selectedBlock?: boolean,
  mouseSelection?: boolean
) => {
  newState.selectedBlocks = [...newState.selectedBlocks];
  const block = getBlockById(newState.dict, id);
  if (
    !block.selected &&
    !(
      block.lineType === LineType.Title &&
      block.referencingContainerType === ContainerTypes.NOTE
    )
  ) {
    block.selected = true;
    if (type === "up") newState.selectedBlocks.unshift(block.id);
    else newState.selectedBlocks.push(block.id);
    updateCurrentBlock(newState, block, UPDATE_TYPES.update);
    if (block.children?.length > 0 && !mouseSelection) {
      unselectChildBlocks(newState, block.id);
    }
  } else {
    if (selectedBlock) {
      const selectionIndex = newState.selectedBlocks.indexOf(id);
      const selectedNr = newState.selectedBlocks.length;
      if (type === "up") {
        if (selectedNr > 1 && selectionIndex === selectedNr - 1) {
          block.selected = false;
          newState.selectedBlocks.splice(selectionIndex, 1);
        }
      } else {
        if (selectedNr > 1 && selectionIndex === 0) {
          block.selected = false;
          newState.selectedBlocks.splice(selectionIndex, 1);
        }
      }
      updateCurrentBlock(newState, block, UPDATE_TYPES.update);
      if (block.children.length > 0 && !mouseSelection)
        unselectChildBlocks(newState, block.id);
      return;
    }
  }
};

export const unselectBlock = (
  newState: BlockReducerState,
  id: string,
  mouseSelection?: boolean
) => {
  const block = getBlockById(newState.dict, id);
  if (block.selected === true) {
    block.selected = false;
    const selectionIndex = newState.selectedBlocks.indexOf(id);
    if (selectionIndex >= 0) newState.selectedBlocks.splice(selectionIndex, 1);
    updateCurrentBlock(newState, block, UPDATE_TYPES.update);
  }
  // if (line.children && line.children.length > 0 && !mouseSelection) unselectChildLines(lineObject, line.id);
};

const unselectChildBlocks = (newState: BlockReducerState, parentId: string) => {
  const parentLine = getBlockById(newState.dict, parentId);
  const childCopy = [...parentLine.children];
  const reverse = childCopy.reverse();
  reverse.map((childId) => {
    unselectBlock(newState, childId);
    unselectChildBlocks(newState, childId);
    return childId;
  });
};

export const checkSelectingUpCase = (
  newState: BlockReducerState,
  id: string,
  context: any
) => {
  const currentContext = getCurrentContext(newState, context.id);
  const block = getBlockById(newState.dict, id);
  const prevSiblingId = getPreviousSibling(newState, block, currentContext);
  if (prevSiblingId) {
    const prevBlock = getBlockById(newState.dict, prevSiblingId);
    if (prevBlock.selected) {
      return prevSiblingId;
    }
  }
  return getPreviousBlock(newState, block, currentContext);
};

export const checkSelectingDownCase = (
  newState: BlockReducerState,
  id: string,
  context: any
) => {
  const block = getBlockById(newState.dict, id);
  const currentContext = getCurrentContext(
    newState,
    block.containerType + block.containerId
  );
  const nextSiblingId = getNextSibling(newState, block, currentContext);
  return nextSiblingId
    ? nextSiblingId
    : getNextBlockOfLastChild(newState, id, currentContext);
};

export const executeUnselectBlocksAction = (newState: BlockReducerState) => {
  const selectedBlocks = [...newState.selectedBlocks];
  newState.selectedBlocks = [...newState.selectedBlocks];
  const reverse = selectedBlocks.reverse();
  for (const id of reverse) {
    unselectBlock(newState, id);
  }
  return newState;
};
