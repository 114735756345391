import React, { useMemo } from "react";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import styles from "./label/clarityLabel.module.scss";

export enum MessageLabelTypes {
  error = "error",
  confirm = "confirm",
  warn = "warn",
  success = "success",
  info = "info",
}

const ClarityLabel: React.FC<{
  message: string;
  type: MessageLabelTypes;
  customStyles?: React.CSSProperties;
}> = ({ message, type, customStyles }) => {
  const details = useMemo(() => {
    if (type === MessageLabelTypes.error) {
      return {
        icon: <CloseCircleOutlined />,
        class: styles.errorTextLabel,
      };
    }
    if (type === MessageLabelTypes.confirm) {
      return {
        icon: <CheckCircleOutlined />,
        class: styles.confirmTextLabel,
      };
    }
    if (type === MessageLabelTypes.success) {
      return {
        icon: <CheckCircleOutlined />,
        class: styles.successTextLabel,
      };
    }
    if (type === MessageLabelTypes.info) {
      return {
        icon: <InfoCircleOutlined />,
        class: styles.infoTextLabel,
      };
    }
    return {
      icon: "",
      class: "",
    };
  }, [type]);

  return (
    <label className={`${styles.shared} ${details.class}`} style={customStyles}>
      <span style={{ marginRight: "6px" }}>{details.icon}</span>
      {message}
    </label>
  );
};

export default ClarityLabel;
