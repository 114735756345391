import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/storeExporter";
import { CookiesProvider } from "react-cookie";
import * as dotenv from "dotenv";
import { IntercomProvider } from "react-use-intercom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import swConfig from "utilities/serviceWorkerHelpers";
// eslint-disable-next-line import/extensions
import "./polyfills/focusPreventScroll.js";
import { setTwoToneColor } from "@ant-design/icons";
import { refreshTheToken } from "utilities/authTokens";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from "axios";

setTwoToneColor(["rgba(49, 44, 48, 0.65)", "rgba(49, 44, 48, 0.065)"]);

const INTERCOM_APP_ID = "o5ppiyed";

dotenv.config();
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ""
);

export const axiosInstance = axios.create();

createAuthRefreshInterceptor(axiosInstance, refreshTheToken, {
  statusCodes: [401, 403],
  pauseInstanceWhileRefreshing: true,
  shouldRefresh: (error) => {
    const res = error.response;
    if (res?.status === 401) {
      if (res.data.message === "Unauthorized") return true;
    }
    if (res?.status === 403) {
      if (res.data.message === "The access token expired") return true;
    }
    return false;
  },
});

ReactDOM.render(
  <CookiesProvider>
    <Elements stripe={stripePromise}>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </IntercomProvider>
    </Elements>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register(swConfig);
serviceWorker.unregister();
