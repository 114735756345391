import { Checkbox } from "antd";
import { tokenApi } from "clientApi/tokenApi";
import { web3Api } from "clientApi/web3Api";
import Button, { ButtonTypes } from "components/Button";
import ClarityInput, { InputLabel } from "components/ClarityInput";
import { throttle } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import store, { ClarityStore } from "store/storeExporter";
import { Abilities, IToken, TokenTypes } from "utilities/types";
import ClarityLabel, { MessageLabelTypes } from "./ClarityLabel";
import Modal from "./Modal";
import styles from "./tokenSection/tokenSection.module.scss";
import { EditOutlined } from "@ant-design/icons";
import DefaultTokenIcon from "icons/icon-token-default.svg";
import { BucketAlbums } from "utilities/types";
import short from "short-uuid";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAbilityChecker } from "editor/utils/customHooks";
import TabLayout from "../screens/base/main/settings/TabLayout";
import NetworkSelect from "components/NetworkSelect";
import ClaritySelect from "components/ClaritySelect";
import ItemDescription from "./ItemDescription";
import { getNetworkNameFromId } from "utilities/web3/connectors";
import { axiosInstance } from "index";
const { v4: uuidv4 } = require("uuid");

const tokenTypeObj = {
  [TokenTypes.ERC20]: "Token (ERC-20)",
  [TokenTypes.ERC721]: "NFT (ERC-721 or ERC-1155)",
  [TokenTypes.ERC1155]: "ERC-1155",
  [TokenTypes.NATIVE_CURRENCY]: "Balance",
  [TokenTypes.MANUAL]: "Manual",
  [TokenTypes.MOLOCH]: "Moloch Shares",
  // [TokenTypes.NATIVE_CURRENCY]: "Token",
};

export const tokenTypeArray = [
  {
    value: TokenTypes.ERC20,
    label: tokenTypeObj[TokenTypes.ERC20],
  },
  {
    value: TokenTypes.ERC721,
    label: tokenTypeObj[TokenTypes.ERC721],
  },
];

const TokenSection: React.FC = () => {
  const [addingToken, setaddingToken] = useState(false);
  const tokens = useSelector(
    (state: ClarityStore) => state.tokens,
    shallowEqual
  );

  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_TOKENS,
  });

  const [tokenIds, setTokenIds] = useState<string[]>([]);

  useEffect(() => {
    const ids = tokens.ids.filter(
      (id) => !["1", "2", "3", "4", "5", "6"].includes(id)
    );
    setTokenIds(ids);
  }, [tokens.ids]);

  return (
    <>
      <TabLayout
        title="Tokens"
        description="Manage the tokens available for use with rewards"
        // actionBtn={
        //   <Button
        //     buttonType={ButtonTypes.PRIMARY}
        //     disabled={!canEditSettings}
        //     onClick={() => setaddingToken(!addingToken)}
        //   >
        //     <PlusOutlined /> Token
        //   </Button>
        // }
      >
        {tokenIds.length === 0 && (
          <div className="caption secondary">
            No custom tokens in this base yet
          </div>
        )}
        {tokenIds.length > 0 && (
          <div className="label bold secondary mb-5">TOKENS</div>
        )}
        <div>
          {tokenIds.map((id) => (
            <CustomTokenRow
              key={id}
              id={id}
              canEditSettings={canEditSettings}
            />
          ))}
        </div>

        {addingToken && (
          <Modal
            size="medium"
            padding={false}
            hideModal={() => setaddingToken(false)}
          >
            <AddOrEditToken setaddingToken={setaddingToken} />
          </Modal>
        )}
      </TabLayout>
    </>
  );
};

enum Problems {
  AlreadyExists = "This token is already in your base",
  AddressNotCorrect = "The contract address does not match a valid address",
}

const AddOrEditToken: React.FC<{
  setaddingToken: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
}> = ({ setaddingToken, id }) => {
  const existingToken = useSelector(
    (store: ClarityStore) => (id ? store.tokens.dict[id] : undefined),
    shallowEqual
  );

  const initialTokenValue = {
    name: "",
    networkId: web3Api.networkId,
    contractAddress: "",
    symbol: "",
    type: TokenTypes.ERC20,
    icon: "",
    id: existingToken ? existingToken.id : uuidv4(),
    default: false,
    baseId: store.getState().workspace.id,
  };

  const isFirstLoad = useRef(true);

  const [token, settoken] = useState<IToken>(initialTokenValue);

  const [hasProblem, sethasProblem] = useState(false);
  const probelmText = useRef(Problems.AddressNotCorrect);

  const updateToken = (update: Partial<IToken>, debug?: boolean) => {
    settoken({ ...token, ...update });
  };

  const updating = (contractAddress: string) => {
    sethasProblem(false);
    const exists = tokenApi.checkIfAddressAlreadyAdded(
      contractAddress,
      token.networkId
    );
    if (exists) {
      probelmText.current = Problems.AlreadyExists;
      sethasProblem(true);
      return;
    }
    web3Api
      .checkIfTokenExists(token.networkId, contractAddress, token.type)
      .then((res) => {
        if (res && res.name) {
          updateToken({
            contractAddress,
            name: res.name,
            symbol: res.symbol,
            icon: res.icon,
          });
          sethasProblem(false);
        } else {
          probelmText.current = Problems.AddressNotCorrect;
          sethasProblem(true);
        }
      });
  };

  const handleChangeAddress = useCallback(throttle(updating, 50), [token]);

  const onConfirm = () => {
    if (!existingToken) tokenApi.save(token);
    else tokenApi.update(token.id, token);
    setaddingToken(false);
  };

  const onDelete = () => {
    tokenApi.delete(token.id);
  };

  const updateTokenIcon = (iconUrl: string) => {
    updateToken({
      icon: iconUrl,
    });
  };

  const chengeNetwork = (networkId: number) => {
    settoken({
      ...initialTokenValue,
      networkId,
    });
  };

  const handleTokenTypeChange = (tokenType: TokenTypes) => {
    settoken({
      ...initialTokenValue,
      networkId: token.networkId,
      type: tokenType,
    });
  };

  useEffect(() => {
    if (!isFirstLoad.current) return;
    if (!existingToken) return;
    settoken({ ...token, ...existingToken });
    isFirstLoad.current = false;
  }, [existingToken]);

  if (!token) return <></>;
  return (
    <div className={styles.tokenModalContainer}>
      <div className={styles.headerContainer}>
        <h3>{existingToken ? "Update token" : "Add token"}</h3>
        <div>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              gap: "12px",
              justifyContent: "end",
            }}
          >
            {existingToken && <Button onClick={onDelete}>Delete</Button>}
            <Button
              disabled={
                !token.name ||
                !token.symbol ||
                token.name === "" ||
                token.symbol === "" ||
                hasProblem ||
                token.contractAddress.length < 20 ||
                (existingToken ? shallowEqual(token, existingToken) : false)
              }
              buttonType={ButtonTypes.PRIMARY}
              onClick={onConfirm}
            >
              {existingToken ? "Save changes" : "Save token"}
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.option}>
            <NetworkSelect
              selectedNetworkId={token.networkId}
              disabled={Boolean(existingToken)}
              onSelectionChanged={chengeNetwork}
            />
          </div>
          <div className={styles.option}>
            <label className={styles.label}>Token Type</label>
            <ClaritySelect
              onChange={handleTokenTypeChange}
              disabled={Boolean(existingToken)}
              value={token.type}
              defaultValue={token.type}
              options={tokenTypeArray}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.option}>
            <ClarityInput
              placeholder="Search or paste contract address”"
              label="Contract Address"
              value={token.contractAddress}
              disabled={existingToken ? true : false}
              onChange={(e) => {
                updateToken({
                  contractAddress: e.currentTarget.value,
                });
                handleChangeAddress(e.target.value);
              }}
            />
            {hasProblem &&
              token.type &&
              token.contractAddress &&
              token.contractAddress?.length > 20 && (
                <ClarityLabel
                  message={probelmText.current}
                  type={MessageLabelTypes.error}
                />
              )}
          </div>
        </div>

        {token.symbol && (
          <>
            <div className={styles.row}>
              <div className={styles.option}>
                <ClarityInput
                  label="Name"
                  placeholder={token.name || "Search or paste contract address"}
                  disabled={true}
                />
              </div>
              <div className={styles.option}>
                <ClarityInput
                  label="Symbol"
                  placeholder={
                    token.symbol || "Search or paste contract address"
                  }
                  disabled={true}
                />
              </div>
            </div>
            <div className={styles.row} style={{ alignItems: "center" }}>
              <div className={styles.option}>
                <InputLabel label="Icon" />
                <AddTokenIcon
                  updateAvatar={updateTokenIcon}
                  avatar={token.icon ?? ""}
                />
              </div>
              <div className={styles.option}>
                <Checkbox
                  style={{ marginLeft: "4px", marginTop: "20px" }}
                  checked={token.default}
                  onChange={() => updateToken({ default: !token.default })}
                >
                  <span className="caption regular secondary">
                    Set as default
                  </span>
                </Checkbox>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CustomTokenRow: React.FC<{ id: string; canEditSettings: boolean }> = ({
  id,
  canEditSettings,
}) => {
  const token = useSelector(
    (state: ClarityStore) => state.tokens.dict[id],
    shallowEqual
  );

  const [addingToken, setaddingToken] = useState(false);
  if (!token) return <></>;
  return (
    <>
      <div className={styles.tokenRow}>
        <div className={styles.header}>
          <TokenIcon icon={token.icon} size={32} />
          <ItemDescription
            noPadding={true}
            title={token.symbol}
            description={getTokenDetails(token)}
          />
        </div>
        <div className={styles.tail}>
          <Button
            buttonType={ButtonTypes.LINK}
            icon={<EditOutlined />}
            disabled={!canEditSettings}
            onClick={() => setaddingToken(true)}
          />
        </div>
      </div>

      {addingToken && (
        <Modal
          size="medium"
          padding={false}
          hideModal={() => setaddingToken(false)}
        >
          <AddOrEditToken setaddingToken={setaddingToken} id={id} />
        </Modal>
      )}
    </>
  );
};

export const TokenIcon: React.FC<{ icon?: string; size?: number }> = ({
  icon,
  size,
}) => {
  return (
    <img
      height={size ?? 20}
      width={size ?? 20}
      style={{
        marginRight: "10px",
      }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = DefaultTokenIcon;
      }}
      src={icon ?? DefaultTokenIcon}
      alt={"token"}
    />
  );
};

const AddTokenIcon: React.FC<{
  avatar: string;
  updateAvatar: (avatar: string) => void;
}> = ({ avatar, updateAvatar }) => {
  const imageContainer = useRef<HTMLDivElement | null>(null);
  const inputAvatarUpload = useRef<HTMLInputElement | null>(null);
  const [isAvatarUploadWaiting, setIsAvatarUploadWaiting] = useState(false);

  const handleUploadImageClick = (input: any) => {
    input.current.click();
  };

  const handleUploadImageChange = (input: any, imageContainer: any) => {
    const image = input.current.files[0];
    if (image.size / 1024 / 1024 >= 5) {
      alert("Please upload the image less than 5MB");
      return;
    }
    const splitFilename = image.name.split(".");
    setIsAvatarUploadWaiting(true);
    const fileExtension = splitFilename[splitFilename.length - 1];

    const photoKey = `${
      BucketAlbums.TOKEN
    }/${short.generate()}.${fileExtension}`;
    imageContainer.current.style.backgroundImage = `url(${URL.createObjectURL(
      image
    )})`;

    axiosInstance
      .post(`/api/auth/getSignedUrl`, { key: photoKey })
      .then((res) => {
        const signedUrl = res.data.uploadUrl;
        const authorizationHeader =
          axiosInstance.defaults.headers.common["Authorization"];
        delete axiosInstance.defaults.headers.common["Authorization"];
        axiosInstance
          .put(signedUrl, image, {
            headers: {
              "content-type": "image/*",
            },
          })
          .then((res) => {
            const hostedUrl = signedUrl.split("?")[0];
            updateAvatar(hostedUrl);
            axiosInstance.defaults.headers.common.Authorization =
              authorizationHeader;
            setIsAvatarUploadWaiting(false);
          });
      });
  };

  return (
    <div
      className={`${styles.row} ${styles.smallGap}`}
      style={{ alignItems: "center" }}
    >
      <div className={styles.Image}>
        <div
          className={`${styles.tokenitem} ${
            isAvatarUploadWaiting ? styles.profileUploading : ""
          } `}
          style={{
            backgroundImage: `url(${
              avatar && avatar !== "" ? avatar : DefaultTokenIcon
            })`,
          }}
          ref={imageContainer}
        >
          <div
            className={styles.overlay}
            onClick={() => handleUploadImageClick(inputAvatarUpload)}
          >
            {isAvatarUploadWaiting ? (
              <LoadingOutlined />
            ) : (
              <CloudUploadOutlined color="#fff" />
            )}
          </div>
        </div>
        <input
          onChange={() =>
            handleUploadImageChange(inputAvatarUpload, imageContainer)
          }
          type="file"
          accept=".jpg, .jpeg, .png"
          ref={inputAvatarUpload}
          style={{ display: "none" }}
        />
      </div>

      <span className="small disabled">
        For best results, upload an image that is at least 32px by 32px.
      </span>
    </div>
  );
};

const getTokenDetails = (token: IToken) => {
  return `${token.name} • ${getNetworkNameFromId(token.networkId)} • ${
    tokenTypeObj[token.type]
  }`;
};

export default TokenSection;
