import { axiosInstance } from "index";
import store from "store/storeExporter";
import { UserEventTypes } from "utilities/types";

class EventApi {
  postEvent(eventType: UserEventTypes, properties: any) {
    const userId = store.getState().user?.id ?? "Anonymous";

    axiosInstance
      .post("/api/event", {
        eventType: eventType,
        userId: userId,
        properties: { ...properties },
      })
      .catch((e) => {
        console.log("e", e);
      });
  }
}

export default new EventApi();
