import Button, { ButtonTypes } from "components/Button";
import { locationSubject } from "components/LocationListener";
import React, { Suspense, useCallback, useState } from "react";
import { useOptionalClassName } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import {
  GeneralViewsNames,
  GroupGeneralViews,
  HomeScreenObj,
  HomeSectionObj,
  HomeSectionRow,
  SectionResourceType,
  TasksViewModes,
} from "utilities/types";
import styles from "./pinsBoard/pinsBoard.module.scss";
import { AuditOutlined, ArrowRightOutlined } from "@ant-design/icons";
import EditTwoTone from "icons/Components/EditTwoTone";
import Modal from "clarity-ui/Modal";
import { Skeleton } from "antd";
import ReactDOM from "react-dom";
import navigationApi from "clientApi/navigationApi";
import { getIconFromNavigationChunk } from "utilities/iconHelpers";
import ControlTwoTone from "icons/Components/ControlTwoTone";
import { navigateToUrl } from "utilities/paneNavigationUtils";
import CollectionsEditor from "./pinsBoard/CollectionsEditor";
import {
  getContainerByType,
  getNameFromContainer,
} from "modules/containerHelpers";
import ScheduleTwoTone from "icons/Components/ScheduleTwoTone";
import TagsTwoTone from "icons/Components/TagsTwoTone";
import SnippetsTwoTone from "icons/Components/SnippetsTwoTone";
import StarTwoTone from "icons/Components/StarTwoTone";
const PinsEditor = React.lazy(() => import("./pinsBoard/PinsEditor"));

interface IPinsBoardProps {
  mode: "group" | "base";
  sections: HomeScreenObj;
  paneId: ChunkDestination;
  addPin: (item: HomeSectionRow, sectionId: string) => void;
  reorderPins: (item: string[], sectionId: string) => void;
  updateOrAddCollection: (item: HomeSectionObj) => void;
  reorderCollection: (ids: string[]) => void;
  deleteCollection: (id: string) => void;
  deletePin: (itemId: string, sectionId: string) => void;
}

const PinsBoard: React.FC<IPinsBoardProps> = ({
  sections,
  paneId,
  addPin,
  updateOrAddCollection,
  reorderPins,
  reorderCollection,
  deleteCollection,
  deletePin,
}) => {
  const classNames = useOptionalClassName({
    baseStyle: styles.board,
    pairs: [],
  });
  const [showEditCollection, setshowEditCollection] = useState(false);
  const close = useCallback(() => {
    setshowEditCollection(false);
  }, []);

  return (
    <div className={classNames}>
      {sections?.sectionIds?.length === 0 && (
        <div
          className={styles.section}
          style={{
            paddingTop: "20px",
          }}
        >
          <span
            className={`disabled body2 regular ${styles.openCollectionEditor}`}
          >
            Use collections to showcase important links and documents.{" "}
            <span
              style={{
                textDecoration: "underline",
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setshowEditCollection(true);
              }}
            >
              Create a collection
            </span>
          </span>
        </div>
      )}
      {sections.sectionIds?.map((sectionId) => (
        <PinsSection
          key={sectionId}
          section={sections.sectionsDict[sectionId]}
          paneId={paneId}
          collections={sections}
          addPin={addPin}
          updateOrAddCollection={updateOrAddCollection}
          reoderPins={reorderPins}
          reorderCollection={reorderCollection}
          deleteCollection={deleteCollection}
          deletePin={deletePin}
        />
      ))}
      {showEditCollection &&
        ReactDOM.createPortal(
          <Modal size="medium" hideModal={close}>
            <Suspense fallback={<Skeleton avatar={false} />}>
              <CollectionsEditor
                collections={sections}
                updateOrAddCollection={updateOrAddCollection}
                reorderCollection={reorderCollection}
                deleteCollection={deleteCollection}
              />
            </Suspense>
          </Modal>,
          document.body
        )}
    </div>
  );
};

const PinsSection: React.FC<{
  section: HomeSectionObj;
  collections: HomeScreenObj;
  paneId: ChunkDestination;
  addPin: (item: HomeSectionRow, sectionId: string) => void;
  reoderPins: (item: string[], sectionId: string) => void;
  updateOrAddCollection: (item: HomeSectionObj) => void;
  reorderCollection: (ids: string[]) => void;
  deleteCollection: (id: string) => void;
  deletePin: (itemId: string, sectionId: string) => void;
}> = ({
  section,
  collections,
  paneId,
  addPin,
  updateOrAddCollection,
  reoderPins,
  reorderCollection,
  deleteCollection,
  deletePin,
}) => {
  const [showEditCollection, setshowEditCollection] = useState(false);
  const close = useCallback(() => {
    setshowEditCollection(false);
  }, []);

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <span className={styles.titleText}>{section.title}</span>
        <Button
          className={styles.hiddenButton}
          onClick={() => {
            setshowEditCollection(true);
          }}
          buttonType={ButtonTypes.DEFAULT}
          icon={<EditTwoTone />}
        />
      </div>
      {section?.resourcesIds?.length === 0 && (
        <div>
          <span className={"body2 disabled"}>
            There are no resources in this collection
          </span>
        </div>
      )}
      <div className={styles.pinContainer}>
        {section.resourcesIds?.map((id, index) => {
          if (id && section.resourcesDict[id])
            return (
              <PinEntry
                key={id}
                pin={section.resourcesDict[id]}
                paneId={paneId}
              />
            );
          return <React.Fragment key={index}></React.Fragment>;
        })}
      </div>
      {showEditCollection &&
        ReactDOM.createPortal(
          <Modal size="medium" hideModal={close}>
            <Suspense fallback={<Skeleton avatar={false} />}>
              <PinsEditor
                collectionId={section.id}
                saveAction={() => null}
                close={close}
                collection={section}
                collections={collections}
                addPin={addPin}
                updateOrAddCollection={updateOrAddCollection}
                reorderPins={reoderPins}
                reorderCollection={reorderCollection}
                deleteCollection={deleteCollection}
                deletePin={deletePin}
              />
            </Suspense>
          </Modal>,
          document.body
        )}
    </div>
  );
};

const PinEntry: React.FC<{
  pin: HomeSectionRow;
  paneId: ChunkDestination;
}> = ({ pin, paneId }) => {
  const handleNavigation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (pin.type === SectionResourceType.InternalLink) {
      const navigateMain = () => {
        if (!pin.navigationChunk) return;
        const to = navigationApi.getLinkFromNavigationChunk(
          pin.navigationChunk
        );
        locationSubject.next(to);
      };
      const navigateSplit = () => {
        if (!pin.navigationChunk) return;
        navigationApi.openSplitViewNavigationChunk(pin.navigationChunk);
      };
      navigateToUrl(e, paneId, navigateMain, navigateSplit);
    } else {
      if (!pin.link) return;
      window?.open(pin.link, "_blank")?.focus();
    }
  };

  const container =
    pin.navigationChunk?.entity && pin.navigationChunk?.entity?.containerType
      ? getContainerByType({
          containerId: pin.navigationChunk?.entity?.containerId,
          containerType: pin.navigationChunk?.entity?.containerType,
        })
      : undefined;

  const name =
    container && pin.navigationChunk?.entity?.containerType
      ? getNameFromContainer(
          container,
          pin.navigationChunk?.entity?.containerType
        )
      : pin.name;

  return (
    <div className={styles.pin} onClick={(e) => handleNavigation(e)}>
      <span className="caption medium secondary">
        <IconSwitcher pin={pin} />
      </span>
      <span className={styles.pinTitle}>{name}</span>
    </div>
  );
};

const IconSwitcher: React.FC<{ pin: HomeSectionRow }> = ({ pin }) => {
  const entityType = pin.navigationChunk?.entity?.containerType;
  if (pin.type === SectionResourceType.OutsideLink)
    return <ArrowRightOutlined className={styles.externalLinkIcon} />;
  if (!pin.navigationChunk) return <></>;
  if (
    !entityType &&
    pin?.navigationChunk?.viewName === GeneralViewsNames.Roadmap
  )
    return <ScheduleTwoTone />;
  if (!entityType && pin?.navigationChunk?.viewName === GeneralViewsNames.Views)
    return <ControlTwoTone />;
  if (
    !entityType &&
    pin?.navigationChunk?.viewName === GeneralViewsNames.TheWeekly
  )
    return <StarTwoTone />;
  if (!entityType && pin?.navigationChunk?.viewName === GeneralViewsNames.Wiki)
    return <TagsTwoTone />;
  if (!entityType && pin?.navigationChunk?.viewName === GroupGeneralViews.Notes)
    return <SnippetsTwoTone />;
  if (!entityType && !pin.navigationChunk.taskViewMode) return <></>;
  if (pin.navigationChunk.taskViewMode === TasksViewModes.MyTasks)
    return <AuditOutlined />;
  if (!entityType && pin.navigationChunk.taskViewMode)
    return <ControlTwoTone />;
  if (!entityType) return <></>;

  return getIconFromNavigationChunk(pin.navigationChunk);
};

export default PinsBoard;
