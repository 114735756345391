import { locationSubject } from "components/LocationListener";
import { useLayoutEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { usePaneId } from "store/reducers/filterReducer";
import { useBaseSlug, useBaseType } from "store/reducers/workspaceReducer";
import { ClarityStore } from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import {
  BaseType,
  GeneralViewsNames,
  UserRole,
  ViewNameType,
} from "utilities/types";

const guestViews: ViewNameType[] = [
  GeneralViewsNames.MyTasks,
  GeneralViewsNames.MyProjects,
  GeneralViewsNames.MyProjectsArchive,
];
export const useGuestVisibility = () => {
  const paneId = usePaneId();
  const navigationChunk = useShallowSelector((state) => {
    if (
      state.navigation &&
      state.navigation.navigation &&
      state.navigation?.navigation[paneId]
    )
      return state.navigation?.navigation[paneId];
  });

  const userRole = useSelector(
    (state: ClarityStore) => state.client.roleType,
    shallowEqual
  );

  const baseSlug = useBaseSlug();

  const baseType = useBaseType();

  useLayoutEffect(() => {
    if (baseType === BaseType.Public) {
      return;
    }
    if (
      userRole === UserRole.GUEST &&
      navigationChunk?.viewName &&
      !guestViews.includes(navigationChunk?.viewName)
    ) {
      locationSubject.next(`/${baseSlug}/my-work`);
    }
  }, []);
};
