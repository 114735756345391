import React from "react";
import { useShallowSelector } from "utilities/hooks";
import Conditional from "./Conditional";
import { ReactComponent as GroupsIcon } from "icons/menu/Groups.svg";
import Icon from "@ant-design/icons";

const ProjectsGroupsDisplay: React.FC<{
  groupIds: string[];
  showIcon?: boolean;
}> = ({ groupIds, showIcon = true }) => {
  const groups = useShallowSelector((store) => store.groups.dict);
  return (
    <span className="exerpt caption valueText">
      {showIcon && (
        <Icon component={GroupsIcon} style={{ marginRight: "4px" }} />
      )}
      <Conditional on={groupIds.length === 1}>
        <>{groups[groupIds[0]]?.name}</>
      </Conditional>
      <Conditional on={groupIds.length > 1}>
        <>{groupIds.length} groups</>
      </Conditional>
    </span>
  );
};

export default ProjectsGroupsDisplay;
