import styles from "./groups.module.scss";
import { Radio, Space } from "antd";
import ClaritySelect, { IOptions } from "components/ClaritySelect";
import { useShallowSelector } from "utilities/hooks";
import React, { useEffect, useState } from "react";

const GroupPolicy: React.FC<{
  setRoles: (roles: string[]) => void;
  groupRequiredRoles: string[];
}> = ({ setRoles, groupRequiredRoles }) => {
  const [roleOptions, setRoleOptions] = useState<IOptions[]>([]);

  const [policy, setPolicy] = useState(groupRequiredRoles.length > 0 ? 2 : 1);

  const roles = useShallowSelector((state) =>
    state.roles.ids.map((id) => state.roles.dict[id])
  );

  useEffect(() => {
    setPolicy(groupRequiredRoles.length > 0 ? 2 : 1);
  }, [groupRequiredRoles]);

  useEffect(() => {
    const noShowRoles = ["Guest", "Owner", "No Role"];

    const roleOptions = roles
      .filter((role) => !noShowRoles.includes(role.roleName))
      .filter((role) => role.roleType !== "personal")
      .map((role) => ({
        value: role.id,
        label: role.roleName,
      }));
    setRoleOptions(roleOptions);
  }, [roles]);

  return (
    <div className={styles.group_privacy}>
      <h4 className={styles.group_privacy__heading}>Group Privacy</h4>
      <Radio.Group
        value={policy}
        onChange={(e) => {
          setPolicy(e.target.value);
        }}
      >
        <Space direction="vertical">
          <Radio value={1}>
            <div className={styles.group_privacy_descriptionHeading}>Open</div>
            <div className={styles.group_privacy_description}>
              Open groups can be seen and joined by all members of the base
            </div>
          </Radio>
          <Radio value={2}>
            <div className={styles.group_privacy_descriptionHeading}>
              Closed
            </div>
            <div className={styles.group_privacy_description}>
              Closed groups can be seen by all members in the Groups list, but
              only specific roles can join them and see their contents
            </div>
          </Radio>
          {policy === 2 && (
            <div>
              <ClaritySelect
                onChange={(roles: string[]) => {
                  setRoles(roles);
                }}
                stopPropagation={false}
                mode={"multiple"}
                options={roleOptions}
                value={groupRequiredRoles}
                searchable={true}
                label={"Required roles"}
                classNames={"claritySelectCustom"}
              />
            </div>
          )}
        </Space>
      </Radio.Group>
    </div>
  );
};
export default GroupPolicy;
