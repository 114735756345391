import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useLayoutEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { Abilities, IRole } from "utilities/types";
import styles from "../roles/roles.module.scss";
import AddOrEditRole from "./AddOrEditRole";
import ItemDescription from "clarity-ui/ItemDescription";
import { TeamOutlined } from "@ant-design/icons";

const DefaultPermissionSection: React.FC<{}> = () => {
  const canEdit = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_ROLES,
  });
  const [isEditing, setisEditing] = useState(false);

  const role = useNoRoleGetter();

  return (
    <>
      <div
        className={styles.defaultPermissionsSection}
        onClick={() => (role?.id ? setisEditing(true) : null)}
      >
        <ItemDescription
          noPadding={true}
          title={"Default Permissions"}
          description={
            "Everyone • The default permissions that apply to all base members"
          }
          icon={<TeamOutlined />}
        />
      </div>
      {isEditing && role?.id && (
        <AddOrEditRole
          id={role.id}
          disabled={!canEdit}
          noRole
          setdetailsOpen={setisEditing}
        />
      )}
    </>
  );
};

const useNoRoleGetter = () => {
  const roleDict = useSelector(
    (state: ClarityStore) => state.roles.dict,
    shallowEqual
  );

  const roleIds = useSelector(
    (store: ClarityStore) => store.roles.ids,
    shallowEqual
  );

  const [role, setRole] = useState<IRole | undefined>();

  useLayoutEffect(() => {
    for (const id of roleIds) {
      if (roleDict[id]?.roleName === "No Role") {
        setRole(roleDict[id]);
        break;
      }
    }
  }, [roleIds, roleDict]);

  return role;
};

export default DefaultPermissionSection;
