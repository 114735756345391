import React, { useCallback, useEffect, useRef, useState } from "react";
import Button, { ButtonTypes } from "./Button";
import ClaritySelect, { IOptions } from "./ClaritySelect";
import styles from "./tokenSelector/tokenSelector.module.scss";
import {
  CloseOutlined,
  LoadingOutlined,
  DownOutlined,
} from "@ant-design/icons";
// import { ReactComponent as DownOutlined } from "icons/chevron-down.svg";
import { throttle, uniqBy } from "lodash";
import { Chains } from "utilities/web3/connectors";
import {
  CoingeckoToken,
  fetchedTokensDict,
  fetchTokens,
} from "utilities/web3/tokens";
import { TokenTypes } from "utilities/types";
import store from "store/storeExporter";
import DefaultTokenIcon from "icons/icon-token-default.svg";
import { VALUE_DOWN, VALUE_ENTER, VALUE_UP } from "keycode-js";
import { useOnClickOutside } from "editor/utils/customHooks";
import { web3Api } from "clientApi/web3Api";
import { tokenApi } from "clientApi/tokenApi";
import { useShallowSelector } from "utilities/hooks";
import notificationsApi from "clientApi/notificationsApi";

export interface ITokenConfirmation {
  name: string;
  symbol: string;
  contractAddress: string;
  icon?: string;
}

const TokenSelector: React.FC<{
  onSelect: (contractAddress: string) => void;
  onConfirmedToken: (data: ITokenConfirmation) => void;
  selectedItem: string | undefined;
  networkId: number;
  tokenType: TokenTypes;
  prefix?: any;
  disabled?: boolean;
}> = ({
  onSelect,
  selectedItem,
  networkId,
  prefix,
  tokenType,
  onConfirmedToken,
  disabled,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [options, setOptions] = useState<IOptions[]>([]);
  const [hoveredItem, sethoveredItem] = useState<string>("");
  const [selectionOpen, setselectionOpen] = useState(false);

  const changeSelection = (e: string) => {
    onSelect(e);
    closeSelection();
    ref.current?.blur();
  };

  const toggleSelectionOpen = (isOpen: boolean) => {
    if (disabled) return;
    setselectionOpen(isOpen);
  };

  const closeSelection = () => {
    toggleSelectionOpen(false);
  };

  useOnClickOutside(ref, closeSelection);

  return (
    <div
      ref={ref}
      className={`${styles.row} ${selectionOpen ? styles.focus : ""} ${
        disabled ? styles.disabled : ""
      }`}
      onFocus={() =>
        options.length > 0 ? toggleSelectionOpen(true) : undefined
      }
      onKeyDown={(e) => {
        if (e.key === VALUE_ENTER) {
          e.preventDefault();
          e.stopPropagation();
          changeSelection(hoveredItem);
        }
      }}
      tabIndex={-1}
    >
      <InputHandler
        networkId={networkId}
        tokenType={tokenType}
        selectedElement={selectedItem}
        changeSelection={changeSelection}
        setOptions={setOptions}
        sethoveredItem={sethoveredItem}
        onConfirmedToken={onConfirmedToken}
        disabled={disabled}
      />
      {options.length > 0 && (
        <span className="ant-select-arrow">
          <DownOutlined />
        </span>
      )}
      {/* {options.length > 0 && (
        <Button
          buttonType={ButtonTypes.LINK}
          icon={<Icon component={DownOutlined} />}
        />
      )} */}
      <div className={styles.hiddenContainer}>
        <ClaritySelect
          onChange={changeSelection}
          defaultValue={selectedItem}
          container={ref.current}
          hoverItem={hoveredItem}
          value={selectedItem}
          open={selectionOpen}
          manualOpen={true}
          options={options}
          classNames="hiddenInput"
        />
      </div>
    </div>
  );
};

const InputHandler: React.FC<{
  networkId: Chains;
  tokenType: TokenTypes;
  selectedElement: string | undefined;
  onConfirmedToken: (data: ITokenConfirmation) => void;
  setOptions: React.Dispatch<React.SetStateAction<IOptions[]>>;
  sethoveredItem: React.Dispatch<React.SetStateAction<string>>;
  changeSelection: (e: string) => void;
  disabled?: boolean;
}> = ({
  networkId,
  tokenType,
  setOptions,
  sethoveredItem,
  selectedElement,
  onConfirmedToken,
  changeSelection,
  disabled,
}) => {
  const {
    selectedToken,
    options,
    inputValue,
    setinputValue,
    isSearching,
    hoveredItem,
    goUp,
    goDown,
  } = useTokenSelectorState(
    networkId,
    tokenType,
    selectedElement,
    onConfirmedToken,
    changeSelection
  );

  useEffect(() => {
    setOptions(options);
  }, [options]);

  useEffect(() => {
    sethoveredItem(hoveredItem);
  }, [hoveredItem]);

  return (
    <>
      {isSearching && (
        <Button buttonType={ButtonTypes.LINK} icon={<LoadingOutlined />} />
      )}
      {selectedToken && !isSearching && (
        <img
          height={20}
          width={20}
          style={{
            marginRight: "10px",
          }}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultTokenIcon;
          }}
          src={selectedToken.logoURI ?? DefaultTokenIcon}
          alt={selectedToken.symbol}
        />
      )}
      <input
        value={inputValue}
        disabled={disabled}
        onChange={(e) => setinputValue(e.target.value)}
        className={styles.input}
        placeholder={selectedToken?.name || "Search or paste contract address"}
        onKeyDown={(e) => {
          if (e.key === VALUE_UP) {
            e.preventDefault();
            e.stopPropagation();
            goUp();
          }
          if (e.key === VALUE_DOWN) {
            e.preventDefault();
            e.stopPropagation();
            goDown();
          }
        }}
      />
      {inputValue && (
        <Button
          buttonType={ButtonTypes.LINK}
          style={{ marginRight: options.length > 0 ? "14px" : undefined }}
          icon={<CloseOutlined />}
          onClick={() => setinputValue("")}
        />
      )}
    </>
  );
};

const ItemLabel: React.FC<{ token: CoingeckoToken }> = ({ token }) => {
  return (
    <span>
      <img
        height={20}
        width={20}
        style={{
          marginRight: "10px",
        }}
        onError={(e) => {
          e.currentTarget.onerror = null;
          e.currentTarget.src = DefaultTokenIcon;
        }}
        src={token.logoURI ?? DefaultTokenIcon}
        alt={token.symbol}
      />
      {token.name}
    </span>
  );
};

const useTokenSelectorState = (
  chainId: Chains,
  tokenType: TokenTypes,
  selectedElement: string | undefined,
  onConfirmedToken: (data: ITokenConfirmation) => void,
  changeSelection: (e: string) => void
) => {
  const [options, setoptions] = useState<IOptions[]>([]);
  const [coins, setcoins] = useState<CoingeckoToken[]>([]);
  const [coinIds, setcoinIds] = useState<string[]>([]);
  const [inputValue, setinputValue] = useState("");
  const [isSearching, setisSearching] = useState(false);
  const [hoveredItem, sethoveredItem] = useState("");
  const [selectedToken, setselectedToken] = useState<
    CoingeckoToken | undefined
  >();
  const coinsRef = useRef<CoingeckoToken[]>([]);
  const [chainTokens, setchainTokens] = useState<CoingeckoToken[]>([]);
  const tokens = useShallowSelector((store) => store.tokens.dict);

  const filterOptions = async (filterBy: string, chainId: number) => {
    const filterByLower = filterBy.toLowerCase();
    const res = coinsRef.current.filter(
      (coin) =>
        coin.address.toLowerCase().includes(filterByLower) ||
        coin.name.toLowerCase().includes(filterByLower) ||
        coin.symbol.toLowerCase().includes(filterByLower)
    );
    setcoins(res);
    if (res.length === 0) {
      if (web3Api.web3.utils.isAddress(filterBy, chainId)) {
        setisSearching(true);
        web3Api
          .checkIfTokenExists(chainId, filterBy, tokenType)
          .then((res) => {
            if (res?.name) {
              tokenApi.generateToken({
                name: res.name,
                symbol: res.symbol,
                icon: res.icon ?? DefaultTokenIcon,
                contractAddress: filterBy,
                networkId: chainId,
                tokenType,
              });
              setinputValue("");
              changeSelection(filterBy);
              onConfirmedToken({
                contractAddress: filterBy,
                name: res.name,
                symbol: res.symbol,
                icon: res.icon ?? DefaultTokenIcon,
              });
            } else {
              showProblem();
            }
            setisSearching(false);
          })
          .catch(() => {
            setisSearching(false);
            showProblem();
          });
      }
    }
  };

  const prepareTokens = async () => {
    setisSearching(true);
    const builtOptions = await buildOptionsArray(chainId, tokenType);
    setisSearching(false);
    coinsRef.current = builtOptions;
    setchainTokens([...builtOptions]);
    setcoins(builtOptions);
  };

  useEffect(() => {
    setcoins([]);
    prepareTokens();
  }, [chainId, tokens, tokenType]);

  const debounceSearch = useCallback(
    throttle(filterOptions, 200, { trailing: true, leading: false }),
    []
  );

  useEffect(() => {
    let newHoveredItem = hoveredItem;
    const ids = coins.map((coin) => coin.address);
    if (!ids.includes(hoveredItem)) {
      newHoveredItem = ids[0];
    }

    if (newHoveredItem !== hoveredItem) {
      sethoveredItem(newHoveredItem);
    }

    const selectOptions: IOptions[] = coins.map((coin, index) => ({
      label: <ItemLabel token={coin} key={coin.address + index} />,
      value: coin.address,
    }));
    setcoinIds(ids);
    setoptions(selectOptions);
  }, [coins]);

  useEffect(() => {
    debounceSearch(inputValue, chainId);
  }, [inputValue]);

  useEffect(() => {
    const selectedCoin = chainTokens.find(
      (coin) => selectedElement && coin.address === selectedElement
    );
    if (selectedCoin) setselectedToken(selectedCoin);
    else setselectedToken(undefined);
    if (selectedCoin) {
      setinputValue("");
      onConfirmedToken({
        name: selectedCoin.name,
        symbol: selectedCoin.symbol,
        icon: selectedCoin.logoURI,
        contractAddress: selectedCoin.address,
      });
    }
  }, [selectedElement, chainTokens]);

  const goUp = () => {
    const index = coinIds.indexOf(hoveredItem);
    if (index === 0) return;
    const el = coinIds[index - 1];
    sethoveredItem(el);
  };

  const goDown = () => {
    const index = coinIds.indexOf(hoveredItem);
    if (index === coinIds.length - 1) return;
    const el = coinIds[index + 1];
    sethoveredItem(el);
  };

  const showProblem = () => {
    notificationsApi.displayError({
      title: "Address data not found",
      body: "The contract address does not match a valid address",
    });
  };

  return {
    selectedToken,
    options,
    inputValue,
    setinputValue,
    isSearching,
    hoveredItem,
    goUp,
    goDown,
  };
};

const buildOptionsArray = async (chainId: Chains, tokenType: TokenTypes) => {
  let options: CoingeckoToken[] = [];
  const savedTokens = store.getState().tokens;
  if ([TokenTypes.ERC20, TokenTypes.NATIVE_CURRENCY].includes(tokenType)) {
    if (fetchedTokensDict[chainId]) {
      options = fetchedTokensDict[chainId];
    } else options = await fetchTokens(chainId);
  }

  if ([TokenTypes.ERC721, TokenTypes.ERC1155].includes(tokenType)) {
    const res = await web3Api.getMainNetNfts(chainId);
    if (res.data) {
      options = res.data.map((nft: any) => ({
        address: nft.address,
        chainId: nft.networkId,
        decimals: 18,
        logoURI: nft.logoUri,
        name: nft.name,
        symbol: nft.slug,
      }));
    }
  }

  let allTokens: CoingeckoToken[] = [];
  savedTokens.ids.forEach((id) => {
    const token = savedTokens.dict[id];
    if (checkTokenType(tokenType, token.type) && token.networkId === chainId) {
      allTokens.push({
        address: token.contractAddress,
        chainId: token.networkId,
        decimals: 18,
        logoURI: token.icon,
        name: token.name,
        symbol: token.symbol,
      });
    }
  });
  options.forEach((token) => {
    allTokens.push(token);
  });

  allTokens = uniqBy(allTokens, "address");
  return allTokens;
};

const checkTokenType = (type: TokenTypes, tokenType: TokenTypes) => {
  const type1 = [TokenTypes.ERC20, TokenTypes.NATIVE_CURRENCY];
  const type2 = [TokenTypes.ERC721, TokenTypes.ERC1155];
  if (type1.includes(type) && type1.includes(tokenType)) return true;
  if (type2.includes(type) && type2.includes(tokenType)) return true;
};

export default TokenSelector;
