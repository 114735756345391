import React from "react";
import styles from "../styles/taskWorkItems.module.scss";

const ActionCTAContent: React.FC<{
  icon: JSX.Element;
  title: string;
  description: React.ReactElement | string;
  border?: boolean;
  accentColor?: string;
}> = ({ icon, title, description, children, border, accentColor }) => {
  return (
    <div
      className={styles.requestContainer}
      style={
        border
          ? {
              border: accentColor ? `solid 1px ${accentColor}` : "solid 1px",
            }
          : {}
      }
    >
      <div className={styles.requestHeader}>
        <div
          className={styles.Icon}
          style={
            accentColor
              ? {
                  backgroundColor: accentColor,
                  color: "white",
                }
              : {}
          }
        >
          {icon}
        </div>
        <div className={styles.text}>
          <span className="body medium">{title}</span>
          <span className="small regular secondary">{description}</span>
        </div>
      </div>
      <div className={styles.requestFooter}>{children}</div>
    </div>
  );
};

export default ActionCTAContent;
