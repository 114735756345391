import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import userReducer from "./reducers/userReducer";
import workspaceReducer from "./reducers/workspaceReducer";
import clientReducer from "./reducers/clientReducer";
import { CHANGE_DOCUMENT_MODE, SET_FOCUS } from "./actions";
import { DocumentModes } from "../utilities/types";
import topNavReducer from "./reducers/topNavReducer";
import valueUpdateChecker from "./middlewares/valueUpdateChecker";
import { loadState, saveState } from "./persistedState";
import cleanupMiddleware from "./middlewares/cleanUpMiddleare";
import networkReducer from "./reducers/networkReducer";
import sidebarReducer from "./reducers/sidebarReducer";
import commandPaletteReducer from "./reducers/commandPaletteReducer";
import { batch } from "react-redux";
import containerReducer from "./reducers/containerReducer";
import selectionReducer from "./reducers/selectionReducer";
import entityConnectionReducer from "./reducers/entityConnectionsReducer";
import { BehaviorSubject } from "rxjs";
import { socket } from "App";
import filterReducer from "./reducers/filterReducer";
import workReducer from "./reducers/workReducer";
import pagesReducer from "./reducers/pagesReducer";
import confirmationModalReducer from "./reducers/confirmationModalReducer";
import entityCreate from "./middlewares/entityCreate";
import memberReducer from "./reducers/memberReducer";
import blockReducer, { FocusObject } from "./reducers/blockReducer";
import {
  resetUpdateBlocks,
  updatedBlocks,
} from "./reducers/blockReducerHeplers/generalBlockHelpers";
import { batchedSubscribe } from "redux-batched-subscribe";
import { unstable_batchedUpdates } from "react-dom";
import { enableBatching } from "redux-batched-actions";
import citationsReducer from "./reducers/citationsReducer";
import mentionsReducer from "./reducers/mentionsReducer";
import { debounce } from "lodash";
import inAppNotificationReducer from "./reducers/inAppNotificationsReducer";
import subscriptionReducer from "./reducers/subscriptionReducer";
import discussionReducer from "./reducers/discussionReducer";
import customWorkViewsReducer from "./reducers/customWorkViewsReducer";
import userBaseSettingsReducer from "./reducers/userBaseSettingsReducer";
import tokenGatesReducer from "./reducers/tokenGatesReducer";
import notesReducer from "./reducers/notesReducer";
import Cookies from "js-cookie";
import tokenReducer from "./reducers/tokenReducer";
import roleReducer from "./reducers/roleReducer";
import groupReducer from "./reducers/groupReducer";
import changelogReducer from "./reducers/changelogReducer";
import navigationReducer from "./reducers/navigationReducer";
import recentlyOpenedReducer from "./reducers/recentlyOpenedReducer";
import { ChunkDestination } from "utilities/stateTypes";
import roadmapFilterReducer from "./reducers/roadmapFilterReducer";
import templatesReducer from "./reducers/templateReducer";
import snippetReducer from "./reducers/snippetReducer";

export const openCard = new BehaviorSubject<any>(null);
export const joinedRooms = new BehaviorSubject<string[]>([]);
export const activeDiscussion = new BehaviorSubject<string>("");
export const activePrediscussion = new BehaviorSubject<string>("");
export const prevState = new BehaviorSubject<ClarityStore>({
  workspace: {},
} as ClarityStore);
export let prevFocusOn: FocusObject = {};
export const $focusOn = new BehaviorSubject<FocusObject>({});

export const focusListener = $focusOn.subscribe((newFocus) => {
  if (!prevState || !prevState.value || !prevState.value.blocks) return;
  const currentFocusOn: FocusObject = prevState.value.blocks.focusOn;
  if (
    newFocus.focusPane !== currentFocusOn.focusPane ||
    newFocus.focusContext?.id !== currentFocusOn.focusContext?.id ||
    newFocus.focusContext?.type !== currentFocusOn.focusContext?.type
  ) {
    if (newFocus.fromRefocus) {
      delete newFocus.fromRefocus;
    } else {
      store.dispatch({
        type: SET_FOCUS,
        param: {
          newFocus,
        },
      });
    }
  }
  if (newFocus.focusBlockId !== currentFocusOn.focusBlockId) {
    emitFocusChange();
  }
});

export const $documentMode = new BehaviorSubject<DocumentModes>(
  DocumentModes.INSERT
);
export const documentModeListener = $documentMode.subscribe(
  (newDocumentMode) => {
    const currentDocumentMode = prevState.value?.blocks?.documentMode
      ? prevState.value?.blocks?.documentMode
      : DocumentModes.INSERT;
    if (currentDocumentMode !== newDocumentMode) {
      batch(() => {
        store.dispatch({
          type: CHANGE_DOCUMENT_MODE,
          param: {
            documentMode: newDocumentMode,
          },
        });
      });
    }
  }
);
export const blockCache = { dict: {} };
export const citationCache = { dict: {} };
export let contextWithNoBlocks: any = {};
export const resetContextWithNoBlocks = () => {
  contextWithNoBlocks = {};
};
export const updateBlockCache = (blockObj: any) => {
  blockCache.dict = { ...blockCache.dict, ...blockObj };
};
export const updateCitationCache = (citationObj: any) => {
  citationCache.dict = { ...citationCache.dict, ...citationObj };
};

export const emitFocusChange = () => {
  if ($focusOn.value.focusContext?.container) {
    socket.emit("changeCaretPosition", {
      newCaretObject: {
        blockId: $focusOn.value.focusBlockId,
        containerId: $focusOn.value.focusContext
          ? $focusOn.value.focusContext.container.id
          : "",
        baseId: prevState.value.workspace.id,
      },
    });
  }
};

const defaultReducers = {
  members: memberReducer,
  confirmationModal: confirmationModalReducer,
  tokenGates: tokenGatesReducer,
  tokens: tokenReducer,
  blocks: blockReducer,
  user: userReducer,
  workspace: workspaceReducer,
  work: workReducer,
  pages: pagesReducer,
  notes: notesReducer,
  templates: templatesReducer,
  snippets: snippetReducer,
  client: clientReducer,
  topNav: topNavReducer,
  network: networkReducer,
  container: containerReducer,
  sidebar: sidebarReducer,
  selection: selectionReducer,
  entityConnection: entityConnectionReducer,
  commandPalette: commandPaletteReducer,
  filter: filterReducer,
  citations: citationsReducer,
  mentions: mentionsReducer,
  inlineDiscussions: discussionReducer,
  inAppNotifications: inAppNotificationReducer,
  customWork: customWorkViewsReducer,
  subscription: subscriptionReducer,
  userBaseSettings: userBaseSettingsReducer,
  roles: roleReducer,
  groups: groupReducer,
  changelogs: changelogReducer,
  navigation: navigationReducer,
  recentlyOpened: recentlyOpenedReducer,
  roadmapFilters: roadmapFilterReducer,
};

const rootReducer = combineReducers(defaultReducers);

const middlewares = [valueUpdateChecker, entityCreate, cleanupMiddleware];

const middlewareEnhancer = applyMiddleware(...middlewares);

const includeDevtoolsExt =
  process.env.REACT_APP_ENV === "DEVELOPMENT" &&
  process.env.REACT_APP_ENABLE_REDUX_DEVTOOLS === "true"
    ? Boolean((window as any).__REDUX_DEVTOOLS_EXTENSION__)
    : undefined;

let enhancer;

if (false && includeDevtoolsExt) {
  enhancer = compose(
    middlewareEnhancer,
    batchedSubscribe(unstable_batchedUpdates),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  enhancer = compose(
    middlewareEnhancer,
    batchedSubscribe(unstable_batchedUpdates)
  );
}

const persistedState = loadState();

const store = createStore(
  enableBatching(rootReducer),
  persistedState,
  enhancer
);

export function createReducer(asyncReducers?: any) {
  return combineReducers({
    ...defaultReducers,
    ...asyncReducers,
  });
}

export function injectAsyncReducer(
  store: any,
  name: string,
  asyncReducer: any
) {
  if (!store.asyncReducers) store.asyncReducers = {};
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
}

export function removeAsyncReducer(store: any, name: string) {
  if (store.asyncReducers[name]) delete store.asyncReducers[name];
  store.replaceReducer(createReducer(store.asyncReducers));
}

const handleStoreSaveSideEffects = debounce(
  (newState: ClarityStore) => {
    saveState({
      client: {
        accessToken: Cookies.get("x-at") ?? newState.client.accessToken,
        isSidebarHidden: newState.client.isSidebarHidden,
        displaySecondaryView:
          newState.navigation.splitView.displaySecondaryView,
        dimension: newState.navigation.dimension || undefined,
        lastVisitedPages: newState.client.lastVisitedPages,
        workItemReviewNudges: newState.client.workItemReviewNudges,
        lastUsedToken: newState.client.lastUsedToken,
      },
      user: { ...newState.user },
      sidebar: {
        isOpen: newState.sidebar.isOpen,
        isTemporary: newState.sidebar.isTemporary,
        sidebarMode: newState.sidebar.sidebarMode,
      },
      navigation: {
        navigation: {
          [ChunkDestination.secondary]: {
            ...newState.navigation?.navigation[ChunkDestination.secondary],
          },
        },
        splitView: { ...newState.navigation.splitView },
        dimension: { ...newState.navigation.dimension },
      },
      commandPalette: {
        context: newState.commandPalette.context,
      },
      work: {
        sections: newState.work.sections,
        activeBaseId: newState.work.activeBaseId,
      },
      roadmapFilters: { ...newState.roadmapFilters },
      recentlyOpened: {
        ...newState.recentlyOpened,
      },
    });
  },
  600,
  { trailing: true }
);

store.subscribe(() => {
  const newState = store.getState();
  prevState.next(newState);
  prevFocusOn = newState.blocks.focusOn ? newState.blocks.focusOn : {};
  if (Object.values(updatedBlocks).length > 0) {
    batch(() => {
      Object.values(updatedBlocks).forEach((block) => {
        if (block?.blockSubject?.next) {
          block.blockSubject.next(block);
        }
      });
      resetUpdateBlocks();
    });
  }
  handleStoreSaveSideEffects(newState);
});

export default store;

export type ClarityStore = ReturnType<typeof store.getState>;
