import React from "react";
import styles from "./documentListHeader/documentListHeader.module.scss";

interface IDocumentListHeaderProps {
  children: any;
}

export default function DocumentListHeader(props: IDocumentListHeaderProps) {
  return <div className={styles.documentListHeader}>{props.children}</div>;
}
