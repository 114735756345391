/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef, useState, useEffect } from "react";
import { LineType } from "utilities/lineUtilities";
import styles from "./styles/blockComponent.module.scss";
import {
  FileImageOutlined,
  ExclamationCircleOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import Button, { ButtonTypes } from "components/Button";
import { connect, useDispatch, batch } from "react-redux";
import short from "short-uuid";
import * as actionTypes from "store/actions";
import { ContainerTypes } from "utilities/types";
import LoadingSpinner from "components/LoadingSpinner";
import { socket } from "App";
import { BlockPropsWithRef } from "../BlockSplitter";
import { getClassNameFromBlockType } from "editor/utils/blockActions";
import { batchBlockUpdate } from "editor/utils/specificActions/batchBlockUpdate";
import { VALUE_ESCAPE } from "keycode-js";
import { isImageUrl, isPdfUrl } from "utilities/linkUtilities";
import { changeLinkBlockType } from "editor/utils/specificActions/blockTypesActions";
import { axiosInstance } from "index";
import { useGetResourceById } from "editor/utils/resourceGetterHooks/resourceGetter";

interface IMapStateToProps {
  workspaceId: string;
  passedEntity: any;
}

const ImageBlock: React.FC<BlockPropsWithRef & IMapStateToProps> = (props) => {
  const { workspaceId } = props;
  const blockImageData = props.blockData.value[0];
  const dispatcher = useDispatch();
  const { resource } = useGetResourceById(
    props.blockData.referencingContainerId
  );
  let initialUrlValue = null;
  if (resource) {
    initialUrlValue = resource.url;
  } else {
    if (blockImageData?.options?.src)
      initialUrlValue = blockImageData.options.src;
  }
  const [imageUrl, setImageUrl] = useState(initialUrlValue);
  const [isImageUploadWaiting, setIsImageUploadWaiting] = useState(false);
  const [embedInput, setEmbedInput] = useState(false);
  const [embedInputValue, setEmbedInputValue] = useState("");
  const [uploadError, setuploadError] = useState(false);

  const ImageRef = useRef<File | null>(null);

  const InputImageUpload = useRef(null);
  const ImageContainer = useRef(null);

  useEffect(() => {
    if (props.passedEntity) {
      if (props.passedEntity.data.imageUrl) {
        handleImageEmbed(
          props.passedEntity.data.files[0],
          props.passedEntity.data.imgUrl
        );
      } else {
        handleImageUpload(props.passedEntity?.data.files[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.passedEntity]);

  useEffect(() => {
    if (resource) {
      setImageUrl(resource.url);
    } else setImageUrl(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  useEffect(() => {
    if (props.blockData.options?.embedData) {
      const src = props.blockData.options?.embedData.src;
      handleImageEmbed(null, src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.blockData.options?.embedData]);

  const handleUploadImageClick = (input: any) => {
    input.current.focus();
    input.current.click();
  };

  const handleUploadImageChange = (input: any, imageContainer: any) => {
    const image = input.current.files[0];
    if (!image || !image.name) return;
    handleImageUpload(image);
  };

  const handleImageUpload = (image: File) => {
    if (!image) return;
    ImageRef.current = image;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    const splitFilename = image.name.split(".");
    const fileExtension = splitFilename[splitFilename.length - 1];
    setIsImageUploadWaiting(true);
    const photoKey = `${workspaceId}/${short.generate()}.${fileExtension}`;

    axiosInstance
      .post(`/api/auth/getSignedUrl`, { key: photoKey })
      .then((res) => {
        const signedUrl = res.data.uploadUrl;
        const authorizationHeader =
          axiosInstance.defaults.headers.common["Authorization"];
        delete axiosInstance.defaults.headers.common["Authorization"];
        axiosInstance
          .put(signedUrl, image, {
            headers: {
              "content-type": "image/*",
            },
          })
          .then(({ data }) => {
            const hostedUrl = signedUrl.split("?")[0];
            setIsImageUploadWaiting(false);
            setuploadError(false);
            setImageUrl(hostedUrl);
            axiosInstance.defaults.headers.common.Authorization =
              authorizationHeader;
            handleEntityCreation(image, hostedUrl);
          })
          .catch(() => {
            setIsImageUploadWaiting(false);
            setuploadError(true);
          });
      });
  };

  const handleImageEmbed = (image: File | null, imgUrl: string) => {
    if (isImageUrl(imgUrl) || image) {
      if (image) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          setImageUrl(reader.result);
        };
      }
      setIsImageUploadWaiting(true);
      setImageUrl(imgUrl);
      handleEntityCreation(image, imgUrl);
    } else if (isPdfUrl(imgUrl)) {
      setImageUrl(imgUrl);
      handleEntityCreation(image, imgUrl);
      changeLinkBlockType(
        props.blockData.id,
        LineType.pdf,
        props.context,
        props.blockRef
      );
    } else {
      setImageUrl(imgUrl);
      handleEntityCreation(image, imgUrl);
      changeLinkBlockType(
        props.blockData.id,
        LineType.webLink,
        props.context,
        props.blockRef
      );
    }
  };

  const handleEntityCreation = (image: File | null, imgUrl: string) => {
    axiosInstance
      .post(`/api/resource/`, {
        name: image ? image.name : "",
        url: imgUrl,
        caption: "",
        type: "Image",
        baseId: workspaceId,
        clientId: socket.id,
      })
      .then((res) => {
        batch(() => {
          setuploadError(false);
          setIsImageUploadWaiting(false);
          dispatcher({
            type: actionTypes.ADD_RESOURCE,
            resource: res.data.payload,
          });

          const resourceId: string = res.data.payload.id;

          batchBlockUpdate({
            id: props.blockData.id,
            context: props.context,
            delta: {
              referencingContainerType: ContainerTypes.RESOURCE,
              referencingContainerId: resourceId,
            },
          });
          dispatcher({
            type: actionTypes.CLEAR_RESOURCE_TO_UPLOAD,
            param: {
              blockId: props.blockData.id,
            },
          });
        });
      })
      .catch((err) => {
        setuploadError(true);
        console.log(err);
      });
  };

  const handleEmbedInputChange = (event: any) => {
    const { value } = event.target;
    setEmbedInputValue(value);
  };

  const EmptyBlock = () => {
    return (
      <div
        className={styles.imageBlockInput}
        style={{
          margin: props.blockData.indentLevel === 0 ? "-16px 0" : "0",
        }}
      >
        <div className={styles.imageBlockInput__icon}>
          <FileImageOutlined />
        </div>
        {isImageUploadWaiting && <LoadingSpinner />}
        {!isImageUploadWaiting && (
          <>
            <span
              className={styles.imageBlockInput__button}
              onClick={(event: any) => {
                handleUploadImageClick(InputImageUpload);
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              Upload an image
            </span>
            <span className={styles.imageBlockInput__or}> or </span>
            <span
              className={styles.imageBlockInput__button}
              onClick={(event: any) => {
                setEmbedInput(true);
                // props.setisFocused(false);
                event.stopPropagation();
              }}
            >
              embed with a link
            </span>
            <input
              style={{ display: "none" }}
              onChange={() =>
                handleUploadImageChange(InputImageUpload, ImageContainer)
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
              type="file"
              accept=".jpg, .jpeg, .png"
              ref={InputImageUpload}
            />
          </>
        )}
      </div>
    );
  };

  const HasImage = () => {
    return (
      <>
        <div style={{ position: "relative" }}>
          <img
            draggable={false}
            className={styles.imageBlock}
            onDoubleClick={openImageModal}
            src={imageUrl}
            ref={ImageContainer}
            alt="image-block"
            style={{ borderRadius: "3px", cursor: "pointer" }}
          />
          {isImageUploadWaiting && (
            <LoadingSpinner
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            />
          )}
          {uploadError && !isImageUploadWaiting && (
            <ExclamationCircleOutlined
              style={{
                position: "absolute",
                top: 0,
                right: "-8px",
              }}
            />
          )}
          {uploadError && (
            <span>
              An error has occured while uploading image <br />{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (ImageRef.current) {
                    handleImageUpload(ImageRef.current);
                  }
                }}
              >
                Retry
              </span>
            </span>
          )}
          <Button
            onClick={openImageModal}
            className={styles.expandButton}
            buttonType={ButtonTypes.LINK}
            icon={<ExpandOutlined />}
          ></Button>
        </div>
      </>
    );
  };

  const EmbedStep = () => {
    return (
      <div
        className={styles.imageBlockInput}
        style={{
          margin: props.blockData.indentLevel === 0 ? "-16px 0" : "0",
        }}
      >
        <div className={styles.imageBlockInput__icon}>
          <FileImageOutlined />
        </div>
        <div className={styles.imageBlockInput__embedInput}>
          <input
            autoFocus={true}
            className={"embed-input"}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onPaste={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation();
              // e.preventDefault();
              // props.setisFocused(false);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === VALUE_ESCAPE) {
                setEmbedInput(false);
              }
            }}
            onChange={handleEmbedInputChange}
            value={embedInputValue}
            placeholder="Paste link here to embed"
          />
        </div>
        <div>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={(event: any) => {
              event.stopPropagation();
              handleImageEmbed(null, embedInputValue);
            }}
          >
            Embed
          </Button>
        </div>
      </div>
    );
  };

  const openImageModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (imageUrl) {
      dispatcher({
        type: actionTypes.TOGGLE_IMAGE_MODAL,
        isOpen: true,
        imageUrl,
      });
    }
  };

  return (
    <div
      data-block-id={props.blockData.id}
      tabIndex={0}
      data-root={true}
      ref={props.blockRef}
      contentEditable={false}
      className={
        "content-section clarity-selectable idle-project " +
        getClassNameFromBlockType(props.blockData.lineType) +
        styles.documentBlock
      }
    >
      <div
        style={{
          textAlign: props.blockData.indentLevel > 0 ? "left" : "center",
          position: "relative",
          padding: props.blockData.indentLevel === 0 ? "16px 0" : "1px",
        }}
      >
        {imageUrl ? HasImage() : embedInput ? EmbedStep() : EmptyBlock()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: BlockPropsWithRef) => {
  return {
    workspaceId: state.workspace.id,
    passedEntity: state.blocks?.resourceToUpload?.[ownProps.blockData.id],
  };
};

export default connect(mapStateToProps)(ImageBlock);
