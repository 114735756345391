/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useLayoutEffect, useRef, useState } from "react";
import { batch, shallowEqual, useSelector } from "react-redux";
import {
  ContainerTypes,
  IBlockContext,
  IPageObj,
  IProjectObj,
  ITag,
} from "utilities/types";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import store from "store/storeExporter";
import { SET_BLOCK_IN_DICT, UPDATE_WORK_ITEM } from "store/actions";
import { ILineValue, LineType, LineValueType } from "utilities/lineUtilities";
import ReadOnlyBlock from "./readOnlyBlocks/ReadOnlyBlock";
import { BlockContextPass } from "editor/blockContainer/BlockContext";
import RelatedPagesBlock from "editor/blockContainer/blockVariants/RelatedPagesBlock";
import { setMultipleBlocks } from "editor/utils/specificActions/textUpdateActions";
import { createNewBlock } from "modules/entityService";
import { ChunkDestination } from "utilities/stateTypes";
import { useShallowSelector } from "utilities/hooks";
import workApi from "clientApi/workApi";
import {
  getContainerByType,
  getNameFromContainer,
} from "modules/containerHelpers";

interface IWorkLabels {
  workItem: any;
  paneId: ChunkDestination;
  noPersist?: boolean;
  disabled?: boolean;
  maxWidth?: string;
}

const LabelsSelection: React.FC<IWorkLabels> = (props) => {
  const isBaseMember = useShallowSelector((state) => state.client.isBaseMember);
  const labelRef = useRef(null);
  const blockRef = useRef<HTMLDivElement | null>(null);
  const block = useSelector((state: any) => {
    return props.workItem.tagsBlockId
      ? state.blocks.dict[props.workItem.tagsBlockId]
      : null;
  }, shallowEqual);

  const [shouldPersist, setshouldPersist] = useState(false);
  const [isFocused, setisFocused] = useState(false);

  const checkShouldPesist = () => {
    const newTaskContext = store.getState().client.newTaskContext;
    if (newTaskContext?.presetData?.id) {
      return false;
    } else return true;
  };

  useLayoutEffect(() => {
    setshouldPersist(checkShouldPesist());
  }, []);

  useLayoutEffect(() => {
    if (!props.workItem.tagsBlockId) {
      let value: ILineValue[] = [];
      if (props.workItem.tags && props.workItem.tags.length > 0) {
        for (const tag of props.workItem.tags) {
          const container = getContainerByType({
            containerId: tag.containerId,
            containerType: tag.containerType,
          });
          if (!container) continue;
          const name = getNameFromContainer(container, tag.containerType);
          const valuePart = {
            type: LineValueType.mention,
            children: [
              {
                type: LineValueType.text,
                value: name,
                children: [] as ILineValue[],
              },
            ],
            value: "",
            linkTo: tag.containerId,
            options: {
              hashtag: true,
            },
          } as ILineValue;

          if (tag.containerType === ContainerTypes.WORK) {
            valuePart.options = {
              workTag: true,
              hashtag: true,
            };
          }
          value.push(valuePart);
          value.push({
            type: LineValueType.text,
            value: " ",
          });
        }
      }

      let newBlock = createNewBlock(
        {
          lineType: LineType.tagsBlock,
          referencingContainerId: props.workItem.id,
          referencingContainerType: ContainerTypes.PROJECT,
          indentLevel: 0,
          documentRank: "0",
          value,
        },
        {
          containerId: props.workItem.id,
          containerType: ContainerTypes.PROJECT,
          baseId: props.workItem.baseId,
        }
      );

      batch(() => {
        store.dispatch({
          type: SET_BLOCK_IN_DICT,
          block: { ...newBlock },
          globalBlockAction: true,
        });
        store.dispatch({
          type: UPDATE_WORK_ITEM,
          workItem: { ...props.workItem, tagsBlockId: newBlock.id },
        });
      });

      const shouldPersist = checkShouldPesist();

      if (shouldPersist) {
        setMultipleBlocks([{ id: newBlock.id, blockData: newBlock }], {
          autosave: true,
        } as any);
        workApi.saveUpdateWorkItem(props.workItem.id, {
          tags: [],
          tagsBlockId: newBlock.id,
        });
      }
    }
  }, []);

  const [context, setContext] = useState<IBlockContext>({
    id: ContainerTypes.PROJECT + props.workItem.id,
    autosave: checkShouldPesist(),
    canComment: false,
    canEdit: props.disabled ? false : true,
    online: true,
    paneId: props.paneId,
    persistToggle: false,
    zoomedBlockId: block?.id,
    ref: labelRef,
    type: "mention",
    container: {
      id: props.workItem.id,
      type: ContainerTypes.PROJECT,
    },
  });
  return (
    <>
      {block && (
        <div
          className="document work-item-document"
          style={{ maxWidth: props.maxWidth }}
          ref={labelRef}
        >
          {isBaseMember ? (
            <div
              className="single-row row related-pages workItem"
              style={{ marginBottom: "0px !important" }}
            >
              <div
                className="section-big"
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <div className="flex-section">
                  <BlockContextPass.Provider value={context}>
                    <RelatedPagesBlock
                      blockComponentData={{ isFocused }}
                      disabled={props.disabled}
                      blockData={block}
                      blockRef={blockRef}
                      changeBlock={() => {}}
                      context={context}
                      isFirst={true}
                      parentListType="no-outline"
                      parentSelected={false}
                      parentSetIsHovered={() => {}}
                      isInWorkItem={true}
                      workItem={props.workItem}
                      workItemId={props.workItem.id}
                    />
                  </BlockContextPass.Provider>
                </div>
              </div>
            </div>
          ) : (
            <ReadOnlyBlock
              block={block}
              fromNote={true}
              isFirst={true}
              blocksObject={{}}
              id={block.id}
              indentLevel={0}
              parentId={""}
              parentListType="no-outline"
              paneId={props.paneId}
            />
          )}
        </div>
      )}
    </>
  );
};

export default LabelsSelection;
