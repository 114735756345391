import { deviceType } from "App";
import { DeviceType } from "editor/utils/customHooks";
import { throttle } from "lodash";
import { NumberSize, Resizable } from "re-resizable";
import { Direction } from "re-resizable/lib/resizer";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SET_SIDEBAR_WIDTH, SET_SPLITVIEW_WIDTH } from "store/actions";
import store, { ClarityStore } from "store/storeExporter";
import Main from "./Main";
import SplitViewWrapper from "./SplitView";
import styles from "./base.module.scss";
import { useShallowSelector } from "utilities/hooks";

interface BaseViewProps {
  id: string;
}

const BaseView: React.FC<BaseViewProps> = ({ id }) => {
  const isBaseMember = useSelector(
    (store: ClarityStore) => store.client.isBaseMember,
    shallowEqual
  );
  const userId = useSelector(
    (store: ClarityStore) => store.user?.id,
    shallowEqual
  );
  const openBaseView = useSelector(
    (store: ClarityStore) => store.client.openBaseView,
    shallowEqual
  );

  const showSlashModal = useShallowSelector(
    (store) => store.user?.showSlashModal
  );

  return useMemo(() => {
    if (showSlashModal) return <></>;
    return (
      <>
        <div className={`flex-fill full-flex`}>
          <Main />
          <SplitViewMainWrapper />
        </div>
      </>
    );
  }, [id, isBaseMember, openBaseView, userId, showSlashModal]);
};

const SplitViewMainWrapper: React.FC = () => {
  const isSidebarHidden = useSelector(
    (store: ClarityStore) => store.client.isSidebarHidden,
    shallowEqual
  );
  const isSplitViewVisible = useSelector(
    (store: ClarityStore) => store.navigation.splitView.displaySecondaryView,
    shallowEqual
  );
  const splitViewWidth = useSelector(
    (store: ClarityStore) => store.navigation.dimension.splitViewWidth,
    shallowEqual
  );

  const [isResizing, setIsResizing] = useState(false);
  const handleResizeStop = useCallback(
    (
      event: MouseEvent | TouchEvent,
      direction: Direction,
      elementRef: HTMLElement,
      delta: NumberSize
    ) => {
      handleResize(event, direction, elementRef, delta);
      setIsResizing(false);
    },
    []
  );

  let splitViewMaxWidth = 0;

  const handleResize = useCallback(
    (
      event: MouseEvent | TouchEvent,
      direction: Direction,
      elementRef: HTMLElement,
      delta: NumberSize
    ) => {
      if (!isSidebarHidden) {
        const baseElement = document.getElementById("primary");
        if (baseElement && baseElement.offsetWidth <= 440) {
          store.dispatch({
            type: SET_SIDEBAR_WIDTH,
            width: window.innerWidth - 440 - splitViewWidth,
          });
        }
      }
      store.dispatch({
        type: SET_SPLITVIEW_WIDTH,
        width: elementRef.offsetWidth,
      });
    },
    [isSidebarHidden]
  );

  const resizeStartRef = useRef(throttle(() => setIsResizing(true)));

  if (isSidebarHidden) {
    splitViewMaxWidth = window.innerWidth - 440;
  } else {
    splitViewMaxWidth = window.innerWidth - 440 - 200;
  }
  return useMemo(() => {
    if (deviceType.value !== DeviceType.mobile) {
      return isSplitViewVisible ? (
        <Resizable
          className={styles.resizable}
          minHeight="100%"
          minWidth="440px"
          maxWidth={splitViewMaxWidth}
          onResizeStart={resizeStartRef.current}
          onResizeStop={handleResizeStop}
          size={{ width: `${splitViewWidth || 440}px`, height: "100%" }}
          handleStyles={{
            right: {
              backgroundColor: isResizing ? "#EBE1E5" : "transparent",
              width: "3px",
              right: "0px",
              zIndex: 20,
            },
          }}
          enable={{
            top: false,
            right: false,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
        >
          <div
            className={"h-100 " + styles.splitContainer}
            // style={{ borderLeft: "1px solid rgba(0, 0, 0, .15)" }}
          >
            <SplitViewWrapper />
          </div>
        </Resizable>
      ) : (
        <></>
      );
    }
    return <></>;
  }, [isSplitViewVisible, isResizing, splitViewWidth, splitViewMaxWidth]);
};

export default BaseView;
