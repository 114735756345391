export const validateEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const EMAIL_REGEX =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEX.test(email);
};

export const PASSWORD_NUMERIC_REGEX = /(?=.*[0-9])/g;
export const PASSWORD_UPPERCASE_REGEX = /(?=.*[A-Z])/;
export const validatePasswordLength = (password: string) => {
  return password.length > 7;
};
export const validatePasswordNumeric = (password: string) => {
  return PASSWORD_NUMERIC_REGEX.test(password);
};
export const validatePasswordUppercase = (password: string) => {
  return PASSWORD_UPPERCASE_REGEX.test(password);
};
export const validatePassword = (password: string) =>
  validatePasswordLength(password) &&
  validatePasswordNumeric(password) &&
  validatePasswordUppercase(password);
// eslint-disable-next-line no-useless-escape
export const DOC_URL_REGEX = /\/[d]\/([a-z0-9\-]*$)/;
// eslint-disable-next-line no-useless-escape
export const PROJECT_URL_REGEX = /\/[w]\/([a-z0-9\-]*)\/[T]-([a-z0-9\-]*$)/;
// eslint-disable-next-line no-useless-escape
export const PROFILE_REGEX = /\/[d]\/([a-z0-9\-]*$)\/profile/g;

export const validateUsername = (username: string) => {
  const PUNCTUATION_REGEX = /['".,\\/#!$%<>@\\^&\\*;:{}=\-_+`~()\s]/g;
  return !PUNCTUATION_REGEX.test(username);
};

export const validateDomainName = (domainName: string) => {
  const REGEX = /^[a-zA-Z0-9-]*$/;
  return REGEX.test(domainName);
};
// eslint-disable-next-line no-useless-escape
export const NOTE_URL_REGEX = /\/[n]\/([a-z0-9\-]*$)/;

export const SCRIPT_REGEX = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;

export const validateBio = (bio: string) => {
  return !SCRIPT_REGEX.test(bio);
};

export const numberWithCommas = (x: any) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
