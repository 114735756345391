export enum ChunkDestination {
  primary = "primary",
  secondary = "secondary",
  peek = "peek",
  taskComments = "taskComments",
}

export type ChunkDestinationKey = { [key in ChunkDestination]: string };

export const getChunkDestination = (
  destination: string | undefined
): ChunkDestination => {
  if (destination === ChunkDestination.secondary)
    return ChunkDestination.secondary;
  if (destination === ChunkDestination.taskComments)
    return ChunkDestination.taskComments;
  else return ChunkDestination.primary;
};
