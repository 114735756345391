import { useEffect, useState } from "react";
import { IBlockContext } from "utilities/types";
import { tableListeners } from "../TableBlock";
import { addColumn, addRow, resizeTableHandler } from "./action";
import { PlusOutlined } from "@ant-design/icons";
import { batch } from "react-redux";
import Conditional from "components/Conditional";
import styles from "../styles/TableEdges.module.scss";

let edgeWasDragged = false;
export const setEdgeWasDragged = () => {
  edgeWasDragged = true;
};

export const TableLeftEdge: React.FC<{ tableId: string }> = ({ tableId }) => {
  return (
    <div
      className="edge"
      data-table-edge="left"
      style={{
        position: "absolute",
        display: "flex",
        left: 0,
        top: "8px",
        bottom: "18px",
      }}
    ></div>
  );
};

export const TableRightEdge: React.FC<{
  tableId: string;
  columns: string[];
  rows: string[];
  context: IBlockContext;
  tableRef: React.MutableRefObject<HTMLTableElement | null>;
}> = ({ tableId, columns, rows, context, tableRef }) => {
  const [showEdge, setshowEdge] = useState(false);
  const [isPressed, setisPressed] = useState(false);

  useEffect(() => {
    const sub = tableListeners.subscribe((tableFocusData) => {
      if (tableFocusData.hoverTableId === tableId) {
        if (tableFocusData.hoverColumnId === columns[columns.length - 1])
          return setshowEdge(true);
      }
      setshowEdge(false);
    });
    return () => sub?.unsubscribe();
  }, [tableId, columns, rows]);

  useEffect(() => {
    const removePressed = () => {
      setisPressed(false);
    };
    document.body.addEventListener("mouseup", removePressed);
    return () => {
      document.body.removeEventListener("mouseup", removePressed);
    };
  }, []);

  return (
    <div
      className="edge"
      data-table-edge="right"
      style={{
        position: "absolute",
        display: "flex",
        right: 0,
        top: "8px",
        bottom: "18px",
      }}
      onMouseEnter={() => {
        if (!context.canEdit) return;
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: columns[columns.length - 1],
          hoverRowId: "",
          hoverTableId: tableId,
        });
      }}
      onMouseLeave={() => {
        if (!context.canEdit) return;
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: "",
          hoverRowId: "",
          hoverTableId: "",
        });
      }}
      onMouseDown={(e) => {
        if (!context.canEdit) return;
        e.stopPropagation();
        setisPressed(true);
        edgeWasDragged = false;
        resizeTableHandler(e, { tableId, context, direction: "x", tableRef });
      }}
    >
      <Conditional on={context.canEdit}>
        <div
          style={{
            display: "flex",
            width: "100%",
            opacity: showEdge || isPressed ? 1 : 0,
            transition: "opacity 150ms ease 50ms",
            pointerEvents: showEdge || isPressed ? "all" : "none",
          }}
          onClick={(e) => {
            if (edgeWasDragged) {
              edgeWasDragged = false;
              e.preventDefault();
              return;
            }
            addColumn({
              tableId,
              insert: "after",
              refColumnId: columns[columns.length - 1],
              context,
              count: 1,
            });
          }}
        >
          <div
            className={
              isPressed
                ? `${styles.colResizeContainer} ${styles.isPressed}`
                : `${styles.colResizeContainer} ${styles.notPressed}`
            }
            style={{
              userSelect: showEdge || isPressed ? "all" : "none",
              transition: "background 20ms ease-in 0s",
              width: "16px",
            }}
          >
            <div className={styles.addRow}>
              <PlusOutlined className={styles.icons} />
            </div>
          </div>
        </div>
      </Conditional>
    </div>
  );
};

export const TableBottomEdge: React.FC<{
  tableId: string;
  columns: string[];
  rows: string[];
  context: IBlockContext;
  tableRef: React.MutableRefObject<HTMLTableElement | null>;
}> = ({ tableId, columns, rows, context, tableRef }) => {
  const [showEdge, setshowEdge] = useState(false);
  const [isPressed, setisPressed] = useState(false);

  useEffect(() => {
    const sub = tableListeners.subscribe((tableFocusData) => {
      if (tableFocusData.hoverTableId === tableId) {
        if (tableFocusData.hoverRowId === rows[rows.length - 1])
          return setshowEdge(true);
      }
      setshowEdge(false);
    });
    return () => sub?.unsubscribe();
  }, [tableId, columns, rows]);

  useEffect(() => {
    const removePressed = () => {
      setisPressed(false);
    };
    document.body.addEventListener("mouseup", removePressed);
    return () => {
      document.body.removeEventListener("mouseup", removePressed);
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        right: "18px",
        left: "0px",
        bottom: "0px",
      }}
      onMouseEnter={() => {
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: "",
          hoverRowId: rows[rows.length - 1],
          hoverTableId: tableId,
        });
      }}
      onMouseLeave={() => {
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: "",
          hoverRowId: "",
          hoverTableId: "",
        });
      }}
      onMouseDown={(e) => {
        setisPressed(true);
        e.stopPropagation();
        edgeWasDragged = false;
        resizeTableHandler(e, { tableId, context, direction: "y", tableRef });
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          opacity: showEdge || isPressed ? 1 : 0,
          transition: "opacity 150ms ease 50ms",
          pointerEvents: showEdge || isPressed ? "all" : "none",
        }}
        onClick={(e) => {
          if (edgeWasDragged) {
            edgeWasDragged = false;
            e.preventDefault();
            return;
          }
          addRow({
            tableId,
            insert: "after",
            refRowId: rows[rows.length - 1],
            context,
            count: 1,
          });
        }}
      >
        <div
          className={
            isPressed
              ? `${styles.colResizeContainer} ${styles.isPressed}`
              : `${styles.colResizeContainer} ${styles.notPressed}`
          }
          style={{
            userSelect: showEdge || isPressed ? "all" : "none",
            transition: "background 20ms ease-in 0s",
            height: "16px",
          }}
        >
          <div className={styles.addRow}>
            <PlusOutlined className={styles.icons} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const TableBottomRightEdge: React.FC<{
  tableId: string;
  columns: string[];
  rows: string[];
  context: IBlockContext;
  tableRef: React.MutableRefObject<HTMLTableElement | null>;
}> = ({ tableId, columns, rows, context, tableRef }) => {
  const [showEdge, setshowEdge] = useState(false);
  const [isPressed, setisPressed] = useState(false);

  useEffect(() => {
    const removePressed = () => {
      setisPressed(false);
    };
    document.body.addEventListener("mouseup", removePressed);
    return () => {
      document.body.removeEventListener("mouseup", removePressed);
    };
  }, []);

  useEffect(() => {
    const sub = tableListeners.subscribe((tableFocusData) => {
      if (tableFocusData.hoverTableId === tableId) {
        if (
          tableFocusData.hoverRowId === rows[rows.length - 1] &&
          tableFocusData.hoverColumnId === columns[columns.length - 1]
        )
          return setshowEdge(true);
      }
      setshowEdge(false);
    });
    return () => sub?.unsubscribe();
  }, [tableId, columns, rows]);

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        right: "0px",
        bottom: "0px",
      }}
      onMouseDown={(e) => {
        setisPressed(true);
        e.stopPropagation();
        edgeWasDragged = false;
        resizeTableHandler(e, { tableId, context, direction: "all", tableRef });
      }}
      onMouseEnter={() => {
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: columns[columns.length - 1],
          hoverRowId: rows[rows.length - 1],
          hoverTableId: tableId,
        });
      }}
      onMouseLeave={() => {
        tableListeners.next({
          ...tableListeners.value,
          hoverColumnId: "",
          hoverRowId: "",
          hoverTableId: "",
        });
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          opacity: showEdge || isPressed ? 1 : 0,
          transition: "opacity 150ms ease 50ms",
          pointerEvents: showEdge || isPressed ? "all" : "none",
        }}
        onClick={(e) => {
          if (edgeWasDragged) {
            edgeWasDragged = false;
            e.preventDefault();
            return;
          }
          batch(() => {
            addColumn({
              tableId,
              insert: "after",
              refColumnId: columns[columns.length - 1],
              context,
              count: 1,
            });
            addRow({
              tableId,
              insert: "after",
              refRowId: rows[rows.length - 1],
              context,
              count: 1,
            });
          });
        }}
      >
        <div
          className={
            isPressed
              ? `${styles.colResizeContainer} ${styles.isPressed}`
              : `${styles.colResizeContainer} ${styles.notPressed}`
          }
          style={{
            userSelect: showEdge || isPressed ? "all" : "none",
            transition: "background 20ms ease-in 0s",
            height: "16px",
            borderRadius: "50%",
          }}
        >
          <div className={styles.addRow}>
            <PlusOutlined className={styles.icons} />
          </div>
        </div>
      </div>
    </div>
  );
};
