import { tokenGateApi } from "clientApi/tokenGatesApi";
import Button, { ButtonTypes } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useSharingAndPermsModalState } from "store/reducers/clientReducer";
import { ClarityStore } from "store/storeExporter";
import { Abilities, PermissionTypes } from "utilities/types";
import {
  usePermissionDeltaContext,
  usePermissionsDropdownState,
} from "./dropdownstate";
import styles from "./sharingAndPermsModal.module.scss";
import { PlusOutlined, KeyOutlined } from "@ant-design/icons";
import EditTwoTone from "icons/Components/EditTwoTone";
import { PermissionsDropdownComponent } from "./PermissionDropDown";
import { Skeleton } from "antd";
import { AddTokenGateModal } from "clarity-ui/tokenGateContainer/TokenGateSelector";
import { DropDownType, loadingData, TokenPerMissionDisplay } from "./util";

export const TokenRequirements: React.FC = () => {
  const { containerId, containerType, gateId, gateLevel } =
    useSharingAndPermsModalState();
  const onSelect = useCallback(
    (id?) => {
      tokenGateApi.assignGateToContainer({
        containerId,
        containerType,
        gateId: id,
      });
      setadding(false);
    },
    [containerId, containerType]
  );

  const tokenGate: any = useSelector(
    (store: ClarityStore) => store.tokenGates.dict[gateId],
    shallowEqual
  );

  const { ...dropdownProps } = usePermissionsDropdownState({
    initialValue: gateLevel ? gateLevel : "suggest",
    readOnly: false,
    permissions: tokenGate?.permissions,
    initialPermOptions: {
      suggest: true,
      edit: true,
      remove: true,
      priv: false,
      view: false,
    },
    onChange: (newPermission: string) => {
      if (newPermission === "remove") {
        tokenGateApi.assignGateToContainer({
          containerId,
          containerType,
        });
      } else {
        tokenGateApi.updateTokenGate(gateId, {
          containerId,
          containerType,
          gateLevel: newPermission as PermissionTypes,
        });
      }
    },
  });

  const [adding, setadding] = useState(false);
  const [loading, setloading] = useState(tokenGate ? false : true);
  const { editable } = usePermissionDeltaContext();
  const canEditShareData = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_TOKEN_GATES,
  });

  useEffect(() => {
    const loadingSub = loadingData.subscribe((data) => {
      if (!tokenGate) setloading(data);
      else setloading(false);
    });
    return () => loadingSub?.unsubscribe();
  }, [tokenGate]);

  return (
    <div className={styles.tokenRequirementsSection}>
      <div className={styles.headingWrap}>
        <span className="body medium">Token access</span>
        {!loading && editable && (
          <Button
            buttonType={ButtonTypes.PLAIN}
            disabled={!canEditShareData}
            onClick={() => setadding(true)}
            icon={!gateId ? <PlusOutlined /> : <EditTwoTone />}
          ></Button>
        )}
      </div>
      {!gateId && (
        <div className={styles.noSetup}>
          <div className={styles.icon}>
            <KeyOutlined />
          </div>
          <div>
            <div className="medium caption">No token access</div>
            <div className="regular small secondary">
              Tokens are not being used to grant access
            </div>
          </div>
        </div>
      )}

      {loading && (
        <Skeleton
          avatar={true}
          active={true}
          title={true}
          paragraph={{ rows: 2 }}
        />
      )}
      {!loading && tokenGate && (
        <div className={styles.tokenRow}>
          <div className={styles.icon}>
            <KeyOutlined />
          </div>
          <div className={styles.tokenRowDes}>
            <div className="medium caption">{tokenGate?.name}</div>
            <div className="regular small secondary">
              Anyone who holds these tokens can{" "}
              {TokenPerMissionDisplay[dropdownProps.permSelected]}
            </div>
          </div>
          <div>
            {gateId && (
              <PermissionsDropdownComponent
                type={DropDownType.guest}
                permissions={tokenGate?.permissions}
                {...dropdownProps}
                readOnly={!canEditShareData}
                editable={canEditShareData}
              />
            )}
          </div>
        </div>
      )}
      {!loading && adding && (
        <AddTokenGateModal onSelect={onSelect} setadding={setadding} />
      )}
    </div>
  );
};
