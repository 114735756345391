import { BlockMenuTypes, BlockStateMenu } from "utilities/types";

export const checkOpenMenuOptions = (menuState: BlockStateMenu) => {
  switch (menuState.type) {
    case BlockMenuTypes.tagsMenu: {
      const selection = document.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range: Range = selection.getRangeAt(0);
        const newNode = document.createElement("span");
        newNode.classList.add("mention-placeholder");
        let lastNode = null;
        if (menuState.options?.hashtag) {
          lastNode = document.createTextNode("#");
        } else lastNode = document.createTextNode("\uFEFF");
        if (lastNode) newNode.appendChild(lastNode);
        range.insertNode(newNode);
        range.collapse();
        const newRange = new Range();
        selection.removeAllRanges();
        newRange.setStartAfter(lastNode);
        selection.addRange(newRange);
      }
      break;
    }
    case BlockMenuTypes.userMentions: {
      const selection = document.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range: Range = selection.getRangeAt(0);
        const newNode = document.createElement("span");
        newNode.classList.add("user-mention-placeholder");
        let lastNode = null;
        lastNode = document.createTextNode("@");
        if (lastNode) newNode.appendChild(lastNode);
        range.insertNode(newNode);
        range.collapse();
        const newRange = new Range();
        selection.removeAllRanges();
        newRange.setStartAfter(lastNode);
        selection.addRange(newRange);
      }
      break;
    }
    case BlockMenuTypes.linkEntityMenu: {
      const newPlaceholder = document.createElement("span");
      newPlaceholder.classList.add("link-entity-placeholder");
      const selection = document.getSelection();
      newPlaceholder.innerHTML = "\uFEFF";
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.insertNode(newPlaceholder);
        const newRange = new Range();
        newRange.setStart(newPlaceholder, 1);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
      break;
    }
    case BlockMenuTypes.snippetMenu: {
      const selection = document.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range: Range = selection.getRangeAt(0);
        const newNode = document.createElement("span");
        newNode.classList.add("snippet-placeholder");
        let lastNode = null;
        lastNode = document.createTextNode(";");
        if (lastNode) newNode.appendChild(lastNode);
        range.insertNode(newNode);
        range.collapse();
        const newRange = new Range();
        selection.removeAllRanges();
        newRange.setStartAfter(lastNode);
        selection.addRange(newRange);
      }
      break;
    }

    default: {
      const selection = document.getSelection();
      if (selection && selection.rangeCount > 0) {
        const placeholder = document.createElement("span");
        placeholder.classList.add("slash-placeholder");
        placeholder.innerHTML = "/";
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          range.insertNode(placeholder);
          const newRange = new Range();
          newRange.setStart(placeholder, 1);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
    }
  }
};
