import Fuse from "fuse.js";

export const containerSearchFuse = (options: any[], query: string) => {
  const fuse = new Fuse(options, {
    shouldSort: true,
    threshold: 0.4,
    minMatchCharLength: 2,
    keys: ["name", "projectId"],
  });
  return fuse.search(query).map((result) => result.item);
};
