import React from "react";
import { Link, useHistory } from "react-router-dom";
import { abbreviatedMonths } from "../utilities/dateTime";
import { useDispatch } from "react-redux";
import { appClipboardData, flashBlock, flashBlockSubject } from "../App";
import { SET_MODAL_CITATION_SYNC } from "../store/actions";
import { Line } from "../utilities/lineUtilities";
import {
  DisconnectOutlined,
  FileSyncOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import Button, { ButtonTypes } from "../components/Button";
import { stripHtml } from "../utilities/stringUtilities";
import store, { openCard } from "../store/storeExporter";
import { Abilities, ContainerTypes, ViewNames } from "../utilities/types";
import { ChunkDestination, getChunkDestination } from "utilities/stateTypes";
import { copyHTMLToClipBoard } from "editor/utils/blockModeEvents";
import { removeCitation, removeTag } from "editor/utils/caretUtils";
import notificationsApi from "clientApi/notificationsApi";
import { getHtml } from "editor/utils/blockValueHelpers";
import navigationApi from "clientApi/navigationApi";
import { useAbilityChecker } from "editor/utils/customHooks";
const { v4: uuidv4 } = require("uuid");

interface Props {
  loading?: boolean;
  cardData?: {
    title: string;
    firstBlock: string;
    link: string;
    citationData: any;
  };
  focusElement?: any;
  blockData?: any;
  citationData?: any;
  destination?: string;
  documentId?: string;
}

const PopupCard: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatcher = useDispatch();
  const isBaseMember = store.getState().client.isBaseMember;
  const canEdit = useAbilityChecker({ abilityName: Abilities.CAN_EDIT_ENTITY });
  let date;
  if (props.cardData && props.cardData.citationData) {
    date = new Date(props.cardData.citationData.dateAccessed);
  }
  if (props.loading) return <div className="card-popup">Loading</div>;
  if (props.cardData)
    return (
      <div onMouseDown={(e) => {}} className="card-popup">
        <div
          style={{
            display: "flex",
            marginBottom: 0,
            paddingLeft: "2px",
          }}
        >
          <div className="body bold" style={{ flexGrow: 1, height: "20px" }}>
            <Link
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                openCard.next(null);
                if (props.destination !== ChunkDestination.secondary) {
                  if (e.shiftKey) {
                    if (props.citationData) {
                      navigationApi.openSplitView({
                        viewName: ViewNames.Detail,
                        containerId: props.citationData?.containerId,
                        containerType: props.citationData?.containerType,
                      });
                    } else {
                      navigationApi.openSplitView({
                        viewName: ViewNames.Detail,
                        containerId: props.documentId,
                        containerType: ContainerTypes.DOCUMENT,
                      });
                    }
                    e.preventDefault();
                  } else {
                    if (props.cardData) history.push(props.cardData.link);
                    e.preventDefault();
                    e.stopPropagation();
                  }
                } else {
                  if (!e.shiftKey) {
                    if (props.citationData) {
                      navigationApi.openSplitView({
                        viewName: ViewNames.Detail,
                        containerId: props.citationData?.containerId,
                        containerType: props.citationData?.containerType,
                      });
                    } else {
                      navigationApi.openSplitView({
                        viewName: ViewNames.Detail,
                        containerId: props.documentId,
                        containerType: ContainerTypes.DOCUMENT,
                      });
                    }
                    e.preventDefault();
                  } else {
                    if (props.cardData) history.push(props.cardData.link);
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }
                flashBlock.id = props.citationData?.sourceBlockId;
                flashBlockSubject.next(flashBlock);
              }}
              to={props.cardData.link}
            >
              <div
                className="caption primary bold"
                style={{
                  marginTop: "2px",
                  maxHeight: "20px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{ __html: props.cardData.title }}
              ></div>
            </Link>
          </div>
          {props.citationData && (
            <Button
              buttonType={ButtonTypes.LINK}
              style={{
                marginTop: "-4px",
                marginRight: "-4px",
                marginBottom: props.cardData.firstBlock ? 0 : "-3px",
              }}
              icon={<CopyOutlined />}
              onClick={(e: any) => {
                let citationInHtml: any = null;
                const citationsInHtml = document.querySelectorAll("citation");
                citationsInHtml.forEach((el) => {
                  if (
                    el.getAttribute("data-citation-id") ===
                    props.citationData.citationId
                  ) {
                    citationInHtml = el;
                  }
                });

                const citationData = props.citationData;
                appClipboardData.sourceBlockId = citationData.sourceBlockId;
                appClipboardData.sourceBlockBaseId =
                  citationData.sourceBlockBaseId;
                appClipboardData.sourceContainer = {
                  containerId: citationData.containerId,
                  containerType: citationData.containerType,
                };
                appClipboardData.id = uuidv4();
                if (
                  appClipboardData.sourceContainer.containerType ===
                  ContainerTypes.PROJECT
                ) {
                  appClipboardData.sourceContainer.projectId =
                    citationData.projectId;
                }

                appClipboardData.citedBlockHtml = getHtml(
                  props.citationData.value
                );

                const fragment = document.createDocumentFragment();
                const element = document.createElement("span");
                element.innerHTML = citationInHtml.innerHTML;
                fragment.appendChild(element);

                copyHTMLToClipBoard(fragment, true);

                appClipboardData.mode = "citationCopied";
                appClipboardData.copyDate = new Date();
                notificationsApi.displayConfirmation({
                  title: "Citation copied!",
                });
              }}
            />
          )}
          {isBaseMember && (
            <Button
              buttonType={ButtonTypes.LINK}
              icon={<DisconnectOutlined />}
              disabled={!canEdit}
              style={{
                marginTop: "-4px",
                marginRight: "-4px",
                marginBottom: props.cardData.firstBlock ? 0 : "-3px",
              }}
              onMouseDown={(e: any) => {
                if (!canEdit) return;
                if (props.focusElement) {
                  removeTag(
                    props.blockData,
                    props.focusElement,
                    dispatcher,
                    props.destination
                  );
                } else {
                  removeCitation(
                    props.blockData,
                    props.cardData?.citationData,
                    dispatcher,
                    props.destination
                  );
                }
                e.preventDefault();
                e.stopPropagation();
              }}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
        </div>
        {props.cardData.firstBlock && (
          <div
            className="small secondary"
            dangerouslySetInnerHTML={{
              __html: props.cardData.firstBlock,
            }}
            style={{
              paddingLeft: "2px",
              paddingTop: "2px",
              maxHeight: "64px",
              overflow: "hidden",
              marginBottom: "2px",
            }}
          ></div>
        )}
        {date && (
          <div
            style={{
              display: "flex",
              paddingTop: "12px",
              paddingLeft: "2px",
              paddingBottom: "4px",
              alignItems: "baseline",
            }}
          >
            <div className="small secondary" style={{ flexGrow: 1 }}>
              <FileSyncOutlined style={{ marginRight: "6px" }} />
              Date Accessed:{" "}
              <span>{`${
                abbreviatedMonths[date.getMonth()]
              } ${date.getDate()}, ${date.getFullYear()}`}</span>
            </div>
            <div className="small secondary" style={{ paddingRight: "4px" }}>
              {props.citationData && checkForCitationCompare(props, dispatcher)}
            </div>
          </div>
        )}
      </div>
    );
  return <></>;
};

const checkForCitationCompare = (props: Props, dispatcher: any) => {
  let citationInHtml: any = null;
  const citationsInHtml = document.querySelectorAll("citation");
  citationsInHtml.forEach((el) => {
    if (el.getAttribute("data-citation-id") === props.citationData.citationId) {
      citationInHtml = el;
    }
  });
  const citationLatestText = stripHtml(getHtml(props.citationData.value));

  if (!citationInHtml) return;

  if (citationInHtml?.textContent === citationLatestText)
    return (
      <div
        className="caption"
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        Up to date
      </div>
    );

  return (
    <p
      className="caption"
      style={{ cursor: "pointer", textDecoration: "underline" }}
      onMouseDown={(e) => {
        openCompareVersions(
          props.citationData,
          props.blockData,
          dispatcher,
          props.destination
        );
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      Compare with source
    </p>
  );
};

const openCompareVersions = (
  citationData: any,
  blockData: Line,
  dispatcher: any,
  destination: string | undefined
) => {
  dispatcher({
    type: SET_MODAL_CITATION_SYNC,
    param: {
      blockId: blockData.id,
      blockData,
      citationData,
      display: true,
    },
    destination: getChunkDestination(destination),
  });
};

export default PopupCard;
