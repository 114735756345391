import React from "react";
import styles from "clarity-ui/clarityLogo/clarityLogo.module.scss";
import { useIsMobile } from "utilities/hooks";
import ClarityLogoGray from "icons/Components/ClarityLogoGray";

interface IClarityLogoProps {
  containerStyle?: (ctx: Ctx) => React.CSSProperties;
  imgStyle?: (ctx: Ctx) => React.CSSProperties;
}

interface Ctx {
  isMobile: boolean;
}

export default function ClarityLogo({
  containerStyle,
  imgStyle,
}: IClarityLogoProps) {
  const isMobile = useIsMobile();
  const ctx = { isMobile };

  return (
    <div
      className={styles.container}
      style={!containerStyle ? {} : containerStyle(ctx)}
    >
      <ClarityLogoGray
        alt="Clarity Logo"
        style={!imgStyle ? {} : imgStyle(ctx)}
      />
    </div>
  );
}
