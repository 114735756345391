import React, { useLayoutEffect, useState, useEffect } from "react";
import { Form } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { batch } from "react-redux";
import { setAccessToken } from "utilities/authTokens";
import styles from "./logInForm/logInForm.module.scss";
import { locationSubject } from "components/LocationListener";
import { WalletLoginButton } from "components/WalletLoginButton";
import ClarityButton, { ButtonTypes } from "components/Button";
import CloseSquareTwoTone from "icons/Components/CloseSquareTwoTone";
import QuestionCircleIcon from "icons/Components/QuestionCircleIcon";
import { LoginWithEmailButton } from "components/LoginWithEmailButton";
import ClarityInput from "components/ClarityInput";
import { validateEmail } from "utilities/regexUtilities";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import { UserEventTypes } from "utilities/types";
import eventApi from "clientApi/eventApi";
import { axiosInstance } from "index";
import { VALUE_ENTER } from "keycode-js";

interface ILoginFormProps {
  redirectPath?: string;
  reset?: boolean;
  setcurrentView?: React.Dispatch<React.SetStateAction<number>>;
  invToken?: string;
  noRedirect?: boolean;
  onLogin?: () => void;
  viralSignupType?: UserEventTypes;
  viewConfig?: {
    showEmailLogin: boolean;
    showWhatIsSiwe: boolean;
    buttonGap?: string;
    showTokenGateEmailLogin?: boolean;
  };
}

const resetLoginErrors = () => ({
  email: "",
  otp: "",
  password: "",
});

function LogInForm(props: ILoginFormProps) {
  const { redirectPath } = props;
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [loginWithEmail, setLoginWithEmail] = useState<boolean>(false);
  const [whatIsEthereum, setWhatIsEthereum] = useState<boolean>(false);
  const inviteToken = query.get("invt") || "";

  useEffect(() => {
    if (props.reset) {
      setLoginWithEmail(false);
      setWhatIsEthereum(false);
    }
  }, [props.reset]);

  return (
    <>
      <div className={styles.loginFormContainer}>
        {loginWithEmail && !whatIsEthereum && (
          <EmailLogin
            reset={props.reset}
            noRedirect={props.noRedirect}
            redirectPath={redirectPath}
            invToken={props.invToken}
            setcurrentView={props.setcurrentView}
          />
        )}
        {!loginWithEmail && !whatIsEthereum && (
          <LoginChoice
            setLoginWithEmail={setLoginWithEmail}
            setWhatIsEthereum={setWhatIsEthereum}
            viewConfig={props.viewConfig}
            viralSignupType={props.viralSignupType}
            afterEffect={() => {
              if (props.noRedirect) return;
              if (props.invToken) {
                return;
              } else if (inviteToken) {
                history.push(`/invite/?invt=${inviteToken ?? props.invToken}`);
                return;
              }
              const redirectTo = redirectPath || query.get("redirectTo");
              if (!redirectTo) {
                return locationSubject.next(`/`);
              }
              return locationSubject.next(`${redirectTo}`);
            }}
          />
        )}
        {!loginWithEmail &&
          whatIsEthereum &&
          (!props.viewConfig || props.viewConfig?.showWhatIsSiwe) && (
            <WhatIsLoginWithEthereum setWhatIsEthereum={setWhatIsEthereum} />
          )}
      </div>
    </>
  );
}

const LoginChoice: React.FC<{
  setLoginWithEmail: React.Dispatch<React.SetStateAction<boolean>>;
  afterEffect: () => void;
  setWhatIsEthereum: React.Dispatch<React.SetStateAction<boolean>>;
  viralSignupType?: UserEventTypes;
  viewConfig?: {
    showEmailLogin: boolean;
    showWhatIsSiwe: boolean;
    buttonGap?: string;
    showTokenGateEmailLogin?: boolean;
  };
}> = ({
  setLoginWithEmail,
  afterEffect,
  setWhatIsEthereum,
  viewConfig,
  viralSignupType,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const element = document.getElementById("clarity-logo");
    if (loading && element) {
      element.style.pointerEvents = "none";
    } else if (element) {
      element.style.pointerEvents = "unset";
    }
  }, [loading]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <WalletLoginButton
        afterEffect={afterEffect}
        loadingState={(loading) => setLoading(loading)}
        viralSignupType={viralSignupType}
        mode="organic"
      />
      {(!viewConfig || viewConfig?.showEmailLogin) && (
        <LoginWithEmailButton
          onClick={() => {
            setLoginWithEmail(true);
          }}
          style={
            viewConfig?.buttonGap
              ? { marginTop: viewConfig.buttonGap }
              : undefined
          }
          loading={loading}
        />
      )}
      {viewConfig && viewConfig.showTokenGateEmailLogin && (
        <LoginWithEmailLink
          loading={loading}
          onClick={() => {
            setLoginWithEmail(true);
          }}
        />
      )}
      {(!viewConfig || viewConfig?.showWhatIsSiwe) && (
        <ClarityButton
          labelClass={styles.lgoinBottomText}
          buttonType={ButtonTypes.LINK}
          style={{
            marginTop: "38px",
            paddingLeft: "7px",
          }}
          disabled={loading}
          onClick={() => {
            setWhatIsEthereum(true);
          }}
          icon={<QuestionCircleIcon alt="Question Circle" />}
        >
          What is Sign-in with Ethereum?
        </ClarityButton>
      )}
    </div>
  );
};

const LoginWithEmailLink: React.FC<{
  onClick: () => void;
  loading: boolean;
}> = ({ onClick, loading }) => {
  return (
    <ClarityButton
      buttonType={ButtonTypes.LINK}
      style={{
        marginTop: "24px",
        paddingLeft: "7px",
      }}
      disabled={loading}
      onClick={onClick}
      labelStyle={{
        // fontSize: "12px",
        // lineHeight: "20px",
        // color: "rgba(49, 39, 46, 0.65)",
        paddingLeft: "5px",
        fontWeight: "bold",
      }}
    >
      Already have an account with email login?
    </ClarityButton>
  );
};

const WhatIsLoginWithEthereum: React.FC<{
  setWhatIsEthereum: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setWhatIsEthereum }) => {
  useLayoutEffect(() => {
    eventApi.postEvent(UserEventTypes.WHAT_IS_SIWE_VIEWED, {});
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        flexWrap: "wrap",
        textAlign: "left",
        fontSize: "15px",
        lineHeight: "22px",
        letterSpacing: "0.02px",
        maxWidth: "580px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "40px",
        }}
      >
        <h3>What is Sign-in with Ethereum?</h3>
        <ClarityButton
          buttonType={ButtonTypes.LINK}
          icon={
            <CloseSquareTwoTone
              style={{
                fontSize: "22px",
              }}
            />
          }
          onClick={() => setWhatIsEthereum(false)}
        ></ClarityButton>
      </div>
      <div className={styles.siweInfo}>
        <span>
          Sign-in with Ethereum (SIWE) is a universal login method for the
          Internet.
        </span>
        <span>
          Similar to other login methods like Facebook and Google, it allows you
          to use the same login across multiple services. However, unlike those
          other methods, SIWE is not owned by a single big corporation. Your
          Ethereum profile is owned and controlled by you.
        </span>
        <span>
          In Clarity, SIWE can help you access certain Bases or documents that
          are only accessible to holders of specific tokens or NFTs.
        </span>
        <b>
          If this sounds intimidating, no sweat, you can still log into Clarity
          using your email address and username.
        </b>
        <span>
          If you’d like to get started using SIWE, you’ll just need to learn the
          basics of using an Ethereum wallet.
        </span>
      </div>
    </div>
  );
};

const EmailLogin: React.FC<{
  reset?: boolean;
  redirectPath: string | undefined;
  invToken?: string | undefined;
  setcurrentView?: React.Dispatch<React.SetStateAction<number>>;
  noRedirect?: boolean;
}> = ({ reset, redirectPath, invToken, setcurrentView, noRedirect }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const history = useHistory();
  const query = useQuery();
  const initialEmail = query.get("email") || "";
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otp, setOtp] = useState("");
  const [errors, seterrors] = useState(resetLoginErrors());
  const [isLoading, setIsLoading] = useState(false);
  const inviteToken = query.get("invt") || "";
  const [sentOtpEmail, setSentOtpEmail] = useState<boolean>(false);
  const [otpLogin, setOtpLogin] = useState<boolean>(true);

  useEffect(() => {
    if (reset) {
      setEmail(initialEmail);
      setPassword("");
      setOtp("");
      seterrors(resetLoginErrors());
      setIsLoading(false);
      setOtpLogin(true);
      setSentOtpEmail(false);
    }
  }, [reset]);

  useEffect(() => {
    setOtp("");
    setSentOtpEmail(false);
    seterrors(resetLoginErrors());
    setIsLoading(false);
  }, [otpLogin]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    seterrors(resetLoginErrors());
    axiosInstance
      .post("/api/auth/login", {
        username: email,
        password: otpLogin ? otp : password,
      })
      .then(({ data }) => {
        handleConfirmation(data);
      })
      .catch((err) => {
        if (sentOtpEmail) {
          handleError("Sorry, that’s incorrect. Please try again.", "otp");
          return;
        }
        setIsLoading(false);
        handleError("Invalid email/password combination", "password");
      });
  };

  const sendOtpEmail = () => {
    const emailIsValid = validateEmail(email);
    if (!emailIsValid) {
      handleError("Email is not valid", "email");
      return;
    }
    setIsLoading(true);
    seterrors(resetLoginErrors());
    axiosInstance
      .post("/api/otp", {
        email: email,
      })
      .then(({ data }) => {
        setSentOtpEmail(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        handleError(err.response?.data?.message, "otp");
      });
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "link":
        setOtp(value);
        break;
      default:
        console.log("unanticipated form field");
        break;
    }
  };

  const handleConfirmation = (data: any) => {
    setIsLoading(false);
    seterrors(resetLoginErrors());
    batch(() => {
      setAccessToken(data.accessToken);
    });
    if (noRedirect) return;
    if (invToken) {
      return;
    } else if (inviteToken) {
      history.push(`/invite/?invt=${inviteToken || invToken}`);
      return;
    }
    const redirectTo = redirectPath || query.get("redirectTo");

    if (!redirectTo) {
      return locationSubject.next(`/`);
      // return window.location.replace("/");
    }
    return locationSubject.next(`${redirectTo}`);
    // return window.location.replace();
  };

  const handleError = (message: string, key: "password" | "email" | "otp") => {
    setIsLoading(false);
    seterrors({ ...errors, [key]: message });
  };

  return (
    <div className={styles.loginForm}>
      <Form
        onSubmitCapture={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === VALUE_ENTER) {
            if (otpLogin) {
              if (!email || !otp) e.preventDefault();
              else handleSubmit(e);
            } else {
              if (!email || !password) e.preventDefault();
              else handleSubmit(e);
            }
          }
        }}
      >
        <div className={styles.loginForm__formSection}>
          <Form.Item name="username">
            <ClarityInput
              label={otpLogin ? "Email" : "Username or Email"}
              onChange={handleChange}
              name="email"
              value={email}
              placeholder={otpLogin ? "Your Email" : "Your username or email"}
              required
              autoFocus={true}
              disabled={sentOtpEmail && otpLogin}
            />
            {errors.email && (
              <ClarityLabel
                type={MessageLabelTypes.error}
                message={errors.email}
              />
            )}
          </Form.Item>
        </div>
        {otpLogin && (
          <div style={{ marginBottom: "40px" }}>
            {sentOtpEmail ? (
              <div className={styles.loginForm__formSection}>
                <div
                  style={{
                    marginTop: "24px",
                    marginBottom: "24px",
                    paddingLeft: "4px",
                  }}
                >
                  <span className={styles.loginDesText}>
                    We just sent a single-use login code to the email above.
                    Please check your inbox.
                  </span>
                </div>
                <input name="password" style={{ display: "none" }} />
                <Form.Item style={{ marginBottom: "36px" }}>
                  <ClarityInput
                    label="Login Code"
                    onChange={handleChange}
                    name="link"
                    autoComplete="off"
                    value={otp}
                    about={"otp"}
                    placeholder="Paste login code"
                    required={otpLogin && sentOtpEmail}
                  />
                  {errors.otp && (
                    <ClarityLabel
                      type={MessageLabelTypes.error}
                      message={errors.otp}
                    />
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: "24px" }}>
                  <ClarityButton
                    isLoading={isLoading}
                    buttonType={ButtonTypes.LARGE_PRIMARY}
                    type="submit"
                    disabled={!email || !otp}
                  >
                    Continue
                  </ClarityButton>
                </Form.Item>
              </div>
            ) : (
              <>
                <ClarityButton
                  buttonType={ButtonTypes.LARGE_PRIMARY}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    sendOtpEmail();
                  }}
                  isLoading={isLoading}
                  style={{
                    marginBottom: "16px",
                    marginTop: "12px",
                  }}
                >
                  Send login code
                </ClarityButton>

                <ClarityButton
                  buttonType={ButtonTypes.LARGE_SECONDARY}
                  disabled={isLoading}
                  onClick={() => {
                    setOtpLogin(false);
                  }}
                >
                  Continue with password
                </ClarityButton>
              </>
            )}
          </div>
        )}

        <div
          style={
            otpLogin
              ? { display: "none" }
              : { display: "unset", marginTop: "24px" }
          }
        >
          <div className={styles.loginForm__formSection}>
            <Form.Item style={{ marginBottom: "6px" }}>
              <ClarityInput
                label="Password"
                onChange={handleChange}
                type="password"
                name="password"
                value={password}
                placeholder="Enter password"
                required={!otpLogin}
              />
            </Form.Item>
            {errors.password && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "8px",
                }}
              >
                <ClarityLabel
                  type={MessageLabelTypes.error}
                  message={errors.password}
                />
              </div>
            )}
          </div>
          <div className={styles.loginForm__forgot_password}>
            <ClarityButton
              buttonType={ButtonTypes.LINK}
              tabIndex={-1}
              onClick={() => history.push("/forgot-password")}
            >
              Forgot password
            </ClarityButton>
          </div>

          <Form.Item style={{ marginBottom: "32px" }}>
            <ClarityButton
              isLoading={isLoading}
              tabIndex={0}
              type="submit"
              buttonType={ButtonTypes.LARGE_PRIMARY}
              disabled={!email || !password}
            >
              Continue
            </ClarityButton>
          </Form.Item>
        </div>

        <ClarityButton
          buttonType={ButtonTypes.LINK}
          className={styles.infoBtn}
          disabled={isLoading}
          style={{ width: "100%", height: "auto", lineHeight: "20px" }}
          onClick={() => {
            if (setcurrentView) {
              setcurrentView(2);
              return;
            }
            const redirectTo = redirectPath || query.get("redirectTo");
            if (redirectTo)
              return history.push(`/signup?redirectTo=${redirectTo}`);
            else history.push("/signup");
          }}
        >
          New here? <b> Create an account</b>
        </ClarityButton>
      </Form>
    </div>
  );
};

export default LogInForm;
