import { Link } from "react-router-dom";
import styles from "./documentCellMain/documentCellMain.module.scss";
import { ContainerTypes, ViewNames } from "../utilities/types";
import { KeyCodes } from "../utilities/lineUtilities";
import { getHtml } from "editor/utils/blockValueHelpers";
import navigationApi from "clientApi/navigationApi";
import { useShallowSelector } from "utilities/hooks";
import { ChunkDestination } from "utilities/stateTypes";
import { stripHtml } from "utilities/stringUtilities";

interface IDocumentCellMainProps {
  cell: any;
  paneId: ChunkDestination;
}

function DocumentCellMain(props: IDocumentCellMainProps) {
  const { cell, paneId } = props;
  const base = useShallowSelector((store) => store.workspace);

  const navigate = (shiftKey: boolean) => {
    navigationApi.contextBasedNavigate({
      currentPane: paneId,
      shiftKey,
      navigationChunk: {
        viewName: ViewNames.Detail,
        entity: {
          containerId: cell.row.original.id,
          containerType: ContainerTypes.DOCUMENT,
        },
      },
    });
  };

  return (
    <div className={styles.documentList__cell_main}>
      <div className={styles.documentList__cell_main__bodyContainer}>
        <Link
          to={`/${base.slug}/tags/${cell.row.original.id}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(e.shiftKey);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === KeyCodes.enter) {
              e.preventDefault();
              e.stopPropagation();
              navigate(e.shiftKey);
            }
          }}
        >
          {cell.row.original.nameValue ? (
            <span
              className={styles.documentList__cell_main__name}
              dangerouslySetInnerHTML={{
                __html: stripHtml(getHtml(cell.row.original.nameValue)),
              }}
            ></span>
          ) : (
            <span className={styles.documentList__cell_main__name}>
              {cell.row.original.name}
            </span>
          )}
        </Link>
      </div>
    </div>
  );
}

export default DocumentCellMain;
