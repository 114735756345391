import React from "react";
import ModalScrimComponent from "components/ModalScrimComponent";
import styles from "./modal/modal.module.scss";
import { useOptionalClassName } from "utilities/hooks";

interface IModalProps {
  hideModal: () => void;
  children: any | any[];
  size?: "small" | "medium" | "large" | "extraLarge" | "full";
  padding?: boolean;
  zIndex?: number;
  className?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
}

export default function Modal(props: IModalProps) {
  const {
    children,
    size,
    hideModal,
    padding = true,
    zIndex,
    className,
    onKeyDown,
  } = props;
  const maxWidth = size
    ? {
        full: "97vw",
        extraLarge: "818px",
        large: "640px",
        medium: "562px",
        small: "484px",
      }[size] ?? "484px"
    : "484px";

  const modalClasses = useOptionalClassName(
    {
      baseStyle: styles.modal,
      pairs: [
        {
          extraStyle: props.className ? props.className : "",
          withExtra: props.className ? true : false,
        },
        {
          extraStyle: styles.extraPadding,
          withExtra: padding,
        },
      ],
    },
    [padding, className]
  );

  return (
    <div
      style={{
        display: "flex",
        zIndex: zIndex ? zIndex : 99,
        position: "fixed",
        maxHeight: "100%",
        overflowX: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <div
        tabIndex={0}
        className={modalClasses}
        style={{
          marginTop: "-48px",
          margin: "auto",
          zIndex: zIndex ? zIndex + 1 : 100,
          width: "100%",
          maxWidth,
          height: size === "full" ? "97vh" : "auto",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={onKeyDown}
      >
        {children}
      </div>
      <ModalScrimComponent hideModal={hideModal} />
    </div>
  );
}
