import { startBlockMove } from "editor/utils/specificActions/moveActions";
import { checkImageDrop } from "editor/utils/specificActions/pasteActions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DRAG_TOGGLE } from "store/actions";
import { getBlockById } from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store from "store/storeExporter";
import { ContainerTypes } from "utilities/types";

const getPadding = (dropType: string) => {
  return dropType === "child" ? "20px" : "0";
};

const getWidth = (dropType: string) => {
  return dropType === "child" ? "calc(100% - 20px)" : "100%";
};

const getZindex = (dropType: string, indentLevel: number) => {
  return dropType === "child" ? indentLevel + 2 : indentLevel + 1;
};

const getZoneLeft = (dropType: string, indentLevel: number) => {
  if (indentLevel === 0 && dropType === "sibling") return "-80px";
  if (indentLevel === 0 && dropType === "child") return "20px";
  return dropType === "child" ? "20px" : "-20px";
};

const BlockDropArea: React.FC<any> = (props) => {
  const [draggedOver, setdraggedOver] = useState(false);
  useEffect(() => {
    if (!props.dragStart) {
      setdraggedOver(false);
    }
  }, [props.dragStart]);

  if (props.dragStart && !props.readOnly && !props.selected)
    return (
      <div className="drag-seperator" style={{ flex: "0 0 100%" }}>
        <div
          style={{
            zIndex: getZindex(props.dropType, props.indentLevel),
            left: getZoneLeft(props.dropType, props.indentLevel),
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setdraggedOver(false);
          }}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setdraggedOver(true);
          }}
          onDragEnd={(e) => {
            setdraggedOver(false);
          }}
          onDrop={(e) => {
            e.preventDefault();
            const data = e.dataTransfer.getData("clarity-move");
            setdraggedOver(false);
            props.toggleDragMode(false);
            if (data) {
              const clarityDragObj = JSON.parse(data);
              const newState = store.getState().blocks;
              const block = getBlockById(newState.dict, props.id);
              if (clarityDragObj.draggedBlockId) {
                const draggedBlock = getBlockById(
                  newState.dict,
                  clarityDragObj.draggedBlockId
                );
                if (
                  draggedBlock.containerType === ContainerTypes.WORK_ACTIVITY &&
                  draggedBlock.containerId !== block.containerId
                )
                  return;
              }

              if (props.dropType === "child") {
                if (block.isFolded && block.children.length > 0) {
                  const lastBlockId = block.children[block.children.length - 1];
                  startBlockMove({
                    context: props.context,
                    options: "sibling",
                    type: props.options,
                    referenceId: lastBlockId,
                    movedBlockId: clarityDragObj.draggedBlockId,
                  });
                  return;
                }
              }
              startBlockMove({
                context: props.context,
                options: props.dropType,
                type: props.options,
                referenceId: props.id,
                movedBlockId: clarityDragObj.draggedBlockId,
              });
            } else {
              if (e.dataTransfer.files.length === 1) {
                e.preventDefault();
                e.stopPropagation();
                checkImageDrop(
                  e,
                  props.blockRef,
                  e.dataTransfer,
                  props.parentProps,
                  false
                );
                return;
              }
            }
          }}
          className={"drop-area"}
        ></div>

        {draggedOver && (
          <div
            style={{
              left: getPadding(props.dropType),
              width: getWidth(props.dropType),
            }}
            className={"drop-bar"}
          ></div>
        )}
      </div>
    );
  return <></>;
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    dragStart: state.container.dragStart,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    toggleDragMode: (dragState: boolean) =>
      dispatch({
        type: DRAG_TOGGLE,
        dragState,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockDropArea);
