import React, { useState } from "react";
import Button, { ButtonTypes } from "components/Button";

import ModalScrimComponent from "components/ModalScrimComponent";
import styles from "./modalConfirmation/modalConfirmation.module.scss";
import store from "store/storeExporter";
import { REMOVE_UNIQUE_USER } from "store/actions";
import ReactDOM from "react-dom";
import { axiosInstance } from "index";

interface Props {
  hideModal: () => void;
  type: "invitation" | "membership";
  info: any;
}

const ModalConfirmation: React.FC<Props> = ({
  type,
  info,
  hideModal,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOkClick = async () => {
    setIsLoading(true);
    if (type === "invitation") {
      try {
        const response: any = await axiosInstance.delete(
          `/api/invitation/${info.invitationId}`
        );
        if (response.data && response.data.status === 1) {
          store.dispatch({
            type: REMOVE_UNIQUE_USER,
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else if (type === "membership") {
      try {
        const response: any = await axiosInstance.post(
          `/api/workspace/revokeAccess`,
          {
            userId: info.userId,
            baseId: info.baseId,
          }
        );
        if (response.status && response.data.status === 1) {
          store.dispatch({
            type: REMOVE_UNIQUE_USER,
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
    setIsLoading(false);
    hideModal();
  };

  return ReactDOM.createPortal(
    <>
      <div
        style={{
          display: "flex",
          zIndex: 99999,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <div className={styles.modal_confirmation}>
          <h3>Are you sure?</h3>
          {type === "membership" && (
            <p>
              You are about to remove{" "}
              <strong>{info.name || `@${info.username}`}</strong> from this
              Base. They will no longer be able to access the Base, nor will
              they be able to see any of the private Docs, Tags, Projects, and
              Tasks belonging to this Base.
            </p>
          )}
          {type === "invitation" && (
            <p>
              You are about to revoke this invitation. This person will not be
              able to join the Base using the invitation that was sent to them.
              In order to invite them you will need to send another invitation.
            </p>
          )}
          <div>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              isLoading={isLoading}
              onClick={handleOkClick}
            >
              Ok
            </Button>
            <Button buttonType={ButtonTypes.DEFAULT} onClick={hideModal}>
              Cancel
            </Button>
          </div>
        </div>
        <ModalScrimComponent hideModal={hideModal} />
      </div>
    </>,
    document.body
  );
};

export default ModalConfirmation;
