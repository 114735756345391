import { IPageObj } from "../../utilities/types";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "../storeExporter";
import {
  ADD_NEW_DOCUMENT,
  CHANGE_DOC_PUBLIC_ACCESS,
  LOAD_WORKSPACE_DOCUMENTS,
  PATCH_DOCUMENT,
  REMOVE_DOCUMENT,
  SET_FOLLOWING_PAGES,
  SET_PAGES_IN_DICT,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_NAME,
} from "../actions";

interface IPagesState {
  dict: IPageDict;
  ids: string[];
  followingIds: string[];
}
const initialState: IPagesState = {
  dict: {},
  ids: [],
  followingIds: [],
};

export interface IPageDict {
  [id: string]: IPageObj;
}

export default function pagesReducer(
  state: IPagesState = initialState,
  action: any
) {
  switch (action.type) {
    case LOAD_WORKSPACE_DOCUMENTS: {
      if (action.documents) {
        const newState = { ...state };
        newState.dict = {};
        newState.ids = [];

        newState.ids = action.documents.map((doc: IPageObj) => {
          newState.dict[doc.id] = doc;
          return doc.id;
        });
        return newState;
      }
      return state;
    }

    case ADD_NEW_DOCUMENT: {
      if (action.document) {
        const newDocument = action.document;
        const newState: IPagesState = {
          ...state,
          dict: { ...state.dict },
          ids: [...state.ids],
        };
        if (newState.dict[newDocument.id]) {
          newState.dict[newDocument.id] = {
            id: newDocument.id,
            dateCreated: newDocument.dateCreated,
            dateModified: newDocument.dateModified,
            titleBlockId: newDocument.titleBlockId,
            name: newDocument.name,
            nameValue: newDocument.nameValue,
            aliases: [],
            isPublicAccess: false,
            mentions: [],
            outlineMode: newDocument.outlineMode,
          };
        } else {
          newState.dict[newDocument.id] = newDocument;
          if (!newState.ids.includes(newDocument.id))
            newState.ids.push(newDocument.id);
        }
        return newState;
      }
      return state;
    }

    case UPDATE_DOCUMENT: {
      if (action.document) {
        const newState = { ...state };
        if (newState.dict && newState.dict[action.document.id]) {
          newState.dict = { ...state.dict };
          newState.dict[action.document.id] = action.document;
          return newState;
        }
      }
      return state;
    }

    case PATCH_DOCUMENT: {
      const newState = { ...state };
      newState.dict = { ...state.dict };
      const container =
        newState.dict[action.patchContainer.container.containerId];
      newState.dict[action.patchContainer.container.containerId] = {
        ...container,
        ...action.patchContainer.patch,
      };
      return newState;
    }

    case REMOVE_DOCUMENT: {
      if (action.documentId) {
        const newState: IPagesState = {
          ...state,
          dict: { ...state.dict },
          ids: [...state.ids.filter((id) => id !== action.documentId)],
          followingIds: [
            ...state.followingIds.filter((id) => id !== action.documentId),
          ],
        };
        delete newState.dict[action.documentId];
        return newState;
      }
      return state;
    }

    case UPDATE_DOCUMENT_NAME: {
      if (action.document) {
        const newState = { ...state };
        if (newState.dict && newState.dict[action.document.id]) {
          newState.dict = { ...state.dict };
          newState.dict[action.document.id] = {
            ...state.dict[action.document.id],
          };
          newState.dict[action.document.id].name = action.document.name;
          newState.dict[action.document.id].nameValue = [
            ...action.document.nameValue,
          ];
          return newState;
        }
      }
      return state;
    }

    case CHANGE_DOC_PUBLIC_ACCESS: {
      const id = action.docId;
      const doc = state.dict[id];
      if (doc) {
        if (doc.isPublicAccess !== action.isPublicAccess) {
          const newState = { ...state };
          newState.dict = { ...newState.dict };
          doc.isPublicAccess = action.isPublicAccess;
          newState.dict[id] = { ...doc };
          return newState;
        }
      }
      return state;
    }

    case SET_FOLLOWING_PAGES: {
      const newState: IPagesState = { ...state };
      const pages = action.pages;
      if (pages.length > 0) {
        newState.dict = { ...newState.dict };
        newState.followingIds = [];
        pages.forEach((page: IPageObj) => {
          newState.followingIds.push(page.id);
          newState.dict[page.id] = page;
        });
      }
      return newState;
    }

    case SET_PAGES_IN_DICT: {
      const newState: IPagesState = { ...state };
      const pages = action.pages;
      newState.dict = { ...newState.dict, ...pages };
      return newState;
    }

    default:
      return state;
  }
}

export function usePages() {
  return useSelector(
    (state: ClarityStore) => ({
      documentsArray: state.pages.ids,
      documentsObj: state.pages.dict,
    }),
    shallowEqual
  );
}
