import { useState, useLayoutEffect, useEffect, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import styles from "./styles.module.scss";
import { DeviceType, useDeviceIdentifier } from "editor/utils/customHooks";

interface OptClassName {
  baseStyle: string;
  pairs: {
    extraStyle: string;
    withExtra?: boolean;
  }[];
}

export function useOptionalClassName(
  {
    baseStyle,
    pairs,
  }: {
    baseStyle: string;
    pairs: {
      extraStyle: string;
      withExtra?: boolean;
    }[];
  },
  deps?: React.DependencyList
) {
  const [className, setClassName] = useState(baseStyle);
  const extras = useMemo(
    () => pairs.map((pair) => pair.withExtra),
    deps ?? undefined
  );

  useLayoutEffect(
    () =>
      setClassName(
        pairs.reduce(
          (style, pair) =>
            pair.withExtra ? `${style} ${pair.extraStyle}` : style,
          baseStyle
        )
      ),
    [extras]
  );

  return className;
}

export function useShallowSelector<TState = ClarityStore, TSelected = unknown>(
  selector: (state: TState) => TSelected
) {
  return useSelector(selector, shallowEqual);
}

const useInnerWidth = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setInnerWidth(window.innerWidth);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return innerWidth;
};

export const useIsMobile = (width: number = 450) => {
  const [isMobile, setIsMobile] = useState(false);
  const innerWidth = useInnerWidth();
  useEffect(() => setIsMobile(innerWidth < width), [innerWidth]);
  return isMobile;
};

type WidthGroup = [number, string[]];

export const useWidthStyles = (ref: any, groups: WidthGroup[]) => {
  const width = useRefWidth(ref);
  const [widthStyles, setWidthStyles] = useState<string[]>([]);

  useEffect(() => {
    setWidthStyles([]);
    groups.forEach(([stylesWidth, styles]) => {
      if (width <= stylesWidth) {
        setWidthStyles(styles);
      }
    });
  }, [width]);
  return widthStyles;
};

const useRefWidth = (ref: any) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setInterval(() => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    }, 100);
  }, []);
  return width;
};

export function useCondVisibility({
  baseStyle,
  pairs = [],
  active = false,
}: Pick<OptClassName, "baseStyle"> &
  Pick<Partial<OptClassName>, "pairs"> & { active?: boolean }) {
  const className = useOptionalClassName({
    baseStyle,
    pairs: [
      ...pairs,
      {
        extraStyle: styles.condVisibleInactive,
        withExtra: !active,
      },
      {
        extraStyle: styles.condVisibleActive,
        withExtra: active,
      },
    ],
  });

  return className;
}

export enum SectionWidthModes {
  xsmall = "xsmall",
  small = "small",
  medium = "medium",
  large = "large",
  xlarge = "xlarge",
}

export const SectionNumber = {
  [SectionWidthModes.xsmall]: 0,
  [SectionWidthModes.small]: 1,
  [SectionWidthModes.medium]: 2,
  [SectionWidthModes.large]: 3,
  [SectionWidthModes.xlarge]: 4,
};
Object.freeze(SectionNumber);

export const useContainerWidth = (
  elementRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const deviceType = useDeviceIdentifier();
  const [sectionWidthMode, setsectionWidthMode] = useState<SectionWidthModes>(
    deviceType === DeviceType.desktop
      ? SectionWidthModes.xlarge
      : deviceType === DeviceType.mobile
      ? SectionWidthModes.small
      : SectionWidthModes.medium
  );

  useEffect(() => {
    if (!elementRef.current) return;
    elementRef.current.focus();
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width < 300)
          return setsectionWidthMode(SectionWidthModes.xsmall);
        if (entry.contentRect.width < 480)
          return setsectionWidthMode(SectionWidthModes.small);
        if (entry.contentRect.width < 790)
          return setsectionWidthMode(SectionWidthModes.medium);
        if (entry.contentRect.width < 1200)
          return setsectionWidthMode(SectionWidthModes.large);

        return setsectionWidthMode(SectionWidthModes.xlarge);
      }
    });
    if (elementRef.current) resizeObserver.observe(elementRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return sectionWidthMode;
};
