import { batch } from "react-redux";
import { REFOCUS } from "store/actions";
import {
  BlockReducerState,
  FocusObject,
  FocusType,
} from "store/reducers/blockReducer";
import {
  getBlockById,
  getCurrentContext,
} from "store/reducers/blockReducerHeplers/generalBlockHelpers";
import store, { $focusOn } from "store/storeExporter";
import { moveCaretAtLineEnd } from "utilities/caretMovement";
import { ContainerTypes } from "utilities/types";
import { unselectBlocks } from "../blockActions";

export const checkAccordingBlock = (
  blockRefs: React.MutableRefObject<any>,
  contextId: string,
  yValue: number,
  atEnd: boolean
) => {
  const newState = store.getState().blocks;
  const context = getCurrentContext(newState, contextId);
  let possibleArray = [...context.state.rootBlocksIds];
  let array = possibleArray;
  if (context.containerType === ContainerTypes.NOTE) {
    possibleArray.shift();
  }

  let blockId: string | null = checkIfOverTheFirstElement(
    blockRefs,
    array,
    yValue,
    atEnd
  );

  if (!blockId)
    blockId = checkHeightForIds(
      blockRefs,
      newState,
      context.state.rootBlocksIds,
      yValue,
      atEnd
    );
  if (!blockId) {
    const array = context.state.rootBlocksIds;
    blockId = giveFocusToLastElement(blockRefs, newState, array, atEnd);
  }

  batch(() => {
    if (!blockId) return;
    if (newState.selectedBlocks.length > 0 && blockId) {
      unselectBlocks({ id: blockId, type: "currentPosition" });
    }
    const newFocus: FocusObject = {
      ...$focusOn.value,
      diff: 0,
      caretPosition: 0,
      type: atEnd ? FocusType.withDiff : FocusType.prevPosition,
      focusBlockId: blockId,
    };
    store.dispatch({
      type: REFOCUS,
      param: {
        newFocus,
      },
    });
  });
  return;
};

const checkIfOverTheFirstElement = (
  blockRefs: React.MutableRefObject<any>,
  array: string[],
  yValue: number,
  atEnd: boolean
): string | null => {
  const blockId = array[0];
  const blockRef = blockRefs?.current[blockId];
  if (blockRef?.current && blockRef?.current.parentElement) {
    const parentElement = blockRef?.current.parentElement;
    const linePosition = parentElement?.getBoundingClientRect();
    const yPosition = linePosition.y;
    if (yValue < yPosition) {
      blockRef?.current.focus();
      return blockId;
    }
  }
  return null;
};

export const giveFocusToLastElement = (
  blockRefs: React.MutableRefObject<any>,
  newState: BlockReducerState,
  array: string[],
  atEnd: boolean
): string | null => {
  let blockId = null;
  const block = getBlockById(newState.dict, array[array.length - 1]);
  const blockRef = blockRefs.current[block.id];
  const childrenLength = block.children ? block.children.length : 0;
  if (childrenLength > 0 && !block.isFolded) {
    blockId = giveFocusToLastElement(
      blockRefs,
      newState,
      block.children,
      atEnd
    );
  } else {
    if (blockRef?.current && document.activeElement !== blockRef.current) {
      if (atEnd) moveCaretAtLineEnd(blockRef.current, 0);
      blockRef.current.focus();
      blockId = block.id;
    }
  }
  return blockId;
};

export const checkHeightForIds = (
  blockRefs: React.MutableRefObject<any>,
  newState: BlockReducerState,
  array: string[],
  yValue: number,
  atEnd: boolean
): string | null => {
  let foundId = null;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  for (const blockId of array) {
    const block = getBlockById(newState.dict, blockId);
    const element = blockRefs.current[blockId]?.current;
    if (element) {
      const linePosition = element.getBoundingClientRect();
      const yPosition = linePosition.y + scrollTop;
      const lineHeightString = window.getComputedStyle(element).height;
      const numberOfChars = lineHeightString.length;
      const lineHeight: number = Number(
        lineHeightString.substring(0, numberOfChars - 2)
      );
      if (
        yValue - lineHeight <= yPosition &&
        yPosition <= yValue + lineHeight
      ) {
        if (element && document.activeElement !== element) {
          if (atEnd) moveCaretAtLineEnd(element, 0);
          element.focus();
        }
        foundId = blockId;
        return foundId;
      } else {
        if (block.children && block.children.length > 0) {
          const foundLine = checkHeightForIds(
            blockRefs,
            newState,
            block.children,
            yValue,
            atEnd
          );
          if (foundLine) return foundLine;
        }
      }
    }
  }
  return foundId;
};
