import Icon from "@ant-design/icons";
import React from "react";

export default function TagsTwoTone(props: React.ComponentProps<typeof Icon>) {
  return <Icon {...props} component={SVG} />;
}

function SVG() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="1.1em"
      height="1.1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        d="M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM20.8125 18.5625H3.1875V5.4375H20.8125V18.5625Z"
        fill="currentColor"
      />
      <path
        opacity="0.1"
        d="M3.1875 18.5625H20.8125V5.4375H3.1875V18.5625ZM14.25 9.84375C14.25 9.74062 14.2734 9.65625 14.3039 9.65625H17.1961C17.2266 9.65625 17.25 9.74062 17.25 9.84375V10.9688C17.25 11.0719 17.2266 11.1562 17.1961 11.1562H14.3039C14.2734 11.1562 14.25 11.0719 14.25 10.9688V9.84375ZM14.25 13.2188C14.25 13.1156 14.325 13.0312 14.4164 13.0312H18.7687C18.8602 13.0312 18.9352 13.1156 18.9352 13.2188V14.3438C18.9352 14.4469 18.8602 14.5312 18.7687 14.5312H14.4164C14.325 14.5312 14.25 14.4469 14.25 14.3438V13.2188ZM5.06719 15.5766C5.13281 14.3273 5.81484 13.2422 6.81562 12.6211C6.39141 12.1523 6.13359 11.5336 6.13359 10.8516C6.13359 9.40078 7.30313 8.22656 8.74453 8.22656C10.1859 8.22656 11.3555 9.40078 11.3555 10.8516C11.3555 11.5336 11.0977 12.1547 10.6734 12.6211C11.6719 13.2398 12.3562 14.3273 12.4219 15.5766C12.4231 15.602 12.4192 15.6274 12.4104 15.6512C12.4015 15.675 12.3879 15.6968 12.3704 15.7152C12.3528 15.7337 12.3317 15.7483 12.3084 15.7583C12.285 15.7683 12.2598 15.7735 12.2344 15.7734H11.2055C11.107 15.7734 11.0273 15.6961 11.0203 15.5977C10.9313 14.4141 9.94219 13.4766 8.74219 13.4766C7.54219 13.4766 6.55312 14.4141 6.46406 15.5977C6.45703 15.6961 6.37734 15.7734 6.27891 15.7734H5.25C5.14219 15.7734 5.05781 15.6844 5.06719 15.5766Z"
        fill="currentColor"
      />
      <path
        opacity="0.1"
        d="M7.52979 10.8516C7.52979 11.1748 7.65745 11.4848 7.88469 11.7133C8.11193 11.9419 8.42014 12.0703 8.7415 12.0703C9.06287 12.0703 9.37108 11.9419 9.59832 11.7133C9.82556 11.4848 9.95322 11.1748 9.95322 10.8516C9.95322 10.6915 9.92188 10.533 9.86099 10.3852C9.80009 10.2373 9.71084 10.1029 9.59832 9.98978C9.4858 9.8766 9.35222 9.78683 9.20521 9.72558C9.0582 9.66434 8.90063 9.63281 8.7415 9.63281C8.58238 9.63281 8.42481 9.66434 8.2778 9.72558C8.13079 9.78683 7.99721 9.8766 7.88469 9.98978C7.77217 10.1029 7.68292 10.2373 7.62202 10.3852C7.56113 10.533 7.52979 10.6915 7.52979 10.8516Z"
        fill="currentColor"
      />
      <path
        d="M14.3036 11.1562H17.1958C17.2262 11.1562 17.2497 11.0719 17.2497 10.9688V9.84375C17.2497 9.74063 17.2262 9.65625 17.1958 9.65625H14.3036C14.2731 9.65625 14.2497 9.74063 14.2497 9.84375V10.9688C14.2497 11.0719 14.2731 11.1562 14.3036 11.1562ZM14.4161 14.5312H18.7684C18.8598 14.5312 18.9348 14.4469 18.9348 14.3438V13.2188C18.9348 13.1156 18.8598 13.0312 18.7684 13.0312H14.4161C14.3247 13.0312 14.2497 13.1156 14.2497 13.2188V14.3438C14.2497 14.4469 14.3247 14.5312 14.4161 14.5312ZM5.24969 15.7734H6.27859C6.37703 15.7734 6.45672 15.6961 6.46375 15.5977C6.55281 14.4141 7.54187 13.4766 8.74187 13.4766C9.94187 13.4766 10.9309 14.4141 11.02 15.5977C11.027 15.6961 11.1067 15.7734 11.2052 15.7734H12.2341C12.2595 15.7735 12.2847 15.7683 12.308 15.7583C12.3314 15.7483 12.3525 15.7337 12.3701 15.7152C12.3876 15.6968 12.4012 15.675 12.4101 15.6512C12.4189 15.6274 12.4228 15.602 12.4216 15.5766C12.3559 14.3273 11.6716 13.2398 10.6731 12.6211C11.1134 12.1371 11.3567 11.5059 11.3552 10.8516C11.3552 9.40078 10.1856 8.22656 8.74422 8.22656C7.30281 8.22656 6.13328 9.40078 6.13328 10.8516C6.13328 11.5336 6.39109 12.1523 6.81531 12.6211C6.30702 12.9361 5.88251 13.3694 5.57805 13.8841C5.27358 14.3987 5.09819 14.9794 5.06687 15.5766C5.0575 15.6844 5.14187 15.7734 5.24969 15.7734ZM8.74187 9.63281C9.40984 9.63281 9.95359 10.1789 9.95359 10.8516C9.95359 11.5242 9.40984 12.0703 8.74187 12.0703C8.07391 12.0703 7.53016 11.5242 7.53016 10.8516C7.53016 10.1789 8.07391 9.63281 8.74187 9.63281Z"
        fill="currentColor"
      />
    </svg>
  );
}
