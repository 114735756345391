import workApi from "clientApi/workApi";
import { useAbilityChecker, useGroupContext } from "editor/utils/customHooks";
import { getGroupsFromEntityId } from "modules/containerHelpers";
import React, { useCallback, useMemo } from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { usePaneId } from "store/reducers/filterReducer";
import { useWork } from "store/reducers/workReducer";
import store, { ClarityStore } from "store/storeExporter";
import { useShallowSelector } from "utilities/hooks";
import { Abilities, ContainerTypes, TasksViewModes } from "utilities/types";
import styles from "./boardView/kanban.module.scss";
import Track from "./boardView/Track";

const BoardView: React.FC<{
  groupIds: string[];
  showingProjects?: boolean;
}> = ({ groupIds, showingProjects }) => {
  const { onDragEnd } = useOnDragEnd({ trackIds: groupIds });
  const groupContext = useGroupContext();
  const paneId = usePaneId();
  const workViewState = useShallowSelector((store: ClarityStore) => ({
    viewConfig: store.filter.viewConfigDict[paneId],
  }));
  const canEditEntity = useAbilityChecker({
    abilityName: Abilities.CAN_EDIT_ENTITY,
    isGroupMember:
      workViewState.viewConfig.viewMode === TasksViewModes.ProjectWork
        ? getGroupsFromEntityId(
            workViewState.viewConfig.getFilters().parents[0],
            ContainerTypes.WORK
          )
        : groupContext ?? [],
  });

  return useMemo(() => {
    return (
      <div className={styles.kanbanBoard}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            className={styles.kanbanBoardTracksContainer}
            style={showingProjects ? { paddingTop: 0 } : { paddingTop: "0px" }}
          >
            {groupIds.map((track) => (
              <Track key={track} id={track} canEditEntity={canEditEntity} />
            ))}
          </div>
        </DragDropContext>
      </div>
    );
  }, [groupIds, showingProjects]);
};

function useOnDragEnd({ trackIds }: { trackIds: string[] }) {
  const { statuses } = useWork();

  const onDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      const sourceTrack = result.source.droppableId;
      const item = result.draggableId;

      const targetTrack = result.destination?.droppableId;

      if (
        sourceTrack &&
        targetTrack &&
        sourceTrack !== targetTrack &&
        result.destination?.index !== undefined
      ) {
        const workItem = store.getState().work.dict[item];
        const currentStatus = statuses.dict[sourceTrack];
        const newStatus = statuses.dict[targetTrack];

        if (currentStatus !== newStatus) {
          workApi.update({ statusId: newStatus.id }, [workItem.id]);
        }
      }
    },
    [trackIds]
  );

  return {
    onDragEnd,
  };
}

export default BoardView;
