import Conditional from "components/Conditional";
import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { IProjectObj } from "utilities/types";
import { ReactComponent as ChevronRight } from "icons/chevron-right.svg";
import Icon from "@ant-design/icons";

const ItemDetails: React.FC<{
  workItem: IProjectObj;
  large?: boolean;
  showChevron?: boolean;
  onClickHandler?: (e: any) => void;
}> = ({ workItem, large, showChevron = true, onClickHandler }) => {
  const parentItem = useSelector(
    (state: ClarityStore) =>
      workItem.parentId ? state.work.dict[workItem.parentId] : null,
    shallowEqual
  );

  return useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <span
          onClick={onClickHandler}
          className={`body regular ellipsis-nowrap project-title-container ${
            workItem.isClosed ? "disabled" : "primary"
          }`}
        >
          <span className="project-title">{workItem.name} </span>

          <Conditional on={parentItem && parentItem.name}>
            <span className="disabled ml-5">
              <Conditional on={showChevron}>
                <Icon style={{ fontSize: "12px" }} component={ChevronRight} />
              </Conditional>
              {parentItem?.name}
            </span>
          </Conditional>
        </span>
      </div>
    );
  }, [parentItem, workItem]);
};

export default ItemDetails;
