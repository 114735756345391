import {
  CommandPaletteContext,
  ContainerTypes,
  IBlockContext,
  WorkTypes,
} from "../../utilities/types";
import {
  SET_COMMAND_PALETTE_CONTEXT,
  SHOW_COMMAND_PALETTE,
  HIDE_COMMAND_PALETTE,
  SELECT_ONE_ENTITY,
} from "../../store/actions";
import store, { ClarityStore } from "store/storeExporter";
import { useDispatch, useSelector, shallowEqual, batch } from "react-redux";
import { Block } from "./blockReducer";
import React from "react";
import { ChunkDestination } from "utilities/stateTypes";

export interface IPaletteParams {
  containerData?: {
    containerId: string;
    containerType: ContainerTypes;
  };
  // selectedWorkIds?: string[];
  slectedItemsType?: ContainerTypes;
  selectedItemIds?: string[];
  blockData?: Block;
  blockRef?: React.MutableRefObject<HTMLDivElement | null>;
  context?: IBlockContext;
  afterCloseFocus?: React.MutableRefObject<HTMLDivElement | null>;
  paneId?: ChunkDestination;
}
export interface ISetCommandPaletteContext {
  type: string;
  context: CommandPaletteContext;
  params?: IPaletteParams;
  afterClose?: any;
}

export interface IInitialState {
  context: CommandPaletteContext;
  display: boolean;
  params: IPaletteParams;
  afterClose: any;
}

const initialState: IInitialState = {
  context: CommandPaletteContext.GENERAL,
  display: false,
  params: {} as IPaletteParams,
  afterClose: null,
};

export default function commandPaletteReducer(
  state = initialState,
  action: ISetCommandPaletteContext
) {
  switch (action.type) {
    case SET_COMMAND_PALETTE_CONTEXT: {
      if (action.context) {
        const newState = { ...state, context: action.context };
        return newState;
      }
      return state;
    }

    case SHOW_COMMAND_PALETTE: {
      if (!state.display) {
        const newState = { ...state };
        newState.display = true;
        if (action.context) {
          newState.context = action.context;
        }
        if (action.params) {
          newState.params = action.params;
        } else newState.params = {};

        if (action.afterClose) {
          newState.afterClose = action.afterClose;
        }

        return newState;
      }
      return state;
    }

    case HIDE_COMMAND_PALETTE: {
      if (state.display !== false) {
        const newState = {
          ...state,
        };
        newState.display = false;

        if (newState.params?.afterCloseFocus) {
          newState.params?.afterCloseFocus?.current?.focus();
        }
        newState.params = {};
        if (newState.afterClose) {
          newState.afterClose = null;
        }
        return newState;
      }
      return state;
    }

    default:
      return state;
  }
}

export function useCommandPaletteShower(data?: {
  itemId: string;
  entityType: ContainerTypes;
  workType: WorkTypes;
}) {
  const dispatch = useDispatch();

  return (context: CommandPaletteContext, params?: IPaletteParams) => {
    batch(() => {
      dispatch({
        type: SHOW_COMMAND_PALETTE,
        context,
        params,
      });

      if (data && data.itemId) {
        dispatch({
          type: SELECT_ONE_ENTITY,
          entityType: data.workType,
          workType: data.workType,
          ids: [data.itemId],
        });
      }
    });
  };
}

export function openCommandPalette(
  context: CommandPaletteContext,
  params: IPaletteParams,
  afterClose?: () => void
) {
  store.dispatch({
    type: SHOW_COMMAND_PALETTE,
    context,
    params,
    afterClose,
  });
}

export function useCommandPaletteContext() {
  return useSelector(
    (state: ClarityStore) => state.commandPalette.context,
    shallowEqual
  );
}
