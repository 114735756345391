import { Space, Switch, Table } from "antd";
import { useLayoutEffect, useRef, useState } from "react";
import { UPDATE_USER } from "store/actions";
import store from "store/storeExporter";
import Button, { ButtonTypes } from "./Button";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./pricingPlans/pricingPlans.module.scss";
import { useStripe } from "@stripe/react-stripe-js";
import { batch, connect, useSelector } from "react-redux";
import CheckOutlined from "icons/Components/CheckOutlined";
import {
  Abilities,
  ICollaborator,
  ISubscriptionObj,
  IUserObj,
  IWorkspaceObj,
} from "utilities/types";
import * as actionTypes from "../store/actions";
import { useAbilityChecker } from "editor/utils/customHooks";
import { useIntercom } from "react-use-intercom";
import { axiosInstance } from "index";

interface IProps {
  base: IWorkspaceObj;
  user: IUserObj;
  subscription: any;
  setBaseSubscription: any;
  activeKey?: string | undefined;
}

const subscriptionState: any = {
  trialing: "trialing",
  active: "trialing",
};

const PricingPlans = (props: IProps) => {
  const acesssToken = useSelector((state: any) => state.client.accessToken);
  const { subscription } = props;
  const [currentPrices, setCurrentPrices] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const [yearly, setYearly] = useState<boolean>(true);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [collaborators, setCollaborators] = useState<ICollaborator[]>([]);
  const paymentOptions = useRef({
    monthly: [] as any[],
    yearly: [] as any[],
  });
  const canEditSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BILLING,
  });
  const { showNewMessages } = useIntercom();
  const openSupportContact = () => {
    showNewMessages(
      "Hi, I’d like to learn more about pricing for DAOs and tokenized communities."
    );
  };

  useLayoutEffect(() => {
    // if (props.activeKey === "5") {
    if (subscription.subscriptionId) {
      setYearly(subscription.recurring === "year");
    } else {
      setYearly(subscription.recurring === "year");
    }
    // }
  }, [subscription, props.activeKey]);

  useLayoutEffect(() => {
    setLoading(true);
    // if (props.activeKey === "5" || props.activeKey === "") {
    const endpoint = `/api/workspace/collaborators/${props.base.id}`;

    const handleResult = (res: any) => {
      const { collaborators } = res.data.payload;

      setCollaborators([...collaborators]);
    };

    const onError = (e: any) => {
      // console.log(e);
    };

    axiosInstance.get(endpoint).then(handleResult).catch(onError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeKey]);

  useLayoutEffect(() => {
    // if (props.activeKey === "5") {
    // setLoading(true);
    const promises = [
      axiosInstance
        .get("/api/payments", {
          params: {
            yearly: true,
          },
        })
        .then(({ data }) => {
          data.sort((a: any, b: any) =>
            a.product?.rank > b.product?.rank ? 1 : -1
          );
          paymentOptions.current.yearly = [...data];
        })
        .catch((e) => {
          console.log("Error while retrieving plans", e);
        }),
      axiosInstance
        .get("/api/payments", {
          params: {
            yearly: false,
          },
        })
        .then(({ data }) => {
          data.sort((a: any, b: any) =>
            a.product?.rank > b.product?.rank ? 1 : -1
          );
          paymentOptions.current.monthly = [...data];
        })
        .catch((e) => {
          console.log("Error while retrieving plans", e);
        }),
    ];
    Promise.all(promises).then(() => {
      setLoading(false);
    });
    // }
  }, [acesssToken, props.activeKey]);

  useLayoutEffect(() => {
    // if (props.activeKey === "5") {
    setCurrentPrices(
      yearly
        ? [...paymentOptions.current.yearly]
        : [...paymentOptions.current.monthly]
    );
    // }
  }, [yearly, loading, props.activeKey]);

  const fetchAndSetBaseSub = async (baseId: string) => {
    return axiosInstance
      .get("/api/payments/baseSubscription", {
        params: {
          baseId: baseId,
        },
      })
      .then((res: any) => {
        props.setBaseSubscription(res.data);
        return true;
      });
  };

  const createSubscriptionCall = (
    customerId: string,
    priceId: string,
    baseId: string
  ) => {
    axiosInstance
      .post("/api/payments/createCheckoutSession", {
        customerId,
        priceId,
        seatNumber: collaborators.length,
        baseId,
      })
      .then(({ data }) => {
        return stripe?.redirectToCheckout({
          sessionId: data.id,
        });
      })
      .then(() => {
        setDisableButtons(false);
      });
  };
  const createSubscription = async (isPerSeat: boolean, priceId: string) => {
    setDisableButtons(true);
    const state = store.getState();
    const baseId = state.workspace.id;
    const customerId = state.subscription?.customerId;
    if (customerId && customerId !== "") {
      createSubscriptionCall(customerId ?? "", priceId, baseId);
    } else {
      axiosInstance
        .post("/api/payments/createCustomer")
        .then(({ data }) => {
          batch(() => {
            store.dispatch({
              type: UPDATE_USER,
              user: { customerId: data.customerId },
            });
            store.dispatch({
              type: actionTypes.UPDATE_SUBSCRIPTION,
              param: {
                subdelta: {
                  customerId: data.customerId,
                },
              },
            });
          });

          return data.customerId;
        })
        .then((customerId) => {
          return createSubscriptionCall(customerId ?? "", priceId, baseId);
        });
    }
  };

  const cancelCurrentSubscription = async () => {
    axiosInstance
      .post("/api/payments/cancelSubscription", {
        baseId: props.base.id,
      })
      .then(async () => {
        await fetchAndSetBaseSub(props.base.id);
      });
  };

  const reactivateCurrentSubscription = async () => {
    axiosInstance
      .post("/api/payments/reactivateSubscription", {
        baseId: props.base.id,
      })
      .then(async () => {
        await fetchAndSetBaseSub(props.base.id);
      });
  };
  const headers = ["Usage", "Collaboration", "Support"];
  return loading ? (
    <div className={styles.containerDiv}>
      <LoadingSpinner />
    </div>
  ) : (
    <div>
      <Table
        rowKey={(row) => row.option}
        className={styles.table}
        columns={[
          {
            title: (
              <Switch
                checkedChildren="Yearly"
                key="switch"
                className={styles.switch}
                unCheckedChildren="Monthly"
                checked={yearly}
                onChange={() => {
                  setYearly(!yearly);
                }}
              />
            ),
            dataIndex: "option",
            render: (text: string) =>
              headers.includes(text) ? <b>{text}</b> : <div>{text}</div>,
          },
          {
            title: (
              <div key={"Free"}>
                <h3 className={styles.headings}>Free</h3>
                <div style={{ display: "flex" }}>
                  <div className="h4 medium" style={{ marginRight: "8px" }}>
                    $0
                  </div>
                </div>
              </div>
            ),
            dataIndex: "free",
          },
          ...currentPrices.map((price: any) => ({
            title: (
              <div key={price.id}>
                <h3 className={styles.headings}>{price.product.name}</h3>

                <div style={{ display: "flex" }}>
                  <div className="h4 medium" style={{ marginRight: "8px" }}>
                    {yearly ? "$8" : "$10"}
                  </div>
                  <div className="small secondary">
                    per member
                    <br />
                    per month
                  </div>
                </div>
              </div>
            ),
            dataIndex: price.product.name,
          })),
          {
            title: (
              <div key={"DAO"}>
                <h3 className={styles.headings}>DAO</h3>
                <div style={{ display: "flex" }}>
                  <div
                    className="small secondary"
                    style={{ marginRight: "8px" }}
                  >
                    Custom pricing for tokenized communities
                  </div>
                </div>
              </div>
            ),
            dataIndex: "DAO",
          },
        ]}
        dataSource={[
          currentPrices.length > 0
            ? {
                option: "",
                free:
                  subscription.subscriptionId &&
                  subscriptionState[subscription.subscriptionState] ? (
                    <></>
                  ) : (
                    <Button buttonType={ButtonTypes.PRIMARY} disabled={true}>
                      Current plan
                    </Button>
                  ),
                Pro:
                  subscription &&
                  currentPrices[0]?.product.id === subscription?.productId ? (
                    <Button buttonType={ButtonTypes.PRIMARY} disabled={true}>
                      Current plan
                    </Button>
                  ) : (
                    <>
                      <Button
                        buttonType={ButtonTypes.PRIMARY}
                        onClick={() =>
                          createSubscription(true, currentPrices[0].id)
                        }
                        disabled={
                          disableButtons ||
                          !canEditSettings ||
                          (subscription.subscriptionId &&
                            subscriptionState[subscription.subscriptionState])
                        }
                        style={{ marginBottom: "8px" }}
                      >
                        Activate Plan
                      </Button>
                    </>
                  ),
                DAO: (
                  <Button
                    buttonType={ButtonTypes.PRIMARY}
                    onClick={openSupportContact}
                  >
                    Contact Us
                  </Button>
                ),
              }
            : {
                option: "",
                free:
                  subscription.subscriptionId &&
                  subscriptionState[subscription.subscriptionState] ? (
                    <></>
                  ) : (
                    <Button buttonType={ButtonTypes.PRIMARY} disabled={true}>
                      Current plan
                    </Button>
                  ),
                Pro: (
                  <Button buttonType={ButtonTypes.PRIMARY} disabled={true}>
                    Current plan
                  </Button>
                ),
                DAO: (
                  <Button
                    buttonType={ButtonTypes.PRIMARY}
                    onClick={openSupportContact}
                  >
                    Contact Us
                  </Button>
                ),
              },
          {
            option: "Usage",
            free: "",
            Pro: "",
            DAO: "",
            width: 100,
          },
          {
            option: "Blocks",
            free: "500 per month",
            Pro: "Unlimited",
            DAO: "Unlimited",
            width: 100,
          },
          {
            option: "Tasks & Projects",
            free: "50 per month",
            Pro: "Unlimited",
            DAO: "Unlimited",
            width: 100,
          },
          {
            option: "Members",
            free: "Unlimited",
            Pro: "Unlimited",
            DAO: "Unlimited",
            width: 100,
          },
          {
            option: "Docs, Tags",
            free: "Unlimited",
            Pro: "Unlimited",
            DAO: "Unlimited",
            width: 100,
          },
          {
            option: "Collaboration",
            free: "",
            Pro: "",
            DAO: "",
            width: 100,
          },
          {
            option: "Real-time collaboration",
            free: <CheckOutlined />,
            Pro: <CheckOutlined />,
            DAO: <CheckOutlined />,
            width: 100,
          },
          {
            option: "Public Link Sharing",
            free: <CheckOutlined />,
            Pro: <CheckOutlined />,
            DAO: <CheckOutlined />,
            width: 100,
          },
          {
            option: "Support",
            free: "",
            Pro: "",
            DAO: "",
            width: 100,
          },
          {
            option: "Email and live chat",
            free: <CheckOutlined />,
            Pro: <CheckOutlined />,
            DAO: <CheckOutlined />,
            width: 100,
          },
          {
            option: "Clarity Commons Discord Community",
            free: <CheckOutlined />,
            Pro: <CheckOutlined />,
            DAO: <CheckOutlined />,
            width: 100,
          },
        ]}
        pagination={false}
      />
      <Space />
      <div
        style={{
          display: "flex",
          paddingTop: "20px",
          paddingLeft: "16px",
          alignItems: "center",
        }}
        className="caption secondary"
      >
        <i style={{ marginRight: "40px" }}>
          All other features are equal across pricing tiers
        </i>
        {!subscription.subscriptionId ? (
          <></>
        ) : (
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={cancelCurrentSubscription}
            disabled={
              !subscription.subscriptionId ||
              subscription.cancel_at_period_end ||
              !canEditSettings
            }
          >
            Cancel your subscription
          </Button>
        )}
        {subscription.subscriptionId &&
          subscription.cancel_at_period_end &&
          subscriptionState[subscription.subscriptionState] && (
            <Button
              buttonType={ButtonTypes.PRIMARY}
              onClick={reactivateCurrentSubscription}
              disabled={!subscription.subscriptionId || !canEditSettings}
            >
              Reactivate your subscription
            </Button>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  subscription: state.subscription,
});

const mapDispatchToProps = (dispatch: any) => ({
  setBaseSubscription: (subscription: ISubscriptionObj) =>
    dispatch({ type: actionTypes.LOAD_BASE_SUBSCRIPTION, subscription }),
});
export default connect(mapStateToProps, mapDispatchToProps)(PricingPlans);
