import Conditional from "components/Conditional";
import React from "react";
import BaseNotFound from "screens/BaseNotFound";
import JoinBase from "screens/JoinBase";
import { useShallowSelector } from "utilities/hooks";
import { BaseType } from "utilities/types";

const BaseTypeSwitcher: React.FC = () => {
  const base = useShallowSelector((store) => store.workspace);

  return (
    <>
      <Conditional
        on={!base || !base.id || !base.type || base.type === BaseType.Secret}
      >
        <BaseNotFound />
      </Conditional>
      <Conditional on={base?.type === BaseType.Private}>
        <JoinBase baseId={base.id} />
      </Conditional>
    </>
  );
};

export default BaseTypeSwitcher;
