import React, { createContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import styles from "./sourceCitations/sourceCitations.module.scss";
import ReactDOM from "react-dom";
import BlockCitationsView from "screens/base/splitView/components/BlockCitationsView";
import store from "store/storeExporter";
import { blockApi } from "editor/utils/blockActionsApi";
import { CitationData, ContainerTypes } from "utilities/types";

const checkIfAnyBlockNotLoaded = (citationArray: CitationData[]) => {
  const blockDict = store.getState().blocks.dict;
  const containers: { containerId: string; containerType: ContainerTypes }[] =
    [];
  const containerIds: string[] = [];
  citationArray.forEach((citation) => {
    if (!blockDict[citation.referencingBlockId]) {
      const container = {
        containerId: citation.referencingContainerId,
        containerType: citation.referencingContainerType,
      };
      const fullId = container.containerType + container.containerId;
      if (!containerIds.includes(fullId)) {
        containers.push(container);
        containerIds.push(fullId);
      }
    }
  });
  if (containers.length > 0) blockApi.load.blocksContainers(containers);
};

const SourceCitations: React.FC<any> = (props) => {
  const [citationsArray, setcitationsArray] = useState<string[]>([]);

  const [isListOpen, setisListOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const numberOfCitations = props.citations
      ? Object.values(props.citations).length
      : 0;
    if (!numberOfCitations) return;
    const citationDict = store.getState().citations.dict;
    const inCitationArray: string[] = [];
    Object.keys(props.citations).forEach((citationId: string) => {
      if (!citationDict[citationId].isDeleted) {
        inCitationArray.push(citationDict[citationId].referencingBlockId);
      }
    });
    setcitationsArray(inCitationArray);
  }, [props.citations]);

  useEffect(() => {
    const numberOfCitations = props.citations
      ? Object.values(props.citations).length
      : 0;
    if (!numberOfCitations) return;
    const citationDict = store.getState().citations.dict;
    const citationArray: any[] = [];
    Object.keys(props.citations).forEach((citationId: string) => {
      if (!citationDict[citationId].isDeleted) {
        citationArray.push(citationDict[citationId]);
      }
    });
    if (citationArray.length > 0) checkIfAnyBlockNotLoaded(citationArray);
  }, []);

  const numberOfCitations = citationsArray.length;

  if (numberOfCitations > 0)
    return (
      <>
        <div
          ref={ref}
          style={{ zIndex: 5 }}
          onClick={(e) => {
            setisListOpen(!isListOpen);
          }}
          className={`${styles.SourceCitations} ${
            isListOpen && styles.SourceCitations__active
          }`}
        >
          <span>{numberOfCitations}</span>
        </div>
        {isListOpen && (
          <SourceCitationListing
            el={ref}
            citations={citationsArray}
            blockId={props.blockId}
            blockRef={props.blockRef}
            paneId={props.paneId}
          />
        )}
      </>
    );
  else return <></>;
};

const SourceCitationListing: React.FC<any> = (props) => {
  if (!props.el.current) return <></>;
  return ReactDOM.createPortal(
    <CitationDisplay citations={props.citations} paneId={props.paneId} />,
    props.blockRef.current
  );
};

export const IsCitationPortal = createContext<boolean>(false);

const CitationDisplay: React.FC<any> = (props) => {
  return (
    <IsCitationPortal.Provider value={true}>
      <BlockCitationsView
        blockId={props.blockId}
        paneId={props.paneId}
        citations={props.citations}
      />
    </IsCitationPortal.Provider>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    citations: state.citations.blockCitations[ownProps.id]?.sourceCitations,
  };
};

export default connect(mapStateToProps, null)(SourceCitations);
