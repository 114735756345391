import { Switch } from "antd";
import notificationsApi from "clientApi/notificationsApi";
import Conditional from "components/Conditional";
import { useAbilityChecker } from "editor/utils/customHooks";
import { useEffect, useState } from "react";
import { useBaseType } from "store/reducers/workspaceReducer";
import { Abilities, BaseType, ContainerVisibility } from "utilities/types";
import { AnonymousDropDownComponent } from "./AnonymousDropDown";
import styles from "./sharingAndPermsModal.module.scss";
import { useEntityUpdater } from "./useEntityUpdater";
import { GlobalOutlined, LockFilled, visibilityData } from "./util";

export function PermGroup() {
  const updateWorkItem = useEntityUpdater();

  const canEditShareData = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_MEMBERS,
  });

  const baseType = useBaseType();
  const [acessEnabled, setAcessEnabled] = useState<boolean>(true);

  const updateAccess = (val: boolean) => {
    setAcessEnabled(val);
    const permission = val
      ? ContainerVisibility.canView
      : ContainerVisibility.private;
    updateWorkItem(permission);
    visibilityData.next(permission);
  };

  const showErrorIfPublic = () => {
    if (baseType === BaseType.Public || !canEditShareData) {
      notificationsApi.displayError({
        title: "Cannot make document private",
        body: "Public access is turned on for all documents because the base is public",
      });
    }
  };

  useEffect(() => {
    const visibilitySub = visibilityData.subscribe((data) => {
      if (
        data === ContainerVisibility.private &&
        baseType !== BaseType.Public
      ) {
        setAcessEnabled(false);
      } else {
        setAcessEnabled(true);
      }
    });
    if (baseType === BaseType.Public) {
      visibilityData.next(ContainerVisibility.canView);
    }
    return () => visibilitySub?.unsubscribe();
  }, []);

  return (
    <div>
      <div className={styles.headingWrap}>
        <span className="body medium">Public access</span>
        <span onClick={() => showErrorIfPublic()}>
          <Switch
            style={
              baseType === BaseType.Public || !canEditShareData
                ? { pointerEvents: "none" }
                : undefined
            }
            disabled={baseType === BaseType.Public || !canEditShareData}
            checked={acessEnabled}
            onChange={(e) => updateAccess(e)}
          />
        </span>
      </div>
      <Conditional on={!acessEnabled}>
        <div className={styles.noSetup}>
          <div className={styles.icon}>
            <LockFilled />
          </div>
          <div>
            <div className="medium caption">No public access</div>
            <div className="regular small secondary">
              Only people who meet the above requirements can access
            </div>
          </div>
        </div>
      </Conditional>
      <Conditional on={acessEnabled}>
        <div className={styles.permGroupContainer}>
          <div className={styles.permGroupPrefix}>
            <GlobalOutlined />
          </div>
          <div className={styles.permGroupInfo}>
            <div className={styles.permGroupInfoDescription}>
              Anyone with the link
            </div>
            <div className="small secondary">
              Anyone on the Internet with the link can view
            </div>
          </div>
          <AnonymousDropDownComponent
            editable={canEditShareData}
          ></AnonymousDropDownComponent>
        </div>
      </Conditional>
    </div>
  );
}
