import { useEffect, useState } from "react";
import { Form, Switch } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import {
  Abilities,
  IUserObj,
  IWorkspaceObj,
  UserBaseSettings,
} from "utilities/types";
import TabLayout from "./TabLayout";
import FooterForSave from "./FooterForSave";
import styles from "./options/options.module.scss";
import store from "store/storeExporter";
import { useAbilityChecker } from "editor/utils/customHooks";
import { UPDATE_WORKSPACE } from "store/actions";
import notificationsApi from "clientApi/notificationsApi";
import { useSetBaseSettingHasChanges } from "store/reducers/clientReducer";
import { axiosInstance } from "index";

interface Props {
  base: IWorkspaceObj;
  user: IUserObj;
  userSettings: UserBaseSettings;
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
}

const OptionsMilestones = ({ base, setAuthenticatedUser }: Props) => {
  const [baseSettings, setBaseSettings] = useState<IWorkspaceObj>(base);

  const [showMilestones, setShowMilestones] = useState<boolean>(
    base.showMilestones
  );

  const canManageBaseSettings = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_BASE_SETTINGS,
  });

  useEffect(() => {
    setBaseSettings(base);
  }, [base]);

  const handleSaveBaseChanges = async () => {
    try {
      const { data } = await axiosInstance.patch(`/api/workspace/profile`, {
        baseId: baseSettings.id,
        patch: [
          {
            op: "replace",
            path: "/showMilestones",
            value: showMilestones,
          },
        ],
      });
      if (data.status === 1) {
        // history.push(`/${base.ownerName}/${baseSettings.slug}/settings`);
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Updated successfully",
        });

        store.dispatch({
          type: UPDATE_WORKSPACE,
          params: {
            delta: { showMilestones },
          },
        });
        // setActiveBase(baseSettings);
        const { data } = await axiosInstance.get("/api/user");
        setAuthenticatedUser(data.payload);
      } else {
        notificationsApi.displayError({
          title: "Error",
          duration: 4,
          body: "Sorry, we could not save your changes. Please try again later",
        });
      }
    } catch (e) {
      notificationsApi.displayError({
        title: "Error",
        duration: 4,
        body: "Sorry, we could not save your changes. Please try again later",
      });
    }
  };
  const handleReset = () => {
    setShowMilestones(base.showMilestones);
  };
  const hasChanges = !(
    baseSettings.showMilestones === showMilestones || !canManageBaseSettings
  );

  useSetBaseSettingHasChanges(hasChanges, [
    baseSettings.showMilestones,
    showMilestones,
    canManageBaseSettings,
  ]);

  return (
    <>
      <TabLayout
        title="Milestones"
        description="Organize your projects into a roadmap"
        actionBtn={
          <>
            <Switch
              checkedChildren={<CheckOutlined />}
              defaultChecked={showMilestones}
              disabled={!canManageBaseSettings}
              checked={showMilestones}
              onChange={() => {
                setShowMilestones(!showMilestones);
              }}
            />
          </>
        }
      ></TabLayout>
      <Form>
        <div className={styles.options}>
          {!(
            baseSettings.showMilestones === showMilestones ||
            !canManageBaseSettings
          ) && (
            <FooterForSave
              onSave={handleSaveBaseChanges}
              onCancel={handleReset}
            />
          )}
        </div>
      </Form>
    </>
  );
};

export default OptionsMilestones;
