import React, {
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./globalTopNav/globalTopNav.module.scss";
import { ReactComponent as ClarityLogo } from "images/clarity-logomark-white.svg";
import Button, { ButtonShapes, ButtonTypes, IconSides } from "./Button";
import store from "store/storeExporter";
import { SET_SWITCH_BASE_MENU_VISIBILITY } from "store/actions";
import {
  SectionNumber,
  SectionWidthModes,
  useShallowSelector,
} from "utilities/hooks";
import Icon, {
  SearchOutlined,
  HistoryOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import UserDisplay from "clarity-ui/UserDisplay";
import {
  ActiveMenuItemContext,
  Menu,
  MenuItem,
  useActiveMenuItemContextValue,
} from "./FabBtn";
import { HelpBtn, SearchBar } from "./TopNavBar";
import { Dropdown } from "antd";
import { locationSubject } from "./LocationListener";
import { useBase } from "store/reducers/workspaceReducer";
import navigationApi from "clientApi/navigationApi";
import { RecentItem } from "screens/base/Sidebar";
import ChevronDown from "icons/Components/ChevronDown";
import {
  ActionIntercept,
  setActionInterceptor,
  useIsBaseMember,
} from "store/reducers/clientReducer";
import Conditional from "./Conditional";
import {
  BaseType,
  GeneralViewsNames,
  TasksViewModes,
  UserRole,
} from "utilities/types";
import { MemberOnly } from "./MemberOnly";
import { ReactComponent as Logo } from "images/siwe-ethereum-logo.svg";
import { sidebarApi } from "clientApi/sidebarApi";
import { ChunkDestination } from "utilities/stateTypes";
import { SidebarModes } from "store/reducers/sidebarReducer";
import BreadcrumbContainer from "./topNavBar/BreadcrumbContainer";
import {
  SplitViewCloseBtn,
  ViewSwapBtn,
} from "./topNavBar/SplitViewComponents";
import { useOnClickOutside } from "editor/utils/customHooks";
import { InboxCounter } from "screens/base/ClaritySidebar";
import { SizeContext } from "App";

const GlovalTopNav: React.FC<{ widthMode?: SectionWidthModes }> = memo(
  ({ widthMode }) => {
    const isBaseMember = useIsBaseMember();
    const userRole = useShallowSelector((store) => store.client.roleType);
    const baseType = useShallowSelector((store) => store.workspace.type);
    const userId = useShallowSelector((store) => store.user?.id);
    const [additionalClassNames, setadditionalClassNames] = useState("");

    useLayoutEffect(() => {
      if (!widthMode) return;
      if (SectionNumber[widthMode] > 2) {
        return setadditionalClassNames(styles.large);
      }
      if (SectionNumber[widthMode] > 1) {
        return setadditionalClassNames(styles.medium);
      }
      return setadditionalClassNames(styles.small);
    }, [widthMode]);

    if (!userId && baseType !== BaseType.Public) return <></>;

    return (
      <div className={`${styles.container} ${additionalClassNames}`}>
        <div className={styles.head}>
          <SidebarSwitch />
          <MemberOnly>
            <SwithBaseToggle />
            <Conditional on={!widthMode || SectionNumber[widthMode] > 2}>
              <InboxToggle />
            </Conditional>
          </MemberOnly>
          <Conditional on={!widthMode || SectionNumber[widthMode] > 2}>
            <MyWorkToggle />
          </Conditional>
        </div>
        <div className={styles.body}>
          <BreadcrumbContainer paneId={ChunkDestination.primary} />
        </div>

        <div className={styles.tail}>
          <MemberOnly>
            <ViewRecents />
          </MemberOnly>

          <Conditional
            on={
              (isBaseMember && userRole !== UserRole.GUEST) ||
              baseType === BaseType.Public
            }
          >
            <SearchBarWrapper />
          </Conditional>

          <MemberOnly>
            <HelpBtn />
          </MemberOnly>
          <AvatarContainer />
          <Conditional on={!userId}>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              shape={ButtonShapes.OVAL}
              className={styles.signUpBtn}
              icon={<Logo style={{ height: "20px" }} />}
              onClick={() => setActionInterceptor(ActionIntercept.login)}
            >
              Sign-in with Ethereum
            </Button>
          </Conditional>
        </div>
      </div>
    );
  }
);

export const SplitGlobalTopNav: React.FC = memo(() => {
  const baseType = useShallowSelector((store) => store.workspace.type);
  const userId = useShallowSelector((store) => store.user?.id);

  if (!userId && baseType !== BaseType.Public) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles.head} style={{ flexBasis: "100%" }}>
        <BreadcrumbContainer paneId={ChunkDestination.secondary} />
      </div>

      <div className={styles.tail}>
        <ViewSwapBtn />
        <SplitViewCloseBtn />
      </div>
    </div>
  );
});

const SwithBaseToggle: React.FC = () => {
  const activeBase = useShallowSelector((store) => store.workspace);

  if (!activeBase) return <></>;

  return (
    <Button
      className={styles.buttonOverwrite2}
      labelClass={styles.buttonLabel}
      additionalIconClass={"secondary"}
      onClick={(e: React.MouseEvent) => {
        const isVisible = store.getState().client.isSwitchBaseMenuVisible;
        e.stopPropagation();
        store.dispatch({
          type: SET_SWITCH_BASE_MENU_VISIBILITY,
          visible: isVisible ? false : true,
        });
      }}
    >
      <Icon
        component={ClarityLogo}
        style={{
          fontSize: "22px",
        }}
      />
      <ChevronDown style={{ color: "#fff" }} />
    </Button>
  );
};

const SearchBarWrapper: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const activeItemContext = useActiveMenuItemContextValue();
  const baseName = useShallowSelector((store) => store.workspace.name);
  const [startedSearch, setstartedSearch] = useState(false);
  const width = useContext(SizeContext);

  useOnClickOutside(containerRef, () => setstartedSearch(false), {
    allowClickOn: ["triggerBtn"],
  });

  return useMemo(() => {
    if (!baseName) return <></>;
    return (
      <>
        <div
          className={styles.searchbarFlowContainer}
          ref={containerRef}
          style={
            SectionNumber[width] > 2 || startedSearch
              ? { display: "unset" }
              : { display: "none" }
          }
        >
          <div className={styles.searchBar}>
            <ActiveMenuItemContext.Provider value={activeItemContext}>
              <SearchBar
                prefix={<SearchOutlined />}
                className={styles.searchbar}
                placeholder={`Search`}
                containerClassName={styles.searchbarContainer}
                inputClass={styles.input}
                inputPlaceholderClass={styles.searchbarPlaceholder}
                isQuickSearch
              />
            </ActiveMenuItemContext.Provider>
          </div>
        </div>

        <Button
          className={`${styles.buttonOverwrite2} triggerBtn`}
          style={
            SectionNumber[width] <= 2 && !startedSearch
              ? { display: "unset" }
              : { display: "none" }
          }
          labelClass={styles.buttonLabel}
          icon={<SearchOutlined style={{ color: "#fff", fontSize: "20px" }} />}
          iconSide={IconSides.RIGHT}
          shape={ButtonShapes.CIRCLE}
          onClick={(e: any) => setstartedSearch(true)}
        />
      </>
    );
  }, [activeItemContext, baseName, width, startedSearch]);
};

const AvatarContainer: React.FC = () => {
  const userId = useShallowSelector((store) => store.user?.id);
  const [openMenu, setopenMenu] = useState(false);

  if (!userId) return <></>;
  return (
    <Dropdown
      overlay={<UserOptionsMenu setopenMenu={setopenMenu} />}
      visible={openMenu}
    >
      <div className={styles.userContainer} onClick={() => setopenMenu(true)}>
        <UserDisplay
          borderColor="black"
          avatarSize={28}
          hideName={true}
          shape="circle"
          id={userId}
          hideTooltip={true}
          showPresence={true}
        />
      </div>
    </Dropdown>
  );
};

const UserOptionsMenu: React.FC<{
  setopenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setopenMenu }) => {
  return (
    <Menu close={() => setopenMenu(false)} slim={true} yDir="down" xDir="left">
      <MenuItem
        title="My Profile"
        onClick={() => locationSubject.next("/profile")}
      />
      <MenuItem
        title="My Settings"
        onClick={() => locationSubject.next("/profile/settings")}
      />
      <MenuItem
        title="Log out"
        onClick={() => locationSubject.next("/logout")}
      />
    </Menu>
  );
};

const ViewRecents: React.FC = () => {
  const [openMenu, setopenMenu] = useState(false);
  const activeBase = useShallowSelector((store) => store.workspace);

  if (!activeBase?.id) return <></>;

  return (
    <Dropdown
      overlay={openMenu ? <RecentsMenu setopenMenu={setopenMenu} /> : <></>}
      destroyPopupOnHide={true}
      visible={openMenu}
    >
      <Button
        className={`${styles.buttonOverwrite2} triggerBtn`}
        labelClass={styles.buttonLabel}
        icon={<HistoryOutlined style={{ color: "#fff", fontSize: "20px" }} />}
        iconSide={IconSides.RIGHT}
        shape={ButtonShapes.CIRCLE}
        onClick={(e: any) => {
          openMenu ? setopenMenu(false) : setopenMenu(true);
        }}
      />
    </Dropdown>
  );
};

const RecentsMenu: React.FC<{
  setopenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setopenMenu }) => {
  const { base } = useBase();

  const [recentlyOpened, setrecentlyOpened] = useState<any[]>([]);

  const recentlyOpenedListener = useShallowSelector((state) => {
    const hasRecentlyOpened =
      state.recentlyOpened.dict && state.recentlyOpened.dict[base.id];
    const recentlyOpened = hasRecentlyOpened
      ? state.recentlyOpened.dict[base.id]
      : [];
    return recentlyOpened;
  });

  useEffect(() => {
    const newArr = recentlyOpenedListener.map((recentlyOpened) => {
      const onClick: React.MouseEventHandler = (e) => {
        if (!e.shiftKey) {
          navigationApi.navigateMain(recentlyOpened.navigationChunk);
        } else {
          e.preventDefault();
          navigationApi.openSplitViewNavigationChunk(
            recentlyOpened.navigationChunk
          );
        }
        setopenMenu(false);
      };

      return {
        ...recentlyOpened,
        onClick,
      };
    });

    setrecentlyOpened([...newArr]);
  }, [recentlyOpenedListener, base.id]);

  return (
    <>
      {recentlyOpened.length === 0 ? (
        <Menu
          close={() => setopenMenu(false)}
          slim={true}
          yDir="down"
          xDir="left"
        >
          <div
            style={{
              padding: "14px",
            }}
          >
            <span className="small disabled">
              Documents you have opened recently will show here
            </span>
          </div>
        </Menu>
      ) : (
        <>
          <Menu
            close={() => setopenMenu(false)}
            slim={true}
            allowClickOn={["triggerBtn"]}
            yDir="down"
            xDir="left"
          >
            {recentlyOpened.map((item) => (
              <RecentItem
                recentlyOpened={item}
                key={item.navigationChunk.entity?.containerId}
              />
            ))}
          </Menu>
        </>
      )}
    </>
  );
};

const SidebarSwitch: React.FC = () => {
  const sidebarIsOpen = useShallowSelector((store) => store.sidebar.isOpen);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          buttonType={ButtonTypes.LINK}
          className={`${styles.buttonOverwrite2} triggerBtn`}
          onClick={() => {
            if (!sidebarIsOpen)
              sidebarApi.setSidebarMode({ isOpen: true, isTemporary: false });
            else
              sidebarApi.setSidebarMode({ isOpen: false, isTemporary: false });
          }}
          icon={sidebarIsOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          iconStyle={{ color: "#fff", fontSize: "18px" }}
        />
        <div className={styles.sidebarDivider}></div>
      </div>
    </>
  );
};

const InboxToggle: React.FC = () => {
  const sidebarState = useShallowSelector((store) => store.sidebar);

  return (
    <span
      onClick={() => {
        if (
          sidebarState.sidebarMode !== SidebarModes.inbox ||
          !sidebarState.isOpen
        ) {
          sidebarApi.setSidebarMode({
            newMode: SidebarModes.inbox,
            isOpen: true,
          });
        }
        if (sidebarState.sidebarMode === SidebarModes.inbox) {
          sidebarApi.setSidebarMode({
            newMode: SidebarModes.home,
            isOpen: false,
          });
        }
      }}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
      className="caption bold primary white exerpt"
    >
      Inbox
      <InboxCounter />
    </span>
  );
};

const MyWorkToggle: React.FC = () => {
  return (
    <span
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        if (e.shiftKey) {
          navigationApi.openSplitView({
            taskViewMode: TasksViewModes.MyTasks,
            viewName: GeneralViewsNames.Tasks,
          });
        } else {
          const baseSlug = store.getState().workspace?.slug;
          if (!baseSlug) return;
          locationSubject.next(`/${baseSlug}/my-work`);
        }
      }}
      className="caption bold primary white exerpt"
    >
      My Work
    </span>
  );
};

export default GlovalTopNav;
