import TokenDetailRow, {
  getTokenDescription,
} from "clarity-ui/tokenGateContainer/TokenDetailRow";
import Button, { ButtonTypes } from "components/Button";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClarityStore } from "store/storeExporter";
import { KeyOutlined, EditOutlined } from "@ant-design/icons";
import ItemDescription from "clarity-ui/ItemDescription";
import AddOrEditTokenGate from "./AddOrEditTokenGate";
import { ITokenVerification } from "clientApi/web3Api";
import styles from "./tokenGateContainer.module.scss";
import { IsInSertingInContainer } from "clarity-ui/TokenGateContainer";
import { ContainerTypes } from "utilities/types";
import { useOptionalClassName } from "utilities/hooks";

const TokenGateRow: React.FC<{
  gateId: string;
  showDetails?: boolean;
  canEditTokenGate: boolean;
  container?: {
    containerId: string;
    containerType: ContainerTypes;
  };
  linkId?: string;
  noUpdate?: boolean;
  overwriteEdit?: any;
  editable?: any;
  noPadding?: boolean;
}> = ({
  gateId,
  showDetails,
  container,
  overwriteEdit,
  linkId,
  noUpdate,
  editable = true,
  noPadding,
  canEditTokenGate,
}) => {
  const isSelecting = useContext(IsInSertingInContainer);
  const tokenGate = useSelector(
    (store: ClarityStore) => store.tokenGates.dict[gateId],
    shallowEqual
  );
  const [isHovered, setisHovered] = useState(false);

  const description = useMemo(() => {
    if (showDetails) return "";
    let name = "";
    const reqNumber = tokenGate ? tokenGate.tokenRequirements.length : 0;
    tokenGate?.tokenRequirements.forEach((token, index: number) => {
      let toAdd = String(token.ammount);
      const descr = getTokenDescription(token);
      toAdd = toAdd + " x " + descr;
      if (index < reqNumber - 1) toAdd = toAdd + ", ";
      name = name + toAdd;
    });
    return name;
  }, [tokenGate?.tokenRequirements, showDetails]);

  const [isEditing, setisEditing] = useState(false);

  useEffect(() => {
    if (isEditing) setisHovered(false);
  }, [isEditing]);

  const verifiedTokens: ITokenVerification[] = useMemo(() => {
    if (!tokenGate) return [];
    return tokenGate.tokenRequirements.map((token) => {
      return {
        success: true,
        tokenReq: token,
      } as ITokenVerification;
    });
  }, [tokenGate?.tokenRequirements]);

  const containerClassName = useOptionalClassName({
    baseStyle: styles.tokenGateRow,
    pairs: [
      {
        extraStyle: styles.selectableItem,
        withExtra: isSelecting,
      },
    ],
  });

  if (!tokenGate) return <></>;
  return (
    <>
      <div
        onMouseEnter={() => setisHovered(true)}
        onMouseLeave={() => setisHovered(false)}
        className={containerClassName}
      >
        <ItemDescription
          noPadding={noPadding}
          title={tokenGate.name}
          description={description}
          customStyle={showDetails ? { paddingLeft: 0 } : {}}
          icon={<KeyOutlined />}
        />
        {(isHovered || showDetails) && !noUpdate && editable && (
          <div
            className={showDetails ? "" : styles.tokenGateRowEditBtnContainer}
          >
            <Button
              className={styles.tokenGateRowEditBtn}
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                if (overwriteEdit) {
                  overwriteEdit();
                } else setisEditing(true);
              }}
              disabled={!canEditTokenGate}
              buttonType={ButtonTypes.LINK}
              icon={<EditOutlined />}
            />
          </div>
        )}

        {isEditing && editable && (
          <AddOrEditTokenGate
            setadding={setisEditing}
            existingTokenGate={tokenGate}
            container={container}
            linkId={linkId}
          />
        )}
      </div>
      {showDetails && (
        <TokenDetailContainer tokenRequirements={verifiedTokens} />
      )}
    </>
  );
};

export const TokenDetailContainer: React.FC<{
  tokenRequirements: ITokenVerification[];
  showStatus?: boolean;
}> = ({ tokenRequirements, showStatus }) => {
  const classes = useOptionalClassName({
    baseStyle: styles.tokenDetailContainer,
    pairs: [{ extraStyle: styles.showStatus, withExtra: showStatus }],
  });
  return (
    <div className={classes}>
      {tokenRequirements.map((token, index) => (
        <TokenDetailRow
          showStatus={showStatus}
          success={token.success}
          token={token.tokenReq}
          key={token.tokenReq.contractAddress + index}
          isFirst={index === 0}
          isLast={index === tokenRequirements.length - 1}
        />
      ))}
    </div>
  );
};

export default TokenGateRow;
