import { ContainerTypes, NavigationChunk, WorkTypes } from "./types";
import {
  FileDoneOutlined,
  AimOutlined,
  ScissorOutlined,
} from "@ant-design/icons";
import FileTextTwoTone from "icons/Components/FileTextTwoTone";
import ControlTwoTone from "icons/Components/ControlTwoTone";
import TagTwoTone from "icons/Components/TagTwoTone";
import CheckCircleTwoTone from "icons/Components/CheckCircleTwoTone";
import SnippetsTwoTone from "icons/Components/SnippetsTwoTone";
import ThunderboltTwoTone from "icons/Components/ThunderboltTwoTone";

export const getIconFromNavigationChunk = (
  navigationChunk: NavigationChunk
) => {
  if (navigationChunk.entity) {
    const containerType = navigationChunk.entity.containerType;
    const workType = navigationChunk.entity?.workType;
    return getIconFromContainerType(containerType, workType);
  }
  return getIconFromContainerType();
};

export const getIconFromContainerType = (
  containerType?: ContainerTypes,
  workType?: WorkTypes
) => {
  switch (containerType) {
    case ContainerTypes.DOCUMENT:
      return <TagTwoTone />;
    case ContainerTypes.NOTE:
      return <FileTextTwoTone />;
    case ContainerTypes.CUSTOM_VIEW:
      return <ControlTwoTone />;
    case ContainerTypes.CYCLE:
      return <ThunderboltTwoTone />;
    case ContainerTypes.TASK:
    case ContainerTypes.WORK:
    case ContainerTypes.PROJECT: {
      if (workType) {
        if (workType === WorkTypes.PROJECT) {
          return <FileDoneOutlined />;
        } else if (workType === WorkTypes.INITIATIVE) {
          return <AimOutlined />;
        } else if (workType === WorkTypes.TASK) {
          return <CheckCircleTwoTone />;
        }
      }
      return <CheckCircleTwoTone />;
    }
    case ContainerTypes.TEMPLATE:
      return <SnippetsTwoTone />;
    case ContainerTypes.SNIPPET:
      return <ScissorOutlined />;
  }
  return <TagTwoTone />;
};
