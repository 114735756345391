import {
  ChainNames,
  Chains,
  getNetworkIdentifierFromId,
  RPC,
} from "./connectors";

export type CoingeckoToken = {
  chainId: number;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  logoURI?: string;
};

export const NETWORK_TOKEN_CONTRACT =
  "0x0000000000000000000000000000000000000000";

const TokenApiURLs: { [id in ChainNames]: string[] } = {
  ETHEREUM: ["https://tokens.coingecko.com/uniswap/all.json"],
  BSC: ["https://tokens.pancakeswap.finance/pancakeswap-extended.json"],
  XDAI: [
    "https://unpkg.com/@1hive/default-token-list@5.17.1/build/honeyswap-default.tokenlist.json",
  ],
  POLYGON: [
    "https://unpkg.com/quickswap-default-token-list@1.0.91/build/quickswap-default.tokenlist.json",
  ],
  AVALANCHE: [
    "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/joe.tokenlist.json",
  ],
  FANTOM: [
    "https://raw.githubusercontent.com/Crocoswap/tokenlists/main/aeb.tokenlist.json",
  ],
  ARBITRUM: ["https://bridge.arbitrum.io/token-list-42161.json"],
  CELO: [
    "https://raw.githubusercontent.com/Ubeswap/default-token-list/master/ubeswap.token-list.json",
  ],
  HARMONY: [
    "https://raw.githubusercontent.com/DefiKingdoms/community-token-list/main/src/defikingdoms-default.tokenlist.json",
    "https://raw.githubusercontent.com/DefiKingdoms/community-token-list/main/build/defikingdoms-community.tokenlist.json",
  ],
  GOERLI: ["https://tokens.coingecko.com/uniswap/all.json"],
  OPTIMISM: ["https://static.optimism.io/optimism.tokenlist.json"],
  MOONRIVER: ["https://tokens.coingecko.com/moonriver/all.json"],
  RINKEBY: ["https://tokens.coingecko.com/uniswap/all.json"],
  ROPSTEN: ["https://tokens.coingecko.com/uniswap/all.json"],
};

export const fetchedTokensDict: { [id: string]: CoingeckoToken[] } = {};

export const fetchTokens = async (chain: Chains) => {
  const chainName = getNetworkIdentifierFromId(chain) as ChainNames;
  return Promise.all(
    TokenApiURLs[chainName].map((url: string) => fetch(url))
  ).then(async (response: Response[]) => {
    const res = await response[0].json?.();

    const tokenArrays = res.tokens;
    const finalTokenArray = tokenArrays.reduce((acc: any, curr: any) => {
      return acc.concat(
        ([curr] as any)?.filter(
          [ChainNames.GOERLI, ChainNames.RINKEBY, ChainNames.ROPSTEN].includes(
            chainName
          )
            ? (token: any) => token.chainId === Chains.ETHEREUM
            : (token: any) => token.chainId === chain
        )
      );
    }, []);
    const finalTokens = RPC[chainName]
      ? [RPC[chainName].nativeCurrency].concat(finalTokenArray)
      : finalTokenArray;
    fetchedTokensDict[chain] = finalTokens;

    return finalTokens;
  });
};
