import React, { useContext, useEffect, useState } from "react";
import { CommandPaletteContext } from "utilities/types";
import OptionLister from "../components/OptionLister";
import { getRecentsAsViewOptions } from "../helpers/contextOptionGetters";
import {
  CommandPaletteOption,
  CommandPaletteState,
  contextMatch,
} from "../helpers/types";

const OpenMode: React.FC = () => {
  const [options, setOptions] = useState<CommandPaletteOption[]>([]);
  const paletteStateContext = useContext(CommandPaletteState);

  useEffect(() => {
    const optionProps = contextMatch[CommandPaletteContext.OPEN];
    paletteStateContext.topBarText.next(optionProps.topBarText);
    const openOptions = optionProps.getOptions()();
    const recentOptions: CommandPaletteOption[] = getRecentsAsViewOptions();

    const filterStringSub = paletteStateContext.searchText.subscribe(
      (searchText) => {
        if (searchText) {
          setOptions(openOptions);
        } else {
          setOptions(recentOptions);
        }
      }
    );

    return () => {
      filterStringSub?.unsubscribe();
    };
  }, []);

  return <OptionLister options={options} />;
};

export default OpenMode;
