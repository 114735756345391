import React, { useCallback, useEffect, useState } from "react";
import { Form } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import TabLayout from "./TabLayout";
import styles from "./groups/groups.module.scss";
import { validateDomainName } from "utilities/regexUtilities";
import ClarityInput from "components/ClarityInput";
import ClarityTextArea from "components/ClarityTextArea";
import notificationsApi from "clientApi/notificationsApi";
import Button, { ButtonTypes } from "components/Button";
import { groupApi } from "clientApi/groupsApi";
import { Abilities, IGroup, BucketAlbums } from "utilities/types";
import ClarityLabel, { MessageLabelTypes } from "clarity-ui/ClarityLabel";
import { useAbilityChecker } from "editor/utils/customHooks";
import GroupPolicy from "./groups/GroupPolicy";
import PhotoUpload from "components/PhotoUpload";
import { throttle } from "lodash";

const CreateGroup: React.FC<{
  onGroupSucess?: any;
}> = ({ onGroupSucess }) => {
  const [newGroup, setNewGroup] = useState<IGroup>(
    groupApi.createNewEmptyGroup()
  );

  const canManageGroups = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_GROUPS,
  });

  const [slugAvaialble, setSlugAvaialble] = useState(true);
  const [coverPhotoUrl, setCoverPhotoUrl] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    setNewGroup({ ...newGroup, name: "" });
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value, name } = e.currentTarget;

    if (name === "slug") {
      setNewGroup({ ...newGroup, [name]: value.trim().toUpperCase() });
    } else {
      const slug = value.trim().substring(0, 3).toUpperCase();
      setNewGroup({ ...newGroup, [name]: value.trim(), slug });
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setNewGroup({ ...newGroup, description: value.trim() });
  };

  const isSlugNameValid =
    slugAvaialble &&
    validateDomainName(newGroup.slug) &&
    newGroup.slug.length < 6;

  const isValidGroup =
    isSlugNameValid && newGroup.name.length > 0 && newGroup.slug.length > 0;

  const handleSaveChanges = async () => {
    if (!canManageGroups) return;
    groupApi.addGroup(newGroup).then(
      () => {
        notificationsApi.displayConfirmation({
          title: "Success",
          duration: 4,
          body: "Group added",
        });
        setNewGroup(groupApi.createNewEmptyGroup());
        form.resetFields();
        setCoverPhotoUrl("");
        if (onGroupSucess) {
          onGroupSucess();
        }
      },
      (error) => {
        if (error?.response?.data) {
          notificationsApi.displayError({
            title: "Error",
            duration: 4,
            body: error?.response?.data.message,
          });
        }
        groupApi.deleteGroupDispatch(newGroup);
      }
    );
  };

  const onUploadSucess = (photoUrl: any) => {
    setNewGroup({ ...newGroup, coverPhotoUrl: photoUrl });
    setCoverPhotoUrl(photoUrl);
  };

  const checkUniqueSlug = async (slug: string) => {
    if (slug) {
      groupApi
        .checkUniqueSlug(slug.toUpperCase(), newGroup.workspaceId, "")
        .then(
          (res) => {
            console.log(res);
            setSlugAvaialble(true);
          },
          (error) => {
            setSlugAvaialble(false);
          }
        );
    }
  };

  const changeSlug = useCallback(
    throttle(checkUniqueSlug, 1000, { trailing: true, leading: false }),
    []
  );

  return (
    <>
      <TabLayout
        title="Create a new group"
        description="Each group in your base has its own members, documents, tasks, and workflows"
      >
        <Form form={form}>
          <div className={styles.options}>
            <div className={styles.options_inputs}>
              <Form.Item name="name" className={styles.options_margin}>
                <ClarityInput
                  name="name"
                  onChange={handleInputChange}
                  value={newGroup.name ?? ""}
                  label="Name"
                  maxLength={50}
                  required
                  disabled={!canManageGroups}
                />
              </Form.Item>

              <Form.Item name="slug" className={styles.options_margin}>
                <ClarityInput
                  name="slug"
                  onChange={(e) => {
                    handleInputChange(e);
                    changeSlug(e.target.value);
                  }}
                  value={newGroup.slug ?? ""}
                  label="Identifier"
                  required
                  maxLength={5}
                  disabled={!canManageGroups}
                />
                {isSlugNameValid && (
                  <div>
                    <span
                      className={`caption secondary ${styles.options_hint}`}
                    >
                      <InfoCircleOutlined /> Used to create task IDs
                      {newGroup.slug && <span> ex {newGroup.slug}–1234 </span>}
                    </span>
                  </div>
                )}
                {!isSlugNameValid && slugAvaialble && (
                  <ClarityLabel
                    message={"Please enter a valid Identifier name"}
                    type={MessageLabelTypes.error}
                  />
                )}
                {!isSlugNameValid && !slugAvaialble && (
                  <ClarityLabel
                    message={"A group with this identifier already exists"}
                    type={MessageLabelTypes.error}
                  />
                )}
              </Form.Item>
            </div>
            <div>
              <Form.Item name="description">
                <ClarityTextArea
                  className=""
                  label="Description"
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={handleTextAreaChange}
                  maxLength={200}
                  rows={2}
                  disabled={!canManageGroups}
                />
                <div>
                  <span className={`caption secondary ${styles.options_hint}`}>
                    <InfoCircleOutlined /> This tagline will be displayed on the
                    Groups list and Group Profile
                  </span>
                </div>
              </Form.Item>
            </div>
          </div>
        </Form>

        <h4 className={styles.options_avatar__heading}>Cover Photo</h4>
        <div className={styles.options_avatar}>
          <PhotoUpload
            onUploadSucess={onUploadSucess}
            disabled={!canManageGroups}
            bucketName={BucketAlbums.GROUPCOVER}
            exitingUrl={coverPhotoUrl}
            fallBackComponent={
              <Button
                disabled={!canManageGroups}
                buttonType={ButtonTypes.DEFAULT}
              >
                Upload
              </Button>
            }
          />
        </div>
        <GroupPolicy
          setRoles={(roles: string[]) => {
            setNewGroup({ ...newGroup, requiredRoles: roles });
          }}
          groupRequiredRoles={newGroup.requiredRoles}
        />

        <Button
          buttonType={ButtonTypes.LARGE_PRIMARY}
          onClick={handleSaveChanges}
          disabled={!isValidGroup}
          isLoading={false}
        >
          Create Group
        </Button>
      </TabLayout>
    </>
  );
};

export default CreateGroup;
