import { axiosInstance } from "index";
import {
  ADD_NEW_FAVORITE,
  REMOVE_FAVORITE,
  UPDATE_FAVORITE,
} from "store/actions";
import store from "store/storeExporter";
import { NavigationChunk } from "utilities/types";

class FavoriteApi {
  async addFavorite(params: {
    entityId: string;
    entityType: string;
    navigationChunk: NavigationChunk;
    name: string;
  }) {
    const baseId = store.getState().workspace.id;

    return axiosInstance
      .post("/api/favorite", {
        entityId: params.entityId,
        entityType: params.entityType,
        navigationChunk: params.navigationChunk,
        name: params.name,
        baseId,
      })
      .then((res) => {
        store.dispatch({
          type: ADD_NEW_FAVORITE,
          favorite: res.data,
        });
      });
  }

  async deleteFavorite(params: { favoriteId: string }) {
    return axiosInstance
      .delete("/api/favorite", {
        data: { favoriteId: params.favoriteId },
      })
      .then((res) => {
        store.dispatch({
          type: REMOVE_FAVORITE,
          id: params.favoriteId,
        });
      });
  }

  async updateFavoriteRank(params: {
    favoriteId: string;
    newRank: string;
    newFavoriteArray: string[];
  }) {
    store.dispatch({
      type: UPDATE_FAVORITE,
      favoriteId: params.favoriteId,
      newRank: params.newRank,
      newFavoriteArray: params.newFavoriteArray,
    });
    return axiosInstance.patch("/api/favorite", {
      favoriteId: params.favoriteId,
      delta: { rank: params.newRank },
    });
  }

  async changedFavorite(data: any) {
    switch (data.changeType) {
      case "add": {
        store.dispatch({
          type: ADD_NEW_FAVORITE,
          favorite: data.favorite,
        });
        break;
      }
      case "deleted": {
        store.dispatch({
          type: REMOVE_FAVORITE,
          id: data.favorite.id,
        });
        break;
      }
      case "updated": {
        store.dispatch({
          type: UPDATE_FAVORITE,
          favoriteId: data.favorite.id,
          newRank: data.favorite.newRank,
        });
      }
    }
  }
}

export const favoriteApi = new FavoriteApi();
