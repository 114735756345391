import Button, { ButtonTypes } from "components/Button";
import React, { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import styles from "./footerForSave/footerForSave.module.scss";

interface Props {
  onSave: any;
  onCancel: any;
}

export const baseNavigationSubject = new BehaviorSubject(false);

const FooterForSave = ({ onSave, onCancel }: Props) => {
  const [navigationTried, setnavigationTried] = useState(false);
  useEffect(() => {
    const subscription = baseNavigationSubject.subscribe((navigationTried) => {
      if (navigationTried) {
        setnavigationTried(true);
        setTimeout(() => {
          baseNavigationSubject.next(false);
        }, 2000);
      } else setnavigationTried(false);
    });
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return (
    <div
      className={
        navigationTried
          ? `${styles.footer_stopNavigation} ${styles.footer_buttonFooter}`
          : styles.footer_buttonFooter
      }
    >
      <span className="small regular secondary">You have unsaved changes </span>
      <div className={styles.footer_actions}>
        <Button
          type="text"
          buttonType={ButtonTypes.LINK}
          onClick={onCancel}
          style={{ borderRadius: 3 }}
        >
          Discard
        </Button>
        <Button
          type="text"
          buttonType={ButtonTypes.PRIMARY}
          onClick={onSave}
          style={{ borderRadius: 3 }}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default FooterForSave;
