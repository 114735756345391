import React, { useMemo } from "react";
import { useShallowSelector } from "utilities/hooks";
import { GroupGeneralViews, TasksViewModes } from "utilities/types";
import ProjectTwoTone from "icons/Components/ProjectTwoTone";
import SnippetsTwoTone from "icons/Components/SnippetsTwoTone";
import CheckSquareTwoTone from "icons/Components/CheckSquareTwoTone";
import store from "store/storeExporter";
import { OPEN_BASE_SETTING_SET_INTIAL } from "store/actions";
import SettingTwoTone from "icons/Components/SettingTwoTone";
import ProfileTwoTone from "icons/Components/ProfileTwoTone";
import ThunderboltTwoTone from "icons/Components/ThunderboltTwoTone";

enum Options {
  overview = "overview",
  work = "work",
  projects = "projects",
  tasks = "tasks",
  docs = "docs",
  cycles = "sprints",
  // payouts = "payouts",
  settings = "settings",
}

const GroupNavigationItems: React.FC = () => {
  return <></>;
};

export const useGroupNavigationOptions = (groupId: string) => {
  const group = useShallowSelector((store) => store.groups.dict[groupId]);
  const baseSlug = useShallowSelector((store) => store.workspace.slug);
  const showPayouts = useShallowSelector(
    (store) => store.workspace.showContributions
  );

  const options = useMemo(() => {
    const possibleOptions: Options[] = [];
    orderedOptions.forEach((option) => {
      if (option === Options.cycles) {
        if (group?.showCycles) {
          possibleOptions.push(option);
        }
        return;
      }
      // if (option === Options.payouts) {
      //   if (showPayouts) {
      //     possibleOptions.push(option);
      //   }
      //   return;
      // }
      possibleOptions.push(option);
    });
    return possibleOptions.map(
      (option) => optionsData(group.id, group.slug, baseSlug)[option]
    );
  }, [group?.id, group?.showCycles, showPayouts]);

  return options;
};

const optionsData = (groupId: string, groupSlug: string, baseSlug: string) => {
  return {
    [Options.overview]: {
      key: "home",
      name: () => "Overview",
      icon: <ProfileTwoTone />,
      url: () => `group/${groupSlug}`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.GroupProfile,
        groupSlug,
      }),
    },
    [Options.projects]: {
      key: "roadmap",
      name: () => "Projects",
      icon: <CheckSquareTwoTone />,
      url: () => `group/${groupSlug}/projects`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.Projects,
        groupSlug,
      }),
    },
    [Options.tasks]: {
      key: "tasks",
      name: () => "Tasks",
      icon: <ProjectTwoTone />,
      url: () => `group/${groupSlug}/tasks`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.Tasks,
        taskViewMode: TasksViewModes.Tasks,
        groupSlug,
      }),
    },
    [Options.cycles]: {
      key: "sprints",
      name: () => "Sprints",
      icon: <ThunderboltTwoTone />,
      url: () => `group/${groupSlug}/sprints`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.Cycles,
        taskViewMode: TasksViewModes.Cycle,
        groupSlug,
      }),
    },
    [Options.docs]: {
      key: "notes",
      name: () => "Docs",
      icon: <SnippetsTwoTone />,
      url: () => `group/${groupSlug}/docs`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.Notes,
        groupSlug,
      }),
    },
    // [Options.payouts]: {
    //   key: "payouts",
    //   name: () => "Payouts",
    //   icon: <TrophyTwoTone />,
    //   url: () => `group/${groupSlug}/payouts`,
    //   secondaryViewConfig: () => ({
    //     viewName: GroupGeneralViews.Contributions,
    //     groupSlug,
    //   }),
    // },
    [Options.work]: {
      key: "work",
      name: () => "Work",
      icon: <CheckSquareTwoTone />,
      activeOn: ["roadmap", Options.tasks, Options.cycles],
      url: () => `group/${groupSlug}/tasks`,
      secondaryViewConfig: () => ({
        viewName: GroupGeneralViews.Projects,
        groupSlug,
      }),
    },
    [Options.settings]: {
      key: Options.settings,
      name: () => "Settings",
      icon: <SettingTwoTone />,
      url: () => "",
      action: () => {
        store.dispatch({
          type: OPEN_BASE_SETTING_SET_INTIAL,
          param: {
            openSettings: {
              isOpen: true,
              selectedSection: "editGroup",
              selectedGroup: groupId,
            },
          },
        });
      },
      secondaryViewConfig: () => {},
    },

    url: () => `${groupSlug}`,
  };
};

const orderedOptions = [
  Options.overview,
  Options.work,
  // Options.projects,
  // Options.tasks,
  Options.docs,
  // Options.cycles,
  // Options.payouts,
  Options.settings,
];

export default GroupNavigationItems;
