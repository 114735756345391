import Button, { ButtonTypes, IconSides } from "components/Button";
import { useAbilityChecker } from "editor/utils/customHooks";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Abilities, HomeScreenObj, HomeSectionObj } from "utilities/types";
import styles from "./pinsEditor/pinsEditor.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import DeleteTwoTone from "icons/Components/DeleteTwoTone";
import SixDotImg from "icons/six-dot-handle.svg";
import { useOptionalClassName } from "utilities/hooks";
import { VALUE_ENTER, VALUE_ESCAPE } from "keycode-js";
import { swapItemFromIndexToIndex } from "utilities/arrayUtils";
import ConfirmModal from "clarity-ui/ConfirmModal";
const { v4: uuidv4 } = require("uuid");

const CollectionsEditor: React.FC<{
  collections: HomeScreenObj;
  updateOrAddCollection: (item: HomeSectionObj) => void;
  reorderCollection: (ids: string[]) => void;
  deleteCollection: (id: string) => void;
}> = ({
  collections,
  updateOrAddCollection,
  reorderCollection,
  deleteCollection,
}) => {
  const [addingCollection, setaddingCollection] = useState(false);

  const canEditHome = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_HOME,
  });

  return (
    <div className={styles.modeContainer}>
      <div className={styles.headingContainer} style={{ marginBottom: "16px" }}>
        <h4>Edit collections</h4>
      </div>

      {collections.sectionIds.length === 0 && !addingCollection && (
        <div
          style={{
            paddingTop: "20px",
            paddingBottom: "5px",
          }}
        >
          <span className="disabled body">There are no collections</span>
        </div>
      )}
      <CollectionList
        collections={collections}
        updateOrAddCollection={updateOrAddCollection}
        reorderCollection={reorderCollection}
        deleteCollection={deleteCollection}
      />
      {addingCollection && (
        <ResourceEditor
          name=""
          cancel={() => setaddingCollection(false)}
          save={(name: string) => {
            updateOrAddCollection({
              id: uuidv4(),
              title: name,
              resourcesIds: [],
              resourcesDict: {},
            });
            setaddingCollection(false);
          }}
          addingNew={true}
        />
      )}
      {!addingCollection && (
        <Button
          icon={<PlusOutlined />}
          style={{ marginTop: "32px" }}
          disabled={!canEditHome}
          iconSide={IconSides.LEFT}
          onClick={() => setaddingCollection(true)}
        >
          Collection
        </Button>
      )}
    </div>
  );
};

const CollectionList: React.FC<{
  collections: HomeScreenObj;
  updateOrAddCollection: (item: HomeSectionObj) => void;
  reorderCollection: (ids: string[]) => void;
  deleteCollection: (id: string) => void;
}> = ({
  collections,
  updateOrAddCollection,
  reorderCollection,
  deleteCollection,
}) => {
  const canEditHome = useAbilityChecker({
    abilityName: Abilities.CAN_MANAGE_HOME,
  });
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);
  const [toDelete, setToDelete] = useState<string>("");
  const [home, setHome] = useState({ ...collections });

  useEffect(() => {
    setHome({
      ...collections,
    });
  }, [collections]);

  const handleDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) return;
    if (!canEditHome) return;
    const prevIdx = source.index;
    const newIdx = destination.index;
    if (newIdx !== undefined) {
      const updatedIds = swapItemFromIndexToIndex(
        collections.sectionIds,
        prevIdx,
        newIdx
      );

      setHome({
        ...home,
        sectionIds: updatedIds,
      });
      reorderCollection(updatedIds);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={(result: any) => handleDragEnd(result)}>
        <Droppable isDropDisabled={!canEditHome} droppableId="droppable">
          {(provided, snapshot) => (
            <>
              <div
                {...provided.droppableProps}
                className={
                  snapshot.isDraggingOver ? styles.isDraggingOver : undefined
                }
                ref={provided.innerRef}
              >
                {home.sectionIds
                  .filter((collectionId) => home.sectionsDict[collectionId])
                  .map((collectionId, index) => (
                    <Row
                      canEditHome={canEditHome}
                      id={collectionId}
                      collection={home.sectionsDict[collectionId]}
                      key={collectionId}
                      index={index}
                      updateOrAddCollection={updateOrAddCollection}
                      deleteCollection={(id) => {
                        if (
                          home.sectionsDict[collectionId].resourcesIds.length >
                          0
                        ) {
                          setToDelete(id);
                          setIsConfirmationModalVisible(true);
                        } else {
                          deleteCollection(id);
                        }
                      }}
                    />
                  ))}
                {provided.placeholder}
              </div>
            </>
          )}
        </Droppable>
      </DragDropContext>
      {isConfirmationModalVisible && (
        <ConfirmModal
          onConfirm={() => {
            deleteCollection(toDelete);
          }}
          confirmationText="Delete"
          title="Delete collection"
          close={() => setIsConfirmationModalVisible(false)}
        >
          <div
            className="body2 regular"
            style={{
              marginTop: "24px",
              marginBottom: "40px",
            }}
          >
            <span>
              Are you sure you want to delete this collections and the it's
              pins?
            </span>
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

const Row: React.FC<{
  id: string;
  index: number;
  collection: HomeSectionObj;
  canEditHome: boolean;
  updateOrAddCollection: (item: HomeSectionObj) => void;
  deleteCollection: (id: string) => void;
}> = ({
  id,
  index,
  collection,
  canEditHome,
  updateOrAddCollection,
  deleteCollection,
}) => {
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
    visibility: !canEditHome ? "hidden" : undefined,
  });

  return (
    <Draggable
      isDragDisabled={!canEditHome}
      key={id}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...provided.draggableProps}
        >
          <div className={styles.rowContainer}>
            <div className={styles.grabHandleIconContainer}>
              <img
                src={SixDotImg}
                alt="Grab Handle"
                style={{ visibility: !canEditHome ? "hidden" : undefined }}
                className={
                  styles.grabHandleIcon +
                  " " +
                  (snapshot.isDragging ? styles.dragging : "")
                }
                {...provided.dragHandleProps}
              />
            </div>

            <ResourceEditor
              name={collection.title}
              disabled={!canEditHome}
              save={(name: string) => {
                updateOrAddCollection({
                  ...collection,
                  title: name,
                });
              }}
              cancel={() => {}}
            >
              <span className={styles.deleteBtn}>
                <Button
                  onClick={() => {
                    if (collection.resourcesIds.length > 0) {
                    }
                    deleteCollection(collection.id);
                  }}
                  buttonType={ButtonTypes.LINK}
                  disabled={!canEditHome}
                  icon={<DeleteTwoTone />}
                  style={{ marginRight: "8px" }}
                />
              </span>
            </ResourceEditor>
          </div>
        </div>
      )}
    </Draggable>
  );
};

const ResourceEditor: React.FC<{
  name: string;
  save: (name: string) => void;
  cancel: any;
  disabled?: boolean;
  addingNew?: boolean;
}> = ({ name, children, save, cancel, addingNew, disabled }) => {
  const [active, setactive] = useState(false);
  const [editingResourceName, seteditingResourceName] = useState(name ?? "");

  const className = useOptionalClassName({
    baseStyle: styles.rowEditor,
    pairs: [
      { extraStyle: styles.active, withExtra: active },
      { extraStyle: styles.addingNew, withExtra: addingNew },
    ],
  });

  return (
    <div
      className={className}
      onBlur={() => {
        const trimmedResourceName = editingResourceName.trim();
        if (editingResourceName.length >= 0 && trimmedResourceName.length < 1) {
          setactive(false);
          cancel();
          return;
        } else {
          save(trimmedResourceName);
          setactive(false);
        }
      }}
    >
      <div className={styles.inputsHolder}>
        <div className={styles.singleInput}>
          <input
            value={editingResourceName}
            className="caption primary medium"
            placeholder="Collection name"
            autoFocus={addingNew}
            disabled={disabled}
            onChange={(e) => seteditingResourceName(e.target.value)}
            onFocus={() => setactive(true)}
            onKeyDown={(e) => {
              if (e.key === VALUE_ESCAPE) {
                e.stopPropagation();
                e.preventDefault();
                cancel();
                setactive(false);

                return;
              }

              if (e.key === VALUE_ENTER) {
                e.stopPropagation();
                e.preventDefault();
                const trimmedResourceName = editingResourceName.trim();
                if (trimmedResourceName.length < 1) {
                  setactive(false);
                  cancel();
                  return;
                }
                save(trimmedResourceName);
                setactive(false);
                return;
              }
            }}
          />
        </div>
      </div>
      {children}
    </div>
  );
};
export default CollectionsEditor;
