import { Progress, ProgressProps } from "antd";
import { connect } from "react-redux";
import { IUserObj } from "utilities/types";
import { videoIds as gettingStartedVideoIds } from "screens/base/GettingStartedModal";

interface IGettingStartedProgressProps {
  showInfo?: ProgressProps["showInfo"];
  currentUrlIdx?: number;
}

interface IDispatchProgressProps {}

interface IStateProgressProps {
  user: IUserObj;
}

type Props = IGettingStartedProgressProps &
  IDispatchProgressProps &
  IStateProgressProps;

const mapDispatch = (dispatch: any) => ({});

const mapState = (state: any) => ({
  user: state.user,
});

const strokeColor = "var(--surface16)";
const trailColor = "#ede9ea";

export default connect(
  mapState,
  mapDispatch
)((props: Props) => {
  const { user, showInfo } = props;

  const totalUrls = gettingStartedVideoIds.length;

  const progressPercentage = Math.floor(
    typeof props.currentUrlIdx === "number"
      ? (((props.currentUrlIdx || 0) + 1) / totalUrls) * 100
      : (((user.gettingStartedProgress || 0) + 1) / totalUrls) * 100
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {showInfo && (
        <span
          className="small secondary"
          style={{ marginBottom: "-4px", marginLeft: "2px" }}
        >
          {progressPercentage}% complete
        </span>
      )}
      <Progress
        percent={progressPercentage}
        strokeColor={strokeColor}
        showInfo={false}
        trailColor={trailColor}
      />
    </div>
  );
});
