import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ContainerTypes,
  ITokenGate,
  ITokenGateRule,
  ITokenRequirement,
  TokenTypes,
} from "utilities/types";
import Button, { ButtonTypes } from "components/Button";
import { tokenGateApi } from "clientApi/tokenGatesApi";
import Modal from "clarity-ui/Modal";
import ClarityInput from "components/ClarityInput";
import styles from "./tokenGateContainer.module.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { IsInSertingInContainer } from "clarity-ui/TokenGateContainer";
import notificationsApi from "clientApi/notificationsApi";
import NetworkSelect from "components/NetworkSelect";
import { NETWORK_TOKEN_CONTRACT } from "utilities/web3/tokens";
import TokenSelector, { ITokenConfirmation } from "components/TokenSelector";
import ClaritySelect, { IOptions } from "components/ClaritySelect";
import { tokenTypeArray } from "clarity-ui/TokenSection";
import { web3Api } from "clientApi/web3Api";
import store from "store/storeExporter";
import { SET_LAST_USED_TOKEN } from "store/actions";

export interface ITokenCreate {
  valid: boolean;
  token: Partial<ITokenRequirement>;
}

const ruleOptions: IOptions[] = [
  {
    label: "Must meet all requirements",
    value: ITokenGateRule.AND,
  },
  {
    label: "Must meet at least one requirement",
    value: ITokenGateRule.OR,
  },
];

const AddOrEditTokenGate: React.FC<{
  setadding: React.Dispatch<React.SetStateAction<boolean>>;
  existingTokenGate?: ITokenGate;
  isInseringInContainer?: boolean;
  container?: {
    containerId: string;
    containerType: ContainerTypes;
  };
  linkId?: string;
}> = ({
  setadding,
  existingTokenGate,
  isInseringInContainer,
  container,
  linkId,
}) => {
  const initialValue = useMemo(() => {
    if (existingTokenGate) return existingTokenGate;
    else
      return {
        roleName: "",
        tokenRequirements: [],
        rule: ITokenGateRule.AND,
      };
  }, [existingTokenGate]);

  const initialTokens = useMemo(() => {
    if (existingTokenGate) {
      return existingTokenGate.tokenRequirements.map((token) => {
        return {
          valid: true,
          token,
        };
      });
    }
    return [
      {
        valid: false,
        token: {
          ammount: 0,
          networkId: web3Api.networkId,
          // contractAddress: "0x0000000000000000000000000000000000000000",
          // type: TokenTypes.NATIVE_CURRENCY,
          tokenIds: "",
          id: `1`,
        },
      },
    ];
  }, [existingTokenGate]);

  const insertFunction =
    useContext(IsInSertingInContainer) || isInseringInContainer;

  const [gate, setgate] = useState<Partial<ITokenGate>>(initialValue);

  const [partialTokens, setPartialTokens] =
    useState<ITokenCreate[]>(initialTokens);

  const partialTokensRef = useRef(partialTokens);

  const addGate = useCallback(() => {
    const fullGate = { ...gate };
    fullGate.tokenRequirements = partialTokensRef.current.map(
      (verifiedToken): ITokenRequirement =>
        verifiedToken.token as ITokenRequirement
    );
    if (existingTokenGate) {
      tokenGateApi.updateTokenGate(existingTokenGate.id, {
        update: { ...fullGate },
      });
      setadding(false);
    } else {
      tokenGateApi.addNewTokenGate(
        fullGate,
        insertFunction,
        container?.containerId,
        container?.containerType
      );
      setadding(false);
    }
  }, [gate]);

  const addTokenReq = useCallback(() => {
    const tokens = [...partialTokensRef.current];
    const newReq: ITokenCreate = {
      valid: false,
      token: {
        ammount: 0,
        networkId: web3Api.networkId,
        id: "1",
      },
    };
    tokens.push(newReq);
    setPartialTokens(tokens);
  }, []);

  const deleteTokenReq = useCallback(async () => {
    if (existingTokenGate?.id) {
      try {
        await tokenGateApi.deleteTokenGate(existingTokenGate?.id);
        setadding(false);
      } catch (e) {
        notificationsApi.displayError({
          body: (
            <span>This token gate cannot be deleted because it is in use</span>
          ),
          duration: 3,
        });
      }
    }
  }, []);

  useEffect(() => {
    partialTokensRef.current = partialTokens;
  }, [partialTokens]);

  const allValid = useMemo(() => {
    return partialTokens.every((token) => token.valid);
  }, [partialTokens]);

  return (
    <>
      <Modal
        size="large"
        zIndex={101}
        hideModal={() => {}}
        className={styles.modalMargin}
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <h4>{existingTokenGate ? "Modify Token Gate" : "New Token Gate"}</h4>
          <div style={{ flexGrow: 1 }}></div>
          {existingTokenGate && (
            <>
              <Button
                buttonType={ButtonTypes.LINK}
                onClick={() => deleteTokenReq()}
              >
                Delete
              </Button>
            </>
          )}
          <Button
            buttonType={ButtonTypes.DEFAULT}
            onClick={() => setadding(false)}
          >
            Cancel
          </Button>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            onClick={addGate}
            disabled={
              !allValid || !gate.name || gate.name?.length === 0 || !gate.rule
            }
          >
            {insertFunction ? "Save & Apply" : "Save"}
          </Button>
        </div>
        <div style={{ marginTop: "30px" }}>
          <div>
            <ClarityInput
              label="Nickname"
              value={gate.name}
              placeholder="Enter Nickname"
              onChange={(e) => {
                setgate({ ...gate, name: e.target.value });
              }}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <ClaritySelect
              label="Rule"
              value={gate.rule}
              options={ruleOptions}
              placeholder="Add Rule"
              onChange={(e: ITokenGateRule) => {
                setgate({ ...gate, rule: e });
              }}
            />
          </div>
          {partialTokens.map((partialToken, index) => (
            <TokenReqCreator
              key={(partialToken.token.id ?? "1") + index}
              partialToken={partialToken}
              partialTokensRef={partialTokensRef}
              index={index}
              setPartialTokens={setPartialTokens}
            />
          ))}
          <div className={styles.actionRow} style={{ marginTop: "20px" }}>
            <Button onClick={addTokenReq}>Add Another</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const TokenReqCreator: React.FC<{
  index: number;
  partialToken: ITokenCreate;
  partialTokensRef: React.MutableRefObject<ITokenCreate[]>;
  setPartialTokens: React.Dispatch<React.SetStateAction<ITokenCreate[]>>;
}> = ({ partialToken, index, setPartialTokens, partialTokensRef }) => {
  const [token, setToken] = useState<ITokenCreate>(partialToken);

  const tokenRef = useRef(token);
  const isFirstLoad = useRef(true);

  const handleChange = (e: TokenTypes) => {
    setToken({
      valid: false,
      token: {
        type: e,
        ammount: 0,
        contractAddress: "",
        id: token.token.id,
        networkId: token.token.networkId ?? 1,
        symbol: "",
        name: "",
        tokenIds: "",
      },
    });
  };

  const handleNetworkChange = (e: number) => {
    web3Api.networkId = e;
    setToken({
      valid: false,
      token: {
        type: token.token.type,
        ammount: 0,
        id: "1",
        networkId: e,
        tokenIds: "",
      },
    });
  };

  const deleteRequirements = () => {
    if (partialTokensRef.current.length === 1) {
      handleChange(TokenTypes.ERC20);
    } else {
      let tokens = [...partialTokensRef.current];
      tokens.splice(index, 1);
      setPartialTokens(tokens);
    }
  };

  useEffect(() => {
    tokenRef.current = token;
    if (!isFirstLoad.current) {
      const tokens = [...partialTokensRef.current];
      tokens[index] = token;
      setPartialTokens(tokens);
    } else isFirstLoad.current = false;
  }, [token]);

  const updateToken = useCallback(
    (
      partial: Partial<ITokenRequirement>,
      fullPartial?: Partial<ITokenCreate>
    ) => {
      const addedObj = fullPartial ? fullPartial : {};
      const updatedTokenData = { ...tokenRef.current.token, ...partial };

      if (
        updatedTokenData.type &&
        [TokenTypes.NATIVE_CURRENCY, TokenTypes.ERC20].includes(
          updatedTokenData.type
        )
      ) {
        if (updatedTokenData.contractAddress === NETWORK_TOKEN_CONTRACT) {
          updatedTokenData.type = TokenTypes.NATIVE_CURRENCY;
        } else updatedTokenData.type = TokenTypes.ERC20;
      }

      if (
        updatedTokenData.contractAddress &&
        updatedTokenData.ammount &&
        updatedTokenData.ammount > 0 &&
        updatedTokenData.name &&
        updatedTokenData.symbol
      ) {
        addedObj.valid = true;
      }

      return setToken({
        ...tokenRef.current,
        token: updatedTokenData,
        ...addedObj,
      });
    },
    []
  );

  const onTokenSelection = useCallback((contractAddress: string) => {
    const partial: Partial<ITokenRequirement> = { contractAddress };
    if (contractAddress === NETWORK_TOKEN_CONTRACT) {
      partial.type = TokenTypes.NATIVE_CURRENCY;
    }
    store.dispatch({
      type: SET_LAST_USED_TOKEN,
      params: {
        networkId: partial?.networkId,
        contractAddress: contractAddress,
      },
    });
    updateToken(partial);
  }, []);

  const onConfirmedToken = useCallback((data: ITokenConfirmation) => {
    updateToken(data);
  }, []);

  const changeType = (type: TokenTypes) => {
    setToken({
      valid: false,
      token: {
        type: type,
        ammount: 0,
        id: "1",
        networkId: token.token.networkId,
        tokenIds: "",
      },
    });
  };

  return (
    <div className={styles.tokenDetailGroup}>
      <div className={styles.optionsRow}>
        <div className={styles.option}>
          <label className={styles.label}>Token Type</label>
          <ClaritySelect
            onChange={changeType}
            placeholder={"Select token type"}
            value={mergeTypes(token.token.type)}
            options={tokenTypeArray}
          />
        </div>
      </div>
      {token.token.type && (
        <div className={styles.optionsRow}>
          <div className={styles.option}>
            <NetworkSelect
              selectedNetworkId={token.token.networkId ?? 1}
              onSelectionChanged={handleNetworkChange}
            />
          </div>
        </div>
      )}
      {token.token.type && token.token.networkId && (
        <div className={styles.optionsRow}>
          <div className={styles.option}>
            <label className={styles.label}>
              {getTokenLabel(token.token.type)}
            </label>
            <TokenSelector
              networkId={token.token.networkId ?? 1}
              tokenType={token.token.type ?? TokenTypes.ERC20}
              onSelect={onTokenSelection}
              selectedItem={token.token.contractAddress}
              onConfirmedToken={onConfirmedToken}
            />
          </div>
        </div>
      )}
      {token.token.type && token.token.contractAddress && (
        <div className={styles.optionsRow}>
          <div className={styles.option}>
            <label className={styles.label}>Minimum amount</label>
            <ClarityInput
              min={0}
              value={token.token.ammount ? token.token.ammount : 0}
              type="number"
              onChange={(e) => {
                const value = Number(e.target.valueAsNumber);
                if (value) {
                  e.target.value = String(value);
                } else {
                  if (e.target.value !== "0" && e.target.value !== "") {
                    e.preventDefault();
                    return;
                  }
                }
                const isValidSet: Partial<ITokenCreate> | undefined = {
                  valid: Number(e.target.valueAsNumber) > 0,
                };
                updateToken(
                  { ammount: Number(e.target.valueAsNumber) },
                  isValidSet
                );
              }}
            />
          </div>
        </div>
      )}
      {token.token.type &&
        token.token.contractAddress &&
        [TokenTypes.ERC1155, TokenTypes.ERC721].includes(token.token.type) && (
          <div className={styles.optionsRow}>
            <div className={styles.option}>
              <label className={styles.label}>Token ID</label>
              <ClarityInput
                placeholder="Example: 1–5, 10–20, 45, 100–200"
                value={token.token.tokenIds ? token.token.tokenIds : ""}
                onChange={(e) => {
                  updateToken({ tokenIds: e.target.value });
                }}
              />
              <p className={styles.explainer}>
                Separate multiple IDs with a comma, or use hyphens to enter a
                range of IDs
              </p>
            </div>
          </div>
        )}
      <div className={styles.actionRow}>
        <Button
          onClick={deleteRequirements}
          buttonType={ButtonTypes.LINK}
          icon={<DeleteOutlined />}
        ></Button>
      </div>
    </div>
  );
};

const mergeTypes = (tokenType?: TokenTypes) => {
  if (!tokenType) return;
  if ([TokenTypes.ERC20, TokenTypes.NATIVE_CURRENCY].includes(tokenType))
    return TokenTypes.ERC20;
  if ([TokenTypes.ERC721, TokenTypes.ERC1155].includes(tokenType))
    return TokenTypes.ERC721;
};

const getTokenLabel = (tokenType: TokenTypes) => {
  if ([TokenTypes.ERC20, TokenTypes.NATIVE_CURRENCY].includes(tokenType))
    return "Token";
  if ([TokenTypes.ERC721, TokenTypes.ERC1155].includes(tokenType)) return "NFT";
};

export default AddOrEditTokenGate;
