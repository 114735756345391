import React from "react";
import moment from "moment";
import styles from "./dueDateDisplay/dueDateDisplay.module.scss";
import { useOptionalClassName } from "utilities/hooks";
import { useCommandPaletteShower } from "store/reducers/commandPaletteReducer";
import { CommandPaletteContext, ContainerTypes } from "utilities/types";

export default function DueDateDisplay({
  dueDate,
  isClosed,
  editId,
  extraClass,
  containerType,
}: {
  dueDate?: Date | null;
  isClosed: boolean;
  editId?: string;
  extraClass?: string;
  containerType?: ContainerTypes;
}): JSX.Element {
  if (!dueDate) return <></>;
  return (
    <HasDueDate
      dueDate={dueDate}
      isClosed={isClosed}
      editId={editId}
      extraClass={extraClass}
    />
  );
}

const HasDueDate: React.FC<{
  dueDate: Date;
  isClosed: boolean;
  editId?: string;
  extraClass?: string;
  containerType?: ContainerTypes;
}> = ({ dueDate, isClosed, editId, extraClass, containerType }) => {
  const isThisWeek = moment(dueDate).isSame(new Date(), "week");
  const isPast = moment(new Date()).isSameOrAfter(dueDate, "day");
  const tomorrow = moment(new Date(), "DD-MM-YYYY").add(1, "days");
  const isTomorrow = moment(dueDate).isSame(tomorrow, "day");
  const className = useGetClassName(
    { isThisWeek, isPast, isTomorrow },
    isClosed
  );
  const openPalette = useCommandPaletteShower();
  const edit: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openPalette(CommandPaletteContext.DUE_DATE, {
      selectedItemIds: editId ? [editId] : [],
      slectedItemsType: containerType ?? ContainerTypes.WORK,
    });
  };

  return (
    <div
      className={`${className} ${extraClass}`}
      onClick={!editId ? undefined : edit}
    >
      <span>{moment.utc(dueDate).format("MMM D")}</span>
    </div>
  );
};

function useGetClassName(
  {
    isThisWeek,
    isPast,
    isTomorrow,
  }: {
    isThisWeek: boolean;
    isPast: boolean;
    isTomorrow: boolean;
  },
  isClosed: boolean
) {
  return useOptionalClassName({
    baseStyle: styles.container,
    pairs: [
      {
        extraStyle: styles.containerDueTodayOrLate,
        withExtra: !isClosed && isPast,
      },
      {
        extraStyle: styles.containerDueTomorrow,
        withExtra: !isClosed && isTomorrow,
      },
      {
        extraStyle: styles.containerDueThisWeek,
        withExtra: !isClosed && isThisWeek,
      },
    ],
  });
}
