import { connect, batch } from "react-redux";
import {
  useEffect,
  memo,
  useMemo,
  useRef,
  useLayoutEffect,
  useState,
} from "react";
import socketIoClient from "socket.io-client";
import { Switch, Route, Redirect } from "react-router-dom";
import store, {
  emitFocusChange,
  joinedRooms,
  prevState,
} from "./store/storeExporter";

// Internal Modules
import * as actionTypes from "store/actions";
import {
  BaseType,
  ContainerTypes,
  IUserObj,
  IWorkspaceObj,
  mapContainerTypeToPrimitive,
  PermissionType,
  PrimitiveScopes,
  UserRole,
} from "utilities/types";
import { logUserOut, refreshTheToken } from "utilities/authTokens";

// Components
import ForgotPassword from "screens/ForgotPassword";
import LaunchBase from "screens/LaunchBase";
import LogIn from "screens/LogIn";
import Logout from "screens/Logout";
import ResetPassword from "screens/ResetPassword";
import SelectionListener from "components/SelectionListener";
import { SignupContainer } from "screens/Signup";
import SwitchBaseMenu from "components/SwitchBaseMenu";
import UserProfile from "screens/UserProfile";
import VerificationPage from "components/VerificationPage";
// TODO: Correct the structure of VerificationPage

// Styles
import "./App.scss";
import InvitationRedeem from "screens/InvitationRedeem";
import Overlay from "components/Overlay";
import GetStartedInvite from "screens/GetStartedInvite";
import { BehaviorSubject } from "rxjs";
import { useIntercom } from "react-use-intercom";
import { checkSocketChanges } from "editor/utils/socketActionsListener";
import LocationListener, { locationSubject } from "components/LocationListener";
import {
  deltaUpdateBlockPresence,
  PresenceDelta,
  resetLocalPresence,
} from "store/localSync";
import { syncNotifications } from "modules/notificationsService";
import SignupPartTwo from "screens/SignupPartTwo";
import SubscriptionSuccess from "screens/SubscriptionSuccess";
import {
  DeviceType,
  getDeviceType,
  useDeviceIdentifier,
} from "editor/utils/customHooks";
import {
  addWorkActivities,
  updateWorkActivityFromSocket,
} from "modules/taskService";
import { syncData } from "modules/appService";
import { executionQueue } from "modules/queueService";
import Mixpanel from "modules/mixpanelModule";
import BaseLocator from "screens/BaseLocator";
import BaseNotFound from "screens/BaseNotFound";
import UnexpectedError from "screens/UnexpectedError";
import LinkInvitationRedeem from "screens/LinkInvitationRedeem";
import { UPDATE_HOME_OBJ } from "store/actions";
import UserSettings from "screens/UserSettings";
import Notifications from "components/Notifications";
import workApi from "clientApi/workApi";
import { tokenApi } from "clientApi/tokenApi";
import rolesApi from "clientApi/rolesApi";
import JoinBase from "screens/JoinBase";
import { userApi } from "clientApi/userApi";
import LeaveBaseConfirmationModal from "components/LeaveBaseConfirmationModal";
import Cookies from "js-cookie";
import { groupApi } from "clientApi/groupsApi";
import { favoriteApi } from "clientApi/favoriteApi";
import { noteApi } from "clientApi/noteApi";
import NewVersionBanner from "components/NewVersionBanner";
import { SectionWidthModes, useShallowSelector } from "utilities/hooks";
import { axiosInstance } from "index";
import { baseApi } from "clientApi/baseApi";
import DiscordIntegrationRedirect from "screens/DiscordIntegrationRedirect";
import React from "react";
import { ChunkDestination } from "utilities/stateTypes";
import { updateTemplateItemAction } from "store/reducers/templateReducer";
import { updateSnippetItemAction } from "store/reducers/snippetReducer";

export const mixpanel = new Mixpanel();

export const appVersion = 16;
const appVersionName = "2.7.1";

export const socket: SocketIOClient.Socket = socketIoClient({
  autoConnect: true,
  upgrade: true,
});

export const checkVersion = () => {
  socket.emit("checkVersion", {
    version: appVersion,
    versionName: appVersionName,
  });
};

export const setUser = () => {
  if (prevState.value.user && prevState.value.user.id) {
    const userVal = prevState.value.user;
    socket.emit("setUsername", {
      user: {
        id: userVal.id,
        name: userVal.name ? userVal.name : "@" + userVal.username,
        email: userVal.email,
        avatar: userVal.avatar,
      },
    });
    emitFocusChange();
  } else {
    socket.emit("setUsername", {});
  }
};

socket.on("connect", (e: any) => {
  setUser();
  checkVersion();
  resetLocalPresence();
  executionQueue.offlineMode(false);
  store.dispatch({
    type: actionTypes.CLIENT_ONLINE,
  });
});

socket.on("msgToServer", (data: any) => {});

socket.on("connect_error", () => {
  resetLocalPresence();
  executionQueue.offlineMode(true);
  store.dispatch({
    type: actionTypes.CLIENT_OFFLINE,
  });
});

socket.on("connect_failed", () => {
  resetLocalPresence();
  executionQueue.offlineMode(true);
  store.dispatch({
    type: actionTypes.CLIENT_OFFLINE,
  });
});

socket.on("reconnect", () => {
  resetLocalPresence();
  syncNotifications();
  checkVersion();
  executionQueue.offlineMode(false);
  const storeData = store.getState();
  if (storeData.workspace?.id) {
    syncData(storeData.workspace.id);
  }
  store.dispatch({
    type: actionTypes.CLIENT_ONLINE,
  });
});

socket.on("usernameSet", () => {
  setTimeout(() => {
    joinedRooms.value.forEach((id) => {
      socket.emit("connectToRoom", { roomId: id });
    });
  }, 0);
});

window.addEventListener("beforeunload", () => {
  socket.disconnect();
});

export let appClipboardData: any = {};
export const clearappClipboardData = () => {
  appClipboardData = {};
};

export let flashBlock: any = {};
export const flashBlockSubject = new BehaviorSubject({});
export const clearFlashBlock = () => {
  flashBlock = {};
  flashBlockSubject.next({});
};

interface IMapStateToProps {
  user: IUserObj;
  connected: boolean;
  base: IWorkspaceObj;
}

interface IMapDispatchToProps {
  setAuthenticatedUser: (user: IUserObj) => { type: string; user: IUserObj };
  setRefreshRedirectUrl: (url: string) => { type: string; url: string };
  setCurrentDiscussionId: (id: string) => { type: string; id: string };
  setCurrentPrediscussionId: (id: string) => { type: string; id: string };
  removePrediscussionId: (id: string) => { type: string; id: string };
}

export const deviceType = new BehaviorSubject(DeviceType.desktop);

const checkIfShouldUpdate = (
  containerId: string,
  containerType: ContainerTypes,
  permissions: Partial<PermissionType>
) => {
  const userRole = store.getState().client.roleType;

  const baseType = store.getState().workspace.type;

  if (baseType === BaseType.Public) {
    return true;
  }

  if (userRole !== UserRole.GUEST) {
    return true;
  }
  if (!permissions) {
    return true;
  }
  const primitiveType: PrimitiveScopes =
    mapContainerTypeToPrimitive[containerType];

  const primitivePermissions = permissions[primitiveType];
  if (primitivePermissions) {
    return primitivePermissions.read.includes(containerId);
  }
  return true;
};

export const SizeContext = React.createContext<SectionWidthModes>(
  getDeviceType() === DeviceType.desktop
    ? SectionWidthModes.xlarge
    : getDeviceType() === DeviceType.mobile
    ? SectionWidthModes.small
    : SectionWidthModes.medium
);

export const PaneContext = React.createContext<ChunkDestination>(
  ChunkDestination.primary
);

const App = (props: IMapStateToProps & IMapDispatchToProps) => {
  const { user } = props;
  const accessToken = useShallowSelector((state) => state.client.accessToken);
  const isFirstLoad = useRef(true);

  useLayoutEffect(() => {
    const refreshToken = Cookies.get("x-rt");
    if (!refreshToken && user.id) {
      const currentLocation = window.location.pathname;
      logUserOut(currentLocation);
      return;
    }
    if (accessToken) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${
        accessToken ? accessToken : ""
      }`;
    }
  }, [accessToken]);

  const { boot, update } = useIntercom();
  deviceType.next(useDeviceIdentifier());

  useEffect(() => {
    if (user?.id) {
      try {
        boot({
          userId: user.id,
          name: user.name || user.username,
          email: user.email,
          createdAt: user.dateCreated,
          hideDefaultLauncher: true,
        });
        update();
      } catch {}
    }
  }, [user?.id]);

  const [loading, setloading] = useState(true);

  const [newVersion, setNewVersion] = useState(false);

  useLayoutEffect(() => {
    if (accessToken) {
      if (isFirstLoad.current) setloading(true);
      axiosInstance
        .get("/api/user")
        .then((res) => {
          const { payload: user } = res.data;
          boot({
            userId: user.id,
            name: user.name || user.username,
            email: user.email,
            createdAt: user.dateCreated,
            hideDefaultLauncher: true,
          });
          props.setAuthenticatedUser(user);
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (user.id) {
        setloading(true);
        refreshTheToken();
        return;
      }
      setloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (props.connected) {
      if (user && user.id) {
        socket.emit("setUsername", {
          user: {
            id: user.id,
            name: user.name,
            email: user.email,
            avatar: user.avatar,
          },
        });
      } else {
        socket.emit("setUsername", {});
      }
    }
  }, [user?.id]);

  useEffect(() => {
    socket.on("projectRankChange", (data: any) => {
      const permissions = store.getState().client.permissions.permissions;
      if (
        !checkIfShouldUpdate(
          data.project.id,
          ContainerTypes.PROJECT,
          permissions
        )
      ) {
        return;
      }
      batch(() => {
        const projectOptions = {
          isFullProject: true,
          projectId: data.project.id,
        };
        store.dispatch({
          type: actionTypes.UPDATE_PROJECT_RANK,
          project: data.project,
          projectOptions,
        });
        store.dispatch({
          type: actionTypes.UPDATE_WORK_ITEM,
          workItem: data.project,
        });
      });
    });

    socket.on("linkedProjectRankChange", (data: any) => {
      const permissions = store.getState().client.permissions.permissions;

      if (
        !checkIfShouldUpdate(data.block.id, ContainerTypes.PROJECT, permissions)
      ) {
        return;
      }
      const project = {
        id: data.block.id,
        rank: data.block.documentRank,
        parentId: data.block.containerId,
        titleBlockId: data.project.titleBlockId,
      };
      const projectOptions = {
        isFullProject: false,
        projectId: data.project.id,
      };
      store.dispatch({
        type: actionTypes.UPDATE_PROJECT_RANK,
        project,
        projectOptions,
      });
    });

    socket.on("linkedProjectRemoved", (data: any) => {
      const permissions = store.getState().client.permissions.permissions;

      if (
        !checkIfShouldUpdate(data.block.id, ContainerTypes.PROJECT, permissions)
      ) {
        return;
      }
      const project = {
        id: data.block.id,
        rank: data.block.documentRank,
        parentId: data.block.containerId,
        titleBlockId: data.project.titleBlockId,
      };
      store.dispatch({
        type: actionTypes.REMOVE_PROJECT_FROM_TREE,
        id: project.id,
      });
    });

    socket.on("projectRemoved", (data: any) => {
      const permissions = store.getState().client.permissions.permissions;

      if (!checkIfShouldUpdate(data.id, ContainerTypes.PROJECT, permissions)) {
        return;
      }
      batch(() => {
        store.dispatch({
          type: actionTypes.DELETE_WORK_ITEM,
          id: data.id,
        });
      });
      store.dispatch({
        type: actionTypes.REMOVE_PROJECT_FROM_TREE,
        id: data.id,
      });
    });

    socket.on("workItemUpdate", (data: any) => {
      const permissions = store.getState().client.permissions?.permissions;

      if (
        !checkIfShouldUpdate(
          data.workItem.id,
          ContainerTypes.PROJECT,
          permissions
        )
      ) {
        return;
      }

      store.dispatch({
        type: actionTypes.UPDATE_WORK_ITEM,
        workItem: data.workItem,
      });
    });

    socket.on("workItemsUpdates", (data: any) => {
      const permissions = store.getState().client.permissions.permissions;

      const updates = [];
      for (const workItem of data.workItems) {
        if (
          checkIfShouldUpdate(workItem.id, ContainerTypes.PROJECT, permissions)
        ) {
          updates.push(workItem);
        }
      }

      store.dispatch({
        type: actionTypes.UPDATE_WORK_ITEMS,
        workItems: updates,
      });
    });

    socket.on("updateWorkActivity", (data: any) => {
      const permissions = store.getState().client.permissions.permissions;
      if (
        !checkIfShouldUpdate(
          data.workItemId,
          ContainerTypes.PROJECT,
          permissions
        )
      ) {
        return;
      }
      if (data.type === "add") {
        addWorkActivities(data.workActivities, data.workItemId);
      }
      if (data.type === "update") {
        updateWorkActivityFromSocket(data.workActivities);
      }
    });

    socket.on("resourceUpdate", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      store.dispatch({
        type: actionTypes.ADD_RESOURCE,
        resource: data.resource,
      });
    });

    socket.on("sectionAdded", (data: any) => {
      if (data.section) {
        store.dispatch({
          type: actionTypes.ADD_SECTION,
          section: data.section,
        });
      }
    });

    socket.on("sectionChange", (data: any) => {
      if (data.type === "update") {
        store.dispatch({
          type: actionTypes.PATCH_SECTION,
          patchSection: {
            id: data.sectionId,
            patch: data.patch,
          },
        });
      }
      if (data.type === "delete") {
        store.dispatch({
          type: actionTypes.DELETE_SECTION,
          sectionId: data.sectionId,
        });
      }
    });

    socket.on("milestoneAdded", (data: any) => {
      if (data.milestone) {
        const milestone = data.milestone;
        workApi.updateMilestoneState(milestone.id, "insert", milestone);
      }
    });

    socket.on("milestoneChange", (data: any) => {
      const milestone = data?.milestone;
      if (data.type === "update") {
        if (!milestone) return;
        workApi.updateMilestoneState(milestone.id, "update", milestone);
      }
      if (data.type === "delete") {
        workApi.updateMilestoneState(data.id, "delete", {});
      }
    });

    socket.on("addedLabel", (data: any) => {
      if (data.label) {
        store.dispatch({
          type: actionTypes.ADD_BASE_LABEL,
          label: data.label,
        });
      }
    });

    socket.on("addPin", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      if (data.pin) {
        store.dispatch({
          type: actionTypes.ADD_NEW_PIN,
          pin: data.pin,
        });
      }
    });

    socket.on("deletePin", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      if (data.pin) {
        store.dispatch({
          type: actionTypes.REMOVE_PIN,
          pin: data.pin,
          id: data.pin.id,
        });
      }
    });

    socket.on("updatedPins", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      if (data.pinIds) {
        store.dispatch({
          type: actionTypes.UPDATE_PIN,
          newPinsArray: data.pinIds,
        });
      }
    });

    socket.on("pageUpdate", (data: any) => {
      const permissions = store.getState().client?.permissions?.permissions;
      if (
        !checkIfShouldUpdate(
          data.page?.id ?? data.documentId,
          ContainerTypes.DOCUMENT,
          permissions
        )
      ) {
        return;
      }
      if (data.type === "add") {
        store.dispatch({
          type: actionTypes.ADD_NEW_DOCUMENT,
          document: data.page,
        });
      }
      if (data.type === "delete") {
        store.dispatch({
          type: actionTypes.REMOVE_DOCUMENT,
          documentId: data.documentId,
        });
      }
    });

    socket.on("blockActionsUpdate", (data: any) => {
      checkSocketChanges(data);
    });

    socket.on("newNote", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      store.dispatch({
        type: actionTypes.ADD_NEW_NOTE,
        note: data.note,
      });
    });

    socket.on("noteDelete", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      store.dispatch({
        type: actionTypes.REMOVE_NOTE,
        noteId: data.noteId,
      });
    });

    socket.on("notificationUpdate", (data: any) => {
      store.dispatch({
        type: actionTypes.UPDATE_THREAD,
        threadDelta: data.threadView,
        id: data.threadView.id,
      });
    });

    socket.on("slackIntegration", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      store.dispatch({
        type: actionTypes.UPDATE_WORKSPACE,
        params: {
          delta: {
            integrations: {
              slack: true,
            },
          },
        },
      });
    });

    socket.on("updateWorkspace", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }

      store.dispatch({
        type: actionTypes.UPDATE_WORKSPACE,
        params: {
          delta: {
            ...data,
          },
        },
      });
    });

    socket.on("homeUpdate", (data: any) => {
      store.dispatch({
        type: UPDATE_HOME_OBJ,
        home: { ...data.home },
      });
    });

    socket.on("workStatusChanged", (data: any) => {
      const permissions = store.getState().client.permissions.permissions;
      if (!checkIfShouldUpdate(data.id, ContainerTypes.PROJECT, permissions)) {
        return;
      }
      store.dispatch({
        type: actionTypes.UPDATE_WORK_STATUS,
        param: {
          ...data,
        },
      });
    });

    socket.on("customWorkChange", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      store.dispatch({
        type: actionTypes.UPDATE_CUSTOM_VIEW,
        param: {
          ...data,
        },
      });
    });

    socket.on("caretDeltaChange", (data: { delta: PresenceDelta[] }) => {
      deltaUpdateBlockPresence(data.delta);
    });

    socket.on("customViewUpdate", (data: any) => {
      const userRole = store.getState().client.roleType;
      if (userRole === UserRole.GUEST) {
        return;
      }
      store.dispatch({
        type: actionTypes.UPDATE_CUSTOM_VIEW,
        param: data,
      });
    });

    socket.on("userBaseSettingsChanged", (data: any) => {
      const settings = {
        ...data.settings,
        settings: data.settings.settings,
      };
      store.dispatch({
        type: actionTypes.SET_USER_BASE_SETTINGS,
        userBaseSettings: settings,
      });
    });

    socket.on("memberChanged", (data: any) => {
      store.dispatch({
        type: actionTypes.CHANGED_BASE_MEMBER,
        ...data,
      });
    });

    socket.on("updateBaseCount", (data: any) => {
      store.dispatch({
        type: actionTypes.UPDATE_CURRENT_MONTHLY_COUNT,
        param: {
          currentCount: data.currentCount,
        },
      });
    });

    socket.on("rewardChanged", (data: any) => {
      workApi.updateWorkItemReward(data.workItemId, data.reward, data.type);
    });

    socket.on("tokenChanged", (data: any) => {
      tokenApi.updateState(data.id, data.token, data.type);
    });

    socket.on("roleChanged", (data: any) => {
      rolesApi.aggregateNewUserRoles(data.roleIds, data.workspaceId);
    });

    socket.on("roleUpdate", (data: any) => {
      rolesApi.updateRoleAndPermissions(data.deltas, data.type);
    });

    socket.on("leftBase", (data: any) => {
      const baseId = data.workspaceId;
      const currentBaseId = store.getState().workspace.id;

      if (baseId === currentBaseId) {
        locationSubject.next("/profile");
      }

      userApi.resetUser();
    });

    socket.on("groupChanged", (data: any) => {
      groupApi.updateGroupFromSocket(data);
    });

    socket.on("userGroupChanged", (data: any) => {
      groupApi.joinedGroup(data);
    });

    socket.on("userFavoriteChanged", (data: any) => {
      favoriteApi.changedFavorite(data);
    });

    socket.on("noteUpdate", (data: any) => {
      noteApi.updateNote(data);
    });

    socket.on("permissionUpdated", (data: any) => {
      store.dispatch({
        type: actionTypes.SET_USER_PERMISSIONS,
        params: {
          roleType: data.roleType,
          permissions: data.permissions,
          roleIds: data.roleIds,
        },
      });
    });

    socket.on("permissionsAdded", (data: any) => {
      store.dispatch({
        type: actionTypes.SET_USER_PERMISSIONS,
        params: {
          roleType: data.roleType,
          permissions: data.permissions,
          roleIds: data.roleIds,
        },
      });
      baseApi.reloadGuestData(data.workspaceId, data.permissions.permissions);
    });

    socket.on("newVersion", (data: any) => {
      if (data.forceReload) {
        window.location.reload();
        return;
      }
      if (data.showBanner) {
        setNewVersion(true);
      }
    });

    socket.on("userOnline", (data: any) => {
      if (data.userId) {
        store.dispatch({
          type: actionTypes.ADD_ONLINE_MEMBER,
          newOnlineMember: data.userId,
        });
      }
    });

    socket.on("userOffline", (data: any) => {
      if (data.userId) {
        store.dispatch({
          type: actionTypes.REMOVE_ONLINE_MEMBER,
          offlineMember: data.userId,
        });
      }
    });

    socket.on("userDiscordConnected", (data: any) => {
      userApi.updateUser({
        discordUsername: data.discordUsername,
      });
    });

    socket.on("userThreadsRead", (data: any) => {
      data.userThreadIds.forEach((threadId: string) => {
        store.dispatch({
          type: actionTypes.UPDATE_THREAD,
          id: threadId,
          threadDelta: { read: true, done: false, mentionCount: 0 },
        });
      });
    });

    socket.on("userThreadsUnread", (data: any) => {
      data.userThreadIds.forEach((threadId: string) => {
        store.dispatch({
          type: actionTypes.UPDATE_THREAD,
          id: threadId,
          threadDelta: { read: false, mentionCount: 0 },
        });
      });
    });

    socket.on("markThreadAsDone", (data: any) => {
      store.dispatch({
        type: actionTypes.UPDATE_THREAD,
        id: data.threadId,
        threadDelta: { done: true, read: true, mentionCount: 0 },
      });
    });

    socket.on("markMultipleThreadAsDone", (data: any) => {
      data.threadIds.forEach((threadId: string) => {
        store.dispatch({
          type: actionTypes.UPDATE_THREAD,
          id: threadId,
          threadDelta: { done: true, read: true, mentionCount: 0 },
        });
      });
    });

    socket.on("markThreadAsNotDone", (data: any) => {
      store.dispatch({
        type: actionTypes.UPDATE_THREAD,
        id: data.threadId,
        threadDelta: { done: false },
      });
    });

    socket.on("markMultipleThreadAsNotDone", (data: any) => {
      data.threadIds.forEach((threadId: string) => {
        store.dispatch({
          type: actionTypes.UPDATE_THREAD,
          id: threadId,
          threadDelta: { done: false },
        });
      });
    });

    socket.on("templateItemUpdates", (data: any) => {
      batch(() => {
        data.actions.forEach((action: any) => {
          updateTemplateItemAction({
            delta: action.delta,
            id: action.id,
            type: action.type,
            skipInsertInList: false,
          });
        });
      });
    });

    socket.on("snippetItemUpdates", (data: any) => {
      batch(() => {
        data.actions.forEach((action: any) => {
          updateSnippetItemAction({
            delta: action.delta,
            id: action.id,
            type: action.type,
            skipInsertInList: false,
          });
        });
      });
    });

    return () => {
      socket.removeEventListener("projectRankChange");
      socket.removeEventListener("linkedProjectRankChange");
      socket.removeEventListener("linkedProjectRemoved");
      socket.removeEventListener("projectRemoved");
      socket.removeEventListener("workItemUpdate");
      socket.removeEventListener("resourceUpdate");
      socket.removeEventListener("sectionAdded");
      socket.removeEventListener("sectionChange");
      socket.removeEventListener("addedLabel");
      socket.removeEventListener("addPin");
      socket.removeEventListener("blockActionsUpdate");
      socket.removeEventListener("newNote");
      socket.removeEventListener("slackIntegration");
      socket.removeEventListener("caretDeltaChange");
      socket.removeEventListener("customViewUpdate");
      socket.removeEventListener("updateWorkActivity");
      socket.removeEventListener("userBaseSettingsChanged");
      socket.removeEventListener("memberChanged");
      socket.removeEventListener("updateBaseCount");
      socket.removeEventListener("rewardChanged");
      socket.removeEventListener("tokenChanged");
      socket.removeEventListener("roleChanged");
      socket.removeEventListener("groupChanged");
      socket.removeEventListener("userGroupChanged");
      socket.removeEventListener("userFavoriteChanged");
      socket.removeEventListener("noteUpdate");
      socket.removeEventListener("newVersion");
      socket.removeEventListener("userOnline");
      socket.removeEventListener("userOffline");
      socket.removeEventListener("updateWorkspace");
      socket.removeEventListener("permissionUpdated");
      socket.removeEventListener("permissionsAdded");
      socket.removeEventListener("userDiscordConnected");
      socket.removeEventListener("userThreadsRead");
      socket.removeEventListener("userThreadsUnread");
      socket.removeEventListener("markThreadAsDone");
      socket.removeEventListener("markThreadAsNotDone");
      socket.removeEventListener("markMultipleThreadAsDone");
      socket.removeEventListener("markMultipleThreadAsNotDone");
      socket.removeEventListener("templateItemUpdates");
      socket.removeEventListener("snippetItemUpdates");
    };
  }, []);

  useEffect(() => {
    isFirstLoad.current = false;
  }, []);

  // switch theme code
  let theme = localStorage.getItem("dark");
  const isCheckTheme = () => (theme === "dark-mode" ? true : false);
  const checkTheme = isCheckTheme();
  const [themeSwitch] = useState(checkTheme);

  useEffect(() => {
    if (themeSwitch) {
      document.body.classList.add("dark-mode");
    }
    const functionData = (e: Event) => {
      document.body.scroll({ top: 0 });
    };
    document.body.addEventListener("scroll", functionData);
    return () => {
      document.body.removeEventListener("scroll", functionData);
    };
  }, []);

  return useMemo(() => {
    if (loading || (user?.id && !accessToken)) return <></>;
    return (
      <div
        className="App"
        id="app"
        onDragEnd={(e) => {
          e.preventDefault();
          e.stopPropagation();
          store.dispatch({
            type: actionTypes.DRAG_TOGGLE,
            dragState: false,
          });
        }}
      >
        <div id="container-tooltip"></div>
        <div id="container-card">
          <SelectionListener
            setCurrentDiscussionId={props.setCurrentDiscussionId}
            setCurrentPrediscussionId={props.setCurrentPrediscussionId}
          />
        </div>
        {newVersion && <NewVersionBanner />}
        <SwitchBaseMenu />
        <LocationListener />
        {user && user.id ? (
          <>
            {!user.verified ? (
              <Switch>
                <Route path="/logout" component={Logout} />
                <Route
                  path="/confirmEmail/"
                  render={(props: any) => <VerificationPage />}
                />
                <Redirect from="/" to="/confirmEmail" />
              </Switch>
            ) : (
              <>
                <Switch>
                  <Route
                    path="/confirmEmail/"
                    render={(props: any) => <VerificationPage />}
                  />
                  <Route path="/logout" component={Logout} />
                  <Route path="/launch-base" component={LaunchBase} />
                  <Route path="/signup-2" component={SignupPartTwo} />
                  <Route path="/invite" component={InvitationRedeem} />
                  <Route
                    path="/invite-link/:token"
                    component={LinkInvitationRedeem}
                  />
                  <Route
                    exact
                    path="/subscription"
                    component={SubscriptionSuccess}
                  />
                  <Route exact path="/not-found" component={BaseNotFound} />
                  <Route exact path="/error" component={UnexpectedError} />
                  <Redirect from="/confirmEmail" to="/" />
                  <Redirect from="/login" to="/" />
                  <Redirect from="/signup" to="/" />
                  <Redirect from="/password-reset" to="/" />
                  <Redirect from="/forgot-password" to="/" />
                  <Route
                    exact
                    path="/profile/"
                    render={(props: any) => (
                      <UserProfile {...props.match.params} />
                    )}
                  />
                  <Route
                    path={"/discord-integration"}
                    render={() => {
                      return <DiscordIntegrationRedirect />;
                    }}
                  />
                  <Route
                    exact
                    path="/profile/settings"
                    component={UserSettings}
                  />
                  <Route
                    exact
                    path="/:baseSlug/get-started/"
                    render={(props: any) => <GetStartedInvite {...props} />}
                  />
                  <Route
                    path="/:baseSlug/join"
                    render={(props: any) => (
                      <JoinBase {...props.match.params} />
                    )}
                  />
                  <Route
                    path="/:baseSlug"
                    render={(props: any) => <BaseLocator {...props} />}
                  />
                  <Route
                    exact
                    path="/:ownerName/"
                    render={(props: any) => (
                      <UserProfile {...props.match.params} />
                    )}
                  />
                  <Route
                    path="/"
                    render={(props: any) => <BaseLocator {...props} />}
                  />
                </Switch>
                <Notifications />
                <LeaveBaseConfirmationModal />
              </>
            )}
          </>
        ) : (
          <>
            <Switch>
              <Redirect from="/logout" to="/" />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/password-reset" component={ResetPassword} />
              <Route path="/login" component={LogIn} />
              <Route path="/signup" component={SignupContainer} />
              <Route path="/invite" component={InvitationRedeem} />
              <Route
                path="/invite-link/:token"
                component={LinkInvitationRedeem}
              />
              <Route path="/profile/" render={(props: any) => <></>} />
              <Route exact path="not-found" component={BaseNotFound}></Route>
              <Route path="/confirmEmail" component={VerificationPage} />
              <Route
                path="/:baseSlug/join"
                render={(props: any) => <JoinBase {...props.match.params} />}
              />
              <Route
                path="/:baseSlug"
                render={(props: any) => <BaseLocator {...props} />}
              />
              <Redirect from="/settings" to="/" />
              <Route path="/" component={LogIn} />
            </Switch>
          </>
        )}
        <Overlay />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, loading, newVersion]);
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    connected: state.network.connected,
    base: state.workspace,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setAuthenticatedUser: (user: IUserObj) =>
    dispatch({ type: actionTypes.SET_AUTHENTICATED_USER, user }),
  setRefreshRedirectUrl: (url: string) =>
    dispatch({ type: actionTypes.SET_REFRESH_REDIRECT_URL, url }),
  setCurrentDiscussionId: (id: string) =>
    dispatch({ type: actionTypes.SET_CURRENT_DISCUSSION_ID, id }),
  setCurrentPrediscussionId: (id: string) =>
    dispatch({ type: actionTypes.SET_CURRENT_PREDISCUSSION_ID, id }),
  removePrediscussionId: (id: string) =>
    dispatch({ type: actionTypes.REMOVE_PREDISCUSSION_ID, id }),
});

const check = (prevProps: any, nextProps: any) => {
  return (
    prevProps.connected === nextProps.connected &&
    prevProps.accessToken === nextProps.accessToken &&
    prevProps.user === nextProps.user
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(App, check));
