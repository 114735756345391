import UserDisplay from "clarity-ui/UserDisplay";
import styles from "./userList.module.scss";

const Member: React.FC<{
  userId: string;
  disabled?: boolean;
}> = ({ userId, disabled, children }) => {
  return (
    <div className={styles.memberCard}>
      <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        <UserDisplay
          avatarSize={28}
          id={userId}
          showPresence={true}
          labelClassname={styles.memberLabelClass}
        />
      </div>
      {children}
    </div>
  );
};

export default Member;
