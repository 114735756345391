import { getDefaultStatusId } from "components/ModalNewTask";
import { checkIfCannotCreateWork } from "modules/appService";
import { ADD_NEW_PROJECT } from "store/actions";
import { INewTaskContext } from "store/reducers/clientReducer";
import store, { ClarityStore } from "store/storeExporter";
import { IProjectObj, WorkTypes } from "utilities/types";
const { v4: uuidv4 } = require("uuid");

export const handleNewWorkItemCreationCases = (action: any, next: any) => {
  const cannotCreateMoreWork = checkIfCannotCreateWork();
  if (cannotCreateMoreWork) return;

  const storeData: ClarityStore = store.getState();
  const newTaskContext = action.newTaskContext as INewTaskContext;
  if (!newTaskContext) return;
  const presetData = newTaskContext.presetData;

  const workSectionId = presetData?.workSectionId;

  const presetName = presetData?.name ? presetData?.name : "";

  const parentId = presetData?.parentId ?? null;
  let statusId = getDefaultStatusId();
  if (presetData?.statusId) {
    statusId = presetData.statusId;
  }

  let assigneeId = null;
  let assignee = undefined;
  if (presetData?.assigneeId) {
    assigneeId = presetData.assigneeId;
    assignee = storeData.members.dict[assigneeId];
  }
  let titleBlockId = "";
  if (presetData?.titleBlockId) {
    titleBlockId = presetData?.titleBlockId;
  }

  let dueDate = presetData?.dueDate ? presetData.dueDate : undefined;

  let workType = presetData?.workType ? presetData.workType : WorkTypes.TASK;

  let presetTaskId = presetData?.id ? presetData?.id : uuidv4();

  const presetGroupId =
    presetData?.groupId && presetData?.groupId !== ""
      ? presetData?.groupId
      : "";

  const presetPriority = presetData?.priority ?? 1;

  const newTask: IProjectObj = {
    id: presetTaskId,
    name: presetName,
    authorId: storeData.user?.id ? storeData.user.id : "",
    nameValue: [],
    assigneeId,
    priority: presetPriority,
    assignee,
    dateCreated: new Date(),
    workType,
    tagsBlockId: null,
    isClosed: false,
    statusId,
    parentId,
    projectId: 0,
    isDeleted: false,
    titleBlockId,
    dueDate,
    workActivities: [],
    workSectionId: workSectionId ? workSectionId : null,
    childrenAggregate: [],
    contributorIds: presetData?.contributorIds ?? [],
    groupId: presetGroupId,
    groupIds: presetData?.groupIds,
    isDone: false,
    reviewerId: presetData?.reviewerId,
    workspaceId: storeData.workspace.id,
    milestoneId: presetData?.milestoneId,
    reward: presetData?.reward,
    tags: presetData?.tags,
  };

  if (newTask.statusId) {
    const status = storeData.work.statuses.dict[newTask.statusId as string];
    if (status && (status.categoryId === "4" || status.categoryId === "5")) {
      newTask.isClosed = true;
      newTask.dateClosed = new Date();
    }
    if (status && status.categoryId === "4") {
      newTask.isDone = true;
    }
  }

  const newAction = {
    type: ADD_NEW_PROJECT,
    project: newTask,
  };

  next(newAction);

  if (action.newTaskContext?.presetData)
    action.newTaskContext.presetData.id = newTask.id;
  else
    action.newTaskContext.presetData = {
      id: newTask.id,
    };

  return action;
};
