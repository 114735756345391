import {
  SET_PAGE_DATA,
  SET_SHARE_DATA,
  SET_TOP_NAV_BTN_COMPONENTS,
  REMOVE_TOP_NAV_BTN_COMPONENTS,
  SET_TASK_COUNT_DATA,
  SET_IS_WORK_VIEW,
  SET_SHOW_SUBROUTES,
  SET_TOPNAV_VIEW_TYPE,
  SET_SHOW_BASE_AS_BREADCRUMB,
} from "store/actions";
import { pick, find } from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useShallowSelector } from "utilities/hooks";
import { ButtonTypes } from "components/Button";
import { Props as TaskCounterProps } from "screens/base/main/workTwo/TaskSelectionCounter";
import { ContainerTypes } from "utilities/types";
import { ChunkDestination } from "utilities/stateTypes";
import store from "store/storeExporter";

interface ISubRoutesParams {
  type: "base" | "group" | "myWork" | "workItem" | "groups&roles" | "templates";
  groupId?: string;
  activeKey?: string;
  action?: (arg: any) => void;
}
interface ViewInitialState {
  pageData?: ISetPageDataParameters;
  shareData?: ISetShareDataParameters;
  btnComponents?: ActionBtn[];
  taskCountData?: ISetTaskDataParameters;
  isWorkView?: boolean;
  subRoutesParams?: ISubRoutesParams;
  navType?: TopNavbarType;
  showBaseAsBreadcrumb?: boolean;
}

export interface IActionBtn {
  kind: ActionBtnKind;
}

export type ActionBtn = IActionTrigger | IActionMenu | IActionTabSet;

export interface IActionTrigger extends IActionBtn {
  kind: ActionBtnKind.Trigger;
  key: string;
  onClick: (e: any) => void;
  contents: React.FC | Function;
  condition?: () => boolean;
  buttonType?: ButtonTypes;
  disabled?: boolean;
  icon?: React.FC | Function;
  iconRef?: ICON;
  standAloneCom?: boolean;
}

export enum ICON {
  PLUS = "PLUS",
}

export interface IActionMenu extends IActionBtn {
  kind: ActionBtnKind.Menu;
  key: string;
  title: React.FC;
  menuItems: MenuChild[];
  condition?: () => boolean;
}

export interface IActionTabSet extends IActionBtn {
  kind: ActionBtnKind.TabSet;
  key: string;
  noFirstDivider?: boolean;
  tabs: {
    key: string;
    onClick: (e: any) => void;
    contents: React.FC | Function;
    active: () => boolean;
    icon?: React.FC | Function;
    condition?: () => boolean;
  }[];
}

interface IMenuChild {
  kind: MenuChildKind;
  key: string;
}

type MenuChild = IMenuSubMenu | IMenuItem;

interface IMenuSubMenu extends IMenuChild {
  kind: MenuChildKind.SubMenu;
  key: string;
  title: string;
  children: IMenuItem[];
}

export interface IMenuItem extends IMenuChild {
  kind: MenuChildKind.Item;
  key: string;
  contents: React.FC;
  onClick: () => void;
  condition?: () => boolean;
}

enum MenuChildKind {
  SubMenu,
  Item,
}

export enum ActionBtnKind {
  Trigger,
  Menu,
  TabSet,
}

interface IInitialState {
  [paneId: string]: ViewInitialState | undefined;
}

const initialState: IInitialState = {};

export enum TopNavbarType {
  base = "base",
  baseOverview = "baseOverview",
  group = "group",
  mywork = "mywork",
  detail = "detail",
  views = "views",
  payoutScreen = "payoutScreen",
}

interface INavActionParameters {
  type: string;
  paneId: ChunkDestination;
  isWorkView?: boolean;
  navType?: TopNavbarType;
  subRoutesParams?: ISubRoutesParams;
  viewType?: TopNavbarType;
  showBaseAsBreadcrumb?: boolean;
}

export interface ISetPageDataParameters {
  title?: string;
  subtitle?: string;
  icon?: React.FC | Function;
  to?: string;
}

interface ISetShareDataParameters {
  activeEntity?: any;
  containerId?: string;
  containerType?: ContainerTypes;
}

interface ISetTaskDataParameters extends TaskCounterProps {}

interface ISetBtnComponentsParameters {
  btnComponents: ActionBtn[];
}

interface IAppendBtnComponentsParameters {
  btnComponents: ActionBtn[];
}

interface IRemoveBtnComponentsParameters {
  btnComponents: ActionBtn[];
}

export default function topNavReducer(
  state = initialState,
  action: INavActionParameters &
    ISetPageDataParameters &
    ISetShareDataParameters &
    ISetBtnComponentsParameters &
    IAppendBtnComponentsParameters &
    IRemoveBtnComponentsParameters &
    ISetTaskDataParameters
): IInitialState {
  const paneId = action.paneId as string;

  switch (action.type) {
    case SET_PAGE_DATA: {
      return {
        ...state,
        [paneId]: {
          ...state[paneId],
          pageData: pick(action, ["title", "subtitle", "icon", "to"]),
        },
      };
    }
    case SET_SHARE_DATA: {
      const newState = {
        ...state,
        [paneId]: {
          ...state[paneId],
          shareData: pick(action, [
            "activeEntity",
            "containerId",
            "containerType",
          ]),
        },
      };
      return newState;
    }

    case SET_TOP_NAV_BTN_COMPONENTS: {
      const newState = {
        ...state,
        [paneId]: {
          ...state[paneId],
          btnComponents: action.btnComponents,
        },
      };
      return newState;
    }

    case REMOVE_TOP_NAV_BTN_COMPONENTS: {
      const btnComponents = (state[paneId]?.btnComponents ?? []).filter(
        (btn) => !find(action.btnComponents, { key: btn.key })
      );
      return {
        ...state,
        [paneId]: {
          ...state[paneId],
          btnComponents,
        },
      };
    }

    case SET_TASK_COUNT_DATA: {
      const newState = {
        ...state,
        [paneId]: {
          ...state[paneId],
          taskCountData: pick(action, [
            "selectedTasksRef",
            "taskSelectionChanged",
            "stateChunkRef",
            "selectAll",
            "deselectAll",
          ]),
        },
      };
      return newState;
    }

    case SET_IS_WORK_VIEW: {
      const newState = {
        ...state,
        [paneId]: {
          ...state[paneId],
          isWorkView: action.isWorkView,
        },
      };
      return newState;
    }

    case SET_SHOW_SUBROUTES: {
      const newState = {
        ...state,
        [paneId]: {
          ...state[paneId],
          subRoutesParams: action.subRoutesParams,
        },
      };
      return newState;
    }

    case SET_TOPNAV_VIEW_TYPE: {
      const newState = {
        ...state,
        [paneId]: {
          ...state[paneId],
          navType: action.navType,
        },
      };
      return newState;
    }

    case SET_SHOW_BASE_AS_BREADCRUMB: {
      const newState = {
        ...state,
        [paneId]: {
          ...state[paneId],
          showBaseAsBreadcrumb: action.showBaseAsBreadcrumb,
        },
      };
      return newState;
    }

    default:
      return state;
  }
}

export function usePageDataSetter(
  paneId: ChunkDestination,
  params: ISetPageDataParameters = {},
  deps: React.DependencyList = []
) {
  const dispatch = useDispatch();

  const setPageData = (params: ISetPageDataParameters) =>
    dispatch({ type: SET_PAGE_DATA, paneId, ...params });

  useEffect(() => {
    if (Object.keys(params).length) {
      setPageData(params);
    }
  }, [params?.title, params?.subtitle, params?.icon, ...deps]);

  useEffect(() => {
    return () => {
      setPageData({ title: "", to: "" });
    };
  }, []);

  return setPageData;
}

export function usePageData(paneId: ChunkDestination) {
  return useShallowSelector((state) => state.topNav[paneId]?.pageData);
}

export function useShareDataSetter(
  paneId: ChunkDestination,
  params?: ISetShareDataParameters
) {
  const dispatch = useDispatch();

  const setShareData = (params: ISetShareDataParameters) =>
    dispatch({ type: SET_SHARE_DATA, paneId, ...params });

  useEffect(() => {
    if (params) {
      setShareData(params);
    }
    return () => {
      setShareData({});
    };
  }, [params?.containerId, paneId]);

  return setShareData;
}

export function useShareData(paneId: ChunkDestination) {
  return useShallowSelector((state) => {
    return state.topNav[paneId]?.shareData;
  });
}

export function useBtnComponentsSetter(
  paneId: ChunkDestination,
  params?: ISetBtnComponentsParameters,
  deps?: any[]
) {
  const dispatch = useDispatch();

  const setBtnComponents = (params: ISetBtnComponentsParameters) =>
    dispatch({
      type: SET_TOP_NAV_BTN_COMPONENTS,
      paneId,
      ...params,
    });

  useEffect(() => {
    if (params) {
      setBtnComponents(params);
    }
  }, [JSON.stringify(params), ...(deps ? deps : [])]);

  useEffect(() => {
    return () => {
      setBtnComponents({ btnComponents: [] });
    };
  }, []);

  return setBtnComponents;
}

export function useBtnComponents(paneId: ChunkDestination, filtered = true) {
  const btnComponents = useShallowSelector(
    (store) => store.topNav[paneId]?.btnComponents ?? []
  );

  return !filtered
    ? btnComponents
    : btnComponents.filter(
        (btn) =>
          isActionMenu(btn) ||
          isActionTabSet(btn) ||
          (isActionTrigger(btn) && (!btn.condition || btn.condition()))
      );
}

export function useTabSets(paneId: ChunkDestination): IActionTabSet[] {
  const btnComponents = useBtnComponents(paneId);
  return btnComponents.filter(isActionTabSet);
}

export function getActionTrigger({
  key,
  onClick,
  contents,
  condition,
  buttonType,
  disabled,
  icon,
  iconRef,
  standAloneCom,
}: Omit<IActionTrigger, "kind">): IActionTrigger {
  return {
    kind: ActionBtnKind.Trigger,
    key,
    onClick,
    contents,
    condition,
    buttonType,
    disabled,
    icon,
    iconRef,
    standAloneCom,
  };
}

export function getActionMenu({
  key,
  title,
  menuItems,
  condition,
}: Omit<IActionMenu, "kind">): IActionMenu {
  return {
    kind: ActionBtnKind.Menu,
    key,
    title,
    menuItems,
    condition,
  };
}

export function getMenuSubMenu({
  title,
  children,
  key,
}: Omit<IMenuSubMenu, "kind">): IMenuSubMenu {
  return {
    kind: MenuChildKind.SubMenu,
    key,
    title,
    children,
  };
}

export function getMenuItem({
  contents,
  onClick,
  key,
  condition,
}: Omit<IMenuItem, "kind">): IMenuItem {
  return {
    kind: MenuChildKind.Item,
    key,
    contents,
    onClick,
    condition,
  };
}

export function getActionTabSet({
  key,
  tabs,
  noFirstDivider,
}: Omit<IActionTabSet, "kind">): IActionTabSet {
  return {
    kind: ActionBtnKind.TabSet,
    key,
    tabs,
    noFirstDivider,
  };
}

export function isActionTrigger(btn: ActionBtn): btn is IActionTrigger {
  return (btn as IActionTrigger).kind === ActionBtnKind.Trigger;
}

export function isActionMenu(btn: ActionBtn): btn is IActionMenu {
  return (btn as IActionMenu).kind === ActionBtnKind.Menu;
}

export function isMenuItem(btn: IMenuItem | IMenuSubMenu): btn is IMenuItem {
  return (btn as IMenuItem).kind === MenuChildKind.Item;
}

export function isActionTabSet(btn: ActionBtn): btn is IActionTabSet {
  return (btn as IActionTabSet).kind === ActionBtnKind.TabSet;
}

export function isMenuSubMenu(
  btn: IMenuItem | IMenuSubMenu
): btn is IMenuSubMenu {
  return (btn as IMenuSubMenu).kind === MenuChildKind.SubMenu;
}

export function setPaneTopNavViewType(params: {
  paneId: ChunkDestination;
  navType?: TopNavbarType;
}) {
  store.dispatch({
    type: SET_TOPNAV_VIEW_TYPE,
    paneId: params.paneId,
    navType: params.navType,
  });
}

export function setPaneSubroutes(params: {
  paneId: ChunkDestination;
  subRoutesParams?: ISubRoutesParams;
}) {
  store.dispatch({
    type: SET_SHOW_SUBROUTES,
    paneId: params.paneId,
    subRoutesParams: params.subRoutesParams,
  });
}
