import { locationSubject } from "components/LocationListener";
import React, { useEffect, useMemo, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { ClarityStore } from "store/storeExporter";
import { UserRole } from "utilities/types";
import Base from "./Base";
import { useIsMobile, useShallowSelector } from "utilities/hooks";
import ReactDOM from "react-dom";
import ShowSplashModal from "clarity-ui/ShowSplashModal";
import { AnimatePresence } from "framer-motion";
import Conditional from "components/Conditional";
import navigationApi from "clientApi/navigationApi";

interface IMatchParams {
  baseSlug: string;
}

const BaseLocator: React.FC<{}> = React.memo(() => {
  const params: IMatchParams = useParams();
  const previousBaseSlug = useRef("");
  const currentState = useSelector((store: ClarityStore) => {
    return {
      user: store.user,
      slug: store.workspace.slug,
      id: store.workspace.id,
      openBaseView: store.client.openBaseView,
      reloadNumber: store.workspace.reloadNumber,
      roleType: store.client.roleType,
      lastVisitedBase: store.recentlyOpened.lastVisitedBase,
    };
  }, shallowEqual);

  const showSlashModal = useShallowSelector(
    (store) => store.user?.showSlashModal
  );

  const location = useLocation();
  const locationRef = useRef(location.pathname);

  useEffect(() => {
    if (!currentState.user) return;
    if (!currentState.user.workspaceIds) {
      return;
    }
    if (!params.baseSlug) {
      if (currentState.user.workspaceIds?.length < 1) {
        locationSubject.next({
          type: "replace",
          location: "/signup-2",
        });
      } else {
        const defaultBase =
          currentState.user.workspaceEntries[currentState.user.workspaceIds[0]];
        const lastVisitedBaseId = currentState.lastVisitedBase;
        const lastVisitedBase =
          currentState.user.workspaceEntries[lastVisitedBaseId];

        if (lastVisitedBase && locationRef.current === "/") {
          locationSubject.next({
            type: "replace",
            location: `/${lastVisitedBase.slug}`,
          });
        } else if (locationRef.current === "/") {
          locationSubject.next({
            type: "replace",
            location: `/${defaultBase.slug}/`,
          });
        }
      }
      previousBaseSlug.current = params.baseSlug;
    }
  }, [currentState.user?.workspaceIds, params.baseSlug]);
  const firstLoad = useRef(0);

  useBaseRedirectListener(firstLoad);

  const isMobile = useIsMobile();

  return useMemo(() => {
    if (!params.baseSlug) return <></>;
    return (
      <>
        <SplashArtListener />
        <Conditional on={!showSlashModal}>
          <div className={"full-flex"}>
            <Base baseSlug={params.baseSlug} />
          </div>
        </Conditional>
      </>
    );
  }, [params.baseSlug, isMobile, currentState.openBaseView, showSlashModal]);
});

const SplashArtListener: React.FC = () => {
  const showSlashModal = useShallowSelector(
    (store) => store.user?.showSlashModal
  );
  return (
    <>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {showSlashModal && <ShowSplashModal />}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useBaseRedirectListener = (firstLoad: any) => {
  const location = useLocation();
  const currentState = useSelector((store: ClarityStore) => {
    return {
      slug: store.workspace.slug,
      id: store.workspace.id,
      openBaseView: store.client.openBaseView,
      reloadNumber: store.workspace.reloadNumber,
      roleType: store.client.roleType,
      work: store.work,
      notes: store.notes,
      showSlashModal: store.user?.showSlashModal,
    };
  }, shallowEqual);

  const changedBase = useRef(currentState.id);
  const lastVisitedState = useSelector((store: ClarityStore) => {
    return {
      lastVisitedBase: store.recentlyOpened.lastVisitedBase,
      lastVisitedLocation: store.recentlyOpened.lastVisitedLocation,
    };
  }, shallowEqual);

  useEffect(() => {
    if (firstLoad.current > 0 && changedBase.current === currentState.id) {
      if (changedBase.current !== currentState.id) {
        changedBase.current = currentState.id;
      }
      return;
    }
    if (changedBase.current !== currentState.id) {
      changedBase.current = currentState.id;
    }
    if (currentState.showSlashModal) {
      if (firstLoad.current === 0) {
        firstLoad.current++;
      }
      return;
    }

    if (!currentState.id) return;

    if (firstLoad.current === 0) {
      firstLoad.current++;
    }

    if (
      (location.pathname === `/${currentState.slug}` ||
        location.pathname === `/${currentState.slug}/` ||
        location.pathname === "/") &&
      !currentState.showSlashModal
    ) {
      if (currentState.roleType === UserRole.GUEST) {
        locationSubject.next({
          type: "replace",
          location: `/${currentState.slug}/my-work`,
        });
      } else {
        const lastVisitedLocation =
          lastVisitedState?.lastVisitedLocation?.[currentState.id];

        if (lastVisitedLocation) {
          navigationApi.navigateMain(lastVisitedLocation);
        }
      }
    }
  }, [currentState]);
};

export default BaseLocator;
